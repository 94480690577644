<template>
	<div style="width:100%">
		<div class="column-format pt-1 pb-4 text-left">
			<div class="brand-bold font-18">Reminders</div>
			<div class="font-14 font-gray_80">Set email reminders and expiration date</div>
		</div>
		<div class="column-format gap-4 mt-2" style="width:99%">
			<date-selector
				v-if="!templateMode && agreement.settings.activationDate"
				style="width:100%;"
				:standard="true"
				:date="agreement.settings.activationDate"
				@change="agreement.settings.activationDate = $event"
				label="Activation date"
			></date-selector>

			<date-selector
				v-if="!templateMode"
				style="width:100%;"
				:standard="true"
				:date="agreement.settings.expirationDate"
				@change="agreement.settings.expirationDate = $event"
				label="Expiration date"
			></date-selector>

			<div class="row-format gap-3" v-if="templateMode && agreement.settings.templateExpirationDate">
				<v-text-field
						type="number"
						inputmode="number"
						hide-details
						label="Expire after"
						dense
						outlined
						persistent-placeholder
						v-model="agreement.settings.templateExpirationDate.duration"
						@keydown="handleDurationKeydown($event)"
				/>
				<v-select
						hide-details
						dense
						outlined
						:label="$t('recurring.time-unit')"
						persistent-placeholder
						:items="timeUnits"
						item-value="value"
						item-text="text"
						v-model="agreement.settings.templateExpirationDate.timeUnit"
				/>
				<v-icon class="material-symbols-rounded" size="20" @click="removeTemplateExpirationDate">delete</v-icon>
			</div>

			<div v-if="templateMode && !agreement.settings.templateExpirationDate" class="column-format">
				<v-btn class="primary-action" @click="addTemplateExpirationDate">+Add expiration date</v-btn>
			</div>

			<div class="column-format text-left gap-3">
				<div class="font-14 brand-medium">Active reminders</div>

				<div
					v-for="reminder in agreement.settings.reminderList"
					:key="reminder.id"
					class="column-format gap-3 pb-3"
					style="border-bottom: 1px solid var(--v-gray_30-base)"
				>
					<div class="row-format gap-3">
						<v-autocomplete
							:items="emailTemplates"
							item-value="id"
							item-text="name"
							v-model="reminder.emailTemplate"
							hide-details
							dense
							persistent-placeholder
							outlined
							label="Email template"
						></v-autocomplete>
					</div>
					<div class="row-format gap-3">
						<v-text-field
							type="number"
							inputmode="number"
							hide-details
							label="Remind after"
							dense
							outlined
							persistent-placeholder
							v-model="reminder.timeline.duration"
							@keydown="handleDurationKeydown($event)"
						/>
						<v-select
							hide-details
							dense
							outlined
							:label="$t('recurring.time-unit')"
							persistent-placeholder
							:items="timeUnits"
							item-value="value"
							item-text="text"
							v-model="reminder.timeline.timeUnit"
						/>
						<v-icon class="material-symbols-rounded" size="20" @click="removeReminder(reminder)">delete</v-icon>
					</div>
				</div>

				<v-btn class="primary-action" @click="addReminder()">+Add reminder</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import DateSelector from '@/components/DateSelector';
	import { v4 as uuid } from 'uuid';
	import EmailTemplateService from '@/modules/templates/EmailTemplateService';

	export default {
		name: 'Reminders',

		props: ['agreement', 'templateMode'],

		components: { DateSelector },

		data: function() {
			return {
				emailTemplates: [],
				timeUnits: [
					{ text: 'Days', value: 'DAYS' },
					{ text: 'Weeks', value: 'WEEKS' },
					{ text: 'Months', value: 'MONTHS' },
				],
			};
		},

		mounted() {
			this.getEmailTemplates();
		},

		beforeDestroy() {},

		methods: {
			getEmailTemplates: function() {
				new EmailTemplateService().getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0);
					this.emailTemplates.push(...res.data);
				});
			},

			removeReminder: function(reminder) {
				let ix = this.agreement.settings.reminderList.findIndex((r) => r.id === reminder.id);
				if (ix > -1) {
					this.agreement.settings.reminderList.splice(ix, 1);
				}
			},

			addReminder() {
				this.agreement.settings.reminderList.push({
					id: uuid(),
					timeline: { duration: 1, timeUnit: 'WEEKS' },
					emailTemplate: null,
				});
			},

			addTemplateExpirationDate: function(){
				this.agreement.settings.templateExpirationDate = { duration: 1, timeUnit: 'WEEKS' };
			},

			removeTemplateExpirationDate: function(){
				this.agreement.settings.templateExpirationDate = null;
			},

			handleDurationKeydown(event) {
				if (event.key === '.') {
					event.preventDefault();
				}
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
