<template>
  <v-menu :close-on-click="false" :close-on-content-click="false" v-model="menu" bottom style="background-color: var(--v-white-base)!important;">
    <template v-slot:activator="{}">
      <div class="row-format gap-2" style="flex: 1 1 0">
        <div class="font-gray_70 font-16" v-if="label">{{label}}</div>
        <div
            @click="openColor"
            :style="
						`flex: 1; border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${
							color ? color : 'unset'
						}; border: 1px solid var(--v-gray_20-base)`
					"
            class="pointer"
        >
          &nbsp;
        </div>
      </div>
    </template>
    <div style="background-color: var(--v-white-base)!important;" class="pa-3 column-format align-center gap-3">
      <v-color-picker
          v-if="menu"
          mode="hexa"
          v-model="color"
          show-swatches
          swatches-max-height="150px"
          @input="updated"
      ></v-color-picker>
      <div class="row-format centered gap-2">
        <v-btn icon @click="clearColor()"><v-icon class="material-symbols-rounded">delete</v-icon></v-btn>
        <v-btn @click="menu = false" elevation="0">OK</v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'ColorSelector',

  props: ['value','label'],

  components: {},

  data: function() {
    return {
      menu: false,
      color: this.value,
    };
  },

  mounted() {},

  beforeDestroy() {},

  methods: {
    openColor: function() {
      if (!this.color) {
        this.color = '#FFFFFF';
      }
      this.menu = true;
    },

    clearColor: function() {
      this.color = null;
      this.menu = false;
      this.updated();
    },

    updated: function() {
      this.$emit('input', this.color);
    },
  },

  computed: {},

  watch: {
    value: function() {
      this.color = this.value;
    },
  },
};
</script>

<style scoped lang="scss"></style>
