<template>
	<v-container class="overview">
		<v-row>
			<v-col>
				<duration-selector class="mt-4" label="Meeting duration" v-model="meeting.meetingLength"></duration-selector>
				<v-select
					persistent-placeholder
					hide-details
					dense
					outlined
					class="my-4"
					label="Location"
					v-model="location"
					:items="locations"
					item-value="value"
					@change="handleLocationChange"
				>
					<template v-slot:selection="{ item }">
						<div class="row-format my-2">
							<v-icon small :class="item.value === 'PhoneIn' ? 'reverse' : ''" color="gray_80">{{
								item.icon
							}}</v-icon>
							<div class="text-left ml-2">
								<div class="font-16">{{ item.text }}</div>
							</div>
						</div>
					</template>

					<template v-slot:item="{ item }">
						<div class="row-format my-1">
							<v-icon :class="item.value === 'PhoneIn' ? 'reverse' : ''" color="gray_80">{{ item.icon }}</v-icon>
							<div class="text-left ml-3">
								<div class="font-16">{{ item.text }}</div>
								<div class="font-12 font-gray_70">{{ item.description }}</div>
							</div>
						</div>
					</template>
				</v-select>
				<div v-if="location">
					<div v-if="location.startsWith('Google')" class="ml-3 text-left font-14 font-primary">
						<div v-if="googleUser">
							{{ googleUser.googleUser.name }}'s Google Calendar ({{ googleUser.googleUser.email }})
						</div>
					</div>
					<div v-else-if="location.startsWith('Microsoft')" class="ml-3 text-left font-14 font-primary">
						<div v-if="microsoftUser">
							{{ microsoftUser.microsoftUser.name }}'s Microsoft Calendar ({{ microsoftUser.microsoftUser.mail }})
						</div>
					</div>
					<div v-if="location.startsWith('Sessions')" class="ml-3 text-left font-14 font-primary">
						<div v-if="sessionsUser">
							{{ sessionsUser.description }}
						</div>
					</div>
					<div v-else-if="location.startsWith('Zoom')" class="ml-3 text-left font-14 font-primary">
						<div v-if="zoomUser">
							{{ zoomUser.zoomUser.first_name }} {{ zoomUser.zoomUser.last_name }}'s Zoom connection ({{
								zoomUser.zoomUser.email
							}})
						</div>
						<div v-else style="color: var(--v-warning-base)" class="row-format align-center">
							<v-icon color="warning" style="margin-left: -10px">$alert</v-icon>
							<div class="ml-2">
								The Zoom account used in this scheduler is current disconnected. Please reconnect or choose a
								different meeting location.
							</div>
						</div>
					</div>
					<div v-else-if="location === 'PhoneOut'" class="ml-3 text-left font-14 font-primary">
						We will ask the invitee for their phone number.
					</div>
					<div v-else-if="location === 'InPerson'" class="mt-2">
						<v-text-field
							persistent-placeholder
							hide-details
							dense
							outlined
							v-model="meeting.location.address"
							label="Address of meeting"
						></v-text-field>
					</div>
					<div v-else-if="location === 'PhoneIn'" class="mt-2">
						<v-text-field
							persistent-placeholder
							hide-details
							dense
							outlined
							type="phone"
							v-model="meeting.location.phone"
							label="Phone number for invitee to call"
						></v-text-field>
					</div>
					<div v-else-if="location === 'Custom'" class="mt-2">
						<v-textarea
							persistent-placeholder
							hide-details
							dense
							outlined
							rows="1"
							:auto-grow="true"
							v-model="meeting.location.custom"
							label="Custom meeting instructions"
						></v-textarea>
					</div>
				</div>

				<v-textarea
					persistent-placeholder
					hide-details
					dense
					outlined
					rows="6"
					:auto-grow="true"
					class="mt-4"
					v-model="meeting.description"
					label="Brief description of meeting"
				></v-textarea>

				<v-select
					v-if="
						mappedCalendars.length && location && !location.startsWith('Google') && !location.startsWith('Microsoft')
					"
					persistent-placeholder
					hide-details
					dense
					outlined
					class="mt-4"
					label="Add meeting to connected calendar"
					:items="mappedCalendars"
					v-model="addToCalendarUser"
				>
					<template v-slot:item="{ item }">
						<div class="row-format align-center">
							<v-icon v-if="item.type === 'Google'" class="mb-1">$googleCalendar</v-icon>
							<v-icon v-if="item.type === 'Apple'" class="mb-1">$apple</v-icon>
							<v-icon v-if="item.type === 'CalDav'" color="secondary">event</v-icon>
							<v-icon v-if="item.type === 'Microsoft'" class="mb-1">$microsoft</v-icon>
							<div class="ml-2">{{ item.text }}</div>
						</div>
					</template>

					<template v-slot:selection="{ item }">
						<div class="row-format align-center">
							<v-icon small v-if="item.type === 'Google'">$googleCalendar</v-icon>
							<v-icon small v-if="item.type === 'Apple'">$apple</v-icon>
							<v-icon small v-if="item.type === 'CalDav'" color="secondary">event</v-icon>
							<v-icon small v-if="item.type === 'Microsoft'">$microsoft</v-icon>
							<div class="ml-2">{{ item.text }}</div>
						</div>
					</template>
				</v-select>

				<multi-email-input class="mt-4" v-model="meeting.additionalAttendees" :known-emails="userEmails" label="Additional attendees"></multi-email-input>

				<v-text-field
					readonly
					persistent-placeholder
					hide-details
					dense
					outlined
					label="Direct meeting link"
					class="mt-4"
					:value="fullMeetingLink"
				></v-text-field>

				<v-select
					persistent-placeholder
					hide-details
					dense
					outlined
					class="mt-4"
					label="Scheduler owner"
					v-model="meeting.ownerUserId"
					:items="usersForAccount"
					item-value="userId"
				>
					<template v-slot:item="{ item }">
						<div v-if="item.userId">
							{{ item.firstName }} {{ item.lastName }} <span class="font-gray_70"> ({{ item.email }})</span>
						</div>
						<div v-else>-- No owner --</div>
					</template>

					<template v-slot:selection="{ item }">
						<div v-if="item.userId">
							{{ item.firstName }} {{ item.lastName }} <span class="font-gray_70"> ({{ item.email }})</span>
						</div>
						<div v-else>-- No owner --</div>
					</template>
				</v-select>

				<v-select
					:disabled="pipelineStages.length === 0"
					hide-details
					dense
					outlined
					persistent-placeholder
					class="mt-4"
					label="Add meeting to pipeline"
					:items="pipelineStages"
					item-value="id"
					item-text="label"
					clearable
					v-model="meeting.pipelineStageId"
				>
					<template v-slot:item="{ item }">
						<div :style="`width: 20px; height: 20px; border-radius: 4px; background-color: ${item.hexColor}`"></div>
						<div class="ml-2">{{ item.label }}</div>
					</template>
					<template v-slot:selection="{ item }">
						<div :style="`width: 20px; height: 20px; border-radius: 4px; background-color: ${item.hexColor}`"></div>
						<div class="ml-2">{{ item.label }}</div>
					</template>
				</v-select>

				<v-switch v-model="meeting.active" label="Link active" hide-details style=""></v-switch>
				<v-switch v-model="meeting.showInPortal" label="Show in Client Portal" hide-details style=""></v-switch>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import DurationSelector from '@/modules/meetings/detail/components/DurationSelector';
	import MultiEmailInput from "@/components/MultiEmailInput";

	export default {
		name: 'Overview',

		props: [
			'meeting',
			'usedNames',
			'uniqueAccountUrl',
			'podId',
			'zoomUsers',
			'sessionsUsers',
			'googleUsers',
			'appleUsers',
			'calDavUsers',
			'microsoftUsers',
			'sampleMode',
			'pipelineStages',
		],

		components: {MultiEmailInput, DurationSelector },

		data: function() {
			return {
				location: null,
				baseUrl: process.env.VUE_APP_MEET_LINK.replace('https://', ''),
				nameIsValid: false,
				nameRules: [(v) => !!v || 'Name is required', (v) => (v && this.isUnique(v)) || 'Name is not unique'],
				addToCalendarUser: this.addToCalendarFormatter(),
			};
		},

		mounted() {
			this.initLocation();
		},

		beforeDestroy() {},

		methods: {
			isUnique: function() {
				if (this.usedNames.includes(this.uniqueMeetingId)) {
					return false;
				} else {
					return true;
				}
			},

			addToCalendarFormatter() {
				let a = this.meeting.addToCalendar;
				if (a) {
					let result = a.type + '|' + a.userId + '|' + a.calendarId;
					if (a.subCalendarId) {
						result = result + '|' + a.subCalendarId;
					}
					return result;
				} else {
					return null;
				}
			},

			initLocation: function() {
				let l = this.meeting.location;
				if (l.type === 'Google') {
					this.location = l.type + ':' + l.googleUserId + ':' + l.calendarId;
					if (l.subCalendarId) {
						this.location = this.location + ':' + l.subCalendarId;
					}
				} else if (l.type === 'Microsoft') {
					this.location = l.type + ':' + l.microsoftUserId + ':' + l.calendarId + ':' + l.subCalendarId;
				} else if (l.type === 'Zoom') {
					this.location = l.type + ':' + l.zoomUserId;
				} else if (l.type === 'Sessions') {
					this.location = l.type + ':' + l.sessionsUserId;
				} else {
					this.location = l.type;
				}
			},

			handleLocationChange: function() {
				if (this.location.startsWith('Google')) {
					let parts = this.location.split(':');
					this.meeting.location.type = 'Google';
					this.meeting.location.googleUserId = parseInt(parts[1], 10);
					this.meeting.location.calendarId = parts[2];
					if (parts.length > 3) {
						this.meeting.location.subCalendarId = parts[3];
					}
				} else if (this.location.startsWith('Zoom')) {
					let parts = this.location.split(':');
					this.meeting.location.type = 'Zoom';
					this.meeting.location.zoomUserId = parseInt(parts[1], 10);
				} else if (this.location.startsWith('Sessions')) {
					let parts = this.location.split(':');
					this.meeting.location.type = 'Sessions';
					this.meeting.location.sessionsUserId = parseInt(parts[1], 10);
				} else if (this.location.startsWith('Microsoft')) {
					let parts = this.location.split(':');
					this.meeting.location.type = 'Microsoft';
					this.meeting.location.microsoftUserId = parseInt(parts[1], 10);
					this.meeting.location.calendarId = parts[2];
					if (parts.length > 3) {
						this.meeting.location.subCalendarId = parts[3];
					}
				} else {
					this.meeting.location.type = this.location;
				}
			},
		},

		watch: {
			uniqueMeetingId: function(newVal) {
				this.meeting.uniqueMeetingId = newVal;
			},

			addToCalendarUser: function(val) {
				if (val) {
					let parts = val.split('|');

					this.meeting.addToCalendar = {
						type: parts[0],
						userId: parts[1],
						calendarId: parts[2],
						subCalendarId: parts.length > 3 ? parts[3] : null,
					};
				} else {
					this.meeting.addToCalendar = null;
				}
			},
		},

		computed: {
			usersForAccount: function() {
				let users = [...this.$store.state.usersForAccount];
				users.splice(0, 0, { userId: null, firstName: null, lastName: null });
				return users;
			},

			userEmails: function(){
				return this.$store.state.usersForAccount.map(u => u.email);
			},

			fullMeetingLink: function() {
				let commPref = this.$store.state.communicationPreferences;
				if (commPref && commPref.primaryDomain) {
					return commPref.primaryDomain + '/public/' + this.uniqueAccountUrl + '/' + this.uniqueMeetingId;
				} else {
					return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + this.uniqueMeetingId;
				}
			},

			uniqueMeetingId: function() {
				if (this.meeting.name) {
					let link = this.meeting.name
						.replaceAll(' ', '-')
						.toLowerCase()
						.replaceAll(/[^a-zA-Z0-9-]/g, '');
					return link;
				} else {
					return '';
				}
			},

			googleUser: function() {
				if (this.meeting.location.type === 'Google' && this.meeting.location.googleUserId) {
					return this.googleUsers.find((g) => g.userId === this.meeting.location.googleUserId && g.googleUser.email === this.meeting.location.calendarId);
				} else {
					return null;
				}
			},

			microsoftUser: function() {
				if (this.meeting.location.type === 'Microsoft' && this.meeting.location.microsoftUserId) {
					return this.microsoftUsers.find((m) => m.userId === this.meeting.location.microsoftUserId);
				} else {
					return null;
				}
			},

			zoomUser: function() {
				if (this.meeting.location.type === 'Zoom' && this.meeting.location.zoomUserId) {
					return this.zoomUsers.find((z) => z.userId === this.meeting.location.zoomUserId);
				} else {
					return null;
				}
			},

			sessionsUser: function() {
				if (this.meeting.location.type === 'Sessions' && this.meeting.location.sessionsUserId) {
					return this.sessionsUsers.find((s) => s.userId === this.meeting.location.sessionsUserId);
				} else {
					return null;
				}
			},

			mappedCalendars: function() {
				let result = [];
				let checkOwnerId = this.meeting.ownerUserId ? this.meeting.ownerUserId : null;

				if (this.googleUsers.length || this.appleUsers.length || this.microsoftUsers || this.calDavUsers) {
					result.push({
						text: ' -- Select --',
						value: null,
					});

					this.appleUsers.forEach((a) => {
						let user = this.$store.state.usersForAccount.find((u) => u.userId === a.userId);
						if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
							a.calendars.forEach((c) => {
								if (c.enabled) {
									result.push({
										type: 'Apple',
										userId: user.userId,
										calendarId: c.path,
										subCalendarId: null,
										calendarLabel: c.name,
										text: `${user.firstName} ${user.lastName} - ${c.name} (${a.email})`,
										value: 'Apple|' + user.userId + '|' + c.path,
									});
								}
							});
						}
					});

					this.calDavUsers.forEach((c) => {
						let user = this.$store.state.usersForAccount.find((u) => u.userId === c.userId);
						if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
								result.push({
									type: 'CalDav',
									userId: user.userId,
									calendarId: c.id,
									subCalendarId: null,
									calendarLabel: c.calendarName,
									text: `${user.firstName} ${user.lastName} - ${c.calendarName}`,
									value: 'CalDav|' + user.userId + '|' + c.id,
								});
						}
					});

					this.googleUsers.forEach((g) => {
						let user = this.$store.state.usersForAccount.find((u) => u.userId === g.userId);
						if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
							if (g.calendarList) {
								g.calendarList
									.filter((c) => c.enabled)
									.forEach((c) => {
										result.push({
											type: 'Google',
											userId: user.userId,
											calendarId: g.googleUser.email,
											subCalendarId: c.calendarId,
											calendarLabel: c.description,
											text: `${g.googleUser.name} - ${c.description} (${g.googleUser.email})`,
											value: 'Google|' + user.userId + '|' + g.googleUser.email + '|' + c.calendarId,
										});
									});
							} else {
								result.push({
									type: 'Google',
									userId: user.userId,
									calendarId: g.googleUser.email,
									calendarLabel: g.googleUser.email,
									subCalendarId: 'primary',
									text: `${g.googleUser.name} (${g.googleUser.email})`,
									value: 'Google|' + user.userId + '|' + g.googleUser.email,
								});
							}
						}
					});

					this.microsoftUsers.forEach((m) => {
						let user = this.$store.state.usersForAccount.find((u) => u.userId === m.userId);
						if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
							m.calendarList
								.filter((c) => c.enabled)
								.forEach((c) => {
									result.push({
										type: 'Microsoft',
										userId: user.userId,
										calendarId: m.microsoftUser.id,
										subCalendarId: c.id,
										calendarLabel: c.name,
										text: `${m.microsoftUser.name} - ${c.name} (${m.microsoftUser.mail})`,
										value: 'Microsoft|' + user.userId + '|' + m.microsoftUser.id + '|' + c.id,
									});
								});
						}
					});
				}

				return result;
			},

			locations: function() {
				let result = [];
				let hasGoogleUsers = false;
				let hasZoomUsers = false;
				let hasMicrosoftUsers = false;
				let hasSessionsUsers = false;
				let checkOwnerId = this.meeting.ownerUserId ? this.meeting.ownerUserId : null;

				this.googleUsers.forEach((g) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === g.userId);
					if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
						hasGoogleUsers = true;

						if (g.calendarList) {
							g.calendarList
								.filter((c) => c.enabled)
								.forEach((c) => {
									result.push({
										text: 'Google meet',
										value: `Google:${user.userId}:${g.googleUser.email}:${c.calendarId}`,
										description: `${g.googleUser.name}'s ${c.description} (${g.googleUser.email})`,
										icon: '$googleCalendar',
									});
								});
						} else {
							result.push({
								text: 'Google meet',
								value: `Google:${user.userId}:${g.googleUser.email}`,
								description: `${g.googleUser.name}'s calender connection (${g.googleUser.email})`,
								icon: '$googleCalendar',
							});
						}
					}
				});

				this.microsoftUsers.forEach((m) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === m.userId);
					if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
						hasMicrosoftUsers = true;
						m.calendarList
							.filter((c) => c.enabled && c.allowedOnlineMeetingProviders.includes('teamsForBusiness'))
							.forEach((c) => {
								result.push({
									text: 'Microsoft teams',
									value: 'Microsoft:' + user.userId + ':' + m.microsoftUser.id + ':' + c.id,
									description: `${m.microsoftUser.name}'s - ${c.name} (${m.microsoftUser.mail})`,
									icon: '$microsoft',
								});
							});
					}
				});

				this.zoomUsers.forEach((z) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === z.userId);
					if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
						hasZoomUsers = true;
						result.push({
							text: 'Zoom',
							value: `Zoom:${user.userId}`,
							description: `${z.zoomUser.first_name} ${z.zoomUser.last_name}'s Zoom connection (${z.zoomUser.email})`,
							icon: '$zoomIcon',
						});
					}
				});

				this.sessionsUsers.forEach((s) => {
					let user = this.$store.state.usersForAccount.find((u) => u.userId === s.userId);
					if (user && (checkOwnerId === null || checkOwnerId === user.userId)) {
						hasSessionsUsers = true;
						result.push({
							text: 'Sessions',
							value: `Sessions:${user.userId}`,
							description: `${s.description}`,
							icon: '$sessionsIcon',
						});
					}
				});

				if (!hasGoogleUsers) {
					result.push({
						text: 'Google meet',
						value: 'Google',
						description: 'Please connect a Google Calendar in your Account Settings page.',
						icon: '$googleCalendar',
						disabled: true,
					});
				}

				if (!hasMicrosoftUsers) {
					result.push({
						text: 'Microsoft',
						value: 'Microsoft',
						description: 'Please connect a Microsoft Calendar in your Account Settings page.',
						icon: '$microsoft',
						disabled: true,
					});
				}

				if (!hasZoomUsers) {
					result.push({
						text: 'Zoom',
						value: 'Zoom',
						description: 'Please connect your Zoom account in your Account Settings page.',
						icon: '$zoomIcon',
						disabled: true,
					});
				}

				if (!hasSessionsUsers) {
					result.push({
						text: 'Sessions',
						value: 'Sessions',
						description: 'Please connect your Sessions.us account in your Account Settings page.',
						icon: '$sessionsIcon',
						disabled: true,
					});
				}

				let otherOptions = [
					{
						text: 'Phone In',
						value: 'PhoneIn',
						description: 'You can provide a phone number for your invitee to call.',
						icon: '$phoneInTalk',
						disabled: false,
					},
					{
						text: 'Phone Out',
						value: 'PhoneOut',
						description: 'We will ask for the invitees phone number so you can call them.',
						icon: '$phoneInTalk',
						disabled: false,
					},
					{
						text: 'In person',
						value: 'InPerson',
						description: 'You can add a physical address where you would like to meet.',
						icon: '$map',
						disabled: false,
					},
					{
						text: 'Custom',
						value: 'Custom',
						description: 'Enter custom instructions for the meeting.',
						icon: '$edit',
						disabled: false,
					},
				];

				result.push(...otherOptions);
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.reverse {
		transform: rotate(270deg);
	}

	.overview {
		padding: 50px;
	}
</style>
