<template>
	<div style="height: 100%; width: 100%; overflow: hidden; background-color: var(--v-white-base)" class="pr-4 stratos">
		<v-container fluid class="fill-height">
			<v-row>
				<v-col md="5" cols="12" class="text-left column-format">
					<img :src="`/images/logo/logo-${$vuetify.theme.dark ? 'white' : 'full'}.png`" style="max-width: 150px" @click="openMoxie()" class="pointer">
					<div class="flex-grow-1 row-format centered">
						<login-core></login-core>
					</div>
				</v-col>
				<v-col md="7" cols="12">
					<div class="right-side column-format centered" :style="$vuetify.breakpoint.mdAndUp ? 'height: calc(var(--vh) - 68px)' : 'padding: 24px'">
						<div class="main-quote-box">
							<div class="quote-box">
								<div class="pa-12 text-left gap-4 column-format">
									<div class="trustpilot-widget" data-locale="en-US" data-template-id="539ad0ffdec7e10e686debd7" data-businessunit-id="64514967fed1aeb46fc6303a" data-style-height="350px" data-style-width="100%" data-theme="light" data-stars="5" data-review-languages="en">
										<a href="https://www.trustpilot.com/review/withmoxie.com" target="_blank" rel="noopener">Trustpilot</a>
									</div>
								</div>

								<v-img :src="$vuetify.theme.dark ? 'images/login/quotes-black.svg' : 'images/login/quotes.svg'" class="quotes"></v-img>
							</div>
							<div
									v-if="$vuetify.breakpoint.smAndDown || ($vuetify.breakpoint.height > 600 && $vuetify.breakpoint.width > 1100)"
								class="row-format text-left px-4 pt-8 gap-2"
								style="
									width: 100%;
									justify-content: space-between;
									color: var(--v-white-base);
									flex-wrap: wrap;
									white-space: nowrap;
								"
							>
								<div class="column-format gap-2">
									<div class="row-format align-center">
										<v-icon color="white">check_circle</v-icon>
										<div class="ml-1">Sell your services</div>
									</div>
									<div class="row-format align-center">
										<v-icon color="white">check_circle</v-icon>
										<div class="ml-1">Kick off new contracts</div>
									</div>
									<div class="row-format align-center">
										<v-icon color="white">check_circle</v-icon>
										<div class="ml-1">Manage projects</div>
									</div>
								</div>
								<div class="column-format gap-2">
									<div class="row-format align-center">
										<v-icon color="white">check_circle</v-icon>
										<div class="ml-1">Communicate with clients</div>
									</div>
									<div class="row-format align-center">
										<v-icon color="white">check_circle</v-icon>
										<div class="ml-1">Track your finances</div>
									</div>
									<div class="row-format align-center">
										<v-icon color="white">check_circle</v-icon>
										<div class="ml-1">Keep it all together</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import LoginCore from '@/modules/auth/LoginCore';
	import quotes from './quotes.json';

	export default {
		name: 'Login',

		props: [],

		components: { LoginCore},

		data: function () {
			return {
				quoteIndex: this.randomIntFromInterval(0, quotes.length - 1),
				quotes: quotes,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openMoxie(){
				window.open('https://www.withmoxie.com','_blank');
			},

			randomIntFromInterval(min, max) {
				return Math.floor(Math.random() * (max - min + 1) + min);
			},

			previous: function () {
				if (this.quoteIndex === 0) {
					this.quoteIndex = this.quotes.length - 1;
				} else {
					this.quoteIndex--;
				}
			},

			next: function () {
				if (this.quoteIndex === this.quotes.length - 1) {
					this.quoteIndex = 0;
				} else {
					this.quoteIndex++;
				}
			},
		},

		computed: {},

		watch: {},
	};
</script>

<style scoped lang="scss">
	.v-application.theme--light {
		.right-side {
			position: relative;
			box-shadow: 0px 0px 16px rgba(0, 43, 82, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.3);
			border-radius: 16px;
			background-position: top right;
			background-image: url('/images/login/top-shape.svg'), linear-gradient(45deg, rgba(201,3,96,1) 23%, rgba(92,0,226,1) 100%);
		}
	}

	.v-application.theme--dark {
		.right-side {
			position: relative;
			box-shadow: 0px 0px 16px rgba(0, 43, 82, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.3);
			border-radius: 16px;
			background-position: top right;
			background-image: url('/images/login/top-shape-black.svg'), linear-gradient(45deg, rgba(201,3,96,1) 23%, rgba(92,0,226,1) 100%);
		}
	}

	.quotes {
		max-width: 112px;
		position: absolute;
		top: -60px;
		left: -40px;
	}

	.main-quote-box {
		width: 70%;
		position: relative;
	}

	.quote-box {
		min-height: 380px;
		background-color: var(--v-white-base);
		border-radius: 16px;
		box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
	}

	.bottom-shape {
		position: absolute;
		bottom: 0;
		z-index: 2;
	}

	.top-button {
		position: absolute;
		top: 0;
	}

	.logo {
		width: 150px;
	}
</style>
