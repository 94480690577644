var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass: "column-format gap-4 mt-2",
        staticStyle: { width: "99%" }
      },
      [
        !_vm.templateMode && _vm.agreement.settings.activationDate
          ? _c("date-selector", {
              staticStyle: { width: "100%" },
              attrs: {
                standard: true,
                date: _vm.agreement.settings.activationDate,
                label: "Activation date"
              },
              on: {
                change: function($event) {
                  _vm.agreement.settings.activationDate = $event
                }
              }
            })
          : _vm._e(),
        !_vm.templateMode
          ? _c("date-selector", {
              staticStyle: { width: "100%" },
              attrs: {
                standard: true,
                date: _vm.agreement.settings.expirationDate,
                label: "Expiration date"
              },
              on: {
                change: function($event) {
                  _vm.agreement.settings.expirationDate = $event
                }
              }
            })
          : _vm._e(),
        _vm.templateMode && _vm.agreement.settings.templateExpirationDate
          ? _c(
              "div",
              { staticClass: "row-format gap-3" },
              [
                _c("v-text-field", {
                  attrs: {
                    type: "number",
                    inputmode: "number",
                    "hide-details": "",
                    label: "Expire after",
                    dense: "",
                    outlined: "",
                    "persistent-placeholder": ""
                  },
                  on: {
                    keydown: function($event) {
                      return _vm.handleDurationKeydown($event)
                    }
                  },
                  model: {
                    value:
                      _vm.agreement.settings.templateExpirationDate.duration,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.agreement.settings.templateExpirationDate,
                        "duration",
                        $$v
                      )
                    },
                    expression:
                      "agreement.settings.templateExpirationDate.duration"
                  }
                }),
                _c("v-select", {
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    label: _vm.$t("recurring.time-unit"),
                    "persistent-placeholder": "",
                    items: _vm.timeUnits,
                    "item-value": "value",
                    "item-text": "text"
                  },
                  model: {
                    value:
                      _vm.agreement.settings.templateExpirationDate.timeUnit,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.agreement.settings.templateExpirationDate,
                        "timeUnit",
                        $$v
                      )
                    },
                    expression:
                      "agreement.settings.templateExpirationDate.timeUnit"
                  }
                }),
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded",
                    attrs: { size: "20" },
                    on: { click: _vm.removeTemplateExpirationDate }
                  },
                  [_vm._v("delete")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.templateMode && !_vm.agreement.settings.templateExpirationDate
          ? _c(
              "div",
              { staticClass: "column-format" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    on: { click: _vm.addTemplateExpirationDate }
                  },
                  [_vm._v("+Add expiration date")]
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "column-format text-left gap-3" },
          [
            _c("div", { staticClass: "font-14 brand-medium" }, [
              _vm._v("Active reminders")
            ]),
            _vm._l(_vm.agreement.settings.reminderList, function(reminder) {
              return _c(
                "div",
                {
                  key: reminder.id,
                  staticClass: "column-format gap-3 pb-3",
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format gap-3" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.emailTemplates,
                          "item-value": "id",
                          "item-text": "name",
                          "hide-details": "",
                          dense: "",
                          "persistent-placeholder": "",
                          outlined: "",
                          label: "Email template"
                        },
                        model: {
                          value: reminder.emailTemplate,
                          callback: function($$v) {
                            _vm.$set(reminder, "emailTemplate", $$v)
                          },
                          expression: "reminder.emailTemplate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row-format gap-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          inputmode: "number",
                          "hide-details": "",
                          label: "Remind after",
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": ""
                        },
                        on: {
                          keydown: function($event) {
                            return _vm.handleDurationKeydown($event)
                          }
                        },
                        model: {
                          value: reminder.timeline.duration,
                          callback: function($$v) {
                            _vm.$set(reminder.timeline, "duration", $$v)
                          },
                          expression: "reminder.timeline.duration"
                        }
                      }),
                      _c("v-select", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          label: _vm.$t("recurring.time-unit"),
                          "persistent-placeholder": "",
                          items: _vm.timeUnits,
                          "item-value": "value",
                          "item-text": "text"
                        },
                        model: {
                          value: reminder.timeline.timeUnit,
                          callback: function($$v) {
                            _vm.$set(reminder.timeline, "timeUnit", $$v)
                          },
                          expression: "reminder.timeline.timeUnit"
                        }
                      }),
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "20" },
                          on: {
                            click: function($event) {
                              return _vm.removeReminder(reminder)
                            }
                          }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            _c(
              "v-btn",
              {
                staticClass: "primary-action",
                on: {
                  click: function($event) {
                    return _vm.addReminder()
                  }
                }
              },
              [_vm._v("+Add reminder")]
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-format pt-1 pb-4 text-left" }, [
      _c("div", { staticClass: "brand-bold font-18" }, [_vm._v("Reminders")]),
      _c("div", { staticClass: "font-14 font-gray_80" }, [
        _vm._v("Set email reminders and expiration date")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }