var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-2" },
    [
      _c("contact-selector", {
        attrs: { multiple: true, label: "To", "to-list": _vm.toList },
        on: {
          change: function($event) {
            return _vm.updateToList($event)
          }
        }
      }),
      _c("contact-selector", {
        attrs: { multiple: true, label: "CC", "to-list": _vm.ccList },
        on: {
          change: function($event) {
            return _vm.updateCcList($event)
          }
        }
      }),
      _c("v-autocomplete", {
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          items: _vm.templates,
          "item-text": "name",
          "item-value": "id",
          label: "Email template"
        },
        on: { change: _vm.checkNewTemplate },
        model: {
          value: _vm.templateId,
          callback: function($$v) {
            _vm.templateId = $$v
          },
          expression: "templateId"
        }
      }),
      _vm.selectedTemplate
        ? _c(
            "div",
            { staticClass: "row-format gap-2" },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  disabled: true,
                  "persistent-placeholder": "",
                  label: "Subject",
                  value: _vm.selectedTemplate.subject
                }
              }),
              _c(
                "v-btn",
                {
                  staticStyle: { "min-height": "40px" },
                  attrs: { outlined: "", elevation: "0", color: "primary" },
                  on: { click: _vm.editTemplate }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [_vm._v("edit")]),
                  _vm._v(" edit")
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.selectedTemplate
        ? _c(
            "div",
            {
              staticClass: "text-left font-gray_70 column-format",
              staticStyle: {
                "max-width": "550px",
                border: "1px solid var(--v-gray_30-base)"
              }
            },
            [
              _c("div", {
                staticClass: "pa-2",
                domProps: {
                  innerHTML: _vm._s(_vm.selectedTemplate.htmlContent)
                }
              }),
              _vm.selectedTemplate.attachments.length
                ? _c(
                    "div",
                    {
                      staticClass: "row-format my-2 pt-2 px-2 gap-2",
                      staticStyle: {
                        "flex-wrap": "wrap",
                        "border-top": "1px solid var(--v-gray_30-base)"
                      }
                    },
                    _vm._l(_vm.selectedTemplate.attachments, function(
                      attachment,
                      index
                    ) {
                      return _c(
                        "v-chip",
                        {
                          key: attachment.fileName + index,
                          attrs: { small: "" }
                        },
                        [_vm._v(_vm._s(attachment.fileName))]
                      )
                    }),
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }