var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "notification-layout",
    {
      attrs: {
        id: _vm.id,
        "action-text": _vm.$t("notifications.view-details"),
        "sent-at": _vm.sentAt
      },
      on: {
        action: function($event) {
          return _vm.navigateToAccount()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _vm.profilePicture
            ? _c("v-avatar", { attrs: { size: "20" } }, [
                _c("img", {
                  staticClass: "pic",
                  attrs: { src: _vm.profilePicture }
                })
              ])
            : _vm._e(),
          _c("div", { staticClass: "ml-2" }, [
            _vm._v(
              _vm._s(_vm.$store.getters.getLoggedInUserName) + "'s workspace"
            )
          ])
        ],
        1
      ),
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "font-14 mb-1 brand-medium" }, [
          _vm._v(
            _vm._s(
              _vm.$t(
                _vm.eventType === "AccountActivated"
                  ? "notifications.stripe-activated"
                  : "notifications.stripe-update"
              )
            )
          )
        ]),
        _c("div", { staticClass: "font-12" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.message) + " ")])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }