import CustomFieldRouter from "@/modules/fields/CustomFieldRouter";

export default [
	{
		path: '/custom-fields',
		name: 'customFields',
		component: CustomFieldRouter,
		title: 'Custom fields',
	},
	{
		path: '/custom-fields/:path',
		name: 'customFields',
		component: CustomFieldRouter,
		title: 'Custom fields',
		props: (route) => ({
			path: route.params.path,
		}),
	}
];
