<template>
	<div>
		<div v-for="field in schema" :key="field.id" class="text-left mb-6">
			<component
					:key="field.id"
					:is="field.fieldType"
					:value="formData[field.name]"
					:preview-mode="true"
					@input="updateForm(field.id, $event)"
					v-bind="field"
			></component>
		</div>
	</div>
</template>

<script>

	import CheckboxInput from "@/modules/discovery/formrenderer/CheckboxInput";
	import RadioInput from "@/modules/discovery/formrenderer/RadioInput";
	import SelectInput from "@/modules/discovery/formrenderer/SelectInput";
	import TextBlock from "@/modules/discovery/formrenderer/TextBlock";
	import TextInput from "@/modules/discovery/formrenderer/TextInput";
	import LeadContact from "@/modules/discovery/formrenderer/LeadContact";
	import DateInput from "@/modules/discovery/formrenderer/DateInput";
	import FileInput from "@/modules/discovery/formrenderer/FileInput";

	export default {
		name: 'Renderer',

		props: ['schema'],

		components: { CheckboxInput, RadioInput, SelectInput, TextBlock, TextInput, LeadContact, DateInput, FileInput  },

		data: function () {
			return {
				formData: this.value || {},
				previewMode: false
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updateForm(id, value) {
				//console.log(id, value);
				this.$set(this.formData, id, value);
				this.$emit('input', this.formData);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
