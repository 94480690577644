var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invoice-action-menu" },
    [
      _c("input", {
        attrs: { type: "hidden", id: "copyInput" + _vm.invoice.id },
        domProps: { value: _vm.invoice.viewOnlineUrl }
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-click": true,
            "nudge-bottom": 30,
            disabled: !_vm.menuEnabled
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(scope) {
                return [
                  _c(
                    "div",
                    { staticClass: "ml-1" },
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "menu-activator",
                            attrs: { icon: "", disabled: !_vm.menuEnabled }
                          },
                          scope.on
                        ),
                        [
                          _c("v-icon", { attrs: { size: 20 } }, [
                            _vm._v(
                              _vm._s(
                                scope.value ? "$arrowUp" : "$moreHorizontal"
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c("div", { staticClass: "more-menu" }, [
            _vm.showView
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("view-detail", _vm.invoice)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.view")) + " ")]
                )
              : _vm._e(),
            ["DRAFT", "SENT", "PARTIAL", "PAID"].includes(_vm.invoice.status) &&
            _vm.sendEnable
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.showModal("SEND")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.sendVerb) + " ")]
                )
              : _vm._e(),
            ["DRAFT"].includes(_vm.invoice.status)
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.showConfirmDialog("MARK_SENT")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("invoice.mark-as-sent")) + " ")]
                )
              : _vm._e(),
            ["SENT", "PARTIAL"].includes(_vm.invoice.status)
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.showModal("MARK-PAID")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("invoice.record-payment")) + " ")]
                )
              : _vm._e(),
            _vm.invoice.status !== "DRAFT"
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.copyLink()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("invoice.copy-link")) + " ")]
                )
              : _vm._e(),
            _vm.invoice.status !== "DRAFT"
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.downloadInvoice()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("invoice.download")) + " ")]
                )
              : _vm._e(),
            _vm.showDuplicate
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.duplicateInvoice()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("invoice.duplicate")) + " ")]
                )
              : _vm._e(),
            _vm.invoice.status !== "DRAFT"
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.showConfirmDialog("DRAFT")
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("invoice.return-to-draft")) + " "
                    )
                  ]
                )
              : _vm._e(),
            _vm.invoice.status !== "PAID" &&
            _vm.lateFee &&
            !_vm.invoice.lateFeeWaived
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.waiveLateFee(true)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invoice.details.waive-late-fee")) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            _vm.invoice.lateFeeWaived
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.waiveLateFee(false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("invoice.details.apply-late-fee")) +
                        " "
                    )
                  ]
                )
              : _vm._e(),
            _vm.invoice.status == "DRAFT" || _vm.invoice.status == "VOIDED"
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.showConfirmDialog("DELETE")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.delete")) + " ")]
                )
              : _vm._e(),
            _vm.invoice.status == "SENT"
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.showModal("VOID")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("invoice.void")) + " ")]
                )
              : _vm._e(),
            ["SENT", "PARTIAL"].includes(_vm.invoice.status)
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.showModal("WRITEOFF")
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("invoice.statuses.WRITE-OFF")) + " "
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      ),
      _c(
        "basic-modal",
        {
          key: _vm.modalKey,
          attrs: {
            dialog: _vm.selectedModal === "MARK-PAID",
            persistent: false
          },
          on: {
            close: function($event) {
              _vm.selectedModal = null
            }
          }
        },
        [
          _c("mark-paid-modal", {
            attrs: { invoice: _vm.invoice },
            on: {
              save: function($event) {
                return _vm.markPaid($event)
              },
              close: function($event) {
                _vm.selectedModal = null
              }
            }
          })
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          attrs: {
            dialog: _vm.selectedModal === "SEND",
            persistent: false,
            "max-width": 500
          },
          on: {
            close: function($event) {
              _vm.selectedModal = null
            }
          }
        },
        [
          _vm.sendEnable
            ? _c("send-widget", {
                attrs: {
                  "client-id": _vm.invoice.clientId,
                  "invoice-id": _vm.invoice.id,
                  status: _vm.invoice.status,
                  "open-state": _vm.selectedModal === "SEND"
                },
                on: {
                  alert: function($event) {
                    return _vm.scope.setAlert($event)
                  },
                  cancel: function($event) {
                    return _vm.handleSendCancel($event)
                  },
                  "invoice-sent": function($event) {
                    return _vm.invoiceSent($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.selectedModal === "VOID", persistent: false },
          on: {
            close: function($event) {
              _vm.selectedModal = null
            }
          }
        },
        [
          _c("void-modal", {
            key: _vm.modalKey,
            attrs: { invoice: _vm.invoice },
            on: {
              save: function($event) {
                return _vm.voidInvoice($event)
              },
              close: function($event) {
                _vm.selectedModal = null
              }
            }
          })
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          attrs: {
            dialog: _vm.selectedModal === "WRITEOFF",
            persistent: false,
            "max-width": 540
          },
          on: {
            close: function($event) {
              _vm.selectedModal = null
            }
          }
        },
        [
          _c("write-off-modal", {
            key: _vm.modalKey,
            attrs: { invoice: _vm.invoice },
            on: {
              save: function($event) {
                return _vm.writeOff($event)
              },
              close: function($event) {
                _vm.selectedModal = null
              }
            }
          })
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDialog === "DELETE",
          "heading-text": "Confirm",
          "yes-text": "Yes",
          "no-text": "No",
          "body-text": _vm.$t("invoice.confirm-dialog.delete")
        },
        on: {
          close: function($event) {
            _vm.confirmDialog = null
          },
          confirm: function($event) {
            return _vm.deleteInvoice()
          }
        }
      }),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDialog == "DRAFT",
          "heading-text": "Confirm",
          "yes-text": "Yes",
          "no-text": "No",
          "body-text": _vm.$t("invoice.confirm-dialog.return-to-draft")
        },
        on: {
          close: function($event) {
            _vm.confirmDialog = null
          },
          confirm: function($event) {
            return _vm.returnToDraft()
          }
        }
      }),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDialog === "MARK_SENT",
          "heading-text": "Confirm",
          "yes-text": "Yes",
          "no-text": "No",
          "body-text": _vm.$t("invoice.confirm-dialog.mark-as-sent")
        },
        on: {
          close: function($event) {
            _vm.confirmDialog = null
          },
          confirm: function($event) {
            return _vm.markAsSent()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }