<template>
	<div>
		<vue-dropzone
			class="ma-0"
			v-if="!branding.favicon"
			ref="fileDropzone"
			id="dropzone"
			v-on:vdropzone-success="uploadSuccess"
			v-on:vdropzone-error="uploadFailure"
			v-on:vdropzone-sending="addFileUploadAuthHeader"
			:use-custom-slot="true"
			:options="dropzoneOptions"
		>
			<div class="row-format centered">
				<v-icon>$vuetify.icons.upload</v-icon><br />
				<span class="ml-2" style="font-size: 0.8em">{{ $t('account.favicon-upload') }}</span>
			</div>
		</vue-dropzone>

		<div class="accountLogo row-format align-center" v-if="branding.favicon" style="position: relative">
			<v-img :src="branding.favicon" style="max-width: 25px"></v-img>
			<v-icon class="delete logoDelete material-symbols-rounded" size="20" @click="deleteFavicon()">delete</v-icon>
		</div>
	</div>
</template>

<script>
	import vueDropzone from 'vue2-dropzone';
	import CustomBrandingService from '@/modules/portal/customization/CustomBrandingService';

	export default {
		name: 'FaviconUploader',

		props: ['branding'],

		components: { vueDropzone },

		data: function() {
			return {
				brandingService: new CustomBrandingService(),
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			uploadSuccess: function(file) {
				this.$refs.fileDropzone.removeFile(file);
				this.$emit('updated');
			},

			uploadFailure: function(file, message) {
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.fileDropzone.removeFile(file);
			},

			deleteFavicon: function() {
				this.$store.commit('startLoading');
				this.brandingService
					.deleteFavicon()
					.then(() => {
						this.$emit('updated');
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {
			dropzoneOptions: function() {
				return {
					url: `${process.env.VUE_APP_BASE_AUTH_URL}/account/${this.branding.accountId}/branding/favicon`,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/png, image/x-icon',
					includeStyling: false,
				};
			},
		},
	};
</script>

<style scoped lang="scss"></style>
