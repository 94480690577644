<template>
	<div  :key="forceUpdate" v-if="isReady" class="fill-height">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' },{  label: 'Communicator' }]">
			Inbox
		</page-header>

		<div v-if="paidAccount">
			<inbox ref="Inbox" :email-boxes="emailBoxes" :subscription="subscription"></inbox>
		</div>

		<div v-if="!paidAccount" class="column-format centered ma-8 px-3">
			<free-tier-card
				style="width: 100%; height: 150px"
				message="One universal inbox for everything important - Voice, Messaging, Email. Communicator is only available on the Pro or Teams plan.  Upgrade now to claim your business phone number and connect your inbox."
				@upgrade-clicked="$router.push('/subscription')"
			></free-tier-card>

			<div class="mt-8"><a href="https://help.withmoxie.com/en/articles/6369542-communicator-overview" target="_blank">Click here to learn more about Communicator!</a></div>
		</div>
	</div>
</template>

<script>
	import FreeTierCard from '@/components/FreeTierCard';
	import Inbox from '@/modules/communicator/inbox/Inbox';
	import CommunicatorService from '@/modules/communicator/CommunicatorService';
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import PageHeader from "@/components/PageHeader";


	export default {
		name: 'Communicator',

		props: ['page'],

		components: {Inbox, FreeTierCard, PageHeader },

		data: function () {
			return {
				communicatorService: new CommunicatorService(),
				communicatorEmailService: new CommunicatorEmailService(),
				subscription: null,
				emailBoxes: [],
				forceUpdate: 0,
				isReady: false,
			};
		},

		mounted() {
			console.log('communicator mounted');
			localStorage.setItem('COMMUNICATOR_LAST_VIEWED','messages');
			this.initialize();
			this.$store.state.eventBus.$on('account-changed', this.initialize);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.initialize);
		},

		methods: {
			initialize: function(){
				let p1 = this.getEmailBoxes();
				let p2 = this.getSubscription();
				Promise.all([p1,p2]).then(() => {
					this.forceUpdate++;
					this.isReady = true;
				})
			},

			getEmailBoxes: function () {
				return new Promise((resolve,reject) => {
					this.communicatorEmailService.getEmailBoxes().then((res) => {
						this.emailBoxes.splice(0, this.emailBoxes.length);
						this.emailBoxes.push(...res.data);
						resolve(this.emailBoxes);
					}).catch((err) => reject(err));
				})
			},

			getSubscription: function () {
				return new Promise((resolve,reject) => {
					this.communicatorService.getSubscription().then((res) => {
						this.subscription = res.data;
						resolve(this.subscription);
					}).catch((err) => reject(err));
				});
			},
		},

		watch: {

		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
