var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format ml-5 mr-3 font-14 mt-2 pt-2 show-scrollbar",
      style: "max-height: " + _vm.height + "; overflow-y:auto",
      attrs: { id: "schedule-view" }
    },
    _vm._l(_vm.days, function(day) {
      return _c(
        "div",
        {
          key: day.date,
          staticClass: "row-format pb-8 mb-3",
          staticStyle: { "border-bottom": "1px solid var(--v-gray_50-base)" },
          attrs: { id: day.date }
        },
        [
          _c(
            "div",
            {
              staticClass: "font-14 brand-medium mt-1 text-left",
              staticStyle: { "min-width": "120px" }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.DateTime.fromISO(day.date).toFormat("ccc")) +
                  " " +
                  _vm._s(
                    _vm.getNumberWithOrdinal(
                      _vm.DateTime.fromISO(day.date).toFormat("d")
                    )
                  ) +
                  " "
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "column-format", staticStyle: { width: "100%" } },
            _vm._l(day.events, function(event) {
              return _c(
                "div",
                {
                  key: event.uuid,
                  staticClass: "pointer mx-2 mb-2",
                  style:
                    "background-color: " +
                    event.backgroundColor +
                    "; border-radius: 4px",
                  on: {
                    click: function($event) {
                      return _vm.$emit("event-clicked", event)
                    }
                  }
                },
                [
                  event.source === "DELIVERABLE"
                    ? _c("deliverable-loader", {
                        staticClass: "pl-0 text-left",
                        attrs: { event: event }
                      })
                    : event.componentName !== "CurrentTimeEvent"
                    ? _c(event.componentName, {
                        tag: "component",
                        staticStyle: { "min-height": "30px" },
                        attrs: {
                          event: event,
                          timed: event.timed,
                          view: "Schedule"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }