var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-3" },
    [
      _c("font-selector", {
        attrs: { label: "Font", font: _vm.lFont },
        on: {
          "font-changed": function($event) {
            return _vm.fontUpdated($event)
          }
        }
      }),
      _c("v-select", {
        attrs: {
          items: [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 45],
          "hide-details": "",
          dense: "",
          label: "Font size",
          outlined: ""
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(_vm._s(item) + "px")]
            }
          },
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(_vm._s(item) + "px")]
            }
          }
        ]),
        model: {
          value: _vm.element.fontSize,
          callback: function($$v) {
            _vm.$set(_vm.element, "fontSize", $$v)
          },
          expression: "element.fontSize"
        }
      }),
      _c("v-select", {
        attrs: {
          items: ["outlined", "underlined", "solo"],
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Input style"
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.element.inputStyle,
          callback: function($$v) {
            _vm.$set(_vm.element, "inputStyle", $$v)
          },
          expression: "element.inputStyle"
        }
      }),
      _c("v-select", {
        attrs: {
          items: _vm.alignment,
          "item-text": "label",
          "item-value": "value",
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Submit button alignment"
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.element.submitAlignment,
          callback: function($$v) {
            _vm.$set(_vm.element, "submitAlignment", $$v)
          },
          expression: "element.submitAlignment"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format align-center" },
        [
          _c("icon-selector", {
            on: { input: _vm.updated },
            model: {
              value: _vm.element.prevPageIcon,
              callback: function($$v) {
                _vm.$set(_vm.element, "prevPageIcon", $$v)
              },
              expression: "element.prevPageIcon"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              "persistent-placeholder": "",
              outlined: "",
              label: "Previous page text"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.prevPageText,
              callback: function($$v) {
                _vm.$set(_vm.element, "prevPageText", $$v)
              },
              expression: "element.prevPageText"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format align-center" },
        [
          _c("icon-selector", {
            on: { input: _vm.updated },
            model: {
              value: _vm.element.nextPageIcon,
              callback: function($$v) {
                _vm.$set(_vm.element, "nextPageIcon", $$v)
              },
              expression: "element.nextPageIcon"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              "persistent-placeholder": "",
              outlined: "",
              label: "Next page text"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.nextPageText,
              callback: function($$v) {
                _vm.$set(_vm.element, "nextPageText", $$v)
              },
              expression: "element.nextPageText"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format align-center" },
        [
          _c("icon-selector", {
            on: { input: _vm.updated },
            model: {
              value: _vm.element.submitIcon,
              callback: function($$v) {
                _vm.$set(_vm.element, "submitIcon", $$v)
              },
              expression: "element.submitIcon"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              "persistent-placeholder": "",
              outlined: "",
              label: "Submit text"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.submitText,
              callback: function($$v) {
                _vm.$set(_vm.element, "submitText", $$v)
              },
              expression: "element.submitText"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "row-format gap-2" }, [
        _c(
          "div",
          { staticClass: "column-format", staticStyle: { width: "49%" } },
          [
            _c("div", { staticClass: "font-14 text-left font-gray_70" }, [
              _vm._v("Form input color")
            ]),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-click": false,
                  "close-on-content-click": false,
                  bottom: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass: "pointer",
                              style:
                                "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                                _vm.element.formInputColor
                            },
                            on
                          ),
                          [_vm._v("   ")]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.inputColorMenu,
                  callback: function($$v) {
                    _vm.inputColorMenu = $$v
                  },
                  expression: "inputColorMenu"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ma-3 column-format align-center gap-3",
                    staticStyle: { "background-color": "var(--v-white-base)" }
                  },
                  [
                    _c("v-color-picker", {
                      attrs: {
                        mode: "hexa",
                        "show-swatches": "",
                        "swatches-max-height": "150px"
                      },
                      on: {
                        input: function($event) {
                          return _vm.updated()
                        }
                      },
                      model: {
                        value: _vm.element.formInputColor,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "formInputColor", $$v)
                        },
                        expression: "element.formInputColor"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            _vm.inputColorMenu = false
                          }
                        }
                      },
                      [_vm._v("OK")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-format", staticStyle: { width: "50%" } },
          [
            _c("div", { staticClass: "font-14 text-left font-gray_70" }, [
              _vm._v("Background color")
            ]),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-click": false,
                  "close-on-content-click": false,
                  bottom: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass: "pointer",
                              style:
                                "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                                _vm.element.backgroundColor
                            },
                            on
                          ),
                          [_vm._v("   ")]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.backgroundColorMenu,
                  callback: function($$v) {
                    _vm.backgroundColorMenu = $$v
                  },
                  expression: "backgroundColorMenu"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ma-3 column-format align-center gap-3",
                    staticStyle: { "background-color": "var(--v-white-base)" }
                  },
                  [
                    _c("v-color-picker", {
                      attrs: {
                        mode: "hexa",
                        "show-swatches": "",
                        "swatches-max-height": "150px"
                      },
                      on: {
                        input: function($event) {
                          return _vm.updated()
                        }
                      },
                      model: {
                        value: _vm.element.backgroundColor,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "backgroundColor", $$v)
                        },
                        expression: "element.backgroundColor"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            _vm.backgroundColorMenu = false
                          }
                        }
                      },
                      [_vm._v("OK")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row-format gap-2 mt-n2" }, [
        _c(
          "div",
          { staticClass: "column-format", staticStyle: { width: "49%" } },
          [
            _c("div", { staticClass: "font-14 text-left ont-gray_70" }, [
              _vm._v("Font color")
            ]),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-click": false,
                  "close-on-content-click": false,
                  bottom: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass: "pointer",
                              style:
                                "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                                _vm.element.fontColor
                            },
                            on
                          ),
                          [_vm._v("   ")]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.fontColorMenu,
                  callback: function($$v) {
                    _vm.fontColorMenu = $$v
                  },
                  expression: "fontColorMenu"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ma-3 column-format align-center gap-3",
                    staticStyle: { "background-color": "var(--v-white-base)" }
                  },
                  [
                    _c("v-color-picker", {
                      attrs: {
                        mode: "hexa",
                        "show-swatches": "",
                        "swatches-max-height": "150px"
                      },
                      on: {
                        input: function($event) {
                          return _vm.updated()
                        }
                      },
                      model: {
                        value: _vm.element.fontColor,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "fontColor", $$v)
                        },
                        expression: "element.fontColor"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            _vm.fontColorMenu = false
                          }
                        }
                      },
                      [_vm._v("OK")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-format", staticStyle: { width: "50%" } },
          [
            _c("div", { staticClass: "font-14 text-left font-gray_70" }, [
              _vm._v("Button color")
            ]),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-click": false,
                  "close-on-content-click": false,
                  bottom: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass: "pointer",
                              style:
                                "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                                _vm.element.buttonColor
                            },
                            on
                          ),
                          [_vm._v("   ")]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.colorMenu,
                  callback: function($$v) {
                    _vm.colorMenu = $$v
                  },
                  expression: "colorMenu"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ma-3 column-format align-center gap-3",
                    staticStyle: { "background-color": "var(--v-white-base)" }
                  },
                  [
                    _c("v-color-picker", {
                      attrs: {
                        mode: "hexa",
                        "show-swatches": "",
                        "swatches-max-height": "150px"
                      },
                      on: { input: _vm.colorUpdated },
                      model: {
                        value: _vm.element.buttonColor,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "buttonColor", $$v)
                        },
                        expression: "element.buttonColor"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            _vm.colorMenu = false
                          }
                        }
                      },
                      [_vm._v("OK")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "row-format gap-2" },
        [
          _c("v-select", {
            staticStyle: { width: "48%" },
            attrs: {
              items: ["Normal", "Block"],
              "hide-details": "",
              outlined: "",
              dense: "",
              label: "Button style"
            },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.buttonStyle,
              callback: function($$v) {
                _vm.$set(_vm.element, "buttonStyle", $$v)
              },
              expression: "element.buttonStyle"
            }
          }),
          _c("v-select", {
            staticStyle: { width: "48%" },
            attrs: {
              items: ["X-Small", "Small", "Normal", "Large", "X-Large"],
              "hide-details": "",
              outlined: "",
              dense: "",
              label: "Button size"
            },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.buttonSize,
              callback: function($$v) {
                _vm.$set(_vm.element, "buttonSize", $$v)
              },
              expression: "element.buttonSize"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "font-14 text-left mb-n3 font-gray_70" }, [
        _vm._v("Max width (" + _vm._s(_vm.element.maxWidth) + "px)")
      ]),
      _c("v-slider", {
        attrs: {
          min: 0,
          max: 1200,
          step: 10,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.maxWidth,
          callback: function($$v) {
            _vm.$set(_vm.element, "maxWidth", $$v)
          },
          expression: "element.maxWidth"
        }
      }),
      _c("simple-code-editor", {
        staticStyle: { width: "100%" },
        attrs: {
          display_language: true,
          language_selector: true,
          languages: [["css", "CSS"]],
          copy_code: false,
          theme: _vm.$vuetify.theme.dark ? "dark" : "light",
          font_size: "14px"
        },
        on: {
          input: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.element.customCss,
          callback: function($$v) {
            _vm.$set(_vm.element, "customCss", $$v)
          },
          expression: "element.customCss"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }