import userflow from 'userflow.js';
import AuthService from "@/modules/auth/AuthService";


export default {
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $onBoarding: {
                get() {
                    return {
                        track: function(event,attributes) {
                            console.log('tracking',event,attributes);
                            return userflow.track(event,attributes);
                        },

                        start: function(id){
                            return userflow.start(id);
                        },

                        status: function(){
                          return AuthService.getOnBoardingStatus();
                        }
                    };
                },
            },
        });

    }
}