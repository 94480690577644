<template>
	<div v-if="isReady">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.paypal.desc') }}</div>
				<connected v-if="isConnected" :label="payPalConnection.status !== 'ACTIVE' ? 'On-Boarding' : ''"></connected>
				<div v-else class="font-primary brand-medium pointer" @click="connectPayPalAccount">Connect PayPal</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<div v-if="payPalConnection.version === 'PARTNER'">
				<div v-if="payPalConnection.status === 'ACTIVE'">
					<div class="mb-6 font-14">
						<span class="brand-medium">PayPal Account:</span> {{ payPalConnection.merchantName }}<br />
					</div>
					<v-btn class="secondary-action mr-1" @click="confirmDisconnect"
						><v-icon small>$delete</v-icon>Disconnect</v-btn
					>
				</div>
				<div v-else>
					<v-btn class="secondary-action mr-1" @click="continueOnBoarding">Continue On-Boarding</v-btn>
					<div class="mt-4 font-12" style="font-style: italic">
						Note: It may take a few minutes after you complete On-Boarding for PayPal to fully connect your account.
					</div>
				</div>
			</div>
			<div v-else>
				<div style="background-color: var(--v-white-base); border-radius: 4px; border: 1px solid var(--v-gray_50-base)" class="pa-4">
					<v-form v-model="isValid" @submit.prevent>
						<div>
							Status: {{ payPalConnection.status }} -
							<span class="font-gray_70">{{ payPalConnection.statusMessage }}</span>
						</div>
						<v-text-field
							v-model="merchantId"
							hide-details
							persistent-placeholder
							class="h-outline"
							:rules="required"
							label="PayPal Merchant Id"
						>
						</v-text-field>
						<v-text-field
							v-model="clientId"
							hide-details
							persistent-placeholder
							class="h-outline"
							:rules="required"
							label="App Client Id"
						>
						</v-text-field>
						<v-text-field
							v-model="clientSecret"
							hide-details
							persistent-placeholder
							class="h-outline"
							:rules="required"
							label="App Client Secret"
						>
						</v-text-field>
						<div class="row-format centered">
							<v-btn class="delete" icon
								><v-icon color="gray_70" @click="deleteStandardPayPalConnection">$delete</v-icon></v-btn
							>
							<v-btn class="ml-2 primary-action" width="200" :disabled="!isValid" @click="updateStandardPayPalConnection">{{
								$t('global.save')
							}}</v-btn>
						</div>
					</v-form>
				</div>
				<div class="mt-4 font-14 brand-medium">
					Check out the <a href="https://help.withmoxie.com/en/articles/5899247-integrating-hectic-with-paypal" target="_blank">help article</a> to quickly connect your PayPal business account with Moxie.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Connected from '@/modules/account/apps/Connected';
	import ConfirmModal from '@/components/ConfirmModal';
	import PayPalService from '@/modules/integrations/paypal/PayPalService';

	export default {
		name: 'PayPal',

		props: [],

		components: { Connected },

		data: function () {
			return {
				payPalService: new PayPalService(),
				expanded: false,
				isReady: true,
				payPalConnection: null,
				merchantId: null,
				clientId: null,
				clientSecret: null,
				isValid: false,
				required: [(v) => !!v || 'Required'],
			};
		},

		mounted() {
			this.checkConnected();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.checkConnected();
			},

			checkConnected: function () {
				this.payPalService.getConnection().then((res) => {
					this.payPalConnection = res.data;
					this.merchantId = res.data.merchantId;
					this.clientId = res.data.clientId;
					this.clientSecret = res.data.clientSecret;
					this.isReady = true;
				});
			},

			createStandardPayPalConnection: function () {
				this.$store.commit('startLoading');
				this.payPalService
					.createStandardConnection()
					.then((res) => {
						this.payPalConnection = res.data;
						this.expanded = true;
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			updateStandardPayPalConnection: function () {
				this.$store.commit('startLoading');

				let config = {
					merchantId: this.merchantId.trim(),
					clientId: this.clientId.trim(),
					clientSecret: this.clientSecret.trim(),
				};

				this.payPalService
					.updateStandardConnection(config)
					.then((res) => {
						this.payPalConnection = res.data;
					})
					.catch((err) => {
						let binding = {
							headingText: 'Unable to connect to PayPal',
							bodyText:
								'Please double check that your Merchant Id, Client Id, and Client Secret are all correct.<br><br>' +
								err.response.data.message,
							hideNo: true,
							yesText: 'OK',
						};
						this.$store.state.globalModalController.openModal(ConfirmModal, binding);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			deleteStandardPayPalConnection: function () {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to disconnect your PayPal account?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.payPalService.deleteStandardConnection().then(() => (this.payPalConnection = null));
					}
				});
			},

			connectPayPalAccount: function () {
				this.$store.commit('startLoading');
				this.payPalService.initializeOnBoarding().then((res) => {
					this.$store.commit('stopLoading');
					this.payPalConnection = res.data;
					window.location.href = this.payPalConnection.actionLink;
				});
			},

			continueOnBoarding: function () {
				this.$store.commit('startLoading');
				this.payPalService.continueOnBoarding().then((res) => {
					this.$store.commit('stopLoading');
					this.payPalConnection = res.data;
					window.location.href = this.payPalConnection.actionLink;
				});
			},

			confirmDisconnect: function () {
				let binding = {
					headingText: 'Disconnect PayPal?',
					bodyText: 'You will need to disconnect your account from the PayPal dashboard.  Would you like to continue?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						window.location.href = 'https://www.paypal.com/businessprofile/mysettings/loginwithpaypal';
					}
				});
			},
		},

		computed: {
			isConnected: function () {
				if (this.payPalConnection) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
