var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format fill-height", attrs: { id: "project-kanban" } },
    [
      _c(
        "div",
        {
          staticClass: "my-0 py-0 fill-height",
          attrs: { id: "kanban-container" }
        },
        [
          _c("div", { staticClass: "mr-3", attrs: { id: "kanban-v-row" } }, [
            _c(
              "div",
              { staticClass: "row-format" },
              [
                _vm.cards["unknown"].length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "kanban-v-col",
                        style:
                          "--kanban-width: " + _vm.columns.length * 272 + "px"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "kanban-column",
                            attrs: { id: "id.unknown" }
                          },
                          [
                            _c("div", { staticClass: "row-format" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "kanban-label",
                                  staticStyle: {
                                    "white-space": "nowrap",
                                    "padding-bottom": "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "[ " +
                                      _vm._s(_vm.$t("projects.no-status")) +
                                      " ]"
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              { attrs: { id: "unknown" } },
                              [
                                _c(
                                  "draggable",
                                  {
                                    attrs: {
                                      list: _vm.cards["unknown"],
                                      group: { name: "card", pull: "clone" },
                                      tag: "section",
                                      emptyInsertThreshold: 100,
                                      animation: 200,
                                      handle: _vm.$store.state.isMobile
                                        ? "disabled"
                                        : ".cardWrapper",
                                      "ghost-class": "ghost",
                                      move: _vm.cardDragHandler
                                    },
                                    on: { end: _vm.endDragHandler }
                                  },
                                  _vm._l(_vm.cards["unknown"], function(
                                    deliverable
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: deliverable.id,
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.editDeliverable(
                                              deliverable
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("kanban-card", {
                                          attrs: { deliverable: deliverable },
                                          on: {
                                            "navigate-to-project": function(
                                              $event
                                            ) {
                                              return _vm.navigateToProject(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "draggable",
                  {
                    staticClass: "row-format",
                    attrs: {
                      disabled: _vm.isCollaborator,
                      list: _vm.columns,
                      handle: ".columnDragHandle",
                      animation: 200,
                      "ghost-class": "ghost"
                    },
                    on: { end: _vm.endColumnDragHandler }
                  },
                  _vm._l(_vm.columns, function(column) {
                    return _c("div", { key: column.id }, [
                      _vm.isInStatusFilter(_vm.filter, column.id)
                        ? _c("div", { staticClass: "kanban-v-col" }, [
                            _c(
                              "div",
                              {
                                staticClass: "kanban-column pointer",
                                style:
                                  "--r: " +
                                  _vm.convertToRGB(column.hexColor)[0] +
                                  "; --g: " +
                                  _vm.convertToRGB(column.hexColor)[1] +
                                  "; --b: " +
                                  _vm.convertToRGB(column.hexColor)[2] +
                                  ";",
                                attrs: { id: "id." + column.id },
                                on: {
                                  click: function($event) {
                                    return _vm.addDeliverable(column)
                                  }
                                }
                              },
                              [
                                _c("kanban-header", {
                                  attrs: {
                                    "all-columns": _vm.columns,
                                    column: column,
                                    "is-collaborator": _vm.isCollaborator,
                                    "current-view": _vm.currentView
                                  },
                                  on: {
                                    "add-deliverable": function($event) {
                                      return _vm.addDeliverableNoWait($event)
                                    },
                                    "confirm-delete-column": function($event) {
                                      return _vm.confirmDeleteColumn($event)
                                    },
                                    "update-deliverable-status-list": function(
                                      $event
                                    ) {
                                      return _vm.updateDeliverableStatusList()
                                    }
                                  }
                                }),
                                _c(
                                  "draggable",
                                  {
                                    class:
                                      "draggable-container " +
                                      _vm.$store.getters.scroll,
                                    style:
                                      "height: calc(var(--vh) - " +
                                      (_vm.singleProjectMode
                                        ? "350px"
                                        : "175px") +
                                      "); overflow-y: auto;",
                                    attrs: {
                                      list: _vm.cards["id" + column.id],
                                      group: { name: "card", pull: "clone" },
                                      tag: "section",
                                      emptyInsertThreshold: 100,
                                      animation: 200,
                                      handle: _vm.$store.state.isMobile
                                        ? "disabled"
                                        : ".cardWrapper",
                                      "ghost-class": "ghost",
                                      move: _vm.cardDragHandler
                                    },
                                    on: { end: _vm.endDragHandler }
                                  },
                                  [
                                    _vm._l(
                                      _vm.cards["id" + column.id],
                                      function(deliverable) {
                                        return _c(
                                          "div",
                                          {
                                            key: deliverable.id,
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.editDeliverable(
                                                  deliverable
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("kanban-card", {
                                              attrs: {
                                                deliverable: deliverable
                                              },
                                              on: {
                                                "navigate-to-project": function(
                                                  $event
                                                ) {
                                                  return _vm.navigateToProject(
                                                    $event
                                                  )
                                                },
                                                "add-new": function($event) {
                                                  return _vm.addDeliverable(
                                                    column
                                                  )
                                                },
                                                "add-new-empty": function(
                                                  $event
                                                ) {
                                                  return _vm.addDeliverableNoWait(
                                                    column
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row-format align-center font-14 font-gray_70 mt-3 mb-16",
                                        on: {
                                          click: function($event) {
                                            return _vm.addDeliverable(column)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "gray_60" } },
                                          [_vm._v("add")]
                                        ),
                                        _c("div", [_vm._v("Add task")])
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ])
                  }),
                  0
                ),
                _c("div", [
                  !_vm.isCollaborator
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "row-format align-center pointer font-14 font-gray_70 mt-3 ml-2 mr-6",
                          on: {
                            click: function($event) {
                              return _vm.addDeliverableStatus()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "16", color: "gray_70" } },
                            [_vm._v("add")]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "white-space": "nowrap" } },
                            [_vm._v("Add new status")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ]
      ),
      !_vm.isCollaborator
        ? _c("confirm-dialog", {
            attrs: {
              dialog: _vm.deleteColumnDialog,
              "heading-text": "Confirm",
              "yes-text": "Delete",
              "no-text": "Cancel",
              "body-text": _vm.$t("projects.delete-column")
            },
            on: {
              close: _vm.cancelDeleteColumn,
              confirm: function($event) {
                return _vm.deleteColumn()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }