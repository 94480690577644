var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ourCall
    ? _c("div", { staticClass: "text-left" }, [
        _c("div", { staticClass: "row-format" }, [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("back")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { size: "24", color: "black" } }, [
                    _vm._v("arrow_back")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ml-auto row-format align-center" },
            [
              _vm.ourFolder !== "Inbox"
                ? _c("div", { staticClass: "mr-2" }, [
                    _vm._v(" " + _vm._s(_vm.ourFolder) + " "),
                    _vm.ourFolder === "Snoozed"
                      ? _c("span", [
                          _vm._v(
                            "until " +
                              _vm._s(
                                _vm.HDateTime.fromISO(
                                  _vm.ourCall.snoozedUntil
                                ).toLocaleString(_vm.HDateTime.DATETIME_MED)
                              )
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm.ourFolder && _vm.ourCall
                ? _c("inbox-actions", {
                    attrs: {
                      size: 24,
                      unread: _vm.ourCall.unread,
                      "snooze-options": _vm.snoozeOptions,
                      folder: _vm.ourFolder,
                      "item-type": "Call",
                      "item-id": _vm.ourCall.id
                    },
                    on: {
                      "return-to-inbox": function($event) {
                        return _vm.returnToInbox()
                      },
                      snooze: function($event) {
                        return _vm.snooze($event)
                      },
                      archive: function($event) {
                        return _vm.archive()
                      },
                      unread: function($event) {
                        return _vm.markCallAsRead()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "show-scrollbar pa-2",
            staticStyle: {
              height: "calc(var(--vh) - 135px)",
              "overflow-y": "scroll"
            }
          },
          [
            _c("div", { staticClass: "row-format mt-3" }, [
              !_vm.clientId
                ? _c(
                    "div",
                    [
                      _vm.client
                        ? _c("client-avatar", {
                            attrs: { client: _vm.client, small: "" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  class:
                    "text-truncate " +
                    (_vm.client && !_vm.clientId ? "ml-3" : "")
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.contact ? _vm.contact.fullName : _vm.formattedPhone
                      ) +
                      " "
                  )
                ]
              ),
              _c("div", { staticClass: "ml-auto" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.HDateTime.fromISO(
                        _vm.ourCall.startTime
                      ).toLocaleString(_vm.HDateTime.DATETIME_MED)
                    ) +
                    " "
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "column-format mt-3",
                staticStyle: { gap: "8px" }
              },
              [
                _c("div", [_vm._v(_vm._s(_vm.ourCall.direction) + " call")]),
                _vm.ourCall.direction === "Inbound"
                  ? _c("div", [
                      _vm._v(" From: "),
                      _c(
                        "span",
                        {
                          staticClass: "pointer font-primary",
                          on: {
                            click: function($event) {
                              return _vm.dial(_vm.ourCall.from)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.ourCall.from))]
                      )
                    ])
                  : _vm._e(),
                _vm.ourCall.direction === "Outbound"
                  ? _c("div", [
                      _vm._v(" To: "),
                      _c(
                        "span",
                        {
                          staticClass: "pointer font-primary",
                          on: {
                            click: function($event) {
                              return _vm.dial(_vm.ourCall.from)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.ourCall.to))]
                      )
                    ])
                  : _vm._e(),
                _c("div", [_vm._v("Duration: " + _vm._s(_vm.duration))]),
                _c("div", [
                  _vm._v("Call result: " + _vm._s(_vm.ourCall.callResult))
                ]),
                _vm.callRecordingUrl
                  ? _c("div", { staticClass: "row-format centered" }, [
                      _c("audio", {
                        staticStyle: { width: "300px" },
                        attrs: { controls: "", src: _vm.callRecordingUrl }
                      })
                    ])
                  : _vm._e(),
                _vm.ourCall.transcription
                  ? _c("div", { staticClass: "mt-3" }, [
                      _vm._v(" " + _vm._s(_vm.ourCall.transcription.text) + " ")
                    ])
                  : _vm._e()
              ]
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }