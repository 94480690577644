import HttpClient from "@/services/HttpClient";

export default class OpportunityService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(null,true,2);
	}

	getOpportunities(clientId=null) {
		let params = {};

		if(clientId){
			params.clientId = clientId;
		}

		return this.httpClient
			.get(`/pipeline/opportunities`,{ params: params})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateKanbanSettings(updates) {
		return this.httpClient
			.put(`/pipeline/kanban`,updates)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getOpportunity(id) {
		return this.httpClient
			.get(`/pipeline/opportunities/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createOpportunity(opportunity) {
		return this.httpClient
			.post(`/pipeline/opportunities`,opportunity)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateOpportunity(id,opportunity) {
		return this.httpClient
			.put(`/pipeline/opportunities/${id}`,opportunity)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateOpportunityToDo(id,toDoId,complete) {
		return this.httpClient
			.post(`/pipeline/opportunities/${id}/todo/${toDoId}?complete=${complete}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchOpportunity(id,patch) {
		return this.httpClient
			.patch(`/pipeline/opportunities/${id}`,patch)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteOpportunity(id) {
		return this.httpClient
			.delete(`/pipeline/opportunities/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getLinkedWithActivity(itemType,itemId){
		return this.httpClient
			.get(`/pipeline/opportunities/linked?itemType=${itemType}&itemId=${itemId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	linkToActivity(id,itemType,itemId){
		return this.httpClient
			.post(`/pipeline/opportunities/${id}/linked?itemType=${itemType}&itemId=${itemId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	unlinkWithActivity(id,itemType,itemId){
		return this.httpClient
			.delete(`/pipeline/opportunities/${id}/linked?itemType=${itemType}&itemId=${itemId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteAttachment(id, fileName) {
		return this.httpClient
			.delete(`/pipeline/opportunities/${id}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(id, fileName) {
		return this.httpClient
			.get(`/pipeline/opportunities/${id}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


}
