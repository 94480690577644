var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.dialog,
        width: "unset",
        persistent: true,
        "overlay-color": _vm.overlayColor,
        "overlay-opacity": _vm.overlayOpacity,
        fullscreen: _vm.fullscreen,
        "content-class": "basic-modal",
        "retain-focus": _vm.retainFocus,
        "no-click-animation": !_vm.retainFocus
      },
      on: {
        "click:outside": function($event) {
          !_vm.persistent ? _vm.handleClose() : ""
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }