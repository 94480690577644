var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0 ma-0",
      style: "min-height: " + _vm.item.minHeight + "px; " + _vm.background,
      attrs: { fluid: "" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0" },
        _vm._l(_vm.filteredColumns, function(column, colIndex) {
          return _c(
            "v-col",
            {
              key: colIndex,
              staticClass: "pa-0 ma-0 column-format",
              attrs: { cols: "12", md: _vm.cols }
            },
            [
              _c(
                "div",
                {
                  style:
                    "padding-left: " +
                    column.pl +
                    "px; padding-right: " +
                    column.pr +
                    "px; padding-bottom: " +
                    column.pb +
                    "px; padding-top: " +
                    column.pt +
                    "px; "
                },
                [
                  _c(
                    "draggable",
                    {
                      attrs: {
                        list: column.items,
                        group: _vm.item.id,
                        disabled: !_vm.active
                      }
                    },
                    _vm._l(column.items, function(item, subIndex) {
                      return _c(
                        "div",
                        { key: item.id },
                        [
                          _c("item-renderer", {
                            ref: "item",
                            refInFor: true,
                            attrs: {
                              index:
                                _vm.index + "." + colIndex + "." + subIndex,
                              item: item,
                              styles: _vm.styles,
                              "edit-mode": _vm.editMode,
                              "in-container": true,
                              "account-logo": _vm.accountLogo,
                              tokens: _vm.tokens,
                              invoice: _vm.invoice,
                              "token-map": _vm.tokenMap,
                              "in-builder": _vm.inBuilder,
                              "deposit-account": _vm.depositAccount,
                              recurring: _vm.recurring,
                              "payment-plan": _vm.paymentPlan,
                              "invoice-service": _vm.invoiceService,
                              "attachment-service": _vm.attachmentService,
                              "scheduled-payments": _vm.scheduledPayments,
                              active:
                                _vm.active &&
                                item.id === _vm.editItemId &&
                                item.type === "TextBlock"
                            },
                            on: {
                              change: function($event) {
                                return _vm.containerElementUpdated(
                                  colIndex,
                                  $event
                                )
                              },
                              delete: function($event) {
                                return _vm.$emit("delete")
                              },
                              click: function($event) {
                                _vm.active
                                  ? _vm.containerItemClick(
                                      colIndex,
                                      item,
                                      $event
                                    )
                                  : null
                              },
                              input: function($event) {
                                return _vm.$emit("input", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm.inBuilder
                    ? _c(
                        "div",
                        [
                          _vm.active
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": "",
                                    "close-on-click": "",
                                    bottom: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mt-3",
                                                  attrs: {
                                                    small: "",
                                                    color: "white"
                                                  }
                                                },
                                                on
                                              ),
                                              [_vm._v("+ Add element")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pa-2",
                                      staticStyle: {
                                        "background-color":
                                          "var(--v-white-base)"
                                      }
                                    },
                                    _vm._l(_vm.schemaTypes, function(
                                      category,
                                      sIndex
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: sIndex,
                                          staticClass: "mb-2 pb-1",
                                          staticStyle: {
                                            "border-bottom":
                                              "1px solid var(--v-gray_30-base)"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-left" },
                                            [_vm._v(_vm._s(category.label))]
                                          ),
                                          _vm._l(
                                            category.types.filter(function(t) {
                                              return t.allowInContainer
                                            }),
                                            function(schema) {
                                              return _c(
                                                "div",
                                                {
                                                  key: schema.type,
                                                  staticClass:
                                                    "row-format align-center schema-item pa-1 gap-2",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$emit(
                                                        "add-container-element",
                                                        {
                                                          container: _vm.item,
                                                          column: colIndex,
                                                          element: schema
                                                        }
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "material-symbols-rounded",
                                                      attrs: {
                                                        color: "secondary",
                                                        size: "24"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(schema.icon)
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "column-format text-left"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "font-14"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(schema.label)
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-12 mt-n1 font-gray_70"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              schema.description
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }