<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }]">
			Integrations
		</page-header>

		<div class="row-format gap-3">
			<div
				:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
				v-for="page in pages"
				:key="page.value"
				@click.stop="setView(page.value)"
			>
				<div>{{ page.label }}</div>
			</div>
		</div>
		<div style="width: 100%;" class="column-format mt-4">
			<div style="border: 1px solid var(--v-gray_20-base); border-radius: 4px" v-if="currentView === 'calendar'">
				<div class="app-row">
					<div class="app-icon">
						<v-icon size="50">$googleCalendar</v-icon>
						<div class="ml-4">{{ $t('connected-apps.google.name') }}</div>
					</div>
					<div class="app-info">
						<google-calendar></google-calendar>
					</div>
				</div>
				<div class="app-row">
					<div class="app-icon">
						<v-icon size="50">$microsoft</v-icon>
						<div class="ml-4">{{ $t('connected-apps.microsoft.name') }}</div>
					</div>
					<div class="app-info">
						<microsoft-calendar></microsoft-calendar>
					</div>
				</div>
				<div class="app-row">
					<div class="app-icon">
						<v-icon size="50" color="black">$apple</v-icon>
						<div class="ml-4">{{ $t('connected-apps.apple.name') }}</div>
					</div>
					<div class="app-info">
						<apple-calendar></apple-calendar>
					</div>
				</div>
				<div class="app-row">
					<div class="app-icon">
						<v-icon size="50" color="secondary">event</v-icon>
						<div class="ml-4">{{ $t('connected-apps.caldav.name') }}</div>
					</div>
					<div class="app-info">
						<cal-dav-calendar></cal-dav-calendar>
					</div>
				</div>
				<div class="app-row">
					<div class="app-icon">
						<img src="/images/zoom-logo1.png" width="60" style="margin-left: -5px" />
						<div class="ml-3">{{ $t('connected-apps.zoom.name') }}</div>
					</div>
					<div class="app-info">
						<zoom></zoom>
					</div>
				</div>
				<div class="app-row">
					<div class="app-icon">
						<img src="/images/apps/sessions-logo.png" width="60" style="margin-left: -5px" />
						<div class="ml-3">{{ $t('connected-apps.sessions.name') }}</div>
					</div>
					<div class="app-info">
						<sessions></sessions>
					</div>
				</div>
			</div>

			<div style="border: 1px solid var(--v-gray_20-base); border-radius: 4px" v-if="currentView === 'payment' && owner">
				<div class="app-row">
					<div class="app-icon">
						<v-icon size="50">$paypal</v-icon>
						<div class="ml-4">{{ $t('connected-apps.paypal.name') }}</div>
					</div>
					<div class="app-info">
						<pay-pal></pay-pal>
					</div>
				</div>
				<div class="app-row">
					<div class="app-icon">
						<v-icon size="50">$stripeLockup</v-icon>
						<div class="ml-4">{{ $t('connected-apps.stripe.name') }}</div>
					</div>
					<div class="app-info">
						<stripe></stripe>
					</div>
				</div>
			</div>

			<div style="border: 1px solid var(--v-gray_20-base); border-radius: 4px" v-if="currentView === 'accounting'">
				<div class="app-row" v-if="fullUser">
					<div class="app-icon">
						<v-icon color="black" size="50">$plaidLockup</v-icon>
						<div class="ml-4">{{ $t('connected-apps.plaid.name') }}</div>
					</div>
					<div class="app-info">
						<plaid></plaid>
					</div>
				</div>
				<div class="app-row" v-if="owner">
					<div class="app-icon">
						<v-icon size="50">$quickbooks</v-icon>
						<div class="ml-4">{{ $t('connected-apps.quickbooks.name') }}</div>
					</div>
					<div class="app-info">
						<quickbooks></quickbooks>
					</div>
				</div>
				<div class="app-row" v-if="owner">
					<div class="app-icon">
						<img src="/images/xero-logo.png" width="60" style="margin-left: -5px" />
						<div class="mx-3">{{ $t('connected-apps.xero.name') }}</div>
					</div>
					<div class="app-info">
						<xero></xero>
					</div>
				</div>
			</div>

			<div
				style="border: 1px solid var(--v-gray_20-base); border-radius: 4px"
				class="mb-8"
				v-if="currentView === 'integrations'"
			>
				<div class="app-row" v-if="fullUser">
					<div class="app-icon">
						<img src="images/apps/make-logo.svg" alt="Make logo" width="50" />
						<div class="ml-4">{{ $t('connected-apps.make.name') }}</div>
					</div>
					<div class="app-info">
						<public-api type="Make"></public-api>
					</div>
				</div>
				<div class="app-row" v-if="fullUser">
					<div class="app-icon">
						<v-icon size="50">$pabbly</v-icon>
						<div class="ml-4">{{ $t('connected-apps.pabbly.name') }}</div>
					</div>
					<div class="app-info">
						<public-api type="Pabbly"></public-api>
					</div>
				</div>
				<div class="app-row" v-if="fullUser">
					<div class="app-icon">
						<v-icon size="50">$zapier</v-icon>
						<div class="ml-4">{{ $t('connected-apps.zapier.name') }}</div>
					</div>
					<div class="app-info">
						<public-api type="Zapier"></public-api>
					</div>
				</div>
				<div class="app-row" v-if="fullUser">
					<div class="app-icon">
						<v-icon size="50" color="secondary">hub</v-icon>
						<div class="ml-4">{{ $t('connected-apps.custom.name') }}</div>
					</div>
					<div class="app-info">
						<public-api type="Public"></public-api>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import GoogleCalendar from '@/modules/account/apps/GoogleCalendar';
	import Stripe from '@/modules/account/apps/Stripe';
	import Plaid from '@/modules/account/apps/Plaid';
	import Zoom from '@/modules/account/apps/Zoom';
	import AppleCalendar from '@/modules/account/apps/AppleCalendar';
	import PayPal from '@/modules/account/apps/PayPal';
	import Quickbooks from '@/modules/account/apps/Quickbooks';
	import MicrosoftCalendar from '@/modules/account/apps/MicrosoftCalendar';
	import Xero from '@/modules/account/apps/Xero';
	import PublicApi from '@/modules/account/apps/PublicApi';
	import Sessions from '@/modules/account/apps/Sessions';
	import CalDavCalendar from '@/modules/account/apps/CalDavCalendar';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'ConnectedApps',

		props: ['v'],

		components: {
			CalDavCalendar,
			PublicApi,
			Quickbooks,
			PayPal,
			AppleCalendar,
			Plaid,
			Stripe,
			GoogleCalendar,
			Zoom,
			MicrosoftCalendar,
			Xero,
			Sessions,
			PageHeader
		},

		data: function() {
			return {
				currentView: 'calendar',
			};
		},

		mounted() {
			if (this.v) {
				this.setView(this.v);
			}
		},

		beforeDestroy() {},

		methods: {
			setView: function(view) {
				this.currentView = view;
			},
		},

		computed: {
			owner: function() {
				return this.$store.getters.isAccountOwner;
			},

			fullUser: function() {
				return this.$store.getters.isAccountFullUser;
			},

			pages: function() {
				let result = [];
				result.push({ label: 'Calendar & meetings', value: 'calendar' });

				if (this.owner) {
					result.push({ label: 'Payment providers', value: 'payment' });
				}

				if (this.owner || this.fullUser) {
					result.push({ label: 'Accounting', value: 'accounting' });
					result.push({ label: 'Integrations', value: 'integrations' });
				}

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.app-row {
		display: flex;
		flex-direction: row;
		text-align: left;
		align-items: flex-start;
		padding: 24px 12px 24px 24px;

		border-bottom: 1px solid var(--v-gray_20-base);

		&:last-child {
			border: 0px;
		}

		&:hover {
			background-color: var(--v-gray_5-base);
		}

		.app-icon {
			display: flex;
			flex-direction: row;
			align-items: center;
			min-width: 33%;
			padding-right: 12px;
		}

		.app-info {
			min-width: 66%;
		}
	}
</style>
