var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "proposal-builder" } },
    [
      _vm.isReady
        ? _c("header-bar", {
            attrs: {
              proposal: _vm.proposal,
              "need-save": _vm.needSave,
              status: _vm.status,
              saving: _vm.saving,
              "client-mode": false,
              "proposal-service": _vm.proposalService
            },
            on: {
              close: function($event) {
                return _vm.close()
              },
              save: function($event) {
                return _vm.save()
              },
              send: function($event) {
                return _vm.shareSendProposal()
              },
              "return-to-draft": function($event) {
                return _vm.returnToDraft()
              },
              void: function($event) {
                return _vm.voidProposal()
              },
              delete: function($event) {
                return _vm.confirmDeleteProposal()
              },
              "create-project": function($event) {
                _vm.newProjectDialog = true
              },
              "create-template": function($event) {
                return _vm.createTemplate()
              }
            }
          })
        : _vm._e(),
      _vm.showSubheaderButtons
        ? _c(
            "div",
            { staticClass: "subheader-buttons" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary-action mr-1",
                  attrs: { disabled: !_vm.protectedEditMode },
                  on: {
                    click: function($event) {
                      _vm.coverMenuOpen = true
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { size: "20" } }, [
                    _vm._v("$photo")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("proposal.header-bar.cover-button")) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary-action ml-1",
                  attrs: { disabled: !_vm.protectedEditMode },
                  on: {
                    click: function($event) {
                      _vm.textMenuOpen = true
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { size: "20" } }, [
                    _vm._v("$atSymbol")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("proposal.header-bar.font-button")) +
                      " "
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", { staticClass: "my-4" }),
      _vm.isReady
        ? _c("proposal-renderer", {
            attrs: {
              proposal: _vm.proposal,
              client: _vm.client,
              "edit-mode": _vm.protectedEditMode,
              "template-mode": false,
              "proposal-service": _vm.proposalService,
              "client-service": _vm.clientService,
              account: _vm.account,
              "need-save": _vm.needSave,
              saving: _vm.saving,
              "client-mode": false,
              "is-locked": _vm.isLocked,
              "refresh-key": _vm.refreshKey
            },
            on: {
              close: function($event) {
                return _vm.close()
              },
              "need-save": function($event) {
                return _vm.handleNeedSave()
              },
              "force-save": function($event) {
                return _vm.save()
              },
              "send-proposal": function($event) {
                return _vm.shareSendProposal()
              },
              "return-to-draft": function($event) {
                return _vm.returnToDraft()
              },
              "delete-proposal": function($event) {
                return _vm.confirmDeleteProposal()
              },
              "create-project": function($event) {
                _vm.newProjectDialog = true
              },
              "cover-menu-open": function($event) {
                _vm.coverMenuOpen = true
              },
              "create-package-from-deliverables": function($event) {
                return _vm.createPackage($event)
              }
            }
          })
        : _vm._e(),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": _vm.$t("global.confirm"),
          "body-text": _vm.isFullyExecuted
            ? _vm.$t("proposal.delete.confirm-fully-executed")
            : _vm.$t("proposal.delete.confirm")
        },
        on: {
          confirm: function($event) {
            return _vm.deleteProposal()
          },
          close: function($event) {
            return _vm.cancelDeleteProposal()
          }
        }
      }),
      _vm.newProjectDialog
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.newProjectDialog, persistent: true },
              on: {
                close: function($event) {
                  _vm.newProjectDialog = false
                }
              }
            },
            [
              _c("new-project", {
                attrs: {
                  "open-state": _vm.newProjectDialog,
                  client: _vm.client,
                  "proposal-id": _vm.proposal.id
                },
                on: {
                  created: function($event) {
                    return _vm.newProjectCreated($event)
                  },
                  close: function($event) {
                    _vm.newProjectDialog = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isReady && _vm.shareDialog
        ? _c("basic-modal", {
            attrs: {
              dialog: _vm.shareDialog,
              persistent: true,
              "retain-focus": false
            },
            on: {
              close: function($event) {
                _vm.shareDialog = false
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("share-send-widget", {
                        attrs: {
                          proposal: _vm.proposal,
                          "proposal-status": _vm.proposal.proposalStatus,
                          "proposal-service": _vm.proposalService,
                          "client-service": _vm.clientService,
                          "mce-config-text": _vm.mceConfigText
                        },
                        on: {
                          finalize: function($event) {
                            return _vm.activate()
                          },
                          alert: function($event) {
                            return scope.setAlert($event)
                          },
                          cancel: function($event) {
                            _vm.shareDialog = false
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              269668339
            )
          })
        : _vm._e(),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.coverMenuOpen, persistent: true },
          on: {
            close: function($event) {
              _vm.coverMenuOpen = false
            }
          }
        },
        [
          !_vm.textMenuOpen
            ? _c("cover-modal", {
                attrs: {
                  "proposal-service": _vm.proposalService,
                  proposal: _vm.proposal,
                  "header-style": _vm.headerStyle,
                  "body-style": _vm.bodyStyle,
                  "client-name": _vm.clientName,
                  "contractor-name": _vm.contractorName,
                  "open-state": _vm.coverMenuOpen
                },
                on: {
                  cancel: function($event) {
                    _vm.coverMenuOpen = false
                  },
                  save: _vm.handleUpdateFromCoverMenu,
                  "update-account": function($event) {
                    return _vm.setAccount($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.textMenuOpen, persistent: true },
          on: {
            close: function($event) {
              _vm.textMenuOpen = false
            }
          }
        },
        [
          !_vm.coverMenuOpen && _vm.isReady
            ? _c(
                "format-modal",
                _vm._b(
                  {
                    attrs: {
                      "proposal-service": _vm.proposalService,
                      "open-state": _vm.textMenuOpen
                    },
                    on: {
                      close: function($event) {
                        _vm.textMenuOpen = false
                      },
                      save: function($event) {
                        return _vm.handleFormatChange($event)
                      }
                    }
                  },
                  "format-modal",
                  _vm.proposal.formatting,
                  false
                )
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }