<template>
	<div v-if="isReady" class="column-format py-1 pl-2 text-left font-gray_90" :style="style">
		<div class="row-format align-center">
			<client-avatar disable-click v-if="client" x-small :client="client"></client-avatar>
			<div v-else :class="`stopwatch row-format centered`">
				<v-icon size="16" color="white">$stopwatch</v-icon>
			</div>
			<div class="ml-1">{{startLabel}} - {{endLabel}}</div>
		</div>
		<div v-if="timerEvent.projectName" class="mt-1">
			<v-icon size="14" class="mr-1">$projects</v-icon>{{timerEvent.projectName}}
		</div>
		<div v-if="timerEvent.deliverableName">
			<v-icon size="14" class="mr-1">$deliverableBox</v-icon>{{timerEvent.deliverableName}}
		</div>
	</div>
</template>

<script>
	import DateTime from "@/modules/utils/HDateTime";
	import ClientAvatar from "@/components/ClientAvatar";
	export default {
		name: 'TimerEvent',

		props: ['event','view'],

		components: {ClientAvatar},

		data: function () {
			return {
				timerEvent: this.event.original.meta.timerEvent,
				start: null,
				end: null,
				isReady: false,
				client: null,
			};
		},

		mounted() {
			this.start = DateTime.fromISO(this.timerEvent.timerStart);
			this.end = DateTime.fromISO(this.timerEvent.timerEnd);
			this.client = this.$store.getters.clients.find(c => c.id === this.timerEvent.clientId);
			this.isReady = true;
		},

		beforeDestroy() {},

		methods: {},

		computed: {
			style: function(){
				//if(this.view === 'Schedule'){
					return `background-color: var(--v-gray_20-base); height:100%; border-top: 1px solid ${this.borderColor}; border-bottom: 1px solid ${this.borderColor}; border-right: 1px solid ${this.borderColor}; border-left:8px solid ${this.borderColor};  overflow-y: hidden; overflow-x: hidden`
				//}	else{
				//	return `background-color: var(--v-white-base); height:100%; border-left:0px solid ${this.borderColor}; overflow-y: hidden; overflow-x: hidden`
				//}
			},

			startLabel: function(){
				return this.start.toLocaleString(DateTime.TIME_SIMPLE);
			},

			endLabel: function(){
				return this.end.toLocaleString(DateTime.TIME_SIMPLE);
			},

			borderColor: function(){
				if(this.timerEvent.clientId){
					let client = this.$store.getters.getClientById(this.timerEvent.clientId);
					if(client && client.color){
						return client.color;
					}
				}
				return 'var(--v-gray_70-base)'
			}
		},
	};
</script>

<style scoped lang="scss">
.stopwatch {
	background-color: var(--v-gray_60-base);
	width:20px;
	height:20px;
	border-radius: 4px;
}
</style>
