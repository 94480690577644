var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-3",
      staticStyle: { "min-width": "600px", "min-height": "400px" }
    },
    [
      _c(
        "div",
        { staticClass: "row-format gap-1 align-center" },
        [
          _c("v-text-field", {
            ref: "searchForm",
            staticStyle: { "max-width": "200px" },
            attrs: {
              dense: "",
              "hide-details": "",
              outlined: "",
              placeholder: _vm.$t("proposal.cover.image-search-btn"),
              "single-line": ""
            },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchPhotos()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function($event) {
                            return _vm.searchPhotos()
                          }
                        }
                      },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.searchTerm,
              callback: function($$v) {
                _vm.searchTerm = $$v
              },
              expression: "searchTerm"
            }
          }),
          _c("v-select", {
            staticStyle: { "max-width": "150px" },
            attrs: {
              dense: "",
              "hide-details": "",
              outlined: "",
              items: ["Landscape", "Portrait", "Squarish"]
            },
            on: {
              change: function($event) {
                return _vm.searchPhotos()
              }
            },
            model: {
              value: _vm.orientation,
              callback: function($$v) {
                _vm.orientation = $$v
              },
              expression: "orientation"
            }
          }),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("close")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "images-wrapper mt-2" }, [
        _vm.photosReady
          ? _c(
              "div",
              { staticClass: "image-grid" },
              _vm._l(_vm.photoResults, function(result) {
                return _c(
                  "div",
                  {
                    key: result.id,
                    staticClass: "image-container",
                    style: "background-image: url(" + result.urls.thumb + ");",
                    on: {
                      click: function($event) {
                        return _vm.setUnsplashImage(result)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "credit-container" }, [
                      _c(
                        "a",
                        {
                          staticClass: "credit-anchor",
                          attrs: {
                            href: "" + result.user.links.html,
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(result.user.first_name) +
                              " " +
                              _vm._s(result.user.last_name) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _c("div", { staticClass: "font-10 mt-1 text-right" }, [
          _vm._v(" " + _vm._s(_vm.$t("proposal.cover.photos-by")) + " "),
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://unsplash.com/?utm_source=hectic&utm_medium=referral",
                target: "_blank"
              }
            },
            [_vm._v("Unsplash")]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "row-format centered" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action mr-1",
              attrs: { icon: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.previousPage()
                }
              }
            },
            [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$chevronLeft")])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-1",
              attrs: { icon: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.nextPage()
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "20" } }, [_vm._v("$chevronRight")])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }