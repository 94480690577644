<template>
	<v-combobox
		v-model="internalValue"
		:items="filteredEmails"
		:multiple="true"
		:chips="true"
		:deletable-chips="true"
		clearable
		hide-details
		dense
		outlined
		persistent-placeholder
		:label="label"
		@input="onInput"
	>
		<!-- Custom slot for rendering selected chips -->
		<template v-slot:selection="data">
				<v-chip
					small
					color="gray_20" class="mt-1"
					:input-value="data.selected"
					close
					close-icon="clear"
					@click:close="remove(data.item)"
				>
					{{ data.item }}
				</v-chip>
		</template>
	</v-combobox>
</template>

<script>
	export default {
		model: {
			prop: 'value',
			event: 'input',
		},
		props: {
			value: {
				type: Array,
				default: () => [],
			},
			knownEmails: {
				type: Array,
				default: () => [],
			},
			label: {
				type: String,
				default: 'Enter email',
			},
		},
		computed: {
			internalValue: {
				get() {
					return this.value;
				},
				set(val) {
					this.$emit('input', val);
				},
			},
			filteredEmails() {
				return this.knownEmails.filter((email) => !this.internalValue.includes(email));
			},
		},
		methods: {
			onInput(value) {
				if (value.length) {
					const lastInput = value[value.length - 1];
					if (this.isValidEmail(lastInput) || this.internalValue.includes(lastInput)) {
						this.internalValue = value;
					} else {
						this.input.pop();
					}
				}
			},
			remove(item) {
				const index = this.internalValue.indexOf(item);
				if (index !== -1) {
					this.internalValue.splice(index, 1);
					this.$emit('input', this.internalValue); // Emit the updated value to the parent component
				}
			},
			isValidEmail(email) {
				const re = /\S+@\S+\.\S+/;
				return re.test(email);
			},
		},
		watch: {
			value(newValue) {
				this.input = newValue;
			},
		},
	};
</script>
