<template>
	<div
		style="background-color: var(--v-white-base);max-height: var(--vh); overflow-y: auto"
		class="pa-6 fill-all stratos column-format show-scrollbar"
		v-if="isReady"
	>
		<div class="row-format align-center">
			<img :src="`/images/logo/logo-${$vuetify.theme.dark ? 'white' : 'full'}.png`" style="max-width: 100px" />
		</div>
		<div
			class="mt-1 mb-3"
			style="width: 100%; min-height: 4px; height: 4px; background-color: var(--v-gray_30-base); border-radius: 4px"
		>
			<div :style="`height: 100%; width: ${width}%; background-color: var(--v-accent-base)`"></div>
		</div>
		<div style="flex-grow: 1">
			<user-info-capture
				v-if="stage === 'UserInfo'"
				:user-info="userInfo"
				@next="userInfoCaptured($event)"
			></user-info-capture>
			<intentions
				v-if="stage === 'Intention'"
				:intention="userInfo.intention"
				@next="intentionCaptured($event)"
				@back="previousStage()"
			></intentions>
			<carousel
				v-if="stage === 'Carousel'"
				:initial-index="carouselIndex"
				:user-info="userInfo"
				@back="previousStage()"
				@next="carouselComplete()"
				@current-index="setCarouselIndex($event)"
			></carousel>
			<lets-go
				v-if="stage === 'LetsGo'"
				:user-info="userInfo"
				@back="previousStage()"
				@next="onBoardingComplete($event)"
			></lets-go>
		</div>
	</div>
</template>

<script>
	import UserInfoCapture from '@/modules/welcome/UserInfoCapture';
	import Intentions from '@/modules/welcome/Intentions';
	import Carousel from '@/modules/welcome/Carousel';
	import carousel from './carousel/carousel.json';
	import LetsGo from '@/modules/welcome/LetsGo';
	import AccountService from '@/modules/account/AccountService';
	import AuthService from '@/modules/auth/AuthService';
	import DefaultTemplate from '@/modules/templates/invoices/DefaultTemplate.json';
	import InvoiceTemplateService from '@/modules/templates/invoices/InvoiceTemplateService';
	import CustomDomainService from '@/modules/portal/CustomDomainService';

	export default {
		name: 'Welcome',

		props: [],

		components: { Carousel, Intentions, UserInfoCapture, LetsGo },

		data: function() {
			return {
				accountService: new AccountService(),
				stage: 'UserInfo',
				stages: ['UserInfo', 'Intention', 'Carousel', 'LetsGo'],
				carouselIndex: 0,
				carousel: carousel,
				useSampleData: false,
				isReady: false,

				userInfo: {
					firstName: this.$store.state.loggedInUser.firstName,
					lastName: this.$store.state.loggedInUser.lastName,
					businessName: null,
					currency: 'USD',
					country: 'US',
					industry: null,
					otherIndustry: null,
					years: null,
					intention: null,
				},
			};
		},

		mounted() {
			this.$store.state.inWelcomeFlow = true;
			this.getAccount();

			const now = new Date();
			const isBFDeal = now >= new Date(now.getFullYear(), 10, 25) && now <= new Date(now.getFullYear(), 11, 6);
			if(isBFDeal) {
				localStorage.setItem('isBFDeal', 'true');
				window.Intercom('update', {
					promotion: 'Black Friday 2024'
				});
			}
		},

		beforeDestroy() {
			this.$store.state.inWelcomeFlow = false;
		},

		methods: {
			getAccount: function() {
				this.accountService
					.getAccount()
					.then((res) => {
						this.account = res.data;
						this.isReady = true;
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			setCarouselIndex: function(val) {
				this.carouselIndex = val;
			},

			userInfoCaptured: function(userInfo) {
				Object.assign(this.userInfo, userInfo);
				this.nextStage();
			},

			intentionCaptured: function(intention) {
				this.userInfo.intention = intention;
				this.nextStage();
			},

			carouselComplete: function() {
				this.nextStage();
			},

			nextStage: function() {
				let ix = this.stages.indexOf(this.stage);
				if (ix < this.stages.length - 1) {
					this.stage = this.stages[ix + 1];
				}
			},

			previousStage: function() {
				let ix = this.stages.indexOf(this.stage);
				if (ix > 0) {
					this.stage = this.stages[ix - 1];
				}
			},

			onBoardingComplete: function(useSampleData = false) {
				this.$store.commit('startLoading');
				this.trackDemographics();
				let userUpdate = this.updateUserName();
				let acctUpdate = this.updateAccountName();
				let templates = this.createDefaultTemplates();
				Promise.all([userUpdate, acctUpdate, templates]).then(() => {
					new CustomDomainService()
						.getCustomDomains()
						.then(() => {
							console.log('done provisioning domains');
						})
						.catch((err) => console.log(err))
						.finally(() => {
							if (useSampleData) {
								this.accountService
									.createSampleData()
									.catch((err) => console.log(err))
									.finally(() => {
										this.finishedSetup();
									});
							} else {
								this.finishedSetup();
							}
						});
				});
			},

			createDefaultTemplates: function() {
				return new Promise((resolve) => {
					new InvoiceTemplateService().createInvoiceTemplate(DefaultTemplate).finally(() => resolve());
				});
			},

			finishedSetup: function() {
				AuthService.refreshToken(true)
					.then((res) => {
						this.$store.commit('logIn', res);

						AuthService.getProfile()
							.then((res) => {
								this.$store.dispatch('setLoggedInUser', res);
								this.$store.commit('stopLoading');
								setTimeout(() => this.$router.push('/getting-started'), 500);
							})
							.catch(() => (document.location.href = '/getting-started'));
					})
					.catch(() => (document.location.href = '/getting-started'));
			},

			updateAccountName: function() {
				if (this.userInfo.businessName) {
					this.account.accountName = this.userInfo.businessName;
				}

				this.account.currency = this.userInfo.currency;
				this.account.country = this.userInfo.country;
				this.account.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				return this.accountService.updateAccount(this.account);
			},

			updateUserName: function() {
				let user = JSON.parse(JSON.stringify(this.$store.state.loggedInUser));
				user.firstName = this.userInfo.firstName;
				user.lastName = this.userInfo.lastName;
				return this.accountService.updateUserProfile(user);
			},

			trackDemographics: function() {
				let industry = this.userInfo.industry + (this.userInfo.otherIndustry ? ` - ${this.userInfo.otherIndustry}` : '');

				if (this.userInfo.industry) {
					this.$track.recordServerSide('industry', { industry: industry });
				}

				if (this.userInfo.years) {
					this.$track.recordServerSide('years-of-experience', { years: this.userInfo.years });
				}

				if (this.userInfo.intention) {
					this.$track.recordServerSide('intention', { goal: this.userInfo.intention });
				}

				this.$track.captureDemographics({
					firstName: this.userInfo.firstName,
					lastName: this.userInfo.lastName,
					industry: industry,
					years: this.userInfo.years,
					intention: this.userInfo.intention,
				});

				window.Intercom('update', {
					name: this.userInfo.firstName + ' ' + this.userInfo.lastName,
					industry: industry ? industry : 'n/a',
					experience: this.userInfo.years ? this.userInfo.years : 'n/a',
					intention: this.userInfo.intention ? this.userInfo.intention : 'n/a',
				});
			},
		},

		computed: {
			activeIntention: function() {
				return this.carousel.find((c) => c.id === this.userInfo.intention);
			},

			width: function() {
				if (this.stage === 'UserInfo') {
					return 0;
				} else if (this.stage === 'Intention') {
					return 15;
				} else if (this.stage === 'Carousel') {
					let totalSlides = this.activeIntention.slides.length;
					let result = (100 / (totalSlides + 2)) * (this.carouselIndex + 1) + 15;
					return result;
				} else {
					return 100;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
