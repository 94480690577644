var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("welcome-container", {
    scopedSlots: _vm._u([
      {
        key: "left",
        fn: function() {
          return [
            _c("div", { staticClass: "column-format fill-all" }, [
              _c(
                "div",
                { staticClass: "column-format flex-grow-1 centered pl-4 pr-8" },
                [
                  _c(
                    "div",
                    { staticClass: "text-left pr-8 column-format gap-5" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "stratos-medium",
                          staticStyle: {
                            "font-size": "56px",
                            "line-height": "64px"
                          }
                        },
                        [_vm._v("Let's launch your Moxie experience!")]
                      ),
                      _c("div", { staticClass: "font-20 stratos-semilight" }, [
                        _vm._v(
                          "First, how do you like to explore? Would you prefer to explore Moxie with sample clients or do you want to dive right in and add your own records?"
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "column-format gap-4 fill-width pr-8 mt-8" },
                    [
                      _c(
                        "div",
                        {
                          class:
                            "option " +
                            (_vm.selectedOption === "Sample" ? "selected" : ""),
                          on: {
                            click: function($event) {
                              _vm.selectedOption = "Sample"
                            }
                          }
                        },
                        [
                          _c("div", [_vm._v("Yes, load up sample data")]),
                          _vm._v(" "),
                          _vm.selectedOption === "Sample"
                            ? _c(
                                "v-icon",
                                { attrs: { size: "20", color: "pop" } },
                                [_vm._v("$check")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class:
                            "option " +
                            (_vm.selectedOption === "Blank" ? "selected" : ""),
                          on: {
                            click: function($event) {
                              _vm.selectedOption = "Blank"
                            }
                          }
                        },
                        [
                          _c("div", [_vm._v("No, I'll explore on my own")]),
                          _vm._v(" "),
                          _vm.selectedOption === "Blank"
                            ? _c(
                                "v-icon",
                                { attrs: { size: "20", color: "pop" } },
                                [_vm._v("$check")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "gap-2 row-format mt-auto mb-6",
                  staticStyle: { "flex-wrap": "wrap" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        outlined: "",
                        color: "gray_50",
                        width: "50"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("back")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "gray_70" } }, [
                        _vm._v("arrow_back")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        color: "primary",
                        disabled: !_vm.selectedOption,
                        width: "240"
                      },
                      on: {
                        click: function($event) {
                          return _vm.letsGo()
                        }
                      }
                    },
                    [
                      _c("span", [_vm._v("Let's go!")]),
                      _vm._v(" "),
                      _c("v-icon", { staticClass: "ml-2" }, [
                        _vm._v("arrow_forward")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "right",
        fn: function() {
          return [
            _c("div", {
              staticClass: "row-format centered fill-all pa-12",
              staticStyle: {
                "background-image": "url('/images/onboarding/letsgo2.png')",
                "background-size": "contain",
                "background-position": "center",
                "background-origin": "content-box"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }