var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", { staticClass: "column-format gap-2" }, [
        _c(
          "div",
          { staticClass: "column-format gap-2" },
          [
            _c("font-selector", {
              staticStyle: { flex: "1 1 0" },
              attrs: { font: _vm.fontFamily, label: "Font family" },
              on: {
                "font-changed": function($event) {
                  return _vm.handleFontChange($event)
                }
              }
            }),
            _c(
              "div",
              { staticClass: "row-format gap-2" },
              [
                _c("v-select", {
                  staticStyle: { flex: "1 1 0" },
                  attrs: {
                    items: _vm.fontSizes,
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    label: "Size"
                  },
                  on: {
                    change: function($event) {
                      return _vm.updated()
                    }
                  },
                  model: {
                    value: _vm.fontSize,
                    callback: function($$v) {
                      _vm.fontSize = $$v
                    },
                    expression: "fontSize"
                  }
                }),
                _c("v-select", {
                  staticStyle: { flex: "1 1 0" },
                  attrs: {
                    items: _vm.fontWeights,
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    label: "Weight"
                  },
                  on: {
                    change: function($event) {
                      return _vm.updated()
                    }
                  },
                  model: {
                    value: _vm.fontWeight,
                    callback: function($$v) {
                      _vm.fontWeight = $$v
                    },
                    expression: "fontWeight"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pr-5" },
          [
            _c("v-slider", {
              attrs: {
                min: "0",
                max: "5",
                step: "0.1",
                "hide-details": "",
                dense: "",
                label: "Line height",
                "thumb-label": true
              },
              on: {
                change: function($event) {
                  return _vm.updated()
                }
              },
              model: {
                value: _vm.lineHeight,
                callback: function($$v) {
                  _vm.lineHeight = $$v
                },
                expression: "lineHeight"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row-format gap-4 align-center" },
          [
            _c("color-selector", {
              attrs: { label: "Font color" },
              on: {
                input: function($event) {
                  return _vm.updated()
                }
              },
              model: {
                value: _vm.color,
                callback: function($$v) {
                  _vm.color = $$v
                },
                expression: "color"
              }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }