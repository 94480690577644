var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.type,
            expression: "type"
          }
        ],
        ref: "type",
        attrs: { type: "hidden", "df-type": "" },
        domProps: { value: _vm.type },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.type = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.due_in,
            expression: "due_in"
          }
        ],
        ref: "due_in",
        attrs: { type: "hidden", "df-due_in": "" },
        domProps: { value: _vm.due_in },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.due_in = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.late_fee,
            expression: "late_fee"
          }
        ],
        ref: "late_fee",
        attrs: { type: "hidden", "df-late_fee": "" },
        domProps: { value: _vm.late_fee },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.late_fee = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.deposit_amount,
            expression: "deposit_amount"
          }
        ],
        ref: "deposit_amount",
        attrs: { type: "hidden", "df-deposit_amount": "" },
        domProps: { value: _vm.deposit_amount },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.deposit_amount = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.deposit_desc,
            expression: "deposit_desc"
          }
        ],
        ref: "deposit_desc",
        attrs: { type: "hidden", "df-deposit_desc": "" },
        domProps: { value: _vm.deposit_desc },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.deposit_desc = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.deposit_tax,
            expression: "deposit_tax"
          }
        ],
        ref: "deposit_tax",
        attrs: { type: "hidden", "df-deposit_tax": "" },
        domProps: { value: _vm.deposit_tax },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.deposit_tax = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.line_items,
            expression: "line_items"
          }
        ],
        ref: "line_items",
        attrs: { type: "hidden", "df-line_items": "" },
        domProps: { value: _vm.line_items },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.line_items = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.template_id,
            expression: "template_id"
          }
        ],
        ref: "template_id",
        attrs: { type: "hidden", "df-template_id": "" },
        domProps: { value: _vm.template_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.template_id = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.to_list,
            expression: "to_list"
          }
        ],
        ref: "to_list",
        attrs: { type: "hidden", "df-to_list": "" },
        domProps: { value: _vm.to_list },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.to_list = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.cc_list,
            expression: "cc_list"
          }
        ],
        ref: "cc_list",
        attrs: { type: "hidden", "df-cc_list": "" },
        domProps: { value: _vm.cc_list },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.cc_list = $event.target.value
          }
        }
      }),
      _c("node-label", {
        on: {
          open: function($event) {
            return _vm.openModal()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded mr-1",
                    attrs: { color: "black", size: "20" }
                  },
                  [_vm._v("request_quote")]
                ),
                _c("div", [_vm._v("Send invoice")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("node-modal", {
        ref: "nodeModal",
        attrs: { title: "Send invoice" },
        on: { save: _vm.handleSave },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.isOpen
                  ? _c(
                      "div",
                      {
                        staticClass: "column-format gap-2 text-left",
                        staticStyle: { width: "550px" }
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            "hide-details": "",
                            dense: "",
                            outlined: "",
                            "persistent-placeholder": "",
                            label: "Type",
                            items: _vm.types,
                            "item-value": "value",
                            "item-text": "label"
                          },
                          model: {
                            value: _vm.type,
                            callback: function($$v) {
                              _vm.type = $$v
                            },
                            expression: "type"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "row-format gap-2" },
                          [
                            _c("v-select", {
                              staticStyle: { width: "50%" },
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                                label: _vm.$t("proposal.terms.payment-terms"),
                                items: _vm.dayOptions,
                                "item-value": "value",
                                "item-text": "label"
                              },
                              model: {
                                value: _vm.due_in,
                                callback: function($$v) {
                                  _vm.due_in = $$v
                                },
                                expression: "due_in"
                              }
                            }),
                            _c("v-text-field", {
                              staticStyle: { width: "50%" },
                              attrs: {
                                suffix: "% / mo.",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: _vm.$t("proposal.terms.late-payment"),
                                type: "number"
                              },
                              model: {
                                value: _vm.late_fee,
                                callback: function($$v) {
                                  _vm.late_fee = $$v
                                },
                                expression: "late_fee"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.type === "FixedAmount"
                          ? _c(
                              "div",
                              { staticClass: "column-format gap-2" },
                              [
                                _vm._l(_vm.lineItems, function(item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticClass: "py-2",
                                      staticStyle: {
                                        "border-bottom":
                                          "1px solid var(--v-gray_30-base)"
                                      }
                                    },
                                    [
                                      _c("per-item-pricing", {
                                        attrs: {
                                          "hide-icon": false,
                                          minimal: false,
                                          product: item.product,
                                          quantity: item.quantity,
                                          immediate: item.immediate,
                                          editable: true
                                        },
                                        on: {
                                          deleted: function($event) {
                                            return _vm.handleDeleted(item)
                                          },
                                          "product-selected": function($event) {
                                            return _vm.handleProductSelected(
                                              item,
                                              $event
                                            )
                                          },
                                          "quantity-updated": function($event) {
                                            return _vm.handleQuantityUpdated(
                                              item,
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "" },
                                    on: { click: _vm.addNewLineItem }
                                  },
                                  [_vm._v("+Add line item")]
                                )
                              ],
                              2
                            )
                          : _c(
                              "div",
                              { staticClass: "column-format gap-2 mb-4" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row-format gap-2" },
                                  [
                                    _c("v-text-field", {
                                      staticStyle: { width: "50%" },
                                      attrs: {
                                        "hide-details": "",
                                        "persistent-placeholder": "",
                                        dense: "",
                                        outlined: "",
                                        label: "Deposit amount"
                                      },
                                      model: {
                                        value: _vm.deposit_amount,
                                        callback: function($$v) {
                                          _vm.deposit_amount = $$v
                                        },
                                        expression: "deposit_amount"
                                      }
                                    }),
                                    _c("v-text-field", {
                                      staticStyle: { width: "50%" },
                                      attrs: {
                                        "hide-details": "",
                                        "persistent-placeholder": "",
                                        dense: "",
                                        outlined: "",
                                        label: "Deposit description"
                                      },
                                      model: {
                                        value: _vm.deposit_desc,
                                        callback: function($$v) {
                                          _vm.deposit_desc = $$v
                                        },
                                        expression: "deposit_desc"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("v-select", {
                                  attrs: {
                                    "hide-details": "",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    label: "Deposit taxable",
                                    items: ["Yes", "No"],
                                    "item-text": "label"
                                  },
                                  model: {
                                    value: _vm.deposit_tax,
                                    callback: function($$v) {
                                      _vm.deposit_tax = $$v
                                    },
                                    expression: "deposit_tax"
                                  }
                                })
                              ],
                              1
                            ),
                        _c("email-selector", {
                          attrs: {
                            to_list: _vm.toList,
                            cc_list: _vm.ccList,
                            template_id: _vm.template_id
                          },
                          on: {
                            "cc-list": function($event) {
                              return _vm.updateCcList($event)
                            },
                            "to-list": function($event) {
                              return _vm.updateToList($event)
                            },
                            "selected-template": function($event) {
                              return _vm.updateSelectedTemplate($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }