var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("basic-modal", { attrs: { dialog: _vm.dialog } }, [
    _c("div", { staticStyle: { "min-width": "500px" } }, [
      _c("div", { staticClass: "pa-3" }, [
        _c(
          "div",
          { staticClass: "row-format" },
          [
            _c("div", [_vm._v("✨Email Assistant")]),
            _c(
              "v-btn",
              {
                staticClass: "ml-auto",
                attrs: { icon: "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("cancel")
                  }
                }
              },
              [_c("v-icon", [_vm._v("$close")])],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-format gap-2" },
          [
            _c(
              "div",
              { staticClass: "row-format gap-2" },
              [
                _c("v-select", {
                  attrs: {
                    items: _vm.toneOptions,
                    "hide-details": "",
                    dense: "",
                    "persistent-placeholder": "",
                    outlined: "",
                    label: "Tone"
                  },
                  model: {
                    value: _vm.tone,
                    callback: function($$v) {
                      _vm.tone = $$v
                    },
                    expression: "tone"
                  }
                }),
                _vm.mode === "rewrite"
                  ? _c("v-select", {
                      attrs: {
                        items: _vm.rewriteOptions,
                        "hide-details": "",
                        dense: "",
                        "persistent-placeholder": "",
                        outlined: "",
                        label: "Length"
                      },
                      model: {
                        value: _vm.rewrite,
                        callback: function($$v) {
                          _vm.rewrite = $$v
                        },
                        expression: "rewrite"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("v-textarea", {
              attrs: {
                "hide-details": "",
                dense: "",
                "persistent-placeholde": "",
                outlined: "",
                autofocus: "",
                placeholder: _vm.instructionLabel
              },
              model: {
                value: _vm.input,
                callback: function($$v) {
                  _vm.input = $$v
                },
                expression: "input"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "pt-4 pb-2",
          staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { width: "200" },
              on: { click: _vm.letsGo }
            },
            [_vm._v("OK")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }