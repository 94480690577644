<template>
	<div>
		<v-combobox
			ref="EmailInput"
			class="mt-1 email-input"
			v-model="to"
			:items="suppressContacts ? [] : contacts"
			hide-details
			dense
			:outlined="!underlined"
			:label="`${label}:`"
			placeholder="Enter email or search"
			:multiple="true"
			small-chips
			@change="$emit('update',to)"
			:filter="isMatch"
		>
		</v-combobox>
	</div>
</template>

<script>
	export default {
		name: 'EmailContactSelector',

		props: ['toList','label','suppressContacts','underlined','clientId'],

		components: {},

		data: function () {
			return {
				to: [... this.toList]
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			isMatch: function (val1, val2) {
				if(val1 && val2){
					return val1.trim().toLowerCase().includes(val2.trim().toLowerCase());
				}else{
					return false
				}
			},

			validateEmail: function(email){
				let re = /\S+@\S+\.\S+/;
				return re.test(email);
			},
		},

		computed: {
			contacts: function () {
				let result = [];
				let contacts = [];

				if(this.clientId){
					contacts.push(... this.$store.getters.communicatorContacts.filter(c => c.clientId === this.clientId));
				}else{
					contacts.push(... this.$store.getters.communicatorContacts);
				}

				for(let i=0; i < contacts.length; i++){
					let c = contacts[i];
					if(c.email){
						result.push((c.fullName ? ('"' + c.fullName + '"') : '') + " <" + c.email + ">");
					}
					for(let j=0; j < c.customValues.length; j++){
						let cv = c.customValues[j];
						if(cv.type === 'Email' && cv.value){
							result.push((c.fullName ? ('"' + c.fullName + '"') : '') + " <" + cv.value + ">");
						}
					}
				}

				result.push(... this.$store.state.usersForAccount.map(u =>  '"' + u.firstName + ' ' + u.lastName + '"' + " <" + u.email + ">"));

				result.sort((a,b) => a.localeCompare(b));

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.phone-input {
		::v-deep .v-select__selections {
			padding: 4px !important;
		}

		::v-deep .v-chip {
			margin: 2px !important;
		}
	}
</style>
