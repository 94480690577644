var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "font-14 availability" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-autocomplete", {
                staticClass: "mb-4",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Schedule timezone",
                  items: _vm.timezones
                },
                model: {
                  value: _vm.meeting.availability.schedule.timezone,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting.availability.schedule, "timezone", $$v)
                  },
                  expression: "meeting.availability.schedule.timezone"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "div",
              {
                staticClass: "px-3 py-1",
                staticStyle: {
                  border: "1px solid var(--v-gray_30-base)",
                  "border-radius": "4px"
                }
              },
              [
                _c("div", { staticClass: "text-left mb-4 mt-2 brand-medium" }, [
                  _vm._v("Weekly schedule")
                ]),
                _c("period-configuration", {
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)",
                    "margin-top": "-14px"
                  },
                  attrs: {
                    label: "Sun",
                    periods: _vm.meeting.availability.schedule.sunday
                  }
                }),
                _c("period-configuration", {
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  },
                  attrs: {
                    label: "Mon",
                    periods: _vm.meeting.availability.schedule.monday
                  }
                }),
                _c("period-configuration", {
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  },
                  attrs: {
                    label: "Tue",
                    periods: _vm.meeting.availability.schedule.tuesday
                  }
                }),
                _c("period-configuration", {
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  },
                  attrs: {
                    label: "Wed",
                    periods: _vm.meeting.availability.schedule.wednesday
                  }
                }),
                _c("period-configuration", {
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  },
                  attrs: {
                    label: "Thu",
                    periods: _vm.meeting.availability.schedule.thursday
                  }
                }),
                _c("period-configuration", {
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  },
                  attrs: {
                    label: "Fri",
                    periods: _vm.meeting.availability.schedule.friday
                  }
                }),
                _c("period-configuration", {
                  attrs: {
                    label: "Sat",
                    periods: _vm.meeting.availability.schedule.saturday
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.mappedCalendars.length
            ? _c("v-col", { staticClass: "mt-4", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "px-4 py-3",
                    staticStyle: {
                      border: "1px solid var(--v-gray_30-base)",
                      "border-radius": "4px"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row-format pointer",
                        on: {
                          click: function($event) {
                            _vm.showConnectedUsers = !_vm.showConnectedUsers
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "text-left brand-medium" }, [
                          _vm._v(
                            "Check availability against connected calendar"
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "ml-auto" },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.showConnectedUsers
                                    ? "$chevronUp"
                                    : "$chevronDown"
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    ),
                    _vm.showConnectedUsers
                      ? _c(
                          "div",
                          { staticClass: "mt-2" },
                          _vm._l(_vm.mappedCalendars, function(user) {
                            return _c("v-checkbox", {
                              key: user.value,
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                value: user.value
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row-format align-center"
                                          },
                                          [
                                            user.type === "Google"
                                              ? _c("v-icon", [
                                                  _vm._v("$googleCalendar")
                                                ])
                                              : _vm._e(),
                                            user.type === "Apple"
                                              ? _c("v-icon", [_vm._v("$apple")])
                                              : _vm._e(),
                                            user.type === "CalDav"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "secondary"
                                                    }
                                                  },
                                                  [_vm._v("event")]
                                                )
                                              : _vm._e(),
                                            user.type === "Microsoft"
                                              ? _c("v-icon", [
                                                  _vm._v("$microsoft")
                                                ])
                                              : _vm._e(),
                                            _c("div", { staticClass: "ml-2" }, [
                                              _vm._v(_vm._s(user.text))
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.checkAvailabilityUsers,
                                callback: function($$v) {
                                  _vm.checkAvailabilityUsers = $$v
                                },
                                expression: "checkAvailabilityUsers"
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "mt-4", attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                staticClass: "px-4 py-3",
                staticStyle: {
                  border: "1px solid var(--v-gray_30-base)",
                  "border-radius": "4px"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "row-format pointer",
                    on: {
                      click: function($event) {
                        _vm.showAdvanced = !_vm.showAdvanced
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "brand-medium" }, [
                      _vm._v("Advanced")
                    ]),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.showAdvanced ? "$chevronUp" : "$chevronDown"
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _vm.showAdvanced
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c("div", { staticClass: "text-left" }, [
                          _vm._v("Invitees can schedule...")
                        ]),
                        _c(
                          "v-radio-group",
                          {
                            staticClass: "mt-1",
                            model: {
                              value: _vm.meeting.availability.limitDaysInFuture,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.meeting.availability,
                                  "limitDaysInFuture",
                                  $$v
                                )
                              },
                              expression:
                                "meeting.availability.limitDaysInFuture"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "row-format align-center" },
                              [
                                _c("v-radio", { attrs: { value: true } }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "mb-2",
                                    staticStyle: { width: "50px" }
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        disabled: !_vm.meeting.availability
                                          .limitDaysInFuture,
                                        "persistent-placeholder": "",
                                        "hide-details": "",
                                        outlined: "",
                                        dense: ""
                                      },
                                      model: {
                                        value:
                                          _vm.meeting.availability
                                            .maxDaysInFuture,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.meeting.availability,
                                            "maxDaysInFuture",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "meeting.availability.maxDaysInFuture"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mb-2 ml-2 font-gray_80 font-14 brand-semilight pointer",
                                    on: {
                                      click: function($event) {
                                        _vm.meeting.availability.limitDaysInFuture = true
                                      }
                                    }
                                  },
                                  [_vm._v(" Days in future ")]
                                )
                              ],
                              1
                            ),
                            _c("v-radio", {
                              attrs: { value: false },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-gray_80 font-14 brand-semilight"
                                          },
                                          [_vm._v("Don't limit")]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1321157399
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "column-format gap-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "row-format gap-3" },
                              [
                                _c("v-select", {
                                  staticStyle: { flex: "1" },
                                  attrs: {
                                    "persistent-placeholder": "",
                                    "hide-details": "",
                                    dense: "",
                                    outlined: "",
                                    label: "Max bookings per day",
                                    items: _vm.maxMeetings
                                  },
                                  model: {
                                    value:
                                      _vm.meeting.availability.maxEventsPerDay,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.meeting.availability,
                                        "maxEventsPerDay",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "meeting.availability.maxEventsPerDay"
                                  }
                                }),
                                _c("v-select", {
                                  staticStyle: { flex: "1" },
                                  attrs: {
                                    "persistent-placeholder": "",
                                    "hide-details": "",
                                    dense: "",
                                    outlined: "",
                                    label: "Max bookings per week",
                                    items: _vm.maxMeetings
                                  },
                                  model: {
                                    value:
                                      _vm.meeting.availability.maxEventsPerWeek,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.meeting.availability,
                                        "maxEventsPerWeek",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "meeting.availability.maxEventsPerWeek"
                                  }
                                }),
                                _c("v-select", {
                                  staticStyle: { flex: "1" },
                                  attrs: {
                                    "persistent-placeholder": "",
                                    "hide-details": "",
                                    dense: "",
                                    outlined: "",
                                    label: "Max bookings per month",
                                    items: _vm.maxMeetings
                                  },
                                  model: {
                                    value:
                                      _vm.meeting.availability
                                        .maxEventsPerMonth,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.meeting.availability,
                                        "maxEventsPerMonth",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "meeting.availability.maxEventsPerMonth"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "row-format gap-3" },
                              [
                                _c("duration-selector", {
                                  staticClass: "flex-grow-1",
                                  attrs: {
                                    label: "Block time before meeting",
                                    options: _vm.bufferOptions
                                  },
                                  model: {
                                    value:
                                      _vm.meeting.availability.bufferBefore,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.meeting.availability,
                                        "bufferBefore",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "meeting.availability.bufferBefore"
                                  }
                                }),
                                _c("duration-selector", {
                                  staticClass: "flex-grow-1",
                                  attrs: {
                                    label: "Block time after meeting",
                                    options: _vm.bufferOptions
                                  },
                                  model: {
                                    value: _vm.meeting.availability.bufferAfter,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.meeting.availability,
                                        "bufferAfter",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "meeting.availability.bufferAfter"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("duration-selector", {
                              attrs: {
                                label: "Start time increments",
                                options: _vm.startIncrements
                              },
                              model: {
                                value: _vm.meeting.availability.startIncrement,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.meeting.availability,
                                    "startIncrement",
                                    $$v
                                  )
                                },
                                expression:
                                  "meeting.availability.startIncrement"
                              }
                            }),
                            _c("date-selector", {
                              attrs: {
                                standard: true,
                                label: "Don't accept meetings before",
                                date: _vm.meeting.availability.startDate
                              },
                              on: {
                                change: function($event) {
                                  _vm.meeting.availability.startDate = $event
                                }
                              }
                            }),
                            _c("date-selector", {
                              attrs: {
                                standard: true,
                                label: "Stop accepting meetings after",
                                date: _vm.meeting.availability.endDate
                              },
                              on: {
                                change: function($event) {
                                  _vm.meeting.availability.endDate = $event
                                }
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "row-format gap-3" },
                              [
                                _c("duration-selector", {
                                  staticClass: "flex-grow-1",
                                  attrs: {
                                    label:
                                      "How close to start time can invitees schedule?",
                                    options: _vm.minimumIncrements,
                                    units: _vm.units
                                  },
                                  model: {
                                    value:
                                      _vm.meeting.availability.minimumNotice,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.meeting.availability,
                                        "minimumNotice",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "meeting.availability.minimumNotice"
                                  }
                                }),
                                _c("duration-selector", {
                                  staticClass: "flex-grow-1",
                                  attrs: {
                                    label:
                                      "How close to start time can invitees reschedule?",
                                    options: _vm.minimumIncrements,
                                    units: _vm.units
                                  },
                                  model: {
                                    value:
                                      _vm.meeting.availability
                                        .minimumReschedule,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.meeting.availability,
                                        "minimumReschedule",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "meeting.availability.minimumReschedule"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }