<template>
	<v-menu :close-on-click="true" :close-on-content-click="false" v-model="menu">
		<template v-slot:activator="{ on }">
			<v-btn icon v-on="on"
				><v-icon color="black" class="material-symbols-rounded">{{ value ? value : '?' }}</v-icon></v-btn
			>
		</template>
		<div
			style="background-color: var(--v-white-base); width:540px; height: 340px; max-width: 540px; max-height: 340px; overflow: auto"
			class="pa-4"
		>
			<div class="row-format align-center gap-2">
				<v-text-field :autofocus="true" v-model="searchIcon" placeholder="Search icon" type="text" dense />
				<v-btn outlined @click="setIcon('')">None</v-btn>
			</div>

			<div class="fill-all">
				<div class="row-format gap-1" style="flex-wrap: wrap">
					<v-icon v-for="item in filteredSymbols" :key="item" size="28" color="black" @click="setIcon(item)">{{
						item
					}}</v-icon>
				</div>
			</div>
		</div>
	</v-menu>
</template>

<script>
	import symbols from '@/assets/data/symbols.json';

	export default {
		name: 'IconSelector',

		props: {
			value: String,
		},

		components: {},

		data: function() {
			return {
				symbols: symbols,
				searchIcon: null,
				menu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			setIcon: function(icon) {
				this.$emit('input', icon);
				this.menu = false;
				this.searchIcon = null;
			},
		},

		computed: {
			filteredSymbols: function(){
				let result = [... this.symbols]

				if(this.searchIcon){
					result =  result.filter(r => r.includes(this.searchIcon.toLowerCase()));
				}

				return result.splice(0,100);

			}
		},
	};
</script>

<style scoped lang="scss"></style>
