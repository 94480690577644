var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen
    ? _c(
        "basic-modal",
        {
          attrs: {
            dialog: _vm.isOpen,
            persistent: false,
            "close-on-escape": true
          },
          on: {
            close: function($event) {
              _vm.isOpen = false
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "background-color": "var(--v-white-base)" } },
            [
              _c(
                "div",
                { staticClass: "row-format px-4 pt-4" },
                [
                  _c("div", { staticClass: "font-16" }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-auto pointer",
                      attrs: { size: "20" },
                      on: {
                        click: function($event) {
                          _vm.isOpen = false
                        }
                      }
                    },
                    [_vm._v("close")]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "pa-4" }, [_vm._t("default")], 2),
              _c(
                "div",
                {
                  staticClass: "py-2",
                  staticStyle: {
                    "border-top": "1px solid var(--v-gray_30-base)"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      attrs: { width: "140" },
                      on: { click: _vm.save }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }