<template>
	<div id="proposal-format-modal">
		<v-btn icon class="close-dialog" @click="cancel()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="px-4 pt-4 pb-6">
			<div>
				<div class="modal-title">{{ $t('proposal.format.text') }}</div>

				<div class="font-select-wrapper mt-2">
					<div>
						<font-selector label="Header font" :font="lHeaderFont" @font-changed="handleHeaderFontChange($event)"></font-selector>
					</div>
					<div>
						<v-select
							hide-details
							dense
							outlined
							:label="$t('proposal.format.header-font-weight-label')"
							:items="weights"
							v-model="lHeaderWeight"
						>
							<template slot="item" slot-scope="data">
								<span
									:style="`font-family: ${headerFont}; font-weight: ${data.item === 'Regular' ? 400 : 700}`"
									>{{ data.item }}</span
								>
							</template>
							<template slot="selection" slot-scope="data">
								<span
									:style="`font-family: ${headerFont}; font-weight: ${data.item === 'Regular' ? 400 : 700}`"
									>{{ data.item }}</span
								>
							</template>
						</v-select>
					</div>
					<div>
						<font-selector label="Body font" :font="lBodyFont" @font-changed="handleBodyFontChange($event)"></font-selector>
					</div>
				</div>
			</div>

			<div class="subtitle mt-4">{{ $t('global.preview') }}</div>

			<div class="preview-wrapper">
				<div class="preview-cover mt-2">
					<div :style="headerStyle">{{ $t('proposal.format.preview-title') }}</div>
					<div class="subtitle-bar"></div>
				</div>
				<div class="preview-body">
					<div :style="headerStyle">{{ $t('proposal.format.preview-content-heading') }}</div>
					<div :style="bodyStyle">
						{{ $t('proposal.format.preview-content-body') }}
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" style="width: 160px" @click="cancel()">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="primary-action ml-1" style="width: 160px" @click="save()">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>

	import FontSelector from "@/components/FontSelector";

	export default {
		props: ['proposalService', 'headerFont', 'headerAlignment', 'headerWeight', 'headerSize', 'bodyFont', 'openState'],

		name: 'FormatMenu',

		components: {
			FontSelector
		},

		data() {
			return {
				weights: ['Regular', 'Bold'],
				lHeaderFont: this.headerFont,
				lBodyFont: this.bodyFont,
				lHeaderWeight: this.headerWeight,
				lHeaderAlignment: this.headerAlignment,
				lHeaderSize: this.headerSize,
			};
		},

		methods: {
			handleHeaderFontChange: function (font) {
				console.log(font);
				this.lHeaderFont = font;
			},

			handleBodyFontChange: function (font) {
				console.log(font);
				this.lBodyFont = font;
			},

			reset() {
				this.lHeaderFont = this.headerFont;
				this.lBodyFont = this.bodyFont;
				this.lHeaderWeight = this.headerWeight;
				this.lHeaderAlignment = this.headerAlignment;
				this.lHeaderSize = this.headerSize;
			},

			cancel() {
				this.reset();
				this.$emit('close');
			},

			save() {
				this.$emit('save', {
					headerFont: this.lHeaderFont,
					headerWeight: this.lHeaderWeight,
					headerAlignment: this.lHeaderAlignment,
					headerSize: '40px',
					bodyFont: this.lBodyFont,
				});
				this.$emit('close');
			},
		},

		computed: {
			hWeight: function () {
				if (this.lHeaderWeight === 'Regular') {
					return 400;
				} else {
					return 700;
				}
			},

			bodyStyle: function () {
				return `font-family: ${this.lBodyFont};`;
			},

			headerStyle: function () {
				return `font-family: ${this.lHeaderFont}; font-weight: ${this.hWeight}; text-align: ${this.lHeaderAlignment}`;
			},
		},

		watch: {
			openState: function (newVal) {
				if (newVal) {
					this.reset();
				}
			},
		},
	};
</script>

<style lang="scss">
	.font-picker {
		.dropdown-button{
			min-height:40px!important;
		}
	}
</style>

<style scoped lang="scss">
	#proposal-format-modal {
		width: 566px;
		background-color: var(--v-white-base);

		.font-select-wrapper {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-column-gap: 12px;
		}
		.subtitle {
			font-weight: 600;
			color: var(--v-gray_80-base);
			font-size: 12px;
			text-align: left;
		}
		.preview-wrapper {
			box-shadow: 0px 1px 0px var(--v-gray_50-base);
		}
		.preview-cover {
			background-color: var(--v-gray_90-base);
			padding: 24px;
			height: 224px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			border-radius: 4px 4px 0 0;
			div:first-child {
				font-size: 32px !important;
				color: var(--v-gray_10-base);
			}
			.subtitle-bar {
				height: 7px;
				width: 62px;
				background-color: var(--v-gray_10-base);
			}
		}
		.preview-body {
			background-color: var(--v-white-base);
			padding: 24px;
			//height: 224px;
			display: flex;
			//flex-direction: column;
			justify-content: space-between;
			border-radius: 0 0 4px 4px;
			border-left: 1px solid var(--v-gray_50-base);
			border-right: 1px solid var(--v-gray_50-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			div {
				color: var(--v-gray_90-base);
				text-align: left;
			}
			div:first-child {
				flex: 0 0 35%;
				font-size: 14px;
			}
			div:last-child {
				flex: 0 0 62%;
				font-size: 12px;
			}
		}
	}
	//.headers {
	//	font-size: 14px;
	//	font-family: stratos-semibold;
	//	margin-bottom: 5px;
	//	margin-top: 10px;
	//}
	//.section-border {
	//	border: 1px solid #d6d1cb;
	//	box-sizing: border-box;
	//	border-radius: 4px;
	//}
</style>
