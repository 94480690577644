var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "expenses-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c(
          "div",
          {
            class: _vm.$vuetify.breakpoint.xs ? "pl-4" : "",
            attrs: { id: "select-expenses" }
          },
          [
            _c("div", { staticClass: "mb-2" }, [
              _c("h1", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("invoice.create-modal.expense-heading")))
              ]),
              _c("p", {
                staticClass: "modal-subtitle",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("invoice.create-modal.expense-subheading")
                  )
                }
              })
            ]),
            _vm._l(_vm.expenses, function(expense) {
              return _c(
                "div",
                {
                  key: expense.id + "-" + _vm.expenseKey,
                  staticClass: "expense"
                },
                [
                  _c("div", { staticClass: "expense-head" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row-format align-center",
                        staticStyle: { "max-width": "50px" }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "chevron",
                            attrs: { icon: "", rounded: "", small: "" },
                            on: {
                              click: function($event) {
                                return _vm.toggleExpanded(expense)
                              }
                            }
                          },
                          [
                            expense.expanded
                              ? _c("v-icon", [_vm._v("$chevronDown")])
                              : _c("v-icon", [_vm._v("$chevronRight")])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1 mt-1",
                            staticStyle: { display: "block" }
                          },
                          [
                            _c("v-simple-checkbox", {
                              attrs: {
                                color: "primary",
                                value: expense.checked
                              },
                              on: {
                                input: function($event) {
                                  return _vm.toggleChecked(expense)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class:
                          "name " +
                          (_vm.$vuetify.breakpoint.xs ? "truncate" : "")
                      },
                      [_vm._v(" " + _vm._s(_vm.expenseHeading(expense)) + " ")]
                    ),
                    _c("div", { staticClass: "hours" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$formatters.dollars(
                              expense.localTotalWithMarkup,
                              true,
                              true,
                              _vm.invoice.currency
                            )
                          ) +
                          " "
                      )
                    ])
                  ]),
                  expense.expanded
                    ? [
                        _c("div", { staticClass: "expense-body" }, [
                          _c("div", { staticClass: "line-item" }, [
                            _c("div", { staticClass: "left" }, [
                              _vm._v("Category")
                            ]),
                            _c("div", { staticClass: "right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("expenseCategory")(expense.category)
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "line-item" }, [
                            _c("div", { staticClass: "left" }, [
                              _vm._v("Vendor")
                            ]),
                            _c("div", { staticClass: "right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("vendorName")(expense.vendor)) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "line-item" }, [
                            _c("div", { staticClass: "left" }, [
                              _vm._v("Paid")
                            ]),
                            _c("div", { staticClass: "right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$DateTime.humanDate(expense.paidDate)
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            })
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              on: { click: _vm.handleSubmitExpenses }
            },
            [_vm._v(_vm._s(_vm.$t("global.done")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }