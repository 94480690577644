var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "new-ticket" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.handleClose()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-padding column-format gap-2" },
            [
              _c("div", { staticClass: "modal-title" }, [
                _c("span", [_vm._v("Create new ticket")])
              ]),
              _c("select-client", {
                attrs: {
                  dense: true,
                  disabled: _vm.clientLocked,
                  "show-avatar": true,
                  "hide-archive": true
                },
                model: {
                  value: _vm.selectedClientId,
                  callback: function($$v) {
                    _vm.selectedClientId = $$v
                  },
                  expression: "selectedClientId"
                }
              }),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.contacts,
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: "Contact",
                  "item-value": "clientPortalUserId",
                  "search-input": _vm.searchQuery,
                  filter: _vm.customFilter,
                  disabled: _vm.contacts.length === 0
                },
                on: {
                  "update:searchInput": function($event) {
                    _vm.searchQuery = $event
                  },
                  "update:search-input": function($event) {
                    _vm.searchQuery = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(item.firstName) +
                              " " +
                              _vm._s(item.lastName) +
                              " (" +
                              _vm._s(item.email) +
                              ")"
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(item.firstName) +
                              " " +
                              _vm._s(item.lastName) +
                              " (" +
                              _vm._s(item.email) +
                              ")"
                          )
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.contactId,
                  callback: function($$v) {
                    _vm.contactId = $$v
                  },
                  expression: "contactId"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: "CC",
                  placeholder: "Comma separated list of emails"
                },
                model: {
                  value: _vm.cc,
                  callback: function($$v) {
                    _vm.cc = $$v
                  },
                  expression: "cc"
                }
              }),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.ticketTypes,
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: "Ticket type",
                  "item-value": "id",
                  "item-text": "type"
                },
                model: {
                  value: _vm.ticketType,
                  callback: function($$v) {
                    _vm.ticketType = $$v
                  },
                  expression: "ticketType"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  "persistent-placeholder": "",
                  rules: _vm.subjectRules,
                  label: "Subject",
                  autofocus: ""
                },
                model: {
                  value: _vm.subject,
                  callback: function($$v) {
                    _vm.subject = $$v
                  },
                  expression: "subject"
                }
              }),
              _vm.showTinyMCE
                ? _c("editor", {
                    ref: "commentEditor",
                    attrs: {
                      id: "tinymce-editor",
                      init: _vm.mceConfig,
                      rows: "3",
                      "auto-grow": ""
                    },
                    model: {
                      value: _vm.comment,
                      callback: function($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment"
                    }
                  })
                : _vm._e(),
              _c("v-file-input", {
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Attachments",
                  "prepend-icon": "attach_file",
                  multiple: ""
                },
                model: {
                  value: _vm.attachments,
                  callback: function($$v) {
                    _vm.attachments = $$v
                  },
                  expression: "attachments"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  attrs: { width: "130" },
                  on: {
                    click: function($event) {
                      return _vm.handleClose()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "super-action ml-1",
                  attrs: { width: "130", disabled: !_vm.isValid },
                  on: {
                    click: function($event) {
                      return _vm.createTicket()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.create")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }