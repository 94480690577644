var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.template
    ? _c(
        "div",
        { key: _vm.template.id, staticClass: "invoice-template-builder" },
        [
          _c("div", { staticClass: "header-area pl-2 pr-4" }, [
            _c(
              "div",
              { staticClass: "header-left row-format gap-3" },
              [
                _c("v-text-field", {
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    placeholder: "Name"
                  },
                  model: {
                    value: _vm.template.name,
                    callback: function($$v) {
                      _vm.$set(_vm.template, "name", $$v)
                    },
                    expression: "template.name"
                  }
                }),
                _c("v-switch", {
                  staticStyle: { "margin-top": "0!important" },
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    label: "Default template"
                  },
                  model: {
                    value: _vm.template.defaultTemplate,
                    callback: function($$v) {
                      _vm.$set(_vm.template, "defaultTemplate", $$v)
                    },
                    expression: "template.defaultTemplate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-right" },
              [
                _vm.showSaved
                  ? _c(
                      "div",
                      {
                        staticClass: "font-14 brand-medium",
                        staticStyle: { color: "var(--v-success-base)" }
                      },
                      [
                        _vm._v("All changes saved "),
                        _c(
                          "v-icon",
                          { attrs: { small: "", color: "success" } },
                          [_vm._v("done")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action ml-2",
                    attrs: { width: "96" },
                    on: {
                      click: function($event) {
                        return _vm.saveTemplate()
                      }
                    }
                  },
                  [_vm._v(" Save ")]
                ),
                _c(
                  "v-menu",
                  {
                    attrs: { "close-on-click": true, "nudge-bottom": "30" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "menu-activator",
                                        attrs: { icon: "", disabled: false }
                                      },
                                      scope.on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { size: 20 } }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.value
                                              ? "$arrowUp"
                                              : "$moreHorizontal"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1588958389
                    )
                  },
                  [
                    _c("div", { staticClass: "more-menu" }, [
                      _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.duplicateTemplate()
                            }
                          }
                        },
                        [_vm._v("Duplicate template")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.confirmDeleteTemplate()
                            }
                          }
                        },
                        [_vm._v("Delete")]
                      )
                    ])
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "menu-activator",
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.saveTemplate(true)
                      }
                    }
                  },
                  [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("close")])],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "row-format",
              staticStyle: { height: "calc(100% - 50px)" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "column-format pb-3 px-2",
                  staticStyle: {
                    width: "350px",
                    "max-width": "350px",
                    "min-width": "350px",
                    "border-right": "1px solid var(--v-gray_30-base)",
                    height: "99%",
                    "max-height": "99%"
                  }
                },
                [
                  _vm.tab === "Elements" && _vm.editItem
                    ? _c(
                        "div",
                        { staticClass: "py-3" },
                        [
                          _c("config-renderer", {
                            key: _vm.editItem.id,
                            attrs: {
                              item: _vm.editItem,
                              template: _vm.template,
                              tokens: _vm.tokens
                            },
                            on: {
                              change: function($event) {
                                return _vm.elementUpdated($event)
                              },
                              delete: function($event) {
                                return _vm.confirmDelete()
                              },
                              duplicate: function($event) {
                                return _vm.duplicate()
                              },
                              done: function($event) {
                                return _vm.doneEditingItem()
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "column-format gap-2 show-scrollbar",
                          staticStyle: { height: "100%", "max-height": "100%" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row-format py-2",
                              staticStyle: {
                                "border-bottom":
                                  "1px solid var(--v-gray_30-base)"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  class:
                                    "row-format align-center page-link " +
                                    (_vm.tab === "Elements"
                                      ? "page-link-active"
                                      : ""),
                                  on: {
                                    click: function($event) {
                                      return _vm.selectTab("Elements")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: {
                                        size: "22",
                                        color:
                                          _vm.tab === "Elements"
                                            ? "primary"
                                            : "gray_50"
                                      }
                                    },
                                    [_vm._v("dashboard_customize")]
                                  ),
                                  _c("div", { staticClass: "ml-1" }, [
                                    _vm._v("Elements")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  class:
                                    "row-format align-center page-link " +
                                    (_vm.tab === "Style"
                                      ? "page-link-active"
                                      : ""),
                                  on: {
                                    click: function($event) {
                                      return _vm.selectTab("Style")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: {
                                        size: "22",
                                        color:
                                          _vm.tab === "Style"
                                            ? "primary"
                                            : "gray_50"
                                      }
                                    },
                                    [_vm._v("palette")]
                                  ),
                                  _c("div", { staticClass: "ml-1" }, [
                                    _vm._v("Style")
                                  ])
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.tab === "Style"
                            ? _c(
                                "div",
                                { staticClass: "py-3" },
                                [
                                  _c("invoice-style-config", {
                                    attrs: { styles: _vm.styles },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateStyles($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "show-scrollbar",
                                  staticStyle: {
                                    "max-height": "calc(100% - 60px)"
                                  }
                                },
                                _vm._l(_vm.schemaTypes, function(
                                  category,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "mb-2 pb-1",
                                      staticStyle: {
                                        "border-bottom":
                                          "1px solid var(--v-gray_30-base)"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "text-left" }, [
                                        _vm._v(_vm._s(category.label))
                                      ]),
                                      _c(
                                        "draggable",
                                        {
                                          attrs: {
                                            list: category.types,
                                            group: {
                                              name: "elements",
                                              pull: "clone",
                                              put: false
                                            },
                                            clone: _vm.cloneElement,
                                            sort: false
                                          }
                                        },
                                        _vm._l(category.types, function(
                                          schema
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: schema.type,
                                              staticClass:
                                                "row-format align-center schema-item pa-1 gap-2"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-symbols-rounded",
                                                  attrs: {
                                                    color: "black",
                                                    size: "24"
                                                  }
                                                },
                                                [_vm._v(_vm._s(schema.icon))]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "column-format text-left"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "font-14" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(schema.label)
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-12 mt-n1 font-gray_70"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          schema.description
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                        ]
                      )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "pa-12 column-format align-center show-scrollbar main-form",
                  style:
                    "width:100%; height:100%; max-height: 100%; background-color: " +
                    _vm.styles.backgroundColor
                },
                [
                  _c(
                    "div",
                    {
                      style:
                        "min-height: 400px; width:100%; min-width: 300px; max-width: " +
                        _vm.styles.maxWidth +
                        "px; background-color: " +
                        _vm.styles.pageColor
                    },
                    [
                      _c(
                        "draggable",
                        {
                          style:
                            "width:100%; background-color: " +
                            _vm.styles.pageColor,
                          attrs: {
                            list: _vm.schema,
                            group: "elements",
                            clone: _vm.cloneElement,
                            emptyInsertThreshold: 1000
                          },
                          on: { change: _vm.changed }
                        },
                        _vm._l(_vm.schema, function(item) {
                          return _c(
                            "div",
                            { key: item.id },
                            [
                              _c("item-renderer", {
                                attrs: {
                                  item: item,
                                  styles: _vm.styles,
                                  font: _vm.template.font,
                                  invoice: _vm.sampleInvoice,
                                  "edit-mode": false,
                                  "account-logo": _vm.accountLogo,
                                  tokens: _vm.tokens,
                                  "token-map": _vm.tokenMap,
                                  "in-builder": true,
                                  "invoice-service": _vm.invoiceService,
                                  "attachment-service": _vm.attachmentService,
                                  active:
                                    item.id === _vm.editItemId ||
                                    item.id === _vm.editContainerId
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleClick(item)
                                  },
                                  change: function($event) {
                                    return _vm.elementUpdated($event)
                                  },
                                  delete: function($event) {
                                    return _vm.confirmDelete()
                                  },
                                  "add-container-element": function($event) {
                                    return _vm.addContainerElement($event)
                                  },
                                  "container-item-click": function($event) {
                                    return _vm.containerItemClicked($event)
                                  },
                                  "container-item-update": function($event) {
                                    return _vm.containerItemUpdated($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c("div", { staticStyle: { "min-height": "50px" } })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }