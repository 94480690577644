import HDateTime from "@/modules/utils/HDateTime";
import CommunicatorCallService from "@/modules/communicator/CommunicatorCallService";

export default {
    data: function () {
        return {
            HDateTime: HDateTime,
            communicatorService: new CommunicatorCallService(),
            ourCall: null,
            ourFolder: null,
        }
    },

    methods: {
        markCallAsRead: function(){
            this.communicatorService.markCallAsRead(this.ourCall.id,this.ourCall.unread).then((res) => {
                this.ourCall = res.data;
                this.$emit('recent-call-updated',res.data);
            });
        },

        snooze: function(snoozeUntil){
            this.communicatorService.setSnooze(this.ourCall.id,snoozeUntil.toISO()).then((res) => {
                this.ourCall = res.data;
                this.$emit('recent-call-updated',res.data);
                this.$emit('back');
            });
        },

        returnToInbox: function(){
            this.communicatorService.returnToInbox(this.ourCall.id).then((res) => {
                this.ourCall = res.data;
                this.ourFolder = 'Inbox';
                this.$emit('recent-call-updated',res.data);
            });
        },

        archive: function(){
            this.communicatorService.setArchiveFlag(this.ourCall.id,!this.ourCall.archive).then((res) => {
                this.ourCall = res.data;
                this.$emit('recent-call-updated',res.data);
                this.$emit('back');
            });
        },
    }
}