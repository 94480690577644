var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5 column-format" },
    [
      _c("input", {
        ref: "FontFiles",
        staticStyle: { display: "none" },
        attrs: { type: "file", multiple: "", accept: ".otf,.ttf,.woff" },
        on: { change: _vm.setFonts }
      }),
      _c(
        "v-btn",
        {
          staticClass: "primary-action",
          attrs: { color: "primary" },
          on: { click: _vm.findFonts }
        },
        [_vm._v("Upload custom fonts")]
      ),
      _c(
        "div",
        { staticClass: "mt-3" },
        _vm._l(_vm.fonts, function(font) {
          return _c(
            "div",
            {
              key: font.fontName,
              staticClass: "row-format mb-2 pb-2",
              staticStyle: {
                "border-bottom": "1px solid var(--v-gray_30-base)"
              }
            },
            [
              _c("div", [_vm._v(_vm._s(font.fontName))]),
              _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.confirmDeleteFont(font)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v("$delete")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }