<template>
	<div>
		<span class="question"
			><editor
				ref="editor"
				style="border: 1px dotted var(--v-grey-lighten3);"
				@input="handleChange"
				:api-key="$store.getters.getTinyMceKey"
				:initial-value="htmlText"
				:inline="true"
				:init="mceConfigText"
				:placeholder="placeHolder"
				:spellcheck="true"
			></editor
		></span>
		<v-textarea class="textArea" :rows="parseInt(ourRows,10)" placeholder="Client will answer here..." no-resize disabled />
		<div class="row-format mb-2">
			<div style="width: fit-content" class="mt-2 ml-auto row-format align-center gap-2">
				<v-text-field outlined persistent-placeholder dense hide-details label="Field id" v-model="ourFieldKey" style="width:150px" @change="handleChange()"></v-text-field>
				<v-select v-model="ourRows" style="width: 95px" :items="['1','2','3','4','5','6','7','8','9','10']" label="Rows" outlined persistent-placeholder dense hide-details  @change="handleChange()">
				</v-select>
				<v-select style="width: 95px;" :items="requiredOptions" item-text="label" item-value="value" v-model="ourRequired" outlined persistent-placeholder dense hide-details label="Required" @change="handleChange()">
				</v-select>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TextInput',
		props: ['id', 'options', 'htmlText', 'fieldType', 'fieldKey', 'placeHolder', 'mceConfigHeader', 'mceConfigText', 'previewMode','required'],

		components: { editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue') },

		mounted() {},

		data() {
			return {
				requiredOptions: [
					{label: 'Yes', value: true},
					{label: 'No', value: false}
				],
				ourRequired: this.required,
				ourFieldKey: this.fieldKey,
				ourRows: (this.options && this.options.length) ? this.options[0] : "1"
			};
		},

		methods: {
			handleChange: function() {
				this.$emit('changed', {
					id: this.id,
					fieldType: this.fieldType,
					htmlText: this.$refs.editor.editor.getContent({ format: 'html' }),
					required: this.ourRequired,
					options: [this.ourRows],
					fieldKey: this.ourFieldKey,
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.textArea ::v-deep .v-input__slot {
		//border-bottom: 1px solid var(--v-gray_60-base) !important;
	}

	//.textArea ::v-deep v-text-field > .v-input__control > .v-input__slot:before {
	//	border-style: none;
	//}
	//.textArea ::v-deep v-text-field > .v-input__control > .v-input__slot:after {
	//	border-style: none;
	//}

	.question {
		font-size: 16px;
		font-weight: 300;
	}

	.words {
		font-size: 16px;
	}
</style>
