var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "350px" } },
    [
      _c("input", {
        attrs: { id: "copyLink", name: "copyLink", type: "hidden" }
      }),
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c("div", { staticClass: "modal-title" }, [
          _vm._v("Share a " + _vm._s(_vm.type) + " link")
        ]),
        _c("div", { staticClass: "font-12 font-gray_70 text-left" }, [
          _vm._v(
            " Using the custom link from this screen will automatically associate the " +
              _vm._s(_vm.type) +
              " with this opportunity. "
          )
        ]),
        _c(
          "table",
          { staticClass: "text-left mt-2", staticStyle: { width: "100%" } },
          [
            _c(
              "tbody",
              _vm._l(_vm.links, function(link) {
                return _c("tr", { key: link.id }, [
                  _c("td", [_vm._v(_vm._s(link.name))]),
                  _c(
                    "td",
                    { staticStyle: { width: "30px" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pointer",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.copyFullUrl(link.id)
                            }
                          }
                        },
                        [_vm._v("content_copy")]
                      )
                    ],
                    1
                  )
                ])
              }),
              0
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }