import Communicator from "@/modules/communicator/Communicator";
import CommunicatorSettings from "@/modules/communicator/CommunicatorSettings";
import CommunicatorRouter from "@/modules/communicator/CommunicatorRouter";
import TicketRoute from "@/modules/communicator/inbox/TicketRoute";
import EmailLogs from "@/modules/communicator/emailLog/EmailLogs";
import TicketSettings from "@/modules/communicator/settings/TicketSettings";
import EmailTemplateList from "@/modules/templates/emails/EmailTemplateList";

export default [
	{
		path: '/communicator',
		name: 'inbox',
		component: CommunicatorRouter,
		props: true,
	},
	{
		path: '/communicator/inbox',
		name: 'inbox',
		component: Communicator,
		props: true,
	},
	{
		path: '/communicator/email-log',
		name: 'sent',
		component: EmailLogs,
		props: {
			recordType: 'SENT_LOG'
		},
	},
	{
		path: '/communicator/scheduled-emails',
		name: 'scheduled',
		component: EmailLogs,
		props: {
			recordType: 'SCHEDULED'
		},
	},
	{
		path: '/communicator/settings',
		name: 'communicator',
		component: CommunicatorSettings,
		props: route => ({
			v: route.query.v,
		})
	},
	{
		path: '/communicator/templates',
		name: 'communicator',
		component: EmailTemplateList,
		props: true
	},
	{
		path: '/tickets',
		name: 'tickets',
		component: TicketRoute,
		props: true
	},
	{
		path: '/tickets/settings',
		name: 'ticket-settings',
		component: TicketSettings,
		props: true
	},
];
