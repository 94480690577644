<template>
	<div class="row-format gap-2 font-14 pointer" v-if="thread" @click="openThread">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" v-tippy="{content: 'Email'}">email</v-icon>
		<div>{{thread.subject}}</div>
		<div class="ml-auto font-gray_80 font-12">{{thread.lastMessageTime | formatForTimeAgo}}</div>
	</div>
</template>

<script>
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import CommunicationDetailModal from "@/modules/communicator/inbox/CommunicationDetailModal";

	export default {
		name: 'EmailThread',

		props: ['opportunity','item'],

		components: {},

		data: function() {
			return {
				thread: null,
				emailService: new CommunicatorEmailService(),
			};
		},

		mounted() {
			this.getThread();
		},

		beforeDestroy() {},

		methods: {
			getThread: function(){
				this.emailService
						.getThread(this.item.itemId)
						.then((res) => {
							this.thread = res.data;
							this.$emit('actual-time', this.thread.lastMessageTime);
						})
						.catch((err) => {
							if (err.response.status === 404) {
								this.$emit('deleted');
							}
						});
			},

			openThread: function(){
				let event = {
					id: this.thread.id,
					channel: 'Emails',
					type: 'EmailThread',
					date: this.thread.lastMessageTime,
					payload: this.thread,
					unread: this.thread.unread,
					archived: this.thread.archived,
					snoozedUntil: this.thread.snoozedUntil
				}
				this.$store.state.globalModalController.openModal(CommunicationDetailModal, { communication: event, opportunityId: this.opportunity.id }).then(() => this.getThread());
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
