<template>
	<div class="column-format flex-grow-1">
		<slot name="filter"></slot>
		<notes ref="notes" :client="project.client" :project="project" :filter="filter" class=" mt-3"></notes>
	</div>
</template>

<script>
	import Notes from '@/modules/clients/detail/overview/Notes';

	export default {
		name: 'ProjectNotes',

		props: ['project','filter'],

		components: { Notes },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addNew: function() {
				this.$refs.notes.addNewNote()
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
