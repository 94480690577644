var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "close-on-click": true, "nudge-bottom": 30 },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(scope) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "ml-1 menu-activator",
                    attrs: { icon: "", width: "28" }
                  },
                  scope.on
                ),
                [
                  _c("v-icon", { attrs: { size: 20 } }, [
                    _vm._v(_vm._s(scope.value ? "$arrowUp" : "$moreHorizontal"))
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c("div", { staticClass: "more-menu no" }, [
        _vm.isUserActive
          ? _c(
              "div",
              {
                staticClass: "more-menu-item nowrap",
                on: {
                  click: function($event) {
                    return _vm.$emit("revoke-account")
                  }
                }
              },
              [_vm._v(" Revoke account access ")]
            )
          : _vm._e(),
        _vm.showManageFeatures
          ? _c(
              "div",
              {
                staticClass: "more-menu-item nowrap",
                on: {
                  click: function($event) {
                    return _vm.$emit("manage-features")
                  }
                }
              },
              [_vm._v(" Manage feature access ")]
            )
          : _vm._e(),
        _vm.showManageProjects
          ? _c(
              "div",
              {
                staticClass: "more-menu-item nowrap",
                on: {
                  click: function($event) {
                    return _vm.$emit("manage-projects")
                  }
                }
              },
              [_vm._v(" Manage project access ")]
            )
          : _vm._e(),
        !_vm.isUserActive
          ? _c(
              "div",
              {
                staticClass: "more-menu-item nowrap",
                on: {
                  click: function($event) {
                    return _vm.$emit("revoke-invite")
                  }
                }
              },
              [_vm._v(" Revoke invitation ")]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }