<template>
	<v-container class="payment" style="min-height:250px">
		<v-row v-if="integratedPaymentsEnabled">
			<v-col cols="12">
				<v-switch v-model="meeting.paymentEnabled" label="Require payment at booking" hide-details style=""></v-switch>
			</v-col>
			<v-col cols="12" v-if="paymentEnabled && meeting.requiredPayment" class="mt-3 column-format gap-3">
				<v-text-field
					v-model="meeting.requiredPayment.amount"
					label="Payment amount"
					:prefix="currencySymbol"
					type="number"
					hide-details
					dense
					outlined
					class=""
					persistent-placeholder
				></v-text-field>
				<v-autocomplete
					hide-details
					persistent-placeholder
					dense
					outlined
					class=""
					:label="$t('account.currency')"
					:items="currencies"
					item-text="label"
					item-value="value"
					v-model="meeting.requiredPayment.currency"
				>
				</v-autocomplete>
				<div class="">
					<mock-payment :meeting="meeting"></mock-payment>
				</div>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col cols="12" class="row-format centered">
				<v-icon color="primary">$alert</v-icon>
				<div>This feature requires an active Stripe integration.</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import currencies from '@/assets/data/currencies.json';
	import MockPayment from '@/modules/meetings/detail/components/MockPayment.vue';

	export default {
		name: 'Payment',

		props: ['meeting'],

		components: { MockPayment },

		data: function() {
			return {
				currencies: currencies,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		watch: {
			paymentEnabled: function(val) {
				if (val && !this.meeting.requiredPayment) {
					this.meeting.requiredPayment = {
						currency: this.$store.state.defaultCurrency,
						amount: 0,
						description: null,
					};
				}
			},
		},

		computed: {
			integratedPaymentsEnabled: function() {
				if (
					this.$store.state.podAccount.stripeIntegration &&
					this.$store.state.podAccount.stripeIntegration.chargesEnabled
				) {
					return true;
				} else {
					return false;
				}
			},

			paymentEnabled: function() {
				return this.meeting.paymentEnabled;
			},

			currencySymbol: function() {
				if (this.meeting.requiredPayment) {
					return this.$formatters.currencySymbol(this.meeting.requiredPayment.currency);
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.payment {
		padding: 50px;
	}
</style>
