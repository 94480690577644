var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "outstanding-invoices" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.handleClose()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.$t("invoice.outstanding")))
          ]),
          _c("invoice-table", {
            attrs: { invoices: _vm.invoices },
            on: {
              "open-invoice": function($event) {
                return _vm.$emit("result", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }