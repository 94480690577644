var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ticket
    ? _c("div", { staticClass: "cardWrapper" }, [
        _c("div", { staticClass: "card pa-4 mb-2" }, [
          _c(
            "div",
            {
              staticClass: "column-format flex-grow-1",
              staticStyle: { "justify-content": "space-between" }
            },
            [
              _c(
                "div",
                { staticClass: "row-format align-center" },
                [
                  _vm.client
                    ? _c("client-avatar", {
                        attrs: { small: "", client: _vm.client }
                      })
                    : _c(
                        "v-icon",
                        { staticClass: "material-symbols-rounded" },
                        [_vm._v("confirmation_number")]
                      ),
                  _c(
                    "div",
                    {
                      staticClass: "font-12 column-format text-truncate",
                      staticStyle: { "margin-left": "6px" }
                    },
                    [
                      _c("div", { staticClass: "brand-bold" }, [
                        _vm._v(_vm._s(_vm.client ? _vm.client.name : ""))
                      ]),
                      _c("div", { staticClass: "mt-n1" }, [
                        _vm._v(
                          _vm._s(
                            _vm.clientContact
                              ? _vm.clientContact.fullName
                              : "wee wo"
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mx-2 material-symbols-rounded" },
                        [_vm._v("confirmation_number")]
                      ),
                      _c("div", { staticClass: "font-14 align-center" }, [
                        _c("div", [_vm._v(_vm._s(_vm.ticket.subject))])
                      ]),
                      _c(
                        "div",
                        { staticClass: "status row-format align-center ml-2" },
                        [_c("div", [_vm._v(_vm._s(_vm.ticket.status))])]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "row-format align-center",
                  staticStyle: { gap: "8px" }
                },
                [
                  _vm.ticket.dueDate
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "font-12 font-grey row-format align-center"
                        },
                        [_vm._v(" " + _vm._s(_vm.timeline) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "ml-auto row-format align-center gap-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "ml-auto row-format align-center" },
                        _vm._l(_vm.ticket.assignedTo, function(
                          assignedTo,
                          index
                        ) {
                          return _c("assigned-user", {
                            key: assignedTo,
                            style:
                              (index > 0 ? "margin-left: -6px" : "") +
                              "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                              index,
                            attrs: {
                              "show-name": false,
                              small: true,
                              "assigned-to": assignedTo
                            }
                          })
                        }),
                        1
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }