<template>
	<div class="row-format gap-2 font-14 pointer" v-if="proposal" @click="openProposal">
		<v-icon class="material-symbols-rounded" color="secondary" size="20"  v-tippy="{content: `${proposal.proposalType}`}">handshake</v-icon>
		<div>{{ proposal.coverPage.heading }} | {{proposal.proposalType}} | {{proposal.proposalStatus}}</div>
		<div class="ml-auto font-gray_80 font-12 nowrap">{{item.timestamp | formatForTimeAgo}}</div>
	</div>
</template>

<script>
import ProposalService from "@/modules/proposals/ProposalService";
import ProposalBuilder from "@/modules/proposals/ProposalBuilder";

export default {
	name: "Proposal",

	props: ['item'],

	components: {},

	data: function () {
		return {
			proposal: null,
			proposalService: new ProposalService(),
		}
	},

	mounted() {
		this.proposalService.getProposal("0",this.item.itemId)
				.then((res) => this.proposal = res.data)
				.catch((err) => {
					if(err.response.status === 404){
						this.$emit('deleted');
					}
				});
	},

	beforeDestroy() {
	},

	methods: {
		openProposal: function() {
			let binding = {
				proposalId: this.proposal.id,
				clientId: this.proposal.clientId,
			};
			this.$store.state.globalModalController.openModal(ProposalBuilder, binding, true, true).then((res) => {
				this.proposal = res;
			})
		},
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>