<template>
	<div @click.stop class="builder-wrapper">
		<div style="text-align: center; cursor: move;" class="pb-2 dragHandle" v-tippy="{ content: 'Drag to re-order' }">
			<v-icon style="transform: rotate(90deg);" >$drag</v-icon>
		</div>

		<div class="builder-control" :style="offset">
			<div><v-icon class="pointer iconHover" @click.native="$emit('shift-up')" v-tippy="{ content: 'Move Up' }">$arrowUp</v-icon></div>
			<div><v-icon class="pointer iconHover" @click.native="$emit('duplicate-section')" v-tippy="{ content: 'Duplicate section' }" small>$subtract</v-icon></div>
			<div><v-icon class="pointer iconHover" @click.native="$emit('delete-section')" v-tippy="{ content: 'Delete section' }" small>$delete</v-icon></div>
			<div><v-icon class="pointer iconHover" @click.native="$emit('shift-down')" v-tippy="{ content: 'Move Down' }">$arrowDown</v-icon></div>
		</div>
		<component
			:key="item.fieldType"
			:is="item.fieldType"
			:update-key="updateFlag"
			:mce-config-header="mceConfigHeader"
			:mce-config-text="mceConfigText"
			:preview-mode="previewMode"
			@changed="handleChange"
			v-bind="item"
		></component>
	</div>
</template>

<script>
	import CheckboxInput from '@/modules/discovery/formbuilder/GenericOptionInput';
	import RadioInput from '@/modules/discovery/formbuilder/GenericOptionInput';
	import SelectInput from '@/modules/discovery/formbuilder/GenericOptionInput';
	import TextBlock from '@/modules/discovery/formbuilder/TextBlock';
	import TextInput from '@/modules/discovery/formbuilder/TextInput';
	import LeadContact from "@/modules/discovery/formbuilder/LeadContact";
	import DateInput from "@/modules/discovery/formbuilder/DateInput";
	import FileInput from "@/modules/discovery/formbuilder/FileInput";

	export default {
		name: 'Builder',

		props: ['item', 'updateFlag', 'mceConfigHeader', 'mceConfigText', 'previewMode'],

		components: { CheckboxInput, RadioInput, SelectInput, TextBlock, TextInput, LeadContact, DateInput, FileInput },

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleChange: function(payload){
				this.$emit('changed',payload);
			}
		},

		computed: {
			offset: function () {
				if (this.$vuetify.breakpoint.width >= 1080) {
					return '--v-offset-left: -150px; --v-offset-top: -10px';
				} else {
					return '--v-offset-left: -80px; --v-offset-top: 0px';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.builder-wrapper {
		position: relative;
	}

	.iconHover {
		&:hover {
			color: var(--v-primary-base);
		}
	}

	.builder-control {
		position: absolute;
		width: 40px;
		height: 120px;
		top: var(--v-offset-top);
		left: var(--v-offset-left);
		background: var(--v-gray_50-base);
		box-shadow: 0px 1px 4px rgba(16, 17, 15, 0.2);
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 8px 0px 8px 0px;
	}
</style>
