<template>
	<v-container fluid class="pa-0 ma-0 proposal-image-text-block proposal-text-area">
		<v-row align="center">
			<v-col v-if="editMode" cols="12" align="left" class="py-0 my-0">
				<v-icon class="customIcon" @click.native="imageAlign('Left')" :disabled="lAlign === 'Left'"
					>$vuetify.icons.imageLeft</v-icon
				>
				<v-icon class="customIcon" @click.native="imageAlign('Right')" :disabled="lAlign === 'Right'"
					>$vuetify.icons.imageRight</v-icon
				>
			</v-col>
			<v-col cols="12">
				<div :class="outerContainerCss">
					<div class="imageContainer">
						<img v-if="signedUrl !== null" :src="signedUrl" class="imageNone" style="border-radius: 4px" />

						<div v-if="templateMode || (editMode && signedUrl !== null)" class="deleteButton">
							<v-btn small icon class="deleteIcon" @click="confirmDeleteFile()"
								><v-icon size="20">$delete</v-icon></v-btn
							>
						</div>

						<vue-dropzone
							v-if="editMode && lFile === null"
							ref="fileDropzone"
							id="dropzone"
							v-on:vdropzone-success="uploadSuccess"
							v-on:vdropzone-error="uploadFailure"
							v-on:vdropzone-sending="addFileUploadAuthHeader"
							:use-custom-slot="true"
							:options="dropzoneOptions"
						>
							<div>
								<v-icon>$vuetify.icons.upload</v-icon>
								<span class="ml-2" style="font-size: 0.8em">{{ $t('proposal.image.upload') }}</span>
							</div>
						</vue-dropzone>
					</div>
					<div style="width: 20px"></div>
					<div class="textContainer" style="text-align: left">
						<div :style="headerStyle">
							<span v-html="lHeader" v-if="!editMode"></span>
							<editor
								ref="headerEditor"
								v-if="editMode"
								class="grey-dots"
								:api-key="$store.getters.getTinyMceKey"
								:initial-value="lHeader"
								:inline="true"
								:init="mceConfigHeader"
								:spellcheck="true"
								@input="handleHeaderInput"
							></editor>
						</div>
						<div :style="bodyStyle" class="mt-4">
							<span v-html="lText" v-if="!editMode"></span>
							<editor
								ref="textEditor"
								v-if="editMode"
								class="grey-dots"
								:api-key="$store.getters.getTinyMceKey"
								:initial-value="lText"
								:inline="true"
								:init="mceConfigText"
								:spellcheck="true"
								@input="handleTextInput"
							></editor>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-dialog v-model="confirmDelete" persistent max-width="290">
			<div class="brand-regular" style="background-color: var(--v-white-base)">
				<v-btn class="close-dialog" icon rounded @click.prevent="cancelDeleteFile()">
					<v-icon size="20">$close</v-icon>
				</v-btn>
				<div class="modal-padding">
					<div class="modal-title text-center">
						<span class="pr-1">
							<v-icon color="warning" class="mt-n1" size="24">$alert</v-icon>
						</span>
						{{ $t('global.confirm') }}
					</div>
					<div class="modal-subtitle text-center mx-3">{{ $t('proposal.image.delete') }}</div>
				</div>
				<div class="modal-footer">
					<v-btn class="mr-1 super-action" width="120" @click="cancelDeleteFile()">{{ $t('global.cancel') }}</v-btn>
					<v-btn class="ml-1 primary-action" width="120" @click="deleteFile()">{{ $t('global.delete') }}</v-btn>
				</div>
			</div>
		</v-dialog>
	</v-container>
</template>

<script>
	import vueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';

	export default {
		name: 'ImageTextBlock',

		props: [
			'header',
			'text',
			'file',
			'align',
			'border',
			'id',
			'editMode',
			'clientId',
			'proposalId',
			'blockType',
			'proposalService',
			'templateService',
			'mceConfigText',
			'mceConfigHeader',
			'formatting',
			'templateMode',
		],

		components: {
			vueDropzone,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		mounted() {
			if (this.file) {
				this.setSignedUrl(this.file);
			}
		},

		data: function () {
			return {
				lHeader: this.header,
				lText: this.text,
				lFile: this.file,
				lAlign: this.align,
				lBorder: this.border,
				signedUrl: null,
				confirmDelete: false,
			};
		},

		methods: {
			imageAlign: function (direction) {
				this.lAlign = direction;
				this.notifyChange();
			},

			notifyChange: function () {
				this.$emit('changed', {
					id: this.id,
					blockType: this.blockType,
					header: this.lHeader,
					text: this.lText,
					file: this.lFile,
					align: this.lAlign,
					border: this.lBorder,
				});
			},

			handleHeaderInput: function () {
				this.lHeader = this.$refs.headerEditor.editor.getContent();
				this.notifyChange();
			},

			handleTextInput: function () {
				this.lText = this.$refs.textEditor.editor.getContent();
				this.notifyChange();
			},

			addFileUploadAuthHeader: function (file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			uploadSuccess: function (file, response) {
				this.$refs.fileDropzone.removeFile(file);
				this.setSignedUrl(response);
				this.lFile = response;
				this.notifyChange();
			},

			uploadFailure: function (file, message) {
				this.$store.commit('error', this.$t('error.error-uploading') + message);
				this.$refs.fileDropzone.removeFile(file);
			},

			confirmDeleteFile: function () {
				this.confirmDelete = true;
			},

			cancelDeleteFile: function () {
				this.confirmDelete = false;
			},

			deleteFile: function () {
				this.deleteFileInternal()
					.then(() => {
						this.lFile = null;
						this.signedUrl = null;
						this.notifyChange();
						this.cancelDeleteFile();
					})
					.catch((err) => {
						this.$store.commit('error', this.$t('error.error-deleting') + err.response.data.message);
						this.cancelDeleteFile();
					});
			},

			deleteFileInternal: function () {
				if (this.templateMode) {
					return this.templateService.deleteFileAttachment(this.proposalId, this.lFile.fileName);
				} else {
					return this.proposalService.deleteFileAttachment(this.clientId, this.proposalId, this.lFile.fileName);
				}
			},

			setSignedUrl: function (s3File) {
				if (this.templateMode) {
					this.templateService.getSignedUrl(this.proposalId, s3File.fileName).then((res) => {
						this.signedUrl = res.data.signedUrl;
					});
				} else {
					this.proposalService.getSignedUrl(this.clientId, this.proposalId, s3File.fileName).then((res) => {
						this.signedUrl = res.data.signedUrl;
					});
				}
			},
		},

		computed: {
			headerWeight: function () {
				if (this.formatting.headerWeight === 'Regular') {
					return 400;
				} else {
					return 600;
				}
			},

			bodyStyle: function () {
				return `font-family: ${this.formatting.bodyFont}; text-align:left; color: var(--v-gray_70-base);`;
			},

			headerStyle: function () {
				return `font-size: 22px; font-family: ${this.formatting.headerFont}; font-weight: ${this.headerWeight}; text-align:left`;
			},

			outerContainerCss: function () {
				if (this.lAlign === 'Left') {
					return 'outerContainerLeft';
				} else {
					return 'outerContainerRight';
				}
			},

			dropzoneOptions: function () {
				let theUrl;

				if (this.templateMode){
					theUrl = `${this.$store.getters.getBaseUrl}/templates/proposals/${this.proposalId}/files`;
				}else{
					theUrl = `${this.$store.getters.getBaseUrl}/clients/${this.clientId}/proposals/${this.proposalId}/files`;
				}

				return {
					url: theUrl,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},
		},
	};
</script>

<style lang="scss">
	.proposal-image-text-block {
		.imageNone {
			width: 100%;
		}

		.outerContainerRight {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
      @media (max-width: 599px){
        flex-direction: column;
      }
		}

		.outerContainerLeft {
			display: flex;
			flex-direction: row;
			align-items: center;
      @media (max-width: 599px){
        flex-direction: column;
      }
		}

		.imageContainer {
			width: 50%;
			//margin: 20px;
			position: relative;
			.deleteButton {
				position: absolute;
				top: 8px;
				right: 8px;
				opacity: 0.5;
				&:hover {
					opacity: 1;
				}
				button {
					background-color: var(--v-white-base);
					z-index: 100;
				}
			}
      @media (max-width: 599px){
        width: 90%;
      }
		}

		.textContainer {
			width: 50%;
			//margin: 10px;
      @media (max-width: 599px){
        width: 80%;
      }
		}

		.customIcon ::v-deep {
			cursor: pointer;
			color: var(--v-grey-base);
			margin-right: 10px;
			margin-top: 3px;
		}

		.customIcon:hover {
			color: var(--v-primary-base);
		}
	}
</style>
