var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "client-hourly-amount-edit" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c(
            "div",
            { staticClass: "client-name" },
            [
              _c("client-avatar", { attrs: { client: _vm.client, left: "" } }),
              _c("div", [_vm._v(_vm._s(_vm.client.name))])
            ],
            1
          ),
          _c("div", {
            staticClass: "modal-subtitle",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("invoice.client-fee-dialog.subheading", {
                  thisClient: _vm.client.name
                })
              )
            }
          }),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              label: _vm.$t("invoice.client-fee-dialog.hourly-amount"),
              placeholder: "0.00",
              prefix: _vm.currencySymbol,
              suffix: "per hour",
              type: "number"
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.save()
              }
            },
            model: {
              value: _vm.hourlyAmount,
              callback: function($$v) {
                _vm.hourlyAmount = $$v
              },
              expression: "hourlyAmount"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { width: "180" },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }