<template>
	<div class="row-format gap-2">
		<v-select
			@change="handleChange()"
			hide-details
			dense
			outlined
			label="Category"
			v-model="category"
			:items="variables"
			item-text="label"
			item-value="value"
			style="width: 180px; max-width: 180px"
		>
		</v-select>
		<v-select
			@change="handleChange()"
			v-if="fields && !isDynamic"
			hide-details
			dense
			outlined
			label="Field"
			v-model="field"
			:items="fields"
			item-text="label"
			item-value="value"
			:style="['Email'].includes(category)  ? 'width: 350px; max-width: 350px' : 'width: 200px; max-width: 200px'"
		></v-select>
		<v-text-field v-if="isDynamic" v-model="field" hide-details dense outlined style="width: 150px; max-width: 150px" :placeholder="selectedCategory.dynamicKeyLabel">
		</v-text-field>
		<v-select
			@change="handleChange()"
			v-if="comparatorList"
			hide-details
			dense
			outlined
			v-model="comparator"
			:items="comparatorList"
			style="width: 150px; max-width: 150px"
		></v-select>
		<date-selector
			v-if="fieldDetail && fieldDetail.type === 'Date' && showValue"
			:standard="true"
			:date="value"
			@change="
				value = $event;
				handleChange();
			"
		></date-selector>
		<v-select v-if="fieldDetail && fieldDetail.type === 'List' && showValue" :items="fieldDetail.options" hide-details dense outlined v-model="value" @change="handleChange()">
		</v-select>
		<v-text-field
			@input="handleChange()"
			v-else-if="(fieldDetail || isDynamic) && showValue"
			hide-details
			dense
			outlined
			placeholder="Value"
			v-model="value"
			:type="fieldDetail && fieldDetail.type === 'Numeric' ? 'number' : 'text'"
		></v-text-field>
		<v-icon class="material-symbols-rounded pointer" size="20" @click="$emit('delete')">delete</v-icon>
	</div>
</template>

<script>
	import DateSelector from '@/components/DateSelector';
	import NodeMixin from "@/modules/workflow/nodes/NodeMixin";
	export default {
		name: 'Condition',

		mixins: [NodeMixin],
		props: ['condition'],

		components: { DateSelector },

		data: function() {
			return {
				id: null,
				category: null,
				field: null,
				comparator: null,
				value: null,
			};
		},

		mounted() {
			this.id = this.condition.id;
			this.category = this.condition.category;
			this.field = this.condition.field;
			this.comparator = this.condition.comparator;
			this.value = this.condition.value;
		},

		beforeDestroy() {},

		methods: {
			handleChange: function() {
				this.$emit('change', {
					id: this.id,
					category: this.category,
					field: this.field,
					comparator: this.comparator,
					value: this.value,
				});
			},
		},

		computed: {
			comparatorList: function() {
				if (this.fieldDetail) {
					if (this.fieldDetail.type === 'Text') {
						return ['Is', 'Is not', 'Starts with', 'Contains', 'Ends with', 'Is set', 'Is not set'];
					}else if(this.fieldDetail.type === 'List'){
						return ['Is','Is not'];
					} else {
						return ['Is', 'Is not', 'Greater than', 'Less than', 'Is set', 'Is not set'];
					}
				}else if(this.selectedCategory && this.selectedCategory.dynamic){
						return ['Is', 'Is not', 'Starts with', 'Contains', 'Ends with', 'Greater than', 'Less than', 'Is set', 'Is not set'];
				}
				return null;
			},

			fieldDetail: function() {
				if (this.fields) {
					return this.fields.find((f) => f.value === this.field);
				}
				return null;
			},

			selectedCategory: function(){
				return this.variables.find((v) => v.value === this.category);
			},

			isDynamic: function(){
				if(this.selectedCategory){
					return this.selectedCategory.dynamic;
				}else{
					return false;
				}
			},

			showValue: function(){
				if(this.comparator === 'Is set' || this.comparator === 'Is not set'){
					return false;
				}else{
					return true;
				}
			},

			fields: function() {
				if(this.selectedCategory){
					return this.selectedCategory.fields;
				}else{
					return null;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
