<template>
	<div>
		<div>Width ({{element.width}}px)</div>
		<v-slider
				v-model="element.width"
				:min="100"
				:max="500"
				:step="10"
				hide-details
				dense
				@input="updated"
				thumb-label
		></v-slider>
		<v-select v-model="element.alignment" :items="alignment" item-text="label" item-value="value" hide-details dense outlined label="Alignment" @change="updated"></v-select>
	</div>
</template>

<script>
	export default {
		name: 'LogoConfig',

		props: ['item'],

		components: {},

		data: function() {
			return {
				element: {... this.item},
				alignment: [
					{label:'Left', value:'left'},
					{label:'Right', value:'right'},
					{label:'Center', value:'center'}
				]
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
