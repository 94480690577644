<template>
	<div>
		<page-header
			class="pb-6"
			:bread-crumbs="[
				{ to: '/home', label: 'Home' },
				{ to: '/apps', label: 'Integrations' },
			]"
		>
			Stripe payments
		</page-header>

		<div class="row-format align-center gap-3 mb-4">
			<div
				:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
				v-for="page in pages"
				:key="page.value"
				@click.stop="setView(page.value)"
			>
				<div>{{ page.label }}</div>
			</div>
		</div>

		<div v-if="account">
			<div class="row-format align-center mb-4" v-if="currentView === 'Subscriptions' || currentView === 'Products'">
				<subscription-filter
					v-if="currentView === 'Subscriptions'"
					v-model="filter"
					@clear-filter="clearFilter()"
				></subscription-filter>

				<v-btn class="super-action ml-auto" @click="handleAddNew()"><v-icon size="20">add</v-icon> Add {{currentView === 'Subscriptions' ? 'subscription' : 'product'}}</v-btn>
			</div>

			<stripe-connect
				v-show="currentView === 'Connection'"
				:stripe-integration="account.stripeIntegration"
			></stripe-connect>

			<stripe-products v-if="isConnected" ref="Products" v-show="currentView === 'Products'"></stripe-products>

			<stripe-subscriptions
				ref="Subscriptions"
				:stripe-integration="account.stripeIntegration"
				v-if="isConnected"
				v-show="currentView === 'Subscriptions'"
				:filter="filter"
			></stripe-subscriptions>
		</div>
	</div>
</template>

<script>
	import AccountService from '@/modules/account/AccountService';
	import StripeConnect from '@/modules/integrations/stripe/StripeConnect';
	import StripeProducts from '@/modules/integrations/stripe/subscriptions/StripeProducts';
	import StripeSubscriptions from '@/modules/integrations/stripe/subscriptions/StripeSubscriptions';
	import SubscriptionFilter from '@/modules/integrations/stripe/subscriptions/SubscriptionFilter';
	import PageHeader from '@/components/PageHeader';

	export default {
		name: 'Stripe',

		props: ['v'],

		components: {
			SubscriptionFilter,
			StripeSubscriptions,
			StripeProducts,
			StripeConnect,
			PageHeader,
		},

		data: function() {
			return {
				isReady: true,
				account: null,
				accountService: new AccountService(),
				currentView: 'Connection',
				filter: null,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'stripe-settings' });
			this.$store.commit('setBreadcrumbs', [{ to: this.$route.path, text: 'Stripe integration' }]);
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);

			if (this.v) {
				this.currentView = this.v;
			}
			this.loadSavedFilter();
			this.handleAccountChange();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function() {
				this.accountService
					.getAccount()
					.then((res) => {
						this.account = res.data;
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			handleAddNew: function() {
				this.$refs[this.currentView].addNew();
			},

			clearFilter: function() {
				this.filter = this.emptyFilter();
			},

			emptyFilter: function() {
				return {
					search: null,
					clientList: [],
					subscriptionStatus: [],
				};
			},

			setView: function(view) {
				this.currentView = view;
			},

			saveCurrentFilter() {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			loadSavedFilter() {
				try {
					let filterString = localStorage.getItem(this.filterStateKey);
					if (filterString) {
						this.filter = JSON.parse(filterString);
					} else {
						this.filter = this.emptyFilter();
					}
				} catch (err) {
					console.log('Error reading filter preferences from local storage.', err);
				} finally {
					this.isReady = true;
				}
			},
		},

		computed: {
			filterStateKey: function() {
				return 'SUBSCRIPTION_FILTER_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},

			pages: function() {
				let result = [];
				result.push({ label: 'Connection', value: 'Connection' });
				if (this.isConnected) {
					result.push({ label: 'Client subscriptions', value: 'Subscriptions' });
					result.push({ label: 'Subscription products', value: 'Products' });
				}
				return result;
			},

			isAccountOwner: function() {
				return this.$store.getters.isAccountOwner;
			},

			isConnected: function() {
				if (this.account && this.account.stripeIntegration) {
					return true;
				} else {
					return false;
				}
			},
		},

		watch: {
			filter: {
				deep: true,
				handler() {
					this.saveCurrentFilter();
				},
			},
		},
	};
</script>

<style lang="scss"></style>
