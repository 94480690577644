import HttpClient from '@/services/HttpClient';

export default class PayPalService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getConnection() {
		return this.httpClient.get('/paypal')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createStandardConnection(){
		return this.httpClient.post('/paypal/standard')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateStandardConnection(config){
		return this.httpClient.put('/paypal/standard',config)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteStandardConnection(){
		return this.httpClient.delete('/paypal/standard')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	initializeOnBoarding(){
		return this.httpClient.post('/paypal/connect')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	finalizeConnection(merchantInfo){
		return this.httpClient.put('/paypal/connect',merchantInfo)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	continueOnBoarding(){
		return this.httpClient.post(`/paypal/continue`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
