import HttpClient from "@/services/HttpClient";

export default class SearchService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	verify(){
		return this.httpClient
			.get(`/search/verify`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	search(query) {
		return this.httpClient
			.get(`/search?query=${encodeURIComponent(query)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
