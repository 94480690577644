import HttpClient from "@/services/HttpClient";

export default class ImportService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getImports(){
		return this.httpClient
			.get(`/import`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteImport(importRecordId,deleteRelated=false){
		return this.httpClient
			.delete(`/import/${importRecordId}?deleteRelated=${deleteRelated}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	importData(type,payload,createClient) {
		return this.httpClient
			.post(`/import/${type.toLowerCase()}?createClient=${createClient}`,payload)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
