<template>
	<div class="column-format gap-2 py-3 text-left">
		<v-text-field
			hide-details
			dense
			outlined
			type="number"
			v-model="element.padding"
			label="Padding"
			@input="updated"
		></v-text-field>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pt"
				label="Margin top"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pb"
				label="Margin bottom"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pl"
				label="Margin left"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pr"
				label="Margin right"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.borderWidth"
				label="Border width"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.borderRadius"
				label="Border radius"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format text-left gap-1">
			<div style="flex: 1 1 0; width: 0;">
				<div>Border color</div>
				<color-selector v-model="element.borderColor" @input="updated"></color-selector>
			</div>
		</div>
		<v-autocomplete
			class="mt-1"
			persistent-placeholder
			hide-details
			dense
			outlined
			label="Embedded scheduler"
			:items="schedulerList"
			v-model="element.attachedSchedulerId"
			@change="updated"
		></v-autocomplete>
		<v-switch v-model="element.showInfo" hide-details dense label="Show info panel" @change="updated"></v-switch>
	</div>
</template>

<script>
	import MeetingService from '@/modules/meetings/MeetingService';
	import ColorSelector from '@/components/ColorSelector';

	export default {
		name: 'FormEmbedConfig',

		props: ['item'],

		components: { ColorSelector },

		data: function() {
			return {
				element: { ...this.item },
				schedulers: [],
				schedulerService: new MeetingService(),
			};
		},

		mounted() {
			this.getSchedulerList();
		},

		beforeDestroy() {},

		methods: {
			getSchedulerList: function() {
				this.schedulerService.getMeetingList().then((res) => {
					this.schedulers.splice(0, this.schedulers.length);
					this.schedulers.push(...res.data);
				});
			},

			updated: function() {
				this.$emit('change', this.element);
			},
		},

		watch: {},

		computed: {
			schedulerList: function() {
				let results = [];

				results.push({
					text: '-- None -- ',
					value: null,
				});

				this.schedulers.forEach((t) => {
					results.push({ text: t.name, value: t.uniqueMeetingId });
				});

				results.sort((a, b) => a.text.localeCompare(b.text));

				return results;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
