import HttpClient from '@/services/HttpClient';

export default class PhotoService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    searchStockPhotos(query, page = 1, perPage = 18, orientation= 'Any') {
        orientation = orientation.toLowerCase();

        if(orientation === 'any'){
            orientation = null;
        }

        return this.httpClient
            .get(`/photos/search?query=${encodeURIComponent(query)}&page=${page}&perPage=${perPage}&orientation=${orientation}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    downloadStockPhoto(photoUrl) {
        return this.httpClient
            .get('/photos/download', {
                photoUrl: photoUrl,
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}