var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "font-14 column-format gap-3" },
    [
      !_vm.hideTaskName
        ? _c("v-text-field", {
            attrs: {
              label: _vm.$t(
                "templates.packages.deliverable-detail-modal.name-label"
              ),
              placeholder: _vm.$t(
                "templates.packages.deliverable-detail-modal.name-placeholder"
              ),
              autofocus: "",
              "hide-details": "",
              outlined: "",
              dense: "",
              "persistent-placeholder": ""
            },
            model: {
              value: _vm.deliverable.name,
              callback: function($$v) {
                _vm.$set(_vm.deliverable, "name", $$v)
              },
              expression: "deliverable.name"
            }
          })
        : _vm._e(),
      _c("v-textarea", {
        attrs: {
          outlined: "",
          dense: "",
          "hide-details": "",
          "persistent-placeholder": "",
          label: _vm.$t("proposal.deliverables.description"),
          placeholder: _vm.$t("projects.description-placeholder"),
          "auto-grow": "",
          rows: "1"
        },
        model: {
          value: _vm.deliverable.description,
          callback: function($$v) {
            _vm.$set(_vm.deliverable, "description", $$v)
          },
          expression: "deliverable.description"
        }
      }),
      _c(
        "div",
        { staticClass: "text-left" },
        [
          _c("div", [_vm._v("Status")]),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-click": true,
                "close-on-content-click": true,
                "offset-y": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "div",
                        _vm._g(
                          {
                            staticClass:
                              "deliverableStatus row-format align-center pointer"
                          },
                          on
                        ),
                        [
                          _vm.deliverable.statusId
                            ? [
                                _c("div", {
                                  staticClass: "statusBox",
                                  style:
                                    "--status-color:" +
                                    _vm.deliverableStatus.hexColor
                                }),
                                _c("div", { staticClass: "text-no-wrap" }, [
                                  _vm._v(_vm._s(_vm.deliverableStatus.label))
                                ])
                              ]
                            : _c("div", { staticClass: "text-no-wrap" }, [
                                _vm._v("--")
                              ])
                        ],
                        2
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-left pa-3 font-14",
                  staticStyle: { "background-color": "var(--v-white-base)" }
                },
                _vm._l(
                  _vm.$store.state.deliverableStatusList.statusList,
                  function(status) {
                    return _c(
                      "div",
                      {
                        key: status.id,
                        staticClass: "py-1 pointer",
                        on: {
                          click: function($event) {
                            _vm.deliverable.statusId = status.id
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "deliverableStatus row-format align-center highlight-hover",
                            style: "--status-color:" + status.hexColor
                          },
                          [
                            _c("div", { staticClass: "statusBox" }),
                            _c("div", [_vm._v(_vm._s(status.label))])
                          ]
                        )
                      ]
                    )
                  }
                ),
                0
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-left" },
        [
          _c("div", [_vm._v("Assigned to")]),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-click": true,
                "close-on-content-click": false,
                "offset-y": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c("div", _vm._g({ staticClass: "pointer" }, on), [
                        _vm.deliverable.assignedToList.length
                          ? _c(
                              "div",
                              { staticClass: "row-format align-center" },
                              _vm._l(_vm.deliverable.assignedToList, function(
                                assignedTo,
                                index
                              ) {
                                return _c(
                                  "assigned-user",
                                  _vm._g(
                                    {
                                      key: assignedTo,
                                      style:
                                        (index > 0 ? "margin-left: -6px" : "") +
                                        "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                                        index,
                                      attrs: {
                                        "assigned-to": assignedTo,
                                        "show-name": false,
                                        "empty-label": "--"
                                      }
                                    },
                                    on
                                  )
                                )
                              }),
                              1
                            )
                          : _c("div", [_vm._v("--")])
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-left pa-3 font-14",
                  staticStyle: { "background-color": "var(--v-white-base)" }
                },
                _vm._l(_vm.userList, function(user) {
                  return _c(
                    "div",
                    {
                      key: user.userId,
                      staticClass:
                        "row-format align-center text-left py-1 pointer"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.deliverable.assignedToList,
                            expression: "deliverable.assignedToList"
                          }
                        ],
                        staticStyle: {
                          width: "20px",
                          height: "20px",
                          "border-radius": "4px"
                        },
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: user.userId,
                          checked: Array.isArray(_vm.deliverable.assignedToList)
                            ? _vm._i(
                                _vm.deliverable.assignedToList,
                                user.userId
                              ) > -1
                            : _vm.deliverable.assignedToList
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.deliverable.assignedToList,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = user.userId,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.deliverable,
                                    "assignedToList",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.deliverable,
                                    "assignedToList",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.deliverable, "assignedToList", $$c)
                            }
                          }
                        }
                      }),
                      user.userId
                        ? _c("assigned-user", {
                            staticClass: "mx-2",
                            attrs: {
                              "assigned-to": user.userId,
                              "show-name": true
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _vm.feeSchedule.feeType === "Per Item"
        ? _c(
            "div",
            [
              _c("div", { staticClass: "label-style mb-2" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "templates.packages.deliverable-detail-modal.per-line-item"
                    )
                  )
                )
              ]),
              _c("per-item-pricing", {
                attrs: {
                  product: _vm.deliverable.product,
                  quantity: _vm.deliverable.quantity,
                  editable: true
                },
                on: {
                  "product-selected": function($event) {
                    _vm.deliverable.product = $event
                  },
                  "quantity-updated": function($event) {
                    _vm.deliverable.quantity = $event
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "label-style" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "templates.packages.deliverable-detail-modal.due-after-label"
            )
          )
        )
      ]),
      _vm.deliverable.dueDate
        ? _c(
            "div",
            {
              staticClass: "d-flex align-center justify-space-between half",
              staticStyle: { gap: "12px" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  inputmode: "number",
                  placeholder: _vm.$t(
                    "templates.packages.deliverable-detail-modal.duration-placeholder",
                    { units: _vm.humanUnits }
                  ),
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                  "persistent-placeholder": ""
                },
                on: {
                  keydown: function($event) {
                    return _vm.handleDurationKeydown($event)
                  }
                },
                model: {
                  value: _vm.deliverable.dueDate.duration,
                  callback: function($$v) {
                    _vm.$set(_vm.deliverable.dueDate, "duration", $$v)
                  },
                  expression: "deliverable.dueDate.duration"
                }
              }),
              _c("v-select", {
                attrs: {
                  placeholder: _vm.$t(
                    "templates.packages.deliverable-detail-modal.units-placeholder"
                  ),
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                  "persistent-placeholder": "",
                  items: _vm.timeUnits,
                  "item-value": "value",
                  "item-text": "text"
                },
                model: {
                  value: _vm.deliverable.dueDate.timeUnit,
                  callback: function($$v) {
                    _vm.$set(_vm.deliverable.dueDate, "timeUnit", $$v)
                  },
                  expression: "deliverable.dueDate.timeUnit"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "label-style" }, [_vm._v("Priority")]),
      _c(
        "div",
        {
          staticClass: "d-flex align-center justify-space-between",
          staticStyle: { gap: "12px" }
        },
        [
          _c("v-text-field", {
            attrs: {
              type: "number",
              inputmode: "number",
              "hide-details": "",
              outlined: "",
              dense: "",
              "persistent-placeholder": ""
            },
            model: {
              value: _vm.deliverable.priority,
              callback: function($$v) {
                _vm.$set(_vm.deliverable, "priority", $$v)
              },
              expression: "deliverable.priority"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "label-style mt-2" }, [
        _vm._v(
          _vm._s(
            _vm.$t("templates.packages.deliverable-detail-modal.tasks-label")
          )
        )
      ]),
      _c(
        "div",
        { staticClass: "tasks-wrapper" },
        [
          _c("tasks", {
            ref: "tasks",
            attrs: { "detail-view": true },
            model: {
              value: _vm.deliverable.tasks,
              callback: function($$v) {
                _vm.$set(_vm.deliverable, "tasks", $$v)
              },
              expression: "deliverable.tasks"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "label-style mt-3 mb-2 brand-medium" }, [
        _vm._v("Attachments")
      ]),
      _c("file-attachments", {
        attrs: {
          "edit-mode": true,
          files: _vm.deliverable.files,
          "file-service": _vm.attachmentService
        }
      }),
      _c("div", { staticClass: "label-style mt-3 mb-2 brand-medium" }, [
        _vm._v("Custom fields")
      ]),
      _vm._l(_vm.fields, function(field) {
        return _c("div", { key: field.id, staticClass: "row-format py-1" }, [
          _c(
            "div",
            { staticClass: "overview-left" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded",
                  attrs: { size: "20" }
                },
                [_vm._v(_vm._s(field.icon))]
              ),
              _c("div", { staticClass: "ml-2" }, [_vm._v(_vm._s(field.name))])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "overview-right" },
            [
              _c("custom-field", {
                attrs: { field: field, value: _vm.getCustomValue(field.id) },
                on: {
                  change: function($event) {
                    return _vm.handleCustomFieldChange($event)
                  }
                }
              })
            ],
            1
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }