var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.project
    ? _c(
        "div",
        {
          staticClass: "row-format gap-2 font-14 pointer",
          on: { click: _vm.openProject }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { content: "Project" },
                  expression: "{ content: `Project` }"
                }
              ],
              staticClass: "material-symbols-rounded",
              attrs: { color: "secondary", size: "20" }
            },
            [_vm._v(" folder_special ")]
          ),
          _c("div", [
            _vm._v(" " + _vm._s(_vm.project.name) + " "),
            _vm.project.dueDate
              ? _c("span", [
                  _vm._v(
                    "| Due: " +
                      _vm._s(
                        _vm.HDateTime.fromISO(
                          _vm.project.dueDate
                        ).toLocaleString(_vm.HDateTime.DATE_MED)
                      )
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "ml-auto font-gray_80 font-12 nowrap" }, [
            _vm._v(_vm._s(_vm._f("formatForTimeAgo")(_vm.item.timestamp)))
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }