var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-3 text-left" },
    [
      _c("v-checkbox", {
        attrs: {
          "hide-details": "",
          dense: "",
          label: "Send automatically (no approval required)"
        },
        model: {
          value: _vm.paymentPlan.autoSend,
          callback: function($$v) {
            _vm.$set(_vm.paymentPlan, "autoSend", $$v)
          },
          expression: "paymentPlan.autoSend"
        }
      }),
      _vm.paymentPlan.autoSend
        ? _c("v-autocomplete", {
            staticClass: "mt-2",
            attrs: {
              items: _vm.emailTemplates,
              "hide-details": "",
              dense: "",
              outlined: "",
              "persistent-placeholder": "",
              label: "Email template (optional)",
              "item-text": "name",
              "item-value": "id"
            },
            model: {
              value: _vm.paymentPlan.emailTemplateId,
              callback: function($$v) {
                _vm.$set(_vm.paymentPlan, "emailTemplateId", $$v)
              },
              expression: "paymentPlan.emailTemplateId"
            }
          })
        : _vm._e(),
      _vm.paymentPlan.autoSend
        ? _c("div", { staticClass: "font-12 font-gray_70 mt-1" }, [
            _vm._v(
              ' Automatically send to all "Invoice" and "Default" contacts on the client record. '
            )
          ])
        : _c("div", { staticClass: "font-12 font-gray_70 mt-1" }, [
            _vm._v(
              " You will receive a notification after the invoice is generated letting you know you to approve and send. "
            )
          ]),
      _c("hr", {
        staticClass: "my-3",
        staticStyle: {
          "border-top": "1px solid var(--v-gray_50-base)",
          "border-bottom": "none"
        }
      }),
      _vm.paymentsEnabled
        ? _c("v-checkbox", {
            attrs: {
              "hide-details": "",
              dense: "",
              label: "Automatically charge on due date"
            },
            model: {
              value: _vm.paymentPlan.autoCharge,
              callback: function($$v) {
                _vm.$set(_vm.paymentPlan, "autoCharge", $$v)
              },
              expression: "paymentPlan.autoCharge"
            }
          })
        : _vm._e(),
      _vm.paymentPlan.autoCharge && _vm.paymentsEnabled
        ? _c("v-select", {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: {
                  content:
                    "This setting will allow you to select a specific payment method.  If this field is left blank,  the default method will be used to process payment."
                },
                expression:
                  "{\n\t\t\tcontent:\n\t\t\t\t'This setting will allow you to select a specific payment method.  If this field is left blank,  the default method will be used to process payment.',\n\t\t}"
              }
            ],
            staticClass: "mt-3",
            attrs: {
              "hide-details": "",
              "persistent-placeholder": "",
              dense: "",
              outlined: "",
              label: "Auto charge payment method (optional)",
              items: _vm.paymentMethods,
              "item-value": "id",
              "return-object": true,
              clearable: ""
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        { staticStyle: { "text-transform": "capitalize" } },
                        [
                          _vm._v(
                            _vm._s(item.label.toLowerCase()) +
                              " - " +
                              _vm._s(item.last4)
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "selection",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        { staticStyle: { "text-transform": "capitalize" } },
                        [
                          _vm._v(
                            _vm._s(item.label.toLowerCase()) +
                              " - " +
                              _vm._s(item.last4)
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              104597948
            ),
            model: {
              value: _vm.paymentPlan.paymentMethod,
              callback: function($$v) {
                _vm.$set(_vm.paymentPlan, "paymentMethod", $$v)
              },
              expression: "paymentPlan.paymentMethod"
            }
          })
        : _vm._e(),
      _c("hr", {
        staticClass: "my-3",
        staticStyle: {
          "border-top": "1px solid var(--v-gray_50-base)",
          "border-bottom": "none"
        }
      }),
      _c(
        "div",
        {
          staticClass: "font-primary pointer font-14 text-center",
          on: { click: _vm.addPayment }
        },
        [_vm._v("+ Add payment event")]
      ),
      _vm._l(_vm.paymentPlan.scheduledPayments, function(scheduledPayment) {
        return _c(
          "div",
          {
            key: scheduledPayment.id,
            staticClass: "column-format gap-2 py-3",
            staticStyle: { "border-bottom": "1px solid var(--v-gray_30-base)" }
          },
          [
            _c("v-text-field", {
              attrs: {
                "hide-details": "",
                dense: "",
                outlined: "",
                "persistent-placeholder": "",
                label: "Label",
                disabled: scheduledPayment.mileStoneAchieved
              },
              model: {
                value: scheduledPayment.label,
                callback: function($$v) {
                  _vm.$set(scheduledPayment, "label", $$v)
                },
                expression: "scheduledPayment.label"
              }
            }),
            _c(
              "div",
              { staticClass: "row-format gap-2" },
              [
                _c("v-select", {
                  staticStyle: { flex: "1 1 0" },
                  attrs: {
                    items: ["Milestone", "Date"],
                    "hide-details": "",
                    dense: "",
                    "persistent-placeholder": "",
                    outlined: "",
                    label: "Payment type",
                    disabled: scheduledPayment.mileStoneAchieved
                  },
                  model: {
                    value: scheduledPayment.triggerType,
                    callback: function($$v) {
                      _vm.$set(scheduledPayment, "triggerType", $$v)
                    },
                    expression: "scheduledPayment.triggerType"
                  }
                }),
                scheduledPayment.triggerType === "Date"
                  ? _c("date-selector", {
                      staticStyle: { flex: "1 1 0" },
                      attrs: {
                        standard: true,
                        label: "Date",
                        date: scheduledPayment.triggerDate,
                        disabled: scheduledPayment.mileStoneAchieved
                      },
                      on: {
                        change: function($event) {
                          scheduledPayment.triggerDate = $event
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            !scheduledPayment.finalPayment
              ? _c("div", { staticClass: "column-format gap-2" }, [
                  _c(
                    "div",
                    { staticClass: "row-format gap-2" },
                    [
                      _c("v-select", {
                        staticStyle: { flex: "1 1 0" },
                        attrs: {
                          items: _vm.types,
                          "item-text": "label",
                          "item-value": "value",
                          "hide-details": "",
                          dense: "",
                          "persistent-placeholder": "",
                          outlined: "",
                          label: "Amount type",
                          disabled: scheduledPayment.mileStoneAchieved
                        },
                        model: {
                          value: scheduledPayment.amountType,
                          callback: function($$v) {
                            _vm.$set(scheduledPayment, "amountType", $$v)
                          },
                          expression: "scheduledPayment.amountType"
                        }
                      }),
                      _c("v-text-field", {
                        staticStyle: { flex: "1 1 0" },
                        attrs: {
                          prefix:
                            scheduledPayment.amountType === "Fixed"
                              ? _vm.currencySymbol
                              : "",
                          suffix:
                            scheduledPayment.amountType === "Percentage"
                              ? "%"
                              : "",
                          numeric: "",
                          "hide-details": "",
                          dense: "",
                          "persistent-placeholder": "",
                          outlined: "",
                          label: "Amount",
                          oninput:
                            "this.value=this.value.replace(/(\\..*)\\./g, '$1');",
                          disabled: scheduledPayment.mileStoneAchieved
                        },
                        model: {
                          value: scheduledPayment.amount,
                          callback: function($$v) {
                            _vm.$set(scheduledPayment, "amount", $$v)
                          },
                          expression: "scheduledPayment.amount"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text-left font-14" }, [
                    _c(
                      "div",
                      { staticClass: "row-format" },
                      [
                        _c("div", { staticClass: "brand-medium" }, [
                          _vm._v("Amount:")
                        ]),
                        _c("div", { staticClass: "ml-auto" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$formatters.dollars(
                                  scheduledPayment.calculatedAmount,
                                  true,
                                  true,
                                  _vm.invoice.currency
                                )
                              ) +
                              " (" +
                              _vm._s(
                                scheduledPayment.calculatedPercentage.toFixed(2)
                              ) +
                              "%) "
                          )
                        ]),
                        !scheduledPayment.mileStoneAchieved
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "pointer",
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.deletePayment(scheduledPayment)
                                  }
                                }
                              },
                              [_vm._v("delete")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              : _c("div", { staticClass: "text-left font-14 row-format" }, [
                  _c("div", { staticClass: "brand-medium" }, [
                    _vm._v("Final payment:")
                  ]),
                  _c("div", { staticClass: "ml-auto" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatters.dollars(
                            scheduledPayment.calculatedAmount,
                            true,
                            true,
                            _vm.invoice.currency
                          )
                        ) +
                        " (" +
                        _vm._s(
                          scheduledPayment.calculatedPercentage.toFixed(2)
                        ) +
                        "%) "
                    )
                  ])
                ])
          ],
          1
        )
      }),
      _vm.errorState
        ? _c(
            "div",
            {
              staticClass: "mt-2 font-14 brand-medium",
              staticStyle: { color: "var(--v-error-base)" }
            },
            [
              _c("v-icon", { attrs: { color: "error", size: "20" } }, [
                _vm._v("warning")
              ]),
              _vm._v(
                " Please correct errors in your payment plan. Final payment amount must be greater than 0%. "
              ),
              _c("v-icon", { attrs: { color: "error", size: "20" } }, [
                _vm._v("warning")
              ])
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }