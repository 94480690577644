var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.automationDetail
        ? _c(
            "v-container",
            { staticClass: "font-14", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "3" } }, [_vm._v("ID")]),
                  _c(
                    "v-col",
                    { staticClass: "brand-medium", attrs: { cols: "9" } },
                    [_vm._v(_vm._s(_vm.automationDetail.id))]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "3" } }, [_vm._v("Status")]),
                  _c(
                    "v-col",
                    { staticClass: "brand-medium", attrs: { cols: "9" } },
                    [_vm._v(_vm._s(_vm.automationDetail.status))]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "3" } }, [_vm._v("Workflow")]),
                  _c(
                    "v-col",
                    { staticClass: "brand-medium", attrs: { cols: "9" } },
                    [
                      _vm.automationDetail.workflow
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.automationDetail.workflow.name))
                          ])
                        : _c("span", [_vm._v("--")])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "3" } }, [_vm._v("Scope")]),
                  _c(
                    "v-col",
                    { staticClass: "brand-medium", attrs: { cols: "9" } },
                    [
                      _vm._v(
                        _vm._s(_vm.automationDetail.scope.type) +
                          ": " +
                          _vm._s(_vm.automationDetail.scope.name)
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.detailClient
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [_vm._v("Client")]),
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "brand-medium row-format align-center gap-2",
                          attrs: { cols: "9" }
                        },
                        [
                          _c("client-avatar", {
                            attrs: { client: _vm.detailClient, small: "" }
                          }),
                          _c("div", [_vm._v(_vm._s(_vm.detailClient.name))])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.automationDetail.waitingFor
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "3" } }, [
                        _vm._v("Waiting for")
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "row-format gap-2 brand-medium",
                          attrs: { cols: "9" }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.automationDetail.waitingFor.waitType) +
                                " - "
                            )
                          ]),
                          _vm.automationDetail.waitingFor.waitingUntil
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.HDateTime.fromISO(
                                      _vm.automationDetail.waitingFor
                                        .waitingUntil
                                    ).toLocaleString(_vm.HDateTime.DATETIME_MED)
                                  )
                                )
                              ])
                            : _vm.automationDetail.waitingFor.resourceName
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.automationDetail.waitingFor.resourceName
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "3" } }, [_vm._v(" Events ")]),
                  _c(
                    "v-col",
                    {
                      staticClass: "brand-medium column-format",
                      attrs: { cols: "9" }
                    },
                    _vm._l(_vm.automationDetail.events, function(event) {
                      return _c(
                        "div",
                        {
                          key: event.id,
                          staticClass: "py-2",
                          staticStyle: {
                            "border-bottom": "1px solid var(--v-gray_30-base)"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.HDateTime.fromISO(
                                  event.timestamp
                                ).toLocaleString(_vm.HDateTime.DATETIME_SHORT)
                              ) +
                              " - " +
                              _vm._s(event.event) +
                              " "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "row-format gap-2 mt-3",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary-action",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.setAutomationDetail(null)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("arrow_back")]),
                          _vm._v(" Back ")
                        ],
                        1
                      ),
                      _vm.automationDetail.status === "Waiting" ||
                      _vm.automationDetail.status === "Active"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "primary-action",
                              on: {
                                click: function($event) {
                                  return _vm.cancelAutomation(
                                    _vm.automationDetail
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: { size: "20" }
                                },
                                [_vm._v("cancel")]
                              ),
                              _vm._v(" Cancel automation ")
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "primary-action",
                              on: {
                                click: function($event) {
                                  return _vm.deleteAutomation(
                                    _vm.automationDetail
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: { size: "20" }
                                },
                                [_vm._v("delete")]
                              ),
                              _vm._v(" Delete ")
                            ],
                            1
                          ),
                      _vm.automationDetail.status === "Waiting"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "primary-action",
                              on: {
                                click: function($event) {
                                  return _vm.restartAutomation(
                                    _vm.automationDetail,
                                    false
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: { size: "20" }
                                },
                                [_vm._v("fast_forward")]
                              ),
                              _vm._v(" Skip forward ")
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.automationDetail.status === "Error"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "primary-action",
                              on: {
                                click: function($event) {
                                  return _vm.restartAutomation(
                                    _vm.automationDetail,
                                    true
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: { size: "20" }
                                },
                                [_vm._v("restart_alt")]
                              ),
                              _vm._v(" Retry failed step ")
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("v-data-table", {
            staticClass: "pointer",
            attrs: {
              items: _vm.fixedAutomations,
              headers: _vm.headers,
              "hide-default-footer": true,
              "items-per-page": -1
            },
            on: {
              "click:row": function($event) {
                return _vm.setAutomationDetail($event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item.timestamp",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatters.formatTimeAgo(item.timestamp)) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.scope",
                fn: function(ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.scope.name) + " ")]
                }
              },
              {
                key: "item.workflow",
                fn: function(ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(item.workflow.name) + " ")]
                }
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }