<template>
	<div class="column-format centered" style="height:100%">
		<div class="row-format" style="width:100%">
			<div class="ml-auto" style="width:150px">
				<v-select :items="items" v-model="view" dense hide-details outlined style="background-color: var(--v-white-base)"></v-select>
			</div>
		</div>
		<div>
			<bar :key="barRefreshKey" v-if="view === 'Trend'" :chart-id="barRefreshKey" datasetIdKey="insight-time-bar" :chart-options="barChartOptions" :chart-data="barChart" :width="450" :height="300" />
			<pie :key="pieRefreshKey" v-else :chart-id="pieRefreshKey" datasetIdKey="insight-time-pie" :chart-options="pieChartOptions" :chart-data="pieChartData" :width="450" :height="300" />
		</div>
	</div>
</template>

<script>
	import { Pie, Bar } from 'vue-chartjs/legacy';
	import DateTime from "@/modules/utils/HDateTime";
	import {v4 as uuidv4} from 'uuid';
	import ClientPalette from "@/modules/clients/ClientPalette";


	export default {
		name: 'TimeChart',

		props: {
			scope: {
				type: String
			},
			earliest: {
				type: Object
			},
			latest: {
				type: Object
			},
			clients: {
				type: Array,
			},
			timeWorked: {
				type: Array,
			},
		},

		components: { Pie, Bar },

		data: function () {
			return {
				clientPalette: new ClientPalette(),
				view: 'By client',
				barRefreshKey: uuidv4(),
				pieRefreshKey: uuidv4(),
				items: ['By client','Trend', 'By user'],

				pieChartOptions: {
					responsive: false,
					maintainAspectRatio: false,
					plugins: {
						tooltip: {
							callbacks: {
								label: (context) => {
									return context.label + '\n' + this.$formatters.formatSeconds(context.raw);
								},
							}
						}
					}
				},

				barChartOptions: {
					responsive: false,
					maintainAspectRatio: false,
					scales: {
						x: {
							stacked: true,
							grid: {
								display: false,
							},
							ticks: {
								autoSkip: true,
								maxTicksLimit: 6,
								maxRotation: 0,
								minRotation: 0,
							}
						},
						y: {
							stacked: true
						}
					}
				}

			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			groupBy: function (array, key) {
				return array.reduce((result, currentValue) => {
					(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
					return result;
				}, {});
			},
		},

		computed: {
			timeWorkedByClient: function () {
				let grouped = this.groupBy(this.timeWorked, 'clientId');
				let result = [];

				for (let g in grouped) {
					let group = grouped[g];
					let clientTotal = group.reduce((sum, time) => sum + time.duration, 0);
					let client = this.clients.find((c) => c.id === g);

					if (!client) {
						client = {
							name: g === 'null' ? 'No client' : '[Deleted]',
							color: '#eee'
						};
					}

					result.push({
						clientId: g,
						clientName: client.name,
						color: client.color,
						total: clientTotal.toFixed(2),
					});
				}
				return result;
			},

			timeWorkedByUser: function () {
				let grouped = this.groupBy(this.timeWorked, 'userId');
				let result = [];

				for (let g in grouped) {
					let group = grouped[g];
					let userId = parseInt(g,10);
					let clientTotal = group.reduce((sum, time) => sum + time.duration, 0);
					let user = this.$store.state.usersForAccount.find((u) => u.userId === userId);
					
					if (!user) {
						user = {
							name: '[Unknown]',
							color: '#eee'
						};
					}

					result.push({
						clientId: g,
						clientName: user.firstName + ' ' + user.lastName,
						color: this.clientPalette.getRandomColor(),
						total: clientTotal.toFixed(2),
					});
				}
				return result;
			},

			barChart: function(){
				let result = {
					labels: this.barChartLabels,
					datasets: this.barChartData
				}

				return result;
			},

			barChartLabels: function(){
				let start = this.earliest.endOf(this.scope);
				let end = this.latest.endOf(this.scope);
				let labels = [];

				let increment = {}
				increment[this.scope + 's'] = 1;

				while(start <= end) {
					labels.push(this.scope === 'month' ? start.toFormat('MMM yy') : start.toFormat('MMM dd'));
					start = start.plus(increment).endOf(this.scope);
				}
				return labels;
			},

			barChartData: function(){
				let clients = [];

				let increment = {}
				increment[this.scope + 's'] = 1;

				let clientsInPeriod = new Set(this.timeWorked.map(i => i.clientId));

				clientsInPeriod.forEach((clientId) => {
					let c = this.clients.find((c) => c.id === clientId);
					let start = this.earliest.endOf(this.scope);
					let end = this.latest.endOf(this.scope);
					let totals = [];

					let client = {
						clientId: clientId,
						data: [],
						label: c ? c.name : 'No client',
						backgroundColor: c ? c.color : '#eee'
					};

					while(start <= end) {
						totals.push({
							key: start.toISODate(),
							total: 0,
						})
						start = start.plus(increment).endOf(this.scope);
					}

					this.timeWorked.filter(i => i.clientId === clientId).forEach(i => {
						let timerStart = DateTime.fromISO(i.timerStart).endOf(this.scope);
						let total = totals.find(k => k.key === timerStart.toISODate());
						if(total) {
							total.total += i.duration;
						}
					});

					client.data = totals.map(t => (t.total/60/60).toFixed(1));
					clients.push(client);
				});

				return clients;
			},

			pieChartData: function () {
				let byClient;
				if(this.view === 'By client'){
					byClient = this.timeWorkedByClient;
				}else{
					byClient = this.timeWorkedByUser;
				}

				return {
					id: 'fd635f2c-bfc1-4a31-ade6-302a25518edd',
					labels: byClient.map((b) => b.clientName),
					datasets: [
						{
							label: 'Time',
							data: byClient.map((b) => b.total),
							backgroundColor: byClient.map((b) => b.color),
						},
					],
				};
			},
		},

		watch: {
			barChart: function(){
				this.barRefreshKey = uuidv4();
			},

			pieChartData: function(){
				this.pieRefreshKey = uuidv4();
			}
		}
	};
</script>

<style scoped lang="scss"></style>
