<template>
	<div style="min-width: 500px; max-width: 500px">
		<v-btn icon class="close-dialog" @click="handleClose">
			<v-icon>$close</v-icon>
		</v-btn>
		<v-form @submit.prevent="connectAccount()" v-model="formValid">
			<div class="modal-padding">
				<div class="modal-title row-format align-center"><v-icon color="secondary" class="mb-1 mr-3">event</v-icon> <div>Connect CalDav Calendar</div></div>

				<div class="modal-subtitle">To connect your CalDav calendar please provide credentials and the CalDav URL</div>
				<div class="pt-2">
				<v-text-field
						:rules="emailRules"
						v-model="email"
						type="text"
						autofocus
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Username"
				></v-text-field>
				<v-text-field
						:rules="passwordRules"
						v-model="password"
						type="password"
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Password"
				></v-text-field>
					<v-text-field
							:rules="required"
							v-model="url"
							hide-details
							persistent-placeholder
							class="h-outline"
							label="Calendar URL"
					></v-text-field>
					<v-text-field
							:rules="required"
							v-model="name"
							hide-details
							persistent-placeholder
							class="h-outline"
							label="Calendar display name"
					></v-text-field>
				</div>
				<div style="color: var(--v-error-base)" class="text-left font-14 pt-2" v-if="errorMessage">
					{{errorMessage}}
				</div>
			</div>
		</v-form>
		<div class="pt-2 pb-4" style="border-top: 1px solid var(--v-gray_50-base)">
			<v-btn class="secondary-action mr-1" width="150" @click="handleClose">Cancel</v-btn>
			<v-btn class="primary-action ml-1" width="150" :disabled="!formValid" @click="connectAccount">Connect</v-btn>
		</div>
	</div>
</template>

<script>
import CalDavCalendarService from "@/modules/integrations/caldav/CalDavCalendarService";

export default {
	name: "CalDavConnect",

	props: [],

	components: {},

	data: function () {
		return {
			calDavService: new CalDavCalendarService(),
			formValid: false,
			email:null,
			password:null,
			url:null,
			name:null,
			errorMessage: null,
			emailRules: [
				(v) => !!v || 'Username is required'
			],
			passwordRules: [
				(v) => !!v || 'Password is required',
			],
			required: [
				(v) => !!v || 'Field is required',
			],
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		connectAccount: function(){
			this.calDavService.connect(this.email,this.password,this.url,this.name).then((res) => {
				this.$emit('result',res.data);
			}).catch((err) => {
				this.errorMessage = 'There was an error connecting your CalDav account: ' + err.response.data.message;
			})
		},

		handleClose: function(){
			this.$emit('result');
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>