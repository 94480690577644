var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "mt-4",
          staticStyle: { "max-width": "985px!important" }
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticStyle: { "font-size": "48px" } }, [
                  _vm._v(_vm._s(_vm.$t("subscription-sumo.heading")))
                ]),
                _c(
                  "div",
                  {
                    staticClass: "brand-semilight",
                    staticStyle: { "font-size": "32px" }
                  },
                  [_vm._v(_vm._s(_vm.$t("subscription-sumo.sub-heading")))]
                )
              ])
            ],
            1
          ),
          _vm.$store.getters.isDisabledAccount
            ? _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-1 brand-medium row-format centered gap-2"
                      },
                      [
                        _c("v-icon", { attrs: { color: "error" } }, [
                          _vm._v("info")
                        ]),
                        _c(
                          "div",
                          { staticStyle: { color: "var(--v-error-base)" } },
                          [
                            _vm._v(
                              " Your current subscription has expired. Please select a new plan to continue using Moxie. "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format gap-4 pa-4",
                    staticStyle: { "justify-content": "center" }
                  },
                  _vm._l(_vm.plans, function(plan) {
                    return _c("plan-card", {
                      key: plan.code,
                      attrs: { "current-plan": _vm.currentPlan, plan: plan }
                    })
                  }),
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "div",
                    { staticClass: "brand-medium font-24 text-left mt-8" },
                    [_vm._v("Frequently Asked Questions")]
                  ),
                  _vm._l(_vm.faq, function(q, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "text-left brand-semilight my-4 pa-5",
                        staticStyle: {
                          "background-color": "var(--v-gray_10-base)",
                          "border-radius": "12px"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "font-20 row-format pointer",
                            on: {
                              click: function($event) {
                                _vm.isExpanded(index)
                                  ? _vm.collapseFaq(index)
                                  : _vm.expandFaq(index)
                              }
                            }
                          },
                          [
                            _c("div", [_vm._v(_vm._s(q.question))]),
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-auto",
                                attrs: { color: "black" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.isExpanded(index) ? "$minus" : "$plus"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.isExpanded(index)
                          ? _c(
                              "div",
                              {
                                staticClass: "mt-3",
                                staticStyle: { "white-space": "pre-wrap" }
                              },
                              [_vm._v(_vm._s(q.answer))]
                            )
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }