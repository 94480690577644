<template>
	<div>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'PrimaryLayout',

		computed: {},
	};
</script>

<style scoped></style>
