<template>
	<div>
		<div class="words pb-3">
			<editor
				style="border: 1px dotted var(--v-grey-lighten3);"
				ref="editor"
				@input="handleChange"
				:api-key="$store.getters.getTinyMceKey"
				:initial-value="initialValue"
				:inline="true"
				:init="mceConfigText"
				:spellcheck="true"
				:placeholder="textPlaceholder"
			></editor>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TextBlock',
		props: ['id', 'htmlText', 'htmlHeader', 'mceConfigHeader', 'mceConfigText', 'headerPlaceholder', 'textPlaceholder'],

		components: { editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue') },

		data() {
			return {
				lHtmlText: this.htmlText,
				lHtmlHeader: this.htmlHeader,
				initialValue: null,
			};
		},

		mounted() {
			if(this.htmlHeader){
				this.initialValue = this.htmlHeader + '<br>'
			}else{
				this.initialValue = '';
			}
			this.initialValue = this.initialValue + this.htmlText;
		},

		methods: {
			notifyChange() {
				this.$emit('changed', {
					id: this.id,
					fieldType: 'TextBlock',
					htmlText: this.lHtmlText,
					htmlHeader:null,
				});
			},

			handleChange() {
				this.lHtmlText = this.$refs.editor.editor.getContent({format:'html'});
				this.notifyChange();
			},
		},

		computed: {
			key() {
				return process.env.VUE_APP_TINYMCE_KEY;
			},
		},
	};
</script>

<style scoped lang="scss">
	.question {
		font-size: 16px;
		font-weight: 300;
	}
	.words {
		font-size: 16px;
	}
</style>
