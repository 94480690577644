var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mock-scheduler pa-3", attrs: { id: "mock-scheduler" } },
    [
      _c(
        "div",
        {
          style:
            "max-width: 900px; font-family: '" +
            _vm.meeting.pageLayout.fontFamily +
            "';"
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  style:
                    "background-color: " +
                    _vm.meeting.pageLayout.backgroundColor +
                    "; border-right: 1px solid var(--v-gray_30-base)",
                  attrs: { sm: "5", cols: "12" }
                },
                [
                  _c("scheduler-info", {
                    attrs: { meeting: _vm.meeting, tokenMap: _vm.tokenMap }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  style:
                    "background-color: " +
                    _vm.meeting.pageLayout.backgroundMainColor,
                  attrs: { sm: "7", cols: "12" }
                },
                [
                  _vm.viewSelect
                    ? _c("calendar", { attrs: { meeting: _vm.meeting } })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "mt-6 text-left font-14 font-primary" }, [
        _vm._v(
          " *Meeting dates and times on preview do not reflect actual availability "
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }