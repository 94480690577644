var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: (_vm.hasContent ? "" : "content-block") + " py-2" },
    [
      !_vm.editMode
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.initialContent) } })
        : _c("editor", {
            ref: "block",
            attrs: {
              "api-key": _vm.$store.getters.getTinyMceKey,
              "initial-value": _vm.initialContent,
              inline: true,
              init: _vm.mceConfig,
              spellcheck: true
            },
            on: {
              input: function($event) {
                return _vm.handleContentUpdated()
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }