<template>
	<basic-modal v-if="isOpen" :dialog="isOpen" :persistent="false" :close-on-escape="true" @close="isOpen = false">
		<div style="background-color: var(--v-white-base)">
			<div class="row-format px-4 pt-4">
				<div class="font-16">{{title}}</div>
				<v-icon class="ml-auto pointer" size="20" @click="isOpen = false">close</v-icon>
			</div>
			<div class="pa-4">
				<slot></slot>
			</div>
			<div style="border-top: 1px solid var(--v-gray_30-base)" class="py-2">
				<v-btn width="140" class="primary-action" @click="save">Save</v-btn>
			</div>
		</div>
	</basic-modal>
</template>

<script>
	import BasicModal from "@/components/BasicModal";

	export default {
		name: 'NodeModal',

		props: ['title'],

		components: {BasicModal},

		data: function() {
			return {
				isOpen: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			save: function(){
				this.$store.state.eventBus.$emit('workflow-resize');
				this.$emit('save');
				this.isOpen = false;
			},

			open: function(){
				this.isOpen = true;
			},

			close: function(){
				this.isOpen = false;
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
