<template>
	<div class="invoice-action-menu">
		<input type="hidden" :id="`copyInput${invoice.id}`" :value="invoice.viewOnlineUrl">
		<v-menu :close-on-click="true" :nudge-bottom="30" :disabled="!menuEnabled">
			<template v-slot:activator="scope">
				<div class="ml-1">
					<v-btn icon class="menu-activator" :disabled="!menuEnabled" v-on="scope.on">
						<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
					</v-btn>
				</div>
			</template>
			<div class="more-menu">
				<div class="more-menu-item" @click="$emit('view-detail', invoice)" v-if="showView">
					{{ $t('global.view') }}
				</div>
				<div
					class="more-menu-item"
					@click="showModal('SEND')"
					v-if="['DRAFT', 'SENT', 'PARTIAL', 'PAID'].includes(invoice.status) && sendEnable"
				>
					{{ sendVerb }}
				</div>

				<div
						class="more-menu-item"
						@click="showConfirmDialog('MARK_SENT')"
						v-if="['DRAFT'].includes(invoice.status)"
				>
					{{ $t('invoice.mark-as-sent') }}
				</div>
				<div class="more-menu-item" @click="showModal('MARK-PAID')" v-if="['SENT', 'PARTIAL'].includes(invoice.status)">
					{{ $t('invoice.record-payment') }}
				</div>
				<div class="more-menu-item" @click="copyLink()" v-if="invoice.status !== 'DRAFT'">
					{{ $t('invoice.copy-link') }}
				</div>
				<div class="more-menu-item" @click="downloadInvoice()" v-if="invoice.status !== 'DRAFT'">
					{{ $t('invoice.download') }}
				</div>
				<div class="more-menu-item" @click="duplicateInvoice()" v-if="showDuplicate">
					{{ $t('invoice.duplicate') }}
				</div>
				<div class="more-menu-item" @click="showConfirmDialog('DRAFT')" v-if="invoice.status !== 'DRAFT'">
					{{ $t('invoice.return-to-draft') }}
				</div>
				<div class="more-menu-item" @click="waiveLateFee(true)" v-if="invoice.status !== 'PAID' && lateFee && !invoice.lateFeeWaived">
					{{ $t('invoice.details.waive-late-fee') }}
				</div>
				<div class="more-menu-item" @click="waiveLateFee(false)" v-if="invoice.lateFeeWaived">
					{{ $t('invoice.details.apply-late-fee') }}
				</div>
				<div
					class="more-menu-item"
					@click="showConfirmDialog('DELETE')"
					v-if="invoice.status == 'DRAFT' || invoice.status == 'VOIDED'"
				>
					{{ $t('global.delete') }}
				</div>
				<div class="more-menu-item" @click="showModal('VOID')" v-if="invoice.status == 'SENT'">
					{{ $t('invoice.void') }}
				</div>
				<div class="more-menu-item" @click="showModal('WRITEOFF')" v-if="['SENT', 'PARTIAL'].includes(invoice.status)">
					{{ $t('invoice.statuses.WRITE-OFF') }}
				</div>
			</div>
		</v-menu>
		<basic-modal :dialog="selectedModal === 'MARK-PAID'" v-on:close="selectedModal = null" :persistent="false" :key="modalKey">
			<mark-paid-modal :invoice="invoice" @save="markPaid($event)" @close="selectedModal = null"></mark-paid-modal>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'SEND'" v-on:close="selectedModal = null" :persistent="false" :max-width="500">
			<send-widget
				v-if="sendEnable"
				:client-id="invoice.clientId"
				:invoice-id="invoice.id"
				:status="invoice.status"
				:open-state="selectedModal === 'SEND'"
				@alert="scope.setAlert($event)"
				@cancel="handleSendCancel($event)"
				@invoice-sent="invoiceSent($event)"
			></send-widget>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'VOID'" v-on:close="selectedModal = null" :persistent="false">
			<void-modal :invoice="invoice" @save="voidInvoice($event)" @close="selectedModal = null" :key="modalKey"></void-modal>
		</basic-modal>
		<basic-modal
			:dialog="selectedModal === 'WRITEOFF'"
			v-on:close="selectedModal = null"
			:persistent="false"
			:max-width="540"
		>
			<write-off-modal
				:invoice="invoice"
				@save="writeOff($event)"
				@close="selectedModal = null"
				:key="modalKey"
			></write-off-modal>
		</basic-modal>
		<confirm-dialog
			:dialog="confirmDialog === 'DELETE'"
			@close="confirmDialog = null"
			@confirm="deleteInvoice()"
			heading-text="Confirm"
			yes-text="Yes"
			no-text="No"
			:body-text="$t('invoice.confirm-dialog.delete')"
		>
		</confirm-dialog>
		<confirm-dialog
			:dialog="confirmDialog == 'DRAFT'"
			@close="confirmDialog = null"
			@confirm="returnToDraft()"
			heading-text="Confirm"
			yes-text="Yes"
			no-text="No"
			:body-text="$t('invoice.confirm-dialog.return-to-draft')"
		>
		</confirm-dialog>
		<confirm-dialog
				:dialog="confirmDialog === 'MARK_SENT'"
				@close="confirmDialog = null"
				@confirm="markAsSent()"
				heading-text="Confirm"
				yes-text="Yes"
				no-text="No"
				:body-text="$t('invoice.confirm-dialog.mark-as-sent')"
		>
		</confirm-dialog>
	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import SendWidget from '@/modules/invoices/SendWidget';
	import VoidModal from '@/modules/invoices/VoidModal';
	import WriteOffModal from '@/modules/invoices/WriteOffModal';
	import MarkPaidModal from '@/modules/invoices/MarkPaidModal';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import BasicModal from '@/components/BasicModal';
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'ActionMenu',

		components: {
			BasicModal,
			SendWidget,
			VoidModal,
			WriteOffModal,
			ConfirmDialog,
			MarkPaidModal,
		},
		props: {
			invoice: { type: Object, required: true },
			sendEnable: { type: Boolean, required: false, default: false },
			showView: { type: Boolean, required: false, default: false },
			showDuplicate: { type: Boolean, required: false, default: true },
			isList: { type: Boolean, required: false, default: false },
		},

		data: function() {
			return {
				invoiceService: new InvoiceService(),
				selectedModal: null,
				confirmDialog: null,
				modalKey: 0,
				billable: null, /* Container for invoiceService.getBillable() result */
			};
		},

		mounted() {
			// console.log('ActionMenu mounted()', this.invoice);
		},

		beforeDestroy() {},

		methods: {
			showModal(modal) {
				this.modalKey++;
				this.selectedModal = modal;
			},
			showConfirmDialog(dialog) {
				this.confirmDialog = dialog;
			},
			startLoading(){
				this.$store.commit('startLoading');
			},
			stopLoading(){
				this.$store.commit('stopLoading');
			},
			deleteInvoice() {
				this.startLoading();
				this.invoiceService
					.deleteInvoice(this.invoice.clientId, this.invoice.id)
					.then(() => {
						this.alert('success', 'Invoice deleted');
						this.$emit('delete-invoice', this.invoice.id);
						// this.$router.push('/invoices/');
					})
					.catch((err) => {
						console.log(err);
						this.alert('error', err.response.data.message);
					}).finally(() => this.stopLoading());
			},
			voidInvoice(userNote) {
				this.startLoading();
				// if (this.invoice.status == 'VOIDED') return this.alert('error', 'Cannot void an invoice that is already voided');
				this.invoiceService
					.void(this.invoice.clientId, this.invoice.id, userNote)
					.then((res) => {
						this.alert('success', 'Invoice voided');
						this.$emit('set-invoice', res.data);
						this.showModal(null);
					})
					.catch((err) => {
						console.log(err);
						this.alert('error', err.response.data.message);
					}).finally(() => this.stopLoading());
			},
			async refreshInvoiceIfNeeded() {
				if (!this.isList) {
					return this.invoice;
				}
				// Get full version of invoice
				this.startLoading();
				return await this.invoiceService
					.getInvoice(this.invoice.clientId, this.invoice.id)
					.then((res) => {
						return res.data;
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
						this.$emit('error', err);
					}).finally(() => this.stopLoading());
			},
			invoiceSent(invoice) {
				this.$emit('set-invoice', invoice);
				this.showModal();
			},
			handleSendCancel(invoice){
				this.selectedModal = null;
				this.$emit('set-invoice', invoice);
			},
			markPaid(invoice) {
				this.$emit('paid',invoice.payments[invoice.payments.length-1]);
				this.$emit('set-invoice', invoice);
				this.showModal();
			},
			returnToDraft() {
				this.startLoading();
				this.invoiceService
					.returnToDraft(this.invoice.clientId, this.invoice.id)
					.then((res) => {
						this.alert('success', 'Invoice returned to "draft" status');
						this.$emit('set-invoice', res.data);
						this.showConfirmDialog(null);
					})
					.catch((err) => {
						console.log(err);
						this.alert('error', err.response.data.message);
					}).finally(() => this.stopLoading());
			},
			markAsSent() {
				this.startLoading();
				this.invoiceService
						.markAsSent(this.invoice.clientId, this.invoice.id)
						.then((res) => {
							this.alert('success', 'Invoice set to "sent" status');
							this.$emit('set-invoice', res.data);
							this.showConfirmDialog(null);
						})
						.catch((err) => {
							console.log(err);
							this.alert('error', err.response.data.message);
						}).finally(() => this.stopLoading());
			},
			writeOff(userNote) {
				this.startLoading();
				this.invoiceService
					.writeOff(this.invoice.clientId, this.invoice.id, userNote)
					.then((res) => {
						this.alert('success', 'Invoice written off');
						this.$emit('set-invoice', res.data);
						this.showModal(null);
					})
					.catch((err) => {
						console.log(err);
						this.alert('error', err.response.data.message);
					}).finally(() => this.stopLoading());
			},

			projectItemsExist() {
				if (!this.invoice.items) {
					return false;
				}
				for (let i = 0; i < this.invoice.items.length; i++) {
					if (this.invoice.items[i].projectId || this.invoice.items[i].type === 'HOURS') {
						return true;
					}
				}
				return false;
			},

			copyLink() {
				navigator.clipboard.writeText(this.invoice.viewOnlineUrl);
				this.$store.commit('success', 'Copied to clipboard');
			},

			downloadInvoice: function() {
				this.startLoading();
				this.invoiceService
						.downloadInvoice(this.invoice.clientId, this.invoice.id)
						.then((res) => {
							let fileURL = window.URL.createObjectURL(new Blob([res.data]));

							let fileLink = document.createElement('a');
							fileLink.href = fileURL;
							fileLink.setAttribute('download', this.invoice.invoiceNumberFormatted + '.pdf');
							document.body.appendChild(fileLink);
							fileLink.click();
						})
						.finally(() => this.stopLoading());
			},

			waiveLateFee(waived){
				this.startLoading();
				this.invoiceService.lateFee(this.invoice.clientId,this.invoice.id,waived).then((res) => {
					let message = waived ? 'Late fee waived' : 'Late fee re-applied';
					this.$store.commit('success',message);
					this.$emit('set-invoice', res.data);
				}).catch((err) => this.$store.commit('error',err.response.data.message)).finally(() => this.stopLoading());
			},

			duplicateInvoice() {
				this.refreshInvoiceIfNeeded()
				.then((invoice) => {
					if (this.projectItemsExist()) {
						let binding = {
							headingText: 'Notice',
							bodyText: 'Projects and/or time tracked to ' +
								invoice.clientInfo.name +
								' are in in this invoice. ' +
								'New billable time and/or deliverables related to these items will be added to the generated invoice. ' +
								'The new invoice can be modifed. Proceed?',
							icon: '$info',
							severity: 'info'
						};
						this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
							if (res) {
								this.createInvoice(invoice);
							}
						});
					} else {
						this.createInvoice(invoice);
					}

				})
				.catch((err)=>{
					console.log(err)
				});
			},

			createInvoice(invoice) {
				this.getBillableForClient(invoice.clientInfo.id)
					.then(()=> {
						let newInvoice = this.fixupDuplicatedInvoice(invoice);
						this.invoiceService.createInvoice(invoice.clientInfo.id, newInvoice)
							.then((res)=>{
								this.$emit('duplicate-invoice', res.data);
							})
							.catch((res)=>{
								console.log(res);
							})
					})
					.catch((res)=>{
						console.log(res);
					});
			},

			fixupDuplicatedInvoice(invoice) {
				console.log(invoice);
				// Go through items and leave ADHOC alone
				// Get new items as needed for PROJECT, DELIVERABLE, HOURS, RETAINER, EXPENSE
				let deleteIndexes = [];
				let items = [];
				if (invoice.items) {
					items = JSON.parse(JSON.stringify(invoice.items));
				}
				for (let i = 0; i < items.length; i++) {
					let item = items[i];
					let newItem;
					console.log(item);

					switch(item.type) {
						case 'DELIVERABLE':
							deleteIndexes.push(i);
							break;

						case 'PROJECT':
							newItem = this.getNewProject(item.projectId, item);
							if (newItem) {
								console.log(newItem);
							}
							break;

						case 'HOURS':
							if (item.projectId) {
								newItem = this.getNewProject(item.projectId, item);
								if (newItem && newItem.qty) {
									items.splice(i, 1, newItem);
								} else {
									deleteIndexes.push(i);
								}
								break;
							}
							newItem = this.getNewHours(item);
							if (newItem && newItem.qty) {
								items.splice(i, 1, newItem);
							} else {
								deleteIndexes.push(i);
							}
							break;

						case 'EXPENSE':
							deleteIndexes.push(i);
							break;

						default:
							// default case allows the item to be added to the new invoice
							break;
					}
				}
				// Delete what needs deletin'
				while(deleteIndexes.length) {
					items.splice(deleteIndexes.pop(), 1);
				}
				let newInvoice = {
					currency: invoice.currency,
					discountAmount: invoice.discountAmount,
					discountPercentage: invoice.discountPercentage,
					invoiceType: invoice.invoiceType,
					items: items,
					payInstructions: invoice.payInstructions,
					paymentTerms: invoice.paymentTerms,
					purchaseOrder: invoice.purchaseOrder,
					taxPercentage: invoice.taxPercentage,
					taxRateLocked: invoice.taxRateLocked,
					notes: invoice.notes,
				}
				return newInvoice;
			},


			getNewProject(projectId, item) {
				let project = this.billable.projects.find((p)=> p.id === projectId );

				let timerEventIds = [];
				for (let i = 0; i < project.projectHours.length; i++) {
					timerEventIds.push(project.projectHours[i].id);
				}

				let hours = 0;
				for (let j = 0; j < project.projectHours.length; j++) {
					let projectHour = project.projectHours[j];
					console.log(projectHour);
					hours += projectHour.duration / 3600;
				}

				let newItem = {
					projectId: project.id,
					projectName: project.name,
					description: item.description,
					qty: hours,
					type: 'HOURS',
					class: 'project',
					price: project.feeSchedule.amount,
					taxable: item.taxable,
					subtotal: (project.feeSchedule.amount * hours),
					timerEventIds: timerEventIds,
				}
				return newItem;
			},

			getNewHours(item) {
				let timerEventIds = [];
				for (let i = 0; i < this.billable.clientHours.length; i++) {
					timerEventIds.push(this.billable.clientHours[i].id);
				}

				let hours = 0;
				// let amount = 0;
				for (let j = 0; j < this.billable.clientHours.length; j++) {
					let clientHour = this.billable.clientHours[j];
					hours += clientHour.duration / 3600;
				}
				let amount = this.billable.client.hourlyAmount;
				let newItem = {
					projectId: null,
					projectName: this.billable.clientName,
					description: item.description,
					qty: hours,
					type: 'HOURS',
					class: 'client',
					price: amount,
					taxable: item.taxable,
					subtotal: (amount * hours),
					timerEventIds: timerEventIds,
				}
				return newItem;
			},

			// getNewExpenses() {
			// 	console.log('getNewExpenses');
			// },

			async getBillableForClient(clientId) {
				await this.invoiceService
					.getBillable(clientId)
					.then((res) => {
						console.log(res.data);
						this.billable = res.data;
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			async getNewInvoice(clientId) {
				await this.invoiceService
					.getBillable(clientId)
					.then((res) => {
						console.log(res.data);
						this.billable = res.data;
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
			alert(type, message) {
				this.$store.commit(type, message);
			},
		},
		computed: {
			lateFee: function() {
				return this.invoice.lateFeeApplied + this.invoice.lateFeeCalculated;
			},

			menuEnabled() {
				// if(this.invoice.total === 0 && this.invoice.status === 'PAID') return true;
				// if (this.sendEnable) return ['PAID', 'SENT', 'PARTIAL', 'DRAFT'].includes(this.invoice.status);
				// return ['SENT', 'PARTIAL', 'DRAFT', 'VOIDED'].includes(this.invoice.status);
				return true;
			},
			sendVerb() {
				if (['DRAFT'].includes(this.invoice.status)) return this.$t('invoice.send');
				return this.$t('invoice.resend');
			},
		},
		watch: {},
	};
</script>

<style lang="scss">
	#invoice-action-menu {
		.more-menu {
			max-width: 200px;
		}
	}
	//button.v-btn.menu-activator {
	//	height: 32px !important;
	//	width: 32px !important;
	//	min-width: 32px !important;
	//}
</style>
