<template>
	<div class="mt-8">
		<v-container fluid class="pa-0" v-if="customEmail">
			<v-row v-if="!customEmail.active">
				<v-col cols="12">
					<div class="row-format gap-3">
						<div class="row-format centered px-3 py-1 pointer button-box" @click="connectGoogleOAuth">
							<v-icon>$googleColor</v-icon>
							<div class="font-14 brand-medium ml-3">Connect Google Account<br />GMail / Google Workspace</div>
						</div>
						<div class="row-format centered px-3 py-1 pointer button-box" @click="connectMicrosoftEmail">
							<v-icon>$microsoft</v-icon>
							<div class="font-14 brand-medium ml-3">
								Connect Microsoft Account<br />Office365 / Outlook / Hotmail
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="customEmail.active && customEmail.authenticationType === 'OAuth'">
				<v-col cols="12">
					<div class="row-format">
						<div class="font-14 text-left">
							<div v-if="customEmail.active">
								Email settings verified <v-icon color="success" small>$success</v-icon>
							</div>
							<div v-else>
								<div><v-icon>$alert</v-icon> Email settings not active</div>
								<div class="font-italic font-12 mt-2">{{ customEmail.errorMessage }}</div>
							</div>
							<div>Type: {{ customEmail.oauthProvider }}</div>
							<div>Account: {{ customEmail.oauthName }}</div>
							<div>Email: {{ customEmail.oauthEmail }}</div>
						</div>
						<div class="ml-auto">
							<div
								class="row-format centered px-3 py-1 pointer button-box"
								@click="connectMicrosoftEmail"
								v-if="customEmail.oauthProvider === 'Office365'"
							>
								<v-icon>$microsoft</v-icon>
								<div class="font-14 brand-medium ml-3">
									Reconnect Microsoft Account<br />Office365 / Outlook / Hotmail
								</div>
							</div>
							<div
								class="row-format centered px-3 py-1 pointer button-box"
								@click="connectGoogleOAuth"
								v-if="customEmail.oauthProvider === 'Google'"
							>
								<v-icon>$googleColor</v-icon>
								<div class="font-14 brand-medium ml-3">
									Reconnect Google Account<br />Gmail / Google Workspace
								</div>
							</div>
						</div>
					</div>
				</v-col>
				<v-col cols="12" class="mt-8">
					<div class="column-format gap-4">
						<v-text-field
							label="From address (optional)"
							v-model="customEmail.fromEmail"
							placeholder="First Last <first@email.com>"
							persistent-placeholder
							hide-details
							dense
							outlined
						></v-text-field>
						<editor
							class="pa-2 text-left"
							style="width: 100%; min-height: 100px; border-bottom: none;"
							ref="editor"
							:api-key="$store.getters.getTinyMceKey"
							:initial-value="customEmail.signature"
							:inline="false"
							:init="mceConfigText"
							:spellcheck="true"
							placeholder="Signature"
							@input="handleSignatureUpdated"
						></editor>
					</div>
				</v-col>
				<v-col cols="12" class="mt-8">
					<div class="row-format gap-2 centered">
						<v-btn class="super-action" width="150" @click="saveFromName">Save</v-btn>
						<v-btn icon @click="confirmDelete"><v-icon class="material-symbols-rounded">delete</v-icon></v-btn>
					</div>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col cols="12">
					<div class="column-format gap-3">
						<div class="brand-medium mt-8" v-if="customEmail.active">Custom email settings</div>
						<div class="brand-medium mt-8" v-else>or Configure custom email settings</div>
						<v-form :disabled="!paidAccount">
							<div class="column-format gap-3">
								<v-text-field
									label="Username"
									v-model="customEmail.username"
									persistent-placeholder
									hide-details
									dense
									outlined
								></v-text-field>
								<v-text-field
									label="Password"
									type="password"
									v-model="password"
									persistent-placeholder
									hide-details
									dense
									outlined
								></v-text-field>
								<v-text-field
									label="From (optional)"
									v-model="customEmail.fromEmail"
									placeholder="First Last <first@email.com>"
									persistent-placeholder
									hide-details
									dense
									outlined
								></v-text-field>
								<editor
									class="pa-2 text-left"
									style="width: 100%; min-height: 100px; border-bottom: none;"
									ref="editor"
									:api-key="$store.getters.getTinyMceKey"
									:initial-value="customEmail.signature"
									:inline="false"
									:init="mceConfigText"
									:spellcheck="true"
									placeholder="Signature"
									@input="handleSignatureUpdated"
								></editor>
								<v-text-field
									label="SMTP Server"
									v-model="customEmail.smtpHost"
									persistent-placeholder
									hide-details
									dense
									outlined
								></v-text-field>
								<v-text-field
									label="SMTP Port"
									type="number"
									v-model="customEmail.smtpPort"
									persistent-placeholder
									hide-details
									dense
									outlined
								></v-text-field>
								<v-select
									label="Connection Security"
									v-model="customEmail.connectionSecurity"
									persistent-placeholder
									hide-details
									dense
									outlined
									:items="connectionSecurity"
								></v-select>
							</div>
						</v-form>

						<div v-if="paidAccount" class="row-format centered mt-4" style="gap: 8px">
							<v-btn class="super-action" width="150" @click="saveCustomEmail">Save</v-btn>
							<v-btn icon @click="confirmDelete"><v-icon class="material-symbols-rounded">delete</v-icon></v-btn>
						</div>

						<div v-else class="font-italic">This feature is not enabled on the Essentials plan.</div>

						<div class="font-14 mt-4">
							<div v-if="customEmail.active"><v-icon color="success">$success</v-icon> Email settings verified</div>
							<div v-else>
								<div><v-icon>$alert</v-icon> Email settings not active</div>
								<div class="font-italic font-12 mt-2">{{ customEmail.errorMessage }}</div>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import CustomEmailService from '@/modules/portal/CustomEmailService';
	import ConfirmModal from '@/components/ConfirmModal';
	import MicrosoftCustomEmailService from '@/modules/integrations/microsoft/MicrosoftCustomEmailService';
	import GoogleEmailService from '@/modules/integrations/google/GoogleEmailService';
	import editor from '@tinymce/tinymce-vue';

	export default {
		name: 'CustomEmail',

		props: ['filter'],

		components: { editor },

		data: function() {
			return {
				customEmailService: new CustomEmailService(),
				microsoftCustomEmailService: new MicrosoftCustomEmailService(),
				googleEmailService: new GoogleEmailService(),
				customEmail: null,
				password: null,

				connectionSecurity: [
					{ text: 'None', value: 'NONE' },
					{ text: 'SSL/TLS', value: 'SSLTLS' },
					{ text: 'STARTTLS', value: 'STARTTLS' },
				],

				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					paste_as_text: false,
					table_style_by_css: true,
					forced_root_block: false,
					paste_data_images: true,
					browser_spellcheck: true,
					plugins: ['paste', 'link', 'code'],
					contextmenu: 'paste | link',
					branding: false,
					statusbar: false,
					height: `200px`,
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: ['forecolor fontsizeselect | bold italic underline | link unlink | code'],
					fontsize_formats: '10pt 11pt 12pt 14pt 16pt 18pt 20pt',
				},
			};
		},

		mounted() {
			this.getCustomEmail();
			this.$store.state.eventBus.$on('account-changed', this.getCustomEmail);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getCustomEmail);
		},

		methods: {
			connectMicrosoftEmail: function() {
				this.$store.commit('startLoading');
				window.location.href = this.microsoftCustomEmailService.setupConnectRequest();
			},

			handleSignatureUpdated: function() {
				this.customEmail.signature = this.$refs.editor.editor.getContent();
			},

			openOtherHelp: function() {
				window.open('https://help.withmoxie.com/en/articles/5855652-client-portal-custom-email', '_blank');
			},

			getCustomEmail: function() {
				this.customEmailService.getCustomEmail().then((res) => (this.customEmail = res.data));
			},

			saveFromName: function() {
				this.$store.commit('startLoading');

				const customEmailSettings = {
					fromEmail: this.customEmail.fromEmail,
					signature: this.customEmail.signature,
				};

				this.customEmailService
					.updateCustomEmailFromName(customEmailSettings)
					.then((res) => {
						this.customEmail = res.data;
						this.$onBoarding.track('connect_email');
					})
					.catch((err) => {
						let binding = {
							headingText: 'There was an error updating email settings.',
							bodyText: err.response.data.message,
							hideNo: true,
							yesText: 'OK',
						};
						this.$store.state.globalModalController.openModal(ConfirmModal, binding, true, false);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			confirmDelete: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to reset your custom email settings?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.customEmailService.deleteCustomEmail().then((res) => (this.customEmail = res.data));
					}
				});
			},

			connectGoogleOAuth: function() {
				let params = {
					client_id: this.googleEmailService.getClientId(),
					scope: this.googleEmailService.getScopes(),
					ux_mode: 'popup',
					callback: this.handleGoogleAuthCode,
				};
				let googleClient = window.google.accounts.oauth2.initCodeClient(params);
				googleClient.requestCode();
			},

			handleGoogleAuthCode: function(res) {
				this.$store.commit('startLoading');
				this.googleEmailService
					.connect(res.code)
					.then((res) => {
						this.customEmail = res.data;
						this.$onBoarding.track('connect_email');
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			saveCustomEmail: function() {
				let update = { ...this.customEmail };
				update.password = null;
				update.passwordEncrypted = null;

				if (this.password) {
					update.password = this.password;
				}

				this.$store.commit('startLoading');

				this.customEmailService
					.updateCustomEmail(update)
					.then((res) => {
						this.$store.commit('stopLoading');
						this.customEmail = res.data;
						this.password = null;
						this.$onBoarding.track('connect_email');
					})
					.catch((err) => {
						this.$store.commit('stopLoading');
						let binding = {
							headingText: 'There was an error updating email settings.',
							bodyText: err.response.data.message,
							hideNo: true,
							yesText: 'OK',
						};
						this.$store.state.globalModalController.openModal(ConfirmModal, binding, true, false);
					});
			},

			validateCustomEmail: function() {
				this.customEmailService.validateCustomEmail().then((res) => (this.customEmail = res.data));
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},
		},
	};
</script>

<style scoped lang="scss">
	.button-box {
		width: 100%;
		border: 1px solid var(--v-gray_20-base);
		cursor: pointer;
		&:hover {
			background-color: var(--v-gray_5-base);
		}
	}
</style>
