var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "products-widget" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding pb-4" },
        [
          _c(
            "div",
            { staticClass: "modal-title" },
            [
              _c("v-text-field", {
                staticStyle: { "max-width": "300px" },
                attrs: {
                  autofocus: "",
                  "hide-details": "",
                  dense: "",
                  solo: "",
                  flat: "",
                  placeholder: "Filter products & services..."
                },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format mb-3" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", small: "", outlined: "" },
                  on: { click: _vm.adHocProduct }
                },
                [_vm._v("Ad-hoc line item")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-auto",
                  attrs: { color: "secondary", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.ProductTable.createNewProduct()
                    }
                  }
                },
                [_vm._v(" + Add product/service ")]
              )
            ],
            1
          ),
          _c("products-table", {
            ref: "ProductTable",
            attrs: { currency: _vm.currency, filter: _vm.filter },
            on: {
              "set-products": function($event) {
                return _vm.$emit("set-products", $event)
              },
              close: function($event) {
                return _vm.$emit("close")
              },
              select: function($event) {
                return _vm.$emit("select", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer text-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              staticStyle: { width: "140px" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.close")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }