var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-left row-format pb-8",
      staticStyle: { width: "100%", height: "100%" }
    },
    [
      _c(
        "div",
        {
          staticClass: "px-4 pt-1 brand-box",
          style: "width:" + (_vm.peopleVisible ? "70%" : "calc(100% - 40px)")
        },
        [
          _vm.communication.type === "EmailThread"
            ? _c("email-thread-detail", {
                attrs: {
                  "client-id": _vm.clientId,
                  thread: _vm.communication.payload,
                  "user-emails": _vm.userEmails,
                  "snooze-options": _vm.snoozeOptions,
                  folder: _vm.folder,
                  "in-modal": _vm.inModal,
                  "email-boxes": _vm.emailBoxes,
                  "opportunity-id": _vm.opportunityId
                },
                on: {
                  "email-thread-updated": function($event) {
                    return _vm.emailThreadUpdated($event)
                  },
                  "set-contacts": function($event) {
                    return _vm.setContacts($event)
                  },
                  back: function($event) {
                    return _vm.$emit("back")
                  }
                }
              })
            : _vm._e(),
          _vm.communication.type === "RecentCall"
            ? _c("recent-call-detail", {
                attrs: {
                  "client-id": _vm.clientId,
                  call: _vm.communication.payload,
                  "snooze-options": _vm.snoozeOptions,
                  folder: _vm.folder,
                  "in-modal": _vm.inModal
                },
                on: {
                  "recent-call-updated": function($event) {
                    return _vm.recentCallUpdated($event)
                  },
                  "set-contacts": function($event) {
                    return _vm.setContacts($event)
                  },
                  back: function($event) {
                    return _vm.$emit("back")
                  }
                }
              })
            : _vm._e(),
          _vm.communication.type === "MessageThread"
            ? _c("message-thread-detail", {
                attrs: {
                  "client-id": _vm.clientId,
                  thread: _vm.communication.payload,
                  "snooze-options": _vm.snoozeOptions,
                  folder: _vm.folder,
                  "in-modal": _vm.inModal
                },
                on: {
                  "message-thread-update": function($event) {
                    return _vm.messageThreadUpdated($event)
                  },
                  "set-contacts": function($event) {
                    return _vm.setContacts($event)
                  },
                  back: function($event) {
                    return _vm.$emit("back")
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.peopleVisible
        ? _c(
            "div",
            {
              staticClass: "px-2 pt-2 ml-2 column-format brand-box",
              staticStyle: { width: "30%", "min-height": "100%" }
            },
            [
              _c(
                "div",
                { staticClass: "row-format align-center mb-3" },
                [
                  _c("div", { staticClass: "brand-medium font-16" }, [
                    _vm._v("In this conversation")
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-auto pointer",
                      attrs: { color: "gray_90" },
                      on: { click: _vm.togglePeople }
                    },
                    [_vm._v("$close")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { height: "100%" } },
                [
                  _c("people-in-thread", {
                    attrs: { contacts: _vm.contacts },
                    on: {
                      "view-communication": function($event) {
                        return _vm.$emit("view-communication", $event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "ml-2 pt-2 column-format pointer brand-box",
              staticStyle: {
                width: "30px",
                "min-height": "100%",
                "justify-content": "flex-start",
                "align-items": "center"
              },
              on: { click: _vm.togglePeople }
            },
            [
              _c("v-icon", { attrs: { color: "black" } }, [
                _vm._v("$contacts")
              ]),
              _c(
                "div",
                {
                  staticClass: "row-format centered",
                  staticStyle: {
                    width: "20px",
                    height: "20px",
                    "border-radius": "10px",
                    "background-color": "var(--v-blue_10-base)"
                  }
                },
                [
                  _c("div", { staticClass: "font-12 brand-medium" }, [
                    _vm._v(_vm._s(_vm.contacts.length))
                  ])
                ]
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }