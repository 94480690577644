<template>
	<div id="project-kanban" class="row-format fill-height">
		<div class="my-0 py-0 fill-height" id="kanban-container">
			<div id="kanban-v-row" class="mr-3">
				<div class="row-format">
					<div v-for="stage in stages" :key="stage.id">
						<div class="kanban-v-col"  v-if="isInStatusFilter(filter, stage.id)">
							<div
									class="kanban-column pointer"
									:id="`id.${stage.id}`"
									:style="`--r: ${convertToRGB(stage.hexColor)[0]}; --g: ${convertToRGB(stage.hexColor)[1]}; --b: ${convertToRGB(stage.hexColor)[2]};`"
							>
								<div class="row-format align-center mb-2 mx-2 py-1">
									<div :style="`width: 12px; min-width:12px; height: 12px; border-radius: 2px; background-color: ${stage.hexColor}`"></div>
									<div class="font-14 brand-medium ml-3">
										{{stage.label}} <span class="font-gray_70">({{getStageCount(stage.id)}})</span>
									</div>
									<div class="ml-auto font-14 brand-medium">{{$formatters.dollars(getStageValue(stage.id),false)}}</div>
								</div>
								<draggable
										:list="cards['id' + stage.id]"
										:group="{ name: 'card', pull: 'clone' }"
										tag="section"
										:style="`height: calc(var(--vh) - 175px); overflow-y: auto;`"
										:emptyInsertThreshold="100"
										:animation="200"
										:handle="$store.state.isMobile ? 'disabled' : '.cardWrapper'"
										ghost-class="ghost"
										:class="`draggable-container ${$store.getters.scroll}`"
										:move="cardDragHandler"
										@end="endDragHandler"
								>
									<div
											v-for="opportunity in cards['id' + stage.id]"
											:key="opportunity.id"
											@click.stop="editOpportunity(opportunity)"
									>
										<opportunity-card :opportunity="opportunity" :stage="stage" @edit="$emit('edit',$event)"></opportunity-card>
									</div>
									<div class="row-format align-center font-14 font-gray_70 my-3" @click="addOpportunity(stage)">
										<v-icon color="gray_60">add</v-icon>
										<div>Add opportunity</div>
									</div>
								</draggable>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Draggable from 'vuedraggable';
	import OpportunityCard from "@/modules/pipeline/kanban/OpportunityCard";

	export default {
		name: 'OpportunityKanban',

		props: ['pipelineStages', 'pipelineFields', 'stageSummaries', 'opportunities', 'confidenceList','filter'],

		components: {OpportunityCard, Draggable},

		data: function() {
			return {
				selectedCard: null,
				targetSortIndex: null,
				fromStatusId: null,
				toStatusId: null,

				clickCount: 0,
				clickTimer: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addOpportunity: function(stage){
				this.$emit('add-opportunity',stage);
			},

			editOpportunity: function(opportunity){
				console.log(opportunity);
			},

			convertToRGB: function(hex) {
				let aRgbHex = hex.replace('#', '').match(/.{1,2}/g);
				let aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
				return aRgb;
			},

			cardDragHandler: function(event) {
				if (event.to.parentNode.id === 'unknown') {
					this.selectedCard = null;
					this.targetSortIndex = null;
					return false;
				}

				this.selectedCard = event.draggedContext.element;
				this.targetSortIndex = event.draggedContext.futureIndex;
				return true;
			},

			endDragHandler: function(event) {
				if (this.selectedCard === null) {
					return;
				}

				this.fromStatusId = event.from.parentElement.id.split('.')[1];
				this.toStatusId = event.to.parentElement.id.split('.')[1];

				let sortArray = this.cards['id' + this.toStatusId];
				let updates = [];

				for (let i = 0; i < sortArray.length; i++) {
					if (sortArray[i].kanbanSort !== i || sortArray[i].id === this.selectedCard.id) {
						let update = {
							id: sortArray[i].id,
							kanbanSort: i,
							statusId: this.toStatusId,
						};
						updates.push(update);
					}
					sortArray[i].kanbanSort = i;
					sortArray[i].statusId = this.toStatusId;
				}

				this.$emit('kanban-updates',updates);
				this.targetSortIndex = null;
				this.selectedCard = null;
				this.fromStatusId = null;
				this.toStatusId = null;
			},


			getStageValue: function(stageId){
				let summary = this.stageSummaries.find(s => s.id === stageId);
				return summary ? summary.totalValue : 0;
			},

			getStageCount: function(stageId){
				let summary = this.stageSummaries.find(s => s.id === stageId);
				return summary ? summary.opportunityCount : 0;
			},

			isInStatusFilter: function (filter,stageId) {
				if (filter.stages.length === 0) {
					return true;
				} else if (filter.stages.indexOf(stageId) > -1) {
					return true;
				} else {
					return false;
				}
			},
		},

		computed: {
			stages: function() {
				return this.pipelineStages.stages;
			},

			cards: function() {
				let columns = [...this.stages];
				let cards = {
				};

				for (let i = 0; i < columns.length; i++) {
					cards['id' + columns[i].id] = [];
				}

				if (this.opportunities.length === 0) {
					let colId = 'id' + columns[0].id;
					cards[colId].push({
						emptyCard: true,
					});
				} else {
					for (let i = 0; i < this.opportunities.length; i++) {
						let colId = 'id' + this.opportunities[i].statusId;
						if (Object.prototype.hasOwnProperty.call(cards, colId)) {
							cards[colId].push(this.opportunities[i]);
						}
					}
				}

				return cards;
			},
		},
	};
</script>

<style lang="scss">
	#pipeline-kanban {
		.add-column {
			width: 36px;
			height: 36px;
			min-width: 36px;
			min-height: 36px;
			cursor: pointer;
			background-color: var(--v-gray_20-base);
			border-radius: 4px;
		}
		#kanban-container {
			overflow-x: auto;
			overflow-y: hidden;
			//height: calc(100% - 50px);

			#kanban-v-row {
				.kanban-v-col {
					margin-left: 8px;
					margin-right: 8px;
					//height: calc(100% - 50px);
					min-width: 260px;
					max-width: 260px;
					overflow-y: scroll;
					.kanban-column {
						background: rgba(var(--r), var(--g), var(--b), 0.09);
						padding: 8px;
						border-radius: 16px;
						text-align: left;
						filter: none;

						.draggable-container {
							//&::-webkit-scrollbar {
							//	display: none!important; // Inherits width and height from brand.scss
							//}
						}

						.hidden-scroll {
							&::-webkit-scrollbar {
								display: none !important; // Inherits width an d height from brand.scss
							}
						}
					}

					.new-column {
						height: 160px;
						background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f9f9f7 100%), #f0efea;
						padding: 8px;
						border-radius: 8px;
						text-align: left;
						filter: none;
					}
				}
			}
		}
	}
</style>
