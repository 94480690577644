<template>
	<div id="invoice-table-and-chart" class="column-format gap-4">
		<div class="row-format gap-3" v-if="filteredInvoices.length" style="flex-wrap: wrap">
			<div class="kpi-box">
				<div class="fit text-left">
					Total invoiced <span class="font-gray_70">({{ filteredInvoices.length }} invoices)</span>
				</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(totalInvoiced, true, true) }}</div>
			</div>
			<div class="kpi-box">
				<div class="fit text-left">Total due</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.dollars(totalDue, true, true) }}</div>
			</div>
			<div class="kpi-box">
				<div class="fit text-left">Avg days to pay</div>
				<div class="font-24 brand-medium fit mt-1">{{ avgDaysToPay }}</div>
			</div>
		</div>

		<div v-if="isReady">
			<v-tabs v-model="invoiceTab" class="mb-4">
				<v-tab key="0">Invoice list</v-tab>
				<v-tab key="1">Recurring invoices</v-tab>
				<v-tab key="2">Payment plans</v-tab>
			</v-tabs>

			<div v-show="invoiceTab === 1">
				<recurring-invoice-list
					:filter="filter"
					ref="RecurringInvoice"
					@record-count="setRecurringCount($event)"
				></recurring-invoice-list>
			</div>

			<div v-show="invoiceTab === 2">
				<payment-plan-list :filter="filter" ref="PaymentPlan"></payment-plan-list>
			</div>

			<div v-show="invoiceTab === 0">
				<div
					v-if="!invoices.length && !recurringInvoiceCount"
					class="row-format centered fill-height"
					style="height: calc(var(--vh) - 300px)"
				>
					<empty-view
						header="No invoices yet"
						:body="
							`You haven’t sent any invoices  yet. Send a manual invoice or set up recurring invoices -- Moxie follows your schedule and adds your work to the invoice, and you just approve it before sending.`
						"
						cta="Send an invoice"
						video-header="See how it works"
						video-body="Learn how to add the work you’ve done into an invoice. You can also see how to set up automatic invoices."
						video-cta="Watch the tutorial"
						video-id="VHlGLkW1suI"
						@cta-clicked="addNew()"
					></empty-view>
				</div>
				<div v-else>
					<invoice-table
						@add-new="addNew()"
						@open-invoice="openInvoice($event)"
						@add-update-invoice="addOrUpdateInvoice($event)"
						@remove-invoice="removeInvoice($event)"
						@invoice-paid="handleInvoicePaid($event)"
						:invoices="filteredInvoices"
					></invoice-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import DateTime from '@/modules/utils/HDateTime';
	import CreateInvoiceModal from '@/modules/invoices/CreateInvoiceModal';
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
	import FilterHelpers from '@/utils/FilterHelpers';
	import InvoiceTable from '@/modules/accounting/invoices/InvoiceTable';
	import RecurringInvoiceList from '@/modules/clients/detail/invoices/RecurringInvoiceList';
	import EmptyView from '@/components/EmptyView';
	import PaymentDetailsModal from '@/modules/invoices/InvoiceRenderer/PaymentDetailsModal';
	import PaymentPlanList from '@/modules/clients/detail/invoices/PaymentPlanList';

	export default {
		name: 'InvoiceList',
		components: { PaymentPlanList, RecurringInvoiceList, InvoiceTable, EmptyView },
		props: ['filter'],

		data() {
			return {
				invoiceTab: 0,
				invoiceService: new InvoiceService(),
				invoices: [],
				isReady: false,
				DateTime: DateTime,
				invoiceInitKey: null,
				recurringInvoiceCount: 0,
			};
		},

		mounted() {
			this.getInvoices();
		},

		methods: {
			getInvoices: function() {
				this.invoiceService.getInvoices().then((res) => {
					this.invoices.splice(0, this.invoices.length);
					this.invoices.push(...res.data);
					this.isReady = true;
				});
			},

			setRecurringCount: function(count) {
				this.recurringInvoiceCount = count;
			},

			invoiceCreated: function(invoice) {
				this.invoices.push(invoice);
				this.openInvoice(invoice.id);
			},

			openInvoice: function(invoice) {
				let binding = {
					clientId: invoice.clientId,
					id: invoice.id,
				};
				this.$store.state.globalModalController.openModal(InvoiceDetails, binding, true, true).then((res) => {
					if (!res) return;
					if (res.action && res.action === 'DELETED') {
						this.removeInvoice(res.id);
					} else if (res.action && res.action === 'DUPLICATED') {
						this.invoices.push(res.invoice);
						return this.openInvoice(res.invoice);
					} else {
						this.addOrUpdateInvoice(res);
					}
				});
			},

			addNew: function() {
				this.$store.state.globalModalController.openModal(CreateInvoiceModal).then((res) => {
					if (res) {
						if (res.createRecurring) {
							this.invoiceTab = 1;
							this.$refs.RecurringInvoice.createRecurringInvoice(res.clientId);
						} else if (res.createPaymentPlan) {
							this.invoiceTab = 2;
							this.$refs.PaymentPlan.createPaymentPlan(res.clientId);
						} else {
							this.invoiceTab = 0;
							this.openInvoice(res);
						}
					}
				});
			},

			handleInvoicePaid: function(event) {
				event.editMode = true;
				this.$store.state.globalModalController.openModal(PaymentDetailsModal, event);
			},

			removeInvoice: function(invoiceId) {
				let ix = this.invoices.findIndex((i) => i.id === invoiceId);
				if (ix > -1) {
					this.invoices.splice(ix, 1);
				}
			},

			addOrUpdateInvoice: function(invoice) {
				let ix = this.invoices.findIndex((i) => i.id === invoice.id);
				if (ix > -1) {
					this.invoices.splice(ix, 1, invoice);
				} else {
					this.invoices.push(invoice);
				}
			},

			formatDate(date) {
				return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
			},
		},

		computed: {
			filteredInvoicesAllDates: function() {
				let result = [...this.invoices];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;
				let lateStatus = ['SENT', 'PARTIAL'];

				return result
					.filter((i) => {
						if (
							!search ||
							(search &&
								(i.invoiceNumber.toString().startsWith(search) ||
									i.invoiceNumberFormatted.toLowerCase().startsWith(search) ||
									(i.clientInfo.name && i.clientInfo.name.toLowerCase().startsWith(search))))
						) {
							return true;
						} else if (search) {
							return false;
						}
					})
					.filter((i) => {
						if (this.filter.invoiceStatus.length) {
							let lateSearch = this.filter.invoiceStatus.includes('LATE');
							if (this.filter.invoiceStatus.includes(i.status)) {
								return true;
							} else if (lateSearch && lateStatus.includes(i.status)) {
								let dateDue = DateTime.fromISO(i.dateDue);
								if (dateDue < DateTime.now()) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((i) => {
						if (this.filter.clientList && this.filter.clientList.length > 0) {
							return this.filter.clientList.includes(i.clientId);
						} else {
							return true;
						}
					});
			},

			filteredInvoices: function() {
				let result = [...this.filteredInvoicesAllDates];

				result = result.filter((i) => {
					if (this.earliest && this.latest) {
						let compareDate = i.dateSent
							? DateTime.fromISO(i.dateSent).endOf('day')
							: DateTime.fromISO(i.dateCreated).startOf('day');

						if (compareDate >= this.earliest && compareDate <= this.latest) {
							return true;
						} else {
							return false;
						}
					} else {
						return true;
					}
				});

				result.sort((a, b) => b.invoiceNumberFormatted.localeCompare(a.invoiceNumberFormatted));
				return result;
			},

			earliest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.earliest).endOf('day');
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).earliest;
					}
				} else {
					return null;
				}
			},

			latest: function() {
				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between') {
						return DateTime.fromISO(this.filter.latest).endOf('day');
					} else {
						return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).latest.endOf('day');
					}
				} else {
					return null;
				}
			},

			totalInvoiced: function() {
				let ignore = ['DRAFT', 'VOIDED'];
				return this.filteredInvoices
					.filter((i) => !ignore.includes(i.status))
					.reduce((sum, { localTotal }) => sum + localTotal, 0);
			},

			totalDue: function() {
				let payable = ['SENT', 'PARTIAL', 'PENDING'];
				return this.filteredInvoices
					.filter((i) => payable.includes(i.status))
					.reduce((sum, { localAmountDue }) => sum + localAmountDue, 0);
			},

			avgDaysToPay: function() {
				let totalDaysToPay = 0;
				let count = 0;
				this.filteredInvoices.forEach((i) => {
					if (i.dateSent && i.datePaid) {
						count++;
						let dateSent = DateTime.fromISO(i.dateSent);
						let datePaid = DateTime.fromISO(i.datePaid);
						let diff = datePaid.diff(dateSent, ['days']).days;
						totalDaysToPay = totalDaysToPay + diff;
					}
				});
				let result = Math.ceil(totalDaysToPay / count);
				if (isNaN(result)) {
					return 0;
				} else {
					return result;
				}
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
	.invoice-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
	}
</style>

<style scoped lang="scss">
	.action-menu ::v-deep {
		.primary-action {
			border: 0px !important;
		}
		.menu-activator {
			border: 0px !important;
		}
	}
</style>
