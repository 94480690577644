var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "proposal-share-send-widget" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.cancel()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _vm.mode === "Select"
        ? _c("div", [
            _c("div", { staticClass: "modal-padding" }, [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("proposal.share.select-method")))
              ]),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: {
                    click: function($event) {
                      _vm.mode = "Send"
                    }
                  }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("$email")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.send-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.send-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.send-next")))
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: {
                    click: function($event) {
                      _vm.mode = "Share"
                    }
                  }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("$share")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.share-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.share-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.share-next")))
                    ])
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm.mode === "Share"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "modal-padding" },
              [
                _c("div", { staticClass: "modal-title mb-5" }, [
                  _vm._v("Click on the signer to copy their shareable link")
                ]),
                _c("input", { attrs: { type: "hidden", id: "linkBox" } }),
                _c(
                  "v-container",
                  { staticClass: "ma-0 pa-0 text-left font-14" },
                  _vm._l(_vm.signers, function(signer) {
                    return _c(
                      "v-row",
                      {
                        key: signer.id,
                        staticClass: "py-2 pointer",
                        staticStyle: {
                          "border-top": "1px solid var(--v-gray_30-base)"
                        },
                        on: {
                          click: function($event) {
                            return _vm.copyLink(signer)
                          }
                        }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-pre-wrap",
                            attrs: { cols: "12" }
                          },
                          [
                            _vm._v(
                              _vm._s(signer.firstName) +
                                " " +
                                _vm._s(signer.lastName) +
                                " <" +
                                _vm._s(signer.email) +
                                "> "
                            ),
                            _c("span", { staticClass: "font-primary" }, [
                              _vm._v(
                                "https://" +
                                  _vm._s(
                                    _vm.$store.state.communicationPreferences
                                      .primaryDomain
                                  ) +
                                  "/document/" +
                                  _vm._s(_vm.agreement.id) +
                                  "." +
                                  _vm._s(signer.id)
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    staticStyle: { width: "140px" },
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.done")) + " ")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.mode === "Send"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "modal-padding" },
              [
                _c("div", { staticClass: "modal-title mb-2" }, [
                  _vm._v(_vm._s(_vm.$t("proposal.share.send-title")))
                ]),
                _c(
                  "v-row",
                  { staticClass: "rowFormat" },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c("span", { staticClass: "emailHeader" }, [
                        _vm._v(_vm._s(_vm.$t("global.email-from")) + ": ")
                      ]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$store.getters.getFromEmail))
                      ])
                    ])
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mt-1 mb-2" }),
                _c(
                  "v-row",
                  { staticClass: "rowFormat" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pb-2", attrs: { cols: "12" } },
                      [
                        _c("span", { staticClass: "emailHeader" }, [
                          _vm._v("Send to signers: ")
                        ]),
                        _vm._l(_vm.signers, function(signer) {
                          return _c(
                            "div",
                            { key: signer.id },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  value: signer.id
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "var(--v-black-base)"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(signer.firstName) +
                                                  " " +
                                                  _vm._s(signer.lastName) +
                                                  " <" +
                                                  _vm._s(signer.email) +
                                                  ">"
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.sendTo,
                                  callback: function($$v) {
                                    _vm.sendTo = $$v
                                  },
                                  expression: "sendTo"
                                }
                              })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mt-1 mb-2" }),
                _c(
                  "v-row",
                  { staticClass: "rowFormat" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pb-1", attrs: { cols: "12" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row"
                            }
                          },
                          [
                            _c("span", { staticClass: "emailHeader" }, [
                              _vm._v(
                                _vm._s(_vm.$t("global.email-subject")) + ": "
                              )
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.subject,
                                  expression: "subject"
                                }
                              ],
                              staticStyle: {
                                outline: "none",
                                width: "100%",
                                color: "var(--v-black-base)"
                              },
                              attrs: { type: "text" },
                              domProps: { value: _vm.subject },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.subject = $event.target.value
                                }
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mt-1 mb-2" }),
                _c(
                  "v-row",
                  { staticClass: "rowFormat" },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c(
                        "div",
                        [
                          _vm.initialEmailContent
                            ? _c("editor", {
                                ref: "emailContent",
                                attrs: {
                                  "api-key": _vm.$store.getters.getTinyMceKey,
                                  inline: false,
                                  "initial-value": _vm.initialEmailContent,
                                  init: _vm.mceConfigText,
                                  spellcheck: true
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "font-12 row-format centered gap-1",
                        attrs: { cols: "12" }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "16", color: "primary" } },
                          [_vm._v("info")]
                        ),
                        _c("div", [
                          _vm._v(
                            " It is normal to see {Tokens} in this view. The agreement and signer specific tokens will be replaced when the email is sent. "
                          )
                        ]),
                        _c(
                          "v-icon",
                          { attrs: { size: "16", color: "primary" } },
                          [_vm._v("info")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "modal-footer row-format centered" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action mr-1",
                    attrs: { disabled: _vm.disabled },
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                ),
                _c("send-email-button", {
                  staticClass: "ml-1",
                  attrs: { width: 170, disabled: _vm.sendTo.length === 0 },
                  on: {
                    click: function($event) {
                      return _vm.sendEmail($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }