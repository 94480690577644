<template>
	<div style="width: 100%; height: 100%" class="column-format centered">
		<div class="brand-bold font-24">Hang tight, we are connecting your Xero account.</div>
	</div>
</template>

<script>
	import XeroService from "@/modules/integrations/xero/XeroService";

	export default {
		name: 'XeroOAuthHandler',

		props: ['code'],

		components: {},

		data: function () {
			return {
				xeroService: new XeroService(),
			};
		},

		mounted() {
			this.$store.commit('startLoading');
			this.connectXero();
		},

		beforeDestroy() {},

		methods: {
			connectXero: function () {
				let redirectUri = document.location.protocol + '//' + document.location.host + '/integrations/xero/oauth';
				this.xeroService
					.exchangeToken(redirectUri,this.code)
					.then(() => {
						this.$store.commit('info', 'Xero account linked.');
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', 'Error connecting your Xero account: ' + err.message);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
						this.$router.push('/apps?v=accounting');
					});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
