<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-details')" @action="navigateToAccount()" :sent-at="sentAt">
		<template slot="header">
			<v-avatar size="20" v-if="profilePicture">
				<img class="pic" :src="profilePicture" />
			</v-avatar>
			<div class="ml-2">{{ $store.getters.getLoggedInUserName }}'s workspace</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ $t(eventType === 'AccountActivated' ? 'notifications.stripe-activated' : 'notifications.stripe-update') }}</div>
			<div class="font-12">
				<span> {{ message }} </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";

	export default {
		name: 'StripeAccountNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { NotificationLayout },

		data: function() {
			return {
				eventType: this.notificationPayload.eventType,
				message: this.notificationPayload.message,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			navigateToAccount: function() {
				this.$router.push('/stripe');
			}
		},

		computed: {
			profilePicture() {
				if (this.$store.state.loggedInUser) {
					if (this.$store.state.loggedInUser.profilePicture) {
						return this.$store.state.loggedInUser.profilePicture;
					}
				}
				return '';
			},
		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
