var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: { "bread-crumbs": [{ to: "/home", label: "Home" }] }
        },
        [_vm._v(" Timesheets ")]
      ),
      _vm.isInitialized
        ? _c("div", { staticClass: "column-format fill-height gap-4" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "div",
                  [
                    _vm.filter
                      ? _c("time-track-list-filter", {
                          attrs: { filter: _vm.filter },
                          on: { "clear-filter": _vm.clearFilter }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-auto super-action",
                    on: {
                      click: function($event) {
                        return _vm.addNew()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
                    _vm._v(" Add entry")
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.events.length
              ? _c(
                  "div",
                  {
                    staticClass: "row-format centered fill-height",
                    staticStyle: { "min-height": "calc(100vh - 300px)" }
                  },
                  [
                    _c("empty-view", {
                      attrs: {
                        header: "No time entries yet",
                        body:
                          "If you track your time, your history of time entries will show up here. In addition to viewing historical entries here, you can also create new entries.",
                        cta: "Create a time entry",
                        "video-header": "See how it works",
                        "video-body":
                          "Learn how to track your time and then add those time entries to an invoice.",
                        "video-cta": "Watch the tutorial",
                        "video-id": "3l1ThT9qGks"
                      },
                      on: {
                        "cta-clicked": function($event) {
                          return _vm.addNew()
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.events.length
              ? _c(
                  "div",
                  { staticClass: "column-format gap-4" },
                  [
                    _c("div", { staticClass: "column-format gap-3" }, [
                      _c("div", { staticClass: "row-format gap-3 flex-wrap" }, [
                        _c("div", { staticClass: "kpi-box" }, [
                          _c("div", { staticClass: "fit text-left" }, [
                            _vm._v("Total hours")
                          ]),
                          _c(
                            "div",
                            { staticClass: "font-24 brand-medium fit" },
                            [
                              _vm._v(
                                _vm._s(_vm.formatSeconds(_vm.metrics.totalTime))
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "kpi-box" }, [
                          _c("div", { staticClass: "row-format" }, [
                            _c(
                              "div",
                              { staticClass: "row-format align-center fit" },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.$t("timetrack.list.by-client"))
                                  )
                                ])
                              ]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "row-format status-wrapper" },
                            _vm._l(_vm.metrics.clients, function(client) {
                              return _c("div", {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy",
                                    value: {
                                      content:
                                        client.name +
                                        " - " +
                                        _vm.formatSecondsToHours(
                                          client.totalTime
                                        )
                                    },
                                    expression:
                                      "{ content: `${client.name} - ${formatSecondsToHours(client.totalTime)}` }"
                                  }
                                ],
                                key: client.name,
                                staticClass: "status-box",
                                style:
                                  "width:" +
                                  client.percentage +
                                  "%; background-color: " +
                                  client.color
                              })
                            }),
                            0
                          )
                        ]),
                        _c("div", { staticClass: "kpi-box" }, [
                          _c("div", { staticClass: "fit text-left" }, [
                            _vm._v(_vm._s(_vm.$t("timetrack.list.this-week")))
                          ]),
                          _c(
                            "div",
                            { staticClass: "font-24 brand-medium fit" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatSeconds(_vm.metrics.timeThisWeek)
                                )
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm.filter.timeEntryStatus.length !== 1
                        ? _c(
                            "div",
                            { staticClass: "row-format gap-3 flex-wrap" },
                            [
                              _vm.filter.timeEntryStatus.length === 0 ||
                              _vm.filter.timeEntryStatus.includes("BILLED")
                                ? _c("div", { staticClass: "kpi-box" }, [
                                    _vm._m(0),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "font-24 brand-medium fit"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatSeconds(
                                              _vm.metrics.billed
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.filter.timeEntryStatus.length === 0 ||
                              _vm.filter.timeEntryStatus.includes("UN-BILLED")
                                ? _c("div", { staticClass: "kpi-box" }, [
                                    _vm._m(1),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "font-24 brand-medium fit"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatSeconds(
                                              _vm.metrics.unBilled
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.filter.timeEntryStatus.length === 0 ||
                              _vm.filter.timeEntryStatus.includes(
                                "NON-BILLABLE"
                              )
                                ? _c("div", { staticClass: "kpi-box" }, [
                                    _vm._m(2),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "font-24 brand-medium fit"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatSeconds(
                                              _vm.metrics.nonBillable
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c("timer-event-table", {
                      attrs: {
                        events: _vm.filteredEvents,
                        "group-by": _vm.filter.groupBy
                          ? _vm.filter.groupBy.field
                          : null
                      },
                      on: {
                        "add-new": function($event) {
                          return _vm.addNew()
                        },
                        duplicate: function($event) {
                          return _vm.duplicate($event)
                        },
                        "edit-timer": function($event) {
                          return _vm.editTimerEvent($event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-format" }, [
      _c("div", { staticClass: "fit text-left" }, [_vm._v("Billed")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-format" }, [
      _c("div", { staticClass: "fit text-left" }, [_vm._v("Un-billed")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-format" }, [
      _c("div", { staticClass: "fit text-left" }, [_vm._v("Non-billable")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }