var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", [
        _c("div", { staticClass: "row-format align-start" }, [
          _c(
            "div",
            { staticClass: "column-format" },
            [
              _c("div", { staticClass: "font-gray_80 mb-1" }, [
                _vm._v(_vm._s(_vm.$t("connected-apps.paypal.desc")))
              ]),
              _vm.isConnected
                ? _c("connected", {
                    attrs: {
                      label:
                        _vm.payPalConnection.status !== "ACTIVE"
                          ? "On-Boarding"
                          : ""
                    }
                  })
                : _c(
                    "div",
                    {
                      staticClass: "font-primary brand-medium pointer",
                      on: { click: _vm.connectPayPalAccount }
                    },
                    [_vm._v("Connect PayPal")]
                  )
            ],
            1
          ),
          _vm.isConnected
            ? _c(
                "div",
                {
                  staticClass: "ml-auto pointer",
                  on: {
                    click: function($event) {
                      _vm.expanded = !_vm.expanded
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.isConnected && _vm.expanded
          ? _c("div", { staticClass: "mt-5" }, [
              _vm.payPalConnection.version === "PARTNER"
                ? _c("div", [
                    _vm.payPalConnection.status === "ACTIVE"
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "mb-6 font-14" }, [
                              _c("span", { staticClass: "brand-medium" }, [
                                _vm._v("PayPal Account:")
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.payPalConnection.merchantName)
                              ),
                              _c("br")
                            ]),
                            _c(
                              "v-btn",
                              {
                                staticClass: "secondary-action mr-1",
                                on: { click: _vm.confirmDisconnect }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("$delete")
                                ]),
                                _vm._v("Disconnect")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "secondary-action mr-1",
                                on: { click: _vm.continueOnBoarding }
                              },
                              [_vm._v("Continue On-Boarding")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "mt-4 font-12",
                                staticStyle: { "font-style": "italic" }
                              },
                              [
                                _vm._v(
                                  " Note: It may take a few minutes after you complete On-Boarding for PayPal to fully connect your account. "
                                )
                              ]
                            )
                          ],
                          1
                        )
                  ])
                : _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "pa-4",
                        staticStyle: {
                          "background-color": "var(--v-white-base)",
                          "border-radius": "4px",
                          border: "1px solid var(--v-gray_50-base)"
                        }
                      },
                      [
                        _c(
                          "v-form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            },
                            model: {
                              value: _vm.isValid,
                              callback: function($$v) {
                                _vm.isValid = $$v
                              },
                              expression: "isValid"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                " Status: " +
                                  _vm._s(_vm.payPalConnection.status) +
                                  " - "
                              ),
                              _c("span", { staticClass: "font-gray_70" }, [
                                _vm._v(
                                  _vm._s(_vm.payPalConnection.statusMessage)
                                )
                              ])
                            ]),
                            _c("v-text-field", {
                              staticClass: "h-outline",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                rules: _vm.required,
                                label: "PayPal Merchant Id"
                              },
                              model: {
                                value: _vm.merchantId,
                                callback: function($$v) {
                                  _vm.merchantId = $$v
                                },
                                expression: "merchantId"
                              }
                            }),
                            _c("v-text-field", {
                              staticClass: "h-outline",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                rules: _vm.required,
                                label: "App Client Id"
                              },
                              model: {
                                value: _vm.clientId,
                                callback: function($$v) {
                                  _vm.clientId = $$v
                                },
                                expression: "clientId"
                              }
                            }),
                            _c("v-text-field", {
                              staticClass: "h-outline",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                rules: _vm.required,
                                label: "App Client Secret"
                              },
                              model: {
                                value: _vm.clientSecret,
                                callback: function($$v) {
                                  _vm.clientSecret = $$v
                                },
                                expression: "clientSecret"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "row-format centered" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "delete",
                                    attrs: { icon: "" }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "gray_70" },
                                        on: {
                                          click:
                                            _vm.deleteStandardPayPalConnection
                                        }
                                      },
                                      [_vm._v("$delete")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2 primary-action",
                                    attrs: {
                                      width: "200",
                                      disabled: !_vm.isValid
                                    },
                                    on: {
                                      click: _vm.updateStandardPayPalConnection
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("global.save")))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._m(0)
                  ])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-4 font-14 brand-medium" }, [
      _vm._v(" Check out the "),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://help.withmoxie.com/en/articles/5899247-integrating-hectic-with-paypal",
            target: "_blank"
          }
        },
        [_vm._v("help article")]
      ),
      _vm._v(" to quickly connect your PayPal business account with Moxie. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }