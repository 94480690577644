<template>
	<div style="width: 600px; min-height: 500px">
		<v-btn icon class="close-dialog" @click="$emit('result')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h2 class="modal-title">Select opportunity</h2>
			<div class="font-16">
				<v-text-field v-model="search" placeholder="Filter..." hide-details dense class="pb-4"></v-text-field>
				<div v-for="opp in filteredOpportunities" :key="opp.id" class="row-format align-center py-2 pointer" @click="selectOpp(opp)" style="border-bottom: 1px solid var(--v-gray_30-base)">
					<client-avatar class="mr-1" :small="true" v-if="opp.clientId" :client="getClient(opp.clientId)"></client-avatar>
					<div>{{opp.name ? opp.name : '--'}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import OpportunityService from '@/modules/pipeline/OpportunityService';
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'OpportunitySelector',

		props: ['clientId'],

		components: {ClientAvatar},

		data: function() {
			return {
				search: null,
				opportunities: [],
				opportunityService: new OpportunityService(),
			};
		},

		mounted() {
			this.opportunityService.getOpportunities().then((res) => {
				this.opportunities.push(...res.data);
			});
		},

		beforeDestroy() {},

		methods: {
			selectOpp: function(opp){
				this.$emit('result',opp);
			},

			getClient: function(clientId){
				return this.$store.getters.getClientById(clientId);
			},
		},

		computed: {
			filteredOpportunities: function() {
				return this.opportunities
					.filter((o) => !o.archive)
					.filter((o) => {
						if (this.clientId) {
							return o.clientId === this.clientId;
						} else {
							return true;
						}
					})
					.filter((o) => {
						if (this.search) {
							let name = o.name ? o.name : '';
							return name.toLocaleLowerCase().includes(this.search.toLowerCase());
						} else {
							return true;
						}
					});
			},
		},
	};
</script>

<style scoped lang="scss"></style>
