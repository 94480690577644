<template>
	<div id="email-or-download">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">
				{{ $t('invoice.send-method') }}
			</div>

			<div class="h-outline d-flex align-start justify-start py-3 px-2 mt-4" @click="sendEmail()">
				<div class="pr-3 pl-1">
					<v-icon>$email</v-icon>
				</div>
				<div class="text-left">
					<div class="brand-medium gray_90--text">{{ $t('invoice.send-email') }}</div>
					<div class="font-14 brand-semilight mt-1">{{ $t('invoice.send-email-desc') }}</div>
					<div class="font-14 brand-medium mt-1">{{ $t('invoice.approve-email') }}</div>
				</div>
			</div>

			<div class="h-outline d-flex align-start justify-start py-3 px-2 mt-4" @click="downloadInvoice()">
				<div class="pr-3 pl-1">
					<v-icon>$pdfFile</v-icon>
				</div>
				<div class="text-left">
					<div class="brand-medium gray_90--text">{{ $t('invoice.download') }}</div>
					<div class="font-14 brand-semilight mt-1">{{ $t('invoice.download-desc') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';

	export default {
		name: 'EmailOrDownload',

		props: ['invoice'],

		components: {},

		data: function() {
			return {
				invoiceService: new InvoiceService(),
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			sendEmail: function() {
				this.$emit('send-email');
			},

			downloadInvoice: function() {
				this.$store.commit('startLoading');
				this.invoiceService
					.downloadInvoice(this.invoice.clientId, this.invoice.id)
					.then((res) => {
						let fileURL = window.URL.createObjectURL(new Blob([res.data]));

						let fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute('download', this.invoice.invoiceNumberFormatted + '.pdf');
						document.body.appendChild(fileLink);
						fileLink.click();

						this.invoiceService.getInvoice(this.invoice.clientId, this.invoice.id).then((inv) => {
							this.$emit('invoice-sent', inv.data);
							this.$emit('close');
						});
					})
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	#email-or-download {
		max-width: 330px;
		background: var(--v-white-base);
		.h-outline {
			cursor: pointer;
			&:hover {
				background-color: var(--v-gray_20-base);
				border-color: var(--v-gray_20-base);
				svg {
					--icon-color: var(--v-primary-base);
					color: var(--v-primary-base);
				}
			}
		}
	}

	.loadingIcon {
		position: absolute;
		top: 50%;
		right: 50%;
		z-index: 300;
	}

	.cloneLink {
		cursor: pointer;
		color: var(--v-primary-base);
		&:hover {
			text-decoration: underline;
		}
	}
</style>
