var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-6", staticStyle: { "max-width": "500px" } },
    [
      _vm.screen === "questions"
        ? _c(
            "v-form",
            {
              ref: "questionForm",
              attrs: { id: "question", "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitQuestion()
                }
              }
            },
            [
              _c(
                "div",
                {},
                [
                  _c("h1", { staticClass: "mb-3" }, [
                    _vm._v(
                      _vm._s(_vm.$t("subscription.cancel.feedback.heading"))
                    )
                  ]),
                  _c("div", { staticClass: "justify-center mb-8 px-4 mx-4" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("subscription.cancel.feedback.subheading")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-8",
                      attrs: { color: "primary", large: "", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("subscription.cancel.cancel-button")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        hint: "Please make a selection",
                        rules: _vm.rules
                      },
                      model: {
                        value: _vm.reason,
                        callback: function($$v) {
                          _vm.reason = $$v
                        },
                        expression: "reason"
                      }
                    },
                    _vm._l(
                      _vm.$t("subscription-v2.downgrade.questions"),
                      function(question, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "mt-2 mb-3" },
                          [
                            _c("v-radio", {
                              staticClass: "custom",
                              attrs: { value: question, label: question }
                            }),
                            _vm.reason === question
                              ? _c(
                                  "div",
                                  { staticClass: "ma-0" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t(
                                          "subscription.cancel.feedback.comment-label"
                                        )
                                      },
                                      model: {
                                        value: _vm.comments,
                                        callback: function($$v) {
                                          _vm.comments = $$v
                                        },
                                        expression: "comments"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        type: "submit",
                        large: "",
                        color: "accent",
                        block: ""
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("subscription.cancel.feedback.button"))
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.screen === "improvement"
        ? _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitImprovement()
                }
              }
            },
            [
              _c("h1", { staticClass: "mb-3" }, [
                _vm._v(_vm._s(_vm.$t("subscription.cancel.feedback.heading")))
              ]),
              _c("div", { staticClass: "justify-center mb-12 px-2 mx-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("subscription.cancel.feedback.subheading2")) +
                    " "
                )
              ]),
              _c(
                "div",
                { staticClass: "mb-12" },
                [
                  _c("h3", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("subscription.cancel.feedback.improvement")
                        ) +
                        " "
                    )
                  ]),
                  _c("v-text-field", {
                    model: {
                      value: _vm.improve,
                      callback: function($$v) {
                        _vm.improve = $$v
                      },
                      expression: "improve"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mb-4",
                  attrs: { color: "primary", large: "", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("subscription.cancel.cancel-button")) +
                      " "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    large: "",
                    color: "accent",
                    block: ""
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("subscription.cancel.feedback.button")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.screen === "confirm"
        ? _c(
            "v-form",
            {
              attrs: { id: "confirm" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.downgradeFeedback()
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "h1",
                    [
                      _vm._v("See ya! "),
                      _c(
                        "v-icon",
                        { attrs: { size: "20", color: "primary" } },
                        [_vm._v("waving_hand")]
                      )
                    ],
                    1
                  ),
                  _c("div", [
                    _vm._v(
                      "Thank you for providing feedback on your experience with Moxie."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "You will have access to your Moxie workspace until the end of your current billing cycle. We recommend using the Import/Export tool to download your workspace data prior to the end of your current billing cycle."
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        type: "submit",
                        block: "",
                        large: "",
                        color: "primary"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("subscription.cancel.confirm.button"))
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }