<template>
	<div class="invoice-action-menu">
		<v-menu :close-on-click="true" :nudge-bottom="30" :disabled="!menuEnabled">
			<template v-slot:activator="scope">
				<div class="ml-1">
					<v-btn icon class="menu-activator secondary-action" :disabled="!menuEnabled" v-on="scope.on">
						<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
					</v-btn>
				</div>
			</template>
			<div class="more-menu">
				<div class="more-menu-item" v-if="subscription.status !== 'sent'" @click="$emit('view-payments')">
					View payments
				</div>
				<div class="more-menu-item" v-if="subscription.status === 'active' || subscription.status === 'past_due'" @click="$emit('cancel-subscription')">
					Cancel subscription
				</div>
				<div class="more-menu-item" v-if="subscription.status === 'sent'" @click="$emit('copy-payment-link')">
					Copy checkout link
				</div>
				<div class="more-menu-item" v-if="subscription.status === 'sent'" @click="$emit('cancel-request')">
					Cancel request
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'SubscriptionActionMenu',

		components: {
		},

		props: {
			subscription: { type: Object, required: true },
		},

		data: function() {
			return {
			};
		},

		mounted() {
			// console.log('ActionMenu mounted()', this.invoice);
		},

		beforeDestroy() {},

		methods: {

		},
		computed: {
			menuEnabled: function(){
				if(this.subscription.status !== 'canceled' && this.subscription.status !== 'ended'){
					return true;
				}else{
					return false;
				}
			}
		},
		watch: {},
	};
</script>

<style lang="scss">
	#invoice-action-menu {
		.more-menu {
			max-width: 200px;
		}
	}
	//button.v-btn.menu-activator {
	//	height: 32px !important;
	//	width: 32px !important;
	//	min-width: 32px !important;
	//}
</style>
