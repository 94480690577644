var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.stage_id,
            expression: "stage_id"
          }
        ],
        ref: "stage_id",
        attrs: { type: "hidden", "df-stage_id": "" },
        domProps: { value: _vm.stage_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.stage_id = $event.target.value
          }
        }
      }),
      _c("node-label", {
        on: {
          open: function($event) {
            return _vm.edit()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1",
                    attrs: { color: "black", size: "22" }
                  },
                  [_vm._v("bolt")]
                ),
                _vm.selectedStage
                  ? _c("div", [
                      _vm._v("Set stage: " + _vm._s(_vm.selectedStage.label))
                    ])
                  : _c("div", [_vm._v("Update stage")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("node-modal", {
        ref: "nodeModal",
        attrs: { title: "Update stage" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "column-format gap-2" },
                  [
                    _c("v-select", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                        label: "Stage",
                        items: _vm.stages,
                        "item-text": "label",
                        "item-value": "id"
                      },
                      model: {
                        value: _vm.stage_id,
                        callback: function($$v) {
                          _vm.stage_id = $$v
                        },
                        expression: "stage_id"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }