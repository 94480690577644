<template>
	<div class="" style="width: 600px">
		<div class="row-format align-center pt-4  px-4">
			<div class="brand-medium font-16">Shared ICS Calendar</div>
			<v-btn class="ml-auto" icon @click.stop="$emit('result')"><v-icon>$close</v-icon></v-btn>
		</div>

		<div class="px-4 pb-6 text-left column-format gap-4">
			<div class="font-14">
				Select which categories you would like visible in your shared calendar.
			</div>

			<div class="column-format">
				<v-checkbox
					v-model="selectedCategories"
					v-for="category in filteredCategories"
					:value="category.value"
					:key="category.value"
					hide-details
					dense
					class="mx-0 my-2 pa-0"
				>
					<template v-slot:label>
						<div class="client-label row-format pointer align-center">
							<v-icon size="20" color="gray_80">{{ category.icon }}</v-icon>
							<div class="tree-label ml-2">{{ category.label }}</div>
						</div>
					</template>
				</v-checkbox>
			</div>

			<div class="d-flex align-center justify-space-between" v-if="shareableLink">
				<div style="flex: 1 1 auto" class="mr-1">
					<input type="hidden" :value="shareableLink" class="link-box" id="linkBox" readonly />
					<div style="overflow-wrap: anywhere;" class="font-14 brand-medium">{{shareableLink}}</div>
				</div>
				<div style="flex: 0 1 auto" class="ml-1">
					<v-btn class="super-action" @click="copyLink()">{{ $t('proposal.share.copy-link') }}</v-btn>
				</div>
			</div>

			<v-btn class="super-action" :disabled="selectedCategories.length === 0" @click="getLink()">Generate Share Link</v-btn>
			<span class="font-12 font-italic"
				>The generated link can be used to subscribe to your Moxie calendar from your calendar app of choice. If you
				aren't familiar with how ICS calendar subscriptions work, check out our
				<a href="http://help.withmoxie.com/en/articles/7158813-sharing-your-hectic-calendar" target="_blank">
					help article</a
				>
				for more information.</span
			>
		</div>
	</div>
</template>

<script>
	import CalendarService from "@/modules/calendar/CalendarService";

	export default {
		name: 'ShareCalendar',

		props: ['categories'],

		components: {},

		data: function() {
			return {
				shareableLink: null,
				selectedCategories: [],
				calendarService: new CalendarService()
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			getLink: function(){
				this.calendarService.getIcsLink(this.selectedCategories.join(",")).then((res) => this.shareableLink = res.data);
			},

			copyLink: function() {
				let copyText = document.getElementById('linkBox');
				copyText.type = 'text';
				copyText.select();
				document.execCommand('copy');
				copyText.type = 'hidden';

				this.$store.commit('success', this.$t('proposal.share.link-copied'));
			},
		},

		watch: {
			selectedCategories: function(){
				this.shareableLink = null;
			}
		},

		computed: {
			filteredCategories: function(){
				return this.categories.filter(c => c.value !== 'TODO');
			}
		},
	};
</script>

<style scoped lang="scss">
	.link-box {
		//width: 500px;
		border-radius: 4px;
		background-color: var(--v-gray_20-base);
		height: 32px;
		width: 100%;
		padding-left: 8px;
		//padding: 15px;
	}
</style>
