<template>
	<div class="text-left row-format font-14">
		<div class="row-format align-center">
			<div :key="refreshKey">
				<div v-if="ourProduct" class="row-format" style="width: 100%; column-gap: 8px; flex-wrap: wrap;">
					<div>{{ourName}}</div>
					<div v-if="!ourProduct.hourly || hours === undefined" style="text-align: right" :contenteditable="editable" :class="editableClass" @blur="updateQuantity">{{ourQuantity}}</div>
					<div v-else>{{hours}}</div>
					<div>@</div>
					<div style="text-align: right" :contenteditable="editable" :class="editableClass" @blur="updateRate">{{$formatters.dollars(ourRate,true,true,ourCurrency,4,0)}}</div>
					<div>=</div>
					<div>{{$formatters.dollars(total,true,true,ourCurrency)}}</div>
					<v-icon v-if="editable" small @click="handleClearProduct">$clear</v-icon>
					<v-icon v-if="!editable" small>$lock</v-icon>
				</div>
				<v-btn v-else @click.stop="productDialog = true" class="secondary-action">Select product /service</v-btn>
			</div>
		</div>
		<basic-modal :dialog="productDialog" @close="productDialog = false" :persistent="true">
			<products @close="productDialog = false" :currency="ourCurrency" @select="handleSelectProduct($event)" />
		</basic-modal>
	</div>
</template>

<script>
	import Products from "@/modules/invoices/InvoiceRenderer/Products";
	import BasicModal from "@/components/BasicModal";

	export default {
		name: 'PerItemPricing',

		props: ['product', 'quantity','editable','hours','hideIcon','currency','minimal','immediate'],

		components: {BasicModal, Products},

		data: function () {
			return {
				productDialog: false,
				ourQuantity: this.quantity ? this.quantity : 0,
				ourProduct: this.product,
				ourName: this.product ? this.product.productName : null,
				ourRate: this.product ? this.product.rate : 0,
				refreshKey: 0,
			};
		},

		mounted() {
			if(this.immediate){
				this.productDialog = true;
			}
		},

		beforeDestroy() {},

		methods: {
			handleSelectProduct: function(event) {
				this.ourProduct = event.product;
				this.productDialog = false;
				this.ourQuantity = 1;

				let currencyRate = event.product.currencyRates.find(c => c.currency === this.ourCurrency);

				if(currencyRate){
					this.ourRate = currencyRate.rate;
					event.product.rate  = currencyRate.rate;
				}else{
					this.ourRate = event.product.rate;
				}

				this.ourName = event.product.productName;
				this.handleQuantityUpdate();
				this.$emit('product-selected',event.product);
			},

			updateQuantity: function(event){
				let qty = event.target.innerText.replace(/[^0-9.]/g, '');
				if(qty){
					this.ourQuantity = parseFloat(qty);
				}else{
					this.ourQuantity = 0;
				}
				this.$emit('quantity-updated',this.ourQuantity);
				this.refreshKey++;
			},

			updateRate: function(event){
				let qty = event.target.innerText.replace(/[^0-9.]/g, '');
				if(qty){
					this.ourRate = parseFloat(qty);
				}else{
					this.ourRate = 0;
				}
				this.ourProduct.rate = this.ourRate;
				this.$emit('product-selected',this.ourProduct);
				this.refreshKey++;
			},

			updateProductName: function(event){
				this.ourProduct.productName = event.target.innerText;
				this.$emit('product-selected',this.ourProduct);
			},

			handleQuantityUpdate: function(){
				this.$emit('quantity-updated',this.ourQuantity);
			},

			handleClearProduct: function(){
				this.ourProduct = null;
				this.$emit('product-selected',null);
				this.$emit('deleted');
			}
		},

		computed: {
			ourCurrency: function(){
				if(this.currency){
					return this.currency;
				}else{
					return this.$store.state.defaultCurrency;
				}
			},

			total: function(){
				if(this.ourProduct.hourly && this.hours !== undefined){
					return this.ourRate * this.hours;
				}else{
					return this.ourRate * this.ourQuantity;
				}
			},

			editableClass: function(){
				if(this.editable){
					return 'editable';
				}else{
					return ''
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	[contenteditable] {
		outline: 0px solid transparent;
	}

	.editable {
		border: 1px solid var(--v-gray_30-base);
		min-width: 12px;
		padding-left: 4px;
		padding-right: 4px;
	}

	.product-button-div {
		padding-top: 6px;
		line-height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
