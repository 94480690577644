var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-4", attrs: { id: "invoice-payment-history" } },
    _vm._l(_vm.invoice.payments, function(payment) {
      return _c(
        "div",
        {
          key: payment.id,
          staticClass: "payment",
          on: {
            click: function($event) {
              return _vm.openPaymentDetails(payment)
            }
          }
        },
        [
          _c("div", { staticClass: "d-flex justify-space-between" }, [
            _c(
              "div",
              { staticClass: "timestamp" },
              [
                _c("v-icon", { attrs: { size: "12" } }, [_vm._v("$clock")]),
                _vm._v(" " + _vm._s(_vm.paymentDateFormat(payment)) + " ")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "d-flex justify-space-between" }, [
            _c("div", { staticClass: "payment-provider" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$formatters.titleCase(payment.paymentProvider)) +
                  " "
              )
            ]),
            _c("div", { staticClass: "amount" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$formatters.dollars(
                      payment.amount,
                      true,
                      true,
                      payment.currency
                    )
                  ) +
                  " "
              )
            ])
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }