<template>
	<div class="quick-link-wrapper" v-tippy :content="' + ' + name">
		<div class="quick-link pointer" :style="`background-color: ${backgroundColor};`">
			<h-icon2 size="30" :name="icon" :style="`--icon-color: ${color}`"></h-icon2>
		</div>
	</div>
</template>

<script>
	import HIcon2 from '@/components/HIcon2';

	export default {
		name: 'QuickLink',

		props: ['name', 'icon', 'color', 'backgroundColor'],

		components: { HIcon2 },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.quick-link-wrapper {
	}

	.quick-link {
		width: 44px;
		height: 44px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		&:hover {
			border: 1px solid var(--v-gray_30-base);
		}
	}
</style>
