<template>
	<div class="row-format gap-2 font-14 pointer" v-if="call" @click="openCall">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" v-tippy="{ content: 'Phone call' }">phone</v-icon>
		<div v-if="contact">{{ contact.fullName }}</div>
		<div v-else>{{ formatPhone(phone) }}</div>
		<div v-if="call.terminatingEndpoint" class>| {{ $formatters.formatSecondsFull(call.terminatingEndpoint.duration) }}</div>
		<div class="ml-auto font-gray_80 font-12">{{ call.startTime | formatForTimeAgo }}</div>
	</div>
</template>

<script>
	import CommunicatorCallService from '@/modules/communicator/CommunicatorCallService';
	import libPhoneNumber from 'google-libphonenumber';
	import HDateTime from '@/modules/utils/HDateTime';
	import CommunicationDetailModal from '@/modules/communicator/inbox/CommunicationDetailModal';

	export default {
		name: 'Call',

		props: ['item'],

		components: {},

		data: function() {
			return {
				call: null,
				callService: new CommunicatorCallService(),
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
				HDateTime: HDateTime,
			};
		},

		mounted() {
			this.callService
				.getCall(this.item.itemId)
				.then((res) => {
					this.call = res.data;
					this.$emit('actual-time', this.call.startTime);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						this.$emit('deleted');
					}
				});
		},

		beforeDestroy() {},

		methods: {
			formatPhone: function(phoneNumber) {
				try {
					let phone = this.PhoneUtil.parse(phoneNumber);
					return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
				} catch (err) {
					return phoneNumber;
				}
			},

			openCall: function() {
				let event = {
					id: this.call.id,
					channel: 'Calls',
					type: 'RecentCall',
					date: this.call.startTime,
					payload: this.call,
					unread: this.call.unread,
					archived: this.call.archived,
					snoozedUntil: this.call.snoozedUntil,
				};
				this.$store.state.globalModalController.openModal(CommunicationDetailModal, { communication: event });
			},
		},

		computed: {
			phone: function() {
				if (!this.call) {
					return null;
				} else if (this.call.direction === 'Inbound') {
					return this.call.from;
				} else {
					return this.call.to;
				}
			},

			contact: function() {
				if (!this.call) {
					return null;
				} else if (this.call.direction === 'Inbound') {
					return this.$store.getters.getContactByPhone(this.call.from);
				} else {
					return this.$store.getters.getContactByPhone(this.call.to);
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
