<template>
	<div id="login-panel" class="column-format centered">
		<v-container v-if="view === 'init'" style="max-width: 400px" class="pa-0 ma-0">
			<v-row dense>
				<v-col cols="12" align="left">
					<span class="font-24 brand-medium">{{ heading }}</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" align="center">
					<div class="column-format">
						<google-sign-in
							:key="'GoogleSignIn' + isLogin"
							:is-login="isLogin"
							:accept-terms="true"
							@error="showError($event)"
							@success="handleLoginSuccessEvent($event)"
						></google-sign-in>

						<apple-sign-in
							class="mt-2"
							:is-login="isLogin"
							:accept-terms="true"
							@error="showError($event)"
							@success="handleLoginSuccessEvent($event)"
						></apple-sign-in>

						<microsoft-sign-in
							class="mt-2"
							:is-login="isLogin"
							:accept-terms="true"
							@error="showError($event)"
							@success="handleLoginSuccessEvent($event)"
						>
						</microsoft-sign-in>

						<div v-if="!isLogin" class="column-format " style="width:100%">
							<div class="row-format align-center font-14 py-6">
								<div style="background-color: var(--v-gray_30-base); height: 1px; flex-grow: 1"></div>
								<div class="px-2 brand-bold">Or sign up with email</div>
								<div style="background-color: var(--v-gray_30-base); height: 1px; flex-grow: 1"></div>
							</div>
							<div class="column-format">
								<v-form
									ref="form"
									v-model="isValid"
									lazy-validation
									@keyup.enter.native.prevent="handleFormSubmit()"
								>
									<div class="row-format gap-2">
										<v-text-field
											v-model="firstName"
											:rules="firstNameRules"
											:label="$t('contact.first-name')"
											persistent-placeholder
											class="h-outline"
											style="background-color: var(--v-white-base)"
											hide-details
											required
											validate-on-blur
										/>
										<v-text-field
											v-model="lastName"
											:rules="lastNameRules"
											:label="$t('contact.last-name')"
											persistent-placeholder
											class="h-outline"
											style="background-color: var(--v-white-base)"
											hide-details
											required
											validate-on-blur
										/>
									</div>
									<v-text-field
										v-model="email"
										:rules="emailRules"
										:label="$t('contact.email')"
										persistent-placeholder
										class="h-outline"
										style="background-color: var(--v-white-base); margin-top: -2px"
										hide-details
										required
										validate-on-blur
									/>
									<v-btn
										class="login-button"
										block
										large
										:disabled="!isValid"
										@click.prevent="handleFormSubmit()"
									>
										{{ $t('login.sign-up-button') }}
									</v-btn>
								</v-form>
							</div>
						</div>

						<div class="column-format" v-if="isLogin">
							<v-btn
								elevation="0"
								width="400"
								style="height: 40px !important; background-color: var(--v-white-base)"
								outlined
								color="gray_30"
								@click="emailClicked"
								class="mt-2"
							>
								<div class="row-format centered" style="width: 100%">
									<v-icon :size="20" color="black" class="material-symbols-rounded">magic_button</v-icon>
									<span class="ml-2 font-gray_90 font-14">{{ emailButton }}</span>
								</div>
							</v-btn>

							<div class="row-format align-center font-14 py-6">
								<div style="background-color: var(--v-gray_30-base); height: 1px; flex-grow: 1"></div>
								<div class="px-2 brand-bold">Or sign in with password</div>
								<div style="background-color: var(--v-gray_30-base); height: 1px; flex-grow: 1"></div>
							</div>
							<div class="column-format">
								<v-form ref="form" v-model="isValid" lazy-validation @keyup.enter.native.prevent="handleFormSubmit('credentials')">
									<v-text-field
											v-model="email"
											:rules="emailRules"
											:label="$t('contact.email')"
											persistent-placeholder
											style="background-color: var(--v-white-base)"
											hide-details
											required
											class="h-outline"
									/>
									<v-text-field
											v-model="password"
											:rules="passwordRules"
											label="Password"
											type="password"
											persistent-placeholder
											style="background-color: var(--v-white-base); margin-top: -2px"
											hide-details
											required
											class="h-outline"
									/>
								</v-form>
							</div>
							<v-btn class="login-button" style="border-radius: 4px" block large :disabled="!isValid" @click.prevent="handleFormSubmit('credentials')">
								{{ $t('login.sign-in-button')}}
							</v-btn>
						</div>
					</div>
				</v-col>
			</v-row>
      <v-row justify="center">
        <v-col cols="12" class="d-flex align-center justify-center gap-4 py-4">
          <v-checkbox v-model="rememberEmail" dense hide-details label="Remember my email" />
          <span class="pointer font-gray_70 mt-2" @click="forgotPassword">Forgot password?</span>
        </v-col>
      </v-row>
      <v-row>
				<v-col cols="12" align="center" class="font-14 brand-semilight my-4">
					{{ toggleText1 }}
					<span class="primary-link pointer font-primary" @click="toggleInitView()">{{
						toggleText2
					}}</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" align="center" class="font-12 font-gray_70 mb-4">
					By logging in or signing up, you agree to Moxie’s
					<a href="https://www.withmoxie.com/terms-of-service" target="_blank" style="color: #867f77"
						>Terms of Service</a
					>
					and acknowledge that Moxie’s
					<a href="https://www.withmoxie.com/privacy-policy" target="_blank" style="color: #867f77">Privacy Policy</a>
					applies to you.
				</v-col>
			</v-row>
		</v-container>
		<v-container v-if="(view === 'email-form' || view === 'forgot-password') && isLogin" style="max-width: 400px">
			<v-row>
				<v-col cols="12" align="left" class="mb-4">
					<h2>{{ heading }}</h2>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" align="left" class="mb-4">
					{{ emailInstructions }}
				</v-col>
			</v-row>
			<v-row v-if="isLogin">
				<v-col cols="12" align="center">
					<v-form ref="form" v-model="isValid" lazy-validation @keyup.enter.native.prevent="handleFormSubmit('magicToken')">
						<v-text-field
							v-model="email"
							:rules="emailRules"
							:label="$t('contact.email')"
							persistent-placeholder
							class="h-outline"
							style="background-color: var(--v-white-base)"
							hide-details
							required
							validate-on-blur
						/>
						<v-checkbox v-model="rememberEmail" label="Remember my email" class="mt-2" />
						<v-btn class="mt-4 login-button" block large :disabled="!isValid" @click.prevent="handleFormSubmit('magicToken')">
							{{ $t('login.sign-in-button')}}
						</v-btn>

						<div class="row-format centered pointer font-gray_70 mt-4 font-14" @click="goBackToOptions()">
							<v-icon class="mr-2">$arrowLeft</v-icon>
							<div>{{ backToText }}</div>
						</div>
					</v-form>
				</v-col>
			</v-row>
		</v-container>
		<v-container v-if="view === 'confirm'" style="max-width: 400px">
			<v-row>
				<v-col cols="12">
					<v-icon color="secondary" class="material-symbols-rounded" size="120">
						mark_email_read
					</v-icon>
					<h2 class="mb-3 mt-4">
						{{ $t('login.check-inbox') }}
					</h2>
					<div
						class="mb-4 py-1"
						v-html="
							$t(isLogin ? 'login.sign-in.email-complete' : 'login.sign-up.email-complete', {
								email: this.email,
							})
						"
					/>
					<div class="mb-2 py-1 small-label" v-html="$t('login.close-window')" />
				</v-col>
			</v-row>
		</v-container>
		<v-container v-if="view === 'confirm-sms'" style="max-width: 400px">
			<v-row>
				<v-col cols="12">
					<v-icon color="primary" style="--secondary-color: var(--v-accent-base); --secondary-opacity: 1" size="120">
						settings_cell
					</v-icon>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" align="center">
					<span>Please enter the 6 digit code that was sent to phone ending in: {{ smsCodeTo }}</span>
					<br /><br />
					<code-input
						ref="smsCodeInput"
						:disabled="smsCodeDisabled"
						:fields="6"
						:auto-focus="false"
						:required="true"
						id="code-input"
						@complete="smsCodeComplete"
					></code-input>
					<span style="color: red" v-if="smsCodeInvalid"><br />The code you entered was not valid.</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" align="center">
					<v-btn icon text  color="black"  class="mt-2" @click="goBackToOptions()">
						<v-icon class="mr-2">$arrowLeft</v-icon>
						{{ backToText }}
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<v-container v-if="view === 'error'" style="max-width: 350px">
			<v-row>
				<v-col cols="12">
					<v-icon color="primary" style="--secondary-color: var(--v-accent-base); --secondary-opacity: 1" size="120">
						$alarm
					</v-icon>
					<h2 class="text-center black--text mb-2 mb-sm-4">Notice</h2>
					<div class="body-1 black--text text-center mb-4 py-1" v-html="errorMsg" />
					<v-btn  color="primary"  block large class="mt-2 mt-md-4" @click="isLogin ? goToSignUp() : goToLogin()">
						{{ errorButton }}
					</v-btn>
					<v-btn  color="primary"  block large text class="mt-2 mt-md-4" @click="isLogin ? goToLogin() : goToSignUp()">
						{{ errorButtonSecondary }}
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import SignUpService from '@/modules/auth/SignUpService';
	import AuthService from '@/modules/auth/AuthService';
	import AccountService from '@/modules/account/AccountService';
	import CodeInput from 'vue-verification-code-input';
	import GoogleSignIn from '@/modules/auth/GoogleSignIn';
	import AppleSignIn from '@/modules/auth/AppleSignIn';
	import MicrosoftSignIn from '@/modules/auth/MicrosoftSignIn';

	export default {
		name: 'LoginCore',

		props: [],

		components: { MicrosoftSignIn, AppleSignIn, GoogleSignIn, CodeInput },

		data: function () {
			return {
				type: null,
				isLogin: false,
				isValid: true,
				rememberEmail: false,

				errorMsg: null,
				view: null,
				disabled: false,

				email: null,
				password: null,
				firstName: null,
				lastName: null,

				emailSuccess: false,

				sms: false,
				smsCodeTo: null,
				smsCodeDisabled: false,
				smsCodeInvalid: false,

				accountService: new AccountService(),

				emailRules: [(v) => !!v || this.$t('valid.email-required'), (v) => /.+@.+\..+/.test(v) || this.$t('valid.email')],
				firstNameRules: [(v) => !!v || this.$t('valid.first-name')],
				lastNameRules: [(v) => !!v || this.$t('valid.last-name')],
				passwordRules: [(v) => !!v || this.$t('valid.password'), (v) => v && v.length >= 8 || this.$t('valid.password')],

				showBackground: this.$vuetify.breakpoint.mdAndUp ? 'show-background' : '',
			};
		},

		mounted() {
			if (this.$store.state.loggedIn) {
				this.$router.push('/home');
			}

			if (this.$route.path === '/login') {
				this.$track.record('page-view', { module: 'login-page' });
				this.isLogin = true;
			} else {
				this.$track.record('page-view', { module: 'signup-page' });
				this.isLogin = false;
			}

			try {
				let lsUserEmail = localStorage.getItem('userEmail');

				if (!this.$validations.isEmpty(lsUserEmail)) {
					this.email = lsUserEmail;
					this.rememberEmail = true;
				}
			} catch (err) {
				console.log('Error reading preferences from local storage.');
			}

			this.view = 'init';
			this.errorMsg = null;
			this.errorButton = null;

			this.disabled = false;
		},

		beforeDestroy() {},

		methods: {
			handleFormSubmit(type = 'magicToken') {
				// With lazy-validation, we need to manually run validate() to handle enter key
				// since no blur event occurs
				if (!this.$refs.form.validate()) {
					return;
				}

				window.grecaptcha.ready(() => {
					window.grecaptcha
						.execute(process.env.VUE_APP_RECATPCHA_SITE_KEY, { action: 'submit' })
						.then((reCaptchaToken) => {
							if (this.isLogin) {
								try {
									localStorage.setItem('userEmail', this.rememberEmail ? this.email : '');
								} catch (err) {
									console.log('Error putting preferences into local storage.');
								}

								if(type === 'magicToken') {
									return this.requestMagicLink(reCaptchaToken);
								}else{
									return this.signInWithCredentials(reCaptchaToken);
								}

							} else {
								return this.requestSignUpEmail(reCaptchaToken);
							}
						});
				});
			},

			emailClicked() {
				this.view = 'email-form';
				this.sms = false;
			},

      forgotPassword() {
        this.view = 'forgot-password';
        this.sms = false;
				this.isLogin = true;
      },

			smsCodeComplete(code) {
				this.$store.commit('startLoading');
				this.errorMsg = null;
				this.disabled = true;
				this.smsCodeDisabled = true;

				AuthService.secondFactor(this.secondFactorToken, code)
					.then((res) => {
						this.handleLoginSuccess(res, 'SMS');
					})
					.catch(() => {
						this.smsCodeInvalid = true;
						this.smsCodeDisabled = false;
						this.$store.commit('stopLoading');
						this.disabled = false;
					});
			},

			focusCodeInput: function () {
				this.$refs.smsCodeInput.$refs.input_0[0].focus();
			},

			signInWithCredentials(reCaptchaToken){
				this.$store.commit('startLoading');
				this.disabled = true;
				AuthService.credentials(this.email,this.password,reCaptchaToken).then((res) => {
					if(res.responseType === 'Authenticated'){
						this.handleLoginSuccess(res.authorizedUser);
					}else{
						this.secondFactorToken = res.secondFactorToken;
						this.smsCodeTo = res.sentTo;
						this.view = 'confirm-sms'
						setTimeout(() => this.focusCodeInput(), 750);
					}
				}).catch((err) => {
					console.log(err);
					this.$store.commit('error','You have entered an invalid email or password');
					this.disabled = false;
				}).finally(() => this.$store.commit('stopLoading'));
			},

			requestMagicLink(reCaptchaToken) {
				this.$store.commit('startLoading');
				this.errorMsg = null;
				this.disabled = true;

				let url = document.location.protocol + '//' + document.location.host;
				url = url + '/magicToken';

				if (this.$store.state.origRequestedUri) {
					url = url + '?origUri=' + this.$store.state.origRequestedUri;
				}

				AuthService.requestMagicLink(this.email, reCaptchaToken, url)
					.then(() => {
						this.view = 'confirm';
					})
					.catch((err) => {
						console.log(err);
						this.errorMsg = this.$t('login.sign-up.invalid-email');
						this.errorButton = this.$t('login.sign-up-button');
						this.errorButtonSecondary = this.$t('login.sign-in-button');
						this.view = 'error';
						this.disabled = false;
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			requestSignUpEmail(reCaptchaToken) {
				this.$store.commit('startLoading');
				this.disabled = true;

				let url = document.location.protocol + '//' + document.location.host;
				url = url + '/magicToken';
				url = url + '?origUri=/welcome';

				SignUpService.signupWithEmail(this.firstName, this.lastName, this.email, reCaptchaToken, url)
					.then(() => {
						this.$track.conversion();
						this.view = 'confirm';
					})
					.catch(() => {
						this.errorMsg = this.$t('login.sign-up.invalid-account');
						this.errorButton = this.$t('login.sign-in-button');
						this.errorButtonSecondary = this.$t('login.sign-up-button');
						this.view = 'error';
						this.disabled = false;
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			handleLoginSuccessEvent(event) {
				this.handleLoginSuccess(event.result, event.source);
			},

			handleLoginSuccess(result) {
				this.$store.commit('logIn', result);

				AuthService.getProfile().then((res) => {
					this.$store.dispatch('setLoggedInUser', res);
					this.$track.identify(res);

					setTimeout(() => {
						if (this.$store.state.origRequestedUri != '/login') {
							window.location.href = this.$store.state.origRequestedUri;
						} else {
							window.location.href = '/home';
						}
					}, 1000);
				});
			},

			showError(errorPayload) {
				console.log(this.errorMsg);
				this.errorMsg = errorPayload.errorMsg;
				this.errorButton = errorPayload.errorButton;
				this.errorButtonSecondary = errorPayload.errorButtonSecondary;
				this.view = 'error';
				this.disabled = false;
			},

			toggleInitView() {
				if (this.isLogin) {
					this.goToSignUp();
				} else {
					this.goToLogin();
				}
			},

			goBackToOptions() {
				if (this.isLogin) {
					this.goToLogin();
				} else {
					this.goToSignUp();
				}
			},

			goToSignUp() {
				if (this.$route.path !== '/signup') this.$router.push('/signup');
				this.view = 'init';
			},

			goToLogin() {
				if (this.$route.path !== '/login') this.$router.push('/login');
				this.view = 'init';
			},
		},

		computed: {
			heading: function () {
				if (this.isLogin) {
					if (this.view === 'email-form' && this.sms) {
						return 'Sign in with SMS';
					} else if (this.view === 'email-form') {
            return 'Sign in with magic token email';
          } else if (this.view === 'forgot-password') {
            return 'Forgot password?';
					} else {
						return this.$t('login.sign-in.heading');
					}
				} else {
					return this.$t('login.sign-up.heading');
				}
			},

			emailButton: function () {
				return this.isLogin ? this.$t('login.sign-in.email-button') : this.$t('login.sign-up.email-button');
			},

			smsButton: function () {
				return this.$t('login.sign-in.sms');
			},

			toggleText1: function () {
				return this.isLogin ? this.$t('login.sign-in.no-account') : this.$t('login.sign-up.already-account');
			},

			toggleText2: function () {
				return this.isLogin ? this.$t('login.sign-in.sign-up-now') : this.$t('login.sign-up.sign-in-now');
			},

			emailInstructions: function () {
				if (this.isLogin) {
					if (this.sms) {
            return this.$t('login.sign-in.sms-instructions');
          } else if (this.view === 'forgot-password') {
            return this.$t('login.sign-in.forgot-password-instructions');
					} else {
						return this.$t('login.sign-in.email-instructions');
					}
				} else {
					return this.$t('login.sign-up.email-instructions');
				}
			},

			backToText: function () {
				return this.isLogin ? this.$t('login.sign-in.back-to-options') : this.$t('login.sign-up.back-to-options');
			},
		},

		watch: {
			'$route.path': function () {
				if (this.$route.path === '/login') {
					this.isLogin = true;
				} else {
					this.isLogin = false;
				}
			},
		},
	};
</script>

<style  lang="scss">

	.login-button {
		color: #fff !important;
		background-color: #5C00E2 !important;
	}


.react-code-input {
	input {
		color: var(--v-black-base)!important;
	}
}
</style>
