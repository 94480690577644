var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row-format" },
        [
          _c(
            "v-menu",
            {
              attrs: { "close-on-content-click": true, "close-on-click": true },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          { staticClass: "secondary-action ml-auto mr-2" },
                          on
                        ),
                        [_vm._v("+ Add")]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { staticClass: "more-menu" },
                _vm._l(_vm.addItems, function(add) {
                  return _c(
                    "div",
                    {
                      key: add.value,
                      staticClass:
                        "more-menu-item row-format align-center gap-1",
                      on: {
                        click: function($event) {
                          return _vm.addItem(add.value)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "material-symbols-rounded",
                          staticStyle: { color: "var(--v-secondary-base)" }
                        },
                        [_vm._v(_vm._s(add.icon))]
                      ),
                      _c("div", [_vm._v(_vm._s(add.value))])
                    ]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _vm.items.length
        ? _c(
            "div",
            { key: _vm.refreshKey, staticClass: "column-format gap-2 mt-4" },
            _vm._l(_vm.items, function(item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "row-format workflow gap-2 py-2 align-center",
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  }
                },
                [
                  _c(item.itemType, {
                    tag: "component",
                    staticClass: "flex-grow-1",
                    attrs: { item: item, opportunity: _vm.opportunity },
                    on: {
                      deleted: function($event) {
                        return _vm.deleteItem(item)
                      },
                      refresh: _vm.refreshViews,
                      "actual-time": function($event) {
                        return _vm.setActualTime(item, $event)
                      }
                    }
                  }),
                  _c(
                    "v-icon",
                    {
                      staticClass:
                        "ml-auto material-symbols-rounded pointer unlink",
                      attrs: { small: "", color: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.unlink(item)
                        }
                      }
                    },
                    [_vm._v("link_off")]
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.items.length
        ? _c(
            "div",
            {
              staticClass: "font-12 font-gray_70",
              staticStyle: { "text-align": "center" }
            },
            [_vm._v("No activity found")]
          )
        : _vm._e(),
      _vm.formsDialog
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.formsDialog, persistent: false },
              on: {
                close: function($event) {
                  _vm.formsDialog = false
                }
              }
            },
            [
              _c("meetings-and-forms", {
                attrs: {
                  opportunity: _vm.opportunity,
                  type: _vm.meetingOrForm
                },
                on: {
                  close: function($event) {
                    _vm.formsDialog = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }