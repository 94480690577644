var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-2" },
    [
      _c("v-textarea", {
        staticStyle: { "background-color": "var(--v-white-base)" },
        attrs: {
          dense: "",
          "hide-details": "",
          outlined: "",
          rows: "3",
          "auto-grow": true,
          "persistent-placeholder": "",
          label: "Description"
        },
        model: {
          value: _vm.item.description,
          callback: function($$v) {
            _vm.$set(_vm.item, "description", $$v)
          },
          expression: "item.description"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format align-center gap-2" },
        [
          _c("v-text-field", {
            staticStyle: { "background-color": "var(--v-white-base)" },
            attrs: {
              type: "number",
              dense: "",
              "hide-details": "",
              outlined: "",
              "persistent-placeholder": "",
              label: "Quantity"
            },
            on: { change: _vm.qtyChanged },
            model: {
              value: _vm.item.quantity,
              callback: function($$v) {
                _vm.$set(_vm.item, "quantity", $$v)
              },
              expression: "item.quantity"
            }
          }),
          _c("v-text-field", {
            staticStyle: { "background-color": "var(--v-white-base)" },
            attrs: {
              type: "number",
              dense: "",
              "hide-details": "",
              outlined: "",
              "persistent-placeholder": "",
              prefix: _vm.$formatters.currencySymbol(_vm.agreement.currency),
              label: "Rate",
              placeholder: "Included"
            },
            model: {
              value: _vm.item.rate,
              callback: function($$v) {
                _vm.$set(_vm.item, "rate", $$v)
              },
              expression: "item.rate"
            }
          }),
          _c("v-checkbox", {
            attrs: {
              dense: "",
              "hide-details": "",
              "persistent-placeholder": "",
              "true-value": true,
              "false-value": false,
              label: "Taxable"
            },
            model: {
              value: _vm.item.taxable,
              callback: function($$v) {
                _vm.$set(_vm.item, "taxable", $$v)
              },
              expression: "item.taxable"
            }
          })
        ],
        1
      ),
      _vm.servicePackage.fees.feeType === "Retainer"
        ? _c("v-select", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              items: _vm.oneTimeFeeOptions
            },
            model: {
              value: _vm.item.oneTimeFee,
              callback: function($$v) {
                _vm.$set(_vm.item, "oneTimeFee", $$v)
              },
              expression: "item.oneTimeFee"
            }
          })
        : _vm._e(),
      _c("v-switch", {
        staticClass: "mt-0",
        attrs: { "hide-details": "", dense: "", label: "Relative due date" },
        model: {
          value: _vm.item.relativeDueDate,
          callback: function($$v) {
            _vm.$set(_vm.item, "relativeDueDate", $$v)
          },
          expression: "item.relativeDueDate"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format gap-2 align-center" },
        [
          _vm.item.relativeDueDate
            ? _c("v-text-field", {
                staticStyle: { "background-color": "var(--v-white-base)" },
                attrs: {
                  type: "number",
                  inputmode: "number",
                  min: "0",
                  placeholder: "No due date",
                  label: _vm.$t(
                    "templates.packages.deliverable-detail-modal.duration-placeholder",
                    {
                      units: _vm.humanUnits
                    }
                  ),
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  "persistent-placeholder": ""
                },
                on: {
                  keydown: function($event) {
                    return _vm.handleDurationKeydown($event)
                  }
                },
                model: {
                  value: _vm.item.dueDateRelative.duration,
                  callback: function($$v) {
                    _vm.$set(_vm.item.dueDateRelative, "duration", $$v)
                  },
                  expression: "item.dueDateRelative.duration"
                }
              })
            : _vm._e(),
          _vm.item.relativeDueDate
            ? _c("v-select", {
                staticStyle: { "background-color": "var(--v-white-base)" },
                attrs: {
                  label: _vm.$t(
                    "templates.packages.deliverable-detail-modal.units-placeholder"
                  ),
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  "persistent-placeholder": "",
                  items: _vm.timeUnits,
                  "item-value": "value",
                  "item-text": "text"
                },
                model: {
                  value: _vm.item.dueDateRelative.timeUnit,
                  callback: function($$v) {
                    _vm.$set(_vm.item.dueDateRelative, "timeUnit", $$v)
                  },
                  expression: "item.dueDateRelative.timeUnit"
                }
              })
            : _vm._e(),
          !_vm.item.relativeDueDate
            ? _c("date-selector", {
                staticStyle: {
                  "background-color": "var(--v-white-base)",
                  width: "100%"
                },
                attrs: {
                  label: "Due date",
                  standard: true,
                  date: _vm.item.dueDate
                },
                on: {
                  change: function($event) {
                    _vm.item.dueDate = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format centered gap-2" },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded",
                  on: {
                    click: function($event) {
                      return _vm.confirmDelete()
                    }
                  }
                },
                [_vm._v("delete")]
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action",
              staticStyle: { "min-width": "150px" },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [_vm._v("Save")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }