var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row-format centered",
      style:
        "--d-weight: " +
        _vm.item.weight +
        "px; --d-style: " +
        _vm.item.style +
        "; --d-color: " +
        _vm.item.color +
        "; --d-width: " +
        _vm.item.width +
        "%; padding-top:" +
        _vm.item.pt +
        "px!important; padding-bottom:" +
        _vm.item.pb +
        "px!important",
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [_c("hr", { staticClass: "form-divider" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }