<template>
	<div class="column-format gap-2 pt-2">
		<v-text-field
			hide-details
			dense
			outlined
			type="number"
			v-model="element.padding"
			label="Padding"
			@input="updated"
		></v-text-field>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pt"
				label="Margin top"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pb"
				label="Margin bottom"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pl"
				label="Margin left"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pr"
				label="Margin right"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.borderWidth"
				label="Border width"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.borderRadius"
				label="Border radius"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format text-left gap-1">
			<div style="flex: 1 1 0; width: 0;">
				<div>Background color</div>
				<color-selector v-model="element.backgroundColor" @input="updated"></color-selector>
			</div>
			<div style="flex: 1 1 0; width: 0;">
				<div>Border color</div>
				<color-selector v-model="element.borderColor" @input="updated"></color-selector>
			</div>
		</div>
	</div>
</template>

<script>
	import ColorSelector from '@/components/ColorSelector';

	export default {
		name: 'PaymentV2Config',

		props: ['agreement', 'item'],

		components: { ColorSelector },

		data: function() {
			return {
				element: { ...this.item },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
