var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.local
    ? _c("div", [
        _c(
          "div",
          { staticClass: "permission-dialog-div" },
          [
            _c(
              "v-btn",
              {
                staticClass: "close-dialog",
                attrs: { icon: "", rounded: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.handleClose()
                  }
                }
              },
              [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$close")])],
              1
            ),
            _c("div", { staticClass: "modal-padding" }, [
              _c(
                "div",
                { staticClass: "row-format centered" },
                [
                  _c("client-avatar", {
                    attrs: { "disable-click": true, client: _vm.local }
                  }),
                  _c("div", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(_vm.local.name))
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c("v-tab", [_vm._v("User access")]),
                      _c("v-tab", [_vm._v("Custom feature access")])
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c("v-tab-item", [
                        _c(
                          "div",
                          { staticClass: "mt-7" },
                          [
                            _vm.view === "list"
                              ? _c(
                                  "v-container",
                                  {
                                    staticClass: "ma-0 pa-0",
                                    attrs: { fluid: "" }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "mb-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "brand-medium font-14",
                                            attrs: { cols: "2", align: "left" }
                                          },
                                          [_vm._v("Access")]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "brand-medium font-14",
                                            attrs: { cols: "4", align: "left" }
                                          },
                                          [_vm._v("Name")]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "brand-medium font-14",
                                            attrs: { cols: "6", align: "left" }
                                          },
                                          [_vm._v("Email")]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.local.contacts, function(
                                      contact
                                    ) {
                                      return _c(
                                        "v-row",
                                        {
                                          key: contact.id,
                                          staticClass: "mb-2",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "2",
                                                align: "left"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                staticStyle: {
                                                  "margin-top": "0px",
                                                  width: "50px"
                                                },
                                                attrs: {
                                                  disabled: !contact.email,
                                                  dense: "",
                                                  "hide-details": ""
                                                },
                                                model: {
                                                  value: contact.portalAccess,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      contact,
                                                      "portalAccess",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contact.portalAccess"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "font-14",
                                              attrs: {
                                                cols: "4",
                                                align: "left"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(contact.firstName) +
                                                  " " +
                                                  _vm._s(contact.lastName)
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "font-14",
                                              attrs: {
                                                cols: "5",
                                                align: "left"
                                              }
                                            },
                                            [_vm._v(_vm._s(contact.email))]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pointer",
                                              attrs: {
                                                cols: "1",
                                                align: "right"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editContact(
                                                    contact
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "pointer",
                                                  attrs: {
                                                    small: "",
                                                    color: "primary"
                                                  }
                                                },
                                                [_vm._v("$edit")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    _c(
                                      "v-row",
                                      { staticClass: "mb-2" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: { cols: "12", align: "left" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-primary pointer row-format align-center",
                                                on: { click: _vm.addNewContact }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-1",
                                                    attrs: {
                                                      small: "",
                                                      color: "primary"
                                                    }
                                                  },
                                                  [_vm._v("$plus")]
                                                ),
                                                _c("div", [
                                                  _vm._v("Add new contact")
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm.view === "edit"
                              ? _c(
                                  "v-container",
                                  {
                                    staticClass: "ma-0 pa-0",
                                    staticStyle: { width: "100%" },
                                    attrs: { fluid: "" }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("v-text-field", {
                                              staticClass: "h-outline",
                                              attrs: {
                                                "hide-details": "",
                                                "persistent-placeholder": "",
                                                label: "First name"
                                              },
                                              model: {
                                                value:
                                                  _vm.contactToEdit.firstName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.contactToEdit,
                                                    "firstName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contactToEdit.firstName"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("v-text-field", {
                                              staticClass: "h-outline",
                                              attrs: {
                                                "hide-details": "",
                                                "persistent-placeholder": "",
                                                label: "Last name"
                                              },
                                              model: {
                                                value:
                                                  _vm.contactToEdit.lastName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.contactToEdit,
                                                    "lastName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contactToEdit.lastName"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c("v-text-field", {
                                              staticClass: "h-outline",
                                              attrs: {
                                                "hide-details": "",
                                                "persistent-placeholder": "",
                                                label: "Email"
                                              },
                                              model: {
                                                value: _vm.contactToEdit.email,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.contactToEdit,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contactToEdit.email"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "primary-action",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editDone()
                                                  }
                                                }
                                              },
                                              [_vm._v("OK")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _c("v-tab-item", [
                        _c("div", { staticClass: "mt-7 mb-2" }, [
                          _vm.clientFeatures
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    { staticClass: "brand-medium text-left" },
                                    [_vm._v("Customized feature access:")]
                                  ),
                                  _c("v-switch", {
                                    attrs: {
                                      label: "Projects enabled",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.clientFeatures.projectsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.clientFeatures,
                                          "projectsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clientFeatures.projectsEnabled"
                                    }
                                  }),
                                  _c("v-switch", {
                                    attrs: {
                                      label: "Invoices enabled",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.clientFeatures.invoicesEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.clientFeatures,
                                          "invoicesEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clientFeatures.invoicesEnabled"
                                    }
                                  }),
                                  _c("v-switch", {
                                    attrs: {
                                      label: "Financial reports / summary",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.clientFeatures
                                          .summaryFinancialsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.clientFeatures,
                                          "summaryFinancialsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clientFeatures.summaryFinancialsEnabled"
                                    }
                                  }),
                                  _c("v-switch", {
                                    attrs: {
                                      label: "Agreements enabled",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value:
                                        _vm.clientFeatures.proposalsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.clientFeatures,
                                          "proposalsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clientFeatures.proposalsEnabled"
                                    }
                                  }),
                                  _c("v-switch", {
                                    attrs: {
                                      label: "Time worked enabled",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.clientFeatures.timeEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.clientFeatures,
                                          "timeEnabled",
                                          $$v
                                        )
                                      },
                                      expression: "clientFeatures.timeEnabled"
                                    }
                                  }),
                                  _c("v-switch", {
                                    attrs: {
                                      label: "Meetings enabled",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.clientFeatures.meetingsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.clientFeatures,
                                          "meetingsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clientFeatures.meetingsEnabled"
                                    }
                                  }),
                                  _c("v-switch", {
                                    attrs: {
                                      label: "Requests/tickets enabled",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.clientFeatures.ticketsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.clientFeatures,
                                          "ticketsEnabled",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clientFeatures.ticketsEnabled"
                                    }
                                  }),
                                  _c("v-switch", {
                                    attrs: {
                                      label: "Forms enabled",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.clientFeatures.formsEnabled,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.clientFeatures,
                                          "formsEnabled",
                                          $$v
                                        )
                                      },
                                      expression: "clientFeatures.formsEnabled"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "text-left" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-5",
                                          attrs: {
                                            color: "gray_70",
                                            outlined: ""
                                          },
                                          on: {
                                            click: _vm.disableCustomFeatures
                                          }
                                        },
                                        [_vm._v("Disable customized access")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "text-left" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "brand-medium text-left" },
                                    [_vm._v("Default access enabled")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { outlined: "", color: "primary" },
                                      on: { click: _vm.enableCustomFeatures }
                                    },
                                    [_vm._v("Customize feature access")]
                                  )
                                ],
                                1
                              )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _vm.view === "list"
          ? _c(
              "div",
              {
                staticClass: "pt-2 pb-3",
                staticStyle: { "border-top": "1px solid var(--v-gray_50-base)" }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action",
                    attrs: { width: "150" },
                    on: {
                      click: function($event) {
                        return _vm.handleSave()
                      }
                    }
                  },
                  [_vm._v("Save")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }