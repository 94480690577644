<template>
	<div class="text-left">
		<input type="hidden" id="invoice-token">
		<div class="brand-medium">Available Tokens</div>
		<ul>
			<li v-for="token in tokens" :key="token" @click="copyToken(token)" class="pointer">{{<span v-html="token"></span>}}</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'TextConfig',

		props: ['item','tokens'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			copyToken: function(token){
				navigator.clipboard.writeText('{{' + token + '}}');
				this.$store.commit('success', 'Copied');
			}
		},

		computed: {

		},
	};
</script>

<style scoped lang="scss"></style>
