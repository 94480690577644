var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [_vm._v("Width (" + _vm._s(_vm.element.width) + "px)")]),
      _c("v-slider", {
        attrs: {
          min: 100,
          max: 500,
          step: 10,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.width,
          callback: function($$v) {
            _vm.$set(_vm.element, "width", $$v)
          },
          expression: "element.width"
        }
      }),
      _c("v-select", {
        attrs: {
          items: _vm.alignment,
          "item-text": "label",
          "item-value": "value",
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Alignment"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.alignment,
          callback: function($$v) {
            _vm.$set(_vm.element, "alignment", $$v)
          },
          expression: "element.alignment"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }