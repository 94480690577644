var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-3",
      staticStyle: { "background-color": "var(--v-white-base)" }
    },
    [
      _c(
        "div",
        { staticClass: "row-format align-center" },
        [
          _c("div", { staticClass: "brand-medium" }, [
            _vm._v("Total " + _vm._s(_vm.type))
          ]),
          _c(
            "v-icon",
            { staticClass: "ml-auto", on: { click: _vm.handleClose } },
            [_vm._v("$close")]
          )
        ],
        1
      ),
      _vm.type === "invoiced"
        ? _c("invoice-table", {
            attrs: { invoices: _vm.dataset },
            on: {
              "open-invoice": function($event) {
                return _vm.openInvoice($event)
              },
              "add-update-invoice": function($event) {
                return _vm.addOrUpdateInvoice($event)
              },
              "remove-invoice": function($event) {
                return _vm.removeInvoice($event)
              },
              "invoice-paid": function($event) {
                return _vm.handleInvoicePaid($event)
              }
            }
          })
        : _vm._e(),
      _vm.type === "received"
        ? _c("payment-table", {
            attrs: { payments: _vm.dataset },
            on: {
              "open-invoice": function($event) {
                return _vm.openInvoice($event)
              }
            }
          })
        : _vm._e(),
      _vm.type === "other income"
        ? _c("payment-table", {
            attrs: { payments: _vm.dataset },
            on: {
              "open-invoice": function($event) {
                return _vm.openIncome($event)
              }
            }
          })
        : _vm._e(),
      _vm.type === "expenses"
        ? _c("expense-table", {
            attrs: { expenses: _vm.dataset, "suppress-new": true },
            on: {
              click: function($event) {
                return _vm.openExpense($event)
              }
            }
          })
        : _vm._e(),
      _vm.type === "hours"
        ? _c("timer-event-table", {
            attrs: {
              "show-client": true,
              "show-add": false,
              events: _vm.dataset
            },
            on: {
              "edit-timer": function($event) {
                return _vm.openTimerEvent($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }