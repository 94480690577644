<template>
	<div id="proposal-template-builder">
		<header-bar
			v-if="isReady"
			:proposal-template="proposalTemplate"
			:need-save="needSave"
			:status="status"
			:saving="saving"
			:folders="folders"
			@folder-change="handleFolderChange($event)"
			@name-change="handleNameChange($event)"
			@close="save(true)"
			@save="save(false)"
			@delete="confirmDeleteTemplate()"
			@create-proposal="handleCreateProposal()"
			@duplicate="handleDuplicate()"
			:template-service="templateService"
		></header-bar>

		<div class="subheader-buttons" v-if="isReady">
			<v-btn @click="coverMenuOpen = true" class="primary-action mr-1">
				<v-icon size="20" class="mr-2">$photo</v-icon>
				{{ $t('proposal.header-bar.cover-button') }}
			</v-btn>
			<v-btn @click="textMenuOpen = true" class="primary-action ml-1">
				<v-icon size="20" class="mr-2">$atSymbol</v-icon>
				{{ $t('proposal.header-bar.font-button') }}
			</v-btn>
		</div>
		<div v-else class="my-4"></div>

		<proposal-renderer
			v-if="isReady"
			:proposal="proposalTemplate"
			:edit-mode="true"
			:template-mode="true"
			:template-service="templateService"
			:proposal-service="proposalService"
			:account="proposalTemplate.accountMini"
			:need-save="needSave"
			:saving="saving"
			:client-mode="false"
			:is-locked="isLocked"
			:refresh-key="refreshKey"
			@close="close()"
			@need-save="handleNeedSave()"
			@force-save="save()"
			@delete-proposal="confirmDeleteTemplate()"
			@cover-menu-open="coverMenuOpen = true"
			@create-package-from-deliverables="createPackage($event)"
		></proposal-renderer>

		<confirm-dialog
			:dialog="confirmDeleteDialog"
			:heading-text="$t('global.confirm')"
			:body-text="$t('templates.proposals.delete-confirm.body')"
			@confirm="deleteTemplate()"
			@close="cancelDeleteTemplate()"
		></confirm-dialog>

		<!--		<basic-modal v-if="newProjectDialog" :dialog="newProjectDialog" :persistent="true" @close="newProjectDialog = false">-->
		<!--			<new-project-->
		<!--				:open-state="newProjectDialog"-->
		<!--				:client="client"-->
		<!--				:proposal-id="proposal.id"-->
		<!--				@created="newProjectCreated($event)"-->
		<!--				@close="newProjectDialog = false"-->
		<!--			></new-project>-->
		<!--		</basic-modal>-->

		<basic-modal :dialog="coverMenuOpen" :persistent="true" @close="coverMenuOpen = false">
			<cover-modal
				v-if="!textMenuOpen"
				:template-service="templateService"
				:proposal-service="proposalService"
				:proposal="proposalTemplate"
				:header-style="headerStyle"
				:body-style="bodyStyle"
				:client-name="clientName"
				:contractor-name="contractorName"
				:open-state="coverMenuOpen"
				@cancel="coverMenuOpen = false"
				@save="handleUpdateFromCoverMenu"
				@update-account="setAccount($event)"
			></cover-modal>
		</basic-modal>

		<basic-modal :dialog="textMenuOpen" :persistent="true" @close="textMenuOpen = false">
			<format-modal
				v-if="!coverMenuOpen && isReady"
				v-bind="proposalTemplate.formatting"
				:template-service="templateService"
				:open-state="textMenuOpen"
				@close="textMenuOpen = false"
				@save="handleFormatChange($event)"
			></format-modal>
		</basic-modal>
	</div>
</template>

<script>
	import ProposalService from '@/modules/proposals/ProposalService';
	import TemplateService from '@/modules/templates/TemplateService';
	import AccountService from '@/modules/account/AccountService';
	import BasicModal from '@/components/BasicModal';
	import ProposalRenderer from '@/modules/proposals/proposal-renderer/ProposalRenderer';
	import HeaderBar from '@/modules/templates/proposals/HeaderBar';
	import FormatModal from '@/modules/proposals/FormatModal';
	import CoverModal from '@/modules/proposals/CoverModal';
	import ProposalMixin from '@/modules/proposals/proposal-renderer/ProposalMixin';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import CreatePackageFromProposalDeliverablesModal from '@/modules/templates/deliverables/CreatePackageFromProposalDeliverablesModal';

	export default {
		name: 'ProposalTemplateBuilder',

		components: {
			ProposalRenderer,
			BasicModal,
			HeaderBar,
			FormatModal,
			CoverModal,
			ConfirmDialog,
		},

		props: ['proposalTemplateId','folders'],

		mixins: [ProposalMixin],

		data() {
			return {
				proposalTemplate: null,
				isReady: false,
				// clientService: new ClientService(),
				proposalService: new ProposalService(),
				templateService: new TemplateService(),
				accountService: new AccountService(),
				editMode: true,
				needSave: false,
				saving: false,
				confirmDeleteDialog: false,
				saveTimer: setInterval(this.save, 15 * 1000),
				refreshKey: 1,
				shareDialog: false,
				shareMode: null,
				newProjectDialog: false,
				coverMenuOpen: false,
				textMenuOpen: false,
				proposalDeleted: false,

				mceConfigText: {
					menubar: false,
					inline: true,
					paste_as_text: true,
					browser_spellcheck: true,
					forced_root_block: '',
					plugins: ['paste', 'lists', 'link'],
					toolbar: ['undo redo | bold italic underline', 'numlist bullist | outdent indent | link unlink'],
				},

				client: {
					id: '',
					name: '',
					contacts: [],
				},
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.initialize();
		},

		beforeDestroy() {
			clearInterval(this.saveTimer);
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.$emit('result', null);
			},
			handleCreateProposal: function () {
				this.$emit('result', {action: 'CREATE-PROPOSAL', proposalTemplate: this.proposalTemplate});
			},
			handleNameChange: function (name) {
				this.proposalTemplate.name = name;
				this.handleNeedSave();
			},
			handleFolderChange: function(folder){
				this.proposalTemplate.folder = folder;
				this.handleNeedSave();
			},

			initialize: function () {
				this.templateService
						.getProposalTemplate(this.proposalTemplateId)
						.then((res) => {
							this.proposalTemplate = res.data;
							this.isReady = true;
						})
						.catch((err) => {
							console.log(err);
							this.$store.commit('error', `${this.$t('proposal.error.load-proposal')}: ${err.response.message}`);
							this.$emit('error', err);
						});
			},

			save(close=false) {
				if (this.needSave) {
					this.performSave();
				}
				if(close){
					this.close();
				}
			},

			performSave() {
				this.saving = true;
				this.templateService
						.putProposalTemplate(this.proposalTemplateId, this.proposalTemplate)
						.then((res) => {
							console.log(res);
							// this.copySignatureBlock(res.data);
							this.needSave = false;
							this.saving = false;
						})
						.catch((err) => {
							this.saving = false;
							this.$store.commit('error', this.$t('proposal.error.save') + err.response.data.message);
						});
			},

			handleNeedSave() {
				// if (this.proposalTemplate.proposalStatus === 'Draft') {
				this.needSave = true;
				// } else {
				// 	this.performSave();
				// }
			},

			createPackage(deliverables) {
				let binding = {
					deliverables: deliverables,
				};
				this.$store.state.globalModalController
						.openModal(CreatePackageFromProposalDeliverablesModal, binding)
						.then((res) => {
							// console.log(res);
							this.$store.commit('success', this.$t('templates.deliverable-package-saved', {name: res.name}));
							// this.$emit('result', 'newPackageCreated', res);
						});
			},

			confirmDeleteTemplate() {
				this.confirmDeleteDialog = true;
			},

			cancelDeleteTemplate() {
				this.confirmDeleteDialog = false;
			},

			deleteTemplate() {
				this.$emit('result', {action: 'DELETE', id: this.proposalTemplate.id});
			},

			handleDuplicate() {
				this.proposalTemplate.action = 'DUPLICATE';
				this.$emit('result', this.proposalTemplate);
			},

			close() {
				this.$emit('result', this.proposalTemplate);
			},

			newProjectCreated() {
				this.newProjectDialog = false;
				this.$emit('result', 'newProjectCreated');
			},

			handleUpdateFromCoverMenu(updatedPage) {
				this.proposalTemplate.coverPage.theme = updatedPage.theme;
				this.proposalTemplate.coverPage.imageDownload = updatedPage.imageDownload;
				this.proposalTemplate.coverPage.image = updatedPage.image;
				this.proposalTemplate.coverPage.imageThumb = updatedPage.imageThumb;
				this.proposalTemplate.coverPage.showLogo = updatedPage.showLogo;
				this.proposalTemplate.coverPage.headingPosition = updatedPage.headingPosition;
				this.coverMenuOpen = false;
				this.handleNeedSave();
			},

			updateAccount(event) {
				this.account = event;
			},

			handleFormatChange(format) {
				this.proposalTemplate.formatting = format;
				this.refreshKey++;
				this.handleNeedSave();
			},
		},

		computed: {
			isLocked: function() {
				return false
			},

			status: function() {
				if (this.needSave && !this.saving) {
					return this.$t('proposal.save.prepare');
				} else if (this.saving) {
					return this.$t('proposal.save.saving');
				} else {
					return this.$t('proposal.save.complete');
				}
			},
		},

		watch: {
			proposalId: function() {
				this.isReady = false;
				this.initialize();
			},
		},
	};
</script>

<style lang="scss">
	#proposal-template-builder {
		background-color: var(--v-gray_10-base);

		.subheader-buttons {
			margin: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			.v-btn {
				width: 260px;
			}
		}
	}
	#delete-proposal-modal {
		width: 300px;
		background-color: var(--v-white-base);
	}
</style>
