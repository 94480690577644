import HttpClient from '@/services/HttpClient';

export default class VendorService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getProfitAndLoss(earliest,latest) {
		return this.httpClient
			.get('/reports/profitAndLoss', {
				params: {
					earliest: earliest,
					latest: latest
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	accountingDownload(earliest,latest) {
		return this.httpClient
			.get('/reports/accountingDownload', {
				params: {
					earliest: earliest,
					latest: latest
				},
				responseType: 'blob'
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
