var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format text-left font-14 font-gray_70 py-3 gap-2" },
    [
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding top"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.item.pt,
              callback: function($$v) {
                _vm.$set(_vm.item, "pt", $$v)
              },
              expression: "item.pt"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding bottom"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.item.pb,
              callback: function($$v) {
                _vm.$set(_vm.item, "pb", $$v)
              },
              expression: "item.pb"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding left"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.item.pl,
              callback: function($$v) {
                _vm.$set(_vm.item, "pl", $$v)
              },
              expression: "item.pl"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding right"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.item.pr,
              callback: function($$v) {
                _vm.$set(_vm.item, "pr", $$v)
              },
              expression: "item.pr"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Border width"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.item.borderWidth,
              callback: function($$v) {
                _vm.$set(_vm.item, "borderWidth", $$v)
              },
              expression: "item.borderWidth"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Inner padding"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.item.padding,
              callback: function($$v) {
                _vm.$set(_vm.item, "padding", $$v)
              },
              expression: "item.padding"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "row-format text-left gap-1" }, [
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", [_vm._v("Background")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.item.backgroundColor,
                callback: function($$v) {
                  _vm.$set(_vm.item, "backgroundColor", $$v)
                },
                expression: "item.backgroundColor"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", [_vm._v("Border")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.item.borderColor,
                callback: function($$v) {
                  _vm.$set(_vm.item, "borderColor", $$v)
                },
                expression: "item.borderColor"
              }
            })
          ],
          1
        )
      ]),
      _c("hr", {
        staticClass: "my-3",
        staticStyle: {
          "border-top": "1px solid var(--v-gray_50-base)",
          "border-bottom": "none"
        }
      }),
      _c(
        "div",
        {
          staticClass: "font-primary pointer font-14 text-center",
          on: { click: _vm.addPayment }
        },
        [_vm._v("+ Add payment event")]
      ),
      _vm._l(_vm.item.scheduledPayments, function(scheduledPayment) {
        return _c(
          "div",
          {
            key: scheduledPayment.id,
            staticClass: "column-format gap-2 py-3",
            staticStyle: { "border-bottom": "1px solid var(--v-gray_30-base)" }
          },
          [
            _c("v-text-field", {
              attrs: {
                "hide-details": "",
                dense: "",
                outlined: "",
                "persistent-placeholder": "",
                label: "Label",
                disabled: scheduledPayment.mileStoneAchieved
              },
              on: { change: _vm.updated },
              model: {
                value: scheduledPayment.label,
                callback: function($$v) {
                  _vm.$set(scheduledPayment, "label", $$v)
                },
                expression: "scheduledPayment.label"
              }
            }),
            _c(
              "div",
              { staticClass: "row-format gap-2" },
              [
                _c("v-select", {
                  staticStyle: { flex: "1 1 0" },
                  attrs: {
                    items: _vm.triggers,
                    "item-text": "label",
                    "item-value": "value",
                    "hide-details": "",
                    dense: "",
                    "persistent-placeholder": "",
                    outlined: "",
                    label: "Payment trigger",
                    disabled: scheduledPayment.mileStoneAchieved
                  },
                  on: { change: _vm.updated },
                  model: {
                    value: scheduledPayment.triggerType,
                    callback: function($$v) {
                      _vm.$set(scheduledPayment, "triggerType", $$v)
                    },
                    expression: "scheduledPayment.triggerType"
                  }
                }),
                scheduledPayment.triggerType === "RelativeDate"
                  ? _c("v-text-field", {
                      staticStyle: { "max-width": "60px" },
                      attrs: {
                        type: "number",
                        inputmode: "number",
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": ""
                      },
                      on: {
                        keydown: function($event) {
                          return _vm.handleDurationKeydown($event)
                        }
                      },
                      model: {
                        value: scheduledPayment.relativeTriggerDate.duration,
                        callback: function($$v) {
                          _vm.$set(
                            scheduledPayment.relativeTriggerDate,
                            "duration",
                            $$v
                          )
                        },
                        expression:
                          "scheduledPayment.relativeTriggerDate.duration"
                      }
                    })
                  : _vm._e(),
                scheduledPayment.triggerType === "RelativeDate"
                  ? _c("v-select", {
                      staticStyle: { "max-width": "110px" },
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                        items: _vm.timeUnits,
                        "item-value": "value",
                        "item-text": "text"
                      },
                      model: {
                        value: scheduledPayment.relativeTriggerDate.timeUnit,
                        callback: function($$v) {
                          _vm.$set(
                            scheduledPayment.relativeTriggerDate,
                            "timeUnit",
                            $$v
                          )
                        },
                        expression:
                          "scheduledPayment.relativeTriggerDate.timeUnit"
                      }
                    })
                  : _vm._e(),
                scheduledPayment.triggerType === "Date"
                  ? _c("date-selector", {
                      staticStyle: { flex: "1 1 0" },
                      attrs: {
                        standard: true,
                        label: "Date",
                        date: scheduledPayment.triggerDate,
                        disabled: scheduledPayment.mileStoneAchieved
                      },
                      on: {
                        change: function($event) {
                          scheduledPayment.triggerDate = $event
                          _vm.updated()
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            !scheduledPayment.finalPayment
              ? _c("div", { staticClass: "column-format gap-2" }, [
                  _c(
                    "div",
                    { staticClass: "row-format gap-2" },
                    [
                      _c("v-select", {
                        staticStyle: { flex: "1 1 0" },
                        attrs: {
                          items: _vm.types,
                          "item-text": "label",
                          "item-value": "value",
                          "hide-details": "",
                          dense: "",
                          "persistent-placeholder": "",
                          outlined: "",
                          label: "Amount type",
                          disabled: scheduledPayment.mileStoneAchieved
                        },
                        on: { change: _vm.updated },
                        model: {
                          value: scheduledPayment.amountType,
                          callback: function($$v) {
                            _vm.$set(scheduledPayment, "amountType", $$v)
                          },
                          expression: "scheduledPayment.amountType"
                        }
                      }),
                      _c("v-text-field", {
                        staticStyle: { flex: "1 1 0" },
                        attrs: {
                          prefix:
                            scheduledPayment.amountType === "Fixed"
                              ? _vm.currencySymbol
                              : "",
                          suffix:
                            scheduledPayment.amountType === "Percentage"
                              ? "%"
                              : "",
                          numeric: "",
                          "hide-details": "",
                          dense: "",
                          "persistent-placeholder": "",
                          outlined: "",
                          label: "Amount",
                          oninput:
                            "this.value=this.value.replace(/(\\..*)\\./g, '$1');",
                          disabled: scheduledPayment.mileStoneAchieved
                        },
                        on: { change: _vm.updated },
                        model: {
                          value: scheduledPayment.amount,
                          callback: function($$v) {
                            _vm.$set(scheduledPayment, "amount", $$v)
                          },
                          expression: "scheduledPayment.amount"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text-left font-14" }, [
                    _c(
                      "div",
                      { staticClass: "row-format" },
                      [
                        _c("div", { staticClass: "brand-medium" }, [
                          _vm._v("Amount:")
                        ]),
                        _c("div", { staticClass: "ml-auto" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$formatters.dollars(
                                  scheduledPayment.calculatedAmount,
                                  true,
                                  true,
                                  _vm.agreement.currency
                                )
                              ) +
                              " (" +
                              _vm._s(
                                scheduledPayment.calculatedPercentage.toFixed(2)
                              ) +
                              "%) "
                          )
                        ]),
                        !scheduledPayment.mileStoneAchieved
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "pointer",
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.deletePayment(scheduledPayment)
                                  }
                                }
                              },
                              [_vm._v("delete")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              : _c("div", { staticClass: "text-left font-14 row-format" }, [
                  _c("div", { staticClass: "brand-medium" }, [
                    _vm._v("Final payment:")
                  ]),
                  _c("div", { staticClass: "ml-auto" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$formatters.dollars(
                            scheduledPayment.calculatedAmount,
                            true,
                            true,
                            _vm.agreement.currency
                          )
                        ) +
                        " (" +
                        _vm._s(
                          scheduledPayment.calculatedPercentage.toFixed(0)
                        ) +
                        "%) "
                    )
                  ])
                ])
          ],
          1
        )
      }),
      _c(
        "div",
        [
          _c("v-icon", { attrs: { size: "20", color: "primary" } }, [
            _vm._v("info")
          ]),
          _vm._v(
            " Payment plans do not apply to monthly or annual recurring fee schedules. "
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }