var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isInitialized
    ? _c("div", { staticClass: "column-format" }, [
        _c("div", { staticClass: "column-format gap-3" }, [
          _c("div", { staticClass: "row-format gap-3" }, [
            _c("div", { staticClass: "kpi-box" }, [
              _c("div", { staticClass: "fit text-left" }, [
                _vm._v("Total hours")
              ]),
              _c("div", { staticClass: "font-24 brand-medium fit" }, [
                _vm._v(_vm._s(_vm.formatSeconds(_vm.metrics.totalTime)))
              ])
            ]),
            _c("div", { staticClass: "kpi-box" }, [
              _c("div", { staticClass: "row-format" }, [
                _c("div", { staticClass: "row-format align-center fit" }, [
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("timetrack.list.by-project")))
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "row-format status-wrapper" },
                _vm._l(_vm.metrics.projects, function(project) {
                  return _c("div", {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: {
                          content:
                            project.name +
                            " - " +
                            _vm.formatSecondsToHours(project.totalTime)
                        },
                        expression:
                          "{ content: `${project.name} - ${formatSecondsToHours(project.totalTime)}` }"
                      }
                    ],
                    key: project.name,
                    staticClass: "status-box",
                    style:
                      "width:" +
                      project.percentage +
                      "%; background-color: " +
                      project.color
                  })
                }),
                0
              )
            ]),
            _c("div", { staticClass: "kpi-box" }, [
              _c("div", { staticClass: "fit text-left" }, [
                _vm._v(_vm._s(_vm.$t("timetrack.list.this-week")))
              ]),
              _c("div", { staticClass: "font-24 brand-medium fit" }, [
                _vm._v(_vm._s(_vm.formatSeconds(_vm.metrics.timeThisWeek)))
              ])
            ])
          ]),
          _vm.filter.timeEntryStatus.length !== 1
            ? _c("div", { staticClass: "row-format gap-3" }, [
                _vm.filter.timeEntryStatus.length === 0 ||
                _vm.filter.timeEntryStatus.includes("BILLED")
                  ? _c("div", { staticClass: "kpi-box" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "font-24 brand-medium fit" }, [
                        _vm._v(_vm._s(_vm.formatSeconds(_vm.metrics.billed)))
                      ])
                    ])
                  : _vm._e(),
                _vm.filter.timeEntryStatus.length === 0 ||
                _vm.filter.timeEntryStatus.includes("UN-BILLED")
                  ? _c("div", { staticClass: "kpi-box" }, [
                      _vm._m(1),
                      _c("div", { staticClass: "font-24 brand-medium fit" }, [
                        _vm._v(_vm._s(_vm.formatSeconds(_vm.metrics.unBilled)))
                      ])
                    ])
                  : _vm._e(),
                _vm.filter.timeEntryStatus.length === 0 ||
                _vm.filter.timeEntryStatus.includes("NON-BILLABLE")
                  ? _c("div", { staticClass: "kpi-box" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "font-24 brand-medium fit" }, [
                        _vm._v(
                          _vm._s(_vm.formatSeconds(_vm.metrics.nonBillable))
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _vm._t("filter"),
            _vm.events.length
              ? _c("timer-event-table", {
                  staticClass: "mt-4",
                  attrs: { events: _vm.filteredEvents },
                  on: {
                    "add-new": function($event) {
                      return _vm.addNew()
                    },
                    duplicate: function($event) {
                      return _vm.duplicate($event)
                    },
                    "edit-timer": function($event) {
                      return _vm.editTimerEvent($event)
                    },
                    "open-invoice": function($event) {
                      return _vm.openInvoice($event)
                    }
                  }
                })
              : _vm._e(),
            !_vm.events.length
              ? _c(
                  "div",
                  {
                    staticClass: "row-format centered mt-8",
                    staticStyle: { flex: "1" }
                  },
                  [
                    _c("empty-view", {
                      attrs: {
                        header: "No time entries yet",
                        body:
                          "If you track your time for " +
                          _vm.client.name +
                          ", your history of time entries will show up here. In addition to viewing historical entries here, you can also create new entries.",
                        cta: "Create a time entry",
                        "video-header": "See how it works",
                        "video-body":
                          "Learn how to track your time and then add those time entries to an invoice.",
                        "video-cta": "Watch the tutorial",
                        "video-id": "3l1ThT9qGks"
                      },
                      on: {
                        "cta-clicked": function($event) {
                          return _vm.addNew()
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-format" }, [
      _c("div", { staticClass: "fit text-left" }, [_vm._v("Billed")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-format" }, [
      _c("div", { staticClass: "fit text-left" }, [_vm._v("Un-billed")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-format" }, [
      _c("div", { staticClass: "fit text-left" }, [_vm._v("Non-billable")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }