var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "new-workflow" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.handleClose()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", [
        _c(
          "div",
          { staticClass: "px-5 pt-5" },
          [
            _c("div", { staticClass: "modal-title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.flow ? "Copy" : "New") +
                  " workflow automation "
              )
            ]),
            _c(
              "v-form",
              {
                ref: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                },
                model: {
                  value: _vm.isValid,
                  callback: function($$v) {
                    _vm.isValid = $$v
                  },
                  expression: "isValid"
                }
              },
              [
                _c("v-text-field", {
                  staticClass: "h-outline",
                  attrs: {
                    "hide-details": "",
                    "persistent-placeholder": "",
                    label: "Workflow name",
                    rules: _vm.required,
                    autofocus: ""
                  },
                  model: {
                    value: _vm.name,
                    callback: function($$v) {
                      _vm.name = $$v
                    },
                    expression: "name"
                  }
                }),
                _c("v-select", {
                  staticClass: "h-outline",
                  attrs: {
                    "hide-details": "",
                    "persistent-placeholder": "",
                    label: "Entry event",
                    items: _vm.eventTypes,
                    "item-value": "value",
                    "item-text": "label"
                  },
                  model: {
                    value: _vm.eventType,
                    callback: function($$v) {
                      _vm.eventType = $$v
                    },
                    expression: "eventType"
                  }
                }),
                _c("v-select", {
                  staticClass: "h-outline",
                  attrs: {
                    "hide-details": "",
                    "persistent-placeholder": "",
                    rules: _vm.required,
                    label: _vm.referenceLabel,
                    items: _vm.referenceList,
                    "item-value": "value",
                    "item-text": "label"
                  },
                  model: {
                    value: _vm.referenceId,
                    callback: function($$v) {
                      _vm.referenceId = $$v
                    },
                    expression: "referenceId"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "py-2",
          staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { width: "200", disabled: !_vm.isValid },
              on: {
                click: function($event) {
                  return _vm.create()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.create")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }