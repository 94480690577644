<template>
	<div class="row-format">
		<v-menu close-on-click>
			<template v-slot:activator="{ on }">
				<div class="button-box pointer row-format centered">
					<v-icon color="secondary" small v-on="on" v-tippy="{ content: 'Snooze notification' }">alarm</v-icon>
				</div>
			</template>
			<v-list dense style="font-size: 14px">
				<v-list-item>
					<v-list-item-content class="brand-bold">{{ $t('notifications.remind-me') }}</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content class="pointer" @click="snooze(5)">
						{{ $t('notifications.snooze.five-minutes') }}
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content class="pointer" @click="snooze(20)">
						{{ $t('notifications.snooze.twenty-minutes') }}
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content class="pointer" @click="snooze(60)">
						{{ $t('notifications.snooze.one-hour') }}
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content class="pointer" @click="snooze(180)">
						{{ $t('notifications.snooze.three-hours') }}
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content class="pointer" @click="snooze(1440)">
						{{ $t('notifications.snooze.tomorrow') }}
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-content class="pointer" @click="snooze(10080)">
						{{ $t('notifications.snooze.next-week') }}
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>

		<div class="button-box pointer row-format centered"  v-tippy="{ content: 'Dismiss notification' }" @click.stop="deleteOne()">
			<v-icon size="22" color="secondary">done</v-icon>
		</div>
	</div>
</template>

<script>
	import NotificationService from './NotificationService';
	import moment from 'moment';

	export default {
		name: 'SnoozeDelete',

		props: ['id'],

		components: {},

		data: function () {
			return {
				notificationService: new NotificationService(),
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			snooze: function (minutes) {
				let snoozeUntil = moment().add(minutes, 'minutes').toISOString();
				this.notificationService.snoozeNotification(this.id, snoozeUntil);
			},

			deleteOne: function () {
				this.notificationService.deleteNotification(this.id);
			},
		},
		computed: {},
	};
</script>

<style scoped lang="scss">
	.button-box {
		width: 32px;
		height: 32px;
		border: none;
		//border: 1px solid var(--v-gray_50-base);
		//box-sizing: border-box;
		//border-radius: 4px;
	}
</style>
