import HttpClient from "@/services/HttpClient";

export default class InvoiceTemplateService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getInvoiceTemplates() {
		return this.httpClient
			.get('/templates/invoices')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getInvoiceTemplate(id) {
		return this.httpClient
			.get(`/templates/invoices/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createInvoiceTemplate(template) {
		return this.httpClient
			.post('/templates/invoices', template)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateInvoiceTemplate(id, template) {
		return this.httpClient
			.put(`/templates/invoices/${id}`, template)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteInvoiceTemplate(id) {
		return this.httpClient
			.delete(`/templates/invoices/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
