var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "column-format call-wrapper px-4 font-14 " +
        (_vm.call.callResult === "Missed" ? "font-red" : "")
    },
    [
      _c("div", { staticClass: "row-format align-center call-record" }, [
        _vm.call.unread ? _c("div", { staticClass: "unread mr-2" }) : _vm._e(),
        _c("div", { staticClass: "row-format align-center text-left" }, [
          _c(
            "div",
            {
              staticClass: "mr-1 pointer",
              on: {
                click: function($event) {
                  return _vm.$store.state.eventBus.$emit(
                    "communicator-place-call",
                    _vm.formattedPhone
                  )
                }
              }
            },
            [
              _vm.contact
                ? _c(
                    "span",
                    {
                      directives: [{ name: "tippy", rawName: "v-tippy" }],
                      attrs: { content: _vm.formattedPhone }
                    },
                    [_vm._v(_vm._s(_vm.contact.fullName))]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.formattedPhone) + " ")])
            ]
          )
        ]),
        _c(
          "div",
          {
            class:
              "ml-auto row-format align-center " +
              (_vm.isExpandable ? "pointer" : ""),
            on: {
              click: function($event) {
                return _vm.expandCall(_vm.call)
              }
            }
          },
          [
            _vm.call.callRecording
              ? _c(
                  "div",
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: {
                          small: "",
                          color: _vm.call.callResult === "Missed" ? "error" : ""
                        }
                      },
                      [_vm._v("$voicemail")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.call.direction === "Outbound"
              ? _c("v-icon", { attrs: { small: "" } }, [_vm._v("$phoneOut")])
              : _vm._e(),
            _vm.call.callResult === "Missed" && !_vm.call.callRecording
              ? _c("v-icon", { attrs: { small: "", color: "error" } }, [
                  _vm._v("$phoneMissed")
                ])
              : _vm._e(),
            _c("div", { staticClass: "ml-1 nowrap" }, [
              _vm._v(_vm._s(_vm.$formatters.formatTimeAgo(_vm.call.startTime)))
            ])
          ],
          1
        )
      ]),
      _vm.expanded
        ? _c(
            "div",
            { staticClass: "mb-2 mx-1", staticStyle: { "text-align": "left" } },
            [
              _c("div", { staticClass: "row-format align-center font-12" }, [
                _c("div", [_vm._v(_vm._s(_vm.duration))]),
                _c(
                  "div",
                  { staticClass: "row-format align-center ml-auto" },
                  [
                    _c("div", { staticClass: "mr-1" }, [
                      _vm._v(
                        _vm._s(
                          this.start.toLocaleString(_vm.DateTime.DATETIME_MED)
                        )
                      )
                    ]),
                    _c(
                      "v-icon",
                      {
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("delete")
                          }
                        }
                      },
                      [_vm._v("$delete")]
                    )
                  ],
                  1
                )
              ]),
              _vm.callRecordingUrl
                ? _c("audio", {
                    staticClass: "mt-2",
                    staticStyle: { width: "100%" },
                    attrs: { controls: "", src: _vm.callRecordingUrl }
                  })
                : _vm._e(),
              _vm.call.transcription
                ? _c(
                    "div",
                    { staticClass: "text-left font-12 font-gray_90 mt-1" },
                    [_vm._v(_vm._s(_vm.call.transcription.text))]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }