<template>
	<v-container>
		<v-row align="center">
			<v-col cols="12" align="center">
				<h2 class="pa-10">Redirecting back to Stripe on-boarding...</h2>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import StripeIntegrationService from "@/modules/integrations/stripe/StripeIntegrationService";

	export default {
		name: 'RefreshHandler',

		props: [],

		components: {},

		data: function () {
			return {
				stripeService: new StripeIntegrationService(),
			};
		},

		mounted() {
			this.stripeService.getConnectLink().then((res) => {
				window.location.href = res.data;
			})
		},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
