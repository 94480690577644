var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Accounting" }
            ]
          }
        },
        [_vm._v(" Expenses ")]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center gap-3" },
        [
          !!_vm.expenses && !!_vm.selectedFilters
            ? _c("expense-filter", {
                attrs: {
                  slot: "filter",
                  filter: _vm.selectedFilters,
                  "is-default-filter": _vm.isDefaultFilter,
                  "client-list": _vm.clients
                },
                on: {
                  "clear-filter": function($event) {
                    return _vm.clearFilter()
                  },
                  updated: function($event) {
                    return _vm.setSelectedFilters($event)
                  }
                },
                slot: "filter"
              })
            : _vm._e(),
          _c(
            "v-menu",
            {
              attrs: {
                "nudge-bottom": 0,
                elevation: 0,
                "content-class": "add-new-menu",
                bottom: "",
                left: "",
                rounded: "",
                "offset-overflow": "",
                "offset-y": "",
                transition: "slide-y-transition"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ staticClass: "ml-auto super-action" }, on),
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v(" Add expense")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { staticClass: "add-new-dropdown" },
                _vm._l(_vm.addNew, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "add-new-item",
                      on: {
                        click: function($event) {
                          return _vm.createExpense(item.value)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.label) + " ")]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c(
            "v-tabs",
            {
              staticClass: "mb-4",
              model: {
                value: _vm.expenseTab,
                callback: function($$v) {
                  _vm.expenseTab = $$v
                },
                expression: "expenseTab"
              }
            },
            [
              _c("v-tab", { key: "0" }, [_vm._v("Expenses")]),
              _c("v-tab", { key: "1" }, [_vm._v("Recurring expenses")])
            ],
            1
          ),
          _vm.expenseTab === 0
            ? _c("expense-table", {
                attrs: { expenses: _vm.filteredExpenses },
                on: {
                  click: function($event) {
                    return _vm.editExpense($event)
                  },
                  "add-new": function($event) {
                    return _vm.createExpense("EXPENSE")
                  }
                }
              })
            : _vm._e(),
          _vm.expenseTab === 1
            ? _c("recurring-expense-list", {
                ref: "RecurringEvents",
                on: { "new-vendor": _vm.newVendor }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.expenseDetailDialog
        ? _c(
            "basic-modal",
            {
              ref: "editModal",
              attrs: { dialog: _vm.expenseDetailDialog, persistent: true },
              on: {
                close: function($event) {
                  _vm.expenseDetailDialog = false
                }
              }
            },
            [
              _c("expense-detail", {
                attrs: {
                  expense: _vm.expenseToEdit,
                  "in-clients": _vm.clients,
                  "in-projects": _vm.projects,
                  "in-vendors": _vm.vendors
                },
                on: {
                  created: _vm.handleCreate,
                  updated: _vm.handleUpdate,
                  deleted: _vm.handleDelete,
                  "new-vendor": _vm.newVendor,
                  close: function($event) {
                    _vm.expenseDetailDialog = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }