var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "notification-layout",
    {
      attrs: {
        id: _vm.id,
        "action-text": _vm.$t("notifications.view-invoice"),
        "sent-at": _vm.sentAt
      },
      on: {
        action: function($event) {
          return _vm.navigateToInvoice()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("client-avatar", { attrs: { "x-small": "", client: _vm.client } }),
          _c("div", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.client.name))])
        ],
        1
      ),
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "font-14 mb-1 brand-medium" }, [
          _vm._v(_vm._s(_vm.heading))
        ]),
        _c("div", { staticClass: "font-12" }, [
          _c("span", [
            _vm._v(" Total: " + _vm._s(_vm.total)),
            _c("br"),
            _vm.dateDue
              ? _c("span", [
                  _vm._v(
                    "Due: " +
                      _vm._s(
                        _vm.DateTime.fromISO(_vm.dateDue).toLocaleString(
                          _vm.DateTime.DATE_MED
                        )
                      )
                  )
                ])
              : _vm._e(),
            _vm.contactList
              ? _c("span", [
                  _c("br"),
                  _vm._v("To: " + _vm._s(_vm.contactList.join(", ")))
                ])
              : _vm._e()
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }