<template>
	<div v-if="isReady">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.sessions.desc') }}</div>
				<connected v-if="isConnected"></connected>
				<div v-else>
					<div class="font-primary brand-medium pointer" @click="showConnectScreen = true" v-if="!showConnectScreen">
						Connect Sessions.us
					</div>
					<div v-if="showConnectScreen" class="column-format gap-2">
						<v-text-field
							v-model="apiKey"
							style="background-color: var(--v-white-base)"
							hide-details
							dense
							persistent-placeholder
							outlined
							label="API key"
						></v-text-field>
						<v-text-field
							v-model="description"
							style="background-color: var(--v-white-base)"
							hide-details
							dense
							persistent-placeholder
							outlined
							label="Description"
						></v-text-field>
						<div class="row-format gap-2">
							<v-btn class="primary-action" @click="showConnectScreen = false">Cancel</v-btn>
							<v-btn class="super-action" width="150" @click="connectSessions">Connect</v-btn>
						</div>
					</div>
				</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<div class="mb-6 font-14">
				<span class="brand-medium"
					>{{ sessionsConnection.description }} connected on
					{{ DateTime.fromISO(sessionsConnection.connectedAt).toLocaleString(DateTime.DATETIME_MED) }}</span
				><br />
			</div>
			<v-btn class="secondary-action mr-1" @click="confirmDisconnect"><v-icon small>$delete</v-icon>Disconnect</v-btn>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
	import Connected from '@/modules/account/apps/Connected';
	import ConfirmModal from '@/components/ConfirmModal';
	import SessionsService from '@/modules/integrations/sessions/SessionsService';

	export default {
		name: 'Sessions',

		props: [],

		components: { Connected },

		data: function() {
			return {
				DateTime: DateTime,
				sessionsService: new SessionsService(),
				isConnected: false,
				expanded: false,
				isReady: false,
				showConnectScreen: false,
				sessionsConnection: null,
				apiKey: null,
				description: null,
			};
		},

		mounted() {
			this.checkConnected();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function() {
				this.isConnected = false;
				this.checkConnected();
			},

			checkConnected: function() {
				this.sessionsService
					.getStatus()
					.then((res) => {
						if (res.data) {
							this.isConnected = true;
							this.sessionsConnection = res.data;
						} else {
							this.isConnected = false;
						}
					})
					.finally(() => (this.isReady = true));
			},

			connectSessions: function() {
				if (!this.apiKey) {
					this.$store.commit('error', 'Api Key is required');
					return;
				} else if (!this.description) {
					this.$store.commit('error', 'Description is required');
					return;
				}

				this.$store.commit('startLoading');
				this.sessionsService
					.connect(this.apiKey, this.description)
					.then((res) => {
						this.apiKey = null;
						this.description = null;
						this.isConnected = true;
						this.sessionsConnection = res.data;
						this.$store.commit('success', 'Sessions.us connected');
					})
					.catch(() => this.$store.commit('error','Error connecting your Sessions account.  Please check your API Key.'))
					.finally(() => this.$store.commit('stopLoading'));
			},

			confirmDisconnect: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to disconnect your Sessions account?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.sessionsService.disconnect().then(() => {
							this.isConnected = false;
							this.expanded = false;
						});
					}
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
