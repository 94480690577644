import ClientService from "@/modules/clients/ClientService";
import ProjectService from "@/modules/projects/ProjectService";
import currencies from "@/assets/data/currencies.json";

export default {
    data: function() {
        return {
            paymentCopy: null,
            clientService: new ClientService(),
            projectService: new ProjectService(),
            clients: [],
            projects: [],
            currencies: currencies,
        };
    },

    methods: {
        getClients: function() {
            this.clientService.getClientList().then((res) => {
                this.clients.splice(0, this.clients.length);
                this.clients.push(...res.data);
            });
        },

        getProjects: function() {
            if(this.paymentCopy.clientId){
                this.projectService.getProjectsByClient(this.paymentCopy.clientId).then((res) => {
                    this.projects.splice(0, this.projects.length);
                    this.projects.push(...res.data);
                });
            }else{
                this.projects.splice(0,this.projects.length);
            }
        },


        clientChanged: function (value) {
            if (this.paymentCopy.clientId !== value) {
                this.paymentCopy.projectId = null;
            }
            if (value === 'clear') {
                this.paymentCopy.clientId = null;
                this.paymentCopy.projectId = null;
            }
            this.getProjects();
        },

        projectChanged: function (value) {
            if (value === 'clear') {
                this.paymentCopy.projectId = null;
            }
        },
    },

    computed: {
        currencySymbol: function () {
            return this.$formatters.currencySymbol(this.paymentCopy.currency);
        },

        noClientDisabled() {
            if (this.paymentCopy.clientId) return false;
            return true;
        },

        clientsPlusClear: function () {
            let tmp = JSON.parse(JSON.stringify(this.clients));
            if (this.paymentCopy.clientId) {
                tmp.unshift({ name: '--Clear--', id: 'clear' });
            }
            return tmp;
        },


        clientProjectsPlusClear: function () {
            if (this.paymentCopy.clientId && this.projects) {
                let tmp = this.projects.filter((p) => p.clientId == this.paymentCopy.clientId);
                if (this.paymentCopy.projectId) {
                    tmp.unshift({ name: '--Clear--', id: 'clear' });
                }
                return tmp;
            } else {
                return [];
            }
        },

        paymentProviders: function() {
            return [
                { value: 'CHECK', text: this.$t('invoice.payment-providers.CHECK') },
                { value: 'CASH', text: this.$t('invoice.payment-providers.CASH') },
                { value: 'CREDIT_CARD', text: this.$t('invoice.payment-providers.CREDIT_CARD') },
                { value: 'BANK_TRANSFER', text: this.$t('invoice.payment-providers.BANK_TRANSFER') },
                { value: 'STRIPE', text: this.$t('invoice.payment-providers.STRIPE') },
                { value: 'VENMO', text: this.$t('invoice.payment-providers.VENMO') },
                { value: 'ZELLE', text: this.$t('invoice.payment-providers.ZELLE') },
                { value: 'PAYPAL', text: this.$t('invoice.payment-providers.PAYPAL') },
                { value: 'APP_PAYOUT', text: this.$t('invoice.payment-providers.APP_PAYOUT') },
                { value: 'OTHER', text: this.$t('invoice.payment-providers.OTHER') },
            ]
        },
    }
};
