<template>
	<div class="panel-modal" style="width: 600px">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="$emit('result')">$chevronRight</v-icon>
				<div class="brand-semibold font-gray_80" style="font-size: 24px">{{ orig.summary }}</div>
				<v-btn class="ml-auto" icon @click.stop="$emit('result')"><v-icon>$close</v-icon></v-btn>
			</div>
		</div>

		<div class="panel-modal-body py-2 show-scrollbar">
			<v-container fluid style="background-color: var(--v-white-base); overflow-wrap: anywhere" class="pa-0 ma-0">
				<v-row>
					<v-col cols="12" align="left">
						<div class="column-format">
							<div class="my-2">
								<span>{{ HDateTime.fromISO(orig.start).toLocaleString(startFormat) }}</span>
								<span v-if="!isSameDay || !dateOnly"> - {{ HDateTime.fromISO(orig.end).toLocaleString(endFormat) }}</span>
							</div>
							<create-time-entry
								:start="orig.start"
								:end="orig.end"
								:notes="orig.summary"
								:client="guessedClient"
							></create-time-entry>
						</div>
						<div v-if="orig.attendees && orig.attendees.length > 0" class="my-5">
							<span class="brand-semibold">Attendees:<br /></span>
							<li v-for="attendee in orig.attendees" :key="attendee">
								<a :href="`mailto:${attendee}`">{{ attendee }}</a>
							</li>
						</div>
						<div v-if="orig.meta.location" class="my-5">
							<span class="brand-semibold">Location:<br /></span>
							<span v-html="orig.meta.location"></span>
						</div>
						<div v-if="orig.description" class="my-6">
							<span class="brand-semibold">Description:<br /></span>
							<div>
								<div style="font-size: 12px; white-space: pre-wrap" v-html="linkify(orig.description)"></div>
							</div>
						</div>
						<div class="mt-4 row-format align-center">
							<v-icon small class="mb-1 mr-2">$apple</v-icon>
							<div class="font-12 font-gray_70">Source: {{ orig.meta.fromCalendar }}</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	import CreateTimeEntry from '@/components/CreateTimeEntry';
	import HDateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'AppleEventDetail',
		isRightModal: true,

		props: ['event'],

		components: { CreateTimeEntry },

		data: function () {
			return {
				orig: this.event,
				HDateTime: HDateTime,
				urlRegex: /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleClose: function () {
				this.$emit('result');
			},

			linkify: function (text) {
				return text.replace(this.urlRegex, function (url) {
					return '<a href="' + url + '" target="_blank">' + url + '</a>';
				});
			},
		},

		computed: {
			isSameDay: function () {
				return HDateTime.fromISO(this.orig.start).startOf('day').ts === HDateTime.fromISO(this.orig.end).startOf('day').ts;
			},

			is12HourFormat(){
				let now = HDateTime.now().toLocaleString(HDateTime.TIME_SIMPLE).toUpperCase();
				return now.includes('AM') || now.includes('PM');
			},

			dateOnly: function () {
				return this.orig.dateOnly;
			},

			startFormat: function () {
				if(this.dateOnly){
					return HDateTime.DATE_HUGE;
				}else{
					return HDateTime.DATETIME_HUGE;
				}
			},

			endFormat: function () {
				if (this.isSameDay) {
					return HDateTime.TIME_SIMPLE;
				} else {
					return HDateTime.DATETIME_HUGE;
				}
			},

			guessedClient: function () {
				for (let i = 0; i < this.orig.attendees.length; i++) {
					let attendee = this.orig.attendees[i];
					let contact = this.$store.getters.getContactByEmail(attendee);
					if (contact && contact.clientId) {
						return this.$store.getters.getClientById(contact.clientId);
					}
				}
				return null;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
