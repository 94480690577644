var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: { "bread-crumbs": [{ to: "/home", label: "Home" }] }
        },
        [_vm._v(" My business ")]
      ),
      _vm.isAccountOwner && _vm.account
        ? _c(
            "v-container",
            { staticStyle: { "max-width": "800px" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "row-format mb-4" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-auto super-action",
                            attrs: { width: "120", disabled: !_vm.acctUpdate },
                            on: { click: _vm.saveAccount }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "row-format gap-3" }, [
                      _c(
                        "div",
                        { attrs: { id: "account-logo" } },
                        [
                          !_vm.account.accountLogo && !_vm.acctUpdate
                            ? _c(
                                "vue-dropzone",
                                {
                                  ref: "fileDropzone",
                                  attrs: {
                                    id: "dropzone",
                                    "use-custom-slot": true,
                                    options: _vm.dropzoneOptions
                                  },
                                  on: {
                                    "vdropzone-success": _vm.uploadSuccess,
                                    "vdropzone-error": _vm.uploadFailure,
                                    "vdropzone-sending":
                                      _vm.addFileUploadAuthHeader
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-icon", [
                                        _vm._v("$vuetify.icons.upload")
                                      ]),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-2",
                                          staticStyle: { "font-size": "0.8em" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("account.logo-upload")
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          !_vm.account.accountLogo && _vm.acctUpdate
                            ? _c(
                                "div",
                                [
                                  _c("v-icon", { attrs: { color: "grey" } }, [
                                    _vm._v("$warning")
                                  ]),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ml-2",
                                      staticStyle: {
                                        "font-size": "0.8em",
                                        color: "var(--v-gray_70-base)"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " Please save changes to upload your logo "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.account.accountLogo
                            ? _c(
                                "div",
                                {
                                  staticClass: "accountLogo",
                                  staticStyle: { position: "relative" }
                                },
                                [
                                  _c("v-img", {
                                    staticStyle: { "max-width": "250px" },
                                    attrs: { src: _vm.account.accountLogo }
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "delete logoDelete",
                                      staticStyle: {
                                        position: "absolute",
                                        top: "35%",
                                        left: "35%"
                                      },
                                      attrs: { icon: "" },
                                      on: { click: _vm.deleteAccountLogo }
                                    },
                                    [_c("v-icon", [_vm._v("$delete")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "flex-grow-1" }, [
                        _c(
                          "div",
                          { staticClass: "column-format gap-3" },
                          [
                            _c("v-text-field", {
                              staticClass: "background",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: _vm.$t("proposal.terms.legal-name")
                              },
                              on: { input: _vm.acctChange },
                              model: {
                                value: _vm.account.accountName,
                                callback: function($$v) {
                                  _vm.$set(_vm.account, "accountName", $$v)
                                },
                                expression: "account.accountName"
                              }
                            }),
                            _vm.entityTypes.length
                              ? _c("v-select", {
                                  staticClass: "background",
                                  attrs: {
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    dense: "",
                                    outlined: "",
                                    label: _vm.$t("proposal.terms.entity-type"),
                                    items: _vm.entityTypes
                                  },
                                  on: { input: _vm.acctChange },
                                  model: {
                                    value: _vm.account.entityType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.account, "entityType", $$v)
                                    },
                                    expression: "account.entityType"
                                  }
                                })
                              : _c("v-text-field", {
                                  staticClass: "background",
                                  staticStyle: { "min-width": "300px" },
                                  attrs: {
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    dense: "",
                                    outlined: "",
                                    placeholder:
                                      "Corporation, Proprietership, GmbH, etc ",
                                    label: _vm.$t("proposal.terms.entity-type")
                                  },
                                  on: { input: _vm.acctChange },
                                  model: {
                                    value: _vm.account.entityType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.account, "entityType", $$v)
                                    },
                                    expression: "account.entityType"
                                  }
                                })
                          ],
                          1
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "column-format gap-3 mt-8" },
                      [
                        _c("v-autocomplete", {
                          staticClass: "background",
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            dense: "",
                            outlined: "",
                            label: _vm.$t("proposal.terms.entity-country"),
                            items: _vm.countriesSimple,
                            "item-text": "name",
                            "item-value": "code"
                          },
                          on: { input: _vm.acctChange },
                          model: {
                            value: _vm.account.country,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "country", $$v)
                            },
                            expression: "account.country"
                          }
                        }),
                        _vm.states.length
                          ? _c("v-autocomplete", {
                              staticClass: "background",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: _vm.$t("proposal.terms.entity-state"),
                                "item-value": "name",
                                "item-text": "name",
                                items: _vm.states
                              },
                              on: { change: _vm.acctChange },
                              model: {
                                value: _vm.account.entityState,
                                callback: function($$v) {
                                  _vm.$set(_vm.account, "entityState", $$v)
                                },
                                expression: "account.entityState"
                              }
                            })
                          : _c("v-text-field", {
                              staticClass: "background",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: _vm.$t("proposal.terms.entity-state")
                              },
                              on: { input: _vm.acctChange },
                              model: {
                                value: _vm.account.entityState,
                                callback: function($$v) {
                                  _vm.$set(_vm.account, "entityState", $$v)
                                },
                                expression: "account.entityState"
                              }
                            }),
                        _c("v-autocomplete", {
                          staticClass: "background",
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            dense: "",
                            outlined: "",
                            label: _vm.$t("account.currency"),
                            items: _vm.currencies,
                            "item-text": "label",
                            "item-value": "value"
                          },
                          on: { input: _vm.acctChange },
                          model: {
                            value: _vm.account.currency,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "currency", $$v)
                            },
                            expression: "account.currency"
                          }
                        }),
                        _c("v-autocomplete", {
                          staticClass: "background",
                          attrs: {
                            "persistent-placeholder": "",
                            "hide-details": "",
                            dense: "",
                            outlined: "",
                            label: "Account timezone",
                            items: _vm.timezones
                          },
                          on: { input: _vm.acctChange },
                          model: {
                            value: _vm.account.timeZone,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "timeZone", $$v)
                            },
                            expression: "account.timeZone"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "font-14 text-left font-gray_80 mt-8 ml-1"
                      },
                      [_vm._v("Mailing Address")]
                    ),
                    _c(
                      "div",
                      { staticClass: "column-format gap-3 mt-3" },
                      [
                        _c("v-text-field", {
                          staticClass: "background",
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            dense: "",
                            outlined: "",
                            label: _vm.$t("contact.mailing-address")
                          },
                          on: { input: _vm.acctChange },
                          model: {
                            value: _vm.account.address1,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "address1", $$v)
                            },
                            expression: "account.address1"
                          }
                        }),
                        _c("v-text-field", {
                          staticClass: "background",
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            dense: "",
                            outlined: "",
                            label: _vm.$t("contact.address-contd")
                          },
                          on: { input: _vm.acctChange },
                          model: {
                            value: _vm.account.address2,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "address2", $$v)
                            },
                            expression: "account.address2"
                          }
                        }),
                        _c("v-text-field", {
                          staticClass: "background",
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            dense: "",
                            outlined: "",
                            label: _vm.$t("contact.city")
                          },
                          on: { input: _vm.acctChange },
                          model: {
                            value: _vm.account.city,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "city", $$v)
                            },
                            expression: "account.city"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "row-format gap-2" },
                          [
                            _c("v-text-field", {
                              staticClass: "background",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: _vm.$t("contact.locality")
                              },
                              on: { input: _vm.acctChange },
                              model: {
                                value: _vm.account.locality,
                                callback: function($$v) {
                                  _vm.$set(_vm.account, "locality", $$v)
                                },
                                expression: "account.locality"
                              }
                            }),
                            _c("v-text-field", {
                              staticClass: "background",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: _vm.$t("contact.postal")
                              },
                              on: { input: _vm.acctChange },
                              model: {
                                value: _vm.account.postal,
                                callback: function($$v) {
                                  _vm.$set(_vm.account, "postal", $$v)
                                },
                                expression: "account.postal"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format gap-2" },
                          [
                            _c("v-text-field", {
                              staticClass: "background",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: _vm.$t("account.phone")
                              },
                              on: { input: _vm.acctChange },
                              model: {
                                value: _vm.account.phone,
                                callback: function($$v) {
                                  _vm.$set(_vm.account, "phone", $$v)
                                },
                                expression: "account.phone"
                              }
                            }),
                            _c("v-text-field", {
                              staticClass: "background",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: _vm.$t("account.tax-id")
                              },
                              on: { input: _vm.acctChange },
                              model: {
                                value: _vm.account.taxId,
                                callback: function($$v) {
                                  _vm.$set(_vm.account, "taxId", $$v)
                                },
                                expression: "account.taxId"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format" },
                          [
                            _c("v-text-field", {
                              staticClass: "background",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: "Website"
                              },
                              on: { input: _vm.acctChange },
                              model: {
                                value: _vm.account.website,
                                callback: function($$v) {
                                  _vm.$set(_vm.account, "website", $$v)
                                },
                                expression: "account.website"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }