import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class EmailBoxService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
	}

	getEmailBoxes() {
		return this.httpClient
			.get('/emailBox')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEmailBox(id) {
		return this.httpClient
			.get(`/emailBox/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createEmailBox(emailBox) {
		return this.httpClient
			.post(`/emailBox/`,emailBox)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateEmailBox(id,emailBox) {
		return this.httpClient
			.put(`/emailBox/${id}`,emailBox)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteEmailBox(id) {
		return this.httpClient
			.delete(`/emailBox/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getAvailableFolders(id) {
		return this.httpClient
			.get(`/emailBox/${id}/availableFolders`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	verifySettings(id) {
		return this.httpClient
			.post(`/emailBox/${id}/verifySettings`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
