<template>
	<div id="agreement-status-menu">
		<v-btn class="secondary-action activator-button" @click="openMenu()">
			<div class="font-12 d-flex align-center">
				<v-icon small>info</v-icon>
				<div class="ml-2">{{ lastEvent }}</div>
			</div>
		</v-btn>

		<basic-modal :dialog="menu" :persistent="false" @close="closeMenu()">
			<div id="agreement-status-modal">
				<v-btn icon class="close-dialog" @click="closeMenu()">
					<v-icon>$close</v-icon>
				</v-btn>
				<div class="pa-4">
					<div class="modal-title">
						{{ $t('proposal.activity') }}
					</div>
					<div id="events-wrapper">
						<div v-for="item in events" :key="item.timestamp" class="event-wrapper">
							<div class="timestamp">
								<v-icon size="12">$clock</v-icon>
								{{ item.timestamp | formatForTimeAgo }}
							</div>
							<div class="event">{{ item.event }}</div>
						</div>
					</div>
				</div>
			</div>
		</basic-modal>
	</div>
</template>

<script>
	import BasicModal from '@/components/BasicModal';

	export default {
		name: 'EventMenu',

		props: ['agreement'],

		components: { BasicModal },

		data: function() {
			return {
				menu: false,
			};
		},

		mounted() {

		},

		beforeDestroy() {

		},

		methods: {
			openMenu() {
				this.menu = true;
			},

			closeMenu() {
				this.menu = false;
			},

		},

		computed: {
			events: function(){
				let events = [... this.agreement.events];
				return events.reverse();
			},

			lastEvent: function() {
				if (this.events && this.events.length > 0) {
					let event = this.events[0];
					return event.event + ' - ' + this.$DateTime.shortDate(event.timestamp);
				} else {
					return ' -- ';
				}
			},
		}
	};
</script>

<style scoped lang="scss">
	#agreement-status-menu {
		.activator-button {
			.unread-indicator {
				background-color: var(--v-primary-base);
				height: 8px;
				width: 8px;
				border-radius: 4px;
				margin-top: -6px;
			}
		}
	}

	#agreement-status-modal {
		width: 300px;
		//background-color: var(--v-white-base);
		#events-wrapper {
			.event-wrapper {
				text-align: left;
				margin-top: 8px;
				.timestamp {
					color: var(--v-gray_70-base);
					font-size: 12px;
					.v-icon {
						margin-top: -3px;
					}
				}
				.event {
          font-weight: 300;
					font-size: 14px;
				}
			}
		}
	}
</style>
