<template>
	<v-form id="planChange" @submit.prevent="planChange()">
		<div class="mx-8 mx-sm-12 mb-6 mb-sm-10">
			<h1 v-html="heading" class="justify-center mb-5"></h1>

			<div class="mb-5" v-html="subheading"></div>

			<div class="mb-9" v-html="$t('subscription.plan-change.confirm-caption')"></div>

			<v-btn type="submit" color="accent" large block class="my-4">{{ button }}</v-btn>
		</div>
	</v-form>
</template>

<script>
	export default {
		name: 'PlanChange',
		props: { parent: { type: Object } },

		data() {
			return {
				heading: '',
				subheading: '',
				button: '',
				buttonText: '',
			};
		},

		mounted() {
			this.buildTextAndButtons();
		},

		methods: {
			alert(type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},

			planChange() {
				this.$emit('plan-change', this.parent.planCode);
				this.$emit('close');
			},

			isProUpgrade() {
				return this.isFreePlan(this.parent.customerPlan.plan.code) && this.isProPlan(this.parent.planCode);
			},

			isProDowngrade() {
				return this.isTeamsPlan(this.parent.customerPlan.plan.code) && this.isProPlan(this.parent.planCode);
			},

			isTeamsUpgrade() {
				let currentPlan = this.parent.customerPlan.plan.code;
				return (this.isFreePlan(currentPlan) || this.isProPlan(currentPlan)) && this.isTeamsPlan(this.parent.planCode);
			},

			isTeamsDowngrade() {
				return this.isTeamsPlan(this.parent.customerPlan.plan.code) && this.isProPlan(this.parent.planCode);
			},

			isFreePlan(planCode) {
				return planCode == 'free';
			},

			isTeamsPlan(planCode) {
				return planCode === 'teams-monthly';
			},

			isProPlan(planCode) {
				return planCode === 'pro-monthly';
			},

			buildTextAndButtons() {
				const newPlan = this.parent.planHash[this.parent.planCode];
				const oldPlan = this.parent.planHash[this.parent.customerPlan.plan.code];
				const newPrice = '<span class="primary--text">' + this.$formatters.dollars(newPlan['price'], false,true,'USD') + '</span>';
				const oldPrice = '<span class="primary--text">' + this.$formatters.dollars(oldPlan['price'],false,true,'USD') + '</span>';
				const renewalDate = this.$DateTime.longDate(this.parent.customerPlan.currentPeriodEndsAt);

				if (this.isProUpgrade()) {
					this.heading = this.$t('subscription.plan-change.pro-upgrade.heading');
					this.subheading = this.$t('subscription.plan-change.pro-upgrade.subheading', {
						'new-price': newPrice,
						'old-price': oldPrice,
						'renewal-date': renewalDate,
					});
					this.button = this.$t('subscription.plan-change.pro-upgrade.button');
					return;
				}else if (this.isProDowngrade()) {
					this.heading = this.$t('subscription.plan-change.pro-downgrade.heading');
					this.subheading = this.$t('subscription.plan-change.pro-downgrade.subheading', {
						'new-price': newPrice,
						'old-price': oldPrice,
						'renewal-date': renewalDate,
					});
					this.button = this.$t('subscription.plan-change.pro-downgrade.button');
					return;
				}else if(this.isTeamsUpgrade()){
					this.heading = this.$t('subscription.plan-change.teams-upgrade.heading');
					this.subheading = this.$t('subscription.plan-change.teams-upgrade.subheading', {
						'new-price': newPrice,
						'old-price': oldPrice,
						'renewal-date': renewalDate,
					});
					this.button = this.$t('subscription.plan-change.teams-upgrade.button');
					return;
				}else if(this.isTeamsDowngrade()){
					this.heading = this.$t('subscription.plan-change.teams-downgrade.heading');
					this.subheading = this.$t('subscription.plan-change.teams-downgrade.subheading', {
						'new-price': newPrice,
						'old-price': oldPrice,
						'renewal-date': renewalDate,
					});
					this.button = this.$t('subscription.plan-change.teams-downgrade.button');
					return;
				}

				return;
			},
		},
		computed: {},
	};
</script>

<style lang="scss" scoped></style>
