<template>
	<div>
		<input type="hidden" v-model="proposal_id" ref="proposal_id" df-proposal_id />
		<input type="hidden" v-model="proposal_name" ref="proposal_name" df-proposal_name />
		<input type="hidden" v-model="proposal_version" ref="proposal_version" df-proposal_version />
		<input type="hidden" v-model="template_id" ref="template_id" df-template_id />
		<input type="hidden" v-model="to_list" ref="to_list" df-to_list />
		<input type="hidden" v-model="cc_list" ref="cc_list" df-cc_list />

		<node-label @open="openModal()">
			<template v-slot:default>
				<v-icon class="material-symbols-rounded mr-1" color="black" size="20">handshake</v-icon>
				<div v-if="proposal_name">{{ proposal_name }}</div>
				<div v-else>Create agreement</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" title="Send email">
			<template v-slot:default>
				<div class="column-format gap-2" style="min-width: 550px" v-if="isOpen">
					<v-select
						hide-details
						dense
						outlined
						persistent-placeholder
						v-model="proposal_id"
						:items="allTemplates"
						item-text="name"
						item-value="id"
						label="Agreement template"
					></v-select>

					<div v-if="selectedTemplate && !selectedTemplate.userSigned" class="font-red font-12 text-left font-weight-bold" style="max-width: 500px">
						Warning!  The agreement template you have selected has NOT been signed by {{$store.getters.getAccount.accountName}}.  Attempting to finalize the agreement in this workflow will fail.  Please pre-sign the template if you wish to utilize it in this workflow.
					</div>
					<email-selector
						:to_list="toList"
						:cc_list="ccList"
						:template_id="template_id"
						@cc-list="updateCcList($event)"
						@to-list="updateToList($event)"
						@selected-template="updateSelectedTemplate($event)"
					></email-selector>
				</div>
			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeMixin from '@/modules/workflow/nodes/NodeMixin';
	import NodeModal from '@/modules/workflow/nodes/NodeModal';
	import NodeLabel from '@/modules/workflow/nodes/NodeLabel';
	import EmailSelector from '@/modules/workflow/nodes/components/EmailSelector';
	import TemplateService from '@/modules/templates/TemplateService';

	export default {
		name: 'Agreement',
		inputs: 1,
		outputs: 1,

		mixins: [NodeMixin],

		props: [],

		components: {EmailSelector, NodeLabel, NodeModal },

		data: function() {
			return {
				//persisted values
				proposal_id: null,
				proposal_name: null,
				proposal_version: null,
				template_id: null,
				to_list: null,
				cc_list: null,

				//internal values
				toList: [],
				ccList: [],
				templates: [],
				proposals: [],
				agreements: [],
				isOpen: false,
				templateService: new TemplateService(),
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.proposal_id = this.$refs.proposal_id.value;
				this.proposal_name = this.$refs.proposal_name.value;
				this.proposal_version = this.$refs.proposal_version.value;
				this.template_id = this.$refs.template_id.value;

				if (this.$refs.to_list.value) {
					let to = JSON.parse(this.$refs.to_list.value);
					this.toList.push(...to);
				}

				if (this.$refs.cc_list.value) {
					let cc = JSON.parse(this.$refs.cc_list.value);
					this.ccList.push(...cc);
				}
			});
		},

		beforeDestroy() {},

		methods: {
			updateToList: function(list) {
				this.toList.splice(0, this.toList.length);
				this.toList.push(...list);
			},

			updateCcList: function(list) {
				this.ccList.splice(0, this.ccList.length);
				this.ccList.push(...list);
			},

			updateSelectedTemplate: function(template) {
				if (template) {
					this.template_id = template.id;
				} else {
					this.template_id = null;
				}
			},

			openModal: function() {
				let p1 = this.getProposalTemplates();
				let p2 = this.getAgreementTemplates();
				Promise.all([p1,p2]).then(() => {
					this.isOpen = true;
					this.$refs.nodeModal.open();
				});
			},

			getProposalTemplates: function() {
				return new Promise((resolve, reject) => {
					this.templateService
						.getProposalTemplates()
						.then((res) => {
							this.proposals.splice(0, this.proposals.length);
							this.proposals.push(...res.data);
							resolve();
						})
						.catch((err) => reject(err));
				});
			},

			getAgreementTemplates: function(){
				return new Promise((resolve, reject) => {
					this.templateService
							.getAgreementTemplates()
							.then((res) => {
								this.agreements.splice(0, this.agreements.length);
								this.agreements.push(...res.data);
								resolve();
							})
							.catch((err) => reject(err));
				});
			}
		},

		watch: {
			template_id: function() {
				this.emitInput('template_id');
			},

			template_name: function() {
				this.emitInput('template_name');
			},

			toList: function() {
				this.to_list = JSON.stringify(this.toList);
				this.$nextTick(() => this.emitInput('to_list'));
			},

			ccList: function() {
				this.cc_list = JSON.stringify(this.ccList);
				this.$nextTick(() => this.emitInput('cc_list'));
			},

			selectedTemplate: function(){
				if(this.selectedTemplate){
					this.proposal_id = this.selectedTemplate.id;
					this.proposal_name = this.selectedTemplate.name;
					this.proposal_version = this.selectedTemplate.version;
				}else{
					this.proposal_id = null;
					this.proposal_name = null;
					this.proposal_version = null;
				}
				this.$nextTick(() => {
					this.emitInput('proposal_id');
					this.emitInput('proposal_name');
					this.emitInput('proposal_version');
				});

			}
		},

		computed: {
			allTemplates: function(){
				let result = []
				this.proposals.forEach(p => {
					result.push({
						id: p.id,
						name: p.name,
						version: 1,
						userSigned: true
					})
				});
				this.agreements.forEach(a => {
					result.push({
						id: a.id,
						name: a.name,
						version: 2,
						userSigned: a.userSigned
					})
				});
				return result;
			},

			selectedTemplate: function() {
				return this.allTemplates.find((p) => p.id === this.proposal_id);
			},
		},
	};
</script>

<style scoped lang="scss"></style>
