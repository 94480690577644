var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "empty-filtered-results row-format centered pa-2 " +
        (_vm.enableClick ? "pointer" : ""),
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "row-format align-center" },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { size: "25" } }, [
            _vm._v("$searchBolt")
          ]),
          _c("div", {
            staticClass: "font-gray_80",
            domProps: { innerHTML: _vm._s(_vm.text) }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }