var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "words pb-3" },
      [
        _c("editor", {
          ref: "editor",
          staticStyle: { border: "1px dotted var(--v-grey-lighten3)" },
          attrs: {
            "api-key": _vm.$store.getters.getTinyMceKey,
            "initial-value": _vm.initialValue,
            inline: true,
            init: _vm.mceConfigText,
            spellcheck: true,
            placeholder: _vm.textPlaceholder
          },
          on: { input: _vm.handleChange }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }