<template>
	<div class="agreement-builder" v-if="agreement" :key="refreshKey" :style="`--v-zoom: ${zoom / 100}; ${styles}`">
		<div class="header-area px-2">
			<div class="header-left row-format gap-3">
				<v-text-field
					id="agreement-name"
					v-model="agreement.name"
					hide-details
					dense
					outlined
					placeholder="Name"
					style="min-width: 370px"
					v-if="agreement.status === 'Draft'"
				></v-text-field>
				<div
					v-else
					:style="
						`font-family: '${agreement.styles.h1.fontFamily}'; font-weight:600; font-size: 20px; max-width:300px; `
					"
					class="truncate"
				>
					{{ agreement.name }}
				</div>
				<pipeline-link-widget
					:item-id="agreement.id"
					item-type="Agreement"
					:client-id="agreement.clientId"
				></pipeline-link-widget>
				<div class="row-format align-center pl-3" style="border-left: 1px solid var(--v-gray_30-base)">
					<v-icon class="pointer" @click="zoomIn()" size="24">zoom_in</v-icon>
					<v-icon class="pointer" @click="zoomOut()" size="24">zoom_out</v-icon>
					<div class="font-12">{{ zoom }}%</div>
				</div>
			</div>
			<div class="header-right">
				<event-menu :agreement="agreement"></event-menu>
				<v-btn
					class="primary-action ml-2"
					width="96"
					@click="updateAgreement(false, true)"
					v-if="agreement.status === 'Draft'"
				>
					Save
				</v-btn>
				<v-btn class="super-action ml-2" @click="shareDialog"> Share </v-btn>

				<v-menu :close-on-click="true" nudge-bottom="30">
					<template v-slot:activator="scope">
						<div class="ml-2">
							<v-btn icon class="menu-activator" :disabled="false" v-on="scope.on">
								<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" v-if="agreement.status === 'Draft'" @click="finalizeAgreement">
							Finalize
						</div>
						<div
							class="more-menu-item"
							v-if="agreement.status !== 'Draft' && !agreement.fullyExecuted"
							@click="returnToDraft"
						>
							Return to draft
						</div>
						<div
							class="more-menu-item"
							v-if="agreement.status !== 'Void' && !agreement.fullyExecuted"
							@click="voidAgreement"
						>
							Void
						</div>
						<div class="more-menu-item" v-if="!agreement.isFullyExecuted" @click="deleteAgreement">
							Delete
						</div>
					</div>
				</v-menu>

				<v-btn icon @click="updateAgreement(true)">
					<v-icon :size="20">$close</v-icon>
				</v-btn>
			</div>
		</div>
		<div style="height: calc(100% - 50px);" class="row-format">
			<div
				v-if="agreement.status === 'Draft'"
				class="row-format"
				style="width: 400px; max-width:400px; min-width:400px; border-right: 1px solid var(--v-gray_30-base); height: 100%; max-height: 100%; background-color: var(--v-white-base)"
			>
				<tab-switcher :current-tab="tab" @change="tab = $event"></tab-switcher>
				<div v-if="tab === 'Elements' && editItem" class="py-3" style="flex: 1 1 0">
					<config-renderer
						:key="editItem.id"
						:page="editPage"
						:item="editItem"
						:agreement="agreement"
						:agreement-service="agreementService"
						@change="itemUpdated(editPage, $event)"
						@delete="confirmDelete()"
						@duplicate="duplicate()"
						@done="doneEditingItem()"
					></config-renderer>
				</div>
				<div v-else style="height: 100%; max-height: 100%; width:100%" class="row-format show-scrollbar pa-2">
					<div v-if="tab === 'Elements'" style="flex: 1 1 0">
						<element-types @dragstart="dragStart($event)" :disable-drag="false"></element-types>
					</div>
					<div v-show="tab === 'Signers'" style="flex: 1 1 0">
						<signers :agreement="agreement" @change="updateSigners($event)"></signers>
					</div>
					<div v-show="tab === 'Styles'" style="flex: 1 1 0">
						<styles-config :agreement="agreement"></styles-config>
					</div>
					<div v-show="tab === 'Settings'" style="flex: 1 1 0">
						<settings-config :agreement="agreement"></settings-config>
					</div>
					<div v-show="tab === 'Pages'" style="flex: 1 1 0">
						<pages-config :agreement="agreement" @add-page="addPage"></pages-config>
					</div>
				</div>
			</div>
			<div
				v-else-if="indexedPages.length && $vuetify.breakpoint.mdAndUp"
				class="column-format pb-3 px-2"
				style="border-right: 1px solid var(--v-gray_30-base); height: 100%; max-height: 100%; background-color: var(--v-white-base); min-width: 250px; max-width: 250px"
			>
				<ul class="text-left font-14 py-2">
					<li
						v-for="page in indexedPages"
						:key="page.index"
						class="pointer py-1 font-16 nav-link"
						:style="
							`border-bottom: 1px solid var(--v-gray_30-base); font-family: '${agreement.styles.h1.fontFamily}';`
						"
						@click="scrollToPage(page.index)"
					>
						{{ page.title }}
					</li>
				</ul>
			</div>
			<div style="max-height: calc(100vh - 50px); width:100%; overflow-y: scroll" class="show-scrollbar" id="page-area">
				<div
					class="zoom"
					style="min-width: fit-content; width:100%; overflow-x: scroll"
				>
					<div id="print-area">
						<div
							v-for="(page, index) in agreement.pages"
							:key="page.id"
							:id="`page-${index}`"
							class="column-format centered"
						>
							<page
								:ref="`page-${index}`"
								:key="index + ':' + refreshKey"
								:page-index="index"
								:agreement="agreement"
								:mce-config="mceConfig"
								:refresh-key="refreshKey"
								:client-mode="false"
								:edit-mode="agreement.status === 'Draft'"
								:tokens="tokens"
								:signer="signer"
								:page-width="pageWidth"
								@delete="confirmDelete($event)"
								@change="itemUpdated(page, $event)"
								@container-item-update="containerItemUpdated(page, $event)"
								@signed="itemSigned(page, $event)"
								@container-item-signed="itemSigned(page, $event)"
								@edit="setActiveItem(page, $event)"
								@container-item-click="containerItemClicked(page, $event)"
								@save-state="saveState()"
							></page>
							<div class="page-break"></div>
						</div>
					</div>

					<div class="column-format centered" v-if="agreement.status === 'Draft'">
						<v-btn text @click="addPage()">+ Add page</v-btn>
					</div>
				</div>
			</div>
		</div>

		<basic-modal :dialog="shareSendDialog" v-if="shareSendDialog" @close="shareSendDialog = false" :retain-focus="false">
			<share-send-widget @close="shareSendDialog = false" :agreement="agreement"></share-send-widget>
		</basic-modal>
	</div>
</template>

<script>
	import ConfigRenderer from '@/modules/agreements/config/ConfigRenderer';
	import ConfirmModal from '@/components/ConfirmModal';
	import AgreementService from '@/modules/agreements/AgreementService';
	import Signers from '@/modules/agreements/config/Signers';
	import StylesConfig from '@/modules/agreements/config/StylesConfig';
	import TabSwitcher from '@/modules/agreements/TabSwitcher';
	import PagesConfig from '@/modules/agreements/config/PagesConfig';
	import BasicModal from '@/components/BasicModal';
	import ShareSendWidget from '@/modules/agreements/ShareSendWidget';
	import AgreementMixin from '@/modules/agreements/schema/AgreementMixin';
	import EventMenu from '@/modules/agreements/EventMenu';
	import BuilderMixin from '@/modules/agreements/BuilderMixin';
	import SettingsConfig from '@/modules/agreements/config/SettingsConfig';
	import ElementTypes from '@/modules/agreements/schema/ElementTypes';
	import Page from '@/modules/agreements/schema/Page';

	export default {
		name: 'AgreementBuilder',

		props: ['id'],

		mixins: [AgreementMixin, BuilderMixin],

		components: {
			ElementTypes,
			EventMenu,
			ShareSendWidget,
			BasicModal,
			PagesConfig,
			TabSwitcher,
			StylesConfig,
			SettingsConfig,
			Page,
			ConfigRenderer,
			Signers,
			PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget'),
		},

		data: function() {
			return {
				agreementService: new AgreementService(),
				shareSendDialog: false,
				autoSaveRequired: false,
				autoSaveTimer: null,
				pageWidth: 0,
				resizeObserver: null,
			};
		},

		mounted() {
			this.getAgreement();
			this.checkUndo = this.checkUndo.bind(this);

			this.$store.state.eventBus.$on('agreement-show-signers', this.showSigners);
			this.$store.state.eventBus.$on('undo-enabled', this.setUndoEnabled);
			this.$store.state.eventBus.$on('save-agreement-state', this.flagAutoSave);
			this.autoSaveTimer = setInterval(() => this.autoSave(), 30 * 1000);
			document.addEventListener('keydown', this.checkUndo);

			this.resizeObserver = new ResizeObserver(() => {
				this.setPageWidth();
			});
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('agreement-show-signers', this.showSigners);
			this.$store.state.eventBus.$off('undo-enabled', this.setUndoEnabled);
			this.$store.state.eventBus.$off('save-agreement-state', this.flagAutoSave);
			clearInterval(this.autoSaveTimer);
			document.removeEventListener('keydown', this.checkUndo);
		},

		methods: {
			listenForPageChanges: function() {
				this.$nextTick(() => {
					this.resizeObserver.observe(document.getElementById('page-area'));
					this.setPageWidth();
				});
			},

			setPageWidth: function() {
				const page = document.getElementById('page-area');
				if (page) {
					this.pageWidth = page.offsetWidth;
				}
			},

			flagAutoSave: function() {
				this.autoSaveRequired = true;
			},

			autoSave: function() {
				if (this.autoSaveRequired) {
					this.updateAgreement(false, false);
				}
			},

			getAgreement: function() {
				this.agreementService.getAgreement(this.id).then((res) => {
					this.agreement = res.data;
					this.loadFonts(this.agreement);
					this.saveState();
					this.listenForPageChanges();
				});
			},

			handleClose: function() {
				this.updateAgreement(true, false);
			},

			updateAgreement: function(close = false, showNotification = false) {
				this.agreementService.saveAgreement(this.id, this.agreement).then((res) => {
					this.autoSaveRequired = false;
					if (close) {
						this.$emit('result', res.data);
					} else {
						if (res.data.documentVersionMismatch) {
							this.$store.commit(
								'warn',
								'This version of your document was outdated. We have updated to latest version.'
							);
							this.agreement = res.data;
							this.refreshKey++;
							showNotification = false;
						} else {
							this.agreement.documentVersion = res.data.documentVersion;
						}

						if (showNotification) {
							this.$store.commit('success', 'Saved');
						}
					}
				});
			},

			shareDialog: function() {
				if (this.agreement.signers.filter((s) => s.signerType === 'CLIENT').length === 0) {
					this.$store.commit('error', 'You must specify at least one client signer before you can share');
					return;
				}

				if (this.agreement.status === 'Draft') {
					let binding = {
						headingText: 'Finalize agreement?',
						bodyText:
							'Before you can share the link or send this agreement, it must be finalized.  Do you want to continue?',
					};

					this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
						if (res) {
							this.finalizeAgreement().then(() => this.openShareDialog());
						}
					});
				} else {
					this.openShareDialog();
				}
			},

			openShareDialog: function() {
				this.shareSendDialog = true;
			},

			showSigners: function() {
				this.selectTab('Signers');
			},

			finalizeAgreement: function() {
				return new Promise((resolve, reject) => {
					this.$store.commit('startLoading');
					this.$track.record('proposal-sent');
					this.agreementService
						.saveAgreement(this.id, this.agreement)
						.then(() => {
							this.agreementService
								.finalizeAgreement(this.id)
								.then((res) => {
									this.$onBoarding.track('agreements_build_and_send');
									this.agreement = res.data;
									this.refreshKey++;
									resolve(this.agreement);
								})
								.catch((err) => {
									this.$store.commit('error', err.response.data.message);
									reject(err);
								})
								.finally(() => this.$store.commit('stopLoading'));
						})
						.catch((err) => {
							this.$store.commit('stopLoading');
							this.$store.commit('error', err.response.data.message);
						});
				});
			},

			returnToDraft: function() {
				this.$store.commit('startLoading');
				this.agreementService
					.returnToDraft(this.id)
					.then((res) => {
						this.agreement = res.data;
						this.refreshKey++;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			voidAgreement: function() {
				this.$store.commit('startLoading');
				this.agreementService
					.voidAgreement(this.id)
					.then((res) => {
						this.agreement = res.data;
						this.refreshKey++;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			deleteAgreement: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this agreement?  This cannot be undone.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.agreementService.deleteAgreement(this.id).then(() => {
							this.agreement.deleted = true;
							this.refreshKey++;
							this.$emit('result', this.agreement);
						});
					}
				});
			},

			selectTab: function(tab) {
				this.tab = tab;
			},
		},

		watch: {},

		computed: {
			tokens: function() {
				return this.getTokensFromAgreement(this.agreement);
			},

			indexedPages: function() {
				let result = [];
				this.agreement.pages.forEach((p, index) => {
					result.push({ title: p.title, index: index });
				});
				return result.filter((r) => r.title);
			},
		},
	};
</script>

<style lang="scss">
	@page {
		size: auto; /* Auto size based on printer settings */
		margin: 0; /* Set margins to zero for full-bleed */
	}

	@media print {
		html, body {
			visibility: hidden;
			margin: 0 !important;
			padding: 0 !important;
		}

		.v-dialog {
			overflow: visible !important;
			height: auto !important;
			position: absolute;
			margin: 0 !important;
			padding: 0 !important;
		}

		.v-dialog__content {
			position: absolute !important;
			margin: 0 !important;
			padding: 0 !important;
		}

		.v-dialog--fullscreen {
			position: absolute !important;
			margin: 0 !important;
			padding: 0 !important;
		}

		#print-area {
			visibility: visible !important;
			position: absolute !important;
			left: 0;
			top: 0;
			width: 100% !important;
			height: auto !important;
			overflow: visible !important;
			margin: 0 !important;
			padding: 0 !important;

			.page {
				padding: 0 !important;
				margin: 0 !important;
				width: 100% !important;
				height: 100% !important;
			}

			.page-wrapper {
				padding-top: 12px !important;
				padding-bottom: 12px !important;
			}

			.page-number {
				display: none;
			}

			.shadow {
				filter: none !important;
			}
		}

		.page-break {
			page-break-after: always;
			page-break-inside: avoid;
		}
	}
</style>

<style scoped lang="scss">
	.zoom {
		zoom: var(--v-zoom);
		-ms-zoom: var(--v-zoom);
		-webkit-zoom: var(--v-zoom);
		-moz-transform: scale(var(--v-zoom), var(--v-zoom));
		-moz-transform-origin: top;
	}

	.agreement-builder {
		background-color: var(--canvas-color);
		height: var(--vh);
		.header-area {
			height: 50px;
			width: 100%;
			background: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_30-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
