var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "font-family": "Inter" } }, [
    _c(
      "div",
      { staticClass: "row-format align-center", staticStyle: { gap: "8px" } },
      [
        _c(
          "div",
          {
            staticClass: "play-button pointer",
            on: {
              click: function($event) {
                _vm.currentTimer ? _vm.handleStop() : _vm.handlePlay()
              }
            }
          },
          [
            !_vm.currentTimer
              ? _c("v-icon", { attrs: { size: "10", color: "secondary" } }, [
                  _vm._v("$play")
                ])
              : _c("v-icon", { attrs: { size: "10", color: "secondary" } }, [
                  _vm._v("$stop")
                ])
          ],
          1
        ),
        _vm.currentTimer
          ? _c(
              "div",
              {
                staticClass: "play-button pointer",
                on: {
                  click: function($event) {
                    _vm.currentTimer.pausedAt
                      ? _vm.handleResume()
                      : _vm.handlePause()
                  }
                }
              },
              [
                _vm.currentTimer.pausedAt
                  ? _c(
                      "span",
                      {
                        staticClass: "material-symbols-rounded",
                        staticStyle: {
                          color: "var(--v-secondary-base)",
                          "font-size": "20px"
                        }
                      },
                      [_vm._v("resume")]
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "material-symbols-rounded",
                        staticStyle: {
                          color: "var(--v-secondary-base)",
                          "font-size": "20px"
                        }
                      },
                      [_vm._v("pause")]
                    )
              ]
            )
          : _vm._e(),
        _c(
          "v-menu",
          {
            attrs: {
              "nudge-bottom": "20",
              bottom: "",
              "close-on-content-click": false,
              disabled: !_vm.currentTimer
            },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "div",
                      _vm._g(
                        {
                          class:
                            "font-14 column-format " +
                            (_vm.currentTimer ? "pointer" : ""),
                          staticStyle: { width: "50px" }
                        },
                        on
                      ),
                      [_c("div", [_vm._v(_vm._s(_vm.timerDisplay))])]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.showProjectMenu,
              callback: function($$v) {
                _vm.showProjectMenu = $$v
              },
              expression: "showProjectMenu"
            }
          },
          [
            _vm.currentTimer
              ? _c("client-project-select", {
                  attrs: {
                    "current-timer": _vm.currentTimer,
                    "show-menu": _vm.showProjectMenu
                  },
                  on: {
                    "delete-timer": _vm.deleteTimer,
                    "update-timer": function($event) {
                      return _vm.handleUpdateTimer($event)
                    },
                    close: function($event) {
                      _vm.showProjectMenu = false
                    }
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "v-menu",
          {
            attrs: {
              "offset-y": "",
              left: "",
              "close-on-content-click": false
            },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-icon",
                      _vm._g(
                        {
                          staticClass: "pointer",
                          attrs: { size: "16", color: "secondary" }
                        },
                        on
                      ),
                      [
                        _vm._v(
                          _vm._s(
                            _vm.showHistoryMenu ? "$arrowUp" : "$arrowDown"
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.showHistoryMenu,
              callback: function($$v) {
                _vm.showHistoryMenu = $$v
              },
              expression: "showHistoryMenu"
            }
          },
          [
            _c("history-widget", {
              on: {
                hideHistoryMenu: function($event) {
                  _vm.showHistoryMenu = false
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _vm.currentTimer && _vm.clientProjectMenuLabel
      ? _c(
          "div",
          {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { content: _vm.clientProjectMenuLabel },
                expression: "{ content: clientProjectMenuLabel }"
              }
            ],
            staticClass: "pointer font-10 truncate",
            staticStyle: {
              "text-align": "center",
              "margin-left": "2px",
              "margin-top": "0px",
              "max-width": "110px",
              width: "110px"
            },
            on: {
              click: function($event) {
                _vm.showProjectMenu = true
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.clientProjectMenuLabel) + " ")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }