import TemplatesMain from '@/modules/templates/TemplatesMain';
import TemplateRouter from "@/modules/templates/TemplateRouter";
import InvoiceTemplateList from "@/modules/templates/invoices/InvoiceTemplateList";
import DiscoveryList from "@/modules/discovery/DiscoveryList";

export default [
	{
		path: '/templates',
		name: 'templateRouter',
		component: TemplateRouter,
		title: 'TemplateRouter',
	},

	{
		path: '/agreements/templates',
		name: 'agreements',
		component: TemplatesMain,
		title: 'Agreement Templates',
		props: (route) => ({
			v: 'agreements',
			id: route.query.id,
		}),
	},

	{
		path: '/templates/proposals',
		name: 'proposals',
		component: TemplatesMain,
		title: 'Agreement Templates',
		props: (route) => ({
			v: 'agreements',
			id: route.query.id,
		}),
	},

	{
		path: '/templates/emails',
		name: 'emails',
		component: TemplatesMain,
		title: 'Email Templates',
		props: (route) => ({
			v: 'emails',
			id: route.query.id,
		}),
	},

	{
		path: '/templates/packages',
		name: 'packages',
		component: TemplatesMain,
		title: 'Deliverable Package Templates',
		props: (route) => ({
			v: 'packages',
			id: route.query.id,
		}),
	},
	{
		path: '/templates/invoices',
		name: 'invoices',
		component: InvoiceTemplateList,
		title: 'Invoice Templates',
		props: (route) => ({
			v: 'invoices',
			id: route.query.id,
		}),
	},
	{
		path: '/templates/forms',
		name: 'forms',
		component: DiscoveryList,
		title: 'Forms',
		props: (route) => ({
			v: 'forms',
			id: route.query.id,
		}),
	},
];
