import HttpClient from '@/services/HttpClient';

export default class NotificationService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getNotifications() {
		return this.httpClient.get('/notifications')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteNotifications() {
		return this.httpClient.delete('/notifications')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteNotification(id) {
		return this.httpClient.delete(`/notifications/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	snoozeNotification(id, snoozeUntil){
		return this.httpClient.post(`/notifications/${id}/snooze?snoozeUntil=${snoozeUntil}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	markAsRead(id){
		return this.httpClient.post(`/notifications/${id}/read`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	markAllAsRead(){
		return this.httpClient.post(`/notifications/read`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	testNotification(type){
		return this.httpClient.put(`/notifications/test?notificationType=${type}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
