var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        {
          staticClass: "panel-modal",
          staticStyle: { width: "600px" },
          attrs: { id: "client-create" }
        },
        [
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.handleClose(true)
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c("div", { staticClass: "brand-medium font-18" }, [
                  _vm._v("Create new client / prospect")
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "super-action ml-1",
                        attrs: { disabled: !_vm.isValid },
                        on: { click: _vm.createClient }
                      },
                      [_vm._v(_vm._s(_vm.$t("global.save")))]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-1",
                        attrs: { text: "" },
                        on: { click: _vm.handleClose }
                      },
                      [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "panel-modal-body show-scrollbar" },
            [
              _c(
                "v-form",
                {
                  ref: "clientCreate",
                  attrs: { id: "clientCreate" },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.isValid,
                    callback: function($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "column-format gap-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "row-format mt-2" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                "nudge-bottom": "32",
                                origin: "top left",
                                transition: "scale-transition",
                                "close-on-content-click": true
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "div",
                                          _vm._g({}, on),
                                          [
                                            _c("client-avatar", {
                                              staticStyle: {
                                                cursor: "pointer",
                                                height: "52px"
                                              },
                                              attrs: {
                                                arrow: _vm.editColor
                                                  ? "up"
                                                  : "down",
                                                client: _vm.client,
                                                large: true,
                                                "disable-click": ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2690859190
                              )
                            },
                            [
                              _c(
                                "div",
                                { attrs: { id: "color-picker-div" } },
                                [
                                  _c("v-color-picker", {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      flat: "",
                                      mode: "hexa",
                                      "show-swatches": "",
                                      "hide-canvas": "",
                                      "hide-mode-switch": "",
                                      "hide-inputs": "",
                                      "swatches-max-height": "200",
                                      swatches: _vm.palette
                                    },
                                    model: {
                                      value: _vm.client.color,
                                      callback: function($$v) {
                                        _vm.$set(_vm.client, "color", $$v)
                                      },
                                      expression: "client.color"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c("v-text-field", {
                            ref: "clientName",
                            attrs: {
                              label: _vm.$t("client.client-name"),
                              placeholder:
                                _vm.$t("client.enter-client-name") +
                                " (required)",
                              autofocus: "",
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": "",
                              rules: [
                                function() {
                                  return !!_vm.client.name.trim() || ""
                                }
                              ]
                            },
                            model: {
                              value: _vm.client.name,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "name", $$v)
                              },
                              expression: "client.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-select", {
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          dense: "",
                          outlined: "",
                          label: "Record type",
                          items: _vm.type,
                          "item-text": "label",
                          "item-value": "value"
                        },
                        model: {
                          value: _vm.client.clientType,
                          callback: function($$v) {
                            _vm.$set(_vm.client, "clientType", $$v)
                          },
                          expression: "client.clientType"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("client.source"),
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": ""
                        },
                        model: {
                          value: _vm.client.leadSource,
                          callback: function($$v) {
                            _vm.$set(_vm.client, "leadSource", $$v)
                          },
                          expression: "client.leadSource"
                        }
                      }),
                      _c("div", { staticClass: "font-16 text-left" }, [
                        _vm._v("Default contact info")
                      ]),
                      _c(
                        "div",
                        { staticClass: "row-format half" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("contact.first-name"),
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": ""
                            },
                            model: {
                              value: _vm.client.contacts[0].firstName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.client.contacts[0],
                                  "firstName",
                                  $$v
                                )
                              },
                              expression: "client.contacts[0].firstName"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("contact.last-name"),
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": ""
                            },
                            model: {
                              value: _vm.client.contacts[0].lastName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.client.contacts[0],
                                  "lastName",
                                  $$v
                                )
                              },
                              expression: "client.contacts[0].lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("contact.email"),
                          "validate-on-blur": "",
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": ""
                        },
                        model: {
                          value: _vm.client.contacts[0].email,
                          callback: function($$v) {
                            _vm.$set(_vm.client.contacts[0], "email", $$v)
                          },
                          expression: "client.contacts[0].email"
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: { width: "100%", position: "relative" }
                        },
                        [
                          _c("vue-tel-input", {
                            staticClass: "dense-phone",
                            attrs: {
                              "valid-characters-only": true,
                              "preferred-countries": _vm.preferredCountries,
                              inputOptions: { placeholder: "Phone" }
                            },
                            on: { input: _vm.phoneUpdated },
                            model: {
                              value: _vm.phone,
                              callback: function($$v) {
                                _vm.phone = $$v
                              },
                              expression: "phone"
                            }
                          }),
                          _c("div", { staticClass: "phone-label" }, [
                            _vm._v("Phone")
                          ])
                        ],
                        1
                      ),
                      _c("div", { staticClass: "font-16 text-left" }, [
                        _vm._v("Business address")
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("contact.address1"),
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": ""
                        },
                        model: {
                          value: _vm.client.address1,
                          callback: function($$v) {
                            _vm.$set(_vm.client, "address1", $$v)
                          },
                          expression: "client.address1"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("contact.address2"),
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": ""
                        },
                        model: {
                          value: _vm.client.address2,
                          callback: function($$v) {
                            _vm.$set(_vm.client, "address2", $$v)
                          },
                          expression: "client.address2"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "row-format half" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("contact.city"),
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": ""
                            },
                            model: {
                              value: _vm.client.city,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "city", $$v)
                              },
                              expression: "client.city"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("contact.locality"),
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": ""
                            },
                            model: {
                              value: _vm.client.locality,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "locality", $$v)
                              },
                              expression: "client.locality"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "row-format half mt-n1" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("contact.postal"),
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": ""
                            },
                            model: {
                              value: _vm.client.postal,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "postal", $$v)
                              },
                              expression: "client.postal"
                            }
                          }),
                          _c("v-autocomplete", {
                            attrs: {
                              label: _vm.$t("contact.country"),
                              autocomplete: "new-password",
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              "persistent-placeholder": "",
                              items: _vm.countries.getCountriesSimple(),
                              "item-value": "code",
                              "item-text": "name"
                            },
                            model: {
                              value: _vm.client.country,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "country", $$v)
                              },
                              expression: "client.country"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "font-16 text-left" }, [
                        _vm._v("General info")
                      ]),
                      _c(
                        "div",
                        { staticClass: "row-format half" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("client.website"),
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": ""
                            },
                            model: {
                              value: _vm.client.website,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "website", $$v)
                              },
                              expression: "client.website"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": "",
                              label: _vm.$t("client.hourly-amount.label"),
                              prefix: _vm.currencyPrefix,
                              min: "0",
                              type: "number"
                            },
                            model: {
                              value: _vm.client.hourlyAmount,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "hourlyAmount", $$v)
                              },
                              expression: "client.hourlyAmount"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": "",
                              label: "Tax ID"
                            },
                            model: {
                              value: _vm.client.taxId,
                              callback: function($$v) {
                                _vm.$set(_vm.client, "taxId", $$v)
                              },
                              expression: "client.taxId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-12 font-gray_70 text-left pl-3 mb-n3"
                        },
                        [_vm._v("Notes")]
                      ),
                      _c("editor", {
                        attrs: {
                          "api-key": _vm.$store.getters.getTinyMceKey,
                          inline: false,
                          init: _vm.mceConfig,
                          spellcheck: true
                        },
                        model: {
                          value: _vm.notes,
                          callback: function($$v) {
                            _vm.notes = $$v
                          },
                          expression: "notes"
                        }
                      }),
                      _vm._l(_vm.clientFields, function(field) {
                        return _c("custom-field", {
                          key: field.id,
                          attrs: {
                            mode: "dense",
                            field: field,
                            value: _vm.getCustomValue(field.id)
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleCustomFieldChange($event)
                            }
                          }
                        })
                      })
                    ],
                    2
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }