var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mt-1",
      staticStyle: { height: "100%" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "row-format",
          staticStyle: { height: "100%", gap: "4px" }
        },
        _vm._l(_vm.deliverablesByStatus, function(status) {
          return _c("div", {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: {
                  placement: "top",
                  arrow: true,
                  arrowType: "round",
                  animation: "fade",
                  delay: 50,
                  boundary: "window"
                },
                expression:
                  "{placement : 'top',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }"
              }
            ],
            key: status.id,
            class: "" + (_vm.rounded ? "status-box-rounded" : "status-box"),
            style:
              "width:" +
              status.percentage +
              "%; background-color: " +
              status.hexColor,
            attrs: {
              content: status.label + " (" + status.deliverableCount + ")"
            }
          })
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }