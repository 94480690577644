var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", [
        _c("div", { staticClass: "row-format align-start" }, [
          _c(
            "div",
            { staticClass: "column-format" },
            [
              _c("div", { staticClass: "font-gray_80 mb-1" }, [
                _vm._v(_vm._s(_vm.$t("connected-apps.zoom.desc")))
              ]),
              _vm.isConnected
                ? _c("connected")
                : _c(
                    "div",
                    {
                      staticClass: "font-primary brand-medium pointer",
                      on: { click: _vm.connectZoomAccount }
                    },
                    [_vm._v("Connect Zoom")]
                  )
            ],
            1
          ),
          _vm.isConnected
            ? _c(
                "div",
                {
                  staticClass: "ml-auto pointer",
                  on: {
                    click: function($event) {
                      _vm.expanded = !_vm.expanded
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.isConnected && _vm.expanded
          ? _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c("div", { staticClass: "mb-6 font-14" }, [
                  _c("span", { staticClass: "brand-medium" }, [
                    _vm._v("Zoom Account:")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.zoomAuth.zoomUser.first_name) +
                      " " +
                      _vm._s(_vm.zoomAuth.zoomUser.last_name)
                  ),
                  _c("br"),
                  _c("span", { staticClass: "brand-medium" }, [
                    _vm._v("Zoom User Email:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.zoomAuth.zoomUser.email) + " ")
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action mr-1",
                    on: { click: _vm.confirmDisconnect }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("$delete")]),
                    _vm._v("Disconnect")
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }