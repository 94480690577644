import HttpClient from '@/services/HttpClient';

export default class AddOnService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getAddOnProducts() {
		return this.httpClient
			.get(`/subscription/add-ons/products`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getAccountAddOns() {
		return this.httpClient
			.get(`/subscription/add-ons`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createCheckoutSession(productId) {
		return this.httpClient
			.post(`/subscription/add-ons?productId=${productId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createPortalSession() {
		return this.httpClient
			.post(`/subscription/add-ons/portal`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	cancelAllPlans(){
		return this.httpClient
			.post(`/subscription/add-ons/cancel`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
