var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "var(--v-white-base)" } },
    [
      _c("v-data-table", {
        staticClass: "pointer",
        attrs: {
          headers: _vm.headers,
          "item-key": "id",
          "hide-default-footer": true,
          "disable-pagination": true,
          items: _vm.paymentPlans
        },
        on: {
          "click:row": function($event) {
            return _vm.openPaymentPlan($event.id)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.amount",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$formatters.dollars(
                          item.invoice.total,
                          true,
                          true,
                          item.invoice.currency
                        )
                      ) +
                      " "
                  )
                ])
              ]
            }
          },
          {
            key: "item.invoiced",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$formatters.dollars(
                        item.scheduledPayments
                          .filter(function(p) {
                            return p.mileStoneAchieved
                          })
                          .map(function(p) {
                            return p.calculatedAmount
                          })
                          .reduce(function(sum, val) {
                            return sum + val
                          }, 0),
                        true,
                        true,
                        item.invoice.currency
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(item.status) + " ")]
            }
          },
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(item.name ? item.name : "--") + " ")]
            }
          },
          {
            key: "item.lastEvent",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(_vm.formatLastEvent(item)) + " ")]
            }
          },
          {
            key: "item.nextStep",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.getNextScheduledPayment(item.scheduledPayments))
                  )
                ])
              ]
            }
          },
          {
            key: "item.client",
            fn: function(ref) {
              var item = ref.item
              return [
                item.invoice.clientInfo
                  ? _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        _c("client-avatar", {
                          attrs: { client: item.invoice.clientInfo, small: "" }
                        }),
                        _c("div", { staticClass: "ml-n3" }, [
                          _vm._v(_vm._s(item.invoice.clientInfo.name))
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }