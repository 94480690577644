var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", { staticStyle: { width: "900px", "max-width": "900px" } }, [
        _c("div", { staticClass: "modal-padding" }, [
          _c(
            "div",
            { staticClass: "row-format mb-2 align-center" },
            [
              _vm.scheduledMeeting
                ? _c("div", { staticClass: "brand-medium font-18" }, [
                    _vm._v("Reschedule meeting")
                  ])
                : _c("div", { staticClass: "brand-medium font-18" }, [
                    _vm._v("Schedule new meeting")
                  ]),
              _c(
                "v-icon",
                {
                  staticClass: "ml-auto pointer",
                  attrs: { size: "20" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v("$close")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "row-format gap-4" }, [
            _c("div", { staticStyle: { "min-width": "350px" } }, [
              _vm.scheduledMeeting
                ? _c(
                    "div",
                    {
                      staticClass:
                        "brand-box pa-2 text-left font-14 column-format gap-2"
                    },
                    [
                      _c("div", [
                        _c("span", { staticClass: "font-gray_50 font-12" }, [
                          _vm._v("Name:")
                        ]),
                        _vm._v(" " + _vm._s(_vm.scheduledMeeting.meetingName))
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "truncate",
                          staticStyle: { "max-width": "330px" }
                        },
                        [
                          _c("span", { staticClass: "font-gray_50 font-12" }, [
                            _vm._v("Contact:")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.scheduledMeeting.formData.firstName) +
                              " " +
                              _vm._s(_vm.scheduledMeeting.formData.lastName) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "truncate",
                          staticStyle: { "max-width": "330px" }
                        },
                        [
                          _c("span", { staticClass: "font-gray_50 font-12" }, [
                            _vm._v("Email:")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.scheduledMeeting.formData.email) +
                              " "
                          )
                        ]
                      ),
                      _c("div", [
                        _c("span", { staticClass: "font-gray_50 font-12" }, [
                          _vm._v("Location:")
                        ]),
                        _vm._v(" " + _vm._s(_vm.scheduledMeeting.location.type))
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "font-gray_50 font-12" }, [
                          _vm._v("Duration:")
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DateTime.fromISO(
                                _vm.scheduledMeeting.confirmedTime.end
                              ).diff(
                                _vm.DateTime.fromISO(
                                  _vm.scheduledMeeting.confirmedTime.start
                                ),
                                "minutes"
                              ).minutes
                            ) +
                            " minutes "
                        )
                      ]),
                      _c("div", [
                        _vm.calculatedStartTime
                          ? _c(
                              "span",
                              { staticClass: "font-gray_50 font-12" },
                              [_vm._v("Current:")]
                            )
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DateTime.fromISO(
                                _vm.scheduledMeeting.confirmedTime.start
                              )
                                .setZone(_vm.booking.scheduledTimezone)
                                .toFormat("ccc") +
                                " " +
                                _vm.DateTime.fromISO(
                                  _vm.scheduledMeeting.confirmedTime.start
                                )
                                  .setZone(_vm.booking.scheduledTimezone)
                                  .toLocaleString(_vm.DateTime.DATETIME_MED)
                            ) +
                            " "
                        )
                      ]),
                      _c("div", {
                        staticStyle: {
                          "border-top": "1px solid var(--v-gray_30-base)"
                        }
                      }),
                      _c("div", [
                        _vm.calculatedStartTime
                          ? _c(
                              "span",
                              { staticClass: "font-gray_50 font-12" },
                              [_vm._v("New start:")]
                            )
                          : _vm._e(),
                        _c("span", { staticClass: "brand-medium" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.calculatedStartTime.toLocaleString(
                                  _vm.DateTime.DATETIME_MED_WITH_WEEKDAY
                                )
                              )
                          )
                        ])
                      ]),
                      _c("div", [
                        _vm.calculatedEndTime
                          ? _c(
                              "span",
                              { staticClass: "font-gray_50 font-12" },
                              [_vm._v("New end:")]
                            )
                          : _vm._e(),
                        _c("span", { staticClass: "brand-medium" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.calculatedEndTime.toLocaleString(
                                  _vm.DateTime.DATETIME_MED_WITH_WEEKDAY
                                )
                              )
                          )
                        ])
                      ])
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "brand-box pa-2 text-left font-14 column-format gap-2"
                    },
                    [
                      _c("div", [
                        _c("span", { staticClass: "font-gray_50 font-12" }, [
                          _vm._v("Name:")
                        ]),
                        _vm._v(" " + _vm._s(_vm.scheduler.name))
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "font-gray_50 font-12" }, [
                          _vm._v("Location:")
                        ]),
                        _vm._v(" " + _vm._s(_vm.scheduler.location.type))
                      ]),
                      _c("div", [
                        _c("span", { staticClass: "font-gray_50 font-12" }, [
                          _vm._v("Duration:")
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.scheduler.meetingLength.duration) +
                            " " +
                            _vm._s(
                              _vm.scheduler.meetingLength.timeUnit.toLowerCase()
                            ) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _vm.calculatedStartTime
                          ? _c(
                              "span",
                              { staticClass: "font-gray_50 font-12" },
                              [_vm._v("Start:")]
                            )
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.calculatedStartTime.toLocaleString(
                                _vm.DateTime.DATETIME_MED_WITH_WEEKDAY
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _vm.calculatedEndTime
                          ? _c(
                              "span",
                              { staticClass: "font-gray_50 font-12" },
                              [_vm._v("End:")]
                            )
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.calculatedEndTime.toLocaleString(
                                _vm.DateTime.DATETIME_MED_WITH_WEEKDAY
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "truncate",
                          staticStyle: { "max-width": "330px" }
                        },
                        [
                          _c("span", { staticClass: "font-gray_50 font-12" }, [
                            _vm._v("Contact:")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.contact.firstName) +
                              " " +
                              _vm._s(_vm.contact.lastName) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "truncate",
                          staticStyle: { "max-width": "330px" }
                        },
                        [
                          _c("span", { staticClass: "font-gray_50 font-12" }, [
                            _vm._v("Email:")
                          ]),
                          _vm._v(" " + _vm._s(_vm.contact.email) + " ")
                        ]
                      ),
                      _c("div", [
                        _vm.contact.phone
                          ? _c(
                              "span",
                              { staticClass: "font-gray_50 font-12" },
                              [_vm._v("Phone:")]
                            )
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.contact.phone))
                      ])
                    ]
                  )
            ]),
            _c("div", { staticClass: "row-format flex-grow-1" }, [
              _c(
                "div",
                {
                  staticClass: "column-format gap-3 flex-grow-1",
                  staticStyle: { height: "fit-content" }
                },
                [
                  !_vm.scheduledMeeting
                    ? _c("v-autocomplete", {
                        attrs: {
                          items: _vm.schedulers,
                          "item-value": "id",
                          "item-text": "name",
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": "",
                          label: "Meeting scheduler"
                        },
                        on: {
                          change: function($event) {
                            return _vm.getAvailableTimes()
                          }
                        },
                        model: {
                          value: _vm.booking.meetingId,
                          callback: function($$v) {
                            _vm.$set(_vm.booking, "meetingId", $$v)
                          },
                          expression: "booking.meetingId"
                        }
                      })
                    : _vm._e(),
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.timezones,
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Timezone"
                    },
                    model: {
                      value: _vm.booking.scheduledTimezone,
                      callback: function($$v) {
                        _vm.$set(_vm.booking, "scheduledTimezone", $$v)
                      },
                      expression: "booking.scheduledTimezone"
                    }
                  }),
                  _c("date-selector", {
                    attrs: {
                      standard: true,
                      label: "Meeting date",
                      date: _vm.meetingDate
                    },
                    on: { change: _vm.meetingDateUpdated }
                  }),
                  !_vm.viewAvailability
                    ? _c(
                        "div",
                        {
                          staticClass: "brand-box row-format meeting-time",
                          staticStyle: { position: "relative", height: "40px" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "font-12 font-gray_60 px-1",
                              staticStyle: {
                                position: "absolute",
                                top: "-10px",
                                left: "8px",
                                "background-color": "var(--v-white-base)"
                              }
                            },
                            [_vm._v(" Meeting time ")]
                          ),
                          _c("vue-timepicker", {
                            attrs: {
                              format: _vm.format,
                              "hide-clear-button": true,
                              "auto-scroll": "",
                              "manual-input": true,
                              "hide-dropdown": true,
                              "drop-direction": "up"
                            },
                            on: { change: _vm.timeUpdated },
                            model: {
                              value: _vm.meetingTime,
                              callback: function($$v) {
                                _vm.meetingTime = $$v
                              },
                              expression: "meetingTime"
                            }
                          }),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-auto material-symbols-rounded",
                              staticStyle: { "padding-right": "10px" }
                            },
                            [_vm._v("schedule")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-switch", {
                    staticClass: "pa-0 ma-0",
                    attrs: {
                      label: "View availability",
                      "hide-details": "",
                      dense: ""
                    },
                    on: { change: _vm.resetMeetingTime },
                    model: {
                      value: _vm.viewAvailability,
                      callback: function($$v) {
                        _vm.viewAvailability = $$v
                      },
                      expression: "viewAvailability"
                    }
                  }),
                  _vm.viewAvailability
                    ? _c(
                        "div",
                        { staticClass: "row-format gap-2 flex-wrap" },
                        [
                          _vm._l(_vm.slotsForDay, function(slot) {
                            return _c(
                              "v-chip",
                              {
                                key: slot.start,
                                staticClass: "pointer",
                                attrs: {
                                  color: slot.localTime.equals(
                                    _vm.calculatedStartTime
                                  )
                                    ? "primary"
                                    : "gray_10"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.selectSlot(slot)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    slot.localTime.toLocaleString(
                                      _vm.DateTime.TIME_SIMPLE
                                    )
                                  )
                                )
                              ]
                            )
                          }),
                          _vm.slotsForDay.length === 0
                            ? _c("div", [
                                _vm._v("No availability for the date selected")
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "modal-footer gap-2 row-format centered" },
          [
            _c(
              "v-btn",
              {
                staticClass: "super-action",
                staticStyle: { width: "160px" },
                on: {
                  click: function($event) {
                    _vm.scheduledMeeting
                      ? _vm.rescheduleMeeting()
                      : _vm.bookMeeting()
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.scheduledMeeting ? "Reschedule" : "Schedule") +
                    " meeting "
                )
              ]
            ),
            _c(
              "v-btn",
              {
                staticClass: "primary-action",
                on: { click: _vm.handleCancel }
              },
              [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }