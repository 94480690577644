var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "290px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g(
                      {
                        class: "row-format centered scope-button",
                        staticStyle: {
                          "min-width": "70px",
                          "background-color": "var(--v-white-base)"
                        }
                      },
                      on
                    ),
                    [_c("div", {}, [_vm._v(_vm._s(_vm.dateFormatted))])]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dateMenu,
            callback: function($$v) {
              _vm.dateMenu = $$v
            },
            expression: "dateMenu"
          }
        },
        [
          _c(
            "v-date-picker",
            {
              attrs: {
                "first-day-of-week": _vm.$store.getters.firstDayOfWeek,
                "no-title": "",
                scrollable: ""
              },
              on: { change: _vm.dateUpdated },
              model: {
                value: _vm.localDate,
                callback: function($$v) {
                  _vm.localDate = $$v
                },
                expression: "localDate"
              }
            },
            [
              _c("template", { slot: "default" }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format centered",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "input-wrapper font-14 font-primary py-1 px-2 pointer",
                        on: {
                          click: function($event) {
                            _vm.localDate = null
                            _vm.dateUpdated()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.clear")) + " ")]
                    )
                  ]
                )
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }