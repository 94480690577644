<template>
	<div class="column-format centered" style="height:100%">
		<div class="row-format" style="width:100%">
			<div class="ml-auto" style="width:200px">
				<v-select :items="items" v-model="view" dense hide-details outlined style="background-color: var(--v-white-base)"></v-select>
			</div>
		</div>
		<div>
			<bar :chart-id="barRefreshKey" datasetIdKey="insight-money-bar" :key="barRefreshKey" v-if="view === 'Trend'" :chart-options="barChartOptions" :chart-data="barChart" :width="450" :height="300" />
			<pie :chart-id="pieRefreshKey" datasetIdKey="insight-lead-pie" :key="pieRefreshKey" v-else-if="view === 'By lead source'" :chart-options="pieChartOptions" :chart-data="pieChartData" :width="450" :height="300" />
			<pie :chart-id="pieRefreshKey" datasetIdKey="insight-money-pie" :key="pieRefreshKey" v-else :chart-options="pieChartOptions" :chart-data="pieChartData" :width="450" :height="300" />
		</div>
	</div>
</template>

<script>
	import { Pie, Bar } from 'vue-chartjs/legacy';
	import DateTime from "@/modules/utils/HDateTime";
	import {v4 as uuidv4} from 'uuid';
	import ClientPalette from "@/modules/clients/ClientPalette";


	export default {
		name: 'MoneyChart',

		props: {
			scope: {
				type: String
			},
			earliest: {
				type: Object
			},
			latest: {
				type: Object
			},
			clients: {
				type: Array,
			},
			invoices: {
				type: Array,
			},
			otherIncome: {
				type: Array
			}
		},

		components: { Pie, Bar },

		data: function () {
			return {
				clientPalette: new ClientPalette(),
				view: 'Trend',
				barRefreshKey: uuidv4(),
				pieRefreshKey: uuidv4(),
				items: ['By client','By lead source','Trend'],

				pieChartOptions: {
					responsive: false,
					maintainAspectRatio: false,
				},

				barChartOptions: {
					responsive: false,
					maintainAspectRatio: false,
					scales: {
						x: {
							stacked: true,
							grid: {
								display: false,
							},
							ticks: {
								autoSkip: true,
								maxTicksLimit: 6,
								maxRotation: 0,
								minRotation: 0,
							}
						},
						y: {
							stacked: true
						}
					}
				}

			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			getLeadSource: function(clientId){
				if(!clientId){
					return "Unknown";
				}else{
					let leadSource = this.leadSourceMap.get(clientId);
					return (leadSource ? leadSource : "Unknown");
				}
			}
		},

		computed: {
			leadSourceMap: function(){
				let result = new Map();
				this.$store.state.clientCache.forEach(c => {
					result.set(c.id,c.leadSource ? c.leadSource : 'Unknown');
				})
				return result;
			},

			adjustedClients: function(){
				let zeroClient = {
					id: "0",
					name: "N/A",
					color: "#eeeeee"
				};

				let result = [... this.clients];
				result.push(zeroClient);
				return result;
			},

			invoicedByClient: function () {
				let income = [... this.otherIncome];

				income.forEach(i => {
					if(!i.clientId){
						i.clientId = "0";
					}
				})

				let grouped = this.$formatters.groupBy(this.invoices, 'clientId');
				let groupedIncome = this.$formatters.groupBy(income,'clientId');
				let result = [];

				for (let g in grouped) {
					let group = grouped[g];
					let clientTotal = group.reduce((sum, invoice) => sum + invoice.localTotal, 0);
					let client = this.adjustedClients.find((c) => c.id === g);

					if (!client) {
						client = group[0].clientInfo;
					}

					result.push({
						clientId: g,
						clientName: client.name,
						color: client.color,
						total: parseFloat(clientTotal.toFixed(2))
					});
				}

				for(let g in groupedIncome){
					let group = groupedIncome[g];
					let clientTotal = group.reduce((sum, income) => sum + income.localAmount, 0);
					let client = this.adjustedClients.find((c) => c.id === g);

					if (!client) {
						client = group[0].clientInfo;
					}

					let ix = result.findIndex(r => r.clientId === g);
					if(ix > -1){
						result[ix].total = result[ix].total + clientTotal;
					}else{
						result.push({
							clientId: g,
							clientName: client.name,
							color: client.color,
							total: parseFloat(clientTotal.toFixed(2))
						});
					}
				}


				result.sort((a, b) => a.total - b.total);
				return result;
			},

			invoicedByLeadSource: function () {
				let income = [... this.otherIncome];
				let invoices = [... this.invoices];

				income.forEach(i => {
					i.leadSource = this.getLeadSource(i.clientId)
				})

				invoices.forEach(i => {
					i.leadSource = this.getLeadSource(i.clientId);
				})

				let grouped = this.$formatters.groupBy(this.invoices, 'leadSource');
				let groupedIncome = this.$formatters.groupBy(income,'leadSource');
				let result = [];

				for (let g in grouped) {
					let group = grouped[g];
					let groupTotal = group.reduce((sum, invoice) => sum + invoice.localTotal, 0);

					result.push({
						leadSource: g,
						clientName: g,
						color: this.clientPalette.getRandomColor([]),
						total: parseFloat(groupTotal.toFixed(2))
					});
				}

				for(let g in groupedIncome){
					let group = groupedIncome[g];
					let groupTotal = group.reduce((sum, income) => sum + income.localAmount, 0);

					let ix = result.findIndex(r => r.leadSource === g);
					if(ix > -1){
						result[ix].total = result[ix].total + groupTotal;
					}else{
						result.push({
							leadSource: g,
							clientName: g,
							color: this.clientPalette.getRandomColor([]),
							total: parseFloat(groupTotal.toFixed(2))
						});
					}
				}

				result.sort((a, b) => a.total - b.total);
				return result;
			},

			barChart: function(){
				let result = {
					id: 'd7d33cdb-cbd8-47ad-8d6c-8c211a46237e',
					labels: this.barChartLabels,
					datasets: this.barChartData
				}
				return result;
			},

			barChartLabels: function(){
				let start = this.earliest.endOf(this.scope);
				let end = this.latest.endOf(this.scope);
				let labels = [];

				let increment = {}
				increment[this.scope + 's'] = 1;

				while(start <= end) {
					labels.push(this.scope === 'month' ? start.toFormat('MMM yy') : start.toFormat('MMM dd'));
					start = start.plus(increment).endOf(this.scope);
				}
				return labels;
			},

			barChartData: function(){
				let clients = [];

				let increment = {}
				increment[this.scope + 's'] = 1;

				let clientsInPeriod = new Set(this.invoices.map(i => i.clientId));
				this.otherIncome.map(i => i.clientId ? i.clientId : "0").forEach(i => clientsInPeriod.add(i));

				clientsInPeriod.forEach((clientId) => {
					let c = this.adjustedClients.find((c) => c.id === clientId);
					let start = this.earliest.endOf(this.scope);
					let end = this.latest.endOf(this.scope);
					let totals = [];

					let client = {
						clientId: clientId,
						data: [],
						label: c ? c.name : '[Deleted]',
						backgroundColor: c ? c.color : '#eee'
					};

					while(start <= end) {
						totals.push({
							key: start.toISODate(),
							total: 0,
						})
						start = start.plus(increment).endOf(this.scope);
					}

					this.invoices.filter(i => i.clientId === clientId).forEach(i => {
						let dateSent = DateTime.fromISO(i.dateSent).endOf(this.scope);
						let total = totals.find(k => k.key === dateSent.toISODate());
						if(total) {
							total.total += i.localTotal;
						}
					});

					this.otherIncome.filter(i => i.clientId === clientId).forEach(i => {
						let dateSent = DateTime.fromISO(i.date).endOf(this.scope);
						let total = totals.find(k => k.key === dateSent.toISODate());
						if(total) {
							total.total += i.localAmount;
						}
					});

					client.data = totals.map(t => t.total);
					clients.push(client);
				});

				return clients;
			},

			pieChartData: function () {
				let byClient;
				if(this.view === 'By lead source'){
					byClient = this.invoicedByLeadSource;
				}else {
					byClient = this.invoicedByClient;
				}

				let result =  {
					id: '289a31ff-6d0f-47fe-9a57-bd0d4eabb59c',
					labels: byClient.map((b) => b.clientName),
					datasets: [
						{
							label: 'Invoiced',
							data: byClient.map((b) => b.total),
							backgroundColor: byClient.map((b) => b.color),
						},
					],
				};

				return result;
			},
		},

		watch: {
			barChart: function(){
				this.barRefreshKey = uuidv4();
			},

			pieChartData: function(){
				this.pieRefreshKey = uuidv4();
			}
		}
	};
</script>

<style scoped lang="scss"></style>
