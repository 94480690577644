var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.proposal_id,
            expression: "proposal_id"
          }
        ],
        ref: "proposal_id",
        attrs: { type: "hidden", "df-proposal_id": "" },
        domProps: { value: _vm.proposal_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.proposal_id = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.proposal_name,
            expression: "proposal_name"
          }
        ],
        ref: "proposal_name",
        attrs: { type: "hidden", "df-proposal_name": "" },
        domProps: { value: _vm.proposal_name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.proposal_name = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.proposal_version,
            expression: "proposal_version"
          }
        ],
        ref: "proposal_version",
        attrs: { type: "hidden", "df-proposal_version": "" },
        domProps: { value: _vm.proposal_version },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.proposal_version = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.template_id,
            expression: "template_id"
          }
        ],
        ref: "template_id",
        attrs: { type: "hidden", "df-template_id": "" },
        domProps: { value: _vm.template_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.template_id = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.to_list,
            expression: "to_list"
          }
        ],
        ref: "to_list",
        attrs: { type: "hidden", "df-to_list": "" },
        domProps: { value: _vm.to_list },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.to_list = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.cc_list,
            expression: "cc_list"
          }
        ],
        ref: "cc_list",
        attrs: { type: "hidden", "df-cc_list": "" },
        domProps: { value: _vm.cc_list },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.cc_list = $event.target.value
          }
        }
      }),
      _c("node-label", {
        on: {
          open: function($event) {
            return _vm.openModal()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded mr-1",
                    attrs: { color: "black", size: "20" }
                  },
                  [_vm._v("handshake")]
                ),
                _vm.proposal_name
                  ? _c("div", [_vm._v(_vm._s(_vm.proposal_name))])
                  : _c("div", [_vm._v("Create agreement")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("node-modal", {
        ref: "nodeModal",
        attrs: { title: "Send email" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.isOpen
                  ? _c(
                      "div",
                      {
                        staticClass: "column-format gap-2",
                        staticStyle: { "min-width": "550px" }
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            "hide-details": "",
                            dense: "",
                            outlined: "",
                            "persistent-placeholder": "",
                            items: _vm.allTemplates,
                            "item-text": "name",
                            "item-value": "id",
                            label: "Agreement template"
                          },
                          model: {
                            value: _vm.proposal_id,
                            callback: function($$v) {
                              _vm.proposal_id = $$v
                            },
                            expression: "proposal_id"
                          }
                        }),
                        _vm.selectedTemplate && !_vm.selectedTemplate.userSigned
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "font-red font-12 text-left font-weight-bold",
                                staticStyle: { "max-width": "500px" }
                              },
                              [
                                _vm._v(
                                  " Warning! The agreement template you have selected has NOT been signed by " +
                                    _vm._s(
                                      _vm.$store.getters.getAccount.accountName
                                    ) +
                                    ". Attempting to finalize the agreement in this workflow will fail. Please pre-sign the template if you wish to utilize it in this workflow. "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("email-selector", {
                          attrs: {
                            to_list: _vm.toList,
                            cc_list: _vm.ccList,
                            template_id: _vm.template_id
                          },
                          on: {
                            "cc-list": function($event) {
                              return _vm.updateCcList($event)
                            },
                            "to-list": function($event) {
                              return _vm.updateToList($event)
                            },
                            "selected-template": function($event) {
                              return _vm.updateSelectedTemplate($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }