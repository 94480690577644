var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "row-format align-center ml-1 " +
        (_vm.view === "Two-Week" ? "mt-1" : "")
    },
    [
      _c("client-avatar", {
        staticStyle: { transform: "scale(.92)" },
        attrs: { "disable-click": "", "x-small": "", client: _vm.client }
      }),
      _c(
        "div",
        {
          class:
            "ml-1 row-format align-center " +
            (_vm.isDarkBackground || _vm.isDarkMode
              ? "font-white"
              : "font-gray_90"),
          staticStyle: { width: "100%" }
        },
        [
          _c("div", { staticClass: "brand-medium" }, [
            _vm._v(_vm._s(_vm.project.name))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }