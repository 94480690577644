var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          staticStyle: { "overflow-y": "auto" },
          attrs: {
            "max-height": "90vh",
            "max-width": "250",
            "close-on-content-click": false,
            "close-on-click": true,
            id: "channel-filter",
            "content-class": "filter-menu-class"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: {
                          "min-height": "40px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          elevation: "0",
                          outlined: "",
                          color: "gray_30"
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (_vm.filter.assignedTo.length
                              ? "font-secondary"
                              : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filterLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.filter.assignedTo.length
                              ? "secondary"
                              : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c("div", { staticClass: "column-format open-menu" }, [
            _c(
              "div",
              { staticClass: "filter-option-box" },
              _vm._l(_vm.userOptions, function(user) {
                return _c("v-checkbox", {
                  key: user.userId,
                  attrs: { value: user.userId, "hide-details": "", dense: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            user.userId !== "unassigned"
                              ? _c("assigned-user", {
                                  attrs: { "assigned-to": user.userId }
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.$t("projects.unassigned")))
                                ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.filter.assignedTo,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "assignedTo", $$v)
                    },
                    expression: "filter.assignedTo"
                  }
                })
              }),
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }