var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "px-4 pb-3 pt-2",
      attrs: { id: "client-project-select-menu" },
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _vm.isReady
        ? _c(
            "div",
            { staticClass: "column-format gap-4" },
            [
              _c("div", { staticClass: "timer-container gap-4" }, [
                _c("div", { staticClass: "inputs column-format" }, [
                  _c("div", { staticClass: "font-14 font-gray_70" }, [
                    _vm._v("Started")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.timerStart,
                        expression: "timerStart"
                      }
                    ],
                    ref: "timerStart",
                    staticClass: "timerStart",
                    staticStyle: { color: "var(--v-black-base)" },
                    attrs: { id: "timerStart" },
                    domProps: { value: _vm.timerStart },
                    on: {
                      focus: function($event) {
                        return _vm.setPauseTimer(true)
                      },
                      blur: function($event) {
                        return _vm.handleTimerStartBlur(false)
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleTimerStartBlur(true)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.timerStart = $event.target.value
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "inputs column-format" }, [
                  _c("div", { staticClass: "font-14 font-gray_70" }, [
                    _vm._v("Duration")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.runningTimer,
                        expression: "runningTimer"
                      }
                    ],
                    ref: "runningTimer",
                    class: _vm.running ? " running" : "",
                    staticStyle: { color: "var(--v-black-base)" },
                    attrs: { id: "timer-display" },
                    domProps: { value: _vm.runningTimer },
                    on: {
                      focus: function($event) {
                        return _vm.setPauseTimer(true)
                      },
                      blur: function($event) {
                        return _vm.handleRunningTimerBlur(false)
                      },
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleRunningTimerBlur(true)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.runningTimer = $event.target.value
                      }
                    }
                  })
                ])
              ]),
              _vm.currentTimerClone.pausedSeconds
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-left font-12 font-gray_70 row-format align-center pointer",
                      on: { click: _vm.confirmClearPause }
                    },
                    [
                      _c("div", { staticClass: "ml-auto" }, [
                        _vm._v("Paused for: " + _vm._s(_vm.pausedTimeFormatted))
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "16" }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showClient
                ? _c("select-client", {
                    attrs: {
                      "hide-archive": true,
                      "prepend-icon": "group",
                      label: "Client",
                      placeholder: _vm.$t("timetrack.current.choose-client")
                    },
                    on: {
                      input: function($event) {
                        return _vm.update("client")
                      }
                    },
                    model: {
                      value: _vm.currentTimerClone.clientId,
                      callback: function($$v) {
                        _vm.$set(_vm.currentTimerClone, "clientId", $$v)
                      },
                      expression: "currentTimerClone.clientId"
                    }
                  })
                : _vm._e(),
              _vm.showClientProjectSelect
                ? _c(
                    "div",
                    { attrs: { id: "project-autocomplete" } },
                    [
                      _vm.showProject
                        ? _c("v-autocomplete", {
                            attrs: {
                              "prepend-inner-icon": "folder_special",
                              items: _vm.projectItems,
                              label: "Project",
                              placeholder: _vm.$t(
                                "timetrack.current.choose-project"
                              ),
                              "no-data-text": _vm.$t(
                                "timetrack.current.no-project-data-available"
                              ),
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              "auto-select-first": ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.update("project")
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(data) {
                                    return [
                                      _c("div", {
                                        staticClass: "autocomplete-custom-item",
                                        domProps: {
                                          innerHTML: _vm._s(data.item.text)
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              63777000
                            ),
                            model: {
                              value: _vm.currentTimerClone.projectId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentTimerClone,
                                  "projectId",
                                  $$v
                                )
                              },
                              expression: "currentTimerClone.projectId"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showClientProjectSelect
                ? _c(
                    "div",
                    { attrs: { id: "deliverable-autocomplete" } },
                    [
                      _vm.showDeliverable
                        ? _c("v-autocomplete", {
                            attrs: {
                              items: _vm.deliverableItems,
                              "prepend-inner-icon": "splitscreen",
                              label: "Task",
                              placeholder: _vm.$t(
                                "timetrack.current.choose-deliverable"
                              ),
                              "no-data-text": _vm.$t(
                                "timetrack.current.no-deliverable-data-available"
                              ),
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              "auto-select-first": ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.update("deliverable")
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(data) {
                                    return [
                                      _c("div", {
                                        staticClass: "autocomplete-custom-item",
                                        domProps: {
                                          innerHTML: _vm._s(data.item.text)
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              63777000
                            ),
                            model: {
                              value: _vm.currentTimerClone.deliverableId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.currentTimerClone,
                                  "deliverableId",
                                  $$v
                                )
                              },
                              expression: "currentTimerClone.deliverableId"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showTicketSelect
                ? _c(
                    "div",
                    { attrs: { id: "project-autocomplete" } },
                    [
                      _vm.showProject
                        ? _c("v-autocomplete", {
                            attrs: {
                              "prepend-inner-icon": "local_activity",
                              items: _vm.ticketItems,
                              label: "Ticket",
                              placeholder: _vm.$t(
                                "timetrack.current.choose-ticket"
                              ),
                              "no-data-text": _vm.$t(
                                "timetrack.current.no-ticket-data-available"
                              ),
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              "auto-select-first": ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.update("ticket")
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(data) {
                                    return [
                                      _c("div", {
                                        staticClass: "autocomplete-custom-item",
                                        domProps: {
                                          innerHTML: _vm._s(data.item.text)
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              63777000
                            ),
                            model: {
                              value: _vm.currentTimerClone.ticketId,
                              callback: function($$v) {
                                _vm.$set(_vm.currentTimerClone, "ticketId", $$v)
                              },
                              expression: "currentTimerClone.ticketId"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-textarea", {
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  rows: 1,
                  "auto-grow": true,
                  "prepend-inner-icon": "comment",
                  dense: "",
                  outlined: "",
                  label: _vm.$t("timetrack.current.notes-label"),
                  placeholder: _vm.$t("timetrack.current.notes-placeholder")
                },
                on: {
                  change: function($event) {
                    return _vm.update()
                  }
                },
                model: {
                  value: _vm.currentTimerClone.notes,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTimerClone, "notes", $$v)
                  },
                  expression: "currentTimerClone.notes"
                }
              }),
              _c("v-switch", {
                staticClass: "mt-0",
                attrs: {
                  size: "small",
                  "hide-details": "",
                  dense: "",
                  label: "Billable",
                  color: "primary"
                },
                model: {
                  value: _vm.currentTimerClone.billable,
                  callback: function($$v) {
                    _vm.$set(_vm.currentTimerClone, "billable", $$v)
                  },
                  expression: "currentTimerClone.billable"
                }
              }),
              _c(
                "div",
                { staticClass: "row-format centered gap-1" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "super-action",
                      staticStyle: { flex: "1 0 auto" },
                      on: {
                        click: function($event) {
                          return _vm.handleSave()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.handleDelete } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "20" }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.deleteConfirm,
          icon: "$alert",
          "heading-text": "Delete?",
          "body-text": "Are you sure you want to delete the running timer?"
        },
        on: {
          confirm: function($event) {
            return _vm.handleDelete()
          },
          close: function($event) {
            _vm.deleteConfirm = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }