import { render, staticRenderFns } from "./MeetingDetail.vue?vue&type=template&id=07550594&scoped=true&"
import script from "./MeetingDetail.vue?vue&type=script&lang=js&"
export * from "./MeetingDetail.vue?vue&type=script&lang=js&"
import style0 from "./MeetingDetail.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./MeetingDetail.vue?vue&type=style&index=1&id=07550594&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07550594",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VRow,VSwitch,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2614485219/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07550594')) {
      api.createRecord('07550594', component.options)
    } else {
      api.reload('07550594', component.options)
    }
    module.hot.accept("./MeetingDetail.vue?vue&type=template&id=07550594&scoped=true&", function () {
      api.rerender('07550594', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/meetings/MeetingDetail.vue"
export default component.exports