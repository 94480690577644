<template>
	<div style="width:350px">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding text-left" v-if="isReady">
			<h3 class="modal-title">Select meeting template</h3>
			<ul>
				<li v-for="meeting in meetingsList" :key="meeting.id" @click="selectMeeting(meeting)" class="pointer">
					{{meeting.name}}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import AccountService from "@/modules/account/AccountService";
import MeetingService from "@/modules/meetings/MeetingService";

export default {
	name: "SchedulerSelector",

	props: [],

	components: {},

	data: function () {
		return {
			meetingService: new MeetingService(),
			meetings: [],
			baseUrl: process.env.VUE_APP_MEET_LINK,
			uniqueAccountUrl: null,
			isReady: false
		}
	},

	async mounted() {
		await this.getUniqueUrl();
		await this.getMeetingList();
		this.isReady = true;
	},

	beforeDestroy() {
	},

	methods: {
		async getMeetingList(){
			let res = await this.meetingService.getMeetingList();
			this.meetings.splice(0,this.meetings.length);
			this.meetings.push(... res.data);
		},

		async getUniqueUrl() {
			let accountService = new AccountService();
			let res = await accountService.getAccountUniqueUrl();
			this.uniqueAccountUrl = res.data;
		},

		fullMeetingLink: function (meeting) {
			let commPref = this.$store.state.communicationPreferences;
			if(commPref && commPref.primaryDomain){
				return 'https://' + commPref.primaryDomain + '/public/' + this.uniqueAccountUrl + '/' + meeting.uniqueMeetingId;
			}else {
				return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + meeting.uniqueMeetingId;
			}
		},

		selectMeeting(meeting){
			this.$emit('result',meeting);
		},

		handleClose(){
			this.$emit('result');
		}
	},

	computed: {
		meetingsList: function(){
			let result = [];
			this.meetings.forEach(m => {
				result.push({
					id: m.id,
					name: m.name,
					link: this.fullMeetingLink(m)
				});
			});
			result.sort((a,b) => a.name.localeCompare(b.name));
			return result;
		}
	},

}
</script>

<style scoped lang="scss">

</style>