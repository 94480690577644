var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", { staticClass: "column-format font-14" }, [
        _c(
          "div",
          {
            staticClass: "pointer column-format",
            on: { click: _vm.toggleEmailContent }
          },
          [
            _c(
              "div",
              { staticClass: "row-format brand-medium pointer" },
              [
                _c("div", { staticClass: "text-truncate font-gray_90" }, [
                  _vm._v(" " + _vm._s(_vm.from) + " "),
                  _vm.showEmailDetail
                    ? _c("span", { staticClass: "font-12 font-gray_70" }, [
                        _vm._v("<" + _vm._s(_vm.email.from.address) + ">")
                      ])
                    : _vm._e()
                ]),
                _vm.email.sentByUserId &&
                _vm.email.sentByUserId !== _vm.$store.getters.getLoggedInUserId
                  ? _c("assigned-user", {
                      staticClass: "ml-2",
                      attrs: {
                        "assigned-to": _vm.email.sentByUserId,
                        "show-name": false
                      }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "ml-auto row-format" }, [
                  _c("div", { staticClass: "nowrap text-truncate font-14" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.received.toLocaleString(_vm.DateTime.DATETIME_MED)
                        ) +
                        " "
                    ),
                    _vm.timeAgo
                      ? _c("span", [_vm._v("(" + _vm._s(_vm.timeAgo) + ")")])
                      : _vm._e()
                  ]),
                  _vm.showEmailDetail
                    ? _c(
                        "div",
                        { staticClass: "ml-2 nowrap" },
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { content: "Reply" },
                                  expression: "{ content: 'Reply' }"
                                }
                              ],
                              staticClass: "mt-n1",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.setupReply.apply(null, arguments)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("reply")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { content: "Reply all" },
                                  expression: "{ content: 'Reply all' }"
                                }
                              ],
                              staticClass: "mt-n1",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.setupReplyAll.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("reply_all")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { content: "Forward" },
                                  expression: "{ content: 'Forward' }"
                                }
                              ],
                              staticClass: "mt-n1",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.setupForward.apply(null, arguments)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("forward")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            ),
            !_vm.showEmailDetail
              ? _c("div", { staticClass: "mt-1 row-format align-center" }, [
                  _vm.email.unread
                    ? _c("div", { staticClass: "unread mr-1" })
                    : _vm._e(),
                  _c("div", { staticClass: "text-truncate nowrap font-14" }, [
                    _vm._v(_vm._s(_vm.email.preview))
                  ])
                ])
              : _vm._e()
          ]
        ),
        _vm.showEmailDetail && _vm.emailDetail
          ? _c("div", [
              _c("div", { staticClass: "row-format" }, [
                _c("div", [
                  _vm.to.length
                    ? _c("div", { staticClass: "font-12" }, [
                        _vm._v("To: " + _vm._s(_vm.to.join(", ")))
                      ])
                    : _vm._e(),
                  _vm.cc.length
                    ? _c("div", { staticClass: "font-12" }, [
                        _vm._v("CC: " + _vm._s(_vm.cc.join(", ")))
                      ])
                    : _vm._e()
                ]),
                _vm.email.openedCount > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "pointer ml-auto font-12 font-gray_70 mr-2"
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tippy",
                                rawName: "v-tippy",
                                value: {
                                  content:
                                    "First opened: " +
                                    _vm.DateTime.fromISO(
                                      _vm.email.firstOpened
                                    ).toLocaleString(_vm.DateTime.DATETIME_MED)
                                },
                                expression:
                                  "{content:'First opened: ' + DateTime.fromISO(email.firstOpened).toLocaleString(DateTime.DATETIME_MED)}"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "Opened " + _vm._s(_vm.email.openedCount) + "x"
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "mt-3", attrs: { id: _vm.email.id } },
                [
                  _vm.emailDetail.htmlContent
                    ? _c("div", {
                        key: _vm.refreshKey,
                        domProps: {
                          innerHTML: _vm._s(_vm.emailDetail.htmlContent)
                        }
                      })
                    : _vm.emailDetail.textContent
                    ? _c("div", { staticClass: "text-pre-wrap" }, [
                        _vm._v(_vm._s(_vm.emailDetail.textContent))
                      ])
                    : _c("div", [_c("v-icon", [_vm._v("empty")])], 1),
                  _vm.hasBlockQuote
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: { click: _vm.toggleBlockQuote }
                        },
                        [_c("v-icon", [_vm._v("$moreHorizontal")])],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.emailDetail.attachments && _vm.emailDetail.attachments.length
                ? _c(
                    "div",
                    _vm._l(
                      _vm.emailDetail.attachments.filter(function(a) {
                        return a
                      }),
                      function(attachment, index) {
                        return _c(
                          "div",
                          { key: attachment + index, staticClass: "mb-2" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  outlined: "",
                                  small: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.download(attachment)
                                  }
                                }
                              },
                              [
                                _c("div", [_vm._v(_vm._s(attachment))]),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-1",
                                    attrs: { small: "", color: "primary" }
                                  },
                                  [_vm._v("$download")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm.replyContent
                ? _c(
                    "div",
                    {
                      staticClass: "mt-5 font-14",
                      attrs: { id: "editor-" + _vm.email.id }
                    },
                    [
                      _c("email-editor", {
                        attrs: {
                          "initial-content": _vm.replyContent,
                          "to-list": _vm.replyTo,
                          "cc-list": _vm.replyCC,
                          subject: _vm.forwardSubject,
                          "show-subject": _vm.isForward,
                          "email-boxes": _vm.emailBoxes,
                          "is-reply": true,
                          "opportunity-id": _vm.opportunityId
                        },
                        on: {
                          "send-email": function($event) {
                            return _vm.sendEmail($event)
                          },
                          cancel: function($event) {
                            return _vm.cancelReply()
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }