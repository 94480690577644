var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ai-chat-widget" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.handleClose()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v("✨" + _vm._s(_vm.title))
          ]),
          _c("ai-chat-core", {
            ref: "ChatCore",
            attrs: {
              context: _vm.context,
              "initial-prompt": _vm.initialPrompt,
              "finalization-prompt": _vm.finalizationPrompt,
              "use-case": _vm.useCase,
              model: _vm.model,
              "result-format": _vm.resultFormat,
              temperature: _vm.temperature,
              "top-p": _vm.topP
            },
            on: {
              result: function($event) {
                return _vm.$emit("result", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              attrs: { width: "130" },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [
              _c("span", { staticClass: "font-gray_70" }, [
                _vm._v(_vm._s(_vm.$t("global.cancel")))
              ])
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action ml-1",
              attrs: { width: "130" },
              on: {
                click: function($event) {
                  return _vm.finalize()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.create")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }