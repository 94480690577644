<template>
	<div v-if="emailBox">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h3 class="modal-title">Connected account configuration</h3>
			<div class="row-format">
				<div class="mr-3 column-format">
					<div>
						<v-select
							:disabled="!!emailBox.id"
							@change="providerUpdated()"
							:items="providers"
							item-value="type"
							v-model="emailBox.provider"
							hide-details
							dense
							outlined
							persistent-placeholder
							label="Email provider"
						>
							<template v-slot:selection="{ item }">
								<v-icon>{{ item.icon }}</v-icon>
								<div class="ml-2">{{ item.label }}</div>
							</template>
							<template v-slot:item="{ item }">
								<v-icon>{{ item.icon }}</v-icon>
								<div class="ml-2">{{ item.label }}</div>
							</template>
						</v-select>
						<div v-if="selectedProvider && selectedProvider.oauth" class="my-4">
							<div v-if="emailBox.id" class="column-format h-outline font-14 pa-4" style="gap: 4px">
								<div>
									Account: <span class="brand-medium">{{ emailBox.oauthName }}</span>
								</div>
								<div>
									Email: <span class="brand-medium">{{ emailBox.oauthEmail }}</span>
								</div>
								<div v-if="selectedProvider.oAuthImage" class="column-format centered">
									<div>Reconnect your {{ selectedProvider.name }} account</div>
									<div class="pointer mt-2" @click="reconnectOAuth">
										<v-img width="200" :src="selectedProvider.oAuthImage"></v-img>
									</div>
								</div>
								<v-btn v-else elevation="0" class="mt-3" large block @click="reconnectOAuth"
									><v-icon class="mr-2">{{ selectedProvider.icon }}</v-icon> Reconnect your
									{{ selectedProvider.name }} account</v-btn
								>
							</div>
							<div v-else>
								<div v-if="selectedProvider.oAuthImage" class="column-format centered">
									<div>Connect your {{ selectedProvider.name }} account</div>
									<div class="pointer mt-2" @click="connectOAuth">
										<v-img width="200" :src="selectedProvider.oAuthImage"></v-img>
									</div>
								</div>
								<v-btn v-else elevation="0" large block @click="connectOAuth"
									><v-icon class="mr-2">{{ selectedProvider.icon }}</v-icon> Connect your
									{{ selectedProvider.name }} account</v-btn
								>
							</div>
						</div>
						<div v-else-if="selectedProvider && !selectedProvider.oauth" class="column-format gap-3 my-3">
							<v-text-field
								v-model="emailBox.username"
								hide-details
								persistent-placeholder
								label="Username/Email"
								dense
								outlined
							></v-text-field>
							<v-text-field
								v-model="emailBox.password"
								type="password"
								hide-details
								persistent-placeholder
								:label="appSpecificPasswordLink ? 'App specific password' : 'Password'"
								dense
								outlined
							></v-text-field>
						</div>
						<v-text-field
							v-if="emailBox.id"
							v-model="emailBox.fromEmail"
							hide-details
							persistent-placeholder
							label="From address (optional)"
							class="mb-3"
							dense
							outlined
						></v-text-field>
						<div class="h-outline">
							<editor
								class="pa-2 text-left"
								style="width: 100%; min-height: 100px; border-bottom: none;"
								ref="editor"
								:api-key="$store.getters.getTinyMceKey"
								:initial-value="emailBox.signature"
								:inline="false"
								:init="mceConfigText"
								:spellcheck="true"
								placeholder="Signature"
								@input="handleSignatureUpdated"
							></editor>
						</div>
						<div v-if="showConfig" class="column-format gap-3 mt-3">
							<v-text-field
								label="IMAP Server"
								v-model="emailBox.host"
								persistent-placeholder
								hide-details
								dense
								outlined
							></v-text-field>
							<v-text-field
								label="IMAP Port"
								type="number"
								v-model="emailBox.port"
								persistent-placeholder
								hide-details
								dense
								outlined
							></v-text-field>
							<v-text-field
								label="SMTP Server"
								v-model="emailBox.smtpHost"
								persistent-placeholder
								hide-details
								dense
								outlined
							></v-text-field>
							<v-text-field
								label="SMTP Port"
								type="number"
								v-model="emailBox.smtpPort"
								persistent-placeholder
								hide-details
								dense
								outlined
							></v-text-field>
							<v-select
								label="Connection Security"
								v-model="emailBox.connectionSecurity"
								persistent-placeholder
								hide-details
								dense
								outlined
								:items="connectionSecurity"
							></v-select>
						</div>
						<div v-if="appSpecificPasswordLink" class="font-gray_80 font-12 row-format align-center text-left my-5">
							<v-icon color="primary">$info</v-icon>
							<div class="ml-2" style="max-width: 300px">
								Your provider requires you to use an App Specific password. Do NOT enter your actual password into
								the above field.
								<a :href="appSpecificPasswordLink" target="_blank"
									>Click here to view instructions for creating an app specific password.</a
								>
							</div>
						</div>
					</div>
					<div class="mb-5 mt-3" v-if="showSettings">
						<v-select
							:items="zeroToSixty"
							v-model="emailBox.fetchFrequencyMinutes"
							persistent-placeholder
							hide-details
							dense
							outlined
							label="Email sync frequency"
						>
							<template v-slot:selection="{ item }">{{ item }} minutes</template>
							<template v-slot:item="{ item }">{{ item }} minutes</template>
						</v-select>
						<v-switch hide-details dense v-model="emailBox.defaultEmailBox" label="Default email"></v-switch>
						<v-checkbox hide-details dense v-model="emailBox.enabled" label="Sync enabled"></v-checkbox>
						<v-checkbox hide-details dense v-model="emailBox.sharedInbox" label="Shared inbox"></v-checkbox>
						<div class="font-12 font-gray_70 text-left" style="max-width: 350px">
							When shared inbox is selected, other members of the team will see emails from this account in their
							inbox.
						</div>
					</div>
					<div v-if="verificationStatus" class="text-left font-14 mt-auto font-gray_80">
						<span class="brand-medium">Connection Status:</span>
						<div class="row-format align-center" style="gap: 8px">
							<div class>IMAP:</div>
							<v-icon small :color="verificationStatus.imapStatus ? 'success' : 'warning'">{{
								verificationStatus.imapStatus ? '$check' : '$alert'
							}}</v-icon>
							<div>{{ verificationStatus.imapMessage }}</div>
						</div>
						<div class="row-format align-center" style="gap: 8px">
							<div>SMTP:</div>
							<v-icon small :color="verificationStatus.smtpStatus ? 'success' : 'warning'">{{
								verificationStatus.smtpStatus ? '$check' : '$alert'
							}}</v-icon>
							<div>{{ verificationStatus.smtpMessage }}</div>
						</div>
					</div>
				</div>
				<div v-if="filteredFolders && emailBox.id">
					<div class="text-left">
						<h3>Enabled folders</h3>
						<div v-for="folder in filteredFolders" :key="folder" class="row-format align-center my-1">
							<input class="big-checkbox" :id="folder" type="checkbox" v-model="emailBox.folders" :value="folder" />
							<div>
								<label :for="folder" class="font-14 ml-2 pointer">{{ folder }}</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="emailBox.id && emailBox.folders.length === 0"
				class="text-left row-format font-12"
				style="max-width: 450px"
			>
				<v-icon color="warning">$alert</v-icon>
				<div class="ml-2">
					You must choose one or more folders to target for mail sync. For most mail providers you want your Inbox and
					Sent items.
				</div>
			</div>
		</div>
		<div
			style="border-top: 1px solid var(--v-gray_30-base)"
			class="mt-2 pa-2 row-format centered gap-2"
			v-if="emailBox.id || !selectedProvider.oauth"
		>
			<v-btn class="super-action" width="160" @click="saveEmailBox()">
				{{ $t(id === null ? 'global.create' : 'global.update') }}
			</v-btn>
			<v-btn icon @click="confirmDelete()">
				<v-icon class="material-symbols-rounded" size="20" color="">delete</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
	import EmailBoxService from '@/modules/communicator/settings/EmailBoxService';
	import providers from './providers.json';
	import ConfirmModal from '@/components/ConfirmModal';
	import MicrosoftCommunicatorService from '@/modules/integrations/microsoft/MicrosoftCommunicatorService';
	import GoogleCommunicatorService from '@/modules/integrations/google/GoogleCommunicatorService';

	export default {
		name: 'MailboxConfig',

		props: ['id'],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function() {
			return {
				emailBoxService: new EmailBoxService(),
				microsoftCommunicatorService: new MicrosoftCommunicatorService(),
				googleCommunicatorService: new GoogleCommunicatorService(),
				emailBox: null,
				availableFolders: [],
				verificationStatus: null,
				providers: providers,
				connectionSecurity: [
					{ text: 'None', value: 'NONE' },
					{ text: 'SSL/TLS', value: 'SSLTLS' },
					{ text: 'STARTTLS', value: 'STARTTLS' },
				],

				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					paste_as_text: false,
					table_style_by_css: true,
					forced_root_block: false,
					paste_data_images: true,
					browser_spellcheck: true,
					plugins: ['paste', 'link', 'code'],
					contextmenu: 'paste | link',
					branding: false,
					statusbar: false,
					height: `200px`,
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: ['forecolor fontsizeselect | bold italic underline | link unlink | code'],
					fontsize_formats: '10pt 11pt 12pt 14pt 16pt 18pt 20pt',
				},
			};
		},

		mounted() {
			if (this.id) {
				this.getEmailBox();
				this.getAvailableFolders();
			} else {
				this.initNew();
			}
		},

		beforeDestroy() {},

		methods: {
			getEmailBox: function() {
				this.emailBoxService.getEmailBox(this.id).then((res) => {
					this.emailBox = res.data;
				});
			},

			handleSignatureUpdated: function() {
				this.emailBox.signature = this.$refs.editor.editor.getContent();
			},

			getAvailableFolders: function() {
				this.emailBoxService.getAvailableFolders(this.id).then((res) => {
					this.availableFolders.splice(0, this.availableFolders.length);
					this.availableFolders.push(...res.data);
				});
			},

			connectOAuth: function() {
				if (this.selectedProvider.type === 'Office365') {
					this.$store.commit('startLoading');
					window.location.href = this.microsoftCommunicatorService.setupConnectRequest();
				} else if (this.selectedProvider.type === 'GoogleOAuth') {
					this.connectGoogleOAuth(false);
				} else {
					this.$store.commit('error', 'OAuth connection not supported');
				}
			},

			reconnectOAuth: function() {
				if (this.selectedProvider.type === 'Office365') {
					window.location.href = this.microsoftCommunicatorService.setupReconnectRequest(this.emailBox.id);
				} else if (this.selectedProvider.type === 'GoogleOAuth') {
					this.connectGoogleOAuth(true);
				} else {
					this.$store.commit('error', 'OAuth connection not supported');
				}
			},

			connectGoogleOAuth: function(reconnect = false) {
				let params = {
					client_id: this.googleCommunicatorService.getClientId(),
					scope: this.googleCommunicatorService.getScopes(),
					ux_mode: 'popup',
					callback: reconnect ? this.handleGoogleReconnectAutoCode : this.handleGoogleAuthCode,
				};
				let googleClient = window.google.accounts.oauth2.initCodeClient(params);
				googleClient.requestCode();
			},

			handleGoogleAuthCode: function(res) {
				this.$store.commit('startLoading');
				this.googleCommunicatorService
					.connect(res.code, this.googleCommunicatorService.getScopes())
					.then((res) => {
						this.emailBox = res.data;
						this.$store.commit('info', 'Google mailbox connected successfully.');
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			handleGoogleReconnectAutoCode: function(res) {
				this.$store.commit('startLoading');
				this.googleCommunicatorService
					.reconnect(this.emailBox.id, res.code, this.googleCommunicatorService.getScopes())
					.then((res) => {
						this.emailBox = res.data;
						this.$store.commit('info', 'Google mailbox connected successfully.');
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			saveEmailBox: function() {
				if (this.emailBox.id) {
					this.cleanUpFolders();
					this.updateEmailBox();
				} else {
					this.createEmailBox();
				}
			},

			cleanUpFolders: function() {
				if (this.emailBox.folders && this.availableFolders.length) {
					let folders = [...this.emailBox.folders];
					this.emailBox.folders.splice(0);
					for (let i = 0; i < folders.length; i++) {
						if (this.availableFolders.includes(folders[i])) {
							this.emailBox.folders.push(folders[i]);
						}
					}
				}
			},

			updateEmailBox: function() {
				this.$store.commit('startLoading');
				this.emailBoxService
					.updateEmailBox(this.id, this.emailBox)
					.then((res) => (this.emailBox = res.data))
					.finally(() => {
						this.$store.commit('stopLoading');
						this.verifySettings();
					});
			},

			createEmailBox: function() {
				this.$store.commit('startLoading');
				this.emailBoxService
					.createEmailBox(this.emailBox)
					.then((res) => (this.emailBox = res.data))
					.finally(() => {
						this.$store.commit('stopLoading');
						this.verifySettings();
					});
			},

			confirmDelete: function() {
				let binding = {
					bodyText:
						'Are you sure you want to delete this mailbox?  This will delete ALL messages associated with it.  Please enter your username for this mailbox to confirm.',
					confirmText: this.emailBox.username,
					confirmHint: `Enter ${this.emailBox.username}`,
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.emailBoxService.deleteEmailBox(this.emailBox.id).then(() => this.$emit('result'));
					}
				});
			},

			verifySettings: function() {
				this.$store.commit('startLoading');
				this.emailBoxService
					.verifySettings(this.emailBox.id)
					.then((res) => {
						this.verificationStatus = res.data;
						if (this.verificationStatus.imapStatus) {
							this.getAvailableFolders();
						}
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			initNew: function() {
				this.emailBox = {
					provider: 'GoogleOAuth',
					enabled: true,
					fetchFrequencyMinutes: 15,
				};
				this.providerUpdated();
			},

			providerUpdated: function() {
				let provider = this.providers.find((p) => p.type === this.emailBox.provider);
				if (provider && provider.type !== 'Other') {
					this.emailBox.host = provider.host;
					this.emailBox.port = provider.port;
					this.emailBox.smtpHost = provider.smtpHost;
					this.emailBox.smtpPort = provider.smtpPort;
					this.emailBox.connectionSecurity = provider.connectionSecurity;
					this.emailBox.folders = provider.defaultFolders;
				}
			},

			handleClose: function() {
				this.$emit('result');
			},
		},

		computed: {
			zeroToSixty: function() {
				let result = [];
				for (let i = 5; i <= 60; i = i + 5) {
					result.push(i);
				}
				return result;
			},

			selectedProvider: function() {
				return this.providers.find((p) => p.type === this.emailBox.provider);
			},

			appSpecificPasswordLink: function() {
				if (this.emailBox) {
					let provider = this.providers.find((p) => p.type === this.emailBox.provider);
					if (provider) {
						return provider.appPassword;
					} else {
						return null;
					}
				} else {
					return null;
				}
			},

			showSettings: function() {
				if (this.emailBox && this.emailBox.id) {
					return true;
				} else if (this.selectedProvider.oauth) {
					return false;
				} else {
					return true;
				}
			},

			showConfig: function() {
				if (this.emailBox && this.emailBox.provider === 'Other') {
					return true;
				} else {
					return false;
				}
			},

			filteredFolders: function() {
				if (this.emailBox.provider === 'Google' || this.emailBox.provider === 'GoogleOAuth') {
					return this.availableFolders.filter((f) => f == '[Gmail]/All Mail');
				} else {
					return this.availableFolders;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.big-checkbox {
		width: 18px;
		height: 18px;
	}
</style>
