var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "panel-modal", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("result")
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c("div", { staticClass: "brand-medium font-18" }, [
                  _vm._v(_vm._s(_vm.$t("recurring.deliverables.heading")))
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _vm.isReady
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "primary-action",
                            attrs: { disabled: !_vm.deliverable.name },
                            on: { click: _vm.saveRecurringDeliverable }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("global.save-changes")) + " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "delete ml-2",
                        attrs: { icon: "" },
                        on: { click: _vm.deleteRecurringDeliverable }
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("$delete")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "panel-modal-body text-left show-scrollbar" },
            [
              _c("recurring-event-config", {
                attrs: { recurring: _vm.recurring }
              }),
              _c("hr", {
                staticClass: "mt-3 mb-6",
                staticStyle: {
                  "border-top": "1px solid var(--v-gray_50-base)",
                  "border-bottom": "none"
                }
              }),
              _c("deliverable-detail", {
                attrs: {
                  deliverable: _vm.deliverable,
                  "fee-schedule": _vm.feeSchedule,
                  "attachment-service": _vm.recurringAttachmentService
                }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }