<template>
	<div style="width:100%; height: 100%" class="column-format">
		<div class="mx-2 video-container pa-2 mb-6" v-if="section.videos.length">
			<div class="font-14 brand-medium text-left mb-2 mt-0 font-gray_80">Video tutorials</div>
			<div class="row-format show-scrollbar pb-2" style="max-width: 100%; overflow-x: scroll">
				<div v-for="(video,index) in section.videos" :key="video.videoId" class="featured-video mr-4">
					<v-img :src="`https://img.youtube.com/vi/${video.videoId}/mqdefault.jpg`" class="pointer" @click="openVideo(index)">
						<div style="width:100%; height:100%" class="row-format centered">
							<div class="play-button"><v-icon class="ml-1">$play</v-icon></div>
						</div>
					</v-img>
					<div class="font-12 brand-medium text-left mt-1">{{video.title}}</div>
				</div>
			</div>
		</div>

		<div class="text-left mx-2">
			<div class="font-14 brand-medium font-gray_80 pl-1 mb-2">Suggested articles</div>
			<div v-for="article in section.articles" :key="article.link" class="article" @click="openArticle(article.link)">
				<div>{{article.title}}</div>
			</div>
		</div>

		<v-dialog v-model="videoOpen" v-if="videoOpen" width="unset" style="background-color: var(--v-white-base)">
			<div style="background-color: var(--v-white-base)" class="pa-4">
				<div style="background-color: var(--v-black-base); padding: 4px 4px 0px 4px">
					<youtube
							:player-vars="{ autoplay: 1 }"
							id="player"
							:video-id="videoId"
							:player-width="950"
							:player-height="700"
					></youtube>
				</div>
				<div v-if="previous || next" class="row-format centered pt-4" style="gap: 12px; border-top: 1px solid var(--v-gray_50-base)">
					<v-btn v-if="previous" elevation="0"  color="gray_30" @click.stop="previousVideo()"><v-icon size="20" color="gray_80">$arrowLeft</v-icon> Previous: {{previous.title}}</v-btn>
					<v-btn v-if="next" elevation="0"  color="gray_30" @click.stop="nextVideo()">Next: {{next.title}} <v-icon size="20" color="gray_80">$arrowRight</v-icon></v-btn>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "SectionDetail",

	props: ['section'],

	components: {},

	data: function () {
		return {
			openIndex: null,
			videoOpen: false,
			videoId: null,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		previousVideo: function(){
			this.openVideo(this.openIndex-1);
		},

		nextVideo: function(){
			this.openVideo(this.openIndex+1);
		},

		openVideo: function(index){
			this.openIndex = index;
			this.videoId= this.section.videos[index].videoId;
			this.videoOpen = true;
		},

		openArticle: function(link) {
			window.open(link, '_blank');
		},

		handleCloseOutside: function(){
			if(this.videoOpen){
				this.videoOpen = false;
				this.videoId = null;
				this.openIndex = null;
			}
		}
	},

	computed: {
		next: function(){
			if(this.openIndex < this.section.videos.length - 1){
				return this.section.videos[this.openIndex+1];
			}else{
				return null;
			}
		},

		previous: function(){
			if(this.openIndex > 0){
				return this.section.videos[this.openIndex-1];
			}else{
				return null;
			}
		},

		featuredVideo: function(){
			if(this.section.videos.length){
				return this.section.videos[0];
			}else{
				return null;
			}
		}
	},

}
</script>

<style scoped lang="scss">
	.video-container {
		background-color: var(--v-gray_20-base);
		border-radius: 4px;
	}

	.featured-video {
		width: 185px;
	}

	.play-button {
		width: 48px;
		height: 48px;
		border-radius: 24px;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_50-base);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.article {
		border-bottom: 1px solid var(--v-gray_50-base);
		padding: 12px 4px;
		font-size: 14px;
		cursor: pointer;
		&:hover{
			background-color: var(--v-gray_20-base);
		}
	}
</style>