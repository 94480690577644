<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-proposal')" @action="navigateToProposal()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small :client="client" />
			<div class="ml-2">{{ heading }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ body }}</div>
			<div class="font-12">
				<span>{{ isAccept ? 'Accepted' : 'Signed' }} {{ notificationPayload.signedAt | formatForTimeAgo }}</span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";
	import ProposalBuilder from "@/modules/proposals/ProposalBuilder";
	import AgreementBuilder from "@/modules/agreements/AgreementBuilder";

	export default {
		name: 'ProposalCommentNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar, NotificationLayout },

		data: function() {
			return {
				heading: this.notificationPayload.proposalHeading,
				client: this.notificationPayload.client,
				signedBy: this.notificationPayload.signedBy,
				isAccept: this.notificationPayload.isAccept,
				proposalType: this.notificationPayload.proposalType,
				version: this.notificationPayload.version,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			navigateToProposal: function() {
				if(this.version === 1){
					let binding = {
						proposalId: this.notificationPayload.proposalId,
						clientId: this.client.id,
					}
					this.$store.state.globalModalController.openModal(ProposalBuilder,binding,true,true)
				}else{
					let binding = {
						id: this.notificationPayload.proposalId
					}
					this.$store.state.globalModalController.openModal(AgreementBuilder,binding,true,true)
				}
			},
		},

		computed: {
			body: function(){
				return `${this.proposalType} ${this.isAccept ? 'accepted' : 'signed'}`
			}
		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
