<template>
	<div class="column-format gap-3 pa-3 text-left">
		<v-select
			persistent-placeholder
			hide-details
			dense
			outlined
			:items="invoiceTemplates"
			v-model="invoiceTemplateId"
			item-value="id"
			item-text="name"
			label="Invoice template"
			@change="invoiceTemplateUpdated"
		></v-select>
		<v-text-field
			v-if="!recurring && !paymentPlan"
			:disabled="invoiceNumberLocked"
			hide-details
			dense
			outlined
			persistent-placeholder
			label="Invoice number"
			v-model="invoiceNumberFormatted"
			@blur="invoiceNumberLocked = true"
			@change="invoiceNumberUpdated"
		>
			<template v-slot:append>
				<v-icon class="pointer" @click="invoiceNumberLocked = !invoiceNumberLocked">{{
					invoiceNumberLocked ? 'lock' : 'lock_open'
				}}</v-icon>
			</template>
		</v-text-field>
		<v-text-field
				hide-details
				dense
				outlined
				persistent-placeholder
				label="Description"
				v-model="description"
				@change="descriptionUpdated"
		></v-text-field>
		<v-select
			v-if="recurring || paymentPlan"
			:items="dayOptions"
			v-model="invoice.paymentTerms.paymentDays"
			hide-details
			dense
			outlined
			persistent-placeholder
			label="Due after send"
		>
			<template v-slot:selection="{ item }">
				<span v-if="item === -1">1st of next month</span>
				<span v-else-if="item === -2">15th of next month</span>
				<span v-else-if="item === 0">On Receipt</span>
				<span v-else>{{ item }} days</span>
			</template>
			<template v-slot:item="{ item }">
				<span v-if="item === -1">1st of next month</span>
				<span v-else-if="item === -2">15th of next month</span>
				<span v-else-if="item === 0">On Receipt</span>
				<span v-else>{{ item }} days</span>
			</template>
		</v-select>
		<date-selector
			v-if="!recurring && !paymentPlan"
			label="Date issued"
			:standard="true"
			:date="invoice.dateSent"
			:show-year="true"
			@change="dateSentUpdated($event)"
		></date-selector>
		<date-selector
			v-if="!recurring && !paymentPlan"
			label="Date due"
			:standard="true"
			:date="invoice.dateDue"
			:show-year="true"
			@change="dateDueUpdated($event)"
		></date-selector>
		<v-text-field
			hide-details
			dense
			outlined
			persistent-placeholder
			label="Purchase Order"
			v-model="purchaseOrder"
			@change="purchaseOrderUpdated"
		></v-text-field>
		<v-text-field
			type="number"
			hide-details
			dense
			outlined
			persistent-placeholder
			label="Monthly late fee percentage"
			suffix="%"
			v-model="invoice.paymentTerms.latePaymentFee"
		></v-text-field>
		<v-text-field
			type="number"
			hide-details
			dense
			outlined
			persistent-placeholder
			label="Discount percentage"
			suffix="%"
			v-model="invoice.discountPercentage"
		></v-text-field>

		<div v-if="invoice.taxRateLocked || additionalTaxRates.length">
			<v-menu :close-on-content-click="true" :close-on-click="true">
				<template v-slot:activator="{ on }">
						<v-text-field
							type="number"
							v-model="invoice.taxPercentage"
							:readonly="true"
							v-on="on"
							:min="0"
							:max="100"
							step="1"
							hide-details
							dense
							outlined
							persistent-placeholder
							label="Tax rate"
							suffix="%"
						/>
				</template>
				<div class="column-format pa-3 font-14 gap-2" style="background-color: var(--v-white-base)">
					<div
						v-for="taxRate in additionalTaxRates"
						:key="taxRate.id"
						class="row-format gap-3 pointer"
						@click="taxRateSelected(taxRate)"
					>
						<div>{{ taxRate.name }}</div>
						<div class="ml-auto">{{ taxRate.rate }}%</div>
					</div>
				</div>
			</v-menu>
		</div>
		<div v-else>
			<v-text-field
				type="number"
				v-model="invoice.taxPercentage"
				:disabled="invoice.taxRateLocked"
				:min="0"
				:max="100"
				step="1"
				hide-details
				dense
				outlined
				persistent-placeholder
				label="Tax rate"
				suffix="%"
			/>
		</div>

		<v-select
			persistent-placeholder
			hide-details
			dense
			outlined
			:items="paidByOptions"
			v-model="invoice.paymentTerms.whoPaysCardFees"
			label="Pass credit card fees to client?"
		></v-select>

		<v-select
			v-if="!hideAutoPay"
			hide-details
			persistent-placeholder
			dense
			outlined
			label="Auto charge payment method (optional)"
			:items="paymentMethods"
			item-value="id"
			:return-object="true"
			v-model="invoice.autoPayMethod"
			clearable
			v-tippy="{
				content: 'If set, the assigned payment method will be automatically charged on the due date.',
			}"
		>
			<template v-slot:item="{ item }">
				<span style="text-transform: capitalize">{{ item.label.toLowerCase() }} - {{ item.last4 }}</span>
			</template>
			<template v-slot:selection="{ item }">
				<span style="text-transform: capitalize">{{ item.label.toLowerCase() }} - {{ item.last4 }}</span>
			</template>
		</v-select>

		<div class="h-outline" style="position: relative">
			<editor
				class="text-left px-1 pt-3"
				style="width: 100%; min-height: 100px; border-bottom: none; font-size: 0.9em"
				ref="editor"
				:api-key="$store.getters.getTinyMceKey"
				:initial-value="payInstructions"
				:inline="true"
				:init="mceConfigText"
				:spellcheck="true"
				@input="handlePayInstructionsInput"
			></editor>

			<div class="phone-label">Payment instructions</div>
		</div>

		<v-switch
			hide-details
			dense
			class="ma-0 pa-0"
			label="Online payments enabled"
			v-model="invoice.allowOnlinePayment"
		></v-switch>
		<v-switch
			hide-details
			dense
			class="ma-0 pa-0"
			v-if="$store.state.podAccount.accountPreferences.invoiceDueNotification !== 'DISABLED'"
			label="Payment reminders enabled"
			v-model="invoice.remindersEnabled"
		></v-switch>
	</div>
</template>

<script>
	import DateSelector from '@/components/DateSelector';
	import InvoiceTemplateService from '@/modules/templates/invoices/InvoiceTemplateService';
	import ClientService from '@/modules/clients/ClientService';

	export default {
		name: 'InvoiceSettings',

		props: ['invoice', 'additionalTaxRates', 'recurring', 'paymentPlan','hideAutoPay'],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
			DateSelector,
		},

		data: function() {
			return {
				clientService: new ClientService(),
				invoiceTemplateId: this.invoice.invoiceTemplate.id,
				invoiceTemplates: [],
				paymentMethods: [],
				invoiceNumberLocked: true,
				invoiceNumberFormatted: this.invoice.invoiceNumberFormatted,
				payInstructions: this.invoice.payInstructions,
				purchaseOrder: this.invoice.purchaseOrder,
				description: this.invoice.description,
				paidByOptions: [
					{ text: 'No', value: 'Freelancer' },
					{ text: 'Yes', value: 'Client' },
					{ text: 'Split it', value: 'Split' },
				],
				dayOptions: [
					0,
					1,
					2,
					3,
					4,
					5,
					6,
					7,
					8,
					9,
					10,
					11,
					12,
					13,
					14,
					15,
					16,
					17,
					18,
					19,
					20,
					21,
					30,
					45,
					60,
					90,
					120,
					180,
					-1,
					-2,
				],
				templateService: new InvoiceTemplateService(),
				mceConfigText: {
					menubar: false,
					inline: true,
					browser_spellcheck: true,
					forced_root_block: 'div',
					plugins: ['paste'],
					paste_as_text: true,
					toolbar: [],
				},
			};
		},

		mounted() {
			this.getInvoiceTemplates();
			this.getPaymentMethods();
		},

		beforeDestroy() {},

		methods: {
			getInvoiceTemplates: function() {
				this.templateService.getInvoiceTemplates().then((res) => {
					this.invoiceTemplates.splice(0);
					this.invoiceTemplates.push(...res.data);
				});
			},
			getPaymentMethods: function() {
				this.clientService.getClientPaymentMethods(this.invoice.clientId).then((res) => {
					this.paymentMethods.splice(0, this.paymentMethods.length);
					this.paymentMethods.push(...res.data);
					this.paymentMethods.sort((a, b) => a.label.localeCompare(b.label));
				});
			},
			dateSentUpdated: function(date) {
				this.invoice.dateSent = date;
			},
			dateDueUpdated: function(date) {
				this.invoice.dateDue = date;
			},
			payInstructionUpdated: function() {
				this.invoice.payInstructions = this.payInstructions;
			},
			invoiceNumberUpdated: function() {
				this.invoice.invoiceNumberFormatted = this.invoiceNumberFormatted;
			},
			purchaseOrderUpdated: function() {
				this.invoice.purchaseOrder = this.purchaseOrder;
			},
			descriptionUpdated: function() {
				this.invoice.description = this.description;
			},
			invoiceTemplateUpdated: function() {
				let template = this.invoiceTemplates.find((t) => t.id === this.invoiceTemplateId);
				if (template) {
					this.invoice.invoiceTemplate = null;
					this.$nextTick(() => this.invoice.invoiceTemplate = template);
				}
			},
			taxRateSelected(taxRate) {
				if (taxRate.type === 'Quickbooks') {
					this.invoice.integrationTaxKeys.quickbooksId = taxRate.id;
					this.$emit('force-save');
				}
				if (taxRate.type === 'Xero') {
					this.invoice.integrationTaxKeys.xeroId = taxRate.id;
					this.$emit('force-save');
				}
			},
			handlePayInstructionsInput: function() {
				this.payInstructions = this.$refs.editor.editor.getContent();
				this.invoice.payInstructions = this.payInstructions;
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
