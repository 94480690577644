var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { to: "/communicator/inbox", label: "Communicator" }
            ]
          }
        },
        [_vm._v(" Email templates ")]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center mb-4" },
        [
          _c("v-text-field", {
            staticStyle: {
              "max-width": "200px",
              "background-color": "var(--v-white-base)"
            },
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search...",
              color: "gray_30"
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "material-symbols-rounded font-gray_50" },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c(
            "v-menu",
            {
              attrs: {
                "nudge-bottom": 0,
                elevation: 0,
                "content-class": "add-new-menu",
                bottom: "",
                left: "",
                rounded: "",
                "offset-overflow": "",
                "offset-y": "",
                transition: "slide-y-transition"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ staticClass: "ml-auto super-action" }, on),
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v(" Add template")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("div", { staticClass: "add-new-dropdown" }, [
                _c(
                  "div",
                  {
                    staticClass: "add-new-item",
                    on: {
                      click: function($event) {
                        return _vm.addNew()
                      }
                    }
                  },
                  [_vm._v("New template")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "add-new-item",
                    on: {
                      click: function($event) {
                        return _vm.templateLibrary()
                      }
                    }
                  },
                  [_vm._v("Search template library")]
                )
              ])
            ]
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          items: _vm.filteredEmailTemplates,
          "fixed-header": true,
          headers: _vm.headers,
          "hide-default-footer": true,
          "items-per-page": -1,
          "group-by": "folder",
          "sort-by": "name"
        },
        on: {
          "click:row": function($event) {
            return _vm.editTemplate($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "group.header",
            fn: function(ref) {
              var group = ref.group
              var headers = ref.headers
              var toggle = ref.toggle
              return [
                _c(
                  "td",
                  {
                    staticClass: "text-left",
                    attrs: { colspan: "4" },
                    on: { click: toggle }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row-format align-center gap-2" },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "20", color: "secondary" } },
                          [_vm._v("folder")]
                        ),
                        _vm._v(_vm._s(group ? group : " -- ") + " ")
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.attachments",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(item.attachments.length) + " ")]
            }
          },
          {
            key: "item.created",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.DateTime.fromISO(item.created).toLocaleString(
                        _vm.DateTime.DATE_MED
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-menu",
                  {
                    attrs: {
                      "close-on-content-click": "",
                      "close-on-click": "",
                      "nudge-bottom": 30
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ml-1" },
                                [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "menu-activator",
                                        attrs: { icon: "" }
                                      },
                                      scope.on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { size: 20 } }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.value
                                              ? "$arrowUp"
                                              : "$moreHorizontal"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("div", { staticClass: "more-menu" }, [
                      _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.editTemplate(item)
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.duplicateTemplate(item)
                            }
                          }
                        },
                        [_vm._v("Duplicate")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.deleteTemplate(item)
                            }
                          }
                        },
                        [_vm._v("Delete")]
                      )
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [
                _c("search-button", { on: { click: _vm.templateLibrary } })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }