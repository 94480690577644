import HttpClient from "@/services/HttpClient";

export default class CustomFieldsService {
	httpClient;
	path;

	constructor(path,version=1) {
		this.path = path;
		this.httpClient = new HttpClient(null,true,version);
	}

	getCustomFields() {
		return this.httpClient
			.get(`${this.path}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateCustomFields(fields) {
		return this.httpClient
			.put(`${this.path}`,fields)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
