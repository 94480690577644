var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.attach && _vm.isReady
        ? _c("v-autocomplete", {
            key: "1" + _vm.clientId,
            attrs: {
              "hide-details": "",
              dense: "",
              "persistent-placeholder": "",
              outlined: "",
              value: _vm.clientId,
              items: _vm.computedItems,
              label: _vm.labelToUse,
              placeholder: _vm.placeholder,
              clearable: false,
              disabled: _vm.disabled,
              attach: _vm.attach,
              "prepend-inner-icon": "person"
            },
            on: { change: _vm.updateValue },
            scopedSlots: _vm._u(
              [
                _vm.showAvatar
                  ? {
                      key: "prepend-inner",
                      fn: function() {
                        return [
                          _vm.selectedClient
                            ? _c("client-avatar", {
                                staticClass: "mr-2",
                                attrs: { client: _vm.selectedClient }
                              })
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "item",
                  fn: function(data) {
                    return [
                      _c("div", {
                        staticClass: "autocomplete-custom-item ellipsis",
                        domProps: { innerHTML: _vm._s(data.item.text) }
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm.isReady
        ? _c("v-autocomplete", {
            key: "1" + _vm.clientId,
            attrs: {
              "hide-details": "",
              dense: "",
              "persistent-placeholder": "",
              outlined: "",
              value: _vm.clientId,
              items: _vm.computedItems,
              label: _vm.labelToUse,
              placeholder: _vm.placeholder,
              clearable: false,
              disabled: _vm.disabled,
              "prepend-inner-icon": "person"
            },
            on: { change: _vm.updateValue },
            scopedSlots: _vm._u(
              [
                _vm.showAvatar
                  ? {
                      key: "prepend-inner",
                      fn: function() {
                        return [
                          _vm.selectedClient
                            ? _c("client-avatar", {
                                attrs: {
                                  small: true,
                                  client: _vm.selectedClient
                                }
                              })
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "item",
                  fn: function(data) {
                    return [
                      _c("div", {
                        staticClass: "autocomplete-custom-item ellipsis",
                        domProps: { innerHTML: _vm._s(data.item.text) }
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }