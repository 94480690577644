<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }]">
			My profile
		</page-header>

		<div class="row-format mb-4 gap-3 flex-wrap">
			<div
				:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
				v-for="page in pages"
				:key="page.value"
				@click.stop="setView(page.value)"
			>
				<div>{{ page.label }}</div>
			</div>
		</div>

		<user-settings v-if="currentView === 'Me'"></user-settings>
		<delete-account v-if="currentView === 'Delete'"></delete-account>
		<workspaces v-if="currentView === 'Workspaces'"></workspaces>
		<notifications v-if="currentView === 'Notifications'"></notifications>
	</div>
</template>

<script>
	import PageHeader from '@/components/PageHeader';
	import UserSettings from '@/modules/account/UserSettings';
	import DeleteAccount from '@/modules/account/DeleteAccount';
	import Notifications from '@/modules/account/Notifications';
	import Workspaces from '@/modules/account/Workspaces';

	export default {
		name: 'Me',

		props: ['v'],

		components: { Notifications, DeleteAccount, UserSettings, PageHeader, Workspaces },

		data: function() {
			return {
				currentView: 'Me',
			};
		},

		mounted() {
			if(this.v){
				this.currentView = this.v;
			}
		},

		beforeDestroy() {},

		methods: {
			setView: function(view) {
				this.currentView = view;
			},
		},

		computed: {
			pages: function() {
				let result = [];
				result.push({ label: 'My info', value: 'Me' });
				result.push({ label: 'Delete account', value: 'Delete' });
				result.push({ label: 'Workspaces', value: 'Workspaces' });
				result.push({ label: 'Notifications', value: 'Notifications' });

				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
