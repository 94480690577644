var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "var(--v-white-base)" } },
    [
      _c("v-data-table", {
        staticClass: "pointer",
        attrs: {
          headers: _vm.headers,
          "item-key": "id",
          "hide-default-footer": true,
          "disable-pagination": true,
          items: _vm.filteredList
        },
        on: {
          "click:row": function($event) {
            return _vm.openInvoice($event.id, $event.invoice.clientId)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.amount",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$formatters.dollars(
                          item.invoice.invoice.total,
                          true,
                          true,
                          item.invoice.invoice.currency
                        )
                      ) +
                      " "
                  )
                ])
              ]
            }
          },
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(item.name ? item.name : "--") + " ")]
            }
          },
          {
            key: "item.client",
            fn: function(ref) {
              var item = ref.item
              return [
                item.client
                  ? _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        _c("client-avatar", {
                          attrs: { client: item.client, small: "" }
                        }),
                        _c("div", { staticClass: "ml-n3" }, [
                          _vm._v(_vm._s(item.client.name))
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.startDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.DateTime.fromISO(item.startDate).toLocaleString(
                        _vm.DateTime.DATE_MED
                      )
                    ) +
                    " "
                ),
                item.endDate
                  ? _c("span", [
                      _vm._v(
                        " thru " +
                          _vm._s(
                            _vm.DateTime.fromISO(item.endDate).toLocaleString(
                              _vm.DateTime.DATE_MED
                            )
                          )
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.nextRunTime",
            fn: function(ref) {
              var item = ref.item
              return [
                item.nextRunTime && item.enabled
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.nextRunTime)
                            .setZone(_vm.$store.state.podAccount.timeZone)
                            .toLocaleString(_vm.DateTime.DATE_MED_WITH_WEEKDAY)
                        )
                      )
                    ])
                  : _c("span", [_vm._v("--")])
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                item.enabled
                  ? _c(
                      "span",
                      { staticStyle: { "text-transform": "capitalize" } },
                      [_vm._v(_vm._s(item.status.toLowerCase()))]
                    )
                  : _c("span", [_vm._v("Disabled")])
              ]
            }
          },
          {
            key: "item.interval",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(_vm.getLabel(item)) + " ")]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }