<template>
	<div id="item-hours-modal" v-if="project">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">
				{{ $t('invoice.details.item-hours-modal.heading') }}
			</div>
			<div class="modal-subtitle" v-html="$t('invoice.details.item-hours-modal.subheading')"></div>

			<v-treeview
				v-model="invoiceItem.timerEventIds"
				@input="handleTreeViewChange()"
				selection-type="leaf"
				open-on-click
				selectable
				selected-color="primary"
				item-children="items"
				item-text="name"
				dense
				expand-icon="$chevronDown"
				:items="[project]"
			>
				<template v-slot:label="{ item }">
					<div :class="'item ' + item.class">
						<!-- Client -->
						<template v-if="item.class == 'client'">
							<div class="label">
								<div class="name">
									<span>{{ item.name }}</span>
									<span class="fee-type">{{ formatFeeType(item.feeSchedule.feeType) }}</span>
								</div>
								<div class="hours" v-if="client.hourlyAmount">
									{{ getClientSubtotalDisplay(item) }}
								</div>
							</div>
						</template>
						<!-- Project -->
						<div class="label" v-else-if="item.class == 'project'">
							<div class="name">
								<span>{{ item.name }}</span>
							</div>
							<div class="hours">{{ getHourlyProjectSubtotalDisplay(item) }}</div>
						</div>
						<!-- Month, Week, Day -->
						<div class="label" v-else-if="item.class">
							<div class="name">
								{{ item.name }}
							</div>
							<div class="hours">{{ item.durationSubtotalDisplay }}</div>
						</div>
						<!-- Timer Event -->
						<div class="label" v-else>
							<!-- Hours display -->
							<div class="name">{{ item.notes | noteFormat }}</div>
							<div class="hours">{{ item.durationDisplay }}</div>
						</div>
					</div>
				</template>
			</v-treeview>
		</div>
		<div class="modal-footer">
			<v-btn class="px-16 primary-action" @click="handleSave">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import TimeTrack from '@/modules/timetracking/TimeTrack';
	import InvoiceMixin from '@/modules/invoices/InvoiceMixin';
	import { v4 as uuidv4 } from 'uuid';
	export default {
		name: 'ItemHoursModal',

		props: {
			invoiceItem: { type: Object, required: true },
			invoice: { type: Object, required: true },
		},

		components: {},
		mixins: [InvoiceMixin],

		data: function() {
			return {
				invoiceService: new InvoiceService(),
				timeTrack: new TimeTrack(),
				project: null,
				client: null,
			};
		},

		mounted() {
			this.getBillable();
		},

		beforeDestroy() {},

		methods: {
			handleSave() {
				this.$emit('save', this.invoiceItem);
			},
			handleTreeViewChange() {
				this.invoiceItem.qty = this.getHourlyProjectQty();
				this.invoiceItem.subtotal = this.invoiceItem.qty * this.invoiceItem.price;
			},
			getHourlyProjectQty() {
				let hours = 0;
				for (let i = 0; i < this.project.projectHours.length; i++) {
					let projectHour = this.project.projectHours[i];
					// Include in sum if hours are selected, or if no hours are selected
					if (this.invoiceItem.timerEventIds.includes(projectHour.id)) {
						hours += projectHour.durationHoursDecimal;
					}
				}
				return Math.round(hours * 100) / 100;
			},
			getBillable() {
				this.invoiceService
					.getBillable(this.invoice.clientId, this.invoice.id)
					.then((res) => {
						this.client = res.data.client;
						console.log(res.data);
						if (this.invoiceItem.projectId) {
							this.project = this.fixupProject(res.data.projects.find((v) => v.id == this.invoiceItem.projectId));
						} else {
							this.project = this.fixupClientProject(res.data.clientHours);
						}
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			fixupClientProject(clientHours) {
				let project = {};
				project.projectHours = [];
				for (let j = 0; j < clientHours.length; j++) {
					project.projectHours[j] = this.timeTrack.fixupEvent(clientHours[j]);
				}
				project.name = this.client.name;
				project.id = uuidv4();
				//project.name = 'No project';
				project.class = 'client';
				project.isClient = true;

				project.feeSchedule = { amount: this.client.hourlyAmount, feeType: 'Hourly' };
				project.items = this.getHourlyProjectTree(project);
				return project;
			},
			fixupProject(project) {
				for (let j = 0; j < project.projectHours.length; j++) {
					project.projectHours[j] = this.timeTrack.fixupEvent(project.projectHours[j]);
				}
				project.class = 'project';
				project.feeSchedule = this.scrubFeeSchedule(project);
				project.items = this.getHourlyProjectTree(project);
				return project;
			},
			formatFeeType: function(val) {
				if (!val) return '';
				return '(' + this.$t('invoice.fee-type.' + val.replace(/ /, '-').toLowerCase()) + ')';
			},
			getHourlyProjectSubtotalDisplay(project) {
				const selectedHours = this.getProjectTimerEventIds(project, true);
				let hours = 0;
				for (let i = 0; i < project.projectHours.length; i++) {
					let projectHour = project.projectHours[i];
					// Include in sum if hours are selected, or if no hours are selected
					if (selectedHours.includes(projectHour.id) || selectedHours.length == 0) {
						hours += projectHour.durationHoursDecimal;
					}
				}
				return this.$formatters.dollars(
					(Math.round(hours * 100) / 100) * project.feeSchedule.amount,
					true,
					true,
					this.invoice.currency
				);
			},
			getClientSubtotalDisplay(client) {
				const selectedHours = this.getProjectTimerEventIds(client, true);
				let hours = 0;
				for (let i = 0; i < client.projectHours.length; i++) {
					let projectHour = client.projectHours[i];
					// Include in sum if hours are selected, or if no hours are selected
					if (selectedHours.includes(projectHour.id) || selectedHours.length == 0) {
						hours += projectHour.durationHoursDecimal;
					}
				}
				return this.$formatters.dollars(
					(Math.round(hours * 100) / 100) * this.client.hourlyAmount,
					true,
					true,
					this.invoice.currency
				);
			},
			getProjectTimerEventIds(project, selectedOnly = false) {
				let ids = [];
				for (let i = 0; i < project.projectHours.length; i++) {
					if (!selectedOnly || this.invoiceItem.timerEventIds.includes(project.projectHours[i].id)) {
						ids.push(project.projectHours[i].id);
					}
				}
				return ids;
			},

			scrubFeeSchedule(project) {
				if (!project.feeSchedule) return this.feeScheduleDefault();
				return project.feeSchedule;
			},
		},
		computed: {
			feeScheduleDefault() {
				return { feeType: '', amount: 0 };
			},
		},
		filters: {
			noteFormat(note) {
				return note ? note : '--';
			},
		},
	};
</script>

<style lang="scss">
	#item-hours-modal {
		max-width: 400px;
		background-color: var(--v-white-base);

		// Treeview is all hourly projects
		.v-treeview {
			.v-treeview-node {
				padding: 16px 0;

				.v-treeview-node__root {
					padding: 0;
					.v-treeview-node__content {
					}
					.v-treeview-node__toggle {
						margin: 0;
						width: 28px;
					}
					.v-treeview-node__checkbox {
						margin: 0;
						width: 28px;
					}
					.item {
						display: flex;
						align-content: center;
						align-items: center;
						justify-content: space-between;
						padding: 4px 0;

						&.project {
						}

						div.label {
							flex: 1 0 auto;
							display: flex;
							justify-content: space-between;
							div.name {
								span {
									margin-right: 4px;
								}
								flex: 1 1 auto;
								text-align: left;
							}
							div.hours {
								flex: 0 1 auto;
								text-align: right;
							}
							.v-input {
								margin: 0;
								padding: 0;
							}
						}
					}
				}
			}
		}
		.v-treeview-node__children {
			margin-top: 0;
			.v-treeview-node {
				padding: 0;
				border-top: none !important;
				.v-treeview-node__root {
					height: 32px;
				}
			}
		}

		.client-name {
			display: flex;
			justify-content: center;
			margin-bottom: 16px;

			div {
				align-self: center;
				flex: 0 0 auto;
			}
		}

		.project.non-hourly {
			border-top: 1px solid var(--v-gray_50-base);
			padding: 16px 0;
			font-size: 16px;
			letter-spacing: 0.2px;

			.project-head {
				clear: both;
				height: 40px;
				display: flex;
				div,
				button {
					align-self: center;
				}
				button {
					justify-content: center;
					&.chevron,
					&.checkbox {
						flex: 0 0 auto;
					}
					&.checkbox {
					}
				}
				div {
					flex: 1 1 auto;
					&.name {
						text-align: left;
						margin-left: 6px;
						span {
							margin-right: 4px;
						}
					}
					&.hours {
						text-align: right;
					}
				}
			}
			.setup-fees-link {
				color: var(--v-gray_70-base);
				cursor: pointer;
				font-size: 12px;
				text-align: left;
				padding-left: 63px;
				&:hover {
					color: var(--v-black-base);
				}
				&:active {
					color: var(--v-primary-base);
				}
			}
		}
	}
</style>
