var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "row-format gap-2 justify-end" },
      [
        _c(
          "v-btn",
          { staticClass: "primary-action", on: { click: _vm.selectProject } },
          [
            _c("span", { staticClass: "font-secondary" }, [
              _vm._v("Link task")
            ]),
            _c(
              "v-icon",
              { staticClass: "ml-1", attrs: { small: "", color: "secondary" } },
              [_vm._v("add")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "column-format gap-2 mt-2" },
      _vm._l(_vm.deliverables, function(deliverable) {
        return _c("deliverable-mini", {
          key: deliverable.id,
          staticClass: "pa-1",
          staticStyle: {
            border: "1px solid var(--v-gray_30-base)",
            "border-radius": "4px"
          },
          attrs: { "hide-icon": true, deliverable: deliverable },
          on: {
            "deliverable-clicked": function($event) {
              return _vm.openDeliverable(deliverable)
            }
          }
        })
      }),
      1
    ),
    _vm.deliverables.length === 0
      ? _c("div", [
          _c("div", { staticClass: "text-center font-gray_70" }, [
            _vm._v("No tasks linked")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }