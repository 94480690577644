var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "400px" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: { click: _vm.handleClose }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _vm.user.original.profilePicture
              ? _c("v-avatar", { attrs: { size: "55" } }, [
                  _c("img", {
                    staticClass: "pic",
                    attrs: { src: _vm.user.original.profilePicture }
                  })
                ])
              : _c("v-avatar", { attrs: { size: "55", color: "primary" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "font-24",
                      staticStyle: { color: "var(--v-white-base)" }
                    },
                    [_vm._v(_vm._s(_vm.initials))]
                  )
                ]),
            _c("div", { staticClass: "font-20 brand-medium mt-4" }, [
              _vm._v(_vm._s(_vm.user.name))
            ]),
            _c("div", [_vm._v(_vm._s(_vm.user.email))])
          ],
          1
        ),
        _c("div", { staticClass: "mt-3 text-left" }, [
          _c("span", { staticClass: "brand-medium font-14" }, [
            _vm._v("Has access to")
          ]),
          _c(
            "div",
            {
              staticClass: "pa-3",
              staticStyle: {
                "max-height": "400px",
                "overflow-y": "auto",
                "background-color": "var(--v-gray_10-base)",
                border: "1px solid var(--v-gray_50-base)"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "pa-3",
                  staticStyle: {
                    "max-height": "400px",
                    "overflow-y": "auto",
                    "background-color": "var(--v-gray_10-base)",
                    border: "1px solid var(--v-gray_50-base)"
                  }
                },
                [
                  _c("v-checkbox", {
                    attrs: { label: "Projects", "hide-details": "", dense: "" },
                    model: {
                      value: _vm.featureAccess.projects,
                      callback: function($$v) {
                        _vm.$set(_vm.featureAccess, "projects", $$v)
                      },
                      expression: "featureAccess.projects"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: { label: "Invoices", "hide-details": "", dense: "" },
                    model: {
                      value: _vm.featureAccess.invoices,
                      callback: function($$v) {
                        _vm.$set(_vm.featureAccess, "invoices", $$v)
                      },
                      expression: "featureAccess.invoices"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      label: "Accounting",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.featureAccess.accounting,
                      callback: function($$v) {
                        _vm.$set(_vm.featureAccess, "accounting", $$v)
                      },
                      expression: "featureAccess.accounting"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: { label: "Pipeline", "hide-details": "", dense: "" },
                    model: {
                      value: _vm.featureAccess.pipeline,
                      callback: function($$v) {
                        _vm.$set(_vm.featureAccess, "pipeline", $$v)
                      },
                      expression: "featureAccess.pipeline"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      label: "Agreements",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.featureAccess.agreements,
                      callback: function($$v) {
                        _vm.$set(_vm.featureAccess, "agreements", $$v)
                      },
                      expression: "featureAccess.agreements"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: { label: "Settings", "hide-details": "", dense: "" },
                    model: {
                      value: _vm.featureAccess.settings,
                      callback: function($$v) {
                        _vm.$set(_vm.featureAccess, "settings", $$v)
                      },
                      expression: "featureAccess.settings"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      label: "Tickets/Requests",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.featureAccess.tickets,
                      callback: function($$v) {
                        _vm.$set(_vm.featureAccess, "tickets", $$v)
                      },
                      expression: "featureAccess.tickets"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      label: "Timesheets",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.featureAccess.timesheets,
                      callback: function($$v) {
                        _vm.$set(_vm.featureAccess, "timesheets", $$v)
                      },
                      expression: "featureAccess.timesheets"
                    }
                  })
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              on: {
                click: function($event) {
                  return _vm.$emit("result", false)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action ml-1",
              attrs: { width: "200" },
              on: {
                click: function($event) {
                  return _vm.updateUser()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }