var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "universal-search-result pointer " +
        (_vm.selected ? "universal-search-selected" : ""),
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _vm.result.type === "Feature"
        ? _c(
            "div",
            { staticClass: "row-format gap-1 font-14" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded",
                  attrs: { size: "20", color: "black" }
                },
                [_vm._v(_vm._s(_vm.result.icon ? _vm.result.icon : "info"))]
              ),
              _c("div", { staticClass: "brand-medium" }, [
                _vm._v(_vm._s(_vm.result.label))
              ])
            ],
            1
          )
        : _vm.result.type === "Command"
        ? _c("div", { staticClass: "column-format gap-1 font-14" }, [
            _c("div", { staticClass: "row-format align-center gap-1" }, [
              _c("div", { staticClass: "brand-medium" }, [
                _vm._v(_vm._s(_vm.result.name))
              ])
            ]),
            _c("div", { staticClass: "font-12 text-left font-gray_70" }, [
              _vm._v(" " + _vm._s(_vm.result.description) + " ")
            ])
          ])
        : _c("div", { staticClass: "column-format gap-1" }, [
            _c(
              "div",
              { staticClass: "row-format align-center gap-1" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded",
                    attrs: { size: "20", color: "black" }
                  },
                  [_vm._v(_vm._s(_vm.icon))]
                ),
                _vm.client
                  ? _c("client-avatar", {
                      attrs: { client: _vm.client, "x-small": "" }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "font-14 brand-medium" }, [
                  _vm._v(_vm._s(_vm.object.label))
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "font-12 text-left font-gray_70" },
              _vm._l(_vm.result.highlights.content, function(highlight, index) {
                return _c("div", {
                  key: index,
                  domProps: { innerHTML: _vm._s(highlight) }
                })
              }),
              0
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }