<template>
	<v-menu close-on-content-click close-on-click>
		<template v-slot:activator="{ on }">
			<div class="pointer last-event" v-on="on">{{ lastEvent }}</div>
		</template>
		<div style="background-color: var(--v-white-base)" class="pa-4 text-left column-format gap-2">
			<div v-for="event in reversedEvents" :key="event.timestamp" class="last-event" style="font-size: 16px" >
				{{ formatEvent(event) }}
			</div>
		</div>
	</v-menu>
</template>

<script>
	import { DateTime } from 'luxon';

	export default {
		name: 'PaymentPlanEvents',

		props: ['paymentPlan'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			formatEvent: function(event) {
				return event.events[0] + ' - ' + DateTime.fromISO(event.timestamp).toLocaleString(DateTime.DATETIME_MED);
			},
		},

		computed: {
			lastEvent: function() {
				if (this.paymentPlan.events && this.paymentPlan.events.length > 0) {
					let event = this.paymentPlan.events[this.paymentPlan.events.length - 1];
					return this.formatEvent(event);
				} else {
					return '--';
				}
			},

			reversedEvents: function(){
				let result = [... this.paymentPlan.events];
				result.reverse();
				return result;
			}
		},
	};
</script>

<style scoped lang="scss">
	.last-event {
		font-size: 12px;
		color: var(--v-gray_70-base);
	}
</style>
