var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "payment-terms-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("invoice.details.payment-terms-modal.heading")) +
                " "
            )
          ]),
          _c("v-select", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              label: _vm.$t("proposal.terms.payment-terms"),
              items: _vm.dayOptions
            },
            on: {
              change: function($event) {
                return _vm.$emit("payment-days-change")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item === 0 ? _c("span", [_vm._v("On Receipt")]) : _vm._e(),
                    item !== 0
                      ? _c("span", [_vm._v(_vm._s(item) + " days")])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item === 0 ? _c("span", [_vm._v("On Receipt")]) : _vm._e(),
                    item !== 0
                      ? _c("span", [_vm._v(_vm._s(item) + " days")])
                      : _vm._e()
                  ]
                }
              }
            ]),
            model: {
              value: _vm.paymentTerms.paymentDays,
              callback: function($$v) {
                _vm.$set(_vm.paymentTerms, "paymentDays", $$v)
              },
              expression: "paymentTerms.paymentDays"
            }
          }),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              label: _vm.$t("proposal.terms.late-payment"),
              placeholder: "No fee",
              suffix: "% mo.",
              type: "number"
            },
            model: {
              value: _vm.paymentTerms.latePaymentFee,
              callback: function($$v) {
                _vm.$set(_vm.paymentTerms, "latePaymentFee", $$v)
              },
              expression: "paymentTerms.latePaymentFee"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-1 secondary-action",
              attrs: { icon: "" },
              nativeOn: {
                click: function($event) {
                  _vm.confirmDeleteDialog = true
                }
              }
            },
            [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$delete")])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-1",
              attrs: { width: "170" },
              on: {
                click: function($event) {
                  return _vm.handleSave()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": _vm.$t("global.confirm"),
          "body-text": _vm.$t("global.confirm-delete")
        },
        on: {
          confirm: function($event) {
            return _vm.deleteTerms()
          },
          close: function($event) {
            _vm.confirmDeleteDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }