<template>
	<div>
		<v-container fluid v-if="automationDetail"  class="font-14">
			<v-row dense>
				<v-col cols="3">ID</v-col>
				<v-col cols="9" class="brand-medium">{{automationDetail.id}}</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="3">Status</v-col>
				<v-col cols="9" class="brand-medium">{{automationDetail.status}}</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="3">Workflow</v-col>
				<v-col cols="9" class="brand-medium"><span v-if="automationDetail.workflow">{{automationDetail.workflow.name}}</span><span v-else>--</span></v-col>
			</v-row>
			<v-row dense>
				<v-col cols="3">Scope</v-col>
				<v-col cols="9" class="brand-medium">{{automationDetail.scope.type}}: {{automationDetail.scope.name}}</v-col>
			</v-row>
			<v-row dense v-if="detailClient">
				<v-col cols="3">Client</v-col>
				<v-col cols="9" class="brand-medium row-format align-center gap-2">
					<client-avatar :client="detailClient" small></client-avatar>
					<div>{{detailClient.name}}</div>
				</v-col>
			</v-row>
			<v-row dense v-if="automationDetail.waitingFor">
				<v-col cols="3">Waiting for</v-col>
				<v-col cols="9" class="row-format gap-2 brand-medium">
					<div>{{automationDetail.waitingFor.waitType}} - </div>
					<div v-if="automationDetail.waitingFor.waitingUntil">{{HDateTime.fromISO(automationDetail.waitingFor.waitingUntil).toLocaleString(HDateTime.DATETIME_MED)}}</div>
					<div v-else-if="automationDetail.waitingFor.resourceName">{{automationDetail.waitingFor.resourceName}}</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="3">
					Events
				</v-col>
				<v-col cols="9" class="brand-medium column-format">
					<div class="py-2" v-for="event in automationDetail.events" :key="event.id" style="border-bottom: 1px solid var(--v-gray_30-base)">
						{{HDateTime.fromISO(event.timestamp).toLocaleString(HDateTime.DATETIME_SHORT)}} - {{event.event}}
					</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" class="row-format gap-2 mt-3">
					<v-btn class="primary-action" color="primary" @click="setAutomationDetail(null)">
						<v-icon>arrow_back</v-icon> Back
					</v-btn>
					<v-btn @click="cancelAutomation(automationDetail)" class="primary-action" v-if="automationDetail.status === 'Waiting' || automationDetail.status === 'Active'">
						<v-icon class="material-symbols-rounded" size="20">cancel</v-icon> Cancel automation
					</v-btn>
					<v-btn @click="deleteAutomation(automationDetail)" class="primary-action" v-else>
						<v-icon class="material-symbols-rounded" size="20">delete</v-icon> Delete
					</v-btn>
					<v-btn @click="restartAutomation(automationDetail,false)" class="primary-action" v-if="automationDetail.status === 'Waiting'">
						<v-icon class="material-symbols-rounded" size="20">fast_forward</v-icon> Skip forward
					</v-btn>
					<v-btn @click="restartAutomation(automationDetail,true)" class="primary-action" v-if="automationDetail.status === 'Error'">
						<v-icon class="material-symbols-rounded" size="20">restart_alt</v-icon> Retry failed step
					</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<v-data-table
			v-else
			:items="fixedAutomations"
			:headers="headers"
			:hide-default-footer="true"
			:items-per-page="-1"
			class="pointer"
			@click:row="setAutomationDetail($event)"
		>
			<template v-slot:item.timestamp="{ item }">
				{{ $formatters.formatTimeAgo(item.timestamp) }}
			</template>
			<template v-slot:item.scope="{ item }">
				{{ item.scope.name }}
			</template>
			<template v-slot:item.workflow="{ item }">
				{{ item.workflow.name }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import AutomationService from '@/modules/workflow/AutomationService';
	import WorkflowService from '@/modules/workflow/WorkflowService';
	import OpportunityService from '@/modules/pipeline/OpportunityService';
	import HDateTime from "@/modules/utils/HDateTime";
	import ClientAvatar from "@/components/ClientAvatar";
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'AutomationList',

		props: ['workflowId', 'resourceType', 'resourceId'],

		components: {ClientAvatar},

		data: function() {
			return {
				HDateTime: HDateTime,
				automationService: new AutomationService(),
				workflowService: new WorkflowService(),
				opportunityService: new OpportunityService(),
				automations: [],
				workflows: [],
				opportunities: [],
				automationDetail: null,
			};
		},

		mounted() {
			if (this.workflowId) {
				this.getAutomationsByWorkflow();
			} else {
				this.getAutomationsByScope();
			}
			this.getWorkflows();
			this.getOpportunities();
		},

		beforeDestroy() {},

		methods: {
			setAutomationDetail(automationDetail) {
				this.automationDetail = automationDetail;
			},

			cancelAutomation: function(automation){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to cancel this automation?  No additional steps will be run.'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.automationService.cancelAutomation(automation.id).then((res) => {
							this.automationDetail = res.data;
						})
					}
				})
			},

			restartAutomation: function(automation,inError){
				let message;

				if(inError){
					message = 'Are you sure you want to try and restart this automation from the last failed step?'
				}else{
					message = 'Are you sure you want to exit the waiting state and skip forward immediately?'
				}

				let binding = {
					headingText: 'Confirm',
					bodyText: message
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.automationService.restartAutomation(automation.id).then(() => {
							this.$store.commit('success','Request submitted');
						})
					}
				})
			},

			deleteAutomation: function(automation){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this automation record?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.automationService.deleteAutomation(automation.id).then(() => {
							let ix = this.automations.findIndex(a => a.id === automation.id);
							this.automations.splice(ix,1);
							this.automationDetail = null;
						})
					}
				})
			},

			getPrimaryScope: function(automation) {
				let scope = automation.inScope.find((i) => i.primary);
				if (scope.resourceType === 'Opportunity') {
					let opp = this.opportunities.find((o) => o.id === scope.resourceId);
					if (opp) {
						return {
							type: 'Opportunity',
							id: opp.id,
							name: opp.name,
							clientId: opp.clientId,
						};
					}
				}
				return {
					type: null,
					id: null,
					name: '--',
					clientId: null,
				};
			},

			getAutomationsByWorkflow: function() {
				this.automationService.getByWorkflowId(this.workflowId).then((res) => {
					this.automations.splice(0, this.automations.length);
					this.automations.push(...res.data);
				});
			},

			getAutomationsByScope: function() {
				this.automationService.getByAutomationScope(this.resourceType, this.resourceId).then((res) => {
					this.automations.splice(0, this.automations.length);
					this.automations.push(...res.data);
				});
			},

			getWorkflows: function() {
				this.workflowService.getWorkflows().then((res) => {
					this.workflows.splice(0, this.workflows.length);
					this.workflows.push(...res.data);
				});
			},

			getOpportunities: function() {
				if (this.resourceType === 'Opportunity') {
					this.opportunityService.getOpportunity(this.resourceId).then((res) => {
						this.opportunities.splice(0, this.opportunities.length);
						this.opportunities.push(res.data);
					});
				} else {
					this.opportunityService.getOpportunities().then((res) => {
						this.opportunities.splice(0, this.opportunities.length);
						this.opportunities.push(...res.data);
					});
				}
			},
		},

		computed: {
			detailClient: function(){
				if(this.automationDetail && this.automationDetail.scope && this.automationDetail.scope.clientId){
					return this.$store.getters.getClientById(this.automationDetail.scope.clientId);
				}else{
					return null;
				}
			},

			fixedAutomations: function() {
				let result = [...this.automations];
				result.forEach((a) => {
					a.scope = this.getPrimaryScope(a);
					a.workflow = this.workflows.find((w) => w.id === a.workflowId);
				});
				return result;
			},

			headers: function() {
				if(this.workflowId){
					return [
						{text: 'Status', value: 'status', class: 'nowrap'},
						{text: 'Timestamp', value: 'timestamp', class: 'nowrap'},
						{text: 'Scope', value: 'scope', class: 'nowrap'},
					];
				}else {
					return [
						{text: 'Status', value: 'status', class: 'nowrap'},
						{text: 'Timestamp', value: 'timestamp', class: 'nowrap'},
						{text: 'Workflow', value: 'workflow', class: 'nowrap'},
					];
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
