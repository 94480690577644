import HttpClient from '@/services/HttpClient';
import MicrosoftEmailService from "@/modules/integrations/microsoft/MicrosoftEmailService";

export default class MicrosoftCustomEmailService extends MicrosoftEmailService{
    httpClient;

    constructor() {
        super();
        this.httpClient = new HttpClient();
    }

    setupConnectRequest(){
        localStorage.setItem('MICROSOFT_OAUTH_TYPE','CustomEmail');
        localStorage.setItem('MICROSOFT_OAUTH_SCOPE',this.getScopes());
        return this.getLoginUrl();
    }

    connect(code,redirectUri) {
        let formData = new FormData();
        formData.append('code',code);
        formData.append('redirectUri',redirectUri);
        formData.append('scope',"https://outlook.office.com/IMAP.AccessAsUser.All https://outlook.office.com/SMTP.Send offline_access");

        return this.httpClient
            .post(`/customEmail/oAuth/microsoft`,formData,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

}