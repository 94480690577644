var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-4" },
    [
      _c(
        "div",
        [
          _c("div", {}, [_vm._v("Width (" + _vm._s(_vm.element.width) + "%)")]),
          _c("v-slider", {
            attrs: {
              min: 10,
              max: 100,
              step: 1,
              "hide-details": "",
              dense: "",
              "thumb-label": ""
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.scale,
              callback: function($$v) {
                _vm.$set(_vm.element, "scale", $$v)
              },
              expression: "element.scale"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("div", {}, [
            _vm._v("Border radius (" + _vm._s(_vm.element.br) + "px)")
          ]),
          _c("v-slider", {
            attrs: {
              min: 0,
              max: 200,
              step: 1,
              "hide-details": "",
              dense: "",
              "thumb-label": ""
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.br,
              callback: function($$v) {
                _vm.$set(_vm.element, "br", $$v)
              },
              expression: "element.br"
            }
          })
        ],
        1
      ),
      _c("v-select", {
        attrs: {
          items: _vm.alignment,
          "item-text": "label",
          "item-value": "value",
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Alignment"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.alignment,
          callback: function($$v) {
            _vm.$set(_vm.element, "alignment", $$v)
          },
          expression: "element.alignment"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding top"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pt,
              callback: function($$v) {
                _vm.$set(_vm.element, "pt", $$v)
              },
              expression: "element.pt"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding bottom"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pb,
              callback: function($$v) {
                _vm.$set(_vm.element, "pb", $$v)
              },
              expression: "element.pb"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "image-grid" },
        [
          _vm._l(_vm.imageAssets, function(result) {
            return _c(
              "div",
              {
                key: result.id,
                staticClass: "image-container custom-image-container",
                style: "background-image: url(" + result.thumbnail + ");",
                on: {
                  click: function($event) {
                    return _vm.setCustomImage(result)
                  }
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action",
                    attrs: { icon: "", "x-small": "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.confirmDelete(result)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { size: "16" } }, [_vm._v("$delete")])
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "vue-dropzone",
            {
              ref: "fileDropzone",
              staticClass: "image-container no-image-container",
              attrs: {
                id: "dropzone",
                "use-custom-slot": true,
                options: _vm.dropzoneOptions
              },
              on: {
                "vdropzone-success": _vm.uploadSuccess,
                "vdropzone-error": _vm.uploadFailure,
                "vdropzone-sending": _vm.addFileUploadAuthHeader
              }
            },
            [
              _c(
                "div",
                [
                  _c("v-icon", [_vm._v("$vuetify.icons.upload")]),
                  _c("div", { staticClass: "font-12" }, [
                    _vm._v(_vm._s(_vm.$t("proposal.cover.upload")))
                  ])
                ],
                1
              )
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }