<template>
	<div v-if="show" :class="divClass" style="width:100%">
		<span class="red--text"><slot></slot></span>
	</div>
</template>

<script>
	export default {
		name: 'inputError',
		props: { show: Boolean, align: String },

		data() {
			return {
				divClass: '',
			};
		},

		mounted() {
			if (this.align == 'text-right') {
				this.divClass = 'text-right my-0 py-0 caption';
			} else if (this.align == 'text-center') {
				this.divClass = 'text-center my-0 py-0 caption';
			} else {
				this.divClass = 'text-left my-0 py-0 caption';
			}
		},
	};
</script>

<style lang="scss" scoped>
	div {
		border-top: 1px solid red;
	}
</style>
