var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "action-menu" },
    [
      _c(
        "v-menu",
        {
          attrs: { "close-on-click": true, "nudge-bottom": 30 },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(scope) {
                return [
                  _c(
                    "div",
                    { staticClass: "ml-1" },
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "menu-activator",
                            attrs: { icon: "" }
                          },
                          scope.on
                        ),
                        [
                          _c("v-icon", { attrs: { size: 20 } }, [
                            _vm._v(
                              _vm._s(
                                scope.value ? "$arrowUp" : "$moreHorizontal"
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c("div", { staticClass: "more-menu" }, [
            _c(
              "div",
              {
                staticClass: "more-menu-item",
                on: {
                  click: function($event) {
                    return _vm.$emit("view")
                  }
                }
              },
              [_vm._v(" View details ")]
            ),
            !_vm.hideDuplicate
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("duplicate")
                      }
                    }
                  },
                  [_vm._v(" Duplicate scheduler ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "more-menu-item",
                on: {
                  click: function($event) {
                    return _vm.$emit("delete")
                  }
                }
              },
              [_vm._v(" Delete ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }