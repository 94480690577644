import HDateTime from "@/modules/utils/HDateTime";
import CommunicatorTicketService from "@/modules/communicator/CommunicatorTicketService";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default {
    data: function () {
        return {
            HDateTime: HDateTime,
            ticketService: new CommunicatorTicketService(),
            ourTicket: null,
            ourFolder: null,
            ticketSettings: null,
        }
    },

    methods: {
        markAsRead: function(){
            this.ticketService.markTicketAsRead(this.ourTicket.id,this.ourTicket.unread).then((res) => {
                this.ourTicket = res.data;
                this.$emit('ticket-updated',res.data);
            });
        },

        snooze: function(snoozeUntil){
            console.log(this.ourTicket)
            this.ticketService.setSnooze(this.ourTicket.id,snoozeUntil.toISO()).then((res) => {
                this.ourTicket = res.data;
                this.$emit('ticket-updated',res.data);
                this.$emit('back');
            });
        },

        returnToInbox: function(){
            this.ticketService.returnToInbox(this.ourTicket.id).then((res) => {
                this.ourTicket = res.data;
                this.ourFolder = 'Inbox';
                this.$emit('ticket-updated',res.data);
            });
        },

        archive: function(){
            this.ticketService.setArchiveFlag(this.ourTicket.id,!this.ourTicket.archive).then((res) => {
                this.ourTicket = res.data;
                this.$emit('ticket-updated',res.data);
                this.$emit('back');
            });
        },

        getTicketSettings: function() {
            this.ticketService.getTicketSettings().then((res) => {
                this.ticketSettings = res.data
            })
        },

        confirmDelete: function() {
            let binding = {
                headingText: 'Confirm',
                bodyText: 'Are you sure you want to delete this ticket?  This cannot be undone.',
            };
            this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
                if (res) {
                    this.ticketService.deleteTicket(this.ourTicket.id).then(() => this.$emit('back'));
                }
            });
        },
    },

    computed: {
        snoozeOptions: function() {
            let result = [];

            let now = HDateTime.now();
            let hourOfDay = now.get('hour');
            let dayOfWeek = now.get('weekday');

            if (hourOfDay < 9) {
                result.push({ label: 'Later today', date: now.set({ hour: 12, minute: 0, second: 0, millisecond: 0 }) });
            }

            if (hourOfDay < 14) {
                result.push({ label: 'This afternoon', date: now.set({ hour: 15, minute: 0, second: 0, millisecond: 0 }) });
            }

            if (hourOfDay < 17) {
                result.push({ label: 'This evening', date: now.set({ hour: 18, minute: 0, second: 0, millisecond: 0 }) });
            }

            result.push({
                label: 'Tomorrow',
                date: now.plus({ day: 1 }).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
            });

            if (dayOfWeek < 3) {
                result.push({
                    label: 'Later this week',
                    date: now.set({ weekday: 5, hour: 8, minute: 0, second: 0, millisecond: 0 }),
                });
            }

            if (dayOfWeek < 6) {
                result.push({
                    label: 'This weekend',
                    date: now.set({ weekday: 6, hour: 8, minute: 0, second: 0, millisecond: 0 }),
                });
            }

            result.push({
                label: 'Next week',
                date: now.plus({ week: 1 }).set({ weekday: 1, hour: 8, minute: 0, second: 0, millisecond: 0 }),
            });

            return result;
        },
    }
}