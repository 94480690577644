import HttpClient from '@/services/HttpClient';

export default class UserNavSettingsService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getUserNavSettings() {
		return this.httpClient
			.get('/userNavSettings')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateUserNavSettings(settings) {
		return this.httpClient
			.post(`/userNavSettings`, settings)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
