<template>
	<v-menu :close-on-click="true" :close-on-content-click="false" offset-y v-model="ticketMenu">
		<template v-slot:activator="{ on }">
			<div v-on="on" class="pointer">
				<div v-if="linkedTicket">
					<span v-if="linkedTicket.ticket.ticketNumber">{{linkedTicket.ticket.ticketNumber}}: </span>{{ truncateString(linkedTicket.ticket.subject,30) }} &nbsp;<span class="status">{{ linkedTicket.ticket.status }}</span>
					<v-icon class="ml-2" small @click.stop="openTicket(linkedTicket)">open_in_new</v-icon>
				</div>
				<div v-else>--</div>
			</div>
		</template>
		<div class="column-format pa-2" style="background-color: var(--v-white-base)">
			<div class="text-left column-format show-scrollbar" style="max-height: 300px; overflow-y: scroll">
				<div
					@click="
						ticketId = ticket.id;
						ticketMenu = false;
					"
					v-for="ticket in tickets"
					:key="ticket.id"
					class="row-format align-center px-3 py-2 pointer hover-gray"
					style="border-bottom: 1px solid var(--v-gray_30-base)"
				>
					<div class="brand-medium">
						<span v-if="ticket.ticketNumber">{{ticket.ticketNumber}}: </span>{{ ticket.subject }} <span class="status">{{ ticket.status }}</span>
					</div>
				</div>
				<div
					@click="
						ticketId = null;
						ticketMenu = false;
					"
					class="row-format align-center px-3 py-2 pointer hover-gray"
					style="border-bottom: 1px solid var(--v-gray_30-base)"
				>
					<div class="brand-medium row-format align-center gap-1">
						<v-icon class="material-symbols-rounded" size="24">link_off</v-icon>
						<div>Unlink ticket</div>
					</div>
				</div>
			</div>
		</div>
	</v-menu>
</template>

<script>
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';
	import TicketDetail from '@/modules/communicator/inbox/tickets/TicketDetail';

	export default {
		name: 'LinkedTicket',

		props: ['deliverable', 'value'],

		components: {},

		data: function() {
			return {
				ticketId: this.value,
				ticketService: new CommunicatorTicketService(),
				ticketMenu: false,
				linkedTicket: null,
				tickets: [],
			};
		},

		mounted() {
			this.getLinkedTicket();
		},

		beforeDestroy() {},

		methods: {
			truncateString: function(str,length=10) {
				if (str.length > length) {
					return str.substring(0, length) + '...';
				} else {
					return str;
				}
			},

			getTickets: function() {
				this.ticketService.getTickets(null, null, true, this.deliverable.clientId).then((res) => {
					this.tickets.splice(0);
					this.tickets.push(...res.data);
				});
			},

			openTicket: function(linkedTicket) {
				let binding = {
					id: linkedTicket.ticket.id,
				};
				this.$store.state.globalModalController.openModal(TicketDetail, binding).then((res) => {
					if (res) {
						console.log(res);
						this.linkedTicket.ticket = res;
					}
				});
			},

			getLinkedTicket: function() {
				if (this.ticketId) {
					this.ticketService
						.getTicketDetails(this.ticketId)
						.then((res) => {
							this.linkedTicket = res.data;
						})
						.catch((err) => console.log(err));
				} else {
					this.linkedTicket = null;
				}
			},
		},

		watch: {
			ticketMenu: function() {
				this.getTickets();
			},

			value: function() {
				this.ticketId = this.value;
			},

			ticketId: function() {
				this.$emit('input', this.ticketId);
				this.getLinkedTicket();
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.status {
		background-color: var(--v-accent-base);
		border-radius: 4px;
		padding: 0px 4px;
		color: var(--v-black-base);
		font-weight: 500;
	}
</style>
