var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.schema, function(item, index) {
      return _c(
        "div",
        { key: item.id },
        [
          _c("item-renderer", {
            ref: "item",
            refInFor: true,
            attrs: {
              index: index,
              item: item,
              styles: _vm.styles,
              disabled: true,
              "account-logo": _vm.accountLogo,
              active: false
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }