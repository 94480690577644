<template>
	<div id="edit-fee-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">
				{{ $t('invoice.details.item-price-modal.' + this.type + '.heading') }}
			</div>


			<template v-if="invoiceItem.type !== 'DEPOSIT' && invoiceItem.type !== 'DELIVERABLE'">
				<div class="modal-subtitle" v-html="$t('invoice.details.item-price-modal.' + this.type + '.subheading')" />
				<div class="h-outline mb-2 d-flex justify-start align-center pa-2">
					<v-simple-checkbox v-model="updateProjectToo" dense />
					<label class="font-14">{{ $t('invoice.details.item-price-modal.' + type + '.update-project-terms') }}</label>
				</div>

				<div class="text-left  font-14">
					<span class="brand-semibold gray_80--text"
						>{{ $t('invoice.details.item-price-modal.' + this.type + '.rate-type') }}:
					</span>
					<span class="brand-semilight gray_90--text">{{ $t('invoice.details.item-type.' + invoiceItem.type) }}</span>
				</div>
				<div v-if="type === 'project'" class="text-left font-12 gray_80--text">
					{{ $t('invoice.details.item-price-modal.project.rate-type-notice') }}
				</div>
			</template>
			<div class="height-control" v-if="invoiceItem.type === 'PROJECT'">
				<v-text-field
					class="h-outline"
					persistent-placeholder
					hide-details
					:label="$t('invoice.details.item-price-modal.' + this.type + '.qty')"
					type="number"
					min="0"
					v-model="invoiceItem.qty"
					@keyup.enter="handleSave"
				/>
			</div>
			<div class="height-control">
				<v-text-field
					class="h-outline"
					persistent-placeholder
					hide-details
					:label="$t('invoice.details.item-price-modal.' + this.type + '.fee')"
					:prefix="currencySymbol"
					type="number"
					min="0"
					v-model="invoiceItem.price"
					@keyup.enter="handleSave"
				/>
			</div>
		</div>

		<div class="modal-footer">
			<v-btn class="px-16 primary-action" @click="handleSave">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import ProjectService from '@/modules/projects/ProjectService';
	// import HPillSelector from '@/components/HPillSelector';
	import jsonPatch from 'fast-json-patch';
	// import AccountService from '@/modules/account/AccountService';

	export default {
		name: 'ItemPriceModal',

		props: {
			invoiceItem: { type: Object, required: true },
			invoice: { type: Object, required: true }
		},

		components: {},

		data: function() {
			return {
				projectService: new ProjectService(),
				updateProjectToo: false,
				project: null,
				projectId: null,
			};
		},

		mounted() {
			console.log(this.invoiceItem);
			this.projectId = this.invoiceItem.projectId;
			if(this.projectId){
				this.getProject();
			}
		},

		beforeDestroy() {},

		methods: {
			handleSave() {
				if (this.updateProjectToo) {
					this.patchProject();
				}
				this.$emit('close');
			},
			patchProject() {
				this.project.feeSchedule.amount = this.invoiceItem.price;
				let patch = jsonPatch.generate(this.observer, true);
				if (patch.length > 0) {
					this.projectService
						.patchProject(this.projectId, patch)
						.then(() => {
							// this.project = res.data;
							// this.observer = jsonPatch.observe(this.project);
							this.notifySuccess();
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
				}
			},
			getProject() {
				this.projectService
					.getProjectDetail(this.projectId)
					.then((res) => {
						this.project = res.data;
						this.observer = jsonPatch.observe(this.project);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.message.data);
					});
			},

			notifySuccess: function() {
				this.$store.commit('success', 'Project settings saved.');
			},
		},
		computed: {
			currencySymbol: function(){
				return this.$formatters.currencySymbol(this.invoice.currency);
			},

			type() {
				console.log(this.invoiceItem);
				if (this.invoiceItem.type === 'DEPOSIT') {
					return 'deposit';
				}
				if (this.invoiceItem.type === 'PROJECT') {
					return 'project';
				}
				return 'default';
			},
		},
	};
</script>

<style lang="scss">
	#edit-fee-modal {
		max-width: 400px;
		background-color: var(--v-white-base);
		//.height-control {
		//	.v-input {
		//		margin: 0;
		//		.v-input__control {
		//			margin-bottom: 0;
		//		}
		//	}
		//	height: 64px;
		//}
	}
</style>
