import HttpClient from '@/services/HttpClient';

export default class CalendarService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getCalendarEvents(start, end, eventTypes=null, useTaskStartDates=true) {
		return this.httpClient
			.get('/dashboard/calendar/events',{
				params: {
					start: start,
					end: end,
					version: 4,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					eventTypes: eventTypes,
					useTaskStartDates: useTaskStartDates
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getIcsLink(eventTypes=null) {
		return this.httpClient
			.get('/dashboard/calendar/publish',{
				params: {
					eventTypes: eventTypes
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
