<template>
	<div>
		<div class="row-format" style="gap: 12px">
			<v-text-field
				v-model="filter"
				label="Number contains"
				persistent-placeholder
				hide-details
				class="h-outline"
			></v-text-field>
			<v-btn class="primary-action mt-1" style="min-height: 52px" @click="performSearch()">
				<v-icon color="primary">search</v-icon>
				Search numbers
			</v-btn>
		</div>
		<v-data-table :items="numbers" :headers="headers" :items-per-page="5" v-if="numbers.length">
			<template v-slot:item.action="{ item }">
				<v-btn class="primary-action" @click="confirmSelection(item)">Select number</v-btn>
			</template>

			<template v-slot:item.capabilities.voice="{ item }">
				{{ item.capabilities.voice ? 'Yes' : 'No' }}
			</template>

			<template v-slot:item.capabilities.sms="{ item }">
				{{ item.capabilities.sms ? 'Yes' : 'No' }}
			</template>

			<template v-slot:item.capabilities.mms="{ item }">
				{{ item.capabilities.mms ? 'Yes' : 'No' }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import ConfirmModal from '@/components/ConfirmModal';
	import TwilioService from '@/modules/communicator/realtime/twilio/TwilioService';

	export default {
		name: 'TwilioNumbers',

		props: ['twilioAccount'],

		components: {},

		data: function() {
			return {
				twilioService: new TwilioService(),
				filter: null,
				numbers: [],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			notAvailableOnTrial: function() {
				let binding = {
					headingText: 'Subscription required',
					bodyText: `This feature is not available on the free trial.  Please subscribe to reserve your business phone number.`,
					hideNo: true,
					yesText: 'OK',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding);
			},

			confirmSelection: function(number) {
				if (this.$store.getters.isInTrial) {
					this.notAvailableOnTrial();
					return;
				}

				let binding = {
					headingText: 'Please confirm',
					bodyText: `Are you sure you want to reserve ${number.phoneNumber.endpoint} as your new business phone line?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.orderNumber(number.phoneNumber.endpoint);
					}
				});
			},

			orderNumber: function(number) {
				this.$store.commit('startLoading');
				this.twilioService
					.tnOrder(number)
					.then((res) => {
						this.$store.state.communicatorSubscription = res.data;
						this.$store.commit('stopLoading');
						this.$store.commit('success', 'Your new business phone line is now active!');
						this.$emit('order-complete');
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},

			performSearch: function() {
				this.numbers.splice(0);
				this.twilioService
					.tnSearch(this.filter)
					.then((res) => {
						this.numbers.push(...res.data);
						if (this.numbers.length === 0) {
							this.$store.commit('warn', 'No phone numbers matched your search criteria.');
						}
					})
					.catch((err) => {
						this.$store.commit('error', 'Error searching number database: [' + err.response.data.message + ']');
					});
			},
		},

		computed: {
			headers: function() {
				let result = [
					{ text: 'Number', value: 'phoneNumber.endpoint' },
					{ text: 'Voice', value: 'capabilities.voice' },
					{ text: 'SMS', value: 'capabilities.sms' },
					{ text: 'MMS', value: 'capabilities.mms' },
					{ text: '', value: 'action', align: 'right' },
				];

				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
