<template>
	<v-container
		fluid
		class="pa-0 ma-0"
		@click="$emit('click', $event)"
		:style="`min-height: ${item.minHeight}px; ${background}`"
		:id="`container-${item.id}`"
	>
		<v-row class="pa-0 ma-0">
			<v-col
				:cols="cols"
				v-for="(column, colIndex) in filteredColumns"
				:key="column.id"
				class="column-format"
				:style="
					`width:${width}%; padding-left: ${padding(column.pl)}px; padding-right: ${padding(column.pr)}px; padding-bottom: ${column.pb}px; padding-top: ${column.pt}px; min-height:40px `
				"
				@click="$emit('click', $event)"
			>
				<draggable :list="column.items" :group="item.id" :disabled="!active">
					<div v-for="(item, subIndex) in column.items" :key="item.id">
						<item-renderer
							:ref="`item-${item.id}`"
							:index="`${index}.${colIndex}.${subIndex}`"
							:item="item"
							:agreement="agreement"
							:styles="styles"
							:in-container="true"
							:account-logo="accountLogo"
							:mce-config="mceConfig"
							:active="false"
							:client-mode="clientMode"
							:edit-mode="editMode"
							:tokens="tokens"
							:signer="signer"
							:template-mode="templateMode"
							:page-width="pageWidth"
							:is-mobile="isMobile"
							@click="active ? containerItemClick(colIndex, item, $event) : null"
							@change="containerItemUpdated(colIndex, $event)"
							@signed="containerItemSigned(colIndex, $event)"
							@drag-enabled="$emit('drag-enabled', $event)"
						></item-renderer>
					</div>
				</draggable>

				<v-menu :close-on-content-click="true" :close-on-click="true" v-if="active" bottom>
					<template v-slot:activator="{ on }">
						<v-btn small text v-on="on" color="primary">+ Add element</v-btn>
					</template>

					<div style="background-color: var(--v-white-base)" class="pa-2">
						<div
							v-for="(category, sIndex) in schemaTypes"
							:key="sIndex"
							style="border-bottom: 1px solid var(--v-gray_30-base)"
							class="mb-2 pb-1"
						>
							<div class="text-left">{{ category.label }}</div>
							<div
								v-for="schema in category.types.filter((t) => t.allowInContainer)"
								:key="schema.type"
								class="row-format align-center schema-item pa-1 gap-2"
								@click="addItem(item, colIndex, schema, $event)"
							>
								<v-icon class="material-symbols-rounded" color="secondary" size="24">{{ schema.icon }}</v-icon>
								<div class="column-format text-left">
									<div class="font-14">{{ schema.label }}</div>
									<div class="font-12 mt-n1 font-gray_70">{{ schema.description }}</div>
								</div>
							</div>
						</div>
					</div>
				</v-menu>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import AgreementSchemaTypes from '@/modules/agreements/AgreementSchemaTypes.json';
	import Draggable from 'vuedraggable';
	import ResponsiveMixin from "./ResponsiveMixin";

	export default {
		name: 'Container',

		props: [
			'item',
			'agreement',
			'styles',
			'active',
			'accountLogo',
			'disabled',
			'index',
			'results',
			'mceConfig',
			'clientMode',
			'editMode',
			'tokens',
			'signer',
			'templateMode',
			'pageWidth',
			'isMobile',
		],

		components: {
			Draggable,
			ItemRenderer: () => import('./ItemRenderer'),
		},

		data: function() {
			return {
				editItem: null,
				schemaTypes: AgreementSchemaTypes,
			};
		},

		mounted() {},

		beforeDestroy() {},

		mixins: [ResponsiveMixin],

		methods: {
			async finalize() {
				for (let i = 0; i < this.filteredColumns.length; i++) {
					for (let j = 0; j < this.filteredColumns[i].items.length; j++) {
						let item = this.filteredColumns[i].items[j];
						if (typeof this.$refs['item-' + item.id][0].finalize === 'function') {
							await this.$refs['item-' + item.id][0].finalize();
						}
					}
				}
				return true;
			},

			async reset() {
				for (let i = 0; i < this.filteredColumns.length; i++) {
					for (let j = 0; j < this.filteredColumns[i].items.length; j++) {
						let item = this.filteredColumns[i].items[j];
						if (typeof this.$refs['item-' + item.id][0].reset === 'function') {
							await this.$refs['item-' + item.id][0].reset();
						}
					}
				}
				return true;
			},

			addItem: function(item, colIndex, schema, event) {
				this.$emit('add-container-element', { container: item, column: colIndex, element: schema, clickEvent: event });
			},

			containerItemClick: function(index, item, event) {
				event.stopPropagation();
				this.editItem = item;
				this.$emit('container-item-click', {
					container: this.item,
					item: item,
					index: index,
				});
			},

			containerItemUpdated: function(index, item) {
				this.$emit('container-item-update', {
					container: this.item,
					item: item,
					index: index,
				});
			},

			containerItemSigned: function(index, item) {
				this.$emit('container-item-signed', {
					containerTarget: this.item,
					signature: item.signature,
					signer: item.signer,
					index: index,
				});
			},
		},

		computed: {
			cols: function() {
				if(this.agreement.styles.pageSize === 'RESPONSIVE' && this.isMobile){
					return 12;
				}else {
					return 12 / this.item.columnCount;
				}
			},

			background: function() {
				let result = '';
				if (this.item.backgroundImageUrl) {
					result =
						result +
						`background-image: url(${this.item.backgroundImageUrl}); background-size: ${this.item.backgroundImageSize}; `;
				}
				if (this.item.backgroundColor) {
					result = result + `background-color: ${this.item.backgroundColor}; `;
				}
				return result;
			},

			filteredColumns: function() {
				let columns = [...this.item.columns];
				return columns.splice(0, this.item.columnCount);
			},

			width: function() {
				return Math.round((100 / this.item.columnCount) * 100) / 100;
			},
		},
	};
</script>

<style scoped lang="scss">
	.schema-item {
		border-radius: 4px;
		&:hover {
			background-color: var(--v-gray_10-base);
			cursor: pointer;
		}
	}
</style>
