var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filter
    ? _c(
        "div",
        { staticClass: "row-format gap-3", attrs: { id: "expense-filter" } },
        [
          _c("v-text-field", {
            style: "max-width: 200px; background-color: var(--v-white-base)",
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search...",
              color: "gray_30"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend-inner",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "material-symbols-rounded font-gray_50"
                        },
                        [_vm._v("search")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              95475665
            ),
            model: {
              value: _vm.filter.search,
              callback: function($$v) {
                _vm.$set(_vm.filter, "search", $$v)
              },
              expression: "filter.search"
            }
          }),
          _c(
            "v-menu",
            {
              staticStyle: { "overflow-y": "auto" },
              attrs: {
                "max-height": "80vh",
                "max-width": "250",
                "close-on-content-click": false,
                "content-class": "filter-menu-class"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticStyle: {
                                "min-height": "40px",
                                "background-color": "var(--v-white-base)"
                              },
                              attrs: {
                                elevation: "0",
                                outlined: "",
                                color: "gray_30"
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "span",
                              {
                                class:
                                  "font-14 " +
                                  (!_vm.isDefaultFilter
                                    ? "font-secondary"
                                    : "font-gray_60") +
                                  " brand-medium"
                              },
                              [_vm._v(_vm._s(_vm.filterLabel))]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  "x-small": "",
                                  color: !_vm.isDefaultFilter
                                    ? "secondary"
                                    : "gray_80"
                                }
                              },
                              [_vm._v("$arrowDown")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3356681210
              ),
              model: {
                value: _vm.showMenu,
                callback: function($$v) {
                  _vm.showMenu = $$v
                },
                expression: "showMenu"
              }
            },
            [
              _c("div", { staticClass: "column-format open-menu" }, [
                _c("div", { staticClass: "filter-option-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      on: {
                        click: function($event) {
                          _vm.reimbursableExpanded = !_vm.reimbursableExpanded
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "font-14" }, [
                        _vm._v("Reimbursable status")
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "margin-left": "auto",
                            "margin-top": "1px"
                          },
                          attrs: { small: "" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.reimbursableExpanded
                                ? "$arrowUp"
                                : "$arrowDown"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.reimbursableExpanded
                    ? _c(
                        "div",
                        { staticClass: "my-2" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-2",
                              attrs: { dense: "" },
                              model: {
                                value: _vm.filter.reimbursable,
                                callback: function($$v) {
                                  _vm.$set(_vm.filter, "reimbursable", $$v)
                                },
                                expression: "filter.reimbursable"
                              }
                            },
                            _vm._l(_vm.reimbursableOptions, function(item) {
                              return _c("v-radio", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  "hide-details": "",
                                  label: item.text
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "filter-option-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      on: {
                        click: function($event) {
                          _vm.paidExpanded = !_vm.paidExpanded
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "font-14" }, [
                        _vm._v("Paid status")
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "margin-left": "auto",
                            "margin-top": "1px"
                          },
                          attrs: { small: "" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.paidExpanded ? "$arrowUp" : "$arrowDown")
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.paidExpanded
                    ? _c(
                        "div",
                        { staticClass: "my-2" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-2",
                              attrs: { dense: "" },
                              model: {
                                value: _vm.filter.paid,
                                callback: function($$v) {
                                  _vm.$set(_vm.filter, "paid", $$v)
                                },
                                expression: "filter.paid"
                              }
                            },
                            _vm._l(_vm.paidOptions, function(item) {
                              return _c("v-radio", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  "hide-details": "",
                                  label: item.text
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "filter-option-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      on: {
                        click: function($event) {
                          _vm.clientExpanded = !_vm.clientExpanded
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "font-14" }, [
                        _vm._v(_vm._s(_vm.$t("invoice.filter.client")))
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "margin-left": "auto",
                            "margin-top": "1px"
                          },
                          attrs: { small: "" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.clientExpanded ? "$arrowUp" : "$arrowDown"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.clientExpanded
                    ? _c(
                        "div",
                        [
                          _c("v-checkbox", {
                            staticClass: "mx-0 my-2 pa-0",
                            attrs: {
                              "hide-details": "",
                              indeterminate: _vm.clientsIndeterminate
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "client-label row-format pointer",
                                          staticStyle: {
                                            "align-content": "center"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "tree-label" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "invoice.filter.select-deselect-clients"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1587615642
                            ),
                            model: {
                              value: _vm.clientsSelected,
                              callback: function($$v) {
                                _vm.clientsSelected = $$v
                              },
                              expression: "clientsSelected"
                            }
                          }),
                          _vm._l(_vm.clientList, function(client) {
                            return _c("v-checkbox", {
                              key: client.id,
                              staticClass: "mx-0 my-2 pa-0",
                              attrs: { value: client.id, "hide-details": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "client-label row-format pointer",
                                            staticStyle: {
                                              "align-content": "center"
                                            }
                                          },
                                          [
                                            _c("client-avatar", {
                                              attrs: {
                                                small: true,
                                                left: "",
                                                client: client,
                                                "disable-click": true
                                              }
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "tree-label" },
                                              [_vm._v(_vm._s(client.name))]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.filter.clients,
                                callback: function($$v) {
                                  _vm.$set(_vm.filter, "clients", $$v)
                                },
                                expression: "filter.clients"
                              }
                            })
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ]),
                _c("div", { staticClass: "filter-option-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      on: {
                        click: function($event) {
                          _vm.dateExpanded = !_vm.dateExpanded
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "font-14" }, [
                        _vm._v(_vm._s(_vm.$t("invoice.filter.specify-dates")))
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "margin-left": "auto",
                            "margin-top": "1px"
                          },
                          attrs: { small: "" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.dateExpanded ? "$arrowUp" : "$arrowDown")
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.dateExpanded
                    ? _c(
                        "div",
                        [
                          _c("v-select", {
                            staticStyle: {
                              "font-size": "14px",
                              "text-align": "left !important"
                            },
                            attrs: {
                              items: _vm.dateOptions,
                              "return-object": true,
                              dense: "",
                              "hide-details": "",
                              "single-line": true,
                              "menu-props": { contentClass: "text-left" }
                            },
                            on: { change: _vm.handleDateChange },
                            model: {
                              value: _vm.filter.dateSpecifier,
                              callback: function($$v) {
                                _vm.$set(_vm.filter, "dateSpecifier", $$v)
                              },
                              expression: "filter.dateSpecifier"
                            }
                          }),
                          _vm.showEarliest
                            ? _c(
                                "div",
                                {},
                                [
                                  _c("date-selector", {
                                    attrs: {
                                      "content-class": "my-5",
                                      date: _vm.earliest,
                                      label: "Earliest"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.earliest = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showLatest
                            ? _c(
                                "div",
                                {},
                                [
                                  _c("date-selector", {
                                    attrs: {
                                      "content-class": "my-5",
                                      date: _vm.latest,
                                      label: "Latest"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.latest = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c(
                  "div",
                  { staticClass: "mx-3 mt-3 mb-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "filter-clear mt-2 mb-1",
                        attrs: {
                          text: "",
                          block: "",
                          color: "secondary",
                          elevation: "0"
                        },
                        on: {
                          click: function($event) {
                            return _vm.clearFilter()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("$clear")
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("timetrack.filter.clear-filter"))
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "super-action",
                        attrs: { block: "", elevation: "0" },
                        on: {
                          click: function($event) {
                            _vm.showMenu = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("global.done")))]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }