<template>
	<welcome-container>
		<template v-slot:left>
			<div class="column-format fill-all">
				<div class="column-format flex-grow-1 centered">
					<div class="text-left pl-4 pr-8 column-format gap-5">
						<div class="stratos-medium" style="font-size: 56px; line-height: 64px">{{ heading }}</div>
						<div class="font-20 stratos-semilight">{{ body }}</div>
					</div>
				</div>
				<div class="gap-2 row-format mt-auto mb-6" style="flex-wrap: wrap">
					<v-btn large outlined color="gray_50" width="50" @click="$emit('back')"
					><v-icon color="gray_70">arrow_back</v-icon></v-btn
					>
					<v-btn large color="primary" width="240" @click="$emit('next')"
					><span>Continue</span><v-icon class="ml-2">arrow_forward</v-icon></v-btn
					>
				</div>
			</div>
		</template>
		<template v-slot:right>
			<div
				class="row-format centered fill-all pa-4"
				:style="`
					background-image: url('/images/onboarding/carousel/${image}');
					background-size: contain;
					background-position: center;
					background-origin: content-box;
				`"
			></div>
		</template>
	</welcome-container>
</template>

<script>
	import WelcomeContainer from '@/modules/welcome/WelcomeContainer';

	export default {
		name: 'Slide',

		props: ['heading', 'body', 'image'],

		components: { WelcomeContainer },

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
