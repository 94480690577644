var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.template
    ? _c(
        "div",
        { key: _vm.template.id, staticClass: "form-builder" },
        [
          _c("div", { staticClass: "header-area px-2" }, [
            _c("div", { staticClass: "header-left" }, [
              _c(
                "div",
                { staticClass: "row-format align-center gap-2" },
                [
                  _c("v-text-field", {
                    staticStyle: { "min-width": "344px" },
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "persistent-placeholder": "",
                      placeholder: "Name"
                    },
                    model: {
                      value: _vm.template.name,
                      callback: function($$v) {
                        _vm.$set(_vm.template, "name", $$v)
                      },
                      expression: "template.name"
                    }
                  }),
                  _vm.client
                    ? _c(
                        "div",
                        { staticClass: "row-format gap-2 align-center" },
                        [
                          _c("div", [_vm._v("Send to:")]),
                          _c("client-avatar", {
                            attrs: { small: true, client: _vm.client }
                          }),
                          _c("div", [_vm._v(_vm._s(_vm.client.name))])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "header-right row-format gap-2" },
              [
                _vm.opportunities.length
                  ? _c("v-select", {
                      staticClass: "mr-2",
                      staticStyle: { "max-width": "250px" },
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        solo: "",
                        flat: "",
                        placeholder: "Opportunity",
                        items: _vm.opportunities,
                        "item-value": "id",
                        "item-text": "name",
                        clearable: ""
                      },
                      model: {
                        value: _vm.template.opportunityId,
                        callback: function($$v) {
                          _vm.$set(_vm.template, "opportunityId", $$v)
                        },
                        expression: "template.opportunityId"
                      }
                    })
                  : _vm._e(),
                _c("folder-selector", {
                  attrs: { folders: _vm.folders },
                  model: {
                    value: _vm.template.folder,
                    callback: function($$v) {
                      _vm.$set(_vm.template, "folder", $$v)
                    },
                    expression: "template.folder"
                  }
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    on: {
                      click: function($event) {
                        _vm.shareDialog = true
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.client ? "Send" : "Share") + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action",
                    attrs: { width: "96" },
                    on: {
                      click: function($event) {
                        return _vm.saveTemplate(false)
                      }
                    }
                  },
                  [_vm._v(" Save ")]
                ),
                _c(
                  "v-menu",
                  {
                    attrs: { "close-on-click": true, "nudge-bottom": "30" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(scope) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "menu-activator",
                                    attrs: { icon: "", disabled: false }
                                  },
                                  scope.on
                                ),
                                [
                                  _c("v-icon", { attrs: { size: 20 } }, [
                                    _vm._v(
                                      _vm._s(
                                        scope.value
                                          ? "$arrowUp"
                                          : "$moreHorizontal"
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      181814012
                    )
                  },
                  [
                    !_vm.client
                      ? _c("div", { staticClass: "more-menu" }, [
                          _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: {
                                click: function($event) {
                                  return _vm.viewResults()
                                }
                              }
                            },
                            [_vm._v(" View results ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: {
                                click: function($event) {
                                  return _vm.downloadResults()
                                }
                              }
                            },
                            [_vm._v(" Download results ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: {
                                click: function($event) {
                                  return _vm.duplicateTemplate()
                                }
                              }
                            },
                            [_vm._v(" Duplicate template ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: {
                                click: function($event) {
                                  return _vm.confirmDeleteTemplate()
                                }
                              }
                            },
                            [_vm._v(" Delete ")]
                          )
                        ])
                      : _c("div", { staticClass: "more-menu" }, [
                          _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: {
                                click: function($event) {
                                  return _vm.confirmDeleteTemplate()
                                }
                              }
                            },
                            [_vm._v(" Delete ")]
                          )
                        ])
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.saveTemplate(true)
                      }
                    }
                  },
                  [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "row-format",
              staticStyle: { height: "calc(100% - 50px)" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "column-format pb-3",
                  staticStyle: {
                    width: "350px",
                    "max-width": "350px",
                    "min-width": "350px",
                    "border-right": "1px solid var(--v-gray_30-base)",
                    height: "99%",
                    "max-height": "99%"
                  }
                },
                [
                  _vm.tab === "Elements" && _vm.editItem
                    ? _c(
                        "div",
                        { staticClass: "py-3 px-3" },
                        [
                          _c("config-renderer", {
                            key: _vm.editItem.id,
                            attrs: {
                              item: _vm.editItem,
                              template: _vm.template,
                              schema: _vm.schema
                            },
                            on: {
                              change: function($event) {
                                return _vm.elementUpdated($event)
                              },
                              delete: function($event) {
                                return _vm.confirmDelete()
                              },
                              duplicate: function($event) {
                                return _vm.duplicate()
                              },
                              done: function($event) {
                                return _vm.doneEditingItem()
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "column-format gap-2 show-scrollbar",
                          staticStyle: { height: "100%", "max-height": "100%" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row-format pt-3 pb-2 px-3 gap-3" },
                            [
                              _c(
                                "div",
                                {
                                  class:
                                    "row-format align-center page-link " +
                                    (_vm.tab === "Elements"
                                      ? "page-link-active"
                                      : ""),
                                  on: {
                                    click: function($event) {
                                      return _vm.selectTab("Elements")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: {
                                        size: "22",
                                        color:
                                          _vm.tab === "Elements"
                                            ? "primary"
                                            : "gray_50"
                                      }
                                    },
                                    [_vm._v("dashboard_customize")]
                                  ),
                                  _c("div", { staticClass: "ml-1" }, [
                                    _vm._v("Elements")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  class:
                                    "row-format align-center page-link " +
                                    (_vm.tab === "Settings"
                                      ? "page-link-active"
                                      : ""),
                                  on: {
                                    click: function($event) {
                                      return _vm.selectTab("Settings")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: {
                                        size: "22",
                                        color:
                                          _vm.tab === "Settings"
                                            ? "primary"
                                            : "gray_50"
                                      }
                                    },
                                    [_vm._v("settings")]
                                  ),
                                  _c("div", { staticClass: "ml-1" }, [
                                    _vm._v("Settings")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  class:
                                    "row-format align-center page-link " +
                                    (_vm.tab === "Style"
                                      ? "page-link-active"
                                      : ""),
                                  on: {
                                    click: function($event) {
                                      return _vm.selectTab("Style")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: {
                                        size: "22",
                                        color:
                                          _vm.tab === "Style"
                                            ? "primary"
                                            : "gray_50"
                                      }
                                    },
                                    [_vm._v("palette")]
                                  ),
                                  _c("div", { staticClass: "ml-1" }, [
                                    _vm._v("Style")
                                  ])
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.tab === "Style"
                            ? _c(
                                "div",
                                { staticClass: "px-3" },
                                [
                                  _c("form-style-config", {
                                    attrs: {
                                      styles: _vm.styles,
                                      font: _vm.template.font
                                    },
                                    on: {
                                      "font-updated": function($event) {
                                        return _vm.fontUpdated($event)
                                      },
                                      change: function($event) {
                                        return _vm.updateStyles($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm.tab === "Settings"
                            ? _c(
                                "div",
                                { staticClass: "px-3" },
                                [
                                  _c("form-settings-config", {
                                    attrs: { template: _vm.template },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleSettingsUpdate($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "show-scrollbar",
                                  staticStyle: {
                                    "max-height": "calc(100% - 60px)"
                                  }
                                },
                                _vm._l(_vm.schemaTypes, function(
                                  category,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "mb-2 pb-1 px-3",
                                      staticStyle: {
                                        "border-bottom":
                                          "1px solid var(--v-gray_30-base)"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "text-left" }, [
                                        _vm._v(_vm._s(category.label))
                                      ]),
                                      _c(
                                        "draggable",
                                        {
                                          attrs: {
                                            list: category.types,
                                            group: {
                                              name: "elements",
                                              pull: "clone",
                                              put: false
                                            },
                                            clone: _vm.cloneElement,
                                            sort: false
                                          }
                                        },
                                        _vm._l(category.types, function(
                                          schema
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: schema.type,
                                              staticClass:
                                                "row-format align-center schema-item pa-1 gap-2"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "material-symbols-rounded",
                                                  attrs: {
                                                    color: "black",
                                                    size: "24"
                                                  }
                                                },
                                                [_vm._v(_vm._s(schema.icon))]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "column-format text-left"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "font-14" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(schema.label)
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-12 mt-n1 font-gray_70"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          schema.description
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                        ]
                      )
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "pa-6 column-format align-center show-scrollbar main-form",
                  style:
                    "width:100%; height:100%; max-height: 100%; --form-font-size: " +
                    _vm.styles.fontSize +
                    "px; --font-color:" +
                    _vm.styles.fontColor +
                    ";  --form-input-color: " +
                    _vm.styles.formInputColor +
                    "; font-family: " +
                    _vm.template.font +
                    "; color: " +
                    _vm.styles.fontColor +
                    "; background-color: " +
                    _vm.styles.backgroundColor
                },
                [
                  _c(
                    "div",
                    {
                      style:
                        "width:100%; min-width: 300px; max-width: " +
                        _vm.styles.maxWidth +
                        "px"
                    },
                    [
                      _c(
                        "draggable",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            list: _vm.schema,
                            group: "elements",
                            clone: _vm.cloneElement,
                            emptyInsertThreshold: 1000
                          },
                          on: { change: _vm.changed }
                        },
                        _vm._l(_vm.schema, function(item) {
                          return _c(
                            "div",
                            { key: item.id },
                            [
                              _c("item-renderer", {
                                attrs: {
                                  item: item,
                                  styles: _vm.styles,
                                  "button-props": _vm.buttonProps,
                                  font: _vm.template.font,
                                  disabled: true,
                                  "account-logo": _vm.accountLogo,
                                  results: [],
                                  builder: true,
                                  active:
                                    item.id === _vm.editItemId ||
                                    item.id === _vm.editContainerId
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleClick(item)
                                  },
                                  change: function($event) {
                                    return _vm.elementUpdated($event)
                                  },
                                  delete: function($event) {
                                    return _vm.confirmDelete()
                                  },
                                  "add-container-element": function($event) {
                                    return _vm.addContainerElement($event)
                                  },
                                  "container-item-click": function($event) {
                                    return _vm.containerItemClicked($event)
                                  },
                                  "container-item-update": function($event) {
                                    return _vm.containerItemUpdated($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "row-format gap-2 pt-2 px-2",
                          style:
                            "width: 100%; justify-content: " + _vm.justification
                        },
                        [
                          _vm.hasPages
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "elevation-0",
                                  style:
                                    "font-size: var(--form-font-size) !important; " +
                                    (_vm.buttonProps.block
                                      ? "width: 49%!important;"
                                      : ""),
                                  attrs: {
                                    color: _vm.styles.buttonColor,
                                    "x-small": _vm.buttonProps.xSmall,
                                    small: _vm.buttonProps.small,
                                    large: _vm.buttonProps.large,
                                    "x-large": _vm.buttonProps.xLarge
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        color: _vm.styles.buttonTextColor
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.styles.prevPageIcon))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      style:
                                        "font-family: " +
                                        _vm.template.font +
                                        "; ; text-transform: none; color: " +
                                        _vm.styles.buttonTextColor
                                    },
                                    [_vm._v(_vm._s(_vm.styles.prevPageText))]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "elevation-0",
                              style:
                                "font-size: var(--form-font-size) !important; " +
                                (_vm.hasPages && _vm.buttonProps.block
                                  ? "width: 49%!important;"
                                  : ""),
                              attrs: {
                                color: _vm.styles.buttonColor,
                                "x-small": _vm.buttonProps.xSmall,
                                small: _vm.buttonProps.small,
                                large: _vm.buttonProps.large,
                                "x-large": _vm.buttonProps.xLarge,
                                block: !_vm.hasPages && _vm.buttonProps.block
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  style:
                                    "font-family: " +
                                    _vm.template.font +
                                    "; ; text-transform: none; color: " +
                                    _vm.styles.buttonTextColor
                                },
                                [_vm._v(_vm._s(_vm.styles.submitText))]
                              ),
                              _vm.styles.submitIcon
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "ml-1 material-symbols-rounded",
                                      attrs: {
                                        color: _vm.$vuetify.theme.dark
                                          ? "black"
                                          : _vm.styles.buttonTextColor
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.styles.submitIcon))]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "fill-height fill-width",
                        staticStyle: { height: "300px" },
                        on: {
                          click: function($event) {
                            _vm.editItem = null
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _vm.shareDialog
            ? _c("basic-modal", {
                attrs: {
                  dialog: _vm.shareDialog,
                  persistent: true,
                  "retain-focus": false,
                  "max-width": 684
                },
                on: {
                  close: function($event) {
                    _vm.shareDialog = false
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.client
                            ? _c("share-send-widget", {
                                attrs: {
                                  "client-id": _vm.client.id,
                                  "discovery-template": _vm.template,
                                  "mce-config-text": _vm.mceConfigText,
                                  "open-state": _vm.shareDialog
                                },
                                on: {
                                  alert: function($event) {
                                    return scope.setAlert($event)
                                  },
                                  cancel: function($event) {
                                    _vm.shareDialog = false
                                  },
                                  finalize: function($event) {
                                    _vm.shareDialog = false
                                  },
                                  "discovery-sent": function($event) {
                                    return _vm.handleDiscoverySent()
                                  }
                                }
                              })
                            : _c("share-form", {
                                attrs: {
                                  "form-id": _vm.template.uniqueTemplateId,
                                  "full-link": _vm.fullTemplateLink,
                                  "full-link-embed": _vm.fullTemplateLinkEmbed
                                },
                                on: {
                                  close: function($event) {
                                    _vm.shareDialog = false
                                  }
                                }
                              })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  572367073
                )
              })
            : _vm._e(),
          _vm.resultsDialog
            ? _c(
                "basic-modal",
                {
                  attrs: {
                    dialog: _vm.resultsDialog,
                    persistent: false,
                    "max-width": 900
                  },
                  on: {
                    close: function($event) {
                      _vm.resultsDialog = false
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "pa-3 column-format" },
                    [
                      _c("div", { staticClass: "row-format mx-2" }, [
                        _c(
                          "div",
                          { staticClass: "ml-auto row-format gap-2" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "pointer",
                                on: {
                                  click: function($event) {
                                    _vm.resultsDialog = false
                                  }
                                }
                              },
                              [_vm._v("close")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("form-submissions-table", {
                        attrs: { "template-id": _vm.template.id }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }