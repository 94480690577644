<template>
	<div>
		<v-btn class="secondary-action" @click="openModal()">
			<div class="iconBlock d-flex align-center">
				<v-icon size="14">info</v-icon>
				<div class="unreadIndicator" v-if="hasUnread"></div>
			</div>
			<div class="pl-1 body-12">
				<span class="navItem mr-1">{{ $t('invoice.statuses.' + invoice.status) }}</span>
				<span class="mr-1">&middot;</span>
				<span class="statusItem">{{ $DateTime.humanDateTime(invoice.statusTimestamp) }}</span>
			</div>
		</v-btn>

		<basic-modal :dialog="modalIsOpen" :persistent="false" :max-width="445" @close="closeModal()">
			<div id="invoice-events-modal">
				<v-btn icon class="close-dialog" @click="closeModal()">
					<v-icon>$close</v-icon>
				</v-btn>

				<div class="modal-padding">
					<div class="modal-title">{{ $t('global.activity') }}</div>
					<p class="modal-subtitle">
						{{ $t('invoice.invoice-number-appended', { invoiceNumber: invoice.invoiceNumber }) }}
					</p>

					<div v-for="event in formattedList" :key="event.timestamp" id="events-wrapper">
						<div class="event-wrapper">
							<div class="timestamp">
								<v-icon size="12">$clock</v-icon>
								{{ $DateTime.humanDateTime(event.timestamp) }}
							</div>
							<div v-for="(note, i) in event.events" :key="i" class="event">
								<div>{{ note }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</basic-modal>
	</div>
</template>

<script>
	// import modal from '@bit/hecticapp.common.modal';
	// import { cloneDeep } from 'lodash';
	import BasicModal from '@/components/BasicModal';

	export default {
		name: 'StatusMenu',

		props: ['invoice'],

		components: { BasicModal },

		data: function() {
			return {
				modalIsOpen: false,
				lastReadDate: null,
				viewedTimestamps: null,
			};
		},

		mounted() {
			this.viewedTimestamps = this.invoice.viewedTimestamps;
			this.lastReadDate = this.getLastViewedDate();
			this.$store.state.eventBus.$on(this.getChannelName(), this.newStatusEvent);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(this.getChannelName(), this.newStatusEvent);
		},

		methods: {
			openModal() {
				console.log('open sesame');
				this.modalIsOpen = true;
				this.lastReadDate = this.$DateTime.local();
			},

			closeModal() {
				this.modalIsOpen = false;
				this.lastReadDate = new this.$DateTime.local();
			},

			getLastViewedDate: function() {
				let timestamp = this.viewedTimestamps['user-' + this.$store.state.loggedInUser.userId];
				if (timestamp) {
					return this.$DateTime.fromISO(timestamp);
				} else {
					return null;
				}
			},

			newStatusEvent: function(event) {
				if (event.userMetadata === 'EventLog') {
					event.message.timestamp = this.$DateTime.local().toISO();
					this.invoice.events.push(event.message);
				}
			},

			getChannelName() {
				return this.$store.getters.getMessageChannelPrefix + '.i-' + this.invoice.id;
			},
		},

		computed: {
			hasUnread: function() {
				if (!this.modalIsOpen && this.lastEvent !== '') {
					let event = this.invoice.events[this.invoice.events.length - 1];
					if (this.lastReadDate === null || this.$DateTime.fromISO(event.timestamp) > this.lastReadDate) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			},

			lastEvent: function() {
				if (this.invoice.events && this.invoice.events.length > 0) {
					let event = this.invoice.events[this.invoice.events.length - 1];
					return event.events[0] + ' - ' + this.$DateTime.humanDate(event.timestamp);
				} else {
					return '';
				}
			},

			lastActivityTimestamp() {
				// let eventTimestamp = this.$DateTime.fromISO(this.invoice.events[this.invoice.events.length - 1]);
				let statusTimestamp = this.$DateTime.fromISO(this.invoice.statusTimestamp).toLocal();
				// if (eventTimestamp > statusTimestamp) return eventTimestamp;
				return statusTimestamp;
			},
			formattedList: function() {
				let events = [...this.invoice.events];
				let result = [];
				events = events.reverse();

				for (let i = 0; i < events.length; i++) {
					let event = events[i];
					result.push(event);
				}

				return result;
			},
		},
	};
</script>

<style lang="scss">
	#invoice-events-modal {
		width: 300px;
		#events-wrapper {
			.event-wrapper {
				text-align: left;
				margin-top: 8px;
				.timestamp {
					color: var(--v-gray_70-base);
					font-size: 12px;
					.v-icon {
						margin-top: -3px;
					}
				}
				.event {
					font-size: 14px;
				}
			}
		}
	}
</style>
