<template>
	<div>
    <v-text-field
        v-model="item.fieldName"
        hide-details
        dense
        persistent-placeholder
        outlined
        label="Mapping key"
        @input="updated"
    ></v-text-field>
	</div>
</template>

<script>
	export default {
		name: 'TextConfig',

		props: ['item'],

		components: {

		},

		data: function() {
			return {
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
      updated: function() {
        this.$emit('change', this.element);
      },
    },

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
