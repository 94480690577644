var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.$vuetify.breakpoint.smAndDown ? "column-format" : "row-format"
    },
    [
      _c(
        "div",
        { staticClass: "left-box mr-4 mb-6" },
        [
          _c("div", { staticClass: "font-24 brand-medium mb-6" }, [
            _vm._v(_vm._s(_vm.header))
          ]),
          _c("div", { staticClass: "mb-6" }, [_vm._v(_vm._s(_vm.body))]),
          _c(
            "v-btn",
            {
              staticClass: "super-action",
              on: {
                click: function($event) {
                  return _vm.$emit("cta-clicked")
                }
              }
            },
            [_vm._v(_vm._s(_vm.cta))]
          )
        ],
        1
      ),
      _vm.videoId
        ? _c(
            "div",
            { staticClass: "right-box ml-4 mb-6" },
            [
              _c(
                "v-img",
                {
                  staticClass: "pointer",
                  staticStyle: { "border-radius": "4px" },
                  attrs: {
                    src:
                      "https://img.youtube.com/vi/" +
                      _vm.videoId +
                      "/mqdefault.jpg"
                  },
                  on: {
                    click: function($event) {
                      _vm.videoOpen = true
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row-format centered",
                      staticStyle: { width: "100%", height: "100%" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "play-button" },
                        [
                          _c("v-icon", { staticClass: "ml-1" }, [
                            _vm._v("$play")
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c("div", { staticClass: "font-18 brand-medium my-3" }, [
                _vm._v(_vm._s(_vm.videoHeader))
              ]),
              _c("div", { staticClass: "font-14" }, [
                _vm._v(_vm._s(_vm.videoBody))
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "primary-action mt-4",
                  staticStyle: { "justify-self": "end" },
                  attrs: { width: "144" },
                  on: {
                    click: function($event) {
                      _vm.videoOpen = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.videoCta))]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.videoOpen
        ? _c(
            "v-dialog",
            {
              attrs: { width: "840" },
              model: {
                value: _vm.videoOpen,
                callback: function($$v) {
                  _vm.videoOpen = $$v
                },
                expression: "videoOpen"
              }
            },
            [
              _c("youtube", {
                staticStyle: {
                  "background-color": "var(--v-black-base)",
                  "padding-top": "4px"
                },
                attrs: {
                  "player-vars": { autoplay: 1 },
                  id: "player",
                  "video-id": _vm.videoId,
                  "player-width": 840,
                  "player-height": 462
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }