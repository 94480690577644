var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "500px" } }, [
    _c(
      "div",
      { staticClass: "modal-padding" },
      [
        _c(
          "v-form",
          {
            ref: "productForm",
            attrs: { "lazy-validation": "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "row-format mb-2 align-center" },
              [
                _c("div", { staticClass: "brand-medium font-18" }, [
                  _vm._v("Product detail")
                ]),
                _c(
                  "v-icon",
                  {
                    staticClass: "ml-auto pointer",
                    attrs: { size: "20" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("result")
                      }
                    }
                  },
                  [_vm._v("$close")]
                )
              ],
              1
            ),
            _c("v-text-field", {
              staticClass: "h-outline",
              attrs: {
                label: _vm.$t("stripe.subscription.product-name"),
                "hide-details": "",
                "persistent-placeholder": "",
                rules: _vm.inputRequired
              },
              model: {
                value: _vm.local.name,
                callback: function($$v) {
                  _vm.$set(_vm.local, "name", $$v)
                },
                expression: "local.name"
              }
            }),
            _c("v-text-field", {
              staticClass: "h-outline",
              attrs: {
                label: _vm.$t("stripe.subscription.product-desc"),
                "hide-details": "",
                "persistent-placeholder": "",
                rules: _vm.inputRequired
              },
              model: {
                value: _vm.local.description,
                callback: function($$v) {
                  _vm.$set(_vm.local, "description", $$v)
                },
                expression: "local.description"
              }
            }),
            _c("v-switch", {
              staticClass: "ml-1",
              staticStyle: { "margin-top": "0px" },
              attrs: {
                inset: "",
                flat: "",
                dense: "",
                "hide-details": "",
                label: _vm.$t("stripe.subscription.product-active")
              },
              model: {
                value: _vm.local.active,
                callback: function($$v) {
                  _vm.$set(_vm.local, "active", $$v)
                },
                expression: "local.active"
              }
            }),
            _c(
              "div",
              { staticClass: "my-4" },
              [
                _c(
                  "div",
                  { staticClass: "row-format", staticStyle: { width: "100%" } },
                  [
                    _c("div", { staticClass: "text-left font-gray_70" }, [
                      _vm._v(
                        _vm._s(_vm.$t("stripe.subscription.product-price"))
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "text-right ml-auto font-gray_70" },
                      [_vm._v(_vm._s(_vm.$t("global.enabled")))]
                    )
                  ]
                ),
                _vm._l(_vm.prices, function(price) {
                  return _c(
                    "div",
                    {
                      key: price.id,
                      staticClass: "row-format align-center mb-1"
                    },
                    [
                      price.id
                        ? _c(
                            "div",
                            {
                              staticClass: "row-format align-center",
                              staticStyle: {
                                width: "100%",
                                "border-bottom":
                                  "1px solid var(--v-gray_30-base)"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  style: !price.active
                                    ? "text-decoration: line-through"
                                    : ""
                                },
                                [_vm._v(_vm._s(_vm.formatPrice(price)))]
                              ),
                              _c(
                                "div",
                                { staticClass: "ml-auto mb-1" },
                                [
                                  _c("v-checkbox", {
                                    attrs: { "hide-details": "", dense: "" },
                                    model: {
                                      value: price.active,
                                      callback: function($$v) {
                                        _vm.$set(price, "active", $$v)
                                      },
                                      expression: "price.active"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "row-format align-center" },
                            [
                              _c("v-text-field", {
                                staticClass: "mr-2",
                                staticStyle: { width: "100px" },
                                attrs: {
                                  prefix: _vm.currencySymbol(price.currency),
                                  type: "number",
                                  "hide-details": "",
                                  dense: "",
                                  outlined: ""
                                },
                                model: {
                                  value: price.amount,
                                  callback: function($$v) {
                                    _vm.$set(price, "amount", $$v)
                                  },
                                  expression: "price.amount"
                                }
                              }),
                              _c("v-select", {
                                staticClass: "mr-2",
                                staticStyle: { width: "100px" },
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  outlined: "",
                                  items: _vm.currencies,
                                  "item-text": "value",
                                  "item-value": "value"
                                },
                                model: {
                                  value: price.currency,
                                  callback: function($$v) {
                                    _vm.$set(price, "currency", $$v)
                                  },
                                  expression: "price.currency"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "mr-2 font-14 font-gray_70" },
                                [_vm._v("Every")]
                              ),
                              _c("v-text-field", {
                                staticClass: "mr-2",
                                staticStyle: { width: "80px" },
                                attrs: {
                                  type: "number",
                                  "hide-details": "",
                                  dense: "",
                                  outlined: ""
                                },
                                model: {
                                  value: price.intervalCount,
                                  callback: function($$v) {
                                    _vm.$set(price, "intervalCount", $$v)
                                  },
                                  expression: "price.intervalCount"
                                }
                              }),
                              _c("v-select", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  outlined: "",
                                  items: _vm.intervals
                                },
                                model: {
                                  value: price.interval,
                                  callback: function($$v) {
                                    _vm.$set(price, "interval", $$v)
                                  },
                                  expression: "price.interval"
                                }
                              })
                            ],
                            1
                          )
                    ]
                  )
                }),
                _c("div", { staticClass: "text-left mt-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "font-primary pointer",
                      on: { click: _vm.newPrice }
                    },
                    [_vm._v("+ New price")]
                  )
                ])
              ],
              2
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "v-btn",
          {
            staticClass: "secondary-action",
            on: {
              click: function($event) {
                return _vm.$emit("result")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("global.cancel")))]
        ),
        _c(
          "v-btn",
          {
            staticClass: "primary-action ml-1",
            attrs: { width: "150" },
            on: {
              click: function($event) {
                return _vm.saveProduct()
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("global.save")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }