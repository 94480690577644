import HttpClient from '@/services/HttpClient';

export default class NativeCalendarService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getUserCalendarEvents(start,end) {
		return this.httpClient
			.get('/calendarEvents',{
				params: {
					start: start,
					end: end,
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getUserCalendarEvent(id){
		return this.httpClient.get(`/calendarEvents/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	createUserCalendarEvent(event){
		return this.httpClient.post(`/calendarEvents/`,event)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	updateUserCalendarEvent(id,event){
		return this.httpClient.put(`/calendarEvents/${id}`,event)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	deleteUserCalendarEvent(id){
		return this.httpClient.delete(`/calendarEvents/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
