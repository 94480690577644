<template>
	<div>

		<div class="row-format mb-3">
			<div class="numberWidget clickable mr-3 column-format"  @click="$emit('clicked','invoiced')">
				<div class="header">Invoiced</div>
				<div class="body">{{ $formatters.dollars(totalInvoiced,false) }}</div>
				<div class="row-format font-14 font-gray_70 gap-2">
					<div v-if="currentDue">Due soon: {{ $formatters.dollars(currentDue,false) }}</div>
					<div v-if="pastDue" style="color: var(--v-error-base)">Past due: {{ $formatters.dollars(pastDue,false) }}</div>
				</div>
			</div>
			<div class="numberWidget clickable" @click="$emit('clicked','received')">
				<div class="header">Received</div>
				<div class="body">{{ $formatters.dollars(totalReceived,false) }}</div>
			</div>
		</div>
		<div class="row-format">
			<div class="splitContainer row-format mr-3">
				<div class="splitWidget clickable" @click="$emit('clicked','otherIncome')">
					<div class="header">Other income</div>
					<div class="body">{{ $formatters.dollars(totalOtherIncome,false) }}</div>
				</div>
				<div class="splitWidget clickable ml-auto" @click="$emit('clicked','expenses')">
					<div class="header">Expenses</div>
					<div class="body">{{ $formatters.dollars(totalExpenses,false) }}</div>
				</div>
			</div>
			<div class="numberWidget">
				<div class="header">Net income</div>
				<div class="body">{{ $formatters.dollars(netIncome,false) }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import HDateTime from "@/modules/utils/HDateTime";

export default {
	name: "MoneyMetrics",

	props: ['clients','invoices','payments','expenses','otherIncome'],

	components: {},

	data: function () {
		return {
			clientHover: null,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		setClientHover: function(name,total){
			this.clientHover = name + ' ' + this.$formatters.dollars(total);
		},

		clearClientHover: function(){
			this.clientHover = null;
		},

		groupBy: function(array, key) {
			return array.reduce((result, currentValue) => {
				(result[currentValue[key]] = result[currentValue[key]] || []).push(
						currentValue
				);
				return result;
			}, {});
		},

		isPastDue: function(invoice){
			if(invoice.amountDue <= 0){
				return false;
			}if(invoice.status !== 'SENT' && invoice.status !== 'PARTIAL'){
				return false;
			}

			if(HDateTime.now() > HDateTime.fromISO(invoice.dateDue)){
				return true;
			}else{
				return false;
			}
		},

		isCurrent: function(invoice){
			if(invoice.amountDue <= 0){
				return false;
			}if(invoice.status !== 'SENT' && invoice.status !== 'PARTIAL'){
				return false;
			}

			if(HDateTime.now() <= HDateTime.fromISO(invoice.dateDue)){
				return true;
			}else{
				return false;
			}
		}
	},

	computed: {
		totalInvoiced: function () {
			let invoiced =  this.invoices.reduce((sum, invoice) => sum + invoice.localTotal, 0);
			return invoiced;
		},

		totalOtherIncome: function(){
			let total = this.otherIncome.reduce((sum,income) => sum + income.localAmount,0);
			return total;
		},

		pastDue: function(){
			let pastDue =  this.invoices.filter(this.isPastDue).reduce((sum, invoice) => sum + invoice.localAmountDue, 0);
			return pastDue;
		},

		currentDue: function(){
			let current =  this.invoices.filter(this.isCurrent).reduce((sum, invoice) => sum + invoice.localAmountDue, 0);
			return current;
		},

		invoicedByClient: function(){
			let grouped = this.groupBy(this.invoices,'clientId');
			let result = []

			for(let g in grouped){
				let group = grouped[g];
				let clientTotal = group.reduce((sum, invoice) => sum + invoice.localTotal, 0);
				let client = this.clients.find(c => c.id === g)

				if(!client){
					client = group[0].clientInfo
				}

				result.push({
					clientId: g,
					clientName: client.name,
					color: client.color,
					total: clientTotal.toFixed(2),
					percentage: ((clientTotal/this.totalInvoiced)*100).toFixed(),
				});
			}
			result.sort((a,b) => a.total - b.total);
			return result;
		},

		totalReceived: function () {
			let payments = this.payments.reduce((sum, invoice) => sum + invoice.localAmount, 0);
			return payments;
		},

		totalExpenses: function () {
			return this.expenses.reduce((sum, expense) => sum + expense.localAmount, 0);
		},

		netIncome: function () {
			return (this.totalOtherIncome + this.totalReceived) - this.totalExpenses;
		},
	},

}
</script>

<style scoped lang="scss">

</style>