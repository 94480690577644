<template>
	<primary-layout>
		<h-header
			:title="$t('nav.invoicesAndExpenses')"
			:add-new-label="isPaid ? $t('accounting.new-linked-account') : null"
			@add-new="linkNewAccount()"
			:pages="pages"
			current-page="accounts"
			@set-page="handleSetPage($event)"
			:border-bottom="true"
		>
		</h-header>

		<v-container fluid class="fill-height align-start">
			<v-row>
				<v-col cols="12" v-if="items.length > 0" class="mt-9">
					<v-data-table :items="items" :headers="headers" id="account-table" :hide-default-footer="true">
						<template v-slot:item.institutionName="{ item }">
							<img v-if="item.institutionLogo" v-bind:src="'data:image/jpeg;base64,' + item.institutionLogo" />
							{{ item.institutionName }}
						</template>

						<template v-slot:item.lastUpdate="{ item }">
							{{ DateTime.fromISO(item.lastUpdate).toLocaleString(DateTime.DATETIME_FULL) }}
						</template>

						<template v-slot:item.consentExpirationTime="{ item }">
							<span v-if="item.consentExpirationTime">{{
									DateTime.fromISO(item.lastUpdate).toLocaleString(DateTime.DATE_FULL)
							}}</span>
							<span v-else>N/A</span>
						</template>

						<template v-slot:item.itemId="{ item }">
							<div style="text-align: right">
								<v-icon class="pointer mr-5" @click="confirmRefresh(item)">refresh</v-icon>
								<v-icon class="pointer" @click="confirmUnlinkAccount(item)">$delete</v-icon>
							</div>
						</template>
					</v-data-table>
				</v-col>
				<v-col cols="12" class="d-flex justify-center flex-column align-center">
					<div class="center-block mt-12" v-if="isReady && !items.length">
						<div class="plaid-tile integration-tile">
							<div class="mt-n10 ml-n4">
								<v-icon size="150" :color="$vuetify.theme.dark ? '#ffffff' : '#111212'">$plaid</v-icon>
							</div>
							<div class="my-4" style="max-width: 800px">
								<p>
									We partner with <a href="https://www.plaid.com" target="_blank">Plaid</a> to make expense
									tracking easy.
								</p>
								<p>
									Connect your Business Checking or Business Credit Card account to automatically import and
									categorize your expenses, making tax prep and financial reporting a breeze.
								</p>

								<div class="mt-6" v-if="isPaid">
									<v-btn class="connect-account" color="primary" @click.prevent="linkNewAccount()">
										Connect account
									</v-btn>
								</div>
							</div>
						</div>
						<div class="how-it-works-tile integration-tile">
							<div class="">
								<img src="/images/accounting/michelle-screen-grab.png" alt="" />
							</div>
							<div class="my-4" style="max-width: 800px">
								<p class="font-24">See how it works</p>
								<p>Follow along as we walk you through connecting your business account.</p>

								<div class="mt-6" v-if="isPaid">
									<v-btn class="connect-account primary-action" @click.prevent="linkNewAccount()">
										Watch the tutorial
									</v-btn>
								</div>
							</div>
						</div>
					</div>

					<div class="upgrade-tile column-format centered pa-2 mt-8" v-if="!isPaid">
						<v-icon>lock</v-icon>
						<div>
							This feature is not available on the free plan.<br />To automate your expense management,
							<router-link to="/subscription">upgrade to Pro.</router-link>
						</div>
					</div>

					<div class="biz-account-tile integration-tile">
						<div>
							<img src="/images/accounting/axos.svg" alt="" />
						</div>
						<div>
							<p>Need a business account?</p>
							<p class="gray_80--text">Check out Axos Bank for a full service online banking experience.</p>

							<p>Visit <a href="https://www.axosbank.com" target="_blank">axosbank.com</a>.</p>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<confirm-dialog
			:dialog="unlinkAccountDialog"
			@confirm="unlinkAccount"
			@close="cancelUnlinkAccount"
			:persistent="false"
			heading-text="Unlink Account?"
			body-text="Are you sure you want to unlink this account?"
		>
		</confirm-dialog>
		<confirm-dialog
			:dialog="refreshAccountDialog"
			@confirm="refreshAccount"
			@close="cancelRefreshAccount"
			:persistent="false"
			heading-text="Refresh Account?"
			body-text="This will allow you to re-authenticate with your banking institution.  Do you want to continue?"
		>
		</confirm-dialog>
	</primary-layout>
</template>

<script>
	import PlaidService from '@/modules/accounting/accounts/PlaidService';
	// import FullColorLogo from '../../../assets/logos/full-color.svg';
	import DateTime from "@/modules/utils/HDateTime";
	import ConfirmDialog from '@/modules/invoices/InvoiceRenderer/ConfirmDialog';
	import PrimaryLayout from '@/components/PrimaryLayout';
	import HHeader from '@/components/HHeader';
	import AccountingMixin from '@/modules/accounting/AccountingMixin';

	export default {
		name: 'PlaidIntegration',

		props: [],

		components: { PrimaryLayout, ConfirmDialog, HHeader },
		mixins: [AccountingMixin],

		data: function () {
			return {
				items: [],
				plaidService: new PlaidService(),
				headers: [
					{ text: 'Institution Name', value: 'institutionName' },
					{ text: 'Last Update', value: 'lastUpdate' },
					{ text: 'Update Message', value: 'lastUpdateMessage' },
					{ text: 'Consent Expiration', value: 'consentExpirationTime' },
					{ text: '', value: 'itemId' },
				],

				plaidHandler: null,
				DateTime:DateTime,
				isReady: false,

				unlinkAccountDialog: false,
				accountToUnlink: null,

				refreshAccountDialog: false,
				accountToRefresh: null,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'accounting-plaid' });
			this.getItems();
		},

		beforeDestroy() {},

		methods: {
			getItems: function () {
				this.plaidService.getItems().then((res) => {
					this.items.splice(0, this.items.length);
					this.items.push(...res.data);
					this.isReady = true;
				});
			},

			linkNewAccount: function (itemId) {
				let url = document.location.protocol + '//' + document.location.host;
				url = url + '/accounting/accounts';

				this.plaidService
					.token(url, itemId)
					.then((res) => {
						this.initializePlaidLink(res.data);
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', err.response.data.message);
					});
			},

			confirmRefresh: function (item) {
				this.refreshAccountDialog = true;
				this.accountToRefresh = item;
			},

			refreshAccount: function () {
				this.linkNewAccount(this.accountToRefresh.itemId);
			},

			cancelRefreshAccount: function () {
				this.refreshAccountDialog = false;
				this.accountToRefresh = null;
			},

			cancelUnlinkAccount: function () {
				this.accountToUnlink = null;
				this.unlinkAccountDialog = false;
			},

			confirmUnlinkAccount: function (item) {
				this.accountToUnlink = item;
				this.unlinkAccountDialog = true;
			},

			unlinkAccount: function () {
				this.plaidService
					.unlinkItem(this.accountToUnlink.itemId)
					.then(() => {
						this.cancelUnlinkAccount();
						this.getItems();
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			initializePlaidLink: function (token) {
				this.plaidHandler = window.Plaid.create({
					token: token,
					onSuccess: this.plaidSuccess,
					onLoad: () => {},
					onExit: this.plaidExit,
					onEvent: this.plaidEvent,
					receivedRedirectUri: null,
				});

				this.plaidHandler.open();
			},

			plaidSuccess: function (token, metadata) {
				console.log('Plaid Success');
				console.log(token);
				console.log(metadata);
				console.log(this.accountToRefresh);

				if (this.accountToRefresh == null) {
					this.plaidService
						.exchange(token)
						.then((res) => {
							this.items.push(res.data);
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
				} else {
					this.cancelRefreshAccount();
					this.$store.commit('success', 'Connected account successfully refreshed.');
				}
			},

			plaidExit: function (err, metadata) {
				this.cancelRefreshAccount();
				console.log('Plaid Exit');
				console.log(err);
				console.log(metadata);
			},

			plaidEvent: function (eventName, metadata) {
				console.log('Plaid Event');
				console.log(eventName);
				console.log(metadata);
			},
		},

		computed: {
			isPaid: function () {
				return this.$store.getters.isPaidAccount;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.center-block {
		width: 652px;
		display: flex;
		justify-content: space-between;
		& > div {
			flex: 0 0 48%;
		}
	}
	.integration-tile {
		border-radius: 4px;
		width: 320px;
		margin-bottom: 12px;
		text-align: left;
		p {
			color: var(--v-gray_90-base);
			font-size: 16px;
		}
	}
	.plaid-tile {
		padding: 24px;
		border: 1px solid var(--v-gray_50-base);
		box-shadow: 0px 1px 0px var(--v-gray_50-base);
	}
	.how-it-works-tile {
		padding: 0 24px;
		p.font-24 {
			font-size: 24px;
		}
	}
	.upgrade-tile {
		background-color: var(--v-gray_20-base);
		width: 652px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
	}
	.biz-account-tile {
		margin-top: 64px;
		background-color: var(--v-gray_20-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		width: 652px;
		display: flex;
		div {
			padding: 24px;
			flex: 0 0 50%;
		}
		div:first-child {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		p {
			font-size: 14px;
			a {
				color: var(--v-primary-base);
			}
		}
	}
	//.block-heading {
	//	align: center;
	//	color: var(--v-black-base);
	//	font-size: 14px;
	//	font-family: stratos-semibold;
	//	font-weight: 600;
	//	letter-spacing: 0.2px;
	//	line-height: 20px;
	//	span {
	//	}
	//	svg {
	//		fill: var(--v-black-base);
	//	}
	//}
	//
	//.empty-state {
	//	border-radius: 8px;
	//	border: 1px solid var(--v-gray_50-base);
	//	background-color: var(--v-gray_20-base);
	//	height: 72px;
	//	display: flex;
	//	align-items: center;
	//	justify-content: center;
	//	.v-icon {
	//		margin-right: 8px;
	//	}
	//}
	//
	//#account-table table {
	//	thead {
	//		th {
	//			cursor: pointer;
	//			font-size: 12px;
	//			line-height: 16px;
	//			text-transform: uppercase;
	//			font-weight: normal;
	//			letter-spacing: 0.4px;
	//			white-space: nowrap;
	//			color: var(--v-schooner_50-base);
	//			svg.v-icon {
	//				margin-left: 10px;
	//				vertical-align: top;
	//				color: var(--v-schooner_50-base);
	//				&.transparent {
	//					color: transparent; // make transparent instead of display:none to control sizing of <th>
	//				}
	//			}
	//		}
	//	}
	//	tbody {
	//		tr {
	//			td {
	//				vertical-align: top;
	//				font-size: 14px;
	//				padding-top: 12px;
	//				padding-bottom: 10px;
	//				line-height: 28px;
	//				letter-spacing: 0.02em;
	//				.avatar {
	//					margin-right: 20px !important;
	//				}
	//				&:hover {
	//				}
	//				.invoice-number {
	//					width: 70px;
	//					text-align: right;
	//				}
	//				.dollars {
	//					width: 80px;
	//					text-align: right;
	//				}
	//			}
	//		}
	//	}
	//}
</style>
