import HttpClient from '@/services/HttpClient';
import store from "@/store";

export default class ExpenseService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getExpenses(earliest, latest) {
		return this.httpClient
			.get('/expenses', {
				params: {
					earliest: earliest,
					latest: latest,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getExpenseCount() {
		return this.httpClient
			.get('/expenses/count')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getExpense(id) {
		return this.httpClient
			.get(`/expenses/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getExpensesByClient(clientId) {
		return this.httpClient
			.get('/expenses', {
				params: {
					clientId: clientId,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getExpensesByProject(projectId,currency) {
		return this.httpClient
			.get('/expenses', {
				params: {
					projectId: projectId,
					currency: currency,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getExpensesByVendor(vendorId) {
		return this.httpClient
			.get('/expenses', {
				params: {
					vendorId: vendorId,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createExpense(expense) {
		return this.httpClient
			.post(`/expenses/`, expense)
			.then((res) => {
				store.state.eventBus.$emit('created','expense')
				return Promise.resolve(res)
			})
			.catch((err) => Promise.reject(err));
	}

	updateExpense(id, expense) {
		return this.httpClient
			.put(`/expenses/${id}`, expense)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchExpense(id, patch) {
		return this.httpClient
			.patch(`/expenses/${id}`, patch)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteExpense(id) {
		return this.httpClient
			.delete(`/expenses/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getCategories(){
		return this.httpClient
			.get(`/expenses/categories`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateCategories(categories){
		return this.httpClient
			.put(`/expenses/categories`,categories)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
