var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-option-box" }, [
    _c(
      "div",
      {
        staticClass: "row-format align-center",
        on: {
          click: function($event) {
            return _vm.toggleExpanded()
          }
        }
      },
      [
        _c("div", { staticClass: "font-14" }, [_vm._v(_vm._s(_vm.label))]),
        !_vm.expanded && _vm.value && _vm.value.length > 0
          ? _c("div", { staticClass: "active-count ml-1" }, [
              _vm._v(_vm._s(_vm.value.length))
            ])
          : _vm._e(),
        _c(
          "v-icon",
          {
            staticStyle: { "margin-left": "auto", "margin-top": "1px" },
            attrs: { small: "" }
          },
          [_vm._v(_vm._s(_vm.expanded ? "$arrowUp" : "$arrowDown"))]
        )
      ],
      1
    ),
    _vm.expanded
      ? _c(
          "div",
          _vm._l(_vm.optionList, function(option) {
            return _c("v-checkbox", {
              key: option.value,
              attrs: { dense: "", "hide-details": "", value: option.value },
              on: { change: _vm.updated },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "font-14 brand-semilight",
                            staticStyle: { "font-size": "14px" }
                          },
                          [_vm._v(_vm._s(option.text))]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.ourValue,
                callback: function($$v) {
                  _vm.ourValue = $$v
                },
                expression: "ourValue"
              }
            })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }