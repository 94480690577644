<template>
	<div class="text-left" @click="$emit('click', $event)" v-if="isConditionMet">
		<div class="form-question">{{ item.question }}<span v-if="item.required" class="font-red brand-medium">*</span> <v-icon v-if="showConditionalHelper" small color="gray_50">account_tree</v-icon></div>
		<v-form @submit.prevent v-model="isValid" ref="form">
			<div v-if="isPhoneInput" :class="classList">
				<div class="v-input__control">
					<div class="v-input__slot" style="padding: 0!important;">
						<div class="v-text-field__slot" style="padding: 0!important;">
							<fieldset  aria-hidden="true" v-if="outlined" :style="`border-radius: 4px!important; ${!this.phoneIsValid ? 'border: 2px solid var(--v-error-base)!important;' : ''}`"><legend style="width: 0px;"><span class="notranslate">&ZeroWidthSpace;</span></legend></fieldset>
							<vue-tel-input
								v-model="textInput"
								:preferred-countries="preferredCountries"
								class="ml-0 custom-phone"
								@input="phoneUpdated"
								:disabled="disabled"
								name="phone"
								autocomplete="phone"
								:inputOptions="{placeholder:item.placeholder}"
							></vue-tel-input>
						</div>
					</div>
				</div>
			</div>
			<v-text-field
				v-else
				hide-details
				dense
				v-model="textInput"
				:placeholder="item.placeholder"
				:outlined="outlined"
				:solo="solo"
				:filled="filled"
				:disabled="disabled"
				class="form-input"
				@input="handleInput()"
				:rules="rules"
				:name="fieldName"
				:autocomplete="autoComplete"
			></v-text-field>
		</v-form>
	</div>
</template>

<script>
	import RendererMixin from '@/modules/forms/v2/schema/RendererMixin';

	export default {
		name: 'TextInput',

		props: ['disabled', 'item', 'styles', 'index', 'results','builder','container','knownData'],

		mixins: [RendererMixin],

		components: {},

		data: function() {
			return {
				textInput: null,
				phoneInput: null,
				isPhoneValid: false,
				doValidate: false,
			};
		},

		mounted() {
      let currentResult = this.getCurrentResult(this.item.id, this.results);

      if (currentResult) {
				this.textInput = currentResult;
				this.phoneInput = currentResult;
			}else if(this.knownData && this.item.schemaMapping){
        let value = this.knownData[this.item.schemaMapping];
        this.textInput = value;
        this.phoneInput = value;
      }
		},

		beforeDestroy() {},

		methods: {
			validate: function () {
        if(!this.isConditionMet) {
          return true
        }

				this.doValidate = true;
				if(this.isPhoneInput){
					return this.phoneIsValid;
				}else{
					return this.$refs.form.validate();
				}
			},

			phoneUpdated(number, object) {
				this.isPhoneValid = object.valid;
				this.phoneInput = object.number;
				this.$emit('input', { item: this.item, value: this.phoneInput, index: this.index, container: this.container });
			},

			handleInput: function() {
				if(this.textInput){
					this.textInput = this.textInput.trim();
				}
				this.$emit('input', { item: this.item, value: this.textInput, index: this.index, container: this.container });
			},
		},

		computed: {
			phoneIsValid: function(){
				if(!this.doValidate){
					return true;
				}
				if(this.isPhoneValid){
					return true;
				}else if(!this.item.required && !this.phoneInput){
					return true;
				}else{
					return false;
				}
			},

			classList: function() {
				if (this.outlined) {
					return `v-input form-input v-input--hide-details ${this.disabled ? 'v-input--is-disabled' : ''} v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder`;
				} else if (this.solo) {
					return `v-input form-input v-input--hide-details ${this.disabled ? 'v-input--is-disabled' : ''} v-input--dense theme--light v-text-field v-text-field--single-line v-text-field--solo v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder`;
				} else {
					return `v-input form-input v-input--hide-details ${this.disabled ? 'v-input--is-disabled' : ''} v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--placeholder`;
				}
			},

			preferredCountries: function() {
				let countries = ['US', 'CA', 'GB', 'IE', 'AU', 'NZ'];
				return countries;
			},

			rules: function() {
				if (this.isEmailInput && this.item.required) {
					return [
						(v) => !!v || 'Required',
						(v) =>
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
								v
							) || 'E-mail must be valid',
					];
				} else if (this.isEmailInput) {
					return [
						(v) =>
							!v ||
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
								v
							) ||
							'E-mail must be valid',
					];
				} else {
					return this.item.required ? this.inputRequired : [];
				}
			},

			required: function(){
				return this.inputRequired;
			},


			fieldName: function(){
				if(!this.item.schemaMapping){
					return "";
				}
				switch(this.item.schemaMapping){
					case "firstName":{return "fname"}
					case "lastName":{return "lname"}
					case "email":{return "email"}
					case "phone":{return "phone"}
					case "address1":{return "address1"}
					case "address2":{return "address2"}
					case "city":{ return "city"}
					case "locality":{return "state"}
					case "postal":{return "zip"}
					case "country":{return "country"}
					case "businessName":{return "organization"}
					case "website":{return "website"}
					default: {return ""}
				}
			},

			autoComplete: function(){
				if(!this.item.schemaMapping){
					return false;
				}
				switch(this.item.schemaMapping){
					case "firstName":{return "given-name"}
					case "lastName":{return "family-name"}
					case "email":{return "email"}
					case "phone":{return "phone"}
					case "address1":{return "address-line1"}
					case "address2":{return "address-line2"}
					case "city":{return "address-level2"}
					case "locality":{return "address-level1"}
					case "postal":{return "postal-code"}
					case "country":{return "country"}
					case "businessName":{return "organization"}
					case "website":{return "url"}
					default: {return false}
				}
			},

			isEmailInput: function() {
				return this.item.schemaMapping === 'email' || this.item.validateFor === 'Email';
			},

			isPhoneInput: function() {
				return this.item.schemaMapping === 'phone' || (this.item.schemaMapping !== 'email' && this.item.validateFor === 'Phone');
			},
		},
	};
</script>


<style lang="scss">
	.phone-input-outlined {
		border: 1px solid rgb(.25,.8,.5,1) !important;
		transition: border .3s cubic-bezier(.25,.8,.5,1)!important;
	}
	.custom-phone {
		border: none !important;
		&:focus-within {
			border: none !important;
			box-shadow: none !important;
		}

		.vti__dropdown {
			background-color: transparent !important;
			&:hover {
				background-color: transparent !important;
			}
		}
	}
</style>
