var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isConditionMet
    ? _c(
        "div",
        {
          staticClass: "text-left",
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-question" },
            [
              _vm._v(_vm._s(_vm.item.question) + " "),
              _vm.item.required
                ? _c("span", { staticClass: "font-red brand-medium" }, [
                    _vm._v("*")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showConditionalHelper
                ? _c("v-icon", { attrs: { small: "", color: "gray_50" } }, [
                    _vm._v("account_tree")
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.fileResult
            ? _c(
                "div",
                { staticClass: "row-format gap-2" },
                _vm._l(_vm.fileResult, function(file) {
                  return _c(
                    "v-chip",
                    {
                      key: file.fileName,
                      staticClass: "pointer",
                      attrs: { small: "", label: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.downloadFile(file)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(file.fileName) + " ")]
                  )
                }),
                1
              )
            : _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.isValid,
                    callback: function($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c("v-file-input", {
                    staticClass: "file-upload",
                    attrs: {
                      disabled: _vm.disabled,
                      "hide-details": "",
                      dense: "",
                      multiple: _vm.item.multi,
                      placeholder: _vm.item.placeholder,
                      outlined: _vm.outlined,
                      solo: _vm.solo,
                      filled: _vm.filled,
                      "append-icon": "attach_file",
                      accept: _vm.accept,
                      rules: _vm.item.required ? _vm.fileInputRequired : []
                    },
                    on: { change: _vm.handleChange },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function(ref) {
                            var text = ref.text
                            return [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    small: "",
                                    label: "",
                                    color: "primary"
                                  }
                                },
                                [_vm._v(" " + _vm._s(text) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      590729088
                    ),
                    model: {
                      value: _vm.files,
                      callback: function($$v) {
                        _vm.files = $$v
                      },
                      expression: "files"
                    }
                  })
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }