var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.view === "COUNTRIES"
      ? _c("div", {}, [
          _c("div", { staticClass: "font-20 font-gray_80 mt-5" }, [
            _vm._v("Claim your number")
          ]),
          _c(
            "div",
            {
              staticClass: "row-format centered font-gray_80 mt-5",
              staticStyle: { gap: "48px", "flex-wrap": "wrap" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.searchNumbers("US")
                    }
                  }
                },
                [
                  _c("div", [_vm._v("United States")]),
                  _c("img", {
                    staticStyle: { "border-radius": "8px" },
                    attrs: {
                      src: "https://assets.ipstack.com/flags/us.svg",
                      width: "150"
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.searchNumbers("CA")
                    }
                  }
                },
                [
                  _c("div", [_vm._v("Canada")]),
                  _c("img", {
                    staticStyle: { "border-radius": "8px" },
                    attrs: {
                      src: "https://assets.ipstack.com/flags/ca.svg",
                      width: "150"
                    }
                  })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.searchNumbers("GB")
                    }
                  }
                },
                [
                  _c("div", {}, [_vm._v("United Kingdom")]),
                  _c("img", {
                    staticStyle: { "border-radius": "8px" },
                    attrs: {
                      src: "https://assets.ipstack.com/flags/gb.svg",
                      width: "150"
                    }
                  })
                ]
              )
            ]
          )
        ])
      : _vm.view === "SEARCH"
      ? _c(
          "div",
          [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "row-format pointer mb-2 font-gray_80",
                  staticStyle: { width: "fit-content" },
                  on: { click: _vm.viewCountries }
                },
                [
                  _c("v-icon", [_vm._v("$arrowLeft")]),
                  _c("div", [_vm._v("Back")])
                ],
                1
              )
            ]),
            _vm.country === "XX"
              ? _c("bandwidth-numbers", {
                  attrs: { country: _vm.country },
                  on: {
                    "order-complete": function($event) {
                      return _vm.$emit("order-complete")
                    }
                  }
                })
              : _c("create-twilio-account", {
                  attrs: { country: _vm.country },
                  on: {
                    "order-complete": function($event) {
                      return _vm.$emit("order-complete")
                    }
                  }
                })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }