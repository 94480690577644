<template>
<div class="column-format">
  <v-text-field
      v-model="element.redirectUrl"
      label="Open URL"
      placeholder="https://www.yourwebsite.com/confirmation"
      persistent-placeholder
      hide-details
      dense
      outlined
      @input="updated()"
  ></v-text-field>
  <div class="row-format align-center pt-4">
    <v-text-field
        v-model="element.text"
        hide-details
        dense
        persistent-placeholder
        outlined
        label="Button text"
        @input="updated"
    ></v-text-field>
  </div>
  <v-select
      class="pt-4"
      v-model="element.alignment"
      :items="alignment"
      item-text="label"
      item-value="value"
      hide-details
      dense
      outlined
      label="Button alignment"
      @change="updated()"
  ></v-select>

  <div class="row-format gap-2 pt-4">
    <v-select
        style="width: 48%"
        v-model="element.style"
        :items="['Normal', 'Outlined', 'Text']"
        hide-details
        outlined
        dense
        label="Button style"
        @change="updated()"
    >
    </v-select>
    <v-select
        style="width: 48%;"
        v-model="element.size"
        :items="['X-Small', 'Small', 'Normal', 'Large', 'X-Large']"
        hide-details
        outlined
        dense
        label="Button size"
        @change="updated()"
    >
    </v-select>
  </div>

  <div class="row-format gap-2 mt-n2 pt-4">

    <div style="width: 49%" class="column-format">
      <div class="font-14 text-left font-gray_70">Button color</div>
      <v-menu :close-on-click="false" :close-on-content-click="false" v-model="colorMenu" bottom>
        <template v-slot:activator="{ on }">
          <div
              v-on="on"
              :style="
								`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.color}`
							"
              class="pointer"
          >
            &nbsp;
          </div>
        </template>
        <div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
          <v-color-picker
              mode="hexa"
              v-model="element.color"
              show-swatches
              swatches-max-height="150px"
              @input="updated()"
          ></v-color-picker>
          <v-btn @click="colorMenu = false" block>OK</v-btn>
        </div>
      </v-menu>
    </div>
		<div style="width: 49%" class="column-format">
			<div class="font-14 text-left font-gray_70">Button text color</div>
			<v-menu :close-on-click="false" :close-on-content-click="false" v-model="textColorMenu" bottom>
				<template v-slot:activator="{ on }">
					<div
							v-on="on"
							:style="
								`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.textColor}`
							"
							class="pointer"
					>
						&nbsp;
					</div>
				</template>
				<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
					<v-color-picker
							mode="hexa"
							v-model="element.textColor"
							show-swatches
							swatches-max-height="150px"
							@input="updated()"
					></v-color-picker>
					<v-btn @click="textColorMenu = false" block>OK</v-btn>
				</div>
			</v-menu>
		</div>
  </div>
</div>

</template>

<script>
// import chroma from "chroma-js";

export default {
  name: 'ButtonConfig',

  props: ['item'],

  components: {},

  data: function() {
    return {
      element: {... this.item},
      colorMenu: false,
			textColorMenu: false,
      alignment: [
        {label:'Left', value:'left'},
        {label:'Right', value:'right'},
        {label:'Center', value:'center'},
				{label:'Block', value: 'block'}
      ]
    };
  },

  mounted() {},

  beforeDestroy() {},

  methods: {
    updated: function() {
      this.$emit('change', this.element);
    },
  },

  computed: {},
};
</script>

<style scoped>

</style>