import { render, staticRenderFns } from "./DeliverableStatus.vue?vue&type=template&id=514c44ae&scoped=true&"
import script from "./DeliverableStatus.vue?vue&type=script&lang=js&"
export * from "./DeliverableStatus.vue?vue&type=script&lang=js&"
import style0 from "./DeliverableStatus.vue?vue&type=style&index=0&id=514c44ae&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "514c44ae",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VColorPicker,VIcon,VMenu,VSelect,VSwitch})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3589467384/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('514c44ae')) {
      api.createRecord('514c44ae', component.options)
    } else {
      api.reload('514c44ae', component.options)
    }
    module.hot.accept("./DeliverableStatus.vue?vue&type=template&id=514c44ae&scoped=true&", function () {
      api.rerender('514c44ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/projects/settings/DeliverableStatus.vue"
export default component.exports