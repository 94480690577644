<template>
	<div :class="`column-format call-wrapper px-4 font-14 ${call.callResult === 'Missed' ? 'font-red' : ''}`">
		<div class="row-format align-center call-record">
			<div v-if="call.unread" class="unread mr-2"></div>
			<div class="row-format align-center text-left">
				<div class="mr-1 pointer" @click="$store.state.eventBus.$emit('communicator-place-call', formattedPhone)">
					<span v-if="contact" v-tippy :content="formattedPhone">{{contact.fullName}}</span>
					<span v-else>{{ formattedPhone }} </span>
				</div>
			</div>
			<div :class="`ml-auto row-format align-center ${isExpandable ? 'pointer' : ''}`" @click="expandCall(call)">
				<div v-if="call.callRecording">
					<v-icon class="mr-1" small :color="call.callResult === 'Missed' ? 'error' : ''">$voicemail</v-icon>
				</div>
				<v-icon small v-if="call.direction === 'Outbound'">$phoneOut</v-icon>
				<v-icon small v-if="call.callResult === 'Missed' && !call.callRecording" color="error">$phoneMissed</v-icon>
				<div class="ml-1 nowrap">{{ $formatters.formatTimeAgo(call.startTime) }}</div>
			</div>
		</div>
		<div v-if="expanded" style="text-align: left" class="mb-2 mx-1">
			<div class="row-format align-center font-12">
				<div>{{ duration }}</div>
				<div class="row-format align-center ml-auto">
					<div class="mr-1">{{this.start.toLocaleString(DateTime.DATETIME_MED)}}</div>
					<v-icon small @click="$emit('delete')">$delete</v-icon>
				</div>
			</div>

			<audio v-if="callRecordingUrl" controls style="width: 100%" class="mt-2" :src="callRecordingUrl"></audio>

			<div class="text-left font-12 font-gray_90 mt-1" v-if="call.transcription">{{ call.transcription.text }}</div>
		</div>
	</div>
</template>

<script>
	import libPhoneNumber from 'google-libphonenumber';
	import { DateTime } from 'luxon';
	import CommunicatorCallService from "@/modules/communicator/CommunicatorCallService";

	export default {
		name: 'CallDetail',

		props: ['call', 'isVisible'],

		components: {},

		data: function () {
			return {
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
				expanded: false,
				callRecordingUrl: null,
				start: DateTime.fromISO(this.call.startTime),
				end: DateTime.fromISO(this.call.endTime),
				DateTime: DateTime,
				contact: null,
			};
		},

		mounted() {
			if (this.isVisible && this.call.unread && !this.call.callRecording) {
				this.markCallAsRead();
			}
			this.contact = this.getContact();
		},

		beforeDestroy() {},

		methods: {
			markCallAsRead: function () {
				this.$emit('mark-read');
			},

			expandCall: function () {
				if (this.expanded) {
					this.expanded = false;
					return;
				}
				this.expanded = true;
				if (this.call.transcription || this.call.callRecording) {
					if (this.call.unread) {
						this.markCallAsRead();
					}
				}
				if (this.call.callRecording) {
					this.getCallRecordingUrl();
				}
			},

			collapseCall: function () {
				this.expanded = false;
				this.callRecordingUrl = null;
			},

			getCallRecordingUrl: function () {
				new CommunicatorCallService().getCallRecordingUrl(this.call.id).then((res) => (this.callRecordingUrl = res.data));
			},

			formatPhone: function (phoneNumber) {
				try {
					let phone = this.PhoneUtil.parse(phoneNumber);
					return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
				} catch (err) {
					return phoneNumber;
				}
			},

			getContact: function () {
				if(!this.call){
					return null;
				}else if(this.call.direction === 'Inbound'){
					return this.$store.getters.getContactByPhone(this.call.from);
				}else{
					return this.$store.getters.getContactByPhone(this.call.to);
				}
			},

			formatSecondsLong(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				if (hours > 0) {
					return hours + ':' + minutes + ':' + seconds;
				} else {
					return minutes + ':' + seconds;
				}
			},
		},

		computed: {
			formattedPhone: function(){
				return this.formatPhone(this.call.direction === 'Inbound' ? this.call.from : this.call.to)
			},


			duration: function () {
				let seconds = Math.ceil(this.end.diff(this.start, ['seconds']).seconds);
				return this.formatSecondsLong(seconds);
			},

			isExpandable: function () {
				return true;
			},
		},

		watch: {
			isVisible: function (newVal, oldVal) {
				if (newVal && !oldVal && this.call.unread && !this.call.callRecording) {
					setTimeout(() => this.markCallAsRead(), 1000);
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.call-record {
		min-height: 48px;
	}

	.call-wrapper {
		border-bottom: 1px solid var(--v-gray_30-base);
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	.unread {
		width: 10px;
		height: 10px;
		min-width: 10px;
		min-height: 10px;
		background-color: var(--v-primary-base);
		border-radius: 5px;
	}
</style>
