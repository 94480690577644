<template>
	<div class="column-format text-left font-14 font-gray_70 py-3">
		<div>Height (px)</div>
		<v-slider
				v-model="element.height"
				:min="1"
				:max="1000"
				:step="1"
				hide-details
				dense
				@input="updated"
				thumb-label
		></v-slider>
	</div>
</template>

<script>
	export default {
		name: 'SpacerConfig',

		props: ['item'],

		components: {},

		data: function() {
			return {
				element: { ...this.item },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
