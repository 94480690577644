var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "search-button row-format centered pa-2 gap-2",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [_vm._m(0), _vm._m(1)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { "max-width": "150px" },
        attrs: { src: "/images/templates/img.png" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-left" }, [
      _c("div", { staticClass: "font-18 brand-medium" }, [
        _vm._v("Search the Moxie Template Library")
      ]),
      _c("div", { staticClass: "font-14" }, [
        _vm._v("Over 250 pre-built templates designed specifically for you")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }