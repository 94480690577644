var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "mb-8" },
        [
          _c(
            "page-header",
            {
              staticClass: "pb-6",
              attrs: {
                "bread-crumbs": [
                  { to: "/home", label: "Home" },
                  { label: "Client portal" }
                ]
              }
            },
            [_vm._v(" Domains ")]
          ),
          _c("v-data-table", {
            key: _vm.refreshKey,
            attrs: {
              headers: _vm.headers,
              items: _vm.domains,
              "hide-default-footer": true,
              "disable-pagination": true,
              "fixed-header": true,
              "items-per-page": -1
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.id",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://" + item.id,
                            target: "_blank"
                          }
                        },
                        [_vm._v(_vm._s(item.id))]
                      )
                    ]
                  }
                },
                {
                  key: "item.currentStatus",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.currentStatus === "READY"
                        ? _c(
                            "v-icon",
                            { attrs: { color: "success", small: "" } },
                            [_vm._v("$check")]
                          )
                        : _c(
                            "v-icon",
                            { attrs: { color: "warning", small: "" } },
                            [_vm._v("$error")]
                          )
                    ]
                  }
                },
                {
                  key: "item.dnsVerified",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            item.dnsVerified || item.providedDomain
                              ? "Yes"
                              : "No"
                          )
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.certEnabled",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            item.certEnabled || item.providedDomain
                              ? "Yes"
                              : "No"
                          )
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.statusHistory",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "pointer",
                          on: {
                            click: function($event) {
                              return _vm.toggleExpanded(item.id)
                            }
                          }
                        },
                        [
                          item.statusHistory.length && !item.statusExpanded
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.DateTime.fromISO(
                                        item.statusHistory[0].statusTimestamp
                                      ).toLocaleString(
                                        _vm.DateTime.DATETIME_MED
                                      )
                                    ) +
                                    " - " +
                                    _vm._s(
                                      item.statusHistory[0].statusMessage
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          item.statusExpanded
                            ? _c(
                                "div",
                                _vm._l(item.statusHistory, function(
                                  status,
                                  index
                                ) {
                                  return _c("div", { key: index }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DateTime.fromISO(
                                            status.statusTimestamp
                                          ).toLocaleString(
                                            _vm.DateTime.DATETIME_MED
                                          )
                                        ) +
                                        " - " +
                                        _vm._s(status.statusMessage) +
                                        " "
                                    )
                                  ])
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      !item.providedDomain
                        ? _c(
                            "v-icon",
                            {
                              attrs: { small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.confirmDelete(item.id)
                                }
                              }
                            },
                            [_vm._v("$delete")]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              2155629551
            )
          }),
          _c(
            "div",
            { staticClass: "text-left" },
            [
              _c(
                "v-form",
                {
                  attrs: {
                    disabled: !_vm.paidAccount || _vm.$store.getters.isInTrial
                  },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.isValid,
                    callback: function($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c("div", { staticClass: "brand-medium ml-1 mt-8" }, [
                    _vm._v("Register custom domain")
                  ]),
                  _c("v-text-field", {
                    staticClass: "my-2",
                    staticStyle: { width: "400px" },
                    attrs: {
                      disabled: _vm.domains.length >= 2,
                      rules: _vm.required,
                      dense: "",
                      outlined: "",
                      "hide-details": "",
                      placeholder: "clients.mydomain.com"
                    },
                    model: {
                      value: _vm.newDomain,
                      callback: function($$v) {
                        _vm.newDomain = $$v
                      },
                      expression: "newDomain"
                    }
                  }),
                  _c("v-checkbox", {
                    staticClass: "mb-2",
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      disabled: _vm.domains.length >= 2
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _c("span", { staticClass: "font-14" }, [
                                _vm._v(
                                  "I have added a CNAME to tls-proxy.withmoxie.com"
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1205523471
                    ),
                    model: {
                      value: _vm.confirm,
                      callback: function($$v) {
                        _vm.confirm = $$v
                      },
                      expression: "confirm"
                    }
                  }),
                  _vm.paidAccount && !_vm.$store.getters.isInTrial
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "primary-action",
                          attrs: { disabled: !_vm.confirm || !_vm.isValid },
                          on: { click: _vm.addNew }
                        },
                        [_vm._v("Register now")]
                      )
                    : _c("div", { staticClass: "font-italic font-secondary" }, [
                        _vm._v(
                          "This feature is not available on the free trial of Moxie. Please subscribe to add your custom domain."
                        )
                      ]),
                  _vm.domains.length < 2
                    ? _c(
                        "div",
                        {
                          staticClass: "font-12 brand-semilight mt-3",
                          staticStyle: { width: "400px" }
                        },
                        [
                          _vm._v(
                            " To add a custom domain for the Client Portal, you must already own a domain name and have the ability to create a CNAME record. "
                          ),
                          _c(
                            "a",
                            {
                              staticStyle: { color: "var(--v-primary-base)" },
                              attrs: {
                                href:
                                  "https://help.withmoxie.com/en/articles/5855651-client-portal-custom-domains",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "Check our our help article on how to prepare your domain to use with Moxie's Client Portal."
                              )
                            ]
                          )
                        ]
                      )
                    : _c(
                        "div",
                        { staticClass: "font-12 brand-medium mt-3" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "warning" } },
                            [_vm._v("$alert")]
                          ),
                          _vm._v(
                            " Maximum 2 custom domain limit has been reached. "
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }