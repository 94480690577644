<template>
	<div
		style="width:100%"
		:class="`page-wrapper column-format align-center ${isMobile ? 'px-0' : 'px-12'}`"
	>
		<div
			:class="`page column-format ${agreement.styles.pageShadow ? 'shadow' : ''} ${layoutClass}`"
			@drop="drop($event)"
			@dragover="allowDrop($event)"
			@click.stop="$emit('edit', null)"
			:style="`--max-page-width: ${agreement.styles.maxWidth}px`"
		>
			<div
				v-for="(item, index) in schema"
				:key="item.id"
				:id="'schemaWrapper-' + item.id"
				style="width:100%"
				@dragenter="handleDragEnter"
				@dragleave="handleDragLeave"
				:draggable="draggable && allowDrag"
				@dragstart="reOrderDragStart"
			>
				<item-renderer
					:ref="`item-${item.id}`"
					:item="item"
					:in-container="false"
					:index="index"
					:active="(item.id === activeElementId || item.id === activeContainerId) && editMode"
					:mce-config="mceConfig"
					:agreement="agreement"
					:client-mode="clientMode"
					:edit-mode="editMode"
					:tokens="tokens"
					:signer="signer"
					:template-mode="templateMode"
					:page-width="pageWidth"
					:is-mobile="isMobile"
					@delete="$emit('delete', $event)"
					@signed="$emit('signed', $event)"
					@change="$emit('change', $event)"
					@container-item-update="$emit('container-item-update', $event)"
					@container-item-signed="$emit('container-item-signed', $event)"
					@click.stop="$emit('edit', item)"
					@add-container-element="addContainerElement($event)"
					@container-item-click="$emit('container-item-click', $event)"
					@drag-enabled="setDragEnabled($event)"
					@move-up="moveUp(item, index)"
					@move-down="moveDown(item, index)"
					@add-element-above="handleAddElement(index, $event)"
					@add-element-below="handleAddElement(index + 1, $event)"
				></item-renderer>
			</div>
		</div>
		<div
			v-if="agreement.pages.length > 1"
			class="font-14 font-gray_70 mt-1 text-right page-number"
			style="margin-left: 30px; margin-right: 30px;"
		>
			{{ pageIndex + 1 }}/{{ agreement.pages.length }}
		</div>
	</div>
</template>

<script>
	import { v4 as uuid4 } from 'uuid';
	import ItemRenderer from './ItemRenderer';

	export default {
		name: 'Page',

		props: [
			'agreement',
			'pageIndex',
			'editItem',
			'mceConfig',
			'clientMode',
			'editMode',
			'tokens',
			'signer',
			'templateMode',
			'refreshKey',
			'pageWidth',
		],

		components: { ItemRenderer },

		data: function() {
			return {
				dropIndex: -1,
				allowDrag: true,
				refreshIndex: 0,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('drag-enabled', this.setDragEnabled);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('drag-enabled', this.setDragEnabled);
		},

		methods: {
			moveUp: function(item, index) {
				if (index > 0) {
					let foundItem = this.getAndRemoveElement(item.id);
					this.schema.splice(index - 1, 0, foundItem);
				} else if (index === 0 && this.pageIndex > 0) {
					let foundItem = this.getAndRemoveElement(item.id);
					this.agreement.pages[this.pageIndex - 1].schema.push(foundItem);
				}

				this.focusItem(item);
			},

			moveDown: function(item, index) {
				if (index < this.schema.length - 1) {
					let foundItem = this.getAndRemoveElement(item.id);
					this.schema.splice(index + 1, 0, foundItem);
				} else if (this.pageIndex < this.agreement.pages.length - 1) {
					let foundItem = this.getAndRemoveElement(item.id);
					this.agreement.pages[this.pageIndex + 1].schema.splice(0, 0, foundItem);
				}

				this.focusItem(item);
			},

			focusItem: function(item) {
				this.$nextTick(() => {
					document
						.getElementById('schemaWrapper-' + item.id)
						.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
					this.$emit('edit', item);
				});
			},

			async finalize() {
				for (let i = 0; i < this.schema.length; i++) {
					if (typeof this.$refs['item-' + this.schema[i].id][0].finalize === 'function') {
						await this.$refs['item-' + this.schema[i].id][0].finalize();
					}
				}
				return true;
			},

			async reset() {
				console.log('resetting page');
				for (let i = 0; i < this.schema.length; i++) {
					if (typeof this.$refs['item-' + this.schema[i].id][0].reset === 'function') {
						await this.$refs['item-' + this.schema[i].id][0].reset();
					}
				}
				return true;
			},

			setDragEnabled: function(dragEnabled) {
				this.allowDrag = dragEnabled;
			},

			reOrderDragStart: function(ev) {
				if (!this.draggable || !this.allowDrag) {
					ev.preventDefault();
					return;
				}

				let payload = {
					type: 'ReOrder',
					id: ev.target.id.replace('schemaWrapper-', ''),
				};

				ev.dataTransfer.setData('text', JSON.stringify(payload));
			},

			allowDrop: function(ev) {
				ev.preventDefault();
			},

			getId: function(element) {
				return element.id ? element.id : '';
			},

			handleDragEnter: function(e) {
				let el = e.target;

				while (!this.getId(el).startsWith('schemaWrapper-')) {
					el = el.parentNode;
				}

				if (el.id && el.id.startsWith('schemaWrapper-')) {
					let id = el.id.replace('schemaWrapper-', '');
					setTimeout(() => (this.dropIndex = this.schema.findIndex((i) => i.id === id)), 50);
					el.classList.add('border-top');
				}
			},

			handleDragLeave: function(e) {
				let el = e.target;
				el.classList.remove('border-top');

				if (el.id && el.id.startsWith('schemaWrapper-')) {
					this.dropIndex = -1;
				}
			},

			agreementHasElementOfType(type) {
				for (let i = 0; i < this.agreement.pages.length; i++) {
					if (this.doesElementTypeExist(type, this.agreement.pages[i].schema)) {
						return true;
					}
				}
				return false;
			},

			doesElementTypeExist(type, elements) {
				for (let i = 0; i < elements.length; i++) {
					if (elements[i].type === 'Container') {
						for (let j = 0; j < elements[i].columnCount; j++) {
							if (this.doesElementTypeExist(type, elements[i].columns[j].items)) {
								return true;
							}
						}
					} else if (elements[i].type === type) {
						return true;
					}
				}
				return false;
			},

			drop: function(ev) {
				ev.preventDefault();
				let payload = JSON.parse(ev.dataTransfer.getData('text'));
				this.handleDrop(payload);
			},

			handleAddElement: function(index, event) {
				this.dropIndex = index;
				let payload = {
					type: 'New',
					id: event.type,
					props: event.props,
				};
				this.handleDrop(payload);
			},

			handleDrop: function(payload) {
				let item = null;

				if (payload.type === 'New') {
					if (payload.props.allowSingleElement) {
						if (this.agreementHasElementOfType(payload.id)) {
							this.$store.commit('error', `Only a single ${payload.id} element is allowed.`);
							return;
						}
					}

					item = {
						id: null,
						type: payload.id,
					};

					Object.assign(item, JSON.parse(JSON.stringify(payload.props)));
					item.id = uuid4();

				} else if (payload.type === 'ReOrder') {
					item = this.getAndRemoveElement(payload.id);
					if (!item) {
						return;
					}
				}

				if (this.dropIndex === -1) {
					this.schema.push(item);
				} else {
					this.schema.splice(this.dropIndex, 0, item);
				}

				if (payload.type === 'New') {
					this.$emit('edit', item);
				}

				this.schema.forEach((s) => {
					let element = document.getElementById('schemaWrapper-' + s.id);
					if (element) {
						element.classList.remove('border-top');
					}
				});

				this.dropIndex = -1;
				this.$emit('save-state');
			},

			addContainerElement: function(event) {
				let container = event.container;
				let column = event.column;
				let element = event.element;

				let newElement = {
					id: null,
					type: element.type,
				};

				Object.assign(newElement, JSON.parse(JSON.stringify(element.props)));
				newElement.id = uuid4()

				let ix = this.schema.findIndex((i) => i.id === container.id);
				container.columns[column].items.push(newElement);

				this.schema.splice(ix, 1, container);

				setTimeout(() => {
					this.$emit('container-item-click', {
						container: container,
						item: newElement,
						index: container.columns[column].items.length - 1,
					});
				}, 100);

				this.$emit('save-state');
			},

			getAndRemoveElement(id) {
				let ix = this.schema.findIndex((s) => s.id === id);

				if (ix > -1) {
					let item = this.schema[ix];
					this.schema.splice(ix, 1);
					return item;
				}

				for (let i = 0; i < this.agreement.pages.length; i++) {
					let page = this.agreement.pages[i];
					ix = page.schema.findIndex((s) => s.id === id);
					if (ix > -1) {
						let item = page.schema[ix];
						page.schema.splice(ix, 1);
						return item;
					}
				}

				return null;
			},
		},

		computed: {
			isMobile: function(){
				return this.pageWidth <= 800;
			},

			draggable: function() {
				return !this.clientMode && this.editMode;
			},

			schema: function() {
				return this.agreement.pages[this.pageIndex].schema;
			},

			activeElementId: function() {
				return this.$store.state.agreementBuilderState.activeElementId;
			},

			activeContainerId: function() {
				return this.$store.state.agreementBuilderState.activeContainerId;
			},

			layoutClass: function() {
				if (this.agreement.styles.pageSize === 'US_LETTER') {
					return this.agreement.styles.landscape ? 'us_letter_landscape' : 'us_letter_portrait';
				} else if (this.agreement.styles.pageSize === 'A4_LETTER') {
					return this.agreement.styles.landscape ? 'a4_letter_landscape' : 'a4_letter_portrait';
				} else {
					return 'responsive_page';
				}
			},
		},
	};
</script>

<style lang="scss">
	.border-top {
		padding-top: 12px;
		border-top: 1px solid var(--v-secondary-base) !important;
	}
</style>

<style lang="scss">
	.shadow {
		filter: drop-shadow(0px 0px 8px #bec4ca);
	}

	.us_letter_portrait {
		width: 816px;
		min-width: 816px;
		max-width: 816px;
		min-height: 1054px;
	}

	.us_letter_landscape {
		width: 1054px;
		min-width: 1054px;
		max-width: 1054px;
		min-height: 780px;
	}

	.a4_letter_portrait {
		width: 794px;
		min-width: 794px;
		max-width: 794px;
		min-height: 1123px;
	}

	.a4_letter_landscape {
		width: 1123px;
		min-width: 1123px;
		max-width: 1123px;
		min-height: 774px;
	}

	.responsive_page {
		width: 100%;
		max-width: var(--max-page-width);
		min-height: 200px;
	}

	.page {
		flex-grow: 1;
		background: var(--page-color);

		margin-top: 10px;
		margin-left: 30px;
		margin-right: 30px;

		font-size: var(--body-size) !important;
		font-weight: var(--body-weight) !important;
		color: var(--body-color) !important;
		font-family: var(--body-font) !important;
		line-height: var(--body-height) !important;

		.v-label--is-disabled {
			color: var(--body-color) !important;
		}

		.theme--dark {
			.v-label,
			.v-input,
			.v-input input,
			.v-input textarea {
				color: var(--primary-brand-color)!important;
			}
		}

		.theme--dark.v-input--switch:not(.v-input--is-label-active) {
			.v-input--switch__track {
				color: var(--primary-brand-color)!important;
				opacity: 0.1;
			}
		}

		.theme--dark.v-card {
			background-color: var(--v-page-color)!important;
		}

		.theme--dark.v-btn.v-btn--icon {
			color: rgba(0,0,0,.54)!important;
		}

		.theme--dark.v-btn.v-btn--disabled {
			color: rgba(0, 0, 0, .26) !important;
		}

		.theme--dark.v-date-picker-table th{
			background-color: var(--v-page-color)!important;
			color: rgba(0, 0, 0, .38) !important
		}

		.theme--dark.v-picker__body{
			background-color: var(--v-page-color)!important;
		}

		.theme--dark.v-btn.v-btn--disabled {
			color: rgba(0, 0, 0, .26) !important;
		}

		.theme--dark.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty):not(.v-input--is-label-active) {
			.v-input--switch__thumb {
				opacity: 0.1;
			}
		}

		.theme--dark.v-input--is-label-active {
			opacity: 1;
		}

		.theme--dark.v-input--switch:not(.v-input--is-label-active) {
			.v-input--switch__thumb {
				color: var(--primary-brand-color) !important;
			}
		}

		.theme--dark.v-input--switch.v-input--is-disabled:not(.v-input--is-dirty) {
			.v-input--switch__track {
				color: var(--primary-brand-color) !important;
				opacity: 0.1;
			}
		}

		h1 {
			margin: unset !important;
			font-size: var(--h1-size) !important;
			font-weight: var(--h1-weight) !important;
			color: var(--h1-color) !important;
			font-family: var(--h1-font) !important;
			line-height: var(--h1-height) !important;
		}
		h2 {
			margin: unset !important;
			font-size: var(--h2-size) !important;
			font-weight: var(--h2-weight) !important;
			color: var(--h2-color) !important;
			font-family: var(--h2-font) !important;
			line-height: var(--h2-height) !important;
		}
		h3 {
			margin: unset !important;
			font-size: var(--h3-size) !important;
			font-weight: var(--h3-weight) !important;
			color: var(--h3-color) !important;
			font-family: var(--h3-font) !important;
			line-height: var(--h3-height) !important;
		}
	}
</style>
