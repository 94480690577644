import HttpClient from "@/services/HttpClient";

export default class UserService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(process.env.VUE_APP_BASE_AUTH_URL,true);
	}

	getAuthKey() {
		return this.httpClient
			.get(`/referrals/authKey`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
