<template>
	<div style="width:500px">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding mb-n5">
			<h3 class="modal-title">Select email template</h3>
			<v-autocomplete hide-details class="h-outline" persistent-placeholder label="Templates" v-model="emailTemplateId" :items="emailTemplates" item-text="name" item-value="id">
			</v-autocomplete>
		</div>
		<div style="border-top: 1px solid var(--v-gray_50-base)" class="mt-2 pa-2">
			<v-btn  class="secondary-action mr-2" @click="handleClose()">
				{{ $t('global.cancel') }}
			</v-btn>
			<v-btn class="primary-action" width="160" @click="renderEmailTemplate()">
				{{ $t('global.ok') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import EmailTemplateService from "@/modules/templates/EmailTemplateService";

export default {
	name: "EmailTemplateSelector",

	props: ['clientId','invoiceId','paymentId','proposalId','contacts','dontRender','opportunityId','mailboxId','discoveryRequestId'],

	components: {},

	data: function () {
		return {
			emailTemplateService: new EmailTemplateService(),
			emailTemplates: [],
			emailTemplateId: null,
		}
	},

	mounted() {
		this.getEmailTemplates();
	},

	beforeDestroy() {
	},

	methods: {
		getEmailTemplates: function(){
			this.emailTemplateService.getEmailTemplates().then((res) => {
				this.emailTemplates.splice(0,this.emailTemplates.length);
				this.emailTemplates.push(... res.data);
				this.emailTemplates.sort((a,b) => a.name.localeCompare(b.name));
			});
		},

		renderEmailTemplate: function(){
			if(this.dontRender){
				let result = this.emailTemplates.find(e => e.id === this.emailTemplateId);
				this.$emit('result',result);
				return;
			}

			this.$store.commit('startLoading');
			let request = {
				clientId:this.clientId,
				invoiceId:this.invoiceId,
				paymentId:this.paymentId,
				proposalId:this.proposalId,
				contacts:this.contacts,
				opportunityId: this.opportunityId,
				mailboxId: this.mailboxId,
				discoveryRequestId: this.discoveryRequestId
			}
			this.emailTemplateService.renderEmailTemplate(this.emailTemplateId,request).then((res) => {
				this.$emit('result',res.data);
			}).finally(() => this.$store.commit('stopLoading'));
		},

		handleClose: function(){
			this.$emit('result');
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>