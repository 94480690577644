<template>
	<div class="plan-card text-left gap-2">
		<div style="font-size: 36px; font-weight: 600; line-height: 36px" class="">
			{{ $t(`subscription-sumo.${plan.code}.header`) }}
		</div>
		<div>
			<div class="font-primary">{{ $t(`subscription-sumo.who-for`) }}</div>
			<div>{{ $t(`subscription-sumo.${plan.code}.who-for`) }}</div>
		</div>
		<div class="my-2">
			<v-btn v-if="isCurrentPlan" large block :disabled="true">Current plan</v-btn>
			<v-btn color="secondary" v-else large block @click="openAppSumo()"><span style="color: var(--v-white-base)">Visit AppSumo</span></v-btn>
		</div>
		<div>
			<ul class="font-14">
				<li v-for="(point,index) in points" :key="index">{{point}}</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PlanCard',

		props: ['plan','currentPlan'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openAppSumo: function(){
				window.open('https://appsumo.com/account/products/','_blank');
			}
		},

		computed: {
			isCurrentPlan: function(){
				return this.currentPlan && this.currentPlan.code === this.plan.code;
			},

			points: function(){
				return this.$t(`subscription-sumo.${this.plan.code}.points`);
			},
		},
	};
</script>

<style scoped lang="scss">
	.plan-card {
		display: flex;
		flex-direction: column;
		width: 350px;
		min-height: 483px;
		padding: 24px;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 12px;

		h1 {
			font-size: 36px;
			font-weight: 600;
		}

		h3 {
			font-size: 24px;
		}
	}
</style>
