var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-height column-format show-scrollbar",
      staticStyle: {
        height: "calc(var(--vh) - 65px)",
        "min-height": "calc(var(--vh) - 65px)",
        "max-height": "calc(var(--vh) - 65px)",
        "overflow-y": "auto"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "fill-width pa-3 ma-2 font-14",
          staticStyle: {
            "background-color": "var(--v-accent-base)",
            "border-radius": "4px"
          }
        },
        [
          _c("v-icon", { attrs: { color: "black" } }, [_vm._v("warning")]),
          _vm._v(
            "You are viewing the legacy version of the Sales Pipeline. This version will remain available until March 1, 2023."
          )
        ],
        1
      ),
      _vm.paidAccount || _vm.sampleMode
        ? _c(
            "div",
            { staticClass: "fill-all" },
            [
              _vm.isReady && !_vm.isBrandNew
                ? _c(
                    "v-container",
                    {
                      staticClass: "d-flex px-4 mt-5",
                      attrs: { id: "kanban-container", fluid: "" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            id: "kanban-v-row",
                            justify: "center",
                            "align-content": "start"
                          }
                        },
                        _vm._l(_vm.columns, function(column, i) {
                          return _c(
                            "v-col",
                            { key: i, staticClass: "xs-12 sm-2 kanban-v-col" },
                            [
                              _c(
                                "div",
                                {
                                  class:
                                    "kanban-column " +
                                    _vm.columnClassState[column.name],
                                  attrs: { id: column.name + "-column" }
                                },
                                [
                                  _c(
                                    "div",
                                    { class: "column-head " + column.color },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "column-head-label" },
                                        [_vm._v(_vm._s(column.label))]
                                      ),
                                      ["prospect"].indexOf(column.name) > -1
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "float-right",
                                              attrs: {
                                                small: "",
                                                rounded: "",
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleColumnPlusClick(
                                                    column.name
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    tooltip: "Add a new client"
                                                  }
                                                },
                                                [_vm._v("$plus")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "draggable",
                                    {
                                      attrs: {
                                        list: _vm.cardsInColumns[column.name],
                                        group: { name: "card", pull: "clone" },
                                        tag: "section",
                                        handle: ".leadgen-card",
                                        "ghost-class": "ghost",
                                        move: _vm.moveOk
                                      },
                                      on: { end: _vm.handleEnd }
                                    },
                                    _vm._l(
                                      _vm.cardsInColumns[column.name],
                                      function(card) {
                                        return _c(
                                          "div",
                                          { key: card.id },
                                          [
                                            _c("lead-gen-card", {
                                              attrs: { card: card },
                                              on: {
                                                "action-clicked":
                                                  _vm.handleAction,
                                                "leadgen-dialog":
                                                  _vm.handleLeadGenDialog
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isReady && _vm.isBrandNew
                ? _c(
                    "div",
                    {
                      staticClass: "row-format centered",
                      staticStyle: { flex: "1" }
                    },
                    [
                      _c("empty-view", {
                        attrs: {
                          header: "Never have another client dry spell",
                          body:
                            "Visualize the prospects you’re talking to, track notes on what they need, pitch them on proposals, and work your way to a signed contract.",
                          cta: "Add a prospect",
                          "video-header": "See how it works",
                          "video-body":
                            "Learn how the pipeline visualizes your sales process. It’s the world’s first CRM (customer relationship management) tool that is simple enough for freelancers, but powerful enough to get results.",
                          "video-cta": "Watch the tutorial",
                          "video-id": "RqZypPmS8fg"
                        },
                        on: {
                          "cta-clicked": function($event) {
                            return _vm.handleColumnPlusClick("prospect")
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCard
                ? _c(
                    "div",
                    [
                      _vm.dialog["createDiscovery"]
                        ? _c(
                            "basic-modal",
                            {
                              attrs: {
                                dialog: _vm.dialog["createDiscovery"],
                                persistent: false,
                                "max-width": _vm.createDiscoveryMaxWidth
                              },
                              on: {
                                close: function($event) {
                                  _vm.closeDialogs()
                                  _vm.discoveryModalState.inSendMode = false
                                }
                              }
                            },
                            [
                              _c("discovery-templates", {
                                attrs: {
                                  "client-id": _vm.selectedCard.client.id,
                                  "modal-state": _vm.discoveryModalState,
                                  discoveryOrigin: "bizDev"
                                },
                                on: {
                                  close: function($event) {
                                    _vm.closeDialogs()
                                    _vm.discoveryModalState.inSendMode = false
                                  },
                                  "template-selected": function($event) {
                                    return _vm.handleTemplateSelected(
                                      $event,
                                      _vm.selectedCard.client
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectedCard && _vm.dialog["viewDiscovery"]
                        ? _c(
                            "basic-modal",
                            {
                              attrs: {
                                dialog: _vm.dialog["viewDiscovery"],
                                persistent: false
                              },
                              on: {
                                close: function($event) {
                                  _vm.dialog["viewDiscovery"] = false
                                },
                                "click:outside": function($event) {
                                  return _vm.closeDialogs()
                                }
                              }
                            },
                            [
                              _c("discovery-modal", {
                                attrs: {
                                  client: _vm.selectedCard.client,
                                  "discovery-request-id": _vm.selectedCard.id,
                                  "card-type": _vm.selectedCard.type
                                },
                                on: {
                                  "delete-discovery": function($event) {
                                    return _vm.deleteCardById($event)
                                  },
                                  close: function($event) {
                                    return _vm.closeDialogs()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectedCard && _vm.dialog["createProposal"]
                        ? _c(
                            "basic-modal",
                            {
                              attrs: {
                                dialog: _vm.dialog["createProposal"],
                                persistent: true
                              },
                              on: {
                                close: function($event) {
                                  _vm.dialog["createProposal"] = false
                                }
                              }
                            },
                            [
                              _c("new-proposal", {
                                attrs: {
                                  "open-state": _vm.dialog["createProposal"],
                                  "client-id": _vm.selectedCard.client.id,
                                  "nav-origin": "bizDev"
                                },
                                on: {
                                  result: function($event) {
                                    return _vm.handleCreateProposalEvent($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialog["sendReminder"]
                        ? _c(
                            "basic-modal",
                            {
                              attrs: {
                                dialog: _vm.dialog["sendReminder"],
                                persistent: true
                              },
                              on: {
                                close: function($event) {
                                  _vm.dialog["sendReminder"] = false
                                }
                              }
                            },
                            [
                              _vm.selectedCard.proposal
                                ? _c("share-send-widget", {
                                    staticStyle: {
                                      "background-color": "var(--v-white-base)"
                                    },
                                    attrs: {
                                      "client-service": _vm.clientService,
                                      proposal: _vm.selectedCard.proposal,
                                      mode: "Send",
                                      "proposal-status":
                                        _vm.selectedCard.proposal
                                          .proposalStatus,
                                      "proposal-service": _vm.proposalService
                                    },
                                    on: {
                                      finalize: _vm.activateProposal,
                                      alert: function($event) {
                                        return _vm.scope.setAlert($event)
                                      },
                                      cancel: function($event) {
                                        _vm.dialog["sendReminder"] = false
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialog["createProject"]
                        ? _c(
                            "basic-modal",
                            {
                              attrs: {
                                dialog: _vm.dialog["createProject"],
                                persistent: true
                              },
                              on: {
                                close: function($event) {
                                  _vm.dialog["createProject"] = false
                                }
                              }
                            },
                            [
                              _c("new-project", {
                                attrs: {
                                  "open-state": _vm.dialog["createProject"],
                                  client: _vm.selectedCard.client.id
                                    ? _vm.selectedCard.client
                                    : "",
                                  "proposal-id": _vm.proposalId
                                },
                                on: {
                                  created: function($event) {
                                    _vm.dialog["createProject"] = false
                                    _vm.getCards()
                                  },
                                  close: function($event) {
                                    _vm.dialog["createProject"] = false
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialog["createClient"]
                        ? _c(
                            "basic-modal",
                            {
                              attrs: {
                                dialog: _vm.dialog["createClient"],
                                persistent: true
                              },
                              on: {
                                close: function($event) {
                                  _vm.dialog["createClient"] = false
                                }
                              }
                            },
                            [
                              _c("client-create", {
                                attrs: {
                                  "is-open": _vm.dialog["createClient"],
                                  clientList: _vm.$store.getters.clients,
                                  leadGen: _vm.selectedCard
                                },
                                on: {
                                  alert: function($event) {
                                    return _vm.$refs.deleteClientModal.setAlert(
                                      $event
                                    )
                                  },
                                  "client-created": function($event) {
                                    return _vm.clientCreated($event)
                                  },
                                  close: function($event) {
                                    _vm.dialog["createClient"] = false
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dialog["archiveClient"]
                        ? _c("confirm-dialog", {
                            attrs: {
                              dialog: _vm.dialog["archiveClient"],
                              "heading-text": "Confirm",
                              "yes-text": "Yes",
                              "no-text": "No",
                              "body-text":
                                "Are you sure you want to hide " +
                                _vm.selectedCard.client.name +
                                " from the Prospect column?"
                            },
                            on: {
                              close: function($event) {
                                _vm.dialog["archiveClient"] = false
                              },
                              confirm: function($event) {
                                return _vm.archiveClient(
                                  _vm.selectedCard.client.id
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.dialog["archiveProposal"]
                        ? _c("confirm-dialog", {
                            attrs: {
                              dialog: _vm.dialog["archiveProposal"],
                              "heading-text": "Confirm",
                              "yes-text": "Yes",
                              "no-text": "No",
                              "body-text":
                                "Are you sure you want to archive the proposal for " +
                                _vm.selectedCard.client.name +
                                "?"
                            },
                            on: {
                              close: function($event) {
                                _vm.dialog["archiveProposal"] = false
                              },
                              confirm: function($event) {
                                return _vm.archiveProposal(
                                  _vm.selectedCard.proposal.id
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.dialog["deleteProject"]
                        ? _c("confirm-dialog", {
                            attrs: {
                              dialog: _vm.dialog["deleteProject"],
                              "heading-text": "Confirm",
                              "yes-text": "Yes",
                              "no-text": "No",
                              "body-text":
                                "Are you sure you want to delete this project, " +
                                _vm.selectedCard.name +
                                "?"
                            },
                            on: {
                              close: function($event) {
                                _vm.dialog["deleteProject"] = false
                              },
                              confirm: function($event) {
                                return _vm.deleteProject(_vm.selectedCard.id)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.dialog["archiveProject"]
                        ? _c("confirm-dialog", {
                            attrs: {
                              dialog: _vm.dialog["archiveProject"],
                              "heading-text": "Confirm",
                              "yes-text": "Yes",
                              "no-text": "No",
                              "body-text":
                                "Are you sure you want to archive the project for " +
                                _vm.selectedCard.client.name +
                                "?"
                            },
                            on: {
                              close: function($event) {
                                _vm.dialog["archiveProject"] = false
                              },
                              confirm: function($event) {
                                return _vm.archiveProject(
                                  _vm.selectedCard.client.id
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.dialog["voidProposal"]
                        ? _c("confirm-dialog", {
                            attrs: {
                              dialog: _vm.dialog["voidProposal"],
                              "heading-text": "Confirm",
                              "yes-text": "Yes",
                              "no-text": "No",
                              "body-text":
                                "Are you sure you want to void this proposal for " +
                                _vm.selectedCard.client.name +
                                "?"
                            },
                            on: {
                              close: function($event) {
                                _vm.dialog["voidProposal"] = false
                              },
                              confirm: function($event) {
                                return _vm.voidProposal(_vm.selectedCard.id)
                              }
                            }
                          })
                        : _vm._e(),
                      _c(
                        "modal",
                        {
                          attrs: {
                            dialog: _vm.leadgenDialog && !_vm.dialogIsActive,
                            persistent: true,
                            "max-width": 920
                          },
                          on: {
                            close: function($event) {
                              _vm.leadgenDialog = false
                            }
                          }
                        },
                        [
                          _c("lead-gen-modal", {
                            key: _vm.selectedCard.id,
                            attrs: { card: _vm.selectedCard },
                            on: { "action-clicked": _vm.handleAction }
                          })
                        ],
                        1
                      ),
                      _vm.dialog["discovery"]
                        ? _c(
                            "basic-modal",
                            {
                              attrs: {
                                dialog: _vm.dialog["discovery"],
                                persistent: true,
                                fullscreen: true
                              },
                              on: { close: _vm.handleBuilderClose }
                            },
                            [
                              _c("discovery-builder", {
                                attrs: {
                                  client: _vm.client,
                                  "template-id": _vm.templateId
                                },
                                on: {
                                  close: _vm.handleBuilderClose,
                                  sent: _vm.handleBuilderClose,
                                  duplicated: _vm.handleBuilderDuplicated
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "row-format centered mx-8 mt-4" },
            [
              _c("free-tier-card", {
                staticStyle: { width: "100%", height: "150px" },
                attrs: {
                  message:
                    "Sales pipeline is only available on the Pro or Teams plan.  Upgrade now to get control over your business and visualize the flow of opportunities through the pipeline."
                },
                on: {
                  "upgrade-clicked": function($event) {
                    return _vm.$router.push("/subscription")
                  }
                }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }