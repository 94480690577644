<template>
	<div>
		<v-menu
			max-height="90vh"
			max-width="250"
			v-model="showMenu"
			:close-on-content-click="false"
			:close-on-click="true"
			id="channel-filter"
			content-class="filter-menu-class"
			style="overflow-y: auto"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" style="min-height: 40px; background-color: var(--v-white-base)" elevation="0" outlined color="gray_30">
					<span :class="`font-14 ${filter.assignedTo.length ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{filterLabel}}</span>
					<v-icon x-small class="mx-1" :color="filter.assignedTo.length ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format open-menu">
				<div class="filter-option-box">
					<v-checkbox
						v-model="filter.assignedTo"
						v-for="user in userOptions"
						:value="user.userId"
						:key="user.userId"
						hide-details
						dense
					>
						<template v-slot:label>
							<assigned-user v-if="user.userId !== 'unassigned'" :assigned-to="user.userId"></assigned-user>
							<span v-else>{{ $t('projects.unassigned') }}</span>
						</template>
					</v-checkbox>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import AssignedUser from '@/components/AssignedUser';

	export default {
		name: 'AssignedToFilter',

		props: ['filter'],

		components: { AssignedUser },

		data: function() {
			return {
				showMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {

		},

		computed: {
			filterLabel: function() {
				if(this.filter.assignedTo && this.filter.assignedTo.length){
					let text = [];
					text.push(this.filter.assignedTo.length + ' users');
					return text.join(" &bull; ")
				}else{
					return 'Assigned to'
				}
			},

			userOptions: function() {
				const unassigned = {
					firstName: this.$t('projects.unassigned'),
					userId: 'unassigned',
				};

				let users = [... this.$store.state.usersForAccount];
				users = users.filter(u => u.userType === 'OWNER' || u.userType === 'FULL_USER' || (u.userType === 'RESTRICTED_USER' && u.featureAccess.tickets === true));

				users.push(unassigned);
				return users;
			},
		},
	};
</script>

<style scoped lang="scss">

.active {
	background-color: var(--v-gray_20-base) !important;
	color: var(--v-secondary-base) !important;
	border: 1px solid var(--v-gray_50-base) !important;
}
</style>