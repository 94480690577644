<template>
	<div>
		<v-btn elevation="0" width="400" style="height: 40px !important; background-color: var(--v-white-base)" outlined color="gray_30" @click="signInWithApple">
			<div class="row-format centered" style="width: 100%">
				<v-icon :size="20" color="black">$apple</v-icon>
				<div class="ml-2 font-gray_90 font-14">{{ appleButton }}</div>
			</div>
		</v-btn>
	</div>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';
	import SignUpService from '@/modules/auth/SignUpService';

	export default {
		name: 'AppleSignIn.vue',

		props: ['isLogin', 'acceptTerms'],

		components: {},

		data: function () {
			return {
				inProcess: false,
			};
		},

		mounted() {
			this.initClient();
		},

		beforeDestroy() {},

		methods: {
			initClient: function () {
				let redirectUri = document.location.protocol + '//' + document.location.host + '/oauth2/apple';

				window.AppleID.auth.init({
					clientId: 'com.hecticapp.signin',
					scope: 'name email',
					redirectURI: redirectUri,
					state: 'origin:web',
					usePopup: true,
				});
			},

			signInWithApple: function () {
				this.inProcess = true;

				window.AppleID.auth
					.signIn()
					.then((res) => {
						this.runRecaptcha(res);
					})
					.catch((err) => {
						this.handleFailure(err);
					});
			},

			handleFailure(failureResult) {
				console.log(failureResult);
				this.inProcess = false;

				if (this.isLogin) {
					this.$track.record('Apple sign in FAIL');
				} else {
					this.$track.record('Apple sign up FAIL');
				}
			},

			runRecaptcha(result) {
				window.grecaptcha.ready(() => {
					window.grecaptcha
						.execute(process.env.VUE_APP_RECATPCHA_SITE_KEY, { action: 'submit' })
						.then((reCaptchaToken) => {
							this.handleSuccess(result, reCaptchaToken);
						});
				});
			},

			handleSuccess(result,reCaptchaToken) {
				this.inProcess = false;
				let code = result.authorization.code;
				let user = result.user;

				if (this.isLogin) {
					// handle sign in
					AuthService.loginApple(code,reCaptchaToken)
						.then((result) => {
							this.$emit('success', {
								result: result,
								source: 'Apple',
							});
						})
						.catch((error) => {
							this.$track.record('Apple sign in FAIL');
							this.emitError(
								this.$t('login.sign-in.invalid-apple-account'),
								this.$t('login.sign-up-button'),
								this.$t('login.sign-in-button')
							);
							console.log(error);
						});
				} else {
					this.$store.commit('startLoading');

					SignUpService.signupWithApple(code, user, reCaptchaToken)
						.then((result) => {
							this.handleSignUpSuccess(result);
						})
						.catch((err) => {
							this.emitError(
								this.$t('login.sign-up.invalid-apple-account'),
								this.$t('login.sign-in-button'),
								this.$t('login.sign-up-button')
							);
							console.log('Apple Error', err);
						})
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			handleSignUpSuccess(result) {
				this.$store.commit('logIn', result);

				AuthService.getProfile().then((res) => {
					this.$store.state.inWelcomeFlow = true;
					this.$store.state.newUser = true;
					this.$store.dispatch('setLoggedInUser', res);
					this.$track.identify(res);
					this.$track.conversion();
					setTimeout(() => this.$router.push('/welcome'), 500);
				});
			},

			emitError(errorMsg, errorButton, errorButtonSecondary) {
				let errorPayload = {
					errorMsg,
					errorButton,
					errorButtonSecondary,
				};
				this.$emit('error', errorPayload);
			},
		},

		computed: {
			appleButton: function () {
				return this.isLogin ? this.$t('login.sign-in.apple-button') : this.$t('login.sign-up.apple-button');
			},
		},
	};
</script>

<style scoped lang="scss"></style>
