var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invoice-action-menu" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-click": true,
            "nudge-bottom": 30,
            disabled: !_vm.menuEnabled
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(scope) {
                return [
                  _c(
                    "div",
                    { staticClass: "ml-1" },
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "menu-activator secondary-action",
                            attrs: { icon: "", disabled: !_vm.menuEnabled }
                          },
                          scope.on
                        ),
                        [
                          _c("v-icon", { attrs: { size: 20 } }, [
                            _vm._v(
                              _vm._s(
                                scope.value ? "$arrowUp" : "$moreHorizontal"
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c("div", { staticClass: "more-menu" }, [
            _vm.subscription.status !== "sent"
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("view-payments")
                      }
                    }
                  },
                  [_vm._v(" View payments ")]
                )
              : _vm._e(),
            _vm.subscription.status === "active" ||
            _vm.subscription.status === "past_due"
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel-subscription")
                      }
                    }
                  },
                  [_vm._v(" Cancel subscription ")]
                )
              : _vm._e(),
            _vm.subscription.status === "sent"
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("copy-payment-link")
                      }
                    }
                  },
                  [_vm._v(" Copy checkout link ")]
                )
              : _vm._e(),
            _vm.subscription.status === "sent"
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel-request")
                      }
                    }
                  },
                  [_vm._v(" Cancel request ")]
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }