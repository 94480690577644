import DateTime from "@/modules/utils/HDateTime";
import ProposalBuilder from '@/modules/proposals/ProposalBuilder';
import NewProposal from "@/modules/proposals/NewProposal";

export default {
	methods: {
		addNewProposal(events){
			this.$store.state.globalModalController.openModal(NewProposal).then((res) => {
				if(res){
					res.client = res.clientMini;

					let proposal = {
						id: res.id,
						eventSource: 'PROPOSAL',
						start: DateTime.fromISO(res.dateCreated).toISODate(),
						end: DateTime.fromISO(res.dateCreated).toISODate(),
						dateOnly: true,
						meta: {
							clientId: res.clientId,
							proposal: res,
						},
					};

					events.push(proposal);
					this.proposalClicked(proposal.meta,events);
				}
			})
		},

		proposalClicked(meta, events) {
			let binding = {
				proposalId: meta.proposal.id,
				clientId: meta.proposal.client.id,
			};
			this.$store.state.globalModalController.openModal(ProposalBuilder, binding, true, true).then((res) => {
				if (!res) return;
				let ix = events.findIndex((e) => e.eventSource === 'PROPOSAL' && e.id === res.id);
				if (ix > -1) {
					if (res.action && res.action === 'DELETED') {
						events.splice(ix, 1);
					} else {
						let proposal = res;
						proposal.client = meta.proposal.client;

						let updated = {
							id: res.id,
							eventSource: 'PROPOSAL',
							start: DateTime.fromISO(res.dateCreated).toISODate(),
							end: DateTime.fromISO(res.dateCreated).toISODate(),
							dateOnly: true,
							meta: {
								clientId: res.clientId,
								proposal: proposal,
							},
						};

						events.splice(ix, 1, updated);
						this.refreshKey++;
					}
				}
			});
		},

		processProposals(proposals) {
			let result = [];

			for (let j = 0; j < proposals.length; j++) {
				let e = proposals[j];
				let p = e.meta.proposal;

				let targetDate = p.dateSent ? p.dateSent : p.dateCreated;

				result.push({
					name: e.summary,
					start: DateTime.fromISO(targetDate).toJSDate(),
					end: DateTime.fromISO(targetDate).toJSDate(),
					timed: false,
					original: {
						eventSource: 'PROPOSAL',
						meta: {
							proposal: p,
						},
					},
					source: 'PROPOSAL',
				});

				if (p.dateSigned) {
					result.push({
						name: e.summary,
						start: DateTime.fromISO(p.dateSigned).toJSDate(),
						end: DateTime.fromISO(p.dateSigned).toJSDate(),
						timed: false,
						original: {
							eventSource: 'PROPOSAL_SIGNED',
							meta: {
								proposal: p,
							},
						},
						source: 'PROPOSAL_SIGNED',
					});
				}
			}

			return result;
		},
	},
};
