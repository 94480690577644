var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "700px" } }, [
    _c(
      "div",
      { staticClass: "modal-padding" },
      [
        _c("v-data-table", {
          attrs: {
            headers: _vm.headers,
            items: _vm.payments,
            "disable-pagination": "",
            "hide-default-footer": ""
          },
          scopedSlots: _vm._u([
            {
              key: "item.amount",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$formatters.dollars(
                          item.amount,
                          true,
                          true,
                          item.currency
                        )
                      ) +
                      " "
                  )
                ]
              }
            },
            {
              key: "item.paid",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$formatters.yesNoFromBoolean(item.paid)) +
                      " "
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "v-btn",
          {
            staticClass: "primary-action ml-1",
            attrs: { width: "150" },
            on: {
              click: function($event) {
                return _vm.$emit("result")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("global.done")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }