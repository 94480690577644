var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: { "bread-crumbs": [{ to: "/home", label: "Home" }] }
        },
        [_vm._v(" Integrations ")]
      ),
      _c(
        "div",
        { staticClass: "row-format gap-3" },
        _vm._l(_vm.pages, function(page) {
          return _c(
            "div",
            {
              key: page.value,
              class:
                "page-link " +
                (_vm.currentView === page.value ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.setView(page.value)
                }
              }
            },
            [_c("div", [_vm._v(_vm._s(page.label))])]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "column-format mt-4", staticStyle: { width: "100%" } },
        [
          _vm.currentView === "calendar"
            ? _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid var(--v-gray_20-base)",
                    "border-radius": "4px"
                  }
                },
                [
                  _c("div", { staticClass: "app-row" }, [
                    _c(
                      "div",
                      { staticClass: "app-icon" },
                      [
                        _c("v-icon", { attrs: { size: "50" } }, [
                          _vm._v("$googleCalendar")
                        ]),
                        _c("div", { staticClass: "ml-4" }, [
                          _vm._v(_vm._s(_vm.$t("connected-apps.google.name")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "app-info" },
                      [_c("google-calendar")],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "app-row" }, [
                    _c(
                      "div",
                      { staticClass: "app-icon" },
                      [
                        _c("v-icon", { attrs: { size: "50" } }, [
                          _vm._v("$microsoft")
                        ]),
                        _c("div", { staticClass: "ml-4" }, [
                          _vm._v(
                            _vm._s(_vm.$t("connected-apps.microsoft.name"))
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "app-info" },
                      [_c("microsoft-calendar")],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "app-row" }, [
                    _c(
                      "div",
                      { staticClass: "app-icon" },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "50", color: "black" } },
                          [_vm._v("$apple")]
                        ),
                        _c("div", { staticClass: "ml-4" }, [
                          _vm._v(_vm._s(_vm.$t("connected-apps.apple.name")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "app-info" },
                      [_c("apple-calendar")],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "app-row" }, [
                    _c(
                      "div",
                      { staticClass: "app-icon" },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "50", color: "secondary" } },
                          [_vm._v("event")]
                        ),
                        _c("div", { staticClass: "ml-4" }, [
                          _vm._v(_vm._s(_vm.$t("connected-apps.caldav.name")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "app-info" },
                      [_c("cal-dav-calendar")],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "app-row" }, [
                    _c("div", { staticClass: "app-icon" }, [
                      _c("img", {
                        staticStyle: { "margin-left": "-5px" },
                        attrs: { src: "/images/zoom-logo1.png", width: "60" }
                      }),
                      _c("div", { staticClass: "ml-3" }, [
                        _vm._v(_vm._s(_vm.$t("connected-apps.zoom.name")))
                      ])
                    ]),
                    _c("div", { staticClass: "app-info" }, [_c("zoom")], 1)
                  ]),
                  _c("div", { staticClass: "app-row" }, [
                    _c("div", { staticClass: "app-icon" }, [
                      _c("img", {
                        staticStyle: { "margin-left": "-5px" },
                        attrs: {
                          src: "/images/apps/sessions-logo.png",
                          width: "60"
                        }
                      }),
                      _c("div", { staticClass: "ml-3" }, [
                        _vm._v(_vm._s(_vm.$t("connected-apps.sessions.name")))
                      ])
                    ]),
                    _c("div", { staticClass: "app-info" }, [_c("sessions")], 1)
                  ])
                ]
              )
            : _vm._e(),
          _vm.currentView === "payment" && _vm.owner
            ? _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid var(--v-gray_20-base)",
                    "border-radius": "4px"
                  }
                },
                [
                  _c("div", { staticClass: "app-row" }, [
                    _c(
                      "div",
                      { staticClass: "app-icon" },
                      [
                        _c("v-icon", { attrs: { size: "50" } }, [
                          _vm._v("$paypal")
                        ]),
                        _c("div", { staticClass: "ml-4" }, [
                          _vm._v(_vm._s(_vm.$t("connected-apps.paypal.name")))
                        ])
                      ],
                      1
                    ),
                    _c("div", { staticClass: "app-info" }, [_c("pay-pal")], 1)
                  ]),
                  _c("div", { staticClass: "app-row" }, [
                    _c(
                      "div",
                      { staticClass: "app-icon" },
                      [
                        _c("v-icon", { attrs: { size: "50" } }, [
                          _vm._v("$stripeLockup")
                        ]),
                        _c("div", { staticClass: "ml-4" }, [
                          _vm._v(_vm._s(_vm.$t("connected-apps.stripe.name")))
                        ])
                      ],
                      1
                    ),
                    _c("div", { staticClass: "app-info" }, [_c("stripe")], 1)
                  ])
                ]
              )
            : _vm._e(),
          _vm.currentView === "accounting"
            ? _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid var(--v-gray_20-base)",
                    "border-radius": "4px"
                  }
                },
                [
                  _vm.fullUser
                    ? _c("div", { staticClass: "app-row" }, [
                        _c(
                          "div",
                          { staticClass: "app-icon" },
                          [
                            _c(
                              "v-icon",
                              { attrs: { color: "black", size: "50" } },
                              [_vm._v("$plaidLockup")]
                            ),
                            _c("div", { staticClass: "ml-4" }, [
                              _vm._v(
                                _vm._s(_vm.$t("connected-apps.plaid.name"))
                              )
                            ])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "app-info" }, [_c("plaid")], 1)
                      ])
                    : _vm._e(),
                  _vm.owner
                    ? _c("div", { staticClass: "app-row" }, [
                        _c(
                          "div",
                          { staticClass: "app-icon" },
                          [
                            _c("v-icon", { attrs: { size: "50" } }, [
                              _vm._v("$quickbooks")
                            ]),
                            _c("div", { staticClass: "ml-4" }, [
                              _vm._v(
                                _vm._s(_vm.$t("connected-apps.quickbooks.name"))
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "app-info" },
                          [_c("quickbooks")],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.owner && _vm.$store.getters.getLoggedInUserId < 31071
                    ? _c("div", { staticClass: "app-row" }, [
                        _c("div", { staticClass: "app-icon" }, [
                          _c("img", {
                            staticStyle: { "margin-left": "-5px" },
                            attrs: { src: "/images/xero-logo.png", width: "60" }
                          }),
                          _c("div", { staticClass: "mx-3" }, [
                            _vm._v(_vm._s(_vm.$t("connected-apps.xero.name")))
                          ])
                        ]),
                        _c("div", { staticClass: "app-info" }, [_c("xero")], 1)
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.currentView === "integrations"
            ? _c(
                "div",
                {
                  staticClass: "mb-8",
                  staticStyle: {
                    border: "1px solid var(--v-gray_20-base)",
                    "border-radius": "4px"
                  }
                },
                [
                  _vm.fullUser
                    ? _c("div", { staticClass: "app-row" }, [
                        _c("div", { staticClass: "app-icon" }, [
                          _c("img", {
                            attrs: {
                              src: "images/apps/make-logo.svg",
                              alt: "Make logo",
                              width: "50"
                            }
                          }),
                          _c("div", { staticClass: "ml-4" }, [
                            _vm._v(_vm._s(_vm.$t("connected-apps.make.name")))
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "app-info" },
                          [_c("public-api", { attrs: { type: "Make" } })],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.fullUser
                    ? _c("div", { staticClass: "app-row" }, [
                        _c(
                          "div",
                          { staticClass: "app-icon" },
                          [
                            _c("v-icon", { attrs: { size: "50" } }, [
                              _vm._v("$pabbly")
                            ]),
                            _c("div", { staticClass: "ml-4" }, [
                              _vm._v(
                                _vm._s(_vm.$t("connected-apps.pabbly.name"))
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "app-info" },
                          [_c("public-api", { attrs: { type: "Pabbly" } })],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.fullUser
                    ? _c("div", { staticClass: "app-row" }, [
                        _c(
                          "div",
                          { staticClass: "app-icon" },
                          [
                            _c("v-icon", { attrs: { size: "50" } }, [
                              _vm._v("$zapier")
                            ]),
                            _c("div", { staticClass: "ml-4" }, [
                              _vm._v(
                                _vm._s(_vm.$t("connected-apps.zapier.name"))
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "app-info" },
                          [_c("public-api", { attrs: { type: "Zapier" } })],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.fullUser
                    ? _c("div", { staticClass: "app-row" }, [
                        _c(
                          "div",
                          { staticClass: "app-icon" },
                          [
                            _c(
                              "v-icon",
                              { attrs: { size: "50", color: "secondary" } },
                              [_vm._v("hub")]
                            ),
                            _c("div", { staticClass: "ml-4" }, [
                              _vm._v(
                                _vm._s(_vm.$t("connected-apps.custom.name"))
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "app-info" },
                          [_c("public-api", { attrs: { type: "Public" } })],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }