<template>
	<div>

	</div>
</template>

<script>
export default {
	name: "ClientRouter",

	props: [],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
		this.$router.push('/clients/overview');
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>