<template>
	<div id="payment-instructions-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">
				{{ $t('invoice.details.payment-instructions-modal.heading') }}
			</div>
			<div class="modal-subtitle">
				{{ $t('invoice.details.payment-instructions-modal.subheading') }}
			</div>

<!--			<div class="h-outline mb-2">-->
<!--				<div-->
<!--					class="font-12 text-left mx-4 mt-4"-->
<!--					v-html="$t('invoice.details.payment-instructions-modal.what-to-update')"-->
<!--				></div>-->
<!--				<div class="mx-4 mb-2">-->
<!--					<v-radio-group v-model="updateAccountToo" dense class="mt-1">-->
<!--						<v-radio-->
<!--							v-for="option in updateOptions"-->
<!--							:key="option.value"-->
<!--							:label="option.label"-->
<!--							:value="option.value"-->
<!--						></v-radio>-->
<!--					</v-radio-group>-->
<!--				</div>-->
<!--			</div>-->
			<div class="h-outline">
<!--				<div class="font-12 text-left mx-4 mt-4" v-html="$t('invoice.details.payment-instructions-modal.label')"></div>-->
				<div class="mx-2 mb-2">
					<editor
						class="mt-1 px-2 text-left"
						style="width: 100%; min-height: 150px; border-bottom: none;"
						ref="editor"
						:api-key="$store.getters.getTinyMceKey"
						:initial-value="payInstructionsClone"
						:inline="true"
						rows="3"
						:spellcheck="true"
						:init="mceConfigText"
					></editor>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn icon @click.native="confirmDeleteDialog = true" class="mr-1 secondary-action">
				<v-icon size="20">$delete</v-icon>
			</v-btn>
			<v-btn class="primary-action ml-1" width="170" @click="handleSave()">{{ $t('global.save') }}</v-btn>
		</div>
		<confirm-dialog
			:dialog="confirmDeleteDialog"
			:heading-text="$t('global.confirm')"
			:body-text="$t('global.confirm-delete')"
			@confirm="handleDelete()"
			@close="confirmDeleteDialog = false"
		></confirm-dialog>
	</div>
</template>

<script>
	// import HPillSelector from '@/components/HPillSelector';
	// import AccountService from '@/modules/account/AccountService';
	import ConfirmDialog from "@/modules/invoices/InvoiceRenderer/ConfirmDialog";
	import { cloneDeep } from 'lodash';

	export default {
		name: 'PaymentInstructionsModal',

		props: ['payInstructions'],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
			ConfirmDialog
		},

		data: function() {
			return {
				// accountService: new AccountService(),
				// updateAccountToo: true,
				// updateOptions: [
				// 	{ label: 'Invoice only', value: false },
				// 	{ label: 'Invoice & account', value: true },
				// ],
				payInstructionsClone: null,

				mceConfigText: {
					menubar: false,
					inline: true,
					browser_spellcheck: true,
					forced_root_block: '',
					plugins: ['paste'],
					paste_as_text: true,
					toolbar: [],
				},
				confirmDeleteDialog: false,
			};
		},

		mounted() {
			this.payInstructionsClone = cloneDeep(this.payInstructions);
		},

		beforeDestroy() {},

		methods: {
			// updateAccount(payInstructionsClone) {
			// 	console.log('account patch', payInstructionsClone, this.payInstructions);
			//
			// 	if (payInstructionsClone === this.payInstructions) return;
			//
			// 	// if (this.payInstructions == undefined) {
			// 	// 	const patch = [{ op: 'add', path: '/payInstructions', value: this.payInstructionsClone }];
			// 	// }
			// 	const patch = [{ op: 'replace', path: '/payInstructions', value: payInstructionsClone }];
			//
			// 	console.log(patch);
			//
			// 	if (patch.length > 0) {
			// 		this.accountService
			// 			.patchAccount(patch)
			// 			.then(() => {
			// 				this.$store.commit('success', 'Account settings saved.');
			// 			})
			// 			.catch((err) => {
			// 				this.alert('error', err.response.data.message);
			// 			});
			// 	}
			// },
			handleSave() {
				console.log(this.updateAccountToo);
				this.payInstructionsClone = this.$refs.editor.editor.getContent();
				// if (this.updateAccountToo) {
				// 	this.updateAccount(this.payInstructionsClone);
				// }
				this.$emit('save', this.payInstructionsClone);
			},

			handleDelete() {
				console.log(this.updateAccountToo);
				this.payInstructionsClone = null;
				this.confirmDeleteDialog = false;
				this.$emit('save', this.payInstructionsClone);
			},
		},
		computed: {},
	};
</script>

<style lang="scss">
	#payment-instructions-modal {
		max-width: 500px;
		min-width: 380px;
		background-color: var(--v-white-base);
	}
</style>
