<template>
	<div class="column-format gap-2">
		<div>Width ({{ element.width }}px)</div>
		<v-slider
			v-model="element.width"
			:min="50"
			:max="800"
			:step="10"
			hide-details
			dense
			@input="updated"
			thumb-label
		></v-slider>
		<v-select
			v-model="element.alignment"
			:items="alignment"
			item-text="label"
			item-value="value"
			hide-details
			dense
			outlined
			label="Alignment"
			@change="updated"
		></v-select>
		<v-text-field
			hide-details
			dense
			outlined
			type="number"
			v-model="element.padding"
			label="Padding"
			@input="updated"
		></v-text-field>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.pt"
				label="Margin top"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.pb"
				label="Margin bottom"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.pl"
				label="Margin left"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.pr"
				label="Margin right"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					v-model="element.borderWidth"
					label="Border width"
					@input="updated"
			></v-text-field>
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					v-model="element.borderRadius"
					label="Border radius"
					@input="updated"
			></v-text-field>
		</div>
		<div class="row-format text-left gap-1">
			<div style="flex: 1 1 0; width: 0;">
				<div class="font-14">Background color</div>
				<color-selector v-model="element.backgroundColor" @input="updated"></color-selector>
			</div>
			<div style="flex: 1 1 0; width: 0;">
				<div class="font-14">Border color</div>
				<color-selector v-model="element.borderColor" @input="updated"></color-selector>
			</div>
		</div>
	</div>
</template>

<script>
	import ColorSelector from "@/components/ColorSelector";
	export default {
		name: 'LogoConfig',

		props: ['item'],

		components: {ColorSelector},

		data: function() {
			return {
				element: { ...this.item },
				alignment: [
					{ label: 'Left', value: 'left' },
					{ label: 'Right', value: 'right' },
					{ label: 'Center', value: 'center' },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
