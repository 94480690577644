import { render, staticRenderFns } from "./DeleteAccount.vue?vue&type=template&id=d8fd2022&scoped=true&"
import script from "./DeleteAccount.vue?vue&type=script&lang=js&"
export * from "./DeleteAccount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8fd2022",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2614485219/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d8fd2022')) {
      api.createRecord('d8fd2022', component.options)
    } else {
      api.reload('d8fd2022', component.options)
    }
    module.hot.accept("./DeleteAccount.vue?vue&type=template&id=d8fd2022&scoped=true&", function () {
      api.rerender('d8fd2022', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/account/DeleteAccount.vue"
export default component.exports