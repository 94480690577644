<template>
	<div id="mapper-details">
		<div id="header-area">
			<div class="header-left pl-md-6 pl-4">
				<div class="mr-6 brand-medium">
					<v-icon>attach_file</v-icon>
					Data import - {{ definition.type }}
				</div>
			</div>
			<div class="header-right pr-md-6 pr-4">
				<v-btn icon class="primary-action" @click="handleClose()">
					<v-icon :size="20">$close</v-icon>
				</v-btn>
			</div>
		</div>
		<div class="mt-4">
			<v-container fluid>
				<v-row>
					<v-col cols="8">
						<div class="row-format" style="flex-wrap: wrap">
							<div
								v-for="column in columns"
								:key="column.column"
								style="min-width: 300px; max-width: 300px"
								class="mr-2"
							>
								<v-select
									:style="
										`${column.required && column.ix == null ? 'border: 1px solid var(--v-error-base)' : ''}`
									"
									v-model="column.ix"
									hide-details
									persistent-placeholder
									class="h-outline"
									:label="column.label"
									:items="sampleRow"
									item-text="data"
									item-value="ix"
									@change="updateRefreshKey"
								>
									<template v-slot:item="{ item }">
										<span class="text-left font-14">{{ item.data }}</span>
									</template>
								</v-select>
							</div>
						</div>
					</v-col>
					<v-col cols="4" align="left">
						<v-container>
							<v-row>
								<v-col cols="12">
									<v-select
										hide-details
										persistent-placeholder
										class="h-outline"
										v-model="dateFormat"
										:items="dateFormatOptions"
										item-text="label"
										item-value="format"
										label="Date format"
									></v-select>
									<v-select
										hide-details
										persistent-placeholder
										class="h-outline"
										v-model="timeFormat"
										:items="timeFormatOptions"
										item-text="label"
										item-value="format"
										label="Time format"
									></v-select>
									<v-select
										hide-details
										persistent-placeholder
										class="h-outline"
										v-model="dateTimeFormat"
										:items="dateTimeFormatOptions"
										item-text="label"
										item-value="format"
										label="Date/Time format"
									></v-select>
									<v-checkbox
										dense
										hide-details
										v-model="createClient"
										label="Create new client/project if no match"
										v-if="definition.type !== 'Client'"
									></v-checkbox>
									<v-checkbox
										v-model="firstRowHeaders"
										dense
										hide-details
										label="First row has headers"
									></v-checkbox>
									<v-btn class="primary-action mt-5" large @click="processImport"
										>Next: Import {{ definition.type }} records</v-btn
									>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-container>
			<div class="brand-medium font-primary ml-4 mt-5 font-18 text-left">Preview:</div>
			<v-data-table
				:key="refreshKey"
				:headers="headers"
				:items="mappedData"
				:hide-default-footer="true"
				:items-per-page="-1"
			></v-data-table>
		</div>
	</div>
</template>

<script>
	import countries from '@/assets/data/countries-simple.json';
	import currencies from '@/assets/data/currencies.json';
	import DateTime from '@/modules/utils/HDateTime';
	import ImportService from '@/modules/account/import/ImportService';
	import ConfirmModal from '@/components/ConfirmModal';
	import Papa from 'papaparse';

	export default {
		name: 'Mapper',

		props: ['inputData', 'definition'],

		components: {},

		data: function() {
			return {
				importService: new ImportService(),
				firstRowHeaders: false,
				dateFormat: 'yyyy-MM-dd',
				timeFormat: 'hh:mm a',
				dateTimeFormat: 'yyyy-MM-dd hh:mm a',
				refreshKey: 0,
				countryByName: null,
				countryByCode: null,
				currencyMap: null,
				data: [],
				columns: [],
				createClient: false,

				dateFormatOptions: [
					{ label: 'YYYY-MM-DD', format: 'yyyy-MM-dd' },
					{ label: 'MM/DD/YYYY', format: 'MM/dd/yyyy' },
					{ label: 'DD/MM/YYYY', format: 'dd/MM/yyyy' },
				],

				timeFormatOptions: [
					{ label: 'hh:mm (24 hour)', format: 'HH:mm' },
					{ label: 'hh:mm AM/PM (12 hour)', format: 'hh:mm a' },
				],
			};
		},

		mounted() {
			this.setupColumns();
			this.data = JSON.parse(JSON.stringify(this.inputData));
			this.countryByName = new Map();
			this.countryByCode = new Map();
			this.currencyMap = new Map();

			for (let i = 0; i < countries.length; i++) {
				let country = countries[i];
				this.countryByName.set(country.name.toLowerCase(), country);
				this.countryByCode.set(country.code.toLowerCase(), country);
			}

			for (let i = 0; i < currencies.length; i++) {
				let currency = currencies[i];
				this.currencyMap.set(currency.value.toLowerCase(), currency);
			}
		},

		beforeDestroy() {},

		methods: {
			setupColumns: function() {
				let result = [...this.definition.columns];
				let custom = [];

				if (this.definition.type === 'Client') {
					custom.push(...this.$store.state.clientFields.fields);
				} else if (this.definition.type === 'Contact') {
					custom.push(...this.$store.state.contactFields.fields);
				} else if (this.definition.type === 'Project') {
					custom.push(...this.$store.state.projectFields.fields);
				} else if (this.definition.type === 'Deliverable') {
					custom.push(...this.$store.state.deliverableFields.fields);
				}else if(this.definition.type === 'Opportunity'){
					custom.push(... this.$store.state.pipelineFields.fields);
				}

				for (let i = 0; i < custom.length; i++) {
					let type = 'String';
					if (custom[i].type === 'Numeric' || custom[i].type === 'Currency') {
						type = 'Number';
					} else if (custom[i].type === 'Date') {
						type = 'Date';
					}

					result.push({
						label: custom[i].name,
						column: custom[i].mappingKey,
						type: type,
						required: false,
						custom: true,
					});
				}

				this.columns.splice(0);
				this.columns.push(... result);
			},

			processImport: function() {
				this.$store.commit('startLoading');
				this.importService
					.importData(this.definition.type, this.finalPayload, this.createClient)
					.then((res) => this.notifyResult(res.data))
					.catch((err) => console.log(err))
					.finally(() => this.$store.commit('stopLoading'));
			},

			notifyResult: function(result) {
				let bodyText;
				if (result.rejectedRecords > 0) {
					bodyText = `There were ${result.successfulRecords} records successfully imported and ${result.rejectedRecords} records rejected.  Please see rejection.csv file for details on why certain rows were not accepted.`;
				} else {
					bodyText = `All records were successfully imported!`;
				}

				let binding = {
					headingText: 'Import finished',
					bodyText: bodyText,
					yesText: 'OK',
					hideNo: true,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then(() => {
					setTimeout(() => this.$emit('result'), 750);
				});

				if (result.rejections.length) {
					let csvContent = Papa.unparse(result.rejections);
					csvContent = 'data:text/csv;charset=utf-8,' + csvContent;

					let encodedUri = encodeURI(csvContent);
					let link = document.createElement('a');
					link.setAttribute('href', encodedUri);
					link.setAttribute('download', `${this.definition.type}_rejections.csv`);
					document.body.appendChild(link);
					link.click();
				}
			},

			parseData: function(col, property) {
				let result = {
					value: null,
					display: null,
					custom: col.custom,
				};

				if (property == null || property == undefined) {
					property = '';
				}

				if (col.type === 'String') {
					result.value = property;
					result.display = property;
				} else if (col.type === 'Date') {
					let date = DateTime.fromFormat(property, this.dateFormat);

					if (date.isValid) {
						result.value = date.toISODate();
						result.display = date.toLocaleString(DateTime.DATE_MED);
					} else {
						result.value = null;
						result.display = 'Invalid date';
					}
				} else if (col.type === 'DateTime') {
					let date = DateTime.fromFormat(property, this.dateTimeFormat);
					if (date.isValid) {
						result.value = date.toISO();
						result.display = date.toLocaleString(DateTime.DATETIME_MED);
					} else {
						result.value = null;
						result.display = 'Invalid date/time';
					}
				} else if (col.type === 'Number') {
					result.value = parseFloat(property.replace(/\$|,/g, ''));
					result.display = parseFloat(property.replace(/\$|,/g, ''));
				} else if (col.type === 'Boolean') {
					let val = property ? property.toLowerCase() : 'false';
					let boolVal = val === 'true' || val === 'yes';
					result.value = boolVal;
					result.display = boolVal.toString();
				} else if (col.type === 'Country') {
					let mapped = this.mapCountry(property);
					if (mapped) {
						result.value = mapped.code;
						result.display = mapped.name;
					} else {
						result.value = null;
						result.display = 'Unknown country';
					}
				} else if (col.type === 'Currency') {
					let mapped = this.currencyMap.get(property.toLowerCase());
					if (mapped) {
						result.value = mapped.value;
						result.display = mapped.label;
					} else {
						result.value = null;
						result.display = 'Unknown currency';
					}
				}

				return result;
			},

			mapCountry: function(country) {
				let mapped = this.countryByName.get(country.toLowerCase());
				if (mapped) {
					return mapped;
				} else {
					mapped = this.countryByCode.get(country.toLowerCase());
					return mapped;
				}
			},

			handleClose: function() {
				this.$emit('result');
			},

			updateRefreshKey: function() {
				console.log('updating refresh key');
				this.refreshKey++;
			},
		},

		computed: {
			mappedData: function() {
				console.log(this.refreshKey);
				let mapping = [];
				let i = this.firstRowHeaders ? 1 : 0;
				for (; i < this.data.length; i++) {
					let object = {};

					for (let j = 0; j < this.columns.length; j++) {
						let col = this.columns[j];
						if (col.ix !== null && col.ix !== undefined) {
							object[col.column] = this.parseData(col, this.data[i][col.ix]);
						}
					}

					mapping.push(object);
				}
				return mapping;
			},

			finalPayload: function() {
				let result = [];
				for (let i = 0; i < this.mappedData.length; i++) {
					let row = this.mappedData[i];
					let obj = {
						customValues: {},
					};
					for (const [key, value] of Object.entries(row)) {
						if (value.custom) {
							obj.customValues[key] = value.value;
						} else {
							obj[key] = value.value;
						}
					}
					result.push(obj);
				}
				return result;
			},

			headers: function() {
				let headers = [];
				this.columns.forEach((c) => {
					if (c.ix !== null && c.ix !== undefined) {
						headers.push({
							text: c.label,
							value: c.column + '.display',
						});
					}
				});
				return headers;
			},

			sampleRow: function() {
				if (!this.data.length) {
					return [];
				}

				let sample = [];
				sample.push({
					ix: null,
					data: '',
				});
				let row = this.data[this.firstRowHeaders ? 1 : 0];
				for (let i = 0; i < row.length; i++) {
					let col = {
						ix: i,
						data: i + 1 + ': ' + row[i],
					};
					sample.push(col);
				}
				return sample;
			},

			dateTimeFormatOptions: function() {
				let result = [];
				for (let i = 0; i < this.dateFormatOptions.length; i++) {
					for (let j = 0; j < this.timeFormatOptions.length; j++) {
						let label = this.dateFormatOptions[i].label + ' ' + this.timeFormatOptions[j].label;
						let format = this.dateFormatOptions[i].format + ' ' + this.timeFormatOptions[j].format;
						result.push({
							label: label,
							format: format,
						});
					}
				}
				result.push({
					label: 'YYYY-MM-DDTHH:MM:SS (ISO 8601)',
					format: 'yyyy-mm-ddThh:mm:ss',
				});
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	#mapper-details {
		background-color: var(--v-white-base);
		#header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
