import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';
import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
import OpportunityDetail from '@/modules/pipeline/opportunity/OpportunityDetail';
import TicketDetail from '@/modules/communicator/inbox/tickets/TicketDetail';
import ProposalBuilder from '@/modules/proposals/ProposalBuilder';
import AgreementBuilder from '@/modules/agreements/AgreementBuilder';

export default {
	methods: {
		checkForNotificationRoute: function() {
			let params = this.getQueryParams();
			if (params.get('mode') === 'router') {
				this.removeQueryParams();
				setTimeout(() => {
					switch (params.get('type')) {
						case 'agreement': {
							this.handleRouteToAgreement(params);
							break;
						}
						case 'client': {
							this.handleRouteToClient(params);
							break;
						}
						case 'task': {
							this.handleRouteToTask(params);
							break;
						}
						case 'invoice': {
							this.handleRouteToInvoice(params);
							break;
						}
						case 'opportunity': {
							this.handleRouteToOpportunity(params);
							break;
						}
						case 'subscription': {
							this.handleRouteToSubscription(params);
							break;
						}
						case 'ticket': {
							this.handleRouteToTicket(params);
							break;
						}
					}
				}, 1000);
			}
		},

		handleRouteToAgreement: function(params) {
			if (params.get('version') === '1') {
				let binding = {
					proposalId: params.get('id'),
					clientId: params.get('clientId'),
				};
				this.$store.state.globalModalController.openModal(ProposalBuilder, binding, true, true);
			} else {
				let binding = {
					id: params.get('id'),
				};
				this.$store.state.globalModalController.openModal(AgreementBuilder, binding, true, true);
			}
		},

		handleRouteToClient: function(params) {
			this.$router.push(`/client/${params.get('id')}`);
		},

		handleRouteToTask: function(params) {
			let binding = {
				deliverableId: params.get('id'),
			};
			this.$store.state.globalModalController.openModal(DeliverableDetail, binding);
		},

		handleRouteToInvoice: function(params) {
			let binding = {
				clientId: params.get('clientId'),
				id: params.get('id'),
				showDuplicate: false,
			};
			this.$store.state.globalModalController.openModal(InvoiceDetails, binding, true, true);
		},

		handleRouteToOpportunity: function(params) {
			this.$store.state.globalModalController.openModal(OpportunityDetail, { id: params.get('id') });
		},

		handleRouteToSubscription: function(params) {
			this.$router.push(`/client/${params.get('clientId')}?v=Invoices`);
		},

		handleRouteToTicket: function(params) {
			let binding = {
				id: params.get('id'),
			};
			this.$store.state.globalModalController.openModal(TicketDetail, binding);
		},

		removeQueryParams: function() {
			const url = window.location.origin + window.location.pathname;
			window.history.replaceState({}, document.title, url);
		},

		getQueryParams: function() {
			const params = new URLSearchParams(window.location.search);
			const paramMap = new Map();

			for (const [key, value] of params.entries()) {
				paramMap.set(key, value);
			}

			return paramMap;
		},
	},
};
