<template>
	<welcome-container>
		<template v-slot:left>
			<div class="column-format fill-all">
				<div class="column-format flex-grow-1 centered pl-4 pr-8">
					<div class="text-left pr-8 column-format gap-5">
						<div class="stratos-medium" style="font-size: 56px; line-height: 64px">Let's launch your Moxie experience!</div>
						<div class="font-20 stratos-semilight">First, how do you like to explore? Would you prefer to explore Moxie with sample clients or do you want to dive right in and add your own records?</div>
					</div>

					<div class="column-format gap-4 fill-width pr-8 mt-8">
						<div :class="`option ${selectedOption === 'Sample' ? 'selected' : ''}`" @click="selectedOption = 'Sample'"><div>Yes, load up sample data</div> <v-icon size="20" color="pop" v-if="selectedOption === 'Sample'">$check</v-icon></div>
						<div :class="`option ${selectedOption === 'Blank' ? 'selected' : ''}`" @click="selectedOption = 'Blank'"><div>No, I'll explore on my own</div> <v-icon size="20" color="pop" v-if="selectedOption === 'Blank'">$check</v-icon></div>
					</div>
				</div>

				<div class="gap-2 row-format mt-auto mb-6" style="flex-wrap: wrap">
					<v-btn large outlined color="gray_50" width="50" @click="$emit('back')"
					><v-icon color="gray_70">arrow_back</v-icon></v-btn
					>
					<v-btn large color="primary" :disabled="!selectedOption" width="240" @click="letsGo()"
					><span>Let's go!</span> <v-icon class="ml-2">arrow_forward</v-icon></v-btn
					>
				</div>
			</div>
		</template>
		<template v-slot:right>
			<div
					class="row-format centered fill-all pa-12"
					style="
					background-image: url('/images/onboarding/letsgo2.png');
					background-size: contain;
					background-position: center;
					background-origin: content-box;
				"
			></div>
		</template>
	</welcome-container>
</template>

<script>
import WelcomeContainer from "@/modules/welcome/WelcomeContainer";
export default {
	name: "LetsGo",

	props: [],

	components: {WelcomeContainer},

	data: function () {
		return {
			selectedOption: 'Sample',
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		letsGo: function(){
			this.$emit('next',this.selectedOption === 'Sample')
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">
	.option {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		border-radius: 8px;
		border: 1px solid var(--v-gray_30-base);
		padding: 16px;
		cursor: pointer;
		gap: 4px;

		&:hover {
			background-color: var(--v-primary-base);
			border: 1px solid var(--v-primary-base);
			color: var(--v-white-base);
		}
	}

	.selected {
		background-color: var(--v-primary-base);
		border: 1px solid var(--v-primary-base);
		color: var(--v-white-base);
	}
</style>