<template>
	<div class="row-format pl-4 pt-0 pb-0" style="background-color: var(--v-white-base); width:600px">
		<div class="column-format text-left" style="justify-content: center">
			<div class="brand-medium">{{header}}</div>
			<div class="brand-semilight font-14 mt-1 mb-6">{{body}}</div>
			<div class="row-format">
				<v-btn outlined color="gray_80" @click="$emit('result')">Continue exploring</v-btn>
				<v-btn color="accent" elevation="0" class="ml-3"><span style="color: var(--v-black-base)" @click="clearSampleData">Clear sample data</span></v-btn>
			</div>
		</div>
		<div>
			<img src="/images/hummingbird.png" width="150" style="transform: scaleX(-1)" />
		</div>
	</div>
</template>

<script>
export default {
	name: "ClearSampleModal",

	props: {
		header: {
			type: String,
			required: false,
			default: 'Are you finished exploring with sample clients?'
		},

		body: {
			type: String,
			required: false,
			default: 'You cannot add or remove clients while in sample mode. If you are ready to add your own clients, you can clear out the sample data to get started.'
		}
	},

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		clearSampleData: function(){
			this.$store.state.eventBus.$emit('delete-sample-data');
			this.$emit('result');
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>