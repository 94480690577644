<template>
	<div id="templates-main">
		<div class="fill-height">
			<div v-if="!paidAccount" class="column-format centered mb-3 px-3 mt-3 mx-1">
				<free-tier-card style="height:90px; width:100%" :message="freeMessage" @upgrade-clicked="upgradeClicked()" />
			</div>
			<proposal-templates ref="agreements" v-if="currentView === 'agreements'" :id="id" />
			<deliverable-package-list ref="packages" v-if="currentView === 'packages'" :id="id" />
			<email-template-list ref="emails" v-if="currentView === 'emails'"></email-template-list>
		</div>
	</div>
</template>

<script>
	import ProposalTemplates from '@/modules/templates/proposals/ProposalTemplates';
	import Template from '@/modules/templates/Template';
	import FreeTierCard from '@/components/FreeTierCard';
	import EmailTemplateList from '@/modules/templates/emails/EmailTemplateList';
	import DeliverablePackageList from "@/modules/templates/deliverables/DeliverablePackageList";

	export default {
		name: 'TemplatesMain',

		props: ['v', 'id'],

		components: { EmailTemplateList, DeliverablePackageList, ProposalTemplates, FreeTierCard },

		data() {
			return {
				templateJS: new Template(),
				// filter: {},
				// filterDefault: { search: null, status: 'active' },
				newButtonDisabled: false,
				currentView: 'packages',

				pages: [
					{ label: 'Agreements', value: 'agreements' },
					{ label: 'Deliverable packages', value: 'packages' },
					{ label: 'Email templates', value: 'emails' },
				],

				templateTypes: [
					{ value: 'MINE', name: this.$t('templates.proposals.my-saved') },
					// { value: 'HECTIC', name: this.$t('templates.proposals.visit-hectic-library') },
				],
				selectedType: 'MINE',
			};
		},
		mounted() {
			if (this.v) {
				this.currentView = this.v;
				Template.setLastViewed(this.v);
			}
		},
		methods: {
			setView: function(view) {
				view = view.toLowerCase();
				this.templateJS.setLastViewed(view);
				this.$router.push('/templates/' + view);
			},

			handleAddNew: function() {
				this.$refs[this.currentView].addNew();
			},

			upgradeClicked: function() {
				this.$router.push('/subscription');
			},
		},

		watch: {
			$route(to) {
				this.currentView = to.name;
				Template.setLastViewed(to.name);
			},
		},

		computed: {
			freeMessage() {
				if (this.currentView === 'emails') {
					return "Email templates are not available on the Essentials plan. You can explore this feature, but you won't be able to send emails from these templates.";
				} else {
					return "Templates are not available on the Essentials plan. You can explore this feature, but you won't be able to create projects and proposals from these templates.";
				}
			},
			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},
		},
	};
</script>

<style lang="scss">
	#templates-main {
		.subheading-links {
			margin: 0 16px;
			height: 64px;
			//border: 1px solid red;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 32px;
			.selected {
				font-weight: 600;
			}
			.not-selected {
				color: var(--v-primary-base);
				cursor: pointer;
			}
		}
	}
</style>
