<template>
	<div style="max-width: 400px">
		<v-btn icon class="close-dialog" @click="handleClose">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="text-center">
				<v-avatar size="55" v-if="user.original.profilePicture">
					<img class="pic" :src="user.original.profilePicture" />
				</v-avatar>
				<v-avatar size="55" v-else color="primary">
					<span style="color: var(--v-white-base);" class="font-24">{{ initials }}</span>
				</v-avatar>
				<div class="font-20 brand-medium mt-4">{{ user.name }}</div>
				<div>{{ user.email }}</div>
			</div>
			<div class="mt-3 text-left">
				<span class="brand-medium font-14">Has access to</span>
				<div
					style="
							max-height: 400px;
							overflow-y: auto;
							background-color: var(--v-gray_10-base);
							border: 1px solid var(--v-gray_50-base);
						"
					class="pa-3"
				>
					<div v-for="p in projects" :key="p.id" class="row-format align-center text-left mb-2">
						<input type="checkbox" v-model="projectList" :value="p.id" style="transform: scale(1.4)" :id="p.id" />
						<label :for="p.id">
							<div class="row-format align-center pointer">
								<client-avatar disable-click small :client="p.client" class="ml-3"></client-avatar>
								<div class="ml-3 font-14 ellipsis" style="width: 300px">{{ p.name }}</div>
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" @click="$emit('result', false)">
				{{ $t('global.cancel') }}
			</v-btn>
			<v-btn width="200" class="super-action ml-1" @click="updateUser()">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import UserService from '@/modules/users/UserService';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'ProjectListDialog',

		props: ['user', 'projects'],

		components: { ClientAvatar },

		data() {
			return {
				userService: new UserService(),
				projectList: [],
			};
		},

		mounted() {
			this.user.projects.forEach((p) => this.projectList.push(p));
			this.projectList = this.projectList.filter((p) => {
				return this.projects.findIndex((project) => p == project.id) > -1;
			});
		},

		methods: {
			updateUser: function() {
				this.$store.commit('startLoading');
				this.userService
					.syncProjectAccess(this.$store.getters.getAccountId, this.projectList, this.user.email)
					.then(() => {
						this.$emit('result', true);
					})
					.catch((err) => {
						this.$emit('error', err);
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			isChecked: function(projectId) {
				console.log(projectId);
				console.log(this.user.projects);
				console.log(this.user.projects.includes(projectId));
				return this.user.projects.includes(projectId);
			},

			handleClose: function() {
				this.$emit('result');
			},
		},

		computed: {
			initials: function() {
				let initials = '';
				if (this.user.firstName) {
					initials += this.user.firstName.substr(0, 1).toUpperCase();
				}
				if (this.user.lastName) {
					initials += this.user.lastName.substr(0, 1).toUpperCase();
				}

				return initials ? initials : '?';
			},
		},
	};
</script>

<style scoped lang="scss"></style>
