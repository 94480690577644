import { v4 as uuidv4 } from 'uuid';
import ClientPalette from '../../clients/ClientPalette';

export default {

    data: function(){
        return {
            clientPalette: new ClientPalette(),
            isReady: false,
            updateCounter: 0,
            deleteColumnDialog: false,
            columnToDelete: null,
        }
    },

    methods:{
        isInStatusFilter: function (filter,statusId) {
            if (filter.status.length === 0) {
                return true;
            } else if (filter.status.indexOf(statusId) > -1) {
                return true;
            } else {
                return false;
            }
        },

        navigateToProject: function(project){
            console.log('navigating');
            this.$router.push(`/projects/${project.id}`);
        },

        endColumnDragHandler: function () {
            this.updateDeliverableStatusList();
        },

        updateDeliverableStatusList: function () {
            this.$store.dispatch('updateDeliverableStatusList');
        },

        confirmDeleteColumn: function (column) {
            this.deleteColumnDialog = true;
            this.columnToDelete = column;
        },

        cancelDeleteColumn: function () {
            this.deleteColumnDialog = false;
            this.columnToDelete = null;
        },

        deleteColumn: function () {
            for (let i = 0; i < this.columns.length; i++) {
                if (this.columns[i].id === this.columnToDelete.id) {
                    this.columns.splice(i, 1);
                    this.updateDeliverableStatusList();
                    this.deleteColumnDialog = false;
                    this.columnToDelete = null;
                    break;
                }
            }

            this.deleteColumnDialog = false;
            this.columnToDelete = null;
        },

        addDeliverableStatus: function () {
            let currentColors = [];

            for (let i = 0; i < this.columns.length; i++) {
                currentColors.push(this.columns[i].hexColor);
            }

            let newColor = this.clientPalette.getRandomColor(currentColors);

            this.columns.push({
                id: uuidv4(),
                label: 'New',
                hexColor: newColor,
            });

            this.updateDeliverableStatusList();
        },

        sortByName: function (a, b) {
            if(a.name && b.name){
                return a.name.localeCompare(b.name, undefined, {sensitivity: 'base'})
            }else if(a.name){
                return 1;
            }else if(b.name){
                return -1;
            }else{
                return 0;
            }
        },
    },

    computed: {}


}