<template>
	<div id="mark-paid-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">
				{{ $t('invoice.mark-paid-modal.heading', { invoiceNumber: invoice.invoiceNumber }) }}
			</div>
			<div class="modal-subtitle">
				{{ $t('invoice.mark-paid-modal.subheading', { invoiceNumber: invoice.invoiceNumber }) }}
			</div>

			<div class="amount-due-wrapper">
				<div class="amount-due-label">{{ $t('invoice.mark-paid-modal.due-label') }}</div>
				<div class="amount-due grey-text">
					{{ $formatters.dollars(invoice.amountDue,true,true,invoice.currency) }}
				</div>
			</div>

			<div class="client-name">
				<client-avatar :client="invoice.clientInfo" left></client-avatar>
				<div>{{ invoice.clientInfo.name }}</div>
			</div>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				type="number"
				:label="$t('invoice.mark-paid-modal.payment-amount')"
				placeholder="0.00"
				:min="0"
				:max="amountDue"
				v-model="payment.amount"
				:prefix="currencySymbol"
			/>

			<v-text-field
					v-if="invoice.currency !== $store.state.defaultCurrency"
					class="h-outline"
					persistent-placeholder
					hide-details
					type="number"
					label="Exchange rate (optional)"
					placeholder="0.0000"
					v-model="payment.exchangeRate"
			/>

			<date-selector :date="datePaid" :label="$t('invoice.mark-paid-modal.date-of-payment')" @change="datePaid=$event"></date-selector>

			<!--v-menu
				v-model="datePaidMenu"
				:close-on-click="true"
				:close-on-content-click="false"
				:min-width="250"
				:max-width="300"
				:nudge-bottom="28"
				:nudge-right="0"
				bottom
				left
				origin="bottom left"
			>
				<template v-slot:activator="{ on }">
					<v-text-field
						class="h-outline date-selector"
						persistent-placeholder
						hide-details
						:label="$t('invoice.mark-paid-modal.date-of-payment')"
						v-on="on"
						type="text"
						:value="$DateTime.shortDate(datePaid)"
						@keydown.prevent="() => {}"
						append-icon="$calendar"
					/>
				</template>
				<v-date-picker :first-day-of-week="$store.getters.firstDayOfWeek" @change="datePaidMenu=false" v-model="datePaid" :no-title="true"></v-date-picker>
			</v-menu-->

			<v-select
				class="h-outline"
				persistent-placeholder
				hide-details
				:label="$t('invoice.mark-paid-modal.payment-type')"
				v-model="payment.paymentProvider"
				:items="paymentProviders"
			></v-select>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				type="text"
				v-model="payment.memo"
				:label="$t('invoice.mark-paid-modal.memo')"
				:placeholder="$t('invoice.mark-paid-modal.memo-placeholder')"
			/>

			<v-checkbox v-if="payment.amount  && payment.amount < amountDue" dense hide-details :label="$t('invoice.mark-paid-modal.mark-fully-paid')" v-model="payment.forcePaidInFull"></v-checkbox>

		</div>
		<div class="modal-footer">
			<v-btn class="primary-action" @click="handleSave" :disabled="!payment.amount">{{
				$t('invoice.mark-paid-modal.save-button')
			}}</v-btn>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	// import ClientService from '@/modules/clients/ClientService';
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import DateSelector from "@/components/DateSelector";

	export default {
		name: 'MarkPaidModal',

		components: {DateSelector, ClientAvatar },
		props: { invoice: { type: Object, required: true } },

		data: function() {
			return {
				amountDue: this.invoice.amountDue,
				invoiceService: new InvoiceService(),
				client: null,
				checkboxConfirmed: false,
				payment: {
					amount: this.invoice.amountDue,
					datePaid: null,
					memo: null,
					currency: this.invoice.currency,
					paymentProvider: 'BANK_TRANSFER',
					forcePaidInFull: false,
				},
				datePaid: this.$DateTime
					.local()
					.toISO()
					.substr(0, 10),
				datePaidMenu: false,
				paymentProviders: [
					{ value: 'APP_PAYOUT', text: this.$t('invoice.payment-providers.APP_PAYOUT') },
					{ value: 'BANK_TRANSFER', text: this.$t('invoice.payment-providers.BANK_TRANSFER') },
					{ value: 'CASH', text: this.$t('invoice.payment-providers.CASH') },
					{ value: 'CHECK', text: this.$t('invoice.payment-providers.CHECK') },
					{ value: 'CREDIT_CARD', text: this.$t('invoice.payment-providers.CREDIT_CARD') },
					{ value: 'PAYPAL', text: this.$t('invoice.payment-providers.PAYPAL') },
					{ value: 'STRIPE', text: this.$t('invoice.payment-providers.STRIPE') },
					{ value: 'VENMO', text: this.$t('invoice.payment-providers.VENMO') },
					{ value: 'ZELLE', text: this.$t('invoice.payment-providers.ZELLE') },
					{ value: 'OTHER', text: this.$t('invoice.payment-providers.OTHER') }
				],
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			handleSave() {
				this.invoiceService
					.payment(this.invoice.clientId, this.invoice.id, this.payment)
					.then((res) => {
						this.$emit('save', res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},
		},
		computed: {
			currencySymbol: function() {
				return this.$formatters.currencySymbol(this.invoice.currency);
			},
		},
		watch: {
			datePaid: {
				handler() {
					this.payment.datePaid = this.$DateTime
						.fromISO(this.datePaid)
						.toISO()
						.substring(0, 10);
					console.log(this.payment.datePaid);
				},
			},
		},
	};
</script>

<style lang="scss">
	#mark-paid-modal {
		background-color: var(--v-white-base);
		max-width: 400px;
		min-width: 320px;
		.amount-due-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 16px;
			div {
				margin: 0 0 12px;
				&.amount-due-label {
					margin-right: 8px;
					font-size: 16px;
				}
				&.amount-due {
					margin-left: 8px;
					font-size: 40px;
					color: var(--v-gray_70-base);
				}
			}
		}
		.client-name {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;
			div {
				align-self: center;
				white-space: nowrap;
				flex: 0 0 auto;
			}
		}
		.h-outline.v-input.date-selector {
			padding-right: 10px;
		}
		.modal-footer {
			button {
				width: 200px;
			}
		}
	}
</style>
