var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "tippy", rawName: "v-tippy" }],
      staticClass: "quick-link-wrapper",
      attrs: { content: " + " + _vm.name }
    },
    [
      _c(
        "div",
        {
          staticClass: "quick-link pointer",
          style: "background-color: " + _vm.backgroundColor + ";"
        },
        [
          _c("h-icon2", {
            style: "--icon-color: " + _vm.color,
            attrs: { size: "30", name: _vm.icon }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }