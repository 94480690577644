var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "close-on-click": true, "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                _vm._g(
                  {
                    staticClass:
                      "row-format pointer font-14 brand-medium font-secondary align-center gap-1 px-3 py-1 nowrap",
                    staticStyle: {
                      "background-color": "var(--v-white-base)",
                      border: "1px solid var(--v-gray_20-base)",
                      "border-radius": "4px",
                      height: "38px"
                    }
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { size: "20", color: "secondary" } }, [
                    _vm._v("folder")
                  ]),
                  _vm._v(_vm._s(_vm.folder) + " ")
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.showMenu,
        callback: function($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu"
      }
    },
    [
      _c(
        "div",
        { staticClass: "more-menu" },
        [
          _c(
            "div",
            { staticClass: "pa-2" },
            [
              _c("v-text-field", {
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  placeholder: "Add new..."
                },
                on: {
                  blur: _vm.addNewFolder,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.addNewFolder.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.newFolder,
                  callback: function($$v) {
                    _vm.newFolder = $$v
                  },
                  expression: "newFolder"
                }
              })
            ],
            1
          ),
          _vm._l(_vm.folderList, function(thisFolder, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "more-menu-item",
                on: {
                  click: function($event) {
                    return _vm.setFolder(thisFolder)
                  }
                }
              },
              [_vm._v(" " + _vm._s(thisFolder) + " ")]
            )
          }),
          _c(
            "div",
            {
              staticClass: "more-menu-item",
              on: {
                click: function($event) {
                  return _vm.setFolder(null)
                }
              }
            },
            [_vm._v("--")]
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }