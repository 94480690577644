<template>
	<div style="max-width: 400px">
		<v-btn icon class="close-dialog" @click="handleClose">
			<v-icon>$close</v-icon>
		</v-btn>
		<v-form @submit.prevent="inviteUser()" v-model="emailValid">
			<div class="modal-padding">
				<div class="modal-title">{{ header }}</div>

				<div class="modal-subtitle mb-2" style="white-space: pre-wrap">{{ description }}</div>

				<v-text-field
					:rules="emailRules"
					v-model="userEmail"
					type="text"
					autofocus
					hide-details
					persistent-placeholder
					class="h-outline"
					label="Email"
				></v-text-field>

				<div v-if="type === 'RESTRICTED_USER'">
					<div class="modal-subtitle mb-1 mt-4">Feature access</div>
					<div
							style="max-height: 400px; overflow-y: auto; background-color: var(--v-gray_10-base); border: 1px solid var(--v-gray_50-base)"
							class="pa-3"
					>
						<v-checkbox label="Projects" hide-details dense v-model="featureAccess.projects"></v-checkbox>
						<v-checkbox label="Invoices" hide-details dense v-model="featureAccess.invoices"></v-checkbox>
						<v-checkbox label="Accounting" hide-details dense v-model="featureAccess.accounting"></v-checkbox>
						<v-checkbox label="Pipeline" hide-details dense v-model="featureAccess.pipeline"></v-checkbox>
						<v-checkbox label="Agreements" hide-details dense v-model="featureAccess.agreements"></v-checkbox>
						<v-checkbox label="Settings" hide-details dense v-model="featureAccess.settings"></v-checkbox>
						<v-checkbox label="Tickets/Requests" hide-details dense v-model="featureAccess.tickets"></v-checkbox>
						<v-checkbox label="Timesheets" hide-details dense v-model="featureAccess.timesheets"></v-checkbox>
					</div>
				</div>
				<div v-else-if="projects">
					<div class="modal-subtitle mb-1 mt-4">Select projects</div>
					<div
						v-if="projects.length > 0"
						style="max-height: 400px; overflow-y: auto; background-color: var(--v-gray_10-base); border: 1px solid var(--v-gray_50-base)"
						class="pa-3"
					>
						<div v-for="p in projects" :key="p.id" class="row-format align-center text-left mb-2">
							<input type="checkbox" v-model="projectList" :value="p.id" style="transform: scale(1.4)" :id="p.id" />
							<label :for="p.id">
								<div class="row-format align-center pointer">
									<client-avatar disable-click small :client="p.client" class="ml-3"></client-avatar>
									<div class="ml-3 font-14 ellipsis" style="width:300px">{{ p.name }}</div>
								</div>
							</label>
						</div>
					</div>
					<div
						v-else
						class="font-14 font-gray_70 pa-3"
						style="background-color: var(--v-gray_10-base); border: 1px solid var(--v-gray_50-base)"
					>
						You currently don't have any projects in your work space. You will need to create the project before you
						can invite the collaborators.
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<v-btn class="secondary-action mr-1" @click="$emit('result', null)">
					{{ $t('global.cancel') }}
				</v-btn>
				<v-btn :disabled="isDisabled" width="200" class="super-action ml-1" @click="inviteUser()">{{
					$t('global.invite')
				}}</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
	import UserService from '@/modules/users/UserService';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'InviteDialog',

		props: ['projectId', 'projects', 'type'],

		components: { ClientAvatar },

		data() {
			return {
				userEmail: null,
				emailValid: false,
				userService: new UserService(),
				projectList: [],
				fullUser: false,
				implementer: false,
				restrictedUser: false,
				emailRules: [
					(v) => !!v || 'Email is required',
					(v) => !v || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v.trim()) || 'E-mail must be valid',
				],
				featureAccess: {
					projects: true,
					invoices: true,
					accounting: true,
					pipeline: true,
					agreements: true,
					settings: true,
					timesheets: true,
					tickets: true,
				}
			};
		},

		mounted() {
			if(this.type === 'IMPLEMENTER') {
				this.implementer = true;
			}else if(this.type === 'RESTRICTED_USER') {
				this.restrictedUser = true;
			}else if(this.type === 'FULL_USER'){
				this.fullUser = true;
			}else {
				if (this.projectId) {
					this.projectList.push(this.projectId);
				}
			}
		},

		methods: {
			inviteUser: function() {
				let url = document.location.protocol + '//' + document.location.host + '/invitation';

				if(this.implementer){
					this.inviteImplementer(url);
				}else if (this.fullUser) {
					this.inviteFullUser(url);
				}else if(this.restrictedUser){
					this.inviteRestrictedUser(url);
				} else {
					this.inviteCollaborator(url);
				}
			},

			inviteImplementer: function(url) {
				this.$store.commit('startLoading');
				this.userService
						.inviteImplementer(this.$store.getters.getAccountId, this.userEmail.trim(), url)
						.then(() => {
							this.$track.recordServerSide('action', { module: 'add-implementer' });
							this.$emit('result', true);
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						})
						.finally(() => this.$store.commit('stopLoading'));
			},

			inviteFullUser: function(url) {
				this.$store.commit('startLoading');
				this.userService
					.inviteFullUser(this.$store.getters.getAccountId, this.userEmail.trim(), url)
					.then(() => {
						this.$track.recordServerSide('action', { module: 'add-full-user' });
						this.$emit('result', true);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			inviteRestrictedUser: function(url) {
				this.$store.commit('startLoading');
				this.userService
						.inviteRestrictedUser(this.$store.getters.getAccountId, this.userEmail.trim(), this.featureAccess, url)
						.then(() => {
							this.$track.recordServerSide('action', { module: 'add-restricted-user' });
							this.$emit('result', true);
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						})
						.finally(() => this.$store.commit('stopLoading'));
			},

			inviteCollaborator: function(url) {
				this.$store.commit('startLoading');
				this.userService
					.inviteProjectCollaborator(this.$store.getters.getAccountId, this.userEmail, this.projectList, url)
					.then(() => {
						this.$track.recordServerSide('action', { module: 'add-project-user' });
						this.$emit('result', true);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			handleClose: function() {
				this.$emit('result', null);
			},
		},

		computed: {
			description: function() {
				if(this.implementer){
					return 'Invite one of our pre-approved Moxie Implementers to help you build out your workspace. They will have full access to this Moxie account.\n\nIf you are interested in connecting with an approved implementer, please let us know in chat.';
				}else if (this.projectId || this.projects) {
					return 'You can invite a project team member here.  They will only have access to basic project information and the project management boards.';
				}else if(this.type === 'RESTRICTED_USER'){
					return 'You can invite a team member here. They will have access only to the features selected.';
				} else {
					return 'You can invite a team member here. They will have full access to this Moxie account.';
				}
			},

			isDisabled: function() {
				if (!this.emailValid) {
					return true;
				}else if(this.implementer || this.fullUser || this.restrictedUser){
					return false;
				} else if (this.projectList.length > 0) {
					return false;
				} else {
					return true;
				}
			},

			header: function() {
				if(this.implementer){
					return 'Invite official Moxie implementer';
				}else if (this.projectId || this.projects) {
					return 'Invite project team member';
				} else {
					return 'Invite team member';
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
