var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "pointer",
        staticStyle: {
          width: "100%",
          "border-bottom": "1px solid var(--v-gray_30-base)"
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.expandAccountList = !_vm.expandAccountList
          }
        }
      },
      [
        _c(
          "v-container",
          {
            staticClass: "ma-0 pa-0",
            style:
              _vm.expandAccountList &&
              _vm.$store.getters.getUserAccounts.length > 1
                ? "border-bottom: 1px solid var(--v-gray_30-base)"
                : "",
            attrs: { fluid: "" }
          },
          _vm._l(_vm.accountList, function(account, index) {
            return _c(
              "v-row",
              {
                key: account.accountId,
                staticClass: "ma-0 pt-2 pb-2 pl-3",
                style:
                  index === 0
                    ? "background: var(--v-gray_10-base)"
                    : "background: var(--v-gray_5-base)",
                attrs: { dense: "", align: "center" }
              },
              [
                _c("v-col", { attrs: { cols: "10", align: "left" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format pointer align-center",
                      staticStyle: { "align-items": "center" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.expandAccountList
                            ? _vm.handleAccountSelection(account.accountId)
                            : (_vm.expandAccountList = true)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "v-avatar",
                            { attrs: { size: "25", color: "white" } },
                            [
                              account.accountLogo
                                ? _c("img", {
                                    attrs: {
                                      src: account.accountLogo,
                                      alt: "logo"
                                    }
                                  })
                                : _c(
                                    "span",
                                    { staticClass: "black--text brand-bold" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getAccountInitials(
                                            account.accountName
                                          )
                                        )
                                      )
                                    ]
                                  )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          class: "pl-2 " + (index === 0 ? "black--text" : ""),
                          staticStyle: { "margin-left": "-2px" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "font-weight": "600"
                              }
                            },
                            [_vm._v(_vm._s(account.accountName))]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                index === 0
                  ? _c(
                      "v-col",
                      {
                        staticClass: "pointer",
                        attrs: { cols: "2", align: "right" }
                      },
                      [
                        _vm.$store.getters.getUserAccounts.length > 1
                          ? _c(
                              "v-icon",
                              {
                                staticStyle: { "margin-right": "12px" },
                                attrs: { color: "black", size: "25" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.expandAccountList
                                      ? "unfold_less"
                                      : "unfold_more"
                                  )
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }