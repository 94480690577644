var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-8", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", align: "center" } },
            [
              _c("span", [
                _vm._v(
                  "To complete your email change request, please enter the 6 digit verification code that was sent to " +
                    _vm._s(_vm.email) +
                    "."
                )
              ]),
              _c("br"),
              _c("br"),
              _c("code-input", {
                ref: "codeInput",
                attrs: {
                  disabled: _vm.disabled,
                  fields: 6,
                  "auto-focus": false,
                  required: true,
                  id: "code-input"
                },
                on: { complete: _vm.codeComplete }
              }),
              _vm.invalid
                ? _c("span", { staticStyle: { color: "red" } }, [
                    _c("br"),
                    _vm._v("The code you entered was not valid.")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }