<template>
	<div class="column-format gap-3 px-2">
		<div class="row-format align-center">
			<div class="font-18 brand-medium">Edit {{componentType}}</div>
			<div class="ml-auto row-format align-center gap-2">
				<v-icon size="20" class="material-symbols-rounded pointer" @click="$emit('duplicate')">content_copy</v-icon>
				<v-icon size="20" class="material-symbols-rounded pointer" @click="$emit('delete')">delete</v-icon>
				<v-btn small elevation="0" color="primary" @click="$emit('done')">Done</v-btn>
			</div>
		</div>
		<div  style="min-height: calc(100vh - 120px); max-height: calc(100vh - 120px); overflow-y: auto" class="show-scrollbar pr-2">
			<component :key="item.type" :is="item.type + 'Config'" :item="item" :agreement="agreement" :agreement-service="agreementService" @change="$emit('change',$event)"></component>
		</div>
	</div>
</template>

<script>
	import ContainerConfig from "@/modules/agreements/config/ContainerConfig";
	import DividerConfig from "@/modules/agreements/config/DividerConfig";
	import SpacerConfig from "@/modules/agreements/config/SpacerConfig";
	import ImageBlockConfig from "@/modules/agreements/config/ImageBlockConfig";
	import TextBlockConfig from "@/modules/agreements/config/TextBlockConfig";
	import LogoConfig from "@/modules/agreements/config/LogoConfig";
	import TermsConfig from "@/modules/agreements/config/TermsConfig";
	import ServicesConfig from "@/modules/agreements/config/ServicesConfig";
	import SignatureConfig from "@/modules/agreements/config/SignatureConfig";
	import FormEmbedConfig from "@/modules/agreements/config/FormEmbedConfig";
	import SchedulerEmbedConfig from "@/modules/agreements/config/SchedulerEmbedConfig";
	import PaymentConfig from "@/modules/agreements/config/PaymentConfig";
	import AcceptButtonConfig from "@/modules/agreements/config/AcceptButtonConfig";
	import PaymentPlanConfig from "@/modules/agreements/config/PaymentPlanConfig";
	import PaymentV2Config from "@/modules/agreements/config/PaymentV2Config";

	export default {
		name: 'ConfigRenderer',

		props: ['agreement','item','tokens','agreementService'],

		components: {
			ContainerConfig,
			DividerConfig,
			ImageBlockConfig,
			SpacerConfig,
			TextBlockConfig,
			LogoConfig,
			TermsConfig,
			ServicesConfig,
			SignatureConfig,
			FormEmbedConfig,
			SchedulerEmbedConfig,
			PaymentConfig,
			AcceptButtonConfig,
			PaymentPlanConfig,
			PaymentV2Config
		},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {

		},

		computed: {
			componentType: function(){
				if(this.item.type === 'Spacer'){
					return 'spacer';
				}else if(this.item.type === 'Divider'){
					return 'divider';
				}else if(this.item.type === 'Container') {
					return 'container';
				}else if(this.item.type === 'ImageBlock') {
					return 'image';
				}else if(this.item.type === 'TextBlock'){
					return 'text';
				}else if(this.item.type === 'Logo'){
					return 'logo';
				}else if(this.item.type === 'LineItems'){
					return 'line items';
				}else if(this.item.type === 'Terms') {
					return 'contract terms';
				}else if(this.item.type === 'Services'){
					return 'services';
				}else if(this.item.type === 'Signature'){
					return 'signature';
				}else if(this.item.type === 'FormEmbed'){
					return 'embedded form';
				}else if(this.item.type === 'SchedulerEmbed'){
					return 'embedded scheduler';
				}else if(this.item.type === 'Payment'){
					return 'integrated payment';
				}else if(this.item.type === 'AcceptButton'){
					return 'accept button';
				}else if(this.item.type === 'PaymentPlan'){
					return 'payment plan';
				}else{
					return ''
				}
			}
		},
	};
</script>

<style lang="scss">
</style>
