var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left column-format font-14 gap-2" },
    [
      _vm._m(0),
      _c("v-switch", {
        staticClass: "mt-0 mb-2",
        attrs: {
          "hide-details": "",
          dense: "",
          label: "Automatically create projects and invoices"
        },
        model: {
          value: _vm.agreement.settings.createProjectsAndInvoices,
          callback: function($$v) {
            _vm.$set(_vm.agreement.settings, "createProjectsAndInvoices", $$v)
          },
          expression: "agreement.settings.createProjectsAndInvoices"
        }
      }),
      _vm.agreement.settings.createProjectsAndInvoices
        ? _c(
            "div",
            { staticClass: "column-format gap-2" },
            [
              _c("v-switch", {
                staticClass: "mt-0 mb-2",
                attrs: {
                  "hide-details": "",
                  dense: "",
                  label: "Review invoice before sending"
                },
                model: {
                  value: _vm.agreement.settings.invoiceApprovalRequired,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.agreement.settings,
                      "invoiceApprovalRequired",
                      $$v
                    )
                  },
                  expression: "agreement.settings.invoiceApprovalRequired"
                }
              }),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.invoiceTemplates,
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Invoice template (optional)",
                  "item-text": "name",
                  "item-value": "id"
                },
                model: {
                  value: _vm.agreement.settings.invoiceTemplateId,
                  callback: function($$v) {
                    _vm.$set(_vm.agreement.settings, "invoiceTemplateId", $$v)
                  },
                  expression: "agreement.settings.invoiceTemplateId"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "row-format align-center mb-3 pb-3 gap-2" },
            [
              _c("v-icon", { attrs: { color: "warning" } }, [
                _vm._v("warning")
              ]),
              _c("div", [
                _vm._v(
                  "Automatic invoice and project creation is disabled for this agreement."
                )
              ])
            ],
            1
          ),
      _c(
        "div",
        {
          staticClass: "column-format my-3 py-3 gap-2",
          staticStyle: {
            "border-bottom": "1px solid var(--v-gray_50-base)",
            "border-top": "1px solid var(--v-gray_50-base)"
          }
        },
        [
          _c("v-switch", {
            staticClass: "mt-0 mb-2",
            attrs: {
              "hide-details": "",
              dense: "",
              label: "Require package selection to finalize"
            },
            model: {
              value: _vm.agreement.settings.requireMinimumPackageSelection,
              callback: function($$v) {
                _vm.$set(
                  _vm.agreement.settings,
                  "requireMinimumPackageSelection",
                  $$v
                )
              },
              expression: "agreement.settings.requireMinimumPackageSelection"
            }
          }),
          _c("v-textarea", {
            attrs: {
              disabled: !_vm.agreement.settings.requireMinimumPackageSelection,
              rows: "4",
              "auto-grow": "",
              "hide-details": "",
              dense: "",
              outlined: "",
              "persistent-placeholder": "",
              label: "Error message if no selection"
            },
            model: {
              value: _vm.agreement.settings.noPackageSelectionError,
              callback: function($$v) {
                _vm.$set(_vm.agreement.settings, "noPackageSelectionError", $$v)
              },
              expression: "agreement.settings.noPackageSelectionError"
            }
          })
        ],
        1
      ),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Completed/Finished button text"
        },
        model: {
          value: _vm.agreement.settings.finishedButton,
          callback: function($$v) {
            _vm.$set(_vm.agreement.settings, "finishedButton", $$v)
          },
          expression: "agreement.settings.finishedButton"
        }
      }),
      _c("v-autocomplete", {
        attrs: {
          items: _vm.emailTemplates,
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Confirmation email",
          "item-text": "name",
          "item-value": "id"
        },
        model: {
          value: _vm.agreement.settings.confirmationEmailTemplateId,
          callback: function($$v) {
            _vm.$set(_vm.agreement.settings, "confirmationEmailTemplateId", $$v)
          },
          expression: "agreement.settings.confirmationEmailTemplateId"
        }
      }),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Post signature redirect URL",
          placeholder: "https://yourwebsite.com"
        },
        model: {
          value: _vm.agreement.settings.redirectUrl,
          callback: function($$v) {
            _vm.$set(_vm.agreement.settings, "redirectUrl", $$v)
          },
          expression: "agreement.settings.redirectUrl"
        }
      }),
      _c("v-textarea", {
        attrs: {
          rows: "4",
          "auto-grow": "",
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Signature confirmation message"
        },
        model: {
          value: _vm.agreement.settings.signedConfirmationMessage,
          callback: function($$v) {
            _vm.$set(_vm.agreement.settings, "signedConfirmationMessage", $$v)
          },
          expression: "agreement.settings.signedConfirmationMessage"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-format pt-1 pb-2 text-left" }, [
      _c("div", { staticClass: "brand-bold font-18" }, [_vm._v("Settings")]),
      _c("div", { staticClass: "font-14 font-gray_80" }, [
        _vm._v("Automations and confirmations")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }