<template>
	<div>
	</div>
</template>

<script>
import AiAssistantService from "@/modules/ai/AiAssistantService";

export default {
	name: "AiEntitlementStateManager",

	props: [],

	components: {},

	data: function () {
		return {
			aiAssistantService: new AiAssistantService(),
		}
	},

	mounted() {
		this.getAiEntitlement();
		this.$store.state.eventBus.$on('account-changed', this.getAiEntitlement);
		this.$store.state.eventBus.$on(this.$store.getters.getMessageChannelPrefix + '.entitlement-ai',this.updateAiEntitlement);
	},

	beforeDestroy() {
		this.$store.state.eventBus.$off('account-changed', this.getAiEntitlement);
		this.$store.state.eventBus.$off(this.$store.getters.getMessageChannelPrefix + '.entitlement-ai',this.updateAiEntitlement);
	},

	methods: {
		getAiEntitlement: function() {
			this.aiAssistantService.getEntitlementStatus().then((res) => this.$store.state.aiEntitlement = res.data);
		},

		updateAiEntitlement: function(event){
			this.$store.state.aiEntitlement = event.message;
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>