var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.event_type,
            expression: "event_type"
          }
        ],
        ref: "event_type",
        attrs: { type: "hidden", "df-event_type": "" },
        domProps: { value: _vm.event_type },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.event_type = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.enable_max_wait,
            expression: "enable_max_wait"
          }
        ],
        ref: "enable_max_wait",
        attrs: { type: "hidden", "df-enable_max_wait": "" },
        domProps: { value: _vm.enable_max_wait },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.enable_max_wait = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.wait_period,
            expression: "wait_period"
          }
        ],
        ref: "wait_period",
        attrs: { type: "hidden", "df-wait_period": "" },
        domProps: { value: _vm.wait_period },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.wait_period = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.wait_duration,
            expression: "wait_duration"
          }
        ],
        ref: "wait_duration",
        attrs: { type: "hidden", "df-wait_duration": "" },
        domProps: { value: _vm.wait_duration },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.wait_duration = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.variable_date_category,
            expression: "variable_date_category"
          }
        ],
        ref: "variable_date_category",
        attrs: { type: "hidden", "df-variable_date_category": "" },
        domProps: { value: _vm.variable_date_category },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.variable_date_category = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.variable_date_field,
            expression: "variable_date_field"
          }
        ],
        ref: "variable_date_field",
        attrs: { type: "hidden", "df-variable_date_field": "" },
        domProps: { value: _vm.variable_date_field },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.variable_date_field = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.variable_date_modifier,
            expression: "variable_date_modifier"
          }
        ],
        ref: "variable_date_modifier",
        attrs: { type: "hidden", "df-variable_date_modifier": "" },
        domProps: { value: _vm.variable_date_modifier },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.variable_date_modifier = $event.target.value
          }
        }
      }),
      _c("node-label", {
        on: {
          open: function($event) {
            return _vm.$refs.nodeModal.open()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded mr-1",
                    attrs: { color: "black", size: "20" }
                  },
                  [_vm._v("hourglass_top")]
                ),
                _c("div", [_vm._v("Wait for: " + _vm._s(_vm.label))])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("node-modal", {
        ref: "nodeModal",
        attrs: { title: "Wait for" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "column-format gap-2",
                    staticStyle: { width: "500px", "min-width": "500px" }
                  },
                  [
                    _c("v-select", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        items: _vm.waitForEvent,
                        "item-text": "label",
                        "item-value": "value"
                      },
                      model: {
                        value: _vm.event_type,
                        callback: function($$v) {
                          _vm.event_type = $$v
                        },
                        expression: "event_type"
                      }
                    }),
                    _vm.event_type === "VariableDate"
                      ? _c("div", { staticClass: "column-format gap-2" }, [
                          _c(
                            "div",
                            { staticClass: "row-format align-center gap-2" },
                            [
                              _c("v-select", {
                                staticStyle: { flex: "1 1 0" },
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  outlined: "",
                                  label: "Category",
                                  items: _vm.variableDateCategories,
                                  "item-text": "label",
                                  "item-value": "value"
                                },
                                model: {
                                  value: _vm.variable_date_category,
                                  callback: function($$v) {
                                    _vm.variable_date_category = $$v
                                  },
                                  expression: "variable_date_category"
                                }
                              }),
                              _c("v-select", {
                                staticStyle: { flex: "1 1 0" },
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  outlined: "",
                                  label: "Field",
                                  items: _vm.variableDateFields,
                                  "item-text": "label",
                                  "item-value": "value"
                                },
                                model: {
                                  value: _vm.variable_date_field,
                                  callback: function($$v) {
                                    _vm.variable_date_field = $$v
                                  },
                                  expression: "variable_date_field"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format gap-2 align-center" },
                            [
                              _c("v-select", {
                                staticStyle: { flex: "1 1 0" },
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                  items: ["Plus", "Minus"]
                                },
                                model: {
                                  value: _vm.variable_date_modifier,
                                  callback: function($$v) {
                                    _vm.variable_date_modifier = $$v
                                  },
                                  expression: "variable_date_modifier"
                                }
                              }),
                              _c("v-text-field", {
                                staticStyle: { flex: "1 1 0" },
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                  type: "number"
                                },
                                on: { blur: _vm.sanitizeDuration },
                                model: {
                                  value: _vm.wait_duration,
                                  callback: function($$v) {
                                    _vm.wait_duration = $$v
                                  },
                                  expression: "wait_duration"
                                }
                              }),
                              _c("v-select", {
                                staticStyle: { flex: "1 1 0" },
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                  items: [
                                    "Minutes",
                                    "Hours",
                                    "Days",
                                    "Weeks",
                                    "Months"
                                  ]
                                },
                                model: {
                                  value: _vm.wait_period,
                                  callback: function($$v) {
                                    _vm.wait_period = $$v
                                  },
                                  expression: "wait_period"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _c(
                          "div",
                          [
                            _vm.event_type !== "TimePeriod"
                              ? _c("v-checkbox", {
                                  staticClass: "mt-n1",
                                  attrs: {
                                    "hide-details": "",
                                    dense: "",
                                    "true-value": "true",
                                    "false-value": "false",
                                    label: "Set maximum wait time"
                                  },
                                  model: {
                                    value: _vm.enable_max_wait,
                                    callback: function($$v) {
                                      _vm.enable_max_wait = $$v
                                    },
                                    expression: "enable_max_wait"
                                  }
                                })
                              : _vm._e(),
                            _vm.event_type === "TimePeriod" ||
                            _vm.enable_max_wait === "true"
                              ? _c(
                                  "div",
                                  { staticClass: "row-format gap-2" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        outlined: "",
                                        type: "number"
                                      },
                                      on: { blur: _vm.sanitizeDuration },
                                      model: {
                                        value: _vm.wait_duration,
                                        callback: function($$v) {
                                          _vm.wait_duration = $$v
                                        },
                                        expression: "wait_duration"
                                      }
                                    }),
                                    _c("v-select", {
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        outlined: "",
                                        items: [
                                          "Minutes",
                                          "Hours",
                                          "Days",
                                          "Weeks",
                                          "Months"
                                        ]
                                      },
                                      model: {
                                        value: _vm.wait_period,
                                        callback: function($$v) {
                                          _vm.wait_period = $$v
                                        },
                                        expression: "wait_period"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }