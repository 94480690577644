import { render, staticRenderFns } from "./DateInputConfig.vue?vue&type=template&id=3edafb1c&scoped=true&"
import script from "./DateInputConfig.vue?vue&type=script&lang=js&"
export * from "./DateInputConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3edafb1c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VSwitch,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3589467384/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3edafb1c')) {
      api.createRecord('3edafb1c', component.options)
    } else {
      api.reload('3edafb1c', component.options)
    }
    module.hot.accept("./DateInputConfig.vue?vue&type=template&id=3edafb1c&scoped=true&", function () {
      api.rerender('3edafb1c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/forms/config/DateInputConfig.vue"
export default component.exports