import HttpClient from '@/services/HttpClient';
import store from "@/store";
import GoogleEmailService from "@/modules/integrations/google/GoogleEmailService";

export default class GoogleCommunicatorService extends GoogleEmailService{
    httpClient;

    constructor() {
        super();
        this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
    }

    connect(code,scopes) {
        let formData = new FormData();
        formData.append('code',code);
        formData.append('scopes',scopes);

        return this.httpClient
            .post(`/emailBox/oAuth/google`,formData,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    reconnect(id,code,scopes){
        let formData = new FormData();
        formData.append('code',code);
        formData.append('scopes',scopes);

        return this.httpClient
            .post(`/emailBox/${id}/oAuth/google`,formData,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}