import HttpClient from '@/services/HttpClient';

export default class StripeSubscriptionService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getSubscriptions(clientId = null) {
		return this.httpClient
			.get('/stripe/subscriptions', {
				params: {
					clientId: clientId,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSubscriptionPayments(subscriptionId) {
		return this.httpClient
			.get(`/stripe/subscriptions/${subscriptionId}/payments`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	cancelSubscription(subscriptionId) {
		return this.httpClient
			.delete(`/stripe/subscriptions/${subscriptionId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProducts() {
		return this.httpClient
			.get('/stripe/products')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTaxRates() {
		return this.httpClient
			.get('/stripe/taxRates')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

    createProduct(product) {
        return this.httpClient
            .post('/stripe/products',product)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

	updateProduct(product) {
		return this.httpClient
			.put('/stripe/products',product)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

    getSubscriptionRequests(clientId = null){
        return this.httpClient
            .get('/stripe/subscriptionRequest', {
                params: {
                    clientId: clientId,
                },
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    createSubscriptionRequest(clientId,taxRateId,request) {
        return this.httpClient
            .post('/stripe/subscriptionRequest',request,{
                params: {
                    clientId: clientId,
					taxRateId: taxRateId
                }
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    reSendSubscriptionRequest(subscriptionRequestId,email){
        return this.httpClient
            .post(`/stripe/subscriptionRequest/${subscriptionRequestId}`,email)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    deleteSubscriptionRequest(subscriptionRequestId){
        return this.httpClient
            .delete(`/stripe/subscriptionRequest/${subscriptionRequestId}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}
