<template>
	<div class="pb-4">
		<div>
			<div class="row-format">
				<v-text-field
						hide-details
						dense
						outlined
						label="Business / Organization name"
						v-if="showBusiness"
						:disabled="previewMode"
				></v-text-field>
				<v-text-field
						hide-details
						dense
						outlined
						:label="$t('contact.website')"
						v-if="showWebsite"
						:class="showBusiness && showWebsite ? 'ml-4' : ''"
						:disabled="true"
				></v-text-field>
			</div>

			<div class="row-format my-2" v-if="showContact">
				<v-text-field
						label="First name"
						hide-details
						dense
						outlined
						class="mr-2"
						:disabled="previewMode"
				></v-text-field>
				<v-text-field
						label="Last name"
						hide-details
						dense
						outlined
						class="ml-2"
						:disabled="previewMode"
				></v-text-field>
			</div>
			<div class="row-format my-2">
				<v-text-field
						label="Email"
						hide-details
						dense
						outlined
						v-if="showEmail"
						:disabled="previewMode"
				></v-text-field>
				<v-text-field
						hide-details
						dense
						outlined
						label="Phone"
						:class="showEmail && showPhone ? 'ml-4' : ''"
						v-if="showPhone"
						:disabled="previewMode"
				></v-text-field>
			</div>
			<div v-if="showAddress" class="my-2">
				<div class="row-format">
					<v-text-field
							hide-details
							dense
							outlined
							class="mr-4"
							:label="$t('contact.address1')"
							:disabled="true"
					></v-text-field>
					<v-text-field hide-details dense outlined :label="$t('contact.address2')" :disabled="true"></v-text-field>
				</div>

				<div class="row-format my-2">
					<v-text-field
							hide-details
							dense
							outlined
							class="mr-4"
							:label="$t('contact.city')"
							:disabled="true"
					></v-text-field>
					<v-text-field  hide-details dense outlined :label="$t('contact.locality')" :disabled="true" />
				</div>

				<div class="row-format my-2">
					<v-text-field  hide-details dense outlined :label="$t('contact.postal')" :disabled="true"></v-text-field>

					<div class="ml-4" style="width: 49%">
						<v-autocomplete
								hide-details
								dense
								outlined
								:disabled="true"
								:label="$t('contact.country')"
								:items="countries.getCountriesSimple()"
								item-value="code"
								item-text="name"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="row-format">
			<div class="row-format" style="flex-wrap: wrap">
				<v-checkbox class="mr-12" dense hide-details v-for="option in captureOptions" :key="option.value" :label="option.label" v-model="option.selected" @change="updateOptions($event,option)"></v-checkbox>
			</div>
			<div style="max-width: 95px; min-width:95px" class="mt-2">
				<v-select :items="requiredOptions" item-text="label" item-value="value" v-model="ourRequired" outlined persistent-placeholder dense hide-details label="Required" @change="handleChange()">
				</v-select>
			</div>
		</div>

	</div>
</template>

<script>
	import Countries from '@/modules/utils/Countries.js';
	export default {
		name: 'LeadContact',
		props: ['id', 'options', 'htmlText', 'fieldType', 'placeHolder', 'mceConfigHeader', 'mceConfigText', 'previewMode','required'],

		components: { },

		mounted() {
			this.captureOptions.forEach(o => {
				if(this.ourOptions.includes(o.value)){
					o.selected = true;
				}
			})
		},

		data() {
			return {
				countries: new Countries(),
				captureOptions: [
					{label: 'Business name', value: 'BusinessName', selected: false},
					{label: 'Contact name', value: 'ContactName', selected: false},
					{label: 'Phone', value: 'Phone', selected: false},
					{label: 'Email', value: 'Email', selected: false},
					{label: 'Website', value: 'Website', selected: false},
					{label: 'Address', value: 'Address', selected: false},
				],
				requiredOptions: [
					{label: 'Yes', value: true},
					{label: 'No', value: false}
				],
				ourOptions: [... this.options.filter(o => o !== '')],
				ourRequired: this.required
			};
		},

		methods: {
			handleChange: function () {
				this.$emit('changed', {
					id: this.id,
					fieldType: this.fieldType,
					options: this.ourOptions,
					required: this.ourRequired
				});
			},

			updateOptions: function(event,option){
				let ix = this.ourOptions.indexOf(option.value);
				if(!event && ix > -1){
					this.ourOptions.splice(ix,1);
				}else if(event){
					this.ourOptions.push(option.value);
				}
				this.handleChange();
			},

			getSelected: function(key){
				return this.captureOptions.find(o => o.value === key).selected;
			}
		},

		computed: {
			showBusiness: function(){
				return this.getSelected('BusinessName');
			},
			showContact: function(){
				return this.getSelected('ContactName');
			},
			showPhone: function(){
				return this.getSelected('Phone');
			},
			showEmail: function(){
				return this.getSelected('Email');
			},
			showWebsite: function(){
				return this.getSelected('Website');
			},
			showAddress: function(){
				return this.getSelected('Address');
			}
		},
	};
</script>

<style scoped lang="scss">
	.textArea ::v-deep .v-input__slot {
		//border-bottom: 1px solid var(--v-gray_60-base) !important;
	}

	.textArea ::v-deep v-text-field > .v-input__control > .v-input__slot:before {
		border-style: none;
	}
	.textArea ::v-deep v-text-field > .v-input__control > .v-input__slot:after {
		border-style: none;
	}

	.question {
		font-size: 16px;
		font-weight: 300;
	}

	.words {
		font-size: 16px;
	}
</style>
