<template>
	<v-container class="confirmation" style="min-height:250px">
		<v-row>
			<v-col cols="12" class="column-format">
				<div v-for="(reminder, index) in meeting.reminders" :key="index" class="column-format gap-2 text-left mb-4">
					<div class="row-format">
						<div class="brand-medium font-14">Notification {{index + 1}}</div>
						<v-icon class="ml-auto material-symbols-rounded pointer" size="20" color="gray_70" @click="deleteReminder(index)">delete</v-icon>
					</div>
					<v-select
						class=""
						hide-details
						dense
						outlined
						persistent-placeholder
						label="Type"
						:items="reminderTypes"
						item-text="label"
						item-value="value"
						v-model="reminder.reminderType"
					></v-select>
					<div class="row-format gap-2">
						<duration-selector class="flex-grow-1"
							label="Timing"
							v-model="reminder.duration"
							:options="reminder.before ? reminderIncrementsBefore : reminderIncrementsAfter"
						></duration-selector>
						<v-select style="max-width: 200px" v-model="reminder.before" outlined hide-details dense persistent-placeholder label="Notification timing" :items="remindTiming"></v-select>
					</div>
					<v-select v-if="reminder.reminderType === 'EMAIL'"
							hide-details
							persistent-placeholder
							dense
							outlined
							class=""
							label="Email template (required)"
							:items="emailTemplates"
							item-value="id"
							item-text="name"
							clearable
							v-model="reminder.emailTemplateId"
					></v-select>
					<div v-if="reminder.reminderType === 'SMS'" class="column-format gap-2">
						<v-select
								hide-details
								dense
								outlined
								persistent-placeholder
								label="From"
								:items="subscriptions"
								item-value="userId"
								v-model="reminder.userId"
						>
							<template v-slot:item="{item}">
								<div>{{item.phone}} - {{item.user.firstName}} {{item.user.lastName}}</div>
							</template>
							<template v-slot:selection="{item}">
								<div>{{item.phone}} - {{item.user.firstName}} {{item.user.lastName}}</div>
							</template>
						</v-select>
						<div class="row-format gap-2">
							<v-textarea style="flex: 1 1 0" :rows="3" auto-grow v-model="reminder.smsMessage" placeholder="Message" hide-details dense persistent-placeholder outlined></v-textarea>
							<div class="row-format flex-wrap gap-1" style="flex: 1 1 0; align-content: flex-start">
								<v-chip v-for="token in tokens" :key="token.v" small @click="appendToken(reminder,token)">{{token.t}}</v-chip>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!meeting.reminders.length" class="font-gray_50">
					No notifications configured
				</div>
				<v-btn @click="addReminder" text class="mb-3">+ Add notification</v-btn>
			</v-col>
			<v-col class="column-format gap-3 pt-3" style="border-top: 1px solid var(--v-gray_30-base)">
				<div class="brand-medium font-14">Confirmations</div>
				<v-select
					:disabled="emailTemplates.length === 0"
					hide-details
					persistent-placeholder
					dense
					outlined
					class=""
					label="Confirmation email template"
					:items="emailTemplates"
					item-value="id"
					item-text="name"
					clearable
					v-model="meeting.confirmationEmailTemplate"
				></v-select>

				<div v-if="subscriptions.length" class="column-format gap-2">
					<v-select
							hide-details
							dense
							outlined
							clearable
							persistent-placeholder
							label="Send SMS confirmation from"
							:items="subscriptions"
							item-value="userId"
							v-model="meeting.confirmationSmsUserId"
					>
						<template v-slot:item="{item}">
							<div>{{item.phone}} - {{item.user.firstName}} {{item.user.lastName}}</div>
						</template>
						<template v-slot:selection="{item}">
							<div>{{item.phone}} - {{item.user.firstName}} {{item.user.lastName}}</div>
						</template>
					</v-select>
					<div class="row-format gap-2" v-if="meeting.confirmationSmsUserId">
						<v-textarea style="flex: 1 1 0" :rows="3" auto-grow v-model="meeting.confirmationSmsMessage" placeholder="SMS confirmation message" hide-details dense persistent-placeholder outlined></v-textarea>
						<div class="row-format flex-wrap gap-1" style="flex: 1 1 0; align-content: flex-start">
							<v-chip v-for="token in tokens" :key="token.v" small @click="appendConfirmationToken(token)">{{token.t}}</v-chip>
						</div>
					</div>
				</div>

				<v-select
					label="After scheduling meeting, navigate to"
					persistent-placeholder
					hide-details
					dense
					outlined
					class=""
					:items="pageOptions"
					v-model="meeting.defaultConfirmationPage"
				>
				</v-select>

				<v-text-field
					v-if="!meeting.defaultConfirmationPage"
					label="Redirect URL"
					placeholder="http://www.yourwebsite.com/confirmation"
					persistent-placeholder
					hide-details
					dense
					outlined
					v-model="meeting.confirmationRedirect"
				></v-text-field>

				<v-checkbox
					v-if="!meeting.defaultConfirmationPage"
					v-model="meeting.appendInfoToRedirect"
					:false-value="false"
					:true-value="true"
					hide-details
					dense
					label="Append meeting info to URL"
				></v-checkbox>

				<div
					v-if="!meeting.defaultConfirmationPage && meeting.appendInfoToRedirect"
					class="text-left font-14 mt-3 font-gray_80"
				>
					The following URL parameters will be appended to the redirect:<br />
					<ul>
						<li><span class="brand-medium">id</span>: &nbsp;&nbsp;Unique meeting id</li>
						<li><span class="brand-medium">start</span>: &nbsp;&nbsp;ISO formatted date/time</li>
						<li><span class="brand-medium">duration</span>: &nbsp;&nbsp;Length of meeting in minutes</li>
						<li><span class="brand-medium">location</span>: &nbsp;&nbsp;Description of meeting location</li>
						<li><span class="brand-medium">email</span>: &nbsp;&nbsp;Invitee email address</li>
						<li><span class="brand-medium">name</span>: &nbsp;&nbsp;Invitee name</li>
					</ul>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import DurationSelector from '@/modules/meetings/detail/components/DurationSelector';
	import CommunicatorService from "@/modules/communicator/CommunicatorService";
	export default {
		name: 'Confirmation',

		props: ['meeting', 'emailTemplates'],

		components: { DurationSelector },

		data: function() {
			return {
				communicatorService: new CommunicatorService(),
				subscriptions: []
			};
		},

		mounted() {
			this.getSubscriptions();
		},

		beforeDestroy() {},

		methods: {
			appendToken: function(reminder,token){
				reminder.smsMessage = reminder.smsMessage + ' {{Meeting.' + token.v + '}} '
			},

			appendConfirmationToken(token){
				this.meeting.confirmationSmsMessage = this.meeting.confirmationSmsMessage +  ' {{Meeting.' + token.v + '}} '
			},

			getSubscriptions: function(){
				this.communicatorService.getSubscriptionsForAccount().then((res) => {
					this.subscriptions.splice(0,this.subscriptions.length);
					res.data.forEach(s => {
						this.subscriptions.push({
							id: s.id,
							userId: s.userId,
							user: this.$store.getters.getUserById(s.userId),
							phone: s.phone
						})
					});
				})
			},

			deleteReminder: function(index){
				this.meeting.reminders.splice(index,1);
			},

			addReminder: function() {
				this.meeting.reminders.push({
					duration: {
						duration: 30,
						timeUnit: 'MINUTES',
					},
					reminderType: 'NATIVE',
					emailTemplateId: null,
					userId: null,
					smsMessage: null,
					before: true
				});
			},
		},


		computed: {
			reminderTypes: function() {
				let result =  [
					{ label: 'Native Calendar', value: 'NATIVE' },
					{ label: 'Email', value: 'EMAIL' },
				];

				if(this.subscriptions.length){
					result.push({ label: 'Text message', value: 'SMS' });
				}

				return result;
			},

			tokens: function(){
				return [
					{t:'Meeting name',v:'SchedulerName'},
					{t:'First',v:'FirstName'},
					{t:'Last',v:'LastName'},
					{t:'Start time',v:'Start'},
					{t:'Duration',v:'Duration'},
					{t:'Location',v:'Location'},
					{t:'Cancel Url',v:'CancelUrl'},
					{t:'Change Url',v:'ChangeUrl'},
				]
			},

			remindTiming: function() {
				return [
					{ text: 'Before meeting', value: true },
					{ text: 'After meeting', value: false },
				];
			},

			pageOptions: function() {
				return [
					{ text: 'Default Moxie confirmation page', value: true },
					{ text: 'Redirect to custom URL', value: false },
				];
			},

			reminderIncrementsBefore: function() {
				return [
					{ label: '5 minutes before meeting starts', value: '5:MINUTES' },
					{ label: '15 minutes before meeting starts', value: '15:MINUTES' },
					{ label: '30 minutes before meeting starts', value: '30:MINUTES' },
					{ label: '1 hour before meeting starts', value: '1:HOURS' },
					{ label: '2 hours before meeting starts', value: '2:HOURS' },
					{ label: '6 hours before meeting starts', value: '6:HOURS' },
					{ label: '1 day before meeting starts', value: '1:DAYS' },
					{ label: '1 week before meeting starts', value: '7:DAYS' },
					{ label: '2 weeks before meeting starts', value: '14:DAYS' },
					{ label: 'Custom (before meeting starts)', value: 'CUSTOM' },
				];
			},

			reminderIncrementsAfter: function() {
				return [
					{ label: '5 minutes after meeting ends', value: '5:MINUTES' },
					{ label: '15 minutes after meeting ends', value: '15:MINUTES' },
					{ label: '30 minutes after meeting ends', value: '30:MINUTES' },
					{ label: '1 hour after meeting ends', value: '1:HOURS' },
					{ label: '2 hours after meeting ends', value: '2:HOURS' },
					{ label: '6 hours after meeting ends', value: '6:HOURS' },
					{ label: '1 day after meeting ends', value: '1:DAYS' },
					{ label: '1 week after meeting ends', value: '7:DAYS' },
					{ label: '2 week after meeting ends', value: '14:DAYS' },
					{ label: 'Custom (after meeting ends)', value: 'CUSTOM' },
				];
			},
		},
	};
</script>

<style scoped lang="scss">
	.confirmation {
		padding: 50px;
	}
</style>
