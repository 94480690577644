var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.mailbox_id,
            expression: "mailbox_id"
          }
        ],
        ref: "mailbox_id",
        attrs: { type: "hidden", "df-mailbox_id": "" },
        domProps: { value: _vm.mailbox_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.mailbox_id = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.user_id,
            expression: "user_id"
          }
        ],
        ref: "user_id",
        attrs: { type: "hidden", "df-user_id": "" },
        domProps: { value: _vm.user_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.user_id = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.template_id,
            expression: "template_id"
          }
        ],
        ref: "template_id",
        attrs: { type: "hidden", "df-template_id": "" },
        domProps: { value: _vm.template_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.template_id = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.template_name,
            expression: "template_name"
          }
        ],
        ref: "template_name",
        attrs: { type: "hidden", "df-template_name": "" },
        domProps: { value: _vm.template_name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.template_name = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.to_list,
            expression: "to_list"
          }
        ],
        ref: "to_list",
        attrs: { type: "hidden", "df-to_list": "" },
        domProps: { value: _vm.to_list },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.to_list = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.cc_list,
            expression: "cc_list"
          }
        ],
        ref: "cc_list",
        attrs: { type: "hidden", "df-cc_list": "" },
        domProps: { value: _vm.cc_list },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.cc_list = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.add_tracking_pixel,
            expression: "add_tracking_pixel"
          }
        ],
        ref: "add_tracking_pixel",
        attrs: { type: "hidden", "df-add_tracking_pixel": "" },
        domProps: { value: _vm.add_tracking_pixel },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.add_tracking_pixel = $event.target.value
          }
        }
      }),
      _c("node-label", {
        on: {
          open: function($event) {
            return _vm.openModal()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded mr-1",
                    attrs: { color: "black", size: "20" }
                  },
                  [_vm._v("email")]
                ),
                _vm.template_name
                  ? _c("div", [_vm._v(_vm._s(_vm.template_name))])
                  : _c("div", [_vm._v("Send email")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("node-modal", {
        ref: "nodeModal",
        attrs: { title: "Send email" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.isOpen
                  ? _c(
                      "div",
                      {
                        staticClass: "column-format gap-2",
                        staticStyle: { "min-width": "550px" }
                      },
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            "hide-details": "",
                            dense: "",
                            outlined: "",
                            "persistent-placeholder": "",
                            label: "From",
                            items: _vm.emailBoxes,
                            "item-value": "id",
                            "item-text": "username"
                          },
                          on: {
                            change: function($event) {
                              return _vm.setUserIdFromMailbox()
                            }
                          },
                          model: {
                            value: _vm.mailbox_id,
                            callback: function($$v) {
                              _vm.mailbox_id = $$v
                            },
                            expression: "mailbox_id"
                          }
                        }),
                        _c("email-selector", {
                          attrs: {
                            to_list: _vm.toList,
                            cc_list: _vm.ccList,
                            template_id: _vm.template_id
                          },
                          on: {
                            "cc-list": function($event) {
                              return _vm.updateCcList($event)
                            },
                            "to-list": function($event) {
                              return _vm.updateToList($event)
                            },
                            "selected-template": function($event) {
                              return _vm.updateSelectedTemplate($event)
                            }
                          }
                        }),
                        _c("v-checkbox", {
                          staticClass: "mb-2",
                          attrs: {
                            label: "Add open/viewed tracking pixel",
                            "hide-details": "",
                            dense: "",
                            "persistent-hint": "",
                            "true-value": "yes",
                            "false-value": "no"
                          },
                          model: {
                            value: _vm.add_tracking_pixel,
                            callback: function($$v) {
                              _vm.add_tracking_pixel = $$v
                            },
                            expression: "add_tracking_pixel"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }