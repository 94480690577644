<template>
  <div class="column-format gap-3 pt-3">
    <v-select
        style="width:100%"
        hide-details
        dense
        outlined
        :label="$t('recurring.repeat-every')"
        persistent-placeholder
        :items="defaultOptions"
        item-value="value"
        item-text="text"
				@change="recurrenceChanged()"
        v-model="recurring.recurrenceType"
    >
      <template v-slot:item="{ item }">
        <span>{{ item.text }}</span>
        <v-icon class="ml-2" v-if="item.text === 'Custom'">tune</v-icon>
      </template>
    </v-select>

    <div class="row-format gap-3" style="gap: 8px;" v-if="recurring.recurrenceType === 'CUSTOM'">
      <v-text-field
          style="width:50%"
          type="number"
          inputmode="number"
          hide-details
          label="Recurs every"
          dense
          outlined
          persistent-placeholder
          v-model="recurring.interval.duration"
          @keydown="handleDurationKeydown($event)"
      />
      <v-select
          style="width:50%"
          hide-details
          dense
          outlined
          :label="$t('recurring.time-unit')"
          persistent-placeholder
          :items="timeUnits"
          item-value="value"
          item-text="text"
          v-model="recurring.interval.timeUnit"
      />
    </div>
    <div v-if="recurring.recurrenceType === 'WEEKLY'" class="column-format text-left pa-2" style="border: 1px solid var(--v-gray_50-base); border-radius: 4px; position: relative">
      <div class="font-12 font-gray_70" style="background-color: var(--v-white-base); position: absolute; top: -9px; padding-left: 2px">Day of week</div>
			<daily-scheduler v-model="recurring.weeklyDays" />
    </div>
    <div class="row-format gap-3" style="gap: 8px">
      <date-selector
          :standard="true"
          style="width:50%"
          :label="$t('recurring.start-date')"
          :date="recurring.startDate"
          @change="recurring.startDate = $event; recurrenceChanged()"
      ></date-selector>
      <date-selector
          :standard="true"
          style="width:50%"
          :label="$t('recurring.end-date')"
          :date="recurring.endDate"
          @change="recurring.endDate = $event; recurrenceChanged"
      ></date-selector>
    </div>
    <div class="column-format gap-3">
      <div class="text-left font-12 font-gray_70">
        <span v-if="recurring.enabled" style="text-transform: capitalize"
        >Status: {{ recurring.status.toLowerCase() }}<br
        /></span>
				<span v-else style="text-transform: capitalize"
				>Status: Disabled<br
				/></span>

				Last run time:
        <span v-if="recurring.lastRunTime">{{
            DateTime.fromISO(recurring.lastRunTime)
                .setZone($store.state.podAccount.timeZone)
                .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
          }}</span
        ><span v-else>--</span><br />
        Next run time:
        <span v-if="recurring.nextRunTime" :style="!recurring.enabled ? 'text-decoration: line-through' : ''">{{
            DateTime.fromISO(recurring.nextRunTime)
                .setZone($store.state.podAccount.timeZone)
                .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
          }}</span
        ><span v-else>--</span>
        <div class="font-primary pointer" v-if="recurring.events && recurring.events.length" @click="viewHistory">
          View history <v-icon x-small color="primary">history</v-icon>
        </div>
      </div>
      <v-checkbox hide-details dense v-model="recurring.enabled" label="Enabled" class="mt-0"></v-checkbox>
    </div>
		<!--span style="text-align:left; font-family: monospace; white-space: pre-wrap; word-wrap: break-word;">{{JSON.stringify(recurring, null, 2)}}</span-->
	</div>
</template>

<script>
import DateSelector from '@/components/DateSelector';
import DateTime from '@/modules/utils/HDateTime';
import ConfirmModal from '@/components/ConfirmModal';
import DailyScheduler from '@/modules/recurrence/DailyScheduler.vue';
export default {
  name: 'RecurringEventConfig',

  props: ['recurring'],

  components: { DateSelector, DailyScheduler },

  data: function() {
    return {
      DateTime: DateTime,
      timeUnits: [
        { text: 'Days', value: 'DAYS' },
        { text: 'Weeks', value: 'WEEKS' },
        { text: 'Months', value: 'MONTHS' },
        { text: 'Years', value: 'YEARS' },
      ],
      selectedOption: 'Daily',
    };
  },

  methods: {
    viewHistory: function() {
      let events = this.recurring.events.reverse();
      let display = events
          .map((e) => DateTime.fromISO(e.timestamp).toLocaleString(DateTime.DATE_MED) + ' - ' + e.events[0])
          .join('<br>');

      let binding = {
        bodyText: display,
        hideNo: true,
        align: 'left',
        yesText: 'OK',
      };

      this.$store.state.globalModalController.openModal(ConfirmModal, binding);
    },

    handleDurationKeydown(event) {
      if (event.key === '.') {
        event.preventDefault();
      }
    },

    getWeekText(weekNumber) {
      let weekText = ['first', 'second', 'third', 'fourth', 'last'];
      return weekText[weekNumber - 1];
    },

    getOrdinalSuffix(n) {
      let s = ['th', 'st', 'nd', 'rd'];
      let v = n % 100;
      return s[(v - 20) % 10] || s[v] || s[0];
    },

		recurrenceChanged: function(){
			this.recurring.monthlyWeekOfMonth = this.weekOfMonth;
			this.recurring.monthlyDayOfWeek = this.dayOfWeek;
		}
  },

  computed: {
		weekOfMonth: function(){
			const startDate = this.DateTime.fromISO(this.recurring.startDate);
			return Math.ceil(startDate.day / 7);
		},

		dayOfWeek: function(){
			const startDate = this.DateTime.fromISO(this.recurring.startDate);
			return startDate.weekday;
		},

		defaultOptions: function() {
      let result = [];

      const startDate = this.DateTime.fromISO(this.recurring.startDate);
      const dayOfWeek = startDate.toFormat('cccc');
			const weekText = this.getWeekText(this.weekOfMonth);
      const monthDay = startDate.toFormat('MMMM d');
      const dayOfMonth = startDate.day;
      const ordinalSuffix = this.getOrdinalSuffix(dayOfMonth);

      result.push({ text: 'Daily', value: 'DAILY' });
      result.push({ text: `Weekly`, value: 'WEEKLY' });
      result.push({ text: `Monthly on the ${weekText} ${dayOfWeek}`, value: 'MONTHLY' });
      result.push({ text: `Annually on ${monthDay}${ordinalSuffix}`, value: 'ANNUALLY' });
      result.push({ text: 'Custom', value: 'CUSTOM' });

      return result;
    },
  },
};
</script>

<style scoped lang="scss"></style>
