<template>
	<div style="width:100%; height: calc(var(--vh) - 60px); background-color: var(--v-white-base)">
		<iframe  :src="iFrameUrl" crossorigin="anonymous" target="_blank" style="width:100%; height:100%; border: none"></iframe>
	</div>
</template>

<script>
import ReferralService from "@/modules/account/ReferralService";

export default {
	name: "Referrals",

	props: [],

	components: {},

	data: function () {
		return {
			baseUrl: process.env.VUE_APP_FP_URL,
			authKey: null,
			referralService: new ReferralService(),

			currentView: 'Portal',

			pages: [
				{ label: 'Portal', value: 'Portal' },
			],
		}
	},

	mounted() {
		this.$track.record('page-view', { module: 'referral-dashboard' });
		this.getAuthKey();
	},

	beforeDestroy() {
	},

	methods: {
		getAuthKey: function(){
			this.referralService.getAuthKey().then((res) => {
				this.authKey = res.data;
			}).catch((err) =>{
				this.$store.commit('error',err.response.data.message);
			})
		},

		onLoad: function(){
			console.log('iframe loaded');
		}
	},

	computed: {
		iFrameUrl: function(){
			return this.baseUrl + this.authKey;
		},
	},

}
</script>

<style scoped lang="scss">
	.fpFrame {
		height: 600px!important;
		width:100%!important;
	}
</style>