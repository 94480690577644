<template>
	<div class="form-builder" v-if="template" :key="template.id">
		<div class="header-area px-2">
			<div class="header-left">
				<div class="row-format align-center gap-2">
					<v-text-field
							v-model="template.name"
							hide-details
							dense
							outlined
							persistent-placeholder
							placeholder="Name"
							style="min-width: 344px"
					></v-text-field>
					<div v-if="client" class="row-format gap-2 align-center">
						<div>Send to:</div>
						<client-avatar :small="true" :client="client"></client-avatar>
						<div>{{ client.name }}</div>
					</div>
				</div>
			</div>
			<div class="header-right row-format gap-2">
				<v-select
					v-model="template.opportunityId"
					dense
					hide-details
					solo flat
					placeholder="Opportunity"
					:items="opportunities"
					item-value="id"
					item-text="name" style="max-width: 250px"
					v-if="opportunities.length" class="mr-2" clearable
				>
				</v-select>
				<folder-selector :folders="folders" v-model="template.folder"></folder-selector>
				<v-btn class="primary-action" @click="shareDialog = true"> {{ client ? 'Send' : 'Share' }} </v-btn>
				<v-btn class="super-action" width="96" @click="saveTemplate(false)"> Save </v-btn>

				<v-menu :close-on-click="true" nudge-bottom="30">
					<template v-slot:activator="scope">
						<v-btn icon class="menu-activator" :disabled="false" v-on="scope.on">
							<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
						</v-btn>
					</template>

					<div class="more-menu" v-if="!client">
						<div class="more-menu-item" @click="viewResults()">
							View results
						</div>
						<div class="more-menu-item" @click="downloadResults()">
							Download results
						</div>
						<div class="more-menu-item" @click="duplicateTemplate()">
							Duplicate template
						</div>
						<div class="more-menu-item" @click="confirmDeleteTemplate()">
							Delete
						</div>
					</div>
					<div class="more-menu" v-else>
						<div class="more-menu-item" @click="confirmDeleteTemplate()">
							Delete
						</div>
					</div>
				</v-menu>
				<v-btn icon  @click="saveTemplate(true)">
					<v-icon :size="20">$close</v-icon>
				</v-btn>
			</div>
		</div>
		<div style="height: calc(100% - 50px);" class="row-format">
			<div
				class="column-format pb-3"
				style="width: 350px; max-width:350px; min-width:350px; border-right: 1px solid var(--v-gray_30-base); height: 99%; max-height: 99%;"
			>
				<div v-if="tab === 'Elements' && editItem" class="py-3 px-3">
					<config-renderer
						:key="editItem.id"
						:item="editItem"
						:template="template"
						:schema="schema"
						@change="elementUpdated($event)"
						@delete="confirmDelete()"
						@duplicate="duplicate()"
						@done="doneEditingItem()"
					></config-renderer>
				</div>
				<div v-else style="height: 100%; max-height: 100%" class="column-format gap-2 show-scrollbar">
					<div class="row-format pt-3 pb-2 px-3 gap-3">
						<div
							:class="`row-format align-center page-link ${tab === 'Elements' ? 'page-link-active' : ''}`"
							@click="selectTab('Elements')"
						>
							<v-icon
								class="material-symbols-rounded"
								size="22"
								:color="tab === 'Elements' ? 'primary' : 'gray_50'"
								>dashboard_customize</v-icon
							>
							<div class="ml-1">Elements</div>
						</div>
						<div
							:class="`row-format align-center page-link ${tab === 'Settings' ? 'page-link-active' : ''}`"
							@click="selectTab('Settings')"
						>
							<v-icon
								class="material-symbols-rounded"
								size="22"
								:color="tab === 'Settings' ? 'primary' : 'gray_50'"
								>settings</v-icon
							>
							<div class="ml-1">Settings</div>
						</div>
						<div
							:class="`row-format align-center page-link ${tab === 'Style' ? 'page-link-active' : ''}`"
							@click="selectTab('Style')"
						>
							<v-icon class="material-symbols-rounded" size="22" :color="tab === 'Style' ? 'primary' : 'gray_50'"
								>palette</v-icon
							>
							<div class="ml-1">Style</div>
						</div>
					</div>

					<div v-if="tab === 'Style'" class="px-3">
						<form-style-config
							:styles="styles"
							:font="template.font"
							@font-updated="fontUpdated($event)"
							@change="updateStyles($event)"
						></form-style-config>
					</div>
					<div v-else-if="tab === 'Settings'" class="px-3">
						<form-settings-config :template="template" @change="handleSettingsUpdate($event)"></form-settings-config>
					</div>
					<div v-else class="show-scrollbar" style="max-height: calc(100% - 60px)">
						<div
							v-for="(category, index) in schemaTypes"
							:key="index"
							style="border-bottom: 1px solid var(--v-gray_30-base)"
							class="mb-2 pb-1 px-3"
						>
							<div class="text-left">{{ category.label }}</div>
							<draggable
								:list="category.types"
								:group="{ name: 'elements', pull: 'clone', put: false }"
								:clone="cloneElement"
								:sort="false"
							>
								<div
									v-for="schema in category.types"
									:key="schema.type"
									class="row-format align-center schema-item pa-1 gap-2"
								>
									<v-icon class="material-symbols-rounded" color="black" size="24">{{ schema.icon }}</v-icon>
									<div class="column-format text-left">
										<div class="font-14">{{ schema.label }}</div>
										<div class="font-12 mt-n1 font-gray_70">{{ schema.description }}</div>
									</div>
								</div>
							</draggable>
						</div>
					</div>
				</div>
			</div>
			<div
				:style="
					`width:100%; height:100%; max-height: 100%; --form-font-size: ${styles.fontSize}px; --font-color:${styles.fontColor};  --form-input-color: ${styles.formInputColor}; font-family: ${template.font}; color: ${styles.fontColor}; background-color: ${styles.backgroundColor}`
				"
				class="pa-6 column-format align-center show-scrollbar main-form"
			>
				<div :style="`width:100%; min-width: 300px; max-width: ${styles.maxWidth}px`">
					<draggable
						:list="schema"
						group="elements"
						:clone="cloneElement"
						@change="changed"
						:emptyInsertThreshold="1000"
						style="width:100%"
					>
						<div v-for="item in schema" :key="item.id">
							<item-renderer
								:item="item"
								:styles="styles"
								:button-props="buttonProps"
								:font="template.font"
								:disabled="true"
								:account-logo="accountLogo"
								:results="[]"
								:builder="true"
								@click="handleClick(item)"
								@change="elementUpdated($event)"
								@delete="confirmDelete()"
								@add-container-element="addContainerElement($event)"
								@container-item-click="containerItemClicked($event)"
								@container-item-update="containerItemUpdated($event)"
								:active="item.id === editItemId || item.id === editContainerId"
							></item-renderer>
						</div>
					</draggable>

					<div class="row-format gap-2 pt-2 px-2" :style="`width: 100%; justify-content: ${justification}`">
						<v-btn
							v-if="hasPages"
							class="elevation-0"
							:color="styles.buttonColor"
							:style="
								`font-size: var(--form-font-size) !important; ${buttonProps.block ? 'width: 49%!important;' : ''}`
							"
							:x-small="buttonProps.xSmall"
							:small="buttonProps.small"
							:large="buttonProps.large"
							:x-large="buttonProps.xLarge"
						>
							<v-icon class="mr-1" :color="styles.buttonTextColor">{{ styles.prevPageIcon }}</v-icon>
							<span
								:style="`font-family: ${template.font}; ; text-transform: none; color: ${styles.buttonTextColor}`"
								>{{ styles.prevPageText }}</span
							>
						</v-btn>
						<v-btn
							class="elevation-0"
							:style="
								`font-size: var(--form-font-size) !important; ${
									hasPages && buttonProps.block ? 'width: 49%!important;' : ''
								}`
							"
							:color="styles.buttonColor"
							:x-small="buttonProps.xSmall"
							:small="buttonProps.small"
							:large="buttonProps.large"
							:x-large="buttonProps.xLarge"
							:block="!hasPages && buttonProps.block"
						>
							<span
								:style="`font-family: ${template.font}; ; text-transform: none; color: ${styles.buttonTextColor}`"
								>{{ styles.submitText }}</span
							>
							<v-icon
								class="ml-1 material-symbols-rounded"
								:color="$vuetify.theme.dark ? 'black' : styles.buttonTextColor"
								v-if="styles.submitIcon"
								>{{ styles.submitIcon }}</v-icon
							>
						</v-btn>
					</div>

					<div class="fill-height fill-width" style="height: 300px;" @click="editItem = null"></div>
				</div>
			</div>
		</div>

		<basic-modal
			v-if="shareDialog"
			:dialog="shareDialog"
			:persistent="true"
			:retain-focus="false"
			:max-width="684"
			@close="shareDialog = false"
			v-slot="scope"
		>
			<share-send-widget
				v-if="client"
				:client-id="client.id"
				:discovery-template="template"
				:mce-config-text="mceConfigText"
				:open-state="shareDialog"
				@alert="scope.setAlert($event)"
				@cancel="shareDialog = false"
				@finalize="shareDialog = false"
				@discovery-sent="handleDiscoverySent()"
			></share-send-widget>
			<share-form
				v-else
				@close="shareDialog = false"
				:base-url="baseUrl.substring(0, baseUrl.length - 1)"
				:form-id="template.uniqueTemplateId"
				:full-link="fullTemplateLink"
				:full-link-embed="fullTemplateLinkEmbed"
			></share-form>
		</basic-modal>

		<basic-modal
			:dialog="resultsDialog"
			v-if="resultsDialog"
			:persistent="false"
			:max-width="900"
			@close="resultsDialog = false"
		>
			<div class="pa-3 column-format">
				<div class="row-format mx-2">
					<div class="ml-auto row-format gap-2">
						<v-icon class="pointer" @click="resultsDialog = false">close</v-icon>
					</div>
				</div>
				<form-submissions-table :template-id="template.id"></form-submissions-table>
			</div>
		</basic-modal>
	</div>
</template>

<script>
	import Draggable from 'vuedraggable';
	import SchemaTypes from './SchemaTypes.json';
	import { v4 as uuid } from 'uuid';
	import ItemRenderer from '@/modules/forms/v2/schema/ItemRenderer';
	import ConfigRenderer from '@/modules/forms/config/ConfigRenderer';
	import ConfirmModal from '@/components/ConfirmModal';
	import FormStyleConfig from '@/modules/forms/config/FormStyleConfig';
	import DiscoveryTemplateService from '@/modules/discovery/DiscoveryTemplateService';
	import FormSettingsConfig from '@/modules/forms/config/FormSettingsConfig';
	import BasicModal from '@/components/BasicModal';
	import FormSubmissionsTable from '@/modules/discovery/FormSubmissionsTable';
	import ShareSendWidget from '@/modules/discovery/ShareSendWidget';
	import ShareForm from '@/modules/discovery/ShareForm';
	import AccountService from '@/modules/account/AccountService';
	import WebFontLoader from 'webfontloader';
	import DiscoveryService from '@/modules/discovery/DiscoveryService';
	import ClientAvatar from '@/components/ClientAvatar';
	import FormSubmissionService from '@/modules/discovery/FormSubmissionService';
	import FolderSelector from '@/components/FolderSelector';
	import OpportunityService from '@/modules/pipeline/OpportunityService';

	export default {
		name: 'FormBuilder',

		props: ['client', 'id', 'otherTemplateNames', 'folders'],

		components: {
			FolderSelector,
			ClientAvatar,
			FormSettingsConfig,
			ConfigRenderer,
			ItemRenderer,
			Draggable,
			FormStyleConfig,
			BasicModal,
			FormSubmissionsTable,
			ShareSendWidget,
			ShareForm,
		},

		data: function() {
			return {
				opportunities: [],
				customCss: null,
				shareDialog: false,
				resultsDialog: false,
				uniqueAccountUrl: null,
				baseUrl: process.env.VUE_APP_MEET_LINK,
				templateService: new DiscoveryTemplateService(),
				requestService: new DiscoveryService(),
				formSubmissionService: new FormSubmissionService(),
				tab: 'Elements',
				schemaTypes: SchemaTypes,
				template: null,
				schema: [],
				fieldIndex: 0,
				styles: {
					fontSize: 14,
					inputStyle: 'outlined',
					fontColor: null,
					maxWidth: 900,
					enableDraftSave: true,
					submitText: 'Submit',
					submitIcon: 'send',
					saveDraftText: 'Save draft',
					draftIcon: 'save',
					submitAlignment: 'RIGHT',
					buttonColor: '#EEEEEEFF',
					buttonTextColor: 'black',
				},
				editItem: null,

				mceConfigText: {
					menubar: false,
					inline: false,
					browser_spellcheck: true,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					forced_root_block: '',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media'],
					contextmenu: 'paste | link image | table',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'table | styleselect forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | link unlink media',
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
				},
			};
		},

		mounted() {
			this.getUniqueAccountUrl();
			this.getTemplate();
			if (this.client) {
				this.getOpportunities();
			}
		},

		beforeDestroy() {
			if (this.customCss) {
				document.head.removeChild(this.customCss);
			}
		},

		methods: {
			getOpportunities: function() {
				new OpportunityService().getOpportunities(this.client.id).then((res) => {
					this.opportunities.splice(0);
					this.opportunities.push(...res.data);
				});
			},

			downloadResults: function() {
				this.$store.commit('startLoading');
				this.formSubmissionService
					.downloadFormSubmissionByTemplate(this.template.id)
					.then((res) => {
						let fileURL = window.URL.createObjectURL(res.data);
						let fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute('download', `${this.template.uniqueTemplateId}.xlsx`);
						document.body.appendChild(fileLink);
						fileLink.click();
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			saveTemplate: function(close = false) {
				if (this.template.confirmationRedirect && !this.template.confirmationRedirect.startsWith('https://')) {
					this.$store.commit('error', 'Redirect URL must be in https://www.yoursite.com format');
					return;
				}

				this.template.schemaV2 = this.schema;
				this.template.formSettingsV2 = this.styles;
				console.log(this.template);

				if (this.client) {
					this.requestService.updateDiscoveryRequest(this.client.id, this.template.id, this.template).then((res) => {
						if (close) {
							this.$emit('result', res.data);
						} else {
							this.setTemplate(res.data);
							this.$store.commit('success', 'Form saved.');
						}
					});
				} else {
					this.templateService.updateDiscoveryTemplate(this.template.id, this.template).then((res) => {
						if (close) {
							this.$emit('result', res.data);
						} else {
							this.setTemplate(res.data);
							this.$store.commit('success', 'Form saved.');
						}
					});
				}
			},

			getTemplate: function() {
				if (this.client) {
					this.requestService.getDiscoveryRequest(this.client.id, this.id).then((res) => {
						this.setTemplate(res.data);
					});
				} else {
					this.templateService.getDiscoveryTemplate(this.id).then((res) => {
						this.setTemplate(res.data);
					});
				}
			},

			getUniqueAccountUrl: function() {
				let accountService = new AccountService();
				accountService.getAccountUniqueUrl().then((res) => (this.uniqueAccountUrl = res.data));
			},

			setTemplate: function(template) {
				this.template = template;
				this.schema = this.template.schemaV2;
				this.styles = this.template.formSettingsV2;
				this.fieldIndex = this.schema.length + 1;
				this.setCustomCss();

				WebFontLoader.load({
					google: {
						families: [`${this.template.font}:200,300,400,500,600`],
					},
				});
			},

			updateStyles: function(styles) {
				this.styles = styles;
			},

			handleClick: function(item) {
				this.tab = 'Elements';
				this.editItem = item;
			},

			changed: function(item) {
				if (item.added && item.added.element.type === 'Container') {
					this.editItem = item.added.element;
				}
			},

			duplicate: function() {
				let item = JSON.parse(JSON.stringify(this.editItem));
				item.id = uuid();

				if (item.type === 'Container') {
					for (let i = 0; i < item.columns.length; i++) {
						for (let j = 0; j < item.columns[i].items.length; j++) {
							item.columns[i].items[j].id = uuid();
						}
					}
				}

				this.schema.push(item);
			},

			doneEditingItem: function() {
				if (this.editItem.containerTarget) {
					let container = this.schema.find((i) => i.id === this.editItem.containerTarget.containerId);
					if (container) {
						this.editItem = container;
					} else {
						this.editItem = null;
					}
				} else {
					this.editItem = null;
				}
				this.tab = 'Elements';
			},

			confirmDelete: function() {
				let binding = {
					headingText: 'Delete?',
					bodyText: 'Are you sure you want to delete this element?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.deleteElement(this.editItem);
					}
				});
			},

			deleteElement: function(element) {
				if (element.containerTarget) {
					let containerIx = this.schema.findIndex((i) => i.id === element.containerTarget.containerId);
					let column = element.containerTarget.index;
					if (containerIx > -1) {
						let container = this.schema[containerIx];
						let ix = container.columns[column].items.findIndex((i) => i.id === element.id);
						if (ix > -1) {
							container.columns[column].items.splice(ix, 1);
							this.schema.splice(containerIx, 1, container);
						}
					}
				} else {
					let ix = this.schema.findIndex((i) => i.id === element.id);
					if (ix > -1) {
						this.schema.splice(ix, 1);
					}
					this.editItem = null;
				}
			},

			fontUpdated: function(font) {
				this.template.font = font;
			},

			setCustomCss: function() {
				if (!this.customCss) {
					this.customCss = document.createElement('style');
					document.head.append(this.customCss);
				}

				this.customCss.textContent = `.main-form{
					${this.styles.customCss ? this.styles.customCss : ''}
				}`;
			},

			handleSettingsUpdate: function(settings) {
				this.template.confirmationEmailTemplate = settings.confirmationEmailTemplate;
				this.template.pipelineStageId = settings.pipelineStageId;
				this.template.confirmationRedirect = settings.confirmationRedirect;
				this.template.confirmationText = settings.confirmationText;
				this.template.sendResultsInEmail = settings.sendResultsInEmail;
				this.template.ticketTypeId = settings.ticketTypeId;
				this.template.active = settings.active;
				console.log(this.template);
			},

			elementUpdated: function(element) {
				if (element.containerTarget) {
					let containerIx = this.schema.findIndex((i) => i.id === element.containerTarget.containerId);
					let column = element.containerTarget.index;
					if (containerIx > -1) {
						let container = this.schema[containerIx];
						let ix = container.columns[column].items.findIndex((i) => i.id === element.id);
						if (ix > -1) {
							container.columns[column].items.splice(ix, 1, element);
							this.schema.splice(containerIx, 1, container);
						}
					}
				} else {
					let ix = this.schema.findIndex((i) => i.id === element.id);
					if (ix > -1) {
						this.schema.splice(ix, 1, element);
					}
				}
			},

			handleDiscoverySent() {
				this.$emit('sent');
			},

			viewResults() {
				this.resultsDialog = true;
			},

			duplicateTemplate() {
				this.allowRender = false;
				let newTemplate = JSON.parse(JSON.stringify(this.template));
				newTemplate.id = null;
				newTemplate.name = 'Copy of: ' + this.template.name;

				this.templateService
					.createDiscoveryTemplate(newTemplate)
					.then((res) => {
						this.setTemplate(res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			confirmDeleteTemplate: function() {
				let bodyText;
				if (this.client) {
					bodyText = 'Are you sure want to delete this client form request?';
				} else {
					bodyText = 'Are you sure you want to delete this form template?  This cannot be undone.';
				}

				let binding = {
					headingText: 'Delete?',
					bodyText: bodyText,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						if (this.client) {
							this.requestService.deleteDiscoveryRequest(this.client.id, this.template.id).then(() => {
								this.template.deleted = true;
								this.$emit('result', this.template);
							});
						} else {
							this.templateService.deleteDiscoveryTemplate(this.template.id).then(() => {
								this.template.deleted = true;
								this.$emit('result', this.template);
							});
						}
					}
				});
			},

			addContainerElement: function(event) {
				let container = event.container;
				let column = event.column;
				let element = event.element;
				let newElement = this.cloneElement(element);

				let ix = this.schema.findIndex((i) => i.id === container.id);
				container.columns[column].items.push(newElement);

				this.schema.splice(ix, 1, container);
			},

			containerItemClicked: function(event) {
				let container = event.container;
				let item = event.item;
				let index = event.index;

				item.containerTarget = {
					containerId: container.id,
					index: index,
				};

				this.handleClick(item);
			},

			containerItemUpdated: function(event) {
				let container = event.container;
				let item = event.item;
				let index = event.index;

				item.containerTarget = {
					containerId: container.id,
					index: index,
				};

				this.elementUpdated(item);
			},

			cloneElement: function(element) {
				let result = {
					id: uuid(),
					type: element.type,
					required: false,
					fieldName: 'Field' + this.fieldIndex++,
					schemaMapping: null,
					conditions: [],
					conditionalType: 'and',
					conditionsEnabled: false,
				};

				if (element.type === 'TextInput') {
					result.question = 'Enter question text';
					result.placeholder = 'Type your answer here.';
				} else if (element.type === 'TextArea') {
					result.question = 'Enter question text';
					result.placeholder = 'Type your answer here.';
					result.rows = 3;
				} else if (element.type === 'DateInput') {
					result.question = 'Select a date';
					result.placeholder = '';
				} else if (element.type === 'Select') {
					result.question = 'Choose an option';
					result.placeholder = 'Make your selection';
					result.options = ['Option 1', 'Option 2'];
				} else if (element.type === 'Checkbox' || element.type === 'Radio') {
					result.question = 'Choose an option';
					result.options = ['Option 1', 'Option 2'];
					result.formatVertically = false;
				} else if (element.type === 'TextBlock') {
					result.text = 'Enter text here';
				} else if (element.type === 'Spacer') {
					result.height = 20;
				} else if (element.type === 'Divider') {
					result.style = 'solid';
					result.color = '#000';
					result.weight = 1;
					result.width = 100;
					result.pt = 10;
					result.pb = 10;
				} else if (element.type === 'FileInput') {
					result.question = 'Upload your file';
					result.placeholder = 'Select any image';
					result.multi = false;
					result.fileTypes = [];
				} else if (element.type === 'Container') {
					result.columnCount = 2;
					result.columns = [];
					result.columns.push({ pt: 0, pb: 0, pr: 0, pl: 0, items: [] });
					result.columns.push({ pt: 0, pb: 0, pr: 0, pl: 0, items: [] });
				} else if (element.type === 'NewPage') {
					result.nextText = 'Next';
					result.backText = 'Previous';
					result.alignment = 'JUSTIFIED';
				} else if (element.type === 'Logo') {
					result.width = 300;
					result.align = 'center';
				} else if (element.type === 'ImageBlock') {
					result.pt = 0;
					result.pb = 0;
					result.br = 4;
					result.scale = 80;
					result.alignment = 'center';
					result.url = null;
				} else if (element.type === 'Button') {
					result.alignment = 'center';
					result.redirectUrl = null;
					result.text = 'Button';
					result.size = 'Normal';
					result.style = 'Normal';
					result.color = this.styles.buttonColor;
					result.textColor = this.styles.buttonTextColor;
				}

				return result;
			},

			selectTab: function(tab) {
				this.tab = tab;
			},

			convertName: function(name) {
				return name
					.replaceAll(' ', '-')
					.toLowerCase()
					.replaceAll(/[^a-zA-Z0-9-]/g, '');
			},
		},

		computed: {
			buttonProps: function() {
				let result = {
					xSmall: this.styles.buttonSize === 'X-Small',
					small: this.styles.buttonSize === 'Small',
					large: this.styles.buttonSize === 'Large',
					xLarge: this.styles.buttonSize === 'X-Large',
					block: this.styles.buttonStyle === 'Block',
				};

				return result;
			},

			hasPages: function() {
				return this.schema.filter((s) => s.type === 'NewPage').length;
			},

			fullTemplateLinkEmbed: function() {
				if (this.template) {
					return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + this.template.uniqueTemplateId;
				} else {
					return null;
				}
			},

			fullTemplateLink: function() {
				if (this.template) {
					let commPref = this.$store.state.communicationPreferences;
					if (commPref && commPref.primaryDomain) {
						return (
							'https://' +
							commPref.primaryDomain +
							'/public/' +
							this.template.uniqueTemplateId
						);
					} else {
						return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + this.template.uniqueTemplateId;
					}
				} else {
					return null;
				}
			},

			podId: function() {
				let podId = this.$store.getters.getAccount.pod.podId;
				return podId.replace(/\D/g, '');
			},

			uniqueTemplateId: function() {
				if (this.template && this.template.name) {
					return this.convertName(this.template.name);
				} else {
					return '';
				}
			},

			justification: function() {
				if (this.styles.submitAlignment === 'JUSTIFIED') {
					return 'space-between';
				} else if (this.styles.submitAlignment === 'LEFT') {
					return 'flex-start';
				} else if (this.styles.submitAlignment === 'RIGHT') {
					return 'flex-end';
				} else {
					return 'center';
				}
			},

			editContainerId: function() {
				if (this.editItem && this.editItem.containerTarget) {
					return this.editItem.containerTarget.containerId;
				} else {
					return null;
				}
			},

			editItemId: function() {
				if (this.editItem) {
					return this.editItem.id;
				} else {
					return null;
				}
			},

			accountLogo: function() {
				if (this.$vuetify.theme.dark && this.$store.state.podAccount.accountLogoDark) {
					return this.$store.state.podAccount.accountLogoDark;
				} else {
					return this.$store.state.podAccount.accountLogo;
				}
			},
		},

		watch: {
			uniqueTemplateId: function(newVal) {
				if (this.template) {
					this.template.uniqueTemplateId = newVal;
				}
			},
		},
	};
</script>

<style lang="scss">
	.main-form {
		.v-text-field {
			background-color: var(--form-input-color) !important;
			background: var(--form-input-color) !important;
			::placeholder {
				color: #aaa5a0 !important;
			}
		}

		.v-icon {
			color: black !important;
		}

		.theme--dark.v-icon.v-icon.v-icon--disabled {
			color: black !important;
		}

		.v-file-input__text--placeholder {
			color: #aaa5a0 !important;
		}
	}
</style>

<style scoped lang="scss">
	.schema-item {
		border-radius: 4px;
		&:hover {
			background-color: var(--v-gray_10-base);
			cursor: grab;
		}
	}

	.form-builder {
		background-color: var(--v-white-base);
		height: 100vh;
		.header-area {
			height: 50px;
			width: 100%;
			border-bottom: 1px solid var(--v-gray_30-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
