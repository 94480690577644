var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "brand-box" }, [
    _c(
      "div",
      {
        staticClass: "px-4 py-2 border-radius-top pointer",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.expanded = !_vm.expanded
          }
        }
      },
      [_vm._t("heading")],
      2
    ),
    _vm.expanded
      ? _c(
          "div",
          [
            _vm._l(_vm.items, function(item) {
              return _c(
                "div",
                { key: item.id, staticClass: "brand-border-top work-row" },
                [
                  _c(
                    "div",
                    { staticClass: "px-4" },
                    [
                      item.type === "Deliverable"
                        ? _c("deliverable-mini", {
                            attrs: { deliverable: item.deliverable },
                            on: {
                              "deliverable-clicked": function($event) {
                                return _vm.$emit(
                                  "deliverable-clicked",
                                  item.deliverable
                                )
                              }
                            }
                          })
                        : _vm._e(),
                      item.type === "OpportunityToDo"
                        ? _c("opportunity-to-do", {
                            attrs: { "opportunity-to-do": item.toDo },
                            on: {
                              "todo-clicked": function($event) {
                                return _vm.$emit("todo-clicked", item.toDo)
                              }
                            }
                          })
                        : _vm._e(),
                      item.type === "Ticket"
                        ? _c("ticket-mini", {
                            attrs: {
                              ticket: item.ticket,
                              client: item.ticket.client
                            },
                            on: {
                              "ticket-clicked": function($event) {
                                return _vm.$emit("ticket-clicked", item.ticket)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            }),
            _vm.countComplete > 0
              ? _c(
                  "div",
                  {
                    staticClass: "px-4 pb-2 brand-border-top",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("toggle-show-complete")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "mt-2 font-gray_80 font-12 pointer" },
                      [
                        !_vm.showComplete
                          ? _c("span", [
                              _vm._v("+ " + _vm._s(_vm.countComplete))
                            ])
                          : _c("span", [_vm._v("- Hide")]),
                        _vm._v(" Completed ")
                      ]
                    )
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }