import HttpClient from '@/services/HttpClient';

export default class CommunicatorTicketService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getTicketSettings() {
		return this.httpClient
			.get('/tickets/settings')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getForwardingAddress() {
		return this.httpClient
			.get('/tickets/forwardingAddress')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getCustomForwardingAddresses(){
		return this.httpClient
			.get('/tickets/forwardingAddress/custom')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	addCustomForwardingAddress(email){
		return this.httpClient
			.post('/tickets/forwardingAddress/custom',null,{
				params: { email: email }
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteCustomForwardingAddress(email){
		return this.httpClient
			.delete('/tickets/forwardingAddress/custom',{
				params: { email: email }
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createTicket(newTicket,attachments = []){
		let formData = new FormData();

		formData.append('newTicket',JSON.stringify(newTicket));
		attachments.forEach(a => {
			formData.append('attachments',a);
		})

		return this.httpClient
			.post(`/tickets`,formData,{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateTicketSettings(settings){
		return this.httpClient.put('/tickets/settings',settings)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTickets(earliest, latest, isOpen = true, clientId) {
		let params = {
			earliest: earliest,
			latest: latest,
			isOpen: isOpen,
			clientId: clientId
		};

		return this.httpClient
			.get('/tickets', { params: params })
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getRecentTicketsForContact(clientContactId,maxRecords=5){
		let params = {
			clientContactId: clientContactId,
			maxRecords: maxRecords
		};

		return this.httpClient
			.get('/tickets/recents', { params: params })
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTicketDetails(ticketId){
		return this.httpClient
			.get(`/tickets/${ticketId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	mergeTicket(sourceTicketId,targetTicketId){
		return this.httpClient
			.post(`/tickets/${sourceTicketId}/mergeInto?targetTicketId=${targetTicketId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateTicket(ticketId,ticket){
		return this.httpClient
			.put(`/tickets/${ticketId}`,ticket)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteTicket(ticketId){
		return this.httpClient
			.delete(`/tickets/${ticketId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createDeliverableFromTicket(ticketId,projectId){
		return this.httpClient
			.post(`/tickets/${ticketId}/createDeliverable?projectId=${projectId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	addComment(ticketId,comment,commentType,attachments,commentFormat='HTML'){
		let formData = new FormData();

		formData.append('comment',comment);
		formData.append('commentType',commentType);
		formData.append('commentFormat',commentFormat);
		attachments.forEach(a => {
			formData.append('attachments',a);
		})

		return this.httpClient
			.post(`/tickets/${ticketId}/comments`,formData,{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	markTicketAsRead(ticketId, read = true){
		return this.httpClient
			.post(`/tickets/${ticketId}/read`,null,{
				params: {
					read: read
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setArchiveFlag(ticketId, archive = true){
		return this.httpClient
			.post(`/tickets/${ticketId}/archive`,null,{
				params: {
					archive: archive
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setSnooze(ticketId, snoozeUntil){
		return this.httpClient
			.post(`/tickets/${ticketId}/snooze`,null,{
				params: {
					snoozeUntil: snoozeUntil
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	returnToInbox(ticketId){
		return this.httpClient
			.post(`/tickets/${ticketId}/returnToInbox`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	removeCC(ticketId,cc){
		return this.httpClient
			.post(`/tickets/${ticketId}/removeCC?cc=${encodeURIComponent(cc)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	addCC(ticketId,cc){
		return this.httpClient
			.post(`/tickets/${ticketId}/addCC?cc=${encodeURIComponent(cc)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}



	getSignedUrl(ticketId, fileName) {
		return this.httpClient
			.get(`/tickets/${ticketId}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
