var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-0 ma-0",
      staticStyle: { "min-width": "500px" },
      attrs: { id: "create-invoice" }
    },
    [
      _c(
        "div",
        { staticClass: "row-format pt-4 px-6 align-center" },
        [
          _c("div", { staticClass: "text-left font-20 brand-medium" }, [
            _vm._v(_vm._s(_vm.$t("invoice.create-modal.unbilled-heading")))
          ]),
          _c(
            "v-icon",
            {
              staticClass: "ml-auto pointer",
              on: { click: _vm.handleSubmitBillables }
            },
            [_vm._v("$close")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "px-6 pt-2 text-left font-14 brand-semilight" },
        [
          _c("div", [
            _vm._v(_vm._s(_vm.$t("invoice.create-modal.unbilled-subheading")))
          ]),
          _vm.hasAnyHourly
            ? _c("v-select", {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: {
                      content:
                        "This setting determines how billable hours are grouped.  One line for total, or broken down by day, week, month"
                    },
                    expression:
                      "{content:'This setting determines how billable hours are grouped.  One line for total, or broken down by day, week, month'}"
                  }
                ],
                staticClass: "h-outline",
                attrs: {
                  items: _vm.billingBreakdowns,
                  "hide-details": "",
                  "persistent-placeholder": "",
                  "item-text": "label",
                  "item-value": "value",
                  label: "Hourly line grouping"
                },
                on: { change: _vm.setBillingBreakdownByClient },
                model: {
                  value: _vm.billingBreakdown,
                  callback: function($$v) {
                    _vm.billingBreakdown = $$v
                  },
                  expression: "billingBreakdown"
                }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "row-format align-center my-3",
              staticStyle: { "border-top": "1px solid var(--v-gray_50-base)" }
            },
            [
              _c("v-checkbox", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  label: "Hide un-finished projects"
                },
                model: {
                  value: _vm.hideUnfinished,
                  callback: function($$v) {
                    _vm.hideUnfinished = $$v
                  },
                  expression: "hideUnfinished"
                }
              }),
              _c(
                "div",
                {
                  staticClass: "pointer brand-medium mt-2 ml-2",
                  on: {
                    click: function($event) {
                      _vm.showUnFinishedHelp = !_vm.showUnFinishedHelp
                    }
                  }
                },
                [_vm._v("(?)")]
              )
            ],
            1
          ),
          _vm.showUnFinishedHelp
            ? _c(
                "div",
                {
                  staticClass: "mb-3 mt-n3 font-italic font-12",
                  staticStyle: { "max-width": "550px" }
                },
                [
                  _vm._v(
                    ' Hide projects which have outstanding tasks that are not yet complete. Tasks are considered complete once they are moved into the Kanban column which contains the green "complete" check mark or any column to the right of the "complete" column. '
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "px-6 mt-0" },
        [
          _c(
            "div",
            { attrs: { id: "select-billables" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      [
                        _vm.hasItems
                          ? _c("div", [
                              _c(
                                "div",
                                { attrs: { id: "projects" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "project-body hourly" },
                                    [
                                      _c("v-treeview", {
                                        attrs: {
                                          "selection-type": "leaf",
                                          "open-on-click": "",
                                          selectable: "",
                                          "selected-color": "primary",
                                          "item-children": "items",
                                          "item-disabled": "disabled",
                                          "item-text": "name",
                                          dense: "",
                                          "expand-icon": "$chevronDown",
                                          items: _vm.hourlyProjects
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        "item " + item.class
                                                    },
                                                    [
                                                      item.class == "client"
                                                        ? [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "label",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    _vm.client
                                                                      .hourlyAmount
                                                                      ? null
                                                                      : _vm.handleClientFeesDialog()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "name"
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.name
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "fee-type"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.formatFeeType(
                                                                              item
                                                                                .feeSchedule
                                                                                .feeType
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    item
                                                                      .projectHours
                                                                      .length ===
                                                                    0
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "nothing-to-bill"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "invoice.create-modal.no-client-hours"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                ),
                                                                _vm.client
                                                                  .hourlyAmount
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "hours",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.handleClientFeesDialog()
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getClientSubtotalDisplay(
                                                                                item
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "setup-fees-link",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                            return _vm.handleClientFeesDialog()
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1 mb-1",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              small:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "$alert"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "invoice.create-modal.set-up-client-fees"
                                                                            )
                                                                          ) +
                                                                            " "
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                              ]
                                                            )
                                                          ]
                                                        : item.class ==
                                                          "project"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "name"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "fee-type"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.formatFeeType(
                                                                            item
                                                                              .feeSchedule
                                                                              .feeType
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  item
                                                                    .projectHours
                                                                    .length ===
                                                                  0
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "nothing-to-bill"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "invoice.create-modal.no-project-hours"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              ),
                                                              item.feeSchedule
                                                                .amount
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "hours",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.handleProjectFeesDialog(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getHourlyProjectSubtotalDisplay(
                                                                              item
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "setup-fees-link",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.handleProjectFeesDialog(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1 mb-1",
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "$alert"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "invoice.create-modal.set-up-client-fees"
                                                                          )
                                                                        ) + " "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                            ]
                                                          )
                                                        : item.class
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "name"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.name
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "hours"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.durationSubtotalDisplay
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "name"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "noteFormat"
                                                                      )(
                                                                        item.notes
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "hours"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.durationDisplay
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                    ],
                                                    2
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1461605836
                                        ),
                                        model: {
                                          value: _vm.selectedHours,
                                          callback: function($$v) {
                                            _vm.selectedHours = $$v
                                          },
                                          expression: "selectedHours"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.fixedPriceProjects, function(
                                    project,
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: project.id,
                                        staticClass: "project fixed-price"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "project-head row-format align-center"
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "chevron",
                                                attrs: {
                                                  icon: "",
                                                  rounded: "",
                                                  small: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleOpenProject(
                                                      project
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm.inOpenProjects(project.id)
                                                  ? _c("v-icon", [
                                                      _vm._v("$chevronDown")
                                                    ])
                                                  : _c("v-icon", [
                                                      _vm._v("$chevronRight")
                                                    ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ml-1 mr-n2 mt-1",
                                                staticStyle: {
                                                  display: "block"
                                                }
                                              },
                                              [
                                                _c("v-simple-checkbox", {
                                                  attrs: {
                                                    color: "primary",
                                                    value: _vm.inSelectedProjects(
                                                      project.id
                                                    )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.toggleSelectedProject(
                                                        project
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                class:
                                                  "name " +
                                                  (_vm.$vuetify.breakpoint.xs
                                                    ? "truncate"
                                                    : "")
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(project.name))
                                                ]),
                                                _c(
                                                  "span",
                                                  { staticClass: "fee-type" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatFeeType(
                                                          project.feeSchedule
                                                            .feeType
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            project.feeSchedule.feeType &&
                                            project.feeSchedule.amount
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "hours",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleProjectFeesDialog(
                                                          project
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          project.subtotalDisplay
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "setup-fees-link",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.handleProjectFeesDialog(
                                                          project
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "mr-1 mb-1",
                                                        attrs: {
                                                          color: "primary",
                                                          small: ""
                                                        }
                                                      },
                                                      [_vm._v("$alert")]
                                                    ),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "invoice.create-modal.set-up-client-fees"
                                                        )
                                                      ) + " "
                                                    )
                                                  ],
                                                  1
                                                )
                                          ],
                                          1
                                        ),
                                        _vm.inOpenProjects(project.id)
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "project-body fixed-price"
                                                },
                                                [
                                                  project.paymentHistory.length
                                                    ? _vm._l(
                                                        project.paymentHistory,
                                                        function(payment) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key:
                                                                payment.invoiceId,
                                                              staticClass:
                                                                "line-item"
                                                            },
                                                            [
                                                              _c("div", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "tippy",
                                                                    rawName:
                                                                      "v-tippy",
                                                                    value: {
                                                                      content: _vm.paymentTip(
                                                                        payment
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "{ content: paymentTip(payment) }"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "left",
                                                                domProps: {
                                                                  innerHTML: _vm._s(
                                                                    _vm.formatPaymentHistory(
                                                                      payment
                                                                    )
                                                                  )
                                                                }
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "right"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$formatters.dollars(
                                                                          payment.amount,
                                                                          1,
                                                                          0,
                                                                          payment.currency
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        "line-item " +
                                                        (_vm.$vuetify.breakpoint
                                                          .xs
                                                          ? "mt-2"
                                                          : "")
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "left" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "invoice.create-modal.invoice-today"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "right pb-2"
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "text-right",
                                                            class:
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .width < 426
                                                                ? "mt-4"
                                                                : "",
                                                            attrs: {
                                                              dense: "",
                                                              type: "number",
                                                              width: 128,
                                                              prefix: _vm.$formatters.currencySymbol(
                                                                _vm.invoice
                                                                  .currency
                                                              ),
                                                              "hide-details": ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .fixedPriceProjects[
                                                                  i
                                                                ].invoiceAmount,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm
                                                                    .fixedPriceProjects[
                                                                    i
                                                                  ],
                                                                  "invoiceAmount",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "fixedPriceProjects[i].invoiceAmount"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ],
                                                2
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "project-body retainer" },
                                    [
                                      _c("v-treeview", {
                                        attrs: {
                                          "selection-type": "leaf",
                                          "open-on-click": "",
                                          selectable: "",
                                          "selected-color": "primary",
                                          "item-children": "items",
                                          "item-disabled": "disabled",
                                          "item-text": "name",
                                          dense: "",
                                          "expand-icon": "$chevronDown",
                                          items: _vm.retainerProjects
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        "item " + item.class
                                                    },
                                                    [
                                                      item.class == "project"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "name",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      item
                                                                        .feeSchedule
                                                                        .amount
                                                                        ? null
                                                                        : _vm.handleProjectFeesDialog(
                                                                            item
                                                                          )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "fee-type"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.formatFeeType(
                                                                            item
                                                                              .feeSchedule
                                                                              .feeType
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  item
                                                                    .billableRetainerPeriods
                                                                    .length ===
                                                                  0
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "nothing-to-bill"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "invoice.create-modal.no-retainer-due"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              ),
                                                              item.feeSchedule
                                                                .amount >= 0
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "hours",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.handleProjectFeesDialog(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getRetainerProjectSubtotalDisplay(
                                                                              item
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "setup-fees-link",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.handleProjectFeesDialog(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "mr-1 mb-1",
                                                                          attrs: {
                                                                            color:
                                                                              "primary",
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "$alert"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "invoice.create-modal.set-up-client-fees"
                                                                          )
                                                                        ) + " "
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.class ==
                                                      "retainer-period"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "name"
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "hours"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$formatters.decimal(
                                                                          item.amount
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          290595320
                                        ),
                                        model: {
                                          value: _vm.selectedRetainers,
                                          callback: function($$v) {
                                            _vm.selectedRetainers = $$v
                                          },
                                          expression: "selectedRetainers"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "project-body per-item" },
                                    [
                                      _c("v-treeview", {
                                        attrs: {
                                          "selection-type": "leaf",
                                          "open-on-click": "",
                                          selectable: "",
                                          "selected-color": "primary",
                                          "item-children": "items",
                                          "item-text": "name",
                                          "item-disabled": "disabled",
                                          dense: "",
                                          "expand-icon": "$chevronDown",
                                          items: _vm.perItemProjects
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      class:
                                                        "item " + item.class
                                                    },
                                                    [
                                                      item.class === "project"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      item.class ===
                                                      "deliverable"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "label"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "name d-flex align-center gap-2"
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.name
                                                                        )
                                                                      )
                                                                    ]),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "font-12 font-gray_80"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.formattedFee(
                                                                                item
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "hours d-flex align-center"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$formatters.decimal(
                                                                          item.amount
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2693417889
                                        ),
                                        model: {
                                          value: _vm.selectedPerItems,
                                          callback: function($$v) {
                                            _vm.selectedPerItems = $$v
                                          },
                                          expression: "selectedPerItems"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ])
                          : !_vm.hasItems && _vm.isReady
                          ? _c("div", [
                              _c("div", { staticClass: "my-6 font-18" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "invoice.create-modal.unbilled-empty"
                                    )
                                  )
                                )
                              ])
                            ])
                          : _vm._e()
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.clientFeesDialog
            ? _c(
                "basic-modal",
                {
                  attrs: {
                    dialog: _vm.clientFeesDialog,
                    persistent: true,
                    "max-width": 400
                  },
                  on: { close: _vm.cancelClientFeesDialog }
                },
                [
                  _c("client-hourly-amount-modal", {
                    attrs: { client: _vm.client },
                    on: {
                      close: function($event) {
                        _vm.clientFeesDialog = false
                      },
                      "update-client-fees": function($event) {
                        return _vm.handleUpdateClientFees($event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.projectFeesDialog
            ? _c(
                "modal",
                {
                  attrs: {
                    dialog: _vm.projectFeesDialog,
                    persistent: true,
                    "max-width": 450
                  },
                  on: { close: _vm.cancelProjectFeesDialog }
                },
                [
                  _vm.feesDialogProjectId
                    ? _c("project-fee-schedule-modal", {
                        attrs: { "project-id": _vm.feesDialogProjectId },
                        on: {
                          "update-project-fees": _vm.handleUpdateProjectFees
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "pt-3 pb-2",
          staticStyle: { "border-top": "1px solid var(--v-gray_50-base)" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { id: "submit-billables", width: "200" },
              on: { click: _vm.handleSubmitBillables }
            },
            [_vm._v(_vm._s(_vm.submitBillablesVerb))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }