import HttpClient from "@/services/HttpClient";

export default class GoogleEmailService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    getScopes(){
        return `profile email https://www.googleapis.com/auth/gmail.modify`;
    }

    getClientId(){
        return process.env.VUE_APP_GOOGLE_CLIENT_ID;
    }

    connect(code) {
        let formData = new FormData();
        formData.append('code',code);

        return this.httpClient
            .post(`/customEmail/oAuth/google`,formData,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

}