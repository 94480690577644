<template>
	<div class="pa-4">
		<LineChartGenerator
			:key="refreshKey"
			:chart-id="refreshKey"
			:chart-options="chartOptions"
			:chart-data="chartData"
			:height="$vuetify.breakpoint.height - 250"
		/>
	</div>
</template>

<script>
	import HDateTime from '@/modules/utils/HDateTime';
	import { v4 as uuidv4 } from 'uuid';
	import DateTime from '@/modules/utils/HDateTime';
	import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
	export default {
		name: 'Forecast',

		props: ['opportunities', 'pipelineStages'],

		components: { LineChartGenerator },

		data: function() {
			return {
				refreshKey: uuidv4(),
				chartOptions: {
					responsive: true,
					maintainAspectRatio: false,

					scales: {
						y: {
							ticks: {
								display: true,
							},
							grid: {
								display: true,
								drawBorder: false,
							},
						},
						x: {
							grid: {
								display: false,
							},
							ticks: {
								display: true,
								autoSkip: true,
								maxTicksLimit: 30,
								maxRotation: 0,
								minRotation: 0,
							},
						},
					},
				},
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			getPeriods: function(date, timePeriod, periods) {
				let result = [];
				let d = HDateTime.fromISO(date);
				for (let i = 0; i < periods; i++) {
					result.push(d);
					switch (timePeriod) {
						case 'Day': {
							d = d.plus({ days: 1 });
							break;
						}
						case 'Week': {
							d = d.plus({ weeks: 1 });
							break;
						}
						case 'Month': {
							d = d.plus({ months: 1 });
							break;
						}
						case 'Quarter': {
							d = d.plus({ months: 3 });
							break;
						}
						case 'SemiAnnual': {
							d = d.plus({ months: 6 });
							break;
						}
						case 'Year': {
							d = d.plus({ years: 1 });
							break;
						}
						default: {
							return result;
						}
					}
				}
				return result;
			},

			calculateValues: function(opportunities, dateField) {
				let start = this.earliest;
				let values = [];

				while (start < this.latest) {
					values.push({
						key: start.toISODate(),
						value: 0,
					});
					start = start.plus({ months: 1 });
				}

				for (let i = 0; i < opportunities.length; i++) {
					let opp = opportunities[i];
					let date = opp[dateField];

					if (!date) {
						continue;
					}

					let periods = this.getPeriods(date, opp.timePeriod, opp.periods);

					periods.forEach((p) => {
						let key = p.startOf('month').toISODate();
						let value = values.find((f) => f.key === key);
						if (value) {
							value.value += opp.value;
						}
					});
				}

				return values;
			},
		},

		watch: {
			'$vuetify.breakpoint.height': {
				handler: function() {
					this.refreshKey = uuidv4();
				},
			},
		},

		computed: {
			earliest: function() {
				return HDateTime.now().startOf('month');
			},

			latest: function() {
				return HDateTime.now()
					.plus({ month: 12 })
					.endOf('month');
			},

			oppCopy: function() {
				return JSON.parse(JSON.stringify(this.opportunities));
			},

			closedWonStages: function() {
				let closedStages = this.pipelineStages.stages.filter((s) => s.stageType === 'ClosedWon');
				let result = [];

				for (let i = 0; i < closedStages.length; i++) {
					let stage = closedStages[i];
					let opportunities = this.oppCopy.filter((o) => o.stage.id === stage.id && o.value);
					if (opportunities.length) {
						let values = this.calculateValues(opportunities, 'actualCloseDate');
						result.push({
							stage: stage,
							values: values.map((v) => v.value),
						});
					}
				}

				return result;
			},

			closedValueByMonth: function() {
				let opportunities = this.oppCopy.filter((o) => o.stage.stageType === 'ClosedWon' && o.value);
				let values = this.calculateValues(opportunities, 'actualCloseDate');
				return values.map((v) => v.value);
			},

			oppValueByMonth: function() {
				let inScope = ['New', 'InProgress'];
				let opportunities = this.oppCopy.filter((o) => inScope.includes(o.stage.stageType) && o.value);
				let values = this.calculateValues(opportunities, 'estCloseDate');
				return values.map((v) => v.value);
			},

			totalValueByMonth: function() {
				let closed = this.closedValueByMonth;
				let opp = this.oppValueByMonth;
				let result = [];
				for (let i = 0; i < closed.length; i++) {
					result.push(closed[i] + opp[i]);
				}
				return result;
			},

			labels: function() {
				let result = [];
				let start = DateTime.fromISO(this.earliest);
				let end = DateTime.fromISO(this.latest);

				while (start < end) {
					result.push(start.toLocaleString({ month: 'short', year: 'numeric' }));
					start = start.plus({ months: 1 });
				}

				return result;
			},

			chartData: function() {
				let datasets = [];

				datasets.push({
					fill: true,
					lineTension: 0.3,
					label: 'Total pipeline',
					data: this.totalValueByMonth,
					borderWidth: 2,
					borderColor: '#867F77',
					backgroundColor: '#d3d3d3',
					pointBackgroundColor: '#867F77',
					pointBorderColor: '#867F77',
					pointHoverBackgroundColor: '#d3d3d3',
					pointHoverBorderColor: '#d3d3d3',
					tooltip: {
						callbacks: {
							label: (context) => {
								return ' Total pipeline: ' + this.$formatters.dollars(context.raw, false, true, this.currency);
							},
						},
					},
				});

				datasets.push({
					fill: true,
					lineTension: 0.3,
					label: 'Total closed',
					data: this.closedValueByMonth,
					borderWidth: 2,
					borderColor: '#867F77',
					backgroundColor: '#C2E5D3',
					pointBackgroundColor: '#867F77',
					pointBorderColor: '#867F77',
					pointHoverBackgroundColor: '#C2E5D3',
					pointHoverBorderColor: '#C2E5D3',
					tooltip: {
						callbacks: {
							label: (context) => {
								return ' Total closed: ' + this.$formatters.dollars(context.raw, false, true, this.currency);
							},
						},
					},
				});

				for (let i = 0; i < this.closedWonStages.length; i++) {
					let data = this.closedWonStages[i];
					datasets.push({
						fill: false,
						lineTension: 0.3,
						label: data.stage.label,
						data: data.values,
						borderWidth: 4,
						borderColor: data.stage.hexColor,
						backgroundColor: data.stage.hexColor,
						pointBackgroundColor: data.stage.hexColor,
						pointBorderColor: data.stage.hexColor,
						pointHoverBackgroundColor: data.stage.hexColor,
						pointHoverBorderColor: data.stage.hexColor,
						tooltip: {
							callbacks: {
								label: (context) => {
									return (
										` ${data.stage.label}: ` +
										this.$formatters.dollars(context.raw, false, true, this.currency)
									);
								},
							},
						},
					});
				}

				datasets.push({
					fill: false,
					lineTension: 0.3,
					label: 'In progress',
					data: this.oppValueByMonth,
					borderWidth: 4,
					borderColor: '#8EA3B8',
					backgroundColor: '#8EA3B8',
					pointBackgroundColor: '#8EA3B8',
					pointBorderColor: '#8EA3B8',
					pointHoverBackgroundColor: '#8EA3B8',
					pointHoverBorderColor: '#8EA3B8',
					tooltip: {
						callbacks: {
							label: (context) => {
								return ' In progress: ' + this.$formatters.dollars(context.raw, false, true, this.currency);
							},
						},
					},
				});

				datasets = datasets.reverse();

				return {
					labels: this.labels,
					datasets: datasets,
				};
			},
		},
	};
</script>

<style scoped lang="scss"></style>
