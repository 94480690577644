<template>
	<div class="column-format font-14" v-if="ready">
		<div class="pointer column-format" @click="toggleEmailContent">
			<div class="row-format brand-medium pointer">
				<div class="text-truncate font-gray_90">
					{{ from }} <span class="font-12 font-gray_70" v-if="showEmailDetail">&lt;{{ email.from.address }}&gt;</span>
				</div>
				<assigned-user class="ml-2" v-if="email.sentByUserId && email.sentByUserId !== $store.getters.getLoggedInUserId" :assigned-to="email.sentByUserId" :show-name="false"></assigned-user>
				<div class="ml-auto row-format">
					<div class="nowrap text-truncate font-14">
						{{ received.toLocaleString(DateTime.DATETIME_MED) }} <span v-if="timeAgo">({{ timeAgo }})</span>
					</div>
					<div v-if="showEmailDetail" class="ml-2 nowrap">
						<v-btn icon class="mt-n1" v-tippy="{ content: 'Reply' }" @click.stop="setupReply"
							><v-icon>reply</v-icon></v-btn
						>
						<v-btn icon class="mt-n1" v-tippy="{ content: 'Reply all' }" @click.stop="setupReplyAll"
							><v-icon>reply_all</v-icon></v-btn
						>
						<v-btn icon class="mt-n1" v-tippy="{ content: 'Forward' }" @click.stop="setupForward"
							><v-icon>forward</v-icon></v-btn
						>
					</div>
				</div>
			</div>
			<div v-if="!showEmailDetail" class="mt-1 row-format align-center">
				<div v-if="email.unread" class="unread mr-1"></div>
				<div class="text-truncate nowrap font-14">{{ email.preview }}</div>
			</div>
		</div>
		<div v-if="showEmailDetail && emailDetail">
			<div class="row-format">
				<div>
					<div class="font-12" v-if="to.length">To: {{ to.join(', ') }}</div>
					<div class="font-12" v-if="cc.length">CC: {{ cc.join(', ') }}</div>
				</div>
				<div class="pointer ml-auto font-12 font-gray_70 mr-2" v-if="email.openedCount > 0">
					<div v-tippy="{content:'First opened: ' + DateTime.fromISO(email.firstOpened).toLocaleString(DateTime.DATETIME_MED)}">Opened {{email.openedCount}}x</div>
				</div>
			</div>



			<div class="mt-3" :id="email.id">
				<div v-if="emailDetail.htmlContent" v-html="emailDetail.htmlContent" :key="refreshKey"></div>
				<div v-else-if="emailDetail.textContent" class="text-pre-wrap">{{ emailDetail.textContent }}</div>
				<div v-else><v-icon>empty</v-icon></div>
				<v-btn v-if="hasBlockQuote" @click="toggleBlockQuote" icon small><v-icon>$moreHorizontal</v-icon></v-btn>
			</div>

			<div v-if="emailDetail.attachments && emailDetail.attachments.length">
				<div
					class="mb-2"
					v-for="(attachment, index) in emailDetail.attachments.filter((a) => a)"
					:key="attachment + index"
				>
					<v-btn color="primary" @click="download(attachment)" outlined small>
						<div>{{ attachment }}</div>
						<v-icon small color="primary" class="ml-1">$download</v-icon>
					</v-btn>
				</div>
			</div>
			<div v-if="replyContent" :id="`editor-${email.id}`" class="mt-5 font-14">
				<email-editor
					:initial-content="replyContent"
					:to-list="replyTo"
					:cc-list="replyCC"
					:subject="forwardSubject"
					:show-subject="isForward"
					:email-boxes="emailBoxes"
					:is-reply="true"
					:opportunity-id="opportunityId"
					@send-email="sendEmail($event)"
					@cancel="cancelReply()"
				></email-editor>
			</div>
		</div>
	</div>
</template>

<script>
	import DateTime from '@/modules/utils/HDateTime';
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import EmailEditor from '@/modules/communicator/inbox/email/EmailEditor';
	import AssignedUser from "@/components/AssignedUser";

	export default {
		name: 'Email',

		props: ['thread', 'email', 'defaultOpen', 'userEmails','emailBoxes','opportunityId'],

		components: {AssignedUser, EmailEditor },

		data: function () {
			return {
				DateTime: DateTime,
				received: DateTime.fromISO(this.email.receivedDate),
				communicatorEmailService: new CommunicatorEmailService(),
				emailDetail: null,
				showEmailDetail: false,
				blockQuoteHidden: false,
				hasBlockQuote: false,
				refreshKey: 0,
				ready: false,

				replyTo: [],
				replyCC: [],
				replyContent: null,
				forwardSubject: null,
				isForward: false,
			};
		},

		mounted() {
			if (this.defaultOpen) {
				this.toggleEmailContent();
			} else {
				this.ready = true;
			}
		},

		beforeDestroy() {},

		methods: {
			getReplyAddress(){
				if(this.email.replyTo && this.email.replyTo.address){
					return this.email.replyTo;
				}else{
					return this.email.from;
				}
			},

			setupReply: function () {
				this.replyCC.splice(0, this.replyCC.length);
				this.replyTo.splice(0, this.replyTo.length);

				if (this.isOurEmail(this.email.from)) {
					this.setupReplyAll();
				} else {
					this.replyTo.push(this.formatEmail(this.getReplyAddress()));
					this.setReplyContent();
				}
			},

			setupReplyAll: function () {
				this.replyCC.splice(0, this.replyCC.length);
				this.replyTo.splice(0, this.replyTo.length);

				let toList = [];
				let ccList = [];

				if (!this.isOurEmail(this.email.from)) {
					toList.push(this.formatEmail(this.getReplyAddress()));
				}
				this.email.to.filter((e) => !this.isOurEmail(e)).forEach((e) => toList.push(this.formatEmail(e)));
				this.email.cc.filter((e) => !this.isOurEmail(e)).forEach((e) => ccList.push(this.formatEmail(e)));

				this.replyTo.push(...Array.from(new Set(toList)));
				this.replyCC.push(...Array.from(new Set(ccList)));

				this.setReplyContent();
			},

			setupForward: function () {
				this.replyCC.splice(0, this.replyCC.length);
				this.replyTo.splice(0, this.replyTo.length);
				this.isForward = true;

				if (this.thread.subject.toLowerCase().startsWith('fwd')) {
					this.forwardSubject = this.thread.subject;
				} else {
					this.forwardSubject = 'Fwd: ' + this.thread.subject;
				}

				this.setReplyContent();
			},

			isOurEmail: function (email) {
				return this.userEmails.map((e) => e.toLowerCase()).includes(email.address.toLowerCase());
			},

			cancelReply: function () {
				this.replyContent = null;
				this.replyCC.splice(0, this.replyCC.length);
				this.replyTo.splice(0, this.replyTo.length);
				this.isForward = false;
				this.forwardSubject = null;
			},

			sendEmail: function (event) {
				let sendEmail = event.email;
				let attachments = event.attachments;
				let s3Attachments = event.s3Attachments;
				let sendLater = event.sendLater;

				//set our in-reply-to so threading works
				sendEmail.inReplyToMessageId = this.email.messageId;

				this.$store.commit('startLoading');
				this.communicatorEmailService
					.sendEmail(this.thread.id, sendEmail, attachments, s3Attachments, sendLater)
					.then((res) => {
						this.cancelReply();
						if(sendLater){
							this.$store.commit('success', `Email successfully scheduled for ${DateTime.fromISO(sendLater).toLocaleString(DateTime.DATETIME_MED)}`);
						}else {
							this.$emit('email-sent', res.data);
						}
					})
					.catch((err) => this.$store.commit('error', 'Error sending email: ' + err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},

			formatEmail: function (email) {
				if (email.personal) {
					return '"' + email.personal + '"' + ' <' + email.address + '>';
				} else {
					return '<' + email.address + '>';
				}
			},

			setReplyContent: function () {
				this.replyContent = `<br><br><br><p style="font-size:14px">On ${this.received.toLocaleString(
					DateTime.DATE_MED_WITH_WEEKDAY
				)} at ${this.received.toLocaleString(DateTime.TIME_SIMPLE)} ${this.from} &lt;<a href="mailto:${
					this.email.from.address
				}">${this.email.from.address}</a>&gt; wrote:</p>`;
				this.replyContent =
					this.replyContent +
					`<blockquote class="hectic_reply" style="color: gray; font-size: 14px; border-left: 1px solid gray;">`;

				if (this.emailDetail.htmlContent) {
					this.replyContent = this.replyContent + this.emailDetail.htmlContent;
				} else {
					this.replyContent =
						this.replyContent + `<div style="white-space: pre-wrap!important;">${this.emailDetail.textContent}</div>`;
				}

				this.replyContent = this.replyContent + `</blockquote>`;
				this.$nextTick(() => {
					let div = document.getElementById('editor-' + this.email.id);
					div.scrollIntoView({ behavior: 'smooth', block: 'center' });
				});
			},

			toggleEmailContent: function () {
				if (this.showEmailDetail) {
					this.showEmailDetail = false;
				} else if (this.emailDetail === null) {
					this.getEmailContent();
				} else {
					this.showEmailDetail = true;
					this.blockQuoteHidden = false;
					this.fixupDocument();
				}
			},

			async getEmailDetail(){
				if(this.emailDetail){
					return this.emailDetail;
				}else{
					return await this.getEmailContent(false);
				}
			},

			async getEmailContent (showEmailDetail = true) {
				let res = await this.communicatorEmailService.getEmailContent(this.thread.mailboxId, this.email.messageId);
				this.emailDetail = res.data;

				//TODO - REMOVE WHEN WE MIGRATE FULLY TO WITHMOXIE DOMAINx
				let domains = ['https://com01.hecticapp.com/','https://com01.withmoxie.com/','https://com00.hecticapp.dev/','https://com00.withmoxie.dev/'];

				if(this.emailDetail.htmlContent) {
					domains.forEach(d => {
						let replaceUrl = d + 'api/email/open.png?key='
						this.emailDetail.htmlContent = this.emailDetail.htmlContent.replaceAll(replaceUrl, '');
					})

					//strip out any of our own tracking Ids
					let replaceUrl = this.$store.state.selectedUserAccount.account.pod.comUrl + 'api/email/open.png?key='
					this.emailDetail.htmlContent = this.emailDetail.htmlContent.replaceAll(replaceUrl, '');
				}

				this.showEmailDetail = showEmailDetail;
				this.ready = true;
				this.fixupDocument();

				return this.emailDetail;
			},

			download: function (attachment) {
				this.communicatorEmailService
					.getEmailAttachment(this.thread.mailboxId, this.email.messageId, attachment)
					.then((res) => {
						console.log(res.data);
						let fileURL = window.URL.createObjectURL(new Blob([res.data]));

						let fileLink = document.createElement('a');
						fileLink.href = fileURL;
						fileLink.setAttribute('download', attachment);
						document.body.appendChild(fileLink);
						fileLink.click();
					});
			},

			fixupDocument: function () {
				this.$nextTick(() => {
					this.fixupLinks();
					this.toggleBlockQuote();
				});
			},

			fixupLinks: function () {
				let div = document.getElementById(this.email.id);
				if (div) {
					let links = div.getElementsByTagName('a');
					links.forEach((a) => a.setAttribute('target', '_blank'));
				}
			},

			toggleBlockQuote: function () {
				let div = document.getElementById(this.email.id);
				if (div) {
					let blockQuote = div.getElementsByTagName('blockquote');
					if (blockQuote.length) {
						this.hasBlockQuote = true;
						if (this.blockQuoteHidden) {
							blockQuote.forEach((b) => b.classList.remove('hide-block-quote'));
							this.blockQuoteHidden = false;
						} else {
							blockQuote.forEach((b) => {
								let hide = false;

								if (b.classList.contains('gmail_quote')) {
									hide = true;
								} else if (b.getAttribute('type') && b.getAttribute('type') === 'cite') {
									hide = true;
								} else if (this.doesBlockQuoteHaveLeftBorder(b)) {
									hide = true;
								}

								if (hide) {
									b.classList.add('hide-block-quote');
								}
							});
							this.blockQuoteHidden = true;
						}
					}
				}
			},

			doesBlockQuoteHaveLeftBorder: function (quote) {
				if (quote.style.borderLeft && quote.style.borderLeft.includes('1px')) {
					return true;
				} else if (quote.style.borderWidth && quote.style.borderWidth.includes('1px')) {
					return true;
				} else {
					return false;
				}
			},
		},

		computed: {
			timeAgo: function () {
				let now = DateTime.now();
				let hours = Math.floor(now.diff(this.received, 'hours').hours);
				let minutes = Math.floor(now.diff(this.received, 'minutes').minutes);
				let days = Math.floor(now.diff(this.received, 'days').days);
				if (hours == 0) {
					return `${minutes} minutes ago`;
				} else if (hours < 24) {
					return `${hours} hours ago`;
				} else if (days < 30) {
					if (days == 1) {
						return `${days} day ago`;
					} else {
						return `${days} days ago`;
					}
				} else {
					return null;
				}
			},

			to: function () {
				return this.email.to.map((t) => t.address);
			},

			cc: function () {
				return this.email.cc.map((c) => c.address);
			},

			from: function () {
				if (this.email.from?.personal && !this.email.from.personal.includes('@')) {
					return this.email.from.personal;
				}

				let contact = this.$store.getters.communicatorContacts
					.filter((c) => c.email)
					.find((c) => c.email.toLowerCase() === this.email.from.address.toLowerCase());
				if (contact) {
					return contact.fullName;
				} else {
					return this.email.from.address.split('@')[0];
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.contact-input {
		::v-deep .v-select__selections {
			padding: 4px !important;
		}

		::v-deep .v-chip {
			margin: 2px !important;
		}
	}

	.unread {
		min-width: 12px;
		min-height: 12px;
		max-height: 12px;
		max-width: 12px;
		border-radius: 8px;
		background-color: var(--v-primary-base);
	}
</style>

<style lang="scss">
	.hide-block-quote {
		display: none;
	}

	.block-quote-button {
		padding: 4px;
		width: fit-content;
		border-radius: 12px;
		background-color: var(--v-gray_20-base);
		color: var(--v-black-base);
	}
</style>
