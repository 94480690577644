var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      staticStyle: { "background-color": "var(--v-white-base)!important" },
      attrs: {
        "close-on-click": false,
        "close-on-content-click": false,
        bottom: ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            return [
              _c(
                "div",
                {
                  staticClass: "row-format gap-2",
                  staticStyle: { flex: "1 1 0" }
                },
                [
                  _vm.label
                    ? _c("div", { staticClass: "font-gray_70 font-16" }, [
                        _vm._v(_vm._s(_vm.label))
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      style:
                        "flex: 1; border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                        (_vm.color ? _vm.color : "unset") +
                        "; border: 1px solid var(--v-gray_20-base)",
                      on: { click: _vm.openColor }
                    },
                    [_vm._v("   ")]
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "pa-3 column-format align-center gap-3",
          staticStyle: { "background-color": "var(--v-white-base)!important" }
        },
        [
          _vm.menu
            ? _c("v-color-picker", {
                attrs: {
                  mode: "hexa",
                  "show-swatches": "",
                  "swatches-max-height": "150px"
                },
                on: { input: _vm.updated },
                model: {
                  value: _vm.color,
                  callback: function($$v) {
                    _vm.color = $$v
                  },
                  expression: "color"
                }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "row-format centered gap-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.clearColor()
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "material-symbols-rounded" }, [
                    _vm._v("delete")
                  ])
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { elevation: "0" },
                  on: {
                    click: function($event) {
                      _vm.menu = false
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }