<template>
	<div id="proposal-templates-modal">
		<div class="proposal-header">
			<div class="header-left pl-md-6 pl-4">
				<div class="brand-medium">
					{{ $t('templates.proposals.my-saved-templates-library') }}
				</div>
			</div>
			<div class="header-right pr-md-6 pr-4">
				<v-btn icon class="primary-action" @click="handleClose()">
					<v-icon :size="20">$close</v-icon>
				</v-btn>
			</div>
		</div>

		<div class="cards-body-wrapper show-scrollbar">
			<div class="cards-body">
				<div v-if="!sampleMode && !paidAccount" class="column-format centered mb-3 px-3 mt-3 mx-1">
					<free-tier-card
						style="height:90px; width:100%"
						message="Templates are not available on the Essentials plan. You can explore this feature, but you won't be able to create projects and proposals from these templates."
						@upgrade-clicked="upgradeClicked()"
					/>
				</div>
				<proposal-templates
					v-if="currentView === 'MINE'"
					:client-id="clientId"
					:opportunity="opportunity"
					@result="handleTemplateSelect($event)"
					mode="MODAL"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import ProposalTemplates from '@/modules/templates/proposals/ProposalTemplates';
	// import Template from '@/modules/templates/Template';
	import FreeTierCard from '@/components/FreeTierCard';

	export default {
		name: 'ProposalTemplateModal',

		props: ['view', 'clientId','opportunity'], // view is "MINE" or "HECTIC"

		components: { ProposalTemplates, FreeTierCard },

		data() {
			return {
				newButtonDisabled: false,
				currentView: 'MINE',

				pages: [
					{ label: 'My saved proposal template library', value: 'MINE' },
					{ label: 'Moxie proposal template library', value: 'HECTIC' },
				],
			};
		},
		mounted() {
			if (this.v) {
				this.currentView = this.v;
				// this.templatejs.setLastViewed(this.v);
			}
		},
		methods: {
			setView: function(view) {
				this.currentView = view.toUpperCase();
			},
			handleTemplateSelect(event) {
				this.$emit('result', event);
			},
			upgradeClicked: function() {
				this.$router.push('/subscription');
			},

			handleClose() {
				this.$emit('result');
			},
		},

		watch: {
			v: {
				immediate: true,
				handler(val) {
					if (val) {
						this.currentView = val;
					} else {
						this.currentView = 'MINE';
					}
				},
			},
		},

		computed: {
			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},
		},
	};
</script>

<style lang="scss">
	#proposal-templates-modal {
		background-color: var(--v-gray_10-base);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.proposal-header {
			flex: 0 0 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);

			display: flex;
			justify-content: space-between;
			align-items: center;
			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				& > div {
					flex: 0 0 auto;
				}
			}
		}
		> .cards-body-wrapper {
			flex: 0 0 calc(var(--vh) - 56px);
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			//border: 1px solid green;
			.cards-body {
				flex: 1 1 1104px;
				height: calc(var(--vh) - 80px);
				background-color: var(--v-white-base);
				border: 1px solid var(--v-gray_50-base);
				max-width: 1112px;
				margin: 0 12px;
				padding-top: 12px;
			}
		}
	}
</style>
