import ClientDetailView from '@/modules/clients/ClientDetailView';
import ClientListView from "@/modules/clients/ClientListView";
import ContactList from "@/modules/clients/contacts/ContactList";
import ClientRouter from "@/modules/clients/ClientRouter";

export default [
	{
		path: '/clients',
		name: 'clientList',
		component: ClientRouter,
		title: 'Client List',
	},
	{
		path: '/clients/overview',
		name: 'clientOverview',
		component: ClientListView,
		title: 'Client List',
	},

	{
		path: '/clients/contacts',
		name: 'contactList',
		component: ContactList,
		title: 'Contact List',
	},
	{
		path: '/client/:id',
		name: 'clientDetail',
		component: ClientDetailView,
		title: 'Client Detail',
		props: (route) => ({
			id: route.params.id,
			v: route.query.v,
			projectId: route.query.projectId,
			proposalId: route.query.proposalId,
		}),
	},
];
