var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "template-library" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("result")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding text-left column-format gap-2" },
        [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.type) + " template library")
          ]),
          _c(
            "div",
            {
              staticClass: "row-format gap-1",
              staticStyle: { "flex-wrap": "wrap" }
            },
            _vm._l(_vm.categories, function(category) {
              return _c(
                "v-chip",
                {
                  key: category,
                  staticClass: "pointer",
                  attrs: {
                    color:
                      _vm.selectedCategory === category
                        ? "secondary"
                        : "primary"
                  },
                  on: {
                    click: function($event) {
                      _vm.selectedCategory = category
                    }
                  }
                },
                [_vm._v(_vm._s(category))]
              )
            }),
            1
          ),
          _c("v-data-table", {
            attrs: {
              items: _vm.filteredTemplates,
              "items-per-page": 5,
              "fixed-header": "",
              headers: _vm.headers
            },
            scopedSlots: _vm._u([
              {
                key: "header.action",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "row-format" },
                      [
                        _c("v-text-field", {
                          staticClass: "ml-auto",
                          staticStyle: {
                            "font-weight": "normal",
                            "max-width": "180px"
                          },
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            placeholder: "Filter...",
                            "append-icon": "filter_alt"
                          },
                          model: {
                            value: _vm.filter,
                            callback: function($$v) {
                              _vm.filter = $$v
                            },
                            expression: "filter"
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "item.action",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { outlined: "", color: "primary", small: "" },
                        on: {
                          click: function($event) {
                            return _vm.useTemplate(item)
                          }
                        }
                      },
                      [_vm._v("Import")]
                    )
                  ]
                }
              },
              {
                key: "no-data",
                fn: function() {
                  return [_vm._v("Please select a category")]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }