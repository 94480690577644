<template>
	<div>
		<div v-if="detailPerson">
			<person-detail :person="detailPerson" @back="showAll()" @person-updated="personUpdated($event)" @view-communication="$emit('view-communication',$event)"></person-detail>
		</div>
		<div v-else class="row-format chip-container">
			<person-chip v-for="(contact,index) in contacts" :key="`contact-${index}`" :contact="contact" @show-detail="showDetail(contact,index)"></person-chip>
		</div>
	</div>

</template>

<script>
import PersonChip from "@/modules/communicator/inbox/PersonChip";
import PersonDetail from "@/modules/communicator/inbox/PersonDetail";
export default {
	name: "PeopleInThread",

	props: ['contacts'],

	components: {PersonDetail, PersonChip},

	data: function () {
		return {
			detailPerson: null,
			detailIndex: null,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		personUpdated: function(person){
			if(person.deleted){
				this.showAll();
			}else {
				this.contacts.splice(this.detailIndex, 1, person);
				this.detailPerson = person;
			}
		},

		showDetail: function(contact,index){
			this.detailPerson = contact;
			this.detailIndex = index;
		},

		showAll: function(){
			this.detailPerson = null;
			this.detailIndex = null;
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">
	.chip-container {
		display: inline-flex;
		flex-wrap: wrap;
		gap: 6px;
	}
</style>