<template>
	<div style="min-width: 600px; min-height: 400px;" class="pa-3">
		<div class="row-format gap-1 align-center">
			<v-text-field
				ref="searchForm"
				dense
				hide-details
				outlined
				v-model="searchTerm"
				@keyup.native.enter="searchPhotos()"
				:placeholder="$t('proposal.cover.image-search-btn')"
				single-line
				style="max-width: 200px"
			>
				<template v-slot:append><v-icon class="pointer" @click="searchPhotos()">search</v-icon></template>
			</v-text-field>

			<v-select
				style="max-width: 150px"
				v-model="orientation"
				dense
				hide-details
				outlined
				:items="['Landscape', 'Portrait', 'Squarish']"
				@change="searchPhotos()"
			></v-select>
			<div class="ml-auto"><v-icon class="pointer" @click="$emit('close')">close</v-icon></div>
		</div>
		<div class="images-wrapper mt-2">
			<div class="image-grid" v-if="photosReady">
				<div
					v-for="result in photoResults"
					:key="result.id"
					:style="`background-image: url(${result.urls.thumb});`"
					class="image-container"
					@click="setUnsplashImage(result)"
				>
					<div class="credit-container">
						<a :href="`${result.user.links.html}`" target="_blank" class="credit-anchor">
							{{ result.user.first_name }} {{ result.user.last_name }}
						</a>
					</div>
				</div>
			</div>

			<div class="font-10 mt-1 text-right">
				{{ $t('proposal.cover.photos-by') }}
				<a href="https://unsplash.com/?utm_source=hectic&utm_medium=referral" target="_blank">Unsplash</a>
			</div>
		</div>
		<div class="row-format centered">
			<v-btn @click.native="previousPage()" class="primary-action mr-1" icon><v-icon size="20">$chevronLeft</v-icon></v-btn>
			<v-btn @click.native="nextPage()" class="primary-action ml-1" icon><v-icon size="20">$chevronRight</v-icon></v-btn>
		</div>
	</div>
</template>

<script>
	import PhotoService from '@/modules/agreements/PhotoService';

	export default {
		name: 'ImageSearch',

		props: [],

		components: {},

		data: function() {
			return {
				perPage: 20,
				searchTerm: null,
				defaultSearch: 'backgrounds',
				orientation: 'Landscape',
				photos: {},
				photosReady: false,
				photoService: new PhotoService(),
			};
		},

		mounted() {
			this.searchPhotos();
		},

		beforeDestroy() {},

		methods: {
			searchPhotos: function(page = 1, perPage = this.perPage) {
				this.currentPage = page;
				let search = this.$validations.isEmpty(this.searchTerm) ? this.defaultSearch : this.searchTerm;

				this.photoService.searchStockPhotos(search, page, perPage, this.orientation).then((res) => {
					this.photos = res.data;
					this.photosReady = true;
				});
			},

			nextPage: function() {
				if (this.currentPage < this.photos.total_pages) {
					this.searchPhotos(++this.currentPage);
				}
			},

			previousPage: function() {
				if (this.currentPage > 1) {
					this.searchPhotos(--this.currentPage);
				}
			},

			setUnsplashImage(image) {
				this.$emit('result', image.urls.raw + '&q=50&width=1024');
			},
		},

		computed: {
			photoResults: function() {
				let result = [...this.photos.results];
				return result.splice(0, this.perPage);
			},
		},
	};
</script>

<style scoped lang="scss">
	.search-wrapper {
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		height: 36px;
		padding: 4px 4px 4px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 50%;
		.v-input {
			margin: 0 !important;
			font-size: 14px;
			line-height: 16px;
			.v-input__control {
				.v-input__slot {
					label {
						display: none;
					}
					&:before,
					&:after {
						border-style: none !important;
					}
				}
			}
		}
		.vertical-rule {
			width: 8px;
			height: 24px;
			border-right: 1px solid var(--v-gray_60-base);
		}
		.magnifier {
			width: 36px;
			//border: 1px solid red;
		}
	}
	.images-wrapper {
		.image-grid {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-column-gap: 6px;
			grid-row-gap: 6px;

			.image-container {
				//width: 100%;
				height: 70px;
				border-radius: 2px;
				display: flex;
				align-items: flex-end;
				&.custom-image-container {
					align-items: flex-start;
					justify-content: flex-end;
					padding: 4px;
					.v-btn {
						opacity: 0;
						transition: opacity 300ms;
					}
					&:hover {
						.v-btn {
							opacity: 1;
							transition: opacity 300ms;
						}
					}
				}
				cursor: pointer;
				background-size: cover;
				background-repeat: no-repeat !important;
				background-position: center center;

				&.no-image-container {
					align-items: center;
					justify-content: center;
					font-size: 12px;
					min-height: 70px !important;
					color: var(--v-gray_80-base);
					border: 1px solid var(--v-gray_50-base);
					&.account-logo {
						background-color: var(--v-gray_30-base);
						border: none;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				&.account-logo {
					border: 1px solid var(--v-gray_50-base);
					background-size: contain;
				}
			}
		}
		.credit-container {
			background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
			display: inline-block;
			align-self: flex-end;
			width: 100%;
			padding: 2px 3px 1px;
			text-align: left;
			.credit-anchor {
				display: block;
				color: black;
				font-size: 10px;
				font-weight: 500;
				text-decoration: none;
				max-width: 100px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
</style>
