<template>
	<v-text-field
		style="max-width: 150px; background-color: var(--v-white-base)"
		hide-details
		dense
		outlined
		placeholder="Search..."
		@input="updated"
		v-model="filter"
	>
		<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
	</v-text-field>
</template>

<script>
	export default {
		name: 'InboxFilter',

		props: ['value'],

		components: {},

		data: function() {
			return {
				menuOpen: false,
				filter: this.value,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			clearFilter: function() {
				this.filter = null;
				this.updated();
			},
			updated: function() {
				this.$emit('input', this.filter);
			},
		},

		computed: {
			filterLabel() {
				return 'Search';
			},

			filterDescription() {
				let l = [];
				let f = this.filter;

				if (f && f.length > 0) {
					l.push('"' + f + '"');
				}

				if (l.length) {
					return l.join(' &bull; ');
				} else {
					return null;
				}
			},

			isDefaultFilter() {
				if (this.filterDescription === null) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.active {
		background-color: var(--v-blue_5-base) !important;
		color: var(--v-blue_70-base) !important;
		border: 1px solid var(--v-blue_5-base) !important;
	}

	.scope-button {
		background-color: var(--v-white-base);
		color: var(--v-gray_80-base);
		border: 1px solid var(--v-gray_50-base);
		border-radius: 12px;
		padding: 4px 8px;
		width: fit-content;
		font-size: 12px;
		font-weight: 500;
		cursor: pointer;
		white-space: nowrap;

		&:hover {
			background-color: var(--v-gray_20-base);
		}
	}
</style>
