<template>
	<div>
		<div v-if="view === 'COUNTRIES'" class="">
			<div class="font-20 font-gray_80 mt-5">Claim your number</div>
			<div class="row-format centered font-gray_80 mt-5" style="gap: 48px; flex-wrap: wrap">
				<div class="pointer" @click="searchNumbers('US')">
					<div>United States</div>
					<img src="https://assets.ipstack.com/flags/us.svg" width="150" style="border-radius: 8px" />
				</div>
				<div class="pointer" @click="searchNumbers('CA')">
					<div>Canada</div>
					<img src="https://assets.ipstack.com/flags/ca.svg" width="150" style="border-radius: 8px" />
				</div>
				<div class="pointer" @click="searchNumbers('GB')">
					<div class="">United Kingdom</div>
					<img src="https://assets.ipstack.com/flags/gb.svg" width="150" style="border-radius: 8px" />
				</div>
			</div>
		</div>
		<div v-else-if="view === 'SEARCH'">
			<div>
				<div class="row-format pointer mb-2 font-gray_80" @click="viewCountries" style="width: fit-content">
					<v-icon>$arrowLeft</v-icon>
					<div>Back</div>
				</div>
			</div>
			<bandwidth-numbers
				:country="country"
				v-if="country === 'XX'"
				@order-complete="$emit('order-complete')"
			></bandwidth-numbers>
			<create-twilio-account :country="country" v-else @order-complete="$emit('order-complete')"></create-twilio-account>
		</div>
	</div>
</template>

<script>
	import countries from '@/assets/data/countries.json';
	import BandwidthNumbers from '@/modules/communicator/settings/providers/bandwidth/BandwidthNumbers';
	import CreateTwilioAccount from '@/modules/communicator/settings/providers/twilio/CreateTwilioAccount';

	export default {
		name: 'NewSubscription',

		props: [],

		components: { CreateTwilioAccount, BandwidthNumbers },

		data: function() {
			return {
				countries: countries,
				country: null,
				view: 'COUNTRIES',
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			searchNumbers: function(country) {
				this.country = country;
				this.view = 'SEARCH';
			},

			viewCountries: function() {
				this.country = null;
				this.view = 'COUNTRIES';
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
