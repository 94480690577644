<template>
	<div style="width: 100%; height: 100%" class="column-format centered">
		<div class="brand-bold font-24">Hang tight, we are connecting your Stripe account.</div>
	</div>
</template>

<script>
	import StripeIntegrationService from '@/modules/integrations/stripe/StripeIntegrationService';

	export default {
		name: 'StripeOAuthHandler',

		props: ['code'],

		components: {},

		data: function () {
			return {
				stripeIntegrationService: new StripeIntegrationService(),
			};
		},

		mounted() {
			this.$store.commit('startLoading');
			this.connectStripeAccount();
		},

		beforeDestroy() {},

		methods: {
			connectStripeAccount: function () {
				this.stripeIntegrationService
					.connectWithOAuthCode(this.code)
					.then(() => {
						this.$store.commit('info', 'Stripe account linked.');
					})
					.catch((err) => {
						this.$store.commit('error', 'Error connecting your Stripe account: ' + err.response.data.message);
					})
					.finally(() => {
						this.$store.state.eventBus.$emit('open-settings-modal','stripe');
						this.$store.commit('stopLoading');
					});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
