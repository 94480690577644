var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "proposal-deliverable-wrapper" },
    [
      _c(
        "v-row",
        {
          class: _vm.classList,
          style:
            _vm.index === 0
              ? "border-top: 1px solid var(--v-gray_50-base);"
              : "",
          on: {
            click: function($event) {
              return _vm.handleClick()
            },
            mouseover: function($event) {
              _vm.hovering = true
            },
            mouseleave: function($event) {
              _vm.hovering = false
            }
          }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "deliverable",
              staticStyle: { "align-items": "center" },
              attrs: { cols: _vm.mainDeliverableColumns, align: "left" }
            },
            [
              _vm.editMode
                ? _c(
                    "v-btn",
                    {
                      staticClass: "deliverableHandle mr-1",
                      attrs: { icon: "", "x-small": "" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return function() {}.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm.editMode
                        ? _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v("$drag")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "column-format" }, [
                _c("div", { staticClass: "brand-medium" }, [
                  _vm._v(_vm._s(_vm.deliverableLabel))
                ]),
                _vm.descriptionLabel
                  ? _c(
                      "div",
                      {
                        staticClass: "font-gray_80",
                        staticStyle: { "white-space": "pre-wrap" }
                      },
                      [_vm._v(_vm._s(_vm.descriptionLabel))]
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm.showPricing
            ? _c(
                "v-col",
                {
                  staticClass: "deliverable",
                  style: _vm.$vuetify.breakpoint.xsOnly
                    ? ""
                    : "justify-content: flex-end; text-align: right",
                  attrs: { cols: "12", sm: "3" }
                },
                [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.deliverablePricing) }
                  })
                ]
              )
            : _vm._e(),
          _vm.showDueDate
            ? _c(
                "v-col",
                {
                  staticClass: "deliverable",
                  style: _vm.$vuetify.breakpoint.xsOnly
                    ? ""
                    : "justify-content: flex-end",
                  attrs: { cols: "12", sm: "2" }
                },
                [_c("div", [_vm._v(_vm._s(_vm.dueDateFormatted))])]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isReady
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.edit, persistent: true, "max-width": 565 },
              on: {
                close: function($event) {
                  return _vm.cancelEdit()
                }
              }
            },
            [
              _c(
                "div",
                { attrs: { id: "proposal-deliverable-modal" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "close-dialog",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelEdit()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("$close")])],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.stopPropagation()
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-padding" },
                        [
                          _vm.deliverable.initialSetupTask
                            ? _c("div", { staticClass: "modal-title" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("proposal.deliverables.setup"))
                                )
                              ])
                            : _c("div", { staticClass: "modal-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("proposal.deliverables.deliverable")
                                  )
                                )
                              ]),
                          _c("v-text-field", {
                            staticClass: "h-outline",
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": "",
                              label: _vm.deliverable.initialSetupTask
                                ? _vm.$t("proposal.deliverables.fee-name")
                                : _vm.$t("proposal.deliverables.name")
                            },
                            model: {
                              value: _vm.deliverable.deliverable,
                              callback: function($$v) {
                                _vm.$set(_vm.deliverable, "deliverable", $$v)
                              },
                              expression: "deliverable.deliverable"
                            }
                          }),
                          _c("v-textarea", {
                            staticClass: "h-outline",
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": "",
                              label: _vm.$t(
                                "proposal.deliverables.description"
                              ),
                              "auto-grow": "",
                              rows: "1"
                            },
                            model: {
                              value: _vm.deliverable.description,
                              callback: function($$v) {
                                _vm.$set(_vm.deliverable, "description", $$v)
                              },
                              expression: "deliverable.description"
                            }
                          }),
                          _vm.showProductSelect
                            ? _c(
                                "div",
                                [
                                  _c("per-item-pricing", {
                                    attrs: {
                                      "hide-icon": true,
                                      product: _vm.deliverable.product,
                                      quantity: _vm.deliverable.quantity,
                                      editable: true,
                                      currency: _vm.currency
                                    },
                                    on: {
                                      "product-selected": function($event) {
                                        return _vm.handleProductSelected($event)
                                      },
                                      "quantity-updated": function($event) {
                                        return _vm.handleQuantityUpdated($event)
                                      }
                                    }
                                  }),
                                  _c("v-switch", {
                                    staticClass: "mt-2 date-type-switch",
                                    attrs: {
                                      label: _vm.$t(
                                        "proposal.deliverables.show-pricing-detail"
                                      ),
                                      inset: "",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.deliverable.showPricingDetail,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.deliverable,
                                          "showPricingDetail",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliverable.showPricingDetail"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-switch", {
                            staticClass: "mt-2 date-type-switch",
                            attrs: {
                              label: _vm.$t("proposal.content.relative-date"),
                              inset: "",
                              dense: "",
                              "hide-details": ""
                            },
                            on: { change: _vm.handleDateTypeSwitch },
                            model: {
                              value: _vm.dateTypeSwitch,
                              callback: function($$v) {
                                _vm.dateTypeSwitch = $$v
                              },
                              expression: "dateTypeSwitch"
                            }
                          }),
                          _vm.dateType === "RELATIVE"
                            ? [
                                _c("div", { staticClass: "label-style" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("proposal.content.how-soon"))
                                  )
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-space-between half",
                                    staticStyle: { gap: "12px" }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "h-outline-no-label mb-0",
                                      attrs: {
                                        type: "number",
                                        inputmode: "number",
                                        min: "0",
                                        placeholder: _vm.$t(
                                          "templates.packages.deliverable-detail-modal.duration-placeholder",
                                          {
                                            units: _vm.humanUnits
                                          }
                                        ),
                                        "hide-details": "",
                                        "persistent-placeholder": "",
                                        disabled: _vm.dateType === "FIXED"
                                      },
                                      on: {
                                        keydown: function($event) {
                                          return _vm.handleDurationKeydown(
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.deliverable.dueDateRelative
                                            .duration,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.deliverable.dueDateRelative,
                                            "duration",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "deliverable.dueDateRelative.duration"
                                      }
                                    }),
                                    _c("v-select", {
                                      staticClass: "h-outline-no-label mb-0",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "templates.packages.deliverable-detail-modal.units-placeholder"
                                        ),
                                        "hide-details": "",
                                        "persistent-placeholder": "",
                                        items: _vm.timeUnits,
                                        "item-value": "value",
                                        "item-text": "text",
                                        disabled: _vm.dateType === "FIXED"
                                      },
                                      model: {
                                        value:
                                          _vm.deliverable.dueDateRelative
                                            .timeUnit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.deliverable.dueDateRelative,
                                            "timeUnit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "deliverable.dueDateRelative.timeUnit"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _vm.dateType === "FIXED"
                            ? [
                                _c("div", { staticClass: "label-style" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("proposal.content.fixed-date")
                                    )
                                  )
                                ]),
                                _c(
                                  "v-menu",
                                  {
                                    ref: "menu",
                                    attrs: {
                                      "close-on-content-click": false,
                                      transition: "scale-transition",
                                      "offset-y": "",
                                      "min-width": "290px",
                                      disabled: _vm.dateType === "RELATIVE"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "h-outline-no-label pr-2 mb-0",
                                                      attrs: {
                                                        "hide-details": "",
                                                        "persistent-placeholder":
                                                          "",
                                                        placeholder: _vm.$t(
                                                          "proposal.deliverables.delivery-date"
                                                        ),
                                                        value:
                                                          _vm.dueDateFormattedInput,
                                                        readonly: ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "append",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "clear-date-icon"
                                                                  },
                                                                  [
                                                                    _vm
                                                                      .deliverable
                                                                      .dueDate !==
                                                                    null
                                                                      ? _c(
                                                                          "v-icon",
                                                                          {
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                _vm.deliverable.dueDate = null
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "$clear"
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "due-date-icon"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        class:
                                                                          "icon-two-color" +
                                                                          (_vm.dateType ===
                                                                          "RELATIVE"
                                                                            ? " disabled"
                                                                            : ""),
                                                                        nativeOn: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.dueDateMenu = true
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "$calendar"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2307620320
                                    ),
                                    model: {
                                      value: _vm.dueDateMenu,
                                      callback: function($$v) {
                                        _vm.dueDateMenu = $$v
                                      },
                                      expression: "dueDateMenu"
                                    }
                                  },
                                  [
                                    _c("v-date-picker", {
                                      attrs: {
                                        "first-day-of-week":
                                          _vm.$store.getters.firstDayOfWeek,
                                        "no-title": "",
                                        scrollable: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.dueDateEdited()
                                        }
                                      },
                                      model: {
                                        value: _vm.deliverable.dueDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.deliverable,
                                            "dueDate",
                                            $$v
                                          )
                                        },
                                        expression: "deliverable.dueDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "modal-footer" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary-action mr-1",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.deleteDialog = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "gray_70", size: "20" } },
                                [_vm._v("$delete")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary-action ml-1",
                              attrs: { width: 120 },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.saveEdit()
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "basic-modal",
        {
          attrs: {
            dialog: _vm.deleteDialog,
            persistent: false,
            "max-width": 290
          },
          on: {
            close: function($event) {
              return _vm.cancelDelete()
            }
          }
        },
        [
          _c(
            "div",
            { attrs: { id: "delete-deliverable-modal" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-dialog",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancelDelete()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("$close")])],
                1
              ),
              _c("div", { staticClass: "px-10 py-6" }, [
                _c("div", { staticClass: "brand-semilight font-16" }, [
                  _vm._v(_vm._s(_vm.$t("proposal.deliverables.delete-confirm")))
                ])
              ]),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary-action mr-1", width: "100" },
                      on: {
                        click: function($event) {
                          return _vm.cancelDelete()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("global.no")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary-action ml-1", width: "100" },
                      on: {
                        click: function($event) {
                          return _vm.doDelete()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("global.yes")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }