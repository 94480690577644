<template>
	<div>
		<input type="hidden" v-model="source" ref="source" df-source />
		<input type="hidden" v-model="template_id" ref="template_id" df-template_id />
		<input type="hidden" v-model="template_name" ref="template_name" df-template_name />
		<input type="hidden" v-model="project_name" ref="project_name" df-project_name />

		<node-label @open="open()">
			<template v-slot:default>
				<v-icon class="material-symbols-rounded mr-1" color="black" size="20">folder_special</v-icon>
				<div>Create project</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" title="Create project">
			<template v-slot:default>
				<div class="column-format gap-2">
					<v-select
						hide-details
						dense
						outlined
						persistent-placeholder
						label="Source"
						:items="types"
						item-text="label"
						item-value="value"
						v-model="source"
					></v-select>
					<div v-if="source === 'FromTemplate'" class="column-format gap-2">
						<v-select
							hide-details
							dense
							outlined
							persistent-placeholder
							label="Template"
							:items="templates"
							item-text="name"
							item-value="id"
							v-model="template_id"
						></v-select>
						<v-text-field
							hide-details
							dense
							outlined
							persistent-placeholder
							v-model="project_name"
							label="Project name"
							placeholder="Optional"
						></v-text-field>
					</div>
				</div>
			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeMixin from '@/modules/workflow/nodes/NodeMixin';
	import NodeModal from '@/modules/workflow/nodes/NodeModal';
	import NodeLabel from '@/modules/workflow/nodes/NodeLabel';
	import TemplateService from '@/modules/templates/TemplateService';

	export default {
		name: 'Project',
		inputs: 1,
		outputs: 1,

		props: [],
		mixins: [NodeMixin],

		components: { NodeLabel, NodeModal },

		data: function() {
			return {
				source: 'FromTemplate',
				template_id: null,
				template_name: null,
				project_name: null,

				templates: [],
				templateService: new TemplateService(),
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.source = this.$refs.source.value;
				this.template_id = this.$refs.template_id.value;
				this.template_name = this.$refs.template_name.value;
				this.project_name = this.$refs.project_name.value;
			});
		},

		beforeDestroy() {},

		methods: {
			open: function() {
				this.templateService.getDeliverablePackages().then((res) => {
					this.templates.splice(0, this.templates.length);
					this.templates.push(...res.data);
					this.$refs.nodeModal.open();
				});
			},
		},

		watch: {
			source: function() {
				this.emitInput('source');
			},

			selectedTemplate: function() {
				if (this.selectedTemplate) {
					this.template_id = this.selectedTemplate.id;
					this.template_name = this.selectedTemplate.name;
				} else {
					this.template_id = null;
					this.template_name = null;
				}

				this.$nextTick(() => {
					this.emitInput('template_id');
					this.emitInput('template_name');
				});
			},

			project_name: function() {
				this.emitInput('project_name');
			},
		},

		computed: {
			selectedTemplate: function() {
				return this.templates.find((t) => t.id === this.template_id);
			},

			types: function() {
				return [
					{ label: 'From template', value: 'FromTemplate' },
				];
			},
		},
	};
</script>
