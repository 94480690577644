var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "show-scrollbar",
      staticStyle: {
        "max-height": "calc(var(--vh) - 50px)",
        "overflow-y": "auto"
      }
    },
    [
      _c("communicator-widget", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.focus === "phone",
            expression: "focus === 'phone'"
          }
        ],
        attrs: { primary: true, "is-visible": _vm.communicatorVisible }
      }),
      _vm.focus === "productivity" ? _c("notes-to-dos") : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.focus === "notifications",
              expression: "focus === 'notifications'"
            }
          ],
          staticClass: "ml-2 mt-2"
        },
        [
          _c("notifications", {
            attrs: { "is-visible": _vm.notificationsVisible }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }