var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "templates-main" } }, [
    _c(
      "div",
      { staticClass: "fill-height" },
      [
        !_vm.paidAccount
          ? _c(
              "div",
              { staticClass: "column-format centered mb-3 px-3 mt-3 mx-1" },
              [
                _c("free-tier-card", {
                  staticStyle: { height: "90px", width: "100%" },
                  attrs: { message: _vm.freeMessage },
                  on: {
                    "upgrade-clicked": function($event) {
                      return _vm.upgradeClicked()
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.currentView === "agreements"
          ? _c("proposal-templates", {
              ref: "agreements",
              attrs: { id: _vm.id }
            })
          : _vm._e(),
        _vm.currentView === "packages"
          ? _c("deliverable-package-list", {
              ref: "packages",
              attrs: { id: _vm.id }
            })
          : _vm._e(),
        _vm.currentView === "emails"
          ? _c("email-template-list", { ref: "emails" })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }