import HttpClient from "@/services/HttpClient";

export default class StockPhotoService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	searchStockPhotos(query, page = 1, perPage = 18) {
		return this.httpClient
			.get(`/photos/search?query=${encodeURIComponent(query)}&page=${page}&perPage=${perPage}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	downloadStockPhoto(photoUrl) {
		return this.httpClient
			.get('/photos/download', {
				photoUrl: photoUrl,
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
