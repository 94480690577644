import HttpClient from '@/services/HttpClient';
import PodService from '@/services/PodService';
import { createParser } from 'eventsource-parser';
import store from '@/store';
import AiLicenseAlert from '@/modules/ai/AiLicenseAlert';

export default class AiAssistantService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getEntitlementStatus() {
		return this.httpClient
			.get(`/ai/entitlement`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	grantEntitlement(userId) {
		return this.httpClient
			.post(`/ai/entitlement/grant?userId=${userId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	revokeEntitlement(userId) {
		return this.httpClient
			.post(`/ai/entitlement/revoke?userId=${userId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getChatSessionList(page = 0, size = 20) {
		return this.httpClient
			.get(`/ai/sessions?page=${page}&size=${size}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getChatSession(id) {
		return this.httpClient
			.get(`/ai/sessions/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteChatSession(id) {
		return this.httpClient
			.delete(`/ai/sessions/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createChatSession(createRequest) {
		if (!this.verifyEntitlement()) {
			return Promise.reject({
				response: {
					data: {
						message: 'No Moxie Assistant License',
					},
				},
			});
		}
		return this.httpClient
			.post(`/ai/sessions`, createRequest)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	continueSession(id, message) {
		if (!this.verifyEntitlement()) {
			return Promise.reject({
				response: {
					data: {
						message: 'No Moxie Assistant License',
					},
				},
			});
		}
		return this.httpClient
			.put(`/ai/sessions/${id}`, message, {
				headers: {
					'Content-Type': 'text/plain',
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	async continueSessionStreaming(id, message, callbacks) {
		if (!this.verifyEntitlement()) {
			return Promise.reject({
				response: {
					data: {
						message: 'No Moxie Assistant License',
					},
				},
			});
		}
		await new PodService().asyncPing(); //make sure our AccessToken is good

		const response = await fetch(`${store.getters.getBaseUrl}/ai/sessions/${id}/streaming`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'text/plain',
				Authorization: 'Bearer ' + store.state.accessToken,
			},
			body: message,
		});

		const reader = response.body.getReader();
		const decoder = new TextDecoder('utf-8');
		let done = false;

		let parser = createParser((event) => {
			if (event.type === 'event') {
				if (event.data && event.data.toLowerCase() === '"[done]"') {
					if (callbacks && typeof callbacks.onComplete === 'function') {
						callbacks.onComplete();
					}
				} else {
					if (callbacks && typeof callbacks.onData === 'function') {
						let cleanData = event.data.substring(1, event.data.length - 1);
						cleanData = cleanData.replace(/\\n/g, '\n');
						callbacks.onData(cleanData);
					}
				}
			} else if (event.type === 'reconnect-interval') {
				console.log('We should set reconnect interval to %d milliseconds', event.value);
			}
		});

		while (!done) {
			const { value, done: readerDone } = await reader.read();
			done = readerDone;
			const chunk = decoder.decode(value, { stream: true });
			parser.feed(chunk);
		}
	}

	/**
	 *
	 * @param text
	 * @param modifier - simplify, elaborate, formalize, friendlier, summarize, paraphrase
	 * @returns {Promise<unknown>}
	 */
	rewrite(text, modifier) {
		return new Promise((resolve, reject) => {
			try {
				let createRequest = {
					model: 'gpt-4o-mini',
					useCase: 'Rewrite',
					context: [],
					temperature: 0.8,
					top_p: 0.8,
					fetchResponse: true,
				};

				createRequest.context.push({
					role: 'system',
					content:
						'You are a writing assistant helping the user to rewrite chunks of text with a specific modifier.  Only return ONLY the modified text, no additional prompts, instructions, or questions.',
				});

				let prompt;

				if (modifier === 'simplify') {
					prompt = `Please simplify this text: ${text}`;
				} else if (modifier === 'elaborate') {
					prompt = `Please elaborate on this text: ${text}`;
				} else if (modifier === 'formalize') {
					prompt = `Please make this text more formal: ${text}`;
				} else if (modifier === 'friendlier') {
					prompt = `Please make this text friendlier: ${text}`;
				} else if (modifier === 'summarize') {
					prompt = `Please summarize this text: ${text}`;
				} else if (modifier === 'paraphrase') {
					prompt = `Please paraphrase this text: ${text}`;
				} else {
					resolve(`${modifier} is not valid.`);
				}

				createRequest.context.push({
					role: 'user',
					content: prompt,
				});

				this.createChatSession(createRequest)
					.then((res) => {
						let chatSession = res.data;
						let lastMessage = chatSession.messages[chatSession.messages.length - 1];
						resolve(lastMessage.content);
					})
					.catch((err) => reject(err));
			} catch (err) {
				reject(err);
			}
		});
	}

	verifyEntitlement() {
		if (store.getters.isAiAvailable) {
			return true;
		} else {
			store.state.globalModalController.openModal(AiLicenseAlert);
			return false;
		}
	}
}
