import HttpClient from "@/services/HttpClient";
import store from '@/store';

export default class CustomPageService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(process.env.VUE_APP_BASE_AUTH_URL,true);
	}

	getCustomPages() {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.get(`/account/${accountId}/portal/pages`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getCustomPage(id) {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.get(`/account/${accountId}/portal/pages/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	createCustomPage(type) {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.post(`/account/${accountId}/portal/pages?type=${type}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	updateCustomPage(page) {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.put(`/account/${accountId}/portal/pages/${page.id}`,page)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	deleteCustomPage(id) {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.delete(`/account/${accountId}/portal/pages/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
