var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c("input", {
        attrs: { id: "copyInput", name: "copyInput", type: "hidden" }
      }),
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Meetings" }
            ]
          }
        },
        [_vm._v(" Meeting schedulers ")]
      ),
      _c(
        "div",
        { staticClass: "row-format mb-4" },
        [
          _c("v-text-field", {
            staticStyle: {
              "max-width": "200px",
              "background-color": "var(--v-white-base)"
            },
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search...",
              color: "gray_30"
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "material-symbols-rounded font-gray_50" },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto super-action",
              on: {
                click: function($event) {
                  return _vm.handleAddNew()
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
              _vm._v(" Add scheduler")
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        key: _vm.tableKey,
        staticStyle: { cursor: "pointer" },
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredMeetings,
          "fixed-header": true,
          "items-per-page": -1,
          "hide-default-footer": true,
          "group-by": "folder"
        },
        on: {
          "click:row": function($event) {
            return _vm.openMeeting($event.id)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "group.header",
            fn: function(ref) {
              var group = ref.group
              var headers = ref.headers
              var toggle = ref.toggle
              return [
                _c(
                  "td",
                  {
                    staticClass: "text-left",
                    attrs: { colspan: "6" },
                    on: { click: toggle }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row-format align-center gap-2" },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "20", color: "secondary" } },
                          [_vm._v("folder")]
                        ),
                        _vm._v(_vm._s(group ? group : " -- ") + " ")
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [_c("span", [_vm._v(_vm._s(item.name))])]
            }
          },
          {
            key: "item.active",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.$formatters.yesNoFromBoolean(item.active)))
                ])
              ]
            }
          },
          {
            key: "item.duration",
            fn: function(ref) {
              var item = ref.item
              return [
                item.meetingLength
                  ? _c("span", [
                      _vm._v(
                        _vm._s(item.meetingLength.duration) +
                          " " +
                          _vm._s(item.meetingLength.timeUnit.toLowerCase())
                      )
                    ])
                  : _c("span", [_vm._v("--")])
              ]
            }
          },
          {
            key: "item.location",
            fn: function(ref) {
              var item = ref.item
              return [
                item.location &&
                item.location.type === "Zoom" &&
                !_vm.getZoomUser(item)
                  ? _c(
                      "div",
                      { staticClass: "row-format" },
                      [
                        _c("v-icon", { attrs: { color: "warning" } }, [
                          _vm._v("$alert")
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "ml-2",
                            staticStyle: { color: "var(--v-warning-base)" }
                          },
                          [
                            _vm._v(
                              " The Zoom account used in this scheduler is current disconnected. Please reconnect or choose a different meeting location. "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : item.location
                  ? _c("div", [_vm._v(" " + _vm._s(item.location.type) + " ")])
                  : _c("div", [_vm._v("--")])
              ]
            }
          },
          {
            key: "item.ownerUserId",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("assigned-user", {
                  attrs: { "assigned-to": item.ownerUserId, "show-name": false }
                })
              ]
            }
          },
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "row-format gap-2" },
                  [
                    _c(
                      "v-icon",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: { content: "Copy scheduler link" },
                            expression: "{content:'Copy scheduler link'}"
                          }
                        ],
                        staticClass: "ml-auto",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.copyToClipboard(item)
                          }
                        }
                      },
                      [_vm._v("link")]
                    ),
                    _c("meeting-action-menu", {
                      on: {
                        view: function($event) {
                          return _vm.openMeeting(item.id)
                        },
                        delete: function($event) {
                          return _vm.confirmDelete(item)
                        },
                        duplicate: function($event) {
                          return _vm.duplicateScheduler(item)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }