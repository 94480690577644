<template>
	<div>
		<input type="hidden" v-model="first" ref="first" df-first />
		<input type="hidden" v-model="last" ref="last" df-last />
		Hello {{ first }} {{ last }} <v-btn @click="changeName">Change</v-btn>
	</div>
</template>

<script>
	export default {
		name: 'Test',

		props: ['type'],

		components: {},

		data: function() {
			return {
				first: null,
				last: null,
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.first = this.$refs.first.value;
				this.last = this.$refs.last.value;
			});
		},

		beforeDestroy() {},

		methods: {
			changeName: function() {
				this.first = 'John';
				this.last = 'Deere';
			},

			emitInput(keyName) {
				this.$nextTick(() => {
					let event = new Event('input', {
						bubbles: true,
						cancelable: true,
					});
					this.$refs[keyName].dispatchEvent(event);
				})
			},
		},

		watch: {
			first: function() {
				this.emitInput('first');
			},

			last: function() {
				this.emitInput('last');
			},
		},
	};
</script>
