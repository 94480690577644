import HttpClient from '@/services/HttpClient';

export default class NoteService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getNoteList(clientId, projectId) {

		return this.httpClient
			.get(`/clients/${clientId}/notes`,{
				params: {
					projectId
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getNote(clientId, id) {
		return this.httpClient
			.get(`/clients/${clientId}/notes/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	postNote(clientId, data) {
		return this.httpClient
			.post(`/clients/${clientId}/notes`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	putNote(clientId, id, data) {
		return this.httpClient
			.put(`/clients/${clientId}/notes/${id}`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteNote(clientId, id) {
		return this.httpClient
			.delete(`/clients/${clientId}/notes/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	/* getProjectNoteList(clientId, projectId) {
		return this.httpClient
			.get(`/clients/${clientId}/projects/${projectId}/notes`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	postProjectNote(clientId, projectId, data) {
		return this.httpClient
			.post(`/clients/${clientId}/projects/${projectId}/notes`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	putProjectNote(clientId, projectId, id, data) {
		return this.httpClient
			.put(`/clients/${clientId}/projects/${projectId}/notes/${id}`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	} */
}
