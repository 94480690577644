<template>
	<div id="apply-deposit-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h2 class="modal-title">{{ $t('invoice.details.apply-deposit-modal.heading') }}</h2>
			<p class="modal-subtitle mb-3">
				{{ $t('invoice.details.apply-deposit-modal.subheading') }}
			</p>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				prefix="$"
				v-model="depositAccount.liabilities"
				:label="$t('invoice.details.apply-deposit-modal.deposit-collected')"
				:disabled="true"
			/>
			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				prefix="$"
				v-model="depositAccount.balance"
				:label="$t('invoice.details.apply-deposit-modal.deposit-available')"
				:disabled="true"
			/>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				type="number"
				hide-details
				:prefix="currencySymbol"
				v-model="amountToApply"
				:label="$t('invoice.details.apply-deposit-modal.apply-to-invoice')"
				:max="depositAccount.balance"
				@keyup.enter="handleSave"
			/>
		</div>
		<div class="modal-footer">
			<v-btn class="primary-action" style="width: 160px" @click="handleSave">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ApplyDepositModal',

		props: {
			invoice: { type: Object, required: true },
			depositAccount: { type: Object, required: true },
		},

		// components: { HPillSelector },

		data: function() {
			return {
				amountToApply: 0,
				// clientService: new ClientService(),
			};
		},

		mounted() {
			if (this.invoice.creditAmount) {
				this.amountToApply = this.invoice.creditAmount;
			} else if (this.depositAccount.balance <= this.invoice.amountDue) {
				this.amountToApply = this.depositAccount.balance;
			} else {
				this.amountToApply = this.invoice.amountDue;
			}
		},

		beforeDestroy() {},

		methods: {
			handleSave() {
				this.invoice.creditAmount = this.amountToApply;
				this.$emit('save');
				this.$emit('close');
			},
			// notifySuccess: function() {
			// 	this.$store.commit('success', 'Specified amount applied to balance due.');
			// },
		},
		computed: {
			currencySymbol: function(){
				return this.$formatters.currencySymbol(this.invoice.currency);
			}
		},
	};
</script>

<style lang="scss">
	#apply-deposit-modal {
		max-width: 275px;
	}
</style>
