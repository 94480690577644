var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800", "content-class": "universal-top-dialog" },
      model: {
        value: _vm.searchOpen,
        callback: function($$v) {
          _vm.searchOpen = $$v
        },
        expression: "searchOpen"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "column-format universal-search",
          attrs: { id: "universal-search" }
        },
        [
          _c(
            "div",
            { staticClass: "row-format align-center" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [_vm._v("search")]),
              _c("v-text-field", {
                staticClass: "gray-background",
                attrs: {
                  "hide-details": "",
                  dense: "",
                  flat: "",
                  solo: "",
                  placeholder:
                    "Search... " +
                    (_vm.isMac ? "Cmd+K" : "Ctrl+k") +
                    " for quick access",
                  autofocus: ""
                },
                on: { input: _vm.onSearchChange },
                model: {
                  value: _vm.query,
                  callback: function($$v) {
                    _vm.query = $$v
                  },
                  expression: "query"
                }
              })
            ],
            1
          ),
          _vm.results.length
            ? _c(
                "div",
                {
                  staticClass: "column-format gap-2 pr-2 show-scrollbar",
                  staticStyle: {
                    "max-height": "calc(100vh - 300px)",
                    "overflow-y": "auto"
                  }
                },
                _vm._l(_vm.results, function(result, index) {
                  return _c(
                    "div",
                    { key: result.searchObject.id },
                    [
                      result.isFirstOfType
                        ? _c(
                            "h3",
                            {
                              staticClass: "text-left mt-3 mb-1 brand-medium",
                              staticStyle: { "text-transform": "capitalize" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.makePlural(result.type).toLowerCase()
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("result", {
                        attrs: {
                          id: "result-" + index,
                          result: result,
                          selected: _vm.selectedIndex === index
                        },
                        on: {
                          click: function($event) {
                            return _vm.resultSelected(result)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }