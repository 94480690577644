import HttpClient from '@/services/HttpClient';

export default class MicrosoftCalendarService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    connect(code,redirectUri,scope) {
        let formData = new FormData();
        formData.append('code',code);
        formData.append('redirectUri',redirectUri);
        formData.append('scope',scope);

        return this.httpClient
            .post(`/microsoft/calendars`,formData,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getAllConnections(){
        return this.httpClient
            .get('/microsoft/calendars/all')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getConnections() {
        return this.httpClient
            .get('/microsoft/calendars')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    update(id,calendarList) {
        return this.httpClient
            .put(`/microsoft/calendars/${id}`,calendarList)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    disconnect(id) {
        return this.httpClient
            .delete(`/microsoft/calendars/${id}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}