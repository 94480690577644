<template>
	<div>
		<keypad
			@mute-call="toggleMute"
			@record-call="toggleRecord"
			:subscription="subscription"
			:call-is-active="callIsActive"
			@dial="dial"
			@hangup="hangup()"
			@digit="digit"
		></keypad>
		<bandwidth-soft-phone
			v-if="primary && subscription.provider === 'Bandwidth'"
			ref="SoftPhone"
			@call-active="callActive"
			@call-ringing="callRinging"
			@call-up="bandwidthCallUp"
			@phone-id="phoneId = $event"
		></bandwidth-soft-phone>
		<twilio-soft-phone
			v-if="primary && subscription.provider === 'Twilio'"
			ref="SoftPhone"
			@call-active="callActive"
			@call-ringing="callRinging"
			@call-up="twilioCallUp"
		></twilio-soft-phone>
	</div>
</template>

<script>
	import Keypad from '@/modules/communicator/realtime/voice/Keypad';
	import BandwidthSoftPhone from '@/modules/communicator/realtime/bandwidth/BandwidthSoftPhone';
	import TwilioSoftPhone from '@/modules/communicator/realtime/twilio/TwilioSoftPhone';
	import { DateTime } from 'luxon';

	export default {
		name: 'Phone',

		props: ['subscription', 'primary'],

		components: { TwilioSoftPhone, BandwidthSoftPhone, Keypad },

		data: function() {
			return {
				phoneId: null,
			};
		},

		mounted() {
			if (this.primary) {
				this.$store.state.eventBus.$on('communicator-phone-dial', this.primaryPerformDial);
				this.$store.state.eventBus.$on('communicator-phone-hangup', this.primaryPerformHangup);
				this.$store.state.eventBus.$on('communicator-phone-digit', this.primaryPerformDigit);
				this.$store.state.eventBus.$on('communicator-toggle-mute', this.primaryToggleMute);
				this.$store.state.eventBus.$on('communicator-toggle-record', this.primaryToggleRecord);
			}
		},

		beforeDestroy() {
			if (this.primary) {
				this.$store.state.eventBus.$off('communicator-phone-dial', this.primaryPerformDial);
				this.$store.state.eventBus.$off('communicator-phone-hangup', this.primaryPerformHangup);
				this.$store.state.eventBus.$off('communicator-phone-digit', this.primaryPerformDigit);
				this.$store.state.eventBus.$off('communicator-toggle-mute', this.primaryToggleMute);
				this.$store.state.eventBus.$off('communicator-toggle-record', this.primaryToggleRecord);
			}
		},

		methods: {
			dial: function(number) {
				this.$store.state.eventBus.$emit('communicator-phone-dial', number);
			},

			hangup: function() {
				this.$store.state.eventBus.$emit('communicator-phone-hangup');
			},

			digit: function(digit) {
				this.$store.state.eventBus.$emit('communicator-phone-digit', digit);
			},

			toggleMute: function() {
				this.$store.state.eventBus.$emit('communicator-toggle-mute');
			},

			toggleRecord: function() {
				this.$store.state.eventBus.$emit('communicator-toggle-record');
			},

			primaryToggleMute: function() {
				if (this.$store.state.communicator.callMuted) {
					this.$store.state.communicator.callMuted = false;
					this.$refs.SoftPhone.unMuteCall();
				} else {
					this.$store.state.communicator.callMuted = true;
					this.$refs.SoftPhone.muteCall();
				}
			},

			primaryToggleRecord: function() {
				this.$store.commit('startLoading');
				if (this.$store.state.communicator.callRecording) {
					this.$refs.SoftPhone.pauseRecording()
						.then(() => (this.$store.state.communicator.callRecording = false))
						.finally(() => this.$store.commit('stopLoading'));
				} else {
					this.$refs.SoftPhone.startRecording()
						.then(() => (this.$store.state.communicator.callRecording = true))
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			primaryPerformDial: function(number) {
				this.$refs.SoftPhone.makeCall(number);
			},

			primaryPerformHangup: function() {
				this.$refs.SoftPhone.hangupCall();
			},

			primaryPerformDigit: function(digit) {
				this.$refs.SoftPhone.dtmf(digit);
			},

			callActive: function(val) {
				this.$store.state.communicator.showExpanded = true;
				this.$store.state.communicator.selectedTab = 'Phone';
				this.$store.state.communicator.callIsActive = val;
			},

			callRinging: function(val) {
				console.log('Call ringing:' + val);
			},

			bandwidthCallUp: function(call) {
				if (call) {
					let activeCall = {
						direction: call.direction,
						from: call.pstn.from,
						to: call.pstn.to,
						start: call.direction === 'Out' ? call.pstn.answerTime : call.user.startTime,
						contactId: call.pstn.contactId,
					};
					this.$store.state.communicator.activeCall = activeCall;
				} else {
					this.$store.state.communicator.callMuted = false;
					this.$store.state.communicator.callRecording = false;
					this.$store.state.communicator.activeCall = null;
				}
			},

			twilioCallUp: function(call) {
				if (call) {
					let ourNumber = this.subscription.numbers[0].number;

					let activeCall = {
						direction: call._direction === 'OUTGOING' ? 'Out' : 'In',
						from: call._direction === 'INCOMING' ? call.parameters.From : ourNumber,
						to: call._direction === 'OUTGOING' ? call.customParameters.get('To') : ourNumber,
						start: DateTime.now().toISO(),
						contactId: call.parameters.CallSid,
					};

					this.$store.state.communicator.activeCall = activeCall;
				} else {
					this.$store.state.communicator.callMuted = false;
					this.$store.state.communicator.callRecording = false;
					this.$store.state.communicator.activeCall = null;
					this.$store.state.communicator.phoneIsValid = false;
				}
			},
		},

		computed: {
			callIsActive: function() {
				return this.$store.state.communicator.callIsActive;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
