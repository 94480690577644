var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: { "bread-crumbs": [{ to: "/home", label: "Home" }] }
        },
        [_vm._v(" Custom fields ")]
      ),
      _c(
        "div",
        { staticClass: "row-format gap-3 pb-4" },
        [
          _vm._l(_vm.pages, function(page) {
            return _c(
              "div",
              {
                key: page.value,
                class:
                  "page-link " +
                  (_vm.path === page.value ? "page-link-active" : ""),
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.setView(page.value)
                  }
                }
              },
              [_c("div", [_vm._v(_vm._s(page.label))])]
            )
          }),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto super-action",
              on: {
                click: function($event) {
                  return _vm.$refs.Editor.addField()
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
              _vm._v(" Add field")
            ],
            1
          )
        ],
        2
      ),
      _c("custom-fields-editor", {
        key: _vm.path,
        ref: "Editor",
        attrs: { type: _vm.type, "max-per-card": _vm.cardCount }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }