var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { attrs: { id: "recurring-invoice-details" } },
        [
          _c("div", { attrs: { id: "header-area" } }, [
            _c(
              "div",
              { staticClass: "header-left pl-md-3 pl-3 gap-3" },
              [
                _c("v-text-field", {
                  staticStyle: { width: "300px" },
                  attrs: {
                    dense: "",
                    outlined: "",
                    "hide-details": "",
                    placeholder:
                      "Recurring invoice for " + _vm.invoice.clientInfo.name
                  },
                  model: {
                    value: _vm.recurring.name,
                    callback: function($$v) {
                      _vm.$set(_vm.recurring, "name", $$v)
                    },
                    expression: "recurring.name"
                  }
                }),
                _c("payment-plan-events", {
                  attrs: { "payment-plan": _vm.recurring }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "header-right pr-md-6 pr-4",
                attrs: { id: "header-right" }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1 super-action",
                    attrs: { width: "96" },
                    on: {
                      click: function($event) {
                        return _vm.saveRecurringInvoice(false)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                ),
                _c(
                  "v-menu",
                  {
                    attrs: { "close-on-click": true, "nudge-bottom": 30 },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ml-1" },
                                [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "menu-activator",
                                        attrs: { icon: "" }
                                      },
                                      scope.on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { size: 20 } }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.value
                                              ? "$arrowUp"
                                              : "$moreHorizontal"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1706142669
                    )
                  },
                  [
                    _c("div", { staticClass: "more-menu" }, [
                      !_vm.invoice.invoiceTemplate
                        ? _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: { click: _vm.applyDefaultTemplate }
                            },
                            [_vm._v(" Apply invoice template ")]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: { click: _vm.refreshInvoiceTemplate }
                            },
                            [_vm._v(" Refresh invoice template ")]
                          ),
                      _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.confirmDelete()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("global.delete")) + " ")]
                      )
                    ])
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("result", _vm.recurring)
                      }
                    }
                  },
                  [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            _vm.rendererType,
            {
              key: _vm.rendererKey,
              tag: "component",
              staticStyle: {
                "max-height": "calc(100vh - 60px)",
                height: "calc(100vh - 60px)"
              },
              attrs: {
                invoice: _vm.invoice,
                recurring: true,
                account: _vm.$store.state.podAccount,
                "edit-mode": true,
                "force-render-index": _vm.forceRenderIndex,
                "attachment-service": _vm.attachmentService,
                "invoice-service": _vm.invoiceService,
                "has-expenses": false,
                "additional-tax-rates": _vm.additionalTaxRates
              },
              on: {
                "force-save": function($event) {
                  return _vm.render()
                },
                updated: function($event) {
                  return _vm.updated($event)
                },
                "pause-auto-save": function($event) {
                  return _vm.pauseAutoRender($event)
                },
                "account-info-modal": function($event) {
                  return _vm.showModal("ACCOUNT-INFO")
                },
                "client-info-modal": function($event) {
                  return _vm.showModal("CLIENT-INFO")
                },
                "invoice-number-format-modal": function($event) {
                  return _vm.showModal("INVOICE-NUMBER-FORMAT")
                },
                "payment-terms-modal": function($event) {
                  return _vm.showPaymentTermsModal()
                },
                "tax-rate-selected": function($event) {
                  return _vm.taxRateSelected($event)
                }
              }
            },
            [
              _c("recurring-event-config", {
                attrs: { recurring: _vm.recurring }
              }),
              _c("hr", {
                staticClass: "my-3",
                staticStyle: {
                  "border-top": "1px solid var(--v-gray_50-base)",
                  "border-bottom": "none"
                }
              }),
              _c(
                "div",
                { staticClass: "column-format gap-1 text-left" },
                [
                  _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      label: "Send automatically (no approval required)"
                    },
                    model: {
                      value: _vm.autoSend,
                      callback: function($$v) {
                        _vm.autoSend = $$v
                      },
                      expression: "autoSend"
                    }
                  }),
                  _vm.autoSend
                    ? _c("v-autocomplete", {
                        staticClass: "mt-2",
                        attrs: {
                          items: _vm.emailTemplates,
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": "",
                          label: "Email template (optional)",
                          "item-text": "name",
                          "item-value": "id"
                        },
                        model: {
                          value: _vm.emailTemplateId,
                          callback: function($$v) {
                            _vm.emailTemplateId = $$v
                          },
                          expression: "emailTemplateId"
                        }
                      })
                    : _vm._e(),
                  _vm.autoSend
                    ? _c("div", { staticClass: "font-12 font-gray_70" }, [
                        _vm._v(
                          ' Automatically send to all "Invoice" and "Default" contacts on the client record. '
                        )
                      ])
                    : _c("div", { staticClass: "font-12 font-gray_70" }, [
                        _vm._v(
                          " You will receive a notification after the invoice is generated letting you know you to approve and send. "
                        )
                      ]),
                  _c("hr", {
                    staticClass: "my-3",
                    staticStyle: {
                      "border-top": "1px solid var(--v-gray_50-base)",
                      "border-bottom": "none"
                    }
                  }),
                  _vm.paymentsEnabled
                    ? _c("v-checkbox", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          label: "Automatically charge on due date"
                        },
                        model: {
                          value: _vm.autoCharge,
                          callback: function($$v) {
                            _vm.autoCharge = $$v
                          },
                          expression: "autoCharge"
                        }
                      })
                    : _vm._e(),
                  _vm.autoCharge && _vm.paymentsEnabled
                    ? _c("v-select", {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: {
                              content:
                                "This setting will allow you to select a specific payment method.  If this field is left blank,  the default method will be used to process payment."
                            },
                            expression:
                              "{\n\t\t\t\t\tcontent:\n\t\t\t\t\t\t'This setting will allow you to select a specific payment method.  If this field is left blank,  the default method will be used to process payment.',\n\t\t\t\t}"
                          }
                        ],
                        staticClass: "mt-2",
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          dense: "",
                          outlined: "",
                          label: "Auto charge payment method (optional)",
                          items: _vm.paymentMethods,
                          "item-value": _vm.id,
                          "return-object": true,
                          clearable: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-transform": "capitalize"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.label.toLowerCase()) +
                                          " - " +
                                          _vm._s(item.last4)
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "text-transform": "capitalize"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.label.toLowerCase()) +
                                          " - " +
                                          _vm._s(item.last4)
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          104597948
                        ),
                        model: {
                          value: _vm.paymentMethod,
                          callback: function($$v) {
                            _vm.paymentMethod = $$v
                          },
                          expression: "paymentMethod"
                        }
                      })
                    : _vm._e(),
                  _c("hr", {
                    staticClass: "my-3",
                    staticStyle: {
                      "border-top": "1px solid var(--v-gray_50-base)",
                      "border-bottom": "none"
                    }
                  }),
                  _vm.hasAnyHours
                    ? _c("v-select", {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: {
                              content:
                                "This setting determines how billable hours are grouped.  One line for total, or broken down by day, week, month"
                            },
                            expression:
                              "{\n\t\t\t\t\tcontent:\n\t\t\t\t\t\t'This setting determines how billable hours are grouped.  One line for total, or broken down by day, week, month',\n\t\t\t\t}"
                          }
                        ],
                        attrs: {
                          items: _vm.billingBreakdowns,
                          "hide-details": "",
                          "persistent-placeholder": "",
                          "item-text": "label",
                          "item-value": "value",
                          dense: "",
                          outlined: "",
                          label: "Hourly line breakdown"
                        },
                        model: {
                          value: _vm.billingBreakdown,
                          callback: function($$v) {
                            _vm.billingBreakdown = $$v
                          },
                          expression: "billingBreakdown"
                        }
                      })
                    : _vm._e(),
                  _vm.hasAnyHours
                    ? _c("hr", {
                        staticClass: "my-3",
                        staticStyle: {
                          "border-top": "1px solid var(--v-gray_50-base)",
                          "border-bottom": "none"
                        }
                      })
                    : _vm._e(),
                  _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      label: "Include billable client hours"
                    },
                    on: { change: _vm.billableUpdated },
                    model: {
                      value: _vm.clientHours,
                      callback: function($$v) {
                        _vm.clientHours = $$v
                      },
                      expression: "clientHours"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      label: "Include all billable projects"
                    },
                    on: { change: _vm.billableUpdated },
                    model: {
                      value: _vm.allProjects,
                      callback: function($$v) {
                        _vm.allProjects = $$v
                      },
                      expression: "allProjects"
                    }
                  }),
                  !_vm.allProjects
                    ? _c(
                        "div",
                        { staticClass: "ml-7" },
                        _vm._l(_vm.projects, function(project) {
                          return _c("v-checkbox", {
                            key: project.id,
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              value: project.id,
                              label: project.name
                            },
                            on: { change: _vm.billableUpdated },
                            model: {
                              value: _vm.includedProjects,
                              callback: function($$v) {
                                _vm.includedProjects = $$v
                              },
                              expression: "includedProjects"
                            }
                          })
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "basic-modal",
            {
              ref: "accountInfoModal",
              attrs: {
                dialog: _vm.selectedModal === "ACCOUNT-INFO",
                persistent: false
              },
              on: {
                close: function($event) {
                  return _vm.showModal(null)
                }
              }
            },
            [
              _vm.invoice
                ? _c("account-info-modal", {
                    attrs: { "account-info": _vm.invoice.accountInfo },
                    on: {
                      "set-account-info": _vm.setAccountInfo,
                      close: function($event) {
                        return _vm.showModal(null)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "basic-modal",
            {
              ref: "clientInfoModal",
              attrs: {
                dialog: _vm.selectedModal === "CLIENT-INFO",
                persistent: false
              },
              on: {
                close: function($event) {
                  return _vm.showModal(null)
                }
              }
            },
            [
              _vm.invoice
                ? _c("client-info-modal", {
                    attrs: {
                      "client-info": _vm.invoice.clientInfo,
                      "client-id": _vm.invoice.clientId
                    },
                    on: {
                      close: function($event) {
                        return _vm.showModal(null)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "basic-modal",
            {
              attrs: {
                dialog: _vm.selectedModal === "INVOICE-NUMBER-FORMAT",
                persistent: false
              },
              on: {
                close: function($event) {
                  return _vm.showModal(null)
                }
              }
            },
            [
              _vm.selectedModal === "INVOICE-NUMBER-FORMAT"
                ? _c("invoice-number-format-modal", {
                    on: {
                      close: function($event) {
                        return _vm.showModal(null)
                      },
                      "set-account-info": function($event) {
                        return _vm.handleInvoiceNumberUpdate($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "basic-modal",
            {
              ref: "paymentTermsModal",
              attrs: {
                dialog: _vm.selectedModal === "PAYMENT-TERMS",
                persistent: false
              },
              on: {
                close: function($event) {
                  return _vm.showModal(null)
                }
              }
            },
            [
              _c("payment-terms-modal", {
                attrs: {
                  "payment-terms": _vm.invoice.paymentTerms,
                  "client-id": _vm.invoice.clientId
                },
                on: {
                  close: function($event) {
                    return _vm.showModal(null)
                  },
                  "delete-terms": function($event) {
                    _vm.invoice.paymentTerms = null
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }