var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        {
          staticClass: "pa-3",
          staticStyle: { "max-width": "950px", "min-width": "800px" }
        },
        [
          _c("detail-view", {
            attrs: {
              communication: _vm.communication,
              "user-emails": _vm.userEmails,
              "ticket-settings": _vm.ticketSettings,
              "email-boxes": _vm.emailBoxes,
              folder: "Inbox",
              "snooze-options": _vm.snoozeOptions,
              "in-modal": true,
              "opportunity-id": _vm.opportunityId
            },
            on: {
              back: function($event) {
                return _vm.$emit("result")
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }