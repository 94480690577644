<template>
	<div>
		<div v-if="filter">
			<v-menu
				v-model="menuOpen"
				max-height="90vh"
				max-width="250"
				:close-on-content-click="false"
				:close-on-click="true"
				content-class="filter-menu-class"
				style="overflow-y: auto"
			>

				<template v-slot:activator="{ on }">
					<v-btn v-on="on" style="min-height: 40px; background-color: var(--v-white-base)" elevation="0" outlined color="gray_30">
						<span :class="`font-14 ${!isDefaultFilter ? 'font-secondary' : 'font-gray_60'} brand-medium`" v-html="isDefaultFilter ? filterLabel : filterDescription"></span>
						<v-icon x-small class="mx-1" :color="!isDefaultFilter ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
					</v-btn>
				</template>

				<div class="column-format open-menu">
					<v-text-field
						id="search-input"
						v-model="filter.search"
						autofocus
						solo
						flat
						hide-details
						dense
						placeholder="Filter..."
						@change="updated"
						class="font-16"
						@keypress.enter.exact="menuOpen=false"
					></v-text-field>

					<filter-option
							:label="$t('client.filter.client-list')"
							:option-list="clientList"
							v-model="filter.clientList"
							@change="updated"
					></filter-option>

					<filter-option
						:label="$t('stripe.subscription.status')"
						:option-list="subscriptionStatus"
						v-model="filter.subscriptionStatus"
						@change="updated"
					></filter-option>

					<div class="mx-3 mt-3 mb-2">
						<v-btn text block color="secondary" elevation="0" class="filter-clear mt-2 mb-1" @click="clearFilter()"
						><v-icon small>$clear</v-icon> {{ $t('timetrack.filter.clear-filter') }}</v-btn
						>
						<v-btn block class="primary-action" @click="menuOpen = false">{{
							$t('global.done')
						}}</v-btn>

					</div>
				</div>
			</v-menu>
		</div>
	</div>
</template>

<script>
	import FilterOption from '@/components/FilterOption';

	export default {
		name: 'SubscriptionFilter',

		props: ['currentView', 'value','projects'],

		components: { FilterOption },

		data: function () {
			return {
				menuOpen: false,
				filter: JSON.parse(JSON.stringify(this.value)),

				subscriptionStatus: [
					{ text: 'Active', value: 'active' },
					{ text: 'Past due', value: 'past_due' },
					{ text: 'Unpaid', value: 'unpaid' },
					{ text: 'Canceled', value: 'canceled' },
					{ text: 'Incomplete', value: 'incomplete'},
					{ text: 'Incomplete expired', value: 'incomplete_expired' },
					{ text: 'Trialing', value: 'trialing' },
					{ text: 'Ended', value: 'ended' },
					{ text: 'Sent', value: 'sent' },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			clearFilter: function () {
				this.$emit('clear-filter');
			},

			updated: function(){
				this.$emit('input',this.filter);
			}
		},

		watch: {
			value: {
				deep: true,
				handler(val){
					this.filter = JSON.parse(JSON.stringify(val));
				}
			},
		},

		computed: {
			filterLabel() {
				return 'Filter...';
			},

			filterDescription() {
				let l = [];
				let f = this.filter;

				if (f.search && f.search.length > 0) {
					l.push('"' + f.search + '"');
				}

				if(f.clientList.length){
					l.push(f.clientList.length + ' clients');
				}

				if(f.subscriptionStatus.length){
					l.push(f.subscriptionStatus.length + '  status');
				}

				if(l.length){
					return l.join(" &bull; ");
				}else{
					return null;
				}
			},

			clientList: function(){
				let result = [];
				this.$store.getters.clients.forEach(c => {
					result.push({
						text: c.name,
						value: c.id
					})
				})
				return result;
			},


			isDefaultFilter() {
				if(this.filterDescription === null){
					return true;
				}else{
					return false;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
