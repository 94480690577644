import { cloneDeep } from 'lodash';

export default class ClientPalette {
	palette = [
		['#8EA3B8', '#FFA5B6', '#CE62E9', '#008556'],
		['#3BDBBE', '#51CF66', '#FEBB37', '#E86825'],
		['#FD7E14', '#FA5252', '#7950F2', '#4F72FF'],
		['#7950E3', '#D6D1CB', '#AAA5A0', '#867F77'],
	];

	getRandomColor(colorsInUse = []) {
		const allColors = [].concat.apply([], this.palette);

		let availableColors = cloneDeep(allColors);
		let index;
		for (let i = 0; i < colorsInUse.length; i++) {
			if ((index = availableColors.indexOf(colorsInUse[i]))) availableColors.splice(index, 1);
		}

		if (availableColors.length) return availableColors[Math.floor(Math.random() * availableColors.length)];

		return allColors[Math.floor(Math.random() * allColors.length)];
	}

	isLightHexColor(hexcolor) {
		if (!hexcolor) return true;
		// If a leading # is provided, remove it
		if (hexcolor.slice(0, 1) === '#') {
			hexcolor = hexcolor.slice(1);
		}
		// Convert to RGB value
		var r = parseInt(hexcolor.substr(0, 2), 16);
		var g = parseInt(hexcolor.substr(2, 2), 16);
		var b = parseInt(hexcolor.substr(4, 2), 16);

		// Get YIQ ratio
		var yiq = (r * 299 + g * 587 + b * 114) / 1000;

		// Check contrast
		return yiq >= 140;
	}
}
