import { render, staticRenderFns } from "./SettingsLink.vue?vue&type=template&id=cd09139a&scoped=true&"
import script from "./SettingsLink.vue?vue&type=script&lang=js&"
export * from "./SettingsLink.vue?vue&type=script&lang=js&"
import style0 from "./SettingsLink.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./SettingsLink.vue?vue&type=style&index=1&id=cd09139a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd09139a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2614485219/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cd09139a')) {
      api.createRecord('cd09139a', component.options)
    } else {
      api.reload('cd09139a', component.options)
    }
    module.hot.accept("./SettingsLink.vue?vue&type=template&id=cd09139a&scoped=true&", function () {
      api.rerender('cd09139a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/nav/settings/SettingsLink.vue"
export default component.exports