var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format align-center gap-1" },
    [
      _c(
        "v-icon",
        {
          staticClass: "material-symbols-rounded",
          attrs: { color: "black", size: "20" }
        },
        [_vm._v("play_circle")]
      ),
      _c("div", [_vm._v("Start")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }