import { render, staticRenderFns } from "./PillSelector.vue?vue&type=template&id=7e654868&scoped=true&"
import script from "./PillSelector.vue?vue&type=script&lang=js&"
export * from "./PillSelector.vue?vue&type=script&lang=js&"
import style0 from "./PillSelector.vue?vue&type=style&index=0&id=7e654868&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e654868",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3589467384/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e654868')) {
      api.createRecord('7e654868', component.options)
    } else {
      api.reload('7e654868', component.options)
    }
    module.hot.accept("./PillSelector.vue?vue&type=template&id=7e654868&scoped=true&", function () {
      api.rerender('7e654868', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/PillSelector.vue"
export default component.exports