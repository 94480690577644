var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "close-on-click": true, "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "" } }, on),
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { color: "black" }
                    },
                    [_vm._v(_vm._s(_vm.value ? _vm.value : "?"))]
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "pa-4",
          staticStyle: {
            "background-color": "var(--v-white-base)",
            width: "540px",
            height: "340px",
            "max-width": "540px",
            "max-height": "340px",
            overflow: "auto"
          }
        },
        [
          _c(
            "div",
            { staticClass: "row-format align-center gap-2" },
            [
              _c("v-text-field", {
                attrs: {
                  autofocus: true,
                  placeholder: "Search icon",
                  type: "text",
                  dense: ""
                },
                model: {
                  value: _vm.searchIcon,
                  callback: function($$v) {
                    _vm.searchIcon = $$v
                  },
                  expression: "searchIcon"
                }
              }),
              _c(
                "v-btn",
                {
                  attrs: { outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.setIcon("")
                    }
                  }
                },
                [_vm._v("None")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "fill-all" }, [
            _c(
              "div",
              {
                staticClass: "row-format gap-1",
                staticStyle: { "flex-wrap": "wrap" }
              },
              _vm._l(_vm.filteredSymbols, function(item) {
                return _c(
                  "v-icon",
                  {
                    key: item,
                    attrs: { size: "28", color: "black" },
                    on: {
                      click: function($event) {
                        return _vm.setIcon(item)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }