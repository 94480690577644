<template>
	<v-container fluid class="">
		<v-row>
			<v-col cols="12" align="center">
				<span>Please enter the 6 digit code that was sent to {{phone}}</span>
				<br><br>
				<code-input ref="codeInput" :disabled="disabled" :fields="6" :auto-focus="false" :required="true" id="code-input" @complete="codeComplete"></code-input>
				<span style="color:red" v-if="invalid"><br>The code you entered was not valid.</span>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import AuthService from "@/modules/auth/AuthService";

export default {


	name: "PhoneVerification",

	props: ['phone'],

	components: {CodeInput},

	data: function () {
		return {
			authService: AuthService,
			disabled: false,
			invalid: false,
		}
	},

	mounted() {
		setTimeout(this.focusFirst,500);
	},

	beforeDestroy() {
	},

	methods: {
		focusFirst: function(){
			this.$refs.codeInput.$refs.input_0[0].focus();
		},

		codeComplete: function(code){
			AuthService.verifyUserPhone(code).then(() => {
				this.$emit('verified')
				this.$emit('close');
			}).catch(() => {
				this.invalid = true;
				this.disabled = false;
			})
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">
	#code-input ::v-deep input {
		font-weight:500;
	}
</style>