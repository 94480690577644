var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-all column-format" },
    [
      _c(
        "slide",
        _vm._b(
          {
            on: {
              back: function($event) {
                return _vm.handleBack()
              },
              next: function($event) {
                return _vm.handleNext()
              }
            }
          },
          "slide",
          _vm.slide,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }