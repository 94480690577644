var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "overview" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("duration-selector", {
                staticClass: "mt-4",
                attrs: { label: "Meeting duration" },
                model: {
                  value: _vm.meeting.meetingLength,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "meetingLength", $$v)
                  },
                  expression: "meeting.meetingLength"
                }
              }),
              _c("v-select", {
                staticClass: "my-4",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Location",
                  items: _vm.locations,
                  "item-value": "value"
                },
                on: { change: _vm.handleLocationChange },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "row-format my-2" },
                          [
                            _c(
                              "v-icon",
                              {
                                class:
                                  item.value === "PhoneIn" ? "reverse" : "",
                                attrs: { small: "", color: "gray_80" }
                              },
                              [_vm._v(_vm._s(item.icon))]
                            ),
                            _c("div", { staticClass: "text-left ml-2" }, [
                              _c("div", { staticClass: "font-16" }, [
                                _vm._v(_vm._s(item.text))
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "row-format my-1" },
                          [
                            _c(
                              "v-icon",
                              {
                                class:
                                  item.value === "PhoneIn" ? "reverse" : "",
                                attrs: { color: "gray_80" }
                              },
                              [_vm._v(_vm._s(item.icon))]
                            ),
                            _c("div", { staticClass: "text-left ml-3" }, [
                              _c("div", { staticClass: "font-16" }, [
                                _vm._v(_vm._s(item.text))
                              ]),
                              _c(
                                "div",
                                { staticClass: "font-12 font-gray_70" },
                                [_vm._v(_vm._s(item.description))]
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.location,
                  callback: function($$v) {
                    _vm.location = $$v
                  },
                  expression: "location"
                }
              }),
              _vm.location
                ? _c("div", [
                    _vm.location.startsWith("Google")
                      ? _c(
                          "div",
                          {
                            staticClass: "ml-3 text-left font-14 font-primary"
                          },
                          [
                            _vm.googleUser
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.googleUser.googleUser.name) +
                                      "'s Google Calendar (" +
                                      _vm._s(_vm.googleUser.googleUser.email) +
                                      ") "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm.location.startsWith("Microsoft")
                      ? _c(
                          "div",
                          {
                            staticClass: "ml-3 text-left font-14 font-primary"
                          },
                          [
                            _vm.microsoftUser
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.microsoftUser.microsoftUser.name
                                      ) +
                                      "'s Microsoft Calendar (" +
                                      _vm._s(
                                        _vm.microsoftUser.microsoftUser.mail
                                      ) +
                                      ") "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm.location.startsWith("Sessions")
                      ? _c(
                          "div",
                          {
                            staticClass: "ml-3 text-left font-14 font-primary"
                          },
                          [
                            _vm.sessionsUser
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.sessionsUser.description) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm.location.startsWith("Zoom")
                      ? _c(
                          "div",
                          {
                            staticClass: "ml-3 text-left font-14 font-primary"
                          },
                          [
                            _vm.zoomUser
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.zoomUser.zoomUser.first_name) +
                                      " " +
                                      _vm._s(_vm.zoomUser.zoomUser.last_name) +
                                      "'s Zoom connection (" +
                                      _vm._s(_vm.zoomUser.zoomUser.email) +
                                      ") "
                                  )
                                ])
                              : _c(
                                  "div",
                                  {
                                    staticClass: "row-format align-center",
                                    staticStyle: {
                                      color: "var(--v-warning-base)"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticStyle: { "margin-left": "-10px" },
                                        attrs: { color: "warning" }
                                      },
                                      [_vm._v("$alert")]
                                    ),
                                    _c("div", { staticClass: "ml-2" }, [
                                      _vm._v(
                                        " The Zoom account used in this scheduler is current disconnected. Please reconnect or choose a different meeting location. "
                                      )
                                    ])
                                  ],
                                  1
                                )
                          ]
                        )
                      : _vm.location === "PhoneOut"
                      ? _c(
                          "div",
                          {
                            staticClass: "ml-3 text-left font-14 font-primary"
                          },
                          [
                            _vm._v(
                              " We will ask the invitee for their phone number. "
                            )
                          ]
                        )
                      : _vm.location === "InPerson"
                      ? _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "persistent-placeholder": "",
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                label: "Address of meeting"
                              },
                              model: {
                                value: _vm.meeting.location.address,
                                callback: function($$v) {
                                  _vm.$set(_vm.meeting.location, "address", $$v)
                                },
                                expression: "meeting.location.address"
                              }
                            })
                          ],
                          1
                        )
                      : _vm.location === "PhoneIn"
                      ? _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "persistent-placeholder": "",
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                type: "phone",
                                label: "Phone number for invitee to call"
                              },
                              model: {
                                value: _vm.meeting.location.phone,
                                callback: function($$v) {
                                  _vm.$set(_vm.meeting.location, "phone", $$v)
                                },
                                expression: "meeting.location.phone"
                              }
                            })
                          ],
                          1
                        )
                      : _vm.location === "Custom"
                      ? _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c("v-textarea", {
                              attrs: {
                                "persistent-placeholder": "",
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                rows: "1",
                                "auto-grow": true,
                                label: "Custom meeting instructions"
                              },
                              model: {
                                value: _vm.meeting.location.custom,
                                callback: function($$v) {
                                  _vm.$set(_vm.meeting.location, "custom", $$v)
                                },
                                expression: "meeting.location.custom"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("v-textarea", {
                staticClass: "mt-4",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  rows: "6",
                  "auto-grow": true,
                  label: "Brief description of meeting"
                },
                model: {
                  value: _vm.meeting.description,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "description", $$v)
                  },
                  expression: "meeting.description"
                }
              }),
              _vm.mappedCalendars.length &&
              _vm.location &&
              !_vm.location.startsWith("Google") &&
              !_vm.location.startsWith("Microsoft")
                ? _c("v-select", {
                    staticClass: "mt-4",
                    attrs: {
                      "persistent-placeholder": "",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label: "Add meeting to connected calendar",
                      items: _vm.mappedCalendars
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                { staticClass: "row-format align-center" },
                                [
                                  item.type === "Google"
                                    ? _c("v-icon", { staticClass: "mb-1" }, [
                                        _vm._v("$googleCalendar")
                                      ])
                                    : _vm._e(),
                                  item.type === "Apple"
                                    ? _c("v-icon", { staticClass: "mb-1" }, [
                                        _vm._v("$apple")
                                      ])
                                    : _vm._e(),
                                  item.type === "CalDav"
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "secondary" } },
                                        [_vm._v("event")]
                                      )
                                    : _vm._e(),
                                  item.type === "Microsoft"
                                    ? _c("v-icon", { staticClass: "mb-1" }, [
                                        _vm._v("$microsoft")
                                      ])
                                    : _vm._e(),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v(_vm._s(item.text))
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                { staticClass: "row-format align-center" },
                                [
                                  item.type === "Google"
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("$googleCalendar")
                                      ])
                                    : _vm._e(),
                                  item.type === "Apple"
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("$apple")
                                      ])
                                    : _vm._e(),
                                  item.type === "CalDav"
                                    ? _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "secondary"
                                          }
                                        },
                                        [_vm._v("event")]
                                      )
                                    : _vm._e(),
                                  item.type === "Microsoft"
                                    ? _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("$microsoft")
                                      ])
                                    : _vm._e(),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v(_vm._s(item.text))
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2156913587
                    ),
                    model: {
                      value: _vm.addToCalendarUser,
                      callback: function($$v) {
                        _vm.addToCalendarUser = $$v
                      },
                      expression: "addToCalendarUser"
                    }
                  })
                : _vm._e(),
              _c("multi-email-input", {
                staticClass: "mt-4",
                attrs: {
                  "known-emails": _vm.userEmails,
                  label: "Additional attendees"
                },
                model: {
                  value: _vm.meeting.additionalAttendees,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "additionalAttendees", $$v)
                  },
                  expression: "meeting.additionalAttendees"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-4",
                attrs: {
                  readonly: "",
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Direct meeting link",
                  value: _vm.fullMeetingLink
                }
              }),
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Scheduler owner",
                  items: _vm.usersForAccount,
                  "item-value": "userId"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.userId
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(item.firstName) +
                                  " " +
                                  _vm._s(item.lastName) +
                                  " "
                              ),
                              _c("span", { staticClass: "font-gray_70" }, [
                                _vm._v(" (" + _vm._s(item.email) + ")")
                              ])
                            ])
                          : _c("div", [_vm._v("-- No owner --")])
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.userId
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(item.firstName) +
                                  " " +
                                  _vm._s(item.lastName) +
                                  " "
                              ),
                              _c("span", { staticClass: "font-gray_70" }, [
                                _vm._v(" (" + _vm._s(item.email) + ")")
                              ])
                            ])
                          : _c("div", [_vm._v("-- No owner --")])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.meeting.ownerUserId,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "ownerUserId", $$v)
                  },
                  expression: "meeting.ownerUserId"
                }
              }),
              _c("v-select", {
                staticClass: "mt-4",
                attrs: {
                  disabled: _vm.pipelineStages.length === 0,
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Add meeting to pipeline",
                  items: _vm.pipelineStages,
                  "item-value": "id",
                  "item-text": "label",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", {
                          style:
                            "width: 20px; height: 20px; border-radius: 4px; background-color: " +
                            item.hexColor
                        }),
                        _c("div", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(item.label))
                        ])
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", {
                          style:
                            "width: 20px; height: 20px; border-radius: 4px; background-color: " +
                            item.hexColor
                        }),
                        _c("div", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(item.label))
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.meeting.pipelineStageId,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "pipelineStageId", $$v)
                  },
                  expression: "meeting.pipelineStageId"
                }
              }),
              _c("v-switch", {
                attrs: { label: "Link active", "hide-details": "" },
                model: {
                  value: _vm.meeting.active,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "active", $$v)
                  },
                  expression: "meeting.active"
                }
              }),
              _c("v-switch", {
                attrs: { label: "Show in Client Portal", "hide-details": "" },
                model: {
                  value: _vm.meeting.showInPortal,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "showInPortal", $$v)
                  },
                  expression: "meeting.showInPortal"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }