import HttpClient from '@/services/HttpClient';

export default class PaymentPlanService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getPaymentPlan(id) {
		return this.httpClient
			.get(`/paymentPlans/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getPaymentPlans(clientId = null) {
		return this.httpClient
			.get('/paymentPlans', {
				params: {
					clientId: clientId
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createPaymentPlan(paymentPlan) {
		return this.httpClient
			.post(`/paymentPlans`, paymentPlan)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updatePaymentPlan(id, paymentPlan) {
		return this.httpClient
			.put(`/paymentPlans/${id}`, paymentPlan)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deletePaymentPlan(id) {
		return this.httpClient
			.delete(`/paymentPlans/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	returnToDraft(id) {
		return this.httpClient
			.post(`/paymentPlans/${id}/returnToDraft`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	activate(id) {
		return this.httpClient
			.post(`/paymentPlans/${id}/activate`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	runMilestone(id, scheduledPaymentId) {
		return this.httpClient
			.post(`/paymentPlans/${id}/runMilestone?scheduledPaymentId=${scheduledPaymentId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
