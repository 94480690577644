<template>
	<div id="invoice-number-format-modal" v-if="account">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">
				{{ $t('invoice.invoice-number-format-modal.heading') }}
			</div>

			<div class="brand-medium font-12 gray_90--text mt-1 text-left mt-1">
				{{ $t('invoice.invoice-number-format-modal.formats') }}
			</div>

			<v-select
				class="h-outline"
				persistent-placeholder
				hide-details
				:label="$t('invoice.invoice-number-format-modal.format-label')"
				:items="formats"
				:key="formatSelectKey"
				item-text="label"
				item-value="value"
				v-model="account.accountPreferences.invoiceNumberFormat"
				:menu-props="{ maxHeight: 600 }"
				@change="handleFormatSelectChange()"
			>
				<template v-slot:item="{ item }">
					<span class="d-flex justify-start" style="width: 100%;">
						{{ item.label }}
					</span>
				</template>
			</v-select>

			<v-btn
				@click="openCustomFormatModal"
				class="secondary-action"
				text
				v-if="!isPresetFormat(account.accountPreferences.invoiceNumberFormat)"
			>
				{{ account.accountPreferences.invoiceNumberFormat }}
				<v-icon right small>$edit</v-icon>
			</v-btn>

			<div class="brand-medium font-12 gray_90--text mt-1 text-left mt-1">
				{{ $t('invoice.invoice-number-format-modal.settings') }}
			</div>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				type="number"
				v-model="account.accountPreferences.invoiceNumberSeed"
				:label="$t('invoice.invoice-number-format-modal.seed-label')"
				@keyup.enter="handleSave"
			></v-text-field>

			<v-select
				class="h-outline"
				persistent-placeholder
				hide-details
				:label="$t('invoice.invoice-number-format-modal.per-client-label')"
				:items="perClientOptions"
				item-text="label"
				item-value="value"
				v-model="account.accountPreferences.invoiceNumberPerClient"
			>
			</v-select>
			<v-select
				class="h-outline"
				persistent-placeholder
				hide-details
				:label="$t('invoice.invoice-number-format-modal.per-year-label')"
				:items="perClientOptions"
				item-text="label"
				item-value="value"
				v-model="account.accountPreferences.invoiceNumberPerYear"
			>
			</v-select>
			<v-select
				class="h-outline"
				persistent-placeholder
				hide-details
				label="Fiscal year start"
				:items="months"
				item-text="text"
				item-value="value"
				v-model="account.accountPreferences.fiscalYearStartMonth"
			>
			</v-select>

			<div>
				<v-btn color="error" small @click="confirmResetInvoiceSequence"
					><v-icon>warning</v-icon>Reset invoice sequence</v-btn
				>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" style="width:160px;" @click="$emit('close')">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="super-action ml-1" style="width:160px;" @click="handleSave">{{ $t('global.save') }}</v-btn>
		</div>
		<basic-modal :dialog="customFormatDialog" @close="customFormatDialog = false" :persistent="false">
			<invoice-number-custom-format
				v-if="customFormatDialog"
				:invoice-number-format="account.accountPreferences.invoiceNumberFormat"
				@close="handleCustomFormatSave()"
				@save="handleCustomFormatSave($event)"
			></invoice-number-custom-format>
		</basic-modal>
	</div>
</template>

<script>
	import AccountService from '@/modules/account/AccountService';
	import jsonPatch from 'fast-json-patch';
	import InvoiceNumberCustomFormat from '@/modules/invoices/InvoiceNumberCustomFormat';
	import BasicModal from '@/components/BasicModal';
	import ConfirmModal from '@/components/ConfirmModal';
	import InvoiceService from '@/modules/invoices/InvoiceService';

	export default {
		name: 'InvoiceNumberFormatModal',

		// props: { accountInfo: { type: Object, required: true } },

		components: { InvoiceNumberCustomFormat, BasicModal },

		data: function() {
			return {
				accountService: new AccountService(),
				account: null,
				invoiceNumberFormatPrevious: null,
				accountObserver: null,
				formats: [
					{ label: '1001 (Unique number only)', value: '{{Num}}' },
					{ label: 'YY-1001 (2-digit year and number)', value: '{{YY}}-{{Num}}' },
					{ label: 'YYYY-1001 (4-digit year and number)', value: '{{YYYY}}-{{Num}}' },
					{ label: 'YY-Mon-1001 (2-digit year, month, and number)', value: '{{YY}}-{{Mon}}-{{Num}}' },
					{ label: 'YYYY-Mon-1001 (2-digit year, month, and number)', value: '{{YYYY}}-{{Mon}}-{{Num}}' },
					{ label: 'SC-1001 (Client initials and number)', value: '{{CI}}-{{Num}}' },
					{ label: 'SC-YY-1001 (Client initials, 2-digit year, and number', value: '{{CI}}-{{YY}}-{{Num}}' },
					{ label: 'SC-YYYY-1001 (Client initials, 4-digit year, and number', value: '{{CI}}-{{YYYY}}-{{Num}}' },
					{ label: 'YY-SC-1001 (2-digit year, client initials, and number', value: '{{YY}}-{{CI}}-{{Num}}' },
					{ label: 'YYYY-SC-1001 (4-digit year, client initials, and number', value: '{{YYYY}}-{{CI}}-{{Num}}' },
					{ label: 'Create custom format', value: '', isCustom: true },
				],
				months: [
					{ value: 1, text: 'January' },
					{ value: 2, text: 'February' },
					{ value: 3, text: 'March' },
					{ value: 4, text: 'April' },
					{ value: 5, text: 'May' },
					{ value: 6, text: 'June' },
					{ value: 7, text: 'July' },
					{ value: 8, text: 'August' },
					{ value: 9, text: 'September' },
					{ value: 10, text: 'October' },
					{ value: 11, text: 'November' },
					{ value: 12, text: 'December' },
				],
				perClientOptions: [
					{ label: this.$t('global.yes'), value: true },
					{ label: this.$t('global.no'), value: false },
				],
				customFormatDialog: false,
				formatSelectKey: 0,
			};
		},

		mounted() {
			this.getAccount();
			// this.accountInfo = this.value;
			this.$store.state.eventBus.$on('account-changed', this.getAccount);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getAccount);
		},

		methods: {
			getAccount: function() {
				this.accountService
					.getAccount()
					.then((res) => {
						// console.log(res.data);
						this.setAccount(res.data);
						if (!this.isPresetFormat(this.account.accountPreferences.invoiceNumberFormat)) {
							this.setCustomFormat(this.account.accountPreferences.invoiceNumberFormat);
						}
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},
			setAccount: function(account) {
				this.account = account;
				this.invoiceNumberFormatPrevious = this.account.accountPreferences.invoiceNumberFormat;
				// console.log('setAccount', this.account);
				this.accountObserver = jsonPatch.observe(this.account);
			},

			handleSave() {
				this.saveAccount(true);
				// this.$emit('close');
			},

			confirmResetInvoiceSequence: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText:
						'Are you sure you want to reset your invoice sequence?  This could result in duplicate invoice numbers.<br><br>If you continue, Your next invoice will begin at the start value listed on this page.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						new InvoiceService()
							.resetInvoiceSequence()
							.then(() => {
								this.$store.commit('success', 'Invoice sequence reset');
							})
							.catch((err) => this.$store.commit('error', err.response.data.message));
					}
				});
			},

			saveAccount: function(andClose = false) {
				this.accountService
					.updateAccount(this.account)
					.then((res) => {
						this.$emit('set-account-info', res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => {
						if (andClose) {
							this.$emit('close');
						}
					});

				this.notifySuccess();
			},

			notifySuccess() {
				this.$store.commit('success', 'Account settings saved.');
			},

			handleFormatSelectChange() {
				if (!this.isPresetFormat(this.account.accountPreferences.invoiceNumberFormat)) {
					this.openCustomFormatModal();
				}
			},

			openCustomFormatModal() {
				this.customFormatDialog = true;
				// this.$store.state.globalModalController
				// 	.openModal(InvoiceNumberCustomFormat, { invoiceNumberFormat: this.invoiceNumberFormat }, true, false)
				// 	.then((res) => {
				// 		if (!res) return;
				// 		if (res.action && res.action === 'SAVE') {
				// 			this.invoiceNumberFormat = res.value;
				// 		}
				// 	});
			},
			handleCustomFormatSave(format) {
				console.log(format);
				if (!format) {
					this.customFormatDialog = false;
					this.account.accountPreferences.invoiceNumberFormat = this.invoiceNumberFormatPrevious;
					console.log('Not even the fee senator');
					console.log(this.invoiceNumberFormatPrevious);
					// this.account.accountPreferences.invoiceNumberFormat = this.invoiceNumberFormatPrevious;
					return;
					// format = this.invoiceNumberFormatPrevious;
				}
				if (this.isPresetFormat(format)) {
					this.setCustomFormat('');
				} else {
					this.setCustomFormat(format);
				}
				// this.$nextTick(() => {
				this.account.accountPreferences.invoiceNumberFormat = format;
				// });
				this.customFormatDialog = false;
				this.saveAccount();
			},
			isPresetFormat(format) {
				let index = this.formats.findIndex((v) => v.value === format);
				// Last item in formats array has the custom value so we only want to match against everything else
				return index > -1 && index < this.formats.length - 1;
			},
			setCustomFormat(format) {
				// console.log('hello setCustomFormat');
				// console.table(this.formats);
				let customIndex = this.formats.length - 1;
				this.formats[customIndex].value = format;
				if (format === '') {
					this.formats[customIndex].label = 'Create custom format';
				} else {
					this.formats[customIndex].label = 'Custom format';
				}
				// console.table(this.formats);
				this.formatSelectKey++;
			},
		},
		watch: {},

		computed: {},
	};
</script>

<style lang="scss">
	#invoice-number-format-modal {
		width: 566px;
	}
</style>
