<template>
	<v-dialog v-model="searchOpen" max-width="800" content-class="universal-top-dialog">
		<div class="column-format universal-search" id="universal-search">
			<div class="row-format align-center">
				<v-icon color="primary">search</v-icon>
				<v-text-field
					hide-details
					dense
					flat
					solo
					:placeholder="`Search... ${isMac ? 'Cmd+K' : 'Ctrl+k'} for quick access`"
					autofocus
					v-model="query"
					@input="onSearchChange"
					class="gray-background"
				></v-text-field>
			</div>
			<div
				v-if="results.length"
				class="column-format gap-2 pr-2 show-scrollbar"
				style="max-height: calc(100vh - 300px); overflow-y: auto"
			>
				<div v-for="(result, index) in results" :key="result.searchObject.id">
					<h3 v-if="result.isFirstOfType" style="text-transform: capitalize;" class="text-left mt-3 mb-1 brand-medium">
						{{ makePlural(result.type).toLowerCase() }}
					</h3>
					<result
						:id="`result-${index}`"
						:result="result"
						:selected="selectedIndex === index"
						@click="resultSelected(result)"
					></result>
				</div>
			</div>
		</div>
	</v-dialog>
</template>

<script>
	import SearchService from '@/modules/search/SearcService';
	import { debounce } from 'lodash';
	import Result from '@/modules/search/Result';
	import NavConfig from '@/views/nav/NavConfig.json';
	import { v4 as uuid4 } from 'uuid';
	import ContactEdit from '@/modules/clients/contacts/ContactEdit';
	import AgreementBuilder from "@/modules/agreements/AgreementBuilder";
	import OpportunityDetail from "@/modules/pipeline/opportunity/OpportunityDetail";
	import InvoiceDetails from "@/modules/invoices/InvoiceDetails";
	import TicketDetail from "@/modules/communicator/inbox/tickets/TicketDetail";
	import FormSubmissionDetail from "@/modules/discovery/FormSubmissionDetail";
	import MeetingDetail from "@/modules/meetings/MeetingDetail";
	import DeliverableDetail from "@/modules/projects/deliverable/DeliverableDetail";
	import QuickLinksMixin from "@/modules/home/focus/QuickLinksMixin";

	export default {
		name: 'UniversalSearch',

		props: [],

		mixins: [QuickLinksMixin],

		components: { Result },

		data: function() {
			return {
				NavConfig: NavConfig,
				searchService: new SearchService(),
				searchOpen: false,
				query: null,
				isMac: /Mac|iPod|iPhone|iPad/.test(navigator.userAgent),
				debouncer: debounce(this.doSearch, 250),
				searchResults: [],
				features: [],
				selectedIndex: 0,
				showAutocomplete: false,
				commands: [
					{ name: '/new-client', description: 'Create a new client record' },
					{ name: '/new-project', description: 'Create a new project' },
					{ name: '/new-invoice', description: 'Create a new invoice' },
					{ name: '/new-agreement', description: 'Create a new agreement' },
					{ name: '/new-ticket', description: 'Create a new ticket' },
					{ name: '/new-task', description: 'Create a new task' },
					{ name: '/new-time-entry', description: 'Add a time entry' },
					{ name: '/start-timer', description: 'Start a timer' },
					{ name: '/stop-timer', description: 'Stop a timer' },
					{ name: '/send-email', description: 'Send email' },
				],
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('universal-search', this.openSearch);
			this.$store.state.eventBus.$on('account-changed', this.verify);
			window.addEventListener('keydown', this.keyHandler);
			this.verify();
			this.parseNavConfig();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('universal-search', this.openSearch);
			this.$store.state.eventBus.$off('account-changed', this.verify);
			window.removeEventListener('keydown', this.keyHandler);
		},

		methods: {
			parseNavConfig: function() {
				Object.keys(this.NavConfig).forEach((key) => {
					let nav = this.NavConfig[key];

					if (nav.title.uri && !nav.title.dontIndex) {
						this.features.push({
							label: nav.title.label,
							search: nav.title.label,
							path: nav.title.uri,
							type: 'Feature',
							icon: nav.title.icon,
							searchObject: {
								id: uuid4(),
							},
						});
					}

					for (let i = 0; i < nav.tabs.length; i++) {
						let tab = nav.tabs[i];
						if (tab.dontIndex) {
							continue;
						}
						this.features.push({
							label: nav.title.label + ' >> ' + tab.label,
							search: nav.title.label + ' ' + tab.label,
							path: tab.uri,
							type: 'Feature',
							icon: tab.icon,
							searchObject: {
								id: uuid4(),
							},
						});
					}
				});
			},

			verify: function() {
				this.searchService.verify();
			},

			doSearch: function() {
				this.selectedIndex = 0;
				if (this.query && !this.query.startsWith('/')) {
					this.searchService.search(this.query).then((res) => {
						this.searchResults.splice(0);
						this.searchResults.push(...res.data);
					});
				} else {
					this.searchResults.splice(0);
				}
			},

			onSearchChange() {
				this.debouncer();
			},

			keyHandler(event) {
				if ((this.isMac && event.metaKey && event.key === 'k') || (!this.isMac && event.ctrlKey && event.key === 'k')) {
					if(event.target.id && event.target.id.includes('tiny-vue')){
						return;
					}
					event.preventDefault();
					this.openSearch();
				} else if (this.searchOpen && event.key === 'ArrowUp') {
					event.preventDefault();
					this.moveUp();
				} else if (this.searchOpen && event.key === 'ArrowDown') {
					event.preventDefault();
					this.moveDown();
				} else if (this.searchOpen && event.key === 'Enter') {
					if (this.results.length) {
						let item = this.results[this.selectedIndex];
						this.resultSelected(item);
					}
				}
			},

			resultSelected: function(result) {
				this.$store.state.globalModalController.forceCloseAll();

				if (result.type !== 'Feature' && result.type !== 'Command') {
					this.$track.record('search route', {type: 'Data', value: result.type});
				}

				if (result.type === 'Feature') {
					this.$track.record('search route', {type: 'Feature', value: result.label});
					this.routeWithDelay(result.path);
				}else if(result.type === 'Command'){
					this.$track.record('search route', {type: 'Command', value: result.name});
					this.executeCommand(result);
				} else if (result.type === 'CLIENT') {
					this.routeWithDelay(`/client/${result.searchObject.id}`);
				} else if (result.type === 'PROJECT') {
					this.routeWithDelay(`/project/${result.searchObject.id}`);
				} else if (result.type === 'CONTACT') {
					this.openContact(result.searchObject);
				} else if(result.type === 'AGREEMENT'){
					this.openAgreement(result.searchObject);
				} else if(result.type === 'OPPORTUNITY'){
					this.openOpportunity(result.searchObject);
				}else if(result.type === 'INVOICE'){
					this.openInvoice(result.searchObject);
				}else if(result.type === 'TICKET'){
					this.openTicket(result.searchObject);
				}else if(result.type === 'FORM'){
					this.openForm(result.searchObject);
				}else if(result.type === 'MEETING'){
					this.openMeeting(result.searchObject);
				}else if(result.type === 'TASK'){
					this.openTask(result.searchObject);
				}
			},

			executeCommand: function(command){
				if(command.name === '/start-timer'){
					this.$store.state.eventBus.$emit('start-timer', {hideMenu: true});
				}else if(command.name === '/stop-timer'){
					this.$store.state.eventBus.$emit('stop-timer', {});
				}else if(command.name === '/new-client'){
					this.createClient();
				}else if(command.name === '/new-project'){
					this.createProject();
				}else if(command.name === '/new-invoice'){
					this.createInvoice();
				}else if(command.name === '/new-agreement'){
					this.createProposal();
				}else if(command.name === '/new-task'){
					this.createDeliverable();
				}else if(command.name === '/add-time-entry'){
					this.createTimeEntry();
				}else if(command.name === '/new-ticket'){
					this.createTicket();
				}else if(command.name === '/send-email'){
					this.sendEmail();
				}
				this.searchOpen = false;
				this.query = null;
			},

			openContact: function(searchObject) {
				setTimeout(() => {
					let binding = { id: searchObject.id };
					this.$store.state.globalModalController.openModal(ContactEdit, binding);
					this.searchOpen = false;
				}, 250);
			},

			openAgreement: function(searchObject){
				setTimeout(() => {
					let binding = { id: searchObject.id };
					this.$store.state.globalModalController.openModal(AgreementBuilder, binding, false, true);
					this.searchOpen = false;
				}, 250);
			},

			openOpportunity: function(searchObject){
				setTimeout(() => {
					let binding = { id: searchObject.id };
					this.$store.state.globalModalController.openModal(OpportunityDetail, binding);
					this.searchOpen = false;
				}, 250);
			},

			openInvoice: function(searchObject){
				setTimeout(() => {
					let binding = {
						id: searchObject.id,
						clientId: searchObject.clientId
					};
					this.$store.state.globalModalController.openModal(InvoiceDetails, binding, false, true);
					this.searchOpen = false;
				}, 250);
			},

			openTicket: function(searchObject){
				setTimeout(() => {
					let binding = { id: searchObject.id };
					this.$store.state.globalModalController.openModal(TicketDetail, binding);
					this.searchOpen = false;
				}, 250);
			},

			openForm: function(searchObject){
				setTimeout(() => {
					let binding = { id: searchObject.id };
					this.$store.state.globalModalController.openModal(FormSubmissionDetail, binding);
					this.searchOpen = false;
				}, 250);
			},

			openMeeting: function(searchObject){
				setTimeout(() => {
					let binding = { id: searchObject.id };
					this.$store.state.globalModalController.openModal(MeetingDetail, binding);
					this.searchOpen = false;
				}, 250);
			},

			openTask: function(searchObject){
				setTimeout(() => {
					let binding = { deliverableId: searchObject.id };
					this.$store.state.globalModalController.openModal(DeliverableDetail, binding);
					this.searchOpen = false;
				}, 250);
			},

			routeWithDelay: function(path) {
				setTimeout(() => {
					this.$router.push(path);
					this.searchOpen = false;
				}, 250);
			},

			moveUp: function() {
				if (this.selectedIndex > 0) {
					this.selectedIndex--;
					this.scrollSelectedIntoView();
				}
			},

			moveDown: function() {
				if (this.selectedIndex < this.results.length - 1) {
					this.selectedIndex++;
					this.scrollSelectedIntoView();
				}
			},

			scrollSelectedIntoView: function() {
				this.$nextTick(() => {
					let div = document.getElementById('result-' + this.selectedIndex);
					if (div) {
						this.scrollIfNotInView(div);
					}
				});
			},

			scrollIfNotInView(element) {
				const rect = element.getBoundingClientRect();
				const containerRect = document.getElementById('universal-search');

				const isVisible =
					rect.top >= containerRect.top &&
					rect.left >= containerRect.left &&
					rect.bottom <= containerRect.bottom &&
					rect.right <= containerRect.right;

				if (!isVisible) {
					element.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
			},

			openSearch: function() {
				this.searchOpen = true;
				this.$onBoarding.track('universal_search');
				this.$track.record('universal search');
			},

			makePlural(type) {
				if (type === 'OPPORTUNITY') {
					return 'OPPORTUNITIES';
				} else {
					return type + 'S';
				}
			},
		},

		computed: {
			filteredCommands() {
				if (!this.query || !this.query.startsWith('/')) {
					return [];
				}

				let result = this.commands.filter((command) => command.name.toLowerCase().startsWith(this.query.toLowerCase()));

				result.forEach((r) => {
					r.searchObject = {
						id: uuid4()
					}
					r.type = 'Command';
				});

				return result;
			},

			typeRank: function() {
				let types = new Map();
				types.set('CLIENT', 100);
				types.set('CONTACT', 50);
				types.set('PROJECT', 45);
				types.set('AGREEMENT', 45);
				types.set('OPPORTUNITY', 45);
				types.set('INVOICE', 40);
				types.set('TICKET', 40);
				types.set('FORM', 30);
				types.set('MEETING', 30);
				types.set('TASK', 1);
				return types;
			},

			results: function() {
				if (this.filteredCommands.length) {
					return this.filteredCommands;
				}

				let results = [...this.matchedFeatures];
				let search = [...this.searchResults];

				search.forEach((s) => {
					s.type = s.searchObject.type;
				});

				search = search.sort((a, b) => this.typeRank.get(b.type) - this.typeRank.get(a.type));
				results.push(...search);

				return results.map((item, index, array) => {
					if (index === 0 || item.type !== array[index - 1].type) {
						return { ...item, isFirstOfType: true };
					}
					return { ...item, isFirstOfType: false };
				});
			},

			matchedFeatures: function() {
				if (this.query) {
					return this.features.filter((f) => f.search.toLowerCase().includes(this.query.toLowerCase()));
				} else {
					return [];
				}
			},
		},
	};
</script>

<style lang="scss">
	.autocomplete-list {
		position: absolute;
		z-index: 10;
		width: 100%;
	}

	.universal-top-dialog {
		background-color: var(--v-white-base);
		position: absolute !important;
		top: 0 !important;
		margin: 0;
	}

	.universal-search {
		background-color: var(--v-white-base);
		padding: 12px;
	}
</style>
