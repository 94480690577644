<template>
	<div>
		<v-autocomplete
			:items="customFonts"
			v-model="selectedFont"
			outlined
			hide-details
			dense
			persistent-placeholder
			:label="label ? label : 'Custom fonts'"
			v-if="customFonts.length"
			@change="$emit('font-changed',selectedFont)"
		></v-autocomplete>
		<font-picker
			v-else
			class="font-picker"
			:api-key="googleFontKey"
			:active-font="font"
			:options="fontOptions"
			@change="handleFontChange"
		></font-picker>
	</div>
</template>

<script>
	import FontPicker from 'font-picker-vue';

	export default {
		name: 'FontSelector',

		props: ['font', 'label'],

		components: { FontPicker },

		data: function () {
			return {
				selectedFont: this.font,

				googleFontKey: process.env.VUE_APP_GOOGLE_FONT_KEY,

				fontOptions: {
					limit: 250,
					categories: ['sans-serif','serif','handwriting','display'],
				},
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleFontChange: function (font) {
				this.$emit('font-changed', font.family);
			},
		},

		computed: {
			customFonts: function () {
				let result = [];
				if (this.$store.state.accountFonts.length) {
					result.push('Inter');
					this.$store.state.accountFonts.forEach((f) => result.push(f.fontName));
				}
				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
