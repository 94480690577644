<template>
	<div>
		<v-menu
			ref="menu"
			v-model="dateMenu"
			:close-on-content-click="false"
			transition="scale-transition"
			offset-y
			min-width="290px"
		>
			<template v-slot:activator="{ on }">
				<div :class="`row-format centered scope-button`" v-on="on" style="min-width: 70px; background-color: var(--v-white-base)">
					<div class="">{{ dateFormatted }}</div>
				</div>
			</template>

			<v-date-picker
				:first-day-of-week="$store.getters.firstDayOfWeek"
				v-model="localDate"
				no-title
				scrollable
				@change="dateUpdated"
			>
				<template slot="default">
					<div style="width: 100%" class="row-format centered">
						<div
							class="input-wrapper font-14 font-primary py-1 px-2 pointer"
							@click="
								localDate = null;
								dateUpdated();
							"
						>
							{{ $t('global.clear') }}
						</div>
					</div>
				</template>
			</v-date-picker>
		</v-menu>
	</div>
</template>

<script>
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'ScopeDateSelector',

		props: ['date', 'label'],

		components: {},

		data: function () {
			return {
				localDate: this.date,
				dateMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			dateUpdated: function () {
				this.dateMenu = false;
				this.$emit('change', this.localDate);
			},
		},

		computed: {
			dateFormatted: function () {
				if (this.localDate) {
					return DateTime.fromISO(this.localDate).toLocaleString();
				} else {
					return ' -- ';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.scope-button {
		background-color: var(--v-white-base);
		color: var(--v-gray_60-base);
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		padding: 4px 8px;
		width: fit-content;
		font-size: 12px;
		font-weight: 500;
		cursor: pointer;
		white-space: nowrap;
		height: 40px;

		&:hover {
			background-color: var(--v-gray_20-base);
		}
	}

	.active {
		background-color: var(--v-blue_5-base);
		color: var(--v-blue_70-base);
		border: 1px solid var(--v-blue_5-base);
	}
</style>
