<template>
	<div class="column-format gap-2 py-3 text-left">
		<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.padding"
				label="Padding"
				@input="updated"
		></v-text-field>
		<div class="row-format gap-1">
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					min="0"
					max="20"
					v-model="element.pt"
					label="Margin top"
					@input="updated"
			></v-text-field>
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					min="0"
					max="20"
					v-model="element.pb"
					label="Margin bottom"
					@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					min="0"
					max="20"
					v-model="element.pl"
					label="Margin left"
					@input="updated"
			></v-text-field>
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					min="0"
					max="20"
					v-model="element.pr"
					label="Margin right"
					@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					v-model="element.borderWidth"
					label="Border width"
					@input="updated"
			></v-text-field>
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					v-model="element.borderRadius"
					label="Border radius"
					@input="updated"
			></v-text-field>
		</div>
		<div class="row-format text-left gap-1">
			<div style="flex: 1 1 0; width: 0;">
				<div>Border color</div>
				<color-selector v-model="element.borderColor" @input="updated"></color-selector>
			</div>
		</div>
		<v-autocomplete
				persistent-placeholder
				hide-details
				dense
				outlined
				label="Embedded form"
				:items="templateList"
				v-model="element.attachedFormId"
				@change="updated"
		></v-autocomplete>
		<v-switch v-model="element.mergeClientData" hide-details dense persistent-hint label="Merge client data"></v-switch>
		<div class="font-12 font-gray_80">When this option is toggled on, any mapped field values will be merged to the client / contact record upon submission.</div>
	</div>
</template>

<script>
	import DiscoveryTemplateService from "@/modules/discovery/DiscoveryTemplateService";
	import ColorSelector from "@/components/ColorSelector";

	export default {
		name: 'FormEmbedConfig',

		props: ['item'],

		components: {ColorSelector},

		data: function() {
			return {
				element: { ...this.item },
				templates: [],
				templateService: new DiscoveryTemplateService(),
			};
		},

		mounted() {
			this.getTemplateList();
		},

		beforeDestroy() {},

		methods: {
			getTemplateList: function () {
				this.templateService.getDiscoveryTemplateList().then((res) => {
					this.templates.splice(0, this.templates.length);
					this.templates.push(...res.data.filter(t => t.schemaVersion > 1));
				});
			},

			updated: function() {
				this.$emit('change', this.element);
			},
		},

		watch: {
			selectedTemplate: function(){
				this.element.discoveryTemplate = this.selectedTemplate;
				this.updated();
			}
		},

		computed: {
			selectedTemplate: function(){
				return this.templates.find(t => t.id === this.element.attachedFormId);
			},

			templateList: function () {
				let results = [];

				results.push({
					text: '-- None -- ',
					value: null,
				});

				this.templates.forEach((t) => {
					results.push({ text: t.name, value: t.id });
				});

				results.sort((a,b) => a.text.localeCompare(b.text));

				return results;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
