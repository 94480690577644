<template>
	<v-container class="question-form" :style="`background-color: ${meeting.pageLayout.backgroundMainColor};`">
    <v-row>
      <v-col
          sm="5"
          cols="12"
          :style="`background-color: ${meeting.pageLayout.backgroundColor}; font-family: '${meeting.pageLayout.fontFamily}'; border-right: 1px solid var(--v-gray_30-base)`"
      >
        <scheduler-info :meeting="meeting" :tokenMap="tokenMap"></scheduler-info>
      </v-col>
      <v-col class="my-4" sm="7" cols="12" :style="`background-color: ${meeting.pageLayout.backgroundMainColor}`">
        <v-row>
          <v-col cols="2" class="row-format ml-3"
            ><v-btn :style="`color: ${textContrast}`" icon @click="handleBack()"><v-icon large>arrow_back</v-icon></v-btn></v-col
          >
          <v-col cols="8" class="font-18 brand-medium mb-4" v-if="!hideDefaultNameEmail" :style="`color: ${textContrast}`">{{ meeting.scheduleMeeting.infoHeader }}</v-col>
          <v-col cols="2" align="left"></v-col>
          <v-col cols="12" v-if="!hideDefaultNameEmail">
            <div class="row-format">
              <v-text-field
                hide-details
                dense
                placeholder="First name"
                outlined
                readonly
                class="mr-1 mt-2"
                :style="`--text-contrast: ${textContrast}`"
              ></v-text-field>
              <v-text-field
                hide-details
                dense
                placeholder="Last name"
                outlined
                readonly
                class="ml-1 mt-2"
                :style="`--text-contrast: ${textContrast}`"
              ></v-text-field>
            </div>
            <v-text-field
              hide-details
              dense
              placeholder="Email"
              outlined
              readonly
              class="mt-2"
              :style="`--text-contrast: ${textContrast}`"
            ></v-text-field>
            <v-text-field
              hide-details
              dense
              placeholder="Phone"
              outlined
              readonly
              class="mt-2"
              :style="`--text-contrast: ${textContrast}`"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="selectedForm" class="mt-2">
            <form-renderer-v1
              v-if="selectedForm.schemaVersion === 1"
              class="renderer"
              :schema="selectedForm.schema"
            ></form-renderer-v1>
            <form-renderer-v2
              v-if="selectedForm.schemaVersion === 2"
              class="renderer"
              :template="selectedForm"
            ></form-renderer-v2>
          </v-col>
        </v-row>
				<div
						v-show="paymentEnabled && meeting.requiredPayment"
						class="mt-5 pa-3 text-left column-format"
						:style="`border: 1px solid var(--v-gray_30-base); border-radius: 4px; gap: 12px;`"
				>
					<div class="font-14 pb-3">
						<div v-html="tokenReplace(meeting)"></div>
					</div>
					<div id="payment-element"></div>
				</div>
        <div :style="`display: flex; justify-content: ${alignButton}`">
          <v-btn
            class="mt-5"
            elevation="0"
            :block="meeting.scheduleMeeting.buttonStyle.buttonStyle === 'Block' ? true : null"
            :x-small="meeting.scheduleMeeting.buttonStyle.buttonSize === 'X-Small' ? true : null"
            :small="meeting.scheduleMeeting.buttonStyle.buttonSize === 'Small' ? true : null"
            :medium="meeting.scheduleMeeting.buttonStyle.buttonSize === 'Medium' ? true : null"
            :large="meeting.scheduleMeeting.buttonStyle.buttonSize === 'Large' ? true : null"
            :x-large="meeting.scheduleMeeting.buttonStyle.buttonSize === 'X-Large' ? true : null"
            style="text-transform: none !important"
            :color="lightAccent"
          >
            <span :style="`letter-spacing:0.3px; color:${meeting.pageLayout.accentColor}; font-weight:800;`">
              {{ meeting.scheduleMeeting.scheduleText }}
            </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
	</v-container>
</template>

<script>
	import DiscoveryTemplateService from '@/modules/discovery/DiscoveryTemplateService';
	import FormRendererV1 from '@/modules/meetings/detail/FormRendererV1.vue';
	import FormRendererV2 from '@/modules/meetings/detail/FormRendererV2.vue';
  import chroma from "chroma-js";
  import SchedulerInfo from "@/modules/meetings/detail/components/SchedulerInfo.vue";

	export default {
		name: 'Questions',

		props: ['meeting', 'tokenMap'],

		components: {SchedulerInfo, FormRendererV1, FormRendererV2 },

		data: function() {
			return {
				templates: [],
				templateService: new DiscoveryTemplateService(),
				stripeElementProcessed: false,
				stripe: null,
			};
		},

		mounted() {
			this.getTemplateList();
		},

		beforeDestroy() {},

		methods: {
			showStripeElement: function(){
				if(this.stripeElementProcessed){
					return;
				}

				this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {
					stripeAccount: this.$store.state.podAccount.stripeIntegration.id,
				});

				const options = {
					mode: 'payment',
					amount: 100,
					currency: 'usd',
					payment_method_types: ['card'],
					appearance: {
						theme: this.stripeTheme,
						variables: {
							colorBackground: this.meeting.pageLayout.backgroundMainColor,
							colorText: this.stripeContrast
						}
					}
				};

				this.elements = this.stripe.elements(options);
				this.paymentElement = this.elements.create('payment');
				this.paymentElement.mount("#payment-element");
				this.stripeElementProcessed = true;
			},

      tokenReplace: function(meeting) {
        if (!meeting.requiredPayment) {
          return '';
        }

        let result = meeting.requiredPayment.paymentInfo;
        let map = this.tokenMap;

        for (let [key, value] of map) {
          let token = '{{' + key + '}}';
          while (result.includes(token)) {
            result = result.replace(token, value);
          }
        }

        return result;
      },

			getTemplateList: function() {
				this.templateService.getDiscoveryTemplateList().then((res) => {
					this.templates.splice(0, this.templates.length);
					this.templates.push(...res.data);
				});
			},

			hasFieldWithMapping: function(schema, schemaMapping) {
				for (let i = 0; i < schema.length; i++) {
					if (schema[i].schemaMapping === schemaMapping) {
						return true;
					} else if (schema[i].type === 'Container') {
						for (let j = 0; j < schema[i].columns.length; j++) {
							let column = schema[i].columns[j];
							for (let k = 0; k < column.items.length; k++) {
								if (column.items[k].schemaMapping === schemaMapping) {
									return true;
								}
							}
						}
					}
				}
				return false;
			},

      handleBack: function() {
        this.$emit('time-confirmed', null);
        this.$emit('selector', true);
      }
		},

    watch: {
			paymentEnabled: {
				immediate: true,
				handler: function(val){
					if(val){
						this.$nextTick(() => this.showStripeElement());
					}

					if (val && !this.meeting.requiredPayment) {
						this.meeting.requiredPayment = {
							currency: this.$store.state.defaultCurrency,
							amount: 0,
							description: null,
						};
					}
				}
			}
    },

		computed: {
			hideDefaultNameEmail: function() {
				if (this.selectedForm && this.selectedForm.schemaVersion === 2) {
					let foundFirst = this.hasFieldWithMapping(this.selectedForm.schemaV2, 'firstName');
					let foundEmail = this.hasFieldWithMapping(this.selectedForm.schemaV2, 'email');
					return foundFirst && foundEmail;
				} else {
					return false;
				}
			},

      paymentEnabled: function() {
        return this.meeting.paymentEnabled;
      },

			selectedForm: function() {
				if (this.meeting.attachedFormId) {
					return this.templates.find((t) => t.id === this.meeting.attachedFormId);
				} else {
					return null;
				}
			},

			templateList: function() {
				let results = [];

				results.push({
					text: '-- None -- ',
					value: null,
				});

				this.templates.forEach((t) => {
					results.push({ text: t.name, value: t.id });
				});
				return results;
			},
      alignButton: function() {
        switch (this.meeting.scheduleMeeting.buttonStyle.buttonAlignment) {
          case "Left":
            return "start";
          case "Center":
            return "center";
          case "Right":
            return "end";
          default:
            return "center";
        }
      },

			stripeTheme: function () {
				let black = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#000000');
				let white = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#FFFFFF');
				if (black > white) {
					return 'stripe';
				} else {
					return 'night';
				}
			},

      textContrast: function() {
        let black = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#000000');
        let white = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#FFFFFF');
        if (black > white) {
          return '#000';
        } else {
          return '#FFF';
        }
      },

      lightAccent: function () {
        return chroma(this.meeting.pageLayout.accentColor).alpha(0.18).hex();
      },
		},
	};
</script>

<style scoped lang="scss">
	.renderer ::v-deep {
		.question {
			font-size: 14px !important;
			font-weight: 500;
		}
		.words {
			font-size: 14px !important;
		}
	}

  .v-text-field::v-deep input::placeholder {
    color: var(--text-contrast) !important;
  }

  .question-form {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

</style>
