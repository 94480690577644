<template>
	<div>
		<subscription-v1 v-if="pricingVersion === 1"></subscription-v1>
		<subscription-sumo v-else-if="pricingVersion === 999"></subscription-sumo>
		<subscription-v2 v-else :pricing-version="pricingVersion"></subscription-v2>
	</div>
</template>

<script>
	import SubscriptionV1 from "@/modules/subscription/v1/Subscription";
	import SubscriptionV2 from "@/modules/subscription/v2/Subscription";
	import SubscriptionSumo from "@/modules/subscription/sumo/Subscription"

	export default {
		name: 'SubscriptionRouter',

		props: [],

		components: {SubscriptionV1, SubscriptionV2, SubscriptionSumo},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			pricingVersion: function(){
				return this.$store.state.selectedUserAccount.account.pricingVersion;
			}
		},
	};
</script>

<style scoped lang="scss"></style>
