var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "500px" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.handleClose()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding mb-n5" },
        [
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v("Select email template")
          ]),
          _c("v-autocomplete", {
            staticClass: "h-outline",
            attrs: {
              "hide-details": "",
              "persistent-placeholder": "",
              label: "Templates",
              items: _vm.emailTemplates,
              "item-text": "name",
              "item-value": "id"
            },
            model: {
              value: _vm.emailTemplateId,
              callback: function($$v) {
                _vm.emailTemplateId = $$v
              },
              expression: "emailTemplateId"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "mt-2 pa-2",
          staticStyle: { "border-top": "1px solid var(--v-gray_50-base)" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-2",
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { width: "160" },
              on: {
                click: function($event) {
                  return _vm.renderEmailTemplate()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.ok")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }