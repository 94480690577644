<template>
	<div :class="`row-format align-center ml-1 ${view === 'Two-Week' ? 'mt-1' : ''}`">
		<client-avatar disable-click v-if="client" x-small :client="client" style="transform: scale(.92)"></client-avatar>
		<div v-else :class="`stopwatch row-format centered ${view === 'Month' ? 'scaled' : ''}`">
			<v-icon size="16" color="white">$stopwatch</v-icon>
		</div>
		<div class="ml-1 font-gray_90 brand-semilight">{{ formatSeconds(this.secondsWorked) }} worked</div>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";
	export default {
		name: 'TimerEvent',

		props: ['event','view'],

		components: {ClientAvatar},

		data: function () {
			return {
				secondsWorked: 0,
				events: [],
				client: null,
			};
		},

		mounted() {
			this.secondsWorked = this.event.original.meta.timerEvent.aggregate.duration;
			this.events.push(... this.event.original.meta.timerEvent.aggregate.events);
			this.client = this.$store.getters.clients.find(c => c.id === this.event.original.meta.timerEvent.clientId);
		},

		beforeDestroy() {},

		methods: {
			formatSeconds: function (seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}

				return hours + ':' + minutes;
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.stopwatch {
		background-color: var(--v-gray_60-base);
		width:20px;
		height:20px;
		border-radius: 4px;
	}

	.scaled {
		transform: scale(.85);
	}
</style>
