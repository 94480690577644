<template>
	<div class="column-format gap-2 py-3 text-left">
		<div class="row-format gap-1">
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pt" label="Padding top" @input="updated"></v-text-field>
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pb" label="Padding bottom" @input="updated"></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pl" label="Padding left" @input="updated"></v-text-field>
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pr" label="Padding right" @input="updated"></v-text-field>
		</div>
		<v-switch v-model="element.clientOnly" hide-details dense label="Client only signature block" @change="updated"></v-switch>
		<span class="font-12" v-if="element.clientOnly">Only signers from {{agreement.client.name}} will be required to sign this block.</span>
		<span class="font-12" v-else>All signers on the document are required to sign</span>
		<div class="mt-3">
			<v-btn @click.stop="$store.state.eventBus.$emit('agreement-show-signers')" elevation="0" color="secondary" outlined>Add signers</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'SignatureConfig',

		props: ['agreement','item','templateMode'],

		components: {},

		data: function() {
			return {
				element: { ...this.item },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
