<template>
	<div style="min-width: 500px; max-width: 500px">
		<v-btn icon class="close-dialog" @click="handleClose">
			<v-icon>$close</v-icon>
		</v-btn>
		<v-form @submit.prevent="connectAccount()" v-model="formValid">
			<div class="modal-padding">
				<div class="modal-title row-format align-center"><v-icon color="black" class="mb-1 mr-3">$apple</v-icon> <div>Connect iCloud Calendar</div></div>

				<div class="modal-subtitle">To connect your iCloud calendar, you must create an app-specific password.  Please see this <a href="https://support.apple.com/en-us/HT204397" style="color:var(--v-primary-base)" target="_blank">Apple help article</a> for instructions.</div>
				<div class="pt-2">
				<v-text-field
						:rules="emailRules"
						v-model="email"
						type="text"
						autofocus
						hide-details
						persistent-placeholder
						class="h-outline"
						label="iCloud email"
				></v-text-field>
				<v-text-field
						:rules="passwordRules"
						v-model="password"
						type="password"
						hide-details
						persistent-placeholder
						class="h-outline"
						label="App specific password"
				></v-text-field>
				</div>
				<div style="color: var(--v-error-base)" class="text-left font-14 pt-2" v-if="errorMessage">
					{{errorMessage}}
				</div>
			</div>
		</v-form>
		<div class="pt-2 pb-4" style="border-top: 1px solid var(--v-gray_50-base)">
			<v-btn class="secondary-action mr-1" width="150" @click="handleClose">Cancel</v-btn>
			<v-btn class="primary-action ml-1" width="150" :disabled="!formValid" @click="connectAccount">Connect</v-btn>
		</div>
	</div>
</template>

<script>
import AppleCalendarService from "@/modules/integrations/apple/AppleCalendarService";

export default {
	name: "AppleCalendarConnect",

	props: [],

	components: {},

	data: function () {
		return {
			appleService: new AppleCalendarService(),
			formValid: false,
			email:null,
			password:null,
			errorMessage: null,
			emailRules: [
				(v) => !!v || 'iCloud Email is required',
				(v) => !v || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'iCloud E-mail must be valid',
			],
			passwordRules: [
				(v) => !!v || 'App specific password is required',
				(v) => v.length > 18 || 'App specific password is required',
			],
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		connectAccount: function(){
			this.appleService.connect(this.email,this.password).then((res) => {
				this.$emit('result',res.data);
			}).catch((err) => {
				this.errorMessage = 'There was an error connecting your iCloud account: ' + err.response.data.message;
			})
		},

		handleClose: function(){
			this.$emit('result');
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>