var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "span",
        { staticClass: "question" },
        [
          _c("editor", {
            ref: "editor",
            staticStyle: { border: "1px dotted var(--v-grey-lighten3)" },
            attrs: {
              "api-key": _vm.$store.getters.getTinyMceKey,
              "initial-value": _vm.htmlText,
              inline: true,
              init: _vm.mceConfigText,
              spellcheck: true,
              placeholder: _vm.placeHolder
            },
            on: { input: _vm.handleChange }
          })
        ],
        1
      ),
      _c("v-file-input", {
        staticClass: "file-upload",
        attrs: {
          disabled: true,
          "hide-details": "",
          dense: "",
          outlined: "",
          multiple: "",
          "append-icon": "attach_file"
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var text = ref.text
              return [
                _c(
                  "v-chip",
                  { attrs: { small: "", label: "", color: "primary" } },
                  [_vm._v(" " + _vm._s(text) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "row-format mb-2" }, [
        _c(
          "div",
          {
            staticClass: "mt-2 ml-auto row-format align-center gap-2",
            staticStyle: { width: "fit-content" }
          },
          [
            _c("v-text-field", {
              staticStyle: { width: "150px" },
              attrs: {
                outlined: "",
                "persistent-placeholder": "",
                dense: "",
                "hide-details": "",
                label: "Field id"
              },
              on: {
                change: function($event) {
                  return _vm.handleChange()
                }
              },
              model: {
                value: _vm.ourFieldKey,
                callback: function($$v) {
                  _vm.ourFieldKey = $$v
                },
                expression: "ourFieldKey"
              }
            }),
            _c("v-select", {
              staticStyle: { width: "95px" },
              attrs: {
                items: _vm.requiredOptions,
                "item-text": "label",
                "item-value": "value",
                outlined: "",
                "persistent-placeholder": "",
                dense: "",
                "hide-details": "",
                label: "Required"
              },
              on: {
                change: function($event) {
                  return _vm.handleChange()
                }
              },
              model: {
                value: _vm.ourRequired,
                callback: function($$v) {
                  _vm.ourRequired = $$v
                },
                expression: "ourRequired"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }