<template>
	<div id="" style="max-width: 500px">
		<v-btn icon class="close-dialog" @click="$emit('result')">
			<v-icon>$close</v-icon>
		</v-btn>

		<div class="modal-padding">
			<div class="modal-title">
				Transfer project to another client
			</div>
			<div class="column-format gap-6 text-left font-14">
				<div>Please select the client you would like to transfer this project to.  All tasks, notes, and time entries will be moved along with the project.</div>
				<select-client v-model="selectedClientId" :show-avatar="true" :hide-archive="true" :dense="true"></select-client>
			</div>
		</div>
		<div class="modal-footer row-format gap-2 centered">
			<v-btn class="secondary-action mr-1" @click="$emit('result')">
				<span class="font-gray_70">{{ $t('global.cancel') }}</span>
			</v-btn>
			<v-btn
					class="super-action"
					@click="transferProject()"
					:disabled="!selectedClientId"
			>
				Confirm transfer
			</v-btn>
		</div>
	</div>
</template>

<script>
	import SelectClient from "@/modules/clients/SelectClient";

	export default {
		name: 'ClientTransfer',

		props: ['project'],

		components: {SelectClient},

		data: function() {
			return {
				selectedClientId: null
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			transferProject: function(){
				this.$emit('result',this.selectedClientId);
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
