var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "send-widget" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("result")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c("h2", { staticClass: "modal-title mb-5" }, [
          _vm._v("Send subscription request")
        ]),
        _c(
          "div",
          [
            _c("select-client", {
              model: {
                value: _vm.clientId,
                callback: function($$v) {
                  _vm.clientId = $$v
                },
                expression: "clientId"
              }
            }),
            _vm._l(_vm.selectedProducts, function(newSubscription) {
              return _c("product-selector", {
                key: newSubscription.id,
                attrs: {
                  "products-flattened": _vm.productsFlattened,
                  "new-subscription": newSubscription
                },
                on: {
                  update: function($event) {
                    return _vm.updateProductSelection($event)
                  },
                  delete: function($event) {
                    return _vm.deleteProductSelection($event)
                  }
                }
              })
            }),
            _c(
              "div",
              {
                staticClass: "font-primary pointer text-left font-14",
                on: { click: _vm.addItem }
              },
              [_vm._v("+ Add additional item")]
            ),
            _vm.taxRates.length
              ? _c("v-autocomplete", {
                  staticClass: "h-outline mt-3",
                  attrs: {
                    "hide-details": "",
                    "persistent-placeholder": "",
                    clearable: "",
                    "item-text": "name",
                    label: "Tax rate",
                    items: _vm.taxRates,
                    "return-object": false,
                    "item-value": "id"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "selection",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item.displayName) +
                                " - " +
                                _vm._s(item.percentage) +
                                "% "
                            )
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item.displayName) +
                                " - " +
                                _vm._s(item.percentage) +
                                "% "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1174154396
                  ),
                  model: {
                    value: _vm.taxRateId,
                    callback: function($$v) {
                      _vm.taxRateId = $$v
                    },
                    expression: "taxRateId"
                  }
                })
              : _vm._e()
          ],
          2
        ),
        _vm.client
          ? _c("div", { staticClass: "mt-5" }, [
              _c("div", { staticClass: "rowFormat" }, [
                _c("span", { staticClass: "emailHeader" }, [
                  _vm._v(_vm._s(_vm.$t("global.email-from")) + ": ")
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$store.getters.getLoggedInUserName) +
                      " <" +
                      _vm._s(_vm.$store.getters.getFromEmail) +
                      ">"
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "rowFormat d-flex justify-space-between align-center"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      staticStyle: { "flex-wrap": "wrap" }
                    },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              _vm.showToMenu = true
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "emailHeader" }, [
                            _vm._v(_vm._s(_vm.$t("global.email-to")) + ":")
                          ]),
                          _vm._l(_vm.sendToContacts, function(contact) {
                            return _c(
                              "span",
                              { key: contact.email, staticClass: "pointer" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.formatEmail(contact)) + "; "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { flex: "0 0 28px" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "min-width": "500",
                            "nudge-left": "476",
                            "nudge-top": "12"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              icon: "",
                                              small: "",
                                              rounded: ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("$chevronDown")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2832106106
                          ),
                          model: {
                            value: _vm.showToMenu,
                            callback: function($$v) {
                              _vm.showToMenu = $$v
                            },
                            expression: "showToMenu"
                          }
                        },
                        [
                          _vm.client
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "background-color": "var(--v-white-base)"
                                  }
                                },
                                [
                                  _vm.toEmailMode === "selectContact"
                                    ? _c(
                                        "v-container",
                                        { attrs: { fluid: "" } },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "py-3 px-3" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "10",
                                                    align: "left"
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.client.contacts,
                                                    function(contact) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: contact.email,
                                                          staticClass:
                                                            "row-format"
                                                        },
                                                        [
                                                          _c(
                                                            "v-simple-checkbox",
                                                            {
                                                              staticClass:
                                                                "float-left mr-2",
                                                              attrs: {
                                                                value:
                                                                  _vm.sendToContacts.findIndex(
                                                                    function(
                                                                      v
                                                                    ) {
                                                                      return (
                                                                        v.email ==
                                                                        contact.email
                                                                      )
                                                                    }
                                                                  ) > -1
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.handleContactChecked(
                                                                    contact
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatEmail(
                                                                  contact
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mt-4 primary-action",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.toEmailMode =
                                                            "createContact"
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            small: "",
                                                            left: ""
                                                          }
                                                        },
                                                        [_vm._v("$plus")]
                                                      ),
                                                      _vm._v(" Add new contact")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "2",
                                                    align: "right"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        rounded: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.closeToMenu()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("$chevronUp")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.toEmailMode === "createContact"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "close-dialog",
                                              attrs: { icon: "", rounded: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.closeToMenu()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("$chevronUp")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "modal-padding" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "modal-title" },
                                                [_vm._v("Create new contact")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-space-between align-center"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pr-1",
                                                      staticStyle: {
                                                        flex: "0 0 50%"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass:
                                                          "h-outline",
                                                        attrs: {
                                                          "persistent-placeholder":
                                                            "",
                                                          "hide-details": "",
                                                          label: "First name",
                                                          autocomplete:
                                                            "new-password"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newContact
                                                              .firstName,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newContact,
                                                              "firstName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newContact.firstName"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pl-1",
                                                      staticStyle: {
                                                        flex: "0 0 50%"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass:
                                                          "h-outline",
                                                        attrs: {
                                                          "persistent-placeholder":
                                                            "",
                                                          "hide-details": "",
                                                          label: "Last name",
                                                          autocomplete:
                                                            "new-password"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newContact
                                                              .lastName,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newContact,
                                                              "lastName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newContact.lastName"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _c("v-text-field", {
                                                staticClass: "h-outline",
                                                attrs: {
                                                  "persistent-placeholder": "",
                                                  "hide-details": "",
                                                  label: "Email",
                                                  autocomplete: "new-password"
                                                },
                                                model: {
                                                  value: _vm.newContact.email,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.newContact,
                                                      "email",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "newContact.email"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "modal-footer" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "secondary-action mr-1",
                                                  attrs: {
                                                    width: "150",
                                                    disabled:
                                                      _vm.processingCreate
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.cancelCreateNewContact()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Cancel")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "primary-action ml-1",
                                                  attrs: {
                                                    width: "150",
                                                    disabled:
                                                      _vm.processingCreate
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.createNewContact()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Save")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("div", { staticClass: "rowFormat" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-direction": "row" } },
                  [
                    _c("span", { staticClass: "emailHeader" }, [
                      _vm._v(_vm._s(_vm.$t("global.email-subject")) + ": ")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.subject,
                          expression: "subject"
                        }
                      ],
                      staticStyle: {
                        outline: "none",
                        width: "100%",
                        color: "var(--v-black-base)"
                      },
                      attrs: { type: "text" },
                      domProps: { value: _vm.subject },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.subject = $event.target.value
                        }
                      }
                    })
                  ]
                )
              ]),
              _c("div", { staticClass: "rowFormat" }, [
                _c(
                  "div",
                  [
                    _vm.body !== null
                      ? _c("editor", {
                          key: _vm.refreshEditor,
                          ref: "bodyEditor",
                          staticStyle: { "max-width": "550px" },
                          attrs: {
                            "api-key": _vm.$store.getters.getTinyMceKey,
                            inline: false,
                            "initial-value": _vm.body,
                            init: _vm.mceConfigText,
                            spellcheck: true
                          },
                          on: { input: _vm.handleBodyInput }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm.s3Attachments.length
                  ? _c(
                      "div",
                      {
                        staticClass: "row-format mt-2",
                        staticStyle: { "flex-wrap": "wrap", gap: "8px" }
                      },
                      _vm._l(_vm.s3Attachments, function(s3, index) {
                        return _c(
                          "v-chip",
                          {
                            key: s3.fileName + index,
                            attrs: {
                              color: "blue_10",
                              small: "",
                              close: "",
                              "close-icon": "cancel"
                            },
                            on: {
                              "click:close": function($event) {
                                return _vm.removeS3Attachment(s3)
                              }
                            }
                          },
                          [_vm._v(_vm._s(s3.fileName))]
                        )
                      }),
                      1
                    )
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mx-2",
              attrs: { width: "120", disabled: _vm.disabled },
              on: {
                click: function($event) {
                  return _vm.cancel()
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: {
                width: "150",
                disabled: _vm.disabled || !this.sendEnabled
              },
              on: {
                click: function($event) {
                  return _vm.sendRequest()
                }
              }
            },
            [
              _c("span", { staticClass: "pr-2" }, [_vm._v("Send")]),
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("$nearMe")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }