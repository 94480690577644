var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("node-label", {
        on: {
          open: function($event) {
            return _vm.edit()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded mr-1",
                    attrs: { color: "black", size: "22" }
                  },
                  [_vm._v("block")]
                ),
                _c("div", [_vm._v("Void agreement")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("node-modal", {
        ref: "nodeModal",
        attrs: { title: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "column-format gap-2",
                    staticStyle: { "max-width": "450px" }
                  },
                  [
                    _vm._v(
                      " Void agreement to prevent further client access or signature. This operation will not impact any agreement that has been fully executed or signed by the client. "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }