var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { id: "planChange" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.planChange()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "mx-8 mx-sm-12 mb-6 mb-sm-10" },
        [
          _c("h1", {
            staticClass: "justify-center mb-5",
            domProps: { innerHTML: _vm._s(_vm.heading) }
          }),
          _c("div", {
            staticClass: "mb-5",
            domProps: { innerHTML: _vm._s(_vm.subheading) }
          }),
          _c("div", {
            staticClass: "mb-9",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("subscription.plan-change.confirm-caption")
              )
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "my-4",
              attrs: { type: "submit", color: "accent", large: "", block: "" }
            },
            [_vm._v(_vm._s(_vm.button))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }