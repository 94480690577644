<template>
	<div id="send-widget">
		<v-btn icon class="close-dialog" @click="$emit('result')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h2 class="modal-title mb-5">Send subscription request</h2>
			<div>
				<select-client v-model="clientId"></select-client>

				<product-selector
						v-for="newSubscription in selectedProducts"
						:key="newSubscription.id"
						:products-flattened="productsFlattened"
						:new-subscription="newSubscription"
						@update="updateProductSelection($event)"
						@delete="deleteProductSelection($event)"></product-selector>

				<div class="font-primary pointer text-left font-14" @click="addItem">+ Add additional item</div>

				<v-autocomplete
						v-if="taxRates.length"
						hide-details
						persistent-placeholder
						clearable
						class="h-outline mt-3"
						item-text="name"
						label="Tax rate"
						:items="taxRates"
						v-model="taxRateId"
						:return-object="false"
						item-value="id"
				>
					<template v-slot:selection="{ item }">
						{{ item.displayName }} - {{ item.percentage }}%
					</template>
					<template v-slot:item="{ item }">
							{{ item.displayName }} - {{ item.percentage }}%
					</template>
				</v-autocomplete>
			</div>

			<div v-if="client" class="mt-5">
				<div class="rowFormat">
					<span class="emailHeader">{{ $t('global.email-from') }}: </span>
					<span>{{ $store.getters.getLoggedInUserName }} &lt;{{ $store.getters.getFromEmail }}&gt;</span>
				</div>

				<div class="rowFormat d-flex justify-space-between align-center">
					<div class="row-format" style="flex-wrap: wrap">
						<div @click="showToMenu = true">
							<span class="emailHeader">{{ $t('global.email-to') }}:</span>
							<span v-for="contact in sendToContacts" class="pointer" :key="contact.email">
								{{ formatEmail(contact) }};
							</span>
						</div>
					</div>
					<div style="flex: 0 0 28px">
						<v-menu
							v-model="showToMenu"
							:close-on-content-click="false"
							min-width="500"
							nudge-left="476"
							nudge-top="12"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon small rounded v-bind="attrs" v-on="on">
									<v-icon small>$chevronDown</v-icon>
								</v-btn>
							</template>
							<div style="background-color: var(--v-white-base)" v-if="client">
								<v-container v-if="toEmailMode === 'selectContact'" fluid>
									<v-row class="py-3 px-3">
										<v-col cols="10" class="" align="left">
											<div v-for="contact in client.contacts" :key="contact.email" class="row-format">
												<v-simple-checkbox
													:value="sendToContacts.findIndex((v) => v.email == contact.email) > -1"
													@click="handleContactChecked(contact)"
													class="float-left mr-2"
												/>
												<span>{{ formatEmail(contact) }}</span>
											</div>
											<v-btn class="mt-4 primary-action" @click="toEmailMode = 'createContact'"
												><v-icon small left class="mr-2">$plus</v-icon> Add new contact</v-btn
											>
										</v-col>
										<v-col cols="2" align="right">
											<v-btn icon rounded @click="closeToMenu()">
												<v-icon small>$chevronUp</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</v-container>
								<div v-if="toEmailMode === 'createContact'">
									<v-btn icon rounded @click="closeToMenu()" class="close-dialog">
										<v-icon small>$chevronUp</v-icon>
									</v-btn>

									<div class="modal-padding">
										<div class="modal-title">Create new contact</div>
										<div class="d-flex justify-space-between align-center">
											<div style="flex: 0 0 50%" class="pr-1">
												<v-text-field
													class="h-outline"
													persistent-placeholder
													hide-details
													v-model="newContact.firstName"
													label="First name"
													autocomplete="new-password"
												></v-text-field>
											</div>
											<div style="flex: 0 0 50%" class="pl-1">
												<v-text-field
													class="h-outline"
													persistent-placeholder
													hide-details
													v-model="newContact.lastName"
													label="Last name"
													autocomplete="new-password"
												></v-text-field>
											</div>
										</div>
										<v-text-field
											class="h-outline"
											persistent-placeholder
											hide-details
											v-model="newContact.email"
											label="Email"
											autocomplete="new-password"
										></v-text-field>
									</div>

									<div class="modal-footer">
										<v-btn
											class="secondary-action mr-1"
											width="150"
											@click="cancelCreateNewContact()"
											:disabled="processingCreate"
											>Cancel</v-btn
										>
										<v-btn
											class="primary-action ml-1"
											width="150"
											@click="createNewContact()"
											:disabled="processingCreate"
											>Save</v-btn
										>
									</div>
								</div>
							</div>
						</v-menu>
					</div>
				</div>
				<div class="rowFormat">
					<div style="display: flex; flex-direction: row">
						<span class="emailHeader">{{ $t('global.email-subject') }}: </span>
						<input v-model="subject" type="text" style="outline: none; width: 100%; color: var(--v-black-base)" />
					</div>
				</div>
				<div class="rowFormat">
					<div>
						<editor
							:key="refreshEditor"
							style="max-width: 550px"
							v-if="body !== null"
							ref="bodyEditor"
							:api-key="$store.getters.getTinyMceKey"
							:inline="false"
							:initial-value="body"
							:init="mceConfigText"
							:spellcheck="true"
							@input="handleBodyInput"
						></editor>
					</div>
					<div v-if="s3Attachments.length" class="row-format mt-2" style="flex-wrap: wrap; gap: 8px">
						<v-chip color="blue_10" small v-for="(s3,index) in s3Attachments" :key="s3.fileName + index" close close-icon="cancel" @click:close="removeS3Attachment(s3)">{{s3.fileName}}</v-chip>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mx-2" width="120" @click="cancel()" :disabled="disabled">Cancel</v-btn>
			<v-btn class="primary-action" width="150" @click="sendRequest()" :disabled="disabled || !this.sendEnabled"
				><span class="pr-2">Send</span>
				<v-icon small>$nearMe</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
	import ClientService from '@/modules/clients/ClientService';
	import SelectClient from '@/modules/clients/SelectClient';
	import StripeSubscriptionService from '@/modules/integrations/stripe/subscriptions/StripeSubscriptionService';
	import ProductSelector from "@/modules/integrations/stripe/subscriptions/ProductSelector";
	import {v4 as uuid4} from 'uuid';
	import EmailTemplateSelector from "@/modules/templates/emails/EmailTemplateSelector";
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'SendSubscriptionRequest',
		props: {},

		components: {
			ProductSelector,
			SelectClient,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function () {
			return {
				clientService: new ClientService(),
				stripeSubscriptionService: new StripeSubscriptionService(),
				clientId: null,
				client: null,
				refreshEditor: 0,
				products: [],
				taxRates: [],
				selectedProducts: [],
				taxRateId: null,
				s3Attachments: [],
				isTemplate: false,

				disabled: false,
				sendToContacts: [],
				showToMenu: false,
				toEmailMode: 'selectContact',

				subject: '',
				body: null,

				processingCreate: false,

				newContact: {
					firstName: null,
					lastName: null,
					email: null,
				},

				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					paste_as_text: false,
					forced_root_block: false,
					paste_data_images: true,
					browser_spellcheck: true,
					plugins: ['paste', 'lists', 'link', 'table'],
					contextmenu: 'paste | link | table',
					branding: false,
					height: `400px`,
					width: '800px',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						`undo redo | styleselect forecolor | bold italic underline | alignleft aligncenter alignright alignjustify',
						'table | bullist numlist | link unlink | ${this.$store.getters.isPaidAccount ? 'template' : ''}`,
					],
					table_toolbar:
							'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('template', {
							text: 'Insert template',
							onAction: () => {
								this.selectEmailTemplate();
							},
						});
					},
				},
			};
		},

		mounted() {
			this.initialize();
		},

		methods: {
			initialize: function () {
				this.stripeSubscriptionService.getProducts().then((res) => {
					this.products.splice(0, this.products.length);
					this.products.push(...res.data);
				});

				this.stripeSubscriptionService.getTaxRates().then((res) => {
					this.taxRates.splice(0, this.taxRates.length);
					this.taxRates.push(...res.data);
				});
				this.addItem();
			},

			addItem: function(){
				this.selectedProducts.push({id: uuid4(), quantity: 1, selectedProduct: null});
			},

			updateProductSelection: function(selection){
				let ix = this.selectedProducts.findIndex(p => p.id === selection.id);
				this.selectedProducts.splice(ix,1,selection);
			},

			deleteProductSelection: function(selection){
				let ix = this.selectedProducts.findIndex(p => p.id === selection.id);
				this.selectedProducts.splice(ix,1);
			},


			getClient: function () {
				this.clientService
					.getClientDetail(this.clientId)
					.then((res) => {
						this.client = res.data;
						// Try to set send-to contact
						if (this.invoiceContacts.length) this.sendToContacts = this.invoiceContacts;
						else if (this.defaultContacts.length) this.sendToContacts = this.defaultContacts;
						else if (this.client.contacts.length) this.sendToContacts = [this.client.contacts[0]];

						this.body = `Hi ${this.client.name} Team,<br>Please follow this link to complete the subscription sign up.<br>Thank you!`;
						this.refreshEditor++;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			handleContactChecked(contact) {
				const index = this.sendToContacts.findIndex((v) => v.email == contact.email);
				if (index > -1) return this.sendToContacts.splice(index, 1);
				this.sendToContacts.push(contact);
			},

			closeToMenu: function () {
				this.showToMenu = false;
				this.toEmailMode = 'selectContact';
			},

			handleBodyInput: function () {
				this.body = this.$refs.bodyEditor.editor.getContent();
			},

			formatEmail: function (contact) {
				if (contact) {
					return contact.firstName + ' ' + contact.lastName + ' <' + contact.email + '>';
				} else {
					return '';
				}
			},

			cancel: function () {
				this.sendToContacts = [];
				this.disabled = false;
				this.$emit('result');
			},

			finalize: function () {
				this.disabled = false;
				this.sendToContacts = [];
				this.$emit('cancel');
			},

			selectEmailTemplate: function(){
				let binding = {
					contacts:this.sendToContacts,
					clientId: this.clientId,
				};
				this.$store.state.globalModalController.openModal(EmailTemplateSelector,binding).then((res) => {
					if(res){
						this.subject = res.subject;
						this.$refs.bodyEditor.editor.setContent('');
						this.$refs.bodyEditor.editor.execCommand('mceInsertContent', false, res.htmlContent);
						this.s3Attachments.splice(0,this.s3Attachments.length);
						this.s3Attachments.push(... res.attachments);
						this.isTemplate = true;

						let confirm = {
							severity: 'info',
							hideNo: true,
							yesText: 'OK',
							headingText: 'Notification',
							bodyText: 'Depending on your template, you may still see specific subscription tokens.  This is is normal and they will be processed when you Send.'
						}
						this.$store.state.globalModalController.openModal(ConfirmModal,confirm);
					}
				});
			},

			sendRequest: function () {
				this.$track.record('action', { module: 'send-subscription' });
				this.disabled = true;
				this.$store.commit('startLoading');

				let newSubscriptionList = this.selectedProducts.map(s => ({priceId: s.selectedProduct.id, quantity: s.quantity}));

				let sendEmail = {
					contacts: this.sendToContacts,
					subject: this.subject,
					body: this.body,
					template: this.isTemplate,
					s3Attachments: this.s3Attachments
				};

				let request = {
					newSubscriptionList: newSubscriptionList,
					sendEmail: sendEmail
				}

				this.stripeSubscriptionService
					.createSubscriptionRequest(this.clientId, this.taxRateId, request)
					.then((res) => {
						this.$emit('result', res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			cancelCreateNewContact: function () {
				this.newContact = {
					firstName: null,
					lastName: null,
					email: null,
				};
				this.toEmailMode = 'selectContact';
			},

			createNewContact: function () {
				this.processingCreate = true;
				let opList = [];
				let operation = {};

				operation.op = 'add';
				operation.path = '/contacts/-';
				operation.value = this.newContact;

				opList.push(operation);

				this.clientService
					.patchClient(this.client.id, opList)
					.then((res) => {
						this.client.contacts = res.data.contacts;

						for (let i = 0; i < this.client.contacts.length; i++) {
							if (this.client.contacts[i].email === this.newContact.email) {
								this.sendToContacts.push(this.client.contacts[i]);
								break;
							}
						}
						this.showToMenu = false;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.processingCreate = false;
						this.cancelCreateNewContact();
					});
			},

			alert: function (type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},

			removeS3Attachment: function(s3File){
				let ix = this.s3Attachments.findIndex(s => s.fileName === s3File.fileName);
				if(ix > -1){
					this.s3Attachments.splice(ix,1);
				}
			}
		},

		computed: {
			sendEnabled: function () {
				if (!this.clientId) {
					return false;
				} else if (!this.allSelectedProductsValid) {
					return false;
				} else if (!this.sendToContacts.length) {
					return false;
				} else {
					return true;
				}
			},

			allSelectedProductsValid: function(){
				let valid = this.selectedProducts.length > 0;

				for(let i=0; i < this.selectedProducts.length; i++){
					if(!this.selectedProducts[i].selectedProduct){
						valid = false;
						break;
					}else if(!this.selectedProducts[i].quantity || this.selectedProducts[i].quantity < 1){
						valid = false;
						break;
					}
				}
				return valid;
			},

			invoiceContacts() {
				return this.client.contacts.filter((v) => v.invoiceContact == true);
			},

			defaultContacts() {
				return this.client.contacts.filter((v) => v.defaultContact == true);
			},

			productsFlattened: function () {
				let result = [];

				for (let i = 0; i < this.products.length; i++) {
					let product = this.products[i];

					if (!product.active) {
						continue;
					}

					for (let j = 0; j < product.prices.length; j++) {
						let price = product.prices[j];

						if (!price.active) {
							continue;
						}

						let amount = this.$formatters.dollars(price.unitAmount / 100, true, true, price.currency);

						if (price.intervalCount == 1) {
							amount = amount + ' / ' + price.interval.toLowerCase();
						} else {
							amount = amount + ' every ' + price.intervalCount + ' ' + price.interval.toLowerCase() + 's';
						}

						result.push({
							name: product.name,
							id: price.id,
							amount: amount,
						});
					}
				}

				return result;
			},
		},
		watch: {
			clientId: function (val) {
				if (val) {
					this.getClient();
				}
			},

			selectedProducts: function () {
				if (this.selectedProducts.length) {
					let name = this.selectedProducts.filter(s => s.selectedProduct).map(s => s.selectedProduct.name).join(" & ");
					this.subject = 'Subscription for ' + name + ' from ' + this.$store.getters.getAccountName;
				}
			},
		},
	};
</script>

<style lang="scss">
	#send-widget {
		background-color: var(--v-white-base);
		width: 850px;
		max-width: 850px;
	}
</style>

<style scoped lang="scss">
	.emailPill {
		background-color: #c9def7;
		border-radius: 8px;
		color: #002b52;
	}

	.rowFormat {
		text-align: left;
		padding: 12px 0 !important;
		&:not(:last-child) {
			border-bottom: 1px solid var(--v-gray_50-base);
		}
	}

	.client-name {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;

		div {
			align-self: center;
			white-space: nowrap;
			flex: 0 0 auto;
		}
	}

	.linkBox {
		width: 500px;
		border-radius: 8px;
		background-color: var(--v-gray_30-base);
		padding: 15px;
	}

	.v-menu__content {
		border-radius: 0px;
		border: 1px solid var(--v-gray_30-base);
	}

	.emailHeader {
		margin-right: 10px;
		color: var(--v-gray_70-base);
	}
</style>
