<template>
	<div  :class="`row-format align-center ml-1 ${view === 'Two-Week' ? 'mt-1' : ''}`">
		<client-avatar disable-click x-small  style="transform: scale(.92)" :client="client"></client-avatar>
		<div class="ml-1 font-gray_90 row-format align-center" style="width: 100%">
			<div class="brand-medium">Proposal signed</div>
			<v-icon class="ml-auto mr-1" size="14" color="success">$alert</v-icon>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'ProposalSignedEvent',

		props: ['event','view'],

		components: { ClientAvatar },

		data: function () {
			return {
				proposal: this.event.original.meta.proposal,
				client: this.$store.getters.clients.find((c) => c.id === this.event.original.meta.proposal.client.id),
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {},

		computed: {

		},
	};
</script>

<style scoped lang="scss">
</style>
