<template>
	<div class="text-left" v-if="ourCall">
		<div class="row-format">
			<div>
				<v-btn icon @click="$emit('back')"><v-icon size="24" color="black">arrow_back</v-icon></v-btn>
			</div>
			<div class="ml-auto row-format align-center">
				<div class="mr-2" v-if="ourFolder !== 'Inbox'">
					{{ ourFolder }}
					<span v-if="ourFolder === 'Snoozed'"
						>until {{ HDateTime.fromISO(ourCall.snoozedUntil).toLocaleString(HDateTime.DATETIME_MED) }}</span
					>
				</div>
				<inbox-actions
					v-if="ourFolder && ourCall"
					:size="24"
					@return-to-inbox="returnToInbox()"
					@snooze="snooze($event)"
					@archive="archive()"
					@unread="markCallAsRead()"
					:unread="ourCall.unread"
					:snooze-options="snoozeOptions"
					:folder="ourFolder"
					item-type="Call"
					:item-id="ourCall.id"
				></inbox-actions>
			</div>
		</div>
		<div class="show-scrollbar pa-2" style="height: calc(var(--vh) - 135px); overflow-y: scroll">
			<div class="row-format mt-3">
				<div v-if="!clientId"><client-avatar v-if="client" :client="client" small></client-avatar></div>
				<div :class="`text-truncate ${client && !clientId ? 'ml-3' : ''}`">
					{{ contact ? contact.fullName : formattedPhone }}
				</div>
				<div class="ml-auto">
					{{ HDateTime.fromISO(ourCall.startTime).toLocaleString(HDateTime.DATETIME_MED) }}
				</div>
			</div>
			<div class="column-format mt-3" style="gap: 8px">
				<div>{{ ourCall.direction }} call</div>
				<div v-if="ourCall.direction === 'Inbound'">
					From: <span class="pointer font-primary" @click="dial(ourCall.from)">{{ ourCall.from }}</span>
				</div>
				<div v-if="ourCall.direction === 'Outbound'">
					To: <span class="pointer font-primary" @click="dial(ourCall.from)">{{ ourCall.to }}</span>
				</div>
				<div>Duration: {{ duration }}</div>
				<div>Call result: {{ ourCall.callResult }}</div>

				<div v-if="callRecordingUrl" class="row-format centered">
					<audio controls style="width: 300px" :src="callRecordingUrl"></audio>
				</div>

				<div v-if="ourCall.transcription" class="mt-3">
					{{ ourCall.transcription.text }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import libPhoneNumber from 'google-libphonenumber';
	import ClientAvatar from '@/components/ClientAvatar';
	import { DateTime } from 'luxon';
	import InboxActions from '@/modules/communicator/inbox/InboxActions';
	import RecentCallMixin from '@/modules/communicator/inbox/calls/RecentCallMixin';
	import CommunicatorCallService from '@/modules/communicator/CommunicatorCallService';

	export default {
		name: 'RecentCallDetail',

		props: ['call', 'snoozeOptions', 'folder', 'clientId'],

		components: { ClientAvatar, InboxActions },

		mixins: [RecentCallMixin],

		data: function() {
			return {
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
				hovering: false,
				callRecordingUrl: null,
				ourCall: {},
			};
		},

		mounted() {
			this.ourCall = { ...this.call };
			this.ourFolder = this.folder;

			if (this.ourCall.callRecording) {
				this.getCallRecordingUrl();
			}

			if (this.ourCall.unread) {
				this.markCallAsRead();
			}
		},

		beforeDestroy() {},

		methods: {
			dial: function(phone) {
				this.$store.state.eventBus.$emit('communicator-place-call', phone);
			},

			formatPhone: function(phoneNumber) {
				try {
					let phone = this.PhoneUtil.parse(phoneNumber);
					return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
				} catch (err) {
					return phoneNumber;
				}
			},

			getCallRecordingUrl: function() {
				new CommunicatorCallService()
					.getCallRecordingUrl(this.ourCall.id)
					.then((res) => (this.callRecordingUrl = res.data));
			},

			formatSecondsLong(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				if (hours > 0) {
					return hours + ':' + minutes + ':' + seconds;
				} else {
					return minutes + ':' + seconds;
				}
			},
		},

		watch: {
			contact: {
				immediate: true,
				handler: function(val) {
					let result = [];
					if (val) {
						result.push(val);
					} else {
						result.push({
							id: null,
							email: null,
							phone: this.ourCall.direction === 'Inbound' ? this.ourCall.from : this.ourCall.to,
							firstName: null,
							lastName: null,
						});
					}
					this.$emit('set-contacts', result);
				},
			},
		},

		computed: {
			start: function() {
				return DateTime.fromISO(this.ourCall.startTime);
			},

			end: function() {
				return DateTime.fromISO(this.ourCall.endTime);
			},

			formattedPhone: function() {
				return this.formatPhone(this.ourCall.direction === 'Inbound' ? this.call.from : this.call.to);
			},

			duration: function() {
				let seconds = Math.ceil(this.end.diff(this.start, ['seconds']).seconds);
				return this.formatSecondsLong(seconds);
			},

			client: function() {
				if (this.contact && this.contact.clientId) {
					return this.$store.state.clientCache.find((c) => c.id === this.contact.clientId);
				} else {
					return null;
				}
			},

			contact: function() {
				if (!this.ourCall) {
					return null;
				} else if (this.ourCall.direction === 'Inbound') {
					return this.$store.getters.getContactByPhone(this.ourCall.from);
				} else {
					return this.$store.getters.getContactByPhone(this.ourCall.to);
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
