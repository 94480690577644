import NewProposal from "@/modules/proposals/NewProposal";
import ProposalBuilder from "@/modules/proposals/ProposalBuilder";
import {v4 as uuidv4} from 'uuid';
import HDateTime from "@/modules/utils/HDateTime";
import AgreementBuilder from "@/modules/agreements/AgreementBuilder";

export default {
    methods: {
        addNewProposal: function(opportunity) {
            this.$store.state.globalModalController
                .openModal(NewProposal, { clientId: opportunity.clientId, opportunityId: opportunity.id }, true, false)
                .then((res) => {
                    if (res) {
                        if (res.action === 'NEW_AGREEMENT') {
                            this.addToWorkflow(opportunity, 'Agreement', res.id);
                            this.openV2Agreement(res.id);
                        } else {
                            this.addToWorkflow(opportunity, 'Proposal', res.id);
                            this.openProposalById(res.id, res.clientId);
                        }
                    }
                });
        },

        addToWorkflow: function(opportunity, type, id){
          this.opportunity.workflow.push({
              id: uuidv4(),
              itemId: id,
              itemType: type,
              timestamp: HDateTime.now().toISO()
          });
          this.$emit('save');
        },

        openV2Agreement: function(id) {
            this.$store.state.globalModalController.openModal(AgreementBuilder, {id: id}, true, true).then(() => this.$emit('refresh'));
        },

        openProposalById: function(proposalId, clientId) {
            let binding = {
                proposalId: proposalId,
                clientId: clientId,
            };
            this.$store.state.globalModalController.openModal(ProposalBuilder, binding, true, true).then(() => this.$emit('refresh'));
        },
    }
}