var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-3" },
    [
      _c("font-selector", {
        staticStyle: { "min-width": "200px" },
        attrs: { font: _vm.meeting.pageLayout.fontFamily },
        on: {
          "font-changed": function($event) {
            _vm.meeting.pageLayout.fontFamily = $event
            _vm.requestSave()
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "column-format pb-2",
          staticStyle: { "border-bottom": "1px solid var(--v-gray_50-base)" }
        },
        [
          _c(
            "div",
            {
              staticClass: "font-14 text-left font-gray_70",
              staticStyle: { "min-width": "100%" }
            },
            [_vm._v("Accent color")]
          ),
          _c("color-selector", {
            model: {
              value: _vm.meeting.pageLayout.accentColor,
              callback: function($$v) {
                _vm.$set(_vm.meeting.pageLayout, "accentColor", $$v)
              },
              expression: "meeting.pageLayout.accentColor"
            }
          }),
          _c(
            "div",
            {
              staticClass: "font-14 text-left font-gray_70",
              staticStyle: { "min-width": "100%" }
            },
            [_vm._v("Background panel color")]
          ),
          _c("color-selector", {
            model: {
              value: _vm.meeting.pageLayout.backgroundColor,
              callback: function($$v) {
                _vm.$set(_vm.meeting.pageLayout, "backgroundColor", $$v)
              },
              expression: "meeting.pageLayout.backgroundColor"
            }
          }),
          _c("div", { staticClass: "font-14 text-left font-gray_70" }, [
            _vm._v("Button color")
          ]),
          _c("color-selector", {
            model: {
              value: _vm.meeting.pageLayout.buttonColor,
              callback: function($$v) {
                _vm.$set(_vm.meeting.pageLayout, "buttonColor", $$v)
              },
              expression: "meeting.pageLayout.buttonColor"
            }
          }),
          _c(
            "div",
            {
              staticClass: "font-14 text-left font-gray_70",
              staticStyle: { "min-width": "100%" }
            },
            [_vm._v("Background color")]
          ),
          _c("color-selector", {
            model: {
              value: _vm.meeting.pageLayout.backgroundMainColor,
              callback: function($$v) {
                _vm.$set(_vm.meeting.pageLayout, "backgroundMainColor", $$v)
              },
              expression: "meeting.pageLayout.backgroundMainColor"
            }
          }),
          _c(
            "div",
            { staticClass: "column-format gap-3" },
            [
              _c(
                "div",
                { staticClass: "wrapper row-format align-center pa-3" },
                [
                  _c("v-switch", {
                    staticStyle: {
                      "margin-bottom": "0px",
                      "margin-top": "0px"
                    },
                    attrs: { label: "Display logo", "hide-details": "" },
                    on: {
                      change: function($event) {
                        return _vm.requestSave()
                      }
                    },
                    model: {
                      value: _vm.meeting.pageLayout.showLogo,
                      callback: function($$v) {
                        _vm.$set(_vm.meeting.pageLayout, "showLogo", $$v)
                      },
                      expression: "meeting.pageLayout.showLogo"
                    }
                  })
                ],
                1
              ),
              _c("v-select", {
                attrs: {
                  items: ["Normal", "Block"],
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                  label: "Button style"
                },
                on: {
                  change: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.meeting.scheduleMeeting.buttonStyle.buttonStyle,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.meeting.scheduleMeeting.buttonStyle,
                      "buttonStyle",
                      $$v
                    )
                  },
                  expression: "meeting.scheduleMeeting.buttonStyle.buttonStyle"
                }
              }),
              _c("v-select", {
                attrs: {
                  items: ["X-Small", "Small", "Normal", "Large", "X-Large"],
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                  label: "Button size"
                },
                on: {
                  change: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.meeting.scheduleMeeting.buttonStyle.buttonSize,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.meeting.scheduleMeeting.buttonStyle,
                      "buttonSize",
                      $$v
                    )
                  },
                  expression: "meeting.scheduleMeeting.buttonStyle.buttonSize"
                }
              }),
              _vm.meeting.scheduleMeeting.buttonStyle.buttonStyle === "Normal"
                ? _c(
                    "div",
                    [
                      _c("v-select", {
                        attrs: {
                          items: ["Left", "Center", "Right"],
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          label: "Button alignment"
                        },
                        on: {
                          change: function($event) {
                            return _vm.updated()
                          }
                        },
                        model: {
                          value:
                            _vm.meeting.scheduleMeeting.buttonStyle
                              .buttonAlignment,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.meeting.scheduleMeeting.buttonStyle,
                              "buttonAlignment",
                              $$v
                            )
                          },
                          expression:
                            "meeting.scheduleMeeting.buttonStyle.buttonAlignment"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("hr")
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "font-gray_90 font-16 text-left ml-3" }, [
        _vm._v("Labels")
      ]),
      _c("div", { staticClass: "row-format gap-2" }, [
        _c(
          "div",
          {
            staticClass: "column-format pl-3 gap-3",
            staticStyle: { "min-width": "100%" }
          },
          [
            _vm.selectedTab === 0
              ? _c(
                  "div",
                  { staticClass: "wrapper row-format align-center" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        "persistent-placeholder": "",
                        "hide-details": "",
                        label: "Confirm text"
                      },
                      model: {
                        value: _vm.meeting.scheduleMeeting.confirmText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.meeting.scheduleMeeting,
                            "confirmText",
                            $$v
                          )
                        },
                        expression: "meeting.scheduleMeeting.confirmText"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedTab === 1
              ? _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        "persistent-placeholder": "",
                        "hide-details": "",
                        label: "Schedule text"
                      },
                      model: {
                        value: _vm.meeting.scheduleMeeting.scheduleText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.meeting.scheduleMeeting,
                            "scheduleText",
                            $$v
                          )
                        },
                        expression: "meeting.scheduleMeeting.scheduleText"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedTab === 2
              ? _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        "persistent-placeholder": "",
                        "hide-details": "",
                        label: "iCal/Outlook Calendar"
                      },
                      model: {
                        value: _vm.meeting.confirmMeeting.addToOtherText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.meeting.confirmMeeting,
                            "addToOtherText",
                            $$v
                          )
                        },
                        expression: "meeting.confirmMeeting.addToOtherText"
                      }
                    }),
                    _c("v-text-field", {
                      attrs: {
                        "persistent-placeholder": "",
                        "hide-details": "",
                        label: "Google calendar"
                      },
                      model: {
                        value: _vm.meeting.confirmMeeting.addToGoogleText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.meeting.confirmMeeting,
                            "addToGoogleText",
                            $$v
                          )
                        },
                        expression: "meeting.confirmMeeting.addToGoogleText"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedTab === 3
              ? _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        "persistent-placeholder": "",
                        "hide-details": "",
                        label: "Cancel meeting"
                      },
                      model: {
                        value: _vm.meeting.cancelMeeting.cancelText,
                        callback: function($$v) {
                          _vm.$set(_vm.meeting.cancelMeeting, "cancelText", $$v)
                        },
                        expression: "meeting.cancelMeeting.cancelText"
                      }
                    }),
                    _c("v-text-field", {
                      attrs: {
                        "persistent-placeholder": "",
                        "hide-details": "",
                        label: "Cancellation reason"
                      },
                      model: {
                        value: _vm.meeting.cancelMeeting.cancellationReasonText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.meeting.cancelMeeting,
                            "cancellationReasonText",
                            $$v
                          )
                        },
                        expression:
                          "meeting.cancelMeeting.cancellationReasonText"
                      }
                    }),
                    _c("v-text-field", {
                      attrs: {
                        "persistent-placeholder": "",
                        "hide-details": "",
                        label: "Post-cancellation text"
                      },
                      model: {
                        value: _vm.meeting.cancelMeeting.postCancellationText,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.meeting.cancelMeeting,
                            "postCancellationText",
                            $$v
                          )
                        },
                        expression: "meeting.cancelMeeting.postCancellationText"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ]),
      _c("hr")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }