var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.notificationSettings
      ? _c("div", { attrs: { id: "settings-table" } }, [
          _c("table", [
            _vm._m(0),
            _c(
              "tbody",
              _vm._l(_vm.notificationTypes, function(type) {
                return _c("tr", { key: type.key }, [
                  _c("td", { staticClass: "label-cell" }, [
                    _vm._v(_vm._s(type.label))
                  ]),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "row-format centered" },
                      [
                        _c("v-checkbox", {
                          attrs: { "hide-details": "", dense: "" },
                          on: { change: _vm.saveNotificationSettings },
                          model: {
                            value: _vm.notificationSettings[type.key].app,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.notificationSettings[type.key],
                                "app",
                                $$v
                              )
                            },
                            expression: "notificationSettings[type.key].app"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "row-format centered" },
                      [
                        _c("v-checkbox", {
                          attrs: { "hide-details": "", dense: "" },
                          on: { change: _vm.saveNotificationSettings },
                          model: {
                            value: _vm.notificationSettings[type.key].push,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.notificationSettings[type.key],
                                "push",
                                $$v
                              )
                            },
                            expression: "notificationSettings[type.key].push"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("td", [
                    _c(
                      "div",
                      { staticClass: "row-format centered" },
                      [
                        _c("v-checkbox", {
                          attrs: { "hide-details": "", dense: "" },
                          on: { change: _vm.saveNotificationSettings },
                          model: {
                            value: _vm.notificationSettings[type.key].email,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.notificationSettings[type.key],
                                "email",
                                $$v
                              )
                            },
                            expression: "notificationSettings[type.key].email"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              }),
              0
            )
          ])
        ])
      : _vm._e(),
    _vm.pushEnabled
      ? _c(
          "div",
          [
            !_vm.existingSubscription
              ? _c(
                  "v-btn",
                  {
                    attrs: { block: "", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.handleSubscribeToPushNotifications()
                      }
                    }
                  },
                  [_vm._v("Enable browser push notifications")]
                )
              : _c(
                  "v-btn",
                  {
                    attrs: { block: "", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.unsubscribe()
                      }
                    }
                  },
                  [_vm._v("Disable browser push notifications")]
                )
          ],
          1
        )
      : _c("div", [_vm._v("Your browser doesn't support push notifications.")])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { "text-align": "left" } }, [
          _vm._v("Event Type")
        ]),
        _c("th", [_vm._v("In App")]),
        _c("th", [_vm._v("Push")]),
        _c("th", [_vm._v("Email")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }