var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-2 py-3 text-left" },
    [
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          type: "number",
          label: "Padding"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.padding,
          callback: function($$v) {
            _vm.$set(_vm.element, "padding", $$v)
          },
          expression: "element.padding"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Margin top"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pt,
              callback: function($$v) {
                _vm.$set(_vm.element, "pt", $$v)
              },
              expression: "element.pt"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Margin bottom"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pb,
              callback: function($$v) {
                _vm.$set(_vm.element, "pb", $$v)
              },
              expression: "element.pb"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Margin left"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pl,
              callback: function($$v) {
                _vm.$set(_vm.element, "pl", $$v)
              },
              expression: "element.pl"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Margin right"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pr,
              callback: function($$v) {
                _vm.$set(_vm.element, "pr", $$v)
              },
              expression: "element.pr"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Border width"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.borderWidth,
              callback: function($$v) {
                _vm.$set(_vm.element, "borderWidth", $$v)
              },
              expression: "element.borderWidth"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Border radius"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.borderRadius,
              callback: function($$v) {
                _vm.$set(_vm.element, "borderRadius", $$v)
              },
              expression: "element.borderRadius"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "row-format text-left gap-1" }, [
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", [_vm._v("Border color")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.element.borderColor,
                callback: function($$v) {
                  _vm.$set(_vm.element, "borderColor", $$v)
                },
                expression: "element.borderColor"
              }
            })
          ],
          1
        )
      ]),
      _c("v-autocomplete", {
        attrs: {
          "persistent-placeholder": "",
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Embedded form",
          items: _vm.templateList
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.attachedFormId,
          callback: function($$v) {
            _vm.$set(_vm.element, "attachedFormId", $$v)
          },
          expression: "element.attachedFormId"
        }
      }),
      _c("v-switch", {
        attrs: {
          "hide-details": "",
          dense: "",
          "persistent-hint": "",
          label: "Merge client data"
        },
        model: {
          value: _vm.element.mergeClientData,
          callback: function($$v) {
            _vm.$set(_vm.element, "mergeClientData", $$v)
          },
          expression: "element.mergeClientData"
        }
      }),
      _c("div", { staticClass: "font-12 font-gray_80" }, [
        _vm._v(
          "When this option is toggled on, any mapped field values will be merged to the client / contact record upon submission."
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }