import HttpClient from "@/services/HttpClient";

export default class EmailTemplateService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getEmailTemplates() {
		return this.httpClient
			.get('/templates/email')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEmailTemplate(id) {
		return this.httpClient
			.get(`/templates/email/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	renderEmailTemplate(id,request) {
		return this.httpClient
			.post(`/templates/email/${id}/render`,request)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createEmailTemplate(template) {
		return this.httpClient
			.post('/templates/email', template)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateEmailTemplate(id, template) {
		return this.httpClient
			.put(`/templates/email/${id}`, template)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteEmailTemplate(id) {
		return this.httpClient
			.delete(`/templates/email/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	duplicateEmailTemplate(id) {
		return this.httpClient
			.post(`/templates/email/${id}/duplicate`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	addFileAttachments(id,attachments){
		let formData = new FormData();

		attachments.forEach(a => {
			formData.append('attachments',a);
		})

		return this.httpClient
			.post(`/templates/email/${id}/files`,formData,{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteFileAttachment(id, fileName) {
		return this.httpClient
			.delete(`/templates/email/${id}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(id, fileName) {
		return this.httpClient
			.get(`/templates/email/${id}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
