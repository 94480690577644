import HttpClient from '@/services/HttpClient';

export default class LeadGenService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getCards() {
		console.log('running getCards()');
		return this.httpClient
			.get('/leadGen?version=2')
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	archive(id, collectionName, archive = true) {
		if (['discoveryRequest', 'project', 'client', 'proposal'].indexOf(collectionName) === -1) {
			console.log('collectionName not supported', collectionName);
		}

		return this.httpClient
			.post(`/leadGen/archive?id=${id}&collectionName=${collectionName}&archive=${archive}`)
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}
}
