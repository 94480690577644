<template>
	<div class="column-format gap-3">
		<font-selector label="Font" :font="element.font" @font-changed="fontUpdated($event)"></font-selector>

		<div class="column-format">
			<div class="font-14 text-left font-gray_70">Background color</div>
			<v-menu :close-on-click="false" :close-on-content-click="false" v-model="backgroundColorMenu" bottom>
				<template v-slot:activator="{ on }">
					<div
						v-on="on"
						:style="
							`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.backgroundColor}`
						"
						class="pointer"
					>
						&nbsp;
					</div>
				</template>
				<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
					<v-color-picker
						mode="hexa"
						v-model="element.backgroundColor"
						show-swatches
						swatches-max-height="150px"
						@input="updated()"
					></v-color-picker>
					<v-btn @click="backgroundColorMenu = false" block>OK</v-btn>
				</div>
			</v-menu>

			<div class="font-14 text-left font-gray_70 mt-1">Page color</div>
			<v-menu :close-on-click="false" :close-on-content-click="false" v-model="pageColorMenu" bottom>
				<template v-slot:activator="{ on }">
					<div
						v-on="on"
						:style="
							`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.pageColor}`
						"
						class="pointer"
					>
						&nbsp;
					</div>
				</template>
				<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
					<v-color-picker
						mode="hexa"
						v-model="element.pageColor"
						show-swatches
						swatches-max-height="150px"
						@input="updated()"
					></v-color-picker>
					<v-btn @click="pageColorMenu = false" block>OK</v-btn>
				</div>
			</v-menu>

			<div class="font-14 text-left ont-gray_70 mt-1">Font color</div>
			<v-menu :close-on-click="false" :close-on-content-click="false" v-model="fontColorMenu" bottom>
				<template v-slot:activator="{ on }">
					<div
						v-on="on"
						:style="
							`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.fontColor}`
						"
						class="pointer"
					>
						&nbsp;
					</div>
				</template>
				<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
					<v-color-picker
						mode="hexa"
						v-model="element.fontColor"
						show-swatches
						swatches-max-height="150px"
						@input="updated()"
					></v-color-picker>
					<v-btn @click="fontColorMenu = false" block>OK</v-btn>
				</div>
			</v-menu>
		</div>
		<div class="font-14 text-left mb-n3 font-gray_70">Page max width ({{ element.maxWidth }}px)</div>
		<v-slider
			v-model="element.maxWidth"
			:min="0"
			:max="1200"
			:step="10"
			hide-details
			dense
			@input="updated"
			thumb-label
		></v-slider>
	</div>
</template>

<script>
	import FontSelector from '@/components/FontSelector';

	export default {
		name: 'InvoiceStyleConfig',

		props: ['font', 'styles'],

		components: { FontSelector },

		data: function() {
			return {
				lFont: this.font,
				pageColorMenu: false,
				fontColorMenu: false,
				backgroundColorMenu: false,
				element: { ...this.styles },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			fontUpdated: function(font) {
				this.element.font = font;
				this.updated();
			},

			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
