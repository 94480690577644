var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section-wrapper row-format align-center",
      on: {
        click: function($event) {
          return _vm.$emit("selected")
        },
        mouseover: function($event) {
          _vm.hovering = true
        },
        mouseout: function($event) {
          _vm.hovering = false
        }
      }
    },
    [
      _c("h-icon2", {
        attrs: { size: "20", name: _vm.icon, active: _vm.hovering }
      }),
      _c(
        "div",
        {
          class:
            "brand-medium ml-2 " +
            (_vm.hovering ? "font-primary" : "font-gray_80")
        },
        [_vm._v(_vm._s(_vm.name))]
      ),
      _c(
        "div",
        { staticClass: "ml-auto arrow" },
        [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$arrowRight")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }