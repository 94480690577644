<template>
	<div id="ai-chat-alert">
		<v-btn icon class="close-dialog" @click="$emit('result')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding text-left column-format gap-3">
			<h1 class="modal-title">✨Moxie Assistant</h1>
			<div>
				Moxie Assistant leverages cutting-edge AI technology to simplify your daily tasks, providing you with intelligent
				insights, efficient summarization, advanced writing support, and conversational configuration. Discover how Moxie
				Assistant can transform the way you work.
			</div>
			<youtube
				style="background-color: var(--v-black-base); padding-top: 4px"
				:player-vars="{ autoplay: 0 }"
				id="player"
				video-id="zlHHDCCSM7A"
				:player-width="840"
				:player-height="462"
			></youtube>
			<div class="row-format centered">
				<div v-if="isInTrial && isAiIncluded" class="font-italic">
					Looks like you have used up your free trial AI credits.  Please subscribe to one of the paid Moxie plans to continue using this feature.
				</div>
				<v-btn v-else-if="isAccountOwner && !isAiIncluded" class="super-action" large block @click="routeToSub"
					>Add Moxie Assistant to your workspace</v-btn
				>
				<div v-else class="font-italic">Please contact the owner of this workspace to get access to this feature.</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AiLicenseAlert',

		props: [],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			routeToSub: function() {
				this.$store.state.globalModalController.forceCloseAll();
				setTimeout(() => this.$router.push('/subscription/add-ons'), 500);
				this.$emit('result');
			},
		},

		computed: {
			isInTrial: function(){
				return this.$store.getters.isInTrial;
			},

			isAiIncluded: function(){
				return this.$store.state.selectedUserAccount.account.pricingVersion >= 3;
			},

			isAccountOwner: function() {
				return this.$store.getters.isAccountOwner;
			},
		},
	};
</script>

<style scoped lang="scss">
	#ai-chat-alert {
		background-color: var(--v-white-base);
		max-width: 892px;
	}
</style>
