<template>
	<div class="text-left" v-if="ourThread">
		<div class="row-format">
			<div>
				<v-btn icon @click="$emit('back')"><v-icon size="24" color="black">arrow_back</v-icon></v-btn>
			</div>
			<div class="ml-auto row-format align-center">
				<div class="mr-2" v-if="ourFolder !== 'Inbox'">
					{{ ourFolder }}
					<span v-if="ourFolder === 'Snoozed'"
						>until {{ HDateTime.fromISO(ourThread.snoozedUntil).toLocaleString(HDateTime.DATETIME_MED) }}</span
					>
				</div>
				<inbox-actions
					:size="24"
					@return-to-inbox="returnToInbox()"
					@snooze="snooze($event)"
					@archive="archive()"
					@unread="markThreadAsRead()"
					:unread="ourThread.unread"
					:snooze-options="snoozeOptions"
					:folder="ourFolder"
					item-type="EmailThread"
					:item-id="ourThread.id"
				></inbox-actions>
			</div>
		</div>

		<div class="column-format py-2 mt-2">
			<div class="font-18 brand-medium row-format align-center">
				<div v-if="!clientId">
					<client-avatar class="mr-1" v-for="client in clients" :key="client.id" :client="client" small></client-avatar>
				</div>
				<div>{{ ourThread.subject }}</div>
				<v-btn  small text color="primary" class="ml-auto" @click="generateSummary(false)">✨Summary</v-btn>
				<v-icon v-if="showSummary && summary" class="pointer" small color="primary" @click="generateSummary(true)"
					>refresh</v-icon
				>
			</div>
			<div v-if="summary && showSummary" class="mt-3 email-summary" v-html="marked(summary, { breaks: true })"></div>
		</div>

		<div class="">
			<div v-for="(email, index) in displayEmails" :key="email.id">
				<div v-if="email.type === 'PLACEHOLDER'" @click="showExpanded = true">
					<div class="expand-row">
						<div class="expand-count row-format centered">
							<div>{{ email.count }}</div>
						</div>
					</div>
				</div>
				<email
					class="email-wrapper py-4"
					v-else
					ref="Email"
					:email="email"
					:thread="ourThread"
					:default-open="index === displayEmails.length - 1"
					:user-emails="userEmails"
					:email-boxes="emailBoxes"
					:opportunity-id="opportunityId"
					@email-sent="emailSent($event)"
				></email>
			</div>
		</div>
	</div>
</template>

<script>
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import Email from '@/modules/communicator/inbox/email/Email';
	import InboxActions from '@/modules/communicator/inbox/InboxActions';
	import HDateTime from '@/modules/utils/HDateTime';
	import EmailMixin from '@/modules/communicator/inbox/email/EmailMixin';
	import ClientAvatar from '@/components/ClientAvatar';
	import AiAssistantService from '@/modules/ai/AiAssistantService';
	import marked from 'marked';

	export default {
		name: 'EmailThreadDetail',

		props: ['thread', 'userEmails', 'snoozeOptions', 'folder', 'inModal', 'clientId', 'emailBoxes', 'opportunityId'],

		components: { InboxActions, Email, ClientAvatar },

		mixins: [EmailMixin],

		data: function() {
			return {
				marked: marked,
				HDateTime: HDateTime,
				communicatorService: new CommunicatorEmailService(),
				showExpanded: false,
				emails: [],
				collapsedCount: 3,
				summary: null,
				showSummary: false,
			};
		},

		mounted() {
			this.ourThread = { ...this.thread };
			this.ourFolder = this.folder;

			if (this.ourThread.summary) {
				this.summary = this.ourThread.summary;
			}

			this.getEmails();
			if (this.ourThread.unread) {
				this.markThreadAsRead();
			}
		},

		beforeDestroy() {},

		methods: {
			getEmails: function() {
				this.communicatorService.getEmailsInThread(this.ourThread.id).then((res) => {
					this.emails.splice(0, this.emails.length);
					this.emails.push(...res.data);
				});
			},

			async generateSummary(force = false) {
				if (this.summary && !force) {
					this.showSummary = !this.showSummary;
					return;
				}

				try {
					this.$store.commit('startLoading');
					let createRequest = {
						model: 'gpt-4o-mini',
						useCase: 'Summary',
						context: [],
						fetchResponse: true,
					};

					createRequest.context.push({
						role:'system',
						content:'You should provide a clear and concise summarization of the data provided by the user. Format the response with clear sections, bold headers, and properly formatted code blocks using markdown.'
					});

					for (let i = 0; i < this.$refs.Email.length; i++) {
						let emailDetail = await this.$refs.Email[i].getEmailDetail();
						let content;
						if (emailDetail.textContent) {
							content = emailDetail.textContent;
						} else {
							content = emailDetail.htmlContent;
						}
						createRequest.context.push({
							role:'user',
							content:content
						});
					}

					createRequest.context.push({
						role: 'user',
						content: 'Please summarize this email thread.  Include sections for participants in the email conversation, topic, important dates, and a high level summary.  Only output the result, no other content.'
					});

					let result = await new AiAssistantService().createChatSession(createRequest);
					let aiSession = result.data;
					let lastMessage = aiSession.messages[aiSession.messages.length - 1];
					this.summary = lastMessage.content;
					this.showSummary = true;
					this.ourThread.summary = this.summary;

					this.communicatorService.updateSummary(this.ourThread.id, this.summary);
					this.$emit('email-thread-updated', this.ourThread);
				} catch (err) {
					this.$store.commit('error', 'There was an error processing the request: ' + err.response?.data?.message);
				} finally {
					this.$store.commit('stopLoading');
				}
			},

			emailSent: function(result) {
				this.$emit('email-thread-updated', result.thread);
				this.ourThread = result.thread;
				this.emails.push(result.email);
			},
		},

		watch: {
			contacts: function(val) {
				this.$emit('set-contacts', val);
			},
		},

		computed: {
			clients: function() {
				if (this.ourThread) {
					let participants = this.ourThread.participants.filter(
						(p) => !this.userEmails.includes(p.address.toLowerCase())
					);
					let result = new Set();
					participants.forEach((p) => {
						let contact = this.$store.getters.getContactByEmail(p.address);
						if (contact && contact.clientId) {
							let client = this.$store.state.clientCache.find((c) => c.id === contact.clientId);
							if (client) {
								result.add(client);
							}
						}
					});
					return Array.from(result);
				} else {
					return [];
				}
			},

			contacts: function() {
				let result = [];

				if (!this.ourThread || !this.ourThread.participants) {
					return result;
				}

				this.ourThread.participants.forEach((p) => {
					if (this.userEmails.includes(p.address)) {
						return;
					}

					let contact = this.$store.getters.getContactByEmail(p.address);

					if (contact) {
						result.push(contact);
					} else {
						let first = null;
						let last = null;
						if (p.personal) {
							let parts = p.personal.split(' ');
							first = parts[0];
							last = parts.length > 1 ? parts[1] : null;
						}
						result.push({
							id: null,
							email: p.address,
							phone: null,
							firstName: first,
							lastName: last,
						});
					}
				});
				return result;
			},

			displayEmails: function() {
				let result = [...this.emails];
				if (result.length > this.collapsedCount && !this.showExpanded) {
					let removeCount = result.length - this.collapsedCount;
					result.splice(this.collapsedCount - 1, removeCount);
					result.splice(this.collapsedCount - 1, 0, {
						type: 'PLACEHOLDER',
						count: removeCount,
					});
					return result;
				} else {
					return result;
				}
			},
		},
	};
</script>

<style lang="scss">
	.email-summary {
		font-size: 14px;
		p {
			margin-bottom: 4px !important;
		}
		h1, h2, h3 {
			margin-top: 8px;
			font-weight: 500;
			font-size: 16px;
		}
	}
</style>

<style scoped lang="scss">
	.email-wrapper {
		overflow-x: auto;
		width: 100%;
		border-top: 1px solid var(--v-gray_20-base);
	}

	.expand-row {
		width: 100%;
		height: 10px;
		//border-top: 1px solid var(--v-gray_30-base);
		border-top: 1px solid var(--v-gray_20-base);
		position: relative;
		cursor: pointer;
	}

	.expand-count {
		width: 30px;
		height: 30px;
		border-radius: 15px;
		font-size: 14px;
		font-weight: 600;
		background-color: var(--v-gray_20-base);
		color: var(--v-gray_70-base);
		position: absolute;
		left: 10px;
		top: -10px;
		cursor: pointer;
	}
</style>
