<template>
	<div id="add-content-dialog">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h2 class="modal-title">Add content block</h2>
			<div class="modal-subtitle">
				Select a type of content block. It will appear at the bottom of the page and then you can drag to re-order it.
			</div>
			<v-container fluid class="mt-3 mb-2 ml-0 pl-0">
				<v-row>
					<v-col cols="12">
						<div class="header text-left mb-2">Text</div>
						<div class="row-format">
							<div class="pointer content-wrapper" @click="addItem('TextBlock')">
								<div class="iconBox column-format centered">
									<v-icon size="60">$text</v-icon>
								</div>
								<div class="instructions">Header and<br />paragraph</div>
							</div>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<div class="header text-left mb-2 mt-4">Lead capture</div>
						<div class="row-format">
							<div class="pointer content-wrapper mr-4" @click="addItem('LeadContact')">
								<div class="iconBox column-format centered">
									<v-icon size="60">$clients</v-icon>
								</div>
								<div class="instructions">Name & contact</div>
							</div>
							<!--div class="pointer content-wrapper" @click="addItem('LeadInfo')">
								<div class="iconBox column-format centered">
									<v-icon size="60">$info</v-icon>
								</div>
								<div class="instructions">Business info</div>
							</div-->
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<div class="header text-left mb-2 mt-4">Questions / Input</div>
						<div class="row-format gap-2">
							<div class="pointer content-wrapper" @click="addItem('TextInput')">
								<div class="iconBox column-format centered">
									<v-icon size="60">$questionMark</v-icon>
								</div>
								<div class="instructions text-center">Open-ended<br />answer</div>
							</div>
							<div class="pointer content-wrapper" @click="addItem('SelectInput')">
								<div class="iconBox column-format centered">
									<v-icon size="60">$multipleAnswers</v-icon>
								</div>
								<div class="instructions">Select, Checkbox, <br />Radio answer</div>
							</div>
							<div class="pointer content-wrapper" @click="addItem('DateInput')">
								<div class="iconBox column-format centered">
									<v-icon size="60">$calendar</v-icon>
								</div>
								<div class="instructions">Date</div>
							</div>
							<div class="pointer content-wrapper" @click="addItem('FileInput')">
								<div class="iconBox column-format centered">
									<v-icon size="60">$attachment</v-icon>
								</div>
								<div class="instructions">File upload</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	import { v4 as uuidv4 } from 'uuid';

	export default {
		name: 'AddContentDialog',

		props: [],

		components: {},

		data: function() {
			return {
				uuidv4: uuidv4,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addItem: function(type) {
				let newItem = {};
				newItem.id = uuidv4();
				newItem.fieldType = type;
				newItem.options = [''];
				newItem.hasOther = false;
				newItem.htmlText = '';
				newItem.placeHolder = type == 'TextBlock' ? 'Enter text here...' : 'Enter question here..';
				newItem.headerPlaceholder = 'Enter header here';
				newItem.textPlaceholder = 'Enter text here';

				if (type === 'LeadContact') {
					newItem.options.splice(0, 1);
					newItem.options.push(...['BusinessName', 'ContactName', 'Phone', 'Email']);
				}

				this.$emit('add-item', newItem);
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	#add-content-dialog {
		width: 500px;
		.content-wrapper {
			color: var(--v-gray_70-base);
			.iconBox {
				min-height: 100px;
				min-width: 100px;
				max-width: 100px;
				max-height: 100px;
				background: var(--v-gray_30-base);
				border-radius: 4px;
			}
			svg {
				--secondary-opacity: 0.3;
				--secondary-color: var(--v-gray_80-base);
			}
			&:hover {
				color: var(--v-gray_90-base);

				svg {
					fill: var(--v-primary-base);
					--secondary-opacity: 1;
					--secondary-color: var(--v-accent-base);
				}
			}
			.instructions {
				font-size: 12px;
				margin-top: 4px;
				//color: var(--v-gray_70-base);
			}
		}

		.headerBox {
			border-bottom: 1px solid var(--v-gray_50-base);
			padding: 0px 20px 0px 20px;
			margin-bottom: 12px;
		}

		.header {
			font-size: 16px;
		}
	}
</style>
