var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.expenseCopy
    ? _c(
        "div",
        { attrs: { id: "expense-detail" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-padding" },
            [
              _c("h3", { staticClass: "modal-title" }, [
                _vm._v(
                  _vm._s(_vm.expenseHeading) +
                    " - " +
                    _vm._s(
                      _vm.$formatters.dollars(
                        _vm.total,
                        true,
                        true,
                        _vm.expenseCopy.currency
                      )
                    )
                )
              ]),
              _c(
                "v-container",
                { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", sm: "7" } }, [
                        _c(
                          "div",
                          { staticClass: "column-format gap-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "row-format gap-2" },
                              [
                                _c("amount-input", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    "hide-details": "",
                                    label: _vm.$t("expenses.amount-label"),
                                    placeholder: _vm.$t(
                                      "expenses.amount-placeholder"
                                    ),
                                    prefix: _vm.currencySymbol,
                                    type: "text",
                                    disabled:
                                      _vm.invoicedDisabled ||
                                      _vm.isPlaidExpense ||
                                      _vm.isLockedExpense,
                                    "persistent-placeholder": ""
                                  },
                                  model: {
                                    value: _vm.expenseCopy.amount,
                                    callback: function($$v) {
                                      _vm.$set(_vm.expenseCopy, "amount", $$v)
                                    },
                                    expression: "expenseCopy.amount"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    "hide-details": "",
                                    label: "Tax rate",
                                    placeholder: "Tax %",
                                    suffix: "%",
                                    type: "number",
                                    disabled: _vm.invoicedDisabled,
                                    "persistent-placeholder": ""
                                  },
                                  model: {
                                    value: _vm.expenseCopy.taxRate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.expenseCopy, "taxRate", $$v)
                                    },
                                    expression: "expenseCopy.taxRate"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "row-format gap-2" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { width: "47%" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        "hide-details": "",
                                        "persistent-placeholder": "",
                                        dense: "",
                                        outlined: "",
                                        label: _vm.$t("account.currency"),
                                        items: _vm.currencies,
                                        "item-text": "value",
                                        "item-value": "value",
                                        disabled:
                                          _vm.invoicedDisabled ||
                                          _vm.isPlaidExpense ||
                                          _vm.isLockedExpense
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _vm._v(
                                                  " " + _vm._s(item.label) + " "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1159317991
                                      ),
                                      model: {
                                        value: _vm.expenseCopy.currency,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.expenseCopy,
                                            "currency",
                                            $$v
                                          )
                                        },
                                        expression: "expenseCopy.currency"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("v-checkbox", {
                                  attrs: {
                                    disabled: _vm.expenseCopy.taxRate === 0,
                                    "hide-details": "",
                                    dense: "",
                                    label: "Tax inclusive"
                                  },
                                  model: {
                                    value: _vm.expenseCopy.taxInclusive,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.expenseCopy,
                                        "taxInclusive",
                                        $$v
                                      )
                                    },
                                    expression: "expenseCopy.taxInclusive"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.expenseCopy.currency !==
                            _vm.$store.state.defaultCurrency
                              ? _c("v-text-field", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": "",
                                    "hide-details": "",
                                    label: "Exchange rate (optional)",
                                    type: "number",
                                    step: "0.00001"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-12 font-gray_70 text-left pr-2"
                                              },
                                              [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      _vm.$formatters.dollars(
                                                        _vm.expenseCopy
                                                          .localAmount
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.$store.state
                                                        .defaultCurrency
                                                    ) +
                                                    ")"
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    2283106566
                                  ),
                                  model: {
                                    value: _vm.expenseCopy.exchangeRate,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.expenseCopy,
                                        "exchangeRate",
                                        $$v
                                      )
                                    },
                                    expression: "expenseCopy.exchangeRate"
                                  }
                                })
                              : _vm._e(),
                            _c("v-textarea", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                focus: "",
                                "hide-details": "",
                                label: _vm.$t("expenses.description-label"),
                                placeholder: _vm.$t(
                                  "expenses.description-placeholder"
                                ),
                                "auto-grow": true,
                                rows: "1",
                                "persistent-placeholder": ""
                              },
                              model: {
                                value: _vm.expenseCopy.description,
                                callback: function($$v) {
                                  _vm.$set(_vm.expenseCopy, "description", $$v)
                                },
                                expression: "expenseCopy.description"
                              }
                            }),
                            _c("v-autocomplete", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("expenses.category-label"),
                                placeholder: _vm.$t(
                                  "expenses.category-placeholder"
                                ),
                                items: _vm.expenseCategoriesPlusClear,
                                "item-text": "category",
                                "item-label": "category"
                              },
                              on: { change: _vm.categoryChanged },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "row-format",
                                            staticStyle: { width: "100%" }
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(item.category))
                                            ]),
                                            item.custom
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ml-auto pointer",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.confirmCategoryDelete(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" x ")]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3978414514
                              ),
                              model: {
                                value: _vm.expenseCopy.category,
                                callback: function($$v) {
                                  _vm.$set(_vm.expenseCopy, "category", $$v)
                                },
                                expression: "expenseCopy.category"
                              }
                            }),
                            _c("v-autocomplete", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("expenses.vendor-label"),
                                placeholder: _vm.$t(
                                  "expenses.vendor-placeholder"
                                ),
                                items: _vm.vendorsPlusClear,
                                "item-text": "name",
                                "item-value": "id"
                              },
                              on: { change: _vm.vendorChanged },
                              model: {
                                value: _vm.expenseCopy.vendorId,
                                callback: function($$v) {
                                  _vm.$set(_vm.expenseCopy, "vendorId", $$v)
                                },
                                expression: "expenseCopy.vendorId"
                              }
                            }),
                            _c("v-select", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("expenses.paid-question"),
                                placeholder: _vm.$t("expenses.paid-question"),
                                items: _vm.paidOptions,
                                disabled:
                                  _vm.isPlaidExpense || _vm.isLockedExpense
                              },
                              on: {
                                value: function($event) {
                                  return _vm.togglePaid($event)
                                }
                              },
                              model: {
                                value: _vm.expenseCopy.paid,
                                callback: function($$v) {
                                  _vm.$set(_vm.expenseCopy, "paid", $$v)
                                },
                                expression: "expenseCopy.paid"
                              }
                            }),
                            _vm.expenseCopy.paid
                              ? _c("date-selector", {
                                  attrs: {
                                    clearable: "",
                                    "hide-details": "",
                                    standard: true,
                                    "persistent-placeholder": "",
                                    label: _vm.$t("expenses.date"),
                                    disabled:
                                      _vm.isPlaidExpense || _vm.isLockedExpense,
                                    date: _vm.expenseCopy.paidDate
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.expenseCopy.paidDate = $event
                                    }
                                  }
                                })
                              : _c("date-selector", {
                                  attrs: {
                                    standard: true,
                                    clearable: "",
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    label: _vm.$t("expenses.due-date"),
                                    disabled:
                                      _vm.isPlaidExpense || _vm.isLockedExpense,
                                    date: _vm.expenseCopy.dueDate
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.expenseCopy.dueDate = $event
                                    }
                                  }
                                }),
                            _c("v-textarea", {
                              staticClass: "mt-2",
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                label: _vm.$t("expenses.notes-label"),
                                "auto-grow": true,
                                rows: "3",
                                "persistent-placeholder": ""
                              },
                              model: {
                                value: _vm.expenseCopy.notes,
                                callback: function($$v) {
                                  _vm.$set(_vm.expenseCopy, "notes", $$v)
                                },
                                expression: "expenseCopy.notes"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.expenseCopy.plaidAccountName
                          ? _c("div", { staticClass: "font-gray_70" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("expenses.source-account")) +
                                  ": " +
                                  _vm._s(_vm.expenseCopy.plaidAccountName) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c("v-col", { attrs: { cols: "12", sm: "5" } }, [
                        _c(
                          "div",
                          { staticClass: "column-format gap-3" },
                          [
                            _c("v-autocomplete", {
                              ref: "clientId",
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("client.client"),
                                placeholder: _vm.$t("expenses.link-to-client"),
                                disabled:
                                  _vm.client != null || _vm.invoicedDisabled,
                                items: _vm.clientsPlusClear,
                                "item-text": "name",
                                "item-value": "id"
                              },
                              on: { change: _vm.clientChanged },
                              model: {
                                value: _vm.expenseCopy.clientId,
                                callback: function($$v) {
                                  _vm.$set(_vm.expenseCopy, "clientId", $$v)
                                },
                                expression: "expenseCopy.clientId"
                              }
                            }),
                            _vm.expenseCopy.clientId
                              ? _c(
                                  "div",
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        "hide-details": "",
                                        "persistent-placeholder": "",
                                        label: _vm.$t("projects.project"),
                                        placeholder: _vm.$t(
                                          "expenses.link-to-project"
                                        ),
                                        disabled:
                                          _vm.noClientDisabled ||
                                          _vm.invoicedDisabled,
                                        items: _vm.clientProjectsPlusClear,
                                        "item-text": "name",
                                        "item-value": "id",
                                        "no-data-text":
                                          "No projects for selected client"
                                      },
                                      on: { change: _vm.projectChanged },
                                      model: {
                                        value: _vm.expenseCopy.projectId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.expenseCopy,
                                            "projectId",
                                            $$v
                                          )
                                        },
                                        expression: "expenseCopy.projectId"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "h-outline d-flex justify-start align-center font-14 px-2 py-1 mb-2"
                                      },
                                      [
                                        _c("v-simple-checkbox", {
                                          attrs: {
                                            color: "var(--v-primary-base)"
                                          },
                                          model: {
                                            value: _vm.expenseCopy.reimbursable,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.expenseCopy,
                                                "reimbursable",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "expenseCopy.reimbursable"
                                          }
                                        }),
                                        _c(
                                          "label",
                                          { staticClass: "font-gray_90" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "expenses.reimburse-status"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.expenseCopy.reimbursable
                                      ? _c(
                                          "div",
                                          [
                                            _c("amount-input", {
                                              attrs: {
                                                dense: "",
                                                outlined: "",
                                                "hide-details": "",
                                                label:
                                                  "Markup percentage to client",
                                                suffix: "%",
                                                type: "number",
                                                disabled:
                                                  _vm.invoicedDisabled ||
                                                  _vm.isLockedExpense,
                                                "persistent-placeholder": ""
                                              },
                                              model: {
                                                value:
                                                  _vm.expenseCopy.markupPercent,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.expenseCopy,
                                                    "markupPercent",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "expenseCopy.markupPercent"
                                              }
                                            }),
                                            _vm.expenseCopy.markupPercent
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "body-12 mt-2 mb-3 gray_80--text text-left"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " +" +
                                                        _vm._s(
                                                          _vm.$formatters.dollars(
                                                            _vm.markup,
                                                            true,
                                                            true,
                                                            _vm.expenseCopy
                                                              .currency
                                                          )
                                                        ) +
                                                        " markup = " +
                                                        _vm._s(
                                                          _vm.$formatters.dollars(
                                                            _vm.totalWithMarkup,
                                                            true,
                                                            true,
                                                            _vm.expenseCopy
                                                              .currency
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.expenseCopy.id &&
                                    _vm.expenseCopy.reimbursable
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "body-12 mt-2 mb-3 gray_80--text text-left"
                                          },
                                          [
                                            _vm.expenseCopy.invoiceId &&
                                            _vm.expenseCopy.invoiceNumber
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "expenses.invoiced-label",
                                                          {
                                                            invoiceNumber:
                                                              _vm.expenseCopy
                                                                .invoiceNumber
                                                          }
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "expenses.not-yet-invoiced"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.expenseCopy.id !== null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mt-3 pt-3",
                                    staticStyle: {
                                      "border-top":
                                        "1px solid var(--v-gray_30-base)"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-left gray_80--text"
                                      },
                                      [_vm._v("Attachments")]
                                    ),
                                    _c("file-attachments", {
                                      attrs: {
                                        files: _vm.expenseCopy.attachments,
                                        "file-service": _vm.attachmentService
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mt-2 pa-2 row-format centered gap-1",
              staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "super-action",
                  attrs: {
                    width: _vm.$vuetify.breakpoint.smAndUp ? "160" : "128"
                  },
                  on: { click: _vm.saveExpense }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          _vm.expenseCopy.id === null
                            ? "global.create"
                            : "global.save"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _vm.expenseCopy.id && !_vm.expenseCopy.invoiceId
                ? _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: { click: _vm.confirmDeleteExpense }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "20" }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }