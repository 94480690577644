import HttpClient from '@/services/HttpClient';
import store from '@/store';
import AiLicenseAlert from '@/modules/ai/AiLicenseAlert';

export default class ExecutiveSummaryService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getExecutiveSummaries() {
		return this.httpClient
			.get(`/dashboard/executiveSummary`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSettings() {
		return this.httpClient
			.get(`/dashboard/executiveSummary/settings`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateSettings(settings) {
		return this.httpClient
			.put(`/dashboard/executiveSummary/settings`,settings)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getExecutiveSummary(id){
		return this.httpClient
			.get(`/dashboard/executiveSummary/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getExecutiveSummaryChatSession(id){
		if (!this.verifyEntitlement()) {
			return Promise.reject({
				response: {
					data: {
						message: 'No Moxie Assistant License',
					},
				},
			});
		}
		return this.httpClient
			.get(`/dashboard/executiveSummary/${id}/chatSession`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	verifyEntitlement() {
		if (store.getters.isAiAvailable) {
			return true;
		} else {
			store.state.globalModalController.openModal(AiLicenseAlert);
			return false;
		}
	}
}
