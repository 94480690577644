<template>
	<div id="settings-modal" class="row-format">
		<div class="settings-left-nav">
			<div
				v-for="view in views"
				:key="view.value"
				:class="`item ${view.value === currentView ? 'active' : ''}`"
				@click="setView(view.value)"
			>
				<div>{{ view.label }}</div>
			</div>
		</div>
		<div class="settings-content">
			<div class="header-bar row-format align-center px-4">
				<div class="row-format align-center">
					<v-icon small color="gray_90">$settings</v-icon>
					<div class="font-gray_90 ml-2 brand-medium">Settings / {{ selectedView.label }}</div>
				</div>
				<v-btn class="ml-auto" @click="$emit('result')" icon><v-icon>$close</v-icon></v-btn>
			</div>

			<div class="content show-scrollbar pb-8">
				<account v-if="currentView === 'account'" @close="$emit('result')"></account>
				<connected-apps
					v-if="currentView === 'connected-apps'"
					:full-user="isAccountFullUser"
					:owner="isAccountOwner"
					@close="$emit('result')"
				></connected-apps>
				<stripe v-if="currentView === 'stripe'" @close="$emit('result')"></stripe>
				<import v-if="currentView === 'data-import'" @close="$emit('result')"></import>
				<preferences v-if="currentView === 'preferences'" @close="$emit('result')"></preferences>
				<account-users v-if="currentView === 'team-members'" @close="$emit('result')"></account-users>
			</div>
		</div>
	</div>
</template>

<script>
	import Account from '@/modules/account/Account';
	import ConnectedApps from '@/modules/account/apps/ConnectedApps';
	import Import from '@/modules/account/import/Import';
	import AccountUsers from '@/modules/users/AccountUsers';
	import Stripe from '@/modules/integrations/stripe/Stripe';
	import Preferences from '@/modules/account/Preferences';

	export default {
		name: 'Settings',

		props: ['focus'],

		components: { Preferences, Stripe, Account, ConnectedApps, Import, AccountUsers },

		data: function () {
			return {
				currentView: 'account',
			};
		},

		mounted() {
			if (this.focus) {
				this.currentView = this.focus;
			}
		},

		beforeDestroy() {},

		methods: {
			setView: function (view) {
				this.currentView = view;
			},
		},

		computed: {
			selectedView: function () {
				return this.views.find((v) => v.value === this.currentView);
			},

			isAccountOwner: function () {
				return this.$store.getters.isAccountOwner;
			},

			isAccountFullUser: function () {
				return this.$store.getters.isAccountFullUser;
			},

			views: function () {
				let result = [
					{ label: 'Account settings', value: 'account' },
					{ label: 'Preferences', value: 'preferences' },
					{ label: 'Connected apps', value: 'connected-apps' },
				];

				if (this.isAccountFullUser) {
					result.push({ label: 'Stripe integration', value: 'stripe' });
					result.push({ label: 'Team members', value: 'team-members' });
					result.push({ label: 'Data import', value: 'data-import' });
				}

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	#settings-modal {
		height: 100%;

		.settings-left-nav {
			width: 280px;
			min-width: 280px;
			height: 100%;
			background-color: var(--v-gray_10-base);
			border: 1px solid var(--v-gray_50-base);

			.item {
				border-bottom: 1px solid var(--v-gray_50-base);
				height: 48px;
				text-align: left;
				font-size: 16px;
				font-weight: 600;
				color: var(--v-gray_70-base);
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-left: 20px;
				cursor: pointer;

				&:hover {
					background-color: var(--v-gray_30-base);
				}
			}
			.active {
				background-color: var(--v-white-base);
				color: var(--v-primary-base);
			}
		}

		.settings-content {
			width: 100%;
			display: flex;
			flex-direction: column;

			.header-bar {
				//border-bottom: 1px solid var(--v-gray_50-base);
				//border-top: 1px solid var(--v-gray_50-base);
				//background-color: var(--v-gray_10-base);
				height: 49px;
			}

			.content {
				max-width: calc(100vw - 280px);
				overflow-y: scroll;
				overflow-x: scroll;
			}
		}
	}
</style>
