export default {
	data: function() {
		return {
			pages: [
				{ label: 'Invoices', value: 'invoices'},
				{ label: 'Payments & income', value: 'payments'},
				{ label: 'Expenses', value: 'expenses' },
				{ label: 'Vendors', value: 'vendors' },
				{ label: 'Profit & loss', value: 'reports' },
			],
		};
	},

	methods: {
		handleSetPage(page) {
			localStorage.setItem('ACCOUNTING_PAGE',page);
			this.$router.push('/accounting/' + page);
		},
	},
};
