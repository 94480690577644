import OpportunityService from "@/modules/pipeline/OpportunityService";
import PipelineSettingsService from "@/modules/pipeline/settings/PipelineSettingsService";
import OpportunityDetail from "@/modules/pipeline/opportunity/OpportunityDetail";
import ConfirmModal from "@/components/ConfirmModal";

export default {
    data: function () {
        return {
            isReady: false,
            pipelineStages: null,
            opportunities: [],
            opportunityService: new OpportunityService(),
            settingsService: new PipelineSettingsService(),
        }
    },

    methods: {
        createOpportunity: function(stage,clientId=null) {
            let binding = {
                pipelineStages: this.pipelineStages,
                pipelineFields: this.pipelineFields,
                defaultStatusId: stage ? stage.id : null,
                clientId: clientId,
            };
            this.$store.state.globalModalController.openModal(OpportunityDetail, binding).then((res) => {
                if (res) {
                    this.$onBoarding.track('pipeline_create_opportunity');
                    let ix = this.opportunities.findIndex((o) => o.id === res.id);
                    if (ix === -1) {
                        this.opportunities.push(res);
                    }
                }
            });
        },

        editOpportunity: function(id) {
            let binding = {
                pipelineStages: this.pipelineStages,
                pipelineFields: this.pipelineFields,
                id: id,
            };
            this.$store.state.globalModalController.openModal(OpportunityDetail, binding).then((res) => {
                if (res) {
                    let ix = this.opportunities.findIndex((o) => o.id === id);
                    if (res.deleted) {
                        if(ix > -1) {
                            this.opportunities.splice(ix, 1);
                        }
                    } else {
                        this.opportunities.splice(ix, 1, res);
                    }
                }
            });
        },


        confirmDelete: function(id) {
            let opp = this.opportunities.find((o) => o.id === id);
            let binding = {
                headingText: 'Confirm delete',
                bodyText: `Are you sure you want to delete the "${opp.name}" opportunity?  This cannot be undone.`,
            };
            this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
                if (res) {
                    this.opportunityService.deleteOpportunity(id).then(() => {
                        let ix = this.opportunities.findIndex((o) => o.id === id);
                        if(ix > -1) {
                            this.opportunities.splice(ix, 1);
                        }
                    });
                }
            });
        },

        archiveOpportunity: function(id) {
            let opp = this.opportunities.find((o) => o.id === id);
            const patch = [
                {
                    op: 'replace',
                    path: '/archive',
                    value: !opp.archive,
                },
            ];
            this.opportunityService.patchOpportunity(id, patch).then((res) => {
                let ix = this.opportunities.findIndex((o) => o.id === res.data.id);
                this.opportunities.splice(ix, 1, res.data);
            });
        },

        getPipelineStages: function() {
            return new Promise((resolve, reject) => {
                this.settingsService
                    .getPipelineStages()
                    .then((res) => (this.pipelineStages = res.data))
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
            });
        },

        getOpportunities: function(clientId=null) {
            return new Promise((resolve, reject) => {
                this.opportunityService
                    .getOpportunities(clientId)
                    .then((res) => {
                        this.opportunities.splice(0, this.opportunities.length);
                        this.opportunities.push(...res.data);
                    })
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
            });
        },
    },

    computed: {
        pipelineFields: function(){
            return this.$store.state.pipelineFields;
        },

        timePeriods: function() {
            return [
                { value: 'OneTime', label: 'One time', label2: null, hasLength: false },
                { value: 'Day', label: 'Per day', label2: 'days', hasLength: true },
                { value: 'Week', label: 'Per week', label2: 'weeks', hasLength: true },
                { value: 'Month', label: 'Per month', label2: 'months', hasLength: true },
                { value: 'Quarter', label: 'Per quarter', label2: 'quarters', hasLength: true },
                { value: 'SemiAnnual', label: 'Per six months', label2: 'periods', hasLength: true },
                { value: 'Year', label: 'Per year', label2: 'years', hasLength: true },
            ];
        },

        confidenceList: function() {
            let result = [];

            result.push({ value: 0, icon: 'sentiment_very_dissatisfied', color: '#BA4A00' });
            result.push({ value: 1, icon: 'sentiment_dissatisfied', color: '#D68910' });
            result.push({ value: 2, icon: 'sentiment_neutral', color: '#2ECC71' });
            result.push({ value: 3, icon: 'sentiment_satisfied', color: '#1E8449' });
            result.push({ value: 4, icon: 'sentiment_very_satisfied', color: '#267AE1' });

            return result.reverse();
        },
    }
}