<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Project management' }]" :compact="true">
			Tasks
		</page-header>
		<div
			class="column-format fill-height pb-0 px-0"
		>
			<project-management></project-management>
		</div>
	</div>
</template>

<script>
	import ProjectManagement from '@/modules/projects/management/ProjectManagement';
	import PageHeader from '@/components/PageHeader';
	export default {
		name: 'ProjectManagementRoute',

		props: [],

		components: { ProjectManagement, PageHeader },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
