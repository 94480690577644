var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initialized
    ? _c("div", { staticClass: "column-format" }, [
        !_vm.opportunities.length
          ? _c(
              "div",
              {
                staticClass: "column-format centered fill-height",
                staticStyle: { flex: "1", "min-height": "calc(100vh - 200px)" }
              },
              [
                _c("empty-view", {
                  attrs: {
                    header: "Never have another client dry spell",
                    body:
                      "Visualize the prospects you’re talking to, track notes on what they need, pitch them on proposals, work your way to a signed contract and getting paid.",
                    cta: "Add an opportunity",
                    "video-header": "See how it works",
                    "video-body":
                      "Learn how the pipeline visualizes your sales process, predicts your income, and helps you keep all of your opportunities on track to close.",
                    "video-cta": "Watch the tutorial",
                    "video-id": "f72IvZ0Ifm8"
                  },
                  on: {
                    "cta-clicked": function($event) {
                      return _vm.createOpportunity()
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.opportunities.length
          ? _c(
              "div",
              { staticClass: "opportunity-wrapper" },
              [
                _vm._t("filter"),
                _vm.isReady
                  ? _c("opportunity-list", {
                      staticClass: "mt-4",
                      attrs: {
                        "pipeline-stages": _vm.pipelineStages,
                        "pipeline-fields": _vm.pipelineFields,
                        opportunities: _vm.filteredOpportunities,
                        "confidence-list": _vm.confidenceList
                      },
                      on: {
                        edit: function($event) {
                          return _vm.editOpportunity($event)
                        },
                        archive: function($event) {
                          return _vm.archiveOpportunity($event)
                        },
                        delete: function($event) {
                          return _vm.confirmDelete($event)
                        }
                      }
                    })
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }