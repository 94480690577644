<template>
	<div id="proposal-wrapper" class="mx-0 my-5" @click="setEditIndex(-1)">
		<div id="proposal-area">
			<div id="proposal" :style="`--v-proposal-height:${proposalHeight}px;`">
				<div id="proposal-background">
					<v-container fluid>
						<v-row id="cover-page-row" :class="editMode ? 'edit-mode' : ''">
							<v-col cols="12" class="pa-0 mb-5">
								<cover-page
									:proposal-type="proposal.proposalType"
									:mceConfigHeader="mceConfigCover"
									v-bind="proposal.coverPage"
									:editMode="editMode"
									:templateMode="templateMode"
									:formatting="proposal.formatting"
									:client-name="clientName"
									:contractor-name="contractorName"
									:client-mode="clientMode"
									:heading-position="proposal.coverPage.headingPosition"
									@changed="handleCoverPageChange($event)"
									@cover-modal="$emit('cover-menu-open')"
									:logo="logo"
								></cover-page>
							</v-col>
						</v-row>
						<draggable v-model="proposal.contentBlocks" group="schema" handle=".handle" @end="dragEnd" v-if="isReady">
							<v-row
								v-for="(block, index) in contentBlocks"
								:key="block.id"
								align="center"
								@click.stop="setEditIndex(index)"
							>
								<v-col cols="12" class="content-block pa-0 mb-1">
									<div
										style="height: 1px"
										:id="`proposal-wrapper-${index}`"
										v-intersect="{
											handler: handleIntersect,
											options: scrollObserverOptions,
										}"
									></div>
									<div class="builder-wrapper">
										<div class="add-new-button" v-if="!clientMode && isEditable">
											<div class="add-new-rule"></div>
											<v-btn color="gray_20" elevation="0" @click="addContentAt(index)" small>
												<v-icon size="16">$plus</v-icon>
												<span class="ml-1 brand-semilight">{{ $t('proposal.content.add') }}</span>
											</v-btn>
										</div>

										<div class="builder-control" v-if="!clientMode && isEditable && editIndex === index">
											<v-btn
												class="secondary-action"
												icon
												text
												@click.native="shiftUp(index)"
												v-tippy="{ content: 'Move Up' }"
											>
												<v-icon size="20">$chevronUp</v-icon>
											</v-btn>
											<v-btn
												class="secondary-action"
												icon
												text
												@click.native="shiftDown(index)"
												v-tippy="{ content: 'Move Down' }"
											>
												<v-icon size="20">$chevronDown</v-icon>
											</v-btn>
											<v-btn
												class="secondary-action"
												icon
												text
												@click.native="confirmRemoveSection(index)"
												v-tippy="{ content: 'Delete section' }"
											>
												<v-icon size="20">$delete</v-icon>
											</v-btn>
										</div>

										<component
											v-bind="block"
											:block="block"
											:key="block.id"
											:ref="block.id"
											:is="block.blockType"
											:edit-mode="!clientMode && isEditable && editIndex === index"
											:template-mode="templateMode"
											:account="proposal.accountMini"
											:client="proposal.clientMini"
											:proposal-id="proposal.id"
											:client-id="proposal.clientId"
											:mce-config-cover="mceConfigCover"
											:mce-config-header="mceConfigHeader"
											:mce-config-text="templateMode ? mceConfigTextTemplate : mceConfigText"
											:deliverables-count="deliverablesCount"
											:force-all-deliverables="proposal.forceAllDeliverables"
											:allow-multiple-deliverables="proposal.allowMultipleDeliverables"
											:deliverable-selected="deliverableSelected"
											:proposal-service="proposalService"
											:template-service="templateService"
											:client-mode="clientMode"
											:formatting="proposal.formatting"
											:header-style="headerStyle"
											:body-style="bodyStyle"
											:is-locked="isLocked"
											:refresh-key="refreshKey"
											:currency="proposal.currency"
											:proposal-type="proposal.proposalType"
											:up-front-amount="upFrontAmount"
											:payment-node-count="paymentCount"
											:pending-charge="proposal.pendingCharge"
											:card-payment-failed="cardPaymentFailed"
											@changed="handleContentBlockChange(index, $event)"
											@toggleMultipleDeliverables="toggleMultipleDeliverables()"
											@total-upfront-amount="handleUpFrontAmount(block, $event)"
											@create-package-from-deliverables="$emit('create-package-from-deliverables', $event)"
											@request-them-sign="requestThemSign(block)"
										></component>
									</div>
								</v-col>
							</v-row>
						</draggable>
						<v-row align="center">
							<v-col cols="12" class="content-block py-0 px-12">
								<div class="add-new-button" v-if="!clientMode && isEditable">
									<div class="add-new-rule"></div>
									<v-btn
										color="gray_20"
										elevation="0"
										@click="addContentAt(proposal.contentBlocks.length)"
										small
									>
										<v-icon size="16">$plus</v-icon>
										<span class="ml-1 brand-semilight">{{ $t('proposal.content.add') }}</span>
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</div>
		</div>

		<basic-modal :dialog="removeSectionDialog" :persistent="true" @close="cancelRemoveSection()">
			<div id="delete-section-modal">
				<v-btn icon class="close-dialog" @click="$emit('close')">
					<v-icon>$close</v-icon>
				</v-btn>
				<div class="modal-padding">
					<div class="modal-title text-center">
						<span class="pr-1">
							<v-icon color="warning" class="mt-n1" size="24">$alert</v-icon>
						</span>
						{{ $t('global.confirm') }}
					</div>
					<div class="modal-subtitle text-center mx-4">{{ $t('proposal.delete.section') }}</div>
				</div>
				<div class="modal-footer">
					<v-btn class="super-action mr-1" style="width: 120px" @click="cancelRemoveSection()">{{
						$t('global.cancel')
					}}</v-btn>
					<v-btn class="primary-action ml-1" style="width: 120px" @click="removeSection()">{{
						$t('global.delete')
					}}</v-btn>
				</div>
			</div>
		</basic-modal>

		<basic-modal :dialog="contentMenuOpen" :persistent="true" @close="contentMenuOpen = false">
			<add-component
				:client-id="proposal.clientId"
				:signature-count="signatureCount"
				:terms-count="termsCount"
				:accept-count="acceptCount"
				:payment-count="paymentCount"
				@add-section="addSection"
				@close="contentMenuOpen = false"
			></add-component>
		</basic-modal>
	</div>
</template>

<script>
	import CoverPage from './CoverPage';
	import TextArea from './TextArea';
	import Deliverables from './Deliverables';
	import Terms from './Terms';
	import SignatureBlock from './SignatureBlock';
	import Files from './Files';
	import ImageBlock from './ImageBlock';
	import ImageTextBlock from './ImageTextBlock';
	import draggable from 'vuedraggable';
	import { v4 as uuidv4 } from 'uuid';
	import AddComponent from './AddComponent';
	import ComponentIcons from './ComponentIcons';
	import HNav from '@bit/hecticapp.common.h-nav';
	import PillSelector from '@bit/hecticapp.common.pill-selector';
	import Modal from '@bit/hecticapp.common.modal';
	import BasicModal from '@/components/BasicModal';
	import StatusMenu from './StatusMenu';
	import CommentsMenu from './CommentsMenu';
	import ProposalMixin from './ProposalMixin';
	import WebFontLoader from 'webfontloader';
	import AcceptBlock from './AcceptBlock';
	import PaymentCaptureBlock from './PaymentCaptureBlock';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'ProposalRenderer',

		props: [
			'templateMode',
			'editMode',
			'proposal',
			'account',
			'proposalService',
			'clientService',
			'templateService',
			'needSave',
			'saving',
			'clientMode',
			'isLocked',
			'refreshKey',
			'client',
			'cardPaymentFailed',
		],

		mixins: [ProposalMixin],

		components: {
			StatusMenu,
			CommentsMenu,
			Modal,
			BasicModal,
			PillSelector,
			ComponentIcons,
			AddComponent,
			CoverPage,
			TextArea,
			Deliverables,
			SignatureBlock,
			AcceptBlock,
			Terms,
			Files,
			ImageBlock,
			ImageTextBlock,
			PaymentCaptureBlock,
			draggable,
			HNav,
		},

		data: function() {
			return {
				isReady: false,
				addSectionIndex: null,
				removeSectionIndex: null,
				removeSectionDialog: false,
				selectedNavItem: 0,
				navIncrement: 1,
				contentBlockChangeMonitor: 1,

				contentMenuOpen: false,
				selectedIndex: -1,
				editIndex: -1,
				addContentAtIndex: -1,
				upFrontAmounts: [],
				cardTokenId: null,
				hasAcknowledgedPayment: false,

				scrollObserverOptions: {
					rootMargin: '-54px 0px -70%',
					threshold: [0, 0.25, 0.5, 0.75, 1],
				},

				mceConfigCover: {
					menubar: false,
					inline: true,
					plugins: [],
					paste_as_text: true,
					toolbar: [],
					forced_root_block: '',
					browser_spellcheck: true,
					setup: function(ed) {
						ed.on('keyDown', function(event) {
							if (event.keyCode == 13) {
								event.preventDefault();
								event.stopPropagation();
								return false;
							}
						});
					},
				},

				mceConfigHeader: {
					menubar: false,
					inline: true,
					plugins: ['paste', 'link'],
					paste_as_text: true,
					browser_spellcheck: true,
					toolbar: ['fontsizeselect styleselect forecolor | bold italic underline | link unlink'],
					forced_root_block: 'div',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					setup: function(ed) {
						ed.on('keyDown', function(event) {
							if (event.keyCode == 13) {
								event.preventDefault();
								event.stopPropagation();
								return false;
							}
						});
					},
				},

				mceConfigText: {
					menubar: false,
					inline: true,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					browser_spellcheck: true,
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: 'paste | link image | table',
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'fontsizeselect styleselect forecolor | bold italic underline |  bullist numlist',
						'blockquote alignleft aligncenter alignright alignjustify indent outdent | table link unlink media code',
					],
					style_formats: [
						{ title: this.$t('proposal.paragraph'), format: 'p' },
						{ title: this.$t('proposal.title'), format: 'h1' },
						{ title: this.$t('proposal.heading'), format: 'h2' },
						{ title: this.$t('proposal.subheading'), format: 'h3' },
						{ title: this.$t('proposal.code'), format: 'code' },
					],
					table_toolbar:
							'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
				},

				mceConfigTextTemplate: {
					menubar: false,
					inline: true,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					browser_spellcheck: true,
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: 'paste | link image | table',
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'fontsizeselect styleselect forecolor | bold italic underline |  bullist numlist | tokenbutton',
						'blockquote alignleft aligncenter alignright alignjustify indent outdent | table link unlink media code',
					],
					style_formats: [
						{ title: this.$t('proposal.paragraph'), format: 'p' },
						{ title: this.$t('proposal.title'), format: 'h1' },
						{ title: this.$t('proposal.heading'), format: 'h2' },
						{ title: this.$t('proposal.subheading'), format: 'h3' },
						{ title: this.$t('proposal.code'), format: 'code' },
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
					setup: (editor) => {
						let items = [];
						this.tokens.forEach((t) => {
							items.push({
								type: 'menuitem',
								text: t,
								onAction: function() {
									editor.insertContent('{{' + t + '}}');
								},
							});
						});
						editor.ui.registry.addMenuButton('tokenbutton', {
							text: 'Tokens',
							fetch: function(callback) {
								callback(items);
							},
						});
					},
				},
			};
		},

		//toolbar: ['undo redo | bold italic underline', 'numlist bullist | outdent indent | link unlink'],

		mounted() {
			this.scrubContentBlocks();
			this.initFonts();
		},

		methods: {
			initFonts() {
				if (this.$store.state.accountFonts.length === 0) {
					WebFontLoader.load({
						google: {
							families: [
								`${this.proposal.formatting.headerFont}:200,300,400,500,600,700`,
								`${this.proposal.formatting.bodyFont}:200,300,400,500,600,700`,
							],
						},
						active: this.setFont,
					});
				}
			},

			setFont() {
				console.log('fonts active');
			},

			requestThemSign: function(signatureBlock) {
				let paymentCaptureBlock = this.getPaymentCaptureBlock();
				if (paymentCaptureBlock) {
					if (this.hasAcknowledgedPayment) {
						this.processPaymentCapture(paymentCaptureBlock, signatureBlock);
					} else {
						let binding = {
							headingText: 'Confirm',
							bodyText: `By finalizing this agreement you acknowledge that your card will be charged ${this.$formatters.dollars(
								this.upFrontAmount,
								true,
								true,
								this.proposal.currency
							)} (${this.proposal.currency}) today.<br>Do you wish to continue?`,
						};

						this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
							if (res) {
								this.hasAcknowledgedPayment = true;
								this.processPaymentCapture(paymentCaptureBlock, signatureBlock);
							}
						});
					}
				} else {
					this.$refs[signatureBlock.id][0].allowThemSign();
				}
			},

			processPaymentCapture: function(paymentCaptureBlock, signatureBlock) {
				this.$refs[paymentCaptureBlock.id][0]
					.exchangeCardForToken()
					.then((res) => {
						if (res) {
							this.cardTokenId = res;
							this.$refs[signatureBlock.id][0].allowThemSign();
						} else {
							this.$store.commit('error', 'Sorry, there was an error with the card provided.');
						}
					})
					.catch((err) => {
						this.$store.commit('error', err);
					});
			},

			handleUpFrontAmount: function(block, amount) {
				let result = {
					id: block.id,
					amount: amount,
					selectedPackage: block.selectedPackage,
				};
				let id = block.id;
				let ix = this.upFrontAmounts.findIndex((u) => u.id === id);
				if (ix > -1) {
					this.upFrontAmounts.splice(ix, 1, result);
				} else {
					this.upFrontAmounts.push(result);
				}
			},

			scrubContentBlocks() {
				let contentBlocks = [];
				for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
					if (!this.proposal.contentBlocks[i]) continue;
					contentBlocks.push(this.proposal.contentBlocks[i]);
				}
				this.proposal.contentBlocks = contentBlocks;
				this.isReady = true;
			},

			navClick(index) {
				this.selectedIndex = index;
				let ourDiv = document.getElementById(`proposal-wrapper-${index}`);
				ourDiv.scrollIntoView({ behavior: 'smooth' });
			},

			setEditIndex(index) {
				this.editIndex = index;
			},

			shareProposal() {
				this.$emit('share-proposal');
			},

			sendProposal() {
				this.$emit('send-proposal');
			},

			dragEnd(payload) {
				this.editIndex = payload.newIndex;
				this.requestSave();
			},

			requestSave() {
				this.$emit('need-save');
				this.navIncrement++;
			},

			getId() {
				return 'id' + uuidv4().replace(/-/g, '');
			},

			returnToDraft() {
				this.$emit('return-to-draft');
			},

			deleteProposal() {
				this.$emit('delete-proposal');
			},

			createProject() {
				this.$emit('create-project');
			},

			shiftUp: function(index) {
				if (index > 0) {
					let block = this.proposal.contentBlocks[index];
					this.proposal.contentBlocks.splice(index, 1);
					this.proposal.contentBlocks.splice(index - 1, 0, block);
					this.editIndex = this.editIndex - 1;
					this.requestSave();
				}
			},

			shiftDown: function(index) {
				if (index < this.proposal.contentBlocks.length - 1) {
					let block = this.proposal.contentBlocks[index];
					this.proposal.contentBlocks.splice(index, 1);
					this.proposal.contentBlocks.splice(index + 1, 0, block);
					this.editIndex = this.editIndex + 1;
					this.requestSave();
				}
			},

			handleCoverPageChange(event) {
				this.proposal.coverPage.heading = event.heading;
				this.proposal.coverPage.customBy = event.customBy;
				this.proposal.coverPage.customFor = event.customFor;
				this.requestSave();
			},

			addContentAt(index) {
				this.addContentAtIndex = index;
				this.contentMenuOpen = true;
			},

			addSection(args) {
				let type = args.type;
				let index;
				if (this.addContentAtIndex > -1) {
					index = this.addContentAtIndex;
					this.addContentAtIndex = -1;
				} else {
					index = this.proposal.contentBlocks.length;
				}

				let newSection = {
					blockType: type,
					id: this.getId(),
				};

				if (type === 'TextArea') {
					newSection.header = this.$t('proposal.content.section-header');
					newSection.text = this.$t('proposal.content.enter-text');
				} else if (type === 'PaymentCaptureBlock') {
					newSection.header = 'Payment information';
					newSection.text = 'Please enter your card information to complete the transaction';
				} else if (type === 'AcceptBlock') {
					newSection.header = '';
					newSection.text = this.$t('proposal.content.accept-text');
				} else if (type === 'SignatureBlock') {
					newSection.header = this.$t('proposal.content.signatures');
					newSection.us = {
						type: 'Keyboard',
						font: 'Arizonia',
						signature: '',
						dts: null,
						ip: {},
						signerName: this.$store.getters.getLoggedInUserName,
						signerTitle: null,
						signerLocked: false,
					};
					newSection.them = {
						type: 'Keyboard',
						font: 'Arizonia',
						signature: '',
						dts: null,
						ip: {},
						signerName: null,
						signerTitle: null,
						signerLocked: false,
					};
				} else if (type === 'Files') {
					newSection.header = this.$t('proposal.content.file-attachments');
					newSection.text = this.$t('proposal.content.describe-attachments');
					newSection.files = [];
				} else if (type === 'ImageBlock') {
					newSection.file = null;
					newSection.scale = 75;
				} else if (type === 'ImageTextBlock') {
					newSection.file = null;
					newSection.align = 'Left';
					newSection.border = 'None';
					newSection.header = this.$t('proposal.content.section-header');
					newSection.text = this.$t('proposal.content.enter-text');
				} else if (type === 'Deliverables') {
					if (args.deliverableBlock) {
						newSection = args.deliverableBlock;
						newSection.selectedPackage = false;
						newSection.taxRate = this.defaultTaxRate;
					} else {
						newSection.header = this.$t('proposal.content.project-name');
						newSection.selectedPackage = false;
						newSection.taxRate = this.defaultTaxRate;
						newSection.deliverables = [];
						newSection.feeSchedule = {
							feeType: 'Hourly',
							amount: 0,
							retainerSchedule: null,
							estimateMin: 0,
							estimateMax: 0,
						};
					}

					newSection.deliverableAutomation = {
						approvalRequired: true,
						autoCreateProject: true,
						autoInvoice: true,
						invoicePercentage: true,
						invoiceAmount: 50,
						paymentRequiredToStart: true,
						overrideDeliverablePackageId: null,
						projectName: null,
					};
				} else if (type === 'Terms') {
					newSection.header = this.$t('proposal.content.terms');
					newSection.paymentDays = 7;
					newSection.invoicingSchedule = 'Weekly';
					newSection.latePaymentFee = 5;
					newSection.depositAmount = 50;
					newSection.depositType = 'Percentage';
					newSection.fullLegalTerms = true;
					newSection.governingLaw = this.$store.state.podAccount.locality;
					newSection.cancellationNoticeDays = 30;
					newSection.displayInPortfolio = true;
					newSection.processingFeePassThrough = true;
					newSection.grantLicenseForFramework = false;
					newSection.clientEntityType = 'Corporation';

					if (this.client) {
						newSection.effectiveDate = new Date().toISOString();
						newSection.clientLegalName = this.client.name;
						newSection.clientState = this.client.locality;
					} else {
						newSection.effectiveDate = null;
						newSection.clientLegalName = '{{Client.Name}}';
						newSection.clientState = '{{Client.Locality}}';
					}

					newSection.evaluatedTerms = null;
				}

				this.proposal.contentBlocks.splice(index, 0, newSection);
				this.editIndex = index;
				this.requestSave();
			},

			confirmRemoveSection(index) {
				this.removeSectionIndex = index;
				this.removeSectionDialog = true;
			},

			cancelRemoveSection() {
				this.removeSectionIndex = null;
				this.removeSectionDialog = false;
			},

			removeSection() {
				let deletedContentBlocks = this.proposal.contentBlocks.splice(this.removeSectionIndex, 1);
				let deletedContentBlock = deletedContentBlocks[0];

				if (deletedContentBlock.blockType === 'Files') {
					this.finalizeDeleteFilesBlock(deletedContentBlock);
				} else if (deletedContentBlock.blockType === 'ImageBlock') {
					this.finalizeDeleteImageBlock(deletedContentBlock);
				}

				this.removeSectionIndex = null;
				this.removeSectionDialog = false;
				this.editIndex = -1;
				this.requestSave();
			},

			finalizeDeleteFilesBlock(deletedContentBlock) {
				for (let i = 0; i < deletedContentBlock.files.length; i++) {
					let file = deletedContentBlock.files[i];
					this.deleteFile(file);
				}
			},

			finalizeDeleteImageBlock(deletedContentBlock) {
				if (deletedContentBlock.file !== null) {
					this.deleteFile(deletedContentBlock.file);
				}
			},

			deleteFile(file) {
				if (this.templateMode) {
					this.templateService.deleteFileAttachment(this.proposal.id, file.fileName);
				} else {
					this.proposalService.deleteFileAttachment(this.proposal.clientId, this.proposal.id, file.fileName);
				}
			},

			toggleMultipleDeliverables() {
				if (!this.proposal.allowMultipleDeliverables) {
					this.proposal.allowMultipleDeliverables = true;
					this.proposal.forceAllDeliverables = false;
				} else if (this.proposal.allowMultipleDeliverables && this.proposal.forceAllDeliverables) {
					this.proposal.allowMultipleDeliverables = false;
					this.proposal.forceAllDeliverables = false;
				} else {
					this.proposal.allowMultipleDeliverables = true;
					this.proposal.forceAllDeliverables = true;
				}
				this.requestSave();
			},

			handleContentBlockChange(index, block) {
				this.contentBlockChangeMonitor++;
				this.proposal.contentBlocks[index] = block;

				if (block.blockType === 'Deliverables' && block.selectedPackage && !this.proposal.allowMultipleDeliverables) {
					this.clearOtherSelectedPackages(index);
					this.$forceUpdate();
				}

				if (this.clientMode) {
					if (block.blockType === 'Deliverables') {
						this.$emit('deliverable-selected', block);
					} else if (block.blockType === 'SignatureBlock') {
						this.$emit('client-signed', {
							block: block,
							cardTokenId: this.cardTokenId,
							upFrontAmount: this.upFrontAmount,
						});
					} else if (block.blockType === 'AcceptBlock') {
						this.$emit('client-accepted', {
							block: block,
							cardTokenId: this.cardTokenId,
							upFrontAmount: this.upFrontAmount,
						});
					}
				} else {
					if (this.proposal.proposalStatus === 'Draft' || block.blockType === 'SignatureBlock' || this.templateMode) {
						this.requestSave();
					}
				}
			},

			clearOtherSelectedPackages: function(currentIndex) {
				for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
					if (i !== currentIndex && this.proposal.contentBlocks[i].blockType === 'Deliverables') {
						this.proposal.contentBlocks[i].selectedPackage = false;
						let block = this.proposal.contentBlocks[i];
						let ix = this.upFrontAmounts.findIndex((u) => u.id === block.id);
						if (ix > -1) {
							let u = this.upFrontAmounts[ix];
							u.selectedPackage = false;
							this.upFrontAmounts.splice(ix, 1, u);
						}
					}
				}
			},

			handleIntersect([entry]) {
				if (entry && entry.isIntersecting && entry.intersectionRatio >= 1) {
					let id = entry.target.id;
					id = id.replace(/^proposal-wrapper-/g, '');
					this.selectedIndex = parseInt(id, 10);
				}
			},

			typeCount: function(type) {
				let counter = 0;
				for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
					if (!this.proposal.contentBlocks[i]) continue;
					if (this.proposal.contentBlocks[i].blockType === type) {
						counter = counter + 1;
					}
				}
				return counter;
			},

			getPaymentCaptureBlock: function() {
				return this.proposal.contentBlocks.find((b) => b.blockType === 'PaymentCaptureBlock');
			},
		},

		watch: {},

		computed: {
			defaultTaxRate: function(){
				if(this.client && (this.client.defaultTaxRate || this.client.defaultTaxRate === 0)) {
					return this.client.defaultTaxRate;
				}else if(this.proposal.accountMini && (this.proposal.accountMini.defaultTaxRate || this.proposal.accountMini.defaultTaxRate === 0)){
					return this.proposal.accountMini.defaultTaxRate;
				}else{
					return this.$store.state.podAccount.accountPreferences.defaultTaxRate;
				}
			},

			clientFields: function(){
				if(this.$store.state.clientFields && this.$store.state.clientFields.fields){
					return this.$store.state.clientFields.fields;
				}else{
					return [];
				}
			},

			contactFields: function(){
				if(this.$store.state.contactFields && this.$store.state.contactFields.fields){
					return this.$store.state.contactFields.fields;
				}else{
					return [];
				}
			},

			tokens: function() {
				if(!this.templateMode){
					return [];
				}

				let client = [
					'Client.Name',
					'Client.TaxId',
					'Client.Currency',
					'Client.Address1',
					'Client.Address2',
					'Client.City',
					'Client.Locality',
					'Client.Postal',
					'Client.Country',
					'Client.Phone',
					'Client.Website',
				];

				let contact = ['Contact.FirstName', 'Contact.LastName', 'Contact.FullName', 'Contact.Email', 'Contact.Phone'];

				this.clientFields.forEach((f) => {
					client.push('Client.Custom.' + f.mappingKey);
				});

				this.contactFields.forEach((f) => {
					contact.push('Contact.Custom.' + f.mappingKey);
				});

				let date = [
						'Date.MMDDYYYY',
						'Date.MMMDDYYYY',
						'Date.MMMMDDYYYY',
						'Date.DDMMYYYY',
						'Date.DDMMMYYYY',
						'Date.DDMMMMYYYY',
						'Date.YYYYMMDD',
						'Time.24HR',
						'Time.12HR'
				];

				return [].concat(client, contact, date);
			},

			logo: function() {
				if (this.proposal.coverPage && this.proposal.coverPage.showLogo) {
					if (this.$vuetify.theme.dark && this.account.accountLogoDark) {
						return this.account.accountLogoDark;
					} else {
						return this.account.accountLogo;
					}
				} else {
					return null;
				}
			},

			upFrontAmount: function() {
				let result = 0;
				let amounts = [...this.upFrontAmounts];

				if (this.deliverablesCount <= 1 || this.proposal.forceAllDeliverables) {
					amounts.forEach((u) => (result = result + u.amount));
				} else {
					amounts.filter((u) => u.selectedPackage).forEach((u) => (result = result + u.amount));
				}

				return result;
			},

			isEditable: function() {
				if (this.templateMode) return true;
				return this.proposal.proposalStatus === 'Draft';
			},

			minWidth: function() {
				return this.$vuetify.breakpoint.width > 918 ? 918 : this.$vuetify.breakpoint.width;
			},

			proposalHeight: function() {
				if (this.clientMode) {
					return this.$vuetify.breakpoint.height - 140;
				} else {
					return this.$vuetify.breakpoint.height - 105;
				}
			},

			navItems: function() {
				let items = [];
				if (this.navIncrement > 0) {
					for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
						if (!this.$validations.isEmpty(this.proposal.contentBlocks[i].header)) {
							items.push({
								label: this.proposal.contentBlocks[i].header,
								contentIndex: i,
							});
						}
					}
				}
				return items;
			},

			deliverablesCount: function() {
				this.contentBlockChangeMonitor;
				return this.typeCount('Deliverables');
			},

			termsCount: function() {
				this.contentBlockChangeMonitor;
				return this.typeCount('Terms');
			},

			paymentCount: function() {
				this.contentBlockChangeMonitor;
				return this.typeCount('PaymentCaptureBlock');
			},

			deliverableSelected: function() {
				this.contentBlockChangeMonitor;
				if (this.deliverablesCount < 2) {
					return true;
				}

				for (let i = 0; i < this.proposal.contentBlocks.length; i++) {
					if (this.proposal.contentBlocks[i].blockType === 'Deliverables') {
						if (this.proposal.contentBlocks[i].selectedPackage) {
							return true;
						}
					}
				}

				return false;
			},

			contentBlocks: function() {
				this.contentBlockChangeMonitor;
				if (this.deliverablesCount > 1 && this.proposal.proposalStatus === 'Fully Executed') {
					let result = [...this.proposal.contentBlocks];
					// result = result.filter(
					// 	(c) => c.blockType !== 'Deliverables' || (c.blockType === 'Deliverables' && c.selectedPackage)
					// );
					return result;
				} else {
					return this.proposal.contentBlocks;
				}
			},

			signatureCount: function() {
				this.contentBlockChangeMonitor;
				return this.typeCount('SignatureBlock');
			},

			acceptCount: function() {
				this.contentBlockChangeMonitor;
				return this.typeCount('AcceptBlock');
			},

			headerWeight: function() {
				if (this.proposal.formatting.headerWeight === 'Regular') {
					return 400;
				} else {
					return 700;
				}
			},
		},
	};
</script>

<style lang="scss">
	.proposal-text-area blockquote {
		margin: 1.5em 10px;
		padding: 0.5em 10px;
	}

	.proposal-text-area blockquote:before {
		color: #ccc;
		content: '“';
		font-size: 4em;
		line-height: 0.1em;
		margin-right: 0.1em;
		vertical-align: -0.4em;
	}

	.proposal-text-area blockquote::after {
		color: #ccc;
		content: '”';
		font-size: 4em;
		line-height: 0.1em;
		margin-left: 0.1em;
		vertical-align: -0.4em;
	}

	.proposal-text-area blockquote p {
		display: inline;
	}

	#proposal-wrapper {
		//border: 1px solid red;
		//width: 100%;
		//display: flex;
		//flex-direction: column;
		padding-bottom: 32px;
		#proposal-area {
			//display: flex;
			max-width: 918px;
			min-width: var(--v-min-width);
			//align-self: center;
			margin: 0 auto;
			//border: 1px solid var(--v-gray_50-base);
			box-shadow: 0px 1px 4px rgba(16, 17, 15, 0.2);
			border-radius: 4px;
			background-color: var(--v-white-base);
			#proposal {
				#proposal-background {
					#cover-page-row:not(.edit-mode) {
						margin-bottom: 28px;
					}
					.add-new-button {
						opacity: 0;
						transition: opacity 300ms;
						&:hover {
							opacity: 1;
							transition: opacity 300ms;
						}
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 4px 0 24px;
						.add-new-rule {
							position: absolute;
							top: 12px;
							left: 0;
							width: 100%;
							border-bottom: 1px solid var(--v-gray_50-base);
						}
						.v-btn {
							height: 24px !important;
							font-size: 12px !important;
							color: var(--v-gray_80-base);
							letter-spacing: 0.2px;
							&:hover {
								color: var(--v-primary-base);
							}
						}
					}

					.builder-wrapper {
						padding: 4px 48px 24px;
						position: relative;
						margin: 12px 0;
						&:hover {
							.add-new-button {
								opacity: 1;
								transition: opacity 300ms;
							}
						}
						.builder-control {
							position: absolute;
							width: 40px;
							//height: 120px;
							top: 18px;
							left: -40px;
							background: var(--v-gray_30-base);
							//box-shadow: 0px 1px 4px rgba(16, 17, 15, 0.2);
							border-radius: 4px;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: space-between;
							padding: 8px 0;
							.v-btn:hover .v-icon {
								color: var(--v-primary-base);
							}
						}
					}
				}
			}
		}
	}
	#delete-section-modal {
		max-width: 290px;
		background-color: var(--v-white-base);
	}
</style>
