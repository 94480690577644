<template>
	<v-container>
		<v-row dense>
			<v-col>
				<h1 class="mb-6">{{ $t('invoice.details.stripe-modal.heading') }}</h1>
				<stripe-connect :stripe-integration="account.stripeIntegration"></stripe-connect>
				<v-btn @click="$emit('close')" class="px-8 secondary-action">{{ $t('global.cancel') }}</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import StripeConnect from '@/modules/integrations/stripe/StripeConnect';
	export default {
		name: 'Account',

		props: ['account'],

		components: { StripeConnect },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>
