<template>
	<v-container class="pa-0 ma-0 font-14" style="width:98%">
		<v-row dense>
			<v-col cols="12" class="column-format gap-2">
				<v-text-field
					v-model="opportunity.formData.businessName"
					dense
					hide-details
					outlined
					persistent-placeholder
					label="Business name"
				></v-text-field>
				<div class="row-format gap-2">
					<v-text-field
						v-model="opportunity.formData.firstName"
						dense
						hide-details
						outlined
						persistent-placeholder
						label="First name"
					></v-text-field>
					<v-text-field
						v-model="opportunity.formData.lastName"
						dense
						hide-details
						outlined
						persistent-placeholder
						label="Last name"
					></v-text-field>
				</div>
				<div class="row-format gap-2">
					<v-text-field
						v-model="opportunity.formData.email"
						dense
						hide-details
						outlined
						persistent-placeholder
						label="Email"
					></v-text-field>
					<v-text-field
						v-model="opportunity.formData.phone"
						dense
						hide-details
						outlined
						persistent-placeholder
						label="Phone"
					></v-text-field>
				</div>
				<v-text-field
						v-model="opportunity.formData.role"
						dense
						hide-details
						outlined
						persistent-placeholder
						label="Role"
				></v-text-field>
				<v-text-field
						v-model="opportunity.formData.website"
						dense
						hide-details
						outlined
						persistent-placeholder
						label="Website"
				></v-text-field>
				<div class="row-format gap-2">
					<v-text-field
							v-model="opportunity.formData.leadSource"
							dense
							hide-details
							outlined
							persistent-placeholder
							label="Lead source"
					></v-text-field>
					<v-text-field
							v-model="opportunity.formData.taxId"
							dense
							hide-details
							outlined
							persistent-placeholder
							label="Tax ID"
					></v-text-field>
				</div>

			</v-col>
		</v-row>
		<v-row dense class="mt-3 pt-3">
			<v-col cols="12" class="column-format gap-2">
				<v-text-field
					v-model="opportunity.formData.address1"
					dense
					hide-details
					outlined
					persistent-placeholder
					label="Address 1"
				></v-text-field>
				<v-text-field
					v-model="opportunity.formData.address2"
					dense
					hide-details
					outlined
					persistent-placeholder
					label="Address 2"
				></v-text-field>
				<div class="row-format gap-2">
					<v-text-field
						v-model="opportunity.formData.city"
						dense
						hide-details
						outlined
						persistent-placeholder
						label="City"
					></v-text-field>
					<v-text-field
						v-model="opportunity.formData.locality"
						dense
						hide-details
						outlined
						persistent-placeholder
						label="Locality"
					></v-text-field>
					<v-text-field
						v-model="opportunity.formData.postal"
						dense
						hide-details
						outlined
						persistent-placeholder
						label="Zip/Postal"
					></v-text-field>
				</div>
				<div class="row-format gap-2">
					<v-autocomplete
							v-model="opportunity.formData.country"
							:label="$t('contact.country')"
							hide-details
							dense
							outlined
							persistent-placeholder
							:items="countries.getCountriesSimple()"
							item-value="code"
							item-text="name"
					/>

				</div>

			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12">
				<div class="fill-width" style="border-bottom: 1px solid var(--v-gray_30-base)"></div>
			</v-col>
		</v-row>
		<v-row dense v-for="(answer, index) in opportunity.formData.answers" :key="index">
			<v-col cols="12">
				<div v-html="answer.question" class="font-gray_70"></div>
				<div contenteditable="true" style="white-space: pre-wrap" @blur="updateAnswer($event,index)">{{ answer.answer }}</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import Countries from "@/modules/utils/Countries";

	export default {
		name: 'LeadInfo',

		props: ['opportunity'],

		components: {},

		data: function() {
			return {
				countries: new Countries(),
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updateAnswer: function(event,index){
				this.opportunity.formData.answers[index].answer = event.target.innerText;
			}
		},

		computed: {
		},
	};
</script>

<style scoped lang="scss"></style>
