<template>
	<div class="pa-3 text-left column-format" style="border: 1px solid var(--v-gray_30-base); border-radius: 4px; gap: 12px">
		<div class="font-14">
			<div class="text-left form-content">
				<editor
					ref="PaymentTextEditor"
					:api-key="$store.getters.getTinyMceKey"
					v-model="meeting.requiredPayment.paymentInfo"
					:inline="true"
					:init="mceConfigText"
				></editor>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MockPayment',

		props: ['meeting'],

		components: { editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue') },

		data: function() {
			return {
				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					paste_as_text: false,
					forced_root_block: 'div',
					paste_data_images: true,
					plugins: ['paste', 'lists', 'link', 'table', 'code', 'hr'],
					contextmenu: 'paste | link | table',
					branding: false,
					height: `400px`,
					width: '500px',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'bold italic underline forecolor fontsizeselect | link unlink code',
						'tokenbutton | hr | alignleft aligncenter alignright alignjustify | formatting_group',
					],
					fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 24pt 30pt 36pt 48pt 72pt',
					toolbar_groups: {
						formatting_group: {
							icon: 'format',
							tooltip: 'Formatting',
							items: 'blockquote bullist numlist indent outdent table | removeformat',
						},
						media_group: {
							icon: 'embed',
							tooltip: 'Media',
							items: ' link unlink media',
						},
					},
					setup: (editor) => {
						let items = [];
						this.tokens.forEach((t) => {
							items.push({
								type: 'menuitem',
								text: t,
								onAction: function() {
									editor.insertContent('{{' + t + '}}');
								},
							});
						});
						editor.ui.registry.addMenuButton('tokenbutton', {
							text: 'Tokens',
							fetch: function(callback) {
								callback(items);
							},
						});
					},
				},
			};
		},

		mounted: function() {},

		methods: {


		},

		computed: {
			tokens: function() {
				let payment = [
          'Payment.Amount',
          'Meeting.Name',
          'Meeting.Duration',
          'Meeting.TimeUnit',
          'Meeting.Location',
          'Meeting.LocationInfo',
          'Meeting.ScheduledTime',
          'Meeting.AccountName',
          'Meeting.OwnerName',
          'Meeting.TimeZone',
          'Meeting.Description',
          'Icons.Clock',
          'Icons.Mic',
          'Icons.Event',
          'Icons.Public',
          'Icons.Info',
        ];
				return payment;
			},
		},
	};
</script>

<style scoped></style>
