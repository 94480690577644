var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-6" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              style:
                "--dp-font-family:" +
                _vm.meeting.pageLayout.fontFamily +
                ";\n                  background-color: " +
                _vm.meeting.pageLayout.backgroundMainColor +
                ";\n                  color: " +
                _vm.textContrast
            },
            [
              _c(
                "div",
                [
                  _c("div", { staticClass: "row-format centered mt-1 mb-2" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          { attrs: { icon: "" } },
                          [
                            _c(
                              "v-icon",
                              {
                                style: "color: " + _vm.textContrast,
                                attrs: { size: "30" }
                              },
                              [_vm._v("$prev")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "brand-medium px-4" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.DateTime.fromISO(
                              _vm.monthToShow
                            ).toLocaleString({ month: "long", year: "numeric" })
                          ) +
                          " "
                      )
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          { attrs: { icon: "" } },
                          [
                            _c(
                              "v-icon",
                              {
                                style: "color: " + _vm.textContrast,
                                attrs: { size: "30" }
                              },
                              [_vm._v("$next")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("v-date-picker", {
                    staticClass: "schedule-picker",
                    style:
                      "--date-color: " +
                      _vm.dateContrast +
                      "; --month-header-color: " +
                      _vm.monthHeadersContrast +
                      "; --dp-font-family: " +
                      _vm.meeting.pageLayout.fontFamily +
                      "; --dp-background-color:" +
                      _vm.meeting.pageLayout.backgroundMainColor +
                      "; --light-accent:" +
                      _vm.lightAccent +
                      "; --accent:" +
                      _vm.meeting.pageLayout.accentColor +
                      ";",
                    attrs: {
                      min: _vm.minDate,
                      "full-width": "",
                      "picker-date": _vm.monthToShow,
                      "no-title": true,
                      "first-day-of-week": _vm.$store.state.firstDayOfWeek
                    }
                  }),
                  _c("div", { staticClass: "brand-medium my-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.DateTime.now().toLocaleString({
                          month: "long",
                          day: "numeric"
                        })
                      )
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      staticStyle: { "flex-wrap": "wrap" }
                    },
                    _vm._l(_vm.availableSlots(), function(slot, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "slot pointer row-format centered",
                          style:
                            "--background-color: " +
                            _vm.meeting.pageLayout.buttonColor +
                            "; --accent-color: " +
                            _vm.meeting.pageLayout.accentColor +
                            "; --light-accent:" +
                            _vm.lightAccent,
                          on: {
                            click: function($event) {
                              return _vm.selectTimeSlot(slot)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              class:
                                "pa-1 " +
                                (_vm.selectedSlot &&
                                _vm.selectedSlot.start === slot.start
                                  ? "selected"
                                  : "")
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DateTime.fromISO(
                                      slot.start
                                    ).toLocaleString(_vm.DateTime.TIME_SIMPLE)
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "selected selected-slot pa-1",
                              style:
                                "overflow: hidden; " +
                                (_vm.selectedSlot &&
                                _vm.selectedSlot.start === slot.start
                                  ? "visibility:visible; width:50%"
                                  : "visibility:hidden; width:0px"),
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.timeConfirmed.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.meeting.scheduleMeeting.confirmText
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }