var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.modals, function(modal, index) {
      return _c(
        "div",
        { key: modal.id },
        [
          modal.type === "standard"
            ? _c(
                "basic-modal",
                {
                  style: "z-index: " + (200 + index),
                  attrs: {
                    dialog: modal.dialog,
                    "close-on-escape": modal.closeOnEscape,
                    fullscreen: modal.fullScreen,
                    "retain-focus": modal.retainFocus,
                    persistent: modal.persistent,
                    "overlay-color": "modal_overlay"
                  },
                  on: {
                    close: function($event) {
                      return _vm.handleClose(modal)
                    }
                  }
                },
                [
                  _c(
                    modal.component,
                    _vm._b(
                      {
                        key: "component-" + modal.id,
                        ref: modal.id,
                        refInFor: true,
                        tag: "component",
                        on: {
                          result: function($event) {
                            return _vm.onResult(modal, $event)
                          },
                          error: function($event) {
                            return _vm.onError(modal, $event)
                          }
                        }
                      },
                      "component",
                      modal.binding,
                      false
                    )
                  )
                ],
                1
              )
            : _vm._e(),
          modal.type === "right"
            ? _c(
                "right-modal",
                {
                  style: "z-index: " + (200 + index),
                  attrs: {
                    persistent: modal.persistent,
                    "close-on-escape": modal.closeOnEscape
                  },
                  on: {
                    close: function($event) {
                      return _vm.handleClose(modal)
                    }
                  }
                },
                [
                  _c(
                    modal.component,
                    _vm._b(
                      {
                        key: "component-" + modal.id,
                        ref: modal.id,
                        refInFor: true,
                        tag: "component",
                        on: {
                          result: function($event) {
                            return _vm.onResult(modal, $event)
                          },
                          error: function($event) {
                            return _vm.onError(modal, $event)
                          }
                        }
                      },
                      "component",
                      modal.binding,
                      false
                    )
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }