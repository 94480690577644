var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pr-4 stratos",
      staticStyle: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
        "background-color": "var(--v-white-base)"
      }
    },
    [
      _c(
        "v-container",
        { staticClass: "fill-height", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-left column-format",
                  attrs: { md: "5", cols: "12" }
                },
                [
                  _c("img", {
                    staticClass: "pointer",
                    staticStyle: { "max-width": "150px" },
                    attrs: {
                      src:
                        "/images/logo/logo-" +
                        (_vm.$vuetify.theme.dark ? "white" : "full") +
                        ".png"
                    },
                    on: {
                      click: function($event) {
                        return _vm.openMoxie()
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "flex-grow-1 row-format centered" },
                    [_c("login-core")],
                    1
                  )
                ]
              ),
              _c("v-col", { attrs: { md: "7", cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "right-side column-format centered",
                    style: _vm.$vuetify.breakpoint.mdAndUp
                      ? "height: calc(var(--vh) - 68px)"
                      : "padding: 24px"
                  },
                  [
                    _c("div", { staticClass: "main-quote-box" }, [
                      _c(
                        "div",
                        { staticClass: "quote-box" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pa-12 text-left gap-4 column-format"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "trustpilot-widget",
                                  attrs: {
                                    "data-locale": "en-US",
                                    "data-template-id":
                                      "539ad0ffdec7e10e686debd7",
                                    "data-businessunit-id":
                                      "64514967fed1aeb46fc6303a",
                                    "data-style-height": "350px",
                                    "data-style-width": "100%",
                                    "data-theme": "light",
                                    "data-stars": "5",
                                    "data-review-languages": "en"
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://www.trustpilot.com/review/withmoxie.com",
                                        target: "_blank",
                                        rel: "noopener"
                                      }
                                    },
                                    [_vm._v("Trustpilot")]
                                  )
                                ]
                              )
                            ]
                          ),
                          _c("v-img", {
                            staticClass: "quotes",
                            attrs: {
                              src: _vm.$vuetify.theme.dark
                                ? "images/login/quotes-black.svg"
                                : "images/login/quotes.svg"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.$vuetify.breakpoint.smAndDown ||
                      (_vm.$vuetify.breakpoint.height > 600 &&
                        _vm.$vuetify.breakpoint.width > 1100)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row-format text-left px-4 pt-8 gap-2",
                              staticStyle: {
                                width: "100%",
                                "justify-content": "space-between",
                                color: "var(--v-white-base)",
                                "flex-wrap": "wrap",
                                "white-space": "nowrap"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "column-format gap-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "row-format align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v("check_circle")]
                                      ),
                                      _c("div", { staticClass: "ml-1" }, [
                                        _vm._v("Sell your services")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "row-format align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v("check_circle")]
                                      ),
                                      _c("div", { staticClass: "ml-1" }, [
                                        _vm._v("Kick off new contracts")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "row-format align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v("check_circle")]
                                      ),
                                      _c("div", { staticClass: "ml-1" }, [
                                        _vm._v("Manage projects")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "column-format gap-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "row-format align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v("check_circle")]
                                      ),
                                      _c("div", { staticClass: "ml-1" }, [
                                        _vm._v("Communicate with clients")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "row-format align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v("check_circle")]
                                      ),
                                      _c("div", { staticClass: "ml-1" }, [
                                        _vm._v("Track your finances")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "row-format align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "white" } },
                                        [_vm._v("check_circle")]
                                      ),
                                      _c("div", { staticClass: "ml-1" }, [
                                        _vm._v("Keep it all together")
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }