var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", [
        _c("div", { staticClass: "row-format align-start" }, [
          _c(
            "div",
            { staticClass: "column-format" },
            [
              _c("div", { staticClass: "font-gray_80 mb-1" }, [
                _vm._v(_vm._s(_vm.$t("connected-apps.google.desc")))
              ]),
              _vm.isConnected
                ? _c("connected")
                : _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "pointer",
                        on: { click: _vm.connectGoogleAccount }
                      },
                      [
                        _c("img", {
                          attrs: {
                            width: "200",
                            src: "images/google/sign_in_with_google.png"
                          }
                        })
                      ]
                    )
                  ])
            ],
            1
          ),
          _vm.isConnected
            ? _c(
                "div",
                {
                  staticClass: "ml-auto pointer",
                  on: {
                    click: function($event) {
                      _vm.expanded = !_vm.expanded
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.isConnected && _vm.expanded
          ? _c(
              "div",
              [
                _vm._l(_vm.googleConnections, function(conn) {
                  return _c(
                    "div",
                    {
                      key: conn.googleUser.email,
                      staticClass: "pt-4 pb-4",
                      staticStyle: {
                        "border-bottom": "1px solid var(--v-gray_50-base)"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-14" },
                        [
                          _c("div", [
                            _c("span", { staticClass: "brand-medium" }, [
                              _vm._v("Google Account:")
                            ]),
                            _vm._v(" " + _vm._s(conn.googleUser.name))
                          ]),
                          _c("div", [
                            _c("span", { staticClass: "brand-medium" }, [
                              _vm._v("Google User Email:")
                            ]),
                            _vm._v(" " + _vm._s(conn.googleUser.email))
                          ]),
                          conn.calendarList && conn.calendarList.length
                            ? _c(
                                "div",
                                { staticClass: "my-3" },
                                _vm._l(conn.calendarList, function(cal) {
                                  return _c(
                                    "div",
                                    {
                                      key: cal.calendarId,
                                      staticClass: "column-format",
                                      staticStyle: {
                                        "border-bottom":
                                          "1px solid var(--v-gray_30-base)"
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: cal.description,
                                          "hide-details": "",
                                          dense: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateSettings(conn)
                                          }
                                        },
                                        model: {
                                          value: cal.enabled,
                                          callback: function($$v) {
                                            _vm.$set(cal, "enabled", $$v)
                                          },
                                          expression: "cal.enabled"
                                        }
                                      }),
                                      cal.enabled
                                        ? _c("v-checkbox", {
                                            staticClass: "ml-5",
                                            attrs: {
                                              label: "Show events in calendar",
                                              "hide-details": "",
                                              dense: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateSettings(conn)
                                              }
                                            },
                                            model: {
                                              value: cal.showInCalendar,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  cal,
                                                  "showInCalendar",
                                                  $$v
                                                )
                                              },
                                              expression: "cal.showInCalendar"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary-action mt-1",
                              on: {
                                click: function($event) {
                                  return _vm.confirmDisconnect(
                                    conn.googleUser.email
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("$delete")
                              ]),
                              _vm._v("Disconnect")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                }),
                _c("div", { staticClass: "mt-4" }, [
                  _c("div", { staticClass: "brand-medium" }, [
                    _vm._v("Connect an additional account:")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      on: { click: _vm.connectGoogleAccount }
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "200",
                          src: "images/google/sign_in_with_google.png"
                        }
                      })
                    ]
                  )
                ])
              ],
              2
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }