var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "proposal-deliverables" } },
    [
      _c(
        "v-container",
        {
          staticClass: "pa-0 ma-0",
          style: _vm.visible ? "" : "opacity: 0.3",
          attrs: { fluid: "" }
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", sm: "7", align: "left" } }, [
                _c(
                  "div",
                  { style: _vm.headerStyle },
                  [
                    !_vm.editMode
                      ? _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.lHeader) }
                        })
                      : _vm._e(),
                    _vm.editMode
                      ? _c("editor", {
                          ref: "headerEditor",
                          staticClass: "grey-dots",
                          attrs: {
                            "api-key": _vm.$store.getters.getTinyMceKey,
                            "initial-value": _vm.lHeader,
                            inline: true,
                            init: _vm.mceConfigCover,
                            spellcheck: true
                          },
                          on: { input: _vm.handleHeaderInput }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm.totalUpfrontAmount &&
                _vm.lAutomations.paymentRequiredToStart
                  ? _c(
                      "div",
                      { staticClass: "font-14 font-gray_70 font-italic" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$formatters.dollars(
                                _vm.totalUpfrontAmount,
                                true,
                                true,
                                _vm.currency
                              )
                            ) +
                            " payment required before project start. "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _c(
                "v-col",
                {
                  class:
                    "align-start " +
                    (_vm.$vuetify.breakpoint.xsOnly ? "my-n5" : "justify-end") +
                    " d-flex",
                  staticStyle: { gap: "8px" },
                  attrs: { cols: "12", sm: "5", align: "right" }
                },
                [
                  _vm.lFeeSchedule
                    ? [
                        _c(
                          "div",
                          {
                            class:
                              "pr-2 column-format" +
                              (_vm.editMode ? " feeEditMode" : ""),
                            staticStyle: {
                              "text-align": "right",
                              width: "fit-content"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openFeeSchedule()
                              }
                            }
                          },
                          [
                            _c("div", {
                              staticClass: "text-right mr-n3",
                              style: _vm.headerStyle,
                              domProps: {
                                innerHTML: _vm._s(_vm.feeSchedulePrimary)
                              }
                            }),
                            _c("div", {
                              staticClass: "text-right mr-n3",
                              style: _vm.bodyStyle,
                              domProps: {
                                innerHTML: _vm._s(_vm.feeScheduleSecondary)
                              }
                            })
                          ]
                        ),
                        _vm.createPackageAllowed && _vm.editMode
                          ? _c(
                              "v-menu",
                              {
                                attrs: {
                                  "nudge-bottom": 0,
                                  elevation: 0,
                                  "content-class": "add-new-menu",
                                  bottom: "",
                                  left: "",
                                  rounded: "",
                                  "offset-overflow": "",
                                  "offset-y": "",
                                  transition: "slide-y-transition"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(menu) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "secondary-action",
                                                attrs: { icon: "" }
                                              },
                                              menu.on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "20" } },
                                                [_vm._v("$moreHorizontal")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  681035982
                                )
                              },
                              [
                                _c("div", { staticClass: "add-new-dropdown" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "add-new-item nowrap",
                                      on: {
                                        click: function($event) {
                                          return _vm.openFeeSchedule()
                                        }
                                      }
                                    },
                                    [_vm._v(" Edit settings ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "add-new-item nowrap",
                                      on: { click: _vm.createPackage }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "templates.save-as-deliverable-package"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _vm.lDeliverables.length > 0
            ? _c(
                "v-row",
                {
                  staticClass: "mt-2",
                  class: _vm.deliverableCss,
                  style: _vm.bodyStyle
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
                        [
                          _c(
                            "draggable",
                            {
                              attrs: { handle: ".deliverableHandle" },
                              on: { end: _vm.dragEnd },
                              model: {
                                value: _vm.lDeliverables,
                                callback: function($$v) {
                                  _vm.lDeliverables = $$v
                                },
                                expression: "lDeliverables"
                              }
                            },
                            _vm._l(_vm.lDeliverables, function(item, index) {
                              return _c(
                                "div",
                                { key: item.id },
                                [
                                  _c("deliverable", {
                                    attrs: {
                                      index: index,
                                      currency: _vm.currency,
                                      "tax-label": _vm.taxLabel,
                                      "tax-rate": _vm.defaultTaxRate,
                                      "fee-schedule": _vm.feeSchedule,
                                      deliverable: item,
                                      "edit-mode": _vm.editMode,
                                      "show-due-date":
                                        _vm.anyDeliverableWithDueDate,
                                      "show-pricing":
                                        _vm.showDeliverablePricing,
                                      "main-deliverable-columns":
                                        _vm.mainDeliverableColumns,
                                      "show-product-select":
                                        (_vm.feeSchedule &&
                                          _vm.feeSchedule.feeType ===
                                            "Per Item") ||
                                        item.initialSetupTask
                                    },
                                    on: {
                                      delete: function($event) {
                                        return _vm.deleteDeliverable(item.id)
                                      },
                                      changed: _vm.notifyChange
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "text-left", style: _vm.bodyStyle },
            [
              _vm.editMode
                ? _c(
                    "v-col",
                    {
                      staticClass: "pa-0 ma-0 gap-3 row-format",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-0",
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.addDeliverable(false)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: "16" } }, [
                            _vm._v("$plusCircle")
                          ]),
                          _c("span", { staticClass: "font-14 brand-medium" }, [
                            _vm._v(_vm._s(_vm.$t("proposal.deliverables.add")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-0",
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.addDeliverable(true)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: "16" } }, [
                            _vm._v("$plusCircle")
                          ]),
                          _c("span", { staticClass: "font-14 brand-medium" }, [
                            _vm._v(
                              _vm._s(_vm.$t("proposal.deliverables.add-setup"))
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("input", {
                attrs: { type: "hidden" },
                domProps: { value: _vm.totalUpfrontAmount }
              }),
              _vm.deliverablesCount > 1
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-1",
                      attrs: { cols: "12", align: "left" }
                    },
                    [
                      _vm.deliverablesCount > 0 && !_vm.forceAllDeliverables
                        ? _c(
                            "v-btn",
                            {
                              class: !_vm.clientMode ? "mt-2" : "",
                              attrs: {
                                disabled: !_vm.clientMode,
                                color: "primary",
                                outlined: !_vm.selectedPackage
                              },
                              on: { click: _vm.packageSelect }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedPackage
                                      ? _vm.$t("proposal.deliverables.selected")
                                      : _vm.$t(
                                          "proposal.deliverables.select-this"
                                        )
                                  ) +
                                  " "
                              ),
                              _vm.selectedPackage
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { "x-small": "" }
                                    },
                                    [_vm._v("$check")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.clientMode && !_vm.isLocked
                        ? _c("div", { staticClass: "mt-2" }, [
                            _c(
                              "div",
                              {
                                staticClass: "font-primary pointer",
                                attrs: { text: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleMultipleDeliverables.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v("*" + _vm._s(_vm.buttonLabel))]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.editFees
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.editFees, persistent: true },
              on: {
                close: function($event) {
                  _vm.editFees = false
                }
              }
            },
            [
              _c("deliverable-fees", {
                attrs: {
                  currency: _vm.currency,
                  "fee-schedule": _vm.lFeeSchedule,
                  automations: _vm.lAutomations,
                  "payment-node-count": _vm.paymentNodeCount
                },
                on: {
                  change: _vm.handleFeeChange,
                  close: function($event) {
                    _vm.editFees = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }