import axios from 'axios';
import store from '@/store';
import router from '@/router';
import AuthService from '../modules/auth/AuthService';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const refreshLogic = (failedRequest) => {
	return AuthService.refreshToken()
		.then((res) => {
			store.commit('logIn', res);
			failedRequest.response.config.headers['Authorization'] = 'Bearer ' + res.authToken;
			return Promise.resolve();
		})
		.catch((err) => {
			console.log(err);
			store.commit('logOut');
			router.push('/login');
			return Promise.reject();
		});
};


const getClient = (baseUrl = null, useAccessToken = true, version = 1) => {
	if(baseUrl === null && version === 2){
		baseUrl = store.getters.getBaseUrlV2;
	}else if(baseUrl === null){
		baseUrl = store.getters.getBaseUrl;
	}

	const options = {
		baseURL: baseUrl,
		withCredentials: true,
	};

	const client = axios.create(options);

	if(useAccessToken) {
		createAuthRefreshInterceptor(client, refreshLogic);
	}

	if (useAccessToken && store.state.accessToken) {
		client.interceptors.request.use(request => {
			request.headers['Authorization'] = `Bearer ${store.state.accessToken}`;
			return request;
		});
	}

	client.interceptors.response.use(
		function(response){
			return response;
		},
		function(error){
			return Promise.reject(error);
		}
	);

	return client;
};



/**
 * Base HTTP Client
 */
export default class HttpClient{

	baseUrl;
	useAccessToken;
	version;

	constructor(baseUrl = null, useAccessToken = true, version = 1) {
		this.baseUrl = baseUrl;
		this.useAccessToken = useAccessToken;
		this.version = version;
	}

	// Provide request methods with the default base_url
	get(url, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken, this.version)
			.get(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	delete(url, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken, this.version)
			.delete(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	head(url, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken, this.version)
			.head(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	options(url, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken, this.version)
			.options(url, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	post(url, data = {}, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken, this.version)
			.post(url, data, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	put(url, data = {}, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken, this.version)
			.put(url, data, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}

	patch(url, data = {}, conf = {}) {
		return getClient(this.baseUrl, this.useAccessToken, this.version)
			.patch(url, data, conf)
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
	}
}
