var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row-format flex-wrap gap-4 text-left",
      staticStyle: { width: "100%" }
    },
    [
      _c("input", {
        attrs: { id: "copyInput", name: "copyInput", type: "hidden" }
      }),
      _c(
        "div",
        { class: "column-format left-side " + _vm.$store.getters.scroll },
        [_c("work-two-week")],
        1
      ),
      _c(
        "div",
        {
          class: "column-format right-side gap-4 " + _vm.$store.getters.scroll
        },
        [
          _vm.fullUser
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "brand-medium pb-2" }, [
                    _vm._v("Add new")
                  ]),
                  _c("focus-quick-links")
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "brand-box" },
            [_c("day-calendar", { attrs: { "is-visible": _vm.isVisible } })],
            1
          ),
          _vm.hasAnyQuickLinks
            ? _c(
                "div",
                { staticClass: "brand-box" },
                [
                  _c(
                    "v-container",
                    { staticClass: "font-14 pb-4" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              { staticClass: "brand-medium font-16 mb-2" },
                              [_vm._v("Quick links")]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.clientPortal
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "row-format align-center",
                                  attrs: { cols: "5" }
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("public")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "brand-medium font-gray_70 ml-1"
                                    },
                                    [_vm._v("Client portal")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "brand-bold",
                                  attrs: { cols: "7" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "copy-link",
                                      on: {
                                        click: function($event) {
                                          return _vm.copyToClipboard(
                                            "https://" + _vm.clientPortal + "/"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.clientPortal) + " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.communicatorPhone
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "row-format align-center",
                                  attrs: { cols: "5" }
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("phone")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "brand-medium font-gray_70 ml-1"
                                    },
                                    [_vm._v("My phone")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "brand-bold",
                                  attrs: { cols: "7" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "copy-link",
                                      on: {
                                        click: function($event) {
                                          return _vm.copyToClipboard(
                                            _vm.communicatorPhone
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.communicatorPhone))]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.meetings.length
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { attrs: { cols: "5" } }, [
                                _c(
                                  "div",
                                  { staticClass: "row-format align-center" },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("date_range")
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "brand-medium font-gray_70 ml-1"
                                      },
                                      [_vm._v("Meeting schedulers")]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "brand-bold",
                                  attrs: { cols: "7" }
                                },
                                [
                                  _vm._l(_vm.filteredMeetings, function(
                                    meeting,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: meeting.id + index,
                                        staticClass: "row-format copy-link"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.copyToClipboard(
                                                  _vm.fullMeetingLink(meeting)
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(meeting.name))]
                                        )
                                      ]
                                    )
                                  }),
                                  _vm.inScopeMeetings.length >
                                  _vm.filteredMeetings.length
                                    ? _c("div", { staticClass: "row-format" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-12 font-primary pointer",
                                            on: {
                                              click: function($event) {
                                                _vm.showAllMeetings = true
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " + " +
                                                _vm._s(
                                                  _vm.inScopeMeetings.length -
                                                    _vm.filteredMeetings.length
                                                ) +
                                                " more schedulers "
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.filteredMeetings.length > 3
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-12 font-primary pointer",
                                            on: {
                                              click: function($event) {
                                                _vm.showAllMeetings = false
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " - Hide " +
                                                _vm._s(
                                                  _vm.meetings.length - 3
                                                ) +
                                                " schedulers "
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }