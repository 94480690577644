var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("date-selector", {
        attrs: {
          micro: _vm.mode !== "standard" && _vm.mode !== "dense",
          standard: _vm.mode === "standard" || _vm.mode === "dense",
          "show-year": true,
          "micro-font": "font-14",
          date: _vm.value,
          label:
            _vm.mode === "standard" || _vm.mode === "dense"
              ? _vm.field.name
              : "--"
        },
        on: {
          change: function($event) {
            _vm.ourValue = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }