var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "invoice-details" } },
    [
      _vm.invoice
        ? _c("div", { attrs: { id: "header-area" } }, [
            _c(
              "div",
              { staticClass: "header-left" },
              [
                _c("div", { staticClass: "mr-6 brand-medium pl-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("invoice.invoice-number-appended", {
                          invoiceNumber: _vm.invoice.invoiceNumberFormatted
                        })
                      ) +
                      " "
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "mr-2",
                    on: {
                      click: function($event) {
                        return _vm.showModal("EVENTS")
                      }
                    }
                  },
                  [_c("events-modal", { attrs: { invoice: _vm.invoice } })],
                  1
                ),
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-click": true,
                          "nudge-bottom": 30,
                          disabled: !_vm.invoice.payments.length
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "secondary-action mr-2",
                                        attrs: {
                                          disabled: !_vm.invoice.payments.length
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { size: "14" }
                                        },
                                        [_vm._v("$time")]
                                      ),
                                      _c("span", { staticClass: "body-12" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "invoice.details.payment-history"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm.invoice.payments.length
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "body-10 ml-2 px-1",
                                              staticStyle: {
                                                "background-color":
                                                  "var(--v-gray_10-base)"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.invoice.payments.length
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1651057996
                        )
                      },
                      [
                        _c("payment-history", {
                          attrs: { invoice: _vm.invoice },
                          on: {
                            openPayment: function($event) {
                              return _vm.openPaymentDetail($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("pipeline-link-widget", {
                  attrs: {
                    "item-id": _vm.invoice.id,
                    "item-type": "Invoice",
                    "client-id": _vm.invoice.clientId
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-right pr-md-6 pr-4" },
              [
                _vm.invoice.integrationKeys.quickbooksId ||
                _vm.invoice.integrationKeys.xeroId
                  ? _c(
                      "div",
                      {
                        staticClass: "row-format align-center accounting",
                        staticStyle: { position: "relative" }
                      },
                      [
                        _vm.invoice.integrationKeys.quickbooksId
                          ? _c(
                              "v-icon",
                              {
                                directives: [
                                  { name: "tippy", rawName: "v-tippy" }
                                ],
                                attrs: {
                                  size: "20",
                                  content:
                                    "Linked to QBO record: " +
                                    _vm.invoice.integrationKeys.quickbooksId
                                }
                              },
                              [_vm._v("$quickbooks")]
                            )
                          : _vm._e(),
                        _vm.invoice.integrationKeys.xeroId
                          ? _c(
                              "v-icon",
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy:content",
                                    value:
                                      "Linked to Xero record: " +
                                      _vm.invoice.integrationKeys.xeroId,
                                    expression:
                                      "`Linked to Xero record: ${invoice.integrationKeys.xeroId}`",
                                    arg: "content"
                                  }
                                ],
                                attrs: { size: "20" }
                              },
                              [_vm._v("$xero")]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "unlink",
                            staticStyle: {
                              "background-color": "var(--v-white-base)",
                              position: "absolute",
                              right: "12px",
                              top: "-8px",
                              "border-radius": "20px"
                            },
                            on: { click: _vm.confirmUnlink }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "pointer",
                                attrs: { size: "20", color: "red" }
                              },
                              [_vm._v("cancel")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mr-2 d-none d-md-inline-flex font-gray_70" },
                  [_vm._v(_vm._s(_vm.saveStatus))]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-1 primary-action",
                    attrs: {
                      width: "96",
                      disabled: ["VOIDED", "WRITE-OFF", "PAID"].includes(
                        _vm.invoice.status
                      )
                    },
                    on: {
                      click: function($event) {
                        return _vm.updateInvoice()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mx-2 super-action",
                    attrs: { elevation: "0", width: "96" },
                    on: {
                      click: function($event) {
                        return _vm.selectSendMethod()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.share")) + " ")]
                ),
                _vm.invoice
                  ? _c(
                      "div",
                      { staticClass: "ml-1" },
                      [
                        _c("action-menu", {
                          ref: "ActionMenu",
                          attrs: {
                            invoice: _vm.invoice,
                            "show-duplicate": _vm.showDuplicate
                          },
                          on: {
                            paid: function($event) {
                              return _vm.openPaymentDetail($event)
                            },
                            "delete-invoice": function($event) {
                              return _vm.deleteInvoice($event)
                            },
                            "set-invoice": function($event) {
                              return _vm.setInvoice($event)
                            },
                            "duplicate-invoice": function($event) {
                              return _vm.duplicateInvoice($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "ml-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleClose()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { size: 20 } }, [
                          _vm._v("$close")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.invoice && _vm.account
        ? _c(_vm.rendererType, {
            key: _vm.rendererKey,
            tag: "component",
            staticStyle: {
              "max-height": "calc(100vh - 60px)",
              height: "calc(100vh - 60px)"
            },
            attrs: {
              invoice: _vm.invoice,
              "item-focus": _vm.itemFocus,
              account: _vm.account,
              "deposit-account": _vm.depositAccount,
              "edit-mode": true,
              "force-render-index": _vm.forceRenderIndex,
              "attachment-service": _vm.attachmentService,
              "invoice-service": _vm.invoiceService,
              "has-expenses": _vm.hasExpenses,
              "additional-tax-rates": _vm.additionalTaxRates,
              "scheduled-payments": _vm.invoice.scheduledPayments
            },
            on: {
              "force-save": function($event) {
                return _vm.save()
              },
              "pause-auto-save": function($event) {
                return _vm.pauseAutoSave($event)
              },
              updated: function($event) {
                return _vm.updated($event)
              },
              "product-modal": function($event) {
                return _vm.showModal("PRODUCTS")
              },
              "tracked-item-modal": function($event) {
                return _vm.handleEditInvoiceItems()
              },
              "apply-deposit-modal": function($event) {
                return _vm.showModal("APPLY-DEPOSIT")
              },
              "account-info-modal": function($event) {
                return _vm.showModal("ACCOUNT-INFO")
              },
              "client-info-modal": function($event) {
                return _vm.showModal("CLIENT-INFO")
              },
              "expenses-modal": function($event) {
                return _vm.showModal("EXPENSES-MODAL")
              },
              "payment-terms-modal": function($event) {
                return _vm.showPaymentTermsModal()
              },
              "payment-instructions-modal": function($event) {
                return _vm.showModal("PAYMENT-INSTRUCTIONS")
              },
              "invoice-number-format-modal": function($event) {
                return _vm.showModal("INVOICE-NUMBER-FORMAT")
              },
              "item-hours-modal": function($event) {
                return _vm.handleItemHoursModal($event)
              },
              "item-price-modal": function($event) {
                return _vm.handleItemPriceModal($event)
              },
              "payment-action": _vm.handlePaymentAction,
              "waive-late-fee": _vm.handleWaiveLateFee,
              "payment-connect": _vm.navigateToPaymentConnect,
              "tax-rate-selected": function($event) {
                return _vm.taxRateSelected($event)
              }
            }
          })
        : _vm._e(),
      _c(
        "basic-modal",
        {
          ref: "clientFeesModal",
          attrs: {
            dialog: _vm.selectedModal === "CLIENT-FEES",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _c("client-hourly-amount-modal", {
            attrs: { client: _vm.client },
            on: {
              close: function($event) {
                return _vm.showModal(null)
              },
              "update-client-fees": function($event) {
                _vm.handleUpdateClientFees($event)
                _vm.showModal(null)
              }
            }
          })
        ],
        1
      ),
      _c(
        "modal",
        {
          ref: "stripefModal",
          attrs: {
            dialog: _vm.selectedModal === "STRIPE",
            persistent: false,
            "max-width": 540
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _c("stripe-modal", {
            attrs: { account: _vm.account },
            on: {
              close: function($event) {
                _vm.selectedModal = null
              }
            }
          })
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          ref: "itemPriceModal",
          attrs: {
            dialog: _vm.selectedModal === "ITEM-PRICE",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _vm.itemModalIndex > -1
            ? _c("item-price-modal", {
                key: _vm.itemModalKey,
                attrs: {
                  invoice: _vm.invoice,
                  "invoice-item": _vm.invoice.items[_vm.itemModalIndex]
                },
                on: {
                  close: function($event) {
                    return _vm.itemPriceModalClose()
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          ref: "accountInfoModal",
          attrs: {
            dialog: _vm.selectedModal === "ACCOUNT-INFO",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _vm.invoice
            ? _c("account-info-modal", {
                attrs: { "account-info": _vm.invoice.accountInfo },
                on: {
                  "set-account-info": _vm.setAccountInfo,
                  close: function($event) {
                    return _vm.showModal(null)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          ref: "clientInfoModal",
          attrs: {
            dialog: _vm.selectedModal === "CLIENT-INFO",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _vm.invoice
            ? _c("client-info-modal", {
                attrs: {
                  "client-info": _vm.invoice.clientInfo,
                  "client-id": _vm.invoice.clientId
                },
                on: {
                  close: function($event) {
                    return _vm.showModal(null)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          ref: "paymentTermsModal",
          attrs: {
            dialog: _vm.selectedModal === "PAYMENT-TERMS",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _vm.invoice
            ? _c("payment-terms-modal", {
                attrs: {
                  "payment-terms": _vm.invoice.paymentTerms,
                  "client-id": _vm.invoice.clientId
                },
                on: {
                  close: function($event) {
                    return _vm.showModal(null)
                  },
                  "delete-terms": function($event) {
                    _vm.invoice.paymentTerms = null
                  },
                  "payment-days-change": _vm.handlePaymentDaysChange
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          ref: "paymentInstructionsModal",
          attrs: {
            dialog: _vm.selectedModal === "PAYMENT-INSTRUCTIONS",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _vm.invoice
            ? _c("payment-instructions-modal", {
                attrs: {
                  "pay-instructions": _vm.invoice.payInstructions,
                  account: _vm.account
                },
                on: {
                  close: function($event) {
                    return _vm.showModal(null)
                  },
                  save: function($event) {
                    return _vm.handlePayInstructionsSave($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          ref: "shareSendModal",
          attrs: {
            dialog: _vm.selectedModal === "SEND",
            persistent: false,
            "retain-focus": false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _vm.invoice
            ? _c("send-widget", {
                attrs: {
                  "client-id": _vm.invoice.clientId,
                  "invoice-id": _vm.invoice.id,
                  "open-state": _vm.selectedModal === "SEND"
                },
                on: {
                  alert: function($event) {
                    return _vm.scope.setAlert($event)
                  },
                  cancel: function($event) {
                    return _vm.showModal(null)
                  },
                  "invoice-sent": function($event) {
                    return _vm.handleInvoiceSent($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          attrs: {
            dialog: _vm.selectedModal === "ITEM-HOURS",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _vm.itemModalIndex > -1
            ? _c("item-hours-modal", {
                key: _vm.itemModalKey,
                attrs: {
                  "invoice-item": _vm.invoice.items[this.itemModalIndex],
                  invoice: _vm.invoice
                },
                on: {
                  save: function($event) {
                    return _vm.updateItemHours($event)
                  },
                  close: function($event) {
                    return _vm.showModal(null)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          attrs: {
            dialog: _vm.selectedModal === "APPLY-DEPOSIT",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _c("apply-deposit-modal", {
            attrs: {
              invoice: _vm.invoice,
              "deposit-account": _vm.depositAccount
            },
            on: {
              save: function($event) {
                return _vm.save()
              },
              close: function($event) {
                return _vm.showModal(null)
              }
            }
          })
        ],
        1
      ),
      _vm.selectedModal === "CREATE-EDIT-INVOICE"
        ? _c(
            "basic-modal",
            {
              attrs: {
                dialog: _vm.selectedModal === "CREATE-EDIT-INVOICE",
                persistent: false
              },
              on: {
                close: function($event) {
                  return _vm.showModal(null)
                }
              }
            },
            [
              _vm.invoice
                ? _c("edit-invoice-modal", {
                    key: _vm.createEditInvoiceKey,
                    attrs: {
                      invoice: _vm.invoice,
                      client: _vm.invoice.clientInfo
                    },
                    on: {
                      close: function($event) {
                        return _vm.showModal(null)
                      },
                      save: function($event) {
                        return _vm.save()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.selectedModal === "EXPENSES-MODAL"
        ? _c(
            "basic-modal",
            {
              attrs: {
                dialog: _vm.selectedModal === "EXPENSES-MODAL",
                persistent: false
              },
              on: {
                close: function($event) {
                  return _vm.showModal(null)
                }
              }
            },
            [
              _vm.invoice
                ? _c("expenses-modal", {
                    attrs: {
                      invoice: _vm.invoice,
                      client: _vm.invoice.clientInfo
                    },
                    on: {
                      close: function($event) {
                        return _vm.showModal(null)
                      },
                      save: function($event) {
                        return _vm.save()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "basic-modal",
        {
          attrs: {
            dialog: _vm.selectedModal === "EMAIL-OR-DOWNLOAD",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _c("email-or-download", {
            attrs: { invoice: _vm.invoice },
            on: {
              close: function($event) {
                return _vm.showModal(null)
              },
              "invoice-sent": function($event) {
                return _vm.handleInvoiceSent($event)
              },
              "send-email": function($event) {
                return _vm.saveAndSend()
              }
            }
          })
        ],
        1
      ),
      _vm.selectedModal === "PAYMENT-DETAIL"
        ? _c(
            "basic-modal",
            {
              attrs: {
                dialog: _vm.selectedModal === "PAYMENT-DETAIL",
                persistent: false
              },
              on: {
                close: function($event) {
                  return _vm.showModal(null)
                }
              }
            },
            [
              _c("payment-details-modal", {
                attrs: {
                  invoice: _vm.invoice,
                  payment: _vm.paymentToOpen,
                  "edit-mode": true
                },
                on: {
                  updated: function($event) {
                    return _vm.updated($event)
                  },
                  "payment-updated": function($event) {
                    return _vm.paymentUpdated($event)
                  },
                  close: function($event) {
                    return _vm.showModal(null)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "basic-modal",
        {
          attrs: {
            dialog: _vm.selectedModal === "INVOICE-NUMBER-FORMAT",
            persistent: false
          },
          on: {
            close: function($event) {
              return _vm.showModal(null)
            }
          }
        },
        [
          _vm.selectedModal === "INVOICE-NUMBER-FORMAT"
            ? _c("invoice-number-format-modal", {
                on: {
                  close: function($event) {
                    return _vm.showModal(null)
                  },
                  "set-account-info": function($event) {
                    return _vm.handleInvoiceNumberUpdate($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }