var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row-format align-center pointer",
      on: { click: _vm.createTimeEntry }
    },
    [
      _c("v-icon", { attrs: { color: "primary", small: "" } }, [
        _vm._v("timer")
      ]),
      _c("div", { staticClass: "font-primary ml-1" }, [
        _vm._v("Add time entry")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }