var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.view === "Day" || _vm.view === "Week"
    ? _c("div", { attrs: { id: "current-time-event" } }, [
        _vm._m(0),
        _c("div", { staticClass: "current-time text-left" }, [
          _vm._v(_vm._s(_vm.formattedTime))
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "time-line-wrapper" }, [
      _c("div", { staticClass: "current-time-circle" }),
      _c("div", { staticClass: "current-time-line" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }