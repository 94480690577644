<template>
	<div id="time-track-history-menu" v-if="history" :key="historyKey">
		<div id="subtotals-head" class="px-3">
			<div class="today">
				Today <span>{{ todaySubtotal | durationFormat(false) }}</span>
			</div>
			<div class="this-week">
				This week <span>{{ thisWeekSubtotal | durationFormat(false) }}</span>
			</div>
			<div class="close-button-box mr-0 ml-2 pointer" @click.stop="$emit('hideHistoryMenu')">
				<h-icon2 name="close-button" size="14" style="display: block; --icon-color: var(--v-white-base)"></h-icon2>
			</div>
		</div>

		<div class="day-wrapper">
			<div v-if="history.length === 0" class="column-format centered">
				<div style="text-align:center; font-size:14px" class="px-5 py-4" v-html="$t('timetrack.empty-history')"></div>
			</div>
			<div v-for="day in history" :key="day.id" class="day py-2">
				<div class="date-head">
					<div class="date ml-3">{{ $DateTime.humanDate(day.date) }}</div>
					<div class="duration mr-3">{{ day.duration | dailyDuration }}</div>
				</div>
				<div
					v-for="clientRollup in day.clientRollup"
					:key="clientRollup.key"
					:class="clientClass(clientRollup) + ' py-2'"
				>
					<div
						:class="clientHeadClass(clientRollup)"
						@click="handleClientRollupClick(clientRollup)"
						:ref="clientRollup.events.length == 1 ? 'event-' + clientRollup.id : ''"
					>
						<div class="avatar ml-3">
							<client-avatar v-if="clientRollup.client" :client="clientRollup.client" x-small left></client-avatar>
							<client-avatar
								v-else
								:client="{ id: '', name: '- -', color: 'var(--v-gray_30-base)' }"
								x-small
								left
							></client-avatar>
						</div>

						<div :class="clientNameClass(clientRollup)">
							<div
								class="row-format"
								style="align-items: center"
								v-html="$options.filters.clientNameOutput(clientRollup)"
							></div>
						</div>

						<div class="duration mr-3">{{ clientRollup.durationTotal | durationFormat }}</div>
					</div>
					<div :class="eventGroupClass(clientRollup)">
						<div
							v-for="event in clientRollup.events"
							:key="event.key"
							:class="eventClass(event) + ' mx-3'"
							:ref="'event-' + event.id"
							@click="handleEventClick(event.id)"
						>
							<div
								@click.stop="handleEventPlayClick(event)"
								:class="'play' + (isMobile ? ' mobile' : '')"
								:style="running ? 'visibility:hidden!important' : ''"
							>
								<v-icon size="10" color="gray_60" style="margin-left: 1px">$play</v-icon>
							</div>
							<div class="event-details ellipsis ml-3">
								<div class="event-description" v-html="eventDetailsOutput(event)"></div>
							</div>
							<div class="duration">{{ event.duration | durationFormat }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import TimeTrackService from './TimeTrackService';
	import ClientService from '../clients/ClientService';
	import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
	import { v4 as uuidv4 } from 'uuid';
	import smoothReflow from 'vue-smooth-reflow';
	import { mapGetters } from 'vuex';
	import { isMobile } from 'mobile-device-detect';

	export default {
		name: 'HistoryWidget',
		components: { ClientAvatar },
		mixins: [smoothReflow],
		//props: { currentTimer: { type: Object, required: false } },

		data() {
			return {
				eventList: null,
				clientList: null,
				timeTrackService: new TimeTrackService(),
				clientService: new ClientService(),

				history: null, // Array of daily summaries.
				historyKey: null,

				openClients: [], // Array of ids of grouped events that have been opened by the user.

				/*
						[
							{
								date: date,
								durationSubtotal: duration sum for date
								clientRollup: [
								  	{
										clientId: clientId,
										clientName: clientName,
										projectId: projectId,
										projectName: projectName,
										durationTotal: duration sum,
										events: []
									}
								]
							}
						]
					*/
				triangleX: null,
				triangleY: null,
				triangle: null, // dom object for pointer
				running: false, // current timer status
				isMobile: isMobile,
			};
		},

		mounted() {
			this.getEventList();
			this.getTimer();
			this.createTriangle();
			this.$smoothReflow([
				{
					// el: '.event-group',
					property: ['height'],
					transition: 'height .2s ease-in',
					/* transitionEvent: {
						selector: 'div.event-group',
						propertyName: 'height',
					}, */
				},
			]);

			this.$store.state.eventBus.$on(`a-${this.accountId}.timer-event-update`, this.handleTimerEventUpdate);
			this.$store.state.eventBus.$on(`a-${this.accountId}.timer-event-create`, this.handleTimerEventCreate);
			this.$store.state.eventBus.$on(`a-${this.accountId}.timer-event-delete`, this.handleTimerEventDelete);
			this.$store.state.eventBus.$on(`u-${this.userId}.timer-start`, this.handleTimerStartEvent);
			this.$store.state.eventBus.$on(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(`a-${this.accountId}.timer-event-update`, this.handleTimerEventUpdate);
			this.$store.state.eventBus.$off(`a-${this.accountId}.timer-event-create`, this.handleTimerEventCreate);
			this.$store.state.eventBus.$off(`a-${this.accountId}.timer-event-delete`, this.handleTimerEventDelete);
			this.$store.state.eventBus.$off(`u-${this.userId}.timer-start`, this.handleTimerStartEvent);
			this.$store.state.eventBus.$off(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
		},

		methods: {
			getTimer() {
				this.timeTrackService
					.getTimer()
					.then((res) => {
						this.currentTimer = res.data;
						if (this.currentTimer) {
							this.running = true;
						} else {
							this.running = false;
						}
					})
					.catch((err) => {
						console.log(err);
						this.running = false;
						this.currentTimer = null;
					});
			},
			startTimer(event) {
				this.timeTrackService
					.start(event)
					.then((res) => {
						this.$store.state.eventBus.$emit(`u-${this.userId}.timer-start`, {
							message: res.data,
						});
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			createTriangle() {
				let div = document.getElementById('triangle-div');
				if (div) {
					return;
				}
				div = document.createElement('div');
				div.id = 'triangle-div';
				div.className = 'alert';
				div.style.zIndex = 15;
				div.style.transform = 'rotate(45deg)';
				div.style.position = 'fixed';
				div.style.width = '20px';
				div.style.height = '20px';
				div.style.display = 'none';
				div.style.boxShadow = '2.5px -2.5px 2px 2px rgba(0,0,0,.08)';
				div.style.backgroundColor = '#ffffff';

				document.body.append(div);
			},
			showTriangle() {
				//console.log('width', document.documentElement.clientWidth);
				if (document.documentElement.clientWidth < 1100) return ''; // no min-width on smaller screens.
				let div = document.getElementById('triangle-div');
				if (!div) return false;
				div.style.left = this.triangleX + 'px';
				div.style.top = this.triangleY + 'px';
				div.style.display = 'block';
				return true;
			},
			hideTriangle() {
				let div = document.getElementById('triangle-div');
				if (div) div.style.display = 'none';
			},
			handleEventPlayClick(event) {
				this.$track.record('time-track start', { source: 'previous' });
				let newEvent = {
					clientId: event.clientId ? event.clientId : null,
					projectId: event.projectId ? event.projectId : null,
					deliverableId: event.deliverableId ? event.deliverableId : null,
					ticketId: event.ticketId ? event.ticketId : null,
					userId: this.userId,
					notes: event.notes ? event.notes : null,
				};
				this.startTimer(newEvent);
				this.$emit('hideHistoryMenu');
			},
			handleEventClick(eventId) {
				const event = this.eventList.find((v) => v.id === eventId);

				let binding = {
					timerEvent: event,
				};

				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then(() => {
					//console.log(res);
				});

				this.showTriangle();
			},
			handleClientRollupClick(clientRollup) {
				// this.hideTriangle();
				// if (clientRollup.events.length == 1) {
				// 	return this.handleEventClick(clientRollup.id);
				// }
				// Open/close rollup if multiple events exist
				const index = this.openClients.indexOf(clientRollup.id);
				//console.log('index', index);
				if (index > -1) this.openClients.splice(index, 1);
				else this.openClients.push(clientRollup.id);
				//console.log(this.openClients);
			},
			clientClass(clientRollup) {
				let css = ['client-section'];
				// if (this.openClients.indexOf(clientRollup.id) > -1) css.push('open');
				if (this.selectedEvent && this.selectedEvent.id === clientRollup.id) css.push('active');
				return css.join(' ');
			},
			eventGroupClass(clientRollup) {
				let css = ['event-group'];
				if (this.openClients.indexOf(clientRollup.id) > -1) css.push('open');
				if (this.running) css.push('running');
				return css.join(' ');
			},
			clientHeadClass(clientRollup) {
				let css = ['client-head'];
				if (clientRollup.events.length === 1 && this.selectedEvent && this.selectedEvent.id === clientRollup.id)
					css.push('active');
				if (this.running) css.push('running');
				if (clientRollup.events.length === 1) css.push('enable-play');
				return css.join(' ');
			},
			eventClass(event) {
				let css = ['event'];
				if (this.selectedEvent && this.selectedEvent.id === event.id) css.push('active');
				if (this.running) css.push('running');
				return css.join(' ');
			},
			projectEventCountClass(clientRollup) {
				let css = ['project-event-count'];
				if (this.openClients.indexOf(clientRollup.id) > -1) css.push('open');
				return css.join(' ');
			},
			clientNameClass(clientRollup) {
				let css = ['client-name'];
				if (this.openClients.indexOf(clientRollup.id) > -1) css.push('open');
				// if (clientRollup.events.length == 1) css.push('wider');
				else css.push('narrower');
				return css.join(' ');
			},
			eventCountClass(clientRollup, index) {
				let css = ['event-count'];
				if (this.openClients.indexOf(clientRollup.id) > -1) css.push('open');
				if (index + 1 === clientRollup.events.length) css.push('last');
				return css.join(' ');
			},

			getHistory() {
				let history = [];
				for (let i = 0; i < this.eventList.length; i++) {
					let event = this.eventList[i];
					//console.log('event', event);
					if (history.findIndex((v) => v.date.ts === event.date.ts) === -1) {
						history.push({
							date: event.date,
							duration: this.getDurationByDate(event.date),
							clientRollup: this.getClientRollup(event.date),
						});
					}
				}
				//console.log('history', history);
				this.historyKey = uuidv4();
				return history.sort(this.sortHistory);
			},

			setMostRecentDayClientsOpen() {
				if (this.history[0]) {
					for (let i = 0; i < this.history[0].clientRollup.length; i++) {
						let id = this.history[0].clientRollup[i].id;
						this.openClients.push(id);
					}
				}
			},

			getClientRollup(date) {
				let events = this.eventList.filter((v) => v.date.ts === date.ts).sort((a, b) => a.date.ts - b.date.ts);
				let clientRollup = [];

				for (let i = 0; i < events.length; i++) {
					let event = events[i];

					if (clientRollup.findIndex((v) => v.clientId === event.clientId) === -1) {
						clientRollup.push({
							id: event.date.ts + '-' + event.clientId,
							client: event.client,
							clientId: event.clientId,
							clientName: event.clientName,
							projectId: event.projectId,
							projectName: event.projectName,
							durationTotal: this.getDurationByClient(events, event.clientId),
							events: this.getClientEvents(date, event.clientId),
						});
					}
				}
				return clientRollup;
			},
			getClientEvents(date, clientId) {
				return this.eventList.filter((v) => v.date.ts === date.ts && v.clientId === clientId);
			},
			getDurationByDate(date) {
				let events = this.eventList.filter((v) => v.date.ts === date.ts);
				let subtotal = events.reduce(
					(accumulator, v) => v.duration.plus(accumulator),
					this.$luxon.Duration.fromMillis(0)
				);
				return subtotal;
			},
			getDurationByClient(eventsOnDate, clientId) {
				let events = eventsOnDate.filter((v) => v.clientId === clientId);
				let subtotal = events.reduce(
					(accumulator, v) => v.duration.plus(accumulator),
					this.$luxon.Duration.fromMillis(0)
				);
				return subtotal;
			},
			getCurrentSubtotalByUnit(unit) {
				// unit can be 'day', 'week', 'month', etc
				let rightNow = this.$DateTime.local();
				let events = this.eventList.filter((v) => v.date.hasSame(rightNow, unit));
				let subtotal = events.reduce(
					(accumulator, v) => v.duration.plus(accumulator),
					this.$luxon.Duration.fromMillis(0)
				);
				return subtotal;
			},
			sortHistory(a, b) {
				if (a.date < b.date) {
					return 1;
				}
				if (a.date > b.date) {
					return -1;
				}
				return 0;
			},
			handleTimerEventDelete(event) {
				// console.log('handleTimerEventDelete', event);
				const index = this.eventList.findIndex((v) => v.id === event.message.id);
				// console.log('handleTimerEventDelete', index);
				if (index === -1) return;
				this.eventList.splice(index, 1);
				this.history = this.getHistory();
			},
			handleTimerEventCreate(event) {
				const index = this.eventList.findIndex((v) => v.id === event.message.id);
				if (index === -1) return;

				let start = this.$DateTime.fromISO(event.message.timerStart);
				let end = this.$DateTime.fromISO(event.message.timerEnd);
				event.message.duration = end.diff(start, 'seconds').as('seconds') - event.message.pausedSeconds;

				this.eventList.push(this.fixupEvent(event.message));
				this.history = this.getHistory();
			},
			handleTimerEventUpdate(event) {
				const index = this.eventList.findIndex((v) => v.id === event.message.id);
				if (index === -1) return;

				let start = this.$DateTime.fromISO(event.message.timerStart);
				let end = this.$DateTime.fromISO(event.message.timerEnd);
				event.message.duration = end.diff(start, 'seconds').as('seconds') - event.message.pausedSeconds;

				this.eventList.splice(index, 1, this.fixupEvent(event.message));
				this.fixupEventListClientColor(this.clientList);
			},
			handleTimerStartEvent() {
				this.running = true;
			},
			handleTimerStopEvent(event) {
				this.running = false;
				//console.log('handleTimerStopEvent', event);

				let start = this.$DateTime.fromISO(event.message.timerStart);
				let end = this.$DateTime.fromISO(event.message.timerEnd);
				event.message.duration = end.diff(start, 'seconds').as('seconds') - event.message.pausedSeconds;

				this.eventList.push(this.fixupEvent(event.message));
				this.fixupEventListClientColor(this.clientList);
				// this.setMostRecentDayClientsOpen();
			},
			getEventList() {
				const earliest = this.$DateTime
					.local()
					.startOf('day')
					.minus({ week: 2 })
					.toISO();

				this.timeTrackService
					.getTimerEventsByUser(this.userId, earliest)
					.then((res) => {
						this.eventList = this.fixupEventList(res.data);
						this.getClientList();
					})
					.catch((err) => {
						console.log(err);
						// let msg = err.response.data.message;
						// let status = err.response.status;
						// this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
			fixupEvent(item) {
				item.dateDisplay = this.$DateTime.humanDate(item.timerStart);

				let timerStart = this.$DateTime.fromISO(item.timerStart);
				let timerEnd = timerStart.plus({ seconds: item.duration });

				item.duration = timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']);
				item.date = timerStart.startOf('day');
				item.clientId = item.clientId ?? '';
				item.projectId = item.projectId ?? '';
				item.deliverableId = item.deliverableId ?? '';
				item.notes = item.notes ?? '';
				item.key = uuidv4();
				if (item.clientId) item.client = { id: item.clientId, name: item.clientName, color: 'grey' }; // Update color later
				return item;
			},
			fixupEventList(list) {
				for (let i = 0; i < list.length; i++) {
					list[i] = this.fixupEvent(list[i]);
				}
				return list;
			},

			getClientList() {
				this.clientService
					.getClientList()
					.then((res) => {
						this.clientList = res.data;
						this.fixupEventListClientColor(res.data);
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			fixupEventListClientColor(clientList) {
				for (let i = 0; i < this.eventList.length; i++) {
					let event = this.eventList[i];

					if (event.client) {
						let clientIndex = clientList.findIndex((client) => client.id == event.clientId);
						if (clientIndex > -1) {
							this.eventList[i].client.color = clientList[clientIndex].color ?? 'var(--v-carrara-base)';
						}
					}
				}
			},
			eventDetailsOutput: function(event) {
				let out = [];
				if (event.ticketName) out.push(event.ticketName);
				if (event.projectName) out.push(event.projectName);
				if (event.deliverableName) out.push(event.deliverableName);
				else if (event.notes) out.push(event.notes);
				if (!out.length) out.push('--');
				// console.table(out);
				return '<div>' + out.join('</div><div>') + '</div>';
			},
		},
		computed: {
			...mapGetters({
				// account: 'getAccount',
				userId: 'getLoggedInUserId',
			}),
			isReady: {
				get: function() {
					return true;
					//return !this.$validations.isEmpty(this.dateStart) && !this.$validations.isEmpty(this.timerStart);
				},
			},
			accountId() {
				return this.$store.getters.getAccountId;
			},
			user() {
				return this.$store.state.loggedInUser;
			},
			todaySubtotal() {
				return this.getCurrentSubtotalByUnit('day');
			},
			thisWeekSubtotal() {
				return this.getCurrentSubtotalByUnit('week');
			},
		},
		watch: {
			eventList() {
				let firstRun = 0;
				if (!this.history) firstRun = 1;
				this.history = this.getHistory();
				if (firstRun) this.setMostRecentDayClientsOpen();
			},
		},
		filters: {
			empty: function(value) {
				if (!value) return '--';
				return value;
			},
			clientNameOutput: function(value) {
				if (value.clientId) {
					return value.clientName + '<div class="event-count">' + value.events.length + '</div>';
				}
				return 'Unassigned' + '<div class="event-count">' + value.events.length + '</div>';
			},
			dailyDuration: function(duration) {
				const tmp = duration
					.shiftTo('hours', 'minutes')
					.toFormat('h:m', { floor: false }) // floor false returns minutes decimal
					.split(':');
				const minutes = parseFloat(tmp[1]);
				return tmp[0] + ' h ' + minutes.toFixed(0).padStart(2, '0') + ' min'; // round and pad minutes
			},
			durationFormat: function(duration, seconds = true) {
				return duration.toFormat(seconds ? 'h:mm:ss' : 'h:mm');
			},
			/* humanDate: function(value) {
					return this.$DateTime.humanDate(value);
				}, */
		},
	};
</script>

<style lang="scss">
	#time-track-history-menu {
		width: 300px;
		text-align: left;
		background-color: var(--v-white-base);
		border-right: 1px solid var(--v-gray_50-base);
		#subtotals-head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: var(--v-primary-base);
			color: var(--v-white-base);
			font-size: 14px;
			height: 52px;
			.today {
				flex: 1 0 auto;
			}
			.this-week {
				flex: 1 0 auto;
				text-align: right;
			}
		}
		.day-wrapper {
			background-color: var(--v-white-base);
			padding: 0;
			max-height: calc(var(--vh) - 402px);
			overflow-y: auto;
			.day {
				.date-head {
					display: flex;
					font-size: 12px;
					letter-spacing: 0.1px;
					height: 32px;
					background-color: var(--v-gray_10-base);
					color: var(--v-black-base);
					align-items: stretch;
					div {
						flex: 1 0 auto;
						align-items: stretch;
						// margin: 0 0 8px;
						&.date {
							align-self: center;
						}
						&.duration {
							align-self: center;
							text-align: right;
						}
					}
				}
				.client-section {
					cursor: pointer;
					&:not(:nth-child(2)) {
						border-top: 1px solid var(--v-gray_50-base);
						// border-top: 1px solid red;
					}
					.client-head {
						display: flex;
						// border: 1px solid red;
						justify-content: space-between;
						align-items: stretch;
						font-size: 14px;
						.avatar {
							// border: 1px solid green;
							flex: 0 0 32px;
						}
						.client-name {
							// border: 1px solid red;
							// border: 1px solid green;
							text-align: left;
							flex: 1 0 auto;
							display: flex;
							align-items: center;
							div {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								max-width: 175px; // Explicitly constrain for ellipsis functionality
							}
						}
						.duration {
							font-size: 12px;
							text-align: right;
							flex: 0 0 51px;
							align-self: center;
						}
						&:hover {
							color: var(--v-primary-base);
						}
						&.active {
							color: var(--v-primary-base);
						}
					}
					.event-group {
						height: 0;
						overflow: hidden;
						&.open {
							height: auto;
						}
						cursor: pointer;

						.event {
							display: flex;
							align-items: center;
							font-size: 12px;
							color: var(--v-gray_70-base);
							// overflow: hidden;
							min-height: 48px;
							&:not(:last-child) {
								border-bottom: 1px solid var(--v-gray_50-base);
							}
							&:hover {
								color: var(--v-primary-base);
							}
							&.active {
								color: var(--v-primary-base);
							}
							.play {
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								height: 20px;
								width: 20px;
								border: 1px solid var(--v-gray_50-base);
								border-radius: 10px;
								align-items: center;
								visibility: hidden;
							}
							&:hover .play {
								visibility: visible;
							}
							.event-details {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								flex: 1 1 auto;
								div.event-description {
									flex: 1 1 auto;
									padding: 12px 0;
									div {
										text-overflow: ellipsis;
										white-space: nowrap;
										overflow: hidden;
										width: 195px;
									}
								}
							}

							.duration {
								margin-left: 4px;
								text-align: right;
								flex: 0 0 29px;
								width: 40px;
								display: flex;
								justify-content: flex-end;
								align-items: center;
							}
						}
					}
				}
			}
		}
	}
	.event-count {
		width: 16px !important;
		height: 16px !important;
		background-color: var(--v-gray_20-base);
		font-size: 12px;
		color: var(--v-gray_80-base);
		border-radius: 2px;
		margin-left: 4px;
		text-align: center;
	}
	.time-track-edit-popover {
		background-color: var(--v-white-base);
		padding: 16px;
	}
	.time-track-edit-modal {
		background-color: var(--v-white-base);
		padding: 16px;
	}
	#history-edit-popover {
		position: fixed !important;
		z-index: 14;
		margin-left: -300px;
	}
	.close-button-box {
		display: flex;
		align-items: center;
		width: 20px;
		height: 20px;
		border-radius: 2.5px;
		border: 1px solid var(--v-gray_50-base);
		justify-content: center;
	}
</style>
