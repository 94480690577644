var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: { "bread-crumbs": [{ to: "/home", label: "Home" }] }
        },
        [_vm._v(" Data import/export ")]
      ),
      _c("div", { staticClass: "row-format mb-8 gap-3 flex-wrap" }, [
        _c(
          "div",
          {
            staticClass: "page-link",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$router.push("/data/import")
              }
            }
          },
          [_c("div", [_vm._v("Import")])]
        ),
        _vm._m(0)
      ]),
      _c(
        "v-container",
        { staticClass: "mt-6", staticStyle: { "max-width": "580px" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "pb-0 mb-0 column-format centered",
                  attrs: { cols: "12", align: "center" }
                },
                [
                  _c("v-icon", { attrs: { color: "secondary", large: "" } }, [
                    _vm._v("download")
                  ]),
                  _c("div", [
                    _vm._v(
                      "Download an Excel file which contains Clients, Contacts, Projects, Tasks, Time worked, Invoices, Expenses, and Payments"
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.$store.getters.isAccountOwner
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-3",
                      attrs: { cols: "12", align: "center" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: {
                            click: function($event) {
                              return _vm.exportWorkspace()
                            }
                          }
                        },
                        [_vm._v("Export workspace data")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-3",
                      attrs: { cols: "12", align: "center" }
                    },
                    [
                      _c("div", { staticClass: "font-italic font-gray_70" }, [
                        _vm._v(
                          "This feature is only accessible to the account owner"
                        )
                      ])
                    ]
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-link page-link-active" }, [
      _c("div", [_vm._v("Export")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }