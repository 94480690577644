<template>
	<input ref="inputRef" type="text" placeholder="--" style="color: var(--v-black-base)" />
</template>

<script>
	import { useCurrencyInput } from 'vue-currency-input';

	export default {
		name: 'CurrencyInput',
		props: {
			value: Number,
			options: Object,
		},
		setup(props) {
			const { inputRef } = useCurrencyInput(props.options);

			return { inputRef };
		},
	};
</script>

<style scoped lang="scss">
	input:focus {
		outline: 1px solid var(--v-gray_30-base);
	}

	input {
		width:100%;
	}
</style>
