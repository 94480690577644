var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "apply-deposit-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("h2", { staticClass: "modal-title" }, [
            _vm._v(
              _vm._s(_vm.$t("invoice.details.apply-deposit-modal.heading"))
            )
          ]),
          _c("p", { staticClass: "modal-subtitle mb-3" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("invoice.details.apply-deposit-modal.subheading")
                ) +
                " "
            )
          ]),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              prefix: "$",
              label: _vm.$t(
                "invoice.details.apply-deposit-modal.deposit-collected"
              ),
              disabled: true
            },
            model: {
              value: _vm.depositAccount.liabilities,
              callback: function($$v) {
                _vm.$set(_vm.depositAccount, "liabilities", $$v)
              },
              expression: "depositAccount.liabilities"
            }
          }),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              prefix: "$",
              label: _vm.$t(
                "invoice.details.apply-deposit-modal.deposit-available"
              ),
              disabled: true
            },
            model: {
              value: _vm.depositAccount.balance,
              callback: function($$v) {
                _vm.$set(_vm.depositAccount, "balance", $$v)
              },
              expression: "depositAccount.balance"
            }
          }),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              type: "number",
              "hide-details": "",
              prefix: _vm.currencySymbol,
              label: _vm.$t(
                "invoice.details.apply-deposit-modal.apply-to-invoice"
              ),
              max: _vm.depositAccount.balance
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSave.apply(null, arguments)
              }
            },
            model: {
              value: _vm.amountToApply,
              callback: function($$v) {
                _vm.amountToApply = $$v
              },
              expression: "amountToApply"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              staticStyle: { width: "160px" },
              on: { click: _vm.handleSave }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }