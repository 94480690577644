var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "column-format fill-height" },
        [
          _c(
            "page-header",
            {
              staticClass: "pb-6",
              attrs: {
                "bread-crumbs": [
                  { to: "/home", label: "Home" },
                  { label: "Sales pipeline" }
                ]
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.view === "opportunities" ? "Opportunities" : "Forecast"
                  ) +
                  " "
              )
            ]
          ),
          _vm.paidAccount || _vm.sampleMode
            ? _c("div", { staticClass: "column-format gap-3" }, [
                _c(
                  "div",
                  { staticClass: "row-format align-center gap-3 flex-wrap" },
                  [
                    _c("v-text-field", {
                      staticStyle: {
                        "max-width": "200px",
                        "background-color": "var(--v-white-base)"
                      },
                      attrs: {
                        outlined: "",
                        "hide-details": "",
                        dense: "",
                        placeholder: "Search...",
                        color: "gray_30"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend-inner",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "material-symbols-rounded font-gray_50"
                                  },
                                  [_vm._v("search")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        95475665
                      ),
                      model: {
                        value: _vm.filter.name,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "name", $$v)
                        },
                        expression: "filter.name"
                      }
                    }),
                    _c("pipeline-filter", {
                      attrs: {
                        filter: _vm.filter,
                        clients: _vm.uniqueClients,
                        stages: _vm.pipelineStages.stages
                      },
                      on: { "reset-filter": _vm.resetFilter }
                    }),
                    _c("pipeline-sort", {
                      attrs: { filter: _vm.filter, view: _vm.opportunityView }
                    }),
                    _c(
                      "div",
                      { staticClass: "ml-auto row-format align-center" },
                      [
                        _vm.totalPages > 0 &&
                        _vm.view === "opportunities" &&
                        _vm.opportunityView === "TABLE"
                          ? _c("pagination", {
                              attrs: { "total-pages": _vm.totalPages },
                              model: {
                                value: _vm.currentPage,
                                callback: function($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            staticClass: "super-action",
                            on: {
                              click: function($event) {
                                return _vm.createOpportunity()
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("add")
                            ]),
                            _vm._v(" Add opportunity")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { class: _vm.$store.getters.scroll }, [
                  _vm.opportunities.length
                    ? _c(
                        "div",
                        [
                          _vm.view === "opportunities" &&
                          _vm.opportunityView === "TABLE"
                            ? _c(
                                "div",
                                {
                                  staticClass: "row-format gap-4 pb-3 flex-wrap"
                                },
                                _vm._l(_vm.stageSummaries, function(stage) {
                                  return _c(
                                    "div",
                                    {
                                      key: stage.id,
                                      staticClass:
                                        "stage-overview row-format centered pointer",
                                      style:
                                        "--stage-color:" + stage.rgb.join(","),
                                      on: {
                                        click: function($event) {
                                          return _vm.focusStage(stage)
                                        }
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(stage.label) +
                                            " (" +
                                            _vm._s(stage.opportunityCount) +
                                            ") - " +
                                            _vm._s(
                                              _vm.$formatters.dollars(
                                                stage.totalValue
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "row-format gap-4 pb-3" }, [
                            _c(
                              "div",
                              {
                                staticClass: "ml-auto row-format gap-3",
                                staticStyle: { "align-items": "end" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Table" },
                                        expression: "{ content: 'Table' }"
                                      }
                                    ],
                                    staticClass: "pointer row-format",
                                    on: {
                                      click: function($event) {
                                        return _vm.setOpportunityView("TABLE")
                                      }
                                    }
                                  },
                                  [
                                    _c("h-icon", {
                                      attrs: {
                                        name: "project-table",
                                        size: "20",
                                        color:
                                          "var(--v-" +
                                          (_vm.opportunityView === "TABLE"
                                            ? "primary"
                                            : "gray_70") +
                                          "-base)"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Kanban" },
                                        expression: "{ content: 'Kanban' }"
                                      }
                                    ],
                                    staticClass: "pointer row-format",
                                    on: {
                                      click: function($event) {
                                        return _vm.setOpportunityView("CARD")
                                      }
                                    }
                                  },
                                  [
                                    _c("h-icon", {
                                      attrs: {
                                        name: "project-kanban",
                                        size: "20",
                                        color:
                                          "var(--v-" +
                                          (_vm.opportunityView === "CARD"
                                            ? "primary"
                                            : "gray_70") +
                                          "-base)"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "show-scrollbar" },
                            [
                              _vm.view === "opportunities" &&
                              _vm.opportunityView === "TABLE"
                                ? _c("opportunity-list", {
                                    attrs: {
                                      "pipeline-stages": _vm.pipelineStages,
                                      "pipeline-fields": _vm.pipelineFields,
                                      opportunities: _vm.paginatedItems,
                                      "confidence-list": _vm.confidenceList
                                    },
                                    on: {
                                      edit: function($event) {
                                        return _vm.editOpportunity($event)
                                      },
                                      archive: function($event) {
                                        return _vm.archiveOpportunity($event)
                                      },
                                      delete: function($event) {
                                        return _vm.confirmDelete($event)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.view === "opportunities" &&
                          _vm.opportunityView === "CARD"
                            ? _c("opportunity-kanban", {
                                attrs: {
                                  "pipeline-stages": _vm.pipelineStages,
                                  "pipeline-fields": _vm.pipelineFields,
                                  "stage-summaries": _vm.stageSummaries,
                                  opportunities: _vm.filteredOpportunities,
                                  "confidence-list": _vm.confidenceList,
                                  filter: _vm.filter
                                },
                                on: {
                                  edit: function($event) {
                                    return _vm.editOpportunity($event)
                                  },
                                  "kanban-updates": function($event) {
                                    return _vm.updateKanban($event)
                                  },
                                  "add-opportunity": function($event) {
                                    return _vm.createOpportunity($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.view === "forecast"
                            ? _c("forecast", {
                                attrs: {
                                  opportunities: _vm.filteredOpportunities,
                                  "pipeline-stages": _vm.pipelineStages
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "row-format centered fill-height",
                          staticStyle: { "min-height": "calc(100vh - 300px)" }
                        },
                        [
                          _c("empty-view", {
                            attrs: {
                              header: "Never have another client dry spell",
                              body:
                                "Visualize the prospects you’re talking to, track notes on what they need, pitch them on proposals, work your way to a signed contract and getting paid.",
                              cta: "Add an opportunity",
                              "video-header": "See how it works",
                              "video-body":
                                "Learn how the pipeline visualizes your sales process, predicts your income, and helps you keep all of your opportunities on track to close.",
                              "video-cta": "Watch the tutorial",
                              "video-id": "f72IvZ0Ifm8"
                            },
                            on: {
                              "cta-clicked": function($event) {
                                return _vm.createOpportunity()
                              }
                            }
                          })
                        ],
                        1
                      )
                ])
              ])
            : _c(
                "div",
                { staticClass: "row-format centered mx-8 mt-4" },
                [
                  _c("free-tier-card", {
                    staticStyle: { width: "100%", height: "150px" },
                    attrs: {
                      message:
                        "Sales pipeline is only available on the Pro or Teams plan.  Upgrade now to get control over your business and visualize the flow of opportunities through the pipeline."
                    },
                    on: {
                      "upgrade-clicked": function($event) {
                        return _vm.$router.push("/subscription")
                      }
                    }
                  })
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }