<template>
	<v-container>
		<v-row align="center">
			<v-col cols="12" align="center">
				<h2 class="pa-10">Connecting your PayPal account...</h2>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import PayPalService from "@/modules/integrations/paypal/PayPalService";

export default {
	name: "PayPalRefreshHandler",

	props: ['merchantIdInPayPal','permissionsGranted','consentStatus'],

	components: {
	},

	data: function () {
		return {
			payPalService: new PayPalService(),
		}
	},

	mounted() {
		this.$store.commit('startLoading');
		this.payPalService.finalizeConnection({
			merchantIdInPayPal:this.merchantIdInPayPal,
			permissionsGranted:this.permissionsGranted,
			consentStatus:this.consentStatus
		}).then(() => {
			this.$store.commit('stopLoading');
			this.$store.state.eventBus.$emit('open-settings-modal','connected-apps');
		})
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>