<template>
	<div class="row-format align-center">
		<v-text-field
				type="number"
				v-model="quantity"
				hide-details
				persistent-placeholder
				class="h-outline mr-2"
				label="Quantity"
				@change="notifyChange"
				style="max-width: 100px"
		></v-text-field>
		<v-autocomplete
				hide-details
				persistent-placeholder
				class="h-outline"
				item-text="name"
				label="Subscription"
				:items="productsFlattened"
				v-model="selectedProduct"
				@change="notifyChange"
				:return-object="true"
				item-value="id"
		>
			<template v-slot:selection="{ item }">
				<div class="row-format align-end">
					<div>{{ item.name }}</div>
					<div class="ml-2 font-12 font-gray_70">{{ item.amount }}</div>
				</div>
			</template>
			<template v-slot:item="{ item }">
				<div class="row-format align-end">
					<div>{{ item.name }}</div>
					<div class="ml-2 font-12 font-gray_70">{{ item.amount }}</div>
				</div>
			</template>
		</v-autocomplete>
		<v-btn icon @click="$emit('delete',{id:id})"><v-icon small color="gray_70">$delete</v-icon></v-btn>
	</div>
</template>

<script>
export default {
	name: "ProductSelector",

	props: ['newSubscription','productsFlattened'],

	components: {},

	data: function () {
		return {
			id: this.newSubscription.id,
			quantity: this.newSubscription.quantity,
			selectedProduct: this.newSubscription.selectedProduct
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		notifyChange: function(){
			this.$emit('update',{
				id: this.id,
				quantity: this.quantity,
				selectedProduct: this.selectedProduct
			})
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>