var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format gap-2" },
    [
      _c("v-text-field", {
        style: "max-width: 160px; background-color: var(--v-white-base)",
        attrs: {
          outlined: "",
          "hide-details": "",
          dense: "",
          placeholder: "Search...",
          color: "gray_30"
        },
        on: { input: _vm.updated },
        scopedSlots: _vm._u([
          {
            key: "prepend-inner",
            fn: function() {
              return [
                _c(
                  "span",
                  { staticClass: "material-symbols-rounded font-gray_50" },
                  [_vm._v("search")]
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.filter.search,
          callback: function($$v) {
            _vm.$set(_vm.filter, "search", $$v)
          },
          expression: "filter.search"
        }
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "max-height": "90vh",
            "nudge-top": 28,
            "max-width": "400",
            bottom: "",
            right: "",
            rounded: "",
            "offset-overflow": "",
            "offset-y": "",
            "close-on-content-click": false,
            "content-class": "filter-menu-class",
            transition: "slide-y-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: {
                          "min-height": "40px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          elevation: "0",
                          outlined: "",
                          color: "gray_30"
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (_vm.isFilterActive
                              ? "font-secondary"
                              : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filterLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.isFilterActive ? "secondary" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c(
            "div",
            { staticClass: "column-format" },
            [
              _c("filter-option", {
                attrs: {
                  label: _vm.$t("client.filter.client-list"),
                  "option-list": _vm.clientList
                },
                on: { change: _vm.updated },
                model: {
                  value: _vm.filter.clients,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "clients", $$v)
                  },
                  expression: "filter.clients"
                }
              }),
              _c("filter-option", {
                attrs: {
                  label: _vm.$t("timetrack.list.user"),
                  "option-list": _vm.userList
                },
                on: { change: _vm.updated },
                model: {
                  value: _vm.filter.users,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "users", $$v)
                  },
                  expression: "filter.users"
                }
              }),
              _c("filter-option", {
                attrs: {
                  label: _vm.$t("client.filter.timer-status"),
                  "option-list": _vm.timeEntryStatus
                },
                on: { change: _vm.updated },
                model: {
                  value: _vm.filter.timeEntryStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "timeEntryStatus", $$v)
                  },
                  expression: "filter.timeEntryStatus"
                }
              }),
              _c("div", { staticClass: "filter-option-box" }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format",
                    on: {
                      click: function($event) {
                        _vm.dateExpanded = !_vm.dateExpanded
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "font-14" }, [
                      _vm._v(_vm._s(_vm.$t("client.filter.date-range")))
                    ]),
                    _c(
                      "v-icon",
                      {
                        staticStyle: {
                          "margin-left": "auto",
                          "margin-top": "1px"
                        },
                        attrs: { small: "" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.dateExpanded ? "$arrowUp" : "$arrowDown")
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm.dateExpanded
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-radio-group",
                          {
                            on: {
                              change: function($event) {
                                return _vm.updated()
                              }
                            },
                            model: {
                              value: _vm.filter.dateSpecifier,
                              callback: function($$v) {
                                _vm.$set(_vm.filter, "dateSpecifier", $$v)
                              },
                              expression: "filter.dateSpecifier"
                            }
                          },
                          _vm._l(_vm.dateOptions, function(option) {
                            return _c("v-radio", {
                              key: option.value,
                              attrs: { value: option.value },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-14 brand-semilight",
                                            staticStyle: { "font-size": "14px" }
                                          },
                                          [_vm._v(_vm._s(option.text))]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          }),
                          1
                        ),
                        _vm.showEarliest
                          ? _c(
                              "div",
                              {},
                              [
                                _c("date-selector", {
                                  attrs: {
                                    "content-class": "my-5",
                                    date: _vm.filter.earliest,
                                    label: "Earliest"
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.filter.earliest = $event
                                      _vm.updated()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showLatest
                          ? _c(
                              "div",
                              {},
                              [
                                _c("date-selector", {
                                  attrs: {
                                    "content-class": "my-5",
                                    date: _vm.filter.latest,
                                    label: "Latest"
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.filter.latest = $event
                                      _vm.updated()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "mx-3 mb-3" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-2",
                      staticStyle: { "font-weight": "500" },
                      attrs: {
                        text: "",
                        block: "",
                        color: "secondary",
                        elevation: "0"
                      },
                      on: {
                        click: function($event) {
                          return _vm.clearFilter()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("$clear")
                      ]),
                      _vm._v(" Clear filters")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "super-action mt-1",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          _vm.showMenu = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("global.done")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-menu",
        {
          staticStyle: { overflow: "visible" },
          attrs: {
            "max-height": "90vh",
            "nudge-top": 28,
            "max-width": "400",
            bottom: "",
            right: "",
            rounded: "",
            "offset-overflow": "",
            "offset-y": "",
            transition: "slide-y-transition",
            "close-on-content-click": false,
            "close-on-click": true
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: {
                          "min-height": "40px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          elevation: "0",
                          outlined: "",
                          color: "gray_30"
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (_vm.filter.groupBy
                              ? "font-secondary"
                              : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.groupByLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.filter.groupBy ? "secondary" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.groupMenu,
            callback: function($$v) {
              _vm.groupMenu = $$v
            },
            expression: "groupMenu"
          }
        },
        [
          _c(
            "v-list",
            { staticClass: "text-left" },
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.setGroupBy(null)
                    }
                  }
                },
                [
                  _c("v-list-item-content", [
                    _c("div", { staticClass: "font-14 brand-medium" }, [
                      _vm._v("No group")
                    ])
                  ])
                ],
                1
              ),
              _vm._l(_vm.groupByOptions, function(group) {
                return _c(
                  "v-list-item",
                  {
                    key: group.field,
                    on: {
                      click: function($event) {
                        return _vm.setGroupBy(group)
                      }
                    }
                  },
                  [
                    _c("v-list-item-content", [
                      _c("div", { staticClass: "font-14 brand-medium" }, [
                        _vm._v(_vm._s(group.label))
                      ])
                    ]),
                    group.subItems && group.subItems.length
                      ? _c(
                          "v-menu",
                          {
                            attrs: {
                              "offset-x": "",
                              "close-on-click": true,
                              "close-on-content-click": true
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v("expand_more")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "text-left font-12" },
                              _vm._l(group.subItems, function(
                                subItem,
                                subIndex
                              ) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: subIndex,
                                    on: {
                                      click: function($event) {
                                        return _vm.setGroupBy(subItem)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "font-14 brand-medium" },
                                      [_vm._v(_vm._s(subItem.label))]
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }