<template>
	<div class="row-format gap-2 font-14 pointer" v-if="agreement" @click="openAgreement">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" >handshake</v-icon>
		<div>{{ agreement.name }} | {{lastEvent.event}}</div>
		<div class="ml-auto font-gray_80 font-12 nowrap">{{lastEvent.timestamp | formatForTimeAgo}}</div>
	</div>
</template>

<script>
import AgreementBuilder from "@/modules/agreements/AgreementBuilder";
import AgreementService from "@/modules/agreements/AgreementService";

export default {
	name: "Proposal",

	props: ['item'],

	components: {},

	data: function () {
		return {
			agreement: null,
			agreementService: new AgreementService(),
		}
	},

	mounted() {
		this.agreementService.getAgreement(this.item.itemId)
				.then((res) => this.agreement = res.data)
				.catch((err) => {
					if(err.response.status === 404){
						this.$emit('deleted');
					}
				});
	},

	beforeDestroy() {
	},

	methods: {
		openAgreement: function() {
			let binding = {
				id: this.agreement.id,
			};
			this.$store.state.globalModalController.openModal(AgreementBuilder, binding, true, true).then((res) => {
				this.agreement = res;
			})
		},
	},

	computed: {
		lastEvent: function(){
			if(this.agreement){
				return this.agreement.events[this.agreement.events.length-1];
			}else{
				return {}
			}
		}
	},

}
</script>

<style scoped lang="scss">

</style>