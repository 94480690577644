import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class CommunicatorCallService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
	}

	getCallDetails(earliest, latest, archived = false, clientId) {
		let params = {
			earliest: earliest,
			latest: latest,
			archived: archived,
			clientId: clientId
		};
		return this.httpClient
			.get('/calls', { params: params })
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getCall(callId) {
		return this.httpClient
			.get(`/calls/${callId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteCall(callId) {
		return this.httpClient
			.delete(`/calls/${callId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	markCallAsRead(callId, read = true) {
		return this.httpClient
			.post(`/calls/${callId}/read`,null,{
				params: {
					read:read
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setArchiveFlag(callId, archive = true) {
		return this.httpClient
			.post(`/calls/${callId}/archive`,null,{
				params: {
					archive:archive
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setSnooze(callId, snoozeUntil) {
		return this.httpClient
			.post(`/calls/${callId}/snooze`,null,{
				params: {
					snoozeUntil:snoozeUntil
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	returnToInbox(callId){
		return this.httpClient
			.post(`/calls/${callId}/returnToInbox`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getCallRecordingUrl(callId) {
		return this.httpClient
			.get(`/calls/${callId}/recordingUrl`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
