import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class ClientService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getClientList(isArchived = 'no', includeContacts = false) {
		return this.httpClient.get(`/clients?archived=${isArchived}&includeContacts=${includeContacts}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	resetAccountingIntegrationKeys(id){
		return this.httpClient.post(`/clients/${id}/resetAccountingIntegrationKeys`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getClientMiniList(isArchived = 'no') {
		return this.httpClient.get(`/clientsMini?archived=${isArchived}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getClientCount() {
		return this.httpClient.get('/clients/count')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getClientDetail(id) {
		return this.httpClient.get(`/clients/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteLogo(id) {
		return this.httpClient.delete(`/clients/${id}/logo`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getClientCurrency(id) {
		return this.httpClient.get(`/clients/${id}/currency`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getClientPaymentMethods(id) {
		return this.httpClient.get(`/clients/${id}/paymentMethods`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	putClient(id, data) {
		return this.httpClient.put(`/clients/${id}`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchClient(id, data) {
		return this.httpClient.patch(`/clients/${id}`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	postClient(data,notes) {
		if(notes){
			data.notes = notes;
		}
		return this.httpClient.post('/clients', data)
			.then((res) => {
				store.state.eventBus.$emit('created','client')
				return Promise.resolve(res)
			})
			.catch((err) => Promise.reject(err));
	}

	deleteClient(id) {
		return this.httpClient.delete('/clients/' + id)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDepositAccount(id,currency) {
		return this.httpClient.get('/clients/' + id + '/deposit',{
			params: {
				currency: currency
			}
		})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateClientDefaults(updateRequest){
		return this.httpClient.post('/clients/updateAllClientDefaults', updateRequest)
			.then((res) => {
				return Promise.resolve(res)
			})
			.catch((err) => Promise.reject(err));
	}
}
