<template>
	<div class="column-format gap-2">
		<contact-selector :multiple="true" label="To" :to-list="toList" @change="updateToList($event)"></contact-selector>
		<contact-selector :multiple="true" label="CC" :to-list="ccList" @change="updateCcList($event)"></contact-selector>
		<v-autocomplete
				hide-details
				dense
				outlined
				persistent-placeholder
				:items="templates"
				item-text="name"
				item-value="id"
				label="Email template"
				v-model="templateId"
				@change="checkNewTemplate"
		></v-autocomplete>
		<div class="row-format gap-2" v-if="selectedTemplate">
			<v-text-field
					dense
					hide-details
					outlined
					:disabled="true"
					persistent-placeholder
					label="Subject"
					:value="selectedTemplate.subject"
			></v-text-field>
			<v-btn outlined elevation="0" color="primary" style="min-height: 40px" @click="editTemplate"><v-icon small>edit</v-icon> edit</v-btn>
		</div>
		<div
				v-if="selectedTemplate"
				class="text-left font-gray_70 column-format"
				style="max-width: 550px; border: 1px solid var(--v-gray_30-base)"
		>
			<div class="pa-2" v-html="selectedTemplate.htmlContent"></div>
			<div v-if="selectedTemplate.attachments.length" class="row-format my-2 pt-2 px-2 gap-2" style="flex-wrap: wrap; border-top: 1px solid var(--v-gray_30-base)">
				<v-chip
						small
						v-for="(attachment, index) in selectedTemplate.attachments"
						:key="attachment.fileName + index"
				>{{ attachment.fileName }}</v-chip
				>
			</div>
		</div>
	</div>
</template>

<script>
import EmailTemplateEditor from "@/modules/templates/emails/EmailTemplateEditor";
import EmailTemplateService from "@/modules/templates/EmailTemplateService";
import ContactSelector from "@/modules/workflow/nodes/components/ContactSelector";

export default {
	name: "EmailSelector",

	props: ['to_list','cc_list','template_id'],
	emits: ['to-list','cc-list','selected-template'],

	components: {ContactSelector},

	data: function () {
		return {
			toList: [... this.to_list],
			ccList: [... this.cc_list],
			templateId: this.template_id,
			templates: [],
			emailTemplateService: new EmailTemplateService(),
		}
	},

	mounted() {
		this.getEmailTemplates();
	},

	beforeDestroy() {
	},

	methods: {
		updateToList: function(list){
			this.toList.splice(0,this.toList.length);
			this.toList.push(... list);
			this.$emit('to-list',this.toList);
		},

		updateCcList: function(list){
			this.ccList.splice(0,this.ccList.length);
			this.ccList.push(... list);
			this.$emit('cc-list',this.ccList);
		},

		getEmailTemplates: function() {
			return new Promise((resolve, reject) => {
				this.emailTemplateService
						.getEmailTemplates()
						.then((res) => {
							this.templates.splice(0, this.templates.length);
							this.templates.push(...res.data);
							this.templates.sort((a, b) => a.name.localeCompare(b.name));
							this.templates.push({id:null,name:'+ Add new'});
							resolve();
						})
						.catch((err) => reject(err));
			});
		},

		checkNewTemplate: function(){
			if(this.template_id === null){
				this.editTemplate();
			}
		},

		editTemplate: function(){
			this.$store.state.globalModalController.openModal(EmailTemplateEditor, { id: this.template_id }).then((res) => {
				if (res) {
					let ix = this.templates.findIndex((e) => e.id === res.id);
					if (res.deleted) {
						this.template_id = null;
						this.templates.splice(ix, 1);
					} else if (ix > -1) {
						this.templates.splice(ix, 1, res);
					} else {
						this.template_id = res.id;
						this.templates.push(res);
					}
				}
			});
		},
	},

	watch: {
		selectedTemplate: function (val) {
			if(val){
				this.$emit('selected-template',{
					id: val.id,
					name: val.name,
				})
			}else{
				this.$emit('selected-template',null);
			}
		}
	},

	computed: {
		selectedTemplate: function() {
			return this.templates.find((t) => t.id === this.templateId);
		},
	},

}
</script>

<style scoped lang="scss">

</style>