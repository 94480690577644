var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format pointer py-2 font-14 deliverable",
      on: {
        click: function($event) {
          return _vm.$emit("deliverable-clicked")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "row-format" },
        [
          !_vm.hideIcon
            ? _c("v-icon", { staticClass: "material-symbols-rounded mr-3" }, [
                _vm._v("splitscreen")
              ])
            : _vm._e(),
          _vm.deliverable.client && !_vm.hideIcon
            ? _c("client-avatar", {
                staticClass: "mr-3",
                attrs: { client: _vm.deliverable.client }
              })
            : _c("div", { staticStyle: { width: "2px", height: "32px" } }),
          _c(
            "div",
            {
              staticClass: "row-format align-center",
              staticStyle: { "flex-wrap": "wrap", gap: "4px" }
            },
            [
              _vm.deliverable.project
                ? _c(
                    "div",
                    {
                      staticClass: "projectName mr-3",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.routeToProject(_vm.deliverable)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.deliverable.project.name) + " ")]
                  )
                : _vm._e(),
              _c("div", { staticClass: "deliverableName mr-3 brand-medium" }, [
                _vm._v(_vm._s(_vm.deliverable.name))
              ]),
              _vm.deliverable.dueDate
                ? _c(
                    "div",
                    { staticClass: "dueDate mr-3 row-format align-center" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { size: "15", color: "gray_70" } },
                        [_vm._v("$clock")]
                      ),
                      _c("div", { staticClass: "font-gray_80" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DateTime.fromISO(
                                _vm.deliverable.dueDate
                              ).toLocaleString({
                                month: "short",
                                day: "numeric"
                              })
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.deliverable.assignedToList.length
                ? _c(
                    "div",
                    { staticClass: "row-format align-center" },
                    _vm._l(_vm.deliverable.assignedToList, function(
                      assignedTo,
                      index
                    ) {
                      return _c("assigned-user", {
                        key: assignedTo,
                        style:
                          (index > 0 ? "margin-left: -6px" : "") +
                          "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                          index,
                        attrs: {
                          "show-name": false,
                          small: true,
                          "assigned-to": assignedTo
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.deliverable.tasks.length
                ? _c(
                    "div",
                    {
                      staticClass: "taskCount mr-3 row-format align-center",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.tasksExpanded = !_vm.tasksExpanded
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { size: "8" } },
                        [_vm._v("$check")]
                      ),
                      _c("div", { staticClass: "font-gray_80" }, [
                        _vm._v(
                          _vm._s(_vm.getTaskCountLabel(_vm.deliverable.tasks))
                        )
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticStyle: { "margin-left": "2px" },
                          attrs: { size: "14" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.tasksExpanded ? "$chevronUp" : "$chevronDown"
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.deliverable.tasks.length == 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "font-12 font-grey ml-1 mr-3 pointer task-box",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.addFirstTask()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { size: "8" } }, [
                        _vm._v("$check")
                      ]),
                      _c("v-icon", { attrs: { size: "14" } }, [_vm._v("$plus")])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "deliverableStatus row-format align-center",
                  style: "--status-color:" + _vm.deliverable.status.hexColor
                },
                [
                  _c("div", { staticClass: "statusBox" }),
                  _c("div", [_vm._v(_vm._s(_vm.deliverable.status.label))])
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tasksExpanded && _vm.deliverable.tasks.length > 0,
              expression: "tasksExpanded && deliverable.tasks.length > 0"
            }
          ],
          staticClass: "ml-10 mt-3"
        },
        [
          _c("tasks", {
            ref: "tasks",
            on: {
              empty: function($event) {
                _vm.tasksExpanded = false
              },
              change: function($event) {
                return _vm.updateTasks()
              }
            },
            model: {
              value: _vm.deliverable.tasks,
              callback: function($$v) {
                _vm.$set(_vm.deliverable, "tasks", $$v)
              },
              expression: "deliverable.tasks"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }