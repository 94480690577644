var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0", staticStyle: { "max-width": "500px" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("workspace-selector")],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pb-4", attrs: { cols: "12" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "text-left", attrs: { cols: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "font-gray_70 font-12 mb-1" },
                                [_vm._v("Account")]
                              ),
                              _vm.isFullUser
                                ? _c("settings-link", {
                                    attrs: {
                                      label: "My business",
                                      route: "/account",
                                      icon: "storefront"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isFullUser
                                ? _c("settings-link", {
                                    attrs: {
                                      label: "Subscription",
                                      route: "/subscription/workspace",
                                      icon: "credit_card"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isFullUser
                                ? _c("settings-link", {
                                    attrs: {
                                      label: "Subscription add-ons",
                                      route: "/subscription/add-ons",
                                      icon: "add_shopping_cart"
                                    }
                                  })
                                : _vm._e(),
                              _vm.$store.getters.hasFeatureAccess("settings")
                                ? _c("settings-link", {
                                    attrs: {
                                      label: "Data import/export",
                                      route: "/data/import",
                                      icon: "upload"
                                    }
                                  })
                                : _vm._e(),
                              _vm.isFullUser
                                ? _c("settings-link", {
                                    attrs: {
                                      label: "Team members",
                                      route: "/team",
                                      icon: "group"
                                    }
                                  })
                                : _vm._e(),
                              _c("settings-link", {
                                attrs: {
                                  label: "Refer a friend, earn $50",
                                  route: "/referrals",
                                  icon: "sentiment_satisfied"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "text-left", attrs: { cols: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "font-gray_70 font-12 mb-1" },
                                [_vm._v("Settings")]
                              ),
                              _c("settings-link", {
                                attrs: {
                                  label: "Integrations",
                                  route: "/apps",
                                  icon: "extension"
                                }
                              }),
                              _vm.isFullUser
                                ? _c("settings-link", {
                                    attrs: {
                                      label: "Stripe payments",
                                      route: "/stripe",
                                      icon: "$stripe"
                                    }
                                  })
                                : _vm._e(),
                              _vm.$store.getters.hasFeatureAccess("settings")
                                ? _c("settings-link", {
                                    attrs: {
                                      label: "Custom fields",
                                      route: "/custom-fields/clients",
                                      icon: "dataset"
                                    }
                                  })
                                : _vm._e(),
                              _c("hr", {
                                staticClass: "my-1",
                                staticStyle: {
                                  "border-top":
                                    "1px solid var(--v-gray_50-base)"
                                }
                              }),
                              _c("settings-link", {
                                attrs: {
                                  label: "Preferences",
                                  route: "/preferences",
                                  icon: "settings"
                                }
                              }),
                              _c("settings-link", {
                                attrs: {
                                  label: "Theme",
                                  children: _vm.theme,
                                  icon: _vm.themeIcon,
                                  "close-on-content-click": false
                                },
                                on: {
                                  selected: function($event) {
                                    return _vm.handleThemeSelection($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }