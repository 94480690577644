var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-modal", staticStyle: { width: "600px" } },
    [
      _c("div", { staticClass: "panel-modal-header" }, [
        _c(
          "div",
          { staticClass: "row-format align-center" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mr-2 pointer",
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("result")
                  }
                }
              },
              [_vm._v("$chevronRight")]
            ),
            _c(
              "div",
              {
                staticClass: "brand-semibold font-gray_80",
                staticStyle: { "font-size": "24px" }
              },
              [
                _vm.isDisabled
                  ? _c("div", [_vm._v(_vm._s(_vm.nativeEvent.summary))])
                  : _c("v-text-field", {
                      staticStyle: { width: "300px" },
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        solo: "",
                        flat: "",
                        placeholder: _vm.$t("calendar.event-title")
                      },
                      model: {
                        value: _vm.nativeEvent.summary,
                        callback: function($$v) {
                          _vm.$set(_vm.nativeEvent, "summary", $$v)
                        },
                        expression: "nativeEvent.summary"
                      }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ml-auto row-format align-center gap-2" },
              [
                _vm.nativeEvent
                  ? _c("pipeline-link-widget", {
                      attrs: {
                        "item-id": _vm.nativeEvent.id,
                        "item-type": "CalendarEvent"
                      }
                    })
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    on: { click: _vm.handleClose }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.save")))]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "secondary", icon: "" },
                    on: {
                      click: function($event) {
                        _vm.confirmDeleteDialog = true
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { size: "20" } }, [_vm._v("$delete")])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "panel-modal-body py-2 show-scrollbar" },
        [
          _vm.isReady
            ? _c(
                "v-container",
                {
                  staticClass: "pa-0 ma-0",
                  staticStyle: {
                    "background-color": "var(--v-white-base)",
                    width: "700px"
                  },
                  attrs: { fluid: "" }
                },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-3", attrs: { cols: "12" } },
                        [
                          _c("create-time-entry", {
                            attrs: {
                              start: _vm.startDate,
                              end: _vm.endDate,
                              notes: _vm.nativeEvent.summary
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                disabled: _vm.isDisabled,
                                "close-on-content-click": true,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              attrs: {
                                                disabled: _vm.isDisabled,
                                                value:
                                                  _vm.nativeEvent.startDate,
                                                label: _vm.$t(
                                                  "calendar.start-date"
                                                ),
                                                readonly: "",
                                                dense: "",
                                                "hide-details": true
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3977162103
                              ),
                              model: {
                                value: _vm.startDateMenu,
                                callback: function($$v) {
                                  _vm.startDateMenu = $$v
                                },
                                expression: "startDateMenu"
                              }
                            },
                            [
                              _vm.startDateMenu
                                ? _c("v-date-picker", {
                                    attrs: {
                                      "first-day-of-week":
                                        _vm.$store.getters.firstDayOfWeek,
                                      "no-title": "",
                                      scrollable: ""
                                    },
                                    model: {
                                      value: _vm.nativeEvent.startDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.nativeEvent,
                                          "startDate",
                                          $$v
                                        )
                                      },
                                      expression: "nativeEvent.startDate"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.nativeEvent.dateOnly
                        ? _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    disabled: _vm.isDisabled,
                                                    value: _vm.timeTo12Hour(
                                                      _vm.nativeEvent.startTime
                                                    ),
                                                    label: _vm.$t(
                                                      "calendar.start-time"
                                                    ),
                                                    readonly: "",
                                                    dense: "",
                                                    "hide-details": ""
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3711990733
                                  ),
                                  model: {
                                    value: _vm.startTimeMenu,
                                    callback: function($$v) {
                                      _vm.startTimeMenu = $$v
                                    },
                                    expression: "startTimeMenu"
                                  }
                                },
                                [
                                  _vm.startTimeMenu
                                    ? _c("v-time-picker", {
                                        attrs: {
                                          "full-width": "",
                                          format: _vm.is12HourFormat
                                            ? "ampm"
                                            : "24hr"
                                        },
                                        model: {
                                          value: _vm.nativeEvent.startTime,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.nativeEvent,
                                              "startTime",
                                              $$v
                                            )
                                          },
                                          expression: "nativeEvent.startTime"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticStyle: { color: "var(--v-gray_70-base)" },
                          attrs: { cols: "1" }
                        },
                        [_vm._v("To")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                disabled: _vm.isDisabled,
                                "close-on-content-click": true,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              attrs: {
                                                disabled: _vm.isDisabled,
                                                value: _vm.nativeEvent.endDate,
                                                label: _vm.$t(
                                                  "calendar.end-date"
                                                ),
                                                readonly: "",
                                                dense: "",
                                                "hide-details": true
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3841947287
                              ),
                              model: {
                                value: _vm.endDateMenu,
                                callback: function($$v) {
                                  _vm.endDateMenu = $$v
                                },
                                expression: "endDateMenu"
                              }
                            },
                            [
                              _vm.endDateMenu
                                ? _c("v-date-picker", {
                                    attrs: {
                                      "first-day-of-week":
                                        _vm.$store.getters.firstDayOfWeek,
                                      "no-title": "",
                                      scrollable: ""
                                    },
                                    model: {
                                      value: _vm.nativeEvent.endDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.nativeEvent,
                                          "endDate",
                                          $$v
                                        )
                                      },
                                      expression: "nativeEvent.endDate"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.nativeEvent.dateOnly
                        ? _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "menu",
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    disabled: _vm.isDisabled,
                                                    value: _vm.timeTo12Hour(
                                                      _vm.nativeEvent.endTime
                                                    ),
                                                    label: _vm.$t(
                                                      "calendar.end-time"
                                                    ),
                                                    readonly: "",
                                                    dense: "",
                                                    "hide-details": ""
                                                  }
                                                },
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2515093389
                                  ),
                                  model: {
                                    value: _vm.endTimeMenu,
                                    callback: function($$v) {
                                      _vm.endTimeMenu = $$v
                                    },
                                    expression: "endTimeMenu"
                                  }
                                },
                                [
                                  _vm.endTimeMenu
                                    ? _c("v-time-picker", {
                                        attrs: {
                                          "full-width": "",
                                          format: _vm.is12HourFormat
                                            ? "ampm"
                                            : "24hr"
                                        },
                                        model: {
                                          value: _vm.nativeEvent.endTime,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.nativeEvent,
                                              "endTime",
                                              $$v
                                            )
                                          },
                                          expression: "nativeEvent.endTime"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left font-12",
                          attrs: { cols: "12" }
                        },
                        [_vm._v(_vm._s(_vm.nativeEvent.timezone))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-2 pt-0",
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              disabled: _vm.isDisabled,
                              label: _vm.$t("calendar.all-day")
                            },
                            model: {
                              value: _vm.nativeEvent.dateOnly,
                              callback: function($$v) {
                                _vm.$set(_vm.nativeEvent, "dateOnly", $$v)
                              },
                              expression: "nativeEvent.dateOnly"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", align: "left" } },
                        [
                          _vm.isDisabled
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.nativeEvent.description
                                      ? _vm.nativeEvent.description
                                      : " -- "
                                  )
                                )
                              ])
                            : _c("editor", {
                                ref: "Description",
                                staticStyle: {
                                  "border-bottom":
                                    "1px solid var(--v-gray_30-base)",
                                  "padding-bottom": "10px"
                                },
                                attrs: {
                                  tabindex: 2,
                                  id: _vm.nativeEvent.id,
                                  placeholder: _vm.$t(
                                    "calendar.event-description"
                                  ),
                                  "input-only-mode": true,
                                  "api-key": _vm.$store.getters.getTinyMceKey,
                                  inline: true,
                                  init: _vm.mceConfig
                                },
                                on: {
                                  update: function($event) {
                                    _vm.nativeEvent.description = $event
                                  }
                                },
                                model: {
                                  value: _vm.nativeEvent.description,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.nativeEvent,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "nativeEvent.description"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-0 pt-4",
                      attrs: { dense: "", align: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              disabled: _vm.isDisabled,
                              "hide-details": "",
                              dense: "",
                              label: _vm.$t("calendar.reminder")
                            },
                            model: {
                              value: _vm.nativeEvent.reminder,
                              callback: function($$v) {
                                _vm.$set(_vm.nativeEvent, "reminder", $$v)
                              },
                              expression: "nativeEvent.reminder"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.nativeEvent.reminder
                        ? _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  "hide-details": "",
                                  dense: "",
                                  type: "number"
                                },
                                model: {
                                  value: _vm.nativeEvent.remindTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.nativeEvent, "remindTime", $$v)
                                  },
                                  expression: "nativeEvent.remindTime"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.nativeEvent.reminder
                        ? _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  "hide-details": "",
                                  dense: "",
                                  items: _vm.timeUnits
                                },
                                model: {
                                  value: _vm.nativeEvent.remindTimeUnit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.nativeEvent,
                                      "remindTimeUnit",
                                      $$v
                                    )
                                  },
                                  expression: "nativeEvent.remindTimeUnit"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-2 pt-0",
                            attrs: {
                              disabled: _vm.isDisabled,
                              "hide-details": "",
                              dense: "",
                              label: _vm.$t("calendar.busy-event")
                            },
                            model: {
                              value: _vm.nativeEvent.busyEvent,
                              callback: function($$v) {
                                _vm.$set(_vm.nativeEvent, "busyEvent", $$v)
                              },
                              expression: "nativeEvent.busyEvent"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.isDisabled
                    ? _c(
                        "v-row",
                        { staticClass: "mt-4", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { color: "var(--v-gray_70-base)" },
                              attrs: { cols: "3", align: "left" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("calendar.shared-event")) +
                                  " "
                              ),
                              !_vm.nativeEvent.sharedEvent
                                ? _c("v-icon", [_vm._v("$lock")])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4", align: "right" } },
                            [
                              _c("pill-selector", {
                                attrs: {
                                  option1: "Private",
                                  option2: "Shared",
                                  dense: true,
                                  defaultSelection: _vm.sharedFromBoolean(
                                    _vm.nativeEvent.sharedEvent
                                  ),
                                  selectedBackground: this.$vuetify.theme
                                    .currentTheme.primary,
                                  selectedColor: "white"
                                },
                                on: {
                                  "option-selected": function($event) {
                                    _vm.nativeEvent.sharedEvent = _vm.sharedToBoolean(
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": "Confirm",
          "yes-text": "Yes",
          "no-text": "No",
          "body-text": _vm.$t("calendar.delete-event-confirm")
        },
        on: {
          close: function($event) {
            _vm.confirmDeleteDialog = false
          },
          confirm: function($event) {
            return _vm.deleteEvent()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }