var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: _vm.paymentStyle,
      attrs: { id: _vm.item.id },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _vm.showPaymentElement
        ? _c("div", [
            _c("div", { attrs: { id: "payment-element" } }),
            _vm._m(0)
          ])
        : _c(
            "div",
            { staticClass: "row-format text-left align-center font-14" },
            [
              _c("div", { staticStyle: { "text-transform": "capitalize" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.item.stripePaymentIntent.status) +
                    " - " +
                    _vm._s(
                      this.$formatters.dollars(
                        _vm.item.stripePaymentIntent.amount / 100,
                        true,
                        true,
                        _vm.item.stripePaymentIntent.currency
                      )
                    ) +
                    " - " +
                    _vm._s(
                      _vm.item.stripePaymentIntent.clientPaymentMethod.type
                    ) +
                    " - " +
                    _vm._s(
                      _vm.item.stripePaymentIntent.clientPaymentMethod.label
                    ) +
                    " - " +
                    _vm._s(
                      _vm.item.stripePaymentIntent.clientPaymentMethod.last4
                    )
                ),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.DateTime.fromISO(
                        _vm.item.stripePaymentIntent.timestamp
                      ).toLocaleString(_vm.DateTime.DATETIME_FULL)
                    )
                ),
                _c("br"),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "/invoice/" + _vm.agreement.invoiceId,
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(_vm.agreement.invoiceNumberFormatted))]
                )
              ]),
              _vm._m(1)
            ]
          )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "text-left mt-3 row-format gap-1 align-center" },
      [
        _c("div", { staticClass: "ml-auto font-14 font-gray_80" }, [
          _vm._v("Pay securely with")
        ]),
        _c("img", {
          attrs: {
            src: "/images/invoices/stripe-logo.svg",
            alt: "Stripe Logo",
            width: "50"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-auto row-format gap-1 align-center" }, [
      _c("div", { staticClass: "ml-auto font-14 font-gray_80" }, [
        _vm._v("Pay securely with")
      ]),
      _c("img", {
        attrs: {
          src: "/images/invoices/stripe-logo.svg",
          alt: "Stripe Logo",
          width: "50"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }