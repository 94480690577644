var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "proposal-terms-dialog" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("cancel")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "v-form",
        {
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-4 pt-4 pb-6 form-inner-wrapper" },
            [
              _c("div", { staticClass: "modal-title mb-2" }, [
                _vm._v(_vm._s(_vm.$t("proposal.terms.payment-terms")))
              ]),
              _vm.terms
                ? _c(
                    "div",
                    [
                      _c("v-select", {
                        staticClass: "h-outline",
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          label: _vm.$t("proposal.terms.payment-terms"),
                          items: _vm.dayOptions
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item === 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("proposal.terms.on-receipt")
                                          )
                                        )
                                      ])
                                    : _vm._e(),
                                  item !== 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item) +
                                            " " +
                                            _vm._s(
                                              _vm.$t("proposal.terms.days")
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item === 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("proposal.terms.on-receipt")
                                          )
                                        )
                                      ])
                                    : _vm._e(),
                                  item !== 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item) +
                                            " " +
                                            _vm._s(
                                              _vm.$t("proposal.terms.days")
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1002020988
                        ),
                        model: {
                          value: _vm.lTerms.paymentDays,
                          callback: function($$v) {
                            _vm.$set(_vm.lTerms, "paymentDays", $$v)
                          },
                          expression: "lTerms.paymentDays"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          label: _vm.$t("proposal.terms.late-payment"),
                          placeholder: "No fee",
                          suffix: "% mo.",
                          type: "number"
                        },
                        model: {
                          value: _vm.lTerms.latePaymentFee,
                          callback: function($$v) {
                            _vm.$set(_vm.lTerms, "latePaymentFee", $$v)
                          },
                          expression: "lTerms.latePaymentFee"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "ml-1" },
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              dense: "",
                              label: _vm.$t("proposal.terms.include-terms")
                            },
                            model: {
                              value: _vm.lTerms.fullLegalTerms,
                              callback: function($$v) {
                                _vm.$set(_vm.lTerms, "fullLegalTerms", $$v)
                              },
                              expression: "lTerms.fullLegalTerms"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.terms && _vm.lTerms.fullLegalTerms
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "modal-title" }, [
                        _vm._v(_vm._s(_vm.$t("proposal.terms.legal-terms")))
                      ]),
                      _c("div", { staticClass: "disclaimer my-2" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "v-icon",
                              { attrs: { size: "30", color: "gray_90" } },
                              [_vm._v("$info")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "font-style": "italic"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("proposal.terms.disclaimer")))]
                        )
                      ]),
                      _c("date-selector", {
                        attrs: {
                          date: _vm.effectiveDate,
                          label: _vm.$t("proposal.terms.effective-date")
                        },
                        on: {
                          change: function($event) {
                            return _vm.effectiveDateUpdated($event)
                          }
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          label: _vm.$t("proposal.terms.governing-law")
                        },
                        model: {
                          value: _vm.lTerms.governingLaw,
                          callback: function($$v) {
                            _vm.$set(_vm.lTerms, "governingLaw", $$v)
                          },
                          expression: "lTerms.governingLaw"
                        }
                      }),
                      _c("v-select", {
                        staticClass: "h-outline",
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          label: _vm.$t("proposal.terms.cancellation"),
                          items: _vm.dayOptions
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item === 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("proposal.terms.no-notice")
                                          )
                                        )
                                      ])
                                    : _vm._e(),
                                  item !== 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item) +
                                            " " +
                                            _vm._s(
                                              _vm.$t("proposal.terms.days")
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item === 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("proposal.terms.no-notice")
                                          )
                                        )
                                      ])
                                    : _vm._e(),
                                  item !== 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item) +
                                            " " +
                                            _vm._s(
                                              _vm.$t("proposal.terms.days")
                                            )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4019304860
                        ),
                        model: {
                          value: _vm.lTerms.cancellationNoticeDays,
                          callback: function($$v) {
                            _vm.$set(_vm.lTerms, "cancellationNoticeDays", $$v)
                          },
                          expression: "lTerms.cancellationNoticeDays"
                        }
                      }),
                      _c("div", { staticClass: "checkbox-flex" }, [
                        _c(
                          "div",
                          [
                            _c("v-simple-checkbox", {
                              attrs: {
                                color: _vm.lTerms.displayInPortfolio
                                  ? "var(--v-blue_50-base)"
                                  : "var(--v-gray_70-base)"
                              },
                              model: {
                                value: _vm.lTerms.displayInPortfolio,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.lTerms,
                                    "displayInPortfolio",
                                    $$v
                                  )
                                },
                                expression: "lTerms.displayInPortfolio"
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", [
                          _c("div", { staticClass: "label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("proposal.terms.portfolio-label"))
                            )
                          ]),
                          _c("div", { staticClass: "details" }, [
                            _vm._v(
                              _vm._s(_vm.$t("proposal.terms.portfolio-details"))
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "checkbox-flex" }, [
                        _c(
                          "div",
                          [
                            _c("v-simple-checkbox", {
                              attrs: {
                                color: _vm.lTerms.grantLicenseForFramework
                                  ? "var(--v-blue_50-base)"
                                  : "var(--v-gray_70-base)"
                              },
                              model: {
                                value: _vm.lTerms.grantLicenseForFramework,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.lTerms,
                                    "grantLicenseForFramework",
                                    $$v
                                  )
                                },
                                expression: "lTerms.grantLicenseForFramework"
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", [
                          _c("div", { staticClass: "label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("proposal.terms.frameworks-label"))
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "checkbox-flex" }, [
                        _c(
                          "div",
                          [
                            _c("v-simple-checkbox", {
                              attrs: {
                                color: _vm.lTerms.processingFeePassThrough
                                  ? "var(--v-blue_50-base)"
                                  : "var(--v-gray_70-base)"
                              },
                              model: {
                                value: _vm.lTerms.processingFeePassThrough,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.lTerms,
                                    "processingFeePassThrough",
                                    $$v
                                  )
                                },
                                expression: "lTerms.processingFeePassThrough"
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", [
                          _c("div", { staticClass: "label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("proposal.terms.fee-pass-through-label")
                              )
                            )
                          ]),
                          _c("div", { staticClass: "details" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("proposal.terms.fee-pass-through-detail")
                              )
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "modal-title mt-4 mb-2" }, [
                        _vm._v(_vm._s(_vm.$t("proposal.terms.the-contractor")))
                      ]),
                      _vm.isReady
                        ? [
                            _c("v-text-field", {
                              staticClass: "h-outline",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("proposal.terms.legal-name")
                              },
                              model: {
                                value: _vm.account.accountName,
                                callback: function($$v) {
                                  _vm.$set(_vm.account, "accountName", $$v)
                                },
                                expression: "account.accountName"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pr-1",
                                    staticStyle: { flex: "1 1 50%" }
                                  },
                                  [
                                    _vm.entityTypes.length
                                      ? _c("v-select", {
                                          staticClass: "h-outline",
                                          attrs: {
                                            "hide-details": "",
                                            "persistent-placeholder": "",
                                            label: _vm.$t(
                                              "proposal.terms.entity-type"
                                            ),
                                            items: _vm.entityTypes
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("span", [
                                                      _vm._v(_vm._s(item))
                                                    ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2957485812
                                          ),
                                          model: {
                                            value: _vm.account.entityType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.account,
                                                "entityType",
                                                $$v
                                              )
                                            },
                                            expression: "account.entityType"
                                          }
                                        })
                                      : _c("v-text-field", {
                                          staticClass: "h-outline",
                                          attrs: {
                                            "hide-details": "",
                                            "persistent-placeholder": "",
                                            label: _vm.$t(
                                              "proposal.terms.entity-type"
                                            )
                                          },
                                          model: {
                                            value: _vm.account.entityType,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.account,
                                                "entityType",
                                                $$v
                                              )
                                            },
                                            expression: "account.entityType"
                                          }
                                        })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pl-1",
                                    staticStyle: { flex: "1 1 50%" }
                                  },
                                  [
                                    _vm.states.length
                                      ? _c("v-select", {
                                          staticClass: "h-outline",
                                          attrs: {
                                            "hide-details": "",
                                            "persistent-placeholder": "",
                                            label: _vm.$t(
                                              "proposal.terms.entity-state"
                                            ),
                                            "item-value": "name",
                                            "item-text": "name",
                                            items: _vm.states
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function(ref) {
                                                  var item = ref.item
                                                  return [
                                                    _c("span", [
                                                      _vm._v(_vm._s(item.name))
                                                    ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3625759709
                                          ),
                                          model: {
                                            value: _vm.account.entityState,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.account,
                                                "entityState",
                                                $$v
                                              )
                                            },
                                            expression: "account.entityState"
                                          }
                                        })
                                      : _c("v-text-field", {
                                          staticClass: "h-outline",
                                          attrs: {
                                            "hide-details": "",
                                            "persistent-placeholder": "",
                                            label: _vm.$t(
                                              "proposal.terms.entity-state"
                                            )
                                          },
                                          model: {
                                            value: _vm.account.entityState,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.account,
                                                "entityState",
                                                $$v
                                              )
                                            },
                                            expression: "account.entityState"
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        : _vm._e(),
                      _c("div", { staticClass: "modal-title mt-4 mb-2" }, [
                        _vm._v(_vm._s(_vm.$t("proposal.terms.the-client")))
                      ]),
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          label: _vm.$t("proposal.terms.legal-name")
                        },
                        model: {
                          value: _vm.lTerms.clientLegalName,
                          callback: function($$v) {
                            _vm.$set(_vm.lTerms, "clientLegalName", $$v)
                          },
                          expression: "lTerms.clientLegalName"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pr-1",
                              staticStyle: { flex: "1 1 50%" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "h-outline",
                                attrs: {
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  label: _vm.$t("proposal.terms.entity-type")
                                },
                                model: {
                                  value: _vm.lTerms.clientEntityType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.lTerms,
                                      "clientEntityType",
                                      $$v
                                    )
                                  },
                                  expression: "lTerms.clientEntityType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pl-1",
                              staticStyle: { flex: "1 1 50%" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "h-outline",
                                attrs: {
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  label: _vm.$t("proposal.terms.entity-state")
                                },
                                model: {
                                  value: _vm.lTerms.clientState,
                                  callback: function($$v) {
                                    _vm.$set(_vm.lTerms, "clientState", $$v)
                                  },
                                  expression: "lTerms.clientState"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "mt-4",
                  attrs: { color: "primary-action", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.confirmTextConversion()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("proposal.terms.convert-button")))]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  staticStyle: { width: "160px" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("global.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "super-action ml-1",
                  staticStyle: { width: "160px" },
                  on: {
                    click: function($event) {
                      return _vm.requestSave()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("global.save")))]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.confirmConversion,
            callback: function($$v) {
              _vm.confirmConversion = $$v
            },
            expression: "confirmConversion"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "background-color": "var(--v-white-base)" } },
            [
              _c(
                "v-container",
                { staticClass: "pa-8", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h2", [
                          _vm._v(
                            _vm._s(_vm.$t("proposal.terms.convert-header"))
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12", align: "left" } }, [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("proposal.terms.confirm-convert")
                            )
                          }
                        })
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action mr-1",
                      on: {
                        click: function($event) {
                          _vm.confirmConversion = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary-action ml-1",
                      on: {
                        click: function($event) {
                          return _vm.requestTextConversion()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("global.continue")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }