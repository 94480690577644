var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "client-info-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("invoice.details.client-info-modal.heading")) +
                " "
            )
          ]),
          _c(
            "div",
            { staticClass: "h-outline mb-2" },
            [
              _c("div", {
                staticClass: "font-12 text-left mx-4 mt-4",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("invoice.details.client-info-modal.subheading")
                  )
                }
              }),
              _c("v-checkbox", {
                attrs: {
                  dense: "",
                  label: _vm.$t(
                    "invoice.details.client-info-modal.update-client"
                  )
                },
                model: {
                  value: _vm.updateClientToo,
                  callback: function($$v) {
                    _vm.updateClientToo = $$v
                  },
                  expression: "updateClientToo"
                }
              })
            ],
            1
          ),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              label: _vm.$t("client.client-name"),
              autocomplete: "new-password"
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSave.apply(null, arguments)
              }
            },
            model: {
              value: _vm.clientInfo.name,
              callback: function($$v) {
                _vm.$set(_vm.clientInfo, "name", $$v)
              },
              expression: "clientInfo.name"
            }
          }),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              label: _vm.$t("contact.mailing-address"),
              autocomplete: "new-password"
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSave.apply(null, arguments)
              }
            },
            model: {
              value: _vm.clientInfo.address1,
              callback: function($$v) {
                _vm.$set(_vm.clientInfo, "address1", $$v)
              },
              expression: "clientInfo.address1"
            }
          }),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              label: _vm.$t("contact.address-contd"),
              autocomplete: "new-password"
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSave.apply(null, arguments)
              }
            },
            model: {
              value: _vm.clientInfo.address2,
              callback: function($$v) {
                _vm.$set(_vm.clientInfo, "address2", $$v)
              },
              expression: "clientInfo.address2"
            }
          }),
          _c("div", { staticClass: "d-flex justify-space-between mt-n1" }, [
            _c(
              "div",
              { staticClass: "pr-1", staticStyle: { flex: "0 0 50%" } },
              [
                _c("v-text-field", {
                  staticClass: "h-outline",
                  attrs: {
                    "persistent-placeholder": "",
                    "hide-details": "",
                    label: _vm.$t("contact.city"),
                    autocomplete: "new-password"
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleSave.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.clientInfo.city,
                    callback: function($$v) {
                      _vm.$set(_vm.clientInfo, "city", $$v)
                    },
                    expression: "clientInfo.city"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pl-1", staticStyle: { flex: "0 0 50%" } },
              [
                _vm.states.length
                  ? _c("v-autocomplete", {
                      staticClass: "h-outline",
                      attrs: {
                        label: _vm.$t("contact.locality"),
                        autocomplete: "new-password",
                        "hide-details": "",
                        "persistent-placeholder": "",
                        items: _vm.states,
                        "item-value": "abbreviation",
                        "item-text": "name"
                      },
                      model: {
                        value: _vm.clientInfo.locality,
                        callback: function($$v) {
                          _vm.$set(_vm.clientInfo, "locality", $$v)
                        },
                        expression: "clientInfo.locality"
                      }
                    })
                  : _c("v-text-field", {
                      staticClass: "h-outline",
                      attrs: {
                        label: _vm.$t("contact.locality"),
                        autocomplete: "new-password",
                        "hide-details": "",
                        "persistent-placeholder": ""
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleSave.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.clientInfo.locality,
                        callback: function($$v) {
                          _vm.$set(_vm.clientInfo, "locality", $$v)
                        },
                        expression: "clientInfo.locality"
                      }
                    })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "d-flex justify-space-between mt-n1" }, [
            _c(
              "div",
              { staticClass: "pr-1", staticStyle: { flex: "0 0 50%" } },
              [
                _c("v-text-field", {
                  staticClass: "h-outline",
                  attrs: {
                    "persistent-placeholder": "",
                    "hide-details": "",
                    label: _vm.$t("contact.postal"),
                    autocomplete: "new-password"
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleSave.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.clientInfo.postal,
                    callback: function($$v) {
                      _vm.$set(_vm.clientInfo, "postal", $$v)
                    },
                    expression: "clientInfo.postal"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pl-1", staticStyle: { flex: "0 0 50%" } },
              [
                _c("v-autocomplete", {
                  staticClass: "h-outline",
                  attrs: {
                    label: _vm.$t("contact.country"),
                    autocomplete: "new-password",
                    "hide-details": "",
                    "persistent-placeholder": "",
                    items: _vm.countries.getCountriesSimple(),
                    "item-value": "code",
                    "item-text": "name"
                  },
                  model: {
                    value: _vm.clientInfo.country,
                    callback: function($$v) {
                      _vm.$set(_vm.clientInfo, "country", $$v)
                    },
                    expression: "clientInfo.country"
                  }
                })
              ],
              1
            )
          ]),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              label: _vm.$t("account.tax-id"),
              autocomplete: "new-password"
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSave.apply(null, arguments)
              }
            },
            model: {
              value: _vm.clientInfo.taxId,
              callback: function($$v) {
                _vm.$set(_vm.clientInfo, "taxId", $$v)
              },
              expression: "clientInfo.taxId"
            }
          }),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              label: _vm.$t("global.phone"),
              autocomplete: "new-password"
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSave.apply(null, arguments)
              }
            },
            model: {
              value: _vm.clientInfo.phone,
              callback: function($$v) {
                _vm.$set(_vm.clientInfo, "phone", $$v)
              },
              expression: "clientInfo.phone"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "px-16 primary-action",
              on: { click: _vm.handleSave }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }