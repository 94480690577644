var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "column-format centered",
        staticStyle: { width: "100%", height: "100%" }
      },
      [
        _c("div", { staticClass: "brand-bold font-24" }, [
          _vm._v("Hang tight, we are connecting your Xero account.")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }