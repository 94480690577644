var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-4", staticStyle: { "max-width": "985px!important" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("div", { staticStyle: { "font-size": "48px" } }, [
              _vm._v("Subscription add ons")
            ]),
            _c(
              "div",
              {
                staticClass: "brand-semilight",
                staticStyle: { "font-size": "32px" }
              },
              [
                _vm._v(
                  " Enhance your workspace with additional products and services "
                )
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { centered: "" },
                  model: {
                    value: _vm.productType,
                    callback: function($$v) {
                      _vm.productType = $$v
                    },
                    expression: "productType"
                  }
                },
                _vm._l(_vm.productTypes, function(productType) {
                  return _c(
                    "v-tab",
                    {
                      key: productType.value,
                      attrs: { "tab-value": productType.value }
                    },
                    [_vm._v(_vm._s(productType.label))]
                  )
                }),
                1
              ),
              _c(
                "div",
                {
                  staticClass: "row-format centered gap-4 my-8",
                  staticStyle: { "flex-wrap": "wrap" }
                },
                _vm._l(_vm.filteredProducts, function(product) {
                  return _c(
                    "div",
                    { key: product.id, staticClass: "product" },
                    [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(product.name))
                      ]),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(_vm._s(product.description))
                      ]),
                      product.entitlementStatus
                        ? _c(
                            "div",
                            { staticClass: "font-italic my-2" },
                            [
                              product.entitlementStatus.currentUsed <=
                              product.entitlementStatus.maxAllowed
                                ? _c("span", [
                                    _vm._v(
                                      "You are currently using " +
                                        _vm._s(
                                          product.entitlementStatus.currentUsed
                                        ) +
                                        " of " +
                                        _vm._s(
                                          product.entitlementStatus.maxAllowed
                                        ) +
                                        " license(s) for this add on. "
                                    )
                                  ])
                                : _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-weight": "600"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "Warning! You are currently using " +
                                          _vm._s(
                                            product.entitlementStatus
                                              .currentUsed
                                          ) +
                                          " license(s) but are only entitled to " +
                                          _vm._s(
                                            product.entitlementStatus.maxAllowed
                                          ) +
                                          " license(s) for this add on. Please adjust your subscription settings or remove active license(s) from your workspace to ensure this feature remains enabled. "
                                      )
                                    ]
                                  ),
                              product.entitlementStatus.link
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: product.entitlementStatus.link
                                      }
                                    },
                                    [_vm._v("Manage assignments")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "row-format align-center" },
                        [
                          _c("div", { staticClass: "price" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatters.dollars(
                                    product.price,
                                    true,
                                    true,
                                    product.currency
                                  )
                                ) +
                                "/" +
                                _vm._s(product.monthly ? "month" : "year") +
                                " "
                            )
                          ]),
                          product.hasActiveAddOn
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-auto",
                                  attrs: {
                                    elevation: "0",
                                    color: "gray_70",
                                    disabled: ""
                                  }
                                },
                                [_vm._v("Active subscription")]
                              )
                            : _c(
                                "v-btn",
                                {
                                  staticClass: "ml-auto",
                                  attrs: { elevation: "0", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.purchase(product)
                                    }
                                  }
                                },
                                [_vm._v("Purchase now")]
                              )
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                attrs: {
                  items: _vm.addOns,
                  "items-per-page": -1,
                  "hide-default-footer": true,
                  headers: _vm.headers
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.total",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$formatters.dollars(
                                item.quantity * item.product.price,
                                true,
                                true,
                                "USD"
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.period",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DateTime.fromISO(
                                item.currentPeriodStart
                              ).toLocaleString(_vm.DateTime.DATE_SHORT)
                            ) +
                            " - " +
                            _vm._s(
                              _vm.DateTime.fromISO(
                                item.currentPeriodEnd
                              ).toLocaleString(_vm.DateTime.DATE_SHORT)
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-8", attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { elevation: "0", color: "primary", large: "" },
                  on: {
                    click: function($event) {
                      return _vm.manageAddOns()
                    }
                  }
                },
                [_vm._v("Manage payment & subscriptions")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }