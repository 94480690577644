import HttpClient from '@/services/HttpClient';

export default class IntegrationService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getConnections(type='Zapier') {
		return this.httpClient.get(`/publicApi/connections?type=${type}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createConnection(type = 'Zapier'){
		return this.httpClient.post(`/publicApi/connections?type=${type}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateAuthentication(id,restHookAuth){
		return this.httpClient.put(`/publicApi/connections/${id}/auth`,restHookAuth)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	subscribeHook(id,restHook){
		return this.httpClient.post(`/publicApi/connections/${id}/hooks`,restHook)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	unSubscribeHook(id,hookId){
		return this.httpClient.delete(`/publicApi/connections/${id}/hooks/${hookId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateHookStatus(id,hookId,status){
		return this.httpClient.post(`/publicApi/connections/${id}/hooks/${hookId}/status?status=${status}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteConnection(id){
		return this.httpClient.delete(`/publicApi/connections/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
