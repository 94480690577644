var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-modal", staticStyle: { width: "600px" } },
    [
      _c("div", { staticClass: "panel-modal-header" }, [
        _c(
          "div",
          { staticClass: "row-format align-center" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mr-2 pointer",
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("result")
                  }
                }
              },
              [_vm._v("$chevronRight")]
            ),
            _c("div", { staticClass: "brand-medium font-18" }, [
              _vm._v("Time worked")
            ]),
            _c(
              "div",
              { staticClass: "ml-auto row-format align-center gap-1" },
              [
                _vm.timerEventClone
                  ? _c("pipeline-link-widget", {
                      attrs: {
                        "item-id": _vm.timerEventClone.id,
                        "item-type": "TimerEvent",
                        "client-id": _vm.timerEventClone.clientId
                      }
                    })
                  : _vm._e(),
                !_vm.disabled
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "ml-2 super-action",
                        attrs: { disabled: !_vm.isTimeRangeValid },
                        on: { click: _vm.handleSave }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                    )
                  : _vm._e(),
                !_vm.disabled
                  ? _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            _vm.deleteTimeEntryDialog = true
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "material-symbols-rounded",
                            attrs: { size: "20" }
                          },
                          [_vm._v("delete")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.disabled ? _c("v-icon", [_vm._v("lock")]) : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "panel-modal-body py-2 show-scrollbar" }, [
        _c(
          "div",
          { staticClass: "ma-4" },
          [
            _vm.timerEventClone
              ? _c(
                  "v-container",
                  { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "" } },
                      [
                        _c("v-col", { attrs: { cols: "12", align: "left" } }, [
                          _c("div", { staticClass: "brand-bold mb-4" }, [
                            _vm._v("Overview")
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "pt-1",
                        attrs: { align: "center", dense: "" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "font-14 font-gray_70 row-format align-center",
                            attrs: { cols: "4", align: "left" }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("event")
                            ]),
                            _c("div", { staticClass: "ml-2" }, [_vm._v("Date")])
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8", align: "left" } },
                          [
                            _c("date-selector", {
                              staticClass: "date-selector",
                              attrs: {
                                micro: true,
                                "show-year": true,
                                "micro-font": "font-14",
                                date: _vm.startDate,
                                label: "--",
                                disabled: _vm.disabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.startDateUpdated($event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "font-14 font-gray_70 row-format align-center",
                            attrs: { cols: "4", align: "left" }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("schedule")
                            ]),
                            _c("div", { staticClass: "ml-2" }, [
                              _vm._v("Start")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8", align: "left" } },
                          [
                            _c("vue-timepicker", {
                              staticStyle: { "font-size": "14px" },
                              attrs: {
                                format: _vm.format,
                                disabled: _vm.disabled,
                                "hide-clear-button": "",
                                "auto-scroll": "",
                                "manual-input": "",
                                "hide-dropdown": ""
                              },
                              on: { change: _vm.startTimeUpdated },
                              model: {
                                value: _vm.timeStartString,
                                callback: function($$v) {
                                  _vm.timeStartString = $$v
                                },
                                expression: "timeStartString"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "font-14 font-gray_70 row-format align-center",
                            attrs: { cols: "4", align: "left" }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("schedule")
                            ]),
                            _c("div", { staticClass: "ml-2" }, [_vm._v("End")])
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "row-format align-center",
                            attrs: { cols: "8", align: "left" }
                          },
                          [
                            _c("vue-timepicker", {
                              staticStyle: { "font-size": "14px" },
                              attrs: {
                                format: _vm.format,
                                disabled: _vm.disabled,
                                "hide-clear-button": true,
                                "auto-scroll": "",
                                "manual-input": true,
                                "hide-dropdown": true,
                                "drop-direction": "up"
                              },
                              on: { change: _vm.endTimeUpdated },
                              model: {
                                value: _vm.timeEndString,
                                callback: function($$v) {
                                  _vm.timeEndString = $$v
                                },
                                expression: "timeEndString"
                              }
                            }),
                            _vm.timerEventClone.pausedSeconds
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-left font-12 font-gray_70 mx-3 row-format align-center pointer",
                                    on: { click: _vm.confirmClearPause }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "material-symbols-rounded",
                                        attrs: { size: "16" }
                                      },
                                      [_vm._v("pause")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.pausedTimeFormatted) +
                                        " "
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "material-symbols-rounded",
                                        attrs: { size: "16" }
                                      },
                                      [_vm._v("clear")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "pt-1",
                        attrs: { align: "center", dense: "" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "font-14 font-gray_70 row-format align-center",
                            attrs: { cols: "4", align: "left" }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("watch")
                            ]),
                            _c("div", { staticClass: "ml-2" }, [
                              _vm._v("Duration")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8", align: "left" } },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.durationString,
                                  expression: "durationString"
                                }
                              ],
                              staticClass: "font-14 duration-input",
                              style:
                                (!_vm.isTimeRangeValid
                                  ? "color:var(--v-error-base)"
                                  : "color: var(--v-black-base)") +
                                "; width:60px; font-weight: 600",
                              attrs: {
                                maxlength: "6",
                                id: "duration",
                                disabled: _vm.disabled,
                                type: "text"
                              },
                              domProps: { value: _vm.durationString },
                              on: {
                                change: _vm.durationUpdated,
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.durationString = $event.target.value
                                }
                              }
                            }),
                            !_vm.isTimeRangeValid
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "mt-1",
                                    attrs: { color: "warning" }
                                  },
                                  [_vm._v("$alert")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "pt-1",
                        attrs: { align: "center", dense: "" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "font-14 font-gray_70 row-format align-center",
                            attrs: { cols: "4", align: "left" }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("person")
                            ]),
                            _c("div", { staticClass: "ml-2" }, [_vm._v("User")])
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8", align: "left" } },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-click": true,
                                  "close-on-content-click": true,
                                  "offset-y": "",
                                  disabled: _vm.disabled
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "pointer ml-2 font-14",
                                                staticStyle: {
                                                  "font-weight": "600"
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.assignedUser.firstName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.assignedUser.lastName
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1118556074
                                ),
                                model: {
                                  value: _vm.userMenu,
                                  callback: function($$v) {
                                    _vm.userMenu = $$v
                                  },
                                  expression: "userMenu"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-left pa-3 font-14",
                                    staticStyle: {
                                      "background-color": "var(--v-white-base)"
                                    }
                                  },
                                  _vm._l(_vm.userList, function(user) {
                                    return _c(
                                      "div",
                                      {
                                        key: user.userId,
                                        staticClass:
                                          "row-format align-center text-left py-1 pointer"
                                      },
                                      [
                                        user.userId
                                          ? _c("assigned-user", {
                                              staticClass: "mx-2",
                                              attrs: {
                                                "assigned-to": user.userId,
                                                "show-name": true
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.timerEventClone.userId =
                                                    user.userId
                                                  _vm.userMenu = false
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "pt-1",
                        attrs: { align: "center", dense: "" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "font-14 font-gray_70 row-format align-center",
                            attrs: { cols: "4", align: "left" }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("money")
                            ]),
                            _c("div", { staticClass: "ml-2" }, [
                              _vm._v("Billable")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8", align: "left" } },
                          [
                            _c("v-switch", {
                              staticClass: "mx-2 mt-0",
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                label: "",
                                color: "primary",
                                disabled: _vm.disabled
                              },
                              model: {
                                value: _vm.timerEventClone.billable,
                                callback: function($$v) {
                                  _vm.$set(_vm.timerEventClone, "billable", $$v)
                                },
                                expression: "timerEventClone.billable"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.disabled && _vm.timerEventClone.invoiceId
                      ? _c(
                          "v-row",
                          {
                            staticClass: "pt-1",
                            attrs: { align: "center", dense: "" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "font-14 font-gray_70 row-format align-center pt-2",
                                attrs: { cols: "4", align: "left" }
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("money")
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Invoiced")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "font-14 row-format align-center pt-2",
                                attrs: { cols: "8", align: "left" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "brand-bold font-primary pl-2 pointer",
                                    on: {
                                      click: function($event) {
                                        return _vm.openInvoice()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.timerEventClone.invoiceNumber
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pl-3",
                            attrs: { cols: "12", align: "left" }
                          },
                          [
                            _c("div", { staticClass: "brand-bold mt-6 mb-4" }, [
                              _vm._v("Client info")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "pt-1",
                        attrs: { align: "center", dense: "" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "font-14 font-gray_70 row-format align-center",
                            attrs: { cols: "4", align: "left" }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("work_outline")
                            ]),
                            _c("div", { staticClass: "ml-2" }, [
                              _vm._v("Client")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8", align: "left" } },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-click": true,
                                  "close-on-content-click": false,
                                  "offset-y": "",
                                  disabled: _vm.disabled
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "pointer pl-2 font-14"
                                              },
                                              on
                                            ),
                                            [
                                              _vm.selectedClient
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row-format align-center"
                                                    },
                                                    [
                                                      _c("client-avatar", {
                                                        attrs: {
                                                          client:
                                                            _vm.selectedClient,
                                                          "x-small": ""
                                                        }
                                                      }),
                                                      _c(
                                                        "div",
                                                        { staticClass: "ml-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.selectedClient
                                                                .name
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c("div", [_vm._v("--")])
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3011279055
                                ),
                                model: {
                                  value: _vm.clientMenu,
                                  callback: function($$v) {
                                    _vm.clientMenu = $$v
                                  },
                                  expression: "clientMenu"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-left column-format pt-1 show-scrollbar",
                                    staticStyle: {
                                      "background-color": "var(--v-white-base)",
                                      "overflow-y": "scroll",
                                      "max-height": "400px",
                                      "min-width": "300px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pa-2" },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            placeholder: "Filter..."
                                          },
                                          model: {
                                            value: _vm.clientFilter,
                                            callback: function($$v) {
                                              _vm.clientFilter = $$v
                                            },
                                            expression: "clientFilter"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.filteredClients, function(
                                      client
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: client.id,
                                          staticClass:
                                            "row-format align-center px-3 py-2 pointer hover-gray",
                                          staticStyle: {
                                            "border-bottom":
                                              "1px solid var(--v-gray_30-base)"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setClientId(client)
                                            }
                                          }
                                        },
                                        [
                                          client.id
                                            ? _c("client-avatar", {
                                                attrs: {
                                                  client: client,
                                                  "x-small": ""
                                                }
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              class:
                                                "ml-2 brand-medium " +
                                                (client.archive
                                                  ? "font-gray_70"
                                                  : "")
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(client.name) + " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.showClientProjectSelect
                      ? _c(
                          "v-row",
                          {
                            staticClass: "pt-1",
                            attrs: { align: "center", dense: "" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "font-14 font-gray_70 row-format align-center",
                                attrs: { cols: "4", align: "left" }
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("folder")
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Project")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "8", align: "left" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-click": true,
                                      "close-on-content-click": true,
                                      "offset-y": "",
                                      "max-height": "400",
                                      disabled: _vm.disabled
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "pointer pl-2 font-14"
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm.projectId
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row-format align-center"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.projectName
                                                                  ? _vm.projectName
                                                                  : "[blank]"
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _c("div", [_vm._v("--")])
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3985068083
                                    ),
                                    model: {
                                      value: _vm.projectMenu,
                                      callback: function($$v) {
                                        _vm.projectMenu = $$v
                                      },
                                      expression: "projectMenu"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-left column-format pt-1",
                                        staticStyle: {
                                          "background-color":
                                            "var(--v-white-base)",
                                          "overflow-y": "scroll"
                                        }
                                      },
                                      [
                                        _vm._l(_vm.projectItems, function(
                                          project
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: project.value,
                                              staticClass:
                                                "row-format align-center px-3 py-2 pointer hover-gray",
                                              staticStyle: {
                                                "border-bottom":
                                                  "1px solid var(--v-gray_30-base)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setProjectId(
                                                    project
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { class: "ml-2 brand-medium" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      project.text
                                                        ? project.text
                                                        : "[blank]"
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        !_vm.projectItems.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "brand-medium font-gray_70 pa-2"
                                              },
                                              [_vm._v("[No projects]")]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showClientProjectSelect
                      ? _c(
                          "v-row",
                          {
                            staticClass: "pt-1",
                            attrs: { align: "center", dense: "" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "font-14 font-gray_70 row-format align-center",
                                attrs: { cols: "4", align: "left" }
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("inventory")
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Task")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "8", align: "left" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-click": true,
                                      "close-on-content-click": true,
                                      "offset-y": "",
                                      "max-height": "400",
                                      disabled: _vm.disabled
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "pointer pl-2 font-14"
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm.deliverableId
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row-format align-center"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.deliverableName
                                                                  ? _vm.deliverableName
                                                                  : "[blank]"
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _c("div", [_vm._v("--")])
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3165498121
                                    ),
                                    model: {
                                      value: _vm.deliverableMenu,
                                      callback: function($$v) {
                                        _vm.deliverableMenu = $$v
                                      },
                                      expression: "deliverableMenu"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-left column-format pt-1",
                                        staticStyle: {
                                          "background-color":
                                            "var(--v-white-base)",
                                          "overflow-y": "scroll"
                                        }
                                      },
                                      [
                                        _vm._l(_vm.deliverableItems, function(
                                          deliverable
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: deliverable.value,
                                              staticClass:
                                                "row-format align-center px-3 py-2 pointer hover-gray",
                                              staticStyle: {
                                                "border-bottom":
                                                  "1px solid var(--v-gray_30-base)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setDeliverableId(
                                                    deliverable
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { class: "ml-2 brand-medium" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        deliverable.text
                                                          ? deliverable.text
                                                          : "[blank]"
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        !_vm.deliverableItems.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "brand-medium font-gray_70 pa-2"
                                              },
                                              [_vm._v(" [No deliverables] ")]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showTicketSelect
                      ? _c(
                          "v-row",
                          {
                            staticClass: "pt-1",
                            attrs: { align: "center", dense: "" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "font-14 font-gray_70 row-format align-center",
                                attrs: { cols: "4", align: "left" }
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("local_activity")
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Ticket")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "8", align: "left" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "close-on-click": true,
                                      "close-on-content-click": true,
                                      "offset-y": "",
                                      "max-height": "400",
                                      disabled: _vm.disabled
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "pointer pl-2 font-14"
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm.ticketId
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row-format align-center"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.ticketName
                                                                  ? _vm.ticketName
                                                                  : "[blank]"
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _c("div", [_vm._v("--")])
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3019609314
                                    ),
                                    model: {
                                      value: _vm.ticketMenu,
                                      callback: function($$v) {
                                        _vm.ticketMenu = $$v
                                      },
                                      expression: "ticketMenu"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-left column-format pt-1",
                                        staticStyle: {
                                          "background-color":
                                            "var(--v-white-base)",
                                          "overflow-y": "scroll"
                                        }
                                      },
                                      [
                                        _vm._l(_vm.ticketItems, function(
                                          ticket
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: ticket.value,
                                              staticClass:
                                                "row-format align-center px-3 py-2 pointer hover-gray",
                                              staticStyle: {
                                                "border-bottom":
                                                  "1px solid var(--v-gray_30-base)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setTicketId(ticket)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { class: "ml-2 brand-medium" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      ticket.text
                                                        ? ticket.text
                                                        : "[blank]"
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        !_vm.ticketItems.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "brand-medium font-gray_70 pa-2"
                                              },
                                              [_vm._v("[No open tickets]")]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pl-3",
                            attrs: { cols: "12", align: "left" }
                          },
                          [
                            _c("div", { staticClass: "brand-bold mt-6 mb-2" }, [
                              _vm._v("Notes")
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-textarea", {
                              attrs: {
                                "auto-grow": "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                rows: 1,
                                placeholder: _vm.$t(
                                  "timetrack.current.notes-placeholder"
                                ),
                                disabled: _vm.disabled
                              },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  if (
                                    $event.ctrlKey ||
                                    $event.shiftKey ||
                                    $event.altKey ||
                                    $event.metaKey
                                  ) {
                                    return null
                                  }
                                  return _vm.handleSave.apply(null, arguments)
                                }
                              },
                              model: {
                                value: _vm.timerEventClone.notes,
                                callback: function($$v) {
                                  _vm.$set(_vm.timerEventClone, "notes", $$v)
                                },
                                expression: "timerEventClone.notes"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.deleteTimeEntryDialog,
          "heading-text": "Delete?",
          "body-text": _vm.$t("timetrack.time-entry-delete-confirm")
        },
        on: {
          confirm: _vm.handleDelete,
          close: function($event) {
            _vm.deleteTimeEntryDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }