<template>
	<div class="panel-modal" style="width: 600px" v-if="isReady">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="savePackage(false)">$chevronRight</v-icon>
				<v-icon color="black" class="mr-1 material-symbols-rounded">folder_special</v-icon>
				<div class="brand-medium font-18"><div>Edit package settings</div></div>
				<div class="ml-auto">
					<v-btn class="super-action" @click="savePackage(true)">
						{{ $t('global.save') }}
					</v-btn>
					<v-icon size="20" color="gray_60" class="pointer ml-2" @click="deletePackage">$delete</v-icon>
				</div>
			</div>
		</div>
		<div class="panel-modal-body text-left">
			<div id="deliverable-detail">
				<input id="deliverableLink" name="deliverableLink" type="hidden" />
				<div class="row-format">
					<v-text-field
						autofocus
						hide-details
						dense
						outlined
						placeholder="Project name"
						v-model="pkg.name"
					></v-text-field>
				</div>
				<div class="mt-6 mb-4 row-format align-center gap-3">
					<div
						:class="`page-link ${currentView === 'Overview' ? 'page-link-active' : ''}`"
						@click="setView('Overview')"
					>
						<div>Overview</div>
					</div>
					<div :class="`page-link ${currentView === 'Tasks' ? 'page-link-active' : ''}`" @click="setView('Tasks')">
						<div>Tasks</div>
					</div>
				</div>

				<div class="show-scrollbar" style="max-height: calc(100vh - 210px); overflow-y: auto">
					<div v-show="currentView === 'Overview'" style="width: 98%" class="">
						<v-form v-model="valid" @submit.prevent>
							<div class="column-format gap-3">
								<div class="column-format pt-2">
									<div
										style="border: 1px solid var(--v-gray_30-base); border-radius: 4px; position: relative"
										class="px-2 py-2"
									>
										<div
											class="font-12 text-left font-gray_70 px-1"
											style="position: absolute; top: -10px; background-color: var(--v-white-base)"
										>
											Description
										</div>
										<div style="padding-left: 2px" class="font-14">
											<editor
												ref="Description"
												v-model="pkg.description"
												:inline="true"
												:init="mceConfig"
												:spellcheck="true"
											></editor>
										</div>
									</div>
								</div>

								<v-select
										:items="portalAccessTypes"
										v-model="pkg.portalAccess"
										persistent-placeholder
										hide-details
										dense
										outlined
										item-value="value"
										item-text="label"
										label="Client portal access"
								>
								</v-select>

								<v-select
										:items="showTimeInPortal"
										v-model="pkg.showTimeWorkedInPortal"
										hide-details
										persistent-placeholder
										dense
										outlined
										item-value="value"
										item-text="label"
										label="Show time worked in portal"
								>
								</v-select>

								<v-select
									:items="feeTypes"
									v-model="pkg.feeSchedule.feeType"
									hide-details
									persistent-placeholder
									dense
									outlined
									item-value="value"
									item-text="label"
									label="Project fee type"
								>
								</v-select>

								<div v-if="pkg.feeSchedule.feeType !== 'Per Item'" class="row-format align-center gap-2">
									<v-text-field
										v-model="pkg.feeSchedule.amount"
										:prefix="currencySymbol"
										hide-details
										persistent-placeholder
										dense
										outlined
										oninput="if(this.value < 0) this.value = this.value*-1;"
										type="number"
										label="Amount"
									></v-text-field>
									<v-checkbox
										v-if="taxEnabled"
										class="mt-n1 mb-0"
										dense
										hide-details
										v-model="pkg.feeSchedule.taxable"
										><template v-slot:label><span class="font-12 ml-n2">Taxable</span></template></v-checkbox
									>
								</div>

								<div v-if="pkg.feeSchedule.feeType === 'Retainer'" class="column-format gap-2">
									<v-select
										:items="schedule"
										v-model="pkg.feeSchedule.retainerSchedule"
										hide-details
										persistent-placeholder
										dense
										outlined
										label="Retainer period"
									>
										<template v-slot:item="{ item }">
											<span>{{ item }}</span>
										</template>
									</v-select>

									<v-select
										:items="retainerTiming"
										v-model="pkg.feeSchedule.retainerTiming"
										hide-details
										persistent-placeholder
										dense
										outlined
										label="Retainer timing"
									>
										<template v-slot:item="{ item }">
											<span>{{ item }}</span>
										</template>
									</v-select>
								</div>

								<div class="row-format gap-2">
									<v-text-field
										v-model="pkg.feeSchedule.estimateMin"
										label="Hour estimate (min)"
										hide-details
										persistent-placeholder
										dense
										outlined
										type="number"
										min="0"
										max="10000"
										@change="validateMinMax('min')"
									></v-text-field>

									<v-text-field
										v-model="pkg.feeSchedule.estimateMax"
										label="Hour estimate (max)"
										hide-details
										persistent-placeholder
										dense
										outlined
										type="number"
										min="0"
										max="10000"
										@change="validateMinMax('max')"
									></v-text-field>
								</div>

								<amount-input
									v-if="pkg.feeSchedule.feeType === 'Retainer' && pkg.feeSchedule.estimateMax"
									v-model="pkg.feeSchedule.retainerOverageRate"
									:prefix="currencySymbol"
									hide-details
									persistent-placeholder
									dense
									outlined
									oninput="if(this.value < 0) this.value = this.value*-1;"
									type="text"
									label="Retainer overage hourly amount"
								></amount-input>
							</div>
						</v-form>
					</div>

					<div>
						<div v-show="currentView === 'Tasks'" style="width: 98%">
							<div>
								<div class="row-format gap-2 justify-end">
									<v-btn class="primary-action" @click="addNewDeliverable()">
										<span class="font-secondary">Add task</span>
										<v-icon small class="ml-1" color="secondary">add</v-icon>
									</v-btn>
								</div>

								<div class="mt-2">
									<draggable :list="pkg.deliverables"  class="column-format gap-2">
										<deliverable-package-task
											@click="editDeliverable(deliverable)"
											class="pa-1"
											style="border: 1px solid var(--v-gray_30-base); border-radius: 4px"
											:hide-icon="true"
											v-for="deliverable in pkg.deliverables"
											:key="deliverable.id"
											:deliverable="deliverable"
										>
											></deliverable-package-task
										>
									</draggable>
								</div>

								<div v-if="pkg.deliverables.length === 0">
									<div class="text-center font-gray_70">No tasks</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AmountInput from '@/components/AmountInput.vue';
	import ConfirmModal from '@/components/ConfirmModal.vue';
	import TemplateService from '@/modules/templates/TemplateService';
	import editor from '@tinymce/tinymce-vue';
	import DeliverablePackageTask from '@/modules/templates/deliverables/DeliverablePackageTask';
	import DeliverablePackageTaskEdit from '@/modules/templates/deliverables/DeliverablePackageTaskEdit';
	import { v4 as uuidv4 } from 'uuid';
	import draggable from "vuedraggable";


	export default {
		name: 'DeliverablePackageEdit',
		isRightModal: true,

		props: ['id'],

		components: { AmountInput, DeliverablePackageTask, editor, draggable },

		data: function() {
			return {
				isReady: false,
				templateService: new TemplateService(),
				currentView: 'Overview',
				valid: false,
				feeTypes: [
					{ label: 'Hourly', value: 'Hourly' },
					{ label: 'Fixed Price', value: 'Fixed Price' },
					{ label: 'Recurring', value: 'Retainer' },
					{ label: 'Per Item', value: 'Per Item' },
				],
				portalAccessTypes: [
					{ label: 'Full project collaboration', value: 'Full access' },
					{ label: 'Read only project collaboration', value: 'Read only' },
					{ label: 'Overview only', value: 'Overview' },
					{ label: 'Not visible', value: 'None' },
				],
				showTimeInPortal: [
					{ label: 'Yes - time worked is visible in portal', value: true },
					{ label: 'No - time worked is hidden', value: false },
				],
				schedule: ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Bi-Annually', 'Annually'],
				retainerTiming: ['Advanced', 'Arrears'],
				pkg: null,
				mceConfig: {
					menubar: false,
					inline: true,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					browser_spellcheck: true,
					statusbar: false,
					placeholder: 'Project description',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: 'paste | link image | table',
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'fontsizeselect styleselect forecolor | bold italic underline |  bullist numlist',
						'blockquote alignleft aligncenter alignright alignjustify indent outdent | table link unlink media code',
					],
					style_formats: [
						{ title: 'Paragraph', format: 'p' },
						{ title: 'Title', format: 'h1' },
						{ title: 'Heading', format: 'h2' },
						{ title: 'Subheading', format: 'h3' },
						{ title: 'Code', format: 'code' },
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
				},
			};
		},
		mounted() {
			this.getPackage();
		},

		methods: {
			getPackage: function() {
				this.templateService.getDeliverablePackage(this.id).then((res) => {
					this.pkg = res.data;
					if (!this.pkg.feeSchedule) {
						this.pkg.feeSchedule = this.createFeeSchedule();
					}
					this.isReady = true;
				});
			},

			savePackage(alert = true) {
				this.templateService.putDeliverablePackage(this.pkg.id, this.pkg).then((res) => {
					if (alert) this.$store.commit('success', 'Saved successfully');
					this.$emit('result', res.data);
				});
			},

			addNewDeliverable() {
        let defaultStatus = this.$store.state.deliverableStatusList.statusList[0].id; // Get the first element in the statusList array
				let deliverable = {
					id: uuidv4().replace(/-/g, ''),
					name: null,
					description: null,
					priority: 0,
          statusId: defaultStatus,
					assignedToList: [],
					dueDate: {
						duration: null,
						timeUnit: 'WEEKS',
					},
					tasks: [],
					files: [],
					customValues: [],
					isRecurring: false,
					recurringSettings: {
						recurrenceType: 'CUSTOM',
						monthlyWeekOfMonth: 1,
						monthlyDayOfWeek: 1,
						monthlyDayOfMonth: 0,
						weeklyDays: [],
						interval: null
					}
				};
				this.pkg.deliverables.push(deliverable);
				this.editDeliverable(deliverable);
			},

			editDeliverable: function(deliverable) {
				let binding = {
					deliverablePackage: this.pkg,
					deliverable: deliverable,
				};

				this.$store.state.globalModalController.openModal(DeliverablePackageTaskEdit, binding).then((res) => {
					if (res) {
						let ix = this.pkg.deliverables.findIndex((d) => d.id === deliverable.id);
						if (res.action === 'DELETED') {
							this.pkg.deliverables.splice(ix, 1);
						} else {
							this.pkg.deliverables.splice(ix, 1, res);
						}
					}
				});
			},

			deletePackage: function() {
				let binding = {
					headingText: this.$t('templates.packages.delete-package-title'),
					bodyText: this.$t('templates.packages.delete-package-body', { name: this.pkg.name }),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.templateService.deletePackage(this.pkg.id).then(() => {
							this.pkg.action = 'DELETED';
							setTimeout(() => this.$emit('result', this.pkg,100));
						});
					}
				});
			},

			setView: function(view) {
				this.currentView = view;
			},

			validateMinMax: function(minOrMax) {
				let val;

				if (minOrMax === 'min') {
					val = this.pkg.feeSchedule.estimateMin;
				} else {
					val = this.pkg.feeSchedule.estimateMax;
				}

				if (!val) {
					return;
				}

				val = parseInt(val, 10);

				if (val < 0) {
					val = 0;
				} else if (val > 10000) {
					val = 10000;
				}

				if (minOrMax === 'min') {
					this.pkg.feeSchedule.estimateMin = val;
				} else {
					this.pkg.feeSchedule.estimateMax = val;
				}
			},

			createFeeSchedule: function() {
				return {
					feeType: 'Hourly',
					amount: 0,
					retainerSchedule: 'Monthly',
					estimateMin: 0,
					estimateMax: 0,
					retainerTiming: 'Advanced',
					fromProposalId: null,
					fromProposalSignedDate: null,
					updatedBy: null,
					updatedDate: null,
					retainerStart: this.$DateTime
						.local()
						.plus({ days: 1 })
						.toFormat('yyyy-MM-dd'),
					retainerEnd: null,
				};
			},
		},

		computed: {
			currencySymbol: function() {
				return this.$formatters.currencySymbol(this.$store.state.defaultCurrency);
			},
			taxEnabled: function() {
				return this.$store.state.podAccount.accountPreferences.invoiceUseTax;
			},
		},

		watch: {},
	};
</script>

<style scoped lang="scss">
	.approval {
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		padding: 4px 8px;
		margin-top: 4px;
		margin-bottom: 4px;
	}

	.numberInput {
		max-width: 50px;
		color: var(--v-black-base);
	}

	.numberInput:focus {
		outline: none;
	}

	#deliverable-detail {
		padding-left: 12px;
		padding-top: 16px;

		.overview-left {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: fit-content;
			color: var(--v-gray_70-base);
			font-size: 14px;
			font-weight: 500;
			width: 140px;
			min-width: 140px;
			max-width: 140px;
			margin-right: 16px;
		}

		.overview-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 14px;
			font-weight: 500;
		}

		.time-worked {
			border: 1px solid var(--v-gray_50-base);
			border-radius: 4px;
			height: 24px;
			padding: 0px 4px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			line-height: 18px;

			&:hover:not(.disabled) {
				background-color: var(--v-gray_10-base);
				cursor: pointer;
			}
		}
	}
</style>
