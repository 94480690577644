<template>
	<div style="background-color: var(--v-white-base)" class="pa-3">
		<div class="row-format align-center">
			<div class="brand-medium">Total {{ type }}</div>
			<v-icon class="ml-auto" @click="handleClose">$close</v-icon>
		</div>
		<invoice-table
			v-if="type === 'invoiced'"
			:invoices="dataset"
			@open-invoice="openInvoice($event)"
			@add-update-invoice="addOrUpdateInvoice($event)"
			@remove-invoice="removeInvoice($event)"
			@invoice-paid="handleInvoicePaid($event)"
		></invoice-table>
		<payment-table v-if="type === 'received'" :payments="dataset" @open-invoice="openInvoice($event)"></payment-table>
		<payment-table v-if="type === 'other income'" :payments="dataset" @open-invoice="openIncome($event)"></payment-table>
		<expense-table
			v-if="type === 'expenses'"
			:expenses="dataset"
			@click="openExpense($event)"
			:suppress-new="true"
		></expense-table>
		<timer-event-table
			v-if="type === 'hours'"
			:show-client="true"
			:show-add="false"
			:events="dataset"
			@edit-timer="openTimerEvent($event)"
		></timer-event-table>
	</div>
</template>

<script>
	import InvoiceTable from '@/modules/accounting/invoices/InvoiceTable';
	import PaymentTable from '@/modules/accounting/payments/PaymentTable';
	import ExpenseTable from '@/modules/accounting/expenses/ExpenseTable';
	import TimerEventTable from '@/modules/clients/detail/timetracking/TimerEventTable';
	import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
	import ProjectService from '@/modules/projects/ProjectService';
	import VendorService from '@/modules/accounting/vendors/VendorService';
	import ExpenseDetail from '@/modules/accounting/expenses/ExpenseDetail';
	import PaymentDetail from '@/modules/accounting/payments/PaymentDetail';
	import PaymentDetailsModal from "@/modules/invoices/InvoiceRenderer/PaymentDetailsModal";

	export default {
		name: 'MetricTableModal',

		props: ['dataset', 'type'],

		components: { TimerEventTable, ExpenseTable, PaymentTable, InvoiceTable },

		data: function() {
			return {
				projectService: new ProjectService(),
				vendorService: new VendorService(),
				markDirty: false,
				projects: [],
				vendors: [],
			};
		},

		mounted() {
			if (this.type === 'expenses') {
				this.getProjects();
				this.getVendors();
			}
		},

		beforeDestroy() {},

		methods: {
			handleClose: function() {
				this.$emit('result', this.markDirty);
			},

			spliceIt: function(res, doDelete = false) {
				let ix = this.dataset.findIndex((d) => d.id === res.id);
				if (ix > -1) {
					if (doDelete) {
						this.dataset.splice(ix, 1);
					} else {
						this.dataset.splice(ix, 1, res);
					}
				}
			},

			addOrUpdateInvoice: function(invoice){
				this.spliceIt(invoice);
				this.markDirty = true;
			},

			removeInvoice: function(invoice){
				this.spliceIt(invoice,true);
				this.markDirty = true;
			},

			handleInvoicePaid: function(event){
				event.editMode = true;
				this.markDirty = true;
				this.$store.state.globalModalController.openModal(PaymentDetailsModal,event).then(() => {

				})
			},

			openInvoice: function(invoice) {
				let binding = {
					id: invoice.invoiceId ? invoice.invoiceId : invoice.id,
					clientId: invoice.clientId,
				};
				this.$store.state.globalModalController.openModal(InvoiceDetails, binding, true, true).then((res) => {
					if (res) {
						this.spliceIt(res);
						this.markDirty = true;
					}
				});
			},

			openIncome: function(payment) {
				this.$store.state.globalModalController.openModal(PaymentDetail, { payment: payment }).then((res) => {
					if (res) {
						console.log(res);
						this.spliceIt(res, res.action === 'DELETED');
						this.markDirty = true;
					}
				});
			},

			openTimerEvent: function(event) {
				let binding = {
					timerEvent: event,
				};

				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding, true, false).then((res) => {
					if (res) {
						this.spliceIt(res);
						this.markDirty = true;
					}
				});
			},

			openExpense: function(expense) {
				let binding = {
					expense: expense,
					inClients: this.clients,
					inProjects: this.projects,
					inVendors: this.vendors,
				};
				this.$store.state.globalModalController.openModal(ExpenseDetail, binding, true, false).then((res) => {
					if (res) {
						this.spliceIt(res);
						this.markDirty = true;
					}
				});
			},

			addExpense: function() {
				console.log('add expense');
			},

			getProjects: function() {
				this.projectService.getAllActiveProjects().then((res) => {
					this.projects.splice(0, this.projects.length);
					this.projects.push(...res.data);
				});
			},

			getVendors: function() {
				this.vendorService.getVendors().then((res) => {
					this.vendors.splice(0, this.vendors.length);
					this.vendors.push(...res.data);
					this.vendors.sort((a, b) => (a.name > b.name ? 1 : -1));
				});
			},
		},

		computed: {
			clients: function() {
				return this.$store.state.clientCache;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
