var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "write-off-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("h2", { staticClass: "modal-title" }, [
            _vm._v(
              _vm._s(
                _vm.$t("invoice.write-off-modal.heading", {
                  invoiceNumber: _vm.invoice.invoiceNumber
                })
              )
            )
          ]),
          _c("p", { staticClass: "modal-subtitle" }, [
            _vm._v(
              _vm._s(
                _vm.$t("invoice.write-off-modal.subheading", {
                  invoiceNumber: _vm.invoice.invoiceNumber
                })
              )
            )
          ]),
          _c("div", { staticClass: "total" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$formatters.dollars(
                    _vm.invoice.amountDue,
                    true,
                    true,
                    _vm.invoice.currency
                  )
                ) +
                " "
            )
          ]),
          _vm.client
            ? _c(
                "div",
                { staticClass: "client-name" },
                [
                  _c("client-avatar", {
                    attrs: { client: _vm.client, left: "" }
                  }),
                  _c("div", [_vm._v(_vm._s(_vm.client.name))])
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "font-14 text-left brand-semilight my-4" }, [
            _vm._v(" " + _vm._s(_vm.$t("invoice.write-off-modal.body")) + " ")
          ]),
          _c(
            "div",
            {
              staticClass:
                "h-outline pa-2 mb-4 font-14 text-left brand-semilight d-flex justify-start align-center"
            },
            [
              _c("v-simple-checkbox", {
                model: {
                  value: _vm.checkboxConfirmed,
                  callback: function($$v) {
                    _vm.checkboxConfirmed = $$v
                  },
                  expression: "checkboxConfirmed"
                }
              }),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("invoice.write-off-modal.checkbox-label")))
              ])
            ],
            1
          ),
          _c("v-textarea", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              rows: 3,
              label: _vm.$t("invoice.write-off-modal.note-label"),
              placeholder: _vm.$t("invoice.write-off-modal.note-placeholder")
            },
            model: {
              value: _vm.userNote,
              callback: function($$v) {
                _vm.userNote = $$v
              },
              expression: "userNote"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "px-16 primary-action",
              attrs: { disabled: !_vm.checkboxConfirmed },
              on: { click: _vm.handleSave }
            },
            [_vm._v(_vm._s(_vm.$t("invoice.write-off-modal.save-button")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }