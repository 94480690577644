<template>
	<div v-if="mode === 'standard' || mode === 'dense'" style="width:100%">
		<v-autocomplete persistent-placeholder outlined v-model="ourValue" :label="field.name" hide-details :dense="mode === 'dense'" :items="field.options">
		</v-autocomplete>
	</div>
	<div v-else style="width:100%">
		<v-menu :close-on-click="true" :close-on-content-click="true">
			<template v-slot:activator="{ on }">
				<div class="pointer truncate" v-on="on">{{ ourValue ? ourValue : '--' }}</div>
			</template>
			<div class="more-menu">
				<div class="more-menu-item" @click="ourValue = null">--</div>
				<div class="more-menu-item" v-for="(option, index) in field.options" :key="index" @click="ourValue = option">
					{{ option }}
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'CustomSelect',

		props: ['field', 'value', 'mode'],

		components: {},

		data: function() {
			return {
				ourValue: this.value,
			};
		},

		mounted() {},

		beforeDestroy() {},

		watch: {
			ourValue: function(val) {
				this.$emit('change', val);
			},
		},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
