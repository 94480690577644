var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "topNavWrapper", attrs: { id: "top-nav-wrapper" } }, [
    _c("nav", { ref: "topNav", attrs: { id: "top-nav" } }, [
      _c(
        "div",
        { staticClass: "row-format align-center", attrs: { id: "left-side" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { icon: "", color: "gray_90" },
              on: {
                click: function($event) {
                  return _vm.setLeftNavExpanded()
                }
              }
            },
            [
              _c(
                "span",
                {
                  staticClass: "pointer material-symbols-rounded",
                  staticStyle: { "font-size": "20px" }
                },
                [_vm._v("menu_open")]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "row-format align-center gap-2 px-2 pointer font-14",
              staticStyle: {
                "min-height": "32px",
                "min-width": "200px",
                border: "1px solid var(--v-gray_30-base)",
                "border-radius": "4px",
                "background-color": "var(--v-white-base)",
                color: "var(--v-gray_50-base)"
              },
              on: { click: _vm.openSearch }
            },
            [
              _c(
                "div",
                {
                  staticClass: "material-symbols-rounded",
                  staticStyle: { "font-size": "20px" }
                },
                [_vm._v("search")]
              ),
              _c("div", [_vm._v("Cmd + k")])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { ref: "rightSide", attrs: { id: "right-side" } },
        [
          _c("time-track-widget"),
          _c(
            "div",
            {
              class:
                "mr-2 right " +
                (_vm.$store.state.rightNavExpanded
                  ? "right-big"
                  : "right-small"),
              staticStyle: { "justify-content": "space-evenly" }
            },
            [
              _vm.$store.getters.hasFeatureAccess("any")
                ? _c(
                    "div",
                    {
                      class:
                        "pointer top-button " +
                        (_vm.$store.state.rightNavFocus === "phone"
                          ? "active-top"
                          : ""),
                      on: {
                        click: function($event) {
                          return _vm.openRightNav("phone")
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          class:
                            _vm.$store.state.rightNavFocus === "phone"
                              ? ""
                              : "material-symbols-rounded",
                          attrs: {
                            size: "20",
                            color:
                              _vm.$store.state.rightNavFocus === "phone"
                                ? "primary"
                                : "black"
                          }
                        },
                        [_vm._v("smartphone")]
                      ),
                      _vm.$store.getters.unreadCommunicator
                        ? _c("div", { staticClass: "top-umi" }, [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.$store.getters.unreadCommunicator)
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  class:
                    "pointer top-button " +
                    (_vm.$store.state.rightNavFocus === "productivity"
                      ? "active-top"
                      : ""),
                  on: {
                    click: function($event) {
                      return _vm.openRightNav("productivity")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      class:
                        _vm.$store.state.rightNavFocus === "productivity"
                          ? ""
                          : "material-symbols-rounded",
                      attrs: {
                        size: "24",
                        color:
                          _vm.$store.state.rightNavFocus === "productivity"
                            ? "primary"
                            : "black"
                      }
                    },
                    [_vm._v("checklist_rtl")]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  class:
                    "pointer top-button " +
                    (_vm.$store.state.rightNavFocus === "notifications"
                      ? "active-top"
                      : ""),
                  on: {
                    click: function($event) {
                      return _vm.openRightNav("notifications")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      class:
                        _vm.$store.state.rightNavFocus === "notifications"
                          ? ""
                          : "material-symbols-rounded",
                      attrs: {
                        size: "20",
                        color:
                          _vm.$store.state.rightNavFocus === "notifications"
                            ? "primary"
                            : "black"
                      }
                    },
                    [_vm._v("notifications_active")]
                  ),
                  _vm.$store.getters.unreadNotificationCount
                    ? _c("div", { staticClass: "top-umi" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.$store.getters.unreadNotificationCount)
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "content-class": "user-menu", "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c("div", _vm._g({}, on), [
                            _vm.profilePicture
                              ? _c("div", {
                                  staticClass: "profile-pic",
                                  style:
                                    "background-image: url(" +
                                    _vm.profilePicture +
                                    ")"
                                })
                              : _c("div", { staticClass: "profile-pic" }, [
                                  _c("span", { staticClass: "white--text" }, [
                                    _vm._v(_vm._s(_vm.initials))
                                  ])
                                ])
                          ])
                        ]
                      }
                    }
                  ])
                },
                [_c("user-menu")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }