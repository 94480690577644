import HttpClient from '@/services/HttpClient';

export default class VendorService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getVendors() {
		return this.httpClient
			.get('/vendors')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createVendor(vendor) {
		return this.httpClient
			.post(`/vendors/`, vendor)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateVendor(id, vendor) {
		return this.httpClient
			.put(`/vendors/${id}`, vendor)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteVendor(id) {
		return this.httpClient
			.delete(`/vendors/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
