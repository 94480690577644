<template>
	<div class="search-button row-format centered pa-2 gap-2" @click="$emit('click')">
		<div><img src="/images/templates/img.png" style="max-width: 150px"></div>
		<div class="text-left">
			<div class="font-18 brand-medium">Search the Moxie Template Library</div>
			<div class="font-14">Over 250 pre-built templates designed specifically for you</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SearchButton",

	props: [],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>