var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        {
          class:
            "form-detail " +
            (_vm.formSubmission.formData.answers.length
              ? "with-answers"
              : "no-answers")
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-padding",
              attrs: { id: "form-inner-wrapper" }
            },
            [
              _c("div", { staticClass: "row-format mb-2 align-center" }, [
                _c("div", { staticClass: "brand-medium font-18" }, [
                  _vm._v(_vm._s(_vm.$t("forms.form-modal.title")))
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto row-format align-center gap-4" },
                  [
                    _c("pipeline-link-widget", {
                      attrs: {
                        "item-id": _vm.formSubmission.id,
                        "item-type": "Form",
                        "client-id": _vm.formSubmission.clientId
                      }
                    }),
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-auto pointer",
                        attrs: { size: "20" },
                        on: { click: _vm.handleClose }
                      },
                      [_vm._v("$close")]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-container",
                    { staticClass: "ml-0 pa-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-0",
                              attrs: {
                                md: _vm.formSubmission.formData.answers.length
                                  ? 6
                                  : 12,
                                cols: "12",
                                align: "left"
                              }
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "pa-0 ma-0 font-14" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.form-modal.form-name"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(_vm.formSubmission.formName)
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm.formSubmission.formData.sourceUrl
                                    ? _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "brand-medium",
                                              attrs: { cols: "4" }
                                            },
                                            [_vm._v("Source URL")]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formSubmission.formData
                                                    .sourceUrl
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("global.contact"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "row-format align-center gap-2",
                                          attrs: { cols: "8" }
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formSubmission.formData
                                                  .firstName
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.formSubmission.formData
                                                    .lastName
                                                )
                                            )
                                          ]),
                                          _vm.showCreateContact
                                            ? _c(
                                                "v-icon",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tippy",
                                                      rawName: "v-tippy",
                                                      value: {
                                                        content:
                                                          "Create new contact"
                                                      },
                                                      expression:
                                                        "{ content: 'Create new contact' }"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "pointer material-symbols-rounded",
                                                  attrs: {
                                                    size: "22",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.addNewContact()
                                                    }
                                                  }
                                                },
                                                [_vm._v("person_add")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.businessInfo
                                    ? _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "brand-medium",
                                              attrs: { cols: "4" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "forms.meeting-modal.business-info"
                                                  )
                                                )
                                              )
                                            ]
                                          ),
                                          _c("v-col", {
                                            attrs: { cols: "8" },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.businessInfo
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("global.email")))]
                                      ),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formSubmission.formData.email
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("global.phone")))]
                                      ),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formSubmission.formData.phone
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "forms.form-modal.submitted"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.DateTime.fromISO(
                                              _vm.formSubmission.submittedAt
                                            ).toLocaleString(
                                              _vm.DateTime.DATETIME_MED
                                            )
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "brand-medium",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("forms.form-modal.from-ip")
                                            )
                                          )
                                        ]
                                      ),
                                      _c("v-col", { attrs: { cols: "8" } }, [
                                        _vm.formSubmission.ipLookup.ip
                                          ? _c(
                                              "div",
                                              { staticClass: "ipBlock" },
                                              [
                                                _vm._v(" Country: "),
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      _vm.formSubmission
                                                        .ipLookup.countryFlag,
                                                    width: "15px"
                                                  }
                                                }),
                                                _c("br"),
                                                _vm._v(
                                                  " Location: " +
                                                    _vm._s(
                                                      _vm.formSubmission
                                                        .ipLookup.city
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.formSubmission
                                                        .ipLookup.region
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.formSubmission
                                                        .ipLookup.country
                                                    )
                                                ),
                                                _c("br")
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-2 mb-0 pb-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("select-client", {
                                            ref: "selectClient",
                                            attrs: {
                                              id: "select-client",
                                              "prepend-icon": "$clients",
                                              "hide-archive": true,
                                              label: "Client",
                                              placeholder: _vm.$t(
                                                "timetrack.current.choose-client"
                                              )
                                            },
                                            model: {
                                              value:
                                                _vm.formSubmission.clientId,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formSubmission,
                                                  "clientId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formSubmission.clientId"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-n2 text-center pa-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          !_vm.formSubmission.clientId
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "secondary-action add-new-client",
                                                  attrs: {
                                                    color: "primary",
                                                    text: ""
                                                  },
                                                  on: {
                                                    click: _vm.addNewClient
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "+ " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "forms.meeting-modal.create-client"
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "h-outline",
                                            attrs: {
                                              "hide-details": "",
                                              "persistent-placeholder": "",
                                              label: "Notes",
                                              rows: "3",
                                              "auto-grow": ""
                                            },
                                            model: {
                                              value: _vm.formSubmission.notes,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.formSubmission,
                                                  "notes",
                                                  $$v
                                                )
                                              },
                                              expression: "formSubmission.notes"
                                            }
                                          }),
                                          _vm.formSubmission.summary
                                            ? _c(
                                                "div",
                                                { staticClass: "text-left" },
                                                [
                                                  _c("div", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.formSubmission
                                                          .summary
                                                      )
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "row-format centered"
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    text: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.generateSummary(
                                                        false
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("✨Summarize")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.fromLeadGen
                                    ? _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "mt-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  inset: "",
                                                  label: _vm.$t(
                                                    "forms.form-modal.archive-from-leadgen"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.formSubmission
                                                      .leadGenArchived,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.formSubmission,
                                                      "leadGenArchived",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "formSubmission.leadGenArchived"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.formSubmission.baseDiscovery
                            ? _c(
                                "v-col",
                                {
                                  attrs: { md: "6", cols: "12", align: "left" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mb-2 row-format" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "forms.meeting-modal.questionnaire"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm.fullResultLink
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "ml-auto font-14 font-primary"
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    color:
                                                      "var(--v-primary-base)"
                                                  },
                                                  attrs: {
                                                    target: "_blank",
                                                    href: _vm.fullResultLink
                                                  }
                                                },
                                                [_vm._v("View in form")]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "v-container",
                                    {
                                      staticClass: "pa-2 ma-0 font-14",
                                      staticStyle: {
                                        "background-color":
                                          "var(--v-gray_10-base)",
                                        "border-radius": "4px"
                                      }
                                    },
                                    [
                                      _vm.formSubmission.formData.answers.length
                                        ? _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "pa-4" },
                                                _vm._l(
                                                  _vm.formSubmission.formData
                                                    .answers,
                                                  function(answer, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "text-left font-14 pb-3"
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "font-12",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              answer.question
                                                            )
                                                          }
                                                        }),
                                                        answer.fieldType ===
                                                        "FileInput"
                                                          ? _c(
                                                              "div",
                                                              _vm._l(
                                                                JSON.parse(
                                                                  answer.answer
                                                                ),
                                                                function(
                                                                  fileName
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: fileName
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "font-primary pointer",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadFile(
                                                                                fileName
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                fileName
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "brand-medium",
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "pre-wrap"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    answer.answer
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "modal-footer gap-2 row-format centered" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "super-action",
                  staticStyle: { width: "160px" },
                  on: { click: _vm.saveFormSubmission }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
              ),
              _c(
                "v-btn",
                { attrs: { icon: "" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { size: "20" },
                      on: { click: _vm.deleteFormSubmission }
                    },
                    [_vm._v("delete")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }