<template>
	<div class="column-format text-left font-14 font-gray_70 gap-2">
		<div>Weight (px)</div>
		<v-slider
			v-model="element.weight"
			:min="1"
			:max="50"
			:step="1"
			hide-details
			dense
			@input="updated"
			thumb-label
		></v-slider>

		<div>Width (%)</div>
		<v-slider
			v-model="element.width"
			:min="1"
			:max="100"
			:step="1"
			hide-details
			dense
			@input="updated"
			thumb-label
		></v-slider>

		<div>Padding top</div>
		<v-slider v-model="element.pt" :min="0" :max="50" :step="1" hide-details dense @input="updated" thumb-label></v-slider>

		<div>Padding bottom</div>
		<v-slider v-model="element.pb" :min="0" :max="50" :step="1" hide-details dense @input="updated" thumb-label></v-slider>

		<div>Color</div>
		<color-selector v-model="element.color" @input="updated"></color-selector>
	</div>
</template>

<script>
	import ColorSelector from "@/components/ColorSelector";
	export default {
		name: 'DividerConfig',

		props: ['item'],

		components: {ColorSelector},

		data: function() {
			return {
				colorMenu: false,
				element: { ...this.item },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>