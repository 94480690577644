var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format flex-grow-1" },
    [
      _vm._t("filter"),
      _c("notes", {
        ref: "notes",
        staticClass: "mt-3",
        attrs: { client: _vm.client, filter: _vm.filter }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }