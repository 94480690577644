<template>
	<div class="column-format gap-5">
		<v-textarea
			v-model="element.question"
			rows="3"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Question"
			@input="updated"
		></v-textarea>
		<v-text-field
			v-model="element.placeholder"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Placeholder"
			@input="updated"
		></v-text-field>
		<v-text-field
			v-model="element.rows"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Rows"
			@input="updated"
		/>
		<v-switch v-model="element.required" hide-details dense label="Required" class="mt-n2" @change="updated"></v-switch>
		<v-text-field
				v-model="element.fieldName"
				hide-details
				dense
				persistent-placeholder
				outlined
				label="Mapping key"
				@input="updated"
		></v-text-field>
		<v-select v-model="element.schemaMapping" :items="mappings" item-text="label" item-value="value" hide-details dense outlined label="Mapping" @change="updated"></v-select>
	</div>
</template>

<script>
	import ConfigMixin from "@/modules/forms/config/ConfigMixin";

	export default {
		name: 'TextAreaConfig',

		props: ['item'],

		mixins: [ConfigMixin],

		components: {},

		data: function() {
			return {
				element: { ...this.item },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
