var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-format wrapper" }, [
    _vm.detailView
      ? _c(
          "div",
          {},
          [
            _c("detail-view", {
              key: "detail-" + _vm.detailView.id,
              attrs: {
                clientId: _vm.clientId,
                communication: _vm.detailView,
                "user-emails": _vm.userEmails,
                "email-boxes": _vm.emailBoxes,
                "snooze-options": _vm.snoozeOptions,
                folder: _vm.filter.folder,
                "in-modal": false
              },
              on: {
                back: _vm.clearDetailView,
                "email-thread-updated": function($event) {
                  return _vm.emailThreadUpdated($event)
                },
                "recent-call-updated": function($event) {
                  return _vm.recentCallUpdated($event)
                },
                "message-thread-updated": function($event) {
                  return _vm.messageThreadUpdated($event)
                },
                "view-communication": function($event) {
                  return _vm.setDetailView($event)
                }
              }
            })
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.detailView,
            expression: "!detailView"
          }
        ],
        staticClass: "column-format"
      },
      [
        _c(
          "div",
          { staticClass: "row-format align-center pb-4 flex-wrap gap-2" },
          [
            _c(
              "div",
              { staticClass: "row-format gap-2 flex-wrap" },
              [
                _c("inbox-filter", {
                  on: { input: _vm.onSearchChange },
                  model: {
                    value: _vm.rawSearch,
                    callback: function($$v) {
                      _vm.rawSearch = $$v
                    },
                    expression: "rawSearch"
                  }
                }),
                _c("generic-filter", {
                  attrs: {
                    filter: _vm.filter,
                    items: ["All", "Unread", "Read"],
                    property: "read"
                  }
                }),
                !_vm.clientId
                  ? _c("client-filter", { attrs: { filter: _vm.filter } })
                  : _vm._e(),
                _c("channel-filter", { attrs: { filter: _vm.filter } }),
                _c("generic-filter", {
                  attrs: {
                    filter: _vm.filter,
                    items: ["Inbox", "Snoozed", "Archived"],
                    property: "folder"
                  }
                }),
                _c("date-filter", {
                  on: { input: _vm.updateScope },
                  model: {
                    value: _vm.filter.dateSpecifier,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "dateSpecifier", $$v)
                    },
                    expression: "filter.dateSpecifier"
                  }
                }),
                _vm.filter.dateSpecifier === "between"
                  ? _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        _c("scope-date-selector", {
                          attrs: { date: _vm.filter.earliest },
                          on: {
                            change: function($event) {
                              return _vm.setEarliest($event)
                            }
                          }
                        }),
                        _c("div", { staticClass: "mx-1" }, [_vm._v("-")]),
                        _c("scope-date-selector", {
                          attrs: { date: _vm.filter.latest },
                          on: {
                            change: function($event) {
                              return _vm.setLatest($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ml-auto row-format align-center gap-2" },
              [
                _vm.totalPages > 0
                  ? _c("pagination", {
                      attrs: { "total-pages": _vm.totalPages },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  : _vm._e(),
                !_vm.clientId
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "super-action ml-auto",
                        on: {
                          click: function($event) {
                            return _vm.addNew()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("add")
                        ]),
                        _vm._v(" Email")
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            class: "inbox " + _vm.$store.getters.scroll + " brand-box",
            staticStyle: {
              "max-height": "calc(100vh - 200px)",
              overflow: "auto"
            }
          },
          [
            _c(
              "div",
              { key: _vm.refreshKey },
              [
                _vm._l(_vm.paginatedItems, function(comm) {
                  return _c(
                    "div",
                    { key: comm.id, staticClass: "communication py-0 px-0" },
                    [
                      _c(comm.type, {
                        tag: "component",
                        attrs: {
                          communication: comm,
                          clientId: _vm.clientId,
                          "email-boxes": _vm.emailBoxes,
                          subscription: _vm.subscription,
                          "user-emails": _vm.userEmails,
                          "snooze-options": _vm.snoozeOptions,
                          folder: _vm.filter.folder
                        },
                        on: {
                          click: function($event) {
                            return _vm.setDetailView(comm)
                          },
                          "email-thread-updated": function($event) {
                            return _vm.emailThreadUpdated($event)
                          },
                          "recent-call-updated": function($event) {
                            return _vm.recentCallUpdated($event)
                          },
                          "message-thread-updated": function($event) {
                            return _vm.messageThreadUpdated($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                !_vm.communications.length
                  ? _c("div", { staticClass: "my-12" }, [
                      _vm._v("No conversations.")
                    ])
                  : _vm._e()
              ],
              2
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }