var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initialized
    ? _c("div", { staticClass: "column-format fill-height" }, [
        _c("div", { staticClass: "row-format gap-3 flex-wrap" }, [
          _c("div", { staticClass: "kpi-box" }, [
            _c("div", { staticClass: "fit text-left" }, [
              _vm._v("Total invoiced")
            ]),
            _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
              _vm._v(
                _vm._s(
                  _vm.$formatters.dollars(
                    _vm.totalInvoiced,
                    true,
                    true,
                    _vm.currency
                  )
                )
              )
            ])
          ]),
          _c("div", { staticClass: "kpi-box" }, [
            _c("div", { staticClass: "fit text-left" }, [
              _vm._v("Total received")
            ]),
            _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
              _vm._v(
                _vm._s(
                  _vm.$formatters.dollars(
                    _vm.totalReceived,
                    true,
                    true,
                    _vm.currency
                  )
                )
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "flex-grow-1 column-format gap-4 my-4" }, [
          _c(
            "div",
            {
              staticClass: "row-format align-center",
              staticStyle: { "flex-wrap": "wrap" }
            },
            [
              _c(
                "div",
                { staticClass: "row-format align-center gap-2 flex-wrap" },
                [
                  _vm._t("filter"),
                  _vm.currencies.length > 1
                    ? _c("v-select", {
                        staticStyle: {
                          "max-width": "100px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          items: _vm.currencies,
                          dense: "",
                          "hide-details": "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.currency,
                          callback: function($$v) {
                            _vm.currency = $$v
                          },
                          expression: "currency"
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            ]
          )
        ]),
        _vm.invoices.length
          ? _c(
              "div",
              { staticStyle: { "background-color": "var(--v-white-base)" } },
              [
                _c("v-data-table", {
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.filteredInvoices,
                    "hide-default-footer": true,
                    "sort-by": "dateCreated",
                    "sort-desc": true,
                    "fixed-header": true,
                    "items-per-page": -1
                  },
                  on: {
                    "click:row": function($event) {
                      return _vm.openInvoice($event)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.dateCreated",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.dateCreated
                              ? _c("span", { staticClass: "brand-semilight" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.DateTime.fromISO(
                                        item.dateCreated
                                      ).toFormat("DD")
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.dateDue",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.dateDue
                              ? _c("span", { staticClass: "brand-semilight" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.DateTime.fromISO(
                                        item.dateDue
                                      ).toFormat("DD")
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.invoiceNumberFormatted",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", { staticClass: "brand-semilight" }, [
                              _vm._v(_vm._s(item.invoiceNumberFormatted))
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.total",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("div", { staticClass: "brand-semilight" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatters.dollars(
                                      item.amount ? item.amount : 0,
                                      true,
                                      true,
                                      item.currency
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            item.localAmount &&
                            item.currency !== _vm.$store.state.defaultCurrency
                              ? _c(
                                  "div",
                                  { staticClass: "font-12 font-gray_70" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$formatters.dollars(
                                            item.localAmount
                                              ? item.localAmount
                                              : 0,
                                            true,
                                            true,
                                            _vm.$store.state.defaultCurrency
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.status",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              { staticClass: "row-format align-center" },
                              [
                                _c("div", {
                                  staticClass: "brand-semilight font-12",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.getStatus(item))
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3629894614
                  )
                })
              ],
              1
            )
          : _vm._e(),
        !_vm.invoices.length
          ? _c(
              "div",
              {
                staticClass: "row-format centered mt-8",
                staticStyle: { flex: "1" }
              },
              [
                _c("empty-view", {
                  attrs: {
                    header: "No invoices yet",
                    body:
                      "You haven’t sent any invoices for " +
                      _vm.project.name +
                      " yet. Create an invoice and get paid for your work now.",
                    cta: "Send an invoice",
                    "video-header": "See how it works",
                    "video-body":
                      "Learn how to add the work you’ve done into an invoice. You can also see how to set up automatic invoices.",
                    "video-cta": "Watch the tutorial",
                    "video-id": "3dIDz5H2TaE"
                  },
                  on: {
                    "cta-clicked": function($event) {
                      return _vm.addNew()
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }