var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("keypad", {
        attrs: {
          subscription: _vm.subscription,
          "call-is-active": _vm.callIsActive
        },
        on: {
          "mute-call": _vm.toggleMute,
          "record-call": _vm.toggleRecord,
          dial: _vm.dial,
          hangup: function($event) {
            return _vm.hangup()
          },
          digit: _vm.digit
        }
      }),
      _vm.primary && _vm.subscription.provider === "Bandwidth"
        ? _c("bandwidth-soft-phone", {
            ref: "SoftPhone",
            on: {
              "call-active": _vm.callActive,
              "call-ringing": _vm.callRinging,
              "call-up": _vm.bandwidthCallUp,
              "phone-id": function($event) {
                _vm.phoneId = $event
              }
            }
          })
        : _vm._e(),
      _vm.primary && _vm.subscription.provider === "Twilio"
        ? _c("twilio-soft-phone", {
            ref: "SoftPhone",
            on: {
              "call-active": _vm.callActive,
              "call-ringing": _vm.callRinging,
              "call-up": _vm.twilioCallUp
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }