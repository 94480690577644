var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-left" }, [
    _c("input", { attrs: { type: "hidden", id: "invoice-token" } }),
    _c("div", { staticClass: "brand-medium" }, [_vm._v("Available Tokens")]),
    _c(
      "ul",
      _vm._l(_vm.tokens, function(token) {
        return _c(
          "li",
          {
            key: token,
            staticClass: "pointer",
            on: {
              click: function($event) {
                return _vm.copyToken(token)
              }
            }
          },
          [
            _vm._v("{{"),
            _c("span", { domProps: { innerHTML: _vm._s(token) } }),
            _vm._v("}}")
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }