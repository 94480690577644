import { render, staticRenderFns } from "./KanbanHeader.vue?vue&type=template&id=0db71548&scoped=true&"
import script from "./KanbanHeader.vue?vue&type=script&lang=js&"
export * from "./KanbanHeader.vue?vue&type=script&lang=js&"
import style0 from "./KanbanHeader.vue?vue&type=style&index=0&id=0db71548&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db71548",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src834000100/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0db71548')) {
      api.createRecord('0db71548', component.options)
    } else {
      api.reload('0db71548', component.options)
    }
    module.hot.accept("./KanbanHeader.vue?vue&type=template&id=0db71548&scoped=true&", function () {
      api.rerender('0db71548', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/projects/management/KanbanHeader.vue"
export default component.exports