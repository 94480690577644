<template>
	<div class="column-format gap-3">
		<font-selector label="Font" :font="lFont" @font-changed="fontUpdated($event)"></font-selector>
		<v-select
			v-model="element.fontSize"
			:items="[8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 45]"
			hide-details
			dense
			label="Font size"
			outlined
			@change="updated()"
		>
			<template v-slot:item="{ item }">{{ item }}px</template>
			<template v-slot:selection="{ item }">{{ item }}px</template>
		</v-select>
		<v-select
			v-model="element.inputStyle"
			:items="['outlined', 'underlined', 'solo']"
			hide-details
			dense
			outlined
			label="Input style"
			@change="updated()"
		></v-select>
		<v-select
			v-model="element.submitAlignment"
			:items="alignment"
			item-text="label"
			item-value="value"
			hide-details
			dense
			outlined
			label="Submit button alignment"
			@change="updated()"
		></v-select>
		<div class="row-format align-center">
			<icon-selector v-model="element.prevPageIcon" @input="updated"></icon-selector>
			<v-text-field
				v-model="element.prevPageText"
				hide-details
				dense
				persistent-placeholder
				outlined
				label="Previous page text"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format align-center">
			<icon-selector v-model="element.nextPageIcon" @input="updated"></icon-selector>
			<v-text-field
				v-model="element.nextPageText"
				hide-details
				dense
				persistent-placeholder
				outlined
				label="Next page text"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format align-center">
			<icon-selector v-model="element.submitIcon" @input="updated"></icon-selector>
			<v-text-field
				v-model="element.submitText"
				hide-details
				dense
				persistent-placeholder
				outlined
				label="Submit text"
				@input="updated"
			></v-text-field>
		</div>
		<!--div class="row-format align-center">
			<icon-selector v-model="element.draftIcon" @input="updated"></icon-selector>
			<v-text-field
				v-model="element.saveDraftText"
				:disabled="!element.enableDraftSave"
				hide-details
				dense
				persistent-placeholder
				outlined
				label="Save draft text"
				@input="updated"
			></v-text-field>
		</div-->
		<!--v-switch
			v-model="element.enableDraftSave"
			hide-details
			dense
			label="Enable draft save"
			class="mt-n2"
			@change="updated"
		></v-switch-->
		<div class="row-format gap-2">
			<div style="width: 49%" class="column-format">
				<div class="font-14 text-left font-gray_70">Form input color</div>
				<v-menu :close-on-click="false" :close-on-content-click="false" v-model="inputColorMenu" bottom>
					<template v-slot:activator="{ on }">
						<div
							v-on="on"
							:style="
								`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.formInputColor}`
							"
							class="pointer"
						>
							&nbsp;
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
						<v-color-picker
							mode="hexa"
							v-model="element.formInputColor"
							show-swatches
							swatches-max-height="150px"
							@input="updated()"
						></v-color-picker>
						<v-btn @click="inputColorMenu = false" block>OK</v-btn>
					</div>
				</v-menu>
			</div>
			<div style="width: 50%" class="column-format">
				<div class="font-14 text-left font-gray_70">Background color</div>
				<v-menu :close-on-click="false" :close-on-content-click="false" v-model="backgroundColorMenu" bottom>
					<template v-slot:activator="{ on }">
						<div
							v-on="on"
							:style="
								`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.backgroundColor}`
							"
							class="pointer"
						>
							&nbsp;
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
						<v-color-picker
							mode="hexa"
							v-model="element.backgroundColor"
							show-swatches
							swatches-max-height="150px"
							@input="updated()"
						></v-color-picker>
						<v-btn @click="backgroundColorMenu = false" block>OK</v-btn>
					</div>
				</v-menu>
			</div>
		</div>
		<div class="row-format gap-2 mt-n2">
			<div style="width: 49%" class="column-format">
				<div class="font-14 text-left ont-gray_70">Font color</div>
				<v-menu :close-on-click="false" :close-on-content-click="false" v-model="fontColorMenu" bottom>
					<template v-slot:activator="{ on }">
						<div
							v-on="on"
							:style="
								`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.fontColor}`
							"
							class="pointer"
						>
							&nbsp;
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
						<v-color-picker
							mode="hexa"
							v-model="element.fontColor"
							show-swatches
							swatches-max-height="150px"
							@input="updated()"
						></v-color-picker>
						<v-btn @click="fontColorMenu = false" block>OK</v-btn>
					</div>
				</v-menu>
			</div>
			<div style="width: 50%" class="column-format">
				<div class="font-14 text-left font-gray_70">Button color</div>
				<v-menu :close-on-click="false" :close-on-content-click="false" v-model="colorMenu" bottom>
					<template v-slot:activator="{ on }">
						<div
							v-on="on"
							:style="
								`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.buttonColor}`
							"
							class="pointer"
						>
							&nbsp;
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
						<v-color-picker
							mode="hexa"
							v-model="element.buttonColor"
							show-swatches
							swatches-max-height="150px"
							@input="colorUpdated"
						></v-color-picker>
						<v-btn @click="colorMenu = false" block>OK</v-btn>
					</div>
				</v-menu>
			</div>
		</div>
		<div class="row-format gap-2">
			<v-select
				style="width: 48%"
				v-model="element.buttonStyle"
				:items="['Normal', 'Block']"
				hide-details
				outlined
				dense
				label="Button style"
				@change="updated()"
			>
			</v-select>
			<v-select
				style="width: 48%;"
				v-model="element.buttonSize"
				:items="['X-Small', 'Small', 'Normal', 'Large', 'X-Large']"
				hide-details
				outlined
				dense
				label="Button size"
				@change="updated()"
			>
			</v-select>
		</div>

		<div class="font-14 text-left mb-n3 font-gray_70">Max width ({{ element.maxWidth }}px)</div>
		<v-slider
			v-model="element.maxWidth"
			:min="0"
			:max="1200"
			:step="10"
			hide-details
			dense
			@input="updated"
			thumb-label
		></v-slider>

		<simple-code-editor
			:display_language="true"
			:language_selector="true"
			:languages="[['css', 'CSS']]"
			:copy_code="false"
			style="width:100%;"
			:theme="$vuetify.theme.dark ? 'dark' : 'light'"
			font_size="14px"
			v-model="element.customCss"
			@input="updated()"
		></simple-code-editor>
	</div>
</template>

<script>
	import chroma from 'chroma-js';
	import IconSelector from '@/components/IconSelector';
	import FontSelector from '@/components/FontSelector';
	import SimpleCodeEditor from 'simple-code-editor';

	export default {
		name: 'FormStyleConfig',

		props: ['font', 'styles'],

		components: { IconSelector, FontSelector, SimpleCodeEditor },

		data: function() {
			return {
				lFont: this.font,
				colorMenu: false,
				fontColorMenu: false,
				backgroundColorMenu: false,
				inputColorMenu: false,
				element: { ...this.styles },
				alignment: [
					{ label: 'Left', value: 'LEFT' },
					{ label: 'Right', value: 'RIGHT' },
					{ label: 'Center', value: 'CENTER' },
					{ label: 'Justified', value: 'JUSTIFIED' },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			colorUpdated: function() {
				let value = chroma(this.element.buttonColor).get('lab.l');
				if (value < 70) {
					this.element.buttonTextColor = 'white';
				} else {
					this.element.buttonTextColor = 'black';
				}
				this.updated();
			},

			fontUpdated: function(font) {
				this.lFont = font;
				this.$emit('font-updated', this.lFont);
			},

			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
