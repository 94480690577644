<template>
	<notification-layout :id="id" :action-text="actionLabel" @action="openMeetingDetail()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small v-if="client" :client="client" class="mr-2" />
			<div class="text-left">{{ scheduledMeeting.meetingName }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium" :style="cancelled ? 'text-decoration:line-through': ''">{{
					DateTime.fromISO(scheduledMeeting.confirmedTime.start).toFormat("cccc") + ' ' + DateTime.fromISO(scheduledMeeting.confirmedTime.start).toLocaleString(DateTime.DATETIME_MED)
				}}</div>
			<div class="font-12" :style="cancelled ? 'text-decoration:line-through': ''">
				<span>{{ scheduledMeeting.formData.firstName }}  {{ scheduledMeeting.formData.lastName }}</span><br>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";
	import DateTime from "@/modules/utils/HDateTime";
	import MeetingDetail from "@/modules/meetings/MeetingDetail";
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'PaymentAppliedNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { NotificationLayout, ClientAvatar },

		data: function() {
			return {
				scheduledMeeting: this.notificationPayload.scheduledMeeting,
				rescheduled: this.notificationPayload.rescheduled,
				cancelled: this.notificationPayload.cancelled,
				reminder: this.notificationPayload.reminder,
				DateTime:DateTime
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openMeetingDetail: function() {
				this.$store.state.globalModalController.openModal(MeetingDetail, {id:this.scheduledMeeting.id});
			},
		},

		computed: {
			client: function(){
				if(this.scheduledMeeting.clientId){
					return this.$store.getters.getClientById(this.scheduledMeeting.clientId);
				}else{
					return null;
				}
			},

			actionLabel: function(){
				if(this.rescheduled){
					return 'Meeting rescheduled'
				}else if(this.cancelled) {
					return 'Meeting cancelled'
				}else if(this.reminder){
					return "Upcoming meeting reminder"
				}else{
					return 'Meeting scheduled!'
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
