<template>
	<LineChartGenerator
		:key="refreshKey"
		:chart-id="refreshKey"
		:chart-options="chartOptions"
		:chart-data="chartData"
		:height="height"
	/>
</template>

<script>
	import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
	import MetricService from '@/modules/reports/metrics/MetricService';
	import moment from 'moment';
	import DateTime from '@/modules/utils/HDateTime';
	import { v4 as uuid4 } from 'uuid';

	export default {
		name: 'InvoicingChart',

		props: ['client', 'currency'],

		components: { LineChartGenerator },

		data: function () {
			return {
				isReady: false,
				metricService: new MetricService(),
				rawData: [],
				height: 150,
				width: 300,
				refreshKey: uuid4(),

				chartOptions: {
					responsive: true,
					maintainAspectRatio: false,

					scales: {
						y: {
							ticks: {
								display: false,
							},
							grid: {
								display: false,
								drawBorder: false
							},
						},
						x: {
							grid: {
								display: false,
							},
							ticks: {
								display: false,
								autoSkip: true,
								maxTicksLimit: 30,
								maxRotation: 0,
								minRotation: 0,
							},
						},
					},
				},
			};
		},

		mounted() {
			this.getRevenueMetrics();
		},

		methods: {
			getRevenueMetrics: function () {
				this.metricService
					.getRevenueMetricsByClient(this.client.id, this.currency)
					.then((res) => {
						this.rawData.splice(0, this.rawData.length);
						this.rawData.push(...res.data);
						this.isReady = true;
						console.log(this.rawData);
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},

		watch: {
			currency: function () {
				this.getRevenueMetrics();
			},
			chartData: function () {
				this.refreshKey = uuid4();
			},
			totalInvoiced: function(val){
				this.$emit('total-invoiced',val);
			},

			totalReceived: function(val){
				this.$emit('total-received',val);
			}
		},

		computed: {
			chartData: function () {
				return {
					labels: this.labels,
					datasets: [
						{
							fill: true,
							lineTension: 0.3,
							label: 'Invoiced',
							backgroundColor: '#7DAFED',
							data: this.invoiced,
							tooltip: {
								callbacks: {
									label: (context) => {
										return this.$formatters.dollars(context.raw, false, true, this.currency);
									},
								},
							},
						},
					],
				};
			},

			labels: function () {
				let labels = [];
				this.rawData.forEach((d) => labels.push(DateTime.fromISO(d.weekEnding).toFormat('MMM yy')));
				return labels;
			},

			totalInvoiced: function () {
				return this.rawData.reduce((sum, { invoiced }) => sum + invoiced, 0);
			},

			totalReceived: function () {
				return this.rawData.reduce((sum, { received }) => sum + received, 0);
			},

			invoiced: function () {
				let invoiced = [];
				let today = moment().endOf('month').add(1, 'day');

				this.rawData.forEach((d) => {
					if (moment(d.weekEnding).isAfter(today)) {
						invoiced.push(0);
					} else {
						invoiced.push(d.received);
					}
				});
				return invoiced;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
