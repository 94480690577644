var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.proposal
    ? _c(
        "div",
        {
          staticClass: "row-format gap-2 font-14 pointer",
          on: { click: _vm.openProposal }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { content: "" + _vm.proposal.proposalType },
                  expression: "{content: `${proposal.proposalType}`}"
                }
              ],
              staticClass: "material-symbols-rounded",
              attrs: { color: "secondary", size: "20" }
            },
            [_vm._v("handshake")]
          ),
          _c("div", [
            _vm._v(
              _vm._s(_vm.proposal.coverPage.heading) +
                " | " +
                _vm._s(_vm.proposal.proposalType) +
                " | " +
                _vm._s(_vm.proposal.proposalStatus)
            )
          ]),
          _c("div", { staticClass: "ml-auto font-gray_80 font-12 nowrap" }, [
            _vm._v(_vm._s(_vm._f("formatForTimeAgo")(_vm.item.timestamp)))
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }