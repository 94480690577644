export default {
	light: {
		primary: '#5C00E2' ,
		secondary: '#C90360' ,
		accent: '#E7BD5B' ,
		anchor: '#867F77',
		success: '#2ECC40',
		error: '#C90360',
		warning: '#FFDC00',
		info: '#0074D9',

		modal_overlay: '#828a92',
		sidebar: '#ebedef',
		pop: '#E7BD5B',

		success_10: '#D6F3E2',
		success_50: '#8fbc8f',

		warning_10: '#FFE1BE',
		warning_50: '#E86825',

		alert_10: '#F9D3D4',
		alert_50: '#DE1C22',

		black: '#000000' /* Black */,
		white: '#FFFFFF' /* White */,

		yellow_10: '#FFFCC9',
		// gray_10: '#f8f9fa',
		// gray_20: '#ebedef' ,
		// gray_30: '#cfd3d7' ,
		// gray_50: '#bec4ca' ,
		// gray_60: '#8893a0' ,
		// gray_70: '#545f6d' ,
		// gray_80: '#434b56',
		// gray_90: '#2b313b' ,

		// gray_5: '#FAFAFB',
		// gray_10: '#F5F5F5',
		// gray_20: '#E0E0E0',
		// gray_30: '#CCCCCC',
		// gray_40: '#B3B3B3',
		// gray_50: '#999999',
		// gray_60: '#808080',
		// gray_70: '#666666',
		// gray_80: '#4D4D4D',
		// gray_90: '#000000',
		// gray_100: '#1A1A1A',

		gray_5: '#f6f8fa',
		gray_10: '#ebeef1',
		gray_20: '#c0c8d2',
		gray_30: '#c0c8d2',
		gray_40: '#87909f',
		gray_50: '#687385',
		gray_60: '#545969',
		gray_70: '#414552',
		gray_80: '#30313d',
		gray_90: '#1a1b25',
		gray_100: '#1A1A1A',

		//Stripe Grays
		// gray_5: '#f6f8fa',
		// gray_10: '#ebeef1',
		// gray_20: '#c0c8d2',
		// gray_30: '#a3acba',
		// gray_40: '#87909f',
		// gray_50: '#687385',
		// gray_60: '#545969',
		// gray_70: '#414552',
		// gray_80: '#30313d',
		// gray_90: '#1a1b25',
		// gray_100: '#1A1A1A',


		blueish: '#B995F4',
		blue_90: '#5C00E2',
		blue_70: '#6c1ae5',
		blue_50: '#8d4deb',
		blue_40: '#9d66ee',
		blue_30: '#ae80f1',
		blue_20: '#be99f3',
		blue_10: '#ceb3f6',
		blue_5: '#deccf9',
		blue_0: '#efe6fc',

		green_90: '#00763D',
		green_50: '#00A861',
		green_30: '#67D5AD',
		green_10: '#A9E6CD',
		green_0: '#e6f1ec',

		orange_90: '#EO822B',
		orange_50: '#E7A73A',
		orange_30: '#EBBD43',
		orange_10: '#FDED81',

		brightblue_90: '#2573B7',
		brightblue_50: '#2C97DF',
		brightblue_30: '#3DB2F1',
		brightblue_10: '#85D2F8',
		brightblue_0: '#d3e3f1',

		// gray: '#10110F',
		bali_hai: '#8EA3B8',
		sundown: '#FFA5B6',
		heliothrope: '#CE62E9',
		cornflower: '#7950F2',
		dodger: '#4F72FF',
		turquoise: '#3BDBBE',
		emerald: '#51CF66',
		sunglow: '#FEBB37',
		pumpkin: '#FD7E14',
		carnation: '#FA5252'
	},
	dark: {
		primary: '#B995F4' ,
		secondary: '#f8f9fa' ,
		accent: '#E7BD5B' ,
		anchor: '#867F77',
		success: '#00703c',
		error: '#DE1C22',
		warning: '#E86825',
		info: '#175AA8',

		success_10: '#003522',
		success_50: '#66B69A',

		warning_10: '#461F0B',
		warning_50: '#ED8651',

		alert_10: '#43080A',
		alert_50: '#E86064',

		modal_overlay: '#2F2E2C',
		sidebar: '#10110F',
		pop: '#E7BD5B',

		// Inverted from light theme
		black: '#FFFFFF' /* Black */,
		white: '#181818' /* White */,
		// grey: '#D6D1CB' /* Gray 60 */,

		// Inverted from light theme
		yellow_10: '#403F32',
		gray_90: '#f8f9fa',
		gray_80: '#ebedef' ,
		gray_70: '#cfd3d7' ,
		gray_60: '#bec4ca' ,
		gray_50: '#97a1ab' ,
		gray_30: '#828a92' ,
		gray_20: '#3c3f44',
		gray_10: '#10110F' ,
		gray_5: '#080908',

		blue_0: '#5C00E2',
		blue_5: '#6c1ae5',
		blue_10: '#8d4deb',
		blue_20: '#9d66ee',
		blue_30: '#ae80f1',
		blue_40: '#be99f3',
		blue_50: '#ceb3f6',
		blue_70: '#deccf9',
		blue_90: '#efe6fc',

		green_0: '#00763D',
		green_10: '#00763D',
		green_30: '#00A861',
		green_50: '#67D5AD',
		green_90: '#A9E6CD',

		orange_10: '#EO822B',
		orange_30: '#E7A73A',
		orange_50: '#EBBD43',
		orange_90: '#FDED81',

		brightblue_0: '#2573B7',
		brightblue_10: '#2573B7',
		brightblue_30: '#2C97DF',
		brightblue_50: '#3DB2F1',
		brightblue_90: '#85D2F8',

		// gray: '#F9F8F6' /* gray 90 */,
		bali_hai: '#9FB2C5',
		sundown: '#F06595',
		heliothrope: '#CE62E9',
		cornflower: '#9775FA',
		dodger: '#748FFC',
		turquoise: '#099268',
		emerald: '#5C940D',
		sunglow: '#F59F00',
		pumpkin: '#E8590C',
		carnation: '#FF8787'
	},
};
