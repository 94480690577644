var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    style: "height: " + _vm.height + "px; min-height: " + _vm.height + "px",
    on: {
      click: function($event) {
        return _vm.$emit("click", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }