var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row-format pt-4 pb-2 px-3 mb-3 gap-3" },
        [
          _c(
            "div",
            {
              class:
                "page-link " + (_vm.tab === "ToDos" ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  return _vm.selectTab("ToDos")
                }
              }
            },
            [_c("div", [_vm._v("To-dos")])]
          ),
          _c(
            "div",
            {
              class:
                "page-link " + (_vm.tab === "Notes" ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  return _vm.selectTab("Notes")
                }
              }
            },
            [_c("div", [_vm._v("Notes")])]
          ),
          _c(
            "div",
            {
              class:
                "page-link " +
                (_vm.tab === "Schedule" ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  return _vm.selectTab("Schedule")
                }
              }
            },
            [_c("div", [_vm._v("Schedule")])]
          ),
          _vm.tab !== "Schedule"
            ? _c(
                "v-icon",
                {
                  staticClass: "ml-auto",
                  attrs: { size: "26", color: "secondary" },
                  on: { click: _vm.handleAdd }
                },
                [_vm._v("add_box")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("to-do", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab === "ToDos",
            expression: "tab === 'ToDos'"
          }
        ],
        ref: "ToDos",
        attrs: { "manage-events": true }
      }),
      _c("user-notes", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab === "Notes",
            expression: "tab === 'Notes'"
          }
        ],
        ref: "Notes",
        attrs: { "manage-events": true }
      }),
      _c("day-calendar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab === "Schedule",
            expression: "tab === 'Schedule'"
          }
        ],
        ref: "Schedule",
        staticStyle: { "text-align": "left!important" },
        attrs: { "right-bar": true }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }