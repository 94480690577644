<template>
	<div>
		<div>Columns</div>
		<v-slider
				v-model="element.columnCount"
				:min="1"
				:max="3"
				:step="1"
				hide-details
				dense
				show-ticks="always"
				tick-size="4"
				:tick-labels="['1','2','3','4']"
				@input="updated"
				thumb-label
		></v-slider>

		<div class="mt-4 column-format gap-4 text-left">
			<div class="px-4 pt-2" v-for="index in element.columnCount" :key="index" style="border: 1px solid var(--v-gray_30-base); border-radius: 4px">
				<span class="font-14 brand-medium">Column {{index}}</span>
				<div class="row-format gap-1 mb-n3">
					<v-text-field type="number" min="0" max="20" v-model="element.columns[index-1].pl" label="Padding left"></v-text-field>
					<v-text-field type="number" min="0" max="20" v-model="element.columns[index-1].pr" label="Padding right"></v-text-field>
				</div>
				<div class="row-format gap-1 mb-n2">
					<v-text-field type="number" min="0" max="20" v-model="element.columns[index-1].pt" label="Padding top"></v-text-field>
					<v-text-field type="number" min="0" max="20" v-model="element.columns[index-1].pb" label="Padding bottom"></v-text-field>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Container",

	props: ['item'],

	components: {},

	data: function () {
		return {
			element: {... this.item}
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		updated: function() {
			for(let i=0; i <= this.element.columnCount; i++){
				if(this.element.columns[i] === undefined){
					this.element.columns.splice(i,0,{pt: 0, pb: 0, pr: 0, pl: 0, items: []});
				}
			}
			this.$emit('change', this.element);
		},
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>