var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-left" }, [
    _c("div", { staticClass: "font-16" }, [_vm._v("Approved client domains")]),
    _c("div", { staticClass: "font-12 font-gray_70" }, [
      _vm._v(
        "In addition to any known Contact email addresses, Moxie will sync all emails and accept tickets to or from any of the domains listed below."
      )
    ]),
    _c(
      "div",
      { staticClass: "row-format gap-2 mt-2" },
      [
        _c("v-text-field", {
          attrs: {
            "hide-details": "",
            dense: "",
            outlined: "",
            placeholder: "domain.com"
          },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.addDomain()
            }
          },
          model: {
            value: _vm.newDomain,
            callback: function($$v) {
              _vm.newDomain = $$v
            },
            expression: "newDomain"
          }
        }),
        _c(
          "v-btn",
          {
            staticClass: "super-action",
            staticStyle: { height: "40px!important" },
            attrs: { disabled: !_vm.newDomain },
            on: {
              click: function($event) {
                return _vm.addDomain()
              }
            }
          },
          [_vm._v("Add domain")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-4 font-14" },
      _vm._l(_vm.syncDomains.domains, function(domain, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "row-format gap-1 py-3",
            staticStyle: { "border-bottom": "1px solid var(--v-gray_30-base)" }
          },
          [
            _c("div", [_vm._v(_vm._s(domain))]),
            _c(
              "v-icon",
              {
                staticClass: "pointer material-symbols-rounded ml-auto",
                on: {
                  click: function($event) {
                    return _vm.deleteDomain(index)
                  }
                }
              },
              [_vm._v("delete")]
            )
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }