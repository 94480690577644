var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.user
        ? _c(
            "v-container",
            { staticStyle: { "max-width": "800px" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "row-format mb-4" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "ml-auto super-action",
                            attrs: { width: "120", disabled: !_vm.userUpdate },
                            on: { click: _vm.saveUser }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 mb-0 column-format centered",
                      attrs: { cols: "3", align: "center" }
                    },
                    [
                      !_vm.user.profilePicture && !_vm.userUpdate
                        ? _c(
                            "vue-dropzone",
                            {
                              ref: "userFileDropzone",
                              attrs: {
                                id: "userDropzone",
                                "use-custom-slot": true,
                                options: _vm.userDropzoneOptions
                              },
                              on: {
                                "vdropzone-success": _vm.userUploadSuccess,
                                "vdropzone-error": _vm.userUploadFailure,
                                "vdropzone-sending": _vm.addFileUploadAuthHeader
                              }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("v-icon", [
                                    _vm._v("$vuetify.icons.upload")
                                  ]),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "ml-2",
                                      staticStyle: { "font-size": "0.8em" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("account.profile-upload"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.user.profilePicture && _vm.userUpdate
                        ? _c("div", { staticClass: "column-format centered" }, [
                            _c(
                              "div",
                              [
                                _c("v-icon", { attrs: { color: "grey" } }, [
                                  _vm._v("$warning")
                                ]),
                                _c("br"),
                                _c(
                                  "span",
                                  {
                                    staticClass: "ml-2",
                                    staticStyle: {
                                      "font-size": "0.8em",
                                      color: "var(--v-gray_70-base)"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "Please save changes to upload your profile picture"
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.user.profilePicture
                        ? _c(
                            "div",
                            {
                              staticClass: "profilePic",
                              staticStyle: { position: "relative" }
                            },
                            [
                              _c("v-avatar", { attrs: { size: "100" } }, [
                                _c("img", {
                                  attrs: { src: _vm.user.profilePicture }
                                })
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "logoDelete delete",
                                  staticStyle: {
                                    position: "absolute",
                                    top: "35%",
                                    left: "35%"
                                  },
                                  attrs: { icon: "" },
                                  on: { click: _vm.deleteProfilePicture }
                                },
                                [_c("v-icon", [_vm._v("$delete")])],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 mb-0",
                      attrs: { cols: "9", align: "left" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "h-outline mb-3",
                        attrs: {
                          label: "First name",
                          "hide-details": "",
                          "persistent-placeholder": ""
                        },
                        on: { input: _vm.userChange },
                        model: {
                          value: _vm.user.firstName,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "firstName", $$v)
                          },
                          expression: "user.firstName"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          label: "Last name",
                          "hide-details": "",
                          "persistent-placeholder": ""
                        },
                        on: { input: _vm.userChange },
                        model: {
                          value: _vm.user.lastName,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "lastName", $$v)
                          },
                          expression: "user.lastName"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticStyle: { position: "relative" } },
                      [
                        _c("v-text-field", {
                          staticClass: "h-outline mb-4",
                          attrs: {
                            label: "Email",
                            disabled: _vm.emailLocked,
                            "hide-details": "",
                            "persistent-placeholder": ""
                          },
                          on: { input: _vm.userChange },
                          model: {
                            value: _vm.toEmail,
                            callback: function($$v) {
                              _vm.toEmail = $$v
                            },
                            expression: "toEmail"
                          }
                        }),
                        _c(
                          "v-icon",
                          {
                            staticClass: "pointer",
                            staticStyle: {
                              position: "absolute",
                              right: "5px",
                              bottom: "5px"
                            },
                            on: {
                              click: function($event) {
                                _vm.emailLocked = !_vm.emailLocked
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.emailLocked ? "lock" : "lock_open")
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row-format centered gap-2" }, [
                      _c(
                        "div",
                        { staticStyle: { "flex-grow": "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { height: "46px !important" },
                              attrs: {
                                block: "",
                                outlined: "",
                                color: "primary"
                              },
                              on: { click: _vm.updatePasswordClicked }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.user.passwordEnabled
                                    ? "Update password"
                                    : "Enable password sign-in"
                                ) + " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.user.passwordEnabled
                        ? _c(
                            "div",
                            { staticStyle: { "flex-grow": "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { height: "46px !important" },
                                  attrs: {
                                    outlined: "",
                                    block: "",
                                    color: "primary"
                                  },
                                  on: { click: _vm.confirmDisablePassword }
                                },
                                [_vm._v("Disable password sign-in")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      { staticClass: "text-left mt-2" },
                      [
                        _c("span", { staticClass: "font-14 font-gray_70" }, [
                          _vm._v(
                            "Enable Two Factor Auth (used with password login):"
                          )
                        ]),
                        _c("phone-number-input", {
                          attrs: {
                            "no-use-browser-locale": "",
                            "only-countries": [
                              "US",
                              "CA",
                              "GB",
                              "IE",
                              "MX",
                              "JM",
                              "PR",
                              "AR",
                              "IN",
                              "PH",
                              "FR",
                              "DE",
                              "HU",
                              "IT",
                              "PL",
                              "PT",
                              "ES",
                              "SE",
                              "CH",
                              "UA",
                              "ZA",
                              "AU",
                              "NZ",
                              "LT"
                            ],
                            clearable: true,
                            color: _vm.$vuetify.theme.dark
                              ? "#56524E"
                              : "#D6D1CB",
                            "valid-color": _vm.$vuetify.theme.dark
                              ? "#56524E"
                              : "#D6D1CB",
                            size: "lg",
                            "dark-color": "#181818",
                            dark: _vm.$vuetify.theme.dark
                          },
                          on: { update: _vm.phoneUpdated },
                          model: {
                            value: _vm.phone,
                            callback: function($$v) {
                              _vm.phone = $$v
                            },
                            expression: "phone"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "grey--text mt-3",
                            staticStyle: { "font-size": "14px" }
                          },
                          [
                            _vm.phone
                              ? _c("span", [
                                  _vm.user.phoneVerified
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [_vm._v("2FA Enabled")]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass: "pointer",
                                          staticStyle: { color: "red" },
                                          on: {
                                            click: function($event) {
                                              _vm.verificationModal = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v("Not Verified "),
                                          !_vm.showVerificationPrompt
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-9",
                                                  attrs: {
                                                    "x-small": "",
                                                    text: "",
                                                    outlined: ""
                                                  },
                                                  on: { click: _vm.sendNewCode }
                                                },
                                                [_vm._v("Send new code?")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c("div", { staticClass: "small-label mt-3" }, [
                          _vm._v(
                            " Providing a verified SMS enabled phone number will enable 2FA (Two Factor Authentication) feature of Moxie. By adding your phone number, you are giving Moxie permission to send you text messages for the sole purpose of authentication. "
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.verificationModal
        ? _c(
            "modal",
            {
              attrs: {
                dialog: _vm.verificationModal,
                "max-width": 550,
                persistent: true,
                "close-on-escape": false
              },
              on: {
                close: function($event) {
                  _vm.verificationModal = false
                }
              }
            },
            [
              _c("phone-verification", {
                attrs: { phone: _vm.phone },
                on: {
                  verified: _vm.phoneVerified,
                  close: function($event) {
                    _vm.verificationModal = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.emailVerificationModal
        ? _c(
            "modal",
            {
              attrs: {
                dialog: _vm.emailVerificationModal,
                "max-width": 550,
                persistent: true,
                "close-on-escape": false
              },
              on: {
                close: function($event) {
                  _vm.emailVerificationModal = false
                }
              }
            },
            [
              _c("email-update-verification", {
                attrs: { email: _vm.user.email },
                on: {
                  verified: _vm.emailUpdated,
                  close: function($event) {
                    _vm.emailVerificationModal = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }