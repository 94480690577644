var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.children.length
        ? _c(
            "v-menu",
            {
              attrs: {
                "offset-x": "",
                "content-class": "workspace-sub-menu",
                "close-on-content-click": _vm.closeOnContentClick
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass:
                                "settings-link row-format align-center pointer"
                            },
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-symbols-rounded",
                                attrs: { color: "black", size: "16" }
                              },
                              [_vm._v(_vm._s(_vm.icon))]
                            ),
                            _c("div", { staticClass: "ml-1" }, [
                              _vm._v(_vm._s(_vm.label))
                            ]),
                            _vm.children.length
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-auto",
                                    attrs: { small: "" }
                                  },
                                  [_vm._v("$chevronRight")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                999777548
              )
            },
            [
              _c(
                "div",
                { staticClass: "column-format" },
                _vm._l(_vm.children, function(child) {
                  return _c("settings-link", {
                    key: child.route,
                    attrs: {
                      label: child.label,
                      route: child.route,
                      icon: child.icon,
                      "select-value": child.selectValue
                    },
                    on: {
                      selected: function($event) {
                        return _vm.$emit("selected", $event)
                      }
                    }
                  })
                }),
                1
              )
            ]
          )
        : _c(
            "div",
            {
              staticClass: "settings-link row-format align-center pointer",
              on: { click: _vm.handleClick }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded",
                  attrs: { size: "16", color: "black" }
                },
                [_vm._v(_vm._s(_vm.icon))]
              ),
              _c("div", { staticClass: "ml-1 nowrap" }, [
                _vm._v(_vm._s(_vm.label))
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }