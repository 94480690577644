<template>
	<div class="text-left">
		<div class="font-16">Approved client domains</div>
		<div class="font-12 font-gray_70">In addition to any known Contact email addresses, Moxie will sync all emails and accept tickets to or from any of the domains listed below.</div>
		<div class="row-format gap-2 mt-2">
			<v-text-field hide-details dense outlined placeholder="domain.com" v-model="newDomain" @keydown.enter="addDomain()"></v-text-field>
			<v-btn class="super-action" style="height: 40px!important;" :disabled="!newDomain" @click="addDomain()">Add domain</v-btn>
		</div>
		<div class="mt-4 font-14">
			<div v-for="(domain,index) in syncDomains.domains" :key="index" class="row-format gap-1 py-3" style="border-bottom: 1px solid var(--v-gray_30-base)">
				<div>{{domain}}</div>
				<v-icon class="pointer material-symbols-rounded ml-auto" @click="deleteDomain(index)">delete</v-icon>
			</div>
		</div>
	</div>
</template>

<script>
	import SyncDomainsService from '@/modules/communicator/settings/SyncDomainsService';

	export default {
		name: 'SyncDomainsConfig',

		props: [],

		components: {},

		data: function() {
			return {
				newDomain: null,
				syncDomains: null,
				syncDomainsService: new SyncDomainsService(),
			};
		},

		mounted() {
			this.syncDomainsService.getSyncDomains().then((res) => {
				this.syncDomains = res.data;
			});
		},

		beforeDestroy() {},

		methods: {
			deleteDomain: function(index){
				this.syncDomains.domains.splice(index,1);
				this.updateSyncDomains();
			},

			addDomain: function(){
				this.syncDomains.domains.unshift(this.newDomain.toLowerCase());
				this.newDomain = null;
				this.updateSyncDomains();
			},

			updateSyncDomains: function(){
				this.syncDomainsService.updateSyncDomains(this.syncDomains);
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>