var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        {
          staticClass: "column-format py-1 pl-2 text-left font-gray_90",
          style: _vm.style
        },
        [
          _c(
            "div",
            { staticClass: "row-format align-center" },
            [
              _vm.client
                ? _c("client-avatar", {
                    attrs: {
                      "disable-click": "",
                      "x-small": "",
                      client: _vm.client
                    }
                  })
                : _c(
                    "div",
                    { class: "stopwatch row-format centered" },
                    [
                      _c("v-icon", { attrs: { size: "16", color: "white" } }, [
                        _vm._v("$stopwatch")
                      ])
                    ],
                    1
                  ),
              _c("div", { staticClass: "ml-1" }, [
                _vm._v(_vm._s(_vm.startLabel) + " - " + _vm._s(_vm.endLabel))
              ])
            ],
            1
          ),
          _vm.timerEvent.projectName
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { size: "14" } }, [
                    _vm._v("$projects")
                  ]),
                  _vm._v(_vm._s(_vm.timerEvent.projectName) + " ")
                ],
                1
              )
            : _vm._e(),
          _vm.timerEvent.deliverableName
            ? _c(
                "div",
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { size: "14" } }, [
                    _vm._v("$deliverableBox")
                  ]),
                  _vm._v(_vm._s(_vm.timerEvent.deliverableName) + " ")
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }