import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class CommunicatorService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
	}

	getSubscriptionsForAccount() {
		return this.httpClient
			.get('/subscriptions')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEntitlementStatus() {
		return this.httpClient
			.get('/subscriptions/entitlement')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSubscription() {
		return this.httpClient
			.get('/subscription')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updatePreferences(subscriptionId,preferences) {
		return this.httpClient
			.put(`/subscription/${subscriptionId}/preferences`,preferences)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	cancelSubscription(subscriptionId) {
		return this.httpClient
			.delete(`/subscription/${subscriptionId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getRecentCommunications(email,phone,maxRecords= 10){
		let params = {
			email:email,
			phone:phone,
			maxRecords:maxRecords
		}
		return this.httpClient
			.get('/recentCommunications',{params: params})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getOutgoingGreeting(subscriptionId) {
		return this.httpClient
			.get(`/subscription/${subscriptionId}/outgoingGreeting`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteOutgoingGreeting(subscriptionId) {
		return this.httpClient
			.delete(`/subscription/${subscriptionId}/outgoingGreeting`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	uploadOutgoingGreeting(subscriptionId,audio){
		let form = new FormData();
		form.append('media',audio);
		return this.httpClient
			.post(`/subscription/${subscriptionId}/outgoingGreeting`,form,{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
