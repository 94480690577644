<template>
	<div id="communicator-channel-filter">
		<v-menu
			max-height="90vh"
			max-width="250"
			v-model="showMenu"
			:close-on-content-click="false"
			:close-on-click="true"
			id="channel-filter"
			content-class="filter-menu-class"
			style="overflow-y: auto"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" style="min-height: 40px; background-color: var(--v-white-base)" elevation="0" outlined color="gray_30">
					<span :class="`font-14 font-gray_60 brand-medium`">{{selected}}</span>
					<v-icon x-small class="mx-1" color="gray_80">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="add-new-dropdown">
				<div v-for="(item,index) in dateList" :key="index" class="add-new-item" @click="update(item)">{{item.label}}</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'DateFilter',

		props: ['value'],

		components: {  },

		data: function() {
			return {
				dateFilter: this.value,
				showMenu: false,
				dateList: [
					{label: 'Today', value: 'today'},
					{label: 'This week', value: 'this-week'},
					{label: 'Last week', value: 'last-week'},
					{label: 'This month', value: 'month-to-date'},
					{label: 'Last month', value: 'last-month'},
					{label: 'Custom dates', value: 'between'}
				]
			};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {
			update: function(newValue){
				this.showMenu = false;
				this.dateFilter = newValue.value;
				this.$emit('input',this.dateFilter);
			}
		},

		watch: {
			value: function(newVal){
				this.dateFilter = newVal;
			}
		},

		computed: {
			selected: function(){
				let result = this.dateList.find(d => d.value === this.dateFilter);
				if(result){
					return result.label;
				}else{
					return '';
				}
			}
		},
	};
</script>

<style lang="scss">



	.open-menu {
		background-color: var(--v-white-base);
		max-width: 250px;
		.client-label .tree-label {
			text-overflow: ellipsis !important;
			overflow: hidden;
			white-space: nowrap;
			max-width: 160px;
		}
	}

	.status-color {
		width: 20px;
		height: 20px;
		border-radius: 4px;
		background-color: var(--status-color);
	}
</style>
