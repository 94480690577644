var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: "width: 100%; text-align: " + _vm.item.alignment + ";",
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _vm.item.url
        ? _c("img", {
            style: _vm.imageStyle,
            attrs: { alt: "image", src: _vm.item.url }
          })
        : _c(
            "div",
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded",
                  attrs: { size: "100" }
                },
                [_vm._v("image")]
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }