<template>
	<div id="write-off-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h2 class="modal-title">{{ $t('invoice.write-off-modal.heading', { invoiceNumber: invoice.invoiceNumber }) }}</h2>
			<p class="modal-subtitle">{{ $t('invoice.write-off-modal.subheading', { invoiceNumber: invoice.invoiceNumber }) }}</p>

			<div class="total">
				{{ $formatters.dollars(invoice.amountDue, true, true, invoice.currency) }}
			</div>

			<div v-if="client" class="client-name">
				<client-avatar :client="client" left></client-avatar>
				<div>{{ client.name }}</div>
			</div>

			<div class="font-14 text-left brand-semilight my-4">
				{{ $t('invoice.write-off-modal.body') }}
			</div>

			<div class="h-outline pa-2 mb-4 font-14 text-left brand-semilight d-flex justify-start align-center">
				<v-simple-checkbox v-model="checkboxConfirmed"></v-simple-checkbox>
				<span>{{ $t('invoice.write-off-modal.checkbox-label') }}</span>
			</div>

			<v-textarea
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="userNote"
				:rows="3"
				:label="$t('invoice.write-off-modal.note-label')"
				:placeholder="$t('invoice.write-off-modal.note-placeholder')"
			></v-textarea>
		</div>
		<div class="modal-footer">
			<v-btn class="px-16 primary-action" @click="handleSave" :disabled="!checkboxConfirmed">{{
				$t('invoice.write-off-modal.save-button')
			}}</v-btn>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import ClientService from '@/modules/clients/ClientService';

	export default {
		name: 'WriteOffModal',

		components: { ClientAvatar },
		props: { invoice: { type: Object, required: true } },

		data: function() {
			return {
				userNote: '',
				clientService: new ClientService(),
				client: null,
				checkboxConfirmed: false,
			};
		},

		mounted() {
			this.getClient();
			console.log('write off', this.invoice);
		},

		beforeDestroy() {},

		methods: {
			handleSave() {
				this.$emit('save', this.userNote);
			},

			getClient() {
				this.clientService
					.getClientDetail(this.invoice.clientId)
					.then((res) => {
						this.client = res.data;
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},
	};
</script>

<style lang="scss">
	#write-off-modal {
		background-color: var(--v-white-base);
		max-width: 400px;
		min-width: 320px;
		.total {
			margin: 0 0 12px;
			font-size: 56px;
			color: var(--v-gray_70-base);
		}
		.client-name {
			display: flex;
			justify-content: center;
			margin-bottom: 16px;
			div {
				align-self: center;
				white-space: nowrap;
				flex: 0 0 auto;
			}
		}
	}
</style>
