var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "panel-modal", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("result")
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c("div", { staticClass: "brand-medium font-18" }, [
                  _vm._v(_vm._s(_vm.$t("recurring.deliverables.heading")))
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _vm.isReady
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "primary-action",
                            attrs: { disabled: !_vm.deliverable.name },
                            on: { click: _vm.saveRecurringDeliverable }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("global.save-changes")) + " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "delete ml-2",
                        attrs: { icon: "" },
                        on: { click: _vm.deleteRecurringDeliverable }
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("$delete")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "panel-modal-body text-left show-scrollbar" },
            [
              _c("recurring-event-config", {
                attrs: { recurring: _vm.recurring }
              }),
              _c("hr", {
                staticClass: "mt-3 mb-3",
                staticStyle: {
                  "border-top": "1px solid var(--v-gray_50-base)",
                  "border-bottom": "none"
                }
              }),
              _c("div", { staticClass: "mb-6 row-format align-center gap-3" }, [
                _c(
                  "div",
                  {
                    class:
                      "page-link " +
                      (_vm.currentView === "Overview"
                        ? "page-link-active"
                        : ""),
                    on: {
                      click: function($event) {
                        return _vm.setView("Overview")
                      }
                    }
                  },
                  [_c("div", [_vm._v("Overview")])]
                ),
                _c(
                  "div",
                  {
                    class:
                      "page-link " +
                      (_vm.currentView === "Subtasks"
                        ? "page-link-active"
                        : ""),
                    on: {
                      click: function($event) {
                        return _vm.setView("Subtasks")
                      }
                    }
                  },
                  [_c("div", [_vm._v("Subtasks")])]
                )
              ]),
              _vm.currentView === "Overview"
                ? _c("deliverable-detail", {
                    attrs: {
                      deliverable: _vm.deliverable,
                      "fee-schedule": _vm.feeSchedule,
                      "attachment-service": _vm.recurringAttachmentService,
                      "project-type": _vm.projectType,
                      projectId: _vm.projectId,
                      clientId: _vm.clientId
                    }
                  })
                : _vm.currentView === "Subtasks"
                ? _c(
                    "div",
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "column-format gap-2",
                          attrs: { list: _vm.deliverable.subtasks }
                        },
                        _vm._l(_vm.deliverable.subtasks, function(subtask) {
                          return _c(
                            "deliverable-package-task",
                            {
                              key: subtask.id,
                              staticClass: "pa-1",
                              staticStyle: {
                                border: "1px solid var(--v-gray_30-base)",
                                "border-radius": "4px"
                              },
                              attrs: {
                                "hide-icon": true,
                                deliverable: subtask,
                                "is-subtask": true
                              },
                              on: {
                                click: function($event) {
                                  return _vm.editSubtask(subtask)
                                }
                              }
                            },
                            [_vm._v(" >")]
                          )
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "font-14 font-gray_50 pointer mt-3",
                          staticStyle: { width: "fit-content" },
                          on: { click: _vm.addSubTask }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mb-1", attrs: { size: "14" } },
                            [_vm._v("$plus")]
                          ),
                          _vm._v(" Add subtask ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.selectedSubtask
            ? _c("basic-modal", { attrs: { dialog: _vm.subtaskEditor } }, [
                _c(
                  "div",
                  { staticClass: "px-5 py-4 column-format gap-3 text-left" },
                  [
                    _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-1 material-symbols-rounded",
                            attrs: { color: "black" }
                          },
                          [_vm._v("graph_2")]
                        ),
                        _c("div", { staticClass: "brand-medium font-18" }, [
                          _c("div", [_vm._v("Edit subtask")])
                        ]),
                        _c(
                          "div",
                          { staticClass: "ml-auto" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "super-action",
                                on: {
                                  click: function($event) {
                                    return _vm.saveSubtask(_vm.selectedSubtask)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("global.save")) + " "
                                )
                              ]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "pointer ml-2",
                                attrs: { size: "20", color: "gray_60" },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmDeleteSubtask()
                                  }
                                }
                              },
                              [_vm._v("$delete")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("deliverable-detail", {
                      attrs: {
                        "hide-task-name": false,
                        "fee-schedule": _vm.feeSchedule,
                        deliverable: _vm.selectedSubtask,
                        "attachment-service": _vm.recurringAttachmentService,
                        "project-type": _vm.projectType,
                        "is-subtask": true,
                        projectId: _vm.projectId,
                        clientId: _vm.clientId
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }