var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format fill-height pointer",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("div", { staticClass: "pa-4" }, [
        _c("div", { staticClass: "font-16 brand-medium font-gray_70" }, [
          _vm._v("Time worked")
        ]),
        _c("span", { staticClass: "font-24 brand-bold" }, [
          _vm._v(_vm._s(_vm.$formatters.formatSeconds(_vm.timeWorkedInPeriod)))
        ]),
        _vm._v(" this " + _vm._s(_vm.retainerUnit) + " "),
        _vm.maxHours
          ? _c("span", [_vm._v(" / " + _vm._s(_vm.maxHours) + " max")])
          : _vm._e()
      ]),
      _c(
        "div",
        {
          staticClass: "pa-4 mt-auto row-format align-center",
          staticStyle: {
            "background-color": "var(--v-gray_5-base)",
            height: "44px",
            "border-top": "1px solid var(--v-gray_30-base)"
          }
        },
        [
          _c("div", [
            _c("span", { staticClass: "brand-bold" }, [
              _vm._v(
                _vm._s(_vm.$formatters.formatSeconds(_vm.totalTimeWorked)) +
                  " total worked"
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }