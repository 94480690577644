<template>
	<div v-if="mode === 'standard' || mode === 'dense'" style="width:100%">
		<amount-input
				hide-details
				persistent-placeholder
				:dense="mode === 'dense'"
				outlined
				type="number"
				v-model="ourValue"
				:label="field.name"
				@input="handleChange"
		>
		</amount-input>
	</div>
	<div v-else style="width:100%">
		<input v-model="ourValue" type="number" placeholder="--" @change="handleChange" style="color: var(--v-black-base)"/>
	</div>
</template>

<script>
import AmountInput from "@/components/AmountInput";

export default {
	name: "CustomNumeric",

	props: ['field','value','mode'],

	components: {AmountInput},

	data: function () {
		return {
			ourValue: this.value
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		handleChange: function(){
			let result = parseFloat(this.ourValue);
			if(isNaN(result)){
				this.ourValue = 0;
			}else{
				this.ourValue = result;
			}
			this.$emit('change',this.ourValue);
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">
	input:focus {
		outline: 1px solid var(--v-gray_30-base);
	}

	input {
		width:100%;
	}
</style>