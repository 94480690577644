<template>
	<div v-if="bookingData" class="column-format gap-6">
		<div class="column-format gap-2">
			<div class="text-left brand-medium row-format align-center gap-1">
				<div>Next 12 months - scheduled income</div>
				<v-menu close-on-content-click close-on-click>
					<template v-slot:activator="{on}">
						<v-icon size="20" v-on="on" class="pointer" color="gray_50">info</v-icon>
					</template>
					<div class="booking-box pa-4 text-left" style="max-width: 300px">
						Scheduled income is based on recurring projects, recurring invoices, and date scheduled payment plans.
					</div>
				</v-menu>
			</div>
			<forward-chart :booking-data="bookingData" :earliest="earliest" :latest="latest"></forward-chart>
		</div>
		<v-container fluid class="ma-0 pa-0">
			<v-row>
				<v-col cols="12" md="6">
					<div class="text-left brand-medium row-format align-center gap-1">
						<div>Un-billed hours</div>
						<v-menu close-on-content-click close-on-click>
							<template v-slot:activator="{on}">
								<v-icon size="20" v-on="on" class="pointer" color="gray_50">info</v-icon>
							</template>
							<div class="booking-box pa-4 text-left" style="max-width: 300px">
								This includes all un-billed hours that are recorded directly at the client level or to an Hourly fee project
							</div>
						</v-menu>
					</div>
					<un-billed :un-billed="bookingData.unBilledHours" :is-time="true" label="Hours" append-link="?v=Time"></un-billed>
				</v-col>
				<v-col cols="12" md="6">
					<div class="text-left brand-medium row-format align-center gap-1">
						<div>Payment plan milestones</div>
						<v-menu close-on-content-click close-on-click>
							<template v-slot:activator="{on}">
								<v-icon size="20" v-on="on" class="pointer" color="gray_50">info</v-icon>
							</template>
							<div class="booking-box pa-4 text-left" style="max-width: 300px">
								This includes the future earnings on milestone based installments in active Payment Plans
							</div>
						</v-menu>
					</div>
					<un-billed :un-billed="bookingData.milestonePayments" label="Milestones" append-link="?v=Invoices&t=2"></un-billed>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6">
					<div class="text-left brand-medium row-format align-center gap-1">
						<div>Un-billed fixed fee projects</div>
						<v-menu close-on-content-click close-on-click>
							<template v-slot:activator="{on}">
								<v-icon size="20" v-on="on" class="pointer" color="gray_50">info</v-icon>
							</template>
							<div class="booking-box pa-4 text-left" style="max-width: 300px">
								This includes the un-billed balance on any fixed fee project that is NOT being managed under a Payment Plan
							</div>
						</v-menu>
					</div>
					<un-billed :un-billed="bookingData.fixedFeeProjects" label="Projects"></un-billed>
				</v-col>
				<v-col cols="12" md="6">
					<div class="text-left brand-medium row-format align-center gap-1">
						<div>Un-billed tasks</div>
						<v-menu close-on-content-click close-on-click>
							<template v-slot:activator="{on}">
								<v-icon size="20" v-on="on" class="pointer" color="gray_50">info</v-icon>
							</template>
							<div class="booking-box pa-4 text-left" style="max-width: 300px">
								This includes the un-billed tasks in any Per Item billing project
							</div>
						</v-menu>
					</div>
					<un-billed :un-billed="bookingData.perItemTasks" label="Billable tasks"></un-billed>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
	import MetricService from '@/modules/reports/metrics/MetricService';
	import ForwardChart from "@/modules/home/bookings/ForwardChart";
	import UnBilled from "@/modules/home/bookings/UnBilled";

	export default {
		name: 'Bookings',

		props: ['isVisible'],

		components: {UnBilled, ForwardChart},

		data: function() {
			return {
				metricService: new MetricService(),
				bookingData: null,
			};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {
			getBookings: function(){
				this.$store.commit('startLoading');
				this.metricService.getBookings(this.earliest.toISODate(),this.latest.toISODate()).then((res) => {
					this.bookingData = res.data;
				}).finally(() => this.$store.commit('stopLoading'));
			}
		},

		watch:{
			isVisible: {
				immediate: true,
				handler: function(val){
					if(val && !this.bookingData){
						this.getBookings();
					}
				}
			}
		},

		computed: {
			earliest: function() {
				return DateTime.now().startOf('month');
			},

			latest: function(){
				return DateTime.now().plus({months:11}).endOf('month');
			}
		},
	};
</script>

<style scoped lang="scss">
	.booking-box {
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		background-color: var(--v-white-base);
	}
</style>
