<template>
	<div id="deliverable-package-modal">
		<v-btn icon class="close-dialog" @click="$emit('result')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding column-format gap-3">
			<div class="modal-title">Import tasks from template</div>
			<v-autocomplete v-if="!project" :items="projects" item-text="name" item-value="id" v-model="projectId" hide-details dense outlined persistent-placeholder label="Select project">
				<template v-slot:item="{item}">
					<div class="row-format align-center gap-2">
						<client-avatar :client="item.client" small></client-avatar>
						<div>{{item.name}}</div>
					</div>
				</template>
				<template v-slot:selection="{item}">
					<div class="row-format align-center gap-2">
						<client-avatar :client="item.client" small></client-avatar>
						<div>{{item.name}}</div>
					</div>
				</template>
			</v-autocomplete>
			<v-autocomplete :items="deliverablePackages" item-text="name" return-object v-model="deliverablePackage" hide-details dense outlined persistent-placeholder label="Select template"></v-autocomplete>
			<div v-if="deliverablePackage">
				<div v-for="deliverable in deliverablePackage.deliverables" :key="deliverable.id">
					<v-checkbox v-model="filter" hide-details dense :label="deliverable.name" :value="deliverable.id"></v-checkbox>
				</div>
			</div>

		</div>
		<div class="modal-footer">
			<v-btn class="super-action" @click="importIntoProject" :disabled="!buttonEnabled">Import selected tasks</v-btn>
		</div>
	</div>
</template>

<script>

	import TemplateService from "@/modules/templates/TemplateService";
	import ProjectService from "@/modules/projects/ProjectService";
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'DeliverableImportModal',

		props: ['project'],

		components: {ClientAvatar},

		data: function() {
			return {
				projects: [],
				deliverablePackages: [],
				deliverablePackage: null,
				projectId: this.project ? this.project.id : null,
				templateService: new TemplateService(),
				projectService: new ProjectService(),
				filter: []
			};
		},

		mounted() {
			this.getDeliverablePackage();
			if(!this.project){
				this.getProjects();
			}
		},

		beforeDestroy() {},

		methods: {
			importIntoProject: function(){
				this.$store.commit('startLoading');
				this.templateService.addToExistingProjectFromDeliverablePackage(this.deliverablePackage.id,this.projectId,this.filter).then(() => {
					this.$store.commit('success',`Successfully imported ${this.filter.length} tasks`);
					this.$emit('result');
				}).finally(() => this.$store.commit('stopLoading'));
			},

			getProjects: function(){
				this.projectService.getAllActiveProjects(true,false).then((res) => {
					this.projects.splice(0);
					this.projects.push(... res.data);
				});
			},

			getDeliverablePackage: function(){
				this.templateService.getDeliverablePackages().then((res) => {
					this.deliverablePackages.splice(0);
					this.deliverablePackages.push(... res.data);
					this.deliverablePackages.sort((a,b) => a.name ? a.name.localeCompare(b.name) : 0);
				})
			}
		},

		watch: {
			deliverablePackage: function(){
				this.filter.splice(0);
				if(this.deliverablePackage){
					this.filter.push(... this.deliverablePackage.deliverables.map(d => d.id));
				}
			}
		},

		computed: {
			buttonEnabled: function(){
				return this.projectId && this.deliverablePackage && this.filter.length;
			}
		},
	};
</script>

<style lang="scss">
	#deliverable-package-modal {
		background-color: var(--v-white-base);
		min-width: 450px;
	}
</style>
