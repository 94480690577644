<template>
	<div v-if="isReady" class="">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.microsoft.desc') }}</div>
				<connected v-if="isConnected"></connected>
				<div v-else class="font-primary brand-medium pointer" @click="connectMicrosoftAccount">Connect Microsoft calendar</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded">
			<div v-for="conn in microsoftConnections" :key="conn.microsoftUser.email"  class="pt-4 pb-4"  style="border-bottom: 1px solid var(--v-gray_50-base)">
				<div class="font-14">
					<div><span class="brand-medium">Account:</span> {{conn.microsoftUser.name}}</div>
					<div><span class="brand-medium">Email:</span> {{conn.microsoftUser.mail}}</div>
					<div class="my-3" v-if="conn.calendarList && conn.calendarList.length">
						<div class="column-format" v-for="cal in conn.calendarList" :key="cal.id" style="border-bottom: 1px solid var(--v-gray_30-base)">
							<v-checkbox v-model="cal.enabled" :label="cal.name"  hide-details dense @change="updateSettings(conn)"></v-checkbox>
							<v-checkbox class="ml-5" v-if="cal.enabled" v-model="cal.showInCalendar" label="Show events in calendar" hide-details dense @change="updateSettings(conn)"></v-checkbox>
						</div>
					</div>
					<v-btn class="secondary-action mt-1" @click="confirmDisconnect(conn)"><v-icon small>$delete</v-icon>Disconnect</v-btn>
				</div>
			</div>
			<div class="mt-4">
				<v-btn class="primary-action" @click="connectMicrosoftAccount">Connect additional account</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import Connected from '@/modules/account/apps/Connected';
	import ConfirmModal from '@/components/ConfirmModal';
	import MicrosoftCalendarService from "@/modules/integrations/microsoft/MicrosoftCalendarService";

	export default {
		name: 'MicrosoftCalendar',

		props: [],

		components: { Connected },

		data: function () {
			return {
				microsoftService: new MicrosoftCalendarService(),
				expanded: false,
				isReady: false,

				microsoftConnections: null,
				clientId: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
				redirectUri: document.location.protocol + '//' + document.location.host + '/integrations/microsoft/oauth',
				scope:  'User.Read Calendars.ReadWrite Calendars.ReadWrite.Shared OnlineMeetings.ReadWrite offline_access',
			};
		},

		mounted() {
			this.getConnections();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.getConnections();
			},


			getConnections: function () {
				this.microsoftService
					.getConnections()
					.then((res) => this.microsoftConnections = res.data)
					.finally(() => (this.isReady = true));
			},

			connectMicrosoftAccount: function () {
				localStorage.setItem('MICROSOFT_OAUTH_TYPE','Calendar');
				localStorage.setItem('MICROSOFT_OAUTH_SCOPE',this.scope);
				window.location.href = this.oauthUrl;
			},

			updateSettings: function(conn){
				this.microsoftService.update(conn.id,conn.calendarList).then((res) => {
					let ix = this.microsoftConnections.findIndex((c) => c.id === res.data.id);
					if(ix > -1){
						this.microsoftConnections.splice(ix,1,res.data);
					}
				})
			},

			confirmDisconnect: function (conn) {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to disconnect your ${conn.microsoftUser.mail} Microsoft calendar?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.microsoftService.disconnect(conn.id).then(() => {
							this.getConnections();
						});
					}
				});
			},
		},

		computed: {
			oauthUrl: function(){
				return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.clientId}&response_type=code&scope=${this.scope}&prompt=select_account&redirect_uri=${encodeURIComponent(this.redirectUri)}`
			},

			isConnected: function(){
				if(this.microsoftConnections && this.microsoftConnections.length){
					return true;
				}else{
					return false;
				}
			}
		},
	};
</script>

<style scoped lang="scss"></style>
