var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("customization", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentView === "customization",
            expression: "currentView === 'customization'"
          }
        ],
        ref: "customization"
      }),
      _c("custom-domains", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentView === "domains",
            expression: "currentView === 'domains'"
          }
        ],
        ref: "domains"
      }),
      _vm.currentView === "permissions"
        ? _c("permissions", {
            ref: "permissions",
            attrs: { filter: _vm.filter }
          })
        : _vm._e(),
      _vm.currentView === "features"
        ? _c("features", { ref: "features" })
        : _vm._e(),
      _c("custom-email", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentView === "email",
            expression: "currentView === 'email'"
          }
        ],
        ref: "email"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }