<template>
	<div class="row-format gap-2 font-14 pointer" v-if="nativeEvent" @click="openCalendarEvent">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" v-tippy="{ content: 'Calendar event' }"
			>event</v-icon
		>
		<div>{{nativeEvent.summary}}</div>
		<div class="ml-auto font-gray_80 font-12">{{ formattedDate }}</div>
	</div>
</template>

<script>
import NativeCalendarService from '@/modules/calendar/mixins/NativeCalendarService';
import NativeEventDetail from "@/modules/calendar/details/NativeEventDetail";
import {DateTime} from 'luxon';


	export default {
		name: 'CalendarEvent',

		props: ['item'],

		components: {},

		data: function() {
			return {
				nativeEvent: null,
				nativeCalendarService: new NativeCalendarService(),
			};
		},

		mounted() {
			this.getCalendarEvent();
		},

		beforeDestroy() {},

		methods: {
			truncateString(string,length){
				if(string && string.length > length){
					return string.substring(0,length) + "...";
				}else{
					return string;
				}
			},
			getCalendarEvent: function() {
				this.nativeCalendarService
					.getUserCalendarEvent(this.item.itemId)
					.then((res) => {
						this.nativeEvent = res.data;
						this.$emit('actual-time', this.nativeEvent.startDate);
					})
					.catch((err) => {
						if (err.response.status === 404) {
							this.$emit('deleted');
						}
					});
			},

			openCalendarEvent: function() {
				let binding = {
					event: this.nativeEvent,
				};
				this.$store.state.globalModalController.openModal(NativeEventDetail, binding, true, false).then((res) => {
					this.nativeEvent = res;
				});
			},
		},

		computed: {
			formattedDate: function(){
				let date = this.nativeEvent.startDate;
				if(!this.nativeEvent.dateOnly){
					date = date + 'T' + this.nativeEvent.startTime;
				}
				return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
			}
		},
	};
</script>

<style scoped lang="scss"></style>
