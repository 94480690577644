var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "signature-dialog" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.cancelSign()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "pa-4" },
        [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.$t("proposal.signature.signature")))
          ]),
          _c(
            "v-tabs",
            {
              attrs: { "slider-size": "4", "slider-color": "blue_50" },
              on: { change: _vm.tabChanged }
            },
            [
              _c("v-tab", [
                _vm._v(_vm._s(_vm.$t("proposal.signature.type-signature")))
              ]),
              _c("v-tab", [
                _vm._v(_vm._s(_vm.$t("proposal.signature.draw-signature")))
              ]),
              _c(
                "v-tab-item",
                [
                  _c(
                    "div",
                    {
                      staticClass: "signature-block mt-4 mb-3",
                      style: _vm.signatureFont
                    },
                    [
                      _c("div", { staticClass: "signature" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.textSignature,
                              expression: "textSignature"
                            }
                          ],
                          staticClass: "keyboardInput",
                          attrs: {
                            placeholder: "Your Name Here",
                            type: "text"
                          },
                          domProps: { value: _vm.textSignature },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.textSignature = $event.target.value
                            }
                          }
                        })
                      ])
                    ]
                  ),
                  _c("v-select", {
                    staticClass: "h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      label: _vm.$t("proposal.signature.signature-font"),
                      items: _vm.fonts
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function(data) {
                          return [
                            _c(
                              "span",
                              {
                                style:
                                  "font-family: " +
                                  data.item +
                                  ", cursive; font-size:30px"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("proposal.signature.signature"))
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "selection",
                        fn: function(data) {
                          return [
                            _c(
                              "span",
                              {
                                style:
                                  "font-family: " +
                                  data.item +
                                  ", cursive; font-size:30px"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("proposal.signature.signature"))
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.signatureObj.font,
                      callback: function($$v) {
                        _vm.$set(_vm.signatureObj, "font", $$v)
                      },
                      expression: "signatureObj.font"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      disabled: _vm.disableInput,
                      label: _vm.$t("proposal.signature.title")
                    },
                    model: {
                      value: _vm.signatureObj.signerTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.signatureObj, "signerTitle", $$v)
                      },
                      expression: "signatureObj.signerTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c("div", { staticClass: "signature-block mt-4 mb-3" }, [
                    _c("div", { staticClass: "instructions" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.signature.draw-in-box")))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "signature",
                        style:
                          "background-color: var(--v-gray_10-base);" +
                          _vm.signatureFont
                      },
                      [
                        _c("vue-signature-pad", {
                          ref: "signaturePad",
                          attrs: { width: "476px", height: "150px" }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("v-text-field", {
                    staticClass: "h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      disabled: _vm.disableInput,
                      label: "Full Name"
                    },
                    model: {
                      value: _vm.signatureObj.signerName,
                      callback: function($$v) {
                        _vm.$set(_vm.signatureObj, "signerName", $$v)
                      },
                      expression: "signatureObj.signerName"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      disabled: _vm.disableInput,
                      label: "Title"
                    },
                    model: {
                      value: _vm.signatureObj.signerTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.signatureObj, "signerTitle", $$v)
                      },
                      expression: "signatureObj.signerTitle"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              on: {
                click: function($event) {
                  return _vm.cancelSign()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.cancel")))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action ml-1",
              on: {
                click: function($event) {
                  return _vm.confirmSign()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("proposal.signature.sign-accept")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }