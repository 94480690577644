var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bookingData
    ? _c(
        "div",
        { staticClass: "column-format gap-6" },
        [
          _c(
            "div",
            { staticClass: "column-format gap-2" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-left brand-medium row-format align-center gap-1"
                },
                [
                  _c("div", [_vm._v("Next 12 months - scheduled income")]),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": "",
                        "close-on-click": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      staticClass: "pointer",
                                      attrs: { size: "20", color: "gray_50" }
                                    },
                                    on
                                  ),
                                  [_vm._v("info")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1461092966
                      )
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "booking-box pa-4 text-left",
                          staticStyle: { "max-width": "300px" }
                        },
                        [
                          _vm._v(
                            " Scheduled income is based on recurring projects, recurring invoices, and date scheduled payment plans. "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _c("forward-chart", {
                attrs: {
                  "booking-data": _vm.bookingData,
                  earliest: _vm.earliest,
                  latest: _vm.latest
                }
              })
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-left brand-medium row-format align-center gap-1"
                        },
                        [
                          _c("div", [_vm._v("Un-billed hours")]),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": "",
                                "close-on-click": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "pointer",
                                              attrs: {
                                                size: "20",
                                                color: "gray_50"
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("info")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1461092966
                              )
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "booking-box pa-4 text-left",
                                  staticStyle: { "max-width": "300px" }
                                },
                                [
                                  _vm._v(
                                    " This includes all un-billed hours that are recorded directly at the client level or to an Hourly fee project "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("un-billed", {
                        attrs: {
                          "un-billed": _vm.bookingData.unBilledHours,
                          "is-time": true,
                          label: "Hours",
                          "append-link": "?v=Time"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-left brand-medium row-format align-center gap-1"
                        },
                        [
                          _c("div", [_vm._v("Payment plan milestones")]),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": "",
                                "close-on-click": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "pointer",
                                              attrs: {
                                                size: "20",
                                                color: "gray_50"
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("info")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1461092966
                              )
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "booking-box pa-4 text-left",
                                  staticStyle: { "max-width": "300px" }
                                },
                                [
                                  _vm._v(
                                    " This includes the future earnings on milestone based installments in active Payment Plans "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("un-billed", {
                        attrs: {
                          "un-billed": _vm.bookingData.milestonePayments,
                          label: "Milestones",
                          "append-link": "?v=Invoices&t=2"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-left brand-medium row-format align-center gap-1"
                        },
                        [
                          _c("div", [_vm._v("Un-billed fixed fee projects")]),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": "",
                                "close-on-click": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "pointer",
                                              attrs: {
                                                size: "20",
                                                color: "gray_50"
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("info")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1461092966
                              )
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "booking-box pa-4 text-left",
                                  staticStyle: { "max-width": "300px" }
                                },
                                [
                                  _vm._v(
                                    " This includes the un-billed balance on any fixed fee project that is NOT being managed under a Payment Plan "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("un-billed", {
                        attrs: {
                          "un-billed": _vm.bookingData.fixedFeeProjects,
                          label: "Projects"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-left brand-medium row-format align-center gap-1"
                        },
                        [
                          _c("div", [_vm._v("Un-billed tasks")]),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": "",
                                "close-on-click": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "pointer",
                                              attrs: {
                                                size: "20",
                                                color: "gray_50"
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("info")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1461092966
                              )
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "booking-box pa-4 text-left",
                                  staticStyle: { "max-width": "300px" }
                                },
                                [
                                  _vm._v(
                                    " This includes the un-billed tasks in any Per Item billing project "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("un-billed", {
                        attrs: {
                          "un-billed": _vm.bookingData.perItemTasks,
                          label: "Billable tasks"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }