var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "proposal-template-header-wrapper" } }, [
    _c("div", { staticClass: "proposal-header" }, [
      _c("div", { staticClass: "header-left pl-md-6 pl-4" }, [
        _c("div", { staticClass: "mr-3 brand-medium d-none d-md-block" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("templates.proposals.edit-template")) + " "
          )
        ]),
        _c(
          "div",
          { staticClass: "name-div" },
          [
            _c("v-text-field", {
              staticClass: "template-name",
              attrs: {
                dense: "",
                "hide-details": "",
                "persistent-placeholder": "",
                placeholder: _vm.$t("templates.proposals.name-placeholder")
              },
              on: {
                change: function($event) {
                  return _vm.$emit("name-change", _vm.proposalTemplate.name)
                }
              },
              model: {
                value: _vm.proposalTemplate.name,
                callback: function($$v) {
                  _vm.$set(_vm.proposalTemplate, "name", $$v)
                },
                expression: "proposalTemplate.name"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "header-right pr-md-6 pr-4 gap-1" },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "div",
                { staticClass: "nowrap" },
                [
                  _vm.needSave || _vm.saving
                    ? _c("v-progress-circular", {
                        staticClass: "mr-2",
                        attrs: {
                          indeterminate: "",
                          size: "20",
                          color: _vm.saving ? "green" : "accent"
                        }
                      })
                    : _vm._e(),
                  !_vm.needSave && !_vm.saving
                    ? _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("check")]
                      )
                    : _vm._e(),
                  _c("span", { staticClass: "progress" }, [
                    _vm._v(_vm._s(_vm.status))
                  ])
                ],
                1
              )
            : _vm._e(),
          _c("folder-selector", {
            attrs: { folders: _vm.folders },
            model: {
              value: _vm.proposalTemplate.folder,
              callback: function($$v) {
                _vm.$set(_vm.proposalTemplate, "folder", $$v)
              },
              expression: "proposalTemplate.folder"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { width: "96", outlined: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
          ),
          _c(
            "v-menu",
            {
              attrs: { "close-on-click": true, "nudge-bottom": "30" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        [
                          scope.value
                            ? _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "secondary-action",
                                    attrs: { icon: "" }
                                  },
                                  scope.on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticStyle: { "margin-right": "2px" },
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("$arrowUp")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !scope.value
                            ? _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "secondary-action",
                                    attrs: { icon: "" }
                                  },
                                  scope.on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("$moreHorizontal")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("div", { staticClass: "more-menu" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("duplicate")
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("templates.proposals.duplicate-proposal")
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.$emit("delete")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.delete")) + " ")]
                )
              ])
            ]
          ),
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary-action",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.handleClose()
                    }
                  }
                },
                [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }