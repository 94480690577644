<template>
	<div class="plus-button" @click="$emit('click',$event)">
		<v-icon color="white" small>$plus</v-icon>
	</div>
</template>

<script>
export default {
	name: "PlusButton",

	props: [],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">
	.plus-button {
		cursor: pointer;
		width: 32px;
		min-width: 32px;
		height: 32px;
		min-height: 32px;
		border-radius: 100px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: var(--v-secondary-base);
		box-shadow: 0px 2px 4px var(--v-gray_10-base);
		white-space: nowrap;
	}
</style>