var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          headers: _vm.headers,
          items: _vm.payments,
          "sort-by": "datePaid",
          "sort-desc": true,
          "fixed-header": true,
          "items-per-page": -1,
          "hide-default-footer": true
        },
        on: {
          "click:row": function($event) {
            return _vm.$emit("open-invoice", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.client.name",
            fn: function(ref) {
              var item = ref.item
              return [
                item.client
                  ? _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        item.client
                          ? _c("client-avatar", {
                              attrs: { small: "", client: item.client }
                            })
                          : _vm._e(),
                        _c("div", [_vm._v(_vm._s(item.client.name))])
                      ],
                      1
                    )
                  : _c("div", [_vm._v(" -- ")])
              ]
            }
          },
          {
            key: "item.datePaid",
            fn: function(ref) {
              var item = ref.item
              return [
                item.datePaid
                  ? _c("span", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.datePaid).toFormat("DD")
                        )
                      )
                    ])
                  : item.date
                  ? _c("span", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(_vm.DateTime.fromISO(item.date).toFormat("DD"))
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.amount",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { staticClass: "brand-semilight" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$formatters.dollars(
                          item.amount ? item.amount : 0,
                          true,
                          true,
                          item.currency
                        )
                      ) +
                      " "
                  )
                ])
              ]
            }
          },
          {
            key: "item.invoiceNumberFormatted",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", { staticClass: "brand-semilight" }, [
                  _vm._v(_vm._s(item.invoiceNumberFormatted))
                ])
              ]
            }
          },
          {
            key: "item.paymentProvider",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", { staticClass: "brand-semilight" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "invoice.payment-providers." + item.paymentProvider
                      )
                    )
                  )
                ])
              ]
            }
          },
          {
            key: "item.pending",
            fn: function(ref) {
              var item = ref.item
              return [
                item.type === "Invoice"
                  ? _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        _c("div", [
                          _vm._v(_vm._s(item.pending ? "Pending" : "Cleared"))
                        ]),
                        item.pending
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "ml-1",
                                attrs: {
                                  small: "",
                                  color: item.pending ? "warning" : "success"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(item.pending ? "$alert" : "$success")
                                )
                              ]
                            )
                          : _vm._e(),
                        item.integrationKeys &&
                        item.integrationKeys.quickbooksId
                          ? _c(
                              "v-icon",
                              { staticClass: "ml-2", attrs: { size: "20" } },
                              [_vm._v("$quickbooks")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c("div", [_vm._v("--")])
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [_vm._v(" No payments found. ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }