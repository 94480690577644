<template>
	<basic-modal :dialog="dialog">
		<div style="min-width: 500px">
			<div class="pa-3">
				<div class="row-format">
					<div>✨Email Assistant</div>
					<v-btn icon @click="$emit('cancel')" class="ml-auto">
						<v-icon>$close</v-icon>
					</v-btn>
				</div>
				<div class="column-format gap-2">
					<div class="row-format gap-2">
						<v-select
							v-model="tone"
							:items="toneOptions"
							hide-details
							dense
							persistent-placeholder
							outlined
							label="Tone"
						></v-select>
						<v-select
							v-if="mode === 'rewrite'"
							v-model="rewrite"
							:items="rewriteOptions"
							hide-details
							dense
							persistent-placeholder
							outlined
							label="Length"
						></v-select>
					</div>
					<v-textarea
						hide-details
						dense
						persistent-placeholde
						outlined
						v-model="input"
						autofocus
						:placeholder="instructionLabel"
					></v-textarea>
				</div>
			</div>
			<div style="border-top: 1px solid var(--v-gray_30-base)" class="pt-4 pb-2">
				<v-btn class="primary-action" width="200" @click="letsGo">OK</v-btn>
			</div>
		</div>
	</basic-modal>
</template>

<script>
	import BasicModal from '@/components/BasicModal';
	export default {
		name: 'AiEmailWidget',

		props: ['dialog', 'mode', 'placeholder'],

		components: { BasicModal },

		data: function() {
			return {
				toneOptions: ['Neutral', 'Friendly', 'Formal'],
				rewriteOptions: ['About the same', 'Elaborate', 'Make more concise'],
				tone: 'Neutral',
				rewrite: 'Elaborate',
				input: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			letsGo: function() {
				this.$emit('result', {
					tone: this.tone,
					rewrite: this.rewrite,
					input: this.input,
				});
			},
		},

		watch: {},

		computed: {
			instructionLabel: function() {
				if(this.placeholder){
					return this.placeholder;
				}
				if (this.mode === 'rewrite') {
					return 'I will be happy to help you rewrite this.  Anything in particular you want in the rewrite?';
				} else if (this.mode === 'reply') {
					return 'What would you like your reply to include?';
				} else {
					return 'Tell me a bit about what your email should say.';
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
