var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ai-chat-alert" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("result")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding text-left column-format gap-3" },
        [
          _c("h1", { staticClass: "modal-title" }, [
            _vm._v("✨Moxie Assistant")
          ]),
          _c("div", [
            _vm._v(
              " Moxie Assistant leverages cutting-edge AI technology to simplify your daily tasks, providing you with intelligent insights, efficient summarization, advanced writing support, and conversational configuration. Discover how Moxie Assistant can transform the way you work. "
            )
          ]),
          _c("youtube", {
            staticStyle: {
              "background-color": "var(--v-black-base)",
              "padding-top": "4px"
            },
            attrs: {
              "player-vars": { autoplay: 0 },
              id: "player",
              "video-id": "zlHHDCCSM7A",
              "player-width": 840,
              "player-height": 462
            }
          }),
          _c(
            "div",
            { staticClass: "row-format centered" },
            [
              _vm.isInTrial && _vm.isAiIncluded
                ? _c("div", { staticClass: "font-italic" }, [
                    _vm._v(
                      " Looks like you have used up your free trial AI credits. Please subscribe to one of the paid Moxie plans to continue using this feature. "
                    )
                  ])
                : _vm.isAccountOwner && !_vm.isAiIncluded
                ? _c(
                    "v-btn",
                    {
                      staticClass: "super-action",
                      attrs: { large: "", block: "" },
                      on: { click: _vm.routeToSub }
                    },
                    [_vm._v("Add Moxie Assistant to your workspace")]
                  )
                : _c("div", { staticClass: "font-italic" }, [
                    _vm._v(
                      "Please contact the owner of this workspace to get access to this feature."
                    )
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }