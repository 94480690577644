var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "nudge-top": 28,
            "min-width": "150",
            "max-width": "150",
            bottom: "",
            right: "",
            rounded: "",
            "offset-overflow": "",
            "offset-y": "",
            "close-on-content-click": true,
            "content-class": "filter-menu-class",
            transition: "slide-y-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: {
                          "min-height": "40px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          elevation: "0",
                          outlined: "",
                          color: "gray_30"
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (_vm.isFilterActive
                              ? "font-secondary"
                              : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filterLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.isFilterActive ? "secondary" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c("div", { staticClass: "column-format" }, [
            _c(
              "div",
              { staticClass: "filter-option-box" },
              [
                _c(
                  "v-radio-group",
                  {
                    staticClass: "small-radio",
                    attrs: { "hide-details": "", dense: "" },
                    on: { change: _vm.updated },
                    model: {
                      value: _vm.filter.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.filter, "sort", $$v)
                      },
                      expression: "filter.sort"
                    }
                  },
                  [
                    _c("v-radio", { attrs: { label: "Name", value: "Name" } }),
                    _c("v-radio", {
                      attrs: { label: "Revenue", value: "Revenue" }
                    }),
                    _c("v-radio", {
                      attrs: { label: "Hours", value: "Hours" }
                    }),
                    _c("v-radio", {
                      attrs: { label: "Favorites", value: "Favorites" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }