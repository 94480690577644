var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.emailBox
    ? _c(
        "div",
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c("div", { staticClass: "modal-padding" }, [
            _c("h3", { staticClass: "modal-title" }, [
              _vm._v("Connected account configuration")
            ]),
            _c("div", { staticClass: "row-format" }, [
              _c("div", { staticClass: "mr-3 column-format" }, [
                _c(
                  "div",
                  [
                    _c("v-select", {
                      attrs: {
                        disabled: !!_vm.emailBox.id,
                        items: _vm.providers,
                        "item-value": "type",
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                        label: "Email provider"
                      },
                      on: {
                        change: function($event) {
                          return _vm.providerUpdated()
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("v-icon", [_vm._v(_vm._s(item.icon))]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(item.label))
                                ])
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("v-icon", [_vm._v(_vm._s(item.icon))]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(item.label))
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1889431932
                      ),
                      model: {
                        value: _vm.emailBox.provider,
                        callback: function($$v) {
                          _vm.$set(_vm.emailBox, "provider", $$v)
                        },
                        expression: "emailBox.provider"
                      }
                    }),
                    _vm.selectedProvider && _vm.selectedProvider.oauth
                      ? _c("div", { staticClass: "my-4" }, [
                          _vm.emailBox.id
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "column-format h-outline font-14 pa-4",
                                  staticStyle: { gap: "4px" }
                                },
                                [
                                  _c("div", [
                                    _vm._v(" Account: "),
                                    _c(
                                      "span",
                                      { staticClass: "brand-medium" },
                                      [_vm._v(_vm._s(_vm.emailBox.oauthName))]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" Email: "),
                                    _c(
                                      "span",
                                      { staticClass: "brand-medium" },
                                      [_vm._v(_vm._s(_vm.emailBox.oauthEmail))]
                                    )
                                  ]),
                                  _vm.selectedProvider.oAuthImage
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "column-format centered"
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Reconnect your " +
                                                _vm._s(
                                                  _vm.selectedProvider.name
                                                ) +
                                                " account"
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pointer mt-2",
                                              on: { click: _vm.reconnectOAuth }
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  width: "200",
                                                  src:
                                                    _vm.selectedProvider
                                                      .oAuthImage
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-3",
                                          attrs: {
                                            elevation: "0",
                                            large: "",
                                            block: ""
                                          },
                                          on: { click: _vm.reconnectOAuth }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedProvider.icon
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            " Reconnect your " +
                                              _vm._s(
                                                _vm.selectedProvider.name
                                              ) +
                                              " account"
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _vm.selectedProvider.oAuthImage
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "column-format centered"
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "Connect your " +
                                                _vm._s(
                                                  _vm.selectedProvider.name
                                                ) +
                                                " account"
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pointer mt-2",
                                              on: { click: _vm.connectOAuth }
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  width: "200",
                                                  src:
                                                    _vm.selectedProvider
                                                      .oAuthImage
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            elevation: "0",
                                            large: "",
                                            block: ""
                                          },
                                          on: { click: _vm.connectOAuth }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedProvider.icon
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            " Connect your " +
                                              _vm._s(
                                                _vm.selectedProvider.name
                                              ) +
                                              " account"
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                        ])
                      : _vm.selectedProvider && !_vm.selectedProvider.oauth
                      ? _c(
                          "div",
                          { staticClass: "column-format gap-3 my-3" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: "Username/Email",
                                dense: "",
                                outlined: ""
                              },
                              model: {
                                value: _vm.emailBox.username,
                                callback: function($$v) {
                                  _vm.$set(_vm.emailBox, "username", $$v)
                                },
                                expression: "emailBox.username"
                              }
                            }),
                            _c("v-text-field", {
                              attrs: {
                                type: "password",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.appSpecificPasswordLink
                                  ? "App specific password"
                                  : "Password",
                                dense: "",
                                outlined: ""
                              },
                              model: {
                                value: _vm.emailBox.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.emailBox, "password", $$v)
                                },
                                expression: "emailBox.password"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.emailBox.id
                      ? _c("v-text-field", {
                          staticClass: "mb-3",
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: "From address (optional)",
                            dense: "",
                            outlined: ""
                          },
                          model: {
                            value: _vm.emailBox.fromEmail,
                            callback: function($$v) {
                              _vm.$set(_vm.emailBox, "fromEmail", $$v)
                            },
                            expression: "emailBox.fromEmail"
                          }
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "h-outline" },
                      [
                        _c("editor", {
                          ref: "editor",
                          staticClass: "pa-2 text-left",
                          staticStyle: {
                            width: "100%",
                            "min-height": "100px",
                            "border-bottom": "none"
                          },
                          attrs: {
                            "api-key": _vm.$store.getters.getTinyMceKey,
                            "initial-value": _vm.emailBox.signature,
                            inline: false,
                            init: _vm.mceConfigText,
                            spellcheck: true,
                            placeholder: "Signature"
                          },
                          on: { input: _vm.handleSignatureUpdated }
                        })
                      ],
                      1
                    ),
                    _vm.showConfig
                      ? _c(
                          "div",
                          { staticClass: "column-format gap-3 mt-3" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                label: "IMAP Server",
                                "persistent-placeholder": "",
                                "hide-details": "",
                                dense: "",
                                outlined: ""
                              },
                              model: {
                                value: _vm.emailBox.host,
                                callback: function($$v) {
                                  _vm.$set(_vm.emailBox, "host", $$v)
                                },
                                expression: "emailBox.host"
                              }
                            }),
                            _c("v-text-field", {
                              attrs: {
                                label: "IMAP Port",
                                type: "number",
                                "persistent-placeholder": "",
                                "hide-details": "",
                                dense: "",
                                outlined: ""
                              },
                              model: {
                                value: _vm.emailBox.port,
                                callback: function($$v) {
                                  _vm.$set(_vm.emailBox, "port", $$v)
                                },
                                expression: "emailBox.port"
                              }
                            }),
                            _c("v-text-field", {
                              attrs: {
                                label: "SMTP Server",
                                "persistent-placeholder": "",
                                "hide-details": "",
                                dense: "",
                                outlined: ""
                              },
                              model: {
                                value: _vm.emailBox.smtpHost,
                                callback: function($$v) {
                                  _vm.$set(_vm.emailBox, "smtpHost", $$v)
                                },
                                expression: "emailBox.smtpHost"
                              }
                            }),
                            _c("v-text-field", {
                              attrs: {
                                label: "SMTP Port",
                                type: "number",
                                "persistent-placeholder": "",
                                "hide-details": "",
                                dense: "",
                                outlined: ""
                              },
                              model: {
                                value: _vm.emailBox.smtpPort,
                                callback: function($$v) {
                                  _vm.$set(_vm.emailBox, "smtpPort", $$v)
                                },
                                expression: "emailBox.smtpPort"
                              }
                            }),
                            _c("v-select", {
                              attrs: {
                                label: "Connection Security",
                                "persistent-placeholder": "",
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                items: _vm.connectionSecurity
                              },
                              model: {
                                value: _vm.emailBox.connectionSecurity,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.emailBox,
                                    "connectionSecurity",
                                    $$v
                                  )
                                },
                                expression: "emailBox.connectionSecurity"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.appSpecificPasswordLink
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "font-gray_80 font-12 row-format align-center text-left my-5"
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("$info")
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "ml-2",
                                staticStyle: { "max-width": "300px" }
                              },
                              [
                                _vm._v(
                                  " Your provider requires you to use an App Specific password. Do NOT enter your actual password into the above field. "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.appSpecificPasswordLink,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "Click here to view instructions for creating an app specific password."
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm.showSettings
                  ? _c(
                      "div",
                      { staticClass: "mb-5 mt-3" },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.zeroToSixty,
                            "persistent-placeholder": "",
                            "hide-details": "",
                            dense: "",
                            outlined: "",
                            label: "Email sync frequency"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_vm._v(_vm._s(item) + " minutes")]
                                }
                              },
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_vm._v(_vm._s(item) + " minutes")]
                                }
                              }
                            ],
                            null,
                            false,
                            3178122492
                          ),
                          model: {
                            value: _vm.emailBox.fetchFrequencyMinutes,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.emailBox,
                                "fetchFrequencyMinutes",
                                $$v
                              )
                            },
                            expression: "emailBox.fetchFrequencyMinutes"
                          }
                        }),
                        _c("v-switch", {
                          attrs: {
                            "hide-details": "",
                            dense: "",
                            label: "Default email"
                          },
                          model: {
                            value: _vm.emailBox.defaultEmailBox,
                            callback: function($$v) {
                              _vm.$set(_vm.emailBox, "defaultEmailBox", $$v)
                            },
                            expression: "emailBox.defaultEmailBox"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            "hide-details": "",
                            dense: "",
                            label: "Sync enabled"
                          },
                          model: {
                            value: _vm.emailBox.enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.emailBox, "enabled", $$v)
                            },
                            expression: "emailBox.enabled"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            "hide-details": "",
                            dense: "",
                            label: "Shared inbox"
                          },
                          model: {
                            value: _vm.emailBox.sharedInbox,
                            callback: function($$v) {
                              _vm.$set(_vm.emailBox, "sharedInbox", $$v)
                            },
                            expression: "emailBox.sharedInbox"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "font-12 font-gray_70 text-left",
                            staticStyle: { "max-width": "350px" }
                          },
                          [
                            _vm._v(
                              " When shared inbox is selected, other members of the team will see emails from this account in their inbox. "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.verificationStatus
                  ? _c(
                      "div",
                      { staticClass: "text-left font-14 mt-auto font-gray_80" },
                      [
                        _c("span", { staticClass: "brand-medium" }, [
                          _vm._v("Connection Status:")
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "row-format align-center",
                            staticStyle: { gap: "8px" }
                          },
                          [
                            _c("div", {}, [_vm._v("IMAP:")]),
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: "",
                                  color: _vm.verificationStatus.imapStatus
                                    ? "success"
                                    : "warning"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.verificationStatus.imapStatus
                                      ? "$check"
                                      : "$alert"
                                  )
                                )
                              ]
                            ),
                            _c("div", [
                              _vm._v(_vm._s(_vm.verificationStatus.imapMessage))
                            ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "row-format align-center",
                            staticStyle: { gap: "8px" }
                          },
                          [
                            _c("div", [_vm._v("SMTP:")]),
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: "",
                                  color: _vm.verificationStatus.smtpStatus
                                    ? "success"
                                    : "warning"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.verificationStatus.smtpStatus
                                      ? "$check"
                                      : "$alert"
                                  )
                                )
                              ]
                            ),
                            _c("div", [
                              _vm._v(_vm._s(_vm.verificationStatus.smtpMessage))
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm.filteredFolders && _vm.emailBox.id
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "text-left" },
                      [
                        _c("h3", [_vm._v("Enabled folders")]),
                        _vm._l(_vm.filteredFolders, function(folder) {
                          return _c(
                            "div",
                            {
                              key: folder,
                              staticClass: "row-format align-center my-1"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.emailBox.folders,
                                    expression: "emailBox.folders"
                                  }
                                ],
                                staticClass: "big-checkbox",
                                attrs: { id: folder, type: "checkbox" },
                                domProps: {
                                  value: folder,
                                  checked: Array.isArray(_vm.emailBox.folders)
                                    ? _vm._i(_vm.emailBox.folders, folder) > -1
                                    : _vm.emailBox.folders
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.emailBox.folders,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = folder,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.emailBox,
                                            "folders",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.emailBox,
                                            "folders",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.emailBox, "folders", $$c)
                                    }
                                  }
                                }
                              }),
                              _c("div", [
                                _c(
                                  "label",
                                  {
                                    staticClass: "font-14 ml-2 pointer",
                                    attrs: { for: folder }
                                  },
                                  [_vm._v(_vm._s(folder))]
                                )
                              ])
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e()
            ]),
            _vm.emailBox.id && _vm.emailBox.folders.length === 0
              ? _c(
                  "div",
                  {
                    staticClass: "text-left row-format font-12",
                    staticStyle: { "max-width": "450px" }
                  },
                  [
                    _c("v-icon", { attrs: { color: "warning" } }, [
                      _vm._v("$alert")
                    ]),
                    _c("div", { staticClass: "ml-2" }, [
                      _vm._v(
                        " You must choose one or more folders to target for mail sync. For most mail providers you want your Inbox and Sent items. "
                      )
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.emailBox.id || !_vm.selectedProvider.oauth
            ? _c(
                "div",
                {
                  staticClass: "mt-2 pa-2 row-format centered gap-2",
                  staticStyle: {
                    "border-top": "1px solid var(--v-gray_30-base)"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "super-action",
                      attrs: { width: "160" },
                      on: {
                        click: function($event) {
                          return _vm.saveEmailBox()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              _vm.id === null
                                ? "global.create"
                                : "global.update"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.confirmDelete()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "20", color: "" }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }