var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.logo
        ? _c(
            "vue-dropzone",
            {
              ref: "fileDropzone",
              staticClass: "ma-3",
              attrs: {
                id: "dropzone",
                "use-custom-slot": true,
                options: _vm.dropzoneOptions
              },
              on: {
                "vdropzone-success": _vm.uploadSuccess,
                "vdropzone-error": _vm.uploadFailure,
                "vdropzone-sending": _vm.addFileUploadAuthHeader
              }
            },
            [
              _c(
                "div",
                [
                  _c("v-icon", [_vm._v("$vuetify.icons.upload")]),
                  _c("br"),
                  _c(
                    "span",
                    {
                      staticClass: "ml-2",
                      staticStyle: { "font-size": "0.8em" }
                    },
                    [_vm._v(_vm._s(_vm.$t("account.logo-upload")))]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.logo
        ? _c(
            "div",
            {
              staticClass: "accountLogo",
              staticStyle: { position: "relative" }
            },
            [
              _c("v-img", {
                staticStyle: { "max-width": "250px" },
                attrs: { src: _vm.logo }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "delete logoDelete",
                  staticStyle: {
                    position: "absolute",
                    top: "35%",
                    left: "35%"
                  },
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.deleteAccountLogo(true)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("$delete")])],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }