<template>
	<div>
		<component
				:key="item.fieldType"
				:is="item.fieldType"
				:update-key="updateFlag"
				:preview-mode="previewMode"
				v-bind="item"
		></component>
	</div>
</template>

<script>
	import CheckboxInput from "@/modules/discovery/formrenderer/CheckboxInput";
	import RadioInput from "@/modules/discovery/formrenderer/RadioInput";
	import SelectInput from "@/modules/discovery/formrenderer/SelectInput";
	import TextBlock from "@/modules/discovery/formrenderer/TextBlock";
	import TextInput from "@/modules/discovery/formrenderer/TextInput";
	import LeadContact from "@/modules/discovery/formrenderer/LeadContact";
	import DateInput from "@/modules/discovery/formrenderer/DateInput";
	import FileInput from "@/modules/discovery/formrenderer/FileInput";

	export default {
		name: 'Renderer',

		props: ['item','updateFlag','previewMode','editorIndex'],

		components: {CheckboxInput,RadioInput,SelectInput,TextBlock,TextInput,LeadContact, DateInput, FileInput},

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
		},
	};
</script>

<style scoped lang="scss">

</style>
