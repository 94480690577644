var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.filter
      ? _c(
          "div",
          [
            _c(
              "v-menu",
              {
                staticStyle: { "overflow-y": "auto" },
                attrs: {
                  "max-height": "90vh",
                  "max-width": "250",
                  "close-on-content-click": false,
                  "close-on-click": true,
                  "content-class": "filter-menu-class"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticStyle: {
                                  "min-height": "40px",
                                  "background-color": "var(--v-white-base)"
                                },
                                attrs: {
                                  elevation: "0",
                                  outlined: "",
                                  color: "gray_30"
                                }
                              },
                              on
                            ),
                            [
                              _c("span", {
                                class:
                                  "font-14 " +
                                  (!_vm.isDefaultFilter
                                    ? "font-secondary"
                                    : "font-gray_60") +
                                  " brand-medium",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.isDefaultFilter
                                      ? _vm.filterLabel
                                      : _vm.filterDescription
                                  )
                                }
                              }),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    "x-small": "",
                                    color: !_vm.isDefaultFilter
                                      ? "secondary"
                                      : "gray_80"
                                  }
                                },
                                [_vm._v("$arrowDown")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1051558667
                ),
                model: {
                  value: _vm.menuOpen,
                  callback: function($$v) {
                    _vm.menuOpen = $$v
                  },
                  expression: "menuOpen"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "column-format open-menu" },
                  [
                    _c("v-text-field", {
                      staticClass: "font-16",
                      attrs: {
                        id: "search-input",
                        autofocus: "",
                        solo: "",
                        flat: "",
                        "hide-details": "",
                        dense: "",
                        placeholder: "Filter..."
                      },
                      on: {
                        change: _vm.updated,
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          ) {
                            return null
                          }
                          _vm.menuOpen = false
                        }
                      },
                      model: {
                        value: _vm.filter.search,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "search", $$v)
                        },
                        expression: "filter.search"
                      }
                    }),
                    _c("filter-option", {
                      attrs: {
                        label: _vm.$t("client.filter.client-list"),
                        "option-list": _vm.clientList
                      },
                      on: { change: _vm.updated },
                      model: {
                        value: _vm.filter.clientList,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "clientList", $$v)
                        },
                        expression: "filter.clientList"
                      }
                    }),
                    _c("filter-option", {
                      attrs: {
                        label: _vm.$t("stripe.subscription.status"),
                        "option-list": _vm.subscriptionStatus
                      },
                      on: { change: _vm.updated },
                      model: {
                        value: _vm.filter.subscriptionStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "subscriptionStatus", $$v)
                        },
                        expression: "filter.subscriptionStatus"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "mx-3 mt-3 mb-2" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "filter-clear mt-2 mb-1",
                            attrs: {
                              text: "",
                              block: "",
                              color: "secondary",
                              elevation: "0"
                            },
                            on: {
                              click: function($event) {
                                return _vm.clearFilter()
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("$clear")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("timetrack.filter.clear-filter"))
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary-action",
                            attrs: { block: "" },
                            on: {
                              click: function($event) {
                                _vm.menuOpen = false
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("global.done")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }