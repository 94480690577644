var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "panel-modal", staticStyle: { width: "600px" } },
        [
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.saveTicket(true)
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1 material-symbols-rounded",
                    attrs: { color: "black" }
                  },
                  [_vm._v("local_activity")]
                ),
                _c("div", { staticClass: "brand-medium font-18" }, [
                  _vm._v("Ticket details")
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto row-format align-center gap-1" },
                  [
                    _vm.isReady
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "super-action",
                            on: {
                              click: function($event) {
                                return _vm.saveTicket(true)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                        )
                      : _vm._e(),
                    _c("pipeline-link-widget", {
                      attrs: {
                        "icon-only": true,
                        "item-id": _vm.ourTicket.id,
                        "item-type": "Request",
                        "client-id": _vm.ourTicket.clientId
                      }
                    }),
                    _c(
                      "v-menu",
                      {
                        attrs: { "nudge-bottom": "10" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._b(
                                      {
                                        directives: [
                                          { name: "tippy", rawName: "v-tippy" }
                                        ],
                                        staticClass:
                                          "pointer material-symbols-rounded",
                                        attrs: {
                                          size: "20",
                                          color: "black",
                                          content: "Merge ticket"
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.getMergeTickets()
                                          }
                                        }
                                      },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    [_vm._v("merge")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3900424751
                        ),
                        model: {
                          value: _vm.mergeMenu,
                          callback: function($$v) {
                            _vm.mergeMenu = $$v
                          },
                          expression: "mergeMenu"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-left pa-2 column-format gap-1",
                            staticStyle: {
                              "background-color": "var(--v-white-base)"
                            }
                          },
                          [
                            _c("div", { staticClass: "brand-medium" }, [
                              _vm._v("Merge into ticket:")
                            ]),
                            _c(
                              "div",
                              { staticClass: "more-menu" },
                              _vm._l(_vm.mergeTickets, function(ticket) {
                                return _c(
                                  "div",
                                  {
                                    key: ticket.id,
                                    staticClass: "more-menu-item",
                                    staticStyle: { padding: "0" },
                                    on: {
                                      click: function($event) {
                                        return _vm.mergeIntoTicket(ticket)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(ticket.ticketNumber) +
                                        " - " +
                                        _vm._s(ticket.subject) +
                                        " "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer material-symbols-rounded",
                        attrs: { size: "20", color: "gray_70" },
                        on: {
                          click: function($event) {
                            return _vm.confirmDelete()
                          }
                        }
                      },
                      [_vm._v("delete")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "panel-modal-body text-left" }, [
            _c("div", { attrs: { id: "ticket-detail" } }, [
              _c(
                "div",
                { staticClass: "row-format" },
                [
                  _c("v-text-field", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      placeholder: "Subject name"
                    },
                    model: {
                      value: _vm.ourTicket.subject,
                      callback: function($$v) {
                        _vm.$set(_vm.ourTicket, "subject", $$v)
                      },
                      expression: "ourTicket.subject"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-6 mb-4 row-format align-center gap-3" },
                [
                  _c(
                    "div",
                    {
                      class:
                        "page-link " +
                        (_vm.currentView === "Overview"
                          ? "page-link-active"
                          : ""),
                      on: {
                        click: function($event) {
                          return _vm.setView("Overview")
                        }
                      }
                    },
                    [_c("div", [_vm._v("Overview")])]
                  ),
                  _c(
                    "div",
                    {
                      class:
                        "page-link " +
                        (_vm.currentView === "Comments"
                          ? "page-link-active"
                          : ""),
                      on: {
                        click: function($event) {
                          return _vm.setView("Comments")
                        }
                      }
                    },
                    [_c("div", [_vm._v("Comments")])]
                  ),
                  _c(
                    "div",
                    {
                      class:
                        "page-link " +
                        (_vm.currentView === "Tasks" ? "page-link-active" : ""),
                      on: {
                        click: function($event) {
                          return _vm.setView("Tasks")
                        }
                      }
                    },
                    [_c("div", [_vm._v("Linked tasks")])]
                  ),
                  _c(
                    "div",
                    {
                      class:
                        "page-link " +
                        (_vm.currentView === "Summary"
                          ? "page-link-active"
                          : ""),
                      on: {
                        click: function($event) {
                          return _vm.setView("Summary")
                        }
                      }
                    },
                    [_c("div", [_vm._v("Summary")])]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "show-scrollbar",
                  staticStyle: {
                    "max-height": "calc(100vh - 210px)",
                    "overflow-y": "auto"
                  }
                },
                [
                  _vm.currentView === "Summary"
                    ? _c("div", { staticStyle: { width: "98%" } }, [
                        _vm.ourTicket.summary
                          ? _c("div", { staticClass: "text-left" }, [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.ourTicket.summary)
                                }
                              })
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "row-format justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "primary-action",
                                on: {
                                  click: function($event) {
                                    return _vm.generateSummary(false)
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "font-secondary" }, [
                                  _vm._v("Summarize ✨")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.currentView === "Overview"
                    ? _c(
                        "div",
                        { staticStyle: { width: "98%" } },
                        [
                          _vm.ourTicket.ticketNumber
                            ? _c(
                                "div",
                                { staticClass: "row-format align-center py-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "overview-left" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "material-symbols-rounded",
                                          attrs: { size: "20" }
                                        },
                                        [_vm._v("tag")]
                                      ),
                                      _c("div", { staticClass: "ml-2" }, [
                                        _vm._v("Number")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "overview-right" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.ourTicket.ticketNumber) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("group")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Client")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-click": true,
                                        "close-on-content-click": false,
                                        "offset-y": "",
                                        disabled:
                                          _vm.$store.getters
                                            .isAccountCollaborator
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    { staticClass: "pointer" },
                                                    on
                                                  ),
                                                  [
                                                    _vm.assignedClient
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row-format align-center"
                                                          },
                                                          [
                                                            _c(
                                                              "client-avatar",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  client:
                                                                    _vm.assignedClient,
                                                                  "disable-click": true
                                                                }
                                                              }
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ml-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .assignedClient
                                                                      .name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _c("div", [
                                                          _vm._v("--")
                                                        ])
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1881710377
                                      ),
                                      model: {
                                        value: _vm.clientMenu,
                                        callback: function($$v) {
                                          _vm.clientMenu = $$v
                                        },
                                        expression: "clientMenu"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "column-format pa-2",
                                          staticStyle: {
                                            "background-color":
                                              "var(--v-white-base)"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "",
                                              dense: "",
                                              outlined: "",
                                              placeholder: "Filter...",
                                              autofocus: ""
                                            },
                                            model: {
                                              value: _vm.clientFilter,
                                              callback: function($$v) {
                                                _vm.clientFilter = $$v
                                              },
                                              expression: "clientFilter"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-left column-format pt-1 mt-2 show-scrollbar",
                                              staticStyle: {
                                                "max-height": "300px",
                                                "overflow-y": "scroll"
                                              }
                                            },
                                            _vm._l(
                                              _vm.filteredClientList,
                                              function(client) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: client.id,
                                                    staticClass:
                                                      "row-format align-center px-3 py-2 pointer hover-gray",
                                                    staticStyle: {
                                                      "border-bottom":
                                                        "1px solid var(--v-gray_30-base)"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.ourTicket.clientId =
                                                          client.id
                                                        _vm.clientMenu = false
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("client-avatar", {
                                                      attrs: {
                                                        client: client,
                                                        "x-small": ""
                                                      }
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-2 brand-medium"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(client.name)
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("person")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Requester")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c("v-select", {
                                    staticClass: "ml-n3 mt-n2",
                                    attrs: {
                                      items: _vm.contactList,
                                      "item-value": "clientPortalUserId",
                                      "item-text": "fullName",
                                      "hide-details": "",
                                      dense: "",
                                      flat: "",
                                      solo: "",
                                      placeholder: "--"
                                    },
                                    model: {
                                      value: _vm.ourTicket.clientContactId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ourTicket,
                                          "clientContactId",
                                          $$v
                                        )
                                      },
                                      expression: "ourTicket.clientContactId"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("assignment_ind")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Assigned to")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-click": true,
                                        "close-on-content-click": false,
                                        "offset-y": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    { staticClass: "pointer" },
                                                    on
                                                  ),
                                                  [
                                                    _vm.ourTicket.assignedTo
                                                      .length
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "row-format align-center"
                                                          },
                                                          _vm._l(
                                                            _vm.ourTicket
                                                              .assignedTo,
                                                            function(
                                                              assignedTo,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "assigned-user",
                                                                _vm._g(
                                                                  {
                                                                    key: assignedTo,
                                                                    style:
                                                                      (index > 0
                                                                        ? "margin-left: -6px"
                                                                        : "") +
                                                                      "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                                                                      index,
                                                                    attrs: {
                                                                      "assigned-to": assignedTo,
                                                                      "show-name": false,
                                                                      "empty-label":
                                                                        "--"
                                                                    }
                                                                  },
                                                                  on
                                                                )
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        )
                                                      : _c("div", [
                                                          _vm._v("--")
                                                        ])
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1711950790
                                      )
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-left pa-3 font-14",
                                          staticStyle: {
                                            "background-color":
                                              "var(--v-white-base)"
                                          }
                                        },
                                        _vm._l(_vm.userList, function(user) {
                                          return _c(
                                            "div",
                                            {
                                              key: user.userId,
                                              staticClass:
                                                "row-format align-center text-left py-1 pointer"
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.ourTicket.assignedTo,
                                                    expression:
                                                      "ourTicket.assignedTo"
                                                  }
                                                ],
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px",
                                                  "border-radius": "4px"
                                                },
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  value: user.userId,
                                                  checked: Array.isArray(
                                                    _vm.ourTicket.assignedTo
                                                  )
                                                    ? _vm._i(
                                                        _vm.ourTicket
                                                          .assignedTo,
                                                        user.userId
                                                      ) > -1
                                                    : _vm.ourTicket.assignedTo
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.ourTicket
                                                          .assignedTo,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = user.userId,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.ourTicket,
                                                            "assignedTo",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.ourTicket,
                                                            "assignedTo",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.ourTicket,
                                                        "assignedTo",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              user.userId
                                                ? _c("assigned-user", {
                                                    staticClass: "mx-2",
                                                    attrs: {
                                                      "assigned-to":
                                                        user.userId,
                                                      "show-name": true
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("page_info")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Request type")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c("v-select", {
                                    staticClass: "ml-n3 mt-n2",
                                    attrs: {
                                      items: _vm.ticketSettings.ticketTypes,
                                      "item-value": "id",
                                      "item-text": "type",
                                      "hide-details": "",
                                      dense: "",
                                      flat: "",
                                      solo: "",
                                      placeholder: "--"
                                    },
                                    model: {
                                      value: _vm.ourTicket.type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.ourTicket, "type", $$v)
                                      },
                                      expression: "ourTicket.type"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("bolt")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Status")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c("v-select", {
                                    staticClass: "ml-n3 mt-n2",
                                    attrs: {
                                      items: _vm.statusOptions,
                                      "hide-details": "",
                                      dense: "",
                                      flat: "",
                                      solo: "",
                                      placeholder: "--"
                                    },
                                    model: {
                                      value: _vm.ourTicket.status,
                                      callback: function($$v) {
                                        _vm.$set(_vm.ourTicket, "status", $$v)
                                      },
                                      expression: "ourTicket.status"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("event")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Due date")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c("date-selector", {
                                    attrs: {
                                      micro: true,
                                      "show-year": true,
                                      "micro-font": "font-14",
                                      date: _vm.ourTicket.dueDate,
                                      label: "--"
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.ourTicket.dueDate = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("timer")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Time worked")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pointer",
                                      staticStyle: { width: "60px" },
                                      on: { click: _vm.openTimerDetails }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$formatters.formatSecondsFull(
                                              _vm.totalTimeWorked +
                                                _vm.runningTimeSeconds
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "tippy",
                                          rawName: "v-tippy",
                                          value: {
                                            content:
                                              "Add a time entry for this ticket"
                                          },
                                          expression:
                                            "{ content: 'Add a time entry for this ticket' }"
                                        }
                                      ],
                                      staticClass: "pointer ml-1",
                                      attrs: { size: "14" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.createTimerEvent.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("$plus")]
                                  ),
                                  !_vm.currentTimer
                                    ? _c(
                                        "v-icon",
                                        {
                                          directives: [
                                            {
                                              name: "tippy",
                                              rawName: "v-tippy",
                                              value: {
                                                content:
                                                  "Start timer for this ticket"
                                              },
                                              expression:
                                                "{ content: 'Start timer for this ticket' }"
                                            }
                                          ],
                                          staticClass: "pointer ml-1",
                                          attrs: { size: "14" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.startRunningTimer.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("$play")]
                                      )
                                    : _vm._e(),
                                  _vm.currentTimer
                                    ? _c(
                                        "v-icon",
                                        {
                                          directives: [
                                            {
                                              name: "tippy",
                                              rawName: "v-tippy",
                                              value: {
                                                content:
                                                  "Stop running timer for this ticket"
                                              },
                                              expression:
                                                "{ content: 'Stop running timer for this ticket' }"
                                            }
                                          ],
                                          staticClass: "pointer ml-1",
                                          attrs: { size: "14", color: "red" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.stopRunningTimer.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("$stop")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("schedule")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Ticket created")
                                  ])
                                ],
                                1
                              ),
                              _c("div", { staticClass: "overview-right" }, [
                                _c("span", { staticClass: "brand-medium" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.HDateTime.fromISO(
                                        _vm.ourTicket.created
                                      ).toLocaleString(
                                        _vm.HDateTime.DATETIME_FULL
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("update")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Last updated")
                                  ])
                                ],
                                1
                              ),
                              _c("div", { staticClass: "overview-right" }, [
                                _c("span", { staticClass: "brand-medium" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.HDateTime.fromISO(
                                        _vm.ourTicket.updated
                                      ).toLocaleString(
                                        _vm.HDateTime.DATETIME_FULL
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "18" }
                                    },
                                    [_vm._v("mail")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("CC List")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "overview-right gap-2",
                                  staticStyle: {
                                    "flex-wrap": "wrap",
                                    "line-height": "0.8"
                                  }
                                },
                                [
                                  _vm._l(_vm.ourTicket.ccList, function(
                                    cc,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "brand-medium row-format justify-end cc"
                                      },
                                      [
                                        _c("div", [_vm._v(_vm._s(cc))]),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "delete pointer",
                                            attrs: {
                                              color: "error",
                                              size: "16"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.confirmDeleteCC(cc)
                                              }
                                            }
                                          },
                                          [_vm._v("delete ")]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "font-primary pointer",
                                      on: { click: _vm.confirmAddCC }
                                    },
                                    [_vm._v("+ add")]
                                  )
                                ],
                                2
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "row-format align-center py-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "overview-left" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("done_all")]
                                  ),
                                  _c("div", { staticClass: "ml-2" }, [
                                    _vm._v("Mark complete")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "overview-right" },
                                [
                                  _c("v-switch", {
                                    staticClass: "mb-0 mt-n1",
                                    attrs: {
                                      "hide-details": "",
                                      dense: "",
                                      "true-value": false,
                                      "false-value": true
                                    },
                                    model: {
                                      value: _vm.ourTicket.open,
                                      callback: function($$v) {
                                        _vm.$set(_vm.ourTicket, "open", $$v)
                                      },
                                      expression: "ourTicket.open"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.ourTicket.formData &&
                          _vm.ourTicket.formData.answers.length
                            ? _c(
                                "v-container",
                                {
                                  staticClass: "font-14 mt-5",
                                  staticStyle: {
                                    border: "1px solid var(--v-gray_50-base)",
                                    "border-radius": "4px"
                                  }
                                },
                                _vm._l(_vm.ourTicket.formData.answers, function(
                                  answer,
                                  index
                                ) {
                                  return _c(
                                    "v-row",
                                    { key: index, attrs: { dense: "" } },
                                    [
                                      _c("v-col", { attrs: { cols: "12" } }, [
                                        _c("div", {
                                          staticClass: "font-gray_70",
                                          domProps: {
                                            innerHTML: _vm._s(answer.question)
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "white-space": "pre-wrap"
                                            }
                                          },
                                          [_vm._v(_vm._s(answer.answer))]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentView === "Comments"
                    ? _c(
                        "div",
                        { staticClass: "pb-2", staticStyle: { width: "98%" } },
                        [
                          _c("editor", {
                            ref: "commentEditor",
                            attrs: {
                              init: _vm.mceConfig,
                              rows: "1",
                              "auto-grow": ""
                            },
                            model: {
                              value: _vm.comment,
                              callback: function($$v) {
                                _vm.comment = $$v
                              },
                              expression: "comment"
                            }
                          }),
                          _c(
                            "div",
                            {
                              on: {
                                drop: function($event) {
                                  $event.preventDefault()
                                  return _vm.addDropFile.apply(null, arguments)
                                },
                                dragover: function($event) {
                                  $event.preventDefault()
                                }
                              }
                            },
                            [
                              _c("v-file-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "requests.upload-attachments"
                                  ),
                                  label: _vm.$t("requests.attachments"),
                                  multiple: "",
                                  "hide-details": "",
                                  "prepend-icon": "attach_file"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var text = ref.text
                                        return [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                label: "",
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v(" " + _vm._s(text) + " ")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  590729088
                                ),
                                model: {
                                  value: _vm.attachments,
                                  callback: function($$v) {
                                    _vm.attachments = $$v
                                  },
                                  expression: "attachments"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "row-format my-5",
                              staticStyle: { gap: "12px" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "super-action",
                                  attrs: {
                                    disabled: !_vm.comment,
                                    width: "200"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addComment()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("requests.add-comment")) + " "
                                  )
                                ]
                              ),
                              _c("v-checkbox", {
                                attrs: {
                                  label: "Private comment",
                                  dense: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.privateComment,
                                  callback: function($$v) {
                                    _vm.privateComment = $$v
                                  },
                                  expression: "privateComment"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticStyle: { height: "16px" } }),
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                _vm._l(_vm.comments, function(comment) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: comment.id,
                                      staticClass: "text-left py-2 mb-2",
                                      style:
                                        "border-bottom: 1px solid var(--v-gray_30-base); " +
                                        (comment.commentType === "PRIVATE"
                                          ? "background-color: var(--v-yellow_10-base); border-radius: 8px"
                                          : ""),
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-12 font-gray_70 row-format"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getCommentBy(
                                                  comment.commentBy
                                                )
                                              ) +
                                              " on " +
                                              _vm._s(
                                                _vm.HDateTime.fromISO(
                                                  comment.created
                                                ).toLocaleString(
                                                  _vm.HDateTime.DATETIME_FULL
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "ml-auto" },
                                            [
                                              comment.commentType === "PRIVATE"
                                                ? _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tippy",
                                                          rawName: "v-tippy",
                                                          value: {
                                                            content:
                                                              "Private comment"
                                                          },
                                                          expression:
                                                            "{ content: 'Private comment' }"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "material-symbols-rounded font-14"
                                                    },
                                                    [_vm._v("visibility_off")]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      ),
                                      comment.updated
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-12 font-gray_70"
                                            },
                                            [
                                              _vm._v(
                                                " Updated: " +
                                                  _vm._s(
                                                    _vm.HDateTime.fromISO(
                                                      comment.updated
                                                    ).toLocaleString(
                                                      _vm.HDateTime
                                                        .DATETIME_FULL
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          class:
                                            comment.commentFormat ===
                                            "PlainText"
                                              ? "text-pre-wrap"
                                              : ""
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "font-14",
                                            domProps: {
                                              innerHTML: _vm._s(comment.comment)
                                            }
                                          })
                                        ]
                                      ),
                                      comment.attachments &&
                                      comment.attachments.length
                                        ? _c(
                                            "div",
                                            { staticClass: "py-2" },
                                            _vm._l(
                                              comment.attachments,
                                              function(file) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: file.fileName,
                                                    staticClass:
                                                      "row-format align-center mb-2"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-format align-center attachment-wrapper",
                                                        staticStyle: {
                                                          position: "relative"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "buttonOverlay row-format align-center gap-2 justify-space-between"
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "pointer",
                                                                attrs: {
                                                                  size: "20"
                                                                },
                                                                nativeOn: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.downloadFile(
                                                                      file
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " $download "
                                                                )
                                                              ]
                                                            ),
                                                            _vm.isOpenableFile(
                                                              file
                                                            )
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "pointer",
                                                                    attrs: {
                                                                      size: "20"
                                                                    },
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.downloadFile(
                                                                          file,
                                                                          false
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "open_in_new"
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "material-symbols-rounded",
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "attach_file"
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "font-12"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                file.fileName
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentView === "Tasks"
                    ? _c(
                        "div",
                        { staticStyle: { width: "98%" } },
                        [
                          _c("linked-tasks", {
                            attrs: { ticket: _vm.ourTicket }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ])
          ]),
          _vm.aiDialog
            ? _c("ai-email-widget", {
                attrs: {
                  dialog: _vm.aiDialog,
                  mode: "draft",
                  placeholder:
                    "Tell me a little about what your response should say"
                },
                on: {
                  cancel: function($event) {
                    _vm.aiDialog = false
                  },
                  result: function($event) {
                    return _vm.runAiAssist($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }