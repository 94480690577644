<template>
	<div v-if="isReady">
		<page-header
			class="pb-3"
			:compact="false"
			:bread-crumbs="[
				{ to: '/home', label: 'Home' },
				{ label: 'Project management' },
				{ to: '/projects/list', label: 'Project list' },
				{ to: `/client/${project.client.id}`, label: project.client.name}
			]"
		>
			{{ project.name }}
		</page-header>

		<div id="project-detail-view">
			<div id="detail-wrapper" class="align-center">
				<div class="row-format">
					<div class="row-format gap-3" style="flex-wrap: wrap">
						<div
							:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
							v-for="page in pages"
							:key="page.value"
							@click.stop="setView(page.value, $event)"
						>
							<div>{{ page.label }}</div>
						</div>
					</div>
					<div class="ml-auto">
						<div class="row-format align-center">
							<v-menu
								v-if="currentView === 'Overview'"
								:nudge-bottom="0"
								:elevation="0"
								content-class="add-new-menu"
								bottom
								left
								rounded
								offset-overflow
								offset-y
								transition="slide-y-transition"
							>
								<template v-slot:activator="{ on }">
									<v-btn class="super-action" v-on="on"><v-icon size="20">add</v-icon> Add</v-btn>
								</template>
								<div class="add-new-dropdown">
									<div
										v-for="(item, index) in addNew"
										:key="index"
										@click="handleAddNew(item.value)"
										class="add-new-item"
									>
										{{ item.label }}
									</div>
								</div>
							</v-menu>
							<v-btn v-else-if="currentView !== 'Meetings'" class="super-action" @click="handleAddNewFromTab"
								><v-icon size="20">add</v-icon> Add</v-btn
							>
						</div>
					</div>
				</div>

				<div class="body-content mt-6 column-format" style="" :key="refreshKey">
					<project-overview
						:project="project"
						v-if="currentView === 'Overview'"
						@edit-project="editProject()"
						@payment-clicked="setView('Invoices')"
						@time-clicked="setView('Time')"
						@view-deliverables="setView('Tasks')"
						@change="saveProject()"
					>
					</project-overview>

					<project-management ref="Tasks" v-if="currentView === 'Tasks'" :project="project"></project-management>

					<project-notes ref="Notes" v-if="currentView === 'Notes'" :project="project" :filter="filters[currentView]">
						<template v-slot:filter>
							<project-filter
								:key="refreshKey"
								v-model="filters[currentView]"
								:current-view="currentView"
								@clear-filter="clearFilter(currentView)"
							></project-filter>
						</template>
					</project-notes>

					<timer-event-list
						ref="Time"
						:project="project"
						v-if="currentView === 'Time'"
						:filter="filters[currentView]"
					>
						<template v-slot:filter>
							<project-filter
									:key="refreshKey"
									v-model="filters[currentView]"
									:current-view="currentView"
									@clear-filter="clearFilter(currentView)"
							></project-filter>
						</template>
					</timer-event-list>

					<invoice-list
						ref="Invoices"
						:project="project"
						v-if="currentView === 'Invoices'"
						:filter="filters[currentView]"
					>
						<template v-slot:filter>
							<project-filter
									:key="refreshKey"
									v-model="filters[currentView]"
									:current-view="currentView"
									@clear-filter="clearFilter(currentView)"
							></project-filter>
						</template>
					</invoice-list>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ProjectService from '@/modules/projects/ProjectService';
	import ProjectOverview from '@/modules/projects/detail/overview/ProjectOverview';
	import TimerEventList from '@/modules/projects/detail/timetracking/TimerEventList';
	import InvoiceList from '@/modules/projects/detail/invoices/InvoiceList';
	import ProjectEdit from '@/modules/projects/detail/ProjectEdit';
	import ProjectFilter from '@/modules/projects/detail/filter/ProjectFilter';
	import ProjectManagement from '@/modules/projects/management/ProjectManagement';
	import ProjectNotes from '@/modules/projects/detail/notes/ProjectNotes';
	import PageHeader from '@/components/PageHeader';

	export default {
		name: 'ProjectDetailView',

		props: ['projectId', 'v'],

		components: {
			ProjectNotes,
			ProjectManagement,
			TimerEventList,
			ProjectOverview,
			InvoiceList,
			ProjectFilter,
			PageHeader,
		},

		data: function() {
			return {
				projectService: new ProjectService(),
				project: null,
				isReady: false,
				currentView: this.v ? this.v : 'Overview',
				refreshKey: 0,
				needsSave: false,
				intervalId: null,

				filters: {
					Deliverables: {},
					Invoices: {},
					Time: {},
				},
			};
		},

		mounted() {
			this.loadFilters();
		},

		beforeDestroy() {
			clearInterval(this.intervalId);
		},

		methods: {
			routeToClient: function(event) {
				if (this.$store.getters.isAccountFullUser) {
					event.stopPropagation();
					if (event && (event.ctrlKey || event.metaKey)) {
						window.open(`/client/${this.project.clientId}`, '_blank');
					} else {
						this.$router.push(`/client/${this.project.clientId}`);
					}
				}
			},

			saveProject: function() {
				this.projectService.updateProject(this.project.id, this.project);
			},

			getProject: function() {
				this.projectService.getProjectWithDeliverables(this.projectId).then((res) => {
					this.project = res.data;
					this.isReady = true;
					this.refreshKey++;
					this.$store.commit('setNavDetailKey', {
						key: 'projectName',
						value: this.project.name,
						parent: this.project.client.name,
						parentRoute: `/client/${this.project.client.id}`,
					});

					if (this.$route.query.edit === 'true') {
						this.$nextTick(() => this.editProject());
						window.history.pushState({}, document.title, this.$router.currentRoute.path);
					}

					if (this.$route.query.autoCreate === 'true') {
						this.handleAddNew(this.v);
					}
				});
			},

			editProject: function() {
				let binding = {
					projectId: this.project.id,
				};

				this.$store.state.globalModalController.openModal(ProjectEdit, binding).then((res) => {
					if (res) {
						if (res.deleted) {
							setTimeout(() => this.$router.push(`/client/${this.project.clientId}`), 500);
						} else {
							this.getProject();
						}
					}
				});
			},

			handleAddNewFromTab: function() {
				this.$refs[this.currentView].addNew();
			},

			handleAddNew: function(type) {
				this.currentView = type;
				this.$nextTick(() => this.$refs[type].addNew());
			},

			loadFilters: function() {
				let storedFilters = localStorage.getItem('PROJECT_DETAIL_FILTERS');
				if (storedFilters) {
					this.filters = JSON.parse(storedFilters);
					if (!Object.prototype.hasOwnProperty.call(this.filters, 'Notes')) {
						this.clearFilter('Notes', true);
					}
				} else {
					this.initFilter();
				}
			},

			saveFilters: function() {
				if (this.filters) {
					localStorage.setItem('PROJECT_DETAIL_FILTERS', JSON.stringify(this.filters));
				}
			},

			initFilter: function() {
				this.clearFilter('Time', true);
				this.clearFilter('Invoices', true);
				this.clearFilter('Tasks', true);
				this.clearFilter('Notes', true);
			},

			clearFilter: function(key, init = false) {
				this.filters[key] = this.emptyFilter(key, init);
				this.refreshKey++;
				if (!init) {
					this.filters = JSON.parse(JSON.stringify(this.filters));
				}

				if (key === 'Tasks') {
					this.filters[key].archiveStatus.push(false);
				}
			},

			emptyFilter: function() {
				let result = {
					search: null,
					dateSpecifier: null,
					earliest: null,
					latest: null,
					archiveStatus: [],
					invoiceStatus: [],
					timeEntryStatus: [],
				};
				return result;
			},

			setView: function(view, event) {
				this.$track.record('page-view', { module: `project-${view.toLowerCase()}` });
				if (event && (event.ctrlKey || event.metaKey)) {
					window.open(this.$router.currentRoute.path + `?v=${view}`, '_blank');
				} else {
					this.currentView = view;
					window.history.pushState({}, document.title, this.$router.currentRoute.path + `?v=${view}`);
				}
			},
		},

		computed: {
			isAccountFullUser: function() {
				return this.$store.getters.isAccountFullUser;
			},

			pages: function() {
				let result = [];

				result.push({ label: 'Overview', value: 'Overview' });
				result.push({ label: 'Tasks', value: 'Tasks' });
				result.push({ label: 'Notes', value: 'Notes' });
				if (this.$store.getters.hasInvoiceAccess) {
					result.push({ label: 'Invoices', value: 'Invoices' });
				}
				result.push({ label: 'Timesheets', value: 'Time' });

				return result;
			},

			addNew: function() {
				let result = [];
				result.push({ label: 'Time Entry', value: 'Time' });
				result.push({ label: 'Task', value: 'Tasks' });
				result.push({ label: 'Note', value: 'Notes' });
				if (this.isAccountFullUser) {
					result.push({ label: 'Invoice', value: 'Invoices' });
				}

				return result;
			},
		},

		watch: {
			filters: {
				deep: true,
				handler() {
					this.saveFilters();
				},
			},
			projectId: {
				immediate: true,
				handler() {
					this.$track.record('page-view', { module: 'project-detail' });
					this.getProject();
					this.refreshKey++;

					if (this.v) {
						this.currentView = this.v;
					}
				},
			},
			v: function(val) {
				if (val) {
					this.currentView = val;
				} else {
					this.currentView = 'Overview';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	#project-detail-view {
		//background-color: var(--v-white-base);
		//border: 1px solid var(--v-gray_30-base);
		//border-radius: 4px;
	}
</style>
