var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("h1", { staticClass: "mb-6" }, [
                _vm._v(_vm._s(_vm.$t("invoice.details.stripe-modal.heading")))
              ]),
              _c("stripe-connect", {
                attrs: { "stripe-integration": _vm.account.stripeIntegration }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "px-8 secondary-action",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("global.cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }