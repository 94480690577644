var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "525px" }, attrs: { id: "new-proposal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("result")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c(
          "div",
          { staticClass: "cloneList" },
          [
            _c("div", { staticClass: "modal-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("proposal.new.heading")) + " ")
            ]),
            _vm.showV1
              ? _c(
                  "v-tabs",
                  {
                    model: {
                      value: _vm.tab,
                      callback: function($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab"
                    }
                  },
                  [
                    _c("v-tab", [_vm._v("Agreements [v2]")]),
                    _c("v-tab", [_vm._v("Agreements [v1 - classic]")])
                  ],
                  1
                )
              : _vm._e(),
            _vm.tab === 1
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-outline d-flex align-start justify-start py-3 px-2 mt-4",
                      on: {
                        click: function($event) {
                          return _vm.freshStart("Proposal")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pr-3 pl-1" },
                        [_c("v-icon", [_vm._v("$proposals")])],
                        1
                      ),
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "div",
                          { staticClass: "brand-medium gray_90--text" },
                          [_vm._v(_vm._s(_vm.$t("proposal.new.proposal")))]
                        ),
                        _c(
                          "div",
                          { staticClass: "font-14 brand-semilight mt-1" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("proposal.new.proposal-caption"))
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-outline d-flex align-start justify-start py-3 px-2 mt-4",
                      on: {
                        click: function($event) {
                          return _vm.freshStart("Contract")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pr-3 pl-1" },
                        [_c("v-icon", [_vm._v("$signature")])],
                        1
                      ),
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "div",
                          { staticClass: "brand-medium gray_90--text" },
                          [_vm._v(_vm._s(_vm.$t("proposal.new.contract")))]
                        ),
                        _c(
                          "div",
                          { staticClass: "font-14 brand-semilight mt-1" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("proposal.new.contract-caption"))
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-outline d-flex align-start justify-start py-3 px-2 mt-4",
                      on: {
                        click: function($event) {
                          return _vm.freshStart("Quote")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pr-3 pl-1" },
                        [_c("v-icon", [_vm._v("$invoices")])],
                        1
                      ),
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "div",
                          { staticClass: "brand-medium gray_90--text" },
                          [_vm._v(_vm._s(_vm.$t("proposal.new.quote")))]
                        ),
                        _c(
                          "div",
                          { staticClass: "font-14 brand-semilight mt-1" },
                          [_vm._v(_vm._s(_vm.$t("proposal.new.quote-caption")))]
                        )
                      ])
                    ]
                  )
                ])
              : _c("div", { staticClass: "column-format gap-3 mt-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-outline d-flex align-start justify-start py-3 px-2",
                      on: {
                        click: function($event) {
                          return _vm.freshStart("Agreement")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pr-3 pl-1" },
                        [
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("handshake")
                          ])
                        ],
                        1
                      ),
                      _vm._m(0)
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-outline d-flex align-start justify-start py-3 px-2",
                      on: {
                        click: function($event) {
                          return _vm.freshStart("Agreement-Proposal")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pr-3 pl-1" },
                        [_c("v-icon", [_vm._v("$proposals")])],
                        1
                      ),
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "div",
                          { staticClass: "brand-medium gray_90--text" },
                          [_vm._v(_vm._s(_vm.$t("proposal.new.proposal")))]
                        ),
                        _c(
                          "div",
                          { staticClass: "font-14 brand-semilight mt-1" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("proposal.new.proposal-caption"))
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-outline d-flex align-start justify-start py-3 px-2",
                      on: {
                        click: function($event) {
                          return _vm.freshStart("Agreement-Contract")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pr-3 pl-1" },
                        [_c("v-icon", [_vm._v("$signature")])],
                        1
                      ),
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "div",
                          { staticClass: "brand-medium gray_90--text" },
                          [_vm._v(_vm._s(_vm.$t("proposal.new.contract")))]
                        ),
                        _c(
                          "div",
                          { staticClass: "font-14 brand-semilight mt-1" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("proposal.new.contract-caption"))
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-outline d-flex align-start justify-start py-3 px-2",
                      on: {
                        click: function($event) {
                          return _vm.freshStart("Agreement-Quote")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pr-3 pl-1" },
                        [_c("v-icon", [_vm._v("$invoices")])],
                        1
                      ),
                      _c("div", { staticClass: "text-left" }, [
                        _c(
                          "div",
                          { staticClass: "brand-medium gray_90--text" },
                          [_vm._v(_vm._s(_vm.$t("proposal.new.quote")))]
                        ),
                        _c(
                          "div",
                          { staticClass: "font-14 brand-semilight mt-1" },
                          [_vm._v(_vm._s(_vm.$t("proposal.new.quote-caption")))]
                        )
                      ])
                    ]
                  )
                ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-left" }, [
      _c("div", { staticClass: "brand-medium gray_90--text" }, [
        _vm._v("Blank agreement")
      ]),
      _c("div", { staticClass: "font-14 brand-semilight mt-1" }, [
        _vm._v(
          "Try the fully refreshed agreement module with a ton of great features and improved customization options."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }