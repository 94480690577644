import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class BandwidthService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
	}

	createParticipant(){
		return this.httpClient
			.get(`/bandwidth/voice/participant`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	makeOutboundCall(call){
		return this.httpClient
			.post(`/bandwidth/voice/rtcOutbound`,call)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	hangupCall(callId){
		return this.httpClient
			.post(`/bandwidth/voice/${callId}/hangup`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	startRecording(callId){
		return this.httpClient
			.post(`/bandwidth/voice/${callId}/startRecording`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	pauseRecording(callId){
		return this.httpClient
			.post(`/bandwidth/voice/${callId}/pauseRecording`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	acceptCall(callId,participantId){
		return this.httpClient
			.post(`/bandwidth/voice/${callId}/accept?participantId=${participantId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	tnSearch(state,city,areaCode){
		return this.httpClient
			.get(`/bandwidth/tn/search`,{
				params: {
					state:state,
					city:city,
					areaCode:areaCode
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	tnOrder(number){
		return this.httpClient
			.post(`/bandwidth/tn/order`,null,{
				params:{
					number:number
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
