<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-opportunity') " @action="openOpportunity()" :sent-at="sentAt">
		<template v-slot:header>
			<client-avatar x-small v-if="client" :client="client" class="mr-2"/>
			<div class="text-left">{{ opportunity.name }}</div>
		</template>

		<template v-slot:content>
			<div class="font-14 mb-1 brand-medium">{{ $t('notifications.opportunity-created') }}</div>
			<div class="font-12">
				<span>{{ sentAt | formatForTimeAgo }} </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";
	import DateTime from "@/modules/utils/HDateTime";
	import OpportunityDetail from "@/modules/pipeline/opportunity/OpportunityDetail";
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'OpportunityCreatedNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { NotificationLayout, ClientAvatar },

		data: function() {
			return {
				opportunity: this.notificationPayload.opportunity,
				DateTime:DateTime
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openOpportunity: function() {
				this.$store.state.globalModalController.openModal(OpportunityDetail, {id:this.opportunity.id});
			},
		},

		computed: {
			client: function(){
				if(this.opportunity.clientId){
					return this.$store.getters.getClientById(this.opportunity.clientId);
				}else{
					return null;
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
