<template>
	<div id="workflow-editor" class="fill-all" v-if="isReady">
		<div class="header-area pl-1 pr-3 row-format align-center">
			<div class="row-format gap-2 align-center">
				<v-text-field style="width:198px" v-model="workflow.name" placeholder="Workflow name" hide-details dense outlined></v-text-field>
				<v-select style="width:150px" :items="['Active','Disabled']" v-model="workflow.status" hide-details dense outlined></v-select>
				<v-btn icon @click="$refs.flow.findHome()"><v-icon class="material-symbols-rounded">home</v-icon></v-btn>
			</div>
			<div class="ml-auto row-format gap-1 align-center">
				<div v-if="workflow.publishedTimestamp" class="font-12 font-gray_70 mr-3">Published: {{$formatters.formatForTimeAgo(workflow.publishedTimestamp)}}</div>
				<v-btn class="primary-action" width="96" @click="save(false)">
					{{ $t('global.save') }}
				</v-btn>
				<v-btn class="super-action" :disabled="!workflow.draft" @click="confirmPublish">
					Publish
				</v-btn>
				<v-btn icon @click="save(true)"><v-icon>close</v-icon></v-btn>
			</div>
		</div>
		<div style="width: 100%; height: calc(100% - 56px)">
			<workflow ref="flow" :workflow="workflow.flow"></workflow>
		</div>
	</div>
</template>

<script>
	import Workflow from "@/modules/workflow/Workflow";
	import WorkflowService from "@/modules/workflow/WorkflowService";
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'WorkflowEditor',

		props: ['id'],

		components: { Workflow },

		data: function() {
			return {
				workflow: null,
				isReady: false,
				workflowService: new WorkflowService(),
			};
		},

		mounted() {
			this.loadWorkflow();
		},

		beforeDestroy() {},

		methods: {
			loadWorkflow: function(){
				this.workflowService.getWorkflow(this.id).then((res) => {
					this.workflow = res.data;
					this.isReady = true;
				})
			},

			doSave: function(){
				return new Promise((resolve,reject) => {
					this.workflow.flow  = this.$refs.flow.export();
					this.workflowService.updateWorkflow(this.id,this.workflow).then((res) => {
						resolve(res);
					}).catch((err) => reject(err));
				})
			},

			save: function(close=false){
				this.$store.commit('startLoading');
				this.doSave()
						.then((res) => {
							this.workflow = res.data;
							if(close){
								this.$emit('result',this.workflow);
							}
						})
						.finally(() => this.$store.commit('stopLoading'));
			},

			confirmPublish: function(){
				let binding = {
					headingText: 'Publish?',
					bodyText: 'Are you sure you want to publish this version of the workflow?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.saveAndPublish();
					}
				})
			},

			saveAndPublish: function(){
				this.$store.commit('startLoading');
				this.doSave()
						.then((res) => {
							this.workflow = res.data;
							this.workflowService.publishWorkflow(this.id).then((res) => {
								this.workflow.draft = res.data.draft;
								this.workflow.publishedTimestamp = res.data.publishedTimestamp;
							})
						}).finally(() => this.$store.commit('stopLoading'))
			},

			delete: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this workflow?  This cannot be undone.',
					confirmText: 'DELETE',
					confirmHint: 'Type the word DELETE to confirm'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.workflowService.deleteWorkflow(this.id).then(() => {
							this.workflow.deleted = true;
							this.$emit('result',this.workflow);
						})
					}
				})
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	#workflow-editor {
		background-color: var(--v-white-base);
		width: 100vw;
		height: 100vh;

		.header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_30-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
		}
	}
</style>
