var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-left font-14 column-format",
      staticStyle: { "min-height": "300px" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "row-format align-center font-14 brand-medium header py-1",
          staticStyle: { width: "100%" }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("back")
                }
              }
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("$chevronLeft")])],
            1
          ),
          _c("div", [_vm._v(_vm._s(_vm.participants.join(" & ")))]),
          _c(
            "div",
            { staticClass: "ml-auto delete" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "icon",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("delete")
                    }
                  }
                },
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v("$delete")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.messages
        ? _c(
            "div",
            {
              staticClass: "messages px-2",
              staticStyle: {
                "max-height": "calc(var(--vh) - 300px)",
                "overflow-y": "scroll"
              },
              attrs: { id: _vm.randomKey + "-communicator-messages" }
            },
            [
              _vm.messages.length >= _vm.recordsPerFetch
                ? _c(
                    "div",
                    {
                      staticClass:
                        "row-format centered pointer font-12 font-gray_70 mt-4"
                    },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("more-messages")
                            }
                          }
                        },
                        [_vm._v("Older messages")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.messages, function(message, ix) {
                return _c(
                  "div",
                  {
                    key: _vm.randomKey + "-" + message.id,
                    staticClass: "my-2 row-format"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "column-format",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _vm.showTimeStamp(ix)
                          ? _c(
                              "div",
                              {
                                class:
                                  "font-10 font-gray_70 mt-2 " +
                                  (message.direction === "Out"
                                    ? "ml-auto mr-1"
                                    : "ml-1")
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$formatters.formatTimeAgo(
                                        message.timestamp
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.participants.length > 1 &&
                        message.direction === "In"
                          ? _c(
                              "div",
                              { staticClass: "font-gray_70 font-12 mt-1 ml-1" },
                              [
                                _vm._v(
                                  _vm._s(_vm.participantMap.get(message.from))
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { class: message.direction },
                          [
                            message.message && message.direction === "In"
                              ? _c("div", [_vm._v(_vm._s(message.message))])
                              : _vm._e(),
                            message.message && message.direction === "Out"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: {
                                          content: _vm.getUserFirstName(
                                            message.userId
                                          )
                                        },
                                        expression:
                                          "{content: getUserFirstName(message.userId)}"
                                      }
                                    ]
                                  },
                                  [_vm._v(_vm._s(message.message))]
                                )
                              : _vm._e(),
                            _vm._l(message.s3Media, function(s3File) {
                              return _c(
                                "div",
                                { key: s3File.path + s3File.fileName },
                                [
                                  _c("s3-file-handler", {
                                    attrs: { "s3-file": s3File }
                                  })
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ]
                )
              })
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "mt-auto pt-2 px-2" }, [
        _c(
          "div",
          {
            on: {
              drop: function($event) {
                $event.preventDefault()
                return _vm.addDropFile.apply(null, arguments)
              },
              dragover: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("v-textarea", {
              attrs: {
                dense: "",
                "hide-details": "",
                outlined: "",
                rows: "1",
                "no-resize": "",
                "auto-grow": "",
                autofocus: ""
              },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  ) {
                    return null
                  }
                  $event.preventDefault()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  ) {
                    return null
                  }
                  return _vm.sendMessage.apply(null, arguments)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "font-secondary pointer py-1 pl-2",
                          staticStyle: {
                            "border-left": "1px solid var(--v-gray_50-base)",
                            "margin-top": "2px"
                          },
                          on: { click: _vm.sendMessage }
                        },
                        [_vm._v(" Send ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.newMessage,
                callback: function($$v) {
                  _vm.newMessage = $$v
                },
                expression: "newMessage"
              }
            }),
            _vm.attachment
              ? _c(
                  "div",
                  { staticClass: "row-format align-center mt-2" },
                  [
                    _c(
                      "v-chip",
                      { staticClass: "truncate", attrs: { small: "" } },
                      [_vm._v(_vm._s(_vm.attachment.name))]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer",
                        attrs: { color: "black", small: "" },
                        on: {
                          click: function($event) {
                            _vm.attachment = null
                          }
                        }
                      },
                      [_vm._v("close")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }