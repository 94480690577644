<template>
	<div class="row-format align-center font-14 font-gray_80">
		<v-btn icon :disabled="currentPage === 1" @click="previousPage"><v-icon color="black">arrow_left</v-icon></v-btn>
		<div class="brand-medium">Page {{currentPage}}/{{totalPages}}</div>
		<v-btn icon :disabled="currentPage === totalPages" @click="nextPage"><v-icon color="black">arrow_right</v-icon></v-btn>
	</div>
</template>

<script>
export default {
	name: "Pagination",

	props: ['totalPages','value'],

	components: {},

	data: function () {
		return {
			currentPage: this.value
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		previousPage: function(){
			this.currentPage--;
		},

		nextPage: function(){
			this.currentPage++;
		}
	},

	watch: {
		value: function(){
			this.currentPage = this.value;
		},

		currentPage: function(){
			this.$emit('input',this.currentPage);
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>