var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filter && _vm.statusList
    ? _c(
        "div",
        { attrs: { id: "calendar-filter" } },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "nudge-top": "20",
                bottom: "",
                left: "",
                rounded: "",
                "offset-overflow": "",
                "offset-y": "",
                "close-on-content-click": false,
                "content-class": "filter-menu-class"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticStyle: {
                                "min-height": "40px",
                                "background-color": "var(--v-white-base)"
                              },
                              attrs: {
                                elevation: "0",
                                outlined: "",
                                color: "gray_30"
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "span",
                              {
                                class:
                                  "font-14 " +
                                  (!_vm.isDefaultFilter
                                    ? "font-secondary"
                                    : "font-gray_60") +
                                  " brand-medium"
                              },
                              [_vm._v(_vm._s(_vm.filterLabel))]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  "x-small": "",
                                  color: !_vm.isDefaultFilter
                                    ? "secondary"
                                    : "gray_80"
                                }
                              },
                              [_vm._v("$arrowDown")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3356681210
              ),
              model: {
                value: _vm.showMenu,
                callback: function($$v) {
                  _vm.showMenu = $$v
                },
                expression: "showMenu"
              }
            },
            [
              _c(
                "div",
                { staticClass: "column-format open-menu" },
                [
                  _c("div", { staticClass: "filter-option-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row-format",
                        on: {
                          click: function($event) {
                            _vm.categoryExpanded = !_vm.categoryExpanded
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "font-14" }, [
                          _vm._v(_vm._s(_vm.$t("calendar.filter.categories")))
                        ]),
                        _c(
                          "v-icon",
                          {
                            staticStyle: {
                              "margin-left": "auto",
                              "margin-top": "1px"
                            },
                            attrs: { small: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.categoryExpanded ? "$arrowUp" : "$arrowDown"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.categoryExpanded
                      ? _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              staticClass: "mx-0 my-2 pa-0",
                              attrs: {
                                "hide-details": "",
                                indeterminate: _vm.categoryIndeterminate
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "client-label row-format pointer",
                                            staticStyle: {
                                              "align-content": "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "tree-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "calendar.filter.select-deselect"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3960988798
                              ),
                              model: {
                                value: _vm.categorySelected,
                                callback: function($$v) {
                                  _vm.categorySelected = $$v
                                },
                                expression: "categorySelected"
                              }
                            }),
                            _vm._l(_vm.categories, function(category) {
                              return _c("v-checkbox", {
                                key: category.value,
                                staticClass: "mx-0 my-2 pa-0",
                                attrs: {
                                  value: category.value,
                                  "hide-details": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "client-label row-format pointer align-center"
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "20",
                                                    color: "gray_80"
                                                  }
                                                },
                                                [_vm._v(_vm._s(category.icon))]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "tree-label ml-2"
                                                },
                                                [_vm._v(_vm._s(category.label))]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.filter.categories,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filter, "categories", $$v)
                                  },
                                  expression: "filter.categories"
                                }
                              })
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "filter-option-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row-format",
                        on: {
                          click: function($event) {
                            _vm.clientExpanded = !_vm.clientExpanded
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "font-14" }, [
                          _vm._v(_vm._s(_vm.$t("invoice.filter.client")))
                        ]),
                        _c(
                          "v-icon",
                          {
                            staticStyle: {
                              "margin-left": "auto",
                              "margin-top": "1px"
                            },
                            attrs: { small: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.clientExpanded ? "$arrowUp" : "$arrowDown"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.clientExpanded
                      ? _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              staticClass: "mx-0 my-2 pa-0",
                              attrs: {
                                "hide-details": "",
                                indeterminate: _vm.clientsIndeterminate
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "client-label row-format pointer",
                                            staticStyle: {
                                              "align-content": "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "tree-label" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "calendar.filter.select-deselect"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3960988798
                              ),
                              model: {
                                value: _vm.clientsSelected,
                                callback: function($$v) {
                                  _vm.clientsSelected = $$v
                                },
                                expression: "clientsSelected"
                              }
                            }),
                            _vm._l(_vm.clientList, function(client) {
                              return _c("v-checkbox", {
                                key: client.id,
                                staticClass: "mx-0 my-2 pa-0",
                                attrs: { value: client.id, "hide-details": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "client-label row-format pointer",
                                              staticStyle: {
                                                "align-content": "center"
                                              }
                                            },
                                            [
                                              _c("client-avatar", {
                                                attrs: {
                                                  small: true,
                                                  left: "",
                                                  client: client,
                                                  "disable-click": true
                                                }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "tree-label" },
                                                [_vm._v(_vm._s(client.name))]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.filter.clients,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filter, "clients", $$v)
                                  },
                                  expression: "filter.clients"
                                }
                              })
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]),
                  _c("filter-option", {
                    attrs: {
                      label: "User filter",
                      "option-list": _vm.userList
                    },
                    model: {
                      value: _vm.filter.users,
                      callback: function($$v) {
                        _vm.$set(_vm.filter, "users", $$v)
                      },
                      expression: "filter.users"
                    }
                  }),
                  _vm.showStatusBox
                    ? _c("div", { staticClass: "filter-option-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "row-format",
                            on: {
                              click: function($event) {
                                _vm.statusExpanded = !_vm.statusExpanded
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "font-14" }, [
                              _vm._v(_vm._s(_vm.$t("calendar.filter.status")))
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticStyle: {
                                  "margin-left": "auto",
                                  "margin-top": "1px"
                                },
                                attrs: { small: "" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.statusExpanded
                                      ? "$arrowUp"
                                      : "$arrowDown"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.statusExpanded
                          ? _c(
                              "div",
                              [
                                _c("v-checkbox", {
                                  staticClass: "mx-0 my-2 pa-0",
                                  attrs: {
                                    "hide-details": "",
                                    indeterminate: _vm.statusIndeterminate
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "client-label row-format pointer",
                                                staticStyle: {
                                                  "align-content": "center"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "tree-label" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "calendar.filter.select-deselect"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    3960988798
                                  ),
                                  model: {
                                    value: _vm.statusSelected,
                                    callback: function($$v) {
                                      _vm.statusSelected = $$v
                                    },
                                    expression: "statusSelected"
                                  }
                                }),
                                _vm._l(_vm.statusList, function(status) {
                                  return _c("v-checkbox", {
                                    key: status.id,
                                    staticClass: "mx-0 my-2 pa-0",
                                    attrs: {
                                      value: status.id,
                                      "hide-details": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "client-label row-format pointer align-center"
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "status-color",
                                                    style:
                                                      "--status-color:" +
                                                      status.hexColor
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tree-label ml-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(status.label)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.filter.deliverableStatus,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filter,
                                          "deliverableStatus",
                                          $$v
                                        )
                                      },
                                      expression: "filter.deliverableStatus"
                                    }
                                  })
                                }),
                                _c("v-switch", {
                                  attrs: {
                                    "hide-details": "",
                                    dense: "",
                                    label: "Show task start/due dates"
                                  },
                                  model: {
                                    value: _vm.filter.useTaskStartDates,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "useTaskStartDates",
                                        $$v
                                      )
                                    },
                                    expression: "filter.useTaskStartDates"
                                  }
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mx-3 mt-3 mb-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "filter-clear mb-2",
                          attrs: {
                            text: "",
                            block: "",
                            color: "secondary",
                            elevation: "0"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("reset-filter")
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("$clear")
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("timetrack.filter.clear-filter"))
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "super-action",
                          attrs: { color: "primary", block: "" },
                          on: {
                            click: function($event) {
                              return _vm.saveFilter()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("global.done")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }