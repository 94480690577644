var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.customFonts.length
        ? _c("v-autocomplete", {
            attrs: {
              items: _vm.customFonts,
              outlined: "",
              "hide-details": "",
              dense: "",
              "persistent-placeholder": "",
              label: _vm.label ? _vm.label : "Custom fonts"
            },
            on: {
              change: function($event) {
                return _vm.$emit("font-changed", _vm.selectedFont)
              }
            },
            model: {
              value: _vm.selectedFont,
              callback: function($$v) {
                _vm.selectedFont = $$v
              },
              expression: "selectedFont"
            }
          })
        : _c("font-picker", {
            staticClass: "font-picker",
            attrs: {
              "api-key": _vm.googleFontKey,
              "active-font": _vm.font,
              options: _vm.fontOptions
            },
            on: { change: _vm.handleFontChange }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }