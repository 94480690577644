<template>
	<div class="row-format" style="height: 100%; width: 100%; max-height: 100%;">
		<v-container
			fluid
			class="px-0 px-md-4 mt-0 show-scrollbar"
			:key="forceRenderIndex"
			style="width:100%; height: 100%; max-height: 100%; overflow-y: auto; background-color: var(--v-gray_20-base)"
		>
			<v-row dense>
				<v-col cols="12" id="invoice-wrapper">
					<div v-if="invoice && account">
						<!-- Remove this div at your peril -->
						<div
							id="invoice-renderer"
							ref="invoice-renderer"
							:style="`width:${$vuetify.breakpoint.width > 1000 ? 1000 : $vuetify.breakpoint.width}px`"
						>
							<!-- Header / Freelancer Info and Invoice Details -->
							<div id="header">
								<div
									class="column-format text-left"
									:style="$vuetify.breakpoint.smAndDown ? 'flex: 0 0 100%;' : 'flex: 0 0 50%;'"
								>
									<div v-if="documentHeader" class="font-20 inter-medium">{{ documentHeader }}</div>
									<div
										id="logo"
										v-if="logo"
										v-html="logo"
										@click="editable ? $emit('account-info-modal') : null"
										:class="editable ? 'pointer text-left' : 'text-left'"
									></div>
									<div v-else-if="editable">
										<div id="add-logo" @click="$emit('account-info-modal')">
											<div>
												<v-icon size="32" left class="mt-n1">$ribbon</v-icon>
											</div>
											<div class="font-12 text-left">{{ $t('invoice.upload-logo') }}</div>
										</div>
									</div>
								</div>

								<div id="details">
									<div :class="$vuetify.breakpoint.xsOnly ? 'column-format' : 'row-format'">
										<div>
											<div
												style="letter-spacing: -0.15px"
												:class="'mr-5 font-20 inter-medium' + (editable ? ' pointer' : '')"
												@click="editable ? $emit('invoice-number-format-modal') : null"
											>
												{{
													$t('invoice.invoice-number-appended', {
														invoiceNumber: invoice.invoiceNumberFormatted
															? invoice.invoiceNumberFormatted
															: invoice.invoiceNumberFormattedPreview,
													})
												}}
											</div>
										</div>
										<div
											:class="
												($vuetify.breakpoint.smAndUp ? 'ml-auto' : 'mt-2') +
													' font-20 inter-semibold text-right'
											"
											style="letter-spacing: -0.18px"
										>
											<div>{{ $formatters.dollars(invoice.total, true, true, invoice.currency) }}</div>
											<div
												class="font-12 font-gray_70"
												v-if="editMode && invoice.currency !== $store.state.defaultCurrency"
											>
												{{
													$formatters.dollars(
														invoice.localTotal ? invoice.localTotal : 0,
														true,
														true,
														$store.state.defaultCurrency
													)
												}}
											</div>
										</div>
									</div>
									<div
										v-if="invoice.purchaseOrder"
										@click="editable ? handlePurchaseOrderClick() : null"
										:class="'font-16' + (editable ? ' pointer' : '')"
									>
										{{
											$t('invoice.details.purchase-order-display', { purchaseOrder: invoice.purchaseOrder })
										}}
									</div>
									<div v-else-if="editable" @click="handlePurchaseOrderClick()" class="payment-link mb-0 mt-1">
										<span class="brand-regular">
											{{ $t('invoice.details.purchase-order-button') }}
										</span>
									</div>

									<v-menu
										:disabled="!editable || recurring"
										:close-on-content-click="true"
										:close-on-click="true"
										:min-width="250"
										:max-width="300"
										:nudge-bottom="28"
										:nudge-right="0"
										bottom
										left
										origin="bottom left"
									>
										<template v-slot:activator="{ on }">
											<div :class="'due-date mt-4' + (editable && !recurring ? ' editable' : '')" v-on="on">
												<div class="d-flex justify-space-between">
													<div>
														<div class="inter-semibold gray_80--text">
															{{ $t('invoice.details.issued') }}
														</div>
														<div class="font-14 font-gray_80 mt-1" v-if="recurring">
															[{{ $t('invoice.details.recurring-disclaimer') }}]
														</div>
														<div class="date-due-display">
															{{ $DateTime.shortDate(invoice.dateSent) }}
														</div>
													</div>
													<div class="ml-1">
														<v-btn v-if="editable && !recurring" icon rounded
															><v-icon>$edit</v-icon></v-btn
														>
													</div>
												</div>
											</div>
										</template>
										<v-date-picker
											:first-day-of-week="$store.getters.firstDayOfWeek"
											:value="invoice.dateSent"
											@input="invoice.dateSent = $event"
											:no-title="true"
											class="invoice-renderer-date-picker"
										></v-date-picker>
									</v-menu>
									<v-menu
										:disabled="!editable || recurring"
										v-model="dateDueMenu"
										:close-on-content-click="true"
										:close-on-click="true"
										:min-width="250"
										:max-width="300"
										:nudge-bottom="28"
										:nudge-right="0"
										bottom
										left
										origin="bottom left"
									>
										<template v-slot:activator="{ on }">
											<div :class="'due-date mt-4' + (editable && !recurring ? ' editable' : '')" v-on="on">
												<div class="d-flex justify-space-between">
													<div>
														<div class="inter-semibold gray_80--text">
															{{ $t('invoice.details.due') }}
														</div>
														<div
															class="font-14 brand-medium font-primary mt-1 pointer"
															v-if="recurring"
															@click.stop="handlePaymentTermsClick"
														>
															<div v-if="paymentDays > 0">
																{{ paymentDays }} days after invoice is sent
															</div>
															<div v-else>On receipt</div>
														</div>
														<div v-else :class="dateDueWarning + ' date-due-display'">
															{{ $DateTime.shortDate(dateDue) }}
															<v-btn
																v-tippy="{ content: 'Revert to default due date' }"
																icon
																x-small
																v-if="invoice.status === 'DRAFT' && invoice.dateDue"
																@click.stop="invoice.dateDue = null"
																><v-icon small>$close</v-icon></v-btn
															>
														</div>
													</div>
													<div class="ml-1">
														<v-btn v-if="editable && !recurring" icon rounded
															><v-icon>$edit</v-icon></v-btn
														>
													</div>
												</div>
											</div>
										</template>
										<v-date-picker
											:first-day-of-week="$store.getters.firstDayOfWeek"
											:value="dateDue"
											@input="invoice.dateDue = $event"
											:no-title="true"
											class="invoice-renderer-date-picker"
										></v-date-picker>
									</v-menu>
								</div>
							</div>
							<!-- Subheader / Client Info -->
							<div
								id="subheader"
								:class="$vuetify.breakpoint.xsOnly ? 'column-format' : 'd-flex justify-space-between'"
							>
								<div
									id="freelancer-address"
									:class="editable ? 'editable' : ''"
									v-if="invoice.accountInfo"
									@click="editable ? $emit('account-info-modal') : ''"
								>
									<div class="d-flex justify-space-between">
										<div class="inter-medium">{{ $t('invoice.from') }}</div>
										<div v-if="editable" class="mt-n1">
											<v-btn icon rounded @click="$emit('account-info-modal')"
												><v-icon>$edit</v-icon></v-btn
											>
										</div>
									</div>
									<div>{{ invoice.accountInfo.accountName }}</div>
									<div v-if="invoice.accountInfo.address1">{{ invoice.accountInfo.address1 }}</div>
									<div v-if="invoice.accountInfo.address2">{{ invoice.accountInfo.address2 }}</div>
									<div v-if="accountInfoCityStateZip">{{ accountInfoCityStateZip }}</div>
									<div v-if="invoice.accountInfo.country">
										{{ countries.codeToName(invoice.accountInfo.country) }}
									</div>
									<div v-if="invoice.accountInfo.phone">{{ invoice.accountInfo.phone }}</div>
									<div v-if="invoice.accountInfo.taxId">
										{{ invoice.accountInfo.taxId }}
									</div>
								</div>

								<div
									id="bill-to-details"
									:class="editable ? 'editable' : ''"
									@click="editable ? $emit('client-info-modal') : ''"
								>
									<div class="d-flex justify-space-between">
										<div class="inter-medium">
											{{ $t('invoice.bill-to') }}
										</div>
										<div v-if="editable" class="mt-n1">
											<v-btn icon rounded @click="$emit('client-info-modal')"><v-icon>$edit</v-icon></v-btn>
										</div>
									</div>
									<div>{{ invoice.clientInfo.name }}</div>
									<div>{{ invoice.clientInfo.address1 }}</div>
									<div>{{ invoice.clientInfo.address2 }}</div>
									<div v-if="clientInfoCityStateZip">{{ clientInfoCityStateZip }}</div>
									<div v-if="invoice.clientInfo.country">
										{{ countries.codeToName(invoice.clientInfo.country) }}
									</div>
									<div v-if="invoice.clientInfo.phone">{{ invoice.clientInfo.phone }}</div>
									<div v-if="invoice.clientInfo.taxId">
										{{ invoice.clientInfo.taxId }}
									</div>
								</div>
							</div>
							<!-- Items -->
							<v-simple-table id="invoice-items-table" style="background-color: var(--v-white-base)" v-if="invoice">
								<template v-slot:default>
									<thead>
										<tr>
											<th
												v-for="(item, i) in itemHeaders"
												:key="i"
												:class="item.class + (editable ? ' editable' : '')"
											>
												{{ item.text }}
											</th>
										</tr>
									</thead>
									<tbody>
										<template v-for="(item, i) in invoice.items">
											<!-- Ad hoc and Editable -->
											<template v-if="(editable && item.type === 'ADHOC') || item.type === 'EXPENSE'">
												<tr class="text-left" :key="i">
													<td class="trash">
														<div class="row-format align-center" v-if="editable">
															<div class="column-format ml-3">
																<v-icon small @click="moveUp(i)">$arrowUp</v-icon>
																<v-icon small @click="moveDown(i)">$arrowDown</v-icon>
															</div>
															<v-btn icon small rounded @click="handleDeleteItemDialog(i)"
																><v-icon size="20">$delete</v-icon></v-btn
															>
														</div>
													</td>
													<td class="description editable">
														<v-textarea
															rows="1"
															auto-grow
															style="margin-bottom: -10px"
															v-model="invoice.items[i].description"
															dense
															@focus="$emit('pause-auto-save', true)"
															@blur="$emit('pause-auto-save', false)"
															class="pause-save item-description"
															:id="`item-description-${i}`"
														/>
														<project-select
															class="mt-2"
															:clientId="invoice.clientId"
															:item="item"
															:editable="editable"
															@select-project="assignProjectToItem($event, item)"
														/>
													</td>
													<td class="qty">
														<v-text-field
															type="number"
															v-model="invoice.items[i].qty"
															min="0"
															ref="qty"
															class="pause-save text-hover-blue"
															dense
															@focus="manualQtyFocus(i)"
															@blur="manualQtyBlur(i)"
															@keyup="itemChange()"
															@change="itemChange()"
														/>
													</td>
													<!--td>
													<span v-if="item.product">{{ item.product.unit }}</span>
													<span v-else>{{ $t('invoice.details.item-type.' + item.type) }}</span>
												</td-->
													<td class="price">
														<v-text-field
															type="number"
															v-model="invoice.items[i].price"
															dense
															class="pause-save text-hover-blue"
															@focus="manualPriceFocus(i)"
															@blur="manualPriceBlur(i)"
															@keyup="itemChange()"
															@change="itemChange()"
														/>
													</td>
													<td class="taxable" v-if="taxEnabled">
														<v-simple-checkbox
															v-model="invoice.items[i].taxable"
															dense
															right
															@click="itemChange(true)"
														></v-simple-checkbox>
													</td>
													<td class="subtotal">
														{{
															$formatters.dollars(
																invoice.items[i].subtotal,
																true,
																true,
																invoice.currency
															)
														}}
													</td>
												</tr>
											</template>
											<!-- Project/Client/Expense linked or non-editable -->
											<template v-else>
												<tr
													:key="i"
													@click="handleItemDetailsClick(item)"
													:class="item.type === 'HOURS' || item.type === 'OVERAGE' ? 'pointer' : ''"
												>
													<td class="trash">
														<div class="row-format align-center" v-if="editable">
															<div class="column-format ml-3">
																<v-icon small @click="moveUp(i)">$arrowUp</v-icon>
																<v-icon small @click="moveDown(i)">$arrowDown</v-icon>
															</div>
															<v-btn
																v-if="
																	editable && item.type !== 'DEPOSIT' && item.type !== 'OVERAGE'
																"
																icon
																small
																rounded
																@click="handleDeleteItemDialog(i)"
															>
																<v-icon size="20">$delete</v-icon>
															</v-btn>
														</div>
													</td>
													<td v-if="editable && item.type !== 'OVERAGE'" class="description editable">
														<v-textarea
															rows="1"
															auto-grow
															v-model="invoice.items[i].description"
															dense
															style="margin-bottom: -10px"
															@change="itemChange()"
															class="pause-save item-description"
														/>
														<!--													<span>{{ item.type }}</span>-->
														<div class="row-format mt-2" style="gap: 4px">
															<div
																class="font-12"
																v-if="item.type === 'DEPOSIT'"
																style="color: var(--v-black-base); cursor: default"
															>
																Deposit
															</div>
															<project-select
																:clientId="invoice.clientId"
																:item="item"
																:editable="editable"
																:prefix="item.type === 'DEPOSIT' ? ' / ' : null"
																@select-project="assignProjectToItem($event, item)"
															/>
															<div
																v-if="item.retainerPeriod"
																class="font-12"
																style="color: var(--v-black-base); cursor: default"
															>
																{{
																	DateTime.fromISO(item.retainerPeriod.start).toLocaleString(
																		DateTime.DATE_MED
																	)
																}}
																-
																{{
																	DateTime.fromISO(item.retainerPeriod.end).toLocaleString(
																		DateTime.DATE_MED
																	)
																}}
															</div>
														</div>
													</td>
													<td v-else class="description" style="min-width: 200px !important">
														<div style="white-space: pre-wrap" class="item-description my-1">
															<div>{{ item.description }}</div>
														</div>
														<div class="row-format align-center" style="gap: 4px">
															<div
																class="font-12"
																v-if="item.type === 'DEPOSIT'"
																style="color: var(--v-black-base); cursor: default"
															>
																Deposit
															</div>
															<project-select
																:clientId="invoice.clientId"
																:item="item"
																:editable="editable"
																:prefix="item.type === 'DEPOSIT' ? ' / ' : null"
																@select-project="assignProjectToItem($event, item)"
															/>
															<div
																v-if="item.retainerPeriod"
																class="font-12"
																style="color: var(--v-black-base); cursor: default"
															>
																{{
																	DateTime.fromISO(item.retainerPeriod.start).toLocaleString(
																		DateTime.DATE_MED
																	)
																}}
																-
																{{
																	DateTime.fromISO(item.retainerPeriod.end).toLocaleString(
																		DateTime.DATE_MED
																	)
																}}
															</div>
															<div
																v-if="item.timerEventIds.length && allowDetailedInvoice"
																class="font-12 font-primary nowrap pointer"
															>
																{{ $t('invoice.details.show-hours') }}
															</div>
														</div>
													</td>
													<td
														v-if="item.type === 'DELIVERABLE' && !item.product.hourly && editable"
														class="qty editable"
													>
														<v-text-field
															type="number"
															v-model="invoice.items[i].qty"
															min="0"
															ref="qty"
															class="pause-save text-hover-blue"
															dense
															@focus="manualQtyFocus(i)"
															@blur="manualQtyBlur(i)"
															@keyup="itemChange()"
															@change="itemChange()"
														/>
													</td>
													<td
														v-else
														:class="'qty' + (clickableQty(item) ? ' editable pointer' : '')"
														@click="handleQtyClick(i)"
													>
														<div>{{ $formatters.decimal(invoice.items[i].qty, 0, 3) }}</div>
													</td>
													<!--td
													:class="'type' + (clickableQty(item) ? ' editable' : '')"
													@click="handleQtyClick(i)"
												>
													<span v-if="item.product">{{ item.product.unit }}</span>
													<span v-else>{{ $t('invoice.details.item-type.' + item.type) }}</span>
												</td-->
													<td
														:class="'price' + (clickableFee(item) ? ' editable' : '')"
														@click="handlePriceClick(i)"
													>
														{{
															$formatters.dollars(
																invoice.items[i].price,
																true,
																editable ? false : true,
																invoice.currency
															)
														}}
													</td>
													<td class="taxable" v-if="taxEnabled">
														<v-simple-checkbox
															:disabled="!editable"
															v-model="invoice.items[i].taxable"
															dense
															right
															@click="itemChange(true)"
														></v-simple-checkbox>
													</td>
													<td class="subtotal">
														{{
															$formatters.dollars(
																invoice.items[i].subtotal,
																true,
																true,
																invoice.currency
															)
														}}
													</td>
												</tr>
											</template>
										</template>
									</tbody>
								</template>
							</v-simple-table>

							<!-- Late Payment -->
							<div
								id="late-payment"
								:class="'calculated text-left' + (invoice.lateFeeWaived ? ' waived' : '')"
								v-if="editMode && (totalLateFee || invoice.lateFeeWaived) && invoice.daysPastDue > 0"
							>
								<div class="left"></div>
								<div class="center" v-if="invoice.lateFeeWaived && invoice.status === 'PAID'">
									{{
										$t('invoice.details.late-payment-fee-waived-paid', {
											lateFee: $formatters.dollars(totalLateFee, true, true, invoice.currency),
											days: invoice.daysPastDue,
											lateFeePercentage: invoice.paymentTerms ? invoice.paymentTerms.latePaymentFee : 0,
										})
									}}
									<v-btn
										text
										small
										color="primary"
										@click="waiveLateFee"
										v-if="editMode && invoice.status !== 'PAID'"
										>{{ $t('invoice.details.apply-late-fee') }}</v-btn
									>
								</div>
								<div class="center" v-else-if="invoice.lateFeeWaived">
									{{
										$t('invoice.details.late-payment-fee-waived', {
											lateFee: $formatters.dollars(totalLateFee, true, true, invoice.currency),
											days: invoice.daysPastDue,
											lateFeePercentage: invoice.paymentTerms ? invoice.paymentTerms.latePaymentFee : 0,
										})
									}}
									<v-btn
										text
										small
										color="primary"
										@click="waiveLateFee"
										v-if="editMode && invoice.status !== 'PAID'"
										>{{ $t('invoice.details.apply-late-fee') }}</v-btn
									>
								</div>
								<div class="center" v-else>
									{{
										$t('invoice.details.late-payment-fee-added', {
											lateFee: $formatters.dollars(totalLateFee, true, true, invoice.currency),
											days: invoice.daysPastDue,
											lateFeePercentage: invoice.paymentTerms ? invoice.paymentTerms.latePaymentFee : 0,
										})
									}}
									<v-btn
										text
										small
										color="primary"
										@click="waiveLateFee"
										v-if="editMode && invoice.status !== 'PAID'"
										>{{ $t('invoice.details.waive-late-fee') }}</v-btn
									>
								</div>
								<!--div :class="'right' + (invoice.lateFeeWaived ? ' waived' : '')">
								{{ $formatters.dollars(invoice.lateFeeCalculated, true, true, invoice.currency) }}
							</div-->
							</div>

							<div id="late-payment" class="applied text-left" v-else-if="totalLateFee && invoice.daysPastDue > 0">
								<div class="left"></div>
								<div class="center" style="">
									{{
										$t('invoice.details.late-payment-fee-added', {
											lateFee: $formatters.dollars(totalLateFee, true, true, invoice.currency),
											days: invoice.daysPastDue,
											lateFeePercentage: invoice.paymentTerms ? invoice.paymentTerms.latePaymentFee : 0,
										})
									}}
								</div>
								<div class="right">
									{{ $formatters.dollars(totalLateFee, true, true, invoice.currency) }}
								</div>
							</div>

							<template v-if="editable && invoice.invoiceType === 'STANDARD'">
								<!-- Add a new line -->
								<div id="add-a-new-line">
									<!--								<span @click="handleAddNewLineItemModal()"-->
									<!--									><v-icon size="20" left>$plusCircle</v-icon-->
									<!--									><span class="brand-regular">{{ $t('invoice.details.add-a-new-line-item') }}</span></span-->
									<!--								>-->
									<div id="product-container">
										<v-menu
											v-model="showProductsMenu"
											max-height="90vh"
											max-width="900"
											attach="#add-a-new-line"
											:close-on-content-click="false"
											:close-on-click="true"
											content-class="filter-menu-class"
											style="overflow-y: auto"
											origin="top center"
											transition="scale-transition"
										>
											<template v-slot:activator="{ on }">
												<div v-on="on" class="pointer row-format align-center">
													<div>
														<v-icon size="20" left>$plusCircle</v-icon
														><span class="brand-regular">{{
															$t('invoice.details.add-a-new-line-item')
														}}</span>
													</div>
												</div>
											</template>
											<products
												@close="showProductsMenu = false"
												@select="handleSelectProduct($event)"
												:currency="invoice.currency"
												:client-id="invoice.clientId"
											/>
										</v-menu>
									</div>
								</div>

								<div id="add-a-new-line2" v-if="!recurring">
									<span @click="handleAddNewTrackedItemModal()"
										><v-icon size="20" left>$kanban</v-icon
										><span class="brand-regular">{{
											$t('invoice.details.add-line-item-from-billable')
										}}</span></span
									>
								</div>

								<!-- Add a new expense -->
								<div id="add-a-new-expense" v-if="hasExpenses && !recurring">
									<span @click="handleAddExpenses()"
										><v-icon size="20" left>$plusCircle</v-icon
										><span class="brand-regular">{{ $t('invoice.details.add-expenses') }}</span></span
									>
								</div>

								<!-- Apply a deposit -->
								<div id="apply-deposit" v-if="balanceExists && !invoice.creditAmount && !recurring">
									<span @click="handleApplyDeposit()"
										><v-icon size="20" left>$money</v-icon
										><span class="brand-regular">{{ $t('invoice.details.apply-deposit-credit') }}</span></span
									>
								</div>
							</template>

							<!-- Totals -->
							<div id="totals">
								<div class="line">
									<template v-if="invoice.invoiceType === 'STANDARD'">
										<div class="left">
											{{ $t('invoice.details.subtotal') }}
										</div>
										<div class="center"></div>
										<div class="right">
											{{ $formatters.dollars(invoice.itemSubtotal, true, true, invoice.currency) }}
										</div>
									</template>
								</div>
								<div class="line" v-if="invoice.convenienceFee">
									<div class="left">
										{{ $t('invoice.details.convenience-fee') }}
									</div>
									<div class="center"></div>
									<div class="right">
										{{ $formatters.dollars(invoice.convenienceFee, true, true, invoice.currency) }}
									</div>
								</div>
								<div class="line" v-if="totalLateFee">
									<div class="left">
										{{ $t('invoice.details.late-fee') }}
									</div>
									<div class="center"></div>
									<div class="right">
										{{ $formatters.dollars(totalLateFee, true, true, invoice.currency) }}
									</div>
								</div>
								<template v-if="invoice.invoiceType !== 'DEPOSIT' && invoice.taxRateLocked === false">
									<div v-if="editable || invoice.discountPercentage" class="line">
										<div class="left">
											{{ $t('invoice.details.discount') }}
										</div>

										<div :class="'center' + (editable ? ' editable' : '')">
											<template v-if="editable">
												<v-text-field
													type="number"
													v-model="invoice.discountPercentage"
													:min="0"
													:max="100"
													@change="forceSave"
													suffix="%"
													dense
													:rules="[
														() => invoice.discountPercentage >= 0 || 'Must be 0 or more',
														() => invoice.discountPercentage <= 100 || 'Must be 100 or less',
													]"
												/>
											</template>
											<template v-else-if="invoice.discountPercentage">
												{{ $formatters.decimal(invoice.discountPercentage) + '%' }}
											</template>
										</div>

										<div class="right">
											{{ $formatters.dollars(0 - invoice.discountAmount, true, true, invoice.currency) }}
										</div>
									</div>
								</template>

								<div class="line" v-if="taxEnabled">
									<div class="left">
										{{ taxLabel }}
									</div>
									<div :class="'center' + (editable ? ' editable' : '')">
										<template v-if="editable">
											<div v-if="invoice.taxRateLocked && additionalTaxRates.length">
												<v-menu :close-on-content-click="true" :close-on-click="true">
													<template v-slot:activator="{ on }">
														<div v-on="on" class="pointer font-gray_70">
															{{ $formatters.decimal(invoice.taxPercentage) + '%' }}
														</div>
													</template>
													<div
														class="column-format pa-3 font-14 gap-2"
														style="background-color: var(--v-white-base)"
													>
														<div
															v-for="taxRate in additionalTaxRates"
															:key="taxRate.id"
															class="row-format gap-3 pointer"
															@click="$emit('tax-rate-selected', taxRate)"
														>
															<div>{{ taxRate.name }}</div>
															<div class="ml-auto">{{ taxRate.rate }}%</div>
														</div>
													</div>
												</v-menu>
											</div>
											<div v-else>
												<v-text-field
													type="number"
													v-model="invoice.taxPercentage"
													:disabled="invoice.taxRateLocked"
													:min="0"
													:max="100"
													step="1"
													@change="forceSave"
													dense
													suffix="%"
												/>
											</div>
										</template>
										<template v-else>
											{{ $formatters.decimal(invoice.taxPercentage) + '%' }}
										</template>
									</div>
									<div class="right">
										{{ $formatters.dollars(invoice.taxAmount, true, true, invoice.currency) }}
									</div>
								</div>

								<div class="line credit-applied" v-if="invoice.creditAmount">
									<div class="left">
										<div class="mr-1">
											<v-btn v-if="editable" icon rounded @click="$emit('apply-deposit-modal')"
												><v-icon>$edit</v-icon></v-btn
											>
										</div>
										{{ $t('invoice.details.credit-amount') }}
									</div>
									<div class="center"></div>
									<div class="right">
										{{ $formatters.dollars(0 - invoice.creditAmount, true, true, invoice.currency) }}
									</div>
								</div>
								<div class="line total">
									<div class="left"></div>
									<div class="center" v-if="invoice.status === 'DRAFT' || invoice.status === 'SENT'">
										{{ $t('invoice.details.total-due') }}
									</div>
									<div class="center" v-else>{{ $t('invoice.details.total') }}</div>
									<div class="right">
										{{ $formatters.dollars(invoice.total, true, true, invoice.currency) }}
									</div>
								</div>

								<div class="line total">
									<div class="left"></div>
									<div class="center font-14">
										<span style="font-weight: 300"
											>{{ $t('invoice.all-amounts') }} {{ invoice.currency }}</span
										>
									</div>
								</div>

								<template
									v-if="
										invoice.status === 'PARTIAL' || invoice.status === 'PAID' || invoice.status === 'PENDING'
									"
								>
									<div class="line balance-due">
										<div class="left"></div>
										<div class="center">{{ $t('invoice.details.payments-applied') }}</div>
										<div class="right">
											{{ $formatters.dollars(invoice.paymentTotal, true, true, invoice.currency) }}
										</div>
									</div>
									<div class="line balance-due mb-3">
										<div class="left"></div>
										<div class="center">{{ $t('invoice.details.balance-due') }}</div>
										<div class="right">
											{{ $formatters.dollars(invoice.amountDue, true, true, invoice.currency) }}
										</div>
									</div>
								</template>
							</div>

							<!-- Payment Button -->
							<div class="pay-button" v-if="invoice.payOnlineUrl && !editable && invoice.amountDue">
								<v-btn @click="handlePaymentAction" :width="191" class="primary-action">{{
									$t('invoice.details.pay-button')
								}}</v-btn>
							</div>

							<!-- Footer -->
							<div id="footer" class="text-left">
								<div v-if="invoice.notes || editable">
									<h3>{{ $t('invoice.details.notes') }}</h3>
									<div
										v-html="notes"
										:contenteditable="editable"
										@input="handleNotesChange"
										:style="`${editable ? 'border: 1px solid var(--v-gray_30-base); padding:4px' : ''}`"
									></div>
								</div>

								<div v-if="invoice.payInstructions || editable" class="my-5">
									<h3>{{ $t('invoice.details.payment-instructions') }}</h3>
									<div
										v-html="payInstructions"
										:contenteditable="editable"
										@input="handlePayInstructionsChange"
										:style="`${editable ? 'border: 1px solid var(--v-gray_30-base); padding:4px' : ''}`"
									></div>
								</div>

								<div class="my-8" v-if="editable || invoice.attachments.length > 0">
									<file-attachments
										:files="invoice.attachments"
										:file-service="attachmentService"
										:edit-mode="editable"
										:previews-supported="false"
									>
									</file-attachments>
								</div>

								<div v-if="invoice.paymentTerms || editable" class="my-5">
									<template v-if="termsSentence">
										<div
											:class="'body-14 font-italic ' + (editable ? 'pointer' : '')"
											@click="handlePaymentTermsClick"
											v-html="termsSentence"
										></div>
									</template>
									<template v-else-if="editable">
										<div class="pl-0 payment-link">
											<span @click="handlePaymentTermsClick()">
												<v-icon size="20" left>$handshake</v-icon>
												<span class="brand-regular">
													{{ $t('invoice.details.add-payment-terms-button') }}
												</span>
											</span>
										</div>
									</template>
								</div>

								<div
									v-if="invoice.autoPayMethod && !invoice.wasAutoPaid"
									class="body-14 font-italic font-primary my-5"
								>
									Automatic payment scheduled for {{ $DateTime.shortDate(invoice.dateDue) }} from
									<span style="text-transform: capitalize">{{ invoice.autoPayMethod.label }}</span>
									{{ invoice.autoPayMethod.last4 }}
									<v-icon
										class="material-symbols-rounded pointer"
										v-if="editable"
										size="20"
										@click="confirmCancelAutoPay"
										color="primary"
										>cancel</v-icon
									>
								</div>
							</div>

							<div id="status-overlay" :class="invoice.status.toLowerCase()" v-if="$vuetify.breakpoint.mdAndUp">
								{{ invoice.status }}
							</div>
						</div>

						<!-- Payment Card -->
						<div
							v-if="showPaymentCardBanner"
							class="payment-card-pay-now pointer"
							@click="handlePaymentAction"
							:style="`width:${$vuetify.breakpoint.width > 1200 ? 1000 : $vuetify.breakpoint.width}px`"
						>
							<div class="">
								<div class="d-flex justify-center align-center">
									<div class="column-format">
										<div>
											<img
												src="/images/invoices/stripe-logo.svg"
												v-if="acceptStripe"
												width="100"
												style="max-width: 140px"
											/>
										</div>
										<div>
											<img
												src="/images/invoices/paypal.svg"
												v-if="acceptPayPal"
												width="100"
												style="max-width: 140px"
											/>
										</div>
									</div>
								</div>
								<div>
									<p class="inter-medium text-left" v-html="$t('invoice.details.payment-card.heading')"></p>
									<p
										class="text-left"
										v-if="acceptPayPal && acceptStripe"
										v-html="$t('invoice.details.payment-card.message-both')"
									></p>
									<p
										class="text-left"
										v-else-if="acceptStripe"
										v-html="$t('invoice.details.payment-card.message-stripe')"
									></p>
									<p
										class="text-left"
										v-else-if="acceptPayPal"
										v-html="$t('invoice.details.payment-card.message-paypal')"
									></p>
									<p class="mt-3 text-left">
										<span class="mr-2"><payment-icon size="16" name="amex"></payment-icon></span>
										<span class="mr-2"><payment-icon size="16" name="master_card"></payment-icon></span>
										<span class="mr-2"><payment-icon size="16" name="visa"></payment-icon></span>
										<span class="mr-2"><payment-icon size="16" name="discover"></payment-icon></span>
										<span class="mr-2"
											><payment-icon size="16" name="paypal" v-if="acceptPayPal"></payment-icon
										></span>
									</p>
								</div>
							</div>
							<div class="">
								<v-btn class="primary-action" block small text>
									<span class="inter-medium font-16">{{
										$t('invoice.details.payment-card.button')
									}}</span></v-btn
								>
							</div>
						</div>

						<div
							v-if="showSetupPaymentCardBanner"
							class="payment-card-setup pointer"
							@click="$emit('payment-connect')"
							:style="`width:${$vuetify.breakpoint.width > 1200 ? 1000 : $vuetify.breakpoint.width}px`"
						>
							<div class="d-flex justify-center align-center">
								<div>
									<v-img src="/images/invoices/stripe-logo.svg" :width="154" />
									<v-img src="/images/invoices/paypal.svg" :width="154"></v-img>
								</div>
							</div>
							<div>
								<p class="inter-medium text-left" v-html="$t('invoice.details.payment-card-setup.heading')"></p>
								<p class="gray_80--text text-left" v-html="$t('invoice.details.payment-card-setup.message')"></p>
								<p class="text-left mt-1">
									<v-btn class="px-0 primary-action" small text>
										<span class="inter-medium">{{ $t('invoice.details.payment-card-setup.button') }}</span>
									</v-btn>
								</p>
							</div>
						</div>

						<confirm-dialog
							:dialog="deleteItemDialog"
							@close="cancelDeleteItem()"
							@confirm="handleDeleteItem()"
							heading-text="Confirm"
							yes-text="Yes"
							no-text="No"
							:body-text="$t('invoice.details.delete-item-confirm')"
						>
						</confirm-dialog>

						<modal
							:dialog="!!showPaymentDetails"
							v-on:close="showPaymentDetails = null"
							ref="paymentDetailsModal"
							:persistent="false"
							:max-width="350"
						>
							<payment-details-modal
								v-if="showPaymentDetails"
								:invoice="invoice"
								:edit-mode="editMode"
								:payment="showPaymentDetails"
								:client="invoice.client"
								@close="showPaymentDetails = null"
								@updated="$emit('updated', $event)"
							></payment-details-modal>
						</modal>
						<modal
							:dialog="!!showItemDetailsModal"
							v-on:close="showItemDetailsModal = false"
							ref="itemDetailsModal"
							:persistent="false"
							:max-width="600"
						>
							<item-details-modal
								v-if="itemDetailsItemId"
								:key="itemDetailsItemId"
								:invoice="invoice"
								:invoice-item-id="itemDetailsItemId"
								:invoice-service="invoiceService"
								:client="invoice.client"
								@close="showItemDetailsModal = false"
							></item-details-modal>
						</modal>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import ConfirmDialog from './ConfirmDialog';
	import Modal from '@bit/hecticapp.common.modal';
	import PaymentIcon from './PaymentIcon';
	import PaymentDetailsModal from './PaymentDetailsModal';
	import ItemDetailsModal from '@/modules/invoices/InvoiceRenderer/ItemDetailsModal';
	import FileAttachments from '@/components/FileAttachments';
	import Countries from '@/modules/utils/Countries.js';
	import Products from '@/modules/invoices/InvoiceRenderer/Products';
	import ProjectSelect from '@/modules/invoices/InvoiceRenderer/ProjectSelect';
	import SimpleTextInput from '@/components/SimpleTextInput';
	import DateTime from '@/modules/utils/HDateTime';
	import { v4 as uuid4 } from 'uuid';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'InvoiceRenderer',
		components: {
			PaymentIcon,
			FileAttachments,
			ConfirmDialog,
			Modal,
			PaymentDetailsModal,
			ItemDetailsModal,
			Products,
			ProjectSelect,
		},

		props: {
			forceRenderIndex: { type: Number, required: false, default: 0 },
			invoice: { type: Object, required: true },
			account: { type: Object, required: true },
			depositAccount: { type: Object, required: false },
			editMode: { type: Boolean, required: false, default: false },
			hasExpenses: { type: Boolean, required: false, default: false },
			additionalTaxRates: { type: Array, required: false, default: () => [] },
			saving: { type: Boolean, required: false, default: false },
			attachmentService: { type: Object, required: true },
			invoiceService: { type: Object, required: true },
			itemFocus: { type: Number, required: false, default: -1 },
			recurring: { type: Boolean, required: false, default: false },
		},

		data: function() {
			return {
				countries: new Countries(),

				itemTypes: [
					{ value: 'DEPOSIT', text: this.$t('invoice.details.item-type.DEPOSIT') },
					{ value: 'HOURS', text: this.$t('invoice.details.item-type.HOURS') },
					{ value: 'RETAINER', text: this.$t('invoice.details.item-type.RETAINER') },
					{ value: 'ADHOC', text: this.$t('invoice.details.item-type.ADHOC') },
					{ value: 'PROJECT', text: this.$t('invoice.details.item-type.PROJECT') },
				],
				discountTypes: [
					{ value: 'NONE', text: this.$t('invoice.details.discount-type.NONE') },
					{ value: 'FIXED', text: this.$t('invoice.details.discount-type.FIXED') },
					{ value: 'PERCENTAGE', text: this.$t('invoice.details.discount-type.PERCENTAGE') },
				],

				deleteItemIndex: null,
				deleteItemDialog: false,
				dateDueMenu: false,

				showItemHoursModal: false,
				itemModalIndex: null,
				showPaymentDetails: null, // Stores payment record selected for viewing
				itemTableKey: 0,

				showItemDetailsModal: false,
				itemDetailsItemId: null,

				showAddNewItemModal: false,

				showInvoiceNumberFormatModal: false,

				showProductsMenu: false,
				// showProjectsModal: false,
				// projectModalItemToEdit: null,

				notes: '',
				payInstructions: '',
				DateTime: DateTime,
			};
		},

		mounted() {
			this.notes = this.invoice.notes;
			this.payInstructions = this.invoice.payInstructions;
		},

		methods: {
			moveUp: function(index) {
				if (index == 0) {
					return;
				}
				let item = this.invoice.items.splice(index, 1)[0];
				this.invoice.items.splice(index - 1, 0, item);
			},

			moveDown: function(index) {
				if (index == this.invoice.items.length - 1) {
					return;
				}
				let item = this.invoice.items.splice(index, 1)[0];
				this.invoice.items.splice(index + 1, 0, item);
			},

			handleApplyDeposit() {
				this.$emit('apply-deposit-modal');
			},
			handleDiscountChange() {
				this.$emit('discount-change');
			},
			handlePayInstructionsClick() {
				if (!this.editable) return;
				this.$emit('payment-instructions-modal');
			},
			handlePaymentTermsClick() {
				if (!this.editable) return;
				this.$emit('payment-terms-modal');
			},
			handlePaymentAction() {
				if (!['SENT', 'PARTIAL'].includes(this.invoice.status)) {
					if (['DRAFT', 'INIT'].includes(this.invoice.status)) {
						this.alert(
							'warn',
							this.$t('invoice.payment-action.send-warning', {
								status: this.$t('invoice.statuses.' + this.invoice.status),
							})
						);
					} else {
						this.alert(
							'warn',
							this.$t('invoice.payment-action.sent-or-partial-warning', {
								status: this.$t('invoice.statuses.' + this.invoice.status),
							})
						);
					}
					return;
				}
				if (!this.invoice.payOnlineUrl) {
					this.alert('error', 'No payment URL has been generated. Try again later.');
					return;
				}
				window.open(this.invoice.payOnlineUrl, '_blank');
			},
			alert(type, message) {
				this.$store.commit(type, message);
			},
			waiveLateFee() {
				this.$emit('waive-late-fee');
			},
			handleQtyClick(index) {
				if (!this.editable) return;
				if (['PROJECT', 'RETAINER'].includes(this.invoice.items[index].type)) {
					this.$emit('item-price-modal', index);
				}
				if (this.invoice.items[index].type === 'HOURS') {
					this.$emit('item-hours-modal', index);
				}
			},
			clickableFee(item) {
				if (item.type === 'EXPENSE' || item.type === 'OVERAGE') return false;
				return this.editable;
			},
			clickableQty(item) {
				if (!['PROJECT', 'HOURS', 'RETAINER'].includes(item.type)) return false;
				return this.editable;
			},
			manualQtyFocus(i) {
				this.$emit('pause-auto-save', true);
				this.invoice.items[i].qty = [0].includes(this.invoice.items[i].qty) ? 0 : this.invoice.items[i].qty;
			},
			manualQtyBlur(i) {
				this.$emit('pause-auto-save', false);
				this.invoice.items[i].qty = this.invoice.items[i].qty === '' ? 1 : this.invoice.items[i].qty;
				this.itemChange(true);
			},
			manualPriceFocus(i) {
				this.$emit('pause-auto-save', true);
				this.invoice.items[i].price = !this.invoice.items[i].price ? '' : this.invoice.items[i].price;
			},
			manualPriceBlur(i) {
				this.$emit('pause-auto-save', false);
				this.invoice.items[i].price = !this.invoice.items[i].price ? 0 : this.invoice.items[i].price;
				this.itemChange(true);
			},

			itemChange(forceSave = false) {
				if (!this.editable) return;
				for (let i = 0; i < this.invoice.items.length; i++) {
					let item = this.invoice.items[i];
					this.invoice.items[i].subtotal = Math.round(item.qty * item.price * 100) / 100;
				}
				if (forceSave) this.forceSave();
			},

			handleNotesChange(event) {
				this.invoice.notes = event.target.innerHTML;
			},

			handlePayInstructionsChange(event) {
				this.invoice.payInstructions = event.target.innerHTML;
			},
			handlePriceClick(index) {
				if (!this.editable || this.invoice.items[index].type === 'OVERAGE') return;
				this.$emit('item-price-modal', index);
			},
			handleItemDetailsClick(item) {
				if (!this.allowDetailedInvoice || this.editable) return;
				if (item.timerEventIds.length === 0) return;
				this.itemDetailsItemId = item.id;
				this.showItemDetailsModal = true;
			},
			handleDeleteItemDialog(i) {
				this.deleteItemIndex = i;
				this.deleteItemDialog = true;
			},
			cancelDeleteItem() {
				this.deleteItemDialog = false;
				this.deleteItemIndex = null;
			},
			handleDeleteItem() {
				this.invoice.items.splice(this.deleteItemIndex, 1);
				this.itemChange(true);
				this.cancelDeleteItem();
				this.forceSave();
			},
			handleAddNewLineItemModal() {
				this.showProductsMenu = true;
			},
			handleSelectProduct(event) {
				let item = {};
				item.id = uuid4();
				item.type = 'ADHOC';
				item.product = event.product;
				item.description = event.product.productName;

				if (event.product.description) {
					item.description = item.description + '\n' + event.product.description;
				}

				let currencyRate = event.product.currencyRates.find((c) => c.currency === this.invoice.currency);

				if (currencyRate) {
					item.price = currencyRate.rate;
				} else {
					item.price = event.product.rate;
				}

				item.qty = 1;
				item.taxable = event.product.taxable;
				this.invoice.items.push(item);
				this.showProductsMenu = false;
				this.$emit('force-save');
			},
			handleAddNewTrackedItemModal() {
				this.$emit('tracked-item-modal');
			},
			handleAddExpenses() {
				this.$emit('expenses-modal');
			},
			forceSave() {
				this.$emit('force-save');
			},
			paymentDateFormat(payment) {
				if (payment.datePaid) {
					return this.$DateTime.humanDate(payment.datePaid);
				}
				return this.$DateTime.humanDate(payment.timestamp);
			},
			assignProjectToItem(project, item) {
				if (project) {
					item.projectId = project.id;
					item.projectName = project.name;
				}
				let index = this.invoice.items.findIndex((i) => i.id === item.id);
				if (index > -1) {
					this.invoice.items.splice(index, 1, item);
				}
			},
			handlePurchaseOrderClick() {
				if (!this.editable) return;
				let binding = {
					label: 'Purchase order',
					bodyText: null,
					input: this.invoice.purchaseOrder,
					hideNo: true,
					required: false,
				};
				this.$store.state.globalModalController.openModal(SimpleTextInput, binding, false, false).then((res) => {
					if (res) {
						this.invoice.purchaseOrder = res;
					} else {
						this.invoice.purchaseOrder = null;
					}
				});
			},
			confirmCancelAutoPay: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to cancel the scheduled auto-pay on this invoice?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.invoice.autoPayMethod = null;
						this.forceSave();
					}
				});
			},
		},
		computed: {
			totalLateFee: function() {
				return this.invoice.lateFeeApplied + this.invoice.lateFeeCalculated;
			},

			allowDetailedInvoice: function() {
				return this.invoice.accountInfo.accountPreferences.invoiceFormat === 'DETAILED';
			},

			documentHeader: function() {
				if (this.invoice.status === 'PAID' && this.invoice.accountInfo.accountPreferences.paidInvoiceDocumentHeader) {
					return this.invoice.accountInfo.accountPreferences.paidInvoiceDocumentHeader;
				} else {
					return this.invoice.accountInfo.accountPreferences.invoiceDocumentHeader;
				}
			},

			taxLabel: function() {
				if (this.invoice.accountInfo.accountPreferences.taxLabel) {
					return this.invoice.accountInfo.accountPreferences.taxLabel;
				}

				let gst = ['NZ', 'AU', 'CA', 'IN', 'SG'];
				let tax = ['US'];
				let country = this.invoice.accountInfo.country;

				if (gst.includes(country)) {
					return this.$t('invoice.details.gst');
				} else if (tax.includes(country)) {
					return this.$t('invoice.details.tax');
				} else {
					return this.$t('invoice.details.vat');
				}
			},

			taxEnabled: function() {
				if (this.editable) {
					return this.invoice.accountInfo.accountPreferences.invoiceUseTax;
				} else {
					if (this.invoice.accountInfo.accountPreferences.showTaxWithZeroPercent) {
						return true;
					} else {
						return this.invoice.taxPercentage > 0;
					}
				}
			},

			dateDue() {
				let dateDue = this.invoice.dateDue;
				if (this.invoice.status === 'DRAFT' && !dateDue) {
					dateDue = this.invoice.dateDueCalculated;
					// console.log(this.invoice.dateDueCalculated);
				}
				return dateDue;
			},
			dateDueWarning() {
				if (this.invoice.status !== 'DRAFT') return '';
				if (!this.invoice.dateDue) return '';

				const dateDue = this.$DateTime.fromISO(this.invoice.dateDue);
				const diff = dateDue.diff(this.$DateTime.now(), ['days']);
				if (diff.days < 1) {
					// warn if less than X days
					return 'date-due-warning';
				}
				return '';
			},

			logo() {
				if (this.$vuetify.theme.dark && this.account.accountLogoDark) {
					return '<img src="' + this.account.accountLogoDark + '" />';
				} else if (this.account.accountLogo) {
					return '<img src="' + this.account.accountLogo + '" />';
				} else {
					return null;
				}
			},

			balanceExists() {
				if (!this.depositAccount) return false;
				return !!this.depositAccount.balance;
			},
			itemHeaders() {
				let headers = [
					{ text: this.$t('invoice.details.item.description'), value: 'description', class: 'description' },
					{ text: this.$t('invoice.details.item.quantity'), value: 'qty', class: 'qty' },
					//{ text: this.$t('invoice.details.item.type'), value: 'type', class: 'type' },
					{ text: this.$t('invoice.details.item.price'), value: 'price', class: 'price' },
					{ text: this.taxLabel, value: 'taxable', class: 'taxable' },
					{ text: this.$t('invoice.details.item.subtotal'), value: 'subtotal', class: 'subtotal' },
				];

				if (!this.taxEnabled) {
					let ix = headers.findIndex((h) => h.value === 'taxable');
					headers.splice(ix, 1);
				}

				headers.splice(0, 0, { text: '', value: 'trash', class: 'trash' });
				// }
				return headers;
			},
			showPaymentCardBanner() {
				if (['DRAFT', 'PAID', 'VOIDED', 'WRITEOFF'].includes(this.invoice.status)) return false;
				return this.invoice.payOnlineUrl;
			},
			showSetupPaymentCardBanner() {
				if (!['DRAFT', 'INIT'].includes(this.invoice.status)) return false;
				if (!this.editable) return false;
				return !this.acceptStripe && !this.acceptPayPal;
			},
			editable() {
				return this.editMode && ['INIT', 'DRAFT'].includes(this.invoice.status);
			},
			accountInfoCityStateZip() {
				return (
					(this.invoice.accountInfo.city ?? '') +
					(this.invoice.accountInfo.locality || this.invoice.accountInfo.postal ? ', ' : '') +
					(this.invoice.accountInfo.locality ?? '') +
					' ' +
					(this.invoice.accountInfo.postal ?? '')
				);
			},
			clientInfoCityStateZip() {
				return (
					(this.invoice.clientInfo.city ?? '') +
					(this.invoice.clientInfo.locality || this.invoice.clientInfo.postal ? ', ' : '') +
					(this.invoice.clientInfo.locality ?? '') +
					' ' +
					(this.invoice.clientInfo.postal ?? '')
				);
			},
			acceptStripe() {
				if (!this.account.stripeIntegration) return false;
				return this.account.stripeIntegration.chargesEnabled;
			},
			acceptPayPal() {
				return this.account.payPalEnabled;
			},
			user() {
				return this.$store.getters.getUser;
			},
			paymentDays: function() {
				let paymentTerms = this.invoice.paymentTerms;
				if (paymentTerms) {
					return paymentTerms.paymentDays;
				} else {
					return 7;
				}
			},
			termsSentence: function() {
				let paymentTerms = this.invoice.paymentTerms;
				if (paymentTerms && paymentTerms.latePaymentFee > 0) {
					return `${this.$t('invoice.paid-in-full')} ${paymentTerms.latePaymentFee}${this.$t('invoice.monthly-fee')}`;
				} else {
					return null;
				}
			},
		},
		watch: {
			itemFocus: {
				handler(n) {
					this.$nextTick(() => {
						let item = document.getElementById(`item-description-${n}`);
						if (item) item.focus();
					});
				},
			},
		},
	};
</script>

<style lang="scss">
	.item-description {
		font-size: 12px;
		font-weight: 300;
		div::first-line {
			font-size: 14px;
			font-weight: 400;
		}
	}

	.invoice-renderer-date-picker {
		.v-date-picker-header__value {
			pointer-events: none;
		}
	}
	.payment-details-link {
		&:hover {
			opacity: 0.7;
		}
	}
	#invoice-wrapper {
		font-weight: 300;
		.inter-medium {
			font-weight: 500;
			font-family: Inter !important;
		}
		.inter-semibold {
			font-weight: 600;
			font-family: Inter !important;
		}

		font-size: 16px;
		color: var(--v-gray_90-base);
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		#invoice-renderer {
			background-color: var(--v-white-base);
			border-radius: 4px;
			margin-top: 24px;
			box-shadow: 0px 1px 4px rgba(16, 17, 15, 0.2);
			letter-spacing: 0.1px;
			//max-width: 1048px;
			min-height: 1352px;
			position: relative; // For paid stamp

			.v-btn {
				color: var(--v-primary-base);
				svg {
					width: 20px;
					height: 20px;
				}
			}
			.trash {
				.v-btn {
					color: var(--v-gray_70-base);
				}
			}
			input[type='number'] {
				text-align: right;
			}
			& > div {
				padding: 0 48px;
			}

			#header {
				padding: 40px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: flex-start;
				background: var(--v-gray_10-base);
				& > div {
					//border: 1px solid red;
					margin: 0;
				}
				div#logo {
					img {
						max-height: 160px;
						max-width: 350px;
					}

					#add-logo {
						width: 258px;
						min-height: 148px;
						border: 1px dotted var(--v-gray_50-base);
						border-radius: 4px;
						background-color: var(--v-gray_10-base);
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						color: var(--v-gray_80-base);

						&:hover {
							//opacity: 0.7;
							background-color: var(--v-gray_20-base);
						}
					}
				}

				div#details {
					flex: 1 0 auto;
					& > div {
						padding-left: 12px;
						//max-width: 180px;
					}
					text-align: left;
					.due-date.editable {
						cursor: pointer;
						border-radius: 4px;
						&:hover {
							background-color: var(--v-gray_20-base);
							.v-btn {
								visibility: visible !important;
							}
						}
						.date-due-display {
							border-radius: 4px;
							padding: 0 4px;
							margin: 0 -4px;
							.v-btn {
								margin-top: -2px;
								color: var(--v-gray_70-base);
								visibility: visible !important;
							}
							&.date-due-warning {
								//border: 1px solid var(--v-warning-base);
								background-color: var(--v-warning_10-base);
								color: var(--v-black-base);
								//background-color: var(--v-alert_10-base);
								.v-btn {
									color: var(--v-gray_80-base);
								}
							}
						}
					}
					.v-btn {
						visibility: hidden;
					}
				}
			}

			#subheader {
				display: flex;
				justify-content: space-between;
				padding: 36px 36px;

				& > div {
					flex: 0 0 50%;
					text-align: left;
					padding: 16px;
					border-radius: 4px;
					.v-btn {
						visibility: hidden;
					}
					&.editable:hover {
						background-color: var(--v-gray_10-base);
						cursor: pointer;
						.v-btn {
							visibility: visible !important;
						}
					}
				}
			}

			#invoice-items-table {
				//border: 1px solid red;
				margin-top: 52px;
				padding: 0;
				border-bottom: 1px solid var(--v-gray_50-base);

				thead {
					//background-color: var(--v-gray_20-base);
					//letter-spacing: 0.4px;
					//text-transform: uppercase;
					height: 48px;

					th {
						//&:first-child {
						//	padding-left: 64px;
						//}
						&:last-child {
							padding-right: 64px;
						}
						//border: 1px solid red;
						text-align: right;
						font-size: 14px;
						border-bottom: 1px solid var(--v-gray_50-base);
						color: var(--v-gray_70-base);
						font-weight: 600;
						&.taxable {
							text-align: center;
						}
						&.description {
							width: 50%;
							text-align: left;
						}
					}
				}

				tbody {
					tr {
						height: 72px;
						//line-height: 20px;
						td {
							//height: 70px;
							//line-height: 20px;
							padding-top: 0 !important;
							padding-bottom: 0 !important;
							vertical-align: middle;
							//&:first-child {
							//	padding-left: 64px;
							//}
							&:last-child {
								padding-right: 64px;
							}

							text-align: right;
							font-size: 16px;
							color: var(--v-gray_90-base);
							font-weight: 300;
							font-size: 14px;

							//.second-line {
							//	height: 20px;
							//	font-size: 12px;
							//	overflow: hidden;
							//}
							//.second-line.show-hide {
							//	transition: height 200ms;
							//	height: 0;
							//}

							&.price {
								min-width: 120px;
							}

							&.qty {
								font-size: 14px;
							}

							&.rate {
								font-size: 14px;
							}

							&.subtotal {
								font-size: 14px;
							}

							&.trash {
								padding: 0 !important;
								text-align: right;
								width: 36px !important;

								.v-icon {
									opacity: 0;
								}

								button.v-btn {
									opacity: 0;
									margin-right: -8px;
									svg:focus {
										outline: none;
									}
								}
							}
							//.v-input.v-input--dense.v-text-field
							.v-input.v-text-field {
								//margin-top: 0;
								.v-input__control {
									.v-text-field__slot {
										input {
											padding-top: 0;
											padding-bottom: 0;
											font-size: 14px;
										}
									}
									.v-text-field__details {
										display: none;
									}
									.v-input__slot {
										&:before,
										&:after {
											border-style: none !important;
										}
									}
								}
							}
							/* Hide spinner for alignment (and mb usability!) */
							/* Chrome, Safari, Edge, Opera */
							input::-webkit-outer-spin-button,
							input::-webkit-inner-spin-button {
								-webkit-appearance: none;
								margin: 0;
							}

							/* Firefox */
							input[type='number'] {
								-moz-appearance: textfield;
							}

							&.description {
								width: 50%;
								text-align: left;
								//padding: 0;
								&:not(.editable) {
									//padding-left: 16px;
								}

								.activator-label.show-hide {
									transition: height 200ms;
									height: 0;
								}

								&:hover {
									.activator-label.show-hide {
										height: 20px;
										transition: height 200ms;
									}
								}
							}
							&.type {
								text-align: right;
							}
							&.editable {
								&:hover {
									color: var(--v-primary-base);
								}
								cursor: pointer;
							}
							&.taxable {
								text-align: center;
								.v-input--selection-controls__input {
									margin: 0px;
								}
							}

							input {
								font-size: 14px;
							}
						}

						&:hover {
							// row hover
							.trash {
								opacity: 1;

								.v-icon {
									opacity: 1;
								}

								//border: 1px solid red;
								button.v-btn {
									opacity: 1;
									//svg:focus {
									//	outline: none;
									//}
								}
							}
						}
					}
				}
			}

			#late-payment {
				margin-top: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid var(--v-gray_50-base);
				padding-left: 8px;
				display: flex;
				font-size: 14px;

				//font-size: 14px;

				div.left {
					flex: 0 1 38px;
				}

				div.center {
					//flex: 1 0 auto;
					button {
						margin-top: -4px;
						span.v-btn__content {
							font-size: 16px;
							//font-weight: normal;
							//font-family: stratos-semibold;
						}
						&:hover {
							span.v-btn__content {
								color: var(--v-black-base);
							}
						}
					}
				}

				div.right {
					flex: 0 1 auto;
					padding-right: 16px;
					margin-left: auto;

					&.waived {
						//text-decoration: line-through;
					}
				}
			}

			.payment-link,
			#add-a-new-line,
			#add-a-new-line2,
			#add-a-new-expense,
			#apply-deposit {
				line-height: 48px;
				text-align: left;
				padding-left: 48px;
				border-bottom: 1px solid var(--v-gray_50-base);
				font-size: 14px;
				font-weight: 500;
				//--icon-color: var(--v-primary-base);
				span {
					cursor: pointer;
					&:hover {
						opacity: 0.7;
					}
					.v-icon {
						margin-top: -3px;
					}
					color: var(--v-primary-base);
				}
			}
			.payment-link {
				line-height: 24px;

				border-bottom: none;
			}
			#totals {
				font-size: 16px;
				font-weight: 300;
				color: var(--v-gray_90-base);

				.v-input.v-text-field {
					.v-input__control {
						.v-text-field__slot {
							input {
								padding-top: 0;
								padding-bottom: 0;
							}
						}
						.v-text-field__details {
							display: none;
						}
						.v-input__slot {
							&:before,
							&:after {
								border-style: none !important;
							}
						}
					}
				}

				.line {
					height: 48px;
					float: right;
					clear: right;
					width: 380px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 16px;

					.v-text-field__prefix,
					.v-text-field__suffix {
						line-height: 24px;
					}

					.v-text-field__details {
						display: none;
					}
					.left,
					.right,
					.center {
						height: 48px;
						display: flex;
						justify-content: flex-end;
						align-items: center;
						//outline: 1px solid blue;
					}

					.left {
						justify-content: flex-end;
						flex: 1 1 148px;
						padding: 0 12px;
						font-weight: 500;
						color: var(--v-gray_80-base);
					}

					.center {
						flex: 0 0 80px;
						padding-left: 12px;
						&:not(.editable) {
							padding-right: 12px;
						}
					}

					.right {
						flex: 0 0 124px;
						padding-right: 16px;
					}

					&.total {
						font-weight: 600;
						.left {
							display: none;
						}
						.center {
							flex: 1 0 auto;
							padding-left: 0;
							padding-right: 12px;
							text-align: right;
						}
						& > div {
							border-top: 1px solid var(--v-gray_50-base);
						}
					}
					&.balance-due {
						& > div {
							border-top: none !important;
							background-color: var(--v-gray_20-base);
							color: var(--v-gray_80-base);
						}
						.left {
							flex: 0 1 0px;
						}
						.center {
							flex: 1 0 auto;
							padding-left: 0;
							padding-right: 12px;
							font-weight: 500;
						}
						.right {
							font-weight: 300;
						}
						& > div {
							border-top: 1px solid var(--v-gray_50-base);
						}
					}
				}
			}
			.pay-button {
				clear: both;
				text-align: right;
				.v-btn .v-btn__content {
					line-height: 18px;
				}
			}

			#footer {
				//border-top: 1px solid var(--v-gray_50-base);
				clear: both;
				padding-top: 24px;
				padding-bottom: 4px;
				text-align: left;

				.payment-stuff {
					margin-bottom: 16px;

					.line {
						display: flex;
						justify-content: flex-start;
						line-height: 48px;

						.left {
							flex: 0 1 auto;
							text-align: left;
							white-space: nowrap;
						}

						.right {
							flex: 0 0 auto;
							text-align: left;
							padding-right: 16px;
						}
					}
				}

				//p {
				//	margin: 12px 0;
				//	color: var(--v-gray_70-base);
				//}

				h3 {
					font-weight: 500;
					font-size: 16px;
					text-align: left;
					margin: 0;
				}

				#payment-terms,
				#pay-instructions {
					.termItem {
						font-weight: 600;
					}
					.editable {
						opacity: 0;
						transition: opacity 300ms;
					}
					&:hover {
						//background-color: var(--v-gray_10-base);
						.editable {
							opacity: 1;
							transition: opacity 300ms;
						}
					}
				}
			}
		}

		.payment-card-setup {
			margin: 28px auto 44px;
			height: 152px;
			max-width: 684px;
			border-radius: 4px;
			background: var(--v-gray_30-base);
			display: flex;
			align-items: center;
			justify-content: space-between;
			& > div {
				flex: 0 0 50%;
				&:last-child {
					padding: 0 40px 0 12px;
				}
			}
			p {
				font-size: 14px;
				line-height: 20px;
				margin: 8px 0;
			}
		}

		.payment-card-pay-now {
			margin: 28px auto 44px;
			max-width: 684px;
			border-radius: 4px;
			background: var(--v-gray_10-base);
			border: 1px solid var(--v-gray_50-base);
			& > div:first-child {
				height: 124px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				& > div {
					flex: 0 0 50%;
					color: var(--v-black-base);
					&:last-child {
						padding: 0 40px 0 12px;
					}
				}
			}
			& > div:last-child {
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: var(--v-gray_10-base);
			}

			p {
				font-size: 14px;
				line-height: 20px;
				margin: 8px 0;
			}
		}
	}
	//#invoice-wrapper {
	//	position: relative;
	//}
	/* PAID / VOIDED / WRITE-OFF stamp */
	#status-overlay {
		position: absolute;
		opacity: 0.35;
		color: var(--v-gray_70-base);
		transform: rotate(-25deg);
		letter-spacing: 8px;
		z-index: 4;
		font-weight: 600;
		line-height: 1em;
		display: none; // only display completed invoices
		&.paid,
		&.voided,
		&.pending,
		&.write-off {
			display: block;
			top: 130px;
			left: 200px;
			font-size: 172px;
		}
		&.paid {
			top: 110px;
			font-size: 252px;
		}
		&.pending {
			top: 180px;
			left: 0px;
			font-size: 200px;
		}
		&.write-off {
			left: 120px;
			top: 200px;
			width: auto;
		}
	}

	.text-hover-blue input {
		&:hover {
			cursor: pointer !important;
			color: var(--v-primary-base) !important;
		}
	}
</style>
