<template>
	<div>
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
			<label v-html="htmlText" class="question"></label>
			<div class="pt-3">
				<v-checkbox
					dense
					hide-details
					class="box"
					style="margin: 0px; padding: 0px"
					v-model="selected"
					@change="handleChange()"
					v-for="option in options"
					:key="option"
					:label="option"
					:value="option"
					:rules="required ? inputRequired : []"
					:disabled="previewMode"
				></v-checkbox>
				<div v-if="hasOther" class="row-format">
					<v-checkbox
						value="$$other"
						label=""
						dense
						hide-details
						@change="handleChange()"
						style="margin: 0px; padding: 0px"
						v-model="selected"
						:rules="required ? inputRequired : []"
						:disabled="previewMode"
					></v-checkbox>
					<div style="width: 320px">
						<v-text-field
							v-model="otherValue"
							:placeholder="$t('discovery.other')"
							@input="handleChange()"
							dense
							hide-details
							:disabled="selected.indexOf('$$other') === -1 || previewMode"
						></v-text-field>
					</div>
				</div>
			</div>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'CheckboxInput',
		props: ['options', 'htmlText', 'hasOther', 'previewMode','required'],

		data() {
			return {
				selected: [],
				otherValue: '',
				otherSelected: null,
				isValid: false,
			};
		},

		methods: {
			validate: function () {
				return this.$refs.form.validate();
			},

			handleChange: function () {
				let values = [...this.selected];
				let otherIx = this.selected.indexOf('$$other');

				if (otherIx > -1) {
					values[otherIx] = this.otherValue;
				}

				this.$emit('input', values);
			},
		},

		computed: {
			inputRequired() {
				return [this.selected.length > 0 || 'Required']
			}
		}
	};
</script>

<style scoped lang="scss"></style>
