import HttpClient from '../../services/HttpClient';

export default class TemplateService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	/* Start Deliverable Packages */
	getDeliverablePackages() {
		return this.httpClient
			.get('/templates/deliverablePackages')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	postDeliverablePackage(data) {
		return this.httpClient
			.post('/templates/deliverablePackages', data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeliverablePackage(id) {
		return this.httpClient
			.get(`/templates/deliverablePackages/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	putDeliverablePackage(id, data) {
		return this.httpClient
			.put(`/templates/deliverablePackages/${id}`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchDeliverablePackage(id, patch) {
		return this.httpClient
			.patch(`/templates/deliverablePackages/${id}`, patch)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createProject(id, clientId, name, startDate) {
		const params = new URLSearchParams();

		if (clientId !== null && clientId !== undefined) {
			params.append('clientId', clientId);
		}
		if (name !== null && name !== undefined) {
			params.append('name', name);
		}
		if (startDate !== null && startDate !== undefined) {
			params.append('startDate', startDate);
		}

		const queryString = params.toString();
		const url = `/templates/deliverablePackages/${id}/toProject` + (queryString ? `?${queryString}` : '');

		return (
			this.httpClient
				.post(url)
				.then((res) => Promise.resolve(res))
				.catch((err) => Promise.reject(err))
		);
	}

	addToExistingProjectFromDeliverablePackage(id,projectId,filter){
		return this.httpClient
			.post(`/templates/deliverablePackages/${id}/toExistingProject?projectId=${projectId}`, filter)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createProposalDeliverables(id,clientId=null) {
		let params = {};

		if(clientId){
			params = {
				clientId: clientId
			}
		}

		return this.httpClient
			.post(`/templates/deliverablePackages/${id}/toProposalDeliverables`,null, {params : params})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createTemplateFromProject(projectId, name) {
		name = encodeURIComponent(name);
		return (
			this.httpClient
				// .post(`/templates/deliverablePackages/fromProject`, { projectId: projectId, name: name })
				.post(`/templates/deliverablePackages/fromProject?projectId=${projectId}&name=${name}`)
				.then((res) => Promise.resolve(res))
				.catch((err) => Promise.reject(err))
		);
	}

	createTemplateFromProposalDeliverables(deliverables) {
		return this.httpClient
			.post(`/templates/deliverablePackages/fromProposalDeliverables`, deliverables)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deletePackage(id) {
		return this.httpClient
			.delete(`/templates/deliverablePackages/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
	/* End Deliverable Packages */

	/* Start Proposal Templates */
	getProposalTemplates() {
		return this.httpClient
			.get('/templates/proposals')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	countProposalTemplates() {
		return this.httpClient
			.get('/templates/proposals/count')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	postProposalTemplate(data) {
		return this.httpClient
			.post('/templates/proposals', data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProposalTemplate(id) {
		return this.httpClient
			.get(`/templates/proposals/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	duplicateProposalTemplate(id,name){
		return this.httpClient
			.post(`/templates/proposals/${id}/duplicate?name=${encodeURIComponent(name)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	putProposalTemplate(id, data) {
		return this.httpClient
			.put(`/templates/proposals/${id}`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchProposalTemplate(id, patch) {
		return this.httpClient
			.patch(`/templates/proposals/${id}`, patch)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createProposalFromTemplate(id, clientId, opportunityId=null) {
		let params = {
			clientId: clientId
		};

		if(opportunityId){
			params.opportunityId = opportunityId
		}

		return this.httpClient
			.post(`/templates/proposals/${id}/toProposal`,null,{ params: params})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createTemplateFromProposal(proposalId, name) {
		name = encodeURIComponent(name);
		return this.httpClient
			.post(`/templates/proposals/fromProposal?proposalId=${proposalId}&name=${name}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteProposalTemplate(id) {
		return this.httpClient
			.delete(`/templates/proposals/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
	/* End Proposal Templates */


	/* Start Agreement Templates */
	getAgreementTemplates() {
		return this.httpClient
			.get('/templates/agreements')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getAgreementTemplate(id) {
		return this.httpClient
			.get(`/templates/agreements/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	initializeAgreementTemplate(base = null){
		if(base) {
			return this.httpClient
				.post(`/templates/agreements`, base)
				.then((res) => Promise.resolve(res))
				.catch((err) => Promise.reject(err));
		}else {
			return this.httpClient
				.post(`/templates/agreements`)
				.then((res) => Promise.resolve(res))
				.catch((err) => Promise.reject(err));
		}
	}

	duplicateAgreementTemplate(templateId){
		return this.httpClient
			.post(`/templates/agreements/${templateId}/duplicate`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	agreementTemplateToAgreement(templateId, clientId, opportunityId){
		return this.httpClient
			.post(`/templates/agreements/${templateId}/toAgreement`,null,{
				params: {
					clientId,
					opportunityId
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	agreementTemplateFromAgreement(agreementId, name){
		return this.httpClient
			.post(`/templates/agreements/fromAgreement`,null,{
				params: {
					agreementId,
					name: encodeURIComponent(name)
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateAgreementTemplate(template){
		return this.httpClient
			.put(`/templates/agreements/${template.id}`,template)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteAgreementTemplate(id){
		return this.httpClient
			.delete(`/templates/agreements/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	/* End Agreement Templates */


	/* Template Util */
	deleteFileAttachment(id, fileName) {
		return this.httpClient
			.delete(`/templates/proposals/${id}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(id, fileName) {
		return this.httpClient
			.get(`/templates/proposals/${id}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createTerms(id, data) {
		return this.httpClient
			.post(`/templates/proposals/${id}/createTerms`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
