var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format fill-height",
      staticStyle: {
        "background-color": "var(--v-white-base)",
        border: "1px solid var(--v-gray_30-base)",
        "border-radius": "4px"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "px-4 py-2 row-format align-center",
          staticStyle: { "border-bottom": "1px solid var(--v-gray_30-base)" }
        },
        [
          _c("h1", [
            _vm._v(
              _vm._s(_vm.$store.state.podAccount.accountName) +
                ": " +
                _vm._s(_vm.summaryLabel)
            )
          ]),
          _vm.summaries.length
            ? _c(
                "div",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.editSettings } },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "material-symbols-rounded" },
                        [_vm._v("settings")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("div", { staticClass: "row-format fill-height" }, [
        _vm.hasAccess
          ? _c(
              "div",
              {
                staticClass: "text-left show-scrollbar",
                staticStyle: {
                  "min-width": "300px",
                  "overflow-y": "auto",
                  "max-width": "300px",
                  "font-size": "14px",
                  "border-right": "1px solid var(--v-gray_30-base)"
                }
              },
              [
                _c("v-treeview", {
                  attrs: {
                    items: _vm.groupedSummaries,
                    hoverable: "",
                    activatable: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prepend",
                        fn: function(ref) {
                          var item = ref.item
                          var open = ref.open
                          return [
                            item.summaryType !== "DAILY"
                              ? _c(
                                  "v-icon",
                                  { staticClass: "material-symbols-rounded" },
                                  [
                                    _vm._v(
                                      _vm._s(open ? "folder_open" : "folder")
                                    )
                                  ]
                                )
                              : _c(
                                  "v-icon",
                                  { staticClass: "material-symbols-rounded" },
                                  [_vm._v("summarize")]
                                )
                          ]
                        }
                      },
                      {
                        key: "label",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              {
                                class: item.summary ? "pointer" : "",
                                on: {
                                  click: function($event) {
                                    return _vm.openItem(item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    701006301
                  )
                })
              ],
              1
            )
          : _c("div", [
              _vm._v("This feature is not enabled for your user account.")
            ]),
        _vm.currentSummary
          ? _c(
              "div",
              {
                staticClass:
                  "text-left font-14 pt-2 business-summary fill-width"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "show-scrollbar",
                    staticStyle: {
                      "max-height": "calc(100vh - 260px)",
                      "overflow-y": "auto"
                    }
                  },
                  [
                    _c("div", {
                      staticClass: "px-8",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.marked(_vm.currentSummary.summary, {
                            breaks: true
                          })
                        )
                      }
                    }),
                    _vm.aiSession
                      ? _c("ai-chat-core", {
                          staticClass: "px-5 pb-5",
                          attrs: { "existing-session-id": _vm.aiSession.id }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.aiSession &&
                _vm.hasAiAccess &&
                _vm.currentSummary.eventContext.length
                  ? _c(
                      "div",
                      { staticClass: "row-format centered pt-2 pb-2" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "super-action",
                            staticStyle: { height: "38px" },
                            on: { click: _vm.launchAiSession }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("smart_toy")
                            ]),
                            _vm._v(" Ask Moxie assistant")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          : _c(
              "div",
              {
                staticClass: "fill-width py-4 column-format centered",
                staticStyle: { "font-style": "italic", "font-size": "14px" }
              },
              [
                !_vm.isAiEnabled || !_vm.summaries.length
                  ? _c(
                      "div",
                      [
                        _c("h1", [
                          _vm._v(
                            "This feature requires the Moxie Assistant add-on"
                          )
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary" },
                            on: { click: _vm.learnMore }
                          },
                          [_vm._v("Learn more")]
                        )
                      ],
                      1
                    )
                  : _c("div", {}, [
                      _vm._v("Select a summary from the left to begin.")
                    ])
              ]
            )
      ]),
      _vm.showSettings
        ? _c(
            "basic-modal",
            { attrs: { dialog: _vm.showSettings } },
            [
              _c("executive-summary-settings", {
                on: {
                  close: function($event) {
                    _vm.showSettings = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }