<template>
	<div :class="`row-format align-center scope-button ${scope === activeScope || isActive ? 'active' : ''}`" @click="$emit('scope-update',scope)">
		<div>{{label}}</div>
	</div>
</template>

<script>
export default {
	name: "ScopeButton",

	props: ['scope','label','activeScope','isActive'],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">
	.scope-button {
		background-color: var(--v-white-base);
		color: var(--v-gray_60-base);
		border: 1px solid var(--v-gray_30-base);
		border-radius: 12px;
		padding: 4px 8px;
		width:fit-content;
		font-size: 14px;
		height: 40px;
		font-weight: 500;
		cursor: pointer;
		white-space: nowrap;

		&:hover {
			background-color: var(--v-gray_20-base);
		}
	}

	.v-application.theme--light .active {
		background-color: var(--v-gray_10-base);
		color: var(--v-secondary-base);
		border: 1px solid var(--v-gray_30-base);
	}

	.v-application.theme--dark .active{
		background-color: var(--v-gray_10-base);
		color: var(--v-black-base);
		border: 1px solid var(--v-gray_30-base);
	}

</style>