<template>
	<v-container v-if="ourCall" class="font-14" fluid @mouseenter="hovering = true" @mouseleave="hovering = false" @click="$emit('click')">
		<v-row dense align="center">
			<v-col cols="3" align="left" class="row-format">
				<div v-if="!clientId"><client-avatar v-for="client in clients" :key="client.id" :client="client" small class="mr-1"></client-avatar></div>
				<div :class="`text-truncate ${clients.length && !clientId ? 'ml-3' : ''}`">{{contact ? contact.fullName : formattedPhone}}</div>
			</v-col>
			<v-col cols="7" align="left" class="row-format align-center">
				<v-icon color="gray_60" v-if="ourCall.direction === 'Outbound'">$phoneOut</v-icon>
				<v-icon color="gray_60" v-else-if="ourCall.callResult === 'Missed' && !ourCall.callRecording">$phoneMissed</v-icon>
				<v-icon color="gray_60" v-else>$phone</v-icon>
				<v-icon color="gray_60" v-if="ourCall.callRecording" class="ml-2">$voicemail</v-icon>
				<div :class="`ml-2  ${ourCall.unread ? 'brand-bold ' : ''}`">{{ duration }}</div>
				<div class="text-truncate ml-2" v-if="ourCall.transcription">{{ourCall.transcription.text}}</div>
			</v-col>
			<v-col cols="2" align="right" class="nowrap">
				<inbox-actions v-if="hovering" class="ml-auto" @return-to-inbox="returnToInbox()" @snooze="snooze($event)" @archive="archive()" @unread="markCallAsRead()" :unread="ourCall.unread" :snooze-options="snoozeOptions" :folder="ourFolder"></inbox-actions>
				<div v-if="!hovering" style="height:38px">{{ $formatters.formatTimeAgo(communication.date) }}</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import libPhoneNumber from "google-libphonenumber";
	import ClientAvatar from "@/components/ClientAvatar";
	import {DateTime} from "luxon";
	import InboxActions from "@/modules/communicator/inbox/InboxActions";
	import RecentCallMixin from "@/modules/communicator/inbox/calls/RecentCallMixin";

	export default {
		name: 'RecentCall',

		props: ['communication','snoozeOptions','folder','clientId'],

		components: { ClientAvatar, InboxActions },

		mixins: [RecentCallMixin],

		data: function () {
			return {
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
				hovering: false,
			};
		},

		mounted() {
			this.ourCall = {...this.communication.payload}
			this.ourFolder = this.folder;
		},

		beforeDestroy() {},

		methods: {
			formatPhone: function (phoneNumber) {
				try {
					let phone = this.PhoneUtil.parse(phoneNumber);
					return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
				} catch (err) {
					return phoneNumber;
				}
			},

			formatSecondsLong(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				if (hours > 0) {
					return hours + ':' + minutes + ':' + seconds;
				} else {
					return minutes + ':' + seconds;
				}
			},
		},

		computed: {
			start: function(){
				return DateTime.fromISO(this.ourCall.startTime);
			},

			end: function(){
				return DateTime.fromISO(this.ourCall.endTime);
			},

			formattedPhone: function(){
				return this.formatPhone(this.ourCall.direction === 'Inbound' ? this.ourCall.from : this.ourCall.to)
			},

			duration: function () {
				let seconds = Math.ceil(this.end.diff(this.start, ['seconds']).seconds);
				return this.formatSecondsLong(seconds);
			},

			clients: function () {
				let result = []
				this.communication.clientIds.forEach((c) => {
					let client = this.$store.getters.getClientById(c);
					if(client){
						result.push(client);
					}
				});
				return result;
			},

			contact: function () {
				if(!this.ourCall){
					return null;
				}else if(this.ourCall.direction === 'Inbound'){
					return this.$store.getters.getContactByPhone(this.ourCall.from);
				}else{
					return this.$store.getters.getContactByPhone(this.ourCall.to);
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>