var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-format" }, [
    _c(
      "div",
      { staticClass: "font-14" },
      [
        _vm._v(
          " Country: " +
            _vm._s(_vm.twilioAccount.country) +
            " | Account Status: " +
            _vm._s(_vm.twilioAccount.status) +
            " | End user type: " +
            _vm._s(_vm.twilioAccount.endUserType) +
            " | "
        ),
        _c(
          "v-icon",
          {
            staticClass: "pointer material-symbols-rounded",
            on: {
              click: function($event) {
                return _vm.deleteAccount()
              }
            }
          },
          [_vm._v("delete")]
        )
      ],
      1
    ),
    _vm.twilioAccount.status === "Approved" && !_vm.subscription
      ? _c(
          "div",
          [
            _c("twilio-numbers", {
              attrs: { "twilio-account": _vm.twilioAccount },
              on: {
                "order-complete": function($event) {
                  return _vm.$emit("refresh")
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }