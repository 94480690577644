var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "column-format gap-2 pt-3", attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                ref: "searchForm",
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  placeholder: "Search login screen background image"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchPhotos()
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", small: "" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.searchPhotos()
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("$search")])],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.searchTerm,
                  callback: function($$v) {
                    _vm.searchTerm = $$v
                  },
                  expression: "searchTerm"
                }
              }),
              _c("div", { staticClass: "images-wrapper" }, [
                _vm.photosReady
                  ? _c(
                      "div",
                      { staticClass: "image-grid" },
                      _vm._l(_vm.photoResults, function(result) {
                        return _c("div", { key: result.id }, [
                          _c("div", {
                            staticClass: "image-container",
                            style:
                              "background-image: url(" +
                              result.urls.thumb +
                              ");",
                            on: {
                              click: function($event) {
                                return _vm.setUnsplashImage(result)
                              }
                            }
                          }),
                          _c("div", { staticClass: "credit-container" }, [
                            _c(
                              "a",
                              {
                                staticClass: "credit-anchor",
                                attrs: {
                                  href: "" + result.user.links.html,
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(result.user.first_name) +
                                    " " +
                                    _vm._s(result.user.last_name) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _c("div", { staticClass: "row-format align-center" }, [
                  _c(
                    "div",
                    { staticClass: "row-format gap-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary-action",
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.previousPage()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: "15" } }, [
                            _vm._v("$chevronLeft")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary-action",
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.nextPage()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: "15" } }, [
                            _vm._v("$chevronRight")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "ml-auto font-10 mt-1 text-right" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("proposal.cover.photos-by")) + " "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://unsplash.com/?utm_source=hectic&utm_medium=referral",
                            target: "_blank"
                          }
                        },
                        [_vm._v("Unsplash")]
                      )
                    ]
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pt-5", attrs: { cols: "3" } },
            [
              _c(
                "vue-dropzone",
                {
                  ref: "fileDropzone",
                  staticClass: "image-container no-image-container",
                  attrs: {
                    id: "dropzone",
                    "use-custom-slot": true,
                    options: _vm.dropzoneOptions
                  },
                  on: {
                    "vdropzone-success": _vm.uploadSuccess,
                    "vdropzone-error": _vm.uploadFailure,
                    "vdropzone-sending": _vm.addFileUploadAuthHeader
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-icon", [_vm._v("upload")]),
                      _c("div", { staticClass: "font-12" }, [
                        _vm._v("Upload custom background")
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            _vm._l(_vm.imageAssets, function(image) {
              return _c(
                "div",
                {
                  key: image.id,
                  staticClass: "pointer",
                  staticStyle: { position: "relative", width: "fit-content" },
                  on: {
                    click: function($event) {
                      return _vm.setCustomImageFromAsset(image)
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: image.thumbnail, alt: image.name }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        position: "absolute",
                        top: "8px",
                        right: "8px"
                      },
                      attrs: { elevation: "0", small: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteImageAsset(image)
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "material-symbols-rounded" },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "text-left pt-4", attrs: { dense: "" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("div", [_vm._v("Login page welcome message")]),
            _c(
              "div",
              {
                staticClass: "pa-2",
                style:
                  "border: 1px solid var(--v-gray_20-base); border-radius: 4px; font-family: " +
                  _vm.branding.font
              },
              [
                _c("editor", {
                  ref: "loginGreeting",
                  attrs: {
                    "api-key": _vm.$store.getters.getTinyMceKey,
                    "initial-value": _vm.branding.loginGreeting,
                    inline: true,
                    init: _vm.mceConfig,
                    spellcheck: true
                  },
                  on: {
                    input: function($event) {
                      return _vm.handleTextInput("loginGreeting")
                    }
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "text-left pt-4" },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              [
                _vm._v("Default welcome page message "),
                _c(
                  "v-icon",
                  {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: {
                          content:
                            "This message is visible if you do not create a custom home page"
                        },
                        expression:
                          "{content:'This message is visible if you do not create a custom home page'}"
                      }
                    ],
                    staticClass: "material-symbols-rounded",
                    attrs: { size: "20" }
                  },
                  [_vm._v("help")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "pa-2",
                style:
                  "border: 1px solid var(--v-gray_20-base); border-radius: 4px; font-family: " +
                  _vm.branding.font
              },
              [
                _c("editor", {
                  ref: "welcomeGreeting",
                  attrs: {
                    "api-key": _vm.$store.getters.getTinyMceKey,
                    "initial-value": _vm.branding.welcomeGreeting,
                    inline: true,
                    init: _vm.mceConfig,
                    spellcheck: true
                  },
                  on: {
                    input: function($event) {
                      return _vm.handleTextInput("welcomeGreeting")
                    }
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "text-left pt-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-select", {
                staticClass: "mb-2",
                attrs: {
                  "hide-details": "",
                  "persistent-placeholder": "",
                  dense: "",
                  outlined: "",
                  label: "Login box position",
                  items: _vm.loginBoxPosition
                },
                model: {
                  value: _vm.branding.loginBoxPosition,
                  callback: function($$v) {
                    _vm.$set(_vm.branding, "loginBoxPosition", $$v)
                  },
                  expression: "branding.loginBoxPosition"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "text-left pt-4" },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("div", [_vm._v("Preview")])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("mock-login", {
                staticClass: "preview-window",
                attrs: { branding: _vm.branding }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }