var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "project-row",
      style:
        _vm.index > 0 ? "border-top: 1px solid var(--v-gray_30-base);" : "",
      attrs: { dense: "" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "v-col",
        { staticClass: "row-format align-center gap-2", attrs: { cols: "4" } },
        [
          _c("client-avatar", { attrs: { client: _vm.project.client } }),
          _c(
            "div",
            {
              staticClass: "column-format text-left text-truncate",
              staticStyle: { "line-height": "18px", "overflow-y": "hidden" }
            },
            [
              _c("div", { staticClass: "font-18 truncate" }, [
                _vm._v(_vm._s(_vm.project.name))
              ]),
              _c("div", { staticClass: "font-14 font-gray_70 truncate" }, [
                _vm._v(_vm._s(_vm.project.client.name))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "text-left",
          attrs: { cols: _vm.showCollaborators ? 3 : 5 }
        },
        [
          _c("div", { staticClass: "row-format" }, [
            _vm.project.timeline
              ? _c("div", { staticClass: "font-gray_70 font-12" }, [
                  _vm._v(" " + _vm._s(_vm.project.timeline) + " ")
                ])
              : _vm._e(),
            _vm.project.nextDueDate
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: { content: _vm.project.nextDueDate.name },
                        expression: "{content: project.nextDueDate.name}"
                      }
                    ],
                    staticClass: "ml-auto font-12 font-gray_70 mr-4",
                    staticStyle: { width: "fit-content" }
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "Next due: " + _vm._s(_vm.project.nextDueDate.date)
                      )
                    ])
                  ]
                )
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "mt-2 mr-4",
              staticStyle: { "max-height": "8px", height: "8px" }
            },
            [
              _vm.project.deliverables.length
                ? _c("deliverable-by-status", {
                    attrs: {
                      project: _vm.project,
                      deliverables: _vm.project.deliverables
                    }
                  })
                : _c("div", {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: {
                          placement: "top",
                          arrow: true,
                          arrowType: "round",
                          animation: "fade",
                          delay: 50,
                          boundary: "window"
                        },
                        expression:
                          "{placement : 'top',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }"
                      }
                    ],
                    staticClass: "mt-1",
                    staticStyle: {
                      height: "100%",
                      width: "100%",
                      "border-radius": "2px",
                      "background-color": "var(--v-gray_20-base)"
                    },
                    attrs: { content: "No tasks" }
                  })
            ],
            1
          )
        ]
      ),
      _c("v-col", { staticClass: "text-left", attrs: { cols: "2" } }, [
        _vm.isFullUser
          ? _c("div", { staticClass: "font-12 font-gray_70" }, [
              _vm._v("Project fees")
            ])
          : _vm._e(),
        _vm.isFullUser
          ? _c("div", {}, [_vm._v(_vm._s(_vm.project.fees))])
          : _vm._e()
      ]),
      _vm.showCollaborators
        ? _c("v-col", { staticClass: "text-left", attrs: { cols: "2" } }, [
            _vm.collaborators.length
              ? _c("div", { staticClass: "font-14 font-gray_70 ml-n1" }, [
                  _vm._v("Collaborators")
                ])
              : _vm._e(),
            _vm.collaborators.length
              ? _c(
                  "div",
                  { staticClass: "row-format align-center" },
                  _vm._l(_vm.collaborators, function(collaborator, index) {
                    return _c("assigned-user", {
                      key: collaborator.userId,
                      style:
                        "border-radius: 100px; border: 2px solid var(--v-white-base); z-index: " +
                        (5 - index),
                      attrs: {
                        "assigned-to": collaborator.userId,
                        "show-name": false
                      }
                    })
                  }),
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "v-col",
        { staticClass: "row-format align-center", attrs: { cols: "1" } },
        [
          _vm.isFullUser
            ? _c(
                "v-menu",
                {
                  attrs: {
                    bottom: "",
                    left: "",
                    rounded: "",
                    "offset-overflow": "",
                    "offset-y": "",
                    "close-on-content-click": true,
                    "close-on-click": true
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "ml-auto" },
                              [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "menu-activator",
                                      attrs: { icon: "" }
                                    },
                                    scope.on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          scope.value
                                            ? "$arrowUp"
                                            : "$moreHorizontal"
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4032028568
                  )
                },
                [
                  _c("div", { staticClass: "more-menu" }, [
                    _c(
                      "div",
                      {
                        staticClass: "more-menu-item",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("click")
                          }
                        }
                      },
                      [_vm._v("View details")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "more-menu-item",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("edit")
                          }
                        }
                      },
                      [_vm._v("Edit")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "more-menu-item",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("mark-complete")
                          }
                        }
                      },
                      [_vm._v("Mark complete")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "more-menu-item",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("client-transfer")
                          }
                        }
                      },
                      [_vm._v("Transfer to another client")]
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }