<template>
	<div>
		<div v-if="assignedUser" v-tippy="{ content: firstName + ' ' + lastName }" class="row-format align-center pointer" @click.stop="clicked">
			<v-avatar :size="size" v-if="assignedUser.profilePicture">
				<img class="pic" :src="assignedUser.profilePicture" />
			</v-avatar>
			<v-avatar :size="size" v-else color="black">
				<span style="color: white;">{{
					firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase()
				}}</span>
			</v-avatar>
			<div class="ml-2 assigned-user-name ellipsis" v-if="showName">{{ firstName }} {{ lastName }}</div>
		</div>
		<div v-else :class="`${emptyClass}`" @click.stop="clicked" v-html="emptyLabel"></div>
	</div>
</template>

<script>
	export default {
		name: 'AssignedUser',

		props: {
			assignedTo: Number,
			showName: {
				type: Boolean,
				default: true
			},
			large: {
				type: Boolean,
				default: false,
			},
			small: {
				type: Boolean,
				default: false,
			},
			medium: {
				type: Boolean,
				default: false,
			},
			emptyLabel: {
				type: String,
				default: '&nbsp;'
			}
		},

		components: {},

		data: function () {
			return {
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			clicked: function(event){
				this.$emit('click',event);
			}
		},

		watch: {

		},

		computed: {
			size: function(){
				if(this.large){
					return 48;
				}else if(this.small) {
					return 20;
				}else if(this.medium){
					return 32;
				}else{
					return 24;
				}
			},

			emptyClass: function(){
				if(this.large){
					return 'empty-large';
				}else if(this.small) {
					return 'empty-small';
				}else if(this.medium){
					return 'empty-medium';
				}else{
					return 'empty';
				}
			},

			assignedUser: function() {
				if(this.assignedTo > 0){
					return this.$store.state.usersForAccount.find((u) => u.userId === this.assignedTo);
				}else{
					return this.$store.getters.getContactByPortalUserId(this.assignedTo);
				}
			},

			firstName: function(){
				if(this.assignedUser && this.assignedUser.firstName){
					return this.assignedUser.firstName;
				}else{
					return '';
				}
			},

			lastName: function(){
				if(this.assignedUser && this.assignedUser.lastName){
					return this.assignedUser.lastName;
				}else{
					return '';
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.empty {
		min-width:25px;
		width: 25px;
		min-height: 25px;
		height: 25px;
		cursor: pointer;
	}

	.empty-small {
		min-width:20px;
		width: 20px;
		min-height: 20px;
		height: 20px;
		cursor: pointer;
	}

	.empty-medium {
		min-width:32px;
		width: 32px;
		min-height: 32px;
		height: 32px;
		cursor: pointer;
	}

	.empty-large {
		min-width:48px;
		width: 48px;
		min-height: 48px;
		height: 48px;
		cursor: pointer;
	}
</style>
