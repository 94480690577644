<template>
	<div class="pa-6" style="max-width: 500px">
		<v-form v-if="screen === 'questions'" id="question" ref="questionForm" @submit.prevent="submitQuestion()" lazy-validation>
			<div class="">
				<h1 class="mb-3">{{ $t('subscription.cancel.feedback.heading') }}</h1>
				<div class="justify-center mb-8 px-4 mx-4">
					{{ $t('subscription.cancel.feedback.subheading') }}
				</div>

				<v-btn class="mb-8" @click="$emit('close')" color="primary" large block>
					{{ $t('subscription.cancel.cancel-button') }}
				</v-btn>

				<v-radio-group v-model="reason" hint="Please make a selection" :rules="rules">
					<div v-for="(question, i) in $t('subscription-v2.downgrade.questions')" :key="i" class="mt-2 mb-3">
						<v-radio :value="question" :label="question" class="custom" />
						<div v-if="reason === question" class="ma-0">
							<v-text-field
								v-model="comments"
								:label="$t('subscription.cancel.feedback.comment-label')"
							></v-text-field>
						</div>
					</div>
				</v-radio-group>
				<v-btn type="submit" class="mt-2" large color="accent" block>{{ $t('subscription.cancel.feedback.button') }}</v-btn>
			</div>
		</v-form>

		<v-form v-if="screen === 'improvement'" @submit.prevent="submitImprovement()">
			<h1 class="mb-3">{{ $t('subscription.cancel.feedback.heading') }}</h1>
			<div class="justify-center mb-12 px-2 mx-2">
				{{ $t('subscription.cancel.feedback.subheading2') }}
			</div>

			<div class="mb-12">
				<h3>
				{{ $t('subscription.cancel.feedback.improvement') }}
				</h3>
				<v-text-field
					v-model="improve"
				/>
			</div>
			<v-btn class="mb-4" @click="$emit('close')" color="primary" large block>
				{{ $t('subscription.cancel.cancel-button') }}
			</v-btn>
			<v-btn type="submit" large color="accent" block>
				{{ $t('subscription.cancel.feedback.button') }}
			</v-btn>
		</v-form>

		<v-form v-if="screen === 'confirm'" id="confirm" @submit.prevent="downgradeFeedback()">
			<div>
				<h1>See ya! <v-icon size="20" color="primary">waving_hand</v-icon></h1>
				<div>Thank you for providing feedback on your experience with Moxie.<br><br>You will have access to your Moxie workspace until the end of your current billing cycle.  We recommend using the Import/Export tool to download your workspace data prior to the end of your current billing cycle.</div>
				<v-btn type="submit" block large color="primary" class="mt-4">{{
					$t('subscription.cancel.confirm.button')
				}}</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
	import SubscriptionService from '../SubscriptionService';
	export default {
		name: 'PlanCancel',
		props: {  },

		data() {
			return {
				subscriptionService: new SubscriptionService(),
				button: '',
				cancelButton: '',
				buttonText: '',
				reason: '',
				comments: '',
				improve: '',
				screen: 'questions',
				questionError: false,
			};
		},

		mounted() {
		},

		methods: {
			submitQuestion() {
				if (this.$refs.questionForm.validate()) {
					this.screen = 'improvement';
				}
			},
			submitImprovement() {
				this.screen = 'confirm';
			},
			alert(type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},
			downgradeFeedback() {
				this.subscriptionService
					.downgradeFeedback(this.reason, this.comments, this.improve)
					.then(() => {
						this.$emit('confirmed');
					})
					.catch((error) => {
						console.log('postChange error', error.response);
						this.alert('error', 'An error occurred.');
					});
			},
		},
		computed: {
			rules() {
				let rules = [];
				if (this.reason.length == 0) {
					const rule = 'Please make a selection to continue.';
					rules.push(rule);
				}
				return rules;
			},
		},
	};
</script>

<style scoped>
	.custom /deep/ label {
		color: var(--v-black-base) !important;
		opacity: 1 !important;
	}
</style>
