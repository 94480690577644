import HttpClient from "@/services/HttpClient";

export default class AutomationService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getByWorkflowId(workflowId) {
		return this.httpClient
			.get('/automations/byWorkflow', {
				params: {
					workflowId: workflowId
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getByAutomationScope(resourceType, resourceId) {
		return this.httpClient
			.get('/automations/byScope', {
				params: {
					resourceType: resourceType,
					resourceId: resourceId
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	cancelAutomation(id) {
		return this.httpClient
			.post(`/automations/${id}/cancel`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	restartAutomation(id) {
		return this.httpClient
			.post(`/automations/${id}/restart`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteAutomation(id) {
		return this.httpClient
			.delete(`/automations/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
