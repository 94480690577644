var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "close-on-content-click": "", "close-on-click": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("div", _vm._g({ staticClass: "pointer last-event" }, on), [
                _vm._v(_vm._s(_vm.lastEvent))
              ])
            ]
          }
        }
      ])
    },
    [
      _c(
        "div",
        {
          staticClass: "pa-4 text-left column-format gap-2",
          staticStyle: { "background-color": "var(--v-white-base)" }
        },
        _vm._l(_vm.reversedEvents, function(event) {
          return _c(
            "div",
            {
              key: event.timestamp,
              staticClass: "last-event",
              staticStyle: { "font-size": "16px" }
            },
            [_vm._v(" " + _vm._s(_vm.formatEvent(event)) + " ")]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }