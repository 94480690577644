<template>
	<div v-if="mode === 'standard' || mode === 'dense'" style="width:100%" class="row-format align-center">
		<v-text-field
				hide-details
				persistent-placeholder
				:dense="mode === 'dense'"
				outlined
				v-model="ourValue"
				:label="field.name"
				@input="handleChange"
		>
			<template v-slot:append>
				<span v-if="ourValue" class="material-symbols-rounded font-18 font-primary pointer" @click="openLink()">open_in_new</span>
			</template>
		</v-text-field>
	</div>
	<div v-else class="row-format align-center" style="width:100%">
		<input v-model="ourValue" type="url" placeholder="--" @change="handleChange" style="color: var(--v-black-base); width:100%"/>
		<span v-if="ourValue" class="material-symbols-rounded font-18 font-primary pointer" @click="openLink()">open_in_new</span>
	</div>
</template>

<script>

	export default {
		name: 'CustomText',

		props: ['field', 'value','mode'],

		components: {  },

		data: function() {
			return {
				ourValue: this.value,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleChange: function(){
				this.$emit('change',this.ourValue);
			},

			openLink: function(){
				if(this.ourValue.toLowerCase().startsWith('http://') || this.ourValue.toLowerCase().startsWith('https://')) {
					window.open(this.ourValue, '_blank');
				}else{
					window.open('https://' + this.ourValue,'_blank');
				}
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	input:focus {
		outline: 1px solid var(--v-gray_30-base);
	}
</style>
