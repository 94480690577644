var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row-format mb-3" }, [
      _c(
        "div",
        {
          staticClass: "numberWidget mr-3 column-format clickable",
          on: {
            click: function($event) {
              return _vm.$emit("clicked", "hours")
            }
          }
        },
        [
          _c("div", { staticClass: "header" }, [_vm._v("Hours worked")]),
          _c("div", { staticClass: "body" }, [
            _vm._v(
              _vm._s(_vm.$formatters.formatSecondsLong(_vm.totalSecondsWorked))
            )
          ])
        ]
      ),
      _c("div", { staticClass: "numberWidget" }, [
        _c("div", { staticClass: "header" }, [
          _vm._v("Avg hours per " + _vm._s(_vm.scope))
        ]),
        _c("div", { staticClass: "body" }, [
          _vm._v(
            _vm._s(
              _vm.$formatters.formatSecondsLong(_vm.averageSecondsPerScope)
            )
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row-format" }, [
      _c(
        "div",
        {
          staticClass: "numberWidget mr-3 column-format clickable",
          on: {
            click: function($event) {
              return _vm.$emit("clicked", "un-billed")
            }
          }
        },
        [
          _c("div", { staticClass: "header" }, [_vm._v("Un-billed Hours")]),
          _c("div", { staticClass: "body" }, [
            _vm._v(
              _vm._s(
                _vm.$formatters.formatSecondsLong(_vm.totalSecondsUnBilled)
              )
            )
          ])
        ]
      ),
      _vm.$store.getters.hasInvoiceAccess
        ? _c("div", { staticClass: "numberWidget" }, [
            _vm._m(0),
            _c("div", { staticClass: "body" }, [
              _vm._v(_vm._s(_vm.$formatters.dollars(_vm.trueHourlyRate)))
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header row-format align-center" }, [
      _c("div", [_vm._v("True hourly rate")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }