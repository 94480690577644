var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { attrs: { id: "recurring-expense-detail" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-padding" },
            [
              _c("h3", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("recurring.expenses.heading")) +
                    " - " +
                    _vm._s(
                      _vm.$formatters.dollars(
                        _vm.total,
                        true,
                        true,
                        _vm.expenseCopy.currency
                      )
                    ) +
                    " "
                )
              ]),
              _c("recurring-event-config", {
                attrs: { recurring: _vm.recurring }
              }),
              _c("hr", {
                staticClass: "mt-3 mb-6",
                staticStyle: {
                  "border-top": "1px solid var(--v-gray_50-base)",
                  "border-bottom": "none"
                }
              }),
              _c(
                "v-container",
                { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pr-1", attrs: { cols: "6" } },
                        [
                          _c(
                            "div",
                            { staticClass: "column-format gap-3" },
                            [
                              _c(
                                "div",
                                { staticClass: "row-format gap-2" },
                                [
                                  _c("amount-input", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      label: _vm.$t("expenses.amount-label"),
                                      placeholder: _vm.$t(
                                        "expenses.amount-placeholder"
                                      ),
                                      prefix: _vm.currencySymbol,
                                      type: "text",
                                      "persistent-placeholder": ""
                                    },
                                    model: {
                                      value: _vm.expenseCopy.amount,
                                      callback: function($$v) {
                                        _vm.$set(_vm.expenseCopy, "amount", $$v)
                                      },
                                      expression: "expenseCopy.amount"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      label: "Tax rate",
                                      placeholder: "Tax %",
                                      suffix: "%",
                                      type: "number",
                                      "persistent-placeholder": ""
                                    },
                                    model: {
                                      value: _vm.expenseCopy.taxRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.expenseCopy,
                                          "taxRate",
                                          $$v
                                        )
                                      },
                                      expression: "expenseCopy.taxRate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "row-format gap-2" },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "47%" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          label: _vm.$t("account.currency"),
                                          items: _vm.currencies,
                                          "item-text": "value",
                                          "item-value": "value"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.label) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1159317991
                                        ),
                                        model: {
                                          value: _vm.expenseCopy.currency,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.expenseCopy,
                                              "currency",
                                              $$v
                                            )
                                          },
                                          expression: "expenseCopy.currency"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-checkbox", {
                                    attrs: {
                                      disabled: _vm.expenseCopy.taxRate === 0,
                                      "hide-details": "",
                                      dense: "",
                                      label: "Tax inclusive"
                                    },
                                    model: {
                                      value: _vm.expenseCopy.taxInclusive,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.expenseCopy,
                                          "taxInclusive",
                                          $$v
                                        )
                                      },
                                      expression: "expenseCopy.taxInclusive"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-textarea", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  label: _vm.$t("expenses.description-label"),
                                  placeholder: _vm.$t(
                                    "expenses.description-placeholder"
                                  ),
                                  "auto-grow": true,
                                  rows: "1",
                                  "persistent-placeholder": ""
                                },
                                model: {
                                  value: _vm.expenseCopy.description,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.expenseCopy,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "expenseCopy.description"
                                }
                              }),
                              _c("v-autocomplete", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  label: _vm.$t("expenses.category-label"),
                                  placeholder: _vm.$t(
                                    "expenses.category-placeholder"
                                  ),
                                  items: _vm.expenseCategoriesPlusClear
                                },
                                on: { change: _vm.categoryChanged },
                                model: {
                                  value: _vm.expenseCopy.category,
                                  callback: function($$v) {
                                    _vm.$set(_vm.expenseCopy, "category", $$v)
                                  },
                                  expression: "expenseCopy.category"
                                }
                              }),
                              _c("v-autocomplete", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  label: _vm.$t("expenses.vendor-label"),
                                  placeholder: _vm.$t(
                                    "expenses.vendor-placeholder"
                                  ),
                                  items: _vm.vendorsPlusClear,
                                  "item-text": "name",
                                  "item-value": "id"
                                },
                                on: { change: _vm.vendorChanged },
                                model: {
                                  value: _vm.expenseCopy.vendorId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.expenseCopy, "vendorId", $$v)
                                  },
                                  expression: "expenseCopy.vendorId"
                                }
                              }),
                              _c("v-select", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  label: "Automatically mark as paid",
                                  placeholder: _vm.$t("expenses.paid-question"),
                                  items: _vm.paidOptions
                                },
                                model: {
                                  value: _vm.expenseCopy.paid,
                                  callback: function($$v) {
                                    _vm.$set(_vm.expenseCopy, "paid", $$v)
                                  },
                                  expression: "expenseCopy.paid"
                                }
                              }),
                              !_vm.expenseCopy.paid
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-14 text-left font-gray_70"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("recurring.expenses.due")
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-space-between half",
                                        staticStyle: { gap: "12px" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            type: "number",
                                            inputmode: "number",
                                            placeholder: _vm.$t(
                                              "templates.packages.deliverable-detail-modal.duration-placeholder",
                                              {
                                                units: _vm.humanUnits
                                              }
                                            ),
                                            "hide-details": "",
                                            dense: "",
                                            outlined: "",
                                            "persistent-placeholder": ""
                                          },
                                          on: {
                                            keydown: function($event) {
                                              return _vm.handleDurationKeydown(
                                                $event
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.expenseCopy.dueDate.duration,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.expenseCopy.dueDate,
                                                "duration",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "expenseCopy.dueDate.duration"
                                          }
                                        }),
                                        _c("v-select", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "templates.packages.deliverable-detail-modal.units-placeholder"
                                            ),
                                            "hide-details": "",
                                            dense: "",
                                            outlined: "",
                                            "persistent-placeholder": "",
                                            items: _vm.timeUnits,
                                            "item-value": "value",
                                            "item-text": "text"
                                          },
                                          model: {
                                            value:
                                              _vm.expenseCopy.dueDate.timeUnit,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.expenseCopy.dueDate,
                                                "timeUnit",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "expenseCopy.dueDate.timeUnit"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pl-3", attrs: { cols: "6" } },
                        [
                          _c(
                            "div",
                            { staticClass: "column-format gap-3" },
                            [
                              _c("v-autocomplete", {
                                ref: "clientId",
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  label: _vm.$t("client.client"),
                                  placeholder: _vm.$t(
                                    "expenses.link-to-client"
                                  ),
                                  items: _vm.clientsPlusClear,
                                  "item-text": "name",
                                  "item-value": "id"
                                },
                                on: { change: _vm.clientChanged },
                                model: {
                                  value: _vm.expenseCopy.clientId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.expenseCopy, "clientId", $$v)
                                  },
                                  expression: "expenseCopy.clientId"
                                }
                              }),
                              _vm.expenseCopy.clientId
                                ? _c(
                                    "div",
                                    { staticClass: "column-format gap-3" },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          label: _vm.$t("projects.project"),
                                          placeholder: _vm.$t(
                                            "expenses.link-to-project"
                                          ),
                                          disabled: _vm.noClientDisabled,
                                          items: _vm.clientProjectsPlusClear,
                                          "item-text": "name",
                                          "item-value": "id",
                                          "no-data-text":
                                            "No projects for selected client"
                                        },
                                        on: { change: _vm.projectChanged },
                                        model: {
                                          value: _vm.expenseCopy.projectId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.expenseCopy,
                                              "projectId",
                                              $$v
                                            )
                                          },
                                          expression: "expenseCopy.projectId"
                                        }
                                      }),
                                      _c("v-checkbox", {
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          label: _vm.$t(
                                            "expenses.reimburse-status"
                                          )
                                        },
                                        model: {
                                          value: _vm.expenseCopy.reimbursable,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.expenseCopy,
                                              "reimbursable",
                                              $$v
                                            )
                                          },
                                          expression: "expenseCopy.reimbursable"
                                        }
                                      }),
                                      _vm.expenseCopy.reimbursable
                                        ? _c(
                                            "div",
                                            [
                                              _c("amount-input", {
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  "hide-details": "",
                                                  label:
                                                    "Markup percentage to client",
                                                  suffix: "%",
                                                  type: "number",
                                                  "persistent-placeholder": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.expenseCopy
                                                      .markupPercent,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.expenseCopy,
                                                      "markupPercent",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "expenseCopy.markupPercent"
                                                }
                                              }),
                                              _vm.expenseCopy.markupPercent
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "body-12 mt-2 mb-3 gray_80--text text-left"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " +" +
                                                          _vm._s(
                                                            _vm.$formatters.dollars(
                                                              _vm.markup,
                                                              true,
                                                              true,
                                                              _vm.expenseCopy
                                                                .currency
                                                            )
                                                          ) +
                                                          " markup = " +
                                                          _vm._s(
                                                            _vm.$formatters.dollars(
                                                              _vm.totalWithMarkup,
                                                              true,
                                                              true,
                                                              _vm.expenseCopy
                                                                .currency
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mt-2 pa-2",
              staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "super-action",
                  attrs: {
                    width: _vm.$vuetify.breakpoint.smAndUp ? "160" : "128"
                  },
                  on: { click: _vm.saveRecurringExpense }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(!_vm.id ? "global.create" : "global.save")
                      ) +
                      " "
                  )
                ]
              ),
              _vm.recurring.id
                ? _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteRecurringExpense()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "20" }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }