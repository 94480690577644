import HttpClient from '@/services/HttpClient';

export default class CalDavCalendarService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    getAllAccounts() {
        return this.httpClient
            .get('/caldav')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getAccounts() {
        return this.httpClient
            .get('/caldav/connection')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    connect(email,password, url, name) {
        let params = {
            email:email,
            password:password,
            url:url,
            name:name
        }

        return this.httpClient
            .post(`/caldav/connection`,null, {
                params: params
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    update(account){
        return this.httpClient
            .put(`/caldav/connection/${account.id}`,account)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    verify(id) {
        return this.httpClient
            .post(`/caldav/connection/${id}/verify`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    disconnect(id) {
        return this.httpClient
            .delete(`/caldav/connection/${id}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}