import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class CommunicatorEmailService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
	}

	getEmailBoxes() {
		return this.httpClient
			.get('/emailBox')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEmailBoxesForAccount() {
		return this.httpClient
			.get('/emailBox/all')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEmailThreads(earliest, latest, archived = false, clientId) {
		let params = {
			earliest: earliest,
			latest: latest,
			archived: archived,
			clientId: clientId,
		};

		return this.httpClient
			.get('/email/threads', { params: params })
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getThread(threadId) {
		return this.httpClient
			.get(`/email/threads/detail/${threadId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEmailsInThread(threadId) {
		return this.httpClient
			.get(`/email/threads/${threadId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	markThreadAsRead(threadId, read = true) {
		return this.httpClient
			.post(`/email/threads/${threadId}/read`, null, {
				params: {
					read: read,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setArchiveFlag(threadId, archive = true) {
		return this.httpClient
			.post(`/email/threads/${threadId}/archive`, null, {
				params: {
					archive: archive,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setSnooze(threadId, snoozeUntil) {
		return this.httpClient
			.post(`/email/threads/${threadId}/snooze`, null, {
				params: {
					snoozeUntil: snoozeUntil,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateSummary(threadId, summary) {
		return this.httpClient
			.put(`/email/threads/${threadId}/summary`, {
				content: summary
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	returnToInbox(threadId) {
		return this.httpClient
			.post(`/email/threads/${threadId}/returnToInbox`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendNewEmail(mailboxId, email, attachments, s3Attachments = [], sendLater = null) {
		let formData = new FormData();

		formData.append('mailboxId', mailboxId);
		formData.append('email', JSON.stringify(email));
		formData.append('s3Attachments', JSON.stringify(s3Attachments));
		if(sendLater) {
			formData.append('sendLater', sendLater);
		}
		attachments.forEach((a) => {
			formData.append('attachments', a);
		});

		return this.httpClient
			.post(`/email`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendEmail(threadId, email, attachments, s3Attachments = [], sendLater = null) {
		let formData = new FormData();

		formData.append('email', JSON.stringify(email));
		formData.append('s3Attachments', JSON.stringify(s3Attachments));
		if(sendLater) {
			formData.append('sendLater', sendLater);
		}
		attachments.forEach((a) => {
			formData.append('attachments', a);
		});

		return this.httpClient
			.post(`/email/threads/${threadId}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEmailContent(mailboxId, messageId) {
		let params = {
			mailboxId: mailboxId,
			messageId: encodeURIComponent(messageId),
		};
		return this.httpClient
			.get(`/email/messageContent`, { params: params })
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEmailAttachment(mailboxId, messageId, attachmentName) {
		let params = {
			mailboxId: mailboxId,
			messageId: encodeURIComponent(messageId),
			attachmentName: attachmentName,
		};
		return this.httpClient
			.get(`/email/messageContent/attachment`, {
				params: params,
				responseType: 'blob',
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
