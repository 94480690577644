var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isInitialized
    ? _c("div", { staticClass: "column-format" }, [
        _c("div", { staticClass: "row-format gap-3" }, [
          _c("div", { staticClass: "kpi-box" }, [
            _c("div", { staticClass: "fit text-left" }, [
              _vm._v(_vm._s(_vm.$t("timetrack.list.total-worked")))
            ]),
            _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
              _vm._v(
                _vm._s(_vm.$formatters.formatSeconds(_vm.metrics.totalTime))
              )
            ])
          ]),
          _c("div", { staticClass: "kpi-box" }, [
            _c("div", { staticClass: "row-format" }, [
              _c("div", { staticClass: "row-format align-center fit" }, [
                _c("div", [
                  _vm._v(_vm._s(_vm.$t("timetrack.list.by-deliverable")))
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "row-format mt-1 status-wrapper" },
              _vm._l(_vm.metrics.deliverables, function(deliverable) {
                return _c("div", {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: {
                        content:
                          deliverable.name +
                          " - " +
                          _vm.$formatters.formatSeconds(deliverable.totalTime)
                      },
                      expression:
                        "{ content: `${deliverable.name} - ${$formatters.formatSeconds(deliverable.totalTime)}` }"
                    }
                  ],
                  key: deliverable.name,
                  staticClass: "status-box",
                  style:
                    "width:" +
                    deliverable.percentage +
                    "%; background-color: " +
                    deliverable.color
                })
              }),
              0
            )
          ]),
          _c("div", { staticClass: "kpi-box" }, [
            _c("div", { staticClass: "fit text-left" }, [
              _vm._v(_vm._s(_vm.$t("timetrack.list.this-week")))
            ]),
            _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
              _vm._v(
                _vm._s(_vm.$formatters.formatSeconds(_vm.metrics.timeThisWeek))
              )
            ])
          ]),
          _c("div", { staticClass: "kpi-box" }, [
            _c("div", { staticClass: "row-format" }, [
              _c("div", { staticClass: "fit text-left" }, [
                _vm._v(_vm._s(_vm.$t("timetrack.list.un-billed")))
              ])
            ]),
            _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
              _vm._v(
                _vm._s(_vm.$formatters.formatSeconds(_vm.metrics.unBilled))
              )
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _vm._t("filter"),
            _c(
              "div",
              { staticStyle: { "background-color": "var(--v-white-base)" } },
              [
                _vm.events.length
                  ? _c("timer-event-table", {
                      staticClass: "mt-4",
                      attrs: { events: _vm.filteredEvents },
                      on: {
                        "add-new": function($event) {
                          return _vm.addNew()
                        },
                        duplicate: function($event) {
                          return _vm.duplicate($event)
                        },
                        "edit-timer": function($event) {
                          return _vm.editTimerEvent($event)
                        },
                        "open-invoice": function($event) {
                          return _vm.openInvoice($event)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            !_vm.events.length
              ? _c(
                  "div",
                  {
                    staticClass: "row-format centered fill-height mt-8",
                    staticStyle: { flex: "1" }
                  },
                  [
                    _c("empty-view", {
                      attrs: {
                        header: "No time entries yet",
                        body:
                          "If you track your time for " +
                          _vm.project.name +
                          ", your history of time entries will show up here. In addition to viewing historical entries here, you can also create new entries.",
                        cta: "Create a time entry",
                        "video-header": "See how it works",
                        "video-body":
                          "Learn how to track your time and then add those time entries to an invoice.",
                        "video-cta": "Watch the tutorial",
                        "video-id": "O6GEZ3DD02k"
                      },
                      on: {
                        "cta-clicked": function($event) {
                          return _vm.addNew()
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }