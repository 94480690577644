var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format centered", attrs: { id: "login-panel" } },
    [
      _vm.view === "init"
        ? _c(
            "v-container",
            { staticClass: "pa-0 ma-0", staticStyle: { "max-width": "400px" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "12", align: "left" } }, [
                    _c("span", { staticClass: "font-24 brand-medium" }, [
                      _vm._v(_vm._s(_vm.heading))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", align: "center" } }, [
                    _c(
                      "div",
                      { staticClass: "column-format" },
                      [
                        _c("google-sign-in", {
                          key: "GoogleSignIn" + _vm.isLogin,
                          attrs: {
                            "is-login": _vm.isLogin,
                            "accept-terms": true
                          },
                          on: {
                            error: function($event) {
                              return _vm.showError($event)
                            },
                            success: function($event) {
                              return _vm.handleLoginSuccessEvent($event)
                            }
                          }
                        }),
                        _c("apple-sign-in", {
                          staticClass: "mt-2",
                          attrs: {
                            "is-login": _vm.isLogin,
                            "accept-terms": true
                          },
                          on: {
                            error: function($event) {
                              return _vm.showError($event)
                            },
                            success: function($event) {
                              return _vm.handleLoginSuccessEvent($event)
                            }
                          }
                        }),
                        _c("microsoft-sign-in", {
                          staticClass: "mt-2",
                          attrs: {
                            "is-login": _vm.isLogin,
                            "accept-terms": true
                          },
                          on: {
                            error: function($event) {
                              return _vm.showError($event)
                            },
                            success: function($event) {
                              return _vm.handleLoginSuccessEvent($event)
                            }
                          }
                        }),
                        !_vm.isLogin
                          ? _c(
                              "div",
                              {
                                staticClass: "column-format",
                                staticStyle: { width: "100%" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row-format align-center font-14 py-6"
                                  },
                                  [
                                    _c("div", {
                                      staticStyle: {
                                        "background-color":
                                          "var(--v-gray_30-base)",
                                        height: "1px",
                                        "flex-grow": "1"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "px-2 brand-bold" },
                                      [_vm._v("Or sign up with email")]
                                    ),
                                    _c("div", {
                                      staticStyle: {
                                        "background-color":
                                          "var(--v-gray_30-base)",
                                        height: "1px",
                                        "flex-grow": "1"
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "column-format" },
                                  [
                                    _c(
                                      "v-form",
                                      {
                                        ref: "form",
                                        attrs: { "lazy-validation": "" },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                            return _vm.handleFormSubmit()
                                          }
                                        },
                                        model: {
                                          value: _vm.isValid,
                                          callback: function($$v) {
                                            _vm.isValid = $$v
                                          },
                                          expression: "isValid"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "row-format gap-2" },
                                          [
                                            _c("v-text-field", {
                                              staticClass: "h-outline",
                                              staticStyle: {
                                                "background-color":
                                                  "var(--v-white-base)"
                                              },
                                              attrs: {
                                                rules: _vm.firstNameRules,
                                                label: _vm.$t(
                                                  "contact.first-name"
                                                ),
                                                "persistent-placeholder": "",
                                                "hide-details": "",
                                                required: "",
                                                "validate-on-blur": ""
                                              },
                                              model: {
                                                value: _vm.firstName,
                                                callback: function($$v) {
                                                  _vm.firstName = $$v
                                                },
                                                expression: "firstName"
                                              }
                                            }),
                                            _c("v-text-field", {
                                              staticClass: "h-outline",
                                              staticStyle: {
                                                "background-color":
                                                  "var(--v-white-base)"
                                              },
                                              attrs: {
                                                rules: _vm.lastNameRules,
                                                label: _vm.$t(
                                                  "contact.last-name"
                                                ),
                                                "persistent-placeholder": "",
                                                "hide-details": "",
                                                required: "",
                                                "validate-on-blur": ""
                                              },
                                              model: {
                                                value: _vm.lastName,
                                                callback: function($$v) {
                                                  _vm.lastName = $$v
                                                },
                                                expression: "lastName"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("v-text-field", {
                                          staticClass: "h-outline",
                                          staticStyle: {
                                            "background-color":
                                              "var(--v-white-base)",
                                            "margin-top": "-2px"
                                          },
                                          attrs: {
                                            rules: _vm.emailRules,
                                            label: _vm.$t("contact.email"),
                                            "persistent-placeholder": "",
                                            "hide-details": "",
                                            required: "",
                                            "validate-on-blur": ""
                                          },
                                          model: {
                                            value: _vm.email,
                                            callback: function($$v) {
                                              _vm.email = $$v
                                            },
                                            expression: "email"
                                          }
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "login-button",
                                            attrs: {
                                              block: "",
                                              large: "",
                                              disabled: !_vm.isValid
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.handleFormSubmit()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("login.sign-up-button")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.isLogin
                          ? _c(
                              "div",
                              { staticClass: "column-format" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mt-2",
                                    staticStyle: {
                                      height: "40px !important",
                                      "background-color": "var(--v-white-base)"
                                    },
                                    attrs: {
                                      elevation: "0",
                                      width: "400",
                                      outlined: "",
                                      color: "gray_30"
                                    },
                                    on: { click: _vm.emailClicked }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row-format centered",
                                        staticStyle: { width: "100%" }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "material-symbols-rounded",
                                            attrs: { size: 20, color: "black" }
                                          },
                                          [_vm._v("magic_button")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ml-2 font-gray_90 font-14"
                                          },
                                          [_vm._v(_vm._s(_vm.emailButton))]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row-format align-center font-14 py-6"
                                  },
                                  [
                                    _c("div", {
                                      staticStyle: {
                                        "background-color":
                                          "var(--v-gray_30-base)",
                                        height: "1px",
                                        "flex-grow": "1"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "px-2 brand-bold" },
                                      [_vm._v("Or sign in with password")]
                                    ),
                                    _c("div", {
                                      staticStyle: {
                                        "background-color":
                                          "var(--v-gray_30-base)",
                                        height: "1px",
                                        "flex-grow": "1"
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "column-format" },
                                  [
                                    _c(
                                      "v-form",
                                      {
                                        ref: "form",
                                        attrs: { "lazy-validation": "" },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                            return _vm.handleFormSubmit(
                                              "credentials"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.isValid,
                                          callback: function($$v) {
                                            _vm.isValid = $$v
                                          },
                                          expression: "isValid"
                                        }
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "h-outline",
                                          staticStyle: {
                                            "background-color":
                                              "var(--v-white-base)"
                                          },
                                          attrs: {
                                            rules: _vm.emailRules,
                                            label: _vm.$t("contact.email"),
                                            "persistent-placeholder": "",
                                            "hide-details": "",
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.email,
                                            callback: function($$v) {
                                              _vm.email = $$v
                                            },
                                            expression: "email"
                                          }
                                        }),
                                        _c("v-text-field", {
                                          staticClass: "h-outline",
                                          staticStyle: {
                                            "background-color":
                                              "var(--v-white-base)",
                                            "margin-top": "-2px"
                                          },
                                          attrs: {
                                            rules: _vm.passwordRules,
                                            label: "Password",
                                            type: "password",
                                            "persistent-placeholder": "",
                                            "hide-details": "",
                                            required: ""
                                          },
                                          model: {
                                            value: _vm.password,
                                            callback: function($$v) {
                                              _vm.password = $$v
                                            },
                                            expression: "password"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "login-button",
                                    staticStyle: { "border-radius": "4px" },
                                    attrs: {
                                      block: "",
                                      large: "",
                                      disabled: !_vm.isValid
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.handleFormSubmit(
                                          "credentials"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("login.sign-in-button")) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex align-center justify-center gap-4 py-4",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          dense: "",
                          "hide-details": "",
                          label: "Remember my email"
                        },
                        model: {
                          value: _vm.rememberEmail,
                          callback: function($$v) {
                            _vm.rememberEmail = $$v
                          },
                          expression: "rememberEmail"
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "pointer font-gray_70 mt-2",
                          on: { click: _vm.forgotPassword }
                        },
                        [_vm._v("Forgot password?")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "font-14 brand-semilight my-4",
                      attrs: { cols: "12", align: "center" }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.toggleText1) + " "),
                      _c(
                        "span",
                        {
                          staticClass: "primary-link pointer font-primary",
                          on: {
                            click: function($event) {
                              return _vm.toggleInitView()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.toggleText2))]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "font-12 font-gray_70 mb-4",
                      attrs: { cols: "12", align: "center" }
                    },
                    [
                      _vm._v(
                        " By logging in or signing up, you agree to Moxie’s "
                      ),
                      _c(
                        "a",
                        {
                          staticStyle: { color: "#867f77" },
                          attrs: {
                            href: "https://www.withmoxie.com/terms-of-service",
                            target: "_blank"
                          }
                        },
                        [_vm._v("Terms of Service")]
                      ),
                      _vm._v(" and acknowledge that Moxie’s "),
                      _c(
                        "a",
                        {
                          staticStyle: { color: "#867f77" },
                          attrs: {
                            href: "https://www.withmoxie.com/privacy-policy",
                            target: "_blank"
                          }
                        },
                        [_vm._v("Privacy Policy")]
                      ),
                      _vm._v(" applies to you. ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      (_vm.view === "email-form" || _vm.view === "forgot-password") &&
      _vm.isLogin
        ? _c(
            "v-container",
            { staticStyle: { "max-width": "400px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mb-4",
                      attrs: { cols: "12", align: "left" }
                    },
                    [_c("h2", [_vm._v(_vm._s(_vm.heading))])]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mb-4",
                      attrs: { cols: "12", align: "left" }
                    },
                    [_vm._v(" " + _vm._s(_vm.emailInstructions) + " ")]
                  )
                ],
                1
              ),
              _vm.isLogin
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", align: "center" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.preventDefault()
                                  return _vm.handleFormSubmit("magicToken")
                                }
                              },
                              model: {
                                value: _vm.isValid,
                                callback: function($$v) {
                                  _vm.isValid = $$v
                                },
                                expression: "isValid"
                              }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "h-outline",
                                staticStyle: {
                                  "background-color": "var(--v-white-base)"
                                },
                                attrs: {
                                  rules: _vm.emailRules,
                                  label: _vm.$t("contact.email"),
                                  "persistent-placeholder": "",
                                  "hide-details": "",
                                  required: "",
                                  "validate-on-blur": ""
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              }),
                              _c("v-checkbox", {
                                staticClass: "mt-2",
                                attrs: { label: "Remember my email" },
                                model: {
                                  value: _vm.rememberEmail,
                                  callback: function($$v) {
                                    _vm.rememberEmail = $$v
                                  },
                                  expression: "rememberEmail"
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-4 login-button",
                                  attrs: {
                                    block: "",
                                    large: "",
                                    disabled: !_vm.isValid
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.handleFormSubmit("magicToken")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("login.sign-in-button")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row-format centered pointer font-gray_70 mt-4 font-14",
                                  on: {
                                    click: function($event) {
                                      return _vm.goBackToOptions()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "mr-2" }, [
                                    _vm._v("$arrowLeft")
                                  ]),
                                  _c("div", [_vm._v(_vm._s(_vm.backToText))])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.view === "confirm"
        ? _c(
            "v-container",
            { staticStyle: { "max-width": "400px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { color: "secondary", size: "120" }
                        },
                        [_vm._v(" mark_email_read ")]
                      ),
                      _c("h2", { staticClass: "mb-3 mt-4" }, [
                        _vm._v(" " + _vm._s(_vm.$t("login.check-inbox")) + " ")
                      ]),
                      _c("div", {
                        staticClass: "mb-4 py-1",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              _vm.isLogin
                                ? "login.sign-in.email-complete"
                                : "login.sign-up.email-complete",
                              {
                                email: this.email
                              }
                            )
                          )
                        }
                      }),
                      _c("div", {
                        staticClass: "mb-2 py-1 small-label",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("login.close-window"))
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.view === "confirm-sms"
        ? _c(
            "v-container",
            { staticStyle: { "max-width": "400px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "--secondary-color": "var(--v-accent-base)",
                            "--secondary-opacity": "1"
                          },
                          attrs: { color: "primary", size: "120" }
                        },
                        [_vm._v(" settings_cell ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", align: "center" } },
                    [
                      _c("span", [
                        _vm._v(
                          "Please enter the 6 digit code that was sent to phone ending in: " +
                            _vm._s(_vm.smsCodeTo)
                        )
                      ]),
                      _c("br"),
                      _c("br"),
                      _c("code-input", {
                        ref: "smsCodeInput",
                        attrs: {
                          disabled: _vm.smsCodeDisabled,
                          fields: 6,
                          "auto-focus": false,
                          required: true,
                          id: "code-input"
                        },
                        on: { complete: _vm.smsCodeComplete }
                      }),
                      _vm.smsCodeInvalid
                        ? _c("span", { staticStyle: { color: "red" } }, [
                            _c("br"),
                            _vm._v("The code you entered was not valid.")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2",
                          attrs: { icon: "", text: "", color: "black" },
                          on: {
                            click: function($event) {
                              return _vm.goBackToOptions()
                            }
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("$arrowLeft")
                          ]),
                          _vm._v(" " + _vm._s(_vm.backToText) + " ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.view === "error"
        ? _c(
            "v-container",
            { staticStyle: { "max-width": "350px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: {
                            "--secondary-color": "var(--v-accent-base)",
                            "--secondary-opacity": "1"
                          },
                          attrs: { color: "primary", size: "120" }
                        },
                        [_vm._v(" $alarm ")]
                      ),
                      _c(
                        "h2",
                        { staticClass: "text-center black--text mb-2 mb-sm-4" },
                        [_vm._v("Notice")]
                      ),
                      _c("div", {
                        staticClass: "body-1 black--text text-center mb-4 py-1",
                        domProps: { innerHTML: _vm._s(_vm.errorMsg) }
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2 mt-md-4",
                          attrs: { color: "primary", block: "", large: "" },
                          on: {
                            click: function($event) {
                              _vm.isLogin ? _vm.goToSignUp() : _vm.goToLogin()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.errorButton) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2 mt-md-4",
                          attrs: {
                            color: "primary",
                            block: "",
                            large: "",
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.isLogin ? _vm.goToLogin() : _vm.goToSignUp()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.errorButtonSecondary) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }