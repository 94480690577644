import moment from 'moment';
import NativeCalendarService from '@/modules/calendar/mixins/NativeCalendarService';
import NativeEventDetail from '@/modules/calendar/details/NativeEventDetail';

export default {
	methods: {
		round: function(date, duration, method) {
			return moment(Math[method](+date / +duration) * +duration);
		},

		nativeCalendarEventClicked(nativeEvent, events) {
			let binding = {
				event: nativeEvent,
			};
			this.$store.state.globalModalController.openModal(NativeEventDetail, binding, true, false).then((res) => {
				if (!res) return;
				if (res.action && res.action == 'DELETED') {
					if (res.event.id) {
						this.handleNativeEventDelete(res.event, events);
					}
				} else {
					if (res.id) {
						this.handleNativeEventUpdate(res, events);
					} else {
						this.handleNativeEventInsert(res, events);
					}
				}
			});
		},

		createNativeCalendarEvent: function(start, end, events) {
			this.refreshKey++;

			let newEvent = {
				id: null,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				startDate: start.toISODate(),
				startTime: start.toFormat('HH:mm:ss'),
				endDate: end.toISODate('YYYY-MM-DD'),
				endTime: end.toFormat('HH:mm:ss'),
				dateOnly: false,
				sharedEvent: false,
				reminder: true,
				remindTime: 15,
				remindTimeUnit: 'Minutes',
			};

			this.handleNativeEventInsert(newEvent,events).then((res) => {
				this.nativeCalendarEventClicked(res, events);
			})
		},

		handleNativeEventInsert(nativeEvent, events) {
			return new Promise((resolve, reject) => {
				new NativeCalendarService()
					.createUserCalendarEvent(nativeEvent)
					.then((res) => {
						let event = this.convertNativeEvent(res.data, true);
						this.insertOrUpdateEvent(event, events);
						this.refreshKey++;
						resolve(res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						reject(err);
					});
			});
		},

		handleNativeEventUpdate: function(nativeEvent, events) {
			new NativeCalendarService()
				.updateUserCalendarEvent(nativeEvent.id, nativeEvent)
				.then((res) => {
					let converted = this.convertNativeEvent(res.data, false);
					this.insertOrUpdateEvent(converted, events);
					this.refreshKey++;
				})
				.catch((res) => this.$store.commit('error', res.response.data.message));
		},

		handleNativeEventDelete: function(nativeEvent, events) {
			new NativeCalendarService()
				.deleteUserCalendarEvent(nativeEvent.id)
				.then(() => {
					this.removeNativeCalendarEvent(nativeEvent.id, events);
					this.refreshKey++;
				})
				.catch((res) => this.$store.commit('error', res.response.data.message));
		},

		removeNativeCalendarEvent: function(id, events) {
			let ix = events.findIndex((e) => e.eventSource === 'NATIVE' && e.meta.nativeEvent.id === id);
			if (ix > -1) {
				this.events.splice(ix, 1);
			}
		},

		insertOrUpdateEvent: function(calendarEvent, events) {
			let ix = events.findIndex(
				(e) => e.eventSource === 'NATIVE' && e.meta.nativeEvent.id === calendarEvent.meta.nativeEvent.id
			);
			if (ix === -1) {
				events.push(calendarEvent);
			} else {
				events.splice(ix, 1, calendarEvent);
			}
		},

		convertNativeEvent: function(nativeEvent) {
			let converted = {
				id: nativeEvent.id,
				eventSource: 'NATIVE',
				summary: nativeEvent.summary,
				description: nativeEvent.description,
				dateOnly: nativeEvent.dateOnly,
				meta: {
					nativeEvent: nativeEvent,
				},
			};

			if (nativeEvent.dateOnly) {
				converted.start = nativeEvent.startDate;
				converted.end = nativeEvent.endDate;
			} else {
				converted.start = nativeEvent.startDate + 'T' + nativeEvent.startTime;
				converted.end = nativeEvent.endDate + 'T' + nativeEvent.endTime;
			}

			return converted;
		},
	},
};
