var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "row-format" },
        [
          _vm.showBusiness
            ? _c("v-text-field", {
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Business / Organization name",
                  disabled: _vm.previewMode
                }
              })
            : _vm._e(),
          _vm.showWebsite
            ? _c("v-text-field", {
                class: _vm.showBusiness && _vm.showWebsite ? "ml-4" : "",
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: _vm.$t("contact.website"),
                  disabled: true
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.showContact
        ? _c(
            "div",
            { staticClass: "row-format my-2" },
            [
              _c("v-text-field", {
                staticClass: "mr-2",
                attrs: {
                  label: "First name",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  disabled: _vm.previewMode
                }
              }),
              _c("v-text-field", {
                staticClass: "ml-2",
                attrs: {
                  label: "Last name",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  disabled: _vm.previewMode
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "row-format my-2" },
        [
          _vm.showEmail
            ? _c("v-text-field", {
                attrs: {
                  label: "Email",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  disabled: _vm.previewMode
                }
              })
            : _vm._e(),
          _vm.showPhone
            ? _c("v-text-field", {
                class: _vm.showEmail && _vm.showPhone ? "ml-4" : "",
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Phone",
                  disabled: _vm.previewMode
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.showAddress
        ? _c("div", { staticClass: "my-2" }, [
            _c(
              "div",
              { staticClass: "row-format" },
              [
                _c("v-text-field", {
                  staticClass: "mr-4",
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    label: _vm.$t("contact.address1"),
                    disabled: true
                  }
                }),
                _c("v-text-field", {
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    label: _vm.$t("contact.address2"),
                    disabled: true
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row-format my-2" },
              [
                _c("v-text-field", {
                  staticClass: "mr-4",
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    label: _vm.$t("contact.city"),
                    disabled: true
                  }
                }),
                _c("v-text-field", {
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    label: _vm.$t("contact.locality"),
                    disabled: true
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row-format my-2" },
              [
                _c("v-text-field", {
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    label: _vm.$t("contact.postal"),
                    disabled: true
                  }
                }),
                _c(
                  "div",
                  { staticClass: "ml-4", staticStyle: { width: "49%" } },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        disabled: true,
                        label: _vm.$t("contact.country"),
                        items: _vm.countries.getCountriesSimple(),
                        "item-value": "code",
                        "item-text": "name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "row-format" }, [
      _c(
        "div",
        { staticClass: "row-format", staticStyle: { "flex-wrap": "wrap" } },
        _vm._l(_vm.captureOptions, function(option) {
          return _c("v-checkbox", {
            key: option.value,
            staticClass: "mr-12",
            attrs: { dense: "", "hide-details": "", label: option.label },
            on: {
              change: function($event) {
                return _vm.updateOptions($event, option)
              }
            },
            model: {
              value: option.selected,
              callback: function($$v) {
                _vm.$set(option, "selected", $$v)
              },
              expression: "option.selected"
            }
          })
        }),
        1
      ),
      _c(
        "div",
        {
          staticClass: "mt-2",
          staticStyle: { "max-width": "95px", "min-width": "95px" }
        },
        [
          _c("v-select", {
            attrs: {
              items: _vm.requiredOptions,
              "item-text": "label",
              "item-value": "value",
              outlined: "",
              "persistent-placeholder": "",
              dense: "",
              "hide-details": "",
              label: "Required"
            },
            on: {
              change: function($event) {
                return _vm.handleChange()
              }
            },
            model: {
              value: _vm.ourRequired,
              callback: function($$v) {
                _vm.ourRequired = $$v
              },
              expression: "ourRequired"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }