import HttpClient from '@/services/HttpClient';
// import store from "@/store";

export default class ImageAssetService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getImageAssets(tags = []) {
		return this.httpClient
			.get(`/assets/images?tags=${tags.join(',')}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	postImageAsset(file, tags = []) {
		return this.httpClient
			.post(`/assets/images/${tags.join(',')}`, { file: file })
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteImageAsset(id) {
		return this.httpClient
			.delete(`/assets/images/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
