<template>
	<div>
		<input type="hidden" v-model="event_type" ref="event_type" df-event_type />
		<input type="hidden" v-model="enable_max_wait" ref="enable_max_wait" df-enable_max_wait />
		<input type="hidden" v-model="wait_period" ref="wait_period" df-wait_period />
		<input type="hidden" v-model="wait_duration" ref="wait_duration" df-wait_duration />
		<input type="hidden" v-model="variable_date_category" ref="variable_date_category" df-variable_date_category />
		<input type="hidden" v-model="variable_date_field" ref="variable_date_field" df-variable_date_field />
		<input type="hidden" v-model="variable_date_modifier" ref="variable_date_modifier" df-variable_date_modifier />

		<node-label @open="$refs.nodeModal.open()">
			<template v-slot:default>
				<v-icon class="material-symbols-rounded mr-1" color="black" size="20">hourglass_top</v-icon>
				<div>Wait for:  {{label}}</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" title="Wait for">
			<template v-slot:default>
				<div class="column-format gap-2" style="width:500px; min-width: 500px;">
					<v-select
							hide-details
							dense
							outlined
							:items="waitForEvent"
							item-text="label"
							item-value="value"
							v-model="event_type"
					></v-select>
					<div v-if="event_type === 'VariableDate'" class="column-format gap-2">
						<div class="row-format align-center gap-2">
							<v-select
									hide-details
									dense
									outlined
									label="Category"
									v-model="variable_date_category"
									:items="variableDateCategories"
									item-text="label"
									item-value="value"
									style="flex: 1 1 0"
							>
							</v-select>
							<v-select
									hide-details
									dense
									outlined
									label="Field"
									v-model="variable_date_field"
									:items="variableDateFields"
									item-text="label"
									item-value="value"
									style="flex: 1 1 0"
							></v-select>
						</div>
						<div class="row-format gap-2 align-center">
							<v-select dense hide-details outlined :items="['Plus','Minus']" v-model="variable_date_modifier" style="flex: 1 1 0"></v-select>
							<v-text-field dense hide-details outlined type="number" v-model="wait_duration" @blur="sanitizeDuration" style="flex: 1 1 0"></v-text-field>
							<v-select dense hide-details outlined :items="['Minutes','Hours','Days','Weeks','Months']" v-model="wait_period" style="flex: 1 1 0"></v-select>
						</div>
					</div>
					<div v-else>
						<v-checkbox class="mt-n1" hide-details dense v-if="event_type !== 'TimePeriod'" v-model="enable_max_wait" true-value="true" false-value="false" label="Set maximum wait time"></v-checkbox>
						<div v-if="event_type === 'TimePeriod' || enable_max_wait === 'true'" class="row-format gap-2">
							<v-text-field dense hide-details outlined type="number" v-model="wait_duration" @blur="sanitizeDuration"></v-text-field>
							<v-select dense hide-details outlined :items="['Minutes','Hours','Days','Weeks','Months']" v-model="wait_period"></v-select>
						</div>
					</div>
				</div>
			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeModal from "@/modules/workflow/nodes/NodeModal";
	import NodeLabel from "@/modules/workflow/nodes/NodeLabel";
	import NodeMixin from "@/modules/workflow/nodes/NodeMixin";
	export default {
		name: 'WaitFor',
		inputs: 1,
		outputs: 1,

		mixins: [NodeMixin],

		props: [],

		components: {NodeLabel, NodeModal },

		data: function() {
			return {
				event_type: 'TimePeriod',
				wait_period: 'Days',
				wait_duration: 1,
				enable_max_wait: 'false',
				variable_date_category: null,
				variable_date_field: null,
				variable_date_modifier: 'Minus',
				isOpen: false
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.event_type = this.$refs.event_type.value
				this.wait_period = this.$refs.wait_period.value
				this.enable_max_wait = this.$refs.enable_max_wait.value;
				this.wait_duration = parseInt(this.$refs.wait_duration.value,10);
				this.variable_date_category = this.$refs.variable_date_category.value;
				this.variable_date_field = this.$refs.variable_date_field.value;
				this.variable_date_modifier = this.$refs.variable_date_modifier.value;
				setTimeout(() => {
					this.emitInput('event_type');
					this.emitInput('wait_period');
					this.emitInput('wait_duration');
					this.emitInput('enable_max_wait');
					this.emitInput('variable_date_category');
					this.emitInput('variable_date_field');
					this.emitInput('variable_date_modifier');
				},250);
			});
		},

		beforeDestroy() {},

		methods: {
			edit: function(){
				this.$refs.nodeModal.open();
			},

			sanitizeDuration: function(){
				this.wait_duration = parseInt(this.wait_duration,10);
				if(isNaN(this.wait_duration)){
					this.wait_duration = 0;
				}
			},
		},

		watch: {
			event_type: function() {
				this.emitInput('event_type');
			},

			wait_period: function() {
				this.emitInput('wait_period');
			},

			wait_duration: function() {
				this.emitInput('wait_duration');
			},

			enable_max_wait: function() {
				this.emitInput('enable_max_wait');
			},

			variable_date_category: function() {
				this.emitInput('variable_date_category');
			},

			variable_date_field: function() {
				this.emitInput('variable_date_field');
			},

			variable_date_modifier: function() {
				this.emitInput('variable_date_modifier');
			},
		},

		computed: {
			selectedCategory: function(){
				return this.variableDateCategories.find((v) => v.value === this.variable_date_category);
			},


			variableDateFields: function() {
				if(this.selectedCategory){
					return this.selectedCategory.fields.filter(f => f.type === 'Date');
				}else{
					return null;
				}
			},

			variableDateCategories: function(){
				let categories = ['Client','Project','Invoice','Opportunity','Agreement','Meeting'];
				let result = [... this.variables];
				return result.filter(v => categories.includes(v.value));
			},

			label: function(){
				if(this.event_type === 'TimePeriod'){
					return this.wait_duration + ' ' + this.wait_period;
				}else{
					let e = this.waitForEvent.find(e => e.value === this.event_type);
					return e ? e.label : null;
				}
			},

			waitForEvent: function() {
				return [
					{ label: 'Time period', value: 'TimePeriod' },
					{ label: 'Invoice to be paid', value: 'InvoicePaid' },
					{ label: 'Agreement to be signed', value: 'AgreementSigned' },
					{ label: 'Form submission', value: 'FormFilled' },
					{ label: 'Email reply', value: 'EmailReplied' },
					{ label: 'Message reply', value: 'MessageReplied' },
					{ label: 'Meeting scheduled', value: 'MeetingScheduled' },
					{ label: 'Project completion', value: 'ProjectComplete' },
					{ label: 'Variable date', value: 'VariableDate' },
				];
			},
		},
	};
</script>

<style scoped lang="scss"></style>
