var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", { staticClass: "fill-all", attrs: { id: "workflow-editor" } }, [
        _c(
          "div",
          { staticClass: "header-area pl-1 pr-3 row-format align-center" },
          [
            _c(
              "div",
              { staticClass: "row-format gap-2 align-center" },
              [
                _c("v-text-field", {
                  staticStyle: { width: "198px" },
                  attrs: {
                    placeholder: "Workflow name",
                    "hide-details": "",
                    dense: "",
                    outlined: ""
                  },
                  model: {
                    value: _vm.workflow.name,
                    callback: function($$v) {
                      _vm.$set(_vm.workflow, "name", $$v)
                    },
                    expression: "workflow.name"
                  }
                }),
                _c("v-select", {
                  staticStyle: { width: "150px" },
                  attrs: {
                    items: ["Active", "Disabled"],
                    "hide-details": "",
                    dense: "",
                    outlined: ""
                  },
                  model: {
                    value: _vm.workflow.status,
                    callback: function($$v) {
                      _vm.$set(_vm.workflow, "status", $$v)
                    },
                    expression: "workflow.status"
                  }
                }),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.$refs.flow.findHome()
                      }
                    }
                  },
                  [
                    _c("v-icon", { staticClass: "material-symbols-rounded" }, [
                      _vm._v("home")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ml-auto row-format gap-1 align-center" },
              [
                _vm.workflow.publishedTimestamp
                  ? _c("div", { staticClass: "font-12 font-gray_70 mr-3" }, [
                      _vm._v(
                        "Published: " +
                          _vm._s(
                            _vm.$formatters.formatForTimeAgo(
                              _vm.workflow.publishedTimestamp
                            )
                          )
                      )
                    ])
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    attrs: { width: "96" },
                    on: {
                      click: function($event) {
                        return _vm.save(false)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action",
                    attrs: { disabled: !_vm.workflow.draft },
                    on: { click: _vm.confirmPublish }
                  },
                  [_vm._v(" Publish ")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.save(true)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("close")])],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticStyle: { width: "100%", height: "calc(100% - 56px)" } },
          [
            _c("workflow", {
              ref: "flow",
              attrs: { workflow: _vm.workflow.flow }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }