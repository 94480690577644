import HttpClient from '@/services/HttpClient';
import store from "@/store";
import MicrosoftEmailService from "@/modules/integrations/microsoft/MicrosoftEmailService";

export default class MicrosoftCommunicatorService extends MicrosoftEmailService{
    httpClient;

    constructor() {
        super();
        this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
    }

    setupConnectRequest(){
        localStorage.setItem('MICROSOFT_OAUTH_TYPE','Communicator');
        localStorage.setItem('MICROSOFT_OAUTH_SCOPE',this.getScopes());
        return this.getLoginUrl();
    }

    setupReconnectRequest(id){
        localStorage.setItem('MICROSOFT_OAUTH_TYPE',`Communicator:${id}`);
        localStorage.setItem('MICROSOFT_OAUTH_SCOPE',this.getScopes());
        return this.getLoginUrl();
    }

    connect(code,redirectUri,scope) {
        let formData = new FormData();
        formData.append('code',code);
        formData.append('redirectUri',redirectUri);
        formData.append('scope',scope);

        return this.httpClient
            .post(`/emailBox/oAuth/microsoft`,formData,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    reconnect(id,code,redirectUri,scope){
        let formData = new FormData();
        formData.append('code',code);
        formData.append('redirectUri',redirectUri);
        formData.append('scope',scope);

        return this.httpClient
            .post(`/emailBox/${id}/oAuth/microsoft`,formData,{
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}