<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-invoice')" @action="navigateToInvoice()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small :client="client" />
			<div class="ml-2">{{ client.name }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ heading }}</div>
			<div class="font-12">
				<span> Total: {{ total }}<br />
					<span v-if="dateDue">Due: {{ DateTime.fromISO(dateDue).toLocaleString(DateTime.DATE_MED) }}</span>
					<span v-if="contactList"><br>To: {{contactList.join(', ')}}</span>
				</span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";
	import DateTime from "@/modules/utils/HDateTime";
	import InvoiceDetails from "@/modules/invoices/InvoiceDetails";

	export default {
		name: 'InvoiceApprovalNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar,NotificationLayout },

		data: function() {
			return {
				DateTime: DateTime,
				client: this.notificationPayload.client,
				invoice: this.notificationPayload.invoiceMini,
				sentAutomatically: this.notificationPayload.sentAutomatically,
				contactList: this.notificationPayload.contactList
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			navigateToInvoice: function() {
				let binding = {
					clientId: this.client.id,
					id: this.invoice.id,
					showDuplicate: false,
				}
				this.$store.state.globalModalController.openModal(InvoiceDetails,binding,true,true);
			},
		},

		computed: {
			dateDue: function(){
				if(this.invoice.dateDue){
					return this.invoice.dateDue;
				}else if(this.invoice.dateDueCalculated){
					return this.invoice.dateDueCalculated;
				}else{
					return null
				}
			},

			heading: function(){
				if(this.sentAutomatically){
					return this.$t('notifications.invoice-sent');
				}else{
					return this.$t('notifications.invoice-ready');
				}
			},

			total: function(){
				let currency = this.invoice.currency ? this.invoice.currency : this.$store.state.defaultCurrency;
				return this.$formatters.dollars(this.invoice.total,true,true, currency);
			}
		},
	};
</script>

<style scoped lang="scss">

</style>
