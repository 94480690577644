<template>
	<div class="font-14">
		<div class="text-left brand-bold px-2 py-1">{{label}}</div>
		<table style="border-collapse: collapse; width:100%; border: 1px solid #cfd3d7; border-radius: 4px" class="text-left">
			<tr :class="invoice && invoice.id && payment.invoiceId === invoice.id ? 'brand-bold' : 'brand-light'" v-for="(payment,index) in scheduledPayments" :key="payment.id" :style="`border-bottom: 1px solid #cfd3d7; ${payment.mileStoneAchieved ? 'background-color: var(--v-gray_20-base)' : ''}`">
				<td class="text-left pa-2 font-14">
					<v-icon class="mr-n1 ml-n2" v-if="invoice && invoice.id && payment.invoiceId === invoice.id">arrow_right</v-icon>
					{{index+1}}) {{payment.label}}
				</td>
				<td class="text-left pa-2 font-14" v-if="payment.mileStoneAchieved">
					{{payment.invoiceNumberFormatted}} - {{DateTime.fromISO(payment.invoiceDate).toLocaleString(DateTime.DATE_MED)}}
				</td>
				<td class="text-left pa-2 font-14" v-else>{{payment.triggerType === 'Date' && payment.triggerDate ? DateTime.fromISO(payment.triggerDate).toLocaleString(DateTime.DATE_MED) : ''}}</td>
				<td class="text-right pa-2 font-14">{{$formatters.dollars(payment.calculatedAmount,true,true,invoice.currency)}}</td>
			</tr>
		</table>
	</div>
</template>

<script>
	import {DateTime} from 'luxon';

	export default {
		name: 'ScheduledPayments',

		props: ['invoice', 'scheduledPayments','lineItems'],

		components: {},

		data: function() {
			return {
				DateTime: DateTime
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			label: function(){
				return this.lineItems.labels.paymentPlan ? this.lineItems.labels.paymentPlan : 'Payment plan';
			}
		},

		watch: {

		}
	};
</script>

<style scoped lang="scss"></style>
