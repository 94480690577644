<template>
	<div>
		<v-data-table
			:items="pages"
			:headers="headers"
			:items-per-page="-1"
			hide-default-footer
			no-data-text="No custom pages"
			class="pointer"
			group-by="folder"
			@click:row="editCustomPage($event)"
		>
			<template v-slot:group.header="{ group, headers, toggle }">
				<td colspan="6" class="text-left" @click="toggle">
					<div class="row-format align-center gap-2">
						<v-icon class="" size="20" color="secondary">folder</v-icon>{{ group ? group : ' -- ' }}
					</div>
				</td>
			</template>

			<template v-slot:item.title="{ item }">
				<div class="row-format align-center gap-1">
					<v-icon class="material-symbols-rounded" size="20">{{ item.icon }}</v-icon>
					<div>{{ item.title }}</div>
				</div>
			</template>
			<template v-slot:item.enabled="{ item }">
				{{ item.enabled ? 'Yes' : 'No' }}
			</template>
			<template v-slot:item.isDefault="{ item }">
				<span v-if="item.type === 'Internal' || item.type === 'iFrame'">{{ item.isDefault ? 'Yes' : 'No' }}</span>
				<span v-else> -- </span>
			</template>
			<template v-slot:item.sortOrder="{ item }">
				<div v-if="item.isDefault">--</div>
				<div @click.stop style="max-width: 100px" v-else>
					<v-select @change="handleNavPositionChange(item)" :items="navPositions" hide-details dense style="margin-top: -5px" v-model="item.sortOrder" item-value="value" item-text="label"></v-select>
				</div>

			</template>
			<template v-slot:item.type="{ item }">
				{{ pageTypes.find((t) => t.value === item.type).text }}
			</template>
			<template v-slot:item.action="{ item }">
				<div class="row-format">
					<v-icon :size="20" color="gray_70" class="ml-auto mr-1" @click.stop="confirmDeleteCustomPage(item)"
						>$delete</v-icon
					>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import CustomPageService from '@/modules/portal/customization/CustomPageService';
	import CustomPage from '@/modules/portal/customization/CustomPage';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'CustomPages',

		props: ['branding', 'mceConfig'],

		components: {  },

		data: function() {
			return {
				customPageService: new CustomPageService(),
				view: 'List',
				pages: [],
			};
		},

		mounted() {
			this.getCustomPages();
		},

		beforeDestroy() {},

		methods: {
			handleNavPositionChange: function(page){
				this.$store.commit('startLoading');
				let ix = this.pages.findIndex(p => p.id === page.id);
				let p = this.pages[ix];
				p.sortOrder = page.sortOrder;
				this.customPageService.updateCustomPage(p).then((res) => {
					this.pages.splice(ix,1,res.data);
					this.pages.sort(this.sortPages);
				}).finally(() => this.$store.commit('stopLoading'));
			},

			getCustomPages: function() {
				this.customPageService.getCustomPages().then((res) => {
					this.pages.splice(0);
					this.pages.push(...res.data);
					this.pages.sort(this.sortPages);
				});
			},

			sortPages: function(a,b){
				if(a.isDefault){
					return -1;
				}
				let result = a.sortOrder - b.sortOrder;
				if(result == 0){
					return a.title.localeCompare(b.title);
				}else{
					return result;
				}
			},

			addCustomPage: function(type) {
				this.customPageService.createCustomPage(type).then((res) => {
					this.pages.push(res.data);
					this.editCustomPage(res.data);
				});
			},

			editCustomPage: function(page) {
				let binding = {
					id: page.id,
					branding: this.branding,
					mceConfig: this.mceConfig,
					folders: this.folders
				};

				let fullScreen = page.type === 'Internal';

				this.$store.state.globalModalController.openModal(CustomPage, binding, false, fullScreen).then((res) => {
					let ix = this.pages.findIndex((p) => p.id === res.id);
					if (res.deleted) {
						this.pages.splice(ix, 1);
					} else {
						this.pages.splice(ix, 1, res);
					}
					this.pages.sort(this.sortPages);
				});
			},

			deleteCustomPage: function(page) {
				this.$store.commit('startLoading');
				this.customPageService
					.deleteCustomPage(page.id)
					.then(() => {
						let ix = this.pages.findIndex((p) => p.id === page.id);
						this.pages.splice(ix, 1);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			confirmDeleteCustomPage: function(page) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this custom page?  This cannot be undone',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.deleteCustomPage(page);
					}
				});
			},
		},

		computed: {
			folders: function() {
				return this.pages
						.filter((e) => e.folder)
						.map((e) => e.folder)
						.filter((value, index, self) => self.indexOf(value) === index);
			},

			navPositions: function(){
				let result = [];
				result.push({value: 99, label:'Default'});
				for(let i=1; i <= 20; i++){
					result.push({value: i, label:i});
				}
				return result;
			},

			headers: function() {
				let result = [
					{ text: 'Title', value: 'title' },
					{ text: 'Type', value: 'type' },
					{ text: 'Enabled', value: 'enabled' },
					{ text: 'Default page', value: 'isDefault' },
					{ text: 'Nav position', value: 'sortOrder' },
					{ text: '', value: 'action', align: 'right', sortable: false },
				];
				return result;
			},

			pageTypes: function() {
				return [
					{ text: 'Custom page', value: 'Internal' },
					{ text: 'External link', value: 'External' },
					{ text: 'iFrame', value: 'iFrame' },
				];
			},
		},
	};
</script>

<style scoped lang="scss"></style>
