var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "invoice-number-custom-format" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c(
            "div",
            { staticClass: "modal-title align-center d-flex justify-start" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 gray_90--text pointer",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("$arrowLeft")]
              ),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("invoice.invoice-number-custom-format.heading")
                  ) +
                  " "
              )
            ],
            1
          ),
          _c("v-text-field", {
            ref: "invoice-number-custom-format-input",
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              type: "text",
              id: "invoice-number-custom-format-input",
              label: _vm.$t(
                "invoice.invoice-number-custom-format.format-label"
              ),
              placeholder: _vm.$t(
                "invoice.invoice-number-custom-format.format-placeholder"
              )
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleSave.apply(null, arguments)
              }
            },
            model: {
              value: _vm.format,
              callback: function($$v) {
                _vm.format = $$v
              },
              expression: "format"
            }
          }),
          _c(
            "div",
            { staticClass: "brand-semilight font-14 text-left mt-7 mb-5" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("invoice.invoice-number-custom-format.top-text")
                  ) +
                  " "
              )
            ]
          ),
          _vm._l(_vm.tokens, function(token) {
            return _c(
              "div",
              {
                key: token.token,
                staticClass: "pointer legend-grid",
                on: {
                  click: function($event) {
                    return _vm.insertToken(token.token)
                  }
                }
              },
              [
                _c("div", [_vm._v(_vm._s(token.text))]),
                _c("div", [
                  _c("span", [_vm._v(_vm._s(token.token))]),
                  _c("span", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(token.append ? token.append : ""))
                  ])
                ])
              ]
            )
          }),
          _c("div", { staticClass: "brand-semilight font-14 text-left my-5" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("invoice.invoice-number-custom-format.bottom-text")
                ) +
                " "
            )
          ])
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              staticStyle: { width: "160px" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.cancel")))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action ml-1",
              staticStyle: { width: "160px" },
              attrs: { disabled: !_vm.formatOk },
              on: { click: _vm.handleSave }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }