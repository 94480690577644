import HttpClient from '@/services/HttpClient';
import store from '../../../store';

export default class ExpenseAttachmentService {

	constructor(expense) {
		this.expense = expense;
		this.httpClient = new HttpClient();
	}

	setExpense(expense){
		this.expense = expense;
	}

	/**
	 * As per the contract of the generic FileAttachment component, we must implement the following methods:
	 *
	 * fileService.deleteFile(fileName);
	 * fileService.getSignedUrl(fileName);
	 * fileService.getPreviewUrl(fileName);
	 * fileService.getUploadUrl();
	 */

	deleteFile(fileName) {
        return this.httpClient
            .delete(`/expenses/${this.expense.id}/files?fileName=${encodeURIComponent(fileName)}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

	getSignedUrl(fileName) {
		return this.httpClient
			.get(`/expenses/${this.expense.id}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getPreviewUrl(file) {
		if (file.fileType === 'JPG' || file.fileType === 'PNG' || file.fileType === 'GIF') {
			return `${store.getters.getPodUrl}api/insecure/${this.expense.accountId}/expenses/${
				this.expense.id
			}/files/thumbnail?fileName=${encodeURIComponent(file.fileName)}&authToken=${encodeURIComponent(
				store.state.accessToken
			)}`;
		} else {
			return file.fileIconUrl;
		}
	}

	getUploadUrl() {
		return `${store.getters.getBaseUrl}/expenses/${this.expense.id}/files`;
	}
}
