<template>
	<v-form id="planChange" @submit.prevent="planChange()">
		<div class="ma-8">
			<h1 v-html="heading" class="justify-center mb-5"></h1>

			<div class="mb-5" v-html="subheading"></div>

			<v-btn type="submit" color="accent" large block class="my-4" @click="$emit('confirm')">Confirm change</v-btn>
		</div>
	</v-form>
</template>

<script>
	export default {
		name: 'PlanChange',
		props: ['currentSubscription','newPlan'],

		data() {
			return {
				heading: '',
				subheading: '',
				button: '',
				buttonText: '',
			};
		},

		mounted() {
			console.log(this.currentSubscription);
			console.log(this.newPlan);
			this.buildTextAndButtons();
		},

		methods: {
			alert(type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},

			planChange() {
				this.$emit('plan-change', this.newPlan.planCode);
				this.$emit('close');
			},

			buildTextAndButtons() {
				let current;
				if(this.currentSubscription && this.currentSubscription.recurlySubscription){
					current = this.currentSubscription.recurlySubscription;
				}else{
					current = {subtotal: 0, currentPeriodEndsAt: this.$DateTime.now()}
				}

				console.log(current);

				let period;
				if(this.newPlan.interval === 1){
					period = 'monthly'
				}else{
					period = 'annually'
				}

				let newPrice = '<span class="primary--text">' + this.$formatters.dollars(this.newPlan.price, false,true,'USD') + ` ${period}` + '</span>';
				let oldPrice = '<span class="primary--text">' + this.$formatters.dollars(current.subtotal,false,true,'USD') + '</span>';
				let renewalDate = this.$DateTime.longDate(current.currentPeriodEndsAt);

				if(this.newPlan.price > current.subtotal){
					renewalDate = "today";
				}

				this.heading = this.$t('subscription-v2.plan-change.heading');
				this.subheading = this.$t('subscription-v2.plan-change.subheading', {
					'new-price': newPrice,
					'old-price': oldPrice,
					'renewal-date': renewalDate,
				});
				return;
			},
		},
		computed: {},
	};
</script>

<style lang="scss" scoped></style>
