var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format pa-1",
      staticStyle: {
        height: "100%",
        "border-right": "1px solid var(--v-gray_20-base)"
      }
    },
    _vm._l(_vm.tabs, function(tab) {
      return _c(
        "div",
        {
          directives: [
            {
              name: "tippy",
              rawName: "v-tippy",
              value: {
                content: tab.label,
                placement: "right",
                arrow: true,
                arrowType: "round",
                animation: "fade",
                delay: 50,
                boundary: "window"
              },
              expression:
                "{content: tab.label, placement : 'right',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window'}"
            }
          ],
          key: tab.label,
          class:
            "pointer link row-format centered " +
            (_vm.currentTab === tab.label ? "active" : ""),
          on: {
            click: function($event) {
              return _vm.$emit("change", tab.label)
            }
          }
        },
        [
          _c(
            "v-icon",
            {
              staticClass: "material-symbols-rounded",
              attrs: {
                size: "24",
                color: _vm.currentTab === tab.label ? "primary" : "black"
              }
            },
            [_vm._v(_vm._s(tab.icon))]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }