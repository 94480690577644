var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initialized
    ? _c("div", { staticClass: "column-format flex-grow-1" }, [
        _vm.invoices.length
          ? _c("div", { staticClass: "row-format mb-2 gap-3 flex-wrap" }, [
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit text-left" }, [
                  _vm._v("Total invoiced "),
                  _c("span", { staticClass: "font-gray_70" }, [
                    _vm._v(
                      "(" + _vm._s(_vm.filteredInvoices.length) + " invoices)"
                    )
                  ])
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$formatters.dollars(
                        _vm.totalInvoiced,
                        true,
                        true,
                        _vm.currency
                      )
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit text-left" }, [
                  _vm._v("Total due")
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$formatters.dollars(
                        _vm.totalDue,
                        true,
                        true,
                        _vm.currency
                      )
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit text-left" }, [
                  _vm._v("Avg days to pay")
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(_vm._s(_vm.avgDaysToPay))
                ])
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "flex-grow-1 column-format gap-4" }, [
          _c(
            "div",
            {
              staticClass: "row-format align-center",
              staticStyle: { "flex-wrap": "wrap" }
            },
            [
              _c(
                "v-tabs",
                {
                  staticStyle: { "max-width": "fit-content" },
                  attrs: { color: "primary" },
                  model: {
                    value: _vm.invoiceTab,
                    callback: function($$v) {
                      _vm.invoiceTab = $$v
                    },
                    expression: "invoiceTab"
                  }
                },
                [
                  _c("v-tab", { key: "0" }, [_vm._v("Invoice list")]),
                  _c("v-tab", { key: "1" }, [_vm._v("Recurring invoices")]),
                  _c("v-tab", { key: "2" }, [_vm._v("Payment plans")])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "ml-auto mt-2 row-format align-center gap-2 flex-wrap"
                },
                [
                  _vm._t("filter"),
                  _vm.currencies.length > 1
                    ? _c("v-select", {
                        staticStyle: {
                          "max-width": "100px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          items: _vm.currencies,
                          dense: "",
                          "hide-details": "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.currency,
                          callback: function($$v) {
                            _vm.currency = $$v
                          },
                          expression: "currency"
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceTab === 1,
                  expression: "invoiceTab === 1"
                }
              ]
            },
            [
              _c("recurring-invoice-list", {
                ref: "RecurringInvoice",
                attrs: { client: _vm.client }
              })
            ],
            1
          ),
          _vm.invoiceTab === 2
            ? _c(
                "div",
                [
                  _c("payment-plan-list", {
                    ref: "PaymentPlan",
                    attrs: { filter: _vm.filter, client: _vm.client }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.invoiceTab === 0,
                  expression: "invoiceTab === 0"
                }
              ],
              staticClass: "column-format flex-grow-1"
            },
            [
              !_vm.invoices.length
                ? _c(
                    "div",
                    {
                      staticClass: "column-format centered flex-grow-1",
                      staticStyle: { "min-height": "calc(100vh - 300px)" }
                    },
                    [
                      _c("empty-view", {
                        attrs: {
                          header: "No invoices yet",
                          body:
                            "You haven’t sent any invoices to " +
                            _vm.client.name +
                            " yet. Send a manual invoice or set up automatic invoices -- Moxie follows your schedule and adds your work to the invoice, and you just approve it before sending.",
                          cta: "Send an invoice",
                          "video-header": "See how it works",
                          "video-body":
                            "Learn how to add the work you’ve done into an invoice. You can also see how to set up automatic invoices.",
                          "video-cta": "Watch the tutorial",
                          "video-id": "VHlGLkW1suI"
                        },
                        on: {
                          "cta-clicked": function($event) {
                            return _vm.addNew()
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.invoices.length && !_vm.filteredInvoices.length
                ? _c(
                    "div",
                    { staticClass: "mt-6" },
                    [_c("empty-filtered-results")],
                    1
                  )
                : _vm.filteredInvoices.length
                ? _c(
                    "div",
                    {
                      staticStyle: { "background-color": "var(--v-white-base)" }
                    },
                    [
                      _c("v-data-table", {
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.filteredInvoices,
                          "hide-default-footer": true,
                          "sort-by": "dateCreated",
                          "sort-desc": true,
                          "items-per-page": -1
                        },
                        on: {
                          "click:row": function($event) {
                            return _vm.openInvoice($event.id)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.dateCreated",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.dateCreated
                                    ? _c(
                                        "span",
                                        { staticClass: "brand-semilight" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.DateTime.fromISO(
                                                item.dateCreated
                                              ).toFormat("DD")
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item.amountDue",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.total
                                    ? _c(
                                        "div",
                                        { staticClass: "brand-semilight" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$formatters.dollars(
                                                item.amountDue
                                                  ? item.amountDue
                                                  : 0,
                                                true,
                                                true,
                                                item.currency
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item.dateSent",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.dateSent
                                    ? _c(
                                        "span",
                                        { staticClass: "brand-semilight" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.DateTime.fromISO(
                                                item.dateSent
                                              ).toFormat("DD")
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item.dateDue",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.dateDue
                                    ? _c(
                                        "span",
                                        { staticClass: "brand-semilight" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.DateTime.fromISO(
                                                item.dateDue
                                              ).toFormat("DD")
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item.invoiceNumberFormatted",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "brand-semilight" },
                                    [
                                      _vm._v(
                                        _vm._s(item.invoiceNumberFormatted)
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.total",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "brand-semilight" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$formatters.dollars(
                                            item.total ? item.total : 0,
                                            true,
                                            true,
                                            item.currency
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  item.localTotal &&
                                  item.currency !== _vm.currency
                                    ? _c(
                                        "div",
                                        { staticClass: "font-12 font-gray_70" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$formatters.dollars(
                                                  item.localTotal
                                                    ? item.localTotal
                                                    : 0,
                                                  true,
                                                  true,
                                                  _vm.currency
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            },
                            {
                              key: "item.status",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "row-format align-center" },
                                    [
                                      _c("div", {
                                        staticClass: "brand-semilight font-12",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.getStatus(item))
                                        }
                                      }),
                                      item.integrationKeys.quickbooksId
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { size: "20" }
                                            },
                                            [_vm._v("$quickbooks")]
                                          )
                                        : _vm._e(),
                                      item.integrationKeys.xeroId
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { size: "20" }
                                            },
                                            [_vm._v("$xero")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.action",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("action-menu", {
                                    staticClass: "action-menu",
                                    attrs: {
                                      invoice: item,
                                      "send-enable": true,
                                      "show-view": true,
                                      "is-list": true
                                    },
                                    on: {
                                      "delete-invoice": function($event) {
                                        return _vm.removeInvoice($event)
                                      },
                                      "set-invoice": function($event) {
                                        return _vm.addOrUpdateInvoice($event)
                                      },
                                      "duplicate-invoice": function($event) {
                                        return _vm.invoiceCreated($event)
                                      },
                                      "view-detail": function($event) {
                                        return _vm.openInvoice($event.id)
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3952663410
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }