import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class TwilioService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
	}


	getAccount(){
		return this.httpClient
			.get(`/twilio/account`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDeviceToken(){
		return this.httpClient
			.get(`/twilio/device/token`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteAccount(){
		return this.httpClient
			.delete(`/twilio/account`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createAccount(country,endUserType){
		return this.httpClient
			.post(`/twilio/account`,null,{
				params:{
					country: country,
					endUserType:endUserType
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	tnSearch(filter){
		return this.httpClient
			.get(`/twilio/tn/search`,{
				params: {
					filter:filter
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	tnOrder(number){
		return this.httpClient
			.post(`/twilio/tn/order`,null,{
				params:{
					number:number
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	toggleRecording(callSid,recordingEnabled,direction){
		return this.httpClient
			.post(`/twilio/recording`,null,{
				params:{
					callSid:callSid,
					recordingEnabled:recordingEnabled,
					direction: direction
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
