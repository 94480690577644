<template>
	<div style="width: 500px">
		<div class="modal-padding">
			<v-form @submit.prevent lazy-validation ref="productForm">
				<div class="row-format mb-2 align-center">
					<div class="brand-medium font-18">Product detail</div>
					<v-icon size="20" class="ml-auto pointer" @click="$emit('result')">$close</v-icon>
				</div>
				<v-text-field
					v-model="local.name"
					:label="$t('stripe.subscription.product-name')"
					hide-details
					class="h-outline"
					persistent-placeholder :rules="inputRequired"
				></v-text-field>
				<v-text-field
					v-model="local.description"
					:label="$t('stripe.subscription.product-desc')"
					hide-details
					class="h-outline"
					persistent-placeholder :rules="inputRequired"
				></v-text-field>


				<v-switch
						class="ml-1"
						style="margin-top: 0px"
						v-model="local.active"
						inset
						flat
						dense
						hide-details
						:label="$t('stripe.subscription.product-active')"
				></v-switch>

				<div class="my-4">
					<div style="width:100%" class="row-format">
						<div class="text-left font-gray_70">{{ $t('stripe.subscription.product-price') }}</div>
						<div class="text-right ml-auto font-gray_70">{{ $t('global.enabled') }}</div>
					</div>

					<div v-for="price in prices" :key="price.id" class="row-format align-center mb-1">
						<div class="row-format align-center" v-if="price.id" style="width: 100%; border-bottom: 1px solid var(--v-gray_30-base)">
							<div :style="!price.active ? 'text-decoration: line-through' : ''">{{formatPrice(price)}}</div>
							<div class="ml-auto mb-1">
								<v-checkbox v-model="price.active" hide-details dense></v-checkbox>
							</div>
						</div>
						<div class="row-format align-center" v-else>
							<v-text-field
								style="width: 100px"
								v-model="price.amount"
								:prefix="currencySymbol(price.currency)"
								type="number"
								hide-details
								dense
								outlined
								class="mr-2"
							></v-text-field>
							<v-select
								style="width: 100px"
								hide-details
								dense
								outlined
								class="mr-2"
								:items="currencies"
								item-text="value"
								item-value="value"
								v-model="price.currency"
							>
							</v-select>
							<div class="mr-2 font-14 font-gray_70">Every</div>
							<v-text-field
								style="width: 80px"
								v-model="price.intervalCount"
								type="number"
								hide-details
								dense
								outlined

								class="mr-2"
							></v-text-field>
							<v-select style="width: 100px" hide-details dense outlined :items="intervals" v-model="price.interval">
							</v-select>
						</div>
					</div>
					<div class="text-left mt-2">
						<div class="font-primary pointer" @click="newPrice">+ New price</div>
					</div>
				</div>
			</v-form>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action" @click="$emit('result')">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="primary-action ml-1" width="150" @click="saveProduct()">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import StripeSubscriptionService from '@/modules/integrations/stripe/subscriptions/StripeSubscriptionService';
	import currencies from '@/assets/data/currencies.json';

	export default {
		name: 'StripeProduct',

		props: ['product'],

		components: {},

		data: function () {
			return {
				currencies: currencies,
				stripeSubscriptionService: new StripeSubscriptionService(),
				local: JSON.parse(JSON.stringify(this.product)),
				prices: [],
				intervals: ['Day', 'Week', 'Month','Year'],
				inputRequired: [(v) => !!v || 'Required'],
			};
		},

		mounted() {
			this.initPrices();
		},

		beforeDestroy() {},

		methods: {
			saveProduct: function () {
				if(!this.$refs.productForm.validate()){
					this.$store.commit('error','Product name and description are required fields.');
					return;
				}

				this.local.prices.splice(0,this.local.prices.length);

				for(let i=0; i < this.prices.length; i++){
					let price = this.prices[i];

					if(!price.id){
						price.unitAmount = Math.floor(price.amount * 100);
						price.active = true;
					}

					if(price.unitAmount > 0){
						this.local.prices.push(price);
					}
				}

				this.$store.commit('startLoading')

				if(this.local.id){
					this.stripeSubscriptionService.updateProduct(this.local).then((res) => {
						this.$emit('result',res.data);
					}).catch((err) => {
						this.$store.commit('error',err.response.data.message)
					}).finally(() => this.$store.commit('stopLoading'))
				}else{
					this.stripeSubscriptionService.createProduct(this.local).then((res) => {
						this.$emit('result',res.data);
					}).catch((err) => {
						this.$store.commit('error',err.response.data.message)
					}).finally(() => this.$store.commit('stopLoading'))
				}
			},

			initPrices: function () {
				this.prices.splice(0, this.prices.length);

				for (let i = 0; i < this.local.prices.length; i++) {
					let price = this.local.prices[i];
					price.amount = price.unitAmount / 100;
					price.currency = price.currency.toUpperCase();
					this.prices.push(price);
				}
			},

			formatPrice: function(price){
				let amount = this.$formatters.dollars(price.unitAmount/100,true,true,price.currency);
				if(price.intervalCount == 1){
					return amount + ' (' + price.currency + ') / ' + price.interval.toLowerCase();
				}else{
					return amount + ' (' + price.currency + ') every ' + price.intervalCount + ' ' + price.interval.toLowerCase() + 's';
				}
			},

			newPrice: function(){
				let price = {
					id: null,
					currency: this.$store.state.defaultCurrency,
					active: true,
					interval: 'Month',
					intervalCount: 1,
					amount: 0,
				}

				this.prices.push(price);
			},

			currencySymbol: function (currency) {
				return this.$formatters.currencySymbol(currency);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
