var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-6 px-3 pt-3" },
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pa-0", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format align-center",
                    staticStyle: { gap: "8px", "flex-wrap": "wrap" }
                  },
                  [
                    _c("client-filter", { attrs: { filter: _vm.filter } }),
                    _c("lead-source-filter", {
                      attrs: {
                        filter: _vm.filter,
                        "lead-sources": _vm.leadSources
                      }
                    }),
                    _c("scope-button", {
                      attrs: {
                        scope: "today",
                        "active-scope": _vm.filter.dateSpecifier,
                        label: "Today"
                      },
                      on: { "scope-update": _vm.updateScope }
                    }),
                    _c("scope-button", {
                      attrs: {
                        scope: "this-week",
                        "active-scope": _vm.filter.dateSpecifier,
                        label: "This week"
                      },
                      on: { "scope-update": _vm.updateScope }
                    }),
                    _c("scope-button", {
                      attrs: {
                        scope: "last-week",
                        "active-scope": _vm.filter.dateSpecifier,
                        label: "Last week"
                      },
                      on: { "scope-update": _vm.updateScope }
                    }),
                    _c("scope-button", {
                      attrs: {
                        scope: "month-to-date",
                        "active-scope": _vm.filter.dateSpecifier,
                        label: "This month"
                      },
                      on: { "scope-update": _vm.updateScope }
                    }),
                    _c("scope-button", {
                      attrs: {
                        scope: "last-month",
                        "active-scope": _vm.filter.dateSpecifier,
                        label: "Last month"
                      },
                      on: { "scope-update": _vm.updateScope }
                    }),
                    _c("scope-button", {
                      attrs: {
                        scope: "year-to-date",
                        "active-scope": _vm.filter.dateSpecifier,
                        label: "Year to date"
                      },
                      on: { "scope-update": _vm.updateScope }
                    }),
                    _c("scope-button", {
                      attrs: {
                        scope: "last-year",
                        "active-scope": _vm.filter.dateSpecifier,
                        label: "Last year"
                      },
                      on: { "scope-update": _vm.updateScope }
                    }),
                    _c("scope-button", {
                      attrs: {
                        scope: "between",
                        "active-scope": _vm.filter.dateSpecifier,
                        label: "Custom dates"
                      },
                      on: { "scope-update": _vm.updateScope }
                    }),
                    _vm.filter.dateSpecifier === "between"
                      ? _c(
                          "div",
                          { staticClass: "row-format" },
                          [
                            _c("scope-date-selector", {
                              attrs: { date: _vm.filter.earliest },
                              on: {
                                change: function($event) {
                                  return _vm.setEarliest($event)
                                }
                              }
                            }),
                            _c("div", { staticClass: "mx-1" }, [_vm._v("-")]),
                            _c("scope-date-selector", {
                              attrs: { date: _vm.filter.latest },
                              on: {
                                change: function($event) {
                                  return _vm.setLatest($event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm.$store.getters.hasInvoiceAccess
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "px-0", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "div",
                        { staticClass: "text-left brand-medium mb-1" },
                        [_vm._v("Money")]
                      ),
                      _c("money-metrics", {
                        attrs: {
                          clients: _vm.clientList,
                          invoices: _vm.filteredInvoices,
                          payments: _vm.filteredPayments,
                          "other-income": _vm.filteredIncome,
                          expenses: _vm.filteredExpenses
                        },
                        on: {
                          clicked: function($event) {
                            return _vm.handleClicked($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("money-chart", {
                        attrs: {
                          scope: _vm.scope,
                          earliest: _vm.earliest,
                          latest: _vm.latest,
                          clients: _vm.clientList,
                          invoices: _vm.filteredInvoices,
                          "other-income": _vm.filteredIncome
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$store.getters.hasFeatureAccess("timesheets")
            ? _c(
                "v-row",
                {
                  staticClass: "mt-10 pt-4",
                  staticStyle: {
                    "border-top": "1px solid var(--v-gray_50-base)"
                  },
                  attrs: { align: "center" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "px-0", attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "div",
                        { staticClass: "text-left brand-medium mb-1" },
                        [_vm._v("Time")]
                      ),
                      _c("time-metrics", {
                        attrs: {
                          scope: _vm.scope,
                          earliest: _vm.earliest,
                          latest: _vm.latest,
                          clients: _vm.clientList,
                          invoices: _vm.filteredInvoices,
                          payments: _vm.filteredPayments,
                          "other-income": _vm.filteredIncome,
                          expenses: _vm.filteredExpenses,
                          "time-worked": _vm.filteredTimeWorked
                        },
                        on: {
                          clicked: function($event) {
                            return _vm.handleClicked($event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("time-chart", {
                        attrs: {
                          scope: _vm.scope,
                          earliest: _vm.earliest,
                          latest: _vm.latest,
                          clients: _vm.clientList,
                          "time-worked": _vm.filteredTimeWorked
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }