<template>
	<div style="width: 100%; height: 100%; position: relative" class="row-format">
		<div style="width:300px; height: 100%; background-color: var(--v-white-base)" class="pa-2">
			<div
				v-for="node in nodeType"
				:key="node.value"
				draggable="true"
				:id="node.value"
				class="row-format node-type pa-1 gap-2"
				@drag="drag"
			>
				<v-icon class="material-symbols-rounded" color="black" size="24">{{
						node.icon
					}}</v-icon>
				<div class="column-format text-left">
					<div class="font-14">{{ node.label }}</div>
					<div class="font-12 mt-n1 font-gray_70">{{ node.description }}</div>
				</div>
			</div>
		</div>
		<div
			id="drawflow"
			@dragover="allowDrop"
			@drop="drop"
			style="display: block; position: relative; width:calc(100% - 200px); height: 100%"
		></div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import DrawFlowExtended from '@/modules/workflow/DrawFlowExtended';
	import WaitFor from "@/modules/workflow/nodes/WaitFor";
	import Decision from "@/modules/workflow/nodes/Decision";
	import Test from "@/modules/workflow/nodes/Test";
	import Start from "@/modules/workflow/nodes/Start";
	import Email from "@/modules/workflow/nodes/Email";
	import SendText from "@/modules/workflow/nodes/SendText";
	import Invoice from "@/modules/workflow/nodes/Invoice";
	import Agreement from "@/modules/workflow/nodes/Agreement";
	import Project from "@/modules/workflow/nodes/Project";
	import OpportunityStage from "@/modules/workflow/nodes/OpportunityStage";
	import AgreementVoid from "@/modules/workflow/nodes/AgreementVoid";
	import CreateClient from "@/modules/workflow/nodes/CreateClient";
	import 'drawflow/dist/drawflow.min.css';
	import CreateProspect from "@/modules/workflow/nodes/CreateProspect";
	import FormToPortal from "@/modules/workflow/nodes/FormToPortal";

	export default {
		name: 'Workflow',

		props: ['workflow'],

		components: {},

		data: function() {
			return {
				editor: null,
				nodeMap: {},
				currentDragType: null,
			};
		},

		mounted() {
			let id = document.getElementById('drawflow');
			this.editor = new DrawFlowExtended(id, Vue, this);
			this.editor.reroute = true;
			this.editor.reroute_fix_curvature = true;
			this.editor.useuuid = true;
			this.editor.start();

			this.editor.on('connectionCreated', (info) => {
				const nodeInfo = this.editor.getNodeFromId(info.output_id);
				if (nodeInfo.outputs[info.output_class].connections.length > 1) {
					const removeConnectionInfo = nodeInfo.outputs[info.output_class].connections[0];
					this.editor.removeSingleConnection(
						info.output_id,
						removeConnectionInfo.node,
						info.output_class,
						removeConnectionInfo.output
					);
				}
			});

			this.editor.registerNode('Start', Start, {}, {});
			this.editor.registerNode('Test', Test, {}, {});
			this.editor.registerNode('WaitFor', WaitFor,{},{});
			this.editor.registerNode('Decision', Decision,{},{});
			this.editor.registerNode('Email', Email, {},{})
			this.editor.registerNode('SendText', SendText, {},{});
			this.editor.registerNode('Invoice', Invoice, {},{});
			this.editor.registerNode('Agreement', Agreement, {},{});
			this.editor.registerNode('AgreementVoid', AgreementVoid, {},{});
			this.editor.registerNode('CreateClient', CreateClient, {},{});
			this.editor.registerNode('CreateProspect', CreateProspect, {},{});
			this.editor.registerNode('Project', Project, {},{});
			this.editor.registerNode('OpportunityStage', OpportunityStage, {},{});
			this.editor.registerNode('FormToPortal', FormToPortal, {},{});

			this.$store.commit('startLoading');
			setTimeout(() => {
				this.initialize();
				this.$store.commit('stopLoading');
			}, 1000);


			window.addEventListener('resize', this.reDrawConnections)
			this.$store.state.eventBus.$on('workflow-resize',this.reDrawConnections);
		},

		beforeDestroy() {
			window.removeEventListener('resize', this.reDrawConnections);
			this.$store.state.eventBus.$off('workflow-resize',this.reDrawConnections);
		},

		methods: {
			initialize: function() {
				this.importWorkflow();
			},

			importWorkflow: function() {
				if(this.workflow) {
					this.editor.import(this.workflow);
					setTimeout(() => this.reDrawConnections(), 50);
				}else {
					this.currentDragType = 'Start';
					this.addNode({clientX: 300, clientY: 200});
				}
			},

			allowDrop: function(ev) {
				ev.preventDefault();
			},

			drag: function(ev) {
				this.currentDragType = ev.currentTarget.id;
			},

			drop: function(ev) {
				ev.preventDefault();
				this.addNode(ev);
			},

			reDrawConnections: function(){
				for (const [key] of Object.entries(this.editor.drawflow.drawflow['Home'].data)) {
					this.editor.updateConnectionNodes('node-' + key);
				}
			},

			export: function() {
				return this.editor.export();
			},

			findHome: function(){
				const df = document.getElementById('drawflow').firstChild;
				let startNode = df.querySelector('.Start');
				startNode.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
			},

			addNode: function(event) {
				const rect = document.getElementById('drawflow').getBoundingClientRect();
				const df = document.getElementById('drawflow').firstChild.getBoundingClientRect();

				let left = df.left - rect.left
				let top = df.top - rect.top;

				const x = (event.clientX - rect.left) - left;
				const y = (event.clientY - rect.top) - top;

				let component;

				if(this.currentDragType === 'WaitFor') {
					component = WaitFor;
				}else if(this.currentDragType === 'Decision') {
					component = Decision;
				}else if(this.currentDragType === 'Start') {
					component = Start;
				}else if(this.currentDragType === 'Email') {
					component = Email;
				}else if(this.currentDragType === 'SendText'){
					component = SendText;
				}else if(this.currentDragType === 'Invoice'){
					component = Invoice;
				}else if(this.currentDragType === 'Agreement') {
					component = Agreement;
				}else if(this.currentDragType === 'AgreementVoid'){
					component = AgreementVoid;
				}else if(this.currentDragType === 'Project'){
					component = Project;
				}else if(this.currentDragType === 'OpportunityStage') {
					component = OpportunityStage;
				}else if(this.currentDragType === 'CreateClient'){
					component = CreateClient;
				}else if(this.currentDragType === 'CreateProspect'){
					component = CreateProspect;
				}else if(this.currentDragType === 'FormToPortal'){
					component = FormToPortal;
				}else{
					component = Test;
				}

				this.editor.addNode(this.currentDragType, component.inputs, component.outputs, x, y, this.currentDragType, {}, this.currentDragType, 'vue');
			},

			save: function() {
				let data = this.editor.export();
				console.log(JSON.stringify(data, null, 2));
			},
		},

		computed: {
			nodeType: function() {
				let result = [
					{ icon: 'hourglass_top', label: 'Wait for', description: 'Wait for an event or time period', value: 'WaitFor'},
					{ icon: 'alt_route', label: 'Decision', description: 'Choose path based on conditions', value: 'Decision'},
					{ icon: 'email', label: 'Send email', description: 'Send an email template', value: 'Email'},
					{ icon: 'chat', label: 'Send text message', description: 'Send a text message w/ Communicator', value: 'SendText'},
					{ icon: 'group', label: 'Create client', description: 'Creates a client if one doesn\'t exist', value: 'CreateClient'},
					{ icon: 'person', label: 'Create prospect', description: 'Creates a prospect if one doesn\'t exist', value: 'CreateProspect'},
					{ icon: 'request_quote', label: 'Send invoice', description: 'Send an invoice w/ line items', value: 'Invoice'},
					{ icon: 'handshake', label: 'Send agreement', description: 'Send an agreement from template', value: 'Agreement'},
					{ icon: 'block', label: 'Void agreement', description: 'Void an existing agreement', value: 'AgreementVoid'},
					{ icon: 'folder_special', label: 'Create project', description: 'Create a project from a template', value: 'Project'},
					{ icon: 'bolt', label: 'Change stage', description: 'Change the state of the opportunity', value: 'OpportunityStage'},
					{ icon: 'checklist', label: 'Form to portal', description: 'Add a form request to the client portal', value: 'FormToPortal'},
				];

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.node-type {
		cursor: grab;
		border-radius: 4px;
		&:active {
			cursor: grabbing;
		}
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>

<style lang="scss">
	.connection {
		left: 0;
		top: 0;
	}

	.drawflow-node.Start .drawflow-delete {
		display: none;
	}

	.drawflow-node.Decision .outputs .output:nth-child(1):before {
		display: block;
		content: 'Yes';
		position: absolute;
		right: 10px;
		top: -10px;
		font-size: 12px;
		font-weight: 600;
		color: var(--v-white-base);
		background-color: var(--v-primary-base);
		border-radius: 4px;
		padding: 0px 4px;
	}
	.drawflow-node.Decision .outputs .output:nth-child(2):before {
		display: block;
		content: 'No';
		position: absolute;
		right: 10px;
		top: -2px;
		font-size: 12px;
		font-weight: 600;
		color: var(--v-white-base);
		background-color: var(--v-primary-base);
		border-radius: 4px;
		padding: 0px 4px;
	}

	:root {
		--dfBackgroundColor: var(--v-white-base);
		--dfBackgroundSize: 20px;
		--dfBackgroundImage: linear-gradient(to right, var(--v-gray_10-base) 1px, transparent 1px),
			linear-gradient(to bottom, var(--v-gray_10-base) 1px, transparent 1px);

		--dfNodeType: flex;
		--dfNodeTypeFloat: none;
		--dfNodeBackgroundColor: var(--v-white-base);
		--dfNodeTextColor: var(--v-black-base);
		--dfNodeBorderSize: 1px;
		--dfNodeBorderColor: var(--v-gray_50-base);
		--dfNodeBorderRadius: 8px;
		--dfNodeMinHeight: 40px;
		--dfNodeMinWidth: 50px;
		--dfNodePaddingTop: 12px;
		--dfNodePaddingBottom: 12px;
		--dfNodeBoxShadowHL: 0px;
		--dfNodeBoxShadowVL: 0px;
		--dfNodeBoxShadowBR: 0px;
		--dfNodeBoxShadowS: 0px;
		--dfNodeBoxShadowColor: var(--v-gray_30-base);

		--dfNodeHoverBackgroundColor: var(--v-white-base);
		--dfNodeHoverTextColor: var(--v-black-base);
		--dfNodeHoverBorderSize: 1px;
		--dfNodeHoverBorderColor: var(--v-primary-base);
		--dfNodeHoverBorderRadius: 8px;

		--dfNodeHoverBoxShadowHL: 0px;
		--dfNodeHoverBoxShadowVL: 0px;
		--dfNodeHoverBoxShadowBR: 4px;
		--dfNodeHoverBoxShadowS: 2px;
		--dfNodeHoverBoxShadowColor: var(--v-gray_30-base);

		--dfNodeSelectedBackgroundColor: var(--v-white-base);
		--dfNodeSelectedTextColor: var(--v-black-base);
		--dfNodeSelectedBorderSize: 1px;
		--dfNodeSelectedBorderColor: var(--v-primary-base);
		--dfNodeSelectedBorderRadius: 8px;

		--dfNodeSelectedBoxShadowHL: 0px;
		--dfNodeSelectedBoxShadowVL: 0px;
		--dfNodeSelectedBoxShadowBR: 4px;
		--dfNodeSelectedBoxShadowS: 2px;
		--dfNodeSelectedBoxShadowColor: var(--v-gray_30-base);

		--dfInputBackgroundColor: var(--v-white-base);
		--dfInputBorderSize: 2px;
		--dfInputBorderColor: var(--v-black-base);
		--dfInputBorderRadius: 50px;
		--dfInputLeft: -27px;
		--dfInputHeight: 10px;
		--dfInputWidth: 10px;

		--dfInputHoverBackgroundColor: var(--v-white-base);
		--dfInputHoverBorderSize: 2px;
		--dfInputHoverBorderColor: var(--v-black-base);
		--dfInputHoverBorderRadius: 50px;

		--dfOutputBackgroundColor: var(--v-white-base);
		--dfOutputBorderSize: 2px;
		--dfOutputBorderColor: var(--v-black-base);
		--dfOutputBorderRadius: 50px;
		--dfOutputRight: -16px;
		--dfOutputHeight: 10px;
		--dfOutputWidth: 10px;

		--dfOutputHoverBackgroundColor: var(--v-white-base);
		--dfOutputHoverBorderSize: 2px;
		--dfOutputHoverBorderColor: var(--v-primary-base);
		--dfOutputHoverBorderRadius: 50px;

		--dfLineWidth: 2px;
		--dfLineColor: var(--v-black-base);
		--dfLineHoverColor: var(--v-primary-base);
		--dfLineSelectedColor: var(--v-secondary-base);

		--dfRerouteBorderWidth: 2px;
		--dfRerouteBorderColor: var(--v-black-base);
		--dfRerouteBackgroundColor: var(--v-white-base);

		--dfRerouteHoverBorderWidth: 2px;
		--dfRerouteHoverBorderColor: var(--v-black-base);
		--dfRerouteHoverBackgroundColor: var(--v-white-base);

		--dfDeleteDisplay: block;
		--dfDeleteColor: var(--v-white-base);
		--dfDeleteBackgroundColor: var(--v-error-base);
		--dfDeleteBorderSize: 2px;
		--dfDeleteBorderColor: var(--v-white-base);
		--dfDeleteBorderRadius: 50px;
		--dfDeleteTop: -15px;

		--dfDeleteHoverColor: var(--v-black-base);
		--dfDeleteHoverBackgroundColor: var(--v-error-base);
		--dfDeleteHoverBorderSize: 2px;
		--dfDeleteHoverBorderColor: var(--v-black-base);
		--dfDeleteHoverBorderRadius: 50px;
	}

	#drawflow {
		background: var(--dfBackgroundColor);
		background-size: var(--dfBackgroundSize) var(--dfBackgroundSize);
		background-image: var(--dfBackgroundImage);
	}

	.drawflow .drawflow-node {
		display: var(--dfNodeType);
		background: var(--dfNodeBackgroundColor);
		color: var(--dfNodeTextColor);
		border: var(--dfNodeBorderSize) solid var(--dfNodeBorderColor);
		border-radius: var(--dfNodeBorderRadius);
		min-height: var(--dfNodeMinHeight);
		width: auto;
		min-width: var(--dfNodeMinWidth);
		padding-top: var(--dfNodePaddingTop);
		padding-bottom: var(--dfNodePaddingBottom);
		-webkit-box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS)
			var(--dfNodeBoxShadowColor);
		box-shadow: var(--dfNodeBoxShadowHL) var(--dfNodeBoxShadowVL) var(--dfNodeBoxShadowBR) var(--dfNodeBoxShadowS)
			var(--dfNodeBoxShadowColor);
	}

	.drawflow .drawflow-node:hover {
		background: var(--dfNodeHoverBackgroundColor);
		color: var(--dfNodeHoverTextColor);
		border: var(--dfNodeHoverBorderSize) solid var(--dfNodeHoverBorderColor);
		border-radius: var(--dfNodeHoverBorderRadius);
		-webkit-box-shadow: var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL) var(--dfNodeHoverBoxShadowBR)
			var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
		box-shadow: var(--dfNodeHoverBoxShadowHL) var(--dfNodeHoverBoxShadowVL) var(--dfNodeHoverBoxShadowBR)
			var(--dfNodeHoverBoxShadowS) var(--dfNodeHoverBoxShadowColor);
	}

	.drawflow .drawflow-node.selected {
		background: var(--dfNodeSelectedBackgroundColor);
		color: var(--dfNodeSelectedTextColor);
		border: var(--dfNodeSelectedBorderSize) solid var(--dfNodeSelectedBorderColor);
		border-radius: var(--dfNodeSelectedBorderRadius);
		-webkit-box-shadow: var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL) var(--dfNodeSelectedBoxShadowBR)
			var(--dfNodeSelectedBoxShadowS) var(--dfNodeSelectedBoxShadowColor);
		box-shadow: var(--dfNodeSelectedBoxShadowHL) var(--dfNodeSelectedBoxShadowVL) var(--dfNodeSelectedBoxShadowBR)
			var(--dfNodeSelectedBoxShadowS) var(--dfNodeSelectedBoxShadowColor);
	}

	.drawflow .drawflow-node .input {
		left: var(--dfInputLeft);
		background: var(--dfInputBackgroundColor);
		border: var(--dfInputBorderSize) solid var(--dfInputBorderColor);
		border-radius: var(--dfInputBorderRadius);
		height: var(--dfInputHeight);
		width: var(--dfInputWidth);
	}

	.drawflow .drawflow-node .input:hover {
		background: var(--dfInputHoverBackgroundColor);
		border: var(--dfInputHoverBorderSize) solid var(--dfInputHoverBorderColor);
		border-radius: var(--dfInputHoverBorderRadius);
	}

	.drawflow .drawflow-node .outputs {
		float: var(--dfNodeTypeFloat);
	}

	.drawflow .drawflow-node .output {
		right: var(--dfOutputRight);
		background: var(--dfOutputBackgroundColor);
		border: var(--dfOutputBorderSize) solid var(--dfOutputBorderColor);
		border-radius: var(--dfOutputBorderRadius);
		height: var(--dfOutputHeight);
		width: var(--dfOutputWidth);
	}

	.drawflow .drawflow-node .output:hover {
		background: var(--dfOutputHoverBackgroundColor);
		border: var(--dfOutputHoverBorderSize) solid var(--dfOutputHoverBorderColor);
		border-radius: var(--dfOutputHoverBorderRadius);
	}

	.drawflow .connection .main-path {
		stroke-width: var(--dfLineWidth);
		stroke: var(--dfLineColor);
	}

	.drawflow .connection .main-path:hover {
		stroke: var(--dfLineHoverColor);
	}

	.drawflow .connection .main-path.selected {
		stroke: var(--dfLineSelectedColor);
	}

	.drawflow .connection .point {
		stroke: var(--dfRerouteBorderColor);
		stroke-width: var(--dfRerouteBorderWidth);
		fill: var(--dfRerouteBackgroundColor);
	}

	.drawflow .connection .point:hover {
		stroke: var(--dfRerouteHoverBorderColor);
		stroke-width: var(--dfRerouteHoverBorderWidth);
		fill: var(--dfRerouteHoverBackgroundColor);
	}

	.drawflow-delete {
		display: var(--dfDeleteDisplay);
		color: var(--dfDeleteColor);
		background: var(--dfDeleteBackgroundColor);
		border: var(--dfDeleteBorderSize) solid var(--dfDeleteBorderColor);
		border-radius: var(--dfDeleteBorderRadius);
	}

	.parent-node .drawflow-delete {
		top: var(--dfDeleteTop);
	}

	.drawflow-delete:hover {
		color: var(--dfDeleteHoverColor);
		background: var(--dfDeleteHoverBackgroundColor);
		border: var(--dfDeleteHoverBorderSize) solid var(--dfDeleteHoverBorderColor);
		border-radius: var(--dfDeleteHoverBorderRadius);
	}
</style>
