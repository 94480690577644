<template>
	<div class="pa-6">
		<div class="row-format align-center mb-3">
			<div class="brand-medium font-16">{{ $t('accounting.payments.apply-to') }}</div>
			<v-icon size="20" class="ml-auto" @click="$emit('result')">$close</v-icon>
		</div>
		<div id="select-type">
			<div class="pointer row-format button-box mb-2 pa-4" @click="$emit('result','apply-to-invoice')">
				<v-icon color="gray_80" style="align-self: flex-start">$invoices</v-icon>
				<div class="text-left pl-4">
					<div class="brand-medium font-16">{{ $t('accounting.payments.invoice') }}</div>
					<div class="font-14 font-gray_80 mt-2">{{ $t('accounting.payments.invoice-desc') }}</div>
				</div>
			</div>
			<div class="pointer row-format button-box pa-4" @click="$emit('result','other-income')">
				<v-icon color="gray_80" style="align-self: flex-start">$money</v-icon>
				<div class="text-left pl-4">
					<div class="brand-medium font-16">{{ $t('accounting.payments.other') }}</div>
					<div class="font-14 font-gray_80 mt-2">{{ $t('accounting.payments.other-desc') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "NewPayment",

	props: [],

	components: {},

	data: function () {
		return {
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>