<template>
	<v-dialog
		:value="dialog"
		transition="fade"
		:persistent="persistent"
		scrollable
		@click:outside="!persistent ? handleClose() : ''"
		@keydown.esc="handleClose()"
		max-width="460"
	>
		<div class="confirm-dialog-div">
			<v-btn class="close-dialog" icon rounded @click.prevent="handleClose()">
				<v-icon size="20">$close</v-icon>
			</v-btn>
			<div class="modal-padding">
				<div class="row-format text-left align-center gap-3">
					<v-icon :color="severity" size="50">{{ icon }}</v-icon>
					<div>
						<div v-if="headingText" class="font-18 brand-medium">
							{{ headingText }}
						</div>
						<div v-if="bodyText" class="font-14" v-html="bodyText"></div>
					</div>
				</div>
			</div>
			<v-form @submit.prevent="handleSubmit()" ref="confirmDialog" id="confirmDialog" lazy-validation>
				<div class="modal-footer row-format gap-1 px-3 py-3" style="justify-content: end">
					<v-btn v-if="!hideNo" class="primary-action" @click.prevent="handleClose()">{{ noText }}</v-btn>
					<v-btn
							type="submit"
							min-width="100"
							elevation="0"
							class="super-action"
							@click.prevent="handleSubmit()"
					>
						{{ yesText }}
					</v-btn>
				</div>
			</v-form>
		</div>
	</v-dialog>
</template>

<script>
	export default {
		name: 'ConfirmDialog',

		props: {
			severity: { type: String, required: false, default: 'warning' }, // info, warning, error, success
			icon: { type: String, required: false, default: '$alert' }, // $alert, $info, etc.
			headingText: String,
			bodyText: String,
			hideNo: { type: Boolean, required: false, default: false },
			yesText: { type: String, required: false, default: 'Yes' },
			noText: { type: String, required: false, default: 'No' },
			dialog: Boolean,
			persistent: { type: Boolean, default: false },
		},

		data() {
			return {};
		},

		mounted() {},

		methods: {
			handleSubmit() {
				this.$emit('confirm');
			},
			handleClose() {
				this.$emit('close');
			},
		},
	};
</script>

<style lang="scss">
	.confirm-dialog-div {
		background-color: var(--v-white-base);
		position: relative;
		border-radius: 4px !important;
		max-width: 460px;
		min-width: 360px;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
