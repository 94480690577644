<template>
	<div>
		<v-container class="pa-0 ma-0" style="max-width: 225px">
			<v-row dense>
				<v-col cols="12" class="text-left pa-4">
					<settings-link label="My profile" route="/me/profile" icon="person"></settings-link>
					<settings-link label="Feedback" select-value="feedback" @selected="handleFeedback()" icon="rate_review"></settings-link>
					<settings-link label="Log out" select-value="logout" @selected="confirmLogout()" icon="logout"></settings-link>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';
	import PodService from '@/services/PodService';
	import SettingsLink from "@/views/nav/settings/SettingsLink";
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'UserMenu',

		components: { SettingsLink },

		data() {
			return {
			};
		},
		mounted() {},
		methods: {
			handleFeedback: function(){
				window.open('https://feedback.withmoxie.com','_blank');
			},

			confirmLogout() {
				let binding = {
					icon: 'logout',
					severity: 'info',
					headingText: 'Do you want to log out?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.doLogout();
					}
				})
			},

			doLogout() {
				this.dialog = false;
				try {
					window.google.accounts.id.disableAutoSelect();
				}catch(err){
					console.log(err);
				}

				AuthService.logout()
					.then(() => {
						console.log('Logout Success');
						this.podLogout();
					})
					.catch((err) => {
						console.log(err);
						this.podLogout();
					});
			},

			podLogout() {
				let podService = new PodService();
				podService
					.clearCookies()
					.then(() => {
						this.finalizeLogout();
					})
					.catch(() => {
						this.finalizeLogout();
					});
			},

			finalizeLogout() {
				this.$store.commit('logOut');
				location.reload();
			},
		},

		watch: {

		},

		computed: {


		},
	};
</script>

<style lang="scss">

</style>
