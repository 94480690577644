var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Custom" + _vm.field.type, {
    tag: "component",
    attrs: { value: _vm.value, field: _vm.field, mode: _vm.mode },
    on: {
      change: function($event) {
        return _vm.handleChange($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }