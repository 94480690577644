var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "columnDragHandle row-format align-center mb-2 mx-2 py-1",
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c("div", {
        style:
          "width: 12px; min-width:12px; height: 12px; border-radius: 2px; background-color: " +
          _vm.column.hexColor
      }),
      _c("div", { staticClass: "font-14 brand-medium ml-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.column.label,
              expression: "column.label"
            }
          ],
          staticClass: "truncate",
          staticStyle: {
            "max-width": "180px",
            outline: "none",
            color: "var(--v-black-base)"
          },
          attrs: { disabled: _vm.isCollaborator, type: "text" },
          domProps: { value: _vm.column.label },
          on: {
            click: function($event) {
              $event.stopPropagation()
            },
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return $event.target.blur()
            },
            blur: function($event) {
              return _vm.updateDeliverableStatusList()
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.column, "label", $event.target.value)
            }
          }
        })
      ]),
      _c(
        "div",
        {
          staticClass: "row-format align-center",
          staticStyle: { "margin-left": "auto" }
        },
        [
          _c(
            "v-icon",
            {
              attrs: { small: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("add-deliverable", _vm.column)
                }
              }
            },
            [_vm._v("add")]
          ),
          _c("kanban-column-edit", {
            attrs: {
              column: _vm.column,
              "all-columns": _vm.allColumns,
              "is-collaborator": _vm.isCollaborator
            },
            on: {
              "update-deliverable-status-list": function($event) {
                return _vm.updateDeliverableStatusList()
              },
              "confirm-delete-column": function($event) {
                return _vm.confirmDeleteColumn($event)
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }