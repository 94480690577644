var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("welcome-container", {
    scopedSlots: _vm._u([
      {
        key: "left",
        fn: function() {
          return [
            _c("div", { staticClass: "column-format fill-all" }, [
              _c("div", { staticClass: "column-format flex-grow-1 centered" }, [
                _c(
                  "div",
                  { staticClass: "text-left pl-4 pr-8 column-format gap-5" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "stratos-medium",
                        staticStyle: {
                          "font-size": "56px",
                          "line-height": "64px"
                        }
                      },
                      [_vm._v(_vm._s(_vm.heading))]
                    ),
                    _c("div", { staticClass: "font-20 stratos-semilight" }, [
                      _vm._v(_vm._s(_vm.body))
                    ])
                  ]
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "gap-2 row-format mt-auto mb-6",
                  staticStyle: { "flex-wrap": "wrap" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        outlined: "",
                        color: "gray_50",
                        width: "50"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("back")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "gray_70" } }, [
                        _vm._v("arrow_back")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "", color: "primary", width: "240" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("next")
                        }
                      }
                    },
                    [
                      _c("span", [_vm._v("Continue")]),
                      _c("v-icon", { staticClass: "ml-2" }, [
                        _vm._v("arrow_forward")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "right",
        fn: function() {
          return [
            _c("div", {
              staticClass: "row-format centered fill-all pa-4",
              style:
                "\n\t\t\t\tbackground-image: url('/images/onboarding/carousel/" +
                _vm.image +
                "');\n\t\t\t\tbackground-size: contain;\n\t\t\t\tbackground-position: center;\n\t\t\t\tbackground-origin: content-box;\n\t\t\t"
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }