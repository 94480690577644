var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("audio", {
      staticStyle: { display: "none" },
      attrs: {
        src: "/media/ringback/US_ringback_tone.mp3",
        loop: "true",
        id: "Ringback"
      }
    }),
    _c("audio", {
      staticStyle: { display: "none" },
      attrs: { src: "/media/inbound-call.mp3", loop: "true", id: "InboundCall" }
    }),
    _c("audio", { staticStyle: { display: "none" }, attrs: { id: "DTMF" } }),
    _vm.rtcStream
      ? _c("video", {
          staticStyle: { display: "none" },
          attrs: { id: "phoneMedia", playsInline: "", autoPlay: "" }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }