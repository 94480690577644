var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          headers: _vm.headers,
          items: _vm.invoices,
          "sort-by": "dateCreated",
          "sort-desc": true,
          "items-per-page": -1,
          "hide-default-footer": true,
          "fixed-header": true
        },
        on: {
          "click:row": function($event) {
            return _vm.$emit("open-invoice", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.dateCreated",
            fn: function(ref) {
              var item = ref.item
              return [
                item.dateCreated
                  ? _c("span", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.dateCreated).toFormat("DD")
                        )
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.dateSent",
            fn: function(ref) {
              var item = ref.item
              return [
                item.dateSent
                  ? _c("span", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.dateSent).toFormat("DD")
                        )
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.dateDue",
            fn: function(ref) {
              var item = ref.item
              return [
                item.dateDue
                  ? _c("span", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.dateDue).toFormat("DD")
                        )
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.client",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "row-format align-center" },
                  [
                    item.clientInfo
                      ? _c("client-avatar", {
                          attrs: { small: "", client: item.clientInfo }
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "ml-n3" }, [
                      _vm._v(_vm._s(item.clientInfo.name))
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.invoiceNumberFormatted",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", { staticClass: "brand-semilight" }, [
                  _vm._v(_vm._s(item.invoiceNumberFormatted))
                ])
              ]
            }
          },
          {
            key: "item.total",
            fn: function(ref) {
              var item = ref.item
              return [
                item.total
                  ? _c("div", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$formatters.dollars(
                            item.total ? item.total : 0,
                            true,
                            true,
                            item.currency
                          )
                        )
                      )
                    ])
                  : _vm._e(),
                item.localTotal &&
                item.currency !== _vm.$store.state.defaultCurrency
                  ? _c("div", { staticClass: "font-12 font-gray_70" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$formatters.dollars(
                              item.localTotal ? item.localTotal : 0,
                              true,
                              true,
                              _vm.$store.state.defaultCurrency
                            )
                          ) +
                          " "
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.amountDue",
            fn: function(ref) {
              var item = ref.item
              return [
                item.total
                  ? _c("div", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$formatters.dollars(
                            item.amountDue ? item.amountDue : 0,
                            true,
                            true,
                            item.currency
                          )
                        )
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "row-format align-center" },
                  [
                    _c("div", {
                      staticClass: "brand-semilight font-12",
                      domProps: { innerHTML: _vm._s(_vm.getStatus(item)) }
                    }),
                    item.integrationKeys.quickbooksId
                      ? _c(
                          "v-icon",
                          { staticClass: "ml-2", attrs: { size: "20" } },
                          [_vm._v("$quickbooks")]
                        )
                      : _vm._e(),
                    item.integrationKeys.xeroId
                      ? _c(
                          "v-icon",
                          { staticClass: "ml-2", attrs: { size: "20" } },
                          [_vm._v("$xero")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("action-menu", {
                  staticClass: "action-menu",
                  attrs: {
                    "is-list": true,
                    invoice: item,
                    "send-enable": true,
                    "show-view": true
                  },
                  on: {
                    paid: function($event) {
                      return _vm.$emit("invoice-paid", {
                        invoice: item,
                        payment: $event
                      })
                    },
                    "delete-invoice": function($event) {
                      return _vm.$emit("remove-invoice", $event)
                    },
                    "set-invoice": function($event) {
                      return _vm.$emit("add-update-invoice", $event)
                    },
                    "view-detail": function($event) {
                      return _vm.$emit("open-invoice", $event)
                    },
                    "duplicate-invoice": function($event) {
                      return _vm.$emit("add-update-invoice", $event)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [
                _vm._v(
                  " No invoice records to display. You can try adjusting your filters or creating a new invoice. "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }