import HttpClient from "@/services/HttpClient";


export default class DiscoveryTemplateService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getDiscoveryTemplateList() {
		return this.httpClient.get('/discoveryTemplates')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDiscoveryTemplate(id) {
		return this.httpClient.get('/discoveryTemplates/' + id)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateDiscoveryTemplate(id, data) {
		return this.httpClient.put('/discoveryTemplates/' + id, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createDiscoveryTemplate(data) {
		return this.httpClient.post('/discoveryTemplates', data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteDiscoveryTemplate(id) {
		return this.httpClient.delete('/discoveryTemplates/' + id)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
