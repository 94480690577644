<template>
	<div id="ai-chat-widget">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h3 class="modal-title">✨{{ title }}</h3>
			<ai-chat-core
				ref="ChatCore"
				:context="context"
				:initial-prompt="initialPrompt"
				:finalization-prompt="finalizationPrompt"
				:use-case="useCase"
				:model="model"
				:result-format="resultFormat"
				:temperature="temperature"
				:top-p="topP"
				@result="$emit('result', $event)"
			></ai-chat-core>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" width="130" @click="handleClose()">
				<span class="font-gray_70">{{ $t('global.cancel') }}</span>
			</v-btn>
			<v-btn class="super-action ml-1" width="130" @click="finalize()">
				{{ $t('global.create') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import AiChatCore from '@/modules/ai/AiChatCore';
	export default {
		name: 'AiChatWidget',

		props: {
			title: {
				type: String,
				default: 'AI Assistant',
			},
			context: {
				type: Array,
				required: true,
			},
			initialPrompt: {
				type: String,
				required: true,
			},
			finalizationPrompt: {
				type: String,
				required: true,
			},
			useCase: {
				type: String,
				default: 'GeneralChat',
			},
			model: {
				type: String,
				default: 'gpt-4o-mini',
			},
			resultFormat: {
				type: String,
				default: 'text',
			},
			temperature: {
				type: Number,
				default: 0.5,
			},
			topP: {
				type: Number,
				default: 0.5,
			},
		},

		components: { AiChatCore },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleClose: function() {
				this.$emit('result');
			},

			finalize: function() {
				this.$refs.ChatCore.finalize();
			},
		},
	};
</script>

<style lang="scss">
	#ai-chat-widget {
		max-width: 684px;
		min-width: 684px;
		background-color: var(--v-white-base);
	}
</style>
