var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.agreement
    ? _c(
        "div",
        {
          staticClass: "row-format gap-2 font-14 pointer",
          on: { click: _vm.openAgreement }
        },
        [
          _c(
            "v-icon",
            {
              staticClass: "material-symbols-rounded",
              attrs: { color: "secondary", size: "20" }
            },
            [_vm._v("handshake")]
          ),
          _c("div", [
            _vm._v(
              _vm._s(_vm.agreement.name) + " | " + _vm._s(_vm.lastEvent.event)
            )
          ]),
          _c("div", { staticClass: "ml-auto font-gray_80 font-12 nowrap" }, [
            _vm._v(_vm._s(_vm._f("formatForTimeAgo")(_vm.lastEvent.timestamp)))
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }