var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row-format pointer py-2 font-14 todo",
      on: {
        click: function($event) {
          return _vm.$emit("todo-clicked")
        }
      }
    },
    [
      _c("v-icon", { staticClass: "material-symbols-rounded mr-3" }, [
        _vm._v("filter_alt")
      ]),
      _vm.client
        ? _c("client-avatar", {
            staticClass: "mr-3",
            attrs: { client: _vm.client }
          })
        : _c("div", { staticStyle: { width: "2px", height: "32px" } }),
      _c(
        "div",
        {
          staticClass: "row-format align-center",
          staticStyle: { "flex-wrap": "wrap", gap: "4px" }
        },
        [
          _c("div", { staticClass: "opportunityName mr-3" }, [
            _vm._v(_vm._s(_vm.opportunityToDo.opportunityName))
          ]),
          _c("div", { staticClass: "deliverableName mr-3 brand-medium" }, [
            _vm._v(_vm._s(_vm.opportunityToDo.toDo.item))
          ]),
          _c(
            "div",
            { staticClass: "dueDate mr-3 row-format align-center" },
            [
              _c("v-icon", { attrs: { size: "15", color: "gray_70" } }, [
                _vm._v("$clock")
              ]),
              _c("div", { staticClass: "font-gray_80" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.opportunityToDo.luxonDate.toLocaleString({
                        month: "short",
                        day: "numeric"
                      })
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c("v-checkbox", {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { content: "Mark complete" },
                expression: "{ content: 'Mark complete' }"
              }
            ],
            staticClass: "mt-n1",
            attrs: { "hide-details": "", dense: "" },
            on: {
              click: function($event) {
                $event.stopPropagation()
              },
              change: _vm.handleChange
            },
            model: {
              value: _vm.opportunityToDo.complete,
              callback: function($$v) {
                _vm.$set(_vm.opportunityToDo, "complete", $$v)
              },
              expression: "opportunityToDo.complete"
            }
          }),
          _c(
            "div",
            {
              staticClass: "opportunityStatus row-format align-center",
              style:
                "--status-color:" +
                (_vm.opportunityToDo.stage
                  ? _vm.opportunityToDo.stage.hexColor
                  : "#FFF")
            },
            [
              _c("div", { staticClass: "statusBox" }),
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.opportunityToDo.stage
                      ? _vm.opportunityToDo.stage.label
                      : "?"
                  )
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }