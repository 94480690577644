<template>
	<div class="row-format gap-2">
		<v-btn
			v-for="(day, index) in daysOfWeek"
			:key="index"
			:class="{ active: activeDays.includes(day.value) }"
			:color="activeDays.includes(day.value) ? 'primary' : 'gray_30'"
			style="border-radius: 50%"
			fab
			x-small
			elevation="0"
			@click="toggleDay(day.value)"
		>
			{{ day.label }}
		</v-btn>
	</div>
</template>

<script>
	export default {
		name: 'DailyScheduler',

		props: ['value'],

		data() {
			return {
				daysOfWeek: [
					{ label: 'S', value: 7 },
					{ label: 'M', value: 1 },
					{ label: 'T', value: 2 },
					{ label: 'W', value: 3 },
					{ label: 'T', value: 4 },
					{ label: 'F', value: 5 },
					{ label: 'S', value: 6 },
				],
				activeDays: [... this.value],
			};
		},
		methods: {
			toggleDay(day) {
				if (this.activeDays.includes(day)) {
					let ix = this.activeDays.findIndex((d) => d === day);
					this.activeDays.splice(ix, 1);
				} else {
					this.activeDays.push(day);
				}
				this.activeDays.sort((a, b) => a - b);
				this.$emit('input',this.activeDays);
			},
		},
	};
</script>

<style scoped></style>
