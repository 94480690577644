var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "font-14" },
    [
      _vm._l(_vm.notes, function(note, index) {
        return _c(
          "div",
          {
            key: note.id,
            staticClass: "px-2 pt-2",
            staticStyle: { "border-bottom": "1px solid var(--v-gray_30-base)" }
          },
          [
            _c(
              "div",
              { staticClass: "note-container text-left" },
              [
                _c(
                  "div",
                  {
                    staticClass: "row-format",
                    staticStyle: { "align-items": "center" }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticStyle: { color: "var(--v-gray_70-base)" },
                        attrs: { size: "14" }
                      },
                      [_vm._v("$time")]
                    ),
                    _c("div", { staticClass: "font-grey font-12 ml-1" }, [
                      _vm._v(
                        _vm._s(_vm.$DateTime.humanDateTime(note.timestamp))
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "delete-button pointer",
                        staticStyle: {
                          "margin-left": "auto",
                          "padding-right": "1px"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.confirmDeleteNote(note, index)
                              }
                            }
                          },
                          [_vm._v("$delete")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.activeNote !== note.id
                  ? _c("div", {
                      domProps: { innerHTML: _vm._s(note.entry) },
                      on: {
                        click: function($event) {
                          _vm.activeNote = note.id
                        }
                      }
                    })
                  : _c("editor", {
                      ref: note.id,
                      refInFor: true,
                      staticClass: "no-wrap-editor",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "api-key": _vm.$store.getters.getTinyMceKey,
                        inline: true,
                        init: _vm.mceConfig(note)
                      },
                      on: {
                        update: function($event) {
                          return _vm.saveNote($event, note)
                        },
                        onblur: function($event) {
                          return _vm.deactivateNote(note)
                        }
                      },
                      model: {
                        value: note.entry,
                        callback: function($$v) {
                          _vm.$set(note, "entry", $$v)
                        },
                        expression: "note.entry"
                      }
                    })
              ],
              1
            )
          ]
        )
      }),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": "Confirm",
          "yes-text": "Yes",
          "no-text": "No",
          "body-text": _vm.$t("productivity.delete-note-confirm")
        },
        on: {
          close: function($event) {
            return _vm.cancelDelete()
          },
          confirm: function($event) {
            return _vm.handleDeleteNote()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }