var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "confirmation", staticStyle: { "min-height": "250px" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "column-format", attrs: { cols: "12" } },
            [
              _vm._l(_vm.meeting.reminders, function(reminder, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "column-format gap-2 text-left mb-4"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row-format" },
                      [
                        _c("div", { staticClass: "brand-medium font-14" }, [
                          _vm._v("Notification " + _vm._s(index + 1))
                        ]),
                        _c(
                          "v-icon",
                          {
                            staticClass:
                              "ml-auto material-symbols-rounded pointer",
                            attrs: { size: "20", color: "gray_70" },
                            on: {
                              click: function($event) {
                                return _vm.deleteReminder(index)
                              }
                            }
                          },
                          [_vm._v("delete")]
                        )
                      ],
                      1
                    ),
                    _c("v-select", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                        label: "Type",
                        items: _vm.reminderTypes,
                        "item-text": "label",
                        "item-value": "value"
                      },
                      model: {
                        value: reminder.reminderType,
                        callback: function($$v) {
                          _vm.$set(reminder, "reminderType", $$v)
                        },
                        expression: "reminder.reminderType"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "row-format gap-2" },
                      [
                        _c("duration-selector", {
                          staticClass: "flex-grow-1",
                          attrs: {
                            label: "Timing",
                            options: reminder.before
                              ? _vm.reminderIncrementsBefore
                              : _vm.reminderIncrementsAfter
                          },
                          model: {
                            value: reminder.duration,
                            callback: function($$v) {
                              _vm.$set(reminder, "duration", $$v)
                            },
                            expression: "reminder.duration"
                          }
                        }),
                        _c("v-select", {
                          staticStyle: { "max-width": "200px" },
                          attrs: {
                            outlined: "",
                            "hide-details": "",
                            dense: "",
                            "persistent-placeholder": "",
                            label: "Notification timing",
                            items: _vm.remindTiming
                          },
                          model: {
                            value: reminder.before,
                            callback: function($$v) {
                              _vm.$set(reminder, "before", $$v)
                            },
                            expression: "reminder.before"
                          }
                        })
                      ],
                      1
                    ),
                    reminder.reminderType === "EMAIL"
                      ? _c("v-select", {
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            dense: "",
                            outlined: "",
                            label: "Email template (required)",
                            items: _vm.emailTemplates,
                            "item-value": "id",
                            "item-text": "name",
                            clearable: ""
                          },
                          model: {
                            value: reminder.emailTemplateId,
                            callback: function($$v) {
                              _vm.$set(reminder, "emailTemplateId", $$v)
                            },
                            expression: "reminder.emailTemplateId"
                          }
                        })
                      : _vm._e(),
                    reminder.reminderType === "SMS"
                      ? _c(
                          "div",
                          { staticClass: "column-format gap-2" },
                          [
                            _c("v-select", {
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                                label: "From",
                                items: _vm.subscriptions,
                                "item-value": "userId"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(item.phone) +
                                              " - " +
                                              _vm._s(item.user.firstName) +
                                              " " +
                                              _vm._s(item.user.lastName)
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(item.phone) +
                                              " - " +
                                              _vm._s(item.user.firstName) +
                                              " " +
                                              _vm._s(item.user.lastName)
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: reminder.userId,
                                callback: function($$v) {
                                  _vm.$set(reminder, "userId", $$v)
                                },
                                expression: "reminder.userId"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "row-format gap-2" },
                              [
                                _c("v-textarea", {
                                  staticStyle: { flex: "1 1 0" },
                                  attrs: {
                                    rows: 3,
                                    "auto-grow": "",
                                    placeholder: "Message",
                                    "hide-details": "",
                                    dense: "",
                                    "persistent-placeholder": "",
                                    outlined: ""
                                  },
                                  model: {
                                    value: reminder.smsMessage,
                                    callback: function($$v) {
                                      _vm.$set(reminder, "smsMessage", $$v)
                                    },
                                    expression: "reminder.smsMessage"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "row-format flex-wrap gap-1",
                                    staticStyle: {
                                      flex: "1 1 0",
                                      "align-content": "flex-start"
                                    }
                                  },
                                  _vm._l(_vm.tokens, function(token) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: token.v,
                                        attrs: { small: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.appendToken(
                                              reminder,
                                              token
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(token.t))]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              !_vm.meeting.reminders.length
                ? _c("div", { staticClass: "font-gray_50" }, [
                    _vm._v(" No notifications configured ")
                  ])
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "mb-3",
                  attrs: { text: "" },
                  on: { click: _vm.addReminder }
                },
                [_vm._v("+ Add notification")]
              )
            ],
            2
          ),
          _c(
            "v-col",
            {
              staticClass: "column-format gap-3 pt-3",
              staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
            },
            [
              _c("div", { staticClass: "brand-medium font-14" }, [
                _vm._v("Confirmations")
              ]),
              _c("v-select", {
                attrs: {
                  disabled: _vm.emailTemplates.length === 0,
                  "hide-details": "",
                  "persistent-placeholder": "",
                  dense: "",
                  outlined: "",
                  label: "Confirmation email template",
                  items: _vm.emailTemplates,
                  "item-value": "id",
                  "item-text": "name",
                  clearable: ""
                },
                model: {
                  value: _vm.meeting.confirmationEmailTemplate,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "confirmationEmailTemplate", $$v)
                  },
                  expression: "meeting.confirmationEmailTemplate"
                }
              }),
              _vm.subscriptions.length
                ? _c(
                    "div",
                    { staticClass: "column-format gap-2" },
                    [
                      _c("v-select", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          "persistent-placeholder": "",
                          label: "Send SMS confirmation from",
                          items: _vm.subscriptions,
                          "item-value": "userId"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(item.phone) +
                                        " - " +
                                        _vm._s(item.user.firstName) +
                                        " " +
                                        _vm._s(item.user.lastName)
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(item.phone) +
                                        " - " +
                                        _vm._s(item.user.firstName) +
                                        " " +
                                        _vm._s(item.user.lastName)
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          972505052
                        ),
                        model: {
                          value: _vm.meeting.confirmationSmsUserId,
                          callback: function($$v) {
                            _vm.$set(_vm.meeting, "confirmationSmsUserId", $$v)
                          },
                          expression: "meeting.confirmationSmsUserId"
                        }
                      }),
                      _vm.meeting.confirmationSmsUserId
                        ? _c(
                            "div",
                            { staticClass: "row-format gap-2" },
                            [
                              _c("v-textarea", {
                                staticStyle: { flex: "1 1 0" },
                                attrs: {
                                  rows: 3,
                                  "auto-grow": "",
                                  placeholder: "SMS confirmation message",
                                  "hide-details": "",
                                  dense: "",
                                  "persistent-placeholder": "",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.meeting.confirmationSmsMessage,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.meeting,
                                      "confirmationSmsMessage",
                                      $$v
                                    )
                                  },
                                  expression: "meeting.confirmationSmsMessage"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "row-format flex-wrap gap-1",
                                  staticStyle: {
                                    flex: "1 1 0",
                                    "align-content": "flex-start"
                                  }
                                },
                                _vm._l(_vm.tokens, function(token) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: token.v,
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.appendConfirmationToken(
                                            token
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(token.t))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-select", {
                attrs: {
                  label: "After scheduling meeting, navigate to",
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  items: _vm.pageOptions
                },
                model: {
                  value: _vm.meeting.defaultConfirmationPage,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "defaultConfirmationPage", $$v)
                  },
                  expression: "meeting.defaultConfirmationPage"
                }
              }),
              !_vm.meeting.defaultConfirmationPage
                ? _c("v-text-field", {
                    attrs: {
                      label: "Redirect URL",
                      placeholder: "http://www.yourwebsite.com/confirmation",
                      "persistent-placeholder": "",
                      "hide-details": "",
                      dense: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.meeting.confirmationRedirect,
                      callback: function($$v) {
                        _vm.$set(_vm.meeting, "confirmationRedirect", $$v)
                      },
                      expression: "meeting.confirmationRedirect"
                    }
                  })
                : _vm._e(),
              !_vm.meeting.defaultConfirmationPage
                ? _c("v-checkbox", {
                    attrs: {
                      "false-value": false,
                      "true-value": true,
                      "hide-details": "",
                      dense: "",
                      label: "Append meeting info to URL"
                    },
                    model: {
                      value: _vm.meeting.appendInfoToRedirect,
                      callback: function($$v) {
                        _vm.$set(_vm.meeting, "appendInfoToRedirect", $$v)
                      },
                      expression: "meeting.appendInfoToRedirect"
                    }
                  })
                : _vm._e(),
              !_vm.meeting.defaultConfirmationPage &&
              _vm.meeting.appendInfoToRedirect
                ? _c(
                    "div",
                    { staticClass: "text-left font-14 mt-3 font-gray_80" },
                    [
                      _vm._v(
                        " The following URL parameters will be appended to the redirect:"
                      ),
                      _c("br"),
                      _c("ul", [
                        _c("li", [
                          _c("span", { staticClass: "brand-medium" }, [
                            _vm._v("id")
                          ]),
                          _vm._v(":   Unique meeting id")
                        ]),
                        _c("li", [
                          _c("span", { staticClass: "brand-medium" }, [
                            _vm._v("start")
                          ]),
                          _vm._v(":   ISO formatted date/time")
                        ]),
                        _c("li", [
                          _c("span", { staticClass: "brand-medium" }, [
                            _vm._v("duration")
                          ]),
                          _vm._v(":   Length of meeting in minutes")
                        ]),
                        _c("li", [
                          _c("span", { staticClass: "brand-medium" }, [
                            _vm._v("location")
                          ]),
                          _vm._v(":   Description of meeting location")
                        ]),
                        _c("li", [
                          _c("span", { staticClass: "brand-medium" }, [
                            _vm._v("email")
                          ]),
                          _vm._v(":   Invitee email address")
                        ]),
                        _c("li", [
                          _c("span", { staticClass: "brand-medium" }, [
                            _vm._v("name")
                          ]),
                          _vm._v(":   Invitee name")
                        ])
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }