<template>
	<div>
		<div>Attach files to invoice</div>
	</div>
</template>

<script>
	export default {
		name: 'FileAttachmentsConfig',

		props: ['item'],

		components: {},

		data: function() {
			return {
				element: {... this.item},
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
