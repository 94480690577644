<template>
	<div class="text-left column-format gap-2">
		<div>To continue registering for a phone line in {{selectedCountry.name}} please choose whether you operate as an Individual or a Business.</div>
		<v-select :items="['Individual','Business']" v-model="endUserType" class="h-outline" persistent-placeholder hide-details label="End user type"></v-select>
		<v-btn class="super-action" @click="confirmRegistration()">Continue</v-btn>
	</div>
</template>

<script>
	import countries from '@/assets/data/countries-simple.json'
	import ConfirmModal from "@/components/ConfirmModal";
	import TwilioService from "@/modules/communicator/realtime/twilio/TwilioService";

	export default {
		name: 'CreateTwilioAccount',

		props: ['country'],

		components: {},

		data: function() {
			return {
				twilioService: new TwilioService(),
				endUserType: 'Business',
				countries: countries,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			confirmRegistration: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: `Please confirm your selection of: ${this.endUserType} in ${this.selectedCountry.name}.  This cannot be changed once your account is provisioned.`
				}

				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.$store.commit('startLoading');
						this.twilioService.createAccount(this.country.toUpperCase(),this.endUserType.toUpperCase()).then(() => {
							this.$store.commit('stopLoading');
							this.$emit('order-complete');
						})
					}
				})
			}
		},

		computed: {
			selectedCountry: function(){
				return this.countries.find(c => c.code === this.country);
			}
		},
	};
</script>

<style scoped lang="scss"></style>
