var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format gap-4",
      attrs: { id: "invoice-table-and-chart" }
    },
    [
      _vm.filteredInvoices.length
        ? _c(
            "div",
            {
              staticClass: "row-format gap-3",
              staticStyle: { "flex-wrap": "wrap" }
            },
            [
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit text-left" }, [
                  _vm._v(" Total invoiced "),
                  _c("span", { staticClass: "font-gray_70" }, [
                    _vm._v(
                      "(" + _vm._s(_vm.filteredInvoices.length) + " invoices)"
                    )
                  ])
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$formatters.dollars(_vm.totalInvoiced, true, true)
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit text-left" }, [
                  _vm._v("Total due")
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(
                    _vm._s(_vm.$formatters.dollars(_vm.totalDue, true, true))
                  )
                ])
              ]),
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit text-left" }, [
                  _vm._v("Avg days to pay")
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(_vm._s(_vm.avgDaysToPay))
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm.isReady
        ? _c(
            "div",
            [
              _c(
                "v-tabs",
                {
                  staticClass: "mb-4",
                  model: {
                    value: _vm.invoiceTab,
                    callback: function($$v) {
                      _vm.invoiceTab = $$v
                    },
                    expression: "invoiceTab"
                  }
                },
                [
                  _c("v-tab", { key: "0" }, [_vm._v("Invoice list")]),
                  _c("v-tab", { key: "1" }, [_vm._v("Recurring invoices")]),
                  _c("v-tab", { key: "2" }, [_vm._v("Payment plans")])
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invoiceTab === 1,
                      expression: "invoiceTab === 1"
                    }
                  ]
                },
                [
                  _c("recurring-invoice-list", {
                    ref: "RecurringInvoice",
                    attrs: { filter: _vm.filter },
                    on: {
                      "record-count": function($event) {
                        return _vm.setRecurringCount($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invoiceTab === 2,
                      expression: "invoiceTab === 2"
                    }
                  ]
                },
                [
                  _c("payment-plan-list", {
                    ref: "PaymentPlan",
                    attrs: { filter: _vm.filter }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invoiceTab === 0,
                      expression: "invoiceTab === 0"
                    }
                  ]
                },
                [
                  !_vm.invoices.length && !_vm.recurringInvoiceCount
                    ? _c(
                        "div",
                        {
                          staticClass: "row-format centered fill-height",
                          staticStyle: { height: "calc(var(--vh) - 300px)" }
                        },
                        [
                          _c("empty-view", {
                            attrs: {
                              header: "No invoices yet",
                              body:
                                "You haven’t sent any invoices  yet. Send a manual invoice or set up recurring invoices -- Moxie follows your schedule and adds your work to the invoice, and you just approve it before sending.",
                              cta: "Send an invoice",
                              "video-header": "See how it works",
                              "video-body":
                                "Learn how to add the work you’ve done into an invoice. You can also see how to set up automatic invoices.",
                              "video-cta": "Watch the tutorial",
                              "video-id": "VHlGLkW1suI"
                            },
                            on: {
                              "cta-clicked": function($event) {
                                return _vm.addNew()
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("invoice-table", {
                            attrs: { invoices: _vm.filteredInvoices },
                            on: {
                              "add-new": function($event) {
                                return _vm.addNew()
                              },
                              "open-invoice": function($event) {
                                return _vm.openInvoice($event)
                              },
                              "add-update-invoice": function($event) {
                                return _vm.addOrUpdateInvoice($event)
                              },
                              "remove-invoice": function($event) {
                                return _vm.removeInvoice($event)
                              },
                              "invoice-paid": function($event) {
                                return _vm.handleInvoicePaid($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }