<template>
	<div v-if="isReady">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.zoom.desc') }}</div>
				<connected v-if="isConnected"></connected>
				<div v-else class="font-primary brand-medium pointer" @click="connectZoomAccount">Connect Zoom</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<div class="mb-6 font-14">
				<span class="brand-medium">Zoom Account:</span> {{zoomAuth.zoomUser.first_name}} {{zoomAuth.zoomUser.last_name}}<br>
				<span class="brand-medium">Zoom User Email:</span> {{zoomAuth.zoomUser.email}}
			</div>
			<v-btn class="secondary-action mr-1" @click="confirmDisconnect"><v-icon small>$delete</v-icon>Disconnect</v-btn>
		</div>
	</div>
</template>

<script>
	import Connected from '@/modules/account/apps/Connected';
	import ConfirmModal from '@/components/ConfirmModal';
	import ZoomAuthService from "@/modules/integrations/zoom/ZoomAuthService";

	export default {
		name: 'GoogleCalendar',

		props: [],

		components: { Connected },

		data: function () {
			return {
				zoomAuthService: new ZoomAuthService(),
				isConnected: false,
				expanded: false,
				isReady: false,
				zoomAuth: null,
			};
		},

		mounted() {
			this.checkConnected();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.isConnected = false;
				this.checkConnected();
			},

			checkConnected: function () {
				this.zoomAuthService
					.getStatus()
					.then((res) => {
						if(res.data){
							this.isConnected = true;
							this.zoomAuth = res.data;
						}else{
							this.isConnected = false;
						}

					})
					.finally(() => (this.isReady = true));
			},

			connectZoomAccount: function () {
				let clientId = process.env.VUE_APP_ZOOM_KEY;
				let redirectUri = document.location.protocol + '//' + document.location.host + '/integrations/zoom/oauth';
				let url = `https://zoom.us/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
				window.location.href = url;
			},

			confirmDisconnect: function () {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to disconnect your Zoom account?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.zoomAuthService.disconnect().then(() => {
							this.isConnected = false;
							this.expanded = false;
						});
					}
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
