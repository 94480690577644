var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ourThread
    ? _c("div", { staticClass: "text-left", staticStyle: { height: "100%" } }, [
        _c("div", { staticClass: "row-format" }, [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("back")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { color: "black" }
                    },
                    [_vm._v("arrow_back")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ml-auto row-format align-center" },
            [
              _vm.ourFolder !== "Inbox"
                ? _c("div", { staticClass: "mr-2" }, [
                    _vm._v(" " + _vm._s(_vm.ourFolder) + " "),
                    _vm.ourFolder === "Snoozed"
                      ? _c("span", [
                          _vm._v(
                            "until " +
                              _vm._s(
                                _vm.HDateTime.fromISO(
                                  _vm.ourThread.snoozedUntil
                                ).toLocaleString(_vm.HDateTime.DATETIME_MED)
                              )
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("inbox-actions", {
                attrs: {
                  size: 24,
                  unread: _vm.ourThread.unread > 0,
                  "snooze-options": _vm.snoozeOptions,
                  folder: _vm.ourFolder,
                  "item-type": "MessageThread",
                  "item-id": _vm.ourThread.id
                },
                on: {
                  "return-to-inbox": function($event) {
                    return _vm.returnToInbox()
                  },
                  snooze: function($event) {
                    return _vm.snooze($event)
                  },
                  archive: function($event) {
                    return _vm.archive()
                  },
                  unread: function($event) {
                    return _vm.markThreadAsRead()
                  }
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "pa-2 column-format",
            staticStyle: { height: "100%" }
          },
          [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                !_vm.clientId && _vm.client
                  ? _c("client-avatar", { attrs: { client: _vm.client } })
                  : _vm._e(),
                _c(
                  "div",
                  { class: !_vm.clientId && _vm.client ? "ml-2" : "" },
                  [_vm._v(_vm._s(_vm.participants.join(" & ")))]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "show-scrollbar mb-3 mt-5",
                staticStyle: {
                  "max-height": "calc(100vh - 320px)",
                  "overflow-y": "scroll"
                },
                attrs: { id: "thread-" + _vm.ourThread.id }
              },
              [
                _vm.messages.length >= _vm.recordsPerFetch
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "row-format centered pointer font-12 font-gray_70 mt-4"
                      },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.getOlderMessages()
                              }
                            }
                          },
                          [_vm._v("Older messages")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.messages, function(message, ix) {
                  return _c(
                    "div",
                    { key: message.id, staticClass: "my-2 row-format pr-4" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "column-format",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _vm.showTimeStamp(ix)
                            ? _c(
                                "div",
                                {
                                  class:
                                    "font-10 font-gray_70 mt-2 " +
                                    (message.direction === "Out"
                                      ? "ml-auto mr-1"
                                      : "ml-1")
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$formatters.formatTimeAgo(
                                          message.timestamp
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { class: message.direction },
                            [
                              message.message && message.direction === "In"
                                ? _c("div", [_vm._v(_vm._s(message.message))])
                                : _vm._e(),
                              message.message && message.direction === "Out"
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tippy",
                                          rawName: "v-tippy",
                                          value: {
                                            content: _vm.$store.getters.getUserById(
                                              message.userId
                                            ).firstName
                                          },
                                          expression:
                                            "{content: $store.getters.getUserById(message.userId).firstName}"
                                        }
                                      ]
                                    },
                                    [_vm._v(_vm._s(message.message))]
                                  )
                                : _vm._e(),
                              _vm._l(message.s3Media, function(s3File) {
                                return _c(
                                  "div",
                                  { key: s3File.path + s3File.fileName },
                                  [
                                    _c("s3-file-handler", {
                                      attrs: { "s3-file": s3File }
                                    })
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ]
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              [
                _c("v-textarea", {
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    outlined: "",
                    rows: "1",
                    "no-resize": "",
                    "auto-grow": "",
                    autofocus: ""
                  },
                  on: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      ) {
                        return null
                      }
                      $event.preventDefault()
                    },
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      ) {
                        return null
                      }
                      return _vm.sendMessage.apply(null, arguments)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "font-secondary pointer py-1 pl-2",
                                staticStyle: {
                                  "border-left":
                                    "1px solid var(--v-gray_50-base)",
                                  "margin-top": "2px"
                                },
                                on: { click: _vm.sendMessage }
                              },
                              [_vm._v(" Send ")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    259946177
                  ),
                  model: {
                    value: _vm.newMessage,
                    callback: function($$v) {
                      _vm.newMessage = $$v
                    },
                    expression: "newMessage"
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }