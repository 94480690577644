<template>
	<!-- Using flex-column to spread elements vertically -->
	<div :id="planCode" :style="`border-radius: 12px; max-width:450px; height:100%; ${cardPlan.code === 'pro-monthly' ? 'background-color: var(--v-gray_10-base)' : ''}`" class="h-outline pa-6">
		<div class="text-left mb-2">
			<div class="font-24 mb-2">{{ $t('subscription.card.' + cardPlan.code + '.header') }}</div>
			<div class="font-16 brand-semilight mb-2">{{ $t('subscription.card.' + cardPlan.code + '.sub-header') }}</div>
			<div style="font-size: 40px" v-if="$t('subscription.card.' + cardPlan.code + '.price') === '0'">Free forever</div>
			<div style="font-size: 40px" v-else>{{ $formatters.dollars($t('subscription.card.' + cardPlan.code + '.price'),true,true,'USD') }}</div>
		</div>

		<v-btn
			v-on:click="handlePlanClick(cardPlan.code)"
			:disabled="disabled || !canManageSubscription"
			block
			large
			elevation="0"
			:color="buttonColor"
			:outlined="buttonOutlined"
			:style="buttonStyle"
		>
			{{ this.buttonVerb() }}
		</v-btn>

		<v-divider class="my-4"></v-divider>

		<div class="row-format align-center text-left mb-3" v-for="(bullet, i) in bullets" :key="i">
			<v-icon size="20" color="primary" class="mr-2">$success</v-icon>
			<div class="font-14 brand-semilight">{{ bullet }}</div>
		</div>

		<p class="pending-plan-notice justify-center mb-0 pb-0 mt-2" v-html="pendingPlanNotice"></p>
	</div>
</template>

<script>
	import SubscriptionService from '../SubscriptionService';

	export default {
		name: 'PlanCard',
		props: {
			account: { type: Object },
			cardPlan: { type: Object },
			planHash: { type: Object },
			customerPlan: { type: Object },
			pendingPlan: { type: Object },
			planCode: { type: String },
			isProcessing: { type: Boolean },
			canManageSubscription: { type: Boolean },
			subscriptionManagedBy: { type: String },
		},

		data() {
			return {
				subscriptionService: new SubscriptionService(),
				cardPlanName: null,
				planOrder: { free: 0, pro: 1, teams: 2 },
				buttonColor: 'gray_50',
				buttonOutlined: false,
				buttonStyle: '',
			};
		},

		mounted() {
			[this.cardPlanName] = this.cardPlan.name.split(/[^a-zA-Z0-9]/);

			this.setButtonProps();
		},

		methods: {
			setButtonProps() {
				switch (this.cardPlan.code) {
					case 'teams-monthly':
						this.buttonColor = 'primary';
						this.bulletColor = 'primary';
						this.buttonStyle = 'color: var(--v-white-base)';
						this.buttonOutlined = !this.canManageSubscription ? true : false;
						break;
					case 'pro-monthly':
						this.buttonColor = 'accent';
						this.bulletColor = 'accent';
						this.buttonStyle = 'color: var(--v-secondary-base)';
						this.buttonOutlined = !this.canManageSubscription ? true : false;
						break;
				}
				// Then override depending on customerPlan
			},
			handlePlanClick(planCode) {
				if (this.cardPlan.code == this.pendingPlan.plan.code) {
					this.$emit('delete-pending', planCode);
					return;
				}
				this.$emit('plan-click', planCode);
			},

			buttonVerb() {
				if (this.isCurrentPlan) return this.$t('subscription.card.button.current');
				if (!this.canManageSubscription) return 'Manage in ' + this.subscriptionManagedBy;
				if (this.pendingPlan !== '' && this.cardPlan.code == this.pendingPlan.plan.code)
					return this.$t('subscription.card.button.cancel-pending');
				// if (this.isCurrentPlan && !this.isCurrentCadence)
				// 	return this.$t('subscription.card.button.switch', { 'monthly-quarterly': this.cardPlan.cadence });
				if (this.lesserPlan) return this.$t('subscription.card.button.downgrade');
				return this.$t('subscription.card.button.upgrade');
			},
		},
		computed: {
			pendingPlanNotice: {
				get: function () {
					if (this.$validations.isEmpty(this.pendingPlan.plan)) return '';
					if (this.cardPlan.code == this.pendingPlan.plan.code) return this.pendingPlan.notice;

					/* if (
						this.planHash[this.pendingPlan.plan.code]['cadence'] !== this.cardPlan.cadence &&
						this.customerPlan.plan.code == this.cardPlan.code
					) */

					// if (this.isCurrentPlan) return this.pendingPlan.notice;
					return '';
				},
			},

			bullets: {
				get: function () {
					let key = ''.concat('subscription.card.', this.cardPlan.code, '.bullets');
					return this.$t(key);
				},
			},

			disabled() {
				if (this.isCurrentPlan || this.isProcessing) {
					return true;
				} else {
					return false;
				}
			},

			planTranslation() {
				if (this.cardPlan.code === 'free') {
					return 'FREE';
				} else if (this.cardPlan.code === 'pro-monthly') {
					return 'PAID';
				} else if (this.cardPlan.code === 'teams-monthly') {
					return 'TEAM';
				} else {
					return 'FREE';
				}
			},

			// isExactCurrentPlan() {
			// 	if (this.cardPlan.price == 0 && this.customerPlan.subtotal == 0) return true;
			// 	return this.cardPlan.code === this.customerPlan.plan.code;
			// },

			isCurrentPlan() {
				return this.planTranslation === this.account.subscriptionType;
			},

			// isCurrentCadence() {
			// 	return this.cardPlan.cadence === this.customerPlan.plan.cadence;
			// },

			isFreePlan() {
				if (this.planCode == undefined) return true;
				return this.planHash[this.planCode]['price'] == 0;
			},

			lesserPlan() {
				return (
					this.planOrder[this.cardPlan.shortPlan] < this.planOrder[this.customerPlan.plan.shortPlan] ||
					(this.planOrder[this.cardPlan.shortPlan] == this.planOrder[this.customerPlan.plan.shortPlan] &&
						this.cardPlan.interval < this.customerPlan.plan.interval)
				);
			},
		},
	};
</script>

<style lang="scss" scoped>
	//@import '~vuetify/src/styles/styles.sass';
	.plan-card {
		display: flex;
		flex-direction: column;
		width: 404px;
		height: 483px;
		padding: 52px 42px;
		background-color: var(--v-white-base);
		h3 {
			display: flex;
			flex-grow: 1;
			text-align: left;
			font-size: 24px;
			line-height: 32px;
			.early-adopter {
				margin-right: 8px;
			}
			.plan-name {
				color: var(--v-primary-base);
				text-transform: uppercase;
				font-weight: 400;
				font-size: 28px;
			}
		}
		h2 {
			display: flex;
			margin: 4px 0;
			flex-grow: 1;
			text-align: left;
			font-size: 32px;
			line-height: 32px;
		}

		.bullets {
			flex-grow: 0;
			font-size: 20px;
			line-height: 28px;
			.bullet {
				margin-top: 20px;
				margin-bottom: 20px;
				.icon {
					svg {
						width: 12px;
					}
					margin-right: 12px;
					width: 20px;
					float: left;
				}
				.bullet-text {
					padding-top: 2px;
				}
				clear: both;
			}
		}
		&.sm-and-down {
			margin: 0 8px;
			padding-top: 20px;
			padding-bottom: 32px;
			display: block;
			width: auto;
			max-width: 360px;
			height: auto;
			h3 {
				font-size: 20px;
				line-height: 24px;
			}
			h2 {
				margin-top: 10px;
				font-size: 20px;
				line-height: 24px;
			}
			.bullets {
				margin: 12px 0;
				line-height: 28px;
				font-size: 16px;
				.bullet {
					margin-top: 8px;
					margin-bottom: 8px;
				}
			}
		}
		.pending-plan-notice {
			color: var(--v-gray_70-base);
			font-size: 13px;
		}

		&#free,
		&#pro-monthly {
			//border: 2px solid var(--v-gray_30-base);
		}
		&#free {
			.plan-name {
				color: var(--v-gray_70-base);
			}
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border-left: 2px solid var(--v-gray_30-base);
			border-top: 2px solid var(--v-gray_30-base);
			border-bottom: 2px solid var(--v-gray_30-base);
			&.sm-and-down {
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
				border-right: 2px solid var(--v-gray_30-base);
				border-left: 2px solid var(--v-gray_30-base);
				border-top: 2px solid var(--v-gray_30-base);
				border-bottom: none;
			}
			h2 {
				color: var(--v-gray_70-base);
			}
		}
		&#pro-monthly {
			border-top: 2px solid var(--v-gray_30-base);
			border-bottom: 2px solid var(--v-gray_30-base);
			&.sm-and-down {
				padding-top: 32px;
				border-top-left-radius: 0px;
				border-top-right-radius: 0px;
				border-right: 2px solid var(--v-gray_30-base);
				border-left: 2px solid var(--v-gray_30-base);
			}
		}
		&#teams-monthly {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			border-right: 2px solid var(--v-gray_30-base);
			border-top: 2px solid var(--v-gray_30-base);
			border-bottom: 2px solid var(--v-gray_30-base);
			&.sm-and-down {
				padding-top: 32px;
				border-top-left-radius: 0px;
				border-top-right-radius: 0px;
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
				border-right: 2px solid var(--v-gray_30-base);
				border-left: 2px solid var(--v-gray_30-base);
				border-top: none;
				border-bottom: 2px solid var(--v-gray_30-base);
			}
		}

		div.v-list-item__content {
			letter-spacing: 0.2px;
		}
		button.v-btn {
			margin-top: 16px;
			font-size: 16px;
			flex-grow: 0;
			.v-btn__content {
				letter-spacing: 0.2px !important;
			}
		}
		.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
			background-color: var(--v-gray_50-base);
			color: white !important;
		}
	}
</style>
