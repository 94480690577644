<template>
	<div id="client-info-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">
				{{ $t('invoice.details.client-info-modal.heading') }}
			</div>

			<div class="h-outline mb-2">
				<div class="font-12 text-left mx-4 mt-4" v-html="$t('invoice.details.client-info-modal.subheading')"></div>
				<v-checkbox v-model="updateClientToo" dense :label="$t('invoice.details.client-info-modal.update-client')" />
			</div>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				:label="$t('client.client-name')"
				autocomplete="new-password"
				v-model="clientInfo.name"
				@keyup.enter="handleSave"
			/>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="clientInfo.address1"
				:label="$t('contact.mailing-address')"
				autocomplete="new-password"
				@keyup.enter="handleSave"
			/>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="clientInfo.address2"
				:label="$t('contact.address-contd')"
				autocomplete="new-password"
				@keyup.enter="handleSave"
			/>

			<div class="d-flex justify-space-between mt-n1">
				<div class="pr-1" style="flex: 0 0 50%">
					<v-text-field
						class="h-outline"
						persistent-placeholder
						hide-details
						v-model="clientInfo.city"
						:label="$t('contact.city')"
						autocomplete="new-password"
						@keyup.enter="handleSave"
					/>
				</div>
				<div class="pl-1" style="flex: 0 0 50%">
					<v-autocomplete
						v-if="states.length"
						v-model="clientInfo.locality"
						:label="$t('contact.locality')"
						autocomplete="new-password"
						class="h-outline"
						hide-details
						persistent-placeholder
						:items="states"
						item-value="abbreviation"
						item-text="name"
					/>
					<v-text-field
						v-else
						v-model="clientInfo.locality"
						:label="$t('contact.locality')"
						autocomplete="new-password"
						hide-details
						class="h-outline"
						persistent-placeholder
						@keyup.enter="handleSave"
					/>
				</div>
			</div>
			<div class="d-flex justify-space-between mt-n1">
				<div class="pr-1" style="flex: 0 0 50%">
					<v-text-field
						class="h-outline"
						persistent-placeholder
						hide-details
						v-model="clientInfo.postal"
						:label="$t('contact.postal')"
						autocomplete="new-password"
						@keyup.enter="handleSave"
					></v-text-field>
				</div>
				<div class="pl-1" style="flex: 0 0 50%">
					<v-autocomplete
						v-model="clientInfo.country"
						:label="$t('contact.country')"
						autocomplete="new-password"
						class="h-outline"
						hide-details
						persistent-placeholder
						:items="countries.getCountriesSimple()"
						item-value="code"
						item-text="name"
					/>
				</div>
			</div>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="clientInfo.taxId"
				:label="$t('account.tax-id')"
				autocomplete="new-password"
				@keyup.enter="handleSave"
			></v-text-field>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="clientInfo.phone"
				:label="$t('global.phone')"
				autocomplete="new-password"
				@keyup.enter="handleSave"
			></v-text-field>
		</div>
		<div class="modal-footer">
			<v-btn class="px-16 primary-action" @click="handleSave">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import ClientService from '@/modules/clients/ClientService';
	import jsonPatch from 'fast-json-patch';
	import Countries from '@/modules/utils/Countries.js';

	// import AccountService from '@/modules/account/AccountService';

	export default {
		name: 'ClientInfoModal',

		props: { clientInfo: { type: Object, required: true }, clientId: { type: String, required: false } },

		components: {},

		data: function () {
			return {
				clientService: new ClientService(),
				countries: new Countries(),

				observer: null,
				client: null, // populate client from db for patch if needed
				updateClientToo: false,
			};
		},

		mounted() {
			if (this.clientId) this.getClient();
			// console.log('clientInfoModal mounted', this.clientId);
			// console.log('clientInfoModal mounted', this.account);
		},

		beforeDestroy() {},

		methods: {
			getClient() {
				this.clientService
					.getClientDetail(this.clientId)
					.then((res) => {
						this.client = res.data;
						this.observer = jsonPatch.observe(this.client);
						console.log('getClient', this.client);
					})
					.catch((err) => {
						console.log(err);
					});
			},
			updateClient() {
				if (!this.clientId) return;
				this.client = Object.assign(this.client, this.clientInfo);

				const patch = jsonPatch.generate(this.observer, true);

				if (patch.length > 0) {
					this.clientService
						.patchClient(this.clientId, patch)
						.then(() => {
							// this.client = res.data;
							// this.observer = jsonPatch.observe(this.client);
							this.$store.commit('success', 'Client settings saved.');
						})
						.catch((err) => {
							this.alert('error', err.response.data.message);
						})
						.finally(() => this.$emit('close'));
				} else {
					this.$emit('close');
				}
			},

			handleSave() {
				if (this.updateClientToo) {
					this.updateClient();
				} else {
					this.clientInfo.customInfo = true;
					this.$emit('close');
				}
			},
		},
		computed: {
			account() {
				return this.$store.getters.getAccount;
			},
			states: function () {
				if (this.clientInfo.country) {
					let country = this.countries.getCountries().find((c) => c.code === this.clientInfo.country);
					if (country) {
						return country.states;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},
		},
	};
</script>

<style lang="scss">
	#client-info-modal {
		max-width: 450px;
		background-color: var(--v-white-base);
		//.logo-wrapper {
		//	position: relative;
		//	display: flex;
		//	align-items: center;
		//	justify-content: center;
		//	.delete-wrapper {
		//		position: absolute;
		//		top: 0;
		//		left: 0;
		//		visibility: hidden;
		//		display: flex;
		//		align-items: center;
		//		justify-content: center;
		//		width: 100%;
		//		height: 100%;
		//		background-color: var(--v-white-base);
		//		opacity: 0.8;
		//	}
		//	&:hover {
		//		.delete-wrapper {
		//			visibility: visible;
		//			.v-btn {
		//			}
		//		}
		//	}
		//}
	}
</style>
