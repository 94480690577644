var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fill-all pa-6" }, [
    _c(
      "div",
      { staticClass: "column-format flex-grow-1 centered" },
      [
        _c(
          "v-form",
          {
            ref: "UserInfoForm",
            model: {
              value: _vm.isValid,
              callback: function($$v) {
                _vm.isValid = $$v
              },
              expression: "isValid"
            }
          },
          [
            _c("div", { staticClass: "font-16 font-gray_80 mt-2 mb-6" }, [
              _vm._v(
                " Tell us a bit about your business so we can set up your new workspace. "
              )
            ]),
            _c("v-text-field", {
              staticClass: "h-outline",
              attrs: {
                "hide-details": "",
                "persistent-placeholder": "",
                label: "What is your business called?",
                placeholder: "E.g. Designs, Inc.",
                rules: [
                  function(v) {
                    return !!v || "Required"
                  }
                ]
              },
              model: {
                value: _vm.businessName,
                callback: function($$v) {
                  _vm.businessName = $$v
                },
                expression: "businessName"
              }
            }),
            _c("v-autocomplete", {
              staticClass: "h-outline",
              attrs: {
                "hide-details": "",
                "persistent-placeholder": "",
                label: "Where are you based?",
                items: _vm.countriesSimple,
                "item-text": "name",
                "item-value": "code",
                rules: [
                  function(v) {
                    return !!v || "Required"
                  }
                ]
              },
              model: {
                value: _vm.country,
                callback: function($$v) {
                  _vm.country = $$v
                },
                expression: "country"
              }
            }),
            _c("v-autocomplete", {
              staticClass: "h-outline",
              attrs: {
                "hide-details": "",
                "persistent-placeholder": "",
                label: "What is your home currency?",
                items: _vm.currencies,
                "item-text": "label",
                "item-value": "value",
                rules: [
                  function(v) {
                    return !!v || "Required"
                  }
                ]
              },
              model: {
                value: _vm.currency,
                callback: function($$v) {
                  _vm.currency = $$v
                },
                expression: "currency"
              }
            }),
            _c(
              "div",
              { staticClass: "row-format centered mt-6 gap-2" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    on: {
                      click: function($event) {
                        return _vm.$emit("result")
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action",
                    attrs: { width: "250", disabled: !_vm.isValid },
                    on: {
                      click: function($event) {
                        return _vm.next()
                      }
                    }
                  },
                  [_vm._v("Continue")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }