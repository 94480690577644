var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [_vm._v("Columns")]),
      _c("v-slider", {
        attrs: {
          min: 1,
          max: 3,
          step: 1,
          "hide-details": "",
          dense: "",
          "show-ticks": "always",
          "tick-size": "4",
          "tick-labels": ["1", "2", "3", "4"],
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.columnCount,
          callback: function($$v) {
            _vm.$set(_vm.element, "columnCount", $$v)
          },
          expression: "element.columnCount"
        }
      }),
      _c(
        "div",
        { staticClass: "mt-4 column-format gap-4 text-left" },
        _vm._l(_vm.element.columnCount, function(index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "px-4 pt-2",
              staticStyle: {
                border: "1px solid var(--v-gray_30-base)",
                "border-radius": "4px"
              }
            },
            [
              _c("span", { staticClass: "font-14 brand-medium" }, [
                _vm._v("Column " + _vm._s(index))
              ]),
              _c(
                "div",
                { staticClass: "row-format gap-1 mb-n3" },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      min: "0",
                      max: "20",
                      label: "Padding left"
                    },
                    model: {
                      value: _vm.element.columns[index - 1].pl,
                      callback: function($$v) {
                        _vm.$set(_vm.element.columns[index - 1], "pl", $$v)
                      },
                      expression: "element.columns[index-1].pl"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      min: "0",
                      max: "20",
                      label: "Padding right"
                    },
                    model: {
                      value: _vm.element.columns[index - 1].pr,
                      callback: function($$v) {
                        _vm.$set(_vm.element.columns[index - 1], "pr", $$v)
                      },
                      expression: "element.columns[index-1].pr"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row-format gap-1 mb-n2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      min: "0",
                      max: "20",
                      label: "Padding top"
                    },
                    model: {
                      value: _vm.element.columns[index - 1].pt,
                      callback: function($$v) {
                        _vm.$set(_vm.element.columns[index - 1], "pt", $$v)
                      },
                      expression: "element.columns[index-1].pt"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      min: "0",
                      max: "20",
                      label: "Padding bottom"
                    },
                    model: {
                      value: _vm.element.columns[index - 1].pb,
                      callback: function($$v) {
                        _vm.$set(_vm.element.columns[index - 1], "pb", $$v)
                      },
                      expression: "element.columns[index-1].pb"
                    }
                  })
                ],
                1
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }