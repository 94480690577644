var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format",
      staticStyle: { width: "100%", height: "100%" }
    },
    [
      _vm.section.videos.length
        ? _c("div", { staticClass: "mx-2 video-container pa-2 mb-6" }, [
            _c(
              "div",
              {
                staticClass:
                  "font-14 brand-medium text-left mb-2 mt-0 font-gray_80"
              },
              [_vm._v("Video tutorials")]
            ),
            _c(
              "div",
              {
                staticClass: "row-format show-scrollbar pb-2",
                staticStyle: { "max-width": "100%", "overflow-x": "scroll" }
              },
              _vm._l(_vm.section.videos, function(video, index) {
                return _c(
                  "div",
                  { key: video.videoId, staticClass: "featured-video mr-4" },
                  [
                    _c(
                      "v-img",
                      {
                        staticClass: "pointer",
                        attrs: {
                          src:
                            "https://img.youtube.com/vi/" +
                            video.videoId +
                            "/mqdefault.jpg"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openVideo(index)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row-format centered",
                            staticStyle: { width: "100%", height: "100%" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "play-button" },
                              [
                                _c("v-icon", { staticClass: "ml-1" }, [
                                  _vm._v("$play")
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "font-12 brand-medium text-left mt-1" },
                      [_vm._v(_vm._s(video.title))]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "text-left mx-2" },
        [
          _c(
            "div",
            { staticClass: "font-14 brand-medium font-gray_80 pl-1 mb-2" },
            [_vm._v("Suggested articles")]
          ),
          _vm._l(_vm.section.articles, function(article) {
            return _c(
              "div",
              {
                key: article.link,
                staticClass: "article",
                on: {
                  click: function($event) {
                    return _vm.openArticle(article.link)
                  }
                }
              },
              [_c("div", [_vm._v(_vm._s(article.title))])]
            )
          })
        ],
        2
      ),
      _vm.videoOpen
        ? _c(
            "v-dialog",
            {
              staticStyle: { "background-color": "var(--v-white-base)" },
              attrs: { width: "unset" },
              model: {
                value: _vm.videoOpen,
                callback: function($$v) {
                  _vm.videoOpen = $$v
                },
                expression: "videoOpen"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "pa-4",
                  staticStyle: { "background-color": "var(--v-white-base)" }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "var(--v-black-base)",
                        padding: "4px 4px 0px 4px"
                      }
                    },
                    [
                      _c("youtube", {
                        attrs: {
                          "player-vars": { autoplay: 1 },
                          id: "player",
                          "video-id": _vm.videoId,
                          "player-width": 950,
                          "player-height": 700
                        }
                      })
                    ],
                    1
                  ),
                  _vm.previous || _vm.next
                    ? _c(
                        "div",
                        {
                          staticClass: "row-format centered pt-4",
                          staticStyle: {
                            gap: "12px",
                            "border-top": "1px solid var(--v-gray_50-base)"
                          }
                        },
                        [
                          _vm.previous
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { elevation: "0", color: "gray_30" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.previousVideo()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "20", color: "gray_80" } },
                                    [_vm._v("$arrowLeft")]
                                  ),
                                  _vm._v(
                                    " Previous: " + _vm._s(_vm.previous.title)
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.next
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { elevation: "0", color: "gray_30" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.nextVideo()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "Next: " + _vm._s(_vm.next.title) + " "
                                  ),
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "20", color: "gray_80" } },
                                    [_vm._v("$arrowRight")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }