<template>
	<div id="new-project">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<v-form ref="form" v-model="isValid" @submit.prevent>
			<div class="modal-padding">
				<div class="modal-title">
					New project<span v-if="ourClient"> for {{ ourClient.name }}</span>
				</div>
				<div v-if="!client" class="pb-3">
					<select-client v-model="selectedClientId" :show-avatar="true" :hide-archive="true" :dense="true"></select-client>
				</div>
				<div class="column-format gap-3" v-if="!assistantMode" style="min-width: 450px">
					<v-text-field
						v-if="!proposalId"
						hide-details
						dense
						outlined
						persistent-placeholder
						:rules="nameRules"
						:label="$t('projects.project-name')"
						:placeholder="$t('projects.project-name-placeholder')"
						v-model="projectName"
						autofocus
					></v-text-field>

					<div v-else class="font-14 text-left">
						Please confirm you would like to create projects from this executed agreement.
					</div>

					<v-autocomplete
						v-if="isPaid && packageList.length && !proposalId"
						hide-details
						persistent-placeholder
						dense
						outlined
						:label="$t('templates.start-from-template')"
						:items="packageList"
						item-text="name"
						clearable
						item-value="id"
						v-model="deliverablePackageId"
						@change="handleTemplateChange"
					>
					</v-autocomplete>
					<date-selector
							v-if="deliverablePackageId"
							:standard="true"
							:date="startDate"
							@change="startDate = $event"
							label="Start date"
					></date-selector>

					<div v-if="isPaid && packageList.length && !proposalId" class="font-14">- Or -</div>
					<v-btn outlined block color="gray_50" style="min-height: 42px" v-tippy content="Create a new project using the AI project assistant." @click="startAiSession()"><span style="color: var(--v-black-base)">Project ✨Assistant</span></v-btn>
				</div>
				<div v-else style="max-width: 700px; min-width:700px">
					<ai-chat-core
							max-height="calc(100vh - 350px)"
							ref="ChatCore"
							:context="initialAiContext"
							:initial-prompt="initialAiPrompt"
							:finalization-prompt="finalizationPrompt"
							use-case="ProjectCreation"
							model="gpt-4o"
							result-format="json"
							@result="aiResult($event)"
							@processing="setProcessing($event)"
					></ai-chat-core>
				</div>
			</div>
			<div class="modal-footer">
				<v-btn class="secondary-action mr-1" width="130" @click="handleClose()">
					<span class="font-gray_70">{{ $t('global.cancel') }}</span>
				</v-btn>
				<v-btn
					class="super-action ml-1"
					width="130"
					@click="createProject()"
					:disabled="(!isValid || !selectedClientId || processing)"
				>
					{{ $t('global.create') }}
				</v-btn>
			</div>
		</v-form>

	</div>
</template>

<script>
	import ProjectService from './ProjectService';
	import ClientService from '../clients/ClientService';
	import SelectClient from '@/modules/clients/SelectClient';
	import TemplateService from '@/modules/templates/TemplateService';
	import AiAssistantService from '@/modules/ai/AiAssistantService';
	import {v4 as uuid} from "uuid";
	import simpleProject from './simple-project.json';
	import {DateTime} from 'luxon';
	import ProjectDeliverableService from "@/modules/projects/deliverable/ProjectDeliverableService";
	import AiChatCore from "@/modules/ai/AiChatCore";
	import DateSelector from "@/components/DateSelector";

	export default {
		name: 'NewProject',

		components: {DateSelector, SelectClient, AiChatCore },

		props: {
			openState: { type: Boolean },
			client: { type: Object, required: false, default: null },
			proposalId: { type: String },
		},

		data: function() {
			return {
				assistantMode: false,
				aiSession: null,
				disabled: false,
				newMessage: null,
				aiSessionReady: false,

				projectService: new ProjectService(),
				clientService: new ClientService(),
				templateService: new TemplateService(),
				aiAssistantService: new AiAssistantService(),
				deliverableService: new ProjectDeliverableService(),
				projectName: null,
				processing: false,
				ourClient: null,
				isValid: false,
				startDate: null,

				nameRules: [(v) => (v && v.trim().length >= 1) || 'Name is required'],
				selectedClientId: null,
				packageList: [],
				deliverablePackageId: null,

				initialAiContext: [
					'You are helping to build out a project definition that will get added to a project management system.  You cannot do anything else other than help to build out this project.',
					'You will ask follow up clarifying questions until you have enough information to suggest the following fields: project name, fee and fee structure, project description, tasks, sub tasks, and due dates for each task. ',
					'Once you have gathered enough context from the user, please output a human readable summary using clear sections and formatting. and prompt the user to click the "Create" button if they are happy with the summary.',
				],

				initialAiPrompt: 'Please tell me a bit about the project so I can help set everything up.  You can just start by telling me what type of work you are going to do for your client.',
				finalizationPrompt: `Please translate the project definition into JSON. If you are unable to fulfill this request, respond only with the word "NO".  Please respond with JSON only. Do not include any metadata, explanations, formatting. Return only the json data. Here is an example of the JSON format to return: ${JSON.stringify(simpleProject)}`


			};
		},

		mounted() {
			this.initialize();
		},

		methods: {
			initialize: function() {
				this.$refs.form.resetValidation();
				this.projectName = null;
				this.processing = false;

				if (this.client !== null) {
					this.selectedClientId = this.client.id;
					this.ourClient = this.client;
				} else if (!this.$validations.isEmpty(this.selectedClientId)) {
					this.getClient();
				}
				this.getPackageList();
			},

			handleClose: function() {
				this.$emit('close');
				this.$emit('result');
			},

			getClient() {
				this.clientService.getClientDetail(this.selectedClientId).then((res) => {
					this.ourClient = res.data;
				});
			},

			createProject: function() {
				if(this.assistantMode){
					this.$refs.ChatCore.finalize();
				}else {
					this.processing = true;
					if (this.deliverablePackageId) {
						this.createProjectFromTemplate();
					} else if (this.proposalId) {
						this.createProjectFromProposal();
					} else {
						this.createProjectBase();
					}
				}
			},

			createProjectBase: function() {
				this.$store.commit('startLoading');
				this.projectService
					.initializeNewProject(this.selectedClientId, this.projectName)
					.then((res) => {
						this.$emit('created', res.data);
						this.$emit('result', res.data);
						this.$onBoarding.track('projects_create_first');
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.processing = false;
						this.$store.commit('stopLoading');
					});
			},

			createProjectFromProposal: function() {
				this.$store.commit('startLoading');
				this.projectService
					.initializeNewProjectFromProposal(this.selectedClientId, this.projectName, this.proposalId)
					.then((res) => {
						this.$emit('created', res.data);
						this.$emit('result', res.data);
						this.$onBoarding.track('projects_create_first');
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.processing = false;
						this.$store.commit('stopLoading');
					});
			},

			createProjectFromTemplate: function() {
				this.$store.commit('startLoading');
				this.templateService
					.createProject(this.deliverablePackageId, this.selectedClientId, this.projectName, this.startDate)
					.then((res) => {
						console.log(res.data);
						this.$emit('created', res.data);
						this.$emit('result', res.data);
						this.$onBoarding.track('projects_create_first');
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.processing = false;
						this.$store.commit('stopLoading');
					});
			},

			getPackageList: function() {
				this.templateService.getDeliverablePackages().then((res) => {
					this.packageList = [];
					this.packageList.push(...res.data);
					// this.clientList.sortByClientName(this.sortByName);
				});
			},

			handleTemplateChange: function() {
				if (this.deliverablePackageId) {
					let pkg = this.packageList.find((p) => {
						return p.id === this.deliverablePackageId;
					});

					if (!this.projectName) {
						this.projectName = pkg.name;
					}
				}
			},

			setProcessing: function(processing){
				this.processing = processing;
			},

			startAiSession(){
				this.assistantMode = true;
			},

			async aiResult(result){
				if(result){
					await this.buildProjectFromJson(result);
				}else{
					this.handleClose();
				}
			},

			async buildProjectFromJson(json){
				this.$store.commit('startLoading');
				this.$store.commit('success','Creating project.')

				let aiProject = JSON.parse(json);
				let result = await this.projectService.initializeNewProject(this.selectedClientId,aiProject.projectName);
				let project = result.data;

				project.startDate = this.validateDate(aiProject.startDate);
				project.dueDate = this.validateDate(aiProject.endDate);
				project.description = aiProject.description;
				this.setFeeSchedule(aiProject,project);

				result = await this.projectService.updateProject(project.id,project);
				project = result.data;

				let assignedToList = [];

				if (this.$store.state.usersForAccount.length === 1) {
					assignedToList.push(this.$store.getters.getLoggedInUserId);
				}

				if(aiProject.tasks) {
					this.$store.commit('success','Creating project tasks.')
					for (let i = 0; i < aiProject.tasks.length; i++) {
						let aiTask = aiProject.tasks[i];
						let deliverable = {
							clientId: project.clientId,
							projectId: project.id,
							name: aiTask.taskName,
							description: aiTask.description,
							statusId: this.$store.state.deliverableStatusList.statusList[0].id,
							dueDate: this.validateDate(aiTask.dueDate),
							assignedToList: assignedToList,
							tasks: []
						}
						aiTask.subTasks.forEach(s => {
							deliverable.tasks.push({
								id: uuid(),
								description: s
							})
						})
						await this.deliverableService.createNewDeliverable(deliverable);
					}
				}
				this.$store.commit('stopLoading');
				this.$store.commit('success','Your project has been created!')
				this.$emit('created', project);
				this.$emit('result', project);
			},

			setFeeSchedule(aiProject,project){
				let rate = parseFloat(aiProject.rate);

				if(isNaN(rate)){
					return;
				}

				project.feeSchedule.amount = rate;

				switch(aiProject.feeType){
					case "HOURLY":{project.feeSchedule.feeType = "Hourly"; break;}
					case "FIXED":{project.feeSchedule.feeType = "Fixed Price";break;}
					case "RECURRING":{project.feeSchedule.feeType = "Retainer";break;}
				}
			},

			validateDate: function(dateString){
				let date = DateTime.fromISO(dateString);
				if (date.isValid) {
					return date.toISODate();
				}else{
					return null;
				}
			},
		},

		computed: {
			isPaid: function() {
				return this.$store.getters.isPaidAccount;
			},
		},

		watch: {
			openState: function(newVal) {
				if (newVal === true) {
					this.initialize();
				}
			},

			selectedClientId: function(newVal) {
				if (newVal) {
					this.getClient();
				}
			},
		},
	};
</script>

<style lang="scss">
	#new-project {
		background-color: var(--v-white-base);
	}
</style>
