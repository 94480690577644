var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.currentRecordingUrl
      ? _c("div", { staticClass: "my-2" }, [
          _c("audio", {
            staticStyle: { width: "300px" },
            attrs: { controls: "", src: _vm.currentRecordingUrl }
          })
        ])
      : _vm._e(),
    _c("div", [
      !_vm.audio
        ? _c(
            "div",
            { staticClass: "row-format" },
            [
              !_vm.recorder
                ? _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      on: {
                        click: function($event) {
                          return _vm.startRecording()
                        }
                      }
                    },
                    [_vm._v("Record voicemail greeting")]
                  )
                : _vm._e(),
              _vm.recorder
                ? _c(
                    "div",
                    { staticClass: "row-format align-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2 primary-action",
                          on: {
                            click: function($event) {
                              return _vm.stopRecording()
                            }
                          }
                        },
                        [_vm._v("Done")]
                      ),
                      _c("v-icon", { attrs: { color: "red" } }, [
                        _vm._v("record_voice_over")
                      ]),
                      _c("div", { staticClass: "ml-1 font-red" }, [
                        _vm._v("Recording - " + _vm._s(_vm.timer) + "s")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.audio
        ? _c(
            "div",
            { staticClass: "row-format" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary-action mr-2",
                  on: {
                    click: function($event) {
                      return _vm.playRecording()
                    }
                  }
                },
                [_vm._v("Review Recording")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-2",
                  on: {
                    click: function($event) {
                      return _vm.saveRecording()
                    }
                  }
                },
                [_vm._v("Save Recording")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action",
                  on: {
                    click: function($event) {
                      return _vm.discardRecording()
                    }
                  }
                },
                [_vm._v("Discard")]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }