var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-input" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c("label", {
            staticClass: "question",
            domProps: { innerHTML: _vm._s(_vm.htmlText) }
          }),
          _c("v-file-input", {
            staticClass: "file-upload",
            attrs: {
              disabled: _vm.previewMode,
              "hide-details": "",
              dense: "",
              outlined: "",
              multiple: "",
              "append-icon": "attach_file"
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var text = ref.text
                  return [
                    _c(
                      "v-chip",
                      { attrs: { small: "", label: "", color: "primary" } },
                      [_vm._v(" " + _vm._s(text) + " ")]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.files,
              callback: function($$v) {
                _vm.files = $$v
              },
              expression: "files"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }