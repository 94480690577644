var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.branding.favicon
        ? _c(
            "vue-dropzone",
            {
              ref: "fileDropzone",
              staticClass: "ma-0",
              attrs: {
                id: "dropzone",
                "use-custom-slot": true,
                options: _vm.dropzoneOptions
              },
              on: {
                "vdropzone-success": _vm.uploadSuccess,
                "vdropzone-error": _vm.uploadFailure,
                "vdropzone-sending": _vm.addFileUploadAuthHeader
              }
            },
            [
              _c(
                "div",
                { staticClass: "row-format centered" },
                [
                  _c("v-icon", [_vm._v("$vuetify.icons.upload")]),
                  _c("br"),
                  _c(
                    "span",
                    {
                      staticClass: "ml-2",
                      staticStyle: { "font-size": "0.8em" }
                    },
                    [_vm._v(_vm._s(_vm.$t("account.favicon-upload")))]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.branding.favicon
        ? _c(
            "div",
            {
              staticClass: "accountLogo row-format align-center",
              staticStyle: { position: "relative" }
            },
            [
              _c("v-img", {
                staticStyle: { "max-width": "25px" },
                attrs: { src: _vm.branding.favicon }
              }),
              _c(
                "v-icon",
                {
                  staticClass: "delete logoDelete material-symbols-rounded",
                  attrs: { size: "20" },
                  on: {
                    click: function($event) {
                      return _vm.deleteFavicon()
                    }
                  }
                },
                [_vm._v("delete")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }