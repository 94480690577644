var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.first,
            expression: "first"
          }
        ],
        ref: "first",
        attrs: { type: "hidden", "df-first": "" },
        domProps: { value: _vm.first },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.first = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.last,
            expression: "last"
          }
        ],
        ref: "last",
        attrs: { type: "hidden", "df-last": "" },
        domProps: { value: _vm.last },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.last = $event.target.value
          }
        }
      }),
      _vm._v(" Hello " + _vm._s(_vm.first) + " " + _vm._s(_vm.last) + " "),
      _c("v-btn", { on: { click: _vm.changeName } }, [_vm._v("Change")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }