import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class SubscriptionService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(store.getters.getSubscriptionBaseUrl(true));
		this.httpClientNoAccount = new HttpClient(store.getters.getSubscriptionBaseUrl(false));
	}

	getPlans(pricingVersion = 1) {
		return this.httpClientNoAccount
			.get(`/subscriptions?pricingVersion=${pricingVersion}`)
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	getCurrent() {
		return this.httpClient
			.get('/subscription/current')
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	getBillingInfo() {
		return this.httpClient
			.get('/subscription/billingInfo')
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	getCouponRedemptions() {
		return this.httpClient
			.get('/subscription/coupons')
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	putBillingInfo(token,threeDS, couponCode = null) {
		const request = { token: token, threeDS: threeDS, couponCode: couponCode };

		return this.httpClient
			.put('/subscription/billingInfo', request)
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	cancelSubscription(){
		return this.httpClient
			.post('/subscription/cancel')
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	reactivateSubscription(){
		return this.httpClient
			.post('/subscription/reactivate')
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	getChange() {
		return this.httpClient
			.get('/subscription/change')
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	postChange(planCode) {
		const request = { planCode: planCode };
		return this.httpClient
			.post('/subscription/change', request)
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	deleteChange() {
		return this.httpClient
			.delete('/subscription/change')
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	getInvoices() {
		return this.httpClient
			.get('/subscription/invoices')
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}

	downgradeFeedback(reason, comments, improve) {
		const request = { reason: reason, comments: comments, improve: improve };
		return this.httpClient
			.post('/subscription/downgradeFeedback', request)
			.then((res) => Promise.resolve(res.data))
			.catch((err) => Promise.reject(err));
	}
}
