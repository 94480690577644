var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "notification-layout",
    {
      attrs: {
        id: _vm.id,
        "action-text": "" + _vm.$t("notifications.view-ticket"),
        "sent-at": _vm.sentAt
      },
      on: {
        action: function($event) {
          return _vm.navigateToTicket()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _vm.client
            ? _c("client-avatar", {
                attrs: { "x-small": "", client: _vm.client }
              })
            : _vm._e(),
          _c("div", { staticClass: "ml-2" }, [
            _vm._v(_vm._s(_vm.client ? _vm.client.name : "[Unknown]"))
          ])
        ],
        1
      ),
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "font-14 mb-1 brand-medium" }, [
          _vm.ticket.ticketNumber
            ? _c("span", [_vm._v(_vm._s(_vm.ticket.ticketNumber) + ": ")])
            : _vm._e(),
          _vm._v(_vm._s(_vm.ticket.subject))
        ]),
        _c("div", { staticClass: "font-12" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm.comment.commentUserName) +
                ': "' +
                _vm._s(_vm.textTruncate(_vm.comment.comment)) +
                '" ' +
                _vm._s(_vm._f("formatForTimeAgo")(_vm.comment.created)) +
                " "
            )
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }