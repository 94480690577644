<template>
	<div id="discovery-share-send-widget">
		<v-btn icon class="close-dialog" @click="cancel()">
			<v-icon>$close</v-icon>
		</v-btn>

		<div v-if="mode === 'Select'">
			<div class="modal-padding">
				<div class="modal-title">{{ $t('discovery.share.select-method') }}</div>

				<div class="selector-tile" @click="mode = 'Send'">
					<div><v-icon>$email</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('discovery.share.send-title') }}</h3>
						<div class="modal-subtitle">{{ $t('discovery.share.send-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('discovery.share.send-next') }}</div>
					</div>
				</div>
				<div class="selector-tile" @click="shareViaLink">
					<div><v-icon>$share</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('discovery.share.share-title') }}</h3>
						<div class="modal-subtitle">{{ $t('discovery.share.share-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('discovery.share.share-next') }}</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="mode === 'Share'">
			<div class="modal-padding">
				<div class="modal-title">{{ $t('discovery.share.share-title') }}</div>

				<div class="d-flex align-center justify-space-between my-5">
					<div style="flex: 1 1 auto" class="mr-1">
						<input type="text" :value="shareableLink" class="link-box" id="linkBox" readonly />
					</div>
					<div style="flex: 0 1 auto" class="ml-1">
						<v-btn class="super-action" @click="copyLink()">{{ $t('discovery.share.copy-link') }}</v-btn>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<v-btn class="primary-action" style="width: 140px" @click="cancel()">
					{{ $t('global.done') }}
				</v-btn>
			</div>
		</div>

		<div class="pa-5" v-if="mode === 'Send'">
			<h2 class="modal-title">{{ $t('proposal.share.send-title') }}</h2>
			<v-container fluid class="px-0 mt-2">
				<v-row class="rowFormat">
					<v-col cols="12">
						<span class="emailHeader">{{ $t('global.email-from') }}: </span>
						<span>{{ $store.getters.getFromEmail }}</span>
					</v-col>
				</v-row>
				<v-divider class="mt-1 mb-2"></v-divider>
				<v-row class="rowFormat">
					<v-col cols="11">
						<div style="display: flex; flex-direction: row; flex-wrap: wrap;" @click="toMenu = true">
							<span class="emailHeader">{{ $t('global.email-to') }}:</span>
							<span style="cursor: pointer;">{{ formatEmail(toEmail) }}</span>
						</div>
					</v-col>
					<v-col cols="1" align="right">
						<v-menu v-model="toMenu" :close-on-content-click="false" min-width="570" nudge-left="530" nudge-top="12">
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon small rounded v-bind="attrs" v-on="on">
									<v-icon small>$chevronDown</v-icon>
								</v-btn>
							</template>
							<div style="background-color: var(--v-white-base);">
								<v-container v-if="toEmailMode === 'selectContact'">
									<v-row>
										<v-col cols="10" class="pl-5" align="left">
											<v-radio-group v-model="toEmail" @change="toMenu = false">
												<v-radio
													v-for="contact in contacts"
													:key="contact.email"
													:hide-details="true"
													:label="formatEmail(contact)"
													:value="contact"
												>
												</v-radio>
											</v-radio-group>
											<v-btn
												text
												large
												color="primary"
												class="pl-1 mt-4"
												@click="toEmailMode = 'createContact'"
												><v-icon small class="mr-2">$plus</v-icon> Add new contact</v-btn
											>
										</v-col>
										<v-col cols="2" align="right">
											<v-btn icon rounded @click="closeToMenu()">
												<v-icon small>$chevronUp</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</v-container>
								<v-container v-if="toEmailMode === 'createContact'">
									<v-row>
										<v-col cols="10">
											<h3 class="ml-12">Create new contact</h3>
										</v-col>
										<v-col cols="2" align="right">
											<v-btn icon rounded @click="closeToMenu()">
												<v-icon small>$chevronUp</v-icon>
											</v-btn>
										</v-col>
									</v-row>
									<v-row class="rowFormat px-6">
										<v-col cols="6"
											><v-text-field
												v-model="newContact.firstName"
												:hide-details="true"
												label="First name"
											></v-text-field
										></v-col>
										<v-col cols="6"
											><v-text-field
												v-model="newContact.lastName"
												:hide-details="true"
												label="Last name"
											></v-text-field
										></v-col>
									</v-row>
									<v-row class="rowFormat px-6">
										<v-col cols="12"
											><v-text-field
												v-model="newContact.email"
												:hide-details="true"
												label="Email"
											></v-text-field
										></v-col>
									</v-row>
									<v-row>
										<v-col cols="12" align="right">
											<v-btn
												text
												large
												color="schooner_20"
												width="100"
												@click="cancelCreateNewContact()"
												:disabled="processingCreate"
												>Cancel</v-btn
											>
											<v-btn
												text
												large
												color="primary"
												width="150"
												@click="createNewContact()"
												:disabled="processingCreate"
												>Save</v-btn
											>
										</v-col>
									</v-row>
								</v-container>
							</div>
						</v-menu>
					</v-col>
				</v-row>
				<v-divider class="mt-1 mb-2"></v-divider>
				<v-row class="rowFormat">
					<v-col cols="12">
						<div style="display: flex; flex-direction: row;">
							<span class="emailHeader">{{ $t('global.email-subject') }}: </span>
							<input v-model="subject" type="text" style="outline: none; width: 100%; color: var(--v-black-base)" />
						</div>
					</v-col>
				</v-row>
				<v-divider class="mt-1 mb-2"></v-divider>
				<v-row class="rowFormat">
					<v-col cols="12">
						<editor
								v-if="initialEmailContent"
								ref="bodyEditor"
								:api-key="$store.getters.getTinyMceKey"
								:inline="false"
								:initial-value="initialEmailContent"
								:init="mceConfigText"
								:spellcheck="true"
						></editor>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div class="modal-footer row-format centered" v-if="mode === 'Send'">
			<v-btn class="secondary-action mr-1" width="120" @click="cancel()" :disabled="disabled">Cancel</v-btn>
			<send-email-button class="ml-1" :width="150" @click="sendDiscoveryRequest($event)" :disabled="disabled || !this.toEmail"></send-email-button>
		</div>
	</div>
</template>

<script>
	import ClientService from '../clients/ClientService';
	import DiscoveryService from './DiscoveryService';
	import EmailTemplateSelector from "@/modules/templates/emails/EmailTemplateSelector";
	import SendEmailButton from "@/components/SendEmailButton";
	import {DateTime} from "luxon";

	export default {
		name: 'ShareSendWidget',
		props: ['discoveryTemplate', 'clientId',  'openState'],

		components: {
			SendEmailButton,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function() {
			return {
				clientService: new ClientService(),
				discoveryService: new DiscoveryService(),
				disabled: false,
				toMenu: false,
				discoveryRequest: null,
				shareableLink: null,
				toEmail: null,
				contacts: [],
				toEmailMode: 'selectContact',
				emailLink: this.discoveryTemplate.name,
				subject: 'Tell me about your project needs - ' + this.$store.getters.getAccountName,
				initialEmailContent: null,
				processingCreate: false,
				mode: 'Select',

				newContact: {
					firstName: null,
					lastName: null,
					email: null,
				},

				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					paste_as_text: false,
					forced_root_block: false,
					paste_data_images: true,
					browser_spellcheck: true,
					plugins: ['paste', 'lists', 'link', 'table', 'code'],
					contextmenu: 'paste | link | table',
					branding: false,
					height: `400px`,
					width: '100%',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						`styleselect forecolor | bold italic underline | alignleft aligncenter alignright alignjustify',
						'table | bullist numlist | link unlink code | ${this.$store.getters.isPaidAccount ? 'template' : ''}`,
					],
					table_toolbar:
							'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('template', {
							text: 'Insert template',
							onAction: () => {
								this.selectEmailTemplate();
							},
						});
					},
				},
			};
		},

		mounted() {
			this.initialize();
		},

		methods: {
			selectEmailTemplate: function(){
				console.log(this.discoveryTemplate);
				let binding = {
					contacts: [this.toEmail],
					clientId: this.clientId,
					discoveryRequestId: this.discoveryTemplate.id,
				};
				this.$store.state.globalModalController.openModal(EmailTemplateSelector,binding).then((res) => {
					if(res){
						this.subject = res.subject;
						this.$refs.bodyEditor.editor.setContent('');
						this.$refs.bodyEditor.editor.execCommand('mceInsertContent', false, res.htmlContent);
						this.isTemplate = true;
					}
				});
			},

			initialize: function() {
				this.clientService.getClientDetail(this.clientId).then((res) => {
					this.client = res.data;
					this.contacts = this.client.contacts;

					if (this.contacts.length > 0) {
						this.toEmail = this.contacts[0];
					}

					this.initialEmailContent = `Hi ${this.client.name} Team,<br>Please tell me a little about your project so we know the best way to get started.<br><br>`;
					this.initialEmailContent = this.initialEmailContent + `<a href="${this.discoveryTemplate.url}" target="_blank">${this.discoveryTemplate.name}</a><br><br>`;
					this.initialEmailContent = this.initialEmailContent + `Thanks,<br>${this.$store.getters.getUserSignature}`;
				});
			},

			closeToMenu: function() {
				this.toMenu = false;
				this.toEmailMode = 'selectContact';
			},

			formatEmail: function(contact) {
				if (contact) {
					return contact.firstName + ' ' + contact.lastName + ' <' + contact.email + '>';
				} else {
					return '';
				}
			},

			cancel: function() {
				this.toEmail = null;
				this.disabled = false;
				this.$emit('cancel');
			},

			finalize: function() {
				this.disabled = false;
				this.toEmail = null;
				this.$emit('finalize');
			},

			shareViaLink: function() {
				this.disabled = false;
				this.shareableLink = this.discoveryTemplate.url;
				this.mode = 'Share';
			},

			sendDiscoveryRequest: function(sendLater = null) {
				this.disabled = true;
				this.$store.commit('startLoading');

				let request = {
					contact: this.toEmail,
					subject: this.subject,
					htmlBody: this.$refs.bodyEditor.editor.getContent(),
				};

				this.discoveryService
					.sendDiscoveryRequest(this.clientId, this.discoveryTemplate.id, request, sendLater)
					.then(() => {
						if(sendLater){
							this.$store.commit('success', `Email successfully scheduled for ${DateTime.fromISO(sendLater).toLocaleString(DateTime.DATETIME_MED)}`);
						}else{
							this.$store.commit('success', this.$t('discovery.email-sent'));
						}
						this.$emit('discovery-sent');
						this.finalize();
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						this.disabled = false;
					})
					.finally(() => {
						this.$store.commit('stopLoading');
					});
			},

			copyLink: function() {
				document.getElementById('linkBox').select();
				document.execCommand('copy');
				this.$store.commit('success', this.$t('proposal.share.link-copied'));
				this.$emit('discovery-sent');
				this.finalize();
			},

			cancelCreateNewContact: function() {
				this.newContact = {
					firstName: null,
					lastName: null,
					email: null,
				};

				this.toEmailMode = 'selectContact';
			},

			createNewContact: function() {
				this.processingCreate = true;
				let opList = [];
				let operation = {};

				operation.op = 'add';
				operation.path = '/contacts/-';
				operation.value = this.newContact;

				opList.push(operation);

				this.clientService
					.patchClient(this.client.id, opList)
					.then((res) => {
						this.contacts = res.data.contacts;

						for (let i = 0; i < this.contacts.length; i++) {
							if (this.contacts[i].email === this.newContact.email) {
								this.toEmail = this.contacts[i];
								break;
							}
						}

						this.toMenu = false;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.processingCreate = false;
						this.cancelCreateNewContact();
					});
			},

			alert: function(type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},
		},

		watch: {
			openState: function() {
				this.initialize();
			},
		},
	};
</script>

<style lang="scss">
	#discovery-share-send-widget {
		width: 820px;
	}
</style>

<style scoped lang="scss">
	//.emailPill {
	//	background-color: var(--v-blue_5-base);
	//	border-radius: 8px;
	//	color: var(--v-blue_90-base);
	//}

	.selector-tile {
		cursor: pointer;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 16px 8px 12px;
		margin: 8px 0;

		&:hover {
			background-color: var(--v-gray_20-base);
			border-color: var(--v-gray_20-base);
			svg {
				--icon-color: var(--v-primary-base);
				color: var(--v-primary-base);
			}
		}
		> div:first-child {
			padding-right: 12px;
			padding-left: 4px;
		}
		> div:last-child {
			text-align: left;
		}
	}

	.link-box {
		//width: 500px;
		border-radius: 4px;
		background-color: var(--v-gray_20-base);
		height: 32px;
		width: 100%;
		padding-left: 8px;
		//padding: 15px;
	}

	.rowFormat {
		text-align: left;
		padding: 12px 0;
		//border-bottom: 1px solid var(--v-gray_30-base);
	}

	.linkBox {
		width: 500px;
		border-radius: 8px;
		background-color: var(--v-gray_30-base);
		padding: 15px;
	}

	.v-menu__content {
		border-radius: 0px;
		border: 1px solid var(--v-gray_30-base);
	}

	.emailHeader {
		margin-right: 10px;
		color: var(--v-gray_70-base);
	}
</style>
