var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-format" }, [
    _c("div", { staticClass: "column-format gap-1 pb-4" }, [
      _c("div", { staticClass: "brand-medium pb-2" }, [
        _vm._v("Work to be done")
      ]),
      _c(
        "div",
        { staticClass: "row-format gap-2" },
        [
          _c("v-select", {
            staticStyle: {
              "max-width": "140px",
              "background-color": "var(--v-white-base)"
            },
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              items: _vm.types,
              "item-text": "label",
              "item-value": "value",
              label: "Type"
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", { staticClass: "font-12" }, [
                      _vm._v(_vm._s(item.label))
                    ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.type,
              callback: function($$v) {
                _vm.type = $$v
              },
              expression: "type"
            }
          }),
          _c("v-select", {
            staticStyle: {
              "max-width": "90px",
              "background-color": "var(--v-white-base)"
            },
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              items: ["All", "Mine"],
              label: "Assigned"
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", { staticClass: "font-12" }, [
                      _vm._v(_vm._s(item))
                    ])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.assignedTo,
              callback: function($$v) {
                _vm.assignedTo = $$v
              },
              expression: "assignedTo"
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        staticClass: "column-format gap-4 font-14",
        staticStyle: { "padding-top": "1px" }
      },
      [
        _vm.filteredPastDue.length
          ? _c("work-bundle", {
              attrs: { items: _vm.filteredPastDue },
              on: {
                "deliverable-clicked": function($event) {
                  return _vm.handleDeliverableClick($event)
                },
                "ticket-clicked": function($event) {
                  return _vm.handleTicketClick($event)
                },
                "todo-clicked": function($event) {
                  return _vm.handleOpportunityClick($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "heading",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "brand-bold",
                            staticStyle: { color: "var(--v-error-base)" }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-symbols-rounded mb-1",
                                attrs: { size: "20", color: "error" }
                              },
                              [_vm._v("warning")]
                            ),
                            _vm._v(
                              " Past due (" +
                                _vm._s(_vm.filteredPastDue.length) +
                                ") "
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                904934646
              )
            })
          : _vm._e(),
        _c("work-bundle", {
          attrs: {
            items: _vm.filteredToday,
            "count-complete": _vm.countComplete(_vm.todayList),
            "show-complete": _vm.showCompletedToday
          },
          on: {
            "toggle-show-complete": function($event) {
              _vm.showCompletedToday = !_vm.showCompletedToday
            },
            "deliverable-clicked": function($event) {
              return _vm.handleDeliverableClick($event)
            },
            "ticket-clicked": function($event) {
              return _vm.handleTicketClick($event)
            },
            "todo-clicked": function($event) {
              return _vm.handleOpportunityClick($event)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "heading",
              fn: function() {
                return [
                  _c("div", { staticClass: "brand-medium" }, [
                    _vm._v(
                      _vm._s(_vm.$t("projects.due-today")) +
                        " (" +
                        _vm._s(_vm.filteredToday.length) +
                        ")"
                    )
                  ]),
                  _vm.filteredToday.length === 0
                    ? _c("div", { staticClass: "empty-yay" }, [
                        _vm._v(" 🌤 Nothing left to do today! ")
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        }),
        _c("work-bundle", {
          attrs: {
            items: _vm.filteredThisWeek,
            "count-complete": _vm.countComplete(_vm.thisWeekList),
            "show-complete": _vm.showCompletedThisWeek
          },
          on: {
            "toggle-show-complete": function($event) {
              _vm.showCompletedThisWeek = !_vm.showCompletedThisWeek
            },
            "deliverable-clicked": function($event) {
              return _vm.handleDeliverableClick($event)
            },
            "ticket-clicked": function($event) {
              return _vm.handleTicketClick($event)
            },
            "todo-clicked": function($event) {
              return _vm.handleOpportunityClick($event)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "heading",
              fn: function() {
                return [
                  _c("div", { staticClass: "brand-medium" }, [
                    _vm._v(
                      _vm._s(_vm.$t("projects.due-this")) +
                        " (" +
                        _vm._s(_vm.filteredThisWeek.length) +
                        ")"
                    )
                  ]),
                  _vm.filteredThisWeek.length === 0
                    ? _c("div", { staticClass: "empty-yay" }, [
                        _vm._v(" 😎 You seem to be all done for the week! ")
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        }),
        _c("work-bundle", {
          attrs: {
            items: _vm.filteredNextWeek,
            "count-complete": _vm.countComplete(_vm.nextWeekList),
            "show-complete": _vm.showCompletedNextWeek,
            collapsed: true
          },
          on: {
            "toggle-show-complete": function($event) {
              _vm.showCompletedNextWeek = !_vm.showCompletedNextWeek
            },
            "deliverable-clicked": function($event) {
              return _vm.handleDeliverableClick($event)
            },
            "ticket-clicked": function($event) {
              return _vm.handleTicketClick($event)
            },
            "todo-clicked": function($event) {
              return _vm.handleOpportunityClick($event)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "heading",
              fn: function() {
                return [
                  _c("div", { staticClass: "brand-medium" }, [
                    _vm._v(
                      _vm._s(_vm.$t("projects.due-next")) +
                        " (" +
                        _vm._s(_vm.filteredNextWeek.length) +
                        ")"
                    )
                  ]),
                  _vm.filteredNextWeek.length === 0
                    ? _c("div", { staticClass: "empty-yay" }, [
                        _vm._v(" 🚀 Nothing on the horizon... ")
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }