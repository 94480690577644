var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "solid-hr", attrs: { cols: "12", align: "left" } },
            [
              _c("div", {
                staticStyle: { "line-height": "1.8" },
                domProps: { innerHTML: _vm._s(_vm.tokenizedText) }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }