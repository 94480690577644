<template>
	<div class="section-wrapper row-format align-center" @click="$emit('selected')" @mouseover="hovering=true" @mouseout="hovering=false">
		<h-icon2 size="20" :name="icon" :active="hovering"></h-icon2>
		<div :class="`brand-medium ml-2 ${hovering ? 'font-primary' : 'font-gray_80'}`">{{ name }}</div>
		<div class="ml-auto arrow">
			<v-icon size="20">$arrowRight</v-icon>
		</div>
	</div>
</template>

<script>
	import HIcon2 from '@/components/HIcon2';
	export default {
		name: 'SectionLink',

		props: ['name', 'icon', 'color', 'description'],

		components: { HIcon2 },

		data: function () {
			return {
				hovering: false
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.section-wrapper{
		width: 100%;
		height: 48px;
		border-bottom: 1px solid var(--v-gray_50-base);
		padding: 12px 8px;
		cursor: pointer;

		.arrow {
			visibility: hidden;
		}

		&:hover {
			background-color: var(--v-gray_20-base);

			.arrow {
				visibility: visible;
			}
		}
	}
</style>
