var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "proposal-template-builder" } },
    [
      _vm.isReady
        ? _c("header-bar", {
            attrs: {
              "proposal-template": _vm.proposalTemplate,
              "need-save": _vm.needSave,
              status: _vm.status,
              saving: _vm.saving,
              folders: _vm.folders,
              "template-service": _vm.templateService
            },
            on: {
              "folder-change": function($event) {
                return _vm.handleFolderChange($event)
              },
              "name-change": function($event) {
                return _vm.handleNameChange($event)
              },
              close: function($event) {
                return _vm.save(true)
              },
              save: function($event) {
                return _vm.save(false)
              },
              delete: function($event) {
                return _vm.confirmDeleteTemplate()
              },
              "create-proposal": function($event) {
                return _vm.handleCreateProposal()
              },
              duplicate: function($event) {
                return _vm.handleDuplicate()
              }
            }
          })
        : _vm._e(),
      _vm.isReady
        ? _c(
            "div",
            { staticClass: "subheader-buttons" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary-action mr-1",
                  on: {
                    click: function($event) {
                      _vm.coverMenuOpen = true
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { size: "20" } }, [
                    _vm._v("$photo")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("proposal.header-bar.cover-button")) +
                      " "
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary-action ml-1",
                  on: {
                    click: function($event) {
                      _vm.textMenuOpen = true
                    }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { size: "20" } }, [
                    _vm._v("$atSymbol")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("proposal.header-bar.font-button")) +
                      " "
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", { staticClass: "my-4" }),
      _vm.isReady
        ? _c("proposal-renderer", {
            attrs: {
              proposal: _vm.proposalTemplate,
              "edit-mode": true,
              "template-mode": true,
              "template-service": _vm.templateService,
              "proposal-service": _vm.proposalService,
              account: _vm.proposalTemplate.accountMini,
              "need-save": _vm.needSave,
              saving: _vm.saving,
              "client-mode": false,
              "is-locked": _vm.isLocked,
              "refresh-key": _vm.refreshKey
            },
            on: {
              close: function($event) {
                return _vm.close()
              },
              "need-save": function($event) {
                return _vm.handleNeedSave()
              },
              "force-save": function($event) {
                return _vm.save()
              },
              "delete-proposal": function($event) {
                return _vm.confirmDeleteTemplate()
              },
              "cover-menu-open": function($event) {
                _vm.coverMenuOpen = true
              },
              "create-package-from-deliverables": function($event) {
                return _vm.createPackage($event)
              }
            }
          })
        : _vm._e(),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": _vm.$t("global.confirm"),
          "body-text": _vm.$t("templates.proposals.delete-confirm.body")
        },
        on: {
          confirm: function($event) {
            return _vm.deleteTemplate()
          },
          close: function($event) {
            return _vm.cancelDeleteTemplate()
          }
        }
      }),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.coverMenuOpen, persistent: true },
          on: {
            close: function($event) {
              _vm.coverMenuOpen = false
            }
          }
        },
        [
          !_vm.textMenuOpen
            ? _c("cover-modal", {
                attrs: {
                  "template-service": _vm.templateService,
                  "proposal-service": _vm.proposalService,
                  proposal: _vm.proposalTemplate,
                  "header-style": _vm.headerStyle,
                  "body-style": _vm.bodyStyle,
                  "client-name": _vm.clientName,
                  "contractor-name": _vm.contractorName,
                  "open-state": _vm.coverMenuOpen
                },
                on: {
                  cancel: function($event) {
                    _vm.coverMenuOpen = false
                  },
                  save: _vm.handleUpdateFromCoverMenu,
                  "update-account": function($event) {
                    return _vm.setAccount($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.textMenuOpen, persistent: true },
          on: {
            close: function($event) {
              _vm.textMenuOpen = false
            }
          }
        },
        [
          !_vm.coverMenuOpen && _vm.isReady
            ? _c(
                "format-modal",
                _vm._b(
                  {
                    attrs: {
                      "template-service": _vm.templateService,
                      "open-state": _vm.textMenuOpen
                    },
                    on: {
                      close: function($event) {
                        _vm.textMenuOpen = false
                      },
                      save: function($event) {
                        return _vm.handleFormatChange($event)
                      }
                    }
                  },
                  "format-modal",
                  _vm.proposalTemplate.formatting,
                  false
                )
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }