var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "column-format" },
      [
        _c("v-text-field", {
          staticStyle: { "max-width": "200px" },
          attrs: {
            outlined: "",
            "hide-details": "",
            dense: "",
            placeholder: "Search..."
          },
          on: { input: _vm.updated },
          model: {
            value: _vm.textFilter,
            callback: function($$v) {
              _vm.textFilter = $$v
            },
            expression: "textFilter"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }