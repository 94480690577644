import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class SyncDomainsService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
	}

	getSyncDomains() {
		return this.httpClient
			.get('/syncDomains')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateSyncDomains(syncDomains) {
		return this.httpClient
			.put(`/syncDomains`,syncDomains)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
