var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ourThread
    ? _c(
        "v-container",
        {
          staticClass: "font-14 my-0",
          attrs: { fluid: "" },
          on: {
            mouseenter: function($event) {
              _vm.hovering = true
            },
            mouseleave: function($event) {
              _vm.hovering = false
            },
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "row-format",
                  attrs: { cols: "3", align: "left" }
                },
                [
                  !_vm.clientId
                    ? _c(
                        "div",
                        _vm._l(_vm.clients, function(client) {
                          return _c("client-avatar", {
                            key: client.id,
                            staticClass: "mr-1",
                            attrs: { client: client, small: "" }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class:
                        "text-truncate " +
                        (_vm.clients.length && !_vm.clientId ? "ml-3" : "")
                    },
                    [_vm._v(_vm._s(_vm.participants.join(", ")))]
                  )
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "brand-semilight row-format align-center",
                  attrs: { cols: "7", align: "left" }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { color: "gray_60", size: "20" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.ourThread.unread ? "mark_email_unread" : "email"
                        )
                      )
                    ]
                  ),
                  _vm.ourThread.summary
                    ? _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: { theme: "left-align" },
                              expression: "{theme: 'left-align'}"
                            }
                          ],
                          staticClass: "material-symbols-rounded",
                          attrs: {
                            color: "gray_60",
                            size: "20",
                            content: _vm.ourThread.summary
                              ? _vm.marked(_vm.ourThread.summary, {
                                  breaks: true
                                })
                              : ""
                          }
                        },
                        [_vm._v("summarize")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class:
                        "ml-2 " + (_vm.ourThread.unread ? "brand-bold" : "")
                    },
                    [_vm._v(_vm._s(_vm.ourThread.subject))]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "nowrap", attrs: { cols: "2", align: "right" } },
                [
                  _c("inbox-actions", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hovering,
                        expression: "hovering"
                      }
                    ],
                    staticClass: "ml-auto",
                    attrs: {
                      unread: _vm.ourThread.unread,
                      "snooze-options": _vm.snoozeOptions,
                      folder: _vm.ourFolder,
                      size: 20
                    },
                    on: {
                      "return-to-inbox": function($event) {
                        return _vm.returnToInbox()
                      },
                      snooze: function($event) {
                        return _vm.snooze($event)
                      },
                      archive: function($event) {
                        return _vm.archive()
                      },
                      unread: function($event) {
                        return _vm.markThreadAsRead()
                      }
                    }
                  }),
                  !_vm.hovering
                    ? _c("div", { staticStyle: { height: "38px" } }, [
                        _vm._v(
                          _vm._s(
                            this.$formatters.formatTimeAgo(
                              _vm.communication.date
                            )
                          )
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.ourThread.messageCount > 1
            ? _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "row-format align-center",
                      attrs: { cols: "12", align: "left" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "message-count row-format centered" },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.ourThread.messageCount))
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "ml-auto multiple-messages" })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }