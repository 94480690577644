import HttpClient from "@/services/HttpClient";
import store from '@/store';

export default class PortalFeaturesService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(process.env.VUE_APP_BASE_AUTH_URL,true);
	}

	getPortalFeatures() {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.get(`/account/${accountId}/portal/features`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	updatePortalFeatures(features) {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.put(`/account/${accountId}/portal/features`,features)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
