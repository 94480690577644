var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode === "standard" || _vm.mode === "dense"
    ? _c(
        "div",
        { staticStyle: { width: "100%", position: "relative" } },
        [
          _c("vue-tel-input", {
            class: _vm.mode + "-phone",
            attrs: {
              "preferred-countries": _vm.preferredCountries,
              inputOptions: { placeholder: _vm.field.name }
            },
            on: { input: _vm.phoneUpdated },
            model: {
              value: _vm.phone,
              callback: function($$v) {
                _vm.phone = $$v
              },
              expression: "phone"
            }
          }),
          _c("div", { staticClass: "phone-label" }, [
            _vm._v(_vm._s(_vm.field.name))
          ])
        ],
        1
      )
    : _c(
        "div",
        [
          _c("vue-tel-input", {
            staticClass: "custom-phone",
            attrs: { "preferred-countries": _vm.preferredCountries },
            on: { input: _vm.phoneUpdated },
            model: {
              value: _vm.phone,
              callback: function($$v) {
                _vm.phone = $$v
              },
              expression: "phone"
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }