var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-format gap-2 py-3" }, [
    _c(
      "div",
      { staticClass: "row-format gap-1" },
      [
        _c("v-text-field", {
          attrs: {
            "hide-details": "",
            dense: "",
            outlined: "",
            type: "number",
            min: "0",
            max: "20",
            label: "Padding top"
          },
          on: { input: _vm.updated },
          model: {
            value: _vm.element.pt,
            callback: function($$v) {
              _vm.$set(_vm.element, "pt", $$v)
            },
            expression: "element.pt"
          }
        }),
        _c("v-text-field", {
          attrs: {
            "hide-details": "",
            dense: "",
            outlined: "",
            type: "number",
            min: "0",
            max: "20",
            label: "Padding bottom"
          },
          on: { input: _vm.updated },
          model: {
            value: _vm.element.pb,
            callback: function($$v) {
              _vm.$set(_vm.element, "pb", $$v)
            },
            expression: "element.pb"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "row-format gap-1" },
      [
        _c("v-text-field", {
          attrs: {
            "hide-details": "",
            dense: "",
            outlined: "",
            type: "number",
            min: "0",
            max: "20",
            label: "Padding left"
          },
          on: { input: _vm.updated },
          model: {
            value: _vm.element.pl,
            callback: function($$v) {
              _vm.$set(_vm.element, "pl", $$v)
            },
            expression: "element.pl"
          }
        }),
        _c("v-text-field", {
          attrs: {
            "hide-details": "",
            dense: "",
            outlined: "",
            type: "number",
            min: "0",
            max: "20",
            label: "Padding right"
          },
          on: { input: _vm.updated },
          model: {
            value: _vm.element.pr,
            callback: function($$v) {
              _vm.$set(_vm.element, "pr", $$v)
            },
            expression: "element.pr"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }