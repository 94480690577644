var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper",
      on: {
        click: function($event) {
          return _vm.$emit("action")
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "header",
          staticStyle: { "align-items": "center", "text-align": "left" }
        },
        [_vm._t("header")],
        2
      ),
      _c("div", { staticClass: "content mt-2" }, [_vm._t("content")], 2),
      _c("div", { staticClass: "row-format action-area mt-auto" }, [
        _vm.actionText
          ? _c("div", { staticClass: "row-format centered action-button" }, [
              _c("div", [_vm._v(_vm._s(_vm.actionText))])
            ])
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { "margin-left": "auto" } },
          [_c("snooze-delete", { attrs: { id: _vm.id } })],
          1
        )
      ]),
      _c("div", { staticClass: "timestamp" }, [
        _vm._v(
          _vm._s(
            _vm.DateTime.fromISO(_vm.sentAt).toLocaleString(
              _vm.DateTime.DATETIME_FULL
            )
          )
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }