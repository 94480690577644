var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0 font-14", staticStyle: { width: "98%" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "column-format gap-2", attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Business name"
                },
                model: {
                  value: _vm.opportunity.formData.businessName,
                  callback: function($$v) {
                    _vm.$set(_vm.opportunity.formData, "businessName", $$v)
                  },
                  expression: "opportunity.formData.businessName"
                }
              }),
              _c(
                "div",
                { staticClass: "row-format gap-2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "First name"
                    },
                    model: {
                      value: _vm.opportunity.formData.firstName,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "firstName", $$v)
                      },
                      expression: "opportunity.formData.firstName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Last name"
                    },
                    model: {
                      value: _vm.opportunity.formData.lastName,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "lastName", $$v)
                      },
                      expression: "opportunity.formData.lastName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row-format gap-2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Email"
                    },
                    model: {
                      value: _vm.opportunity.formData.email,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "email", $$v)
                      },
                      expression: "opportunity.formData.email"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Phone"
                    },
                    model: {
                      value: _vm.opportunity.formData.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "phone", $$v)
                      },
                      expression: "opportunity.formData.phone"
                    }
                  })
                ],
                1
              ),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Role"
                },
                model: {
                  value: _vm.opportunity.formData.role,
                  callback: function($$v) {
                    _vm.$set(_vm.opportunity.formData, "role", $$v)
                  },
                  expression: "opportunity.formData.role"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Website"
                },
                model: {
                  value: _vm.opportunity.formData.website,
                  callback: function($$v) {
                    _vm.$set(_vm.opportunity.formData, "website", $$v)
                  },
                  expression: "opportunity.formData.website"
                }
              }),
              _c(
                "div",
                { staticClass: "row-format gap-2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Lead source"
                    },
                    model: {
                      value: _vm.opportunity.formData.leadSource,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "leadSource", $$v)
                      },
                      expression: "opportunity.formData.leadSource"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Tax ID"
                    },
                    model: {
                      value: _vm.opportunity.formData.taxId,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "taxId", $$v)
                      },
                      expression: "opportunity.formData.taxId"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3 pt-3", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "column-format gap-2", attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Address 1"
                },
                model: {
                  value: _vm.opportunity.formData.address1,
                  callback: function($$v) {
                    _vm.$set(_vm.opportunity.formData, "address1", $$v)
                  },
                  expression: "opportunity.formData.address1"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Address 2"
                },
                model: {
                  value: _vm.opportunity.formData.address2,
                  callback: function($$v) {
                    _vm.$set(_vm.opportunity.formData, "address2", $$v)
                  },
                  expression: "opportunity.formData.address2"
                }
              }),
              _c(
                "div",
                { staticClass: "row-format gap-2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "City"
                    },
                    model: {
                      value: _vm.opportunity.formData.city,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "city", $$v)
                      },
                      expression: "opportunity.formData.city"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Locality"
                    },
                    model: {
                      value: _vm.opportunity.formData.locality,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "locality", $$v)
                      },
                      expression: "opportunity.formData.locality"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      "persistent-placeholder": "",
                      label: "Zip/Postal"
                    },
                    model: {
                      value: _vm.opportunity.formData.postal,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "postal", $$v)
                      },
                      expression: "opportunity.formData.postal"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row-format gap-2" },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      label: _vm.$t("contact.country"),
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "persistent-placeholder": "",
                      items: _vm.countries.getCountriesSimple(),
                      "item-value": "code",
                      "item-text": "name"
                    },
                    model: {
                      value: _vm.opportunity.formData.country,
                      callback: function($$v) {
                        _vm.$set(_vm.opportunity.formData, "country", $$v)
                      },
                      expression: "opportunity.formData.country"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("div", {
              staticClass: "fill-width",
              staticStyle: {
                "border-bottom": "1px solid var(--v-gray_30-base)"
              }
            })
          ])
        ],
        1
      ),
      _vm._l(_vm.opportunity.formData.answers, function(answer, index) {
        return _c(
          "v-row",
          { key: index, attrs: { dense: "" } },
          [
            _c("v-col", { attrs: { cols: "12" } }, [
              _c("div", {
                staticClass: "font-gray_70",
                domProps: { innerHTML: _vm._s(answer.question) }
              }),
              _c(
                "div",
                {
                  staticStyle: { "white-space": "pre-wrap" },
                  attrs: { contenteditable: "true" },
                  on: {
                    blur: function($event) {
                      return _vm.updateAnswer($event, index)
                    }
                  }
                },
                [_vm._v(_vm._s(answer.answer))]
              )
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }