var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", [
        _c("div", [
          _c(
            "div",
            { staticClass: "text-left" },
            [
              _c(
                "div",
                { staticClass: "column-format" },
                [
                  _c(
                    "div",
                    { staticClass: "row-format align-center gap-2 mb-4" },
                    [
                      _c("div", { staticClass: "brand-medium font-18" }, [
                        _vm._v(
                          "Your number: " +
                            _vm._s(
                              _vm.formatPhone(
                                _vm.subscription.numbers[0].number
                              )
                            )
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "super-action ml-auto",
                          attrs: { width: "150" },
                          on: {
                            click: function($event) {
                              return _vm.updatePreferences()
                            }
                          }
                        },
                        [_vm._v("Save settings")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "gray_50" },
                          on: {
                            click: function($event) {
                              return _vm.confirmCancel()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "material-symbols-rounded" },
                            [_vm._v("delete")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      label: "Accept calls in browser"
                    },
                    model: {
                      value: _vm.subscription.preferences.ringBrowser,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.subscription.preferences,
                          "ringBrowser",
                          $$v
                        )
                      },
                      expression: "subscription.preferences.ringBrowser"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      label: "Play incoming message sound in browser"
                    },
                    model: {
                      value:
                        _vm.subscription.preferences.playMessageSoundInBrowser,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.subscription.preferences,
                          "playMessageSoundInBrowser",
                          $$v
                        )
                      },
                      expression:
                        "subscription.preferences.playMessageSoundInBrowser"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      "true-value": false,
                      "false-value": true,
                      label:
                        "Silence missed call / message notifications outside of schedule."
                    },
                    model: {
                      value:
                        _vm.subscription.preferences
                          .notificationsOutsideSchedule,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.subscription.preferences,
                          "notificationsOutsideSchedule",
                          $$v
                        )
                      },
                      expression:
                        "subscription.preferences.notificationsOutsideSchedule"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "column-format my-8 gap-3" },
                [
                  _c("div", { staticClass: "font-gray_80 font-14" }, [
                    _vm._v(
                      " If you aren't logged in or do not answer within Moxie, you can optionally choose to have calls forwarded to another phone "
                    )
                  ]),
                  _c(
                    "div",
                    { staticStyle: { width: "100%", position: "relative" } },
                    [
                      _c("vue-tel-input", {
                        staticClass: "dense-phone",
                        attrs: {
                          mode: "international",
                          "valid-characters-only": true,
                          "preferred-countries": _vm.preferredCountries,
                          inputOptions: { placeholder: "Phone" }
                        },
                        model: {
                          value: _vm.subscription.preferences.forwardTo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.subscription.preferences,
                              "forwardTo",
                              $$v
                            )
                          },
                          expression: "subscription.preferences.forwardTo"
                        }
                      }),
                      _c("div", { staticClass: "phone-label" }, [
                        _vm._v("Phone")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row-format gap-2" },
                    [
                      _vm.subscription.provider === "Bandwidth"
                        ? _c("v-select", {
                            attrs: {
                              items: _vm.zeroToSixty,
                              "persistent-placeholder": "",
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              label: "Delay before forwarding"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [_vm._v(_vm._s(item) + " seconds")]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [_vm._v(_vm._s(item) + " seconds")]
                                  }
                                }
                              ],
                              null,
                              false,
                              48223164
                            ),
                            model: {
                              value: _vm.subscription.preferences.forwardDelay,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.subscription.preferences,
                                  "forwardDelay",
                                  $$v
                                )
                              },
                              expression:
                                "subscription.preferences.forwardDelay"
                            }
                          })
                        : _vm._e(),
                      _c("v-select", {
                        attrs: {
                          items: _vm.zeroToSixty,
                          "persistent-placeholder": "",
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          label: "How long to ring forwarding phone"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [_vm._v(_vm._s(item) + " seconds")]
                              }
                            },
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [_vm._v(_vm._s(item) + " seconds")]
                              }
                            }
                          ],
                          null,
                          false,
                          48223164
                        ),
                        model: {
                          value: _vm.subscription.preferences.forwardRing,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.subscription.preferences,
                              "forwardRing",
                              $$v
                            )
                          },
                          expression: "subscription.preferences.forwardRing"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-select", {
                    attrs: {
                      items: _vm.callerIdOptions,
                      "persistent-placeholder": "",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "item-text": "label",
                      "item-value": "value",
                      label:
                        "Whose number do you want to see when calls are forwarded to your phone?"
                    },
                    model: {
                      value: _vm.subscription.preferences.callerId,
                      callback: function($$v) {
                        _vm.$set(_vm.subscription.preferences, "callerId", $$v)
                      },
                      expression: "subscription.preferences.callerId"
                    }
                  }),
                  _vm.$store.getters.isTeamAccount
                    ? _c("div", { staticClass: "column-format gap-2" }, [
                        _c(
                          "div",
                          { staticClass: "row-format gap-2" },
                          [
                            _c("v-select", {
                              attrs: {
                                items: [
                                  {
                                    label:
                                      "Yes -share line with other team members",
                                    value: true
                                  },
                                  { label: "No - do not share", value: false }
                                ],
                                "persistent-placeholder": "",
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                "item-text": "label",
                                "item-value": "value",
                                label: "Enable line sharing?"
                              },
                              model: {
                                value: _vm.subscription.preferences.sharedLine,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.subscription.preferences,
                                    "sharedLine",
                                    $$v
                                  )
                                },
                                expression:
                                  "subscription.preferences.sharedLine"
                              }
                            }),
                            _vm.otherTeamMembers.length &&
                            _vm.subscription.preferences.sharedLine
                              ? _c("v-select", {
                                  attrs: {
                                    items: _vm.otherTeamMembers,
                                    "persistent-placeholder": "",
                                    "hide-details": "",
                                    dense: "",
                                    outlined: "",
                                    "item-text": "fullName",
                                    "item-value": "userId",
                                    "return-object": true,
                                    label: "Select team member to share with"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.handleTeamSelection($event)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.subscription.preferences.sharedWith.length &&
                  _vm.subscription.preferences.sharedLine
                    ? _c(
                        "div",
                        { staticClass: "mb-5" },
                        [
                          _c(
                            "v-container",
                            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
                            _vm._l(
                              _vm.subscription.preferences.sharedWith,
                              function(sharedWith, index) {
                                return _c(
                                  "v-row",
                                  {
                                    key: sharedWith.userId,
                                    staticClass: "py-2"
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "font-14 row-format align-center",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeTeamMember(
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "material-symbols-rounded",
                                                attrs: { size: "20" }
                                              },
                                              [_vm._v("delete")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.$store.getters.getUserById(
                                          sharedWith.userId
                                        )
                                          ? _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$store.getters.getUserById(
                                                      sharedWith.userId
                                                    ).firstName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.$store.getters.getUserById(
                                                      sharedWith.userId
                                                    ).lastName
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-12 font-gray_70"
                                                },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.$store.getters.getUserById(
                                                          sharedWith.userId
                                                        ).email
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c("phone-number-input", {
                                          attrs: {
                                            "default-country-code":
                                              _vm.defaultCountry,
                                            "only-countries": _vm.onlyCountries,
                                            clearable: true,
                                            color: _vm.$vuetify.theme.dark
                                              ? "#56524E"
                                              : "#D6D1CB",
                                            "valid-color": _vm.$vuetify.theme
                                              .dark
                                              ? "#56524E"
                                              : "#D6D1CB",
                                            size: "sm",
                                            "dark-color": "#181818",
                                            dark: _vm.$vuetify.theme.dark
                                          },
                                          on: {
                                            update: function($event) {
                                              return _vm.sharedWithPhoneUpdated(
                                                sharedWith,
                                                $event
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              sharedWith.forwardToFormatted,
                                            callback: function($$v) {
                                              _vm.$set(
                                                sharedWith,
                                                "forwardToFormatted",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "sharedWith.forwardToFormatted"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "mt-3 mb-1 font-gray_80 font-14" }, [
                _vm._v("Outgoing voicemail greeting")
              ]),
              _c("outgoing-greeting", {
                attrs: { subscription: _vm.subscription }
              }),
              _c("div", { staticClass: "mt-8 mb-1 font-gray_80 font-14" }, [
                _vm._v(
                  " Set your business hours to limit what days and times incoming calls are forwarded to your phone "
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "py-2 px-3",
                  staticStyle: {
                    border: "1px solid var(--v-gray_20-base)",
                    "border-radius": "4px"
                  }
                },
                [
                  _c("period-configuration", {
                    staticStyle: {
                      "border-bottom": "1px solid var(--v-gray_30-base)"
                    },
                    attrs: {
                      label: "Sun",
                      periods: _vm.subscription.preferences.schedule.sunday
                    }
                  }),
                  _c("period-configuration", {
                    staticStyle: {
                      "border-bottom": "1px solid var(--v-gray_30-base)"
                    },
                    attrs: {
                      label: "Mon",
                      periods: _vm.subscription.preferences.schedule.monday
                    }
                  }),
                  _c("period-configuration", {
                    staticStyle: {
                      "border-bottom": "1px solid var(--v-gray_30-base)"
                    },
                    attrs: {
                      label: "Tue",
                      periods: _vm.subscription.preferences.schedule.tuesday
                    }
                  }),
                  _c("period-configuration", {
                    staticStyle: {
                      "border-bottom": "1px solid var(--v-gray_30-base)"
                    },
                    attrs: {
                      label: "Wed",
                      periods: _vm.subscription.preferences.schedule.wednesday
                    }
                  }),
                  _c("period-configuration", {
                    staticStyle: {
                      "border-bottom": "1px solid var(--v-gray_30-base)"
                    },
                    attrs: {
                      label: "Thu",
                      periods: _vm.subscription.preferences.schedule.thursday
                    }
                  }),
                  _c("period-configuration", {
                    staticStyle: {
                      "border-bottom": "1px solid var(--v-gray_30-base)"
                    },
                    attrs: {
                      label: "Fri",
                      periods: _vm.subscription.preferences.schedule.friday
                    }
                  }),
                  _c("period-configuration", {
                    attrs: {
                      label: "Sat",
                      periods: _vm.subscription.preferences.schedule.saturday
                    }
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      "persistent-placeholder": "",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      label: "Schedule timezone",
                      items: _vm.timezones
                    },
                    model: {
                      value: _vm.subscription.preferences.schedule.timezone,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.subscription.preferences.schedule,
                          "timezone",
                          $$v
                        )
                      },
                      expression: "subscription.preferences.schedule.timezone"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "column-format gap-2 mt-8" }, [
                _c("div", { staticClass: "font-gray_80 font-14" }, [
                  _vm._v(
                    " Block specific phone numbers from being able to call or message your phone line. "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "row-format gap-2" },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "100%", position: "relative" } },
                      [
                        _c("vue-tel-input", {
                          staticClass: "dense-phone",
                          attrs: {
                            mode: "international",
                            "valid-characters-only": true,
                            "preferred-countries": _vm.preferredCountries,
                            inputOptions: { placeholder: "Phone" }
                          },
                          model: {
                            value: _vm.blockPhone,
                            callback: function($$v) {
                              _vm.blockPhone = $$v
                            },
                            expression: "blockPhone"
                          }
                        }),
                        _c("div", { staticClass: "phone-label" }, [
                          _vm._v("Phone")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticStyle: { "min-height": "40px" },
                        attrs: { outlined: "", color: "gray_50" },
                        on: { click: _vm.addBlockedNumber }
                      },
                      [_vm._v("Add blocked number")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "row-format gap-1",
                    staticStyle: { "flex-wrap": "wrap" }
                  },
                  _vm._l(_vm.subscription.preferences.blockedNumbers, function(
                    blockedNumber,
                    index
                  ) {
                    return _c(
                      "v-chip",
                      { key: index, attrs: { color: "error" } },
                      [
                        _vm._v(" " + _vm._s(blockedNumber) + " "),
                        _c(
                          "v-icon",
                          {
                            staticClass: "pointer material-symbols-rounded",
                            attrs: { size: "20" },
                            on: {
                              click: function($event) {
                                _vm.subscription.preferences.blockedNumbers.splice(
                                  index,
                                  1
                                )
                                _vm.updatePreferences(false)
                              }
                            }
                          },
                          [_vm._v("delete")]
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ])
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }