var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: {
        border: "1px solid var(--v-gray_30-base)",
        "border-radius": "4px",
        overflow: "hidden",
        "background-color": "var(--v-white-base)"
      },
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pa-0 column-format",
              staticStyle: {
                "justify-content": "center",
                "background-color": "var(--v-gray_5-base)",
                "border-right": "1px solid var(--v-gray_30-base)"
              },
              attrs: { cols: "5" }
            },
            [
              _c("div", { staticClass: "booking-widget" }, [
                _c("div", { staticClass: "header" }, [
                  _vm._v(_vm._s(_vm.label))
                ]),
                _c("div", { staticClass: "body" }, [
                  _vm._v(_vm._s(_vm.totalUsage))
                ])
              ]),
              _c("div", { staticClass: "booking-widget" }, [
                _c("div", { staticClass: "header" }, [_vm._v("Earnings")]),
                _c("div", { staticClass: "body" }, [
                  _vm._v(
                    _vm._s(_vm.$formatters.dollars(_vm.totalValue, true, true))
                  )
                ])
              ])
            ]
          ),
          _c("v-col", { attrs: { cols: "7" } }, [
            _c(
              "div",
              {
                staticClass: "row-format centered pa-2",
                staticStyle: { overflow: "hidden" }
              },
              [
                _vm.unBilled.length
                  ? _c("pie", {
                      attrs: {
                        "chart-id": _vm.pieRefreshKey,
                        datasetIdKey: "insight-time-pie",
                        "chart-options": _vm.pieChartOptions,
                        "chart-data": _vm.pieChartData,
                        width: 200,
                        height: 200
                      }
                    })
                  : _c(
                      "div",
                      {
                        staticClass: "column-format centered",
                        staticStyle: { "min-height": "200px" }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "100", color: "gray_20" } },
                          [_vm._v("block")]
                        )
                      ],
                      1
                    )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }