<template>
		<div @click="createTimeEntry" class="row-format align-center pointer">
			<v-icon color="primary" small>timer</v-icon>
			<div class="font-primary ml-1">Add time entry</div>
		</div>
</template>

<script>
	import {DateTime} from 'luxon';
	import TimeTrackEdit from "@/modules/timetracking/TimeTrackEdit";

	export default {
		name: 'CreateTimeEntry',

		props: ['start', 'end', 'client', 'notes'],

		components: {},

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			createTimeEntry: function(){
				let timer = {
					clientId: this.client ? this.client.id : null,
					clientName: this.client ? this.client.name : null,
					deliverableId: null,
					deliverableName: null,
					id: null,
					userId: this.$store.getters.getLoggedInUserId,
					notes: this.notes,
					projectId: null,
					projectName: null,
					timerStart: this.startDate.toISO(),
					timerEnd: this.endDate.toISO(),
					duration: this.endDate.diff(this.startDate, ['hours', 'minutes', 'seconds', 'milliseconds']),
				}
				this.$store.state.globalModalController.openModal(TimeTrackEdit,{timerEvent:timer}).then((res) => {
					if(res && res.id){
						let duration = this.$formatters.formatSecondsLong(res.duration);
						this.$store.commit('success',`A time entry for ${duration} has been successfully added.`);
					}
				})
			}
		},

		computed: {
			startDate: function(){
				return DateTime.fromISO(this.start);
			},

			endDate: function(){
				return DateTime.fromISO(this.end);
			}
		},
	};
</script>

<style scoped lang="scss"></style>
