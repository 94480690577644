var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        [
          _c("v-data-table", {
            attrs: {
              id: "products-data-table",
              "items-per-page": 25,
              "hide-default-footer": _vm.filteredProducts.length <= 25,
              "hide-default-header": false,
              headers: _vm.headers,
              items: _vm.filteredProducts,
              "item-key": "id",
              "no-data-text": "Add your first product or service",
              "mobile-breakpoint": 0,
              "item-class": _vm.itemClass,
              "group-by": "folder"
            },
            on: {
              "click:row": function($event) {
                return _vm.selectProduct($event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "group.header",
                  fn: function(ref) {
                    var group = ref.group
                    var headers = ref.headers
                    var toggle = ref.toggle
                    return [
                      _c(
                        "td",
                        {
                          staticClass: "text-left",
                          attrs: { colspan: "5" },
                          on: { click: toggle }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row-format align-center gap-2" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "20", color: "secondary" } },
                                [_vm._v("folder")]
                              ),
                              _vm._v(_vm._s(group ? group : " -- ") + " ")
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.productName",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("div", { staticClass: "product-name" }, [
                        _vm._v(_vm._s(item.productName))
                      ])
                    ]
                  }
                },
                {
                  key: "item.rate",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("div", [_vm._v(_vm._s(_vm.getRateForList(item)))])
                    ]
                  }
                },
                {
                  key: "item.hourly",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          item.hourly
                            ? _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(_vm._s(_vm.$t("product.check")))
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.taxable",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          item.taxable
                            ? _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(_vm._s(_vm.$t("product.check")))
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.deposit",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          item.deposit
                            ? _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(_vm._s(_vm.$t("product.check")))
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.manage",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "text-right mt-n1" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.editProduct(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: { color: "gray_50", size: "20" }
                                },
                                [_vm._v("$edit")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteProduct(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: { color: "gray_50", size: "20" }
                                },
                                [_vm._v("$delete")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3835996678
            )
          }),
          _vm.productModal
            ? _c(
                "basic-modal",
                {
                  attrs: { dialog: _vm.productModal, persistent: true },
                  on: {
                    close: function($event) {
                      _vm.productModal = false
                    }
                  }
                },
                [
                  _c("div", { attrs: { id: "edit-product" } }, [
                    _c(
                      "div",
                      { staticClass: "modal-padding column-format gap-3" },
                      [
                        _c(
                          "v-form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            },
                            model: {
                              value: _vm.isValid,
                              callback: function($$v) {
                                _vm.isValid = $$v
                              },
                              expression: "isValid"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "modal-title row-format gap-2 align-center"
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("product.create-product-title")
                                    )
                                  )
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ml-auto row-format align-center"
                                  },
                                  [
                                    _c("folder-selector", {
                                      attrs: { folders: _vm.folders },
                                      model: {
                                        value: _vm.editingProduct.folder,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editingProduct,
                                            "folder",
                                            $$v
                                          )
                                        },
                                        expression: "editingProduct.folder"
                                      }
                                    }),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            _vm.productModal = false
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("close")])],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("v-text-field", {
                              attrs: {
                                "hide-details": "",
                                outlined: "",
                                dense: "",
                                autofocus: "",
                                "persistent-placeholder": "",
                                placeholder: _vm.$t(
                                  "product.product-name-placeholder"
                                ),
                                label: _vm.$t("product.product-name-label"),
                                rules: [
                                  function(value) {
                                    return !!value || "Required."
                                  }
                                ]
                              },
                              model: {
                                value: _vm.editingProduct.productName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editingProduct,
                                    "productName",
                                    $$v
                                  )
                                },
                                expression: "editingProduct.productName"
                              }
                            }),
                            _c("v-textarea", {
                              staticClass: "my-3",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                outlined: "",
                                dense: "",
                                placeholder: _vm.$t(
                                  "product.product-description"
                                ),
                                spellcheck: "true",
                                rows: 9
                              },
                              model: {
                                value: _vm.editingProduct.description,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editingProduct,
                                    "description",
                                    $$v
                                  )
                                },
                                expression: "editingProduct.description"
                              }
                            }),
                            _c("amount-input", {
                              attrs: {
                                "hide-details": "",
                                outlined: "",
                                dense: "",
                                "persistent-placeholder": "",
                                prefix: _vm.currencySymbol(),
                                placeholder: _vm.$t("product.rate-placeholder"),
                                label: _vm.$t("product.rate-label"),
                                rules: [
                                  function(value) {
                                    return !!value || "Required."
                                  }
                                ]
                              },
                              model: {
                                value: _vm.editingProduct.rate,
                                callback: function($$v) {
                                  _vm.$set(_vm.editingProduct, "rate", $$v)
                                },
                                expression: "editingProduct.rate"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "column-format gap-2 mt-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-start align-start"
                                  },
                                  [
                                    _c("v-simple-checkbox", {
                                      model: {
                                        value: _vm.editingProduct.deposit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editingProduct,
                                            "deposit",
                                            $$v
                                          )
                                        },
                                        expression: "editingProduct.deposit"
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        directives: [
                                          {
                                            name: "tippy",
                                            rawName: "v-tippy",
                                            value: {
                                              content:
                                                "Used to flag a line item as being part of a prepaid deposit"
                                            },
                                            expression:
                                              "{ content: 'Used to flag a line item as being part of a prepaid deposit' }"
                                          }
                                        ],
                                        staticClass:
                                          "font-14 font-gray_80 ml-n1"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("product.deposit-label")
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                !_vm.editingProduct.deposit
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-start align-start"
                                      },
                                      [
                                        _c("v-simple-checkbox", {
                                          on: {
                                            click: function($event) {
                                              return _vm.handleHourlyClick()
                                            }
                                          },
                                          model: {
                                            value: _vm.editingProduct.hourly,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.editingProduct,
                                                "hourly",
                                                $$v
                                              )
                                            },
                                            expression: "editingProduct.hourly"
                                          }
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "font-14 font-gray_80 ml-n1"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("product.hourly-label")
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-start align-start"
                                  },
                                  [
                                    _c("v-simple-checkbox", {
                                      model: {
                                        value: _vm.editingProduct.taxable,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editingProduct,
                                            "taxable",
                                            $$v
                                          )
                                        },
                                        expression: "editingProduct.taxable"
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "font-14 font-gray_80 ml-n1"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("product.taxable-label")
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c("div", {
                              staticClass: "mt-2 mb-4",
                              staticStyle: {
                                "border-bottom":
                                  "1px solid var(--v-gray_50-base)",
                                width: "100%",
                                "min-height": "2px"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "row-format gap-4" },
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    dense: "",
                                    outlined: "",
                                    placeholder: _vm.$t("account.currency"),
                                    items: _vm.currencies,
                                    "item-text": "label",
                                    "item-value": "value"
                                  },
                                  model: {
                                    value: _vm.newCurrency,
                                    callback: function($$v) {
                                      _vm.newCurrency = $$v
                                    },
                                    expression: "newCurrency"
                                  }
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: { "min-height": "40px" },
                                    attrs: {
                                      outlined: "",
                                      color: "secondary",
                                      disabled: !_vm.newCurrency
                                    },
                                    on: { click: _vm.addCurrency }
                                  },
                                  [_vm._v("+ Add new rate")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-4 column-format gap-4" },
                              _vm._l(_vm.editingProduct.currencyRates, function(
                                currencyRate,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: currencyRate.currency,
                                    staticClass: "row-format gap-4 align-center"
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "hide-details": "",
                                        outlined: "",
                                        dense: "",
                                        "persistent-placeholder": "",
                                        prefix: _vm.currencySymbol(
                                          currencyRate.currency
                                        ),
                                        suffix: currencyRate.currency,
                                        placeholder: _vm.$t(
                                          "product.rate-placeholder"
                                        ),
                                        label: _vm.$t("product.rate"),
                                        rules: [
                                          function(value) {
                                            return !!value || "Required."
                                          }
                                        ]
                                      },
                                      model: {
                                        value: currencyRate.rate,
                                        callback: function($$v) {
                                          _vm.$set(currencyRate, "rate", $$v)
                                        },
                                        expression: "currencyRate.rate"
                                      }
                                    }),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "material-symbols-rounded pointer",
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteCurrencyRate(index)
                                          }
                                        }
                                      },
                                      [_vm._v("delete")]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "div",
                        { staticClass: "row-format gap-1 centered" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "super-action",
                              staticStyle: { width: "140px" },
                              attrs: {
                                disabled:
                                  !_vm.editingProduct.productName ||
                                  !_vm.isValid
                              },
                              on: {
                                click: function($event) {
                                  return _vm.save()
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "icon",
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteProduct(_vm.editingProduct)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: { size: "20" }
                                },
                                [_vm._v("delete")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }