var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.$vuetify.theme.dark ? "dark" : "light",
      attrs: { id: "help-center" }
    },
    [
      _c(
        "div",
        {
          staticClass: "row-format align-center ma-4 pointer text-left",
          on: { click: _vm.allHelp }
        },
        [
          _vm.focusHelp
            ? _c(
                "div",
                { staticClass: "row-format align-center pointer font-gray_80" },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { size: "20" } }, [
                    _vm._v("$arrowLeft")
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              class:
                "font-14 brand-medium " +
                (!_vm.focusHelp ? "font-gray_90" : "font-gray_80"),
              staticStyle: { "white-space": "nowrap" }
            },
            [_vm._v("Help center ")]
          ),
          _vm.focusHelp
            ? _c(
                "div",
                {
                  staticClass:
                    "font-14 brand-medium font-gray_90 row-format align-center"
                },
                [
                  _c("div", { staticClass: "mx-2" }, [_vm._v("/")]),
                  _c("h-icon2", {
                    attrs: { size: "20", name: _vm.focusHelp.icon }
                  }),
                  _c("div", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(_vm.focusHelp.name))
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("$close")]
              )
            ],
            1
          )
        ]
      ),
      !_vm.focusHelp
        ? _c(
            "div",
            {
              staticClass: "column-format show-scrollbar mx-4 pb-4",
              staticStyle: {
                "max-height": "calc(var(--vh) - 150px)",
                "overflow-y": "auto"
              }
            },
            _vm._l(_vm.sortedHelp, function(section) {
              return _c("section-link", {
                key: section.name,
                attrs: {
                  name: section.name,
                  icon: section.icon,
                  color: section.color,
                  description: section.description
                },
                on: {
                  selected: function($event) {
                    return _vm.sectionSelected(section)
                  }
                }
              })
            }),
            1
          )
        : _vm._e(),
      _vm.focusHelp
        ? _c(
            "div",
            {
              staticClass: "row-format ma-3 show-scrollbar",
              staticStyle: {
                "max-height": "calc(var(--vh) - 150px)",
                "overflow-y": "auto"
              }
            },
            [
              _c("section-detail", {
                attrs: { section: _vm.focusHelp },
                on: {
                  "all-help": function($event) {
                    return _vm.allHelp()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "mt-auto mb-4 mx-3 pt-4" }, [
        _c("div", { staticClass: "text-left brand-medium mb-1" }, [
          _vm._v(" Still need help? ")
        ]),
        _c("div", { staticClass: "row-format centered" }, [
          _c(
            "div",
            {
              staticClass: "button column-format centered mr-1",
              on: { click: _vm.openHelpCenter }
            },
            [
              _c("h-icon", {
                staticStyle: {
                  "--secondary-opacity": "1",
                  "--secondary-color": "var(--v-gray_50-base)"
                },
                attrs: {
                  name: "knowledge-base",
                  size: "30",
                  "two-color": false,
                  color: "var(--v-primary-base)"
                }
              }),
              _c("div", { staticClass: "font-14 brand-medium" }, [
                _vm._v("Knowledge base")
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "button column-format centered ml-1",
              on: { click: _vm.stillNeedHelp }
            },
            [
              _c("h-icon", {
                staticStyle: {
                  "--secondary-opacity": "1",
                  "--secondary-color": "var(--v-gray_50-base)"
                },
                attrs: {
                  name: "chat-with-us",
                  size: "30",
                  "two-color": false,
                  color: "var(--v-primary-base)"
                }
              }),
              _c("div", { staticClass: "font-14 brand-medium" }, [
                _vm._v("Chat with us")
              ])
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }