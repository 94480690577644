var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "create-template-from-proposal-modal" } }, [
    _c(
      "div",
      { staticClass: "modal-padding pb-0" },
      [
        _c("div", { staticClass: "row-format mb-2" }, [
          _c("div", { staticClass: "brand-medium font-18" }, [
            _vm._v(_vm._s(_vm.$t("templates.save-as-template")))
          ]),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "v-icon",
                {
                  attrs: { color: "gray_80", size: "20" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("result")
                    }
                  }
                },
                [_vm._v("$close")]
              )
            ],
            1
          )
        ]),
        _c("v-text-field", {
          ref: "templateName",
          staticClass: "h-outline",
          attrs: {
            label: _vm.$t(
              "templates.create-from-project-modal.template-name-label"
            ),
            placeholder: _vm.$t(
              "templates.create-from-project-modal.template-name-placeholder"
            ),
            autofocus: "",
            "hide-details": "",
            "persistent-placeholder": ""
          },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.save()
            }
          },
          model: {
            value: _vm.name,
            callback: function($$v) {
              _vm.name = $$v
            },
            expression: "name"
          }
        }),
        _vm.templateList.length
          ? _c(
              "div",
              { staticClass: "brand-semibold text-left font-12 mt-4" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("templates.proposals.existing-templates")) +
                    " "
                )
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { attrs: { id: "item-wrapper" } },
      _vm._l(_vm.templateList, function(item) {
        return _c(
          "div",
          {
            key: item.id,
            class:
              _vm.templateToDelete && _vm.templateToDelete.id === item.id
                ? "selected"
                : "",
            on: {
              click: function($event) {
                return _vm.toggleDelete(item)
              }
            }
          },
          [_vm._v(" " + _vm._s(item.name) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "v-btn",
          {
            staticClass: "delete mr-1",
            attrs: { width: "160" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("global.cancel")))]
        ),
        _c(
          "v-btn",
          {
            staticClass: "primary-action ml-1",
            attrs: { width: "160", disabled: !_vm.name },
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("global.save")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }