var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.discoveryTemplate && _vm.allowRender
    ? _c(
        "div",
        {
          staticStyle: { "min-height": "100vh" },
          attrs: { id: "discovery-builder" }
        },
        [
          _c(
            "div",
            {
              attrs: { id: "header-area" },
              on: {
                mouseenter: function($event) {
                  _vm.headerHovering = true
                },
                mouseleave: function($event) {
                  _vm.headerHovering = false
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "header-left pl-md-6 pl-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      on: {
                        click: function($event) {
                          _vm.addContentDialog = true
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mt-1", attrs: { left: "" } },
                        [_vm._v("$plusSmall")]
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("leadgen.add-content")) + " ")
                    ],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "close-on-click": true
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    { staticClass: "primary-action ml-2" },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("$settings")
                                    ]),
                                    _vm._v(" Settings ")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3844196477
                      ),
                      model: {
                        value: _vm.settingsMenu,
                        callback: function($$v) {
                          _vm.settingsMenu = $$v
                        },
                        expression: "settingsMenu"
                      }
                    },
                    [
                      _c(
                        "v-container",
                        {
                          staticStyle: {
                            "max-width": "280px",
                            width: "280px",
                            "min-height": "400px",
                            "background-color": "var(--v-white-base)"
                          },
                          attrs: { fluid: "" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-4",
                                  staticStyle: { height: "400px" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "column-format",
                                      staticStyle: {
                                        height: "100%",
                                        gap: "12px"
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          dense: "",
                                          "hide-details": "",
                                          label: "Show logo"
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.needSave = true
                                          }
                                        },
                                        model: {
                                          value: _vm.discoveryTemplate.showLogo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.discoveryTemplate,
                                              "showLogo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "discoveryTemplate.showLogo"
                                        }
                                      }),
                                      _c("font-selector", {
                                        attrs: {
                                          label: "Font",
                                          font: _vm.discoveryTemplate.font
                                        },
                                        on: {
                                          "font-changed": function($event) {
                                            return _vm.handleFontChange($event)
                                          }
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "row-format",
                                          staticStyle: {
                                            "justify-content": "space-between",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "fit-content"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "offset-y": "",
                                                    "close-on-content-click": false
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "accent-button",
                                                                  attrs: {
                                                                    elevation:
                                                                      "0",
                                                                    color:
                                                                      _vm
                                                                        .discoveryTemplate
                                                                        .accentColor,
                                                                    width: "120"
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " Accent color "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2428467573
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "var(--v-white-base)"
                                                      }
                                                    },
                                                    [
                                                      _c("v-color-picker", {
                                                        attrs: {
                                                          "show-swatches": "",
                                                          mode: "hexa"
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            _vm.needSave = true
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .discoveryTemplate
                                                              .accentColor,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.discoveryTemplate,
                                                              "accentColor",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "discoveryTemplate.accentColor"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "fit-content"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "offset-y": "",
                                                    "close-on-content-click": false
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "accent-button",
                                                                  attrs: {
                                                                    elevation:
                                                                      "0",
                                                                    color:
                                                                      _vm
                                                                        .discoveryTemplate
                                                                        .accentColor,
                                                                    width: "120"
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    style:
                                                                      "color: " +
                                                                      _vm
                                                                        .discoveryTemplate
                                                                        .buttonText +
                                                                      "!important"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Button text"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1530815421
                                                  )
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "var(--v-white-base)"
                                                      }
                                                    },
                                                    [
                                                      _c("v-color-picker", {
                                                        attrs: {
                                                          "show-swatches": "",
                                                          mode: "hexa"
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            _vm.needSave = true
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .discoveryTemplate
                                                              .buttonText,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.discoveryTemplate,
                                                              "buttonText",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "discoveryTemplate.buttonText"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c("v-autocomplete", {
                                        staticClass: "h-outline",
                                        attrs: {
                                          disabled:
                                            _vm.emailTemplates.length === 0,
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          label: "Confirmation email template",
                                          items: _vm.emailTemplates,
                                          "item-value": "id",
                                          "item-text": "name",
                                          clearable: ""
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.needSave = true
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.discoveryTemplate
                                              .confirmationEmailTemplate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.discoveryTemplate,
                                              "confirmationEmailTemplate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "discoveryTemplate.confirmationEmailTemplate"
                                        }
                                      }),
                                      _c("v-select", {
                                        staticClass: "h-outline mt-n1",
                                        attrs: {
                                          disabled:
                                            _vm.pipelineStages.length === 0,
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          label: "Add submission to pipeline",
                                          items: _vm.pipelineStages,
                                          "item-value": "id",
                                          "item-text": "label",
                                          clearable: ""
                                        },
                                        on: {
                                          input: function($event) {
                                            _vm.needSave = true
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c("div", {
                                                    style:
                                                      "width: 20px; height: 20px; border-radius: 4px; background-color: " +
                                                      item.hexColor
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "ml-2" },
                                                    [_vm._v(_vm._s(item.label))]
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "selection",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c("div", {
                                                    style:
                                                      "width: 20px; height: 20px; border-radius: 4px; background-color: " +
                                                      item.hexColor
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "ml-2" },
                                                    [_vm._v(_vm._s(item.label))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2885459100
                                        ),
                                        model: {
                                          value:
                                            _vm.discoveryTemplate
                                              .pipelineStageId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.discoveryTemplate,
                                              "pipelineStageId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "discoveryTemplate.pipelineStageId"
                                        }
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "primary-action",
                                          staticStyle: { "margin-top": "auto" },
                                          on: {
                                            click: function($event) {
                                              _vm.settingsMenu = false
                                            }
                                          }
                                        },
                                        [_vm._v("OK")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "header-center d-flex align-center justify-center"
                },
                [
                  _vm.client
                    ? _c(
                        "div",
                        { staticClass: "mr-2" },
                        [
                          _c("client-avatar", { attrs: { client: _vm.client } })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "brand-semibold font-16" },
                    [
                      _c("v-text-field", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          dense: "",
                          "hide-details": "",
                          outlined: "",
                          placeholder: "Template name"
                        },
                        on: {
                          change: function($event) {
                            _vm.needSave = true
                          }
                        },
                        model: {
                          value: _vm.discoveryTemplate.name,
                          callback: function($$v) {
                            _vm.$set(_vm.discoveryTemplate, "name", $$v)
                          },
                          expression: "discoveryTemplate.name"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "div",
                { staticClass: "header-right pr-md-6 pr-4" },
                [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "div",
                        { staticStyle: { "white-space": "nowrap" } },
                        [
                          _vm.needSave || _vm.saving
                            ? _c("v-progress-circular", {
                                staticClass: "mr-2",
                                attrs: {
                                  indeterminate: "",
                                  size: "20",
                                  color: _vm.saving ? "green" : "accent"
                                }
                              })
                            : _vm._e(),
                          !_vm.needSave && !_vm.saving
                            ? _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { small: "" } },
                                [_vm._v("check")]
                              )
                            : _vm._e(),
                          _c("span", { staticClass: "mr-3 font-12" }, [
                            _vm._v(_vm._s(_vm.status))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("folder-selector", {
                    attrs: { folders: _vm.folders },
                    on: {
                      input: function($event) {
                        _vm.needSave = true
                      }
                    },
                    model: {
                      value: _vm.discoveryTemplate.folder,
                      callback: function($$v) {
                        _vm.$set(_vm.discoveryTemplate, "folder", $$v)
                      },
                      expression: "discoveryTemplate.folder"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action ml-2",
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v(" Save ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "super-action ml-2",
                      on: {
                        click: function($event) {
                          _vm.shareDialog = true
                        }
                      }
                    },
                    [_vm._v(" Share ")]
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: { "close-on-click": true, "nudge-bottom": "30" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "ml-2" },
                                  [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "menu-activator secondary-action",
                                          attrs: { icon: "", disabled: false }
                                        },
                                        scope.on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { size: 20 } }, [
                                          _vm._v(
                                            _vm._s(
                                              scope.value
                                                ? "$arrowUp"
                                                : "$moreHorizontal"
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1580892412
                      )
                    },
                    [
                      _c("div", { staticClass: "more-menu" }, [
                        _c(
                          "div",
                          {
                            staticClass: "more-menu-item",
                            on: {
                              click: function($event) {
                                return _vm.viewResults()
                              }
                            }
                          },
                          [_vm._v(" View results ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "more-menu-item",
                            on: {
                              click: function($event) {
                                return _vm.duplicateTemplate()
                              }
                            }
                          },
                          [_vm._v(" Duplicate template ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "more-menu-item",
                            on: {
                              click: function($event) {
                                _vm.confirmDeleteDialog = true
                              }
                            }
                          },
                          [_vm._v(" Delete ")]
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ml-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary-action",
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.back()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: 20 } }, [
                            _vm._v("$close")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "v-container",
            {
              staticClass: "px-0 px-md-4 mt-0",
              style:
                "width: 800px; max-width: 800px; align-self:center; --discovery-font: " +
                _vm.discoveryTemplate.font +
                "; --accent-color: " +
                _vm.discoveryTemplate.accentColor,
              on: {
                click: function($event) {
                  _vm.editorIndex = -1
                }
              }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "form-box mb-12 mt-8" }, [
                      _c("div", { style: "width:100%;" }, [
                        _c(
                          "div",
                          {
                            staticClass: "pb-4 styled-form mt-4",
                            staticStyle: {
                              "background-color": "var(--v-white-base)"
                            }
                          },
                          [
                            _vm.discoveryTemplate.showLogo
                              ? _c(
                                  "div",
                                  { staticClass: "row-format centered" },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        "max-height": "100px",
                                        "max-width": "250px"
                                      },
                                      attrs: {
                                        src:
                                          _vm.$store.getters.getAccount
                                            .accountLogo
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "draggable",
                              {
                                attrs: {
                                  group: "schema",
                                  handle: ".dragHandle"
                                },
                                on: { end: _vm.dragComplete },
                                model: {
                                  value: _vm.discoveryTemplate.schema,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.discoveryTemplate,
                                      "schema",
                                      $$v
                                    )
                                  },
                                  expression: "discoveryTemplate.schema"
                                }
                              },
                              _vm._l(_vm.discoveryTemplate.schema, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "content-block",
                                    style: _vm.computedStyle(index),
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.rendererClick(index)
                                      }
                                    }
                                  },
                                  [
                                    _vm.editorIndex !== index
                                      ? _c("renderer", {
                                          attrs: {
                                            "editor-index": _vm.editorIndex,
                                            "update-flag": _vm.updateFlag,
                                            item: item,
                                            "preview-mode": true
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.editorIndex === index
                                      ? _c("builder", {
                                          attrs: {
                                            item: item,
                                            "mce-config-header":
                                              _vm.mceConfigHeader,
                                            "mce-config-text":
                                              _vm.mceConfigText,
                                            "preview-mode": true
                                          },
                                          on: {
                                            changed: function($event) {
                                              return _vm.handleChange(
                                                $event,
                                                index
                                              )
                                            },
                                            "shift-up": function($event) {
                                              return _vm.shiftUp(index)
                                            },
                                            "shift-down": function($event) {
                                              return _vm.shiftDown(index)
                                            },
                                            "duplicate-section": function(
                                              $event
                                            ) {
                                              return _vm.duplicateSection(index)
                                            },
                                            "delete-section": function($event) {
                                              return _vm.deleteSection(index)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "div",
                              { staticClass: "mx-8 mt-8" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      width: "150",
                                      color: _vm.discoveryTemplate.accentColor,
                                      elevation: "0"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        style:
                                          "font-weight: 600; color: " +
                                          _vm.discoveryTemplate.buttonText +
                                          "!important"
                                      },
                                      [_vm._v(_vm._s(_vm.$t("global.submit")))]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "content-block add-content d-flex flex-column align-center pointer my-5 pb-4",
                            style: _vm.computedStyle(-2),
                            on: {
                              click: function($event) {
                                _vm.addContentDialog = true
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "add-content-icon",
                                    attrs: { size: "64" }
                                  },
                                  [_vm._v("$duplicate")]
                                )
                              ],
                              1
                            ),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.discoveryTemplate.schema.length === 0
                                        ? "discovery.add-first-content"
                                        : "discovery.add-additional-content"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.shareDialog
            ? _c("basic-modal", {
                attrs: {
                  dialog: _vm.shareDialog,
                  persistent: true,
                  "retain-focus": false,
                  "max-width": 684
                },
                on: {
                  close: function($event) {
                    _vm.shareDialog = false
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _vm.client
                            ? _c("share-send-widget", {
                                attrs: {
                                  "client-id": _vm.client.id,
                                  "discovery-template": _vm.discoveryTemplate,
                                  "mce-config-text": _vm.mceConfigText,
                                  "open-state": _vm.shareDialog
                                },
                                on: {
                                  alert: function($event) {
                                    return scope.setAlert($event)
                                  },
                                  cancel: function($event) {
                                    _vm.shareDialog = false
                                  },
                                  finalize: function($event) {
                                    _vm.shareDialog = false
                                  },
                                  "discovery-sent": function($event) {
                                    return _vm.handleDiscoverySent()
                                  }
                                }
                              })
                            : _c("share-form", {
                                attrs: {
                                  "full-link": _vm.fullTemplateLink,
                                  "full-link-embed": _vm.fullTemplateLinkEmbed
                                },
                                on: {
                                  close: function($event) {
                                    _vm.shareDialog = false
                                  }
                                }
                              })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2766865851
                )
              })
            : _vm._e(),
          _vm.confirmDeleteDialog
            ? _c("confirm-dialog", {
                attrs: {
                  dialog: _vm.confirmDeleteDialog,
                  persistent: false,
                  "heading-text": "Delete template",
                  "body-text": _vm.$t("discovery.delete-confirm", {
                    template: _vm.discoveryTemplate.name
                  })
                },
                on: {
                  confirm: _vm.deleteTemplate,
                  close: _vm.cancelDeleteTemplate
                }
              })
            : _vm._e(),
          _vm.addContentDialog
            ? _c(
                "basic-modal",
                {
                  attrs: {
                    dialog: _vm.addContentDialog,
                    persistent: false,
                    "max-width": 300
                  },
                  on: {
                    close: function($event) {
                      _vm.addContentDialog = false
                    }
                  }
                },
                [
                  _c("add-content-dialog", {
                    on: {
                      "add-item": function($event) {
                        _vm.handleAddItem($event)
                        _vm.addContentDialog = false
                      },
                      close: function($event) {
                        _vm.addContentDialog = false
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.resultsDialog
            ? _c(
                "basic-modal",
                {
                  attrs: {
                    dialog: _vm.resultsDialog,
                    persistent: false,
                    "max-width": 900
                  },
                  on: {
                    close: function($event) {
                      _vm.resultsDialog = false
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "pa-3 column-format" },
                    [
                      _c(
                        "div",
                        { staticClass: "row-format mr-2" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-auto pointer",
                              on: {
                                click: function($event) {
                                  _vm.resultsDialog = false
                                }
                              }
                            },
                            [_vm._v("close")]
                          )
                        ],
                        1
                      ),
                      _c("form-submissions-table", {
                        attrs: { "template-id": _vm.discoveryTemplate.id }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }