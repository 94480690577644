var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "column-format" },
        [
          _vm._t("filter"),
          _c(
            "div",
            {
              staticClass: "mt-4",
              staticStyle: { "background-color": "var(--v-white-base)" }
            },
            [
              _c("v-data-table", {
                staticStyle: { cursor: "pointer" },
                attrs: {
                  headers: _vm.headers,
                  items: _vm.filteredMeetingList,
                  "sort-by": "confirmedTime.start",
                  "sort-asc": "",
                  "disable-pagination": true,
                  "hide-default-footer": true
                },
                on: {
                  "click:row": function($event) {
                    return _vm.openScheduledMeeting($event.id)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.confirmedTime.start",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "span",
                            {
                              class:
                                "" +
                                (item.meetingStatus === "Cancelled"
                                  ? "cancelled"
                                  : "")
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.DateTime.fromISO(
                                    item.confirmedTime.start
                                  ).toFormat("ccc") +
                                    " " +
                                    _vm.DateTime.fromISO(
                                      item.confirmedTime.start
                                    ).toLocaleString(_vm.DateTime.DATETIME_MED)
                                )
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.meetingName",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "span",
                            {
                              class:
                                "" +
                                (item.meetingStatus === "Cancelled"
                                  ? "cancelled"
                                  : "")
                            },
                            [_vm._v(_vm._s(item.meetingName))]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.contact",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "span",
                            {
                              class:
                                "" +
                                (item.meetingStatus === "Cancelled"
                                  ? "cancelled"
                                  : "")
                            },
                            [
                              _vm._v(
                                _vm._s(item.formData.firstName) +
                                  " " +
                                  _vm._s(item.formData.lastName)
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.action",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("meeting-action-menu", {
                            attrs: { "hide-duplicate": true },
                            on: {
                              view: function($event) {
                                return _vm.openScheduledMeeting(item.id)
                              },
                              delete: function($event) {
                                return _vm.confirmDelete(item)
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [
                          !_vm.scheduledMeetings.length
                            ? _c("div", [
                                _vm._v(
                                  " There aren't any meetings scheduled yet. "
                                )
                              ])
                            : _c("div", [
                                _vm._v(
                                  " There are no meetings that match the filter criteria. "
                                )
                              ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3217371774
                )
              })
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }