var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardWrapper" }, [
    _vm.deliverable.emptyCard
      ? _c(
          "div",
          {
            staticClass: "blankCard mx-0 my-2 pl-4 pr-0 pt-2",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("add-new-empty")
              }
            }
          },
          [
            _c("v-icon", { attrs: { size: "34" } }, [_vm._v("$duplicate")]),
            _c("span", { staticClass: "font-grey" }, [
              _vm._v(_vm._s(_vm.$t("projects.add-first-deliverable")))
            ])
          ],
          1
        )
      : _c("div", { staticClass: "card pa-4 mb-2" }, [
          _c(
            "div",
            {
              staticClass: "column-format flex-grow-1",
              staticStyle: { "justify-content": "space-between" }
            },
            [
              _c(
                "div",
                { staticClass: "row-format align-center" },
                [
                  _vm.deliverable.client
                    ? _c("client-avatar", {
                        attrs: { small: "", client: _vm.deliverable.client }
                      })
                    : _c("v-icon", { attrs: { color: "gray_30" } }, [
                        _vm._v("check_box_outline_blank")
                      ]),
                  _c(
                    "div",
                    {
                      staticClass: "font-12 column-format text-truncate",
                      staticStyle: { "margin-left": "6px" }
                    },
                    [
                      _c("div", { staticClass: "brand-bold" }, [
                        _vm._v(
                          _vm._s(
                            _vm.deliverable.client
                              ? _vm.deliverable.client.name
                              : ""
                          )
                        )
                      ]),
                      _vm.projectName
                        ? _c("div", { staticClass: "mt-n1" }, [
                            _vm._v(_vm._s(_vm.projectName))
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm.deliverable.invoiceId
                    ? _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                content:
                                  "Included in invoice " +
                                  _vm.deliverable.invoiceNumber
                              },
                              expression:
                                "{ content: `Included in invoice ${deliverable.invoiceNumber}` }"
                            }
                          ],
                          staticClass: "ml-auto",
                          attrs: { color: "success", small: "" }
                        },
                        [_vm._v("attach_money")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "font-14" },
                [
                  _c("div", [_vm._v(_vm._s(_vm.deliverableName))]),
                  _vm._l(_vm.customFields, function(field) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.getCustomValue(field.id),
                            expression: "getCustomValue(field.id)"
                          }
                        ],
                        key: field.id,
                        staticClass: "font-12 font-gray_80"
                      },
                      [
                        _vm._v(
                          _vm._s(field.name) +
                            ": " +
                            _vm._s(_vm.getCustomValue(field.id))
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "row-format align-center",
                  staticStyle: { gap: "8px" }
                },
                [
                  _vm.deliverable.dueDate || _vm.deliverable.startDate
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "font-12 font-grey row-format align-center"
                        },
                        [_vm._v(" " + _vm._s(_vm.timeline) + " ")]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "row-format align-center" }, [
                    _vm.deliverable.tasks.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "font-12 font-grey pointer row-format align-center",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm.showTasks = !_vm.showTasks
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { size: "16" } }, [
                              _vm._v("check_circle_outline")
                            ]),
                            _c("div", { staticClass: "ml-1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getTaskCountLabel(_vm.deliverable.tasks)
                                )
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.deliverable.tasks.length === 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "font-12 font-grey pointer row-format align-center",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.addFirstTask()
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { size: "16" } }, [
                              _vm._v("check_circle_outline")
                            ]),
                            _c("v-icon", { attrs: { size: "16" } }, [
                              _vm._v("add")
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-auto row-format align-center gap-2" },
                    [
                      _vm.deliverable.archived
                        ? _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { content: "Archived" },
                                  expression: "{ content: 'Archived' }"
                                }
                              ],
                              attrs: { size: "20", color: "gray_70" }
                            },
                            [_vm._v("lock")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "ml-auto row-format align-center" },
                        _vm._l(_vm.deliverable.assignedToList, function(
                          assignedTo,
                          index
                        ) {
                          return _c("assigned-user", {
                            key: assignedTo,
                            style:
                              (index > 0 ? "margin-left: -6px" : "") +
                              "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                              index,
                            attrs: {
                              "show-name": false,
                              small: true,
                              "assigned-to": assignedTo
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTasks && _vm.deliverable.tasks.length > 0,
                  expression: "showTasks && deliverable.tasks.length > 0"
                }
              ],
              staticClass: "mt-3"
            },
            [
              _c("tasks", {
                ref: "tasks",
                on: {
                  empty: function($event) {
                    _vm.showTasks = false
                  },
                  change: function($event) {
                    return _vm.updateTasks($event)
                  }
                },
                model: {
                  value: _vm.deliverable.tasks,
                  callback: function($$v) {
                    _vm.$set(_vm.deliverable, "tasks", $$v)
                  },
                  expression: "deliverable.tasks"
                }
              })
            ],
            1
          )
        ]),
    _vm.deliverable === 12
      ? _c("div", { staticClass: "card mb-2" }, [
          _c("div", { staticClass: "card-wrapper" }, [
            _c(
              "div",
              { staticClass: "row-format mb-2" },
              [
                _vm.highlightColor
                  ? _c("div", { style: _vm.highlightColor })
                  : _vm._e(),
                _vm.deliverable.project
                  ? _c(
                      "div",
                      {
                        staticClass: "project-name ellipsis",
                        style:
                          "" +
                          (_vm.highlightColor
                            ? "border-left: 0px; border-top-left-radius: 0px; border-bottom-left-radius: 0px"
                            : "")
                      },
                      [_vm._v(" " + _vm._s(_vm.deliverable.project.name) + " ")]
                    )
                  : _vm._e(),
                _vm.deliverable.invoiceId
                  ? _c(
                      "v-icon",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: {
                              content:
                                "Invoice: " + _vm.deliverable.invoiceNumber
                            },
                            expression:
                              "{ content: `Invoice: ${deliverable.invoiceNumber}` }"
                          }
                        ],
                        staticClass: "ml-auto",
                        attrs: { small: "", color: "success" }
                      },
                      [_vm._v("attach_money")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "deliverable-name mb-2 row-format" }, [
              _c("div", [_vm._v(_vm._s(_vm.deliverable.name))]),
              _vm.showStatus
                ? _c(
                    "div",
                    { staticClass: "ml-auto row-format align-center" },
                    [
                      _vm.showStatus
                        ? _c("div", [_vm._v(_vm._s(_vm.status.label))])
                        : _vm._e(),
                      _c("div", {
                        staticClass: "ml-2",
                        style:
                          "width:20px; height: 20px; border-radius: 4px; background-color: " +
                          _vm.status.hexColor
                      })
                    ]
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _vm.deliverable.client
                  ? _c("client-avatar", {
                      attrs: { client: _vm.deliverable.client, small: "" }
                    })
                  : _vm._e(),
                _c("assigned-user", {
                  staticClass: "ml-2",
                  attrs: {
                    "show-name": false,
                    "assigned-to": _vm.deliverable.assignedTo
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "row-format align-center",
                    staticStyle: { "margin-left": "auto" }
                  },
                  [
                    _vm.deliverable.dueDate
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "font-12 font-grey row-format align-center"
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mb-1", attrs: { size: "12" } },
                              [_vm._v("event")]
                            ),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DateTime.fromISO(
                                      _vm.deliverable.dueDate
                                    ).toLocaleString({
                                      month: "short",
                                      day: "numeric"
                                    })
                                  ) +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }