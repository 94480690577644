var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        {
          staticClass: "fill-all",
          staticStyle: { "min-height": "calc(var(--vh) - 100px)" }
        },
        [
          _c("div", { staticClass: "column-format centered fill-height" }, [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass: "row-format mt-auto pa-12 gap-8",
                staticStyle: {
                  width: "100%",
                  background: "var(--v-gray_5-base)",
                  border: "1px solid var(--v-gray_30-base)",
                  "border-radius": "4px"
                }
              },
              [
                _c("div", { staticClass: "text-left column-format gap-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.openHelpCenter()
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-primary" },
                        [
                          _vm._v(" Moxie Help Center "),
                          _c("v-icon", { attrs: { color: "primary" } }, [
                            _vm._v("$questionMark")
                          ])
                        ],
                        1
                      ),
                      _c("div", [
                        _vm._v(
                          "Learn and master Moxie with advice from the team"
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.openWindow(
                            "https://www.facebook.com/groups/withmoxie"
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-primary" },
                        [
                          _vm._v(" Join the conversation! "),
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("group")]
                          )
                        ],
                        1
                      ),
                      _c("div", [
                        _vm._v(
                          "Connect with other users in the official Moxie Community. Get tips, tricks, and stay up to date on the latest Moxie info."
                        )
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "column-format text-left gap-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.openWindow(
                            "https://www.withmoxie.com/guides"
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-primary" },
                        [
                          _vm._v(" Guides for freelancers "),
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("book")]
                          )
                        ],
                        1
                      ),
                      _c("div", [
                        _vm._v(
                          "Land jobs, build your brand, run your business like a pro"
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.openWindow(
                            "https://www.withmoxie.com/academy"
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-primary" },
                        [
                          _vm._v(" Moxie Academy "),
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "primary" } },
                            [_vm._v("school")]
                          )
                        ],
                        1
                      ),
                      _c("div", [
                        _vm._v(
                          "Micro modules created for freelancers just like you to level up your freelance game."
                        )
                      ])
                    ]
                  )
                ])
              ]
            )
          ]),
          _vm.celebrationDialogue
            ? _c(
                "modal",
                {
                  attrs: {
                    dialog: _vm.celebrationDialogue,
                    "max-width": 600,
                    persistent: false,
                    "close-on-escape": true
                  },
                  on: {
                    close: function($event) {
                      _vm.celebrationDialogue = false
                    }
                  }
                },
                [
                  _c("v-img", {
                    staticClass: "mt-3",
                    staticStyle: { margin: "0 auto" },
                    attrs: {
                      "lazy-src": "/images/logo/brain-splash-small.png",
                      "max-width": "40px",
                      "max-height": "40px",
                      src: "/images/logo/brain-splash-small.png"
                    }
                  }),
                  _c("h3", { staticClass: "mt-4 mb-4" }, [
                    _vm._v("Thanks for upgrading!")
                  ]),
                  _c(
                    "p",
                    { staticStyle: { "max-width": "350px", margin: "0 auto" } },
                    [
                      _vm._v(
                        " Look at you, adding clients and making your freelance dreams come true. Congratulations from all of us at Moxie. "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-11" },
                    [
                      _c("v-img", {
                        staticClass: "mb-2",
                        staticStyle: { "border-radius": "4px" },
                        attrs: {
                          "lazy-src":
                            "/images/dashboard/upgrade-modal-party.jpg",
                          src: "/images/dashboard/upgrade-modal-party.jpg"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "column-format centered mb-4 flex-grow-1" },
      [
        _c("div", { staticStyle: { "max-width": "530px" } }, [
          _c(
            "div",
            {
              staticClass: "brand-medium stratos-medium",
              staticStyle: {
                color: "var(--v-secondary-base)",
                "font-size": "40px"
              }
            },
            [_vm._v("Welcome to Moxie!")]
          ),
          _c("div", { staticClass: "brand-semilight mt-3 mb-5" }, [
            _vm._v(
              " Congratulations on taking the first step in transforming the way you manage and grow your freelance business; Moxie is here to help you every step of the way from overwhelmed and chaotic to in control and productive. "
            )
          ])
        ]),
        _c("div", [
          _c("img", {
            attrs: { src: "/images/onboarding/moxie-user.svg", width: "300" }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }