import GoogleEventDetail from '@/modules/calendar/details/GoogleEventDetail';
import AppleEventDetail from '@/modules/calendar/details/AppleEventDetail';
import ProjectEdit from '@/modules/projects/detail/ProjectEdit';
import chroma from 'chroma-js';
import MicrosoftEventDetail from "@/modules/calendar/details/MicrosoftEventDetail";
import CalDavEventDetail from "@/modules/calendar/details/CalDavEventDetail";

export default {
	data: function() {
		return {
			currentView: 'Month',
		}
	},

	methods: {
		getEventColor: function (event) {
			let darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

			if (event.original.eventSource === 'INVOICE') {
				if (event.original.meta.pastDue) {
					return 'var(--v-warning_5-base)';
				} else {
					return 'var(--v-gray_10-base)';
				}
			}else if(event.original.eventSource === 'TODO'){
				return 'var(--v-blue_10-base)';
			} else if (event.original.eventSource === 'PROPOSAL') {
				return 'var(--v-gray_20-base)';
			} else if (event.original.eventSource === 'PAYMENT' || event.original.eventSource === 'PROPOSAL_SIGNED') {
				return 'var(--v-success_10-base)';
			} else if (event.original.eventSource === 'GOOGLE_CALENDAR') {
				if(event.original.meta.color){
					if(darkMode){
						return event.original.meta.color;
					}else {
						return chroma(event.original.meta.color).darken().saturate(2).hex();
					}
				}else{
					return 'var(--v-blue_50-base)';
				}
			} else if (event.original.eventSource === 'NATIVE') {
				return 'var(--v-blue_50-base)';
			} else if (event.original.eventSource === 'APPLE_CALENDAR' || event.original.eventSource === 'CALDAV_CALENDAR') {
				return 'var(--v-blue_50-base)';
			} else if (event.original.eventSource === 'MICROSOFT_CALENDAR') {
				if(event.original.meta.color){
					return event.original.meta.color;
				}else{
					return 'var(--v-blue_50-base)';
				}
			} else if (event.original.eventSource === 'SCHEDULED_MEETING') {
				return 'var(--v-blue_50-base)';
			} else if (event.original.eventSource === 'DELIVERABLE') {
				if(this.currentView === 'Schedule') {
					return 'var(--v-white-base)';
				}else{
					return 'var(--v-gray_10-base)';
				}
			} else if (event.original.eventSource === 'PROJECT') {
				if(event.original.meta.project.hexColor && event.original.meta.project.hexColor != '#FFFFFF00'){
					if(darkMode){
						return chroma(event.original.meta.project.hexColor).darken(2).saturate(2).hex();
					}else {
						return event.original.meta.project.hexColor
					}
				}else {
					return 'var(--v-blue_10-base)';
				}
			} else if (event.original.eventSource === 'TIMER_EVENT' || event.original.eventSource === 'AGGREGATE_TIMER') {
				return 'var(--v-gray_10-base)';
			}
		},

		getEventSort: function (event) {
			if (event.original.eventSource === 'TODO') {
				return 0.9;
			} else if (event.original.eventSource === 'AGGREGATE_TIMER') {
				return 0.9;
			} else if (event.original.eventSource === 'INVOICE') {
				return 1.3;
			} else if (event.original.eventSource === 'PAYMENT') {
				return 1.2;
			} else if (event.original.eventSource === 'PROPOSAL' || event.original.eventSource === 'PROPOSAL_SIGNED') {
				return 1.5;
			} else if (event.original.eventSource === 'GOOGLE_CALENDAR') {
				return 1.0;
			} else if (event.original.eventSource === 'APPLE_CALENDAR' || event.original.eventSource === 'CALDAV_CALENDAR') {
				return 1.1;
			} else if (event.original.eventSource === 'NATIVE') {
				return 1.2;
			} else if (event.original.eventSource === 'MICROSOFT_CALENDAR') {
				return 1.3;
			} else if (event.original.eventSource === 'DELIVERABLE') {
				return 1.7;
			} else if (event.original.eventSource === 'DELIVERABLE') {
				return 1.6;
			} else if (event.original.eventSource === 'TIMER_EVENT') {
				return 1.4;
			}
		},

		getComponentName: function (event) {
			if (event.original.eventSource === 'TODO') {
				return 'ToDoEvent';
			} else if (event.original.eventSource === 'GOOGLE_CALENDAR') {
				return 'GoogleCalendarEvent';
			} else if (event.original.eventSource === 'APPLE_CALENDAR') {
				return 'AppleCalendarEvent';
			} else if (event.original.eventSource === 'CALDAV_CALENDAR') {
				return 'CalDavEvent';
			} else if (event.original.eventSource === 'MICROSOFT_CALENDAR') {
				return 'MicrosoftCalendarEvent';
			} else if (event.original.eventSource === 'DELIVERABLE') {
				return 'DeliverableEvent';
			} else if (event.original.eventSource === 'PROJECT') {
				return 'ProjectEvent';
			} else if (event.original.eventSource === 'NATIVE') {
				return 'NativeCalendarEvent';
			} else if (event.original.eventSource === 'TIMER_EVENT') {
				return 'TimerEvent';
			} else if (event.original.eventSource === 'AGGREGATE_TIMER') {
				return 'AggregateTimer';
			} else if (event.original.eventSource === 'INVOICE') {
				return 'InvoiceEvent';
			} else if (event.original.eventSource === 'PAYMENT') {
				return 'PaymentEvent';
			} else if (event.original.eventSource === 'PROPOSAL') {
				return 'ProposalEvent';
			} else if (event.original.eventSource === 'PROPOSAL_SIGNED') {
				return 'ProposalSignedEvent';
			} else if (event.original.eventSource === 'CURRENT_TIME') {
				return 'CurrentTimeEvent';
			} else if (event.original.eventSource === 'SCHEDULED_MEETING') {
				return 'ScheduledMeetingEvent';
			} else if(event.original.eventSource === 'TICKET') {
				return 'TicketEvent';
			}
		},

		projectClicked(project, events) {
			this.$store.state.globalModalController.openModal(ProjectEdit, { projectId: project.id }).then((p) => {
				if (!p) return;
				let ix = events.findIndex((e) => e.eventSource === 'PROJECT' && e.id === p.id);
				if (ix > -1) {
					if (p.deleted || (!p.startDate && !p.dueDate)) {
						events.splice(ix, 1);
					} else {
						p.client = project.client;
						let start, end;

						if (p.startDate) {
							start = p.startDate;
						} else {
							start = p.dueDate;
						}

						if (p.dueDate) {
							end = p.dueDate;
						} else {
							end = p.startDate;
						}

						let updated = {
							id: p.id,
							eventSource: 'PROJECT',
							summary: p.name,
							description: null,
							start: start,
							end: end,
							dateOnly: true,
							meta: {
								clientId: p.clientId,
								client: p.client,
								project: p,
								id: p.id,
							},
						};
						events.splice(ix, 1, updated);
						this.refreshKey++;
					}
				}
			});
		},

		googleCalendarEventClicked(source, event) {
			let binding = {
				event: event,
			};
			this.$store.state.globalModalController.openModal(GoogleEventDetail, binding, true, false).then((res) => {
				if (!res) return;
				console.log('google calendar event updated', res);
			});
		},

		appleCalendarEventClicked(source, event) {
			let binding = {
				event: event,
			};
			this.$store.state.globalModalController.openModal(AppleEventDetail, binding, true, false).then((res) => {
				if (!res) return;
				console.log('apple calendar event updated', res);
			});
		},

		calDavEventClicked(source, event) {
			let binding = {
				event: event,
			};
			this.$store.state.globalModalController.openModal(CalDavEventDetail, binding, true, false).then((res) => {
				if (!res) return;
				console.log('caldav calendar event updated', res);
			});
		},

		microsoftCalendarEventClicked(source,event){
			let binding = {
				event: event,
			};
			this.$store.state.globalModalController.openModal(MicrosoftEventDetail, binding, true, false).then((res) => {
				if (!res) return;
				console.log('apple calendar event updated', res);
			});
		}
	},

	computed: {},
};
