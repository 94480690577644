<template>
	<v-container
		v-if="ourTicket"
		class="font-14"
		fluid
		@mouseenter="hovering = true"
		@mouseleave="hovering = false"
		@click="$emit('click')"
	>
		<v-row dense align="center">
			<v-col cols="3" align="left" class="row-format align-center">
				<div v-if="!clientId">
					<client-avatar v-for="client in clients" :key="client.id" :client="client" small class="mr-1"></client-avatar>
				</div>
				<div :class="` column-format  ${clients.length && !clientId ? 'ml-3' : ''}`">
					<div class="text-truncate">{{ contact ? contact.fullName : '' }}</div>
					<div class="font-12">
						<span v-if="!ourTicket.open" class="closed">Closed</span
						><span class="status" v-else>{{ ourTicket.status }}</span>
						<span v-if="ourTicket.ticketType"> - {{ ourTicket.ticketType.type }}</span>
					</div>
					<div class="font-12" v-if="ourTicket.dueDate">Due: {{DateTime.fromISO(ourTicket.dueDate).toLocaleString(DateTime.DATE_MED)}}</div>
				</div>
			</v-col>
			<v-col cols="7" align="left" class="row-format align-center">
				<div :class="`mx-2  ${ourTicket.unread ? 'brand-bold' : ''}`">
					<div class="row-format align-center">
						<div>{{ ourTicket.subject }}</div>
					</div>
					<div class="brand-semilight font-12">{{ truncate(ourTicket.lastComment, 100) }}</div>
					<div class="row-format">
						<assigned-user
								v-for="(assignedTo, index) in ourTicket.assignedTo"
								:key="assignedTo"
								:show-name="false"
								:small="true"
								:assigned-to="assignedTo"
								:style="
													`${
														index > 0 ? 'margin-left: -6px' : ''
													}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
												"
						></assigned-user>
					</div>

				</div>
			</v-col>
			<v-col cols="2" align="right" class="nowrap">
				<inbox-actions
					v-show="hovering"
					class="ml-auto"
					@return-to-inbox="returnToInbox()"
					@snooze="snooze($event)"
					@archive="archive()"
					@unread="markAsRead()"
					:unread="ourTicket.unread"
					:snooze-options="snoozeOptions"
					:folder="ourFolder"
				></inbox-actions>
				<div v-if="!hovering" style="height:32px">{{ $formatters.formatTimeAgo(communication.date) }}</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import InboxActions from '@/modules/communicator/inbox/InboxActions';
	import TicketMixin from '@/modules/communicator/inbox/tickets/TicketMixin';
	import AssignedUser from "@/components/AssignedUser";
	import HDateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'Ticket',

		props: ['communication', 'snoozeOptions', 'folder', 'clientId'],

		components: { ClientAvatar, InboxActions, AssignedUser },

		mixins: [TicketMixin],

		data: function() {
			return {
				DateTime: HDateTime,
				hovering: false,
			};
		},

		mounted() {
			this.ourTicket = { ...this.communication.payload };
			this.ourFolder = this.folder;
		},

		beforeDestroy() {},

		methods: {
			truncate: function(string, length, end = '...') {
				if (string) {
					return string.length <= length ? string : string.substring(0, length) + end;
				} else {
					return '';
				}
			},
		},

		computed: {
			contact: function() {
				return this.$store.getters.getContactByClientPortalId(this.ourTicket.clientContactId);
			},

			clients: function() {
				let result = [];
				this.communication.clientIds.forEach((c) => {
					let client = this.$store.getters.getClientById(c);
					if (client) {
						result.push(client);
					}
				});
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.closed {
		background-color: var(--v-error-base);
		border-radius: 4px;
		padding: 0px 4px;
		color: var(--v-white-base);
		font-size: 12px;
		font-weight: 500;
	}

	.status {
		background-color: var(--v-accent-base);
		border-radius: 4px;
		padding: 0px 4px;
		color: var(--v-black-base);
		font-size: 12px;
		font-weight: 500;
	}
</style>
