var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.project
    ? _c(
        "div",
        { attrs: { id: "item-hours-modal" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-padding" },
            [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("invoice.details.item-hours-modal.heading")) +
                    " "
                )
              ]),
              _c("div", {
                staticClass: "modal-subtitle",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("invoice.details.item-hours-modal.subheading")
                  )
                }
              }),
              _c("v-treeview", {
                attrs: {
                  "selection-type": "leaf",
                  "open-on-click": "",
                  selectable: "",
                  "selected-color": "primary",
                  "item-children": "items",
                  "item-text": "name",
                  dense: "",
                  "expand-icon": "$chevronDown",
                  items: [_vm.project]
                },
                on: {
                  input: function($event) {
                    return _vm.handleTreeViewChange()
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { class: "item " + item.class },
                            [
                              item.class == "client"
                                ? [
                                    _c("div", { staticClass: "label" }, [
                                      _c("div", { staticClass: "name" }, [
                                        _c("span", [_vm._v(_vm._s(item.name))]),
                                        _c(
                                          "span",
                                          { staticClass: "fee-type" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatFeeType(
                                                  item.feeSchedule.feeType
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm.client.hourlyAmount
                                        ? _c("div", { staticClass: "hours" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getClientSubtotalDisplay(
                                                    item
                                                  )
                                                ) +
                                                " "
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  ]
                                : item.class == "project"
                                ? _c("div", { staticClass: "label" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _c("span", [_vm._v(_vm._s(item.name))])
                                    ]),
                                    _c("div", { staticClass: "hours" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getHourlyProjectSubtotalDisplay(
                                            item
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : item.class
                                ? _c("div", { staticClass: "label" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(" " + _vm._s(item.name) + " ")
                                    ]),
                                    _c("div", { staticClass: "hours" }, [
                                      _vm._v(
                                        _vm._s(item.durationSubtotalDisplay)
                                      )
                                    ])
                                  ])
                                : _c("div", { staticClass: "label" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(
                                        _vm._s(_vm._f("noteFormat")(item.notes))
                                      )
                                    ]),
                                    _c("div", { staticClass: "hours" }, [
                                      _vm._v(_vm._s(item.durationDisplay))
                                    ])
                                  ])
                            ],
                            2
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  855503843
                ),
                model: {
                  value: _vm.invoiceItem.timerEventIds,
                  callback: function($$v) {
                    _vm.$set(_vm.invoiceItem, "timerEventIds", $$v)
                  },
                  expression: "invoiceItem.timerEventIds"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-16 primary-action",
                  on: { click: _vm.handleSave }
                },
                [_vm._v(_vm._s(_vm.$t("global.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }