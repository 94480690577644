<template>
	<div id="invoice-number-custom-format">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title align-center d-flex justify-start">
				<v-icon class="mr-2 gray_90--text pointer" @click="$emit('close')">$arrowLeft</v-icon>
				{{ $t('invoice.invoice-number-custom-format.heading') }}
			</div>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				type="text"
				id="invoice-number-custom-format-input"
				ref="invoice-number-custom-format-input"
				v-model="format"
				:label="$t('invoice.invoice-number-custom-format.format-label')"
				:placeholder="$t('invoice.invoice-number-custom-format.format-placeholder')"
				@keyup.enter="handleSave"
			></v-text-field>

			<div class="brand-semilight font-14 text-left mt-7 mb-5">
				{{ $t('invoice.invoice-number-custom-format.top-text') }}
			</div>
			<!-- prettier-ignore -->
			<div class="pointer legend-grid" v-for="token in tokens" :key="token.token" @click="insertToken(token.token)">
				<div>{{ token.text }}</div>
				<div>
					<span>{{ token.token }}</span>
					<span class="ml-1">{{token.append ? token.append : ''}}</span>
				</div>
			</div>

			<div class="brand-semilight font-14 text-left my-5">
				{{ $t('invoice.invoice-number-custom-format.bottom-text') }}
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" style="width:160px;" @click="$emit('close')">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="super-action ml-1" style="width:160px;" @click="handleSave" :disabled="!formatOk">{{
				$t('global.save')
			}}</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'InvoiceNumberCustomFormat',

		props: { invoiceNumberFormat: { type: String, required: true } },

		components: {},

		data: function() {
			return {
				format: null,
				tokens: [
					{
						token: '{{CI}}',
						text: this.$t('invoice.invoice-number-custom-format.ci-text'),
						append: this.$t('invoice.invoice-number-custom-format.ci-append'),
					},

					{ token: '{{DD}}', text: this.$t('invoice.invoice-number-custom-format.dd-text') },
					{ token: '{{MM}}', text: this.$t('invoice.invoice-number-custom-format.mm-text') },
					{ token: '{{Mon}}', text: this.$t('invoice.invoice-number-custom-format.mon-text') },
					{ token: '{{YY}}', text: this.$t('invoice.invoice-number-custom-format.yy-text') },
					{ token: '{{YYYY}}', text: this.$t('invoice.invoice-number-custom-format.yyyy-text') },
					{ token: '{{Num}}', text: this.$t('invoice.invoice-number-custom-format.num-text') },
					{
						token: '{{Num|4}}',
						text: this.$t('invoice.invoice-number-custom-format.num-padding-text'),
						append: this.$t('invoice.invoice-number-custom-format.num-padding-append'),
					},
				],
			};
		},

		mounted() {
			this.format = this.invoiceNumberFormat;
		},

		beforeDestroy() {},

		methods: {
			handleSave() {
				this.$emit('save', this.format);
				// this.$emit('result', { action: 'SAVE', value: this.invoiceNumberFormatClone });
				// this.$emit('close');
			},
			getCaretPosition() {
				let el = document.getElementById('invoice-number-custom-format-input');
				let caret = 0;
				if (el.selectionStart || el.selectionStart === 0) {
					caret = el.selectionStart;
				}
				return caret;
			},
			setCaretPosition(pos) {
				let el = document.getElementById('invoice-number-custom-format-input');
				if (el.setSelectionRange) {
					el.focus();
					el.setSelectionRange(pos, pos);
				} else if (el.createTextRange) {
					let range = el.createTextRange();
					range.collapse(true);
					range.moveEnd('character', pos);
					range.moveStart('character', pos);
					range.select();
				}
			},
			insertToken(token) {
				let pos = this.getCaretPosition();
				this.format = this.format.substr(0, pos) + token + this.format.substr(pos);
				this.setCaretPosition(pos + token.length);
			},
		},
		watch: {},

		computed: {
			// To do: add some real validation here
			// Should probably enforce the existence of {{Num}} at a minimum.
			formatOk() {
				if (!this.format) return false;
				return true;
			},
		},
	};
</script>

<style lang="scss">
	#invoice-number-custom-format {
		width: 566px;
		.legend-grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			justify-items: start;
			border-bottom: 1px solid var(--v-gray_50-base);
			> div {
				font-size: 14px;
				height: 32px;
				width: 100%;
				padding: 0 8px;
				display: flex;
				align-items: center;
				border-color: var(--v-gray_50-base);
				border-style: solid;
			}
			> div:nth-child(odd) {
				//border-color: var(--v-gray_50-base);
				border-width: 1px 0 0 1px;
			}
			> div:nth-child(even) {
				//border: 1px solid red;
				//border-color: var(--v-gray_50-base);
				border-width: 1px 1px 0 1px;
			}
		}
	}
</style>
