var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-3 pt-3" },
    [
      _c("v-select", {
        staticStyle: { width: "100%" },
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          label: _vm.$t("recurring.repeat-every"),
          "persistent-placeholder": "",
          items: _vm.defaultOptions,
          "item-value": "value",
          "item-text": "text"
        },
        on: {
          change: function($event) {
            return _vm.recurrenceChanged()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", [_vm._v(_vm._s(item.text))]),
                item.text === "Custom"
                  ? _c("v-icon", { staticClass: "ml-2" }, [_vm._v("tune")])
                  : _vm._e()
              ]
            }
          }
        ]),
        model: {
          value: _vm.recurring.recurrenceType,
          callback: function($$v) {
            _vm.$set(_vm.recurring, "recurrenceType", $$v)
          },
          expression: "recurring.recurrenceType"
        }
      }),
      _vm.recurring.recurrenceType === "CUSTOM"
        ? _c(
            "div",
            { staticClass: "row-format gap-3", staticStyle: { gap: "8px" } },
            [
              _c("v-text-field", {
                staticStyle: { width: "50%" },
                attrs: {
                  type: "number",
                  inputmode: "number",
                  "hide-details": "",
                  label: "Recurs every",
                  dense: "",
                  outlined: "",
                  "persistent-placeholder": ""
                },
                on: {
                  keydown: function($event) {
                    return _vm.handleDurationKeydown($event)
                  }
                },
                model: {
                  value: _vm.recurring.interval.duration,
                  callback: function($$v) {
                    _vm.$set(_vm.recurring.interval, "duration", $$v)
                  },
                  expression: "recurring.interval.duration"
                }
              }),
              _c("v-select", {
                staticStyle: { width: "50%" },
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: _vm.$t("recurring.time-unit"),
                  "persistent-placeholder": "",
                  items: _vm.timeUnits,
                  "item-value": "value",
                  "item-text": "text"
                },
                model: {
                  value: _vm.recurring.interval.timeUnit,
                  callback: function($$v) {
                    _vm.$set(_vm.recurring.interval, "timeUnit", $$v)
                  },
                  expression: "recurring.interval.timeUnit"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.recurring.recurrenceType === "WEEKLY"
        ? _c(
            "div",
            {
              staticClass: "column-format text-left pa-2",
              staticStyle: {
                border: "1px solid var(--v-gray_50-base)",
                "border-radius": "4px",
                position: "relative"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "font-12 font-gray_70",
                  staticStyle: {
                    "background-color": "var(--v-white-base)",
                    position: "absolute",
                    top: "-9px",
                    "padding-left": "2px"
                  }
                },
                [_vm._v("Day of week")]
              ),
              _c("daily-scheduler", {
                model: {
                  value: _vm.recurring.weeklyDays,
                  callback: function($$v) {
                    _vm.$set(_vm.recurring, "weeklyDays", $$v)
                  },
                  expression: "recurring.weeklyDays"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "row-format gap-3", staticStyle: { gap: "8px" } },
        [
          _c("date-selector", {
            staticStyle: { width: "50%" },
            attrs: {
              standard: true,
              label: _vm.$t("recurring.start-date"),
              date: _vm.recurring.startDate
            },
            on: {
              change: function($event) {
                _vm.recurring.startDate = $event
                _vm.recurrenceChanged()
              }
            }
          }),
          _c("date-selector", {
            staticStyle: { width: "50%" },
            attrs: {
              standard: true,
              label: _vm.$t("recurring.end-date"),
              date: _vm.recurring.endDate
            },
            on: {
              change: function($event) {
                _vm.recurring.endDate = $event
                _vm.recurrenceChanged
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "column-format gap-3" },
        [
          _c("div", { staticClass: "text-left font-12 font-gray_70" }, [
            _vm.recurring.enabled
              ? _c(
                  "span",
                  { staticStyle: { "text-transform": "capitalize" } },
                  [
                    _vm._v(
                      "Status: " + _vm._s(_vm.recurring.status.toLowerCase())
                    ),
                    _c("br")
                  ]
                )
              : _c(
                  "span",
                  { staticStyle: { "text-transform": "capitalize" } },
                  [_vm._v("Status: Disabled"), _c("br")]
                ),
            _vm._v(" Last run time: "),
            _vm.recurring.lastRunTime
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.DateTime.fromISO(_vm.recurring.lastRunTime)
                        .setZone(_vm.$store.state.podAccount.timeZone)
                        .toLocaleString(_vm.DateTime.DATETIME_MED_WITH_WEEKDAY)
                    )
                  )
                ])
              : _c("span", [_vm._v("--")]),
            _c("br"),
            _vm._v(" Next run time: "),
            _vm.recurring.nextRunTime
              ? _c(
                  "span",
                  {
                    style: !_vm.recurring.enabled
                      ? "text-decoration: line-through"
                      : ""
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.DateTime.fromISO(_vm.recurring.nextRunTime)
                          .setZone(_vm.$store.state.podAccount.timeZone)
                          .toLocaleString(
                            _vm.DateTime.DATETIME_MED_WITH_WEEKDAY
                          )
                      )
                    )
                  ]
                )
              : _c("span", [_vm._v("--")]),
            _vm.recurring.events && _vm.recurring.events.length
              ? _c(
                  "div",
                  {
                    staticClass: "font-primary pointer",
                    on: { click: _vm.viewHistory }
                  },
                  [
                    _vm._v(" View history "),
                    _c(
                      "v-icon",
                      { attrs: { "x-small": "", color: "primary" } },
                      [_vm._v("history")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("v-checkbox", {
            staticClass: "mt-0",
            attrs: { "hide-details": "", dense: "", label: "Enabled" },
            model: {
              value: _vm.recurring.enabled,
              callback: function($$v) {
                _vm.$set(_vm.recurring, "enabled", $$v)
              },
              expression: "recurring.enabled"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }