<template>
	<v-container fluid class="pa-0 ma-0" style="min-height: 400px">
		<v-row no-gutters>
			<v-col cols="12">
				<tag-user-field
					ref="tagUserField"
					:userList="userList"
					:commentToEdit="null"
					@sendComment="sendComment($event)"
				/>
				<div v-if="deliverable.events.length"
					class="mt-1 mb-2 pointer font-12 text-left"
					style="color: var(--v-gray_70-base)"
					@click="showEvents = !showEvents"
				>
					<v-icon small color="gray_70">
						{{ showEvents ? '$visibilityOff' : '$visibility' }}
					</v-icon>
					<span class="ml-1" >
						{{ showEvents ? $t('global.hide') : $t('global.show') }} {{ $t('projects.activity-stream') }}
					</span>
				</div>
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12">
				<div
					style="word-wrap: anywhere"
					v-for="(comment, index) in sortedActivity"
					:key="comment.id"
					:class="canEdit(comment, index) ? 'can-edit' : ''"
					@mouseover="setHoverIndex(index)"
					@mouseleave="setHoverIndex(null)"
				>
					<div v-if="comment.events && showEvents" class="font-12 text-left my-2 brand-semilight">
						<div>
							<span class="brand-medium">{{ comment.user }}</span> {{ comment.events.join(' and ') }}
						</div>
						<div style="color: var(--v-gray_70-base)">
							<v-icon size="12" color="gray_70">$clock</v-icon>{{ comment.timestamp | formatForTimeAgo }}
						</div>
					</div>

					<div v-if="comment.comment" class="mt-2 comment-box">
						<div class="row-format align-center font-12">
							<client-avatar :disable-click="true" v-if="comment.clientComment && client" :client="client" small></client-avatar>
							<assigned-user v-else :show-name="false" :assigned-to="parseInt(comment.authorId, 10)"></assigned-user>
							<div class="ml-3">
								{{ comment.author }}
							</div>
							<v-icon size="14" class="ml-3 mr-1">$clock</v-icon>
							<div style="color: var(--v-gray_70-base)">
								{{ comment.timestamp | formatForTimeAgo }}
							</div>
							<div v-if="canEdit(comment, index)" style="margin-left: auto">
								<v-icon small @click.native="editComment(comment, index)" class="pointer mr-2"
									>edit</v-icon
								>
								<v-icon small @click.native="confirmDelete(comment)" class="pointer">delete</v-icon>
							</div>
						</div>
						<div
							v-if="editIndex !== index"
							class="pl-9 font-14 comment"
							style="width: 100%; text-align: left;"
							v-html="formatMarkdown(comment.comment)"
						/>
						<div v-if="editIndex === index" class="comment pl-9" style="width: 100%; text-align: left;">
							<tag-user-field
								:userList="userList"
								:commentToEdit="commentToEdit"
								@sendComment="sendComment($event)"
								@saveEdit="saveEdit($event)"
								@cancelEdit="cancelEdit($event)"
							/>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>

		<confirm-dialog
			:dialog="confirmDeleteDialog"
			@close="cancelDelete()"
			@confirm="deleteComment()"
			:heading-text="$t('global.confirm')"
			yes-text="Delete"
			no-text="Cancel"
			:body-text="$t('projects.delete-note')"
		/>
	</v-container>
</template>

<script>
	import ProjectDeliverableService from './ProjectDeliverableService';
	import TagUserField from './TagUserField';
	import marked from 'marked';
	// import modal from '@bit/hecticapp.common.modal';
	import AssignedUser from '@/components/AssignedUser';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'Activity',

		props: ['client','project', 'deliverable', 'renderIndex', 'userList'],

		components: {
			ClientAvatar,
			AssignedUser,
			TagUserField,
			ConfirmDialog,
		},

		data: function() {
			return {
				hoverIndex: null,
				editIndex: null,
				commentToEdit: null,
				commentToDelete: null,
				confirmDeleteDialog: false,
				showEvents: false,
				deliverableService: new ProjectDeliverableService(),
			};
		},

		mounted() {
			console.log('rendering');
		},

		methods: {
			checkCommentBeforeSave: function() {
				return new Promise((resolve, reject) => {
					let pendingComment = this.$refs.tagUserField.getPendingComment();
					if (pendingComment && pendingComment.length > 0) {
						this.sendComment(pendingComment)
							.then(() => resolve(true))
							.catch((err) => reject(err));
					} else {
						resolve(false);
					}
				});
			},

			sendComment: function(comment) {
				return this.deliverableService.sendComment(this.deliverable.id, {
					comment: comment,
					privateComment: false,
					sendEmail: false,
				})
			},

			confirmDelete: function(comment) {
				this.commentToDelete = comment;
				this.confirmDeleteDialog = true;
			},

			cancelDelete: function() {
				this.commentToDelete = null;
				this.confirmDeleteDialog = false;
			},

			deleteComment: function() {
				this.deliverableService.deleteComment(this.deliverable.id, this.commentToDelete.id).then(() => {
					this.commentToDelete = null;
					this.confirmDeleteDialog = false;
				});
			},

			editComment: function(comment, index) {
				this.commentToEdit = Object.assign({}, comment);
				this.editIndex = index;
			},

			cancelEdit: function() {
				this.commentToEdit = null;
				this.editIndex = null;
			},

			saveEdit: function(comment) {
				this.deliverableService.updateComment(this.deliverable.id, comment).then(() => {
					let ix = this.deliverable.comments.findIndex((c) => c.id === comment.id);
					if (ix > -1) {
						this.deliverable.comments.splice(ix, 1, comment);
					} else {
						this.deliverable.comments.push(comment);
					}
					this.editIndex = null;
					this.commentToEdit = null;
				});
			},

			canEdit: function(comment, index) {
				if (
					this.editIndex === null &&
						(comment.authorId == this.$store.state.loggedInUser.userId || this.$store.getters.isAccountOwner) &&
					index === this.hoverIndex
				) {
					return true;
				} else {
					return false;
				}
			},

			setHoverIndex: function(index) {
				this.hoverIndex = index;
			},

			compareDates: function(a, b) {
				if (a.timestamp > b.timestamp) {
					return -1;
				} else if (a.timestamp < b.timestamp) {
					return 1;
				} else {
					return 0;
				}
			},

			formatMarkdown: function(comment) {
				this.userList.forEach((u) => {
					comment = comment.replaceAll(u.email, `[${this.getName(u)}](mailto://${u.email})`);
				});

				let m = marked(comment, { breaks: true });
				m = m.replaceAll('<a href', '<a target="_blank" href');
				return m;
			},

			getName: function(user) {
				let name = '';

				if (user.firstName) {
					name += user.firstName + ' ';
				}

				if (user.lastName) {
					name += user.lastName;
				}

				if (name === '') {
					name = user.email;
				}

				return name;
			},
		},

		computed: {
			sortedActivity: function() {
				let activity = [...this.deliverable.comments, ...this.deliverable.events];
				activity.sort(this.compareDates);
				return activity;
			},
		},

		watch: {},
	};
</script>

<style scoped lang="scss">
	.comment-box {
		padding: 8px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 4px;
	}

	.can-edit {
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}

	//.comment {
	//	a {
	//		word-wrap: anywhere;
	//		word-break: break-all;
	//		overflow-wrap: break-word;
	//	}
	//}

	.comment ::v-deep p {
		padding: 0;
		margin: 0;

	}

	.iconBlock {
		position: relative;
	}
</style>
