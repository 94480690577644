var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "agreement-status-menu" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "secondary-action activator-button",
          on: {
            click: function($event) {
              return _vm.openMenu()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "font-12 d-flex align-center" },
            [
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("info")]),
              _c("div", { staticClass: "ml-2" }, [
                _vm._v(_vm._s(_vm.lastEvent))
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.menu, persistent: false },
          on: {
            close: function($event) {
              return _vm.closeMenu()
            }
          }
        },
        [
          _c(
            "div",
            { attrs: { id: "agreement-status-modal" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-dialog",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeMenu()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("$close")])],
                1
              ),
              _c("div", { staticClass: "pa-4" }, [
                _c("div", { staticClass: "modal-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("proposal.activity")) + " ")
                ]),
                _c(
                  "div",
                  { attrs: { id: "events-wrapper" } },
                  _vm._l(_vm.events, function(item) {
                    return _c(
                      "div",
                      { key: item.timestamp, staticClass: "event-wrapper" },
                      [
                        _c(
                          "div",
                          { staticClass: "timestamp" },
                          [
                            _c("v-icon", { attrs: { size: "12" } }, [
                              _vm._v("$clock")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatForTimeAgo")(item.timestamp)
                                ) +
                                " "
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "event" }, [
                          _vm._v(_vm._s(item.event))
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }