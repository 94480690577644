var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format flex-grow-1" },
    [
      _vm.notes.length
        ? _c(
            "v-container",
            {
              staticClass: "ma-0 pa-0",
              staticStyle: {
                "background-color": "var(--v-white-base)",
                border: "1px solid var(--v-gray_30-base)",
                "border-radius": "4px"
              },
              attrs: { fluid: "" }
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                _vm._l(_vm.filteredNotes, function(note, index) {
                  return _c(
                    "v-col",
                    {
                      key: note.id,
                      staticClass: "edit-note py-2",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row-format align-center gap-2 pointer px-3",
                          on: {
                            click: function($event) {
                              note.expanded = !note.expanded
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "font-12" }, [
                            _vm._v(_vm._s(note.author))
                          ]),
                          _c(
                            "v-icon",
                            { attrs: { size: "12", color: "gray_70" } },
                            [_vm._v("$clock")]
                          ),
                          _c("div", { staticClass: "font-gray_70 font-12" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatForTimeAgo")(
                                    note.timestampUpdated
                                      ? note.timestampUpdated
                                      : note.timestamp
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "material-symbols-rounded",
                              attrs: { color: "gray_70" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  note.expanded
                                    ? "keyboard_arrow_up"
                                    : "keyboard_arrow_down"
                                )
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto pointer delete-button" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "material-symbols-rounded",
                                  attrs: { size: "20", color: "gray_90" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.confirmDeleteNote(note)
                                    }
                                  }
                                },
                                [_vm._v("delete")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.toggleNoteExpansion(note)
                            }
                          }
                        },
                        [
                          note.id !== _vm.activeNote
                            ? _c(
                                "div",
                                {
                                  staticClass: "note-wrapper pointer pa-3",
                                  style:
                                    index < _vm.filteredNotes.length - 1
                                      ? "border-bottom: 1px solid var(--v-gray_30-base)"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.setActive(note)
                                    }
                                  }
                                },
                                [
                                  _c("div", {
                                    class: note.expanded ? "" : "collapsed",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        note.entry === ""
                                          ? "Enter a note..."
                                          : note.entry
                                      )
                                    }
                                  })
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "text-left",
                                  attrs: { autofocus: "" }
                                },
                                [
                                  _c("editor", {
                                    ref: "editor" + note.id,
                                    refInFor: true,
                                    staticClass: "no-wrap-editor",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "api-key":
                                        _vm.$store.getters.getTinyMceKey,
                                      inline: false,
                                      init: _vm.mceConfig(note)
                                    },
                                    on: {
                                      onblur: function($event) {
                                        return _vm.saveNote(note)
                                      }
                                    },
                                    model: {
                                      value: note.entry,
                                      callback: function($$v) {
                                        _vm.$set(note, "entry", $$v)
                                      },
                                      expression: "note.entry"
                                    }
                                  })
                                ],
                                1
                              )
                        ]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.notes.length === 0
        ? _c(
            "div",
            {
              staticClass: "flex-grow-1 column-format centered",
              staticStyle: { "min-height": "calc(100vh - 300px)" }
            },
            [
              _c("empty-view", {
                attrs: {
                  header: "No notes yet",
                  body:
                    "You haven’t added any notes for " +
                    _vm.client.name +
                    " yet. Notes can help you keep track of important details and interactions.",
                  cta: "Add a note",
                  "video-header": "",
                  "video-body": "",
                  "video-cta": "",
                  "video-id": ""
                },
                on: {
                  "cta-clicked": function($event) {
                    return _vm.addNewNote()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.confirmDeleteDialog
        ? _c("confirm-dialog", {
            attrs: {
              dialog: _vm.confirmDeleteDialog,
              "heading-text": "Delete?",
              "body-text": "Are you sure you want to delete this note?"
            },
            on: { close: _vm.cancelDeleteNote, confirm: _vm.deleteNote }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }