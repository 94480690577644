<template>
	<div id="current-time-event" v-if="view === 'Day' || view === 'Week'">
		<div class="time-line-wrapper">
			<div class="current-time-circle"></div>
			<div class="current-time-line"></div>
		</div>
		<div class="current-time text-left">{{ formattedTime }}</div>
	</div>
</template>

<script>
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'CurrentTimeEvent',

		props: ['event', 'eventParsed', 'timed', 'view', 'inMoreView'],

		components: {},

		data: function() {
			return {
				// nativeEvent: null,
				// isReady: false,
				// orig: this.event.original,
			};
		},

		mounted() {
			// console.log(this.event);
		},

		beforeDestroy() {},

		methods: {},

		computed: {
			formattedTime() {
				return DateTime.fromISO(this.event.original.start).toLocaleString(DateTime.TIME_SIMPLE);
			},
		},
	};
</script>

<style lang="scss">
	#current-time-event {
		border: none;
		z-index: 200 !important;
		.time-line-wrapper {
			//border: 1px solid red;
			//margin-top: -4px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			//.current-time-circle {
			//	flex: 0 0 4px;
			//	height: 4px;
			//	width: 4px;
			//	border-radius: 2px;
			//	z-index: 200;
			//	background-color: var(--v-alert_50-base);
			//	//background-color: var(--v-primary-base);
			//}
			.current-time-line {
				flex: 1 1 auto;
				height: 1px;
				//background-color: var(--v-primary-base);
				background-color: var(--v-alert_50-base);
			}
		}
		position: relative;
		.current-time {
			overflow: visible !important;
			position: absolute;
			left: 8px;
			top: 3px;
			height: 36px !important;
			color: var(--v-black-base);
		}
	}
</style>
