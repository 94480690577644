var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customPage
    ? _c(
        "div",
        {
          staticClass: "fill-all",
          attrs: {
            id: _vm.isInternal
              ? "custom-page-editor-internal"
              : "custom-page-editor-external"
          }
        },
        [
          _c(
            "div",
            { staticClass: "header-area pl-1 pr-3 row-format align-center" },
            [
              _c(
                "div",
                { staticClass: "row-format gap-2 align-center pl-2" },
                [
                  _c("icon-selector", {
                    model: {
                      value: _vm.customPage.icon,
                      callback: function($$v) {
                        _vm.$set(_vm.customPage, "icon", $$v)
                      },
                      expression: "customPage.icon"
                    }
                  }),
                  _c("v-text-field", {
                    staticStyle: { "background-color": "var(--v-white-base)" },
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      maxlength: "20",
                      "persistent-placeholder": "",
                      label: "Title"
                    },
                    model: {
                      value: _vm.customPage.title,
                      callback: function($$v) {
                        _vm.$set(_vm.customPage, "title", $$v)
                      },
                      expression: "customPage.title"
                    }
                  }),
                  _vm.isInternal
                    ? _c(
                        "div",
                        { staticStyle: { "max-width": "275px" } },
                        [
                          _c("v-select", {
                            staticStyle: {
                              "background-color": "var(--v-white-base)"
                            },
                            attrs: {
                              items: _vm.layoutOptions,
                              "item-text": "label",
                              "item-value": "key",
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": "",
                              label: "Screen layout"
                            },
                            model: {
                              value: _vm.customPage.layoutKey,
                              callback: function($$v) {
                                _vm.$set(_vm.customPage, "layoutKey", $$v)
                              },
                              expression: "customPage.layoutKey"
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticStyle: { "max-width": "275px" } },
                        [
                          _c("v-select", {
                            staticStyle: {
                              "background-color": "var(--v-white-base)"
                            },
                            attrs: {
                              items: ["External", "iFrame"],
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": "",
                              label: "Link type"
                            },
                            model: {
                              value: _vm.customPage.type,
                              callback: function($$v) {
                                _vm.$set(_vm.customPage, "type", $$v)
                              },
                              expression: "customPage.type"
                            }
                          })
                        ],
                        1
                      ),
                  _vm.isInternal
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "close-on-click": true
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                "min-height": "40px"
                                              },
                                              attrs: {
                                                outlined: "",
                                                color: "gray_50"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "var(--v-black-base)"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Visible to " +
                                                    _vm._s(_vm.restrictLabel)
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1018891198
                              ),
                              model: {
                                value: _vm.restrictMenu,
                                callback: function($$v) {
                                  _vm.restrictMenu = $$v
                                },
                                expression: "restrictMenu"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pa-3",
                                  staticStyle: {
                                    "background-color": "var(--v-white-base)",
                                    height: "calc(100vh - 100px)",
                                    "overflow-y": "auto"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secondary-action",
                                      on: {
                                        click: function($event) {
                                          _vm.restrictMenu = false
                                        }
                                      }
                                    },
                                    [_vm._v("OK")]
                                  ),
                                  _vm._l(_vm.$store.getters.clients, function(
                                    client
                                  ) {
                                    return _c("v-checkbox", {
                                      key: client.id,
                                      attrs: {
                                        value: client.id,
                                        "hide-details": "",
                                        dense: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row-format align-center gap-2 ml-n1"
                                                  },
                                                  [
                                                    _c("client-avatar", {
                                                      attrs: {
                                                        client: client,
                                                        "x-small": "",
                                                        "disable-click": true
                                                      }
                                                    }),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(client.name)
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.customPage.restrictToClients,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.customPage,
                                            "restrictToClients",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "customPage.restrictToClients"
                                      }
                                    })
                                  })
                                ],
                                2
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-auto row-format gap-2 align-center" },
                [
                  _c("folder-selector", {
                    attrs: { folders: _vm.folders },
                    model: {
                      value: _vm.customPage.folder,
                      callback: function($$v) {
                        _vm.$set(_vm.customPage, "folder", $$v)
                      },
                      expression: "customPage.folder"
                    }
                  }),
                  _vm.isInternal || _vm.isIframe
                    ? _c("v-switch", {
                        staticStyle: { "margin-top": "0px!important" },
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          label: "Default page"
                        },
                        model: {
                          value: _vm.customPage.isDefault,
                          callback: function($$v) {
                            _vm.$set(_vm.customPage, "isDefault", $$v)
                          },
                          expression: "customPage.isDefault"
                        }
                      })
                    : _vm._e(),
                  _c("v-switch", {
                    staticStyle: { "margin-top": "0px!important" },
                    attrs: { "hide-details": "", dense: "", label: "Enabled" },
                    model: {
                      value: _vm.customPage.enabled,
                      callback: function($$v) {
                        _vm.$set(_vm.customPage, "enabled", $$v)
                      },
                      expression: "customPage.enabled"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      attrs: { width: "96" },
                      on: {
                        click: function($event) {
                          return _vm.save(false)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.save(true)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm.isInternal
            ? _c(
                "div",
                {
                  staticClass: "pa-3",
                  staticStyle: { width: "100%", height: "calc(100% - 56px)" }
                },
                [
                  _c(
                    "v-container",
                    { key: _vm.refreshKey, attrs: { fluid: "" } },
                    _vm._l(_vm.page, function(row, index) {
                      return _c(
                        "v-row",
                        {
                          key: index,
                          style: "font-family: " + _vm.branding.font
                        },
                        _vm._l(row, function(col) {
                          return _c(
                            "v-col",
                            {
                              key: "key" + col.contentIndex,
                              staticStyle: {
                                border: "thin dashed var(--v-gray_30-base)"
                              },
                              attrs: { cols: col.cols },
                              on: {
                                click: function($event) {
                                  return _vm.setEditIndex(col.contentIndex)
                                }
                              }
                            },
                            [
                              _c("content-block", {
                                attrs: {
                                  index: col.contentIndex,
                                  content: _vm.customPage.content,
                                  "edit-mode":
                                    _vm.editIndex === col.contentIndex,
                                  "mce-config": _vm.mceConfig
                                },
                                on: {
                                  updated: function($event) {
                                    return _vm.setContent(
                                      col.contentIndex,
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "pa-3" },
                [
                  _c("v-text-field", {
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      dense: "",
                      outlined: "",
                      label: "Default external link",
                      placeholder: "https://www.domain.com"
                    },
                    model: {
                      value: _vm.customPage.hyperLink,
                      callback: function($$v) {
                        _vm.$set(_vm.customPage, "hyperLink", $$v)
                      },
                      expression: "customPage.hyperLink"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "column-format my-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "row-format gap-2" },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.$store.getters.clients,
                              "hide-details": "",
                              "persistent-placeholder": "",
                              dense: "",
                              outlined: "",
                              label: "Client",
                              "item-value": "id",
                              "item-text": "name"
                            },
                            model: {
                              value: _vm.newClientId,
                              callback: function($$v) {
                                _vm.newClientId = $$v
                              },
                              expression: "newClientId"
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              staticStyle: { "min-height": "40px" },
                              attrs: {
                                color: "primary",
                                outlined: "",
                                disabled: !_vm.newClientId
                              },
                              on: { click: _vm.addClientPage }
                            },
                            [_vm._v("+ Add client specific page")]
                          )
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "my-3",
                        staticStyle: {
                          "border-top": "1px solid var(--v-gray_50-base)",
                          height: "8px"
                        }
                      }),
                      _vm._l(_vm.customPage.clientSpecificHyperLinks, function(
                        clientPage,
                        index
                      ) {
                        return _c(
                          "div",
                          { key: index, staticClass: "row-format my-2 gap-2" },
                          [
                            _c("client-avatar", {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    content: _vm.getClient(clientPage.clientId)
                                      .name
                                  },
                                  expression:
                                    "{content: getClient(clientPage.clientId).name}"
                                }
                              ],
                              attrs: {
                                large: "",
                                client: _vm.getClient(clientPage.clientId)
                              }
                            }),
                            _c("v-text-field", {
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                dense: "",
                                outlined: "",
                                label: "External link",
                                placeholder: "https://www.domain.com"
                              },
                              model: {
                                value: clientPage.hyperLink,
                                callback: function($$v) {
                                  _vm.$set(clientPage, "hyperLink", $$v)
                                },
                                expression: "clientPage.hyperLink"
                              }
                            }),
                            _c(
                              "v-icon",
                              {
                                staticClass: "material-symbols-rounded",
                                attrs: { size: "20" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteClientPage(index)
                                  }
                                }
                              },
                              [_vm._v("delete")]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }