import HttpClient from '@/services/HttpClient';

export default class GoogleService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    connect(authCode) {
        return this.httpClient
            .post(`/google/connect?authCode=${authCode}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getConnections() {
        return this.httpClient
            .get('/google/status')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    update(connection) {
        return this.httpClient
            .put('/google/connect',connection)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getUserList() {
        return this.httpClient
            .get('/google')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    disconnect(email) {
        return this.httpClient
            .post(`/google/disconnect?email=${encodeURIComponent(email)}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}