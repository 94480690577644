var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "primary-layout",
    [
      _c("h-header", {
        attrs: {
          title: _vm.$t("nav.invoicesAndExpenses"),
          "add-new-label": _vm.isPaid
            ? _vm.$t("accounting.new-linked-account")
            : null,
          pages: _vm.pages,
          "current-page": "accounts",
          "border-bottom": true
        },
        on: {
          "add-new": function($event) {
            return _vm.linkNewAccount()
          },
          "set-page": function($event) {
            return _vm.handleSetPage($event)
          }
        }
      }),
      _c(
        "v-container",
        { staticClass: "fill-height align-start", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _vm.items.length > 0
                ? _c(
                    "v-col",
                    { staticClass: "mt-9", attrs: { cols: "12" } },
                    [
                      _c("v-data-table", {
                        attrs: {
                          items: _vm.items,
                          headers: _vm.headers,
                          id: "account-table",
                          "hide-default-footer": true
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.institutionName",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.institutionLogo
                                    ? _c("img", {
                                        attrs: {
                                          src:
                                            "data:image/jpeg;base64," +
                                            item.institutionLogo
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    " " + _vm._s(item.institutionName) + " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.lastUpdate",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.DateTime.fromISO(
                                          item.lastUpdate
                                        ).toLocaleString(
                                          _vm.DateTime.DATETIME_FULL
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.consentExpirationTime",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.consentExpirationTime
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.DateTime.fromISO(
                                              item.lastUpdate
                                            ).toLocaleString(
                                              _vm.DateTime.DATE_FULL
                                            )
                                          )
                                        )
                                      ])
                                    : _c("span", [_vm._v("N/A")])
                                ]
                              }
                            },
                            {
                              key: "item.itemId",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "right" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "pointer mr-5",
                                          on: {
                                            click: function($event) {
                                              return _vm.confirmRefresh(item)
                                            }
                                          }
                                        },
                                        [_vm._v("refresh")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "pointer",
                                          on: {
                                            click: function($event) {
                                              return _vm.confirmUnlinkAccount(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("$delete")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1308173671
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center flex-column align-center",
                  attrs: { cols: "12" }
                },
                [
                  _vm.isReady && !_vm.items.length
                    ? _c("div", { staticClass: "center-block mt-12" }, [
                        _c(
                          "div",
                          { staticClass: "plaid-tile integration-tile" },
                          [
                            _c(
                              "div",
                              { staticClass: "mt-n10 ml-n4" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      size: "150",
                                      color: _vm.$vuetify.theme.dark
                                        ? "#ffffff"
                                        : "#111212"
                                    }
                                  },
                                  [_vm._v("$plaid")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "my-4",
                                staticStyle: { "max-width": "800px" }
                              },
                              [
                                _c("p", [
                                  _vm._v(" We partner with "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://www.plaid.com",
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("Plaid")]
                                  ),
                                  _vm._v(" to make expense tracking easy. ")
                                ]),
                                _c("p", [
                                  _vm._v(
                                    " Connect your Business Checking or Business Credit Card account to automatically import and categorize your expenses, making tax prep and financial reporting a breeze. "
                                  )
                                ]),
                                _vm.isPaid
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-6" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "connect-account",
                                            attrs: { color: "primary" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.linkNewAccount()
                                              }
                                            }
                                          },
                                          [_vm._v(" Connect account ")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "how-it-works-tile integration-tile" },
                          [
                            _c("div", {}, [
                              _c("img", {
                                attrs: {
                                  src:
                                    "/images/accounting/michelle-screen-grab.png",
                                  alt: ""
                                }
                              })
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "my-4",
                                staticStyle: { "max-width": "800px" }
                              },
                              [
                                _c("p", { staticClass: "font-24" }, [
                                  _vm._v("See how it works")
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Follow along as we walk you through connecting your business account."
                                  )
                                ]),
                                _vm.isPaid
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-6" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "connect-account primary-action",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.linkNewAccount()
                                              }
                                            }
                                          },
                                          [_vm._v(" Watch the tutorial ")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  !_vm.isPaid
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "upgrade-tile column-format centered pa-2 mt-8"
                        },
                        [
                          _c("v-icon", [_vm._v("lock")]),
                          _c(
                            "div",
                            [
                              _vm._v(
                                " This feature is not available on the free plan."
                              ),
                              _c("br"),
                              _vm._v("To automate your expense management, "),
                              _c(
                                "router-link",
                                { attrs: { to: "/subscription" } },
                                [_vm._v("upgrade to Pro.")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "biz-account-tile integration-tile" },
                    [
                      _c("div", [
                        _c("img", {
                          attrs: { src: "/images/accounting/axos.svg", alt: "" }
                        })
                      ]),
                      _c("div", [
                        _c("p", [_vm._v("Need a business account?")]),
                        _c("p", { staticClass: "gray_80--text" }, [
                          _vm._v(
                            "Check out Axos Bank for a full service online banking experience."
                          )
                        ]),
                        _c("p", [
                          _vm._v("Visit "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.axosbank.com",
                                target: "_blank"
                              }
                            },
                            [_vm._v("axosbank.com")]
                          ),
                          _vm._v(".")
                        ])
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.unlinkAccountDialog,
          persistent: false,
          "heading-text": "Unlink Account?",
          "body-text": "Are you sure you want to unlink this account?"
        },
        on: { confirm: _vm.unlinkAccount, close: _vm.cancelUnlinkAccount }
      }),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.refreshAccountDialog,
          persistent: false,
          "heading-text": "Refresh Account?",
          "body-text":
            "This will allow you to re-authenticate with your banking institution.  Do you want to continue?"
        },
        on: { confirm: _vm.refreshAccount, close: _vm.cancelRefreshAccount }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }