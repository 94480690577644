var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "proposal-cover-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.cancel()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "px-4 pt-4 pb-6" }, [
        _c("div", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("proposal.cover.cover")))
        ]),
        _c("div", { staticClass: "left-right-wrapper" }, [
          _c("div", { staticClass: "left-side" }, [
            _c("div", { staticClass: "subtitle" }, [
              _vm._v(_vm._s(_vm.$t("global.preview")))
            ]),
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c(
                  "cover-page",
                  _vm._b(
                    {
                      attrs: {
                        formatting: _vm.proposal.formatting,
                        editMode: false,
                        "is-preview": true,
                        "client-name": _vm.clientName,
                        "contractor-name": _vm.contractorName,
                        logo: _vm.coverPage.showLogo
                          ? _vm.account.accountLogo
                          : ""
                      }
                    },
                    "cover-page",
                    _vm.coverPage,
                    false
                  )
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "mt-2 row-format",
                staticStyle: { width: "100%" }
              },
              [
                _c("v-select", {
                  attrs: {
                    "hide-details": "",
                    "persistent-placeholder": "",
                    outlined: "",
                    dense: "",
                    items: ["Prominent", "Hidden"],
                    label: "Heading position"
                  },
                  model: {
                    value: _vm.coverPage.headingPosition,
                    callback: function($$v) {
                      _vm.$set(_vm.coverPage, "headingPosition", $$v)
                    },
                    expression: "coverPage.headingPosition"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "ml-2", staticStyle: { width: "50%" } },
                  [
                    _vm.coverPage.headingPosition === "Hidden"
                      ? _c("v-text-field", {
                          attrs: {
                            "hide-details": "",
                            outlined: "",
                            dense: "",
                            placeholder: "Proposal Heading"
                          },
                          model: {
                            value: _vm.coverPage.heading,
                            callback: function($$v) {
                              _vm.$set(_vm.coverPage, "heading", $$v)
                            },
                            expression: "coverPage.heading"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "subtitle mt-4" }, [
              _vm._v(_vm._s(_vm.$t("proposal.cover.background-image")))
            ]),
            _c(
              "div",
              { staticClass: "d-flex justify-space-between align-center mt-2" },
              [
                _c(
                  "div",
                  { staticClass: "search-wrapper" },
                  [
                    _c("v-text-field", {
                      ref: "searchForm",
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        placeholder: _vm.$t("proposal.cover.image-search-btn"),
                        "single-line": ""
                      },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchPhotos()
                        }
                      },
                      model: {
                        value: _vm.searchTerm,
                        callback: function($$v) {
                          _vm.searchTerm = $$v
                        },
                        expression: "searchTerm"
                      }
                    }),
                    _c("div", { staticClass: "vertical-rule" }),
                    _c(
                      "div",
                      { staticClass: "magnifier" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", small: "" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.searchPhotos()
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("$search")])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary-action mr-1",
                        attrs: { icon: "" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.previousPage()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("$chevronLeft")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary-action ml-1",
                        attrs: { icon: "" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.nextPage()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("$chevronRight")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c("div", { staticClass: "images-wrapper mt-2" }, [
              _vm.photosReady
                ? _c(
                    "div",
                    { staticClass: "image-grid" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-container no-image-container",
                          style: "cursor:pointer",
                          on: {
                            click: function($event) {
                              return _vm.setBlankTheme()
                            }
                          }
                        },
                        [_vm._v(" No image ")]
                      ),
                      _vm._l(_vm.photoResults, function(result) {
                        return _c(
                          "div",
                          {
                            key: result.id,
                            staticClass: "image-container",
                            style:
                              "background-image: url(" +
                              result.urls.thumb +
                              ");",
                            on: {
                              click: function($event) {
                                return _vm.setUnsplashImage(result)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "credit-container" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "credit-anchor",
                                  attrs: {
                                    href: "" + result.user.links.html,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(result.user.first_name) +
                                      " " +
                                      _vm._s(result.user.last_name) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _c("div", { staticClass: "font-10 mt-1 text-right" }, [
                _vm._v(" " + _vm._s(_vm.$t("proposal.cover.photos-by")) + " "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://unsplash.com/?utm_source=hectic&utm_medium=referral",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Unsplash")]
                )
              ])
            ]),
            _c("div", { staticClass: "subtitle mt-1" }, [
              _vm._v(_vm._s(_vm.$t("proposal.cover.uploaded-images")))
            ]),
            _c("div", { staticClass: "images-wrapper mt-2" }, [
              _vm.assetsReady
                ? _c(
                    "div",
                    { staticClass: "image-grid" },
                    [
                      _vm._l(_vm.imageAssets, function(result) {
                        return _c(
                          "div",
                          {
                            key: result.id,
                            staticClass:
                              "image-container custom-image-container",
                            style:
                              "background-image: url(" +
                              result.thumbnail +
                              ");",
                            on: {
                              click: function($event) {
                                return _vm.setCustomImage(result)
                              }
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "secondary-action",
                                attrs: { icon: "", "x-small": "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.confirmDelete(result)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { size: "16" } }, [
                                  _vm._v("$delete")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _c(
                        "vue-dropzone",
                        {
                          ref: "fileDropzone",
                          staticClass: "image-container no-image-container",
                          attrs: {
                            id: "dropzone",
                            "use-custom-slot": true,
                            options: _vm.dropzoneOptions
                          },
                          on: {
                            "vdropzone-success": _vm.uploadSuccess,
                            "vdropzone-error": _vm.uploadFailure,
                            "vdropzone-sending": _vm.addFileUploadAuthHeader
                          }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("v-icon", [_vm._v("$vuetify.icons.upload")]),
                              _c("div", { staticClass: "font-12" }, [
                                _vm._v(_vm._s(_vm.$t("proposal.cover.upload")))
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    2
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              {
                staticClass: "subtitle mt-3 d-flex justify-start align-center"
              },
              [
                _c(
                  "div",
                  [
                    _c("v-switch", {
                      staticClass: "mt-0",
                      attrs: {
                        dense: "",
                        inset: "",
                        "hide-details": "",
                        disabled: !_vm.account.accountLogo
                      },
                      model: {
                        value: _vm.coverPage.showLogo,
                        callback: function($$v) {
                          _vm.$set(_vm.coverPage, "showLogo", $$v)
                        },
                        expression: "coverPage.showLogo"
                      }
                    })
                  ],
                  1
                ),
                _c("div", [
                  _vm._v(" " + _vm._s(_vm.$t("proposal.cover.show-logo")) + " ")
                ])
              ]
            ),
            _c("div", { staticClass: "images-wrapper mt-2" }, [
              _c("div", { staticClass: "image-grid" }, [
                _vm.account.accountLogo
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "image-container custom-image-container account-logo",
                        style:
                          "background-image: url(" +
                          _vm.account.accountLogo +
                          ");",
                        on: {
                          click: function($event) {
                            _vm.openAccountInfoModal = true
                          }
                        }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "secondary-action",
                            attrs: { icon: "", "x-small": "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm.openAccountInfoModal = true
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { size: "16" } }, [
                              _vm._v("$edit")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "image-container no-image-container account-logo",
                        on: {
                          click: function($event) {
                            _vm.openAccountInfoModal = true
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("v-icon", [_vm._v("$vuetify.icons.edit")]),
                            _c("div", [_vm._v(_vm._s(_vm.$t("global.edit")))])
                          ],
                          1
                        )
                      ]
                    )
              ])
            ])
          ]),
          _c("div", { staticClass: "right-side" }, [
            _c("div", { staticClass: "subtitle" }, [
              _vm._v(_vm._s(_vm.$t("proposal.cover.color-scheme")))
            ]),
            _c("div", { staticClass: "color-scheme-wrapper mt-2" }, [
              _c(
                "div",
                {
                  class:
                    "scheme-box dark pointer" +
                    (this.coverPage.theme === "dark" ? " selected" : ""),
                  on: {
                    click: function($event) {
                      return _vm.themeChange("dark")
                    }
                  }
                },
                [_vm._m(0), _vm._m(1)]
              ),
              _c(
                "div",
                {
                  class:
                    "scheme-box light pointer mt-2" +
                    (this.coverPage.theme === "light" ? " selected" : ""),
                  on: {
                    click: function($event) {
                      return _vm.themeChange("light")
                    }
                  }
                },
                [_vm._m(2), _vm._m(3)]
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              staticStyle: { width: "160px" },
              on: {
                click: function($event) {
                  return _vm.cancel()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.cancel")))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-1",
              staticStyle: { width: "160px" },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          persistent: false,
          "heading-text": "Delete Background Image?",
          "yes-text": _vm.$t("global.delete"),
          "no-text": _vm.$t("global.cancel"),
          "body-text": _vm.confirmBodyText
        },
        on: {
          confirm: function($event) {
            return _vm.deleteCustomImage()
          },
          close: function($event) {
            _vm.confirmDeleteDialog = false
          }
        }
      }),
      _c(
        "basic-modal",
        {
          ref: "accountInfoModal",
          attrs: { dialog: _vm.openAccountInfoModal, persistent: false },
          on: {
            close: function($event) {
              _vm.openAccountInfoModal = false
            }
          }
        },
        [
          true
            ? _c("account-info-modal", {
                attrs: { "account-info": _vm.account },
                on: {
                  "set-account-info": _vm.setAccountInfo,
                  close: function($event) {
                    _vm.openAccountInfoModal = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-section" }, [
      _c("div", { staticClass: "title" }),
      _c("div", { staticClass: "subtitle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom-section" }, [
      _c("div", [_vm._v("Dark")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-section" }, [
      _c("div", { staticClass: "title" }),
      _c("div", { staticClass: "subtitle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bottom-section" }, [
      _c("div", [_vm._v("Light")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }