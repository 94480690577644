<template>
	<div class="column-format gap-6 px-3 pt-3">
		<v-container fluid class="ma-0 pa-0">
			<v-row>
				<v-col cols="12" class="pa-0">
					<div class="row-format align-center" style="gap: 8px; flex-wrap: wrap">
						<client-filter :filter="filter"></client-filter>
						<lead-source-filter :filter="filter" :lead-sources="leadSources"></lead-source-filter>
						<scope-button scope="today" :active-scope="filter.dateSpecifier" label="Today" @scope-update="updateScope"></scope-button>
						<scope-button scope="this-week" :active-scope="filter.dateSpecifier" label="This week" @scope-update="updateScope"></scope-button>
						<scope-button scope="last-week" :active-scope="filter.dateSpecifier" label="Last week" @scope-update="updateScope"></scope-button>
						<scope-button scope="month-to-date" :active-scope="filter.dateSpecifier" label="This month" @scope-update="updateScope"></scope-button>
						<scope-button scope="last-month" :active-scope="filter.dateSpecifier" label="Last month" @scope-update="updateScope"></scope-button>
						<scope-button scope="year-to-date" :active-scope="filter.dateSpecifier" label="Year to date" @scope-update="updateScope"></scope-button>
						<scope-button scope="last-year" :active-scope="filter.dateSpecifier" label="Last year" @scope-update="updateScope"></scope-button>
						<scope-button scope="between" :active-scope="filter.dateSpecifier" label="Custom dates" @scope-update="updateScope"></scope-button>
						<div v-if="filter.dateSpecifier === 'between'" class="row-format">
							<scope-date-selector :date="filter.earliest" @change="setEarliest($event)"></scope-date-selector>
							<div class="mx-1">-</div>
							<scope-date-selector :date="filter.latest" @change="setLatest($event)"></scope-date-selector>
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row align="center" v-if="$store.getters.hasInvoiceAccess">
				<v-col cols="12" md="6" class="px-0">
					<div class="text-left brand-medium mb-1">Money</div>
					<money-metrics @clicked="handleClicked($event)" :clients="clientList" :invoices="filteredInvoices" :payments="filteredPayments" :other-income="filteredIncome" :expenses="filteredExpenses"></money-metrics>
				</v-col>
				<v-col cols="12" md="6">
					<money-chart :scope="scope" :earliest="earliest" :latest="latest" :clients="clientList" :invoices="filteredInvoices" :other-income="filteredIncome"></money-chart>
				</v-col>
			</v-row>
			<v-row style="border-top: 1px solid var(--v-gray_50-base)" class="mt-10 pt-4" align="center" v-if="$store.getters.hasFeatureAccess('timesheets')">
				<v-col cols="12" md="6" class="px-0">
					<div class="text-left brand-medium mb-1">Time</div>
					<time-metrics @clicked="handleClicked($event)" :scope="scope" :earliest="earliest" :latest="latest" :clients="clientList" :invoices="filteredInvoices" :payments="filteredPayments" :other-income="filteredIncome" :expenses="filteredExpenses" :time-worked="filteredTimeWorked"></time-metrics>
				</v-col>
				<v-col cols="12" md="6">
					<time-chart :scope="scope" :earliest="earliest" :latest="latest" :clients="clientList" :time-worked="filteredTimeWorked"></time-chart>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import FilterHelpers from '@/utils/FilterHelpers';
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import TimeTrackService from '@/modules/timetracking/TimeTrackService';
	import ExpenseService from '@/modules/accounting/expenses/ExpenseService';
	import MoneyMetrics from "@/modules/home/insights/MoneyMetrics";
	import TimeMetrics from "@/modules/home/insights/TimeMetrics";
	import ScopeButton from "@/modules/home/insights/ScopeButton";
	import MoneyChart from "@/modules/home/insights/MoneyChart";
	import TimeChart from "@/modules/home/insights/TimeChart";
	import ClientFilter from "@/modules/home/insights/ClientFilter";
	import ScopeDateSelector from "@/modules/home/insights/ScopeDateSelector";
	import MetricTableModal from "@/modules/home/insights/MetricTableModal";
	import DateTime from "@/modules/utils/HDateTime";
	import IncomeService from "@/modules/accounting/payments/IncomeService";
	import LeadSourceFilter from "@/modules/home/insights/LeadSourceFilter";

	export default {
		name: 'Reports',

		props: ['isVisible'],

		components: {
			LeadSourceFilter,
			ScopeDateSelector, ClientFilter, TimeChart, MoneyChart, ScopeButton, TimeMetrics, MoneyMetrics},

		data: function () {
			return {
				invoiceService: new InvoiceService(),
				timeTrackService: new TimeTrackService(),
				expenseService: new ExpenseService(),
				incomeService: new IncomeService(),

				invoices: [],
				expenses: [],
				payments: [],
				otherIncome: [],
				timeWorked: [],
				filter: this.emptyFilter(),
			};
		},

		mounted() {
			this.getFilter();
			this.getData();
		},

		beforeDestroy() {},

		methods: {
			handleClicked: function(type){
				let dataset;
				if(type === 'invoiced'){
					dataset = this.filteredInvoices;
				}else if(type === 'received'){
					dataset = this.filteredPayments;
				}else if(type === 'expenses'){
					dataset = this.filteredExpenses
				}else if(type === 'hours'){
					dataset = this.filteredTimeWorked;
				}else if(type === 'un-billed'){
					dataset = this.filteredUnBilledTime;
					type = 'hours';
				}else if(type === 'otherIncome'){
					dataset = this.filteredIncome;
					type = 'other income';
				}

				let binding = {
					type: type,
					dataset: dataset
				}

				this.$store.state.globalModalController.openModal(MetricTableModal,binding).then((res) => {
					if(res){
						this.getData();
					}
				});
			},

			getFilter: function(){
				let storedFilter = localStorage.getItem('HOME_INSIGHT_FILTER');

				if(storedFilter){
					this.filter = JSON.parse(storedFilter);
				}else{
					this.filter = this.emptyFilter();
				}

				if(!this.filter.clients){
					this.filter.clients = [];
				}
				if(!this.filter.leadSources){
					this.filter.leadSources = [];
				}
			},

			emptyFilter: function () {
				return {
					dateSpecifier: 'year-to-date',
					earliest: null,
					latest: null,
					clients: [],
					leadSources: [],
					isReady: false,
				};
			},

			updateScope: function(scope){
				if(scope !== this.filter.dateSpecifier){
					this.filter.dateSpecifier = scope;
					this.getData();
				}
			},

			setEarliest: function(earliest){
				this.filter.earliest = earliest;
				this.getData();
			},

			setLatest: function(latest){
				this.filter.latest = latest;
				this.getData();
			},

			getData: function () {
				if(this.earliest.invalid || this.latest.invalid) {
					this.expenses.splice(0, this.expenses.length);
					this.invoices.splice(0, this.invoices.length);
					this.payments.splice(0, this.payments.length);
					this.otherIncome.splice(0,this.otherIncome.length);
				}else{
					if (this.isVisible) {
						this.$store.commit('startLoading');
					}
					let p1 = this.getExpenses();
					let p2 = this.getInvoicesSentOrPaid();
					let p3 = this.getTimeWorked();
					let p4 = this.getOtherIncome();
					Promise.all([p1, p2, p3, p4]).finally(() => this.$store.commit('stopLoading'));
				}
			},

			getExpenses: function () {
				return this.expenseService
					.getExpenses(this.earliest.toISODate(), this.latest.toISODate())
					.then((res) => {
						this.expenses.splice(0, this.expenses.length);
						res.data.forEach((expense) => {
							let paidDate = DateTime.fromISO(expense.paidDate);
							if (paidDate >= this.earliest && paidDate <= this.latest) {
								this.expenses.push(expense);
							}
						});
						Promise.resolve(res);
					})
					.catch((err) => Promise.reject(err));
			},

			getOtherIncome: function(){
				return this.incomeService.getIncomeList(this.earliest.toISODate(),this.latest.toISODate())
				.then((res) => {
					this.otherIncome.splice(0,this.otherIncome.length);
					this.otherIncome.push(... res.data);
				})
				.catch((err) => Promise.reject(err));
			},

			getInvoicesSentOrPaid: function () {
				return this.invoiceService
					.getInvoicesSentOrPaidBetween(this.earliest.toISODate(), this.latest.toISODate())
					.then((res) => {
						this.invoices.splice(0, this.invoices.length);
						this.payments.splice(0, this.payments.length);

						let invoices = [...res.data.filter((i) => i.status !== 'DRAFT')];

						invoices.forEach((invoice) => {
							let dateSent = DateTime.fromISO(invoice.dateSent);
							if (dateSent >= this.earliest && dateSent <= this.latest) {
								this.invoices.push(invoice);
							}

							invoice.payments.forEach((payment) => {
								let datePaid = DateTime.fromISO(payment.datePaid);
								if (datePaid >= this.earliest && datePaid <= this.latest) {
									payment.clientId = invoice.clientId;
									payment.client = invoice.clientInfo;
									payment.invoiceId = invoice.id;
									this.payments.push(payment);
								}
							});
						});

						Promise.resolve(res);
					})
					.catch((err) => Promise.reject(err));
			},

			getTimeWorked: function () {
				return this.timeTrackService
					.getTimerEventsFull(null, this.earliest.toISO(), this.latest.toISO(), null, null, null)
					.then((res) => {
						this.timeWorked.splice(0, this.timeWorked.length);
						this.timeWorked.push(...res.data);
						Promise.resolve(res);
					})
					.catch((err) => Promise.reject(err));
			},

			filterByClient: function (a) {
				if(!this.filter || !this.filter.clients){
					return true;
				}else if (this.filter.clients.length === 0) {
					return true;
				} else {
					return this.filter.clients.includes(a.clientId);
				}
			},

			filterByLeadSource: function(a){
				if(!this.filter || !this.filter.leadSources){
					return true;
				}else if (this.filter.leadSources.length === 0) {
					return true;
				} else {
					let leadSource = this.clientLeadSourceMap.get(a.clientId);
					return this.filter.leadSources.includes(leadSource);
				}
			}
		},

		watch:{
			filter: {
				deep: true,
				handler: function(){
					localStorage.setItem('HOME_INSIGHT_FILTER',JSON.stringify(this.filter));
				}
			}
		},

		computed: {
			filteredTimeWorked: function(){
				return this.timeWorked.filter(this.filterByClient).filter(this.filterByLeadSource);
			},

			filteredInvoices: function(){
				return this.invoices.filter(this.filterByClient).filter(this.filterByLeadSource);
			},

			filteredIncome: function(){
				return this.otherIncome.filter(this.filterByClient).filter(this.filterByLeadSource);
			},

			filteredPayments: function(){
				return this.payments.filter(this.filterByClient).filter(this.filterByLeadSource);
			},

			filteredExpenses: function(){
				return this.expenses.filter(this.filterByClient).filter(this.filterByLeadSource);
			},

			filteredUnBilledTime: function(){
				return this.filteredTimeWorked.filter(t => (t.projectId === null || (t.feeSchedule && t.feeSchedule.feeType === 'Hourly')) && !t.invoiceId);
			},

			clientList: function () {
				return this.$store.state.clientCache;
			},

			earliest: function () {
				if (this.filter.dateSpecifier === 'between') {
					return DateTime.fromISO(this.filter.earliest).startOf('day');
				} else {
					return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).earliest;
				}
			},

			latest: function () {
				if (this.filter.dateSpecifier === 'between') {
					return DateTime.fromISO(this.filter.latest).endOf('day');
				} else {
					return FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false).latest.endOf('day');
				}
			},

			scope: function(){
				let months = this.latest.diff(this.earliest,["months"]).months;
				let days = this.latest.diff(this.earliest,["days"]).days;

				if(months >= 2){
					return 'month';
				}else if(days <= 14){
					return 'day';
				}else{
					return 'week';
				}
			},

			leadSources: function(){
				let values = this.clientList.map(c => c.leadSource ? c.leadSource : 'Unknown');
				let result = [... new Set(values)];
				result.sort((a,b) => a.localeCompare(b));
				return result;
			},

			clientLeadSourceMap: function(){
				let result = new Map();
				this.clientList.forEach(c => {
					result.set(c.id,c.leadSource ? c.leadSource : 'Unknown');
				})
				return result;
			}
		},
	};
</script>

<style lang="scss">
	.clickable {
		cursor: pointer;
		&:hover {
			background-color: var(--v-gray_10-base) !important;
		}
	}

	.splitContainer {
		text-align: left;
		height: 124px;
		width: 50%;
		border-radius: 4px;

		.splitWidget {
			background-color: var(--v-gray_5-base);
			border: 1px solid var(--v-gray_30-base);
			border-radius: 4px;
			height: 100%;
			width: 49%;
			padding: 16px;

			.header {
				font-size: 16px;
				color: var(--v-gray_80-base);
			}

			.body {
				font-size: 24px;
				font-weight: 600;
			}
		}
	}

	.numberWidget {
		text-align: left;
		background-color: var(--v-gray_5-base);
		height: 124px;
		width: 50%;
		padding: 16px;
		border-radius: 4px;
		border: 1px solid var(--v-gray_30-base);

		.header {
			font-size: 16px;
			color: var(--v-gray_80-base);
		}

		.body {
			font-size: 24px;
			font-weight: 600;
		}
	}
</style>
