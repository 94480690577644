import { render, staticRenderFns } from "./TextInput.vue?vue&type=template&id=1f33ef56&scoped=true&"
import script from "./TextInput.vue?vue&type=script&lang=js&"
export * from "./TextInput.vue?vue&type=script&lang=js&"
import style0 from "./TextInput.vue?vue&type=style&index=0&id=1f33ef56&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f33ef56",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VSelect,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src834000100/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f33ef56')) {
      api.createRecord('1f33ef56', component.options)
    } else {
      api.reload('1f33ef56', component.options)
    }
    module.hot.accept("./TextInput.vue?vue&type=template&id=1f33ef56&scoped=true&", function () {
      api.rerender('1f33ef56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/discovery/formbuilder/TextInput.vue"
export default component.exports