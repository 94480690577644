<template>
	<div>
		<input type="hidden" v-model="discovery_template_id" ref="discovery_template_id" df-discovery_template_id />
		<input type="hidden" v-model="template_name" ref="template_name" df-template_name />

		<node-label @open="openModal()">
			<template v-slot:default>
				<v-icon class="mr-1" color="black" size="22">checklist</v-icon>
				<div v-if="template_name">Add {{template_name}} to portal</div>
				<div v-else>Add form to portal</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" title="Form to portal">
			<template v-slot:default>
				<div class="column-format gap-2" style="min-width: 550px">
					<v-autocomplete
						hide-details
						dense
						outlined
						clearable
						persistent-placeholder
						label="Form template"
						:items="discoveryTemplates"
						item-text="name"
						item-value="id"
						v-model="discovery_template_id"
						@change="updateSelectedTemplate()"
					></v-autocomplete>
				</div>
			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeModal from '@/modules/workflow/nodes/NodeModal';
	import NodeLabel from '@/modules/workflow/nodes/NodeLabel';
	import NodeMixin from '@/modules/workflow/nodes/NodeMixin';
	import DiscoveryTemplateService from "@/modules/discovery/DiscoveryTemplateService";

	export default {
		name: 'FormToPortal',
		inputs: 1,
		outputs: 1,

		mixins: [NodeMixin],

		props: [],

		components: { NodeLabel, NodeModal },

		data: function() {
			return {
				discovery_template_id: null,
				template_name: null,
				discoveryTemplates: [],
				discoveryTemplateService: new DiscoveryTemplateService(),
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.discovery_template_id = this.$refs.discovery_template_id.value;
				this.template_name = this.$refs.template_name.value;
			});
		},

		beforeDestroy() {},

		methods: {
			edit: function() {
				this.$refs.nodeModal.open();
			},

			updateSelectedTemplate: function() {
				if (this.discovery_template_id) {
					this.template_name = this.discoveryTemplates.find(d => d.id === this.discovery_template_id).name;
				} else {
					this.template_name = null;
				}
			},

			getDiscoveryTemplates: function() {
				return new Promise((resolve, reject) => {
					this.discoveryTemplateService
							.getDiscoveryTemplateList()
							.then((res) => {
								this.discoveryTemplates.splice(0);
								this.discoveryTemplates.push(...res.data);
								resolve();
							})
							.catch((err) => reject(err));
				});
			},

			openModal: function() {
				let p1 = this.getDiscoveryTemplates();
				Promise.all([p1]).then(() => {
					this.isOpen = true;
					this.$refs.nodeModal.open()
				});
			},
		},

		watch: {
			discovery_template_id: function() {
				this.emitInput('discovery_template_id');
			},

			template_name: function() {
				this.emitInput('template_name');
			},
		},

		computed: {

		},
	};
</script>

<style scoped lang="scss"></style>
