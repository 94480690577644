var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "600px", "min-height": "500px" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("result")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c("h2", { staticClass: "modal-title" }, [
          _vm._v("Select opportunity")
        ]),
        _c(
          "div",
          { staticClass: "font-16" },
          [
            _c("v-text-field", {
              staticClass: "pb-4",
              attrs: {
                placeholder: "Filter...",
                "hide-details": "",
                dense: ""
              },
              model: {
                value: _vm.search,
                callback: function($$v) {
                  _vm.search = $$v
                },
                expression: "search"
              }
            }),
            _vm._l(_vm.filteredOpportunities, function(opp) {
              return _c(
                "div",
                {
                  key: opp.id,
                  staticClass: "row-format align-center py-2 pointer",
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectOpp(opp)
                    }
                  }
                },
                [
                  opp.clientId
                    ? _c("client-avatar", {
                        staticClass: "mr-1",
                        attrs: {
                          small: true,
                          client: _vm.getClient(opp.clientId)
                        }
                      })
                    : _vm._e(),
                  _c("div", [_vm._v(_vm._s(opp.name ? opp.name : "--"))])
                ],
                1
              )
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }