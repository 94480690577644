<template>
	<div class="column-format" v-if="isInitialized">
		<div class="row-format gap-3">
			<div class="kpi-box">
				<div class="fit text-left">{{ $t('timetrack.list.total-worked') }}</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.formatSeconds(metrics.totalTime) }}</div>
			</div>
			<div class="kpi-box">
				<div class="row-format">
					<div class="row-format align-center fit">
						<div>{{ $t('timetrack.list.by-deliverable') }}</div>
					</div>
				</div>
				<div class="row-format mt-1 status-wrapper">
					<div
						class="status-box"
						v-for="deliverable in metrics.deliverables"
						:key="deliverable.name"
						:style="`width:${deliverable.percentage}%; background-color: ${deliverable.color}`"
						v-tippy="{ content: `${deliverable.name} - ${$formatters.formatSeconds(deliverable.totalTime)}` }"
					></div>
				</div>
			</div>
			<div class="kpi-box">
				<div class="fit text-left">{{ $t('timetrack.list.this-week') }}</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.formatSeconds(metrics.timeThisWeek) }}</div>
			</div>
			<div class="kpi-box">
				<div class="row-format">
					<div class="fit text-left">{{ $t('timetrack.list.un-billed') }}</div>
				</div>
				<div class="font-24 brand-medium fit mt-1">{{ $formatters.formatSeconds(metrics.unBilled) }}</div>
			</div>
		</div>
		<div class="mt-4">
			<slot name="filter"></slot>
			<div style="background-color: var(--v-white-base)">
				<timer-event-table
					class="mt-4"
					v-if="events.length"
					@add-new="addNew()"
					@duplicate="duplicate($event)"
					@edit-timer="editTimerEvent($event)"
					@open-invoice="openInvoice($event)"
					:events="filteredEvents"
				></timer-event-table>
			</div>
			<div class="row-format centered fill-height mt-8" style="flex: 1" v-if="!events.length">
				<empty-view
					header="No time entries yet"
					:body="
						`If you track your time for ${project.name}, your history of time entries will show up here. In addition to viewing historical entries here, you can also create new entries.`
					"
					cta="Create a time entry"
					video-header="See how it works"
					video-body="Learn how to track your time and then add those time entries to an invoice."
					video-cta="Watch the tutorial"
					video-id="O6GEZ3DD02k"
					@cta-clicked="addNew()"
				></empty-view>
			</div>
		</div>
	</div>
</template>

<script>
	import TimeTrackService from '@/modules/timetracking/TimeTrackService';
	import DateTime from '@/modules/utils/HDateTime';
	import ClientPalette from '@/modules/clients/ClientPalette';
	import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
	import FilterHelpers from '@/utils/FilterHelpers';
	import EmptyView from '@/components/EmptyView';
	import { mapGetters } from 'vuex';
	import TimerEventTable from '@/modules/clients/detail/timetracking/TimerEventTable';

	export default {
		name: 'TimerEventList',

		props: ['client', 'project', 'isActive', 'filter'],

		components: { TimerEventTable, EmptyView },

		data: function() {
			return {
				events: [],
				timeTrackService: new TimeTrackService(),
				isInitialized: false,
				DateTime: DateTime,
				clientPalette: new ClientPalette(),
			};
		},

		mounted() {
			this.getTimerEvents();
			this.$store.state.eventBus.$on('timer-list-reload', this.getTimerEvents);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('timer-list-reload', this.getTimerEvents);
		},

		methods: {
			getTimerEvents: function() {
				this.timeTrackService.getTimerEventsFull(null, null, null, null, this.project.id, null).then((res) => {
					this.events.splice(0, this.events.length);
					this.events.push(...res.data);
					this.isInitialized = true;
				});
			},

			editTimerEvent: function(timerEvent) {
				let binding = {
					timerEvent: timerEvent,
					client: this.client,
				};

				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					if (!res) return;
					if (res.action && res.action === 'DELETED') {
						this.timerDeleted(timerEvent.id);
					} else {
						this.timerUpdated(res);
					}
				});
			},

			addNew: function(timerEvent = null) {
				if (timerEvent === null) {
					let timerStart = DateTime.now();
					let timerEnd = DateTime.now().plus({ minutes: 30 });

					timerEvent = {
						id: null,
						clientId: this.project.client.id,
						projectId: this.project.id,
						projectName: this.project.name,
						userId: this.$store.getters.getLoggedInUserId,
						timerStart: timerStart.toISO(),
						timerEnd: timerEnd.toISO(),
						duration: timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']),
					};
				}

				let binding = {
					timerEvent: timerEvent,
					client: this.project.client,
				};
				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					if (!res) return;
					this.timerCreated(res);
				});
			},

			duplicate: function(timerEvent) {
				timerEvent.id = null;
				this.addNew(timerEvent);
			},

			timerUpdated(timerEvent) {
				if (timerEvent.projectId === this.project.id) {
					let ix = this.events.findIndex((e) => e.id === timerEvent.id);
					if (ix > -1) {
						this.events.splice(ix, 1, timerEvent);
					}
				} else {
					this.timerDeleted(timerEvent.id);
				}
			},

			timerCreated(timerEvent) {
				if (timerEvent.projectId === this.project.id) {
					this.events.push(timerEvent);
				}
			},

			timerDeleted(id) {
				let ix = this.events.findIndex((e) => e.id === id);
				if (ix > -1) {
					this.events.splice(ix, 1);
				}
			},

			openInvoice: function(invoiceId) {
				this.$emit('open-invoice', invoiceId);
			},
		},

		computed: {
			...mapGetters({
				userId: 'getLoggedInUserId',
			}),

			filteredEvents: function() {
				let events = [...this.events];
				let search = this.filter.search ? this.filter.search.toLowerCase() : null;
				let earliest, latest;

				if (this.filter.dateSpecifier) {
					if (this.filter.dateSpecifier === 'between' && this.filter.earliest && this.filter.latest) {
						earliest = DateTime.fromISO(this.filter.earliest).startOf('day');
						latest = DateTime.fromISO(this.filter.latest)
							.plus({ days: 1 })
							.minus({ seconds: 1 });
					} else if (this.filter.dateSpecifier !== 'between') {
						let helper = FilterHelpers.getEarliestAndLatest(this.filter.dateSpecifier, false);
						earliest = helper.earliest;
						latest = helper.latest;
					}
				}

				for (let i = 0; i < events.length; i++) {
					if (events[i].deliverableName) {
						events[i].deliverableOrNote = events[i].deliverableName;
					} else if (events[i].notes) {
						events[i].deliverableOrNote = events[i].notes;
					}

					if (events[i].invoiceId) {
						events[i].billingStatus = 'BILLED';
					} else if (
						events[i].billable &&
						(!events[i].projectId || (events[i].feeSchedule && events[i].feeSchedule.feeType === 'Hourly'))
					) {
						events[i].billingStatus = 'UN-BILLED';
					} else {
						events[i].billingStatus = 'NON-BILLABLE';
					}
				}

				return events
					.filter((e) => {
						if (search) {
							if (
								(e.projectName && e.projectName.toLowerCase().includes(search)) ||
								(e.deliverableName && e.deliverableName.toLowerCase().includes(search)) ||
								(e.notes && e.notes.toLowerCase().includes(search))
							) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((e) => {
						if (earliest && latest) {
							let dateSent = DateTime.fromISO(e.timerStart);
							if (dateSent >= earliest && dateSent < latest) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((e) => {
						if (this.filter.timeEntryStatus && this.filter.timeEntryStatus.length > 0) {
							if (this.filter.timeEntryStatus.includes(e.billingStatus)) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					})
					.filter((e) => {
						if (this.filter.projectList && this.filter.projectList.length > 0) {
							if (this.filter.projectList.includes(e.projectId)) {
								return true;
							} else {
								return false;
							}
						} else {
							return true;
						}
					});
			},

			metrics: function() {
				let result = {
					totalTime: 0,
					unBilled: 0,
					timeThisWeek: 0,
					deliverables: {},
				};

				let startOfWeek = DateTime.now().startOf('week');
				let colorsInUse = [];
				let deliverables = [...new Set(this.events.map((item) => (item.deliverableId ? item.deliverableId : '0')))];

				deliverables.forEach((p) => {
					let color = this.clientPalette.getRandomColor(colorsInUse);
					colorsInUse.push(color);
					result.deliverables[p] = { color: color, totalTime: 0 };
				});

				for (let i = 0; i < this.filteredEvents.length; i++) {
					let e = this.filteredEvents[i];
					result.totalTime += e.duration;
					if (e.feeSchedule && e.feeSchedule.feeType === 'Hourly') {
						result.unBilled += e.invoiceId ? 0 : e.duration;
					}

					if (DateTime.fromISO(e.timerStart) > startOfWeek) {
						result.timeThisWeek += e.duration;
					}

					let deliverableId = e.deliverableId ? e.deliverableId : '0';
					result.deliverables[deliverableId].totalTime += e.duration;
					result.deliverables[deliverableId].name = deliverableId == '0' ? '[No deliverable]' : e.deliverableName;
				}

				for (const deliverable in result.deliverables) {
					result.deliverables[deliverable].percentage = Math.round(
						(result.deliverables[deliverable].totalTime / result.totalTime) * 100
					);
				}

				return result;
			},
		},

		watch: {
			isActive: function(newVal) {
				if (newVal && !this.isInitialized) {
					this.getTimerEvents();
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.event-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
	}

	.status-wrapper {
		height: 36px;
	}

	.status-box {
		height: 100%;
		margin-right: 3px;
		border-radius: 2px;
	}

	.invoice-status {
		.default-label {
			display: block;
		}
		.hover-label {
			display: none;
		}

		&:hover {
			.default-label {
				display: none;
			}
			.hover-label {
				display: block;
			}
		}
	}

	.kpi-box {
		.create-invoice {
			display: none;
		}

		&:hover {
			.create-invoice {
				display: block;
			}
		}
	}
</style>
