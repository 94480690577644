<template>
	<div class="row-format align-center gap-1">
		<v-icon class="material-symbols-rounded" color="black" size="20">play_circle</v-icon>
		<div>Start</div>
	</div>
</template>

<script>
export default {
	name: "Start",
	inputs: 0,
	outputs: 1,

	props: [],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>