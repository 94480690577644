var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "deliverable-table-wrapper" } }, [
    _c("input", {
      attrs: { id: "copyToClip", name: "copyToClip", value: "", type: "hidden" }
    }),
    _c(
      "table",
      { staticClass: "table", attrs: { id: "deliverable-table" } },
      [
        _vm._l(_vm.deliverableGroupings, function(group, index) {
          return [
            _c(
              "tbody",
              { key: index },
              [
                group.groupKey
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "group-heading",
                          style: index > 0 ? "padding-top: 36px" : "",
                          attrs: {
                            colspan:
                              (_vm.singleProjectMode ? 9 : 10) +
                              _vm.customFields.length
                          }
                        },
                        [_vm._v(" " + _vm._s(group.groupLabel) + " ")]
                      )
                    ])
                  : _vm._e(),
                _c(
                  "tr",
                  [
                    !_vm.singleProjectMode
                      ? _c(
                          "td",
                          {
                            class:
                              "heading " +
                              (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                          },
                          [_vm._v("Project")]
                        )
                      : _vm._e(),
                    _c(
                      "td",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                      },
                      [_c("div", { staticClass: "pl-1" }, [_vm._v("Name")])]
                    ),
                    _c(
                      "td",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                      },
                      [_vm._v("Status")]
                    ),
                    _vm._l(_vm.customFields, function(field) {
                      return _c(
                        "td",
                        {
                          key: field.id,
                          class:
                            "heading " +
                            (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                        },
                        [_vm._v(_vm._s(field.name))]
                      )
                    }),
                    _c(
                      "td",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                      },
                      [_vm._v("Priority")]
                    ),
                    _c(
                      "td",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                      },
                      [_vm._v("Assigned")]
                    ),
                    _c(
                      "td",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                      },
                      [_vm._v("Start")]
                    ),
                    _c(
                      "td",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                      },
                      [_vm._v("Due")]
                    ),
                    _c(
                      "td",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                      },
                      [_vm._v("Comments")]
                    ),
                    _c(
                      "td",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                      },
                      [_vm._v("Subtasks")]
                    ),
                    _c(
                      "td",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.theme.dark ? "heading-dark" : "")
                      },
                      [_vm._v(" ")]
                    )
                  ],
                  2
                ),
                _vm._l(group.deliverables, function(deliverable) {
                  return _c(
                    "tr",
                    { key: deliverable.id, staticClass: "font-14 text-left" },
                    [
                      !_vm.singleProjectMode
                        ? _c("td", { staticClass: "content" }, [
                            _c(
                              "div",
                              {
                                staticClass: "row-format align-center",
                                on: {
                                  click: function($event) {
                                    return _vm.editDeliverable(deliverable)
                                  }
                                }
                              },
                              [
                                deliverable.client
                                  ? _c("client-avatar", {
                                      attrs: {
                                        client: deliverable.client,
                                        small: true
                                      }
                                    })
                                  : _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "gray_30", size: "24" }
                                      },
                                      [_vm._v("check_box_outline_blank")]
                                    ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ml-2 pointer",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.routeToProject(
                                          deliverable.project,
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        deliverable.project
                                          ? deliverable.project.name
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _c("td", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "row-format align-center" },
                          [
                            deliverable.invoiceId
                              ? _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: {
                                          content:
                                            "Included in invoice " +
                                            deliverable.invoiceNumber
                                        },
                                        expression:
                                          "{ content: `Included in invoice ${deliverable.invoiceNumber}` }"
                                      }
                                    ],
                                    attrs: { color: "success", small: "" }
                                  },
                                  [_vm._v("attach_money")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "pa-1",
                                attrs: { contenteditable: "true" },
                                on: {
                                  blur: function($event) {
                                    return _vm.setName(deliverable, $event)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(deliverable.name) + " ")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("td", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "row-format align-center gap-2" },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-click": true,
                                  "close-on-content-click": true
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "deliverableStatus row-format align-center pointer",
                                                style:
                                                  "--status-color:" +
                                                  deliverable.status.hexColor
                                              },
                                              on
                                            ),
                                            [
                                              _c("div", {
                                                staticClass: "statusBox"
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "text-no-wrap" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      deliverable.status.label
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-left pa-3 font-14",
                                    staticStyle: {
                                      "background-color": "var(--v-white-base)"
                                    }
                                  },
                                  _vm._l(
                                    _vm.$store.state.deliverableStatusList
                                      .statusList,
                                    function(status) {
                                      return _c(
                                        "div",
                                        {
                                          key: status.id,
                                          staticClass: "py-1 pointer",
                                          on: {
                                            click: function($event) {
                                              return _vm.setStatus(
                                                deliverable,
                                                status
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "deliverableStatus row-format align-center highlight-hover",
                                              style:
                                                "--status-color:" +
                                                status.hexColor
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "statusBox"
                                              }),
                                              _c("div", [
                                                _vm._v(_vm._s(status.label))
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              ]
                            ),
                            deliverable.archived
                              ? _c(
                                  "v-icon",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { content: "Archived" },
                                        expression: "{ content: 'Archived' }"
                                      }
                                    ],
                                    staticClass: "ml-auto",
                                    attrs: { size: "20", color: "gray_70" }
                                  },
                                  [_vm._v("lock")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _vm._l(_vm.customFields, function(field) {
                        return _c(
                          "td",
                          { key: field.id, staticClass: "content" },
                          [
                            _c("custom-field", {
                              attrs: {
                                field: field,
                                value: _vm.getCustomValue(deliverable, field.id)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleCustomFieldChange(
                                    deliverable,
                                    $event
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _c("td", { staticClass: "content" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: deliverable.priority,
                              expression: "deliverable.priority"
                            }
                          ],
                          staticClass: "numberInput",
                          attrs: {
                            type: "number",
                            oninput: "this.value=(parseInt(this.value)||0)",
                            step: "1"
                          },
                          domProps: { value: deliverable.priority },
                          on: {
                            change: function($event) {
                              return _vm.setPriority(deliverable)
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                deliverable,
                                "priority",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("td", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticStyle: { width: "fit-content" } },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-click": true,
                                  "close-on-content-click": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              {
                                                staticClass: "ml-auto pointer"
                                              },
                                              on
                                            ),
                                            [
                                              deliverable.assignedToList.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row-format align-center"
                                                    },
                                                    _vm._l(
                                                      deliverable.assignedToList,
                                                      function(
                                                        assignedTo,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "assigned-user",
                                                          _vm._g(
                                                            {
                                                              key: assignedTo,
                                                              style:
                                                                (index > 0
                                                                  ? "margin-left: -6px"
                                                                  : "") +
                                                                "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                                                                index,
                                                              attrs: {
                                                                "show-name": false,
                                                                small: true,
                                                                "assigned-to": assignedTo
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _c("div", [_vm._v(" -- ")])
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-left pa-3 font-14",
                                    staticStyle: {
                                      "background-color": "var(--v-white-base)"
                                    }
                                  },
                                  _vm._l(
                                    _vm.usersForProject(deliverable.projectId),
                                    function(user) {
                                      return _c(
                                        "div",
                                        {
                                          key: user.userId,
                                          staticClass:
                                            "row-format align-center text-left py-1 pointer"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  deliverable.assignedToList,
                                                expression:
                                                  "deliverable.assignedToList"
                                              }
                                            ],
                                            staticStyle: {
                                              width: "20px",
                                              height: "20px",
                                              "border-radius": "4px"
                                            },
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              value: user.userId,
                                              checked: Array.isArray(
                                                deliverable.assignedToList
                                              )
                                                ? _vm._i(
                                                    deliverable.assignedToList,
                                                    user.userId
                                                  ) > -1
                                                : deliverable.assignedToList
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  var $$a =
                                                      deliverable.assignedToList,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = user.userId,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          deliverable,
                                                          "assignedToList",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          deliverable,
                                                          "assignedToList",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      deliverable,
                                                      "assignedToList",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function($event) {
                                                  return _vm.setAssignedToList(
                                                    deliverable
                                                  )
                                                }
                                              ]
                                            }
                                          }),
                                          user.userId
                                            ? _c("assigned-user", {
                                                staticClass: "mx-2",
                                                attrs: {
                                                  "assigned-to": user.userId,
                                                  "show-name": true
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "td",
                        { staticClass: "content" },
                        [
                          _c("date-selector", {
                            attrs: {
                              micro: true,
                              "micro-font": "font-14",
                              date: deliverable.startDate,
                              label: "&nbsp;"
                            },
                            on: {
                              change: function($event) {
                                return _vm.setStartDate(deliverable, $event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "content" },
                        [
                          _c("date-selector", {
                            attrs: {
                              micro: true,
                              "micro-font": "font-14",
                              date: deliverable.dueDate,
                              label: "&nbsp;"
                            },
                            on: {
                              change: function($event) {
                                return _vm.setDueDate(deliverable, $event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "content" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-click": true,
                                "close-on-content-click": false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        deliverable.comments.length
                                          ? _c(
                                              "div",
                                              _vm._g(
                                                {
                                                  staticClass: "pointer",
                                                  staticStyle: {
                                                    position: "relative"
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "gray_70",
                                                      size: "20"
                                                    }
                                                  },
                                                  [_vm._v("comment")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row-format centered brand-bold",
                                                    staticStyle: {
                                                      position: "absolute",
                                                      top: "-6px",
                                                      left: "12px",
                                                      width: "16px",
                                                      height: "16px",
                                                      "border-radius": "10px",
                                                      color:
                                                        "var(--v-white-base)",
                                                      "background-color":
                                                        "var(--v-secondary-base)"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "font-12"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            deliverable.comments
                                                              .length
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              _vm._g(
                                                { staticClass: "pointer" },
                                                on
                                              ),
                                              [_vm._v(" ")]
                                            )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pa-4",
                                  staticStyle: {
                                    "background-color": "var(--v-white-base)",
                                    "min-width": "400px",
                                    "max-width": "400px",
                                    "min-height": "200px"
                                  }
                                },
                                [
                                  _c("activity", {
                                    attrs: {
                                      deliverable: deliverable,
                                      project: deliverable.project,
                                      client: deliverable.client,
                                      "user-list":
                                        _vm.usersByProject[
                                          deliverable.projectId
                                        ]
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        { staticClass: "content" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-click": true,
                                "close-on-content-click": false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            { staticClass: "pointer" },
                                            on
                                          ),
                                          [
                                            deliverable.tasks.length
                                              ? _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getTaskCountLabel(
                                                        deliverable.tasks
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _c("div", [_vm._v(" ")])
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pa-4",
                                  staticStyle: {
                                    "min-width": "300px",
                                    "max-width": "400px",
                                    "background-color": "var(--v-white-base)"
                                  }
                                },
                                [
                                  _c("tasks", {
                                    attrs: { "detail-view": true },
                                    on: {
                                      change: function($event) {
                                        return _vm.setTasks(deliverable, $event)
                                      }
                                    },
                                    model: {
                                      value: deliverable.tasks,
                                      callback: function($$v) {
                                        _vm.$set(deliverable, "tasks", $$v)
                                      },
                                      expression: "deliverable.tasks"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c("td", { staticClass: "content" }, [
                        _c(
                          "div",
                          { staticClass: "row-format centered" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "edit-deliverable",
                                      deliverable
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-symbols-rounded",
                                    attrs: { size: "20" }
                                  },
                                  [_vm._v("edit")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "close-on-content-click": true,
                                  "close-on-click": true,
                                  bottom: "",
                                  "nudge-bottom": "30"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              { attrs: { icon: "" } },
                                              scope.on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.value
                                                      ? "$arrowUp"
                                                      : "$moreHorizontal"
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("div", { staticClass: "more-menu" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "more-menu-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "edit-deliverable",
                                            deliverable
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" View details ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "more-menu-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.getShareLink(deliverable)
                                        }
                                      }
                                    },
                                    [_vm._v("Share")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "more-menu-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.confirmDuplicate(
                                            deliverable
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Duplicate")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "more-menu-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleArchive(deliverable)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            deliverable.archived
                                              ? "Unarchive"
                                              : "Archive"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "more-menu-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.confirmDeleteDeliverable(
                                            deliverable
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Delete")]
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                }),
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "footer",
                      staticStyle: { border: "none" },
                      attrs: { colspan: "10" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pointer",
                          staticStyle: { width: "fit-content" },
                          on: {
                            click: function($event) {
                              return _vm.addDeliverable(group)
                            }
                          }
                        },
                        [_vm._v("+ Add task")]
                      )
                    ]
                  )
                ])
              ],
              2
            )
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }