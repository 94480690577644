<template>
	<div>
		<v-data-table
			:items="recurringDeliverables"
			:hide-default-footer="true"
			:items-per-page="-1"
			:headers="headers"
			class="pointer"
			@click:row="openRecurringDeliverable($event.id)"
		>
			<template v-slot:item.interval="{ item }">
				<div class="row-format align-center mr-2" v-if="formatInterval">
					<v-icon size="14" style="margin-bottom:1px; margin-right:4px">autorenew</v-icon>
					<div>{{ getLabel(item) }}</div>
				</div>
			</template>
			<template v-slot:item.subtasks="{ item }">
				{{taskCountLabel(item.deliverable.deliverable)}}
			</template>
			<template v-slot:item.nextRunTime="{ item }">
				<span v-if="item.nextRunTime">{{DateTime.fromISO(item.nextRunTime).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}}</span>
				<span v-else>--</span>
			</template>
			<template v-slot:item.status="{ item }">
				<span style="text-transform: capitalize">{{item.enabled ? item.status.toLowerCase() : 'disabled'}}</span>
			</template>
			<template v-slot:item.deliverable.deliverable.assignedToList="{ item }">
				<div class="row-format align-center">
					<div v-if="!item.deliverable.deliverable.assignedToList.length">--</div>
					<assigned-user
							v-for="(assignedTo, index) in item.deliverable.deliverable.assignedToList"
							:key="assignedTo"
							:show-name="false"
							:small="true"
							:assigned-to="assignedTo"
							:style="
							`${
								index > 0 ? 'margin-left: -6px' : ''
							}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
						"
					></assigned-user>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import RecurringEventMixin from '@/modules/recurrence/RecurringEventMixin';
	import RecurringDeliverable from '@/modules/projects/deliverable/RecurringDeliverable';
	import AssignedUser from "@/components/AssignedUser";
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'RecurringDeliverables',

		props: ['project'],

		mixins: [RecurringEventMixin],

		components: {AssignedUser},

		data: function() {
			return {
				DateTime: DateTime,
				recurringDeliverables: [],
				recurringInit: false,
				headers: [
					{ text: 'Name', value: 'deliverable.deliverable.name' },
					{ text: 'Assigned to', value: 'deliverable.deliverable.assignedToList' },
					{ text: 'Interval', value: 'interval' },
					{ text: 'Status', value: 'status' },
					{ text: 'Next', value: 'nextRunTime' },
				],
			};
		},

		mounted() {
			this.$store.commit('stopLoading');
			this.initRecurringDeliverables();
		},

		beforeDestroy() {},

		methods: {
			initRecurringDeliverables: function() {
				this.getRecurringDeliverables(this.project.id).then((res) => {
					this.recurringDeliverables.splice(0, this.recurringDeliverables.length);
					this.recurringDeliverables.push(...res.data);
					this.recurringDeliverables.sort((a, b) => a.nextRunTime.localeCompare(b.nextRunTime));
					this.recurringInit = true;
				});
			},

			addNew: function() {
				this.openRecurringDeliverable();
			},

			nextRunTime: function(item) {
				if (item.nextRunTime) {
					return (
							'Next run ' +
							DateTime.fromISO(this.recurring.nextRunTime)
									.setZone(this.$store.state.podAccount.timeZone)
									.toLocaleString(DateTime.DATE_MED)
					);
				} else {
					return null;
				}
			},

			taskCountLabel: function(deliverable) {
				if (deliverable.tasks.length === 0) {
					return null;
				} else {
					let taskCount = deliverable.tasks.length;
					return `${taskCount} tasks`;
				}
			},

			openRecurringDeliverable: function(id = null) {
				let binding = {
					projectId: this.project.id,
          clientId: this.project.client.id,
					id: id,
					feeSchedule: this.project.feeSchedule,
					projectType: this.projectType
				};
				this.$store.state.globalModalController.openModal(RecurringDeliverable, binding).then((res) => {
					if (res) {
						let ix = this.recurringDeliverables.findIndex((d) => d.id === id);

						if (ix > -1) {
							this.recurringDeliverables.splice(ix, 1);
						}

						if (!res.deleted) {
							this.recurringDeliverables.push(res);
							this.recurringDeliverables.sort((a, b) => a.nextRunTime.localeCompare(b.nextRunTime));
						}
					}
				});
			},
		},

		computed: {
			projectType: function(){
				return this.$store.getters.getProjectType(this.project.projectTypeId);
			}
		},
	};
</script>

<style scoped lang="scss"></style>
