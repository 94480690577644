<template>
	<v-container fluid class="mt-0 pt-0" id="fee-schedule-edit" v-if="feeSchedule">
		<v-row dense class="mb-6">
			<v-col cols="12">
				<h2>
					<div>{{ project.name }}</div>
				</h2>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="4" class="label">{{ $t('invoice.project-fee-dialog.fee-type') }}</v-col>
			<v-col cols="8">
				<v-select :items="feeTypes" v-model="feeSchedule.feeType" dense item-text="label" item-value="value">
					<template v-slot:item="{ item }">
						<span style="font-size:16px">{{ item }}</span>
					</template>
				</v-select>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="4" class="label">{{ $t('invoice.project-fee-dialog.amount') }}</v-col>
			<v-col cols="8">
				<v-text-field
					v-model="feeSchedule.amount"
					:prefix="currencySymbol"
					dense
					type="number"
					@keyup.enter="saveEditFees()"
				></v-text-field>
			</v-col>
		</v-row>
		<v-row dense v-if="feeSchedule.feeType === 'Retainer'">
			<v-col cols="4" class="label">{{ $t('invoice.project-fee-dialog.frequency') }}</v-col>
			<v-col cols="8">
				<v-select :items="schedule" v-model="feeSchedule.retainerSchedule" dense>
					<template v-slot:item="{ item }">
						<span>{{ item }}</span>
					</template>
				</v-select>
			</v-col>
		</v-row>
		<v-row dense v-if="feeSchedule.feeType === 'Retainer'">
			<v-col cols="4" class="label">{{ $t('invoice.project-fee-dialog.retainer-start') }}</v-col>
			<v-col cols="8">
				<date-selector :date="feeSchedule.retainerStart" @change="feeSchedule.retainerStart = $event"></date-selector>
			</v-col>
		</v-row>
		<v-row dense class="pt-6">
			<v-col cols="4" class="label"
				>{{ $t('invoice.project-fee-dialog.hour-estimate') }}<br /><span
					style="font-style: italic; font-size:12px; color:var(--v-gray_70-base)"
					class="ma-0 pa-0"
					>{{ $t('global.optional') }}</span
				></v-col
			>
			<v-col cols="4">
				<v-text-field
					v-model="feeSchedule.estimateMin"
					:label="$t('invoice.project-fee-dialog.minimum')"
					dense
					type="number"
					@keyup.enter="saveEditFees()"
				></v-text-field>
			</v-col>
			<v-col cols="4">
				<v-text-field
					v-model="feeSchedule.estimateMax"
					:label="$t('invoice.project-fee-dialog.maximum')"
					@keyup.enter="saveEditFees()"
					dense
					type="number"
				></v-text-field>
			</v-col>
		</v-row>

		<v-row class="mt-4">
			<v-col cols="12">
				<v-btn class="primary-action"  width="180" @click="saveEditFees()">{{ $t('global.save') }}</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	// import ConfirmDialog from '@/components/ConfirmDialog';
	import ProjectService from '@/modules/projects/ProjectService';
	import jsonPatch from 'fast-json-patch';
	import DateSelector from '@/components/DateSelector';
	export default {
		name: 'ProjectFeeScheduleModal',
		components: { DateSelector },
		props: {
			projectId: { type: String, required: true },
		},

		// components: { ConfirmDialog },

		data: function() {
			return {
				projectService: new ProjectService(),
				project: null,
				// editFeesDialog: false,
				confirmDeleteDialog: false,
				feeSchedule: null,

				feeTypes: [
					{label:'Hourly',value:'Hourly',},
					{label:'Fixed Price',value:'Fixed Price',},
					{label:'Recurring',value:'Retainer',},
					{label:'Per Item',value:'Per Item',}
				],

				schedule: ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Bi-Annually', 'Annually'],

				observer: null,
			};
		},

		mounted() {
			this.getProject();
			// this.getFeeSchedule();
			// this.observer = jsonPatch.observe(this.project);
			// console.log(this.feeSchedule);
		},

		beforeDestroy() {},

		computed: {
			currencySymbol: function(){
				return this.$formatters.currencySymbol(this.$store.state.defaultCurrency);
			}
		},

		methods: {
			getFeeSchedule: function() {
				if (this.project.feeSchedule) {
					this.feeSchedule = JSON.parse(JSON.stringify(this.project.feeSchedule));
					return;
				}
				this.createFeeSchedule();
			},
			createFeeSchedule: function() {
				this.feeSchedule = {
					feeType: 'Hourly',
					amount: 0,
					retainerSchedule: 'Monthly',
					estimateMin: null,
					estimateMax: null,

					fromProposalId: null,
					fromProposalSignedDate: null,
					updatedBy: null,
					updatedDate: null,
				};
			},
			saveEditFees: function() {
				this.project.feeSchedule = this.feeSchedule;
				const patch = jsonPatch.generate(this.observer, true);

				this.projectService
					.patchProject(this.project.id, patch)
					.then((res) => {
						const project = res.data;
						console.log('patchProject', project);
						this.$emit('update-project-fees', project);
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
			getProject: function() {
				this.projectService
					.getProjectDetail(this.projectId)
					.then((res) => {
						this.project = res.data;
						this.observer = jsonPatch.observe(this.project);
						this.getFeeSchedule();
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
		},
	};
</script>

<style lang="scss">
	#fee-schedule-edit {
		.label {
			text-align: left;
			letter-spacing: 0.2px;
		}
	}
</style>
