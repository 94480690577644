var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.local
    ? _c("div", [
        _c(
          "div",
          { staticClass: "type-modal-dialog-div" },
          [
            _c(
              "v-btn",
              {
                staticClass: "close-dialog",
                attrs: { icon: "", rounded: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.handleClose()
                  }
                }
              },
              [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$close")])],
              1
            ),
            _c(
              "div",
              { staticClass: "modal-padding column-format gap-3" },
              [
                _c("h3", { staticClass: "modal-title" }, [
                  _vm._v("Request type settings")
                ]),
                _c("v-text-field", {
                  attrs: {
                    "hide-details": "",
                    "persistent-placeholder": "",
                    dense: "",
                    outlined: "",
                    label: "Type"
                  },
                  model: {
                    value: _vm.local.type,
                    callback: function($$v) {
                      _vm.$set(_vm.local, "type", $$v)
                    },
                    expression: "local.type"
                  }
                }),
                _c("v-select", {
                  attrs: {
                    items: _vm.templates,
                    "item-value": "id",
                    "item-text": "name",
                    "hide-details": "",
                    "persistent-placeholder": "",
                    dense: "",
                    outlined: "",
                    label: "Attached form"
                  },
                  model: {
                    value: _vm.local.attachedFormId,
                    callback: function($$v) {
                      _vm.$set(_vm.local, "attachedFormId", $$v)
                    },
                    expression: "local.attachedFormId"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "text-left" },
                  [
                    _c("div", { staticClass: "brand-medium" }, [
                      _vm._v("Status options")
                    ]),
                    _c(
                      "draggable",
                      {
                        attrs: { group: "options", handle: ".nestedHandle" },
                        model: {
                          value: _vm.local.statusList,
                          callback: function($$v) {
                            _vm.$set(_vm.local, "statusList", $$v)
                          },
                          expression: "local.statusList"
                        }
                      },
                      _vm._l(_vm.local.statusList, function(status, index) {
                        return _c(
                          "div",
                          {
                            key: status + "-" + index,
                            staticClass: "row-format align-center mb-2"
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "nestedHandle mt-1",
                                staticStyle: { cursor: "pointer" }
                              },
                              [_vm._v(" $dragSecondary ")]
                            ),
                            _c("v-text-field", {
                              staticClass: "ml-2",
                              attrs: {
                                value: status,
                                dense: true,
                                "hide-details": true
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleOptionChange(index, $event)
                                }
                              }
                            }),
                            _c(
                              "v-icon",
                              {
                                staticClass: "pointer ml-4",
                                attrs: { small: "" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.removeOption(index)
                                  }
                                }
                              },
                              [_vm._v("$close")]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "font-14 font-primary pointer mt-3",
                        on: {
                          click: function($event) {
                            return _vm.addOption()
                          }
                        }
                      },
                      [_vm._v("+ Add status")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "pt-2 pb-3 row-format centered gap-2",
            staticStyle: { "border-top": "1px solid var(--v-gray_50-base)" }
          },
          [
            _c(
              "v-btn",
              {
                staticClass: "super-action",
                attrs: { width: "150" },
                on: {
                  click: function($event) {
                    return _vm.handleSave()
                  }
                }
              },
              [_vm._v("Save")]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }