var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.branding
    ? _c(
        "div",
        { staticClass: "mb-8" },
        [
          _c(
            "page-header",
            {
              staticClass: "pb-6",
              attrs: {
                "bread-crumbs": [
                  { to: "/home", label: "Home" },
                  { label: "Client portal" }
                ]
              }
            },
            [_vm._v(" Customization ")]
          ),
          _c(
            "div",
            { staticClass: "align-center", attrs: { id: "detail-wrapper" } },
            [
              _c(
                "div",
                { staticClass: "row-format mb-4 gap-3 flex-wrap" },
                [
                  _vm._l(_vm.pages, function(page) {
                    return _c(
                      "div",
                      {
                        key: page.value,
                        class:
                          "page-link " +
                          (_vm.currentView === page.value
                            ? "page-link-active"
                            : ""),
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.setView(page.value)
                          }
                        }
                      },
                      [_c("div", [_vm._v(_vm._s(page.label))])]
                    )
                  }),
                  _vm.currentView !== "Custom"
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-auto super-action",
                          on: {
                            click: function($event) {
                              return _vm.handleSave()
                            }
                          }
                        },
                        [_vm._v("Save")]
                      )
                    : _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": "",
                            "close-on-click": "",
                            bottom: "",
                            left: "",
                            "nudge-bottom": "30",
                            "nudge-left": "15"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        { staticClass: "ml-auto super-action" },
                                        on
                                      ),
                                      [_vm._v("+ Add page")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1103748889
                          )
                        },
                        [
                          _c("div", { staticClass: "more-menu" }, [
                            _c(
                              "div",
                              {
                                staticClass: "more-menu-item",
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.Custom.addCustomPage(
                                      "Internal"
                                    )
                                  }
                                }
                              },
                              [_vm._v("Custom page")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "more-menu-item",
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.Custom.addCustomPage(
                                      "External"
                                    )
                                  }
                                }
                              },
                              [_vm._v("External link")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "more-menu-item",
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.Custom.addCustomPage(
                                      "iFrame"
                                    )
                                  }
                                }
                              },
                              [_vm._v("iFrame")]
                            )
                          ])
                        ]
                      )
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "body-content" },
                [
                  _vm.currentView === "Brand"
                    ? _c("brand", {
                        attrs: { branding: _vm.branding },
                        on: {
                          refresh: function($event) {
                            return _vm.getCustomBranding()
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.currentView === "Login"
                    ? _c("login-screen", {
                        attrs: {
                          branding: _vm.branding,
                          "mce-config": _vm.mceConfig
                        }
                      })
                    : _vm._e(),
                  _vm.currentView === "Custom"
                    ? _c("custom-pages", {
                        ref: "Custom",
                        attrs: {
                          branding: _vm.branding,
                          "mce-config": _vm.mceConfig
                        }
                      })
                    : _vm._e(),
                  _vm.currentView === "Code"
                    ? _c("custom-code", { ref: "Code" })
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm.confirm
            ? _c("confirm-dialog", {
                attrs: {
                  dialog: _vm.confirm,
                  "body-text":
                    "You have unsaved changes.  Are you sure you want to leave the page?"
                },
                on: { confirm: _vm.confirmLeave, close: _vm.cancelLeave }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }