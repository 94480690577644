export default {
    moxie: {
        primary: '#5C00E2' ,
        secondary: '#C90360' ,
        accent: '#E7BD5B' ,
        sidebar: '#ebedef',
    },
    professional: {
        primary: '#003366' ,
        secondary: '#708090' ,
        accent: '#C0C0C0' ,
        sidebar: '#E1E1E1'
    },
    warm: {
        primary: '#EE6C4D' ,
        secondary: '#F4A261' ,
        accent: '#FFF1E6' ,
        sidebar: '#F5EDE6'
    },
    eco: {
        primary: '#1B4D3E' ,
        secondary: '#9DC183' ,
        accent: '#FFDA03' ,
        sidebar: '#E5E5E0'
    },
    bold: {
        primary: '#007BA7' ,
        secondary: '#40E0D0' ,
        accent: '#FF7F50' ,
        sidebar: '#E6E6FA'
    },
    minimalist: {
        primary: '#36454F' ,
        secondary: '#B2BEB5' ,
        accent: '#008080' ,
        sidebar: '#DCDCDC'
    },
    party: {
        primary: '#FF6EC7' ,
        secondary: '#8A2BE2' ,
        accent: '#39FF14' ,
        sidebar: '#E6E6FA'
    }
}