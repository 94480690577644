<template>
	<div id="recurring-expense-detail" v-if="isReady">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>

		<div class="modal-padding">
			<h3 class="modal-title">{{ $t('recurring.payments.heading') }}</h3>

			<recurring-event-config :recurring="recurring"></recurring-event-config>

			<hr class="mt-3 mb-6" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" />

			<v-container fluid class="px-0 mt-2 mb-0">
				<v-row>
					<v-col cols="12" sm="7" class="column-format gap-3">
						<div class="row-format">
							<amount-input
								hide-details
								dense
								outlined
								:label="$t('income.amount-label')"
								:placeholder="$t('income.amount-placeholder')"
								:prefix="currencySymbol"
								type="text"
								v-model="paymentCopy.amount"
								persistent-placeholder
							></amount-input>

							<div style="width: 60%" class="ml-3">
								<v-autocomplete
									hide-details
									persistent-placeholder
									dense
									outlined
									:label="$t('account.currency')"
									:items="currencies"
									item-text="label"
									item-value="value"
									v-model="paymentCopy.currency"
								>
								</v-autocomplete>
							</div>
						</div>

						<v-select
							dense
							outlined
							persistent-placeholder
							hide-details
							:label="$t('invoice.mark-paid-modal.payment-type')"
							v-model="paymentCopy.paymentProvider"
							:items="paymentProviders"
						></v-select>

						<v-text-field
							dense
							outlined
							hide-details
							:label="$t('income.id-label')"
							:placeholder="$t('income.id-placeholder')"
							v-model="paymentCopy.identifier"
							persistent-placeholder
						></v-text-field>

						<v-textarea
							dense
							outlined
							hide-details
							:label="$t('income.description')"
							:placeholder="$t('income.description-placeholder')"
							v-model="paymentCopy.description"
							:auto-grow="true"
							rows="1"
							persistent-placeholder
						></v-textarea>
					</v-col>
					<v-col cols="12" sm="5">
						<div class="column-format gap-3">
							<v-autocomplete
								dense
								outlined
								hide-details
								persistent-placeholder
								:label="$t('client.client')"
								:placeholder="$t('income.link-to-client')"
								v-model="paymentCopy.clientId"
								:items="clientsPlusClear"
								item-text="name"
								item-value="id"
								@change="clientChanged"
								ref="clientId"
							></v-autocomplete>

							<v-autocomplete
								v-if="paymentCopy.clientId"
								dense
								outlined
								hide-details
								persistent-placeholder
								:label="$t('projects.project')"
								:placeholder="$t('income.link-to-project')"
								:disabled="noClientDisabled"
								v-model="paymentCopy.projectId"
								:items="clientProjectsPlusClear"
								item-text="name"
								item-value="id"
								@change="projectChanged"
								no-data-text="No projects for selected client"
							></v-autocomplete>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div style="border-top: 1px solid var(--v-gray_30-base)" class="row-format centered gap-1 mt-2 pa-2">
			<v-btn class="super-action" :width="$vuetify.breakpoint.smAndUp ? '160' : '128'" @click="saveRecurringPayment">
				{{ $t(!id ? 'global.create' : 'global.save') }}
			</v-btn>
			<v-btn icon @click="deleteRecurringPayment()" v-if="recurring.id">
				<v-icon size="20" class="material-symbols-rounded">delete</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
	import currencies from '@/assets/data/currencies.json';
	import RecurringEventConfig from '@/modules/recurrence/RecurringEventConfig';
	import RecurringEventMixin from '@/modules/recurrence/RecurringEventMixin';
	import ConfirmModal from '@/components/ConfirmModal';
	import PaymentsMixin from '@/modules/accounting/payments/PaymentsMixin';
	import AmountInput from '@/components/AmountInput.vue';

	export default {
		name: 'RecurringPayment',

		props: ['id'],

		mixins: [RecurringEventMixin, PaymentsMixin],

		components: { AmountInput, RecurringEventConfig },

		data: function() {
			return {
				currencies: currencies,
				recurring: null,
				isReady: false,
			};
		},

		mounted() {
			this.initialize();
			this.initRelatedData();
		},

		beforeDestroy() {},

		methods: {
			initialize: function() {
				if (this.id) {
					this.getRecurringEvent(this.id).then((res) => {
						this.paymentCopy = res.data.income;
						this.recurring = res.data;
						this.isReady = true;
					});
				} else {
					this.paymentCopy = this.initializePayment();
					this.recurring = this.initRecurring('INCOME');
					this.isReady = true;
				}
			},

			saveRecurringPayment: function() {
				this.saveRecurringEvent(this.recurring, this.paymentCopy, 'INCOME').then((res) => {
					this.$emit('result', res.data);
				});
			},

			deleteRecurringPayment: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this recurring payment?',
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.deleteRecurringEvent(this.id).then(() => {
							this.$emit('deleted', this.id);
						});
					}
				});
			},

			initializePayment: function() {
				let result = {
					id: null,
					clientId: null,
					projectId: null,
					amount: 0,
					currency: this.$store.state.defaultCurrency,
					paymentProvider: 'BANK_TRANSFER',
					description: null,
					identifier: null,
					attachments: [],
				};

				return result;
			},

			initRelatedData: function() {
				this.getClients();
				this.getProjects();
			},

			handleClose: function() {
				this.$emit('close');
			},

			handleDurationKeydown(event) {
				if (event.key === '.') {
					event.preventDefault();
				}
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	#recurring-payment-detail {
		min-width: 684px;
		max-width: 684px;
		background-color: var(--v-white-base);
	}
</style>
