<template>
	<div class="font-14">
		<div
			class="px-2 pt-2"
			v-for="(note, index) in notes"
			:key="note.id"
      style="border-bottom: 1px solid var(--v-gray_30-base)"
		>
			<div class="note-container text-left">
				<div class="row-format" style="align-items: center">
					<v-icon size="14" style="color: var(--v-gray_70-base)">$time</v-icon>
					<div class="font-grey font-12 ml-1">{{ $DateTime.humanDateTime(note.timestamp) }}</div>
					<div class="delete-button pointer" style="margin-left: auto; padding-right: 1px">
						<v-icon small @click.native="confirmDeleteNote(note, index)">$delete</v-icon>
					</div>
				</div>
        <div v-if="activeNote !== note.id" @click="activeNote = note.id" v-html="note.entry"></div>
				<editor
          v-else
					v-model="note.entry"
					:ref="note.id"
          :api-key="$store.getters.getTinyMceKey"
					:inline="true"
					:init="mceConfig(note)"
					class="no-wrap-editor"
					style="width: 100%"
					@update="saveNote($event, note)"
          @onblur="deactivateNote(note)"
				>
				</editor>
			</div>
		</div>
		<confirm-dialog
			:dialog="confirmDeleteDialog"
			@close="cancelDelete()"
			@confirm="handleDeleteNote()"
			heading-text="Confirm"
			yes-text="Yes"
			no-text="No"
			:body-text="$t('productivity.delete-note-confirm')"
		>
		</confirm-dialog>
	</div>
</template>

<script>
	import smoothReflow from 'vue-smooth-reflow';
	import UserNoteService from './UserNoteService';
	import moment from 'moment';
	import { v4 as uuidv4 } from 'uuid';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import DateTime from '@/modules/utils/HDateTime';
	import editor from '@tinymce/tinymce-vue';
	import marked from 'marked';

	export default {
		mixins: [smoothReflow],

		name: 'UserNotes',

		props: ['manageEvents'],

		components: {
			editor,
			ConfirmDialog,
		},

		data() {
			return {
				userNoteService: new UserNoteService(),
				confirmDeleteDialog: false,
				deleteNote: null,
				deleteNoteIndex: null,
				updateKey: null,
        activeNote: null,
			};
		},

		mounted() {
			if (this.manageEvents) {
				this.getUserNotes();
				this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
				this.$store.state.eventBus.$on(`u-${this.userId}.userNote`, this.handleUserNoteEvent);
			}
		},

		beforeDestroy() {
			if (this.manageEvents) {
				this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
				this.$store.state.eventBus.$off(`u-${this.userId}.userNote`, this.handleUserNoteEvent);
			}
		},

		methods: {
      mceConfig: function(note) {
        return {
          menubar: false,
          inline: true,
          paste_as_text: false,
          paste_data_images: true,
          table_style_by_css: true,
          browser_spellcheck: true,
          statusbar: false,
          placeholder: 'Write something clever here...',
          extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
          forced_root_block: 'div',
          plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
          contextmenu: false,
          indentation: '12pt',
          skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
          content_css: this.$vuetify.theme.dark ? 'dark' : '',
          content_style: '.mce-content-body { text-align: start }',
          toolbar: [
            'bold bullist underline forecolor | formatting_group | savebutton',
          ],
          fontsize_formats: '10px 12px 14px 16px 18px 20px 22px 24px 28px 32px 40px 48px',
          toolbar_groups: {
            formatting_group: {
              icon: 'format',
              tooltip: 'Formatting',
              items: 'fontsizeselect fontselect | blockquote italic numlist indent outdent table | alignleft aligncenter alignright alignjustify | removeformat',
            },
            media_group: {
              icon: 'embed',
              tooltip: 'Media',
              items: ' link unlink media',
            },
          },
          setup: (editor) => {
            editor.ui.registry.addButton('savebutton', {
              icon: 'new-save',
              tooltip: 'Save',
              onAction: function () {
                this.saveNote(note.entry, note)
                this.deactivateNote()
              }.bind(this)
            });

						editor.on('init', () => {
							editor.focus();
						});

            editor.ui.registry.addIcon('new-save','<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z"/></svg>');
          }
        }
      },

			handleAccountChange: function() {
				this.getUserNotes();
			},

			handleUserNoteEvent: function(event) {
				if (event.message.accountId !== this.$store.getters.getAccountId) {
					return;
				} else if (this.$store.state.productivity.updateKey === event.message.updateKey) {
					return;
				}

				let ix = -1;
				for (let i = 0; i < this.notes.length; i++) {
					if (this.notes[i].id === event.message.id) {
						ix = i;
						break;
					}
				}

				if (event.channel.endsWith('created') && ix === -1) {
					this.notes.unshift(event.message);
				} else if ((event.channel.endsWith('updated') || event.channel.endsWith('deleted')) && ix > -1) {
					if (event.channel.endsWith('deleted')) {
						this.notes.splice(ix, 1);
					} else {
						this.notes.splice(ix, 1, event.message);
					}
				}
			},

			getUserNotes: function() {
				this.userNoteService.getUserNotes().then((res) => {
					this.notes.splice(0, this.notes.length);
					this.notes.push(...res.data);
					this.notes.sort(this.sortNote);
				});

				this.notes.forEach((n) => {
					if (n.format === 'Markdown') {
						n.entry = this.formatMarkdown(n.entry);
					}
				});
			},

			formatMarkdown: function(comment) {
				let m = marked(comment, { breaks: true });
				m = m.replaceAll('<a href', '<a target="_blank" href');
				return m;
			},

			addNew: function() {
				this.notes.unshift({
					id: null,
					entry: '',
					timestamp: DateTime.now().toISO(),
				});
			},

			saveNote: function(entry, note) {
				let updateKey = uuidv4();
				note.entry = entry;
				note.updateKey = updateKey;
				this.$store.state.productivity.updateKey = updateKey;

				if (note.id === null && this.$validations.isEmpty(entry)) {
					return;
				}

				if (note.id === null) {
					this.userNoteService.createUserNote(note).then((res) => {
						Object.assign(note, res.data);
					});
				} else {
					this.userNoteService.updateUserNote(note.id, note).then((res) => {
						Object.assign(note, res.data);
					});
				}
			},

      deactivateNote: function(note) {
				this.saveNote(note.entry,note);
				this.activeNote = null
      },

			confirmDeleteNote: function(note, index) {
				if (this.$validations.isEmpty(note.entry) && note.id === null) {
					this.notes.splice(index, 1);
				} else {
					this.deleteNote = note;
					this.deleteNoteIndex = index;
					this.confirmDeleteDialog = true;
				}
			},

			cancelDelete: function() {
				this.deleteNote = null;
				this.deleteNoteIndex = null;
				this.confirmDeleteDialog = false;
			},

			handleDeleteNote: function() {
				if (this.deleteNote.id === null) {
					this.notes.splice(this.deleteNoteIndex, 1);
				} else {
					this.userNoteService.deleteUserNote(this.deleteNote.id).then(() => {
						// No need to delete from array. This is handled by handleUserNoteEvent
						// this.notes.splice(this.deleteNoteIndex, 1);
					});
				}
				this.cancelDelete();
			},

			sortNote: function(a, b) {
				if (moment(a.timestamp).isBefore(moment(b.timestamp))) {
					return 1;
				} else {
					return -1;
				}
			},
		},

		computed: {
			userId: function() {
				return this.$store.getters.getLoggedInUserId;
			},

			notes: function() {
				return this.$store.state.productivity.notes;
			},
		},

		watch: {},
	};
</script>

<style lang="scss" scoped>
	.markdown {
		font-weight: 300;
		font-size: 14px;
		::v-deep .v-textarea {
			font-weight: 300;
			font-size: 14px;
		}
	}

	.note-container {
		.delete-button {
			visibility: hidden;
		}

		&:hover {
			.delete-button {
				visibility: visible;
			}
		}
	}
</style>
