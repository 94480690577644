<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-subscription')" @action="navigateToSubscriptions()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small :client="client" />
			<div class="ml-2">{{ client.name }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ $t('notifications.subscription-cancelled') }}</div>
			<div class="font-12">
				<span>A subscription for {{ product.name }} was cancelled for {{client.name}}. </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";

	export default {
		name: 'SubscriptionCancelledNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar, NotificationLayout },

		data: function() {
			return {
				client: this.notificationPayload.client,
				product: this.notificationPayload.product,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			navigateToSubscriptions: function() {
				this.$router.push(`/stripe?v=Subscriptions`);
			},
		},

		computed: {
		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
