<template>
	<div class="column-format gap-4">
		<div>
			<div class="">Width ({{element.width}}%)</div>
			<v-slider
					v-model="element.scale"
					:min="10"
					:max="100"
					:step="1"
					hide-details
					dense
					@input="updated"
					thumb-label
			></v-slider>
		</div>
		<div>
			<div class="">Border radius ({{element.br}}px)</div>
			<v-slider
					v-model="element.br"
					:min="0"
					:max="200"
					:step="1"
					hide-details
					dense
					@input="updated"
					thumb-label
			></v-slider>
		</div>
		<v-select v-model="element.alignment" :items="alignment" item-text="label" item-value="value" hide-details dense outlined label="Alignment" @change="updated"></v-select>

		<div class="row-format gap-1">
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pt" label="Padding top" @input="updated"></v-text-field>
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pb" label="Padding bottom" @input="updated"></v-text-field>
		</div>

		<div class="image-grid">
			<div
				v-for="result in imageAssets"
				:key="result.id"
				:style="`background-image: url(${result.thumbnail});`"
				class="image-container custom-image-container"
				@click="setCustomImage(result)"
			>
				<v-btn class="secondary-action" icon x-small @click.stop="confirmDelete(result)"
					><v-icon size="16">$delete</v-icon></v-btn
				>
			</div>

			<vue-dropzone
				ref="fileDropzone"
				id="dropzone"
				class="image-container no-image-container"
				v-on:vdropzone-success="uploadSuccess"
				v-on:vdropzone-error="uploadFailure"
				v-on:vdropzone-sending="addFileUploadAuthHeader"
				:use-custom-slot="true"
				:options="dropzoneOptions"
			>
				<div>
					<v-icon>$vuetify.icons.upload</v-icon>
					<div class="font-12">{{ $t('proposal.cover.upload') }}</div>
				</div>
			</vue-dropzone>
		</div>
	</div>
</template>

<script>
	import ImageAssetService from '@/services/ImageAssetService';
	import AccountService from '@/modules/account/AccountService';
	import vueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'ImageBlockConfig',

		props: ['template', 'item'],

		components: {vueDropzone},

		data: function() {
			return {
				element: {... this.item},
				imageAssetService: new ImageAssetService(),
				accountService: new AccountService(),
				tags: [this.template.id],
				imageAssets: [],
				alignment: [
					{label:'Left', value:'left'},
					{label:'Right', value:'right'},
					{label:'Center', value:'center'}
				]
			};
		},

		mounted() {
			this.getImageAssets();
		},

		beforeDestroy() {},

		methods: {
			getImageAssets: function() {
				this.imageAssetService.getImageAssets(this.tags).then((res) => {
					this.imageAssets.splice(0, this.imageAssets.length);
					this.imageAssets.push(...res.data);
				});
			},

			setCustomImage: function(imageAsset) {
				this.element.url = imageAsset.fullSize;
				this.updated();
			},

			confirmDelete: function(imageAsset) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this image from your form library?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.imageAssetService.deleteImageAsset(imageAsset.id).then(() => {
							let ix = this.imageAssets.findIndex(i => i.id === imageAsset.id);
							if(ix > -1){
								this.imageAssets.splice(ix,1);
							}
							if(this.element.url === imageAsset.fullSize){
								this.element.url = null;
								this.updated();
							}
						})
					}
				})
			},

			updated: function() {
				this.$emit('change', this.element);
			},

			uploadSuccess: function(file, response) {
				this.$refs.fileDropzone.removeFile(file);
				this.imageAssets.push(response);
				this.setCustomImage(response);
			},

			uploadFailure: function(file, message) {
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.fileDropzone.removeFile(file);
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},
		},

		computed: {
			dropzoneOptions: function() {
				return {
					url: `${this.$store.getters.getBaseUrl}/assets/images?tags=${this.tags.join(',')}`,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},
		},
	};
</script>

<style scoped lang="scss">
	.image-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 6px;

		.image-container {
			//width: 100%;
			height: 100px;
			width: 100px;
			border-radius: 2px;
			display: flex;
			align-items: flex-end;
			background-position: center;

			&.custom-image-container {
				align-items: flex-start;
				justify-content: flex-end;
				padding: 4px;

				.v-btn {
					opacity: 0;
					transition: opacity 300ms;
				}

				&:hover {
					.v-btn {
						opacity: 1;
						transition: opacity 300ms;
					}
				}
			}
		}
	}
</style>
