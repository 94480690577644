var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format text-left font-14 font-gray_70 gap-2" },
    [
      _c("div", [_vm._v("Weight (px)")]),
      _c("v-slider", {
        attrs: {
          min: 1,
          max: 50,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.weight,
          callback: function($$v) {
            _vm.$set(_vm.element, "weight", $$v)
          },
          expression: "element.weight"
        }
      }),
      _c("div", [_vm._v("Width (%)")]),
      _c("v-slider", {
        attrs: {
          min: 1,
          max: 100,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.width,
          callback: function($$v) {
            _vm.$set(_vm.element, "width", $$v)
          },
          expression: "element.width"
        }
      }),
      _c("div", [_vm._v("Padding top")]),
      _c("v-slider", {
        attrs: {
          min: 0,
          max: 50,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.pt,
          callback: function($$v) {
            _vm.$set(_vm.element, "pt", $$v)
          },
          expression: "element.pt"
        }
      }),
      _c("div", [_vm._v("Padding bottom")]),
      _c("v-slider", {
        attrs: {
          min: 0,
          max: 50,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.pb,
          callback: function($$v) {
            _vm.$set(_vm.element, "pb", $$v)
          },
          expression: "element.pb"
        }
      }),
      _c("div", [_vm._v("Color")]),
      _c("color-selector", {
        on: { input: _vm.updated },
        model: {
          value: _vm.element.color,
          callback: function($$v) {
            _vm.$set(_vm.element, "color", $$v)
          },
          expression: "element.color"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }