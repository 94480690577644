import HttpClient from '@/services/HttpClient';

export default class PlaidService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getItems() {
		return this.httpClient
			.get('/plaid/items')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	unlinkItem(itemId) {
		return this.httpClient
			.delete(`/plaid/items/${itemId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	resSync(itemId) {
		return this.httpClient
			.post(`/plaid/items/${itemId}/reSync`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	token(redirectUri,itemId) {
		return this.httpClient
			.get(`/plaid/token`, {
				params: {
					redirectUri,
					itemId,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	exchange(publicToken) {
		return this.httpClient
			.post(`/plaid/exchange?token=${publicToken}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	testWebhook(itemId){
		return this.httpClient
			.post(`/plaid/webhookTest/${itemId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
