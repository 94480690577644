<template>
	<div>
		Loading...
	</div>
</template>

<script>
export default {
	name: "AccountingRouter",

	props: [],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
		let page = localStorage.getItem('ACCOUNTING_PAGE');
		if(!page){
			page = 'invoices';
		}
		this.$router.push('/accounting/' + page);
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>