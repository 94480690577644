<template>
	<div :class="`empty-filtered-results row-format centered pa-2 ${enableClick ? 'pointer' : ''}`" @click="$emit('click')">
		<div class="row-format align-center">
			<v-icon size="25" class="mr-2">$searchBolt</v-icon>
			<div v-html="text" class="font-gray_80"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'EmptyFilteredResults',
		props: {
			text: { type: String, default: 'There aren\'t any records that match your filter. Change it up and try again.', required: false },
			enableClick: {type: Boolean, default: false, required: false}
		},
		data() {
			return {

			};
		},
		mounted() {
		},
	};
</script>

<style lang="scss" scoped>
	.empty-filtered-results {
		width: 100%;
		background-color: var(--v-white-base);
		border: none;
		border-radius: 4px;
	}
</style>
