var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-padding" },
    [
      _c(
        "v-container",
        { staticClass: "leadgen-modal", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-left pl-0", attrs: { cols: "4" } },
                [
                  _c(
                    "div",
                    { staticClass: "body-16 brand-semibold black--text" },
                    [
                      _c("v-icon", { attrs: { color: "black", left: "" } }, [
                        _vm._v("$actions")
                      ]),
                      _vm._v(_vm._s(_vm.$t("global.actions")) + " ")
                    ],
                    1
                  ),
                  _vm.actions
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "body-12 grey--text mt-6 mb-1" },
                            [_vm._v("Next phase")]
                          ),
                          _vm._l(_vm.actions, function(action) {
                            return _c(
                              "div",
                              {
                                key: action.name,
                                staticClass:
                                  "mt-4 grey-action brand-semibold pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "action-clicked",
                                      $event,
                                      action.name,
                                      _vm.card
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t(action.label)) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.showAdminActions
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "body-12 grey--text mt-6 mb-1" },
                            [_vm._v("Admin")]
                          ),
                          _vm._l(_vm.adminActions, function(action) {
                            return _c(
                              "div",
                              {
                                key: action.name,
                                staticClass:
                                  "mt-4 grey-action brand-semibold pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "action-clicked",
                                      $event,
                                      action.name,
                                      _vm.card
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t(action.label)) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ]
              ),
              _vm.client !== null
                ? _c(
                    "v-col",
                    { staticClass: "text-left pr-0", attrs: { cols: "8" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-18 brand-medium d-flex justify-start align-center gap-2"
                        },
                        [
                          _c("client-avatar", {
                            attrs: { client: _vm.card.client }
                          }),
                          _c("div", [_vm._v(_vm._s(_vm.card.client.name))])
                        ],
                        1
                      ),
                      _c("div", { staticClass: "body-12 mt-2" }, [
                        _vm.card.contact
                          ? _c("span", [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("leadgen.primary-contact"))
                                )
                              ]),
                              _c(
                                "span",
                                { staticClass: "brand-semibold mr-4" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.card.contact.firstName +
                                        " " +
                                        _vm.card.contact.lastName
                                    )
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(_vm._s(_vm.$t("leadgen.phase")))
                        ]),
                        _c("span", { class: "phase " + _vm.phaseColor }, [
                          _vm._v(_vm._s(_vm.card.cardType))
                        ])
                      ]),
                      _vm.clientIsSet
                        ? _c("notes-embed", {
                            attrs: { "client-id": _vm.client.id }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }