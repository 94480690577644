var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Accounting" }
            ]
          }
        },
        [_vm._v(" Payments & income ")]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center gap-3" },
        [
          _c("report-filter", {
            attrs: { slot: "filter", "current-view": "Payment" },
            on: {
              "clear-filter": function($event) {
                return _vm.clearFilter()
              }
            },
            slot: "filter",
            model: {
              value: _vm.filter,
              callback: function($$v) {
                _vm.filter = $$v
              },
              expression: "filter"
            }
          }),
          _c(
            "v-menu",
            {
              attrs: {
                "nudge-bottom": 0,
                elevation: 0,
                "content-class": "add-new-menu",
                bottom: "",
                left: "",
                rounded: "",
                "offset-overflow": "",
                "offset-y": "",
                transition: "slide-y-transition"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ staticClass: "ml-auto super-action" }, on),
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v(" Add payment")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { staticClass: "add-new-dropdown" },
                _vm._l(_vm.addNew, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "add-new-item",
                      on: {
                        click: function($event) {
                          return _vm.handleNewPayment(item.value)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.label) + " ")]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      !_vm.invoices.length && !_vm.income.length
        ? _c(
            "div",
            {
              staticClass: "row-format centered fill-height",
              staticStyle: { height: "calc(var(--vh) - 100px)" }
            },
            [
              _c("empty-view", {
                attrs: {
                  header: "No payments yet",
                  body:
                    "You haven’t sent any invoices or logged any payments yet. ",
                  cta: "Send an invoice",
                  "video-header": "See how it works",
                  "video-body":
                    "Learn how to get paid for your work. You can also see how to set up automatic invoices.",
                  "video-cta": "Watch the tutorial",
                  "video-id": "lOS8CCgWhTg"
                },
                on: {
                  "cta-clicked": function($event) {
                    return _vm.handleNewPayment()
                  }
                }
              })
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "mb-4",
                  model: {
                    value: _vm.paymentTab,
                    callback: function($$v) {
                      _vm.paymentTab = $$v
                    },
                    expression: "paymentTab"
                  }
                },
                [
                  _c("v-tab", { key: "0" }, [_vm._v("Payments")]),
                  _c("v-tab", { key: "1" }, [_vm._v("Recurring payments")])
                ],
                1
              ),
              _vm.paymentTab === 1
                ? _c("recurring-payment-list", { ref: "RecurringPayment" })
                : _vm._e(),
              _vm.paymentTab === 0
                ? _c("payment-table", {
                    attrs: { payments: _vm.filteredPayments },
                    on: {
                      "open-invoice": function($event) {
                        return _vm.openPayment($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
      _c(
        "basic-modal",
        { attrs: { dialog: _vm.newPayment } },
        [
          _c("new-payment", {
            on: {
              result: function($event) {
                return _vm.handleResult($event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "basic-modal",
        { attrs: { dialog: _vm.outstandingInvoice } },
        [
          _c("outstanding-invoices", {
            on: {
              result: function($event) {
                return _vm.outstandingInvoiceResult($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }