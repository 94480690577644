var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left row-format font-14" },
    [
      _c("div", { staticClass: "row-format align-center" }, [
        _c(
          "div",
          { key: _vm.refreshKey },
          [
            _vm.ourProduct
              ? _c(
                  "div",
                  {
                    staticClass: "row-format",
                    staticStyle: {
                      width: "100%",
                      "column-gap": "8px",
                      "flex-wrap": "wrap"
                    }
                  },
                  [
                    _c("div", [_vm._v(_vm._s(_vm.ourName))]),
                    !_vm.ourProduct.hourly || _vm.hours === undefined
                      ? _c(
                          "div",
                          {
                            class: _vm.editableClass,
                            staticStyle: { "text-align": "right" },
                            attrs: { contenteditable: _vm.editable },
                            on: { blur: _vm.updateQuantity }
                          },
                          [_vm._v(_vm._s(_vm.ourQuantity))]
                        )
                      : _c("div", [_vm._v(_vm._s(_vm.hours))]),
                    _c("div", [_vm._v("@")]),
                    _c(
                      "div",
                      {
                        class: _vm.editableClass,
                        staticStyle: { "text-align": "right" },
                        attrs: { contenteditable: _vm.editable },
                        on: { blur: _vm.updateRate }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$formatters.dollars(
                              _vm.ourRate,
                              true,
                              true,
                              _vm.ourCurrency,
                              4,
                              0
                            )
                          )
                        )
                      ]
                    ),
                    _c("div", [_vm._v("=")]),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.$formatters.dollars(
                            _vm.total,
                            true,
                            true,
                            _vm.ourCurrency
                          )
                        )
                      )
                    ]),
                    _vm.editable
                      ? _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: { click: _vm.handleClearProduct }
                          },
                          [_vm._v("$clear")]
                        )
                      : _vm._e(),
                    !_vm.editable
                      ? _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("$lock")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.productDialog = true
                      }
                    }
                  },
                  [_vm._v("Select product /service")]
                )
          ],
          1
        )
      ]),
      _c(
        "basic-modal",
        {
          attrs: { dialog: _vm.productDialog, persistent: true },
          on: {
            close: function($event) {
              _vm.productDialog = false
            }
          }
        },
        [
          _c("products", {
            attrs: { currency: _vm.ourCurrency },
            on: {
              close: function($event) {
                _vm.productDialog = false
              },
              select: function($event) {
                return _vm.handleSelectProduct($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }