var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "row-format align-center ml-1 " +
        (_vm.view === "Two-Week" ? "mt-1" : "")
    },
    [
      _vm.client
        ? _c("client-avatar", {
            staticStyle: { transform: "scale(.92)" },
            attrs: { "disable-click": "", "x-small": "", client: _vm.client }
          })
        : _c(
            "div",
            {
              class:
                "stopwatch row-format centered " +
                (_vm.view === "Month" ? "scaled" : "")
            },
            [
              _c("v-icon", { attrs: { size: "16", color: "white" } }, [
                _vm._v("$stopwatch")
              ])
            ],
            1
          ),
      _c("div", { staticClass: "ml-1 font-gray_90 brand-semilight" }, [
        _vm._v(_vm._s(_vm.formatSeconds(this.secondsWorked)) + " worked")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }