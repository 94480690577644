import HttpClient from "@/services/HttpClient";

export default class WorkflowService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getWorkflows() {
		return this.httpClient
			.get(`/workflows`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getAutomationCounts() {
		return this.httpClient
			.get(`/workflows/counts`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getWorkflow(id) {
		return this.httpClient
			.get(`/workflows/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createWorkflow(workflow) {
		return this.httpClient
			.post(`/workflows`,workflow)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateWorkflow(id,workflow) {
		return this.httpClient
			.put(`/workflows/${id}`,workflow)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	publishWorkflow(id) {
		return this.httpClient
			.post(`/workflows/${id}/publish`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	deleteWorkflow(id) {
		return this.httpClient
			.delete(`/workflows/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
