var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { to: "/communicator/inbox", label: "Communicator" }
            ]
          }
        },
        [_vm._v(" Settings ")]
      ),
      _c(
        "div",
        { staticClass: "row-format mb-4 gap-3 flex-wrap" },
        _vm._l(_vm.pages, function(page) {
          return _c(
            "div",
            {
              key: page.value,
              class:
                "page-link " +
                (_vm.currentView === page.value ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.setView(page.value)
                }
              }
            },
            [_c("div", [_vm._v(_vm._s(page.label))])]
          )
        }),
        0
      ),
      _c("mailboxes", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentView === 0,
            expression: "currentView === 0"
          }
        ]
      }),
      _c("custom-email", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentView === 1,
            expression: "currentView === 1"
          }
        ]
      }),
      _c("subscription-router", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentView === 2,
            expression: "currentView === 2"
          }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }