var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-panel row-format align-center",
      style:
        _vm.justify +
        "; --background:url('" +
        _vm.branding.loginSplash +
        "'); font-family: " +
        _vm.branding.font +
        "; --brand-color: " +
        _vm.branding.primaryColor
    },
    [
      _c(
        "div",
        {
          staticClass: "pa-5",
          style:
            "\n\t\t\ttransform: scale(0.6);\n\t\t\tbackground-color: var(--v-white-base);\n\t\t\t" +
            _vm.borderRadius +
            ";\n\t\t\tmax-width: 400px;\n\t\t\theight: fit-content;\n\t\t\t" +
            _vm.margin +
            ";\n\t\t"
        },
        [
          _c(
            "v-container",
            { staticStyle: { "max-width": "350px" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "12", align: "center" } }, [
                    _c("img", {
                      staticStyle: { "max-width": "200px" },
                      attrs: { src: _vm.logo }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "12", align: "left" } }, [
                    _c("div", {
                      staticClass: "my-3",
                      staticStyle: { "white-space": "pre-wrap" },
                      domProps: {
                        innerHTML: _vm._s(_vm.branding.loginGreeting)
                      }
                    })
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", align: "left" } },
                    [
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          "persistent-placeholder": "",
                          "hide-details": "",
                          label: "Email",
                          disabled: true
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: "Remember my email",
                          "hide-details": "",
                          dense: "",
                          value: true,
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-6",
                      attrs: { cols: "12", align: "left" }
                    },
                    [
                      _c(
                        "v-btn",
                        { staticClass: "button-action", attrs: { block: "" } },
                        [_vm._v("Sign in")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }