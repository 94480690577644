<template>
	<div>
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{$t('connected-apps.stripe.desc')}}</div>
				<connected v-if="isConnected">
				</connected>
				<div class="partial-button" v-else-if="isPartial" @click="handleConnectRequest">
					In Progress
				</div>
				<div v-else class="font-primary brand-medium pointer" @click="handleConnectRequest">Connect Stripe</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{expanded ? '$chevronUp' : '$chevronDown'}}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<v-btn class="primary-action mr-1" @click="handleConnectRequest">View transactions</v-btn>
			<v-btn class="primary-action ml-1" @click="openDashboard">Open Stripe dashboard</v-btn>
		</div>
	</div>
</template>

<script>
import Connected from "@/modules/account/apps/Connected";
import StripeIntegrationService from "@/modules/integrations/stripe/StripeIntegrationService";

export default {
	name: "Stripe",

	props: [],

	components: {Connected},

	data: function () {
		return {
			expanded: false,
			stripeModal: false,
			stripeService: new StripeIntegrationService(),
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		openDashboard: function(){
				window.open('https://dashboard.stripe.com', '_blank').focus();
		},

		handleConnectRequest: function(){
			this.$router.push('/stripe');
		},
	},

	computed: {
		isConnected: function(){
			if(this.stripeIntegration && this.stripeIntegration.chargesEnabled){
				return true
			}else{
				return false;
			}
		},

		isPartial: function(){
			if(this.stripeIntegration && !this.stripeIntegration.chargesEnabled){
				return true;
			}else{
				return false;
			}
		},

		stripeIntegration: function(){
			if(this.$store.state.podAccount){
				return this.$store.state.podAccount.stripeIntegration;
			}else{
				return null;
			}
		},

		account: function(){
			return this.$store.state.podAccount;
		}
	},

}
</script>

<style scoped lang="scss">
	.partial-button {
		background-color: var(--v-blue_10-base);
		color: var(--v-primary-base);
		border-radius: 4px;
		width: fit-content;
		font-size: 14px;
		padding: 4px 8px;
		cursor: pointer;
	}
</style>