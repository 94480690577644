var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.history
    ? _c(
        "div",
        { key: _vm.historyKey, attrs: { id: "time-track-history-menu" } },
        [
          _c("div", { staticClass: "px-3", attrs: { id: "subtotals-head" } }, [
            _c("div", { staticClass: "today" }, [
              _vm._v(" Today "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("durationFormat")(_vm.todaySubtotal, false))
                )
              ])
            ]),
            _c("div", { staticClass: "this-week" }, [
              _vm._v(" This week "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("durationFormat")(_vm.thisWeekSubtotal, false))
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "close-button-box mr-0 ml-2 pointer",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("hideHistoryMenu")
                  }
                }
              },
              [
                _c("h-icon2", {
                  staticStyle: {
                    display: "block",
                    "--icon-color": "var(--v-white-base)"
                  },
                  attrs: { name: "close-button", size: "14" }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "day-wrapper" },
            [
              _vm.history.length === 0
                ? _c("div", { staticClass: "column-format centered" }, [
                    _c("div", {
                      staticClass: "px-5 py-4",
                      staticStyle: {
                        "text-align": "center",
                        "font-size": "14px"
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("timetrack.empty-history"))
                      }
                    })
                  ])
                : _vm._e(),
              _vm._l(_vm.history, function(day) {
                return _c(
                  "div",
                  { key: day.id, staticClass: "day py-2" },
                  [
                    _c("div", { staticClass: "date-head" }, [
                      _c("div", { staticClass: "date ml-3" }, [
                        _vm._v(_vm._s(_vm.$DateTime.humanDate(day.date)))
                      ]),
                      _c("div", { staticClass: "duration mr-3" }, [
                        _vm._v(_vm._s(_vm._f("dailyDuration")(day.duration)))
                      ])
                    ]),
                    _vm._l(day.clientRollup, function(clientRollup) {
                      return _c(
                        "div",
                        {
                          key: clientRollup.key,
                          class: _vm.clientClass(clientRollup) + " py-2"
                        },
                        [
                          _c(
                            "div",
                            {
                              ref:
                                clientRollup.events.length == 1
                                  ? "event-" + clientRollup.id
                                  : "",
                              refInFor: true,
                              class: _vm.clientHeadClass(clientRollup),
                              on: {
                                click: function($event) {
                                  return _vm.handleClientRollupClick(
                                    clientRollup
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "avatar ml-3" },
                                [
                                  clientRollup.client
                                    ? _c("client-avatar", {
                                        attrs: {
                                          client: clientRollup.client,
                                          "x-small": "",
                                          left: ""
                                        }
                                      })
                                    : _c("client-avatar", {
                                        attrs: {
                                          client: {
                                            id: "",
                                            name: "- -",
                                            color: "var(--v-gray_30-base)"
                                          },
                                          "x-small": "",
                                          left: ""
                                        }
                                      })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { class: _vm.clientNameClass(clientRollup) },
                                [
                                  _c("div", {
                                    staticClass: "row-format",
                                    staticStyle: { "align-items": "center" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$options.filters.clientNameOutput(
                                          clientRollup
                                        )
                                      )
                                    }
                                  })
                                ]
                              ),
                              _c("div", { staticClass: "duration mr-3" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("durationFormat")(
                                      clientRollup.durationTotal
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { class: _vm.eventGroupClass(clientRollup) },
                            _vm._l(clientRollup.events, function(event) {
                              return _c(
                                "div",
                                {
                                  key: event.key,
                                  ref: "event-" + event.id,
                                  refInFor: true,
                                  class: _vm.eventClass(event) + " mx-3",
                                  on: {
                                    click: function($event) {
                                      return _vm.handleEventClick(event.id)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        "play" +
                                        (_vm.isMobile ? " mobile" : ""),
                                      style: _vm.running
                                        ? "visibility:hidden!important"
                                        : "",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.handleEventPlayClick(event)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: { "margin-left": "1px" },
                                          attrs: {
                                            size: "10",
                                            color: "gray_60"
                                          }
                                        },
                                        [_vm._v("$play")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "event-details ellipsis ml-3"
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "event-description",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.eventDetailsOutput(event)
                                          )
                                        }
                                      })
                                    ]
                                  ),
                                  _c("div", { staticClass: "duration" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("durationFormat")(event.duration)
                                      )
                                    )
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }