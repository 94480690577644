<template>
	<div class="column-format gap-2 py-3">
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pt"
				label="Padding top"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pb"
				label="Padding bottom"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pl"
				label="Padding left"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pr"
				label="Padding right"
				@input="updated"
			></v-text-field>
		</div>

		<v-select
			v-model="element.buttonSize"
			:items="['X-Small', 'Small', 'Normal', 'Large', 'X-Large']"
			hide-details
			outlined
			dense
			persistent-placeholder
			label="Button size"
			@change="updated"
		>
		</v-select>

		<v-select
			v-model="element.buttonAlignment"
			:items="alignment"
			item-text="label"
			item-value="value"
			hide-details
			dense
			outlined
			persistent-placeholder
			label="Button alignment"
			@change="updated"
		></v-select>

		<div class="column-format">
			<div class="font-12 text-left font-gray_70">Button color</div>
			<color-selector v-model="element.buttonColor" @input="updated"></color-selector>
		</div>

		<hr class="my-2" />

		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.borderWidth"
				label="Border width"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.borderRadius"
				label="Border radius"
				@input="updated"
			></v-text-field>
		</div>
		<v-text-field
			hide-details
			dense
			outlined
			type="number"
			v-model="element.padding"
			label="Inner padding"
			@input="updated"
		></v-text-field>
		<div class="row-format text-left gap-1">
			<div style="flex: 1 1 0; width: 0;">
				<div>Background</div>
				<color-selector v-model="element.backgroundColor" @input="updated"></color-selector>
			</div>
			<div style="flex: 1 1 0; width: 0;">
				<div>Border</div>
				<color-selector v-model="element.borderColor" @input="updated"></color-selector>
			</div>
			<div style="flex: 1 1 0; width: 0;">
				<div>Table</div>
				<color-selector v-model="element.tableBorderColor" @input="updated"></color-selector>
			</div>
		</div>

		<hr class="my-2" />

		<v-select
			:disabled="element.packages.length < 2"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Can select..."
			v-model="element.selectionType"
			:items="selectTypes"
			item-value="value"
			item-text="label"
			@change="updated"
		></v-select>
		<v-select
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Required"
			v-model="element.selectionRequired"
			:items="required"
			item-value="value"
			item-text="label"
			@change="updated"
		></v-select>
		<v-select
			:disabled="element.packages.length < 2"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Layout"
			v-model="element.stacked"
			:items="layout"
			item-value="value"
			item-text="label"
			@change="updated"
		></v-select>
	</div>
</template>

<script>
	import ColorSelector from '@/components/ColorSelector';
	import TemplateService from '@/modules/templates/TemplateService';
	import { v4 as uuid4 } from 'uuid';
	import AgreementMixin from '@/modules/agreements/schema/AgreementMixin';

	export default {
		name: 'ServicesConfig',

		props: ['agreement', 'item', 'tokens'],

		mixins: [AgreementMixin],

		components: {ColorSelector },

		data: function() {
			return {
				showAddOnMenu: false,
				templateService: new TemplateService(),
				packageTemplates: [],
				packageEditor: false,
				packageToEdit: null,
				element: { ...this.item },
				alignment: [
					{ label: 'Left', value: 'left' },
					{ label: 'Right', value: 'right' },
					{ label: 'Center', value: 'center' },
					{ label: 'Block', value: 'block' },
				],
				selectTypes: [
					{ label: 'Single package', value: 'SINGLE' },
					{ label: 'Multiple packages', value: 'MULTIPLE' },
				],
				required: [
					{ label: 'Yes', value: true },
					{ label: 'No', value: false },
				],
				layout: [
					{ label: 'Row', value: false },
					{ label: 'Stacked', value: true },
				],
			};
		},

		mounted() {
			this.getPackageTemplates();
			this.$store.state.eventBus.$on('agreement-edit-package',this.handleEditPackageEvent)
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('agreement-edit-package',this.handleEditPackageEvent)
		},

		methods: {
			getPackageTemplates: function() {
				this.templateService.getDeliverablePackages().then((res) => {
					this.packageTemplates.splice(0);
					this.packageTemplates.push(...res.data);
					this.packageTemplates.sort((a,b) => {
						if(a.name && b.name){
							return a.name.localeCompare(b.name);
						}else if(a.name){
							return -1;
						}else{
							return 1;
						}
					})
				});
			},


			updatePackage: function(servicePackage) {
				let ix = this.element.packages.findIndex((p) => p.id === servicePackage.id);
				this.element.packages.splice(ix, 1, servicePackage);
				this.updated();
				this.editPackage(null);
			},

			deletePackage: function(servicePackage) {
				let ix = this.element.packages.findIndex((p) => p.id === servicePackage.id);
				this.element.packages.splice(ix, 1);
				this.updated();
				this.editPackage(null);
			},

			importTemplate: function(template) {
				let newPackage = {
					id: uuid4(),
					packageName: template.name,
					description:
							'<h2>{{Package.Name}}</h2><br>Description that shows the price of the package<br>{{Package.Total}} {{Package.FeeLabel}}<br>',
					fees: template.feeSchedule,
					buttonText: 'Select',
					selected: false,
					defaultSelection: false,
					items: [],
				};

				template.deliverables.forEach((d) => {
					newPackage.items.push({
						id: uuid4(),
						description: d.name + '\n' + d.description,
						product: d.product,
						quantity: d.quantity,
						rate: d.product ? d.product.rate : 0,
						taxable: d.product ? d.product.taxable : false,
						relativeDueDate: true,
						dueDateRelative: d.dueDate,
					});
				});

				this.element.packages.push(newPackage);
				this.updated();
			},

			handleEditPackageEvent: function(id){
				let servicePackage = this.element.packages.find(p => p.id === id);
				if(servicePackage){
					this.editPackage(servicePackage);
				}
			},

			editPackage: function(servicePackage) {
				this.packageToEdit = servicePackage;
				this.packageEditor = !!servicePackage;
			},

			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.package {
		cursor: pointer;
		&:hover {
			background-color: var(--v-gray_20-base);
		}
	}
</style>
