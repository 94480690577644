<template>
		<v-combobox
			ref="EmailInput"
			v-model="to"
			:items="tokens"
			hide-details
			dense
			outlined
			persistent-placeholder
			:label="label"
			:multiple="multiple"
			small-chips
			@change="$emit('change',to)"
			:filter="isMatch"
		>
		</v-combobox>
</template>

<script>
	export default {
		name: 'ContactSelector',

		props: ['toList','label','multiple','phone'],

		components: {},

		data: function () {
			return {
				to: this.multiple ? [... this.toList] : this.toList
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			isMatch: function (val1, val2) {
				if(val1 && val2){
					return val1.trim().toLowerCase().includes(val2.trim().toLowerCase());
				}else{
					return false
				}
			},
		},

		computed: {
			tokens: function () {
				return [
					'{{Opportunity.Email}}',
					'{{Form.Email}}',
					'{{Contacts.Default}}',
					'{{Contacts.All}}',
					'{{Contacts.Invoice}}',
					'{{Contacts.Portal}}'
				]
			},
		},
	};
</script>

<style scoped lang="scss">
</style>
