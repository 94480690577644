<template>
	<div  class="text-left">
		<div class="brand-medium pa-4">Subscription payment history</div>
		<v-list-item v-for="invoice in invoicesPages" :key="invoice.id">
			<v-list-item-content>
				<v-list-item-title style="font-size:14px;" v-text="$DateTime.longDate(invoice.createdAt)"></v-list-item-title>
				<v-list-item-subtitle
						style="font-size:14px;"
						class="mt-3"
						v-text="$formatters.dollars(invoice.total,true,true,'USD')"
				></v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-action>
				<v-btn
						text
						color="primary"
						@click="handleClick(invoice.id, invoice.downloadSignature)"
						style="font-size:12px"
				>
					View
				</v-btn>
			</v-list-item-action>
		</v-list-item>
		<div class="mt-3">
			<v-pagination
					class="float-none float-sm-left"
					color="none"
					v-model="page"
					:total-visible="5"
					:length="Math.ceil(invoices.length / rowsPerPage)"
					v-if="invoices.length > rowsPerPage"
			></v-pagination>
		</div>
		<Modal :dialog="invoiceModal" v-on:close="invoiceModal = false" ref="invoiceModal" :max-width="800">
			<Invoice
					:key="invoiceModal"
					v-on:close="invoiceModal = false"
					:invoiceId="invoiceId"
					:downloadSignature="downloadSignature"
			></Invoice>
		</Modal>
	</div>
</template>

<script>
import SubscriptionService from "@/modules/subscription/SubscriptionService";
import HDateTime from "@/modules/utils/HDateTime";
import Invoice from "@/modules/subscription/Invoice";
import Modal from "@bit/hecticapp.common.modal";

export default {
	name: "Invoices",

	props: ['invoices'],

	components: {Modal, Invoice},

	data: function () {
		return {
			HDateTime: HDateTime,
			subscriptionService: new SubscriptionService(),
			page: 1,
			rowsPerPage: 4,

			invoiceId: null,
			downloadSignature: null,
			invoiceModal: false,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		handleClick(id, downloadSignature) {
			this.invoiceId = id;
			this.downloadSignature = downloadSignature;
			this.invoiceModal = true;
		},
	},

	computed: {
		invoicesPages: function() {
			const start = (this.page - 1) * this.rowsPerPage;
			const end = start + this.rowsPerPage;
			let ret = [];
			for (let i = start; i < end; i++) {
				if (this.invoices[i] == undefined) break;
				ret.push(this.invoices[i]);
			}
			return ret;

		},
	},

}
</script>

<style scoped lang="scss">
.v-list-item {
	margin-top: 1em;
	padding-bottom: 0.4em;
	border-bottom: 1px solid var(--v-gray_50-base);
}
</style>