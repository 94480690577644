var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-3 text-left column-format",
      staticStyle: {
        border: "1px solid var(--v-gray_30-base)",
        "border-radius": "4px",
        gap: "12px"
      }
    },
    [
      _c("div", { staticClass: "font-14" }, [
        _c(
          "div",
          { staticClass: "text-left form-content" },
          [
            _c("editor", {
              ref: "PaymentTextEditor",
              attrs: {
                "api-key": _vm.$store.getters.getTinyMceKey,
                inline: true,
                init: _vm.mceConfigText
              },
              model: {
                value: _vm.meeting.requiredPayment.paymentInfo,
                callback: function($$v) {
                  _vm.$set(_vm.meeting.requiredPayment, "paymentInfo", $$v)
                },
                expression: "meeting.requiredPayment.paymentInfo"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }