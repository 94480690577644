import HttpClient from '../../services/HttpClient';

export default class ProductService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getProducts() {
		return this.httpClient
			.get('/products')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	postProduct(data) {
		return this.httpClient
			.post('/products', data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProduct(id) {
		return this.httpClient
			.get(`/products/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateProduct(id, data) {
		return this.httpClient
			.put(`/products/${id}`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteProduct(id) {
		return this.httpClient
			.delete(`/products/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
