var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.dialog,
        transition: "fade",
        persistent: _vm.persistent,
        scrollable: "",
        "max-width": "460"
      },
      on: {
        "click:outside": function($event) {
          !_vm.persistent ? _vm.handleClose() : ""
        },
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.handleClose()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "confirm-dialog-div" },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "", rounded: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleClose()
                }
              }
            },
            [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$close")])],
            1
          ),
          _c("div", { staticClass: "modal-padding" }, [
            _c(
              "div",
              { staticClass: "row-format text-left align-center gap-3" },
              [
                _c("v-icon", { attrs: { color: _vm.severity, size: "50" } }, [
                  _vm._v(_vm._s(_vm.icon))
                ]),
                _c("div", [
                  _vm.headingText
                    ? _c("div", { staticClass: "font-18 brand-medium" }, [
                        _vm._v(" " + _vm._s(_vm.headingText) + " ")
                      ])
                    : _vm._e(),
                  _vm.bodyText
                    ? _c("div", {
                        staticClass: "font-14",
                        domProps: { innerHTML: _vm._s(_vm.bodyText) }
                      })
                    : _vm._e()
                ])
              ],
              1
            )
          ]),
          _c(
            "v-form",
            {
              ref: "confirmDialog",
              attrs: { id: "confirmDialog", "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit()
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-footer row-format gap-1 px-3 py-3",
                  staticStyle: { "justify-content": "end" }
                },
                [
                  !_vm.hideNo
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "primary-action",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.handleClose()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.noText))]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "super-action",
                      attrs: {
                        type: "submit",
                        "min-width": "100",
                        elevation: "0"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.handleSubmit()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.yesText) + " ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }