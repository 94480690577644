<template>
	<div>
		<node-label @open="edit()">
			<template v-slot:default>
				<v-icon class="material-symbols-rounded mr-1" color="black" size="22">person</v-icon>
				<div>Create prospect</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" title="">
			<template v-slot:default>
				<div class="column-format gap-2" style="max-width: 450px;">
					Create a prospect record if one isn't already attached to the workflow scope.
				</div>
			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeModal from '@/modules/workflow/nodes/NodeModal';
	import NodeLabel from '@/modules/workflow/nodes/NodeLabel';
	import NodeMixin from '@/modules/workflow/nodes/NodeMixin';

	export default {
		name: 'CreateProspect',
		inputs: 1,
		outputs: 1,

		mixins: [NodeMixin],

		props: [],

		components: { NodeLabel, NodeModal },

		data: function() {
			return {

			};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {
			edit: function() {
				this.$refs.nodeModal.open();
			},
		},

		watch: {

		},

		computed: {

		},
	};
</script>

<style scoped lang="scss"></style>
