<template>
	<div style="font-family: Inter">
		<div class="row-format align-center" style="gap: 8px">
			<div class="play-button pointer" @click="currentTimer ? handleStop() : handlePlay()">
				<v-icon size="10" color="secondary" v-if="!currentTimer">$play</v-icon>
				<v-icon size="10" color="secondary" v-else>$stop</v-icon>
			</div>
			<div v-if="currentTimer" class="play-button pointer" @click="currentTimer.pausedAt ? handleResume() : handlePause()">
				<span
					style="color: var(--v-secondary-base); font-size: 20px"
					class="material-symbols-rounded"
					v-if="currentTimer.pausedAt"
					>resume</span
				>
				<span style="color: var(--v-secondary-base); font-size: 20px" class="material-symbols-rounded" v-else
					>pause</span
				>
			</div>
			<v-menu v-model="showProjectMenu" nudge-bottom="20" bottom :close-on-content-click="false" :disabled="!currentTimer">
				<template v-slot:activator="{ on }">
					<div :class="`font-14 column-format ${currentTimer ? 'pointer' : ''}`" style="width: 50px" v-on="on">
						<div>{{ timerDisplay }}</div>
					</div>
				</template>
				<client-project-select
					v-if="currentTimer"
					@delete-timer="deleteTimer"
					@update-timer="handleUpdateTimer($event)"
					@close="showProjectMenu = false"
					:current-timer="currentTimer"
					:show-menu="showProjectMenu"
				></client-project-select>
			</v-menu>
			<v-menu v-model="showHistoryMenu" offset-y left :close-on-content-click="false">
				<template v-slot:activator="{ on }">
					<v-icon size="16" class="pointer" color="secondary" v-on="on">{{
						showHistoryMenu ? '$arrowUp' : '$arrowDown'
					}}</v-icon>
				</template>
				<history-widget @hideHistoryMenu="showHistoryMenu = false" />
			</v-menu>
		</div>
		<div
			v-if="currentTimer && clientProjectMenuLabel"
			@click="showProjectMenu = true"
			v-tippy="{ content: clientProjectMenuLabel }"
			class="pointer font-10 truncate"
			style="text-align: center; margin-left: 2px; margin-top: 0px; max-width: 110px; width: 110px;"
		>
			{{ clientProjectMenuLabel }}
		</div>
	</div>
</template>

<script>
	import TimeTrackService from '@/modules/timetracking/TimeTrackService';
	import ClientService from '@/modules/clients/ClientService';
	import { mapGetters } from 'vuex';
	import TimeTrack from './TimeTrack';
	import HistoryWidget from '@/modules/timetracking/HistoryWidget';
	import ClientProjectSelect from '@/modules/timetracking/ClientProjectSelect';

	export default {
		name: 'TimeTrackWidget',
		components: { HistoryWidget, ClientProjectSelect },
		props: [],

		data() {
			return {
				timeTrackService: new TimeTrackService(),
				clientService: new ClientService(),
				timeTrack: new TimeTrack(),
				client: null,
				running: false,
				showProjectMenu: false,
				showCurrentMenu: false,
				showHistoryMenu: false,
				currentTimer: null, // Timer object from server
				timerDisplay: '', // Visible counting timer
				timerEvents: [],
				pauseOnFocus: false,
				title: null,
				intervalId: null,
				getTimerIntervalId: null,
			};
		},

		mounted() {
			this.getTimer();
			this.getTimerIntervalId = setInterval(() => this.getTimer(), 15000);
			this.runTimerDisplay();
			this.$store.state.eventBus.$on('account-changed', this.accountChanged);
			this.$store.state.eventBus.$on(`u-${this.userId}.timer-start`, this.handleTimerStartEvent);
			this.$store.state.eventBus.$on(`u-${this.userId}.timer-update`, this.handleTimerUpdateEvent);
			this.$store.state.eventBus.$on(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
			this.$store.state.eventBus.$on(`u-${this.userId}.timer-delete`, this.handleTimerDeleteEvent);
			this.$store.state.eventBus.$on('start-timer', this.startTimer);
			this.$store.state.eventBus.$on('stop-timer', this.stopTimer);
			this.$store.state.eventBus.$on('long-idle-detected', this.getTimer);
		},

		beforeDestroy() {
			this.stopTimerDisplay();
			clearInterval(this.getTimerIntervalId);
			this.$store.state.eventBus.$off('account-changed', this.accountChanged);
			this.$store.state.eventBus.$off(`u-${this.userId}.timer-start`, this.handleTimerStartEvent);
			this.$store.state.eventBus.$off(`u-${this.userId}.timer-update`, this.handleTimerUpdateEvent);
			this.$store.state.eventBus.$off(`u-${this.userId}.timer-stop`, this.handleTimerStopEvent);
			this.$store.state.eventBus.$off(`u-${this.userId}.timer-delete`, this.handleTimerDeleteEvent);
			this.$store.state.eventBus.$off('start-timer', this.startTimer);
			this.$store.state.eventBus.$off('stop-timer', this.stopTimer);
			this.$store.state.eventBus.$off('long-idle-detected', this.getTimer);
		},

		methods: {
			accountChanged: function() {
				this.getTimer();
			},

			handleTimerStartEvent(event) {
				this.currentTimer = event.message;
				this.$store.commit('setCurrentTimer', this.currentTimer);
				this.running = true;
			},

			handleTimerUpdateEvent(event) {
				this.currentTimer = event.message;
				this.$store.commit('setCurrentTimer', this.currentTimer);
			},

			handleTimerStopEvent(event) {
				this.currentTimer = null;
				this.$store.commit('setCurrentTimer', null);
				this.running = false;
				if (event && event.message) {
					this.timerEvents.push(event.message);
				}
			},

			handleTimerDeleteEvent() {
				this.currentTimer = null;
				this.$store.commit('setCurrentTimer', null);
				this.running = false;
			},

			runTimerDisplay() {
				this.intervalId = setInterval(() => {
					this.getTimerDisplay();
				}, 500);
			},

			stopTimerDisplay() {
				clearInterval(this.intervalId);
			},

			getTimerDisplay() {
				if (this.pauseOnFocus) {
					return;
				}
				if (this.currentTimer && this.running) {
					const timerStart = this.$DateTime
						.fromISO(this.currentTimer.timerStart)
						.plus({ seconds: this.currentTimer.pausedSeconds });
					let endTime;
					if (this.currentTimer.pausedAt) {
						endTime = this.$DateTime.fromISO(this.currentTimer.pausedAt);
					} else {
						endTime = this.$DateTime.local();
					}

					const diff = endTime.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']);

					this.timerDisplay = diff.toFormat('h:mm:ss');
					document.title = diff.toFormat('h:mm:ss');
				} else {
					this.timerDisplay = '0:00:00';
					this.completedSteps = 0;
					document.title = this.$store.state.documentTitle;
				}
			},

			scrubTimerInput(input) {
				// to do: improve this so only 2 digits at-a-time and no more than 2 colons
				// input = input.replace(/[^0-9: minshour]/i, '').replace(/^[^0-9]/i, '');
				input = input.replace(/[^0-9:. minshour]/i, '');
				return input;
			},

			handleTimerDisplayBlur() {
				this.pauseOnFocus = false;
				this.getTimerDisplay();
			},

			handleUpdateTimerStart(event) {
				this.currentTimer.timerStart = event;
				this.updateTimer();
			},

			handleUpdateTimer(event) {
				this.currentTimer = event;
				this.updateTimer();
			},

			handlePlay() {
				this.$track.record('time-track start', { source: 'widget' });
				if (!this.running) {
					this.startTimer();
				}
			},

			handleStop() {
				this.$track.record('time-track stop');
				this.showCurrentMenu = false;
				this.running = false;
				this.stopTimer();
			},

			handlePause() {
				this.$track.record('time-track pause');
				this.running = true;
				this.pauseTimer();
			},

			handleResume() {
				this.$track.record('time-track resume');
				this.running = true;
				this.resumeTimer();
			},

			getClient() {
				this.client = this.$store.getters.clients.find((c) => c.id === this.currentTimer.clientId);
			},

			areTimersEqual(timer1, timer2) {
				if (!timer1 && !timer2) {
					return true;
				}
				if (timer1 && timer2) {
					let start1 = this.$DateTime.fromISO(timer1.timerStart);
					let start2 = this.$DateTime.fromISO(timer2.timerStart);
					if (timer1.id === timer2.id && start1.ts === start2.ts) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			},

			getTimer() {
				this.timeTrackService
					.getTimer()
					.then((res) => {
						if (!this.areTimersEqual(this.currentTimer, res.data)) {
							this.currentTimer = res.data;
							this.$store.commit('setCurrentTimer', this.currentTimer);
							if (this.currentTimer) {
								this.running = true;
							} else {
								this.running = false;
							}
						}
						this.getTimerDisplay();
					})
					.catch((err) => {
						this.running = false;
						this.currentTimer = null;
						this.$store.commit('setCurrentTimer', null);
						if (err.response.status !== 402) {
							this.$store.commit('error', err.response.data.message);
						} else {
							clearInterval(this.getTimerIntervalId);
						}
					});
			},

			startTimer(defaults = {}) {
				let timerStop;
				if (this.currentTimer) {
					timerStop = this.stopTimer();
				} else {
					timerStop = Promise.resolve();
				}

				let showMenu = !defaults.hideMenu;

				timerStop.then(() => {
					this.timeTrackService
						.start(defaults)
						.then((res) => {
							let event = {
								message: res.data,
							};
							this.handleTimerStartEvent(event);
							this.showProjectMenu = showMenu;
						})
						.catch((err) => {
							let msg = err.response.data.message;
							let status = err.response.status;
							this.$store.commit('warn', 'Error [' + status + '] ' + msg);
						});
				});
			},

			pauseTimer() {
				return new Promise((resolve) => {
					this.timeTrackService
						.pause()
						.then((res) => {
							this.currentTimer = res.data;
							this.$store.commit('setCurrentTimer', this.currentTimer);
							this.running = true;
							resolve(res.data);
						})
						.catch((err) => {
							console.log(err);
						});
				});
			},

			resumeTimer() {
				return new Promise((resolve) => {
					this.timeTrackService
						.resume()
						.then((res) => {
							this.currentTimer = res.data;
							this.$store.commit('setCurrentTimer', this.currentTimer);
							this.running = true;
							resolve(res.data);
						})
						.catch((err) => {
							console.log(err);
						});
				});
			},

			stopTimer() {
				return new Promise((resolve) => {
					this.timeTrackService
						.stop()
						.catch((err) => {
							console.log(err);
						})
						.finally(() => {
							this.handleTimerStopEvent(null);
							resolve();
						});
				});
			},

			updateTimer() {
				this.timeTrackService
					.update(this.currentTimer)
					.then(() => {
						//timer object synchronization happens in pubnub handlers
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						if (status === 404) {
							this.handleTimerStopEvent(null);
						} else {
							this.$store.commit('warn', 'Error [' + status + '] ' + msg);
						}
					});
			},

			deleteTimer() {
				this.timeTrackService
					.delete()
					.then(() => {
						this.running = false;
						this.currentTimer = null;
						this.$store.commit('setCurrentTimer', null);
						this.$emit('menu-closed');
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			getTimerEvents(earliest, latest) {
				this.timeTrackService
					.getTimerEventsByUser(this.userId, earliest, latest)
					.then((res) => {
						this.timerEvents = res.data;
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
		},

		computed: {
			...mapGetters({
				userId: 'getLoggedInUserId',
			}),

			clientProjectMenuLabel() {
				if (!this.currentTimer) return '';
				if (this.currentTimer.projectId) {
					return this.currentTimer.projectName;
				}
				if(this.currentTimer.ticketId){
					return this.currentTimer.ticketName;
				}
				if (this.currentTimer.clientId) {
					return this.currentTimer.clientName;
				}
				return null;
			},
		},

		watch: {
			currentTimer(n, o) {
				if (!n || !n.clientId) {
					this.client = null;
					return;
				}
				let oldClientId = '';
				if (o !== null && o.clientId) oldClientId = o.clientId;
				if (n.clientId !== oldClientId) {
					this.getClient();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.play-button {
		width: 24px;
		height: 24px;
		border-radius: 12px;
		background-color: var(--v-white-base);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
</style>
