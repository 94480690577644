<template>
	<div style="max-height: calc(var(--vh) - 150px); overflow-y: scroll">
		<div class="row-format mb-3">
			<v-btn icon @click="$emit('back')"><v-icon>$arrowLeft</v-icon></v-btn>
			<v-btn icon class="ml-auto" @click="openContactEdit()"><v-icon color="gray_70" size="20">$edit</v-icon></v-btn>
		</div>
		<div class="column-format" style="gap: 8px">
			<div class="row-format align-center mb-3" v-if="name">
				<v-avatar color="secondary" size="30">
					<span class="font-12" style="color: var(--v-white-base)">{{ initials }}</span>
				</v-avatar>
				<div class="ml-2">{{name}}</div>
			</div>
			<div v-if="client" class="row-format align-center">
				<client-avatar class="ml-1" small :client="client"></client-avatar>
				<div class="ml-2 font-14">{{client.name}}</div>
			</div>
			<div v-if="person.phone" class="row-format align-center">
				<v-icon class="ml-1">phone</v-icon>
				<div class="ml-2 font-14">{{formattedPhone}}</div>
			</div>
			<div v-if="person.email" class="row-format align-center">
				<v-icon class="ml-1">email</v-icon>
				<div class="ml-2 font-14">{{person.email}}</div>
			</div>
		</div>

		<div class="mt-5">
			<person-communications :person="person" @view-communication="$emit('view-communication',$event)"></person-communications>
		</div>

	</div>
</template>

<script>
	import libPhoneNumber from "google-libphonenumber";
	import ClientAvatar from "@/components/ClientAvatar";
	import PersonCommunications from "@/modules/communicator/inbox/PersonCommunications";
	import ContactEdit from "@/modules/clients/contacts/ContactEdit";

	export default {
		name: "PersonDetail",

		props: ['person'],

		components: {PersonCommunications, ClientAvatar},

		data: function () {
			return {
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
			}
		},

		mounted() {

		},

		beforeDestroy() {
		},

		methods: {
			openContactEdit: function(){
				let binding;
				if(this.person.id){
					binding = {
						id:this.person.id
					}
				}else{
					binding = {
						newContact: this.person
					}
				}

				this.$store.state.globalModalController.openModal(ContactEdit, binding).then((res) => {
					if (res) {
						this.$emit('person-updated',res);
					}
				});
			}
		},

		computed: {
			formattedPhone: function () {
				if(this.person.phone) {
					try {
						let phone = this.PhoneUtil.parse(this.person.phone);
						return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
					} catch (err) {
						return this.person.phone;
					}
				}else{
					return null;
				}
			},

			client: function(){
				if(this.person.clientId){
					return this.$store.getters.getClientById(this.person.clientId);
				}else{
					return null;
				}
			},

			initials: function () {
				let initials = '';
				if (this.person.firstName) {
					initials += this.person.firstName.substr(0, 1).toUpperCase();
				}
				if (this.person.lastName) {
					initials += this.person.lastName.substr(0, 1).toUpperCase();
				}
				if (initials) {
					return initials;
				} else {
					return null;
				}
			},

			name: function(){
				let result = [];
				if(this.person.firstName){
					result.push(this.person.firstName);
				}

				if(this.person.lastName){
					result.push(this.person.lastName);
				}

				if(result.length){
					return result.join(' ');
				}else{
					return null;
				}
			}
		},

	}
</script>

<style scoped lang="scss">

</style>
