import HttpClient from '../../services/HttpClient';

export default class DiscoveryService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	createDiscoveryRequest(clientId, data) {
		return this.httpClient
			.post(`/clients/${clientId}/discovery`, data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDiscoveryRequest(clientId, discoveryRequestId) {
		return this.httpClient
			.get(`/clients/${clientId}/discovery/${discoveryRequestId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	updateDiscoveryRequest(clientId, discoveryRequestId, discoveryRequest) {
		return this.httpClient
			.put(`/clients/${clientId}/discovery/${discoveryRequestId}`,discoveryRequest)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendDiscoveryRequest(clientId, discoveryRequestId, discoveryRequest,  sendLater=null) {
		const params = {};

		if (sendLater !== null) {
			params.sendLater = sendLater;
		}

		return this.httpClient
			.post(`/clients/${clientId}/discovery/${discoveryRequestId}/send`,discoveryRequest, {params:params})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getDiscoveryRequests(clientId) {
		return this.httpClient
			.get(`/clients/${clientId}/discovery`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteDiscoveryRequest(clientId, id) {
		return this.httpClient
			.delete(`/clients/${clientId}/discovery/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
