<template>
	<div
		class="login-panel row-format align-center"
		:style="`${justify}; --background:url('${branding.loginSplash}'); font-family: ${branding.font}; --brand-color: ${branding.primaryColor}`"
	>
		<div
			:style="`
				transform: scale(0.6);
				background-color: var(--v-white-base);
				${borderRadius};
				max-width: 400px;
				height: fit-content;
				${margin};
			`"
			class="pa-5"
		>
			<v-container style="max-width: 350px">
				<v-row dense>
					<v-col cols="12" align="center">
						<img :src="logo" style="max-width: 200px" />
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12" align="left">
						<div v-html="branding.loginGreeting" style="white-space: pre-wrap" class="my-3"></div>
					</v-col>
					<v-col cols="12" align="left">
						<v-text-field
							class="h-outline"
							persistent-placeholder
							hide-details
							label="Email"
							:disabled="true"
						></v-text-field>
						<v-checkbox label="Remember my email" hide-details dense :value="true" :disabled="true"></v-checkbox>
					</v-col>
					<v-col cols="12" align="left" class="mt-6">
						<v-btn class="button-action" block>Sign in</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MockLogin',

		props: ['branding'],

		components: {},

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			logo: function(){
				if(this.$vuetify.theme.dark && this.$store.state.selectedUserAccount.account.accountLogoDark) {
					return this.$store.state.selectedUserAccount.account.accountLogoDark;
				}else{
					return this.$store.state.selectedUserAccount.account.accountLogo
				}
			},

			justify: function(){
				if(this.branding.loginBoxPosition === 'Left'){
					return 'justify-content: start'
				}else if(this.branding.loginBoxPosition === 'Right'){
					return 'justify-content: end'
				}else{
					return 'justify-content: center';
				}
			},

			margin: function(){
				if(this.branding.loginBoxPosition === 'Left'){
					return 'margin-left: -78px'
				}else if(this.branding.loginBoxPosition === 'Right'){
					return 'margin-right: -78px'
				}else{
					return '';
				}
			},

			borderRadius: function(){
				if(this.branding.loginBoxPosition === 'Left'){
					return 'border-radius: 0px 4px 4px 0px;'
				}else if(this.branding.loginBoxPosition === 'Right'){
					return 'border-radius: 4px 0px 0px 4px;'
				}else{
					return 'border-radius: 4px';
				}

			}
		},
	};
</script>

<style scoped lang="scss">
	.login-panel {
		background-image: var(--background);
		background-size: cover;
		background-position: center;
	}

	.button-action {
		box-shadow: none !important;
		&:not(.v-btn--text) {
			border: 1px solid var(--v-gray_50-base) !important;
			background-color: var(--v-white-base) !important;
			color: var(--brand-color) !important;
		}
	}
</style>
