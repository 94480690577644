<template>
	<div id="proposal-terms-dialog">
		<v-btn icon class="close-dialog" @click="$emit('cancel')">
			<v-icon>$close</v-icon>
		</v-btn>
		<v-form v-model="isValid">
			<div class="px-4 pt-4 pb-6 form-inner-wrapper">
				<div class="modal-title mb-2">{{ $t('proposal.terms.payment-terms') }}</div>

				<div v-if="terms">
					<v-select
						class="h-outline"
						hide-details
						persistent-placeholder
						:label="$t('proposal.terms.payment-terms')"
						:items="dayOptions"
						v-model="lTerms.paymentDays"
					>
						<template v-slot:selection="{ item }">
							<span v-if="item === 0">{{ $t('proposal.terms.on-receipt') }}</span>
							<span v-if="item !== 0">{{ item }} {{ $t('proposal.terms.days') }}</span>
						</template>
						<template v-slot:item="{ item }">
							<span v-if="item === 0">{{ $t('proposal.terms.on-receipt') }}</span>
							<span v-if="item !== 0">{{ item }} {{ $t('proposal.terms.days') }}</span>
						</template>
					</v-select>

					<v-text-field
						class="h-outline"
						hide-details
						persistent-placeholder
						:label="$t('proposal.terms.late-payment')"
						v-model="lTerms.latePaymentFee"
						placeholder="No fee"
						suffix="% mo."
						type="number"
					></v-text-field>

					<div class="ml-1">
						<v-switch
							inset
							dense
							v-model="lTerms.fullLegalTerms"
							:label="$t('proposal.terms.include-terms')"
						></v-switch>
					</div>
				</div>
				<div v-if="terms && lTerms.fullLegalTerms">
					<div class="modal-title">{{ $t('proposal.terms.legal-terms') }}</div>

					<div class="disclaimer my-2">
						<div><v-icon size="30" color="gray_90">$info</v-icon></div>
						<div style="font-size: 12px; font-style: italic">{{ $t('proposal.terms.disclaimer') }}</div>
					</div>


					<date-selector :date="effectiveDate" :label="$t('proposal.terms.effective-date')" @change="effectiveDateUpdated($event)"></date-selector>

					<v-text-field
						class="h-outline"
						hide-details
						persistent-placeholder
						:label="$t('proposal.terms.governing-law')"
						v-model="lTerms.governingLaw"
					></v-text-field>

					<v-select
						class="h-outline"
						hide-details
						persistent-placeholder
						:label="$t('proposal.terms.cancellation')"
						:items="dayOptions"
						v-model="lTerms.cancellationNoticeDays"
					>
						<template v-slot:selection="{ item }">
							<span v-if="item === 0">{{ $t('proposal.terms.no-notice') }}</span>
							<span v-if="item !== 0">{{ item }} {{ $t('proposal.terms.days') }}</span>
						</template>
						<template v-slot:item="{ item }">
							<span v-if="item === 0">{{ $t('proposal.terms.no-notice') }}</span>
							<span v-if="item !== 0">{{ item }} {{ $t('proposal.terms.days') }}</span>
						</template>
					</v-select>

					<div class="checkbox-flex">
						<div>
							<v-simple-checkbox
								:color="lTerms.displayInPortfolio ? 'var(--v-blue_50-base)' : 'var(--v-gray_70-base)'"
								v-model="lTerms.displayInPortfolio"
							/>
						</div>
						<div>
							<div class="label">{{ $t('proposal.terms.portfolio-label') }}</div>
							<div class="details">{{ $t('proposal.terms.portfolio-details') }}</div>
						</div>
					</div>

					<div class="checkbox-flex">
						<div>
							<v-simple-checkbox
								:color="lTerms.grantLicenseForFramework ? 'var(--v-blue_50-base)' : 'var(--v-gray_70-base)'"
								v-model="lTerms.grantLicenseForFramework"
							/>
						</div>
						<div>
							<div class="label">{{ $t('proposal.terms.frameworks-label') }}</div>
							<!--							<div>{{ $t('proposal.terms.frameworks-details') }}</div>-->
						</div>
					</div>

					<div class="checkbox-flex">
						<div>
							<v-simple-checkbox
								:color="lTerms.processingFeePassThrough ? 'var(--v-blue_50-base)' : 'var(--v-gray_70-base)'"
								v-model="lTerms.processingFeePassThrough"
							/>
						</div>
						<div>
							<div class="label">{{ $t('proposal.terms.fee-pass-through-label') }}</div>
							<div class="details">{{ $t('proposal.terms.fee-pass-through-detail') }}</div>
						</div>
					</div>

					<div class="modal-title mt-4 mb-2">{{ $t('proposal.terms.the-contractor') }}</div>
					<template v-if="isReady">
						<v-text-field
							class="h-outline"
							hide-details
							persistent-placeholder
							:label="$t('proposal.terms.legal-name')"
							v-model="account.accountName"
						>
						</v-text-field>

						<div class="d-flex justify-space-between">
							<div style="flex: 1 1 50%" class="pr-1">
								<v-select
									v-if="entityTypes.length"
									class="h-outline"
									hide-details
									persistent-placeholder
									:label="$t('proposal.terms.entity-type')"
									:items="entityTypes"
									v-model="account.entityType"
								>
									<template v-slot:item="{ item }">
										<span>{{ item }}</span>
									</template>
								</v-select>
								<v-text-field
									v-else
									class="h-outline"
									v-model="account.entityType"
									hide-details
									persistent-placeholder
									:label="$t('proposal.terms.entity-type')"
								></v-text-field>
							</div>
							<div style="flex: 1 1 50%" class="pl-1">
								<v-select
									v-if="states.length"
									class="h-outline"
									hide-details
									persistent-placeholder
									:label="$t('proposal.terms.entity-state')"
									item-value="name"
									item-text="name"
									:items="states"
									v-model="account.entityState"
								>
									<template v-slot:item="{ item }">
										<span>{{ item.name }}</span>
									</template>
								</v-select>
								<v-text-field
									v-else
									class="h-outline"
									v-model="account.entityState"
									hide-details
									persistent-placeholder
									:label="$t('proposal.terms.entity-state')"
								></v-text-field>
							</div>
						</div>
					</template>

					<div class="modal-title mt-4 mb-2">{{ $t('proposal.terms.the-client') }}</div>

					<v-text-field
						class="h-outline"
						hide-details
						persistent-placeholder
						:label="$t('proposal.terms.legal-name')"
						v-model="lTerms.clientLegalName"
					>
					</v-text-field>

					<div class="d-flex justify-space-between">
						<div style="flex: 1 1 50%" class="pr-1">
							<v-text-field
									class="h-outline"
									v-model="lTerms.clientEntityType"
									hide-details
									persistent-placeholder
									:label="$t('proposal.terms.entity-type')"
							></v-text-field>
						</div>
						<div style="flex: 1 1 50%" class="pl-1">
							<v-text-field
									class="h-outline"
									v-model="lTerms.clientState"
									hide-details
									persistent-placeholder
									:label="$t('proposal.terms.entity-state')"
							></v-text-field>
						</div>
					</div>
				</div>

				<v-btn color="primary-action" block class="mt-4" @click="confirmTextConversion()">{{
					$t('proposal.terms.convert-button')
				}}</v-btn>
			</div>
			<div class="modal-footer">
				<v-btn class="secondary-action mr-1" style="width: 160px" @click="$emit('cancel')">{{
					$t('global.cancel')
				}}</v-btn>
				<v-btn class="super-action ml-1" style="width: 160px" @click="requestSave()">{{ $t('global.save') }}</v-btn>
			</div>
		</v-form>

		<v-dialog v-model="confirmConversion" persistent max-width="600">
			<div style="background-color: var(--v-white-base)">
				<v-container fluid class="pa-8">
					<v-row>
						<v-col cols="12"
							><h2>{{ $t('proposal.terms.convert-header') }}</h2></v-col
						>
					</v-row>
					<v-row>
						<v-col cols="12" align="left"><div v-html="$t('proposal.terms.confirm-convert')"></div></v-col>
					</v-row>
				</v-container>
				<div class="modal-footer">
					<v-btn class="primary-action mr-1" @click="confirmConversion = false">{{ $t('global.cancel') }}</v-btn>
					<v-btn class="secondary-action ml-1" @click="requestTextConversion()">{{ $t('global.continue') }}</v-btn>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
	import countries from '@/assets/data/countries.json';
	import DateSelector from "@/components/DateSelector";
	// import moment from 'moment';

	export default {
		name: 'TermsDialog',

		components: {DateSelector},

		props: ['terms', 'openStatus', 'proposalService'],

		data: function () {
			return {
				isValid: true,
				lTerms: JSON.parse(JSON.stringify(this.terms)),
				dayOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 30, 45, 60, 90, 120, 180],
				depositOptions: ['No deposit', 'Fixed amount', 'Percentage'],
				scheduleOptions: ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Annually', 'As Needed'],
				effectiveMenu: false,
				effectiveDate: this.terms.effectiveDate,
				confirmConversion: false,
				// editContractorInfo: false,
				account: null,
				countries: countries,
				isReady: false,

				rules: {
					min(min, v) {
						return v >= min || `Must be at least ${min}`;
					},
					max(max, v) {
						return v <= max || `Cannot be greater than ${max}.`;
					},
				},
			};
		},

		mounted() {
			this.fetchAccountInfo();
		},

		computed: {
			contractorInfo: function () {
				if (this.account.entityType === 'Individual') {
					return this.account.accountName + ' an ' + this.account.entityType;
				} else {
					return this.account.accountName + ' a ' + this.account.entityState + ' ' + this.account.entityType;
				}
			},

			effectiveDateFormatted: function () {
				return this.$DateTime.fromISO(this.effectiveDate).toLocaleString(this.$DateTime.DATE_MED);
				// return moment(this.effectiveDate).format('MM/DD/YYYY');
			},

			entityTypes: function () {
				if (this.account.country === 'US' || this.account.country === 'CA') {
					let country = this.countries.find((c) => c.code === this.account.country);
					if (country) {
						return country.entityTypes;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},

			states: function () {
				if (this.account.country == 'US' || this.account.country == 'CA') {
					let country = this.countries.find((c) => c.code === this.account.country);
					if (country) {
						return country.states;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},
		},

		methods: {
			fetchAccountInfo: function () {
				this.proposalService.getAccountInfo().then((res) => {
					this.account = res.data;
					this.isReady = true;
				});
			},

			saveContractorInfo: function () {
				this.$store.dispatch('updateAccountInfo', this.account);
				// this.editContractorInfo = false;
			},

			requestSave: function () {
				this.saveContractorInfo();
				this.$emit('save', this.lTerms);
			},

			confirmTextConversion: function () {
				this.confirmConversion = true;
			},

			requestTextConversion: function () {
				this.$emit('text-conversion', this.lTerms);
			},

			effectiveDateUpdated: function (date) {
				if(date) {
					this.lTerms.effectiveDate = date + 'T12:00:00Z';
				}else{
					this.lTerms.effectiveDate = null;
				}
			},
		},
	};
</script>

<style lang="scss">
	#proposal-terms-dialog {
		width: 447px;
		background-color: var(--v-white-base);

		.disclaimer {
			display: flex;
			justify-content: space-between;
			align-content: flex-start;
			background-color: var(--v-gray_10-base);
			border-radius: 4px;
			> div:first-child {
				flex: 0 0 40px;
				padding: 8px;
			}
			> div:last-child {
				flex: 1 1 auto;
				text-align: left;
				font-size: 14px;
				font-weight: 300;
				padding: 8px 8px 8px 0;
			}
		}

		.checkbox-flex {
			display: flex;
			justify-content: space-between;
			align-content: flex-start;
			border: 1px solid var(--v-gray_50-base);
			//background-color: var(--v-blue_5-base);
			border-radius: 4px;
			margin-bottom: 8px;
			> div:first-child {
				flex: 0 0 40px;
				padding: 8px;
			}
			> div:last-child {
				flex: 1 1 auto;
				text-align: left;
				font-size: 14px;
				font-weight: 300;
				padding: 8px 8px 8px 0;
				> div.label {
					margin-top: 2px;
					font-size: 14px;
					font-weight: 300;
				}
				> div.details {
					margin-top: 8px;
					color: var(--v-gray_80-base);
					font-size: 12px;
					font-weight: 400;
				}
			}
		}
	}
	//.label {
	//	text-align: left;
	//	font-weight: bold;
	//}
</style>
