var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          staticStyle: { "font-size": "1.1em" },
          attrs: { color: "primary", timeout: 30000, bottom: true },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarLabel) + " "),
          _c(
            "v-btn",
            {
              staticClass: "ml-2",
              attrs: { dark: "", outlined: "" },
              on: {
                click: function($event) {
                  return _vm.routeTo()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.buttonLabel))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: { icon: "", text: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }