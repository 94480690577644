var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "font-14" },
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _vm.toDo && _vm.current.length > 0
            ? _c(
                "v-row",
                {
                  key: _vm.$store.state.productivity.todo.updateKey,
                  staticClass: "ma-0 pa-0",
                  attrs: { dense: "" }
                },
                [
                  _c(
                    "draggable",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        list: _vm.toDo.items,
                        tag: "section",
                        animation: 200,
                        handle: ".drag",
                        "ghost-class": "ghost"
                      },
                      on: { end: _vm.endDragHandler }
                    },
                    _vm._l(_vm.toDo.items, function(item, index) {
                      return _c(
                        "div",
                        { key: item.id },
                        [
                          _vm.current.findIndex(function(c) {
                            return c.id === item.id
                          }) > -1
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "px-3 pt-2 pb-1 ma-0 todo text-left",
                                  staticStyle: {
                                    "border-bottom":
                                      "1px solid var(--v-gray_30-base)"
                                  },
                                  style:
                                    index === 0
                                      ? "border-top: 1px solid var(--v-gray_30-base)"
                                      : "",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "row-format gap-2" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "drag round-checkbox" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: item.complete,
                                                expression: "item.complete"
                                              }
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              id: "cb-" + item.id
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                item.complete
                                              )
                                                ? _vm._i(item.complete, null) >
                                                  -1
                                                : item.complete
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  var $$a = item.complete,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          item,
                                                          "complete",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          item,
                                                          "complete",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      item,
                                                      "complete",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function($event) {
                                                  return _vm.checkComplete(item)
                                                }
                                              ]
                                            }
                                          }),
                                          _c("label", {
                                            attrs: { for: "cb-" + item.id }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          class:
                                            "editContent " +
                                            (item.complete
                                              ? "itemComplete"
                                              : "") +
                                            " " +
                                            _vm.getFontClass(item),
                                          staticStyle: {
                                            width: "100%",
                                            outline: "none",
                                            "word-break": "break-word"
                                          },
                                          attrs: {
                                            id:
                                              "item-" +
                                              item.id +
                                              "-" +
                                              _vm.manageEvents,
                                            contenteditable: !item.complete
                                          },
                                          on: {
                                            keydown: [
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "delete",
                                                    [8, 46],
                                                    $event.key,
                                                    [
                                                      "Backspace",
                                                      "Delete",
                                                      "Del"
                                                    ]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.checkDeleteToDo(
                                                  item,
                                                  $event
                                                )
                                              },
                                              function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  $event.ctrlKey ||
                                                  $event.shiftKey ||
                                                  $event.altKey ||
                                                  $event.metaKey
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                _vm.setItem(item, $event)
                                                _vm.addNew(item.id)
                                              }
                                            ],
                                            blur: function($event) {
                                              return _vm.setItem(item, $event)
                                            }
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(item.item) + " ")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      key: item.id + item.dueDate,
                                      staticClass:
                                        "font-12 row-format align-center"
                                    },
                                    [
                                      _c("date-selector", {
                                        class:
                                          "mt-1 " +
                                          (item.dueDate ? "" : "todo-action"),
                                        attrs: {
                                          date: item.dueDate,
                                          label: "+ Set due date",
                                          micro: true,
                                          "content-class":
                                            "font-12 font-gray_80"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.setItemDueDate(
                                              item.id,
                                              $event
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "delete-button ml-auto material-symbols-rounded pointer",
                                          attrs: { small: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteToDo(item)
                                            }
                                          }
                                        },
                                        [_vm._v("$delete")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.past.length > 0
        ? _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 pa-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: { cols: "12" },
                      on: {
                        click: function($event) {
                          _vm.showOldTasks = !_vm.showOldTasks
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            "pl-5 row-format pointer align-center history-box " +
                            (_vm.showOldTasks ? "history-box-open" : "")
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "brand-regular font-12 font-grey" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.showOldTasks
                                        ? "productivity.completed-tasks-hide"
                                        : "productivity.completed-tasks-show"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "past-count ml-1 text-center" },
                            [_vm._v(_vm._s(_vm.past.length))]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm.showOldTasks
                    ? _c("v-col", { attrs: { cols: "12", align: "left" } }, [
                        _c(
                          "div",
                          { staticClass: "column-format" },
                          _vm._l(_vm.past, function(item) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "mt-2 ml-3" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-grey font-12 brand-medium"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatForTimeAgo")(
                                          item.dateCompleted
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c("br"),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-grey font-12 brand-semilight",
                                    staticStyle: {
                                      "text-decoration": "line-through"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.item))]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }