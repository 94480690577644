<template>
	<div class="ma-4">
		<div style="max-height: calc(var(--vh) - 80px); overflow: auto">
			<lead-gen v-show="currentView === 'SalesPipeline'"></lead-gen>
		</div>
	</div>
</template>

<script>
import LeadGen from "@/modules/leadgen/LeadGen";

export default {
	name: "Business",

	props: [],

	components: {LeadGen},

	data: function () {
		return {
			currentView: 'SalesPipeline',
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		setView: function (view) {
			this.currentView = view;
		},
	},

	computed: {
		pages: function () {
			let result = [];
			result.push({ label: 'Overview', value: 'SalesPipeline' })
			return result;
		},
	},

}
</script>

<style scoped lang="scss">

</style>