<template>
	<div class="column-format">
		<div class="column-format gap-1 pb-4">
			<div class="brand-medium pb-2">Work to be done</div>
			<div class="row-format gap-2">
				<v-select
					hide-details
					dense
					outlined
					v-model="type"
					:items="types"
					item-text="label"
					item-value="value"
					style="max-width: 140px; background-color: var(--v-white-base)"
					label="Type"
				>
					<template v-slot:selection="{ item }">
						<span class="font-12">{{ item.label }}</span>
					</template>
				</v-select>
				<v-select
					hide-details
					dense
					outlined
					v-model="assignedTo"
					:items="['All', 'Mine']"
					style="max-width:90px; background-color: var(--v-white-base)"
					label="Assigned"
				>
					<template v-slot:selection="{ item }">
						<span class="font-12">{{ item }}</span>
					</template>
				</v-select>
			</div>
		</div>

		<div class="column-format gap-4 font-14" style="padding-top: 1px">
			<work-bundle
				v-if="filteredPastDue.length"
				:items="filteredPastDue"
				@deliverable-clicked="handleDeliverableClick($event)"
				@ticket-clicked="handleTicketClick($event)"
				@todo-clicked="handleOpportunityClick($event)"
			>
				<template v-slot:heading>
					<div class="brand-bold" style="color: var(--v-error-base)">
						<v-icon class="material-symbols-rounded mb-1" size="20" color="error">warning</v-icon> Past due ({{filteredPastDue.length}})
					</div>
				</template>
			</work-bundle>

			<work-bundle
				:items="filteredToday"
				:count-complete="countComplete(todayList)"
				:show-complete="showCompletedToday"
				@toggle-show-complete="showCompletedToday = !showCompletedToday"
				@deliverable-clicked="handleDeliverableClick($event)"
				@ticket-clicked="handleTicketClick($event)"
				@todo-clicked="handleOpportunityClick($event)"
			>
				<template v-slot:heading>
					<div class="brand-medium">{{ $t('projects.due-today') }} ({{filteredToday.length}})</div>
					<div class="empty-yay" v-if="filteredToday.length === 0">
						&#127780; Nothing left to do today!
					</div>
				</template>
			</work-bundle>

			<work-bundle
					:items="filteredThisWeek"
					:count-complete="countComplete(thisWeekList)"
					:show-complete="showCompletedThisWeek"
					@toggle-show-complete="showCompletedThisWeek = !showCompletedThisWeek"
					@deliverable-clicked="handleDeliverableClick($event)"
					@ticket-clicked="handleTicketClick($event)"
					@todo-clicked="handleOpportunityClick($event)"
			>
				<template v-slot:heading>
					<div class="brand-medium">{{ $t('projects.due-this') }} ({{filteredThisWeek.length}})</div>
					<div class="empty-yay" v-if="filteredThisWeek.length === 0">
						&#128526; You seem to be all done for the week!
					</div>
				</template>
			</work-bundle>

			<work-bundle
					:items="filteredNextWeek"
					:count-complete="countComplete(nextWeekList)"
					:show-complete="showCompletedNextWeek"
					:collapsed="true"
					@toggle-show-complete="showCompletedNextWeek = !showCompletedNextWeek"
					@deliverable-clicked="handleDeliverableClick($event)"
					@ticket-clicked="handleTicketClick($event)"
					@todo-clicked="handleOpportunityClick($event)"
			>
				<template v-slot:heading>
					<div class="brand-medium">{{ $t('projects.due-next') }} ({{filteredNextWeek.length}})</div>
					<div class="empty-yay" v-if="filteredNextWeek.length === 0">
						&#128640; Nothing on the horizon...
					</div>
				</template>
			</work-bundle>
		</div>
	</div>
</template>

<script>
	import DateTime from '@/modules/utils/HDateTime';
	import DeliverableTwoWeekMixin from '@/modules/productivity/work/DeliverableTwoWeekMixin';
	import OpportunityTwoWeekMixin from '@/modules/productivity/work/OpportunityTwoWeekMixin';
	import TicketTwoWeekMixin from '@/modules/productivity/work/TicketTwoWeekMixin';
	import WorkBundle from '@/modules/home/focus/WorkBundle';

	export default {
		name: 'WorkTwoWeek',

		props: [],

		mixins: [DeliverableTwoWeekMixin, OpportunityTwoWeekMixin, TicketTwoWeekMixin],

		components: { WorkBundle},

		data: function() {
			return {
				filter: null,
				tabHeaders: ['Today', 'This week', 'Next week'],
				today: null,
				endOneWeek: null,
				endTwoWeek: null,
				showCompletedToday: false,
				showCompletedThisWeek: false,
				showCompletedNextWeek: false,
				assignedTo: 'All',
				type: 'All',
				types: [
					{ label: 'All', value: 'All' },
					{ label: 'Project Tasks', value: 'Deliverable' },
					{ label: 'Pipeline Todos', value: 'OpportunityToDo' },
					{ label: 'Tickets', value: 'Ticket' },
				],
			};
		},

		mounted() {
			this.handleStartUp();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function() {
				this.handleShutDown();
				this.handleStartUp();
			},

			handleShutDown: function() {
				this.handleDeliverableShutdown();
				this.handleOpportunityShutdown();
			},

			handleStartUp: function() {
				this.initializeDates();
				let assignedTo = localStorage.getItem('DELIVERABLE_TWO_WEEKS_ASSIGNED');
				if (assignedTo) {
					this.assignedTo = assignedTo;
				}
				let type = localStorage.getItem('DELIVERABLE_TWO_WEEKS_TYPE');
				if (type) {
					this.type = type;
				}
				this.handleDeliverableStartup();
				this.handleOpportunityStartup();
				this.handleTicketStartup();
			},

			initializeDates: function() {
				this.today = DateTime.now().startOf('day');
				this.endOneWeek = this.today.endOf('week');
				this.endTwoWeek = this.endOneWeek.plus({ week: 1 });
			},

			sortDate: function(a, b) {
				let sort = a.luxonDate.toMillis() - b.luxonDate.toMillis();
				if (sort === 0) {
					return this.sortStatus(a, b);
				} else {
					return sort;
				}
			},

			sortStatus: function(a, b) {
				return a.statusIndex - b.statusIndex;
			},

			countComplete: function(list) {
				return list.filter((l) => l.complete).length;
			},
		},

		watch: {
			assignedTo: function(val) {
				localStorage.setItem('DELIVERABLE_TWO_WEEKS_ASSIGNED', val);
			},
			type: function(val) {
				localStorage.setItem('DELIVERABLE_TWO_WEEKS_TYPE', val);
			},
		},

		computed: {
			combinedList: function() {
				let result = [];
				if (this.type === 'All' || this.type === 'Deliverable') {
					this.filteredDeliverables.forEach((d) => {
						result.push({
							id: d.id,
							type: 'Deliverable',
							luxonDate: d.luxonDate,
							complete: d.complete,
							statusIndex: d.statusIndex,
							deliverable: d,
						});
					});
				}

				if (this.type === 'All' || this.type === 'OpportunityToDo') {
					this.toDos.forEach((t) => {
						result.push({
							id: t.id,
							type: 'OpportunityToDo',
							luxonDate: t.luxonDate,
							complete: t.complete,
							statusIndex: t.statusIndex,
							toDo: t,
						});
					});
				}

				if (this.type === 'All' || this.type === 'Ticket') {
					this.tickets.forEach((ticket) => {
						if (!ticket.snoozedUntil) {
							result.push({
								id: ticket.id,
								type: 'Ticket',
								status: ticket.status,
								ticket: ticket,
								luxonDate: ticket.luxonDate,
							});
						}
					});
				}
				return result;
			},

			filteredPastDue: function() {
				return this.pastDueList.filter((d) => !d.complete);
			},

			filteredToday: function() {
				return this.todayList.filter((d) => this.showCompletedToday || !d.complete);
			},

			filteredThisWeek: function() {
				return this.thisWeekList.filter((d) => this.showCompletedThisWeek || !d.complete);
			},

			filteredNextWeek: function() {
				return this.nextWeekList.filter((d) => this.showCompletedNextWeek || !d.complete);
			},

			pastDueList: function() {
				let result = [];
				this.combinedList.forEach((d) => {
					if (d.luxonDate && d.luxonDate < this.today) {
						result.push(d);
					}
				});
				return result.sort(this.sortDate);
			},

			todayList: function() {
				let result = [];
				this.combinedList.forEach((d) => {
					if (d.luxonDate && d.luxonDate.hasSame(DateTime.local(), 'day')) {
						result.push(d);
					}
				});
				return result.sort(this.sortDate);
			},

			thisWeekList: function() {
				let result = [];
				this.combinedList.forEach((d) => {
					if (d.luxonDate && d.luxonDate > this.today && d.luxonDate <= this.endOneWeek) {
						result.push(d);
					}
				});
				return result.sort(this.sortDate);
			},

			nextWeekList: function() {
				let result = [];
				this.combinedList.forEach((d) => {
					if (d.luxonDate && d.luxonDate > this.endOneWeek && d.luxonDate <= this.endTwoWeek) {
						result.push(d);
					}
				});
				return result.sort(this.sortDate);
			},
		},
	};
</script>

<style scoped lang="scss">
	.week-column {
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f9f9f7 100%), #f0efea;
		border-radius: 8px 8px 0px 0px;
	}

	.deliverable-container {
		display: flex;
		flex-direction: column;
		height: 389px;
		overflow-y: auto;
		margin-top: 10px;
	}

	.small-deliverable-container {
		display: flex;
		flex-direction: column;
		height: 362px;
		overflow-y: auto;
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.empty-yay {
		font-size: 14px;
		padding: 12px 0;
		color: var(--v-gray_70-base);
	}
</style>
