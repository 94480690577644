<template>
	<div class="column-format gap-2" v-if="isReady">
		<div class="column-format gap-2">
			<font-selector :font="fontFamily" @font-changed="handleFontChange($event)" label="Font family" style="flex: 1 1 0"></font-selector>
			<div class="row-format gap-2">
				<v-select :items="fontSizes" v-model="fontSize" hide-details dense outlined label="Size" style="flex: 1 1 0" @change="updated()"></v-select>
				<v-select :items="fontWeights" v-model="fontWeight" hide-details dense outlined label="Weight" style="flex: 1 1 0" @change="updated()"></v-select>
			</div>
		</div>
		<div class="pr-5">
			<v-slider min="0" max="5" step="0.1" hide-details dense :label="`Line height`" :thumb-label="true" v-model="lineHeight" @change="updated()"></v-slider>
		</div>
		<div class="row-format gap-4 align-center">
			<color-selector v-model="color" @input="updated()" label="Font color"></color-selector>
		</div>

	</div>
</template>

<script>
	import FontSelector from '@/components/FontSelector';
	import ColorSelector from '@/components/ColorSelector';
	export default {
		name: 'FontStyle',

		props: ['value'],

		components: { ColorSelector, FontSelector },

		data: function() {
			return {
				isReady: false,
				fontFamily: null,
				color: null,
				fontSize: null,
				fontWeight: null,
				lineHeight: null,
				fontSizes: [10, 12, 14, 16, 18, 20, 22, 24, 28, 32, 38, 40, 48, 60, 72],
				fontWeights: [200,300,400,500,600,700,800]
			};
		},

		mounted() {
			this.setFromValue();
		},

		beforeDestroy() {},

		methods: {
			handleFontChange: function(fontFamily) {
				this.fontFamily = fontFamily;
				this.updated();
			},

			setFromValue: function() {
				this.fontFamily = this.value.fontFamily;
				this.color = this.value.color;
				this.fontSize = this.value.fontSize;
				this.lineHeight = this.value.lineHeight;
				this.fontWeight = this.value.fontWeight;
				this.isReady = true;
			},

			updated: function() {
				let fontStyle = {
					fontFamily: this.fontFamily,
					color: this.color,
					fontSize: this.fontSize,
					lineHeight: this.lineHeight,
					fontWeight: this.fontWeight
				};
				this.$emit('input', fontStyle);
			},
		},

		computed: {},

		watch: {
			value: function() {
				this.setFromValue();
			},
		},
	};
</script>

<style scoped lang="scss"></style>
