var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("welcome-container", {
    scopedSlots: _vm._u([
      {
        key: "left",
        fn: function() {
          return [
            _c("div", { staticClass: "column-format fill-all" }, [
              _c("div", { staticClass: "column-format flex-grow-1 centered" }, [
                _c(
                  "div",
                  {
                    staticClass: "text-left column-format mr-6",
                    staticStyle: { "max-width": "450px" }
                  },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "UserInfoForm",
                        attrs: { "lazy-validation": "" },
                        model: {
                          value: _vm.isValid,
                          callback: function($$v) {
                            _vm.isValid = $$v
                          },
                          expression: "isValid"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "32px",
                              "font-weight": "600"
                            }
                          },
                          [
                            _vm._v(" Welcome"),
                            _vm.firstName
                              ? _c("span", [
                                  _vm._v(", " + _vm._s(_vm.firstName))
                                ])
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "font-16 font-gray_80 mt-2 mb-6" },
                          [
                            _vm._v(
                              " Tell us a bit about your business so we can tailor your experience to your needs. "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format gap-3" },
                          [
                            _c("v-text-field", {
                              staticClass: "h-outline",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: "First name",
                                rules: [
                                  function(v) {
                                    return !!v || "Required"
                                  }
                                ]
                              },
                              model: {
                                value: _vm.firstName,
                                callback: function($$v) {
                                  _vm.firstName = $$v
                                },
                                expression: "firstName"
                              }
                            }),
                            _c("v-text-field", {
                              staticClass: "h-outline",
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: "Last name",
                                rules: [
                                  function(v) {
                                    return !!v || "Required"
                                  }
                                ]
                              },
                              model: {
                                value: _vm.lastName,
                                callback: function($$v) {
                                  _vm.lastName = $$v
                                },
                                expression: "lastName"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-text-field", {
                          staticClass: "h-outline",
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: "What is your business called?",
                            placeholder: "E.g. Designs, Inc.",
                            rules: [
                              function(v) {
                                return !!v || "Required"
                              }
                            ]
                          },
                          model: {
                            value: _vm.businessName,
                            callback: function($$v) {
                              _vm.businessName = $$v
                            },
                            expression: "businessName"
                          }
                        }),
                        _c("v-autocomplete", {
                          staticClass: "h-outline",
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: "Where are you based?",
                            items: _vm.countriesSimple,
                            "item-text": "name",
                            "item-value": "code",
                            rules: [
                              function(v) {
                                return !!v || "Required"
                              }
                            ]
                          },
                          model: {
                            value: _vm.country,
                            callback: function($$v) {
                              _vm.country = $$v
                            },
                            expression: "country"
                          }
                        }),
                        _c("v-autocomplete", {
                          staticClass: "h-outline",
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: "What is your home currency?",
                            items: _vm.currencies,
                            "item-text": "label",
                            "item-value": "value",
                            rules: [
                              function(v) {
                                return !!v || "Required"
                              }
                            ]
                          },
                          model: {
                            value: _vm.currency,
                            callback: function($$v) {
                              _vm.currency = $$v
                            },
                            expression: "currency"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "row-format",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c("v-select", {
                              class:
                                "h-outline " +
                                (_vm.industry === "Other" ? "mr-1" : ""),
                              attrs: {
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: "What kind of work do you do?",
                                items: _vm.industryList,
                                placeholder: "Select",
                                rules: [
                                  function(v) {
                                    return !!v || "Required"
                                  }
                                ]
                              },
                              model: {
                                value: _vm.industry,
                                callback: function($$v) {
                                  _vm.industry = $$v
                                },
                                expression: "industry"
                              }
                            }),
                            _vm.industry === "Other"
                              ? _c("v-text-field", {
                                  staticClass: "h-outline",
                                  attrs: {
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    label: "Other industry",
                                    placeholder: "Nice, what industry?"
                                  },
                                  model: {
                                    value: _vm.otherIndustry,
                                    callback: function($$v) {
                                      _vm.otherIndustry = $$v
                                    },
                                    expression: "otherIndustry"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("v-select", {
                          staticClass: "h-outline",
                          staticStyle: { width: "100%" },
                          attrs: {
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: "How long have you been your own boss?",
                            placeholder: "Select",
                            items: _vm.experienceList,
                            rules: [
                              function(v) {
                                return !!v || "Required"
                              }
                            ]
                          },
                          model: {
                            value: _vm.years,
                            callback: function($$v) {
                              _vm.years = $$v
                            },
                            expression: "years"
                          }
                        }),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-6",
                            attrs: {
                              color: "primary",
                              block: "",
                              large: "",
                              disabled: !_vm.isValid
                            },
                            on: {
                              click: function($event) {
                                return _vm.next()
                              }
                            }
                          },
                          [_vm._v("Continue")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "right",
        fn: function() {
          return [
            _c("div", {
              staticClass: "row-format centered fill-all",
              staticStyle: {
                "background-image": "url('/images/onboarding/welcome1.png')",
                "background-size": "cover",
                "background-position": "center"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }