var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isConditionMet
    ? _c(
        "div",
        {
          staticClass: "text-left form-content",
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            }
          }
        },
        [
          _vm.active
            ? _c("editor", {
                ref: "textEditor",
                attrs: {
                  "api-key": _vm.$store.getters.getTinyMceKey,
                  "initial-value": _vm.element.text,
                  inline: true,
                  init: _vm.mceConfigText
                },
                on: { input: _vm.handleTextInput }
              })
            : _c("div", { domProps: { innerHTML: _vm._s(_vm.element.text) } }),
          _vm.active
            ? _c(
                "div",
                { staticClass: "row-format mb-n1 mt-1" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      staticStyle: { cursor: "grab" },
                      attrs: { size: "22" }
                    },
                    [_vm._v("drag_indicator")]
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-auto pointer material-symbols-rounded",
                      attrs: { size: "22" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("delete")
                        }
                      }
                    },
                    [_vm._v("delete")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }