<template>
	<LineChartGenerator
		:key="refreshKey"
		:chart-id="refreshKey"
		:chart-options="chartOptions"
		:chart-data="chartData"
		:height="height"
	/>
</template>

<script>
	import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
	import MetricService from '@/modules/reports/metrics/MetricService';
	import DateTime from '@/modules/utils/HDateTime';
	import { v4 as uuid4 } from 'uuid';

	export default {
		name: 'TimeTrackingChart',

		props: ['client'],

		components: { LineChartGenerator },

		data: function () {
			return {
				isReady: false,
				metricService: new MetricService(),
				rawData: [],
				refreshKey: uuid4(),

				height: 150,
				width: 200,

				chartOptions: {
					responsive: true,
					maintainAspectRatio: false,

					scales: {
						y: {
							ticks: {
								display: false,
							},
							grid: {
								display: false,
								drawBorder: false
							},
						},
						x: {
							grid: {
								display: false,
							},
							ticks: {
								display: false,
								autoSkip: true,
								maxTicksLimit: 3,
								maxRotation: 0,
								minRotation: 0,
							},
						},
					},
				},
			};
		},

		mounted() {
			this.getTimeMetrics();
			this.$store.state.eventBus.$on(`a-${this.accountId}.timer-event-create`, this.handleTimerEventUpdate);
			this.$store.state.eventBus.$on(`a-${this.accountId}.timer-event-update`, this.handleTimerEventUpdate);
			this.$store.state.eventBus.$on(`a-${this.accountId}.timer-event-delete`, this.handleTimerEventUpdate);
			this.$store.state.eventBus.$on(`u-${this.$store.getters.getLoggedInUserId}.timer-stop`, this.handleTimerStopEvent);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(`a-${this.accountId}.timer-event-create`, this.handleTimerEventUpdate);
			this.$store.state.eventBus.$off(`a-${this.accountId}.timer-event-update`, this.handleTimerEventUpdate);
			this.$store.state.eventBus.$off(`a-${this.accountId}.timer-event-delete`, this.handleTimerEventUpdate);
			this.$store.state.eventBus.$off(`u-${this.$store.getters.getLoggedInUserId}.timer-stop`, this.handleTimerStopEvent);
		},

		methods: {
			getTimeMetrics: function () {
				this.metricService
					.getMonthlyTimeMetricsByClient(this.client.id)
					.then((res) => {
						this.rawData = res.data;
						this.isReady = true;
						console.log(this.rawData);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			handleTimerEventUpdate() {
				this.getTimeMetrics();
			},

			handleTimerStopEvent(event) {
				if (event.message.clientId === this.client.id) {
					this.getTimeMetrics();
				}
			},
		},

		watch: {
			chartData: function () {
				this.refreshKey = uuid4();
			},

			hoursArray: function(val){
				let totalHours = val.reduce((sum, { value }) => sum + value, 0);
				this.$emit('total-hours',totalHours);

				let thisMonth = val.find(v => v.month === DateTime.now().startOf('month').toISODate());

				if(thisMonth){
					this.$emit('this-month',thisMonth.value);
				}else{
					this.$emit('this-month',0);
				}
			}
		},

		computed: {
			accountId() {
				return this.$store.getters.getAccountId;
			},

			// totalTime: function () {
			// 	return this.rawData.reduce((sum, { invoiced }) => sum + invoiced, 0);
			// },
			//
			// thisMonth: function () {
			// 	return this.rawData.reduce((sum, { received }) => sum + received, 0);
			// },

			chartData: function () {
				return {
					labels: this.labels,
					datasets: [
						{
							fill: true,
							lineTension: 0.3,
							label: 'Hours',
							backgroundColor: '#8EA3B8',
							data: this.hours,
						},
					],
				};
			},

			labels: function () {
				let monthData = this.rawData.timeByMonth;
				let sortableArray = [];
				for (let prop in monthData) {
					sortableArray.push(prop);
				}
				sortableArray.sort((a, b) => (a > b ? 1 : -1));
				return sortableArray.map((l) => DateTime.fromISO(l).toFormat('MMM yy'));
			},

			hoursArray: function(){
				let monthData = this.rawData.timeByMonth;

				let sortableArray = [];
				for (let prop in monthData) {
					sortableArray.push({
						month: prop,
						value: monthData[prop],
					});
				}
				return sortableArray;
			},

			hours: function () {
				let monthData = this.rawData.timeByMonth;
				let data = [];

				let sortableArray = [];
				for (let prop in monthData) {
					sortableArray.push({
						month: prop,
						value: monthData[prop],
					});
				}

				sortableArray.sort((a, b) => (a.month > b.month ? 1 : -1));
				sortableArray.forEach((i) => data.push(i.value / 60 / 60));
				return data;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
