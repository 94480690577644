<template>
	<div>
		<editor
			:api-key="$store.getters.getTinyMceKey"
			:init="mceConfig"
			rows="1"
			auto-grow
			v-model="comment"
			style="font-size: 14px; width: 100%"
			:disabled="$store.state.loggedInUser.userId !== authorId"
			@onblur="handleChange"
		>
		</editor>
	</div>
</template>

<script>
	import editor from '@tinymce/tinymce-vue';

	export default {
		name: 'Comment',

		props: ['value', 'authorId'],

		components: { editor },

		data: function() {
			return {
				comment: this.value,

				mceConfig: {
					menubar: false,
					inline: false,
					paste_as_text: false,
          browser_spellcheck: true,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					placeholder: 'Add a note...',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: false,
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
							'bold italic underline forecolor | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist formatting_group | link unlink savebutton',
					],
					content_style:
						"@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); body { font-family: 'Inter', sans-serif; }",

					toolbar_groups: {
						formatting_group: {
							icon: 'format',
							tooltip: 'Formatting',
							items: 'fontsizeselect fontselect | indent outdent table | removeformat',
						},
					},
					style_formats: [
						{ title: 'Paragraph', format: 'p' },
						{ title: 'Title', format: 'h1' },
						{ title: 'Heading', format: 'h2' },
						{ title: 'Subheading', format: 'h3' },
						{ title: 'Code', format: 'code' },
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('savebutton', {
							icon: 'new-save',
							onAction: this.handleChange,
						});

						editor.ui.registry.addIcon('new-save','<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z"/></svg>');
					},
				},
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleChange: function() {
				this.$emit('input', this.comment);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
