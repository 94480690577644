var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "meeting-schedule" } }, [
    !_vm.selectedDate
      ? _c(
          "div",
          [
            _c("div", { staticClass: "row-format centered mt-1 mb-2" }, [
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", disabled: _vm.disablePrevious },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.incrementMonth(-1)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { size: "30" } }, [_vm._v("$prev")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "font-16 brand-medium px-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.monthStart.toLocaleString({
                        month: "long",
                        year: "numeric"
                      })
                    ) +
                    " "
                )
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", disabled: _vm.disableNext } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { size: "30" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.incrementMonth(1)
                            }
                          }
                        },
                        [_vm._v("$next")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("v-date-picker", {
              staticClass: "schedule-picker",
              style:
                "--light-accent:" +
                _vm.lightAccent +
                "; --accent:" +
                _vm.$store.getters.getCustomBranding.primaryColor +
                ";",
              attrs: {
                min: _vm.minDate,
                "full-width": "",
                "picker-date": _vm.monthStart.toISODate(),
                "no-title": true,
                "allowed-dates": _vm.hasAvailability,
                "first-day-of-week": _vm.$store.state.firstDayOfWeek
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                },
                change: _vm.dateClicked
              }
            })
          ],
          1
        )
      : _c("div", { staticClass: "fill-height" }, [
          _c("div", { staticClass: "row-format centered mt-1 mb-2" }, [
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.incrementDay(-1)
                      }
                    }
                  },
                  [_c("v-icon", { attrs: { size: "30" } }, [_vm._v("$prev")])],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "font-16 brand-medium pointer px-4",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.zoomOut()
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.selectedDate.toLocaleString(_vm.DateTime.DATE_HUGE)
                    ) +
                    " "
                )
              ]
            ),
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  { attrs: { icon: "" } },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: { size: "30" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.incrementDay(1)
                          }
                        }
                      },
                      [_vm._v("$next")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", {
            staticClass: "brand-medium my-2 pointer",
            on: {
              click: function($event) {
                _vm.selectedDate = null
              }
            }
          }),
          _vm.availableSlots.length
            ? _c(
                "div",
                {
                  staticClass: "row-format",
                  staticStyle: { "flex-wrap": "wrap" }
                },
                _vm._l(_vm.availableSlots, function(slot, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "slot pointer row-format centered",
                      style:
                        "--accent-color: " +
                        _vm.$store.getters.getCustomBranding.primaryColor +
                        "; --contrast-color: " +
                        _vm.contrast +
                        "; --light-accent:" +
                        _vm.lightAccent,
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.selectTimeSlot(slot)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            "pa-1 " +
                            (_vm.selectedSlot &&
                            _vm.selectedSlot.start === slot.start
                              ? "selected"
                              : "")
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.DateTime.fromISO(slot.start).toLocaleString(
                                  _vm.DateTime.TIME_SIMPLE
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "selected selected-slot pa-1",
                          style:
                            "overflow: hidden; " +
                            (_vm.selectedSlot &&
                            _vm.selectedSlot.start === slot.start
                              ? "visibility:visible; width:50%"
                              : "visibility:hidden; width:0px"),
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.timeConfirmed.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v(" Selected ")]
                      )
                    ]
                  )
                }),
                0
              )
            : _c(
                "div",
                { staticClass: "column-format centered" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { size: "80" }
                    },
                    [_vm._v("hourglass_disabled")]
                  )
                ],
                1
              ),
          _vm.availableSlots.length
            ? _c("div", { staticClass: "mt-5 font-12" }, [
                _vm._v(_vm._s(_vm.Settings.defaultZone.name))
              ])
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }