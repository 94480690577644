var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "secondary-action",
          on: {
            click: function($event) {
              return _vm.openModal()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "iconBlock d-flex align-center" },
            [
              _c("v-icon", { attrs: { size: "14" } }, [_vm._v("info")]),
              _vm.hasUnread
                ? _c("div", { staticClass: "unreadIndicator" })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "pl-1 body-12" }, [
            _c("span", { staticClass: "navItem mr-1" }, [
              _vm._v(_vm._s(_vm.$t("invoice.statuses." + _vm.invoice.status)))
            ]),
            _c("span", { staticClass: "mr-1" }, [_vm._v("·")]),
            _c("span", { staticClass: "statusItem" }, [
              _vm._v(
                _vm._s(_vm.$DateTime.humanDateTime(_vm.invoice.statusTimestamp))
              )
            ])
          ])
        ]
      ),
      _c(
        "basic-modal",
        {
          attrs: {
            dialog: _vm.modalIsOpen,
            persistent: false,
            "max-width": 445
          },
          on: {
            close: function($event) {
              return _vm.closeModal()
            }
          }
        },
        [
          _c(
            "div",
            { attrs: { id: "invoice-events-modal" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-dialog",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("$close")])],
                1
              ),
              _c(
                "div",
                { staticClass: "modal-padding" },
                [
                  _c("div", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.$t("global.activity")))
                  ]),
                  _c("p", { staticClass: "modal-subtitle" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("invoice.invoice-number-appended", {
                            invoiceNumber: _vm.invoice.invoiceNumber
                          })
                        ) +
                        " "
                    )
                  ]),
                  _vm._l(_vm.formattedList, function(event) {
                    return _c(
                      "div",
                      { key: event.timestamp, attrs: { id: "events-wrapper" } },
                      [
                        _c(
                          "div",
                          { staticClass: "event-wrapper" },
                          [
                            _c(
                              "div",
                              { staticClass: "timestamp" },
                              [
                                _c("v-icon", { attrs: { size: "12" } }, [
                                  _vm._v("$clock")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$DateTime.humanDateTime(
                                        event.timestamp
                                      )
                                    ) +
                                    " "
                                )
                              ],
                              1
                            ),
                            _vm._l(event.events, function(note, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "event" },
                                [_c("div", [_vm._v(_vm._s(note))])]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }