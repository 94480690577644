export default class LeadGenRules {
	actions;
	phase;
	columns;

	constructor(phase) {
		if (phase) this.phase = phase.toLowerCase();

		this.columns = [
			{ name: 'inquiry', label: 'Inquiry', color: '' },
			{ name: 'prospect', label: 'Prospect', color: 'regent_20' },
			{ name: 'discovery', label: 'Discovery', color: 'dodger_20' },
			{ name: 'proposal', label: 'Proposal', color: 'bright_sun_20' },
			{ name: 'signedproposal', label: 'Signed contract', color: 'celery_20' },
			{ name: 'project', label: 'Active projects', color: 'burnt_sienna_20' },
			// { name: 'idle', label: 'Idle', color: 'white' },
		];

		this.actions = [
			{
				name: 'createClient',
				inActions: ['inquiry'],
				inCards: ['inquiry'],
				label: 'leadgen.create-client',
				icon: 'user',
			},
			{
				name: 'createDiscovery',
				inActions: ['prospect', 'discovery'],
				inCards: ['prospect'],
				label: 'leadgen.create-discovery',
				icon: 'discovery',
			},
			{
				name: 'viewDiscovery',
				inActions: ['discovery'],
				inCards: ['discovery'],
				label: 'leadgen.view-discovery',
				icon: 'discovery',
			},
			{
				name: 'createProposal',
				inActions: ['prospect', 'discovery'],
				inCards: ['prospect', 'discovery','inquiry'],
				label: 'leadgen.create-proposal',
				icon: 'proposals',
			},
			{
				name: 'createProject',
				inActions: ['prospect', 'discovery', 'signedproposal'],
				inCards: ['proposal', 'signedproposal','inquiry'],
				label: 'leadgen.create-project',
				icon: 'projects',
			},
			{
				name: 'viewProposal',
				inActions: ['proposal', 'signedproposal'],
				inCards: ['proposal', 'signedproposal'],
				proposalStatus: ['draft', 'sent', 'signed', 'fully executed'],
				label: 'leadgen.view-proposal',
				icon: 'proposals',
			},
			// {
			// 	name: 'sendReminder',
			// 	inActions: ['proposal'],
			// 	inCards: ['proposal'],
			// 	proposalStatus: ['sent'],
			// 	label: 'leadgen.send-reminder',
			// 	icon: 'email',
			// },
			{
				name: 'signProposal',
				inActions: ['proposal'],
				proposalStatus: ['signed', 'sent'],
				label: 'leadgen.sign-proposal',
				icon: 'signature',
			},
			{
				name: 'voidProposal',
				inActions: ['proposal'],
				proposalStatus: ['sent'],
				label: 'leadgen.void-proposal',
			},
			{
				name: 'archiveProposal',
				inAdminActions: ['proposal'],
				proposalStatus: ['draft', 'sent', 'signed', 'fully executed'],
				label: 'leadgen.archive-proposal',
			},
			{
				name: 'viewProject',
				inActions: ['project'],
				inCards: ['project'],
				label: 'leadgen.view-project',
				icon: 'projects',
			},
			{
				name: 'completeProject',
				inActions: ['project'],
				label: 'leadgen.complete-project',
			},
			{
				name: 'archiveProject',
				inAdminActions: ['project'],
				label: 'leadgen.archive-project',
			},
			{
				name: 'deleteProject',
				inActions: ['project'],
				label: 'leadgen.delete-project',
			},
			{
				name: 'archiveClient',
				inAdminActions: ['prospect'],
				label: 'leadgen.archive-client',
			},
			{
				name: 'deleteClient',
				inAdminActions: ['prospect', 'discovery', 'proposal'],
				label: 'leadgen.delete-client',
			},
		];
	}

	getColumns() {
		return this.columns;
	}

	getPhaseColor(phase) {
		phase = phase.toLowerCase();
		if (this.columns.filter((col) => col.name == phase).length)
			return this.columns.filter((col) => col.name == phase)[0].color;
		return '';
	}

	getActions() {
		return this.actions.filter((action) => {
			if (!action.inActions) return false;
			return action.inActions.indexOf(this.phase) > -1;
		});
	}

	getAdminActions() {
		return this.actions.filter((action) => {
			if (!action.inAdminActions) return false;
			return action.inAdminActions.indexOf(this.phase) > -1;
		});
	}

	getCardActions() {
		return this.actions.filter((action) => {
			if (!action.inCards) return false;
			return action.inCards.indexOf(this.phase) > -1;
		});
	}

	filterProposalActions(actions, status) {
		return actions.filter((action) => {
			if (!action.proposalStatus) return true;
			return action.proposalStatus.indexOf(status.toLowerCase()) > -1;
		});
	}
}
