var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "row-format py-2",
        staticStyle: { "border-bottom": "1px solid var(--v-gray_30-base)" }
      },
      [
        _c(
          "div",
          {
            class:
              "row-format align-center page-link " +
              (_vm.tab === "Field Settings" ? "page-link-active" : ""),
            on: {
              click: function($event) {
                return _vm.selectTab("Field Settings")
              }
            }
          },
          [
            _c(
              "v-icon",
              {
                staticClass: "material-symbols-rounded",
                attrs: {
                  size: "22",
                  color: _vm.tab === "Field Settings" ? "primary" : "gray_50"
                }
              },
              [_vm._v("settings")]
            ),
            _c("div", { staticClass: "ml-1" }, [_vm._v("Field Settings")])
          ],
          1
        ),
        !_vm.nonConditionalTypes.includes(_vm.item.type)
          ? _c(
              "div",
              {
                class:
                  "row-format align-center page-link " +
                  (_vm.tab === "Conditional Logic" ? "page-link-active" : ""),
                on: {
                  click: function($event) {
                    return _vm.selectTab("Conditional Logic")
                  }
                }
              },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded",
                    attrs: {
                      size: "22",
                      color:
                        _vm.tab === "Conditional Logic" ? "primary" : "gray_50"
                    }
                  },
                  [_vm._v("account_tree")]
                ),
                _c(
                  "div",
                  { staticClass: "ml-1" },
                  [
                    _vm._v("Conditional Logic "),
                    _vm.showConditionalHelper
                      ? _c(
                          "v-icon",
                          { attrs: { color: "success", small: "" } },
                          [_vm._v("done_outline")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]
    ),
    _c("div", [
      _c(
        "div",
        { staticClass: "column-format gap-3 px-2 py-2" },
        [
          _c("div", { staticClass: "row-format align-center" }, [
            _c("div", { staticClass: "font-18 brand-medium" }, [
              _vm._v("Edit " + _vm._s(this.item.fieldName))
            ]),
            _c(
              "div",
              { staticClass: "ml-auto row-format align-center gap-2" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded pointer",
                    attrs: { size: "20" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("duplicate")
                      }
                    }
                  },
                  [_vm._v("content_copy")]
                ),
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded pointer",
                    attrs: { size: "20" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("delete")
                      }
                    }
                  },
                  [_vm._v("delete")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { small: "", elevation: "0", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("done")
                      }
                    }
                  },
                  [_vm._v("Done")]
                )
              ],
              1
            )
          ]),
          _vm.tab === "Field Settings"
            ? _c(_vm.item.type + "Config", {
                key: _vm.item.type,
                tag: "component",
                attrs: {
                  item: _vm.item,
                  template: _vm.template,
                  schema: _vm.schema
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("change", $event)
                  }
                }
              })
            : _vm._e(),
          _vm.tab === "Conditional Logic"
            ? _c("conditional-config", {
                attrs: { item: _vm.item, schema: _vm.schema }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }