<template>
	<div>
		<v-container style="max-width: 985px!important;" class="mt-4">
			<v-row>
				<v-col cols="12">
					<div style="font-size: 48px" class="brand-semilight">
						{{ $t(`subscription-v${this.pricingVersion}.heading`) }}
					</div>
				</v-col>
				<v-col cols="12" class="my-4">
					<v-btn-toggle v-model="annual" :mandatory="true" color="primary" background-color="gray_5">
						<v-btn large :value="false">Monthly</v-btn>
						<v-btn large :value="true">Annually</v-btn>
					</v-btn-toggle>
				</v-col>
			</v-row>
			<v-row v-if="$store.getters.isDisabledAccount">
				<v-col cols="12">
					<div class="my-1 brand-medium row-format centered gap-2">
						<v-icon color="error">info</v-icon>
						<div style="color: var(--v-error-base)">
							Your current subscription has expired. Please select a new plan to continue using Moxie.
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="isCanceled">
				<v-col cols="12">
					<div class="my-1 brand-medium row-format centered gap-2">
						<v-icon color="primary">info</v-icon>
						<div class="font-primary">
							Plan cancellation pending. Workspace active until
							{{
								HDateTime.fromISO(currentSubscription.recurlySubscription.currentPeriodEndsAt).toLocaleString(
									HDateTime.DATE_MED
								)
							}}
						</div>
						<div class="font-red font-12 pointer" @click="reactivateCurrentPlan">[Reactivate plan]</div>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="pendingChange && !isForcedPendingChange">
				<v-col cols="12">
					<div class="my-1 brand-medium row-format centered gap-2">
						<v-icon color="primary">info</v-icon>
						<div class="font-primary">
							Plan changing to {{ getFriendlyPlanName(pendingChange.plan.code) }} on
							{{ HDateTime.fromISO(pendingChange.activateAt).toLocaleString(HDateTime.DATE_FULL) }}
						</div>
						<div class="font-red font-12 pointer" @click="cancelPendingChange">[Cancel change]</div>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="row-format gap-4 pa-4" style="justify-content: center">
						<plan-card
							:pricing-version="pricingVersion"
							:current-plan="currentPlan"
							:in-trial="inTrial"
							:plan="plan"
							v-for="plan in filteredPlans"
							:key="plan.code"
							:disabled="(pendingChange && !isForcedPendingChange) || isCanceled"
							auto-coupon-header="Black Friday Special!"
							:auto-coupon-description="autoCouponDescription"
							:auto-coupon-discount="autoCouponDiscount"
							:auto-coupon-valid-until="`*Offer must be redeemed by ${DateTime.fromJSDate(redemptionEnd).toLocaleString(DateTime.DATE_MED)}`"
							@click="planClicked(plan)"
						></plan-card>
					</div>
					<div>(All amounts in USD)</div>
				</v-col>
			</v-row>
			<v-row v-if="invoices.length || billingInfo" class="mt-8">
				<v-col cols="12" md="8" class="text-left" order="2" order-md="1">
					<invoices :invoices="invoices"></invoices>
				</v-col>
				<v-col cols="12" md="4" class="text-left" order="1" order-md="2">
					<div v-if="billingInfo">
						<div class="brand-medium py-4 pr-4 row-format font-14">
							<div>{{ $t('subscription.payment-method') }}</div>
							<div class="font-primary pointer ml-auto" @click="updatePaymentInfo">Update payment method</div>
						</div>
						<div class="mr-8" style="height:42px;width:28px;height:42px;float:left;">
							<payment-icon size="28" :name="billingInfo.paymentMethod.cardType"></payment-icon>
						</div>
						<p class="mb-3" v-html="paymentMethod"></p>
						<p style="font-size: 14px; color: var(--v-gray_70-base)" v-html="paymentMethodExp"></p>
					</div>
					<div v-else>
						<div class="brand-medium py-4 pr-4 row-format font-14">
							<div>{{ $t('subscription.payment-method') }}</div>
							<div class="font-primary pointer ml-auto" @click="updatePaymentInfo">Add payment method</div>
						</div>
					</div>
					<div v-if="currentSubscription && !inTrial && !isCanceled" class="row-format pr-4">
						<div class="ml-auto font-red font-14 pointer" @click="planCancelDialog = true">Cancel current plan</div>
					</div>
					<div
						v-if="activeCoupons.length"
						style="border: 1px solid var(--v-gray_30-base); border-radius: 4px"
						class="pa-2 font-14 mt-3"
					>
						<div class="brand-medium">Active discounts:</div>
						<div v-for="coupon in activeCoupons" :key="coupon.id" class="row-format">
							<div>{{ coupon.coupon.name }}</div>
							<div class="ml-auto">{{ coupon.coupon.discount.percent }}%</div>
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div class="brand-medium font-24 text-left mt-8">Frequently Asked Questions</div>
					<div
						v-for="(q, index) in faq"
						:key="index"
						class="text-left brand-semilight my-4 pa-5"
						style="background-color: var(--v-gray_5-base);border-radius: 4px"
					>
						<div
							class="font-20 row-format pointer"
							@click="isExpanded(index) ? collapseFaq(index) : expandFaq(index)"
						>
							<div>{{ q.question }}</div>
							<v-icon class="ml-auto" color="black">{{ isExpanded(index) ? '$minus' : '$plus' }}</v-icon>
						</div>
						<div class="mt-3" style="white-space: pre-wrap" v-if="isExpanded(index)">{{ q.answer }}</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<basic-modal :dialog="paymentDialog" @close="paymentDialog = false" v-if="paymentDialog">
			<payment
				:billing-info="billingInfo"
				:current-plan="currentPlan"
				:new-plan="pendingPlan"
				:auto-coupon-code="autoCouponCode"
				:auto-coupon-discount="autoCouponDiscount"
				:auto-coupon-description="autoCouponDescription"
				@billing-info="billingInfoSet($event)"
			></payment>
		</basic-modal>
		<basic-modal :dialog="planChangeDialog" @close="planChangeDialog = false" v-if="planChangeDialog">
			<plan-change
				:current-subscription="currentSubscription"
				:new-plan="pendingPlan"
				@confirm="changePlan()"
			></plan-change>
		</basic-modal>
		<basic-modal :dialog="planCancelDialog" @close="planCancelDialog = false" v-if="planCancelDialog">
			<plan-cancel @confirmed="cancelCurrentPlan()" @close="planCancelDialog = false"></plan-cancel>
		</basic-modal>
	</div>
</template>

<script>
	import SubscriptionService from '@/modules/subscription/SubscriptionService';
	import PlanCard from '@/modules/subscription/v2/PlanCard';
	import Invoices from '@/modules/subscription/v2/Invoices';
	import BasicModal from '@/components/BasicModal';
	import Payment from '@/modules/subscription/v2/Payment';
	import PaymentIcon from '@/components/PaymentIcon';
	import HDateTime from '@/modules/utils/HDateTime';
	import PlanChange from '@/modules/subscription/v2/PlanChange';
	import ConfirmModal from '@/components/ConfirmModal';
	import PlanCancel from '@/modules/subscription/v2/PlanCancel';
	import AuthService from '@/modules/auth/AuthService';
	import AddOnService from '@/modules/subscription/addons/AddOnService';
	import {DateTime} from 'luxon';

	export default {
		name: 'Subscription',

		props: ['pricingVersion'],

		components: { PlanCancel, PlanChange, Payment, BasicModal, Invoices, PlanCard, PaymentIcon },

		data: function() {
			return {
				DateTime: DateTime,
				currentSubscription: null,
				subscriptionService: new SubscriptionService(),
				addOnService: new AddOnService(),
				plans: [],
				invoices: [],
				coupons: [],
				annual: false,
				expandedFaq: [],
				billingInfo: null,
				paymentMethod: '',
				paymentMethodExp: '',
				pendingPlan: null,
				paymentDialog: false,
				planChangeDialog: false,
				planCancelDialog: false,
				pendingChange: null,
				HDateTime: HDateTime
			};
		},

		mounted() {
			this.getPlans();
			this.getCurrentSubscription();
			this.getInvoices();
			this.getBillingInfo();
			this.getPendingChange();
			this.getCouponRedemptions();
		},

		beforeDestroy() {},

		methods: {
			getFriendlyPlanName: function(code) {
				let plan = this.plans.find((p) => p.code === code);
				if (plan) {
					return plan.name;
				} else {
					return '?';
				}
			},

			planClicked: function(plan) {
				this.pendingPlan = plan;
				if (!this.billingInfo) {
					this.paymentDialog = true;
				} else {
					this.planChangeDialog = true;
				}
			},

			updatePaymentInfo: function() {
				this.pendingPlan = null;
				this.paymentDialog = true;
			},

			billingInfoSet: function() {
				this.getBillingInfo();

				if (this.pendingPlan) {
					this.$store.commit('startLoading');
					this.subscriptionService
						.postChange(this.pendingPlan.code)
						.then(() => {
							window.fbq('track', 'Subscribe', {
								value: this.pendingPlan.price,
								currency: 'USD'
							});
							this.getCurrentSubscription();
							this.getNewAuthTokens();
						})
						.catch((err) => console.log(err))
						.finally(() => {
							this.paymentDialog = false;
							this.$store.commit('stopLoading');
						});
				} else {
					this.$store.commit('info', 'Billing info updated.');
					this.paymentDialog = false;
				}
			},

			cancelCurrentPlan: function() {
				this.$store.commit('startLoading');
				this.subscriptionService
					.cancelSubscription()
					.then(() => {
						this.getCurrentSubscription();
						this.addOnService.cancelAllPlans();
						this.planCancelDialog = false;
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			reactivateCurrentPlan: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Please confirm you want to reactivate your current subscription?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.subscriptionService
							.reactivateSubscription()
							.then(() => {
								this.getCurrentSubscription();
							})
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			cancelPendingChange: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to cancel this pending change?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.subscriptionService
							.deleteChange()
							.then(() => (this.pendingChange = null))
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			changePlan: function() {
				this.$store.commit('startLoading');
				this.subscriptionService
					.postChange(this.pendingPlan.code)
					.then(() => {
						this.getCurrentSubscription();
						this.getPendingChange();
						this.getNewAuthTokens();
					})
					.finally(() => {
						this.planChangeDialog = false;
						this.$store.commit('stopLoading');
					});
			},

			expandFaq: function(index) {
				this.expandedFaq.push(index);
			},

			collapseFaq: function(index) {
				let ix = this.expandedFaq.findIndex((f) => f === index);
				if (ix > -1) {
					this.expandedFaq.splice(ix, 1);
				}
			},

			isExpanded: function(index) {
				let ix = this.expandedFaq.findIndex((f) => f === index);
				return ix > -1;
			},

			getInvoices() {
				this.subscriptionService.getInvoices().then((res) => {
					this.invoices.splice(0, this.invoices.length);
					this.invoices.push(...res.filter((i) => i.total > 0));
				});
			},

			getPendingChange() {
				this.subscriptionService.getChange().then((response) => {
					this.pendingChange = response;
				});
			},

			getBillingInfo() {
				this.subscriptionService
					.getBillingInfo()
					.then((response) => {
						if (response) {
							this.billingInfo = response;
							this.paymentMethod = this.getPaymentMethod(response);
							this.paymentMethodExp = this.getPaymentMethodExp(response);
						} else {
							this.billingInfo = null;
							this.paymentMethod = null;
							this.paymentMethodExp = null;
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getCouponRedemptions() {
				this.subscriptionService
					.getCouponRedemptions()
					.then((response) => {
						this.coupons.splice(0);
						this.coupons.push(...response);
					})
					.catch((error) => {
						console.log(error);
					});
			},

			getCurrentSubscription: function() {
				this.subscriptionService.getCurrent().then((res) => {
					this.currentSubscription = res;
					if (this.currentSubscription && this.currentSubscription.recurlySubscription) {
						if (this.currentSubscription.recurlySubscription.plan.code.includes('annual')) {
							this.annual = true;
						} else {
							this.annual = false;
						}
					}
				});
			},

			getPlans: function() {
				this.subscriptionService.getPlans(this.pricingVersion).then((res) => {
					this.plans.splice(0, this.plans.length);
					this.plans.push(...res);
					this.plans.sort((a, b) => a.price - b.price);
				});
			},

			getPaymentMethod(billingInfo) {
				return (
					billingInfo.paymentMethod.cardType +
					'<span style="margin-left:24px;margin-right:12px">&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;</span>' +
					billingInfo.paymentMethod.lastFour
				);
			},

			getPaymentMethodExp(billingInfo) {
				return (
					'<span class="mr-2">Expires</span>' +
					billingInfo.paymentMethod.expMonth +
					' / ' +
					(billingInfo.paymentMethod.expYear - 2000)
				);
			},

			getNewAuthTokens() {
				AuthService.refreshToken(true).then((res) => {
					this.$store.commit('logIn', res);

					AuthService.getProfile()
						.then((res) => {
							this.$store.dispatch('setLoggedInUser', res);
							this.$store.commit('info', 'Plan has been updated.');
						})
						.catch((err) => {
							console.log('Something went wrong fetching the user profile.' + err);
							this.$store.commit('logOut');
							this.$router.push('/login');
						});
				});
			},
		},

		computed: {
			redemptionStart: function(){
				return new Date(new Date().getFullYear(), 10, 25);
			},

			redemptionEnd: function(){
				return new Date(new Date().getFullYear(), 11, 10);
			},

			salePeriodEnd: function(){
				return new Date(new Date().getFullYear(), 11, 10);
			},

			isWithinRedemptionPeriod: function(){
				const now = new Date();
				const isBFDeal = localStorage.getItem('isBFDeal');

				if(isBFDeal === 'true' && now >= this.redemptionStart && now <= this.redemptionEnd){
					return true;
				}else{
					return now >= this.redemptionStart && now <= this.salePeriodEnd
				}
			},

			autoCouponDescription: function(){
				if(this.autoCouponCode){
					if(this.annual){
						return '30% discount on your first year';
					}else{
						return '50% discount on your first three months';
					}
				}else{
					return null;
				}
			},

			autoCouponDiscount: function(){
				if(this.autoCouponCode){
					if(this.annual){
						return 30;
					}else{
						return 50;
					}
				}else{
					return null;
				}
			},

			autoCouponCode: function(){
				if(this.isWithinRedemptionPeriod && (this.inTrial || !this.currentSubscription )){
					if(this.annual){
						return 'savewithmoxie1year'
					}else{
						return 'savewithmoxie';
					}
				}else{
					return null;
				}
			},

			isForcedPendingChange: function(){
				return this.pendingChange && this.pendingChange?.plan.code === this.currentSubscription?.recurlySubscription.plan.code
			},

			activeCoupons: function() {
				return this.coupons.filter((c) => c.state === 'active');
			},

			currentPlan: function() {
				if (this.currentSubscription && this.currentSubscription.recurlySubscription) {
					return this.currentSubscription.recurlySubscription.plan;
				} else {
					return null;
				}
			},

			isCanceled: function() {
				if (this.currentSubscription && this.currentSubscription.recurlySubscription) {
					return this.currentSubscription.recurlySubscription.state === 'canceled';
				} else {
					return false;
				}
			},

			inTrial: function() {
				if (
					this.currentSubscription &&
					this.currentSubscription.recurlySubscription &&
					this.currentSubscription.recurlySubscription.trialEndsAt !== null
				) {
					let trialEndsAt = HDateTime.fromISO(this.currentSubscription.recurlySubscription.trialEndsAt);
					return trialEndsAt > HDateTime.now();
				} else {
					return false;
				}
			},

			filteredPlans: function() {
				return this.plans.filter((p) => p.interval === (this.annual ? 12 : 1));
			},

			faq: function() {
				return this.$t(`subscription-v${this.pricingVersion}.faq`);
			},
		},
	};
</script>

<style scoped lang="scss"></style>
