<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-invoice')" @action="navigateToInvoice()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small :client="client" />
			<div class="ml-2">{{ client.name }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ $t('notifications.payment-rejected') }}</div>
			<div class="font-12">
				<span>{{ amount }} Payment Rejected.</span>
				<span>Please check your payment dashboard for additional details.</span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";
	import InvoiceDetails from "@/modules/invoices/InvoiceDetails";

	export default {
		name: 'PaymentFailedNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar, NotificationLayout },

		data: function() {
			return {
				invoice: this.notificationPayload.invoiceMini,
				client: this.notificationPayload.client,
				payloadAmount: this.notificationPayload.amount,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			navigateToInvoice: function() {
				let binding = {
					clientId: this.client.id,
					id: this.invoice.id,
					showDuplicate: false,
				}
				this.$store.state.globalModalController.openModal(InvoiceDetails,binding,false,true);
			},
		},

		computed: {
			amount: function(){
				let currency = this.invoice.currency ? this.invoice.currency : this.$store.state.defaultCurrency;
				return this.$formatters.dollars(this.payloadAmount,true,true, currency);
			}
		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
