var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "create-invoice" } },
    [
      _c(
        "div",
        { staticClass: "row-format align-center mb-6 pt-6 px-6" },
        [
          _c("div", { staticClass: "brand-medium font-16" }, [
            _vm._v(_vm._s(_vm.heading))
          ]),
          _c(
            "v-icon",
            {
              staticClass: "ml-auto",
              attrs: { size: "20" },
              on: {
                click: function($event) {
                  return _vm.$emit("result")
                }
              }
            },
            [_vm._v("$close")]
          )
        ],
        1
      ),
      _vm.view === "SELECT_CLIENT"
        ? _c("div", { staticStyle: { "min-width": "400px" } }, [
            _c(
              "div",
              { staticClass: "px-6" },
              [
                _c("select-client", {
                  key: _vm.clientRefreshKey,
                  attrs: {
                    "prepend-icon": "$clients",
                    label: "Client",
                    "hide-archive": true,
                    placeholder: _vm.$t("timetrack.current.choose-client")
                  },
                  model: {
                    value: _vm.clientId,
                    callback: function($$v) {
                      _vm.clientId = $$v
                    },
                    expression: "clientId"
                  }
                }),
                _vm.isPaidAccount || _vm.clientList.length === 0
                  ? _c(
                      "div",
                      {
                        staticClass: "pointer my-6",
                        staticStyle: {
                          "font-size": "14px",
                          color: "var(--v-primary-base)"
                        },
                        on: {
                          click: function($event) {
                            return _vm.newClientModal()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("projects.create-client")) + " "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "py-2",
                staticStyle: { "border-top": "1px solid var(--v-gray_20-base)" }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    attrs: { width: "160", disabled: _vm.nextButtonDisabled },
                    on: { click: _vm.handleSelectClient }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.next")) + " ")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.view === "SELECT_TYPE"
        ? _c(
            "div",
            { staticClass: "px-6 pb-6", attrs: { id: "select-type" } },
            [
              _c(
                "div",
                {
                  staticClass: "pointer row-format button-box mb-2 pa-4",
                  on: {
                    click: function($event) {
                      return _vm.createStandardInvoice()
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: { "align-self": "flex-start" },
                      attrs: { color: "gray_80" }
                    },
                    [_vm._v("paid")]
                  ),
                  _c("div", { staticClass: "text-left pl-4" }, [
                    _c("div", { staticClass: "brand-medium font-16" }, [
                      _vm._v(_vm._s(_vm.$t("invoice.invoice-types.STANDARD")))
                    ]),
                    _c("div", { staticClass: "font-14 font-gray_80 mt-2" }, [
                      _vm._v(
                        _vm._s(_vm.$t("invoice.invoice-types.STANDARD-DESC"))
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "pointer row-format button-box mb-2 pa-4",
                  on: {
                    click: function($event) {
                      _vm.view = "DEPOSIT_AMOUNT"
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: { "align-self": "flex-start" },
                      attrs: { color: "gray_80" }
                    },
                    [_vm._v("account_balance")]
                  ),
                  _c("div", { staticClass: "text-left pl-4" }, [
                    _c("div", { staticClass: "brand-medium font-16" }, [
                      _vm._v(_vm._s(_vm.$t("invoice.invoice-types.DEPOSIT")))
                    ]),
                    _c("div", { staticClass: "font-14 font-gray_80 mt-2" }, [
                      _vm._v(
                        _vm._s(_vm.$t("invoice.invoice-types.DEPOSIT-DESC"))
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "pointer row-format button-box mb-2 pa-4",
                  on: {
                    click: function($event) {
                      return _vm.createPaymentPlanInvoice()
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: { "align-self": "flex-start" },
                      attrs: { color: "gray_80" }
                    },
                    [_vm._v("timeline")]
                  ),
                  _c("div", { staticClass: "text-left pl-4" }, [
                    _c("div", { staticClass: "brand-medium font-16" }, [
                      _vm._v(
                        _vm._s(_vm.$t("invoice.invoice-types.PAYMENTPLAN"))
                      )
                    ]),
                    _c("div", { staticClass: "font-14 font-gray_80 mt-2" }, [
                      _vm._v(
                        _vm._s(_vm.$t("invoice.invoice-types.PAYMENTPLAN-DESC"))
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm.enableRecurring
                ? _c(
                    "div",
                    {
                      staticClass: "pointer row-format button-box mb-2 pa-4",
                      on: {
                        click: function($event) {
                          return _vm.createRecurringInvoice()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: { "align-self": "flex-start" },
                          attrs: { color: "gray_80" }
                        },
                        [_vm._v("replay_circle_filled")]
                      ),
                      _c("div", { staticClass: "text-left pl-4" }, [
                        _c("div", { staticClass: "brand-medium font-16" }, [
                          _vm._v(
                            _vm._s(_vm.$t("invoice.invoice-types.RECURRING"))
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "font-14 font-gray_80 mt-2" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("invoice.invoice-types.RECURRING-DESC")
                              )
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "font-12 pb-2" }, [_vm._v("- Or -")]),
              _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    outlined: "",
                    small: "",
                    color: "gray_50"
                  },
                  on: {
                    click: function($event) {
                      return _vm.aiAssistant()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-gray_80" }, [
                    _vm._v("Quick Invoice ✨Assistant")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.view === "DEPOSIT_AMOUNT"
        ? _c(
            "div",
            {
              staticStyle: { width: "450px" },
              attrs: { id: "deposit-amount" }
            },
            [
              _c(
                "div",
                { staticClass: "px-6 pb-6" },
                [
                  _c("v-text-field", {
                    staticClass: "h-outline",
                    attrs: {
                      prefix: _vm.currencySymbol,
                      "hide-details": "",
                      "persistent-placeholder": "",
                      label: _vm.$t("invoice.create-modal.deposit-label"),
                      placeholder: _vm.$t(
                        "invoice.create-modal.deposit-amount-placeholder"
                      )
                    },
                    on: { input: _vm.validateDepositAmount },
                    model: {
                      value: _vm.depositAmount,
                      callback: function($$v) {
                        _vm.depositAmount = $$v
                      },
                      expression: "depositAmount"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "py-2",
                  staticStyle: {
                    "border-top": "1px solid var(--v-gray_30-base)"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      attrs: { width: "160", disabled: !_vm.depositAmount },
                      on: {
                        click: function($event) {
                          return _vm.createDepositInvoice()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("global.continue")) + " ")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "basic-modal",
        {
          ref: "clientCreateModal",
          attrs: { dialog: _vm.clientCreateDialog },
          on: {
            close: function($event) {
              _vm.clientCreateDialog = false
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "max-height": "calc(100vh - 100px)",
                height: "calc(100vh - 100px)",
                "--vh": "calc(100vh - 150px)"
              }
            },
            [
              _c("client-create", {
                attrs: {
                  "is-open": _vm.clientCreateDialog,
                  clientList: _vm.clientList
                },
                on: {
                  close: function($event) {
                    _vm.clientCreateDialog = false
                  },
                  alert: function($event) {
                    return _vm.$refs.clientCreateModal.setAlert($event)
                  },
                  "client-created": function($event) {
                    return _vm.clientCreated($event)
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }