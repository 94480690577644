<template>
	<div id="discovery-builder" style="min-height: 100vh" v-if="discoveryTemplate && allowRender">
		<div id="header-area" @mouseenter="headerHovering = true" @mouseleave="headerHovering = false">
			<div class="header-left pl-md-6 pl-4">
				<v-btn @click="addContentDialog = true" class="primary-action">
					<v-icon left class="mt-1">$plusSmall</v-icon>
					{{ $t('leadgen.add-content') }}
				</v-btn>
				<v-menu v-model="settingsMenu" :close-on-content-click="false" :close-on-click="true">
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" class="primary-action ml-2">
							<v-icon left>$settings</v-icon>
							Settings
						</v-btn>
					</template>
					<v-container fluid style="max-width: 280px; width: 280px; min-height: 400px;  background-color: var(--v-white-base)">
						<v-row>
							<v-col class="pa-4" style="height: 400px">
								<div class="column-format" style="height: 100%; gap: 12px">
									<v-checkbox
										dense
										hide-details
										label="Show logo"
										@change="needSave = true"
										v-model="discoveryTemplate.showLogo"
									></v-checkbox>

									<font-selector
										label="Font"
										:font="discoveryTemplate.font"
										@font-changed="handleFontChange($event)"
									></font-selector>

									<div class="row-format" style="justify-content: space-between; width:100%">
										<div style="width: fit-content">
											<v-menu offset-y :close-on-content-click="false">
												<template v-slot:activator="{ on }">
													<v-btn
														elevation="0"
														:color="discoveryTemplate.accentColor"
														v-on="on"
														width="120"
														class="accent-button"
													>
														Accent color
													</v-btn>
												</template>
												<div style="background-color: var(--v-white-base)">
													<v-color-picker
														v-model="discoveryTemplate.accentColor"
														@input="needSave = true"
														show-swatches
														mode="hexa"
													></v-color-picker>
												</div>
											</v-menu>
										</div>
										<div style="width: fit-content">
											<v-menu offset-y :close-on-content-click="false">
												<template v-slot:activator="{ on }">
													<v-btn
														elevation="0"
														:color="discoveryTemplate.accentColor"
														v-on="on"
														width="120"
														class="accent-button"
													>
														<span :style="`color: ${discoveryTemplate.buttonText}!important`"
															>Button text</span
														>
													</v-btn>
												</template>
												<div style="background-color: var(--v-white-base)">
													<v-color-picker
														v-model="discoveryTemplate.buttonText"
														@input="needSave = true"
														show-swatches
														mode="hexa"
													></v-color-picker>
												</div>
											</v-menu>
										</div>
									</div>

									<v-autocomplete
											:disabled="emailTemplates.length === 0"
											hide-details
											persistent-placeholder
											class="h-outline"
											label="Confirmation email template"
											:items="emailTemplates"
											item-value="id"
											item-text="name"
											clearable
											@change="needSave = true"
											v-model="discoveryTemplate.confirmationEmailTemplate"
									></v-autocomplete>

									<v-select
											:disabled="pipelineStages.length === 0"
											hide-details
											persistent-placeholder
											class="h-outline mt-n1"
											label="Add submission to pipeline"
											:items="pipelineStages"
											item-value="id"
											item-text="label"
											clearable
											@input="needSave = true"
											v-model="discoveryTemplate.pipelineStageId"
									>
										<template v-slot:item="{ item }">
											<div :style="`width: 20px; height: 20px; border-radius: 4px; background-color: ${item.hexColor}`"></div>
											<div class="ml-2">{{item.label}}</div>
										</template>
										<template v-slot:selection="{ item }">
											<div :style="`width: 20px; height: 20px; border-radius: 4px; background-color: ${item.hexColor}`"></div>
											<div class="ml-2">{{item.label}}</div>
										</template>
									</v-select>

									<v-btn @click="settingsMenu = false" class="primary-action" style="margin-top: auto">OK</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-container>
				</v-menu>
			</div>
			<div class="header-center d-flex align-center justify-center">
				<div class="mr-2" v-if="client"><client-avatar :client="client"></client-avatar></div>
				<div class="brand-semibold font-16">
					<v-text-field style="width:300px" dense hide-details outlined v-model="discoveryTemplate.name" placeholder="Template name" @change="needSave=true"></v-text-field>
				</div>
			</div>
			<div class="header-right pr-md-6 pr-4">
				<div v-if="$vuetify.breakpoint.mdAndUp" style="white-space: nowrap">
					<v-progress-circular
						class="mr-2"
						v-if="needSave || saving"
						indeterminate
						size="20"
						:color="saving ? 'green' : 'accent'"
					></v-progress-circular>
					<v-icon small class="mr-2" v-if="!needSave && !saving">check</v-icon>
					<span class="mr-3 font-12">{{ status }}</span>
				</div>
				<folder-selector :folders="folders" v-model="discoveryTemplate.folder" @input="needSave=true"></folder-selector>
				<v-btn class="primary-action ml-2" @click="save()"> Save </v-btn>
				<v-btn class="super-action ml-2" @click="shareDialog = true"> Share </v-btn>

				<v-menu :close-on-click="true" nudge-bottom="30">
					<template v-slot:activator="scope">
						<div class="ml-2">
							<v-btn icon class="menu-activator secondary-action" :disabled="false" v-on="scope.on">
								<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>

					<div class="more-menu">
						<div class="more-menu-item" @click="viewResults()">
							<!--							<v-icon left>$subtract</v-icon>-->
							View results
						</div>
						<div class="more-menu-item" @click="duplicateTemplate()">
							<!--							<v-icon left>$subtract</v-icon>-->
							Duplicate template
						</div>
						<div class="more-menu-item" @click="confirmDeleteDialog = true">
							<!--							<v-icon left>$delete</v-icon>-->
							Delete
						</div>
					</div>
				</v-menu>

				<div class="ml-2">
					<v-btn icon class="primary-action" @click="back()">
						<v-icon :size="20">$close</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
		<v-container class="px-0 px-md-4 mt-0" :style="`width: 800px; max-width: 800px; align-self:center; --discovery-font: ${discoveryTemplate.font}; --accent-color: ${discoveryTemplate.accentColor}`" @click="editorIndex = -1">
			<v-row>
				<v-col cols="12">
					<div class="form-box mb-12 mt-8">
						<div :style="`width:100%;`">
							<div
									style="background-color: var(--v-white-base);"
									class="pb-4 styled-form mt-4"
							>
								<div v-if="discoveryTemplate.showLogo" class="row-format centered">
									<img :src="$store.getters.getAccount.accountLogo" style="max-height: 100px; max-width: 250px" />
								</div>

								<draggable v-model="discoveryTemplate.schema" group="schema" @end="dragComplete" handle=".dragHandle">
									<div
											v-for="(item, index) in discoveryTemplate.schema"
											:key="item.id"
											class="content-block"
											@click.stop="rendererClick(index)"
											:style="computedStyle(index)"
									>
										<renderer
												v-if="editorIndex !== index"
												:editor-index="editorIndex"
												:update-flag="updateFlag"
												:item="item"
												:preview-mode="true"
										></renderer>

										<builder
												v-if="editorIndex === index"
												:item="item"
												:mce-config-header="mceConfigHeader"
												:mce-config-text="mceConfigText"
												:preview-mode="true"
												@changed="handleChange($event, index)"
												@shift-up="shiftUp(index)"
												@shift-down="shiftDown(index)"
												@duplicate-section="duplicateSection(index)"
												@delete-section="deleteSection(index)"
										></builder>
									</div>
								</draggable>
								<div class="mx-8 mt-8">
									<v-btn width="150" :color="discoveryTemplate.accentColor" elevation="0"
									><span :style="`font-weight: 600; color: ${discoveryTemplate.buttonText}!important`">{{
											$t('global.submit')
										}}</span></v-btn
									>
								</div>
							</div>

							<div
									class="content-block add-content d-flex flex-column align-center pointer my-5 pb-4"
									:style="computedStyle(-2)"
									@click="addContentDialog = true"
							>
								<div>
									<v-icon size="64" class="add-content-icon">$duplicate</v-icon>
								</div>
								<div>
									{{
										$t(
												discoveryTemplate.schema.length === 0
														? 'discovery.add-first-content'
														: 'discovery.add-additional-content'
										)
									}}
								</div>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>


		<basic-modal
			v-if="shareDialog"
			:dialog="shareDialog"
			:persistent="true"
			:retain-focus="false"
			:max-width="684"
			@close="shareDialog = false"
			v-slot="scope"
		>
			<share-send-widget
				v-if="client"
				:client-id="client.id"
				:discovery-template="discoveryTemplate"
				:mce-config-text="mceConfigText"
				:open-state="shareDialog"
				@alert="scope.setAlert($event)"
				@cancel="shareDialog = false"
				@finalize="shareDialog = false"
				@discovery-sent="handleDiscoverySent()"
			></share-send-widget>
			<share-form v-else @close="shareDialog = false" :full-link="fullTemplateLink" :full-link-embed="fullTemplateLinkEmbed"></share-form>
		</basic-modal>

		<confirm-dialog
			v-if="confirmDeleteDialog"
			:dialog="confirmDeleteDialog"
			@confirm="deleteTemplate"
			@close="cancelDeleteTemplate"
			:persistent="false"
			heading-text="Delete template"
			:body-text="$t('discovery.delete-confirm', { template: discoveryTemplate.name })"
		/>

		<basic-modal
			:dialog="addContentDialog"
			v-if="addContentDialog"
			:persistent="false"
			:max-width="300"
			@close="addContentDialog = false"
		>
			<add-content-dialog
				@add-item="
					handleAddItem($event);
					addContentDialog = false;
				"
				@close="addContentDialog = false"
			></add-content-dialog>
		</basic-modal>

		<basic-modal
				:dialog="resultsDialog"
				v-if="resultsDialog"
				:persistent="false"
				:max-width="900"
				@close="resultsDialog = false"
		>
			<div class="pa-3 column-format">
				<div class="row-format mr-2">
					<v-icon  class="ml-auto pointer" @click="resultsDialog=false">close</v-icon>
				</div>
				<form-submissions-table :template-id="discoveryTemplate.id"></form-submissions-table>
			</div>

		</basic-modal>
	</div>
</template>

<script>
	import DiscoveryTemplateService from './DiscoveryTemplateService';
	import DiscoveryService from './DiscoveryService';
	import ShareSendWidget from './ShareSendWidget';
	import ShareForm from '@/modules/discovery/ShareForm';
	import Renderer from './formrenderer/Renderer';
	import Builder from './formbuilder/Builder';
	import BasicModal from '@/components/BasicModal';
	import draggable from 'vuedraggable';
	import { v4 as uuidv4 } from 'uuid';
	import ClientAvatar from '@/components/ClientAvatar';
	import ClientService from '@/modules/clients/ClientService';
	import AddContentDialog from '@/modules/discovery/AddContentDialog';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import AccountService from '@/modules/account/AccountService';
	import FontSelector from '@/components/FontSelector';
	import EmailTemplateService from "@/modules/templates/EmailTemplateService";
	import PipelineSettingsService from "@/modules/pipeline/settings/PipelineSettingsService";
	import FormSubmissionsTable from "@/modules/discovery/FormSubmissionsTable";
	import FolderSelector from "@/components/FolderSelector";

	export default {
		name: 'DiscoveryBuilder',
		components: {
			FormSubmissionsTable,
			FontSelector,
			AddContentDialog,
			ClientAvatar,
			ShareSendWidget,
			Renderer,
			Builder,
			ConfirmDialog,
			BasicModal,
			ShareForm,
			draggable,
			FolderSelector
		},

		props: ['client', 'templateId', 'otherTemplateNames','folders'],

		data() {
			return {
				allowRender: true,
				needSave: false,
				saving: false,
				discoveryTemplateService: new DiscoveryTemplateService(),
				pipelineSettingsService: new PipelineSettingsService(),
				clientService: new ClientService(),
				discoveryService: new DiscoveryService(),
				discoveryTemplate: null,
				saveTimer: setInterval(this.save, 15 * 1000),
				addContentDialog: false,
				confirmDeleteDialog: false,
				duplicateDialog: false,
				duplicateTemplateName: null,
				shareDialog: false,
				shareFormDialog: false,
				resultsDialog: false,
				updateFlag: 0,
				editorIndex: -1,
				headerHovering: false,
				uuidv4: uuidv4,
				usedTemplateNames: [],
				uniqueAccountUrl: null,
				settingsMenu: false,
				emailTemplates: [],
				pipelineStages: [],
				baseUrl: process.env.VUE_APP_MEET_LINK,

				googleFontKey: process.env.VUE_APP_GOOGLE_FONT_KEY,

				fontOptions: {
					limit: 150,
				},

				mceConfigHeader: {
					menubar: false,
					inline: true,
					browser_spellcheck: true,
					plugins: ['paste', 'link'],
					paste_as_text: true,
					toolbar: ['undo redo | forecolor | bold italic underline | link unlink'],
					forced_root_block: '',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					setup: function (ed) {
						ed.on('keyDown', function (event) {
							if (event.keyCode == 13) {
								event.preventDefault();
								event.stopPropagation();
								return false;
							}
						});
					},
				},

				mceConfigText: {
					menubar: false,
					inline: false,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					browser_spellcheck: true,
					forced_root_block: '',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media'],
					contextmenu: 'paste | link image | table',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'table | styleselect forecolor | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | link unlink media',
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
				},
			};
		},

		mounted() {
			if (this.otherTemplateNames) {
				this.usedTemplateNames.push(...this.otherTemplateNames);
			}
			this.initializeBuilder();
			this.getUniqueAccountUrl();
		},

		methods: {
			initializeBuilder: function () {
				this.fetchDiscoveryTemplate();
				this.getEmailTemplates();
				this.getPipelineStages();
			},

			getPipelineStages: function(){
				this.pipelineSettingsService.getPipelineStages().then((res) => {
					this.pipelineStages.splice(0,this.pipelineStages.length);
					this.pipelineStages.push(... res.data.stages);
				})
			},

			getEmailTemplates: function () {
				new EmailTemplateService().getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0, this.emailTemplates.length);
					this.emailTemplates.push(...res.data);
					this.emailTemplates.sort((a,b) => {
						let aName = a.name ? a.name : "";
						let bName = b.name ? b.name : "";
						aName.localeCompare(bName);
					});
				});
			},

			back: function () {
				this.$emit('close');
			},


			handleDiscoverySent() {
				this.$emit('sent');
			},

			share() {
				this.shareDialog = true;
			},

			handleFontChange: function (font) {
				this.discoveryTemplate.font = font;
				this.needSave = true;
			},

			fetchDiscoveryTemplate() {
				this.discoveryTemplateService
					.getDiscoveryTemplate(this.templateId)
					.then((res) => {
						this.discoveryTemplate = res.data;
						this.needSave = false;
						this.saving = false;
						this.allowRender = true;

						if (!this.discoveryTemplate.uniqueTemplateId) {
							this.needSave = true;
							this.discoveryTemplate.uniqueTemplateId = this.convertName(this.discoveryTemplate.name);
						}
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			getId() {
				return uuidv4().replace(/-/g, '');
			},

			handleChange(item, index) {
				this.discoveryTemplate.schema[index] = item;
				this.updateFlag++;
				this.needSave = true;
			},

			save() {
				if (this.needSave) {
					this.performSave();
				}
			},

			performSave() {
				this.saving = true;
				this.discoveryTemplateService
					.updateDiscoveryTemplate(this.templateId, this.discoveryTemplate)
					.then(() => {
						this.needSave = false;
						this.saving = false;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			duplicateTemplate() {
				this.allowRender = false;
				let newTemplate = JSON.parse(JSON.stringify(this.discoveryTemplate));
				newTemplate.id = null;
				newTemplate.name = 'Copy of: ' + this.discoveryTemplate.name;

				this.discoveryTemplateService
					.createDiscoveryTemplate(newTemplate)
					.then((res) => {
						this.duplicateDialog = false;
						this.$emit('duplicated', res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			cancelDeleteTemplate() {
				this.confirmDeleteDialog = false;
			},

			deleteTemplate() {
				this.discoveryTemplateService.deleteDiscoveryTemplate(this.templateId).then(() => {
					this.back();
				});
			},

			viewResults(){
				this.resultsDialog = true;
			},

			rendererClick: function (index) {
				this.editorIndex = index;
			},

			computedStyle: function (index) {
				if (index === this.editorIndex) {
					return '--v-padding: 36px 36px 0px 36px; --v-border: 2px solid var(--v-primary-base)';
				} else {
					return '--v-padding: 36px 36px 0px 36px;';
				}
			},

			handleAddItem: function (item) {
				this.discoveryTemplate.schema.push(item);
				this.editorIndex = this.discoveryTemplate.schema.length - 1;
				this.$nextTick(this.scrollToBottom);
			},

			dragComplete: function (payload) {
				this.editorIndex = payload.newIndex;
				this.needSave = true;
			},

			shiftUp: function (index) {
				if (index > 0) {
					let block = this.discoveryTemplate.schema[index];
					this.discoveryTemplate.schema.splice(index, 1);
					this.discoveryTemplate.schema.splice(index - 1, 0, block);
					this.editorIndex = this.editorIndex - 1;
					this.needSave = true;
				}
			},

			shiftDown: function (index) {
				if (index < this.discoveryTemplate.schema.length - 1) {
					let block = this.discoveryTemplate.schema[index];
					this.discoveryTemplate.schema.splice(index, 1);
					this.discoveryTemplate.schema.splice(index + 1, 0, block);
					this.editorIndex = this.editorIndex + 1;
					this.needSave = true;
				}
			},

			duplicateSection: function (index) {
				let block = JSON.parse(JSON.stringify(this.discoveryTemplate.schema[index]));
				block.id = uuidv4();
				this.discoveryTemplate.schema.splice(index + 1, 0, block);
				this.editorIndex = index + 1;
				this.needSave = true;
			},

			deleteSection: function (index) {
				this.discoveryTemplate.schema.splice(index, 1);
				this.needSave = true;
				this.editorIndex = -1;
			},

			scrollToBottom: function () {
				let div = document.getElementById('form-area');
				if (div) {
					div.scrollTop = div.scrollHeight;
				}
			},

			getUniqueAccountUrl: function () {
				let accountService = new AccountService();
				accountService.getAccountUniqueUrl().then((res) => (this.uniqueAccountUrl = res.data));
			},

			convertName: function (name) {
				return name
					.replaceAll(' ', '-')
					.toLowerCase()
					.replaceAll(/[^a-zA-Z0-9-]/g, '');
			},
		},

		beforeDestroy() {
			clearInterval(this.saveTimer);
		},

		computed: {
			fullTemplateLinkEmbed: function () {
				if (this.discoveryTemplate) {
					return (
						this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + this.discoveryTemplate.uniqueTemplateId
					);
				} else {
					return null;
				}
			},

			fullTemplateLink: function () {
				if (this.discoveryTemplate) {
					let commPref = this.$store.state.communicationPreferences;
					if(commPref && commPref.primaryDomain){
						return 'https://' + commPref.primaryDomain + '/public/' + this.uniqueAccountUrl + '/' + this.discoveryTemplate.uniqueTemplateId;
					}else {
						return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + this.discoveryTemplate.uniqueTemplateId;
					}
				} else {
					return null;
				}
			},

			podId: function () {
				let podId = this.$store.getters.getAccount.pod.podId;
				return podId.replace(/\D/g, '');
			},

			uniqueTemplateId: function () {
				if (this.discoveryTemplate && this.discoveryTemplate.name) {
					return this.convertName(this.discoveryTemplate.name);
				} else {
					return '';
				}
			},

			editorHeight: function () {
				return this.$vuetify.breakpoint.height - 58;
			},

			status: function () {
				if (this.needSave && !this.saving) {
					return this.$t('proposal.save.prepare');
				} else if (this.saving) {
					return this.$t('proposal.save.saving');
				} else {
					return this.$t('proposal.save.complete');
				}
			},
		},

		watch: {
			uniqueTemplateId: function (newVal) {
				if (this.discoveryTemplate) {
					this.discoveryTemplate.uniqueTemplateId = newVal;
				}
			},

			templateId: function () {
				this.initializeBuilder();
			},
		},
	};
</script>

<style lang="scss">
	.accent-button.v-btn {
		min-height: 35px !important;
	}

	.font-picker {
		width: 100% !important;
		background-color: var(--v-white-base) !important;
		box-shadow: none !important;
		.dropdown-button {
			background-color: var(--v-white-base) !important;
			border: 1px solid var(--v-gray_50-base) !important;
			border-radius: 4px !important;
		}
		ul.expanded {
			background-color: var(--v-white-base) !important;
			border: 1px solid var(--v-gray_50-base) !important;
			border-radius: 4px !important;
			box-shadow: none !important;
		}
		ul {
			transition: none !important;
		}
	}

	#discovery-builder {
		background-color: var(--v-gray_10-base);
		#header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				flex: 0 1 33%;
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-center {
				flex: 0 1 34%;
			}
			.header-right {
				flex: 1 0 33%;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.v-btn:not(.v-btn--icon) {
					min-width: 96px;
				}
			}
		}
		.add-content {
			color: var(--v-gray_70-base);
			.add-content-icon {
				--secondary-opacity: 0.3;
				--secondary-color: var(--v-gray_80-base);
			}
			&:hover {
				color: var(--v-gray_90-base);
				svg {
					fill: var(--v-primary-base);
					--secondary-opacity: 1;
					--secondary-color: var(--v-accent-base);
				}
			}
		}

		.wrapper {
			display: flex;
			flex-direction: column;
		}

		.overlay {
			width: 100%;
			height: 100%;
			background-color: var(--v-secondary-base);
			z-index: 20;
			position: absolute;
			top: 0px;
			left: 0px;
			opacity: 0.6;
		}

		//.header-area {
		//	background-color: var(--v-white-base);
		//	height: 48px;
		//	box-shadow: 0px 1px 4px rgba(16, 17, 15, 0.2);
		//}

		.form-box {
			//margin-top: 12px;
			//padding: 0px 24px 0px 24px;
			height: fit-content;
			border-radius: 4px;
			background-color: var(--v-white-base);
		}

		.styled-form {
			font-family: var(--discovery-font) !important;
		}

		.form-area {
			height: var(--height);
			//background-color: var(--accent-color);
			width: 100%;
			overflow: auto;
			justify-content: center;
			display: flex;
		}

		.content-block {
			background-color: var(--v-white-base);
			padding: var(--v-padding);
			text-align: left;
			border: var(--v-border);
		}

		.progress {
			font-size: 12px;
			color: var(--v-gray_70-base);
		}
	}
</style>
