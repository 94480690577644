<template>
	<div>
		<div class="row-format m1-2 mt-1 centered" v-if="rightBar">
			<div class="row-format align-center">
				<v-icon small color="secondary" class="pointer" @click="prevDay">$chevronLeft</v-icon>
				<div class="mx-1 font-14 font-secondary">{{ formattedDate }}</div>
				<v-icon small color="secondary" class="pointer" @click="nextDay">$chevronRight</v-icon>
			</div>
		</div>
		<div class="row-format mb-2" v-else>
			<div class="brand-medium text-left mt-2 ml-3 pointer" @click="today">Schedule</div>
			<div class="ml-auto row-format align-center mt-2 mr-2">
				<v-icon small class="pointer" @click="prevDay">$chevronLeft</v-icon>
				<div class="mx-1 font-14 font-gray_80">{{ formattedDate }}</div>
				<v-icon small class="pointer" @click="nextDay">$chevronRight</v-icon>
			</div>
		</div>

		<div v-if="processedEvents.length" style="height: calc(100% - 40px); overflow-y: auto" class="show-scrollbar">
			<div
				v-for="event in processedEvents"
				:key="event.uuid"
				:id="event.uuid"
				:style="`background-color: ${event.backgroundColor}; border-radius: 4px; font-size: 14px;`"
				class="pointer mx-2 my-2 pa-1 event"
				@click="eventClicked({ event: event })"
			>
				<component
					style="min-height: 30px"
					:is="event.componentName"
					:event="event"
					:timed="event.timed"
					view="Schedule"
				></component>
			</div>
		</div>

		<div v-else-if="isReady" style="height: calc(100% - 40px)" class="row-format centered mt-5 pb-8">
			<div class="column-format centered">
				<h-icon2 name="calendar" size="35" style="--icon-color: var(--v-gray_80-base)"></h-icon2>
				<div class="font-gray_80">Nothing on your calendar today</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DateTime from "@/modules/utils/HDateTime";
	import CalendarService from '@/modules/calendar/CalendarService';
	import AppleCalendarEvent from '@/modules/calendar/events/AppleCalendarEvent';
	import GoogleCalendarEvent from '@/modules/calendar/events/GoogleCalendarEvent';
	import NativeCalendarEvent from '@/modules/calendar/events/NativeCalendarEvent';
	import ScheduledMeetingEvent from '@/modules/calendar/events/ScheduledMeetingEvent';
	import CalendarMixin from '@/modules/calendar/CalendarMixin';
	import NativeEventMixin from '@/modules/calendar/mixins/NativeEventMixin';
	import ScheduledMeetingMixin from '@/modules/calendar/mixins/ScheduledMeetingMixin';
	import { v4 as uuidv4 } from 'uuid';
	import HIcon2 from '@/components/HIcon2';
	import MicrosoftCalendarEvent from "@/modules/calendar/events/MicrosoftCalendarEvent";
	import ToDoEvent from "@/modules/calendar/events/ToDoEvent";

	export default {
		name: 'DayCalendar',

		props: ['isVisible','rightBar'],

		mixins: [CalendarMixin, NativeEventMixin, ScheduledMeetingMixin],

		components: {
			AppleCalendarEvent,
			GoogleCalendarEvent,
			NativeCalendarEvent,
			MicrosoftCalendarEvent,
			ScheduledMeetingEvent,
			ToDoEvent,
			HIcon2,
		},

		data: function () {
			return {
				calendarService: new CalendarService(),
				scheduleDate: DateTime.now(),
				eventTypes: ['APPLE_CALENDAR', 'GOOGLE_CALENDAR', 'SCHEDULED_MEETING', 'NATIVE','MICROSOFT_CALENDAR'],
				events: [],
				isReady: false,
			};
		},

		mounted() {
			this.getScheduleEvents();
		},

		beforeDestroy() {},

		methods: {
			today: function () {
				if (!this.isReady) return;
				this.scheduleDate = DateTime.now();
				this.getScheduleEvents();
			},

			nextDay: function () {
				if (!this.isReady) return;
				this.scheduleDate = this.scheduleDate.plus({ day: 1 });
				this.getScheduleEvents();
			},

			prevDay: function () {
				if (!this.isReady) return;
				this.scheduleDate = this.scheduleDate.minus({ day: 1 });
				this.getScheduleEvents();
			},

			getScheduleEvents: function () {
				this.isReady = false;
				this.events.splice(0, this.events.length);
				this.calendarService
					.getCalendarEvents(this.scheduleDate.toISODate(), this.scheduleDate.toISODate(), this.eventTypes.join(','))
					.then((res) => {
						this.events.push(...res.data);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.isReady = true;
						this.$nextTick(() => this.scrollToNextEvent());
					});
			},

			scrollToNextEvent: function () {
				let now = new Date();
				let nextEvent = this.processedEvents.find((e) => (e.start < now && e.end > now) || e.start > now);
				if(nextEvent) {
					let div = document.getElementById(nextEvent.uuid);
					div.scrollIntoView({behavior: 'smooth'});
				}
			},

			eventClicked: function (e) {
				if (e.event.source === 'GOOGLE_CALENDAR') {
					this.googleCalendarEventClicked(e.event.source, e.event.original);
				} else if (e.event.source === 'APPLE_CALENDAR') {
					this.appleCalendarEventClicked(e.event.source, e.event.original);
				} else if (e.event.source === 'MICROSOFT_CALENDAR') {
					this.microsoftCalendarEventClicked(e.event.source, e.event.original);
				} else if (e.event.source === 'NATIVE') {
					this.nativeCalendarEventClicked(e.event.original.meta.nativeEvent, this.events);
				} else if (e.event.source === 'SCHEDULED_MEETING') {
					this.scheduledMeetingEventClicked(e.event.original.meta.scheduledMeeting, this.events);
				}
			},
		},

		watch: {
			isVisible: function(val){
				if(val){
					this.$nextTick(() => this.scrollToNextEvent());
				}
			}
		},

		computed: {
			formattedDate: function () {
				let now = DateTime.now();

				if (now.hasSame(this.scheduleDate, 'day')) {
					return 'Today';
				} else if (now.minus({ day: 1 }).hasSame(this.scheduleDate, 'day')) {
					return 'Yesterday';
				} else if (now.plus({ day: 1 }).hasSame(this.scheduleDate, 'day')) {
					return 'Tomorrow';
				} else {
					return this.scheduleDate.toFormat('ccc, MMM d');
				}
			},

			processedEvents: function () {
				let result = [];
				let events = [...this.events];

				/// BEGIN FILTERING OUT DUPLICATE EVENTS BETWEEN MEETING SCHEDULER AND GOOGLE/APPLE CALENDAR
				let meetingIds = events
						.filter((e) => e.eventSource === 'SCHEDULED_MEETING')
						.map((m) => m.meta.scheduledMeeting.connectedICalUid ? m.meta.scheduledMeeting.connectedICalUid : m.meta.scheduledMeeting.icalUid);

				let calendarList = ['GOOGLE_CALENDAR','APPLE_CALENDAR','MICROSOFT_CALENDAR'];
				let linkedEvents = events.filter((e) => calendarList.includes(e.eventSource));
				events = events.filter((e) => !calendarList.includes(e.eventSource));

				linkedEvents = linkedEvents.filter((g) => !meetingIds.includes(g.meta.iCalUID));
				events.push(...linkedEvents);
				// END FILTERING DUPLICATE EVENTS

				events.forEach((e) => {
					let c = {
						name: e.summary,
						start: DateTime.fromISO(e.start).toJSDate(),
						end: DateTime.fromISO(e.end).toJSDate(),
						timed: !e.dateOnly,
						original: e,
						source: e.eventSource,
					};
					result.push(c);
				});

				if(this.$store.state.productivity && this.$store.state.productivity.todo){
					let items = JSON.parse(JSON.stringify(this.$store.state.productivity.todo.items.filter(t => t.dueDate === this.scheduleDate.toISODate())));
					for(let i=0; i < items.length; i++){
						let item = items[i];
						item.eventSource = 'TODO';
						let c = {
							name: item.item,
							start: DateTime.fromISO(item.dueDate).toJSDate(),
							end: DateTime.fromISO(item.dueDate).toJSDate(),
							timed: true,
							original: item,
							source: 'TODO',
							complete: item.complete
						}
						result.push(c);
					}
				}

				result.forEach((r) => {
					r.uuid = uuidv4();
					r.componentName = this.getComponentName(r);

					if (r.end < new Date() || r.complete) {
						r.backgroundColor = `var(--v-gray_5-base)`;
						r.foregroundColor = `gray_60`;
					} else {
						r.backgroundColor = `var(--v-gray_10-base)`;
						r.foregroundColor = `gray_90`;
					}
				});

				result.sort((a, b) => {
					return a.start - b.start;
				});

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.event {
		border: 1px solid var(--v-white-base);
		box-sizing: border-box;
		//box-shadow: 0px 1px 0px var(--v-white-base);

		&:hover {
			border: 1px solid var(--v-gray_50-base);
			box-sizing: border-box;
			//box-shadow: 0px 1px 0px var(--v-gray_50-base);
		}
	}
</style>
