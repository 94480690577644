<template>
	<div>
		Stripe Return Handler
	</div>
</template>

<script>
	export default {
		name: 'ReturnHandler',

		props: [],

		components: {},

		data: function () {
			return {};
		},

		mounted() {
			this.$store.state.eventBus.$emit('open-settings-modal','stripe');
		},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
