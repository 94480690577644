<template>
	<div class="panel-modal" style="min-width: 600px" v-if="isReady">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon color="gray_90" small class="mr-2 pointer" @click.stop="$emit('result')">$chevronRight</v-icon>
				<div class="brand-medium font-18">Edit client details</div>
				<div class="ml-auto row-format gap-2">
					<v-btn class="super-action" @click="updateClient">
						{{ $t('global.save') }}
					</v-btn>
					<v-icon color="gray_90" size="20" class="pointer material-symbols-rounded" @click="deleteClient">delete</v-icon>
				</div>
			</div>
		</div>

		<div class="panel-modal-body text-left show-scrollbar" id="client-edit">
			<v-form @keyup.enter.native.prevent id="clientEditForm" class="" ref="clientEdit" lazy-validation>
				<div class="row-format align-center mt-2 gap-2">
					<div class="avatar mb-1" v-if="local.logo">
						<client-avatar :client="local" :large="true" disable-click />
						<div class="avatar-delete pointer" v-if="local.s3LogoFile">
							<v-icon color="gray_80" @click="confirmDeleteLogo">$delete</v-icon>
						</div>
					</div>

					<client-avatar
						v-else
						:client="local"
						:large="true"
						:disable-click="true"
						style="height: 100%"
					/>

					<div @click="handleSampleDataNameClick" style="width: 100%">
						<v-text-field
							autofocus
							hide-details
							dense
							outlined
							persistent-placeholder
							placeholder="Enter client name"
							:label="$t('client.client-name')"
							v-model="local.name"
							:disabled="local.sampleData"
							:rules="[(value) => !!value || 'Required.']"
						>
						</v-text-field>
					</div>
					<div class="mt-n3">
						<vue-dropzone
							style="background-color: var(--v-white-base)"
							v-if="!local.s3LogoFile"
							ref="logoFileDropzone"
							id="logoDropzone"
							v-on:vdropzone-success="logoUploadSuccess"
							v-on:vdropzone-error="logoUploadFailure"
							v-on:vdropzone-sending="addFileUploadAuthHeader"
							:use-custom-slot="true"
							:options="logoDropzoneOptions"
						>
							<span class="font-12">Logo</span>
							<v-icon size="20" v-tippy="{ content: 'Upload client logo' }">$upload</v-icon>
						</vue-dropzone>
					</div>
				</div>

				<v-tabs v-model="tab">
					<v-tab>Business info</v-tab>
					<v-tab v-if="$store.getters.hasInvoiceAccess">Invoicing & payment settings</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-container fluid class="pa-0 mt-3 mx-0">
							<v-row dense>
								<v-col cols="12" class="column-format gap-3 pt-2">
									<v-select
										hide-details
										persistent-placeholder
										dense
										outlined
										label="Record type"
										v-model="local.clientType"
										:items="type"
										item-text="label"
										item-value="value"
									></v-select>

									<v-text-field
										v-model="local.address1"
										:label="$t('contact.address1')"
										hide-details
										dense
										outlined
										persistent-placeholder
									></v-text-field>

									<v-text-field
										v-model="local.address2"
										:label="$t('contact.address2')"
										hide-details
										dense
										outlined
										persistent-placeholder
									></v-text-field>

									<div class="row-format half gap-2">
										<v-text-field
											v-model="local.city"
											:label="$t('contact.city')"
											hide-details
											dense
											outlined
											persistent-placeholder
										></v-text-field>
										<v-text-field
											v-model="local.locality"
											:label="$t('contact.locality')"
											hide-details
											dense
											outlined
											persistent-placeholder
										/>
									</div>

									<div class="row-format half gap-2">
										<v-text-field
											v-model="local.postal"
											:label="$t('contact.postal')"
											hide-details
											dense
											outlined
											persistent-placeholder
										></v-text-field>

										<v-autocomplete
											v-model="local.country"
											:label="$t('contact.country')"
											dense
											outlined
											hide-details
											persistent-placeholder
											:items="countries.getCountriesSimple()"
											item-value="code"
											item-text="name"
										/>
									</div>

									<v-text-field
										v-model="local.leadSource"
										:label="$t('client.source')"
										hide-details
										dense
										outlined
										persistent-placeholder
									></v-text-field>

									<v-text-field
										v-model="local.website"
										:label="$t('contact.website')"
										hide-details
										dense
										outlined
										persistent-placeholder
									>
										<template v-slot:append>
											<span
												v-if="local.website"
												class="material-symbols-rounded font-18 font-primary pointer pt-1"
												@click="openLink(local.website)"
												>open_in_new</span
											>
										</template>
									</v-text-field>

									<div style="width:100%; position: relative">
										<vue-tel-input
											v-model="phone"
											:valid-characters-only="true"
											:preferred-countries="preferredCountries"
											@input="phoneUpdated"
											class="dense-phone"
											:inputOptions="{ placeholder: 'Phone' }"
										></vue-tel-input>
										<div class="phone-label">Phone</div>
									</div>

									<v-autocomplete
										hide-details
										persistent-placeholder
										dense
										outlined
										:label="$t('account.currency')"
										:items="currencies"
										item-text="label"
										item-value="value"
										v-model="local.currency"
									>
									</v-autocomplete>

									<v-text-field
										v-model="local.taxId"
										:label="$t('account.tax-id')"
										hide-details
										dense
										outlined
										persistent-placeholder
									></v-text-field>

									<v-text-field
										v-model="local.initials"
										label="Unique client initials (invoice number prefix & client avatar)"
										hide-details
										dense
										outlined
										persistent-placeholder
										:placeholder="initials"
										maxlength="4"
									></v-text-field>

									<custom-field
										v-for="field in fields"
										:key="field.id"
										mode="dense"
										:field="field"
										:value="getCustomValue(field.id)"
										@change="handleCustomFieldChange($event)"
									></custom-field>

									<div class="mt-4 mb-1">
										<color-selector :value="local.color" label="Highlight color" @input="local.color = $event"></color-selector>
									</div>

									<div class="pa-2 text-left">
										<v-switch
											class=""
											style="margin-top: 0px"
											v-model="local.archive"
											inset
											flat
											dense
											hide-details
											:label="`${local.archive ? 'Archived' : 'Archive'} client`"
										></v-switch>
										<div class="font-12 font-gray_80 mt-2">
											Archived clients will still be accessible from the client list screen, <br />but will
											not show up in other areas of Moxie.
										</div>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-tab-item>

					<v-tab-item v-if="$store.getters.hasInvoiceAccess">
						<v-container fluid class="pa-0 mt-3 mx-0">
							<v-row dense>
								<v-col cols="12" class="column-format gap-3 pt-2">
									<div class="row-format half gap-2">
										<amount-input
											v-model="local.hourlyAmount"
											:label="$t('client.hourly-amount.label')"
											:prefix="currencySymbol"
											type="text"
											hide-details
											dense
											outlined
											persistent-placeholder
										></amount-input>
										<v-select
											hide-details
											persistent-placeholder
											dense
											outlined
											:label="$t('client.billing-increment')"
											v-model="local.roundingIncrement"
											:items="rounding"
											style="width: 140px"
											item-text="label"
											item-value="value"
										></v-select>
									</div>
									<div class="row-format half gap-2">
										<v-select
											:items="dayOptions"
											v-model="local.paymentTerms.paymentDays"
											hide-details
											dense
											outlined
											persistent-placeholder
											:label="$t('proposal.terms.payment-terms')"
										>
											<template v-slot:selection="{ item }">
												<span v-if="item === 0">On Receipt</span>
												<span v-if="item !== 0">{{ item }} days</span>
											</template>
											<template v-slot:item="{ item }">
												<span v-if="item === 0">On Receipt</span>
												<span v-if="item !== 0">{{ item }} days</span>
											</template>
										</v-select>
										<v-text-field
											v-model="local.paymentTerms.latePaymentFee"
											placeholder="No fee"
											suffix="% / mo."
											hide-details
											dense
											outlined
											persistent-placeholder
											:label="$t('proposal.terms.late-payment')"
											type="number"
										></v-text-field>
									</div>
									<div class="row-format half pr-2">
										<v-text-field
											v-model="local.defaultTaxRate"
											:placeholder="$store.state.podAccount.accountPreferences.defaultTaxRate"
											suffix="%"
											hide-details
											dense
											outlined
											persistent-placeholder
											label="Tax rate"
											type="number"
										></v-text-field>
									</div>
									<div class="h-outline" style="position: relative">
										<div class="mx-2 mb-2">
											<editor
												class="mt-1 px-2 text-left"
												style="width: 100%; min-height: 100px; border-bottom: none"
												ref="editor"
												:api-key="$store.getters.getTinyMceKey"
												:initial-value="local.payInstructions"
												:inline="true"
												:init="mceConfigText"
												:spellcheck="true"
												@input="handlePayInstructionsInput"
											></editor>
										</div>
										<div class="phone-label" style="margin-top: -2px">Payment instructions</div>
									</div>

									<div  v-if="$store.state.podAccount.stripeIntegration" class="column-format gap-3">
										<div class="row-format">
											<v-text-field
												v-model="local.stripeClientId"
												hide-details
												persistent-placeholder
												:disabled="stripeLocked"
												dense
												outlined
												label="Stripe client Id"
											></v-text-field>
											<v-icon class="pointer" @click="stripeLocked = !stripeLocked">lock</v-icon>
										</div>
										<v-select
											persistent-placeholder
											hide-details
											dense
											outlined
											:items="paidByOptions"
											v-model="local.paymentTerms.whoPaysCardFees"
											label="Pass Stripe credit card fees to client?"
										></v-select>
										<div class="font-italic font-12 text-left">
											This feature applies to Stripe credit card fees only.
										</div>
									</div>
								</v-col>
							</v-row>
						</v-container>
					</v-tab-item>
				</v-tabs-items>
			</v-form>
		</div>
	</div>
</template>

<script>
	import ClientService from '../ClientService';
	import ClientAvatar from '@/components/ClientAvatar';
	import ClientPalette from '../ClientPalette.js';
	import { mapGetters } from 'vuex';
	import ConfirmModal from '@/components/ConfirmModal';
	import Countries from '@/modules/utils/Countries.js';
	import currencies from '@/assets/data/currencies.json';
	import DateTime from '@/modules/utils/HDateTime';
	import Cropper from 'cropperjs';
	import VueDropzone from 'vue2-dropzone';
	import ClearSampleModal from '@/modules/clients/ClearSampleModal';
	import AmountInput from '@/components/AmountInput.vue';
	import CustomField from '@/components/CustomField';
	import ColorSelector from "@/components/ColorSelector";

	export default {
		name: 'ClientEdit',
		isRightModal: true,
		components: {
			AmountInput,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
			ClientAvatar,
			VueDropzone,
			CustomField,
			ColorSelector
		},

		data() {
			return {
				isReady: false,
				deleteDialog: false,
				clientService: new ClientService(),
				clientPalette: new ClientPalette(),
				countries: new Countries(),
				palette: null,
				hideModeSwitch: true,
				editColor: false,
				local: {},
				currencies: currencies,
				phone: null,
				stripeLocked: true,

				tab: null,
				tabItems: ['Contact info', 'Billing settings'],

				rounding: [
					{ label: 'No rounding', value: 1 },
					{ label: '2 minutes', value: 2},
					{ label: '3 minutes', value: 3 },
					{ label: '4 minutes', value: 4 },
					{ label: '5 minutes', value: 5 },
					{ label: '6 minutes', value: 6 },
					{ label: '7 minutes', value: 7 },
					{ label: '8 minutes', value: 8 },
					{ label: '9 minutes', value: 9 },
					{ label: '10 minutes', value: 10 },
					{ label: '11 minutes', value: 11 },
					{ label: '12 minutes', value: 12 },
					{ label: '13 minutes', value: 13 },
					{ label: '14 minutes', value: 14 },
					{ label: '15 minutes', value: 15 },
					{ label: '20 minutes', value: 20 },
					{ label: '30 minutes', value: 30 },
					{ label: '45 minutes', value: 45 },
					{ label: '1 hour', value: 60 },
				],

				type: [
					{ label: 'Prospect', value: 'Prospect' },
					{ label: 'Client', value: 'Client' },
				],

				rules: {
					required: (v) => !!v || 'Required',
				},

				dayOptions: [
					0,
					1,
					2,
					3,
					4,
					5,
					6,
					7,
					8,
					9,
					10,
					11,
					12,
					13,
					14,
					15,
					16,
					17,
					18,
					19,
					20,
					21,
					30,
					45,
					60,
					90,
					120,
					180,
				],
				scheduleOptions: ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Annually', 'As Needed'],

				paidByOptions: [
					{ text: 'No', value: 'Freelancer' },
					{ text: 'Yes', value: 'Client' },
					{ text: 'Split it', value: 'Split' },
				],

				creationOptions: [
					{
						value: 'Manual',
						text: 'Manual',
					},
					{
						value: 'Automatic',
						text: 'Automatic (with Approval)',
					},
				],
				depositOptions: ['No deposit', 'Fixed amount', 'Percentage'],

				paymentTermsRules: {
					min(min, v) {
						return v >= min || `Must be at least ${min}`;
					},
					max(max, v) {
						return v <= max || `Cannot be greater than ${max}.`;
					},
				},

				mceConfigText: {
					menubar: false,
					inline: true,
					browser_spellcheck: true,
					forced_root_block: '',
					plugins: ['paste'],
					paste_as_text: true,
					toolbar: [],
				},
			};
		},

		props: {
			client: { type: Object },
		},

		mounted() {
			this.local = Object.assign({}, this.client);
			if (!this.local.color) {
				this.local.color = '#FFFFFFFF';
			}
			if (!this.local.currency) {
				this.local.currency = this.$store.state.defaultCurrency;
			}

			if (!this.local.paymentTerms) {
				this.local.paymentTerms = this.getDefaultTerms();
			}

			this.phone = this.local.phone;
			this.palette = this.clientPalette.palette;
			this.isReady = true;
		},

		watch: {},

		methods: {
			getCustomValue: function(id) {
				let value = this.local.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			handleCustomFieldChange: function(value) {
				let ix = this.local.customValues.findIndex((c) => c.fieldId === value.fieldId);
				if (ix > -1) {
					if (value.value != null) {
						this.local.customValues.splice(ix, 1, value);
					} else {
						this.local.customValues.splice(ix, 1);
					}
				} else if (value.value != null) {
					this.local.customValues.push(value);
				}
			},

			openLink: function(link) {
				if (link.toLowerCase().startsWith('http://') || link.toLowerCase().startsWith('https://')) {
					window.open(link, '_blank');
				} else {
					window.open('https://' + link, '_blank');
				}
			},

			transformFile: function(file, done) {
				let myDropZone = this.$refs.logoFileDropzone.dropzone;
				let editor = document.createElement('div');
				editor.style.position = 'fixed';
				editor.style.left = '0';
				editor.style.right = '0';
				editor.style.top = '0';
				editor.style.bottom = '0';
				editor.style.zIndex = '9999';
				editor.style.backgroundColor = '#000';
				document.body.appendChild(editor);

				// Create confirm button at the top left of the viewport
				let buttonConfirm = document.createElement('div');
				buttonConfirm.style.position = 'absolute';
				buttonConfirm.setAttribute('class', 'confirmButton');
				buttonConfirm.textContent = 'Confirm';
				editor.appendChild(buttonConfirm);

				buttonConfirm.addEventListener('click', function() {
					// Get the canvas with image data from Cropper.js
					let canvas = cropper.getCroppedCanvas({
						width: 64,
						height: 64,
					});

					// Turn the canvas into a Blob (file object without a name)
					canvas.toBlob(function(blob) {
						// Create a new Dropzone file thumbnail
						myDropZone.createThumbnail(
							blob,
							myDropZone.options.thumbnailWidth,
							myDropZone.options.thumbnailHeight,
							myDropZone.options.thumbnailMethod,
							false,
							function(dataURL) {
								// Update the Dropzone file thumbnail
								myDropZone.emit('thumbnail', file, dataURL);
								// Return the file to Dropzone
								done(blob);
							}
						);

						done(blob);
					});

					// Remove the editor from the view
					document.body.removeChild(editor);
				});

				// Create an image node for Cropper.js
				let image = new Image();
				image.src = URL.createObjectURL(file);
				editor.appendChild(image);

				// Create Cropper.js
				let cropper = new Cropper(image, { aspectRatio: 1 });
			},

			addFileUploadAuthHeader: function(file, xhr) {
				this.$store.commit('startLoading');
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			logoUploadSuccess: function(file, response) {
				this.$store.commit('stopLoading');
				this.local.s3LogoFile = response;
				this.local.logo = response;
				this.$refs.logoFileDropzone.removeFile(file);
			},

			logoUploadFailure: function(file, message) {
				this.$store.commit('stopLoading');
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.logoFileDropzone.removeFile(file);
			},

			confirmDeleteLogo: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to remove the client logo?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.clientService.deleteLogo(this.client.id).then(() => {
							this.local.s3LogoFile = null;
							this.local.logo = null;
						});
					}
				});
			},

			phoneUpdated(number, object) {
				this.local.phone = object.number;
			},

			getDefaultTerms: function() {
				return {
					paymentDays: 7,
					invoicingSchedule: 'Monthly',
					latePaymentFee: 10,
					invoiceCreation: 'Manual',
					depositAmount: 0,
					depositType: 'No deposit',
					whoPaysCardFees: 'Freelancer',
					fromProposalId: null,
					fromProposalSignedDate: null,
					updatedBy: null,
					updatedDate: null,
					firstInvoiceDate: DateTime.now()
						.startOf('month')
						.plus({ months: 1 })
						.toISODate(),
					lastInvoiceDate: null,
				};
			},

			handleSampleDataNameClick() {
				if (!this.isSampleMode) {
					return;
				}
				let binding = {
					severity: 'info',
					headingText: this.$t('client.sample-mode-alert-heading'),
					bodyText: this.$t('client.sample-mode-alert-body'),
					hideNo: true,
					yesText: this.$t('global.close'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding);
			},

			checkInvoiceSchedule: function() {
				if (this.local.paymentTerms.invoicingSchedule === 'As Needed') {
					this.local.paymentTerms.invoiceCreation = 'Manual';
				}
			},

			formatPhone(phone) {
				this.local.phone = this.$formatters.phoneNumber(phone);
			},

			handlePayInstructionsInput: function() {
				this.local.payInstructions = this.$refs.editor.editor.getContent();
			},

			handleClose: function() {
				this.updateClient();
			},

			updateClient() {
				if (!this.$refs.clientEdit.validate()) {
					return false;
				}

				if (this.client.sampleData) {
					this.local.name = this.client.name;
				}

				this.local._update = true;
				this.$emit('result', this.local);
			},

			doAlert(type, message) {
				this.$emit('alert', { type: type, message: message });
			},

			deleteClient() {
				if (this.sampleMode) {
					this.$store.state.globalModalController.openModal(ClearSampleModal);
					return;
				}

				let binding = {
					headingText: this.$t('client.delete-client'),
					bodyText: this.$t('client.delete-are-you-sure', { name: this.client.name }),
					confirmText: this.client.name,
					confirmHint: 'Please type the exact client name to confirm',
					yesText: this.$t('global.delete'),
					noText: this.$t('global.cancel'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.local._delete = true;
						this.$emit('result', this.local);
					}
				});
			},
		},

		computed: {
			...mapGetters({
				isSampleMode: 'isSampleMode',
			}),

			fields: function() {
				if (this.$store.state.clientFields) {
					return this.$store.state.clientFields.fields;
				} else {
					return [];
				}
			},

			initials() {
				if (!this.client || !this.client.name) return '--';
				const ignoreMe = ['a', 'an', 'as', 'to', 'and', 'of', 'if', 'in'];
				let clientName = this.client.name
					.replace('-', ' ')
					.replace(/[^a-zA-Z0-9 ]/g, '')
					.replace(/ +/g, ' ')
					.trim();
				let words = clientName.toLowerCase().split(' ');
				let ret = [];
				for (var i = 0; i < words.length; i++) {
					if (ignoreMe.indexOf(words[i]) > -1) continue;
					ret[i] = words[i].substring(0, 1);
					if (ret.length == 3) break;
				}
				return ret.join('').toUpperCase();
			},

			preferredCountries() {
				if (this.local.country) {
					return [this.local.country, 'US', 'CA', 'GB', 'AU', 'NZ'];
				} else {
					return ['US', 'CA', 'GB', 'AU', 'NZ'];
				}
			},

			logoDropzoneOptions: function() {
				return {
					url: `${this.$store.getters.getPodUrl}api/v1/${this.client.accountId}/clients/${this.client.id}/logo`,
					transformFile: this.transformFile,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},

			color: {
				get() {
					return this[this.type];
				},
				set(v) {
					this[this.type] = v;
				},
			},

			currencySymbol: function() {
				return this.$formatters.currencySymbol(this.local.currency);
			},
		},
	};
</script>

<style scoped lang="scss">
	#logoDropzone {
		min-height: 20px !important;
		margin: 0px;
		padding: 4px;
	}

	.avatar {
		position: relative;

		.avatar-delete {
			position: absolute;
			top: 15px;
			left: 15px;
			visibility: hidden;
		}

		&:hover {
			.avatar-delete {
				background-color: var(--v-white-base);
				visibility: visible;
				border-radius: 4px;
			}
		}
	}

	#client-edit {
		.row-format {
			&.half {
				> div {
					flex: 0 1 50%;
				}
			}
		}
	}
</style>
