<template>
	<div>
		<input type="hidden" v-model="mailbox_id" ref="mailbox_id" df-mailbox_id />
		<input type="hidden" v-model="user_id" ref="user_id" df-user_id/>
		<input type="hidden" v-model="template_id" ref="template_id" df-template_id />
		<input type="hidden" v-model="template_name" ref="template_name" df-template_name />
		<input type="hidden" v-model="to_list" ref="to_list" df-to_list />
		<input type="hidden" v-model="cc_list" ref="cc_list" df-cc_list />
		<input type="hidden" v-model="add_tracking_pixel" ref="add_tracking_pixel" df-add_tracking_pixel/>

		<node-label @open="openModal()">
			<template v-slot:default>
				<v-icon class="material-symbols-rounded mr-1" color="black" size="20">email</v-icon>
				<div v-if="template_name">{{ template_name }}</div>
				<div v-else>Send email</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" title="Send email">
			<template v-slot:default>
				<div class="column-format gap-2" style="min-width: 550px" v-if="isOpen">
					<v-autocomplete
						hide-details
						dense
						outlined
						persistent-placeholder
						label="From"
						:items="emailBoxes"
						item-value="id"
						item-text="username"
						v-model="mailbox_id"
						@change="setUserIdFromMailbox()"
					></v-autocomplete>
					<email-selector
						:to_list="toList"
						:cc_list="ccList"
						:template_id="template_id"
						@cc-list="updateCcList($event)"
						@to-list="updateToList($event)"
						@selected-template="updateSelectedTemplate($event)"
					></email-selector>
					<v-checkbox v-model="add_tracking_pixel" label="Add open/viewed tracking pixel" hide-details dense persistent-hint class="mb-2" true-value="yes" false-value="no"></v-checkbox>
				</div>
			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeMixin from '@/modules/workflow/nodes/NodeMixin';
	import NodeModal from '@/modules/workflow/nodes/NodeModal';
	import NodeLabel from '@/modules/workflow/nodes/NodeLabel';
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import EmailSelector from '@/modules/workflow/nodes/components/EmailSelector';

	export default {
		name: 'Email',
		inputs: 1,
		outputs: 1,

		mixins: [NodeMixin],

		props: [],

		components: { EmailSelector, NodeLabel, NodeModal },

		data: function() {
			return {
				//persisted values
				mailbox_id: null,
				user_id: null,
				template_id: null,
				template_name: null,
				to_list: null,
				cc_list: null,
				add_tracking_pixel: null,

				//internal values
				toList: [],
				ccList: [],
				templates: [],
				emailBoxes: [],
				isOpen: false,

				communicatorEmailService: new CommunicatorEmailService(),
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.mailbox_id = this.$refs.mailbox_id.value;
				this.user_id = this.$refs.user_id.value;
				this.template_id = this.$refs.template_id.value;
				this.template_name = this.$refs.template_name.value;
				this.add_tracking_pixel = this.$refs.add_tracking_pixel.value;

				if (this.$refs.to_list.value) {
					let to = JSON.parse(this.$refs.to_list.value);
					this.toList.push(...to);
				}

				if (this.$refs.cc_list.value) {
					let cc = JSON.parse(this.$refs.cc_list.value);
					this.ccList.push(...cc);
				}

				this.isReady = true;
			});
		},

		beforeDestroy() {},

		methods: {
			updateToList: function(list) {
				this.toList.splice(0, this.toList.length);
				this.toList.push(...list);
			},

			updateCcList: function(list) {
				this.ccList.splice(0, this.ccList.length);
				this.ccList.push(...list);
			},

			setUserIdFromMailbox: function(){
				if(this.mailbox_id) {
					let emailBox = this.emailBoxes.find(e => e.id === this.mailbox_id);
					this.user_id = emailBox.userId;
				}else{
					this.user_id = null;
				}
			},

			updateSelectedTemplate: function(template) {
				if (template) {
					this.template_id = template.id;
					this.template_name = template.name;
				} else {
					this.template_id = null;
					this.template_name = null;
				}
			},

			openModal: function() {
				let p1 = this.getEmailBoxes();
				Promise.all([p1]).then(() => {
					this.isOpen = true;
					this.$refs.nodeModal.open()
				});
			},

			getEmailBoxes: function() {
				return new Promise((resolve, reject) => {
					this.communicatorEmailService
						.getEmailBoxesForAccount()
						.then((res) => {
							this.emailBoxes.splice(0, this.emailBoxes.length);
							this.emailBoxes.push(...res.data);
							resolve();
						})
						.catch((err) => reject(err));
				});
			},
		},

		watch: {
			mailbox_id: function() {
				this.emitInput('mailbox_id');
			},

			user_id: function(){
				this.emitInput('user_id');
			},

			template_id: function() {
				this.emitInput('template_id');
			},

			template_name: function() {
				this.emitInput('template_name');
			},

			add_tracking_pixel: function(){
				this.emitInput('add_tracking_pixel');
			},

			toList: function() {
				this.to_list = JSON.stringify(this.toList);
				this.$nextTick(() => this.emitInput('to_list'));
			},

			ccList: function() {
				this.cc_list = JSON.stringify(this.ccList);
				this.$nextTick(() => this.emitInput('cc_list'));
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
