<template>
	<div id="void-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">{{ $t('invoice.void-modal.heading', { invoiceNumber: invoice.invoiceNumber }) }}</div>
			<div class="modal-subtitle">{{ $t('invoice.void-modal.subheading', { invoiceNumber: invoice.invoiceNumber }) }}</div>

			<div v-if="invoice.clientInfo" class="client-name">
				<client-avatar :client="invoice.clientInfo" left></client-avatar>
				<div>{{ invoice.clientInfo.name }}</div>
			</div>

			<div class="font-14 brand-semilight text-left mb-4">
				{{ $t('invoice.void-modal.body') }}
			</div>

			<v-textarea
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="userNote"
				:rows="3"
				:label="$t('invoice.void-modal.note-label')"
				:placeholder="$t('invoice.void-modal.note-placeholder')"
			></v-textarea>
		</div>
		<div class="modal-footer">
			<v-btn class="px-16 primary-action" @click="handleSave">{{ $t('invoice.void-modal.save-button') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	export default {
		name: 'VoidModal',

		props: { invoice: { type: Object, required: true } },

		components: { ClientAvatar },

		data: function() {
			return {
				userNote: '',
			};
		},

		mounted() {
			console.log(this.invoice);
		},

		beforeDestroy() {},

		methods: {
			handleSave() {
				this.$emit('save', this.userNote);
			},
		},
	};
</script>

<style lang="scss">
	#void-modal {
		background-color: var(--v-white-base);
		max-width: 400px;
		min-width: 320px;
		.total {
			margin: 0 0 12px;
			font-size: 56px;
			color: var(--v-gray_70-base);
		}
		.client-name {
			display: flex;
			justify-content: center;
			margin-bottom: 16px;
			margin-top: 16px;

			div {
				align-self: center;
				white-space: nowrap;
				flex: 0 0 auto;
			}
		}
		//.divider {
		//	background-color: var(--v-gray_50-base);
		//	color: var(--v-gray_50-base);
		//	height: 1px;
		//}
	}
</style>
