import DateTime from "@/modules/utils/HDateTime";
import CommunicatorTicketService from "@/modules/communicator/CommunicatorTicketService";
import TicketMixin from "@/modules/communicator/inbox/tickets/TicketMixin";
import TicketDetail from "@/modules/communicator/inbox/tickets/TicketDetail"

export default {
    data: function() {
        return {
            originalTickets: [],
            ticketService: new CommunicatorTicketService(),
        }
    },

    mixins: [TicketMixin],

    methods: {
        handleTicketStartup: function(){
            if(this.$store.getters.hasFeatureAccess('tickets')) {
                this.getTicketsForTwoWeeks();
            }
        },

        getTicketsForTwoWeeks: function () {
            this.ticketService
                .getTickets()
                .then((res) => {
                    this.originalTickets.splice(0, this.originalTickets.length);
                    this.originalTickets.push(...res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        ticketAssignedFilter: function(t){
            if(this.assignedTo === 'Mine'){
                return t.assignedTo.includes(this.$store.state.loggedInUser.userId);
            }else{
                return true;
            }
        },

        fixupTicket: function (t) {
            if (t.dueDate) {
                t.luxonDate = DateTime.fromISO(t.dueDate);
            }
            t.client = this.client(t)
        },

        client: function(item) {
            return this.$store.getters.getClientById(item.clientId);
        },

        handleTicketClick: function (ticket) {
            let binding = {
                id:ticket.id
            };
            this.$store.state.globalModalController.openModal(TicketDetail, binding).then((res) => {
                if(res) {
                    let ix = this.originalTickets.findIndex(t => t.id === res.id);
                    if(res.deleted) {
                        this.originalTickets.splice(ix, 1);
                    }
                    else {
                        this.originalTickets.splice(ix, 1, res);
                    }
                }
            });
        },
    },

    computed: {
        tickets: function(){
            let result = [... this.originalTickets];
            result.forEach(t => {
                this.fixupTicket(t);
            })
            return result.filter(this.ticketAssignedFilter);
        }
    }
}