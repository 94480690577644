<template>
	<div style="background-color: var(--v-white-base);">
		<v-data-table
				:headers="headers"
				item-key="id"
				:hide-default-footer="true"
				:disable-pagination="true"
				:items="paymentPlans"
				@click:row="openPaymentPlan($event.id)"
				class="pointer"
		>
			<template v-slot:item.amount="{ item }">
				<div>
					{{ $formatters.dollars(item.invoice.total, true, true, item.invoice.currency) }}
				</div>
			</template>
			<template v-slot:item.invoiced="{item}">
				{{$formatters.dollars(item.scheduledPayments.filter(p => p.mileStoneAchieved).map(p => p.calculatedAmount).reduce((sum, val) => sum + val, 0), true, true, item.invoice.currency)}}
			</template>
			<template v-slot:item.status="{item}">
				{{item.status}}
			</template>
			<template v-slot:item.name="{item}">
				{{item.name ? item.name : '--'}}
			</template>
			<template v-slot:item.lastEvent="{item}">
				{{formatLastEvent(item)}}
			</template>
			<template v-slot:item.nextStep="{item}">
				<div>{{getNextScheduledPayment(item.scheduledPayments)}}</div>
			</template>
			<template v-slot:item.client="{ item }">
				<div class="row-format align-center" v-if="item.invoice.clientInfo">
					<client-avatar :client="item.invoice.clientInfo" small></client-avatar>
					<div class="ml-n3">{{ item.invoice.clientInfo.name }}</div>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import PaymentPlanDetails from "@/modules/invoices/paymentPlan/PaymentPlanDetails";
import ClientAvatar from "@/components/ClientAvatar";
import PaymentPlanService from "@/modules/invoices/paymentPlan/PaymentPlanService";
import {DateTime} from 'luxon';

export default {
	name: "PaymentPlanList",

	props: ['client'],

	components: {ClientAvatar},

	data: function () {
		return {
			DateTime: DateTime,
			paymentPlanService: new PaymentPlanService(),
			paymentPlans: [],
		}
	},

	mounted() {
		this.getPaymentPlans();
	},

	beforeDestroy() {
	},

	methods: {
		getPaymentPlans: function(){
			this.paymentPlanService.getPaymentPlans(this.client ? this.client.id : null).then((res) => {
				this.paymentPlans.push(... res.data);
			})
		},

		getNextScheduledPayment: function(scheduledPayments){
			let upcomingPayments = scheduledPayments.filter(s => s.invoiceId === null);
			if(upcomingPayments.length > 0){
				let nextUp = upcomingPayments[0];
				if(nextUp.triggerType === 'Date'){
					return nextUp.label + ' - ' + DateTime.fromISO(nextUp.triggerDate).toLocaleString(DateTime.DATE_MED);
				}else{
					return 'Milestone -  ' + nextUp.label;
				}
			}else{
				return '--'
			}
		},

		createPaymentPlan: function(clientId){
			this.openPaymentPlan(null,clientId)
		},

		openPaymentPlan: function (id, clientId = null) {
			let binding = {
				clientId: clientId,
				id: id,
			};
			this.$store.state.globalModalController.openModal(PaymentPlanDetails, binding, false, true).then((res) => {
				if (res) {
					let ix = this.paymentPlans.findIndex(p => p.id === res.id);
					console.log(ix);
					if(ix > -1 && res.deleted){
						this.paymentPlans.splice(ix,1);
					}else if(ix > -1){
						this.paymentPlans.splice(ix,1,res);
					}else{
						this.paymentPlans.push(res);
					}
				}
			});
		},

		formatLastEvent: function(paymentPlan){
			let event = paymentPlan.events[paymentPlan.events.length - 1];
			return event.events[0] + ' - ' + DateTime.fromISO(event.timestamp).toLocaleString(DateTime.DATE_MED);
		}
	},

	computed: {
		headers: function () {
			let headers = [
				{ text: 'Total', value: 'amount', class: 'nowrap' },
				{ text: 'Invoiced', value: 'invoiced', class: 'nowrap' },
				{ text: 'Desc', value: 'name', class: 'nowrap' },
				{ text: 'Next step', value: 'nextStep', class: 'nowrap' },
				{ text: 'Last activity', value: 'lastEvent', class: 'nowrap' },
				{ text: 'Status', value: 'status', class: 'nowrap' },
			];

			if (!this.client) {
				headers.splice(0, 0, { text: this.$t('global.client'), value: 'client' });
			}

			return headers;
		},
	},

}
</script>

<style scoped lang="scss">
</style>