var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "key-pad centered px-3 pt-1 pb-2" }, [
      !_vm.callIsActive
        ? _c(
            "div",
            [
              !_vm.keyPadEntry
                ? _c("contact-selector", {
                    key: "phone-contact",
                    ref: "ContactSelector",
                    attrs: { subscription: _vm.subscription, multiple: false },
                    on: {
                      "to-list": function($event) {
                        return _vm.setTo($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm.keyPadEntry
                ? _c("v-text-field", {
                    staticClass: "mt-1",
                    attrs: {
                      label: "To:",
                      "hide-details": "",
                      dense: "",
                      outlined: ""
                    },
                    on: { input: _vm.scrubInput },
                    model: {
                      value: _vm.keyPadEntry,
                      callback: function($$v) {
                        _vm.keyPadEntry = $$v
                      },
                      expression: "keyPadEntry"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.activeCall
        ? _c("div", [
            _c("div", { staticClass: "font-20 font-primary" }, [
              _vm._v(_vm._s(this.$store.state.communicator.callTime))
            ]),
            _vm.activeCallName
              ? _c(
                  "div",
                  {
                    directives: [{ name: "tippy", rawName: "v-tippy" }],
                    staticClass: "font-14",
                    attrs: { content: _vm.activeCallPhone }
                  },
                  [_vm._v(_vm._s(_vm.activeCallName))]
                )
              : _c("div", { staticClass: "font-14" }, [
                  _vm._v(_vm._s(_vm.activeCallPhone))
                ])
          ])
        : _vm._e(),
      _c("div", [
        _c("div", { staticClass: "button-row mt-2" }, [
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("1")
                }
              }
            },
            [_c("div", [_vm._v("1")])]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("2")
                }
              }
            },
            [_c("div", [_vm._v("2")])]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("3")
                }
              }
            },
            [_c("div", [_vm._v("3")])]
          )
        ]),
        _c("div", { staticClass: "button-row" }, [
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("4")
                }
              }
            },
            [_c("div", [_vm._v("4")])]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("5")
                }
              }
            },
            [_c("div", [_vm._v("5")])]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("6")
                }
              }
            },
            [_c("div", [_vm._v("6")])]
          )
        ]),
        _c("div", { staticClass: "button-row" }, [
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("7")
                }
              }
            },
            [_c("div", [_vm._v("7")])]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("8")
                }
              }
            },
            [_c("div", [_vm._v("8")])]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("9")
                }
              }
            },
            [_c("div", [_vm._v("9")])]
          )
        ]),
        _c("div", { staticClass: "button-row mb-2" }, [
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("star")
                }
              }
            },
            [_c("div", [_vm._v("*")])]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("0")
                }
              }
            },
            [_c("div", [_vm._v("0")])]
          ),
          _c(
            "div",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.digit("pound")
                }
              }
            },
            [_c("div", [_vm._v("#")])]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "pt-1" },
        [
          !_vm.callIsActive
            ? _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    large: "",
                    elevation: "0",
                    color: "primary",
                    disabled: !_vm.phoneIsValid,
                    id: "custom-disabled"
                  },
                  on: {
                    click: function($event) {
                      return _vm.dial()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("$phone")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.activeCall
            ? _c(
                "div",
                { staticClass: "row-format mb-2", staticStyle: { gap: "4px" } },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "50%" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            block: "",
                            elevation: "0",
                            color: _vm.callRecording ? "primary" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("record-call")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "column-format" },
                            [
                              _c("v-icon", [_vm._v("$voicemail")]),
                              _c("div", { staticClass: "font-12" }, [
                                _vm._v("Record")
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { width: "50%" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            block: "",
                            elevation: "0",
                            color: _vm.callMuted ? "primary" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("mute-call")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "column-format" },
                            [
                              _c("v-icon", [_vm._v("volume_off")]),
                              _c("div", { staticClass: "font-12" }, [
                                _vm._v("Mute")
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm.callIsActive
            ? _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    large: "",
                    elevation: "0",
                    color: "error"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("hangup")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("$phone")
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "font-14",
                      staticStyle: { color: "var(--v-white-base)" }
                    },
                    [_vm._v("End call")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }