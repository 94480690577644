<template>
	<div id="template-library">
		<v-btn icon class="close-dialog" @click="$emit('result')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding text-left column-format gap-2">
			<div class="modal-title">{{ type }} template library</div>
			<div class="row-format gap-1" style="flex-wrap: wrap">
				<v-chip
					class="pointer"
					:color="selectedCategory === category ? 'secondary' : 'primary'"
					v-for="category in categories"
					:key="category"
					@click="selectedCategory = category"
					>{{ category }}</v-chip
				>
			</div>
			<v-data-table :items="filteredTemplates" :items-per-page="5" fixed-header :headers="headers">
				<template v-slot:header.action>
					<div class="row-format">
						<v-text-field
							class="ml-auto"
							dense
							hide-details
							placeholder="Filter..."
							v-model="filter"
							append-icon="filter_alt"
							style="font-weight: normal; max-width: 180px"
						></v-text-field>
					</div>
				</template>
				<template v-slot:item.action="{ item }">
					<v-btn @click="useTemplate(item)" outlined color="primary" small>Import</v-btn>
				</template>
				<template v-slot:no-data>Please select a category</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import TemplateLibraryService from '@/modules/templates/library/TemplateLibraryService';

	export default {
		name: 'TemplateLibrary',

		props: ['type'],

		components: {},

		data: function() {
			return {
				filter: null,
				templates: [],
				selectedCategory: null,
				templateLibraryService: new TemplateLibraryService(),
				headers: [
					{ text: 'Name', value: 'name' },
					{ text: '', value: 'action', align: 'end', sortable: false },
				],
			};
		},

		mounted() {
			this.templateLibraryService.getTemplatesOfType(this.type).then((res) => {
				this.templates.splice(0);
				this.templates.push(...res.data);
			});
		},

		beforeDestroy() {},

		methods: {
			useTemplate: function(template) {
				this.templateLibraryService.createTemplateOfType(this.type, template.id).then((res) => {
					this.$emit('result', res.data);
				});
			},
		},

		computed: {
			filteredTemplates: function() {
				return this.templates
					.filter((t) => t.folder === this.selectedCategory)
					.filter((t) => {
						if (this.filter) {
							return t.name && t.name.toLowerCase().includes(this.filter);
						} else {
							return true;
						}
					});
			},

			categories: function() {
				let folders = Array.from(new Set(this.templates.map((t) => (t.folder ? t.folder : ''))));
				folders.sort((a, b) => a.localeCompare(b));
				return folders;
			},
		},
	};
</script>

<style scoped lang="scss">
	#template-library {
		width: 780px;
		background-color: var(--v-white-base);
	}
</style>
