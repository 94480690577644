var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { key: _vm.forceUpdate, staticClass: "fill-height" },
        [
          _c(
            "page-header",
            {
              staticClass: "pb-6",
              attrs: {
                "bread-crumbs": [
                  { to: "/home", label: "Home" },
                  { label: "Communicator" }
                ]
              }
            },
            [_vm._v(" Inbox ")]
          ),
          _vm.paidAccount
            ? _c(
                "div",
                [
                  _c("inbox", {
                    ref: "Inbox",
                    attrs: {
                      "email-boxes": _vm.emailBoxes,
                      subscription: _vm.subscription
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.paidAccount
            ? _c(
                "div",
                { staticClass: "column-format centered ma-8 px-3" },
                [
                  _c("free-tier-card", {
                    staticStyle: { width: "100%", height: "150px" },
                    attrs: {
                      message:
                        "One universal inbox for everything important - Voice, Messaging, Email. Communicator is only available on the Pro or Teams plan.  Upgrade now to claim your business phone number and connect your inbox."
                    },
                    on: {
                      "upgrade-clicked": function($event) {
                        return _vm.$router.push("/subscription")
                      }
                    }
                  }),
                  _vm._m(0)
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-8" }, [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://help.withmoxie.com/en/articles/6369542-communicator-overview",
            target: "_blank"
          }
        },
        [_vm._v("Click here to learn more about Communicator!")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }