<template>
	<div>
		Loading...
	</div>
</template>

<script>
import Template from "@/modules/templates/Template";

export default {
	name: "TemplateRouter",

	props: [],

	components: {},

	data: function () {
		return {
		}
	},

	mounted() {
		let lastViewed = Template.getLastViewed();
		this.$router.push(`/templates/${lastViewed.toLowerCase()}`);
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>