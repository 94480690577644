<template>
	<div id="help-center" :class="$vuetify.theme.dark ? 'dark' : 'light'">
		<div class="row-format align-center ma-4 pointer text-left" @click="allHelp">
			<div v-if="focusHelp" class="row-format align-center pointer font-gray_80">
				<v-icon size="20" class="mr-1">$arrowLeft</v-icon>
			</div>
			<div :class="`font-14 brand-medium ${!focusHelp ? 'font-gray_90' : 'font-gray_80'}`" style="white-space: nowrap">Help center </div>
			<div class="font-14 brand-medium font-gray_90 row-format align-center" v-if="focusHelp">
				<div class="mx-2">/</div>
				<h-icon2 size="20" :name="focusHelp.icon"></h-icon2>
				<div class="ml-1">{{focusHelp.name}}</div>
			</div>
			<div class="ml-auto">
				<v-icon class="pointer" @click="$emit('close')">$close</v-icon>
			</div>
		</div>

		<div v-if="!focusHelp" class="column-format show-scrollbar mx-4 pb-4" style="max-height: calc(var(--vh) - 150px); overflow-y: auto">
			<section-link
					@selected="sectionSelected(section)"
					v-for="section in sortedHelp"
					:key="section.name"
					:name="section.name"
					:icon="section.icon"
					:color="section.color"
					:description="section.description"
			></section-link>
		</div>

		<div v-if="focusHelp" class="row-format ma-3 show-scrollbar" style="max-height: calc(var(--vh) - 150px); overflow-y: auto">
			<section-detail @all-help="allHelp()" :section="focusHelp"></section-detail>
		</div>

		<div class="mt-auto mb-4 mx-3 pt-4">
			<div class="text-left brand-medium mb-1">
				Still need help?
			</div>

			<div class="row-format centered">
				<div class="button column-format centered mr-1"  @click="openHelpCenter">
					<h-icon
							name="knowledge-base"
							size="30"
							:two-color="false"
							color="var(--v-primary-base)"
							style="--secondary-opacity: 1; --secondary-color: var(--v-gray_50-base)"
					></h-icon>
					<div class="font-14 brand-medium">Knowledge base</div>
				</div>

				<div class="button column-format centered ml-1"  @click="stillNeedHelp">
					<h-icon
							name="chat-with-us"
							size="30"
							:two-color="false"
							color="var(--v-primary-base)"
							style="--secondary-opacity: 1; --secondary-color: var(--v-gray_50-base)"
					></h-icon>
					<div class="font-14 brand-medium">Chat with us</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SectionLink from '@/modules/help/SectionLink';
	import help from './help.json';
	import SectionDetail from "@/modules/help/SectionDetail";

	export default {
		name: 'HelpCenter',

		props: ['isOpen'],

		components: { SectionLink, SectionDetail },

		data: function () {
			return {
				help: help,
				focusHelp: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			stillNeedHelp: function () {
				window.Intercom('show');
				this.$emit('close');
			},

			openHelpCenter: function(){
				window.open('https://help.withmoxie.com','_blank');
			},

			sectionSelected: function(section){
				this.focusHelp = section;
			},

			allHelp: function(){
				this.focusHelp = null;
			},

			tryAutoFocus: function(){
				let topic = this.getSuggestedTopic();
				if(topic){
					let section = this.help.find(h => h.id === topic);
					if(section){
						this.sectionSelected(section);
					}else{
						this.allHelp();
					}
				}
			},

			getSuggestedTopic: function(){
				// let path = this.$router.currentRoute.path;
				// console.log(path);
				// if(path.startsWith('/home')) {
				// 	return "Homebase";
				// }else if(path.startsWith('/communicator')){
				// 	return "Communicator";
				// }else if(path.startsWith('/projects')) {
				// 	return "Projects";
				// }else if(path.startsWith('/accounting')) {
				// 	return "Accounting";
				// }else if(path.startsWith('/pipeline')) {
				// 	return "SalesPipeline"
				// }else if(path.startsWith('/subscription')){
				// 	return 'GettingStarted'
				// }else if(path.startsWith('/portal')){
				// 	return 'ClientPortal'
				// }else if(path.startsWith('/pages')){
				// 	return 'MeetingsForms'
				// }else if(path.startsWith('/templates')){
				// 	return 'Templates'
				// }else if(path.startsWith('/clients')){
				// 	let queryString = window.location.search;
				// 	let urlParams = new URLSearchParams(queryString);
				// 	let v = urlParams.get('v');
				//
				// 	if(v === 'Invoices' || v === 'Deposits'){
				// 		return 'Invoicing';
				// 	}else if(v === 'Proposals') {
				// 		return 'Proposals';
				// 	}else if(v === 'Time') {
				// 		return 'TimeTracking';
				// 	}else if(v === 'Forms'){
				// 		return 'MeetingsForms'
				// 	}else{
				// 		return 'Clients';
				// 	}
				//
				// }else{
				// 	return null;
				// }
				return null;
			}
		},

		computed: {
			sortedHelp: function(){
				let result = [... this.help];
				let top = result.splice(0,2);
				result.sort((a,b) => a.name.localeCompare(b.name));
				result.unshift(... top);
				return result;
			}
		},

		watch: {
			isOpen: function(val){
				if(val){
					this.tryAutoFocus();
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	#help-center {
		position: fixed;
		right: 0;
		background-color: var(--v-white-base);
		height: var(--vh);
		width: 400px;
		z-index: 200;
		border: 1px solid var(--v-gray_50-base);
		display: flex;
		flex-direction: column;
	}

	.button {
		width: 50%;
		height: 100px;
		border: 1px solid var(--v-white-base);
		background-color: var(--v-gray_20-base);
		border-radius: 4px;
		cursor: pointer;
		&:hover {
			border: 1px solid var(--v-gray_50-base);
		}
	}

	.light {
		box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.35);
	}

	.dark {
		box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.25);
	}
</style>
