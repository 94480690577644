var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pointer",
      on: {
        click: function($event) {
          return _vm.downloadFile()
        }
      }
    },
    [
      _vm.isImageType && _vm.signedUrl
        ? _c("img", {
            staticStyle: { "max-width": "200px" },
            attrs: { src: _vm.signedUrl }
          })
        : _vm._e(),
      !_vm.isImageType
        ? _c(
            "v-chip",
            { staticClass: "pointer", attrs: { color: "blue_10" } },
            [
              _c(
                "div",
                { staticClass: "row-format align-center font-12" },
                [
                  _c("div", [
                    _vm._v("Attachment: " + _vm._s(_vm.s3File.fileName))
                  ]),
                  _c("v-icon", { staticClass: "ml-2", attrs: { small: "" } }, [
                    _vm._v("$download")
                  ])
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }