var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-list" },
    [
      _c("v-data-table", {
        staticClass: "h-dense pointer",
        staticStyle: {
          border: "none!important",
          "overflow-y": "auto",
          "max-height": "300px"
        },
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredProjects,
          "sort-by": "nextDueDate",
          "sort-desc": true,
          "hide-default-footer": true,
          "fixed-header": true,
          "items-per-page": -1
        },
        on: { "click:row": _vm.routeToProject },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                item.name
                  ? _c(
                      "span",
                      {
                        staticClass: "brand-medium truncate",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.routeToProject(item, $event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticStyle: { height: "16px" } },
                  [
                    _c("deliverable-by-status", {
                      attrs: { project: item, deliverables: item.deliverables },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.routeToProject(item, $event)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.startDate",
            fn: function(ref) {
              var item = ref.item
              return [
                item.startDate
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.routeToProject(item, $event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            item.startDate.toLocaleString({
                              month: "short",
                              day: "numeric"
                            })
                          )
                        )
                      ]
                    )
                  : _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.routeToProject(item, $event)
                          }
                        }
                      },
                      [_vm._v(" -- ")]
                    )
              ]
            }
          },
          {
            key: "item.dueDate",
            fn: function(ref) {
              var item = ref.item
              return [
                item.dueDate
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.routeToProject(item, $event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            item.dueDate.toLocaleString({
                              month: "short",
                              day: "numeric"
                            })
                          )
                        )
                      ]
                    )
                  : _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.routeToProject(item, $event)
                          }
                        }
                      },
                      [_vm._v(" -- ")]
                    )
              ]
            }
          },
          {
            key: "item.nextDue",
            fn: function(ref) {
              var item = ref.item
              return [
                item.nextDue
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.routeToProject(item, $event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            item.nextDue.toLocaleString({
                              month: "short",
                              day: "numeric"
                            })
                          )
                        )
                      ]
                    )
                  : _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.routeToProject(item, $event)
                          }
                        }
                      },
                      [_vm._v(" -- ")]
                    )
              ]
            }
          },
          {
            key: "item.fees",
            fn: function(ref) {
              var item = ref.item
              return [_c("span", [_vm._v(_vm._s(_vm.fees(item)))])]
            }
          },
          {
            key: "item.invoiced",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$formatters.dollars(
                        item.paymentHistory.reduce(function(sum, ref) {
                          var localAmount = ref.localAmount

                          return sum + localAmount
                        }, 0),
                        false,
                        true,
                        _vm.client.currency
                      )
                    )
                  )
                ])
              ]
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "col col-12 text-left brand-medium" },
                  [_vm._v("No projects found")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.completeProjects.length && !_vm.showComplete
        ? _c(
            "div",
            {
              staticClass: "row-format py-2 pointer",
              staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" },
              on: {
                click: function($event) {
                  _vm.showComplete = true
                }
              }
            },
            [
              _c("div", { staticClass: "col col-12 font-12 text-left" }, [
                _vm._v(
                  "+ " +
                    _vm._s(_vm.completeProjects.length) +
                    " completed projects"
                )
              ])
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "row-format footer-row font-12 pointer",
          staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" },
          on: {
            click: function($event) {
              return _vm.createNewProject()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "col col-10 text-left row-format align-center" },
            [
              _c("div", { staticClass: "font-gray_70" }, [
                _vm._v("Create new")
              ]),
              _c("h-icon3", {
                staticStyle: {
                  "margin-left": "4px",
                  "margin-right": "2px",
                  "padding-bottom": "3px"
                },
                attrs: {
                  name: "projects",
                  size: "16",
                  color: "var(--v-secondary-base)"
                }
              }),
              _c("div", [_vm._v("Project")])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col col-2 text-right" },
            [
              _c("v-icon", { attrs: { size: "20", color: "secondary" } }, [
                _vm._v("add")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }