<template>
	<div class="action-menu">
		<v-menu :close-on-click="true" :nudge-bottom="30">
			<template v-slot:activator="scope">
				<div class="ml-1">
					<v-btn icon class="menu-activator" v-on="scope.on">
						<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
					</v-btn>
				</div>
			</template>
			<div class="more-menu">
				<div class="more-menu-item" @click="$emit('view')">
					View details
				</div>
				<div v-if="!hideDuplicate" class="more-menu-item" @click="$emit('duplicate')">
					Duplicate scheduler
				</div>
				<div class="more-menu-item" @click="$emit('delete')">
					Delete
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'MeetingActionMenu',

		components: {

		},

		props: ['hideDuplicate'],

		data: function() {
			return {
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {

		},
		computed: {
		},
		watch: {},
	};
</script>

<style scoped lang="scss">
	.action-menu {
		.more-menu {
			max-width: 200px;
		}
	}
</style>
