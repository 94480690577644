<template>
	<div v-if="isReady" class="">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.caldav.desc') }}</div>
				<connected v-if="isConnected"></connected>
				<div v-else class="font-primary brand-medium pointer" @click="connectAccount">Connect CalDav calendar</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<div v-for="conn in accounts" :key="conn.id"  class="pt-4 pb-4"  style="border-bottom: 1px solid var(--v-gray_50-base)">
				<div class="font-14">
					<div><strong>User</strong>: {{conn.username}}</div>
					<div><strong>Calendar</strong>: {{conn.calendarName}}</div>
					<div class="truncate"><strong>URL</strong>: {{conn.calendarUrl}}</div>
					<v-btn class="secondary-action mt-3" @click="confirmDisconnect(conn)"><v-icon small>$delete</v-icon>Disconnect</v-btn>
				</div>
			</div>
			<div class="mt-4">
				<v-btn elevation="0" color="secondary" @click="connectAccount">Connect additional account</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import Connected from '@/modules/account/apps/Connected';
	import ConfirmModal from '@/components/ConfirmModal';
	import CalDavCalendarService from "@/modules/integrations/caldav/CalDavCalendarService";
	import CalDavConnect from "@/modules/account/apps/CalDavConnect";

	export default {
		name: 'CalDavCalendar',

		props: [],

		components: { Connected },

		data: function () {
			return {
				calDavCalendarService: new CalDavCalendarService(),
				expanded: false,
				isReady: false,
				accounts: [],
			};
		},

		mounted() {
			this.getConnection();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.getConnection();
			},


			getConnection: function () {
				this.calDavCalendarService.getAccounts().then(res => {
					this.accounts.splice(0);
					this.accounts.push(... res.data);
					this.isReady = true;
				})
			},

			updateSettings: function(account){
				this.calDavCalendarService.update(this.appleAccount).then(res => {
					let ix = this.accounts.findIndex(a => a.id === account.id);
					this.accounts.splice(ix,1,res.data);
				})
			},

			verify: function(id){
				this.calDavCalendarService.verify(id).then(() => {
					console.log('OK');
				}).catch((err) => this.$store.commit('error',err.response.data.message));
			},

			connectAccount: function () {
				this.$store.state.globalModalController.openModal(CalDavConnect).then(res => {
					if(res){
						this.accounts.push(res);
						this.expanded = true;
						this.$onBoarding.track('connect_calendar');
					}
				})
			},

			confirmDisconnect: function (connection) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to disconnect your CalDav calendar?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.calDavCalendarService.disconnect(connection.id).then(() => {
							let ix = this.accounts.findIndex(a => a.id === connection.id);
							this.accounts.splice(ix,1);
						})
					}
				});
			},
		},

		computed: {
			isConnected: function(){
				if(this.accounts.length > 0){
					return true;
				}else{
					return false;
				}
			}
		},
	};
</script>

<style scoped lang="scss"></style>
