<template>
	<div class="ml-1 pl-1" :style="`color:var(--v-${foregroundColor}-base); height:100%; overflow-y: hidden; overflow-x: hidden;`">
		<div v-if="!inMoreView" :class="`${view === 'Two-Week' || view === 'Week' ? 'mt-1' : ''}`">
			<div class="row-format align-center">
				<v-icon size="12" :color="foregroundColor" class="mr-1">$meetingsOrig</v-icon>
				<div>{{ event.name }}</div>
			</div>
			<div class="text-left">{{ convertTime(orig.start) }}-{{ convertTime(orig.end) }}</div>
		</div>
		<div v-if="inMoreView" :class="`row-format align-center ${view === 'Two-Week' ? 'mt-1' : ''}`">
			<v-icon size="12" :color="foregroundColor" class="mr-1">$meetingsOrig</v-icon>
			<div class="text-left">{{ event.name }}</div>
		</div>
	</div>
</template>

<script>
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'ScheduledMeetingEvent',

		props: ['event', 'eventParsed', 'timed','view','inMoreView'],

		components: {
		},

		data: function () {
			return {
				scheduledMeeting: null,
				isReady: false,
				orig: this.event.original
			};
		},

		mounted() {
			this.initializeData();
		},

		beforeDestroy() {},

		methods: {
			initializeData: function () {
				this.scheduledMeeting = this.event.original.meta.scheduledMeeting;
				this.isReady = true;
			},

			convertTime: function (time) {
				return DateTime.fromISO(time).toFormat('t').replace(' ','').toLowerCase();
			},
		},

		computed: {
			foregroundColor: function(){
				if(this.event.foregroundColor){
					return this.event.foregroundColor;
				}else{
					return 'white';
				}
			}
		}
	};
</script>

<style scoped lang="scss"></style>
