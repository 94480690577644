var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ourCall
    ? _c(
        "v-container",
        {
          staticClass: "font-14",
          attrs: { fluid: "" },
          on: {
            mouseenter: function($event) {
              _vm.hovering = true
            },
            mouseleave: function($event) {
              _vm.hovering = false
            },
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "", align: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "row-format",
                  attrs: { cols: "3", align: "left" }
                },
                [
                  !_vm.clientId
                    ? _c(
                        "div",
                        _vm._l(_vm.clients, function(client) {
                          return _c("client-avatar", {
                            key: client.id,
                            staticClass: "mr-1",
                            attrs: { client: client, small: "" }
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class:
                        "text-truncate " +
                        (_vm.clients.length && !_vm.clientId ? "ml-3" : "")
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.contact
                            ? _vm.contact.fullName
                            : _vm.formattedPhone
                        )
                      )
                    ]
                  )
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "row-format align-center",
                  attrs: { cols: "7", align: "left" }
                },
                [
                  _vm.ourCall.direction === "Outbound"
                    ? _c("v-icon", { attrs: { color: "gray_60" } }, [
                        _vm._v("$phoneOut")
                      ])
                    : _vm.ourCall.callResult === "Missed" &&
                      !_vm.ourCall.callRecording
                    ? _c("v-icon", { attrs: { color: "gray_60" } }, [
                        _vm._v("$phoneMissed")
                      ])
                    : _c("v-icon", { attrs: { color: "gray_60" } }, [
                        _vm._v("$phone")
                      ]),
                  _vm.ourCall.callRecording
                    ? _c(
                        "v-icon",
                        { staticClass: "ml-2", attrs: { color: "gray_60" } },
                        [_vm._v("$voicemail")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class:
                        "ml-2  " + (_vm.ourCall.unread ? "brand-bold " : "")
                    },
                    [_vm._v(_vm._s(_vm.duration))]
                  ),
                  _vm.ourCall.transcription
                    ? _c("div", { staticClass: "text-truncate ml-2" }, [
                        _vm._v(_vm._s(_vm.ourCall.transcription.text))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "nowrap", attrs: { cols: "2", align: "right" } },
                [
                  _vm.hovering
                    ? _c("inbox-actions", {
                        staticClass: "ml-auto",
                        attrs: {
                          unread: _vm.ourCall.unread,
                          "snooze-options": _vm.snoozeOptions,
                          folder: _vm.ourFolder
                        },
                        on: {
                          "return-to-inbox": function($event) {
                            return _vm.returnToInbox()
                          },
                          snooze: function($event) {
                            return _vm.snooze($event)
                          },
                          archive: function($event) {
                            return _vm.archive()
                          },
                          unread: function($event) {
                            return _vm.markCallAsRead()
                          }
                        }
                      })
                    : _vm._e(),
                  !_vm.hovering
                    ? _c("div", { staticStyle: { height: "38px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$formatters.formatTimeAgo(
                              _vm.communication.date
                            )
                          )
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }