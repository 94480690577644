var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ma-4" }, [
    _c(
      "div",
      {
        staticStyle: {
          "max-height": "calc(var(--vh) - 80px)",
          overflow: "auto"
        }
      },
      [
        _c("lead-gen", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentView === "SalesPipeline",
              expression: "currentView === 'SalesPipeline'"
            }
          ]
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }