import Account from "@/modules/account/Account";
import Referrals from "@/modules/account/Referrals";


export default [
	{
		path: '/account',
		name: 'account',
		component: Account,
		title: 'Account',
		props: true
	},
	{
		path: '/referrals',
		name: 'referrals',
		component: Referrals,
		title: 'Referrals',
		props: true,
	}
];
