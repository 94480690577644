import HttpClient from '@/services/HttpClient';

export default class AgreementService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient(null,true,2);
    }

    initializeAgreement(clientId,opportunityId,baseAgreement){
        return this.httpClient
            .post(`/agreements?clientId=${clientId}&opportunityId=${opportunityId}`,baseAgreement)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getAgreements(clientId = null){
        if(clientId) {
            return this.httpClient
                .get(`/agreements?clientId=${clientId}`)
                .then((res) => Promise.resolve(res))
                .catch((err) => Promise.reject(err));
        }else{
            return this.httpClient
                .get(`/agreements`)
                .then((res) => Promise.resolve(res))
                .catch((err) => Promise.reject(err));
        }
    }

    getAgreement(id){
        return this.httpClient
            .get(`/agreements/${id}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    saveAgreement(id,agreement){
        return this.httpClient
            .put(`/agreements/${id}`,agreement)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    finalizeAgreement(id){
        return this.httpClient
            .post(`/agreements/${id}/finalize`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    returnToDraft(id){
        return this.httpClient
            .post(`/agreements/${id}/returnToDraft`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    voidAgreement(id){
        return this.httpClient
            .post(`/agreements/${id}/void`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    deleteAgreement(id){
        return this.httpClient
            .delete(`/agreements/${id}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    cloneAgreement(id,newClientId){
        return this.httpClient
            .post(`/agreements/${id}/clone?newClientId=${newClientId}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }


    renderTerms(terms) {
        return this.httpClient
            .post(`/agreements/createTerms`, terms)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    async sendAgreement(id,sendAgreement, sendLater=null) {
        const params = {};

        if (sendLater !== null) {
            params.sendLater = sendLater;
        }

        return this.httpClient
            .post(`/agreements/${id}/send`, sendAgreement, {params:params})
            .then((res) => Promise.resolve(res.data))
            .catch((err) => Promise.reject(err));
    }
}