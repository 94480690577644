<template>
	<div>
		<div class="column-format pt-1 pb-2 text-left">
			<div class="brand-bold font-18">Elements</div>
			<div class="font-14 font-gray_80">Click or drag into your document</div>
		</div>
		<div v-for="(category, index) in schemaTypes" :key="index" style="width:100%;" class="mb-3">
			<div class="text-left font-14 pb-3">{{ category.label }}</div>
			<div class="row-format flex-wrap gap-2">
				<div
					v-for="schema in category.types"
					:key="schema.type"
					:id="schema.type"
					:draggable="!disableDrag"
					@click="disableDrag ? $emit('selected',schema) : null"
					:class="`row-format schema-item align-start px-3 py-2 text-left ${disableDrag ? 'drag-disabled' : 'drag-enabled'}`"
					@dragstart="$emit('dragstart', $event)"
				>
					<div class="font-14">{{ schema.label }}</div>
					<v-icon class="material-symbols-rounded ml-auto" color="black" size="22">{{ schema.icon }}</v-icon>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AgreementSchemaTypes from '@/modules/agreements/AgreementSchemaTypes.json';
	export default {
		name: 'ElementTypes',

		props: ['disableDrag','inContainer'],

		components: {},

		data: function() {
			return {
				schemaTypes: AgreementSchemaTypes,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.schema-item {
		background-color: var(--v-gray_20-base);
		border: 1px solid var(--v-gray_20-base);
		width: 154px;
		height: 64px;
		border-radius: 4px;
		&:hover {
			background-color: var(--v-gray_20-base);
			border: 1px solid var(--v-gray_50-base);
		}
	}

	.drag-enabled {
		cursor: grab;
	}

	.drag-disabled {
		cursor: pointer;
	}
</style>
