<template>
	<div>
		<audio src="/media/ringback/US_ringback_tone.mp3" loop="true" style="display: none" id="Ringback"></audio>
		<audio src="/media/inbound-call.mp3" loop="true" style="display: none" id="InboundCall"></audio>
		<audio style="display: none" id="DTMF"></audio>
		<video v-if="rtcStream"
				id="phoneMedia"
				playsInline
				autoPlay
				style="display: none"></video>
	</div>
</template>

<script>
	import BandwidthRtc from '@bandwidth/webrtc-browser';
	import BandwidthService from "@/modules/communicator/realtime/bandwidth/BandwidthService";
	import ConfirmModal from "@/components/ConfirmModal";
	import {v4 as uuid4} from 'uuid';

	export default {
		name: 'BandwidthSoftPhone',

		props: [],

		components: {},

		data: function () {
			return {
				bandwidthService: new BandwidthService(),
				bandwidthRtc: new BandwidthRtc(),
				participant: null,
				rtcStream: null,
				eventKey: null,
				inboundCallEventKey: null,
				call: null,
				pending: false,
				cancelId: uuid4()
			};
		},

		mounted() {
			this.createParticipant();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(this.eventKey,this.handleCallUpdate);
			this.$store.state.eventBus.$off(this.inboundCallEventKey,this.newInboundCall);
		},

		methods: {
			createParticipant: function(){
				this.bandwidthService.createParticipant().then((res) => {
					this.participant = res.data;
					this.eventKey = `u-${this.$store.getters.getLoggedInUserId}.bandwidthCall.${this.participant.id}`;
					this.inboundCallEventKey = `u-${this.$store.getters.getLoggedInUserId}.bandwidthInboundCall`;
					this.inboundCallCancelEventKey = `u-${this.$store.getters.getLoggedInUserId}.bandwidthInboundCallCancel`;
					this.$store.state.eventBus.$on(this.eventKey,this.handleCallUpdate);
					this.$store.state.eventBus.$on(this.inboundCallEventKey,this.newInboundCall);
					this.$store.state.eventBus.$on(this.inboundCallCancelEventKey,this.inboundCallCancel);
					this.$emit('phone-id',this.participant.id);
					this.initializeDevice();
				});
			},

			newInboundCall: function(event){
				let call = event.message;

				let binding = {
					severity: 'success',
					icon: '$phone',
					headingText: 'Incoming call',
					bodyText: `Call from ${call.pstn.from} - do you wish to accept?`,
					timeOut: 30,
					yesText: 'Answer',
					noText: 'Ignore',
					persistent: true,
					forceTimeoutEventId: this.cancelId,
				}

				this.startRinging();

				this.$store.state.globalModalController.openModal(ConfirmModal,binding, false, false, true).then((res) => {
					if(res){
						this.bandwidthService.acceptCall(call.id,this.participant.id);
					}
				}).finally(() => this.stopRinging());
			},

			inboundCallCancel: function(){
				console.log('cancelling inbound call');
				this.$store.state.eventBus.$emit(this.cancelId);
			},

			handleCallUpdate: function(event){
				this.call = event.message;
				if(this.call.pstn.sessionStatus !== 'Ringing'){
					this.stopRingBack();
				}
			},

			stopRingBack: function(){
				document.getElementById("Ringback").pause();
				document.getElementById("Ringback").currentTime = 0;
			},

			startRingBack: function(){
				document.getElementById("Ringback").play();
			},

			startRinging: function(){
				document.getElementById("InboundCall").play();
			},

			stopRinging: function(){
				document.getElementById("InboundCall").pause();
				document.getElementById("InboundCall").currentTime = 0;
			},

			dtmf: function(digit){
				if(this.isCallActive){
					document.getElementById("DTMF").src = `media/dtmf/dtmf-${digit}.mp3`
					document.getElementById("DTMF").play();

					let dtmf;
					switch(digit){
						case "star": {dtmf = '*'; break;}
						case "pound": {dtmf = '#'; break;}
						default: {dtmf = digit;}
					}
					this.bandwidthRtc.sendDtmf(dtmf);
				}
			},

			async muteCall(){
				await this.bandwidthRtc.unpublish();
			},

			async unMuteCall(){
				await this.bandwidthRtc.publish({
					audio: true,
					video: false,
				});
			},

			startRecording: function(){
				return this.bandwidthService.startRecording(this.call.id);
			},

			pauseRecording: function(){
				return this.bandwidthService.pauseRecording(this.call.id);
			},

			makeCall: function(phoneToDial){
				this.startRingBack();
				this.pending = true;
				this.$store.state.communicator.callIsActive = true;

				this.bandwidthService.makeOutboundCall(
						{
							to: phoneToDial,
							participantId: this.participant.id,
							ringDuration: 60
						}
				).then((res) => {
					this.call = res.data;
				}).finally(() => this.pending = false);
			},

			hangupCall: function(){
				this.bandwidthService.hangupCall(this.call.id);
			},

			initializeDevice: function () {
				this.bandwidthRtc.connect({
					deviceToken: this.participant.token
				});

				this.bandwidthRtc.onStreamAvailable((event) => this.onStreamAvailable(event));
				this.bandwidthRtc.onStreamUnavailable((event) => this.onStreamUnavailable(event));

			},

			async onStreamAvailable(event){
				console.log(
						`A stream is available with endpointId=${event.endpointId}, its media types are ${event.mediaTypes} and the stream itself is ${event.mediaStream}`
				);

				this.stopRingBack();

				await this.bandwidthRtc.publish({
					audio: true,
					video: false,
				});

				this.rtcStream = event;

				this.$nextTick(() => {
					let videoElement = document.getElementById("phoneMedia");
					videoElement.srcObject = this.rtcStream.mediaStream;
				})
			},

			async onStreamUnavailable(event){
				console.log(
						`The stream with endpointId=${event.endpointId} is now unavailable and is being removed from the UI.`
				);

				await this.bandwidthRtc.unpublish();
				this.rtcStream = null;
				this.call.pstn.sessionStatus = 'Down';
			}
		},

		watch: {
			isCallActive: function(current){
				this.$emit('call-active',current);
			},

			isCallRinging: function(current){
				this.$emit('call-ringing',current);
			},

			isCallUp: function(current){
				if(current){
					this.$emit('call-up',this.call);
				}else{
					this.$emit('call-up',null);
				}
			},
		},

		computed: {
			isCallUp: function(){
				if(this.call && (this.call.pstn.sessionStatus === 'Up')){
					return true;
				}else{
					return false;
				}
			},

			isCallRinging: function(){
				if(this.pending){
					return true;
				}else if(this.call && (this.call.pstn.sessionStatus === 'Ringing')){
					return true;
				}else{
					return false;
				}
			},

			isCallActive: function(){
				if(this.call && (this.call.pstn.sessionStatus === 'Up' || this.call.pstn.sessionStatus === 'Ringing')){
					return true;
				}else{
					return false;
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.key-pad {
		display: flex;
		flex-direction: column;

		.button-row {
			margin-top: 8px;
			display: flex;
			flex-direction: row;
			gap: 8px;
		}
	}
</style>
