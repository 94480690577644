var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", {}, [
        _c("div", { staticClass: "row-format align-start" }, [
          _c(
            "div",
            { staticClass: "column-format" },
            [
              _c("div", { staticClass: "font-gray_80 mb-1" }, [
                _vm._v(_vm._s(_vm.$t("connected-apps.apple.desc")))
              ]),
              _vm.isConnected
                ? _c("connected")
                : _c(
                    "div",
                    {
                      staticClass: "font-primary brand-medium pointer",
                      on: { click: _vm.connectAppleAccount }
                    },
                    [_vm._v("Connect iCloud calendar")]
                  )
            ],
            1
          ),
          _vm.isConnected
            ? _c(
                "div",
                {
                  staticClass: "ml-auto pointer",
                  on: {
                    click: function($event) {
                      _vm.expanded = !_vm.expanded
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.isConnected && _vm.expanded
          ? _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "div",
                  { staticClass: "mb-6 font-14" },
                  [
                    _c("div", [
                      _vm._v(
                        "iCloud account " +
                          _vm._s(_vm.appleAccount.email) +
                          " successfully connected. Please select which calendars you would like enabled:"
                      )
                    ]),
                    _vm._l(_vm.appleAccount.calendars, function(cal) {
                      return _c("v-checkbox", {
                        key: cal.path,
                        attrs: {
                          label: cal.name,
                          "hide-details": "",
                          dense: ""
                        },
                        on: { change: _vm.updateSettings },
                        model: {
                          value: cal.enabled,
                          callback: function($$v) {
                            _vm.$set(cal, "enabled", $$v)
                          },
                          expression: "cal.enabled"
                        }
                      })
                    })
                  ],
                  2
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action mr-2",
                    on: { click: _vm.refreshConnection }
                  },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [_vm._v("refresh")]),
                    _vm._v("Refresh calendar list")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action mr-1",
                    on: { click: _vm.confirmDisconnect }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("$delete")]),
                    _vm._v("Disconnect")
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }