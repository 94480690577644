var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    attrs: {
      items: _vm.filteredEmails,
      multiple: true,
      chips: true,
      "deletable-chips": true,
      clearable: "",
      "hide-details": "",
      dense: "",
      outlined: "",
      "persistent-placeholder": "",
      label: _vm.label
    },
    on: { input: _vm.onInput },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function(data) {
          return [
            _c(
              "v-chip",
              {
                staticClass: "mt-1",
                attrs: {
                  small: "",
                  color: "gray_20",
                  "input-value": data.selected,
                  close: "",
                  "close-icon": "clear"
                },
                on: {
                  "click:close": function($event) {
                    return _vm.remove(data.item)
                  }
                }
              },
              [_vm._v(" " + _vm._s(data.item) + " ")]
            )
          ]
        }
      }
    ]),
    model: {
      value: _vm.internalValue,
      callback: function($$v) {
        _vm.internalValue = $$v
      },
      expression: "internalValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }