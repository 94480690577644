export default {
	install(Vue) {
		Object.defineProperties(Vue.prototype, {
			$validations: {
				get() {
					return {
						isEmpty: function(val) {
							return val === undefined || val === null || val.length === 0;
						},
						validPhone(phone, required = false) {
							if ((phone == null || phone === '') && !required) return true;
							else if (phone == null || phone === '') return false;
							if (phone.search(/[^-+0-9.,() ]/) !== -1) {
								return false;
							}
							phone = phone.replace(/[^0-9]+/g, '');
							return phone.length >= 10 && phone.length <= 15;
						},
					};
				},
			},
		});
	},
};
