var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("notification-layout", {
    attrs: {
      id: _vm.id,
      "action-text": _vm.$t("notifications.view-opportunity"),
      "sent-at": _vm.sentAt
    },
    on: {
      action: function($event) {
        return _vm.openOpportunity()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _vm.client
              ? _c("client-avatar", {
                  staticClass: "mr-2",
                  attrs: { "x-small": "", client: _vm.client }
                })
              : _vm._e(),
            _c("div", { staticClass: "text-left" }, [
              _vm._v(_vm._s(_vm.opportunity.name))
            ])
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("div", { staticClass: "font-14 mb-1 brand-medium" }, [
              _vm._v(_vm._s(_vm.$t("notifications.opportunity-created")))
            ]),
            _c("div", { staticClass: "font-12" }, [
              _c("span", [
                _vm._v(_vm._s(_vm._f("formatForTimeAgo")(_vm.sentAt)) + " ")
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }