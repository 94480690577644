var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "free-card row-format align-center pointer",
      staticStyle: { overflow: "hidden" },
      on: {
        click: function($event) {
          return _vm.$emit("upgrade-clicked")
        }
      }
    },
    [
      _c("div", { staticClass: "text-left ml-4" }, [
        _c("div", { staticClass: "brand-medium" }, [
          _vm._v("You're on the free Essentials plan!")
        ]),
        _c(
          "div",
          {
            staticClass: "font-14 brand-semilight mt-1",
            staticStyle: { "line-height": "20px" }
          },
          [_vm._v(" " + _vm._s(_vm.message) + " ")]
        )
      ]),
      _c(
        "div",
        { staticClass: "ml-auto", staticStyle: { position: "relative" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: {
                position: "absolute",
                top: "70px",
                right: "20px",
                "z-index": "1"
              },
              attrs: { color: "accent", elevation: "0" }
            },
            [
              _c("span", { staticStyle: { color: "var(--v-white-base)" } }, [
                _vm._v("Upgrade")
              ])
            ]
          ),
          _c("img", {
            staticClass: "mt-0",
            staticStyle: { transform: "scaleX(-1)" },
            attrs: { src: "/images/logo/brain-splash-small.png", width: "140" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }