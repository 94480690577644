var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.itemSum > 0
    ? _c(
        "div",
        {
          staticClass: "row-format gap-2 font-14 pointer",
          on: { click: _vm.viewInvoice }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { content: "Invoice" },
                  expression: "{content: `Invoice`}"
                }
              ],
              staticClass: "material-symbols-rounded",
              attrs: { color: "secondary", size: "20" }
            },
            [_vm._v("request_quote")]
          ),
          _c("div", [
            _vm._v(
              "Project invoice " +
                _vm._s(_vm.invoice.invoiceNumberFormatted) +
                " | " +
                _vm._s(
                  _vm.$formatters.dollars(
                    _vm.itemSum,
                    true,
                    true,
                    _vm.invoice.currency
                  )
                ) +
                " | "
            ),
            _c("span", { staticStyle: { "text-transform": "capitalize" } }, [
              _vm._v(_vm._s(_vm.invoice.status.toLowerCase()))
            ])
          ]),
          _c("div", { staticClass: "ml-auto font-gray_80 font-12 nowrap" }, [
            _vm._v(_vm._s(_vm._f("formatForTimeAgo")(_vm.item.timestamp)))
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }