import SubscriptionRouter from "@/modules/subscription/SubscriptionRouter";
import AddOns from "@/modules/subscription/addons/AddOns";

export default [
	{
		path: '/subscription',
		redirect: '/subscription/workspace'
	},
	{
		path: '/subscription/workspace',
		name: 'subscription',
		component: SubscriptionRouter,
		title: 'SubscriptionRouter',
	},
	{
		path: '/subscription/add-ons',
		name: 'addOns',
		component: AddOns,
		title: 'AddOns',
	},
];
