<template>
	<div style="background-color: var(--v-white-base)">
		<div class="row-format">
			<v-btn class="ml-auto" icon @click="$emit('result')">
				<v-icon size="20">$close</v-icon>
			</v-btn>
		</div>
		<client-project-select
				v-if="timer"
				@delete-timer="deleteTimer"
				@update-timer="handleUpdateTimer($event)"
				@close="$emit('result')"
				:current-timer="timer"
				:show-client="showClient"
				:show-project="showProject"
				:show-deliverable="showDeliverable"
		></client-project-select>
	</div>
</template>

<script>
import TimeTrackService from "@/modules/timetracking/TimeTrackService";
import ClientProjectSelect from "@/modules/timetracking/ClientProjectSelect";

export default {
	name: "ClientProjectSelectModal",

	props: {
		currentTimer: { type: Object, required: true },
		showClient: { type: Boolean, required: false, default: true },
		showProject: { type: Boolean, required: false, default: true },
		showDeliverable: { type: Boolean, required: false, default: true },
	},

	components: {ClientProjectSelect},

	data: function () {
		return {
			timeTrackService: new TimeTrackService(),
			timer: JSON.parse(JSON.stringify(this.currentTimer))
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		handleUpdateTimer(event) {
			this.timer = event;
			this.updateTimer();
		},

		updateTimer() {
			this.timeTrackService
					.update(this.timer)
					.then(() => {
						//don't do anything - pubnub gonna handle it.
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
						this.$emit('error',err);
					});
		},

		deleteTimer() {
			this.timeTrackService
					.delete()
					.then(() => {
						this.timer = null;
						this.$store.commit('setCurrentTimer',null);
						this.$emit('result');
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
		},
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>