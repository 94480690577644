import HttpClient from '@/services/HttpClient';

export default class IncomeService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getIncomeList(earliest, latest) {
		return this.httpClient
			.get('/income', {
				params: {
					earliest: earliest,
					latest: latest,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	getIncome(id) {
		return this.httpClient
			.get(`/income/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getIncomeByClient(clientId) {
		return this.httpClient
			.get('/income', {
				params: {
					clientId: clientId,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getIncomeByProject(projectId,currency) {
		return this.httpClient
			.get('/income', {
				params: {
					projectId: projectId,
					currency: currency,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	createIncome(income) {
		return this.httpClient
			.post(`/income/`, income)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateIncome(id, income) {
		return this.httpClient
			.put(`/income/${id}`, income)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteIncome(id) {
		return this.httpClient
			.delete(`/income/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
