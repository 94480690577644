var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "400px" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: { click: _vm.handleClose }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.inviteUser()
            }
          },
          model: {
            value: _vm.emailValid,
            callback: function($$v) {
              _vm.emailValid = $$v
            },
            expression: "emailValid"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-padding" },
            [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.header))
              ]),
              _c(
                "div",
                {
                  staticClass: "modal-subtitle mb-2",
                  staticStyle: { "white-space": "pre-wrap" }
                },
                [_vm._v(_vm._s(_vm.description))]
              ),
              _c("v-text-field", {
                staticClass: "h-outline",
                attrs: {
                  rules: _vm.emailRules,
                  type: "text",
                  autofocus: "",
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: "Email"
                },
                model: {
                  value: _vm.userEmail,
                  callback: function($$v) {
                    _vm.userEmail = $$v
                  },
                  expression: "userEmail"
                }
              }),
              _vm.type === "RESTRICTED_USER"
                ? _c("div", [
                    _c("div", { staticClass: "modal-subtitle mb-1 mt-4" }, [
                      _vm._v("Feature access")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "pa-3",
                        staticStyle: {
                          "max-height": "400px",
                          "overflow-y": "auto",
                          "background-color": "var(--v-gray_10-base)",
                          border: "1px solid var(--v-gray_50-base)"
                        }
                      },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            label: "Projects",
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.featureAccess.projects,
                            callback: function($$v) {
                              _vm.$set(_vm.featureAccess, "projects", $$v)
                            },
                            expression: "featureAccess.projects"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            label: "Invoices",
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.featureAccess.invoices,
                            callback: function($$v) {
                              _vm.$set(_vm.featureAccess, "invoices", $$v)
                            },
                            expression: "featureAccess.invoices"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            label: "Accounting",
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.featureAccess.accounting,
                            callback: function($$v) {
                              _vm.$set(_vm.featureAccess, "accounting", $$v)
                            },
                            expression: "featureAccess.accounting"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            label: "Pipeline",
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.featureAccess.pipeline,
                            callback: function($$v) {
                              _vm.$set(_vm.featureAccess, "pipeline", $$v)
                            },
                            expression: "featureAccess.pipeline"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            label: "Agreements",
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.featureAccess.agreements,
                            callback: function($$v) {
                              _vm.$set(_vm.featureAccess, "agreements", $$v)
                            },
                            expression: "featureAccess.agreements"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            label: "Settings",
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.featureAccess.settings,
                            callback: function($$v) {
                              _vm.$set(_vm.featureAccess, "settings", $$v)
                            },
                            expression: "featureAccess.settings"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            label: "Tickets/Requests",
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.featureAccess.tickets,
                            callback: function($$v) {
                              _vm.$set(_vm.featureAccess, "tickets", $$v)
                            },
                            expression: "featureAccess.tickets"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            label: "Timesheets",
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.featureAccess.timesheets,
                            callback: function($$v) {
                              _vm.$set(_vm.featureAccess, "timesheets", $$v)
                            },
                            expression: "featureAccess.timesheets"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm.projects
                ? _c("div", [
                    _c("div", { staticClass: "modal-subtitle mb-1 mt-4" }, [
                      _vm._v("Select projects")
                    ]),
                    _vm.projects.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "pa-3",
                            staticStyle: {
                              "max-height": "400px",
                              "overflow-y": "auto",
                              "background-color": "var(--v-gray_10-base)",
                              border: "1px solid var(--v-gray_50-base)"
                            }
                          },
                          _vm._l(_vm.projects, function(p) {
                            return _c(
                              "div",
                              {
                                key: p.id,
                                staticClass:
                                  "row-format align-center text-left mb-2"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.projectList,
                                      expression: "projectList"
                                    }
                                  ],
                                  staticStyle: { transform: "scale(1.4)" },
                                  attrs: { type: "checkbox", id: p.id },
                                  domProps: {
                                    value: p.id,
                                    checked: Array.isArray(_vm.projectList)
                                      ? _vm._i(_vm.projectList, p.id) > -1
                                      : _vm.projectList
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.projectList,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = p.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.projectList = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.projectList = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.projectList = $$c
                                      }
                                    }
                                  }
                                }),
                                _c("label", { attrs: { for: p.id } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row-format align-center pointer"
                                    },
                                    [
                                      _c("client-avatar", {
                                        staticClass: "ml-3",
                                        attrs: {
                                          "disable-click": "",
                                          small: "",
                                          client: p.client
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ml-3 font-14 ellipsis",
                                          staticStyle: { width: "300px" }
                                        },
                                        [_vm._v(_vm._s(p.name))]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "font-14 font-gray_70 pa-3",
                            staticStyle: {
                              "background-color": "var(--v-gray_10-base)",
                              border: "1px solid var(--v-gray_50-base)"
                            }
                          },
                          [
                            _vm._v(
                              " You currently don't have any projects in your work space. You will need to create the project before you can invite the collaborators. "
                            )
                          ]
                        )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  on: {
                    click: function($event) {
                      return _vm.$emit("result", null)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "super-action ml-1",
                  attrs: { disabled: _vm.isDisabled, width: "200" },
                  on: {
                    click: function($event) {
                      return _vm.inviteUser()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("global.invite")))]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }