var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "inputRef",
    staticStyle: { color: "var(--v-black-base)" },
    attrs: { type: "text", placeholder: "--" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }