var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "panel-modal", staticStyle: { "min-width": "600px" } },
        [
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { color: "gray_90", small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("result")
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c("div", { staticClass: "brand-medium font-18" }, [
                  _vm._v("Edit client details")
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto row-format gap-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "super-action",
                        on: { click: _vm.updateClient }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer material-symbols-rounded",
                        attrs: { color: "gray_90", size: "20" },
                        on: { click: _vm.deleteClient }
                      },
                      [_vm._v("delete")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "panel-modal-body text-left show-scrollbar",
              attrs: { id: "client-edit" }
            },
            [
              _c(
                "v-form",
                {
                  ref: "clientEdit",
                  attrs: { id: "clientEditForm", "lazy-validation": "" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format align-center mt-2 gap-2" },
                    [
                      _vm.local.logo
                        ? _c(
                            "div",
                            { staticClass: "avatar mb-1" },
                            [
                              _c("client-avatar", {
                                attrs: {
                                  client: _vm.local,
                                  large: true,
                                  "disable-click": ""
                                }
                              }),
                              _vm.local.s3LogoFile
                                ? _c(
                                    "div",
                                    { staticClass: "avatar-delete pointer" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "gray_80" },
                                          on: { click: _vm.confirmDeleteLogo }
                                        },
                                        [_vm._v("$delete")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _c("client-avatar", {
                            staticStyle: { height: "100%" },
                            attrs: {
                              client: _vm.local,
                              large: true,
                              "disable-click": true
                            }
                          }),
                      _c(
                        "div",
                        {
                          staticStyle: { width: "100%" },
                          on: { click: _vm.handleSampleDataNameClick }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              autofocus: "",
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": "",
                              placeholder: "Enter client name",
                              label: _vm.$t("client.client-name"),
                              disabled: _vm.local.sampleData,
                              rules: [
                                function(value) {
                                  return !!value || "Required."
                                }
                              ]
                            },
                            model: {
                              value: _vm.local.name,
                              callback: function($$v) {
                                _vm.$set(_vm.local, "name", $$v)
                              },
                              expression: "local.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-n3" },
                        [
                          !_vm.local.s3LogoFile
                            ? _c(
                                "vue-dropzone",
                                {
                                  ref: "logoFileDropzone",
                                  staticStyle: {
                                    "background-color": "var(--v-white-base)"
                                  },
                                  attrs: {
                                    id: "logoDropzone",
                                    "use-custom-slot": true,
                                    options: _vm.logoDropzoneOptions
                                  },
                                  on: {
                                    "vdropzone-success": _vm.logoUploadSuccess,
                                    "vdropzone-error": _vm.logoUploadFailure,
                                    "vdropzone-sending":
                                      _vm.addFileUploadAuthHeader
                                  }
                                },
                                [
                                  _c("span", { staticClass: "font-12" }, [
                                    _vm._v("Logo")
                                  ]),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "tippy",
                                          rawName: "v-tippy",
                                          value: {
                                            content: "Upload client logo"
                                          },
                                          expression:
                                            "{ content: 'Upload client logo' }"
                                        }
                                      ],
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("$upload")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tabs",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c("v-tab", [_vm._v("Business info")]),
                      _vm.$store.getters.hasInvoiceAccess
                        ? _c("v-tab", [_vm._v("Invoicing & payment settings")])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "pa-0 mt-3 mx-0",
                              attrs: { fluid: "" }
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "column-format gap-3 pt-2",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          label: "Record type",
                                          items: _vm.type,
                                          "item-text": "label",
                                          "item-value": "value"
                                        },
                                        model: {
                                          value: _vm.local.clientType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.local,
                                              "clientType",
                                              $$v
                                            )
                                          },
                                          expression: "local.clientType"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("contact.address1"),
                                          "hide-details": "",
                                          dense: "",
                                          outlined: "",
                                          "persistent-placeholder": ""
                                        },
                                        model: {
                                          value: _vm.local.address1,
                                          callback: function($$v) {
                                            _vm.$set(_vm.local, "address1", $$v)
                                          },
                                          expression: "local.address1"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("contact.address2"),
                                          "hide-details": "",
                                          dense: "",
                                          outlined: "",
                                          "persistent-placeholder": ""
                                        },
                                        model: {
                                          value: _vm.local.address2,
                                          callback: function($$v) {
                                            _vm.$set(_vm.local, "address2", $$v)
                                          },
                                          expression: "local.address2"
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "row-format half gap-2"
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t("contact.city"),
                                              "hide-details": "",
                                              dense: "",
                                              outlined: "",
                                              "persistent-placeholder": ""
                                            },
                                            model: {
                                              value: _vm.local.city,
                                              callback: function($$v) {
                                                _vm.$set(_vm.local, "city", $$v)
                                              },
                                              expression: "local.city"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t("contact.locality"),
                                              "hide-details": "",
                                              dense: "",
                                              outlined: "",
                                              "persistent-placeholder": ""
                                            },
                                            model: {
                                              value: _vm.local.locality,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.local,
                                                  "locality",
                                                  $$v
                                                )
                                              },
                                              expression: "local.locality"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "row-format half gap-2"
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.$t("contact.postal"),
                                              "hide-details": "",
                                              dense: "",
                                              outlined: "",
                                              "persistent-placeholder": ""
                                            },
                                            model: {
                                              value: _vm.local.postal,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.local,
                                                  "postal",
                                                  $$v
                                                )
                                              },
                                              expression: "local.postal"
                                            }
                                          }),
                                          _c("v-autocomplete", {
                                            attrs: {
                                              label: _vm.$t("contact.country"),
                                              dense: "",
                                              outlined: "",
                                              "hide-details": "",
                                              "persistent-placeholder": "",
                                              items: _vm.countries.getCountriesSimple(),
                                              "item-value": "code",
                                              "item-text": "name"
                                            },
                                            model: {
                                              value: _vm.local.country,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.local,
                                                  "country",
                                                  $$v
                                                )
                                              },
                                              expression: "local.country"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("client.source"),
                                          "hide-details": "",
                                          dense: "",
                                          outlined: "",
                                          "persistent-placeholder": ""
                                        },
                                        model: {
                                          value: _vm.local.leadSource,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.local,
                                              "leadSource",
                                              $$v
                                            )
                                          },
                                          expression: "local.leadSource"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("contact.website"),
                                          "hide-details": "",
                                          dense: "",
                                          outlined: "",
                                          "persistent-placeholder": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function() {
                                                return [
                                                  _vm.local.website
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "material-symbols-rounded font-18 font-primary pointer pt-1",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openLink(
                                                                _vm.local
                                                                  .website
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("open_in_new")]
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          2847657682
                                        ),
                                        model: {
                                          value: _vm.local.website,
                                          callback: function($$v) {
                                            _vm.$set(_vm.local, "website", $$v)
                                          },
                                          expression: "local.website"
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            position: "relative"
                                          }
                                        },
                                        [
                                          _c("vue-tel-input", {
                                            staticClass: "dense-phone",
                                            attrs: {
                                              "valid-characters-only": true,
                                              "preferred-countries":
                                                _vm.preferredCountries,
                                              inputOptions: {
                                                placeholder: "Phone"
                                              }
                                            },
                                            on: { input: _vm.phoneUpdated },
                                            model: {
                                              value: _vm.phone,
                                              callback: function($$v) {
                                                _vm.phone = $$v
                                              },
                                              expression: "phone"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "phone-label" },
                                            [_vm._v("Phone")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-autocomplete", {
                                        attrs: {
                                          "hide-details": "",
                                          "persistent-placeholder": "",
                                          dense: "",
                                          outlined: "",
                                          label: _vm.$t("account.currency"),
                                          items: _vm.currencies,
                                          "item-text": "label",
                                          "item-value": "value"
                                        },
                                        model: {
                                          value: _vm.local.currency,
                                          callback: function($$v) {
                                            _vm.$set(_vm.local, "currency", $$v)
                                          },
                                          expression: "local.currency"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t("account.tax-id"),
                                          "hide-details": "",
                                          dense: "",
                                          outlined: "",
                                          "persistent-placeholder": ""
                                        },
                                        model: {
                                          value: _vm.local.taxId,
                                          callback: function($$v) {
                                            _vm.$set(_vm.local, "taxId", $$v)
                                          },
                                          expression: "local.taxId"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            "Unique client initials (invoice number prefix & client avatar)",
                                          "hide-details": "",
                                          dense: "",
                                          outlined: "",
                                          "persistent-placeholder": "",
                                          placeholder: _vm.initials,
                                          maxlength: "4"
                                        },
                                        model: {
                                          value: _vm.local.initials,
                                          callback: function($$v) {
                                            _vm.$set(_vm.local, "initials", $$v)
                                          },
                                          expression: "local.initials"
                                        }
                                      }),
                                      _vm._l(_vm.fields, function(field) {
                                        return _c("custom-field", {
                                          key: field.id,
                                          attrs: {
                                            mode: "dense",
                                            field: field,
                                            value: _vm.getCustomValue(field.id)
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleCustomFieldChange(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "mt-4 mb-1" },
                                        [
                                          _c("color-selector", {
                                            attrs: {
                                              value: _vm.local.color,
                                              label: "Highlight color"
                                            },
                                            on: {
                                              input: function($event) {
                                                _vm.local.color = $event
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "pa-2 text-left" },
                                        [
                                          _c("v-switch", {
                                            staticStyle: {
                                              "margin-top": "0px"
                                            },
                                            attrs: {
                                              inset: "",
                                              flat: "",
                                              dense: "",
                                              "hide-details": "",
                                              label:
                                                (_vm.local.archive
                                                  ? "Archived"
                                                  : "Archive") + " client"
                                            },
                                            model: {
                                              value: _vm.local.archive,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.local,
                                                  "archive",
                                                  $$v
                                                )
                                              },
                                              expression: "local.archive"
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "font-12 font-gray_80 mt-2"
                                            },
                                            [
                                              _vm._v(
                                                " Archived clients will still be accessible from the client list screen, "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "but will not show up in other areas of Moxie. "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.$store.getters.hasInvoiceAccess
                        ? _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-container",
                                {
                                  staticClass: "pa-0 mt-3 mx-0",
                                  attrs: { fluid: "" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "column-format gap-3 pt-2",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row-format half gap-2"
                                            },
                                            [
                                              _c("amount-input", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "client.hourly-amount.label"
                                                  ),
                                                  prefix: _vm.currencySymbol,
                                                  type: "text",
                                                  "hide-details": "",
                                                  dense: "",
                                                  outlined: "",
                                                  "persistent-placeholder": ""
                                                },
                                                model: {
                                                  value: _vm.local.hourlyAmount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.local,
                                                      "hourlyAmount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "local.hourlyAmount"
                                                }
                                              }),
                                              _c("v-select", {
                                                staticStyle: { width: "140px" },
                                                attrs: {
                                                  "hide-details": "",
                                                  "persistent-placeholder": "",
                                                  dense: "",
                                                  outlined: "",
                                                  label: _vm.$t(
                                                    "client.billing-increment"
                                                  ),
                                                  items: _vm.rounding,
                                                  "item-text": "label",
                                                  "item-value": "value"
                                                },
                                                model: {
                                                  value:
                                                    _vm.local.roundingIncrement,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.local,
                                                      "roundingIncrement",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "local.roundingIncrement"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row-format half gap-2"
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.dayOptions,
                                                  "hide-details": "",
                                                  dense: "",
                                                  outlined: "",
                                                  "persistent-placeholder": "",
                                                  label: _vm.$t(
                                                    "proposal.terms.payment-terms"
                                                  )
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          item === 0
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "On Receipt"
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          item !== 0
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(item) +
                                                                    " days"
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "item",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        return [
                                                          item === 0
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "On Receipt"
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          item !== 0
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(item) +
                                                                    " days"
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2647635356
                                                ),
                                                model: {
                                                  value:
                                                    _vm.local.paymentTerms
                                                      .paymentDays,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.local.paymentTerms,
                                                      "paymentDays",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "local.paymentTerms.paymentDays"
                                                }
                                              }),
                                              _c("v-text-field", {
                                                attrs: {
                                                  placeholder: "No fee",
                                                  suffix: "% / mo.",
                                                  "hide-details": "",
                                                  dense: "",
                                                  outlined: "",
                                                  "persistent-placeholder": "",
                                                  label: _vm.$t(
                                                    "proposal.terms.late-payment"
                                                  ),
                                                  type: "number"
                                                },
                                                model: {
                                                  value:
                                                    _vm.local.paymentTerms
                                                      .latePaymentFee,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.local.paymentTerms,
                                                      "latePaymentFee",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "local.paymentTerms.latePaymentFee"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row-format half pr-2"
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  placeholder:
                                                    _vm.$store.state.podAccount
                                                      .accountPreferences
                                                      .defaultTaxRate,
                                                  suffix: "%",
                                                  "hide-details": "",
                                                  dense: "",
                                                  outlined: "",
                                                  "persistent-placeholder": "",
                                                  label: "Tax rate",
                                                  type: "number"
                                                },
                                                model: {
                                                  value:
                                                    _vm.local.defaultTaxRate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.local,
                                                      "defaultTaxRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "local.defaultTaxRate"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "h-outline",
                                              staticStyle: {
                                                position: "relative"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mx-2 mb-2" },
                                                [
                                                  _c("editor", {
                                                    ref: "editor",
                                                    staticClass:
                                                      "mt-1 px-2 text-left",
                                                    staticStyle: {
                                                      width: "100%",
                                                      "min-height": "100px",
                                                      "border-bottom": "none"
                                                    },
                                                    attrs: {
                                                      "api-key":
                                                        _vm.$store.getters
                                                          .getTinyMceKey,
                                                      "initial-value":
                                                        _vm.local
                                                          .payInstructions,
                                                      inline: true,
                                                      init: _vm.mceConfigText,
                                                      spellcheck: true
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.handlePayInstructionsInput
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "phone-label",
                                                  staticStyle: {
                                                    "margin-top": "-2px"
                                                  }
                                                },
                                                [_vm._v("Payment instructions")]
                                              )
                                            ]
                                          ),
                                          _vm.$store.state.podAccount
                                            .stripeIntegration
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "column-format gap-3"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "row-format"
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          "hide-details": "",
                                                          "persistent-placeholder":
                                                            "",
                                                          disabled:
                                                            _vm.stripeLocked,
                                                          dense: "",
                                                          outlined: "",
                                                          label:
                                                            "Stripe client Id"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.local
                                                              .stripeClientId,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.local,
                                                              "stripeClientId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "local.stripeClientId"
                                                        }
                                                      }),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "pointer",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.stripeLocked = !_vm.stripeLocked
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("lock")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      "persistent-placeholder":
                                                        "",
                                                      "hide-details": "",
                                                      dense: "",
                                                      outlined: "",
                                                      items: _vm.paidByOptions,
                                                      label:
                                                        "Pass Stripe credit card fees to client?"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.local.paymentTerms
                                                          .whoPaysCardFees,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.local
                                                            .paymentTerms,
                                                          "whoPaysCardFees",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "local.paymentTerms.whoPaysCardFees"
                                                    }
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-italic font-12 text-left"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " This feature applies to Stripe credit card fees only. "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }