var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format",
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "draggable",
        {
          class: (_vm.item.stacked ? "column-format" : "row-format") + " gap-3",
          style:
            "padding-left: " +
            _vm.padding(_vm.item.pl) +
            "px; padding-right: " +
            _vm.padding(_vm.item.pr) +
            "px; padding-bottom: " +
            _vm.item.pb +
            "px; padding-top: " +
            _vm.item.pt +
            "px; " +
            (_vm.agreement.styles.pageSize === "RESPONSIVE"
              ? "flex-wrap: wrap;"
              : "flex-wrap:wrap"),
          attrs: {
            disabled:
              !_vm.active || !_vm.editMode || _vm.item.packages.length < 2,
            id: _vm.item.id,
            handle: ".dragHandle"
          },
          on: { start: _vm.onDragStart, end: _vm.onDragEnd },
          model: {
            value: _vm.item.packages,
            callback: function($$v) {
              _vm.$set(_vm.item, "packages", $$v)
            },
            expression: "item.packages"
          }
        },
        _vm._l(_vm.item.packages, function(servicePackage) {
          return _c(
            "div",
            {
              key: servicePackage.id,
              style:
                "flex: 1 1 0; " +
                (_vm.agreement.styles.pageSize === "RESPONSIVE"
                  ? "min-width:300px"
                  : "min-width:300px")
            },
            [
              _c("service-package", {
                ref: "servicePackage",
                refInFor: true,
                attrs: {
                  agreement: _vm.agreement,
                  services: _vm.item,
                  "service-package": servicePackage,
                  "mce-config": _vm.mceConfig,
                  active: _vm.active,
                  "client-mode": _vm.clientMode,
                  "edit-mode": _vm.editMode,
                  tokens: _vm.tokens,
                  signer: _vm.signer,
                  "template-mode": _vm.templateMode
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("click", $event)
                  },
                  change: function($event) {
                    return _vm.handleChange(servicePackage, $event)
                  },
                  delete: function($event) {
                    return _vm.deletePackage(servicePackage)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm.active && _vm.editMode
        ? _c("div", { staticClass: "row-format centered py-4" }, [
            _c(
              "div",
              {
                staticClass: "row-format add-button align-center",
                staticStyle: { "min-width": "400px" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "new row-format centered",
                    on: {
                      click: function($event) {
                        return _vm.addNewPackage()
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "material-symbols-rounded",
                        attrs: { color: "primary", size: "20" }
                      },
                      [_vm._v("add")]
                    ),
                    _c("div", [_vm._v("Add package")])
                  ],
                  1
                ),
                _c(
                  "v-menu",
                  {
                    attrs: {
                      "close-on-content-click": "",
                      "close-on-click": "",
                      "nudge-top": "100"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  {
                                    staticClass:
                                      "from-package row-format centered"
                                  },
                                  on
                                ),
                                [
                                  _c("div", [_vm._v("From template")]),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { color: "primary" }
                                    },
                                    [_vm._v("arrow_drop_down")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2925733456
                    )
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "more-menu show-scrollbar",
                        staticStyle: { "max-height": "500px" }
                      },
                      _vm._l(_vm.packageTemplates, function(template) {
                        return _c(
                          "div",
                          {
                            key: template.id,
                            staticClass: "more-menu-item font-14",
                            on: {
                              click: function($event) {
                                return _vm.importTemplate(template)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(template.name) + " ")]
                        )
                      }),
                      0
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }