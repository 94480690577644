var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { attrs: { id: "recurring-expense-detail" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-padding" },
            [
              _c("h3", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("recurring.payments.heading")))
              ]),
              _c("recurring-event-config", {
                attrs: { recurring: _vm.recurring }
              }),
              _c("hr", {
                staticClass: "mt-3 mb-6",
                staticStyle: {
                  "border-top": "1px solid var(--v-gray_50-base)",
                  "border-bottom": "none"
                }
              }),
              _c(
                "v-container",
                { staticClass: "px-0 mt-2 mb-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "column-format gap-3",
                          attrs: { cols: "12", sm: "7" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row-format" },
                            [
                              _c("amount-input", {
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  outlined: "",
                                  label: _vm.$t("income.amount-label"),
                                  placeholder: _vm.$t(
                                    "income.amount-placeholder"
                                  ),
                                  prefix: _vm.currencySymbol,
                                  type: "text",
                                  "persistent-placeholder": ""
                                },
                                model: {
                                  value: _vm.paymentCopy.amount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.paymentCopy, "amount", $$v)
                                  },
                                  expression: "paymentCopy.amount"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-3",
                                  staticStyle: { width: "60%" }
                                },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: "",
                                      label: _vm.$t("account.currency"),
                                      items: _vm.currencies,
                                      "item-text": "label",
                                      "item-value": "value"
                                    },
                                    model: {
                                      value: _vm.paymentCopy.currency,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.paymentCopy,
                                          "currency",
                                          $$v
                                        )
                                      },
                                      expression: "paymentCopy.currency"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": "",
                              "hide-details": "",
                              label: _vm.$t(
                                "invoice.mark-paid-modal.payment-type"
                              ),
                              items: _vm.paymentProviders
                            },
                            model: {
                              value: _vm.paymentCopy.paymentProvider,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paymentCopy,
                                  "paymentProvider",
                                  $$v
                                )
                              },
                              expression: "paymentCopy.paymentProvider"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              label: _vm.$t("income.id-label"),
                              placeholder: _vm.$t("income.id-placeholder"),
                              "persistent-placeholder": ""
                            },
                            model: {
                              value: _vm.paymentCopy.identifier,
                              callback: function($$v) {
                                _vm.$set(_vm.paymentCopy, "identifier", $$v)
                              },
                              expression: "paymentCopy.identifier"
                            }
                          }),
                          _c("v-textarea", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              label: _vm.$t("income.description"),
                              placeholder: _vm.$t(
                                "income.description-placeholder"
                              ),
                              "auto-grow": true,
                              rows: "1",
                              "persistent-placeholder": ""
                            },
                            model: {
                              value: _vm.paymentCopy.description,
                              callback: function($$v) {
                                _vm.$set(_vm.paymentCopy, "description", $$v)
                              },
                              expression: "paymentCopy.description"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", sm: "5" } }, [
                        _c(
                          "div",
                          { staticClass: "column-format gap-3" },
                          [
                            _c("v-autocomplete", {
                              ref: "clientId",
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("client.client"),
                                placeholder: _vm.$t("income.link-to-client"),
                                items: _vm.clientsPlusClear,
                                "item-text": "name",
                                "item-value": "id"
                              },
                              on: { change: _vm.clientChanged },
                              model: {
                                value: _vm.paymentCopy.clientId,
                                callback: function($$v) {
                                  _vm.$set(_vm.paymentCopy, "clientId", $$v)
                                },
                                expression: "paymentCopy.clientId"
                              }
                            }),
                            _vm.paymentCopy.clientId
                              ? _c("v-autocomplete", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    label: _vm.$t("projects.project"),
                                    placeholder: _vm.$t(
                                      "income.link-to-project"
                                    ),
                                    disabled: _vm.noClientDisabled,
                                    items: _vm.clientProjectsPlusClear,
                                    "item-text": "name",
                                    "item-value": "id",
                                    "no-data-text":
                                      "No projects for selected client"
                                  },
                                  on: { change: _vm.projectChanged },
                                  model: {
                                    value: _vm.paymentCopy.projectId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.paymentCopy,
                                        "projectId",
                                        $$v
                                      )
                                    },
                                    expression: "paymentCopy.projectId"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "row-format centered gap-1 mt-2 pa-2",
              staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "super-action",
                  attrs: {
                    width: _vm.$vuetify.breakpoint.smAndUp ? "160" : "128"
                  },
                  on: { click: _vm.saveRecurringPayment }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(!_vm.id ? "global.create" : "global.save")
                      ) +
                      " "
                  )
                ]
              ),
              _vm.recurring.id
                ? _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteRecurringPayment()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "20" }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }