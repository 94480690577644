var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format fill-height", attrs: { id: "project-kanban" } },
    [
      _c(
        "div",
        {
          staticClass: "my-0 py-0 fill-height",
          attrs: { id: "kanban-container" }
        },
        [
          _c("div", { staticClass: "mr-3", attrs: { id: "kanban-v-row" } }, [
            _c(
              "div",
              { staticClass: "row-format" },
              _vm._l(_vm.stages, function(stage) {
                return _c("div", { key: stage.id }, [
                  _vm.isInStatusFilter(_vm.filter, stage.id)
                    ? _c("div", { staticClass: "kanban-v-col" }, [
                        _c(
                          "div",
                          {
                            staticClass: "kanban-column pointer",
                            style:
                              "--r: " +
                              _vm.convertToRGB(stage.hexColor)[0] +
                              "; --g: " +
                              _vm.convertToRGB(stage.hexColor)[1] +
                              "; --b: " +
                              _vm.convertToRGB(stage.hexColor)[2] +
                              ";",
                            attrs: { id: "id." + stage.id }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row-format align-center mb-2 mx-2 py-1"
                              },
                              [
                                _c("div", {
                                  style:
                                    "width: 12px; min-width:12px; height: 12px; border-radius: 2px; background-color: " +
                                    stage.hexColor
                                }),
                                _c(
                                  "div",
                                  { staticClass: "font-14 brand-medium ml-3" },
                                  [
                                    _vm._v(" " + _vm._s(stage.label) + " "),
                                    _c(
                                      "span",
                                      { staticClass: "font-gray_70" },
                                      [
                                        _vm._v(
                                          "(" +
                                            _vm._s(
                                              _vm.getStageCount(stage.id)
                                            ) +
                                            ")"
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ml-auto font-14 brand-medium"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatters.dollars(
                                          _vm.getStageValue(stage.id),
                                          false
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "draggable",
                              {
                                class:
                                  "draggable-container " +
                                  _vm.$store.getters.scroll,
                                style:
                                  "height: calc(var(--vh) - 175px); overflow-y: auto;",
                                attrs: {
                                  list: _vm.cards["id" + stage.id],
                                  group: { name: "card", pull: "clone" },
                                  tag: "section",
                                  emptyInsertThreshold: 100,
                                  animation: 200,
                                  handle: _vm.$store.state.isMobile
                                    ? "disabled"
                                    : ".cardWrapper",
                                  "ghost-class": "ghost",
                                  move: _vm.cardDragHandler
                                },
                                on: { end: _vm.endDragHandler }
                              },
                              [
                                _vm._l(_vm.cards["id" + stage.id], function(
                                  opportunity
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: opportunity.id,
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.editOpportunity(
                                            opportunity
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("opportunity-card", {
                                        attrs: {
                                          opportunity: opportunity,
                                          stage: stage
                                        },
                                        on: {
                                          edit: function($event) {
                                            return _vm.$emit("edit", $event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row-format align-center font-14 font-gray_70 my-3",
                                    on: {
                                      click: function($event) {
                                        return _vm.addOpportunity(stage)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "gray_60" } },
                                      [_vm._v("add")]
                                    ),
                                    _c("div", [_vm._v("Add opportunity")])
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              }),
              0
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }