<template>
	<v-container fluid class="ma-0 pa-0">
		<v-row dense>
			<v-col cols="12" class="text-left column-format gap-1">
				<div>Font</div>
				<font-selector :font="branding.font" @font-changed="handleFontChange($event)" class="mb-2"> </font-selector>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" class="py-4">
				<hr style="border-top: 1px solid var(--v-gray_30-base); border-bottom: none;">
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" class="text-left column-format gap-1">
				<div>Favicon <span class="font-12 font-gray_70">(Best format: 32x32 pixel image in ICO or PNG format)</span></div>
				<favicon-uploader :branding="branding" @updated="$emit('refresh')"></favicon-uploader>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" class="py-4">
				<hr style="border-top: 1px solid var(--v-gray_30-base); border-bottom: none;">
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" class="text-left column-format gap-1">
				<div>Light mode colors & logo</div>
				<v-menu offset-y :close-on-content-click="false">
					<template v-slot:activator="{ on }">
						<v-btn block elevation="0" :color="branding.primaryColor" v-on="on" class="mb-2">
							Primary color
						</v-btn>
					</template>
					<div style="background-color: var(--v-white-base)">
						<v-color-picker v-model="branding.primaryColor" show-swatches mode="hexa"></v-color-picker>
					</div>
				</v-menu>
				<v-menu offset-y :close-on-content-click="false">
					<template v-slot:activator="{ on }">
						<v-btn block elevation="0" :color="branding.navColor" v-on="on">Nav bar color </v-btn>
					</template>
					<div style="background-color: var(--v-white-base)">
						<v-color-picker v-model="branding.navColor" show-swatches mode="hexa"></v-color-picker>
					</div>
				</v-menu>
				<div v-if="account" class="mt-3" :style="`border: 1px solid var(--v-gray_30-base); background-color: ${branding.navColor}; border-radius: 8px`">
					<logo-uploader :account="account" @updated="updated()" :light-mode="true"></logo-uploader>
				</div>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" class="py-4">
				<hr style="border-top: 1px solid var(--v-gray_30-base); border-bottom: none;">
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" class="text-left column-format gap-1">
				<div>Dark mode colors & logo</div>
				<v-menu offset-y :close-on-content-click="false">
					<template v-slot:activator="{ on }">
						<v-btn block elevation="0" :color="branding.primaryColorDark" v-on="on" class="mb-2">
							Dark primary color
						</v-btn>
					</template>
					<div style="background-color: var(--v-white-base)">
						<v-color-picker v-model="branding.primaryColorDark" show-swatches mode="hexa"></v-color-picker>
					</div>
				</v-menu>
				<v-menu offset-y :close-on-content-click="false">
					<template v-slot:activator="{ on }">
						<v-btn block elevation="0" :color="branding.navColorDark" v-on="on">Dark nav bar color </v-btn>
					</template>
					<div style="background-color: var(--v-white-base)">
						<v-color-picker v-model="branding.navColorDark" show-swatches mode="hexa"></v-color-picker>
					</div>
				</v-menu>
				<div v-if="account" class="mt-3" style="border: 1px solid var(--v-gray_30-base); background-color: #000; border-radius: 8px">
					<logo-uploader :account="account" @updated="updated()" :light-mode="false"></logo-uploader>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import FontSelector from '@/components/FontSelector';
	import AuthService from "@/modules/auth/AuthService";
	import AccountService from "@/modules/account/AccountService";
	import LogoUploader from "@/modules/portal/customization/LogoUploader";
	import FaviconUploader from "@/modules/portal/customization/FaviconUploader";

	export default {
		name: 'Brand',

		props: ['branding'],

		components: {FaviconUploader, LogoUploader, FontSelector },

		data: function() {
			return {
				accountService: new AccountService(),
				account: null,
				darkModeOptions: ['Automatic', 'Always', 'Never'],
			};
		},

		mounted() {
			this.getAccount();
		},

		beforeDestroy() {},

		methods: {
			getAccount: function() {
				this.accountService
					.getAccount()
					.then((res) => {
						this.account = res.data;
					})
					.catch((err) => this.$store.commit('error', err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
			},

			handleFontChange: function(font) {
				this.branding.font = font;
			},

			updated: function(){
				this.getAccount();
				this.refreshUserAndAccountTokens();
			},

			refreshUserAndAccountTokens: function () {
				AuthService.refreshToken(true).then((res) => {
					this.$store.commit('logIn', res);

					AuthService.getProfile().then((res) => {
						this.$store.dispatch('setLoggedInUser', res);
					});
				});
			},
		},

		computed: {

		},
	};
</script>

<style scoped lang="scss">
.preview-window {
	height: 500px;
	width: 100%;
	border: 1px solid var(--v-gray_50-base);
}
</style>
