import ReturnHandler from "@/modules/integrations/stripe/ReturnHandler";
import RefreshHandler from "@/modules/integrations/stripe/RefreshHandler";
import ZoomOauthHandler from "@/modules/integrations/zoom/ZoomOauthHandler";
import PlaidOAuthHandler from "@/modules/integrations/plaid/PlaidOAuthHandler";
import PayPalRefreshHandler from "@/modules/integrations/paypal/PayPalRefreshHandler";
import PayPalConnectHandler from "@/modules/integrations/paypal/PayPalConnectHandler";
import QuickbooksOauthHandler from "@/modules/integrations/quickbooks/QuickbooksOauthHandler";
import StripeRedirect from "@/modules/integrations/stripe/StripeRedirect";
import StripeOAuthHandler from "@/modules/integrations/stripe/StripeOAuthHandler";
import MicrosoftOauthHandler from "@/modules/integrations/microsoft/MicrosoftOauthHandler";
import XeroOauthHandler from "@/modules/integrations/xero/XeroOauthHandler";

export default [
	{
		path: '/stripeRedirect',
		name: 'stripe redirect',
		component: StripeRedirect,
		title: 'StripeRedirect',
		props: true,
	},
	{
		path: '/integrations/stripe/return',
		name: 'stripeReturn',
		component: ReturnHandler,
	},
	{
		path: '/integrations/stripe/refresh',
		name: 'stripeRefresh',
		component: RefreshHandler,
	},
	{
		path: '/integrations/zoom/oauth',
		name: 'zoom',
		component: ZoomOauthHandler,
		props: route => ({
			code: route.query.code
		})
	},
	{
		path: '/integrations/microsoft/oauth',
		name: 'microsoft',
		component: MicrosoftOauthHandler,
		props: route => ({
			code: route.query.code
		})
	},
	{
		path: '/integrations/stripe/oauth',
		name: 'stripeOauth',
		component: StripeOAuthHandler,
		props: route => ({
			code: route.query.code,
		})
	},
	{
		path: '/integrations/quickbooks/oauth',
		name: 'quickbooks',
		component: QuickbooksOauthHandler,
		props: route => ({
			code: route.query.code,
			realmId: route.query.realmId
		})
	},
	{
		path: '/integrations/xero/oauth',
		name: 'xero',
		component: XeroOauthHandler,
		props: route => ({
			code: route.query.code,
		})
	},
	{
		path: '/integrations/paypal/refresh',
		name: 'paypal',
		component: PayPalRefreshHandler
	},
	{
		path: '/integrations/paypal/connect',
		name: 'paypalConnect',
		component: PayPalConnectHandler,
		props: route => ({
			merchantIdInPayPal: route.query.merchantIdInPayPal,
			permissionsGranted: route.query.permissionsGranted,
			consentStatus: route.query.consentStatus
		})
	},
	{
		path: '/integrations/plaid/oauth',
		name: 'plaid',
		component: PlaidOAuthHandler,
		props: route => ({
			code: route.query.code
		})
	},
];
