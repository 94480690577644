<template>
	<div>
		<div class="row-format" style="gap: 12px">
			<v-autocomplete
					v-model="state"
					:items="states"
					item-value="abbreviation"
					item-text="name"
					label="State/Province"
					persistent-placeholder
					hide-details
					class="h-outline"
			></v-autocomplete>
			<v-text-field v-model="city" label="City" persistent-placeholder hide-details class="h-outline"></v-text-field>
			<v-text-field
					v-model="areaCode"
					label="Area code"
					persistent-placeholder
					hide-details
					class="h-outline"
			></v-text-field>
			<v-btn class="primary-action mt-1" style="min-height: 52px" @click="performSearch()">
				<v-icon color="primary">search</v-icon>
				Search numbers
			</v-btn>
		</div>
		<v-data-table :items="numbers" :headers="headers" :items-per-page="5" v-if="numbers.length">
			<template v-slot:item.action="{ item }">
				<v-btn class="primary-action" @click="confirmSelection(item)">Select number</v-btn>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import BandwidthService from "@/modules/communicator/realtime/bandwidth/BandwidthService";
import ConfirmModal from "@/components/ConfirmModal";
import countries from "@/assets/data/countries.json";

export default {
	name: "BandwidthNumbers",

	props: ['country'],

	components: {},

	data: function () {
		return {
			bandwidthService: new BandwidthService(),
			state: null,
			city: null,
			areaCode: null,
			numbers: [],
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		notAvailableOnTrial: function(){
			let binding = {
				headingText: 'Subscription required',
				bodyText: `This feature is not available on the free trial.  Please subscribe to reserve your business phone number.`,
				hideNo: true,
				yesText: 'OK'
			};
			this.$store.state.globalModalController.openModal(ConfirmModal, binding);
		},

		confirmSelection: function (number) {
			if(this.$store.getters.isInTrial){
				this.notAvailableOnTrial();
				return;
			}

			let binding = {
				headingText: 'Please confirm',
				bodyText: `Are you sure you want to reserve ${number.fullNumber} as your new business phone line?`,
			};
			this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
				if (res) {
					this.orderNumber(number.fullNumber);
				}
			});
		},

		orderNumber: function (number) {
			this.$store.commit('startLoading');
			this.bandwidthService
					.tnOrder(number)
					.then((res) => {
						this.$store.state.communicatorSubscription = res.data;
						this.$store.commit('stopLoading');
						this.$store.commit('success', 'Your new business phone line is now active!');
						this.$emit('order-complete');
					})
					.catch((err) => this.$store.commit('error',err.response.data.message))
					.finally(() => this.$store.commit('stopLoading'));
		},

		performSearch: function () {
			this.numbers.splice(0, this.numbers.length);
			this.bandwidthService
					.tnSearch(this.state, this.city, this.areaCode)
					.then((res) => {
						this.numbers.push(...res.data);
						if (this.numbers.length === 0) {
							this.$store.commit('warn', 'No phone numbers matched your search criteria.');
						}
					})
					.catch((err) => {
						let parser = new DOMParser();
						let xmlDoc = parser.parseFromString(err.response.data.message, 'text/xml');
						let errMessage = xmlDoc.getElementsByTagName('Description')[0].textContent;
						this.$store.commit('error', 'Error searching number database: [' + errMessage + ']');
					});
		},
	},

	computed: {
		headers: function () {
			let result = [
				{ text: 'Number', value: 'fullNumber' },
				{ text: 'City', value: 'city' },
				{ text: 'State', value: 'state' },
				{ text: '', value: 'action', align: 'right' },
			];

			return result;
		},

		states: function () {
			if (this.country) {
				let c = countries.find((c) => c.code === this.country);
				if (c) {
					return c.states.filter((s) => {
						if (s.abbreviation === 'AK' || s.abbreviation === 'HI') {
							return true;
						} else {
							return true;
						}
					});
				} else {
					return [];
				}
			} else {
				return [];
			}
		},
	},

}
</script>

<style scoped lang="scss">

</style>