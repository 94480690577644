var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-input" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c("label", {
            staticClass: "question",
            domProps: { innerHTML: _vm._s(_vm.htmlText) }
          }),
          _c("date-selector", {
            class: _vm.wasValidated && !_vm.ourDate ? "error-state" : "",
            attrs: { disabled: _vm.previewMode, standard: true },
            on: {
              change: function($event) {
                return _vm.setDate($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }