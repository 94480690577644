var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ml-1 pl-1",
      style:
        "color:var(--v-" +
        _vm.foregroundColor +
        "-base); height: 100%; overflow-y: hidden; overflow-x: hidden"
    },
    [
      _vm.timed && !_vm.inMoreView
        ? _c(
            "div",
            {
              class:
                "" +
                (_vm.view === "Two-Week" || _vm.view === "Week" ? "mt-1" : "")
            },
            [
              _c(
                "div",
                { staticClass: "row-format align-center" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-1",
                      attrs: { size: "12", color: _vm.foregroundColor }
                    },
                    [_vm._v("$apple")]
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.event.name))])
                ],
                1
              ),
              _c("div", { staticClass: "text-left" }, [
                _vm._v(
                  _vm._s(_vm.convertTime(_vm.orig.start)) +
                    "-" +
                    _vm._s(_vm.convertTime(_vm.orig.end))
                )
              ])
            ]
          )
        : _vm._e(),
      !_vm.timed || _vm.inMoreView
        ? _c(
            "div",
            {
              class:
                "row-format align-center " +
                (_vm.view === "Two-Week" ? "mt-1" : "")
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-1",
                  attrs: { size: "12", color: _vm.foregroundColor }
                },
                [_vm._v("$apple")]
              ),
              _c("div", { staticClass: "text-left" }, [
                _vm._v(_vm._s(_vm.event.name))
              ])
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }