var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row-format send-button align-center",
      style: "min-width: " + _vm.width + "px; min-height: 40px"
    },
    [
      _c(
        "div",
        {
          class:
            "send row-format align-center " + (_vm.disabled ? "disabled" : ""),
          on: {
            click: function($event) {
              return _vm.handleClick()
            }
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center", width: "100%" } },
            [_vm._v(_vm._s(_vm.label))]
          )
        ]
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-click": true,
            "close-on-content-click": true,
            disabled: _vm.disabled
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g(
                      {
                        class:
                          "later row-format align-center " +
                          (_vm.disabled ? "disabled" : "")
                      },
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { color: _vm.disabled ? "gray_30" : "white" }
                        },
                        [_vm._v("arrow_drop_down")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            {
              staticClass: "column-format text-left font-14",
              staticStyle: {
                width: "220px",
                "background-color": "var(--v-white-base)"
              }
            },
            [
              _c("div", { staticClass: "pt-2 pl-5 font-16 brand-medium" }, [
                _vm._v("Schedule send")
              ]),
              _vm.sendLaterOptions
                ? _c(
                    "div",
                    { staticClass: "add-new-dropdown" },
                    [
                      _vm._l(_vm.sendLaterOptions, function(option) {
                        return _c(
                          "div",
                          {
                            key: option.id,
                            staticClass: "add-new-item row-format",
                            on: {
                              click: function($event) {
                                return _vm.handleCustomSnooze(option.date)
                              }
                            }
                          },
                          [
                            _c("div", [_vm._v(_vm._s(option.label))]),
                            _c("div", { staticClass: "ml-auto" }, [
                              _vm._v(
                                " " +
                                  _vm._s(option.date.toFormat("ccc")) +
                                  " " +
                                  _vm._s(
                                    option.date.toLocaleString(
                                      _vm.HDateTime.TIME_SIMPLE
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "add-new-item row-format align-center",
                          on: { click: _vm.customSnoozeDateTime }
                        },
                        [
                          _c("div", [_vm._v("Pick date & time")]),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-auto",
                              attrs: { color: "secondary" }
                            },
                            [_vm._v("$calendarClock")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e()
            ]
          )
        ]
      ),
      _vm.customMenu
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.customMenu, persistent: false },
              on: {
                close: function($event) {
                  _vm.customMenu = false
                }
              }
            },
            [
              _c("snooze-picker", {
                on: {
                  result: function($event) {
                    return _vm.handleCustomSnooze($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }