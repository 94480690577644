var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-6" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Client management" }
            ]
          }
        },
        [_vm._v(" Contacts ")]
      ),
      _c("div", { staticClass: "column-format fill-height" }, [
        _c(
          "div",
          { staticClass: "pb-4 row-format align-center flex-wrap gap-3" },
          [
            _c("v-text-field", {
              staticStyle: {
                "max-width": "200px",
                "background-color": "var(--v-white-base)"
              },
              attrs: {
                outlined: "",
                "hide-details": "",
                dense: "",
                placeholder: "Search...",
                color: "gray_30"
              },
              scopedSlots: _vm._u([
                {
                  key: "prepend-inner",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "material-symbols-rounded font-gray_50"
                        },
                        [_vm._v("search")]
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.search,
                callback: function($$v) {
                  _vm.search = $$v
                },
                expression: "search"
              }
            }),
            _c(
              "div",
              { staticClass: "ml-auto row-format align-center gap-3" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: { content: "List" },
                        expression: "{ content: 'List' }"
                      }
                    ],
                    staticClass: "pointer row-format align-center",
                    on: {
                      click: function($event) {
                        return _vm.setView("LIST")
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "material-symbols-rounded",
                        style:
                          "font-size: 24px; color: var(--v-" +
                          (_vm.currentView === "LIST" ? "primary" : "gray_60") +
                          "-base)"
                      },
                      [_vm._v("list")]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: { content: "Card" },
                        expression: "{ content: 'Card' }"
                      }
                    ],
                    staticClass: "pointer row-format align-center",
                    on: {
                      click: function($event) {
                        return _vm.setView("CARD")
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "material-symbols-rounded",
                        style:
                          "font-size: 24px; color: var(--v-" +
                          (_vm.currentView === "CARD" ? "primary" : "gray_60") +
                          "-base)"
                      },
                      [_vm._v("grid_on")]
                    )
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action",
                    on: {
                      click: function($event) {
                        return _vm.addNew()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
                    _vm._v(" Add contact")
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("div", [
          _vm.currentView === "CARD"
            ? _c(
                "div",
                { staticClass: "row-format flex-wrap gap-3" },
                _vm._l(_vm.filteredContacts, function(contact) {
                  return _c("contact-card", {
                    key: contact.id,
                    attrs: { input: contact },
                    on: {
                      edit: function($event) {
                        return _vm.openContact(contact)
                      },
                      delete: function($event) {
                        return _vm.confirmDelete(contact)
                      },
                      "send-email": function($event) {
                        return _vm.sendEmail(contact)
                      },
                      "make-call": function($event) {
                        return _vm.makeCall(contact)
                      },
                      "send-text": function($event) {
                        return _vm.sendText(contact)
                      },
                      "book-meeting": function($event) {
                        return _vm.bookMeeting(contact)
                      }
                    }
                  })
                }),
                1
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    "background-color": "var(--v-white-base)",
                    border: "1px solid var(--v-gray_30-base)",
                    "border-radius": "4px"
                  }
                },
                [
                  _c("v-data-table", {
                    staticClass: "primary-table",
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.filteredContacts,
                      "hide-default-footer": true,
                      "items-per-page": -1
                    },
                    on: {
                      "click:row": function($event) {
                        return _vm.openContact($event)
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.name",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item.firstName) +
                                " " +
                                _vm._s(item.lastName) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.client.name",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.client
                              ? _c(
                                  "div",
                                  { staticClass: "row-format align-center" },
                                  [
                                    _c("client-avatar", {
                                      attrs: {
                                        client: item.client,
                                        "x-small": true,
                                        "disable-click": false
                                      }
                                    }),
                                    _c("div", [
                                      _vm._v(_vm._s(item.client.name))
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.action",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  bottom: "",
                                  left: "",
                                  rounded: "",
                                  "nudge-bottom": "24",
                                  "close-on-content-click": true,
                                  "close-on-click": true
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "ml-auto" },
                                            [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "menu-activator",
                                                    attrs: { icon: "" }
                                                  },
                                                  scope.on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.value
                                                          ? "$arrowUp"
                                                          : "$moreHorizontal"
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("div", { staticClass: "more-menu" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "more-menu-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.openContact(item)
                                        }
                                      }
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  item.email
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "more-menu-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.sendEmail(item)
                                            }
                                          }
                                        },
                                        [_vm._v("Send Email")]
                                      )
                                    : _vm._e(),
                                  item.phone
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "more-menu-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.sendText(item)
                                            }
                                          }
                                        },
                                        [_vm._v("Send Text")]
                                      )
                                    : _vm._e(),
                                  item.phone
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "more-menu-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.makeCall(item)
                                            }
                                          }
                                        },
                                        [_vm._v("Make Call")]
                                      )
                                    : _vm._e(),
                                  item.email
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "more-menu-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.bookMeeting(item)
                                            }
                                          }
                                        },
                                        [_vm._v("Book meeting")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "more-menu-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.confirmDelete(item)
                                        }
                                      }
                                    },
                                    [_vm._v("Delete")]
                                  )
                                ])
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "no-data",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "" },
                                on: { click: _vm.addNew }
                              },
                              [_vm._v("No contact records")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }