<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-client') " @action="navigateToClient()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small v-if="client" :client="client" />
			<div class="ml-2">{{ client.name }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ $t('notifications.client-created') }}</div>
			<div class="font-12">
				<span>{{client.name}} has been created via API - {{ notificationPayload.createdAt | formatForTimeAgo }} </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";

	export default {
		name: 'ClientCreatedNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar, NotificationLayout},

		data: function() {
			return {
				client: this.notificationPayload.client,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			navigateToClient: function() {
				this.$router.push(`/client/${this.client.id}?v=Forms`);
			},
		},

		computed: {

		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}
</style>
