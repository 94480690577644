var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticStyle: { "max-width": "55vw", "min-width": "55vw" } }, [
      _c("div", { staticClass: "tabs row-format py-2 gap-3" }, [
        _c("div", { staticClass: "row-format py-2 gap-3" }, [
          _c(
            "div",
            {
              class:
                "row-format align-center page-link " +
                (_vm.componentSelector === 0 ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  return _vm.selectTab(0)
                }
              }
            },
            [_c("div", { staticClass: "ml-1" }, [_vm._v("Calendar view")])]
          ),
          _c(
            "div",
            {
              class:
                "row-format align-center page-link " +
                (_vm.componentSelector === 1 ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  return _vm.selectTab(1)
                }
              }
            },
            [_c("div", { staticClass: "ml-1" }, [_vm._v("Question form")])]
          ),
          _c(
            "div",
            {
              class:
                "row-format align-center page-link " +
                (_vm.componentSelector === 2 ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  return _vm.selectTab(2)
                }
              }
            },
            [
              _c("div", { staticClass: "ml-1" }, [
                _vm._v("Meeting confirmation")
              ])
            ]
          ),
          _c(
            "div",
            {
              class:
                "row-format align-center page-link " +
                (_vm.componentSelector === 3 ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  return _vm.selectTab(3)
                }
              }
            },
            [_c("div", { staticClass: "ml-1" }, [_vm._v("Cancel meeting")])]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm.componentSelector === 0
            ? _c("mock-scheduler", {
                attrs: { meeting: _vm.meeting, tokenMap: _vm.tokenMapNoFake }
              })
            : _vm.componentSelector === 1
            ? _c("mock-question-form", {
                attrs: { meeting: _vm.meeting, tokenMap: _vm.tokenMap }
              })
            : _vm.componentSelector === 2
            ? _c("mock-confirmation", {
                attrs: { meeting: _vm.meeting, tokenMap: _vm.tokenMap }
              })
            : _vm.componentSelector === 3
            ? _c("mock-cancel", {
                attrs: { meeting: _vm.meeting, tokenMap: _vm.tokenMap }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }