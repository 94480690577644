var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-5" },
    [
      _c("v-textarea", {
        attrs: {
          rows: "3",
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Question"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.question,
          callback: function($$v) {
            _vm.$set(_vm.element, "question", $$v)
          },
          expression: "element.question"
        }
      }),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Placeholder"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.placeholder,
          callback: function($$v) {
            _vm.$set(_vm.element, "placeholder", $$v)
          },
          expression: "element.placeholder"
        }
      }),
      _c("v-switch", {
        staticClass: "mt-n2",
        attrs: { "hide-details": "", dense: "", label: "Required" },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.required,
          callback: function($$v) {
            _vm.$set(_vm.element, "required", $$v)
          },
          expression: "element.required"
        }
      }),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Mapping key"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.fieldName,
          callback: function($$v) {
            _vm.$set(_vm.element, "fieldName", $$v)
          },
          expression: "element.fieldName"
        }
      }),
      _c("v-select", {
        attrs: {
          items: _vm.mappings,
          "item-text": "label",
          "item-value": "value",
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Mapping"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.schemaMapping,
          callback: function($$v) {
            _vm.$set(_vm.element, "schemaMapping", $$v)
          },
          expression: "element.schemaMapping"
        }
      }),
      _c(
        "draggable",
        {
          staticClass: "column-format gap-2",
          attrs: {
            list: _vm.element.options,
            group: "elements",
            options: { handle: ".drag-handle" }
          }
        },
        _vm._l(_vm.element.options, function(option, index) {
          return _c(
            "div",
            { key: index, staticClass: "row-format align-center" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "drag-handle",
                  staticStyle: { cursor: "grab" },
                  attrs: { tabindex: "-1" }
                },
                [_vm._v("drag_indicator")]
              ),
              _c("v-text-field", {
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  value: option,
                  placeholder: "Option value"
                },
                on: {
                  input: function($event) {
                    return _vm.updateOption(index, $event)
                  }
                }
              }),
              _c(
                "v-icon",
                {
                  attrs: { tabindex: "-1" },
                  on: {
                    click: function($event) {
                      return _vm.deleteOption(index)
                    }
                  }
                },
                [_vm._v("clear")]
              )
            ],
            1
          )
        }),
        0
      ),
      _c("v-btn", { attrs: { text: "" }, on: { click: _vm.addOption } }, [
        _vm._v("+ Add option")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }