var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left" },
    [
      _vm.tickets.length
        ? _c(
            "div",
            { staticClass: "mb-5" },
            [
              _c("div", { staticClass: "font-gray_80 font-14 brand-medium" }, [
                _vm._v("Recent tickets")
              ]),
              _vm._l(_vm.tickets, function(ticket) {
                return _c(
                  "div",
                  {
                    key: ticket.id,
                    staticClass: "px-1 font-12 py-2 font-gray_80 recent",
                    on: {
                      click: function($event) {
                        return _vm.openTicket(ticket)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "gray_70", size: "20" } },
                          [_vm._v("$ticket")]
                        ),
                        _c("div", { staticClass: "ml-auto" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$formatters.formatTimeAgo(ticket.updated)
                            )
                          )
                        ])
                      ],
                      1
                    ),
                    _c("div", [_vm._v(_vm._s(ticket.subject))])
                  ]
                )
              }),
              _vm.tickets.length >= _vm.maxTickets
                ? _c(
                    "div",
                    { staticClass: "row-format centered mt-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", text: "", color: "gray_80" },
                          on: {
                            click: function($event) {
                              return _vm.showMoreTickets()
                            }
                          }
                        },
                        [_vm._v("+ Show more")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "font-gray_80 font-14 brand-medium" }, [
        _vm._v("Recent conversations")
      ]),
      _vm._l(_vm.communications, function(comm) {
        return _c(
          "div",
          {
            key: comm.id,
            staticClass: "px-1 font-12 py-2 font-gray_80 recent",
            on: {
              click: function($event) {
                return _vm.$emit("view-communication", comm)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c("v-icon", { attrs: { color: "gray_70", size: "20" } }, [
                  _vm._v(_vm._s(_vm.getIcon(comm)))
                ]),
                _c("div", { staticClass: "ml-auto" }, [
                  _vm._v(_vm._s(_vm.$formatters.formatTimeAgo(comm.date)))
                ])
              ],
              1
            ),
            _c("div", [_vm._v(_vm._s(_vm.getHeading(comm)))])
          ]
        )
      }),
      _vm.communications.length === 0
        ? _c("div", { staticClass: "font-gray_70 font-12 mt-2" }, [
            _vm._v(" No conversations found... ")
          ])
        : _vm._e(),
      _vm.communications.length >= _vm.maxRecords
        ? _c(
            "div",
            { staticClass: "row-format centered mt-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", text: "", color: "gray_80" },
                  on: {
                    click: function($event) {
                      return _vm.showMore()
                    }
                  }
                },
                [_vm._v("+ Show more")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }