var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-left",
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c("div", { staticClass: "form-question" }, [
        _vm._v(_vm._s(_vm.item.question) + " "),
        _vm.item.required
          ? _c("span", { staticClass: "font-red brand-medium" }, [_vm._v("*")])
          : _vm._e()
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c("v-file-input", {
            staticClass: "file-upload",
            attrs: {
              disabled: !_vm.editMode,
              "hide-details": "",
              dense: "",
              multiple: _vm.item.multi,
              placeholder: _vm.item.placeholder,
              outlined: _vm.outlined,
              solo: _vm.solo,
              filled: _vm.filled,
              "append-icon": "attach_file",
              accept: _vm.accept,
              rules: _vm.item.required ? _vm.fileInputRequired : []
            },
            on: { change: _vm.handleChange },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var text = ref.text
                  return [
                    _c(
                      "v-chip",
                      { attrs: { small: "", label: "", color: "primary" } },
                      [_vm._v(" " + _vm._s(text) + " ")]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.files,
              callback: function($$v) {
                _vm.files = $$v
              },
              expression: "files"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }