<template>
	<div class="column-format">
		<v-date-picker
			:first-day-of-week="$store.getters.firstDayOfWeek"
			v-model="datePart"
			no-title
			color="primary"
			:show-current="true"
		/>
		<div style="border: 1px solid var(--v-gray_30-base)" v-if="isReady">
			<vue-timepicker
				style="width: 90px"
				v-model="timePart"
				:format="format"
				hide-clear-button
				auto-scroll
				manual-input
				hide-dropdown
			></vue-timepicker>
		</div>
		<div class="my-3">
			<v-btn class="primary-action mr-1" @click="cancel">Cancel</v-btn>
			<v-btn class="super-action ml-1" width="120" @click="save">Save</v-btn>
		</div>
	</div>
</template>

<script>
	import HDateTime from '@/modules/utils/HDateTime';
	import VueTimepicker from 'vue2-timepicker';

	export default {
		name: 'SnoozePicker',

		props: [],

		components: { VueTimepicker },

		data: function () {
			return {
				datePart: HDateTime.now().toISODate(),
				timePart: HDateTime.now().plus({ hour: 2 }).set({ minute: 0, second: 0, millisecond: 0 }).toLocaleString(HDateTime.TIME_SIMPLE),
				is12HourFormat: false,
				isReady: false,
			};
		},

		mounted() {
			if (this.timePart.includes('AM') || this.timePart.includes('PM')) {
				this.is12HourFormat = true;
			}
			this.isReady = true;
		},

		beforeDestroy() {},

		methods: {
			cancel: function () {
				this.$emit('result');
			},

			save: function () {
				this.$emit('result', this.finalDateTime);
			},
		},

		computed: {
			format: function () {
				if (this.is12HourFormat) {
					return 'h:mm A';
				} else {
					return 'HH:mm';
				}
			},

			finalDateTime: function () {
				return HDateTime.fromFormat(
					this.datePart + ' ' + this.timePart,
					'yyyy-LL-dd ' + (this.is12HourFormat ? 'h:mm a' : 'HH:mm')
				);
			},
		},
	};
</script>

<style lang="scss">
	.vue__time-picker {
		.controls {
			display: none !important;
		}
	}
</style>
