<template>
	<div>
		<div class="row-format mr-2">
			<v-btn @click="createNewNote()" class="secondary-action ml-auto">+ Add note</v-btn>
		</div>
		<div class="mt-5 px-1">
			<div v-for="comment in comments" :key="comment.id" class="edit-comment my-4">
				<div class="row-format align-center font-12 pb-2">
					<assigned-user
						class="mr-3"
						:show-name="false"
						v-if="comment.authorId !== '0'"
						:assigned-to="parseInt(comment.authorId, 10)"
					></assigned-user>
					<div>
						{{ comment.author }}
					</div>
					<v-icon size="14" class="ml-3 mr-1">$clock</v-icon>
					<div style="color: var(--v-gray_70-base)">
						{{ comment.timestamp | formatForTimeAgo }}
					</div>
					<div v-if="comment.edited" class="font-italic font-12 font-gray_70 ml-4">Edited</div>
					<div class="ml-auto">
						<v-icon
							v-if="comment.id !== activeComment"
							@click.native="setActive(comment)"
							size="16"
							class="pointer material-symbols-rounded mx-1"
							>edit</v-icon
						>
						<v-icon @click.native="confirmDelete(comment)" size="16" class="pointer material-symbols-rounded"
							>$delete</v-icon
						>
					</div>
				</div>
				<div
					class="pb-2"
					style="border-bottom: 1px solid var(--v-gray_30-base)"
					v-if="comment.id !== activeComment"
					v-html="comment.comment === '' ? 'Enter a note...' : comment.comment"
				></div>
				<comment
					v-else
					v-model="comment.comment"
					:author-id="parseInt(comment.authorId, 10)"
					@input="forceSave()"
				></comment>
			</div>
		</div>
	</div>
</template>

<script>
	import { v4 as uuidv4 } from 'uuid';
	import HDateTime from '@/modules/utils/HDateTime';
	import AssignedUser from '@/components/AssignedUser';
	import ConfirmModal from '@/components/ConfirmModal';
	import marked from 'marked';
	import Comment from '@/modules/pipeline/opportunity/Comment';

	export default {
		name: 'Comments',

		props: ['opportunity'],

		components: { Comment, AssignedUser },

		data: function() {
			return {
				activeComment: null,
				activeNewNote: false,
				newComment: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			forceSave: function(){
				this.activeComment = null;
				this.$emit('force-save');
			},

			setActive: function(comment) {
				this.activeComment = comment.id;
			},

			createNewNote: function() {
				let newComment = {
					id: uuidv4(),
					author: this.$store.getters.getLoggedInUserName,
					authorId: this.$store.state.loggedInUser.userId,
					timestamp: HDateTime.now().toISO(),
					comment: '',
				};
				this.opportunity.comments.push(newComment);
				this.setActive(newComment);
			},

			confirmDelete: function(comment) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this note?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						let ix = this.opportunity.comments.findIndex((c) => c.id === comment.id);
						if (ix > -1) {
							this.opportunity.comments.splice(ix, 1);
							this.$emit('force-save');
						}
					}
				});
			},

			formatMarkdown: function(comment) {
				let m = marked(comment, { breaks: true });
				m = m.replaceAll('<a href', '<a target="_blank" href');
				return m;
			},
		},

		computed: {
			comments: function() {
				let result = [...this.opportunity.comments];

				result.forEach((n) => {
					if (n.format === 'Markdown') {
						n.comment = this.formatMarkdown(n.comment);
						n.format = 'HTML';
					}
				});

				result.reverse();
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.editor {
		color: var(--v-gray_60-base);
		border: 1px solid #000;
		padding: 10px;
		border-radius: 12px;
		border-color: var(--v-gray_50-base);
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&:hover {
			cursor: pointer;
			background-color: var(--v-gray_10-base);
		}
	}
</style>
