var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-5" },
    [
      _c("v-textarea", {
        attrs: {
          rows: "3",
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Question"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.question,
          callback: function($$v) {
            _vm.$set(_vm.element, "question", $$v)
          },
          expression: "element.question"
        }
      }),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Placeholder"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.placeholder,
          callback: function($$v) {
            _vm.$set(_vm.element, "placeholder", $$v)
          },
          expression: "element.placeholder"
        }
      }),
      _c("v-switch", {
        staticClass: "mt-n2",
        attrs: { "hide-details": "", dense: "", label: "Allow multiple files" },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.multi,
          callback: function($$v) {
            _vm.$set(_vm.element, "multi", $$v)
          },
          expression: "element.multi"
        }
      }),
      _c("v-switch", {
        staticClass: "mt-n2",
        attrs: { "hide-details": "", dense: "", label: "Required" },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.required,
          callback: function($$v) {
            _vm.$set(_vm.element, "required", $$v)
          },
          expression: "element.required"
        }
      }),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Mapping key"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.fieldName,
          callback: function($$v) {
            _vm.$set(_vm.element, "fieldName", $$v)
          },
          expression: "element.fieldName"
        }
      }),
      _c(
        "div",
        { staticClass: "column-format text-left font-14" },
        [
          _c("div", [_vm._v("Restrict file types")]),
          _c("v-checkbox", {
            attrs: {
              "hide-details": "",
              dense: "",
              value: "image/*",
              label: "Image"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.element.fileTypes,
              callback: function($$v) {
                _vm.$set(_vm.element, "fileTypes", $$v)
              },
              expression: "element.fileTypes"
            }
          }),
          _c("v-checkbox", {
            attrs: {
              "hide-details": "",
              dense: "",
              value: "video/*",
              label: "Video"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.element.fileTypes,
              callback: function($$v) {
                _vm.$set(_vm.element, "fileTypes", $$v)
              },
              expression: "element.fileTypes"
            }
          }),
          _c("v-checkbox", {
            attrs: {
              "hide-details": "",
              dense: "",
              value: "audio/*",
              label: "Audio"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.element.fileTypes,
              callback: function($$v) {
                _vm.$set(_vm.element, "fileTypes", $$v)
              },
              expression: "element.fileTypes"
            }
          }),
          _c("v-checkbox", {
            attrs: {
              "hide-details": "",
              dense: "",
              value: "application/pdf",
              label: "PDF"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.element.fileTypes,
              callback: function($$v) {
                _vm.$set(_vm.element, "fileTypes", $$v)
              },
              expression: "element.fileTypes"
            }
          }),
          _c("v-checkbox", {
            attrs: {
              "hide-details": "",
              dense: "",
              value:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
              label: "MS Excel"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.element.fileTypes,
              callback: function($$v) {
                _vm.$set(_vm.element, "fileTypes", $$v)
              },
              expression: "element.fileTypes"
            }
          }),
          _c("v-checkbox", {
            attrs: {
              "hide-details": "",
              dense: "",
              value:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword",
              label: "MS Word"
            },
            on: { change: _vm.updated },
            model: {
              value: _vm.element.fileTypes,
              callback: function($$v) {
                _vm.$set(_vm.element, "fileTypes", $$v)
              },
              expression: "element.fileTypes"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }