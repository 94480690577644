<template>
	<v-container>
		<v-row align="center">
			<v-col cols="12" align="center">
				<h2 class="pa-10">Redirecting back to PayPal on-boarding...</h2>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import PayPalService from "@/modules/integrations/paypal/PayPalService";

export default {
	name: "PayPalRefreshHandler",

	props: [],

	components: {
	},

	data: function () {
		return {
			payPalService: new PayPalService(),
		}
	},

	mounted() {
		this.payPalService.continueOnBoarding().then((res) => {
			window.location.href = res.data.actionLink;
		})
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>