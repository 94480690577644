import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class StripeIntegrationService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	updatePreferences(cardPaymentsEnabled = null, achPaymentsEnabled = null, sendSubscriptionEmails = null) {
		return this.httpClient
			.post(`/stripe/preferences`, null, {
				params: {
					cardPaymentsEnabled: cardPaymentsEnabled,
					achPaymentsEnabled: achPaymentsEnabled,
					sendSubscriptionEmails: sendSubscriptionEmails
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getConnectLink() {
		let base = document.location.protocol + '//' + document.location.host;

		let urls = {
			refreshUrl: base + '/integrations/stripe/refresh',
			returnUrl: base + '/integrations/stripe/return',
		};

		return this.httpClient
			.post(`/stripe/connect`, urls)
			.then((res) => {
				store.state.eventBus.$emit('created', 'stripe');
				return Promise.resolve(res);
			})
			.catch((err) => Promise.reject(err));
	}

	connectWithOAuthCode(code){
		return this.httpClient
			.post(`/stripe/connect/oauth?code=${code}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getBalance() {
		return this.httpClient
			.get(`/stripe/balance`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
