<template>
	<div class="pa-3" style="max-width: 950px; min-width: 800px" v-if="isReady">
		<detail-view
			:communication="communication"
			:user-emails="userEmails"
			:ticket-settings="ticketSettings"
			:email-boxes="emailBoxes"
			folder="Inbox"
			:snooze-options="snoozeOptions"
			:in-modal="true"
			:opportunity-id="opportunityId"
			@back="$emit('result')"
		></detail-view>
	</div>
</template>

<script>
	import DetailView from '@/modules/communicator/inbox/DetailView';
	import HDateTime from '@/modules/utils/HDateTime';
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';
	export default {
		name: 'CommunicationDetailModal',

		props: ['communication','opportunityId'],

		components: { DetailView },

		data: function () {
			return {
				communicatorEmailService: new CommunicatorEmailService(),
				communicatorTicketService: new CommunicatorTicketService(),
				emailBoxes: [],
				ticketSettings: null,
				isReady: false,
			};
		},

		mounted() {
			let p1 = this.getEmailBoxes();
			let p2 = this.getTicketSettings();
			Promise.all([p1,p2]).finally(() => this.isReady = true);
		},

		beforeDestroy() {},

		methods: {
			getEmailBoxes: function () {
				return new Promise((resolve, reject) => {
					this.communicatorEmailService
						.getEmailBoxes()
						.then((res) => {
							this.emailBoxes.splice(0, this.emailBoxes.length);
							this.emailBoxes.push(...res.data);
							resolve();
						})
						.catch((err) => reject(err));
				});
			},

			getTicketSettings: function () {
				return new Promise((resolve, reject) => {
					this.communicatorTicketService
						.getTicketSettings()
						.then((res) => {
							this.ticketSettings = res.data;
							resolve();
						})
						.catch((err) => reject(err));
				});
			},
		},

		computed: {
			userEmails: function () {
				return this.emailBoxes.map((e) => e.username.toLowerCase());
			},

			snoozeOptions: function () {
				let result = [];

				let now = HDateTime.now();
				let hourOfDay = now.get('hour');
				let dayOfWeek = now.get('weekday');

				if (hourOfDay < 9) {
					result.push({ label: 'Later today', date: now.set({ hour: 12, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 14) {
					result.push({ label: 'This afternoon', date: now.set({ hour: 15, minute: 0, second: 0, millisecond: 0 }) });
				}

				if (hourOfDay < 17) {
					result.push({ label: 'This evening', date: now.set({ hour: 18, minute: 0, second: 0, millisecond: 0 }) });
				}

				result.push({
					label: 'Tomorrow',
					date: now.plus({ day: 1 }).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
				});

				if (dayOfWeek < 3) {
					result.push({
						label: 'Later this week',
						date: now.set({ weekday: 5, hour: 8, minute: 0, second: 0, millisecond: 0 }),
					});
				}

				if (dayOfWeek < 6) {
					result.push({
						label: 'This weekend',
						date: now.set({ weekday: 6, hour: 8, minute: 0, second: 0, millisecond: 0 }),
					});
				}

				result.push({
					label: 'Next week',
					date: now.plus({ week: 1 }).set({ weekday: 1, hour: 8, minute: 0, second: 0, millisecond: 0 }),
				});

				return result;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
