import HttpClient from '@/services/HttpClient';

export default class SessionsService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    getStatus() {
        return this.httpClient
            .get('/sessions/status')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getUserList() {
        return this.httpClient
            .get('/sessions')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    connect(apiKey,description) {
        return this.httpClient
            .post(`/sessions/connect?apiKey=${encodeURIComponent(apiKey)}&description=${encodeURIComponent(description)}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    disconnect() {
        return this.httpClient
            .post('/sessions/disconnect')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}