<template>
	<div>
		<v-autocomplete
			v-if="attach && isReady"
			hide-details
			dense
			persistent-placeholder
			outlined
			:value="clientId"
			:items="computedItems"
			:label="labelToUse"
			:placeholder="placeholder"
			:clearable="false"
			@change="updateValue"
			:disabled="disabled"
			:attach="attach"
			prepend-inner-icon="person"
			:key="'1' + clientId"
		>
			<template v-slot:prepend-inner v-if="showAvatar">
				<client-avatar class="mr-2" v-if="selectedClient" :client="selectedClient"></client-avatar>
			</template>
			<template v-slot:item="data">
				<div v-html="data.item.text" class="autocomplete-custom-item ellipsis"></div>
			</template>
		</v-autocomplete>
		<v-autocomplete
			v-else-if="isReady"
			hide-details
			dense
			persistent-placeholder
			outlined
			:value="clientId"
			:items="computedItems"
			:label="labelToUse"
			:placeholder="placeholder"
			:clearable="false"
			@change="updateValue"
			:disabled="disabled"
			prepend-inner-icon="person"
			:key="'1' + clientId"
		>
			<template v-slot:prepend-inner v-if="showAvatar">
				<client-avatar  v-if="selectedClient" :small="true" :client="selectedClient"></client-avatar>
			</template>
			<template v-slot:item="data">
				<div v-html="data.item.text" class="autocomplete-custom-item ellipsis"></div>
			</template>
		</v-autocomplete>
	</div>
</template>

<script>
	import ClientService from '../clients/ClientService';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'SelectClient',
		components: { ClientAvatar },
		props: {
			value: { type: String },
			label: { type: String },
			noLabel: { type: Boolean, default: false },
			placeholder: { type: String },
			prependIcon: { type: String },
			dense: { type: Boolean, default: false, required: false },
			disabled: { type: Boolean, default: false, required: false },
			attach: { type: String, default: null, required: false },
			hideArchive: { type: Boolean, default: false, required: false },
			clearable: { type: Boolean, default: true, required: false },
			showAvatar: { type: Boolean, default: true, required: false },
		},

		//props: ['value', 'label', 'placeholder', 'prependInnerIcon', 'prependIcon'],

		data: function () {
			return {
				clientService: new ClientService(),
				clientList: null,
				clientId: null,
				items: [],
				isReady: false,
				labelToUse: '',
				icon: '$contact',
				prepend: '',
				prependInner: '',
			};
		},

		mounted() {
			this.labelToUse = this.noLabel ? '' : this.label ? this.label : this.$t('client.select-a-client');
			this.placeholderToUse = this.placeholder ? this.placeholder : this.$t('client.select-a-client');
			this.getClientList();
			this.clientId = this.value;
			if (this.prependInnerIcon) this.prependInner = this.prependInnerIcon;
			if (this.prependIcon) this.prepend = this.prependIcon;
		},

		methods: {
			getClientList() {
				this.clientService
					.getClientList()
					.then((res) => {
						this.clientList = res.data;

						// this.items.push({ text: '-- Clear --', value: '', disabled: false });

						for (let i = 0; i < this.clientList.length; i++) {
							// Show client, even if it's archived, if it's the current selection
							if (!this.hideArchive || !this.clientList[i].archive || this.clientList[i].id === this.value) {
								this.items.push({
									text: this.clientList[i].name,
									value: this.clientList[i].id,
									disabled: false,
								});
							}
						}
						this.items.sort(function (a, b) {
							return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
						});
						this.isReady = true;
						this.$emit('ready', true);
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
			updateValue: function (event) {
				this.clientId = event;
				if (event === '') {
					this.$emit('input', null);
				} else {
					this.$emit('input', event);
				}
			},
		},

		computed: {
			computedItems: function () {
				let items = [...this.items];
				if (this.clientId && this.clearable) {
					items.unshift({ text: '-- Clear --', value: null, disabled: false });
				}
				return items;
			},

			selectedClient: function () {
				if (this.clientList) {
					return this.clientList.find((c) => c.id === this.clientId);
				} else {
					return null;
				}
			},
		},

		watch: {
			value: function (n) {
				this.clientId = n;
			},
		},
	};
</script>

<style scoped lang="scss">
	.autocomplete-custom-item ::v-deep {
		//border: 1px solid red;
		max-width: 320px !important;
	}
</style>
