var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        {
          staticClass: "pa-6 fill-all stratos column-format show-scrollbar",
          staticStyle: {
            "background-color": "var(--v-white-base)",
            "max-height": "var(--vh)",
            "overflow-y": "auto"
          }
        },
        [
          _c("div", { staticClass: "row-format align-center" }, [
            _c("img", {
              staticStyle: { "max-width": "100px" },
              attrs: {
                src:
                  "/images/logo/logo-" +
                  (_vm.$vuetify.theme.dark ? "white" : "full") +
                  ".png"
              }
            })
          ]),
          _c(
            "div",
            {
              staticClass: "mt-1 mb-3",
              staticStyle: {
                width: "100%",
                "min-height": "4px",
                height: "4px",
                "background-color": "var(--v-gray_30-base)",
                "border-radius": "4px"
              }
            },
            [
              _c("div", {
                style:
                  "height: 100%; width: " +
                  _vm.width +
                  "%; background-color: var(--v-accent-base)"
              })
            ]
          ),
          _c(
            "div",
            { staticStyle: { "flex-grow": "1" } },
            [
              _vm.stage === "UserInfo"
                ? _c("user-info-capture", {
                    attrs: { "user-info": _vm.userInfo },
                    on: {
                      next: function($event) {
                        return _vm.userInfoCaptured($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm.stage === "Intention"
                ? _c("intentions", {
                    attrs: { intention: _vm.userInfo.intention },
                    on: {
                      next: function($event) {
                        return _vm.intentionCaptured($event)
                      },
                      back: function($event) {
                        return _vm.previousStage()
                      }
                    }
                  })
                : _vm._e(),
              _vm.stage === "Carousel"
                ? _c("carousel", {
                    attrs: {
                      "initial-index": _vm.carouselIndex,
                      "user-info": _vm.userInfo
                    },
                    on: {
                      back: function($event) {
                        return _vm.previousStage()
                      },
                      next: function($event) {
                        return _vm.carouselComplete()
                      },
                      "current-index": function($event) {
                        return _vm.setCarouselIndex($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm.stage === "LetsGo"
                ? _c("lets-go", {
                    attrs: { "user-info": _vm.userInfo },
                    on: {
                      back: function($event) {
                        return _vm.previousStage()
                      },
                      next: function($event) {
                        return _vm.onBoardingComplete($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }