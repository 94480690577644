<template>
	<div>
		<v-snackbar v-model="show" color="primary" :timeout="30000" :bottom="true" style="font-size: 1.1em">
			{{ snackbarLabel }}
			<v-btn dark outlined @click="routeTo()" class="ml-2"> {{ buttonLabel }}</v-btn>
			<v-btn icon text class="ml-4" @click="close()"><v-icon>close</v-icon></v-btn>
		</v-snackbar>
	</div>
</template>

<script>
	export default {
		name: 'ObjectCreatedHandler',

		props: [],

		components: {},

		data: function () {
			return {
				show: false,
				type: null,
				client: null,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('timer-event-created', this.timerEventCreated);
			this.$store.state.eventBus.$on('project-created', this.projectCreated);
			this.$store.state.eventBus.$on('proposal-created', this.proposalCreated);
			this.$store.state.eventBus.$on('invoice-created', this.invoiceCreated);
			this.$store.state.eventBus.$on('deliverable-created', this.deliverableCreated);
			this.$store.state.eventBus.$on('expense-created', this.expenseCreated);
		},

		beforeDestroy() {},

		methods: {
			routeTo: function () {
				if (this.type === 'Projects' || this.type === 'Deliverables') {
					this.$router.push('/projects');
				} else if (this.type === 'Expenses') {
					this.$router.push('/accounting/expenses');
				} else if (this.type === 'Time' && !this.client) {
					this.$router.push('/home?v=Calendar');
				} else {
					this.$router.push(`/client/${this.client.id}?v=${this.type}`);
				}
				this.close();
			},

			close: function () {
				this.show = false;
				this.type = null;
				this.client = null;
			},

			timerEventCreated: function (timerEvent) {
				this.type = 'Time';
				this.client = this.$store.getters.clients.find((c) => c.id === timerEvent.clientId);
				this.show = true;
			},

			projectCreated: function (project) {
				this.type = 'Projects';
				this.client = this.$store.getters.clients.find((c) => c.id === project.clientId);
				this.show = true;
			},

			deliverableCreated: function () {
				this.type = 'Deliverables';
				this.client = null;
				this.show = true;
			},

			proposalCreated: function (proposal) {
				this.type = 'Agreements';
				this.client = this.$store.getters.clients.find((c) => c.id === proposal.clientId);
				this.show = true;
			},

			expenseCreated: function () {
				this.type = 'Expenses';
				this.client = null;
				this.show = true;
			},

			invoiceCreated: function (invoice) {
				this.client = this.$store.getters.getClientById(invoice.clientId);
				if (this.client) {
					this.type = 'Invoices';
					this.show = true;
				}
			},
		},

		computed: {
			snackbarLabel: function () {
				if (this.type === 'Projects') {
					return `The project for ${this.clientName} has been created.`;
				} else if (this.type === 'Deliverables') {
					return `Project deliverable has been created.`;
				} else if (this.type === 'Expenses') {
					return `Expense has been created.`;
				} else if (this.type === 'Invoices') {
					return `The invoice for ${this.clientName} has been created.`;
				} else if (this.type === 'Time' && this.client) {
					return `The time entry for ${this.clientName} has been created.`;
				} else if (this.type === 'Time') {
					return `The time entry has been created.`;
				} else if (this.type === 'Agreements') {
					return `The agreement for ${this.clientName} has been created.`;
				} else {
					return null;
				}
			},

			buttonLabel: function () {
				if (this.type === 'Projects' || this.type === 'Deliverables') {
					return `View projects now.`;
				} else if (this.type === 'Expenses') {
					return `View expenses now.`;
				} else if (this.type === 'Invoices') {
					return `View invoices now.`;
				} else if (this.type === 'Time') {
					return `View time worked now.`;
				} else if (this.type === 'Agreements') {
					return `View agreements now.`;
				} else {
					return null;
				}
			},

			clientName: function () {
				if (this.client) {
					return this.client.name;
				} else {
					return null;
				}
			},
		},

		watch: {
			show: function (newVal) {
				if (!newVal) {
					this.close();
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
