import HttpClient from '@/services/HttpClient';

export default class MeetingService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    async getMeetingList() {
        return this.httpClient.get('/meetings')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getMeetingCount() {
        return this.httpClient.get('/meetings/count')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getMeeting(id) {
        return this.httpClient.get(`/meetings/${id}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getMeetingPreview(id,start,end) {
        return this.httpClient.get(`/meetings/${id}/preview?start=${start}&end=${end}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    initializeMeeting(name,uniqueMeetingId) {
        let timezone = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
        name = encodeURIComponent(name);
        uniqueMeetingId = encodeURIComponent(uniqueMeetingId);

        return this.httpClient.post(`/meetings/initialize?name=${name}&timezone=${timezone}&uniqueMeetingId=${uniqueMeetingId}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    createMeeting(meeting) {
        console.log(meeting);
        return this.httpClient.post('/meetings',meeting)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    updateMeeting(id, data) {
        return this.httpClient.put(`/meetings/${id}`, data)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    patchMeeting(id, data) {
        return this.httpClient.patch(`/meetings/${id}`, data)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    deleteMeeting(id) {
        return this.httpClient.delete('/meetings/' + id)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }


}
