import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class DeliverableAttachmentService {

	constructor(deliverablePackage,deliverable) {
		this.deliverablePackage = deliverablePackage;
		this.deliverable = deliverable;
		this.httpClient = new HttpClient();
	}

	/**
	 * As per the contract of the generic FileAttachment component, we must implement the following methods:
	 *
	 * fileService.deleteFile(fileName);
	 * fileService.getSignedUrl(fileName);
	 * fileService.getPreviewUrl(fileName);
	 * fileService.getUploadUrl();
	 */

	deleteFile(fileName) {
        return this.httpClient
            .delete(`/templates/deliverablePackages/${this.deliverablePackage.id}/files?deliverableId=${this.deliverable.id}&fileName=${encodeURIComponent(fileName)}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

	getSignedUrl(fileName) {
		return this.httpClient
			.get(`/templates/deliverablePackages/${this.deliverablePackage.id}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getPreviewUrl(file) {
		return file.fileIconUrl;
	}

	getUploadUrl() {
		return `${store.getters.getBaseUrl}/templates/deliverablePackages/${this.deliverablePackage.id}/files?deliverableId=${this.deliverable.id}`;
	}
}
