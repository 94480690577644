import AgreementSchemaTypes from "@/modules/agreements/AgreementSchemaTypes.json";
import {v4 as uuid, v4 as uuid4} from "uuid";
import {DateTime} from "luxon";
import ConfirmModal from "@/components/ConfirmModal";

export default {
    data: function () {
        return {
            undoEnabled: true,
            undoStack: [],
            redoStack: [],
            refreshKey: 0,
            tab: 'Pages',
            editItem: null,
            editPage: null,
            schemaTypes: AgreementSchemaTypes,
            zoom: 100,
            agreement: null,
            undoing: false,
            mceConfig: {
                menubar: false,
                inline: true,
                paste_as_text: false,
                paste_data_images: true,
                table_style_by_css: true,
                browser_spellcheck: true,
                statusbar: false,
                placeholder: 'Enter text...',
                forced_root_block: 'div',
                plugins: ['paste', 'lists', 'advlist', 'link', 'table', 'autoresize', 'media', 'code'],
                contextmenu: 'paste | link image | table',
                indentation: '12pt',
                skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
                content_css: this.$vuetify.theme.dark ? 'dark' : '',

                fontsize_formats: '10px 12px 14px 16px 18px 20px 22px 24px 28px 32px 38px 40px 48px 60px 72px',
                toolbar: [
                    'fontsizeselect styleselect forecolor | bold italic underline |  bullist numlist',
                    'blockquote alignleft aligncenter alignright alignjustify indent outdent | tokenbutton | ai | table link unlink media code',
                ],
                style_formats: [
                    { title: this.$t('proposal.paragraph'), format: 'p' },
                    { title: this.$t('proposal.title'), format: 'h1' },
                    { title: this.$t('proposal.heading'), format: 'h2' },
                    { title: this.$t('proposal.subheading'), format: 'h3' },
                    { title: this.$t('proposal.code'), format: 'code' },
                ],
                table_toolbar:
                    'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
            },
        };
    },

    methods: {
        setUndoEnabled: function(val){
            this.undoEnabled = val;
        },

        checkUndo: function(event){
            if(!this.undoEnabled){
                return;
            }

            if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
                event.preventDefault();
                this.undo();
            }else if ((event.ctrlKey || event.metaKey) && event.key === 'y') {
                event.preventDefault();
                this.redo();
            }
        },

        saveState() {
            this.undoStack.push(JSON.stringify(this.agreement));
            this.redoStack.splice(0);

            if(this.undoStack.length > 50){
                this.undoStack.shift();
            }

            this.$store.state.eventBus.$emit('save-agreement-state');
        },

        undo() {
            if (this.undoStack.length > 0) {
                if(this.redoStack.length === 0 && this.undoStack.length > 1){
                    this.undoStack.pop(); //discard top of stack
                }

                this.redoStack.push(JSON.stringify(this.agreement));
                this.replaceState(JSON.parse(this.undoStack.pop()));
                this.doneEditingItem();

                if(this.undoStack.length === 0){
                    this.undoStack.push(JSON.stringify(this.agreement));
                }

                this.refreshKey++;
            }
        },

        redo() {
            if (this.redoStack.length > 0) {
                this.undoStack.push(JSON.stringify(this.agreement));
                this.replaceState(JSON.parse(this.redoStack.pop()));
                this.doneEditingItem();
                this.refreshKey++;
            }
        },

        replaceState: function(previousVersion){
            this.agreement.pages.splice(0);
            this.agreement.pages.push(... previousVersion.pages);
        },

        addPage: function() {
            let page = {
                id: uuid4(),
                schema: [],
            };

            this.agreement.pages.push(page);
            this.saveState();
        },


        updateSigners: function(signers) {
            this.agreement.signers.splice(0);
            this.agreement.signers.push(...signers);
        },

        containerItemUpdated: function(page, event) {
            let container = event.container;
            let item = event.item;
            let index = event.index;

            item.containerTarget = {
                containerId: container.id,
                index: index,
            };

            this.itemUpdated(page, item);
        },

        containerItemClicked: function(page, event) {
            let container = event.container;
            let item = event.item;
            let index = event.index;

            item.containerTarget = {
                containerId: container.id,
                index: index,
            };

            this.setActiveItem(page, item);
        },

        containerItemSigned: function(page, event) {
            let container = event.container;
            let item = event.item;
            let index = event.index;

            item.containerTarget = {
                containerId: container.id,
                index: index,
            };

            this.itemSigned(page, item);
        },

        setActiveItem: function(page, item) {
            this.editPage = page;

            if (page) {
                this.agreementBuilderState.activePage = page.id;
            } else {
                this.agreementBuilderState.activePage = null;
            }

            if (item) {
                this.tab = 'Elements';
                this.editItem = item;
                this.agreementBuilderState.activeElementId = item.id;

                if (item.containerTarget) {
                    this.agreementBuilderState.activeContainerId = item.containerTarget.containerId;
                } else {
                    this.agreementBuilderState.activeContainerId = null;
                }
            } else {
                this.editItem = null;
                this.agreementBuilderState.activeElementId = null;
                this.agreementBuilderState.activeContainerId = null;
                this.undoEnabled = true;
            }

            this.agreement.pages.forEach((p) => {
                p.schema.forEach((s) => {
                    let element = document.getElementById('schemaWrapper-' + s.id);
                    if (element) {
                        element.classList.remove('border-top');
                    }
                });
            });
        },

        dragStart: function(ev) {
            let payload = {
                type: 'New',
                id: ev.target.id,
            };

            let type = this.getTypeById(ev.target.id);
            if (type) {
                payload.props = type.props;
            }

            ev.dataTransfer.setData('text', JSON.stringify(payload));
        },

        zoomIn: function() {
            this.zoom = this.zoom + 10;
        },

        zoomOut: function() {
            if (this.zoom > 10) {
                this.zoom = this.zoom - 10;
            }
        },

        getTypeById: function(id) {
            for (let i = 0; i < this.schemaTypes.length; i++) {
                for (let j = 0; j < this.schemaTypes[i].types.length; j++) {
                    if (this.schemaTypes[i].types[j].type === id) {
                        return this.schemaTypes[i].types[j];
                    }
                }
            }
            return null;
        },

        itemUpdated: function(page, item) {
            let pageIx = this.agreement.pages.findIndex((p) => p.id === page.id);
            let schema = this.agreement.pages[pageIx].schema;
            this.editItem = item;

            if (item.containerTarget) {
                let containerIx = schema.findIndex((i) => i.id === item.containerTarget.containerId);
                let column = item.containerTarget.index;
                if (containerIx > -1) {
                    let container = schema[containerIx];
                    let ix = container.columns[column].items.findIndex((i) => i.id === item.id);
                    if (ix > -1) {
                        container.columns[column].items.splice(ix, 1, item);
                        schema.splice(containerIx, 1, container);
                    }
                }
            } else {
                let ix = schema.findIndex((i) => i.id === item.id);
                if (ix > -1) {
                    schema.splice(ix, 1, item);
                }
            }
            //console.log(this.$refs['page-' + pageIx]);
            this.saveState();
        },

        itemSigned: function(page, item) {
            let signer = item.signer;
            let signature = item.signature;

            let signerIx = this.agreement.signers.findIndex((s) => s.id === signer.id);
            let signerRecord = this.agreement.signers[signerIx];
            signerRecord.signature = signer.signature;
            signerRecord.signatureType = signer.signatureType;
            signerRecord.initials = signer.initials;
            signerRecord.title = signer.title;
            signerRecord.timestamp = DateTime.now().toISO();
            this.agreement.signers.splice(signerIx, 1, signerRecord);

            if (signature.signedBy.indexOf(signer.id) === -1) {
                signature.signedBy.push(signer.id);
                //this.itemUpdated(page, signature);
            }
            this.saveState();
        },

        confirmDelete: function(editItem = this.editItem) {
            let binding = {
                headingText: 'Delete?',
                bodyText: 'Are you sure you want to delete this item?',
            };
            this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
                if (res) {
                    this.deleteItem(editItem);
                    this.doneEditingItem();
                }
            });
        },

        deleteItem: function(item) {
            let pageIx = this.agreement.pages.findIndex((p) => p.id === this.editPage.id);
            let schema = this.agreement.pages[pageIx].schema;

            if (item.containerTarget) {
                let containerIx = schema.findIndex((i) => i.id === item.containerTarget.containerId);
                let column = item.containerTarget.index;
                if (containerIx > -1) {
                    let container = schema[containerIx];
                    let ix = container.columns[column].items.findIndex((i) => i.id === item.id);
                    if (ix > -1) {
                        container.columns[column].items.splice(ix, 1);
                    }
                }
            } else {
                let ix = schema.findIndex((i) => i.id === item.id);
                if (ix > -1) {
                    schema.splice(ix, 1);
                }
            }
            this.saveState();
        },

        updateServicesIds(item){
            if(item.type === 'Services'){
                for(let i=0; i < item.packages.length; i++){
                    item.packages[i].id = uuid();
                }
            }
        },

        duplicate: function() {
            let newItem = JSON.parse(JSON.stringify(this.editItem));
            newItem.id = uuid();

            if (newItem.type === 'Container') {
                for (let i = 0; i < newItem.columns.length; i++) {
                    for (let j = 0; j < newItem.columns[i].items.length; j++) {
                        newItem.columns[i].items[j].id = uuid();
                        this.updateServicesIds(newItem.columns[i].items[j])
                    }
                }
            }

            this.updateServicesIds(newItem);

            let pageIx = this.agreement.pages.findIndex((p) => p.id === this.editPage.id);
            let schema = this.agreement.pages[pageIx].schema;

            if (this.editItem.containerTarget) {
                let containerIx = schema.findIndex((i) => i.id === this.editItem.containerTarget.containerId);
                let column = this.editItem.containerTarget.index;
                if (containerIx > -1) {
                    let container = schema[containerIx];
                    let ix = container.columns[column].items.findIndex((i) => i.id === this.editItem.id);
                    if (ix > -1) {
                        container.columns[column].items.splice(ix+1, 0,newItem);
                    }
                }
            } else {
                let ix = schema.findIndex((i) => i.id === this.editItem.id);
                if (ix > -1) {
                    schema.splice(ix+1, 0,newItem);
                }
            }
            this.saveState();
        },

        doneEditingItem: function() {
            if (this.agreementBuilderState.activeContainerId) {
                let pageIx = this.agreement.pages.findIndex((p) => p.id === this.editPage.id);
                let item = this.agreement.pages[pageIx].schema.find(
                    (i) => i.id === this.agreementBuilderState.activeContainerId
                );
                this.setActiveItem(this.agreement.pages[pageIx], item);
            } else {
                this.setActiveItem(null, null);
                this.tab = 'Elements';
            }
            this.undoEnabled = true;
        },

        scrollToPage: function(index) {
            document.getElementById(`page-${index}`).scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
    },

    computed: {
        signer: function() {
            return this.agreement.signers.find(
                (s) => s.email !== null && s.email.toLowerCase() === this.$store.state.loggedInUser.email.toLowerCase()
            );
        },

        currentUserEmail: function() {
            return this.$store.state.loggedInUser.email;
        },

        agreementBuilderState: function() {
            return this.$store.state.agreementBuilderState;
        },

        styles: function() {
            let body = this.agreement.styles.body;
            let h1 = this.agreement.styles.h1;
            let h2 = this.agreement.styles.h2;
            let h3 = this.agreement.styles.h3;

            let baseCSS = `--primary-brand-color: ${this.$store.getters.getCustomBranding.primaryColor}; --canvas-color: ${this.agreement.styles.canvasColor}; --page-color: ${this.agreement.styles.pageColor}; `;
            let bodyCSS = `--body-size: ${body.fontSize}px; --body-weight: ${body.fontWeight}; --body-font: "${body.fontFamily}"; --body-color: ${body.color}; --body-height: ${body.lineHeight}; `;
            let h1CSS = `--h1-size: ${h1.fontSize}px; --h1-weight: ${h1.fontWeight}; --h1-font: "${h1.fontFamily}"; --h1-color: ${h1.color}; --h1-height: ${h1.lineHeight};  `;
            let h2CSS = `--h2-size: ${h2.fontSize}px; --h2-weight: ${h2.fontWeight}; --h2-font: "${h2.fontFamily}"; --h2-color: ${h2.color}; --h2-height: ${h2.lineHeight};  `;
            let h3CSS = `--h3-size: ${h3.fontSize}px; --h3-weight: ${h3.fontWeight}; --h3-font: "${h3.fontFamily}"; --h3-color: ${h3.color}; --h3-height: ${h3.lineHeight};  `;

            return baseCSS + bodyCSS + h1CSS + h2CSS + h3CSS;
        },
    }
}