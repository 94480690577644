var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    staticStyle: {
      "max-width": "150px",
      "background-color": "var(--v-white-base)"
    },
    attrs: {
      "hide-details": "",
      dense: "",
      outlined: "",
      placeholder: "Search..."
    },
    on: { input: _vm.updated },
    scopedSlots: _vm._u([
      {
        key: "prepend-inner",
        fn: function() {
          return [
            _c(
              "span",
              { staticClass: "material-symbols-rounded font-gray_50" },
              [_vm._v("search")]
            )
          ]
        },
        proxy: true
      }
    ]),
    model: {
      value: _vm.filter,
      callback: function($$v) {
        _vm.filter = $$v
      },
      expression: "filter"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }