<template>
	<div class="column-format text-left font-14 font-gray_70">
		<div class="font-gray_90 font-16">Visible Columns</div>
		<div class="row-format gap-3">
			<v-switch
				label="Quantity"
				v-model="element.enabledColumns.quantity"
				hide-details
				dense
				style="margin-top: 0"
				@change="updated()"
			></v-switch>
			<v-switch
				label="Rate"
				v-model="element.enabledColumns.rate"
				hide-details
				dense
				style="margin-top: 0"
				@change="updated()"
			></v-switch>
			<v-switch
				label="Tax"
				v-model="element.enabledColumns.tax"
				hide-details
				dense
				style="margin-top: 0"
				@change="updated()"
			></v-switch>
		</div>
		<hr />

		<v-switch
			label="Enable detailed time entries on invoice"
			v-model="element.showTimeDetails"
			hide-details
			dense
			style="margin-top: 8px"
			@change="updated()"
		></v-switch>

		<v-switch
				label="Show time in HH:MM format"
				v-model="element.showTimeInHourMinute"
				hide-details
				dense
				style="margin-top: 8px"
				@change="updated()"
		></v-switch>

		<v-switch
				label="Round quantity to 2 decimals"
				v-model="element.roundQty"
				hide-details
				dense
				style="margin-top: 8px"
				@change="updated()"
		></v-switch>

		<hr />

		<div class="font-gray_90 font-16 mt-3">Layout</div>
		<v-select
			class="mt-3"
			:items="['Compact', 'Standard', 'Roomy']"
			v-model="element.size"
			hide-details
			dense
			outlined
			persistent-placeholder
			label="Size"
			@change="updated()"
		></v-select>
		<div class="column-format gap-6 mt-4 pt-4 pb-3 px-2" style="border: 1px solid var(--v-gray_30-base); border-radius: 4px">
			<div class="row-format gap-1">
				<v-text-field
					type="number"
					min="0"
					max="20"
					v-model="element.pl"
					label="Padding left"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
				<v-text-field
					type="number"
					min="0"
					max="20"
					v-model="element.pr"
					label="Padding right"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
			</div>
			<div class="row-format gap-1">
				<v-text-field
					type="number"
					min="0"
					max="20"
					v-model="element.pt"
					label="Padding top"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
				<v-text-field
					type="number"
					min="0"
					max="20"
					v-model="element.pb"
					label="Padding bottom"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
			</div>
		</div>

		<hr />

		<div class="font-gray_90 font-16 mt-3">Button styling</div>
		<div class="row-format">
			<div style="width:50%" class="pr-1">
				<div class="font-14 text-left font-gray_70 mt-1">Button color</div>
				<v-menu :close-on-click="false" :close-on-content-click="false" v-model="buttonColorMenu" bottom>
					<template v-slot:activator="{ on }">
						<div
							v-on="on"
							:style="
								`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.buttonColor}`
							"
							class="pointer"
						>
							&nbsp;
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
						<v-color-picker
							mode="hexa"
							v-model="element.buttonColor"
							show-swatches
							swatches-max-height="150px"
							@input="updated"
						></v-color-picker>
						<v-btn @click="buttonColorMenu = false" block>OK</v-btn>
					</div>
				</v-menu>
			</div>
			<div style="width:50%" class="pl-1">
				<div class="font-14 text-left font-gray_70 mt-1">Button font color</div>
				<v-menu :close-on-click="false" :close-on-content-click="false" v-model="buttonFontColorMenu" bottom>
					<template v-slot:activator="{ on }">
						<div
							v-on="on"
							:style="
								`border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.buttonTextColor}`
							"
							class="pointer"
						>
							&nbsp;
						</div>
					</template>
					<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
						<v-color-picker
							mode="hexa"
							v-model="element.buttonTextColor"
							show-swatches
							swatches-max-height="150px"
							@input="updated"
						></v-color-picker>
						<v-btn @click="buttonFontColorMenu = false" block>OK</v-btn>
					</div>
				</v-menu>
			</div>
		</div>

		<div class="row-format align-center mt-4">
			<icon-selector v-model="element.payNowIcon" @input="updated"></icon-selector>
			<v-text-field
				v-model="element.payNowText"
				hide-details
				dense
				persistent-placeholder
				outlined
				label="Pay now button text"
				@input="updated"
			></v-text-field>
		</div>

		<div class="row-format gap-2 pt-4">
			<v-select
				style="width: 48%"
				v-model="element.buttonStyle"
				:items="['Normal', 'Outlined', 'Text']"
				hide-details
				outlined
				dense
				label="Button style"
				@change="updated()"
			>
			</v-select>
			<v-select
				style="width: 48%;"
				v-model="element.buttonSize"
				:items="['X-Small', 'Small', 'Normal', 'Large', 'X-Large']"
				hide-details
				outlined
				dense
				label="Button size"
				@change="updated()"
			>
			</v-select>
		</div>

		<div class="row-format gap-2 mt-3">
			<v-select
				v-model="element.buttonAlignment"
				:items="alignment"
				item-text="label"
				item-value="value"
				hide-details
				dense
				outlined
				label="Button alignment"
				@change="updated()"
			></v-select>
		</div>

		<hr />

		<div class="font-gray_90 font-16 mt-3">Labels</div>
		<div class="column-format gap-6 mt-3 pt-4 pb-3 px-2" style="border: 1px solid var(--v-gray_30-base); border-radius: 4px">
			<div class="row-format gap-1">
				<v-text-field
					v-model="element.labels.description"
					label="Description"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
				<v-text-field
					v-model="element.labels.quantity"
					label="Quantity"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
			</div>
			<div class="row-format gap-1">
				<v-text-field v-model="element.labels.rate" label="Rate" dense hide-details @input="updated()"></v-text-field>
				<v-text-field v-model="element.labels.tax" label="Tax" dense hide-details @input="updated()"></v-text-field>
			</div>
			<div class="row-format gap-1">
				<v-text-field v-model="element.labels.total" label="Total" dense hide-details @input="updated()"></v-text-field>
				<v-text-field
					v-model="element.labels.subTotal"
					label="Sub total"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
			</div>
			<div class="row-format gap-1">
				<v-text-field
					v-model="element.labels.discount"
					label="Discount"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
				<v-text-field
					v-model="element.labels.processingFee"
					label="Processing fee"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
			</div>
			<div class="row-format gap-1">
				<v-text-field
					v-model="element.labels.creditApplied"
					label="Credit applied"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
				<v-text-field
					v-model="element.labels.lateFee"
					label="Late fee"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
			</div>
			<div class="row-format gap-1">
				<v-text-field
					v-model="element.labels.payments"
					label="Payments"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
				<v-text-field
					v-model="element.labels.balance"
					label="Balance due"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
			</div>
			<div class="row-format gap-1">
				<v-text-field
					v-model="element.labels.paymentTotal"
					label="Payment total"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
				<v-text-field
					v-model="element.labels.amountDue"
					label="Amount due"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
			</div>
			<div class="row-format gap-1">
				<v-text-field
					v-model="element.labels.paymentPlan"
					label="Payment plan"
					dense
					hide-details
					@input="updated()"
				></v-text-field>
			</div>
		</div>
	</div>
</template>

<script>
	import IconSelector from '@/components/IconSelector';

	export default {
		name: 'LineItemsConfig',

		props: ['item'],

		components: { IconSelector },

		data: function() {
			return {
				element: { ...this.item },
				buttonColorMenu: false,
				buttonFontColorMenu: false,
				alignment: [
					{ label: 'Left', value: 'left' },
					{ label: 'Right', value: 'right' },
					{ label: 'Center', value: 'center' },
					{ label: 'Block', value: 'block' },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	hr {
		margin-top: 16px;
		outline: none;
		border-top: 1px solid var(--v-black-base);
	}
</style>
