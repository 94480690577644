<template>
	<div class="row-format gap-2 font-14 pointer" v-if="project" @click="openProject">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" v-tippy="{ content: `Project` }">
			folder_special
		</v-icon>
		<div>
			{{ project.name }}
			<span v-if="project.dueDate">| Due: {{ HDateTime.fromISO(project.dueDate).toLocaleString(HDateTime.DATE_MED) }}</span>
		</div>
		<div class="ml-auto font-gray_80 font-12 nowrap">{{ item.timestamp | formatForTimeAgo }}</div>
	</div>
</template>

<script>
	import ProjectService from '@/modules/projects/ProjectService';
	import ProjectEdit from '@/modules/projects/detail/ProjectEdit';
	import HDateTime from '@/modules/utils/HDateTime';

	export default {
		name: 'Project',

		props: ['item'],

		components: {},

		data: function() {
			return {
				HDateTime: HDateTime,
				project: null,
				projectService: new ProjectService(),
			};
		},

		mounted() {
			this.projectService
				.getProjectDetail(this.item.itemId)
				.then((res) => (this.project = res.data))
				.catch((err) => {
					if (err.response.status === 404) {
						this.$emit('deleted');
					}
				});
		},

		beforeDestroy() {},

		methods: {
			openProject: function() {
				let binding = {
					projectId: this.project.id,
				};
				this.$store.state.globalModalController.openModal(ProjectEdit, binding).then((res) => {
					if (res) {
						this.project = res;
					}
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
