<template>
	<div class="leadgen-card" @click.stop="$emit('leadgen-dialog', card)">
		<div>
			<div class="heading" v-html="heading"></div>
			<div class="subheading">
				{{ subheading }}
			</div>
			<div class="infos" v-for="(info, i) in infos" :key="i">
				<div class="d-flex align-center justify-start gap-1">
					<v-icon size="16" color="black">{{ info.icon }}</v-icon>
					<div>{{ info.label }}</div>
				</div>
			</div>
			<v-divider class="my-2"></v-divider>

			<div class="actions" v-for="(action, i) in cardActions" :key="card.id + '-' + i">
				<div
					@click.stop="$emit('action-clicked', $event, action.name, card)"
					class="action pointer grey-action d-flex align-center justify-start gap-1"
				>
					<v-icon size="16">${{ action.icon }}</v-icon>
					<div>{{ $t(action.label) }}</div>
				</div>
			</div>
		</div>
		<div class="clientAvatar">
			<client-avatar :client="card.client" small v-if="card.client"></client-avatar>
		</div>
	</div>
</template>

<script>
	import LeadGenRules from './LeadGenRules.js';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'LeadGenCard',
		props: { card: { type: Object, required: true } },
		components: { ClientAvatar },

		data() {
			return {
				leadGenRules: new LeadGenRules(this.card.cardType),
				cardActions: null,
			};
		},

		mounted() {
			this.cardActions = this.leadGenRules.getCardActions();
			if (this.card.cardType == 'Proposal') {
				this.cardActions = this.leadGenRules.filterProposalActions(this.cardActions, this.card.status);
			}
			// console.log(this.card);
		},

		methods: {},

		computed: {
			isReady: {
				get: function() {
					return this.card !== null && this.cardActions !== null;
				},
			},
			heading: {
				get: function() {
					return this.card.name;
				},
			},
			subheading: {
				get: function() {
					switch (this.card.cardType) {
						case 'Inquiry':
							return this.card.subheading;
						case 'Prospect':
						case 'Discovery':
							if (this.card.contact) return this.card.contact.firstName + ' ' + this.card.contact.lastName;
							return '';
						case 'Proposal':
						case 'Contract':
						case 'Project':
							if (this.card.client) return this.card.client.name;
							return '';
						default:
							return '';
					}
				},
			},
			infos: {
				get: function() {
					switch (this.card.cardType) {
						case 'Prospect':
							if (this.card.lastNote) return [{ label: this.card.lastNote, icon: '$notes' }];
							return [];
						//return [];
						case 'Discovery':
						case 'Proposal':
						case 'Contract':
						case 'Project':
							return '';
						default:
							return '';
					}
				},
			},
		},
	};
</script>

<style lang="scss">
	.leadgen-card {
		cursor: pointer;
		margin-top: 8px;
		padding: 8px;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-white-base);
		border-radius: 8px;
		min-height: 148px;
		box-shadow: 0px 1px 4px rgba(16, 17, 15, 0.2);
		position: relative;

		// display: flex;
		// flex-direction: column;
		// justify-content: space-between;
		cursor: pointer;

		.heading {
			font-size: 12px;
			border: 1px solid var(--v-gray_30-base);
			color: var(--v-gray_80-base);
			padding: 0px 6px;
			border-radius: 4px;
			width: fit-content;
		}

		&:hover {
			border: 1px solid var(--v-gray_50-base);
			.heading {
				border: 1px solid var(--v-gray_50-base);
				color: var(--v-gray_90-base);
			}
		}

		.subheading {
			margin: 12px 0 12px 2px;
			font-size: 14px;
			line-height: 16px;
			width: 100%;
			//letter-spacing: 0.4px;
			color: var(--v-gray_90-base);
		}
		.infos {
			margin-top: 16px;
			font-size: 12px;
			line-height: 16px;
			//letter-spacing: 0.4px;
			color: var(--v-black-base);
		}
		.actions {
			margin-top: 8px;
			font-size: 14px;
			line-height: 24px;
			//letter-spacing: 0.4px;
			font-weight: 500;
		}
		svg {
			margin-right: 4px;
		}
		.avatar {
			position: absolute;
			bottom: 4px;
			right: 4px;
			border-radius: 4px;
		}
		// }
	}
</style>
