import DateTime from "@/modules/utils/HDateTime";
import InvoiceDetails from "@/modules/invoices/InvoiceDetails";
import CreateInvoiceModal from "@/modules/invoices/CreateInvoiceModal";
import CalendarPaymentList from "@/modules/calendar/lists/CalendarPaymentList";

export default {

    methods: {
        createInvoice(events){
            this.$store.state.globalModalController.openModal(CreateInvoiceModal).then((res) => {
                if(res){
                    events.push(this.convertInvoice(res));
                    this.invoiceClicked({invoice:res},events);
                }
            })
        },

        invoiceClicked(meta,events){
            let binding = {
                id: meta.invoice.id,
                clientId: meta.invoice.clientId,
            }
            this.$store.state.globalModalController.openModal(InvoiceDetails,binding,true,true).then((res) => {
                if(!res) return;
                let ix = events.findIndex(e => e.eventSource === 'INVOICE' && e.id === res.id);
                if(ix > -1){
                    if(res.action && res.action === 'DELETED'){
                        if(ix > -1){
                            events.splice(ix,1);
                        }
                    }else{
                        events.splice(ix,1,this.convertInvoice(res));
                        this.refreshKey++;
                    }
                }
            })
        },

        convertInvoice: function(invoice){
            let result =   {
                id: invoice.id,
                eventSource: 'INVOICE',
                start: invoice.dateDue,
                end: invoice.dateDue,
                dateOnly: true,
                meta: {
                    clientId: invoice.clientId,
                    invoice: invoice,
                }
            }

            return result;
        },

        processInvoicesAndPayments(invoices){
            let result = [];
            let skipStates = ['INIT','VOIDED'];

            for(let j=0; j < invoices.length; j++){
                let e = invoices[j];
                let i = e.meta.invoice;

                if(skipStates.includes(i.status)){
                    continue;
                }

                let targetDate = (i.status === 'DRAFT' || !i.dateSent) ? i.dateCreated : i.dateSent;

                result.push({
                    name: i.invoiceNumber,
                    start: DateTime.fromISO(targetDate).toJSDate(),
                    end: DateTime.fromISO(targetDate).toJSDate(),
                    timed: false,
                    original: {
                        eventSource: 'INVOICE',
                        meta: {
                            invoice: i,
                            pastDue: this.isPastDue(i)
                        },
                    },
                    source: 'INVOICE',
                });

                i.payments.forEach((p) => {
                    result.push({
                        name: i.invoiceNumber,
                        start: DateTime.fromISO(p.datePaid).toJSDate(),
                        end: DateTime.fromISO(p.datePaid).toJSDate(),
                        timed: false,
                        original: {
                            eventSource: 'PAYMENT',
                            meta: {
                                invoice: i,
                                payment: p,
                            },
                        },
                        source: 'PAYMENT',
                    });
                })
            }

            return result;
        },

        isPastDue: function(invoice){
            let status = ['SENT','PARTIAL'];
            if(status.includes(invoice.status) && invoice.amountDue > 0){
                if(DateTime.fromISO(invoice.dateDue) < DateTime.now()){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        },

        handlePaymentMetricClicked: function(metric){
            let start = this.startDate.toJSDate().getTime();
            let end = this.endDate.toJSDate().getTime();
            let events = this.calendarEvents
                .filter((e) => e.source === 'PAYMENT')
                .filter((e) => e.start.getTime() >= start && e.end.getTime() <= end)
                .filter((e) => {
                    if(!metric){
                        return true;
                    }else{
                        return e.original.meta.invoice.clientId === metric.id;
                    }
                });

            let binding = {
                events:events
            }

            this.$store.state.globalModalController.openModal(CalendarPaymentList,binding);
        },
    },

    computed: {
        paymentMetricsByClient: function(){
            let result = [];
            let start = this.startDate.toJSDate().getTime();
            let end = this.endDate.toJSDate().getTime();
            let events = this.calendarEvents.filter((e) => e.source === 'PAYMENT' && e.start.getTime() >= start && e.end.getTime() <= end)
            let clients = new Map();

            events.forEach((e) => {
                this.incrementMapByKey(clients,e.original.meta.invoice.clientId,e.original.meta.payment.localAmount);
            })

            let totalDollars = 0;
            clients.forEach(v => totalDollars+=v);

            clients.forEach((v,k) => {
                let client = this.$store.getters.clients.find(c => c.id === k)

                result.push({
                    id: k,
                    heading: client ? client.name : 'No client',
                    subheading: this.$formatters.dollars(v,true,true,this.$store.state.defaultCurrency),
                    value: v,
                    color: client ? client.color : 'var(--v-gray_50-base)',
                    percentage: Math.round((v/totalDollars)*100)
                })
            })

            result = result.filter((r) => r.percentage > 0);

            return {
                defaultHeading: 'Payments received',
                defaultSubheading: this.$formatters.dollars(totalDollars,true,true,this.$store.state.defaultCurrency),
                metrics: result
            }
        },
    }
}