<template>
	<div style="flex-grow: 1" class="column-format">
		<div v-if="!activeThread && !newMessage" class="column-format" style="min-height: 324px">
			<div class="messages" style="max-height: calc(var(--vh) - 150px); overflow-y: auto;"  v-if="!activeThread && isVisible">
				<thread
					v-for="thread in $store.state.threads"
					:key="randomKey + '-' + thread.id"
					:thread="thread"
					@open-thread="openThread(thread)"
				></thread>
			</div>

		</div>
		<div v-else-if="newMessage">
			<start-new-message
					ref="StartNewMessage"
				:subscription="subscription"
				@back="allThreads"
				@new-message="newMessageStarted"
			></start-new-message>
		</div>
		<div v-else class="pb-2">
			<thread-detail
				ref="ThreadDetail"
				:thread="activeThread"
				:key="refreshKey"
				:message-update="messageUpdate"
				:random-key="randomKey"
				:records-per-fetch="recordsPerFetch"
				@back="allThreads"
				@more-messages="fetchMoreMessages(activeThread)"
				@delete="deleteThread(activeThread)"
			></thread-detail>
		</div>
		<audio src="/media/inbound_message.mp3" style="display: none" id="NewMessage"></audio>
	</div>
</template>

<script>
	import Thread from '@/modules/communicator/realtime/messages/Thread';
	import ThreadDetail from '@/modules/communicator/realtime/messages/ThreadDetail';
	import StartNewMessage from '@/modules/communicator/realtime/messages/StartNewMessage';
	import CommunicatorMessageService from '@/modules/communicator/CommunicatorMessageService';
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'Messages',

		props: ['primary', 'isVisible'],

		components: { StartNewMessage, Thread, ThreadDetail },

		data: function () {
			return {
				communicatorService: new CommunicatorMessageService(),
				randomKey: Math.floor(Math.random() * 99999) + 1,
				recordsPerFetch: 50,
			};
		},

		mounted() {
			if (this.primary) {
				this.getThreads();
				this.setupListeners();
			}
		},

		beforeDestroy() {
			if (this.primary) {
				this.stopListeners();
			}
		},

		methods: {
			setupListeners: function () {
				this.$store.state.eventBus.$on(`u-${this.userId}.communicatorMessage`, this.handleNewMessage);
				this.$store.state.eventBus.$on(`u-${this.userId}.communicatorMessageStatus`, this.handleMessageStatus);
				this.$store.state.eventBus.$on(`u-${this.userId}.communicatorMessageThread`, this.handleThreadUpdate);
			},

			stopListeners: function () {
				this.$store.state.eventBus.$off(`u-${this.userId}.communicatorMessage`, this.handleNewMessage);
				this.$store.state.eventBus.$off(`u-${this.userId}.communicatorMessageStatus`, this.handleMessageStatus);
				this.$store.state.eventBus.$on(`u-${this.userId}.communicatorMessageThread`, this.handleThreadUpdate);
			},

			startNewMessage: function () {
				this.newMessage = true;
			},

			newMessageStarted: function (newMessage) {
				this.openThread(newMessage.thread);
			},

			handleThreadUpdate: function (event) {
				let thread = event.message;
				let threadIx = this.$store.state.threads.findIndex((t) => t.id === thread.id);
				if (threadIx > -1) {
					let ourThread = this.$store.state.threads[threadIx];
					Object.assign(ourThread,thread);
					this.$store.state.threads.splice(threadIx,1,ourThread);
				}
			},

			handleNewMessage: function (event) {
				let thread = event.message.thread;
				let message = event.message.message;
				let threadIx = this.$store.state.threads.findIndex((t) => t.id === thread.id);

				if (threadIx > -1) {
					let ourThread = this.$store.state.threads[threadIx];
					ourThread.lastMessage = thread.lastMessage;
					ourThread.lastMessageText = thread.lastMessageText;
					ourThread.unread = thread.unread;
					if(thread.participants) {
						console.log(JSON.stringify(thread.participants));
						ourThread.participants = thread.participants;
					}

					if (this.activeThreadId === thread.id && this.isVisible) {
						this.communicatorService.markThreadAsRead(thread.id);
					}

					if (ourThread.messages) {
						ourThread.messages.push(message);
					}

					this.$store.state.threads.splice(threadIx, 1, ourThread);
				} else {
					this.$store.state.threads.push(thread);
				}

				this.$store.state.threads.sort((a, b) => b.lastMessage.localeCompare(a.lastMessage));
				this.messageUpdate++;

				if (message.direction === 'In' && this.subscription.preferences.playMessageSoundInBrowser) {
					document.getElementById('NewMessage').currentTime = 0;
					document.getElementById('NewMessage').play();
				}
			},

			handleMessageStatus: function (event) {
				let messageId = event.message.id;
				let threadId = event.message.threadId;
				let status = event.message.status;
				let statusReason = event.message.statusReason;

				let threadIx = this.$store.state.threads.findIndex((t) => t.id === threadId);

				if (threadIx > -1) {
					let ourThread = this.$store.state.threads[threadIx];

					if (ourThread.messages) {
						let messageIx = ourThread.messages.findIndex((m) => m.id === messageId);
						if (messageIx > -1) {
							let ourMessage = ourThread.messages[messageIx];
							ourMessage.status = status;
							ourMessage.statusReason = statusReason;
							ourThread.messages.splice(messageIx, 1, ourMessage);
							this.messageUpdate++;
						}
					}
				}
			},

			getThreads: function () {
				this.communicatorService.getMessageThreads().then((res) => {
					this.$store.state.threads.splice(0, this.$store.state.threads.length);
					this.$store.state.threads.push(...res.data);
				});
			},

			getMessages: function (thread) {
				let page = thread.pagination.page;
				let records = thread.pagination.records;

				this.communicatorService.getMessagesInThread(thread.id,page,records).then((res) => {
					let ix = this.$store.state.threads.findIndex((t) => t.id === thread.id);
					if (ix > -1) {
						if (!this.$store.state.threads[ix].messages) {
							this.$store.state.threads[ix].messages = [];
						}

						if(page === 0) {
							this.$store.state.threads[ix].messages.splice(0, this.$store.state.threads[ix].messages.length);
						}

						this.$store.state.threads[ix].messages.unshift(...res.data);
						this.$refs.ThreadDetail.addMoreMessages(thread.id,res.data);

						if(page === 0) {
							this.refreshKey++;
						}
					}
				});
			},

			deleteThread: function(thread){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Do you want to delete this message thread?'
				};
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.communicatorService.deleteThread(thread.id).then(() => {
							let ix = this.$store.state.threads.findIndex((t) => t.id === thread.id);
							if (ix > -1) {
								this.$store.state.threads.splice(ix,1);
							}
						})
					}
				})
			},

			openThread: function (thread) {
				this.refreshKey++;
				this.activeThreadId = thread.id;

				if (thread.unread) {
					thread.unread = false;
					this.communicatorService.markThreadAsRead(thread.id);
				}

				let ix = this.$store.state.threads.findIndex((t) => t.id === thread.id);
				this.$store.state.threads.splice(ix, 1, thread);

				this.newMessage = false;
				if (!thread.messages) {
					thread.pagination = {page: 0, records: this.recordsPerFetch};
					this.getMessages(thread);
				}
			},

			fetchMoreMessages: function(thread){
				if(thread.pagination){
					thread.pagination.page++;
				}
				this.getMessages(thread);
			},

			allThreads: function () {
				this.newMessage = false;
				this.activeThreadId = null;
			},
		},

		computed: {
			subscription: function () {
				return this.$store.state.communicatorSubscription;
			},

			activeThread: function () {
				return this.$store.state.threads.find((t) => t.id === this.activeThreadId);
			},

			userId: function () {
				return this.$store.getters.getLoggedInUserId;
			},

			activeThreadId: {
				get() {
					return this.$store.state.communicator.activeThreadId;
				},
				set(newVal) {
					this.$store.state.communicator.activeThreadId = newVal;
				},
			},

			newMessage: {
				get() {
					return this.$store.state.communicator.newMessage;
				},
				set(newVal) {
					this.$store.state.communicator.newMessage = newVal;
				},
			},

			refreshKey: {
				get() {
					return this.$store.state.communicator.refreshKey;
				},
				set(newVal) {
					this.$store.state.communicator.refreshKey = newVal;
				},
			},

			messageUpdate: {
				get() {
					return this.$store.state.communicator.messageUpdate;
				},
				set(newVal) {
					this.$store.state.communicator.messageUpdate = newVal;
				},
			},
		},

		watch: {
			isVisible: function (val) {
				if (this.activeThread && this.activeThread.unread && val) {
					this.communicatorService.markThreadAsRead(this.activeThread.id);
				}
			},
		},
	};
</script>

<style scoped lang="scss">

</style>