var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.emailTemplate
    ? _c(
        "div",
        { staticStyle: { "min-width": "900px" } },
        [
          _c("div", { staticClass: "modal-padding" }, [
            _c(
              "div",
              { staticClass: "modal-title row-format align-center gap-1" },
              [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.emailTemplate.id ? "Edit" : "Create") +
                      " email template"
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c("folder-selector", {
                      attrs: { folders: _vm.folders },
                      model: {
                        value: _vm.emailTemplate.folder,
                        callback: function($$v) {
                          _vm.$set(_vm.emailTemplate, "folder", $$v)
                        },
                        expression: "emailTemplate.folder"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.saveEmailTemplate(true)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("$close")])],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "row-format" }, [
              _c(
                "div",
                { staticClass: "column-format" },
                [
                  _c("v-text-field", {
                    staticClass: "mb-1 h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      label: "Template name"
                    },
                    model: {
                      value: _vm.emailTemplate.name,
                      callback: function($$v) {
                        _vm.$set(_vm.emailTemplate, "name", $$v)
                      },
                      expression: "emailTemplate.name"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-2 h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      label: "Subject"
                    },
                    model: {
                      value: _vm.emailTemplate.subject,
                      callback: function($$v) {
                        _vm.$set(_vm.emailTemplate, "subject", $$v)
                      },
                      expression: "emailTemplate.subject"
                    }
                  }),
                  _c("input", {
                    ref: "EmailAttachment",
                    staticStyle: { display: "none" },
                    attrs: { type: "file", multiple: "" },
                    on: { change: _vm.setAttachments }
                  }),
                  _c("editor", {
                    ref: "EmailEditor",
                    attrs: {
                      "api-key": _vm.$store.getters.getTinyMceKey,
                      "initial-value": _vm.emailTemplate.htmlContent,
                      init: _vm.mceConfigText,
                      spellcheck: true
                    }
                  }),
                  _vm.emailTemplate.attachments.length
                    ? _c(
                        "div",
                        {
                          staticClass: "row-format mt-2",
                          staticStyle: { "flex-wrap": "wrap", gap: "8px" }
                        },
                        _vm._l(_vm.emailTemplate.attachments, function(
                          attachment,
                          index
                        ) {
                          return _c(
                            "v-chip",
                            {
                              key: attachment.fileName + index,
                              attrs: {
                                small: "",
                                close: "",
                                "close-icon": "cancel"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadAttachment(attachment)
                                },
                                "click:close": function($event) {
                                  return _vm.removeAttachment(attachment)
                                }
                              }
                            },
                            [_vm._v(_vm._s(attachment.fileName))]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  key: _vm.refreshKey,
                  staticClass: "mt-2 ml-2",
                  staticStyle: { width: "250px" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format font-14" },
                    [
                      _c(
                        "v-icon",
                        {
                          directives: [{ name: "tippy", rawName: "v-tippy" }],
                          attrs: { content: "Tokens" }
                        },
                        [_vm._v("find_replace")]
                      ),
                      _c("div", { staticClass: "font-gray_80" }, [
                        _vm._v("Tokens")
                      ])
                    ],
                    1
                  ),
                  _vm._l(_vm.tokens, function(category) {
                    return _c(
                      "div",
                      {
                        key: category.category,
                        staticClass: "font-14 text-left"
                      },
                      [
                        _c("ul", [
                          _c(
                            "li",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.toggleCategory(category)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "brand-medium pointer" },
                                [
                                  _vm._v(" " + _vm._s(category.category) + " "),
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "black", size: "12" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          category.expanded
                                            ? "$arrowUp"
                                            : "$arrowDown"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              category.expanded
                                ? _c(
                                    "ul",
                                    { staticStyle: { "padding-left": "12px" } },
                                    _vm._l(category.tokens, function(token) {
                                      return _c(
                                        "li",
                                        {
                                          key: category.category + token.value,
                                          staticClass: "token-link pointer",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.insert(token.value)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(token.label) + " "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "row-format centered mt-2 pa-2 gap-2",
              staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "super-action",
                  attrs: { width: "160" },
                  on: {
                    click: function($event) {
                      return _vm.saveEmailTemplate(true)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.confirmDelete()
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { size: "20", color: "gray_90" }
                    },
                    [_vm._v("delete")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.aiDialog
            ? _c("ai-email-widget", {
                attrs: { dialog: _vm.aiDialog, mode: "draft" },
                on: {
                  cancel: function($event) {
                    _vm.aiDialog = false
                  },
                  result: function($event) {
                    return _vm.runAiAssist($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }