<template>
	<v-container :class="`pl-0 ${periods.length ? 'py-5' : 'py-3'}`">
		<v-row>
			<v-col cols="1" align="left">
				<div class="mt-2 brand-semilight">{{ label }}</div>
			</v-col>
			<v-col cols="10" align="left">
				<div v-for="(period, index) in periods" :key="index" class="column-format">
					<div :class="`row-format ${index > 0 ? 'mt-2': ''}`">
						<div style="width: 110px">
							<v-autocomplete
									:items="enabledTimeIncrements"
									v-model="period.start"
									hide-details
									dense
									outlined
							></v-autocomplete>
						</div>
						<div class="mt-2 mx-2">-</div>
						<div style="width: 110px">
							<v-autocomplete
									:items="enabledTimeIncrements"
									v-model="period.end"
									hide-details
									dense
									outlined
							></v-autocomplete>
						</div>
						<div class="mt-1 ml-1">
							<v-btn icon @click="periods.splice(index,1)"><v-icon color="gray_70" small>$delete</v-icon></v-btn>
						</div>
					</div>
					<div v-if="period.start > period.end" style="color: var(--v-error-base)" class="font-14 brand-semilight">
						Choose an end time later than the start time.
					</div>
				</div>
				<div v-if="hasOverlaps" style="color: var(--v-error-base)" class="font-14 brand-semilight">
					Times currently overlap.  Please correct.
				</div>
				<div v-if="!periods.length" class="mt-2" style="color: var(--v-gray_60-base)">
					Unavailable
				</div>
			</v-col>
			<v-col cols="1" align="right">
				<v-btn icon class="mt-2" @click="addPeriod"><v-icon color="gray_70" small>$plus</v-icon></v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import timeIncrements from '@/assets/data/time-increments.json';
	import timeIncrements24 from '@/assets/data/time-increments-24.json'
	import HDateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'PeriodConfiguration',

		props: ['label', 'periods'],

		components: {},

		data: function () {
			return {
				timeIncrements: timeIncrements,
				timeIncrements24: timeIncrements24
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addPeriod: function(){
				if(!this.periods.length){
					this.periods.push({start:540,end:1020})
				}else{
					let last = this.periods[this.periods.length-1];
					let start = last.end + 30;
					let end = start + 90;

					if(end >= 1440){
						end = 1439;
					}

					if(start < 1440){
						this.periods.push({start:start,end:end})
					}else{
						this.$store.commit('error','Unable to add timeslot - current end time is too late.')
					}
				}
			}
		},

		computed: {
			enabledTimeIncrements: function(){
				let time = HDateTime.now().toLocaleString(HDateTime.TIME_SIMPLE);
				if(time.toUpperCase().includes('AM') || time.toUpperCase().includes('PM')){
					return this.timeIncrements;
				}else{
					return this.timeIncrements24;
				}
			},

			hasOverlaps: function(){
				let previousEnd = 0;
				for (let p of this.periods) {
					if(p.start < previousEnd){
						return true;
					}else{
						previousEnd = p.end;
					}
				}
				return false;
			}
		},
	};
</script>

<style scoped lang="scss"></style>
