var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.settings
    ? _c(
        "div",
        {
          staticStyle: {
            width: "400px",
            "min-width": "400px",
            "background-color": "var(--v-white-base)"
          }
        },
        [
          _c(
            "div",
            { staticClass: "pa-3" },
            [
              _c("v-checkbox", {
                attrs: {
                  label: "Daily email enabled",
                  "hide-details": "",
                  dense: ""
                },
                model: {
                  value: _vm.settings.sendDailyEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "sendDailyEmail", $$v)
                  },
                  expression: "settings.sendDailyEmail"
                }
              }),
              _c("v-checkbox", {
                attrs: {
                  label: "Weekly email enabled",
                  "hide-details": "",
                  dense: ""
                },
                model: {
                  value: _vm.settings.sendWeeklyEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "sendWeeklyEmail", $$v)
                  },
                  expression: "settings.sendWeeklyEmail"
                }
              }),
              _c("v-checkbox", {
                attrs: {
                  label: "Monthly email enabled",
                  "hide-details": "",
                  dense: ""
                },
                model: {
                  value: _vm.settings.sendMonthlyEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "sendMonthlyEmail", $$v)
                  },
                  expression: "settings.sendMonthlyEmail"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "align-center centered pt-3 pb-2",
              staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
            },
            [
              _c(
                "v-btn",
                { staticClass: "primary-action", on: { click: _vm.save } },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }