var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-modal", staticStyle: { width: "600px" } },
    [
      _c("div", { staticClass: "panel-modal-header" }, [
        _c(
          "div",
          { staticClass: "row-format align-center" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mr-2 pointer",
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("result")
                  }
                }
              },
              [_vm._v("$chevronRight")]
            ),
            _c("div", { staticClass: "brand-medium font-18" }, [
              _vm._v("Automation executions")
            ]),
            _c(
              "div",
              { staticClass: "ml-auto" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "pointer ml-2",
                    attrs: { size: "20", color: "secondary" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("result")
                      }
                    }
                  },
                  [_vm._v("close")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "text-left show-scrollbar",
          staticStyle: {
            "max-height": "calc(100% - 60px)",
            "overflow-y": "auto"
          }
        },
        [
          _c("automation-list", {
            attrs: {
              "workflow-id": _vm.workflowId,
              "resource-type": _vm.resourceType,
              "resource-id": _vm.resourceId
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }