<template>
	<div class="column-format text-left font-14 font-gray_70 py-3 gap-2">
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pt"
				label="Padding top"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pb"
				label="Padding bottom"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pl"
				label="Padding left"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pr"
				label="Padding right"
				@input="updated"
			></v-text-field>
		</div>
		<v-select
			dense
			outlined
			hide-details
			persistent-placeholder
			:label="$t('proposal.terms.payment-terms')"
			:items="dayOptions"
			v-model="element.paymentDays"
			@change="renderTerms"
		>
			<template v-slot:selection="{ item }">
				<span v-if="item === 0">{{ $t('proposal.terms.on-receipt') }}</span>
				<span v-if="item !== 0">{{ item }} {{ $t('proposal.terms.days') }}</span>
			</template>
			<template v-slot:item="{ item }">
				<span v-if="item === 0">{{ $t('proposal.terms.on-receipt') }}</span>
				<span v-if="item !== 0">{{ item }} {{ $t('proposal.terms.days') }}</span>
			</template>
		</v-select>

		<v-text-field
			dense
			outlined
			hide-details
			persistent-placeholder
			:label="$t('proposal.terms.late-payment')"
			v-model="element.latePaymentFee"
			placeholder="No fee"
			suffix="% mo."
			type="number"
			@change="renderTerms"
		></v-text-field>

		<v-switch @change="renderTerms" class="ml-1" inset hide-details dense v-model="element.fullLegalTerms" :label="$t('proposal.terms.include-terms')"></v-switch>

		<div v-if="element.fullLegalTerms" class="column-format gap-4">
			<div class="modal-title mb-n2">{{ $t('proposal.terms.legal-terms') }}</div>

			<div class="disclaimer">
				<div><v-icon size="30" color="gray_90">$info</v-icon></div>
				<div style="font-size: 12px; font-style: italic">{{ $t('proposal.terms.disclaimer') }}</div>
			</div>

			<date-selector
				:standard="true"
				:date="effectiveDate"
				:label="$t('proposal.terms.effective-date')"
				@change="effectiveDateUpdated($event)"
			></date-selector>

			<v-text-field
				dense
				outlined
				hide-details
				persistent-placeholder
				:label="$t('proposal.terms.governing-law')"
				v-model="element.governingLaw"
				@change="renderTerms"
			></v-text-field>

			<v-select
				dense
				outlined
				hide-details
				persistent-placeholder
				:label="$t('proposal.terms.cancellation')"
				:items="dayOptions"
				v-model="element.cancellationNoticeDays"
				@change="renderTerms"
			>
				<template v-slot:selection="{ item }">
					<span v-if="item === 0">{{ $t('proposal.terms.no-notice') }}</span>
					<span v-if="item !== 0">{{ item }} {{ $t('proposal.terms.days') }}</span>
				</template>
				<template v-slot:item="{ item }">
					<span v-if="item === 0">{{ $t('proposal.terms.no-notice') }}</span>
					<span v-if="item !== 0">{{ item }} {{ $t('proposal.terms.days') }}</span>
				</template>
			</v-select>

			<div class="checkbox-flex">
				<div>
					<v-simple-checkbox
						:color="element.displayInPortfolio ? 'var(--v-blue_50-base)' : 'var(--v-gray_70-base)'"
						v-model="element.displayInPortfolio"
						@input="renderTerms"
					/>
				</div>
				<div>
					<div class="label">{{ $t('proposal.terms.portfolio-label') }}</div>
					<div class="details">{{ $t('proposal.terms.portfolio-details') }}</div>
				</div>
			</div>

			<div class="checkbox-flex">
				<div>
					<v-simple-checkbox
						:color="element.grantLicenseForFramework ? 'var(--v-blue_50-base)' : 'var(--v-gray_70-base)'"
						v-model="element.grantLicenseForFramework"
						@input="renderTerms"
					/>
				</div>
				<div>
					<div class="label">{{ $t('proposal.terms.frameworks-label') }}</div>
					<!--							<div>{{ $t('proposal.terms.frameworks-details') }}</div>-->
				</div>
			</div>

			<div class="checkbox-flex">
				<div>
					<v-simple-checkbox
						:color="element.processingFeePassThrough ? 'var(--v-blue_50-base)' : 'var(--v-gray_70-base)'"
						v-model="element.processingFeePassThrough"
						@input="renderTerms"
					/>
				</div>
				<div>
					<div class="label">{{ $t('proposal.terms.fee-pass-through-label') }}</div>
					<div class="details">{{ $t('proposal.terms.fee-pass-through-detail') }}</div>
				</div>
			</div>

			<div class="modal-title mb-n2">{{ $t('proposal.terms.the-contractor') }}</div>

			<v-text-field
				dense
				outlined
				hide-details
				persistent-placeholder
				:label="$t('proposal.terms.legal-name')"
				v-model="account.accountName"
				@change="renderTerms"
			>
			</v-text-field>

			<div class="d-flex justify-space-between">
				<div style="flex: 1 1 50%" class="pr-1">
					<v-select
						v-if="entityTypes.length"
						dense
						outlined
						hide-details
						persistent-placeholder
						:label="$t('proposal.terms.entity-type')"
						:items="entityTypes"
						v-model="account.entityType"
						@change="renderTerms"
					>
						<template v-slot:item="{ item }">
							<span>{{ item }}</span>
						</template>
					</v-select>
					<v-text-field
						v-else
						dense
						outlined
						hide-details
						persistent-placeholder
						v-model="account.entityType"
						:label="$t('proposal.terms.entity-type')"
						@change="renderTerms"
					></v-text-field>
				</div>
				<div style="flex: 1 1 50%" class="pl-1">
					<v-select
						v-if="states.length"
						dense
						outlined
						hide-details
						persistent-placeholder
						:label="$t('proposal.terms.entity-state')"
						item-value="name"
						item-text="name"
						:items="states"
						v-model="account.entityState"
						@change="renderTerms"
					>
						<template v-slot:item="{ item }">
							<span>{{ item.name }}</span>
						</template>
					</v-select>
					<v-text-field
						v-else
						dense
						outlined
						hide-details
						persistent-placeholder
						v-model="account.entityState"
						:label="$t('proposal.terms.entity-state')"
						@change="renderTerms"
					></v-text-field>
				</div>
			</div>

			<div class="modal-title mb-n2">{{ $t('proposal.terms.the-client') }}</div>

			<v-text-field
				dense
				outlined
				hide-details
				persistent-placeholder
				:label="$t('proposal.terms.legal-name')"
				v-model="element.clientLegalName"
				@change="renderTerms"
			>
			</v-text-field>

			<div class="d-flex justify-space-between">
				<div style="flex: 1 1 50%" class="pr-1">
					<v-text-field
						dense
						outlined
						hide-details
						persistent-placeholder
						v-model="element.clientEntityType"
						:label="$t('proposal.terms.entity-type')"
						@change="renderTerms"
					></v-text-field>
				</div>
				<div style="flex: 1 1 50%" class="pl-1">
					<v-text-field
						dense
						outlined
						hide-details
						persistent-placeholder
						v-model="element.clientState"
						:label="$t('proposal.terms.entity-state')"
						@change="renderTerms"
					></v-text-field>
				</div>
			</div>
		</div>

		<v-btn elevation="0" @click="confirmConvert">Convert to editable text</v-btn>
	</div>
</template>

<script>
	import countries from '@/assets/data/countries.json';
	import DateSelector from '@/components/DateSelector';
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'SpacerConfig',

		props: ['item', 'agreementService'],

		components: { DateSelector },

		data: function() {
			return {
				dayOptions: [
					0,
					1,
					2,
					3,
					4,
					5,
					6,
					7,
					8,
					9,
					10,
					11,
					12,
					13,
					14,
					15,
					16,
					17,
					18,
					19,
					20,
					21,
					30,
					45,
					60,
					90,
					120,
					180,
				],
				depositOptions: ['No deposit', 'Fixed amount', 'Percentage'],
				scheduleOptions: ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Annually', 'As Needed'],
				countries: countries,
				effectiveMenu: false,
				effectiveDate: this.item.effectiveDate,
				element: { ...this.item },

				rules: {
					min(min, v) {
						return v >= min || `Must be at least ${min}`;
					},
					max(max, v) {
						return v <= max || `Cannot be greater than ${max}.`;
					},
				},
			};
		},

		mounted() {
			this.renderTerms();
		},

		beforeDestroy() {},

		methods: {
			renderTerms: function() {
				this.agreementService.renderTerms(this.element).then((res) => {
					this.element.evaluatedTerms = res.data;
					this.updated();
				});
			},

			updated: function() {
				this.$emit('change', this.element);
			},

			effectiveDateUpdated: function(date){
				this.element.effectiveDate = date;
				this.renderTerms();
			},

			confirmConvert: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to convert these terms to editable text?'
				}

				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						let element = {
							id: this.element.id,
							type: 'TextBlock',
							pt: this.element.pt,
							pb: this.element.pb,
							pl: this.element.pl,
							pr: this.element.pr,
							text: `<div style="font-size: 14px">${this.element.evaluatedTerms}</div>`
						}
						this.$emit('change',element);
					}
				});
			}
		},

		computed: {
			account: function() {
				return this.$store.state.podAccount;
			},

			client: function() {
				return {};
			},

			entityTypes: function() {
				if (this.account.country === 'US' || this.account.country === 'CA') {
					let country = this.countries.find((c) => c.code === this.account.country);
					if (country) {
						return country.entityTypes;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},

			states: function() {
				if (this.account.country === 'US' || this.account.country === 'CA') {
					let country = this.countries.find((c) => c.code === this.account.country);
					if (country) {
						return country.states;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.disclaimer {
		display: flex;
		justify-content: space-between;
		align-content: flex-start;
		background-color: var(--v-gray_10-base);
		border-radius: 4px;
		> div:first-child {
			flex: 0 0 40px;
			padding: 8px;
		}
		> div:last-child {
			flex: 1 1 auto;
			text-align: left;
			font-size: 14px;
			font-weight: 300;
			padding: 8px 8px 8px 0;
		}
	}

	.checkbox-flex {
		display: flex;
		justify-content: space-between;
		align-content: flex-start;
		border: 1px solid var(--v-gray_50-base);
		//background-color: var(--v-blue_5-base);
		border-radius: 4px;
		> div:first-child {
			flex: 0 0 40px;
			padding: 8px;
		}
		> div:last-child {
			flex: 1 1 auto;
			text-align: left;
			font-size: 14px;
			font-weight: 300;
			padding: 8px 8px 8px 0;
			> div.label {
				margin-top: 2px;
				font-size: 14px;
				font-weight: 300;
			}
			> div.details {
				margin-top: 8px;
				color: var(--v-gray_80-base);
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
</style>
