<template>
	<div :class="`${hasContent ? '' : 'content-block'} py-2`">
		<div v-html="initialContent" v-if="!editMode"></div>
		<editor
				v-else
				ref="block"
				:api-key="$store.getters.getTinyMceKey"
				:initial-value="initialContent"
				:inline="true"
				:init="mceConfig"
				:spellcheck="true"
				@input="handleContentUpdated()"
		></editor>
	</div>
</template>

<script>
	import editor from "@tinymce/tinymce-vue";
	export default {
		name: 'ContentBlock',

		props: ['editMode', 'index', 'content','mceConfig'],

		emits: ['updated'],

		components: {editor},

		data: function() {
			return {
				initialContent: this.content.length >= this.index ? this.content[this.index] : ""
			};
		},

		mounted() {
			console.log('index:' + this.index);
			console.log('content-length:' + this.content.length);
		},

		beforeDestroy() {},

		methods: {
			handleContentUpdated: function(){
				this.initialContent = this.$refs.block.editor.getContent({format: 'html'});
				this.$emit('updated',this.initialContent);
			}
		},

		computed: {
			hasContent: function(){
				if(this.initialContent){
					return true;
				}else{
					return false;
				}
			}
		},

		watch: {
			editMode: function(val){
				if(val){
					setTimeout(() => {
						this.$refs.block.editor.focus()
					},150);
				}else{
					this.handleContentUpdated();
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	.content-block{
		min-height: 200px;
		.mce-content-body {
			min-height: 200px;
		}
	}
</style>
