import HttpClient from "@/services/HttpClient";
import store from '@/store';

export default class CustomBrandingService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(process.env.VUE_APP_BASE_AUTH_URL,true);
	}

	getCustomBranding() {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.get(`/account/${accountId}/branding`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	updateCustomBranding(branding) {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.put(`/account/${accountId}/branding`,branding)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	deleteFavicon() {
		let accountId = store.getters.getAccountId;
		return this.httpClient
			.delete(`/account/${accountId}/branding/favicon`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
