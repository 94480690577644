var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format pointer py-2 font-14",
      on: {
        click: function($event) {
          return _vm.$emit("ticket-clicked")
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "row-format" },
        [
          _c("v-icon", { staticClass: "material-symbols-rounded mr-3" }, [
            _vm._v("local_activity")
          ]),
          _vm.client
            ? _c("client-avatar", {
                staticClass: "mr-3",
                attrs: { client: _vm.client }
              })
            : _c("div", { staticStyle: { width: "2px", height: "32px" } }),
          _c(
            "div",
            {
              staticClass: "row-format align-center",
              staticStyle: { "flex-wrap": "wrap", gap: "4px" }
            },
            [
              _c("div", { staticClass: "deliverableName mr-3 brand-medium" }, [
                _vm._v(_vm._s(_vm.ticket.subject))
              ]),
              _c(
                "div",
                { staticClass: "dueDate mr-3 row-format align-center" },
                [
                  _c("v-icon", { attrs: { size: "15", color: "gray_70" } }, [
                    _vm._v("$clock")
                  ]),
                  _c("div", { staticClass: "font-gray_80" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.DateTime.fromISO(
                            _vm.ticket.dueDate
                          ).toLocaleString({ month: "short", day: "numeric" })
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
              _vm.ticket.assignedTo.length
                ? _c(
                    "div",
                    { staticClass: "row-format align-center" },
                    _vm._l(_vm.ticket.assignedTo, function(assignedTo, index) {
                      return _c("assigned-user", {
                        key: assignedTo,
                        style:
                          (index > 0 ? "margin-left: -6px" : "") +
                          "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                          index,
                        attrs: {
                          "show-name": false,
                          small: true,
                          "assigned-to": assignedTo
                        }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "status row-format align-center" }, [
                _c("div", [_vm._v(_vm._s(_vm.ticket.status))])
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }