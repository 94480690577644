var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { to: "/apps", label: "Integrations" }
            ]
          }
        },
        [_vm._v(" Stripe payments ")]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center gap-3 mb-4" },
        _vm._l(_vm.pages, function(page) {
          return _c(
            "div",
            {
              key: page.value,
              class:
                "page-link " +
                (_vm.currentView === page.value ? "page-link-active" : ""),
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.setView(page.value)
                }
              }
            },
            [_c("div", [_vm._v(_vm._s(page.label))])]
          )
        }),
        0
      ),
      _vm.account
        ? _c(
            "div",
            [
              _vm.currentView === "Subscriptions" ||
              _vm.currentView === "Products"
                ? _c(
                    "div",
                    { staticClass: "row-format align-center mb-4" },
                    [
                      _vm.currentView === "Subscriptions"
                        ? _c("subscription-filter", {
                            on: {
                              "clear-filter": function($event) {
                                return _vm.clearFilter()
                              }
                            },
                            model: {
                              value: _vm.filter,
                              callback: function($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter"
                            }
                          })
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "super-action ml-auto",
                          on: {
                            click: function($event) {
                              return _vm.handleAddNew()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v(
                            " Add " +
                              _vm._s(
                                _vm.currentView === "Subscriptions"
                                  ? "subscription"
                                  : "product"
                              )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("stripe-connect", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentView === "Connection",
                    expression: "currentView === 'Connection'"
                  }
                ],
                attrs: { "stripe-integration": _vm.account.stripeIntegration }
              }),
              _vm.isConnected
                ? _c("stripe-products", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentView === "Products",
                        expression: "currentView === 'Products'"
                      }
                    ],
                    ref: "Products"
                  })
                : _vm._e(),
              _vm.isConnected
                ? _c("stripe-subscriptions", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentView === "Subscriptions",
                        expression: "currentView === 'Subscriptions'"
                      }
                    ],
                    ref: "Subscriptions",
                    attrs: {
                      "stripe-integration": _vm.account.stripeIntegration,
                      filter: _vm.filter
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }