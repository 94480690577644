import HttpClient from '@/services/HttpClient';

export default class UserNoteService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getUserNotes() {
		return this.httpClient.get('/userNotes')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getUserNote(id) {
		return this.httpClient.get(`/userNotes/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createUserNote(note) {
		return this.httpClient.post(`/userNotes`, note)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateUserNote(id,note) {
		return this.httpClient.put(`/userNotes/${id}`, note)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteUserNote(id) {
		return this.httpClient.delete(`/userNotes/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


}
