var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ourThread
    ? _c("div", { staticClass: "text-left" }, [
        _c("div", { staticClass: "row-format" }, [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("back")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { size: "24", color: "black" } }, [
                    _vm._v("arrow_back")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ml-auto row-format align-center" },
            [
              _vm.ourFolder !== "Inbox"
                ? _c("div", { staticClass: "mr-2" }, [
                    _vm._v(" " + _vm._s(_vm.ourFolder) + " "),
                    _vm.ourFolder === "Snoozed"
                      ? _c("span", [
                          _vm._v(
                            "until " +
                              _vm._s(
                                _vm.HDateTime.fromISO(
                                  _vm.ourThread.snoozedUntil
                                ).toLocaleString(_vm.HDateTime.DATETIME_MED)
                              )
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _c("inbox-actions", {
                attrs: {
                  size: 24,
                  unread: _vm.ourThread.unread,
                  "snooze-options": _vm.snoozeOptions,
                  folder: _vm.ourFolder,
                  "item-type": "EmailThread",
                  "item-id": _vm.ourThread.id
                },
                on: {
                  "return-to-inbox": function($event) {
                    return _vm.returnToInbox()
                  },
                  snooze: function($event) {
                    return _vm.snooze($event)
                  },
                  archive: function($event) {
                    return _vm.archive()
                  },
                  unread: function($event) {
                    return _vm.markThreadAsRead()
                  }
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "column-format py-2 mt-2" }, [
          _c(
            "div",
            { staticClass: "font-18 brand-medium row-format align-center" },
            [
              !_vm.clientId
                ? _c(
                    "div",
                    _vm._l(_vm.clients, function(client) {
                      return _c("client-avatar", {
                        key: client.id,
                        staticClass: "mr-1",
                        attrs: { client: client, small: "" }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c("div", [_vm._v(_vm._s(_vm.ourThread.subject))]),
              _c(
                "v-btn",
                {
                  staticClass: "ml-auto",
                  attrs: { small: "", text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.generateSummary(false)
                    }
                  }
                },
                [_vm._v("✨Summary")]
              ),
              _vm.showSummary && _vm.summary
                ? _c(
                    "v-icon",
                    {
                      staticClass: "pointer",
                      attrs: { small: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.generateSummary(true)
                        }
                      }
                    },
                    [_vm._v("refresh")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.summary && _vm.showSummary
            ? _c("div", {
                staticClass: "mt-3 email-summary",
                domProps: {
                  innerHTML: _vm._s(_vm.marked(_vm.summary, { breaks: true }))
                }
              })
            : _vm._e()
        ]),
        _c(
          "div",
          {},
          _vm._l(_vm.displayEmails, function(email, index) {
            return _c(
              "div",
              { key: email.id },
              [
                email.type === "PLACEHOLDER"
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            _vm.showExpanded = true
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "expand-row" }, [
                          _c(
                            "div",
                            { staticClass: "expand-count row-format centered" },
                            [_c("div", [_vm._v(_vm._s(email.count))])]
                          )
                        ])
                      ]
                    )
                  : _c("email", {
                      ref: "Email",
                      refInFor: true,
                      staticClass: "email-wrapper py-4",
                      attrs: {
                        email: email,
                        thread: _vm.ourThread,
                        "default-open": index === _vm.displayEmails.length - 1,
                        "user-emails": _vm.userEmails,
                        "email-boxes": _vm.emailBoxes,
                        "opportunity-id": _vm.opportunityId
                      },
                      on: {
                        "email-sent": function($event) {
                          return _vm.emailSent($event)
                        }
                      }
                    })
              ],
              1
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }