<template>
	<div class="row-format centered">
	</div>
</template>

<script>
	import ProjectDeliverableService from './ProjectDeliverableService';
	import DeliverableDetail from './DeliverableDetail';

	export default {
		name: 'DeliverableFullPage',

		props: {
			deliverableId: { type: String },
		},

		components: {  },

		data: function () {
			return {
				projectDeliverableService: new ProjectDeliverableService(),
				client: null,
				project: null,
				deliverable: null,
				deleted: false,
			};
		},

		mounted() {
			this.initialize();
		},

		beforeDestroy() {},

		methods: {
			initialize: function(){
				this.projectDeliverableService
						.getDeliverable(this.deliverableId)
						.then((res) => {
							this.setDeliverable(res.data);
						})
						.catch((err) => {
							console.log(err);
						});
			},

			setDeliverable: function (payload) {
				let binding = {
					client: payload.client,
					project: payload.project,
					deliverableId: this.deliverableId
				}
				this.$store.state.globalModalController.openModal(DeliverableDetail,binding).then(() => {
					setTimeout(() => this.$router.push('/projects'),500);
				});
			},
		},

		computed: {},

		watch: {
			deliverableId: function(){
				this.isReady = false;
				this.initialize();
			}
		},
	};
</script>

<style scoped lang="scss">

</style>
