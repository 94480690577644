import { render, staticRenderFns } from "./ProposalTemplates.vue?vue&type=template&id=54d70692&"
import script from "./ProposalTemplates.vue?vue&type=script&lang=js&"
export * from "./ProposalTemplates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VDataTable,VIcon,VMenu,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3589467384/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('54d70692')) {
      api.createRecord('54d70692', component.options)
    } else {
      api.reload('54d70692', component.options)
    }
    module.hot.accept("./ProposalTemplates.vue?vue&type=template&id=54d70692&", function () {
      api.rerender('54d70692', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/templates/proposals/ProposalTemplates.vue"
export default component.exports