<template>
	<div class="panel-modal" style="width: 600px" v-if="isReady">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click.stop="$emit('result')">$chevronRight</v-icon>
				<div class="brand-medium font-18">{{ $t('recurring.deliverables.heading') }}</div>
				<div class="ml-auto">
					<v-btn v-if="isReady" class="primary-action" @click="saveRecurringDeliverable" :disabled="!deliverable.name">
						{{ $t('global.save-changes') }}
					</v-btn>
					<v-btn class="delete ml-2" icon @click="deleteRecurringDeliverable"><v-icon size="20">$delete</v-icon></v-btn>
				</div>
			</div>
		</div>
		<div class="panel-modal-body text-left show-scrollbar">
			<recurring-event-config :recurring="recurring"></recurring-event-config>
			<hr class="mt-3 mb-3" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" />
			<div class="mb-6 row-format align-center gap-3">
				<div :class="`page-link ${currentView === 'Overview' ? 'page-link-active' : ''}`" @click="setView('Overview')">
					<div>Overview</div>
				</div>
				<div :class="`page-link ${currentView === 'Subtasks' ? 'page-link-active' : ''}`" @click="setView('Subtasks')">
					<div>Subtasks</div>
				</div>
			</div>

			<deliverable-detail
				v-if="currentView === 'Overview'"
				:deliverable="deliverable"
				:fee-schedule="feeSchedule"
				:attachment-service="recurringAttachmentService"
				:project-type="projectType"
        :projectId="projectId"
        :clientId="clientId"
			></deliverable-detail>
			<div v-else-if="currentView === 'Subtasks'">
				<draggable :list="deliverable.subtasks" class="column-format gap-2">
					<deliverable-package-task
						@click="editSubtask(subtask)"
						class="pa-1"
						style="border: 1px solid var(--v-gray_30-base); border-radius: 4px"
						:hide-icon="true"
						v-for="subtask in deliverable.subtasks"
						:key="subtask.id"
						:deliverable="subtask"
						:is-subtask="true"
					>
						></deliverable-package-task
					>
				</draggable>

				<div style="width:fit-content" class="font-14 font-gray_50 pointer mt-3" @click="addSubTask">
					<v-icon size="14" class="mb-1">$plus</v-icon> Add subtask
				</div>
			</div>
		</div>
		<basic-modal :dialog="subtaskEditor" v-if="selectedSubtask">
			<div class="px-5 py-4 column-format gap-3 text-left">
				<div class="row-format align-center">
					<v-icon color="black" class="mr-1 material-symbols-rounded">graph_2</v-icon>
					<div class="brand-medium font-18"><div>Edit subtask</div></div>
					<div class="ml-auto">
						<v-btn class="super-action" @click="saveSubtask(selectedSubtask)">
							{{ $t('global.save') }}
						</v-btn>
						<v-icon size="20" color="gray_60" class="pointer ml-2" @click="confirmDeleteSubtask()">$delete</v-icon>
					</div>
				</div>
				<deliverable-detail
						:hide-task-name="false"
						:fee-schedule="feeSchedule"
						:deliverable="selectedSubtask"
						:attachment-service="recurringAttachmentService"
						:project-type="projectType"
						:is-subtask="true"
            :projectId="projectId"
            :clientId="clientId"
				></deliverable-detail>
			</div>
		</basic-modal>
	</div>
</template>

<script>
	import RecurringEventMixin from '@/modules/recurrence/RecurringEventMixin';
	import RecurringEventConfig from '@/modules/recurrence/RecurringEventConfig';
	import DeliverableDetail from '@/modules/templates/deliverables/DeliverableDetail';
	import { v4 as uuidv4 } from 'uuid';
	import ConfirmModal from '@/components/ConfirmModal';
	import RecurringAttachmentService from '@/modules/recurrence/RecurringAttachmentService';
	import DeliverablePackageTask from '@/modules/templates/deliverables/DeliverablePackageTask';
	import draggable from 'vuedraggable';
	import BasicModal from "@/components/BasicModal";

	export default {
		name: 'RecurringDeliverable',
		isRightModal: true,

		props: ['projectId', 'clientId', 'id', 'feeSchedule', 'projectType'],

		mixins: [RecurringEventMixin],

		components: { DeliverableDetail, RecurringEventConfig, draggable, DeliverablePackageTask, BasicModal },

		data: function() {
			return {
				currentView: 'Overview',
				deliverable: null,
				recurring: null,
				isReady: false,
				recurringAttachmentService: null,
				subtaskEditor: false,
				selectedSubtask: null,
			};
		},

		mounted() {
			this.initialize();
		},

		beforeDestroy() {},

		methods: {
			setView: function(view) {
				this.currentView = view;
			},

			initialize: function() {
				if (this.id) {
					this.getRecurringEvent(this.id).then((res) => {
						this.deliverable = res.data.deliverable.deliverable;
						this.recurring = res.data;
						this.isReady = true;
						this.recurringAttachmentService = new RecurringAttachmentService(this.recurring);
					});
				} else {
					this.deliverable = this.initializeDeliverable();
					this.recurring = this.initRecurring('DELIVERABLE');
					this.saveRecurringDeliverable(false);
				}
			},

			initializeDeliverable: function() {
				let defaultStatus = this.projectType.statusList[0].id; // Get the first element in the statusList array
				return {
					id: uuidv4().replace(/-/g, ''),
					name: null,
					description: null,
					dueDate: {
						duration: null,
						timeUnit: 'WEEKS',
					},
					statusId: defaultStatus,
					tasks: [],
					assignedToList: [],
				};
			},

			saveRecurringDeliverable: function(close = true) {
				let payload = {
					projectId: this.projectId,
          clientId: this.projectId,
					deliverable: this.deliverable,
				};

				this.saveRecurringEvent(this.recurring, payload, 'DELIVERABLE').then((res) => {
					if (close) {
						this.$emit('result', res.data);
					} else {
						this.deliverable = res.data.deliverable.deliverable;
						this.recurring = res.data;
						this.isReady = true;
						this.recurringAttachmentService = new RecurringAttachmentService(this.recurring);
					}
				});
			},

			deleteRecurringDeliverable: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this recurring deliverable?',
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.deleteRecurringEvent(this.id).then(() => {
							this.recurring.deleted = true;
							this.$emit('result', this.recurring);
						});
					}
				});
			},

			editSubtask: function(subtask){
				this.subtaskEditor = true;
				this.selectedSubtask = subtask;
			},

			saveSubtask: function(){
				this.subtaskEditor = false;
				this.selectedSubtask = null;
			},

			confirmDeleteSubtask: function(){
				let binding = {
					headingText: 'Delete?',
					bodyText: 'Are you sure you want to remove this subtask?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if(res){
						let ix = this.deliverable.subtasks.findIndex(s => s.id === this.selectedSubtask.id);
						if(ix > -1){
							this.deliverable.subtasks.splice(ix,1);
							this.subtaskEditor = false;
							this.selectedSubtask = null;
						}
					}
				})
			},

			addSubTask: function(){
				let defaultStatus = this.projectType.statusList[0].id;
				let subtask = {
					id: uuidv4().replace(/-/g, ''),
					name: null,
					description: null,
					priority: 0,
					statusId: defaultStatus,
					assignedToList: [],
					dueDate: {
						duration: null,
						timeUnit: 'WEEKS',
					},
					tasks: [],
					files: [],
					customValues: [],
					isRecurring: false,
				}
				this.deliverable.subtasks.push(subtask);
				this.editSubtask(subtask);
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	#recurring-deliverable-detail-modal {
		max-width: 500px;
		.label-style {
			margin-top: 8px;
			font-weight: 500;
			font-size: 12px;
			color: var(--v-gray_80-base);
			text-align: left;
		}
		.half {
			> div {
				flex: 0 1 50%;
			}
		}
	}
</style>
