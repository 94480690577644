<template>
	<div id="proposal-share-send-widget">
		<v-btn icon class="close-dialog" @click="cancel()">
			<v-icon>$close</v-icon>
		</v-btn>

		<div v-if="proposal.proposalStatus === 'Draft'">
			<div class="modal-padding">
				<div class="modal-title">{{ $t('proposal.share.activate-title', { mode: mode }) }}</div>
				<div class="modal-subtitle">{{ $t('proposal.share.activate-message') }}</div>
			</div>
			<div class="modal-footer">
				<v-btn min-width="120" class="secondary-action mr-1" @click="cancel()">{{ $t('global.cancel') }}</v-btn>
				<v-btn min-width="120" class="primary-action ml-1" @click="finalize()">
					{{ $t('global.finalize') }}
				</v-btn>
			</div>
		</div>

		<div v-if="proposal.proposalStatus !== 'Draft' && mode === 'Select'">
			<div class="modal-padding">
				<div class="modal-title">{{ $t('proposal.share.select-method') }}</div>

				<div class="selector-tile" @click="mode = 'Send'">
					<div><v-icon>$email</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('proposal.share.send-title') }}</h3>
						<div class="modal-subtitle">{{ $t('proposal.share.send-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('proposal.share.send-next') }}</div>
					</div>
				</div>
				<div class="selector-tile" @click="mode = 'Share'">
					<div><v-icon>$share</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('proposal.share.share-title') }}</h3>
						<div class="modal-subtitle">{{ $t('proposal.share.share-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('proposal.share.share-next') }}</div>
					</div>
				</div>
				<div class="selector-tile" @click="requestPrint()">
					<div><v-icon>print</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('proposal.share.print-title') }}</h3>
						<div class="modal-subtitle">{{ $t('proposal.share.print-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('proposal.share.print-next') }}</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="proposal.proposalStatus !== 'Draft' && mode === 'Share'">
			<div class="modal-padding">
				<div class="modal-title">{{ $t('proposal.share.share-title') }}</div>

				<div class="d-flex align-center justify-space-between my-5">
					<div style="flex: 1 1 auto" class="mr-1">
						<input type="text" :value="shareableLink" class="link-box" id="linkBox" readonly />
					</div>
					<div style="flex: 0 1 auto" class="ml-1">
						<v-btn class="super-action" @click="copyLink()">{{ $t('proposal.share.copy-link') }}</v-btn>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<v-btn class="primary-action" style="width: 140px" @click="cancel()">
					{{ $t('global.done') }}
				</v-btn>
			</div>
		</div>

		<div v-if="proposal.proposalStatus !== 'Draft' && mode === 'Send'">
			<div class="modal-padding">
				<div class="modal-title mb-2">{{ $t('proposal.share.send-title') }}</div>

				<v-row class="rowFormat">
					<v-col cols="12">
						<span class="emailHeader">{{ $t('global.email-from') }}: </span>
						<span>{{ $store.getters.getLoggedInUserName }} &lt;{{ $store.getters.getFromEmail }}&gt;</span>
					</v-col>
				</v-row>
				<v-divider class="mt-1 mb-2"></v-divider>
				<v-row class="rowFormat">
					<v-col cols="10">
						<div style="display: flex; flex-direction: row; flex-wrap: wrap;" @click="toMenu = true">
							<span class="emailHeader">{{ $t('global.email-to') }}: </span>
							<div v-for="contact in toEmail" :key="contact.email" class="emailPill">
								{{ contact.email }}
								<v-icon
									class="ml-1"
									color="var(--v-blue_90-base)"
									small
									@click.native="deselectEmail($event, contact)"
									style="cursor:pointer;"
									>$closeSmall</v-icon
								>
							</div>
						</div>
					</v-col>
					<v-col cols="2" class="text-right pr-3">
						<v-menu v-model="toMenu" :close-on-content-click="false" nudge-left="418" elevation="1" nudge-top="12">
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon small rounded v-bind="attrs" v-on="on">
									<v-icon small>$chevronDown</v-icon>
								</v-btn>
							</template>
							<div style="background-color: var(--v-white-base);width:400px;">
								<v-container v-if="toEmailMode === 'selectContact'">
									<v-row>
										<v-col cols="10" class="pl-5" align="left">
											<v-checkbox
												v-for="contact in contacts"
												v-model="toEmail"
												:key="contact.email"
												:hide-details="true"
												:label="formatEmail(contact)"
												:value="contact"
												dense
											></v-checkbox>
											<v-btn
												text
												large
												color="primary"
												class="pl-1 mt-4"
												@click="toEmailMode = 'createContact'"
												><v-icon small class="mr-2">$plus</v-icon> Add new contact</v-btn
											>
										</v-col>
										<v-col cols="2" align="right">
											<v-btn icon rounded @click="closeToMenu()">
												<v-icon small>$chevronUp</v-icon>
											</v-btn>
										</v-col>
									</v-row>
								</v-container>
								<div v-if="toEmailMode === 'createContact'">
									<div class="modal-padding">
										<div class="modal-title mb-2">Create new contact</div>

										<v-btn icon class="close-dialog" @click="closeToMenu()">
											<v-icon small>$chevronUp</v-icon>
										</v-btn>

										<div class="d-flex">
											<div class="pr-1">
												<v-text-field
													class="h-outline"
													hide-details
													persistent-placeholder
													v-model="newContact.firstName"
													label="First name"
												></v-text-field>
											</div>
											<div class="pl-1">
												<v-text-field
													class="h-outline"
													hide-details
													persistent-placeholder
													v-model="newContact.lastName"
													label="Last name"
												></v-text-field>
											</div>
										</div>
										<v-text-field
											class="h-outline"
											hide-details
											persistent-placeholder
											v-model="newContact.email"
											label="Email"
										></v-text-field>
									</div>
									<div class="modal-footer">
										<v-btn class="secondary-action mr-1" width="120" @click="cancelCreateNewContact()"
											>Cancel</v-btn
										>
										<v-btn class="primary-action ml-1" width="120" @click="createNewContact()">Save</v-btn>
									</div>
								</div>
							</div>
						</v-menu>
					</v-col>
				</v-row>
				<v-divider class="mt-1 mb-2"></v-divider>
				<v-row class="rowFormat">
					<v-col cols="12">
						<div style="display: flex; flex-direction: row;">
							<span class="emailHeader">{{ $t('global.email-subject') }}: </span>
							<input v-model="subject" type="text" style="outline: none; width: 100%;color:var(--v-black-base)" />
						</div>
					</v-col>
				</v-row>
				<v-divider class="mt-1 mb-2"></v-divider>
				<v-row class="rowFormat">
					<v-col cols="12">
						<div>
							<editor v-if="initialEmailContent"
								ref="emailContent"
								:api-key="$store.getters.getTinyMceKey"
								:inline="false"
								:initial-value="initialEmailContent"
								:init="mceConfigText"
								:spellcheck="true"
							></editor>
						</div>
					</v-col>
				</v-row>
			</div>
			<div class="modal-footer">
				<v-btn class="secondary-action mr-1" width="120" @click="cancel()" :disabled="disabled">Cancel</v-btn>
				<v-btn class="super-action ml-1" width="120" @click="sendEmail()" :disabled="toEmail.length === 0 || disabled"
					>Send</v-btn
				>
			</div>
		</div>
	</div>
</template>

<script>
	import EmailTemplateSelector from "@/modules/templates/emails/EmailTemplateSelector";

	export default {
		name: 'ShareWidget',
		props: ['proposal', 'proposalStatus', 'proposalService', 'clientService'],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function() {
			return {
				mode: 'Select',
				disabled: false,
				toMenu: false,
				shareableLink: null,
				toEmail: [],
				contacts: [],
				toEmailMode: 'selectContact',
				initialEmailContent: null,
				subject: this.proposal.coverPage.heading.replace(/&nbsp;/g, ' ') + ' by ' + this.proposal.accountMini.accountName,

				newContact: {
					firstName: null,
					lastName: null,
					email: null,
				},
				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					paste_as_text: false,
					forced_root_block: false,
					paste_data_images: true,
					browser_spellcheck: true,
					plugins: ['paste', 'lists', 'link', 'table'],
					contextmenu: 'paste | link | table',
					branding: false,
					height: `400px`,
					width: '750px',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						`styleselect forecolor | bold italic underline | alignleft aligncenter alignright alignjustify',
						'table | bullist numlist | link unlink | ${this.$store.getters.isPaidAccount ? 'template' : ''}`,
					],
					table_toolbar:
							'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('template', {
							text: 'Insert template',
							onAction: () => {
								this.selectEmailTemplate();
							},
						});
					},
				},
			};
		},

		mounted() {
			this.clientService.getClientDetail(this.proposal.clientMini.id).then((res) => {
				this.client = res.data;
				this.contacts = this.client.contacts;
				this.setShareableLink();
			});
		},

		methods: {
			closeToMenu: function() {
				this.toMenu = false;
				this.toEmailMode = 'selectContact';
			},

			getEmailLink: function(){
				let heading = this.proposal.coverPage.heading.replace('&nbsp;','').trim();
				if(heading){
					return heading;
				}else{
					return 'View proposal';
				}
			},

			deselectEmail: function(event, contact) {
				event.stopPropagation();

				let ix = this.toEmail.indexOf(contact);
				if (ix > -1) {
					this.toEmail.splice(ix, 1);
				}
			},

			selectEmailTemplate: function(){
				let binding = {
					contacts: this.toEmail,
					clientId: this.proposal.clientMini.id,
					proposalId: this.proposal.id,
				};
				this.$store.state.globalModalController.openModal(EmailTemplateSelector,binding).then((res) => {
					if(res){
						this.subject = res.subject;
						this.$refs.emailContent.editor.setContent('');
						this.$refs.emailContent.editor.execCommand('mceInsertContent', false, res.htmlContent);
					}
				});
			},

			formatEmail: function(contact) {
				return contact.firstName + ' ' + contact.lastName + ' <' + contact.email + '>';
			},

			cancel: function() {
				this.$emit('cancel');
			},

			finalize: function() {
				this.$emit('finalize');
			},

			requestPrint: function() {
				window.open(this.shareableLink, '_blank');
				this.$emit('cancel');
			},

			sendEmail: function() {
				if (this.toEmail.length === 0) {
					this.alert('warning', 'At least one recipient is required.');
					return;
				}

				this.disabled = true;
				this.$store.commit('startLoading');
				let email = {
					to: this.toEmail,
					subject: this.subject,
					body: this.$refs.emailContent.editor.getContent()
				};

				this.proposalService
					.sendEmail(this.client.id, this.proposal.id, email)
					.then(() => {
						this.toEmail = [];
						this.cancel();
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
						this.cancel();
						this.disabled = false;
					});
			},

			copyLink: function() {
				navigator.clipboard.writeText(this.shareableLink);
				this.$store.commit('success', this.$t('proposal.share.link-copied'));
				this.cancel();
			},

			setShareableLink: function() {
				if (this.proposal.proposalStatus !== 'Draft') {
					this.proposalService
						.getShareableLink(this.client.id, this.proposal.id)
						.then((res) => {
							this.shareableLink = res.data.link;
							this.setInitialEmailContent();
						})
						.catch((err) => {
							this.alert('error', err.response.data.message);
						});
				}
			},

			setInitialEmailContent: function(){
				this.initialEmailContent = `Hi ${this.proposal.clientMini.name} Team,<br>I've created a proposal that I hope works well.  Please click the personalized link below.`
				this.initialEmailContent = this.initialEmailContent + `<br><br>`
				this.initialEmailContent = this.initialEmailContent + `<a href="${this.shareableLink}" target="_blank">${this.getEmailLink()}</a>`
				this.initialEmailContent = this.initialEmailContent + `<br><br>`
				this.initialEmailContent = this.initialEmailContent + `Thanks,<br>${this.$store.getters.getLoggedInUserName}`
			},

			cancelCreateNewContact: function() {
				this.newContact = {
					firstName: null,
					lastName: null,
					email: null,
				};

				this.toEmailMode = 'selectContact';
			},

			createNewContact: function() {
				let opList = [];
				let operation = {};

				operation.op = 'add';
				operation.path = '/contacts/-';
				operation.value = this.newContact;

				opList.push(operation);

				this.clientService
					.patchClient(this.client.id, opList)
					.then((res) => {
						this.contacts = res.data.contacts;

						for (let i = 0; i < this.contacts.length; i++) {
							if (this.contacts[i].email === this.newContact.email) {
								this.toEmail.push(this.contacts[i]);
								break;
							}
						}
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => {
						this.cancelCreateNewContact();
					});
			},

			alert: function(type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},
		},

		watch: {
			proposalStatus: function() {
				this.setShareableLink();
			},
		},
	};
</script>

<style scoped lang="scss">
	#proposal-share-send-widget {
		background-color: var(--v-white-base);
		width: 800px; // was 800

		.selector-tile {
			cursor: pointer;
			border: 1px solid var(--v-gray_50-base);
			border-radius: 4px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 16px 8px 12px;
			margin: 8px 0;

			&:hover {
				background-color: var(--v-gray_20-base);
				border-color: var(--v-gray_20-base);
				svg {
					--icon-color: var(--v-primary-base);
					color: var(--v-primary-base);
				}
			}
			> div:first-child {
				padding-right: 12px;
				padding-left: 4px;
			}
			> div:last-child {
				text-align: left;
			}
		}
		.link-box {
			//width: 500px;
			border-radius: 4px;
			background-color: var(--v-gray_20-base);
			height: 32px;
			width: 100%;
			padding-left: 8px;
			//padding: 15px;
		}
	}
	.emailPill {
		background-color: var(--v-blue_10-base);
		border-radius: 8px;
		color: var(--v-blue_90-base);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 8px;
		margin: 0 8px 0 0;
	}

	.rowFormat {
		text-align: left;
		padding: 12px 0;
		//border-bottom: 1px solid var(--v-gray_30-base);
	}

	.v-menu__content {
		border-radius: 4px;
		border: 1px solid var(--v-gray_50-base);
	}

	.emailHeader {
		margin-right: 10px;
		color: var(--v-gray_70-base);
	}
</style>
