<template>
	<div @click="$emit('click', $event)" :style="`width: 100%; text-align: ${item.alignment}; padding-top: ${item.pt}px; padding-bottom: ${item.pb}px!important`" v-if="isConditionMet">
		<img v-if="item.url" :src="item.url" :style="`width:${item.scale}%; border-radius: ${item.br}px`">
		<div v-else><v-icon class="material-symbols-rounded" size="100">image</v-icon></div>
	</div>
</template>

<script>
import RendererMixin from "@/modules/forms/v2/schema/RendererMixin";

export default {
	name: "ImageBlock",

	props: ['item', 'results','builder'],

	mixins: [RendererMixin],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>