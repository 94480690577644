<template>
	<div id="create-package-from-proposal-deliverables">
		<div class="modal-padding pb-0">
			<div class="row-format mb-2">
				<div class="brand-medium font-18">{{ $t('templates.save-as-deliverable-package') }}</div>
				<div class="ml-auto"><v-icon color="gray_80" size="20" @click="$emit('result')">$close</v-icon></div>
			</div>
			<v-text-field
				:label="$t('templates.create-from-project-modal.template-name-label')"
				:placeholder="$t('templates.create-from-project-modal.template-name-placeholder')"
				ref="templateName"
				autofocus
				hide-details
				class="h-outline"
				persistent-placeholder
				@keyup.enter.native.prevent="save()"
				v-model="name"
			/>
			<div v-if="templateList.length" class="brand-semibold text-left font-12 mt-4">
				{{ $t('templates.create-from-project-modal.existing-templates') }}
			</div>
		</div>
		<div id="item-wrapper">
			<div
				v-for="item in templateList"
				:key="item.id"
				:class="templateToDelete && templateToDelete.id === item.id ? 'selected' : ''"
				@click="toggleDelete(item)"
			>
				{{ item.name }}
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="delete mr-1" width="160" @click="$emit('close')">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="primary-action ml-1" width="160" @click="save()" :disabled="!name">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import TemplateService from '@/modules/templates/TemplateService';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'CreatePackageFromProposalDeliverablesModal',
		components: {},

		data() {
			return {
				templateService: new TemplateService(),
				name: null,
				templateList: [],
				templateToDelete: null,
				newTemplate: null,
			};
		},

		props: { deliverables: { required: true, type: Object } },

		mounted() {
			this.getTemplateList();
			this.name = this.deliverables.header;
		},

		methods: {
			save() {
				if (!this.name) return;

				if (this.templateToDelete) {
					let binding = {
						headingText: this.$t('templates.overwrite-package-title'),
						bodyText: this.$t('templates.overwrite-package-body', { name: this.templateToDelete.name }),
						yesText: this.$t('templates.overwrite-button'),
						noText: this.$t('global.cancel'),
					};
					this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
						if (res) {
							let createResult = this.createTemplate();
							let deleteResult = this.deleteTemplate();

							Promise.all([createResult, deleteResult]).then(() => {
								this.$emit('result', this.newTemplate);
							});
						}
					});
					return;
				}

				this.createTemplate().then(() => {
					this.$emit('result', this.newTemplate);
				});
			},

			toggleDelete(template) {
				if (this.templateToDelete && template.id === this.templateToDelete.id) {
					this.templateToDelete = null;
					this.name = null;
					return;
				}
				this.templateToDelete = template;
				this.name = template.name;
			},
			getTemplateList() {
				this.templateService
					.getDeliverablePackages()
					.then((res) => {
						this.templateList = [];
						this.templateList.push(...res.data);
						this.templateList.sort(this.sortByName);
						this.isReady = true;
					})
					.catch((err) => {
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
			sortByName: function(a, b) {
				let aName = a.name.toLowerCase();
				let bName = b.name.toLowerCase();
				if (aName > bName) {
					return 1;
				} else if (aName < bName) {
					return -1;
				} else {
					return 0;
				}
			},
			async createTemplate() {
				this.deliverables.header = this.name;
				await this.templateService
					.createTemplateFromProposalDeliverables(this.deliverables)
					.then((res) => {
						if (res.data) {
							this.newTemplate = res.data;
							return true;
						}
					})
					.catch((e) => {
						console.log(e);
					})
					.finally(() => {});
			},
			async deleteTemplate() {
				// let data = { projectId: this.projectId, name: this.name };
				if (!this.templateToDelete.id) return;
				await this.templateService
					.deletePackage(this.templateToDelete.id)
					.then(() => {
						return true;
					})
					.catch((e) => {
						console.log(e);
					});
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	#create-package-from-proposal-deliverables {
		width: 450px;

		#item-wrapper {
			margin-bottom: 16px;

			> div {
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 0 24px;
				color: var(--v-gray_80-base);
				font-weight: 500;
				cursor: pointer;
				&.selected {
					color: var(--v-primary-base);
					background-color: var(--v-gray_20-base);
				}
			}
			> div:not(:first-child) {
				border-top: 1px solid var(--v-gray_50-base);
			}
		}
	}
</style>
