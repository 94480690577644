var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "max-height": "calc(var(--vh) - 150px)",
        "overflow-y": "scroll"
      }
    },
    [
      _c(
        "div",
        { staticClass: "row-format mb-3" },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("back")
                }
              }
            },
            [_c("v-icon", [_vm._v("$arrowLeft")])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.openContactEdit()
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "gray_70", size: "20" } }, [
                _vm._v("$edit")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "column-format", staticStyle: { gap: "8px" } }, [
        _vm.name
          ? _c(
              "div",
              { staticClass: "row-format align-center mb-3" },
              [
                _c("v-avatar", { attrs: { color: "secondary", size: "30" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "font-12",
                      staticStyle: { color: "var(--v-white-base)" }
                    },
                    [_vm._v(_vm._s(_vm.initials))]
                  )
                ]),
                _c("div", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.name))])
              ],
              1
            )
          : _vm._e(),
        _vm.client
          ? _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c("client-avatar", {
                  staticClass: "ml-1",
                  attrs: { small: "", client: _vm.client }
                }),
                _c("div", { staticClass: "ml-2 font-14" }, [
                  _vm._v(_vm._s(_vm.client.name))
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.person.phone
          ? _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c("v-icon", { staticClass: "ml-1" }, [_vm._v("phone")]),
                _c("div", { staticClass: "ml-2 font-14" }, [
                  _vm._v(_vm._s(_vm.formattedPhone))
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.person.email
          ? _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c("v-icon", { staticClass: "ml-1" }, [_vm._v("email")]),
                _c("div", { staticClass: "ml-2 font-14" }, [
                  _vm._v(_vm._s(_vm.person.email))
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("person-communications", {
            attrs: { person: _vm.person },
            on: {
              "view-communication": function($event) {
                return _vm.$emit("view-communication", $event)
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }