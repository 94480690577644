var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "" + (_vm.row ? "row-format" : "column-format") },
    [
      _vm._l(_vm.sortedNotifications, function(item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "pb-2 pr-2",
            staticStyle: { "min-height": "100%" }
          },
          [
            _c(
              item.notificationType,
              _vm._b(
                {
                  key: item.notificationType,
                  tag: "component",
                  staticStyle: { "min-height": "100%" }
                },
                "component",
                item,
                false
              )
            )
          ],
          1
        )
      }),
      _vm.sortedNotifications.length === 0
        ? _c(
            "div",
            {
              staticClass: "column-format centered mb-6 mt-6",
              staticStyle: { "align-self": "center" }
            },
            [
              _c("v-icon", [_vm._v("$partyPopper")]),
              _c("div", { staticClass: "font-gray_70" }, [
                _vm._v("You're up to date!")
              ])
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }