var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.source,
            expression: "source"
          }
        ],
        ref: "source",
        attrs: { type: "hidden", "df-source": "" },
        domProps: { value: _vm.source },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.source = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.template_id,
            expression: "template_id"
          }
        ],
        ref: "template_id",
        attrs: { type: "hidden", "df-template_id": "" },
        domProps: { value: _vm.template_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.template_id = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.template_name,
            expression: "template_name"
          }
        ],
        ref: "template_name",
        attrs: { type: "hidden", "df-template_name": "" },
        domProps: { value: _vm.template_name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.template_name = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.project_name,
            expression: "project_name"
          }
        ],
        ref: "project_name",
        attrs: { type: "hidden", "df-project_name": "" },
        domProps: { value: _vm.project_name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.project_name = $event.target.value
          }
        }
      }),
      _c("node-label", {
        on: {
          open: function($event) {
            return _vm.open()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "material-symbols-rounded mr-1",
                    attrs: { color: "black", size: "20" }
                  },
                  [_vm._v("folder_special")]
                ),
                _c("div", [_vm._v("Create project")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("node-modal", {
        ref: "nodeModal",
        attrs: { title: "Create project" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "column-format gap-2" },
                  [
                    _c("v-select", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        "persistent-placeholder": "",
                        label: "Source",
                        items: _vm.types,
                        "item-text": "label",
                        "item-value": "value"
                      },
                      model: {
                        value: _vm.source,
                        callback: function($$v) {
                          _vm.source = $$v
                        },
                        expression: "source"
                      }
                    }),
                    _vm.source === "FromTemplate"
                      ? _c(
                          "div",
                          { staticClass: "column-format gap-2" },
                          [
                            _c("v-select", {
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                                label: "Template",
                                items: _vm.templates,
                                "item-text": "name",
                                "item-value": "id"
                              },
                              model: {
                                value: _vm.template_id,
                                callback: function($$v) {
                                  _vm.template_id = $$v
                                },
                                expression: "template_id"
                              }
                            }),
                            _c("v-text-field", {
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                "persistent-placeholder": "",
                                label: "Project name",
                                placeholder: "Optional"
                              },
                              model: {
                                value: _vm.project_name,
                                callback: function($$v) {
                                  _vm.project_name = $$v
                                },
                                expression: "project_name"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }