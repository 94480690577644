<template>
	<div class="mb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { to: '/pipeline/opportunities', label: 'Sales pipeline' }]">
			Settings
		</page-header>

		<div class="row-format mb-4 gap-3 flex-wrap">
			<div
					:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
					v-for="page in pages"
					:key="page.value"
					@click.stop="currentView = page.value"
			>
				<div>{{ page.label }}</div>
			</div>

			<v-btn class="ml-auto super-action" @click="addNew()"><v-icon size="20">add</v-icon> Add {{currentView === 0 ? 'stage' : 'to-do'}}</v-btn>
		</div>

		<stages v-if="currentView === 0" ref="Stages"></stages>
		<default-to-dos v-if="currentView === 1" ref="ToDo"></default-to-dos>
	</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Stages from "@/modules/pipeline/settings/Stages";
import DefaultToDos from "@/modules/pipeline/settings/DefaultToDos";

export default {
	name: "PipelineSettings",

	props: [],

	components: {DefaultToDos, Stages, PageHeader},

	data: function () {
		return {
			currentView: 0
		}
	},

	mounted() {
		this.setPage();
	},

	beforeDestroy() {
	},

	methods: {
		addNew: function(){
			if(this.currentView === 0){
				this.$refs.Stages.addStage();
			}else{
				this.$refs.ToDo.addToDo();
			}
		}
	},

	watch: {
		'$route.query': function(){
			this.setPage();
		}
	},

	computed: {
		pages: function() {
			let result = [];
			result.push({ label: 'Stages', value: 0 });
			result.push({ label: 'Default to-dos', value: 1});

			return result;
		},
	},

}
</script>

<style scoped lang="scss">

</style>