import HttpClient from '@/services/HttpClient';

export default class CustomFontService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getCustomFonts() {
		return this.httpClient
			.get('/fonts')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	uploadCustomFonts(fonts){
		let formData = new FormData();
		fonts.forEach(a => {
			formData.append('fonts',a);
		})

		return this.httpClient
			.post(`/fonts`,formData,{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteCustomFont(id) {
		return this.httpClient
			.delete(`/fonts/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
