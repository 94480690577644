<template>
	<div>
		<vue-dropzone
			class="ma-3"
			v-if="!logo"
			ref="fileDropzone"
			id="dropzone"
			v-on:vdropzone-success="uploadSuccess"
			v-on:vdropzone-error="uploadFailure"
			v-on:vdropzone-sending="addFileUploadAuthHeader"
			:use-custom-slot="true"
			:options="dropzoneOptions"
		>
			<div>
				<v-icon>$vuetify.icons.upload</v-icon><br />
				<span class="ml-2" style="font-size: 0.8em">{{ $t('account.logo-upload') }}</span>
			</div>
		</vue-dropzone>

		<div class="accountLogo" v-if="logo" style="position: relative">
			<v-img :src="logo" style="max-width: 250px"></v-img>

			<v-btn
				icon
				class="delete logoDelete"
				style="position: absolute; top: 35%; left: 35%"
				@click="deleteAccountLogo(true)"
			>
				<v-icon>$delete</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
	import vueDropzone from 'vue2-dropzone';
	import AccountService from "@/modules/account/AccountService";

	export default {
		name: 'LogoUploader',

		props: ['account', 'lightMode'],

		components: { vueDropzone },

		data: function() {
			return {
				accountService: new AccountService()
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			uploadSuccess: function(file) {
				this.$refs.fileDropzone.removeFile(file);
				this.$emit('updated');
			},

			uploadFailure: function(file, message) {
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.fileDropzone.removeFile(file);
			},

			deleteAccountLogo: function() {
				this.accountService
					.deleteLogo(this.lightMode)
					.then(() => {
						this.$emit('updated');
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},
		},

		computed: {
			logo: function() {
				if (this.lightMode) {
					return this.account.accountLogo;
				} else {
					return this.account.accountLogoDark;
				}
			},

			dropzoneOptions: function() {
				return {
					url: `${this.$store.getters.getBaseUrl}/logo?lightMode=${this.lightMode}`,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},
		},
	};
</script>

<style scoped lang="scss"></style>
