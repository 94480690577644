<template>
	<div class="text-left">
		<div v-if="tickets.length" class="mb-5">
			<div class="font-gray_80 font-14 brand-medium">Recent tickets</div>
			<div v-for="ticket in tickets" :key="ticket.id" @click="openTicket(ticket)" class="px-1 font-12 py-2 font-gray_80 recent">
				<div class="row-format align-center">
					<v-icon color="gray_70" size="20">$ticket</v-icon>
					<div class="ml-auto">{{ $formatters.formatTimeAgo(ticket.updated) }}</div>
				</div>
				<div>{{ticket.subject}}</div>
			</div>
			<div class="row-format centered mt-2" v-if="tickets.length >= maxTickets">
				<v-btn small text @click="showMoreTickets()" color="gray_80">+ Show more</v-btn>
			</div>
		</div>

		<div class="font-gray_80 font-14 brand-medium">Recent conversations</div>
		<div v-for="comm in communications" :key="comm.id" @click="$emit('view-communication',comm)" class="px-1 font-12 py-2 font-gray_80 recent">
			<div class="row-format align-center">
				<v-icon color="gray_70" size="20">{{getIcon(comm)}}</v-icon>
				<div class="ml-auto">{{ $formatters.formatTimeAgo(comm.date) }}</div>
			</div>
			<div>{{getHeading(comm)}}</div>
		</div>
		<div v-if="communications.length === 0" class="font-gray_70 font-12 mt-2">
			No conversations found...
		</div>
		<div class="row-format centered mt-2" v-if="communications.length >= maxRecords">
			<v-btn small text @click="showMore()" color="gray_80">+ Show more</v-btn>
		</div>
	</div>
</template>

<script>
import CommunicatorService from "@/modules/communicator/CommunicatorService";
import CommunicatorTicketService from "@/modules/communicator/CommunicatorTicketService";

export default {
	name: "PersonCommunications",

	props: ['person'],

	components: {},

	data: function () {
		return {
			communicatorService: new CommunicatorService(),
			ticketService: new CommunicatorTicketService(),
			maxRecords: 5,
			maxTickets: 2,
			communications: [],
			tickets: [],
		}
	},

	mounted() {
		this.getRecentCommunications();
		this.getRecentTickets();
	},

	beforeDestroy() {
	},

	methods: {
		showMore: function(){
			this.maxRecords = this.maxRecords+5;
			this.getRecentCommunications();
		},

		showMoreTickets: function(){
			this.maxTickets = this.maxTickets+3;
			this.getRecentTickets();
		},

		getRecentTickets: function(){
			if(this.person.clientPortalUserId) {
				this.ticketService.getRecentTicketsForContact(this.person.clientPortalUserId,this.maxTickets).then((res) => {
					this.tickets.splice(0, this.tickets.length);
					this.tickets.push(...res.data);
				})
			}
		},

		getRecentCommunications: function(){
			this.communicatorService.getRecentCommunications(this.person.email,this.person.phone,this.maxRecords).then((res) => {
				this.communications.splice(0,this.communications.length);
				this.communications.push(... res.data);
			})
		},

		getIcon: function(comm){
			if(comm.type === 'MessageThread'){
				return 'chat';
			}else if(comm.type === 'EmailThread'){
				return 'email'
			}else{
				return 'phone'
			}
		},

		getHeading: function(comm){
			if(comm.type === 'MessageThread'){
				return comm.payload.lastMessageText;
			}else if(comm.type === 'EmailThread'){
				return comm.payload.subject
			}else{
				return comm.payload.direction + ' call -  ' + comm.payload.callResult;
			}
		},

		openTicket: function(ticket){
			let comm = {
				id: ticket.id,
				type: 'Ticket',
				channel: 'Tickets',
				date: ticket.updated,
				payload: ticket,
				unread: ticket.unread,
				archived: ticket.archived,
				snoozedUntil: ticket.snoozedUntil
			}

			this.$emit('view-communication',comm);
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">
	.recent {
		border-bottom: 1px solid var(--v-gray_50-base);
		cursor: pointer;
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>