var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-4", attrs: { id: "main-calendar" } },
    [
      _c("div", { attrs: { id: "calendar-header-wrapper" } }, [
        _c(
          "div",
          { staticClass: "row-format align-center gap-2" },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  "nudge-bottom": 0,
                  elevation: 0,
                  "content-class": "add-new-menu",
                  bottom: "",
                  left: "",
                  rounded: "",
                  "offset-overflow": "",
                  "offset-y": "",
                  transition: "slide-y-transition"
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticStyle: {
                                "min-height": "40px",
                                "background-color": "var(--v-white-base)"
                              },
                              attrs: {
                                elevation: "0",
                                outlined: "",
                                color: "gray_30"
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "span",
                              { class: "font-14 font-secondary brand-medium" },
                              [_vm._v(_vm._s(_vm.currentViewLabel))]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mx-1",
                                attrs: { "x-small": "", color: "secondary" }
                              },
                              [_vm._v("$arrowDown")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c(
                  "div",
                  { staticClass: "add-new-dropdown" },
                  _vm._l(_vm.views, function(view, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "add-new-item",
                        on: {
                          click: function($event) {
                            return _vm.changeView(view.value)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(view.label) + " ")]
                    )
                  }),
                  0
                )
              ]
            ),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-content-click": false,
                  "close-on-click": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticStyle: {
                                "min-height": "40px",
                                "background-color": "var(--v-white-base)"
                              },
                              attrs: {
                                elevation: "0",
                                outlined: "",
                                color: "gray_30"
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "span",
                              { class: "font-14 font-primary brand-medium" },
                              [_vm._v(_vm._s(_vm.dateLabel))]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.datePickerMenu,
                  callback: function($$v) {
                    _vm.datePickerMenu = $$v
                  },
                  expression: "datePickerMenu"
                }
              },
              [
                _c("v-date-picker", {
                  attrs: {
                    "first-day-of-week": _vm.$store.getters.firstDayOfWeek,
                    "no-title": "",
                    scrollable: ""
                  },
                  on: { change: _vm.datePickerUpdated },
                  model: {
                    value: _vm.datePickerDate,
                    callback: function($$v) {
                      _vm.datePickerDate = $$v
                    },
                    expression: "datePickerDate"
                  }
                })
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticStyle: {
                  "min-height": "40px",
                  "background-color": "var(--v-white-base)"
                },
                attrs: { elevation: "0", outlined: "", color: "gray_30" },
                on: {
                  click: function($event) {
                    return _vm.goToToday()
                  }
                }
              },
              [
                _c("span", { class: "font-14 font-secondary brand-medium" }, [
                  _vm._v("Today")
                ])
              ]
            ),
            _c(
              "v-btn",
              {
                staticStyle: {
                  "min-height": "40px",
                  "background-color": "var(--v-white-base)"
                },
                attrs: {
                  icon: "",
                  elevation: "0",
                  outlined: "",
                  color: "gray_30"
                },
                on: {
                  click: function($event) {
                    return _vm.changePeriod(-1)
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "secondary", size: "16" } }, [
                  _vm._v("$chevronLeft")
                ])
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticStyle: {
                  "min-height": "40px",
                  "background-color": "var(--v-white-base)"
                },
                attrs: {
                  icon: "",
                  elevation: "0",
                  outlined: "",
                  color: "gray_30"
                },
                on: {
                  click: function($event) {
                    return _vm.changePeriod(1)
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "secondary", size: "16" } }, [
                  _vm._v("$chevronRight")
                ])
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "ml-auto" }, [
          _c(
            "div",
            { staticClass: "row-format align-center" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2 pointer",
                  attrs: { size: "20" },
                  on: {
                    click: function($event) {
                      return _vm.openShareCalendar()
                    }
                  }
                },
                [_vm._v("share")]
              ),
              _vm.filter
                ? _c("calendar-filter", {
                    staticClass: "mr-2",
                    attrs: {
                      filter: _vm.filter,
                      categories: _vm.filterCategories
                    },
                    on: { "reset-filter": _vm.resetFilter }
                  })
                : _vm._e(),
              _c(
                "v-menu",
                {
                  attrs: {
                    "nudge-top": "20",
                    bottom: "",
                    left: "",
                    rounded: "",
                    "offset-overflow": "",
                    "offset-y": "",
                    "close-on-content-click": false,
                    "content-class": "add-new-menu",
                    transition: "slide-y-transition"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ staticClass: "super-action" }, on),
                            [
                              _c("v-icon", { attrs: { size: "20" } }, [
                                _vm._v("add")
                              ]),
                              _vm._v(" Add")
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    { staticClass: "add-new-dropdown" },
                    _vm._l(_vm.addNew, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "add-new-item",
                          on: {
                            click: function($event) {
                              return _vm.handleAddNew(item.value)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm.hasAnyMetrics
        ? _c(
            "div",
            { staticClass: "row-format" },
            [
              _c("metric-widget", {
                staticClass: "m-widget",
                attrs: { metrics: _vm.timeMetricsByClient },
                on: {
                  "metric-clicked": function($event) {
                    return _vm.handleTimeMetricClicked($event)
                  }
                }
              }),
              _c("metric-widget", {
                staticClass: "m-widget",
                attrs: { metrics: _vm.paymentMetricsByClient },
                on: {
                  "metric-clicked": function($event) {
                    return _vm.handlePaymentMetricClicked($event)
                  }
                }
              }),
              _c("metric-widget", {
                staticClass: "m-widget",
                attrs: { metrics: _vm.deliverableMetricsByStatus },
                on: {
                  "metric-clicked": function($event) {
                    return _vm.handleDeliverableMetricClicked($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.currentView === "Schedule"
        ? _c("schedule-view", {
            attrs: {
              days: _vm.scheduleEvents,
              "has-metrics": _vm.hasAnyMetrics
            },
            on: {
              "event-clicked": function($event) {
                return _vm.eventClicked({ event: $event })
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentView !== "Schedule",
              expression: "currentView !== 'Schedule'"
            }
          ],
          staticClass: "row-format fill-height"
        },
        [
          _c(
            "div",
            {
              class:
                "mb-0" +
                (["Day", "Week"].includes(_vm.currentView)
                  ? " show-scrollbar"
                  : ""),
              style:
                "height: calc(var(--vh) - " +
                (_vm.hasAnyMetrics ? 265 : 200) +
                "px); min-width: " +
                (_vm.calendarType === "day" && _vm.daysEvents.length > 0
                  ? "50%"
                  : "100%")
            },
            [
              _c(
                "v-calendar",
                {
                  key: _vm.refreshKey,
                  ref: "calendar",
                  attrs: {
                    locale: _vm.$store.state.locale,
                    weekdays: _vm.weekDays,
                    type: _vm.calendarType,
                    start: _vm.startDate.toISODate(),
                    end: _vm.endDate.toISODate(),
                    "event-color": _vm.getEventColor,
                    events: _vm.calendarEvents,
                    "event-height": _vm.currentView === "Two-Week" ? 28 : 20,
                    "event-margin-bottom": 4,
                    "event-ripple": false
                  },
                  on: {
                    "click:event": _vm.eventClicked,
                    "click:more": _vm.moreClicked,
                    "contextmenu:day": _vm.showDayMenu,
                    "contextmenu:time": _vm.showDayMenu
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "day-label",
                      fn: function(ref) {
                        var day = ref.day
                        var weekday = ref.weekday
                        var date = ref.date
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "text-left font-12 pl-2",
                              on: {
                                click: function($event) {
                                  return _vm.dayClicked(date)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "d-label" }, [
                                _vm._v(_vm._s(_vm.days[weekday]))
                              ]),
                              _vm._v(" " + _vm._s(day) + " ")
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "day-label-header",
                      fn: function(ref) {
                        var day = ref.day
                        var weekday = ref.weekday
                        var date = ref.date
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "text-left font-12 pl-2",
                              on: {
                                click: function($event) {
                                  return _vm.dayClicked(date)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.days[weekday]) + " " + _vm._s(day)
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "event",
                      fn: function(ref) {
                        var event = ref.event
                        var eventParsed = ref.eventParsed
                        var timed = ref.timed
                        return [
                          _c(_vm.getComponentName(event), {
                            key: _vm.getComponentName(event),
                            tag: "component",
                            attrs: {
                              event: event,
                              "event-parsed": eventParsed,
                              timed: timed,
                              view: _vm.currentView
                            }
                          })
                        ]
                      }
                    }
                  ])
                },
                [_vm._v(" > ")]
              )
            ],
            1
          ),
          _vm.currentView === "Day"
            ? _c(
                "div",
                { staticClass: "mt-10 mr-5", staticStyle: { width: "50%" } },
                _vm._l(_vm.daysEvents, function(event) {
                  return _c(
                    "div",
                    {
                      key: event.uuid,
                      staticClass: "text-left mb-2 pointer",
                      on: {
                        click: function($event) {
                          return _vm.eventClicked({ event: event })
                        }
                      }
                    },
                    [
                      event.source === "DELIVERABLE"
                        ? _c("deliverable-loader", {
                            staticClass: "pl-1",
                            attrs: { event: event }
                          })
                        : event.source === "TICKET"
                        ? _c("ticket-kanban-card", {
                            staticClass: "pl-1",
                            attrs: { event: event }
                          })
                        : _c(_vm.getComponentName(event), {
                            key: _vm.getComponentName(event),
                            tag: "component",
                            staticClass: "px-2 py-1 font-14",
                            style:
                              "background-color: " +
                              _vm.getEventColor(event) +
                              "; border-radius: 4px",
                            attrs: {
                              event: event,
                              timed: false,
                              view: _vm.currentView
                            }
                          })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ]
      ),
      _vm.openDialog
        ? _c(
            "v-menu",
            {
              attrs: {
                absolute: "",
                top: "",
                left: "",
                "nudge-bottom": _vm.clickY + 150,
                "nudge-right": _vm.clickX + 140
              },
              model: {
                value: _vm.openDialog,
                callback: function($$v) {
                  _vm.openDialog = $$v
                },
                expression: "openDialog"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "pa-3",
                  staticStyle: {
                    "background-color": "var(--v-white-base)",
                    width: "300px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format align-center px-2 mb-2" },
                    [
                      _c("div", { staticClass: "brand-medium" }, [
                        _vm._v(
                          _vm._s(
                            _vm.DateTime.fromISO(_vm.moreEvent.date).toFormat(
                              "ccc d"
                            )
                          )
                        )
                      ]),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-auto",
                          attrs: { color: "gray_90", small: "" },
                          on: {
                            click: function($event) {
                              _vm.openDialog = false
                            }
                          }
                        },
                        [_vm._v("$close")]
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.eventsForMore, function(event, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "mx-1 my-1 font-14 pointer text-left",
                        style:
                          "background-color: " +
                          _vm.getEventColor(event) +
                          "; padding:2px; border-radius:4px",
                        on: {
                          click: function($event) {
                            return _vm.eventClicked({ event: event })
                          }
                        }
                      },
                      [
                        _c(_vm.getComponentName(event), {
                          tag: "component",
                          attrs: {
                            event: event,
                            timed: event.timed,
                            view: _vm.currentView,
                            "in-more-view": true
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }