<template>
	<div class="item-project-selector row-format" v-if="currentProjectLabel">
		<v-menu
			:nudge-bottom="0"
			:elevation="0"
			content-class="project-menu"
			bottom
			left
			rounded
			offset-overflow
			offset-y
			transition="slide-y-transition"
			:disabled="disabled"
			v-if="!disabled"
		>
			<template v-slot:activator="{ on }">
				<v-btn color="primary" small v-if="useButton" v-on="on">
					<v-icon size="16" class="mr-1 material-symbols-rounded" color="white" >folder_special</v-icon>
					<span class="font-14">{{ currentProjectLabel }}</span>
				</v-btn>
				<div v-else v-on="on" class="activator-label-wrapper">
					<div :class="'row-format align-center d-flex justify-start activator-label ' + showHide">
						<v-icon size="16" class="mr-1 material-symbols-rounded" >folder_special</v-icon>
						<div class="font-14">{{ currentProjectLabel }}</div>
					</div>
				</div>
			</template>

			<div class="project-menu-items">
				<div
					v-for="(project, index) in projects"
					:key="index"
					@click="selectProject(project)"
					:class="menuItemClass(project)"
				>
					{{ project.name }}
				</div>
				<div class="menu-item" v-if="item.projectId">
					<v-btn class="primary-action ml-n2 mt-n1" text style="width: 140px" @click="clearSelection()">
						<v-icon size="14">$close</v-icon>
						<span class="ml-1">{{ $t('invoice.clear-project-selection') }}</span>
					</v-btn>
				</div>
			</div>
		</v-menu>
		<div v-else :class="'row-format align-center d-flex justify-start activator-label'">
			<v-btn color="primary" small v-if="useButton" :disabled="true">
				<v-icon size="16" class="mr-1 material-symbols-rounded" color="white" >folder_special</v-icon>
				<span class="font-14">{{ currentProjectLabel }}</span>
			</v-btn>
			<div v-else  class="activator-label-wrapper">
				<v-icon size="16" class="material-symbols-rounded">folder_special</v-icon>
				<div class="font-14" style="margin-left: 2px; cursor: default; color: var(--v-gray_90-base)">{{ currentProjectLabel }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ProjectService from '@/modules/projects/ProjectService';
	import {DateTime} from 'luxon';

	export default {
		name: 'ProjectSelect',
		props: ['clientId', 'item', 'editable','prefix','useButton'],

		data() {
			return {
				projectService: new ProjectService(),
				DateTime: DateTime,
				isReady: false,

				projectHeaders: [
					{ text: this.$t('invoice.project-headers.project-name'), name: 'name', value: 'name', sortable: true },
					{
						text: this.$t('invoice.project-headers.date-created'),
						name: 'dateCreated',
						value: 'dateCreated',
						sortable: true,
					},
					{ text: this.$t('invoice.project-headers.due-date'), name: 'dueDate', value: 'dueDate', sortable: true },
				],

				projects: [],
			};
		},

		mounted() {
			this.getProjects();
		},

		methods: {
			getProjects: function () {
				this.projectService
					.getProjectsByClient(this.clientId, false)
					.then((res) => {
						this.projects.splice(0,this.projects.length);
						this.projects.push(...res.data.filter(p => p.active));
						for (let i = 0; i < this.projects.length; i++) {
							this.projects[i].css = '';
							if (this.projects[i].id === this.item.projectId) {
								this.projects[i].css = 'selected';
							}
						}
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},
			selectProject: function (project) {
				this.$emit('select-project', project);
			},
			clearSelection: function () {
				let project = { id: null, name: null };
				this.$emit('select-project', project);
			},
			menuItemClass(project) {
				if (project.id === this.item.projectId) {
					return 'menu-item selected';
				}
				return 'menu-item';
			},
		},

		computed: {
			currentProjectLabel() {
				if (this.item.projectId) {
					if (this.item.deliverableName === this.item.description) {
						return (this.prefix ? this.prefix : '') + this.item.projectName;
					}
					if (this.item.deliverableName) {
						return (this.prefix ? this.prefix : '') + this.item.projectName + ' / ' + this.item.deliverableName;
					}
					return (this.prefix ? this.prefix : '') + this.item.projectName;
				}
				if (!this.disabled && this.editable && this.projects.length > 0) {
					return (this.prefix ? this.prefix : '')+ 'Assign to project';
				}
				return '';
			},
			showKanbanIcon() {
				return !this.item.projectId && this.currentProjectLabel;
			},
			showHide() {
				if (!this.item.projectName && !this.disabled) {
					return 'show-hide';
				}
				return '';
			},
			disabled() {
				if(['DEPOSIT','ADHOC','EXPENSE','SUBSCRIPTION'].includes(this.item.type)){
					return !this.editable || this.projects.length === 0;
				}else{
					return true;
				}
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
	.item-project-selector {
		max-width: 400px;
		.activator-label-wrapper {
			.activator-label {
				height: 20px;
				font-size: 12px;
				overflow: hidden;
				display: flex;
				justify-content: flex-start;
			}
		}
	}

	.v-menu__content.project-menu {
		background-color: var(--v-white-base);
		background-opacity: 1;
	}

	.project-menu-items {
		.menu-item {
			height: 52px;
			text-align: left;
			padding: 12px 12px 12px 20px;
			cursor: pointer;
			border-bottom: 1px solid var(--v-gray_30-base);
			color: var(--v-primary-base);
			background-color: var(--v-white-base);
			background-opacity: 1 !important;

			&:hover {
				background-color: var(--v-gray_20-base);
			}

			&.selected {
				background-color: var(--v-gray_10-base);
			}
		}
	}
</style>
