var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.deliverable.files.length
        ? _c(
            "div",
            { staticClass: "mt-2 mb-2" },
            _vm._l(_vm.deliverable.files, function(file) {
              return _c(
                "div",
                {
                  key: file.fileName,
                  staticClass: "row-format align-center mb-2"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "row-format align-center attachment-wrapper",
                      staticStyle: { width: "100%", position: "relative" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "buttonOverlay row-format align-center",
                          staticStyle: { "justify-content": "space-between" }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "pointer",
                              attrs: { size: "20" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.downloadFile(file)
                                }
                              }
                            },
                            [_vm._v(" $download ")]
                          ),
                          _vm.isOpenableFile(file)
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "pointer",
                                  attrs: { size: "20" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.downloadFile(file, false)
                                    }
                                  }
                                },
                                [_vm._v("open_in_new")]
                              )
                            : _vm._e(),
                          _c(
                            "v-icon",
                            {
                              staticClass: "pointer",
                              attrs: { size: "20" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.confirmDeleteFile(file)
                                }
                              }
                            },
                            [_vm._v("$delete")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "24px",
                            "max-width": "24px"
                          }
                        },
                        [
                          _c("v-img", {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              contain: "",
                              src: _vm.getFilePreview(file),
                              height: "24"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ml-2 font-14" }, [
                        _vm._v(_vm._s(file.fileName))
                      ])
                    ]
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { width: "fit-content" } },
        [
          _c(
            "vue-dropzone",
            {
              ref: "fileDropzone",
              staticStyle: { "background-color": "var(--v-white-base)" },
              attrs: {
                id: "dropzone",
                "use-custom-slot": true,
                options: _vm.dropzoneOptions
              },
              on: {
                "vdropzone-success": _vm.uploadSuccess,
                "vdropzone-error": _vm.uploadFailure,
                "vdropzone-sending": _vm.addFileUploadAuthHeader
              }
            },
            [
              _c(
                "div",
                { staticStyle: { width: "fit-content" } },
                [
                  _c("v-icon", { staticClass: "mb-1", attrs: { size: "14" } }, [
                    _vm._v("$plus")
                  ]),
                  _vm._v(" Add file")
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.deleteFileDialog,
          "heading-text": "Confirm",
          "yes-text": "Delete",
          "no-text": "Cancel",
          "body-text": _vm.$t("projects.delete-attachment-confirm")
        },
        on: {
          close: function($event) {
            return _vm.cancelDeleteFile()
          },
          confirm: function($event) {
            return _vm.deleteFile()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }