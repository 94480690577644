var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "add-content-dialog" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("h2", { staticClass: "modal-title" }, [
            _vm._v("Add content block")
          ]),
          _c("div", { staticClass: "modal-subtitle" }, [
            _vm._v(
              " Select a type of content block. It will appear at the bottom of the page and then you can drag to re-order it. "
            )
          ]),
          _c(
            "v-container",
            { staticClass: "mt-3 mb-2 ml-0 pl-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "header text-left mb-2" }, [
                      _vm._v("Text")
                    ]),
                    _c("div", { staticClass: "row-format" }, [
                      _c(
                        "div",
                        {
                          staticClass: "pointer content-wrapper",
                          on: {
                            click: function($event) {
                              return _vm.addItem("TextBlock")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "iconBox column-format centered" },
                            [
                              _c("v-icon", { attrs: { size: "60" } }, [
                                _vm._v("$text")
                              ])
                            ],
                            1
                          ),
                          _c("div", { staticClass: "instructions" }, [
                            _vm._v("Header and"),
                            _c("br"),
                            _vm._v("paragraph")
                          ])
                        ]
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "header text-left mb-2 mt-4" }, [
                      _vm._v("Lead capture")
                    ]),
                    _c("div", { staticClass: "row-format" }, [
                      _c(
                        "div",
                        {
                          staticClass: "pointer content-wrapper mr-4",
                          on: {
                            click: function($event) {
                              return _vm.addItem("LeadContact")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "iconBox column-format centered" },
                            [
                              _c("v-icon", { attrs: { size: "60" } }, [
                                _vm._v("$clients")
                              ])
                            ],
                            1
                          ),
                          _c("div", { staticClass: "instructions" }, [
                            _vm._v("Name & contact")
                          ])
                        ]
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "header text-left mb-2 mt-4" }, [
                      _vm._v("Questions / Input")
                    ]),
                    _c("div", { staticClass: "row-format gap-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "pointer content-wrapper",
                          on: {
                            click: function($event) {
                              return _vm.addItem("TextInput")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "iconBox column-format centered" },
                            [
                              _c("v-icon", { attrs: { size: "60" } }, [
                                _vm._v("$questionMark")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "instructions text-center" },
                            [_vm._v("Open-ended"), _c("br"), _vm._v("answer")]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pointer content-wrapper",
                          on: {
                            click: function($event) {
                              return _vm.addItem("SelectInput")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "iconBox column-format centered" },
                            [
                              _c("v-icon", { attrs: { size: "60" } }, [
                                _vm._v("$multipleAnswers")
                              ])
                            ],
                            1
                          ),
                          _c("div", { staticClass: "instructions" }, [
                            _vm._v("Select, Checkbox, "),
                            _c("br"),
                            _vm._v("Radio answer")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pointer content-wrapper",
                          on: {
                            click: function($event) {
                              return _vm.addItem("DateInput")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "iconBox column-format centered" },
                            [
                              _c("v-icon", { attrs: { size: "60" } }, [
                                _vm._v("$calendar")
                              ])
                            ],
                            1
                          ),
                          _c("div", { staticClass: "instructions" }, [
                            _vm._v("Date")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pointer content-wrapper",
                          on: {
                            click: function($event) {
                              return _vm.addItem("FileInput")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "iconBox column-format centered" },
                            [
                              _c("v-icon", { attrs: { size: "60" } }, [
                                _vm._v("$attachment")
                              ])
                            ],
                            1
                          ),
                          _c("div", { staticClass: "instructions" }, [
                            _vm._v("File upload")
                          ])
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }