<template>
  <div class="solid-hr">
    <v-switch v-model="item.conditionsEnabled" class="mt-0" label="Turn on conditional logic" hide-details dense></v-switch>
    <div v-if="item.conditionsEnabled" class="mr-4">
      <div class="align-center">
        <v-radio-group v-model="item.conditionalType" row>
          <v-radio label="AND" value="and"></v-radio>
          <v-radio label="OR" value="or"></v-radio>
        </v-radio-group>
      </div>
      <div class="mb-4" style="text-align: left">Field will show if <strong> {{ item.conditionalType === "and" ? "all" : "any" }} </strong> of these conditions are true:</div>
      <hr/>
      <ul v-for="(cond, index) in item.conditions" :key="index">
        <li class="mt-4">
          <div style="text-align: left"><strong>{{cond.fieldName}}</strong> {{cond.operation}} <strong v-if="!['is answered','isn\'t answered'].includes(cond.operation)">{{cond.answer}}</strong></div>
        </li>
      </ul>
      <v-btn class="primary-action my-2" @click="editConditions()"> Edit </v-btn>
      <hr/>
    </div>

    <basic-modal
        :dialog="conditionalDialog"
        v-if="!!conditionalDialog"
        :persistent="false"
        @close="conditionalDialog = false"
    >
      <v-btn icon class="close-dialog">
        <v-icon @click="conditionalDialog = false">$close</v-icon>
      </v-btn>
      <div class="px-8 py-8" style="min-width: 900px">
        <div class="modal-title">Conditional Settings</div>
        <div v-for="(cond, index) in item.conditions" :key="index">
          <v-row>
            <v-col cols="4">
              <v-select
                  v-model="cond.fieldName"
                  label="Field name"
                  :items="mappingKeys"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                  v-model="cond.operation"
                  label="Operation"
                  :items="['is', 'isn\'t', 'is answered', 'isn\'t answered', 'contains', 'doesn\'t contain']"
              ></v-select>
            </v-col>
						<v-col cols="4" class="row-format align-center">
							<div v-if="cond.operation === 'is answered' || cond.operation === 'isn\'t answered'"> -- </div>
							<v-select
									v-else-if="isOptionType(cond.fieldName) && (cond.operation === 'is' || cond.operation === 'isn\'t')"
									v-model="cond.answer"
									label="Answer"
									:items="optionsFromField(cond.fieldName)"
							></v-select>
							<v-text-field
									v-else
									v-model="cond.answer"
									label="Answer"
							></v-text-field>
							<v-icon size="20" class="ml-auto material-symbols-rounded pointer" @click="deleteConditional(index)">delete</v-icon>
						</v-col>
          </v-row>
        </div>
        <v-btn text @click="addConditional()">+ New condition</v-btn>
      </div>
      <div class="mb-4">
        <v-btn class="primary-action ml-4" width="136" @click="conditionalDialog = false"> Save Changes </v-btn>
      </div>
    </basic-modal>
  </div>
</template>

<script>
import BasicModal from '@/components/BasicModal';
export default {
  name: 'Checkbox',

  props: ['item', 'schema'],

  components: {
    BasicModal
  },

  data: function() {
    return {
			conditionalDialog: false,
    };
  },

  mounted() {
  },

  beforeDestroy() {},

  methods: {
		editConditions: function(){
			this.conditionalDialog = true;
			if(this.item.conditions.length === 0){
				this.addConditional();
			}
		},

    addConditional: function() {
      this.item.conditions.push({
        fieldName: '',
        operation: '',
        answer: '',
      });
    },

		deleteConditional: function(index) {
			this.item.conditions.splice(index, 1)
		},

    optionsFromField: function(fieldName) {
        let matchedItem = this.previous.find(i => i.fieldName === fieldName);
        if (matchedItem) {
          return matchedItem.options;
        }
      return [];
    },

		isQuestionType: function(type){
			return ['Checkbox','DateInput','FileInput','Radio','Select','TextArea','TextInput'].includes(type);
		},

		isOptionType: function(fieldName){
			let item = this.previous.find(p => p.fieldName === fieldName);
			if(item){
				return ['Checkbox','Radio','Select'].includes(item.type);
			}else{
				return false;
			}
		}
  },

  computed: {
		previous: function(){
			let result = [];

			for(let i=0; i < this.schema.length; i++){
				let item = this.schema[i];
				if(item.id === this.item.id){
					break;
				}
				if(this.isQuestionType(item.type)){
					result.push({
						type: item.type,
						fieldName: item.fieldName,
						options: item.options
					});
				}else if(item.type === 'Container'){
					item.columns.forEach((column) => {
						column.items.forEach((colItem) => {
							if(this.isQuestionType(colItem.type)){
								result.push({
									type: colItem.type,
									fieldName: colItem.fieldName,
									options: colItem.options
								});
							}
						})
					})
				}
			}

			return result;
		},

		mappingKeys: function() {
			return this.previous.map(i => i.fieldName);
		},
  }
}
</script>

<style lang="scss">
  #solid-hr {
    hr {
      border-top: 1px solid var(--v-gray_70-base) !important;
    }
  }
</style>
