var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: _vm.view !== "SELECT_CLIENT" ? "min-height: 625px;" : "",
      attrs: { id: "new-proposal" }
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("result")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _vm.view === "SELECT_CLIENT"
          ? _c(
              "div",
              { staticClass: "cloneList" },
              [
                _c("div", { staticClass: "modal-title" }, [
                  _vm._v(" Create new agreement ")
                ]),
                _c(
                  "div",
                  [
                    _c("select-client", {
                      key: _vm.clientRefreshKey,
                      model: {
                        value: _vm.selectedClientId,
                        callback: function($$v) {
                          _vm.selectedClientId = $$v
                        },
                        expression: "selectedClientId"
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "font-14 font-primary my-3 pointer",
                        on: {
                          click: function($event) {
                            _vm.clientCreateDialog = true
                          }
                        }
                      },
                      [_vm._v("+ Add new client")]
                    )
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action mt-2",
                    staticStyle: { "min-width": "160px" },
                    attrs: { disabled: !_vm.selectedClientId },
                    on: {
                      click: function($event) {
                        _vm.view = "START_VIEW"
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.continue")))]
                ),
                _vm.processing
                  ? _c(
                      "div",
                      { staticClass: "loadingIcon" },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            size: 50,
                            color: "primary",
                            indeterminate: ""
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm.view === "START_VIEW"
          ? _c(
              "div",
              { staticClass: "cloneList" },
              [
                _c("div", { staticClass: "modal-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("proposal.new.heading")) + " ")
                ]),
                _vm.showV1
                  ? _c(
                      "v-tabs",
                      {
                        model: {
                          value: _vm.tab,
                          callback: function($$v) {
                            _vm.tab = $$v
                          },
                          expression: "tab"
                        }
                      },
                      [
                        _c("v-tab", { key: "1" }, [_vm._v("Agreements [v2]")]),
                        _c("v-tab", { key: "0" }, [
                          _vm._v("Agreements [v1 - classic]")
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", [
                  _vm.tab === 1
                    ? _c("div", { staticClass: "column-format gap-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-outline d-flex align-start justify-start py-3 px-2 mt-4",
                            on: {
                              click: function($event) {
                                return _vm.freshStart("Proposal")
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pr-3 pl-1" },
                              [_c("v-icon", [_vm._v("$proposals")])],
                              1
                            ),
                            _c("div", { staticClass: "text-left" }, [
                              _c(
                                "div",
                                { staticClass: "brand-medium gray_90--text" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("proposal.new.proposal"))
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 brand-semilight mt-1" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("proposal.new.proposal-caption")
                                    )
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-outline d-flex align-start justify-start py-3 px-2",
                            on: {
                              click: function($event) {
                                return _vm.freshStart("Contract")
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pr-3 pl-1" },
                              [_c("v-icon", [_vm._v("$signature")])],
                              1
                            ),
                            _c("div", { staticClass: "text-left" }, [
                              _c(
                                "div",
                                { staticClass: "brand-medium gray_90--text" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("proposal.new.contract"))
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 brand-semilight mt-1" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("proposal.new.contract-caption")
                                    )
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-outline d-flex align-start justify-start py-3 px-2",
                            on: {
                              click: function($event) {
                                return _vm.freshStart("Quote")
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pr-3 pl-1" },
                              [_c("v-icon", [_vm._v("$invoices")])],
                              1
                            ),
                            _c("div", { staticClass: "text-left" }, [
                              _c(
                                "div",
                                { staticClass: "brand-medium gray_90--text" },
                                [_vm._v(_vm._s(_vm.$t("proposal.new.quote")))]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 brand-semilight mt-1" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("proposal.new.quote-caption"))
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "py-3 px-2 column-format fill-width gap-2",
                            staticStyle: {
                              border: "1px solid var(--v-gray_50-base)",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c("div", { staticClass: "row-format" }, [
                              _c(
                                "div",
                                { staticClass: "pr-3 pl-1" },
                                [_c("v-icon", [_vm._v("$duplicate")])],
                                1
                              ),
                              _vm._m(0)
                            ]),
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.proposalTemplateList,
                                "return-object": true,
                                "item-text": "name",
                                "hide-details": "",
                                dense: "",
                                outlined: ""
                              },
                              on: { change: _vm.confirmCreateFromTemplate },
                              model: {
                                value: _vm.selectedTemplate,
                                callback: function($$v) {
                                  _vm.selectedTemplate = $$v
                                },
                                expression: "selectedTemplate"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _c("div", { staticClass: "column-format gap-2 mt-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-outline d-flex align-start justify-start py-3 px-2",
                            on: {
                              click: function($event) {
                                return _vm.newAgreement(_vm.base)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pr-3 pl-1" },
                              [_c("v-icon", [_vm._v("handshake")])],
                              1
                            ),
                            _vm._m(1)
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-outline d-flex align-start justify-start py-3 px-2",
                            on: {
                              click: function($event) {
                                return _vm.newAgreement(_vm.proposal)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pr-3 pl-1" },
                              [_c("v-icon", [_vm._v("$proposals")])],
                              1
                            ),
                            _c("div", { staticClass: "text-left" }, [
                              _c(
                                "div",
                                { staticClass: "brand-medium gray_90--text" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("proposal.new.proposal"))
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 brand-semilight mt-1" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("proposal.new.proposal-caption")
                                    )
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-outline d-flex align-start justify-start py-3 px-2",
                            on: {
                              click: function($event) {
                                return _vm.newAgreement(_vm.contract)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pr-3 pl-1" },
                              [_c("v-icon", [_vm._v("$signature")])],
                              1
                            ),
                            _c("div", { staticClass: "text-left" }, [
                              _c(
                                "div",
                                { staticClass: "brand-medium gray_90--text" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("proposal.new.contract"))
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 brand-semilight mt-1" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("proposal.new.contract-caption")
                                    )
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-outline d-flex align-start justify-start py-3 px-2",
                            on: {
                              click: function($event) {
                                return _vm.newAgreement(_vm.quote)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pr-3 pl-1" },
                              [_c("v-icon", [_vm._v("$invoices")])],
                              1
                            ),
                            _c("div", { staticClass: "text-left" }, [
                              _c(
                                "div",
                                { staticClass: "brand-medium gray_90--text" },
                                [_vm._v(_vm._s(_vm.$t("proposal.new.quote")))]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 brand-semilight mt-1" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("proposal.new.quote-caption"))
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "py-3 px-2 column-format fill-width gap-2",
                            staticStyle: {
                              border: "1px solid var(--v-gray_10-base)",
                              "border-radius": "4px"
                            }
                          },
                          [
                            _c("div", { staticClass: "row-format" }, [
                              _c(
                                "div",
                                { staticClass: "pr-3 pl-1" },
                                [_c("v-icon", [_vm._v("$duplicate")])],
                                1
                              ),
                              _vm._m(2)
                            ]),
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.agreementTemplateList,
                                "return-object": true,
                                "item-text": "name",
                                "hide-details": "",
                                dense: "",
                                outlined: ""
                              },
                              on: {
                                change: _vm.confirmCreateFromAgreementTemplate
                              },
                              model: {
                                value: _vm.selectedAgreementTemplate,
                                callback: function($$v) {
                                  _vm.selectedAgreementTemplate = $$v
                                },
                                expression: "selectedAgreementTemplate"
                              }
                            })
                          ],
                          1
                        )
                      ])
                ]),
                _vm.processing
                  ? _c(
                      "div",
                      { staticClass: "loadingIcon" },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            size: 50,
                            color: "primary",
                            indeterminate: ""
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "basic-modal",
        {
          ref: "clientCreateModal",
          attrs: { dialog: _vm.clientCreateDialog },
          on: {
            close: function($event) {
              _vm.clientCreateDialog = false
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "max-height": "calc(100vh - 100px)",
                height: "calc(100vh - 100px)",
                "--vh": "calc(100vh - 150px)"
              }
            },
            [
              _c("client-create", {
                attrs: {
                  "is-open": _vm.clientCreateDialog,
                  clientList: _vm.$store.getters.clients
                },
                on: {
                  close: function($event) {
                    _vm.clientCreateDialog = false
                  },
                  alert: function($event) {
                    return _vm.$refs.clientCreateModal.setAlert($event)
                  },
                  "client-created": function($event) {
                    return _vm.clientCreated($event)
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-left" }, [
      _c("div", { staticClass: "brand-medium gray_90--text" }, [
        _vm._v(" Start from a saved template ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-left" }, [
      _c("div", { staticClass: "brand-medium gray_90--text" }, [
        _vm._v("Blank agreement")
      ]),
      _c("div", { staticClass: "font-14 brand-semilight mt-1" }, [
        _vm._v(
          "Try the fully refreshed agreement module with a ton of great features and improved customization options."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-left" }, [
      _c("div", { staticClass: "brand-medium gray_90--text" }, [
        _vm._v(" Start from a saved template ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }