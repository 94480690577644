var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.timerEvent
    ? _c(
        "div",
        {
          staticClass: "row-format gap-2 font-14 pointer",
          on: { click: _vm.openTimerEvent }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { content: "Timer event" },
                  expression: "{ content: 'Timer event' }"
                }
              ],
              staticClass: "material-symbols-rounded",
              attrs: { color: "secondary", size: "20" }
            },
            [_vm._v("timer")]
          ),
          _c("div", [
            _vm._v(_vm._s(_vm.formattedSeconds)),
            _vm.timerEvent.notes
              ? _c("span", [
                  _vm._v(
                    " - " + _vm._s(_vm.truncateString(_vm.timerEvent.notes, 35))
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "ml-auto font-gray_80 font-12" }, [
            _vm._v(
              _vm._s(_vm._f("formatForTimeAgo")(_vm.timerEvent.timerStart))
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }