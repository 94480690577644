var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "insight-source-filter" } },
    [
      _c(
        "v-menu",
        {
          staticStyle: { "overflow-y": "auto" },
          attrs: {
            "max-height": "90vh",
            "max-width": "250",
            "close-on-content-click": false,
            "close-on-click": true,
            "content-class": "filter-menu-class"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: {
                          "min-height": "40px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          elevation: "0",
                          outlined: "",
                          color: "gray_30"
                        }
                      },
                      on
                    ),
                    [
                      _c("span", {
                        class:
                          "font-14 " +
                          (_vm.isDefaultFilter
                            ? "font-secondary"
                            : "font-gray_60") +
                          " brand-medium",
                        domProps: { innerHTML: _vm._s(_vm.filterLabel) }
                      }),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.isDefaultFilter ? "secondary" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c("div", { staticClass: "column-format open-menu" }, [
            _c("div", { staticClass: "filter-option-box" }, [
              _c(
                "div",
                [
                  _c("v-checkbox", {
                    staticClass: "mx-0 my-2 pa-0",
                    attrs: {
                      "hide-details": "",
                      indeterminate: _vm.sourcesIndeterminate
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "client-label row-format pointer",
                                staticStyle: { "align-content": "center" }
                              },
                              [
                                _c("div", { staticClass: "tree-label" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "calendar.filter.select-deselect"
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.sourcesSelected,
                      callback: function($$v) {
                        _vm.sourcesSelected = $$v
                      },
                      expression: "sourcesSelected"
                    }
                  }),
                  _vm._l(_vm.leadSources, function(source) {
                    return _c("v-checkbox", {
                      key: source,
                      staticClass: "mx-0 my-2 pa-0",
                      attrs: { value: source, "hide-details": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "client-label row-format pointer",
                                    staticStyle: { "align-content": "center" }
                                  },
                                  [
                                    _c("div", { staticClass: "tree-label" }, [
                                      _vm._v(_vm._s(source))
                                    ])
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.filter.leadSources,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "leadSources", $$v)
                        },
                        expression: "filter.leadSources"
                      }
                    })
                  })
                ],
                2
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }