<template>
	<div id="item-details-modal">
		<v-container class="pa-0 ma-0">
			<v-row dense>
				<v-col cols="12">
					<h2>{{ $t('invoice.item-details-modal.heading', { invoiceNumber: invoice.invoiceNumber }) }}</h2>
					<p class="mt-4" v-if="item">
						{{ $t('invoice.item-details-modal.subheading', { description: item.description }) }}
					</p>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<!-- TABLE -->
					<v-data-table
						v-if="timerEvents"
						id="timer-events-table"
						class="row-pointer mb-6"
						:hide-default-header="this.$vuetify.breakpoint.xsOnly ? true : false"
						:headers="headers"
						sort-by="dateDisplay"
						:sort-desc="true"
						:items="timerEvents"
						:hide-default-footer="true"
						:disable-pagination="true"
					>
						<template v-slot:item.dateDisplay="{ item }">
							<div style="margin-top: 6px">
								{{ item.dateDisplay }}
							</div>
						</template>

						<template v-slot:item.timeWorkedSortable="{ item }">
							<div style="margin-top: 6px">
								{{ item.timeWorked }}
							</div>
						</template>

						<template v-slot:item.projectName="{ item }">
							<div style="margin-top: 5px">
								{{ item.projectName | empty }}
							</div>
						</template>

						<template v-slot:item.deliverableName="{ item }">
							<div style="margin-top: 5px">
								{{ item.deliverableName | empty }}
							</div>
						</template>

						<template v-slot:item.notes="{ item }">
							<div style="margin-top: 5px">
								{{ item.notes }}
							</div>
						</template>

						<template v-slot:item.xsRenderSlot="{ item }">
							<div class="row-format">
								{{ item.dateDisplay }}
								—
								{{ item.timeWorked }}
							</div>
						</template>
					</v-data-table>
				</v-col>
			</v-row>

			<v-row dense class="mb-4 mt-0">
				<v-col cols="12" class="px-16">
					<v-btn class="primary-action" @click="$emit('close')">{{ $t('global.close') }}</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	// import InvoiceService from '@/modules/invoices/InvoiceService';

	export default {
		name: 'ItemDetailsModal',

		components: {},
		props: {
			invoice: { type: Object, required: true },
			invoiceItemId: { type: String, required: true },
			invoiceService: { type: Object, required: true },
		},

		data: function() {
			return {
				// invoiceService: new InvoiceService(),
				item: null,
				timerEvents: null,
			};
		},

		mounted() {
			this.getInvoiceItem();
		},

		beforeDestroy() {},

		methods: {
			getInvoiceItem() {
				this.invoiceService
					.getInvoiceItem(this.invoice.clientId, this.invoice.id, this.invoiceItemId)
					.then((res) => {
						this.item = res.data.item;
						this.timerEvents = this.fixupEventList(res.data.timerEvents);
						return true;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			fixupEventList(list) {
				for (let i = 0; i < list.length; i++) {
					this.fixupEvent(list[i]);
				}
				return list;
			},

			fixupEvent(item) {
				item.dateDisplay = this.$DateTime.humanDate(item.timerStart);

				let timerStart = this.$DateTime.fromISO(item.timerStart);
				let timerEnd = this.$DateTime.fromISO(item.timerEnd);
				item.dateTime = timerStart;
				item.durationHoursDecimal = timerEnd.diff(timerStart, ['hours']).values.hours; // Store as Duration in hours decimal for chart calculations
				item.duration = timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds']);
				// if (item.duration.seconds > 29) item.duration.plus({ minutes: 1 });
				// console.log(item.duration);
				item.timeWorked = item.duration.plus(item.duration.seconds > 29 ? 60000 : 0).toFormat('h:mm'); // Table display
				item.timeWorkedSortable = item.duration.toFormat('hh:mm:ss'); // Table sortability
				item.value = '--'; // Value of time worked in dollars. Update later
				item.valueTooltip = this.$t('timetrack.value-tooltip.default');
				item.projectName = item.projectName ?? '';
				item.deliverableName = item.deliverableName ?? '';
				item.notes = item.notes ?? '';

				return item;
			},
		},
		computed: {
			headers() {
				if (this.$vuetify.breakpoint.xsOnly) {
					return [{ text: 'Entries', value: 'xsRenderSlot', sortable: false }];
				}
				return [
					{ text: this.$t('global.date'), value: 'dateDisplay', class: 'nowrap' },
					{ text: this.$t('invoice.item-details-modal.time-worked'), value: 'timeWorkedSortable', class: 'nowrap' },
					// { text: this.$t('projects.project'), value: 'projectName', class: 'd-none d-sm-table-cell nowrap' },
					{ text: this.$t('projects.deliverable'), value: 'deliverableName', class: 'd-none d-sm-table-cell nowrap' },
					{ text: this.$t('timetrack.notes'), value: 'notes', class: 'd-none d-sm-table-cell nowrap' },
				];
			},
		},
		filters: {
			empty: function(value) {
				if (!value) return '--';
				return value;
			},
		},
		watch: {},
	};
</script>

<style lang="scss">
	#item-details-modal {
		.amount-due-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			div {
				margin: 0 0 12px;
				//border: 1px solid red;
				&.amount-due-label {
					margin-right: 8px;
					font-size: 16px;
				}
				&.amount-due {
					margin-left: 8px;
					font-size: 56px;
					color: var(--v-gray_70-base);
				}
			}
		}
		.client-name {
			display: flex;
			justify-content: center;
			margin-bottom: 16px;

			div {
				align-self: center;
				white-space: nowrap;
				flex: 0 0 auto;
			}
		}
		.divider {
			background-color: var(--v-gray_50-base);
			color: var(--v-gray_50-base);
			height: 1px;
		}
		.label {
			text-align: left;
		}
		.date-paid {
			border-bottom: 1px solid var(v-grey-base);
		}
	}
</style>
