var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "span",
        { staticClass: "question" },
        [
          _c("editor", {
            ref: "editor",
            staticStyle: { border: "1px dotted var(--v-grey-lighten3)" },
            attrs: {
              "api-key": _vm.$store.getters.getTinyMceKey,
              "initial-value": _vm.htmlText,
              inline: true,
              init: _vm.mceConfigText,
              placeholder: _vm.placeHolder,
              spellcheck: true
            },
            on: { input: _vm.handleChange }
          })
        ],
        1
      ),
      _c("v-textarea", {
        staticClass: "textArea",
        attrs: {
          rows: parseInt(_vm.ourRows, 10),
          placeholder: "Client will answer here...",
          "no-resize": "",
          disabled: ""
        }
      }),
      _c("div", { staticClass: "row-format mb-2" }, [
        _c(
          "div",
          {
            staticClass: "mt-2 ml-auto row-format align-center gap-2",
            staticStyle: { width: "fit-content" }
          },
          [
            _c("v-text-field", {
              staticStyle: { width: "150px" },
              attrs: {
                outlined: "",
                "persistent-placeholder": "",
                dense: "",
                "hide-details": "",
                label: "Field id"
              },
              on: {
                change: function($event) {
                  return _vm.handleChange()
                }
              },
              model: {
                value: _vm.ourFieldKey,
                callback: function($$v) {
                  _vm.ourFieldKey = $$v
                },
                expression: "ourFieldKey"
              }
            }),
            _c("v-select", {
              staticStyle: { width: "95px" },
              attrs: {
                items: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
                label: "Rows",
                outlined: "",
                "persistent-placeholder": "",
                dense: "",
                "hide-details": ""
              },
              on: {
                change: function($event) {
                  return _vm.handleChange()
                }
              },
              model: {
                value: _vm.ourRows,
                callback: function($$v) {
                  _vm.ourRows = $$v
                },
                expression: "ourRows"
              }
            }),
            _c("v-select", {
              staticStyle: { width: "95px" },
              attrs: {
                items: _vm.requiredOptions,
                "item-text": "label",
                "item-value": "value",
                outlined: "",
                "persistent-placeholder": "",
                dense: "",
                "hide-details": "",
                label: "Required"
              },
              on: {
                change: function($event) {
                  return _vm.handleChange()
                }
              },
              model: {
                value: _vm.ourRequired,
                callback: function($$v) {
                  _vm.ourRequired = $$v
                },
                expression: "ourRequired"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }