<template>
	<div>
		<div class="key-pad centered px-3 pt-1 pb-2">
			<div v-if="!callIsActive">
				<contact-selector
					v-if="!keyPadEntry"
					ref="ContactSelector"
					:subscription="subscription"
					@to-list="setTo($event)"
					:multiple="false"
					key="phone-contact"
				></contact-selector>
				<v-text-field
					v-if="keyPadEntry"
					v-model="keyPadEntry"
					@input="scrubInput"
					class="mt-1"
					label="To:"
					hide-details
					dense
					outlined
				></v-text-field>
			</div>
			<div v-if="activeCall">
				<div class="font-20 font-primary">{{this.$store.state.communicator.callTime}}</div>
				<div class="font-14" v-if="activeCallName" v-tippy :content="activeCallPhone">{{activeCallName}}</div>
				<div class="font-14" v-else>{{activeCallPhone}}</div>
			</div>
			<div>
				<div class="button-row mt-2">
					<div class="button" @click="digit('1')"><div>1</div></div>
					<div class="button" @click="digit('2')"><div>2</div></div>
					<div class="button" @click="digit('3')"><div>3</div></div>
				</div>
				<div class="button-row">
					<div class="button" @click="digit('4')"><div>4</div></div>
					<div class="button" @click="digit('5')"><div>5</div></div>
					<div class="button" @click="digit('6')"><div>6</div></div>
				</div>
				<div class="button-row">
					<div class="button" @click="digit('7')"><div>7</div></div>
					<div class="button" @click="digit('8')"><div>8</div></div>
					<div class="button" @click="digit('9')"><div>9</div></div>
				</div>
				<div class="button-row mb-2">
					<div class="button" @click="digit('star')"><div>*</div></div>
					<div class="button" @click="digit('0')"><div>0</div></div>
					<div class="button" @click="digit('pound')"><div>#</div></div>
				</div>
			</div>

			<div class="pt-1">
				<v-btn
						block large elevation="0"
						color="primary"
						:disabled="!phoneIsValid"
						@click="dial()"
						v-if="!callIsActive"
						id="custom-disabled"
				>
					<v-icon color="white">$phone</v-icon>
				</v-btn>
				<div class="row-format mb-2" style="gap: 4px" v-if="activeCall">
					<div style="width: 50%">
						<v-btn large block elevation="0" :color="callRecording ? 'primary' : ''" @click="$emit('record-call')">
							<div class="column-format">
								<v-icon>$voicemail</v-icon>
								<div class="font-12">Record</div>
							</div>
						</v-btn>
					</div>
					<div style="width: 50%">
						<v-btn large block elevation="0" :color="callMuted ? 'primary' : ''" @click="$emit('mute-call')">
							<div class="column-format">
								<v-icon>volume_off</v-icon>
								<div class="font-12">Mute</div>
							</div>
						</v-btn>
					</div>
				</div>
				<v-btn block large elevation="0" color="error" v-if="callIsActive" @click="$emit('hangup')">
					<v-icon color="white">$phone</v-icon> <span class="font-14" style="color: var(--v-white-base)">End call</span>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import ContactSelector from '@/modules/communicator/realtime/ContactSelector';
	import libPhoneNumber from 'google-libphonenumber';
	import {DateTime} from 'luxon';

	export default {
		name: 'Keypad',

		props: ['subscription', 'callIsActive'],

		components: { ContactSelector },

		data: function () {
			return {
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			dial: function () {
				if (this.keyPadEntry) {
					let phone = this.keyPadEntry.replace(/[^\d]/g, '');
					this.$emit('dial', phone);
				} else {
					this.$emit('dial', this.to.phone);
				}

				this.keyPadEntry = null;
				this.to = null;
				this.toList = null;
			},

			digit: function (digit) {
				if (this.activeCall) {
					this.$emit('digit', digit);
				} else {
					this.phoneIsValid = false;
					this.keyPadEntry = this.keyPadEntry + digit;
					this.scrubInput();
				}
			},

			matchesAnyCountryCode: function (countryCodes, phone) {
				for (let i = 0; i < countryCodes.length; i++) {
					if (phone.startsWith(countryCodes[i])) {
						return true;
					}
				}
				return false;
			},

			scrubInput: function () {
				let countryCodes = [];
				this.subscription.countryCode.forEach((c) => {
					countryCodes.push(c.replace(/[^\d]/g, ''));
				});

				this.keyPadEntry = this.keyPadEntry.replace(/[^\d+]/g, '');
				let result;
				try {
					if (this.keyPadEntry.startsWith('+')) {
						result = this.PhoneUtil.parse(this.keyPadEntry);
					} else if (this.matchesAnyCountryCode(countryCodes, this.keyPadEntry)) {
						result = this.PhoneUtil.parse('+' + this.keyPadEntry);
					} else {
						result = this.PhoneUtil.parse(this.subscription.countryCode[0] + this.keyPadEntry);
					}

					if (this.PhoneUtil.isValidNumber(result)) {
						this.keyPadEntry = this.PhoneUtil.format(result, this.PNF.NATIONAL);
						this.phoneIsValid = true;
					}
				} catch (err) {
					console.log('error processing to number');
				}
			},

			setTo: function (to) {
				this.to = to;
				if (this.to && this.to.valid) {
					this.phoneIsValid = true;
				} else {
					this.phoneIsValid = false;
				}
			},

			setCallDuration: function(){
				let now = DateTime.now();
				let start = DateTime.fromISO(this.activeCall.start);
				let seconds = Math.ceil(now.diff(start,"seconds").seconds);
				this.$store.state.communicator.callTime = this.formatSecondsLong(seconds);
			},

			formatSecondsLong: function(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				if (hours > 0) {
					return hours + ':' + minutes + ':' + seconds;
				} else {
					return minutes + ':' + seconds;
				}
			},
		},

		watch: {
			activeCall: function(val){
				if(this.$store.state.communicator.timerId){
					clearInterval(this.$store.state.communicator.timerId);
				}
				if(val) {
					this.setCallDuration();
					this.$store.state.communicator.timerId = setInterval(() => this.setCallDuration(), 1000);
				}else{
					this.$store.state.communicator.callTime = null;
				}
			}
		},

		computed: {
			callMuted: function(){
				return this.$store.state.communicator.callMuted;
			},

			callRecording: function(){
				return this.$store.state.communicator.callRecording;
			},

			to: {
				get() {
					return this.$store.state.communicator.to;
				},
				set(newVal) {
					this.$store.state.communicator.to = newVal;
				},
			},

			toList: {
				get(){
					return this.$store.state.communicator.toList;
				},
				set(newVal){
					this.$store.state.communicator.toList = newVal;
				}
			},

			keyPadEntry: {
				get() {
					return this.$store.state.communicator.keyPadEntry;
				},
				set(newVal) {
					this.$store.state.communicator.keyPadEntry = newVal;
				},
			},

			phoneIsValid: {
				get() {
					return this.$store.state.communicator.phoneIsValid;
				},
				set(newVal) {
					this.$store.state.communicator.phoneIsValid = newVal;
				},
			},

			activeCallName: function(){
				if(this.activeCall && this.activeCall.contactId){
					let contact = this.$store.getters.communicatorContacts.find(c => c.id === this.activeCall.contactId);
					if(contact){
						return contact.fullName;
					}else{
						return null;
					}
				}else{
					return null
				}
			},

			activeCallPhone: function(){
				if(this.activeCall){
					try {
						let phone = this.PhoneUtil.parse(this.activeCall.direction === 'Inbound' ? this.activeCall.from : this.activeCall.to);
						return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
					}catch(error){
						console.log(error);
						return null;
					}
				}else{
					return null
				}
			},

			activeCall: function(){
				return this.$store.state.communicator.activeCall;
			}
		},
	};
</script>

<style scoped lang="scss">
	#custom-disabled.v-btn--disabled {
		background-color: var(--v-primary-base) !important;
		opacity: 0.2 !important;
	}

	.button-row {
		display: flex;
		flex-direction: row;
		margin-bottom: 8px;
		gap: 8px;
		justify-content: center;
	}

	.button {
		width: 85px;
		height: 48px;
		border: 1px solid var(--v-gray_50-base);
		border-radius: 62px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>
