<template>
	<div style="background-color: var(--v-white-base); width: 1000px" class="pa-3">
		<div class="row-format align-center">
			<div class="row-format align-center">
				<div>Payments</div>
			</div>
			<v-icon class="ml-auto" @click="handleClose">$close</v-icon>
		</div>
		<div class="mt-3">
			<v-data-table
				:headers="headers"
				:items="payments"
				sort-by="datePaid"
				:sort-desc="true"
				@click:row="openInvoice($event)"
				style="cursor: pointer"
				:items-per-page="10"
				:hide-default-footer="payments.length <= 10"
			>
				<template v-slot:item.client.name="{ item }">
					<div class="row-format align-center">
						<client-avatar small v-if="item.client" :client="item.client" />
						<div>{{ item.client.name }}</div>
					</div>
				</template>

				<template v-slot:item.datePaid="{ item }">
					<span v-if="item.datePaid" class="brand-semilight" style="white-space: nowrap">{{ DateTime.fromISO(item.datePaid).toFormat('DD') }}</span>
				</template>

				<template v-slot:item.amount="{ item }">
					<div v-if="item.amount" class="brand-semilight">
						{{ $formatters.dollars(item.amount ? item.amount : 0, true, true, item.currency) }}
					</div>
				</template>

				<template v-slot:item.invoiceNumberFormatted="{ item }">
					<span class="brand-semilight">{{ item.invoiceNumberFormatted }}</span>
				</template>

				<template v-slot:item.paymentProvider="{ item }">
					<span class="brand-semilight">{{ item.paymentProvider }}</span>
				</template>

				<template v-slot:item.pending="{ item }">
					<div class="row-format align-center">
						<div>{{ item.pending ? 'ACH Pending' : 'Cleared' }}</div>
						<v-icon small :color="item.pending ? 'warning' : 'success'" class="ml-1">{{
							item.pending ? '$alert' : '$success'
						}}</v-icon>
						<v-icon class="ml-2" size="20" v-if="item.integrationKeys.quickbooksId">$quickbooks</v-icon>
					</div>
				</template>

				<template v-slot:no-data> No payments found. </template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
	import DateTime from "@/modules/utils/HDateTime";
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'CalendarPaymentList',

		components: { ClientAvatar },

		props: ['events'],

		data: function () {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {
		},

		beforeDestroy() {
		},

		methods: {
			handleClose: function () {
				this.$emit('result');
			},

			openInvoice: function (payment) {
				let binding = {
					clientId: payment.client.id,
					id: payment.invoiceId,
				};
				this.$store.state.globalModalController.openModal(InvoiceDetails, binding, false, true).then((res) => {
					if (!res) return;
					if (res.action && res.action === 'DELETED') {
						this.removeInvoice(res.id);
					} else {
						this.addOrUpdateInvoice(res);
					}
				});
			},
		},

		watch: {
		},

		computed: {
			payments: function () {
				let payments = [];
				this.events.forEach((e) => {
					let i = e.original.meta.invoice;
					let p = e.original.meta.payment;
					p.client = i.clientInfo;
					p.invoiceNumberFormatted = i.invoiceNumberFormatted;
					p.invoiceId = i.id;
					payments.push(p);
				});
				return payments;
			},

			headers: function () {
				let result = [
					{ text: this.$t('invoice.details.payment-details-modal.date-paid'), value: 'datePaid' },
					{ text: this.$t('invoice.details.payment-details-modal.amount'), value: 'amount' },
					{ text: this.$t('client.client'), value: 'client.name' },
					{ text: this.$t('invoice.invoice-number'), value: 'invoiceNumberFormatted' },
					{ text: this.$t('invoice.details.payment-details-modal.payment-type'), value: 'paymentProvider' },
					{ text: this.$t('invoice.status'), value: 'pending' },
				];

				return result;
			},
		},
	};
</script>

<style lang="scss">

</style>
