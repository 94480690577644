var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.metrics.metrics.length > 0
    ? _c("div", { staticClass: "metric-wrapper px-3" }, [
        _c(
          "div",
          { staticClass: "row-format", staticStyle: { width: "100%" } },
          _vm._l(_vm.metrics.metrics, function(metric) {
            return _c("div", {
              key: metric.id,
              staticClass: "metric mr-1 pointer",
              style:
                "--width:" + metric.percentage + "%; --color:" + metric.color,
              on: {
                click: function($event) {
                  return _vm.$emit("metric-clicked", metric)
                },
                mouseenter: function($event) {
                  return _vm.setHover(metric)
                },
                mouseleave: function($event) {
                  return _vm.setHover(null)
                }
              }
            })
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "ml-1 column-format text-left pointer",
            staticStyle: { "min-width": "150px" },
            on: {
              click: function($event) {
                return _vm.$emit("metric-clicked")
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "font-gray_80 brand-medium font-14 truncate-heading"
              },
              [_vm._v(_vm._s(_vm.heading))]
            ),
            _c("div", { staticClass: "font-12 font-gray_80" }, [
              _vm._v(_vm._s(_vm.subHeading))
            ])
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }