<template>
	<div class="text-left column-format font-14 gap-2">
		<div class="column-format pt-1 pb-2 text-left">
			<div class="brand-bold font-18">Settings</div>
			<div class="font-14 font-gray_80">Automations and confirmations</div>
		</div>
		<v-switch v-model="agreement.settings.createProjectsAndInvoices" hide-details dense class="mt-0 mb-2" label="Automatically create projects and invoices"></v-switch>
		<div class="column-format gap-2" v-if="agreement.settings.createProjectsAndInvoices">
			<v-switch v-model="agreement.settings.invoiceApprovalRequired" hide-details dense class="mt-0 mb-2" label="Review invoice before sending"></v-switch>
			<v-autocomplete v-model="agreement.settings.invoiceTemplateId" :items="invoiceTemplates" hide-details dense outlined persistent-placeholder label="Invoice template (optional)" item-text="name" item-value="id" ></v-autocomplete>
		</div>
		<div v-else class="row-format align-center mb-3 pb-3 gap-2">
			<v-icon color="warning">warning</v-icon>
			<div>Automatic invoice and project creation is disabled for this agreement.</div>
		</div>
		<div class="column-format my-3 py-3 gap-2" style="border-bottom: 1px solid var(--v-gray_50-base);border-top: 1px solid var(--v-gray_50-base)">
			<v-switch v-model="agreement.settings.requireMinimumPackageSelection" hide-details dense class="mt-0 mb-2" label="Require package selection to finalize"></v-switch>
			<v-textarea :disabled="!agreement.settings.requireMinimumPackageSelection" rows="4" auto-grow v-model="agreement.settings.noPackageSelectionError" hide-details dense outlined persistent-placeholder label="Error message if no selection"></v-textarea>
		</div>
		<v-text-field v-model="agreement.settings.finishedButton" hide-details dense outlined persistent-placeholder label="Completed/Finished button text"></v-text-field>
		<v-autocomplete v-model="agreement.settings.confirmationEmailTemplateId" :items="emailTemplates" hide-details dense outlined persistent-placeholder label="Confirmation email" item-text="name" item-value="id" ></v-autocomplete>
		<v-text-field v-model="agreement.settings.redirectUrl" hide-details dense outlined persistent-placeholder label="Post signature redirect URL" placeholder="https://yourwebsite.com"></v-text-field>
		<v-textarea rows="4" auto-grow v-model="agreement.settings.signedConfirmationMessage" hide-details dense outlined persistent-placeholder label="Signature confirmation message"></v-textarea>
	</div>
</template>

<script>

	import InvoiceTemplateService from "@/modules/templates/invoices/InvoiceTemplateService";
	import EmailTemplateService from "@/modules/templates/EmailTemplateService";

	export default {
		name: 'SettingsConfig',

		props: ['agreement'],

		components: { },

		data: function() {
			return {
				invoiceTemplateService: new InvoiceTemplateService(),
				emailTemplateService: new EmailTemplateService(),
				invoiceTemplates: [],
				emailTemplates: []
			};
		},

		mounted() {
			this.getInvoiceTemplates();
			this.getEmailTemplates();
		},

		beforeDestroy() {},

		methods: {
			getInvoiceTemplates: function(){
				this.invoiceTemplateService.getInvoiceTemplates().then((res) => {
					this.invoiceTemplates.splice(0);
					this.invoiceTemplates.push(... res.data);
				})
			},

			getEmailTemplates: function(){
				this.emailTemplateService.getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0);
					this.emailTemplates.push(... res.data);
				})
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
