var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "mb-8" },
        [
          _c(
            "page-header",
            {
              staticClass: "pb-6",
              attrs: {
                "bread-crumbs": [
                  { to: "/home", label: "Home" },
                  { label: "Client portal" }
                ]
              }
            },
            [_vm._v(" Permissions ")]
          ),
          _c(
            "div",
            { staticClass: "row-format align-center mb-4" },
            [
              _c("permission-filter", {
                attrs: { filter: _vm.filter },
                on: {
                  filter: function($event) {
                    return _vm.setFilter($event)
                  }
                }
              })
            ],
            1
          ),
          _c("v-data-table", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              headers: _vm.headers,
              items: _vm.filteredClients,
              "hide-default-footer": true,
              "sort-by": "name",
              "fixed-header": true,
              "items-per-page": -1
            },
            on: {
              "click:row": function($event) {
                return _vm.editClientPermissions($event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.name",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "row-format align-center" },
                        [
                          _c("client-avatar", { attrs: { client: item } }),
                          _c("div", [
                            _c("div", [_vm._v(_vm._s(item.name))]),
                            item.archive
                              ? _c(
                                  "div",
                                  { staticClass: "font-gray_70 font-12" },
                                  [_vm._v("(Archived)")]
                                )
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.contacts",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._l(
                        item.contacts.filter(function(c) {
                          return c.portalAccess
                        }),
                        function(contact, index) {
                          return _c("div", { key: contact.id }, [
                            _vm._v(
                              " " +
                                _vm._s(contact.firstName) +
                                " " +
                                _vm._s(contact.lastName)
                            ),
                            _c("br"),
                            _c("div", { staticClass: "font-primary font-12" }, [
                              _vm._v(_vm._s(contact.email))
                            ]),
                            index <
                            item.contacts.filter(function(c) {
                              return c.portalAccess
                            }).length -
                              1
                              ? _c("div", { staticClass: "mb-3" })
                              : _vm._e()
                          ])
                        }
                      ),
                      item.contacts.filter(function(c) {
                        return c.portalAccess
                      }).length === 0
                        ? _c(
                            "div",
                            { staticStyle: { color: "var(--v-gray_50-base)" } },
                            [_vm._v(" No contacts with access ")]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.access",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("div", { staticClass: "row-format align-center" }, [
                        _vm.hasCustomAccess(item.id)
                          ? _c(
                              "div",
                              { staticClass: "row-format align-center" },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("lock")
                                ]),
                                _c("div", { staticClass: "ml-2" }, [
                                  _vm._v("Enabled")
                                ])
                              ],
                              1
                            )
                          : _c("div", [_vm._v("--")])
                      ])
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-click": true,
                            "nudge-bottom": 30,
                            disabled: _vm.customDomain === null
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "ml-1" },
                                      [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "menu-activator",
                                              attrs: { icon: "" }
                                            },
                                            scope.on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: 20 } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.value
                                                      ? "$arrowUp"
                                                      : "$moreHorizontal"
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("div", { staticClass: "more-menu" }, [
                            _c(
                              "div",
                              {
                                staticClass: "more-menu-item",
                                on: {
                                  click: function($event) {
                                    return _vm.viewPortalAs(item)
                                  }
                                }
                              },
                              [_vm._v("View portal as " + _vm._s(item.name))]
                            )
                          ])
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              452083649
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }