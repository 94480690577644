<template>
	<div v-if="settings" style="width: 400px; min-width: 400px; background-color: var(--v-white-base)">
		<div class="pa-3">
			<v-checkbox v-model="settings.sendDailyEmail" label="Daily email enabled" hide-details dense></v-checkbox>
			<v-checkbox v-model="settings.sendWeeklyEmail" label="Weekly email enabled" hide-details dense></v-checkbox>
			<v-checkbox v-model="settings.sendMonthlyEmail" label="Monthly email enabled" hide-details dense></v-checkbox>
		</div>
		<div style="border-top: 1px solid var(--v-gray_30-base)" class="align-center centered pt-3 pb-2">
			<v-btn class="primary-action" @click="save">Save</v-btn>
		</div>
	</div>
</template>

<script>
	import ExecutiveSummaryService from '@/modules/home/summary/ExecutiveSummaryService';

	export default {
		name: 'ExecutiveSummarySettings',

		props: [],

		components: {},

		data: function() {
			return {
				summaryService: new ExecutiveSummaryService(),
				settings: null,
			};
		},

		mounted() {
			this.getSettings();
		},

		beforeDestroy() {},

		methods: {
			save: function(){
				this.summaryService.updateSettings(this.settings).then(() => {
					this.$store.commit('success','Saved successfully.');
					this.$emit('close');
				});
			},

			getSettings: function() {
				this.summaryService.getSettings().then((res) => {
					this.settings = res.data;
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
