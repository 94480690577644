var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.feeSchedule
    ? _c(
        "v-container",
        {
          staticClass: "mt-0 pt-0",
          attrs: { fluid: "", id: "fee-schedule-edit" }
        },
        [
          _c(
            "v-row",
            { staticClass: "mb-6", attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h2", [_c("div", [_vm._v(_vm._s(_vm.project.name))])])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { staticClass: "label", attrs: { cols: "4" } }, [
                _vm._v(_vm._s(_vm.$t("invoice.project-fee-dialog.fee-type")))
              ]),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.feeTypes,
                      dense: "",
                      "item-text": "label",
                      "item-value": "value"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "16px" } },
                                [_vm._v(_vm._s(item))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      382363075
                    ),
                    model: {
                      value: _vm.feeSchedule.feeType,
                      callback: function($$v) {
                        _vm.$set(_vm.feeSchedule, "feeType", $$v)
                      },
                      expression: "feeSchedule.feeType"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { staticClass: "label", attrs: { cols: "4" } }, [
                _vm._v(_vm._s(_vm.$t("invoice.project-fee-dialog.amount")))
              ]),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      prefix: _vm.currencySymbol,
                      dense: "",
                      type: "number"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.saveEditFees()
                      }
                    },
                    model: {
                      value: _vm.feeSchedule.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.feeSchedule, "amount", $$v)
                      },
                      expression: "feeSchedule.amount"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.feeSchedule.feeType === "Retainer"
            ? _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { staticClass: "label", attrs: { cols: "4" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("invoice.project-fee-dialog.frequency"))
                    )
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("v-select", {
                        attrs: { items: _vm.schedule, dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [_c("span", [_vm._v(_vm._s(item))])]
                              }
                            }
                          ],
                          null,
                          false,
                          2957485812
                        ),
                        model: {
                          value: _vm.feeSchedule.retainerSchedule,
                          callback: function($$v) {
                            _vm.$set(_vm.feeSchedule, "retainerSchedule", $$v)
                          },
                          expression: "feeSchedule.retainerSchedule"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.feeSchedule.feeType === "Retainer"
            ? _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { staticClass: "label", attrs: { cols: "4" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("invoice.project-fee-dialog.retainer-start")
                      )
                    )
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("date-selector", {
                        attrs: { date: _vm.feeSchedule.retainerStart },
                        on: {
                          change: function($event) {
                            _vm.feeSchedule.retainerStart = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "pt-6", attrs: { dense: "" } },
            [
              _c("v-col", { staticClass: "label", attrs: { cols: "4" } }, [
                _vm._v(
                  _vm._s(_vm.$t("invoice.project-fee-dialog.hour-estimate"))
                ),
                _c("br"),
                _c(
                  "span",
                  {
                    staticClass: "ma-0 pa-0",
                    staticStyle: {
                      "font-style": "italic",
                      "font-size": "12px",
                      color: "var(--v-gray_70-base)"
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.optional")))]
                )
              ]),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoice.project-fee-dialog.minimum"),
                      dense: "",
                      type: "number"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.saveEditFees()
                      }
                    },
                    model: {
                      value: _vm.feeSchedule.estimateMin,
                      callback: function($$v) {
                        _vm.$set(_vm.feeSchedule, "estimateMin", $$v)
                      },
                      expression: "feeSchedule.estimateMin"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("invoice.project-fee-dialog.maximum"),
                      dense: "",
                      type: "number"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.saveEditFees()
                      }
                    },
                    model: {
                      value: _vm.feeSchedule.estimateMax,
                      callback: function($$v) {
                        _vm.$set(_vm.feeSchedule, "estimateMax", $$v)
                      },
                      expression: "feeSchedule.estimateMax"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-4" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      attrs: { width: "180" },
                      on: {
                        click: function($event) {
                          return _vm.saveEditFees()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("global.save")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }