<template>
	<div>
		<v-container class="mt-6" style="max-width: 580px">
			<v-row dense>
				<v-col cols="12" align="center" class="pb-0 mb-0 column-format centered">
					<v-icon color="warning" large>$alert</v-icon>
					By deleting your account, all of your data including client records, attachments, invoices, contracts, etc will be permanently removed
					and will be unrecoverable.
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" align="center" class="pt-3">
					<v-btn color="warning" @click="confirmDeleteAccount" :disabled="hasPaidAccount || hasMultipleWorkspaces">Yes, I want to delete my account</v-btn>
					<div class="mt-6 font-italic font-14" v-if="hasPaidAccount">** You cannot delete your account while you have an active subscription **</div>
					<div class="mt-6 font-italic font-14" v-if="hasMultipleWorkspaces">** You currently have more than one workspace which you are the owner of.  Please individually delete your workspaces in the workspace tab **</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal";
import AuthService from "@/modules/auth/AuthService";
import AccountService from "@/modules/account/AccountService";
import {DateTime} from "luxon";

export default {
	name: "DeleteAccount",

	props: [],

	components: {},

	data: function () {
		return {
			accountService: new AccountService()
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		confirmDeleteAccount: function () {
			let binding = {
				headingText: 'Delete account?',
				bodyText:
						'Please confirm you want to remove your user account and all associated data by entering DELETE MY DATA in the field below.',
				confirmText: 'DELETE MY DATA',
			};
			this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
				if (res) {
					this.$store.commit('startLoading');
					this.$track.userDelete();
					this.accountService.deleteAccount().then(() => {
						AuthService.deleteUser().then(() => {
							AuthService.logout().then(() => {
								location.reload();
							});
						});
					});
				}
			});
		},
	},

	computed: {
		hasPaidAccount: function(){
			let ownedAccounts = this.$store.state.loggedInUser.userAccounts.filter(a => a.userType === 'OWNER');
			for(let i=0; i < ownedAccounts.length; i++){
				let ownedAccount = ownedAccounts[i];
				let subscriptionType = ownedAccount.account.subscriptionType;
				if(subscriptionType === 'PAID' ||  subscriptionType === 'TEAM'){
					if(ownedAccount.account.trialEndsAt){
						if(DateTime.fromISO(ownedAccount.account.trialEndsAt) < DateTime.now()){
							return true;
						}
					}else{
						return true;
					}
				}
			}

			return false;
		},

		hasMultipleWorkspaces: function(){
			let ownedAccounts = this.$store.state.loggedInUser.userAccounts.filter(a => a.userType === 'OWNER');
			return ownedAccounts.length > 1;
		},
	},

}
</script>

<style scoped lang="scss">

</style>