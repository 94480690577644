var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-4",
      staticStyle: { "min-width": "800px", "max-width": "calc(100vw - 200px)" }
    },
    [
      _c("div", { staticClass: "text-left" }, [
        _c("div", { staticClass: "row-format" }, [
          _c("span", [
            _c("span", { staticClass: "brand-bold" }, [_vm._v("From:")]),
            _vm._v(" " + _vm._s(_vm.emailLog.from.address))
          ]),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "pointer",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("close")]
              )
            ],
            1
          )
        ]),
        _vm.emailLog.to.length
          ? _c("div", [
              _c("span", { staticClass: "brand-bold" }, [_vm._v("To:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.emailLog.to
                      .map(function(t) {
                        return t.address
                      })
                      .join(", ")
                  )
              )
            ])
          : _vm._e(),
        _vm.emailLog.cc.length
          ? _c("div", [
              _c("span", { staticClass: "brand-bold" }, [_vm._v("CC:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.emailLog.cc
                      .map(function(t) {
                        return t.address
                      })
                      .join(", ")
                  )
              )
            ])
          : _vm._e(),
        _vm.emailLog.bcc.length
          ? _c("div", [
              _c("span", { staticClass: "brand-bold" }, [_vm._v("BCC:")]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.emailLog.bcc
                      .map(function(t) {
                        return t.address
                      })
                      .join(", ")
                  )
              )
            ])
          : _vm._e(),
        _c("div", [
          _c("span", { staticClass: "brand-bold" }, [
            _vm._v(
              _vm._s(_vm.recordType === "SENT_LOG" ? "Sent" : "Send at") + ":"
            )
          ]),
          _vm._v(
            " " +
              _vm._s(
                _vm.DateTime.fromISO(_vm.emailLog.sentAt).toLocaleString(
                  _vm.DateTime.DATETIME_FULL
                )
              )
          )
        ]),
        _c("div", [
          _c("span", { staticClass: "brand-bold" }, [_vm._v("Subject:")]),
          _vm._v(" " + _vm._s(_vm.emailLog.subject))
        ])
      ]),
      _vm.emailContent
        ? _c(
            "div",
            {
              staticClass: "text-left py-3 my-3 show-scrollbar",
              staticStyle: {
                "border-top": "1px solid var(--v-gray_30-base)",
                "border-bottom": "1px solid var(--v-gray_30-base)",
                overflow: "auto"
              }
            },
            [
              _c("div", {
                attrs: { id: _vm.emailLog.id },
                domProps: { innerHTML: _vm._s(_vm.emailContent.htmlContent) }
              }),
              _c(
                "div",
                { staticClass: "my-3" },
                _vm._l(_vm.emailContent.attachments, function(attachment) {
                  return _c(
                    "v-chip",
                    {
                      key: attachment,
                      staticClass: "pointer",
                      attrs: { small: "", color: "gray_80" },
                      on: {
                        click: function($event) {
                          return _vm.download(attachment)
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-1",
                          attrs: { color: "white", size: "20" }
                        },
                        [_vm._v("attachment")]
                      ),
                      _c(
                        "div",
                        { staticStyle: { color: "var(--v-white-base)" } },
                        [_vm._v(_vm._s(attachment))]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }