var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left font-14 column-format px-2 py-1" },
    [
      _c(
        "div",
        {
          staticClass: "row-format align-center font-14 mb-2",
          staticStyle: { width: "100%" }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("back")
                }
              }
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("$chevronLeft")])],
            1
          ),
          _c("div", [_vm._v("New message")])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c("contact-selector", {
            attrs: {
              subscription: _vm.subscription,
              multiple: _vm.subscription.provider === "Bandwidth"
            },
            on: {
              "to-list": function($event) {
                return _vm.setToList($event)
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "mt-auto" }, [
        _c(
          "div",
          {
            on: {
              drop: function($event) {
                $event.preventDefault()
                return _vm.addDropFile.apply(null, arguments)
              },
              dragover: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("v-textarea", {
              attrs: {
                dense: "",
                "hide-details": "",
                outlined: "",
                rows: "1",
                "no-resize": "",
                "auto-grow": "",
                autofocus: ""
              },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  ) {
                    return null
                  }
                  $event.preventDefault()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  if (
                    $event.ctrlKey ||
                    $event.shiftKey ||
                    $event.altKey ||
                    $event.metaKey
                  ) {
                    return null
                  }
                  return _vm.sendMessage.apply(null, arguments)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "font-secondary pointer py-1 pl-2",
                          staticStyle: {
                            "border-left": "1px solid var(--v-gray_50-base)",
                            "margin-top": "2px"
                          },
                          on: { click: _vm.sendMessage }
                        },
                        [_vm._v(" Send ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.newMessage,
                callback: function($$v) {
                  _vm.newMessage = $$v
                },
                expression: "newMessage"
              }
            }),
            _vm.attachment
              ? _c(
                  "div",
                  { staticClass: "row-format align-center mt-2" },
                  [
                    _c(
                      "v-chip",
                      { staticClass: "truncate", attrs: { small: "" } },
                      [_vm._v(_vm._s(_vm.attachment.name))]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer",
                        attrs: { color: "black", small: "" },
                        on: {
                          click: function($event) {
                            _vm.attachment = null
                          }
                        }
                      },
                      [_vm._v("close")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }