import ImageAssetService from "@/services/ImageAssetService";
import AccountService from "@/modules/account/AccountService";
import ConfirmModal from "@/components/ConfirmModal";

export default {
    data: function () {
        return {
            imageAssetService: new ImageAssetService(),
            accountService: new AccountService(),
            tags: [this.template.id],
            imageAssets: [],
        }
    },

    methods: {
        getImageAssets: function() {
            this.imageAssetService.getImageAssets(this.tags).then((res) => {
                this.imageAssets.splice(0, this.imageAssets.length);
                this.imageAssets.push(...res.data);
            });
        },

        confirmDelete: function(imageAsset) {
            let binding = {
                headingText: 'Confirm',
                bodyText: 'Are you sure you want to delete this image from your template library?'
            }
            this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
                if(res){
                    this.imageAssetService.deleteImageAsset(imageAsset.id).then(() => {
                        let ix = this.imageAssets.findIndex(i => i.id === imageAsset.id);
                        if(ix > -1){
                            this.imageAssets.splice(ix,1);
                        }
                        if(this.element.url === imageAsset.fullSize){
                            this.element.url = null;
                            this.updated();
                        }
                    })
                }
            })
        },

        uploadSuccess: function(file, response) {
            this.$refs.fileDropzone.removeFile(file);
            this.imageAssets.push(response);
            this.setCustomImage(response);
        },

        uploadFailure: function(file, message) {
            this.$store.commit('error', 'Error uploading file: ' + message);
            this.$refs.fileDropzone.removeFile(file);
        },

        addFileUploadAuthHeader: function(file, xhr) {
            xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
        },
    },

    computed: {
        dropzoneOptions: function() {
            return {
                url: `${this.$store.getters.getBaseUrl}/assets/images?tags=${this.tags.join(',')}`,
                thumbnailWidth: 50,
                maxFiles: 1,
                maxFilesize: 50,
                withCredentials: true,
                autoProcessQueue: true,
                duplicateCheck: true,
                createImageThumbnails: false,
                acceptedFiles: 'image/*',
                includeStyling: false,
            };
        },
    },
}