var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-4" },
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { align: "center" } },
                [
                  _c("gantt-chart", {
                    attrs: {
                      deliverables: _vm.deliverablesWithDates,
                      "start-date": _vm.start,
                      "end-date": _vm.end,
                      visible: _vm.visible
                    },
                    on: {
                      "edit-deliverable": function($event) {
                        return _vm.$emit("edit-deliverable", $event)
                      },
                      "dates-updated": function($event) {
                        return _vm.$emit("dates-updated", $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }