<template>
	<div id="share-send-widget">
		<input id="copyInput" name="copyInput"  type="hidden" />

		<v-btn icon class="close-dialog">
			<v-icon @click="$emit('close')">$close</v-icon>
		</v-btn>

		<div>
			<div class="modal-padding" v-if="view === 'SELECT'">
				<div class="modal-title">{{ $t('discovery.share.select-method') }}</div>

				<div
						v-if="!sampleMode && !paidAccount"
						class="column-format centered"
						style="width:100%"
				>
					<free-tier-card
							style="width:100%; height:120px"
							message="Embedded forms are not available on the Essentials plan. You can explore this feature, but access to embedding forms will be restricted."
							@upgrade-clicked="$router.push('/subscription')"
					/>
				</div>

				<div class="selector-tile" @click="view = 'EMBED'">
					<div><v-icon>code</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('discovery.share.embed-title') }}</h3>
						<div class="modal-subtitle">{{ $t('discovery.share.embed-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('discovery.share.embed-next') }}</div>
					</div>
				</div>
				<div class="selector-tile" @click="view = 'LINK'">
					<div><v-icon>$share</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('discovery.share.link-title') }}</h3>
						<div class="modal-subtitle">{{ $t('discovery.share.link-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('discovery.share.link-next') }}</div>
					</div>
				</div>
			</div>
			<div v-if="view === 'EMBED'">
				<div class="modal-padding text-left">
					<div class="modal-title mb-2 row-format align-center">
						<v-icon @click="view = 'SELECT'">$arrowLeft</v-icon>
						<div class="ml-3">Copy the code below</div>
					</div>
					<div
						style="font-size:12px; font-family: Courier; border: 1px solid var(--v-gray_50-base); border-radius: 4px; overflow-y: scroll"
						class="pa-3 text-no-wrap"
					>
						&lt;script type=&quot;text/javascript&quot; src=&quot;https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.10/iframeResizer.min.js&quot;&gt;&lt;/script&gt;<br>
						&lt;div style=&quot;width:100%; min-height:500px&quot;&gt;<br>
						&nbsp;&nbsp;&lt;iframe id=&quot;moxie-{{formId}}&quot; allowtransparency=&quot;true&quot; style=&quot;padding: 0px; margin: 0px; border: 0; max-width: 100%; min-width: 100%&quot;&gt;&lt;/iframe&gt;<br>
						&lt;/div&gt;<br>
						&lt;script type=&quot;text/javascript&quot;&gt;<br>
						&nbsp;&nbsp;let moxieFrame = document.getElementById(&quot;moxie-{{formId}}&quot;);<br>
						&nbsp;&nbsp;moxieFrame.src = '{{ fullLinkEmbed }}?inFrame=true&sourceUrl=' + encodeURIComponent(window.location.href)<br>
						&nbsp;&nbsp;setTimeout(() =&gt; iFrameResize({heightCalculationMethod: 'min', sizeWidth: true, sizeHeight: true, log: false, checkOrigin: false}, '#moxie-{{formId}}'),100);<br>
						<br>
						&nbsp;&nbsp;window.addEventListener("message", (event) => {<br>
						&nbsp;&nbsp;&nbsp;&nbsp;if(event.origin === '{{baseUrl}}' && event.data && event.data.startsWith('[Redirect]')){<br>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;let url = event.data.slice(10);<br>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;window.location = url;<br>
						&nbsp;&nbsp;&nbsp;&nbsp;}<br>
						&nbsp;&nbsp;}, false);<br>
						&lt;/script&gt;<br>
					</div>
				</div>
				<div class="modal-footer">
					<v-btn class="primary-action" @click="$emit('close')">Done</v-btn>
				</div>
			</div>
			<div v-if="view === 'LINK'">
				<div class="modal-padding text-left" v-if="view === 'LINK'">
					<div class="modal-title mb-2 row-format align-center">
						<v-icon @click="view = 'SELECT'">$arrowLeft</v-icon>
						<div class="ml-3">Copy the link below</div>
					</div>
					<div class="row-format align-center">
						<v-text-field
								readonly
								persistent-placeholder
								hide-details
								label="Direct meeting link"
								class="h-outline mt-4"
								:value="fullLink"
						></v-text-field>
						<v-btn color="primary" icon class="ml-2" @click="copyLink"><v-icon>content_copy</v-icon></v-btn>
					</div>
				</div>
				<div class="modal-footer">
					<v-btn class="primary-action" @click="$emit('close')">Done</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import FreeTierCard from "@/components/FreeTierCard";
	export default {
		name: 'ShareMeeting',

		props: ['fullLink','fullLinkEmbed','formId','baseUrl'],

		components: {FreeTierCard},

		data: function() {
			return {
				view: 'SELECT',
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			copyLink: function(){
				navigator.clipboard.writeText(this.fullLink)
				this.$store.commit('info', 'Copied to clipboard');
			}
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
		},
	};
</script>

<style scoped lang="scss">
	#share-send-widget {
		background-color: var(--v-white-base);
		width: 600px; // was 800

		.selector-tile {
			cursor: pointer;
			border: 1px solid var(--v-gray_50-base);
			border-radius: 4px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 16px 8px 12px;
			margin: 8px 0;

			&:hover {
				background-color: var(--v-gray_20-base);
				border-color: var(--v-gray_20-base);
				svg {
					--icon-color: var(--v-primary-base);
					color: var(--v-primary-base);
				}
			}
			> div:first-child {
				padding-right: 12px;
				padding-left: 4px;
			}
			> div:last-child {
				text-align: left;
			}
		}
		.link-box {
			//width: 500px;
			border-radius: 4px;
			background-color: var(--v-gray_20-base);
			height: 32px;
			width: 100%;
			padding-left: 8px;
			//padding: 15px;
		}
	}
</style>
