<template>
	<div>
		<div id="googleButtonDiv"></div>
	</div>
</template>

<script>
	import AuthService from '@/modules/auth/AuthService';
	import SignUpService from '@/modules/auth/SignUpService';

	export default {
		name: 'GoogleSignIn',

		props: ['isLogin', 'acceptTerms'],

		components: {},

		data: function () {
			return {
				googleInProcess: false,

				params: {
					client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
					callback: this.runRecaptcha,
				},
			};
		},

		mounted() {
			window.google.accounts.id.initialize(this.params);
			window.google.accounts.id.renderButton(
					document.getElementById("googleButtonDiv"),
					{ theme: "outline", size: "large", width: 400, text: this.isLogin ? 'signin_with' : 'signup_with', logo_alignment: "center" }  // customization attributes
			);
		},

		beforeDestroy() {},

		methods: {
			runRecaptcha(credentialResponse) {
				window.grecaptcha.ready(() => {
					window.grecaptcha
							.execute(process.env.VUE_APP_RECATPCHA_SITE_KEY, { action: 'submit' })
							.then((reCaptchaToken) => {
								this.googleSuccess(credentialResponse, reCaptchaToken);
							});
				});
			},

			googleSuccess(credentialResponse,reCaptchaToken) {
				this.googleInProcess = false;
				let id_token = credentialResponse.credential;

				if (this.isLogin) {
					// handle sign in
					AuthService.loginGoogle(id_token,reCaptchaToken)
						.then((result) => {
							this.$emit('success',{
								result:result,
								source:'Google'
							})
						})
						.catch((error) => {
							this.$track.record('Google sign in FAIL');
							this.emitError(
								this.$t('login.sign-in.invalid-google-account'),
								this.$t('login.sign-up-button'),
								this.$t('login.sign-in-button')
							);
							console.log(error);
						});
				} else {
					this.$store.commit('startLoading');

					SignUpService.signupWithGoogle(id_token,reCaptchaToken)
						.then((result) => {
							this.handleGoogleSignUpSuccess(result);
						})
						.catch((err) => {
							this.emitError(
								this.$t('login.sign-up.invalid-google-account'),
								this.$t('login.sign-in-button'),
								this.$t('login.sign-up-button')
							);
							console.log('Google Error', err);
						})
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			handleGoogleSignUpSuccess(result) {
				this.$store.commit('logIn', result);

				AuthService.getProfile().then((res) => {
					this.$store.state.inWelcomeFlow = true;
					this.$store.state.newUser = true;
					this.$store.dispatch('setLoggedInUser', res);
					this.$track.identify(res);
					this.$track.conversion();
					setTimeout(() => this.$router.push('/welcome'), 500);
				});
			},

			emitError(errorMsg, errorButton, errorButtonSecondary) {
				let errorPayload = {
					errorMsg,
					errorButton,
					errorButtonSecondary,
				};
				this.$emit('error', errorPayload);
			},
		},

		computed: {
			googleButton: function () {
				return this.isLogin ? this.$t('login.sign-in.google-button') : this.$t('login.sign-up.google-button');
			},
		},
	};
</script>

<style scoped lang="scss"></style>
