<template>
	<div class="row-format align-center">
		<v-menu
			v-model="menuOpen"
			bottom nudge-bottom="24"
			:close-on-content-click="true"
			content-class="filter-menu-class"
			transition="slide-y-transition"
		>
			<template v-slot:activator="{  }">
				<div
					@click.stop="setActiveContact(contact)"
					:class="`row-format centered ${client.archive ? 'contact-avatar-archive' : 'contact-avatar'}`"
					v-for="contact in client.contacts"
					:key="contact.id"
					v-tippy="{
						content: contact.fullName,
						placement: 'top',
						arrow: true,
						arrowType: 'round',
						animation: 'fade',
						delay: 50,
					}"
				>
					<div>
						{{ contact.firstName ? contact.firstName.substr(0, 1).toUpperCase() : ''
						}}{{ contact.lastName ? contact.lastName.substr(0, 1).toUpperCase() : '' }}
					</div>
				</div>
			</template>

			<div v-if="activeContact" class="text-left">
				<div class="row-format">
					<v-icon class="ml-auto pointer mt-4 mr-4" small @click="menuOpen=false">$close</v-icon>
				</div>
				<div class="px-4 pb-4 column-format" style="gap: 4px">
					<div class="font-16 font-gray_70">{{activeContact.fullName}}</div>
					<div class="font-14 row-format align-center pointer" v-if="activeContact.email"  @click="sendEmail(activeContact.email)">
						<div class="mr-3">{{activeContact.email}}</div>
						<v-icon class="ml-auto" color="secondary">email</v-icon>
					</div>
					<div class="font-14 row-format align-center pointer" v-if="activeContact.phone" @click="makePhoneCall(activeContact.phone)">
						<div class="mr-3">{{activeContact.phone}}</div>
						<v-icon class="ml-auto" color="secondary">phone</v-icon>
					</div>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	import NewEmail from "@/modules/communicator/inbox/email/NewEmail";

	export default {
		name: 'Contacts',

		props: ['client'],

		components: {},

		data: function () {
			return {
				menuOpen: false,
				activeContact: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			setActiveContact: function (contact) {
				setTimeout(() => {
					this.activeContact = contact;
					this.menuOpen = true
				},100);
			},

			sendEmail: function(email){
				let binding = {
					toList: [email],
				}
				this.$store.state.globalModalController.openModal(NewEmail,binding)
			},

			makePhoneCall: function(phone){
				this.$store.state.eventBus.$emit('communicator-place-call',phone);
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.contact-avatar {
		width: 28px;
		height: 28px;
		border-radius: 100px;
		background-color: var(--v-black-base);
		color: var(--v-white-base);
		font-size: 12px;
		border: 2px solid var(--v-white-base);
		margin-left: -6px;
	}

	.contact-avatar-archive {
		width: 28px;
		height: 28px;
		border-radius: 100px;
		background-color: var(--v-gray_30-base);
		color: var(--v-gray_50-base);
		font-size: 12px;
		border: 2px solid var(--v-white-base);
		margin-left: -6px;
	}
</style>
