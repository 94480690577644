<template>
	<div class="free-card row-format align-center pointer" style="overflow: hidden" @click="$emit('upgrade-clicked')">
		<div style="" class="text-left ml-4">
			<div class="brand-medium">You're on the free Essentials plan!</div>
			<div class="font-14 brand-semilight mt-1" style="line-height: 20px">
				{{message}}
			</div>
		</div>
		<div class="ml-auto" style="position: relative">
			<v-btn style="position: absolute; top: 70px; right: 20px; z-index: 1" color="accent" elevation="0"
				><span style="color: var(--v-white-base)">Upgrade</span></v-btn
			>
			<img src="/images/logo/brain-splash-small.png" class="mt-0" width="140" style="transform: scaleX(-1)" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FreeTierCard',
		props: ['message']
	};
</script>

<style scoped lang="scss">
	.free-card {
		background-color: var(--v-gray_20-base);
		border: 1px solid var(--v-gray_50-base);
		box-shadow: 0px 1px 0px var(--v-gray_50-base);
		border-radius: 8px;
	}
</style>
