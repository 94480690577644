var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row-format",
      staticStyle: { gap: "8px", width: "fit-content" }
    },
    [
      _vm.inInbox
        ? _c(
            "div",
            { staticClass: "row-format align-center" },
            [
              _c(
                "v-menu",
                {
                  staticStyle: { "overflow-y": "auto" },
                  attrs: {
                    "close-on-content-click": false,
                    "close-on-click": true,
                    "content-class": "filter-menu-class"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: { content: "Snooze" },
                                      expression: "{ content: 'Snooze' }"
                                    }
                                  ],
                                  attrs: { icon: "" }
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "material-symbols-rounded",
                                    attrs: { size: _vm.size, color: "gray_90" }
                                  },
                                  [_vm._v("snooze")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1144291312
                  ),
                  model: {
                    value: _vm.snoozeMenu,
                    callback: function($$v) {
                      _vm.snoozeMenu = $$v
                    },
                    expression: "snoozeMenu"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "column-format text-left font-14",
                      staticStyle: { width: "220px" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pt-2 pl-5 font-16 brand-medium" },
                        [_vm._v("Snooze until")]
                      ),
                      _vm.snoozeOptions
                        ? _c(
                            "div",
                            { staticClass: "add-new-dropdown" },
                            [
                              _vm._l(_vm.snoozeOptions, function(option) {
                                return _c(
                                  "div",
                                  {
                                    key: option.id,
                                    staticClass: "add-new-item row-format",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleCustomSnooze(
                                          option.date
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("div", [_vm._v(_vm._s(option.label))]),
                                    _c("div", { staticClass: "ml-auto" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(option.date.toFormat("ccc")) +
                                          " " +
                                          _vm._s(
                                            option.date.toLocaleString(
                                              _vm.HDateTime.TIME_SIMPLE
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                )
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "add-new-item row-format align-center",
                                  on: { click: _vm.customSnoozeDateTime }
                                },
                                [
                                  _c("div", [_vm._v("Pick date & time")]),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-auto",
                                      attrs: { color: "secondary" }
                                    },
                                    [_vm._v("$calendarClock")]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: {
                        content: _vm.unread ? "Mark read" : "Mark unread"
                      },
                      expression:
                        "{ content: unread ? 'Mark read' : 'Mark unread' }"
                    }
                  ],
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("unread")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { size: _vm.size, color: "gray_90" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.unread ? "mark_email_read" : "mark_email_unread"
                        )
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { content: "Archive" },
                      expression: "{ content: 'Archive' }"
                    }
                  ],
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("archive")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { size: _vm.size, color: "gray_90" }
                    },
                    [_vm._v("archive")]
                  )
                ],
                1
              ),
              _vm.itemType
                ? _c("pipeline-link-widget", {
                    attrs: {
                      "item-id": _vm.itemId,
                      "item-type": _vm.itemType,
                      "icon-only": true
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "row-format align-center" },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { content: "Return to inbox" },
                      expression: "{ content: 'Return to inbox' }"
                    }
                  ],
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("return-to-inbox")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { color: "black", size: _vm.size }
                    },
                    [_vm._v("forward_to_inbox")]
                  )
                ],
                1
              ),
              _vm.itemType
                ? _c("pipeline-link-widget", {
                    attrs: {
                      "item-id": _vm.itemId,
                      "item-type": _vm.itemType,
                      "icon-only": true
                    }
                  })
                : _vm._e()
            ],
            1
          ),
      _vm.customMenu
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.customMenu, persistent: false },
              on: {
                close: function($event) {
                  _vm.customMenu = false
                }
              }
            },
            [
              _c("snooze-picker", {
                on: {
                  result: function($event) {
                    return _vm.handleCustomSnooze($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }