import HttpClient from "@/services/HttpClient";

export default class DeliverableSettingsService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getDeliverableStatus() {
		return this.httpClient
			.get(`/deliverableStatus`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateDeliverableStatus(status) {
		return this.httpClient
			.put(`/deliverableStatus`,status)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
