<template>
	<div id="ticket-table">
		<v-data-table
			:headers="headers"
			:items="tickets"
			sort-by="payload.created"
			:sort-desc="true"
			@click:row="$emit('open-ticket', $event)"
			:group-by="groupSelection"
			style="cursor: pointer"
			:items-per-page="-1"
			:hide-default-footer="true"
		>
			<template v-slot:group.header="{ group, headers, toggle }">
				<td colspan="6" class="text-left" @click="toggle">
					<div class="row-format align-center gap-2">
						<v-icon class="" size="20" color="secondary">folder</v-icon>{{ group ? group : ' -- ' }}
					</div>
				</td>
			</template>

			<template v-slot:item.payload.created="{ item }">
				<span v-if="item.payload.created" class="brand-semilight">{{
					DateTime.fromISO(item.payload.created).toFormat('DD')
				}}</span>
			</template>

			<template v-slot:item.payload.updated="{ item }">
				<span v-if="item.payload.updated" class="brand-semilight">{{
					DateTime.fromISO(item.payload.updated).toFormat('DD')
				}}</span>
			</template>

			<template v-slot:item.payload.dueDate="{ item }">
				<span v-if="item.payload.dueDate" class="brand-semilight">{{
					DateTime.fromISO(item.payload.dueDate).toFormat('DD')
				}}</span>
			</template>

			<template v-slot:item.assignedTo="{ item }">
				<div class="row-format">
					<assigned-user
						v-for="(assignedTo, index) in item.payload.assignedTo"
						:key="assignedTo"
						:show-name="false"
						:small="true"
						:assigned-to="assignedTo"
						:style="
							`${
								index > 0 ? 'margin-left: -6px' : ''
							}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
						"
					></assigned-user>
				</div>
			</template>

			<template v-slot:item.payload.status="{ item }">
				<div v-tippy="{theme: 'left-align'}" :content="item.payload.summary">
					<span v-if="item.payload.status" class="status">{{ item.payload.status }}</span>
					<v-icon color="gray_90" size="16" class="material-symbols-rounded ml-1" v-if="item.payload.summary">summarize</v-icon>
					<span v-if="item.payload.ticketNumber"> &bull; {{item.payload.ticketNumber}}</span>
					<span v-if="item.payload.ticketType"> &bull; {{ item.payload.ticketType.type }}</span><br>
					<span v-if="item.payload.subject" class="brand-semilight font-14 font-gray_70">{{ item.payload.subject }}</span>
				</div>
			</template>

			<template v-slot:item.client.name="{ item }">
				<div class="column-format">
					<div class="row-format align-center" v-if="item.client">
						<client-avatar :client="item.client" small class="mr-0 nested-avatar"></client-avatar>
						<span>{{ item.client.name }}</span>
					</div>
					<div class="brand-semilight font-14 font-gray_70">{{ contact(item.payload) ? contact(item.payload).fullName : '--' }}</div>
				</div>
			</template>

			<template v-slot:no-data>
				No ticket records to display. You can try adjusting your filters or creating a new ticket.
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import DateTime from '@/modules/utils/HDateTime';
	import TicketMixin from '@/modules/communicator/inbox/tickets/TicketMixin';
	//import InboxActions from '@/modules/communicator/inbox/InboxActions';
	import AssignedUser from '@/components/AssignedUser';

	export default {
		name: 'TicketTable',

		props: ['communications', 'filter'],

		components: { ClientAvatar, /* InboxActions, */ AssignedUser },

		mixins: [TicketMixin],

		data: function() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			contact: function(item) {
				if(item.clientContactId) {
					return this.$store.getters.getContactByClientPortalId(item.clientContactId);
				}else{
					return null;
				}
			},

			client: function(item) {
				return this.$store.getters.getClientById(item.clientId);
			},

			getClientName: function(item) {
				let client = this.$store.getters.getClientById(item.clientId);
				return client ? client.name : '--';
			},
		},

		computed: {
			headers: function() {
				let result = [
					{ text: 'Client', value: 'client.name', sortable: true },
					{ text: 'Ticket status', value: 'payload.status', sortable: true },
					{ text: 'Assigned To', value: 'assignedTo', sortable: false},
					{ text: 'Created', value: 'payload.created', sortable: true  },
					{ text: 'Updated', value: 'payload.updated', sortable: true  },
					{ text: 'Due', value: 'payload.dueDate', sortable: true  },
					/* {text: '', value: 'action', sortable: false } */
				];

				return result;
			},

			tickets: function(){
				let result = JSON.parse(JSON.stringify(this.communications));
				result.forEach((t) => {
					t.group = {};
					t.group.clientName = t.client ? t.client.name : '--';
					t.group.status = t.payload.status;
					t.group.dueDate = t.payload.dueDate ? DateTime.fromISO(t.payload.dueDate).toLocaleString(DateTime.DATE_FULL) : '--';
				})
				return result;
			},

			groupSelection: function() {
				switch (this.filter.group) {
					case 'None':
						return null;
					case 'Client':
						return 'group.clientName';
					case 'Status':
						return 'group.status';
					case 'Due Date':
						return 'group.dueDate';
					default:
						return null;
				}
			},
		},
	};
</script>

<style lang="scss">
	.status {
		background-color: var(--v-accent-base);
		border-radius: 4px;
		padding: 0px 4px;
		color: var(--v-black-base);
		font-size: 12px;
		font-weight: 500;
	}

	#ticket-table {
		.v-data-table .v-data-table__wrapper table {
			tbody {
				tr {
					td {
						.avatar {
							margin-right: 4px !important;
						}
					}
				}
			}
		}
	}
</style>
