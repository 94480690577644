<template>
	<div :class="$vuetify.breakpoint.smAndDown ? 'column-format' : 'row-format'">
		<div class="left-box mr-4 mb-6">
			<div class="font-24 brand-medium mb-6">{{ header }}</div>
			<div class="mb-6">{{body}}</div>
			<v-btn class="super-action" @click="$emit('cta-clicked')">{{cta}}</v-btn>
		</div>
		<div class="right-box ml-4 mb-6" v-if="videoId">
			<v-img :src="`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`" class="pointer" @click="videoOpen=true" style="border-radius: 4px">
				<div style="width:100%; height:100%" class="row-format centered">
					<div class="play-button"><v-icon class="ml-1">$play</v-icon></div>
				</div>
			</v-img>
			<div class="font-18 brand-medium my-3">{{ videoHeader }}</div>
			<div class="font-14">{{ videoBody }}</div>
			<v-btn class="primary-action mt-4" width="144" style="justify-self: end" @click="videoOpen=true">{{videoCta}}</v-btn>
		</div>

		<v-dialog v-model="videoOpen" v-if="videoOpen" width="840">
			<youtube
					style="background-color: var(--v-black-base); padding-top: 4px"
					:player-vars="{ autoplay: 1 }"
					id="player"
					:video-id="videoId"
					:player-width="840"
					:player-height="462"
			></youtube>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: 'EmptyView',
		components: {},
		props: ['header','body','cta','videoHeader','videoBody','videoCta','videoId'],

		data() {
			return {
				videoOpen: false,
			};
		},

		mounted() {},

		methods: {},
	};
</script>

<style lang="scss" scoped>
	.play-button {
		width: 48px;
		height: 48px;
		border-radius: 24px;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_30-base);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.left-box {
		width:320px;
		min-width:320px;
		min-height: 330px;
		border: 1px solid var(--v-gray_30-base);
		border-radius: 8px;
		text-align: left;
		padding: 24px;
		flex: 1 1 auto;
	}

	.right-box {
		width: 265px;
		min-width: 265px;
		min-height: 330px;
		text-align: left;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		padding: 1px;
	}
</style>
