var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        [
          _c("simple-code-editor", {
            staticStyle: { width: "100%" },
            attrs: {
              theme: _vm.$vuetify.theme.dark ? "dark" : "light",
              font_size: "14px"
            },
            model: {
              value: _vm.embed,
              callback: function($$v) {
                _vm.embed = $$v
              },
              expression: "embed"
            }
          }),
          _c("div", { staticClass: "font-14 text-left my-2 font-italic" }, [
            _vm._v(
              "Custom code embedding can be used to add any custom JavaScript to your client portal. This can include analytics, chat widgets, or anything that requires you load custom script to extend functionality of your client portal."
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }