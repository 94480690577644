var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-modal", staticStyle: { width: "600px" } },
    [
      _c("div", { staticClass: "panel-modal-header" }, [
        _c(
          "div",
          { staticClass: "row-format align-center" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mr-2 pointer",
                attrs: { small: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("result")
                  }
                }
              },
              [_vm._v("$chevronRight")]
            ),
            _c(
              "div",
              {
                staticClass: "brand-semibold",
                staticStyle: { "font-size": "24px" }
              },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "tippy",
                        rawName: "v-tippy",
                        value: { content: "View in Google Calendar" },
                        expression: "{ content: 'View in Google Calendar' }"
                      }
                    ],
                    staticStyle: { "text-decoration": "none" },
                    attrs: {
                      href: _vm.orig.meta.externalLink,
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(_vm.orig.summary))]
                )
              ]
            ),
            _c(
              "v-btn",
              {
                staticClass: "ml-auto",
                attrs: { icon: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("result")
                  }
                }
              },
              [_c("v-icon", [_vm._v("$close")])],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "panel-modal-body py-2 show-scrollbar" },
        [
          _c(
            "v-container",
            {
              staticClass: "pa-0 ma-0",
              staticStyle: {
                "background-color": "var(--v-white-base)",
                "overflow-wrap": "anywhere"
              },
              attrs: { fluid: "" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12", align: "left" } }, [
                    _c(
                      "div",
                      { staticClass: "column-format" },
                      [
                        _c("div", { staticClass: "my-3" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.HDateTime.fromISO(
                                  _vm.orig.start
                                ).toLocaleString(_vm.startFormat)
                              )
                            )
                          ]),
                          !_vm.isSameDay || !_vm.dateOnly
                            ? _c("span", [
                                _vm._v(
                                  " - " +
                                    _vm._s(
                                      _vm.HDateTime.fromISO(
                                        _vm.orig.end
                                      ).toLocaleString(_vm.endFormat)
                                    )
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("create-time-entry", {
                          attrs: {
                            start: _vm.orig.start,
                            end: _vm.orig.end,
                            notes: _vm.orig.summary,
                            client: _vm.guessedClient
                          }
                        })
                      ],
                      1
                    ),
                    _vm.orig.attendees && _vm.orig.attendees.length > 0
                      ? _c(
                          "div",
                          { staticClass: "my-5" },
                          [
                            _c("span", { staticClass: "brand-semibold" }, [
                              _vm._v("Attendees:"),
                              _c("br")
                            ]),
                            _vm._l(_vm.orig.attendees, function(attendee) {
                              return _c("li", { key: attendee }, [
                                _c(
                                  "a",
                                  { attrs: { href: "mailto:" + attendee } },
                                  [_vm._v(_vm._s(attendee))]
                                )
                              ])
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.orig.meta.location
                      ? _c("div", { staticClass: "my-5" }, [
                          _c("span", { staticClass: "brand-semibold" }, [
                            _vm._v("Location:"),
                            _c("br")
                          ]),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.orig.meta.location)
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm.orig.meta.hangoutLink
                      ? _c(
                          "div",
                          { staticClass: "my-6 column-format" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticStyle: { "text-transform": "none" },
                                attrs: {
                                  outlined: "",
                                  color: "primary",
                                  href: _vm.orig.meta.hangoutLink,
                                  target: "_blank",
                                  width: "250"
                                }
                              },
                              [_vm._v("Join with Google Meet")]
                            ),
                            _c("div", { staticClass: "small-label my-2" }, [
                              _vm._v(_vm._s(_vm.orig.meta.hangoutLink))
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.orig.description
                      ? _c("div", { staticClass: "my-6" }, [
                          _c("span", { staticClass: "brand-semibold" }, [
                            _vm._v("Description:"),
                            _c("br")
                          ]),
                          _c("div", [
                            _c("div", {
                              staticStyle: {
                                "font-size": "12px",
                                "white-space": "pre-wrap"
                              },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.linkify(_vm.orig.description)
                                )
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mt-3" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.orig.meta.externalLink,
                            target: "_blank"
                          }
                        },
                        [
                          _c("v-img", {
                            directives: [
                              {
                                name: "tippy",
                                rawName: "v-tippy",
                                value: { content: "View in Google Calendar" },
                                expression:
                                  "{ content: 'View in Google Calendar' }"
                              }
                            ],
                            attrs: {
                              src: "images/dashboard/google-calendar-logo.png",
                              width: "50"
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "font-12 font-gray_70" }, [
                        _vm._v("Source: " + _vm._s(_vm.orig.meta.fromCalendar))
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }