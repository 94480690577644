var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contact
    ? _c(
        "div",
        { staticClass: "panel-modal", staticStyle: { "max-width": "500px" } },
        [
          _c("div", { staticClass: "panel-modal-header" }, [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-2 pointer",
                    attrs: { small: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("result")
                      }
                    }
                  },
                  [_vm._v("$chevronRight")]
                ),
                _c("div", { staticClass: "brand-medium font-18" }, [
                  _vm.isNewContact
                    ? _c("span", {}, [
                        _vm._v(_vm._s(_vm.$t("contact.new-contact")))
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.$t("contact.edit-contact")))
                      ])
                ]),
                _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "super-action",
                        on: { click: _vm.saveContact }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer ml-2 material-symbols-rounded",
                        attrs: { size: "20", color: "gray_60" },
                        on: { click: _vm.confirmDelete }
                      },
                      [_vm._v("delete")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "panel-modal-body text-left show-scrollbar" },
            [
              _c(
                "v-form",
                {
                  ref: "updateContact",
                  attrs: { id: "update-contact", "lazy-validation": "" },
                  model: {
                    value: _vm.isValid,
                    callback: function($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "column-format gap-3 pt-2",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row-format gap-2" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("contact.first-name"),
                                      "hide-details": "",
                                      outlined: "",
                                      dense: "",
                                      "persistent-placeholder": "",
                                      rules: [_vm.rules.firstName]
                                    },
                                    on: { blur: _vm.resetValidation },
                                    model: {
                                      value: _vm.contact.firstName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contact,
                                          "firstName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contact.firstName"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      "hide-details": "",
                                      outlined: "",
                                      dense: "",
                                      "persistent-placeholder": "",
                                      label: _vm.$t("contact.last-name"),
                                      rules: [_vm.rules.lastName]
                                    },
                                    on: { blur: _vm.resetValidation },
                                    model: {
                                      value: _vm.contact.lastName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contact,
                                          "lastName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contact.lastName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("contact.role"),
                                  "hide-details": "",
                                  dense: "",
                                  outlined: "",
                                  "persistent-placeholder": ""
                                },
                                model: {
                                  value: _vm.contact.role,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contact,
                                      "role",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "contact.role"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  "validate-on-blur": "",
                                  rules: _vm.emailRequired
                                    ? [_vm.rules.required, _vm.rules.email]
                                    : [_vm.rules.email],
                                  type: "email",
                                  "hide-details": "",
                                  outlined: "",
                                  dense: "",
                                  "persistent-placeholder": "",
                                  label: _vm.$t("contact.email")
                                },
                                model: {
                                  value: _vm.contact.email,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contact,
                                      "email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "contact.email"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    position: "relative"
                                  }
                                },
                                [
                                  _c("vue-tel-input", {
                                    staticClass: "dense-phone",
                                    attrs: {
                                      "valid-characters-only": true,
                                      "preferred-countries":
                                        _vm.preferredCountries,
                                      inputOptions: { placeholder: "Phone" }
                                    },
                                    on: { input: _vm.phoneUpdated },
                                    model: {
                                      value: _vm.phone,
                                      callback: function($$v) {
                                        _vm.phone = $$v
                                      },
                                      expression: "phone"
                                    }
                                  }),
                                  _c("div", { staticClass: "phone-label" }, [
                                    _vm._v("Phone")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "column-format gap-3" },
                                _vm._l(_vm.fields, function(field) {
                                  return _c("custom-field", {
                                    key: field.id,
                                    attrs: {
                                      mode: "dense",
                                      field: field,
                                      value: _vm.getCustomValue(field.id)
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleCustomFieldChange(
                                          $event
                                        )
                                      }
                                    }
                                  })
                                }),
                                1
                              ),
                              _c("v-textarea", {
                                attrs: {
                                  label: _vm.$t("global.notes"),
                                  "no-resize": "",
                                  "auto-grow": "",
                                  rows: "1",
                                  "hide-details": "",
                                  dense: "",
                                  outlined: "",
                                  "persistent-placeholder": ""
                                },
                                model: {
                                  value: _vm.contact.notes,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contact,
                                      "notes",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "contact.notes"
                                }
                              }),
                              !_vm.clientId
                                ? _c("select-client", {
                                    attrs: {
                                      label: "Client",
                                      "show-avatar": true,
                                      dense: true
                                    },
                                    model: {
                                      value: _vm.contact.clientId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.contact, "clientId", $$v)
                                      },
                                      expression: "contact.clientId"
                                    }
                                  })
                                : _vm._e(),
                              _vm.contact.clientId
                                ? _c(
                                    "div",
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          dense: "",
                                          inset: "",
                                          "hide-details": "",
                                          label: _vm.$t(
                                            "contact.default-contact"
                                          )
                                        },
                                        model: {
                                          value: _vm.contact.defaultContact,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "defaultContact",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "contact.defaultContact"
                                        }
                                      }),
                                      _c("v-switch", {
                                        staticClass: "mt-1",
                                        attrs: {
                                          dense: "",
                                          inset: "",
                                          "hide-details": "",
                                          label: _vm.$t(
                                            "contact.invoice-contact"
                                          )
                                        },
                                        model: {
                                          value: _vm.contact.invoiceContact,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "invoiceContact",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "contact.invoiceContact"
                                        }
                                      }),
                                      _c("v-switch", {
                                        staticClass: "mt-1",
                                        attrs: {
                                          dense: "",
                                          inset: "",
                                          "hide-details": "",
                                          label: _vm.$t("contact.portal-access")
                                        },
                                        model: {
                                          value: _vm.contact.portalAccess,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.contact,
                                              "portalAccess",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "contact.portalAccess"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-8" },
                    [
                      _c("person-communications", {
                        attrs: { person: _vm.contact },
                        on: {
                          "view-communication": function($event) {
                            return _vm.openCommunication($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }