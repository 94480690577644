import TimerEventList from "@/modules/timetracking/TimerEventList";

export default [
	{
		path: '/timesheets',
		name: 'timeSheets',
		component: TimerEventList,
		title: 'Timer Event List',
	}
];
