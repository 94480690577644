var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "pointer",
        attrs: {
          id: "project-data-table",
          items: _vm.projects,
          headers: _vm.headers,
          "hide-default-footer": true,
          "no-data-text": "No fixed price projects found",
          "sort-by": "amountDue",
          "sort-desc": true
        },
        on: { "click:row": _vm.handleProjectSelected },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [_vm._v(" " + _vm._s(item.name) + " ")]
            }
          },
          {
            key: "item.feeSchedule.amount",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$formatters.dollars(
                        item.feeSchedule.amount,
                        true,
                        true,
                        _vm.invoice.currency
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.amountDue",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$formatters.dollars(
                        item.amountDue,
                        true,
                        true,
                        _vm.invoice.currency
                      )
                    ) +
                    " "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }