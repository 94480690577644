var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isConditionMet
    ? _c(
        "div",
        {
          style: "width: 100%; text-align: " + _vm.item.alignment,
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            }
          }
        },
        [
          _c("img", {
            style: "width:" + _vm.item.width + "px",
            attrs: { src: _vm.accountLogo }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }