<template>
	<div id="products-widget">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding pb-4">
			<div class="modal-title" style="">
				<v-text-field autofocus hide-details dense solo flat placeholder="Filter products & services..." style="max-width: 300px" v-model="filter"></v-text-field>
			</div>
			<div class="row-format mb-3">
				<v-btn color="primary" small outlined @click="adHocProduct">Ad-hoc line item</v-btn>
				<v-btn class="ml-auto" color="secondary" small @click="$refs.ProductTable.createNewProduct()">
					+ Add product/service
				</v-btn>
			</div>

			<products-table ref="ProductTable" :currency="currency" @set-products="$emit('set-products',$event)" @close="$emit('close')" @select="$emit('select',$event)" :filter="filter"></products-table>
		</div>
		<div class="modal-footer text-center">
			<v-btn class="primary-action" style="width: 140px" @click="$emit('close')">
				{{ $t('global.close') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
	import ProductsTable from "@/modules/invoices/InvoiceRenderer/ProductsTable";
	import {v4 as uuid} from 'uuid';

	export default {
		name: 'Products',
		components: {ProductsTable },
		props: ['currency'],

		data() {
			return {
				filter: null
			};
		},

		mounted() {
		},

		methods: {
			adHocProduct: function(){
				let product = {
					id: uuid(),
					productName: "",
					unit: "",
					description: "",
					rate: 0.00,
					quantity: 1,
					taxable: false,
					deposit: false,
					hourly: false,
					currencyRates: []
				}
				this.$emit('select',{product:product});
			},
		},

		computed: {},

		watch: {},
	};
</script>

<style lang="scss">
	#products-widget {
		font-family: Inter, sans-serif!important;
		min-width: 420px;
		background-color: var(--background);
		.v-data-table .v-data-table__wrapper {
			table tbody tr td {
				padding-top: 12px !important;
				padding-bottom: 4px !important;
			}
		}
		.edit-icon, .delete-icon {
			//--icon-color: red;
			//svg {
			//	color: var(--v-gray_80-base);
			//}
		}
	}
	#edit-product {
		width: 420px;
		div.h-outline {
			height: 52px;
			margin-top: 4px;
			margin-bottom: 8px;
			width: 100%;
			align-items: center;
			display: flex;
		}
		div.half {
			gap: 12px;
			> div {
				flex: 0 1 auto;
			}
		}
	}
	div.product-name {
		max-width: 260px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:nowrap;
	}
</style>
