var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.label,
            expression: "label"
          }
        ],
        ref: "label",
        attrs: { type: "hidden", "df-label": "" },
        domProps: { value: _vm.label },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.label = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.conditions_json,
            expression: "conditions_json"
          }
        ],
        ref: "conditions_json",
        attrs: { type: "hidden", "df-conditions_json": "" },
        domProps: { value: _vm.conditions_json },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.conditions_json = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.operator,
            expression: "operator"
          }
        ],
        ref: "operator",
        attrs: { type: "hidden", "df-operator": "" },
        domProps: { value: _vm.operator },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.operator = $event.target.value
          }
        }
      }),
      _c("node-label", {
        on: {
          open: function($event) {
            return _vm.$refs.nodeModal.open()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "row-format align-center",
                    staticStyle: { "min-width": "110px" }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "material-symbols-rounded",
                        attrs: { color: "black", size: "20" }
                      },
                      [_vm._v("alt_route")]
                    ),
                    _vm.label
                      ? _c("div", { staticClass: "pr-6" }, [
                          _vm._v(_vm._s(_vm.label))
                        ])
                      : _c("div", [_vm._v("Decision")])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("node-modal", {
        ref: "nodeModal",
        attrs: { title: "Conditions" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "column-format gap-3" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        outlined: "",
                        "persistent-placeholder": "",
                        placeholder: "Label",
                        maxlength: "25"
                      },
                      model: {
                        value: _vm.label,
                        callback: function($$v) {
                          _vm.label = $$v
                        },
                        expression: "label"
                      }
                    }),
                    _vm._l(_vm.conditions, function(condition) {
                      return _c("condition", {
                        key: condition.id,
                        attrs: { condition: condition },
                        on: {
                          change: function($event) {
                            return _vm.updateCondition($event)
                          },
                          delete: function($event) {
                            return _vm.removeCondition(condition)
                          }
                        }
                      })
                    }),
                    _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.addCondition()
                              }
                            }
                          },
                          [_vm._v("+ Add condition")]
                        ),
                        _vm.conditions.length > 1
                          ? _c("v-select", {
                              staticClass: "ml-auto mr-7",
                              staticStyle: { "max-width": "120px" },
                              attrs: {
                                items: ["And", "Or"],
                                "hide-details": "",
                                dense: "",
                                outlined: "",
                                label: "Operator"
                              },
                              model: {
                                value: _vm.operator,
                                callback: function($$v) {
                                  _vm.operator = $$v
                                },
                                expression: "operator"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }