var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "solid-hr" }, [
    _vm.meeting.pageLayout.showLogo
      ? _c("div", { staticClass: "pb-6" }, [
          _c("img", {
            staticStyle: {
              display: "flex",
              "max-width": "80%",
              "max-height": "150px",
              "align-self": "start"
            },
            attrs: {
              alt: "Image not found",
              src: this.$store.getters.getAccountLogo
            }
          })
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "px-4 text-left form-content",
        on: {
          click: function($event) {
            return _vm.activateEditor(true)
          }
        }
      },
      [
        _vm.editorActive
          ? _c("editor", {
              ref: "SchedulerTextEditor",
              attrs: {
                "api-key": _vm.$store.getters.getTinyMceKey,
                inline: true,
                init: _vm.mceConfigText
              },
              on: {
                onblur: function($event) {
                  return _vm.activateEditor(false)
                }
              },
              model: {
                value: _vm.meeting.scheduleMeeting.schedulerInfo,
                callback: function($$v) {
                  _vm.$set(_vm.meeting.scheduleMeeting, "schedulerInfo", $$v)
                },
                expression: "meeting.scheduleMeeting.schedulerInfo"
              }
            })
          : _c("div", {
              staticStyle: { "line-height": "1.8" },
              domProps: { innerHTML: _vm._s(_vm.tokenizedText(_vm.meeting)) }
            })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }