var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Mapping key"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.item.fieldName,
          callback: function($$v) {
            _vm.$set(_vm.item, "fieldName", $$v)
          },
          expression: "item.fieldName"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }