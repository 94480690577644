import ExpenseList from "@/modules/accounting/expenses/ExpenseList";
import VendorList from "@/modules/accounting/vendors/VendorList";
import ReportViewer from "@/modules/accounting/reports/ReportViewer";
import PlaidIntegration from "@/modules/accounting/accounts/PlaidIntegration";
import InvoiceMain from "@/modules/accounting/invoices/InvoiceMain";
import PaymentList from "@/modules/accounting/payments/PaymentList";
import AccountingRouter from "@/modules/accounting/AccountingRouter";
import ProductList from "@/modules/invoices/ProductList";
import InvoiceTemplateList from "@/modules/templates/invoices/InvoiceTemplateList";

export default [
	{
		path: '/accounting',
		name: 'accounting',
		component: AccountingRouter,
		title: 'Accounting Router',
		props: true,
	},
	{
		path: '/accounting/invoices',
		name: 'invoices',
		component: InvoiceMain,
		title: 'Invoices',
		props: true,
	},
	{
		path: '/accounting/payments',
		name: 'payments',
		component: PaymentList,
		title: 'PaymentList',
		props: true,
	},
	{
		path: '/accounting/expenses',
		name: 'expenses',
		component: ExpenseList,
		title: 'Expenses',
		props: true,
	},
	{
		path: '/accounting/vendors',
		name: 'vendors',
		component: VendorList,
		title: 'Vendors',
		props: true,
	},
	{
		path: '/accounting/reports',
		name: 'accountingReports',
		component: ReportViewer,
		title: 'Reports',
		props: true,
	},
	{
		path: '/accounting/accounts',
		name: 'accounts',
		component: PlaidIntegration,
		title: 'Accounts',
		props: true,
	},
	{
		path: '/accounting/products',
		name: 'products',
		component: ProductList,
		title: 'Products & services',
		props: true,
	},
	{
		path: '/accounting/templates',
		name: 'invoiceTemplates',
		component: InvoiceTemplateList,
		title: 'Invoice templates',
		props: true,
	},

];
