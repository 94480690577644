<template>
	<v-container
		fluid
		class="pa-0 ma-0"
		@click="$emit('click', $event)"
		:style="`min-height: ${item.minHeight}px; ${background}`"
	>
		<v-row class="pa-0 ma-0">
			<v-col
				cols="12"
				:md="cols"
				v-for="(column, colIndex) in filteredColumns"
				:key="colIndex"
				class="pa-0 ma-0 column-format"
			>
				<div
					:style="
						`padding-left: ${column.pl}px; padding-right: ${column.pr}px; padding-bottom: ${column.pb}px; padding-top: ${column.pt}px; `
					"
				>
					<draggable :list="column.items" :group="item.id" :disabled="!active">
						<div v-for="(item, subIndex) in column.items" :key="item.id">
							<item-renderer
								ref="item"
								:index="`${index}.${colIndex}.${subIndex}`"
								:item="item"
								:styles="styles"
								:edit-mode="editMode"
								:in-container="true"
								:account-logo="accountLogo"
								:tokens="tokens"
								:invoice="invoice"
								:token-map="tokenMap"
								:in-builder="inBuilder"
								:deposit-account="depositAccount"
								:recurring="recurring"
								:payment-plan="paymentPlan"
								:invoice-service="invoiceService"
								:attachment-service="attachmentService"
								:scheduled-payments="scheduledPayments"
								:has-expenses="hasExpenses"
								:active="active && item.id === editItemId && item.type === 'TextBlock'"
								@change="containerElementUpdated(colIndex, $event)"
                @delete="$emit('delete')"
								@click="active ? containerItemClick(colIndex, item, $event) : null"
								@input="$emit('input', $event)"
							></item-renderer>
						</div>
					</draggable>
					<div v-if="inBuilder">
						<v-menu close-on-content-click close-on-click v-if="active" bottom>
							<template v-slot:activator="{ on }">
								<v-btn small v-on="on" color="white" class="mt-3">+ Add element</v-btn>
							</template>

							<div style="background-color: var(--v-white-base)" class="pa-2">
								<div
									v-for="(category, sIndex) in schemaTypes"
									:key="sIndex"
									style="border-bottom: 1px solid var(--v-gray_30-base)"
									class="mb-2 pb-1"
								>
									<div class="text-left">{{ category.label }}</div>
									<div
										v-for="schema in category.types.filter((t) => t.allowInContainer)"
										:key="schema.type"
										class="row-format align-center schema-item pa-1 gap-2"
										@click="
											$emit('add-container-element', { container: item, column: colIndex, element: schema })
										"
									>
										<v-icon class="material-symbols-rounded" color="secondary" size="24">{{
											schema.icon
										}}</v-icon>
										<div class="column-format text-left">
											<div class="font-14">{{ schema.label }}</div>
											<div class="font-12 mt-n1 font-gray_70">{{ schema.description }}</div>
										</div>
									</div>
								</div>
							</div>
						</v-menu>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import RendererMixin from '@/modules/forms/v2/schema/RendererMixin';
	import SchemaTypes from '@/modules/templates/invoices/SchemaTypes.json';

	export default {
		name: 'Container',

		props: [
			'item',
			'styles',
			'active',
			'accountLogo',
			'editMode',
			'index',
			'tokens',
			'invoice',
			'tokenMap',
			'inBuilder',
			'depositAccount',
			'invoiceService',
			'attachmentService',
			'recurring',
			'paymentPlan',
			'scheduledPayments',
			'hasExpenses'
		],

		mixins: [RendererMixin],

		components: {
			ItemRenderer: () => import('@/modules/templates/invoices/schema/ItemRenderer'),
			draggable: () => import('vuedraggable'),
		},

		data: function() {
			return {
				editItem: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			validate: function() {
				let invalidCount = 0;

				if (this.$refs.item) {
					for (let i = 0; i < this.$refs.item.length; i++) {
						if (!this.$refs.item[i].validate()) {
							invalidCount++;
						}
					}
				}

				return invalidCount <= 0;
			},

			containerItemClick: function(index, item, event) {
				event.stopPropagation();
				this.editItem = item;
				this.$emit('container-item-click', {
					container: this.item,
					item: item,
					index: index,
				});
			},

			containerElementUpdated: function(index, item) {
				this.$emit('container-item-update', {
					container: this.item,
					item: item,
					index: index,
				});
			},
		},

		watch: {
			active: function(val) {
				if (!val) {
					this.editItem = null;
				}
			},
		},

		computed: {
			editItemId: function() {
				if (this.editItem) {
					return this.editItem.id;
				} else {
					return null;
				}
			},

			cols: function() {
				return 12 / this.item.columnCount;
			},

			filteredColumns: function() {
				let columns = [...this.item.columns];
				return columns.splice(0, this.item.columnCount);
			},

			schemaTypes: function() {
				let result = [...SchemaTypes];
				return result;
			},

			background: function() {
				let result = '';
				if (this.item.backgroundImageUrl) {
					result = result + `background-image: url(${this.item.backgroundImageUrl}); background-size: cover; `;
				}
				if (this.item.backgroundColor) {
					result = result + `background-color: ${this.item.backgroundColor}; `;
				}
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.schema-item {
		border-radius: 4px;
		&:hover {
			background-color: var(--v-gray_10-base);
			cursor: pointer;
		}
	}
</style>
