var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "var(--v-white-base)" } },
    [
      _c(
        "div",
        { staticClass: "row-format" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-auto",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("result")
                }
              }
            },
            [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$close")])],
            1
          )
        ],
        1
      ),
      _vm.timer
        ? _c("client-project-select", {
            attrs: {
              "current-timer": _vm.timer,
              "show-client": _vm.showClient,
              "show-project": _vm.showProject,
              "show-deliverable": _vm.showDeliverable
            },
            on: {
              "delete-timer": _vm.deleteTimer,
              "update-timer": function($event) {
                return _vm.handleUpdateTimer($event)
              },
              close: function($event) {
                return _vm.$emit("result")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }