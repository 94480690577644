<template>
	<div class="row-format gap-2 font-14 pointer" v-if="timerEvent" @click="openTimerEvent">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" v-tippy="{ content: 'Timer event' }"
			>timer</v-icon
		>
		<div>{{formattedSeconds}}<span v-if="timerEvent.notes"> -  {{truncateString(timerEvent.notes,35)}}</span></div>
		<div class="ml-auto font-gray_80 font-12">{{ timerEvent.timerStart | formatForTimeAgo }}</div>
	</div>
</template>

<script>
	import TimeTrackService from "@/modules/timetracking/TimeTrackService";
	import TimeTrackEdit from "@/modules/timetracking/TimeTrackEdit";

	export default {
		name: 'Request',

		props: ['item'],

		components: {},

		data: function() {
			return {
				timerEvent: null,
				timeTrackService: new TimeTrackService(),
			};
		},

		mounted() {
			this.getTimerEvent();
		},

		beforeDestroy() {},

		methods: {
			truncateString(string,length){
				if(string && string.length > length){
					return string.substring(0,length) + "...";
				}else{
					return string;
				}
			},
			getTimerEvent: function() {
				this.timeTrackService
					.getTimerEvent(this.item.itemId)
					.then((res) => {
						this.timerEvent = res.data;
						this.$emit('actual-time', this.timerEvent.timerStart);
					})
					.catch((err) => {
						if (err.response.status === 404) {
							this.$emit('deleted');
						}
					});
			},

			formatSeconds: function(seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				//let time = hours + ':' + minutes + ':' + seconds;
				let time = hours + ':' + minutes;
				return time;
			},

			openTimerEvent: function() {
				let binding = {
					timerEvent: this.timerEvent,
				};
				console.log(this.timerEvent);
				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					this.timerEvent = res;
				});
			},
		},

		computed: {
			formattedSeconds: function() {
				if(!this.timerEvent){
					return '00:00:00';
				}
				let seconds = this.timerEvent.duration;

				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				return hours + ':' + minutes + ':' + seconds;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
