<template>
	<div
		:style="backgroundImage"
		:class="(isPreview ? 'preview' : '') + (logo ? ' with-logo' : ' without-logo') + ' ' + getHeightClass()"
		id="cover-wrapper"
	>
		<div :class="coverTextClass">
			<h1 :class="headingClass" :style="headerStyle" v-if="headingPosition === 'Prominent'">
				<editor
					ref="headerEditor"
					:disabled="!editMode || clientMode"
					:api-key="$store.getters.getTinyMceKey"
					:initial-value="headingInitial"
					:inline="true"
					:init="mceConfigHeader"
					:spellcheck="true"
					:placeholder="$t('proposals.cover-page.heading-placeholder')"
					@input="handleHeaderInput"
				></editor>
			</h1>
			<div :class="'subheader ' + (logo ? 'with-logo' : 'without-logo') + (isPreview ? ' preview' : '')">
				<div class="logo-box">
					<div class="logo-box-inner" :style="`background-image: url(${logo});`">
						<img class="account-logo" :src="logo" alt="Placeholder for sizing" style="visibility: hidden" />
					</div>
				</div>
				<h2 :class="headingClass" :style="preparedStyle">
					<editor
							ref="customBy"
							:disabled="!editMode || clientMode"
							:api-key="$store.getters.getTinyMceKey"
							:initial-value="customByInitial"
							:inline="true"
							:init="mceConfigHeader"
							:spellcheck="true"
							:placeholder="$t('proposals.cover-page.by-placeholder')"
							@onblur="handleByInput"
					></editor>
					<editor
						ref="customFor"
						:disabled="!editMode || clientMode"
						:api-key="$store.getters.getTinyMceKey"
						:initial-value="customForInitial"
						:inline="true"
						:init="mceConfigHeader"
						:spellcheck="true"
						:placeholder="$t('proposals.cover-page.for-placeholder')"
						@onblur="handleForInput"
					></editor>
				</h2>
			</div>
		</div>
		<div v-if="!isPreview && editMode" class="edit-button">
			<v-btn icon class="secondary-action" @click="$emit('cover-modal')">
				<v-icon size="20">$edit</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CoverPage',

		props: {
			proposalType: String,
			theme: String,
			image: String,
			heading: String,
			subHeading: String,
			preparedBy: String,
			customBy: String,
			customFor: String,
			editMode: Boolean,
			mceConfigHeader: Object,
			formatting: Object,
			clientName: String,
			contractorName: String,
			clientMode: Boolean,
			templateMode: Boolean,
			headingPosition: String,
			isPreview: {
				type: Boolean,
				default: false,
			},
			logo: String,
		},

		data: function() {
			return {
				lHeading: this.heading,
				lPreparedBy: this.preparedBy,
				lCustomBy: this.customBy,
				lCustomFor: this.customFor,
				lSubHeading: this.subHeading,
				forDefault: 'For <strong>' + this.clientName + '</strong>',
				byDefault: 'By <strong>' + this.contractorName + '</strong>',
			};
		},

		mounted() {
		},
		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		methods: {
			handleHeaderInput: function() {
				this.lHeading = this.$refs.headerEditor.editor.getContent();
				this.notifyChange();
			},

			getHeightClass: function(){
				if(!this.backgroundImage && this.headingPosition === 'Prominent'){
					return 'no-image';
				}else if(!this.backgroundImage){
					return 'no-image-no-header'
				}else{
					return ''
				}
			},

			handleByInput: function() {
				// this.proposal.coverPage.customBy = this.$refs.customBy.editor.getContent();
				this.lCustomBy = this.$refs.customBy.editor.getContent();
				if (!this.lCustomBy) {
					this.$refs.customBy.editor.setContent(this.byDefault);
					this.lCustomBy = null;
				}
				console.log(this.lCustomBy);
				this.notifyChange();
			},

			handleForInput: function() {
				// this.proposal.coverPage.customFor = this.$refs.customFor.editor.getContent();
				this.lCustomFor = this.$refs.customFor.editor.getContent();
				if (!this.lCustomFor) {
					this.$refs.customFor.editor.setContent(this.forDefault);
					this.lCustomFor = null;
				}
				console.log(this.lCustomFor);
				this.notifyChange();
			},

			notifyChange: function() {
				this.$emit('changed', {
					heading: this.lHeading,
					customBy: this.lCustomBy,
					customFor: this.lCustomFor,
				});
			},
		},

		computed: {
			headingInitial() {
				if (this.heading) {
					return this.heading;
				}
				return this.$t('proposals.cover-page.heading-default');
			},

			customForInitial() {
				if (this.customFor) {
					console.log(this.customFor);
					return this.customFor;
				}
				return this.forDefault;
			},

			customByInitial() {
				if (this.customBy) {
					console.log(this.customBy);
					return this.customBy;
				}
				return this.byDefault;
			},

			logoSize() {
				if (!this.coverPage.showLogo) return;
				let img = new Image();

				img.onload = function() {
					let height = img.height;
					let width = img.width;
					return { height: height, width: width };
				};

				img.src = this.account.accountLogo;
				return { height: 'auto', width: 'auto' };
			},

			key() {
				return process.env.VUE_APP_TINYMCE_KEY;
			},

			coverTextClass: function() {
				let css = ['cover-text'];

				if (this.isPreview) {
					css.push('preview');
				}

				if (this.theme === 'dark') {
					css.push('cover-dark');
				} else {
					css.push('cover-light');
				}
				return css.join(' ');
			},

			headingClass: function() {
				let css = [];
				if (this.isPreview) {
					css.push('preview');
				}
				if (this.theme === 'dark') {
					css.push('header-dark');
				} else {
					css.push('header-light');
				}
				return css.join(' ');
			},

			backgroundImage: function() {
				if(this.image){
					let height = 400;
					let width = 918;

					if (this.theme === 'blank') {
						return 'background-color: #FFF';
					} else if (this.image.includes('.amazonaws.com/')) {
						// Custom image; Appending things breaks URL.
						return `background-image: url('${this.image}');`;
					} else {
						// Unsplash
						if (this.isPreview) {
							width = 537;
							height = 234;
						}
						return `background-image: url('${this.image}&q=50&h=${height}&w=${width}&crop=edges&fit=crop');`;
					}
				}else{
					return null;
				}
			},


			headerWeight: function() {
				if (this.formatting.headerWeight === 'Regular') {
					return 400;
				} else {
					return 600;
				}
			},

			preparedStyle: function() {
				// let fontSize = this.isPreview ? 12 : 16;
				return `font-family: ${this.formatting.headerFont}; text-align: left;`;
			},

			headerStyle: function() {
				// let fontSize = this.isPreview ? 35 : 50;
				if(this.$vuetify.breakpoint.xsOnly){
					return `font-size: 36px; font-family: ${this.formatting.headerFont}; font-weight: ${this.headerWeight}; text-align: left; line-height: 36px;`;
				}
				return `font-family: ${this.formatting.headerFont}; font-weight: ${this.headerWeight}; text-align: left`;
			},
		},
	};
</script>

<style scoped lang="scss">
	#cover-wrapper {
		border-radius: 4px;
		background-color: var(--v-gray_10-base);
		height: 400px;
		&.preview {
			height: 234px;
		}
		&.no-image-no-header {
			height: 100px;
		}
		&.no-image {
			padding-top: 16px;
			height: auto;
		}
		background-size: cover;
		background-repeat: no-repeat !important;
		background-position: center center;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
		position: relative;
		transition: margin-bottom 600ms;
		&.with-logo {
			margin-bottom: 86px;
			transition: margin-bottom 600ms;
		}
		//border: 1px solid pink;

		.edit-button {
			position: absolute;
			top: 56px;
			right: 56px;
		}
		//transform: scale(0.7);

		.cover-text {
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-start;
			padding: 0 24px 12px 48px;

			h1 {
				width: 100%;
				font-size: 56px;
				line-height: 72px;
				margin: 12px 0 0 !important;
			}
			.subheader {
				//transition: margin-bottom 600ms;
				width: 100%;

				h2 {
					// For/by header
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 48px;
					//transition: margin-bottom 600ms;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					flex-wrap: wrap;
					flex: 1 0 auto;
					column-gap: 24px;
					> div {
						white-space: nowrap;
						span {
							font-weight: 300;
							margin-right: 4px;
						}
						&:first-child {
							flex: 0 1 100px;
						}
						&:last-child {
							flex: 1 0 100px;
						}
					}
          @media (max-width: 436px) {
            display: block;
          }
				}
				&.without-logo {
					.logo-box {
						height: 0;
						width: 0;
						min-width: 0;
						transition: height 300ms linear 0ms, width 300ms linear 0ms, min-width 300ms linear 0ms;
						.logo-box-inner {
							opacity: 0;
							transition: opacity 300ms linear 0ms;
						}
					}
				}
				&.with-logo {
					margin-top: 24px;
					margin-bottom: -86px;
					//transition: margin-bottom 600ms;
					//border: 1px solid white;
					display: flex;
					align-items: flex-end;
					justify-content: flex-start;
					.logo-box {
						min-width: 120px;
						padding: 8px;
						height: 120px;
						transition: min-width 300ms linear 300ms;
						border-radius: 4px;
						border: 1px solid var(--v-gray_50-base);
						background-color: var(--v-white-base);
						margin-right: 12px;
						.logo-box-inner {
							height: 104px;
							//border: 1px solid gold;
							background-size: 90%;
							background-position: center;
							display: flex;
							justify-content: center;
							align-items: center;
							opacity: 1;
							transition: opacity 300ms linear 600ms;
							.account-logo {
								max-width: 240px;
								max-height: 104px;
							}
						}
					}
					h2 {
						margin-bottom: 24px;
						//transition: margin-bottom 300ms;
						color: var(--v-black-base);
					}
				}
				&.preview {
					&.with-logo {
						margin-top: 18px;
						margin-bottom: -60px;
						.logo-box {
							padding: 6px;
							min-width: 90px;
							height: 90px;
							.logo-box-inner {
								height: 78px;
								.account-logo {
									max-width: 180px;
									max-height: 78px;
								}
							}
						}
					}
					h2 {
						margin-bottom: 21px;
						> div {
							span {
								margin-right: 3px;
							}
							&:first-child {
								margin-right: 21px;
							}
						}
					}
				}
			}
			&.preview {
				padding: 0 12px 9px 36px;
				h1 {
					font-size: 40px;
					line-height: 40px;
					margin: 9px 0 0 !important;
				}
				h2 {
					font-size: 11px;
					line-height: 14px;
					margin-bottom: 24px;
				}
			}
			width: 100%;
			&.cover-light {
				background-image: linear-gradient(to top, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
			}
			&.cover-dark {
				background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
			}
		}

		.header-dark {
			color: white;
		}

		.header-light {
			color: black;
		}
	}
</style>
