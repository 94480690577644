var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fill-width" }, [
    _c(
      "table",
      {
        staticClass: "table",
        staticStyle: { "table-layout": "auto" },
        attrs: { id: "fields-table" }
      },
      [
        _vm._m(0),
        _vm.pipelineToDos && _vm.pipelineToDos.toDos.length
          ? _c(
              "draggable",
              {
                attrs: { group: "options", tag: "tbody", handle: ".drag" },
                on: { change: _vm.saveToDos },
                model: {
                  value: _vm.pipelineToDos.toDos,
                  callback: function($$v) {
                    _vm.$set(_vm.pipelineToDos, "toDos", $$v)
                  },
                  expression: "pipelineToDos.toDos"
                }
              },
              _vm._l(_vm.pipelineToDos.toDos, function(toDo) {
                return _c(
                  "tr",
                  { key: toDo.id, staticClass: "font-14 text-left" },
                  [
                    _c(
                      "td",
                      {
                        staticStyle: { width: "50px", "text-align": "center" }
                      },
                      [
                        _c("v-icon", { staticClass: "drag" }, [
                          _vm._v("drag_handle")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("v-text-field", {
                          attrs: {
                            placeholder: "ToDo",
                            "hide-details": "",
                            dense: "",
                            outlined: "",
                            "persistent-placeholder": ""
                          },
                          on: {
                            change: function($event) {
                              return _vm.saveToDos()
                            }
                          },
                          model: {
                            value: toDo.item,
                            callback: function($$v) {
                              _vm.$set(toDo, "item", $$v)
                            },
                            expression: "toDo.item"
                          }
                        })
                      ],
                      1
                    ),
                    _c("td", { staticStyle: { width: "250px" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "row-format gap-2",
                          staticStyle: { "max-width": "fit-content" }
                        },
                        [
                          _c("v-text-field", {
                            staticStyle: { "max-width": "70px" },
                            attrs: {
                              type: "number",
                              inputmode: "number",
                              placeholder: _vm.$t(
                                "templates.packages.deliverable-detail-modal.duration-placeholder",
                                {
                                  units: _vm.humanUnits(
                                    toDo.relativeDueDate.timeUnit
                                  )
                                }
                              ),
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": ""
                            },
                            on: {
                              keydown: function($event) {
                                return _vm.handleDurationKeydown($event)
                              },
                              change: function($event) {
                                return _vm.saveToDos()
                              }
                            },
                            model: {
                              value: toDo.relativeDueDate.duration,
                              callback: function($$v) {
                                _vm.$set(toDo.relativeDueDate, "duration", $$v)
                              },
                              expression: "toDo.relativeDueDate.duration"
                            }
                          }),
                          _c("v-select", {
                            staticStyle: { "max-width": "180px" },
                            attrs: {
                              placeholder: _vm.$t(
                                "templates.packages.deliverable-detail-modal.units-placeholder"
                              ),
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              clearable: "",
                              items: _vm.timeUnits,
                              "item-value": "value",
                              "item-text": "text"
                            },
                            on: {
                              change: function($event) {
                                return _vm.saveToDos()
                              }
                            },
                            model: {
                              value: toDo.relativeDueDate.timeUnit,
                              callback: function($$v) {
                                _vm.$set(toDo.relativeDueDate, "timeUnit", $$v)
                              },
                              expression: "toDo.relativeDueDate.timeUnit"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "row-format gap-2" },
                        [
                          _c("v-select", {
                            staticStyle: { width: "48%" },
                            attrs: {
                              placeholder: "Stage",
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              clearable: "",
                              items: _vm.stages,
                              "item-value": "id",
                              "item-text": "label"
                            },
                            on: {
                              change: function($event) {
                                return _vm.saveToDos()
                              }
                            },
                            model: {
                              value: toDo.scopeId,
                              callback: function($$v) {
                                _vm.$set(toDo, "scopeId", $$v)
                              },
                              expression: "toDo.scopeId"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "td",
                      {
                        staticStyle: { "text-align": "center", width: "50px" }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "material-symbols-rounded",
                            attrs: { size: "20" },
                            on: {
                              click: function($event) {
                                return _vm.confirmDelete(toDo)
                              }
                            }
                          },
                          [_vm._v("delete")]
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          : _c("tr", [
              _c(
                "td",
                {
                  staticClass: "pa-4 font-gray_70",
                  staticStyle: { "text-align": "center" },
                  attrs: { colspan: "3" }
                },
                [_vm._v("No default to-dos defined")]
              )
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { "text-align": "center", width: "50px" } }),
        _c("th", [_vm._v("To do")]),
        _c("th", { staticStyle: { width: "200px" } }, [
          _vm._v("Relative due date")
        ]),
        _c("th", [_vm._v("Add on stage")]),
        _c("th", { staticStyle: { "text-align": "center", width: "50px" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }