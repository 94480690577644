import HttpClient from "@/services/HttpClient";

export default class PipelineSettingsService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(null,true,2);
	}

	getPipelineStages() {
		return this.httpClient
			.get(`/pipeline/stages`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updatePipelineStages(stages) {
		return this.httpClient
			.put(`/pipeline/stages`,stages)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getPipelineToDos() {
		return this.httpClient
			.get(`/pipeline/toDos`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updatePipelineToDos(toDos) {
		return this.httpClient
			.put(`/pipeline/toDos`,toDos)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
