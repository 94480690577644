var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "email-or-download" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c("div", { staticClass: "modal-title" }, [
          _vm._v(" " + _vm._s(_vm.$t("invoice.send-method")) + " ")
        ]),
        _c(
          "div",
          {
            staticClass:
              "h-outline d-flex align-start justify-start py-3 px-2 mt-4",
            on: {
              click: function($event) {
                return _vm.sendEmail()
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "pr-3 pl-1" },
              [_c("v-icon", [_vm._v("$email")])],
              1
            ),
            _c("div", { staticClass: "text-left" }, [
              _c("div", { staticClass: "brand-medium gray_90--text" }, [
                _vm._v(_vm._s(_vm.$t("invoice.send-email")))
              ]),
              _c("div", { staticClass: "font-14 brand-semilight mt-1" }, [
                _vm._v(_vm._s(_vm.$t("invoice.send-email-desc")))
              ]),
              _c("div", { staticClass: "font-14 brand-medium mt-1" }, [
                _vm._v(_vm._s(_vm.$t("invoice.approve-email")))
              ])
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "h-outline d-flex align-start justify-start py-3 px-2 mt-4",
            on: {
              click: function($event) {
                return _vm.downloadInvoice()
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "pr-3 pl-1" },
              [_c("v-icon", [_vm._v("$pdfFile")])],
              1
            ),
            _c("div", { staticClass: "text-left" }, [
              _c("div", { staticClass: "brand-medium gray_90--text" }, [
                _vm._v(_vm._s(_vm.$t("invoice.download")))
              ]),
              _c("div", { staticClass: "font-14 brand-semilight mt-1" }, [
                _vm._v(_vm._s(_vm.$t("invoice.download-desc")))
              ])
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }