<template>
	<div style="width: 100%; height: 100%" class="column-format centered">
		<div class="brand-bold font-24">Hang tight, we are connecting your Quickbooks account.</div>
	</div>
</template>

<script>
	import QuickbooksService from "@/modules/integrations/quickbooks/QuickbooksService";

	export default {
		name: 'QuickbooksOAuthHandler',

		props: ['code','realmId'],

		components: {},

		data: function () {
			return {
				quickBooksService: new QuickbooksService(),
			};
		},

		mounted() {
			this.$store.commit('startLoading');
			this.connectQuickbooks();
		},

		beforeDestroy() {},

		methods: {
			connectQuickbooks: function () {
				let redirectUri = document.location.protocol + '//' + document.location.host + '/integrations/quickbooks/oauth';
				this.quickBooksService
					.exchangeToken(redirectUri,this.code,this.realmId)
					.then(() => {
						this.$store.commit('info', 'Quickbooks account linked.');
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', 'Error connecting your Quickbooks account: ' + err.message);
					})
					.finally(() => {
						this.$store.commit('stopLoading');
						this.$router.push('/apps?v=accounting');
					});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
