<template>
	<v-container fluid class="pa-0 ma-0 selector_signature" style="text-align: left">
		<v-row>
			<v-col cols="12">
				<span :style="headerStyle">
					<span v-html="lHeader" v-if="!editMode"></span>
					<editor
						ref="headerEditor"
						v-if="editMode"
						class="grey-dots"
						:api-key="$store.getters.getTinyMceKey"
						:initial-value="lHeader"
						:inline="true"
						:init="mceConfigHeader"
						:spellcheck="true"
						@input="handleHeaderInput"
					></editor>
				</span>
			</v-col>
		</v-row>

    <v-row :style="fontStyle">
      <v-col cols="12" sm="6" class="">
				<div style="display: flex">
				<span class="label-wrapper font-14"> {{ account ? account.accountName : templateAccountName }}</span>
				</div>
        <signature
            align="center"
            ref="us"
            :signature="us"
            @signed="signedUs"
            mode="us"
            :edit-mode="editMode"
            :client-mode="clientMode"
            :template-mode="false"
            :deliverable-selected="areDeliverablesSelected"
        ></signature>
          <div style="text-align: left" class="column-format">
            <div style="font-weight: bold">{{ us.signerName }}</div>
            <div>{{ us.signerTitle }}</div>
          </div>
          <div class="ipBlock" v-if="us.ip.ip">
            {{ $t('proposals.country') }}: <img :src="us.ip.countryFlag" width="15px" /><br />
            {{ $t('proposals.ip') }}: {{ us.ip.ip }}<br />
            {{ $t('proposals.location') }}: {{ us.ip.city }}, {{ us.ip.region }}, {{ us.ip.country }}<br />
          </div>
          <div v-if="us.dts" class="ipBlock">
            {{ DateTime.fromISO(us.dts).toLocaleString(DateTime.DATETIME_FULL) }}
          </div>
      </v-col>

      <v-col cols="12" sm="6"  class="">
        <div style="display: flex">
          <span class="label-wrapper truncate font-14">{{ client ? client.name : templateClientName }}</span>
          <div style="margin-left: auto" v-if="settingSigner === true">
            <v-icon small color="green" @click.native="saveSignerInfo()" style="cursor: pointer">$success</v-icon>
            <v-icon small color="grey" @click.native="cancelSignerInfo()" style="cursor: pointer">$cancel </v-icon>
          </div>
        </div>
        <signature
            align="center"
            ref="them"
            :signature="them"
            @signed="signedThem"
            @signer-info="signerInfoUpdated"
            @set-signer="settingSigner = true"
            @request-them-sign="requestThemSign"
            mode="them"
            :edit-mode="editMode"
            :client-mode="clientMode"
            :template-mode="templateMode"
            :deliverable-selected="areDeliverablesSelected"
        ></signature>
          <div style="text-align: left" class="column-format" v-if="!settingSigner && (themSignerName || themSignerTitle)">
            <div style="font-weight: bold">{{ this.them.signerName }}</div>
            <div>{{ this.them.signerTitle }}</div>
          </div>

          <div class="ipBlock" v-if="them.ip.ip">
            {{ $t('proposals.country') }} <img :src="them.ip.countryFlag" width="15px" /><br />
            {{ $t('proposals.ip') }}: {{ them.ip.ip }}<br />
            {{ $t('proposals.location') }}: {{ them.ip.city }}, {{ them.ip.region }}, {{ them.ip.country }}<br />
          </div>
          <div v-if="them.dts" class="ipBlock">
            {{ DateTime.fromISO(them.dts).toLocaleString(DateTime.DATETIME_FULL) }}
          </div>
          <v-btn v-if="cardPaymentFailed" class="my-3" large block color="primary" @click="requestThemSign">Resubmit Payment</v-btn>
      </v-col>
    </v-row>

	</v-container>
</template>

<script>
	import Signature from './Signature';
	import {DateTime} from 'luxon';

	export default {
		name: 'SignatureBlock',

		props: [
			'id',
			'header',
			'blockType',
			'editMode',
			'them',
			'us',
			'mceConfigHeader',
			'clientMode',
			'headerStyle',
			'formatting',
			'deliverableSelected',
      'forceAllDeliverables',
			'templateMode',
			'account',
			'client',
      'cardPaymentFailed',
      'isLocked',
		],

		components: {
			Signature,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function () {
			return {
				lHeader: this.header,
				// lUs: JSON.parse(JSON.stringify(this.us)),
				// lThem: JSON.parse(JSON.stringify(this.them)),
				lUs: this.us,
				lThem: this.them,
				settingSigner: false,
				templateAccountName: "{{MyBusinessName}}",
				templateClientName: "{{Client.Name}}",
				DateTime: DateTime,
			};
		},

		mounted() {},

		methods: {
			notifyChange: function () {
				this.$emit('changed', {
					id: this.id,
					blockType: this.blockType,
					header: this.lHeader,
					us: this.lUs,
					them: this.lThem,
				});
			},

      requestThemSign: function(){
        this.$emit('request-them-sign');
      },

      allowThemSign: function(){
        if(this.isLocked){
          this.notifyChange();
        }else{
          this.$refs.them.allowThemSign();
        }
      },

			saveSignerInfo: function () {
				this.settingSigner = false;
				this.$refs.them.saveSignerInfo();
			},

			cancelSignerInfo: function () {
				this.settingSigner = false;
				this.$refs.them.cancelSignerInfo();
			},

			signerInfoUpdated: function (signatureObj) {
				this.settingSigner = false;
				this.lThem = signatureObj;
				this.notifyChange();
			},

			handleHeaderInput: function () {
				this.lHeader = this.$refs.headerEditor.editor.getContent();
				this.notifyChange();
			},

			signedUs: function (signatureObj) {
				this.lUs = signatureObj;
				this.notifyChange();
			},

			signedThem: function (signatureObj) {
				this.lThem = signatureObj;
				this.notifyChange();
			},
		},

		computed: {
      areDeliverablesSelected: function(){
        return this.deliverableSelected || this.forceAllDeliverables;
      },

			fontStyle: function () {
				return `font-family: ${this.formatting.bodyFont}`;
			},

			themSignerName: function () {
				return !this.$validations.isEmpty(this.them.signerName);
			},

			themSignerTitle: function () {
				return !this.$validations.isEmpty(this.them.signerTitle);
			},
		},

		watch: {
			us: function (newVal) {
				this.lUs = newVal;
			},

			them: function (newVal) {
				this.lThem = newVal;
			},
		},
	};
</script>

<style scoped lang="scss">
	.ipBlock {
		text-align: left;
		color: darkgray;
		font-size: 0.7em;
	}
</style>

<style lang="scss">
	.selector_signature {
		.label-wrapper {
			.v-input {
				:not(.v-input--is-focussed) {
					&::before {
						border: none;
					}
				}
				.v-input__control .v-input__slot .v-text-field__slot {
					input {
						font-size: 16px !important;
					}
				}
			}
		}
	}
</style>
