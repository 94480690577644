<template>
	<div class="column-format text-left font-14 font-gray_70 py-3 gap-2">
		<div class="row-format gap-1">
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					min="0"
					max="20"
					v-model="item.pt"
					label="Padding top"
					@change="updated"
			></v-text-field>
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					min="0"
					max="20"
					v-model="item.pb"
					label="Padding bottom"
					@change="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					min="0"
					max="20"
					v-model="item.pl"
					label="Padding left"
					@change="updated"
			></v-text-field>
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					min="0"
					max="20"
					v-model="item.pr"
					label="Padding right"
					@change="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					v-model="item.borderWidth"
					label="Border width"
					@change="updated"
			></v-text-field>
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					v-model="item.padding"
					label="Inner padding"
					@change="updated"
			></v-text-field>
		</div>

		<div class="row-format text-left gap-1">
			<div style="flex: 1 1 0; width: 0;">
				<div>Background</div>
				<color-selector v-model="item.backgroundColor" @input="updated"></color-selector>
			</div>
			<div style="flex: 1 1 0; width: 0;">
				<div>Border</div>
				<color-selector v-model="item.borderColor" @input="updated"></color-selector>
			</div>
		</div>
		<hr class="my-3" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" />
		<div class="font-primary pointer font-14 text-center" @click="addPayment">+ Add payment event</div>
		<div
				v-for="scheduledPayment in item.scheduledPayments"
				:key="scheduledPayment.id"
				class="column-format gap-2 py-3"
				style="border-bottom: 1px solid var(--v-gray_30-base)"
		>
			<v-text-field
					v-model="scheduledPayment.label"
					hide-details
					dense
					outlined
					persistent-placeholder
					label="Label"
					:disabled="scheduledPayment.mileStoneAchieved"
					@change="updated"
			></v-text-field>
			<div class="row-format gap-2">
				<v-select
						:items="triggers"
						item-text="label"
						item-value="value"
						style="flex: 1 1 0"
						v-model="scheduledPayment.triggerType"
						hide-details
						dense
						persistent-placeholder
						outlined
						label="Payment trigger"
						:disabled="scheduledPayment.mileStoneAchieved"
						@change="updated"
				></v-select>
					<v-text-field
							v-if="scheduledPayment.triggerType === 'RelativeDate'"
							style="max-width: 60px"
							type="number"
							inputmode="number"
							hide-details
							dense
							outlined
							persistent-placeholder
							v-model="scheduledPayment.relativeTriggerDate.duration"
							@keydown="handleDurationKeydown($event)"
					/>
					<v-select
							v-if="scheduledPayment.triggerType === 'RelativeDate'"
							style="max-width: 110px"
							hide-details
							dense
							outlined
							persistent-placeholder
							:items="timeUnits"
							item-value="value"
							item-text="text"
							v-model="scheduledPayment.relativeTriggerDate.timeUnit"
					/>

				<date-selector
						v-if="scheduledPayment.triggerType === 'Date'"
						:standard="true"
						style="flex: 1 1 0"
						label="Date"
						:date="scheduledPayment.triggerDate"
						@change="scheduledPayment.triggerDate = $event; updated()"
						:disabled="scheduledPayment.mileStoneAchieved"
				></date-selector>
			</div>
			<div v-if="!scheduledPayment.finalPayment" class="column-format gap-2">
				<div class="row-format gap-2">
					<v-select
							:items="types"
							item-text="label"
							item-value="value"
							style="flex: 1 1 0"
							v-model="scheduledPayment.amountType"
							hide-details
							dense
							persistent-placeholder
							outlined
							label="Amount type"
							:disabled="scheduledPayment.mileStoneAchieved"
							@change="updated"
					></v-select>
					<v-text-field
							:prefix="scheduledPayment.amountType === 'Fixed' ? currencySymbol : ''"
							:suffix="scheduledPayment.amountType === 'Percentage' ? '%' : ''"
							numeric
							style="flex: 1 1 0"
							v-model="scheduledPayment.amount"
							hide-details
							dense
							persistent-placeholder
							outlined
							label="Amount"
							oninput="this.value=this.value.replace(/(\..*)\./g, '$1');"
							:disabled="scheduledPayment.mileStoneAchieved"
							@change="updated"
					></v-text-field>
				</div>
				<div class="text-left font-14 ">
					<div class="row-format">
						<div class="brand-medium">Amount:</div>
						<div class="ml-auto">
							{{ $formatters.dollars(scheduledPayment.calculatedAmount, true, true, agreement.currency) }}
							({{scheduledPayment.calculatedPercentage.toFixed(2)}}%)
						</div>
						<v-icon small class="pointer" @click="deletePayment(scheduledPayment)" v-if="!scheduledPayment.mileStoneAchieved">delete</v-icon>
					</div>
				</div>
			</div>
			<div v-else class="text-left font-14 row-format">
				<div class="brand-medium">Final payment:</div>
				<div class="ml-auto">
					{{ $formatters.dollars(scheduledPayment.calculatedAmount, true, true, agreement.currency) }}
					({{scheduledPayment.calculatedPercentage.toFixed(0)}}%)
				</div>
			</div>
		</div>
		<div>
			<v-icon size="20" color="primary">info</v-icon> Payment plans do not apply to monthly or annual recurring fee schedules.
		</div>
	</div>
</template>

<script>
	import ColorSelector from "@/components/ColorSelector";
	import {v4 as uuid4} from "uuid";
	import DateSelector from "@/components/DateSelector";

	export default {
		name: 'PaymentPlanConfig',

		props: ['agreement','item'],

		components: {ColorSelector, DateSelector},

		data: function() {
			return {
				colorMenu: false,
				types: [
					{ label: 'Percent', value: 'Percentage' },
					{ label: 'Fixed', value: 'Fixed' },
				],
				triggers: [
					{ label: 'Upon signature', value: 'Immediate' },
					{ label: 'Date', value: 'Date' },
					{ label: 'Relative date', value: 'RelativeDate'},
					{ label: 'Milestone', value: 'Milestone' },
				],
				timeUnits: [
					{ text: 'Days', value: 'DAYS' },
					{ text: 'Weeks', value: 'WEEKS' },
					{ text: 'Months', value: 'MONTHS' },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleDurationKeydown(event) {
				if (event.key === '.') {
					event.preventDefault();
				}
			},

			addPayment: function() {
				this.item.scheduledPayments.splice(0, 0, {
					id: uuid4(),
					label: 'Payment',
					triggerType: 'Milestone',
					triggerDate: null,
					amountType: 'Percentage',
					amount: 0,
					mileStoneAchieved: false,
					finalPayment: false,
					calculatedAmount: 0,
					calculatedPercentage: 0
				});
			},

			deletePayment: function(payment) {
				let ix = this.item.scheduledPayments.findIndex((p) => p.id === payment.id);
				this.item.scheduledPayments.splice(ix, 1);
			},

			updated: function() {
				this.$emit('change', this.item);
			},
		},

		watch: {
		},

		computed: {
			currencySymbol: function () {
				return this.$formatters.currencySymbol(this.agreement.currency);
			},
		},
	};
</script>

<style scoped lang="scss"></style>