import HttpClient from '@/services/HttpClient';

export default class ContactService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getContactList(includeOpportunities = false) {
		return this.httpClient.get(`/contacts?includeOpportunities=${includeOpportunities}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getContact(id) {
		return this.httpClient.get(`/contacts/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createContact(contact){
		return this.httpClient.post(`/contacts`,contact)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateContact(id,contact){
		return this.httpClient.put(`/contacts/${id}`,contact)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteContact(id){
		return this.httpClient.delete(`/contacts/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
