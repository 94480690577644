var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format gap-3 line-item-editor",
      style:
        "font-family: Inter!important; min-width: 300px; background-color: var(--v-white-base); width:" +
        _vm.menuWidth +
        "px;"
    },
    [
      _c(
        "div",
        { staticClass: "row-format px-4" },
        [
          _c(
            "v-icon",
            {
              staticClass: "ml-auto pointer pt-2",
              attrs: { color: "gray_70" },
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v("close")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "row-format gap-2 px-4" }, [
        _c(
          "div",
          { staticClass: "column-format", staticStyle: { width: "60%" } },
          [
            _c(
              "div",
              {
                staticClass: "column-format gap-2",
                staticStyle: { height: "fit-content", width: "100%" }
              },
              [
                _vm.showEditor
                  ? _c("editor", {
                      staticClass: "no-wrap-editor",
                      staticStyle: { width: "100%" },
                      attrs: { inline: false, init: _vm.mceConfig },
                      model: {
                        value: _vm.item.description,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "description", $$v)
                        },
                        expression: "item.description"
                      }
                    })
                  : _vm._e(),
                _c("project-select", {
                  staticClass: "mt-2 pointer",
                  attrs: {
                    clientId: _vm.invoice.clientId,
                    item: _vm.item,
                    "use-button": true,
                    editable: !_vm.projectDisabled
                  },
                  on: {
                    "select-project": function($event) {
                      return _vm.assignProjectToItem($event)
                    }
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "column-format", staticStyle: { width: "40%" } },
          [
            _c(
              "div",
              {
                staticClass: "column-format gap-2",
                staticStyle: { height: "fit-content", width: "100%" }
              },
              [
                _c("v-text-field", {
                  attrs: {
                    type: "number",
                    dense: "",
                    "hide-details": "",
                    outlined: "",
                    "persistent-placeholder": "",
                    label: "Quantity"
                  },
                  on: { change: _vm.qtyChanged },
                  model: {
                    value: _vm.item.qty,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "qty", $$v)
                    },
                    expression: "item.qty"
                  }
                }),
                _c("v-text-field", {
                  attrs: {
                    type: "number",
                    dense: "",
                    "hide-details": "",
                    outlined: "",
                    "persistent-placeholder": "",
                    prefix: _vm.$formatters.currencySymbol(
                      _vm.invoice.currency
                    ),
                    label: "Rate"
                  },
                  model: {
                    value: _vm.item.price,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "price", $$v)
                    },
                    expression: "item.price"
                  }
                }),
                _c("v-checkbox", {
                  staticClass: "mt-n2",
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    "persistent-placeholder": "",
                    label: "Taxable"
                  },
                  model: {
                    value: _vm.item.taxable,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "taxable", $$v)
                    },
                    expression: "item.taxable"
                  }
                })
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "row-format centered py-3 gap-2",
          staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("delete", _vm.lineItem)
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded",
                  attrs: { size: "22", color: "gray_80" }
                },
                [_vm._v("delete")]
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action",
              staticStyle: { "min-width": "150px" },
              on: { click: _vm.save }
            },
            [_vm._v(_vm._s(_vm.$t("global.ok")))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }