var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "plus-button",
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [_c("v-icon", { attrs: { color: "white", small: "" } }, [_vm._v("$plus")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }