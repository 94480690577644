<template>
	<div id="proposal-header-wrapper">
		<div class="proposal-header">
			<div class="header-left pl-md-6 pl-4">
				<div class="mr-6 brand-medium">
					<!--				<h-icon2 name="proposals" size="24" class="mr-4"></h-icon2>-->
					{{ $t('proposal.proposal') }}
				</div>

				<!--			<div @click="addContent()" class="row-format brand-semibold centered pointer ml-6" v-if="isEditable">-->
				<!--				<v-icon style="margin-top: 6px;" color="primary">$plusSmall</v-icon>-->
				<!--				<span style="color: var(&#45;&#45;v-primary-base);">{{ $t('proposal.nav.add-content') }}</span>-->
				<!--			</div>-->

				<div class="mr-2">
					<status-menu
						v-if="!clientMode"
						:proposal-id="proposal.id"
						:client-id="proposal.clientId"
						:status-items="proposal.events"
						:viewed-timestamps="proposal.viewedTimestamps"
					>
					</status-menu>
				</div>
				<div class="mr-2">
					<comments-menu
						:comments="proposal.comments"
						:proposal-id="proposal.id"
						:client-id="proposal.clientId"
						:proposal-service="proposalService"
						:client-mode="clientMode"
						:viewed-timestamps="proposal.viewedTimestamps"
					>
					</comments-menu>
				</div>
				<div class="mr-2">
					<pipeline-link-widget item-type="Proposal" :item-id="proposal.id" :client-id="proposal.clientId"></pipeline-link-widget>
				</div>
			</div>
			<div class="header-right pr-md-6 pr-4">
				<div v-if="$vuetify.breakpoint.mdAndUp && isEditable" class="nowrap">
					<v-progress-circular
						class="mr-2"
						v-if="needSave || saving"
						indeterminate
						size="20"
						:color="saving ? 'green' : 'accent'"
					></v-progress-circular>
					<v-icon small class="mr-2" v-if="!needSave && !saving">check</v-icon>
					<span class="progress mr-5">{{ status }}</span>
				</div>
				<v-btn class="ml-1 primary-action" width="96" outlined color="primary" @click="save()" v-if="isEditable">
					{{ $t('global.save') }}
				</v-btn>
				<v-btn class="mx-2 super-action" width="96" elevation="0" color="primary" @click="send()">
					{{ $t('global.share') }}
				</v-btn>

				<v-menu :close-on-click="true" nudge-bottom="30">
					<template v-slot:activator="scope">
						<div>
							<v-btn icon class="secondary-action" v-if="scope.value" v-on="scope.on">
								<v-icon small style="margin-right: 2px;">$arrowUp</v-icon>
							</v-btn>
							<v-btn icon class="secondary-action" v-if="!scope.value" v-on="scope.on">
								<v-icon small>$moreHorizontal</v-icon>
							</v-btn>
						</div>
					</template>

					<div class="more-menu">
						<div class="more-menu-item" v-if="!isEditable && canReturnToDraft"  @click="returnToDraft()">
							Return to draft
						</div>
						<div class="more-menu-item" v-if="!isEditable && canVoid"  @click="voidProposal()">
							Void proposal
						</div>
						<div class="more-menu-item" @click="$emit('delete')">
							{{ $t('global.delete') }}
						</div>
						<div class="more-menu-item" @click="$emit('create-project')" v-if="isFullyExecuted">
							<!--							<v-icon left>$projects</v-icon>-->
							{{ $t('proposal.header-bar.create-project') }}
						</div>
						<div class="more-menu-item" @click="$emit('create-template')" v-if="!clientMode">
							<!--							<v-icon left>$templates</v-icon>-->
							{{ $t('proposal.header-bar.create-template') }}
						</div>
					</div>
				</v-menu>
				<div class="ml-2">
					<v-btn icon class="primary-action" @click="handleClose()">
						<v-icon :size="20">$close</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CommentsMenu from './proposal-renderer/CommentsMenu';
	import StatusMenu from './proposal-renderer/StatusMenu';

	export default {
		name: 'HeaderBar',

		props: ['proposal', 'needSave', 'saving', 'status', 'clientMode', 'proposalService'],

		components: {PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget'), CommentsMenu, StatusMenu },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleClose: function() {
				this.$emit('close');
			},

			addContent: function() {
				this.$emit('add-content');
			},

			// textModal: function() {
			// 	this.$emit('text-modal');
			// },
			//
			// coverModal: function() {
			// 	this.$emit('cover-modal');
			// },

			// contentMenu: function() {
			// 	this.$emit('content-menu');
			// },

			save: function() {
				this.$emit('save');
			},

			send: function() {
				this.$emit('send');
			},

			returnToDraft: function() {
				this.$emit('return-to-draft');
			},

			voidProposal: function() {
				this.$emit('void');
			},
		},

		computed: {
			isEditable: function() {
				return this.proposal.proposalStatus === 'Draft';
			},

			canReturnToDraft: function() {
				if (this.proposal.proposalStatus === 'Sent' || this.proposal.proposalStatus === 'Declined' || this.proposal.proposalStatus === 'Void') {
					return true;
				} else {
					return false;
				}
			},

			canVoid: function() {
				if (this.proposal.proposalStatus === 'Sent' || this.proposal.proposalStatus === 'Declined') {
					return true;
				} else {
					return false;
				}
			},

			isFullyExecuted: function() {
				return this.proposal.proposalStatus === 'Fully Executed';
			},
		},
	};
</script>

<style lang="scss">
	#proposal-header-wrapper {
		// Needed for sticky positioning
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		z-index: 203;
		// end sticky

		.proposal-header {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);

			display: flex;
			justify-content: space-between;
			align-items: center;
			//border: 1px solid orange;
			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
				.locked-message {
					font-size: 12px;
					color: var(--v-gray_80-base);
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				//border: 1px solid red;
				& > div {
					flex: 0 0 auto;
					//border: 1px solid blue;
				}
			}
		}
	}

	.progress {
		font-size: 12px;
		color: var(--v-gray_50-base);
	}
</style>
