var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-4" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { md: "9", cols: "3" } }, [_vm._v(" ")]),
              _c(
                "v-col",
                { attrs: { md: "3", cols: "9", align: "right" } },
                [
                  _c("v-select", {
                    attrs: {
                      "hide-details": true,
                      outlined: "",
                      dense: "",
                      label: "Input type",
                      items: _vm.componentTypes,
                      "item-text": "label",
                      "item-value": "type"
                    },
                    on: {
                      change: function($event) {
                        return _vm.notifyChange()
                      }
                    },
                    model: {
                      value: _vm.ourFieldType,
                      callback: function($$v) {
                        _vm.ourFieldType = $$v
                      },
                      expression: "ourFieldType"
                    }
                  })
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "span",
                  { staticClass: "question" },
                  [
                    _c("editor", {
                      ref: "editor",
                      staticStyle: {
                        border: "1px dotted var(--v-grey-lighten3)"
                      },
                      attrs: {
                        "api-key": _vm.$store.getters.getTinyMceKey,
                        "initial-value": _vm.htmlText,
                        inline: true,
                        init: _vm.mceConfigText,
                        placeholder: _vm.placeHolder,
                        spellcheck: true
                      },
                      on: { input: _vm.handleChange }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "draggable",
            {
              attrs: { group: "options", handle: ".nestedHandle" },
              on: {
                end: function($event) {
                  return _vm.notifyChange()
                }
              },
              model: {
                value: _vm.ourOptions,
                callback: function($$v) {
                  _vm.ourOptions = $$v
                },
                expression: "ourOptions"
              }
            },
            _vm._l(_vm.ourOptions, function(option, index) {
              return _c(
                "v-row",
                { key: option.id, attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "my-2",
                      staticStyle: { "white-space": "nowrap" },
                      attrs: { cols: "11" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row-format" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "nestedHandle mt-1",
                              staticStyle: { cursor: "pointer" }
                            },
                            [_vm._v(" $dragSecondary ")]
                          ),
                          _vm.icon
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "ml-4",
                                  attrs: { color: "grey" }
                                },
                                [_vm._v(_vm._s(_vm.icon))]
                              )
                            : _vm._e(),
                          _c("v-text-field", {
                            staticClass: "ml-2",
                            attrs: {
                              value: option.option,
                              dense: true,
                              "hide-details": true
                            },
                            on: {
                              change: function($event) {
                                return _vm.handleOptionChange(index, {
                                  id: option.id,
                                  option: $event
                                })
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "1", align: "right" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pointer",
                          attrs: { small: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.removeOption(index)
                            }
                          }
                        },
                        [_vm._v("$close")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _vm.ourHasOther && _vm.ourFieldType !== "SelectInput"
            ? _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "my-2",
                      staticStyle: { "white-space": "nowrap" },
                      attrs: { cols: "11" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row-format" },
                        [
                          _vm.icon
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "ml-10",
                                  attrs: { color: "grey" }
                                },
                                [_vm._v(_vm._s(_vm.icon))]
                              )
                            : _vm._e(),
                          _c("v-text-field", {
                            staticClass: "ml-2",
                            attrs: {
                              placeholder: "Other",
                              dense: true,
                              "hide-details": true,
                              disabled: true
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "1", align: "right" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "pointer",
                          attrs: { small: "" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.removeOther()
                            }
                          }
                        },
                        [_vm._v("$close")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "my-2", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "row-format" },
                  [
                    _vm.icon
                      ? _c("v-icon", { staticClass: "ml-10" }, [
                          _vm._v(_vm._s(_vm.icon))
                        ])
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "brand-regular pa-0 ml-2",
                        attrs: { text: "", color: "grey" },
                        on: {
                          click: function($event) {
                            return _vm.addOption()
                          }
                        }
                      },
                      [_vm._v("Add option")]
                    ),
                    _vm.ourFieldType !== "SelectInput" && !_vm.ourHasOther
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "brand-regular pa-0 ml-1",
                            attrs: { text: "", color: "grey" },
                            on: {
                              click: function($event) {
                                return _vm.addOther()
                              }
                            }
                          },
                          [_vm._v('or Add "Other"')]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12", align: "right" } }, [
                _c(
                  "div",
                  {
                    staticClass: "mt-2 row-format gap-2",
                    staticStyle: { width: "fit-content" }
                  },
                  [
                    _c("v-text-field", {
                      staticStyle: { width: "150px" },
                      attrs: {
                        outlined: "",
                        "persistent-placeholder": "",
                        dense: "",
                        "hide-details": "",
                        label: "Field id"
                      },
                      on: {
                        change: function($event) {
                          return _vm.handleChange()
                        }
                      },
                      model: {
                        value: _vm.ourFieldKey,
                        callback: function($$v) {
                          _vm.ourFieldKey = $$v
                        },
                        expression: "ourFieldKey"
                      }
                    }),
                    _c("v-select", {
                      staticStyle: { width: "95px" },
                      attrs: {
                        items: _vm.requiredOptions,
                        "item-text": "label",
                        "item-value": "value",
                        outlined: "",
                        "persistent-placeholder": "",
                        dense: "",
                        "hide-details": "",
                        label: "Required"
                      },
                      on: {
                        change: function($event) {
                          return _vm.notifyChange()
                        }
                      },
                      model: {
                        value: _vm.ourRequired,
                        callback: function($$v) {
                          _vm.ourRequired = $$v
                        },
                        expression: "ourRequired"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }