<template>
	<div>
		<div class="row-format gap-2 justify-end">
			<v-btn class="primary-action" @click="selectProject">
				<span class="font-secondary">Link task</span>
				<v-icon small class="ml-1" color="secondary">add</v-icon>
			</v-btn>
		</div>

		<div class="column-format gap-2 mt-2">
			<deliverable-mini class="pa-1" style="border: 1px solid var(--v-gray_30-base); border-radius: 4px" :hide-icon="true" v-for="deliverable in deliverables" :key="deliverable.id" :deliverable="deliverable" @deliverable-clicked="openDeliverable(deliverable)"></deliverable-mini>
		</div>

		<div v-if="deliverables.length === 0">
			<div class="text-center font-gray_70">No tasks linked</div>
		</div>
	</div>
</template>

<script>
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	import ProjectSelector from '@/modules/projects/ProjectSelector';
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';
	import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';
	import DeliverableMini from "@/modules/productivity/work/DeliverableMini";
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'LinkedTasks',

		props: ['ticket'],

		components: {DeliverableMini},

		data: function() {
			return {
				projectDeliverableService: new ProjectDeliverableService(),
				ticketService: new CommunicatorTicketService(),
				deliverables: [],
			};
		},

		mounted() {
			this.getLinkedDeliverables();
		},

		beforeDestroy() {},

		methods: {
			getLinkedDeliverables: function(){
				this.projectDeliverableService.getDeliverablesForTicket(this.ticket.id).then((res) => {
					this.deliverables.splice(0);
					this.deliverables.push(... res.data);
					this.deliverables.forEach((d) => {
						this.fixupDeliverable(d);
					})
				})
			},

			fixupDeliverable(d){
				if (d.dueDate) {
					d.luxonDate = DateTime.fromISO(d.dueDate);
				}
				d.status = this.$store.getters.getDeliverableStatusById(d.statusId);
			},

			selectProject: function() {
				let binding = {
					clientId: this.ticket.clientId,
				};
				this.$store.state.globalModalController.openModal(ProjectSelector, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.ticketService
							.createDeliverableFromTicket(this.ticket.id, res.project.id)
							.then((res) => {
								this.fixupDeliverable(res.data);
								this.deliverables.push(res.data);
								this.openDeliverable(res.data);
							})
							.catch((err) => this.$store.commit('error', err.response.data.message))
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			openDeliverable: function(deliverable) {
				let binding = {
					deliverableId: deliverable.id,
					client: deliverable.client,
					project: deliverable.project,
				};
				this.$store.state.globalModalController.openModal(DeliverableDetail, binding, true, false).then((res) => {
					if(res) {
						let ix = this.deliverables.findIndex((d) => d.id === res.id);
						if (ix > -1) {
							if (res.action === 'DELETED' || res.ticketId !== this.ticket.id) {
								this.deliverables.splice(ix, 1);
							} else {
								this.fixupDeliverable(res);
								this.deliverables.splice(ix, 1, res);
							}
						}
					}
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
