var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: "text-align: " + _vm.alignment,
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "elevation-0",
          style: "font-size: var(--form-font-size) !important;",
          attrs: {
            color: _vm.item.color,
            "x-small": _vm.buttonSettings.xSmall,
            small: _vm.buttonSettings.small,
            large: _vm.buttonSettings.large,
            "x-large": _vm.buttonSettings.xLarge,
            outlined: _vm.buttonSettings.outlined,
            block: _vm.buttonSettings.block,
            text: _vm.buttonSettings.text
          },
          on: { click: _vm.handleClick }
        },
        [
          _c("span", { style: "color: " + _vm.item.textColor }, [
            _vm._v(_vm._s(_vm.item.text))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }