<template>
	<div>
		<div v-for="(item,index) in schema" :key="item.id">
			<item-renderer
					ref="item"
					:index="index"
					:item="item"
					:styles="styles"
					:disabled="true"
					:account-logo="accountLogo"
					:active="false"
			></item-renderer>
		</div>
	</div>
</template>

<script>
import ItemRenderer from "@/modules/forms/v2/schema/ItemRenderer";

export default {
	name: "FormRendererV2",

	props: ['template','meet'],

	components: {ItemRenderer},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {
		styles: function () {
			return this.template.formSettingsV2;
		},

		schema: function(){
			return this.template.schemaV2.filter(s => s.type !== 'NewPage');
		},

		accountLogo: function () {
			if (this.$vuetify.theme.dark && this.$store.state.podAccount.accountLogoDark) {
				return this.$store.state.podAccount.accountLogoDark;
			} else {
				return this.$store.state.podAccount.accountLogo;
			}
		},

		justification: function() {
			if (this.styles.submitAlignment === 'JUSTIFIED') {
				return 'space-between';
			} else if (this.styles.submitAlignment === 'LEFT') {
				return 'flex-start';
			} else if (this.styles.submitAlignment === 'RIGHT') {
				return 'flex-end';
			} else {
				return 'center';
			}
		},
	},

}
</script>

<style scoped lang="scss">

</style>