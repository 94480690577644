var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        {
          class:
            "mx-8 mx-sm-16 mb-6 " + (_vm.$vuetify.breakpoint.xs ? "mt-6" : "")
        },
        [
          _vm.screen === "questions"
            ? _c(
                "v-form",
                {
                  ref: "questionForm",
                  attrs: { id: "question", "lazy-validation": "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitQuestion()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {},
                    [
                      _c("h1", { staticClass: "mb-3" }, [
                        _vm._v(
                          _vm._s(_vm.$t("subscription.cancel.feedback.heading"))
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "justify-center mb-8 px-4 mx-4" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "subscription.cancel.feedback.subheading"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-8",
                          attrs: { color: "accent", large: "", block: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("subscription.cancel.cancel-button")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            hint: "Please make a selection",
                            rules: _vm.rules
                          },
                          model: {
                            value: _vm.reason,
                            callback: function($$v) {
                              _vm.reason = $$v
                            },
                            expression: "reason"
                          }
                        },
                        _vm._l(
                          _vm.$t("subscription.cancel.feedback.questions"),
                          function(question, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "mt-2 mb-3" },
                              [
                                _c("v-radio", {
                                  staticClass: "custom",
                                  attrs: { value: question, label: question }
                                }),
                                [1, 2, 3, 4, 5, 7].includes(i) &&
                                _vm.reason === question
                                  ? _c(
                                      "div",
                                      { staticClass: "ma-0" },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$t(
                                              "subscription.cancel.feedback.comment-label"
                                            )
                                          },
                                          model: {
                                            value: _vm.comments,
                                            callback: function($$v) {
                                              _vm.comments = $$v
                                            },
                                            expression: "comments"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            type: "submit",
                            large: "",
                            color: "primary",
                            block: ""
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("subscription.cancel.feedback.button")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm.screen === "improvement"
            ? _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitImprovement()
                    }
                  }
                },
                [
                  _c("h1", { staticClass: "mb-3" }, [
                    _vm._v(
                      _vm._s(_vm.$t("subscription.cancel.feedback.heading"))
                    )
                  ]),
                  _c("div", { staticClass: "justify-center mb-12 px-2 mx-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("subscription.cancel.feedback.subheading2")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "mb-12" },
                    [
                      _c("h3", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("subscription.cancel.feedback.improvement")
                            ) +
                            " "
                        )
                      ]),
                      _c("v-text-field", {
                        model: {
                          value: _vm.improve,
                          callback: function($$v) {
                            _vm.improve = $$v
                          },
                          expression: "improve"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-4",
                      attrs: { color: "accent", large: "", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("subscription.cancel.cancel-button")) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        type: "submit",
                        large: "",
                        color: "primary",
                        block: ""
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("subscription.cancel.feedback.button")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.screen === "confirm"
            ? _c(
                "v-form",
                {
                  attrs: { id: "confirm" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.downgradeFeedback()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("h1", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("subscription.cancel.confirm.heading", {
                              "old-plan-name": _vm.oldPlan["name"],
                              "days-to-expiry": _vm.daysToExpiry,
                              "new-price": _vm.newPlan["price"],
                              "old-price": _vm.oldPlan["price"]
                            })
                          )
                        }
                      }),
                      _vm._l(
                        _vm.$t("subscription.cancel.confirm.subheading", {
                          "old-plan-name": _vm.oldPlan["name"],
                          "days-to-expiry": _vm.daysToExpiry,
                          "new-price": _vm.newPlan["price"],
                          "old-price": _vm.oldPlan["price"]
                        }),
                        function(subheading, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "mt-4 mb-3" },
                            [_vm._v(" " + _vm._s(subheading) + " ")]
                          )
                        }
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-12",
                          attrs: { block: "", large: "", color: "accent" },
                          on: {
                            click: function($event) {
                              _vm.screen = ""
                              _vm.$emit("close")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("subscription.cancel.cancel-button")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            type: "submit",
                            block: "",
                            large: "",
                            color: "primary"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("subscription.cancel.confirm.button"))
                          )
                        ]
                      )
                    ],
                    2
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }