var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pa-6" }, [
    _c(
      "div",
      { staticClass: "row-format align-center mb-3" },
      [
        _c("div", { staticClass: "brand-medium font-16" }, [
          _vm._v(_vm._s(_vm.$t("accounting.payments.apply-to")))
        ]),
        _c(
          "v-icon",
          {
            staticClass: "ml-auto",
            attrs: { size: "20" },
            on: {
              click: function($event) {
                return _vm.$emit("result")
              }
            }
          },
          [_vm._v("$close")]
        )
      ],
      1
    ),
    _c("div", { attrs: { id: "select-type" } }, [
      _c(
        "div",
        {
          staticClass: "pointer row-format button-box mb-2 pa-4",
          on: {
            click: function($event) {
              return _vm.$emit("result", "apply-to-invoice")
            }
          }
        },
        [
          _c(
            "v-icon",
            {
              staticStyle: { "align-self": "flex-start" },
              attrs: { color: "gray_80" }
            },
            [_vm._v("$invoices")]
          ),
          _c("div", { staticClass: "text-left pl-4" }, [
            _c("div", { staticClass: "brand-medium font-16" }, [
              _vm._v(_vm._s(_vm.$t("accounting.payments.invoice")))
            ]),
            _c("div", { staticClass: "font-14 font-gray_80 mt-2" }, [
              _vm._v(_vm._s(_vm.$t("accounting.payments.invoice-desc")))
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "pointer row-format button-box pa-4",
          on: {
            click: function($event) {
              return _vm.$emit("result", "other-income")
            }
          }
        },
        [
          _c(
            "v-icon",
            {
              staticStyle: { "align-self": "flex-start" },
              attrs: { color: "gray_80" }
            },
            [_vm._v("$money")]
          ),
          _c("div", { staticClass: "text-left pl-4" }, [
            _c("div", { staticClass: "brand-medium font-16" }, [
              _vm._v(_vm._s(_vm.$t("accounting.payments.other")))
            ]),
            _c("div", { staticClass: "font-14 font-gray_80 mt-2" }, [
              _vm._v(_vm._s(_vm.$t("accounting.payments.other-desc")))
            ])
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }