var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.account
    ? _c(
        "div",
        { attrs: { id: "invoice-number-format-modal" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-padding" },
            [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("invoice.invoice-number-format-modal.heading")
                    ) +
                    " "
                )
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "brand-medium font-12 gray_90--text mt-1 text-left mt-1"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("invoice.invoice-number-format-modal.formats")
                      ) +
                      " "
                  )
                ]
              ),
              _c("v-select", {
                key: _vm.formatSelectKey,
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  label: _vm.$t(
                    "invoice.invoice-number-format-modal.format-label"
                  ),
                  items: _vm.formats,
                  "item-text": "label",
                  "item-value": "value",
                  "menu-props": { maxHeight: 600 }
                },
                on: {
                  change: function($event) {
                    return _vm.handleFormatSelectChange()
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "d-flex justify-start",
                              staticStyle: { width: "100%" }
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1780309328
                ),
                model: {
                  value: _vm.account.accountPreferences.invoiceNumberFormat,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.account.accountPreferences,
                      "invoiceNumberFormat",
                      $$v
                    )
                  },
                  expression: "account.accountPreferences.invoiceNumberFormat"
                }
              }),
              !_vm.isPresetFormat(
                _vm.account.accountPreferences.invoiceNumberFormat
              )
                ? _c(
                    "v-btn",
                    {
                      staticClass: "secondary-action",
                      attrs: { text: "" },
                      on: { click: _vm.openCustomFormatModal }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.account.accountPreferences.invoiceNumberFormat
                          ) +
                          " "
                      ),
                      _c("v-icon", { attrs: { right: "", small: "" } }, [
                        _vm._v("$edit")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "brand-medium font-12 gray_90--text mt-1 text-left mt-1"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("invoice.invoice-number-format-modal.settings")
                      ) +
                      " "
                  )
                ]
              ),
              _c("v-text-field", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  type: "number",
                  label: _vm.$t(
                    "invoice.invoice-number-format-modal.seed-label"
                  )
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSave.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.account.accountPreferences.invoiceNumberSeed,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.account.accountPreferences,
                      "invoiceNumberSeed",
                      $$v
                    )
                  },
                  expression: "account.accountPreferences.invoiceNumberSeed"
                }
              }),
              _c("v-select", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  label: _vm.$t(
                    "invoice.invoice-number-format-modal.per-client-label"
                  ),
                  items: _vm.perClientOptions,
                  "item-text": "label",
                  "item-value": "value"
                },
                model: {
                  value: _vm.account.accountPreferences.invoiceNumberPerClient,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.account.accountPreferences,
                      "invoiceNumberPerClient",
                      $$v
                    )
                  },
                  expression:
                    "account.accountPreferences.invoiceNumberPerClient"
                }
              }),
              _c("v-select", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  label: _vm.$t(
                    "invoice.invoice-number-format-modal.per-year-label"
                  ),
                  items: _vm.perClientOptions,
                  "item-text": "label",
                  "item-value": "value"
                },
                model: {
                  value: _vm.account.accountPreferences.invoiceNumberPerYear,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.account.accountPreferences,
                      "invoiceNumberPerYear",
                      $$v
                    )
                  },
                  expression: "account.accountPreferences.invoiceNumberPerYear"
                }
              }),
              _c("v-select", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  label: "Fiscal year start",
                  items: _vm.months,
                  "item-text": "text",
                  "item-value": "value"
                },
                model: {
                  value: _vm.account.accountPreferences.fiscalYearStartMonth,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.account.accountPreferences,
                      "fiscalYearStartMonth",
                      $$v
                    )
                  },
                  expression: "account.accountPreferences.fiscalYearStartMonth"
                }
              }),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", small: "" },
                      on: { click: _vm.confirmResetInvoiceSequence }
                    },
                    [
                      _c("v-icon", [_vm._v("warning")]),
                      _vm._v("Reset invoice sequence")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  staticStyle: { width: "160px" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("global.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "super-action ml-1",
                  staticStyle: { width: "160px" },
                  on: { click: _vm.handleSave }
                },
                [_vm._v(_vm._s(_vm.$t("global.save")))]
              )
            ],
            1
          ),
          _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.customFormatDialog, persistent: false },
              on: {
                close: function($event) {
                  _vm.customFormatDialog = false
                }
              }
            },
            [
              _vm.customFormatDialog
                ? _c("invoice-number-custom-format", {
                    attrs: {
                      "invoice-number-format":
                        _vm.account.accountPreferences.invoiceNumberFormat
                    },
                    on: {
                      close: function($event) {
                        return _vm.handleCustomFormatSave()
                      },
                      save: function($event) {
                        return _vm.handleCustomFormatSave($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }