var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 pa-0" },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _vm.$store.getters.hasInvoiceAccess
            ? _c("v-col", { staticClass: "px-2", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "insight-box pointer fill-height column-format",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("money-clicked")
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row-format header-row py-3" }, [
                      _c(
                        "div",
                        { staticClass: "col col-12 text-left font-gray_80" },
                        [
                          _c("div", { staticClass: "font-14 font-gray_70" }, [
                            _vm._v("Payment received")
                          ]),
                          _c("div", { staticClass: "font-gray_90" }, [
                            _c(
                              "span",
                              { staticClass: "font-24 brand-medium truncate" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatters.dollars(
                                      _vm.totalReceived,
                                      false,
                                      true,
                                      _vm.client.currency
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(
                              " / " +
                                _vm._s(
                                  _vm.$formatters.dollars(
                                    _vm.outstanding,
                                    false,
                                    true,
                                    _vm.client.currency
                                  )
                                ) +
                                " outstanding"
                            )
                          ])
                        ]
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "row-format mt-auto",
                        staticStyle: {
                          "border-top": "1px solid var(--v-gray_30-base)"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col col-12 py-2" },
                          [
                            _c("invoicing-chart", {
                              attrs: {
                                client: _vm.client,
                                currency: _vm.client.currency
                              },
                              on: {
                                "total-invoiced": function($event) {
                                  _vm.totalInvoiced = $event
                                },
                                "total-received": function($event) {
                                  _vm.totalReceived = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _c("v-col", { staticClass: "px-2 mt-2", attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                staticClass: "insight-box pointer fill-height column-format",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("time-clicked")
                  }
                }
              },
              [
                _c("div", { staticClass: "row-format header-row py-3" }, [
                  _c(
                    "div",
                    { staticClass: "col col-12 text-left font-gray_80" },
                    [
                      _c("div", { staticClass: "font-14 font-gray_70" }, [
                        _vm._v("Total hours worked")
                      ]),
                      _c("div", { staticClass: "font-gray_90" }, [
                        _c(
                          "span",
                          { staticClass: "font-24 brand-medium truncate" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$formatters.formatSeconds(_vm.totalHours)
                              )
                            )
                          ]
                        ),
                        _vm._v(
                          " / " +
                            _vm._s(
                              _vm.$formatters.formatSeconds(_vm.thisMonthHours)
                            ) +
                            " this month"
                        )
                      ])
                    ]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "row-format mt-auto",
                    staticStyle: {
                      "border-top": "1px solid var(--v-gray_30-base)"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col col-12 py-2" },
                      [
                        _c("time-tracking-chart", {
                          attrs: { client: _vm.client },
                          on: {
                            "total-hours": function($event) {
                              _vm.totalHours = $event
                            },
                            "this-month": function($event) {
                              _vm.thisMonthHours = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }