var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Tickets" }
            ]
          }
        },
        [_vm._v(" Ticket list ")]
      ),
      _c("ticket-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }