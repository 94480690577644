<template>
	<div class="column-format gap-5">
	</div>
</template>

<script>
export default {
	name: "NewPageConfig",

	props: ['item'],

	components: {},

	data: function () {
		return {
			element: {... this.item},
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		updated: function(){
			this.$emit('change',this.element);
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>