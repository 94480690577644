var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        (_vm.route ? "pointer" : "") +
        " row-format " +
        (_vm.expanded ? "align-center" : "align-center") +
        "  mx-2 " +
        (_vm.active ? "nav-link-active" : "") +
        " nav-link" +
        (_vm.expanded ? "-expanded" : ""),
      on: {
        click: function($event) {
          _vm.more ? _vm.$emit("click", $event) : _vm.handleClick($event)
        }
      }
    },
    [
      _vm.expanded
        ? _c(
            "div",
            {
              staticClass: "column-format",
              staticStyle: { "padding-left": "12px", width: "100%" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "row-format align-center gap-2 pr-2 hover-link",
                  staticStyle: { width: "100%" }
                },
                [
                  _c(
                    "v-icon",
                    {
                      class: !_vm.active
                        ? "material-symbols-rounded"
                        : "material-symbols-rounded",
                      attrs: {
                        color:
                          _vm.active && !_vm.showChildren
                            ? "primary"
                            : "gray_90",
                        size: "18"
                      }
                    },
                    [_vm._v(_vm._s(_vm.icon))]
                  ),
                  _c(
                    "div",
                    {
                      class:
                        "nav-label  " +
                        (_vm.active && !_vm.showChildren ? "active" : ""),
                      staticStyle: { "font-weight": "500" }
                    },
                    [_vm._v(_vm._s(_vm.label))]
                  ),
                  _vm.children && _vm.children.length
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "ml-auto material-symbols-rounded",
                          attrs: { size: "16", color: "gray_40" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.showChildren
                                ? "keyboard_arrow_up"
                                : "keyboard_arrow_down"
                            )
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.more
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "ml-auto material-symbols-rounded",
                          attrs: { size: "16", color: "gray_40" }
                        },
                        [_vm._v("chevron_right")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.children && _vm.children.length && _vm.showChildren
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-left font-gray_90 column-format pt-1 mt-2",
                      staticStyle: {
                        "font-size": "13px",
                        "padding-left": "18px",
                        "margin-left": "8px",
                        "border-left": "1px solid var(--v-gray_30-base)"
                      }
                    },
                    [
                      _c(
                        "draggable",
                        {
                          attrs: { disabled: !_vm.isQuickLink },
                          on: { end: _vm.endDragHandler },
                          model: {
                            value: _vm.children,
                            callback: function($$v) {
                              _vm.children = $$v
                            },
                            expression: "children"
                          }
                        },
                        _vm._l(_vm.children, function(child, index) {
                          return _c(
                            "div",
                            {
                              key: child.uri,
                              staticClass: "row-format align-center gap-2",
                              class:
                                (index < _vm.children.length - 1
                                  ? "pb-2"
                                  : "") +
                                " child-label drag " +
                                (child.uri === _vm.path && !_vm.isQuickLink
                                  ? "active"
                                  : ""),
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.childClick($event, child)
                                }
                              }
                            },
                            [
                              child.client
                                ? _c("client-avatar", {
                                    attrs: { client: child.client, micro: true }
                                  })
                                : _vm._e(),
                              _c("div", [_vm._v(_vm._s(child.label))])
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: {
                    placement: "right",
                    arrow: true,
                    arrowType: "round",
                    animation: "fade",
                    delay: 50,
                    boundary: "window"
                  },
                  expression:
                    "{ placement: 'right', arrow: true, arrowType: 'round', animation: 'fade', delay: 50, boundary: 'window' }"
                }
              ],
              staticClass: "row-format centered",
              staticStyle: { "padding-left": "12px" },
              attrs: { content: _vm.label }
            },
            [
              _c(
                "v-icon",
                {
                  class: !_vm.active
                    ? "material-symbols-rounded"
                    : "material-symbols-rounded",
                  attrs: {
                    color: _vm.active ? "primary" : "gray_90",
                    size: "18"
                  }
                },
                [_vm._v(_vm._s(_vm.icon))]
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }