<template>
	<div>
		<div class="row-format mb-3">
			<div class="numberWidget mr-3 column-format clickable" @click="$emit('clicked','hours')">
				<div class="header">Hours worked</div>
				<div class="body">{{$formatters.formatSecondsLong(totalSecondsWorked)}}</div>
			</div>
			<div class="numberWidget">
				<div class="header">Avg hours per {{scope}}</div>
				<div class="body">{{$formatters.formatSecondsLong(averageSecondsPerScope)}}</div>
			</div>
		</div>
		<div class="row-format">
			<div class="numberWidget mr-3 column-format clickable" @click="$emit('clicked','un-billed')">
				<div class="header">Un-billed Hours</div>
				<div class="body">{{$formatters.formatSecondsLong(totalSecondsUnBilled)}}</div>
			</div>
			<div class="numberWidget" v-if="$store.getters.hasInvoiceAccess">
				<div class="header row-format align-center">
					<div>True hourly rate</div>
				</div>
				<div class="body">{{$formatters.dollars(trueHourlyRate)}}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TimeMetrics",

	props: ['scope','earliest','latest','clients','invoices','payments','expenses','timeWorked','otherIncome'],

	components: {},

	data: function () {
		return {
			clientHover: null,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		setClientHover: function(name,total){
			this.clientHover = name + ' ' + this.$formatters.formatSecondsLong(total);
		},

		clearClientHover: function(){
			this.clientHover = null;
		},

		groupBy: function(array, key) {
			return array.reduce((result, currentValue) => {
				(result[currentValue[key]] = result[currentValue[key]] || []).push(
						currentValue
				);
				return result;
			}, {});
		}
	},

	computed: {
		totalInvoiced: function () {
			return this.invoices.reduce((sum, invoice) => sum + invoice.localTotal, 0);
		},

		totalOtherIncome: function(){
			let total = this.otherIncome.reduce((sum,income) => sum + income.localAmount,0);
			return total;
		},

		totalReceived: function () {
			return this.payments.reduce((sum, invoice) => sum + invoice.localAmount, 0);
		},

		totalExpenses: function () {
			return this.expenses.reduce((sum, expense) => sum + expense.localAmount, 0);
		},

		totalSecondsWorked: function(){
			return this.timeWorked.reduce((sum,time) => sum + time.duration,0);
		},

		totalSecondsUnBilled: function(){
			return this.unBilledTime.reduce((sum,time) => sum + time.duration,0);
		},

		netIncome: function () {
			return (this.totalReceived + this.totalOtherIncome) - this.totalExpenses;
		},

		unBilledTime: function(){
			return this.timeWorked.filter(t => (t.billable && (t.projectId == null || (t.feeSchedule && t.feeSchedule.feeType === 'Hourly')) && !t.invoiceId));
		},

		timeWorkedByClient: function(){
			let grouped = this.groupBy(this.timeWorked,'clientId');
			let result = []

			for(let g in grouped){
				let group = grouped[g];
				let clientTotal = group.reduce((sum, time) => sum + time.duration, 0);
				let client = this.clients.find(c => c.id === g)

				if(!client){
					client = {
						name: 'No client',
						id: '0',
						color: '#ffffff',
					}
				}

				result.push({
					clientId: g,
					clientName: client.name,
					color: client.color,
					total: clientTotal.toFixed(2),
					percentage: ((clientTotal/this.totalSecondsWorked)*100).toFixed(),
				});
			}
			result.sort((a,b) => a.total - b.total);
			return result;
		},

		averageSecondsPerScope: function(){
			let divisor;

			if(this.scope === 'day'){
				divisor = this.latest.diff(this.earliest,["days"]).days;
			}else if(this.scope === 'week'){
				divisor = this.latest.diff(this.earliest,["weeks"]).weeks;
			}else{
				divisor = this.latest.diff(this.earliest,["months"]).months;
			}

			return this.totalSecondsWorked / divisor;
		},

		trueHourlyRate: function(){
			if(this.totalSecondsWorked > 0){
				return this.netIncome / (this.totalSecondsWorked / 60 / 60)
			}else{
				return 0;
			}
		},

	},

}
</script>

<style scoped lang="scss">

</style>