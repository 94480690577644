<template>
	<div id="payment-terms-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">
				{{ $t('invoice.details.payment-terms-modal.heading') }}
			</div>
<!--			<div class="modal-subtitle">-->
<!--				{{ $t('invoice.details.payment-terms-modal.subheading') }}-->
<!--			</div>-->

<!--			<div class="h-outline mb-2">-->
<!--				<div class="font-12 text-left mx-4 mt-2" v-html="$t('invoice.details.payment-terms-modal.what-to-update')"></div>-->
<!--				<div class="mx-4 mb-2">-->
<!--					<v-radio-group hide-details dense v-model="updateClientToo" class="mt-1">-->
<!--						<v-radio-->
<!--							v-for="option in updateOptions"-->
<!--							:key="option.value"-->
<!--							:label="option.label"-->
<!--							:value="option.value"-->
<!--						></v-radio>-->
<!--					</v-radio-group>-->
<!--				</div>-->
<!--			</div>-->

			<v-select
				class="h-outline"
				persistent-placeholder
				hide-details
				:label="$t('proposal.terms.payment-terms')"
				:items="dayOptions"
				v-model="paymentTerms.paymentDays"
				@change="$emit('payment-days-change')"
			>
				<template v-slot:selection="{ item }">
					<span v-if="item === 0">On Receipt</span>
					<span v-if="item !== 0">{{ item }} days</span>
				</template>
				<template v-slot:item="{ item }">
					<span v-if="item === 0">On Receipt</span>
					<span v-if="item !== 0">{{ item }} days</span>
				</template>
			</v-select>

<!--			<v-select-->
<!--				v-if="updateClientToo"-->
<!--				class="h-outline"-->
<!--				persistent-placeholder-->
<!--				hide-details-->
<!--				:label="$t('proposal.terms.invoice-schedule')"-->
<!--				:items="scheduleOptions"-->
<!--				v-model="paymentTerms.invoicingSchedule"-->
<!--			>-->
<!--				<template v-slot:item="{ item }">-->
<!--					<span>{{ item }}</span>-->
<!--				</template>-->
<!--			</v-select>-->

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				:label="$t('proposal.terms.late-payment')"
				v-model="paymentTerms.latePaymentFee"
				placeholder="No fee"
				suffix="% mo."
				type="number"
			></v-text-field>

<!--			<div class="d-flex justify-space-between">-->
<!--				<div style="flex:1 1 50%" class="">-->
<!--					<v-select-->
<!--						class="h-outline"-->
<!--						persistent-placeholder-->
<!--						hide-details-->
<!--						:label="$t('proposal.terms.deposit-required')"-->
<!--						:items="depositOptions"-->
<!--						v-model="paymentTerms.depositType"-->
<!--					>-->
<!--						<template v-slot:item="{ item }">-->
<!--							<span>{{ item }}</span>-->
<!--						</template>-->
<!--					</v-select>-->
<!--				</div>-->
<!--				<div class="ml-2" style="flex:1 1 50%" v-if="paymentTerms.depositType !== 'No deposit'">-->
<!--					<v-text-field-->
<!--						class="h-outline"-->
<!--						persistent-placeholder-->
<!--						hide-details-->
<!--						:label="$t('invoice.details.payment-terms-modal.deposit-amount')"-->
<!--						v-model="paymentTerms.depositAmount"-->
<!--						:suffix="paymentTerms.depositType === 'Percentage' ? '%' : ''"-->
<!--						:prefix="paymentTerms.depositType === 'Fixed amount' ? currencySymbol : ''"-->
<!--						:rules="depositAmountRules"-->
<!--						type="number"-->
<!--					></v-text-field>-->
<!--				</div>-->
<!--			</div>-->

<!--			<v-select-->
<!--				persistent-placeholder-->
<!--				hide-details-->
<!--				class="h-outline"-->
<!--				:items="paidByOptions"-->
<!--				v-model="paymentTerms.whoPaysCardFees"-->
<!--				label="Pass credit card fees on to client?"-->
<!--			></v-select>-->
		</div>
		<div class="modal-footer">
			<v-btn icon @click.native="confirmDeleteDialog = true" class="mr-1 secondary-action">
				<v-icon size="20">$delete</v-icon>
			</v-btn>
			<v-btn class="primary-action ml-1" width="170" @click="handleSave()">{{ $t('global.save') }}</v-btn>
		</div>
		<confirm-dialog
			:dialog="confirmDeleteDialog"
			:heading-text="$t('global.confirm')"
			:body-text="$t('global.confirm-delete')"
			@confirm="deleteTerms()"
			@close="confirmDeleteDialog = false"
		></confirm-dialog>
	</div>
</template>

<script>
	import ClientService from '@/modules/clients/ClientService';
	import jsonPatch from 'fast-json-patch';
	// import HPillSelector from '@/components/HPillSelector';
	import ConfirmDialog from '@/components/ConfirmDialog';

	export default {
		name: 'PaymentTermsModal',

		props: { paymentTerms: { type: Object, required: false }, clientId: { type: String, required: true } },

		components: { ConfirmDialog },

		data: function() {
			return {
				clientService: new ClientService(),
				observer: null,
				client: null, // populate client from db for patch if needed
				updateClientToo: false,
				updateOptions: [
					{ label: 'Invoice only', value: false },
					{ label: 'Client & invoice', value: true },
				],
				paidByOptions: [
					{ text: 'No', value: 'Freelancer' },
					{ text: 'Yes', value: 'Client' },
					{ text: 'Split it', value: 'Split' },
				],
				confirmDeleteDialog: false,

				dayOptions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 30, 45, 60, 90, 120, 180],
				scheduleOptions: ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Annually', 'As Needed'],
				creationOptions: ['Manual', 'Automatic'],
				depositOptions: ['No deposit', 'Fixed amount', 'Percentage'],

				rules: {
					min(min, v) {
						return v >= min || `Must be at least ${min}`;
					},
					max(max, v) {
						return v <= max || `Cannot be greater than ${max}.`;
					},
				},
			};
		},

		mounted() {
			if (this.clientId) this.getClient();
			// if (!this.paymentTerms) this.paymentTerms = this.paymentTermsDefault;
			console.log('clientInfoModal mounted', this.clientId);
		},

		beforeDestroy() {},

		methods: {
			getClient() {
				this.clientService
					.getClientDetail(this.clientId)
					.then((res) => {
						this.client = res.data;
						this.observer = jsonPatch.observe(this.client);
						console.log('getClient', this.client);
					})
					.catch((err) => {
						console.log(err);
					});
			},

			// updateClient() {
			// 	if (!this.clientId) return;
			// 	if (this.client.paymentTerms) {
			// 		this.client.paymentTerms = Object.assign(this.client.paymentTerms, this.paymentTerms);
			// 	} else {
			// 		delete this.client.paymentTerms;
			// 		this.client.paymentTerms = this.paymentTerms;
			// 	}
			// 	const patch = jsonPatch.generate(this.observer, true);
			//
			// 	if (patch.length > 0) {
			// 		this.clientService
			// 			.patchClient(this.clientId, patch)
			// 			.then(() => {
			// 				// this.client = res.data;
			// 				// this.observer = jsonPatch.observe(this.client);
			// 				this.$store.commit('success', 'Client settings saved.');
			// 			})
			// 			.catch((err) => {
			// 				this.alert('error', err.response.data.message);
			// 			});
			// 	}
			// },

			handleSave() {
				// if (this.updateClientToo) {
				// 	this.updateClient();
				// }
				this.$emit('close');
			},

			deleteTerms() {
				this.confirmDeleteDialog = false;
				this.$emit('delete-terms');
				this.$emit('close');
			},
		},
		computed: {
			currencySymbol: function(){
				return this.$formatters.currencySymbol(this.$store.state.defaultCurrency);
			},

			depositAmountRules() {
				let rule = [];
				rule.push(this.rules.min(1, this.paymentTerms.depositAmount));
				if (this.paymentTerms.depositType === 'Percentage')
					rule.push(this.rules.max(100, this.paymentTerms.depositAmount));
				return rule;
			},
		},
	};
</script>

<style lang="scss">
	#payment-terms-modal {
		max-width: 500px;
		background-color: var(--v-white-base);

		.v-radio {
			label {
				font-size: 14px;
			}
			margin-bottom: 0 !important;
		}
	}
</style>
