import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class InvoiceService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getInvoice(clientId, id) {
		return this.httpClient
			.get(`/clients/${clientId}/invoices/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	resetAccountingIntegrationKeys(clientId,id){
		return this.httpClient.post(`/clients/${clientId}/invoices/${id}/resetAccountingIntegrationKeys`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getOutstandingInvoices() {
		return this.httpClient
			.get('/invoices/outstanding')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	render(invoice) {
		return this.httpClient
			.post('/invoices/render',invoice)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getInvoiceCount() {
		return this.httpClient
			.get('/invoices/count')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateExchangeRate(clientId, invoiceId, paymentId, exchangeRate){
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${invoiceId}/payment/${paymentId}/exchangeRate?rate=${exchangeRate}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getInvoiceItem(clientId, invoiceId, invoiceItemId) {
		return this.httpClient
			.get(`/clients/${clientId}/invoices/${invoiceId}/item/${invoiceItemId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getInvoices(clientId, earliest, latest, currency = null) {
		return this.httpClient
			.get('/invoices', {
				params: {
					clientId: clientId,
					earliest: earliest,
					latest: latest,
					currency: currency
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getInvoicesSentOrPaidBetween(earliest, latest) {
		return this.httpClient
			.get('/invoices/sentOrPaidBetween', {
				params: {
					earliest: earliest,
					latest: latest,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createInvoice(clientId, invoice, persist = true) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices?persist=${persist}`, invoice, {
				headers: {
					'content-type': 'application/json'
				}
			})
			.then((res) => {
				store.state.eventBus.$emit('created', 'invoice');
				return Promise.resolve(res);
			})
			.catch((err) => Promise.reject(err));
	}

	putInvoice(clientId, id, invoice) {
		return this.httpClient
			.put(`/clients/${clientId}/invoices/${id}`, invoice)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchInvoice(clientId, id, invoice) {
		return this.httpClient
			.patch(`/clients/${clientId}/invoices/${id}`, invoice)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteInvoice(clientId, id) {
		return this.httpClient
			.delete(`/clients/${clientId}/invoices/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getBillable(clientId, invoiceId = '', projectId = '') {
		let params = [];
		if (invoiceId) {
			params.push(`invoiceId=${invoiceId}`);
		}
		if (projectId) {
			params.push(`projectId=${projectId}`);
		}
		return this.httpClient
			.get(`/clients/${clientId}/invoices/billable` + (params.length ? '?' + params.join('&') : ''))
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getUnBilledExpenses(clientId, invoiceId) {
		return this.httpClient
			.get(`/clients/${clientId}/invoices/billable/expenses?invoiceId=${invoiceId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendInvoice(clientId, id, post, sendLater=null) {
		const params = {};

		if (sendLater !== null) {
			params.sendLater = sendLater;
		}

		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/send`, post, {params: params})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	downloadInvoice(clientId, id) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/download`, null, {
				responseType: 'blob',
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	lateFee(clientId, id, lateFeeWaived) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/lateFee?lateFeeWaived=` + Boolean(lateFeeWaived))
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	returnToDraft(clientId, id) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/returnToDraft`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	markAsSent(clientId, id) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/markAsSent`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	void(clientId, id, userNote) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/void?userNote=${userNote}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	writeOff(clientId, id, userNote) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/writeOff?userNote=${userNote}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	payment(clientId, id, post) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/payment`, post)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deletePayment(clientId, id, paymentId) {
		return this.httpClient
			.delete(`/clients/${clientId}/invoices/${id}/payment/${paymentId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	viewed(clientId, id) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/viewed`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	refreshInvoiceNumber(clientId, id) {
		return this.httpClient
			.post(`/clients/${clientId}/invoices/${id}/refreshInvoiceNumber`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	invoiceEmailTemplate(clientId, invoiceId, paymentId = null, templateId = null){
		let params = {
			paymentId: paymentId,
			templateId: templateId
		}
		return this.httpClient
			.get(`/clients/${clientId}/invoices/${invoiceId}/emailTemplate`,{params:params})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	resetRecurringPaymentTerms() {
		return this.httpClient
			.post('/invoices/resetRecurringPaymentTerms')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	resetInvoiceSequence() {
		return this.httpClient
			.post('/invoices/resetInvoiceSequence')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
