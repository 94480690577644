<template>
	<div class="text-left form-content" @click="$emit('click',$event)" v-if="isConditionMet">
		<editor
				ref="textEditor"
				v-if="active"
				:api-key="$store.getters.getTinyMceKey"
				:initial-value="element.text"
				:inline="true"
				:init="mceConfigText"
				@input="handleTextInput"
		></editor>
		<div v-else v-html="element.text"></div>
		<div v-if="active" class="row-format mb-n1 mt-1">
			<v-icon size="22" class="material-symbols-rounded" style="cursor: grab">drag_indicator</v-icon>
			<v-icon size="22" class="ml-auto pointer material-symbols-rounded" @click="$emit('delete')">delete</v-icon>
		</div>
	</div>
</template>

<script>
import RendererMixin from "@/modules/forms/v2/schema/RendererMixin";

export default {
	name: "TextBlock",

	props: ['item','active', 'results','builder'],

	mixins: [RendererMixin],

	components: {
		editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
	},

	data: function () {
		return {
			element: {... this.item},
			mceConfigText: {
				menubar: false,
				inline: true,
				paste_as_text: false,
				paste_data_images: true,
				table_style_by_css: true,
				statusbar: false,
				forced_root_block: 'div',
				plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
				contextmenu: 'paste | link image | table',
				indentation: '12pt',
				skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
				content_css: this.$vuetify.theme.dark ? 'dark' : '',
				toolbar: [
					'bold italic underline forecolor | formatting_group | alignleft aligncenter alignright alignjustify | media_group',
				],
				fontsize_formats: "10px 12px 14px 16px 18px 20px 22px 24px 28px 32px 40px 48px",
				toolbar_groups: {
					formatting_group: {
						icon: 'format',
						tooltip: 'Formatting',
						items: 'fontsizeselect | blockquote bullist numlist indent outdent table | removeformat'
					},
					media_group: {
						icon: 'embed',
						tooltip: 'Media',
						items: ' link unlink media'
					}
				},
				table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
			},
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		handleTextInput: function () {
			this.element.text = this.$refs.textEditor.editor.getContent();
			this.$emit('change',this.element);
		},
	},

	computed: {},

	watch: {
		active: function(val){
			if(val){
				setTimeout(() => this.$refs.textEditor.editor.focus(),200);
			}
		}
	}

}
</script>

<style scoped lang="scss">
</style>