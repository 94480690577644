var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format text-left" },
    [
      _vm._m(0),
      _c(
        "draggable",
        {
          staticClass: "column-format gap-3",
          attrs: { list: _vm.agreement.pages },
          on: { end: _vm.update }
        },
        _vm._l(_vm.agreement.pages, function(page, index) {
          return _c(
            "div",
            {
              key: page.id,
              staticClass: "page-preview row-format gap-1 text-left",
              on: {
                click: function($event) {
                  return _vm.scrollToPage(index)
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "drag-handle",
                  staticStyle: { cursor: "grab" },
                  attrs: { size: "20" }
                },
                [_vm._v("drag_indicator")]
              ),
              _vm.editIndex === index
                ? _c("v-text-field", {
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "persistent-placeholder": "",
                      placeholder: "Title (optional)"
                    },
                    on: {
                      blur: function($event) {
                        _vm.update()
                        _vm.editIndex = -1
                      }
                    },
                    model: {
                      value: page.title,
                      callback: function($$v) {
                        _vm.$set(page, "title", $$v)
                      },
                      expression: "page.title"
                    }
                  })
                : _c("div", [_vm._v(_vm._s(page.title ? page.title : ""))]),
              _c(
                "div",
                { staticClass: "actions ml-auto row-format align-center" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded pointer",
                      attrs: { size: "20", color: "gray_70" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.toggleEditIndex(index)
                        }
                      }
                    },
                    [_vm._v("edit")]
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded pointer",
                      attrs: { size: "20", color: "gray_70" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.confirmDelete(index)
                        }
                      }
                    },
                    [_vm._v("delete")]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.$emit("add-page")
                }
              }
            },
            [_vm._v("+ Add page")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-format pt-1 pb-4" }, [
      _c("div", { staticClass: "brand-bold font-18" }, [_vm._v("Contents")]),
      _c("div", { staticClass: "font-14 font-gray_80" }, [
        _vm._v("Click a heading to navigate to the section")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }