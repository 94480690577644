<template>
	<div class="row-format gap-2 font-14 pointer" v-if="scheduledMeeting" @click="openMeeting">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" v-tippy="{ content: 'Scheduled meeting' }"
			>event</v-icon
		>
		<div>Meeting {{ meetingStatus }} {{ meetingTime }}</div>
		<div class="ml-auto font-gray_80 font-12">{{ item.timestamp | formatForTimeAgo }}</div>
	</div>
</template>

<script>
	import ScheduledMeetingService from '@/modules/meetings/ScheduledMeetingService';
	import HDateTime from '@/modules/utils/HDateTime';
	import MeetingDetail from '@/modules/meetings/MeetingDetail';

	export default {
		name: 'ScheduledMeeting',

		props: ['item'],

		components: {},

		data: function() {
			return {
				scheduledMeeting: null,
				scheduledMeetingService: new ScheduledMeetingService(),
			};
		},

		mounted() {
			this.scheduledMeetingService
				.getScheduledMeeting(this.item.itemId)
				.then((res) => {
					this.scheduledMeeting = res.data;
					//this.$emit('actual-time',this.scheduledMeeting.confirmedTime.start);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						this.$emit('deleted');
					}
				});
		},

		beforeDestroy() {},

		methods: {
			openMeeting: function() {
				let binding = {
					id: this.scheduledMeeting.id,
				};
				this.$store.state.globalModalController.openModal(MeetingDetail, binding, true, false).then((res) => {
					if (res) {
						this.scheduledMeeting = res;
					}
				});
			},
		},

		computed: {
			meetingTime: function() {
				return HDateTime.fromISO(this.scheduledMeeting.confirmedTime.start).toLocaleString(HDateTime.DATETIME_MED);
			},

			meetingStatus: function() {
				if (this.scheduledMeeting.meetingStatus === 'Cancelled') {
					return 'cancelled for';
				}

				let start = HDateTime.fromISO(this.scheduledMeeting.confirmedTime.start);
				let now = HDateTime.now();

				if (start > now) {
					return 'scheduled for';
				} else {
					return 'occurred on';
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
