import { v4 as uuidv4 } from 'uuid';
// import { Duration } from 'luxon';

export default {
	methods: {
		getHourlyProjectTree(project) {
			let tree = []; // array of objects with {year:2020, month:1}

			project.projectHours.sort((a, b) => a.dateTime.toISO().localeCompare(b.dateTime.toISO()));

			for (let i = 0; i < project.projectHours.length; i++) {
				let projectHours = project.projectHours[i];
				let year = projectHours.dateTime.year;
				let month = projectHours.dateTime.month;
				let monthIndex = tree.findIndex((v) => v.year == year && v.month == month);
				if (monthIndex < 0) {
					tree.push({
						year: year,
						month: month,
						id: uuidv4(),
						items: [],
						name: projectHours.dateTime.monthLong,
						class: 'month',
					});
					monthIndex = tree.length - 1;
				}
				// Get week-start and month-start. Use whichever is greater for week-start
				let startOfWeek = projectHours.dateTime.startOf('week');
				let startOfMonth = projectHours.dateTime.startOf('month');
				if (startOfMonth > startOfWeek) startOfWeek = startOfMonth;
				// Get week-end and month-end. Use whichever is less for week-end
				let endOfWeek = projectHours.dateTime.endOf('week');
				let endOfMonth = projectHours.dateTime.endOf('month');
				if (endOfMonth < endOfWeek) endOfWeek = endOfMonth;

				let weekIndex = tree[monthIndex].items.findIndex(
					(v) => v.startOfWeek.ts == startOfWeek.ts && v.endOfWeek.ts == endOfWeek.ts
				);

				if (weekIndex < 0) {
					tree[monthIndex].items.push({
						startOfWeek: startOfWeek,
						endOfWeek: endOfWeek,
						name: startOfWeek.toFormat('LLL d') + '-' + endOfWeek.day,
						id: uuidv4(),
						items: [],
						class: 'week',
					});
					weekIndex = tree[monthIndex].items.length - 1;
				}

				let day = projectHours.dateTime.day;

				let dayIndex = tree[monthIndex].items[weekIndex].items.findIndex((v) => v.day == day);
				if (dayIndex < 0) {
					tree[monthIndex].items[weekIndex].items.push({
						day: day,
						endOfWeek: endOfWeek,
						name: projectHours.dateTime.toFormat('EEE d'),
						id: uuidv4(),
						items: [],
						class: 'day',
					});
					dayIndex = tree[monthIndex].items[weekIndex].items.length - 1;
				}
				tree[monthIndex].items[weekIndex].items[dayIndex].items.push(projectHours); // Push all timerEvents onto day array

				// Subtotal Luxon-duration by day
				let subtotal = tree[monthIndex].items[weekIndex].items[dayIndex].items.reduce(function(prev, cur) {
					return prev + cur.duration;
				}, 0);
				tree[monthIndex].items[weekIndex].items[dayIndex].durationSubtotal = subtotal;
				tree[monthIndex].items[weekIndex].items[dayIndex].durationSubtotalDisplay = this.formatWeekDuration(subtotal);
			}
			return this.sumTreeHours(tree);
		},
		sumTreeHours(tree) {
			// console.log('sumTreeHours', tree);
			for (let monthIndex = 0; monthIndex < tree.length; monthIndex++) {
				for (let weekIndex = 0; weekIndex < tree[monthIndex].items.length; weekIndex++) {
					let subtotal = tree[monthIndex].items[weekIndex].items.reduce(function(prev, cur) {
						return prev + cur.durationSubtotal;
					}, 0);
					tree[monthIndex].items[weekIndex].durationSubtotal = subtotal;
					tree[monthIndex].items[weekIndex].durationSubtotalDisplay = this.formatWeekDuration(subtotal);
				}

				let subtotal = tree[monthIndex].items.reduce(function(prev, cur) {
					return prev + cur.durationSubtotal;
				}, 0);
				tree[monthIndex].durationSubtotal = subtotal;
				tree[monthIndex].durationSubtotalDisplay = this.formatMonthDuration(subtotal);
			}
			return tree;
		},
		formatMonthDuration(duration) {
			const tmp = this.$luxon.Duration.fromMillis(duration).shiftTo('hours', 'minutes');
			// console.log('formatMonthDuration', tmp);
			const hours = tmp.hours;
			const mins = Math.round(tmp.minutes).toString();
			let ret = [];
			if (hours) ret.push(hours + ' h');
			if (mins) ret.push(mins + ' min');
			return ret.join(' ');
		},
		formatWeekDuration(duration) {
			// console.log('formatWeekDuration', duration);
			return this.$luxon.Duration.fromMillis(duration).toFormat('h:mm:ss');
		},

		getRetainerProjectTree(project,invoice) {

			let tree = []; // array of objects with {year:2020, month:1}

			for (let i = 0; i < project.billableRetainerPeriods.length; i++) {
				let period = project.billableRetainerPeriods[i];
				let item = invoice.items.find(i => i.projectId === project.id && i.retainerPeriod && i.retainerPeriod.start === period.start);
				tree.push({
					start: period.start,
					end: period.end,
					amount: project.feeSchedule.amount,
					id: project.id + ':' + period.start,
					projectId: project.id,
					items: [],
					name: this.$DateTime.humanDate(period.start) + ' - ' + this.$DateTime.humanDate(period.end),
					description: item ? item.description : project.name,
					class: 'retainer-period',
				});
			}

			tree.subtotal = tree.reduce(function(prev, cur) {
				return prev + cur.amount;
			}, 0);
			return tree;
		},

		getPerItemProjectTree(project, invoice) {
			let tree = []; // array of objects with {year:2020, month:1}

			for (let i = 0; i < project.deliverables.length; i++) {
				let deliv = project.deliverables[i];
				let invoiceItem = this.getInvoiceItem(invoice, deliv);

				tree.push({
					// amount: deliv.fee.price * deliv.fee.qty,

					amount: invoiceItem
						? invoiceItem.qty * deliv.product.rate
						: deliv.product
						? deliv.quantity * deliv.product.rate
						: 0,
					id: project.id + ':' + deliv.id,
					projectId: project.id,
					projectDeliverableId: deliv.id,
					qty: invoiceItem ? invoiceItem.qty : deliv.quantity,
					items: [],
					name: deliv.name,
					description: deliv.description,
					class: 'deliverable',
					product: deliv.product,
				});
			}

			tree.subtotal = tree.reduce(function(prev, cur) {
				return prev + cur.amount;
			}, 0);
			return tree;
		},

		getInvoiceItem(invoice, deliverable) {
			for (let i = 0; i < invoice.items.length; i++) {
				if (invoice.items[i].projectDeliverableId === deliverable.id) {
					return invoice.items[i];
				}
			}
		},
	},
};
