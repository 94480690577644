import tokenData from "@/modules/templates/emails/tokens.json";
import DiscoveryTemplateService from "@/modules/discovery/DiscoveryTemplateService";
import MeetingService from "@/modules/meetings/MeetingService";

export default {

    data: function () {
        return {
            refreshKey: 0,
            tokens: []
        }
    },

    methods: {
        toggleCategory: function(category) {
            if (category.expanded) {
                category.expanded = false;
            } else {
                category.expanded = true;
            }
            this.refreshKey++;
        },

        initTokens: function() {
            let tokens = JSON.parse(JSON.stringify(tokenData));
            let discoveryTemplateService = new DiscoveryTemplateService();
            let meetingSchedulerService = new MeetingService();

            if(this.$store.state.pipelineFields && this.$store.state.pipelineFields.fields){
                let opportunity = tokens.find((t) => t.category === 'Opportunity');
                this.$store.state.pipelineFields.fields.forEach((f) => {
                    opportunity.tokens.push({
                        label: f.name,
                        value: 'Opportunity.Custom.' + f.mappingKey
                    });
                });
            }

            if(this.$store.state.clientFields && this.$store.state.clientFields.fields){
                let client = tokens.find((t) => t.category === 'Client');
                this.$store.state.clientFields.fields.forEach((f) => {
                    client.tokens.push({
                        label: f.name,
                        value: 'Client.Custom.' + f.mappingKey
                    });
                });
            }

            if(this.$store.state.contactFields && this.$store.state.contactFields.fields){
                let contact = tokens.find((t) => t.category === 'Contact');
                this.$store.state.contactFields.fields.forEach((f) => {
                    contact.tokens.push({
                        label: f.name,
                        value: 'Contact.Custom.' + f.mappingKey
                    });
                });
            }

            meetingSchedulerService.getMeetingList().then((res) => {
                let schedulers = tokens.find((t) => t.category === 'Scheduler links');
                res.data.forEach((s) => {
                    schedulers.tokens.push({
                        label: s.name ? s.name : '--',
                        value: 'Scheduler.' + s.uniqueMeetingId,
                    });
                });

                schedulers.tokens.sort((a, b) => a.label.localeCompare(b.label));
            });

            discoveryTemplateService.getDiscoveryTemplateList().then((res) => {
                let forms = tokens.find((t) => t.category === 'Form links');
                res.data.forEach((f) => {
                    forms.tokens.push({
                        label: f.name ? f.name : '--',
                        value: 'Forms.' + f.uniqueTemplateId,
                    });
                });

                forms.tokens.sort((a, b) => a.label.localeCompare(b.label));
            });

            tokens.sort((a, b) => a.category.localeCompare(b.category));
            this.tokens.splice(0, this.tokens.length);
            this.tokens.push(...tokens);
        },
    }

}