var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-combobox", {
        ref: "ContactInput",
        staticClass: "mt-1 phone-input",
        attrs: {
          items: _vm.contacts,
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "To:",
          placeholder: "Enter phone or search",
          multiple: _vm.multiple,
          "small-chips": "",
          filter: _vm.isMatch
        },
        on: { change: _vm.validateToList },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      outlined: !item.valid,
                      small: "",
                      color: !item.valid ? "warning" : ""
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "font-12",
                        style: !item.valid
                          ? "text-decoration: line-through"
                          : ""
                      },
                      [
                        _vm._v(
                          _vm._s(item.fullName) +
                            " (" +
                            _vm._s(item.phoneLabel) +
                            ")"
                        )
                      ]
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "row-format align-center gap-1" },
                  [
                    item.client
                      ? _c("client-avatar", {
                          attrs: { "x-small": "", client: item.client }
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "font-14" }, [
                      _vm._v(
                        _vm._s(item.fullName) +
                          " (" +
                          _vm._s(item.phoneLabel) +
                          ")"
                      )
                    ])
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.toList,
          callback: function($$v) {
            _vm.toList = $$v
          },
          expression: "toList"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }