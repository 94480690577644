var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "leadgen-card",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$emit("leadgen-dialog", _vm.card)
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("div", {
            staticClass: "heading",
            domProps: { innerHTML: _vm._s(_vm.heading) }
          }),
          _c("div", { staticClass: "subheading" }, [
            _vm._v(" " + _vm._s(_vm.subheading) + " ")
          ]),
          _vm._l(_vm.infos, function(info, i) {
            return _c("div", { key: i, staticClass: "infos" }, [
              _c(
                "div",
                { staticClass: "d-flex align-center justify-start gap-1" },
                [
                  _c("v-icon", { attrs: { size: "16", color: "black" } }, [
                    _vm._v(_vm._s(info.icon))
                  ]),
                  _c("div", [_vm._v(_vm._s(info.label))])
                ],
                1
              )
            ])
          }),
          _c("v-divider", { staticClass: "my-2" }),
          _vm._l(_vm.cardActions, function(action, i) {
            return _c(
              "div",
              { key: _vm.card.id + "-" + i, staticClass: "actions" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "action pointer grey-action d-flex align-center justify-start gap-1",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit(
                          "action-clicked",
                          $event,
                          action.name,
                          _vm.card
                        )
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { size: "16" } }, [
                      _vm._v("$" + _vm._s(action.icon))
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.$t(action.label)))])
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "clientAvatar" },
        [
          _vm.card.client
            ? _c("client-avatar", {
                attrs: { client: _vm.card.client, small: "" }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }