<template>
	<div class="row-format gap-2 font-14 pointer" v-if="invoice" @click="viewInvoice">
		<v-icon class="material-symbols-rounded" color="secondary" size="20"  v-tippy="{content: `Invoice`}">request_quote</v-icon>
		<div>Invoice {{ invoice.invoiceNumberFormatted }} | {{$formatters.dollars(invoice.total,true,true,invoice.currency)}} | <span style="text-transform: capitalize">{{invoice.status.toLowerCase()}}</span></div>
		<div class="ml-auto font-gray_80 font-12 nowrap">{{item.timestamp | formatForTimeAgo}}</div>
	</div>
</template>

<script>
import InvoiceService from "@/modules/invoices/InvoiceService";
import InvoiceMixin from "@/modules/pipeline/opportunity/workflow/mixins/InvoiceMixin";

export default {
	name: "Invoice",

	props: ['item'],

	mixins: [InvoiceMixin],

	components: {},

	data: function () {
		return {
			invoice: null,
			invoiceService: new InvoiceService(),
		}
	},

	mounted() {
		this.invoiceService.getInvoice("0",this.item.itemId)
				.then((res) => {
					this.invoice = res.data
				})
				.catch((err) => {
					if(err.response.status === 404){
						this.$emit('deleted');
					}
				});
	},

	beforeDestroy() {
	},

	methods: {
		viewInvoice: function(){
			this.openInvoice(this.invoice.clientId,this.invoice.id);
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>