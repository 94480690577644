var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode === "standard" || _vm.mode === "dense"
    ? _c(
        "div",
        { staticStyle: { width: "100%" } },
        [
          _c("v-autocomplete", {
            attrs: {
              "persistent-placeholder": "",
              outlined: "",
              label: _vm.field.name,
              "hide-details": "",
              dense: _vm.mode === "dense",
              items: _vm.field.options
            },
            model: {
              value: _vm.ourValue,
              callback: function($$v) {
                _vm.ourValue = $$v
              },
              expression: "ourValue"
            }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticStyle: { width: "100%" } },
        [
          _c(
            "v-menu",
            {
              attrs: { "close-on-click": true, "close-on-content-click": true },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "div",
                        _vm._g({ staticClass: "pointer truncate" }, on),
                        [_vm._v(_vm._s(_vm.ourValue ? _vm.ourValue : "--"))]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { staticClass: "more-menu" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "more-menu-item",
                      on: {
                        click: function($event) {
                          _vm.ourValue = null
                        }
                      }
                    },
                    [_vm._v("--")]
                  ),
                  _vm._l(_vm.field.options, function(option, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "more-menu-item",
                        on: {
                          click: function($event) {
                            _vm.ourValue = option
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(option) + " ")]
                    )
                  })
                ],
                2
              )
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }