var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Accounting" }
            ]
          }
        },
        [_vm._v(" Invoices ")]
      ),
      _c(
        "div",
        { staticClass: "fill-height column-format gap-4" },
        [
          _c(
            "div",
            { staticClass: "row-format align-center gap-2 flex-wrap" },
            [
              _c("report-filter", {
                attrs: { "current-view": "Money" },
                on: {
                  "clear-filter": function($event) {
                    return _vm.clearFilter()
                  }
                },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              }),
              _c("scope-button", {
                attrs: {
                  scope: "DRAFT",
                  "is-active": _vm.filter.invoiceStatus.includes("DRAFT"),
                  label: "Draft"
                },
                on: { "scope-update": _vm.updateScope }
              }),
              _c("scope-button", {
                attrs: {
                  scope: "SENT",
                  "is-active": _vm.filter.invoiceStatus.includes("SENT"),
                  label: "Sent"
                },
                on: { "scope-update": _vm.updateScope }
              }),
              _c("scope-button", {
                attrs: {
                  scope: "PARTIAL",
                  "is-active": _vm.filter.invoiceStatus.includes("PARTIAL"),
                  label: "Partial"
                },
                on: { "scope-update": _vm.updateScope }
              }),
              _c("scope-button", {
                attrs: {
                  scope: "PAID",
                  "is-active": _vm.filter.invoiceStatus.includes("PAID"),
                  label: "Paid"
                },
                on: { "scope-update": _vm.updateScope }
              }),
              _c("scope-button", {
                attrs: {
                  scope: "LATE",
                  "is-active": _vm.filter.invoiceStatus.includes("LATE"),
                  label: "Late"
                },
                on: { "scope-update": _vm.updateScope }
              }),
              _c("scope-button", {
                attrs: {
                  scope: "VOIDED",
                  "is-active": _vm.filter.invoiceStatus.includes("VOIDED"),
                  label: "Voided"
                },
                on: { "scope-update": _vm.updateScope }
              }),
              _c("scope-button", {
                attrs: {
                  scope: "WRITE-OFF",
                  "is-active": _vm.filter.invoiceStatus.includes("WRITE-OFF"),
                  label: "Write Off"
                },
                on: { "scope-update": _vm.updateScope }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "ml-auto super-action",
                  on: {
                    click: function($event) {
                      return _vm.$refs.InvoiceTable.addNew()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
                  _vm._v(" Add invoice")
                ],
                1
              )
            ],
            1
          ),
          _vm.isReady
            ? _c("invoice-list", {
                ref: "InvoiceTable",
                attrs: { filter: _vm.filter }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }