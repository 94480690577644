var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column-format fill-height pointer",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("div", { staticClass: "pa-4" }, [
        _c("div", { staticClass: "font-16 brand-medium font-gray_70" }, [
          _vm._v("Payments received")
        ]),
        _c("div", [
          _c("span", { staticClass: "font-24 brand-bold" }, [
            _vm._v(
              _vm._s(
                _vm.$formatters.dollars(
                  _vm.totalReceived,
                  false,
                  true,
                  _vm.project.client.currency
                )
              )
            )
          ]),
          _vm._v(
            " / " +
              _vm._s(
                _vm.$formatters.dollars(
                  _vm.outstanding,
                  false,
                  true,
                  _vm.project.client.currency
                )
              ) +
              " outstanding"
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "pa-4 mt-auto row-format align-center pointer",
          staticStyle: {
            "background-color": "var(--v-gray_5-base)",
            height: "44px",
            "border-top": "1px solid var(--v-gray_30-base)"
          }
        },
        [
          _c("div", [
            _c("span", { staticClass: "brand-bold" }, [
              _vm._v("Net profit " + _vm._s(_vm.netProfit))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "font-gray_80 font-12" }, [
              _vm._v("(" + _vm._s(_vm.totalExpenses) + " in expenses)")
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }