<template>
	<div id="discovery-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="ma-0 text-left" v-if="isReady">
				<div class="modal-title">Discovery Request</div>
				<div class="modal-subtitle">{{ subtitle }}</div>

				<div v-if="discovery.completedTimestamp" class="body-12 gray_80--text">
					Completed {{ $DateTime.shortDate(discovery.completedTimestamp) }}
				</div>
				<div v-else class="body-12 gray_80--text">
					Not completed
				</div>

				<div v-for="(item, j) in discovery.schema" :key="j" class="my-4">
					<div class="body-16 brand-medium gray_80--text" v-if="item.htmlHeader">
						{{ $formatters.stripHtml(item.htmlHeader) }}
					</div>
					<div class="body-14 gray_80--text">{{ $formatters.stripHtml(item.htmlText) }}</div>
					<div class="body-16 black--text mt-1" v-html="item.response"></div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" icon @click="confirmDeleteDiscovery(discovery)">
				<v-icon size="20">$delete</v-icon>
			</v-btn>
			<v-btn class="primary-action ml-1" style="min-width:120px" @click="$emit('close')">
				{{ $t('global.close') }}
			</v-btn>
		</div>

		<confirm-dialog
			:dialog="confirmDeleteDialog"
			@close="cancelDelete()"
			@confirm="deleteDiscoveryRequest()"
			heading-text="Confirm"
			yes-text="Yes"
			no-text="No"
			:body-text="$t('discovery.delete-confirm-request')"
		>
		</confirm-dialog>
	</div>
</template>

<script>
	import DiscoveryService from '@/modules/discovery/DiscoveryService';
	import ConfirmDialog from '@/components/ConfirmDialog';

	export default {
		name: 'Discovery',
		props: {
			client: { type: Object, required: true },
			discoveryRequestId: { type: String, required: true } ,
			cardType: { type: String, required: false, default: 'DiscoveryRequest'}
		},
		components: { ConfirmDialog },

		data() {
			return {
				newDiscoveryDialog: false,
				discoveryService: new DiscoveryService(),
				discovery: null,
				deleteTarget: null,
				confirmDeleteDialog: false,
				isReady: false,
			};
		},

		mounted() {
			this.discoveryService
				.getDiscoveryRequest(this.client.id, this.discoveryRequestId)
				.then((res) => {
					this.discovery = res.data;
					this.isReady = true;
					console.log(this.discovery);
				})
				.catch((err) => {
					console.log(err);
				});
		},

		methods: {
			alert(type, message) {
				this.$emit('alert', { type: type, message: message });
			},

			confirmDeleteDiscovery: function() {
				this.confirmDeleteDialog = true;
			},

			cancelDelete: function() {
				this.confirmDeleteDialog = false;
			},

			deleteDiscoveryRequest: function() {
				this.discoveryService
					.deleteDiscoveryRequest(this.client.id, this.discoveryRequestId)
					.then(() => {
						this.confirmDeleteDialog = false;
						this.$emit('delete-discovery', this.discoveryRequestId);
						this.$emit('close');
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},
		},

		computed: {
			subtitle() {
				if (this.discovery == null) return '';

				let name = '';

				if (this.discovery.contact) {
					let names = [this.discovery.contact.firstName, this.discovery.contact.lastName];

					if (names.join('') === '') {
						name = '';
					} else if (this.discovery.contact.email) {
						name = 'to ' + this.discovery.contact.email;
					} else {
						name = 'to ' + names.join(' ');
					}
				}

				return 'Sent ' + name + ', ' + this.$DateTime.shortDate(this.discovery.sentTimestamp);
			},
		},
	};
</script>

<style lang="scss">
	#discovery-modal {
		max-width: 450px;
	}
</style>
