<template>
	<div class="column-format text-left font-14 font-gray_70 py-3 gap-3">
		<div class="row-format gap-1">
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pt" label="Padding top" @input="updated"></v-text-field>
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pb" label="Padding bottom" @input="updated"></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pl" label="Padding left" @input="updated"></v-text-field>
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pr" label="Padding right" @input="updated"></v-text-field>
		</div>
		<v-text-field hide-details outlined dense label="Accept text" v-model="element.text" @input="updated"></v-text-field>
		<v-text-field hide-details outlined dense label="Accepted text" v-model="element.acceptedText" @input="update"></v-text-field>
		<v-select
			v-model="element.buttonSize"
			:items="['X-Small', 'Small', 'Normal', 'Large', 'X-Large']"
			hide-details
			outlined
			dense
			persistent-placeholder
			label="Button size"
			@change="updated"
		>
		</v-select>

		<v-select
			v-model="element.buttonAlignment"
			:items="alignment"
			item-text="label"
			item-value="value"
			hide-details
			dense
			outlined
			persistent-placeholder
			label="Button alignment"
			@change="updated"
		></v-select>

		<color-selector v-model="element.buttonColor" @input="updated" label="Button color"></color-selector>
	</div>
</template>

<script>
	import ColorSelector from "@/components/ColorSelector";

	export default {
		name: 'AcceptButtonConfig',

		props: ['item'],

		components: {ColorSelector},

		data: function() {
			return {
				element: { ...this.item },
				alignment: [
					{ label: 'Left', value: 'left' },
					{ label: 'Right', value: 'right' },
					{ label: 'Center', value: 'center' },
					{ label: 'Block', value: 'block' },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
