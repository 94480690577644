<template>
	<div>
		<div v-if="notificationSettings" id="settings-table">
			<table>
				<thead>
				<tr>
					<th style="text-align: left">Event Type</th>
					<th>In App</th>
					<th>Push</th>
					<th>Email</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="type in notificationTypes" :key="type.key">
					<td class="label-cell">{{type.label}}</td>
					<td><div class="row-format centered"><v-checkbox v-model="notificationSettings[type.key].app" hide-details dense @change="saveNotificationSettings"></v-checkbox></div></td>
					<td><div class="row-format centered"><v-checkbox v-model="notificationSettings[type.key].push" hide-details dense @change="saveNotificationSettings"></v-checkbox></div></td>
					<td><div class="row-format centered"><v-checkbox v-model="notificationSettings[type.key].email" hide-details dense @change="saveNotificationSettings"></v-checkbox></div></td>
				</tr>
				</tbody>
			</table>
		</div>
		<div v-if="pushEnabled">
			<v-btn block color="primary" v-if="!existingSubscription" @click="handleSubscribeToPushNotifications()"
				>Enable browser push notifications</v-btn
			>
			<v-btn block color="primary" v-else @click="unsubscribe()">Disable browser push notifications</v-btn>
		</div>
		<div v-else>Your browser doesn't support push notifications.</div>
	</div>
</template>

<script>
	import {
		PERMISSION_STATES,
		askForPushPermission,
		subscribeUserToPush,
		getExistingPushSubscription,
		unsubscribeUserToPush,
	} from '@/utils/Notifications';
	import PushSubscriptionService from '@/modules/account/PushSubscriptionService';
	import UserNotificationSettingsService from '@/modules/account/UserNotificationSettingsService';

	export default {
		name: 'Notifications',

		props: [],

		components: {},

		data: function() {
			return {
				userNotificationSettingsService: new UserNotificationSettingsService(),
				pushSubscriptionService: new PushSubscriptionService(),
				existingSubscription: null,
				notificationSettings: null,
				notificationTypes: [
					{ key: 'project', label: 'Project/Task events' },
					{ key: 'form', label: 'Form submissions' },
					{ key: 'meeting', label: 'Meeting events' },
					{ key: 'generic', label: 'System messages' },
					{ key: 'pipeline', label: 'Sales pipeline' },
					{ key: 'agreement', label: 'Agreement events' },
					{ key: 'ticket', label: 'Ticket notifications' },
				],
			};
		},

		async mounted() {
			this.getNotificationSettings();
			this.existingSubscription = await getExistingPushSubscription();
			this.notificationTypes.sort((a,b) => a.label.localeCompare(b.label));
			this.$store.state.eventBus.$on('account-changed', this.getNotificationSettings);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getNotificationSettings);
		},

		methods: {
			getNotificationSettings: function() {
				this.userNotificationSettingsService.getUserNotificationSettings().then((res) => {
					this.notificationSettings = res.data;
				});
			},

			saveNotificationSettings: function() {
				this.userNotificationSettingsService.updateUserNotificationSettings(this.notificationSettings).then((res) => {
					this.notificationSettings = res.data;
				});
			},

			async unsubscribe() {
				let result = await unsubscribeUserToPush();
				if (result) {
					this.pushSubscriptionService
						.unsubscribe(this.existingSubscription)
						.then(() => {
							this.existingSubscription = null;
							this.$store.commit('success', 'Push notifications have been disabled');
						})
						.catch((err) => this.$store.commit('error', err.response.data.message));
				} else {
					this.$store.commit('error', 'Unable to unsubscribe');
				}
			},

			async handleSubscribeToPushNotifications() {
				const permissionResult = await askForPushPermission();

				if (permissionResult === PERMISSION_STATES.GRANTED) {
					this.existingSubscription = await subscribeUserToPush();
					this.pushSubscriptionService
						.subscribe(this.existingSubscription)
						.then(() => {
							this.$store.commit('success', 'Push notifications have been enabled');
						})
						.catch((err) => this.$store.commit('error', err.response.data.message));
				}
			},
		},

		computed: {
			pushEnabled: function() {
				return 'serviceWorker' in navigator && 'PushManager' in window;
			},
		},
	};
</script>

<style scoped lang="scss">
	#settings-table {
		table {
			width: 100%;
			border-collapse: collapse;
			margin: 20px 0;
			font-size: 14px;
		}

		th,
		td {
			padding: 8px;
			text-align: center;
			border: 1px solid var(--v-gray_30-base);
		}

		th {
			background-color: var(--v-gray_5-base);
		}

		td {
			//background-color: #f9f9f9;
		}

		.label-cell {
			text-align: left;
			font-weight: 400;
			//background-color: #f2f2f2;
		}
	}
</style>
