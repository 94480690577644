export default {
	data: function() {
		return {
			mappings: [
				{ label: 'None', value: null },
				{ label: 'Business name', value: 'businessName' },
				{ label: 'Lead Source', value: 'leadSource' },
				{ label: 'Contact role', value: 'role' },
				{ label: 'First name', value: 'firstName' },
				{ label: 'Last name', value: 'lastName' },
				{ label: 'Address 1', value: 'address1' },
				{ label: 'Address 2', value: 'address2' },
				{ label: 'City', value: 'city' },
				{ label: 'Locality', value: 'locality' },
				{ label: 'Postal', value: 'postal' },
				{ label: 'Country', value: 'country' },
				{ label: 'Website', value: 'website' },
				{ label: 'Phone', value: 'phone' },
				{ label: 'Email', value: 'email' },
				{ label: 'Tax ID', value: 'taxId' },
			],
		};
	},
};
