<template>
	<div class="brand-box">
		<div class="px-4 py-2 border-radius-top pointer" @click.stop="expanded=!expanded"><slot name="heading"></slot></div>
		<div v-if="expanded">
			<div v-for="item in items" :key="item.id" class="brand-border-top work-row">
				<div class="px-4">
					<deliverable-mini
						v-if="item.type === 'Deliverable'"
						:deliverable="item.deliverable"
						@deliverable-clicked="$emit('deliverable-clicked', item.deliverable)"
					/>
					<opportunity-to-do
						v-if="item.type === 'OpportunityToDo'"
						:opportunity-to-do="item.toDo"
						@todo-clicked="$emit('todo-clicked', item.toDo)"
					/>
					<ticket-mini
						v-if="item.type === 'Ticket'"
						:ticket="item.ticket"
						:client="item.ticket.client"
						@ticket-clicked="$emit('ticket-clicked', item.ticket)"
					></ticket-mini>
				</div>
			</div>
			<div class="px-4 pb-2 brand-border-top" v-if="countComplete > 0" @click.stop="$emit('toggle-show-complete')">
				<div class="mt-2 font-gray_80 font-12 pointer">
					<span v-if="!showComplete">+ {{ countComplete }}</span
					><span v-else>- Hide</span> Completed
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DeliverableMini from '@/modules/productivity/work/DeliverableMini';
	import OpportunityToDo from '@/modules/productivity/work/OpportunityToDo';
	import TicketMini from '@/modules/productivity/work/TicketMini';

	export default {
		name: 'WorkBundle',

		props: ['icon', 'label', 'items', 'countComplete', 'showComplete', 'collapsed'],

		components: { DeliverableMini, OpportunityToDo, TicketMini },

		data: function() {
			return {
				expanded: !this.collapsed,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.work-row {
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>
