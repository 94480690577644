var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "mapper-details" } }, [
    _c("div", { attrs: { id: "header-area" } }, [
      _c("div", { staticClass: "header-left pl-md-6 pl-4" }, [
        _c(
          "div",
          { staticClass: "mr-6 brand-medium" },
          [
            _c("v-icon", [_vm._v("attach_file")]),
            _vm._v(" Data import - " + _vm._s(_vm.definition.type) + " ")
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "header-right pr-md-6 pr-4" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "8" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format",
                      staticStyle: { "flex-wrap": "wrap" }
                    },
                    _vm._l(_vm.columns, function(column) {
                      return _c(
                        "div",
                        {
                          key: column.column,
                          staticClass: "mr-2",
                          staticStyle: {
                            "min-width": "300px",
                            "max-width": "300px"
                          }
                        },
                        [
                          _c("v-select", {
                            staticClass: "h-outline",
                            style:
                              "" +
                              (column.required && column.ix == null
                                ? "border: 1px solid var(--v-error-base)"
                                : ""),
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": "",
                              label: column.label,
                              items: _vm.sampleRow,
                              "item-text": "data",
                              "item-value": "ix"
                            },
                            on: { change: _vm.updateRefreshKey },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "text-left font-14" },
                                        [_vm._v(_vm._s(item.data))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: column.ix,
                              callback: function($$v) {
                                _vm.$set(column, "ix", $$v)
                              },
                              expression: "column.ix"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "v-col",
                  { attrs: { cols: "4", align: "left" } },
                  [
                    _c(
                      "v-container",
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("v-select", {
                                  staticClass: "h-outline",
                                  attrs: {
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    items: _vm.dateFormatOptions,
                                    "item-text": "label",
                                    "item-value": "format",
                                    label: "Date format"
                                  },
                                  model: {
                                    value: _vm.dateFormat,
                                    callback: function($$v) {
                                      _vm.dateFormat = $$v
                                    },
                                    expression: "dateFormat"
                                  }
                                }),
                                _c("v-select", {
                                  staticClass: "h-outline",
                                  attrs: {
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    items: _vm.timeFormatOptions,
                                    "item-text": "label",
                                    "item-value": "format",
                                    label: "Time format"
                                  },
                                  model: {
                                    value: _vm.timeFormat,
                                    callback: function($$v) {
                                      _vm.timeFormat = $$v
                                    },
                                    expression: "timeFormat"
                                  }
                                }),
                                _c("v-select", {
                                  staticClass: "h-outline",
                                  attrs: {
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    items: _vm.dateTimeFormatOptions,
                                    "item-text": "label",
                                    "item-value": "format",
                                    label: "Date/Time format"
                                  },
                                  model: {
                                    value: _vm.dateTimeFormat,
                                    callback: function($$v) {
                                      _vm.dateTimeFormat = $$v
                                    },
                                    expression: "dateTimeFormat"
                                  }
                                }),
                                _vm.definition.type !== "Client"
                                  ? _c("v-checkbox", {
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        label:
                                          "Create new client/project if no match"
                                      },
                                      model: {
                                        value: _vm.createClient,
                                        callback: function($$v) {
                                          _vm.createClient = $$v
                                        },
                                        expression: "createClient"
                                      }
                                    })
                                  : _vm._e(),
                                _c("v-checkbox", {
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    label: "First row has headers"
                                  },
                                  model: {
                                    value: _vm.firstRowHeaders,
                                    callback: function($$v) {
                                      _vm.firstRowHeaders = $$v
                                    },
                                    expression: "firstRowHeaders"
                                  }
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary-action mt-5",
                                    attrs: { large: "" },
                                    on: { click: _vm.processImport }
                                  },
                                  [
                                    _vm._v(
                                      "Next: Import " +
                                        _vm._s(_vm.definition.type) +
                                        " records"
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "brand-medium font-primary ml-4 mt-5 font-18 text-left"
          },
          [_vm._v("Preview:")]
        ),
        _c("v-data-table", {
          key: _vm.refreshKey,
          attrs: {
            headers: _vm.headers,
            items: _vm.mappedData,
            "hide-default-footer": true,
            "items-per-page": -1
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }