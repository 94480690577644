var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "discovery-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _vm.isReady
          ? _c(
              "div",
              { staticClass: "ma-0 text-left" },
              [
                _c("div", { staticClass: "modal-title" }, [
                  _vm._v("Discovery Request")
                ]),
                _c("div", { staticClass: "modal-subtitle" }, [
                  _vm._v(_vm._s(_vm.subtitle))
                ]),
                _vm.discovery.completedTimestamp
                  ? _c("div", { staticClass: "body-12 gray_80--text" }, [
                      _vm._v(
                        " Completed " +
                          _vm._s(
                            _vm.$DateTime.shortDate(
                              _vm.discovery.completedTimestamp
                            )
                          ) +
                          " "
                      )
                    ])
                  : _c("div", { staticClass: "body-12 gray_80--text" }, [
                      _vm._v(" Not completed ")
                    ]),
                _vm._l(_vm.discovery.schema, function(item, j) {
                  return _c("div", { key: j, staticClass: "my-4" }, [
                    item.htmlHeader
                      ? _c(
                          "div",
                          { staticClass: "body-16 brand-medium gray_80--text" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatters.stripHtml(item.htmlHeader)
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "body-14 gray_80--text" }, [
                      _vm._v(_vm._s(_vm.$formatters.stripHtml(item.htmlText)))
                    ]),
                    _c("div", {
                      staticClass: "body-16 black--text mt-1",
                      domProps: { innerHTML: _vm._s(item.response) }
                    })
                  ])
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.confirmDeleteDiscovery(_vm.discovery)
                }
              }
            },
            [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$delete")])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-1",
              staticStyle: { "min-width": "120px" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.close")) + " ")]
          )
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": "Confirm",
          "yes-text": "Yes",
          "no-text": "No",
          "body-text": _vm.$t("discovery.delete-confirm-request")
        },
        on: {
          close: function($event) {
            return _vm.cancelDelete()
          },
          confirm: function($event) {
            return _vm.deleteDiscoveryRequest()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }