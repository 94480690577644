var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format text-left font-14 font-gray_70" },
    [
      _c("div", [_vm._v("Weight (px)")]),
      _c("v-slider", {
        attrs: {
          min: 1,
          max: 50,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.weight,
          callback: function($$v) {
            _vm.$set(_vm.element, "weight", $$v)
          },
          expression: "element.weight"
        }
      }),
      _c("div", [_vm._v("Width (%)")]),
      _c("v-slider", {
        attrs: {
          min: 1,
          max: 100,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.width,
          callback: function($$v) {
            _vm.$set(_vm.element, "width", $$v)
          },
          expression: "element.width"
        }
      }),
      _c("div", [_vm._v("Padding top")]),
      _c("v-slider", {
        attrs: {
          min: 0,
          max: 50,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.pt,
          callback: function($$v) {
            _vm.$set(_vm.element, "pt", $$v)
          },
          expression: "element.pt"
        }
      }),
      _c("div", [_vm._v("Padding bottom")]),
      _c("v-slider", {
        attrs: {
          min: 0,
          max: 50,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.pb,
          callback: function($$v) {
            _vm.$set(_vm.element, "pb", $$v)
          },
          expression: "element.pb"
        }
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-click": false,
            "close-on-content-click": false,
            bottom: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g(
                      {
                        staticClass: "pointer",
                        style:
                          "border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                          _vm.element.color
                      },
                      on
                    ),
                    [_vm._v("   ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.colorMenu,
            callback: function($$v) {
              _vm.colorMenu = $$v
            },
            expression: "colorMenu"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "ma-3 column-format align-center gap-3",
              staticStyle: { "background-color": "var(--v-white-base)" }
            },
            [
              _c("v-color-picker", {
                attrs: {
                  mode: "hexa",
                  "show-swatches": "",
                  "swatches-max-height": "150px"
                },
                on: { input: _vm.updated },
                model: {
                  value: _vm.element.color,
                  callback: function($$v) {
                    _vm.$set(_vm.element, "color", $$v)
                  },
                  expression: "element.color"
                }
              }),
              _c(
                "v-btn",
                {
                  attrs: { block: "" },
                  on: {
                    click: function($event) {
                      _vm.colorMenu = false
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }