<template>
  <div class="cardWrapper" v-if="ticket">
    <div class="card pa-4 mb-2">
      <div class="column-format flex-grow-1" style="justify-content: space-between">
        <div class="row-format align-center">
          <client-avatar small :client="client" v-if="client"></client-avatar>
          <v-icon class="material-symbols-rounded" v-else>confirmation_number</v-icon>
          <div class="font-12 column-format text-truncate" style="margin-left: 6px;">
            <div class="brand-bold">{{ client ? client.name : '' }}</div>
            <div class="mt-n1">{{ clientContact ? clientContact.fullName : 'wee wo' }}</div>
          </div>
        </div>
        <div>
          <v-row>
            <v-icon class=" mx-2 material-symbols-rounded">confirmation_number</v-icon>
            <div class="font-14 align-center">
              <div>{{ ticket.subject }}</div>
            </div>
            <div class="status row-format align-center ml-2">
              <div>{{ ticket.status }}</div>
            </div>
          </v-row>
        </div>
        <div class="row-format align-center" style="gap: 8px">
          <div class="font-12 font-grey row-format align-center" v-if="ticket.dueDate">
            {{ timeline }}
          </div>
          <div class="ml-auto row-format align-center gap-2">
            <div class="ml-auto row-format align-center">
              <assigned-user
                  v-for="(assignedTo, index) in ticket.assignedTo"
                  :key="assignedTo"
                  :show-name="false"
                  :small="true"
                  :assigned-to="assignedTo"
                  :style="
									`${
										index > 0 ? 'margin-left: -6px' : ''
									}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
								"
              ></assigned-user>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommunicatorTicketService from "@/modules/communicator/CommunicatorTicketService";
import ClientAvatar from "@/components/ClientAvatar.vue";
import DateTime from "@/modules/utils/HDateTime";
import AssignedUser from "@/components/AssignedUser.vue";
export default {
  name: 'TicketKanbanCard',

  props: ['event'],

  components: {AssignedUser, ClientAvatar},

  data: function () {
    return {
      ticket: null,
      id: null,
      client: null,
      clientContact: null,
      communicatorTicketService: new CommunicatorTicketService(),
    };
  },

  mounted() {
    this.id = this.event.meta ? this.event.meta.id : this.event.original.meta.id;
    this.initialize();
  },

  beforeDestroy() {},

  methods: {
    initialize: function () {
      this.communicatorTicketService.getTicketDetails(this.id)
          .then((res) => {
            console.log(res.data.ticket)
            this.ticket = res.data.ticket
            this.client = this.getClient(res.data.ticket.clientId)
            this.clientContact = this.getContactFromClient(res.data.ticket.clientId, res.data.ticket.clientContactId)
          })
          .catch((err) => {
            console.log(err);
          });

    },

    getClient: function(clientId) {
      return this.$store.getters.getClientById(clientId);
    },

    getContactFromClient: function(clientId, contactId) {
      let currContact = null;
      this.$store.getters.getContactsByClientId(clientId).forEach((contact) => {
        if(contact.clientPortalUserId === contactId) {
          currContact = contact
        }
      });
      return currContact
    },
  },

  computed: {
    timeline: function() {
      if(this.ticket.dueDate) {
        let due = DateTime.fromISO(this.ticket.dueDate).toLocaleString({
          month: 'short',
          day: 'numeric',
        });
        return `Due ${due}`;
      }
      else {
        return null;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.card-wrapper {
  padding: 8px 8px 8px 8px;
  &:active {
    cursor: grabbing;
  }
}

.card {
  background-color: var(--v-white-base);
  border: 1px solid var(--v-gray_30-base);
  box-sizing: border-box;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 160px;
  &:hover {
    border: 1px solid var(--v-gray_50-base);
  }
}

.blankCard {
  background-color: var(--v-white-base);
  height: 144px;
  border: 1px solid var(--v-gray_50-base);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.project-name {
  font-size: 12px;
  border: 1px solid var(--v-gray_30-base);
  color: var(--v-gray_80-base);
  padding: 0px 6px;
  border-radius: 4px;
  width: fit-content;
  //width: fit-content;
  //white-space: nowrap;
  //text-overflow: ellipsis;
}

.deliverable-name {
  font-size: 14px;
  color: var(--v-gray_90-base);
}

.task-box {
  padding: 2px 6px;
  border-radius: 2px;
  &:hover {
    background-color: var(--v-gray_10-base);
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.status {
  background-color: var(--v-accent-base);
  border-radius: 4px;
  color: var(--v-black-base);
  font-size: 12px;
  font-weight: 500;
}
</style>
