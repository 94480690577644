import HttpClient from '../../../services/HttpClient';

export default class TemplateLibraryService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getTemplatesOfType(type) {
		return this.httpClient
			.get(`/templates/library?type=${type}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createTemplateOfType(type,id) {
		return this.httpClient
			.post(`/templates/library/${id}?type=${type}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
