<template>
	<div class="text-left row-format pb-8" style="width: 100%; height: 100%;">
		<div :style="`width:${peopleVisible ? '70%' : 'calc(100% - 40px)'}`" class="px-4 pt-1 brand-box">
			<email-thread-detail
				v-if="communication.type === 'EmailThread'"
				:client-id="clientId"
				:thread="communication.payload"
				:user-emails="userEmails"
				:snooze-options="snoozeOptions"
				:folder="folder"
				:in-modal="inModal"
				:email-boxes="emailBoxes"
				:opportunity-id="opportunityId"
				@email-thread-updated="emailThreadUpdated($event)"
				@set-contacts="setContacts($event)"
				@back="$emit('back')"
			></email-thread-detail>
			<recent-call-detail
				v-if="communication.type === 'RecentCall'"
				:client-id="clientId"
				:call="communication.payload"
				:snooze-options="snoozeOptions"
				:folder="folder"
				:in-modal="inModal"
				@recent-call-updated="recentCallUpdated($event)"
				@set-contacts="setContacts($event)"
				@back="$emit('back')"
			></recent-call-detail>
			<message-thread-detail
				v-if="communication.type === 'MessageThread'"
				:client-id="clientId"
				:thread="communication.payload"
				:snooze-options="snoozeOptions"
				:folder="folder"
				:in-modal="inModal"
				@message-thread-update="messageThreadUpdated($event)"
				@set-contacts="setContacts($event)"
				@back="$emit('back')"
			></message-thread-detail>
		</div>
		<div v-if="peopleVisible" style="width: 30%; min-height: 100%" class="px-2 pt-2 ml-2 column-format brand-box">
			<div class="row-format align-center mb-3">
				<div class="brand-medium font-16">In this conversation</div>
				<v-icon class="ml-auto pointer" color="gray_90" @click="togglePeople">$close</v-icon>
			</div>
			<div class="" style="height: 100%">
				<people-in-thread
					:contacts="contacts"
					@view-communication="$emit('view-communication', $event)"
				></people-in-thread>
			</div>
		</div>
		<div
			v-else
			class="ml-2 pt-2 column-format pointer brand-box"
			@click="togglePeople"
			style="
				width: 30px;
				min-height: 100%;
				justify-content: flex-start;
				align-items: center;
			"
		>
			<v-icon color="black">$contacts</v-icon>
			<div
				class="row-format centered"
				style="width: 20px; height: 20px; border-radius: 10px; background-color: var(--v-blue_10-base)"
			>
				<div class="font-12 brand-medium">{{ contacts.length }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import EmailThreadDetail from '@/modules/communicator/inbox/email/EmailThreadDetail';
	import RecentCallDetail from '@/modules/communicator/inbox/calls/RecentCallDetail';
	import MessageThreadDetail from '@/modules/communicator/inbox/messages/MessageThreadDetail';
	import PeopleInThread from '@/modules/communicator/inbox/PeopleInThread';

	export default {
		name: 'DetailView',

		props: ['communication', 'userEmails', 'snoozeOptions', 'folder', 'inModal','ticketSettings','clientId','emailBoxes','opportunityId'],

		components: { PeopleInThread, RecentCallDetail, EmailThreadDetail, MessageThreadDetail },

		data: function () {
			return {
				showPeople: false,
				contacts: [],
			};
		},

		mounted() {
			this.getShowPeople();
		},

		beforeDestroy() {},

		methods: {
			getShowPeople: function () {
				let val = localStorage.getItem('COMMUNICATOR_SHOW_PEOPLE');
				if (val) {
					this.showPeople = val === 'true';
				}
			},

			togglePeople: function () {
				this.showPeople = !this.showPeople;
				localStorage.setItem('COMMUNICATOR_SHOW_PEOPLE', this.showPeople);
				console.log('setting show people', this.showPeople);
			},

			setContacts: function (contacts) {
				this.contacts.splice(0, this.contacts.length);
				this.contacts.push(...contacts);
			},

			emailThreadUpdated: function (thread) {
				this.$emit('email-thread-updated', thread);
			},

			recentCallUpdated: function (call) {
				this.$emit('recent-call-updated', call);
			},

			messageThreadUpdated: function (thread) {
				this.$emit('message-thread-updated', thread);
			},

			ticketUpdated: function(ticket){
				this.$emit('ticket-updated',ticket);
			}
		},

		watch: {},

		computed: {
			peopleVisible: function () {
				return this.contacts.length && this.showPeople;
			},
		},
	};
</script>

<style scoped lang="scss">
	.people-wrapper {
		height: 100%;
		border: 1px solid var(--v-gray_20-base);
		border-radius: 4px;
	}
</style>
