<template>
	<div class="column-format gap-2 py-3">
		<div class="row-format gap-1">
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pt" label="Padding top" @input="updated"></v-text-field>
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pb" label="Padding bottom" @input="updated"></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pl" label="Padding left" @input="updated"></v-text-field>
			<v-text-field hide-details dense outlined type="number" min="0" max="20" v-model="element.pr" label="Padding right" @input="updated"></v-text-field>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TextConfig',

		props: ['item','tokens'],

		components: {},

		data: function() {
			return {
				element: {... this.item},
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.element.text = this.item.text;
				this.$emit('change', this.element);
			},
		},

		computed: {

		},
	};
</script>

<style scoped lang="scss"></style>
