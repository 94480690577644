<template>
	<div :class="`${row ? 'row-format' : 'column-format'}`">
		<div v-for="item in sortedNotifications" :key="item.id" class="pb-2 pr-2" style="min-height: 100%">
			<component :key="item.notificationType" :is="item.notificationType" v-bind="item" style="min-height: 100%" />
		</div>

		<div v-if="sortedNotifications.length === 0" class="column-format centered mb-6 mt-6" style="align-self: center">
			<v-icon>$partyPopper</v-icon>
			<div class="font-gray_70">You're up to date!</div>
		</div>
	</div>
</template>

<script>
	import NotificationService from './NotificationService';
	import GenericNotification from './widgets/GenericNotification';
	import InvoiceApprovalNotification from './widgets/InvoiceApprovalNotification';
	import PaymentAppliedNotification from './widgets/PaymentAppliedNotification';
	import DiscoveryCompleteNotification from './widgets/DiscoveryCompleteNotification';
	import ProposalCommentNotification from './widgets/ProposalCommentNotification';
	import ProposalSignedNotification from './widgets/ProposalSignedNotification';
	import DeliverableAssignedNotification from './widgets/DeliverableAssignedNotification';
	import DeliverableCommentNotification from './widgets/DeliverableCommentNotification';
	import StripeAccountNotification from './widgets/StripeAccountNotification';
	import MeetingScheduledNotification from '@/modules/productivity/notifications/widgets/MeetingScheduledNotification';
	import DeliverableApprovedNotification from '@/modules/productivity/notifications/widgets/DeliverableApprovedNotification';
	import DeliverableAttachmentNotification from '@/modules/productivity/notifications/widgets/DeliverableAttachmentNotification';
	import PaymentFailedNotification from '@/modules/productivity/notifications/widgets/PaymentFailedNotification';
	import SubscriptionFailedNotification from '@/modules/productivity/notifications/widgets/SubscriptionFailedNotification';
	import SubscriptionCancelledNotification from '@/modules/productivity/notifications/widgets/SubscriptionCancelledNotification';
	import FormSubmissionNotification from '@/modules/productivity/notifications/widgets/FormSubmissionNotification';
	import ClientCreatedNotification from '@/modules/productivity/notifications/widgets/ClientCreatedNotification';
	import TicketCreatedNotification from "@/modules/productivity/notifications/widgets/TicketCreatedNotification";
	import TicketCommentNotification from "@/modules/productivity/notifications/widgets/TicketCommentNotification";
	import OpportunityCreatedNotification
		from "@/modules/productivity/notifications/widgets/OpportunityCreatedNotification";
	import TicketAssignedNotification from "@/modules/productivity/notifications/widgets/TicketAssignedNotification";
	import DeliverableUpdateNotification
		from "@/modules/productivity/notifications/widgets/DeliverableUpdateNotification";

	export default {
		name: 'Notifications',

		props: ['isVisible','row'],

		components: {
			GenericNotification,
			DiscoveryCompleteNotification,
			InvoiceApprovalNotification,
			PaymentAppliedNotification,
			ProposalCommentNotification,
			ProposalSignedNotification,
			DeliverableCommentNotification,
			DeliverableAssignedNotification,
			StripeAccountNotification,
			MeetingScheduledNotification,
			DeliverableApprovedNotification,
			DeliverableUpdateNotification,
			PaymentFailedNotification,
			DeliverableAttachmentNotification,
			SubscriptionFailedNotification,
			SubscriptionCancelledNotification,
			FormSubmissionNotification,
			ClientCreatedNotification,
			TicketCreatedNotification,
			TicketCommentNotification,
			TicketAssignedNotification,
			OpportunityCreatedNotification
		},

		data: function () {
			return {
				notificationService: new NotificationService(),
			};
		},

		mounted() {
		},

		beforeDestroy() {
		},

		methods: {
			deleteAll: function () {
				this.notificationService.deleteNotifications();
			},

			markAllAsRead: function () {
				this.notificationService.markAllAsRead();
			},


			/* ~~New API~~ insert whichever notification type you need to procure
			as the argument to this.notificationService.testNotification()
			ie - "DeliverableAssignedNotification" */
			testNotification() {
				this.notificationService.testNotification('StripeAccountNotification');
			},


			sortFunction: function (a, b) {
				return b.sentAt.localeCompare(a.sentAt);
			},
		},

		computed: {

			notifications: function () {
				return this.$store.state.productivity.notifications;
			},

			sortedNotifications: function () {
				let newArray = [...this.$store.state.productivity.notifications];
				newArray.sort(this.sortFunction);
				return newArray;
			},

			unreadNotificationCount: function () {
				return this.$store.getters.unreadNotificationCount;
			},
		},

		watch: {
			isVisible: {
				immediate: true,
				handler(newVal) {
					if (newVal && this.unreadNotificationCount > 0) {
						this.markAllAsRead();
					}
				},
			}
		},
	};
</script>

<style scoped lang="scss">
	.rule {
		border-top: 1px solid var(--v-gray_50-base);
		border-bottom: none;
	}
</style>
