export default class Template {
	constructor() {
		//if (phase) this.phase = phase.toLowerCase();
	}

	static getLastViewed() {
		let lv = localStorage.getItem('TEMPLATES_LAST_VIEWED');
		if (!lv) {
			this.setLastViewed('Packages');
			return 'Packages';
		}
		return lv;
	}

	static setLastViewed(view) {
		localStorage.setItem('TEMPLATES_LAST_VIEWED', view);
	}
}
