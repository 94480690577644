var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        [
          _c("div", { staticClass: "row-format align-start" }, [
            _c(
              "div",
              { staticClass: "column-format" },
              [
                _c("div", { staticClass: "font-gray_80 mb-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "connected-apps." +
                          _vm.integrationType.toLowerCase() +
                          ".desc"
                      )
                    )
                  )
                ]),
                _vm.isConnected
                  ? _c("connected")
                  : _c(
                      "div",
                      {
                        staticClass: "font-primary brand-medium pointer",
                        on: { click: _vm.connectApi }
                      },
                      [
                        _vm._v(
                          "Enable " +
                            _vm._s(_vm.integrationType) +
                            " Integration"
                        )
                      ]
                    )
              ],
              1
            ),
            _vm.isConnected
              ? _c(
                  "div",
                  {
                    staticClass: "ml-auto pointer",
                    on: {
                      click: function($event) {
                        _vm.expanded = !_vm.expanded
                      }
                    }
                  },
                  [
                    _c("v-icon", [
                      _vm._v(
                        _vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown")
                      )
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.isConnected && _vm.expanded
            ? _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _vm._l(_vm.connections, function(conn, index) {
                    return _c(
                      "div",
                      {
                        key: conn.id,
                        staticClass: "pt-5 pb-5 mb-5",
                        staticStyle: {
                          "border-top": "1px solid var(--v-gray_50-base)"
                        }
                      },
                      [
                        _c("input", {
                          attrs: {
                            id: "key" + index,
                            name: "key" + index,
                            type: "hidden"
                          },
                          domProps: { value: conn.apiKey }
                        }),
                        _c("input", {
                          attrs: {
                            id: "platform" + index,
                            name: "platform" + index,
                            type: "hidden"
                          },
                          domProps: {
                            value:
                              _vm.type === "Zapier" ? _vm.platform : _vm.baseUrl
                          }
                        }),
                        _c(
                          "v-container",
                          { staticClass: "font-14 ma-0 pa-0" },
                          [
                            _c(
                              "v-row",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "2", align: "left" } },
                                  [_vm._v(" API Key: ")]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "font-gray_70",
                                    attrs: { cols: "9", align: "left" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "word-wrap": "anywhere",
                                          "overflow-wrap": "break-word"
                                        }
                                      },
                                      [_vm._v(_vm._s(conn.apiKey))]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "1", align: "right" } },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.copy(conn.apiKey)
                                          }
                                        }
                                      },
                                      [_vm._v("$duplicate")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "2", align: "left" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.type === "Zapier"
                                            ? "Platform"
                                            : "Base URL"
                                        ) +
                                        ": "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "font-gray_70",
                                    attrs: { cols: "9", align: "left" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.type === "Zapier"
                                            ? _vm.platform
                                            : _vm.baseUrl
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "1", align: "right" } },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.copy(
                                              _vm.type === "Zapier"
                                                ? _vm.platform
                                                : _vm.baseUrl
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("$duplicate")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [_c("v-col", { attrs: { cols: "12" } })],
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "py-3" }, [_vm._v(" ")]),
                        _vm.type === "Public"
                          ? _c(
                              "div",
                              { staticClass: "column-format gap-2" },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: [
                                      "None",
                                      "Basic",
                                      "Bearer",
                                      "Custom"
                                    ],
                                    label: "Rest hook authentication type",
                                    dense: "",
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    outlined: ""
                                  },
                                  model: {
                                    value: conn.restHookAuth.authType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        conn.restHookAuth,
                                        "authType",
                                        $$v
                                      )
                                    },
                                    expression: "conn.restHookAuth.authType"
                                  }
                                }),
                                conn.restHookAuth.authType === "Basic"
                                  ? _c(
                                      "div",
                                      { staticClass: "row-format gap-2" },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "User name",
                                            dense: "",
                                            "hide-details": "",
                                            "persistent-placeholder": "",
                                            outlined: ""
                                          },
                                          model: {
                                            value: conn.restHookAuth.username,
                                            callback: function($$v) {
                                              _vm.$set(
                                                conn.restHookAuth,
                                                "username",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "conn.restHookAuth.username"
                                          }
                                        }),
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Password",
                                            dense: "",
                                            "hide-details": "",
                                            "persistent-placeholder": "",
                                            outlined: "",
                                            type: "password"
                                          },
                                          model: {
                                            value: conn.restHookAuth.password,
                                            callback: function($$v) {
                                              _vm.$set(
                                                conn.restHookAuth,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "conn.restHookAuth.password"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                conn.restHookAuth.authType === "Bearer"
                                  ? _c(
                                      "div",
                                      { staticClass: "row-format gap-2" },
                                      [
                                        _c("v-textarea", {
                                          attrs: {
                                            label: "Bearer token",
                                            dense: "",
                                            "hide-details": "",
                                            "persistent-placeholder": "",
                                            outlined: "",
                                            rows: "3",
                                            "no-resize": ""
                                          },
                                          model: {
                                            value: conn.restHookAuth.value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                conn.restHookAuth,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "conn.restHookAuth.value"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                conn.restHookAuth.authType === "Custom"
                                  ? _c(
                                      "div",
                                      { staticClass: "row-format gap-3" },
                                      [
                                        _c("v-text-field", {
                                          staticStyle: { "max-width": "200px" },
                                          attrs: {
                                            label: "HTTP header name",
                                            dense: "",
                                            "hide-details": "",
                                            "persistent-placeholder": "",
                                            outlined: ""
                                          },
                                          model: {
                                            value: conn.restHookAuth.headerName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                conn.restHookAuth,
                                                "headerName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "conn.restHookAuth.headerName"
                                          }
                                        }),
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "HTTP header value",
                                            dense: "",
                                            "hide-details": "",
                                            "persistent-placeholder": "",
                                            outlined: ""
                                          },
                                          model: {
                                            value: conn.restHookAuth.value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                conn.restHookAuth,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "conn.restHookAuth.value"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        conn.hooks.length
                          ? _c(
                              "v-container",
                              {
                                staticClass: "mt-2 gap-2 column-format font-14"
                              },
                              _vm._l(conn.hooks, function(hook) {
                                return _c(
                                  "v-row",
                                  {
                                    key: hook.id,
                                    staticClass: "py-2",
                                    staticStyle: {
                                      border: "1px solid var(--v-gray_30-base)",
                                      "border-radius": "4px"
                                    }
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(_vm._s(hook.label))
                                    ]),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: {
                                          "word-break": "break-word"
                                        },
                                        attrs: { cols: "6" }
                                      },
                                      [_vm._v(_vm._s(hook.hookUrl))]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: {
                                          "word-break": "break-word"
                                        },
                                        attrs: { cols: "2" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.DateTime.fromISO(
                                              hook.statusTime
                                            ).toLocaleString(
                                              _vm.DateTime.DATETIME_SHORT
                                            )
                                          ) +
                                            " - " +
                                            _vm._s(hook.statusMessage)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "row-format align-center",
                                        attrs: { cols: "2" }
                                      },
                                      [
                                        _c("v-switch", {
                                          staticClass: "ml-auto mt-n1",
                                          attrs: {
                                            "hide-details": "",
                                            dense: ""
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.updateHookStatus(
                                                conn,
                                                hook
                                              )
                                            }
                                          },
                                          model: {
                                            value: hook.enabled,
                                            callback: function($$v) {
                                              _vm.$set(hook, "enabled", $$v)
                                            },
                                            expression: "hook.enabled"
                                          }
                                        }),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "material-symbols-rounded pointer",
                                            attrs: { size: "20" },
                                            on: {
                                              click: function($event) {
                                                return _vm.confirmDelete(
                                                  conn,
                                                  hook
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("delete")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "row-format mt-4 gap-2" },
                          [
                            _vm.canAddRestHooks
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "super-action",
                                    on: {
                                      click: function($event) {
                                        return _vm.saveRestHookAuth(conn)
                                      }
                                    }
                                  },
                                  [_vm._v("Save")]
                                )
                              : _vm._e(),
                            _vm.canAddRestHooks
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary-action",
                                    on: {
                                      click: function($event) {
                                        return _vm.newRestHook(conn)
                                      }
                                    }
                                  },
                                  [_vm._v("Add REST hook")]
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                staticClass: "delete",
                                on: {
                                  click: function($event) {
                                    return _vm.disconnectApi(conn)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "material-symbols-rounded" },
                                  [_vm._v("delete")]
                                ),
                                _vm._v(" Delete connection")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "pt-16" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", large: "", color: "gray_80" },
                          on: {
                            click: function($event) {
                              return _vm.connectApi()
                            }
                          }
                        },
                        [_vm._v("Create additional API key")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e(),
          _vm.addRestHook
            ? _c("basic-modal", { attrs: { dialog: _vm.addRestHook } }, [
                _c(
                  "div",
                  {
                    staticClass: "rest-hook-create-div",
                    staticStyle: { "min-width": "500px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row-format" },
                      [
                        _c("div", { staticClass: "pt-3 pl-4" }, [
                          _vm._v(" Add new REST hook ")
                        ]),
                        _c(
                          "v-btn",
                          {
                            staticClass: "close-dialog",
                            attrs: { icon: "", rounded: "" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.addRestHook = false
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { size: "20" } }, [
                              _vm._v("$close")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "modal-padding column-format gap-3" },
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.hookTypes,
                            "item-text": "label",
                            "item-value": "type",
                            dense: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: "Event type",
                            outlined: ""
                          },
                          model: {
                            value: _vm.newHookType,
                            callback: function($$v) {
                              _vm.newHookType = $$v
                            },
                            expression: "newHookType"
                          }
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Endpoint",
                            placeholder: "https://www.domain.com/endpoint",
                            dense: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            outlined: ""
                          },
                          model: {
                            value: _vm.newHookUrl,
                            callback: function($$v) {
                              _vm.newHookUrl = $$v
                            },
                            expression: "newHookUrl"
                          }
                        }),
                        _c(
                          "v-btn",
                          {
                            staticClass: "super-action",
                            attrs: { disabled: !_vm.isValid },
                            on: {
                              click: function($event) {
                                return _vm.saveNewRestHook()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("global.save")))]
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }