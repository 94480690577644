var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [{ to: "/home", label: "Home" }, { label: "Forms" }]
          }
        },
        [_vm._v(" Submissions ")]
      ),
      _c(
        "div",
        { staticClass: "row-format gap-3 mb-4" },
        [
          _c("v-text-field", {
            staticStyle: {
              "max-width": "200px",
              "background-color": "var(--v-white-base)"
            },
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search...",
              color: "gray_30"
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "material-symbols-rounded font-gray_50" },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.filter.search,
              callback: function($$v) {
                _vm.$set(_vm.filter, "search", $$v)
              },
              expression: "filter.search"
            }
          }),
          _c("discovery-list-filter", {
            attrs: { slot: "filter" },
            on: {
              "clear-filter": function($event) {
                return _vm.clearFilter()
              }
            },
            slot: "filter",
            model: {
              value: _vm.filter,
              callback: function($$v) {
                _vm.filter = $$v
              },
              expression: "filter"
            }
          })
        ],
        1
      ),
      _c("form-submissions-table", { attrs: { filter: _vm.filter } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }