<template>
	<div class="pb-8">
		<input id="copyInput" name="copyInput" type="hidden" />

		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Meetings' }]">
			Meeting schedulers
		</page-header>

		<div class="row-format mb-4">
			<v-text-field
					outlined
					hide-details
					dense
					v-model="search"
					placeholder="Search..."
					style="max-width: 200px; background-color: var(--v-white-base)"
					color="gray_30"
			>
				<template v-slot:prepend-inner
				><span class="material-symbols-rounded font-gray_50">search</span></template
				>
			</v-text-field>
			<v-btn class="ml-auto super-action" @click="handleAddNew()"><v-icon size="20">add</v-icon> Add scheduler</v-btn>
		</div>

		<v-data-table
			:key="tableKey"
			:headers="headers"
			:items="filteredMeetings"
			@click:row="openMeeting($event.id)"
			:fixed-header="true"
			style="cursor: pointer"
			:items-per-page="-1"
			:hide-default-footer="true"
			group-by="folder"
		>
			<template v-slot:group.header="{ group, headers, toggle }">
				<td colspan="6" class="text-left" @click="toggle">
					<div class="row-format align-center gap-2">
						<v-icon class="" size="20" color="secondary">folder</v-icon>{{ group ? group : ' -- ' }}
					</div>
				</td>
			</template>

			<template v-slot:item.name="{ item }">
				<span>{{ item.name }}</span>
			</template>

			<template v-slot:item.active="{ item }">
				<span>{{ $formatters.yesNoFromBoolean(item.active) }}</span>
			</template>

			<template v-slot:item.duration="{ item }">
				<span v-if="item.meetingLength"
					>{{ item.meetingLength.duration }} {{ item.meetingLength.timeUnit.toLowerCase() }}</span
				>
				<span v-else>--</span>
			</template>

			<template v-slot:item.location="{ item }">
				<div v-if="item.location && item.location.type === 'Zoom' && !getZoomUser(item)" class="row-format">
					<v-icon color="warning">$alert</v-icon>
					<div class="ml-2" style="color: var(--v-warning-base)">
						The Zoom account used in this scheduler is current disconnected. Please reconnect or choose a different
						meeting location.
					</div>
				</div>
				<div v-else-if="item.location">
					{{ item.location.type }}
				</div>
				<div v-else>--</div>
			</template>

			<template v-slot:item.ownerUserId="{ item }">
				<assigned-user :assigned-to="item.ownerUserId" :show-name="false"></assigned-user>
			</template>

			<template v-slot:item.action="{ item }">
				<div class="row-format gap-2">
					<v-icon class="ml-auto" @click.stop="copyToClipboard(item)" v-tippy="{content:'Copy scheduler link'}">link</v-icon>
					<meeting-action-menu
						@view="openMeeting(item.id)"
						@delete="confirmDelete(item)"
						@duplicate="duplicateScheduler(item)"
					></meeting-action-menu>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import MeetingDetail from '@/modules/meetings/SchedulerDetail';
	import MeetingService from '@/modules/meetings/MeetingService';
	import MeetingActionMenu from '@/modules/meetings/MeetingActionMenu';
	import ConfirmModal from '@/components/ConfirmModal';
	import ClearSampleModal from '@/modules/clients/ClearSampleModal';
	import ZoomAuthService from '@/modules/integrations/zoom/ZoomAuthService';
	import AssignedUser from '@/components/AssignedUser';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'SchedulerList',

		props: ['v'],

		components: { AssignedUser, MeetingActionMenu, PageHeader },

		data: function() {
			return {
				meetingService: new MeetingService(),
				zoomAuthService: new ZoomAuthService(),
				tableKey: 0,
				meetings: [],
				zoomUsers: [],
				isReady: true,
				search: null,

				pages: [
					{ label: 'Meeting schedulers', value: 'schedulers' },
					{ label: 'Forms', value: 'forms' },
				],
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'scheduler-list' });
			this.initialize();
			this.$store.state.eventBus.$on('account-changed', this.initialize);
			this.$store.state.eventBus.$on('schedulers_onboarding', this.handleOnboardingRequest);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.initialize);
			this.$store.state.eventBus.$off('schedulers_onboarding', this.handleOnboardingRequest);
		},

		methods: {
			handleOnboardingRequest: function() {
				this.handleAddNew();
			},

			initialize: function() {
				this.getMeetingList();
				this.getConnectedZoomUsers();
			},

			setView: function(view) {
				this.$router.push('/pages/' + view);
			},

			getMeetingList: function() {
				this.meetingService.getMeetingList().then((res) => {
					this.meetings.splice(0, this.meetings.length);
					this.meetings.push(...res.data);
				});
			},

			getZoomUser: function(meeting) {
				return this.zoomUsers.find((z) => z.userId === meeting.location.zoomUserId);
			},

			openMeeting: function(id) {
				let binding = {
					id: id,
					usedNames: this.getOtherMeetingNames(id),
					folders: this.folders,
				};
				this.$store.state.globalModalController.openModal(MeetingDetail, binding, false, true).then((res) => {
					if (res === 'UPGRADE') {
						setTimeout(() => this.$router.push('/subscription'), 500);
					} else {
						this.getMeetingList();
					}
				});
			},

			getConnectedZoomUsers: function() {
				new ZoomAuthService().getUserList().then((res) => {
					this.zoomUsers.splice(0, this.zoomUsers.length);
					this.zoomUsers.push(...res.data);
				});
			},

			handleAddNew: function() {
				if (this.sampleMode) {
					let binding = {
						header: 'Are you finished exploring with sample data?',
						body:
							'You cannot add or remove schedulers while in sample mode. If you are ready to add your own scheduler, you can clear out the sample data to get started.',
					};
					this.$store.state.globalModalController.openModal(ClearSampleModal, binding);
				} else {
					let name = '30 minute meeting';
					let finalName = name;
					let ix = 1;
					let names = [];
					this.meetings.forEach((m) => names.push(m.name));

					while (names.includes(finalName)) {
						finalName = name + ' ' + ix++;
					}

					let uniqueMeetingId = finalName
						.replaceAll(' ', '-')
						.toLowerCase()
						.replaceAll('[^a-zA-Z0-9-]', '');

					this.meetingService.initializeMeeting(finalName, uniqueMeetingId).then((res) => {
						this.$onBoarding.track('schedulers_create_scheduler');
						this.openMeeting(res.data.id);
					});
				}
			},

			duplicateScheduler: function(schedulerToCopy) {
				if (this.sampleMode) {
					let binding = {
						header: 'Are you finished exploring with sample data?',
						body:
							'You cannot add or remove schedulers while in sample mode. If you are ready to add your own scheduler, you can clear out the sample data to get started.',
					};
					this.$store.state.globalModalController.openModal(ClearSampleModal, binding);
				} else {
					let name = schedulerToCopy.name;
					let finalName = name;
					let ix = 1;
					let names = [];
					this.meetings.forEach((m) => names.push(m.name));

					while (names.includes(finalName)) {
						finalName = name + ' ' + ix++;
					}

					let scheduler = JSON.parse(JSON.stringify(schedulerToCopy));
					scheduler.id = null;
					scheduler.name = finalName;
					scheduler.uniqueMeetingId = finalName
						.replaceAll(' ', '-')
						.toLowerCase()
						.replaceAll('[^a-zA-Z0-9-]', '');
					this.meetingService.createMeeting(scheduler).then((res) => {
						this.openMeeting(res.data.id);
					});
				}
			},

			getOtherMeetingNames: function(id) {
				let result = [];
				this.meetings
					.filter((m) => m.id !== id)
					.forEach((m) =>
						result.push(
							m.name
								.replaceAll(' ', '-')
								.toLowerCase()
								.replaceAll('[^a-zA-Z0-9-]', '')
						)
					);
				return result;
			},

			confirmDelete: function(meeting) {
				let binding = {
					headingText: 'Delete meeting scheduler?',
					bodyText: `Are you sure you want to delete the "${meeting.name}" meeting scheduler?  This cannot be undone.`,
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.meetingService
							.deleteMeeting(meeting.id)
							.then(() => {
								this.getMeetingList();
							})
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			copyToClipboard(meeting) {
				navigator.clipboard.writeText(this.accountBaseLink + meeting.uniqueMeetingId)
        this.$store.commit('info', 'Copied to clipboard');
			},
		},

		computed: {
			folders: function() {
				return this.meetings
					.filter((e) => e.folder)
					.map((e) => e.folder)
					.filter((value, index, self) => self.indexOf(value) === index);
			},

			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},

			headers: function() {
				return [
					{ text: this.$t('meeting.name'), value: 'name' },
					{ text: this.$t('meeting.active'), value: 'active' },
					{ text: this.$t('meeting.duration'), value: 'duration' },
					{ text: this.$t('meeting.location'), value: 'location' },
					{ text: this.$t('meeting.owner'), value: 'ownerUserId' },
					{ text: '', value: 'action', sortable: false, align: 'right' },
				];
			},

			accountBaseLink: function(){
				let commPref = this.$store.state.communicationPreferences;
				if(commPref && commPref.primaryDomain){
					return 'https://' + commPref.primaryDomain + '/public/';
				}else {
					return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' ;
				}
			},

			filteredMeetings: function() {
				if (this.search) {
					return this.meetings.filter((s) => {
						return s.name.toLowerCase().includes(this.search.toLowerCase());
					});
				} else {
					return this.meetings;
				}
			},
		},
	};
</script>

<style lang="scss"></style>
