var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-2" },
    [
      _c("div", [_vm._v("Width (" + _vm._s(_vm.element.width) + "px)")]),
      _c("v-slider", {
        attrs: {
          min: 50,
          max: 800,
          step: 10,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.width,
          callback: function($$v) {
            _vm.$set(_vm.element, "width", $$v)
          },
          expression: "element.width"
        }
      }),
      _c("v-select", {
        attrs: {
          items: _vm.alignment,
          "item-text": "label",
          "item-value": "value",
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Alignment"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.alignment,
          callback: function($$v) {
            _vm.$set(_vm.element, "alignment", $$v)
          },
          expression: "element.alignment"
        }
      }),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          type: "number",
          label: "Padding"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.padding,
          callback: function($$v) {
            _vm.$set(_vm.element, "padding", $$v)
          },
          expression: "element.padding"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Margin top"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pt,
              callback: function($$v) {
                _vm.$set(_vm.element, "pt", $$v)
              },
              expression: "element.pt"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Margin bottom"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pb,
              callback: function($$v) {
                _vm.$set(_vm.element, "pb", $$v)
              },
              expression: "element.pb"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Margin left"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pl,
              callback: function($$v) {
                _vm.$set(_vm.element, "pl", $$v)
              },
              expression: "element.pl"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Margin right"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pr,
              callback: function($$v) {
                _vm.$set(_vm.element, "pr", $$v)
              },
              expression: "element.pr"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Border width"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.borderWidth,
              callback: function($$v) {
                _vm.$set(_vm.element, "borderWidth", $$v)
              },
              expression: "element.borderWidth"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Border radius"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.borderRadius,
              callback: function($$v) {
                _vm.$set(_vm.element, "borderRadius", $$v)
              },
              expression: "element.borderRadius"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "row-format text-left gap-1" }, [
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", { staticClass: "font-14" }, [_vm._v("Background color")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.element.backgroundColor,
                callback: function($$v) {
                  _vm.$set(_vm.element, "backgroundColor", $$v)
                },
                expression: "element.backgroundColor"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", { staticClass: "font-14" }, [_vm._v("Border color")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.element.borderColor,
                callback: function($$v) {
                  _vm.$set(_vm.element, "borderColor", $$v)
                },
                expression: "element.borderColor"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }