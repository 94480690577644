var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "item " +
        (_vm.active && !_vm.inContainer ? "item-active" : "item-inactive"),
      attrs: { id: "itemRenderer-" + _vm.item.id }
    },
    [
      !_vm.active && _vm.editMode && !_vm.inContainer
        ? _c(
            "v-menu",
            {
              attrs: { "max-width": "700" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c("div", { staticClass: "line-container" }, [
                          _c("div", { staticClass: "line" }),
                          _c("div", _vm._g({ staticClass: "icon" }, on), [
                            _vm._v("+")
                          ])
                        ])
                      ]
                    }
                  }
                ],
                null,
                false,
                2738191429
              )
            },
            [
              _c(
                "div",
                {
                  staticClass: "pl-3 pt-3 pb-3",
                  staticStyle: { "background-color": "var(--v-white-base)" }
                },
                [
                  _c("element-types", {
                    attrs: { "disable-drag": true },
                    on: {
                      selected: function($event) {
                        return _vm.$emit("add-element-above", $event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.active && !_vm.inContainer
        ? _c(
            "div",
            { staticClass: "builder-control" },
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { content: "Move Up" },
                      expression: "{ content: 'Move Up' }"
                    }
                  ],
                  attrs: { icon: "", text: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("move-up")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { size: "20", color: "white" }
                    },
                    [_vm._v("keyboard_arrow_up")]
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { content: "Move Down" },
                      expression: "{ content: 'Move Down' }"
                    }
                  ],
                  attrs: { icon: "", text: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("move-down")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { size: "20", color: "white" }
                    },
                    [_vm._v("keyboard_arrow_down")]
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: { content: "Delete section" },
                      expression: "{ content: 'Delete section' }"
                    }
                  ],
                  attrs: { icon: "", text: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$emit("delete")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded",
                      attrs: { size: "20", color: "white" }
                    },
                    [_vm._v("delete")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(_vm.item.type, {
        key: _vm.item.type,
        ref: "component",
        tag: "component",
        attrs: {
          item: _vm.item,
          agreement: _vm.agreement,
          active: _vm.active,
          "account-logo": _vm.accountLogo,
          index: _vm.index,
          tokens: _vm.tokens,
          "mce-config": _vm.mceConfig,
          "client-mode": _vm.clientMode,
          "edit-mode": _vm.editMode,
          signer: _vm.signer,
          "template-mode": _vm.templateMode,
          "page-width": _vm.pageWidth,
          "is-mobile": _vm.isMobile
        },
        on: {
          click: function($event) {
            return _vm.$emit("click", $event)
          },
          delete: function($event) {
            return _vm.$emit("delete")
          },
          change: function($event) {
            return _vm.$emit("change", $event)
          },
          signed: function($event) {
            return _vm.handleSigned($event)
          },
          "drag-enabled": function($event) {
            return _vm.$emit("drag-enabled", $event)
          },
          "container-item-click": function($event) {
            return _vm.$emit("container-item-click", $event)
          },
          "container-item-update": function($event) {
            return _vm.$emit("container-item-update", $event)
          },
          "container-item-signed": function($event) {
            return _vm.$emit("container-item-signed", $event)
          },
          "add-container-element": function($event) {
            return _vm.$emit("add-container-element", $event)
          }
        }
      }),
      !_vm.active && _vm.editMode && !_vm.inContainer
        ? _c(
            "v-menu",
            {
              attrs: { "max-width": "700" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c("div", { staticClass: "line-container" }, [
                          _c("div", { staticClass: "line" }),
                          _c("div", _vm._g({ staticClass: "icon" }, on), [
                            _vm._v("+")
                          ])
                        ])
                      ]
                    }
                  }
                ],
                null,
                false,
                2738191429
              )
            },
            [
              _c(
                "div",
                {
                  staticClass: "pl-3 pt-3 pb-3",
                  staticStyle: { "background-color": "var(--v-white-base)" }
                },
                [
                  _c("element-types", {
                    attrs: { "disable-drag": true },
                    on: {
                      selected: function($event) {
                        return _vm.$emit("add-element-below", $event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }