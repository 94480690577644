import HttpClient from '@/services/HttpClient';

export default class XeroService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getConnection() {
		return this.httpClient
			.get('/xero')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	saveSettings(xeroOAuth){
		return this.httpClient
			.put('/xero',xeroOAuth)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTaxRates(){
		return this.httpClient
			.get('/xero/taxRates')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getAccounts(){
		return this.httpClient
			.get('/xero/accounts')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	initializeOAuth(redirectUri) {
		return this.httpClient
			.get('/xero/oauth', {
				params: {
					redirectUri: redirectUri,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	exchangeToken(redirectUri,code) {
		return this.httpClient
			.post('/xero/oauth', null, {
				params: {
					redirectUri: redirectUri,
					code:code,
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	revoke() {
		return this.httpClient
			.delete(`/xero/oauth`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	reset() {
		return this.httpClient
			.post(`/xero/reset`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
