<template>
	<div>
		<input type="hidden" v-model="subscription_id" ref="subscription_id" df-subscription_id />
		<input type="hidden" v-model="user_id" ref="user_id" df-user_id />
		<input type="hidden" v-model="from" ref="from" df-from />
		<input type="hidden" v-model="message" ref="message" df-message />
		<input type="hidden" v-model="to" ref="to" df-to />

		<node-label @open="open()">
			<template v-slot:default>
				<v-icon class="material-symbols-rounded mr-1" color="black" size="20">chat</v-icon>
				<div>Send text message</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" title="Send text">
			<template v-slot:default>
				<div class="row-format" style="min-width: 650px">
					<div  style="min-width: 450px">
						<v-select
								hide-details
								dense
								outlined
								persistent-placeholder
								label="From"
								:items="subscriptions"
								item-value="id"
								v-model="subscription_id"
								@change="setUserFromSubscription()"
						>
							<template v-slot:item="{item}">
								<div>{{item.phone}} - {{item.user.firstName}} {{item.user.lastName}}</div>
							</template>
							<template v-slot:selection="{item}">
								<div>{{item.phone}} - {{item.user.firstName}} {{item.user.lastName}}</div>
							</template>
						</v-select>

						<contact-selector class="mt-3"  v-if="isReady" :multiple="false" label="To" :to-list="to" @change="updateToList($event)"></contact-selector>

						<div class="row-format mt-3">
							<v-textarea
									dense
									hide-details
									outlined
									rows="10"
									persistent-placeholder
									label="Message"
									v-model="message"
							></v-textarea>
						</div>
					</div>
					<div class="pl-3" style="max-width: 200px" :key="refreshKey">
						<div class="row-format font-14">
							<v-icon v-tippy content="Tokens">find_replace</v-icon>
							<div class="font-gray_80">Tokens</div>
						</div>
						<div v-for="category in tokens" :key="category.category" class="font-14 text-left">
							<ul>
								<li @click="toggleCategory(category)">
									<div class="brand-medium pointer">
										{{ category.category }}
										<v-icon color="black" size="12">{{ category.expanded ? '$arrowUp' : '$arrowDown' }}</v-icon>
									</div>
									<ul v-if="category.expanded" style="padding-left: 12px">
										<li
												class="token-link pointer"
												v-for="token in category.tokens"
												:key="category.category + token.value"
												@click.stop="insert(token.value)"
										>
											{{ token.label }}
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>

			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeMixin from '@/modules/workflow/nodes/NodeMixin';
	import NodeModal from '@/modules/workflow/nodes/NodeModal';
	import NodeLabel from '@/modules/workflow/nodes/NodeLabel';
	import ContactSelector from "@/modules/workflow/nodes/components/ContactSelectorPhone";
	import CommunicatorService from "@/modules/communicator/CommunicatorService";
	import TemplateMixin from "@/modules/templates/emails/TemplateMixin";

	export default {
		name: 'SendText',
		inputs: 1,
		outputs: 1,

		mixins: [NodeMixin, TemplateMixin],

		props: [],

		components: { NodeLabel, NodeModal, ContactSelector },

		data: function() {
			return {
				subscription_id: null,
				user_id: null,
				from: null,
				message: null,
				to: null,

				subscriptions: [],
				isReady: false,
				communicatorService: new CommunicatorService(),
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.subscription_id = this.$refs.subscription_id.value;
				this.user_id = this.$refs.user_id.value;
				this.from = this.$refs.from.value;
				this.message = this.$refs.message.value;
				this.to = this.$refs.to.value;
				this.isReady = true
			});
		},

		beforeDestroy() {},

		methods: {
			open: function(){
				this.initTokens();

				this.communicatorService.getSubscriptionsForAccount().then((res) => {
					this.subscriptions.splice(0,this.subscriptions.length);
					res.data.forEach(s => {
						this.subscriptions.push({
							id: s.id,
							userId: s.userId,
							user: this.$store.getters.getUserById(s.userId),
							phone: s.phone
						})
					});
					this.$refs.nodeModal.open();
				})
			},

			setUserFromSubscription: function(){
				if(this.subscription_id) {
					let subscription = this.subscriptions.find(e => e.id === this.subscription_id);
					this.user_id = subscription.userId;
					this.from = subscription.phone;
				}else{
					this.user_id = null;
					this.from = null;
				}
			},

			insert: function(text) {
				this.message = this.message + ' {{' + text + '}}';
			},

			updateToList: function(to){
				this.to = to;
			},

		},

		watch: {
			subscription_id: function() {
				this.emitInput('subscription_id');
			},

			user_id: function(){
				this.emitInput('user_id');
			},

			from: function(){
				this.emitInput('from');
			},

			message: function() {
				this.emitInput('message');
			},

			to: function() {
				this.emitInput('to');
			},
		},

		computed: {
		},
	};
</script>

<style scoped lang="scss"></style>
