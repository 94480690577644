var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "pointer",
        attrs: {
          items: _vm.recurringDeliverables,
          "hide-default-footer": true,
          "items-per-page": -1,
          headers: _vm.headers
        },
        on: {
          "click:row": function($event) {
            return _vm.openRecurringDeliverable($event.id)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.interval",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm.formatInterval
                  ? _c(
                      "div",
                      { staticClass: "row-format align-center mr-2" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticStyle: {
                              "margin-bottom": "1px",
                              "margin-right": "4px"
                            },
                            attrs: { size: "14" }
                          },
                          [_vm._v("autorenew")]
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.getLabel(item)))])
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.subtasks",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.taskCountLabel(item.deliverable.deliverable)) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.nextRunTime",
            fn: function(ref) {
              var item = ref.item
              return [
                item.nextRunTime
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.nextRunTime).toLocaleString(
                            _vm.DateTime.DATE_MED_WITH_WEEKDAY
                          )
                        )
                      )
                    ])
                  : _c("span", [_vm._v("--")])
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "span",
                  { staticStyle: { "text-transform": "capitalize" } },
                  [
                    _vm._v(
                      _vm._s(
                        item.enabled ? item.status.toLowerCase() : "disabled"
                      )
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.deliverable.deliverable.assignedToList",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "row-format align-center" },
                  [
                    !item.deliverable.deliverable.assignedToList.length
                      ? _c("div", [_vm._v("--")])
                      : _vm._e(),
                    _vm._l(
                      item.deliverable.deliverable.assignedToList,
                      function(assignedTo, index) {
                        return _c("assigned-user", {
                          key: assignedTo,
                          style:
                            (index > 0 ? "margin-left: -6px" : "") +
                            "; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: " +
                            index,
                          attrs: {
                            "show-name": false,
                            small: true,
                            "assigned-to": assignedTo
                          }
                        })
                      }
                    )
                  ],
                  2
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }