<template>
	<div v-if="mode === 'standard' || mode === 'dense'" style="width:100%; position: relative">
		<vue-tel-input v-model="phone" :preferred-countries="preferredCountries" @input="phoneUpdated" :class="`${mode}-phone`" :inputOptions="{placeholder:field.name}"></vue-tel-input>
		<div class="phone-label">{{field.name}}</div>
	</div>
	<div v-else>
		<vue-tel-input v-model="phone" :preferred-countries="preferredCountries" class="custom-phone" @input="phoneUpdated"></vue-tel-input>
	</div>
</template>

<script>
	export default {
		name: 'CustomPhone',

		props: ['field', 'value','mode'],

		components: {},

		data: function() {
			return {
				phone: this.value,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addCountry: function(countryArray, country) {
				let ix = countryArray.findIndex((c) => c === country);
				if (ix > -1) {
					countryArray.splice(ix, 1);
				}
				countryArray.unshift(country);
			},

			phoneUpdated(number,object) {
				this.$emit('change',object.number);
			},
		},

		computed: {
			preferredCountries: function() {
				let countries = ['US', 'CA', 'GB', 'IE', 'AU', 'NZ'];

				if (this.$store.state.podAccount.country) {
					this.addCountry(countries, this.$store.state.podAccount.country);
				}

				return countries;
			},
		},
	};
</script>

<style lang="scss">
	.custom-phone {
		border: none!important;
		&:focus-within {
			border: 1px solid var(--v-gray_60-base)!important;
			box-shadow: none!important;
		}

		.vti__dropdown{
			&:hover{
				background-color: var(--v-white-base);
			}
		}
	}
</style>

