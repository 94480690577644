var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "payment-instructions-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c("div", { staticClass: "modal-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("invoice.details.payment-instructions-modal.heading")
              ) +
              " "
          )
        ]),
        _c("div", { staticClass: "modal-subtitle" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("invoice.details.payment-instructions-modal.subheading")
              ) +
              " "
          )
        ]),
        _c("div", { staticClass: "h-outline" }, [
          _c(
            "div",
            { staticClass: "mx-2 mb-2" },
            [
              _c("editor", {
                ref: "editor",
                staticClass: "mt-1 px-2 text-left",
                staticStyle: {
                  width: "100%",
                  "min-height": "150px",
                  "border-bottom": "none"
                },
                attrs: {
                  "api-key": _vm.$store.getters.getTinyMceKey,
                  "initial-value": _vm.payInstructionsClone,
                  inline: true,
                  rows: "3",
                  spellcheck: true,
                  init: _vm.mceConfigText
                }
              })
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-1 secondary-action",
              attrs: { icon: "" },
              nativeOn: {
                click: function($event) {
                  _vm.confirmDeleteDialog = true
                }
              }
            },
            [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$delete")])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-1",
              attrs: { width: "170" },
              on: {
                click: function($event) {
                  return _vm.handleSave()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      ),
      _c("confirm-dialog", {
        attrs: {
          dialog: _vm.confirmDeleteDialog,
          "heading-text": _vm.$t("global.confirm"),
          "body-text": _vm.$t("global.confirm-delete")
        },
        on: {
          confirm: function($event) {
            return _vm.handleDelete()
          },
          close: function($event) {
            _vm.confirmDeleteDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }