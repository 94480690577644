import HttpClient from '@/services/HttpClient';

export default class QuickbooksService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getConnection() {
		return this.httpClient
			.get('/quickbooks')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	saveSettings(config){
		return this.httpClient
			.post('/quickbooks',config)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getChartOfAccounts() {
		return this.httpClient
			.get('/quickbooks/accounts')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTaxRates() {
		return this.httpClient
			.get('/quickbooks/taxRates')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	initializeOAuth(redirectUri) {
		return this.httpClient
			.get('/quickbooks/oauth', {
				params: {
					redirectUri: redirectUri,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	exchangeToken(redirectUri,code,realmId) {
		return this.httpClient
			.post('/quickbooks/oauth', null, {
				params: {
					redirectUri: redirectUri,
					code:code,
					realmId:realmId
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	revoke() {
		return this.httpClient
			.delete(`/quickbooks/oauth`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	reset() {
		return this.httpClient
			.post(`/quickbooks/reset`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
