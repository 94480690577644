<template>
	<div v-if="emailTemplate" style="min-width: 900px">
		<div class="modal-padding">
			<div class="modal-title row-format align-center gap-1">
				<div>{{ emailTemplate.id ? 'Edit' : 'Create' }} email template</div>
				<div class="ml-auto"><folder-selector v-model="emailTemplate.folder" :folders="folders"></folder-selector></div>
				<v-btn icon @click="saveEmailTemplate(true)">
					<v-icon>$close</v-icon>
				</v-btn>
			</div>
			<div class="row-format">
				<div class="column-format">
					<v-text-field
						v-model="emailTemplate.name"
						hide-details
						class="mb-1 h-outline"
						persistent-placeholder
						label="Template name"
					></v-text-field>
					<v-text-field
						v-model="emailTemplate.subject"
						hide-details
						class="mb-2 h-outline"
						persistent-placeholder
						label="Subject"
					></v-text-field>
					<input type="file" ref="EmailAttachment" @change="setAttachments" multiple style="display: none" />
					<editor
						ref="EmailEditor"
						:api-key="$store.getters.getTinyMceKey"
						:initial-value="emailTemplate.htmlContent"
						:init="mceConfigText"
						:spellcheck="true"
					></editor>
					<div v-if="emailTemplate.attachments.length" class="row-format mt-2" style="flex-wrap: wrap; gap: 8px">
						<v-chip
							small
							v-for="(attachment, index) in emailTemplate.attachments"
							:key="attachment.fileName + index"
							close
							close-icon="cancel"
							@click="downloadAttachment(attachment)"
							@click:close="removeAttachment(attachment)"
							>{{ attachment.fileName }}</v-chip
						>
					</div>
				</div>
				<div style="width: 250px" class="mt-2 ml-2" :key="refreshKey">
					<div class="row-format font-14">
						<v-icon v-tippy content="Tokens">find_replace</v-icon>
						<div class="font-gray_80">Tokens</div>
					</div>
					<div v-for="category in tokens" :key="category.category" class="font-14 text-left">
						<ul>
							<li @click="toggleCategory(category)">
								<div class="brand-medium pointer">
									{{ category.category }}
									<v-icon color="black" size="12">{{ category.expanded ? '$arrowUp' : '$arrowDown' }}</v-icon>
								</div>
								<ul v-if="category.expanded" style="padding-left: 12px">
									<li
										class="token-link pointer"
										v-for="token in category.tokens"
										:key="category.category + token.value"
										@click.stop="insert(token.value)"
									>
										{{ token.label }}
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div style="border-top: 1px solid var(--v-gray_30-base)" class="row-format centered mt-2 pa-2 gap-2">
			<v-btn class="super-action" width="160" @click="saveEmailTemplate(true)">
				{{ $t('global.save') }}
			</v-btn>
			<v-btn icon @click="confirmDelete()">
				<v-icon size="20" color="gray_90" class="material-symbols-rounded">delete</v-icon>
			</v-btn>
		</div>
		<ai-email-widget v-if="aiDialog" :dialog="aiDialog" mode="draft" @cancel="aiDialog = false" @result="runAiAssist($event)"></ai-email-widget>
	</div>
</template>

<script>
	import Editor from '@tinymce/tinymce-vue';
	import EmailTemplateService from '@/modules/templates/EmailTemplateService';
	import ConfirmModal from '@/components/ConfirmModal';
	import axios from 'axios';
	import TemplateMixin from "@/modules/templates/emails/TemplateMixin";
	import FolderSelector from "@/components/FolderSelector";
	import AiAssistantService from "@/modules/ai/AiAssistantService";
	import marked from "marked";
	import AiEmailWidget from "@/modules/communicator/inbox/email/AiEmailWidget";

	export default {
		name: 'EmailTemplateEditor',

		props: ['id','folders'],
		mixins: [TemplateMixin],
		components: {FolderSelector, Editor, AiEmailWidget },

		data: function() {
			return {
				aiDialog: false,
				aiSession: null,
				aiAssistantService: new AiAssistantService(),
				emailTemplateService: new EmailTemplateService(),
				emailTemplate: null,
				showTokens: true,
				tokens: [],
				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					browser_spellcheck: true,
					paste_as_text: false,
					forced_root_block: 'div',
					paste_data_images: true,
					plugins: ['paste', 'lists', 'link', 'table', 'code'],
					contextmenu: 'paste | link | table | assistant',
					branding: false,
					height: `400px`,
					width: '750px',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'undo redo | fontsizeselect forecolor bold italic underline | alignleft aligncenter alignright alignjustify',
						'table | fontselect |bullist numlist | link unlink | code removeformat | ai |  template attachment',
					],
					fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 24pt 30pt 36pt 48pt 72pt",
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('attachment', {
							text:
								'<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M21,10V4c0-1.1-0.9-2-2-2H3C1.9,2,1.01,2.9,1.01,4L1,16c0,1.1,0.9,2,2,2h11v-5c0-1.66,1.34-3,3-3H21z M11,11L3,6V4l8,5 l8-5v2L11,11z"/><path d="M21,14v4c0,1.1-0.9,2-2,2s-2-0.9-2-2v-4.5c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5V18h2v-4.5c0-1.38-1.12-2.5-2.5-2.5 S15,12.12,15,13.5V18c0,2.21,1.79,4,4,4s4-1.79,4-4v-4H21z"/></g></g></svg>',
							onAction: () => {
								this.$refs.EmailAttachment.click();
							},
						});

						editor.ui.registry.addNestedMenuItem('assistant', {
							text: '✨Assistant',
							getSubmenuItems: () => {
								return [
									{
										type: 'menuitem',
										text: 'Elaborate',
										onAction: () => {
											this.rewrite('elaborate');
										},
									},
									{
										type: 'menuitem',
										text: 'Simplify',
										onAction: () => {
											this.rewrite('simplify');
										},
									},
									{
										type: 'menuitem',
										text: 'Make more formal',
										onAction: () => {
											this.rewrite('formalize');
										},
									},
									{
										type: 'menuitem',
										text: 'Make friendlier',
										onAction: () => {
											this.rewrite('friendlier');
										},
									},
									{
										type: 'menuitem',
										text: 'Summarize',
										onAction: () => {
											this.rewrite('summarize');
										},
									},
									{
										type: 'menuitem',
										text: 'Paraphrase',
										onAction: () => {
											this.rewrite('paraphrase');
										},
									},
								];
							},
						});

						editor.ui.registry.addButton('ai', {
							text: '✨Assistant',
							onAction: () => {
								this.emailAssistant();
							},
						});
					},
				},
			};
		},

		mounted() {
			if (this.id) {
				this.getEmailTemplate();
			} else {
				this.initNewTemplate();
			}
			this.initTokens();
		},

		beforeDestroy() {},

		methods: {
			rewrite(modifier) {
				let editor = this.$refs.EmailEditor.editor;
				let selection = editor.selection.getContent({ format: 'html' });

				if (selection) {
					this.$store.commit('startLoading');
					this.aiAssistantService
							.rewrite(selection, modifier)
							.then((response) => {
								editor.selection.setContent(response);
							})
							.catch((err) => this.$store.commit('error', err.response?.data?.message))
							.finally(() => this.$store.commit('stopLoading'));
				}
			},

			async initializeAiSession(){
				if(!this.aiSession){
					try {
						let createRequest = {
							model: 'gpt-4o-mini',
							useCase: 'EmailResponse',
							context: [],
							temperature: 0.5,
							top_p: 0.5
						};

						createRequest.context.push({
							role: 'system',
							content: 'You are building a reusable email template that can be used in automations.'
						});
						createRequest.context.push({
							role: 'system',
							content: 'Always use these tokens in place of actual values:' + this.flatTokenList
						});
						createRequest.context.push({
							role: 'system',
							content: 'Any token that starts with "Client" or "Contact" relates to the recipients of the email, not the sender'
						});
						createRequest.context.push({
							role: 'system',
							content: 'Only Use the token "{{Email.Signature}}" in place of the value where the user would put their name at the end of the email.'
						});
						createRequest.context.push({
							role: 'system',
							content: 'Your response must only contain the actual email body content. Do not include the subject in your response.'
						});

						let result = await this.aiAssistantService.createChatSession(createRequest);
						this.aiSession = result.data;
					}catch(err){
						this.$store.commit('Error processing request: ' + err.response?.data?.message)
					}
				}
			},

			async runAiAssist(params){
				try {
					this.$store.commit('startLoading');
					this.aiDialog = false;
					await this.initializeAiSession();
					let instructions = `Please write me a new email in a ${params.tone} tone.  The email content should include: ${params.input}.`

					let editor = this.$refs.EmailEditor.editor;
					let newContent = '';
					editor.setContent('');
					editor.selection.setCursorLocation(editor.getBody(), 0);

					await this.aiAssistantService.continueSessionStreaming(this.aiSession.id, instructions, {
						onData: (data) => {
							newContent += data;
							this.$refs.EmailEditor.editor.execCommand('mceInsertContent', false, this.removeMarkdownCharacters(data));
						},
						onComplete: () => {
							this.$store.commit('stopLoading');
							let formatted = marked(newContent, {breaks: true});
							editor.setContent(formatted);
							editor.selection.setCursorLocation(editor.getBody(), 0);
						},
						onError: (error) => {
							this.$store.commit('Error processing request: ' + error.response?.data?.message)
							this.$store.commit('stopLoading');
						}
					})
				}catch(err){
					this.$store.commit('stopLoading');
					this.$store.commit('Error processing request: ' + err.response?.data?.message)
				}
			},

			removeMarkdownCharacters: function(text) {
				return text
						.replace(/[_*~`>#+\-=|{}[\]()]/g, "") // Remove common Markdown characters
						.replace(/^\s*\n/gm, "") // Remove empty lines

			},

			async emailAssistant(){
				this.aiDialog = true;
			},

			insert: function(text) {
				this.$refs.EmailEditor.editor.execCommand('mceInsertContent', false, `{{${text}}}`);
			},

			getEmailTemplate: function() {
				this.emailTemplateService.getEmailTemplate(this.id).then((res) => (this.emailTemplate = res.data));
			},

			saveEmailTemplate: function(close) {
				this.emailTemplate.htmlContent = this.$refs.EmailEditor.editor.getContent();

				this.emailTemplateService.updateEmailTemplate(this.emailTemplate.id, this.emailTemplate).then((res) => {
					this.emailTemplate = res.data;
					if (close) {
						this.handleClose();
					}
				});
			},

			confirmDelete: function() {
				let binding = {
					bodyText: 'Are you sure you want to delete this email template?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.deleteEmailTemplate();
					}
				});
			},

			deleteEmailTemplate: function() {
				this.emailTemplateService.deleteEmailTemplate(this.emailTemplate.id).then(() => {
					this.emailTemplate.deleted = true;
					this.handleClose();
				});
			},

			setAttachments: function(event) {
				this.$store.commit('startLoading');
				let files = [];
				event.target.files.forEach((f) => {
					files.push(f);
				});
				this.emailTemplateService
					.addFileAttachments(this.emailTemplate.id, files)
					.then((res) => {
						this.emailTemplate.attachments.push(...res.data);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			downloadAttachment: function(s3File) {
				this.emailTemplateService.getSignedUrl(this.emailTemplate.id, s3File.fileName).then((res) => {
					axios({
						url: res.data.signedUrl,
						method: 'GET',
						responseType: 'blob',
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', s3File.fileName);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					});
				});
			},

			removeAttachment: function(s3File) {
				this.emailTemplateService.deleteFileAttachment(this.emailTemplate.id, s3File.fileName).then(() => {
					let ix = this.emailTemplate.attachments.findIndex((s) => s.fileName === s3File.fileName);
					if (ix > -1) {
						this.emailTemplate.attachments.splice(ix, 1);
					}
				});
			},

			handleClose: function() {
				this.$emit('result', this.emailTemplate);
			},

			initNewTemplate: function() {
				let newTemplate = {
					id: null,
					name: null,
					subject: null,
					htmlContent: null,
					attachments: [],
				};

				this.emailTemplateService.createEmailTemplate(newTemplate).then((res) => (this.emailTemplate = res.data));
			},
		},

		computed: {
			flatTokenList: function(){
				let result = [];
				for(let i=0; i < this.tokens.length; i++){
					let category = this.tokens[i];
					for(let j=0; j < category.tokens.length; j++){
						result.push('{{' + category.tokens[j].value + '}}');
					}
				}
				return result.join(', ');
			}
		},
	};
</script>

<style lang="scss">
	.tox-toolbar__group:last-child {
		margin-left: auto !important;
	}
</style>

<style scoped lang="scss">
	.token-link {
		&:hover {
			color: var(--v-primary-base);
		}
	}
</style>
