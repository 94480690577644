var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { attrs: { id: "payment-plan-details" } },
        [
          _c("div", { attrs: { id: "header-area" } }, [
            _c(
              "div",
              {
                staticClass:
                  "header-left pl-md-3 pl-3 gap-3 align-center row-format"
              },
              [
                _c("v-text-field", {
                  staticStyle: { width: "300px" },
                  attrs: {
                    dense: "",
                    outlined: "",
                    "hide-details": "",
                    placeholder:
                      "Payment plan for " + _vm.invoice.clientInfo.name
                  },
                  model: {
                    value: _vm.paymentPlan.name,
                    callback: function($$v) {
                      _vm.$set(_vm.paymentPlan, "name", $$v)
                    },
                    expression: "paymentPlan.name"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "plan-status", style: _vm.statusColors },
                  [_vm._v(_vm._s(_vm.paymentPlan.status))]
                ),
                _c("payment-plan-events", {
                  attrs: { "payment-plan": _vm.paymentPlan }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "header-right pr-md-6 pr-4",
                attrs: { id: "header-right" }
              },
              [
                _vm.paymentPlan.status === "Draft"
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mr-1 super-action",
                        attrs: { width: "96" },
                        on: {
                          click: function($event) {
                            return _vm.savePaymentPlan(false)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
                    )
                  : _vm.nextUp
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mr-1 super-action",
                        on: { click: _vm.runNextMilestone }
                      },
                      [
                        _vm.nextUp.triggerType === "Milestone"
                          ? _c("span", [
                              _vm._v("Milestone: " + _vm._s(_vm.nextUp.label))
                            ])
                          : _vm.nextUp.triggerType === "Date"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.DateTime.fromISO(
                                    _vm.nextUp.triggerDate
                                  ).toLocaleString(_vm.DateTime.DATE_MED)
                                ) +
                                  ": " +
                                  _vm._s(_vm.nextUp.label)
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-menu",
                  {
                    attrs: { "close-on-click": true, "nudge-bottom": 30 },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ml-1" },
                                [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "menu-activator",
                                        attrs: { icon: "" }
                                      },
                                      scope.on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { size: 20 } }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.value
                                              ? "$arrowUp"
                                              : "$moreHorizontal"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1706142669
                    )
                  },
                  [
                    _c("div", { staticClass: "more-menu" }, [
                      _vm.paymentPlan.status === "Draft"
                        ? _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: {
                                click: function($event) {
                                  return _vm.activate()
                                }
                              }
                            },
                            [_vm._v(" Activate ")]
                          )
                        : _vm._e(),
                      _vm.paymentPlan.status === "Active"
                        ? _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: {
                                click: function($event) {
                                  return _vm.returnToDraft()
                                }
                              }
                            },
                            [_vm._v(" Return to draft ")]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.confirmDelete()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("global.delete")) + " ")]
                      )
                    ])
                  ]
                ),
                _c(
                  "v-btn",
                  { attrs: { icon: "" }, on: { click: _vm.handleClose } },
                  [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "invoice-renderer-v2",
            {
              key: _vm.rendererKey,
              staticStyle: {
                "max-height": "calc(100vh - 60px)",
                height: "calc(100vh - 60px)"
              },
              attrs: {
                invoice: _vm.invoice,
                recurring: false,
                "payment-plan": true,
                account: _vm.$store.state.podAccount,
                "edit-mode": _vm.paymentPlan.status === "Draft",
                "force-render-index": _vm.forceRenderIndex,
                "attachment-service": {},
                "invoice-service": _vm.invoiceService,
                "has-expenses": false,
                "additional-tax-rates": _vm.additionalTaxRates,
                "scheduled-payments": _vm.paymentPlan.scheduledPayments
              },
              on: {
                "force-save": function($event) {
                  return _vm.render()
                },
                updated: function($event) {
                  return _vm.updated($event)
                },
                "pause-auto-save": function($event) {
                  return _vm.pauseAutoRender($event)
                },
                "tax-rate-selected": function($event) {
                  return _vm.taxRateSelected($event)
                }
              }
            },
            [
              _c("payment-plan-settings", {
                attrs: { "payment-plan": _vm.paymentPlan, invoice: _vm.invoice }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }