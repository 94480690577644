<template>
	<div style="display: none"></div>
</template>

<script>
	import userflow from 'userflow.js';

	export default {
		name: 'OnBoardingController',

		props: [],

		components: {},

		data: function() {
			return {};
		},

		mounted() {
			window.vuex_state = this.$store.state;
			window.vue_router = this.$router;
			window.userflow = userflow;
			this.$store.state.eventBus.$on('on-boarding', this.handleOnBoardingEvent);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$on('on-boarding', this.handleOnBoardingEvent);
		},

		methods: {
			handleOnBoardingEvent(flowEvent) {
				userflow.start(flowEvent.flow)
				userflow.track(flowEvent.event);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
