<template>
	<div class="pb-4">
		<v-container>
			<v-row dense>
				<v-col md="9" cols="3">&nbsp;</v-col>
				<v-col md="3" cols="9" align="right">
					<v-select
						:hide-details="true"
						outlined dense label="Input type"
						:items="componentTypes"
						v-model="ourFieldType"
						@change="notifyChange()"
						item-text="label"
						item-value="type"
					></v-select>
				</v-col>
				<v-col cols="12">
					<span class="question">
						<editor
							style="border: 1px dotted var(--v-grey-lighten3);"
							ref="editor"
							@input="handleChange"
							:api-key="$store.getters.getTinyMceKey"
							:initial-value="htmlText"
							:inline="true"
							:init="mceConfigText"
							:placeholder="placeHolder"
							:spellcheck="true"
						>
						</editor>
					</span>
				</v-col>
			</v-row>
			<draggable v-model="ourOptions" group="options" @end="notifyChange()" handle=".nestedHandle">
				<v-row v-for="(option, index) in ourOptions" :key="option.id" align="center">
					<v-col cols="11" style="white-space: nowrap;" class="my-2">
						<div class="row-format">
							<v-icon style="cursor: pointer;" class="nestedHandle mt-1">
								$dragSecondary
							</v-icon>
							<v-icon color="grey" v-if="icon" class="ml-4">{{ icon }}</v-icon>
							<v-text-field
								:value="option.option"
								:dense="true"
								:hide-details="true"
								class="ml-2"
								@change="handleOptionChange(index, { id: option.id, option: $event })"
							>
							</v-text-field>
						</div>
					</v-col>
					<v-col cols="1" align="right">
						<v-icon small @click.native="removeOption(index)" class="pointer">$close</v-icon>
					</v-col>
				</v-row>
			</draggable>
			<v-row v-if="ourHasOther && ourFieldType !== 'SelectInput'" align="center">
				<v-col cols="11" style="white-space: nowrap;" class="my-2">
					<div class="row-format">
						<v-icon color="grey" v-if="icon" class="ml-10">{{ icon }}</v-icon>
						<v-text-field class="ml-2" placeholder="Other" :dense="true" :hide-details="true" :disabled="true">
						</v-text-field>
					</div>
				</v-col>
				<v-col cols="1" align="right">
					<v-icon small @click.native="removeOther()" class="pointer">$close</v-icon>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="my-2">
					<div class="row-format">
						<v-icon class="ml-10" v-if="icon">{{ icon }}</v-icon>
						<v-btn text color="grey" class="brand-regular pa-0 ml-2" @click="addOption()">Add option</v-btn>
						<v-btn
							v-if="ourFieldType !== 'SelectInput' && !ourHasOther"
							text
							color="grey"
							class="brand-regular pa-0 ml-1"
							@click="addOther()"
							>or Add "Other"</v-btn
						>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" align="right">
					<div style="width: fit-content" class="mt-2 row-format gap-2">
						<v-text-field outlined persistent-placeholder dense hide-details label="Field id" v-model="ourFieldKey" style="width:150px" @change="handleChange()"></v-text-field>
						<v-select style="width:95px" :items="requiredOptions" item-text="label" item-value="value" v-model="ourRequired" outlined persistent-placeholder dense hide-details label="Required" @change="notifyChange()">
						</v-select>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import draggable from 'vuedraggable';
	import { v4 as uuidv4 } from 'uuid';

	export default {
		name: 'GenericOptionInput',
		props: ['id', 'options', 'htmlText', 'fieldType', 'fieldKey', 'placeHolder', 'mceConfigHeader', 'mceConfigText', 'hasOther', 'required'],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
			draggable,
		},

		mounted() {
			this.ourOptions = this.buildOptionArray(this.options);
			this.ourHtmlText = this.htmlText;
			this.ourHasOther = this.hasOther;
			this.ourFieldType = this.fieldType;
			this.ourFieldKey = this.fieldKey;
		},

		data() {
			return {
				componentTypes: [
					{ type: 'SelectInput', label: 'Dropdown' },
					{ type: 'RadioInput', label: 'Radio buttons' },
					{ type: 'CheckboxInput', label: 'Checkboxes' },
				],

				requiredOptions: [
					{label: 'Yes', value: true},
					{label: 'No', value: false}
				],

				ourOptions: null,
				ourHtmlText: null,
				ourHasOther: false,
				ourFieldType: null,
				ourFieldKey: null,
				ourRequired: this.required
			};
		},

		methods: {
			buildOptionArray(options) {
				let result = [];
				for (let i = 0; i < options.length; i++) {
					result.push(this.getOption(options[i]));
				}
				return result;
			},

			getOption(option) {
				return {
					id: uuidv4().replace(/-/g, ''),
					option: option,
				};
			},

			addOption() {
				this.ourOptions.push(this.getOption(''));
				this.notifyChange();
			},

			addOther() {
				this.ourHasOther = true;
				this.notifyChange();
			},

			removeOther() {
				this.ourHasOther = false;
				this.notifyChange();
			},

			removeOption(index) {
				this.ourOptions.splice(index, 1);
				this.notifyChange();
			},

			handleChange() {
				this.ourHtmlText = this.$refs.editor.editor.getContent({ format: 'html' });
				this.notifyChange();
			},

			handleOptionChange(index, text) {
				this.ourOptions[index] = text;
				this.notifyChange();
			},

			notifyChange() {
				let optionArray = [];

				for (let i = 0; i < this.ourOptions.length; i++) {
					optionArray.push(this.ourOptions[i].option);
				}

				this.$emit('changed', {
					id: this.id,
					fieldType: this.ourFieldType,
					htmlText: this.ourHtmlText,
					options: optionArray,
					hasOther: this.ourHasOther,
					required: this.ourRequired,
					fieldKey: this.ourFieldKey
				});
			},
		},

		computed: {
			icon: function() {
				if (this.ourFieldType === 'RadioInput') {
					return 'radio_button_unchecked';
				} else if (this.ourFieldType === 'CheckboxInput') {
					return 'check_box_outline_blank';
				} else {
					return null;
				}
			},
		},
	};
</script>

<style scoped>
	.selectStyle {
		width: 170px;
	}

	.question {
		font-size: 16px;
		font-weight: 300;
	}

	.words {
		font-size: 16px;
	}

	.selectStyle ::v-deep .v-select__selection {
		margin-left: 10px !important;
	}

	.selectStyle ::v-deep .v-input__control {
		border: 2px solid var(--v-gray_70-base) !important;
		border-radius: 8px;
	}

	.selectStyle ::v-deep .v-select__selection--comma {
		color: var(--v-gray_70-base) !important;
	}

	.selectStyle ::v-deep .v-input__slot:before {
		border-style: none !important;
	}
	.selectStyle ::v-deep .v-input__slot:after {
		border-style: none !important;
	}
</style>
