var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row-format align-start" }, [
      _c(
        "div",
        { staticClass: "column-format" },
        [
          _c("div", { staticClass: "font-gray_80 mb-1" }, [
            _vm._v(_vm._s(_vm.$t("connected-apps.stripe.desc")))
          ]),
          _vm.isConnected
            ? _c("connected")
            : _vm.isPartial
            ? _c(
                "div",
                {
                  staticClass: "partial-button",
                  on: { click: _vm.handleConnectRequest }
                },
                [_vm._v(" In Progress ")]
              )
            : _c(
                "div",
                {
                  staticClass: "font-primary brand-medium pointer",
                  on: { click: _vm.handleConnectRequest }
                },
                [_vm._v("Connect Stripe")]
              )
        ],
        1
      ),
      _vm.isConnected
        ? _c(
            "div",
            {
              staticClass: "ml-auto pointer",
              on: {
                click: function($event) {
                  _vm.expanded = !_vm.expanded
                }
              }
            },
            [
              _c("v-icon", [
                _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
              ])
            ],
            1
          )
        : _vm._e()
    ]),
    _vm.isConnected && _vm.expanded
      ? _c(
          "div",
          { staticClass: "mt-5" },
          [
            _c(
              "v-btn",
              {
                staticClass: "primary-action mr-1",
                on: { click: _vm.handleConnectRequest }
              },
              [_vm._v("View transactions")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "primary-action ml-1",
                on: { click: _vm.openDashboard }
              },
              [_vm._v("Open Stripe dashboard")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }