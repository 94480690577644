<template>
	<div id="account-info-modal" v-if="account">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">
				{{ $t('invoice.details.account-info-modal.heading') }}
			</div>

			<div class="d-flex justify-space-between align-start">
				<div style="flex:0 0 34%" class="pt-1">
					<vue-dropzone
						v-if="!account.accountLogo"
						ref="fileDropzone"
						id="dropzone"
						v-on:vdropzone-success="uploadSuccess"
						v-on:vdropzone-error="uploadFailure"
						v-on:vdropzone-sending="addFileUploadAuthHeader"
						:use-custom-slot="true"
						:options="dropzoneOptions"
					>
						<div>
							<v-icon>$vuetify.icons.upload</v-icon>
							<div class="mt-1 font-14" style="line-height: 19px;">
								{{ $t('invoice.details.account-info-modal.logo-upload') }}
							</div>
						</div>
					</vue-dropzone>

					<div class="logo-wrapper" v-if="account.accountLogo">
						<v-img :src="account.accountLogo" max-width="108" max-height="108"></v-img>
						<div class="delete-wrapper">
							<v-btn icon @click.native="deleteAccountLogo()"><v-icon>$delete</v-icon></v-btn>
						</div>
					</div>
				</div>
				<div style="flex:0 0 66%" class="pl-2">
					<v-text-field
						class="h-outline"
						persistent-placeholder
						hide-details
						:label="$t('proposal.terms.legal-name')"
						v-model="account.accountName"
						@keyup.enter="handleSave"
					></v-text-field>
					<v-select
							v-if="entityTypes.length"
							hide-details
							persistent-placeholder
							class="h-outline"
							:label="$t('proposal.terms.entity-type')"
							:items="entityTypes"
							v-model="account.entityType"
					></v-select>
					<v-text-field
							style="min-width: 300px"
							v-else
							hide-details
							persistent-placeholder
							class="h-outline"
							placeholder="Corporation, GmbH, etc "
							:label="$t('proposal.terms.entity-type')"
							v-model="account.entityType"
					>
					</v-text-field>
				</div>
			</div>

			<v-autocomplete
					hide-details
					persistent-placeholder
					class="h-outline"
					:label="$t('proposal.terms.entity-country')"
					:items="countriesSimple"
					item-text="name"
					item-value="code"
					v-model="account.country"
			>
			</v-autocomplete>

			<v-text-field
					hide-details
					persistent-placeholder
					class="h-outline"
					v-model="account.taxId"
					:label="$t('account.tax-id')"
			></v-text-field>

			<div class="brand-medium font-12 gray_90--text mt-6 text-left mt-1">Mailing address</div>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="account.address1"
				:label="$t('contact.mailing-address')"
				@keyup.enter="handleSave"
			></v-text-field>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="account.address2"
				:label="$t('contact.address-contd')"
				@keyup.enter="handleSave"
			></v-text-field>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="account.city"
				:label="$t('contact.city')"
				@keyup.enter="handleSave"
			></v-text-field>

			<div class="d-flex justify-space-between mt-n1">
				<div class="pr-1" style="width:50%">
					<v-text-field
						hide-details
						persistent-placeholder
						class="h-outline"
						:label="$t('proposal.terms.entity-state')"
						v-model="account.locality"
						@keyup.enter="handleSave"
					>
					</v-text-field>
				</div>
				<div class="pl-1" style="width: 50%">
					<v-text-field
						class="h-outline"
						persistent-placeholder
						hide-details
						v-model="account.postal"
						:label="$t('contact.postal')"
						@keyup.enter="handleSave"
					></v-text-field>
				</div>
			</div>
			<v-text-field
					class="h-outline"
					persistent-placeholder
					hide-details
					v-model="account.phone"
					:label="$t('contact.mobile')"
					@keyup.enter="handleSave"
			></v-text-field>
		</div>
		<div class="modal-footer">
			<v-btn class="px-16 primary-action" @click="handleSave">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import countries from '@/assets/data/countries.json';
	import countriesSimple from '@/assets/data/countries-simple.json';
	import AccountService from '@/modules/account/AccountService';
	import jsonPatch from 'fast-json-patch';
	import vueDropzone from 'vue2-dropzone';
	import Cropper from 'cropperjs';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import 'cropperjs/dist/cropper.min.css';
	import AuthService from '@/modules/auth/AuthService';
	// import jsonPatch from 'fast-json-patch';

	export default {
		name: 'AccountInfoModal',

		props: { accountInfo: { type: Object, required: true } },
		// props: { value: { type: Object, required: true } },
		//
		components: { vueDropzone },

		data: function() {
			return {
				accountService: new AccountService(),
				countries: countries,
				countriesSimple:countriesSimple,
				account: null,
				// logo: null,
				// updateAccountToo: true,
				// accountInfo: null,
				accountObserver: null,
			};
		},

		mounted() {
			this.getAccount();
			// this.accountInfo = this.value;
			this.$store.state.eventBus.$on('account-changed', this.getAccount);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getAccount);
		},

		methods: {
			getAccount: function() {
				this.accountService
					.getAccount()
					.then((res) => {
						// console.log(res.data);
						this.setAccount(res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},
			setAccount: function(account) {
				this.account = account;
				// console.log('setAccount', this.account);
				this.accountObserver = jsonPatch.observe(this.account);
			},

			handleSave() {
				this.saveAccount();
				this.$emit('close');
			},

			saveAccount: function() {
				this.accountService
					.updateAccount(this.account)
					.then((res) => {
						// console.log(res);
						this.$emit('set-account-info', res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
				this.notifySuccess();
			},
			deleteAccountLogo: function() {
				this.accountService
					.deleteLogo()
					.then(() => {
						this.account.accountLogo = null;
						// this.saveAccount();
						// this.refreshUserAndAccountTokens();
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			refreshUserAndAccountTokens: function() {
				AuthService.refreshToken(true).then((res) => {
					this.$store.commit('logIn', res);

					AuthService.getProfile().then((res) => {
						this.$store.dispatch('setLoggedInUser', res);
						this.user = JSON.parse(JSON.stringify(this.$store.state.loggedInUser));
					});
				});
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},
			uploadSuccess: function(file, response) {
				this.$refs.fileDropzone.removeFile(file);
				// console.log(file);
				// console.log(response);
				this.account.accountLogo = response.accountLogo;
				this.saveAccount();
				// this.refreshUserAndAccountTokens();
			},

			uploadFailure: function(file, message) {
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.fileDropzone.removeFile(file);
			},
			// To do: move this and the options computed prop to an external library
			transformFile: function(file, done) {
				let myDropZone = this.$refs.userFileDropzone.dropzone;
				console.log(myDropZone);
				let editor = document.createElement('div');
				editor.style.position = 'fixed';
				editor.style.left = '0';
				editor.style.right = '0';
				editor.style.top = '0';
				editor.style.bottom = '0';
				editor.style.zIndex = '9999';
				editor.style.backgroundColor = '#000';
				document.body.appendChild(editor);

				// Create confirm button at the top left of the viewport
				let buttonConfirm = document.createElement('div');
				buttonConfirm.style.position = 'absolute';
				buttonConfirm.setAttribute('class', 'confirmButton');
				buttonConfirm.textContent = 'Confirm';
				editor.appendChild(buttonConfirm);

				buttonConfirm.addEventListener('click', function() {
					// Get the canvas with image data from Cropper.js
					let canvas = cropper.getCroppedCanvas({
						width: 256,
						height: 256,
					});

					// Turn the canvas into a Blob (file object without a name)
					canvas.toBlob(function(blob) {
						// Create a new Dropzone file thumbnail
						myDropZone.createThumbnail(
							blob,
							myDropZone.options.thumbnailWidth,
							myDropZone.options.thumbnailHeight,
							myDropZone.options.thumbnailMethod,
							false,
							function(dataURL) {
								// Update the Dropzone file thumbnail
								myDropZone.emit('thumbnail', file, dataURL);
								// Return the file to Dropzone
								done(blob);
							}
						);

						done(blob);
					});

					// Remove the editor from the view
					document.body.removeChild(editor);
				});

				// Create an image node for Cropper.js
				let image = new Image();
				image.src = URL.createObjectURL(file);
				editor.appendChild(image);

				// Create Cropper.js
				let cropper = new Cropper(image, { aspectRatio: 1 });
			},
			notifySuccess: function() {
				this.$store.commit('success', 'Account settings saved.');
			},
		},
		watch: {},

		computed: {
			entityTypes: function () {
				if (this.account.country == 'US' || this.account.country == 'CA') {
					let country = this.countries.find((c) => c.code === this.account.country);
					if (country) {
						return country.entityTypes;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},

			states: function () {
				if (this.account.country == 'US' || this.account.country == 'CA') {
					let country = this.countries.find((c) => c.code === this.account.country);
					if (country) {
						return country.states;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},

			dropzoneOptions: function() {
				return {
					url: `${this.$store.getters.getBaseUrl}/logo`,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},
		},
	};
</script>

<style lang="scss">
	#account-info-modal {
		max-width: 650px;
		width: 650px;
		background-color: var(--v-white-base);
		#dropzone {
			max-width: 108px;
			max-height: 108px;
		}
		.logo-wrapper {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 108px;
			.delete-wrapper {
				position: absolute;
				top: 0;
				left: 0;
				visibility: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				background-color: var(--v-white-base);
				opacity: 0.8;
			}
			&:hover {
				.delete-wrapper {
					visibility: visible;
					.v-btn {
					}
				}
			}
		}
	}
</style>
