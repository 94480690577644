<template>
	<div style="background-color: var(--v-white-base); width:500px" class="pa-3">
		<div class="row-format align-center">
			<div class="row-format align-center">
				<div v-if="status">Deliverables in:  {{status.label}}</div>
				<did v-else>Deliverables</did>
			</div>
			<v-icon class="ml-auto" @click="handleClose">$close</v-icon>
		</div>
		<div class="mt-3">
			<deliverable-loader v-for="deliverable in filteredList" :key="deliverable.id" @click.native="deliverableClicked(deliverable)"
					class="pointer pl-0 text-left"
					:event="deliverable"
			></deliverable-loader>
		</div>
	</div>
</template>

<script>
	import DeliverableLoader from "@/modules/calendar/details/DeliverableLoader";
	import DeliverableDetail from "@/modules/projects/deliverable/DeliverableDetail";
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'DeliverableList',

		props: ['startDate','endDate','events','status'],

		components: { DeliverableLoader },

		data: function () {
			return {

			};
		},

		mounted() {
			console.log(status);
		},

		beforeDestroy() {},

		methods: {
			deliverableClicked: function(event){
				let deliverable = event.meta;
				let meta = event.meta;
				let binding = {
					deliverableId: deliverable.id,
					project: deliverable.project,
					client: deliverable.client,
				};
				this.$store.state.globalModalController.openModal(DeliverableDetail,binding,true,false).then((res) => {
					if(!res) return;
					let ix = this.events.findIndex(e => e.eventSource === 'DELIVERABLE' && e.id === res.id);
					if(ix > -1){
						if(res.action && res.action === 'DELETED'){
							this.events.splice(ix,1);
						}else{
							let updated = {
								id: res.id,
								eventSource: 'DELIVERABLE',
								summary: res.name,
								description: res.description,
								start: res.dueDate,
								end: res.dueDate,
								dateOnly: true,
								meta: {
									clientId: meta.client.id,
									client: meta.client,
									project: meta.project,
									id: res.id,
									assignedToList: res.assignedToList,
									statusId: res.statusId
								}
							}
							this.events.splice(ix,1,updated);
						}
					}
				})
			},

			handleClose: function () {
				this.$emit('result', this.eventList);
			},

		},

		computed: {
			filteredList: function(){
				return this.events
						.filter(e => e.eventSource === 'DELIVERABLE')
						.filter(e => {
							if(this.status){
								return e.meta.statusId === this.status.id
							}else{
								return true;
							}
						})
						.filter(e => {
							let due = DateTime.fromISO(e.start).startOf('day');
							return due >= this.startDate && due <= this.endDate;
						})
			}
		},
	};
</script>

<style scoped lang="scss">
	.status-box{
		width: 20px;
		height: 20px;
		border-radius: 4px;
		background-color: var(--status-color);
	}
</style>
