var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-combobox", {
        ref: "EmailInput",
        staticClass: "mt-1 email-input",
        attrs: {
          items: _vm.suppressContacts ? [] : _vm.contacts,
          "hide-details": "",
          dense: "",
          outlined: !_vm.underlined,
          label: _vm.label + ":",
          placeholder: "Enter email or search",
          multiple: true,
          "small-chips": "",
          filter: _vm.isMatch
        },
        on: {
          change: function($event) {
            return _vm.$emit("update", _vm.to)
          }
        },
        model: {
          value: _vm.to,
          callback: function($$v) {
            _vm.to = $$v
          },
          expression: "to"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }