var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.pricingVersion === 1
        ? _c("subscription-v1")
        : _vm.pricingVersion === 999
        ? _c("subscription-sumo")
        : _c("subscription-v2", {
            attrs: { "pricing-version": _vm.pricingVersion }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }