var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-3",
          attrs: {
            compact: false,
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Client management" },
              { to: "/clients/overview", label: "Client list" }
            ]
          }
        },
        [_vm._v(" " + _vm._s(_vm.client.name) + " ")]
      ),
      _vm.isReady && _vm.filtersReady
        ? _c("div", { attrs: { id: "client-detail-view" } }, [
            _vm.isReady
              ? _c(
                  "div",
                  {
                    staticClass: "align-center",
                    attrs: { id: "detail-wrapper" }
                  },
                  [
                    _c("div", { staticClass: "row-format" }, [
                      _c(
                        "div",
                        {
                          staticClass: "row-format gap-3",
                          staticStyle: { "flex-wrap": "wrap" }
                        },
                        _vm._l(_vm.pages, function(page) {
                          return _c(
                            "div",
                            {
                              key: page.value,
                              class:
                                "page-link " +
                                (_vm.currentView === page.value
                                  ? "page-link-active"
                                  : ""),
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.setView(page.value, $event)
                                }
                              }
                            },
                            [_c("div", [_vm._v(_vm._s(page.label))])]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "ml-auto" }, [
                        _c(
                          "div",
                          { staticClass: "row-format align-center" },
                          [
                            _vm.currentView === "Overview"
                              ? _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "nudge-bottom": 0,
                                      elevation: 0,
                                      "content-class": "add-new-menu",
                                      bottom: "",
                                      left: "",
                                      rounded: "",
                                      "offset-overflow": "",
                                      "offset-y": "",
                                      transition: "slide-y-transition"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass: "super-action"
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "20" } },
                                                    [_vm._v("add")]
                                                  ),
                                                  _vm._v(" Add")
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      251512182
                                    )
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "add-new-dropdown" },
                                      _vm._l(_vm.addNew, function(item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "add-new-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.handleAddNew(
                                                  item.value
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                )
                              : _vm.currentView !== "Meetings"
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "super-action",
                                    on: { click: _vm.handleAddNewFromTab }
                                  },
                                  [
                                    _c("v-icon", { attrs: { size: "20" } }, [
                                      _vm._v("add")
                                    ]),
                                    _vm._v(" Add")
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "body-content mt-6 column-format" },
                      [
                        _vm.currentView === "Overview"
                          ? _c("overview", {
                              attrs: {
                                client: _vm.client,
                                projects: _vm.projects,
                                "is-active": _vm.currentView === "Overview",
                                filter: _vm.filters["Overview"],
                                currencies: _vm.currencies
                              },
                              on: {
                                "update-client": _vm.updateClient,
                                "edit-client": _vm.editClient,
                                "unlink-accounting": function($event) {
                                  return _vm.unlinkAccounting()
                                },
                                "time-clicked": function($event) {
                                  return _vm.setView("Time")
                                },
                                "money-clicked": function($event) {
                                  return _vm.setView("Invoices")
                                },
                                "send-email": function($event) {
                                  return _vm.sendEmail($event)
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.currentView === "Notes"
                          ? _c("notes", {
                              ref: "Notes",
                              attrs: {
                                client: _vm.client,
                                "is-active": _vm.currentView === "Notes",
                                filter: _vm.filters["Notes"]
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "filter",
                                    fn: function() {
                                      return [
                                        _c("client-filter", {
                                          key: _vm.refreshkey,
                                          staticClass: "mr-4",
                                          attrs: {
                                            "current-view": _vm.currentView,
                                            projects: _vm.projects.filter(
                                              function(p) {
                                                return p.active
                                              }
                                            )
                                          },
                                          on: {
                                            "clear-filter": function($event) {
                                              return _vm.clearFilter(
                                                _vm.currentView
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.filters[_vm.currentView],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                _vm.currentView,
                                                $$v
                                              )
                                            },
                                            expression: "filters[currentView]"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1587146540
                              )
                            })
                          : _vm._e(),
                        _vm.currentView === "Invoices"
                          ? _c("invoice-list", {
                              ref: "Invoices",
                              attrs: {
                                client: _vm.client,
                                "is-active": _vm.currentView === "Invoices",
                                filter: _vm.filters["Invoices"],
                                currencies: _vm.currencies
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "filter",
                                    fn: function() {
                                      return [
                                        _c("client-filter", {
                                          key: _vm.refreshkey,
                                          staticClass: "mr-4",
                                          attrs: {
                                            "current-view": _vm.currentView,
                                            projects: _vm.projects.filter(
                                              function(p) {
                                                return p.active
                                              }
                                            )
                                          },
                                          on: {
                                            "clear-filter": function($event) {
                                              return _vm.clearFilter(
                                                _vm.currentView
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.filters[_vm.currentView],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                _vm.currentView,
                                                $$v
                                              )
                                            },
                                            expression: "filters[currentView]"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1587146540
                              )
                            })
                          : _vm._e(),
                        _vm.currentView === "Deposits"
                          ? _c("deposit-list", {
                              ref: "Deposits",
                              attrs: {
                                client: _vm.client,
                                "is-active": _vm.currentView === "Deposits",
                                filter: _vm.filters["Deposits"],
                                currencies: _vm.currencies
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "filter",
                                    fn: function() {
                                      return [
                                        _c("client-filter", {
                                          key: _vm.refreshkey,
                                          staticClass: "mr-4",
                                          attrs: {
                                            "current-view": _vm.currentView,
                                            projects: _vm.projects.filter(
                                              function(p) {
                                                return p.active
                                              }
                                            )
                                          },
                                          on: {
                                            "clear-filter": function($event) {
                                              return _vm.clearFilter(
                                                _vm.currentView
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.filters[_vm.currentView],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                _vm.currentView,
                                                $$v
                                              )
                                            },
                                            expression: "filters[currentView]"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1587146540
                              )
                            })
                          : _vm._e(),
                        _vm.currentView === "Time"
                          ? _c("timer-event-list", {
                              ref: "Time",
                              attrs: {
                                client: _vm.client,
                                "is-active": _vm.currentView === "Time",
                                filter: _vm.filters["Time"]
                              },
                              on: {
                                "quick-invoice": function($event) {
                                  return _vm.$refs.Invoices.addNewQuick()
                                },
                                "open-invoice": function($event) {
                                  return _vm.$refs.Invoices.openInvoice($event)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "filter",
                                    fn: function() {
                                      return [
                                        _c("client-filter", {
                                          key: _vm.refreshkey,
                                          staticClass: "mr-4",
                                          attrs: {
                                            "current-view": _vm.currentView,
                                            projects: _vm.projects.filter(
                                              function(p) {
                                                return p.active
                                              }
                                            )
                                          },
                                          on: {
                                            "clear-filter": function($event) {
                                              return _vm.clearFilter(
                                                _vm.currentView
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.filters[_vm.currentView],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                _vm.currentView,
                                                $$v
                                              )
                                            },
                                            expression: "filters[currentView]"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1587146540
                              )
                            })
                          : _vm._e(),
                        _vm.currentView === "Agreements"
                          ? _c("proposal-list", {
                              ref: "Agreements",
                              attrs: {
                                client: _vm.client,
                                "is-active": _vm.currentView === "Agreements",
                                filter: _vm.filters["Agreements"],
                                "proposal-id": _vm.proposalId
                              },
                              on: {
                                newProjectCreated: function($event) {
                                  return _vm.newProjectCreated()
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "filter",
                                    fn: function() {
                                      return [
                                        _c("client-filter", {
                                          key: _vm.refreshkey,
                                          staticClass: "mr-4",
                                          attrs: {
                                            "current-view": _vm.currentView,
                                            projects: _vm.projects.filter(
                                              function(p) {
                                                return p.active
                                              }
                                            )
                                          },
                                          on: {
                                            "clear-filter": function($event) {
                                              return _vm.clearFilter(
                                                _vm.currentView
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.filters[_vm.currentView],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                _vm.currentView,
                                                $$v
                                              )
                                            },
                                            expression: "filters[currentView]"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1587146540
                              )
                            })
                          : _vm._e(),
                        _vm.currentView === "Forms"
                          ? _c("forms-list", {
                              ref: "Forms",
                              attrs: {
                                client: _vm.client,
                                "is-active": _vm.currentView === "Forms",
                                filter: _vm.filters["Forms"]
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "filter",
                                    fn: function() {
                                      return [
                                        _c("client-filter", {
                                          key: _vm.refreshkey,
                                          staticClass: "mr-4",
                                          attrs: {
                                            "current-view": _vm.currentView,
                                            projects: _vm.projects.filter(
                                              function(p) {
                                                return p.active
                                              }
                                            )
                                          },
                                          on: {
                                            "clear-filter": function($event) {
                                              return _vm.clearFilter(
                                                _vm.currentView
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.filters[_vm.currentView],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                _vm.currentView,
                                                $$v
                                              )
                                            },
                                            expression: "filters[currentView]"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1587146540
                              )
                            })
                          : _vm._e(),
                        _vm.currentView === "Inbox"
                          ? _c("inbox", {
                              ref: "Inbox",
                              attrs: {
                                "email-boxes": _vm.$store.state.emailBoxes,
                                subscription:
                                  _vm.$store.state.communicatorSubscription,
                                "is-active": _vm.currentView === "Inbox",
                                "client-id": _vm.client.id
                              }
                            })
                          : _vm._e(),
                        _vm.currentView === "Tickets"
                          ? _c("ticket-view", {
                              ref: "Tickets",
                              attrs: {
                                "is-active": _vm.currentView === "Tickets",
                                "client-id": _vm.client.id
                              }
                            })
                          : _vm._e(),
                        _vm.currentView === "Meetings"
                          ? _c("meeting-list", {
                              ref: "Meetings",
                              attrs: {
                                client: _vm.client,
                                "is-active": _vm.currentView === "Meetings",
                                filter: _vm.filters["Meetings"]
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "filter",
                                    fn: function() {
                                      return [
                                        _c("client-filter", {
                                          key: _vm.refreshkey,
                                          staticClass: "mr-4",
                                          attrs: {
                                            "current-view": _vm.currentView,
                                            projects: _vm.projects.filter(
                                              function(p) {
                                                return p.active
                                              }
                                            )
                                          },
                                          on: {
                                            "clear-filter": function($event) {
                                              return _vm.clearFilter(
                                                _vm.currentView
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.filters[_vm.currentView],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                _vm.currentView,
                                                $$v
                                              )
                                            },
                                            expression: "filters[currentView]"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1587146540
                              )
                            })
                          : _vm._e(),
                        _c("client-opportunities", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currentView === "Opportunities",
                              expression: "currentView === 'Opportunities'"
                            }
                          ],
                          ref: "Opportunities",
                          attrs: {
                            client: _vm.client,
                            "is-active": _vm.currentView === "Opportunities",
                            filter: _vm.filters["Opportunities"]
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "filter",
                                fn: function() {
                                  return [
                                    _c("client-filter", {
                                      key: _vm.refreshkey,
                                      staticClass: "mr-4",
                                      attrs: {
                                        "current-view": _vm.currentView,
                                        projects: _vm.projects.filter(function(
                                          p
                                        ) {
                                          return p.active
                                        })
                                      },
                                      on: {
                                        "clear-filter": function($event) {
                                          return _vm.clearFilter(
                                            _vm.currentView
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.filters[_vm.currentView],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            _vm.currentView,
                                            $$v
                                          )
                                        },
                                        expression: "filters[currentView]"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1587146540
                          )
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }