<template>
	<div class="column-format gap-2">
		<div>
			<div class="">Width ({{ element.width }}%)</div>
			<v-slider
				v-model="element.scale"
				:min="10"
				:max="100"
				:step="1"
				hide-details
				dense
				@input="updated"
				thumb-label
			></v-slider>
		</div>
		<div class="row-format gap-1">
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					v-model="element.minWidth"
					label="Min width (px)"
					@input="updated"
			></v-text-field>
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					v-model="element.maxWidth"
					label="Max width (px)"
					@input="updated"
			></v-text-field>
		</div>
		<v-select
			v-model="element.alignment"
			:items="alignment"
			item-text="label"
			item-value="value"
			hide-details
			dense
			outlined
			label="Alignment"
			@change="updated"
		></v-select>
		<v-text-field
			hide-details
			dense
			outlined
			type="number"
			v-model="element.padding"
			label="Padding"
			@input="updated"
		></v-text-field>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pt"
				label="Margin top"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pb"
				label="Margin bottom"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pl"
				label="Margin left"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				min="0"
				max="20"
				v-model="element.pr"
				label="Margin right"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format gap-1">
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.borderWidth"
				label="Border width"
				@input="updated"
			></v-text-field>
			<v-text-field
				hide-details
				dense
				outlined
				type="number"
				v-model="element.borderRadius"
				label="Border radius"
				@input="updated"
			></v-text-field>
		</div>
		<div class="row-format text-left gap-1">
			<div style="flex: 1 1 0; width: 0;">
				<div>Background color</div>
				<color-selector v-model="element.backgroundColor" @input="updated"></color-selector>
			</div>
			<div style="flex: 1 1 0; width: 0;">
				<div>Border color</div>
				<color-selector v-model="element.borderColor" @input="updated"></color-selector>
			</div>
		</div>
		<div class="row-format">
			<div>Image library</div>
			<div class="ml-auto pointer font-primary row-format align-center" @click="imageSearch=true">
				<v-icon small color="primary">search</v-icon>
				<div>Search</div>
			</div>
		</div>

		<div class="image-grid">
			<div
				v-for="result in imageAssets"
				:key="result.id"
				:style="`background-image: url(${result.thumbnail});`"
				class="pointer image-container custom-image-container"
				@click="setCustomImage(result)"
			>
				<v-btn class="secondary-action" icon x-small @click.stop="confirmDelete(result)"
					><v-icon size="16">$delete</v-icon></v-btn
				>
			</div>

			<vue-dropzone
				ref="fileDropzone"
				id="dropzone"
				class="image-container no-image-container"
				v-on:vdropzone-success="uploadSuccess"
				v-on:vdropzone-error="uploadFailure"
				v-on:vdropzone-sending="addFileUploadAuthHeader"
				:use-custom-slot="true"
				:options="dropzoneOptions"
			>
				<div>
					<v-icon>$vuetify.icons.upload</v-icon>
					<div class="font-12">{{ $t('proposal.cover.upload') }}</div>
				</div>
			</vue-dropzone>
		</div>

		<basic-modal v-show="imageSearch" :persistent="false" :dialog="imageSearch" style="background-color: var(--v-white-base); min-width: 300px;" @close="imageSearch=false">
			<image-search @close="imageSearch=false" @result="setUnsplashImage($event)"></image-search>
		</basic-modal>
	</div>
</template>

<script>
	import vueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import ImageMixin from '@/modules/agreements/config/ImageMixin';
	import BasicModal from "@/components/BasicModal";
	import ImageSearch from "@/modules/agreements/config/ImageSearch";
	import ColorSelector from "@/components/ColorSelector";

	export default {
		name: 'ImageBlockConfig',

		props: ['agreement', 'item'],

		mixins: [ImageMixin],

		components: {ColorSelector, vueDropzone, BasicModal, ImageSearch},

		data: function() {
			return {
				imageSearch: false,
				colorMenu: false,
				borderMenu: false,
				element: { ...this.item },
				alignment: [
					{ label: 'Left', value: 'left' },
					{ label: 'Right', value: 'right' },
					{ label: 'Center', value: 'center' },
				],
			};
		},

		mounted() {
			this.getImageAssets();
		},

		beforeDestroy() {},

		methods: {
			setCustomImage: function(imageAsset) {
				this.element.url = imageAsset.fullSize;
				this.updated();
			},

			setUnsplashImage: function(url){
				this.element.url = url;
				this.imageSearch = false;
				this.updated();
			},

			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.image-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 6px;

		.image-container {
			//width: 100%;
			height: 100px;
			width: 100px;
			border-radius: 2px;
			display: flex;
			align-items: flex-end;
			background-position: center;

			&.custom-image-container {
				align-items: flex-start;
				justify-content: flex-end;
				padding: 4px;

				.v-btn {
					opacity: 0;
					transition: opacity 300ms;
				}

				&:hover {
					.v-btn {
						opacity: 1;
						transition: opacity 300ms;
					}
				}
			}
		}
	}
</style>
