<template>
	<div>
		<div class="mb-2 pl-2 row-format align-center">
			<v-menu
					nudge-top="0"
					max-width="200"
					bottom
					right
					rounded
					offset-overflow
					offset-y
					:close-on-content-click="false"
					content-class="filter-menu-class"
					transition="slide-y-transition"
					v-for="(user,index) in users"  :key="user.userId">
				<template v-slot:activator="{ on }">
					<assigned-user @click="on.click" :style="`border-radius: 100px; border: 2px solid var(--v-white-base)!important; z-index: ${10-index}`" class="ml-n2" :large="true"  :assigned-to="user.userId" :show-name="false"></assigned-user>
				</template>
				<div class="pa4">
					<div class="more-menu-item" @click="revokeAccess(user)">Revoke access</div>
				</div>
			</v-menu>
			<div class="row-format centered contact-avatar-new pointer" @click="inviteUser" v-if="isAccountFullUser">
				<v-icon color="gray_50">$plus</v-icon>
			</div>
		</div>
		<div v-if="invitations.length" class="font-gray_70 font-12 pointer" @click="$router.push('/team')">+ {{invitations.length}} pending invitation(s)</div>
	</div>
</template>

<script>
	import UserService from '@/modules/users/UserService';
	import InviteDialog from '@/modules/users/InviteDialog';
	import AssignedUser from '@/components/AssignedUser';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'ProjectCollaborators',
		props: { project: { type: Object } },
		components: { AssignedUser },

		data() {
			return {
				view: 0,
				users: [],
				invitations: [],
				userService: new UserService(),
			};
		},

		mounted() {
			console.log('project collaborators');
			this.getCollaborators();

			if(this.isAccountFullUser) {
				this.getInvitations();
			}
		},

		methods: {
			routeToUsers: function () {
				this.$router.push('/team');
			},

			getCollaborators: function () {
				this.userService
					.getProjectCollaborators(this.$store.getters.getAccountId, this.project.id)
					.then((res) => {
						this.users.splice(0,this.users.length);
						this.users.push(... res.data);
						console.log(this.users);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			getInvitations: function () {
				this.userService
					.getPendingProjectInvitations(this.$store.getters.getAccountId, this.project.id)
					.then((res) => {
						this.invitations = res.data;
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			inviteUser: function () {
				let binding = {
					projectId: this.project.id,
				};

				this.$store.state.globalModalController.openModal(InviteDialog, binding).then((res) => {
					if (res) {
						this.$store.commit('success', 'Invitation sent.');
						this.getInvitations();
					}
				});
			},

			revokeAccess: function (user) {
				let binding = {
					icon: '$users',
					headingText: 'Remove User',
					bodyText: 'Are you sure you want to revoke this users access?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.userService
							.revokeProjectAccess(this.$store.getters.getAccountId, this.project.id, user.email)
							.then(() => {
								this.getCollaborators();
							})
							.catch((err) => this.$store.commit('error', err.response.data.message));
					}
				});
			},

			alert(type, message) {
				this.$emit('alert', { type: type, message: message });
			},
		},

		computed: {
			isPaid: function () {
				return this.$store.getters.isPaidAccount;
			},

			isAccountFullUser: function(){
				return 	this.$store.getters.isAccountFullUser;
			},
		},

		watch: {},
	};
</script>

<style lang="scss" scoped>
	.contact-avatar-new {
		width: 48px;
		height: 48px;
		border-radius: 100px;
		background-color: var(--v-white-base);
		color: var(--v-white-base);
		font-size: 16px;
		border: 2px dashed var(--v-gray_30-base);
		margin-left: -8px;
	}
</style>
