<template>
	<div v-if="isReady" :class="`mx-8 mx-sm-16 mb-6 ${$vuetify.breakpoint.xs ? 'mt-6' : ''}`">
		<v-form v-if="screen === 'questions'" id="question" ref="questionForm" @submit.prevent="submitQuestion()" lazy-validation>
			<div class="">
				<h1 class="mb-3">{{ $t('subscription.cancel.feedback.heading') }}</h1>
				<div class="justify-center mb-8 px-4 mx-4">
					{{ $t('subscription.cancel.feedback.subheading') }}
				</div>

				<v-btn class="mb-8" @click="$emit('close')" color="accent" large block>
					{{ $t('subscription.cancel.cancel-button') }}
				</v-btn>

				<v-radio-group v-model="reason" hint="Please make a selection" :rules="rules">
					<div v-for="(question, i) in $t('subscription.cancel.feedback.questions')" :key="i" class="mt-2 mb-3">
						<v-radio :value="question" :label="question" class="custom" />
						<div v-if="[1, 2, 3, 4, 5, 7].includes(i) && reason === question" class="ma-0">
							<v-text-field
								v-model="comments"
								:label="$t('subscription.cancel.feedback.comment-label')"
							></v-text-field>
						</div>
					</div>
				</v-radio-group>
				<v-btn type="submit" class="mt-2" large color="primary" block>{{ $t('subscription.cancel.feedback.button') }}</v-btn>
			</div>
		</v-form>

		<v-form v-if="screen === 'improvement'" @submit.prevent="submitImprovement()">
			<h1 class="mb-3">{{ $t('subscription.cancel.feedback.heading') }}</h1>
			<div class="justify-center mb-12 px-2 mx-2">
				{{ $t('subscription.cancel.feedback.subheading2') }}
			</div>

			<div class="mb-12">
				<h3>
				{{ $t('subscription.cancel.feedback.improvement') }}
				</h3>
				<v-text-field
					v-model="improve"
				/>
			</div>
			<v-btn class="mb-4" @click="$emit('close')" color="accent" large block>
				{{ $t('subscription.cancel.cancel-button') }}
			</v-btn>
			<v-btn type="submit" large color="primary" block>
				{{ $t('subscription.cancel.feedback.button') }}
			</v-btn>
		</v-form>

		<v-form v-if="screen === 'confirm'" id="confirm" @submit.prevent="downgradeFeedback()">
			<div>
				<h1
					v-html="
						$t('subscription.cancel.confirm.heading', {
							'old-plan-name': oldPlan['name'],
							'days-to-expiry': daysToExpiry,
							'new-price': newPlan['price'],
							'old-price': oldPlan['price'],
						})
					"
				/>
				<div
					v-for="(subheading, i) in $t('subscription.cancel.confirm.subheading', {
						'old-plan-name': oldPlan['name'],
						'days-to-expiry': daysToExpiry,
						'new-price': newPlan['price'],
						'old-price': oldPlan['price'],
					})"
					:key="i"
					class="mt-4 mb-3"
				>
					{{ subheading }}
				</div>
				<v-btn
					@click="
						screen = '';
						$emit('close');
					"
					block
					large
					color="accent"
					class="mt-12"
				>
					{{ $t('subscription.cancel.cancel-button') }}
				</v-btn>

				<v-btn type="submit" block large color="primary" class="mt-4">{{
					$t('subscription.cancel.confirm.button')
				}}</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
	import SubscriptionService from '../SubscriptionService';
	export default {
		name: 'PlanCancel',
		props: { planHash: { type: Object }, customerPlan: { type: Object }, planCode: { type: String } },

		data() {
			return {
				subscriptionService: new SubscriptionService(),
				heading: '',
				subheading: '',
				button: '',
				cancelButton: '',
				buttonText: '',
				reason: '',
				comments: '',
				improve: '',
				screen: 'questions',
				oldPlan: null,
				newPlan: null,
				daysToExpiry: null,
				questionError: false,
				isReady: false,
			};
		},

		mounted() {
			this.buildTextAndButtons();
			// console.log('PlanCancel', this.planCode, this.customerPlan);
			// console.log([...Array(7).keys()]);
		},

		methods: {
			submitQuestion() {
				if (this.$refs.questionForm.validate()) {
					this.screen = 'improvement';
				}
			},
			submitImprovement() {
				this.screen = 'confirm';
			},
			alert(type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},
			downgradeFeedback() {
				this.subscriptionService
					.downgradeFeedback(this.reason, this.comments, this.improve)
					.then((response) => {
						console.log('downgradeFeedback response', response);
						this.$emit('plan-change', this.planCode);
						this.$emit('close');
					})
					.catch((error) => {
						console.log('postChange error', error.response);
						this.alert('error', 'An error occurred.');
					});
			},
			buildTextAndButtons() {
				this.newPlan = this.planHash[this.planCode];
				this.oldPlan = this.planHash[this.customerPlan.plan.code];
				if (!this.oldPlan) this.oldPlan = this.planHash['pro-monthly']; // ugly hack for backwards compatibility

				let start = this.$luxon.DateTime.local();
				let end = this.$luxon.DateTime.fromISO(this.customerPlan.currentPeriodEndsAt);
				let i = this.$luxon.Interval.fromDateTimes(start, end);
				console.log(i);
				this.daysToExpiry = parseInt(i.length('days'));
				this.isReady = true;
			},
		},
		computed: {
			rules() {
				let rules = [];
				if (this.reason.length === 0) {
					const rule = 'Please make a selection to continue.';
					rules.push(rule);
				}
				return rules;
			},
		},
	};
</script>

<style scoped>
	.custom /deep/ label {
		color: var(--v-black-base) !important;
		opacity: 1 !important;
	}
</style>
