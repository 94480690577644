var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.call
    ? _c(
        "div",
        {
          staticClass: "row-format gap-2 font-14 pointer",
          on: { click: _vm.openCall }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { content: "Phone call" },
                  expression: "{ content: 'Phone call' }"
                }
              ],
              staticClass: "material-symbols-rounded",
              attrs: { color: "secondary", size: "20" }
            },
            [_vm._v("phone")]
          ),
          _vm.contact
            ? _c("div", [_vm._v(_vm._s(_vm.contact.fullName))])
            : _c("div", [_vm._v(_vm._s(_vm.formatPhone(_vm.phone)))]),
          _vm.call.terminatingEndpoint
            ? _c("div", {}, [
                _vm._v(
                  "| " +
                    _vm._s(
                      _vm.$formatters.formatSecondsFull(
                        _vm.call.terminatingEndpoint.duration
                      )
                    )
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "ml-auto font-gray_80 font-12" }, [
            _vm._v(_vm._s(_vm._f("formatForTimeAgo")(_vm.call.startTime)))
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }