import DeliverableFullPage from "@/modules/projects/deliverable/DeliverableFullPage";
import ProjectList from "@/modules/projects/ProjectList";
import ProjectRouter from "@/modules/projects/ProjectRouter";
import ProjectDetailView from "@/modules/projects/detail/ProjectDetailView";
import DeliverableStatus from "@/modules/projects/settings/DeliverableStatus";
import ProjectManagementRoute from "@/modules/projects/management/ProjectManagementRoute";
import DeliverablePackageList from "@/modules/templates/deliverables/DeliverablePackageList";

export default [
	{
		path: '/projects',
		name: 'projectRouter',
		component: ProjectRouter,
		title: 'Project Router',
		props: true
	},
	{
		path: '/projects/overview',
		name: 'ProjectList',
		component: ProjectList,
		title: 'Project List',
		props: true
	},
	{
		path: '/projects/list',
		name: 'ProjectList',
		component: ProjectList,
		title: 'Project List',
		props: true
	},
	{
		path: '/projects/tasks',
		name: 'ProjectManagement',
		component: ProjectManagementRoute,
		title: 'Project Management',
		props: true
	},
	{
		path: '/project/:projectId',
		name: 'ProjectDetail',
		component: ProjectDetailView,
		title: 'Project detail',
		props: (route) => ({
			projectId: route.params.projectId,
			v: route.query.v
		}),
	},
	{
		path: '/deliverable/:deliverableId',
		name: 'deliverableFullPage',
		component: DeliverableFullPage,
		title: 'Deliverable Page',
		props: true,
	},
	{
		path: '/projects/templates',
		name: 'settingsRouter',
		component: DeliverablePackageList,
		title: 'templates router',
		props: true
	},
	{
		path: '/projects/settings',
		name: 'settingsRouter',
		component: DeliverableStatus,
		title: 'Settings router',
		props: true
	}
];
