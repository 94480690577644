<template>
	<div  :class="`row-format align-center ml-1 ${view === 'Two-Week' ? 'mt-1' : ''}`">
		<client-avatar disable-click x-small  style="transform: scale(.92)" :client="client"></client-avatar>
		<div :class="`ml-1 row-format align-center ${isDarkBackground || isDarkMode ? 'font-white' : 'font-gray_90'}`" style="width: 100%">
			<div class="brand-medium">{{project.name}}</div>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";
	import chroma from 'chroma-js';

	export default {
		name: 'ProjectEvent',

		props: ['event','view'],

		components: { ClientAvatar },

		data: function () {
			return {
				project: this.event.original.meta.project,
				client: this.event.original.meta.project.client
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {},

		computed: {
			isDarkMode: function(){
				return window.matchMedia('(prefers-color-scheme: dark)').matches;
			},

			isDarkBackground: function(){
				try {
					return chroma(this.event.original.meta.project.hexColor).get('lab.l') < 60;
				}catch(err){
					return false;
				}
			}
		},
	};
</script>

<style scoped lang="scss">
</style>
