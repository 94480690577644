import HDateTime from "@/modules/utils/HDateTime";
import CommunicatorMessageService from "@/modules/communicator/CommunicatorMessageService";

export default {
    data: function () {
        return {
            HDateTime: HDateTime,
            communicatorService: new CommunicatorMessageService(),
            ourThread: null,
            ourFolder: null,
        }
    },

    methods: {
        markThreadAsRead: function(){
            this.communicatorService.markThreadAsRead(this.ourThread.id,this.ourThread.unread).then((res) => {
                this.ourThread = res.data;
                this.$emit('message-thread-updated',res.data);
            });
        },

        snooze: function(snoozeUntil){
            this.communicatorService.setSnooze(this.ourThread.id,snoozeUntil.toISO()).then((res) => {
                this.ourThread = res.data;
                this.$emit('message-thread-updated',res.data);
                this.$emit('back');
            });
        },

        returnToInbox: function(){
            this.communicatorService.returnToInbox(this.ourThread.id).then((res) => {
                this.ourFolder = 'Inbox';
                this.ourThread = res.data;
                this.$emit('message-thread-updated',res.data);
            });
        },

        archive: function(){
            this.communicatorService.setArchiveFlag(this.ourThread.id,!this.ourThread.archive).then((res) => {
                this.ourThread = res.data;
                this.$emit('message-thread-updated',res.data);
                this.$emit('back');
            });
        },
    }
}