import { render, staticRenderFns } from "./SendWidget.vue?vue&type=template&id=948bd6f8&scoped=true&"
import script from "./SendWidget.vue?vue&type=script&lang=js&"
export * from "./SendWidget.vue?vue&type=script&lang=js&"
import style0 from "./SendWidget.vue?vue&type=style&index=0&id=948bd6f8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "948bd6f8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VChip,VCol,VContainer,VIcon,VMenu,VRow,VSimpleCheckbox,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src834000100/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('948bd6f8')) {
      api.createRecord('948bd6f8', component.options)
    } else {
      api.reload('948bd6f8', component.options)
    }
    module.hot.accept("./SendWidget.vue?vue&type=template&id=948bd6f8&scoped=true&", function () {
      api.rerender('948bd6f8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/invoices/SendWidget.vue"
export default component.exports