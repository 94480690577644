<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-deliverable')" @action="navigateToDeliverable()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small :client="client" />
			<div class="ml-2 text-left">{{ deliverableName }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ $t('notifications.deliverable-updated') }}</div>
			<div class="font-12 text-left">
				<span>{{ updatedBy}} updated status to {{statusName}}</span>
				<span class="px-1"> • </span>
				<span>{{ notificationPayload.updatedAt | formatForTimeAgo }}</span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";
	import DeliverableDetail from "@/modules/projects/deliverable/DeliverableDetail";

	export default {
		name: 'DeliverableUpdateNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar, NotificationLayout},

		data: function() {
			return {
				deliverableName: this.notificationPayload.deliverableName,
				client: this.notificationPayload.client,
				updatedBy: this.notificationPayload.updatedBy,
				deliverableId: this.notificationPayload.deliverableId,
				projectId: this.notificationPayload.projectId,
				projectName: this.notificationPayload.projectName,
				statusName: this.notificationPayload.statusName,
				updatedAt: this.notificationPayload.updatedAt,
				expanded: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {

			navigateToDeliverable: function() {
				let binding = {
					deliverableId: this.deliverableId,
					project: {
						id: this.projectId,
						name: this.projectName
					},
					client: this.client
				}
				this.$store.state.globalModalController.openModal(DeliverableDetail,binding);
			},

			toggleExpand: function() {
				this.expanded = !this.expanded;
			},
		},

		computed: {

		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
