<template>
	<div class="text-left font-14 column-format" style="min-height: 300px">
		<div class="row-format align-center font-14 brand-medium header py-1" style="width: 100%">
			<v-btn icon @click="$emit('back')"><v-icon small>$chevronLeft</v-icon></v-btn>
			<div>{{ participants.join(' & ') }}</div>
			<div class="ml-auto delete">
				<v-btn class="icon" icon @click="$emit('delete')"><v-icon small>$delete</v-icon></v-btn>
			</div>
		</div>
		<div :id="`${randomKey}-communicator-messages`" v-if="messages" style="max-height: calc(var(--vh) - 300px); overflow-y: scroll" class="messages px-2">
			<div class="row-format centered pointer font-12 font-gray_70 mt-4" v-if="messages.length >= recordsPerFetch"><div @click="$emit('more-messages')">Older messages</div></div>
			<div v-for="(message, ix) in messages" :key="randomKey + '-' + message.id" class="my-2 row-format">
				<div class="column-format" style="width: 100%">
					<div
						:class="`font-10 font-gray_70 mt-2 ${message.direction === 'Out' ? 'ml-auto mr-1' : 'ml-1'}`"
						v-if="showTimeStamp(ix)"
					>
						{{ $formatters.formatTimeAgo(message.timestamp) }}
					</div>
					<div v-if="participants.length > 1 && message.direction === 'In'" class="font-gray_70 font-12 mt-1 ml-1">{{participantMap.get(message.from)}}</div>

					<div :class="message.direction">
						<div v-if="message.message && message.direction === 'In'">{{ message.message }}</div>
						<div v-if="message.message && message.direction === 'Out'" v-tippy="{content: getUserFirstName(message.userId)}">{{ message.message }}</div>
						<div v-for="s3File in message.s3Media" :key="s3File.path + s3File.fileName">
							<s3-file-handler :s3-file="s3File"></s3-file-handler>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-auto pt-2 px-2">
			<div @drop.prevent="addDropFile" @dragover.prevent>
				<v-textarea
					dense
					hide-details
					outlined
					rows="1"
					no-resize
					auto-grow
					autofocus
					v-model="newMessage"
					@keydown.enter.exact.prevent
					@keyup.enter.exact="sendMessage"
				>
					<template v-slot:append>
						<div
							class="font-secondary pointer py-1 pl-2"
							style="border-left: 1px solid var(--v-gray_50-base); margin-top: 2px"
							@click="sendMessage"
						>
							Send
						</div>
					</template>
				</v-textarea>
				<div class="row-format align-center mt-2" v-if="attachment">
					<v-chip class="truncate" small>{{attachment.name}}</v-chip>
					<v-icon @click="attachment = null" class="pointer" color="black" small>close</v-icon>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import libPhoneNumber from 'google-libphonenumber';
	import { DateTime } from 'luxon';
	import CommunicatorMessageService from "@/modules/communicator/CommunicatorMessageService";
	import S3FileHandler from "@/modules/communicator/inbox/messages/S3FileHandler";

	export default {
		name: 'Thread',

		props: ['thread', 'messageUpdate','randomKey','recordsPerFetch'],

		emits: ['more-messages'],

		components: {S3FileHandler},

		data: function () {
			return {
				communicatorService: new CommunicatorMessageService(),
				newMessage: null,
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
				messages: [],
				attachment: null,
			};
		},

		mounted() {
			if (this.thread.messages) {
				this.messages.push(...this.thread.messages);
			}
			this.$nextTick(() => this.scrollToBottom());
		},

		beforeDestroy() {},

		methods: {
			getUserFirstName: function(userId){
				let user = this.$store.getters.getUserById(userId);
				if(user){
					return user.firstName;
				}else{
					return '?'
				}
			},

			addDropFile: function(e) {
				let attachments = [];
				attachments.push(...Array.from(e.dataTransfer.files));
				if(attachments.length){
					this.attachment = attachments[0];
				}
			},

			addMoreMessages: function(threadId,messages){
				if(threadId !== this.thread.id){
					return;
				}
				this.messages.unshift(...messages);
			},

			scrollToBottom: function () {
				let div = document.getElementById(this.randomKey + '-communicator-messages');
				if (div) {
					div.scrollTop = div.scrollHeight;
				}
			},

			sendMessage: function () {
				let payload = {
					threadId: this.thread.id,
					from: this.thread.userPhone,
					message: this.newMessage,
				};

				if(this.attachment) {
					this.$store.commit('startLoading');
					this.communicatorService.sendMessageWithAttachment(payload,this.attachment)
							.catch((err) => this.$store.commit('error',err.response.data.message))
							.finally(() => {
								this.$store.commit('stopLoading');
								this.attachment = null;
								this.newMessage = null;
							});
				}else{
					this.communicatorService.sendMessage(payload).catch((err) => this.$store.commit('error',err.response.data.message));
					this.newMessage = null;
				}
			},

			showTimeStamp: function (index) {
				if (index === 0) {
					return true;
				} else {
					let prev = DateTime.fromISO(this.messages[index - 1].timestamp);
					let curr = DateTime.fromISO(this.messages[index].timestamp);

					if (curr.diff(prev, ['minutes']).minutes > 10) {
						return true;
					} else {
						return false;
					}
				}
			},
		},

		computed: {
			participants: function () {
				if(Array.isArray(this.thread.participants)) {
					return this.thread.participants.map((p) => {
						let contact = this.$store.getters.getContactByPhone(p.phone);
						if (contact) {
							return contact.fullName;
						} else {
							try {
								let phone = this.PhoneUtil.parse(p.phone);
								return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
							} catch (err) {
								return p.phone;
							}
						}
					});
				}else{
					return null;
				}
			},

			participantMap: function(){
				let participants = new Map();
				this.thread.participants.forEach((p) => {
					let contact = this.$store.getters.getContactByPhone(p.phone);
					let value;
					if(contact){
						value = contact.fullName;
					}else{
						let phone = this.PhoneUtil.parse(p.phone);
						value = this.PhoneUtil.format(phone, this.PNF.NATIONAL);
					}
					participants.set(p.phone,value);
				});
				return participants;
			}
		},

		watch: {
			thread: {
				deep: true,
				handler: function (val) {
					if(this.messages){
						this.messages.splice(0, this.messages.length);
						this.messages.push(...val.messages);
						this.$nextTick(() => this.scrollToBottom());
					}
				},
			},
		},
	};
</script>

<style scoped lang="scss">
	.thread {
		padding-left: 4px;
		padding-right: 4px;
		border-bottom: 1px solid var(--v-gray_30-base);
	}

	.messages {
		padding-bottom: 12px;
	}

	.header {
		border-bottom: 1px solid var(--v-gray_30-base);
		.delete {
			visibility: hidden;
		}

		&:hover {
			.delete {
				visibility: visible;
			}
		}
	}

	.In {
		background-color: var(--v-gray_20-base);
		color: var(--v-black-base);
		border-radius: 12px 12px 12px 0px;
		padding: 8px 12px;
		width: 90%;
		white-space: pre-wrap;
	}

	.Out {
		background-color: var(--v-secondary-base);
		color: var(--v-white-base);
		border-radius: 12px 12px 0px 12px;
		padding: 8px 12px;
		width: 90%;
		margin-left: auto;
		white-space: pre-wrap;
	}

	.new-message {
		display: flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		padding: 8px;
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
