<template>
	<div>
		<v-chip class="pointer" color="blue_5" @click="$emit('show-detail')">{{label}}</v-chip>
	</div>
</template>

<script>
import libPhoneNumber from "google-libphonenumber";

export default {
	name: "PersonChip",

	props: ['contact'],

	components: {},

	data: function () {
		return {
			PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
			PNF: libPhoneNumber.PhoneNumberFormat,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		formatPhone: function (phoneNumber) {
			try {
				let phone = this.PhoneUtil.parse(phoneNumber);
				return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
			} catch (err) {
				return phoneNumber;
			}
		}
	},

	computed: {
		label: function(){
			if(!this.contact){
				return "?"
			}
			if(this.contact.firstName || this.contact.lastName){
				return this.contact.firstName + ' ' + this.contact.lastName
			}else if(this.contact.email){
				return this.contact.email;
			}else if(this.contact.phone){
				return this.formatPhone(this.contact.phone);
			}else{
				return "?"
			}
		}
	},

}
</script>

<style scoped lang="scss">

</style>