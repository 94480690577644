<template>
	<div class="column-format flex-grow-1">
		<slot name="filter"></slot>
		<notes class="mt-3" ref="notes" :client="client" :filter="filter"></notes>
	</div>
</template>

<script>
	import Notes from '@/modules/clients/detail/overview/Notes';

	export default {
		name: 'ClientNotes',

		props: ['client', 'filter'],

		components: { Notes },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			addNew: function() {
				this.$refs.notes.addNewNote()
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
