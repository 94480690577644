<template>
	<div class="py-6">
		<v-row>
			<v-col
				:style="
					`--dp-font-family:${meeting.pageLayout.fontFamily};
                  background-color: ${meeting.pageLayout.backgroundMainColor};
                  color: ${textContrast}`
				"
			>
				<div>
					<div class="row-format centered mt-1 mb-2">
						<div>
							<v-btn icon><v-icon size="30" :style="`color: ${textContrast}`">$prev</v-icon></v-btn>
						</div>
						<div class="brand-medium px-4">
							{{ DateTime.fromISO(monthToShow).toLocaleString({ month: 'long', year: 'numeric' }) }}
						</div>
						<div>
							<v-btn icon><v-icon size="30" :style="`color: ${textContrast}`">$next</v-icon></v-btn>
						</div>
					</div>
					<v-date-picker
						class="schedule-picker"
						:min="minDate"
						full-width
						:picker-date="monthToShow"
						:style="`--date-color: ${dateContrast}; --month-header-color: ${monthHeadersContrast}; --dp-font-family: ${meeting.pageLayout.fontFamily}; --dp-background-color:${meeting.pageLayout.backgroundMainColor}; --light-accent:${lightAccent}; --accent:${meeting.pageLayout.accentColor};`"
						:no-title="true"
						:first-day-of-week="$store.state.firstDayOfWeek"
					></v-date-picker>
					<div class="brand-medium my-2">{{ DateTime.now().toLocaleString({ month: 'long', day: 'numeric' }) }}</div>
					<div class="row-format" style="flex-wrap: wrap">
						<div
							v-for="(slot, index) in availableSlots()"
							:key="index"
							@click="selectTimeSlot(slot)"
							class="slot pointer row-format centered"
							:style="
								`--background-color: ${meeting.pageLayout.buttonColor}; --accent-color: ${meeting.pageLayout.accentColor}; --light-accent:${lightAccent}`
							"
						>
							<div :class="`pa-1 ${selectedSlot && selectedSlot.start === slot.start ? 'selected' : ''}`">
								{{ DateTime.fromISO(slot.start).toLocaleString(DateTime.TIME_SIMPLE) }}
							</div>
							<div
								@click.stop="timeConfirmed"
								class="selected selected-slot pa-1"
								:style="
									`overflow: hidden; ${
										selectedSlot && selectedSlot.start === slot.start
											? 'visibility:visible; width:50%'
											: 'visibility:hidden; width:0px'
									}`
								"
							>
								{{ meeting.scheduleMeeting.confirmText }}
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import { DateTime, Settings } from 'luxon';
	import chroma from 'chroma-js';
	import timezones from '@/assets/data/timezones.json';
	export default {
		name: 'MockScheduler',

		props: ['meeting', 'selector'],

		components: {},

		data: function() {
			return {
				timezones: timezones,
				DateTime: DateTime,
				Settings: Settings,
				selectedDate: null,
				selectedSlot: null,
				monthToShow: DateTime.now().toISODate(),
				filter: null,
				timezoneMenu: false,
				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					paste_as_text: false,
					forced_root_block: 'div',
					paste_data_images: true,
					plugins: ['paste', 'lists', 'link', 'table', 'code', 'hr'],
					contextmenu: 'paste | link | table',
					branding: false,
					height: `400px`,
					width: '500px',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'bold italic underline forecolor fontsizeselect | link unlink code',
						'tokenbutton | hr | alignleft aligncenter alignright alignjustify | formatting_group',
					],
					fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 24pt 30pt 36pt 48pt 72pt',
					toolbar_groups: {
						formatting_group: {
							icon: 'format',
							tooltip: 'Formatting',
							items: 'blockquote bullist numlist indent outdent table | removeformat',
						},
						media_group: {
							icon: 'embed',
							tooltip: 'Media',
							items: ' link unlink media',
						},
					},
					setup: (editor) => {
						let items = [];
						this.tokens.forEach((t) => {
							items.push({
								type: 'menuitem',
								text: t,
								onAction: function() {
									editor.insertContent('{{' + t + '}}');
								},
							});
						});
						editor.ui.registry.addMenuButton('tokenbutton', {
							text: 'Tokens',
							fetch: function(callback) {
								callback(items);
							},
						});
					},
				},
			};
		},

		mounted() {
      const time = DateTime.now().toISODate() + 'T09:00:00.000';
      const confirmBtn = { start: time, end: DateTime.fromISO(time).plus({ minutes: 30 }).toISO({ includeOffset: false }) };
      this.selectTimeSlot(confirmBtn);
    },

		beforeDestroy() {},

		methods: {
			timeConfirmed: function() {
        this.$emit('time-confirmed', this.selectedSlot);
				this.$emit('selector', false)
			},

			selectTimeSlot: function(slot) {
				this.selectedSlot = slot;
        this.timeConfirmed();
			},

			suffix: function(num) {
				let j = num % 10,
					k = num % 100;
				if (j === 1 && k !== 11) {
					return `${num}st`;
				} else if (j === 2 && k !== 12) {
					return `${num}nd`;
				} else if (j === 3 && k !== 13) {
					return `${num}rd`;
				} else {
					return `${num}th`;
				}
			},
			availableSlots: function() {
				let result = [];
				let today = DateTime.now().toISODate()
				for (let i = 0; i < 16; i++) {
					let timeHeader = Math.floor(i / 2 + 9);
					if (timeHeader < 10) {
						timeHeader = '0' + Math.floor(i / 2 + 9);
					}
					if (i % 2 === 0) {
						let startTime = DateTime.fromISO(today + 'T' + timeHeader + ':00:00.000');
						let endTime = DateTime.fromISO(today + 'T' + timeHeader + ':30:00.000');
						result.push({
							end: endTime.toISO({ includeOffset: false }),
							start: startTime.toISO({ includeOffset: false }),
						});
					} else {
						let startTime = DateTime.fromISO(today + 'T' + timeHeader + ':30:00.000');
						let endTime = DateTime.fromISO(today + 'T' + (i / 2 + 9) + ':00:00.000');
						result.push({
							end: endTime.toISO({ includeOffset: false }),
							start: startTime.toISO({ includeOffset: false }),
						});
					}
				}
				return result;
			},
		},

		computed: {
			minDate: function() {
				return DateTime.now().toISODate();
			},

			textContrast: function() {
				let black = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#000000');
				let white = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#FFFFFF');
				if (black > white) {
					return '#000';
				} else {
					return '#FFF';
				}
			},

			monthHeadersContrast: function() {
				let black = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#000000');
				let white = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#FFFFFF');
				if (black > white) {
					return '#00000061';
				} else {
					return '#FFFFFFA0';
				}
			},

			dateContrast: function() {
				let black = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#000000');
				let white = chroma.contrast(this.meeting.pageLayout.backgroundMainColor, '#FFFFFF');
				if (black > white) {
					return '#00000042';
				} else {
					return '#FFFFFF70';
				}
			},

			lightAccent: function() {
				return chroma(this.meeting.pageLayout.accentColor)
					.alpha(0.18)
					.hex();
			},

			tokens: function() {
				let scheduler = ['Meeting.Name', 'Meeting.Duration', 'Meeting.Location'];
				return scheduler;
			},
		},
	};
</script>

<style lang="scss">
	#mock-scheduler {
		.v-date-picker-header {
			display: none !important;
		}

		.v-date-picker-table--date__week {
			color: var(--contrast-color);
		}

		.v-date-picker-table {
			height: unset !important;
			background-color: var(--dp-background-color);
			font-family: var(--dp-font-family) !important;

			th {
				color: var(--month-header-color);
			}

			td {
				padding-bottom: 8px;
			}

			.v-btn {
				.v-btn--disabled.v-icon {
				}
				&:not(.v-btn--disabled) {
					font-weight: 800;
					background-color: var(--light-accent);
					.v-btn__content {
						font-family: var(--dp-font-family);
						color: var(--accent) !important;
					}
				}
				&:is(.v-btn--disabled) {
					.v-btn__content {
						font-family: var(--dp-font-family);
						color: var(--date-color);
					}
				}
			}
		}

		.slot {
			min-width: 180px;
			flex: 1 1 0px;
			border: 1px solid var(--v-gray_30-base);
			border-radius: 4px;
			margin: 4px;
			color: var(--accent-color);
			font-weight: 600;
			transition: all 150ms ease;
			background-color: var(--background-color);

			&:hover {
				box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px;
			}
		}

		.selected {
			width: 50%;
		}

		.selected-slot {
			background-color: var(--light-accent);
			color: var(--accent-color);
			transition: all 150ms ease;
		}

		.dot-class {
			border-radius: 20px;
			width: 20px;
			height: 20px;
		}
	}
  .mock-scheduler {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
</style>
