var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "row-format align-center ml-1 " +
        (_vm.view === "Two-Week" ? "mt-1" : "")
    },
    [
      _vm.client
        ? _c("client-avatar", {
            staticStyle: { transform: "scale(.92)" },
            attrs: { "disable-click": "", "x-small": "", client: _vm.client }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "ml-1 font-gray_90 row-format align-center",
          staticStyle: { width: "100%" }
        },
        [
          _c("div", { staticClass: "brand-medium" }, [
            _vm._v("Payment received")
          ]),
          _c("div", { staticClass: "brand-semilight ml-1" }, [
            _vm._v(
              _vm._s(
                _vm.$formatters.dollars(
                  _vm.payment.amount,
                  true,
                  true,
                  _vm.payment.currency
                )
              )
            )
          ]),
          _c(
            "v-icon",
            {
              staticClass: "ml-auto mr-1",
              attrs: { color: "success", size: "14" }
            },
            [_vm._v("$money")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }