var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isCollaborator
    ? _c(
        "v-menu",
        {
          attrs: { "offset-y": "", "close-on-content-click": false },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "div",
                      { staticClass: "row-format centered" },
                      [
                        _vm.column.clientApproval
                          ? _c(
                              "v-icon",
                              _vm._g(
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        content: _vm.$t("projects.client-icon")
                                      },
                                      expression:
                                        "{ content: $t('projects.client-icon') }"
                                    }
                                  ],
                                  attrs: { small: "", color: "primary" }
                                },
                                on
                              ),
                              [_vm._v(" $clients ")]
                            )
                          : _vm._e(),
                        _vm.column.complete
                          ? _c(
                              "v-icon",
                              _vm._g(
                                {
                                  directives: [
                                    {
                                      name: "tippy",
                                      rawName: "v-tippy",
                                      value: {
                                        content: _vm.$t(
                                          "projects.complete-icon"
                                        )
                                      },
                                      expression:
                                        "{ content: $t('projects.complete-icon') }"
                                    }
                                  ],
                                  staticStyle: { "font-weight": "bold" },
                                  attrs: { color: "green", small: "" }
                                },
                                on
                              ),
                              [_vm._v("check")]
                            )
                          : _vm._e(),
                        _c("v-icon", _vm._g({ attrs: { small: "" } }, on), [
                          _vm._v("$moreHorizontal")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            false,
            3283931447
          )
        },
        [
          _c("v-card", [
            _c(
              "div",
              { staticClass: "column-format centered py-3" },
              [
                _c(
                  "div",
                  { staticClass: "color-picker-div" },
                  [
                    _c("v-color-picker", {
                      staticClass: "mx-auto",
                      attrs: {
                        flat: "",
                        mode: "hexa",
                        "show-swatches": "",
                        "hide-canvas": "",
                        "hide-mode-switch": "",
                        "hide-inputs": "",
                        "swatches-max-height": "200",
                        swatches: _vm.clientPalette.palette
                      },
                      on: {
                        input: function($event) {
                          return _vm.updateDeliverableStatusList()
                        }
                      },
                      model: {
                        value: _vm.column.hexColor,
                        callback: function($$v) {
                          _vm.$set(_vm.column, "hexColor", $$v)
                        },
                        expression: "column.hexColor"
                      }
                    })
                  ],
                  1
                ),
                _c("v-switch", {
                  staticClass: "mt-0",
                  attrs: { "hide-details": "", dense: "" },
                  on: {
                    change: function($event) {
                      return _vm.updateDeliverableStatusList()
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "span",
                              { staticClass: "font-14 font-gray_90 pr-1" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("projects.client-approval"))
                                )
                              ]
                            ),
                            _c(
                              "v-icon",
                              {
                                directives: [
                                  {
                                    name: "tippy",
                                    rawName: "v-tippy",
                                    value: {
                                      content: _vm.$t("projects.action-icon")
                                    },
                                    expression:
                                      "{ content: $t('projects.action-icon') }"
                                  }
                                ],
                                staticClass: "material-symbols-rounded",
                                attrs: { small: "" }
                              },
                              [_vm._v("help")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    75109956
                  ),
                  model: {
                    value: _vm.column.clientApproval,
                    callback: function($$v) {
                      _vm.$set(_vm.column, "clientApproval", $$v)
                    },
                    expression: "column.clientApproval"
                  }
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-2",
                    attrs: { text: "", disabled: _vm.column.complete },
                    on: {
                      click: function($event) {
                        return _vm.setStatusComplete(_vm.column)
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { color: "green" } },
                      [_vm._v("check")]
                    ),
                    _vm._v(
                      " " + _vm._s(_vm.$t("projects.set-as-complete")) + " "
                    )
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { text: "" },
                    on: {
                      click: function($event) {
                        return _vm.confirmDeleteColumn(_vm.column)
                      }
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", color: "gray_80" }
                      },
                      [_vm._v("$delete")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("projects.delete-column-button")) +
                        " "
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }