var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "h-outline pa-6",
      style:
        "border-radius: 12px; max-width:450px; height:100%; " +
        (_vm.cardPlan.code === "pro-monthly"
          ? "background-color: var(--v-gray_10-base)"
          : ""),
      attrs: { id: _vm.planCode }
    },
    [
      _c("div", { staticClass: "text-left mb-2" }, [
        _c("div", { staticClass: "font-24 mb-2" }, [
          _vm._v(
            _vm._s(_vm.$t("subscription.card." + _vm.cardPlan.code + ".header"))
          )
        ]),
        _c("div", { staticClass: "font-16 brand-semilight mb-2" }, [
          _vm._v(
            _vm._s(
              _vm.$t("subscription.card." + _vm.cardPlan.code + ".sub-header")
            )
          )
        ]),
        _vm.$t("subscription.card." + _vm.cardPlan.code + ".price") === "0"
          ? _c("div", { staticStyle: { "font-size": "40px" } }, [
              _vm._v("Free forever")
            ])
          : _c("div", { staticStyle: { "font-size": "40px" } }, [
              _vm._v(
                _vm._s(
                  _vm.$formatters.dollars(
                    _vm.$t("subscription.card." + _vm.cardPlan.code + ".price"),
                    true,
                    true,
                    "USD"
                  )
                )
              )
            ])
      ]),
      _c(
        "v-btn",
        {
          style: _vm.buttonStyle,
          attrs: {
            disabled: _vm.disabled || !_vm.canManageSubscription,
            block: "",
            large: "",
            elevation: "0",
            color: _vm.buttonColor,
            outlined: _vm.buttonOutlined
          },
          on: {
            click: function($event) {
              return _vm.handlePlanClick(_vm.cardPlan.code)
            }
          }
        },
        [_vm._v(" " + _vm._s(this.buttonVerb()) + " ")]
      ),
      _c("v-divider", { staticClass: "my-4" }),
      _vm._l(_vm.bullets, function(bullet, i) {
        return _c(
          "div",
          { key: i, staticClass: "row-format align-center text-left mb-3" },
          [
            _c(
              "v-icon",
              { staticClass: "mr-2", attrs: { size: "20", color: "primary" } },
              [_vm._v("$success")]
            ),
            _c("div", { staticClass: "font-14 brand-semilight" }, [
              _vm._v(_vm._s(bullet))
            ])
          ],
          1
        )
      }),
      _c("p", {
        staticClass: "pending-plan-notice justify-center mb-0 pb-0 mt-2",
        domProps: { innerHTML: _vm._s(_vm.pendingPlanNotice) }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }