var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "pb-1 question",
      domProps: { innerHTML: _vm._s(_vm.htmlHeader) }
    }),
    _c("div", {
      staticClass: "words",
      domProps: { innerHTML: _vm._s(_vm.htmlText) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }