var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pl-2 text-left font-gray_90" }, [
    _c(
      "div",
      { staticClass: "row-format align-center" },
      [
        _c("v-icon", { attrs: { small: "", color: "gray_90" } }, [
          _vm._v("check")
        ]),
        _c(
          "div",
          {
            staticClass: "ml-1",
            class: "" + (_vm.event.original.complete ? "font-strike" : "")
          },
          [_vm._v(_vm._s(_vm.event.original.item))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }