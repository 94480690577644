<template>
	<div id="recurring-invoice-details" v-if="isReady">
		<div id="header-area" class="">
			<div class="header-left pl-md-3 pl-3 gap-3">
				<v-text-field
					style="width:300px"
					dense
					outlined
					hide-details
					v-model="recurring.name"
					:placeholder="`Recurring invoice for ${invoice.clientInfo.name}`"
				></v-text-field>
				<payment-plan-events :payment-plan="recurring"></payment-plan-events>
			</div>
			<div class="header-right pr-md-6 pr-4" id="header-right">
				<v-btn class="mr-1 super-action" width="96" @click="saveRecurringInvoice(false)">
					{{ $t('global.save') }}
				</v-btn>
				<v-menu :close-on-click="true" :nudge-bottom="30">
					<template v-slot:activator="scope">
						<div class="ml-1">
							<v-btn icon class="menu-activator" v-on="scope.on">
								<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click="applyDefaultTemplate" v-if="!invoice.invoiceTemplate">
							Apply invoice template
						</div>
						<div class="more-menu-item" @click="refreshInvoiceTemplate" v-else>
							Refresh invoice template
						</div>
						<div class="more-menu-item" @click="confirmDelete()">
							{{ $t('global.delete') }}
						</div>
					</div>
				</v-menu>
				<v-btn icon @click="$emit('result', recurring)">
					<v-icon :size="20">$close</v-icon>
				</v-btn>
			</div>
		</div>

		<component
			style="max-height: calc(100vh - 60px); height: calc(100vh - 60px)"
			:is="rendererType"
			:invoice="invoice"
			:recurring="true"
			:account="$store.state.podAccount"
			:edit-mode="true"
			:force-render-index="forceRenderIndex"
			:attachment-service="attachmentService"
			:invoice-service="invoiceService"
			:has-expenses="false"
			:key="rendererKey"
			:additional-tax-rates="additionalTaxRates"
			@force-save="render()"
			@updated="updated($event)"
			@pause-auto-save="pauseAutoRender($event)"
			@account-info-modal="showModal('ACCOUNT-INFO')"
			@client-info-modal="showModal('CLIENT-INFO')"
			@invoice-number-format-modal="showModal('INVOICE-NUMBER-FORMAT')"
			@payment-terms-modal="showPaymentTermsModal()"
			@tax-rate-selected="taxRateSelected($event)"
		>
			<recurring-event-config :recurring="recurring"></recurring-event-config>

			<hr class="my-3" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" />

			<div class="column-format gap-1 text-left">
				<v-checkbox hide-details dense v-model="autoSend" label="Send automatically (no approval required)"></v-checkbox>

				<v-autocomplete class="mt-2" v-if="autoSend" v-model="emailTemplateId" :items="emailTemplates" hide-details dense outlined persistent-placeholder label="Email template (optional)" item-text="name" item-value="id" ></v-autocomplete>


				<div class="font-12 font-gray_70" v-if="autoSend">
					Automatically send to all "Invoice" and "Default" contacts on the client record.
				</div>
				<div class="font-12 font-gray_70" v-else>
					You will receive a notification after the invoice is generated letting you know you to approve and send.
				</div>

				<hr class="my-3" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" />

				<v-checkbox
					v-if="paymentsEnabled"
					hide-details
					dense
					v-model="autoCharge"
					label="Automatically charge on due date"
				></v-checkbox>

				<v-select
					class="mt-2"
					v-if="autoCharge && paymentsEnabled"
					hide-details
					persistent-placeholder
					dense
					outlined
					label="Auto charge payment method (optional)"
					:items="paymentMethods"
					:item-value="id"
					:return-object="true"
					v-model="paymentMethod"
					clearable
					v-tippy="{
						content:
							'This setting will allow you to select a specific payment method.  If this field is left blank,  the default method will be used to process payment.',
					}"
				>
					<template v-slot:item="{ item }"
						><span style="text-transform: capitalize"
							>{{ item.label.toLowerCase() }} - {{ item.last4 }}</span
						></template
					>
					<template v-slot:selection="{ item }"
						><span style="text-transform: capitalize"
							>{{ item.label.toLowerCase() }} - {{ item.last4 }}</span
						></template
					>
				</v-select>

				<hr class="my-3" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" />
				<v-select
					v-if="hasAnyHours"
					:items="billingBreakdowns"
					v-model="billingBreakdown"
					hide-details
					persistent-placeholder
					item-text="label"
					item-value="value"
					dense
					outlined
					label="Hourly line breakdown"
					v-tippy="{
						content:
							'This setting determines how billable hours are grouped.  One line for total, or broken down by day, week, month',
					}"
				>
				</v-select>
				<hr class="my-3" style="border-top: 1px solid var(--v-gray_50-base); border-bottom: none" v-if="hasAnyHours" />
				<v-checkbox
					hide-details
					dense
					v-model="clientHours"
					label="Include billable client hours"
					@change="billableUpdated"
				></v-checkbox>
				<v-checkbox
					hide-details
					dense
					v-model="allProjects"
					label="Include all billable projects"
					@change="billableUpdated"
				></v-checkbox>
				<div class="ml-7" v-if="!allProjects">
					<v-checkbox
						hide-details
						dense
						v-model="includedProjects"
						v-for="project in projects"
						:key="project.id"
						:value="project.id"
						:label="project.name"
						@change="billableUpdated"
					></v-checkbox>
				</div>
			</div>
		</component>

		<basic-modal
			:dialog="selectedModal === 'ACCOUNT-INFO'"
			@close="showModal(null)"
			ref="accountInfoModal"
			:persistent="false"
		>
			<account-info-modal
				v-if="invoice"
				:account-info="invoice.accountInfo"
				@set-account-info="setAccountInfo"
				@close="showModal(null)"
			></account-info-modal>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'CLIENT-INFO'" @close="showModal(null)" ref="clientInfoModal" :persistent="false">
			<client-info-modal
				v-if="invoice"
				:client-info="invoice.clientInfo"
				:client-id="invoice.clientId"
				@close="showModal(null)"
			></client-info-modal>
		</basic-modal>
		<basic-modal :dialog="selectedModal === 'INVOICE-NUMBER-FORMAT'" @close="showModal(null)" :persistent="false">
			<invoice-number-format-modal
				v-if="selectedModal === 'INVOICE-NUMBER-FORMAT'"
				@close="showModal(null)"
				@set-account-info="handleInvoiceNumberUpdate($event)"
			></invoice-number-format-modal>
		</basic-modal>
		<basic-modal
			:dialog="selectedModal === 'PAYMENT-TERMS'"
			@close="showModal(null)"
			ref="paymentTermsModal"
			:persistent="false"
		>
			<payment-terms-modal
				:payment-terms="invoice.paymentTerms"
				:client-id="invoice.clientId"
				@close="showModal(null)"
				@delete-terms="invoice.paymentTerms = null"
			></payment-terms-modal>
		</basic-modal>
	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import InvoiceRenderer from '@/modules/invoices/InvoiceRenderer/InvoiceRenderer';
	import ClientService from '@/modules/clients/ClientService';
	import InvoiceAttachmentService from '@/modules/invoices/InvoiceAttachmentService';
	import jsonPatch from 'fast-json-patch';
	import AccountInfoModal from '@/modules/invoices/AccountInfoModal';
	import ClientInfoModal from '@/modules/invoices/ClientInfoModal';
	import BasicModal from '@/components/BasicModal';
	import RecurringEventConfig from '@/modules/recurrence/RecurringEventConfig';
	import RecurringEventMixin from '@/modules/recurrence/RecurringEventMixin';
	import DateTime from '@/modules/utils/HDateTime';
	import ProjectService from '@/modules/projects/ProjectService';
	import ConfirmModal from '@/components/ConfirmModal';
	import InvoiceNumberFormatModal from '@/modules/invoices/InvoiceNumberFormatModal';
	import PaymentTermsModal from '@/modules/invoices/PaymentTermsModal';
	import QuickbooksService from '@/modules/integrations/quickbooks/QuickbooksService';
	import XeroService from "@/modules/integrations/xero/XeroService";
	import InvoiceRendererV2 from '@/modules/invoices/invoiceRendererV2/InvoiceRendererV2';
	import InvoiceTemplateService from '@/modules/templates/invoices/InvoiceTemplateService';
	import PaymentPlanEvents from '@/modules/invoices/paymentPlan/PaymentPlanEvents';
	import EmailTemplateService from "@/modules/templates/EmailTemplateService";

	export default {
		name: 'RecurringInvoiceDetails',

		components: {
			PaymentPlanEvents,
			RecurringEventConfig,
			InvoiceRenderer,
			InvoiceRendererV2,
			BasicModal,
			AccountInfoModal,
			ClientInfoModal,
			InvoiceNumberFormatModal,
			PaymentTermsModal,
		},

		mixins: [RecurringEventMixin],

		props: {
			clientId: { type: String, required: true },
			id: { type: String, required: false },
		},

		data: function() {
			return {
				invoiceService: new InvoiceService(),
				clientService: new ClientService(),
				projectService: new ProjectService(),
				quickbooksService: new QuickbooksService(),
				xeroService: new XeroService(),
				attachmentService: null,
				rendererKey: 0,
				forceRenderIndex: 0,
				invoice: null,
				observer: null,
				recurring: null,
				selectedModal: null,
				isReady: false,
				recurringMenu: false,
				includedProjects: [],
				allProjects: true,
				clientHours: true,
				autoSend: true,
				autoCharge: false,
				paymentMethod: null,
				emailTemplateId: null,
				billingBreakdown: 'TOTAL',
				DateTime: DateTime,
				projects: [],
				quickbooksTaxAccounts: [],
				xeroTaxAccounts: [],
				paymentMethods: [],
				emailTemplates: [],

				// Auto-save
				rendering: false,
				rendered: false,
				autoSave: false,
				renderTimer: setInterval(this.autoRender, 5 * 1000),
				autoSavePaused: false,

				paymentTermsDefault: {
					paymentDays: 7,
					latePaymentFee: 5,
					depositAmount: 0,
					depositType: 'No deposit',
					invoiceCreation: 'Manual',
					invoicingSchedule: 'Monthly',
				},

				billingBreakdowns: [
					{ label: 'Daily', value: 'DAY' },
					{ label: 'Weekly', value: 'WEEK' },
					{ label: 'Monthly', value: 'MONTH' },
					{ label: 'Total', value: 'TOTAL' },
				],
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'recurring-invoice-detail' });
			this.initialize();
			this.getEmailTemplates();
			this.getQuickbooksTaxAccounts();
			this.getXeroTaxAccounts();
		},

		beforeDestroy() {
			clearInterval(this.renderTimer);
		},

		methods: {
			initialize: function() {
				this.$store.commit('startLoading');
				if (this.id) {
					this.getRecurringEvent(this.id)
						.then((res) => {
							this.recurring = res.data;
							this.setInvoice(res.data.invoice.invoice);
							this.renderInvoice(true);
							this.allProjects = res.data.invoice.allProjects;
							this.clientHours = res.data.invoice.clientHours;
							this.includedProjects = res.data.invoice.includedProjects;
							this.autoSend = res.data.invoice.autoSend;
							this.autoCharge = res.data.invoice.autoCharge;
							this.paymentMethod = res.data.invoice.paymentMethod;
							this.billingBreakdown = res.data.invoice.billingBreakdown;
							this.emailTemplateId = res.data.invoice.emailTemplateId;
							this.attachmentService = new InvoiceAttachmentService(this.invoice);
							this.isReady = true;
							this.getProjects();
						})
						.finally(() => this.$store.commit('stopLoading'));
				} else {
					this.invoiceService
						.createInvoice(this.clientId, null, false)
						.then((res) => {
							this.setInvoice(res.data);
							this.recurring = this.initRecurring('INVOICE');
							this.recurring.enabled = false;
							this.attachmentService = new InvoiceAttachmentService(res.data);
							this.recurringMenu = true;
							this.isReady = true;
							this.getProjects();
						})
						.finally(() => this.$store.commit('stopLoading'));
				}

				this.getPaymentMethods();
			},

			getEmailTemplates: function(){
				new EmailTemplateService().getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0);
					this.emailTemplates.push(... res.data);
					this.emailTemplates.sort((a,b) => a.name.localeCompare(b.name));
				})
			},

			billableUpdated: function() {
				this.autoSave = true;
			},

			getProjects: function() {
				this.projectService.getProjectsByClient(this.clientId, false).then((res) => {
					this.projects.splice(0, this.projects.length);

					for (let i = 0; i < res.data.length; i++) {
						let p = res.data[i];
						if (!p.active) {
							continue;
						} else if (p.feeSchedule.feeType === 'Retainer' && !p.feeSchedule.retainerActive) {
							continue;
						}

						this.projects.push(p);
					}

					this.projects.sort((a, b) => a.name.localeCompare(b.name));
					this.cleanupProjectList();
				});
			},

			getPaymentMethods: function() {
				this.clientService.getClientPaymentMethods(this.clientId).then((res) => {
					this.paymentMethods.splice(0, this.paymentMethods.length);
					this.paymentMethods.push(...res.data);
					this.paymentMethods.sort((a, b) => a.label.localeCompare(b.label));
				});
			},

			cleanupProjectList: function() {
				for (let i = this.includedProjects.length - 1; i >= 0; i--) {
					let projectId = this.includedProjects[i];
					let ix = this.projects.findIndex((p) => p.id === projectId);
					if (ix === -1) {
						this.includedProjects.splice(i, 1);
					}
				}
			},

			setInvoice(invoice) {
				this.invoice = invoice;
				this.invoice.status = 'DRAFT';
				this.observer = jsonPatch.observe(this.invoice);
			},

			getQuickbooksTaxAccounts: function(){
				this.quickbooksService.getConnection().then((res) => {
					this.quickbooksTaxAccounts.splice(0,this.quickbooksTaxAccounts.length);
					if(res.data && res.data.companyInfo) {
						this.quickbooksTaxAccounts.push(...res.data.companyInfo.allTaxAccounts);
					}
				})
			},

			getXeroTaxAccounts: function(){
				this.xeroService.getConnection().then((res) => {
					this.xeroTaxAccounts.splice(0,this.xeroTaxAccounts.length);
					if(res.data && res.data.connection) {
						this.xeroTaxAccounts.push(...res.data.connection.allTaxAccounts);
					}
				})
			},

			showModal(modal) {
				this.autoRenderPaused = !!modal;
				this.selectedModal = modal;
			},

			updated(invoice) {
				this.invoice = invoice;
			},

			pauseAutoRender(event) {
				this.autoRenderPaused = event;
			},

			handleInvoiceNumberUpdate(account) {
				this.setAccountInfo(account);
				this.render(true);
			},

			render() {
				return this.renderInvoice();
			},

			autoRender() {
				if (this.autoRenderPaused) {
					return;
				}
				let focusField = document.activeElement;
				if (focusField && this.parentHasClass(focusField, 'pause-save')) {
					return;
				}
				if (this.invoice.status !== 'DRAFT') {
					return;
				}
				if (this.autoSave) {
					return this.saveRecurringInvoice(false);
				} else {
					return this.renderInvoice();
				}
			},

			parentHasClass(element, classname) {
				if (typeof element.className == 'string' && element.className.split(' ').indexOf(classname) >= 0) return true;
				return element.parentNode && this.parentHasClass(element.parentNode, classname);
			},

			async renderInvoice(force = false) {
				if (this.saving) {
					return;
				}

				if (this.invoice.discountPercentage === '' || this.invoice.discountPercentage === null) {
					this.invoice.discountPercentage = 0;
				}

				if (this.invoice.taxPercentage === '' || this.invoice.taxPercentage === null) {
					this.invoice.taxPercentage = 0;
				}

				if (!this.invoice.paymentTerms) {
					this.invoice.paymentTerms = {
						paymentDays: 7,
						latePaymentFee: 5,
						depositAmount: 0,
						depositType: 'No deposit',
						invoiceCreation: 'Manual',
						invoicingSchedule: 'Monthly',
					};
				}

				let patch = jsonPatch.generate(this.observer, false);
				let finalPatch = [];

				for (let i = 0; i < patch.length; i++) {
					if (!patch[i].path.startsWith('/attachments')) {
						finalPatch.push(patch[i]);
					}
				}

				if (finalPatch.length > 0 || force) {
					this.rendering = true;
					this.invoiceService.render(this.invoice).then((res) => {
						this.invoice = res.data;
						this.rendering = false;
						this.rendered = true;
						this.observer = jsonPatch.observe(this.invoice);
					});
				}
			},

			taxRateSelected(taxRate) {
				if (taxRate.type === 'Quickbooks') {
					this.invoice.integrationTaxKeys.quickbooksId = taxRate.id;
					this.renderInvoice();
				}
			},

			saveRecurringInvoice: function(close = true) {
				let payload = {
					clientId: this.clientId,
					allProjects: this.allProjects,
					clientHours: this.clientHours,
					autoSend: this.autoSend,
					autoCharge: this.autoCharge,
					paymentMethod: this.paymentMethod,
					includedProjects: this.includedProjects,
					emailTemplateId: this.emailTemplateId,
					invoice: this.invoice,
					billingBreakdown: this.billingBreakdown,
				};

				this.saveRecurringEvent(this.recurring, payload, 'INVOICE').then((res) => {
					if (close) {
						this.$emit('result', res.data);
					} else {
						if(res.data.autoDisabled){
							this.$store.commit('error', 'The invoice has been auto-disabled based on a next run time in the past.\nIf you would like to create back dated invoices, please click the Enabled checkbox and re-save.');
						}else {
							this.$store.commit('success', 'Saved');
						}
						this.recurring = res.data;
						this.invoice = res.data.invoice.invoice;
						this.invoice.status = 'DRAFT';
						this.observer = jsonPatch.observe(this.invoice);
						this.autoSave = false;
					}
				});
			},

			applyDefaultTemplate: function() {
				new InvoiceTemplateService().getInvoiceTemplates().then((res) => {
					for (const t of res.data) {
						if (t.defaultTemplate) {
							this.invoice.invoiceTemplate = t;
							return;
						}
					}
				});
			},

			refreshInvoiceTemplate: function() {
				new InvoiceTemplateService().getInvoiceTemplates().then((res) => {
					for (const t of res.data) {
						if (t.id === this.invoice.invoiceTemplate.id) {
							this.invoice.invoiceTemplate = null;
							this.$nextTick(() => (this.invoice.invoiceTemplate = t));
							return;
						}
					}
					console.log('nada');
				});
			},

			confirmDelete: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this recurring invoice?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						if (this.recurring.id) {
							this.deleteRecurringEvent(this.recurring.id).then(() => {
								this.recurring.deleted = true;
								this.$emit('result', this.recurring);
							});
						} else {
							this.$emit('result');
						}
					}
				});
			},

			showPaymentTermsModal() {
				if (!this.invoice.paymentTerms) {
					this.invoice.paymentTerms = this.paymentTermsDefault;
				}
				this.showModal('PAYMENT-TERMS');
			},

			setAccountInfo(account) {
				this.account = account;
				this.forceRenderIndex++;
				const keys = Object.keys(this.invoice.accountInfo);
				let update = {};
				for (let i = 0; i < keys.length; i++) {
					update[keys[i]] = account[keys[i]];
				}
				this.invoice.accountInfo = update;
			},
		},

		computed: {
			rendererType: function() {
				return this.invoice && this.invoice.invoiceTemplate ? 'InvoiceRendererV2' : 'InvoiceRenderer';
			},

			paymentsEnabled: function() {
				if (
					this.$store.state.podAccount.stripeIntegration &&
					this.$store.state.podAccount.stripeIntegration.chargesEnabled
				) {
					return true;
				} else {
					return false;
				}
			},

			nextRunTime: function() {
				if (this.recurring.nextRunTime) {
					let nextRunTime = DateTime.fromISO(this.recurring.nextRunTime);

					if (this.recurring.endDate) {
						let endDate = DateTime.fromISO(this.recurring.endDate);
						if (endDate < nextRunTime) {
							return `Ended ${endDate
								.setZone(this.$store.state.podAccount.timeZone)
								.toLocaleString(DateTime.DATE_MED)}`;
						}
					}

					return `Next ${nextRunTime.setZone(this.$store.state.podAccount.timeZone).toLocaleString(DateTime.DATE_MED)}`;
				} else {
					return null;
				}
			},

			additionalTaxRates: function() {
				let result = [];
				if (this.quickbooksTaxAccounts.length) {
					this.quickbooksTaxAccounts.forEach((t) => {
						result.push({
							id: t.id,
							name: t.name,
							type: 'Quickbooks',
							rate: t.rateValue,
						});
					});
				}
				if(this.xeroTaxAccounts.length){
					this.xeroTaxAccounts.forEach(t => {
						result.push({
							id: t.taxType,
							name: t.name,
							type: 'Xero',
							rate: t.effectiveRate
						})
					});
				}
				return result;
			},

			hasAnyHours: function() {
				if (this.allProjects || this.clientHours) {
					return true;
				} else if (this.includedProjects.length) {
					return true;
				} else {
					return false;
				}
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
	#recurring-invoice-details {
		background-color: var(--v-gray_20-base);
		#header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
