<template>
	<div>
		<div class="column-format pt-1 pb-4 text-left">
			<div class="brand-bold font-18">Signers</div>
			<div class="font-14 font-gray_80">Individuals who will sign or accept the document</div>
		</div>
		<v-container>
			<v-row
				v-for="signer in signers"
				:key="signer.id"
				class="font-14 text-left py-3"
				style="border-bottom: 1px solid var(--v-gray_30-base)"
			>
				<v-col cols="4" class="brand-medium">Name:</v-col>
				<v-col cols="8">{{ signer.firstName }} {{signer.lastName}}</v-col>
				<v-col cols="4" class="brand-medium">Title:</v-col>
				<v-col cols="8" contenteditable="true" @input="titleUpdated(signer,$event)">{{ signer.initialTitle }}</v-col>
				<v-col cols="4" class="brand-medium">Email:</v-col>
				<v-col cols="8">{{ signer.email }}</v-col>
				<v-col cols="4" class="brand-medium">Signer for:</v-col>
				<v-col cols="8" class="row-format">
					<div>{{ signerFor(signer.signerType) }}</div>
					<v-icon class="material-symbols-rounded pointer ml-auto" size="20" @click="removeSigner(signer)">delete</v-icon>
				</v-col>
			</v-row>
		</v-container>
		<div class="column-format gap-2 mt-3">
			<v-menu close-on-click close-on-content-click nudge-bottom="10">
				<template v-slot:activator="{ on }">
					<v-btn class="secondary-action" v-on="on">Add {{ $store.state.podAccount.accountName }} signer</v-btn>
				</template>
				<div class="more-menu">
					<div v-for="user in internalUsers" :key="user.id" class="more-menu-item" @click="addInternalUser(user)">
						{{ user.firstName }} {{ user.lastName }}
					</div>
				</div>
			</v-menu>

			<v-menu close-on-click close-on-content-click nudge-bottom="10" v-if="!templateMode">
				<template v-slot:activator="{ on }">
					<v-btn class="super-action" v-on="on">Add {{ agreement.client.name }} signer</v-btn>
				</template>
				<div class="more-menu">
					<div
						v-for="contact in clientUsers"
						:key="contact.id"
						class="more-menu-item"
						@click="addClientContact(contact)"
					>
						{{ contact.firstName }} {{ contact.lastName }}
					</div>
					<div class="more-menu-item" @click="addNewContact">+ Add new contact</div>
				</div>
			</v-menu>
		</div>
	</div>
</template>

<script>
	import ContactEdit from "@/modules/clients/contacts/ContactEdit";

	export default {
		name: 'Signers',

		props: ['agreement','templateMode'],

		components: {},

		data: function() {
			return {
				signers: [],
			};
		},

		mounted() {
			this.signers.push(...this.agreement.signers);
			this.signers.forEach(s => s.initialTitle = s.title);
		},

		beforeDestroy() {},

		methods: {
			titleUpdated: function(signer,event){
				signer.title = event.target.innerText;
				let ix = this.signers.findIndex(s => s.id === signer.id);
				this.signers.splice(ix,1,signer);
				this.$emit('change',this.signers);
			},

			addNewContact: function(){
				let binding = {
					clientId: this.agreement.clientId,
					emailRequired: true,
					forceStandard: true,
				}
				this.$store.state.globalModalController.openModal(ContactEdit,binding).then((res) => {
					if(res){
						this.addClientContact(res);
					}
				})
			},

			addClientContact: function(contact) {
				if (this.isDuplicate(contact.email)) {
					this.$store.commit('error', 'Cannot add signer - duplicate email.');
					return;
				}

				let signer = {
					id: this.uniqueId(5),
					firstName: contact.firstName,
					lastName: contact.lastName,
					title: contact.role,
					initialTitle: contact.role,
					email: contact.email,
					extra: '',
					signerType: 'CLIENT',
					signatureType: 'Keyboard',
					font: 'Arizonia'
				};
				this.signers.push(signer);
				this.$emit('change', this.signers);
			},

			addInternalUser: function(user) {
				if (this.isDuplicate(user.email)) {
					this.$store.commit('error', 'Cannot add signer - duplicate email.');
					return;
				}

				let signer = {
					id: this.uniqueId(5),
					firstName: user.firstName,
					lastName: user.lastName,
					title: '',
					email: user.email,
					extra: '',
					signerType: 'USER',
					signatureType: 'Keyboard',
					font: 'Arizonia'
				};
				this.signers.push(signer);
				this.$emit('change', this.signers);
			},

			removeSigner: function(signer) {
				let ix = this.signers.findIndex((s) => s.id === signer.id);
				this.signers.splice(ix, 1);
				this.$emit('change', this.signers);
			},

			uniqueId: function(len){
				return Array.from(Array(len), () => Math.floor(Math.random() * 36).toString(36)).join('');
			},

			isDuplicate: function(email) {
				return this.signers.findIndex((s) => s.email === email) !== -1;
			},

			signerFor: function(signerType) {
				return signerType === 'USER' ? this.$store.state.podAccount.accountName : this.agreement.client.name;
			},

			canAccessAgreements: function(u){
				if(u.userType === 'OWNER' || u.userType === 'FULL_USER'){
					return true;
				}else if(u.userType === 'RESTRICTED_USER' && u.featureAccess && u.featureAccess.agreements){
					return true;
				}else {
					return false;
				}
			}
		},

		computed: {
			internalUsers: function() {
				return this.$store.state.usersForAccount
					.filter((u) => this.canAccessAgreements(u))
					.filter((u) => this.signers.findIndex((s) => s.email === u.email) === -1);
			},

			clientUsers: function() {
				return this.$store.getters
					.getContactsByClientId(this.agreement.clientId)
					.filter((c) => this.signers.findIndex((s) => s.email === c.email) === -1);
			},
		},
	};
</script>

<style scoped lang="scss"></style>
