<template>
	<div class="connected-button">
		{{displayLabel}}
	</div>
</template>

<script>
export default {
	name: "Connected",

	props: ['label'],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {
		displayLabel: function(){
			if(this.label){
				return this.label
			}else{
				return 'Connected'
			}
		},
	},

}
</script>

<style scoped lang="scss">
	.connected-button {
		background-color: var(--v-success_10-base);
		color: var(--v-success-base);
		border-radius: 4px;
		width: fit-content;
		font-size: 14px;
		padding: 4px 8px;
	}
</style>