var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "pointer",
        attrs: {
          headers: _vm.recurringPaymentTableHeaders,
          "hide-default-footer": true,
          items: _vm.recurringPayments,
          "disable-pagination": true
        },
        on: {
          "click:row": function($event) {
            return _vm.openPayment($event.id)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.amount",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$formatters.dollars(
                        item.income.amount,
                        true,
                        true,
                        item.income.currency
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.startDate",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.DateTime.fromISO(item.startDate).toLocaleString(
                        _vm.DateTime.DATE_MED
                      )
                    ) +
                    " "
                ),
                item.endDate
                  ? _c("span", [
                      _vm._v(
                        " thru " +
                          _vm._s(
                            _vm.DateTime.fromISO(item.endDate).toLocaleString(
                              _vm.DateTime.DATE_MED
                            )
                          )
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.nextRunTime",
            fn: function(ref) {
              var item = ref.item
              return [
                item.nextRunTime && item.enabled
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.DateTime.fromISO(item.nextRunTime)
                            .setZone(_vm.$store.state.podAccount.timeZone)
                            .toLocaleString(_vm.DateTime.DATE_MED_WITH_WEEKDAY)
                        )
                      )
                    ])
                  : _c("span", [_vm._v("--")])
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                item.enabled
                  ? _c(
                      "span",
                      { staticStyle: { "text-transform": "capitalize" } },
                      [_vm._v(_vm._s(item.status.toLowerCase()))]
                    )
                  : _c("span", [_vm._v("Disabled")])
              ]
            }
          },
          {
            key: "item.interval",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-icon",
                  {
                    staticStyle: {
                      "margin-bottom": "1px",
                      "margin-right": "4px"
                    },
                    attrs: { size: "14" }
                  },
                  [_vm._v("autorenew")]
                ),
                _vm._v(_vm._s(_vm.getLabel(item)) + " ")
              ]
            }
          }
        ])
      }),
      _vm.recurringPaymentDetailDialog
        ? _c(
            "basic-modal",
            {
              attrs: {
                dialog: _vm.recurringPaymentDetailDialog,
                persistent: true
              },
              on: {
                close: function($event) {
                  _vm.recurringPaymentDetailDialog = false
                }
              }
            },
            [
              _c("recurring-payment", {
                attrs: { id: _vm.recurringPaymentId },
                on: {
                  close: function($event) {
                    _vm.recurringPaymentDetailDialog = false
                  },
                  result: function($event) {
                    return _vm.recurringEventSaved($event)
                  },
                  deleted: function($event) {
                    return _vm.recurringEventDeleted($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }