<template>
	<div id="new-workflow">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div>
			<div class="px-5 pt-5">
				<div class="modal-title">
					{{flow ? 'Copy' : 'New'}} workflow automation
				</div>
				<v-form ref="form" v-model="isValid" @submit.prevent>
					<v-text-field
						class="h-outline"
						hide-details
						persistent-placeholder
						label="Workflow name"
						v-model="name"
						:rules="required"
						autofocus
					></v-text-field>
					<v-select
						hide-details
						persistent-placeholder
						class="h-outline"
						label="Entry event"
						:items="eventTypes"
						item-value="value"
						item-text="label"
						v-model="eventType"
					></v-select>
					<v-select
						hide-details
						persistent-placeholder
						class="h-outline"
						:rules="required"
						:label="referenceLabel"
						:items="referenceList"
						item-value="value"
						item-text="label"
						v-model="referenceId"
					></v-select>
				</v-form>
			</div>
		</div>
		<div style="border-top: 1px solid var(--v-gray_30-base)" class="py-2">
			<v-btn class="primary-action" width="200" :disabled="!isValid" @click="create()">{{ $t('global.create') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import WorkflowService from '@/modules/workflow/WorkflowService';

	export default {
		name: 'NewWorkflow',

		props: ['flow','defaultName'],

		components: {},

		data: function() {
			return {
				isValid: false,
				name: this.defaultName,
				eventType: 'OpportunityEntersStage',
				referenceId: null,
				workflowService: new WorkflowService(),
				required: [(v) => (v && v.trim().length >= 1) || 'Field is required'],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			create: function() {
				let workflow = {
					name: this.name,
					entryRule: {
						eventType: this.eventType,
						referenceId: this.referenceId,
					},
					flow: this.flow,
				};
				this.workflowService
					.createWorkflow(workflow)
					.then((res) => {
						this.$emit('result', res.data);
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			handleClose: function() {
				this.$emit('result');
			},
		},

		computed: {
			referenceList: function() {
				let result = [];
				if (this.eventType === 'OpportunityEntersStage') {
					this.pipelineStages.forEach((s) => {
						result.push({ label: s.label, value: s.id });
					});
				}
				return result;
			},

			referenceLabel: function() {
				if (this.eventType === 'OpportunityEntersStage') {
					return 'Pipeline stage';
				} else {
					return null;
				}
			},

			pipelineStages: function() {
				return this.$store.state.pipelineStages;
			},

			eventTypes: function() {
				return [{ label: 'Opportunity enters stage', value: 'OpportunityEntersStage' }];
			},
		},
	};
</script>

<style scoped lang="scss">
	#new-workflow {
		width: 450px;
		background-color: var(--v-white-base);
	}
</style>
