<template>
	<div id="client-hourly-amount-edit">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="client-name">
				<client-avatar :client="client" left></client-avatar>
				<div>{{ client.name }}</div>
			</div>

			<div class="modal-subtitle" v-html="$t('invoice.client-fee-dialog.subheading', { thisClient: client.name })"></div>

			<v-text-field
				class="h-outline"
				persistent-placeholder
				hide-details
				v-model="hourlyAmount"
				:label="$t('invoice.client-fee-dialog.hourly-amount')"
				placeholder="0.00"
				:prefix="currencySymbol"
				suffix="per hour"
				type="number"
				@keyup.enter="save()"
			></v-text-field>
		</div>

		<div class="modal-footer">
			<v-btn class="primary-action" width="180" @click="save()">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import ClientService from '@/modules/clients/ClientService';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'ClientHourlyAmountModal',
		components: { ClientAvatar },
		props: {
			client: { type: Object, required: true },
		},

		// components: { ConfirmDialog },

		data: function() {
			return {
				clientService: new ClientService(),
				hourlyAmount: null,
				// observer: null,
			};
		},

		mounted() {
			console.log(this.client);
			this.hourlyAmount = this.client.hourlyAmount;
			// this.getProject();
			// this.getFeeSchedule();
			// this.observer = jsonPatch.observe(this.project);
			// console.log(this.feeSchedule);
		},

		beforeDestroy() {},

		computed: {
			currencySymbol: function(){
				return this.$formatters.currencySymbol(this.$store.state.defaultCurrency);
			}
		},

		methods: {
			save: function() {
				const patch = [{ op: 'replace', path: '/hourlyAmount', value: this.hourlyAmount }];

				this.clientService
					.patchClient(this.client.id, patch)
					.then((res) => {
						const client = res.data;
						console.log('patchClient', client);
						this.$emit('update-client-fees', client);
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},
		},
	};
</script>

<style lang="scss">
	#client-hourly-amount-edit {
		max-width: 360px;
		background-color: var(--v-white-base);

		//.label {
		//	text-align: left;
		//	letter-spacing: 0.2px;
		//	font-family: stratos-semibold;
		//}
		.client-name {
			display: flex;
			justify-content: center;
			margin-top: 8px;
			margin-bottom: 8px;
			div {
				align-self: center;
				flex: 0 0 auto;
			}
		}
	}
</style>
