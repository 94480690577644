var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "payment", staticStyle: { "min-height": "250px" } },
    [
      _vm.integratedPaymentsEnabled
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    attrs: {
                      label: "Require payment at booking",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.meeting.paymentEnabled,
                      callback: function($$v) {
                        _vm.$set(_vm.meeting, "paymentEnabled", $$v)
                      },
                      expression: "meeting.paymentEnabled"
                    }
                  })
                ],
                1
              ),
              _vm.paymentEnabled && _vm.meeting.requiredPayment
                ? _c(
                    "v-col",
                    {
                      staticClass: "mt-3 column-format gap-3",
                      attrs: { cols: "12" }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Payment amount",
                          prefix: _vm.currencySymbol,
                          type: "number",
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          "persistent-placeholder": ""
                        },
                        model: {
                          value: _vm.meeting.requiredPayment.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.meeting.requiredPayment, "amount", $$v)
                          },
                          expression: "meeting.requiredPayment.amount"
                        }
                      }),
                      _c("v-autocomplete", {
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          dense: "",
                          outlined: "",
                          label: _vm.$t("account.currency"),
                          items: _vm.currencies,
                          "item-text": "label",
                          "item-value": "value"
                        },
                        model: {
                          value: _vm.meeting.requiredPayment.currency,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.meeting.requiredPayment,
                              "currency",
                              $$v
                            )
                          },
                          expression: "meeting.requiredPayment.currency"
                        }
                      }),
                      _c(
                        "div",
                        {},
                        [
                          _c("mock-payment", {
                            attrs: { meeting: _vm.meeting }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "row-format centered", attrs: { cols: "12" } },
                [
                  _c("v-icon", { attrs: { color: "primary" } }, [
                    _vm._v("$alert")
                  ]),
                  _c("div", [
                    _vm._v(
                      "This feature requires an active Stripe integration."
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }