var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticStyle: {
            height: "40px !important",
            "background-color": "var(--v-white-base)"
          },
          attrs: {
            elevation: "0",
            width: "400",
            outlined: "",
            color: "gray_30"
          },
          on: { click: _vm.signInWithApple }
        },
        [
          _c(
            "div",
            {
              staticClass: "row-format centered",
              staticStyle: { width: "100%" }
            },
            [
              _c("v-icon", { attrs: { size: 20, color: "black" } }, [
                _vm._v("$apple")
              ]),
              _c("div", { staticClass: "ml-2 font-gray_90 font-14" }, [
                _vm._v(_vm._s(_vm.appleButton))
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }