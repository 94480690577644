<template>
	<div>
		<input type="hidden" v-model="label" ref="label" df-label />
		<input type="hidden" v-model="conditions_json" ref="conditions_json" df-conditions_json />
		<input type="hidden" v-model="operator" ref="operator" df-operator />

		<node-label @open="$refs.nodeModal.open()">
			<template v-slot:default>
				<div style="min-width: 110px" class="row-format align-center">
					<v-icon class="material-symbols-rounded" color="black" size="20">alt_route</v-icon>
					<div class="pr-6" v-if="label">{{label}}</div>
					<div v-else>Decision</div>
				</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" title="Conditions">
			<template v-slot:default>
				<div class="column-format gap-3">
					<v-text-field dense hide-details outlined v-model="label" persistent-placeholder placeholder="Label" maxlength="25"></v-text-field>

					<condition v-for="condition in conditions" :key="condition.id" :condition="condition" @change="updateCondition($event)" @delete="removeCondition(condition)"></condition>
					<div class="row-format align-center">
						<v-btn text color="primary" @click="addCondition()">+ Add condition</v-btn>
						<v-select class="ml-auto mr-7" v-if="conditions.length > 1" :items="['And','Or']" v-model="operator" hide-details dense outlined label="Operator" style="max-width: 120px"></v-select>
					</div>
				</div>
			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeLabel from "@/modules/workflow/nodes/NodeLabel";
	import NodeModal from "@/modules/workflow/nodes/NodeModal";
	import NodeMixin from "@/modules/workflow/nodes/NodeMixin";
	import Condition from "@/modules/workflow/nodes/components/Condition";
	import {v4 as uuidv4} from 'uuid';

	export default {
		name: 'Decision',
		inputs: 1,
		outputs: 2,

		mixins: [NodeMixin],

		props: [],

		components: {Condition, NodeLabel, NodeModal},

		data: function() {
			return {
				label: null,
				conditions: [],
				conditions_json: "[]",
				operator: 'And',
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.conditions = JSON.parse(this.$refs.conditions_json.value);
				this.operator = this.$refs.operator.value;
				this.label = this.$refs.label.value;
			});
		},

		beforeDestroy() {},

		methods: {
			addCondition: function(){
				this.conditions.push({id:uuidv4(), comparator:'Is'});
			},

			updateCondition: function(condition){
				let ix = this.conditions.findIndex(c => c.id === condition.id);
				this.conditions.splice(ix,1,condition);
			},

			removeCondition: function(condition){
				let ix = this.conditions.findIndex(c => c.id === condition.id);
				this.conditions.splice(ix,1);
			}
		},

		watch: {
			conditions: function(val){
				this.conditions_json = JSON.stringify(val);
				this.emitInput('conditions_json');
			},

			operator: function(){
				this.emitInput('operator')
			},

			label: function(){
				this.emitInput('label')
			}
		},

		computed: {

		},
	};
</script>

<style scoped lang="scss"></style>
