<template>
	<div  :class="`row-format align-center ml-1 ${view === 'Two-Week' ? 'mt-1' : ''}`">
		<client-avatar disable-click x-small  style="transform: scale(.92)" :client="client"></client-avatar>
		<div class="ml-1 font-gray_90 row-format align-center" style="width: 100%">
			<div class="brand-medium">{{statusLabel}}</div>
			<v-icon class="ml-auto mr-1" size="14" color="blue_30">{{icon}}</v-icon>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'ProposalEvent',

		props: ['event','view'],

		components: { ClientAvatar },

		data: function () {
			return {
				proposal: this.event.original.meta.proposal,
				client: this.$store.getters.clients.find((c) => c.id === this.event.original.meta.proposal.client.id),
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {},

		computed: {
			statusLabel: function(){
				if(this.proposal.proposalStatus === 'Draft' || !this.proposal.dateSent){
					return "Proposal drafted"
				}else{
					return "Proposal sent"
				}
			},

			icon: function(){
				if(this.proposal.proposalStatus === 'Draft' || !this.proposal.dateSent){
					return "$edit"
				}else{
					return "$paperAirplane"
				}
			},


			subLabel: function(){
				return this.proposal.coverPage.heading;
			}
		},
	};
</script>

<style scoped lang="scss">
</style>
