var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", {}, [
        _c("div", { staticClass: "row-format align-start" }, [
          _c(
            "div",
            { staticClass: "column-format" },
            [
              _c("div", { staticClass: "font-gray_80 mb-1" }, [
                _vm._v(_vm._s(_vm.$t("connected-apps.caldav.desc")))
              ]),
              _vm.isConnected
                ? _c("connected")
                : _c(
                    "div",
                    {
                      staticClass: "font-primary brand-medium pointer",
                      on: { click: _vm.connectAccount }
                    },
                    [_vm._v("Connect CalDav calendar")]
                  )
            ],
            1
          ),
          _vm.isConnected
            ? _c(
                "div",
                {
                  staticClass: "ml-auto pointer",
                  on: {
                    click: function($event) {
                      _vm.expanded = !_vm.expanded
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.isConnected && _vm.expanded
          ? _c(
              "div",
              { staticClass: "mt-5" },
              [
                _vm._l(_vm.accounts, function(conn) {
                  return _c(
                    "div",
                    {
                      key: conn.id,
                      staticClass: "pt-4 pb-4",
                      staticStyle: {
                        "border-bottom": "1px solid var(--v-gray_50-base)"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "font-14" },
                        [
                          _c("div", [
                            _c("strong", [_vm._v("User")]),
                            _vm._v(": " + _vm._s(conn.username))
                          ]),
                          _c("div", [
                            _c("strong", [_vm._v("Calendar")]),
                            _vm._v(": " + _vm._s(conn.calendarName))
                          ]),
                          _c("div", { staticClass: "truncate" }, [
                            _c("strong", [_vm._v("URL")]),
                            _vm._v(": " + _vm._s(conn.calendarUrl))
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary-action mt-3",
                              on: {
                                click: function($event) {
                                  return _vm.confirmDisconnect(conn)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("$delete")
                              ]),
                              _vm._v("Disconnect")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                }),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { elevation: "0", color: "secondary" },
                        on: { click: _vm.connectAccount }
                      },
                      [_vm._v("Connect additional account")]
                    )
                  ],
                  1
                )
              ],
              2
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }