import HttpClient from "@/services/HttpClient";
import store from "@/store";

export default class UserService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(process.env.VUE_APP_BASE_AUTH_URL,true);
	}

	getAccountUsers(accountId) {
		return this.httpClient
			.get(`/account/${accountId}/users`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjectCollaborators(accountId,projectId) {
		return this.httpClient
			.get(`/account/${accountId}/users?projectId=${projectId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getPendingInvitations(accountId){
		return this.httpClient
			.get(`/account/${accountId}/invitations`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getPendingProjectInvitations(accountId,projectId){
		return this.httpClient
			.get(`/account/${accountId}/invitations?projectId=${projectId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	inviteFullUser(accountId,email,baseUrl){
		let invitation = {
			accountId: accountId,
			email: email,
			userType: "FULL_USER",
			baseUrl: baseUrl
		}
		return this.doInvite(invitation);
	}

	inviteImplementer(accountId,email,baseUrl){
		let invitation = {
			accountId: accountId,
			email: email,
			userType: "IMPLEMENTER",
			baseUrl: baseUrl
		}
		return this.doInvite(invitation);
	}

	inviteProjectCollaborator(accountId,email,projectIds,baseUrl){
		let invitation = {
			accountId: accountId,
			email: email,
			userType: "COLLABORATOR",
			projectIds: projectIds,
			baseUrl: baseUrl
		}
		return this.doInvite(invitation);
	}

	inviteRestrictedUser(accountId,email,featureAccess,baseUrl){
		let invitation = {
			accountId: accountId,
			email: email,
			userType: "RESTRICTED_USER",
			featureAccess: featureAccess,
			baseUrl: baseUrl
		}
		return this.doInvite(invitation);
	}

	doInvite(invite){
		return this.httpClient
			.post(`/account/${invite.accountId}/inviteUser`,invite)
			.then(() => store.state.eventBus.$emit('created','user'))
			.then(res => Promise.resolve(res))
			.catch(err => Promise.reject(err))
	}

	revokeInvitation(accountId,email){
		return this.httpClient
			.post(`/account/${accountId}/revokeInvitation?email=${encodeURIComponent(email)}`)
			.then(res => Promise.resolve(res))
			.catch(err => Promise.reject(err))
	}

	revokeAccountAccess(accountId,email){
		return this.httpClient
			.post(`/account/${accountId}/revokeAccountAccess?email=${encodeURIComponent(email)}`)
			.then(res => Promise.resolve(res))
			.catch(err => Promise.reject(err))
	}

	revokeProjectAccess(accountId,projectId,email){
		return this.httpClient
			.post(`/account/${accountId}/revokeProjectAccess?email=${encodeURIComponent(email)}&projectId=${projectId}`)
			.then(res => Promise.resolve(res))
			.catch(err => Promise.reject(err))
	}

	syncProjectAccess(accountId,projectList,email){
		return this.httpClient
			.post(`/account/${accountId}/syncProjectAccess?email=${encodeURIComponent(email)}`,projectList)
			.then(res => Promise.resolve(res))
			.catch(err => Promise.reject(err))
	}

	syncFeatureAccess(accountId,featureAccess,email){
		return this.httpClient
			.post(`/account/${accountId}/syncFeatureAccess?email=${encodeURIComponent(email)}`,featureAccess)
			.then(res => Promise.resolve(res))
			.catch(err => Promise.reject(err))
	}
}
