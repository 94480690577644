var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: "max-width:" + _vm.maxWidth + "px",
      attrs: { id: "vendor-detail" }
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: { click: _vm.handleClose }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        {
          staticClass: "parentModal",
          style: "--max-height:" + _vm.divMaxHeight + "px"
        },
        [
          _c("h3", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.vendorHeading))
          ]),
          _c(
            "v-container",
            { staticClass: "px-0 mt-2", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: _vm.vendorCopy.id ? 6 : 12 } }, [
                    _c(
                      "div",
                      { staticClass: "column-format gap-3" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("vendors.vendor-name")
                          },
                          model: {
                            value: _vm.vendorCopy.name,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy, "name", $$v)
                            },
                            expression: "vendorCopy.name"
                          }
                        }),
                        _c(
                          "h4",
                          {
                            staticClass:
                              "brand-medium text-left gray_90--text ml-1"
                          },
                          [_vm._v(_vm._s(_vm.$t("vendors.contact")))]
                        ),
                        _c(
                          "div",
                          { staticClass: "row-format gap-2" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("contact.first-name")
                              },
                              model: {
                                value: _vm.vendorCopy.contact.firstName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.vendorCopy.contact,
                                    "firstName",
                                    $$v
                                  )
                                },
                                expression: "vendorCopy.contact.firstName"
                              }
                            }),
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("contact.last-name")
                              },
                              model: {
                                value: _vm.vendorCopy.contact.lastName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.vendorCopy.contact,
                                    "lastName",
                                    $$v
                                  )
                                },
                                expression: "vendorCopy.contact.lastName"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("contact.email")
                          },
                          model: {
                            value: _vm.vendorCopy.contact.email,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy.contact, "email", $$v)
                            },
                            expression: "vendorCopy.contact.email"
                          }
                        }),
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("contact.mobile")
                          },
                          model: {
                            value: _vm.vendorCopy.contact.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy.contact, "phone", $$v)
                            },
                            expression: "vendorCopy.contact.phone"
                          }
                        }),
                        _c(
                          "h4",
                          {
                            staticClass:
                              "brand-medium text-left gray_90--text ml-1"
                          },
                          [_vm._v(_vm._s(_vm.$t("global.address")))]
                        ),
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("global.address1")
                          },
                          model: {
                            value: _vm.vendorCopy.address1,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy, "address1", $$v)
                            },
                            expression: "vendorCopy.address1"
                          }
                        }),
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("global.address2")
                          },
                          model: {
                            value: _vm.vendorCopy.address2,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy, "address2", $$v)
                            },
                            expression: "vendorCopy.address2"
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "row-format gap-2" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("global.city")
                              },
                              model: {
                                value: _vm.vendorCopy.city,
                                callback: function($$v) {
                                  _vm.$set(_vm.vendorCopy, "city", $$v)
                                },
                                expression: "vendorCopy.city"
                              }
                            }),
                            _vm.states.length
                              ? _c("v-autocomplete", {
                                  attrs: {
                                    label: _vm.$t("contact.locality"),
                                    autocomplete: "new-password",
                                    dense: "",
                                    outlined: "",
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    items: _vm.states,
                                    "item-value": "abbreviation",
                                    "item-text": "name"
                                  },
                                  model: {
                                    value: _vm.vendorCopy.locality,
                                    callback: function($$v) {
                                      _vm.$set(_vm.vendorCopy, "locality", $$v)
                                    },
                                    expression: "vendorCopy.locality"
                                  }
                                })
                              : _c("v-text-field", {
                                  attrs: {
                                    label: _vm.$t("contact.locality"),
                                    "hide-details": "",
                                    dense: "",
                                    outlined: "",
                                    "persistent-placeholder": ""
                                  },
                                  model: {
                                    value: _vm.vendorCopy.locality,
                                    callback: function($$v) {
                                      _vm.$set(_vm.vendorCopy, "locality", $$v)
                                    },
                                    expression: "vendorCopy.locality"
                                  }
                                }),
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("contact.postal")
                              },
                              model: {
                                value: _vm.vendorCopy.postal,
                                callback: function($$v) {
                                  _vm.$set(_vm.vendorCopy, "postal", $$v)
                                },
                                expression: "vendorCopy.postal"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-autocomplete", {
                          attrs: {
                            label: _vm.$t("contact.country"),
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            items: _vm.countries.getCountriesSimple(),
                            "item-value": "code",
                            "item-text": "name"
                          },
                          model: {
                            value: _vm.vendorCopy.country,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy, "country", $$v)
                            },
                            expression: "vendorCopy.country"
                          }
                        }),
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("global.website")
                          },
                          model: {
                            value: _vm.vendorCopy.website,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy, "website", $$v)
                            },
                            expression: "vendorCopy.website"
                          }
                        }),
                        _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("vendors.tax-id")
                          },
                          model: {
                            value: _vm.vendorCopy.taxId,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy, "taxId", $$v)
                            },
                            expression: "vendorCopy.taxId"
                          }
                        }),
                        _c("v-checkbox", {
                          attrs: {
                            label: _vm.$t("vendors.track-1099"),
                            "hide-details": "",
                            dense: ""
                          },
                          model: {
                            value: _vm.vendorCopy.track1099,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy, "track1099", $$v)
                            },
                            expression: "vendorCopy.track1099"
                          }
                        }),
                        _c("v-textarea", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("global.notes"),
                            rows: 1
                          },
                          model: {
                            value: _vm.vendorCopy.notes,
                            callback: function($$v) {
                              _vm.$set(_vm.vendorCopy, "notes", $$v)
                            },
                            expression: "vendorCopy.notes"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.vendorCopy.id
                    ? _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("file-attachments", {
                                    staticClass: "mt-3 mb-6",
                                    attrs: {
                                      files: _vm.vendorCopy.attachments,
                                      "file-service": _vm.attachmentService
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between body-14 brand-semibold gray_90--text mb-1"
                                    },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.$t("global.expenses"))
                                        )
                                      ]),
                                      _vm.expenses.length
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "vendors.total-expenses",
                                                  { total: _vm.expensesTotal }
                                                )
                                              )
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  ),
                                  _c("expense-table", {
                                    attrs: {
                                      expenses: _vm.expenses,
                                      "table-type": "Vendor",
                                      dense: true,
                                      outline: true
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editOurExpense($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal-footer",
          staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "super-action",
              attrs: { width: _vm.$vuetify.breakpoint.smAndUp ? "200" : "128" },
              on: { click: _vm.saveVendor }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      _vm.vendorCopy.id === null
                        ? "global.create"
                        : "global.save"
                    )
                  ) +
                  " "
              )
            ]
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("delete-vendor-dialog", _vm.vendorCopy)
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded",
                  attrs: { size: "20" }
                },
                [_vm._v("delete")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }