<template>
	<div>
		<v-menu
			:nudge-top="28" max-width="200"
			bottom
			right
			rounded
			offset-overflow
			offset-y
			:close-on-content-click="false"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" style="min-height: 40px; background-color: var(--v-white-base)" elevation="0" outlined color="gray_30">
					<span :class="`font-14 ${isFilterActive ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{ filterLabel }}</span>
					<v-icon x-small class="mx-1" :color="isFilterActive ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format">
<!--				<v-text-field-->
<!--					@input="updated"-->
<!--					autofocus-->
<!--					solo-->
<!--					flat-->
<!--					hide-details-->
<!--					dense-->
<!--					placeholder="Search..."-->
<!--					v-model="filter.search"-->
<!--				></v-text-field>-->
				<div class="filter-option-box">
					<div class="row-format">
						<div class="font-14">Status</div>
					</div>
					<div class="my-2">
						<v-radio-group @change="updated" class="small-radio" hide-details dense v-model="filter.status">
							<v-radio label="Active" value="active"></v-radio>
							<v-radio label="Archived" value="archive"></v-radio>
							<v-radio label="Favorites" value="favorite"></v-radio>
							<v-radio label="All" value="all"></v-radio>
						</v-radio-group>
					</div>
				</div>

				<div class="filter-option-box">
					<div class="row-format">
						<div class="font-14">Record type</div>
					</div>
					<div class="my-2">
						<v-radio-group @change="updated" class="small-radio" hide-details dense v-model="filter.clientType">
							<v-radio label="Client" value="Client"></v-radio>
							<v-radio label="Prospect" value="Prospect"></v-radio>
							<v-radio label="All" value="all"></v-radio>
						</v-radio-group>
					</div>
				</div>

				<div class="mx-3 mb-3">
					<v-btn
						text
						block
						color="secondary"
						elevation="0"
						style="font-weight: 500"
						class="mt-2"
						@click="clearFilter()"
						><v-icon small>$clear</v-icon> Clear Filters</v-btn
					>
        <v-btn class="super-action mt-1" @click="showMenu = false" color="primary" block>{{
            $t('global.done')
          }}</v-btn>
			</div>
      </div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'ClientListFilter',

		props: ['filter'],

		data() {
			return {
				showMenu: false,
			};
		},

		mounted() {},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			applyFilter: function() {
				this.showMenu = false;
			},

			clearFilter: function() {
				this.filter.search = null;
				this.filter.status = 'active';
				this.filter.clientType = 'Client';
				this.updated();
				this.showMenu = false;
			},
		},

		computed: {
			isFilterActive: function() {
				return this.filter.status !== 'active';
			},

			filterLabel: function() {
				let label = [];

				// if (this.filter.search) {
				// 	label.push('"' + this.filter.search + '"');
				// }

				if (this.filter.status && this.filter.status !== 'active') {
					label.push('Status: ' + this.filter.status[0].toUpperCase() + this.filter.status.slice(1));
				}

				if(this.filter.clientType && this.filter.clientType === 'Prospect'){
					label.push('Prospects');
				}

				if (label.length === 0) {
					return 'Filter';
				} else {
					return label.length + ' Filter' + (label.length > 1 ? 's' : '');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.small-radio {
		margin-top: 0px !important;
	}

	.small-radio ::v-deep label {
		font-size: 14px;
	}
</style>
