var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row-format pl-4 pt-0 pb-0",
      staticStyle: { "background-color": "var(--v-white-base)", width: "600px" }
    },
    [
      _c(
        "div",
        {
          staticClass: "column-format text-left",
          staticStyle: { "justify-content": "center" }
        },
        [
          _c("div", { staticClass: "brand-medium" }, [
            _vm._v(_vm._s(_vm.header))
          ]),
          _c("div", { staticClass: "brand-semilight font-14 mt-1 mb-6" }, [
            _vm._v(_vm._s(_vm.body))
          ]),
          _c(
            "div",
            { staticClass: "row-format" },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "gray_80" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("result")
                    }
                  }
                },
                [_vm._v("Continue exploring")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-3",
                  attrs: { color: "accent", elevation: "0" }
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: { color: "var(--v-black-base)" },
                      on: { click: _vm.clearSampleData }
                    },
                    [_vm._v("Clear sample data")]
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { transform: "scaleX(-1)" },
        attrs: { src: "/images/hummingbird.png", width: "150" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }