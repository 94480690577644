var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.thread
    ? _c(
        "div",
        {
          staticClass: "row-format gap-2 font-14 pointer",
          on: { click: _vm.openThread }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { content: "Message" },
                  expression: "{content: 'Message'}"
                }
              ],
              staticClass: "material-symbols-rounded",
              attrs: { color: "secondary", size: "20" }
            },
            [_vm._v("chat")]
          ),
          _c("div", [_vm._v(_vm._s(_vm.participants.join(", ")) + " | ")]),
          _c(
            "div",
            { staticClass: "truncate", staticStyle: { "max-width": "200px" } },
            [_vm._v(_vm._s(_vm.thread.lastMessageText))]
          ),
          _c("div", { staticClass: "ml-auto font-gray_80 font-12" }, [
            _vm._v(_vm._s(_vm._f("formatForTimeAgo")(_vm.thread.lastMessage)))
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }