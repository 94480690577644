<template>
	<div class="font-14">
		<v-container fluid class="ma-0 pa-0">
			<v-row dense v-if="toDo && current.length > 0" :key="$store.state.productivity.todo.updateKey" class="ma-0 pa-0">
				<draggable
					:list="toDo.items"
					tag="section"
					:animation="200"
					handle=".drag"
					ghost-class="ghost"
					@end="endDragHandler"
					style="width: 100%"
				>
					<div v-for="(item,index) in toDo.items" :key="item.id">
						<v-col
							cols="12"
							class="px-3 pt-2 pb-1 ma-0 todo text-left"
							v-if="current.findIndex((c) => c.id === item.id) > -1"
							style="border-bottom: 1px solid var(--v-gray_30-base);"
							:style="index === 0 ? 'border-top: 1px solid var(--v-gray_30-base)' : ''"
						>
							<div class="row-format gap-2">
								<div class="drag round-checkbox" style="">
									<input
										type="checkbox"
										:id="'cb-' + item.id"
										v-model="item.complete"
										@change="checkComplete(item)"
									/>
									<label :for="'cb-' + item.id"></label>
								</div>
								<div
									:id="`item-${item.id}-${manageEvents}`"
									style="width: 100%; outline: none; word-break: break-word"
									@keydown.delete="checkDeleteToDo(item, $event)"
									@keydown.enter.exact.prevent="
										setItem(item, $event);
										addNew(item.id);
									"
									@blur="setItem(item, $event)"
									:contenteditable="!item.complete"
									:class="'editContent ' + (item.complete ? 'itemComplete' : '') + ' ' + getFontClass(item)"
								>
									{{ item.item }}
								</div>
							</div>
							<div class="font-12 row-format align-center" :key="item.id + item.dueDate">
								<date-selector
									:class="`mt-1 ${item.dueDate ? '' : 'todo-action'}`"
									:date="item.dueDate"
									label="+ Set due date"
									@change="setItemDueDate(item.id, $event)"
									:micro="true"
									:content-class="`font-12 font-gray_80`"
								></date-selector>
								<v-icon small class="delete-button ml-auto material-symbols-rounded pointer" @click="deleteToDo(item)">$delete</v-icon>
							</div>
						</v-col>
					</div>
				</draggable>
			</v-row>
		</v-container>

		<v-container fluid class="ma-0 pa-0" v-if="past.length > 0">
			<v-row class="ma-0 pa-0">
				<v-col cols="12" @click="showOldTasks = !showOldTasks" class="ma-0 pa-0">
					<div :class="'pl-5 row-format pointer align-center history-box ' + (showOldTasks ? 'history-box-open' : '')">
						<div class="brand-regular font-12 font-grey">
							{{ $t(showOldTasks ? 'productivity.completed-tasks-hide' : 'productivity.completed-tasks-show') }}
						</div>
						<div class="past-count ml-1 text-center">{{ past.length }}</div>
					</div>
				</v-col>
				<v-col cols="12" align="left" v-if="showOldTasks">
					<div class="column-format">
						<div v-for="item in past" :key="item.id" class="mt-2 ml-3">
							<span class="font-grey font-12 brand-medium">{{ item.dateCompleted | formatForTimeAgo }}</span>
							<br />
							<span class="font-grey font-12 brand-semilight" style="text-decoration: line-through">{{
								item.item
							}}</span>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import ToDoService from '@/modules/productivity/todo/ToDoService';
	import { v4 as uuidv4 } from 'uuid';
	import moment from 'moment';
	import Draggable from 'vuedraggable';
	import HDateTime from '@/modules/utils/HDateTime';
	import DateSelector from '@/components/DateSelector';

	export default {
		name: 'ToDo',

		props: ['manageEvents', 'expanded', 'openState'],

		components: {
			Draggable,
			DateSelector,
		},

		data: function() {
			return {
				toDoService: new ToDoService(),
				showOldTasks: false,
				uuidv4: uuidv4,
				updateCounter: 0,
			};
		},

		mounted() {
			if (this.manageEvents) {
				this.getToDo();
				this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
				this.$store.state.eventBus.$on(`u-${this.userId}.userToDo`, this.handleToDoUpdate);
			}
		},

		beforeDestroy() {
			if (this.manageEvents) {
				this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
				this.$store.state.eventBus.$off(`u-${this.userId}.userToDo`, this.handleToDoUpdate);
			}
		},

		methods: {
			handleAccountChange: function() {
				this.getToDo();
			},

			setItemDueDate: function(id, dueDate) {
				let ix = this.toDo.items.findIndex((t) => t.id === id);
				if (ix > -1) {
					let toDo = this.toDo.items[ix];
					toDo.dueDate = dueDate;
					this.toDo.items.splice(ix, 1, toDo);
					this.saveToDo();
				}
			},

			handleToDoUpdate: function(event) {
				if (event.message.accountId !== this.$store.getters.getAccountId) {
					return;
				} else if (event.message.updateKey === this.toDo.updateKey) {
					return;
				} else {
					this.$store.state.productivity.todo = event.message;
				}
			},

			saveToDo: function() {
				this.$store.state.productivity.todo.updateKey = uuidv4();
				this.toDo.items.sort(this.sortByDueDate);
				this.toDoService.putToDo(this.$store.state.productivity.todo);
			},

			getItemIndex: function(items, id) {
				for (let i = 0; i < items.length; i++) {
					if (id === items[i].id) {
						return i;
					}
				}
				return null;
			},

			checkDeleteToDo: function(item, event) {
				let index = this.getItemIndex(this.toDo.items, item.id);
				let visualIndex = this.getItemIndex(this.current, item.id);

				if (this.$validations.isEmpty(event.target.innerText)) {
					if (item.deleteCheck) {
						this.toDo.items.splice(index, 1);
						if (this.current.length > 0 && visualIndex !== 0) {
							this.focusEditable('item-' + this.current[visualIndex - 1].id + '-' + this.manageEvents);
						}
						this.saveToDo();
					} else {
						item.deleteCheck = true;
					}
				} else {
					item.deleteCheck = null;
				}
			},

			deleteToDo: function(item) {
				let index = this.getItemIndex(this.toDo.items, item.id);
				this.toDo.items.splice(index, 1);
				this.saveToDo();
			},

			setItem: function(item, event) {
				if (event.target && event.target.innerText) {
					item.item = event.target.innerText;
					event.target.innerText = item.item;
					item.deleteCheck = null;
					this.saveToDo();
				}
			},

			addNew: function(itemId) {
				let index;
				if (itemId === null) {
					index = this.toDo.items.length + 1;
				} else {
					index = this.getItemIndex(this.toDo.items, itemId) + 1;
				}

				let id = uuidv4();
				this.toDo.items.splice(index, 0, {
					id: id,
					item: null,
					complete: false,
					dateCompleted: null,
				});

				this.focusEditable('item-' + id + '-' + this.manageEvents);
				this.saveToDo();
			},

			focusEditable: function(id) {
				setTimeout(() => {
					const el = document.getElementById(id);
					const selection = window.getSelection();
					const range = document.createRange();
					selection.removeAllRanges();
					range.selectNodeContents(el);
					range.collapse(false);
					selection.addRange(range);
					el.focus();
				}, 500);
			},

			checkComplete: function(item) {
				if (item.complete) {
					item.dateCompleted = new Date().toISOString();
				} else {
					item.dateCompleted = null;
				}

				let ix = this.toDo.items.findIndex((t) => t.id === item.id);
				if (ix > -1) {
					this.toDo.items.splice(ix, 1, item);
					this.saveToDo();
				}
			},

			getToDo: function() {
				this.toDoService
					.getToDo()
					.then((res) => {
						this.$store.state.productivity.todo = res.data;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.message.data);
					});
			},

			sortToDo: function(a, b) {
				return b.dateCompleted.localeCompare(a.dateCompleted);
			},

			endDragHandler: function() {
				this.saveToDo();
				this.updateCounter++;
			},

			sortByDueDate: function(a, b) {
				if (a.dueDate && b.dueDate) {
					return a.dueDate.localeCompare(b.dueDate);
				} else if (a.dueDate) {
					return -1;
				} else if (b.dueDate) {
					return 1;
				} else {
					return 0;
				}
			},

			getFontClass: function(item) {
				if (item.dueDate && !item.complete) {
					let ourDate = HDateTime.fromISO(item.dueDate).startOf('day');
					let now = HDateTime.now().startOf('day');

					if (ourDate.equals(now)) {
						return 'font-primary font-medium';
					} else if (ourDate < now) {
						return 'font-red font-medium';
					} else {
						return '';
					}
				} else {
					return '';
				}
			},
		},

		computed: {
			userId: function() {
				return this.$store.getters.getLoggedInUserId;
			},

			current: function() {
				if (!this.toDo) {
					return [];
				}

				let result = [];
				let today = moment();

				for (let i = 0; i < this.toDo.items.length; i++) {
					let item = this.toDo.items[i];
					if (!item.complete || moment(item.dateCompleted).isSame(today, 'day')) {
						result.push(item);
					}
				}
				return result;
			},

			past: function() {
				if (!this.toDo) {
					return [];
				}

				let result = [];
				let today = moment();

				for (let i = 0; i < this.toDo.items.length; i++) {
					let item = this.toDo.items[i];
					if (item.complete && moment(item.dateCompleted).isBefore(today, 'day')) {
						result.push(item);
					}
				}

				result.sort(this.sortToDo);

				return result;
			},

			toDo: function() {
				return this.$store.state.productivity.todo;
			},
		},

		watch: {
			openState: function(val) {
				if (val && this.toDo.items.length === 0) {
					this.addNew();
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.past-count {
		width: 14px;
		height: 14px;
		background-color: var(--v-gray_20-base);
		color: var(--v-gray_80-base);
		font-size: 10px;
		border-radius: 4px;
	}

	.history-box {
		//border-top: 1px solid var(--v-gray_30-base);
		padding: 6px 0px 6px 0px;
		margin: 0px;
	}

	.history-box-open {
		//border-bottom: 1px solid var(--v-gray_30-base);
	}

	.editContent {
		width: 100%;
		margin-top: 3px;
		outline: none;
		white-space: pre-line;
		font-weight: 300;
		font-size: 14px;
		word-break: break-all;
	}

  .todo {
    .delete-button {
      visibility: hidden;
    }

    &:hover {
      .delete-button {
        visibility: visible;
      }
    }
  }

	//.todo .todo-action {
	//	height: 0px!important;
	//	opacity: 0;
	//	transition: all 100ms ease-in-out;
	//}
	//
	//.todo:hover .todo-action {
	//	opacity: 1;
	//	height: unset!important;
	//}

	.itemComplete {
		text-decoration: line-through;
		color: var(--v-gray_70-base);
	}

	.drag {
		cursor: grab;
	}

	.drag:active {
		cursor: grabbing;
	}
</style>
