var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format text-left font-14 font-gray_70 py-3 gap-3" },
    [
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding top"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pt,
              callback: function($$v) {
                _vm.$set(_vm.element, "pt", $$v)
              },
              expression: "element.pt"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding bottom"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pb,
              callback: function($$v) {
                _vm.$set(_vm.element, "pb", $$v)
              },
              expression: "element.pb"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding left"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pl,
              callback: function($$v) {
                _vm.$set(_vm.element, "pl", $$v)
              },
              expression: "element.pl"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding right"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pr,
              callback: function($$v) {
                _vm.$set(_vm.element, "pr", $$v)
              },
              expression: "element.pr"
            }
          })
        ],
        1
      ),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          outlined: "",
          dense: "",
          label: "Accept text"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.text,
          callback: function($$v) {
            _vm.$set(_vm.element, "text", $$v)
          },
          expression: "element.text"
        }
      }),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          outlined: "",
          dense: "",
          label: "Accepted text"
        },
        on: { input: _vm.update },
        model: {
          value: _vm.element.acceptedText,
          callback: function($$v) {
            _vm.$set(_vm.element, "acceptedText", $$v)
          },
          expression: "element.acceptedText"
        }
      }),
      _c("v-select", {
        attrs: {
          items: ["X-Small", "Small", "Normal", "Large", "X-Large"],
          "hide-details": "",
          outlined: "",
          dense: "",
          "persistent-placeholder": "",
          label: "Button size"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.buttonSize,
          callback: function($$v) {
            _vm.$set(_vm.element, "buttonSize", $$v)
          },
          expression: "element.buttonSize"
        }
      }),
      _c("v-select", {
        attrs: {
          items: _vm.alignment,
          "item-text": "label",
          "item-value": "value",
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Button alignment"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.buttonAlignment,
          callback: function($$v) {
            _vm.$set(_vm.element, "buttonAlignment", $$v)
          },
          expression: "element.buttonAlignment"
        }
      }),
      _c("color-selector", {
        attrs: { label: "Button color" },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.buttonColor,
          callback: function($$v) {
            _vm.$set(_vm.element, "buttonColor", $$v)
          },
          expression: "element.buttonColor"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }