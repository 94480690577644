import { render, staticRenderFns } from "./ProposalRenderer.vue?vue&type=template&id=01f40ad6&"
import script from "./ProposalRenderer.vue?vue&type=script&lang=js&"
export * from "./ProposalRenderer.vue?vue&type=script&lang=js&"
import style0 from "./ProposalRenderer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VRow})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3589467384/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01f40ad6')) {
      api.createRecord('01f40ad6', component.options)
    } else {
      api.reload('01f40ad6', component.options)
    }
    module.hot.accept("./ProposalRenderer.vue?vue&type=template&id=01f40ad6&", function () {
      api.rerender('01f40ad6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/proposals/proposal-renderer/ProposalRenderer.vue"
export default component.exports