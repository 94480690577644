<template>
	<div class="column-format gap-3">
		<font-selector
			style="min-width:200px"
			:font="meeting.pageLayout.fontFamily"
			@font-changed="
				meeting.pageLayout.fontFamily = $event;
				requestSave();
			"
		></font-selector>
		<div class="column-format pb-2" style="border-bottom: 1px solid var(--v-gray_50-base);">
			<div class="font-14 text-left font-gray_70" style="min-width: 100%">Accent color</div>
			<color-selector v-model="meeting.pageLayout.accentColor"></color-selector>

			<div class="font-14 text-left font-gray_70" style="min-width: 100%">Background panel color</div>
			<color-selector v-model="meeting.pageLayout.backgroundColor"></color-selector>

			<div class="font-14 text-left font-gray_70">Button color</div>
			<color-selector v-model="meeting.pageLayout.buttonColor"></color-selector>

			<div class="font-14 text-left font-gray_70" style="min-width: 100%">Background color</div>
			<color-selector v-model="meeting.pageLayout.backgroundMainColor"></color-selector>

			<div class="column-format gap-3">
				<div class="wrapper row-format align-center pa-3">
					<v-switch
						v-model="meeting.pageLayout.showLogo"
						@change="requestSave()"
						label="Display logo"
						hide-details
						style="margin-bottom: 0px; margin-top: 0px"
					></v-switch>
				</div>

				<v-select
					:items="['Normal', 'Block']"
					hide-details
					outlined
					dense
					label="Button style"
					v-model="meeting.scheduleMeeting.buttonStyle.buttonStyle"
					@change="updated()"
				>
				</v-select>

				<v-select
					:items="['X-Small', 'Small', 'Normal', 'Large', 'X-Large']"
					hide-details
					outlined
					dense
					label="Button size"
					v-model="meeting.scheduleMeeting.buttonStyle.buttonSize"
					@change="updated()"
				>
				</v-select>

				<div v-if="meeting.scheduleMeeting.buttonStyle.buttonStyle === 'Normal'">
					<v-select
						:items="['Left', 'Center', 'Right']"
						hide-details
						outlined
						dense
						label="Button alignment"
						v-model="meeting.scheduleMeeting.buttonStyle.buttonAlignment"
						@change="updated()"
					>
					</v-select>
				</div>

				<hr />
			</div>
		</div>
		<div class="font-gray_90 font-16 text-left ml-3">Labels</div>
		<div class="row-format gap-2">
			<div class="column-format pl-3 gap-3" style="min-width: 100%">
				<div class="wrapper row-format align-center" v-if="selectedTab === 0">
					<v-text-field
						persistent-placeholder
						hide-details
						label="Confirm text"
						v-model="meeting.scheduleMeeting.confirmText"
					></v-text-field>
        </div>
        <div v-if="selectedTab === 1">
					<v-text-field
						persistent-placeholder
						hide-details
						label="Schedule text"
						v-model="meeting.scheduleMeeting.scheduleText"
					></v-text-field>
				</div>
				<div v-if="selectedTab === 2">
					<v-text-field
						persistent-placeholder
						hide-details
						label="iCal/Outlook Calendar"

						v-model="meeting.confirmMeeting.addToOtherText"
					></v-text-field>
          <v-text-field
              persistent-placeholder
              hide-details
              label="Google calendar"
              v-model="meeting.confirmMeeting.addToGoogleText"
          ></v-text-field>
				</div>
        <div v-if="selectedTab === 3">
          <v-text-field
            persistent-placeholder
            hide-details
            label="Cancel meeting"
            v-model="meeting.cancelMeeting.cancelText"
          ></v-text-field>
          <v-text-field
              persistent-placeholder
              hide-details
              label="Cancellation reason"
              v-model="meeting.cancelMeeting.cancellationReasonText"
          ></v-text-field>
          <v-text-field
              persistent-placeholder
              hide-details
              label="Post-cancellation text"
              v-model="meeting.cancelMeeting.postCancellationText"
          ></v-text-field>
        </div>
			</div>
		</div>
		<hr />
	</div>
</template>

<script>
	import FontSelector from '@/components/FontSelector';
	import ColorSelector from '@/components/ColorSelector.vue';

	export default {
		name: 'SchedulerStyleConfig',

		props: ['meeting', 'uniqueAccountUrl', 'podId', 'selectedTab'],

		components: { FontSelector, ColorSelector },

		data: function() {
			return {};
		},

		methods: {
			requestSave: function() {
				this.$emit('request-save');
			},
		},

		computed: {
			fullMeetingLink: function() {
				return (
					process.env.VUE_APP_MEET_LINK +
					this.podId +
					'/' +
					this.uniqueAccountUrl +
					'/' +
					this.meeting.uniqueMeetingId +
					'?inFrame=true&preview=true'
				);
			},
		},
	};
</script>

<style scoped></style>
