var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "nav-shim" } }),
    _c(
      "div",
      {
        class: _vm.$store.state.leftNavExpanded
          ? "left-nav-expanded"
          : "left-nav-collapsed",
        attrs: { id: "left-nav" },
        on: { mouseenter: _vm.handleMouseIn, mouseleave: _vm.handleMouseOut }
      },
      [
        _vm.$store.state.selectedUserAccount && _vm.isReady
          ? _c(
              "div",
              { staticClass: "column-format fill-height" },
              [
                _c(
                  "div",
                  {
                    class:
                      "row-format align-center mx-2 mt-2 nav-link" +
                      (_vm.navExpanded ? "-expanded" : ""),
                    staticStyle: { "margin-bottom": "2px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row-format align-center px-3 pb-3 pt-2 pointer",
                        staticStyle: {
                          width: "100%",
                          "min-height": "56px",
                          "min-width": "56px"
                        },
                        attrs: { id: "moxie-logo-link" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/getting-started")
                          }
                        }
                      },
                      [
                        _vm.navExpanded
                          ? _c("logo", {
                              staticStyle: {
                                "max-height": "28px",
                                "margin-left": "-2px",
                                "margin-top": "-4px"
                              }
                            })
                          : _c("icon", {
                              staticStyle: {
                                "max-height": "22px",
                                "margin-left": "-8px",
                                "margin-top": "-8px"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("/getting-started")
                                }
                              }
                            })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "column-format gap-5 pb-16",
                    staticStyle: {
                      "max-height": "calc(var(--vh) - 120px)",
                      "overflow-y": "auto",
                      "padding-top": "6px"
                    }
                  },
                  [
                    _vm._l(_vm.pinnedElements, function(element) {
                      return [
                        !element.title.featureAccess ||
                        _vm.$store.getters.hasFeatureAccess(
                          element.title.featureAccess
                        )
                          ? _c("nav-link", {
                              key: element.key,
                              attrs: {
                                label: element.title.label,
                                icon: element.title.icon,
                                route: element.key,
                                "nav-config": element.tabs,
                                expanded: _vm.navExpanded
                              }
                            })
                          : _vm._e()
                      ]
                    }),
                    _c(
                      "v-menu",
                      {
                        attrs: { "close-on-content-click": false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "nav-link",
                                    _vm._g(
                                      {
                                        staticClass: "pointer",
                                        attrs: {
                                          label: "More",
                                          icon: "steppers",
                                          more: true,
                                          expanded: _vm.navExpanded
                                        }
                                      },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1350978663
                        ),
                        model: {
                          value: _vm.moreMenu,
                          callback: function($$v) {
                            _vm.moreMenu = $$v
                          },
                          expression: "moreMenu"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "column-format brand-box gap-5",
                            on: {
                              mouseenter: _vm.handleMoreMouseIn,
                              mouseleave: _vm.handleMoreMouseOut
                            }
                          },
                          [
                            _vm.unPinnedElements.length
                              ? _c(
                                  "div",
                                  { staticClass: "column-format gap-5 pt-5" },
                                  [
                                    _vm._l(_vm.unPinnedElements, function(
                                      element
                                    ) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: element.key,
                                            staticClass: "row-format",
                                            staticStyle: {
                                              "align-items": "start"
                                            }
                                          },
                                          [
                                            !element.title.featureAccess ||
                                            _vm.$store.getters.hasFeatureAccess(
                                              element.title.featureAccess
                                            )
                                              ? _c("nav-link", {
                                                  attrs: {
                                                    label: element.title.label,
                                                    icon: element.title.icon,
                                                    route: element.key,
                                                    "nav-config": element.tabs,
                                                    expanded: true
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.moreMenu = false
                                                    }
                                                  }
                                                })
                                              : _vm._e(),
                                            _c(
                                              "v-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "tippy",
                                                    rawName: "v-tippy"
                                                  }
                                                ],
                                                staticClass:
                                                  "ml-auto material-symbols-rounded pointer mr-3 ml-n2",
                                                attrs: {
                                                  size: "16",
                                                  color: "gray_50",
                                                  content: "Pin"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.pin(element.key)
                                                  }
                                                }
                                              },
                                              [_vm._v("keep")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-left pt-3 pb-6 pl-6 pr-3 column-format gap-3",
                                staticStyle: {
                                  "background-color": "var(--v-gray_5-base)",
                                  "border-top":
                                    "1px solid var(--v-gray_30-base)",
                                  "border-bottom-left-radius": "4px",
                                  "border-bottom-right-radius": "4px"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "brand-medium font-14" },
                                  [_vm._v("Pinned")]
                                ),
                                _vm._l(
                                  _vm.pinnedElements.filter(function(e) {
                                    return e.key !== "/quick"
                                  }),
                                  function(element) {
                                    return _c(
                                      "div",
                                      {
                                        key: element.key + "a",
                                        staticClass: "row-format gap-2"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "material-symbols-rounded",
                                            attrs: {
                                              color: "black",
                                              size: "18"
                                            }
                                          },
                                          [_vm._v(_vm._s(element.title.icon))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "nav-label" },
                                          [_vm._v(_vm._s(element.title.label))]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "tippy",
                                                rawName: "v-tippy"
                                              }
                                            ],
                                            staticClass:
                                              "ml-auto material-symbols-rounded pointer",
                                            attrs: {
                                              size: "16",
                                              color: "primary",
                                              content: "Unpin"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.unpin(element.key)
                                              }
                                            }
                                          },
                                          [_vm._v("keep")]
                                        )
                                      ],
                                      1
                                    )
                                  }
                                )
                              ],
                              2
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  2
                ),
                _vm.showTrialBanner && _vm.navExpanded
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "py-2 pointer row-format centered font-14 brand-medium",
                        staticStyle: {
                          color: "var(--v-white-base)",
                          "background-color": "var(--v-secondary-base)",
                          position: "absolute",
                          bottom: "54px",
                          left: "0",
                          width: "236px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/subscription")
                          }
                        }
                      },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm.daysInTrial) +
                              " " +
                              _vm._s(_vm.daysInTrial !== 1 ? "Days" : "Day") +
                              " remaining in your trial"
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-menu",
                  {
                    attrs: {
                      "offset-x": "",
                      "nudge-left": "10",
                      "content-class": "workspace-menu"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  {
                                    staticClass:
                                      "workspace-settings row-format align-center mt-auto",
                                    style: !_vm.navExpanded
                                      ? "max-width: 59px;"
                                      : ""
                                  },
                                  on
                                ),
                                [
                                  !_vm.navExpanded
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row-format centered fill-width"
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                size: "24",
                                                color: "white"
                                              }
                                            },
                                            [
                                              _vm.accountLogo
                                                ? _c("img", {
                                                    attrs: {
                                                      src: _vm.accountLogo,
                                                      alt: "logo"
                                                    }
                                                  })
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "black--text brand-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.accountInitials
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row-format align-center font-14 flex-grow-1 pl-3 pr-2",
                                          staticStyle: {
                                            "line-height": "1.2em"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                size: "24",
                                                color: "white"
                                              }
                                            },
                                            [
                                              _vm.accountLogo
                                                ? _c("img", {
                                                    attrs: {
                                                      src: _vm.accountLogo,
                                                      alt: "logo"
                                                    }
                                                  })
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "black--text brand-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.accountInitials
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "column-format text-left pl-3"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "truncate brand-medium",
                                                  staticStyle: {
                                                    "max-width": "160px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.accountName)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-12 font-gray_50"
                                                },
                                                [_vm._v("Workspace settings")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-auto",
                                              attrs: {
                                                size: "20",
                                                color: "gray_80",
                                                small: ""
                                              }
                                            },
                                            [_vm._v("$chevronRight")]
                                          )
                                        ],
                                        1
                                      )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2322072557
                    )
                  },
                  [_c("settings-nav")],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }