var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pl
    ? _c(
        "div",
        { staticStyle: { width: "100%" } },
        [
          _c("p-l-chart", {
            attrs: { "pl-report": _vm.pl, mode: _vm.chartMode }
          }),
          _c("div", {
            staticClass: "fill-width my-8",
            staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
          }),
          _c(
            "div",
            {
              staticClass: "show-scrollbar",
              staticStyle: { "max-width": "100%", "overflow-x": "auto" }
            },
            [
              _c("table", [
                _c(
                  "thead",
                  [
                    _c("th", { staticClass: "text-left" }),
                    _vm._l(_vm.monthColumns, function(month, index) {
                      return _c(
                        "th",
                        {
                          key: "month-header" + index,
                          staticClass: "text-right month-header nowrap"
                        },
                        [_vm._v(" " + _vm._s(month.toFormat("LLL yy")) + " ")]
                      )
                    }),
                    _c("th", { staticClass: "text-right pr-4" }, [
                      _vm._v("Total")
                    ])
                  ],
                  2
                ),
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm._l(_vm.pl.income, function(income, index) {
                      return _c(
                        "tr",
                        { key: "income" + index, staticClass: "data-tr" },
                        [
                          _c(
                            "td",
                            {
                              staticClass:
                                "text-left desc-cell pl-5 nowrap pr-9"
                            },
                            [_vm._v(" " + _vm._s(income.client.name) + " ")]
                          ),
                          _vm._l(income.months, function(month, index) {
                            return _c(
                              "td",
                              {
                                key: "income-month" + index,
                                staticClass: "money-cell"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formatDataCell(month.amount)) +
                                    " "
                                )
                              ]
                            )
                          }),
                          _c("td", { staticClass: "money-cell semibold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatters.dollars(
                                    _vm.rowTotal("income", index),
                                    true,
                                    true,
                                    _vm.$store.state.defaultCurrency
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        2
                      )
                    }),
                    _c(
                      "tr",
                      { staticClass: "data-tr" },
                      [
                        _c(
                          "td",
                          { staticClass: "desc-cell text-left semibold" },
                          [_vm._v("Total Income")]
                        ),
                        _vm._l(_vm.monthColumns, function(month, index) {
                          return _c(
                            "td",
                            {
                              key: "total-income" + index,
                              staticClass: "text-right money-cell semibold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatters.dollars(
                                      _vm.incomeTotal(index),
                                      true,
                                      true,
                                      _vm.$store.state.defaultCurrency
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "money-cell bold",
                            staticStyle: { color: "var(--v-primary-base)" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatters.dollars(
                                    _vm.allTotal("income"),
                                    true,
                                    true,
                                    _vm.$store.state.defaultCurrency
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      2
                    ),
                    _vm._m(1),
                    _vm._m(2),
                    _vm._l(_vm.pl.expenses, function(expense, index) {
                      return _c(
                        "tr",
                        { key: "expense" + index, staticClass: "data-tr" },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "text-left desc-cell pl-5 nowrap",
                              staticStyle: { "padding-right": "0px !important" }
                            },
                            [_vm._v(" " + _vm._s(expense.category) + " ")]
                          ),
                          _vm._l(expense.months, function(month, index) {
                            return _c(
                              "td",
                              {
                                key: "total-month" + index,
                                staticClass: "money-cell"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatDataCell(month.amount * -1)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          }),
                          _c("td", { staticClass: "money-cell semibold" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatters.dollars(
                                    _vm.rowTotal("expenses", index) * -1,
                                    true,
                                    true,
                                    _vm.$store.state.defaultCurrency
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        2
                      )
                    }),
                    _c(
                      "tr",
                      { staticClass: "data-tr" },
                      [
                        _c(
                          "td",
                          { staticClass: "desc-cell text-left semibold" },
                          [_vm._v("Total Expenses")]
                        ),
                        _vm._l(_vm.monthColumns, function(month, index) {
                          return _c(
                            "td",
                            {
                              key: "total-expense" + index,
                              staticClass: "text-right money-cell semibold"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatters.dollars(
                                      _vm.expenseTotal(index) * -1,
                                      true,
                                      true,
                                      _vm.$store.state.defaultCurrency
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        }),
                        _c(
                          "td",
                          {
                            staticClass: "money-cell bold",
                            staticStyle: { color: "var(--v-primary-base)" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatters.dollars(
                                    _vm.allTotal("expenses") * -1,
                                    true,
                                    true,
                                    _vm.$store.state.defaultCurrency
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      2
                    ),
                    _vm._m(3),
                    _c(
                      "tr",
                      { staticClass: "data-tr" },
                      [
                        _c(
                          "td",
                          { staticClass: "desc-cell text-left semibold" },
                          [_vm._v("Profit")]
                        ),
                        _vm._l(_vm.monthColumns, function(month, index) {
                          return _c(
                            "td",
                            {
                              key: "gt" + index,
                              class:
                                "text-right money-cell semibold " +
                                (_vm.monthTotal(index) >= 0 ? "" : "font-red")
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatters.dollars(
                                      _vm.monthTotal(index),
                                      true,
                                      true,
                                      _vm.$store.state.defaultCurrency
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        }),
                        _c(
                          "td",
                          {
                            class:
                              "money-cell bold " +
                              (_vm.allTotal("income") -
                                _vm.allTotal("expenses") >=
                              0
                                ? "font-primary"
                                : "font-red")
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatters.dollars(
                                    _vm.allTotal("income") -
                                      _vm.allTotal("expenses"),
                                    true,
                                    true,
                                    _vm.$store.state.defaultCurrency
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      2
                    )
                  ],
                  2
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "text-left pt-1 semibold" }, [_vm._v("Money in")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { staticClass: "pt-5" }, [_vm._v(" ")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "text-left semibold" }, [_vm._v("Money out")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", { staticClass: "pt-5" }, [_vm._v(" ")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }