var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "proposal-templates-modal" } }, [
    _c("div", { staticClass: "proposal-header" }, [
      _c("div", { staticClass: "header-left pl-md-6 pl-4" }, [
        _c("div", { staticClass: "brand-medium" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("templates.proposals.my-saved-templates-library")) +
              " "
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "header-right pr-md-6 pr-4" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "cards-body-wrapper show-scrollbar" }, [
      _c(
        "div",
        { staticClass: "cards-body" },
        [
          !_vm.sampleMode && !_vm.paidAccount
            ? _c(
                "div",
                { staticClass: "column-format centered mb-3 px-3 mt-3 mx-1" },
                [
                  _c("free-tier-card", {
                    staticStyle: { height: "90px", width: "100%" },
                    attrs: {
                      message:
                        "Templates are not available on the Essentials plan. You can explore this feature, but you won't be able to create projects and proposals from these templates."
                    },
                    on: {
                      "upgrade-clicked": function($event) {
                        return _vm.upgradeClicked()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.currentView === "MINE"
            ? _c("proposal-templates", {
                attrs: {
                  "client-id": _vm.clientId,
                  opportunity: _vm.opportunity,
                  mode: "MODAL"
                },
                on: {
                  result: function($event) {
                    return _vm.handleTemplateSelect($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }