<template>
	<div class="column-format gap-2">
		<v-select
				persistent-placeholder
				hide-details
				dense
				outlined
				:label="label"
				item-text="label"
				item-value="value"
				v-model="meetingLength"
				:items="lengthOptions"
				@change="doUpdate"
		></v-select>
		<div v-if="isCustomLength" class="row-format">
			<v-text-field
					hide-details
					dense
					outlined
					type="number"
					class="mr-2"
					style="width:50%"
					v-model="customDuration"
					@change="doUpdate"
			></v-text-field>
			<v-select
					hide-details
					dense
					outlined
					item-text="label"
					item-value="value"
					v-model="customUnit"
					:items="unitOptions"
					@change="doUpdate"
			>
				<template v-slot:append>
					<div class="row-format" style="margin-top: 2px; border-left: 1px solid var(--v-gray_50-base); width:33px; justify-content: end">
						<v-icon style="margin-right:-2px" small>$arrowDown</v-icon>
					</div>
				</template>
			</v-select>
		</div>
	</div>
</template>

<script>
export default {
	name: "DurationSelector",

	props: ['label','value','options','units'],

	components: {},

	data: function () {
		return {
			customDuration: null,
			customUnit: null,
			meetingLength: null,
		}
	},

	mounted() {
		if(this.value){
			this.customDuration =  this.value.duration;
			this.customUnit = this.value.timeUnit;
			this.meetingLength = this.value.duration + ':' + this.value.timeUnit;

			if(this.lengthOptions.findIndex(o => o.value === this.meetingLength) === -1){
				this.meetingLength = 'CUSTOM';
			}
		}
	},

	beforeDestroy() {

	},

	methods: {
		doUpdate: function(){
			if(!this.meetingLength){
				this.$emit('input',null);
			} else if(this.isCustomLength){
				this.$emit('input',{
					duration: this.customDuration,
					timeUnit: this.customUnit
				})
			}else{
				let parts = this.meetingLength.split(":");
				this.$emit('input',{
					duration: parseInt(parts[0],10),
					timeUnit: parts[1]
				})
			}
		},

	},

	computed: {
		unitOptions: function() {
			if(!this.units){
				return [
					{ label: 'Minutes', value: 'MINUTES' },
					{ label: 'Hours', value: 'HOURS' },
					{ label: 'Days', value: 'DAYS' },
				]
			}else{
				return this.units;
			}
		},

		isCustomLength: function() {
			return this.meetingLength === 'CUSTOM';
		},

		lengthOptions: function () {
			if(!this.options){
				return [
					{ label: '15 minutes', value: '15:MINUTES' },
					{ label: '30 minutes', value: '30:MINUTES' },
					{ label: '45 minutes', value: '45:MINUTES' },
					{ label: '60 minutes', value: '60:MINUTES' },
					{ label: 'Custom', value: 'CUSTOM' },
				];
			}else{
				return this.options;
			}
		},
	},

}
</script>

<style scoped lang="scss">

</style>