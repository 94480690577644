<template>
	<div class="row-format align-center pointer" @dblclick="$emit('open')">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'NodeLabel',

		props: [],

		components: {},

		data: function() {
			return {
				mouseTime: 0,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			mouseDown: function(event){
				if(event.buttons === 1) {
					this.mouseTime = new Date().getTime();
				}else{
					this.mouseTime = 0;
				}
			},

			mouseUp: function(){
				let duration = new Date().getTime() - this.mouseTime;
				if(duration < 400){
					this.$emit('open');
				}
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
