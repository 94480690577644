var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-6", staticStyle: { "max-width": "580px" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "pb-0 mb-0 column-format centered",
                  attrs: { cols: "12", align: "center" }
                },
                [
                  _c("v-icon", { attrs: { color: "warning", large: "" } }, [
                    _vm._v("$alert")
                  ]),
                  _vm._v(
                    " By deleting your account, all of your data including client records, attachments, invoices, contracts, etc will be permanently removed and will be unrecoverable. "
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pt-3", attrs: { cols: "12", align: "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "warning",
                        disabled:
                          _vm.hasPaidAccount || _vm.hasMultipleWorkspaces
                      },
                      on: { click: _vm.confirmDeleteAccount }
                    },
                    [_vm._v("Yes, I want to delete my account")]
                  ),
                  _vm.hasPaidAccount
                    ? _c("div", { staticClass: "mt-6 font-italic font-14" }, [
                        _vm._v(
                          "** You cannot delete your account while you have an active subscription **"
                        )
                      ])
                    : _vm._e(),
                  _vm.hasMultipleWorkspaces
                    ? _c("div", { staticClass: "mt-6 font-italic font-14" }, [
                        _vm._v(
                          "** You currently have more than one workspace which you are the owner of. Please individually delete your workspaces in the workspace tab **"
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }