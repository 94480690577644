var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-2" },
    [
      _c("v-select", {
        attrs: {
          "persistent-placeholder": "",
          "hide-details": "",
          dense: "",
          outlined: "",
          label: _vm.label,
          "item-text": "label",
          "item-value": "value",
          items: _vm.lengthOptions
        },
        on: { change: _vm.doUpdate },
        model: {
          value: _vm.meetingLength,
          callback: function($$v) {
            _vm.meetingLength = $$v
          },
          expression: "meetingLength"
        }
      }),
      _vm.isCustomLength
        ? _c(
            "div",
            { staticClass: "row-format" },
            [
              _c("v-text-field", {
                staticClass: "mr-2",
                staticStyle: { width: "50%" },
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  type: "number"
                },
                on: { change: _vm.doUpdate },
                model: {
                  value: _vm.customDuration,
                  callback: function($$v) {
                    _vm.customDuration = $$v
                  },
                  expression: "customDuration"
                }
              }),
              _c("v-select", {
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  "item-text": "label",
                  "item-value": "value",
                  items: _vm.unitOptions
                },
                on: { change: _vm.doUpdate },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "row-format",
                              staticStyle: {
                                "margin-top": "2px",
                                "border-left":
                                  "1px solid var(--v-gray_50-base)",
                                width: "33px",
                                "justify-content": "end"
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticStyle: { "margin-right": "-2px" },
                                  attrs: { small: "" }
                                },
                                [_vm._v("$arrowDown")]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3764329102
                ),
                model: {
                  value: _vm.customUnit,
                  callback: function($$v) {
                    _vm.customUnit = $$v
                  },
                  expression: "customUnit"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }