<template>
	<div v-if="mode === 'standard' || mode === 'dense'" style="width:100%">
		<v-text-field
				hide-details
				persistent-placeholder
				:dense="mode === 'dense'"
				outlined
				v-model="ourValue"
				:label="field.name"
				@input="handleChange"
		></v-text-field>
	</div>
	<div v-else style="width:100%">
		<input v-model="ourValue" type="email" placeholder="--" @change="handleChange" :style="`color: ${isValidEmail ? 'var(--v-black-base)' : 'var(--v-error-base)'}`"/>
	</div>
</template>

<script>
export default {
	name: "CustomEmail",

	props: ['field','value','mode'],

	components: {},

	data: function () {
		return {
			ourValue: this.value
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		handleChange: function(){
			this.$emit('change',this.ourValue);
		}
	},

	computed: {
		isValidEmail() {
			if(this.ourValue) {
				return /^[^@]+@\w+(\.\w+)+\w$/.test(this.ourValue);
			}else{
				return true;
			}
		}
	},

}
</script>

<style scoped lang="scss">
	input:focus {
		outline: 1px solid var(--v-gray_30-base);
	}

	input {
		width:100%;
	}
</style>