<template>
	<div class="column-format pointer py-2 font-14" @click="$emit('ticket-clicked')">
		<div class="row-format">
			<v-icon class="material-symbols-rounded mr-3">local_activity</v-icon>
			<client-avatar class="mr-3" :client="client" v-if="client"></client-avatar>
			<div v-else style="width:2px; height: 32px"></div>
			<div class="row-format align-center" style="flex-wrap: wrap; gap: 4px">
				<div class="deliverableName mr-3 brand-medium">{{ ticket.subject }}</div>
				<div class="dueDate mr-3 row-format align-center">
					<v-icon size="15" color="gray_70">$clock</v-icon>
					<div class="font-gray_80">
						{{ DateTime.fromISO(ticket.dueDate).toLocaleString({ month: 'short', day: 'numeric' }) }}
					</div>
				</div>
				<div v-if="ticket.assignedTo.length" class="row-format align-center">
					<assigned-user
						v-for="(assignedTo, index) in ticket.assignedTo"
						:key="assignedTo"
						:show-name="false"
						:small="true"
						:assigned-to="assignedTo"
						:style="
							`${
								index > 0 ? 'margin-left: -6px' : ''
							}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
						"
					></assigned-user>
				</div>
				<div class="status row-format align-center">
					<div>{{ ticket.status }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import DateTime from '@/modules/utils/HDateTime';
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';
	import AssignedUser from '@/components/AssignedUser';

	export default {
		name: 'TicketMini',

		props: ['ticket', 'client'],

		components: { ClientAvatar, AssignedUser },

		data: function() {
			return {
				DateTime: DateTime,
				tasksExpanded: false,
				ticketService: new CommunicatorTicketService(),
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.projectName {
		background-color: var(--v-brightblue_0-base);
		color: var(--v-black-base);
		border-radius: 4px;
		padding: 4px 12px;
	}

	.status {
		background-color: var(--v-accent-base);
		border-radius: 4px;
		padding: 0px 4px;
		color: var(--v-black-base);
		font-size: 12px;
		font-weight: 500;
	}
</style>
