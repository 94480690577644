var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.detailPerson
      ? _c(
          "div",
          [
            _c("person-detail", {
              attrs: { person: _vm.detailPerson },
              on: {
                back: function($event) {
                  return _vm.showAll()
                },
                "person-updated": function($event) {
                  return _vm.personUpdated($event)
                },
                "view-communication": function($event) {
                  return _vm.$emit("view-communication", $event)
                }
              }
            })
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "row-format chip-container" },
          _vm._l(_vm.contacts, function(contact, index) {
            return _c("person-chip", {
              key: "contact-" + index,
              attrs: { contact: contact },
              on: {
                "show-detail": function($event) {
                  return _vm.showDetail(contact, index)
                }
              }
            })
          }),
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }