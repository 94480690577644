var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "share-send-widget" } },
    [
      _c("input", {
        attrs: { id: "copyInput", name: "copyInput", type: "hidden" }
      }),
      _c(
        "v-btn",
        { staticClass: "close-dialog", attrs: { icon: "" } },
        [
          _c(
            "v-icon",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("$close")]
          )
        ],
        1
      ),
      _c("div", [
        _vm.view === "SELECT"
          ? _c("div", { staticClass: "modal-padding" }, [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("meeting.share.select-method")))
              ]),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: {
                    click: function($event) {
                      _vm.view = "EMBED"
                    }
                  }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("code")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("meeting.share.embed-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("meeting.share.embed-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("meeting.share.embed-next")))
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: {
                    click: function($event) {
                      _vm.view = "LINK"
                    }
                  }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("$share")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("meeting.share.link-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("meeting.share.link-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("meeting.share.link-next")))
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: {
                    click: function($event) {
                      _vm.view = "INTERNAL-LINK"
                    }
                  }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("lock")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("meeting.share.internal-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("meeting.share.internal-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("meeting.share.internal-next")))
                    ])
                  ])
                ]
              )
            ])
          : _vm._e(),
        _vm.view === "EMBED"
          ? _c("div", [
              _c("div", { staticClass: "modal-padding text-left" }, [
                _c(
                  "div",
                  { staticClass: "modal-title mb-2 row-format align-center" },
                  [
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function($event) {
                            _vm.view = "SELECT"
                          }
                        }
                      },
                      [_vm._v("$arrowLeft")]
                    ),
                    _c("div", { staticClass: "ml-3" }, [
                      _vm._v("Copy the code below")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "pa-3 text-no-wrap",
                    staticStyle: {
                      "font-size": "12px",
                      "font-family": "Courier",
                      border: "1px solid var(--v-gray_50-base)",
                      "border-radius": "4px",
                      "overflow-y": "scroll"
                    }
                  },
                  [
                    _vm._v(
                      ' <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.10/iframeResizer.min.js"></script>'
                    ),
                    _c("br"),
                    _vm._v(' <div style="width:100%; min-height:500px">'),
                    _c("br"),
                    _vm._v(
                      '   <iframe src="' +
                        _vm._s(_vm.fullLinkEmbed) +
                        '?inFrame=true" id="moxie-' +
                        _vm._s(_vm.meetingId) +
                        '" style="padding: 0px; margin: 0px; border: 0; max-width: 100%; min-width: 100%"></iframe>'
                    ),
                    _c("br"),
                    _vm._v(" </div>"),
                    _c("br"),
                    _vm._v(' <script type="text/javascript">'),
                    _c("br"),
                    _vm._v(
                      "   setTimeout(() => iFrameResize({heightCalculationMethod: 'min', sizeWidth: true, sizeHeight: true, log: false, checkOrigin: false}, '#moxie-" +
                        _vm._s(_vm.meetingId) +
                        "'),100);"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      '   window.addEventListener("message", (event) => {'
                    ),
                    _c("br"),
                    _vm._v(
                      "     if(event.data && event.data.startsWith('[Redirect]')){"
                    ),
                    _c("br"),
                    _vm._v("       let url = event.data.slice(10);"),
                    _c("br"),
                    _vm._v("       window.location = url;"),
                    _c("br"),
                    _vm._v("     }"),
                    _c("br"),
                    _vm._v("   }, false);"),
                    _c("br"),
                    _vm._v(" </script> ")
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("Done")]
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.view === "LINK" || _vm.view === "INTERNAL-LINK"
          ? _c("div", [
              _c("div", { staticClass: "modal-padding text-left" }, [
                _c(
                  "div",
                  { staticClass: "modal-title mb-2 row-format align-center" },
                  [
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function($event) {
                            _vm.view = "SELECT"
                          }
                        }
                      },
                      [_vm._v("$arrowLeft")]
                    ),
                    _c("div", { staticClass: "ml-3" }, [
                      _vm._v("Copy the link below")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row-format align-center" },
                  [
                    _c("v-text-field", {
                      staticClass: "h-outline mt-4",
                      attrs: {
                        readonly: "",
                        "persistent-placeholder": "",
                        "hide-details": "",
                        label: "Direct meeting link",
                        value:
                          _vm.view === "LINK" ? _vm.fullLink : _vm.internalLink
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-2",
                        attrs: { color: "primary", icon: "" },
                        on: { click: _vm.copyLink }
                      },
                      [_c("v-icon", [_vm._v("content_copy")])],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("Done")]
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }