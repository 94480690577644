var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "calc(var(--vh) - 60px)",
        "background-color": "var(--v-white-base)"
      }
    },
    [
      _c("iframe", {
        staticStyle: { width: "100%", height: "100%", border: "none" },
        attrs: {
          src: _vm.iFrameUrl,
          crossorigin: "anonymous",
          target: "_blank"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }