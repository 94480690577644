<template>
	<div>
		<v-snackbar v-model="show" color="error" :timeout="55000" :bottom="true" style="font-size: 1.1em">
			{{buttonLabel}}
			<v-btn outlined @click="doUndo()" class="ml-2"> Undo now <v-icon class="ml-1">undo</v-icon> </v-btn>
			<v-btn icon text class="ml-4" @click="close()"><v-icon>close</v-icon></v-btn>
		</v-snackbar>
	</div>
</template>

<script>
	import ProjectDeliverableService from '@/modules/projects/deliverable/ProjectDeliverableService';
	export default {
		name: 'SafeDeleteHandler',

		props: [],

		components: {},

		data: function () {
			return {
				projectDeliverableService: new ProjectDeliverableService(),

				show: false,
				event: null,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('safe-delete', this.listenForSafeDelete);
		},

		beforeDestroy() {},

		methods: {
			listenForSafeDelete: function (event) {
				this.show = true;
				this.event = event;
			},

			close: function () {
				this.show = false;
				this.event = null;
			},

			doUndo: function () {
				if (this.event.type === 'ProjectDeliverable') {
					this.projectDeliverableService.cancelDeleteDeliverable(this.event.deliverableId).then(() => {
						this.$store.commit('success','Operation has been undone.');
					}).finally(() => {
						this.close();
					})
				}
			},
		},

		computed: {
			buttonLabel: function(){
				if(this.event && this.event.type === 'ProjectDeliverable'){
					return this.$t('projects.safe-delete');
				}else{
					return 'Unknown operation.'
				}
			}
		},

		watch: {
			show: function (newVal) {
				if (!newVal) {
					this.event = null;
				}
			},


		},
	};
</script>

<style scoped lang="scss"></style>
