var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-width": "500px", "max-width": "500px" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: { click: _vm.handleClose }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "v-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.connectAccount()
            }
          },
          model: {
            value: _vm.formValid,
            callback: function($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid"
          }
        },
        [
          _c("div", { staticClass: "modal-padding" }, [
            _c(
              "div",
              { staticClass: "modal-title row-format align-center" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mb-1 mr-3", attrs: { color: "black" } },
                  [_vm._v("$apple")]
                ),
                _vm._v(" "),
                _c("div", [_vm._v("Connect iCloud Calendar")])
              ],
              1
            ),
            _c("div", { staticClass: "modal-subtitle" }, [
              _vm._v(
                "To connect your iCloud calendar, you must create an app-specific password. Please see this "
              ),
              _c(
                "a",
                {
                  staticStyle: { color: "var(--v-primary-base)" },
                  attrs: {
                    href: "https://support.apple.com/en-us/HT204397",
                    target: "_blank"
                  }
                },
                [_vm._v("Apple help article")]
              ),
              _vm._v(" for instructions.")
            ]),
            _c(
              "div",
              { staticClass: "pt-2" },
              [
                _c("v-text-field", {
                  staticClass: "h-outline",
                  attrs: {
                    rules: _vm.emailRules,
                    type: "text",
                    autofocus: "",
                    "hide-details": "",
                    "persistent-placeholder": "",
                    label: "iCloud email"
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                }),
                _c("v-text-field", {
                  staticClass: "h-outline",
                  attrs: {
                    rules: _vm.passwordRules,
                    type: "password",
                    "hide-details": "",
                    "persistent-placeholder": "",
                    label: "App specific password"
                  },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                })
              ],
              1
            ),
            _vm.errorMessage
              ? _c(
                  "div",
                  {
                    staticClass: "text-left font-14 pt-2",
                    staticStyle: { color: "var(--v-error-base)" }
                  },
                  [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
                )
              : _vm._e()
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "pt-2 pb-4",
          staticStyle: { "border-top": "1px solid var(--v-gray_50-base)" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              attrs: { width: "150" },
              on: { click: _vm.handleClose }
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-1",
              attrs: { width: "150", disabled: !_vm.formValid },
              on: { click: _vm.connectAccount }
            },
            [_vm._v("Connect")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }