var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c("label", {
            staticClass: "question",
            domProps: { innerHTML: _vm._s(_vm.htmlText) }
          }),
          _c(
            "div",
            { staticClass: "pt-3" },
            [
              _vm._l(_vm.options, function(option) {
                return _c("v-checkbox", {
                  key: option,
                  staticClass: "box",
                  staticStyle: { margin: "0px", padding: "0px" },
                  attrs: {
                    dense: "",
                    "hide-details": "",
                    label: option,
                    value: option,
                    rules: _vm.required ? _vm.inputRequired : [],
                    disabled: _vm.previewMode
                  },
                  on: {
                    change: function($event) {
                      return _vm.handleChange()
                    }
                  },
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                })
              }),
              _vm.hasOther
                ? _c(
                    "div",
                    { staticClass: "row-format" },
                    [
                      _c("v-checkbox", {
                        staticStyle: { margin: "0px", padding: "0px" },
                        attrs: {
                          value: "$$other",
                          label: "",
                          dense: "",
                          "hide-details": "",
                          rules: _vm.required ? _vm.inputRequired : [],
                          disabled: _vm.previewMode
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleChange()
                          }
                        },
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      }),
                      _c(
                        "div",
                        { staticStyle: { width: "320px" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: _vm.$t("discovery.other"),
                              dense: "",
                              "hide-details": "",
                              disabled:
                                _vm.selected.indexOf("$$other") === -1 ||
                                _vm.previewMode
                            },
                            on: {
                              input: function($event) {
                                return _vm.handleChange()
                              }
                            },
                            model: {
                              value: _vm.otherValue,
                              callback: function($$v) {
                                _vm.otherValue = $$v
                              },
                              expression: "otherValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }