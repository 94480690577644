var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "max-width": "400px" } }, [
    _c(
      "div",
      { staticClass: "row-format align-center mb-6 pt-6 px-6" },
      [
        _c("div", { staticClass: "brand-medium font-16" }, [
          _vm._v(_vm._s(_vm.heading))
        ]),
        _c(
          "v-icon",
          {
            staticClass: "ml-auto",
            attrs: { size: "20" },
            on: {
              click: function($event) {
                return _vm.$emit("result")
              }
            }
          },
          [_vm._v("$close")]
        )
      ],
      1
    ),
    _c("div", { staticStyle: { "min-width": "400px" } }, [
      _c(
        "div",
        { staticClass: "px-6" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.isValid,
                callback: function($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c(
                "div",
                { staticClass: "column-format gap-3" },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.rules,
                      label: "Password",
                      type: "password",
                      dense: "",
                      "hide-details": "",
                      "persistent-placeholder": "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.newPassword,
                      callback: function($$v) {
                        _vm.newPassword = $$v
                      },
                      expression: "newPassword"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      rules: _vm.confirmPasswordRules,
                      label: "Confirm password",
                      type: "password",
                      "persistent-placeholder": "",
                      "hide-details": "",
                      dense: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.confirmPassword,
                      callback: function($$v) {
                        _vm.confirmPassword = $$v
                      },
                      expression: "confirmPassword"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-14 font-gray_70 row-format align-center gap-2 mb-3"
                    },
                    [
                      _vm.isValid
                        ? _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("check")
                          ])
                        : _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v("cancel")
                          ]),
                      _c("div", { staticClass: "text-left" }, [
                        _vm._v(
                          "Passwords must be between 8-20 characters and include 1 upper, 1 lower, 1 digit, 1 symbol"
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "py-2",
          staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "super-action",
              attrs: { width: "160", disabled: !_vm.isValid },
              on: { click: _vm.updatePassword }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.save")) + " ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }