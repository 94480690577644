<template>
	<div :key="mode">
		<LineChartGenerator
				:key="refreshKey"
				:chart-id="refreshKey"
				:chart-options="chartOptions"
				:chart-data="chartData"
				:height="300"
		/>	</div>
</template>

<script>
	import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
	import DateTime from "@/modules/utils/HDateTime";
	import {v4 as uuidv4} from 'uuid';


	export default {
		name: 'PLChart',

		props: ['plReport','mode'],

		components: { LineChartGenerator },

		data: function () {
			return {
				refreshKey: uuidv4(),

				chartOptions: {
					responsive: true,
					maintainAspectRatio: false,

					scales: {
						y: {
							ticks: {
								display: true,
							},
							grid: {
								display: true,
								drawBorder: false
							},
						},
						x: {
							grid: {
								display: false,
							},
							ticks: {
								display: true,
								autoSkip: true,
								maxTicksLimit: 30,
								maxRotation: 0,
								minRotation: 0,
							},
						},
					},
				},
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			getInitializedArray: function(){
				let result = [];
				let start = DateTime.fromISO(this.plReport.earliest);
				let end = DateTime.fromISO(this.plReport.latest);

				while (start < end) {
					result.push(0);
					start = start.plus({months:1});
				}
				return result;
			},

			calculateSum(key){
				let sum = this.getInitializedArray();

				this.plReport[key].forEach(e => {
					for(let i=0; i < e.months.length; i++){
						sum[i] = sum[i] + e.months[i].amount;
					}
				})

				for(let i=0; i < sum.length; i++){
					sum[i] = Math.ceil(sum[i]);
				}

				return sum;
			}
		},

		computed: {
			expenses: function(){
				let expenses = this.calculateSum('expenses');
				let baseSum = 0;
				if(this.mode === 'Cumulative') {
					for (let i = 0; i < expenses.length; i++) {
						baseSum = baseSum + expenses[i];
						expenses[i] = baseSum;
					}
				}
				return expenses;
			},

			income: function(){
				let income = this.calculateSum('income');
				let baseSum = 0;
				if(this.mode === 'Cumulative') {
					for (let i = 0; i < income.length; i++) {
						baseSum = baseSum + income[i];
						income[i] = baseSum;
					}
				}
				return income;
			},

			labels: function () {
				let result = [];
				let start = DateTime.fromISO(this.plReport.earliest);
				let end = DateTime.fromISO(this.plReport.latest);

				while (start < end) {
					result.push(start.toLocaleString({month:'short', year: 'numeric'}));
					start = start.plus({months:1});
				}
				return result;
			},

			profit: function(){
				let expenses = this.calculateSum('expenses');
				let income = this.calculateSum('income');
				let result = this.getInitializedArray();
				let baseSum = 0;

				for(let i=0; i < result.length; i++){
					if(this.mode === 'Cumulative') {
						baseSum = baseSum + (income[i] - expenses[i]);
						result[i] = baseSum;
					}else{
						result[i] = income[i] - expenses[i];
					}
				}

				return result;
			},

			chartData: function () {
				return {
					labels: this.labels,
					datasets: [
						{
							fill: false,
							lineTension: 0.5,
							label: 'Money in',
							data: this.income,
							borderWidth: 4,
							borderColor: "#175AA8",
							backgroundColor: "#175AA8",
							pointBackgroundColor: "#175AA8",
							pointBorderColor: "#175AA8",
							pointHoverBackgroundColor: "#175AA8",
							pointHoverBorderColor: "#175AA8",
							tooltip: {
								callbacks: {
									label: (context) => {
										return ' Money in: ' + this.$formatters.dollars(context.raw, false, true, this.currency);
									},
								},
							},
						},
						{
							fill: false,
							lineTension: 0.5,
							label: 'Money out',
							data: this.expenses,
							borderWidth: 4,
							borderColor: "#FD7E14",
							backgroundColor: "#FD7E14",
							pointBackgroundColor: "#FD7E14",
							pointBorderColor: "#FD7E14",
							pointHoverBackgroundColor: "#FD7E14",
							pointHoverBorderColor: "#FD7E14",
							tooltip: {
								callbacks: {
									label: (context) => {
										return ' Money out: ' + this.$formatters.dollars(context.raw, false, true, this.currency);
									},
								},
							},
						},
						{
							fill: false,
							lineTension: 0.5,
							label: 'Profit',
							data: this.profit,
							borderWidth: 4,
							borderColor: "#51CF66",
							backgroundColor: "#51CF66",
							pointBackgroundColor: "#51CF66",
							pointBorderColor: "#51CF66",
							pointHoverBackgroundColor: "#51CF66",
							pointHoverBorderColor: "#51CF66",
							tooltip: {
								callbacks: {
									label: (context) => {
										return ' Profit: ' + this.$formatters.dollars(context.raw, false, true, this.currency);
									},
								},
							},
						},
					],
				};
			},
		},

		watch: {

		}
	};
</script>

<style scoped lang="scss"></style>
