<template>
	<div class="row-format pointer py-2 font-14 todo" @click="$emit('todo-clicked')">
		<v-icon class="material-symbols-rounded mr-3">filter_alt</v-icon>
		<client-avatar class="mr-3" :client="client" v-if="client"></client-avatar>
		<div v-else style="width:2px; height: 32px"></div>
		<div class="row-format align-center" style="flex-wrap: wrap; gap: 4px">
			<div class="opportunityName mr-3">{{ opportunityToDo.opportunityName }}</div>
			<div class="deliverableName mr-3 brand-medium">{{ opportunityToDo.toDo.item }}</div>
			<div class="dueDate mr-3 row-format align-center">
				<v-icon size="15" color="gray_70">$clock</v-icon>
				<div class="font-gray_80">
					{{ opportunityToDo.luxonDate.toLocaleString({ month: 'short', day: 'numeric' }) }}
				</div>
			</div>
			<v-checkbox
				@click.stop
				v-model="opportunityToDo.complete"
				hide-details
				dense
				v-tippy="{ content: 'Mark complete' }"
				class="mt-n1"
				@change="handleChange"
			></v-checkbox>
			<div
				class="opportunityStatus row-format align-center"
				:style="`--status-color:${opportunityToDo.stage ? opportunityToDo.stage.hexColor : '#FFF'}`"
			>
				<div class="statusBox"></div>
				<div>{{ opportunityToDo.stage ? opportunityToDo.stage.label : '?' }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import OpportunityService from '@/modules/pipeline/OpportunityService';

	export default {
		name: 'OpportunityToDo',

		props: ['opportunityToDo'],

		components: { ClientAvatar },

		data: function() {
			return {
				opportunityService: new OpportunityService(),
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleChange: function() {
				this.opportunityService.updateOpportunityToDo(
					this.opportunityToDo.opportunityId,
					this.opportunityToDo.id,
					this.opportunityToDo.complete
				);
			},
		},

		computed: {
			client: function() {
				return this.$store.getters.getClientById(this.opportunityToDo.clientId);
			},
		},
	};
</script>

<style scoped lang="scss">
	.opportunityName {
		background-color: var(--v-sidebar-base);
		color: var(--v-black-base);
		border-radius: 4px;
		padding: 4px 12px;
	}

	.opportunityStatus {
		font-size: 12px;
		padding: 2px 8px;
		box-sizing: border-box;
		border-radius: 4px;
		border: 1px solid var(--v-gray_50-base);

		.statusBox {
			margin-right: 8px;
			width: 10px;
			height: 10px;
			border-radius: 2px;
			background-color: var(--status-color);
		}
	}
</style>
