<template>
	<div>
		<div v-if="filter" class="row-format gap-2">
			<v-text-field
				outlined
				hide-details
				dense
				v-model="filter.search"
				placeholder="Search..."
				:style="showAnyFilter ? 'max-width: 200px; min-width: 200px' : 'max-width: 300px; min-width: 300px'"
				style="background-color: var(--v-white-base)"
				color="gray_30"
				@input="updated()"
			>
				<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
			</v-text-field>
			<v-menu
				v-if="showAnyFilter"
				v-model="menuOpen"
				:nudge-top="28"
				bottom
				left
				rounded
				offset-overflow
				offset-y
				:close-on-content-click="false"
				content-class="filter-menu-class"
				transition="slide-y-transition"
			>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" style="min-height: 40px; background-color: var(--v-white-base)" elevation="0" outlined color="gray_30" >
						<span :class="`font-14 ${!isDefaultFilter ? 'font-secondary' : 'font-gray_60'} brand-medium`"
							><span v-html="isDefaultFilter ? filterLabel : filterDescription"></span
						></span>
						<v-icon x-small class="mx-1" :color="!isDefaultFilter ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
					</v-btn>
				</template>

				<div class="column-format open-menu">
					<filter-option
						v-show="showInvoiceStatus"
						:label="$t('client.filter.invoice-status')"
						:option-list="invoiceStatus"
						v-model="filter.invoiceStatus"
						@change="updated"
					></filter-option>

					<filter-option
						v-show="showTimeStatus"
						:label="$t('client.filter.timer-status')"
						:option-list="timeEntryStatus"
						v-model="filter.timeEntryStatus"
						@change="updated"
					></filter-option>

					<div v-if="showDateRange" class="filter-option-box">
						<div class="row-format" @click="dateExpanded = !dateExpanded">
							<div class="font-14">{{ $t('client.filter.date-range') }}</div>
							<v-icon style="margin-left: auto; margin-top: 1px" small>{{
								dateExpanded ? '$arrowUp' : '$arrowDown'
							}}</v-icon>
						</div>

						<div v-if="dateExpanded">
							<v-radio-group v-model="filter.dateSpecifier" @change="updated()">
								<v-radio v-for="option in dateOptions" :key="option.value" :value="option.value">
									<template v-slot:label>
										<span style="font-size: 14px" class="font-14 brand-semilight">{{ option.text }}</span>
									</template>
								</v-radio>
							</v-radio-group>

							<div class="" v-if="showEarliest">
								<date-selector
									content-class="my-5"
									:date="filter.earliest"
									@change="
										filter.earliest = $event;
										updated();
									"
									label="Earliest"
								></date-selector>
							</div>

							<div class="" v-if="showLatest">
								<date-selector
									content-class="my-5"
									:date="filter.latest"
									@change="
										filter.latest = $event;
										updated();
									"
									label="Latest"
								></date-selector>
							</div>
						</div>
					</div>

					<div class="mx-3 mt-3 mb-2">
						<v-btn text block color="secondary" elevation="0" class="filter-clear mb-2" @click="clearFilter()"
							><v-icon small>$clear</v-icon> {{ $t('global.clear') }}</v-btn
						>
						<v-btn class="primary-action" @click="menuOpen = false" block>{{ $t('global.done') }}</v-btn>
					</div>
				</div>
			</v-menu>
		</div>
	</div>
</template>

<script>
	import FilterHelpers from '@/utils/FilterHelpers';
	import DateSelector from '@/components/DateSelector';
	import FilterOption from '@/components/FilterOption';
	import DateTime from '@/modules/utils/HDateTime';

	export default {
		name: 'ProjectFilter',

		props: ['currentView', 'value'],

		components: { FilterOption, DateSelector },

		data: function() {
			return {
				menuOpen: false,
				filter: JSON.parse(JSON.stringify(this.value)),

				dateOptions: FilterHelpers.getDateOptions([
					// 'today',
					// 'yesterday',
					'this-week',
					'last-week',
					'this-month',
					'last-month',
					'past-30-days',
					'past-60-days',
					'past-90-days',
					'past-6-months',
					'past-12-months',
					'this-year',
					'last-year',
					'between',
				]),

				invoiceStatus: [
					{ text: 'Draft', value: 'DRAFT' },
					{ text: 'Sent', value: 'SENT' },
					{ text: 'Partially paid', value: 'PARTIAL' },
					{ text: 'Paid in full', value: 'PAID' },
					{ text: 'Past due', value: 'LATE' },
					{ text: 'Voided', value: 'VOIDED' },
					{ text: 'Written off', value: 'WRITE-OFF' },
				],

				timeEntryStatus: [
					{ text: 'Billed', value: 'BILLED' },
					{ text: 'Un-billed', value: 'UN-BILLED' },
					{ text: 'Non-billable', value: 'NON-BILLABLE' },
				],

				dateExpanded: false,
				timeStatusExpanded: false,
				invoiceStatusExpanded: false,
			};
		},

		mounted() {
			console.log('mounted');
		},

		beforeDestroy() {},

		methods: {
			clearFilter: function() {
				this.$emit('clear-filter');
			},
			updated: function() {
				this.$emit('input', this.filter);
			},
		},

		watch: {
			value: {
				deep: true,
				handler(val) {
					this.filter = JSON.parse(JSON.stringify(val));
				},
			},
		},

		computed: {
			showAnyFilter: function() {
				return this.showTimeStatus || this.showInvoiceStatus || this.showDateRange;
			},

			filterLabel() {
				return 'Filter';
			},

			filterDescription() {
				let l = [];
				let f = this.filter;

				if (f.search && f.search.length > 0) {
					l.push('"' + f.search + '"');
				}

				if (this.currentView !== 'Overview' && this.currentView !== 'Inbox') {
					if (f.dateSpecifier) {
						if (f.dateSpecifier === 'between') {
							return (
								DateTime.fromISO(f.earliest).toLocaleString({ month: 'short', day: 'numeric' }) +
								' - ' +
								DateTime.fromISO(f.latest).toLocaleString({ month: 'short', day: 'numeric' })
							);
						} else {
							l.push(this.dateOptions.find((d) => d.value === f.dateSpecifier).text);
						}
					}

					if (f.invoiceStatus.length) {
						l.push(f.invoiceStatus.length + ' invoice status');
					}

					if (f.timeEntryStatus.length) {
						l.push(f.timeEntryStatus.length + ' timer status');
					}
				}

				if (l.length) {
					return l.join(' &bull; ');
				} else {
					return null;
				}
			},

			showEarliest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}

				if (this.filter.dateSpecifier === 'between') {
					return true;
				} else {
					return false;
				}
			},

			showLatest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}

				if (this.filter.dateSpecifier === 'between') {
					return true;
				} else {
					return false;
				}
			},

			showDateRange: function() {
				if (this.currentView !== 'Overview' && this.currentView !== 'Inbox' && this.currentView !== 'Notes') {
					return true;
				} else {
					return false;
				}
			},

			showTimeStatus: function() {
				if (this.currentView === 'Time') {
					return true;
				} else {
					return false;
				}
			},

			showInvoiceStatus: function() {
				if (this.currentView === 'Invoices') {
					return true;
				} else {
					return false;
				}
			},

			isDefaultFilter() {
				if (this.filterDescription === null) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
