<template>
	<v-menu offset-y v-if="!isCollaborator" :close-on-content-click="false">
		<template v-slot:activator="{ on }">
			<div class="row-format centered">
				<v-icon
					small
					color="primary"
					v-if="column.clientApproval"
					v-on="on"
					v-tippy="{ content: $t('projects.client-icon') }">
					$clients
				</v-icon>
				<v-icon
					color="green"
					small
					style="font-weight: bold"
					v-if="column.complete"
					v-on="on"
					v-tippy="{ content: $t('projects.complete-icon') }"
					>check</v-icon
				>
				<v-icon v-on="on" small>$moreHorizontal</v-icon>
			</div>
		</template>
		<v-card>
			<div class="column-format centered py-3">
				<div class="color-picker-div">
					<v-color-picker
						flat
						mode="hexa"
						show-swatches
						hide-canvas
						hide-mode-switch
						hide-inputs
						class="mx-auto"
						swatches-max-height="200"
						:swatches="clientPalette.palette"
						v-model="column.hexColor"
						@input="updateDeliverableStatusList()"
					></v-color-picker>
				</div>

				<v-switch class="mt-0" hide-details dense v-model="column.clientApproval" @change="updateDeliverableStatusList()">
					<template v-slot:label>
						<span class="font-14 font-gray_90 pr-1">{{ $t('projects.client-approval') }}</span>
            <v-icon class="material-symbols-rounded" small v-tippy="{ content: $t('projects.action-icon') }">help</v-icon>
					</template>
				</v-switch>

				<v-btn class="mt-2" text @click="setStatusComplete(column)" :disabled="column.complete">
					<v-icon color="green" class="mr-1">check</v-icon> {{ $t('projects.set-as-complete') }}
				</v-btn>

				<v-btn text @click="confirmDeleteColumn(column)">
					<v-icon small color="gray_80" class="mr-1">$delete</v-icon> {{ $t('projects.delete-column-button') }}
				</v-btn>
			</div>
		</v-card>
	</v-menu>
</template>

<script>
	import KanbanMixin from './ProjectMixin';
	export default {
		name: 'KanbanColumnEdit',
		mixins: [KanbanMixin],

		props: ['column', 'allColumns', 'isCollaborator'],

		components: {},

		data: function () {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			setStatusComplete: function (column) {
				for (let i = 0; i < this.allColumns.length; i++) {
					this.allColumns[i].complete = false;
				}
				column.complete = true;
				this.updateDeliverableStatusList();
			},

			confirmDeleteColumn: function (column) {
				this.$emit('confirm-delete-column', column);
			},

			updateDeliverableStatusList: function () {
				this.$emit('update-deliverable-status-list');
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.column-ellipsis {
		color: var(--v-gray_70-base);
		white-space: nowrap;
		font-weight: bold;
		letter-spacing: 3px;
	}

	.color-picker-div {
		background-color: white;
		::v-deep .v-color-picker .v-color-picker__controls {
			display: none !important;
		}
	}
</style>
