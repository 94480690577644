<template>
	<div class="font-14 column-format gap-3">
		<v-text-field
			v-if="!hideTaskName"
			:label="$t('templates.packages.deliverable-detail-modal.name-label')"
			:placeholder="$t('templates.packages.deliverable-detail-modal.name-placeholder')"
			autofocus
			hide-details
			outlined
			dense
			persistent-placeholder
			v-model="deliverable.name"
		/>

		<v-textarea
			outlined
			dense
			hide-details
			persistent-placeholder
			:label="$t('proposal.deliverables.description')"
			:placeholder="$t('projects.description-placeholder')"
			v-model="deliverable.description"
			auto-grow
			rows="1"
		></v-textarea>

    <div class="text-left">
      <div>Status</div>
      <v-menu :close-on-click="true" :close-on-content-click="true" offset-y>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="deliverableStatus row-format align-center pointer">
            <template v-if="deliverable.statusId">
              <div class="statusBox" :style="`--status-color:${deliverableStatus.hexColor}`"></div>
              <div class="text-no-wrap">{{ deliverableStatus.label }}</div>
            </template>
            <div v-else class="text-no-wrap">--</div>
          </div>
        </template>
        <div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
          <div
              v-for="status in $store.state.deliverableStatusList.statusList"
              :key="status.id"
              class="py-1 pointer"
              @click="deliverable.statusId = status.id"
          >
            <div
                class="deliverableStatus row-format align-center highlight-hover"
                :style="`--status-color:${status.hexColor}`"
            >
              <div class="statusBox"></div>
              <div>{{ status.label }}</div>
            </div>
          </div>
        </div>
      </v-menu>
    </div>

		<div class="text-left">
			<div>Assigned to</div>
			<v-menu :close-on-click="true" :close-on-content-click="false" offset-y>
				<template v-slot:activator="{ on }">
					<div v-on="on" class="pointer">
						<div v-if="deliverable.assignedToList.length" class="row-format align-center">
							<assigned-user
								v-on="on"
								v-for="(assignedTo, index) in deliverable.assignedToList"
								:key="assignedTo"
								:assigned-to="assignedTo"
								:show-name="false"
								empty-label="--"
								:style="
									`${
										index > 0 ? 'margin-left: -6px' : ''
									}; border: 2px solid var(--v-white-base); border-radius: 100%; z-index: ${index}`
								"
							></assigned-user>
						</div>
						<div v-else>--</div>
					</div>
				</template>
				<div style="background-color: var(--v-white-base)" class="text-left pa-3 font-14">
					<div v-for="user in userList" :key="user.userId" class="row-format align-center text-left py-1 pointer">
						<input
							style="width: 20px; height: 20px; border-radius: 4px"
							type="checkbox"
							:value="user.userId"
							v-model="deliverable.assignedToList"
						/>
						<assigned-user
							class="mx-2"
							v-if="user.userId"
							:assigned-to="user.userId"
							:show-name="true"
						></assigned-user>
					</div>
				</div>
			</v-menu>
		</div>

		<div v-if="feeSchedule.feeType === 'Per Item'">
			<div class="label-style mb-2">{{ $t('templates.packages.deliverable-detail-modal.per-line-item') }}</div>
			<per-item-pricing
				:product="deliverable.product"
				:quantity="deliverable.quantity"
				:editable="true"
				@product-selected="deliverable.product = $event"
				@quantity-updated="deliverable.quantity = $event"
			></per-item-pricing>
		</div>

		<div class="label-style">{{ $t('templates.packages.deliverable-detail-modal.due-after-label') }}</div>
		<div class="d-flex align-center justify-space-between half" style="gap: 12px" v-if="deliverable.dueDate">
			<v-text-field
				type="number"
				inputmode="number"
				:placeholder="$t('templates.packages.deliverable-detail-modal.duration-placeholder', { units: humanUnits })"
				hide-details
				outlined
				dense
				persistent-placeholder
				v-model="deliverable.dueDate.duration"
				@keydown="handleDurationKeydown($event)"
			/>
			<v-select
				:placeholder="$t('templates.packages.deliverable-detail-modal.units-placeholder')"
				hide-details
				outlined
				dense
				persistent-placeholder
				:items="timeUnits"
				item-value="value"
				item-text="text"
				v-model="deliverable.dueDate.timeUnit"
			/>
		</div>
		<div class="label-style">Priority</div>
		<div class="d-flex align-center justify-space-between " style="gap: 12px">
			<v-text-field
				type="number"
				inputmode="number"
				hide-details
				outlined
				dense
				persistent-placeholder
				v-model="deliverable.priority"
			/>
		</div>

		<div class="label-style mt-2">{{ $t('templates.packages.deliverable-detail-modal.tasks-label') }}</div>

		<div class="tasks-wrapper">
			<tasks ref="tasks" v-model="deliverable.tasks" :detail-view="true"></tasks>
		</div>

		<div class="label-style mt-3 mb-2 brand-medium">Attachments</div>
		<file-attachments :edit-mode="true" :files="deliverable.files" :file-service="attachmentService"></file-attachments>

		<div class="label-style mt-3 mb-2 brand-medium">Custom fields</div>
		<div class="row-format py-1" v-for="field in fields" :key="field.id">
			<div class="overview-left">
				<v-icon size="20" class="material-symbols-rounded">{{ field.icon }}</v-icon>
				<div class="ml-2">{{ field.name }}</div>
			</div>
			<div class="overview-right">
				<custom-field
						:field="field"
						:value="getCustomValue(field.id)"
						@change="handleCustomFieldChange($event)"
				></custom-field>
			</div>
		</div>
	</div>
</template>

<script>
	import PerItemPricing from '@/modules/projects/deliverable/PerItemPricing';
	import Tasks from '@/modules/projects/deliverable/Tasks';
	import AssignedUser from '@/components/AssignedUser';
	import FileAttachments from '@/components/FileAttachments';
	import CustomField from '@/components/CustomField';

	export default {
		name: 'DeliverableDetail',

		props: ['feeSchedule', 'deliverable', 'attachmentService', 'hideTaskName'],

		components: { PerItemPricing, Tasks, AssignedUser, FileAttachments, CustomField },

		data: function() {
			return {
				timeUnits: [
					{ text: 'Days', value: 'DAYS' },
					{ text: 'Weeks', value: 'WEEKS' },
					{ text: 'Months', value: 'MONTHS' },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			getCustomValue: function(id) {
				let value = this.deliverable.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			handleCustomFieldChange: function(value) {
				let ix = this.deliverable.customValues.findIndex((c) => c.fieldId === value.fieldId);
				if (ix > -1) {
					if (value.value != null) {
						this.deliverable.customValues.splice(ix, 1, value);
					} else {
						this.deliverable.customValues.splice(ix, 1);
					}
				} else if (value.value != null) {
					this.deliverable.customValues.push(value);
				}
			},

			handleDurationKeydown(event) {
				if (event.key === '.') {
					event.preventDefault();
				}
			},

			checkClear: function(propName) {
				if (this.deliverable[propName] === '--clear--') {
					this.deliverable[propName] = null;
				}
			},
		},

		computed: {
			fields: function() {
				if (this.$store.state.deliverableFields) {
					return this.$store.state.deliverableFields.fields;
				} else {
					return [];
				}
			},

			humanUnits() {
				let i = this.timeUnits.findIndex((u) => {
					return u.value === this.deliverable.dueDate.timeUnit;
				});
				if (i > -1) return this.timeUnits[i].text;
				return '';
			},

			taxEnabled: function() {
				return this.$store.state.podAccount.accountPreferences.invoiceUseTax;
			},

			userList: function() {
				let users = [];

				for (let i = 0; i < this.$store.state.usersForAccount.length; i++) {
					let user = this.$store.state.usersForAccount[i];
					users.push(user);
				}
				return users;
			},

      deliverableStatus: function() {
        if (this.deliverable) {
          return this.$store.getters.getDeliverableStatusById(this.deliverable.statusId);
        } else {
          return null;
        }
      },
		},
	};
</script>

<style scoped lang="scss">
	.overview-left {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: fit-content;
		color: var(--v-gray_70-base);
		font-size: 14px;
		font-weight: 500;
		width: 140px;
		min-width: 140px;
		max-width: 140px;
		margin-right: 16px;
	}

	.overview-right {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 14px;
		font-weight: 500;
	}
</style>
