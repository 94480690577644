var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format gap-2" },
    _vm._l(_vm.daysOfWeek, function(day, index) {
      return _c(
        "v-btn",
        {
          key: index,
          class: { active: _vm.activeDays.includes(day.value) },
          staticStyle: { "border-radius": "50%" },
          attrs: {
            color: _vm.activeDays.includes(day.value) ? "primary" : "gray_30",
            fab: "",
            "x-small": "",
            elevation: "0"
          },
          on: {
            click: function($event) {
              return _vm.toggleDay(day.value)
            }
          }
        },
        [_vm._v(" " + _vm._s(day.label) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }