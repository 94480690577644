var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("input", {
        attrs: { id: "copyInput", name: "copyInput", type: "hidden" }
      }),
      !_vm.inListView
        ? _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          )
        : _vm._e(),
      !_vm.modalState.inSendMode
        ? _c(
            "div",
            { class: _vm.inListView ? "" : "modal-padding" },
            [
              !_vm.inListView
                ? _c("h2", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.$t("leadgen.client-discovery-forms")))
                  ])
                : _vm._e(),
              _c("v-data-table", {
                staticStyle: { cursor: "pointer" },
                attrs: {
                  items: _vm.filteredDiscoveryTemplates,
                  "sort-by": "name",
                  "fixed-header": true,
                  headers: _vm.headers,
                  "items-per-page": -1,
                  "hide-default-footer": true,
                  "group-by": "folder"
                },
                on: {
                  "click:row": function($event) {
                    return _vm.navigateToDiscoveryBuilder($event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "group.header",
                      fn: function(ref) {
                        var group = ref.group
                        var headers = ref.headers
                        var toggle = ref.toggle
                        return [
                          _c(
                            "td",
                            {
                              staticClass: "text-left",
                              attrs: { colspan: "4" },
                              on: { click: toggle }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "row-format align-center gap-2"
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { size: "20", color: "secondary" }
                                    },
                                    [_vm._v("folder")]
                                  ),
                                  _vm._v(_vm._s(group ? group : " -- ") + " ")
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.link",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(" " + _vm._s(item.uniqueTemplateId) + " ")
                        ]
                      }
                    },
                    {
                      key: "item.actions",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "row-format gap-2" },
                            [
                              _vm.inListView
                                ? _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "tippy",
                                          rawName: "v-tippy",
                                          value: {
                                            content: "Copy public form link"
                                          },
                                          expression:
                                            "{content:'Copy public form link'}"
                                        }
                                      ],
                                      staticClass: "ml-auto",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.copyToClipboard(item)
                                        }
                                      }
                                    },
                                    [_vm._v("link")]
                                  )
                                : _vm._e(),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-click": true,
                                    "nudge-bottom": 36
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "ml-1 mr-4" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    { attrs: { size: 24 } },
                                                    scope.on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.value
                                                          ? "$arrowUp"
                                                          : "$moreHorizontal"
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("div", { staticClass: "more-menu" }, [
                                    _vm.clientId
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "more-menu-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.sendTemplate(item)
                                              }
                                            }
                                          },
                                          [_vm._v(" Send ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "more-menu-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.navigateToDiscoveryBuilder(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Edit ")]
                                    ),
                                    item.schemaVersion !== 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "more-menu-item",
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit(
                                                  "download-results",
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" Download results ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "more-menu-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.cloneTemplate(item)
                                          }
                                        }
                                      },
                                      [_vm._v(" Duplicate ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "more-menu-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmDeleteTemplate(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Delete ")]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "no-data",
                      fn: function() {
                        return [
                          _c("search-button", {
                            on: {
                              click: function($event) {
                                return _vm.templateLibrary()
                              }
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2386364786
                )
              }),
              _vm.showV1Warning
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-left row-format align-center font-12 ml-4 gap-1 font-gray_70"
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("warning")
                      ]),
                      _c("div", [
                        _vm._v(
                          "Legacy forms are no longer supported in this flow and are hidden from this list"
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.templateToDelete
                ? _c("confirm-dialog", {
                    attrs: {
                      dialog: _vm.confirmDeleteDialog,
                      persistent: false,
                      "heading-text": "Delete template",
                      "body-text": _vm.$t("discovery.delete-confirm", {
                        template: _vm.templateToDelete.name
                      })
                    },
                    on: {
                      confirm: _vm.deleteTemplate,
                      close: _vm.cancelDeleteTemplate
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.modalState.inSendMode
        ? _c(
            "div",
            {},
            [
              _c("share-send-widget", {
                attrs: {
                  "client-id": _vm.clientId,
                  mode: "Send",
                  "discovery-template": _vm.templateToSend,
                  "mce-config-text": _vm.mceConfigText,
                  "open-state": _vm.modalState.inSendMode
                },
                on: {
                  cancel: function($event) {
                    _vm.modalState.inSendMode = false
                  },
                  "discovery-sent": function($event) {
                    return _vm.handleDiscoverySent()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }