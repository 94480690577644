<template>
	<div>
		<div
				class="pointer"
				style="width:100%;border-bottom: 1px solid var(--v-gray_30-base)"
				@click.stop="expandAccountList = !expandAccountList"
		>
			<v-container
					fluid
					class="ma-0 pa-0"
					:style="expandAccountList && $store.getters.getUserAccounts.length > 1 ? 'border-bottom: 1px solid var(--v-gray_30-base)' : ''"
			>
				<v-row
						dense
						class="ma-0 pt-2 pb-2 pl-3"
						align="center"
						v-for="(account, index) in accountList"
						:key="account.accountId"
						:style="index === 0 ? 'background: var(--v-gray_10-base)' : 'background: var(--v-gray_5-base)'"
				>
					<v-col cols="10" align="left">
						<div
								class="row-format pointer align-center"
								style="align-items: center"
								@click.stop="
								expandAccountList ? handleAccountSelection(account.accountId) : (expandAccountList = true)
							"
						>
							<div>
								<v-avatar size="25" color="white">
									<img :src="account.accountLogo" alt="logo" v-if="account.accountLogo">
									<span v-else class="black--text brand-bold">{{ getAccountInitials(account.accountName) }}</span>
								</v-avatar>
							</div>
							<div :class="`pl-2 ${index === 0 ? 'black--text' : ''}`" style="margin-left: -2px">
								<span style="font-size: 14px; font-weight: 600">{{ account.accountName }}</span>
							</div>
						</div>
					</v-col>
					<v-col cols="2" align="right" class="pointer" v-if="index === 0">
						<v-icon color="black" style="margin-right:12px" size="25" v-if="$store.getters.getUserAccounts.length > 1">{{
								expandAccountList ? 'unfold_less' : 'unfold_more'
							}}</v-icon>
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script>
export default {
	name: "WorkspaceSelector",

	props: [],

	components: {},

	data: function () {
		return {
			expandAccountList: false,
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		handleAccountSelection: function(accountId) {
			if (this.$store.getters.getAccountId === accountId) {
				return;
			}

			for (let i = 0; i < this.$store.getters.getUserAccounts.length; i++) {
				let ua = this.$store.getters.getUserAccounts[i];
				if (ua.account.accountId === accountId) {
					this.$store.dispatch('setSelectedAccount', ua);
					this.expandAccountList = false;
					return;
				}
			}
		},

		getAccountInitials(accountName) {
			if(!accountName){
				return '?'
			}
			let ignoreMe = ['a', 'an', 'as', 'to', 'and', 'of', 'if', 'in', 'the', '-'];
			let words = accountName.toLowerCase().split(' ');
			let ret = [];
			for (let i = 0; i < words.length; i++) {
				if (ignoreMe.indexOf(words[i]) > -1) continue;
				ret[i] = words[i].substring(0, 1);
				if (ret.length == 3) break;
			}
			return ret.join('').toUpperCase();
		},
	},

	computed: {
		accountList() {
			let accountList = [];
			accountList.push(this.$store.getters.getAccount);

			if (this.expandAccountList) {
				for (let i = 0; i < this.$store.getters.getUserAccounts.length; i++) {
					let ua = this.$store.getters.getUserAccounts[i];
					if (ua.account.accountId !== this.$store.getters.getAccountId) {
						accountList.push(ua.account);
					}
				}
			}
			return accountList;

		},
	},

}
</script>

<style scoped lang="scss">

</style>