<template>
	<div  :class="`row-format align-center ml-1 ${view === 'Two-Week' ? 'mt-1' : ''}`">
		<client-avatar disable-click x-small  style="transform: scale(.92)" :client="client" v-if="client"></client-avatar>
		<div class="ml-1 font-gray_90 row-format align-center" style="width:100%">
			<div class="brand-medium">Payment received</div>
			<div class="brand-semilight ml-1">{{$formatters.dollars(payment.amount,true,true,payment.currency)}}</div>
			<v-icon class="ml-auto mr-1" color="success" size="14">$money</v-icon>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'PaymentEvent',

		props: ['event','view'],

		components: { ClientAvatar },

		data: function () {
			return {
				invoice: this.event.original.meta.invoice,
				payment: this.event.original.meta.payment,
				client: this.$store.getters.clients.find((c) => c.id === this.event.original.meta.invoice.clientId),
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {},

		computed: {
		},
	};
</script>

<style scoped lang="scss">
</style>
