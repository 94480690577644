import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class CommunicatorMessageService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(store.getters.getBaseCommUrl);
	}


	getMessageThreads(earliest, latest, archived = false, clientId) {
		let params = {
			earliest: earliest,
			latest: latest,
			archived: archived,
			clientId: clientId
		};
		return this.httpClient
			.get('/messaging/threads', { params: params })
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getMessagesInThread(threadId, page= 0, records= 50) {
		let params = {
			threadId: threadId,
			page: page,
			records: records,
		};
		return this.httpClient
			.get(`/messaging/threads/${threadId}/messages`, { params: params })
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getThread(threadId) {
		return this.httpClient
			.get(`/messaging/threads/${threadId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteThread(threadId) {
		return this.httpClient
			.delete(`/messaging/threads/${threadId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	markThreadAsRead(threadId, read = true) {
		return this.httpClient
			.post(`/messaging/threads/${threadId}/read`,null,{
				params: {
					read: read ? 0 : 1
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setArchiveFlag(threadId, archive = true) {
		return this.httpClient
			.post(`/messaging/threads/${threadId}/archive`,null,{
				params: {
					archive: archive
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setSnooze(threadId, snoozeUntil) {
		return this.httpClient
			.post(`/messaging/threads/${threadId}/snooze`,null,{
				params: {
					snoozeUntil: snoozeUntil
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	returnToInbox(threadId){
		return this.httpClient
			.post(`/messaging/threads/${threadId}/returnToInbox`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendMessage(newMessage) {
		return this.httpClient
			.post(`/messaging/send`, newMessage)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendMessageWithAttachment(newMessage,file) {
		let formData = new FormData();

		formData.append('newMessage',JSON.stringify(newMessage));
		formData.append('file',file);

		return this.httpClient
			.post(`/messaging/sendWithAttachment`,formData,{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getMediaUrl(s3File){
		return this.httpClient
			.post(`/messaging/mediaUrl`, s3File)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
