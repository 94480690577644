//import { DateTime, Interval, Duration } from 'luxon';
import { Duration } from 'luxon';
import DateTime from "@/modules/utils/HDateTime";

export default class TimeTrack {
	actions;
	phase;
	columns;
	model;

	constructor() {
		//if (phase) this.phase = phase.toLowerCase();
	}

	parseDateFromISO(iso) {
		return DateTime.fromISO(iso)
			.toISO()
			.substr(0, 10);
	}

	parseTimeFromISO(iso) {
		return DateTime.fromISO(iso).toLocaleString(DateTime.TIME_SIMPLE);
	}

	fixupEvent(item) {
		item.dateDisplay = DateTime.humanDate(item.timerStart);
		let timerStart = DateTime.fromISO(item.timerStart);
		let timerEnd = timerStart.plus({seconds: item.duration});
		item.dateTime = timerStart;
		item.durationHoursDecimal = timerEnd.diff(timerStart, 'hours').hours; // Store as Duration in hours decimal for chart calculations
		item.duration = timerEnd.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']);
		item.seconds = timerEnd.diff(timerStart, 'seconds').seconds;
		item.durationDisplay = item.duration.toFormat('h:mm'); // Table display
		item.durationSortable = item.duration.toFormat('hh:mm'); // Table sortability
		item.timeWorked = item.duration.toFormat('h:mm'); // Table display
		item.timeWorkedSortable = item.duration.toFormat('hh:mm'); // Table sortability
		item.value = '--'; // Value of time worked in dollars default value. Update later
		item.clientName = item.clientName ?? '';
		item.projectName = item.projectName ?? '';
		item.deliverableName = item.deliverableName ?? '';
		item.notes = item.notes ?? '';
		item.invoiced = !!item.invoiceId;
		if (item.clientId) item.client = { id: item.clientId, name: item.clientName ?? '- -', color: 'var(--v-gray_30-base)' }; // Update later
		return item;
	}

	timerInputToISO(timerInput, currentTimer) {
		let fromUser = DateTime.parseHumanTime(timerInput); // parse out hour, minute, seconds
		console.log('fromUser', fromUser);
		if (!fromUser) return; // Do nothing if we can't figure out what was entered by user. Mb add error state here. -ssf

		const current = DateTime.fromISO(currentTimer.timerStart);
		fromUser.year = current.year;
		fromUser.month = current.month;
		fromUser.day = current.day;
		console.log('fromUser', fromUser);

		let newStart = DateTime.fromObject(fromUser);
		console.log('newStart', newStart.c);
		const now = DateTime.local();
		console.log('now', now.c);
		if (newStart > now) {
			// if new value is from the future, subtract 24 hours
			newStart = newStart.minus({ hours: 24 });
		} else if (newStart < now.minus({ hours: 24 })) {
			newStart = newStart.plus({ hours: 24 });
		}
		return newStart.toISO();
	}
	scrubRunningTimer(input) {
		// to do: improve this so only 2 digits at-a-time and no more than 2 colons
		// input = input.replace(/[^0-9: minshour]/i, '').replace(/^[^0-9]/i, '');
		input = input.replace(/[^0-9:. minshour]/i, '');
		return input;
	}
	scrubTimerStart(input) {
		input = input.replace(/[^0-9: amp]/i, '').replace(/^[^0-9]/i, '');
		return input;
	}
	// Return minutes if appended by 'm', etc or if it's an integer >= 15
	isMinutes(input) {
		let mins = input.match(/^([0-9]{1,3}) *(m|mi|min|mins)/i);
		if (mins) return parseInt(mins[1]);

		mins = input.match(/^[0-9]{1,3}$/);
		if (mins) {
			mins = parseInt(mins[0]);
			if (mins >= 15) return mins;
		}
		return 0;
	}
	parseRunningTimerInput(input) {
		// parse human input and return a luxon duration
		input = input.trim();
		let dur = {};
		// Is entry simple minutes?
		let mins = this.isMinutes(input);
		if (mins) {
			dur.minutes = mins;
			return Duration.fromObject(dur);
		}

		let hours = input.match(/^(([0-9]{1,2})(\.[0-9]{1,3}|))( *(hour|hours|h|hrs?))?$/i); // Is entry simple hours?
		if (hours) {
			dur.hours = parseInt(hours[2]);
			if (hours[3]) {
				// Convert hours decimal to minutes
				let hourDec = parseFloat('0' + hours[3]);
				dur.minutes = Math.round(hourDec * 60);
			}
			return Duration.fromObject(dur);
		}

		let tmp = input.split(':'); // parse hour, minute, second
		if (!tmp) return;
		dur.hours = parseInt(tmp[0]);
		dur.minutes = tmp[1] ? parseInt(tmp[1]) : 0;
		dur.seconds = tmp[2] ? parseInt(tmp[2]) : 0;
		return Duration.fromObject(dur);
	}

	getCurrentRunningTimer(currentTimerClone) {
		const timerStart = DateTime
			.fromISO(currentTimerClone.timerStart)
			.plus({ seconds: currentTimerClone.pausedSeconds });

		let endTime;

		if (currentTimerClone.pausedAt) {
			endTime = DateTime.fromISO(currentTimerClone.pausedAt);
		} else {
			endTime = DateTime.local();
		}

		const diff = endTime.diff(timerStart, ['hours', 'minutes', 'seconds', 'milliseconds']);
		return diff.toFormat('h:mm:ss');
	}

	initTimerEvent() {
		return {
			clientId: null,
			clientName: null,
			deliverableId: null,
			deliverableName: null,
			id: null,
			notes: null,
			projectId: null,
			projectName: null,
			timerStart: DateTime.local().toISO(),
			timerEnd: DateTime.local()
				.plus({ minute: 15 })
				.toISO(),
		};
	}

	validateEntry(timer, requireId = true) {
		const model = {
			// accountId: { type: Number, required: true },
			userId: { type: 'number', required: true },
			pausedSeconds: { type: 'number', required: false },
			clientId: { type: 'string', required: false },
			clientName: { type: 'string', required: false },
			deliverableId: { type: 'string', required: false },
			deliverableName: { type: 'string', required: false },
			id: { type: 'string', required: requireId },
			billable: { type: 'boolean', required: true },
			notes: { type: 'string', required: false },
			projectId: { type: 'string', required: false },
			projectName: { type: 'string', required: false },
			ticketId: { type: 'string', required: false },
			ticketName: { type: 'string', required: false },
			timerEnd: { type: 'string', required: true, validateAsDate: true },
			timerStart: { type: 'string', required: true, validateAsDate: true },

		};

		let timerKeys = Object.keys(timer);
		let modelKeys = Object.keys(model);

		// Remove any properties of timer that are not in model
		for (let i = 0; i < timerKeys.length; i++) {
			if (modelKeys.includes(timerKeys[i])){ continue;}
			delete timer[timerKeys[i]];
		}

		for (const [key, val] of Object.entries(model)) {
			if ((!timer[key] && timer[key] !== false) && val.required) {
				console.log('Required field missing: ', key);
				return false;
			}

			if (!timer[key] && timer[key] !== false) {
				delete timer[key];
				continue;
			}

			if (typeof timer[key] !== val.type) {
				console.log('Invalid type ' + key + ' ' + timer[key]);
				console.log(typeof timer[key]);
				return false;
			}

			if (val.validateAsDate) {
				const ok = DateTime.fromISO(timer[key]);
				if (!ok) return false;
			}
		}

		return timer;
	}
}
