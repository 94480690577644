var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: { "bread-crumbs": [{ to: "/home", label: "Home" }] }
        },
        [_vm._v(" Agreements ")]
      ),
      _c(
        "div",
        { staticClass: "column-format gap-4" },
        [
          _c(
            "div",
            { staticClass: "row-format align-center" },
            [
              _vm.filterReady
                ? _c("proposal-filter", {
                    key: _vm.refreshKey,
                    attrs: { slot: "filter" },
                    on: {
                      "clear-filter": function($event) {
                        return _vm.initFilter()
                      },
                      updated: function($event) {
                        return _vm.saveCurrentFilter($event)
                      }
                    },
                    slot: "filter",
                    model: {
                      value: _vm.filter,
                      callback: function($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter"
                    }
                  })
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "ml-auto super-action",
                  on: {
                    click: function($event) {
                      return _vm.handleAddNew()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
                  _vm._v(" Add agreement")
                ],
                1
              )
            ],
            1
          ),
          _c("proposal-list", {
            ref: "ProposalList",
            attrs: {
              "proposal-id": _vm.id,
              filter: _vm.filter,
              "is-active": true
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }