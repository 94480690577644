<template>
	<div>

	</div>
</template>

<script>
import ContactService from "@/modules/clients/contacts/ContactService";
import CommunicatorService from "@/modules/communicator/CommunicatorService";
import libPhoneNumber from "google-libphonenumber";
import CommunicatorEmailService from "@/modules/communicator/CommunicatorEmailService";

export default {
	name: "ContactStateManager",

	props: [],

	components: {},

	data: function () {
		return {
			contactService: new ContactService(),
			communicatorService: new CommunicatorService(),
			communicatorEmailService: new CommunicatorEmailService(),
			subscription: null,
			countryCodes: [],
			PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
			PNF: libPhoneNumber.PhoneNumberFormat,
		}
	},

	mounted() {
		this.initialize();
		this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		this.$store.state.eventBus.$on('contact-update',this.updateContactHandler);
		this.$store.state.eventBus.$on('contact-delete',this.deleteContactHandler);
		this.$store.state.eventBus.$on(this.$store.getters.getMessageChannelPrefix + '.contact-update',this.updateContactHandler);
		this.$store.state.eventBus.$on(this.$store.getters.getMessageChannelPrefix + '.contact-delete',this.deleteContactHandler);
	},

	beforeDestroy() {
		this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		this.$store.state.eventBus.$off('contact-update',this.updateContactHandler);
		this.$store.state.eventBus.$off('contact-delete',this.deleteContactHandler);
		this.$store.state.eventBus.$off(this.$store.getters.getMessageChannelPrefix + '.contact-update',this.updateContactHandler);
		this.$store.state.eventBus.$off(this.$store.getters.getMessageChannelPrefix + '.contact-delete',this.deleteContactHandler);
	},

	methods: {
		handleAccountChange: function () {
			this.subscription = null;
			this.initialize();
		},

		initialize: function () {
			this.communicatorService
					.getSubscription()
					.then((res) => {
						if(res.data) {
							this.subscription = res.data;
							this.subscription.countryCode.forEach((c) => {
								this.countryCodes.push(c.replace(/[^\d]/g, ''));
							});
						}
					}).finally(() => this.getContacts());

			this.communicatorEmailService.getEmailBoxes().then((res) => {
				this.$store.state.emailBoxes.splice(0, this.$store.state.emailBoxes.length);
				this.$store.state.emailBoxes.push(... res.data);
			})
		},

		getContacts: function () {
			this.contactService.getContactList(true).then((res) => {
				this.$store.state.contacts.splice(0, this.$store.state.contacts.length);
				res.data.forEach((c) => {
					this.processContact(c,this.countryCodes);
				});
			});
		},

		updateContactHandler: function(event){
			let contact = event.message;
			this.processContact(contact,this.countryCodes);
		},

		deleteContactHandler: function(event){
			let deletedContact = event.message;
			let ix = this.$store.state.contacts.findIndex(contact => contact.id === deletedContact.id);
			if(ix > -1) {
				this.$store.state.contacts.splice(ix, 1);
			}
		},

		processContact: function(c,countryCodes){
			let finalPhone = null;

			if (c.phone) {
				try {
					let phone = c.phone.replace(/[^\d+]/g, '');
					let result;

					if (phone.startsWith('+')) {
						result = this.PhoneUtil.parse(phone);
					} else if (this.matchesAnyCountryCode(countryCodes, phone)) {
						result = this.PhoneUtil.parse('+' + phone);
					} else if (this.subscription){
						result = this.PhoneUtil.parse(this.subscription.countryCode[0] + phone);
					}else {
						result = phone;
					}

					if (this.PhoneUtil.isValidNumber(result)) {
						finalPhone = this.PhoneUtil.format(result, this.PNF.E164);
					}
				} catch (err) {
					console.log('error parsing phone');
				}
			}

			let ourContact = Object.assign({},c);
			ourContact.phone = finalPhone;
			ourContact.fullName = (c.firstName ? c.firstName : '') + ' ' + (c.lastName ? c.lastName : '');
			ourContact.fullName = ourContact.fullName.trim();
			ourContact.valid = !!finalPhone;

			let ix = this.$store.state.contacts.findIndex(contact => contact.id === ourContact.id);
			if(ix > -1){
				this.$store.state.contacts.splice(ix,1,ourContact);
			}else{
				this.$store.state.contacts.push(ourContact);
			}
		},

		matchesAnyCountryCode: function (countryCodes, phone) {
			for (let i = 0; i < countryCodes.length; i++) {
				if (phone.startsWith(countryCodes[i])) {
					return true;
				}
			}
			return false;
		},
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>