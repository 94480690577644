var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fill-width" }, [
    _c(
      "table",
      { attrs: { id: "stages-table" } },
      [
        _vm._m(0),
        _vm.stageSettings
          ? _c(
              "draggable",
              {
                attrs: { group: "options", tag: "tbody", handle: ".drag" },
                on: { change: _vm.saveSettings },
                model: {
                  value: _vm.stageSettings.stages,
                  callback: function($$v) {
                    _vm.$set(_vm.stageSettings, "stages", $$v)
                  },
                  expression: "stageSettings.stages"
                }
              },
              _vm._l(_vm.stageSettings.stages, function(stage) {
                return _c(
                  "tr",
                  { key: stage.id, staticClass: "font-14 text-left" },
                  [
                    _c(
                      "td",
                      {
                        staticStyle: { width: "50px", "text-align": "center" }
                      },
                      [
                        _c("v-icon", { staticClass: "drag" }, [
                          _vm._v("drag_handle")
                        ])
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "div",
                        {
                          staticClass: "pa-1",
                          attrs: { contenteditable: "true" },
                          on: {
                            blur: function($event) {
                              return _vm.setName(stage, $event)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(stage.label) + " ")]
                      )
                    ]),
                    _c(
                      "td",
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-click": true,
                              "close-on-content-click": true
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g({ staticClass: "pointer" }, on),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.translateType(stage.stageType)
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text-left font-14 more-menu",
                                staticStyle: {
                                  "background-color": "var(--v-white-base)"
                                }
                              },
                              _vm._l(_vm.typeList, function(type) {
                                return _c(
                                  "div",
                                  {
                                    key: type.id,
                                    staticClass:
                                      "pl-3 py-2 pointer more-menu-item",
                                    on: {
                                      click: function($event) {
                                        return _vm.setType(stage, type.value)
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(type.label) + " ")]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-click": true,
                              "close-on-content-click": true
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "div",
                                        _vm._g(
                                          {
                                            staticClass: "stage-color pointer",
                                            style:
                                              "--stage-color:" + stage.hexColor
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "div",
                              { attrs: { id: "color-picker-div" } },
                              [
                                _c("v-color-picker", {
                                  staticClass: "mx-auto",
                                  attrs: {
                                    flat: "",
                                    mode: "hexa",
                                    "show-swatches": "",
                                    "hide-canvas": "",
                                    "hide-mode-switch": "",
                                    "hide-inputs": "",
                                    "swatches-max-height": "200",
                                    swatches: _vm.clientPalette.palette
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.saveSettings()
                                    }
                                  },
                                  model: {
                                    value: stage.hexColor,
                                    callback: function($$v) {
                                      _vm.$set(stage, "hexColor", $$v)
                                    },
                                    expression: "stage.hexColor"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticStyle: { "text-align": "center", width: "50px" }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "material-symbols-rounded",
                            attrs: { size: "20" },
                            on: {
                              click: function($event) {
                                return _vm.confirmDelete(stage)
                              }
                            }
                          },
                          [_vm._v("delete")]
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "50px" } }),
        _c("th", [_vm._v("Label")]),
        _c("th", [_vm._v("Stage type")]),
        _c("th", [_vm._v("Highlight color")]),
        _c("th", { staticStyle: { width: "50px" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }