var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          staticStyle: { "overflow-y": "auto" },
          attrs: {
            "max-height": "90vh",
            "max-width": "250",
            "close-on-content-click": false,
            "close-on-click": true,
            "content-class": "filter-menu-class"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: {
                          "min-height": "40px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          elevation: "0",
                          outlined: "",
                          color: "gray_30"
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (!_vm.isDefault
                              ? "font-secondary"
                              : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filter[_vm.property]))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: !_vm.isDefault ? "secondary" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "div",
            { staticClass: "add-new-dropdown" },
            _vm._l(_vm.items, function(item) {
              return _c(
                "div",
                {
                  key: item,
                  staticClass: "add-new-item",
                  on: {
                    click: function($event) {
                      return _vm.setValue(item)
                    }
                  }
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }