import DateTime from "@/modules/utils/HDateTime";
import OpportunityService from "@/modules/pipeline/OpportunityService";
import PipelineSettingsService from "@/modules/pipeline/settings/PipelineSettingsService";
import OpportunityDetail from "@/modules/pipeline/opportunity/OpportunityDetail";

export default {
    data: function () {
        return {
            toDos: [],
            opportunityService: new OpportunityService(),
            pipelineSettingsService: new PipelineSettingsService(),
            opportunityChannelName: null,
            pipelineStages: null
        }
    },

    methods: {
        handleOpportunityStartup: function(){
            if(this.$store.getters.hasFeatureAccess('pipeline')) {
                this.opportunityChannelName = this.getOpportunityChannelName();
                this.$store.state.eventBus.$on(this.opportunityChannelName, this.opportunityEventHandler);

                this.pipelineSettingsService.getPipelineStages().then((res) => {
                    this.pipelineStages = res.data;
                    this.getToDosForOpportunities();
                })
            }
        },

        handleOpportunityShutdown: function(){
            this.$store.state.eventBus.$off(this.deliverableChannelName, this.opportunityEventHandler);
        },

        handleOpportunityClick: function (opportunityToDo) {
            let binding = {
                pipelineStages: this.pipelineStages,
                id: opportunityToDo.opportunityId,
            };
            this.$store.state.globalModalController.openModal(OpportunityDetail, binding).then((res) => {
                if (res) {
                    if(res.deleted){
                        this.removeToDosForOpportunity(res);
                    }else{
                        this.processOpportunity(res);
                    }
                }
            });
        },

        getToDosForOpportunities: function () {
            this.opportunityService.getOpportunities().then((res) => {
                let opportunities = [... res.data.filter(o => o.toDos.length)];
                for(let i=0; i < opportunities.length; i++){
                    this.processOpportunity(opportunities[i]);
                }
            })
        },

        processOpportunity: function(opportunity){
            this.removeToDosForOpportunity(opportunity);

            let toDos = opportunity.toDos.filter(t => t.dueDate);
            for(let j=0; j < toDos.length; j++){
                let toDo = toDos[j];
                let date = DateTime.fromISO(toDo.dueDate);

                if(date > this.endTwoWeek){
                    continue;
                }else if(date < this.today && toDo.complete){
                    continue;
                }

                let wrapper = {
                    id: toDo.id,
                    opportunityId: opportunity.id,
                    clientId: opportunity.clientId,
                    statusId: opportunity.statusId,
                    opportunityName: opportunity.name,
                    stage: this.pipelineStages.stages.find(s => s.id === opportunity.statusId),
                    luxonDate: date,
                    complete: toDo.complete,
                    statusIndex: this.pipelineStages.stages.findIndex(s => s.id === opportunity.statusId),
                    toDo: toDo
                }

                this.toDos.push(wrapper);
            }
        },

        removeToDosForOpportunity(opportunity){
            let fixedList = this.toDos.filter(t => t.opportunityId !== opportunity.id);
            this.toDos.splice(0,this.toDos.length);
            this.toDos.push(... fixedList);
        },

        getOpportunityChannelName() {
            return this.$store.getters.getMessageChannelPrefix + '.opp';
        },

        opportunityEventHandler: function (event) {
            if (event.userMetadata === 'Opportunity') {
                this.processOpportunityEvent(event.message);
            } else if (event.userMetadata === 'KanbanUpdate') {
                this.processKanbanUpdate(event.message);
            }
        },

        processKanbanUpdate: function(updates) {
            for (let i = 0; i < updates.length; i++) {
                let id = updates[i].id;
                let statusId = updates[i].statusId;

                this.toDos.forEach(opp => {
                    if(opp.opportunityId === id) {
                        opp.statusId = statusId;
                        opp.stage = this.pipelineStages.stages.find(s => s.id === statusId);
                        opp.statusIndex = this.pipelineStages.stages.findIndex(s => s.id === statusId);
                    }
                })
            }
        },

        processOpportunityEvent: function(opportunity) {
            if(opportunity.statusId === '__deleted__'){
                this.removeToDosForOpportunity(opportunity);
            }else {
                this.processOpportunity(opportunity);
            }
        },
    },

    computed: {
        pipelineFields: function(){
            return this.$store.state.pipelineFields;
        }
    }
}