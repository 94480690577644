<template>
	<div id="proposal-share-send-widget">
		<v-btn icon class="close-dialog" @click="cancel()">
			<v-icon>$close</v-icon>
		</v-btn>

		<div v-if="mode === 'Select'">
			<div class="modal-padding">
				<div class="modal-title">{{ $t('proposal.share.select-method') }}</div>

				<div class="selector-tile" @click="mode = 'Send'">
					<div><v-icon>$email</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('proposal.share.send-title') }}</h3>
						<div class="modal-subtitle">{{ $t('proposal.share.send-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('proposal.share.send-next') }}</div>
					</div>
				</div>
				<div class="selector-tile" @click="mode = 'Share'">
					<div><v-icon>$share</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('proposal.share.share-title') }}</h3>
						<div class="modal-subtitle">{{ $t('proposal.share.share-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('proposal.share.share-next') }}</div>
					</div>
				</div>
				<!--div class="selector-tile" @click="requestPrint()">
					<div><v-icon>print</v-icon></div>
					<div>
						<h3 class="modal-title">{{ $t('proposal.share.print-title') }}</h3>
						<div class="modal-subtitle">{{ $t('proposal.share.print-desc') }}</div>
						<div class="brand-medium font-14">{{ $t('proposal.share.print-next') }}</div>
					</div>
				</div-->
			</div>
		</div>

		<div v-if="mode === 'Share'">
			<div class="modal-padding">
				<div class="modal-title mb-5">Click on the signer to copy their shareable link</div>
				<input type="hidden" id="linkBox" />
				<v-container class="ma-0 pa-0 text-left font-14">
					<v-row
						v-for="signer in signers"
						:key="signer.id"
						class="py-2 pointer"
						style="border-top: 1px solid var(--v-gray_30-base)"
						@click="copyLink(signer)"
					>
						<v-col cols="12" class="text-pre-wrap"
							>{{ signer.firstName }} {{ signer.lastName }} &lt;{{ signer.email }}&gt;
							<span class="font-primary"
								>https://{{ $store.state.communicationPreferences.primaryDomain }}/document/{{ agreement.id }}.{{
									signer.id
								}}</span
							>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<div class="modal-footer">
				<v-btn class="primary-action" style="width: 140px" @click="cancel()">
					{{ $t('global.done') }}
				</v-btn>
			</div>
		</div>

		<div v-if="mode === 'Send'">
			<div class="modal-padding">
				<div class="modal-title mb-2">{{ $t('proposal.share.send-title') }}</div>

				<v-row class="rowFormat">
					<v-col cols="12">
						<span class="emailHeader">{{ $t('global.email-from') }}: </span>
						<span>{{ $store.getters.getFromEmail }}</span>
					</v-col>
				</v-row>
				<v-divider class="mt-1 mb-2"></v-divider>
				<v-row class="rowFormat">
					<v-col cols="12" class="pb-2">
						<span class="emailHeader">Send to signers: </span>
						<div v-for="signer in signers" :key="signer.id">
							<v-checkbox hide-details dense :value="signer.id" v-model="sendTo">
								<template #label>
									<span style="color: var(--v-black-base)"
										>{{ signer.firstName }} {{ signer.lastName }} &lt;{{ signer.email }}&gt;</span
									>
								</template>
							</v-checkbox>
						</div>
					</v-col>
				</v-row>
				<v-divider class="mt-1 mb-2"></v-divider>
				<v-row class="rowFormat">
					<v-col cols="12" class="pb-1">
						<div style="display: flex; flex-direction: row;">
							<span class="emailHeader">{{ $t('global.email-subject') }}: </span>
							<input v-model="subject" type="text" style="outline: none; width: 100%;color:var(--v-black-base)" />
						</div>
					</v-col>
				</v-row>
				<v-divider class="mt-1 mb-2"></v-divider>
				<v-row class="rowFormat">
					<v-col cols="12">
						<div>
							<editor
								v-if="initialEmailContent"
								ref="emailContent"
								:api-key="$store.getters.getTinyMceKey"
								:inline="false"
								:initial-value="initialEmailContent"
								:init="mceConfigText"
								:spellcheck="true"
							></editor>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="font-12 row-format centered gap-1">
						<v-icon size="16" color="primary">info</v-icon>
						<div>
							It is normal to see {Tokens} in this view.  The agreement and signer specific tokens will be replaced when the email is sent.
						</div>
						<v-icon size="16" color="primary">info</v-icon>
					</v-col>
				</v-row>
			</div>
			<div class="modal-footer row-format centered">
				<v-btn class="primary-action mr-1" @click="cancel()" :disabled="disabled">Cancel</v-btn>
				<send-email-button class="ml-1" :width="170" :disabled="sendTo.length === 0" @click="sendEmail($event)"></send-email-button>
			</div>
		</div>
	</div>
</template>

<script>
	import EmailTemplateSelector from "@/modules/templates/emails/EmailTemplateSelector";
	import AgreementMixin from "@/modules/agreements/schema/AgreementMixin";
	import AgreementService from "@/modules/agreements/AgreementService";
	import SendEmailButton from "@/components/SendEmailButton";
	import {DateTime} from "luxon";

	export default {
		name: 'ShareSendWidget',
		props: ['agreement'],
		mixins: [AgreementMixin],

		components: {
			SendEmailButton,
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
		},

		data: function() {
			return {
				mode: 'Select',
				disabled: false,

				shareableLink: null,
				agreementService: new AgreementService(),

				subject: this.agreement.name,
				initialEmailContent: null,
				sendTo: [],

				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					paste_as_text: false,
					forced_root_block: false,
					paste_data_images: true,
					browser_spellcheck: true,
					plugins: ['paste', 'lists', 'link', 'table', 'code'],
					contextmenu: 'paste | link | table',
					branding: false,
					height: `400px`,
					width: '750px',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						`styleselect forecolor | bold italic underline | alignleft aligncenter alignright alignjustify',
						'table | bullist numlist | link unlink code | ${this.$store.getters.isPaidAccount ? 'template' : ''}`,
					],
					table_toolbar:
							'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('template', {
							text: 'Insert template',
							onAction: () => {
								this.selectEmailTemplate();
							},
						});
					},
				},
			};
		},

		mounted() {
			this.setInitialEmailContent();
			this.agreement.signers.forEach(s => {
				if(s.signerType === 'CLIENT'){
					this.sendTo.push(s.id);
				}
			})
		},

		methods: {
			selectEmailTemplate: function(){
				let binding = {
					contacts: this.toEmail,
					clientId: this.agreement.client.id,
				};
				this.$store.state.globalModalController.openModal(EmailTemplateSelector,binding).then((res) => {
					if(res){
						let signer = null;
						if(this.signers.length === 1){
							signer = this.signers[0];
						}
						let template = this.tokenizeEmailTemplate(res,signer)
						this.subject = template.subject;
						this.$refs.emailContent.editor.setContent('');
						this.$refs.emailContent.editor.execCommand('mceInsertContent', false, template.htmlContent);
					}
				});
			},

			tokenizeEmailTemplate(template,signer){
				let tokens = this.getTokensFromAgreement(this.agreement);

				if(signer){
					tokens.set('Signer.FirstName', this.checkNull(signer.firstName));
					tokens.set('Signer.LastName', this.checkNull(signer.lastName));
					tokens.set('Signer.Email', this.checkNull(signer.email));
					tokens.set('Signer.Title', this.checkNull(signer.title));
					tokens.set('Agreement.OnlineUrl',this.getLinkForSigner(signer));
				}else{
					tokens.delete('Signer.FirstName');
					tokens.delete('Signer.LastName');
					tokens.delete('Signer.Email');
					tokens.delete('Signer.Title');
					tokens.delete('Agreement.OnlineURL');
				}

				tokens.forEach((value, key) => {
					template.subject = template.subject.replaceAll(`{{${key}}}`, value);
					template.htmlContent = template.htmlContent.replaceAll(`{{${key}}}`, value);
				});

				return template;
			},


			cancel: function() {
				this.$emit('close');
			},

			async sendEmail(sendLater=null) {
				if (this.sendTo.length === 0) {
					this.alert('warning', 'At least one recipient is required.');
					return;
				}

				try{
					this.$store.commit('startLoading');
					this.disabled = true;

					let template = {
						subject: this.subject,
						htmlContent: this.$refs.emailContent.editor.getContent()
					}

					for(let i=0; i < this.sendTo.length; i++) {
						let signer = this.signers.find(s => s.id === this.sendTo[i]);
						let t = JSON.parse(JSON.stringify(template));
						t = this.tokenizeEmailTemplate(t, signer);

						let email = {
							to: [signer.email],
							subject: t.subject,
							body: t.htmlContent
						};

						let eventLog = await this.agreementService.sendAgreement(this.agreement.id, email, sendLater);
						this.agreement.events.push(eventLog);
					}

					if(sendLater){
						this.$store.commit('success', `Email successfully scheduled for ${DateTime.fromISO(sendLater).toLocaleString(DateTime.DATETIME_MED)}`);
					}else{
						this.$store.commit('success','Sent successfully');
					}

					this.$emit('close');
				}catch(error){
					if(error.response.data){
						this.$store.commit('error',error.response.data.message);
					}else{
						this.$store.commit('error',JSON.stringify(error));
					}

				}finally{
					this.$store.commit('stopLoading');
				}
			},

			getLinkForSigner: function(signer){
				return `https://${this.$store.state.communicationPreferences.primaryDomain}/document/${this.agreement.id}.${signer.id}`;
			},

			copyLink: function(signer) {
				navigator.clipboard.writeText(this.getLinkForSigner(signer));
				this.$store.commit('success', `Link copied for ${signer.name}`);
			},


			setInitialEmailContent: function(){
				this.initialEmailContent = `Hi {{Signer.FirstName}},<br>I've created an agreement that I hope works well.  Please click your personalized link below.`
				this.initialEmailContent = this.initialEmailContent + `<br><br>`
				this.initialEmailContent = this.initialEmailContent + `<a href="{{Agreement.OnlineUrl}}" target="_blank">${this.agreement.name}</a>`
				this.initialEmailContent = this.initialEmailContent + `<br><br>`
				this.initialEmailContent = this.initialEmailContent + `Thanks,<br>${this.$store.getters.getLoggedInUserName}`
			},

			alert: function(type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},
		},

		computed: {
			signers: function(){
				return this.agreement.signers.filter((s) => s.signerType === 'CLIENT');
			}
		},

		watch: {
			proposalStatus: function() {
				this.setShareableLink();
			},
		},
	};
</script>

<style scoped lang="scss">
	#proposal-share-send-widget {
		background-color: var(--v-white-base);
		width: 800px; // was 800

		.selector-tile {
			cursor: pointer;
			border: 1px solid var(--v-gray_50-base);
			border-radius: 4px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 16px 8px 12px;
			margin: 8px 0;

			&:hover {
				background-color: var(--v-gray_20-base);
				border-color: var(--v-gray_20-base);
				svg {
					--icon-color: var(--v-primary-base);
					color: var(--v-primary-base);
				}
			}
			> div:first-child {
				padding-right: 12px;
				padding-left: 4px;
			}
			> div:last-child {
				text-align: left;
			}
		}
		.link-box {
			//width: 500px;
			border-radius: 4px;
			background-color: var(--v-gray_20-base);
			height: 32px;
			width: 100%;
			padding-left: 8px;
			//padding: 15px;
		}
	}
	.emailPill {
		background-color: var(--v-blue_10-base);
		border-radius: 8px;
		color: var(--v-blue_90-base);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 8px;
		margin: 0 8px 0 0;
	}

	.rowFormat {
		text-align: left;
		padding: 12px 0;
		//border-bottom: 1px solid var(--v-gray_30-base);
	}

	.v-menu__content {
		border-radius: 4px;
		border: 1px solid var(--v-gray_50-base);
	}

	.emailHeader {
		margin-right: 10px;
		color: var(--v-gray_70-base);
	}
</style>
