<template>
	<div v-if="mode === 'standard' || mode === 'dense'" style="width:100%">
		<v-textarea
				rows="1"
				auto-grow
				hide-details
				persistent-placeholder
				:dense="mode === 'dense'"
				outlined
				v-model="ourValue"
				:label="field.name"
				@input="handleChange"
		></v-textarea>
	</div>
	<div v-else style="width:100%">
		<resizeable-text-area >
			<textarea  rows="1" placeholder="--" v-model="ourValue" @change="$emit('change',ourValue)"></textarea>
		</resizeable-text-area>
	</div>
</template>

<script>
	import ResizeableTextArea from '@/components/customFields/ResizeableTextArea';

	export default {
		name: 'CustomText',

		props: ['field', 'value','mode'],

		components: { ResizeableTextArea },

		data: function() {
			return {
				ourValue: this.value,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleChange: function(){
				this.$emit('change',this.ourValue);
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	textarea:focus {
		outline: 1px solid var(--v-gray_30-base);
	}

	textarea {
		width: 100%;
		resize: none!important;
    color: var(--black-base);
	}
</style>
