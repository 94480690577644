<template>
	<div class="text-left font-14 column-format px-2 py-1" style="">
		<div class="row-format align-center font-14 mb-2" style="width: 100%">
			<v-btn icon @click="$emit('back')"><v-icon small>$chevronLeft</v-icon></v-btn>
			<div>New message</div>
		</div>
		<div class="mb-3">
			<contact-selector
				:subscription="subscription"
				@to-list="setToList($event)"
				:multiple="subscription.provider === 'Bandwidth'"
			></contact-selector>
		</div>

		<div class="mt-auto">
			<div @drop.prevent="addDropFile" @dragover.prevent>
				<v-textarea
					dense
					hide-details
					outlined
					rows="1"
					no-resize
					auto-grow
					autofocus
					v-model="newMessage"
					@keydown.enter.exact.prevent
					@keyup.enter.exact="sendMessage"
				>
					<template v-slot:append>
						<div
							class="font-secondary pointer py-1 pl-2"
							style="border-left: 1px solid var(--v-gray_50-base); margin-top: 2px"
							@click="sendMessage"
						>
							Send
						</div>
					</template>
				</v-textarea>
				<div class="row-format align-center mt-2" v-if="attachment">
					<v-chip class="truncate" small>{{ attachment.name }}</v-chip>
					<v-icon @click="attachment = null" class="pointer" color="black" small>close</v-icon>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContactSelector from '@/modules/communicator/realtime/ContactSelector';
	import CommunicatorMessageService from '@/modules/communicator/CommunicatorMessageService';

	export default {
		name: 'StartNewMessage',

		props: ['subscription'],

		components: { ContactSelector },

		data: function() {
			return {
				communicatorService: new CommunicatorMessageService(),
				newMessage: null,
				toList: null,
				attachment: null,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			setToList: function(toList) {
				if (toList instanceof Array) {
					this.toList = toList;
				} else {
					this.toList = [toList];
				}
			},

			addDropFile: function(e) {
				let attachments = [];
				attachments.push(...Array.from(e.dataTransfer.files));
				if(attachments.length){
					this.attachment = attachments[0];
				}
			},

			sendMessage: function() {
				if (this.sendEnabled) {
					this.$store.commit('startLoading');

					let newMessage = {
						from: this.subscription.numbers[0].number,
						to: this.validTo.map((t) => t.phone),
						message: this.newMessage,
					};
					
					if(this.attachment) {
						this.communicatorService.sendMessageWithAttachment(newMessage,this.attachment)
								.then((res) => this.$emit('new-message', res.data))
								.catch((err) => this.$store.commit('error',err.response.data.message))
								.finally(() => {
									this.$store.commit('stopLoading');
									this.attachment = null;
									this.newMessage = null;
								});
					}else{
						this.communicatorService
								.sendMessage(newMessage)
								.then((res) => {
									this.$emit('new-message', res.data);
								})
								.finally(() => this.$store.commit('stopLoading'));
					}
				}
			},
		},

		watch: {},

		computed: {
			validTo: function() {
				if (this.toList) {
					return this.toList.filter((t) => t.valid);
				} else {
					return [];
				}
			},

			sendEnabled: function() {
				return this.validTo.length && this.newMessage;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
