var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nativeEvent
    ? _c(
        "div",
        {
          staticClass: "row-format gap-2 font-14 pointer",
          on: { click: _vm.openCalendarEvent }
        },
        [
          _c(
            "v-icon",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { content: "Calendar event" },
                  expression: "{ content: 'Calendar event' }"
                }
              ],
              staticClass: "material-symbols-rounded",
              attrs: { color: "secondary", size: "20" }
            },
            [_vm._v("event")]
          ),
          _c("div", [_vm._v(_vm._s(_vm.nativeEvent.summary))]),
          _c("div", { staticClass: "ml-auto font-gray_80 font-12" }, [
            _vm._v(_vm._s(_vm.formattedDate))
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }