var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: { "bread-crumbs": [{ to: "/home", label: "Home" }] }
        },
        [_vm._v(" Team members ")]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center mb-4" },
        [
          _c("account-user-filter", {
            attrs: { slot: "filter" },
            on: {
              filter: function($event) {
                return _vm.setFilter($event)
              }
            },
            slot: "filter"
          }),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ staticClass: "super-action ml-auto" }, on),
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v(" Invite user")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { staticClass: "add-new-dropdown" },
                _vm._l(_vm.addNew, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "add-new-item",
                      on: {
                        click: function($event) {
                          return _vm.handleAddNew(item.value)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(item.label) + " ")]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _vm.communicatorEntitlement &&
      _vm.communicatorEntitlement.currentUsed >
        _vm.communicatorEntitlement.maxAllowed
        ? _c(
            "div",
            {
              staticClass: "row-format centered pa-3 gap-3 mb-4",
              staticStyle: {
                "background-color": "var(--v-gray_80-base)",
                "border-radius": "4px"
              }
            },
            [
              _c("v-icon", { attrs: { color: "white" } }, [_vm._v("warning")]),
              _c(
                "div",
                { staticStyle: { color: "var(--v-white-base)" } },
                [
                  _vm._v(
                    " You currently have more active communicator lines than you are subscribed to. Please disconnect " +
                      _vm._s(
                        _vm.communicatorEntitlement.currentUsed -
                          _vm.communicatorEntitlement.maxAllowed
                      ) +
                      " lines or "
                  ),
                  _c(
                    "router-link",
                    {
                      staticStyle: { color: "white!important" },
                      attrs: { to: "/subscription/add-ons" }
                    },
                    [_vm._v("increase your subscription")]
                  ),
                  _vm._v(" add ons to re-enable this service. ")
                ],
                1
              ),
              _c("v-icon", { attrs: { color: "white" } }, [_vm._v("warning")])
            ],
            1
          )
        : _vm._e(),
      _vm.aiEntitlement &&
      _vm.aiEntitlement.currentUsed > _vm.aiEntitlement.maxAllowed
        ? _c(
            "div",
            {
              staticClass: "row-format centered pa-3 gap-3 mb-4",
              staticStyle: {
                "background-color": "var(--v-gray_80-base)",
                "border-radius": "4px"
              }
            },
            [
              _c("v-icon", { attrs: { color: "white" } }, [_vm._v("warning")]),
              _c(
                "div",
                { staticStyle: { color: "var(--v-white-base)" } },
                [
                  _vm._v(
                    " You currently have more active Moxie Assistant licenses assigned than you are subscribed to. Please disconnect " +
                      _vm._s(
                        _vm.aiEntitlement.currentUsed -
                          _vm.aiEntitlement.maxAllowed
                      ) +
                      " licenses or "
                  ),
                  _c(
                    "router-link",
                    {
                      staticStyle: { color: "white!important" },
                      attrs: { to: "/subscription/add-ons" }
                    },
                    [_vm._v("increase your subscription")]
                  ),
                  _vm._v(" add ons to re-enable this service. ")
                ],
                1
              ),
              _c("v-icon", { attrs: { color: "white" } }, [_vm._v("warning")])
            ],
            1
          )
        : _vm._e(),
      _c("v-data-table", {
        key: _vm.refreshKey,
        staticStyle: { cursor: "pointer" },
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredUsers,
          "hide-default-footer": true,
          "fixed-header": true,
          "items-per-page": -1
        },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "div",
                  { staticClass: "row-format align-center" },
                  [
                    item.active
                      ? _c("assigned-user", {
                          attrs: {
                            "assigned-to": item.userId,
                            "show-name": false
                          }
                        })
                      : _c(
                          "v-avatar",
                          { attrs: { size: "25", color: "gray_70" } },
                          [
                            _c("span", { staticStyle: { color: "white" } }, [
                              _vm._v(
                                _vm._s(item.name.substr(0, 1).toUpperCase())
                              )
                            ])
                          ]
                        ),
                    _c("div", { staticClass: "ml-2" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-2 pointer",
                        attrs: { small: "", color: "gray_70" },
                        on: {
                          click: function($event) {
                            return _vm.openLink(item.email)
                          }
                        }
                      },
                      [_vm._v("$email")]
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "item.email",
            fn: function(ref) {
              var item = ref.item
              return [_c("span", [_vm._v(_vm._s(item.email))])]
            }
          },
          {
            key: "item.communicatorSubscription",
            fn: function(ref) {
              var item = ref.item
              return [
                item.communicatorSubscription
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          " " +
                            _vm._s(item.communicatorSubscription.phone) +
                            " "
                        ),
                        _c(
                          "v-icon",
                          {
                            directives: [
                              {
                                name: "tippy",
                                rawName: "v-tippy",
                                value: { content: "Disconnect phone line" },
                                expression:
                                  "{ content: 'Disconnect phone line' }"
                              }
                            ],
                            staticClass: "material-symbols-rounded pointer",
                            attrs: { size: "20", color: "red" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.disconnectPhoneLine(item)
                              }
                            }
                          },
                          [_vm._v("cancel")]
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v("--")])
              ]
            }
          },
          {
            key: "item.aiEntitlement",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-menu",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c("div", _vm._g({}, on), [
                                item.original.aiEntitlement
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            directives: [
                                              {
                                                name: "tippy",
                                                rawName: "v-tippy",
                                                value: {
                                                  content:
                                                    "Assigned Moxie Assistant License"
                                                },
                                                expression:
                                                  "{ content: 'Assigned Moxie Assistant License' }"
                                              }
                                            ],
                                            staticClass:
                                              "material-symbols-rounded",
                                            attrs: { color: "primary" }
                                          },
                                          [_vm._v("smart_toy")]
                                        )
                                      ],
                                      1
                                    )
                                  : _c("div", [_vm._v("--")])
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("div", { staticClass: "more-menu" }, [
                      _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: {
                            click: function($event) {
                              return _vm.processLicenseRequest(item)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.original.aiEntitlement
                                  ? "Revoke Moxie Assistant license"
                                  : "Grant Moxie Assistant license"
                              ) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "item.userType",
            fn: function(ref) {
              var item = ref.item
              return [
                item.userType === "OWNER"
                  ? _c("div", [_vm._v("Account owner")])
                  : item.userType === "FULL_USER"
                  ? _c("div", [_vm._v("Full access")])
                  : item.userType === "RESTRICTED_USER"
                  ? _c(
                      "div",
                      {
                        staticClass: "truncate",
                        on: {
                          click: function($event) {
                            return _vm.manageFeatures(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " Restricted " +
                            _vm._s(
                              _vm.getFeatureList(item.original.featureAccess)
                            ) +
                            " features "
                        )
                      ]
                    )
                  : item.userType === "IMPLEMENTER"
                  ? _c("div", [_vm._v("Moxie implementer")])
                  : _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.manageProjects(item)
                          }
                        }
                      },
                      [
                        _vm._l(
                          item.expanded
                            ? item.projects
                            : item.projects.slice(0, 1),
                          function(projectId) {
                            return _c(
                              "div",
                              {
                                key: projectId,
                                staticClass: "row-format align-center mb-2",
                                attrs: {
                                  set: (_vm.p = _vm.getProject(projectId))
                                }
                              },
                              [
                                _c("client-avatar", {
                                  attrs: { small: "", client: _vm.p.client }
                                }),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-left": "-10px" } },
                                  [_vm._v(_vm._s(_vm.p.name))]
                                ),
                                !item.expanded && item.projects.length > 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-gray_70 font-14 ml-3 pointer",
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.setProjectsExpanded(
                                              item,
                                              true
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " + " +
                                            _vm._s(item.projects.length - 1) +
                                            " more "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          }
                        ),
                        item.expanded
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "font-gray_70 font-14 mt-1 pointer",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.setProjectsExpanded(item, false)
                                  }
                                }
                              },
                              [_vm._v(" Show less ")]
                            )
                          : _vm._e()
                      ],
                      2
                    )
              ]
            }
          },
          {
            key: "item.since",
            fn: function(ref) {
              var item = ref.item
              return [
                item.active
                  ? _c("div", [
                      _vm._v(
                        _vm._s(_vm.DateTime.fromISO(item.since).toFormat("DD"))
                      )
                    ])
                  : _c("div", [
                      _vm._v(
                        "Pending (invited " +
                          _vm._s(
                            _vm.DateTime.fromISO(item.since).toFormat("DD")
                          ) +
                          ")"
                      )
                    ])
              ]
            }
          },
          {
            key: "item.action",
            fn: function(ref) {
              var item = ref.item
              return [
                item.userType !== "OWNER"
                  ? _c("user-action-menu", {
                      attrs: { user: item },
                      on: {
                        "revoke-account": function($event) {
                          return _vm.revokeAccess(item)
                        },
                        "manage-projects": function($event) {
                          return _vm.manageProjects(item)
                        },
                        "revoke-invite": function($event) {
                          return _vm.revokeInvitation(item)
                        },
                        "manage-features": function($event) {
                          return _vm.manageFeatures(item)
                        }
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }