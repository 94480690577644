<template>
	<v-container
		fluid
		:fill-height="$vuetify.breakpoint.xsOnly"
		:style="
			$vuetify.breakpoint.xsOnly ? 'height: var(--vh); background-color: white; z-index:300; margin: 0; padding: 8px;' : ''
		"
	>
		<div class="ma-2" v-if="!inThreeDSFlow">
			<h1 class="justify-center mb-4" style="" v-html="title"></h1>
			<div class="brand-normal text--black mb-12" style="font-size: 16px; line-height: 24px;" v-html="subtitle"></div>
			<v-form id="payment" @submit.prevent="getToken">
				<input type="hidden" v-model="firstName" id="first_name" name="first_name" data-recurly="first_name" />
				<input type="hidden" v-model="lastName" id="last_name" name="last_name" data-recurly="last_name" />
				<v-container>
					<fieldset class="name-fieldset mb-1">
						<div class="d-flex ma-0 pt-0 pb-2 px-1">
							<div class="ma-0 pa-0 flex-grow-1">
								<input
									type="text"
									v-model="fullName"
									name="full_name"
									id="full_name"
									style="color: var(--v-black-base)"
									:placeholder="$t('payment.name-on-card')"
								/>
							</div>
							<div class="d-flex pa-0 ma-0">
								<input
									style="color: var(--v-black-base)"
									:style="$vuetify.breakpoint.smAndUp ? 'width: 106px' : 'width: 75px'"
									type="tel"
									v-model="postalCode"
									id="postal_code"
									name="postal_code"
									data-recurly="postal_code"
									:placeholder="$t('payment.card-postal')"
								/>
							</div>
						</div>
					</fieldset>

					<div class="d-flex ma-0 pa-0">
						<div class="ma-0 flex-grow-1">
							<inputError :show="errors.name">{{ $t('payment.name-on-card') }}</inputError>
						</div>
						<div class="d-flex ma-0" :style="$vuetify.breakpoint.smAndUp ? 'width: 106px' : 'width: 75px'">
							<inputError :show="errors.postal_code" align="text-left">{{ $t('payment.card-postal') }}</inputError>
						</div>
					</div>

					<div class="mt-0" style="height: 64px;">
						<div id="recurly-elements"></div>
						<input type="hidden" name="recurly-token" data-recurly="token" />

						<div class="d-flex mx-0 mt-1 pa-0">
							<div class="ma-0 flex-grow-1">
								<inputError :show="errors.number">{{ this.$t('payment.card-number') }}</inputError>
							</div>
							<div class="d-flex ma-0" :style="$vuetify.breakpoint.smAndUp ? 'width: 62px' : 'width: 60px'">
								<inputError :show="errors.expiry">{{ this.$t('payment.card-expires') }}</inputError>
							</div>
							<div class="d-flex ma-0" :style="$vuetify.breakpoint.smAndUp ? 'width: 50px' : 'width: 60px'">
								<inputError :show="errors.cvv">{{ this.$t('payment.card-cvv') }}</inputError>
							</div>
						</div>
						<div class="py-2"></div>
					</div>

					<div class="mt-3 row-format gap-1">
						<v-text-field v-if="enableCouponCode" hide-details dense flat placeholder="Coupon code?" style="max-width: 140px" v-model="couponCode"></v-text-field>
						<v-btn
							:class="enableCouponCode ? 'ml-auto' : ''"
							:disabled="submitDisabled"
							type="submit"
							:block="!enableCouponCode"
							color="secondary"
							style="min-height:40px; max-height: 40px; min-width: 300px "
							><span style="color: var(--v-white-base)">{{ button }}</span></v-btn
						>
					</div>
					<div class="font-10 font-italic font-gray_70 mt-5">All payments are final and non-refundable</div>
				</v-container>
			</v-form>
		</div>
		<div id="threeDS" v-if="inThreeDSFlow" style="width:600px; height: 400px; min-width: 600px; min-height: 400px"></div>
	</v-container>
</template>

<script>
	import SubscriptionService from '../SubscriptionService';
	import recurly from 'recurly.js';
	import inputError from './input-error';

	export default {
		name: 'Payment',
		props: ['billingInfo', 'currentPlan', 'newPlan'],
		components: { inputError },

		data() {
			return {
				subscriptionService: new SubscriptionService(),
				recurlyPublicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
				elements: null,
				fullName: '',
				postalCode: null,
				cardElement: null,
				errors: {},
				title: '',
				subtitle: '',
				button: '',
				firstPayment: null,
				submitDisabled: false,
				token: null,
				inThreeDSFlow: false,
				couponCode: null,
				enableCouponCode: false,
			};
		},

		mounted() {
			this.buildFormInput();
			this.buildTextAndButtons();

			recurly.configure(this.recurlyPublicKey);
			this.elements = recurly.Elements();
			this.cardElement = this.elements.CardElement({
				inputType: 'mobileSelect',
				style: {
					fontSize: '16px',
					placeholder: {
						fontSize: this.$vuetify.breakpoint.xsOnly ? '13px' : 'default',
						color: '#867F77',
						content: {
							number: this.$t('payment.card-number'),
							cvv: this.$t('payment.card-cvv'),
							expiry: this.$t('payment.card-expiry'),
						},
					},
				},
			});

			setTimeout(this.cardAttach, 1000);
		},

		methods: {
			cardAttach() {
				this.cardElement.attach('#recurly-elements');
			},

			alert(type, message) {
				this.$store.commit('error', message);
			},

			getToken() {
				this.submitDisabled = true;
				this.$store.commit('startLoading');
				this.errors = {}; // clear context errors
				this.$store.commit('clear');
				let form = document.querySelector('#payment');
				recurly.token(this.elements, form, this.handleToken);
			},

			handleToken(err, token) {
				if (err) {
					// handle error using err.code and err.fields
					console.log('Recurly Error', err);
					this.submitDisabled = false;
					this.$store.commit('stopLoading');
					this.handleRecurlyError(err);
				} else {
					this.token = token;
					this.processRequest(null);
				}
			},

			processRequest: function(threeDS = null) {
				this.subscriptionService
					.putBillingInfo(this.token.id, threeDS, this.couponCode)
					.then((response) => {
						this.$emit('billing-info', response);
					})
					.catch((error) => {
						if (error.response.status === 500) {
							this.alert('error', error.response.data.message);
						} else if (error.response.status === 424) {
							this.run3DSFlow(error.response.data.message);
						} else {
							this.handleRecurlyError(error);
						}
					})
					.finally(() => {
						this.submitDisabled = false;
						this.$store.commit('stopLoading');
					});
			},

			run3DSFlow: function(threeDSecureActionTokenId) {
				let risk = recurly.Risk();
				let threeDSecure = risk.ThreeDSecure({ actionTokenId: threeDSecureActionTokenId });
				this.inThreeDSFlow = true;

				threeDSecure.on('error', (err) => {
					console.log(err);
					this.handleRecurlyError(err);
					this.inThreeDSFlow = false;
				});

				threeDSecure.on('token', (token) => {
					this.inThreeDSFlow = false;
					this.processRequest(token.id);
				});

				this.$nextTick(() => threeDSecure.attach(document.querySelector('#threeDS')));
			},

			handleRecurlyError(error) {
				this.errors = {};
				if (error.fields) {
					for (let field of error.fields) {
						if (field == 'first_name' || field == 'last_name') {
							this.errors['name'] = true;
							continue;
						}
						if (field == 'month' || field == 'year') {
							this.errors['expiry'] = true;
							continue;
						}
						this.errors[field] = true; // first_name, last_name, postal_code, number, expiry
					}
					this.alert('error', this.$t('payment.error.please-correct'));
					return;
				}
				if (error.response.data.message == undefined) {
					this.alert('error', this.$t('payment.error.undefined'));
					return false;
				}
				if (this.isAvsError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.avs'));
					this.errors['postal_code'] = true;
					return;
				}
				if (this.isInsufficientError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.insufficient'));
					return;
				}
				if (this.isCvvError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.cvv'));
					this.errors['cvv'] = true;
					return;
				}
				if (this.isExpiryError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.expiry'));
					this.errors['expiry'] = true;
					return;
				}
				if (this.isCardTypeError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.card-type'));
					this.errors['number'] = true;
					return;
				}
				if (this.isGatewayTimeoutError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.gateway-timeout'));
					return;
				}
				if (this.isDeclined(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.declined'));
					return;
				}
				this.alert('error', this.$t('payment.error.undefined'));
				return;
			},

			isDeclined(message) {
				return message.match(/declined/g);
			},
			isAvsError(message) {
				return message.match(/billing address does not match/g);
			},
			isInsufficientError(message) {
				return message.match(/insufficient/g);
			},
			isExpiryError(message) {
				return message.match(/expiration date/g);
			},
			isCvvError(message) {
				return message.match(/security code/g);
			},
			isCardTypeError(message) {
				return message.match(/card type is not accepted/g);
			},
			isGatewayTimeoutError(message) {
				return message.match(/payment system did not respond in time/g);
			},

			buildFormInput() {
				if (this.billingInfo) {
					this.fullName = this.billingInfo.name;
					this.postalCode = this.billingInfo.postal;
				} else {
					this.fullName = this.$store.state.loggedInUser.firstName + ' ' + this.$store.state.loggedInUser.lastName;
				}
				return true;
			},

			getNames() {
				let names = this.fullName.split(/[\s]+/);
				return { firstName: names[0], lastName: names[names.length - 1] };
			},
			buildTextAndButtons() {
				if (!this.newPlan) {
					// It's a payment change only
					const date = this.currentPlan
						? this.$DateTime.longDate(this.currentPlan.currentPeriodEndsAt)
						: this.$DateTime.now();
					this.title = this.$t('payment.change.heading');
					this.subtitle = this.$t('payment.change.subheading', {
						'next-date': date,
					});
					this.button = this.$t('payment.change.button');
					return;
				}
				const nextDate = this.$DateTime.local().plus({ months: this.newPlan.interval });
				this.title = this.$t('payment.new.heading');
				this.enableCouponCode = true;
				this.subtitle = this.$t('payment.new.subheading', {
					'new-price': '$' + this.newPlan.price,
					'next-date': '<nobr>' + this.$DateTime.longDate(nextDate) + '</nobr>',
					'monthly-yearly': this.newPlan.interval === 1 ? 'monthly' : 'yearly',
				});
				this.button = this.$t('payment.new.button');
				return;
			},
		},
		computed: {
			firstName: {
				get: function() {
					if (!this.fullName) return '';
					let names = this.getNames();
					return names.firstName;
				},
			},
			lastName: {
				get: function() {
					if (!this.fullName) return '';
					let names = this.getNames();
					return names.lastName;
				},
			},
		},
	};
</script>

<style lang="scss">
	.recurly-element-card,
	.name-fieldset {
		font-family: Inter;
		border: none;
		border-bottom: 1px solid var(--v-gray_70-base);
		border-radius: 0;
		color: #867f77;
	}
	.name-fieldset:hover,
	.recurly-element-card:hover {
		border-bottom: 1px solid var(--v-black-base);
		border-color: black;
	}
	//	.recurly-element-card input,
	.name-fieldset input {
		font-size: 16px;
		border: none;
		display: inline-block;
		height: 100%;
		margin: 0;
		outline: none;
		box-sizing: border-box;
	}

	.name-fieldset input:placeholder {
		color: var(--v-gray_70-base);
		//color: '#929296';
	}

	.name-fieldset input#full_name {
		width: 100%;
	}
	.name-fieldset input#postal_code {
		width: 100%;
		float: right;
	}

	div.col {
		padding-bottom: 0;
		padding-top: 0;
	}
</style>
