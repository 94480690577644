<template>
	<div>
		<customization v-show="currentView === 'customization'" ref="customization"></customization>
		<custom-domains v-show="currentView === 'domains'" ref="domains"></custom-domains>
		<permissions v-if="currentView === 'permissions'" ref="permissions" :filter="filter"></permissions>
		<features v-if="currentView === 'features'" ref="features"></features>
		<custom-email v-show="currentView === 'email'" ref="email"></custom-email>
	</div>
</template>

<script>
	import CustomDomains from '@/modules/portal/CustomDomains';
	import Permissions from '@/modules/portal/Permissions';
	import CustomEmail from '@/modules/communicator/CustomEmail';
	import Customization from "@/modules/portal/customization/Customization";
	import Features from "@/modules/portal/customization/Features";

	export default {
		name: 'PortalConfig',

		props: ['v'],

		components: {Customization, CustomEmail, Permissions, CustomDomains, Features },

		data: function () {
			return {
				currentView: 'Customization',
				pages: [
					{ label: this.$t('portal.branding'), value: 'Customization' },
					{ label: this.$t('portal.permissions'), value: 'Permissions' },
					{ label: this.$t('portal.custom-domains'), value: 'Domains' },
					{ label: this.$t('portal.custom-email'), value: 'Email' },
					{ label: this.$t('portal.request-settings'), value: 'Requests' },
				],
			};
		},

		mounted() {
			if (this.v) {
				this.currentView = this.v;
			}
			this.$track.record('page-view', { module: 'portal-' + this.currentView.toLowerCase() });
		},

		beforeDestroy() {},

		beforeRouteLeave (to, from, next) {
			if(this.currentView === 'Customization'){
				this.$refs.customization.beforeRouteLeave(to,from,next);
			}else{
				next();
			}
		},

		methods: {
			upgradeClicked: function () {
				this.$router.push('/subscription');
			},

			setView: function (view) {
				this.currentView = view;
				this.$track.record('page-view', { module: 'portal-' + this.currentView.toLowerCase() });
			},
		},

		watch: {
			v: function (newVal) {
				this.setView(newVal);
			},
		},

		computed: {
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},

			sampleMode() {
				return this.$store.getters.isSampleMode;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
