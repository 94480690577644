var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.agreement
    ? _c(
        "div",
        {
          key: _vm.refreshKey,
          staticClass: "agreement-builder",
          style: "--v-zoom: " + _vm.zoom / 100 + "; " + _vm.styles
        },
        [
          _c("div", { staticClass: "header-area px-2" }, [
            _c(
              "div",
              { staticClass: "header-left row-format gap-3" },
              [
                _vm.agreement.status === "Draft"
                  ? _c("v-text-field", {
                      staticStyle: { "min-width": "370px" },
                      attrs: {
                        id: "agreement-name",
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        placeholder: "Name"
                      },
                      model: {
                        value: _vm.agreement.name,
                        callback: function($$v) {
                          _vm.$set(_vm.agreement, "name", $$v)
                        },
                        expression: "agreement.name"
                      }
                    })
                  : _c(
                      "div",
                      {
                        staticClass: "truncate",
                        style:
                          "font-family: '" +
                          _vm.agreement.styles.h1.fontFamily +
                          "'; font-weight:600; font-size: 20px; max-width:300px; "
                      },
                      [_vm._v(" " + _vm._s(_vm.agreement.name) + " ")]
                    ),
                _c("pipeline-link-widget", {
                  attrs: {
                    "item-id": _vm.agreement.id,
                    "item-type": "Agreement",
                    "client-id": _vm.agreement.clientId
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "row-format align-center pl-3",
                    staticStyle: {
                      "border-left": "1px solid var(--v-gray_30-base)"
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer",
                        attrs: { size: "24" },
                        on: {
                          click: function($event) {
                            return _vm.zoomIn()
                          }
                        }
                      },
                      [_vm._v("zoom_in")]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "pointer",
                        attrs: { size: "24" },
                        on: {
                          click: function($event) {
                            return _vm.zoomOut()
                          }
                        }
                      },
                      [_vm._v("zoom_out")]
                    ),
                    _c("div", { staticClass: "font-12" }, [
                      _vm._v(_vm._s(_vm.zoom) + "%")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-right" },
              [
                _c("event-menu", { attrs: { agreement: _vm.agreement } }),
                _vm.agreement.status === "Draft"
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "primary-action ml-2",
                        attrs: { width: "96" },
                        on: {
                          click: function($event) {
                            return _vm.updateAgreement(false, true)
                          }
                        }
                      },
                      [_vm._v(" Save ")]
                    )
                  : _vm._e(),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action ml-2",
                    on: { click: _vm.shareDialog }
                  },
                  [_vm._v(" Share ")]
                ),
                _c(
                  "v-menu",
                  {
                    attrs: { "close-on-click": true, "nudge-bottom": "30" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "ml-2" },
                                [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "menu-activator",
                                        attrs: { icon: "", disabled: false }
                                      },
                                      scope.on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { size: 20 } }, [
                                        _vm._v(
                                          _vm._s(
                                            scope.value
                                              ? "$arrowUp"
                                              : "$moreHorizontal"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1588958389
                    )
                  },
                  [
                    _c("div", { staticClass: "more-menu" }, [
                      _vm.agreement.status === "Draft"
                        ? _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: { click: _vm.finalizeAgreement }
                            },
                            [_vm._v(" Finalize ")]
                          )
                        : _vm._e(),
                      _vm.agreement.status !== "Draft" &&
                      !_vm.agreement.fullyExecuted
                        ? _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: { click: _vm.returnToDraft }
                            },
                            [_vm._v(" Return to draft ")]
                          )
                        : _vm._e(),
                      _vm.agreement.status !== "Void" &&
                      !_vm.agreement.fullyExecuted
                        ? _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: { click: _vm.voidAgreement }
                            },
                            [_vm._v(" Void ")]
                          )
                        : _vm._e(),
                      !_vm.agreement.isFullyExecuted
                        ? _c(
                            "div",
                            {
                              staticClass: "more-menu-item",
                              on: { click: _vm.deleteAgreement }
                            },
                            [_vm._v(" Delete ")]
                          )
                        : _vm._e()
                    ])
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.updateAgreement(true)
                      }
                    }
                  },
                  [_c("v-icon", { attrs: { size: 20 } }, [_vm._v("$close")])],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "row-format",
              staticStyle: { height: "calc(100% - 50px)" }
            },
            [
              _vm.agreement.status === "Draft"
                ? _c(
                    "div",
                    {
                      staticClass: "row-format",
                      staticStyle: {
                        width: "400px",
                        "max-width": "400px",
                        "min-width": "400px",
                        "border-right": "1px solid var(--v-gray_30-base)",
                        height: "100%",
                        "max-height": "100%",
                        "background-color": "var(--v-white-base)"
                      }
                    },
                    [
                      _c("tab-switcher", {
                        attrs: { "current-tab": _vm.tab },
                        on: {
                          change: function($event) {
                            _vm.tab = $event
                          }
                        }
                      }),
                      _vm.tab === "Elements" && _vm.editItem
                        ? _c(
                            "div",
                            {
                              staticClass: "py-3",
                              staticStyle: { flex: "1 1 0" }
                            },
                            [
                              _c("config-renderer", {
                                key: _vm.editItem.id,
                                attrs: {
                                  page: _vm.editPage,
                                  item: _vm.editItem,
                                  agreement: _vm.agreement,
                                  "agreement-service": _vm.agreementService
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.itemUpdated(_vm.editPage, $event)
                                  },
                                  delete: function($event) {
                                    return _vm.confirmDelete()
                                  },
                                  duplicate: function($event) {
                                    return _vm.duplicate()
                                  },
                                  done: function($event) {
                                    return _vm.doneEditingItem()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "row-format show-scrollbar pa-2",
                              staticStyle: {
                                height: "100%",
                                "max-height": "100%",
                                width: "100%"
                              }
                            },
                            [
                              _vm.tab === "Elements"
                                ? _c(
                                    "div",
                                    { staticStyle: { flex: "1 1 0" } },
                                    [
                                      _c("element-types", {
                                        attrs: { "disable-drag": false },
                                        on: {
                                          dragstart: function($event) {
                                            return _vm.dragStart($event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tab === "Signers",
                                      expression: "tab === 'Signers'"
                                    }
                                  ],
                                  staticStyle: { flex: "1 1 0" }
                                },
                                [
                                  _c("signers", {
                                    attrs: { agreement: _vm.agreement },
                                    on: {
                                      change: function($event) {
                                        return _vm.updateSigners($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tab === "Styles",
                                      expression: "tab === 'Styles'"
                                    }
                                  ],
                                  staticStyle: { flex: "1 1 0" }
                                },
                                [
                                  _c("styles-config", {
                                    attrs: { agreement: _vm.agreement }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tab === "Settings",
                                      expression: "tab === 'Settings'"
                                    }
                                  ],
                                  staticStyle: { flex: "1 1 0" }
                                },
                                [
                                  _c("settings-config", {
                                    attrs: { agreement: _vm.agreement }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tab === "Pages",
                                      expression: "tab === 'Pages'"
                                    }
                                  ],
                                  staticStyle: { flex: "1 1 0" }
                                },
                                [
                                  _c("pages-config", {
                                    attrs: { agreement: _vm.agreement },
                                    on: { "add-page": _vm.addPage }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.tab === "Reminders",
                                      expression: "tab === 'Reminders'"
                                    }
                                  ],
                                  staticStyle: { flex: "1 1 0" }
                                },
                                [
                                  _c("reminders", {
                                    attrs: { agreement: _vm.agreement }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                    ],
                    1
                  )
                : _vm.indexedPages.length && _vm.$vuetify.breakpoint.mdAndUp
                ? _c(
                    "div",
                    {
                      staticClass: "column-format pb-3 px-2",
                      staticStyle: {
                        "border-right": "1px solid var(--v-gray_30-base)",
                        height: "100%",
                        "max-height": "100%",
                        "background-color": "var(--v-white-base)",
                        "min-width": "250px",
                        "max-width": "250px"
                      }
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "text-left font-14 py-2" },
                        _vm._l(_vm.indexedPages, function(page) {
                          return _c(
                            "li",
                            {
                              key: page.index,
                              staticClass: "pointer py-1 font-16 nav-link",
                              style:
                                "border-bottom: 1px solid var(--v-gray_30-base); font-family: '" +
                                _vm.agreement.styles.h1.fontFamily +
                                "';",
                              on: {
                                click: function($event) {
                                  return _vm.scrollToPage(page.index)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(page.title) + " ")]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "show-scrollbar",
                  staticStyle: {
                    "max-height": "calc(100vh - 50px)",
                    width: "100%",
                    "overflow-y": "scroll"
                  },
                  attrs: { id: "page-area" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "zoom",
                      staticStyle: {
                        "min-width": "fit-content",
                        width: "100%",
                        "overflow-x": "scroll"
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "print-area" } },
                        _vm._l(_vm.agreement.pages, function(page, index) {
                          return _c(
                            "div",
                            {
                              key: page.id,
                              staticClass: "column-format centered",
                              attrs: { id: "page-" + index }
                            },
                            [
                              _c("page", {
                                key: index + ":" + _vm.refreshKey,
                                ref: "page-" + index,
                                refInFor: true,
                                attrs: {
                                  "page-index": index,
                                  agreement: _vm.agreement,
                                  "mce-config": _vm.mceConfig,
                                  "refresh-key": _vm.refreshKey,
                                  "client-mode": false,
                                  "edit-mode": _vm.agreement.status === "Draft",
                                  tokens: _vm.tokens,
                                  signer: _vm.signer,
                                  "page-width": _vm.pageWidth
                                },
                                on: {
                                  delete: function($event) {
                                    return _vm.confirmDelete($event)
                                  },
                                  change: function($event) {
                                    return _vm.itemUpdated(page, $event)
                                  },
                                  "container-item-update": function($event) {
                                    return _vm.containerItemUpdated(
                                      page,
                                      $event
                                    )
                                  },
                                  signed: function($event) {
                                    return _vm.itemSigned(page, $event)
                                  },
                                  "container-item-signed": function($event) {
                                    return _vm.itemSigned(page, $event)
                                  },
                                  edit: function($event) {
                                    return _vm.setActiveItem(page, $event)
                                  },
                                  "container-item-click": function($event) {
                                    return _vm.containerItemClicked(
                                      page,
                                      $event
                                    )
                                  },
                                  "save-state": function($event) {
                                    return _vm.saveState()
                                  }
                                }
                              }),
                              _c("div", { staticClass: "page-break" })
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm.agreement.status === "Draft"
                        ? _c(
                            "div",
                            { staticClass: "column-format centered" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addPage()
                                    }
                                  }
                                },
                                [_vm._v("+ Add page")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            ]
          ),
          _vm.shareSendDialog
            ? _c(
                "basic-modal",
                {
                  attrs: { dialog: _vm.shareSendDialog, "retain-focus": false },
                  on: {
                    close: function($event) {
                      _vm.shareSendDialog = false
                    }
                  }
                },
                [
                  _c("share-send-widget", {
                    attrs: { agreement: _vm.agreement },
                    on: {
                      close: function($event) {
                        _vm.shareSendDialog = false
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }