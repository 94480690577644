var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "list-view",
        staticStyle: { cursor: "pointer" },
        attrs: {
          "item-key": "id",
          "fixed-header": "",
          headers: _vm.headers,
          items: _vm.products,
          "hide-default-footer": true,
          "items-per-page": -1
        },
        on: {
          "click:row": function($event) {
            return _vm.openProduct($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.active",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.$formatters.yesNoFromBoolean(item.active)) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.prices",
            fn: function(ref) {
              var item = ref.item
              return _vm._l(item.prices, function(price) {
                return _c("div", { key: price.id }, [
                  _c(
                    "span",
                    {
                      style: !price.active
                        ? "text-decoration: line-through"
                        : ""
                    },
                    [_vm._v(_vm._s(_vm.formatPrice(price)))]
                  )
                ])
              })
            }
          },
          {
            key: "no-data",
            fn: function() {
              return [
                _vm._v(
                  " " + _vm._s(_vm.$t("stripe.subscription.no-products")) + " "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }