import {v4 as uuidv4} from 'uuid';
import HDateTime from "@/modules/utils/HDateTime";
import ProjectEdit from "@/modules/projects/detail/ProjectEdit";
import NewProject from "@/modules/projects/NewProject";

export default {
    methods: {
        addNewProject: function(opportunity) {
            let client = this.$store.getters.getClientById(opportunity.clientId);
            let binding = {
                client: client
            }
            this.$store.state.globalModalController.openModal(NewProject,binding).then((res) => {
                if(res){
                    this.addProjectToWorkflow(opportunity,res.id);
                    this.openProject(res.id);
                }
            })
        },

        addProjectToWorkflow: function(opportunity, projectId){
          this.opportunity.workflow.push({
              id: uuidv4(),
              itemId: projectId,
              itemType: 'Project',
              timestamp: HDateTime.now().toISO()
          });
          this.$emit('save');
        },

        openProject: function(projectId) {
            let binding = {
                projectId: projectId,
            };
            this.$store.state.globalModalController.openModal(ProjectEdit, binding).then(() => {
                this.$emit('refresh')
            });
        },
    }
}