<template>
	<div class="invoice-template-builder" v-if="template" :key="template.id">
		<div class="header-area pl-2 pr-4">
			<div class="header-left row-format gap-3">
				<v-text-field v-model="template.name" hide-details dense outlined placeholder="Name"></v-text-field>
				<v-switch style="margin-top:0!important" hide-details dense v-model="template.defaultTemplate" label="Default template"></v-switch>
			</div>
			<div class="header-right">
				<div v-if="showSaved" class="font-14 brand-medium" style="color: var(--v-success-base);">All changes saved <v-icon small color="success">done</v-icon></div>
				<v-btn class="super-action ml-2" width="96" @click="saveTemplate()"> Save </v-btn>
				<v-menu :close-on-click="true" nudge-bottom="30">
					<template v-slot:activator="scope">
						<div class="ml-2">
							<v-btn icon class="menu-activator" :disabled="false" v-on="scope.on">
								<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click="duplicateTemplate()">Duplicate template</div>
						<div class="more-menu-item" @click="confirmDeleteTemplate()">Delete</div>
					</div>
				</v-menu>
				<v-btn icon class="menu-activator" @click="saveTemplate(true)">
					<v-icon :size="20">close</v-icon>
				</v-btn>
			</div>
		</div>
		<div style="height: calc(100% - 50px);" class="row-format">
			<div
				class="column-format pb-3 px-2"
				style="width: 350px; max-width:350px; min-width:350px; border-right: 1px solid var(--v-gray_30-base); height: 99%; max-height: 99%;"
			>
				<div v-if="tab === 'Elements' && editItem" class="py-3">
					<config-renderer
						:key="editItem.id"
						:item="editItem"
						:template="template"
						:tokens="tokens"
						@change="elementUpdated($event)"
						@delete="confirmDelete()"
						@duplicate="duplicate()"
						@done="doneEditingItem()"
					></config-renderer>
				</div>
				<div v-else style="height: 100%; max-height: 100%" class="column-format gap-2 show-scrollbar">
					<div class="row-format py-2" style="border-bottom: 1px solid var(--v-gray_30-base)">
						<div
							:class="`row-format align-center page-link ${tab === 'Elements' ? 'page-link-active' : ''}`"
							@click="selectTab('Elements')"
						>
							<v-icon
								class="material-symbols-rounded"
								size="22"
								:color="tab === 'Elements' ? 'primary' : 'gray_50'"
								>dashboard_customize</v-icon
							>
							<div class="ml-1">Elements</div>
						</div>
						<!--div
							:class="`row-format align-center page-link ${tab === 'Settings' ? 'page-link-active' : ''}`"
							@click="selectTab('Settings')"
						>
							<v-icon
								class="material-symbols-rounded"
								size="22"
								:color="tab === 'Settings' ? 'primary' : 'gray_50'"
								>settings</v-icon
							>
							<div class="ml-1">Settings</div>
						</div-->
						<div
							:class="`row-format align-center page-link ${tab === 'Style' ? 'page-link-active' : ''}`"
							@click="selectTab('Style')"
						>
							<v-icon class="material-symbols-rounded" size="22" :color="tab === 'Style' ? 'primary' : 'gray_50'"
								>palette</v-icon
							>
							<div class="ml-1">Style</div>
						</div>
					</div>
					<div v-if="tab === 'Style'" class="py-3">
						<invoice-style-config :styles="styles" @change="updateStyles($event)"></invoice-style-config>
					</div>
					<div v-else class="show-scrollbar" style="max-height: calc(100% - 60px)">
						<div
							v-for="(category, index) in schemaTypes"
							:key="index"
							style="border-bottom: 1px solid var(--v-gray_30-base)"
							class="mb-2 pb-1"
						>
							<div class="text-left">{{ category.label }}</div>
							<draggable
								:list="category.types"
								:group="{ name: 'elements', pull: 'clone', put: false }"
								:clone="cloneElement"
								:sort="false"
							>
								<div
									v-for="schema in category.types"
									:key="schema.type"
									class="row-format align-center schema-item pa-1 gap-2"
								>
									<v-icon class="material-symbols-rounded" color="black" size="24">{{ schema.icon }}</v-icon>
									<div class="column-format text-left">
										<div class="font-14">{{ schema.label }}</div>
										<div class="font-12 mt-n1 font-gray_70">{{ schema.description }}</div>
									</div>
								</div>
							</draggable>
						</div>
					</div>
				</div>
			</div>
			<div
				:style="`width:100%; height:100%; max-height: 100%; background-color: ${styles.backgroundColor}`"
				class="pa-12 column-format align-center show-scrollbar main-form"
			>
				<div
					:style="
						`min-height: 400px; width:100%; min-width: 300px; max-width: ${styles.maxWidth}px; background-color: ${styles.pageColor}`
					"
				>
					<draggable
						:list="schema"
						group="elements"
						:clone="cloneElement"
						@change="changed"
						:emptyInsertThreshold="1000"
						:style="`width:100%; background-color: ${styles.pageColor}`"
					>
						<div v-for="item in schema" :key="item.id">
							<item-renderer
								:item="item"
								:styles="styles"
								:font="template.font"
								:invoice="sampleInvoice"
								:edit-mode="false"
								:account-logo="accountLogo"
								:tokens="tokens"
								:token-map="tokenMap"
								:in-builder="true"
								:invoice-service="invoiceService"
								:attachment-service="attachmentService"
								@click="handleClick(item)"
								@change="elementUpdated($event)"
								@delete="confirmDelete()"
								@add-container-element="addContainerElement($event)"
								@container-item-click="containerItemClicked($event)"
								@container-item-update="containerItemUpdated($event)"
								:active="item.id === editItemId || item.id === editContainerId"
							></item-renderer>
						</div>
					</draggable>
					<div style="min-height: 50px"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ConfigRenderer from '@/modules/templates/invoices/config/ConfigRenderer';
	import SchemaTypes from '@/modules/templates/invoices/SchemaTypes';
	import SampleInvoice from '@/modules/templates/invoices/SampleInvoice';
	import ConfirmModal from '@/components/ConfirmModal';
	import { v4 as uuid } from 'uuid';
	import InvoiceTemplateService from '@/modules/templates/invoices/InvoiceTemplateService';
	import Draggable from 'vuedraggable';
	import ItemRenderer from '@/modules/templates/invoices/schema/ItemRenderer';
	import InvoiceTemplateMixin from "@/modules/templates/invoices/InvoiceTemplateMixin";
	import InvoiceStyleConfig from "@/modules/templates/invoices/config/InvoiceStyleConfig";
	import WebFontLoader from "webfontloader";
	import {DateTime} from 'luxon';

	export default {
		name: 'InvoiceTemplateBuilder',

		props: ['id'],

		mixins: [InvoiceTemplateMixin],

		components: {
			InvoiceStyleConfig,
			ConfigRenderer,
			Draggable,
			ItemRenderer,
		},

		data: function() {
			return {
				templateService: new InvoiceTemplateService(),
				tab: 'Elements',
				schemaTypes: SchemaTypes,
				sampleInvoice: SampleInvoice,
				template: null,
				schema: [],
				fieldIndex: 0,
				editItem: null,
				styles: null,
				showSaved: false,
				tokenMap: null,
				invoiceService: {
					getInvoiceItem: function(){
						return new Promise((resolve) => {
							resolve({
								data: {
									timerEvents:[
										{id:uuid(),timerStart: DateTime.now().toISO(),timerEnd: DateTime.now().plus({minutes:30}).toISO(), deliverableName: 'Task number 1', notes: 'Sample notes entered with task'},
										{id:uuid(),timerStart: DateTime.now().toISO(),timerEnd: DateTime.now().plus({minutes:60}).toISO(), deliverableName: 'Task number 2', notes: 'Notes describing the work that was performed.'}
									]
								}
							})
						})
					}
				},
				attachmentService: {
					getUploadUrl: function(){
						return 'https://www.fakedomain.com/';
					}
				},
			};
		},

		mounted() {
			console.log(this.sampleInvoice);
			this.tokenMap = this.getTokensFromInvoice(this.sampleInvoice);
			this.getTemplate();
		},

		beforeDestroy() {},

		methods: {
			handleClose: function(){
				this.saveTemplate(true);
			},

			getTemplate: function() {
				this.templateService.getInvoiceTemplate(this.id).then((res) => {
					this.template = res.data;
					this.schema = res.data.schema;
					this.styles = res.data.invoiceStyle;
					this.loadFonts();
				});
			},

			loadFonts: function(){
				WebFontLoader.load({
					google: {
						families: [`${this.styles.font}:200,300,400,500,600,700,800`],
					}
				});
			},

			saveTemplate: function(close = false){
				this.template.schema = this.schema;
				this.template.invoiceStyle = this.styles;

				this.templateService.updateInvoiceTemplate(this.id,this.template).then((res) => {
					this.template = res.data;
					this.showSaved = true;
					setTimeout(() => this.showSaved = false,5000);
					if(close){
						this.$emit('result',this.template);
					}
				})
			},

			duplicateTemplate: function(){
				let binding = {
					headingText: 'Duplicate?',
					bodyText: 'Are you sure you want to duplicate this template?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.template.duplicate = true;
						this.$emit('result',this.template);
					}
				});
			},

			confirmDeleteTemplate: function(){
				let binding = {
					headingText: 'Delete?',
					bodyText: 'Are you sure you want to delete this template?  This cannot be undone.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.templateService.deleteInvoiceTemplate(this.id).then(() => {
							this.template.deleted = true;
							this.$emit('result',this.template);
						})
					}
				});
			},

			updateStyles: function(styles) {
				this.styles = styles;
				this.template.invoiceStyle = styles;
			},

			selectTab: function(tab) {
				this.tab = tab;
			},

			doneEditingItem: function() {
				if (this.editItem.containerTarget) {
					let container = this.schema.find((i) => i.id === this.editItem.containerTarget.containerId);
					if (container) {
						this.editItem = container;
					} else {
						this.editItem = null;
					}
				} else {
					this.editItem = null;
				}
				this.tab = 'Elements';
			},

			elementUpdated: function(element) {
				if (element.containerTarget) {
					let containerIx = this.schema.findIndex((i) => i.id === element.containerTarget.containerId);
					let column = element.containerTarget.index;
					if (containerIx > -1) {
						let container = this.schema[containerIx];
						let ix = container.columns[column].items.findIndex((i) => i.id === element.id);
						if (ix > -1) {
							container.columns[column].items.splice(ix, 1, element);
							this.schema.splice(containerIx, 1, container);
						}
					}
				} else {
					let ix = this.schema.findIndex((i) => i.id === element.id);
					if (ix > -1) {
						this.schema.splice(ix, 1, element);
					}
				}
			},

			addContainerElement: function(event) {
				let container = event.container;
				let column = event.column;
				let element = event.element;
				let newElement = this.cloneElement(element);

				let ix = this.schema.findIndex((i) => i.id === container.id);
				container.columns[column].items.push(newElement);
				this.schema.splice(ix, 1, container);
			},

			containerItemClicked: function(event) {
				let container = event.container;
				let item = event.item;
				let index = event.index;

				item.containerTarget = {
					containerId: container.id,
					index: index,
				};

				this.handleClick(item);
			},

			containerItemUpdated: function(event) {
				let container = event.container;
				let item = event.item;
				let index = event.index;

				item.containerTarget = {
					containerId: container.id,
					index: index,
				};

				this.elementUpdated(item);
			},

			duplicate: function() {
				let item = { ...this.editItem };
				item.id = uuid();
				this.schema.push(item);
			},

			confirmDelete: function() {
				let binding = {
					headingText: 'Delete?',
					bodyText: 'Are you sure you want to delete this element?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.deleteElement(this.editItem);
					}
				});
			},

			deleteElement: function(element) {
				if (element.containerTarget) {
					let containerIx = this.schema.findIndex((i) => i.id === element.containerTarget.containerId);
					let column = element.containerTarget.index;
					if (containerIx > -1) {
						let container = this.schema[containerIx];
						let ix = container.columns[column].items.findIndex((i) => i.id === element.id);
						if (ix > -1) {
							container.columns[column].items.splice(ix, 1);
							this.schema.splice(containerIx, 1, container);
						}
					}
				} else {
					let ix = this.schema.findIndex((i) => i.id === element.id);
					if (ix > -1) {
						this.schema.splice(ix, 1);
					}
					this.editItem = null;
				}
			},

			changed: function(item) {
				if(item.added.element.type === 'LineItems' || item.added.element.type === 'FileAttachments'){
					let count = this.schema.filter(e => e.type === item.added.element.type).length;
					if(count > 1) {
						this.schema.splice(item.added.newIndex, 1);
						this.$store.commit('error','Only one instance of this element is allowed')
						return;
					}
				}

				if (item.added && item.added.element.type === 'Container') {
					this.editItem = item.added.element;
				}
			},

			handleClick: function(item) {
				this.tab = 'Elements';
				this.editItem = item;
			},

			cloneElement: function(element) {
				let result = {
					id: uuid(),
					type: element.type,
					required: false,
					fieldName: 'Field' + this.fieldIndex++,
					schemaMapping: null,
				};

				if (element.type === 'TextBlock') {
					result.text = 'Enter text here';
				} else if (element.type === 'Spacer') {
					result.height = 20;
				} else if (element.type === 'Divider') {
					result.style = 'solid';
					result.color = '#000000';
					result.weight = 1;
					result.width = 100;
					result.pt = 10;
					result.pb = 10;
				}  else if (element.type === 'Container') {
					result.backgroundColor = '#FFFFFF';
					result.backgroundUrl = null;
					result.columnCount = 2;
					result.minHeight = 200;
					result.columns = [];
					result.columns.push({ pt: 20, pb: 20, pr: 20, pl: 20, items: [] });
					result.columns.push({ pt: 20, pb: 20, pr: 20, pl: 20, items: [] });
				} else if (element.type === 'Logo') {
					result.width = 300;
					result.align = 'center';
				} else if (element.type === 'ImageBlock') {
					result.pt = 0;
					result.pb = 0;
					result.br = 0;
					result.scale = 80;
					result.alignment = 'center';
					result.url = null;
				}else if(element.type === 'LineItems'){
					result.pt = 0;
					result.pb = 0;
					result.pr = 20;
					result.pl = 20;
					result.size = "Standard"
					result.buttonColor = "#828a92";
					result.buttonTextColor = "#000000"
					result.labels = {
						description: 'Description',
						quantity: 'Qty',
						rate: 'Rate',
						tax: 'Tax',
						discount: 'Discount',
						total: 'Total',
						subTotal: 'Sub total',
						processingFee: 'Processing fee',
						creditApplied: 'Credit applied',
						lateFee: 'Late fee',
						payments: 'Payments',
						balance: 'Balance due',
						status: 'Status',
						paymentTotal: 'Payment total',
						amountDue: 'Amount due'
					}
					result.enabledColumns = {
						quantity: true,
						rate: true,
						tax: true
					}
				}
				return result;
			},
		},

		watch: {
			'styles.font': function (){
				this.loadFonts();
			}
		},

		computed: {
			editContainerId: function() {
				if (this.editItem && this.editItem.containerTarget) {
					return this.editItem.containerTarget.containerId;
				} else {
					return null;
				}
			},

			editItemId: function() {
				if (this.editItem) {
					return this.editItem.id;
				} else {
					return null;
				}
			},

			accountLogo: function() {
				return this.$store.state.podAccount.accountLogo;
			},
		},
	};
</script>

<style scoped lang="scss">
	.schema-item {
		border-radius: 4px;
		&:hover {
			background-color: var(--v-gray_10-base);
			cursor: grab;
		}
	}

	.invoice-template-builder {
		background-color: var(--v-white-base);
		height: 100vh;
		.header-area {
			height: 50px;
			width: 100%;
			border-bottom: 1px solid var(--v-gray_30-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
