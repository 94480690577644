var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "question-form",
      style:
        "background-color: " + _vm.meeting.pageLayout.backgroundMainColor + ";"
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              style:
                "background-color: " +
                _vm.meeting.pageLayout.backgroundColor +
                "; font-family: '" +
                _vm.meeting.pageLayout.fontFamily +
                "'; border-right: 1px solid var(--v-gray_30-base)",
              attrs: { sm: "5", cols: "12" }
            },
            [
              _c("scheduler-info", {
                attrs: { meeting: _vm.meeting, tokenMap: _vm.tokenMap }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "my-4",
              style:
                "background-color: " +
                _vm.meeting.pageLayout.backgroundMainColor,
              attrs: { sm: "7", cols: "12" }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "row-format ml-3", attrs: { cols: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          style: "color: " + _vm.textContrast,
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleBack()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v("arrow_back")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.hideDefaultNameEmail
                    ? _c(
                        "v-col",
                        {
                          staticClass: "font-18 brand-medium mb-4",
                          style: "color: " + _vm.textContrast,
                          attrs: { cols: "8" }
                        },
                        [_vm._v(_vm._s(_vm.meeting.scheduleMeeting.infoHeader))]
                      )
                    : _vm._e(),
                  _c("v-col", { attrs: { cols: "2", align: "left" } }),
                  !_vm.hideDefaultNameEmail
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "div",
                            { staticClass: "row-format" },
                            [
                              _c("v-text-field", {
                                staticClass: "mr-1 mt-2",
                                style: "--text-contrast: " + _vm.textContrast,
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  placeholder: "First name",
                                  outlined: "",
                                  readonly: ""
                                }
                              }),
                              _c("v-text-field", {
                                staticClass: "ml-1 mt-2",
                                style: "--text-contrast: " + _vm.textContrast,
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  placeholder: "Last name",
                                  outlined: "",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            style: "--text-contrast: " + _vm.textContrast,
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              placeholder: "Email",
                              outlined: "",
                              readonly: ""
                            }
                          }),
                          _c("v-text-field", {
                            staticClass: "mt-2",
                            style: "--text-contrast: " + _vm.textContrast,
                            attrs: {
                              "hide-details": "",
                              dense: "",
                              placeholder: "Phone",
                              outlined: "",
                              readonly: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedForm
                    ? _c(
                        "v-col",
                        { staticClass: "mt-2", attrs: { cols: "12" } },
                        [
                          _vm.selectedForm.schemaVersion === 1
                            ? _c("form-renderer-v1", {
                                staticClass: "renderer",
                                attrs: { schema: _vm.selectedForm.schema }
                              })
                            : _vm._e(),
                          _vm.selectedForm.schemaVersion === 2
                            ? _c("form-renderer-v2", {
                                staticClass: "renderer",
                                attrs: { template: _vm.selectedForm }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.paymentEnabled && _vm.meeting.requiredPayment,
                      expression: "paymentEnabled && meeting.requiredPayment"
                    }
                  ],
                  staticClass: "mt-5 pa-3 text-left column-format",
                  style:
                    "border: 1px solid var(--v-gray_30-base); border-radius: 4px; gap: 12px;"
                },
                [
                  _c("div", { staticClass: "font-14 pb-3" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.tokenReplace(_vm.meeting))
                      }
                    })
                  ]),
                  _c("div", { attrs: { id: "payment-element" } })
                ]
              ),
              _c(
                "div",
                { style: "display: flex; justify-content: " + _vm.alignButton },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-5",
                      staticStyle: { "text-transform": "none !important" },
                      attrs: {
                        elevation: "0",
                        block:
                          _vm.meeting.scheduleMeeting.buttonStyle
                            .buttonStyle === "Block"
                            ? true
                            : null,
                        "x-small":
                          _vm.meeting.scheduleMeeting.buttonStyle.buttonSize ===
                          "X-Small"
                            ? true
                            : null,
                        small:
                          _vm.meeting.scheduleMeeting.buttonStyle.buttonSize ===
                          "Small"
                            ? true
                            : null,
                        medium:
                          _vm.meeting.scheduleMeeting.buttonStyle.buttonSize ===
                          "Medium"
                            ? true
                            : null,
                        large:
                          _vm.meeting.scheduleMeeting.buttonStyle.buttonSize ===
                          "Large"
                            ? true
                            : null,
                        "x-large":
                          _vm.meeting.scheduleMeeting.buttonStyle.buttonSize ===
                          "X-Large"
                            ? true
                            : null,
                        color: _vm.lightAccent
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          style:
                            "letter-spacing:0.3px; color:" +
                            _vm.meeting.pageLayout.accentColor +
                            "; font-weight:800;"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.meeting.scheduleMeeting.scheduleText) +
                              " "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }