var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format text-left font-14 font-gray_70" },
    [
      _c("div", { staticClass: "font-gray_90 font-16" }, [
        _vm._v("Visible Columns")
      ]),
      _c(
        "div",
        { staticClass: "row-format gap-3" },
        [
          _c("v-switch", {
            staticStyle: { "margin-top": "0" },
            attrs: { label: "Quantity", "hide-details": "", dense: "" },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.enabledColumns.quantity,
              callback: function($$v) {
                _vm.$set(_vm.element.enabledColumns, "quantity", $$v)
              },
              expression: "element.enabledColumns.quantity"
            }
          }),
          _c("v-switch", {
            staticStyle: { "margin-top": "0" },
            attrs: { label: "Rate", "hide-details": "", dense: "" },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.enabledColumns.rate,
              callback: function($$v) {
                _vm.$set(_vm.element.enabledColumns, "rate", $$v)
              },
              expression: "element.enabledColumns.rate"
            }
          }),
          _c("v-switch", {
            staticStyle: { "margin-top": "0" },
            attrs: { label: "Tax", "hide-details": "", dense: "" },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.enabledColumns.tax,
              callback: function($$v) {
                _vm.$set(_vm.element.enabledColumns, "tax", $$v)
              },
              expression: "element.enabledColumns.tax"
            }
          })
        ],
        1
      ),
      _c("hr"),
      _c("v-switch", {
        staticStyle: { "margin-top": "8px" },
        attrs: {
          label: "Enable detailed time entries on invoice",
          "hide-details": "",
          dense: ""
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.element.showTimeDetails,
          callback: function($$v) {
            _vm.$set(_vm.element, "showTimeDetails", $$v)
          },
          expression: "element.showTimeDetails"
        }
      }),
      _c("v-switch", {
        staticStyle: { "margin-top": "8px" },
        attrs: {
          label: "Show time in HH:MM format",
          "hide-details": "",
          dense: ""
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.element.showTimeInHourMinute,
          callback: function($$v) {
            _vm.$set(_vm.element, "showTimeInHourMinute", $$v)
          },
          expression: "element.showTimeInHourMinute"
        }
      }),
      _c("v-switch", {
        staticStyle: { "margin-top": "8px" },
        attrs: {
          label: "Round quantity to 2 decimals",
          "hide-details": "",
          dense: ""
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.element.roundQty,
          callback: function($$v) {
            _vm.$set(_vm.element, "roundQty", $$v)
          },
          expression: "element.roundQty"
        }
      }),
      _c("hr"),
      _c("div", { staticClass: "font-gray_90 font-16 mt-3" }, [
        _vm._v("Layout")
      ]),
      _c("v-select", {
        staticClass: "mt-3",
        attrs: {
          items: ["Compact", "Standard", "Roomy"],
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Size"
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.element.size,
          callback: function($$v) {
            _vm.$set(_vm.element, "size", $$v)
          },
          expression: "element.size"
        }
      }),
      _c(
        "div",
        {
          staticClass: "column-format gap-6 mt-4 pt-4 pb-3 px-2",
          staticStyle: {
            border: "1px solid var(--v-gray_30-base)",
            "border-radius": "4px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  min: "0",
                  max: "20",
                  label: "Padding left",
                  dense: "",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.pl,
                  callback: function($$v) {
                    _vm.$set(_vm.element, "pl", $$v)
                  },
                  expression: "element.pl"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  min: "0",
                  max: "20",
                  label: "Padding right",
                  dense: "",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.pr,
                  callback: function($$v) {
                    _vm.$set(_vm.element, "pr", $$v)
                  },
                  expression: "element.pr"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  min: "0",
                  max: "20",
                  label: "Padding top",
                  dense: "",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.pt,
                  callback: function($$v) {
                    _vm.$set(_vm.element, "pt", $$v)
                  },
                  expression: "element.pt"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  min: "0",
                  max: "20",
                  label: "Padding bottom",
                  dense: "",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.pb,
                  callback: function($$v) {
                    _vm.$set(_vm.element, "pb", $$v)
                  },
                  expression: "element.pb"
                }
              })
            ],
            1
          )
        ]
      ),
      _c("hr"),
      _c("div", { staticClass: "font-gray_90 font-16 mt-3" }, [
        _vm._v("Button styling")
      ]),
      _c("div", { staticClass: "row-format" }, [
        _c(
          "div",
          { staticClass: "pr-1", staticStyle: { width: "50%" } },
          [
            _c("div", { staticClass: "font-14 text-left font-gray_70 mt-1" }, [
              _vm._v("Button color")
            ]),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-click": false,
                  "close-on-content-click": false,
                  bottom: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass: "pointer",
                              style:
                                "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                                _vm.element.buttonColor
                            },
                            on
                          ),
                          [_vm._v("   ")]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.buttonColorMenu,
                  callback: function($$v) {
                    _vm.buttonColorMenu = $$v
                  },
                  expression: "buttonColorMenu"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ma-3 column-format align-center gap-3",
                    staticStyle: { "background-color": "var(--v-white-base)" }
                  },
                  [
                    _c("v-color-picker", {
                      attrs: {
                        mode: "hexa",
                        "show-swatches": "",
                        "swatches-max-height": "150px"
                      },
                      on: { input: _vm.updated },
                      model: {
                        value: _vm.element.buttonColor,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "buttonColor", $$v)
                        },
                        expression: "element.buttonColor"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            _vm.buttonColorMenu = false
                          }
                        }
                      },
                      [_vm._v("OK")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pl-1", staticStyle: { width: "50%" } },
          [
            _c("div", { staticClass: "font-14 text-left font-gray_70 mt-1" }, [
              _vm._v("Button font color")
            ]),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-click": false,
                  "close-on-content-click": false,
                  bottom: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass: "pointer",
                              style:
                                "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                                _vm.element.buttonTextColor
                            },
                            on
                          ),
                          [_vm._v("   ")]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.buttonFontColorMenu,
                  callback: function($$v) {
                    _vm.buttonFontColorMenu = $$v
                  },
                  expression: "buttonFontColorMenu"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ma-3 column-format align-center gap-3",
                    staticStyle: { "background-color": "var(--v-white-base)" }
                  },
                  [
                    _c("v-color-picker", {
                      attrs: {
                        mode: "hexa",
                        "show-swatches": "",
                        "swatches-max-height": "150px"
                      },
                      on: { input: _vm.updated },
                      model: {
                        value: _vm.element.buttonTextColor,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "buttonTextColor", $$v)
                        },
                        expression: "element.buttonTextColor"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            _vm.buttonFontColorMenu = false
                          }
                        }
                      },
                      [_vm._v("OK")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "row-format align-center mt-4" },
        [
          _c("icon-selector", {
            on: { input: _vm.updated },
            model: {
              value: _vm.element.payNowIcon,
              callback: function($$v) {
                _vm.$set(_vm.element, "payNowIcon", $$v)
              },
              expression: "element.payNowIcon"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              "persistent-placeholder": "",
              outlined: "",
              label: "Pay now button text"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.payNowText,
              callback: function($$v) {
                _vm.$set(_vm.element, "payNowText", $$v)
              },
              expression: "element.payNowText"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-2 pt-4" },
        [
          _c("v-select", {
            staticStyle: { width: "48%" },
            attrs: {
              items: ["Normal", "Outlined", "Text"],
              "hide-details": "",
              outlined: "",
              dense: "",
              label: "Button style"
            },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.buttonStyle,
              callback: function($$v) {
                _vm.$set(_vm.element, "buttonStyle", $$v)
              },
              expression: "element.buttonStyle"
            }
          }),
          _c("v-select", {
            staticStyle: { width: "48%" },
            attrs: {
              items: ["X-Small", "Small", "Normal", "Large", "X-Large"],
              "hide-details": "",
              outlined: "",
              dense: "",
              label: "Button size"
            },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.buttonSize,
              callback: function($$v) {
                _vm.$set(_vm.element, "buttonSize", $$v)
              },
              expression: "element.buttonSize"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-2 mt-3" },
        [
          _c("v-select", {
            attrs: {
              items: _vm.alignment,
              "item-text": "label",
              "item-value": "value",
              "hide-details": "",
              dense: "",
              outlined: "",
              label: "Button alignment"
            },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.buttonAlignment,
              callback: function($$v) {
                _vm.$set(_vm.element, "buttonAlignment", $$v)
              },
              expression: "element.buttonAlignment"
            }
          })
        ],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "font-gray_90 font-16 mt-3" }, [
        _vm._v("Labels")
      ]),
      _c(
        "div",
        {
          staticClass: "column-format gap-6 mt-3 pt-4 pb-3 px-2",
          staticStyle: {
            border: "1px solid var(--v-gray_30-base)",
            "border-radius": "4px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: { label: "Description", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.description,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "description", $$v)
                  },
                  expression: "element.labels.description"
                }
              }),
              _c("v-text-field", {
                attrs: { label: "Quantity", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.quantity,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "quantity", $$v)
                  },
                  expression: "element.labels.quantity"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: { label: "Rate", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.rate,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "rate", $$v)
                  },
                  expression: "element.labels.rate"
                }
              }),
              _c("v-text-field", {
                attrs: { label: "Tax", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.tax,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "tax", $$v)
                  },
                  expression: "element.labels.tax"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: { label: "Total", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.total,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "total", $$v)
                  },
                  expression: "element.labels.total"
                }
              }),
              _c("v-text-field", {
                attrs: { label: "Sub total", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.subTotal,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "subTotal", $$v)
                  },
                  expression: "element.labels.subTotal"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: { label: "Discount", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.discount,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "discount", $$v)
                  },
                  expression: "element.labels.discount"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Processing fee",
                  dense: "",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.processingFee,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "processingFee", $$v)
                  },
                  expression: "element.labels.processingFee"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Credit applied",
                  dense: "",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.creditApplied,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "creditApplied", $$v)
                  },
                  expression: "element.labels.creditApplied"
                }
              }),
              _c("v-text-field", {
                attrs: { label: "Late fee", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.lateFee,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "lateFee", $$v)
                  },
                  expression: "element.labels.lateFee"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: { label: "Payments", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.payments,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "payments", $$v)
                  },
                  expression: "element.labels.payments"
                }
              }),
              _c("v-text-field", {
                attrs: { label: "Balance due", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.balance,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "balance", $$v)
                  },
                  expression: "element.labels.balance"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Payment total",
                  dense: "",
                  "hide-details": ""
                },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.paymentTotal,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "paymentTotal", $$v)
                  },
                  expression: "element.labels.paymentTotal"
                }
              }),
              _c("v-text-field", {
                attrs: { label: "Amount due", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.amountDue,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "amountDue", $$v)
                  },
                  expression: "element.labels.amountDue"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row-format gap-1" },
            [
              _c("v-text-field", {
                attrs: { label: "Payment plan", dense: "", "hide-details": "" },
                on: {
                  input: function($event) {
                    return _vm.updated()
                  }
                },
                model: {
                  value: _vm.element.labels.paymentPlan,
                  callback: function($$v) {
                    _vm.$set(_vm.element.labels, "paymentPlan", $$v)
                  },
                  expression: "element.labels.paymentPlan"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }