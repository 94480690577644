var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-format fill-all" }, [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass: "row-format gap-3 centered",
        staticStyle: { "flex-wrap": "wrap" }
      },
      _vm._l(_vm.intentions, function(intention) {
        return _c(
          "div",
          {
            key: intention.value,
            class:
              "intention row-format align-center text-left " +
              (_vm.selectedIntention === intention.value
                ? "active-intention"
                : ""),
            on: {
              click: function($event) {
                return _vm.setSelectedIntention(intention.value)
              }
            }
          },
          [
            _c("div", {
              staticStyle: {
                "min-width": "140px",
                "min-height": "140px",
                width: "140px",
                height: "140px"
              },
              style:
                "background-position: center; background-size: contain; background-image: url('/images/onboarding/intentions/" +
                intention.image +
                "')"
            }),
            _c("div", { staticClass: "ml-3 font-18 brand-medium" }, [
              _vm._v(_vm._s(intention.heading))
            ])
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "mt-10 gap-2 row-format centered",
        attrs: { id: "intentionContinue" }
      },
      [
        _c(
          "v-btn",
          {
            attrs: { large: "", outlined: "", color: "gray_50", width: "50" },
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [
            _c("v-icon", { attrs: { color: "gray_70" } }, [
              _vm._v("arrow_back")
            ])
          ],
          1
        ),
        _c(
          "v-btn",
          {
            attrs: {
              large: "",
              color: "primary",
              width: "240",
              disabled: !_vm.selectedIntention
            },
            on: {
              click: function($event) {
                return _vm.next()
              }
            }
          },
          [_vm._v(_vm._s(_vm.cta))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-10 mt-3" }, [
      _c(
        "div",
        { staticStyle: { "font-size": "32px", "font-weight": "600" } },
        [_vm._v("What are you hoping to achieve with Moxie today?")]
      ),
      _c("div", { staticClass: "font-16 font-gray_70" }, [
        _vm._v(
          " Select one goal for now. Don’t worry, you can always come back and do more later! "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }