<template>
	<div v-if="aiSession">
		<div :style="`max-height: ${maxHeight}; overflow-y: auto`" class="show-scrollbar column-format gap-2 font-14">
			<div v-for="message in messages" :key="message.id" :class="message.role" :id="message.id">
				<div v-if="message.role === 'user'">{{ message.content }}</div>
				<div v-else v-html="message.formattedContent"></div>
			</div>
		</div>
		<v-textarea
			ref="NewMessage"
			dense
			hide-details
			outlined
			rows="1"
			no-resize
			auto-grow
			autofocus
			v-model="newMessage"
			:disabled="disabled"
			@keydown.enter.exact.prevent
			@keyup.enter.exact="sendMessageStreaming"
		>
			<template v-slot:append>
				<div
					class="font-secondary pointer py-1 pl-2"
					style="border-left: 1px solid var(--v-gray_50-base); margin-top: 2px; min-height: 100%"
					@click="sendMessageStreaming"
				>
					Send
				</div>
			</template>
		</v-textarea>
	</div>
</template>

<script>
	import AiAssistantService from '@/modules/ai/AiAssistantService';
	import marked from 'marked';
	import { v4 as uuid } from 'uuid';

	export default {
		name: 'AiChatCore',

		props: {
			existingSessionId: {
				type: String,
				required: false,
			},
			maxHeight: {
				type: String,
				default: '100%',
			},
			context: {
				type: Array,
				required: false,
			},
			initialPrompt: {
				type: String,
				required: false,
			},
			finalizationPrompt: {
				type: String,
				required: false,
			},
			useCase: {
				type: String,
				default: 'GeneralChat',
			},
			model: {
				type: String,
				default: 'gpt-4o-mini',
			},
			resultFormat: {
				type: String,
				default: 'text',
			},
			temperature: {
				type: Number,
				default: 0.5,
			},
			topP: {
				type: Number,
				default: 0.5,
			},
		},

		components: {},

		data: function() {
			return {
				aiSession: null,
				disabled: false,
				newMessage: null,
				aiAssistantService: new AiAssistantService(),
				refreshKey: 0,
			};
		},

		mounted() {
			if (this.existingSessionId) {
				this.getExistingSession();
			} else {
				this.initializeAiSession();
			}
		},

		beforeDestroy() {},

		methods: {
			getExistingSession: function() {
				this.aiAssistantService
					.getChatSession(this.existingSessionId)
					.then((res) => {
						this.aiSession = res.data;
						this.aiSession.messages.forEach((m) => {
							if (m.role === 'assistant') {
								m.formattedContent = this.formatMarkdown(m.content);
							}
						});
					})
					.catch((err) => this.$store.commit('error', 'Error processing request: ' + err.response?.data?.message));
			},

			async initializeAiSession() {
				try {
					if (!this.aiSession) {
						this.$store.commit('startLoading');

						let createRequest = {
							model: this.model,
							useCase: this.useCase,
							context: [],
							temperature: this.temperature,
							top_p: this.topP,
						};

						if (this.context) {
							this.context.forEach((c) => {
								createRequest.context.push({
									role: 'system',
									content: c,
								});
							});
						}

						let result = await this.aiAssistantService.createChatSession(createRequest);
						this.aiSession = result.data;

						this.aiSession.messages.push({
							role: 'assistant',
							formattedContent: this.initialPrompt,
						});

						this.$store.commit('stopLoading');
					}
				} catch (err) {
					this.$store.commit('stopLoading');
					this.$store.commit('error', 'Error processing request: ' + err.response?.data?.message);
					this.$emit('result');
				}
			},

			sendMessageStreaming: function() {
				try {
					let message = this.newMessage;
					this.newMessage = null;
					this.$emit('processing', true);

					this.aiSession.messages.push({
						id: uuid(),
						role: 'user',
						content: message,
					});

					this.disabled = true;

					let streamingMessage = {
						id: uuid(),
						role: 'assistant',
						content: '',
						formattedContent: '',
					};

					this.aiSession.messages.push(streamingMessage);
					this.aiSessionReady = false;

					this.aiAssistantService.continueSessionStreaming(this.aiSession.id, message, {
						onData: (response) => {
							streamingMessage.content = streamingMessage.content + response;
							streamingMessage.formattedContent = this.formatMarkdown(streamingMessage.content);
							this.replaceMessage(streamingMessage);
							this.$nextTick(() =>
								document
									.getElementById(streamingMessage.id)
									.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
							);
						},
						onComplete: () => {
							this.aiSessionReady = true;
							this.disabled = false;
							this.$emit('processing', false);
							setTimeout(() => this.$refs['NewMessage'].$refs.input.focus(), 500);
						},
					});
				} catch (err) {
					this.$store.commit('error', 'Error processing request: ' + err.response?.data?.message);
					this.aiSessionReady = true;
					this.disabled = false;
					this.$emit('processing', false);
				}
			},

			async finalize() {
				try {
					this.$store.commit('startLoading');
					let content;

					if (this.finalizationPrompt) {
						let result = await this.aiAssistantService.continueSession(this.aiSession.id, this.finalizationPrompt);
						content = result.data.content;
					} else {
						content = this.messages[this.messages.length - 1].content;
					}

					this.$store.commit('stopLoading');

					if (this.resultFormat === 'json') {
						content = this.cleanJsonResponse(content);
						if (content) {
							this.$emit('result', content);
						} else {
							this.$store.commit('stopLoading');
							let message = {
								id: uuid(),
								role: 'assistant',
								content: '',
								formattedContent:
									'Sorry, something went wrong.  Can you provide a little more info and we can try again?',
							};
							this.aiSession.messages.push(message);
							this.$nextTick(() =>
								document
									.getElementById(message.id)
									.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
							);
						}
					} else {
						this.$emit('result', content);
					}
				} catch (err) {
					this.$store.commit('stopLoading');
					this.$store.commit('error', 'Error processing request: ' + err.response?.message?.data);
				}
			},

			replaceMessage: function(streamingMessage) {
				let ix = this.aiSession.messages.findIndex((m) => m.id === streamingMessage.id);
				if (ix > -1) {
					this.aiSession.messages.splice(ix, 1, streamingMessage);
					this.refreshKey++;
				}
			},

			cleanJsonResponse(jsonString) {
				try {
					// Trim leading and trailing whitespace
					jsonString = jsonString.trim();

					// Use regular expressions to find the first and last occurrence of '{', '}', '[', and ']'
					let startIndex = Math.min(
						jsonString.indexOf('{') === -1 ? Infinity : jsonString.indexOf('{'),
						jsonString.indexOf('[') === -1 ? Infinity : jsonString.indexOf('[')
					);

					let endIndex = Math.max(
						jsonString.lastIndexOf('}') === -1 ? -Infinity : jsonString.lastIndexOf('}') + 1,
						jsonString.lastIndexOf(']') === -1 ? -Infinity : jsonString.lastIndexOf(']') + 1
					);

					// Extract the JSON part
					jsonString = jsonString.substring(startIndex, endIndex);

					JSON.parse(jsonString);
					return jsonString;
				} catch (error) {
					console.error('Invalid JSON format:', jsonString);
					return null;
				}
			},

			formatMarkdown: function(message) {
				let m = marked(message, { breaks: true });
				m = m.replaceAll('<a href', '<a target="_blank" href');
				return m;
			},
		},

		computed: {
			messages: function() {
				return this.aiSession.messages.filter((m) => m.role !== 'system');
			},
		},
	};
</script>

<style scoped lang="scss">
	.assistant {
		color: var(--v-black-base);
		border-radius: 12px 12px 12px 0;
		padding: 8px 12px;
		width: 90%;
		text-align: left;
		p {
			margin-bottom: 8px !important;
		}
	}

	.user {
		background-color: var(--v-gray_20-base);
		color: var(--v-black-base);
		border-radius: 12px 12px 0 12px;
		padding: 8px 12px;
		width: 90%;
		margin-left: auto;
		text-align: right;
		p {
			margin-bottom: 0 !important;
		}
	}
</style>
