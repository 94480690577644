<template>
	<v-container style="max-width: 985px!important;" class="mt-4">
		<v-row>
			<v-col cols="12">
				<div style="font-size: 48px" class="">Subscription add ons</div>
				<div style="font-size: 32px" class="brand-semilight">
					Enhance your workspace with additional products and services
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-tabs centered v-model="productType">
					<v-tab v-for="productType in productTypes" :key="productType.value" :tab-value="productType.value">{{ productType.label }}</v-tab>
				</v-tabs>

				<div class="row-format centered gap-4 my-8" style="flex-wrap: wrap">
					<div v-for="product in filteredProducts" :key="product.id" class="product">
						<div class="name">{{ product.name }}</div>
						<div class="description">{{ product.description }}</div>
						<div v-if="product.entitlementStatus" class="font-italic my-2">
							<span v-if="product.entitlementStatus.currentUsed <= product.entitlementStatus.maxAllowed"
								>You are currently using {{ product.entitlementStatus.currentUsed }} of
								{{ product.entitlementStatus.maxAllowed }} license(s) for this add on.
							</span>
							<span v-else style="color:red; font-weight: 600"
								>Warning! You are currently using {{ product.entitlementStatus.currentUsed }} license(s) but are
								only entitled to {{ product.entitlementStatus.maxAllowed }} license(s) for this add on. Please
								adjust your subscription settings or remove active license(s) from your workspace to ensure this
								feature remains enabled.
							</span>
							<router-link v-if="product.entitlementStatus.link" :to="product.entitlementStatus.link"
								>Manage assignments</router-link
							>
						</div>
						<div class="row-format align-center">
							<div class="price">
								{{ $formatters.dollars(product.price, true, true, product.currency) }}/{{
									product.monthly ? 'month' : 'year'
								}}
							</div>
							<v-btn class="ml-auto" elevation="0" color="gray_70" disabled v-if="product.hasActiveAddOn"
								>Active subscription</v-btn
							>
							<v-btn class="ml-auto" elevation="0" color="primary" @click="purchase(product)" v-else
								>Purchase now</v-btn
							>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table :items="addOns" :items-per-page="-1" :hide-default-footer="true" :headers="headers">
					<template v-slot:item.total="{ item }">
						{{ $formatters.dollars(item.quantity * item.product.price, true, true, 'USD') }}
					</template>
					<template v-slot:item.period="{ item }">
						{{ DateTime.fromISO(item.currentPeriodStart).toLocaleString(DateTime.DATE_SHORT) }} -
						{{ DateTime.fromISO(item.currentPeriodEnd).toLocaleString(DateTime.DATE_SHORT) }}
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="pt-8">
				<v-btn elevation="0" color="primary" large @click="manageAddOns()">Manage payment & subscriptions</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import AddOnService from '@/modules/subscription/addons/AddOnService';
	import HDateTime from '@/modules/utils/HDateTime';
	import CommunicatorService from '@/modules/communicator/CommunicatorService';
	import AiAssistantService from "@/modules/ai/AiAssistantService";

	export default {
		name: 'AddOns',

		props: [],

		components: {},

		data: function() {
			return {
				DateTime: HDateTime,
				addOnService: new AddOnService(),

				communicatorService: new CommunicatorService(),
				aiAssistantService: new AiAssistantService(),
				products: [],
				addOns: [],
				communicatorEntitlementStatus: null,
				aiEntitlementStatus: null,
				productType: 'MoxieAssistant',
				productTypes: [
					{ value: 'MoxieAssistant', label: '✨Moxie Assistant' },
					{ value: 'StandardCommunicator', label: 'Communicator Phone' },
				],
			};
		},

		mounted() {
			let p1 = this.getAccountAddOns();
			let p2 = this.getCommunicatorEntitlementStatus();
			let p3 = this.getAiEntitlementStatus();
			Promise.all([p1, p2, p3]).then(() => this.getAddOnProducts());
		},

		beforeDestroy() {},

		methods: {
			getAddOnProducts: function() {
				this.addOnService.getAddOnProducts().then((res) => {
					this.products.splice(0);
					this.products.push(...res.data);
					this.products.forEach((p) => {
						p.hasActiveAddOn = this.hasActiveAddOn(p.productType);
						if (p.productType === 'StandardCommunicator') {
							p.entitlementStatus = this.communicatorEntitlementStatus;
						}else if(p.productType === 'MoxieAssistant') {
							p.entitlementStatus = this.aiEntitlementStatus;
						}
					});
				});
			},

			getCommunicatorEntitlementStatus() {
				return new Promise((resolve, reject) => {
					this.communicatorService
						.getEntitlementStatus()
						.then((res) => {
							this.communicatorEntitlementStatus = res.data;
							this.communicatorEntitlementStatus.link = '/team';
							resolve(this.communicatorEntitlementStatus);
						})
						.catch((err) => reject(err));
				});
			},

			getAiEntitlementStatus() {
				return new Promise((resolve, reject) => {
					this.aiAssistantService
							.getEntitlementStatus()
							.then((res) => {
								this.aiEntitlementStatus = res.data;
								this.aiEntitlementStatus.link = '/team';
								resolve(this.aiEntitlementStatus);
							})
							.catch((err) => reject(err));
				});
			},

			getAccountAddOns: function() {
				return new Promise((resolve, reject) => {
					this.addOnService
						.getAccountAddOns()
						.then((res) => {
							this.addOns.splice(0);
							this.addOns.push(...res.data);
							this.addOns.sort((a, b) => b.currentPeriodStart.localeCompare(a.currentPeriodStart));
							resolve(this.addOns);
						})
						.catch((err) => reject(err));
				});
			},

			hasActiveAddOn(type) {
				let ix = this.addOns.findIndex((a) => a.product.productType === type && ['Active', 'PastDue'].includes(a.status));
				return ix > -1;
			},

			purchase(product) {
				this.$store.commit('startLoading');
				this.addOnService
					.createCheckoutSession(product.id)
					.then((res) => {
						let stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);

						stripe.redirectToCheckout({
							sessionId: res.data,
						});
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			manageAddOns: function() {
				this.$store.commit('startLoading');
				this.addOnService
					.createPortalSession()
					.then((res) => {
						document.location = res.data;
					})
					.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {
			filteredProducts: function() {
				return this.products.filter((p) => p.productType === this.productType);
			},

			headers: function() {
				return [
					{ text: 'Product', value: 'product.name' },
					{ text: 'Status', value: 'status' },
					{ text: 'Period', value: 'period' },
					{ text: 'Quantity', value: 'quantity', align: 'right' },
					{ text: 'Price', value: 'product.price', align: 'right' },
					{ text: 'Total', value: 'total', align: 'right' },
				];
			},
		},
	};
</script>

<style scoped lang="scss">
	.product {
		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		max-width: 450px;
		min-width: 450px;
		width: 450px;
		text-align: left;
		padding: 12px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		.name {
			font-size: 18px;
			font-weight: 600;
		}
		.description {
			font-size: 14px;
			font-weight: 300;
			white-space: pre-wrap;
		}
		.price {
			font-weight: 600;
		}
	}
</style>
