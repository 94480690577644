var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "questions" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "column-format gap-3 mb-2 pb-2",
              staticStyle: {
                "border-bottom": "1px solid var(--v-gray_30-base)"
              },
              attrs: { cols: "12" }
            },
            [
              _c("v-autocomplete", {
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  label: "Additional form questions",
                  items: _vm.templateList
                },
                model: {
                  value: _vm.meeting.attachedFormId,
                  callback: function($$v) {
                    _vm.$set(_vm.meeting, "attachedFormId", $$v)
                  },
                  expression: "meeting.attachedFormId"
                }
              }),
              _vm.meeting.attachedFormId
                ? _c(
                    "div",
                    { staticClass: "row-format gap-2 align-center pb-2" },
                    [
                      _c("v-switch", {
                        staticClass: "mt-0",
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          label: "Save as form submission"
                        },
                        model: {
                          value: _vm.meeting.saveStandaloneForm,
                          callback: function($$v) {
                            _vm.$set(_vm.meeting, "saveStandaloneForm", $$v)
                          },
                          expression: "meeting.saveStandaloneForm"
                        }
                      }),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": "",
                            "close-on-click": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass: "pointer",
                                          attrs: {
                                            size: "20",
                                            color: "gray_50"
                                          }
                                        },
                                        on
                                      ),
                                      [_vm._v("info")]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1461092966
                          )
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "booking-box pa-4 text-left",
                              staticStyle: {
                                "max-width": "300px",
                                "background-color": "var(--v-white-base)"
                              }
                            },
                            [
                              _vm._v(
                                " When this feature is enabled, the submitted form data will be saved with the scheduled event as well as creating a record in your Form submission results. "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          !_vm.hideDefaultNameEmail
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "row-format" },
                    [
                      _c("v-text-field", {
                        staticClass: "mr-1",
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          placeholder: "First name",
                          disabled: ""
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "ml-1",
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          placeholder: "Last name",
                          disabled: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      placeholder: "Email",
                      disabled: ""
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      placeholder: "Phone",
                      disabled: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.selectedForm
            ? _c(
                "v-col",
                { staticClass: "mt-2", attrs: { cols: "12" } },
                [
                  _vm.selectedForm.schemaVersion === 1
                    ? _c("form-renderer-v1", {
                        staticClass: "renderer",
                        attrs: { schema: _vm.selectedForm.schema }
                      })
                    : _vm._e(),
                  _vm.selectedForm.schemaVersion === 2
                    ? _c("form-renderer-v2", {
                        staticClass: "renderer",
                        attrs: { template: _vm.selectedForm }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }