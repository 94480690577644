import { render, staticRenderFns } from "./AcceptButton.vue?vue&type=template&id=4ed84463&scoped=true&"
import script from "./AcceptButton.vue?vue&type=script&lang=js&"
export * from "./AcceptButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed84463",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src834000100/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ed84463')) {
      api.createRecord('4ed84463', component.options)
    } else {
      api.reload('4ed84463', component.options)
    }
    module.hot.accept("./AcceptButton.vue?vue&type=template&id=4ed84463&scoped=true&", function () {
      api.rerender('4ed84463', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/agreements/schema/AcceptButton.vue"
export default component.exports