import axios from 'axios';
import qs from 'qs';

const getClient = () => {
	const options = {
		baseURL: process.env.VUE_APP_BASE_AUTH_URL,
		withCredentials: true,
	};

	const client = axios.create(options);

	return client;
};

export default {

	signupWithEmail(firstName, lastName, email, reCaptchaToken, baseUrl) {
		const request = {
			firstName,
			lastName,
			email,
			reCaptchaToken,
			baseUrl
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/signup/email', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	signupWithGoogle(id_token,reCaptchaToken) {
		const request = {
			id_token,
			reCaptchaToken
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/signup/google', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	signupWithMicrosoft(accessToken,reCaptchaToken,baseUrl) {
		const request = {
			accessToken,
			reCaptchaToken,
			baseUrl
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/signup/microsoft', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

	signupWithApple(code,user,reCaptchaToken) {
		const request = {
			code:code,
			reCaptchaToken:reCaptchaToken
		};

		if(user){
			request.firstName = user.name.firstName;
			request.lastName = user.name.lastName;
		}

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		return getClient()
			.post('/signup/apple', qs.stringify(request), config)
			.then((response) => response.data)
			.catch((error) => Promise.reject(error));
	},

};
