<template>
	<div id="outstanding-invoices">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h3 class="modal-title">{{ $t('invoice.outstanding') }}</h3>
			<invoice-table :invoices="invoices" @open-invoice="$emit('result',$event)"></invoice-table>
		</div>
	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import InvoiceTable from "@/modules/accounting/invoices/InvoiceTable";

	export default {
		name: 'OutstandingInvoices',

		props: [],

		components: {InvoiceTable},

		data: function () {
			return {
				invoiceService: new InvoiceService(),
				invoices: [],
			};
		},

		mounted() {
			this.getOutstandingInvoices();
		},

		beforeDestroy() {},

		methods: {
			getOutstandingInvoices: function(){
				this.invoiceService.getOutstandingInvoices().then((res) => {
					this.invoices.splice(0,this.invoices.length);
					this.invoices.push(... res.data);
				})
			},

			handleClose: function () {
				this.$emit('result');
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	#outstanding-invoices {
		min-width: 900px;
	}
</style>