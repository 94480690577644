import HttpClient from '@/services/HttpClient';

export default class AppleCalendarService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    getAllAccounts() {
        return this.httpClient
            .get('/apple')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getAccount() {
        return this.httpClient
            .get('/apple/connection')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    connect(email,password) {
        let params = {
            email:email,
            password:password
        }

        return this.httpClient
            .post(`/apple/connection`,null, {
                params: params
            })
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    update(appleAccount){
        return this.httpClient
            .put(`/apple/connection`,appleAccount)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    refresh() {
        return this.httpClient
            .post('/apple/connection/refresh')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    disconnect() {
        return this.httpClient
            .delete('/apple/connection')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}