<template>
	<div class="row-format gap-2 font-14 pointer" v-if="request" @click="openRequest">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" v-tippy="{ content: 'Ticket' }"
			>local_activity</v-icon
		>
		<div>{{ request.subject }}</div>
		<div class="ml-auto font-gray_80 font-12">{{ request.created | formatForTimeAgo }}</div>
	</div>
</template>

<script>
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';
	import TicketDetail from '@/modules/communicator/inbox/tickets/TicketDetail';

	export default {
		name: 'Request',

		props: ['item'],

		components: {},

		data: function() {
			return {
				request: null,
				requestService: new CommunicatorTicketService(),
			};
		},

		mounted() {
			this.getRequest();
		},

		beforeDestroy() {},

		methods: {
			getRequest: function() {
				this.requestService
					.getTicketDetails(this.item.itemId)
					.then((res) => {
						this.request = res.data.ticket;
						this.$emit('actual-time', this.request.created);
					})
					.catch((err) => {
						if (err.response.status === 404) {
							this.$emit('deleted');
						}
					});
			},

			openRequest: function() {
				let binding = {
					id: this.request.id,
				};
				this.$store.state.globalModalController.openModal(TicketDetail, binding).then((res) => {
					this.request = res;
				});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
