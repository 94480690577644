import { render, staticRenderFns } from "./EmailThread.vue?vue&type=template&id=49755d91&scoped=true&"
import script from "./EmailThread.vue?vue&type=script&lang=js&"
export * from "./EmailThread.vue?vue&type=script&lang=js&"
import style1 from "./EmailThread.vue?vue&type=style&index=1&id=49755d91&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49755d91",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3589467384/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49755d91')) {
      api.createRecord('49755d91', component.options)
    } else {
      api.reload('49755d91', component.options)
    }
    module.hot.accept("./EmailThread.vue?vue&type=template&id=49755d91&scoped=true&", function () {
      api.rerender('49755d91', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/communicator/inbox/email/EmailThread.vue"
export default component.exports