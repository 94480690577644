<template>
	<div class="ml-2" :style="`color:var(--v-${foregroundColor}-base); height: 100%; overflow-y: hidden; overflow-x: hidden`">
		<div v-if="timed && !inMoreView" :class="`${view === 'Two-Week' || view === 'Week' ? 'mt-1' : ''}`">
			<div class="row-format align-center">
				<v-icon size="16" :color="foregroundColor" class="mr-1" style="margin-left:-2px">$origHummingbird</v-icon><div v-html="event.name"></div>
			</div>
			<div class="text-left">{{ convertTime(orig.start) }}-{{ convertTime(orig.end) }}</div>
		</div>
		<div v-if="!timed || inMoreView" :class="`row-format align-center ${view === 'Two-Week' || view === 'Week' ? 'mt-1' : ''}`">
			<v-icon size="16" :color="foregroundColor" class="mr-1" style="margin-left:-2px">$origHummingbird</v-icon>
			<div class="text-left" v-html="event.name"></div>
		</div>
	</div>
</template>

<script>
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'NativeCalendarEvent',

		props: ['event', 'eventParsed', 'timed','view','inMoreView'],

		components: {
		},

		data: function () {
			return {
				nativeEvent: null,
				isReady: false,
				orig: this.event.original
			};
		},

		mounted() {
			this.initializeData();
		},

		beforeDestroy() {},

		methods: {
			initializeData: function () {
				this.nativeEvent = this.event.original.meta.nativeEvent;
				this.isReady = true;
			},

			convertTime: function (time) {
				return DateTime.fromISO(time).toFormat('t').replace(' ','').toLowerCase();
			},
		},

		computed: {
			foregroundColor: function(){
				if(this.event.foregroundColor){
					return this.event.foregroundColor;
				}else{
					return 'white';
				}
			}
		},
	};
</script>

<style scoped lang="scss"></style>
