<template>
	<div class="row-format gap-2 font-14 pointer" v-if="thread" @click="openThread">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" v-tippy="{content: 'Message'}">chat</v-icon>
		<div>{{participants.join(', ')}} | </div>
		<div class="truncate" style="max-width: 200px">{{thread.lastMessageText}}</div>
		<div class="ml-auto font-gray_80 font-12">{{thread.lastMessage | formatForTimeAgo}}</div>
	</div>
</template>

<script>
import CommunicatorMessageService from "@/modules/communicator/CommunicatorMessageService";
import CommunicationDetailModal from "@/modules/communicator/inbox/CommunicationDetailModal";
import libPhoneNumber from "google-libphonenumber";

export default {
	name: "MessageThread",

	props: ['item'],

	components: {},

	data: function () {
		return {
			thread: null,
			messageService: new CommunicatorMessageService(),
      PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
      PNF: libPhoneNumber.PhoneNumberFormat,
		}
	},

	mounted() {
		this.getThread();
	},

	beforeDestroy() {
	},

	methods: {
		getThread: function(){
			this.messageService
					.getThread(this.item.itemId)
					.then((res) => {
						this.thread = res.data;
						this.$emit('actual-time', this.thread.lastMessage);
					})
					.catch((err) => {
						if (err.response.status === 404) {
							this.$emit('deleted');
						}
					});
		},

		openThread: function(){
			let event = {
				id: this.thread.id,
				channel: 'Messages',
				type: 'MessageThread',
				date: this.thread.lastMessage,
				payload: this.thread,
				unread: this.thread.unread,
				archived: this.thread.archived,
				snoozedUntil: this.thread.snoozedUntil
			}
			this.$store.state.globalModalController.openModal(CommunicationDetailModal, { communication: event }).then(() => {
				this.getThread();
			})
		}
	},

	computed: {
		participants: function() {
			return this.thread.participants.map((p) => {
				let contact = this.$store.getters.getContactByPhone(p.phone);
				if (contact) {
					return contact.fullName;
				} else {
					let phone = this.PhoneUtil.parse(p.phone);
					return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
				}
			});
		},
	},

}
</script>

<style scoped lang="scss">

</style>
