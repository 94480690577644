var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "proposal-format-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.cancel()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "px-4 pt-4 pb-6" }, [
        _c("div", [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v(_vm._s(_vm.$t("proposal.format.text")))
          ]),
          _c("div", { staticClass: "font-select-wrapper mt-2" }, [
            _c(
              "div",
              [
                _c("font-selector", {
                  attrs: { label: "Header font", font: _vm.lHeaderFont },
                  on: {
                    "font-changed": function($event) {
                      return _vm.handleHeaderFontChange($event)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("v-select", {
                  attrs: {
                    "hide-details": "",
                    dense: "",
                    outlined: "",
                    label: _vm.$t("proposal.format.header-font-weight-label"),
                    items: _vm.weights
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function(data) {
                        return [
                          _c(
                            "span",
                            {
                              style:
                                "font-family: " +
                                _vm.headerFont +
                                "; font-weight: " +
                                (data.item === "Regular" ? 400 : 700)
                            },
                            [_vm._v(_vm._s(data.item))]
                          )
                        ]
                      }
                    },
                    {
                      key: "selection",
                      fn: function(data) {
                        return [
                          _c(
                            "span",
                            {
                              style:
                                "font-family: " +
                                _vm.headerFont +
                                "; font-weight: " +
                                (data.item === "Regular" ? 400 : 700)
                            },
                            [_vm._v(_vm._s(data.item))]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.lHeaderWeight,
                    callback: function($$v) {
                      _vm.lHeaderWeight = $$v
                    },
                    expression: "lHeaderWeight"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("font-selector", {
                  attrs: { label: "Body font", font: _vm.lBodyFont },
                  on: {
                    "font-changed": function($event) {
                      return _vm.handleBodyFontChange($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "subtitle mt-4" }, [
          _vm._v(_vm._s(_vm.$t("global.preview")))
        ]),
        _c("div", { staticClass: "preview-wrapper" }, [
          _c("div", { staticClass: "preview-cover mt-2" }, [
            _c("div", { style: _vm.headerStyle }, [
              _vm._v(_vm._s(_vm.$t("proposal.format.preview-title")))
            ]),
            _c("div", { staticClass: "subtitle-bar" })
          ]),
          _c("div", { staticClass: "preview-body" }, [
            _c("div", { style: _vm.headerStyle }, [
              _vm._v(_vm._s(_vm.$t("proposal.format.preview-content-heading")))
            ]),
            _c("div", { style: _vm.bodyStyle }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("proposal.format.preview-content-body")) +
                  " "
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              staticStyle: { width: "160px" },
              on: {
                click: function($event) {
                  return _vm.cancel()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.cancel")))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "primary-action ml-1",
              staticStyle: { width: "160px" },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }