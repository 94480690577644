import { render, staticRenderFns } from "./Subscriptions.vue?vue&type=template&id=3a8bb6a6&scoped=true&"
import script from "./Subscriptions.vue?vue&type=script&lang=js&"
export * from "./Subscriptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a8bb6a6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VChip,VCol,VContainer,VIcon,VRow,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2614485219/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3a8bb6a6')) {
      api.createRecord('3a8bb6a6', component.options)
    } else {
      api.reload('3a8bb6a6', component.options)
    }
    module.hot.accept("./Subscriptions.vue?vue&type=template&id=3a8bb6a6&scoped=true&", function () {
      api.rerender('3a8bb6a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/communicator/settings/providers/Subscriptions.vue"
export default component.exports