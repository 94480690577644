var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left column-format gap-2" },
    [
      _c("div", [
        _vm._v(
          "To continue registering for a phone line in " +
            _vm._s(_vm.selectedCountry.name) +
            " please choose whether you operate as an Individual or a Business."
        )
      ]),
      _c("v-select", {
        staticClass: "h-outline",
        attrs: {
          items: ["Individual", "Business"],
          "persistent-placeholder": "",
          "hide-details": "",
          label: "End user type"
        },
        model: {
          value: _vm.endUserType,
          callback: function($$v) {
            _vm.endUserType = $$v
          },
          expression: "endUserType"
        }
      }),
      _c(
        "v-btn",
        {
          staticClass: "super-action",
          on: {
            click: function($event) {
              return _vm.confirmRegistration()
            }
          }
        },
        [_vm._v("Continue")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }