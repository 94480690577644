var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format" },
    [
      _c(
        "v-menu",
        {
          attrs: { "close-on-click": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    { staticClass: "button-box pointer row-format centered" },
                    [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            directives: [
                              {
                                name: "tippy",
                                rawName: "v-tippy",
                                value: { content: "Snooze notification" },
                                expression: "{ content: 'Snooze notification' }"
                              }
                            ],
                            attrs: { color: "secondary", small: "" }
                          },
                          on
                        ),
                        [_vm._v("alarm")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            { staticStyle: { "font-size": "14px" }, attrs: { dense: "" } },
            [
              _c(
                "v-list-item",
                [
                  _c("v-list-item-content", { staticClass: "brand-bold" }, [
                    _vm._v(_vm._s(_vm.$t("notifications.remind-me")))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.snooze(5)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("notifications.snooze.five-minutes")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.snooze(20)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("notifications.snooze.twenty-minutes")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.snooze(60)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("notifications.snooze.one-hour")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.snooze(180)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("notifications.snooze.three-hours")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.snooze(1440)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("notifications.snooze.tomorrow")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function($event) {
                          return _vm.snooze(10080)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("notifications.snooze.next-week")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "tippy",
              rawName: "v-tippy",
              value: { content: "Dismiss notification" },
              expression: "{ content: 'Dismiss notification' }"
            }
          ],
          staticClass: "button-box pointer row-format centered",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.deleteOne()
            }
          }
        },
        [
          _c("v-icon", { attrs: { size: "22", color: "secondary" } }, [
            _vm._v("done")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }