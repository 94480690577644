<template>
	<div class="pb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }, { label: 'Forms' }]">
			Submissions
		</page-header>

		<div class="row-format gap-3 mb-4">
			<v-text-field
					outlined
					hide-details
					dense
					v-model="filter.search"
					placeholder="Search..."
					style="max-width: 200px; background-color: var(--v-white-base)"
					color="gray_30"
			>
				<template v-slot:prepend-inner
				><span class="material-symbols-rounded font-gray_50">search</span></template
				>
			</v-text-field>
			<discovery-list-filter slot="filter" v-model="filter" @clear-filter="clearFilter()"></discovery-list-filter>
		</div>

		<form-submissions-table :filter="filter"></form-submissions-table>
	</div>
</template>

<script>
	import FormSubmissionsTable from '@/modules/discovery/FormSubmissionsTable';
	import DiscoveryListFilter from '@/modules/discovery/DiscoveryListFilter';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'FormSubmissions',

		props: [],

		components: { FormSubmissionsTable, DiscoveryListFilter, PageHeader },

		data: function() {
			return {
				filter: this.emptyFilter(),
			};
		},

		mounted() {
			this.loadSavedFilter();
		},

		beforeDestroy() {},

		methods: {
			setView: function(view) {
				this.$router.push('/pages/' + view);
			},

			emptyFilter: function() {
				return {
					search: null,
					dateSpecifier: 'past-30-days',
					earliest: null,
					latest: null,
					clientList: [],
				};
			},

			saveCurrentFilter() {
				try {
					localStorage.setItem(this.filterStateKey, JSON.stringify(this.filter));
				} catch (err) {
					console.log('Error putting preferences into local storage.');
				}
			},

			clearFilter() {
				this.filter = this.emptyFilter();
			},

			loadSavedFilter() {
				try {
					let filterString = localStorage.getItem(this.filterStateKey);
					if (filterString) {
						this.filter = JSON.parse(filterString);
					} else {
						this.filter = this.emptyFilter();
						this.filter.dateSpecifier = 'past-30-days';
					}
				} catch (err) {
					console.log('Error reading filter preferences from local storage.', err);
				}
			},
		},

		watch: {
			filter: {
				deep: true,
				handler() {
					this.saveCurrentFilter();
				},
			},
		},

		computed: {
			filterStateKey: function() {
				return 'DISCOVERY_LIST_FILTERS_' + this.$store.getters.getAccountId + '_' + this.$store.getters.getLoggedInUserId;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
