<template>
	<v-container
		fluid
		:fill-height="$vuetify.breakpoint.xsOnly"
		:style="$vuetify.breakpoint.xsOnly ? 'height: var(--vh); background-color: white; z-index:300; margin: 0; padding: 8px;' : ''"
	>
		<div class="mt-0 mx-3 mx-sm-8 mb-2 mb-sm-4">
			<h1 class="justify-center mb-4" style="" v-html="title"></h1>
			<div class="brand-normal text--black mb-12" style="font-size: 16px; line-height: 24px;" v-html="subtitle"></div>
			<v-form id="payment" @submit.prevent="getToken">
				<input type="hidden" v-model="firstName" id="first_name" name="first_name" data-recurly="first_name" />
				<input type="hidden" v-model="lastName" id="last_name" name="last_name" data-recurly="last_name" />
				<v-container>
					<fieldset class="name-fieldset mb-1">
						<div class="d-flex ma-0 pt-0 pb-2 px-1">
							<div class="ma-0 pa-0 flex-grow-1">
								<input
									type="text"
									v-model="fullName"
									name="full_name"
									id="full_name"
									:placeholder="$t('payment.name-on-card')"
								/>
							</div>
							<div class="d-flex pa-0 ma-0">
								<input
									:style="$vuetify.breakpoint.smAndUp ? 'width: 106px' : 'width: 75px'"
									type="tel"
									v-model="postalCode"
									id="postal_code"
									name="postal_code"
									data-recurly="postal_code"
									:placeholder="$t('payment.card-postal')"
								/>
							</div>
						</div>
					</fieldset>

					<div class="d-flex ma-0 pa-0">
						<div class="ma-0 flex-grow-1">
							<inputError :show="errors.name">{{ $t('payment.name-on-card') }}</inputError>
						</div>
						<div class="d-flex ma-0" :style="$vuetify.breakpoint.smAndUp ? 'width: 106px' : 'width: 75px'">
							<inputError :show="errors.postal_code" align="text-left">{{ $t('payment.card-postal') }}</inputError>
						</div>
					</div>

					<div class="mt-0" style="height: 64px;">
						<div id="recurly-elements"></div>
						<input type="hidden" name="recurly-token" data-recurly="token" />

						<div class="d-flex mx-0 mt-1 pa-0">
							<div class="ma-0 flex-grow-1">
								<inputError :show="errors.number">{{ this.$t('payment.card-number') }}</inputError>
							</div>
							<div class="d-flex ma-0" :style="$vuetify.breakpoint.smAndUp ? 'width: 62px' : 'width: 60px'">
								<inputError :show="errors.expiry">{{ this.$t('payment.card-expires') }}</inputError>
							</div>
							<div class="d-flex ma-0" :style="$vuetify.breakpoint.smAndUp ? 'width: 50px' : 'width: 60px'">
								<inputError :show="errors.cvv">{{ this.$t('payment.card-cvv') }}</inputError>
							</div>
						</div>
					</div>

					<div class="mt-3">
						<v-btn
							:disabled="submitDisabled"
							type="submit"
							color="primary"
							large
							:block="$vuetify.breakpoint.xsOnly"
							style="min-width: 320px;"
							>{{ button }}</v-btn
						>
					</div>
				</v-container>
			</v-form>
		</div>
	</v-container>
</template>

<script>
	import SubscriptionService from '../SubscriptionService';
	import recurly from 'recurly.js';
	import inputError from './input-error';

	export default {
		name: 'Payment',
		props: { parent: { type: Object } },
		components: { inputError },

		data() {
			return {
				subscriptionService: new SubscriptionService(),
				recurlyPublicKey: process.env.VUE_APP_RECURLY_PUBLIC_KEY,
				elements: null,
				fullName: '',
				postalCode: null,
				cardElement: null,
				errors: {},
				title: '',
				subtitle: '',
				button: '',
				firstPayment: null,
				submitDisabled: false,
			};
		},

		mounted() {
			//this.errors.number = true;
			//this.errors.expiry = true;
			//this.errors.cvv = true;
			//this.errors.postal_code = true;
			//this.errors.number = true;

			this.buildFormInput();
			this.buildTextAndButtons();

			recurly.configure(this.recurlyPublicKey);
			this.elements = recurly.Elements();
			this.cardElement = this.elements.CardElement({
				inputType: 'mobileSelect',
				style: {
					fontSize: '16px',
					placeholder: {
						fontSize: this.$vuetify.breakpoint.xsOnly ? '13px' : 'default',
						color: '#867F77',
						content: {
							number: this.$t('payment.card-number'),
							cvv: this.$t('payment.card-cvv'),
							expiry: this.$t('payment.card-expiry'),
						},
					},
				},
			});

			this.firstPayment = this.$validations.isEmpty(this.parent.billingInfo);
			setTimeout(this.cardAttach, 1000);
		},

		methods: {
			cardAttach() {
				this.cardElement.attach('#recurly-elements');
			},

			alert(type, message) {
				let alert = { type: type, message: message };
				this.$emit('alert', alert);
			},

			getToken() {
				this.submitDisabled = true;
				this.$store.commit('startLoading');
				this.errors = {}; // clear context errors
				this.alert(); // clear alert error
				let form = document.querySelector('#payment');
				recurly.token(this.elements, form, this.handleToken);
			},

			handleToken(err, token) {
				// console.log('token', token);
				if (err) {
					// handle error using err.code and err.fields
					console.log('Recurly Error', err);
					this.submitDisabled = false;
					this.$store.commit('stopLoading');
					this.handleRecurlyError(err);
				} else {
					// recurly.js has filled in the 'token' field, so now we can submit the
					// form to your server
					this.subscriptionService
						.putBillingInfo(token.id)
						.then((response) => {
							console.log('putBillingInfo response', response);
							this.$emit('get-billing-info');
							if (this.parent.planCode) {
								this.$emit('plan-change', this.parent.planCode);
							} else {
								this.$store.commit('success', 'Payment method updated. Thanks!');
							}
							this.$emit('close');
						})
						.catch((error) => {
							this.handleRecurlyError(error);
							console.log(error.response);
						})
						.finally(() => {
							this.submitDisabled = false;
							this.$store.commit('stopLoading');
						});
					console.log('planCode', this.parent.planCode);
				}
			},

			handleRecurlyError(error) {
				// pass in raw response from Recurly
				this.alert();
				this.errors = {};
				console.log(error);
				if (error.fields) {
					for (let field of error.fields) {
						if (field == 'first_name' || field == 'last_name') {
							this.errors['name'] = true;
							continue;
						}
						if (field == 'month' || field == 'year') {
							this.errors['expiry'] = true;
							continue;
						}
						this.errors[field] = true; // first_name, last_name, postal_code, number, expiry
					}
					this.alert('error', this.$t('payment.error.please-correct'));
					return;
				}
				if (error.response.data.message == undefined) {
					this.alert('error', this.$t('payment.error.undefined'));
					return false;
				}
				if (this.isAvsError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.avs'));
					this.errors['postal_code'] = true;
					return;
				}
				if (this.isInsufficientError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.insufficient'));
					return;
				}
				if (this.isCvvError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.cvv'));
					this.errors['cvv'] = true;
					return;
				}
				if (this.isExpiryError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.expiry'));
					this.errors['expiry'] = true;
					return;
				}
				if (this.isCardTypeError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.card-type'));
					this.errors['number'] = true;
					return;
				}
				if (this.isGatewayTimeoutError(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.gateway-timeout'));
					return;
				}
				if (this.isDeclined(error.response.data.message)) {
					this.alert('error', this.$t('payment.error.declined'));
					return;
				}
				console.log('no error type match', error.response);
				this.alert('error', this.$t('payment.error.undefined'));
				return;
			},

			isDeclined(message) {
				return message.match(/declined/g);
			},
			isAvsError(message) {
				return message.match(/billing address does not match/g);
			},
			isInsufficientError(message) {
				return message.match(/insufficient/g);
			},
			isExpiryError(message) {
				return message.match(/expiration date/g);
			},
			isCvvError(message) {
				return message.match(/security code/g);
			},
			isCardTypeError(message) {
				return message.match(/card type is not accepted/g);
			},
			isGatewayTimeoutError(message) {
				return message.match(/payment system did not respond in time/g);
			},

			buildFormInput() {
				if (this.parent.billingInfo) {
					// Set to billing info name and zip if available
					this.fullName = this.parent.billingInfo.name;
					this.postalCode = this.parent.billingInfo.postal;
				} else {
					// Otherwise, set initial state to user's account values
					let [firstName, lastName] = this.parent.account.accountName.split(/[\s]+/);
					this.fullName = firstName + ' ' + lastName;
					this.postalCode = this.parent.account.postal ? this.parent.account.postal : '';
					console.log(this.fullName);
				}
				// console.log(ret);
				return true;
			},

			getNames() {
				let names = this.fullName.split(/[\s]+/);
				return { firstName: names[0], lastName: names[names.length - 1] };
			},
			buildTextAndButtons() {
				if (!this.parent.planCode) {
					// It's a payment change only
					const date = this.$DateTime.longDate(this.parent.customerPlan.currentPeriodEndsAt);
					this.title = this.$t('payment.change.heading');
					this.subtitle = this.$t('payment.change.subheading', {
						'next-date': date,
					});
					this.button = this.$t('payment.change.button');
					return;
				}
				// New subscription plan
				const newPlan = this.parent.planHash[this.parent.planCode];
				console.log(newPlan);

				const nextDate = this.$DateTime.local().plus({ months: newPlan.interval });

				this.title = this.$t('payment.new.heading');
				this.subtitle = this.$t('payment.new.subheading', {
					'new-price': '$' + newPlan.price,
					'next-date': '<nobr>' + this.$DateTime.longDate(nextDate) + '</nobr>',
					'monthly-quarterly': newPlan.cadence,
				});
				this.button = this.$t('payment.new.button');
				return;
			},
		},
		computed: {
			firstName: {
				get: function() {
					if (!this.fullName) return '';
					let names = this.getNames();
					return names.firstName;
				},
				/* set: function() {
					let names = this.getNames();
					return names.firstName;
				}, */
			},
			lastName: {
				get: function() {
					if (!this.fullName) return '';
					let names = this.getNames();
					return names.lastName;
				},
				/* set: function() {
					let names = this.getNames();
					return names.lastName;
				}, */
			},
		},
	};
</script>

<style lang="scss">
	.recurly-element-card,
	.name-fieldset {
		border: none;
		border-bottom: 1px solid var(--v-gray_70-base);
		border-radius: 0;
		color: #867f77;
	}
	.name-fieldset:hover,
	.recurly-element-card:hover {
		border-bottom: 1px solid var(--v-black-base);
		border-color: black;
	}
	//	.recurly-element-card input,
	.name-fieldset input {
		font-size: 16px;
		border: none;
		display: inline-block;
		height: 100%;
		margin: 0;
		outline: none;
		box-sizing: border-box;
	}

	.name-fieldset input:placeholder {
		color: var(--v-gray_70-base);
		//color: '#929296';
	}

	.name-fieldset input#full_name {
		width: 100%;
	}
	.name-fieldset input#postal_code {
		width: 100%;
		float: right;
	}

	div.col {
		padding-bottom: 0;
		padding-top: 0;
	}
</style>
