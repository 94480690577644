var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-4",
      staticStyle: {
        "background-color": "var(--v-white-base)",
        border: "1px solid var(--v-gray_30-base)",
        "border-radius": "4px"
      }
    },
    [
      _c("bar", {
        key: _vm.barRefreshKey,
        attrs: {
          "chart-id": _vm.barRefreshKey,
          datasetIdKey: "insight-money-bar",
          "chart-options": _vm.barChartOptions,
          "chart-data": _vm.barChart,
          height: 300
        }
      }),
      _vm.showDetail
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.showDetail },
              on: {
                close: function($event) {
                  _vm.showDetail = false
                }
              }
            },
            [
              _c("booking-detail", {
                attrs: { "booking-detail": _vm.bookingDetail },
                on: {
                  close: function($event) {
                    _vm.showDetail = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }