<template>
	<div class="plan-card text-left gap-2">
		<div style="font-size: 36px; font-weight: 600; line-height: 36px" class="">
			{{ $t(`subscription-v${this.pricingVersion}.${planType}.header`) }}
		</div>
		<div class="font-20">
			<div v-if="isAnnual">{{ $formatters.dollars(plan.price, false, true, 'USD') }} annually</div>
			<div v-else>{{ $formatters.dollars(plan.price, false, true, 'USD') }} monthly</div>
			<div v-if="isAnnual" class="font-12 font-gray_70">
				Save 20% with an annual plan!
			</div>
		</div>
		<div>
			<div class="font-primary">{{ $t(`subscription-v${this.pricingVersion}.who-for`) }}</div>
			<div>{{ $t(`subscription-v${this.pricingVersion}.${planType}.who-for`) }}</div>
		</div>
		<div class="my-2">
			<v-btn v-if="isCurrentPlan && !inTrial" large block :disabled="true">Current plan</v-btn>
			<v-btn color="secondary" v-else large block @click="$emit('click')" :disabled="disabled"
				><span style="color: var(--v-white-base)">Select plan</span></v-btn
			>
		</div>
		<div>
			<ul class="font-14">
				<li v-for="(point, index) in points" :key="index">{{ point }}</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'PlanCard',

		props: ['plan', 'currentPlan', 'inTrial', 'disabled', 'pricingVersion'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			planType: function() {
				return this.plan.code.split('-')[0];
			},

			isCurrentPlan: function() {
				return this.currentPlan && this.currentPlan.code === this.plan.code;
			},

			points: function() {
				return this.$t(`subscription-v${this.pricingVersion}.${this.planType}.points`);
			},

			pricePerYear: function() {
				if (this.isAnnual) {
					return this.plan.price;
				} else {
					return this.plan.price * 12;
				}
			},

			isAnnual: function() {
				return this.plan.interval === 12;
			},
		},
	};
</script>

<style scoped lang="scss">
	.plan-card {
		display: flex;
		flex-direction: column;
		width: 350px;
		min-height: 483px;
		padding: 24px;
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_20-base);
		border-radius: 4px;

		h1 {
			font-size: 36px;
			font-weight: 600;
		}

		h3 {
			font-size: 24px;
		}
	}
</style>
