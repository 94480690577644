var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "solid-hr" },
    [
      _c("v-switch", {
        staticClass: "mt-0",
        attrs: {
          label: "Turn on conditional logic",
          "hide-details": "",
          dense: ""
        },
        model: {
          value: _vm.item.conditionsEnabled,
          callback: function($$v) {
            _vm.$set(_vm.item, "conditionsEnabled", $$v)
          },
          expression: "item.conditionsEnabled"
        }
      }),
      _vm.item.conditionsEnabled
        ? _c(
            "div",
            { staticClass: "mr-4" },
            [
              _c(
                "div",
                { staticClass: "align-center" },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.item.conditionalType,
                        callback: function($$v) {
                          _vm.$set(_vm.item, "conditionalType", $$v)
                        },
                        expression: "item.conditionalType"
                      }
                    },
                    [
                      _c("v-radio", { attrs: { label: "AND", value: "and" } }),
                      _c("v-radio", { attrs: { label: "OR", value: "or" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-4", staticStyle: { "text-align": "left" } },
                [
                  _vm._v("Field will show if "),
                  _c("strong", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.item.conditionalType === "and" ? "all" : "any"
                        ) +
                        " "
                    )
                  ]),
                  _vm._v(" of these conditions are true:")
                ]
              ),
              _c("hr"),
              _vm._l(_vm.item.conditions, function(cond, index) {
                return _c("ul", { key: index }, [
                  _c("li", { staticClass: "mt-4" }, [
                    _c("div", { staticStyle: { "text-align": "left" } }, [
                      _c("strong", [_vm._v(_vm._s(cond.fieldName))]),
                      _vm._v(" " + _vm._s(cond.operation) + " "),
                      !["is answered", "isn't answered"].includes(
                        cond.operation
                      )
                        ? _c("strong", [_vm._v(_vm._s(cond.answer))])
                        : _vm._e()
                    ])
                  ])
                ])
              }),
              _c(
                "v-btn",
                {
                  staticClass: "primary-action my-2",
                  on: {
                    click: function($event) {
                      return _vm.editConditions()
                    }
                  }
                },
                [_vm._v(" Edit ")]
              ),
              _c("hr")
            ],
            2
          )
        : _vm._e(),
      !!_vm.conditionalDialog
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.conditionalDialog, persistent: false },
              on: {
                close: function($event) {
                  _vm.conditionalDialog = false
                }
              }
            },
            [
              _c(
                "v-btn",
                { staticClass: "close-dialog", attrs: { icon: "" } },
                [
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function($event) {
                          _vm.conditionalDialog = false
                        }
                      }
                    },
                    [_vm._v("$close")]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "px-8 py-8",
                  staticStyle: { "min-width": "900px" }
                },
                [
                  _c("div", { staticClass: "modal-title" }, [
                    _vm._v("Conditional Settings")
                  ]),
                  _vm._l(_vm.item.conditions, function(cond, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    label: "Field name",
                                    items: _vm.mappingKeys
                                  },
                                  model: {
                                    value: cond.fieldName,
                                    callback: function($$v) {
                                      _vm.$set(cond, "fieldName", $$v)
                                    },
                                    expression: "cond.fieldName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    label: "Operation",
                                    items: [
                                      "is",
                                      "isn't",
                                      "is answered",
                                      "isn't answered",
                                      "contains",
                                      "doesn't contain"
                                    ]
                                  },
                                  model: {
                                    value: cond.operation,
                                    callback: function($$v) {
                                      _vm.$set(cond, "operation", $$v)
                                    },
                                    expression: "cond.operation"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "row-format align-center",
                                attrs: { cols: "4" }
                              },
                              [
                                cond.operation === "is answered" ||
                                cond.operation === "isn't answered"
                                  ? _c("div", [_vm._v(" -- ")])
                                  : _vm.isOptionType(cond.fieldName) &&
                                    (cond.operation === "is" ||
                                      cond.operation === "isn't")
                                  ? _c("v-select", {
                                      attrs: {
                                        label: "Answer",
                                        items: _vm.optionsFromField(
                                          cond.fieldName
                                        )
                                      },
                                      model: {
                                        value: cond.answer,
                                        callback: function($$v) {
                                          _vm.$set(cond, "answer", $$v)
                                        },
                                        expression: "cond.answer"
                                      }
                                    })
                                  : _c("v-text-field", {
                                      attrs: { label: "Answer" },
                                      model: {
                                        value: cond.answer,
                                        callback: function($$v) {
                                          _vm.$set(cond, "answer", $$v)
                                        },
                                        expression: "cond.answer"
                                      }
                                    }),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "ml-auto material-symbols-rounded pointer",
                                    attrs: { size: "20" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteConditional(index)
                                      }
                                    }
                                  },
                                  [_vm._v("delete")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.addConditional()
                        }
                      }
                    },
                    [_vm._v("+ New condition")]
                  )
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action ml-4",
                      attrs: { width: "136" },
                      on: {
                        click: function($event) {
                          _vm.conditionalDialog = false
                        }
                      }
                    },
                    [_vm._v(" Save Changes ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }