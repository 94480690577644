var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "proposal-share-send-widget" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.cancel()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _vm.proposal.proposalStatus === "Draft"
        ? _c("div", [
            _c("div", { staticClass: "modal-padding" }, [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("proposal.share.activate-title", { mode: _vm.mode })
                  )
                )
              ]),
              _c("div", { staticClass: "modal-subtitle" }, [
                _vm._v(_vm._s(_vm.$t("proposal.share.activate-message")))
              ])
            ]),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action mr-1",
                    attrs: { "min-width": "120" },
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action ml-1",
                    attrs: { "min-width": "120" },
                    on: {
                      click: function($event) {
                        return _vm.finalize()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.finalize")) + " ")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.proposal.proposalStatus !== "Draft" && _vm.mode === "Select"
        ? _c("div", [
            _c("div", { staticClass: "modal-padding" }, [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("proposal.share.select-method")))
              ]),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: {
                    click: function($event) {
                      _vm.mode = "Send"
                    }
                  }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("$email")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.send-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.send-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.send-next")))
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: {
                    click: function($event) {
                      _vm.mode = "Share"
                    }
                  }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("$share")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.share-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.share-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.share-next")))
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: {
                    click: function($event) {
                      return _vm.requestPrint()
                    }
                  }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("print")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.print-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.print-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("proposal.share.print-next")))
                    ])
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm.proposal.proposalStatus !== "Draft" && _vm.mode === "Share"
        ? _c("div", [
            _c("div", { staticClass: "modal-padding" }, [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("proposal.share.share-title")))
              ]),
              _c(
                "div",
                {
                  staticClass: "d-flex align-center justify-space-between my-5"
                },
                [
                  _c(
                    "div",
                    { staticClass: "mr-1", staticStyle: { flex: "1 1 auto" } },
                    [
                      _c("input", {
                        staticClass: "link-box",
                        attrs: { type: "text", id: "linkBox", readonly: "" },
                        domProps: { value: _vm.shareableLink }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ml-1", staticStyle: { flex: "0 1 auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "super-action",
                          on: {
                            click: function($event) {
                              return _vm.copyLink()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("proposal.share.copy-link")))]
                      )
                    ],
                    1
                  )
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    staticStyle: { width: "140px" },
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.done")) + " ")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.proposal.proposalStatus !== "Draft" && _vm.mode === "Send"
        ? _c("div", [
            _c(
              "div",
              { staticClass: "modal-padding" },
              [
                _c("div", { staticClass: "modal-title mb-2" }, [
                  _vm._v(_vm._s(_vm.$t("proposal.share.send-title")))
                ]),
                _c(
                  "v-row",
                  { staticClass: "rowFormat" },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c("span", { staticClass: "emailHeader" }, [
                        _vm._v(_vm._s(_vm.$t("global.email-from")) + ": ")
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$store.getters.getLoggedInUserName) +
                            " <" +
                            _vm._s(_vm.$store.getters.getFromEmail) +
                            ">"
                        )
                      ])
                    ])
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mt-1 mb-2" }),
                _c(
                  "v-row",
                  { staticClass: "rowFormat" },
                  [
                    _c("v-col", { attrs: { cols: "10" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "flex-wrap": "wrap"
                          },
                          on: {
                            click: function($event) {
                              _vm.toMenu = true
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "emailHeader" }, [
                            _vm._v(_vm._s(_vm.$t("global.email-to")) + ": ")
                          ]),
                          _vm._l(_vm.toEmail, function(contact) {
                            return _c(
                              "div",
                              { key: contact.email, staticClass: "emailPill" },
                              [
                                _vm._v(" " + _vm._s(contact.email) + " "),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-1",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      color: "var(--v-blue_90-base)",
                                      small: ""
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.deselectEmail(
                                          $event,
                                          contact
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("$closeSmall")]
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _c(
                      "v-col",
                      { staticClass: "text-right pr-3", attrs: { cols: "2" } },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              "nudge-left": "418",
                              elevation: "1",
                              "nudge-top": "12"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                rounded: ""
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("$chevronDown")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2832106106
                            ),
                            model: {
                              value: _vm.toMenu,
                              callback: function($$v) {
                                _vm.toMenu = $$v
                              },
                              expression: "toMenu"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "background-color": "var(--v-white-base)",
                                  width: "400px"
                                }
                              },
                              [
                                _vm.toEmailMode === "selectContact"
                                  ? _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "pl-5",
                                                attrs: {
                                                  cols: "10",
                                                  align: "left"
                                                }
                                              },
                                              [
                                                _vm._l(_vm.contacts, function(
                                                  contact
                                                ) {
                                                  return _c("v-checkbox", {
                                                    key: contact.email,
                                                    attrs: {
                                                      "hide-details": true,
                                                      label: _vm.formatEmail(
                                                        contact
                                                      ),
                                                      value: contact,
                                                      dense: ""
                                                    },
                                                    model: {
                                                      value: _vm.toEmail,
                                                      callback: function($$v) {
                                                        _vm.toEmail = $$v
                                                      },
                                                      expression: "toEmail"
                                                    }
                                                  })
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "pl-1 mt-4",
                                                    attrs: {
                                                      text: "",
                                                      large: "",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.toEmailMode =
                                                          "createContact"
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: { small: "" }
                                                      },
                                                      [_vm._v("$plus")]
                                                    ),
                                                    _vm._v(" Add new contact")
                                                  ],
                                                  1
                                                )
                                              ],
                                              2
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "2",
                                                  align: "right"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      icon: "",
                                                      rounded: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.closeToMenu()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("$chevronUp")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.toEmailMode === "createContact"
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "modal-padding" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "modal-title mb-2" },
                                            [_vm._v("Create new contact")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "close-dialog",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.closeToMenu()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("$chevronUp")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "div",
                                              { staticClass: "pr-1" },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "h-outline",
                                                  attrs: {
                                                    "hide-details": "",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "First name"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newContact.firstName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.newContact,
                                                        "firstName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newContact.firstName"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "pl-1" },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "h-outline",
                                                  attrs: {
                                                    "hide-details": "",
                                                    "persistent-placeholder":
                                                      "",
                                                    label: "Last name"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newContact.lastName,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.newContact,
                                                        "lastName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newContact.lastName"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]),
                                          _c("v-text-field", {
                                            staticClass: "h-outline",
                                            attrs: {
                                              "hide-details": "",
                                              "persistent-placeholder": "",
                                              label: "Email"
                                            },
                                            model: {
                                              value: _vm.newContact.email,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.newContact,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "newContact.email"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "modal-footer" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "secondary-action mr-1",
                                              attrs: { width: "120" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.cancelCreateNewContact()
                                                }
                                              }
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "primary-action ml-1",
                                              attrs: { width: "120" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.createNewContact()
                                                }
                                              }
                                            },
                                            [_vm._v("Save")]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mt-1 mb-2" }),
                _c(
                  "v-row",
                  { staticClass: "rowFormat" },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row"
                          }
                        },
                        [
                          _c("span", { staticClass: "emailHeader" }, [
                            _vm._v(
                              _vm._s(_vm.$t("global.email-subject")) + ": "
                            )
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.subject,
                                expression: "subject"
                              }
                            ],
                            staticStyle: {
                              outline: "none",
                              width: "100%",
                              color: "var(--v-black-base)"
                            },
                            attrs: { type: "text" },
                            domProps: { value: _vm.subject },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.subject = $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _c("v-divider", { staticClass: "mt-1 mb-2" }),
                _c(
                  "v-row",
                  { staticClass: "rowFormat" },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c(
                        "div",
                        [
                          _vm.initialEmailContent
                            ? _c("editor", {
                                ref: "emailContent",
                                attrs: {
                                  "api-key": _vm.$store.getters.getTinyMceKey,
                                  inline: false,
                                  "initial-value": _vm.initialEmailContent,
                                  init: _vm.mceConfigText,
                                  spellcheck: true
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action mr-1",
                    attrs: { width: "120", disabled: _vm.disabled },
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action ml-1",
                    attrs: {
                      width: "120",
                      disabled: _vm.toEmail.length === 0 || _vm.disabled
                    },
                    on: {
                      click: function($event) {
                        return _vm.sendEmail()
                      }
                    }
                  },
                  [_vm._v("Send")]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }