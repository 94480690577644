var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format align-center" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            "nudge-bottom": "24",
            "close-on-content-click": true,
            "content-class": "filter-menu-class",
            transition: "slide-y-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                return _vm._l(_vm.client.contacts, function(contact) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: {
                            content: contact.fullName,
                            placement: "top",
                            arrow: true,
                            arrowType: "round",
                            animation: "fade",
                            delay: 50
                          },
                          expression:
                            "{\n\t\t\t\t\tcontent: contact.fullName,\n\t\t\t\t\tplacement: 'top',\n\t\t\t\t\tarrow: true,\n\t\t\t\t\tarrowType: 'round',\n\t\t\t\t\tanimation: 'fade',\n\t\t\t\t\tdelay: 50,\n\t\t\t\t}"
                        }
                      ],
                      key: contact.id,
                      class:
                        "row-format centered " +
                        (_vm.client.archive
                          ? "contact-avatar-archive"
                          : "contact-avatar"),
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.setActiveContact(contact)
                        }
                      }
                    },
                    [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              contact.firstName
                                ? contact.firstName.substr(0, 1).toUpperCase()
                                : ""
                            ) +
                            _vm._s(
                              contact.lastName
                                ? contact.lastName.substr(0, 1).toUpperCase()
                                : ""
                            ) +
                            " "
                        )
                      ])
                    ]
                  )
                })
              }
            }
          ]),
          model: {
            value: _vm.menuOpen,
            callback: function($$v) {
              _vm.menuOpen = $$v
            },
            expression: "menuOpen"
          }
        },
        [
          _vm.activeContact
            ? _c("div", { staticClass: "text-left" }, [
                _c(
                  "div",
                  { staticClass: "row-format" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-auto pointer mt-4 mr-4",
                        attrs: { small: "" },
                        on: {
                          click: function($event) {
                            _vm.menuOpen = false
                          }
                        }
                      },
                      [_vm._v("$close")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "px-4 pb-4 column-format",
                    staticStyle: { gap: "4px" }
                  },
                  [
                    _c("div", { staticClass: "font-16 font-gray_70" }, [
                      _vm._v(_vm._s(_vm.activeContact.fullName))
                    ]),
                    _vm.activeContact.email
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "font-14 row-format align-center pointer",
                            on: {
                              click: function($event) {
                                return _vm.sendEmail(_vm.activeContact.email)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "mr-3" }, [
                              _vm._v(_vm._s(_vm.activeContact.email))
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-auto",
                                attrs: { color: "secondary" }
                              },
                              [_vm._v("email")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeContact.phone
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "font-14 row-format align-center pointer",
                            on: {
                              click: function($event) {
                                return _vm.makePhoneCall(
                                  _vm.activeContact.phone
                                )
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "mr-3" }, [
                              _vm._v(_vm._s(_vm.activeContact.phone))
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-auto",
                                attrs: { color: "secondary" }
                              },
                              [_vm._v("phone")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }