var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-chip",
        {
          staticClass: "pointer",
          attrs: { color: "blue_5" },
          on: {
            click: function($event) {
              return _vm.$emit("show-detail")
            }
          }
        },
        [_vm._v(_vm._s(_vm.label))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }