<template>
	<div class="cardWrapper">
		<div v-if="opportunity.emptyCard" class="blankCard mx-0 my-2 pl-4 pr-0 pt-2" @click.stop="$emit('add-new-empty')">
			<v-icon size="34">$duplicate</v-icon>
			<span class="font-grey">{{ $t('projects.add-first-deliverable') }}</span>
		</div>
		<div v-else class="card pa-4 mb-2" @click="$emit('edit', opportunity.id)">
			<div class="column-format flex-grow-1 gap-2">
				<div class="row-format align-center" v-if="client">
					<client-avatar x-small :client="client"></client-avatar>
					<div class="font-12 brand-bold" style="margin-left: 6px">
						{{client.name}}
					</div>
				</div>
				<div class="column-format font-14">
					<div class="mb-2">{{opportunity.name}}</div>
					<div class="font-12" v-if="opportunity.formData.firstName || opportunity.formData.lastName">{{opportunity.formData.firstName}} {{opportunity.formData.lastName}}</div>
					<div class="font-12" v-if="opportunity.formData.email">{{opportunity.formData.email}}</div>
				</div>
				<div class="column-format mt-auto text-option">
					<div class="row-format gap-1" v-if="opportunity.nextDue">
						<div class="font-gray_70">Next due:</div>
						<div class="ml-auto">{{DateTime.fromISO(opportunity.nextDue.dueDate).toLocaleString(DateTime.DATE_MED)}}</div>
					</div>
					<div class="row-format gap-1" v-if="opportunity.estCloseDate">
						<div class="font-gray_70">Est. Close:</div>
						<div class="ml-auto">{{DateTime.fromISO(opportunity.estCloseDate).toLocaleString(DateTime.DATE_MED)}}</div>
					</div>
					<div class="row-format gap-1" v-if="opportunity.value">
						<div class="font-gray_70">Value:</div>
						<div class="ml-auto">{{$formatters.dollars(opportunity.value,false,true,opportunity.currency)}} / {{opportunity.timePeriodDetail.label}}</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HDateTime from "@/modules/utils/HDateTime";
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'OpportunityCard',

		props: ['opportunity','stage'],

		components: {ClientAvatar},

		data: function() {
			return {
				DateTime:HDateTime
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			client: function(){
				if(this.opportunity.clientId){
					return this.$store.getters.getClientById(this.opportunity.clientId);
				}else{
					return null;
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.card-wrapper {
		padding: 8px 8px 8px 8px;
		&:active {
			cursor: grabbing;
		}
	}

	.card {
		background-color: var(--v-white-base);
		border: 1px solid var(--v-gray_30-base);
		box-sizing: border-box;
		box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		min-height: 200px;
		&:hover {
			border: 1px solid var(--v-gray_50-base);
		}
	}

	.blankCard {
		background-color: var(--v-white-base);
		height: 144px;
		border: 1px solid var(--v-gray_50-base);
		box-sizing: border-box;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.text-option {
		font-size: 12px;
		font-weight: 500;
	}

</style>
