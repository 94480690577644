var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "builder-wrapper",
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "tippy",
              rawName: "v-tippy",
              value: { content: "Drag to re-order" },
              expression: "{ content: 'Drag to re-order' }"
            }
          ],
          staticClass: "pb-2 dragHandle",
          staticStyle: { "text-align": "center", cursor: "move" }
        },
        [
          _c("v-icon", { staticStyle: { transform: "rotate(90deg)" } }, [
            _vm._v("$drag")
          ])
        ],
        1
      ),
      _c("div", { staticClass: "builder-control", style: _vm.offset }, [
        _c(
          "div",
          [
            _c(
              "v-icon",
              {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: { content: "Move Up" },
                    expression: "{ content: 'Move Up' }"
                  }
                ],
                staticClass: "pointer iconHover",
                nativeOn: {
                  click: function($event) {
                    return _vm.$emit("shift-up")
                  }
                }
              },
              [_vm._v("$arrowUp")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "v-icon",
              {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: { content: "Duplicate section" },
                    expression: "{ content: 'Duplicate section' }"
                  }
                ],
                staticClass: "pointer iconHover",
                attrs: { small: "" },
                nativeOn: {
                  click: function($event) {
                    return _vm.$emit("duplicate-section")
                  }
                }
              },
              [_vm._v("$subtract")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "v-icon",
              {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: { content: "Delete section" },
                    expression: "{ content: 'Delete section' }"
                  }
                ],
                staticClass: "pointer iconHover",
                attrs: { small: "" },
                nativeOn: {
                  click: function($event) {
                    return _vm.$emit("delete-section")
                  }
                }
              },
              [_vm._v("$delete")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "v-icon",
              {
                directives: [
                  {
                    name: "tippy",
                    rawName: "v-tippy",
                    value: { content: "Move Down" },
                    expression: "{ content: 'Move Down' }"
                  }
                ],
                staticClass: "pointer iconHover",
                nativeOn: {
                  click: function($event) {
                    return _vm.$emit("shift-down")
                  }
                }
              },
              [_vm._v("$arrowDown")]
            )
          ],
          1
        )
      ]),
      _c(
        _vm.item.fieldType,
        _vm._b(
          {
            key: _vm.item.fieldType,
            tag: "component",
            attrs: {
              "update-key": _vm.updateFlag,
              "mce-config-header": _vm.mceConfigHeader,
              "mce-config-text": _vm.mceConfigText,
              "preview-mode": _vm.previewMode
            },
            on: { changed: _vm.handleChange }
          },
          "component",
          _vm.item,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }