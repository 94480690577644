<template>
	<div :id="isInternal ? 'custom-page-editor-internal' : 'custom-page-editor-external'" class="fill-all" v-if="customPage">
		<div class="header-area pl-1 pr-3 row-format align-center">
			<div class="row-format gap-2 align-center pl-2">
				<icon-selector v-model="customPage.icon"></icon-selector>
				<v-text-field
						style="background-color: var(--v-white-base)"
						hide-details
						dense
						outlined
						maxlength="20"
						v-model="customPage.title"
						persistent-placeholder
						label="Title"
				></v-text-field>
				<div style="max-width: 275px" v-if="isInternal">
					<v-select
						style="background-color: var(--v-white-base)"
						:items="layoutOptions"
						v-model="customPage.layoutKey"
						item-text="label"
						item-value="key"
						hide-details
						dense
						outlined
						persistent-placeholder
						label="Screen layout"
					></v-select>
				</div>
				<div style="max-width: 275px" v-else>
					<v-select
							style="background-color: var(--v-white-base)"
							:items="['External','iFrame']"
							v-model="customPage.type"
							hide-details
							dense
							outlined
							persistent-placeholder
							label="Link type"
					></v-select>
				</div>
				<div v-if="isInternal">
					<v-menu :close-on-content-click="false" :close-on-click="true" v-model="restrictMenu">
						<template v-slot:activator="{on}">
							<v-btn v-on="on" outlined style="min-height: 40px" color="gray_50"><span style="color: var(--v-black-base)">Visible to {{restrictLabel}}</span></v-btn>
						</template>
						<div style="background-color: var(--v-white-base); height: calc(100vh - 100px); overflow-y: auto" class="pa-3">
							<v-btn class="secondary-action" @click="restrictMenu = false">OK</v-btn>
							<v-checkbox v-model="customPage.restrictToClients" v-for="client in $store.getters.clients" :value="client.id" :key="client.id" hide-details dense>
								<template v-slot:label>
									<div class="row-format align-center gap-2 ml-n1">
										<client-avatar :client="client" x-small :disable-click="true"></client-avatar>
										<div>{{client.name}}</div>
									</div>
								</template>
							</v-checkbox>
						</div>
					</v-menu>
				</div>
			</div>
			<div class="ml-auto row-format gap-2 align-center">
				<folder-selector v-model="customPage.folder" :folders="folders"></folder-selector>

				<v-switch v-if="isInternal || isIframe"
						style="margin-top: 0px!important"
						v-model="customPage.isDefault"
						hide-details
						dense
						label="Default page"
				></v-switch>

				<v-switch
					style="margin-top: 0px!important"
					v-model="customPage.enabled"
					hide-details
					dense
					label="Enabled"
				></v-switch>


				<v-btn class="primary-action" width="96" @click="save(false)">
					{{ $t('global.save') }}
				</v-btn>
				<v-btn icon @click="save(true)"><v-icon>close</v-icon></v-btn>
			</div>
		</div>
		<div style="width: 100%; height: calc(100% - 56px)" class="pa-3" v-if="isInternal">
			<v-container fluid :key="refreshKey">
				<v-row v-for="(row, index) in page" :key="index" :style="`font-family: ${branding.font}`">
					<v-col
						v-for="col in row"
						:key="'key' + col.contentIndex"
						:cols="col.cols"
						style="border: thin dashed var(--v-gray_30-base)"
						@click="setEditIndex(col.contentIndex)"
					>
						<content-block
							:index="col.contentIndex"
							:content="customPage.content"
							:edit-mode="editIndex === col.contentIndex"
							@updated="setContent(col.contentIndex, $event)"
							:mce-config="mceConfig"
						></content-block>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div v-else class="pa-3">
			<v-text-field hide-details persistent-placeholder dense outlined label="Default external link" placeholder="https://www.domain.com" v-model="customPage.hyperLink"></v-text-field>
			<div class="column-format my-3">
				<div class="row-format gap-2">
					<v-autocomplete :items="$store.getters.clients" hide-details persistent-placeholder  dense outlined label="Client" item-value="id" item-text="name" v-model="newClientId"></v-autocomplete>
					<v-btn color="primary" outlined style="min-height:40px" @click="addClientPage" :disabled="!newClientId">+ Add client specific page</v-btn>
				</div>
				<div style="border-top: 1px solid var(--v-gray_50-base); height: 8px;" class="my-3"></div>
				<div v-for="(clientPage,index) in customPage.clientSpecificHyperLinks" :key="index" class="row-format my-2 gap-2">
					<client-avatar large v-tippy="{content: getClient(clientPage.clientId).name}" :client="getClient(clientPage.clientId)"></client-avatar>
					<v-text-field hide-details persistent-placeholder dense outlined label="External link" placeholder="https://www.domain.com" v-model="clientPage.hyperLink"></v-text-field>
					<v-icon size="20" class="material-symbols-rounded" @click="deleteClientPage(index)">delete</v-icon>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContentBlock from '@/modules/portal/customization/ContentBlock';
	import CustomPageService from '@/modules/portal/customization/CustomPageService';
	import IconSelector from "@/components/IconSelector";
	import ClientAvatar from "@/components/ClientAvatar";
	import FolderSelector from "@/components/FolderSelector";

	export default {
		name: 'CustomPage',

		props: ['branding', 'mceConfig', 'id', 'folders'],

		components: {ClientAvatar, ContentBlock, IconSelector, FolderSelector },

		data: function() {
			return {
				restrictMenu: false,
				newClientId: null,
				customPageService: new CustomPageService(),
				editIndex: -1,
				refreshKey: 0,
				customPage: null,
			};
		},

		mounted() {
			this.getCustomPage();
		},

		beforeDestroy() {},

		methods: {
			getCustomPage: function() {
				this.customPageService.getCustomPage(this.id).then((res) => (this.customPage = res.data));
			},

			getClient: function(id){
				let client = this.$store.state.clientCache.find(c => c.id === id);
				if(!client){
					client = {
						id: id,
						hexColor: '#fff',
						name: '[Unknown]'
					}
				}
				return client;
			},

			addClientPage: function(){
				this.customPage.clientSpecificHyperLinks.push({
					clientId: this.newClientId,
					hyperLink: null
				});
				this.newClientId = null;
			},

			deleteClientPage: function(index){
				this.customPage.clientSpecificHyperLinks.splice(index,1);
			},

			save: function(close = false) {
				this.$store.commit('startLoading');
				this.customPageService.updateCustomPage(this.customPage).then((res) => {
					this.customPage = res.data;
					this.refreshKey++
					if (close) {
						this.$emit('result', this.customPage);
					}
				}).finally(() => this.$store.commit('stopLoading'));
			},

			setEditIndex: function(index) {
				this.editIndex = index;
			},

			setContent: function(index, content) {
				this.customPage.content.splice(index, 1, content);
			},

			colsInRow: function(row, contentIndex) {
				let cols = 12 / row.cols;
				let result = [];
				let i = cols;

				while (i <= 12) {
					result.push({
						cols: cols,
						contentIndex: contentIndex.index++,
					});

					i = i + cols;
				}
				return result;
			},
		},

		watch: {
			layoutKey: function() {
				this.contentIndex = 0;
				this.refreshKey++;
			},
		},

		computed: {
			isInternal: function(){
				return this.customPage && this.customPage.type === 'Internal'
			},

			isIframe: function(){
				return this.customPage && this.customPage.type === 'iFrame'
			},

			layoutOptions: function() {
				let options = [];
				options.push({
					label: 'Single page',
					key: '1',
					layout: [{ cols: 1 }],
				});

				options.push({
					label: 'Side-by-side',
					key: '2',
					layout: [{ cols: 2 }],
				});
				options.push({
					label: 'Header & Two-column',
					key: '1,2',
					layout: [{ cols: 1 }, { cols: 2 }],
				});
				options.push({
					label: 'Header, Two-column, Footer',
					key: '1,2,1',
					layout: [{ cols: 1 }, { cols: 2 }, { cols: 1 }],
				});
				return options;
			},

			currentLayout: function() {
				let result = this.layoutOptions.find((l) => l.key === this.customPage.layoutKey);
				if (!result) {
					result = this.layoutOptions[0];
				}
				return result;
			},

			page: function() {
				let result = [];
				let contentIndex = {
					index: 0,
				};
				for (let i = 0; i < this.currentLayout.layout.length; i++) {
					result.push(this.colsInRow(this.currentLayout.layout[i], contentIndex));
				}
				return result;
			},

			restrictLabel: function(){
				if(this.customPage.restrictToClients.length){
					let clients = [... this.$store.getters.clients];
					clients = clients.filter(c => this.customPage.restrictToClients.includes(c.id));
					if(clients.length === 1){
						return clients[0].name;
					}else{
						let additionalCount = clients.length - 1;
						return `${clients[0].name} & ${additionalCount} additional ${additionalCount > 1 ? 'clients' : 'client'}`
					}
				}else{
					return 'all clients'
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	#custom-page-editor-internal {
		background-color: var(--v-white-base);
		width: 100vw;
		height: 100vh;
	}

	#custom-page-editor-external {
		background-color: var(--v-white-base);
		width: 1000px;
		padding-top: 8px;
	}

	.header-area {
		height: 56px;
		width: 100%;
		background-color: var(--v-gray_10-base);
		border-bottom: 1px solid var(--v-gray_50-base);
		// Needed for sticky positioning
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		z-index: 203;
	}
</style>
