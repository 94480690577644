<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-comment')" @action="navigateToDeliverable()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small :client="client" />
			<div class="ml-2 text-left">{{ deliverableName }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ header }}</div>
			<div class="font-12 text-left">
				<span>"{{ formattedComment }}"</span>
				<v-icon v-if="isCommentLong" x-small class="mx-2" @click.stop="toggleExpand">
					{{ this.expanded ? '$chevronUp' : '$chevronDown' }}
				</v-icon>
				<span v-else class="px-1"> • </span>
				<span>Comment by {{ comment.author }}</span>
				<span class="px-1"> • </span>
				<span>{{ notificationPayload.assignedAt | formatForTimeAgo }}</span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";
	import DeliverableDetail from "@/modules/projects/deliverable/DeliverableDetail";

	export default {
		name: 'DeliverableCommentNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar, NotificationLayout},

		data: function() {
			return {
				deliverableName: this.notificationPayload.deliverableName,
				client: this.notificationPayload.client,
				comment: this.notificationPayload.comment,
				deliverableId: this.notificationPayload.deliverableId,
				projectId: this.notificationPayload.projectId,
				projectName: this.notificationPayload.projectName,
				mentioned: this.notificationPayload.mentioned,
				expanded: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {

			navigateToDeliverable: function() {
				let binding = {
					deliverableId: this.deliverableId,
					project: {
						id: this.projectId,
						name: this.projectName
					},
					client: this.client
				}
				this.$store.state.globalModalController.openModal(DeliverableDetail,binding);
			},

			toggleExpand: function() {
				this.expanded = !this.expanded;
			},
		},

		computed: {
			header: function() {
				if (this.mentioned) {
					return this.$t('notifications.new-deliverable-mention');
				} else {
					return this.$t('notifications.new-deliverable-comment');
				}
			},

			formattedComment: function() {
				let comment = this.comment.comment;

				this.userList.forEach((u) => {
					comment = comment.replaceAll(u.email, `${u.firstName} ${u.lastName}`);
				});

				if (!this.expanded && comment && comment.length > 70) {
					return comment.substr(0, 70) + '...';
				} else {
					return comment;
				}
			},

			isCommentLong: function() {
				if (this.comment.comment) {
					return this.comment.comment.length > 70;
				} else {
					return false;
				}
			},

			userList: function() {
				return this.$store.state.usersForAccount;
			},
		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}

	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
