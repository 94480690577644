var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-3",
      staticStyle: { "background-color": "var(--v-white-base)", width: "500px" }
    },
    [
      _c(
        "div",
        { staticClass: "row-format align-center" },
        [
          _c(
            "div",
            { staticClass: "row-format align-center" },
            [
              _vm.status
                ? _c("div", [
                    _vm._v("Deliverables in: " + _vm._s(_vm.status.label))
                  ])
                : _c("did", [_vm._v("Deliverables")])
            ],
            1
          ),
          _c(
            "v-icon",
            { staticClass: "ml-auto", on: { click: _vm.handleClose } },
            [_vm._v("$close")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3" },
        _vm._l(_vm.filteredList, function(deliverable) {
          return _c("deliverable-loader", {
            key: deliverable.id,
            staticClass: "pointer pl-0 text-left",
            attrs: { event: deliverable },
            nativeOn: {
              click: function($event) {
                return _vm.deliverableClicked(deliverable)
              }
            }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }