<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-comment')" @action="navigateToProposal()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small :client="client" />
			<div class="ml-2">{{ client.name }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ $t('notifications.new-proposal-comment') }}</div>
			<div class="font-12 text-left">
				<span>"{{ formattedComment }}"</span>
				<v-icon v-if="isCommentLong" x-small class="mx-2" @click.stop="toggleExpand">
					{{ this.expanded ? '$chevronUp' : '$chevronDown' }}
				</v-icon>
				<span v-else class="px-1"> • </span>
				<span>Comment by {{ comment.author }}</span>
				<span class="px-1"> • </span>
				<span>{{ notificationPayload.commentedAt | formatForTimeAgo }}</span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from '@/modules/productivity/notifications/NotificationLayout';
	import ProposalBuilder from "@/modules/proposals/ProposalBuilder";

	export default {
		name: 'ProposalCommentNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { NotificationLayout, ClientAvatar },

		data: function () {
			return {
				heading: this.notificationPayload.proposalHeading,
				client: this.notificationPayload.client,
				comment: this.notificationPayload.comment,
				expanded: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			navigateToProposal: function () {
				let binding = {
					proposalId: this.notificationPayload.proposalId,
					clientId: this.client.id,
				}
				this.$store.state.globalModalController.openModal(ProposalBuilder,binding,true,true)
			},

			toggleExpand: function () {
				this.expanded = !this.expanded;
			},
		},

		computed: {
			formattedComment: function () {
				if (!this.expanded && this.comment.comment.length > 70) {
					return this.comment.comment.substr(0, 70) + '...';
				} else {
					return this.comment.comment;
				}
			},

			isCommentLong: function () {
				return this.comment.comment.length > 70;
			},
		},
	};
</script>

<style scoped lang="scss">
</style>
