<template>
	<div class="row-format gap-2 font-14 pointer" v-if="formSubmission" @click="openForm">
		<v-icon class="material-symbols-rounded" color="secondary" size="20" v-tippy="{ content: 'Form submission' }"
			>fact_check</v-icon
		>
		<div>{{ formSubmission.formName }} submitted by {{ submittedBy }}</div>
		<div class="ml-auto font-gray_80 font-12">{{ item.timestamp | formatForTimeAgo }}</div>
	</div>
</template>

<script>
	import FormSubmissionService from '@/modules/discovery/FormSubmissionService';
	import FormSubmissionDetail from '@/modules/discovery/FormSubmissionDetail';

	export default {
		name: 'Form',

		props: ['item'],

		components: {},

		data: function() {
			return {
				formSubmission: null,
				formSubmissionService: new FormSubmissionService(),
			};
		},

		mounted() {
			this.formSubmissionService
				.getFormSubmission(this.item.itemId)
				.then((res) => {
					this.formSubmission = res.data;
					this.$emit('actual-time', this.formSubmission.submittedAt);
				})
				.catch((err) => {
					if (err.response.status === 404) {
						this.$emit('deleted');
					}
				});
		},

		beforeDestroy() {},

		methods: {
			openForm: function() {
				let binding = {
					id: this.formSubmission.id,
				};
				this.$store.state.globalModalController.openModal(FormSubmissionDetail, binding).then((res) => {
					if (res) {
						this.formSubmission = res;
					}
				});
			},
		},

		computed: {
			submittedBy: function() {
				let first = this.formSubmission.formData.firstName;
				let last = this.formSubmission.formData.lastName;
				let email = this.formSubmission.formData.email;
				if (first && last) {
					return first + ' ' + last;
				} else if (first) {
					return first;
				} else if (last) {
					return last;
				} else if (email) {
					return email;
				} else {
					return '?';
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
