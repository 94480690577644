var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "proposal-add-component" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c("div", { staticClass: "modal-title" }, [
          _vm._v(_vm._s(_vm.$t("proposal.content.add")))
        ]),
        _c("div", { staticClass: "section" }, [
          _c("div", { staticClass: "content-heading" }, [
            _vm._v(_vm._s(_vm.$t("proposal.content.content")))
          ]),
          _c("div", { staticClass: "tiles" }, [
            _c(
              "div",
              {
                staticClass: "tile",
                on: {
                  click: function($event) {
                    return _vm.addSection("TextArea")
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "icon-box" },
                  [_c("v-icon", { attrs: { size: "32" } }, [_vm._v("$text")])],
                  1
                ),
                _c("div", {
                  staticClass: "instructions",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("proposal.content.text-block"))
                  }
                })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "tile",
                on: {
                  click: function($event) {
                    return _vm.addSection("Files")
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "icon-box" },
                  [
                    _c("v-icon", { attrs: { size: "32" } }, [
                      _vm._v("$attachment")
                    ])
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "instructions",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("proposal.content.files"))
                  }
                })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "tile",
                on: {
                  click: function($event) {
                    return _vm.addSection("ImageTextBlock")
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "icon-box" },
                  [
                    _c("v-icon", { attrs: { size: "32" } }, [
                      _vm._v("$imageAndText")
                    ])
                  ],
                  1
                ),
                _c("div", {
                  staticClass: "instructions",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("proposal.content.image-caption"))
                  }
                })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "tile",
                on: {
                  click: function($event) {
                    return _vm.addSection("ImageBlock")
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "icon-box" },
                  [_c("v-icon", { attrs: { size: "32" } }, [_vm._v("$image")])],
                  1
                ),
                _c("div", {
                  staticClass: "instructions",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("proposal.content.image"))
                  }
                })
              ]
            )
          ])
        ]),
        _vm.termsCount == 0 ||
        (_vm.paymentCount == 0 && _vm.integratedPaymentsEnabled)
          ? _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "content-heading" }, [
                _vm._v(_vm._s(_vm.$t("proposal.content.legal")))
              ]),
              _c("div", { staticClass: "tiles" }, [
                _vm.termsCount === 0
                  ? _c(
                      "div",
                      {
                        staticClass: "tile",
                        on: {
                          click: function($event) {
                            return _vm.addSection("Terms")
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "icon-box" },
                          [
                            _c("v-icon", { attrs: { size: "32" } }, [
                              _vm._v("$handshake")
                            ])
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "instructions",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("proposal.content.contract")
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm.paymentCount === 0 && _vm.integratedPaymentsEnabled
                  ? _c(
                      "div",
                      {
                        staticClass: "tile",
                        on: {
                          click: function($event) {
                            return _vm.addSection("PaymentCaptureBlock")
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "icon-box" },
                          [
                            _c("v-icon", { attrs: { size: "32" } }, [
                              _vm._v("$money")
                            ])
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "instructions",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("proposal.content.payment-instructions")
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        !_vm.signatureCount && !_vm.acceptCount
          ? _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "content-heading" }, [
                _vm._v(_vm._s(_vm.$t("proposal.content.finalize")))
              ]),
              _c("div", { staticClass: "tiles" }, [
                _c(
                  "div",
                  {
                    staticClass: "tile",
                    on: {
                      click: function($event) {
                        return _vm.addSection("SignatureBlock")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon-box" },
                      [
                        _c("v-icon", { attrs: { size: "32" } }, [
                          _vm._v("$signature")
                        ])
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "instructions",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("proposal.content.signatures"))
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tile",
                    on: {
                      click: function($event) {
                        return _vm.addSection("AcceptBlock")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon-box" },
                      [
                        _c("v-icon", { attrs: { size: "32" } }, [
                          _vm._v("$success")
                        ])
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "instructions",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("proposal.content.accept"))
                      }
                    })
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm.isPaid && _vm.packageList.length && !_vm.gettingPackages
          ? _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "content-heading" }, [
                _vm._v(_vm._s(_vm.$t("proposal.content.fee-structure")))
              ]),
              _c(
                "div",
                { staticClass: "tiles packages" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tile",
                      on: {
                        click: function($event) {
                          return _vm.addSection("Deliverables")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "icon-box" },
                        [
                          _c("v-icon", { attrs: { size: "24" } }, [
                            _vm._v("$plus")
                          ])
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "instructions",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("proposal.content.from-scratch")
                          )
                        }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "content-heading copy-fees" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("proposal.content.copy-deliverable-packages")
                        ) +
                        " "
                    )
                  ]),
                  _vm._l(_vm.packageList, function(pkg, i) {
                    return _c(
                      "div",
                      {
                        key: pkg.id,
                        class: _vm.packageClass(i),
                        on: {
                          click: function($event) {
                            return _vm.addSectionFromPackage(pkg.id)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "icon-box" },
                          [
                            _c("v-icon", { attrs: { size: "24" } }, [
                              _vm._v("$box")
                            ])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "instructions" }, [
                          _vm._v(_vm._s(pkg.name))
                        ])
                      ]
                    )
                  }),
                  _vm.packageList.length > 3
                    ? _c(
                        "div",
                        {
                          staticClass: "tile",
                          on: {
                            click: function($event) {
                              _vm.showAllPackages = !_vm.showAllPackages
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "icon-box" },
                            [
                              _c("v-icon", { attrs: { size: "24" } }, [
                                _vm._v("$more-hor")
                              ])
                            ],
                            1
                          ),
                          _vm.showAllPackages
                            ? _c("div", { staticClass: "instructions" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("proposal.content.see-less"))
                                )
                              ])
                            : _c("div", { staticClass: "instructions" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("proposal.content.see-more"))
                                )
                              ])
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          : _c("div", { staticClass: "section" }, [
              _c("div", { staticClass: "content-heading" }, [
                _vm._v(_vm._s(_vm.$t("proposal.content.fee-structure")))
              ]),
              _c("div", { staticClass: "tiles" }, [
                _c(
                  "div",
                  {
                    staticClass: "tile",
                    on: {
                      click: function($event) {
                        return _vm.addSection("Deliverables")
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon-box" },
                      [
                        _c("v-icon", { attrs: { size: "24" } }, [
                          _vm._v("$box")
                        ])
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass: "instructions",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("proposal.content.from-scratch")
                        )
                      }
                    })
                  ]
                )
              ])
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }