<template>
	<div style="width:350px;">
		<input id="copyLink" name="copyLink" type="hidden" />
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="modal-title">Share a {{ type }} link</div>
			<div class="font-12 font-gray_70 text-left">
				Using the custom link from this screen will automatically associate the {{ type }} with this opportunity.
			</div>
			<table style="width:100%" class="text-left mt-2">
				<tbody>
					<tr v-for="link in links" :key="link.id">
						<td>{{ link.name }}</td>
						<td style="width:30px">
							<v-icon @click="copyFullUrl(link.id)" color="primary" class="pointer">content_copy</v-icon>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
	import AccountService from '@/modules/account/AccountService';
	import MeetingService from '@/modules/meetings/MeetingService';
	import DiscoveryTemplateService from "@/modules/discovery/DiscoveryTemplateService";

	export default {
		name: 'MeetingsAndForms',

		props: ['opportunity', 'type'],

		components: {},

		data: function() {
			return {
				baseUrl: process.env.VUE_APP_MEET_LINK,
				uniqueAccountUrl: null,
				meetingService: new MeetingService(),
				discoveryService: new DiscoveryTemplateService(),
				links: [],
			};
		},

		mounted() {
			this.getUniqueUrl();
			if (this.type === 'Meeting') {
				this.getMeetings();
			}else if(this.type === 'Form'){
				this.getForms();
			}
		},

		beforeDestroy() {},

		methods: {
			getUniqueUrl: function() {
				let accountService = new AccountService();
				accountService.getAccountUniqueUrl().then((res) => (this.uniqueAccountUrl = res.data));
			},

			copyFullUrl: function(partialUrl) {
				let fullLink = this.accountBaseLink + partialUrl + `?opportunityId=${this.opportunity.id}`;
				let copyLink = document.getElementById('copyLink');
				copyLink.type = 'text';
				copyLink.value = fullLink;
				copyLink.select();
				document.execCommand('copy');
				copyLink.type = 'hidden';

				this.$store.commit('success', 'Link copied to clipboard');
			},

			getForms: function(){
				this.discoveryService.getDiscoveryTemplateList().then((res) => {
					this.links.splice(0, this.links.length);
					res.data.forEach((m) => {
						this.links.push({
							id: m.uniqueTemplateId,
							name: m.name,
						});
					});
				});
			},

			getMeetings: function() {
				this.meetingService.getMeetingList().then((res) => {
					this.links.splice(0, this.links.length);
					res.data.forEach((m) => {
						this.links.push({
							id: m.uniqueMeetingId,
							name: m.name,
						});
					});
				});
			},
		},

		computed: {
			accountBaseLink: function(){
				let commPref = this.$store.state.communicationPreferences;
				if(commPref && commPref.primaryDomain){
					return 'https://' + commPref.primaryDomain + '/public/';
				}else {
					return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' ;
				}
			},

			podId: function() {
				let podId = this.$store.getters.getAccount.pod.podId;
				return podId.replace(/\D/g, '');
			},
		},
	};
</script>

<style scoped lang="scss"></style>
