<template>
	<div class="column-format text-left">
		<div>Columns</div>
		<v-slider
			v-model="element.columnCount"
			:min="1"
			:max="3"
			:step="1"
			hide-details
			dense
			show-ticks="always"
			tick-size="4"
			:tick-labels="['1', '2', '3', '4']"
			@input="updated"
			thumb-label
		></v-slider>

		<div class="mt-5">Min Height (px)</div>
		<v-slider
			v-model="element.minHeight"
			:min="40"
			:max="2048"
			:step="1"
			hide-details
			dense
			@input="updated"
			thumb-label
		></v-slider>

		<div class="mt-3">Background color</div>
		<color-selector v-model="element.backgroundColor" @input="updated"></color-selector>
		<div class="mt-3">Background image</div>
		<div
			:style="
				`border-radius: 4px; min-width:100%: min-height: 20px; background-image: url(${element.backgroundImageUrl}); border: 1px solid var(--v-gray_20-base)`
			"
			class="pointer row-format centered"
		>
			<v-btn v-if="element.backgroundImageUrl" class="secondary-action" icon x-small @click.stop="setCustomImage(null)">
				<v-icon size="16">$delete</v-icon>
			</v-btn>
			<div v-else>&nbsp;</div>
		</div>
		<v-select
			class="mt-3"
			v-model="element.backgroundImageSize"
			:items="['auto', 'cover', 'contain']"
			hide-details
			dense
			outlined
			label="Image size"
			@change="updated"
		></v-select>
		<div class="row-format mt-3">
			<div>Image library</div>
			<div class="ml-auto pointer font-primary row-format align-center" @click="imageSearch=true">
				<v-icon small color="primary">search</v-icon>
				<div>Search</div>
			</div>
		</div>

		<div class="image-grid">
			<div
				v-for="result in imageAssets"
				:key="result.id"
				:style="`background-image: url(${result.thumbnail});`"
				class="pointer image-container custom-image-container"
				@click="setCustomImage(result)"
			>
				<v-btn class="secondary-action" icon x-small @click.stop="confirmDelete(result)"
					><v-icon size="16">$delete</v-icon></v-btn
				>
			</div>

			<vue-dropzone
				ref="fileDropzone"
				id="dropzone"
				class="image-container no-image-container"
				v-on:vdropzone-success="uploadSuccess"
				v-on:vdropzone-error="uploadFailure"
				v-on:vdropzone-sending="addFileUploadAuthHeader"
				:use-custom-slot="true"
				:options="dropzoneOptions"
			>
				<div>
					<v-icon>$vuetify.icons.upload</v-icon>
					<div class="font-12">{{ $t('proposal.cover.upload') }}</div>
				</div>
			</vue-dropzone>
		</div>

		<div class="mt-4 column-format gap-4 text-left">
			<div
				class="px-4 pt-2"
				v-for="index in element.columnCount"
				:key="index"
				style="border: 1px solid var(--v-gray_30-base); border-radius: 4px"
			>
				<span class="font-14 brand-medium">Column {{ index }}</span>
				<div class="row-format gap-1 mb-n3">
					<v-text-field
						type="number"
						min="0"
						max="100"
						v-model="element.columns[index - 1].pl"
						label="Padding left"
						@change="updated"
					></v-text-field>
					<v-text-field
						type="number"
						min="0"
						max="100"
						v-model="element.columns[index - 1].pr"
						label="Padding right"
						@change="updated"
					></v-text-field>
				</div>
				<div class="row-format gap-1 mb-n2">
					<v-text-field
						type="number"
						min="0"
						max="100"
						v-model="element.columns[index - 1].pt"
						label="Padding top"
						@change="updated"
					></v-text-field>
					<v-text-field
						type="number"
						min="0"
						max="100"
						v-model="element.columns[index - 1].pb"
						label="Padding bottom"
						@change="updated"
					></v-text-field>
				</div>
			</div>
		</div>

		<basic-modal v-if="imageSearch" :persistent="false" :dialog="imageSearch" style="background-color: var(--v-white-base); min-width: 300px;" @close="imageSearch=false">
			<image-search @close="imageSearch=false" @result="setUnsplashImage($event)"></image-search>
		</basic-modal>
	</div>
</template>

<script>
	import vueDropzone from 'vue2-dropzone';
	import ImageMixin from '@/modules/agreements/config/ImageMixin';
	import BasicModal from '@/components/BasicModal';
	import ImageSearch from '@/modules/agreements/config/ImageSearch';
	import ColorSelector from "@/components/ColorSelector";

	export default {
		name: 'Container',

		props: ['agreement', 'item'],

		mixins: [ImageMixin],

		components: { ImageSearch, BasicModal, vueDropzone, ColorSelector },

		data: function() {
			return {
				imageSearch: false,
				colorMenu: false,
				element: { ...this.item },
			};
		},

		mounted() {
			this.getImageAssets();
		},

		beforeDestroy() {},

		methods: {
			setUnsplashImage: function(url){
				this.element.backgroundImageUrl = url;
				this.imageSearch = false;
				this.updated();
			},

			setCustomImage: function(imageAsset) {
				if (imageAsset) {
					this.element.backgroundImageUrl = imageAsset.fullSize;
				} else {
					this.element.backgroundImageUrl = null;
				}
				this.updated();
			},

			updated: function() {
				for (let i = 0; i <= this.element.columnCount; i++) {
					if (this.element.columns[i] === undefined) {
						this.element.columns.splice(i, 0, { pt: 0, pb: 0, pr: 0, pl: 0, items: [] });
					}
				}
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.image-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 6px;

		.image-container {
			//width: 100%;
			height: 100px;
			width: 100px;
			border-radius: 2px;
			display: flex;
			align-items: flex-end;
			background-position: center;

			&.custom-image-container {
				align-items: flex-start;
				justify-content: flex-end;
				padding: 4px;

				.v-btn {
					opacity: 0;
					transition: opacity 300ms;
				}

				&:hover {
					.v-btn {
						opacity: 1;
						transition: opacity 300ms;
					}
				}
			}
		}
	}
</style>
