var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Dashboard" }
            ]
          }
        },
        [_vm._v(" " + _vm._s(_vm.pageName) + " ")]
      ),
      _c(
        "div",
        [
          _c("focus", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentView === "focus",
                expression: "currentView === 'focus'"
              }
            ],
            key: "focus-" + _vm.refreshKey,
            ref: "Focus",
            attrs: {
              "full-user": _vm.$store.getters.hasFeatureAccess("any"),
              "is-visible": _vm.currentView === "focus"
            }
          }),
          _c("calendar", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentView === "calendar",
                expression: "currentView === 'calendar'"
              }
            ],
            key: "calendar-" + _vm.refreshKey,
            ref: "Calendar",
            attrs: { "is-visible": _vm.currentView === "calendar" },
            on: {
              "set-view": function($event) {
                return _vm.setView($event)
              }
            }
          }),
          _vm.hasInsightsAccess
            ? _c("insights", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentView === "insights",
                    expression: "currentView === 'insights'"
                  }
                ],
                key: "insights-" + _vm.refreshKey,
                attrs: { "is-visible": _vm.currentView === "insights" }
              })
            : _vm._e(),
          _vm.hasInsightsAccess
            ? _c("bookings", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentView === "bookings",
                    expression: "currentView === 'bookings'"
                  }
                ],
                key: "bookings-" + _vm.refreshKey,
                attrs: { "is-visible": _vm.currentView === "bookings" }
              })
            : _vm._e(),
          _c("executive-summary", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentView === "summary",
                expression: "currentView === 'summary'"
              }
            ],
            key: "summary-" + _vm.refreshKey,
            attrs: { "is-visible": _vm.currentView === "summary" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }