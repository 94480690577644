var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-2 py-3" },
    [
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding top"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pt,
              callback: function($$v) {
                _vm.$set(_vm.element, "pt", $$v)
              },
              expression: "element.pt"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding bottom"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pb,
              callback: function($$v) {
                _vm.$set(_vm.element, "pb", $$v)
              },
              expression: "element.pb"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding left"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pl,
              callback: function($$v) {
                _vm.$set(_vm.element, "pl", $$v)
              },
              expression: "element.pl"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding right"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pr,
              callback: function($$v) {
                _vm.$set(_vm.element, "pr", $$v)
              },
              expression: "element.pr"
            }
          })
        ],
        1
      ),
      _c("v-select", {
        attrs: {
          items: ["X-Small", "Small", "Normal", "Large", "X-Large"],
          "hide-details": "",
          outlined: "",
          dense: "",
          "persistent-placeholder": "",
          label: "Button size"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.buttonSize,
          callback: function($$v) {
            _vm.$set(_vm.element, "buttonSize", $$v)
          },
          expression: "element.buttonSize"
        }
      }),
      _c("v-select", {
        attrs: {
          items: _vm.alignment,
          "item-text": "label",
          "item-value": "value",
          "hide-details": "",
          dense: "",
          outlined: "",
          "persistent-placeholder": "",
          label: "Button alignment"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.buttonAlignment,
          callback: function($$v) {
            _vm.$set(_vm.element, "buttonAlignment", $$v)
          },
          expression: "element.buttonAlignment"
        }
      }),
      _c(
        "div",
        { staticClass: "column-format" },
        [
          _c("div", { staticClass: "font-12 text-left font-gray_70" }, [
            _vm._v("Button color")
          ]),
          _c("color-selector", {
            on: { input: _vm.updated },
            model: {
              value: _vm.element.buttonColor,
              callback: function($$v) {
                _vm.$set(_vm.element, "buttonColor", $$v)
              },
              expression: "element.buttonColor"
            }
          })
        ],
        1
      ),
      _c("hr", { staticClass: "my-2" }),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Border width"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.borderWidth,
              callback: function($$v) {
                _vm.$set(_vm.element, "borderWidth", $$v)
              },
              expression: "element.borderWidth"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Border radius"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.borderRadius,
              callback: function($$v) {
                _vm.$set(_vm.element, "borderRadius", $$v)
              },
              expression: "element.borderRadius"
            }
          })
        ],
        1
      ),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          type: "number",
          label: "Inner padding"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.padding,
          callback: function($$v) {
            _vm.$set(_vm.element, "padding", $$v)
          },
          expression: "element.padding"
        }
      }),
      _c("div", { staticClass: "row-format text-left gap-1" }, [
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", [_vm._v("Background")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.element.backgroundColor,
                callback: function($$v) {
                  _vm.$set(_vm.element, "backgroundColor", $$v)
                },
                expression: "element.backgroundColor"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", [_vm._v("Border")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.element.borderColor,
                callback: function($$v) {
                  _vm.$set(_vm.element, "borderColor", $$v)
                },
                expression: "element.borderColor"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", [_vm._v("Table")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.element.tableBorderColor,
                callback: function($$v) {
                  _vm.$set(_vm.element, "tableBorderColor", $$v)
                },
                expression: "element.tableBorderColor"
              }
            })
          ],
          1
        )
      ]),
      _c("hr", { staticClass: "my-2" }),
      _c("v-select", {
        attrs: {
          disabled: _vm.element.packages.length < 2,
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Can select...",
          items: _vm.selectTypes,
          "item-value": "value",
          "item-text": "label"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.selectionType,
          callback: function($$v) {
            _vm.$set(_vm.element, "selectionType", $$v)
          },
          expression: "element.selectionType"
        }
      }),
      _c("v-select", {
        attrs: {
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Required",
          items: _vm.required,
          "item-value": "value",
          "item-text": "label"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.selectionRequired,
          callback: function($$v) {
            _vm.$set(_vm.element, "selectionRequired", $$v)
          },
          expression: "element.selectionRequired"
        }
      }),
      _c("v-select", {
        attrs: {
          disabled: _vm.element.packages.length < 2,
          "hide-details": "",
          dense: "",
          "persistent-placeholder": "",
          outlined: "",
          label: "Layout",
          items: _vm.layout,
          "item-value": "value",
          "item-text": "label"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.stacked,
          callback: function($$v) {
            _vm.$set(_vm.element, "stacked", $$v)
          },
          expression: "element.stacked"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }