<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-answers') " @action="openFormSubmission()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small v-if="client" :client="client" />
			<div class="ml-2">{{ formSubmission.formName }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ $t('notifications.form-submission') }}</div>
			<div class="font-12">
				<span>{{name}} responded {{ notificationPayload.completedAt | formatForTimeAgo }} </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";
	import FormSubmissionDetail from "@/modules/discovery/FormSubmissionDetail";

	export default {
		name: 'FormSubmissionNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar, NotificationLayout},

		data: function() {
			return {
				client: this.notificationPayload.client,
				formSubmission: this.notificationPayload.formSubmission,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			openFormSubmission: function(){
				this.$store.state.globalModalController.openModal(FormSubmissionDetail,{id:this.formSubmission.id});
			},
		},

		computed: {
			name: function(){
				let formData = this.formSubmission.formData;
				if(formData.firstName || formData.lastName){
					return formData.firstName + ' ' + formData.lastName;
				}else{
					return formData.email;
				}
			}
		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}
</style>
