var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pa-6",
      staticStyle: {
        "min-width": "calc(100vw - 200px)",
        "min-height": "calc(100vh - 200px)"
      }
    },
    [
      _c(
        "div",
        { staticClass: "row-format" },
        [
          !_vm.errorState ? _c("div", [_vm._v("New email")]) : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto",
              attrs: { icon: "" },
              on: { click: _vm.cancel }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          )
        ],
        1
      ),
      _vm.showEditor && _vm.isReady
        ? _c("email-editor", {
            attrs: {
              "email-boxes": _vm.emailBoxes,
              "to-list": _vm.toList,
              "show-subject": true,
              "opportunity-id": _vm.opportunityId,
              "client-id": _vm.clientId
            },
            on: {
              "send-email": function($event) {
                return _vm.sendEmail($event)
              },
              cancel: function($event) {
                return _vm.cancel()
              }
            }
          })
        : _vm._e(),
      _vm.errorState
        ? _c(
            "div",
            {
              staticClass: "text-center column-format",
              staticStyle: { gap: "8px" }
            },
            [
              _c(
                "div",
                { staticClass: "row-format centered" },
                [
                  _c("v-icon", { attrs: { color: "error" } }, [
                    _vm._v("$alert")
                  ]),
                  _c("div", { staticClass: "brand-medium" }, [
                    _vm._v("No communicator mailbox connected")
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "font-14" }, [
                _vm._v(
                  "You will need to connect an email account in communicator to use this feature. Would you like to do that now?"
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "row-format centered mt-4",
                  staticStyle: { gap: "8px" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "super-action",
                      on: {
                        click: function($event) {
                          return _vm.routeToConfig()
                        }
                      }
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary-action",
                      on: {
                        click: function($event) {
                          return _vm.$emit("result")
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }