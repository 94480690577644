<template>
	<div class="fill-all pa-6">
		<div class="column-format flex-grow-1 centered">
			<v-form v-model="isValid" ref="UserInfoForm">
				<div class="font-16 font-gray_80 mt-2 mb-6">
					Tell us a bit about your business so we can set up your new workspace.
				</div>
				<v-text-field
					hide-details
					persistent-placeholder
					class="h-outline"
					label="What is your business called?"
					v-model="businessName"
					:placeholder="`E.g. Designs, Inc.`"
					:rules="[(v) => !!v || 'Required']"
				></v-text-field>
				<v-autocomplete
					hide-details
					persistent-placeholder
					class="h-outline"
					label="Where are you based?"
					:items="countriesSimple"
					item-text="name"
					item-value="code"
					v-model="country"
					:rules="[(v) => !!v || 'Required']"
				>
				</v-autocomplete>
				<v-autocomplete
					hide-details
					persistent-placeholder
					class="h-outline"
					label="What is your home currency?"
					:items="currencies"
					item-text="label"
					item-value="value"
					v-model="currency"
					:rules="[(v) => !!v || 'Required']"
				>
				</v-autocomplete>
				<div class="row-format centered mt-6 gap-2">
					<v-btn class="primary-action" @click="$emit('result')">Cancel</v-btn>
					<v-btn class="super-action" width="250" @click="next()" :disabled="!isValid">Continue</v-btn>
				</div>

			</v-form>
		</div>
	</div>
</template>

<script>
	import currencies from '@/assets/data/currencies.json';
	import countriesSimple from '@/assets/data/countries-simple.json';

	export default {
		name: 'NewWorkspace',

		props: [],

		components: {},

		data: function() {
			return {
				businessName: null,
				currency: null,
				country: null,
				currencies: currencies,
				countriesSimple: countriesSimple,
				isValid: false,
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'new-workspace' });
		},

		beforeDestroy() {},

		methods: {
			next: function() {
				this.$emit('result', {
					businessName: this.businessName,
					currency: this.currency,
					country: this.country,
				});
			},
		},

		computed: {},

		watch: {},
	};
</script>

<style scoped lang="scss"></style>
