var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c(
        "v-container",
        _vm._l(_vm.signers, function(signer) {
          return _c(
            "v-row",
            {
              key: signer.id,
              staticClass: "font-14 text-left py-3",
              staticStyle: {
                "border-bottom": "1px solid var(--v-gray_30-base)"
              }
            },
            [
              _c(
                "v-col",
                { staticClass: "brand-medium", attrs: { cols: "4" } },
                [_vm._v("Name:")]
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _vm._v(_vm._s(signer.firstName) + " " + _vm._s(signer.lastName))
              ]),
              _c(
                "v-col",
                { staticClass: "brand-medium", attrs: { cols: "4" } },
                [_vm._v("Title:")]
              ),
              _c(
                "v-col",
                {
                  attrs: { cols: "8", contenteditable: "true" },
                  on: {
                    input: function($event) {
                      return _vm.titleUpdated(signer, $event)
                    }
                  }
                },
                [_vm._v(_vm._s(signer.initialTitle))]
              ),
              _c(
                "v-col",
                { staticClass: "brand-medium", attrs: { cols: "4" } },
                [_vm._v("Email:")]
              ),
              _c("v-col", { attrs: { cols: "8" } }, [
                _vm._v(_vm._s(signer.email))
              ]),
              _c(
                "v-col",
                { staticClass: "brand-medium", attrs: { cols: "4" } },
                [_vm._v("Signer for:")]
              ),
              _c(
                "v-col",
                { staticClass: "row-format", attrs: { cols: "8" } },
                [
                  _c("div", [_vm._v(_vm._s(_vm.signerFor(signer.signerType)))]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-symbols-rounded pointer ml-auto",
                      attrs: { size: "20" },
                      on: {
                        click: function($event) {
                          return _vm.removeSigner(signer)
                        }
                      }
                    },
                    [_vm._v("delete")]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "column-format gap-2 mt-3" },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-click": "",
                "close-on-content-click": "",
                "nudge-bottom": "10"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ staticClass: "secondary-action" }, on),
                        [
                          _vm._v(
                            "Add " +
                              _vm._s(_vm.$store.state.podAccount.accountName) +
                              " signer"
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "div",
                { staticClass: "more-menu" },
                _vm._l(_vm.internalUsers, function(user) {
                  return _c(
                    "div",
                    {
                      key: user.id,
                      staticClass: "more-menu-item",
                      on: {
                        click: function($event) {
                          return _vm.addInternalUser(user)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(user.firstName) +
                          " " +
                          _vm._s(user.lastName) +
                          " "
                      )
                    ]
                  )
                }),
                0
              )
            ]
          ),
          !_vm.templateMode
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-click": "",
                    "close-on-content-click": "",
                    "nudge-bottom": "10"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g({ staticClass: "super-action" }, on),
                              [
                                _vm._v(
                                  "Add " +
                                    _vm._s(_vm.agreement.client.name) +
                                    " signer"
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3364986355
                  )
                },
                [
                  _c(
                    "div",
                    { staticClass: "more-menu" },
                    [
                      _vm._l(_vm.clientUsers, function(contact) {
                        return _c(
                          "div",
                          {
                            key: contact.id,
                            staticClass: "more-menu-item",
                            on: {
                              click: function($event) {
                                return _vm.addClientContact(contact)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(contact.firstName) +
                                " " +
                                _vm._s(contact.lastName) +
                                " "
                            )
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "more-menu-item",
                          on: { click: _vm.addNewContact }
                        },
                        [_vm._v("+ Add new contact")]
                      )
                    ],
                    2
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-format pt-1 pb-4 text-left" }, [
      _c("div", { staticClass: "brand-bold font-18" }, [_vm._v("Signers")]),
      _c("div", { staticClass: "font-14 font-gray_80" }, [
        _vm._v("Individuals who will sign or accept the document")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }