<template>
	<v-menu :close-on-click="true" :nudge-bottom="30">
		<template v-slot:activator="scope">
			<v-btn icon class="ml-1 menu-activator" width="28" v-on="scope.on">
				<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
			</v-btn>
		</template>
		<div class="more-menu no">
			<div class="more-menu-item nowrap" v-if="isUserActive" @click="$emit('revoke-account')">
				Revoke account access
			</div>
			<div class="more-menu-item nowrap" v-if="showManageFeatures" @click="$emit('manage-features')">
				Manage feature access
			</div>
			<div class="more-menu-item nowrap" v-if="showManageProjects" @click="$emit('manage-projects')">
				Manage project access
			</div>
			<div class="more-menu-item nowrap" v-if="!isUserActive" @click="$emit('revoke-invite')">
				Revoke invitation
			</div>
		</div>
	</v-menu>
</template>

<script>

	export default {
		name: 'UserActionMenu',

		components: {
		},

		emits: ['revoke-account','manage-projects','revoke-invite'],
		props: ['user'],

		data: function() {
			return {
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {

		},

		computed: {
			isUserActive: function(){
				if(this.user.active){
					return true;
				}else{
					return false;
				}
			},

			showManageFeatures: function(){
				if(this.user.active && this.user.userType === 'RESTRICTED_USER'){
					return true;
				}else{
					return false;
				}
			},

			showManageProjects: function(){
				if(this.user.active && this.user.userType === 'COLLABORATOR'){
					return true;
				}else{
					return false;
				}
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
</style>
