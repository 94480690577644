var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left font-14 column-format" },
    [
      _c("div", { staticClass: "signature-line row-format centered" }, [
        !_vm.isSigned && _vm.isCurrentUser
          ? _c(
              "div",
              {
                staticClass: "sign column-format centered pointer",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.doSign.apply(null, arguments)
                  }
                }
              },
              [
                _c("div", [_vm._v("Sign")]),
                _c("v-icon", { attrs: { color: "black", size: "18" } }, [
                  _vm._v("arrow_downward")
                ])
              ],
              1
            )
          : !_vm.isSigned && !_vm.isCurrentUser
          ? _c(
              "div",
              { staticClass: "no-sign column-format centered" },
              [
                _c("v-icon", { attrs: { color: "#bec4ca", size: "18" } }, [
                  _vm._v("block")
                ])
              ],
              1
            )
          : _vm.isSigned
          ? _c("div", { staticClass: "column-format align-center" }, [
              _vm.signer.signatureType === "Image"
                ? _c("div", { staticStyle: { height: "100%" } }, [
                    _c("img", {
                      staticStyle: {
                        height: "70px",
                        "max-height": "70px",
                        padding: "8px"
                      },
                      attrs: { src: _vm.signer.signature, alt: "signature" }
                    })
                  ])
                : _c(
                    "div",
                    {
                      style:
                        "font-family: " + _vm.signer.font + "; font-size:40px"
                    },
                    [_vm._v(" " + _vm._s(_vm.signer.signature) + " ")]
                  ),
              _c("div", { staticClass: "font-12" }, [
                _vm._v(
                  _vm._s(
                    _vm.DateTime.fromISO(_vm.signer.timestamp).toLocaleString(
                      _vm.DateTime.DATETIME_SHORT
                    )
                  )
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticStyle: { "font-weight": "500" } }, [
        _vm._v(
          " " +
            _vm._s(_vm.signer.firstName) +
            " " +
            _vm._s(_vm.signer.lastName) +
            " "
        )
      ]),
      _c("div", { staticClass: "font-12" }, [_vm._v(_vm._s(_vm.signingFor))]),
      _c("div", { staticClass: "font-12" }, [
        _vm._v(_vm._s(_vm.signer.title ? _vm.signer.title : " "))
      ]),
      _vm.agreement.account.enableGeoLocationOnSignature &&
      _vm.signer.ip &&
      _vm.signer.ip.ip
        ? _c("div", { staticClass: "font-12 row-format align-center gap-1" }, [
            _c("img", {
              attrs: { src: _vm.signer.ip.countryFlag, width: "15px" }
            }),
            _c("div", [
              _vm._v(
                _vm._s(_vm.signer.ip.ip) +
                  " - " +
                  _vm._s(_vm.signer.ip.city) +
                  ", " +
                  _vm._s(_vm.signer.ip.region) +
                  ", " +
                  _vm._s(_vm.signer.ip.country)
              )
            ])
          ])
        : _vm._e(),
      _c(
        "basic-modal",
        { attrs: { dialog: _vm.signDialog } },
        [
          _c("sign-dialog", {
            attrs: { signer: _vm.signer },
            on: {
              cancel: function($event) {
                _vm.signDialog = false
              },
              signed: _vm.handleSigned
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }