var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            disabled: _vm.disabled,
            "min-width": "290px"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _vm.minimal
                        ? _c(
                            "div",
                            { staticClass: "pointer" },
                            [
                              _vm.dateFormattedMinimal
                                ? _c(
                                    "div",
                                    {
                                      class: _vm.contentClass,
                                      staticStyle: { "white-space": "nowrap" }
                                    },
                                    [_vm._v(_vm._s(_vm.dateFormattedMinimal))]
                                  )
                                : _c(
                                    "v-icon",
                                    {
                                      staticClass: "material-symbols-rounded",
                                      attrs: { size: "20" }
                                    },
                                    [_vm._v("event")]
                                  )
                            ],
                            1
                          )
                        : _vm.micro
                        ? _c(
                            "div",
                            {
                              class:
                                "pointer " +
                                (_vm.microFont ? _vm.microFont : "font-12")
                            },
                            [
                              _vm.dateFormattedMinimal
                                ? _c(
                                    "div",
                                    {
                                      class: _vm.contentClass,
                                      staticStyle: { "white-space": "nowrap" }
                                    },
                                    [_vm._v(_vm._s(_vm.dateFormattedMinimal))]
                                  )
                                : _c("div", {
                                    domProps: { innerHTML: _vm._s(_vm.label) }
                                  })
                            ]
                          )
                        : _vm.standard
                        ? _c("v-text-field", {
                            class:
                              (_vm.contentClass ? _vm.contentClass : "") +
                              " pointer",
                            attrs: {
                              value: _vm.dateFormatted,
                              readonly: "",
                              label: _vm.label,
                              disabled: _vm.disabled,
                              "hide-details": "",
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": _vm.label
                                ? true
                                : false,
                              "append-icon": "event"
                            }
                          })
                        : _c("v-text-field", {
                            class:
                              (_vm.contentClass ? _vm.contentClass : "") +
                              " h-outline pointer",
                            attrs: {
                              value: _vm.dateFormatted,
                              readonly: "",
                              label: _vm.label,
                              disabled: _vm.disabled,
                              "hide-details": "",
                              "persistent-placeholder": _vm.label
                                ? true
                                : false,
                              "append-icon": "event"
                            }
                          })
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dateMenu,
            callback: function($$v) {
              _vm.dateMenu = $$v
            },
            expression: "dateMenu"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "max-width": "300px" } },
            [
              _c(
                "v-date-picker",
                {
                  attrs: {
                    "first-day-of-week": _vm.$store.getters.firstDayOfWeek,
                    "no-title": "",
                    scrollable: ""
                  },
                  on: { change: _vm.dateUpdated },
                  model: {
                    value: _vm.localDate,
                    callback: function($$v) {
                      _vm.localDate = $$v
                    },
                    expression: "localDate"
                  }
                },
                [
                  _c("template", { slot: "default" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row-format centered",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-wrapper font-14 font-primary py-1 px-2 pointer",
                            on: {
                              click: function($event) {
                                _vm.localDate = null
                                _vm.dateUpdated()
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("global.clear")) + " ")]
                        )
                      ]
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }