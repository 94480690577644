var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "notification-layout",
    {
      attrs: {
        id: _vm.id,
        "action-text": _vm.$t("notifications.view-deliverable"),
        "sent-at": _vm.sentAt
      },
      on: {
        action: function($event) {
          return _vm.navigateToDeliverable()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("client-avatar", { attrs: { "x-small": "", client: _vm.client } }),
          _c("div", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.projectName))])
        ],
        1
      ),
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "font-14 mb-1 brand-medium" }, [
          _vm._v(_vm._s(_vm.$t("notifications.deliverable-assigned")))
        ]),
        _c("div", { staticClass: "font-12 text-left" }, [
          _c("span", [_vm._v(' "' + _vm._s(_vm.deliverableName) + '" ')]),
          _c("span", { staticClass: "px-1" }, [_vm._v(" • ")]),
          _c("span", [_vm._v(" Assigned by " + _vm._s(_vm.assignedBy) + " ")])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }