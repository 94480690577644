<template>
	<div class="text-input">
		<v-form @submit.prevent v-model="isValid" lazy-validation ref="form">
			<label v-html="htmlText" class="question"></label>
			<v-file-input class="file-upload" :disabled="previewMode" hide-details dense outlined multiple append-icon="attach_file" v-model="files">
				<template v-slot:selection="{ text }">
					<v-chip small label color="primary">
						{{ text }}
					</v-chip>
				</template>
			</v-file-input>
		</v-form>
	</div>
</template>

<script>
	export default {
		name: 'DateInput',
		components: {},
		props: ['htmlHeader', 'options', 'htmlText', 'fieldType', 'previewMode', 'required'],

		mounted() {},

		data() {
			return {
				ourDate: null,
				isValid: false,
				wasValidated: false,
				files: [],
			};
		},

		methods: {
			validate: function() {
				this.wasValidated = true;
				if (this.required && !this.files.length) {
					return false;
				} else {
					return true;
				}
			},

			setDate: function(date) {
				this.ourDate = date;
				this.$emit('input', date);
			},
		},

		computed: {
			placeholder: function() {
				if (this.previewMode) {
					return this.$t('discovery.client-answer');
				} else {
					return this.$t('discovery.your-answer');
				}
			},
		},
	};
</script>

<style lang="scss">
	.file-upload .v-input__prepend-outer {
		display: none!important;
	}

	.error-state {
		border: 1px solid var(--v-error-base);
		border-radius: 4px;
	}
</style>
