var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        _vm.item.fieldType,
        _vm._b(
          {
            key: _vm.item.fieldType,
            tag: "component",
            attrs: {
              "update-key": _vm.updateFlag,
              "preview-mode": _vm.previewMode
            }
          },
          "component",
          _vm.item,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }