var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "notification-layout",
    {
      attrs: {
        id: _vm.id,
        "action-text": _vm.actionLabel,
        "sent-at": _vm.sentAt
      },
      on: {
        action: function($event) {
          return _vm.openMeetingDetail()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _vm.client
            ? _c("client-avatar", {
                staticClass: "mr-2",
                attrs: { "x-small": "", client: _vm.client }
              })
            : _vm._e(),
          _c("div", { staticClass: "text-left" }, [
            _vm._v(_vm._s(_vm.scheduledMeeting.meetingName))
          ])
        ],
        1
      ),
      _c("template", { slot: "content" }, [
        _c(
          "div",
          {
            staticClass: "font-14 mb-1 brand-medium",
            style: _vm.cancelled ? "text-decoration:line-through" : ""
          },
          [
            _vm._v(
              _vm._s(
                _vm.DateTime.fromISO(
                  _vm.scheduledMeeting.confirmedTime.start
                ).toFormat("cccc") +
                  " " +
                  _vm.DateTime.fromISO(
                    _vm.scheduledMeeting.confirmedTime.start
                  ).toLocaleString(_vm.DateTime.DATETIME_MED)
              )
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "font-12",
            style: _vm.cancelled ? "text-decoration:line-through" : ""
          },
          [
            _c("span", [
              _vm._v(
                _vm._s(_vm.scheduledMeeting.formData.firstName) +
                  " " +
                  _vm._s(_vm.scheduledMeeting.formData.lastName)
              )
            ]),
            _c("br")
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }