var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "right-modal hidden",
      style: "z-index: 150;",
      attrs: { id: "right-modal" },
      on: { click: _vm.handleClick }
    },
    [
      _c(
        "div",
        {
          class: "right-side " + _vm.$store.getters.scroll,
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }