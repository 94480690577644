<template>
  <v-text-field
      v-bind="$attrs"
      v-on="$listeners"
      v-model="cleanedValue"
      @blur="cleanupValue"
  ></v-text-field>
</template>

<script>
export default {
  name: 'AmountInput',
  props: ['value'],
  computed: {
    cleanedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  methods: {
    cleanupValue() {
			if(this.value) {
				this.cleanedValue = this.value.toString().replace(/,/g, '').replace(/[^\d.-]/g, '').replace(/(\..*)\./g, '$1');
			}
    }
  }
};
</script>