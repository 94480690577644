<template>
	<div>
		<v-container  v-if="user" style="max-width: 800px">
			<v-row>
				<v-col cols="12">
					<div class="row-format mb-4">
						<v-btn class="ml-auto super-action" width="120"  :disabled="!userUpdate" @click="saveUser">
							{{ $t('global.save') }}
						</v-btn>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="3" align="center" class="pb-0 mb-0 column-format centered">
					<vue-dropzone
						v-if="!user.profilePicture && !userUpdate"
						ref="userFileDropzone"
						id="userDropzone"
						v-on:vdropzone-success="userUploadSuccess"
						v-on:vdropzone-error="userUploadFailure"
						v-on:vdropzone-sending="addFileUploadAuthHeader"
						:use-custom-slot="true"
						:options="userDropzoneOptions"
					>
						<div>
							<v-icon>$vuetify.icons.upload</v-icon><br />
							<span class="ml-2" style="font-size: 0.8em">{{ $t('account.profile-upload') }}</span>
						</div>
					</vue-dropzone>

					<div v-if="!user.profilePicture && userUpdate" class="column-format centered">
						<div>
							<v-icon color="grey">$warning</v-icon><br />
							<span class="ml-2" style="font-size: 0.8em; color: var(--v-gray_70-base)"
								>Please save changes to upload your profile picture</span
							>
						</div>
					</div>

					<div v-if="user.profilePicture" class="profilePic" style="position: relative">
						<v-avatar size="100">
							<img :src="user.profilePicture" />
						</v-avatar>

						<v-btn
							icon
							class="logoDelete delete"
							style="position: absolute; top: 35%; left: 35%"
							@click="deleteProfilePicture"
						>
							<v-icon>$delete</v-icon>
						</v-btn>
					</div>
				</v-col>
				<v-col cols="9" align="left" class="pb-0 mb-0">
					<v-text-field
						label="First name"
						v-model="user.firstName"
						@input="userChange"
						hide-details
						persistent-placeholder
						class="h-outline mb-3"
					></v-text-field>
					<v-text-field
						label="Last name"
						v-model="user.lastName"
						@input="userChange"
						hide-details
						persistent-placeholder
						class="h-outline"
					></v-text-field>
				</v-col>
				<v-col cols="12">
					<div style="position: relative">
						<v-text-field
							label="Email"
							v-model="toEmail"
							:disabled="emailLocked"
							@input="userChange"
							hide-details
							persistent-placeholder
							class="h-outline mb-4"
						>
						</v-text-field>
						<v-icon
							class="pointer"
							@click="emailLocked = !emailLocked"
							style="position: absolute; right: 5px; bottom: 5px"
							>{{ emailLocked ? 'lock' : 'lock_open' }}</v-icon
						>
					</div>

					<div class="row-format centered gap-2">
						<div style="flex-grow: 1">
							<v-btn block style="height: 46px !important;" outlined color="primary" @click="updatePasswordClicked"
								>{{ user.passwordEnabled ? 'Update password' : 'Enable password sign-in' }}
							</v-btn>
						</div>

						<div style="flex-grow: 1" v-if="user.passwordEnabled">
							<v-btn style="height: 46px !important;" outlined block color="primary" @click="confirmDisablePassword"
								>Disable password sign-in</v-btn
							>
						</div>
					</div>

					<div class="text-left mt-2">
						<span class="font-14 font-gray_70">Enable Two Factor Auth (used with password login):</span>
						<phone-number-input
							no-use-browser-locale
							v-model="phone"
							:only-countries="[
								'US',
								'CA',
								'GB',
								'IE',
								'MX',
								'JM',
								'PR',
								'AR',
								'IN',
								'PH',
								'FR',
								'DE',
								'HU',
								'IT',
								'PL',
								'PT',
								'ES',
								'SE',
								'CH',
								'UA',
								'ZA',
								'AU',
								'NZ',
								'LT'
							]"
							:clearable="true"
							@update="phoneUpdated"
							:color="$vuetify.theme.dark ? '#56524E' : '#D6D1CB'"
							:valid-color="$vuetify.theme.dark ? '#56524E' : '#D6D1CB'"
							size="lg"
							dark-color="#181818"
							:dark="$vuetify.theme.dark"
						></phone-number-input>

						<div class="grey--text mt-3" style="font-size: 14px">
							<span v-if="phone">
								<span v-if="user.phoneVerified" style="color: green">2FA Enabled</span>
								<span v-else style="color: red" class="pointer" @click="verificationModal = true"
									>Not Verified
									<v-btn x-small text outlined class="ml-9" v-if="!showVerificationPrompt" @click="sendNewCode"
										>Send new code?</v-btn
									></span
								>
							</span>
						</div>

						<div class="small-label mt-3">
							Providing a verified SMS enabled phone number will enable 2FA (Two Factor Authentication) feature of
							Moxie. By adding your phone number, you are giving Moxie permission to send you text messages for
							the sole purpose of authentication.
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<modal
			v-if="verificationModal"
			:dialog="verificationModal"
			:max-width="550"
			:persistent="true"
			:close-on-escape="false"
			@close="verificationModal = false"
		>
			<phone-verification :phone="phone" @verified="phoneVerified" @close="verificationModal = false"></phone-verification>
		</modal>

		<modal
			v-if="emailVerificationModal"
			:dialog="emailVerificationModal"
			:max-width="550"
			:persistent="true"
			:close-on-escape="false"
			@close="emailVerificationModal = false"
		>
			<email-update-verification
				:email="user.email"
				@verified="emailUpdated"
				@close="emailVerificationModal = false"
			></email-update-verification>
		</modal>
	</div>
</template>

<script>
	import EmailUpdateVerification from '@/modules/account/EmailUpdateVerification';
	import PhoneVerification from '@/modules/account/PhoneVerification';
	import ConfirmModal from '@/components/ConfirmModal';
	import AuthService from '@/modules/auth/AuthService';
	import AccountService from '@/modules/account/AccountService';
	import Cropper from 'cropperjs';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import 'cropperjs/dist/cropper.min.css';
	import VueDropzone from 'vue2-dropzone';
	import PasswordEnabled from '@/modules/account/PasswordEnabled.vue';
	import AuthUserService from '@/modules/auth/AuthUserService';
	import modal from "@bit/hecticapp.common.modal";

	export default {
		name: 'UserSettings',

		props: [],

		components: { EmailUpdateVerification, PhoneVerification, VueDropzone, modal },

		data: function() {
			return {
				authUserService: new AuthUserService(),
				accountService: new AccountService(),
				user: null,
				emailVerificationModal: false,
				toEmail: null,
				currentView: 'AboutMe',
				emailLocked: true,
				userUpdate: false,
				phone: null,
				showVerificationPrompt: false,
				verificationModal: false,
			};
		},

		mounted() {
			new AuthUserService().getAuthUser().then((res) => {
				this.user = res.data;
				this.toEmail = this.user.email;
				this.phone = this.user.phone;
			});
		},

		beforeDestroy() {},

		methods: {
			confirmDisablePassword() {
				let binding = {
					headingText: 'Confirm',
					bodyText:
						'Are you sure you want to disable password login?\nYou will be required to use either magic link or social sign-in.',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						AuthService.updateUserPassword(null)
							.then((res) => {
								this.$store.commit('error', 'Password sign-in disabled');
								this.user = res;
							})
							.catch((error) => {
								this.$store.commit('error', error.response.data.message);
							}).finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			updatePasswordClicked: function() {
				this.$store.state.globalModalController.openModal(PasswordEnabled).then((res) => {
					if(res) {
						this.user = res;
					}
				});
			},

			phoneUpdated: function(updatedPhone) {
				if (this.user.phone === updatedPhone.e164) {
					return;
				}
				this.user.phone = updatedPhone.e164;
				this.user.phoneVerified = false;
				this.userUpdate = true;
				if (this.user.phone) {
					this.showVerificationPrompt = true;
				} else {
					this.showVerificationPrompt = false;
				}
			},

			requestEmailUpdate: function() {
				AuthService.updateUserEmail(this.toEmail)
					.then(() => {
						this.emailVerificationModal = true;
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			emailUpdated: function() {
				let binding = {
					headingText: 'Email updated!',
					bodyText: `Your email has been successfully updated to ${this.toEmail}`,
					hideNo: true,
					yesText: 'OK',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then(() => {
					AuthService.refreshToken(true).then(() => location.reload());
				});
			},

			sendNewCode: function() {
				AuthService.sendVerification().then(() => {
					this.verificationModal = true;
				});
			},

			phoneVerified: function() {
				this.refreshUserAndAccountTokens();
			},

			requestDeleteProfilePicture: function() {
				if (this.userUpdate) {
					this.saveUser().then(() => this.deleteProfilePicture());
				} else {
					this.deleteProfilePicture();
				}
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			userUploadSuccess: function(file, response) {
				this.user.profilePicture = response;
				this.$refs.userFileDropzone.removeFile(file);

				this.refreshUserAndAccountTokens();
			},

			userUploadFailure: function(file, message) {
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.userFileDropzone.removeFile(file);
			},

			transformFile: function(file, done) {
				let myDropZone = this.$refs.userFileDropzone.dropzone;
				let editor = document.createElement('div');
				editor.style.position = 'fixed';
				editor.style.left = '0';
				editor.style.right = '0';
				editor.style.top = '0';
				editor.style.bottom = '0';
				editor.style.zIndex = '9999';
				editor.style.backgroundColor = '#000';
				document.body.appendChild(editor);

				// Create confirm button at the top left of the viewport
				let buttonConfirm = document.createElement('div');
				buttonConfirm.style.position = 'absolute';
				buttonConfirm.setAttribute('class', 'confirmButton');
				buttonConfirm.textContent = 'Confirm';
				editor.appendChild(buttonConfirm);

				buttonConfirm.addEventListener('click', function() {
					// Get the canvas with image data from Cropper.js
					let canvas = cropper.getCroppedCanvas({
						width: 256,
						height: 256,
					});

					// Turn the canvas into a Blob (file object without a name)
					canvas.toBlob(function(blob) {
						// Create a new Dropzone file thumbnail
						myDropZone.createThumbnail(
							blob,
							myDropZone.options.thumbnailWidth,
							myDropZone.options.thumbnailHeight,
							myDropZone.options.thumbnailMethod,
							false,
							function(dataURL) {
								// Update the Dropzone file thumbnail
								myDropZone.emit('thumbnail', file, dataURL);
								// Return the file to Dropzone
								done(blob);
							}
						);

						done(blob);
					});

					// Remove the editor from the view
					document.body.removeChild(editor);
				});

				// Create an image node for Cropper.js
				let image = new Image();
				image.src = URL.createObjectURL(file);
				editor.appendChild(image);

				// Create Cropper.js
				let cropper = new Cropper(image, { aspectRatio: 1 });
			},

			deleteProfilePicture: function() {
				this.accountService
					.deleteProfilePicture()
					.then(() => {
						this.user.profilePicture = null;
						this.refreshUserAndAccountTokens();
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			userChange: function() {
				this.userUpdate = true;
			},

			saveUser: function() {
       let cleanedEmail = this.toEmail.replace(/\s/g, '');
        if (cleanedEmail !== this.user.email) {
          this.requestEmailUpdate();
          return
				}

				this.$store.commit('startLoading');
				return this.accountService
					.updateUserProfile(this.user)
					.then(() => {
						this.refreshUserAndAccountTokens();
						this.userUpdate = false;
						if (this.showVerificationPrompt) {
							this.verificationModal = true;
							this.showVerificationPrompt = false;
						}
						Promise.resolve();
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
						Promise.reject();
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			refreshUserAndAccountTokens: function() {
				AuthService.refreshToken(true).then((res) => {
					this.$store.commit('logIn', res);

					AuthService.getProfile().then((res) => {
						this.$store.dispatch('setLoggedInUser', res);
						this.user = JSON.parse(JSON.stringify(this.$store.state.loggedInUser));
					});
				});
			},
		},

		computed: {
			userDropzoneOptions: function() {
				return {
					url: `${this.$store.getters.getPodUrl}api/me/profile/picture`,
					transformFile: this.transformFile,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},
		},
	};
</script>

<style scoped lang="scss">
	.confirmButton {
		background-color: var(--v-primary-base);
		font-weight: 500;
		font-family: Inter;
		color: var(--v-white-base);
		top: 0px;
		left: 0px;
		width: 100px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px 4px 4px 4px;
		z-index: 9999;
		text-align: center;
		cursor: pointer;
	}

	.profilePic {
		.logoDelete {
			visibility: hidden;
		}

		&:hover {
			.logoDelete {
				visibility: visible;
			}
		}
	}
</style>
