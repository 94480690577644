import {v4 as uuidv4} from 'uuid';
import HDateTime from "@/modules/utils/HDateTime";
import InvoiceDetails from "@/modules/invoices/InvoiceDetails";
import CreateInvoiceModal from "@/modules/invoices/CreateInvoiceModal";

export default {
    methods: {
        addNewInvoice: function(opportunity) {
            let client = this.$store.getters.getClientById(opportunity.clientId);
            let binding = {
                selectedClient: client,
                enableRecurring: false
            }
            this.$store.state.globalModalController.openModal(CreateInvoiceModal,binding).then((res) => {
                if(res) {
                    console.log(res);
                    this.addInvoiceToWorkflow(opportunity,res.id);
                    this.openInvoice(client.id, res.id);
                }
            })
        },

        addInvoiceToWorkflow: function(opportunity, invoiceId){
          this.opportunity.workflow.push({
              id: uuidv4(),
              itemId: invoiceId,
              itemType: 'Invoice',
              timestamp: HDateTime.now().toISO()
          });
          this.$emit('save');
        },

        openInvoice: function(clientId, invoiceId) {
            let binding = {
                clientId: clientId,
                id: invoiceId
            };
            this.$store.state.globalModalController.openModal(InvoiceDetails, binding, true, true).then(() => this.$emit('refresh'));
        },
    }
}