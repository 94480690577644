var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format text-left font-14 font-gray_70 py-3" },
    [
      _c("div", [_vm._v("Height (px)")]),
      _c("v-slider", {
        attrs: {
          min: 1,
          max: 1000,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.height,
          callback: function($$v) {
            _vm.$set(_vm.element, "height", $$v)
          },
          expression: "element.height"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }