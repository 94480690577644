import HttpClient from '@/services/HttpClient';

export default class ToDoService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getToDo() {
		return this.httpClient.get('/toDo')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	putToDo(toDo) {
		return this.httpClient.put(`/toDo`, toDo)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchToDo(toDo) {
		return this.httpClient.patch(`/toDo`, toDo)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteToDo() {
		return this.httpClient.delete(`/toDo`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


}
