<template>
	<div v-if="isReady">
		<simple-code-editor
			style="width:100%;"
			:theme="$vuetify.theme.dark ? 'dark' : 'light'"
			font_size="14px"
			v-model="embed"
		></simple-code-editor>
		<div class="font-14 text-left my-2 font-italic">Custom code embedding can be used to add any custom JavaScript to your client portal.  This can include analytics, chat widgets, or anything that requires you load custom script to extend functionality of your client portal.</div>

	</div>
</template>

<script>
	import SimpleCodeEditor from 'simple-code-editor';
	import CustomCodeService from '@/modules/portal/customization/CustomCodeService';

	export default {
		name: 'CustomCode',

		props: [],

		components: { SimpleCodeEditor },

		data: function() {
			return {
				customCodeService: new CustomCodeService(),
				embed: null,
				isReady: false,
			};
		},

		mounted() {
			this.customCodeService.getCustomCode().then((res) => {
				this.embed = res.data.embed;
				this.isReady = true;
			})
		},

		beforeDestroy() {},

		methods: {
			save: function(){
				let customCode = {
					embed: this.embed
				}
				this.customCodeService.updateCustomCode(customCode).then((res) => {
					this.embed = res.data.embed;
				})
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
