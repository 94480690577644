var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        ref: "EmailAttachment",
        staticStyle: { display: "none" },
        attrs: { type: "file", multiple: "" },
        on: { change: _vm.setAttachments }
      }),
      _c(
        "div",
        { staticClass: "mb-1" },
        [
          _vm.fromList.length && !_vm.isReply
            ? _c("v-select", {
                attrs: {
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  items: _vm.fromList,
                  label: "From:"
                },
                model: {
                  value: _vm.from,
                  callback: function($$v) {
                    _vm.from = $$v
                  },
                  expression: "from"
                }
              })
            : _vm._e(),
          _c("email-contact-selector", {
            attrs: {
              "to-list": _vm.to,
              label: "To",
              "client-id": _vm.clientId
            },
            on: {
              update: function($event) {
                return _vm.toUpdated($event)
              }
            }
          }),
          _vm.showCCs
            ? _c(
                "div",
                [
                  _c("email-contact-selector", {
                    attrs: {
                      "to-list": _vm.cc,
                      label: "CC",
                      "client-id": _vm.clientId
                    },
                    on: {
                      update: function($event) {
                        return _vm.ccUpdated($event)
                      }
                    }
                  }),
                  _c("email-contact-selector", {
                    attrs: {
                      "to-list": _vm.bcc,
                      label: "BCC",
                      "client-id": _vm.clientId
                    },
                    on: {
                      update: function($event) {
                        return _vm.bccUpdated($event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showSubject
            ? _c("v-text-field", {
                staticClass: "mt-1",
                attrs: {
                  outlined: "",
                  "hide-details": "",
                  dense: "",
                  placeholder: "Subject"
                },
                model: {
                  value: _vm.ourSubject,
                  callback: function($$v) {
                    _vm.ourSubject = $$v
                  },
                  expression: "ourSubject"
                }
              })
            : _vm._e(),
          !_vm.showCCs
            ? _c(
                "div",
                {
                  staticClass: "text-left font-12 font-primary pointer my-2",
                  on: {
                    click: function($event) {
                      _vm.showCCs = true
                    }
                  }
                },
                [_vm._v("Show CC/BCC")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("editor", {
        ref: "EmailEditor",
        attrs: {
          "api-key": _vm.$store.getters.getTinyMceKey,
          "initial-value": _vm.initialContent,
          init: _vm.mceConfigText,
          spellcheck: true
        }
      }),
      _vm.attachmentNames.length || _vm.s3Attachments.length
        ? _c(
            "div",
            {
              staticClass: "row-format mt-2",
              staticStyle: { "flex-wrap": "wrap", gap: "8px" }
            },
            [
              _vm._l(_vm.attachmentNames, function(name, index) {
                return _c(
                  "v-chip",
                  {
                    key: name + index,
                    attrs: { small: "", close: "", "close-icon": "cancel" },
                    on: {
                      "click:close": function($event) {
                        return _vm.removeAttachment(name)
                      }
                    }
                  },
                  [_vm._v(_vm._s(name))]
                )
              }),
              _vm._l(_vm.s3Attachments, function(s3, index) {
                return _c(
                  "v-chip",
                  {
                    key: s3.fileName + index,
                    attrs: {
                      color: "blue_10",
                      small: "",
                      close: "",
                      "close-icon": "cancel"
                    },
                    on: {
                      "click:close": function($event) {
                        return _vm.removeS3Attachment(s3)
                      }
                    }
                  },
                  [_vm._v(_vm._s(s3.fileName))]
                )
              })
            ],
            2
          )
        : _vm._e(),
      _c("v-checkbox", {
        staticClass: "mb-2",
        attrs: {
          label: "Add open/viewed tracking pixel",
          "hide-details": "",
          dense: "",
          "persistent-hint": ""
        },
        model: {
          value: _vm.addTrackingPixel,
          callback: function($$v) {
            _vm.addTrackingPixel = $$v
          },
          expression: "addTrackingPixel"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format mt-2 centered" },
        [
          _c("send-email-button", {
            attrs: { label: "Send", width: 140 },
            on: {
              click: function($event) {
                return _vm.sendEmail($event)
              }
            }
          }),
          _c(
            "v-btn",
            {
              staticStyle: { "min-height": "40px" },
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.cancelSend()
                }
              }
            },
            [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("$delete")])],
            1
          )
        ],
        1
      ),
      _vm.aiDialog
        ? _c("ai-email-widget", {
            attrs: { dialog: _vm.aiDialog, mode: _vm.aiMode },
            on: {
              cancel: function($event) {
                _vm.aiDialog = false
              },
              result: function($event) {
                return _vm.runAiAssist($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }