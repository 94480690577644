var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format gap-2" },
    [
      _c("v-select", {
        staticStyle: { width: "180px", "max-width": "180px" },
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Category",
          items: _vm.variables,
          "item-text": "label",
          "item-value": "value"
        },
        on: {
          change: function($event) {
            return _vm.handleChange()
          }
        },
        model: {
          value: _vm.category,
          callback: function($$v) {
            _vm.category = $$v
          },
          expression: "category"
        }
      }),
      _vm.fields && !_vm.isDynamic
        ? _c("v-select", {
            style: ["Email"].includes(_vm.category)
              ? "width: 350px; max-width: 350px"
              : "width: 200px; max-width: 200px",
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              label: "Field",
              items: _vm.fields,
              "item-text": "label",
              "item-value": "value"
            },
            on: {
              change: function($event) {
                return _vm.handleChange()
              }
            },
            model: {
              value: _vm.field,
              callback: function($$v) {
                _vm.field = $$v
              },
              expression: "field"
            }
          })
        : _vm._e(),
      _vm.isDynamic
        ? _c("v-text-field", {
            staticStyle: { width: "150px", "max-width": "150px" },
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              placeholder: _vm.selectedCategory.dynamicKeyLabel
            },
            model: {
              value: _vm.field,
              callback: function($$v) {
                _vm.field = $$v
              },
              expression: "field"
            }
          })
        : _vm._e(),
      _vm.comparatorList
        ? _c("v-select", {
            staticStyle: { width: "150px", "max-width": "150px" },
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              items: _vm.comparatorList
            },
            on: {
              change: function($event) {
                return _vm.handleChange()
              }
            },
            model: {
              value: _vm.comparator,
              callback: function($$v) {
                _vm.comparator = $$v
              },
              expression: "comparator"
            }
          })
        : _vm._e(),
      _vm.fieldDetail && _vm.fieldDetail.type === "Date" && _vm.showValue
        ? _c("date-selector", {
            attrs: { standard: true, date: _vm.value },
            on: {
              change: function($event) {
                _vm.value = $event
                _vm.handleChange()
              }
            }
          })
        : _vm._e(),
      _vm.fieldDetail && _vm.fieldDetail.type === "List" && _vm.showValue
        ? _c("v-select", {
            attrs: {
              items: _vm.fieldDetail.options,
              "hide-details": "",
              dense: "",
              outlined: ""
            },
            on: {
              change: function($event) {
                return _vm.handleChange()
              }
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : (_vm.fieldDetail || _vm.isDynamic) && _vm.showValue
        ? _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              placeholder: "Value",
              type:
                _vm.fieldDetail && _vm.fieldDetail.type === "Numeric"
                  ? "number"
                  : "text"
            },
            on: {
              input: function($event) {
                return _vm.handleChange()
              }
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e(),
      _c(
        "v-icon",
        {
          staticClass: "material-symbols-rounded pointer",
          attrs: { size: "20" },
          on: {
            click: function($event) {
              return _vm.$emit("delete")
            }
          }
        },
        [_vm._v("delete")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }