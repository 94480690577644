var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "400px" }, attrs: { id: "booking-detail" } },
    [
      _c("div", { staticClass: "modal-padding" }, [
        _c("div", { staticClass: "modal-title" }, [
          _vm._v(
            _vm._s(
              _vm.DateTime.fromISO(_vm.bookingDetail.month).toLocaleString({
                month: "long",
                year: "numeric"
              })
            ) +
              " scheduled income for " +
              _vm._s(_vm.bookingDetail.client.label)
          )
        ]),
        _c(
          "div",
          [
            _c("v-treeview", {
              staticClass: "text-left custom-tree",
              attrs: { items: _vm.treeData }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }