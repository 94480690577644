import HttpClient from '@/services/HttpClient';
import store from "@/store";

export default class TimeTrackService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getTimer() {
		return this.httpClient
			.get('/timer/current')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	start(data) {
		return this.httpClient
			.post('/timer/current/start', data)
			.then((res) => {
				store.state.eventBus.$emit('created','timer')
				return Promise.resolve(res)
			})
			.catch((err) => Promise.reject(err));
	}

	pause() {
		return this.httpClient
			.post('/timer/current/pause')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	resume() {
		return this.httpClient
			.post('/timer/current/resume')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	stop() {
		return this.httpClient
			.post('/timer/current/stop')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	delete() {
		return this.httpClient
			.delete('/timer/current')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	update(data) {
		return this.httpClient
			.post('/timer/current/update', data)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTimerEvent(id) {
		return this.httpClient
			.get(`/timer/events/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTimerEventCount() {
		return this.httpClient
			.get('/timer/events/count')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTimerEvents(earliest, latest) {
		return this.getTimerEventsByUser(null, earliest, latest);
	}

	timerEventDownload(earliest,latest,eventIds,fileType = "XLSX", groupBy=null) {
		let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		return this.httpClient
			.post('/timer/events/download', eventIds, {
				params: {
					earliest: earliest,
					latest: latest,
					timeZone: timeZone,
					fileType: fileType,
					groupBy: groupBy

				},
				responseType: 'blob'
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTimerEventsByUser(userId, earliest, latest) {
		return this.getTimerEventsFull(userId,earliest,latest,null,null,null);
	}

	getTimerEventsFull(userId, earliest, latest, clientId, projectId, deliverableId, customGroupField=null, ticketId = null) {
		return this.httpClient
			.get('/timer/events', {
				params: {
					userId: userId,
					earliest: earliest,
					latest: latest,
					clientId: clientId,
					projectId: projectId,
					deliverableId: deliverableId,
					customGroupField: customGroupField,
					ticketId: ticketId
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	createTimerEvent(timerEvent) {
		return this.httpClient
			.post('/timer/events', timerEvent)
			.then((res) => {
				store.state.eventBus.$emit('created','timer')
				return Promise.resolve(res)
			})
			.catch((err) => Promise.reject(err));
	}

	putTimerEvent(id, timerEvent) {
		return this.httpClient
			.put(`/timer/events/${id}`, timerEvent)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchTimerEvent(id, timerEvent) {
		return this.httpClient
			.patch(`/timer/events/${id}`, timerEvent)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteTimerEvent(id) {
		return this.httpClient
			.delete(`/timer/events/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
