<template>
	<div>
		<v-container fluid class="text-left brand-box">
			<v-row
				:style="index < (opportunities.length - 1) ? 'border-bottom: 1px solid var(--v-gray_30-base);' : ''"
				class="px-3 py-6 pointer opportunity-row"
				@click="$emit('edit', opportunity.id)"
				v-for="(opportunity,index) in opportunities"
				:key="opportunity.id"
			>
				<v-col cols="5" class="row-format align-center">
					<div class="row-format fill-width align-center">
						<client-avatar class="mt-1 mr-2" v-if="opportunity.client" :client="opportunity.client" large></client-avatar>
						<div>
							<div class="font-18 brand-medium">{{ opportunity.name ? opportunity.name : '--' }}</div>
							<div class="font-14 font-gray_70" v-if="opportunity.client">
								{{opportunity.client.clientType === 'Prospect' ? 'Prospect' : 'Client'}} &bull; {{ opportunity.client.name }}
							</div>
							<div v-if="opportunity.archive" class="font-gray_70 font-14">Archived <v-icon color="secondary" size="20">archive</v-icon> </div>
							<div v-if="opportunity.formData" class="font-gray_70 font-14 row-format gap-1">
								<div v-if="opportunity.formData.firstName || opportunity.formData.lastName">{{opportunity.formData.firstName}} {{opportunity.formData.lastName}}</div>
								<div v-if="(opportunity.formData.firstName || opportunity.formData.lastName) && opportunity.formData.email">&bull;</div>
								<div v-if="opportunity.formData.email">{{opportunity.formData.email}}</div>
							</div>
						</div>
					</div>
				</v-col>
				<v-col cols="2" class="column-format gap-3">
					<div class="column-format">
						<div class="font-14 font-gray_70">Stage</div>
						<div class="row-format align-center">
							<div v-if="opportunity.stage" class="stage" :style="`--stage-hex-color: ${opportunity.stage.hexColor}`"></div>
							<div class="ml-1 font-14 brand-medium">{{opportunity.stage.label}}</div>
						</div>
					</div>
					<div class="column-format">
						<div class="font-14 font-gray_70 mb-n1">Confidence</div>
						<div><v-icon class="material-symbols-rounded" :color="opportunity.sentimentObj.color">{{opportunity.sentimentObj.icon}}</v-icon></div>
					</div>
				</v-col>
				<v-col cols="2" class="column-format gap-3">
					<div class="column-format">
						<div class="font-14 font-gray_70">Est. value</div>
						<div class="text-option" v-if="opportunity.value">{{$formatters.dollars(opportunity.value)}} / {{opportunity.timePeriodDetail.label}}</div>
						<div v-else class="text-option">--</div>
					</div>
					<div class="column-format" v-if="opportunity.closed">
						<div class="font-14 font-gray_70">Date closed</div>
						<div class="text-option" v-if="opportunity.actualCloseDate">{{DateTime.fromISO(opportunity.actualCloseDate).toLocaleString(DateTime.DATE_MED)}}</div>
						<div v-else class="text-option">--</div>
					</div>
					<div class="column-format" v-else>
						<div class="font-14 font-gray_70">Est. close</div>
						<div class="text-option" v-if="opportunity.estCloseDate">{{DateTime.fromISO(opportunity.estCloseDate).toLocaleString(DateTime.DATE_MED)}}</div>
						<div v-else class="text-option">--</div>
					</div>
				</v-col>
				<v-col cols="2" class="column-format gap-3">
					<div class="column-format">
						<div class="font-14 font-gray_70">Last activity</div>
						<div class="text-option" v-if="opportunity.lastActivity">{{opportunity.lastActivity.timestamp | formatForTimeAgo}}</div>
						<div v-else class="text-option">--</div>
					</div>
					<div class="column-format">
						<div class="font-14 font-gray_70">Next due</div>
						<div class="text-option" v-if="opportunity.nextDue">{{DateTime.fromISO(opportunity.nextDue.dueDate).toLocaleString(DateTime.DATE_MED)}}</div>
						<div v-else class="text-option">--</div>
					</div>
				</v-col>
				<v-col cols="1" class="row-format align-center">
						<v-menu :nudge-bottom="30">
              <template v-slot:activator="scope">
								<v-btn icon class="ml-auto menu-activator" v-on="scope.on">
									<v-icon>{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
								</v-btn>
              </template>
							<div class="more-menu">
								<div class="more-menu-item" @click="$emit('edit', opportunity.id)">Edit</div>
								<div class="more-menu-item" @click="$emit('archive',opportunity.id)">{{opportunity.archive ? 'Unarchive' : 'Archive'}}</div>
								<div class="more-menu-item" @click="$emit('delete',opportunity.id)">Delete</div>
							</div>
						</v-menu>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";
	import HDateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'OpportunityList',

		props: ['pipelineStages', 'pipelineFields', 'opportunities','confidenceList'],

		components: {ClientAvatar},

		data: function() {
			return {
				DateTime:HDateTime,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
		},

		computed: {
		// 	opportunityList: function(){
		// 		let result = JSON.parse(JSON.stringify(this.opportunities));
		// 		result.forEach(o => o.showDescription = false);
		// 		return result;
		// 	},
		//
		// 	groupedOpportunities: function() {
		// 		let oppList = JSON.parse(JSON.stringify(this.opportunities));
		//
		// 		let stageMap = new Map();
		// 		let unknownKey = 'unknown';
		//
		// 		stageMap.set(unknownKey, {
		// 			id: unknownKey,
		// 			label: '[Unknown status]',
		// 			hexColor: '#ffffff',
		// 			opportunities: [],
		// 			index: -1,
		// 		});
		//
		// 		this.pipelineStages.stages.forEach((s, index) => {
		// 			stageMap.set(s.id, {
		// 				id: s.id,
		// 				label: s.label,
		// 				hexColor: s.hexColor,
		// 				opportunities: [],
		// 				index: index,
		// 			});
		// 		});
		//
		// 		oppList.forEach((o) => {
		// 			if (o.clientId) {
		// 				o.client = this.$store.getters.getClientById(o.clientId);
		// 			}
		//
		// 			if (stageMap.has(o.statusId)) {
		// 				stageMap.get(o.statusId).opportunities.push(o);
		// 			} else {
		// 				stageMap.get(unknownKey).opportunities.push(0);
		// 			}
		// 		});
		//
		// 		let result = Array.from(stageMap.values());
		// 		result.sort((a, b) => a.index - b.index);
		// 		return result.filter((r) => r.opportunities.length);
		// 	},
		},
	};
</script>

<style scoped lang="scss">
	.truncate-description {
		width:200px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.show-description {
		white-space: pre-wrap;
	}

	.text-option {
		font-size: 14px;
		font-weight: 500;
		width: fit-content;
		padding: 2px 0px;
		border-radius: 4px;
	}

	.stage {
		width: 16px;
		height: 16px;
		border-radius: 4px;
		background-color: var(--stage-hex-color);
	}

	.opportunity-row {
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>
