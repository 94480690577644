import HttpClient from '@/services/HttpClient';

export default class AuthUserService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(process.env.VUE_APP_BASE_AUTH_URL);
	}

	getAuthUser() {
		return this.httpClient
			.get(`/auth/me`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
