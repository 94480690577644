<template>
	<v-container fluid class="pa-0 ma-0">
		<v-row dense>
			<v-col cols="12" class="column-format gap-2 pt-3">
				<v-text-field
					ref="searchForm"
					dense
					hide-details
					outlined
					v-model="searchTerm"
					@keyup.native.enter="searchPhotos()"
					placeholder="Search login screen background image"
				>
					<template v-slot:append>
						<v-btn @click.native="searchPhotos()" icon small><v-icon>$search</v-icon></v-btn>
					</template>
				</v-text-field>

				<div class="images-wrapper">
					<div class="image-grid" v-if="photosReady">
						<div v-for="result in photoResults" :key="result.id">
							<div
								:style="`background-image: url(${result.urls.thumb});`"
								class="image-container"
								@click="setUnsplashImage(result)"
							></div>
							<div class="credit-container">
								<a :href="`${result.user.links.html}`" target="_blank" class="credit-anchor">
									{{ result.user.first_name }} {{ result.user.last_name }}
								</a>
							</div>
						</div>
					</div>

					<div class="row-format align-center">
						<div class="row-format gap-2">
							<v-btn @click.native="previousPage()" class="primary-action" icon
								><v-icon size="15">$chevronLeft</v-icon></v-btn
							>
							<v-btn @click.native="nextPage()" class="primary-action" icon
								><v-icon size="15">$chevronRight</v-icon></v-btn
							>
						</div>
						<div class="ml-auto font-10 mt-1 text-right">
							{{ $t('proposal.cover.photos-by') }}
							<a href="https://unsplash.com/?utm_source=hectic&utm_medium=referral" target="_blank">Unsplash</a>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3" class="pt-5">
				<vue-dropzone
						ref="fileDropzone"
						id="dropzone"
						class="image-container no-image-container"
						v-on:vdropzone-success="uploadSuccess"
						v-on:vdropzone-error="uploadFailure"
						v-on:vdropzone-sending="addFileUploadAuthHeader"
						:use-custom-slot="true"
						:options="dropzoneOptions"
				>
					<div>
						<v-icon>upload</v-icon>
						<div class="font-12">Upload custom background</div>
					</div>
				</vue-dropzone>
			</v-col>
			<v-col cols="9">
				<div v-for="image in imageAssets" :key="image.id" style="position: relative; width:fit-content" class="pointer" @click="setCustomImageFromAsset(image)">
					<img :src="image.thumbnail"  :alt="image.name">
					<v-btn elevation="0" small style="position: absolute; top:8px; right:8px" @click.stop="deleteImageAsset(image)"><v-icon  class="material-symbols-rounded">delete</v-icon></v-btn>
				</div>
			</v-col>
		</v-row>
		<v-row class="text-left pt-4" dense>
			<v-col cols="12">
				<div>Login page welcome message</div>
				<div :style="`border: 1px solid var(--v-gray_20-base); border-radius: 4px; font-family: ${branding.font}`" class="pa-2">
					<editor
							ref="loginGreeting"
							:api-key="$store.getters.getTinyMceKey"
							:initial-value="branding.loginGreeting"
							:inline="true"
							:init="mceConfig"
							:spellcheck="true"
							@input="handleTextInput('loginGreeting')"
					></editor>
				</div>
			</v-col>
		</v-row>
		<v-row class="text-left pt-4">
			<v-col cols="12">
				<div>Default welcome page message <v-icon class="material-symbols-rounded" size="20" v-tippy="{content:'This message is visible if you do not create a custom home page'}">help</v-icon></div>
				<div :style="`border: 1px solid var(--v-gray_20-base); border-radius: 4px; font-family: ${branding.font}`" class="pa-2">
					<editor
							ref="welcomeGreeting"
							:api-key="$store.getters.getTinyMceKey"
							:initial-value="branding.welcomeGreeting"
							:inline="true"
							:init="mceConfig"
							:spellcheck="true"
							@input="handleTextInput('welcomeGreeting')"
					></editor>
				</div>
			</v-col>
		</v-row>
		<v-row class="text-left pt-4">
			<v-col cols="12">
				<v-select
						class="mb-2"
						hide-details
						persistent-placeholder
						dense
						outlined
						label="Login box position"
						v-model="branding.loginBoxPosition"
						:items="loginBoxPosition"
				></v-select>
			</v-col>
		</v-row>

		<v-row class="text-left pt-4">
			<v-col cols="12">
				<div>Preview</div>
			</v-col>
			<v-col cols="12">
				<mock-login class="preview-window" :branding="branding"></mock-login>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import CustomBrandingService from '@/modules/portal/customization/CustomBrandingService';
	import StockPhotoService from '@/modules/portal/StockPhotoService';
	import MockLogin from "@/modules/portal/components/MockLogin";
	import editor from "@tinymce/tinymce-vue";
	import ImageAssetService from "@/services/ImageAssetService";
	import vueDropzone from "vue2-dropzone";
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'LoginScreen',

		props: ['branding','mceConfig'],

		components: {
			MockLogin,
			editor,
			vueDropzone
		},

		data: function() {
			return {
				brandingService: new CustomBrandingService(),
				stockPhotoService: new StockPhotoService(),
				imageAssetService: new ImageAssetService(),
				perPage: 5,
				photos: {},
				photosReady: false,
				searchTerm: null,
				defaultSearch: 'blue background',
				loginBoxPosition: ['Left', 'Center', 'Right'],
				tags: ['ClientPortalSplash'],
				imageAssets: [],
			};
		},

		mounted() {
			this.searchPhotos();
			this.getImageAssets();
		},

		beforeDestroy() {},

		methods: {
			searchPhotos: function(page = 1, perPage = this.perPage) {
				this.currentPage = page;
				let search = this.$validations.isEmpty(this.searchTerm) ? this.defaultSearch : this.searchTerm;

				this.stockPhotoService.searchStockPhotos(search, page, perPage).then((res) => {
					this.photos = res.data;
					this.photosReady = true;
				});
			},

			getImageAssets: function() {
				this.imageAssetService.getImageAssets(this.tags).then((res) => {
					this.imageAssets.splice(0, this.imageAssets.length);
					this.imageAssets.push(...res.data);
				});
			},

			handleTextInput(ref) {
				this.branding[ref] = this.$refs[ref].editor.getContent();
			},

			nextPage: function() {
				if (this.currentPage < this.photos.total_pages) {
					this.searchPhotos(++this.currentPage);
				}
			},

			previousPage: function() {
				if (this.currentPage > 1) {
					this.searchPhotos(--this.currentPage);
				}
			},

			setUnsplashImage(image) {
				this.stockPhotoService.downloadStockPhoto(image.links.download_location);
				this.branding.loginSplash = image.urls.raw + '&w=2048&q=100';
			},

			setCustomImageFromAsset(asset){
				this.branding.loginSplash = asset.fullSize;
			},

			deleteImageAsset(imageAsset){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this image from your form library?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if (res) {
						this.imageAssetService.deleteImageAsset(imageAsset.id).then(() => {
							let ix = this.imageAssets.findIndex(i => i.id === imageAsset.id);
							if (ix > -1) {
								this.imageAssets.splice(ix, 1);
							}
						})
					}
				});
			},

			uploadSuccess: function(file, response) {
				this.$refs.fileDropzone.removeFile(file);
				this.imageAssets.push(response);
				this.setCustomImageFromAsset(response);
			},

			uploadFailure: function(file, message) {
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.fileDropzone.removeFile(file);
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},
		},

		computed: {
			photoResults: function() {
				let result = [...this.photos.results];
				return result.splice(0, this.perPage);
			},

			dropzoneOptions: function() {
				return {
					url: `${this.$store.getters.getBaseUrl}/assets/images?tags=${this.tags.join(',')}`,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},
		},
	};
</script>

<style scoped lang="scss">
	.preview-window {
		height: 500px;
		width: 100%;
		border: 1px solid var(--v-gray_50-base);
	}

	.search-wrapper {
		border-radius: 4px;
		height: 36px;
		padding: 4px 4px 4px 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 50%;
		.v-input {
			margin: 0 !important;
			font-size: 14px;
			line-height: 16px;
			border: none !important;
			.v-input__control {
				.v-input__slot {
					label {
						display: none;
					}
					&:before,
					&:after {
						border-style: none !important;
					}
				}
			}
		}
		.vertical-rule {
			width: 8px;
			height: 24px;
			border-right: 1px solid var(--v-gray_60-base);
		}
		.magnifier {
			width: 36px;
			//border: 1px solid red;
		}
	}

	.custom-image {
		min-width: 200px;
		min-height: 200px;
	}

	.images-wrapper {
		.image-grid {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-column-gap: 6px;
			grid-row-gap: 6px;

			.image-container {
				//width: 100%;
				height: 100px;
				min-height: 100px;
				border-radius: 2px;
				display: flex;
				align-items: flex-end;
				&.custom-image-container {
					align-items: flex-start;
					justify-content: flex-end;
					padding: 4px;
					.v-btn {
						opacity: 0;
						transition: opacity 300ms;
					}
					&:hover {
						.v-btn {
							opacity: 1;
							transition: opacity 300ms;
						}
					}
				}
				cursor: pointer;
				background-size: cover;
				background-repeat: no-repeat !important;
				background-position: center center;
			}
		}
		.credit-container {
			background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
			display: inline-block;
			align-self: flex-end;
			width: 100%;
			padding: 2px 3px 1px;
			text-align: left;
			.credit-anchor {
				display: block;
				color: black;
				font-size: 10px;
				font-weight: 500;
				text-decoration: none;
				max-width: 100px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
</style>
