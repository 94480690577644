<template>
	<div id="proposal-cover-modal">
		<v-btn icon class="close-dialog" @click="cancel()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="px-4 pt-4 pb-6">
			<div class="modal-title">{{ $t('proposal.cover.cover') }}</div>
			<div class="left-right-wrapper">
				<div class="left-side">
					<div class="subtitle">{{ $t('global.preview') }}</div>

					<div class="mt-2">
						<cover-page
							v-bind="coverPage"
							:formatting="proposal.formatting"
							:editMode="false"
							:is-preview="true"
							:client-name="clientName"
							:contractor-name="contractorName"
							:logo="coverPage.showLogo ? account.accountLogo : ''"
						></cover-page>
					</div>

					<div class="mt-2 row-format" style="width:100%">
						<v-select hide-details persistent-placeholder outlined dense :items="['Prominent','Hidden']" v-model="coverPage.headingPosition" label="Heading position">
						</v-select>
						<div style="width: 50%" class="ml-2">
							<v-text-field v-if="coverPage.headingPosition === 'Hidden'" hide-details outlined dense v-model="coverPage.heading" placeholder="Proposal Heading"></v-text-field>
						</div>
					</div>

					<div class="subtitle mt-4">{{ $t('proposal.cover.background-image') }}</div>

					<!-- Unsplash Section -->
					<div class="d-flex justify-space-between align-center mt-2">
						<div class="search-wrapper">
							<v-text-field
								ref="searchForm"
								dense
								hide-details
								v-model="searchTerm"
								@keyup.native.enter="searchPhotos()"
								:placeholder="$t('proposal.cover.image-search-btn')"
								single-line
							></v-text-field>
							<div class="vertical-rule"></div>
							<div class="magnifier">
								<v-btn @click.native="searchPhotos()" icon small><v-icon>$search</v-icon></v-btn>
							</div>
						</div>
						<div>
							<v-btn @click.native="previousPage()" class="primary-action mr-1" icon
								><v-icon size="20">$chevronLeft</v-icon></v-btn
							>
							<v-btn @click.native="nextPage()" class="primary-action ml-1" icon
								><v-icon size="20">$chevronRight</v-icon></v-btn
							>
						</div>
					</div>
					<div class="images-wrapper mt-2">
						<div class="image-grid" v-if="photosReady">
							<div :style="`cursor:pointer`" class="image-container no-image-container" @click="setBlankTheme()">
								No image
							</div>

							<div
								v-for="result in photoResults"
								:key="result.id"
								:style="`background-image: url(${result.urls.thumb});`"
								class="image-container"
								@click="setUnsplashImage(result)"
							>
								<div class="credit-container">
									<a :href="`${result.user.links.html}`" target="_blank" class="credit-anchor">
										{{ result.user.first_name }} {{ result.user.last_name }}
									</a>
								</div>
							</div>
						</div>

						<div class="font-10 mt-1 text-right">
							{{ $t('proposal.cover.photos-by') }}
							<a href="https://unsplash.com/?utm_source=hectic&utm_medium=referral" target="_blank">Unsplash</a>
						</div>
					</div>

					<!-- Custom Image Upload -->
					<div class="subtitle mt-1">{{ $t('proposal.cover.uploaded-images') }}</div>
					<div class="images-wrapper mt-2">
						<div class="image-grid" v-if="assetsReady">
							<div
								v-for="result in imageAssets"
								:key="result.id"
								:style="`background-image: url(${result.thumbnail});`"
								class="image-container custom-image-container"
								@click="setCustomImage(result)"
							>
								<v-btn class="secondary-action" icon x-small @click.stop="confirmDelete(result)"
									><v-icon size="16">$delete</v-icon></v-btn
								>
							</div>

							<vue-dropzone
								ref="fileDropzone"
								id="dropzone"
								class="image-container no-image-container"
								v-on:vdropzone-success="uploadSuccess"
								v-on:vdropzone-error="uploadFailure"
								v-on:vdropzone-sending="addFileUploadAuthHeader"
								:use-custom-slot="true"
								:options="dropzoneOptions"
							>
								<div>
									<v-icon>$vuetify.icons.upload</v-icon>
									<div class="font-12">{{ $t('proposal.cover.upload') }}</div>
								</div>
							</vue-dropzone>
						</div>
					</div>

					<!-- Logo -->
					<div class="subtitle mt-3 d-flex justify-start align-center">
						<div>
							<v-switch
								v-model="coverPage.showLogo"
								dense
								inset
								hide-details
								class="mt-0"
								:disabled="!account.accountLogo"
							></v-switch>
						</div>
						<div>
							{{ $t('proposal.cover.show-logo') }}
						</div>
					</div>
					<div class="images-wrapper mt-2">
						<div class="image-grid">
							<div
								v-if="account.accountLogo"
								:style="`background-image: url(${account.accountLogo});`"
								class="image-container custom-image-container account-logo"
								@click="openAccountInfoModal = true"
							>
								<v-btn class="secondary-action" icon x-small @click.stop="openAccountInfoModal = true"
									><v-icon size="16">$edit</v-icon></v-btn
								>
							</div>

							<div
								v-else
								class="image-container no-image-container account-logo"
								@click="openAccountInfoModal = true"
							>
								<div>
									<v-icon>$vuetify.icons.edit</v-icon>
									<div>{{ $t('global.edit') }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Right Column -->
				<div class="right-side">
					<div class="subtitle">{{ $t('proposal.cover.color-scheme') }}</div>
					<div class="color-scheme-wrapper mt-2">
						<div
							:class="'scheme-box dark pointer' + (this.coverPage.theme === 'dark' ? ' selected' : '')"
							@click="themeChange('dark')"
						>
							<div class="top-section">
								<div class="title"></div>
								<div class="subtitle"></div>
							</div>
							<div class="bottom-section">
								<div>Dark</div>
							</div>
						</div>
						<div
							:class="'scheme-box light pointer mt-2' + (this.coverPage.theme === 'light' ? ' selected' : '')"
							@click="themeChange('light')"
						>
							<div class="top-section">
								<div class="title"></div>
								<div class="subtitle"></div>
							</div>
							<div class="bottom-section">
								<div>Light</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" style="width:160px;" @click="cancel()">{{ $t('global.cancel') }}</v-btn>
			<v-btn class="primary-action ml-1" style="width: 160px;" @click="save()">{{ $t('global.save') }}</v-btn>
		</div>
		<confirm-dialog
			:dialog="confirmDeleteDialog"
			@confirm="deleteCustomImage()"
			@close="confirmDeleteDialog = false"
			:persistent="false"
			heading-text="Delete Background Image?"
			:yes-text="$t('global.delete')"
			:no-text="$t('global.cancel')"
			:body-text="confirmBodyText"
		>
		</confirm-dialog>
		<basic-modal
			:dialog="openAccountInfoModal"
			@close="openAccountInfoModal = false"
			ref="accountInfoModal"
			:persistent="false"
		>
			<account-info-modal
				v-if="true"
				:account-info="account"
				@set-account-info="setAccountInfo"
				@close="openAccountInfoModal = false"
			></account-info-modal>
		</basic-modal>
	</div>
</template>

<script>
	import CoverPage from './proposal-renderer/CoverPage';
	import ImageAssetService from '@/services/ImageAssetService';
	import vueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import AccountService from '@/modules/account/AccountService';
	import AccountInfoModal from '@/modules/invoices/AccountInfoModal';
	import BasicModal from '@/components/BasicModal';

	export default {
		props: ['proposalService', 'proposal', 'clientName', 'contractorName', 'headerStyle', 'bodyStyle'],

		name: 'CoverModal',

		components: { CoverPage, vueDropzone, ConfirmDialog, AccountInfoModal, BasicModal },

		data() {
			return {
				imageAssetService: new ImageAssetService(),
				accountService: new AccountService(),
				imageAssetTags: ['proposal-cover-page'],
				imageAssets: [],
				photos: {},
				photosReady: false,
				searchTerm: null,
				defaultSearch: 'backgrounds',
				view: 'LAYOUT',
				currentPage: 1,
				perPage: 9,
				coverPage: JSON.parse(JSON.stringify(this.proposal.coverPage)),
				assetsReady: false,
				confirmDeleteDialog: false,
				imageToDelete: null,
				account: {},
				openAccountInfoModal: false,
			};
		},

		mounted() {
			this.searchPhotos();
			this.getImageAssets();
			this.getAccount();
		},

		methods: {
			setAccountInfo: function(event) {
				this.account = event;
				this.openAccountInfoModal = false;
				this.$emit('update-account', event);
			},
			getAccount: function() {
				this.accountService
					.getAccount()
					.then((res) => {
						this.account = res.data;
						console.log(this.account);
					})
					.catch((err) => {
						console.log(err);
					});
			},
			uploadSuccess: function(file, response) {
				this.$refs.fileDropzone.removeFile(file);
				console.log(response);
				this.imageAssets.push(response);
				this.setCustomImage(response);
			},
			uploadFailure: function(file, message) {
				console.log(message);
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.fileDropzone.removeFile(file);
			},
			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},
			confirmDelete(image) {
				this.imageToDelete = image;
				this.confirmDeleteDialog = true;
			},
			deleteCustomImage() {
				let image = this.imageToDelete;
				this.imageAssetService
					.deleteImageAsset(image.id)
					.then(() => {
						let i = this.imageAssets.findIndex((img) => img.id === image.id);
						this.imageAssets.splice(i, 1);
						this.confirmDeleteDialog = false;
						this.imageToDelete = null;

						if (this.coverPage.image === image.fullSize) {
							this.setBlankTheme();
						}
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', `${err.response.message}`);
					});
			},

			getImageAssets() {
				this.imageAssetService
					.getImageAssets(this.imageAssetTags)
					.then((res) => {
						this.imageAssets = res.data;
						console.log(this.imageAssets);
						this.assetsReady = true;
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', `${err.response.message}`);
					});
			},
			cancel: function() {
				this.coverPage = JSON.parse(JSON.stringify(this.proposal.coverPage));
				this.$emit('cancel');
			},

			save: function() {
				this.$emit('save', this.coverPage);
			},

			searchPhotos: function(page = 1, perPage = this.perPage) {
				this.currentPage = page;
				let search = this.$validations.isEmpty(this.searchTerm) ? this.defaultSearch : this.searchTerm;

				this.proposalService.searchStockPhotos(search, page, perPage).then((res) => {
					this.photos = res.data;
					this.photosReady = true;
				});
			},

			nextPage: function() {
				if (this.currentPage < this.photos.total_pages) {
					this.searchPhotos(++this.currentPage);
				}
			},

			previousPage: function() {
				if (this.currentPage > 1) {
					this.searchPhotos(--this.currentPage);
				}
			},

			setBlankTheme() {
				this.themeChange('blank');
				this.coverPage.imageDownload = null;
				this.coverPage.image = null;
				this.coverPage.imageThumb = null;
			},
			themeChange: function(theme) {
				this.coverPage.theme = theme;
			},

			setUnsplashImage(image) {
				if (this.coverPage.theme === 'blank') {
					this.themeChange('light');
				}
				this.coverPage.imageDownload = image.links.download_location;
				this.coverPage.image = image.urls.raw;
				this.coverPage.imageThumb = image.urls.thumb;
			},

			setCustomImage(image) {
				if (this.coverPage.theme === 'blank') {
					this.themeChange('light');
				}
				console.log(image);
				this.coverPage.imageDownload = image.fullSize;
				this.coverPage.image = image.fullSize;
				this.coverPage.imageThumb = image.thumbnail;
			},

			goToLayoutView() {
				this.view = 'LAYOUT';
			},

			goToPhotoSearchView() {
				this.view = 'PHOTO-SEARCH';

				this.$nextTick(function() {
					console.log(this.$refs.searchForm);
					this.$refs.searchForm.focus();
				});
			},
		},

		computed: {
			photoResults: function() {
				let result = [...this.photos.results];
				return result.splice(0, this.perPage);
			},
			dropzoneOptions: function() {
				return {
					url: `${this.$store.getters.getBaseUrl}/assets/images?tags=${this.imageAssetTags.join(',')}`,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},
			confirmBodyText() {
				if (this.imageToDelete && this.coverPage.image === this.imageToDelete.fullSize) {
					return "This will delete the image from your 'Uploaded images' and delete your cover image. Continue?";
				}
				return "This will delete the image from your 'Uploaded images'. Continue?";
			},
		},

		watch: {
			openState: function(newVal) {
				if (newVal) {
					this.coverPage = JSON.parse(JSON.stringify(this.proposal.coverPage));
				}
			},
		},
	};
</script>

<style lang="scss">
	#proposal-cover-modal {
		//max-width: 1000px;
		width: 696px;
		background-color: var(--v-white-base);

		.subtitle {
			font-weight: 600;
			color: var(--v-gray_80-base);
			font-size: 12px;
			text-align: left;
		}
		.left-right-wrapper {
			margin-top: 12px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			//border: 1px solid red;

			.left-side {
				flex: 0 0 85%;
				//border: 1px solid green;
				padding-right: 12px;
			}

			.right-side {
				flex: 0 0 15%;
				//border: 1px solid red;
				padding-left: 4px;
			}
		}

		.search-wrapper {
			border: 1px solid var(--v-gray_50-base);
			border-radius: 4px;
			height: 36px;
			padding: 4px 4px 4px 8px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex: 0 0 50%;
			.v-input {
				margin: 0 !important;
				font-size: 14px;
				line-height: 16px;
				.v-input__control {
					.v-input__slot {
						label {
							display: none;
						}
						&:before,
						&:after {
							border-style: none !important;
						}
					}
				}
			}
			.vertical-rule {
				width: 8px;
				height: 24px;
				border-right: 1px solid var(--v-gray_60-base);
			}
			.magnifier {
				width: 36px;
				//border: 1px solid red;
			}
		}
		.images-wrapper {
			.image-grid {
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				grid-column-gap: 6px;
				grid-row-gap: 6px;

				.image-container {
					//width: 100%;
					height: 70px;
					border-radius: 2px;
					display: flex;
					align-items: flex-end;
					&.custom-image-container {
						align-items: flex-start;
						justify-content: flex-end;
						padding: 4px;
						.v-btn {
							opacity: 0;
							transition: opacity 300ms;
						}
						&:hover {
							.v-btn {
								opacity: 1;
								transition: opacity 300ms;
							}
						}
					}
					cursor: pointer;
					background-size: cover;
					background-repeat: no-repeat !important;
					background-position: center center;

					&.no-image-container {
						align-items: center;
						justify-content: center;
						font-size: 12px;
						min-height: 70px !important;
						color: var(--v-gray_80-base);
						border: 1px solid var(--v-gray_50-base);
						&.account-logo {
							background-color: var(--v-gray_30-base);
							border: none;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					&.account-logo {
						border: 1px solid var(--v-gray_50-base);
						background-size: contain;
					}
				}
			}
			.credit-container {
				background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
				display: inline-block;
				align-self: flex-end;
				width: 100%;
				padding: 2px 3px 1px;
				text-align: left;
				.credit-anchor {
					display: block;
					color: black;
					font-size: 10px;
					font-weight: 500;
					text-decoration: none;
					max-width: 100px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
		.scheme-box {
			width: 100%;
			//width: 95px;
			height: 64px;
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-content: space-between;
			border: 1px solid var(--v-gray_50-base);
			&.selected {
				border: 1px solid var(--v-black-base);
			}
			background-color: white;
			.top-section {
				border-radius: 4px 4px 0 0;
				height: 42px;
				background-color: black;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				padding: 0 0 8px 8px;
				.title {
					background-color: white;
					height: 4px;
					width: 32px;
				}
				.subtitle {
					background-color: white;
					height: 2px;
					width: 16px;
					margin-top: 4px;
				}
			}
			.bottom-section {
				padding-bottom: 1px;
				font-size: 12px;
				text-align: left;
				margin-left: 8px;
				display: flex;
				align-items: flex-end;
				color: black;
			}
			&.light {
				.top-section {
					background-color: #f9f8f6;
					.title,
					.subtitle {
						background-color: black;
					}
				}
				.bottom-section {
					color: #565451;
				}
			}
			&.dark .top-section {
				background-color: black;
			}
		}
	}
</style>
