var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cardWrapper" }, [
    _vm.opportunity.emptyCard
      ? _c(
          "div",
          {
            staticClass: "blankCard mx-0 my-2 pl-4 pr-0 pt-2",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("add-new-empty")
              }
            }
          },
          [
            _c("v-icon", { attrs: { size: "34" } }, [_vm._v("$duplicate")]),
            _c("span", { staticClass: "font-grey" }, [
              _vm._v(_vm._s(_vm.$t("projects.add-first-deliverable")))
            ])
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "card pa-4 mb-2",
            on: {
              click: function($event) {
                return _vm.$emit("edit", _vm.opportunity.id)
              }
            }
          },
          [
            _c("div", { staticClass: "column-format flex-grow-1 gap-2" }, [
              _vm.client
                ? _c(
                    "div",
                    { staticClass: "row-format align-center" },
                    [
                      _c("client-avatar", {
                        attrs: { "x-small": "", client: _vm.client }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "font-12 brand-bold",
                          staticStyle: { "margin-left": "6px" }
                        },
                        [_vm._v(" " + _vm._s(_vm.client.name) + " ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "column-format font-14" }, [
                _c("div", { staticClass: "mb-2" }, [
                  _vm._v(_vm._s(_vm.opportunity.name))
                ]),
                _vm.opportunity.formData.firstName ||
                _vm.opportunity.formData.lastName
                  ? _c("div", { staticClass: "font-12" }, [
                      _vm._v(
                        _vm._s(_vm.opportunity.formData.firstName) +
                          " " +
                          _vm._s(_vm.opportunity.formData.lastName)
                      )
                    ])
                  : _vm._e(),
                _vm.opportunity.formData.email
                  ? _c("div", { staticClass: "font-12" }, [
                      _vm._v(_vm._s(_vm.opportunity.formData.email))
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "column-format mt-auto text-option" }, [
                _vm.opportunity.nextDue
                  ? _c("div", { staticClass: "row-format gap-1" }, [
                      _c("div", { staticClass: "font-gray_70" }, [
                        _vm._v("Next due:")
                      ]),
                      _c("div", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm.DateTime.fromISO(
                              _vm.opportunity.nextDue.dueDate
                            ).toLocaleString(_vm.DateTime.DATE_MED)
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.opportunity.estCloseDate
                  ? _c("div", { staticClass: "row-format gap-1" }, [
                      _c("div", { staticClass: "font-gray_70" }, [
                        _vm._v("Est. Close:")
                      ]),
                      _c("div", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm.DateTime.fromISO(
                              _vm.opportunity.estCloseDate
                            ).toLocaleString(_vm.DateTime.DATE_MED)
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.opportunity.value
                  ? _c("div", { staticClass: "row-format gap-1" }, [
                      _c("div", { staticClass: "font-gray_70" }, [
                        _vm._v("Value:")
                      ]),
                      _c("div", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$formatters.dollars(
                              _vm.opportunity.value,
                              false,
                              true,
                              _vm.opportunity.currency
                            )
                          ) +
                            " / " +
                            _vm._s(_vm.opportunity.timePeriodDetail.label)
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }