var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isConditionMet
    ? _c(
        "v-container",
        {
          staticClass: "pa-0 ma-0",
          staticStyle: { "min-height": "40px" },
          attrs: { fluid: "" },
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            }
          }
        },
        [
          _c(
            "v-row",
            { staticClass: "pa-0 ma-0" },
            _vm._l(_vm.filteredColumns, function(column, colIndex) {
              return _c(
                "v-col",
                {
                  key: colIndex,
                  staticClass: "pa-0 ma-0 column-format",
                  attrs: { cols: "12", sm: _vm.cols }
                },
                [
                  _c(
                    "div",
                    { style: _vm.padding(column) },
                    [
                      _c(
                        "draggable",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { list: column.items, disabled: !_vm.builder }
                        },
                        _vm._l(column.items, function(item, subIndex) {
                          return _c(
                            "div",
                            { key: item.id },
                            [
                              _vm.colConditionMet(item)
                                ? _c("item-renderer", {
                                    ref: "item",
                                    refInFor: true,
                                    attrs: {
                                      index:
                                        _vm.index +
                                        "." +
                                        colIndex +
                                        "." +
                                        subIndex,
                                      item: item,
                                      styles: _vm.styles,
                                      disabled: _vm.disabled,
                                      "in-container": true,
                                      "account-logo": _vm.accountLogo,
                                      active:
                                        _vm.active &&
                                        item.id === _vm.editItemId &&
                                        item.type === "TextBlock",
                                      results: _vm.results,
                                      "known-data": _vm.knownData,
                                      builder: _vm.builder,
                                      container: _vm.container
                                    },
                                    on: {
                                      delete: function($event) {
                                        return _vm.$emit("delete", item)
                                      },
                                      change: function($event) {
                                        return _vm.containerElementUpdated(
                                          colIndex,
                                          $event
                                        )
                                      },
                                      click: function($event) {
                                        _vm.active
                                          ? _vm.containerItemClick(
                                              colIndex,
                                              item,
                                              $event
                                            )
                                          : null
                                      },
                                      input: function($event) {
                                        return _vm.$emit("input", $event)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        [
                          _vm.active
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": "",
                                    "close-on-click": "",
                                    bottom: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    small: "",
                                                    text: "",
                                                    color: "primary"
                                                  }
                                                },
                                                on
                                              ),
                                              [_vm._v("+ Add element")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pa-2",
                                      staticStyle: {
                                        "background-color":
                                          "var(--v-white-base)"
                                      }
                                    },
                                    _vm._l(_vm.schemaTypes, function(
                                      category,
                                      sIndex
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: sIndex,
                                          staticClass: "mb-2 pb-1",
                                          staticStyle: {
                                            "border-bottom":
                                              "1px solid var(--v-gray_30-base)"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-left" },
                                            [_vm._v(_vm._s(category.label))]
                                          ),
                                          _vm._l(
                                            category.types.filter(function(t) {
                                              return t.allowInContainer
                                            }),
                                            function(schema) {
                                              return _c(
                                                "div",
                                                {
                                                  key: schema.type,
                                                  staticClass:
                                                    "row-format align-center schema-item pa-1 gap-2",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$emit(
                                                        "add-container-element",
                                                        {
                                                          container: _vm.item,
                                                          column: colIndex,
                                                          element: schema
                                                        }
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "material-symbols-rounded",
                                                      attrs: {
                                                        color: "secondary",
                                                        size: "24"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(schema.icon)
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "column-format text-left"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "font-14"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(schema.label)
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-12 mt-n1 font-gray_70"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              schema.description
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }