import HttpClient from '@/services/HttpClient';

export default class MetricService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getRevenueMetricsByClient(clientId,currency) {
		return this.httpClient
			.get(`/dashboard/metrics/revenue/${clientId}`,{
				params: {
					currency: currency
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTotalRevenueGroupedByClient(currency) {
		return this.httpClient
			.get(`/dashboard/metrics/revenue/all`,{
				params: {
					currency: currency
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getRevenueMetrics(start,end) {
		return this.httpClient
			.get('/dashboard/metrics/revenue',{
				params: {
					earliest: start,
					latest: end,
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getBookings(start,end,currency = null) {
		return this.httpClient
			.get('/dashboard/metrics/bookings',{
				params: {
					earliest: start,
					latest: end,
					currency: currency
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getMonthlyTimeMetrics(start,end) {
		return this.httpClient
			.get('/dashboard/metrics/time',{
				params: {
					earliest: start,
					latest: end,
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getMonthlyTimeMetricsByClient(clientId) {
		return this.httpClient
			.get(`/dashboard/metrics/time/${clientId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getTotalTimeGroupedByClient() {
		return this.httpClient
			.get(`/dashboard/metrics/time/all`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProjectCountGroupedByClient() {
		return this.httpClient
			.get(`/dashboard/metrics/projects/all`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
