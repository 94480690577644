import ProjectDeliverableService from "@/modules/projects/deliverable/ProjectDeliverableService";

export default {

    data: function(){
        return {

        }
    },

    methods:{
        getDeliverableShareLink: function(deliverable) {
            if (deliverable) {
                return document.location.protocol + '//' + document.location.host + '/deliverable/' + deliverable.id;
            } else {
                return null;
            }
        },

        processDuplicateDeliverable: function(deliverable){
            let newDeliverable = JSON.parse(JSON.stringify(deliverable));
            newDeliverable.id = null;
            newDeliverable.name = 'Copy of: ' + deliverable.name;
            newDeliverable.tasks.forEach(t => {t.complete = false});
            newDeliverable.statusId = this.$store.state.deliverableStatusList.statusList[0].id;
            newDeliverable.comments = [];
            newDeliverable.events = [];
            newDeliverable.files = [];
            newDeliverable.archived = false;

            return new ProjectDeliverableService().createNewDeliverable(newDeliverable);
        },
    },

    computed: {}


}