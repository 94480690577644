<template>
	<div v-if="isReady">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.google.desc') }}</div>
				<connected v-if="isConnected"></connected>
				<div v-else>
					<div class="pointer" @click="connectGoogleAccount"><img width="200" src="images/google/sign_in_with_google.png"></div>
				</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded">
			<div v-for="conn in googleConnections" :key="conn.googleUser.email"  class="pt-4 pb-4"  style="border-bottom: 1px solid var(--v-gray_50-base)">
				<div class="font-14">
					<div><span class="brand-medium">Google Account:</span> {{conn.googleUser.name}}</div>
					<div><span class="brand-medium">Google User Email:</span> {{conn.googleUser.email}}</div>
					<div class="my-3" v-if="conn.calendarList && conn.calendarList.length">
						<div class="column-format" v-for="cal in conn.calendarList" :key="cal.calendarId" style="border-bottom: 1px solid var(--v-gray_30-base)">
							<v-checkbox v-model="cal.enabled" :label="cal.description"  hide-details dense @change="updateSettings(conn)"></v-checkbox>
							<v-checkbox class="ml-5" v-if="cal.enabled" v-model="cal.showInCalendar" label="Show events in calendar" hide-details dense @change="updateSettings(conn)"></v-checkbox>
						</div>
					</div>
					<v-btn class="secondary-action mt-1" @click="confirmDisconnect(conn.googleUser.email)"><v-icon small>$delete</v-icon>Disconnect</v-btn>
				</div>
			</div>
			<div class="mt-4">
				<div class="brand-medium">Connect an additional account:</div>
				<div class="pointer" @click="connectGoogleAccount"><img width="200" src="images/google/sign_in_with_google.png"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import GoogleService from '@/services/integrations/GoogleService';
	import Connected from '@/modules/account/apps/Connected';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'GoogleCalendar',

		props: [],

		components: { Connected },

		data: function () {
			return {
				googleService: new GoogleService(),
				expanded: false,
				isReady: false,

				googleIsReady: false,
				googleConnections: null,
				googleClient: null,

				params: {
					client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
					scope: 'profile email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.calendarlist.readonly',
					ux_mode: 'popup',
					callback: this.handleAuthCode
				},
			};
		},

		mounted() {
			this.getConnections();
			this.initGoogleClient();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.getConnections();
			},

			initGoogleClient: function () {
				this.googleClient = window.google.accounts.oauth2.initCodeClient(this.params);
			},

			getConnections: function () {
				this.googleService
					.getConnections()
					.then((res) => this.googleConnections = res.data)
					.finally(() => (this.isReady = true));
			},

			connectGoogleAccount: function () {
				this.googleClient.requestCode();
			},

			handleAuthCode: function(res){
				this.googleService.connect(res.code).then(() => {
					this.$onBoarding.track('connect_calendar');
					this.getConnections();
				});
			},

			updateSettings: function(conn){
				this.googleService.update(conn).then(() => {
					this.getConnections();
				})
			},

			confirmDisconnect: function (email) {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to disconnect your ${email} Google calendar?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.googleService.disconnect(email).then(() => {
							this.getConnections();
						});
					}
				});
			},
		},

		computed: {
			isConnected: function(){
				if(this.googleConnections && this.googleConnections.length){
					return true;
				}else{
					return false;
				}
			}
		},
	};
</script>

<style scoped lang="scss"></style>
