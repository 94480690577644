var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("audio", {
        staticStyle: { display: "none" },
        attrs: {
          src: "/media/ringback/US_ringback_tone.mp3",
          loop: "true",
          id: "Ringback"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }