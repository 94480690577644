<template>
	<welcome-container>
		<template v-slot:left>
			<div class="column-format fill-all">
				<div class="column-format flex-grow-1 centered">
					<div class="text-left column-format mr-6" style="max-width: 450px">
						<v-form lazy-validation v-model="isValid" ref="UserInfoForm">
							<div style="font-size: 32px; font-weight: 600">
								Welcome<span v-if="firstName">, {{ firstName }}</span>
							</div>
							<div class="font-16 font-gray_80 mt-2 mb-6">
								Tell us a bit about your business so we can tailor your experience to your needs.
							</div>
							<div class="row-format gap-3">
								<v-text-field
									hide-details
									persistent-placeholder
									class="h-outline"
									label="First name"
									v-model="firstName"
									:rules="[v => !!v || 'Required']"
								></v-text-field>
								<v-text-field
									hide-details
									persistent-placeholder
									class="h-outline"
									label="Last name"
									v-model="lastName"
									:rules="[v => !!v || 'Required']"
								></v-text-field>
							</div>
							<v-text-field
								hide-details
								persistent-placeholder
								class="h-outline"
								label="What is your business called?"
								v-model="businessName"
								:placeholder="`E.g. Designs, Inc.`"
								:rules="[v => !!v || 'Required']"
							></v-text-field>
							<v-autocomplete
								hide-details
								persistent-placeholder
								class="h-outline"
								label="Where are you based?"
								:items="countriesSimple"
								item-text="name"
								item-value="code"
								v-model="country"
								:rules="[v => !!v || 'Required']"
							>
							</v-autocomplete>
							<v-autocomplete
								hide-details
								persistent-placeholder
								class="h-outline"
								label="What is your home currency?"
								:items="currencies"
								item-text="label"
								item-value="value"
								v-model="currency"
								:rules="[v => !!v || 'Required']"
							>
							</v-autocomplete>
							<div class="row-format" style="width: 100%">
								<v-select
									hide-details
									persistent-placeholder
									:class="`h-outline ${industry === 'Other' ? 'mr-1' : ''}`"
									label="What kind of work do you do?"
									v-model="industry"
									:items="industryList"
									placeholder="Select"
									:rules="[v => !!v || 'Required']"
								>
								</v-select>
								<v-text-field
									v-if="industry === 'Other'"
									hide-details
									persistent-placeholder
									class="h-outline"
									label="Other industry"
									v-model="otherIndustry"
									placeholder="Nice, what industry?"
								></v-text-field>
							</div>
							<v-select
								style="width: 100%"
								hide-details
								persistent-placeholder
								class="h-outline"
								label="How long have you been your own boss?"
								placeholder="Select"
								v-model="years"
								:items="experienceList"
								:rules="[v => !!v || 'Required']"
							>
							</v-select>
							<v-btn  class="mt-6" color="primary" block large @click="next()" :disabled="!isValid">Continue</v-btn>
						</v-form>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:right>
			<div
				class="row-format centered fill-all"
				style="
					background-image: url('/images/onboarding/welcome1.png');
					background-size: cover;
					background-position: center;
				"
			></div>
		</template>
	</welcome-container>
</template>

<script>
	import currencies from '@/assets/data/currencies.json';
	import countriesSimple from '@/assets/data/countries-simple.json';
	import WelcomeContainer from '@/modules/welcome/WelcomeContainer';

	export default {
		name: 'UserInfoCapture',

		props: ['userInfo'],

		components: { WelcomeContainer },

		data: function () {
			return {
				firstName: null,
				lastName: null,
				businessName: null,
				currency: null,
				country: null,
				industry: null,
				otherIndustry: null,
				years: null,
				currencies: currencies,
				countriesSimple: countriesSimple,
				isValid: false,

				steps: ['Provisioning services...', 'Setting up your account...', 'Loading up sample data...', 'Done!'],

				industryList: [
					'Accounting / Finance',
					'Consulting',
					'Data science',
					'Digital marketing',
					'Event services',
					'Graphic design',
					'Influencer',
					'IT support',
					'Legal services',
					'Photographer',
					'Product design / UX',
					'Quality assurance',
					'Social media manager',
					'Software developer',
					'Virtual assistant',
					'Web developer',
					'Web designer',
					'Writer / content creator',
					'Other',
				],

				experienceList: ['Just getting started', '1 year', '1-2 years', '2-5 years', '5+ years'],
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'user-info-capture' });
			this.setUserInfo();
			this.$nextTick(() => this.$refs.UserInfoForm.validate());
		},

		beforeDestroy() {},

		methods: {
			setUserInfo: function () {
				this.firstName = this.userInfo.firstName;
				this.lastName = this.userInfo.lastName;
				this.businessName = this.userInfo.businessName;
				this.currency = this.userInfo.currency;
				this.country = this.userInfo.country;
				this.industry = this.userInfo.industry;
				this.otherIndustry = this.userInfo.otherIndustry;
				this.years = this.userInfo.years;
			},

			next: function () {
				this.$emit('next', {
					firstName: this.firstName,
					lastName: this.lastName,
					businessName: this.businessName,
					currency: this.currency,
					country: this.country,
					industry: this.industry,
					otherIndustry: this.otherIndustry,
					years: this.years,
				});
			},
		},

		computed: {},

		watch: {},
	};
</script>

<style scoped lang="scss">
	.logo {
		width: 150px;
	}
</style>
