var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { class: "pl-0 " + (_vm.periods.length ? "py-5" : "py-3") },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "1", align: "left" } }, [
            _c("div", { staticClass: "mt-2 brand-semilight" }, [
              _vm._v(_vm._s(_vm.label))
            ])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "10", align: "left" } },
            [
              _vm._l(_vm.periods, function(period, index) {
                return _c("div", { key: index, staticClass: "column-format" }, [
                  _c(
                    "div",
                    { class: "row-format " + (index > 0 ? "mt-2" : "") },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "110px" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.enabledTimeIncrements,
                              "hide-details": "",
                              dense: "",
                              outlined: ""
                            },
                            model: {
                              value: period.start,
                              callback: function($$v) {
                                _vm.$set(period, "start", $$v)
                              },
                              expression: "period.start"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mt-2 mx-2" }, [_vm._v("-")]),
                      _c(
                        "div",
                        { staticStyle: { width: "110px" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.enabledTimeIncrements,
                              "hide-details": "",
                              dense: "",
                              outlined: ""
                            },
                            model: {
                              value: period.end,
                              callback: function($$v) {
                                _vm.$set(period, "end", $$v)
                              },
                              expression: "period.end"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mt-1 ml-1" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.periods.splice(index, 1)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "gray_70", small: "" } },
                                [_vm._v("$delete")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  period.start > period.end
                    ? _c(
                        "div",
                        {
                          staticClass: "font-14 brand-semilight",
                          staticStyle: { color: "var(--v-error-base)" }
                        },
                        [
                          _vm._v(
                            " Choose an end time later than the start time. "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              }),
              _vm.hasOverlaps
                ? _c(
                    "div",
                    {
                      staticClass: "font-14 brand-semilight",
                      staticStyle: { color: "var(--v-error-base)" }
                    },
                    [_vm._v(" Times currently overlap. Please correct. ")]
                  )
                : _vm._e(),
              !_vm.periods.length
                ? _c(
                    "div",
                    {
                      staticClass: "mt-2",
                      staticStyle: { color: "var(--v-gray_60-base)" }
                    },
                    [_vm._v(" Unavailable ")]
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "v-col",
            { attrs: { cols: "1", align: "right" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-2",
                  attrs: { icon: "" },
                  on: { click: _vm.addPeriod }
                },
                [
                  _c("v-icon", { attrs: { color: "gray_70", small: "" } }, [
                    _vm._v("$plus")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }