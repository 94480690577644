<template>
	<div class="column-format">
		<div class="font-14">
			Country: {{twilioAccount.country}} | Account Status: {{twilioAccount.status}} | End user type: {{twilioAccount.endUserType}} | <v-icon class="pointer material-symbols-rounded" @click="deleteAccount()">delete</v-icon>
		</div>
		<div v-if="twilioAccount.status === 'Approved' && !subscription">
			<twilio-numbers :twilio-account="twilioAccount" @order-complete="$emit('refresh')"></twilio-numbers>
		</div>
	</div>
</template>

<script>
	import TwilioService from '@/modules/communicator/realtime/twilio/TwilioService';
	import TwilioNumbers from "@/modules/communicator/settings/providers/twilio/TwilioNumbers";
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'TwilioAccount',

		props: ['twilioAccount','subscription'],

		components: {TwilioNumbers},

		data: function() {
			return {
				twilioService: new TwilioService(),
			};
		},

		mounted() {

		},

		beforeDestroy() {},

		methods: {
			deleteAccount: function(){
				let binding = {
					headingText: 'Confirm delete',
					bodyText: 'Are you sure you want to delete your business phone line account.  Any phone numbers will be terminated and all supporting documents will be deleted'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.twilioService.deleteAccount().then(() => this.$emit('refresh'))
					}
				})
			},
		},

		computed: {
		},
	};
</script>

<style scoped lang="scss"></style>
