<template>
	<div  :class="`row-format align-center ml-1 ${view === 'Two-Week' ? 'mt-1' : ''}`">
		<client-avatar v-if="client" disable-click x-small  style="transform: scale(.92)" :client="client"></client-avatar>
		<div class="ml-1 font-gray_90 row-format align-center" style="width: 100%">
			<div class="brand-medium">{{statusLabel}}</div>
			<div class="brand-semilight ml-1"> {{amountLabel}}</div>
			<v-icon class="ml-auto mr-1" size="14" :color="isPastDue ? 'warning' : 'blue_30'">{{icon}}</v-icon>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";
	import DateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'InvoiceEvent',

		props: ['event','view'],

		components: { ClientAvatar },

		data: function () {
			return {
				invoice: this.event.original.meta.invoice,
				client: this.$store.getters.clients.find((c) => c.id === this.event.original.meta.invoice.clientId),
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {},

		computed: {
			statusLabel: function(){
				if(this.invoice.status === 'DRAFT'){
					return "Invoice drafted"
				}else{
					if(this.isPastDue){
						return "Invoice past due";
					}else if(this.invoice.amountDue) {
						return "Invoice sent"
					}else{
						return "Invoice paid in full"
					}
				}
			},

			icon: function(){
				if(this.invoice.status === 'DRAFT'){
					return '$edit';
				}else if(this.isPastDue){
					return '$alert';
				}else if(this.invoice.amountDue > 0){
					return '$paperAirplane';
				}else{
					return '';
				}
			},

			isPastDue: function(){
				let status = ['SENT','PARTIAL'];
				if(status.includes(this.invoice.status) && this.invoice.amountDue > 0){
					if(DateTime.fromISO(this.invoice.dateDue) < DateTime.now()){
						return true;
					}else{
						return false;
					}
				}else{
					return false;
				}
			},

			amountLabel: function(){
				return this.$formatters.dollars(this.invoice.total,true,true,this.invoice.currency)
			}
		},
	};
</script>

<style scoped lang="scss">
</style>
