var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("editor", {
        staticStyle: { "font-size": "14px", width: "100%" },
        attrs: {
          "api-key": _vm.$store.getters.getTinyMceKey,
          init: _vm.mceConfig,
          rows: "1",
          "auto-grow": "",
          disabled: _vm.$store.state.loggedInUser.userId !== _vm.authorId
        },
        on: { onblur: _vm.handleChange },
        model: {
          value: _vm.comment,
          callback: function($$v) {
            _vm.comment = $$v
          },
          expression: "comment"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }