var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", [
        _c("div", { staticClass: "row-format align-start" }, [
          _c(
            "div",
            { staticClass: "column-format" },
            [
              _c("div", { staticClass: "font-gray_80 mb-1" }, [
                _vm._v(_vm._s(_vm.$t("connected-apps.sessions.desc")))
              ]),
              _vm.isConnected
                ? _c("connected")
                : _c("div", [
                    !_vm.showConnectScreen
                      ? _c(
                          "div",
                          {
                            staticClass: "font-primary brand-medium pointer",
                            on: {
                              click: function($event) {
                                _vm.showConnectScreen = true
                              }
                            }
                          },
                          [_vm._v(" Connect Sessions.us ")]
                        )
                      : _vm._e(),
                    _vm.showConnectScreen
                      ? _c(
                          "div",
                          { staticClass: "column-format gap-2" },
                          [
                            _c("v-text-field", {
                              staticStyle: {
                                "background-color": "var(--v-white-base)"
                              },
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                "persistent-placeholder": "",
                                outlined: "",
                                label: "API key"
                              },
                              model: {
                                value: _vm.apiKey,
                                callback: function($$v) {
                                  _vm.apiKey = $$v
                                },
                                expression: "apiKey"
                              }
                            }),
                            _c("v-text-field", {
                              staticStyle: {
                                "background-color": "var(--v-white-base)"
                              },
                              attrs: {
                                "hide-details": "",
                                dense: "",
                                "persistent-placeholder": "",
                                outlined: "",
                                label: "Description"
                              },
                              model: {
                                value: _vm.description,
                                callback: function($$v) {
                                  _vm.description = $$v
                                },
                                expression: "description"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "row-format gap-2" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary-action",
                                    on: {
                                      click: function($event) {
                                        _vm.showConnectScreen = false
                                      }
                                    }
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "super-action",
                                    attrs: { width: "150" },
                                    on: { click: _vm.connectSessions }
                                  },
                                  [_vm._v("Connect")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
            ],
            1
          ),
          _vm.isConnected
            ? _c(
                "div",
                {
                  staticClass: "ml-auto pointer",
                  on: {
                    click: function($event) {
                      _vm.expanded = !_vm.expanded
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.isConnected && _vm.expanded
          ? _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c("div", { staticClass: "mb-6 font-14" }, [
                  _c("span", { staticClass: "brand-medium" }, [
                    _vm._v(
                      _vm._s(_vm.sessionsConnection.description) +
                        " connected on " +
                        _vm._s(
                          _vm.DateTime.fromISO(
                            _vm.sessionsConnection.connectedAt
                          ).toLocaleString(_vm.DateTime.DATETIME_MED)
                        )
                    )
                  ]),
                  _c("br")
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action mr-1",
                    on: { click: _vm.confirmDisconnect }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("$delete")]),
                    _vm._v("Disconnect")
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }