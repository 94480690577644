import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class InvoiceAttachmentService {

	constructor(invoice) {
		this.invoice = invoice;
		this.httpClient = new HttpClient();
	}

	setExpense(invoice){
		this.invoice = invoice;
	}

	/**
	 * As per the contract of the generic FileAttachment component, we must implement the following methods:
	 *
	 * fileService.deleteFile(fileName);
	 * fileService.getSignedUrl(fileName);
	 * fileService.getPreviewUrl(fileName);
	 * fileService.getUploadUrl();
	 */

	deleteFile(fileName) {
        return this.httpClient
            .delete(`/clients/${this.invoice.clientId}/invoices/${this.invoice.id}/files?fileName=${encodeURIComponent(fileName)}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

	getSignedUrl(fileName) {
		return this.httpClient
			.get(`/clients/${this.invoice.clientId}/invoices/${this.invoice.id}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getUploadUrl() {
		return `${store.getters.getBaseUrl}/clients/${this.invoice.clientId}/invoices/${this.invoice.id}/files`;
	}
}
