import HttpClient from "@/services/HttpClient";
import store from '@/store';

export default class CustomCodeService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(process.env.VUE_APP_BASE_AUTH_URL,true);
	}

	getCustomCode() {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.get(`/account/${accountId}/portal/embed`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateCustomCode(customCode) {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.put(`/account/${accountId}/portal/embed`,customCode)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
