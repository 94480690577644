var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row-format align-center text-left py-2 px-2 font-14 thread",
      on: {
        click: function($event) {
          return _vm.$emit("open-thread")
        }
      }
    },
    [
      _vm.thread.unread ? _c("div", { staticClass: "unread mr-2" }) : _vm._e(),
      _c(
        "div",
        { staticClass: "column-format", staticStyle: { width: "100%" } },
        [
          _c("div", { staticClass: "row-format font-14 align-center" }, [
            _c("div", { staticClass: "brand-medium truncate mr-2" }, [
              _vm._v(_vm._s(_vm.participants.join(" & ")))
            ]),
            _c("div", { staticClass: "ml-auto" }, [
              _vm._v(
                _vm._s(_vm.$formatters.formatTimeAgo(_vm.thread.lastMessage))
              )
            ])
          ]),
          _c("div", { staticClass: "truncate font-gray_80 font-12" }, [
            _vm._v(" " + _vm._s(_vm.thread.lastMessageText) + " ")
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }