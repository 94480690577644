var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format gap-2", staticStyle: { "flex-wrap": "wrap" } },
    [
      _c("quick-link", {
        attrs: {
          name: "Client",
          icon: "clients",
          color: "#5C00E2",
          "background-color": "#deccf9"
        },
        nativeOn: {
          click: function($event) {
            return _vm.createClient()
          }
        }
      }),
      _c("quick-link", {
        attrs: {
          name: "Project",
          icon: "projects",
          color: "#B995F4",
          "background-color": "#f1eafd"
        },
        nativeOn: {
          click: function($event) {
            return _vm.createProject()
          }
        }
      }),
      _c("quick-link", {
        attrs: {
          name: "Agreement",
          icon: "proposals",
          color: "#C90360",
          "background-color": "#f4cddf"
        },
        nativeOn: {
          click: function($event) {
            return _vm.createProposal()
          }
        }
      }),
      _c("quick-link", {
        attrs: {
          name: "Task",
          icon: "deliverable-box",
          color: "#C75029",
          "background-color": "#f4dcd4"
        },
        nativeOn: {
          click: function($event) {
            return _vm.createDeliverable()
          }
        }
      }),
      _c("quick-link", {
        attrs: {
          name: "Time entry",
          icon: "stopwatch",
          color: "#67D5AD",
          "background-color": "#e1f7ef"
        },
        nativeOn: {
          click: function($event) {
            return _vm.createTimeEntry()
          }
        }
      }),
      _c("quick-link", {
        attrs: {
          name: "Expense",
          icon: "accounting",
          color: "#E7A73A",
          "background-color": "#faedd8"
        },
        nativeOn: {
          click: function($event) {
            return _vm.createExpense()
          }
        }
      }),
      _c("quick-link", {
        attrs: {
          name: "Invoice",
          icon: "invoices",
          color: "#2C97DF",
          "background-color": "#d5eaf9"
        },
        nativeOn: {
          click: function($event) {
            return _vm.createInvoice()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }