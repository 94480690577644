var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "discovery-share-send-widget" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.cancel()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _vm.mode === "Select"
        ? _c("div", [
            _c("div", { staticClass: "modal-padding" }, [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("discovery.share.select-method")))
              ]),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: {
                    click: function($event) {
                      _vm.mode = "Send"
                    }
                  }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("$email")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("discovery.share.send-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("discovery.share.send-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("discovery.share.send-next")))
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "selector-tile",
                  on: { click: _vm.shareViaLink }
                },
                [
                  _c("div", [_c("v-icon", [_vm._v("$share")])], 1),
                  _c("div", [
                    _c("h3", { staticClass: "modal-title" }, [
                      _vm._v(_vm._s(_vm.$t("discovery.share.share-title")))
                    ]),
                    _c("div", { staticClass: "modal-subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("discovery.share.share-desc")))
                    ]),
                    _c("div", { staticClass: "brand-medium font-14" }, [
                      _vm._v(_vm._s(_vm.$t("discovery.share.share-next")))
                    ])
                  ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm.mode === "Share"
        ? _c("div", [
            _c("div", { staticClass: "modal-padding" }, [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("discovery.share.share-title")))
              ]),
              _c(
                "div",
                {
                  staticClass: "d-flex align-center justify-space-between my-5"
                },
                [
                  _c(
                    "div",
                    { staticClass: "mr-1", staticStyle: { flex: "1 1 auto" } },
                    [
                      _c("input", {
                        staticClass: "link-box",
                        attrs: { type: "text", id: "linkBox", readonly: "" },
                        domProps: { value: _vm.shareableLink }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ml-1", staticStyle: { flex: "0 1 auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "super-action",
                          on: {
                            click: function($event) {
                              return _vm.copyLink()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("discovery.share.copy-link")))]
                      )
                    ],
                    1
                  )
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "modal-footer" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action",
                    staticStyle: { width: "140px" },
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.done")) + " ")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.mode === "Send"
        ? _c(
            "div",
            { staticClass: "pa-5" },
            [
              _c("h2", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("proposal.share.send-title")))
              ]),
              _c(
                "v-container",
                { staticClass: "px-0 mt-2", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "rowFormat" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("span", { staticClass: "emailHeader" }, [
                          _vm._v(_vm._s(_vm.$t("global.email-from")) + ": ")
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$store.getters.getFromEmail))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mt-1 mb-2" }),
                  _c(
                    "v-row",
                    { staticClass: "rowFormat" },
                    [
                      _c("v-col", { attrs: { cols: "11" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "flex-wrap": "wrap"
                            },
                            on: {
                              click: function($event) {
                                _vm.toMenu = true
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "emailHeader" }, [
                              _vm._v(_vm._s(_vm.$t("global.email-to")) + ":")
                            ]),
                            _c("span", { staticStyle: { cursor: "pointer" } }, [
                              _vm._v(_vm._s(_vm.formatEmail(_vm.toEmail)))
                            ])
                          ]
                        )
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "1", align: "right" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "min-width": "570",
                                "nudge-left": "530",
                                "nudge-top": "12"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  icon: "",
                                                  small: "",
                                                  rounded: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("$chevronDown")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2832106106
                              ),
                              model: {
                                value: _vm.toMenu,
                                callback: function($$v) {
                                  _vm.toMenu = $$v
                                },
                                expression: "toMenu"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "background-color": "var(--v-white-base)"
                                  }
                                },
                                [
                                  _vm.toEmailMode === "selectContact"
                                    ? _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pl-5",
                                                  attrs: {
                                                    cols: "10",
                                                    align: "left"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.toMenu = false
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.toEmail,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.toEmail = $$v
                                                        },
                                                        expression: "toEmail"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.contacts,
                                                      function(contact) {
                                                        return _c("v-radio", {
                                                          key: contact.email,
                                                          attrs: {
                                                            "hide-details": true,
                                                            label: _vm.formatEmail(
                                                              contact
                                                            ),
                                                            value: contact
                                                          }
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "pl-1 mt-4",
                                                      attrs: {
                                                        text: "",
                                                        large: "",
                                                        color: "primary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.toEmailMode =
                                                            "createContact"
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("$plus")]
                                                      ),
                                                      _vm._v(" Add new contact")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "2",
                                                    align: "right"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        rounded: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.closeToMenu()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("$chevronUp")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.toEmailMode === "createContact"
                                    ? _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "10" } },
                                                [
                                                  _c(
                                                    "h3",
                                                    { staticClass: "ml-12" },
                                                    [
                                                      _vm._v(
                                                        "Create new contact"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "2",
                                                    align: "right"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        rounded: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.closeToMenu()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("$chevronUp")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "rowFormat px-6" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": true,
                                                      label: "First name"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newContact
                                                          .firstName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.newContact,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newContact.firstName"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": true,
                                                      label: "Last name"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newContact.lastName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.newContact,
                                                          "lastName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newContact.lastName"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "rowFormat px-6" },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": true,
                                                      label: "Email"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newContact.email,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.newContact,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newContact.email"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    align: "right"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        large: "",
                                                        color: "schooner_20",
                                                        width: "100",
                                                        disabled:
                                                          _vm.processingCreate
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.cancelCreateNewContact()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Cancel")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        large: "",
                                                        color: "primary",
                                                        width: "150",
                                                        disabled:
                                                          _vm.processingCreate
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.createNewContact()
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("Save")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mt-1 mb-2" }),
                  _c(
                    "v-row",
                    { staticClass: "rowFormat" },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row"
                            }
                          },
                          [
                            _c("span", { staticClass: "emailHeader" }, [
                              _vm._v(
                                _vm._s(_vm.$t("global.email-subject")) + ": "
                              )
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.subject,
                                  expression: "subject"
                                }
                              ],
                              staticStyle: {
                                outline: "none",
                                width: "100%",
                                color: "var(--v-black-base)"
                              },
                              attrs: { type: "text" },
                              domProps: { value: _vm.subject },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.subject = $event.target.value
                                }
                              }
                            })
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mt-1 mb-2" }),
                  _c(
                    "v-row",
                    { staticClass: "rowFormat" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.initialEmailContent
                            ? _c("editor", {
                                ref: "bodyEditor",
                                attrs: {
                                  "api-key": _vm.$store.getters.getTinyMceKey,
                                  inline: false,
                                  "initial-value": _vm.initialEmailContent,
                                  init: _vm.mceConfigText,
                                  spellcheck: true
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.mode === "Send"
        ? _c(
            "div",
            { staticClass: "modal-footer row-format centered" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  attrs: { width: "120", disabled: _vm.disabled },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c("send-email-button", {
                staticClass: "ml-1",
                attrs: { width: 150, disabled: _vm.disabled || !this.toEmail },
                on: {
                  click: function($event) {
                    return _vm.sendDiscoveryRequest($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }