<template>
	<div v-if="local">
		<div class="type-modal-dialog-div">
			<v-btn class="close-dialog" icon rounded @click.prevent="handleClose()">
				<v-icon size="20">$close</v-icon>
			</v-btn>
			<div class="modal-padding column-format gap-3">
				<h3 class="modal-title">Request type settings</h3>
				<v-text-field
					v-model="local.type"
					hide-details
					persistent-placeholder
					dense
					outlined
					label="Type"
				></v-text-field>
				<v-select
					v-model="local.attachedFormId"
					:items="templates"
					item-value="id"
					item-text="name"
					hide-details
					persistent-placeholder
					dense
					outlined
					label="Attached form"
				></v-select>
				<div class="text-left">
					<div class="brand-medium">Status options</div>
					<draggable v-model="local.statusList" group="options" handle=".nestedHandle">
						<div v-for="(status, index) in local.statusList" :key="status + '-' + index" class="row-format align-center mb-2">
							<v-icon style="cursor: pointer" class="nestedHandle mt-1"> $dragSecondary </v-icon>
							<v-text-field
								:value="status"
								:dense="true"
								:hide-details="true"
								class="ml-2"
								@change="handleOptionChange(index, $event)"
							>
							</v-text-field>
							<v-icon small @click.stop="removeOption(index)" class="pointer ml-4">$close</v-icon>
						</div>
					</draggable>
					<div @click="addOption()" class="font-14 font-primary pointer mt-3">+ Add status</div>
				</div>
			</div>
		</div>
		<div style="border-top: 1px solid var(--v-gray_50-base)" class="pt-2 pb-3 row-format centered gap-2">
			<v-btn class="super-action" width="150" @click="handleSave()">Save</v-btn>
		</div>
	</div>
</template>

<script>
	import draggable from 'vuedraggable';

	export default {
		name: 'TicketTypeModal',
		components: { draggable },
		props: ['ticketType', 'templates'],

		data() {
			return {
				local: null,
			};
		},

		mounted() {
			this.local = JSON.parse(JSON.stringify(this.ticketType));
		},

		methods: {
			handleSave() {
				this.$emit('result', this.local);
			},

			handleClose() {
				this.$emit('result', false);
			},

			addOption: function () {
				this.local.statusList.push('');
			},

			removeOption: function (index) {
				this.local.statusList.splice(index,1);
			},

			handleOptionChange: function (index, option) {
				this.local.statusList.splice(index,1,option);
			},
		},
	};
</script>

<style lang="scss">
	.type-modal-dialog-div {
		background-color: var(--v-white-base);
		position: relative;
		border-radius: 4px !important;
		min-width: 500px;

		button.v-btn.close-dialog {
			right: 4px !important;
			top: 6px !important;
		}
	}
</style>
