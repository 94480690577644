var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table", attrs: { id: "fields-table" } },
    [
      _c("thead", [
        _c("tr", [
          _c("th", { staticStyle: { "text-align": "center", width: "50px" } }),
          _c("th", [_vm._v("Icon")]),
          _c("th", [_vm._v("Field name")]),
          _c("th", [_vm._v("Mapping key")]),
          _c("th", [_vm._v("Type")]),
          _c("th", [_vm._v("Options")]),
          _vm.maxPerCard > 0 ? _c("th", [_vm._v("Show on card")]) : _vm._e(),
          _c("th", { staticStyle: { "text-align": "center", width: "50px" } })
        ])
      ]),
      _vm.customFields && _vm.customFields.fields.length
        ? _c(
            "draggable",
            {
              key: _vm.refreshKey,
              attrs: { group: "options", tag: "tbody", handle: ".drag" },
              on: { change: _vm.saveFields },
              model: {
                value: _vm.customFields.fields,
                callback: function($$v) {
                  _vm.$set(_vm.customFields, "fields", $$v)
                },
                expression: "customFields.fields"
              }
            },
            _vm._l(_vm.customFields.fields, function(field) {
              return _c(
                "tr",
                { key: field.id, staticClass: "font-14 text-left" },
                [
                  _c(
                    "td",
                    { staticStyle: { width: "50px", "text-align": "center" } },
                    [
                      _c("v-icon", { staticClass: "drag" }, [
                        _vm._v("drag_handle")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("icon-selector", {
                        on: { input: _vm.saveFields },
                        model: {
                          value: field.icon,
                          callback: function($$v) {
                            _vm.$set(field, "icon", $$v)
                          },
                          expression: "field.icon"
                        }
                      })
                    ],
                    1
                  ),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticClass: "pa-1",
                        attrs: { contenteditable: "true" },
                        on: {
                          blur: function($event) {
                            return _vm.setName(field, $event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(field.name))]
                    )
                  ]),
                  _c("td", [
                    _c(
                      "div",
                      {
                        staticClass: "pa-1",
                        attrs: { contenteditable: "true" },
                        on: {
                          blur: function($event) {
                            return _vm.setMappingKey(field, $event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(field.mappingKey))]
                    )
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-click": true,
                            "close-on-content-click": true
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "div",
                                      _vm._g({ staticClass: "pointer" }, on),
                                      [_vm._v(_vm._s(field.type))]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-left font-14 more-menu",
                              staticStyle: {
                                "background-color": "var(--v-white-base)"
                              }
                            },
                            _vm._l(_vm.types, function(type) {
                              return _c(
                                "div",
                                {
                                  key: type,
                                  staticClass:
                                    "pl-3 py-2 pointer more-menu-item",
                                  on: {
                                    click: function($event) {
                                      return _vm.setType(field, type)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(type) + " ")]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _vm.isOptionType(field)
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-click": true,
                                "close-on-content-click": false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            { staticClass: "pointer pr-2" },
                                            on
                                          ),
                                          [
                                            field.options.length
                                              ? _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.formatOptions(
                                                        field.options
                                                      )
                                                    )
                                                  )
                                                ])
                                              : _c("div", [_vm._v("--")])
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-left font-14 more-menu",
                                  staticStyle: {
                                    "background-color": "var(--v-white-base)"
                                  }
                                },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      attrs: {
                                        group: "test",
                                        handle: ".nested-drag"
                                      },
                                      on: { change: _vm.saveFields },
                                      model: {
                                        value: field.options,
                                        callback: function($$v) {
                                          _vm.$set(field, "options", $$v)
                                        },
                                        expression: "field.options"
                                      }
                                    },
                                    _vm._l(field.options, function(
                                      option,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "pointer more-menu-item row-format align-center fill-width gap-2"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "nested-drag" },
                                            [_vm._v("drag_handle")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "fill-width py-2",
                                              attrs: {
                                                contenteditable: "true"
                                              },
                                              on: {
                                                blur: function($event) {
                                                  return _vm.setOption(
                                                    field,
                                                    index,
                                                    $event
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" " + _vm._s(option) + " ")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "ml-auto material-symbols-rounded",
                                              attrs: { size: "20" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteOption(
                                                    field,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("delete")]
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "more-menu-item",
                                      on: {
                                        click: function($event) {
                                          return _vm.addOption(field)
                                        }
                                      }
                                    },
                                    [_vm._v("+ Add option")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _c("div", [_vm._v("--")])
                    ],
                    1
                  ),
                  _vm.maxPerCard > 0
                    ? _c(
                        "td",
                        [
                          _c("v-switch", {
                            staticClass: "mt-n1",
                            attrs: { "hide-details": "", dense: "" },
                            on: {
                              change: function($event) {
                                return _vm.setShowOnCard(field)
                              }
                            },
                            model: {
                              value: field.showOnCard,
                              callback: function($$v) {
                                _vm.$set(field, "showOnCard", $$v)
                              },
                              expression: "field.showOnCard"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "td",
                    { staticStyle: { "text-align": "center", width: "50px" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "20" },
                          on: {
                            click: function($event) {
                              return _vm.confirmDelete(field)
                            }
                          }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        : _c("tr", [
            _c(
              "td",
              {
                staticClass: "pa-4 font-gray_70",
                staticStyle: { "text-align": "center" },
                attrs: { colspan: "5" }
              },
              [_vm._v("No custom fields defined")]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }