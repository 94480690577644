var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-click": true,
        "close-on-content-click": false,
        "offset-y": ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("div", _vm._g({ staticClass: "pointer" }, on), [
                _vm.linkedTicket
                  ? _c(
                      "div",
                      [
                        _vm.linkedTicket.ticket.ticketNumber
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.linkedTicket.ticket.ticketNumber) +
                                  ": "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(
                          _vm._s(
                            _vm.truncateString(
                              _vm.linkedTicket.ticket.subject,
                              30
                            )
                          ) + "  "
                        ),
                        _c("span", { staticClass: "status" }, [
                          _vm._v(_vm._s(_vm.linkedTicket.ticket.status))
                        ]),
                        _c(
                          "v-icon",
                          {
                            staticClass: "ml-2",
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openTicket(_vm.linkedTicket)
                              }
                            }
                          },
                          [_vm._v("open_in_new")]
                        )
                      ],
                      1
                    )
                  : _c("div", [_vm._v("--")])
              ])
            ]
          }
        }
      ]),
      model: {
        value: _vm.ticketMenu,
        callback: function($$v) {
          _vm.ticketMenu = $$v
        },
        expression: "ticketMenu"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "column-format pa-2",
          staticStyle: { "background-color": "var(--v-white-base)" }
        },
        [
          _c(
            "div",
            {
              staticClass: "text-left column-format show-scrollbar",
              staticStyle: { "max-height": "300px", "overflow-y": "scroll" }
            },
            [
              _vm._l(_vm.tickets, function(ticket) {
                return _c(
                  "div",
                  {
                    key: ticket.id,
                    staticClass:
                      "row-format align-center px-3 py-2 pointer hover-gray",
                    staticStyle: {
                      "border-bottom": "1px solid var(--v-gray_30-base)"
                    },
                    on: {
                      click: function($event) {
                        _vm.ticketId = ticket.id
                        _vm.ticketMenu = false
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "brand-medium" }, [
                      ticket.ticketNumber
                        ? _c("span", [
                            _vm._v(_vm._s(ticket.ticketNumber) + ": ")
                          ])
                        : _vm._e(),
                      _vm._v(_vm._s(ticket.subject) + " "),
                      _c("span", { staticClass: "status" }, [
                        _vm._v(_vm._s(ticket.status))
                      ])
                    ])
                  ]
                )
              }),
              _c(
                "div",
                {
                  staticClass:
                    "row-format align-center px-3 py-2 pointer hover-gray",
                  staticStyle: {
                    "border-bottom": "1px solid var(--v-gray_30-base)"
                  },
                  on: {
                    click: function($event) {
                      _vm.ticketId = null
                      _vm.ticketMenu = false
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "brand-medium row-format align-center gap-1"
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "24" }
                        },
                        [_vm._v("link_off")]
                      ),
                      _c("div", [_vm._v("Unlink ticket")])
                    ],
                    1
                  )
                ]
              )
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }