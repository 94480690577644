<template>
	<div class="panel-modal" style="width: 600px" v-if="isReady">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small color="gray_90" class="mr-2 pointer" @click.stop="$emit('result')">$chevronRight</v-icon>
				<div class="brand-medium font-18">Edit project settings</div>
				<div class="ml-auto row-format align-center gap-2">
					<pipeline-link-widget
						:item-id="project.id"
						item-type="Project"
						:client-id="project.clientId"
					></pipeline-link-widget>
					<v-btn class="super-action" @click="updateProject">
						{{ $t('global.save') }}
					</v-btn>
					<v-icon size="20" color="gray_90" class="pointer material-symbols-rounded" @click="confirmDeleteProject">delete</v-icon>
				</div>
			</div>
		</div>

		<div class="panel-modal-body show-scrollbar">
			<v-form v-model="valid" @submit.prevent>
				<div class="column-format gap-3 mt-2">
					<v-text-field
						hide-details
						dense
						outlined
						persistent-placeholder
						:label="$t('projects.project-name')"
						v-model="project.name"
						:rules="[(v) => (v && v.trim().length > 0) || 'Required.']"
					>
					</v-text-field>

					<date-selector
						:standard="true"
						:date="project.startDate"
						@change="project.startDate = $event"
						label="Start date"
					></date-selector>

					<date-selector
						:standard="true"
						:date="project.dueDate"
						@change="project.dueDate = $event"
						label="Due date"
					></date-selector>

					<v-select
						v-if="isPaidAccount"
						:items="portalAccessTypes"
						v-model="project.portalAccess"
						persistent-placeholder
						hide-details
						dense
						outlined
						item-value="value"
						item-text="label"
						label="Client portal access"
					>
					</v-select>

					<v-select
						v-if="isPaidAccount"
						:items="showTimeInPortal"
						v-model="project.showTimeWorkedInPortal"
						hide-details
						persistent-placeholder
						dense
						outlined
						item-value="value"
						item-text="label"
						label="Show time worked in portal"
					>
					</v-select>

					<v-select
						:items="feeTypes"
						v-model="project.feeSchedule.feeType"
						hide-details
						persistent-placeholder
						item-text="label"
						item-value="value"
						dense
						outlined
						label="Project fee type"
					>
					</v-select>

					<div v-if="project.feeSchedule.feeType !== 'Per Item'" class="row-format">
						<v-text-field
							v-model="project.feeSchedule.amount"
							:prefix="currencySymbol"
							hide-details
							persistent-placeholder
							dense
							outlined
							oninput="if(this.value < 0) this.value = this.value*-1;"
							type="number"
							label="Amount"
						></v-text-field>
						<v-checkbox v-if="taxEnabled" dense hide-details class="ml-2" v-model="project.feeSchedule.taxable"
							><template v-slot:label><span class="font-14 ml-n2">Taxable</span></template></v-checkbox
						>
					</div>

					<div v-if="project.feeSchedule.feeType === 'Retainer'" class="column-format gap-3">
						<v-select
							:items="schedule"
							v-model="project.feeSchedule.retainerSchedule"
							hide-details
							persistent-placeholder
							dense
							outlined
							label="Recurring period"
						>
							<template v-slot:item="{ item }">
								<span>{{ item }}</span>
							</template>
						</v-select>

						<v-select
							:items="retainerTiming"
							v-model="project.feeSchedule.retainerTiming"
							hide-details
							persistent-placeholder
							dense
							outlined
							label="Recurring timing"
						>
							<template v-slot:item="{ item }">
								<span>{{ item }}</span>
							</template>
						</v-select>

						<div class="row-format gap-2">
							<date-selector
								style="flex: 1 1 0"
								:standard="true"
								:date="project.feeSchedule.retainerStart"
								@change="project.feeSchedule.retainerStart = $event"
								label="Recurring start date"
							></date-selector>

							<v-select
									style="flex: 1 1 0"
								:items="periods"
								v-model="project.feeSchedule.retainerPeriods"
								hide-details
								persistent-placeholder
								dense
								outlined
								item-text="label"
								item-value="value"
								label="Billable periods"
							>
							</v-select>
						</div>
					</div>

					<div class="row-format gap-2">
						<v-text-field
							v-model="project.feeSchedule.estimateMin"
							label="Hour estimate (min)"
							hide-details
							persistent-placeholder
							dense
							outlined
							type="number"
							min="0"
							max="10000"
							@change="validateMinMax('min')"
						></v-text-field>

						<v-text-field
							v-model="project.feeSchedule.estimateMax"
							label="Hour estimate (max)"
							hide-details
							persistent-placeholder
							dense
							outlined
							type="number"
							min="0"
							max="10000"
							@change="validateMinMax('max')"
						></v-text-field>
					</div>

					<v-text-field
						v-if="project.feeSchedule.feeType === 'Retainer' && project.feeSchedule.estimateMax"
						v-model="project.feeSchedule.retainerOverageRate"
						:prefix="currencySymbol"
						hide-details
						persistent-placeholder
						dense
						outlined
						oninput="if(this.value < 0) this.value = this.value*-1;"
						type="number"
						label="Recurring overage hourly amount"
					></v-text-field>

					<custom-field
						v-for="field in fields"
						:key="field.id"
						mode="dense"
						:field="field"
						:value="getCustomValue(field.id)"
						@change="handleCustomFieldChange($event)"
					></custom-field>
				</div>

				<div class="mt-4 mb-1">
					<color-selector :value="project.hexColor" label="Calendar highlight" @input="project.hexColor = $event"></color-selector>
				</div>

				<div class="column-format ml-1">
					<v-switch v-model="complete" inset flat dense hide-details label="Set complete">
						<template v-slot:label>
							<span class="font-gray_80">Set complete</span>
						</template>
					</v-switch>
					<div class="font-12 font-gray_80 mt-1 text-left">
						Completed projects are hidden from project management but will remain accessible from within the client
						details.
					</div>
				</div>

				<div class="column-format mt-8" v-if="createPackageAllowed">
					<v-btn elevation="0" @click="createDeliverablePackage">
						<v-icon small>content_copy</v-icon>
						<div>Save project as template</div>
					</v-btn>
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
	import DateSelector from '@/components/DateSelector';
	import ClientPalette from '@/modules/clients/ClientPalette';
	import ClientService from '@/modules/clients/ClientService';
	import ProjectService from '@/modules/projects/ProjectService';
	import ConfirmModal from '@/components/ConfirmModal';
	import CreatePackageFromProjectModal from '@/modules/templates/deliverables/CreatePackageFromProjectModal';
	import CustomField from '@/components/CustomField';
	import ColorSelector from "@/components/ColorSelector";

	export default {
		name: 'ProjectEdit',
		props: ['projectId'],
		isRightModal: true,

		components: {ColorSelector, CustomField, DateSelector, PipelineLinkWidget: () => import('@/modules/pipeline/PipelineLinkWidget') },

		data: function() {
			return {
				projectService: new ProjectService(),
				valid: false,
				isReady: false,
				deleteDialog: false,
				complete: false,
				project: null,
				feeTypes: [
					{ label: 'Hourly', value: 'Hourly' },
					{ label: 'Fixed Price', value: 'Fixed Price' },
					{ label: 'Recurring', value: 'Retainer' },
					{ label: 'Per Item', value: 'Per Item' },
				],
				portalAccessTypes: [
					{ label: 'Full project collaboration', value: 'Full access' },
					{ label: 'Read only project collaboration', value: 'Read only' },
					{ label: 'Overview only', value: 'Overview' },
					{ label: 'Not visible', value: 'None' },
				],
				showTimeInPortal: [
					{ label: 'Yes - time worked is visible in portal', value: true },
					{ label: 'No - time worked is hidden', value: false },
				],
				schedule: ['Weekly', 'Bi-Weekly', 'Monthly', 'Quarterly', 'Bi-Annually', 'Annually'],
				retainerTiming: ['Advanced', 'Arrears'],
				colorMenu: false,
				clientPalette: new ClientPalette(),
				clientService: new ClientService(),
				currency: this.$store.state.defaultCurrency,
			};
		},

		mounted() {
			this.getProject();
		},

		beforeDestroy() {},

		methods: {
			getProject: function() {
				this.projectService.getProjectDetail(this.projectId).then((res) => {
					this.project = res.data;
					if (!this.project.feeSchedule) {
						this.project.feeSchedule = this.createFeeSchedule();
					}
					if (!this.project.hexColor) {
						this.project.hexColor = '#ffffff00';
					}
					(this.complete = !this.project.active), this.getCurrency();
					this.isReady = true;
				});
			},

			getCustomValue: function(id) {
				let value = this.project.customValues.find((c) => c.fieldId === id);
				if (value) {
					return value.value;
				} else {
					return null;
				}
			},

			handleCustomFieldChange: function(value) {
				let ix = this.project.customValues.findIndex((c) => c.fieldId === value.fieldId);
				if (ix > -1) {
					if (value.value != null) {
						this.project.customValues.splice(ix, 1, value);
					} else {
						this.project.customValues.splice(ix, 1);
					}
				} else if (value.value != null) {
					this.project.customValues.push(value);
				}
			},

			confirmDeleteProject() {
				let binding = {
					headingText: this.$t('projects.delete-project'),
					bodyText: this.$t('projects.delete-are-you-sure', { name: this.project.name }),
					confirmText: this.project.name,
					confirmHint: 'Please type project name exactly',
					yesText: this.$t('global.delete'),
					noText: this.$t('global.cancel'),
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.deleteProject();
					}
				});
			},

			deleteProject: function() {
				this.$store.commit('startLoading');
				this.projectService
					.deleteProject(this.projectId)
					.then(() => {
						this.project.deleted = true;
						this.$emit('result', this.project);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			handleClose: function() {
				this.updateProject();
			},

			updateProject: function() {
				this.$store.commit('startLoading');
				this.project.active = !this.complete;
				this.projectService
					.updateProject(this.projectId, this.project)
					.then((res) => {
						this.$emit('result', res.data);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			createDeliverablePackage() {
				let binding = {
					projectId: this.project.id,
				};
				this.$store.state.globalModalController.openModal(CreatePackageFromProjectModal, binding).then((res) => {
					if (res) {
						this.$emit('result');
						setTimeout(() => {
							this.$router.push(`/templates/packages?id=${res.id}`);
						}, 500);
					}
				});
			},

			getCurrency: function() {
				this.clientService.getClientCurrency(this.project.clientId).then((res) => {
					this.currency = res.data;
				});
			},

			validateMinMax: function(minOrMax) {
				let val;

				if (minOrMax === 'min') {
					val = this.feeSchedule.estimateMin;
				} else {
					val = this.feeSchedule.estimateMax;
				}

				if (!val) {
					return;
				}

				val = parseInt(val, 10);

				if (val < 0) {
					val = 0;
				} else if (val > 10000) {
					val = 10000;
				}

				if (minOrMax === 'min') {
					this.feeSchedule.estimateMin = val;
				} else {
					this.feeSchedule.estimateMax = val;
				}
			},

			createFeeSchedule: function() {
				let feeSchedule = {
					feeType: 'Hourly',
					amount: 0,
					retainerSchedule: 'Monthly',
					estimateMin: 0,
					estimateMax: 0,
					retainerTiming: 'Advanced',
					fromProposalId: null,
					fromProposalSignedDate: null,
					updatedBy: null,
					updatedDate: null,
					retainerStart: this.$DateTime
						.local()
						.plus({ days: 1 })
						.toFormat('yyyy-MM-dd'),
					retainerEnd: null,
				};

				return feeSchedule;
			},
		},

		computed: {
			periodsLabel: function() {
				let val = this.project.feeSchedule.retainerSchedule;
				if (val === 'Weekly') {
					return 'weeks';
				} else if (val === 'Bi-Weekly') {
					return 'two week periods';
				} else if (val === 'Monthly') {
					return 'months';
				} else if (val === 'Quarterly') {
					return 'quarters';
				} else if (val === 'Bi-Annually') {
					return 'six month periods';
				} else if (val === 'Annually') {
					return 'years';
				} else {
					return '?';
				}
			},

			periods: function() {
				let result = [];
				result.push({ label: 'Until project complete', value: -1 });
				for (let i = 1; i <= 104; i++) {
					result.push({ label: `${i} ${this.periodsLabel}`, value: i });
				}
				return result;
			},

			fields: function() {
				if (this.$store.state.projectFields) {
					return this.$store.state.projectFields.fields;
				} else {
					return [];
				}
			},
			currencySymbol: function() {
				return this.$formatters.currencySymbol(this.currency);
			},
			isPaidAccount: function() {
				return this.$store.getters.isPaidAccount;
			},
			taxEnabled: function() {
				return this.$store.state.podAccount.accountPreferences.invoiceUseTax;
			},
			createPackageAllowed: function() {
				let isPaid = this.$store.getters.isPaidAccount;
				let userType = this.$store.getters.getUserType;
				return isPaid && (userType === 'OWNER' || userType === 'FULL_USER' || userType === 'IMPLEMENTER');
			},
		},
	};
</script>

<style scoped lang="scss">
	#color-picker-div {
		background-color: white;
		.v-color-picker .v-color-picker__controls {
			display: none !important;
		}
	}

	.hover-button {
		cursor: pointer;
		color: var(--v-gray_80-base);
		&:hover {
			color: var(--v-primary-base);
		}
	}
</style>
