var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Project management" }
            ],
            compact: true
          }
        },
        [_vm._v(" Tasks ")]
      ),
      _c(
        "div",
        { staticClass: "column-format fill-height pb-0 px-0" },
        [_c("project-management")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }