var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.paymentCopy
    ? _c(
        "div",
        { attrs: { id: "payment-detail" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-padding" },
            [
              _c("h3", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.paymentHeading))
              ]),
              _c(
                "v-container",
                { staticClass: "px-0 mt-2 mb-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "column-format gap-3",
                          attrs: { cols: "12", sm: "7" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row-format" },
                            [
                              _c("amount-input", {
                                attrs: {
                                  "hide-details": "",
                                  dense: "",
                                  outlined: "",
                                  label: _vm.$t("income.amount-label"),
                                  placeholder: _vm.$t(
                                    "income.amount-placeholder"
                                  ),
                                  prefix: _vm.currencySymbol,
                                  type: "text",
                                  disabled: _vm.paymentCopy.locked,
                                  "persistent-placeholder": ""
                                },
                                model: {
                                  value: _vm.paymentCopy.amount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.paymentCopy, "amount", $$v)
                                  },
                                  expression: "paymentCopy.amount"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-3",
                                  staticStyle: { width: "60%" }
                                },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      "hide-details": "",
                                      "persistent-placeholder": "",
                                      dense: "",
                                      outlined: "",
                                      label: _vm.$t("account.currency"),
                                      items: _vm.currencies,
                                      "item-text": "label",
                                      "item-value": "value",
                                      disabled: _vm.paymentCopy.locked
                                    },
                                    model: {
                                      value: _vm.paymentCopy.currency,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.paymentCopy,
                                          "currency",
                                          $$v
                                        )
                                      },
                                      expression: "paymentCopy.currency"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.paymentCopy.currency !==
                          _vm.$store.state.defaultCurrency
                            ? _c(
                                "div",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "persistent-placeholder": "",
                                      "hide-details": "",
                                      label: "Exchange rate (optional)",
                                      type: "number",
                                      step: "0.00001"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append",
                                          fn: function() {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-12 font-gray_70 text-left pr-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        _vm.$formatters.dollars(
                                                          _vm.paymentCopy
                                                            .localAmount
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.$store.state
                                                          .defaultCurrency
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3479793420
                                    ),
                                    model: {
                                      value: _vm.paymentCopy.exchangeRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.paymentCopy,
                                          "exchangeRate",
                                          $$v
                                        )
                                      },
                                      expression: "paymentCopy.exchangeRate"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "persistent-placeholder": "",
                              "hide-details": "",
                              label: _vm.$t(
                                "invoice.mark-paid-modal.payment-type"
                              ),
                              items: _vm.paymentProviders,
                              disabled: _vm.paymentCopy.locked
                            },
                            model: {
                              value: _vm.paymentCopy.paymentProvider,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.paymentCopy,
                                  "paymentProvider",
                                  $$v
                                )
                              },
                              expression: "paymentCopy.paymentProvider"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              label: _vm.$t("income.id-label"),
                              placeholder: _vm.$t("income.id-placeholder"),
                              "persistent-placeholder": "",
                              disabled: _vm.paymentCopy.locked
                            },
                            model: {
                              value: _vm.paymentCopy.identifier,
                              callback: function($$v) {
                                _vm.$set(_vm.paymentCopy, "identifier", $$v)
                              },
                              expression: "paymentCopy.identifier"
                            }
                          }),
                          _vm.paymentCopy.locked
                            ? _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  label: "Source",
                                  "persistent-placeholder": "",
                                  disabled: _vm.paymentCopy.locked
                                },
                                model: {
                                  value: _vm.paymentCopy.paymentSource,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.paymentCopy,
                                      "paymentSource",
                                      $$v
                                    )
                                  },
                                  expression: "paymentCopy.paymentSource"
                                }
                              })
                            : _vm._e(),
                          _c("v-textarea", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                              label: _vm.$t("income.description"),
                              placeholder: _vm.$t(
                                "income.description-placeholder"
                              ),
                              "auto-grow": true,
                              rows: "1",
                              "persistent-placeholder": ""
                            },
                            model: {
                              value: _vm.paymentCopy.description,
                              callback: function($$v) {
                                _vm.$set(_vm.paymentCopy, "description", $$v)
                              },
                              expression: "paymentCopy.description"
                            }
                          }),
                          _c("date-selector", {
                            attrs: {
                              clearable: "",
                              "hide-details": "",
                              "persistent-placeholder": "",
                              standard: true,
                              label: _vm.$t("income.date"),
                              date: _vm.paymentCopy.date,
                              disabled: _vm.paymentCopy.locked
                            },
                            on: {
                              change: function($event) {
                                _vm.paymentCopy.date = $event
                              }
                            }
                          }),
                          _vm.paymentCopy.receiptUrl
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mt-2 row-format align-center pointer font-primary font-14",
                                  on: { click: _vm.openReceipt }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-transform": "capitalize"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " View " +
                                          _vm._s(
                                            _vm.paymentCopy.paymentProvider.toLowerCase()
                                          ) +
                                          " receipt "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary", size: "20" } },
                                    [_vm._v("receipt_long")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", sm: "5" } }, [
                        _c(
                          "div",
                          { staticClass: "column-format gap-3" },
                          [
                            _c("v-autocomplete", {
                              ref: "clientId",
                              attrs: {
                                dense: "",
                                outlined: "",
                                "hide-details": "",
                                "persistent-placeholder": "",
                                label: _vm.$t("client.client"),
                                placeholder: _vm.$t("income.link-to-client"),
                                items: _vm.clientsPlusClear,
                                "item-text": "name",
                                "item-value": "id"
                              },
                              on: { change: _vm.clientChanged },
                              model: {
                                value: _vm.paymentCopy.clientId,
                                callback: function($$v) {
                                  _vm.$set(_vm.paymentCopy, "clientId", $$v)
                                },
                                expression: "paymentCopy.clientId"
                              }
                            }),
                            _vm.paymentCopy.clientId
                              ? _c("v-autocomplete", {
                                  attrs: {
                                    dense: "",
                                    outlined: "",
                                    "hide-details": "",
                                    "persistent-placeholder": "",
                                    label: _vm.$t("projects.project"),
                                    placeholder: _vm.$t(
                                      "income.link-to-project"
                                    ),
                                    disabled: _vm.noClientDisabled,
                                    items: _vm.clientProjectsPlusClear,
                                    "item-text": "name",
                                    "item-value": "id",
                                    "no-data-text":
                                      "No projects for selected client"
                                  },
                                  on: { change: _vm.projectChanged },
                                  model: {
                                    value: _vm.paymentCopy.projectId,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.paymentCopy,
                                        "projectId",
                                        $$v
                                      )
                                    },
                                    expression: "paymentCopy.projectId"
                                  }
                                })
                              : _vm._e(),
                            _vm.paymentCopy && _vm.paymentCopy.id
                              ? _c(
                                  "div",
                                  { staticClass: "text-left" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-left body-12 gray_80--text mb-4"
                                      },
                                      [_vm._v("Attachments")]
                                    ),
                                    _c("file-attachments", {
                                      attrs: {
                                        files: _vm.paymentCopy.attachments,
                                        "file-service": _vm.attachmentService,
                                        "edit-mode": true
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mt-2 pa-2 row-format centered gap-1",
              staticStyle: { "border-top": "1px solid var(--v-gray_30-base)" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "super-action",
                  attrs: {
                    width: _vm.$vuetify.breakpoint.smAndUp ? "160" : "128"
                  },
                  on: { click: _vm.saveIncome }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          _vm.paymentCopy.id === null
                            ? "global.create"
                            : "global.save"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _vm.paymentCopy.id
                ? _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.deletePaymentDialog = true
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-rounded",
                          attrs: { size: "20" }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "modal",
            {
              attrs: {
                dialog: _vm.deletePaymentDialog,
                persistent: true,
                "max-width": 290
              },
              on: {
                close: function($event) {
                  _vm.deletePaymentDialog = false
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "pt-8" },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("income.delete-confirm")) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-3", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary-action mr-2",
                                  on: {
                                    click: function($event) {
                                      _vm.deletePaymentDialog = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("global.no")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: { elevation: "0" },
                                  on: { click: _vm.deleteIncome }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("global.yes")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }