var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.aiSession
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "show-scrollbar column-format gap-2 font-14",
              style: "max-height: " + _vm.maxHeight + "; overflow-y: auto"
            },
            _vm._l(_vm.messages, function(message) {
              return _c(
                "div",
                {
                  key: message.id,
                  class: message.role,
                  attrs: { id: message.id }
                },
                [
                  message.role === "user"
                    ? _c("div", [_vm._v(_vm._s(message.content))])
                    : _c("div", {
                        domProps: {
                          innerHTML: _vm._s(message.formattedContent)
                        }
                      })
                ]
              )
            }),
            0
          ),
          _c("v-textarea", {
            ref: "NewMessage",
            attrs: {
              dense: "",
              "hide-details": "",
              outlined: "",
              rows: "1",
              "no-resize": "",
              "auto-grow": "",
              autofocus: "",
              disabled: _vm.disabled
            },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                $event.preventDefault()
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                return _vm.sendMessageStreaming.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "font-secondary pointer py-1 pl-2",
                          staticStyle: {
                            "border-left": "1px solid var(--v-gray_50-base)",
                            "margin-top": "2px",
                            "min-height": "100%"
                          },
                          on: { click: _vm.sendMessageStreaming }
                        },
                        [_vm._v(" Send ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3824759239
            ),
            model: {
              value: _vm.newMessage,
              callback: function($$v) {
                _vm.newMessage = $$v
              },
              expression: "newMessage"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }