<template>
	<div></div>
</template>

<script>
	export default {
		name: 'CommunicatorRouter',

		props: [],

		components: {},

		data: function() {
			return {};
		},

		mounted() {
			this.getLastViewed();
		},

		beforeDestroy() {},

		methods: {
			getLastViewed: function() {
				let lv = localStorage.getItem('COMMUNICATOR_LAST_VIEWED');

				if(lv !== 'messages' && lv !== 'email-log'){
					lv = 'messages';
				}

				this.$router.push(`/inbox/${lv ? lv : 'messages'}`);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
