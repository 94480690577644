<template>
	<div class="column-format gap-3 px-2">
		<div class="row-format align-center">
			<div class="font-18 brand-medium">Edit {{componentType}}</div>
			<div class="ml-auto row-format align-center gap-2">
				<v-icon size="20" class="material-symbols-rounded pointer" @click="$emit('duplicate')">content_copy</v-icon>
				<v-icon size="20" class="material-symbols-rounded pointer" @click="$emit('delete')">delete</v-icon>
				<v-btn small elevation="0" color="primary" @click="$emit('done')">Done</v-btn>
			</div>
		</div>
		<div  style="max-height: calc(100vh - 120px); overflow-y: auto" class="show-scrollbar pr-2">
			<component :key="item.type" :is="item.type + 'Config'" :item="item" :tokens="tokens" :template="template" @change="$emit('change',$event)"></component>
		</div>
	</div>
</template>

<script>
	import ContainerConfig from "@/modules/templates/invoices/config/ContainerConfig";
	import DividerConfig from "@/modules/templates/invoices/config/DividerConfig";
	import FileInputConfig from "@/modules/templates/invoices/config/FileInputConfig";
	import SpacerConfig from "@/modules/templates/invoices/config/SpacerConfig";
	import ImageBlockConfig from "@/modules/templates/invoices/config/ImageBlockConfig";
	import TextBlockConfig from "@/modules/templates/invoices/config/TextBlockConfig";
	import LogoConfig from "@/modules/templates/invoices/config/LogoConfig";
	import LineItemsConfig from "@/modules/templates/invoices/config/LineItemsConfig";
	import FileAttachmentsConfig from "@/modules/templates/invoices/config/FileAttachmentsConfig";

	export default {
		name: 'ConfigRenderer',

		props: ['template','item','tokens'],

		components: {
			ContainerConfig,
			DividerConfig,
			FileInputConfig,
			ImageBlockConfig,
			SpacerConfig,
			TextBlockConfig,
			LogoConfig,
			LineItemsConfig,
			FileAttachmentsConfig
		},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {

		},

		computed: {
			componentType: function(){
				if(this.item.type === 'Spacer'){
					return 'spacer';
				}else if(this.item.type === 'Divider'){
					return 'divider';
				}else if(this.item.type === 'Container') {
					return 'container';
				}else if(this.item.type === 'ImageBlock') {
					return 'image';
				}else if(this.item.type === 'TextBlock'){
					return 'text';
				}else if(this.item.type === 'Logo'){
					return 'logo';
				}else if(this.item.type === 'LineItems'){
					return 'line items';
				}else{
					return ''
				}
			}
		},
	};
</script>

<style lang="scss">
</style>
