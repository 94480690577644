<template>
	<div v-if="isReady" class="gap-2 column-format">
		<subscriptions v-if="subscription"></subscriptions>
		<new-subscription v-if="!subscription && !twilioAccount" @order-complete="initialize()"></new-subscription>
		<twilio-account v-if="twilioAccount" @refresh="initialize()" :subscription="subscription" :twilio-account="twilioAccount"></twilio-account>
	</div>
</template>

<script>
	import CommunicatorService from '@/modules/communicator/CommunicatorService';
	import NewSubscription from "@/modules/communicator/settings/providers/NewSubscription";
	import Subscriptions from "@/modules/communicator/settings/providers/Subscriptions";
	import TwilioAccount from "@/modules/communicator/settings/providers/twilio/TwilioAccount";
	import TwilioService from "@/modules/communicator/realtime/twilio/TwilioService";

	export default {
		name: 'SubscriptionRouter',

		props: [],

		components: {TwilioAccount, Subscriptions, NewSubscription},

		data: function() {
			return {
				communicatorService: new CommunicatorService(),
				twilioService: new TwilioService(),
				subscription: null,
				twilioAccount: null,
				isReady: false,
			};
		},

		mounted() {
			this.initialize();
		},

		beforeDestroy() {},

		methods: {
			initialize: function(){
				this.isReady = false;
				let p1 = this.getSubscription();
				let p2 = this.getTwilioAccount();
				Promise.all([p1,p2]).then(() => this.isReady = true);
			},

			getSubscription: function() {
				return new Promise((resolve,reject) => {
					this.communicatorService.getSubscription().then((res) => {
						this.subscription = res.data;
						resolve(res.data)
					}).catch((err) => reject(err));
				})
			},

			getTwilioAccount: function() {
				return new Promise((resolve,reject) => {
					this.twilioService.getAccount().then((res) => {
						this.twilioAccount = res.data;
						resolve(res.data)
					}).catch((err) => reject(err));
				})
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
