var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "primary-layout",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                this.user != null
                  ? _c("div", [
                      _vm._v(
                        "Hello " +
                          _vm._s(this.user.firstName) +
                          " " +
                          _vm._s(this.user.lastName)
                      )
                    ])
                  : _vm._e(),
                _c("div", [
                  _vm._v("Your email is: " + _vm._s(this.user.email))
                ]),
                _c("div", [
                  _vm._v("Your user ID is: " + _vm._s(this.user.userId))
                ]),
                _c("div", [
                  _vm._v(
                    "Your log in method is: " + _vm._s(this.user.oidcProvider)
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("div", { staticClass: "mt-6" }, [
                    _vm._v("Having a problem with still seeing sample data?")
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", color: "error" },
                      on: { click: _vm.clearSampleData }
                    },
                    [_vm._v("Clear Sample Data")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }