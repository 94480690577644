var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column-format gap-3 px-2" }, [
    _c("div", { staticClass: "row-format align-center" }, [
      _c("div", { staticClass: "font-18 brand-medium" }, [
        _vm._v("Edit " + _vm._s(_vm.componentType))
      ]),
      _c(
        "div",
        { staticClass: "ml-auto row-format align-center gap-2" },
        [
          _c(
            "v-icon",
            {
              staticClass: "material-symbols-rounded pointer",
              attrs: { size: "20" },
              on: {
                click: function($event) {
                  return _vm.$emit("duplicate")
                }
              }
            },
            [_vm._v("content_copy")]
          ),
          _c(
            "v-icon",
            {
              staticClass: "material-symbols-rounded pointer",
              attrs: { size: "20" },
              on: {
                click: function($event) {
                  return _vm.$emit("delete")
                }
              }
            },
            [_vm._v("delete")]
          ),
          _c(
            "v-btn",
            {
              attrs: { small: "", elevation: "0", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.$emit("done")
                }
              }
            },
            [_vm._v("Done")]
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      {
        staticClass: "show-scrollbar pr-2",
        staticStyle: {
          "max-height": "calc(100vh - 120px)",
          "overflow-y": "auto"
        }
      },
      [
        _c(_vm.item.type + "Config", {
          key: _vm.item.type,
          tag: "component",
          attrs: { item: _vm.item, tokens: _vm.tokens, template: _vm.template },
          on: {
            change: function($event) {
              return _vm.$emit("change", $event)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }