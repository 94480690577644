import HttpClient from '../../services/HttpClient';

export default class DeliverableStatusService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getDeliverableStatusForAccount(){
		return this.httpClient.get("/deliverableStatus")
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateDeliverableStatusForAccount(deliverableStatusList){
		return this.httpClient.put("/deliverableStatus",deliverableStatusList)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
