<template>
	<div class="pa-6" style="min-width: calc(100vw - 200px); min-height: calc(100vh - 200px)">
		<div class="row-format">
			<div v-if="!errorState">New email</div>
			<v-btn icon @click="cancel" class="ml-auto">
				<v-icon>$close</v-icon>
			</v-btn>
		</div>
		<email-editor
			v-if="showEditor && isReady"
			:email-boxes="emailBoxes"
			:to-list="toList"
			:show-subject="true"
			:opportunity-id="opportunityId"
			:client-id="clientId"
			@send-email="sendEmail($event)"
			@cancel="cancel()"
		></email-editor>
		<div v-if="errorState" class="text-center column-format" style="gap: 8px">
			<div class="row-format centered">
				<v-icon color="error">$alert</v-icon>
				<div class="brand-medium">No communicator mailbox connected</div>
			</div>
			<div class="font-14">You will need to connect an email account in communicator to use this feature.  Would you like to do that now?</div>
			<div class="row-format centered mt-4" style="gap: 8px">
				<v-btn class="super-action" @click="routeToConfig()">Yes</v-btn>
				<v-btn class="primary-action" @click="$emit('result')">No</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
	import EmailEditor from '@/modules/communicator/inbox/email/EmailEditor';
	import CommunicatorEmailService from '@/modules/communicator/CommunicatorEmailService';
	import {DateTime} from "luxon";

	export default {
		name: 'NewEmail',

		props: {
			toList: {
				type: Array,
				required: false,
				default: () => [],
			},
			opportunityId: {
				type: String,
				required: false,
				default: null,
			},
			clientId: {
				type: String,
				required: false,
				default: null,
			},
		},

		components: { EmailEditor },

		data: function () {
			return {
				showEditor: false,
				isReady: false,
				errorState: false,
				communicatorEmailService: new CommunicatorEmailService(),
				emailBoxes: [],
				test: []
			};
		},

		mounted() {
			if(this.clientId && this.toList.length === 0){
				let result = this.$store.getters.getContactsByClientId(this.clientId).filter(c => c.defaultContact && c.email);
				if(result.length){
					this.toList.push(result[0].email);
				}
			}

			this.getMailBoxes();
			this.$nextTick(() => {
				setTimeout(() => {
					this.showEditor = true;
				}, 100);
			});
		},

		beforeDestroy() {
			this.showEditor = false;
		},

		methods: {
			getMailBoxes: function () {
				let userId = this.$store.getters.getLoggedInUserId;
				
				this.communicatorEmailService.getEmailBoxes().then((res) => {
					this.emailBoxes.splice(0, this.emailBoxes.length);
					this.emailBoxes.push(...res.data);

					this.emailBoxes.forEach(e => {
						e.sortOrder = e.userId === userId ? 0 : 1
					})

					this.emailBoxes.sort(this.sortEmailBoxes);
					if(this.emailBoxes.length === 0){
						this.errorState = true;
						this.isReady = false;
					}else{
						this.isReady = true;
					}
				});
			},

			sortEmailBoxes: function(a,b){
				return a.sortOrder - b.sortOrder;
			},

			routeToConfig: function(){
				this.$emit('result')
				setTimeout(() => this.$router.push('/communicator/mailboxes'),500);
			},

			sendEmail: function (event) {
				let sendEmail = event.email;
				let attachments = event.attachments;
				let s3Attachments = event.s3Attachments;
				let mailboxId = event.from;
				let sendLater = event.sendLater;

				sendEmail.opportunityId = this.opportunityId;

				this.$store.commit('startLoading');
				this.communicatorEmailService
					.sendNewEmail(mailboxId, sendEmail, attachments, s3Attachments,sendLater)
					.then((res) => {
						if(sendLater){
							this.$store.commit('success', `Email successfully scheduled for ${DateTime.fromISO(sendLater).toLocaleString(DateTime.DATETIME_MED)}`);
						}
						this.$emit('result', res.data);
					})
					.catch((err) => {
						this.$store.commit('error', 'Error sending email: ' + err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			cancel: function () {
				this.$emit('result');
			},
		},

		computed: {

		},
	};
</script>

<style scoped lang="scss"></style>
