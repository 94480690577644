<template>
	<div :class="`universal-search-result pointer ${selected ? 'universal-search-selected' : ''}`" @click="$emit('click',$event)">
		<div v-if="result.type === 'Feature'" class="row-format gap-1 font-14">
			<v-icon class="material-symbols-rounded" size="20" color="black">{{result.icon ? result.icon : 'info'}}</v-icon>
			<div class="brand-medium">{{result.label}}</div>
		</div>
		<div v-else-if="result.type === 'Command'" class="column-format gap-1 font-14">
			<div class="row-format align-center gap-1">
				<div class="brand-medium">{{result.name}}</div>
			</div>
			<div class="font-12 text-left font-gray_70">
				{{result.description}}
			</div>
		</div>
		<div v-else class="column-format gap-1">
			<div class="row-format align-center gap-1">
				<v-icon class="material-symbols-rounded" size="20" color="black">{{ icon }}</v-icon>
				<client-avatar :client="client" v-if="client" x-small=""></client-avatar>
				<div class="font-14 brand-medium">{{ object.label }}</div>
			</div>
			<div class="font-12 text-left font-gray_70">
				<div v-for="(highlight, index) in result.highlights.content" v-html="highlight" :key="index"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	export default {
		name: 'Result',

		props: ['result', 'selected'],

		components: { ClientAvatar },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			client: function() {
				if (this.object.clientId) {
					return this.$store.getters.getClientById(this.object.clientId);
				} else {
					return null;
				}
			},

			object: function() {
				return this.result.searchObject;
			},

			icon: function() {
				switch (this.object.type) {
					case 'CLIENT':
						return 'group';
					case 'PROJECT':
						return 'folder_special';
					case 'INVOICE':
						return 'request_quote';
					case 'TICKET':
						return 'local_activity';
					case 'CONTACT':
						return 'person';
					case 'AGREEMENT':
						return 'handshake';
					case 'OPPORTUNITY':
						return 'filter_alt';
					case 'TASK':
						return 'splitscreen';
					case 'FORM':
						return 'list_alt';
					case 'MEETING':
						return 'event_available';

				}

				return 'info';
			},
		},
	};
</script>

<style lang="scss">
	.universal-search .universal-search-selected {
		border: 1px solid var(--v-primary-base);
	}

	.universal-search-result {
		background-color: var(--v-gray_10-base);
		border: 1px solid var(--v-gray_10-base);
		padding: 4px 12px;
		border-radius: 4px;

		&:hover{
			background-color: var(--v-gray_20-base);
		}

		em {
			background-color: rgb(255, 255, 153, 0.3);
			padding-left: 2px;
			padding-right: 2px;
		}
	}
</style>
