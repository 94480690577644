<template>
	<notification-layout :id="id" :action-text="$t('notifications.view-deliverable')" @action="navigateToDeliverable()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small :client="client" />
			<div class="ml-2">{{ projectName }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium">{{ $t('notifications.deliverable-approved') }}</div>
			<div class="font-12 text-left">
				<span> "{{ deliverableName }}" • Updated by {{ approvedBy }} </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from '@/modules/productivity/notifications/NotificationLayout';
	import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';

	export default {
		name: 'DeliverableApprovedNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar, NotificationLayout },

		data: function () {
			return {
				deliverableName: this.notificationPayload.deliverableName,
				projectName: this.notificationPayload.projectName,
				approvedBy: this.notificationPayload.approvedBy,
				client: this.notificationPayload.client,
				deliverableId: this.notificationPayload.deliverableId,
				projectId: this.notificationPayload.projectId,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			navigateToDeliverable: function () {
				let binding = {
					deliverableId: this.deliverableId,
					project: {
						id: this.projectId,
						name: this.projectName,
					},
					client: this.client,
				};
				this.$store.state.globalModalController.openModal(DeliverableDetail, binding);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
