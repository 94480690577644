var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "500px" } }, [
    _c("div", { staticClass: "modal-padding" }, [
      _c("div", { staticClass: "row-format align-center my-5" }, [
        _c("div", { staticClass: "mr-1", staticStyle: { width: "400px" } }, [
          _c("input", {
            staticClass: "link-box",
            staticStyle: { width: "100%" },
            attrs: { type: "text", id: "linkBox", readonly: "" },
            domProps: { value: _vm.link }
          })
        ]),
        _c(
          "div",
          { staticClass: "ml-1" },
          [
            _c(
              "v-btn",
              {
                staticClass: "super-action",
                on: {
                  click: function($event) {
                    return _vm.copyLink()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("proposal.share.copy-link")))]
            )
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "modal-footer" },
      [
        _c(
          "v-btn",
          {
            staticClass: "primary-action",
            staticStyle: { width: "140px" },
            on: {
              click: function($event) {
                return _vm.cancel()
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("global.done")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }