<template>
	<div id="notes-embed" ref="notesEmbed">
		<div :class="'pt-4 px-0 pb-0 mb-0 text-left notes ' + notesCssClass">
			<div class="d-flex justify-start align-center px-2 mt-4 mb-6">
				<v-icon size="20" color="black">$notes</v-icon>
				<span class="body-16 brand-semibold ml-2 black--text">Notes</span>
			</div>

			<div class="px-2 mb-1">
				<markdown
					:key="newNoteKey"
					:value="newNote"
					id="0"
					:placeholder="$t('leadgen.add-a-new-note')"
					:input-only-mode="true"
					@update="addNote($event)"
				/>
			</div>
			<div v-for="note in notesFiltered" :key="note.id" class="note py-3">
				<div class="d-flex justify-space-between px-2">
					<div class="body-14 grey--text" v-if="note.id == '0'">
						{{ $t('note.create-new-note') }}
					</div>
					<div class="body-14 grey--text" v-else>
						{{ $DateTime.humanDateTime(note.timestamp) }}
						<span class="ml-3 body-14 swirl--text" v-if="note.timestampUpdated">
							{{ $t('note.edited', { date: $DateTime.humanDateTime(note.timestampUpdated) }) }}
						</span>
					</div>

					<div class="icons pa-0 ma-0">
						<!--v-btn x-small depressed icon @click="handleTinyFocus(note)" class="mx-3 edit">
								<v-icon>$edit</v-icon>
							</v-btn-->
						<v-btn
							v-if="note.id !== '0'"
							x-small
							depressed
							icon
							@click="handleDeleteNoteClick(note.id)"
							class="delete mt-n2"
						>
							<v-icon>$delete</v-icon>
						</v-btn>
					</div>
				</div>

				<div class="brand-regular body-16 mt-1 px-2" @mouseover="fading = false">
					<markdown
						:value="note.entry"
						:id="note.id"
						:placeholder="$t('note.input-placeholder')"
						:input-only-mode="note.id == '0' ? true : false"
						@update="updateNote($event, note.id)"
						@focus="fading = false"
						@blur="fading = true"
					/>
				</div>
				<div v-if="fading && truncate && notes.length > maxNotes" class="fading"></div>
			</div>
		</div>
		<div class="px-2 pb-2 chevron-div" v-if="notes.length > maxNotes">
			<div v-if="truncate" @click="setTruncate(false)"><v-icon>$chevronDown</v-icon></div>
			<div v-if="!truncate" @click="setTruncate(true)">
				<v-icon>$chevronUp</v-icon>
			</div>
		</div>
		<div class="px-2 pb-2" v-else></div>

		<modal
			:dialog="deleteNoteDialog"
			:persistent="false"
			v-on:close="deleteNoteDialog = false"
			:max-width="400"
			ref="deleteNoteModal"
		>
			<v-container justify="center" align="center" class="pt-0 px-4 pb-5">
				<h1>
					<v-icon color="primary" size="45">$notes</v-icon>
					{{ $t('note.delete-note') }}
				</h1>
				<p class="my-6 text-black text-center" v-html="$t('note.delete-are-you-sure')" />
				<v-row>
					<v-col cols="12" class="px-12">
						<v-btn large class="primary-action mr-2" @click.prevent="closeDeleteDialog()" ref="cancelButton">{{
							$t('global.cancel')
						}}</v-btn>
						<v-btn large text class="secondary-action ml-2" @click="deleteNote()" ref="deleteButton">
							{{ $t('global.delete') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</modal>
	</div>
</template>

<script>
	import NoteService from '@/services/NoteService';
	import Modal from '@bit/hecticapp.common.modal';
	import smoothReflow from 'vue-smooth-reflow';
	import Markdown from '@/components/Markdown';

	export default {
		mixins: [smoothReflow],

		name: 'NotesEmbed',

		props: { clientId: { type: String }, projectId: { type: String, default: null } },

		components: {
			Modal,
			Markdown,
		},

		data() {
			return {
				noteService: new NoteService(),
				notes: [],
				newNote: '',
				newNoteKey: 0,
				showNewNote: false,
				deleteNoteDialog: false,
				deleteNoteId: null,
				truncate: true,
				fading: true,
				maxNotes: 5,
				maxNotesDefault: null,
			};
		},

		mounted() {
			this.$smoothReflow([
				{
					el: '.notes, .new-note',
					property: ['height'],
					transition: 'height .25s ease-in',
				},
			]);
			this.getNotes();
			this.maxNotesDefault = this.maxNotes;
		},

		methods: {
			setTruncate(truncate) {
				this.truncate = truncate;
				if (truncate) {
					this.fading = true;
					// let el = document.getElementById('card-anchor-2');
					// let viewportOffset = el.getBoundingClientRect(); // Get position of card.
					// if (viewportOffset.top > 65) return; // Autoscroll into position if it's off the screen at top
					// this.$scrollTo('#card-anchor-2', '200ms', { offset: -65 });
				}
			},

			handleDeleteNoteClick(id) {
				if (id == '0') {
					return this.handleHideNewNote();
				}

				this.deleteNoteId = id;
				this.deleteNoteDialog = true;
			},

			closeDeleteDialog() {
				this.deleteNoteId = null;
				this.deleteNoteDialog = false;
			},

			initNewNote() {
				return {
					clientId: this.clientId,
					entry: '',
					id: '0',
					projectId: this.projectId,
				};
			},

			handleShowNewNote() {
				this.maxNotes++;
				this.notes.unshift(this.initNewNote());
				this.showNewNote = true;
				this.$emit('set-card-empty', false); // Let parent know if we're not empty!
			},

			handleHideNewNote() {
				this.maxNotes--;
				this.notes.shift();
				this.showNewNote = false;
				this.$emit('set-card-empty', this.notes.length !== 0); // Let parent know if we're empty or not!
			},

			getNotes() {
				this.noteService
					.getNoteList(this.clientId, this.projectId)
					.then((res) => {
						this.notes = res.data;
						if (!this.notes.length) {
							this.$emit('set-card-empty', true); // Let parent know we're empty!
						}
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			addNote(event) {
				if (!event.trim()) {
					return;
				}

				const payload = { entry: event.trim(), projectId: this.projectId };

				console.log('addNote', payload);
				this.noteService
					.postNote(this.clientId, payload)
					.then((res) => {
						console.log('addNote res', res);
						//this.notes.shift();
						this.notes.unshift(res.data); // Replace with returned object, mainly to update note.id.
						this.setTruncate(true);
						//this.maxNotes = this.maxNotesDefault;
						this.newNote = '';
						this.newNoteKey++;
					})
					.catch((err) => {
						console.log('error', err);
						this.alert('error', err.response.data.message);
					});
			},

			updateNote(entry, id) {
				entry = entry.trim();

				if (id === '0') {
					return this.addNote(entry);
				}

				const i = this.notes
					.map(function(e) {
						return e.id;
					})
					.indexOf(id);

				if (entry == this.notes[i].entry) {
					return;
				}

				const data = { entry: entry, projectId: this.projectId };
				this.noteService
					.putNote(this.clientId, id, data)
					.then((res) => {
						this.notes[i] = res.data;
						this.$nextTick().then(() => {
							this.$forceUpdate();
						});
					})
					.catch((err) => {
						this.alert('error', err.response.data.message);
					});
			},

			deleteNote() {
				this.noteService
					.deleteNote(this.clientId, this.deleteNoteId)
					.then(() => {
						const i = this.notes
							.map(function(e) {
								return e.id;
							})
							.indexOf(this.deleteNoteId);
						this.notes.splice(i, 1);
						this.closeDeleteDialog();
					})
					.catch((err) => {
						this.alert('error', err.response.data.message);
					});
			},

			alert(type, message) {
				this.$emit('alert', { type: type, message: message });
			},
		},

		computed: {
			notesFiltered() {
				if (this.truncate && this.notes.length > this.maxNotes) return this.notes.slice(0, this.maxNotes);
				return this.notes;
			},

			notesCssClass() {
				if (this.truncate && this.notes.length > this.maxNotes) return 'notes-truncated';
				return 'notes-show-all';
			},

			cardIsEmpty() {
				return this.notes.length === 0 && this.showNewNote === false ? true : false;
			},
		},
	};
</script>

<style lang="scss" scoped>
	div.notes {
		.note {
			.icons {
				visibility: hidden;
			}
			&:hover {
				background-color: var(--v-gray_10-base);
				.icons {
					visibility: visible;
					.edit:hover {
						color: var(--v-primary-base);
					}
					.delete:hover {
						color: var(--v-alert-base);
					}
				}
			}
		}
		.note:nth-child(2) {
			position: relative;
			z-index: auto;
			.fading {
				z-index: 6;
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 80px;
				background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
				background-image: -moz-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
				background-image: -o-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
				background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
				background-image: -ms-linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
			}
			&:hover .fading {
				display: none;
			}
			&:active .fading {
				display: none;
			}
		}
	}
	div.chevron-div {
		text-align: center;
	}
</style>
