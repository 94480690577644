import HttpClient from '../../services/HttpClient';
import store from "@/store";

export default class ProposalService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	initializeProposal(clientId) {
		return this.httpClient
			.post(`/clients/${clientId}/proposals`)
			.then((res) => {
				store.state.eventBus.$emit('created','proposal')
				return Promise.resolve(res)
			})
			.catch((err) => Promise.reject(err));
	}

	getProposalListByAccount() {
		return this.httpClient
			.get(`/proposals`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProposalList(clientId) {
		return this.httpClient
			.get(`/clients/${clientId}/proposals`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProposalCount() {
		return this.httpClient
			.get('/proposals/count')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getProposal(clientId, proposalId) {
		return this.httpClient
			.get(`/clients/${clientId}/proposals/${proposalId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	cloneProposal(clientId, proposalId, newClientId) {
		return this.httpClient
			.post(`/clients/${clientId}/proposals/${proposalId}/clone?newClientId=${newClientId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateProposal(clientId, proposalId, proposal) {
		return this.httpClient
			.put(`/clients/${clientId}/proposals/${proposalId}`, proposal)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendComment(clientId, proposalId, comment) {
		return this.httpClient
			.post(`/clients/${clientId}/proposals/${proposalId}/comment`, comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateComment(clientId, proposalId, comment) {
		return this.httpClient
			.put(`/clients/${clientId}/proposals/${proposalId}/comment`, comment)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteComment(clientId, proposalId, commentId) {
		return this.httpClient
			.delete(`/clients/${clientId}/proposals/${proposalId}/comment?commentId=${commentId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteProposal(clientId, proposalId) {
		return this.httpClient
			.delete(`/clients/${clientId}/proposals/${proposalId}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getShareableLink(clientId, proposalId) {
		return this.httpClient
			.get(`/clients/${clientId}/proposals/${proposalId}/link`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	sendEmail(clientId, proposalId, email) {
		return this.httpClient
			.post(`/clients/${clientId}/proposals/${proposalId}/sendEmail`, email)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	activateProposal(clientId, proposalId) {
		return this.httpClient
			.post(`/clients/${clientId}/proposals/${proposalId}/activate`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	voidProposal(clientId, proposalId) {
		return this.httpClient
			.post(`/clients/${clientId}/proposals/${proposalId}/void`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	draftProposal(clientId, proposalId) {
		return this.httpClient
			.post(`/clients/${clientId}/proposals/${proposalId}/draft`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	setLastViewed(clientId, proposalId) {
		return this.httpClient
			.post(`/clients/${clientId}/proposals/${proposalId}/viewed`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createTerms(clientId, proposalId, terms) {
		return this.httpClient
			.post(`/clients/${clientId}/proposals/${proposalId}/createTerms`, terms)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteFileAttachment(clientId, proposalId, fileName) {
		return this.httpClient
			.delete(`/clients/${clientId}/proposals/${proposalId}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getSignedUrl(clientId, proposalId, fileName) {
		return this.httpClient
			.get(`/clients/${clientId}/proposals/${proposalId}/files?fileName=${encodeURIComponent(fileName)}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	searchStockPhotos(query, page = 1, perPage = 18) {
		return this.httpClient
			.get(`/photos/search?query=${encodeURIComponent(query)}&page=${page}&perPage=${perPage}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	downloadStockPhoto(photoUrl) {
		return this.httpClient
			.get('/photos/download', {
				photoUrl: photoUrl,
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getAccountInfo() {
		return this.httpClient
			.get('')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
