<template>
	<div class="pointer" @click="downloadFile()">
		<img v-if="isImageType && signedUrl" :src="signedUrl" style="max-width: 200px">
		<v-chip v-if="!isImageType" color="blue_10" class="pointer">
			<div class="row-format align-center font-12">
				<div>Attachment: {{s3File.fileName}}</div>
				<v-icon small class="ml-2">$download</v-icon>
			</div>
		</v-chip>
	</div>
</template>

<script>
import CommunicatorMessageService from "@/modules/communicator/CommunicatorMessageService";
import axios from "axios";

export default {
	name: "S3FileHandler",

	props: ['s3File'],

	components: {
	},

	data: function () {
		return {
			communicatorMessageService: new CommunicatorMessageService(),
			imageTypes: ['GIF','JPG','PNG','SVG'],
			signedUrl: null,
		}
	},

	mounted() {
		if(this.isImageType){
			this.getSignedUrl()
		}
	},

	beforeDestroy() {
	},

	methods: {
		getSignedUrl: function(){
			this.communicatorMessageService.getMediaUrl(this.s3File).then((res) => this.signedUrl = res.data);
		},

		downloadFile: function() {
			this.communicatorMessageService.getMediaUrl(this.s3File).then((res) => {
				axios({
					url: res.data, //your url
					method: 'GET',
					responseType: 'blob', // important
				}).then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', this.s3File.fileName); //or any other extension
					document.body.appendChild(link);
					link.click();
				});
			})
		}
	},

	computed: {
		isImageType: function(){
			if(this.imageTypes.includes(this.s3File.fileType)){
				return true;
			}else{
				return false;
			}
		}
	},

}
</script>

<style scoped lang="scss">

</style>