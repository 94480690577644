<template>
	<div style="width:500px">
		<div class="modal-padding">
			<div class="row-format align-center my-5">
				<div class="mr-1" style="width:400px">
					<input style="width:100%" type="text" :value="link" class="link-box" id="linkBox" readonly />
				</div>
				<div class="ml-1">
					<v-btn class="super-action" @click="copyLink()">{{ $t('proposal.share.copy-link') }}</v-btn>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="primary-action" style="width: 140px" @click="cancel()">
				{{ $t('global.done') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: "LinkShare",

	props: ['title','link'],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		copyLink: function() {
			navigator.clipboard.writeText(this.link);
			this.cancel();
		},
		cancel: function() {
			this.$emit('result');
		},
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>