<template>
	<primary-layout>
		<v-container fluid>
			<v-row>
				<v-col>
					<div v-if="this.user != null">Hello {{ this.user.firstName }} {{ this.user.lastName }}</div>
					<div>Your email is: {{ this.user.email }}</div>
					<div>Your user ID is: {{ this.user.userId }}</div>
					<div>Your log in method is: {{ this.user.oidcProvider }}</div>
					<!--div><v-textarea rows="20" label="Authorization: Bearer" :value="this.$store.state.accessToken" /></div-->
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div class="mt-6">Having a problem with still seeing sample data?</div>
					<v-btn outlined color="error" @click="clearSampleData">Clear Sample Data</v-btn>
				</v-col>
			</v-row>
		</v-container>


	</primary-layout>
</template>

<script>
	import PrimaryLayout from '../../components/PrimaryLayout';
	import AccountService from "@/modules/account/AccountService";

	export default {
		name: 'Profile',
		components: { PrimaryLayout },
		props: {},

		data() {
			return {
				AccountService: new AccountService()
			};
		},

		methods: {
			clearSampleData: function(){
				this.$store.commit('startLoading');
				this.AccountService.deleteSampleData().then(() => this.$store.commit('success','Sample data cleared')).finally(() => this.$store.commit('stopLoading'));
			}
		},

		computed: {
			user: {
				get: function() {
					return this.$store.state.loggedInUser;
				},
				set: function() {
					//no-op
				},
			},
		},

		mounted() {
			console.log(this.$store.state.loggedInUser);
			this.$store.commit('setBreadcrumbs', [{ to: this.$route.path, text: 'Profile' }]);
		},
	};
</script>
