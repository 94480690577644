import MeetingDetail from "@/modules/meetings/MeetingDetail";

export default {
	methods: {

		scheduledMeetingEventClicked(scheduledMeeting,events) {
			let binding = {
				id: scheduledMeeting.id,
			};
			this.$store.state.globalModalController.openModal(MeetingDetail, binding, true, false).then((res) => {
				if(!res) return;

				let ix = events.findIndex(e => e.eventSource === 'SCHEDULED_MEETING' && e.id === scheduledMeeting.id);

				if (res.action === 'DELETED') {
					events.splice(ix,1);
				} else {
					events.splice(ix,1,this.convertScheduledMeeting(res));
				}
			});
		},

		convertScheduledMeeting: function (scheduledMeeting) {
			let userId = [];

			if(scheduledMeeting.ownerUserId){
				userId.push(scheduledMeeting.ownerUserId);
			}

			let converted = {
				id: scheduledMeeting.id,
				eventSource: 'SCHEDULED_MEETING',
				summary: scheduledMeeting.meetingName + " with " + scheduledMeeting.formData.firstName + ' ' + scheduledMeeting.formData.lastName,
				description: null,
				dateOnly: false,
				start: scheduledMeeting.confirmedTime.start,
				end: scheduledMeeting.confirmedTime.end,
				userId: userId,
				meta: {
					clientId: scheduledMeeting.clientId,
					scheduledMeeting: scheduledMeeting,
				},
			};
			return converted;
		},
	},
};
