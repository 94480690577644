var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode === "standard" || _vm.mode === "dense"
    ? _c(
        "div",
        { staticStyle: { width: "100%" } },
        [
          _c("v-textarea", {
            attrs: {
              rows: "1",
              "auto-grow": "",
              "hide-details": "",
              "persistent-placeholder": "",
              dense: _vm.mode === "dense",
              outlined: "",
              label: _vm.field.name
            },
            on: { input: _vm.handleChange },
            model: {
              value: _vm.ourValue,
              callback: function($$v) {
                _vm.ourValue = $$v
              },
              expression: "ourValue"
            }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticStyle: { width: "100%" } },
        [
          _c("resizeable-text-area", [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.ourValue,
                  expression: "ourValue"
                }
              ],
              attrs: { rows: "1", placeholder: "--" },
              domProps: { value: _vm.ourValue },
              on: {
                change: function($event) {
                  return _vm.$emit("change", _vm.ourValue)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.ourValue = $event.target.value
                }
              }
            })
          ])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }