var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-2" },
    [
      _c(
        "div",
        [
          _c("div", {}, [_vm._v("Width (" + _vm._s(_vm.element.width) + "%)")]),
          _c("v-slider", {
            attrs: {
              min: 10,
              max: 100,
              step: 1,
              "hide-details": "",
              dense: "",
              "thumb-label": ""
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.scale,
              callback: function($$v) {
                _vm.$set(_vm.element, "scale", $$v)
              },
              expression: "element.scale"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Min width (px)"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.minWidth,
              callback: function($$v) {
                _vm.$set(_vm.element, "minWidth", $$v)
              },
              expression: "element.minWidth"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Max width (px)"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.maxWidth,
              callback: function($$v) {
                _vm.$set(_vm.element, "maxWidth", $$v)
              },
              expression: "element.maxWidth"
            }
          })
        ],
        1
      ),
      _c("v-select", {
        attrs: {
          items: _vm.alignment,
          "item-text": "label",
          "item-value": "value",
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Alignment"
        },
        on: { change: _vm.updated },
        model: {
          value: _vm.element.alignment,
          callback: function($$v) {
            _vm.$set(_vm.element, "alignment", $$v)
          },
          expression: "element.alignment"
        }
      }),
      _c("v-text-field", {
        attrs: {
          "hide-details": "",
          dense: "",
          outlined: "",
          type: "number",
          label: "Padding"
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.padding,
          callback: function($$v) {
            _vm.$set(_vm.element, "padding", $$v)
          },
          expression: "element.padding"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Margin top"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pt,
              callback: function($$v) {
                _vm.$set(_vm.element, "pt", $$v)
              },
              expression: "element.pt"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Margin bottom"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pb,
              callback: function($$v) {
                _vm.$set(_vm.element, "pb", $$v)
              },
              expression: "element.pb"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Margin left"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pl,
              callback: function($$v) {
                _vm.$set(_vm.element, "pl", $$v)
              },
              expression: "element.pl"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Margin right"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pr,
              callback: function($$v) {
                _vm.$set(_vm.element, "pr", $$v)
              },
              expression: "element.pr"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Border width"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.borderWidth,
              callback: function($$v) {
                _vm.$set(_vm.element, "borderWidth", $$v)
              },
              expression: "element.borderWidth"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              label: "Border radius"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.borderRadius,
              callback: function($$v) {
                _vm.$set(_vm.element, "borderRadius", $$v)
              },
              expression: "element.borderRadius"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "row-format text-left gap-1" }, [
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", [_vm._v("Background color")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.element.backgroundColor,
                callback: function($$v) {
                  _vm.$set(_vm.element, "backgroundColor", $$v)
                },
                expression: "element.backgroundColor"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { flex: "1 1 0", width: "0" } },
          [
            _c("div", [_vm._v("Border color")]),
            _c("color-selector", {
              on: { input: _vm.updated },
              model: {
                value: _vm.element.borderColor,
                callback: function($$v) {
                  _vm.$set(_vm.element, "borderColor", $$v)
                },
                expression: "element.borderColor"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row-format" }, [
        _c("div", [_vm._v("Image library")]),
        _c(
          "div",
          {
            staticClass: "ml-auto pointer font-primary row-format align-center",
            on: {
              click: function($event) {
                _vm.imageSearch = true
              }
            }
          },
          [
            _c("v-icon", { attrs: { small: "", color: "primary" } }, [
              _vm._v("search")
            ]),
            _c("div", [_vm._v("Search")])
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "image-grid" },
        [
          _vm._l(_vm.imageAssets, function(result) {
            return _c(
              "div",
              {
                key: result.id,
                staticClass: "pointer image-container custom-image-container",
                style: "background-image: url(" + result.thumbnail + ");",
                on: {
                  click: function($event) {
                    return _vm.setCustomImage(result)
                  }
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action",
                    attrs: { icon: "", "x-small": "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.confirmDelete(result)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { size: "16" } }, [_vm._v("$delete")])
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "vue-dropzone",
            {
              ref: "fileDropzone",
              staticClass: "image-container no-image-container",
              attrs: {
                id: "dropzone",
                "use-custom-slot": true,
                options: _vm.dropzoneOptions
              },
              on: {
                "vdropzone-success": _vm.uploadSuccess,
                "vdropzone-error": _vm.uploadFailure,
                "vdropzone-sending": _vm.addFileUploadAuthHeader
              }
            },
            [
              _c(
                "div",
                [
                  _c("v-icon", [_vm._v("$vuetify.icons.upload")]),
                  _c("div", { staticClass: "font-12" }, [
                    _vm._v(_vm._s(_vm.$t("proposal.cover.upload")))
                  ])
                ],
                1
              )
            ]
          )
        ],
        2
      ),
      _c(
        "basic-modal",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.imageSearch,
              expression: "imageSearch"
            }
          ],
          staticStyle: {
            "background-color": "var(--v-white-base)",
            "min-width": "300px"
          },
          attrs: { persistent: false, dialog: _vm.imageSearch },
          on: {
            close: function($event) {
              _vm.imageSearch = false
            }
          }
        },
        [
          _c("image-search", {
            on: {
              close: function($event) {
                _vm.imageSearch = false
              },
              result: function($event) {
                return _vm.setUnsplashImage($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }