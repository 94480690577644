<template>
	<div class="pb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }]">
			My business
		</page-header>

		<v-container v-if="isAccountOwner && account" style="max-width: 800px">
			<v-row>
				<v-col cols="12">
					<div class="row-format mb-4">
						<v-btn class="ml-auto super-action" width="120" :disabled="!acctUpdate" @click="saveAccount">
							{{ $t('global.save') }}
						</v-btn>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="row-format gap-3">
						<div id="account-logo">
							<vue-dropzone
								v-if="!account.accountLogo && !acctUpdate"
								ref="fileDropzone"
								id="dropzone"
								v-on:vdropzone-success="uploadSuccess"
								v-on:vdropzone-error="uploadFailure"
								v-on:vdropzone-sending="addFileUploadAuthHeader"
								:use-custom-slot="true"
								:options="dropzoneOptions"
							>
								<div>
									<v-icon>$vuetify.icons.upload</v-icon><br />
									<span class="ml-2" style="font-size: 0.8em">{{ $t('account.logo-upload') }}</span>
								</div>
							</vue-dropzone>

							<div v-if="!account.accountLogo && acctUpdate">
								<v-icon color="grey">$warning</v-icon><br />
								<span class="ml-2" style="font-size: 0.8em; color: var(--v-gray_70-base)">
									Please save changes to upload your logo
								</span>
							</div>

							<div class="accountLogo" v-if="account.accountLogo" style="position: relative">
								<v-img :src="account.accountLogo" style="max-width: 250px"></v-img>

								<v-btn
									icon
									class="delete logoDelete"
									style="position: absolute; top: 35%; left: 35%"
									@click="deleteAccountLogo"
								>
									<v-icon>$delete</v-icon>
								</v-btn>
							</div>
						</div>
						<div class="flex-grow-1">
							<div class="column-format gap-3">
								<v-text-field
									hide-details
									persistent-placeholder
									dense
									outlined
									class="background"
									:label="$t('proposal.terms.legal-name')"
									v-model="account.accountName"
									@input="acctChange"
								>
								</v-text-field>
								<v-select
									v-if="entityTypes.length"
									hide-details
									persistent-placeholder
									dense
									outlined
									class="background"
									:label="$t('proposal.terms.entity-type')"
									:items="entityTypes"
									v-model="account.entityType"
									@input="acctChange"
								></v-select>
								<v-text-field
									style="min-width: 300px"
									v-else
									hide-details
									persistent-placeholder
									dense
									outlined
									class="background"
									placeholder="Corporation, Proprietership, GmbH, etc "
									:label="$t('proposal.terms.entity-type')"
									v-model="account.entityType"
									@input="acctChange"
								>
								</v-text-field>
							</div>
						</div>
					</div>

					<div class="column-format gap-3 mt-8">
						<v-autocomplete
							hide-details
							persistent-placeholder
							dense
							outlined
							class="background"
							:label="$t('proposal.terms.entity-country')"
							:items="countriesSimple"
							item-text="name"
							item-value="code"
							v-model="account.country"
							@input="acctChange"
						>
						</v-autocomplete>

						<v-autocomplete
							v-if="states.length"
							hide-details
							persistent-placeholder
							dense
							outlined
							class="background"
							:label="$t('proposal.terms.entity-state')"
							item-value="name"
							item-text="name"
							:items="states"
							v-model="account.entityState"
							@change="acctChange"
						>
						</v-autocomplete>

						<v-text-field
							v-else
							hide-details
							persistent-placeholder
							dense
							outlined
							class="background"
							:label="$t('proposal.terms.entity-state')"
							v-model="account.entityState"
							@input="acctChange"
						>
						</v-text-field>

						<v-autocomplete
							hide-details
							persistent-placeholder
							dense
							outlined
							class="background"
							:label="$t('account.currency')"
							:items="currencies"
							item-text="label"
							item-value="value"
							v-model="account.currency"
							@input="acctChange"
						>
						</v-autocomplete>

						<v-autocomplete
							persistent-placeholder
							hide-details
							dense
							outlined
							class="background"
							label="Account timezone"
							v-model="account.timeZone"
							:items="timezones"
							@input="acctChange"
						>
						</v-autocomplete>
					</div>

					<div class="font-14 text-left font-gray_80 mt-8 ml-1">Mailing Address</div>

					<div class="column-format gap-3 mt-3">
						<v-text-field
							hide-details
							persistent-placeholder
							dense
							outlined
							class="background"
							v-model="account.address1"
							:label="$t('contact.mailing-address')"
							@input="acctChange"
						></v-text-field>

						<v-text-field
							hide-details
							persistent-placeholder
							dense
							outlined
							class="background"
							v-model="account.address2"
							:label="$t('contact.address-contd')"
							@input="acctChange"
						></v-text-field>

						<v-text-field
							hide-details
							persistent-placeholder
							dense
							outlined
							class="background"
							v-model="account.city"
							:label="$t('contact.city')"
							@input="acctChange"
						></v-text-field>

						<div class="row-format gap-2">
							<v-text-field
								hide-details
								persistent-placeholder
								dense
								outlined
								class="background"
								v-model="account.locality"
								:label="$t('contact.locality')"
								@input="acctChange"
							></v-text-field>

							<v-text-field
								hide-details
								persistent-placeholder
								dense
								outlined
								class="background"
								v-model="account.postal"
								:label="$t('contact.postal')"
								@input="acctChange"
							></v-text-field>
						</div>
						<div class="row-format gap-2">
							<v-text-field
								hide-details
								persistent-placeholder
								dense
								outlined
								class="background"
								v-model="account.phone"
								:label="$t('account.phone')"
								@input="acctChange"
							></v-text-field>
							<v-text-field
								hide-details
								persistent-placeholder
								dense
								outlined
								class="background"
								v-model="account.taxId"
								:label="$t('account.tax-id')"
								@input="acctChange"
							></v-text-field>
						</div>
						<div class="row-format">
							<v-text-field
								hide-details
								persistent-placeholder
								dense
								outlined
								class="background"
								v-model="account.website"
								label="Website"
								@input="acctChange"
							></v-text-field>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import countries from '@/assets/data/countries.json';
	import countriesSimple from '@/assets/data/countries-simple.json';
	import currencies from '@/assets/data/currencies.json';
	import AccountService from '@/modules/account/AccountService';
	import AuthService from '@/modules/auth/AuthService';
	import vueDropzone from 'vue2-dropzone';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import 'cropperjs/dist/cropper.min.css';
	import timezones from '@/assets/data/timezones.json';
	import PageHeader from '@/components/PageHeader';

	export default {
		name: 'Account',

		props: [],

		components: {
			vueDropzone,
			PageHeader,
		},

		data: function() {
			return {
				timezones: timezones,
				isReady: true,

				account: null,
				countries: countries,
				countriesSimple: countriesSimple,
				currencies: currencies,
				accountService: new AccountService(),
				acctUpdate: false,

				mceConfigText: {
					menubar: false,
					inline: true,
					browser_spellcheck: true,
					forced_root_block: '',
					plugins: [],
					paste_as_text: true,
					toolbar: [],
				},
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'account-settings' });
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.$store.state.eventBus.$emit('created', 'account');
			this.handleAccountChange();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			setView: function(view) {
				this.currentView = view;
			},

			handlePayInstructionChange() {
				this.account.payInstructions = this.$refs.editor.editor.getContent();
				this.acctChange();
			},

			saveAccount: function() {
				this.$store.commit('startLoading');
				return this.accountService
					.updateAccount(this.account)
					.then((res) => {
						this.refreshUserAndAccountTokens();
						this.acctUpdate = false;
						this.$onBoarding.track('confirm_business');
						Promise.resolve(res);
					})
					.catch((err) => Promise.reject(err))
					.finally(() => this.$store.commit('stopLoading'));
			},

			handleAccountChange: function() {
				if (this.isAccountOwner) {
					this.$store.commit('startLoading');
					this.accountService
						.getAccount()
						.then((res) => {
							this.account = res.data;
							this.$store.state.defaultCurrency = this.account.currency;
						})
						.catch((err) => this.$store.commit('error', err.response.data.message))
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			refreshUserAndAccountTokens: function() {
				AuthService.refreshToken(true).then((res) => {
					this.$store.commit('logIn', res);

					AuthService.getProfile().then((res) => {
						this.$store.dispatch('setLoggedInUser', res);
						this.user = JSON.parse(JSON.stringify(this.$store.state.loggedInUser));
					});
				});
			},

			acctChange: function() {
				this.acctUpdate = true;
			},

			requestDeleteAccountLogo: function() {
				if (this.acctUpdate) {
					this.saveAccount().then(() => {
						this.deleteAccountLogo();
					});
				} else {
					this.deleteAccountLogo();
				}
			},

			deleteAccountLogo: function() {
				this.accountService
					.deleteLogo()
					.then(() => {
						this.account.accountLogo = null;
						this.refreshUserAndAccountTokens();
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			navClick: function(index) {
				this.selectedCard = index;
				let ourDiv = document.getElementById(`card-wrapper-${index}`);
				ourDiv.scrollIntoView({ behavior: 'smooth' });
			},

			yesNoFromBoolean: function(val) {
				return val ? 'Yes' : 'No';
			},

			booleanFromYesNo: function(val) {
				return val === 'Yes' ? true : false;
			},

			addFileUploadAuthHeader: function(file, xhr) {
				xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.accessToken}`);
			},

			uploadSuccess: function(file, response) {
				this.$refs.fileDropzone.removeFile(file);
				this.account = response;
				this.$onBoarding.track('confirm_business');
				this.refreshUserAndAccountTokens();
			},

			uploadFailure: function(file, message) {
				this.$store.commit('error', 'Error uploading file: ' + message);
				this.$refs.fileDropzone.removeFile(file);
			},

			handleIntersect([entry]) {
				if (entry && entry.isIntersecting && entry.intersectionRatio >= 1) {
					let id = entry.target.id;
					id = id.replace(/^card-wrapper-/g, '');
					this.selectedCard = parseInt(id, 10);
				}
			},
		},

		computed: {
			isPaidAccount: function() {
				return this.$store.getters.isPaidAccount;
			},

			entityTypes: function() {
				if (this.account.country === 'US' || this.account.country === 'CA') {
					let country = this.countries.find((c) => c.code === this.account.country);
					if (country) {
						return country.entityTypes;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},

			states: function() {
				if (this.account.country === 'US' || this.account.country === 'CA') {
					let country = this.countries.find((c) => c.code === this.account.country);
					if (country) {
						return country.states;
					} else {
						return [];
					}
				} else {
					return [];
				}
			},

			isAccountOwner: function() {
				return this.$store.getters.isAccountOwner;
			},

			isAccountFullUser: function() {
				return this.$store.getters.isAccountFullUser;
			},

			placeHolderHeight: function() {
				return this.$vuetify.breakpoint.height - 400;
			},

			editorHeight: function() {
				return this.$vuetify.breakpoint.height - 60;
			},

			positionClass() {
				if (this.$vuetify.breakpoint.mdAndUp) {
					return 'sticky-position';
				}
				return '';
			},

			dropzoneOptions: function() {
				return {
					url: `${this.$store.getters.getBaseUrl}/logo?lightMode=true`,
					thumbnailWidth: 50,
					maxFiles: 1,
					maxFilesize: 50,
					withCredentials: true,
					autoProcessQueue: true,
					duplicateCheck: true,
					createImageThumbnails: false,
					acceptedFiles: 'image/*',
					includeStyling: false,
				};
			},
		},
	};
</script>

<style lang="scss">
	.confirmButton {
		background-color: var(--v-primary-base);
		font-weight: 500;
		font-family: Inter;
		color: var(--v-white-base);
		top: 0px;
		left: 0px;
		width: 100px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px 4px 4px 4px;
		z-index: 9999;
		text-align: center;
		cursor: pointer;
	}

	.accountLogo {
		.logoDelete {
			visibility: hidden;
		}

		&:hover {
			.logoDelete {
				visibility: visible;
			}
		}
	}
</style>
