var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "row-format align-center scope-button " +
        (_vm.scope === _vm.activeScope || _vm.isActive ? "active" : ""),
      on: {
        click: function($event) {
          return _vm.$emit("scope-update", _vm.scope)
        }
      }
    },
    [_c("div", [_vm._v(_vm._s(_vm.label))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }