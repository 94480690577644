var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isConditionMet
    ? _c(
        "div",
        {
          staticClass: "text-left",
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-question" },
            [
              _vm._v(_vm._s(_vm.item.question) + " "),
              _vm.item.required
                ? _c("span", { staticClass: "font-red brand-medium" }, [
                    _vm._v("*")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showConditionalHelper
                ? _c("v-icon", { attrs: { small: "", color: "gray_50" } }, [
                    _vm._v("account_tree")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              },
              model: {
                value: _vm.isValid,
                callback: function($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c("v-textarea", {
                staticClass: "form-input",
                attrs: {
                  "hide-details": "",
                  dense: "",
                  placeholder: _vm.item.placeholder,
                  outlined: _vm.outlined,
                  solo: _vm.solo,
                  filled: _vm.filled,
                  disabled: _vm.disabled,
                  rows: _vm.item.rows,
                  rules: _vm.item.required ? _vm.inputRequired : []
                },
                on: {
                  input: function($event) {
                    return _vm.handleInput()
                  }
                },
                model: {
                  value: _vm.textInput,
                  callback: function($$v) {
                    _vm.textInput = $$v
                  },
                  expression: "textInput"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }