<template>
	<div>
		<div v-if="currentRecordingUrl" class="my-2">
			<audio controls style="width: 300px" :src="currentRecordingUrl"></audio>
		</div>
		<div>
			<div v-if="!audio" class="row-format">
				<v-btn @click="startRecording()" v-if="!recorder" class="primary-action">Record voicemail greeting</v-btn>
				<div v-if="recorder" class="row-format align-center">
					<v-btn class="mr-2 primary-action" @click="stopRecording()">Done</v-btn>
					<v-icon color="red">record_voice_over</v-icon>
					<div class="ml-1 font-red">Recording - {{timer}}s</div>
				</div>
			</div>
			<div class="row-format" v-if="audio">
				<v-btn @click="playRecording()" class="primary-action mr-2">Review Recording</v-btn>
				<v-btn @click="saveRecording()" class="secondary-action mr-2">Save Recording</v-btn>
				<v-btn @click="discardRecording()" class="secondary-action">Discard</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import CommunicatorService from "@/modules/communicator/CommunicatorService";

export default {
	name: "OutgoingGreeting",

	props: ['subscription'],

	components: {},

	data: function () {
		return {
			recorder: null,
			audio: null,
			timer: 0,
			timerId: null,
			currentRecordingUrl: null,
			communicatorService: new CommunicatorService()
		}
	},

	mounted() {
		console.log(this.subscription);
		if(this.subscription.preferences.outgoingAudio){
			this.getUrlForOutgoingGreeting();
		}
	},

	beforeDestroy() {
	},

	methods: {
		getUrlForOutgoingGreeting: function(){
			this.communicatorService.getOutgoingGreeting(this.subscription.id).then((res) => this.currentRecordingUrl = res.data);
		},

		async recordAudio() {
			// eslint-disable-next-line
			return new Promise(async (resolve) => {
				const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
				const mediaRecorder = new MediaRecorder(stream);
				const audioChunks = [];

				mediaRecorder.addEventListener('dataavailable', (event) => {
					audioChunks.push(event.data);
				});

				const start = () => mediaRecorder.start();

				const stop = () =>
						new Promise((resolve) => {
							mediaRecorder.addEventListener('stop', () => {
								const audioBlob = new Blob(audioChunks, { type: 'audio/mpeg' });
								const audioUrl = URL.createObjectURL(audioBlob);
								const audio = new Audio(audioUrl);

								stream.getAudioTracks().forEach(function (track) {
									track.stop();
								});

								resolve({ audioBlob, audioUrl, audio });
							});

							mediaRecorder.stop();
						});

				resolve({ start, stop });
			});
		},

		sleep: function (time) {
			return new Promise((resolve) => setTimeout(resolve, time));
		},

		async startRecording() {
			this.audio = null;
			this.recorder = await this.recordAudio();
			this.recorder.start();
			this.timerId = setInterval(() => this.timer++,1000);
		},

		async stopRecording() {
			const audio = await this.recorder.stop();
			this.audio = audio;
			this.recorder = null;
			clearInterval(this.timerId);
			this.timer = 0;
		},

		playRecording() {
			this.audio.audio.play();
		},

		saveRecording() {
			this.communicatorService
					.uploadOutgoingGreeting(this.subscription.id,this.audio.audioBlob)
					.then((res) => {
						this.audio = null
						this.recorder = null;
						this.$emit('outgoing-recording',res.data);
						this.getUrlForOutgoingGreeting();
					})
					.catch((err) => this.$store.commit('error',err.response.data.message));
		},

		deleteExistingRecording(){
			this.communicatorService
					.deleteOutgoingGreeting(this.subscription.id)
					.then(() => this.$emit('outgoing-recording',null))
					.catch((err) => this.$store.commit('error',err.response.data.message));
		},

		discardRecording() {
			this.audio = null;
		}
	},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>