var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "div",
        {
          key: _vm.refreshKey,
          staticClass: "pa-0 ma-0",
          staticStyle: {
            "max-height": "calc(var(--vh) - 450px)",
            "overflow-y": "auto"
          }
        },
        _vm._l(_vm.calls, function(call) {
          return _c("call-detail", {
            key: call.id,
            attrs: { call: call, "is-visible": _vm.isVisible },
            on: {
              "mark-read": function($event) {
                return _vm.markCallAsRead(call)
              },
              delete: function($event) {
                return _vm.deleteCall(call)
              }
            }
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }