var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.discovery_template_id,
            expression: "discovery_template_id"
          }
        ],
        ref: "discovery_template_id",
        attrs: { type: "hidden", "df-discovery_template_id": "" },
        domProps: { value: _vm.discovery_template_id },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.discovery_template_id = $event.target.value
          }
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.template_name,
            expression: "template_name"
          }
        ],
        ref: "template_name",
        attrs: { type: "hidden", "df-template_name": "" },
        domProps: { value: _vm.template_name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.template_name = $event.target.value
          }
        }
      }),
      _c("node-label", {
        on: {
          open: function($event) {
            return _vm.openModal()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-1",
                    attrs: { color: "black", size: "22" }
                  },
                  [_vm._v("checklist")]
                ),
                _vm.template_name
                  ? _c("div", [
                      _vm._v("Add " + _vm._s(_vm.template_name) + " to portal")
                    ])
                  : _c("div", [_vm._v("Add form to portal")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("node-modal", {
        ref: "nodeModal",
        attrs: { title: "Form to portal" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "column-format gap-2",
                    staticStyle: { "min-width": "550px" }
                  },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        "hide-details": "",
                        dense: "",
                        outlined: "",
                        clearable: "",
                        "persistent-placeholder": "",
                        label: "Form template",
                        items: _vm.discoveryTemplates,
                        "item-text": "name",
                        "item-value": "id"
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateSelectedTemplate()
                        }
                      },
                      model: {
                        value: _vm.discovery_template_id,
                        callback: function($$v) {
                          _vm.discovery_template_id = $$v
                        },
                        expression: "discovery_template_id"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }