<template>
	<div>
		Please wait while we redirect you to the Stripe On-Boarding platform
	</div>
</template>

<script>
	import StripeIntegrationService from '@/modules/integrations/stripe/StripeIntegrationService';

	export default {
		name: 'StripeRedirect',

		props: [],

		components: {
		},

		data: function () {
			return {
				stripeService: new StripeIntegrationService(),
			};
		},

		mounted() {
			this.stripeService.getConnectLink().then((res) => {
				window.location.href = res.data;
			})
		},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
