<template>
	<div class="column-format flex-grow-1">
		<v-container fluid class="ma-0 pa-0" v-if="notes.length" style="background-color: var(--v-white-base); border: 1px solid var(--v-gray_30-base); border-radius: 4px">
			<v-row dense>
				<v-col class="edit-note py-2" cols="12" v-for="(note,index) in filteredNotes" :key="note.id">
					<div class="row-format align-center gap-2 pointer px-3" @click="note.expanded = !note.expanded">
						<div class="font-12">{{ note.author }}</div>
						<v-icon size="12" color="gray_70">$clock</v-icon>
						<div class="font-gray_70 font-12">
							{{ note.timestampUpdated ? note.timestampUpdated : note.timestamp | formatForTimeAgo }}
						</div>
						<v-icon color="gray_70" class="material-symbols-rounded">{{
							note.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
						}}</v-icon>
						<div class="ml-auto pointer delete-button">
							<v-icon
								size="20"
								color="gray_90"
								@click.native="confirmDeleteNote(note)"
								class="material-symbols-rounded"
								>delete</v-icon
							>
						</div>
					</div>
					<div @click="toggleNoteExpansion(note)">
						<div
							v-if="note.id !== activeNote"
							class="note-wrapper pointer pa-3"
							@click="setActive(note)"
							:style="index < filteredNotes.length - 1 ? 'border-bottom: 1px solid var(--v-gray_30-base)' : ''"
						>
							<div
								:class="note.expanded ? '' : 'collapsed'"
								v-html="note.entry === '' ? 'Enter a note...' : note.entry"
							></div>
						</div>
						<div v-else autofocus class="text-left">
							<editor
								v-model="note.entry"
								:ref="'editor' + note.id"
								:api-key="$store.getters.getTinyMceKey"
								:inline="false"
								:init="mceConfig(note)"
								class="no-wrap-editor"
								style="width: 100%"
								@onblur="saveNote(note)"
							>
							</editor>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>

		<div class="flex-grow-1 column-format centered" v-if="notes.length === 0" style="min-height: calc(100vh - 300px)">
			<empty-view
					header="No notes yet"
					:body="
					`You haven’t added any notes for ${client.name} yet. Notes can help you keep track of important details and interactions.`
				"
					cta="Add a note"
					video-header=""
					video-body=""
					video-cta=""
					video-id=""
					@cta-clicked="addNewNote()"
			></empty-view>
		</div>

		<confirm-dialog
			v-if="confirmDeleteDialog"
			:dialog="confirmDeleteDialog"
			heading-text="Delete?"
			body-text="Are you sure you want to delete this note?"
			@close="cancelDeleteNote"
			@confirm="deleteNote"
		></confirm-dialog>
	</div>
</template>

<script>
	import NoteService from '@/services/NoteService';
	import marked from 'marked';
	import DateTime from '@/modules/utils/HDateTime';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import editor from '@tinymce/tinymce-vue';
	import EmptyView from "@/components/EmptyView";

	export default {
		mixins: [],

		name: 'Notes',

		props: ['client', 'filter', 'project'],

		components: { ConfirmDialog, editor, EmptyView },

		data() {
			return {
				DateTime: DateTime,
				noteService: new NoteService(),
				notes: [],
				activeNote: null,
				activeNewNote: false,
				editNoteId: null,
				deleteNoteId: null,
				confirmDeleteDialog: false,
				newNote: null,
			};
		},

		mounted() {
			this.getNotes();
		},

		methods: {
			mceConfig: function(note) {
				return {
					menubar: false,
					inline: false,
					paste_as_text: false,
					browser_spellcheck: true,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					placeholder: 'Add a note...',
					extended_valid_elements: 'iframe[src,id,style], style[media|type], link[rel,type,href]',
					forced_root_block: 'div',
					plugins: ['autolink', 'paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: false,
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					default_link_target: '_blank',
					toolbar: [
						'undo redo | fontsizeselect styleselect | ' +
							'bold italic underline backcolor forecolor | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist outdent indent | table link unlink media code | savebutton',
					],
					content_style:
						"@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); body { font-family: 'Inter', sans-serif; }",

					style_formats: [
						{ title: 'Paragraph', format: 'p' },
						{ title: 'Title', format: 'h1' },
						{ title: 'Heading', format: 'h2' },
						{ title: 'Subheading', format: 'h3' },
						{ title: 'Code', format: 'code' },
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.ui.registry.addButton('savebutton', {
							icon: 'new-save',
							onAction: () => {
								this.saveNote(note);
							},
						});
						editor.ui.registry.addIcon(
							'new-save',
							'<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z"/></svg>'
						);
					},
				};
			},

			getNotes: function() {
				this.noteService.getNoteList(this.client.id, this.projectId).then((res) => {
					this.notes = res.data.map((note) => ({
						...note,
						expanded: false,
					}));
					this.sortNotes();

					this.notes.forEach((n) => {
						if (n.format === 'Markdown') {
							n.entry = this.formatMarkdown(n.entry);
							n.format = 'HTML';
						}
					});

					if (this.notes.length > 0) {
						let firstNote = this.notes[0];
						firstNote.expanded = true;
						this.notes.splice(0, 1, firstNote);
					}
				});
			},

			toggleNoteExpansion(note) {
				note.expanded = !note.expanded;
			},

			addNewNote: function() {
				let note = {
					format: 'HTML',
					entry: '',
					projectId: this.projectId,
					expanded: true,
				};

				this.noteService.postNote(this.client.id, note).then((res) => {
					res.data.expanded = true;
					this.notes.push(res.data);
					this.setActive(res.data);
					this.sortNotes();
				});
			},

			sortNotes: function() {
				this.notes.sort((a, b) => {
					let atime = a.timestampUpdated ? a.timestampUpdated : a.timestamp;
					let btime = b.timestampUpdated ? b.timestampUpdated : b.timestamp;
					return atime > btime ? -1 : 1;
				});
			},

			setActive: function(note) {
				this.activeNote = note.id;
				note.expanded = true;
			},

			saveNote: function(note) {
				this.noteService.putNote(this.client.id, note.id, note).then((res) => {
					let ix = this.notes.findIndex((n) => n.id === note.id);
					if (ix > -1) {
						this.notes[ix].entry = res.data.entry;
						this.notes[ix].expanded = true;
						this.editNoteId = null;
						this.sortNotes();
					}
				});
				this.activeNote = null;
			},

			confirmDeleteNote(note) {
				this.confirmDeleteDialog = true;
				this.deleteNoteId = note.id;
			},

			cancelDeleteNote() {
				this.confirmDeleteDialog = false;
				this.deleteNoteId = null;
			},

			deleteNote: function() {
				this.noteService
					.deleteNote(this.client.id, this.deleteNoteId)
					.then(() => {
						let ix = this.notes.findIndex((n) => n.id === this.deleteNoteId);
						if (ix > -1) {
							this.notes.splice(ix, 1);
						}
					})
					.finally(() => {
						this.deleteNoteId = null;
						this.confirmDeleteDialog = false;
					});
			},

			formatMarkdown: function(comment) {
				let m = marked(comment, { breaks: true });
				m = m.replaceAll('<a href', '<a target="_blank" href');
				return m;
			},
		},

		computed: {
			projectId: function() {
				if (this.project) {
					return this.project.id;
				} else {
					return null;
				}
			},

			filteredNotes: function() {
				let notes = [...this.notes];
				if (this.filter.search) {
					let search = this.filter.search.toLowerCase();
					return notes.filter((n) => {
						if (n.entry && n.entry.toLowerCase().includes(search)) {
							return true;
						} else if (n.author && n.author.toLowerCase().includes(search)) {
							return true;
						} else {
							return false;
						}
					});
				} else {
					return notes;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.collapsed {
		width: 98%;
		max-width: 98%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-height: 22px;
	}
	.edit-note {
		.delete-button {
			visibility: hidden;
		}
		&:hover {
			.delete-button {
				visibility: visible;
			}
		}
	}

	.note-wrapper {
		box-sizing: border-box;
		text-align: left;
		padding: 12px 0;

		::v-deep p {
			margin: 0px;
		}
	}

	.editable-content {
		outline: none;
		cursor: text;
	}
</style>
