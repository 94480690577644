var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Client management" }
            ]
          }
        },
        [_vm._v(" Client list ")]
      ),
      _vm.isReady
        ? _c("div", { staticClass: "column-format fill-height" }, [
            _c("input", {
              attrs: { id: "copyToClip", name: "copyToClip", type: "hidden" },
              domProps: { value: _vm.copyToClip }
            }),
            _c(
              "div",
              { staticClass: "pb-4 row-format align-center flex-wrap gap-3" },
              [
                _c(
                  "div",
                  { staticClass: "row-format align-center gap-3" },
                  [
                    _c("v-text-field", {
                      staticStyle: {
                        "max-width": "200px",
                        "background-color": "var(--v-white-base)"
                      },
                      attrs: {
                        outlined: "",
                        "hide-details": "",
                        dense: "",
                        placeholder: "Search...",
                        color: "gray_30"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend-inner",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "material-symbols-rounded font-gray_50"
                                  },
                                  [_vm._v("search")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        95475665
                      ),
                      model: {
                        value: _vm.filter.search,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "search", $$v)
                        },
                        expression: "filter.search"
                      }
                    }),
                    _c(
                      "div",
                      [
                        _vm.filter
                          ? _c("client-list-filter", {
                              attrs: { filter: _vm.filter },
                              on: { updated: _vm.saveCurrentFilter }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm.filter
                          ? _c("client-list-sort", {
                              attrs: { filter: _vm.filter },
                              on: { updated: _vm.saveCurrentFilter }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ml-auto row-format align-center" },
                  [
                    _vm.totalPages > 0
                      ? _c("pagination", {
                          attrs: { "total-pages": _vm.totalPages },
                          model: {
                            value: _vm.currentPage,
                            callback: function($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage"
                          }
                        })
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "super-action",
                        on: {
                          click: function($event) {
                            return _vm.createNewClient()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("add")
                        ]),
                        _vm._v(" Add client")
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            !_vm.clientList.length
              ? _c(
                  "div",
                  {
                    staticClass: "row-format centered",
                    staticStyle: {
                      flex: "1",
                      "min-height": "calc(var(--vh) - 130px)"
                    }
                  },
                  [
                    _c("empty-view", {
                      attrs: {
                        header: "Everything starts with a client...",
                        body:
                          "Once you start adding your clients to Moxie, you can find and manage them all here. Ready to add your first one? Let’s do it! ",
                        cta: "Create your first client",
                        "video-header": "See how it works",
                        "video-body":
                          "See how to create clients and then view all the details for their hours, invoices, proposals, deposits, and much more.",
                        "video-cta": "Watch the tutorial",
                        "video-id": "ZQoOlfiMVAk"
                      },
                      on: {
                        "cta-clicked": function($event) {
                          return _vm.createNewClient()
                        }
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    key: _vm.refreshKey,
                    class: "pa-0",
                    staticStyle: {
                      "background-color": "var(--v-white-base)",
                      border: "1px solid var(--v-gray_30-base)",
                      "border-radius": "4px"
                    }
                  },
                  [
                    _c(
                      "v-container",
                      {
                        staticClass: "pa-0 ma-0",
                        staticStyle: {
                          "max-width": "100%",
                          overflow: "hidden"
                        },
                        attrs: { fluid: "" }
                      },
                      _vm._l(_vm.paginatedItems, function(client) {
                        return _c(
                          "v-row",
                          {
                            key: client.id,
                            staticClass: "text-left client-row",
                            style: client.archive
                              ? "color: var(--v-gray_50-base)!important"
                              : "",
                            attrs: { dense: "" },
                            on: {
                              click: function($event) {
                                return _vm.routeToClient(client, $event)
                              }
                            }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "row-format align-center",
                                attrs: { cols: "5" }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: client.favorite
                                        ? "accent"
                                        : "gray_50",
                                      size: "20"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleFavorite(client.id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        client.favorite
                                          ? "star"
                                          : "star_outline"
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    style: client.archive ? "opacity: 0.3" : ""
                                  },
                                  [
                                    _c("client-avatar", {
                                      staticClass: "ml-3 mr-2",
                                      attrs: { client: client }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-18 brand-medium row-format align-center"
                                    },
                                    [
                                      _c("div", [_vm._v(_vm._s(client.name))]),
                                      client.archive
                                        ? _c(
                                            "v-icon",
                                            {
                                              directives: [
                                                {
                                                  name: "tippy",
                                                  rawName: "v-tippy",
                                                  value: {
                                                    content: "Archived"
                                                  },
                                                  expression:
                                                    "{ content: 'Archived' }"
                                                }
                                              ],
                                              staticClass: "ml-3",
                                              attrs: { color: "gray_30" }
                                            },
                                            [_vm._v("archive")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      class:
                                        "font-14 " +
                                        (client.archive
                                          ? "font-gray_50"
                                          : "font-gray_70")
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            client.clientType === "Prospect"
                                              ? "Prospect | "
                                              : ""
                                          ) +
                                          _vm._s(client.projectCount) +
                                          " " +
                                          _vm._s(
                                            client.projectCount === 1
                                              ? "project"
                                              : "projects"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "row-format align-center",
                                attrs: { cols: "2" }
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        "font-14 " +
                                        (client.archive
                                          ? "font-gray_50"
                                          : "font-gray_70") +
                                        " ml-n1"
                                    },
                                    [_vm._v("Contacts")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "row-format" },
                                    [
                                      client.contacts.length
                                        ? _c("contacts", {
                                            attrs: { client: client }
                                          })
                                        : _vm._e(),
                                      !client.contacts.length
                                        ? _c("div", [_vm._v("-")])
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "row-format align-center",
                                attrs: { cols: "2" }
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      class:
                                        "font-14 " +
                                        (client.archive
                                          ? "font-gray_50"
                                          : "font-gray_70")
                                    },
                                    [_vm._v("Hours worked")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        parseFloat(
                                          client.timeWorked / 60 / 60
                                        ).toFixed(2)
                                      )
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "row-format align-center",
                                attrs: { cols: "2" }
                              },
                              [
                                _vm.$store.getters.hasInvoiceAccess
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            "font-14 " +
                                            (client.archive
                                              ? "font-gray_50"
                                              : "font-gray_70")
                                        },
                                        [_vm._v("Revenue to date")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$formatters.dollars(
                                              client.revenue
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "row-format align-center",
                                attrs: { cols: "1" }
                              },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      bottom: "",
                                      left: "",
                                      rounded: "",
                                      "offset-overflow": "",
                                      "offset-y": "",
                                      "close-on-content-click": true,
                                      "close-on-click": true
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "ml-auto" },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "menu-activator",
                                                        attrs: { icon: "" }
                                                      },
                                                      scope.on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.value
                                                              ? "$arrowUp"
                                                              : "$moreHorizontal"
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("div", { staticClass: "more-menu" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "more-menu-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.routeToClient(client)
                                            }
                                          }
                                        },
                                        [_vm._v("View details")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "more-menu-item",
                                          on: {
                                            click: function($event) {
                                              return _vm.editClient(client)
                                            }
                                          }
                                        },
                                        [_vm._v("Edit")]
                                      ),
                                      !client.archive
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "more-menu-item",
                                              on: {
                                                click: function($event) {
                                                  return _vm.archiveClient(
                                                    client.id,
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Archive ")]
                                          )
                                        : _vm._e(),
                                      client.archive
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "more-menu-item",
                                              on: {
                                                click: function($event) {
                                                  return _vm.archiveClient(
                                                    client.id,
                                                    false
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Un-archive ")]
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }