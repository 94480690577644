<template>
	<div>
		<input type="hidden" v-model="type" ref="type" df-type />
		<input type="hidden" v-model="due_in" ref="due_in" df-due_in />
		<input type="hidden" v-model="late_fee" ref="late_fee" df-late_fee />
		<input type="hidden" v-model="deposit_amount" ref="deposit_amount" df-deposit_amount />
		<input type="hidden" v-model="deposit_desc" ref="deposit_desc" df-deposit_desc />
		<input type="hidden" v-model="deposit_tax" ref="deposit_tax" df-deposit_tax />
		<input type="hidden" v-model="line_items" ref="line_items" df-line_items />
		<input type="hidden" v-model="template_id" ref="template_id" df-template_id />
		<input type="hidden" v-model="to_list" ref="to_list" df-to_list />
		<input type="hidden" v-model="cc_list" ref="cc_list" df-cc_list />

		<node-label @open="openModal()">
			<template v-slot:default>
				<v-icon class="material-symbols-rounded mr-1" color="black" size="20">request_quote</v-icon>
				<div>Send invoice</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" :title="`Send invoice`" @save="handleSave">
			<template v-slot:default>
				<div class="column-format gap-2 text-left" style="width: 550px" v-if="isOpen">
					<v-select
						hide-details
						dense
						outlined
						persistent-placeholder
						label="Type"
						:items="types"
						item-value="value"
						item-text="label"
						v-model="type"
					></v-select>
					<div class="row-format gap-2">
						<v-select
							style="width: 50%"
							hide-details
							dense
							outlined
							persistent-placeholder
							:label="$t('proposal.terms.payment-terms')"
							:items="dayOptions"
							item-value="value"
							item-text="label"
							v-model="due_in"
						>
						</v-select>
						<v-text-field
							style="width: 50%"
							v-model="late_fee"
							suffix="% / mo."
							hide-details
							persistent-placeholder
							dense
							outlined
							:label="$t('proposal.terms.late-payment')"
							type="number"
						></v-text-field>
					</div>
					<div class="column-format gap-2" v-if="type === 'FixedAmount'">
						<div
							v-for="item in lineItems"
							:key="item.id"
							style="border-bottom: 1px solid var(--v-gray_30-base)"
							class="py-2"
						>
							<per-item-pricing
								:hide-icon="false"
								:minimal="false"
								:product="item.product"
								:quantity="item.quantity"
								:immediate="item.immediate"
								:editable="true"
								@deleted="handleDeleted(item)"
								@product-selected="handleProductSelected(item, $event)"
								@quantity-updated="handleQuantityUpdated(item, $event)"
							>
							</per-item-pricing>
						</div>
						<v-btn text @click="addNewLineItem">+Add line item</v-btn>
					</div>
					<div v-else class="column-format gap-2 mb-4">
						<div class="row-format gap-2">
							<v-text-field
								style="width:50%"
								v-model="deposit_amount"
								hide-details
								persistent-placeholder
								dense
								outlined
								label="Deposit amount"
							></v-text-field>
							<v-text-field
								style="width: 50%"
								v-model="deposit_desc"
								hide-details
								persistent-placeholder
								dense
								outlined
								label="Deposit description"
							></v-text-field>
						</div>
						<v-select
							hide-details
							dense
							outlined
							persistent-placeholder
							label="Deposit taxable"
							:items="['Yes', 'No']"
							item-text="label"
							v-model="deposit_tax"
						></v-select>
					</div>
					<email-selector
						:to_list="toList"
						:cc_list="ccList"
						:template_id="template_id"
						@cc-list="updateCcList($event)"
						@to-list="updateToList($event)"
						@selected-template="updateSelectedTemplate($event)"
					></email-selector>
				</div>
			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeMixin from '@/modules/workflow/nodes/NodeMixin';
	import NodeModal from '@/modules/workflow/nodes/NodeModal';
	import NodeLabel from '@/modules/workflow/nodes/NodeLabel';
	import PerItemPricing from '@/modules/projects/deliverable/PerItemPricing';
	import { v4 as uuidv4 } from 'uuid';
	import EmailSelector from '@/modules/workflow/nodes/components/EmailSelector';

	export default {
		name: 'Invoice',
		inputs: 1,
		outputs: 1,

		props: [],
		mixins: [NodeMixin],

		components: { EmailSelector, NodeLabel, NodeModal, PerItemPricing },

		data: function() {
			return {
				//persisted values
				type: 'FixedAmount',
				line_items: [],
				due_in: 7,
				late_fee: 0,
				deposit_amount: 0,
				deposit_desc: 'Deposit',
				deposit_tax: 'Yes',
				to_list: [],
				cc_list: [],
				template_id: null,

				//internal values
				toList: [],
				ccList: [],
				lineItems: [],
				templates: [],
				isOpen: false,
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.type = this.$refs.type.value;
				this.due_in = this.$refs.due_in.value;
				this.late_fee = this.$refs.late_fee.value;
				this.template_id = this.$refs.template_id.value;
				this.deposit_amount = this.$refs.deposit_amount.value;
				this.deposit_desc = this.$refs.deposit_desc.value;
				this.deposit_tax = this.$refs.deposit_tax.value;

				if (this.$refs.to_list.value) {
					let to = JSON.parse(this.$refs.to_list.value);
					this.toList.push(...to);
				}

				if (this.$refs.cc_list.value) {
					let cc = JSON.parse(this.$refs.cc_list.value);
					this.ccList.push(...cc);
				}

				if (this.$refs.line_items.value) {
					let l = JSON.parse(this.$refs.line_items.value);
					this.lineItems.push(...l);
				}
			});
		},

		beforeDestroy() {},

		methods: {
			openModal: function() {
				this.isOpen = true;
				this.$refs.nodeModal.open();
			},

			updateToList: function(list) {
				this.toList.splice(0, this.toList.length);
				this.toList.push(...list);
			},

			updateCcList: function(list) {
				this.ccList.splice(0, this.ccList.length);
				this.ccList.push(...list);
			},

			updateSelectedTemplate: function(template) {
				if (template) {
					this.template_id = template.id;
				} else {
					this.template_id = null;
				}
			},

			handleSave: function() {
				if (this.type === 'FromProposal') {
					this.lineItems.splice(0, this.lineItems.length);
				}
			},

			addNewLineItem: function() {
				this.lineItems.push({
					id: uuidv4(),
					quantity: 0,
					product: null,
					immediate: true,
				});
			},

			handleProductSelected: function(lineItem, product) {
				delete lineItem.immediate;
				lineItem.product = product;
			},

			handleDeleted: function(lineItem) {
				let ix = this.lineItems.findIndex((l) => l.id === lineItem.id);
				this.lineItems.splice(ix, 1);
			},

			handleQuantityUpdated: function(lineItem, quantity) {
				lineItem.quantity = quantity;
			},
		},

		computed: {
			types: function() {
				return [
					{ label: 'Standard invoice', value: 'FixedAmount' },
					{ label: 'Deposit invoice', value: 'DepositAmount' },
				];
			},
			dayOptions: function() {
				return [
					{ label: 'On receipt', value: '0' },
					{ label: '7 days', value: '7' },
					{ label: '15 days', value: '15' },
					{ label: '30 days', value: '30' },
					{ label: '45 days', value: '45' },
					{ label: '60 days', value: '60' },
					{ label: '90 days', value: '90' },
				];
			},
		},

		watch: {
			type: {
				immediate: true,
				handler: function() {
					this.emitInput('type');
				},
			},

			due_in: function() {
				this.emitInput('due_in');
			},

			late_fee: function() {
				this.emitInput('late_fee');
			},

			template_id: function() {
				this.emitInput('template_id');
			},

			deposit_amount: function() {
				this.emitInput('deposit_amount');
			},

			deposit_desc: function() {
				this.emitInput('deposit_desc');
			},

			deposit_tax: function() {
				this.$nextTick(() => this.emitInput('deposit_tax'));
			},

			lineItems: {
				deep: true,
				handler: function() {
					this.line_items = JSON.stringify(this.lineItems);
					this.$nextTick(() => this.emitInput('line_items'));
				},
			},

			toList: function() {
				this.to_list = JSON.stringify(this.toList);
				this.$nextTick(() => this.emitInput('to_list'));
			},

			ccList: function() {
				this.cc_list = JSON.stringify(this.ccList);
				this.$nextTick(() => this.emitInput('cc_list'));
			},
		},
	};
</script>
