var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format centered", staticStyle: { height: "100%" } },
    [
      _c("div", { staticClass: "row-format", staticStyle: { width: "100%" } }, [
        _c(
          "div",
          { staticClass: "ml-auto", staticStyle: { width: "200px" } },
          [
            _c("v-select", {
              staticStyle: { "background-color": "var(--v-white-base)" },
              attrs: {
                items: _vm.items,
                dense: "",
                "hide-details": "",
                outlined: ""
              },
              model: {
                value: _vm.view,
                callback: function($$v) {
                  _vm.view = $$v
                },
                expression: "view"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        [
          _vm.view === "Trend"
            ? _c("bar", {
                key: _vm.barRefreshKey,
                attrs: {
                  "chart-id": _vm.barRefreshKey,
                  datasetIdKey: "insight-money-bar",
                  "chart-options": _vm.barChartOptions,
                  "chart-data": _vm.barChart,
                  width: 450,
                  height: 300
                }
              })
            : _vm.view === "By lead source"
            ? _c("pie", {
                key: _vm.pieRefreshKey,
                attrs: {
                  "chart-id": _vm.pieRefreshKey,
                  datasetIdKey: "insight-lead-pie",
                  "chart-options": _vm.pieChartOptions,
                  "chart-data": _vm.pieChartData,
                  width: 450,
                  height: 300
                }
              })
            : _c("pie", {
                key: _vm.pieRefreshKey,
                attrs: {
                  "chart-id": _vm.pieRefreshKey,
                  datasetIdKey: "insight-money-pie",
                  "chart-options": _vm.pieChartOptions,
                  "chart-data": _vm.pieChartData,
                  width: 450,
                  height: 300
                }
              })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }