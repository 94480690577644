var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "deliverable-package-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("result")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding column-format gap-3" },
        [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v("Import tasks from template")
          ]),
          !_vm.project
            ? _c("v-autocomplete", {
                attrs: {
                  items: _vm.projects,
                  "item-text": "name",
                  "item-value": "id",
                  "hide-details": "",
                  dense: "",
                  outlined: "",
                  "persistent-placeholder": "",
                  label: "Select project"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "row-format align-center gap-2" },
                            [
                              _c("client-avatar", {
                                attrs: { client: item.client, small: "" }
                              }),
                              _c("div", [_vm._v(_vm._s(item.name))])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "row-format align-center gap-2" },
                            [
                              _c("client-avatar", {
                                attrs: { client: item.client, small: "" }
                              }),
                              _c("div", [_vm._v(_vm._s(item.name))])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1656988060
                ),
                model: {
                  value: _vm.projectId,
                  callback: function($$v) {
                    _vm.projectId = $$v
                  },
                  expression: "projectId"
                }
              })
            : _vm._e(),
          _c("v-autocomplete", {
            attrs: {
              items: _vm.deliverablePackages,
              "item-text": "name",
              "return-object": "",
              "hide-details": "",
              dense: "",
              outlined: "",
              "persistent-placeholder": "",
              label: "Select template"
            },
            model: {
              value: _vm.deliverablePackage,
              callback: function($$v) {
                _vm.deliverablePackage = $$v
              },
              expression: "deliverablePackage"
            }
          }),
          _vm.deliverablePackage
            ? _c(
                "div",
                _vm._l(_vm.deliverablePackage.deliverables, function(
                  deliverable
                ) {
                  return _c(
                    "div",
                    { key: deliverable.id },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          label: deliverable.name,
                          value: deliverable.id
                        },
                        model: {
                          value: _vm.filter,
                          callback: function($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter"
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "super-action",
              attrs: { disabled: !_vm.buttonEnabled },
              on: { click: _vm.importIntoProject }
            },
            [_vm._v("Import selected tasks")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }