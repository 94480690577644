<template>
	<v-menu
		ref="menu"
		v-model="dateMenu"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		:disabled="disabled"
		min-width="290px"
	>
		<template v-slot:activator="{ on }">
			<div v-on="on" v-tippy :content="relativeTime">
				<div v-if="minimal" class="pointer">
					<div v-if="dateFormattedMinimal" :class="contentClass" style="white-space:nowrap">
						{{ dateFormattedMinimal }}
					</div>
					<v-icon v-else size="20" class="material-symbols-rounded">event</v-icon>
				</div>
				<div v-else-if="micro" :class="`pointer ${microFont ? microFont : 'font-12'}`">
					<div v-if="dateFormattedMinimal" :class="contentClass" style="white-space:nowrap">
						{{ dateFormattedMinimal }}
					</div>
					<div v-else v-html="label"></div>
				</div>
				<v-text-field
					v-else-if="standard"
					:value="dateFormatted"
					readonly
					:label="label"
					:disabled="disabled"
					hide-details
					dense
					outlined
					:persistent-placeholder="label ? true : false"
					:class="(contentClass ? contentClass : '') + ' pointer'"
					append-icon="event"
				>
				</v-text-field>
				<v-text-field
					v-else
					:value="dateFormatted"
					readonly
					:label="label"
					:disabled="disabled"
					hide-details
					:persistent-placeholder="label ? true : false"
					:class="(contentClass ? contentClass : '') + ' h-outline pointer'"
					append-icon="event"
				>
				</v-text-field>
			</div>
		</template>
		<div style="max-width: 300px">
			<v-date-picker
				:first-day-of-week="$store.getters.firstDayOfWeek"
				v-model="localDate"
				no-title
				scrollable
				@change="dateUpdated"
			>
				<template slot="default">
					<div style="width: 100%" class="row-format centered">
						<div
							class="input-wrapper font-14 font-primary py-1 px-2 pointer"
							@click="
								localDate = null;
								dateUpdated();
							"
						>
							{{ $t('global.clear') }}
						</div>
					</div>
				</template>
			</v-date-picker>
		</div>
	</v-menu>
</template>

<script>
	import HDateTime from '@/modules/utils/HDateTime';

	export default {
		name: 'DateSelector',

		props: ['date', 'label', 'disabled', 'contentClass', 'minimal', 'micro', 'standard', 'dense', 'microFont', 'showYear'],

		components: {},

		data: function() {
			return {
				localDate: this.date,
				dateMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			dateUpdated: function() {
				this.dateMenu = false;
				this.$emit('change', this.localDate);
			},
		},

		computed: {
			dateFormatted: function() {
				if (this.localDate) {
					return HDateTime.fromISO(this.localDate).toLocaleString(HDateTime.DATE_SHORT);
				} else {
					return ' -- ';
				}
			},

			dateFormattedMinimal: function() {
				if (this.localDate) {
					if (this.showYear) {
						return HDateTime.fromISO(this.localDate).toLocaleString({
							month: 'short',
							day: 'numeric',
							year: 'numeric',
						});
					} else {
						return HDateTime.fromISO(this.localDate).toLocaleString({ month: 'short', day: 'numeric' });
					}
				} else {
					return ' -- ';
				}
			},

			relativeTime: function() {
				if (this.localDate && (this.minimal || this.micro)) {
					let fromDate = new Date();
					const [year, month, day] = this.localDate.split('-');
					const toDate = new Date(Number(year), Number(month) - 1, Number(day));
					return this.$formatters.relativeTime(fromDate, toDate);
				} else {
					return null;
				}
			},
		},

		watch: {
			date: function(newVal) {
				this.localDate = newVal;
			},
		},
	};
</script>

<style scoped lang="scss">
	.v-input.h-outline ::v-deep {
		input {
			font-size: 14px;
		}
	}
	//.select-append {
	//	border-left: 1px solid var(--v-gray_50-base);
	//	margin-top: 1px;
	//	margin-bottom: 8px;
	//	width: 45px;
	//	height: 25px;
	//}
</style>
