<template>
	<div class="row-format flex-wrap gap-4 text-left" style="width:100%">
		<input id="copyInput" name="copyInput" type="hidden" />
		<div :class="`column-format left-side ${$store.getters.scroll}`">
			<work-two-week></work-two-week>
		</div>
		<div :class="`column-format right-side gap-4 ${$store.getters.scroll}`">
			<div v-if="fullUser">
				<div class="brand-medium pb-2">Add new</div>
				<focus-quick-links></focus-quick-links>
			</div>
			<div class="brand-box">
				<day-calendar :is-visible="isVisible"></day-calendar>
			</div>
			<div class="brand-box" v-if="hasAnyQuickLinks">
				<v-container class="font-14 pb-4">
					<v-row dense>
						<v-col cols="12"><div class="brand-medium font-16 mb-2">Quick links</div></v-col>
					</v-row>
					<v-row v-if="clientPortal" dense>
						<v-col cols="5" class="row-format align-center">
							<v-icon small>public</v-icon>
							<div class="brand-medium font-gray_70 ml-1">Client portal</div>
						</v-col>
						<v-col cols="7" class="brand-bold">
							<div class="copy-link" @click="copyToClipboard('https://' + clientPortal + '/')">
								{{ clientPortal }}
							</div>
						</v-col>
					</v-row>
					<v-row v-if="communicatorPhone" dense>
						<v-col cols="5" class="row-format align-center">
							<v-icon small>phone</v-icon>
							<div class="brand-medium font-gray_70 ml-1">My phone</div>
						</v-col>
						<v-col cols="7" class="brand-bold">
							<div class="copy-link" @click="copyToClipboard(communicatorPhone)">{{ communicatorPhone }}</div>
						</v-col>
					</v-row>
					<v-row v-if="meetings.length" dense>
						<v-col cols="5">
							<div class="row-format align-center">
								<v-icon small>date_range</v-icon>
								<div class="brand-medium font-gray_70 ml-1">Meeting schedulers</div>
							</div>
						</v-col>
						<v-col cols="7" class="brand-bold">
							<div
								v-for="(meeting, index) in filteredMeetings"
								:key="meeting.id + index"
								class="row-format copy-link"
							>
								<div @click="copyToClipboard(fullMeetingLink(meeting))">{{ meeting.name }}</div>
							</div>
							<div v-if="inScopeMeetings.length > filteredMeetings.length" class="row-format">
								<div class="font-12 font-primary pointer" @click="showAllMeetings = true">
									+ {{ inScopeMeetings.length - filteredMeetings.length }} more schedulers
								</div>
							</div>
							<div v-if="filteredMeetings.length > 3">
								<div class="font-12 font-primary pointer" @click="showAllMeetings = false">
									- Hide {{ meetings.length - 3 }} schedulers
								</div>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>
	</div>
</template>

<script>
	import FocusQuickLinks from '@/modules/home/focus/FocusQuickLinks';
	import DayCalendar from '@/modules/home/focus/DayCalendar';
	import WorkTwoWeek from '@/modules/productivity/work/WorkTwoWeek';
	import MeetingService from '@/modules/meetings/MeetingService';
	import AccountService from '@/modules/account/AccountService';
	import libPhoneNumber from 'google-libphonenumber';
	import NotificationRouterMixin from "@/modules/home/focus/NotificationRouterMixin";
	export default {
		name: 'Focus',

		props: ['isVisible', 'fullUser'],

		components: { WorkTwoWeek, DayCalendar, FocusQuickLinks },

		mixins: [NotificationRouterMixin],

		data: function() {
			return {
				meetingService: new MeetingService(),
				meetings: [],
				baseUrl: process.env.VUE_APP_MEET_LINK,
				uniqueAccountUrl: null,
				showAllMeetings: false,
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
			};
		},

		mounted() {
			this.getUniqueUrl();
			this.getMeetingList();
			this.checkForNotificationRoute();
		},

		beforeDestroy() {},

		methods: {
			copyToClipboard(value) {
				navigator.clipboard.writeText(value);
				this.$store.commit('info', 'Copied to clipboard');
			},

			getMeetingList: function() {
				this.meetingService.getMeetingList().then((res) => {
					this.meetings.splice(0, this.meetings.length);
					res.data.forEach((m) => {
						if (!m.ownerUserId || m.ownerUserId === this.$store.getters.getLoggedInUserId) {
							this.meetings.push(m);
						}
					});
				});
			},

			getUniqueUrl: function() {
				let accountService = new AccountService();
				accountService.getAccountUniqueUrl().then((res) => (this.uniqueAccountUrl = res.data));
			},

			fullMeetingLink: function(meeting) {
				let commPref = this.$store.state.communicationPreferences;
				if (commPref && commPref.primaryDomain) {
					return (
						'https://' + commPref.primaryDomain + '/public/' + meeting.uniqueMeetingId
					);
				} else {
					return this.baseUrl + this.podId + '/' + this.uniqueAccountUrl + '/' + meeting.uniqueMeetingId;
				}
			},
		},

		computed: {
			inScopeMeetings: function(){
				return this.meetings.filter(m => m.active && (!m.ownerUserId || m.ownerUserId === this.$store.state.loggedInUser.userId));
			},

			filteredMeetings: function() {
				if (this.showAllMeetings) {
					return this.inScopeMeetings;
				} else {
					let result = [... this.inScopeMeetings];
					return result.splice(0, 3);
				}
			},

			hasAnyQuickLinks: function() {
				if (this.clientPortal || this.communicatorPhone || this.meetings.length) {
					return true;
				} else {
					return false;
				}
			},

			podId: function() {
				let podId = this.$store.getters.getAccount.pod.podId;
				return podId.replace(/\D/g, '');
			},

			clientPortal: function() {
				if (this.$store.state.communicationPreferences) {
					return this.$store.state.communicationPreferences.primaryDomain;
				} else {
					return null;
				}
			},

			communicatorPhone: function() {
				if (this.$store.state.communicatorSubscription) {
					let number = this.$store.state.communicatorSubscription.numbers[0].number;
					let phone = this.PhoneUtil.parse(number);
					return this.PhoneUtil.format(phone, this.PNF.NATIONAL);
				} else {
					return null;
				}
			},

			leftWidth: function() {
				if (this.$vuetify.breakpoint.xsOnly) {
					return '100%';
				} else {
					return '60%';
				}
			},
			rightWidth: function() {
				if (this.$vuetify.breakpoint.xsOnly) {
					return '100%';
				} else {
					return '40%';
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.left-side {
		flex: 2 1 0%; /* Takes up 2/3 of the width */
		min-width: 400px; /* Optional: Sets a minimum width before wrapping */
		max-height: calc(100vh - 130px);
		overflow-y: auto;
		padding-bottom: 24px;
	}

	.right-side {
		flex: 1 1 0%; /* Takes up 1/3 of the width */
		max-height: calc(100vh - 130px);
		overflow-y: auto;
		padding-bottom: 24px;
	}

	.rule {
		border-top: 1px solid var(--v-gray_30-base);
		border-bottom: none;
	}

	.copy-link {
		cursor: pointer;
		&:hover {
			color: var(--v-primary-base);
		}
	}
</style>
