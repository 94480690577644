import VendorService from '@/modules/accounting/vendors/VendorService';
// import ClientService from '@/modules/clients/ClientService';
// import ProjectService from '@/modules/projects/ProjectService';

export default {
	data: function() {
		return {
			refreshKey: 0,
			vendors: [],
			clients: [],
			projects: [],
			expenses: [],
			vendorService: new VendorService(),
			vendorToEdit: null,
			vendorDetailDialog: false,
			isReady: false,
		};
	},

	methods: {
		getVendors: function() {
			this.vendorService.getVendors().then((res) => {
				this.vendors.splice(0, this.vendors.length);
				this.vendors.push(...res.data);
				this.vendors.sort((a, b) => (a.name > b.name) ? 1 : -1);
				this.vendors.forEach((v) => {
					if(!v.contact){
						v.contact = {}
					}
				})
				this.isReady = true;
			});
		},

		handleCreate: function(vendor) {
			this.refreshKey++;
			this.vendorToEdit = vendor;
			this.vendors.push(vendor);
			this.vendors.sort((a, b) => (a.name > b.name) ? 1 : -1)
		},

		handleUpdate: function(vendor) {
			this.refreshKey++;
			let ix = this.vendors.findIndex((e) => vendor.id === e.id);
			if (ix !== -1) {
				console.log(ix);
				console.log(vendor);
				this.vendors.splice(ix, 1, vendor);
				this.vendors.sort((a, b) => (a.name > b.name) ? 1 : -1)
			} else {
				this.handleCreate(vendor);
			}
		},

		handleDelete: function(id) {
			this.refreshKey++;
			let ix = this.vendors.findIndex((e) => id === e.id);
			if (ix !== -1) {
				this.vendors.splice(ix, 1);
			}
			this.deleteVendorDialog = false;
			this.vendorDetailDialog = false;
			this.deletingVendor = null;
		},

		deleteVendor: function(vendor) {
			if (!vendor.id) {
				return;
			} else {
				this.vendorService
					.deleteVendor(vendor.id)
					.then(() => {
						this.handleDelete(vendor.id);
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			}
		},

		editVendor: function(vendor) {
			this.vendorToEdit = vendor;
			this.vendorDetailDialog = true;
		},

		createVendor: function() {
			this.vendorToEdit = {
				contact: {},
			};
			this.vendorDetailDialog = true;
		},
	},
};
