var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "500px" }, attrs: { id: "" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("result")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c("div", { staticClass: "modal-title" }, [
          _vm._v(" Transfer project to another client ")
        ]),
        _c(
          "div",
          { staticClass: "column-format gap-6 text-left font-14" },
          [
            _c("div", [
              _vm._v(
                "Please select the client you would like to transfer this project to. All tasks, notes, and time entries will be moved along with the project."
              )
            ]),
            _c("select-client", {
              attrs: { "show-avatar": true, "hide-archive": true, dense: true },
              model: {
                value: _vm.selectedClientId,
                callback: function($$v) {
                  _vm.selectedClientId = $$v
                },
                expression: "selectedClientId"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "modal-footer row-format gap-2 centered" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              on: {
                click: function($event) {
                  return _vm.$emit("result")
                }
              }
            },
            [
              _c("span", { staticClass: "font-gray_70" }, [
                _vm._v(_vm._s(_vm.$t("global.cancel")))
              ])
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action",
              attrs: { disabled: !_vm.selectedClientId },
              on: {
                click: function($event) {
                  return _vm.transferProject()
                }
              }
            },
            [_vm._v(" Confirm transfer ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }