import Vue from 'vue';
import App from './App';
import Vuetify from 'vuetify';
import router from './router';
import store from './store';
import i18n from './i18n';
import InstantSearch from 'vue-instantsearch';
import Luxon from './luxon';
import Formatters from './utils/formatters';
import Validations from './utils/validations';
import Tracker from './utils/tracker';
import OnBoarding from './utils/onBoarding'
import VueScrollTo from 'vue-scrollto';
import VueSignaturePad from 'vue-signature-pad';
import VueYouTubeEmbed from 'vue-youtube-embed';
import icons from './assets/icons/icons';
import themes from './assets/css/themes';

import 'tinymce';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/table';


/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';

/* A theme is also required */
import 'tinymce/themes/silver';



//fix build
//import 'material-icons/iconfont/material-icons.css';
//import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

import HIcon from "@/components/HIcon";
import HIcon2 from '@/components/HIcon2';
import 'vuetify/dist/vuetify.min.css';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import VueTippy, { TippyComponent } from 'vue-tippy';
import 'tippy.js/themes/light-border.css';

import { Chart } from 'chart.js';
import 'chart.js/auto';
Chart.defaults.font.family = 'Inter';
Chart.defaults.font.size = 14;
Chart.defaults.plugins.tooltip.enabled = true;
Chart.defaults.plugins.legend.display = false;

Number.prototype.round = function (places) {
	return +(Math.round(this + 'e+' + places) + 'e-' + places);
};

Vue.use(VueTippy, {
	directive: 'tippy', // => v-tippy
	theme: 'light-border',
	ignoreAttributes: true,
	duration: [500, 100],
	placement: 'top',
	arrow: true,
	arrowType: 'round',
	animation: 'fade',
	delay: 50,
	boundary: 'window',
	onShow: (options) => {
		return !!options.props.content
	},
});

Vue.component('tippy', TippyComponent);
Vue.component('phone-number-input', VuePhoneNumberInput);

Vue.use(VueScrollTo, {
	container: 'body',
	duration: 500,
	easing: 'ease-in-out',
	offset: 0,
	force: true,
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false,
	y: true,
});


Vue.use(Formatters);
Vue.use(Luxon);
Vue.use(Vuetify);
Vue.use(InstantSearch);
Vue.use(Validations);
Vue.use(VueSignaturePad);
Vue.use(VueYouTubeEmbed);
Vue.use(Tracker);
Vue.use(OnBoarding);
Vue.use(VueTelInput);
Vue.component('h-icon', HIcon);
Vue.component('h-icon2', HIcon2);
Vue.config.productionTip = false;

/*
Add the Google ReCaptcha Script to the HTML
 */
let s = document.createElement( 'script' );
s.setAttribute( 'src', `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECATPCHA_SITE_KEY}`);
document.body.appendChild( s );

/*
Initialize the Intercom JavaScript in the main page so that it is available to be loaded
post login
 */
(function () {
	var w = window;
	var ic = w.Intercom;
	if (typeof ic === 'function') {
		ic('reattach_activator');
		ic('update', w.intercomSettings);
	} else {
		var d = document;
		var i = function () {
			i.c(arguments);
		};
		i.q = [];
		i.c = function (args) {
			i.q.push(args);
		};
		w.Intercom = i;
		var l = function () {
			var s = d.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.src = 'https://widget.intercom.io/widget/' + process.env.VUE_APP_INTERCOM_APP_ID;
			var x = d.getElementsByTagName('script')[0];
			x.parentNode.insertBefore(s, x);
		};
		if (document.readyState === 'complete') {
			l();
		} else if (w.attachEvent) {
			w.attachEvent('onload', l);
		} else {
			w.addEventListener('load', l, false);
		}
	}
})();

(function (h, o, t, j, a, r) {
	h.hj =
		h.hj ||
		function () {
			(h.hj.q = h.hj.q || []).push(arguments);
		};
	h._hjSettings = { hjid: process.env.VUE_APP_HOTJAR_SITE, hjsv: 6 };
	a = o.getElementsByTagName('head')[0];
	r = o.createElement('script');
	r.async = false;
	r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
	a.appendChild(r);
})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

const vuetify = new Vuetify({
	icons: icons,
	theme: {
		options: {
			customProperties: true,
			variations: false,
		},
		themes: themes,
	},
});

new Vue({
	vuetify,
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
