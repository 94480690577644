var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row-format mb-3" }, [
      _c(
        "div",
        {
          staticClass: "numberWidget clickable mr-3 column-format",
          on: {
            click: function($event) {
              return _vm.$emit("clicked", "invoiced")
            }
          }
        },
        [
          _c("div", { staticClass: "header" }, [_vm._v("Invoiced")]),
          _c("div", { staticClass: "body" }, [
            _vm._v(_vm._s(_vm.$formatters.dollars(_vm.totalInvoiced, false)))
          ]),
          _c("div", { staticClass: "row-format font-14 font-gray_70 gap-2" }, [
            _vm.currentDue
              ? _c("div", [
                  _vm._v(
                    "Due soon: " +
                      _vm._s(_vm.$formatters.dollars(_vm.currentDue, false))
                  )
                ])
              : _vm._e(),
            _vm.pastDue
              ? _c("div", { staticStyle: { color: "var(--v-error-base)" } }, [
                  _vm._v(
                    "Past due: " +
                      _vm._s(_vm.$formatters.dollars(_vm.pastDue, false))
                  )
                ])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "numberWidget clickable",
          on: {
            click: function($event) {
              return _vm.$emit("clicked", "received")
            }
          }
        },
        [
          _c("div", { staticClass: "header" }, [_vm._v("Received")]),
          _c("div", { staticClass: "body" }, [
            _vm._v(_vm._s(_vm.$formatters.dollars(_vm.totalReceived, false)))
          ])
        ]
      )
    ]),
    _c("div", { staticClass: "row-format" }, [
      _c("div", { staticClass: "splitContainer row-format mr-3" }, [
        _c(
          "div",
          {
            staticClass: "splitWidget clickable",
            on: {
              click: function($event) {
                return _vm.$emit("clicked", "otherIncome")
              }
            }
          },
          [
            _c("div", { staticClass: "header" }, [_vm._v("Other income")]),
            _c("div", { staticClass: "body" }, [
              _vm._v(
                _vm._s(_vm.$formatters.dollars(_vm.totalOtherIncome, false))
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "splitWidget clickable ml-auto",
            on: {
              click: function($event) {
                return _vm.$emit("clicked", "expenses")
              }
            }
          },
          [
            _c("div", { staticClass: "header" }, [_vm._v("Expenses")]),
            _c("div", { staticClass: "body" }, [
              _vm._v(_vm._s(_vm.$formatters.dollars(_vm.totalExpenses, false)))
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "numberWidget" }, [
        _c("div", { staticClass: "header" }, [_vm._v("Net income")]),
        _c("div", { staticClass: "body" }, [
          _vm._v(_vm._s(_vm.$formatters.dollars(_vm.netIncome, false)))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }