<template>
	<notification-layout :id="id" :action-text="`${$t('notifications.view-ticket')}`" @action="navigateToTicket()" :sent-at="sentAt">
		<template slot="header">
			<client-avatar x-small v-if="client" :client="client" />
			<div class="ml-2">{{ client ? client.name : '[Unknown]' }}</div>
		</template>

		<template slot="content">
			<div class="font-14 mb-1 brand-medium"><span v-if="ticket.ticketNumber">{{ticket.ticketNumber}}: </span>{{ $t('notifications.ticket-created') }}</div>
			<div class="font-12">
				<span>{{ticket.subject}} - created  {{ ticket.created | formatForTimeAgo }} </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import ClientAvatar from '@/components/ClientAvatar';
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";
	import TicketDetail from "@/modules/communicator/inbox/tickets/TicketDetail";

	export default {
		name: 'TicketCreatedNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { ClientAvatar, NotificationLayout},

		data: function() {
			return {
				client: this.notificationPayload.client,
				ticket: this.notificationPayload.ticket
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			navigateToTicket: function() {
				let binding = {
					id: this.ticket.id,
				};
				this.$store.state.globalModalController.openModal(TicketDetail, binding);
			},
		},

		computed: {

		},
	};
</script>

<style scoped lang="scss">
	.notification-summary {
		color: #267ae1;
		font-size: small;
		border-radius: 8px;
		padding: 8px;
		background-color: #e9f2fc;
	}

	.greyFont {
		color: var(--v-gray_70-base);
	}
</style>
