var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.rightBar
      ? _c("div", { staticClass: "row-format m1-2 mt-1 centered" }, [
          _c(
            "div",
            { staticClass: "row-format align-center" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "pointer",
                  attrs: { small: "", color: "secondary" },
                  on: { click: _vm.prevDay }
                },
                [_vm._v("$chevronLeft")]
              ),
              _c("div", { staticClass: "mx-1 font-14 font-secondary" }, [
                _vm._v(_vm._s(_vm.formattedDate))
              ]),
              _c(
                "v-icon",
                {
                  staticClass: "pointer",
                  attrs: { small: "", color: "secondary" },
                  on: { click: _vm.nextDay }
                },
                [_vm._v("$chevronRight")]
              )
            ],
            1
          )
        ])
      : _c("div", { staticClass: "row-format mb-2" }, [
          _c(
            "div",
            {
              staticClass: "brand-medium text-left mt-2 ml-3 pointer",
              on: { click: _vm.today }
            },
            [_vm._v("Schedule")]
          ),
          _c(
            "div",
            { staticClass: "ml-auto row-format align-center mt-2 mr-2" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "pointer",
                  attrs: { small: "" },
                  on: { click: _vm.prevDay }
                },
                [_vm._v("$chevronLeft")]
              ),
              _c("div", { staticClass: "mx-1 font-14 font-gray_80" }, [
                _vm._v(_vm._s(_vm.formattedDate))
              ]),
              _c(
                "v-icon",
                {
                  staticClass: "pointer",
                  attrs: { small: "" },
                  on: { click: _vm.nextDay }
                },
                [_vm._v("$chevronRight")]
              )
            ],
            1
          )
        ]),
    _vm.processedEvents.length
      ? _c(
          "div",
          {
            staticClass: "show-scrollbar",
            staticStyle: { height: "calc(100% - 40px)", "overflow-y": "auto" }
          },
          _vm._l(_vm.processedEvents, function(event) {
            return _c(
              "div",
              {
                key: event.uuid,
                staticClass: "pointer mx-2 my-2 pa-1 event",
                style:
                  "background-color: " +
                  event.backgroundColor +
                  "; border-radius: 4px; font-size: 14px;",
                attrs: { id: event.uuid },
                on: {
                  click: function($event) {
                    return _vm.eventClicked({ event: event })
                  }
                }
              },
              [
                _c(event.componentName, {
                  tag: "component",
                  staticStyle: { "min-height": "30px" },
                  attrs: { event: event, timed: event.timed, view: "Schedule" }
                })
              ],
              1
            )
          }),
          0
        )
      : _vm.isReady
      ? _c(
          "div",
          {
            staticClass: "row-format centered mt-5 pb-8",
            staticStyle: { height: "calc(100% - 40px)" }
          },
          [
            _c(
              "div",
              { staticClass: "column-format centered" },
              [
                _c("h-icon2", {
                  staticStyle: { "--icon-color": "var(--v-gray_80-base)" },
                  attrs: { name: "calendar", size: "35" }
                }),
                _c("div", { staticClass: "font-gray_80" }, [
                  _vm._v("Nothing on your calendar today")
                ])
              ],
              1
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }