<template>
	<div>
		<input type="file" ref="EmailAttachment" @change="setAttachments" multiple style="display: none" />
		<div class="mb-1">
			<v-select
				hide-details
				dense
				outlined
				v-if="fromList.length && !isReply"
				:items="fromList"
				label="From:"
				v-model="from"
			></v-select>
			<email-contact-selector
				:to-list="to"
				label="To"
				@update="toUpdated($event)"
				:client-id="clientId"
			></email-contact-selector>
			<div v-if="showCCs">
				<email-contact-selector
					:to-list="cc"
					label="CC"
					@update="ccUpdated($event)"
					:client-id="clientId"
				></email-contact-selector>
				<email-contact-selector
					:to-list="bcc"
					label="BCC"
					@update="bccUpdated($event)"
					:client-id="clientId"
				></email-contact-selector>
			</div>
			<v-text-field
				outlined
				v-if="showSubject"
				v-model="ourSubject"
				hide-details
				dense
				class="mt-1"
				placeholder="Subject"
			></v-text-field>
			<div v-if="!showCCs" class="text-left font-12 font-primary pointer my-2" @click="showCCs = true">Show CC/BCC</div>
		</div>
		<editor
			ref="EmailEditor"
			:api-key="$store.getters.getTinyMceKey"
			:initial-value="initialContent"
			:init="mceConfigText"
			:spellcheck="true"
		></editor>
		<div v-if="attachmentNames.length || s3Attachments.length" class="row-format mt-2" style="flex-wrap: wrap; gap: 8px">
			<v-chip
				small
				v-for="(name, index) in attachmentNames"
				:key="name + index"
				close
				close-icon="cancel"
				@click:close="removeAttachment(name)"
				>{{ name }}</v-chip
			>
			<v-chip
				color="blue_10"
				small
				v-for="(s3, index) in s3Attachments"
				:key="s3.fileName + index"
				close
				close-icon="cancel"
				@click:close="removeS3Attachment(s3)"
				>{{ s3.fileName }}</v-chip
			>
		</div>
		<v-checkbox
			v-model="addTrackingPixel"
			label="Add open/viewed tracking pixel"
			hide-details
			dense
			persistent-hint
			class="mb-2"
		></v-checkbox>
		<div class="row-format mt-2 centered">
			<send-email-button label="Send" @click="sendEmail($event)" :width="140"></send-email-button>
			<v-btn class="" style="min-height: 40px" icon @click="cancelSend()">
				<v-icon size="20">$delete</v-icon>
			</v-btn>
		</div>
		<ai-email-widget
			v-if="aiDialog"
			:dialog="aiDialog"
			:mode="aiMode"
			@cancel="aiDialog = false"
			@result="runAiAssist($event)"
		></ai-email-widget>
	</div>
</template>

<script>
	import Editor from '@tinymce/tinymce-vue';
	import EmailContactSelector from '@/modules/communicator/inbox/email/EmailContactSelector';
	import EmailTemplateSelector from '@/modules/templates/emails/EmailTemplateSelector';
	import SchedulerSelector from '@/modules/meetings/SchedulerSelector';
	import SendEmailButton from '@/components/SendEmailButton';
	import AiAssistantService from '@/modules/ai/AiAssistantService';
	import AiEmailWidget from '@/modules/communicator/inbox/email/AiEmailWidget';
	import marked from 'marked';

	export default {
		name: 'EmailEditor',

		props: {
			initialContent: {
				type: String,
				required: false,
				default: null,
			},
			height: {
				type: Number,
				required: false,
				default: 300,
			},
			subject: {
				type: String,
				required: false,
				default: null,
			},
			showSubject: {
				type: Boolean,
				required: false,
				default: true,
			},
			isReply: {
				type: Boolean,
				required: false,
				default: false,
			},
			toList: {
				type: Array,
				required: false,
				default: () => [],
			},
			ccList: {
				type: Array,
				required: false,
				default: () => [],
			},
			emailBoxes: {
				type: Array,
				required: false,
				default: () => [],
			},
			opportunityId: {
				type: String,
				required: false,
				default: null,
			},
			clientId: {
				type: String,
				required: false,
				default: null,
			},
		},

		components: { AiEmailWidget, SendEmailButton, Editor, EmailContactSelector },

		data: function() {
			return {
				aiDialog: false,
				aiMode: 'draft',
				aiContext: '',
				aiSession: null,
				aiAssistantService: new AiAssistantService(),
				to: [...this.toList],
				cc: [...this.ccList],
				bcc: [],
				ourSubject: this.subject,
				attachments: [],
				s3Attachments: [],
				from: null,
				showCCs: false,
				addTrackingPixel: false,
				mceConfigText: {
					auto_focus: true,
					menubar: false,
					inline: false,
					browser_spellcheck: true,
					paste_as_text: false,
					table_style_by_css: true,
					forced_root_block: 'div',
					paste_data_images: true,
					plugins: ['paste', 'lists', 'link', 'table', 'code'],
					contextmenu: 'paste | link | table | assistant',
					branding: false,
					statusbar: false,
					height: `calc(100vh - 350px)`,
					resize: 'both',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'undo redo | fontsizeselect forecolor bold italic underline | alignleft aligncenter alignright alignjustify',
						'table | fontselect | bullist numlist | link unlink | code | removeformat scheduler attachment template ai',
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

					setup: (editor) => {
						editor.on('init', () => this.setSignature());

						editor.ui.registry.addNestedMenuItem('assistant', {
							text: '✨Assistant',
							getSubmenuItems: () => {
								return [
									{
										type: 'menuitem',
										text: 'Elaborate',
										onAction: () => {
											this.rewrite('elaborate');
										},
									},
									{
										type: 'menuitem',
										text: 'Simplify',
										onAction: () => {
											this.rewrite('simplify');
										},
									},
									{
										type: 'menuitem',
										text: 'Make more formal',
										onAction: () => {
											this.rewrite('formalize');
										},
									},
									{
										type: 'menuitem',
										text: 'Make friendlier',
										onAction: () => {
											this.rewrite('friendlier');
										},
									},
									{
										type: 'menuitem',
										text: 'Summarize',
										onAction: () => {
											this.rewrite('summarize');
										},
									},
									{
										type: 'menuitem',
										text: 'Paraphrase',
										onAction: () => {
											this.rewrite('paraphrase');
										},
									},
								];
							},
						});

						editor.ui.registry.addButton('scheduler', {
							text:
								'<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M596.817-220Q556-220 528-248.183q-28-28.183-28-69T528.183-386q28.183-28 69-28T666-385.817q28 28.183 28 69T665.817-248q-28.183 28-69 28ZM180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Z"/></svg>',
							onAction: () => {
								this.selectMeetingLink();
							},
						});

						editor.ui.registry.addButton('ai', {
							text: '✨Assistant',
							onAction: () => {
								this.emailAssistant();
							},
						});

						editor.ui.registry.addButton('attachment', {
							text:
								'<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M21,10V4c0-1.1-0.9-2-2-2H3C1.9,2,1.01,2.9,1.01,4L1,16c0,1.1,0.9,2,2,2h11v-5c0-1.66,1.34-3,3-3H21z M11,11L3,6V4l8,5 l8-5v2L11,11z"/><path d="M21,14v4c0,1.1-0.9,2-2,2s-2-0.9-2-2v-4.5c0-0.28,0.22-0.5,0.5-0.5s0.5,0.22,0.5,0.5V18h2v-4.5c0-1.38-1.12-2.5-2.5-2.5 S15,12.12,15,13.5V18c0,2.21,1.79,4,4,4s4-1.79,4-4v-4H21z"/></g></g></svg>',
							onAction: () => {
								this.$refs.EmailAttachment.click();
							},
						});

						editor.ui.registry.addButton('template', {
							text: 'Insert template',
							onAction: () => {
								this.selectEmailTemplate();
							},
						});
					},
				},
			};
		},

		mounted() {
			this.from = this.fromList.length ? this.fromList[0].value : null;

			if (localStorage.getItem('ADD_TRACKING_PIXEL') === 'true') {
				this.addTrackingPixel = true;
			}

			setTimeout(() => {
				let nodeSpan = this.$refs.EmailEditor.editor.dom.select('div');
				if (nodeSpan && nodeSpan.length) {
					this.$refs.EmailEditor.editor.selection.setCursorLocation(nodeSpan[0].firstChild, 0);
					this.$refs.EmailEditor.editor.focus();
					nodeSpan[0].scrollIntoView();
				}
			}, 1000);
		},

		beforeDestroy() {},

		methods: {
			rewrite(modifier) {
				let editor = this.$refs.EmailEditor.editor;
				let selection = editor.selection.getContent({ format: 'html' });

				if (selection) {
					this.$store.commit('startLoading');
					this.aiAssistantService
						.rewrite(selection, modifier)
						.then((response) => {
							editor.selection.setContent(response);
						})
						.catch((err) => this.$store.commit('error', 'Error processing request: ' + err.response?.data?.message))
						.finally(() => this.$store.commit('stopLoading'));
				}
			},

			sortByDefaultEmailBox() {
				return this.emailBoxes.sort((a, b) => (a.defaultEmailBox === b.defaultEmailBox ? 0 : a.defaultEmailBox ? -1 : 1));
			},

			setSignature: function() {
				if (this.signature) {
					this.$refs.EmailEditor.editor.execCommand('mceInsertContent', false, '<br><br>' + this.signature);
				}
			},

			sendEmail: function(sendLater = null) {
				let email = {
					to: this.to,
					cc: this.cc,
					bcc: this.bcc,
					subject: this.ourSubject,
					htmlContent: this.$refs.EmailEditor.editor.getContent(),
					addTrackingPixel: this.addTrackingPixel,
				};

				this.$emit('send-email', {
					from: this.isReply ? null : this.from,
					email: email,
					attachments: this.attachments,
					s3Attachments: this.s3Attachments,
					sendLater: sendLater,
				});
			},

			selectMeetingLink: function() {
				this.$store.state.globalModalController.openModal(SchedulerSelector, {}).then((res) => {
					if (res) {
						let link = `<a href="${res.link}" target="_blank">${res.name}</a>`;
						this.$refs.EmailEditor.editor.execCommand('mceInsertContent', false, link);
					}
				});
			},

			async emailAssistant() {
				const selectedText = this.$refs.EmailEditor.editor.selection.getContent({ format: 'text' });

				if (selectedText.length > 0) {
					this.aiMode = 'rewrite';
					this.aiContext = selectedText;
				} else if (this.initialContent) {
					this.aiMode = 'reply';
					this.aiContext = '';
				} else {
					this.aiMode = 'draft';
					this.aiContext = '';
				}

				this.aiDialog = true;
			},

			async runAiAssist(params) {
				try {
					this.$store.commit('startLoading');

					this.aiDialog = false;
					await this.initializeAiSession();
					let instructions;
					if (this.aiMode === 'reply') {
						instructions = `Please draft a reply to the original email in a ${params.tone} tone. Here is info about what I want in the reply: ${params.input}.`;
					} else {
						instructions = `Please write me a new email in a ${params.tone} tone.  The email content should include: ${params.input}.`;
					}

					if (this.to.length) {
						instructions += '; The email is being sent to: ';
						for (let i = 0; i < this.to.length; i++) {
							instructions += this.to[i];
						}
					}

					let editor = this.$refs.EmailEditor.editor;
					let initialContent = editor.getContent();
					let newContent = '';

					if (this.aiMode === 'rewrite') {
						this.deleteSelectedText();
					} else {
						editor.setContent('');
						editor.selection.setCursorLocation(editor.getBody(), 0);
					}

					await this.aiAssistantService.continueSessionStreaming(this.aiSession.id, instructions, {
						onData: (data) => {
							newContent += data;
							this.$refs.EmailEditor.editor.execCommand('mceInsertContent', false, this.removeMarkdownCharacters(data));
						},
						onComplete: () => {
							this.$store.commit('stopLoading');
							if (this.aiMode !== 'rewrite') {
								let formatted = marked(newContent, {breaks: true});
								editor.setContent(formatted + initialContent);
								editor.selection.setCursorLocation(editor.getBody(), 0);
							}
						},
						onError: () => {
							this.$store.commit('stopLoading');
						},
					});
				}catch(err){
					this.$store.commit('error','There was an error processing the request: ' +err.response?.data?.message);
				}finally{
					this.$store.commit('stopLoading');
				}
			},

			removeMarkdownCharacters: function(text) {
				return text
					.replace(/[_*~`>#+\-=|{}[\]()]/g, '') // Remove common Markdown characters
					.replace(/^\s*\n/gm, ''); // Remove empty lines
			},

			deleteSelectedText: function() {
				const editor = this.$refs.EmailEditor.editor;
				const selection = editor.selection;
				const rng = selection.getRng();
				const startNode = rng.startContainer;
				const startOffset = rng.startOffset;
				selection.setContent('');
				selection.setCursorLocation(startNode, startOffset);
			},

			async initializeAiSession() {
				if (!this.aiSession) {
					//let model = 'gpt-4o';
					let model = 'gpt-4o-mini';

					let createRequest = {
						model: model,
						useCase: 'EmailResponse',
						context: [],
					};

					createRequest.context.push({ role: 'system', content: 'You are helping to draft an email.' });
					createRequest.context.push({
						role: 'system',
						content: 'Your response should only contain the actual email content without the subject line, additional prompts, questions, or instructions.',
					});
					createRequest.context.push({
						role: 'system',
						content: `The senders name is ${this.$store.state.loggedInUser.firstName}`,
					});

					if (this.initialContent) {
						let content = this.initialContent.substring(0, Math.min(this.initialContent.length, 25000));
						createRequest.context.push({
							role: 'system',
							content: `For context, this is the original email thread that is in descending order that we are replying to: ${content}`,
						});
					}

					let result = await this.aiAssistantService.createChatSession(createRequest);
					this.aiSession = result.data;
				}
			},

			selectEmailTemplate: function() {
				let contacts = [];
				let clientId = this.clientId;

				this.to.forEach((to) => {
					let email = to.match(/[^@<\s]+@[^@\s>]+/g);
					if (email.length) {
						let contact = this.$store.getters.getContactByEmail(email[0]);
						if (contact) {
							contacts.push(contact);
							if (contact.clientId && !clientId) {
								clientId = contact.clientId;
							}
						}
					}
				});

				let binding = {
					contacts: contacts,
					clientId: clientId,
					opportunityId: this.opportunityId,
					mailboxId: this.from,
				};

				this.$store.state.globalModalController.openModal(EmailTemplateSelector, binding).then((res) => {
					if (res) {
						if (!this.isReply) {
							this.ourSubject = res.subject;
						}
						this.$refs.EmailEditor.editor.setContent('');

						if (this.initialContent) {
							this.$refs.EmailEditor.editor.execCommand(
								'mceInsertContent',
								false,
								res.htmlContent + '<br>' + this.initialContent
							);
						} else {
							this.$refs.EmailEditor.editor.execCommand('mceInsertContent', false, res.htmlContent);
						}

						let nodeSpan = this.$refs.EmailEditor.editor.dom.select('div');
						if (nodeSpan && nodeSpan.length) {
							this.$refs.EmailEditor.editor.selection.setCursorLocation(nodeSpan[0].firstChild, 0);
							this.$refs.EmailEditor.editor.focus();
							nodeSpan[0].scrollIntoView();
						}

						this.s3Attachments.splice(0, this.s3Attachments.length);
						this.s3Attachments.push(...res.attachments);
					}
				});
			},

			cancelSend: function() {
				this.$emit('cancel');
			},

			toUpdated: function(to) {
				this.to.splice(0, this.to.length);
				this.to.push(...to);
			},

			ccUpdated: function(cc) {
				this.cc.splice(0, this.cc.length);
				this.cc.push(...cc);
			},

			bccUpdated: function(bcc) {
				this.bcc.splice(0, this.bcc.length);
				this.bcc.push(...bcc);
			},

			setAttachments: function(event) {
				event.target.files.forEach((f) => {
					this.attachments.push(f);
				});
			},

			removeAttachment: function(name) {
				for (let i = this.attachments.length - 1; i >= 0; i--) {
					if (this.attachments[i].name === name) {
						this.attachments.splice(i, 1);
					}
				}
			},

			removeS3Attachment: function(s3File) {
				let ix = this.s3Attachments.findIndex((s) => s.fileName === s3File.fileName);
				if (ix > -1) {
					this.s3Attachments.splice(ix, 1);
				}
			},
		},

		watch: {
			signature: {
				handler: function(val) {
					if (val && this.$refs.EmailEditor && this.$refs.EmailEditor.editor) {
						this.$refs.EmailEditor.editor.execCommand('mceInsertContent', false, '<br><br>' + val);
					}
				},
			},

			addTrackingPixel: function(val) {
				localStorage.setItem('ADD_TRACKING_PIXEL', val);
			},
		},

		computed: {
			attachmentNames: function() {
				return this.attachments.map((a) => a.name);
			},

			signature: function() {
				let emailBox = this.emailBoxes.find((e) => e.id === this.from);
				if (emailBox) {
					return emailBox.signature;
				} else {
					return null;
				}
			},

			fromList: function() {
				const emailBoxes = this.sortByDefaultEmailBox();
				return emailBoxes.map((e) => ({
					value: e.id,
					text: e.fullFromAddress,
				}));
			},
		},
	};
</script>

<style lang="scss">
	.tox-toolbar__group:last-child {
		margin-left: auto !important;
	}
</style>
