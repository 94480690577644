import Pipeline from "@/modules/leadgen/Pipeline";
import PipeLineV2 from "@/modules/pipeline/PipeLineV2";
import PipelineRouter from "@/modules/pipeline/PipelineRouter";
import PipelineSettings from "@/modules/pipeline/settings/PipelineSettings";

export default [
	{
		path: '/pipeline',
		name: 'pipelineRouter',
		component: PipelineRouter,
	},
	{
		path: '/pipeline/legacy',
		name: 'legacyPipeline',
		component: Pipeline,
	},
	{
		path: '/pipeline/opportunities',
		name: 'pipeline',
		component: PipeLineV2,
		props: true
	},
	{
		path: '/pipeline/forecast',
		name: 'pipelineForecast',
		component: PipeLineV2,
	},
	{
		path: '/pipeline/settings',
		name: 'settingsRouter',
		component: PipelineSettings,
		title: 'Settings router',
		props: true
	},
];
