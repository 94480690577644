import { render, staticRenderFns } from "./ProjectOverview.vue?vue&type=template&id=371e8c46&scoped=true&"
import script from "./ProjectOverview.vue?vue&type=script&lang=js&"
export * from "./ProjectOverview.vue?vue&type=script&lang=js&"
import style0 from "./ProjectOverview.vue?vue&type=style&index=0&id=371e8c46&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "371e8c46",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3589467384/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('371e8c46')) {
      api.createRecord('371e8c46', component.options)
    } else {
      api.reload('371e8c46', component.options)
    }
    module.hot.accept("./ProjectOverview.vue?vue&type=template&id=371e8c46&scoped=true&", function () {
      api.rerender('371e8c46', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/projects/detail/overview/ProjectOverview.vue"
export default component.exports