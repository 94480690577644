import HttpClient from '@/services/HttpClient';

export default class ZoomAuthService {
    httpClient;

    constructor() {
        this.httpClient = new HttpClient();
    }

    getStatus() {
        return this.httpClient
            .get('/zoom/status')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    getUserList() {
        return this.httpClient
            .get('/zoom')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    connect(authCode,redirectUri) {
        return this.httpClient
            .post(`/zoom/connect?authCode=${authCode}&redirectUri=${encodeURIComponent(redirectUri)}`)
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }

    disconnect() {
        return this.httpClient
            .post('/zoom/disconnect')
            .then((res) => Promise.resolve(res))
            .catch((err) => Promise.reject(err));
    }
}