var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-4" },
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { cols: "12", md: "6", align: "left" } }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format",
                    staticStyle: { "flex-wrap": "wrap", gap: "4px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "stripe-connect",
                        on: { click: _vm.handleConnectRequest }
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.buttonLabel))])]
                    ),
                    _vm.isConnected && _vm.chargesEnabled
                      ? _c(
                          "v-icon",
                          { staticClass: "ml-5", attrs: { color: "success" } },
                          [_vm._v("$success")]
                        )
                      : _vm._e(),
                    !_vm.isConnected
                      ? _c("div", { staticClass: "mx-3" }, [_vm._v("or")])
                      : _vm._e(),
                    !_vm.isConnected
                      ? _c(
                          "div",
                          {
                            staticClass: "stripe-connect",
                            on: { click: _vm.handleOAuthRequest }
                          },
                          [
                            _c("span", [
                              _vm._v("Connect an existing Stripe account")
                            ])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                _c(
                  "div",
                  {
                    staticClass: "row-format",
                    style: _vm.$vuetify.breakpoint.smAndDown
                      ? ""
                      : "justify-content: flex-end"
                  },
                  [
                    _c("v-switch", {
                      staticClass: "mt-0",
                      attrs: {
                        disabled: !_vm.chargesEnabled,
                        "hide-details": "",
                        dense: "",
                        label: "Stripe payments enabled"
                      },
                      on: { change: _vm.updatePreferences },
                      model: {
                        value: _vm.cardPaymentsEnabled,
                        callback: function($$v) {
                          _vm.cardPaymentsEnabled = $$v
                        },
                        expression: "cardPaymentsEnabled"
                      }
                    }),
                    _vm.isUsdAccount
                      ? _c("v-switch", {
                          staticClass: "mt-0 ml-3",
                          attrs: {
                            disabled: !_vm.chargesEnabled,
                            "hide-details": "",
                            dense: "",
                            label: "ACH payments enabled"
                          },
                          on: { change: _vm.updatePreferences },
                          model: {
                            value: _vm.achPaymentsEnabled,
                            callback: function($$v) {
                              _vm.achPaymentsEnabled = $$v
                            },
                            expression: "achPaymentsEnabled"
                          }
                        })
                      : _vm._e(),
                    _vm.isUsdAccount && _vm.legacyAchEnabled
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mt-0 ml-1",
                            attrs: { icon: "" },
                            on: { click: _vm.openHelpInfo }
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("$help")
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "row-format gap-3" }, [
        _c("div", { staticClass: "kpi-box" }, [
          _c("div", { staticClass: "fit text-left" }, [_vm._v("Available")]),
          _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
            _vm._v(
              _vm._s(
                _vm.$formatters.dollars(_vm.available, true, true, _vm.currency)
              )
            )
          ])
        ]),
        _c("div", { staticClass: "kpi-box" }, [
          _c("div", { staticClass: "fit text-left" }, [_vm._v("Pending")]),
          _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
            _vm._v(
              _vm._s(
                _vm.$formatters.dollars(_vm.pending, true, true, _vm.currency)
              )
            )
          ])
        ]),
        _c("div", { staticClass: "kpi-box" }, [
          _c("div", { staticClass: "fit text-left" }, [_vm._v("On the way")]),
          _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
            _vm._v(
              _vm._s(
                _vm.$formatters.dollars(_vm.onTheWay, true, true, _vm.currency)
              )
            )
          ])
        ])
      ]),
      _vm.isConnected
        ? _c(
            "div",
            {},
            [
              _c("v-data-table", {
                attrs: {
                  items: _vm.transactions,
                  headers: _vm.headers,
                  "items-per-page": 10
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.amount",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$formatters.dollars(
                                  item.amount,
                                  true,
                                  true,
                                  item.currency
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.fee",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$formatters.dollars(
                                  item.fee,
                                  true,
                                  true,
                                  item.currency
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.net",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$formatters.dollars(
                                  item.net,
                                  true,
                                  true,
                                  item.currency
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1167266604
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "row-format centered mt-8",
          staticStyle: { width: "100%" }
        },
        [
          !_vm.isConnected
            ? _c(
                "div",
                {
                  staticClass: "column-format centered",
                  staticStyle: { "max-width": "800px" }
                },
                [_vm._m(0)]
              )
            : _vm._e(),
          _vm.isConnected && !_vm.chargesEnabled
            ? _c("div", { staticStyle: { "max-width": "800px" } }, [
                _c(
                  "div",
                  { staticClass: "my-6 column-format centered" },
                  [
                    _c("v-icon", { attrs: { color: "warning" } }, [
                      _vm._v("$alert")
                    ]),
                    _vm._v(" Your account isn't fully configured yet. "),
                    _vm.stripeIntegration.provisioningEventuallyDue.length > 0
                      ? _c("span", [
                          _vm._v(
                            "There are at least " +
                              _vm._s(
                                _vm.stripeIntegration.provisioningEventuallyDue
                                  .length
                              ) +
                              " items you still need to provide."
                          )
                        ])
                      : _vm.stripeIntegration.pendingVerification.length > 0
                      ? _c("span", [
                          _vm._v(
                            "The Stripe team is verifying the information you provided. Please check back soon. "
                          )
                        ])
                      : _vm._e(),
                    _c("br"),
                    _vm._v(
                      " You can click the button above at any time to continue on-boarding or view the status of your application. "
                    ),
                    _c("br"),
                    _c("span", { staticClass: "small-label" }, [
                      _vm._v(
                        "** If you haven't done so already, please check your inbox to confirm your email address with Stripe."
                      )
                    ])
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column-format centered" }, [
      _c("h2", [
        _vm._v(
          "Sign up with Stripe to start accepting online payments globally."
        )
      ]),
      _c("div", { staticClass: "font-14 mt-6" }, [
        _vm._v(" With the "),
        _c(
          "a",
          { attrs: { href: "https://stripe.com/pricing", target: "_blank" } },
          [_vm._v("Stripe integration")]
        ),
        _vm._v(
          ' enabled, a "Pay Now" button will appear on your invoices so that you can get paid faster! Accept all major credit cards, ACH, Apple Pay, and Google Pay. In addition to traditional invoicing, activating Stripe will also enable the '
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://help.withmoxie.com/en/articles/6010167-using-subscription-billing",
              target: "_blank"
            }
          },
          [_vm._v("Subscription billing")]
        ),
        _vm._v(" features of Moxie. ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }