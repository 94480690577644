<template>
	<div>

	</div>
</template>

<script>
export default {
	name: "ProjectRouter",

	props: [],

	components: {},

	data: function () {
		return {
			validOptions: ['overview','tasks','list']
		}
	},

	mounted() {
		let projectView = localStorage.getItem('PROJECT_VIEW');
		if(this.validOptions.includes(projectView)){
			this.$router.push(`/projects/${projectView}`);
		}else{
			this.$router.push('/projects/overview');
		}

	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>