<template>
	<div class="column-format gap-5">
		<v-textarea
			v-model="element.question"
			rows="3"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Question"
			@input="updated"
		></v-textarea>
		<v-text-field
			v-model="element.placeholder"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Placeholder"
			@input="updated"
		></v-text-field>
		<v-switch v-model="element.multi" hide-details dense label="Allow multiple files" class="mt-n2" @change="updated"></v-switch>
		<v-switch v-model="element.required" hide-details dense label="Required" class="mt-n2" @change="updated"></v-switch>
		<v-text-field
				v-model="element.fieldName"
				hide-details
				dense
				persistent-placeholder
				outlined
				label="Reporting field name"
				@input="updated"
		></v-text-field>
		<div class="column-format text-left font-14">
			<div>Restrict file types</div>
			<v-checkbox hide-details dense v-model="element.fileTypes" value="image/*" label="Image" @change="updated"></v-checkbox>
			<v-checkbox hide-details dense v-model="element.fileTypes" value="video/*" label="Video" @change="updated"></v-checkbox>
			<v-checkbox hide-details dense v-model="element.fileTypes" value="audio/*" label="Audio" @change="updated"></v-checkbox>
			<v-checkbox hide-details dense v-model="element.fileTypes" value="application/pdf" label="PDF" @change="updated"></v-checkbox>
			<v-checkbox
				hide-details
				dense
				v-model="element.fileTypes"
				value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				label="MS Excel"
				@change="updated"
			></v-checkbox>
			<v-checkbox
				hide-details
				dense
				v-model="element.fileTypes"
				value="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
				label="MS Word"
				@change="updated"
			></v-checkbox>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FileInputConfig',

		props: ['item'],

		components: {},

		data: function() {
			return {
				element: { ...this.item },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
