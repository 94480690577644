<template>
	<v-combobox
		ref="PhoneInput"
		v-model="to"
		:items="tokens"
		hide-details
		dense
		outlined
		persistent-placeholder
		:label="label"
		:multiple="multiple"
		small-chips
		@change="handleChange($event)"
		:filter="isMatch"
	>
	</v-combobox>
</template>

<script>
	import libPhoneNumber from 'google-libphonenumber';

	export default {
		name: 'ContactSelectorPhone',

		props: ['toList', 'label', 'multiple', 'phone'],

		components: {},

		data: function() {
			return {
				to: this.multiple ? [...this.toList] : this.toList,
				PhoneUtil: libPhoneNumber.PhoneNumberUtil.getInstance(),
				PNF: libPhoneNumber.PhoneNumberFormat,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleChange: function(to){
				if(!to || (to.startsWith("{{") && to.endsWith("}}"))){
					this.$emit('change',to)
				}else{
					let result;
					try{
						if (to.startsWith('+')) {
							result = this.PhoneUtil.parse(to);
						} else if (to.startsWith('1')){
							result = this.PhoneUtil.parse('+' + to);
						} else {
							result = this.PhoneUtil.parse('+1' + to);
						}

						if (this.PhoneUtil.isValidNumber(result)) {
							this.to = this.PhoneUtil.format(result, this.PNF.E164);
							this.$emit('change', this.to);
						}else{
							this.$refs.PhoneInput.reset();
							this.to = null;
							this.$emit('change', this.to);
						}
					}catch(err){
						this.$refs.PhoneInput.reset();
						this.to = null;
						this.$emit('change', this.to);
					}
				}
			},

			isMatch: function(val1, val2) {
				if (val1 && val2) {
					return val1
						.trim()
						.toLowerCase()
						.includes(val2.trim().toLowerCase());
				} else {
					return false;
				}
			},
		},

		computed: {
			tokens: function() {
				return [
					'{{Opportunity.Phone}}',
					'{{Form.Phone}}',
					'{{Contacts.Phone.Default}}',
					'{{Contacts.Phone.All}}',
					'{{Contacts.Phone.Invoice}}',
					'{{Contacts.Phone.Portal}}',
				];
			},
		},
	};
</script>

<style scoped lang="scss"></style>
