var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format", attrs: { id: "settings-modal" } },
    [
      _c(
        "div",
        { staticClass: "settings-left-nav" },
        _vm._l(_vm.views, function(view) {
          return _c(
            "div",
            {
              key: view.value,
              class: "item " + (view.value === _vm.currentView ? "active" : ""),
              on: {
                click: function($event) {
                  return _vm.setView(view.value)
                }
              }
            },
            [_c("div", [_vm._v(_vm._s(view.label))])]
          )
        }),
        0
      ),
      _c("div", { staticClass: "settings-content" }, [
        _c(
          "div",
          { staticClass: "header-bar row-format align-center px-4" },
          [
            _c(
              "div",
              { staticClass: "row-format align-center" },
              [
                _c("v-icon", { attrs: { small: "", color: "gray_90" } }, [
                  _vm._v("$settings")
                ]),
                _c("div", { staticClass: "font-gray_90 ml-2 brand-medium" }, [
                  _vm._v("Settings / " + _vm._s(_vm.selectedView.label))
                ])
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticClass: "ml-auto",
                attrs: { icon: "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("result")
                  }
                }
              },
              [_c("v-icon", [_vm._v("$close")])],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content show-scrollbar pb-8" },
          [
            _vm.currentView === "account"
              ? _c("account", {
                  on: {
                    close: function($event) {
                      return _vm.$emit("result")
                    }
                  }
                })
              : _vm._e(),
            _vm.currentView === "connected-apps"
              ? _c("connected-apps", {
                  attrs: {
                    "full-user": _vm.isAccountFullUser,
                    owner: _vm.isAccountOwner
                  },
                  on: {
                    close: function($event) {
                      return _vm.$emit("result")
                    }
                  }
                })
              : _vm._e(),
            _vm.currentView === "stripe"
              ? _c("stripe", {
                  on: {
                    close: function($event) {
                      return _vm.$emit("result")
                    }
                  }
                })
              : _vm._e(),
            _vm.currentView === "data-import"
              ? _c("import", {
                  on: {
                    close: function($event) {
                      return _vm.$emit("result")
                    }
                  }
                })
              : _vm._e(),
            _vm.currentView === "preferences"
              ? _c("preferences", {
                  on: {
                    close: function($event) {
                      return _vm.$emit("result")
                    }
                  }
                })
              : _vm._e(),
            _vm.currentView === "team-members"
              ? _c("account-users", {
                  on: {
                    close: function($event) {
                      return _vm.$emit("result")
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }