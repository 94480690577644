<template>
	<div>
		<v-data-table
			:headers="recurringPaymentTableHeaders"
			:hide-default-footer="true"
			:items="recurringPayments"
			:disable-pagination="true"
			@click:row="openPayment($event.id)"
			class="pointer"
		>
			<template v-slot:item.amount="{ item }">
				{{ $formatters.dollars(item.income.amount, true, true, item.income.currency) }}
			</template>
			<template v-slot:item.startDate="{ item }">
				{{ DateTime.fromISO(item.startDate).toLocaleString(DateTime.DATE_MED) }}
				<span v-if="item.endDate"> thru {{ DateTime.fromISO(item.endDate).toLocaleString(DateTime.DATE_MED) }}</span>
			</template>
			<template v-slot:item.nextRunTime="{ item }">
				<span v-if="item.nextRunTime && item.enabled">{{
					DateTime.fromISO(item.nextRunTime)
						.setZone($store.state.podAccount.timeZone)
						.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
				}}</span>
				<span v-else>--</span>
			</template>
			<template v-slot:item.status="{ item }">
				<span v-if="item.enabled" style="text-transform: capitalize">{{ item.status.toLowerCase() }}</span>
				<span v-else>Disabled</span>
			</template>
			<template v-slot:item.interval="{ item }">
				<v-icon size="14" style="margin-bottom:1px; margin-right:4px">autorenew</v-icon>{{ getLabel(item) }}
			</template>
		</v-data-table>

		<basic-modal
			v-if="recurringPaymentDetailDialog"
			:dialog="recurringPaymentDetailDialog"
			@close="recurringPaymentDetailDialog = false"
			:persistent="true"
		>
			<recurring-payment
				:id="recurringPaymentId"
				@close="recurringPaymentDetailDialog = false"
				@result="recurringEventSaved($event)"
				@deleted="recurringEventDeleted($event)"
			/>
		</basic-modal>
	</div>
</template>

<script>
	import RecurringEventMixin from '@/modules/recurrence/RecurringEventMixin';
	import BasicModal from '@/components/BasicModal';
	import DateTime from '@/modules/utils/HDateTime';
	import RecurringPayment from '@/modules/accounting/payments/RecurringPayment';

	export default {
		name: 'RecurringPaymentList',

		props: [],

		mixins: [RecurringEventMixin],

		components: { BasicModal, RecurringPayment },

		data: function() {
			return {
				recurringPaymentDetailDialog: false,
				recurringPaymentId: null,
				recurringPayments: [],
				DateTime: DateTime,
				showPayments: false,
			};
		},

		mounted() {
			this.initRecurringPayments();
		},

		beforeDestroy() {},

		methods: {
			addNew: function() {
				this.recurringPaymentDetailDialog = true;
				this.recurringPaymentId = null;
			},

			openPayment: function(id) {
				this.recurringPaymentDetailDialog = true;
				this.recurringPaymentId = id;
			},

			initRecurringPayments: function() {
				this.getRecurringPayments().then((res) => {
					this.recurringPayments.splice(0, this.recurringPayments.length);
					this.recurringPayments.push(...res.data);
				});
			},

			recurringEventSaved: function(event) {
				let ix = this.recurringPayments.findIndex((r) => r.id === event.id);
				if (ix > -1) {
					this.recurringPayments.splice(ix, 1, event);
				} else {
					this.recurringPayments.push(event);
				}
				this.recurringPaymentDetailDialog = false;
			},

			recurringEventDeleted: function(id) {
				let ix = this.recurringPayments.findIndex((r) => r.id === id);
				if (ix > -1) {
					this.recurringPayments.splice(ix, 1);
				}
				this.recurringPaymentDetailDialog = false;
			},
		},

		computed: {
			recurringPaymentTableHeaders: function() {
				let headers = [
					{ text: this.$t('invoice.details.payment-details-modal.amount'), value: 'amount', class: 'nowrap' },
					{ text: this.$t('recurring.payments.description'), value: 'income.description' },
					{ text: this.$t('recurring.start-date'), value: 'startDate' },
					{ text: this.$t('recurring.repeat-every'), value: 'interval' },
					{ text: this.$t('recurring.next-run-time'), value: 'nextRunTime' },
					{ text: this.$t('invoice.status'), value: 'status' },
				];

				return headers;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
