<template>
	<div v-if="isReady" class="">
		<div class="row-format align-start">
			<div class="column-format">
				<div class="font-gray_80 mb-1">{{ $t('connected-apps.apple.desc') }}</div>
				<connected v-if="isConnected"></connected>
				<div v-else class="font-primary brand-medium pointer" @click="connectAppleAccount">Connect iCloud calendar</div>
			</div>
			<div class="ml-auto pointer" @click="expanded = !expanded" v-if="isConnected">
				<v-icon>{{ expanded ? '$chevronUp' : '$chevronDown' }}</v-icon>
			</div>
		</div>
		<div v-if="isConnected && expanded" class="mt-5">
			<div class="mb-6 font-14">
				<div>iCloud account {{appleAccount.email}} successfully connected.  Please select which calendars you would like enabled:</div>
				<v-checkbox v-for="cal in appleAccount.calendars" v-model="cal.enabled" :label="cal.name" :key="cal.path" hide-details dense @change="updateSettings"></v-checkbox>
			</div>
			<v-btn class="primary-action mr-2" @click="refreshConnection"><v-icon class="mr-1">refresh</v-icon>Refresh calendar list</v-btn>
			<v-btn class="secondary-action mr-1" @click="confirmDisconnect"><v-icon small>$delete</v-icon>Disconnect</v-btn>
		</div>
	</div>
</template>

<script>
	import Connected from '@/modules/account/apps/Connected';
	import ConfirmModal from '@/components/ConfirmModal';
	import AppleCalendarConnect from "@/modules/account/apps/AppleCalendarConnect";
	import AppleCalendarService from "@/modules/integrations/apple/AppleCalendarService";

	export default {
		name: 'AppleCalendar',

		props: [],

		components: { Connected },

		data: function () {
			return {
				appleService: new AppleCalendarService(),
				expanded: false,
				isReady: false,
				appleAccount: null,
			};
		},

		mounted() {
			this.getConnection();
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
		},

		methods: {
			handleAccountChange: function () {
				this.getConnection();
			},


			getConnection: function () {
				this.appleService.getAccount().then(res => {
					this.appleAccount = res.data;
					this.isReady = true;
				})
			},

			updateSettings: function(){
				this.appleService.update(this.appleAccount).then(res => {
					this.appleAccount = res.data;
				})
			},

			refreshConnection: function(){
				this.appleService.refresh().then(res => {
					this.appleAccount = res.data;
				})
			},

			connectAppleAccount: function () {
				this.$store.state.globalModalController.openModal(AppleCalendarConnect).then(res => {
					if(res){
						this.appleAccount = res;
						this.expanded = true;
						this.$onBoarding.track('connect_calendar');
					}
				})
			},

			confirmDisconnect: function () {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to disconnect your iCloud calendar?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.appleService.disconnect().then(() => {
							this.appleAccount = null;
							this.expanded = false;
						})
					}
				});
			},
		},

		computed: {
			isConnected: function(){
				if(this.appleAccount){
					return true;
				}else{
					return false;
				}
			}
		},
	};
</script>

<style scoped lang="scss"></style>
