var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row-format fill-height" }, [
    _c("div", { staticClass: "left-side" }, [_vm._t("left")], 2),
    _c("div", { staticClass: "right-side" }, [_vm._t("right")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }