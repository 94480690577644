<template>
	<div>
		<input type="hidden" v-model="stage_id" ref="stage_id" df-stage_id />

		<node-label @open="edit()">
			<template v-slot:default>
				<v-icon class="mr-1" color="black" size="22">bolt</v-icon>
				<div v-if="selectedStage">Set stage: {{selectedStage.label}}</div>
				<div v-else>Update stage</div>
			</template>
		</node-label>

		<node-modal ref="nodeModal" title="Update stage">
			<template v-slot:default>
				<div class="column-format gap-2">
					<v-select
						hide-details
						dense
						outlined
						persistent-placeholder
						label="Stage"
						:items="stages"
						item-text="label"
						item-value="id"
						v-model="stage_id"
					></v-select>
				</div>
			</template>
		</node-modal>
	</div>
</template>

<script>
	import NodeModal from '@/modules/workflow/nodes/NodeModal';
	import NodeLabel from '@/modules/workflow/nodes/NodeLabel';
	import NodeMixin from '@/modules/workflow/nodes/NodeMixin';

	export default {
		name: 'OpportunityStage',
		inputs: 1,
		outputs: 1,

		mixins: [NodeMixin],

		props: [],

		components: { NodeLabel, NodeModal },

		data: function() {
			return {
				stage_id: null,
			};
		},

		mounted() {
			this.$nextTick(() => {
				this.stage_id = this.$refs.stage_id.value;
			});
		},

		beforeDestroy() {},

		methods: {
			edit: function() {
				this.$refs.nodeModal.open();
			},
		},

		watch: {
			stage_id: function() {
				this.emitInput('stage_id');
			},
		},

		computed: {
			stages: function(){
				return this.$store.state.pipelineStages;
			},

			selectedStage: function(){
				return this.stages.find(s => s.id === this.stage_id);
			}
		},
	};
</script>

<style scoped lang="scss"></style>
