<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' },{ label: 'Tickets' }]">
			Ticket list
		</page-header>
		<ticket-view></ticket-view>
	</div>
</template>

<script>
	import TicketView from '@/modules/communicator/inbox/TicketView';
	import PageHeader from "@/components/PageHeader";
	export default {
		name: 'TicketRoute',

		props: [],

		components: { TicketView, PageHeader },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
