var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row-format",
      staticStyle: { width: "100%", height: "100%", position: "relative" }
    },
    [
      _c(
        "div",
        {
          staticClass: "pa-2",
          staticStyle: {
            width: "300px",
            height: "100%",
            "background-color": "var(--v-white-base)"
          }
        },
        _vm._l(_vm.nodeType, function(node) {
          return _c(
            "div",
            {
              key: node.value,
              staticClass: "row-format node-type pa-1 gap-2",
              attrs: { draggable: "true", id: node.value },
              on: { drag: _vm.drag }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "material-symbols-rounded",
                  attrs: { color: "black", size: "24" }
                },
                [_vm._v(_vm._s(node.icon))]
              ),
              _c("div", { staticClass: "column-format text-left" }, [
                _c("div", { staticClass: "font-14" }, [
                  _vm._v(_vm._s(node.label))
                ]),
                _c("div", { staticClass: "font-12 mt-n1 font-gray_70" }, [
                  _vm._v(_vm._s(node.description))
                ])
              ])
            ],
            1
          )
        }),
        0
      ),
      _c("div", {
        staticStyle: {
          display: "block",
          position: "relative",
          width: "calc(100% - 200px)",
          height: "100%"
        },
        attrs: { id: "drawflow" },
        on: { dragover: _vm.allowDrop, drop: _vm.drop }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }