<template>
	<div v-if="ticketSettings" >
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' },{ to: '/tickets', label: 'Tickets' }]">
			Settings
		</page-header>

		<div v-if="!$store.getters.hasFeatureAccess('settings')" class="pa-12">
			No access to ticket settings.
		</div>
		<v-container class="pa-0 ma-0" fluid v-else>
			<input id="forwardingAddress" name="forwardingAddress" :value="forwardingAddress" type="hidden" />
			<v-row dense>
				<v-col cols="12" class="pt-3">
					<v-data-table
						:items="ticketSettings.ticketTypes"
						:headers="headers"
						class="pointer"
						:hide-default-footer="true"
						:items-per-page="-1"
						@click:row="editTicketType($event)"
					>
						<template v-slot:item.attachedFormId="{ item }">
							<span>{{ getTemplateById(item.attachedFormId).name }}</span>
						</template>
						<template v-slot:item.id="{ item }">
							<v-icon size="20" class="pointer" @click.stop="confirmDelete(item)">$delete</v-icon>
						</template>

						<template v-slot:header.id="{}">
							<div class="row-format">
								<v-icon size="20" class="ml-auto pointer" style="" @click="addNew()">$plus</v-icon>
							</div>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" class="text-left font-14">
					<div class="pt-8">
						New requests can be opened by known contacts by emailing
						<span class="font-primary brand-medium pointer" @click="copyToClipboard()">{{ forwardingAddress }}</span
						>. If you have a custom domain with email hosting you can consider creating a forwarding email address
						from a support@ or help@ account on your domain to this email. Please check with your email provider on
						how to configure email forwarding. <br /><br />
						In addition, if your client emails you directly, you can forward their email to
						<span class="font-primary brand-medium pointer" @click="copyToClipboard()">{{ forwardingAddress }}</span>
						directly and the email will be converted into a request for the client contact.
					</div>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" class="text-left pt-8">
					<span class="font-16">Registered Forwarding Addresses</span><br />
					<span class="font-12 font-gray_70"
						>With most email providers, you will need to register your support@ or help@ forwarded email address below
						to ensure our email routing system can properly process your forwarded messages.</span
					>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12" class="row-format gap-2">
					<v-text-field
						v-model="customForwardingAddress"
						hide-details
						dense
						persistent-placeholder
						outlined
						placeholder="support@yourdomain.com"
					></v-text-field>
					<v-btn
						class="super-action"
						style="height: 40px!important;"
						:disabled="!customForwardingAddress"
						@click="addCustomForwardingAddress()"
						>Register address</v-btn
					>
				</v-col>
			</v-row>
			<v-row
				dense
				class="text-left font-14 py-3"
				v-for="address in customForwardingAddresses"
				:key="address.email"
				style="border-bottom:  1px solid var(--v-gray_30-base)"
			>
				<v-col cols="6">{{ address.email }}</v-col>
				<v-col cols="4">{{ DateTime.fromISO(address.timestamp).toLocaleString(DateTime.DATETIME_MED) }}</v-col>
				<v-col cols="2" class="text-right"
					><v-icon class="material-symbols-rounded pointer" @click="deleteCustomForwardingAddress(address.email)"
						>delete</v-icon
					></v-col
				>
			</v-row>
		</v-container>
		<sync-domains-config class="mt-8"></sync-domains-config>
	</div>
</template>

<script>
	import CommunicatorTicketService from '@/modules/communicator/CommunicatorTicketService';
	import DiscoveryTemplateService from '@/modules/discovery/DiscoveryTemplateService';
	import { v4 as uuid4 } from 'uuid';
	import ConfirmModal from '@/components/ConfirmModal';
	import TicketTypeModal from '@/modules/communicator/settings/TicketTypeModal';
	import HDateTime from '@/modules/utils/HDateTime';
	import PageHeader from "@/components/PageHeader";
	import SyncDomainsConfig from "@/modules/communicator/settings/SyncDomainsConfig";

	export default {
		name: 'TicketSettings',

		props: ['filter'],

		components: {SyncDomainsConfig, PageHeader},

		data: function() {
			return {
				DateTime: HDateTime,
				ticketService: new CommunicatorTicketService(),
				templateService: new DiscoveryTemplateService(),
				ticketSettings: null,
				templates: [],
				customForwardingAddresses: [],
				forwardingAddress: null,
				customForwardingAddress: null,
			};
		},

		mounted() {
			this.initialize();
			this.$store.state.eventBus.$on('account-changed', this.initialize);
			this.$store.state.eventBus.$on('communicator_onboarding_tickets',this.handleOnBoardingRequest);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.initialize);
			this.$store.state.eventBus.$off('communicator_onboarding_tickets',this.handleOnBoardingRequest);
		},

		methods: {
			handleOnBoardingRequest: function(){
				this.addNew();
			},

			initialize: function() {
				this.getRequestSettings();
				this.getTemplates();
				this.getForwardingAddress();
				this.getCustomForwardingAddresses();
			},

			copyToClipboard: function() {
				navigator.clipboard.writeText(this.forwardingAddress);
				this.$store.commit('success', 'Address copied to clipboard');
			},

			getCustomForwardingAddresses: function() {
				this.ticketService.getCustomForwardingAddresses().then((res) => {
					this.customForwardingAddresses.splice(0);
					this.customForwardingAddresses.push(...res.data);
				});
			},

			addCustomForwardingAddress: function() {
				this.ticketService
					.addCustomForwardingAddress(this.customForwardingAddress)
					.then((res) => {
						this.customForwardingAddresses.push(res.data);
						this.customForwardingAddress = null;
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});
			},

			deleteCustomForwardingAddress: function(email) {
				this.ticketService.deleteCustomForwardingAddress(email).then(() => {
					this.getCustomForwardingAddresses();
				});
			},

			getForwardingAddress: function() {
				this.ticketService.getForwardingAddress().then((res) => (this.forwardingAddress = res.data));
			},

			getRequestSettings: function() {
				this.ticketService.getTicketSettings().then((res) => {
					this.ticketSettings = res.data;
				});
			},

			getTemplates: function() {
				this.templateService.getDiscoveryTemplateList().then((res) => {
					this.templates.splice(0, this.templates.length);

					let mappedTemplates = res.data.map((t) => ({ id: t.id, name: t.name }));
					this.templates.push({ id: null, name: ' --' });
					this.templates.push(...mappedTemplates);
				});
			},

			editTicketType: function(type) {
				let binding = {
					ticketType: type,
					templates: this.templates,
				};

				this.$store.state.globalModalController.openModal(TicketTypeModal, binding).then((res) => {
					if (res) {
						let ix = this.ticketSettings.ticketTypes.findIndex((t) => t.id === type.id);
						this.ticketSettings.ticketTypes.splice(ix, 1, res);
						this.saveTicketSettings();
					}
				});
			},

			saveTicketSettings: function() {
				this.ticketService
					.updateTicketSettings(this.ticketSettings)
					.then((res) => (this.ticketSettings = res.data))
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			confirmDelete: function(type) {
				if (this.ticketSettings.ticketTypes.length < 2) {
					this.$store.commit('error', 'You just have at least one request type.');
					return;
				}
				let binding = {
					headerText: 'Confirm',
					bodyText: `Are you sure you want to delete ${type.type}?`,
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						let ix = this.ticketSettings.ticketTypes.findIndex((t) => t.id === type.id);
						this.ticketSettings.ticketTypes.splice(ix, 1);
						this.saveTicketSettings();
					}
				});
			},

			addNew: function() {
				let type = {
					id: uuid4(),
					type: 'New request type',
					statusList: ['New', 'Open', 'Resolved'],
					attachedFormId: null,
				};

				this.ticketSettings.ticketTypes.push(type);
				this.$onBoarding.track('communicator_setup_ticketing');
				this.editTicketType(type);
			},

			getTemplateById: function(id) {
				if (id) {
					let result = this.templates.find((t) => t.id === id);
					if (result) {
						return result;
					} else {
						return { id: id, name: '[Deleted]' };
					}
				} else {
					return { id: null, name: ' -- ' };
				}
			},
		},

		computed: {
			headers: function() {
				return [
					{ text: 'Request type', value: 'type' },
					{ text: 'Status list', value: 'statusList' },
					{ text: 'Attached form', value: 'attachedFormId' },
					{ text: null, value: 'id', align: 'right', sortable: false },
				];
			},
			paidAccount() {
				return this.$store.getters.isPaidAccount;
			},
		},
	};
</script>

<style scoped lang="scss">
	.button-box {
		width: 50%;
		border: 1px solid var(--v-gray_50-base);
		cursor: pointer;
		&:hover {
			background-color: var(--v-gray_10-base);
		}
	}
</style>
