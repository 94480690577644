<template>
	<div style="max-width: 400px">
		<v-btn icon class="close-dialog" @click="handleClose">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="text-center">
				<v-avatar size="55" v-if="user.original.profilePicture">
					<img class="pic" :src="user.original.profilePicture" />
				</v-avatar>
				<v-avatar size="55" v-else color="primary">
					<span style="color: var(--v-white-base);" class="font-24">{{ initials }}</span>
				</v-avatar>
				<div class="font-20 brand-medium mt-4">{{ user.name }}</div>
				<div>{{ user.email }}</div>
			</div>
			<div class="mt-3 text-left">
				<span class="brand-medium font-14">Has access to</span>
				<div
					style="
							max-height: 400px;
							overflow-y: auto;
							background-color: var(--v-gray_10-base);
							border: 1px solid var(--v-gray_50-base);
						"
					class="pa-3"
				>
					<div
							style="max-height: 400px; overflow-y: auto; background-color: var(--v-gray_10-base); border: 1px solid var(--v-gray_50-base)"
							class="pa-3"
					>
						<v-checkbox label="Projects" hide-details dense v-model="featureAccess.projects"></v-checkbox>
						<v-checkbox label="Invoices" hide-details dense v-model="featureAccess.invoices"></v-checkbox>
						<v-checkbox label="Accounting" hide-details dense v-model="featureAccess.accounting"></v-checkbox>
						<v-checkbox label="Pipeline" hide-details dense v-model="featureAccess.pipeline"></v-checkbox>
						<v-checkbox label="Agreements" hide-details dense v-model="featureAccess.agreements"></v-checkbox>
						<v-checkbox label="Settings" hide-details dense v-model="featureAccess.settings"></v-checkbox>
						<v-checkbox label="Tickets/Requests" hide-details dense v-model="featureAccess.tickets"></v-checkbox>
						<v-checkbox label="Timesheets" hide-details dense v-model="featureAccess.timesheets"></v-checkbox>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" @click="$emit('result', false)">
				{{ $t('global.cancel') }}
			</v-btn>
			<v-btn width="200" class="super-action ml-1" @click="updateUser()">{{ $t('global.save') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import UserService from '@/modules/users/UserService';

	export default {
		name: 'FeatureListDialog',

		props: ['user'],

		components: {  },

		data() {
			return {
				userService: new UserService(),
				featureAccess: this.user.original.featureAccess,
			};
		},

		mounted() {

		},

		methods: {
			updateUser: function() {
				this.$store.commit('startLoading');
				this.userService
					.syncFeatureAccess(this.$store.getters.getAccountId, this.featureAccess, this.user.email)
					.then(() => {
						this.$emit('result', true);
					})
					.catch((err) => {
						this.$emit('error', err);
						this.$store.commit('error', err.response.data.message);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			handleClose: function() {
				this.$emit('result');
			},
		},

		computed: {
			initials: function() {
				let initials = '';
				if (this.user.firstName) {
					initials += this.user.firstName.substr(0, 1).toUpperCase();
				}
				if (this.user.lastName) {
					initials += this.user.lastName.substr(0, 1).toUpperCase();
				}

				return initials ? initials : '?';
			},
		},
	};
</script>

<style scoped lang="scss"></style>
