<template>
	<div class="mock-scheduler pa-3"
       id="mock-scheduler">
		<div
			:style="`max-width: 900px; font-family: '${meeting.pageLayout.fontFamily}';`"
		>
			<v-row>
				<v-col
					sm="5"
					cols="12"
					:style="
						`background-color: ${meeting.pageLayout.backgroundColor}; border-right: 1px solid var(--v-gray_30-base)`
					"
				>
					<scheduler-info :meeting="meeting" :tokenMap="tokenMap"></scheduler-info>
				</v-col>
				<v-col sm="7" cols="12" :style="`background-color: ${meeting.pageLayout.backgroundMainColor}`">
					<calendar v-if="viewSelect" :meeting="meeting"> </calendar>
				</v-col>
			</v-row>
		</div>
		<div class="mt-6 text-left font-14 font-primary">
			*Meeting dates and times on preview do not reflect actual availability
		</div>
	</div>
</template>

<script>
	import { DateTime, Settings } from 'luxon';
	import timezones from '@/assets/data/timezones.json';
	import Calendar from '@/modules/meetings/detail/components/MockCalendar.vue';
	import SchedulerInfo from '@/modules/meetings/detail/components/SchedulerInfo.vue';

	export default {
		name: 'MockScheduler',

		props: ['meeting', 'confirmedTime', 'editMode', 'tokenMap'],

		components: {
			Calendar,
			SchedulerInfo,
		},

		data: function() {
			return {
				editorActive: false,
				viewSelect: true,
				timezones: timezones,
				DateTime: DateTime,
				Settings: Settings,
				selectedDate: null,
				selectedSlot: null,
				monthToShow: DateTime.now().toISODate(),
				filter: null,
				timezoneMenu: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			activateEditor(value) {
				this.editorActive = value;
			},

			setView: function() {
				this.viewSelect = false;
			},

			suffix: function(num) {
				let j = num % 10,
					k = num % 100;
				if (j === 1 && k !== 11) {
					return `${num}st`;
				} else if (j === 2 && k !== 12) {
					return `${num}nd`;
				} else if (j === 3 && k !== 13) {
					return `${num}rd`;
				} else {
					return `${num}th`;
				}
			},
		},

		computed: {
			minDate: function() {
				return DateTime.now().toISODate();
			},

			tokens: function() {
				let scheduler = [
					'Meeting.Name',
					'Meeting.Duration',
					'Meeting.TimeUnit',
					'Meeting.Location',
					'Meeting.LocationInfo',
					'Meeting.ScheduledTime',
					'Meeting.AccountName',
					'Meeting.OwnerName',
					'Meeting.TimeZone',
					'Meeting.Description',
					'Icons.Clock',
					'Icons.Mic',
					'Icons.Event',
					'Icons.Public',
				];
				return scheduler;
			},
		},
	};
</script>

<style lang="scss">
	#mock-scheduler {
		.v-date-picker-header {
			display: none !important;
		}

		.v-date-picker-table--date__week {
			color: var(--contrast-color);
		}

		.v-date-picker-table {
			height: unset !important;
			background-color: var(--dp-background-color);
			font-family: var(--dp-font-family) !important;

			th {
				color: var(--month-header-color);
			}

			td {
				padding-bottom: 8px;
			}

			.v-btn {
				.v-btn--disabled.v-icon {
				}
				&:not(.v-btn--disabled) {
					font-weight: 800;
					background-color: var(--light-accent);
					.v-btn__content {
						font-family: var(--dp-font-family);
						color: var(--accent) !important;
					}
				}
				&:is(.v-btn--disabled) {
					.v-btn__content {
						font-family: var(--dp-font-family);
						color: var(--date-color);
					}
				}
			}
		}

		.slot {
			min-width: 180px;
			flex: 1 1 0px;
			border: 1px solid var(--v-gray_30-base);
			border-radius: 4px;
			margin: 4px;
			color: var(--accent-color);
			font-weight: 600;
			transition: all 150ms ease;
			background-color: var(--background-color);

			&:hover {
				box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 3px;
			}
		}

		.selected {
			width: 50%;
		}

		.selected-slot {
			background-color: var(--light-accent);
			color: var(--accent-color);
			transition: all 150ms ease;
		}

		.dot-class {
			border-radius: 20px;
			width: 20px;
			height: 20px;
		}
	}

	.mock-scheduler {
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	}
</style>
