import HttpClient from "@/services/HttpClient";
import store from '@/store';

export default class CustomDomainService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(process.env.VUE_APP_BASE_AUTH_URL,true);
	}

	getCustomDomains() {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.get(`/account/${accountId}/domains`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	addCustomDomain(domain) {
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.post(`/account/${accountId}/domains`,null,{
				params:{
					domain:domain
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}


	deleteCustomDomain(domain){
		let accountId = store.getters.getAccountId;

		return this.httpClient
			.delete(`/account/${accountId}/domains`,{
				params:{
					domain:domain
				}
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
