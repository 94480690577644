<template>
	<div v-if="isReady" class="fill-all" style="min-height: calc(var(--vh) - 100px)">
		<div class="column-format centered fill-height">
			<div class="column-format centered mb-4 flex-grow-1">
				<div style="max-width: 530px;">
					<div class="brand-medium stratos-medium" style="color: var(--v-secondary-base); font-size: 40px">Welcome to Moxie!</div>
					<div class="brand-semilight mt-3 mb-5">
						Congratulations on taking the first step in transforming the way you manage and grow your freelance business;
						Moxie is here to help you every step of the way from overwhelmed and chaotic to in control and productive.
					</div>
				</div>
				<div>
					<img src="/images/onboarding/moxie-user.svg" width="300">
				</div>
				<!--div>
					<youtube
							:player-vars="{ autoplay: 0 }"
							id="player"
							video-id="Q2OFuBKxGT4"
					></youtube>
				</div-->
			</div>

			<div style="width: 100%; background: var(--v-gray_5-base); border: 1px solid var(--v-gray_30-base); border-radius: 4px" class="row-format mt-auto pa-12 gap-8">
				<div class="text-left column-format gap-4">
					<div class="pointer" @click="openHelpCenter()">
						<div class="font-primary">
							Moxie Help Center <v-icon color="primary">$questionMark</v-icon>
						</div>
						<div>Learn and master Moxie with advice from the team</div>
					</div>
					<div class="pointer" @click="openWindow('https://www.facebook.com/groups/withmoxie')">
						<div class="font-primary">
							Join the conversation! <v-icon small color="primary">group</v-icon>
						</div>
						<div>Connect with other users in the official Moxie Community. Get tips, tricks, and stay up to date on the latest Moxie info.</div>
					</div>
				</div>
				<div class="column-format text-left gap-4">
					<div class="pointer" @click="openWindow('https://www.withmoxie.com/guides')">
						<div class="font-primary">
							Guides for freelancers <v-icon small color="primary">book</v-icon>
						</div>
						<div>Land jobs, build your brand, run your business like a pro</div>
					</div>
					<div class="pointer" @click="openWindow('https://www.withmoxie.com/academy')">
						<div class="font-primary">
							Moxie Academy <v-icon small color="primary">school</v-icon>
						</div>
						<div>Micro modules created for freelancers just like you to level up your freelance game.</div>
					</div>
				</div>
			</div>
		</div>

		<modal
				v-if="celebrationDialogue"
				:dialog="celebrationDialogue"
				:max-width="600"
				:persistent="false"
				:close-on-escape="true"
				@close="celebrationDialogue = false"
		>
			<v-img
					lazy-src="/images/logo/brain-splash-small.png"
					max-width="40px"
					max-height="40px"
					style="margin: 0 auto"
					src="/images/logo/brain-splash-small.png"
					class="mt-3"
			/>
			<h3 class="mt-4 mb-4">Thanks for upgrading!</h3>
			<p style="max-width: 350px; margin: 0 auto">
				Look at you, adding clients and making your freelance dreams come true. Congratulations from all of us at Moxie.
			</p>
			<div class="mt-11">
				<v-img
						class="mb-2"
						style="border-radius: 4px"
						lazy-src="/images/dashboard/upgrade-modal-party.jpg"
						src="/images/dashboard/upgrade-modal-party.jpg"
				/>
			</div>
		</modal>
	</div>
</template>

<script>
import moment from 'moment';
import modal from '@bit/hecticapp.common.modal';
import AccountService from '@/modules/account/AccountService';

export default {
	name: 'Home',

	props: ['celebration', 'welcome'],

	components: { modal,},

	data: function () {
		return {
			moment: moment,
			celebrationDialogue: false,
			accountService: new AccountService(),
			currentPage: 0,
			observer: null,
			isReady: false,
		};
	},

	mounted() {
		this.$track.record('page-view', { module: 'home' });
		this.celebrationDialogue = this.celebration ? true : false;

		this.isReady = true;

		if (this.celebration) {
			window.history.pushState({}, document.title, this.$router.currentRoute.path);
		}
	},

	beforeDestroy() {},

	methods: {
		openWindow: function (url) {
			window.open(url, '_blank');
		},
		openHelpCenter: function(){
			this.$store.state.eventBus.$emit('open-help-center');
		}
	},

	watch: {},

	computed: {
		isAccountOwner: function () {
			return this.$store.getters.isAccountOwner;
		},
	},
};
</script>

<style lang="scss">

</style>