var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "notesEmbed", attrs: { id: "notes-embed" } },
    [
      _c(
        "div",
        { class: "pt-4 px-0 pb-0 mb-0 text-left notes " + _vm.notesCssClass },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-start align-center px-2 mt-4 mb-6" },
            [
              _c("v-icon", { attrs: { size: "20", color: "black" } }, [
                _vm._v("$notes")
              ]),
              _c(
                "span",
                { staticClass: "body-16 brand-semibold ml-2 black--text" },
                [_vm._v("Notes")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "px-2 mb-1" },
            [
              _c("markdown", {
                key: _vm.newNoteKey,
                attrs: {
                  value: _vm.newNote,
                  id: "0",
                  placeholder: _vm.$t("leadgen.add-a-new-note"),
                  "input-only-mode": true
                },
                on: {
                  update: function($event) {
                    return _vm.addNote($event)
                  }
                }
              })
            ],
            1
          ),
          _vm._l(_vm.notesFiltered, function(note) {
            return _c("div", { key: note.id, staticClass: "note py-3" }, [
              _c("div", { staticClass: "d-flex justify-space-between px-2" }, [
                note.id == "0"
                  ? _c("div", { staticClass: "body-14 grey--text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("note.create-new-note")) + " ")
                    ])
                  : _c("div", { staticClass: "body-14 grey--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$DateTime.humanDateTime(note.timestamp)) +
                          " "
                      ),
                      note.timestampUpdated
                        ? _c(
                            "span",
                            { staticClass: "ml-3 body-14 swirl--text" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("note.edited", {
                                      date: _vm.$DateTime.humanDateTime(
                                        note.timestampUpdated
                                      )
                                    })
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                _c(
                  "div",
                  { staticClass: "icons pa-0 ma-0" },
                  [
                    note.id !== "0"
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "delete mt-n2",
                            attrs: { "x-small": "", depressed: "", icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleDeleteNoteClick(note.id)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("$delete")])],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "brand-regular body-16 mt-1 px-2",
                  on: {
                    mouseover: function($event) {
                      _vm.fading = false
                    }
                  }
                },
                [
                  _c("markdown", {
                    attrs: {
                      value: note.entry,
                      id: note.id,
                      placeholder: _vm.$t("note.input-placeholder"),
                      "input-only-mode": note.id == "0" ? true : false
                    },
                    on: {
                      update: function($event) {
                        return _vm.updateNote($event, note.id)
                      },
                      focus: function($event) {
                        _vm.fading = false
                      },
                      blur: function($event) {
                        _vm.fading = true
                      }
                    }
                  })
                ],
                1
              ),
              _vm.fading && _vm.truncate && _vm.notes.length > _vm.maxNotes
                ? _c("div", { staticClass: "fading" })
                : _vm._e()
            ])
          })
        ],
        2
      ),
      _vm.notes.length > _vm.maxNotes
        ? _c("div", { staticClass: "px-2 pb-2 chevron-div" }, [
            _vm.truncate
              ? _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        return _vm.setTruncate(false)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("$chevronDown")])],
                  1
                )
              : _vm._e(),
            !_vm.truncate
              ? _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        return _vm.setTruncate(true)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("$chevronUp")])],
                  1
                )
              : _vm._e()
          ])
        : _c("div", { staticClass: "px-2 pb-2" }),
      _c(
        "modal",
        {
          ref: "deleteNoteModal",
          attrs: {
            dialog: _vm.deleteNoteDialog,
            persistent: false,
            "max-width": 400
          },
          on: {
            close: function($event) {
              _vm.deleteNoteDialog = false
            }
          }
        },
        [
          _c(
            "v-container",
            {
              staticClass: "pt-0 px-4 pb-5",
              attrs: { justify: "center", align: "center" }
            },
            [
              _c(
                "h1",
                [
                  _c("v-icon", { attrs: { color: "primary", size: "45" } }, [
                    _vm._v("$notes")
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("note.delete-note")) + " ")
                ],
                1
              ),
              _c("p", {
                staticClass: "my-6 text-black text-center",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("note.delete-are-you-sure"))
                }
              }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "px-12", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          ref: "cancelButton",
                          staticClass: "primary-action mr-2",
                          attrs: { large: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.closeDeleteDialog()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          ref: "deleteButton",
                          staticClass: "secondary-action ml-2",
                          attrs: { large: "", text: "" },
                          on: {
                            click: function($event) {
                              return _vm.deleteNote()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("global.delete")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }