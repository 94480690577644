var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "400px" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: { click: _vm.handleClose }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c("div", { staticClass: "modal-padding" }, [
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _vm.user.original.profilePicture
              ? _c("v-avatar", { attrs: { size: "55" } }, [
                  _c("img", {
                    staticClass: "pic",
                    attrs: { src: _vm.user.original.profilePicture }
                  })
                ])
              : _c("v-avatar", { attrs: { size: "55", color: "primary" } }, [
                  _c(
                    "span",
                    {
                      staticClass: "font-24",
                      staticStyle: { color: "var(--v-white-base)" }
                    },
                    [_vm._v(_vm._s(_vm.initials))]
                  )
                ]),
            _c("div", { staticClass: "font-20 brand-medium mt-4" }, [
              _vm._v(_vm._s(_vm.user.name))
            ]),
            _c("div", [_vm._v(_vm._s(_vm.user.email))])
          ],
          1
        ),
        _c("div", { staticClass: "mt-3 text-left" }, [
          _c("span", { staticClass: "brand-medium font-14" }, [
            _vm._v("Has access to")
          ]),
          _c(
            "div",
            {
              staticClass: "pa-3",
              staticStyle: {
                "max-height": "400px",
                "overflow-y": "auto",
                "background-color": "var(--v-gray_10-base)",
                border: "1px solid var(--v-gray_50-base)"
              }
            },
            _vm._l(_vm.projects, function(p) {
              return _c(
                "div",
                {
                  key: p.id,
                  staticClass: "row-format align-center text-left mb-2"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.projectList,
                        expression: "projectList"
                      }
                    ],
                    staticStyle: { transform: "scale(1.4)" },
                    attrs: { type: "checkbox", id: p.id },
                    domProps: {
                      value: p.id,
                      checked: Array.isArray(_vm.projectList)
                        ? _vm._i(_vm.projectList, p.id) > -1
                        : _vm.projectList
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.projectList,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = p.id,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.projectList = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.projectList = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.projectList = $$c
                        }
                      }
                    }
                  }),
                  _c("label", { attrs: { for: p.id } }, [
                    _c(
                      "div",
                      { staticClass: "row-format align-center pointer" },
                      [
                        _c("client-avatar", {
                          staticClass: "ml-3",
                          attrs: {
                            "disable-click": "",
                            small: "",
                            client: p.client
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "ml-3 font-14 ellipsis",
                            staticStyle: { width: "300px" }
                          },
                          [_vm._v(_vm._s(p.name))]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            }),
            0
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "secondary-action mr-1",
              on: {
                click: function($event) {
                  return _vm.$emit("result", false)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "super-action ml-1",
              attrs: { width: "200" },
              on: {
                click: function($event) {
                  return _vm.updateUser()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }