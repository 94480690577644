var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "nudge-top": 28,
            "max-width": "300",
            bottom: "",
            right: "",
            rounded: "",
            "offset-overflow": "",
            "offset-y": "",
            "close-on-content-click": false,
            "content-class": "filter-menu-class",
            transition: "slide-y-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticStyle: {
                          "min-height": "40px",
                          "background-color": "var(--v-white-base)"
                        },
                        attrs: {
                          elevation: "0",
                          outlined: "",
                          color: "gray_30"
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "span",
                        {
                          class:
                            "font-14 " +
                            (_vm.isFilterActive
                              ? "font-secondary"
                              : "font-gray_60") +
                            " brand-medium"
                        },
                        [_vm._v(_vm._s(_vm.filterLabel))]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            "x-small": "",
                            color: _vm.isFilterActive ? "secondary" : "gray_80"
                          }
                        },
                        [_vm._v("$arrowDown")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showMenu,
            callback: function($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu"
          }
        },
        [
          _c("div", { staticClass: "column-format" }, [
            _c(
              "div",
              {
                staticClass: "pa-3",
                staticStyle: {
                  "max-height": "calc(90vh - 90px)",
                  "overflow-y": "auto"
                }
              },
              _vm._l(_vm.clientList, function(client) {
                return _c("v-checkbox", {
                  key: client.value,
                  attrs: { dense: "", "hide-details": "", value: client.value },
                  on: { change: _vm.updated },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "font-14 brand-semilight",
                                staticStyle: { "font-size": "14px" }
                              },
                              [_vm._v(_vm._s(client.text))]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.filter.clientList,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "clientList", $$v)
                    },
                    expression: "filter.clientList"
                  }
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "mx-3 mb-3 mt-3" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-2",
                    staticStyle: { "font-weight": "500" },
                    attrs: {
                      text: "",
                      block: "",
                      color: "secondary",
                      elevation: "0"
                    },
                    on: {
                      click: function($event) {
                        return _vm.clearFilter()
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("$clear")]),
                    _vm._v(" Clear Filters")
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "super-action mt-1",
                    attrs: { color: "primary", block: "" },
                    on: {
                      click: function($event) {
                        _vm.showMenu = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("global.done")))]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }