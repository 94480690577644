var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Communicator" }
            ]
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.recordType === "SENT_LOG" ? "Email log" : "Scheduled emails"
              ) +
              " "
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center gap-2" },
        [
          _c("v-text-field", {
            staticStyle: {
              "max-width": "200px",
              "background-color": "var(--v-white-base)"
            },
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search...",
              color: "gray_30"
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "material-symbols-rounded font-gray_50" },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.filter.search,
              callback: function($$v) {
                _vm.$set(_vm.filter, "search", $$v)
              },
              expression: "filter.search"
            }
          }),
          _vm.recordType === "SENT_LOG"
            ? _c(
                "div",
                { staticClass: "row-format align-center gap-2 flex-wrap" },
                [
                  _c("scope-button", {
                    attrs: {
                      scope: "today",
                      "active-scope": _vm.filter.dateSpecifier,
                      label: "Today"
                    },
                    on: { "scope-update": _vm.updateScope }
                  }),
                  _c("scope-button", {
                    attrs: {
                      scope: "this-week",
                      "active-scope": _vm.filter.dateSpecifier,
                      label: "This week"
                    },
                    on: { "scope-update": _vm.updateScope }
                  }),
                  _c("scope-button", {
                    attrs: {
                      scope: "last-week",
                      "active-scope": _vm.filter.dateSpecifier,
                      label: "Last week"
                    },
                    on: { "scope-update": _vm.updateScope }
                  }),
                  _c("scope-button", {
                    attrs: {
                      scope: "month-to-date",
                      "active-scope": _vm.filter.dateSpecifier,
                      label: "This month"
                    },
                    on: { "scope-update": _vm.updateScope }
                  }),
                  _c("scope-button", {
                    attrs: {
                      scope: "last-month",
                      "active-scope": _vm.filter.dateSpecifier,
                      label: "Last month"
                    },
                    on: { "scope-update": _vm.updateScope }
                  }),
                  _c("scope-button", {
                    attrs: {
                      scope: "between",
                      "active-scope": _vm.filter.dateSpecifier,
                      label: "Custom dates"
                    },
                    on: { "scope-update": _vm.updateScope }
                  }),
                  _vm.filter.dateSpecifier === "between"
                    ? _c(
                        "div",
                        { staticClass: "row-format" },
                        [
                          _c("scope-date-selector", {
                            attrs: { date: _vm.filter.earliest },
                            on: {
                              change: function($event) {
                                return _vm.setEarliest($event)
                              }
                            }
                          }),
                          _c("div", { staticClass: "mx-1" }, [_vm._v("-")]),
                          _c("scope-date-selector", {
                            attrs: { date: _vm.filter.latest },
                            on: {
                              change: function($event) {
                                return _vm.setLatest($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("v-data-table", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              headers: _vm.headers,
              items: _vm.filteredEmails,
              "hide-default-footer": true,
              "sort-by": "sentAt",
              "sort-desc": true,
              "items-per-page": -1,
              "no-data-text":
                _vm.recordType === "SENT_LOG"
                  ? "No sent emails for this period"
                  : "No scheduled emails"
            },
            on: {
              "click:row": function($event) {
                return _vm.rowClicked($event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "item.to",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.to
                            .map(function(t) {
                              return t.address
                            })
                            .join(", ")
                        ) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.sentAt",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.DateTime.fromISO(item.sentAt).toLocaleString(
                            _vm.DateTime.DATETIME_MED
                          )
                        ) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.action",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-icon",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: { content: "Cancel scheduled send" },
                            expression: "{content:'Cancel scheduled send'}"
                          }
                        ],
                        staticClass: "material-symbols-rounded",
                        attrs: { size: "20" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.confirmDelete(item)
                          }
                        }
                      },
                      [_vm._v("cancel")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.dialogOpen
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.dialogOpen, "close-on-escape": true },
              on: {
                close: function($event) {
                  _vm.dialogOpen = false
                }
              }
            },
            [
              _c("email-log-detail", {
                attrs: { "email-log": _vm.logDetail },
                on: {
                  close: function($event) {
                    _vm.dialogOpen = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }