var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tooltip
    ? _c(
        "svg",
        {
          directives: [{ name: "tippy", rawName: "v-tippy" }],
          style: _vm.styles,
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            "aria-hidden": "true",
            content: _vm.tooltip
          },
          on: {
            mouseover: function($event) {
              _vm.hovering = true
            },
            mouseleave: function($event) {
              _vm.hovering = false
            }
          }
        },
        [_c("use", { attrs: { id: _vm.id, "xlink:href": _vm.href } })]
      )
    : _c(
        "svg",
        {
          style: _vm.styles,
          attrs: { xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true" },
          on: {
            mouseover: function($event) {
              _vm.hovering = true
            },
            mouseleave: function($event) {
              _vm.hovering = false
            }
          }
        },
        [_c("use", { attrs: { id: _vm.id, "xlink:href": _vm.href } })]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }