<template>
	<div id="proposal-template-header-wrapper">
		<div class="proposal-header">
			<div class="header-left pl-md-6 pl-4">
				<div class="mr-3 brand-medium d-none d-md-block">
					{{ $t('templates.proposals.edit-template') }}
				</div>
				<div class="name-div">
					<v-text-field
						v-model="proposalTemplate.name"
						class="template-name"
						dense
						hide-details
						persistent-placeholder
						:placeholder="$t('templates.proposals.name-placeholder')"
						@change="$emit('name-change', proposalTemplate.name)"
					/>
				</div>
			</div>
			<div class="header-right pr-md-6 pr-4 gap-1">
				<div v-if="$vuetify.breakpoint.mdAndUp" class="nowrap">
					<v-progress-circular
						class="mr-2"
						v-if="needSave || saving"
						indeterminate
						size="20"
						:color="saving ? 'green' : 'accent'"
					></v-progress-circular>
					<v-icon small class="mr-2" v-if="!needSave && !saving">check</v-icon>
					<span class="progress">{{ status }}</span>
				</div>
				<folder-selector v-model="proposalTemplate.folder" :folders="folders"></folder-selector>
				<v-btn class="primary-action" width="96" outlined color="primary" @click="save()">
					{{ $t('global.save') }}
				</v-btn>
				<v-menu :close-on-click="true" nudge-bottom="30">
					<template v-slot:activator="scope">
						<div>
							<v-btn icon class="secondary-action" v-if="scope.value" v-on="scope.on">
								<v-icon small style="margin-right: 2px;">$arrowUp</v-icon>
							</v-btn>
							<v-btn icon class="secondary-action" v-if="!scope.value" v-on="scope.on">
								<v-icon small>$moreHorizontal</v-icon>
							</v-btn>
						</div>
					</template>

					<div class="more-menu">
						<div class="more-menu-item" @click="$emit('duplicate')">
							{{ $t('templates.proposals.duplicate-proposal') }}
						</div>
						<div class="more-menu-item" @click="$emit('delete')">
							{{ $t('global.delete') }}
						</div>
					</div>
				</v-menu>
				<div>
					<v-btn icon class="primary-action" @click="handleClose()">
						<v-icon :size="20">$close</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import FolderSelector from "@/components/FolderSelector";
	export default {
		name: 'HeaderBar',

		props: ['proposalTemplate', 'needSave', 'saving', 'status', 'templateService','folders'],

		components: {FolderSelector},

		data: function() {
			return {};
		},

		mounted() {
			console.log(this.proposalTemplate);
		},

		beforeDestroy() {},

		methods: {
			handleClose: function() {
				this.$emit('close');
			},

			addContent: function() {
				this.$emit('add-content');
			},

			save: function() {
				this.$emit('save');
			},
		},

		watch: {
			'proposalTemplate.folder': function(){
				this.$emit('folder-change',this.proposalTemplate.folder);
			}
		},

		computed: {},
	};
</script>

<style lang="scss">
	#proposal-template-header-wrapper {
		// Needed for sticky positioning
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		z-index: 203;
		// end sticky

		.proposal-header {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);

			display: flex;
			justify-content: space-between;
			align-items: center;
			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					//border: 1px solid red;
					display: flex;
					align-items: center;
					justify-items: flex-start;
					.v-input.template-name {
						margin-top: 0;
						.v-text-field__slot {
							font-size: 16px;
							font-weight: 500;
							min-width: 320px;
						}
					}
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
				.locked-message {
					font-size: 12px;
					color: var(--v-gray_80-base);
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 8px;
				& > div {
					flex: 0 0 auto;
				}
			}
		}
	}

	.progress {
		font-size: 12px;
		color: var(--v-gray_50-base);
	}
</style>
