<template>
	<div class="column-format fill-height pointer" @click.stop="$emit('click')">
		<div class="pa-4">
			<div class="font-16 brand-medium font-gray_70">Payments received</div>
			<div><span class="font-24 brand-bold">{{$formatters.dollars(totalReceived,false,true,project.client.currency)}}</span> / {{$formatters.dollars(outstanding,false,true,project.client.currency)}} outstanding</div>
		</div>
		<div
				class="pa-4 mt-auto row-format align-center pointer"
				style="background-color: var(--v-gray_5-base); height: 44px; border-top: 1px solid var(--v-gray_30-base)">
			<div><span class="brand-bold">Net profit {{netProfit}}</span>  <span class="font-gray_80 font-12">({{totalExpenses}} in expenses)</span></div>
		</div>
	</div>
</template>

<script>
	import ProjectService from "@/modules/projects/ProjectService";
	import IncomeService from "@/modules/accounting/payments/IncomeService";
	import ExpenseService from "@/modules/accounting/expenses/ExpenseService";

	export default {
		name: 'ProjectPayments',

		props: ['project'],

		components: {},

		data: function () {
			return {
				projectService: new ProjectService(),
				incomeService: new IncomeService(),
				expenseService: new ExpenseService(),
				income: [],
				payments: [],
				expenses: [],
				currency: null,
			};
		},

		mounted() {
			console.log(this.project);
			this.getInvoicesForProject();
			this.getIncomeForProject();
			this.getExpensesForProject();
		},

		beforeDestroy() {},

		methods: {
			getInvoicesForProject: function(){
				this.projectService.getProjectPayments(this.project.id,this.project.client.currency).then((res) => {
					this.payments.splice(0,this.payments.length);
					this.payments.push(... res.data);
					this.payments.forEach(p => p.total = p.amount);
				})
			},
			getIncomeForProject: function(){
				this.incomeService.getIncomeByProject(this.project.id,this.project.client.currency).then((res) => {
					this.income.splice(0,this.income.length);
					this.income.push(... res.data);
				})
			},
			getExpensesForProject: function(){
				this.expenseService.getExpensesByProject(this.project.id,this.project.client.currency).then((res) => {
					this.expenses.splice(0,this.expenses.length);
					this.expenses.push(... res.data);
				})
			},
		},

		computed: {
			totalInvoiced: function(){
				return this.payments.reduce((sum, { localAmount }) => sum + localAmount, 0) + this.totalIncome;
			},
			totalReceived: function(){
				return this.payments.filter((p) => p.invoiceStatus === 'PAID').reduce((sum, { localAmount }) => sum + localAmount, 0) + this.totalIncome
			},
			totalIncome: function(){
				return this.income.reduce((sum, {localAmount}) => sum + localAmount, 0);
			},
			totalExpenses: function(){
				return this.$formatters.dollars(this.expenses.reduce((sum, {localAmount}) => sum + localAmount, 0), false, true, this.project.client.currency);
			},
			outstanding: function(){
				return this.totalInvoiced - this.totalReceived;
			},
			netProfit: function(){
				let payments = this.payments.filter((p) => p.invoiceStatus === 'PAID').reduce((sum, { localAmount }) => sum + localAmount, 0);
				let expenses = this.expenses.reduce((sum, {localAmount}) => sum + localAmount, 0);
				let income = this.income.reduce((sum, {localAmount}) => sum + localAmount, 0);
				return this.$formatters.dollars((income + payments) - expenses,false, true, this.project.client.currency);
			},
		},
	};
</script>

<style scoped lang="scss"></style>
