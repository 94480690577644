var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%", "background-color": "var(--v-white-base)" }
    },
    [
      _vm.isReady
        ? _c(
            "v-container",
            {
              staticClass: "mt-4",
              staticStyle: { "max-width": "985px!important" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "mb-4", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "brand-semilight",
                        staticStyle: { "font-size": "48px" }
                      },
                      [_vm._v(_vm._s(_vm.$t("subscription.heading")))]
                    ),
                    _c("div", { staticClass: "brand-semilight" }, [
                      _vm._v(
                        _vm._s(_vm.$t("subscription.subheading")) +
                          " (All amounts in USD)"
                      )
                    ])
                  ]),
                  _vm._l(_vm.plansInOrder, function(planCode, i) {
                    return _c(
                      "v-col",
                      {
                        key: i,
                        attrs: { align: "center", md: "4", cols: "12" }
                      },
                      [
                        _c("PlanCard", {
                          key: planCode,
                          ref: planCode,
                          refInFor: true,
                          attrs: {
                            "plan-code": planCode,
                            "card-plan": _vm.planHash[planCode],
                            "plan-hash": _vm.planHash,
                            account: _vm.account,
                            "customer-plan": _vm.customerPlan,
                            "pending-plan": _vm.pendingPlan,
                            "is-processing": _vm.isProcessing,
                            "can-manage-subscription":
                              _vm.canManageSubscription,
                            "subscription-managed-by": _vm.subscriptionManagedBy
                          },
                          on: {
                            "plan-click": function($event) {
                              return _vm.handlePlanClick($event)
                            },
                            "delete-pending": function($event) {
                              return _vm.deleteChange($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "my-6 px-10",
          staticStyle: { "background-color": "var(--v-white-base)" }
        },
        [
          _c(
            "v-container",
            { staticStyle: { "max-width": "985px!important" } },
            [
              _vm.billingInfo && _vm.canManageSubscription
                ? _c(
                    "v-row",
                    {
                      staticStyle: {
                        "background-color": "var(--v-gray_10-base)",
                        "border-radius": "12px"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left",
                          attrs: {
                            cols: "12",
                            md: "8",
                            order: "2",
                            "order-md": "1"
                          }
                        },
                        [
                          _c("Invoices", {
                            attrs: {
                              "pending-plan": _vm.pendingPlan,
                              "customer-plan": _vm.customerPlan
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left",
                          attrs: {
                            cols: "12",
                            md: "4",
                            order: "1",
                            "order-md": "2"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mb-5" },
                            [
                              _c("h4", { staticClass: "d-inline mr-4" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("subscription.payment-method"))
                                )
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "py-0 mt-n1",
                                  staticStyle: { "font-size": "12px" },
                                  attrs: { color: "primary", text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.planCode = null
                                      _vm.paymentDialog = true
                                    }
                                  }
                                },
                                [_vm._v("Change")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "mr-8",
                              staticStyle: {
                                clear: "all:height:42px",
                                width: "28px",
                                height: "42px",
                                float: "left"
                              }
                            },
                            [
                              _c("payment-icon", {
                                attrs: {
                                  size: "28",
                                  name: _vm.billingInfo.paymentMethod.cardType
                                }
                              })
                            ],
                            1
                          ),
                          _c("p", {
                            staticClass: "mb-3",
                            domProps: { innerHTML: _vm._s(_vm.paymentMethod) }
                          }),
                          _c("p", {
                            staticStyle: {
                              "font-size": "14px",
                              color: "var(--v-gray_70-base)"
                            },
                            domProps: {
                              innerHTML: _vm._s(_vm.paymentMethodExp)
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "div",
                        { staticClass: "brand-medium font-24 text-left mt-6" },
                        [_vm._v("Frequently Asked Questions")]
                      ),
                      _vm._l(_vm.faq, function(q, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "text-left brand-semilight my-4 pa-5",
                            staticStyle: {
                              "background-color": "var(--v-gray_10-base)",
                              "margin-left": "-12px",
                              "margin-right": "-12px",
                              "border-radius": "12px"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "font-20 row-format pointer",
                                on: {
                                  click: function($event) {
                                    _vm.isExpanded(index)
                                      ? _vm.collapseFaq(index)
                                      : _vm.expandFaq(index)
                                  }
                                }
                              },
                              [
                                _c("div", [_vm._v(_vm._s(q.question))]),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-auto",
                                    attrs: { color: "black" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isExpanded(index)
                                          ? "$minus"
                                          : "$plus"
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm.isExpanded(index)
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "mt-3",
                                    staticStyle: { "white-space": "pre-wrap" }
                                  },
                                  [_vm._v(_vm._s(q.answer))]
                                )
                              : _vm._e()
                          ]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("Modal", {
        ref: "paymentModal",
        attrs: {
          dialog: _vm.paymentDialog,
          fullscreen: _vm.$vuetify.breakpoint.xsOnly
        },
        on: {
          close: function($event) {
            _vm.paymentDialog = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("Payment", {
                  key: _vm.paymentDialog,
                  attrs: {
                    parent: {
                      planHash: _vm.planHash,
                      customerPlan: _vm.customerPlan,
                      billingInfo: _vm.billingInfo,
                      planCode: _vm.planCode,
                      account: _vm.account
                    }
                  },
                  on: {
                    alert: [
                      function($event) {
                        return _vm.$refs.paymentModal.setAlert($event)
                      },
                      function($event) {
                        return scope.setAlert($event)
                      }
                    ],
                    close: function($event) {
                      _vm.paymentDialog = false
                    },
                    "plan-change": function($event) {
                      return _vm.planChange($event)
                    },
                    "get-billing-info": function($event) {
                      return _vm.getBillingInfo($event)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("Modal", {
        ref: "planChangeModal",
        attrs: {
          dialog: _vm.planChangeDialog,
          fullscreen: _vm.$vuetify.breakpoint.xsOnly,
          "max-width": 550
        },
        on: {
          close: function($event) {
            _vm.planChangeDialog = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("PlanChange", {
                  key: _vm.planChangeDialog,
                  attrs: {
                    parent: {
                      planHash: _vm.planHash,
                      customerPlan: _vm.customerPlan,
                      planCode: _vm.planCode
                    }
                  },
                  on: {
                    alert: [
                      function($event) {
                        return _vm.$refs.paymentModal.setAlert($event)
                      },
                      function($event) {
                        return scope.setAlert($event)
                      }
                    ],
                    close: function($event) {
                      _vm.planChangeDialog = false
                    },
                    "plan-change": function($event) {
                      return _vm.planChange($event)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("Modal", {
        ref: "planCancelModal",
        attrs: {
          dialog: _vm.planCancelDialog,
          "max-width": 588,
          fullscreen: _vm.$vuetify.breakpoint.xsOnly
        },
        on: {
          close: function($event) {
            _vm.planCancelDialog = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c("PlanCancel", {
                  key: _vm.planCancelDialog,
                  attrs: {
                    planHash: _vm.planHash,
                    customerPlan: _vm.customerPlan,
                    planCode: _vm.planCode
                  },
                  on: {
                    alert: [
                      function($event) {
                        return _vm.$refs.planCancelModal.setAlert($event)
                      },
                      function($event) {
                        return scope.setAlert($event)
                      }
                    ],
                    close: function($event) {
                      _vm.planCancelDialog = false
                    },
                    "plan-change": function($event) {
                      return _vm.planChange($event)
                    }
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }