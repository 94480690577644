var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "text-left brand-box", attrs: { fluid: "" } },
        _vm._l(_vm.opportunities, function(opportunity, index) {
          return _c(
            "v-row",
            {
              key: opportunity.id,
              staticClass: "px-3 py-6 pointer opportunity-row",
              style:
                index < _vm.opportunities.length - 1
                  ? "border-bottom: 1px solid var(--v-gray_30-base);"
                  : "",
              on: {
                click: function($event) {
                  return _vm.$emit("edit", opportunity.id)
                }
              }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "row-format align-center",
                  attrs: { cols: "5" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format fill-width align-center" },
                    [
                      opportunity.client
                        ? _c("client-avatar", {
                            staticClass: "mt-1 mr-2",
                            attrs: { client: opportunity.client, large: "" }
                          })
                        : _vm._e(),
                      _c("div", [
                        _c("div", { staticClass: "font-18 brand-medium" }, [
                          _vm._v(
                            _vm._s(opportunity.name ? opportunity.name : "--")
                          )
                        ]),
                        opportunity.client
                          ? _c("div", { staticClass: "font-14 font-gray_70" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    opportunity.client.clientType === "Prospect"
                                      ? "Prospect"
                                      : "Client"
                                  ) +
                                  " • " +
                                  _vm._s(opportunity.client.name) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        opportunity.archive
                          ? _c(
                              "div",
                              { staticClass: "font-gray_70 font-14" },
                              [
                                _vm._v("Archived "),
                                _c(
                                  "v-icon",
                                  { attrs: { color: "secondary", size: "20" } },
                                  [_vm._v("archive")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        opportunity.formData
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "font-gray_70 font-14 row-format gap-1"
                              },
                              [
                                opportunity.formData.firstName ||
                                opportunity.formData.lastName
                                  ? _c("div", [
                                      _vm._v(
                                        _vm._s(opportunity.formData.firstName) +
                                          " " +
                                          _vm._s(opportunity.formData.lastName)
                                      )
                                    ])
                                  : _vm._e(),
                                (opportunity.formData.firstName ||
                                  opportunity.formData.lastName) &&
                                opportunity.formData.email
                                  ? _c("div", [_vm._v("•")])
                                  : _vm._e(),
                                opportunity.formData.email
                                  ? _c("div", [
                                      _vm._v(_vm._s(opportunity.formData.email))
                                    ])
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-col",
                { staticClass: "column-format gap-3", attrs: { cols: "2" } },
                [
                  _c("div", { staticClass: "column-format" }, [
                    _c("div", { staticClass: "font-14 font-gray_70" }, [
                      _vm._v("Stage")
                    ]),
                    _c("div", { staticClass: "row-format align-center" }, [
                      opportunity.stage
                        ? _c("div", {
                            staticClass: "stage",
                            style:
                              "--stage-hex-color: " + opportunity.stage.hexColor
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "ml-1 font-14 brand-medium" }, [
                        _vm._v(_vm._s(opportunity.stage.label))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "column-format" }, [
                    _c("div", { staticClass: "font-14 font-gray_70 mb-n1" }, [
                      _vm._v("Confidence")
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "material-symbols-rounded",
                            attrs: { color: opportunity.sentimentObj.color }
                          },
                          [_vm._v(_vm._s(opportunity.sentimentObj.icon))]
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "v-col",
                { staticClass: "column-format gap-3", attrs: { cols: "2" } },
                [
                  _c("div", { staticClass: "column-format" }, [
                    _c("div", { staticClass: "font-14 font-gray_70" }, [
                      _vm._v("Est. value")
                    ]),
                    opportunity.value
                      ? _c("div", { staticClass: "text-option" }, [
                          _vm._v(
                            _vm._s(_vm.$formatters.dollars(opportunity.value)) +
                              " / " +
                              _vm._s(opportunity.timePeriodDetail.label)
                          )
                        ])
                      : _c("div", { staticClass: "text-option" }, [
                          _vm._v("--")
                        ])
                  ]),
                  opportunity.closed
                    ? _c("div", { staticClass: "column-format" }, [
                        _c("div", { staticClass: "font-14 font-gray_70" }, [
                          _vm._v("Date closed")
                        ]),
                        opportunity.actualCloseDate
                          ? _c("div", { staticClass: "text-option" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.DateTime.fromISO(
                                    opportunity.actualCloseDate
                                  ).toLocaleString(_vm.DateTime.DATE_MED)
                                )
                              )
                            ])
                          : _c("div", { staticClass: "text-option" }, [
                              _vm._v("--")
                            ])
                      ])
                    : _c("div", { staticClass: "column-format" }, [
                        _c("div", { staticClass: "font-14 font-gray_70" }, [
                          _vm._v("Est. close")
                        ]),
                        opportunity.estCloseDate
                          ? _c("div", { staticClass: "text-option" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.DateTime.fromISO(
                                    opportunity.estCloseDate
                                  ).toLocaleString(_vm.DateTime.DATE_MED)
                                )
                              )
                            ])
                          : _c("div", { staticClass: "text-option" }, [
                              _vm._v("--")
                            ])
                      ])
                ]
              ),
              _c(
                "v-col",
                { staticClass: "column-format gap-3", attrs: { cols: "2" } },
                [
                  _c("div", { staticClass: "column-format" }, [
                    _c("div", { staticClass: "font-14 font-gray_70" }, [
                      _vm._v("Last activity")
                    ]),
                    opportunity.lastActivity
                      ? _c("div", { staticClass: "text-option" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatForTimeAgo")(
                                opportunity.lastActivity.timestamp
                              )
                            )
                          )
                        ])
                      : _c("div", { staticClass: "text-option" }, [
                          _vm._v("--")
                        ])
                  ]),
                  _c("div", { staticClass: "column-format" }, [
                    _c("div", { staticClass: "font-14 font-gray_70" }, [
                      _vm._v("Next due")
                    ]),
                    opportunity.nextDue
                      ? _c("div", { staticClass: "text-option" }, [
                          _vm._v(
                            _vm._s(
                              _vm.DateTime.fromISO(
                                opportunity.nextDue.dueDate
                              ).toLocaleString(_vm.DateTime.DATE_MED)
                            )
                          )
                        ])
                      : _c("div", { staticClass: "text-option" }, [
                          _vm._v("--")
                        ])
                  ])
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "row-format align-center",
                  attrs: { cols: "1" }
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "nudge-bottom": 30 },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(scope) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-auto menu-activator",
                                      attrs: { icon: "" }
                                    },
                                    scope.on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          scope.value
                                            ? "$arrowUp"
                                            : "$moreHorizontal"
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("div", { staticClass: "more-menu" }, [
                        _c(
                          "div",
                          {
                            staticClass: "more-menu-item",
                            on: {
                              click: function($event) {
                                return _vm.$emit("edit", opportunity.id)
                              }
                            }
                          },
                          [_vm._v("Edit")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "more-menu-item",
                            on: {
                              click: function($event) {
                                return _vm.$emit("archive", opportunity.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                opportunity.archive ? "Unarchive" : "Archive"
                              )
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "more-menu-item",
                            on: {
                              click: function($event) {
                                return _vm.$emit("delete", opportunity.id)
                              }
                            }
                          },
                          [_vm._v("Delete")]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }