<template>
	<div id="payment-plan-details" v-if="isReady">
		<div id="header-area" class="">
			<div class="header-left pl-md-3 pl-3 gap-3 align-center row-format">
				<v-text-field style="width:300px" dense outlined hide-details v-model="paymentPlan.name" :placeholder="`Payment plan for ${invoice.clientInfo.name}`"></v-text-field>
				<div class="plan-status" :style="statusColors">{{paymentPlan.status}}</div>
				<payment-plan-events :payment-plan="paymentPlan"></payment-plan-events>
			</div>
			<div class="header-right pr-md-6 pr-4" id="header-right">
				<v-btn class="mr-1 super-action" width="96" @click="savePaymentPlan(false)" v-if="paymentPlan.status === 'Draft'">
					{{ $t('global.save') }}
				</v-btn>
				<v-btn class="mr-1 super-action"  @click="runNextMilestone" v-else-if="nextUp">
					<span v-if="nextUp.triggerType === 'Milestone'">Milestone: {{nextUp.label}}</span>
					<span v-else-if="nextUp.triggerType === 'Date'">{{DateTime.fromISO(nextUp.triggerDate).toLocaleString(DateTime.DATE_MED)}}: {{nextUp.label}}</span>
				</v-btn>
				<v-menu :close-on-click="true" :nudge-bottom="30">
					<template v-slot:activator="scope">
						<div class="ml-1">
							<v-btn icon class="menu-activator" v-on="scope.on">
								<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click="activate()" v-if="paymentPlan.status === 'Draft'">
							Activate
						</div>
						<div class="more-menu-item" @click="returnToDraft()" v-if="paymentPlan.status === 'Active'">
							Return to draft
						</div>
						<div class="more-menu-item" @click="confirmDelete()">
							{{ $t('global.delete') }}
						</div>
					</div>
				</v-menu>
				<v-btn icon  @click="handleClose">
					<v-icon :size="20">$close</v-icon>
				</v-btn>
			</div>
		</div>

		<invoice-renderer-v2
			style="max-height: calc(100vh - 60px); height: calc(100vh - 60px)"
			:invoice="invoice"
			:recurring="false"
			:payment-plan="true"
			:account="$store.state.podAccount"
			:edit-mode="paymentPlan.status === 'Draft'"
			:force-render-index="forceRenderIndex"
			:attachment-service="{}"
			:invoice-service="invoiceService"
			:has-expenses="false"
			:key="rendererKey"
			:additional-tax-rates="additionalTaxRates"
			:scheduled-payments="paymentPlan.scheduledPayments"
			@force-save="render()"
			@updated="updated($event)"
			@pause-auto-save="pauseAutoRender($event)"
			@tax-rate-selected="taxRateSelected($event)"
		>
			<payment-plan-settings :payment-plan="paymentPlan" :invoice="invoice"></payment-plan-settings>
		</invoice-renderer-v2>
	</div>
</template>

<script>
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import jsonPatch from 'fast-json-patch';
	import DateTime from '@/modules/utils/HDateTime';
	import ProjectService from '@/modules/projects/ProjectService';
	import ConfirmModal from '@/components/ConfirmModal';
	import QuickbooksService from '@/modules/integrations/quickbooks/QuickbooksService';
	import XeroService from "@/modules/integrations/xero/XeroService";
	import InvoiceRendererV2 from '@/modules/invoices/invoiceRendererV2/InvoiceRendererV2';
	import ClientService from '@/modules/clients/ClientService';
	import PaymentPlanSettings from '@/modules/invoices/paymentPlan/PaymentPlanSettings';
	import { v4 as uuid4 } from 'uuid';
	import PaymentPlanService from '@/modules/invoices/paymentPlan/PaymentPlanService';
	import PaymentPlanEvents from "@/modules/invoices/paymentPlan/PaymentPlanEvents";

	export default {
		name: 'PaymentPlanDetails',

		components: {
			PaymentPlanEvents,
			PaymentPlanSettings,
			InvoiceRendererV2,
		},

		mixins: [],

		props: {
			clientId: { type: String, required: false },
			id: { type: String, required: false },
		},

		data: function() {
			return {
				clientService: new ClientService(),
				invoiceService: new InvoiceService(),
				projectService: new ProjectService(),
				paymentPlanService: new PaymentPlanService(),
				quickbooksService: new QuickbooksService(),
				xeroService: new XeroService(),
				attachmentService: null,
				rendererKey: 0,
				forceRenderIndex: 0,
				invoice: null,
				observer: null,
				isReady: false,

				paymentPlan: {
					id: null,
					clientId: this.clientId,
					autoSend: false,
					autoCharge: false,
					paymentMethod: null,
					scheduledPayments: [
						{
							id: uuid4(),
							label: 'Final payment',
							triggerType: 'Milestone',
							triggerDate: null,
							amountType: 'Percentage',
							amount: 0,
							mileStoneAchieved: false,
							finalPayment: true,
							calculatedAmount: 0,
							calculatedPercentage: 0,
						},
					],
				},

				DateTime: DateTime,

				quickbooksTaxAccounts: [],
				xeroTaxAccounts: [],

				// Auto-save
				rendering: false,
				rendered: false,
				renderTimer: setInterval(this.autoRender, 5 * 1000),
				autoSavePaused: false,

				paymentTermsDefault: {
					paymentDays: 7,
					latePaymentFee: 5,
					depositAmount: 0,
					depositType: 'No deposit',
					invoiceCreation: 'Manual',
					invoicingSchedule: 'Monthly',
				},
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'payment-plan-detail' });
			this.initialize();
			this.getQuickbooksTaxAccounts();
			this.getXeroTaxAccounts();
		},

		beforeDestroy() {
			clearInterval(this.renderTimer);
		},

		methods: {
			runNextMilestone: function(){
				if(this.nextUp) {
					let confirm = {
						headingText: 'Complete milestone?',
						bodyText:  `Do you want to mark the "${this.nextUp.label}" milestone as complete?  This will create an invoice for ${this.$formatters.dollars(this.nextUp.calculatedAmount,true,true,this.invoice.currency)}`
					}

					this.$store.state.globalModalController.openModal(ConfirmModal,confirm).then((res) => {
						if(res) {
							this.$store.commit('startLoading');
							this.paymentPlanService.runMilestone(this.paymentPlan.id, this.nextUp.id).then((res) => {
								this.paymentPlan = res.data;
								this.setInvoice(res.data.invoice);
								this.$store.commit('success', 'Milestone marked complete');
							}).finally(() => this.$store.commit('stopLoading'));
						}
					});
				}
			},

			initialize: function() {
				if (this.id) {
					this.paymentPlanService.getPaymentPlan(this.id).then((res) => {
						this.paymentPlan = res.data;
						this.setInvoice(res.data.invoice);
						this.isReady = true;
					});
				} else {
					this.invoiceService.createInvoice(this.clientId, null, false).then((res) => {
						if (!res.data.invoiceTemplate) {
							this.$store.commit('error', 'You must enable invoice templates to utilize this feature');
							this.$emit('result');
						} else {
							this.setInvoice(res.data);
							this.paymentPlan.invoice = res.data;
							this.createPaymentPlan();
						}
					});
				}
			},

			createPaymentPlan: function() {
				this.paymentPlanService.createPaymentPlan(this.paymentPlan).then((res) => {
					this.paymentPlan = res.data;
					this.setInvoice(res.data.invoice);
					this.isReady = true;
				}).catch((err) => this.$store.commit('error',err.response.data.message));
			},

			handleClose: function(){
				if(this.paymentPlan.status === 'Draft'){
					this.savePaymentPlan(true);
				}else{
					this.$emit('result',this.paymentPlan);
				}
			},

			savePaymentPlan: function(close = false) {
				return new Promise((resolve, reject) => {
					this.$store.commit('startLoading');
					this.paymentPlan.invoice = this.invoice;

					this.paymentPlanService
						.updatePaymentPlan(this.paymentPlan.id, this.paymentPlan)
						.then((res) => {
							this.paymentPlan = res.data;
							this.setInvoice(res.data.invoice);
							this.isReady = true;
							if (close) {
								this.$emit('result', this.paymentPlan);
							} else {
								this.$store.commit('success', 'Saved');
							}
							resolve(this.paymentPlan);
						})
						.catch((err) => {
							this.$store.commit('error',err.response.data.message);
							reject(err);
						})
						.finally(() => this.$store.commit('stopLoading'));
				});
			},

			activate: function(){
				this.savePaymentPlan(false).then(() => {
					this.$store.commit('startLoading');
					this.paymentPlanService.activate(this.paymentPlan.id).then((res) => {
						this.paymentPlan = res.data;
						this.setInvoice(res.data.invoice);
					}).finally(() => this.$store.commit('stopLoading'));
				})
			},

			returnToDraft: function(){
				this.paymentPlanService.returnToDraft(this.paymentPlan.id).then((res) => {
					this.paymentPlan = res.data;
					this.setInvoice(res.data.invoice);
				}).finally(() => this.$store.commit('stopLoading'));
			},

			setInvoice(invoice) {
				this.invoice = invoice;
				this.invoice.status = 'DRAFT';
				this.observer = jsonPatch.observe(this.invoice);
			},

			getQuickbooksTaxAccounts: function(){
				this.quickbooksService.getConnection().then((res) => {
					this.quickbooksTaxAccounts.splice(0,this.quickbooksTaxAccounts.length);
					if(res.data && res.data.companyInfo) {
						this.quickbooksTaxAccounts.push(...res.data.companyInfo.allTaxAccounts);
					}
				})
			},

			getXeroTaxAccounts: function(){
				this.xeroService.getConnection().then((res) => {
					this.xeroTaxAccounts.splice(0,this.xeroTaxAccounts.length);
					if(res.data && res.data.connection) {
						this.xeroTaxAccounts.push(...res.data.connection.allTaxAccounts);
					}
				})
			},

			updated(invoice) {
				this.invoice = invoice;
			},

			pauseAutoRender(event) {
				this.autoRenderPaused = event;
			},

			render() {
				return this.renderInvoice();
			},

			autoRender() {
				if (this.autoRenderPaused) {
					return;
				}
				let focusField = document.activeElement;
				if (focusField && this.parentHasClass(focusField, 'pause-save')) {
					return;
				}
				return this.renderInvoice();
			},

			parentHasClass(element, classname) {
				if (typeof element.className == 'string' && element.className.split(' ').indexOf(classname) >= 0) return true;
				return element.parentNode && this.parentHasClass(element.parentNode, classname);
			},

			async renderInvoice(force = false) {
				if (this.saving) {
					return;
				}

				if (this.invoice.discountPercentage === '' || this.invoice.discountPercentage === null) {
					this.invoice.discountPercentage = 0;
				}

				if (this.invoice.taxPercentage === '' || this.invoice.taxPercentage === null) {
					this.invoice.taxPercentage = 0;
				}

				if (!this.invoice.paymentTerms) {
					this.invoice.paymentTerms = this.paymentTermsDefault;
				}

				let patch = jsonPatch.generate(this.observer, false);
				let finalPatch = [];

				for (let i = 0; i < patch.length; i++) {
					if (!patch[i].path.startsWith('/attachments')) {
						finalPatch.push(patch[i]);
					}
				}

				if (finalPatch.length > 0 || force) {
					this.rendering = true;
					this.invoiceService.render(this.invoice).then((res) => {
						this.invoice = res.data;
						this.rendering = false;
						this.rendered = true;
						this.observer = jsonPatch.observe(this.invoice);
					});
				}
			},

			taxRateSelected(taxRate) {
				if (taxRate.type === 'Quickbooks') {
					this.invoice.integrationTaxKeys.quickbooksId = taxRate.id;
					this.renderInvoice();
				}
			},

			confirmDelete: function() {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to delete this payment plan?',
				};
				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.paymentPlanService.deletePaymentPlan(this.paymentPlan.id).then(() => {
							this.paymentPlan.deleted = true;
							this.$emit('result', this.paymentPlan);
						});
					}
				});
			},
		},

		computed: {
			statusColors: function(){
				if(this.paymentPlan.status === 'Error'){
					return `color: var(--v-black-base); background-color: var(--v-warning-base)`;
				}else if(this.paymentPlan.status === 'Active'){
					return `color: var(--v-white-base); background-color: var(--v-primary-base)`;
				}else if(this.paymentPlan.status === 'Finished'){
					return `color: var(--v-white-base); background-color: var(--v-success-base)`;
				}else{
					return `color: var(--v-black-base); background-color: var(--v-gray_30-base)`;
				}
			},

			nextUp: function(){
				if(this.paymentPlan.status !== 'Active'){
					return;
				}
				for(let i=0; i < this.paymentPlan.scheduledPayments.length; i++){
					let scheduledPayment = this.paymentPlan.scheduledPayments[i];
					if(scheduledPayment.mileStoneAchieved === false){
						return scheduledPayment;
					}
				}
				return null;
			},

			additionalTaxRates: function() {
				let result = [];
				if (this.quickbooksTaxAccounts.length) {
					this.quickbooksTaxAccounts.forEach((t) => {
						result.push({
							id: t.id,
							name: t.name,
							type: 'Quickbooks',
							rate: t.rateValue,
						});
					});
				}
				if(this.xeroTaxAccounts.length){
					this.xeroTaxAccounts.forEach(t => {
						result.push({
							id: t.taxType,
							name: t.name,
							type: 'Xero',
							rate: t.effectiveRate
						})
					});
				}
				return result;
			},
		},

		watch: {},
	};
</script>

<style lang="scss">
	.plan-status {
		font-weight: 600;
		font-size: 14px;
		padding: 4px;
		border-radius: 4px;
	}
</style>

<style lang="scss">
	#payment-plan-details {
		background-color: var(--v-gray_20-base);
		#header-area {
			height: 56px;
			width: 100%;
			background-color: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_50-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
