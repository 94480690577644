<template>
	<div id="proposal-builder">
		<header-bar
			v-if="isReady"
			:proposal="proposal"
			:need-save="needSave"
			:status="status"
			:saving="saving"
			@close="close()"
			@save="save()"
			@send="shareSendProposal()"
			@return-to-draft="returnToDraft()"
			@void="voidProposal()"
			@delete="confirmDeleteProposal()"
			@create-project="newProjectDialog = true"
			@create-template="createTemplate()"
			:client-mode="false"
			:proposal-service="proposalService"
		></header-bar>

		<div class="subheader-buttons" v-if="showSubheaderButtons">
			<v-btn @click="coverMenuOpen = true" class="primary-action mr-1" :disabled="!protectedEditMode">
				<v-icon size="20" class="mr-2">$photo</v-icon>
				{{ $t('proposal.header-bar.cover-button') }}
			</v-btn>
			<v-btn @click="textMenuOpen = true" class="primary-action ml-1" :disabled="!protectedEditMode">
				<v-icon size="20" class="mr-2">$atSymbol</v-icon>
				{{ $t('proposal.header-bar.font-button') }}
			</v-btn>
		</div>
		<div v-else class="my-4"></div>
		<proposal-renderer
			v-if="isReady"
			:proposal="proposal"
			:client="client"
			:edit-mode="protectedEditMode"
			:template-mode="false"
			:proposal-service="proposalService"
			:client-service="clientService"
			:account="account"
			:need-save="needSave"
			:saving="saving"
			:client-mode="false"
			:is-locked="isLocked"
			:refresh-key="refreshKey"
			@close="close()"
			@need-save="handleNeedSave()"
			@force-save="save()"
			@send-proposal="shareSendProposal()"
			@return-to-draft="returnToDraft()"
			@delete-proposal="confirmDeleteProposal()"
			@create-project="newProjectDialog = true"
			@cover-menu-open="coverMenuOpen = true"
			@create-package-from-deliverables="createPackage($event)"
		></proposal-renderer>

		<confirm-dialog
			:dialog="confirmDeleteDialog"
			:heading-text="$t('global.confirm')"
			:body-text="isFullyExecuted ? $t('proposal.delete.confirm-fully-executed') : $t('proposal.delete.confirm')"
			@confirm="deleteProposal()"
			@close="cancelDeleteProposal()"
		></confirm-dialog>

		<basic-modal v-if="newProjectDialog" :dialog="newProjectDialog" :persistent="true" @close="newProjectDialog = false">
			<new-project
				:open-state="newProjectDialog"
				:client="client"
				:proposal-id="proposal.id"
				@created="newProjectCreated($event)"
				@close="newProjectDialog = false"
			></new-project>
		</basic-modal>

		<basic-modal
			v-if="isReady && shareDialog"
			:dialog="shareDialog"
			:persistent="true"
			:retain-focus="false"
			@close="shareDialog = false"
			v-slot="scope"
		>
			<share-send-widget
				:proposal="proposal"
				:proposal-status="proposal.proposalStatus"
				:proposal-service="proposalService"
				:client-service="clientService"
				:mce-config-text="mceConfigText"
				@finalize="activate()"
				@alert="scope.setAlert($event)"
				@cancel="shareDialog = false"
			></share-send-widget>
		</basic-modal>

		<basic-modal :dialog="coverMenuOpen" :persistent="true" @close="coverMenuOpen = false">
			<cover-modal
				v-if="!textMenuOpen"
				:proposal-service="proposalService"
				:proposal="proposal"
				:header-style="headerStyle"
				:body-style="bodyStyle"
				:client-name="clientName"
				:contractor-name="contractorName"
				:open-state="coverMenuOpen"
				@cancel="coverMenuOpen = false"
				@save="handleUpdateFromCoverMenu"
				@update-account="setAccount($event)"
			></cover-modal>
		</basic-modal>

		<basic-modal :dialog="textMenuOpen" :persistent="true" @close="textMenuOpen = false">
			<format-modal
				v-if="!coverMenuOpen && isReady"
				v-bind="proposal.formatting"
				:proposal-service="proposalService"
				:open-state="textMenuOpen"
				@close="textMenuOpen = false"
				@save="handleFormatChange($event)"
			></format-modal>
		</basic-modal>
	</div>
</template>

<script>
	import ProposalService from './ProposalService';
	// import TemplateService from '@/modules/templates/TemplateService';
	import ClientService from '../clients/ClientService';
	import AccountService from '@/modules/account/AccountService';
	import BasicModal from '@/components/BasicModal';
	import ShareSendWidget from './ShareSendWidget';
	import NewProject from '@/modules/projects/NewProject';
	import ProposalRenderer from '@/modules/proposals/proposal-renderer/ProposalRenderer';
	import HeaderBar from '@/modules/proposals/HeaderBar';
	import FormatModal from '@/modules/proposals/FormatModal';
	import CoverModal from '@/modules/proposals/CoverModal';
	import ProposalMixin from '@/modules/proposals/proposal-renderer/ProposalMixin';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import CreatePackageFromProposalDeliverablesModal from '@/modules/templates/deliverables/CreatePackageFromProposalDeliverablesModal';
	import CreateTemplateFromProposal from '@/modules/templates/proposals/CreateTemplateFromProposal';

	export default {
		name: 'ProposalBuilder',

		components: {
			ProposalRenderer,
			BasicModal,
			ShareSendWidget,
			NewProject,
			HeaderBar,
			FormatModal,
			CoverModal,
			ConfirmDialog,
		},

		props: ['clientId', 'proposalId', 'navOrigin'],

		mixins: [ProposalMixin],

		data() {
			return {
				proposal: null,
				account: null,
				isReady: false,
				clientService: new ClientService(),
				proposalService: new ProposalService(),
				accountService: new AccountService(),
				editMode: true,
				needSave: false,
				saving: false,
				confirmDeleteDialog: false,
				saveTimer: setInterval(this.save, 15 * 1000),
				refreshKey: 1,
				shareDialog: false,
				shareMode: null,
				newProjectDialog: false,
				coverMenuOpen: false,
				textMenuOpen: false,
				proposalDeleted: false,

				mceConfigText: {
					menubar: false,
					inline: true,
					paste_as_text: true,
					browser_spellcheck: true,
					forced_root_block: '',
					plugins: ['paste', 'lists', 'link'],
					toolbar: ['undo redo | bold italic underline', 'numlist bullist | outdent indent | link unlink'],
				},

				client: {
					id: '',
					name: '',
					contacts: [],
				},
			};
		},

		mounted() {
			this.$track.record('page-view', { module: 'proposal-detail' });
			this.$store.state.eventBus.$on('account-changed', this.handleAccountChange);
			this.initialize();
			this.getAccount();
		},

		beforeDestroy() {
			clearInterval(this.saveTimer);
			this.$store.state.eventBus.$off('account-changed', this.handleAccountChange);
			if (!this.proposalDeleted) this.proposalService.setLastViewed(this.clientId, this.proposalId);
		},

		methods: {
			getAccount: function() {
				this.accountService
					.getAccount()
					.then((res) => {
						this.account = res.data;
					})
					.catch((err) => {
						console.log(err);
					});
			},
			setAccount: function(account) {
				this.account = account;
			},
			handleAccountChange: function() {
				this.$emit('result', null);
			},

			initialize: function() {
				this.proposalService
					.getProposal(this.clientId, this.proposalId)
					.then((res) => {
						this.proposal = res.data;
						this.proposalService.setLastViewed(this.clientId, this.proposalId);
						this.clientService
							.getClientDetail(this.clientId)
							.then((res) => {
								this.client = res.data;
							})
							.catch((err) => {
								this.$store.commit('error', `${this.$t('proposal.error.load-client')}: ${err.response.message}`);
							}).finally(() => this.isReady = true);
					})
					.catch((err) => {
						console.log(err);
						this.$store.commit('error', `${this.$t('proposal.error.load-proposal')}: ${err.response.message}`);
						this.$emit('error', err);
					});
			},

			save() {
				if (this.needSave) {
					this.performSave();
				}
			},

			performSave() {
				this.saving = true;
				this.proposalService
					.updateProposal(this.clientId, this.proposalId, this.proposal)
					.then((res) => {
						this.copySignatureBlock(res.data);
						this.needSave = false;
						this.saving = false;
					})
					.catch((err) => {
						this.saving = false;
						this.$store.commit('error', this.$t('proposal.error.save') + err.response.data.message);
					});
			},

			handleNeedSave() {
				if (this.proposal.proposalStatus === 'Draft') {
					this.needSave = true;
				} else {
					this.performSave();
				}
			},

			copySignatureBlock(updatedProposal) {
				let signatureBlock = null;
				let index = null;

				for (let i = 0; i < updatedProposal.contentBlocks.length; i++) {
					if (updatedProposal.contentBlocks[i].blockType === 'SignatureBlock') {
						signatureBlock = updatedProposal.contentBlocks[i];
						index = i;
						break;
					}
				}

				if (signatureBlock !== null) {
					this.proposal.contentBlocks[index] = signatureBlock;
					this.refreshKey++;
				}
			},

			shareSendProposal() {
				this.shareDialog = true;
			},

			activate() {
				if (this.proposal.proposalStatus === 'Draft') {
					this.$track.record('proposal-sent');

					this.proposalService
						.activateProposal(this.clientId, this.proposalId)
						.then((res) => {
							this.proposal = res.data;
						})
						.catch((err) => {
							this.$store.commit('error', err.result.data.message);
						});
				} else {
					this.$store.commit('error', this.$t('proposal.error.not-draft'));
				}
			},

			returnToDraft() {
				if (this.proposal.proposalStatus === 'Sent' || this.proposal.proposalStatus === 'Declined' || this.proposal.proposalStatus === 'Void') {
					this.proposalService
						.draftProposal(this.clientId, this.proposalId)
						.then((res) => {
							this.proposal = res.data;
						})
						.catch((err) => {
							this.$store.commit('error', err.result.data.message);
						});
				} else {
					this.$store.commit('error', this.$t('proposal.error.not-sent'));
				}
			},

			voidProposal() {
				if (this.proposal.proposalStatus === 'Sent' || this.proposal.proposalStatus === 'Declined') {
					this.proposalService
							.voidProposal(this.clientId, this.proposalId)
							.then((res) => {
								this.proposal = res.data;
							})
							.catch((err) => {
								this.$store.commit('error', err.result.data.message);
							});
				} else {
					this.$store.commit('error', this.$t('proposal.error.not-sent'));
				}
			},

			confirmDeleteProposal() {
				this.confirmDeleteDialog = true;
			},

			cancelDeleteProposal() {
				this.confirmDeleteDialog = false;
			},

			deleteProposal() {
				this.$store.commit('startLoading');
				this.proposalService
					.deleteProposal(this.clientId, this.proposalId)
					.then(() => {
						this.$store.commit('success','Proposal has been deleted.');
						this.proposalDeleted = true;
						this.$emit('result', { action: 'DELETED', id: this.proposalId });
					})
					.catch((err) => {
						this.confirmDeleteDialog = false;
						this.$store.commit('error', err.response.data.message);
					}).finally(() => this.$store.commit('stopLoading'));
			},

			close() {
				this.$emit('result', this.proposal);
			},

			newProjectCreated() {
				this.newProjectDialog = false;
				this.$store.commit('info','Project(s) successful created from agreement');
				setTimeout(() => this.$router.push(`/client/${this.proposal.clientId}?v=Overview`),500);
				this.$emit('result', 'newProjectCreated');

			},

			handleUpdateFromCoverMenu(updatedPage) {
				this.proposal.coverPage.theme = updatedPage.theme;
				this.proposal.coverPage.imageDownload = updatedPage.imageDownload;
				this.proposal.coverPage.image = updatedPage.image;
				this.proposal.coverPage.imageThumb = updatedPage.imageThumb;
				this.proposal.coverPage.showLogo = updatedPage.showLogo;
				this.proposal.coverPage.headingPosition = updatedPage.headingPosition;
				this.proposal.coverPage.heading = updatedPage.heading;
				this.coverMenuOpen = false;
				this.handleNeedSave();
			},

			updateAccount(event) {
				this.account = event;
			},

			handleFormatChange(format) {
				this.proposal.formatting = format;
				this.refreshKey++;
				this.handleNeedSave();
			},

			createPackage(deliverables) {
				console.log('hello!');
				console.log(deliverables);
				let binding = {
					deliverables: deliverables,
				};
				this.$store.state.globalModalController
					.openModal(CreatePackageFromProposalDeliverablesModal, binding)
					.then((res) => {
						// console.log(res);
						this.$store.commit('success', this.$t('templates.deliverable-package-saved', { name: res.name }));
						// this.$emit('result', 'newPackageCreated', res);
					});
			},

			createTemplate() {
				console.log('hello!');

				let binding = {
					proposal: this.proposal,
				};
				this.$store.state.globalModalController.openModal(CreateTemplateFromProposal, binding).then((res) => {
					// console.log(res);
					this.$store.commit('success', this.$t('templates.template-saved', { name: res.name }));
					// this.$emit('result', 'newPackageCreated', res);
				});
			},
		},

		computed: {
			isLocked: function() {
				if (!this.isReady) return true;
				return !(this.proposal.proposalStatus === 'Draft');
			},

			isFullyExecuted: function() {
				if (!this.isReady) return false;
				return this.proposal.proposalStatus === 'Fully Executed';
			},

			showSubheaderButtons: function() {
				if (!this.isReady) return false;
				return !(this.proposal.proposalStatus === 'Fully Executed');
			},

			protectedEditMode: function() {
				if (!this.isReady) return false;
				if (this.proposal.proposalStatus !== 'Draft') {
					return false;
				} else {
					return this.editMode;
				}
			},

			status: function() {
				if (this.needSave && !this.saving) {
					return this.$t('proposal.save.prepare');
				} else if (this.saving) {
					return this.$t('proposal.save.saving');
				} else {
					return this.$t('proposal.save.complete');
				}
			},
		},

		watch: {
			proposalId: function() {
				this.isReady = false;
				this.initialize();
			},
		},
	};
</script>

<style lang="scss">
	#proposal-builder {
		background-color: var(--v-gray_10-base);

		.subheader-buttons {
			margin: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			.v-btn {
				width: 260px;
			}
		}
	}
	#delete-proposal-modal {
		width: 300px;
		background-color: var(--v-white-base);
	}
</style>
