<template>
	<div class="pa-4" style="min-width: 800px; max-width: calc(100vw - 200px)">
		<div class="text-left">
			<div class="row-format">
				<span><span class="brand-bold">From:</span> {{ emailLog.from.address }}</span>
				<div class="ml-auto">
					<v-icon @click="$emit('close')" class="pointer">close</v-icon>
				</div>
			</div>
			<div v-if="emailLog.to.length"><span class="brand-bold">To:</span> {{ emailLog.to.map((t) => t.address).join(', ') }}</div>
			<div v-if="emailLog.cc.length"><span class="brand-bold">CC:</span> {{ emailLog.cc.map((t) => t.address).join(', ') }}</div>
			<div v-if="emailLog.bcc.length"><span class="brand-bold">BCC:</span> {{ emailLog.bcc.map((t) => t.address).join(', ') }}</div>
			<div><span class="brand-bold">{{recordType === 'SENT_LOG' ? 'Sent' : 'Send at'}}:</span> {{ DateTime.fromISO(emailLog.sentAt).toLocaleString(DateTime.DATETIME_FULL) }}</div>
			<div><span class="brand-bold">Subject:</span> {{ emailLog.subject }}</div>
		</div>
		<div v-if="emailContent" class="text-left py-3 my-3 show-scrollbar" style="border-top: 1px solid var(--v-gray_30-base);border-bottom: 1px solid var(--v-gray_30-base); overflow: auto">
			<div :id="emailLog.id" v-html="emailContent.htmlContent"></div>
			<div class="my-3">
				<v-chip small color="gray_80" class="pointer" v-for="attachment in emailContent.attachments" :key="attachment" @click="download(attachment)">
					<v-icon color="white" size="20" class="mr-1">attachment</v-icon>
					<div style="color: var(--v-white-base)">{{attachment}}</div>
				</v-chip>
			</div>
		</div>
	</div>
</template>

<script>
	import EmailLogService from '@/modules/communicator/emailLog/EmailLogService';
	import HDateTime from "@/modules/utils/HDateTime";

	export default {
		name: 'EmailLogDetail',

		props: ['emailLog','recordType'],

		components: {},

		data: function() {
			return {
				DateTime: HDateTime,
				emailContent: null,
				emailLogService: new EmailLogService(),
			};
		},

		mounted() {
			this.getEmailContent();
		},

		beforeDestroy() {},

		methods: {
			getEmailContent: function() {
				this.emailLogService.getEmailContent(this.emailLog.id).then((res) => {
					this.emailContent = res.data;
					this.$nextTick(() => this.fixupLinks());
				});
			},

			fixupLinks: function () {
				let div = document.getElementById(this.emailLog.id);
				if (div) {
					let links = div.getElementsByTagName('a');
					links.forEach((a) => a.setAttribute('target', '_blank'));
				}
			},

			download: function (attachment) {
				this.emailLogService
						.getEmailAttachment(this.emailLog.id, attachment)
						.then((res) => {
							console.log(res.data);
							let fileURL = window.URL.createObjectURL(new Blob([res.data]));

							let fileLink = document.createElement('a');
							fileLink.href = fileURL;
							fileLink.setAttribute('download', attachment);
							document.body.appendChild(fileLink);
							fileLink.click();
						});
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
