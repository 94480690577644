<template>
	<div id="expenses-modal">
		<v-btn icon class="close-dialog" @click="$emit('close')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div id="select-expenses" :class="$vuetify.breakpoint.xs ? 'pl-4' : ''">
				<div class="mb-2">
					<h1 class="modal-title">{{ $t('invoice.create-modal.expense-heading') }}</h1>
					<p class="modal-subtitle" v-html="$t('invoice.create-modal.expense-subheading')"></p>
				</div>

				<div v-for="expense in expenses" :key="expense.id + '-' + expenseKey" class="expense">
					<div class="expense-head">
						<div class="row-format align-center" style="max-width: 50px">
							<v-btn icon rounded small class="chevron" @click="toggleExpanded(expense)">
								<v-icon v-if="expense.expanded">$chevronDown</v-icon>
								<v-icon v-else>$chevronRight</v-icon>
							</v-btn>

							<div style="display: block" class="ml-1 mt-1">
								<v-simple-checkbox color="primary" :value="expense.checked" @input="toggleChecked(expense)"></v-simple-checkbox>
							</div>
						</div>

						<div :class="`name ${$vuetify.breakpoint.xs ? 'truncate' : ''}`">
							{{ expenseHeading(expense) }}
						</div>
						<div class="hours">
							{{ $formatters.dollars(expense.localTotalWithMarkup,true,true,invoice.currency) }}
						</div>
					</div>

					<template v-if="expense.expanded">
						<div class="expense-body">
							<div class="line-item">
								<div class="left">Category</div>
								<div class="right">
									{{ expense.category | expenseCategory }}
								</div>
							</div>
							<div class="line-item">
								<div class="left">Vendor</div>
								<div class="right">
									{{ expense.vendor | vendorName }}
								</div>
							</div>
							<div class="line-item">
								<div class="left">Paid</div>
								<div class="right">
									{{ $DateTime.humanDate(expense.paidDate) }}
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<v-btn class="primary-action" @click="handleSubmitExpenses">{{ $t('global.done') }}</v-btn>
		</div>
	</div>
</template>

<script>
	import InvoiceMixin from '@/modules/invoices/InvoiceMixin';
	import expenseCategories from '../../assets/data/expense-categories.json';
	import VendorService from '@/modules/accounting/vendors/VendorService';
	import ExpenseService from '@/modules/accounting/expenses/ExpenseService';
	import InvoiceService from '@/modules/invoices/InvoiceService';

	export default {
		name: 'ExpensesModal',
		components: {},
		mixins: [InvoiceMixin],
		props: {
			client: { type: Object, required: true },
			invoice: { type: Object, required: true },
		},

		//props: ['value', 'label', 'placeholder', 'prependInnerIcon', 'prependIcon'],

		data: function() {
			return {
				invoiceService: new InvoiceService(),
				expenseCategories: expenseCategories,
				vendorService: new VendorService(),
				expenseService: new ExpenseService(),
				billable: null,

				expenses: [],
				vendors: [],
				expenseKey: 0,
			};
		},

		mounted() {
			this.getBillable();
		},

		methods: {
			setSelectedFromInvoice() {
				for (let i = 0; i < this.invoice.items.length; i++) {
					let item = this.invoice.items[i];
					if (item.type === 'EXPENSE') {
						let ix = this.expenses.findIndex((e) => e.id === item.expenseId);
						if (ix > -1) {
							this.expenses[ix].checked = true;
						}
					}
				}
			},

			getVendors: function() {
				this.vendorService.getVendors().then((res) => {
					this.vendors.splice(0, this.vendors.length);
					this.vendors.push(...res.data);
				});
			},

			getBillable() {
				this.invoiceService
					.getBillable(this.client.id, this.invoice.id)
					.then((res) => {
						this.expenses = res.data.expenses;

						this.expenses.forEach((e) => {
							e.checked = false;
							e.expanded = false;
						});

						if (this.expenses.length) {
							this.getVendors();
						}
						this.setSelectedFromInvoice();
					})
					.catch((err) => {
						console.log(err);
						let msg = err.response.data.message;
						let status = err.response.status;
						this.$store.commit('warn', 'Error [' + status + '] ' + msg);
					});
			},

			expenseHeading(expense) {
				if (expense.vendor && expense.vendor.name) return expense.vendor.name;
				if (expense.category) return expense.category;
				if (expense.description) return expense.description;
				if (expense.datePaid)
					return this.$formatters.shortDate(expense.datePaid) + ' ' + this.$t('global.expense').toLowerCase();
				return this.$t('global.expense').toLowerCase();
			},

			toggleExpanded(expense) {
				expense.expanded = !expense.expanded;
				this.expenseKey++;
			},

			toggleChecked(expense) {
				expense.checked = !expense.checked;
				this.expenseKey++;
			},

			handleSubmitExpenses() {
				this.invoice.items = this.invoice.items.filter((i) => i.type !== 'EXPENSE');
				this.expenses
					.filter((e) => e.checked)
					.forEach((e) => {
						let description = e.category;
						if (e.vendor && e.vendor.name) description = e.vendor.name;
						if (e.description) description = e.description;

						this.invoice.items.push({
							qty: 1,
							price: e.localTotalWithMarkup,
							expenseId: e.id,
							description: `<strong>${description}</strong>`,
							type: 'EXPENSE',
						});
					});

				this.$emit('save');
				this.$emit('close');
			},
		},

		filters: {
			noteFormat(note) {
				return note ? note : '--';
			},
			vendorName(vendor) {
				if (vendor) return vendor.name;
				return '--';
			},
			expenseCategory(category) {
				if (category) return category;
				return '--';
			},
		},

		computed: {},

		watch: {},
	};
</script>

<style lang="scss">
	#expenses-modal {
		background-color: var(--v-white-base);
		max-width: 483px;

		#select-expenses {
			.truncate {
				max-width: 120px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.expense {
				border-top: 1px solid var(--v-gray_50-base);
				padding: 16px 0;
				font-size: 16px;
				letter-spacing: 0.2px;

				.expense-head {
					clear: both;
					height: 40px;
					display: flex;
					div,
					button {
						align-self: center;
						justify-content: center;
						&.chevron,
						&.checkbox {
							flex: 0 0 auto;
						}
						&.checkbox {
						}
					}
					div {
						flex: 1 1 auto;
						&.name {
							text-align: left;
							margin-left: 6px;
							span {
								margin-right: 4px;
								&.fee-type {
									white-space: nowrap;
								}
							}
						}
						&.hours {
							cursor: pointer;
							text-align: right;
						}
					}
				}

				.expense-body {
					.line-item {
						padding-left: 62px;
						height: 28px;
						display: flex;
						justify-content: space-between;
						font-weight: 300;
						font-size: 14px;
						div {
							align-self: center;
							&.left {
								flex: 1 1 auto;
								text-align: left;
							}
							&.right {
								flex: 0 0 230px;
								text-align: right;
							}
						}
					}
				}
			}
		}
		.modal-footer {
			button {
				width: 200px;
			}
		}
	}
</style>
