var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "row-format", attrs: { id: "project-filter" } },
        [
          _c(
            "v-menu",
            {
              staticStyle: { "overflow-y": "auto" },
              attrs: {
                "max-height": "90vh",
                "max-width": "250",
                attach: "#project-filter",
                "close-on-content-click": false,
                "close-on-click": true,
                "content-class": "filter-menu-class"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticStyle: {
                                "min-height": "40px",
                                "background-color": "var(--v-white-base)"
                              },
                              attrs: {
                                elevation: "0",
                                outlined: "",
                                color: "gray_30"
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "span",
                              {
                                class:
                                  "font-14 " +
                                  (!_vm.filtersAreEmpty
                                    ? "font-secondary"
                                    : "font-gray_60") +
                                  " brand-medium"
                              },
                              [_vm._v(_vm._s(_vm.filterLabel))]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  "x-small": "",
                                  color: !_vm.filtersAreEmpty
                                    ? "secondary"
                                    : "gray_80"
                                }
                              },
                              [_vm._v("$arrowDown")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2387291514
              ),
              model: {
                value: _vm.menu,
                callback: function($$v) {
                  _vm.menu = $$v
                },
                expression: "menu"
              }
            },
            [
              _c(
                "div",
                { staticClass: "column-format" },
                [
                  !_vm.singleProjectMode
                    ? _c("div", { staticClass: "filter-option-box" }, [
                        _c(
                          "div",
                          {
                            staticClass: "row-format",
                            on: {
                              click: function($event) {
                                _vm.clientExpanded = !_vm.clientExpanded
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "pr-4 font-14" }, [
                              _vm._v(
                                _vm._s(_vm.$t("projects.filter.client-project"))
                              )
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticStyle: {
                                  "margin-left": "auto",
                                  "margin-top": "1px"
                                },
                                attrs: { small: "" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.clientExpanded
                                      ? "$arrowUp"
                                      : "$arrowDown"
                                  )
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.clientExpanded
                          ? _c(
                              "div",
                              [
                                _c("v-checkbox", {
                                  staticClass: "mb-2",
                                  staticStyle: { "margin-left": "23px" },
                                  attrs: {
                                    label: "[No client]",
                                    "hide-details": ""
                                  },
                                  model: {
                                    value: _vm.noClientAssigned,
                                    callback: function($$v) {
                                      _vm.noClientAssigned = $$v
                                    },
                                    expression: "noClientAssigned"
                                  }
                                }),
                                _c("v-treeview", {
                                  attrs: {
                                    selectable: "",
                                    "selected-color": "primary",
                                    "item-children": "projects",
                                    "item-text": "name",
                                    dense: "",
                                    items: _vm.clientsProjects
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function(ref) {
                                          var item = ref.item
                                          var selected = ref.selected
                                          var indeterminate = ref.indeterminate
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "left"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row-format pointer align-center",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.toggleProject(
                                                          item,
                                                          selected,
                                                          indeterminate
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    item.isClient
                                                      ? _c("client-avatar", {
                                                          attrs: {
                                                            "x-small": "",
                                                            client: item,
                                                            "disable-click": true
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-2 ellipsis"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2273474295
                                  ),
                                  model: {
                                    value: _vm.projectFilters,
                                    callback: function($$v) {
                                      _vm.projectFilters = $$v
                                    },
                                    expression: "projectFilters"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "filter-option-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row-format",
                        on: {
                          click: function($event) {
                            _vm.statusExpanded = !_vm.statusExpanded
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "font-14" }, [
                          _vm._v(_vm._s(_vm.$t("projects.filter.status")))
                        ]),
                        _c(
                          "v-icon",
                          {
                            staticStyle: {
                              "margin-left": "auto",
                              "margin-top": "1px"
                            },
                            attrs: { small: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.statusExpanded ? "$arrowUp" : "$arrowDown"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.statusExpanded
                      ? _c(
                          "div",
                          _vm._l(_vm.statusList, function(status) {
                            return _c("v-checkbox", {
                              key: status.id,
                              attrs: {
                                value: status.id,
                                "hide-details": "",
                                dense: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row-format align-center",
                                            staticStyle: { width: "100%" }
                                          },
                                          [
                                            _c("div", {
                                              style:
                                                "width:8px; height: 8px; border-radius: 2px; background-color:" +
                                                status.hexColor
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "status-label ml-2 ellipsis",
                                              domProps: {
                                                innerHTML: _vm._s(status.label)
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.filter.status,
                                callback: function($$v) {
                                  _vm.$set(_vm.filter, "status", $$v)
                                },
                                expression: "filter.status"
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "filter-option-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row-format",
                        on: {
                          click: function($event) {
                            _vm.assignedExpanded = !_vm.assignedExpanded
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "font-14" }, [
                          _vm._v(_vm._s(_vm.$t("projects.filter.assigned")))
                        ]),
                        _c(
                          "v-icon",
                          {
                            staticStyle: {
                              "margin-left": "auto",
                              "margin-top": "1px"
                            },
                            attrs: { small: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.assignedExpanded ? "$arrowUp" : "$arrowDown"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.assignedExpanded
                      ? _c(
                          "div",
                          { staticClass: "assigned-user ellipsis" },
                          _vm._l(_vm.userOptions, function(user) {
                            return _c("v-checkbox", {
                              key: user.userId,
                              attrs: {
                                value: user.userId,
                                "hide-details": "",
                                dense: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        user.userId !== "unassigned"
                                          ? _c("assigned-user", {
                                              attrs: {
                                                "assigned-to": user.userId
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("projects.unassigned")
                                                )
                                              )
                                            ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.filter.assignedTo,
                                callback: function($$v) {
                                  _vm.$set(_vm.filter, "assignedTo", $$v)
                                },
                                expression: "filter.assignedTo"
                              }
                            })
                          }),
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("filter-option", {
                    attrs: {
                      label: "Active status",
                      "option-list": _vm.archiveStatus
                    },
                    model: {
                      value: _vm.filter.archiveStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.filter, "archiveStatus", $$v)
                      },
                      expression: "filter.archiveStatus"
                    }
                  }),
                  _c("div", { staticClass: "filter-option-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row-format",
                        on: {
                          click: function($event) {
                            _vm.dateExpanded = !_vm.dateExpanded
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "font-14" }, [
                          _vm._v(_vm._s(_vm.$t("client.filter.date-range")))
                        ]),
                        _c(
                          "v-icon",
                          {
                            staticStyle: {
                              "margin-left": "auto",
                              "margin-top": "1px"
                            },
                            attrs: { small: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.dateExpanded ? "$arrowUp" : "$arrowDown"
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.dateExpanded
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-radio-group",
                              {
                                model: {
                                  value: _vm.filter.dueBy,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filter, "dueBy", $$v)
                                  },
                                  expression: "filter.dueBy"
                                }
                              },
                              _vm._l(_vm.dateOptions, function(option) {
                                return _c("v-radio", {
                                  key: option.value,
                                  attrs: { value: option },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-14 brand-semilight",
                                                staticStyle: {
                                                  "font-size": "14px"
                                                }
                                              },
                                              [_vm._v(_vm._s(option.text))]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              }),
                              1
                            ),
                            _vm.showEarliest
                              ? _c(
                                  "div",
                                  {},
                                  [
                                    _c("date-selector", {
                                      attrs: {
                                        "content-class": "my-5",
                                        date: _vm.filter.dueDate1,
                                        label: "Earliest"
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.filter.dueDate1 = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showLatest
                              ? _c(
                                  "div",
                                  {},
                                  [
                                    _c("date-selector", {
                                      attrs: {
                                        "content-class": "my-5",
                                        date: _vm.filter.dueDate2,
                                        label: "Latest"
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.filter.dueDate2 = $event
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "mx-3 mt-3 mb-3 column-format",
                      staticStyle: {
                        gap: "12px",
                        color: "var(--v-secondary-base)"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pointer font-14 row-format centered",
                          on: {
                            click: function($event) {
                              return _vm.clearFilters()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "secondary" } },
                            [_vm._v("$clear")]
                          ),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("projects.filter.clear-filter"))
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "super-action",
                          on: {
                            click: function($event) {
                              _vm.menu = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("global.done")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }