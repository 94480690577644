import HttpClient from '@/services/HttpClient';

export default class UserNotificationSettingsService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getUserNotificationSettings() {
		return this.httpClient
			.get('/userNotificationSettings')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateUserNotificationSettings(settings) {
		return this.httpClient
			.post(`/userNotificationSettings`, settings)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
