var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format align-center font-14 font-gray_80" },
    [
      _c(
        "v-btn",
        {
          attrs: { icon: "", disabled: _vm.currentPage === 1 },
          on: { click: _vm.previousPage }
        },
        [_c("v-icon", { attrs: { color: "black" } }, [_vm._v("arrow_left")])],
        1
      ),
      _c("div", { staticClass: "brand-medium" }, [
        _vm._v("Page " + _vm._s(_vm.currentPage) + "/" + _vm._s(_vm.totalPages))
      ]),
      _c(
        "v-btn",
        {
          attrs: { icon: "", disabled: _vm.currentPage === _vm.totalPages },
          on: { click: _vm.nextPage }
        },
        [_c("v-icon", { attrs: { color: "black" } }, [_vm._v("arrow_right")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }