var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left" },
    [
      _c("div", { staticClass: "brand-medium pa-4" }, [
        _vm._v("Subscription payment history")
      ]),
      _vm._l(_vm.invoicesPages, function(invoice) {
        return _c(
          "v-list-item",
          { key: invoice.id },
          [
            _c(
              "v-list-item-content",
              [
                _c("v-list-item-title", {
                  staticStyle: { "font-size": "14px" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$DateTime.longDate(invoice.createdAt)
                    )
                  }
                }),
                _c("v-list-item-subtitle", {
                  staticClass: "mt-3",
                  staticStyle: { "font-size": "14px" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$formatters.dollars(invoice.total, true, true, "USD")
                    )
                  }
                })
              ],
              1
            ),
            _c(
              "v-list-item-action",
              [
                _c(
                  "v-btn",
                  {
                    staticStyle: { "font-size": "12px" },
                    attrs: { text: "", color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.handleClick(
                          invoice.id,
                          invoice.downloadSignature
                        )
                      }
                    }
                  },
                  [_vm._v(" View ")]
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _vm.invoices.length > _vm.rowsPerPage
            ? _c("v-pagination", {
                staticClass: "float-none float-sm-left",
                attrs: {
                  color: "none",
                  "total-visible": 5,
                  length: Math.ceil(_vm.invoices.length / _vm.rowsPerPage)
                },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "Modal",
        {
          ref: "invoiceModal",
          attrs: { dialog: _vm.invoiceModal, "max-width": 800 },
          on: {
            close: function($event) {
              _vm.invoiceModal = false
            }
          }
        },
        [
          _c("Invoice", {
            key: _vm.invoiceModal,
            attrs: {
              invoiceId: _vm.invoiceId,
              downloadSignature: _vm.downloadSignature
            },
            on: {
              close: function($event) {
                _vm.invoiceModal = false
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }