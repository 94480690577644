var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "project-selector" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: { click: _vm.handleClose }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _vm.currentView === "SELECT"
        ? _c("div", [
            _c("div", { staticClass: "modal-padding" }, [
              _c("h2", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("projects.add-deliverable")))
              ]),
              _c("div", { staticClass: "modal-subtitle" }, [
                _vm._v(_vm._s(_vm.$t("projects.add-deliverable-sub")))
              ]),
              _c(
                "div",
                { staticClass: "mt-6" },
                [
                  _vm.projects.length > 5
                    ? _c("v-text-field", {
                        staticClass: "project-filter",
                        attrs: {
                          placeholder: "Filter by project or client",
                          "persistent-placeholder": "",
                          "hide-details": "",
                          autofocus: "",
                          clearable: "",
                          outlined: "",
                          "prepend-inner-icon": "$filter"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("v-data-table", {
                    attrs: {
                      id: "data-table",
                      "items-per-page": 5,
                      "hide-default-footer": _vm.projects.length <= 5,
                      "hide-default-header": true,
                      headers: _vm.headers,
                      items: _vm.projects,
                      search: _vm.search,
                      "custom-filter": _vm.filterList,
                      "mobile-breakpoint": 0
                    },
                    on: {
                      "click:row": function($event) {
                        return _vm.selectProject($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.name",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                { staticClass: "project-wrapper" },
                                [
                                  _c("client-avatar", {
                                    attrs: {
                                      "disable-click": "",
                                      client: item.client
                                    }
                                  }),
                                  _c("div", { staticClass: "project-name" }, [
                                    _vm._v(_vm._s(item.name))
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1269080260
                    )
                  })
                ],
                1
              ),
              !_vm.isCollaborator
                ? _c("div", { staticClass: "mt-8" }, [
                    _c(
                      "span",
                      {
                        staticClass: "internal-link",
                        on: {
                          click: function($event) {
                            return _vm.createProject()
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "12", color: "primary" } },
                          [_vm._v("$plus")]
                        ),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.$t("projects.create")))
                        ])
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm.currentView === "CREATE"
        ? _c(
            "div",
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.formValid,
                    callback: function($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "modal-padding" },
                    [
                      _c(
                        "h2",
                        { staticClass: "modal-title" },
                        [
                          _vm.projects.length > 0 && !_vm.skipDeliverable
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.backToSelect()
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("$arrowLeft")])],
                                1
                              )
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.clientCreate
                                  ? _vm.$t("projects.create-project-client")
                                  : _vm.$t("projects.create")
                              ) +
                              " "
                          )
                        ],
                        1
                      ),
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          "persistent-placeholder": "",
                          "hide-details": "",
                          autofocus: "",
                          rules: _vm.nameRules,
                          label: _vm.$t("projects.project-name")
                        },
                        model: {
                          value: _vm.newProjectName,
                          callback: function($$v) {
                            _vm.newProjectName = $$v
                          },
                          expression: "newProjectName"
                        }
                      }),
                      _vm.isPaid && _vm.packageList.length
                        ? _c("v-select", {
                            staticClass: "h-outline",
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": "",
                              label: _vm.$t("templates.start-from-template"),
                              items: _vm.packageList,
                              "item-text": "name",
                              "item-value": "id"
                            },
                            on: {
                              change: function($event) {
                                return _vm.handleTemplateChange()
                              }
                            },
                            model: {
                              value: _vm.deliverablePackageId,
                              callback: function($$v) {
                                _vm.deliverablePackageId = $$v
                              },
                              expression: "deliverablePackageId"
                            }
                          })
                        : _vm._e(),
                      !_vm.clientCreate && !_vm.clientId
                        ? _c(
                            "div",
                            [
                              _c("v-autocomplete", {
                                staticClass: "h-outline",
                                staticStyle: {
                                  "font-size": "14px",
                                  "text-align": "left !important"
                                },
                                attrs: {
                                  "persistent-placeholder": "",
                                  "hide-details": "",
                                  label: _vm.$t("projects.select-client"),
                                  items: _vm.clientItems,
                                  "item-text": "name",
                                  "return-object": "",
                                  clearable: false,
                                  rules: [
                                    function(v) {
                                      return !!v || "Field is required"
                                    }
                                  ],
                                  "menu-props": { contentClass: "text-left" }
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.handleSelectedClientChange()
                                  }
                                },
                                model: {
                                  value: _vm.selectedClient,
                                  callback: function($$v) {
                                    _vm.selectedClient = $$v
                                  },
                                  expression: "selectedClient"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.clientCreate && !_vm.clientId
                        ? _c(
                            "div",
                            [
                              _c("v-text-field", {
                                staticClass: "h-outline",
                                attrs: {
                                  "persistent-placeholder": "",
                                  "hide-details": "",
                                  rules: _vm.nameRules,
                                  label: _vm.$t("client.create-new-client")
                                },
                                model: {
                                  value: _vm.newClientName,
                                  callback: function($$v) {
                                    _vm.newClientName = $$v
                                  },
                                  expression: "newClientName"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.clientCreate && !_vm.clientId
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "internal-link",
                                on: {
                                  click: function($event) {
                                    return _vm.createClient()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("projects.create-client")) + " "
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: {
                                      color: "var(--v-primary-base)"
                                    },
                                    attrs: { size: "12" }
                                  },
                                  [_vm._v("$plus")]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.clientCreate && !_vm.clientId
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "internal-link",
                                on: {
                                  click: function($event) {
                                    return _vm.selectClient()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("projects.or-select-client")) +
                                    " "
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: {
                                      color: "var(--v-primary-base)"
                                    },
                                    attrs: { size: "12" }
                                  },
                                  [_vm._v("$chevronDown")]
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-footer mt-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary-action mr-1",
                          attrs: { width: "150" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("result")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("global.cancel")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary-action ml-1",
                          attrs: { width: "150", disabled: !_vm.formValid },
                          on: {
                            click: function($event) {
                              return _vm.initializeItems()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("global.save")))]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }