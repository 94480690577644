<template>
	<div class="column-format fill-all">
		<div class="mb-10 mt-3">
			<div style="font-size: 32px; font-weight: 600">What are you hoping to achieve with Moxie today?</div>
			<div class="font-16 font-gray_70">
				Select one goal for now. Don’t worry, you can always come back and do more later!
			</div>
		</div>

		<div class="row-format gap-3 centered" style="flex-wrap: wrap">
			<div
				:class="`intention row-format align-center text-left ${
					selectedIntention === intention.value ? 'active-intention' : ''
				}`"
				v-for="intention in intentions"
				:key="intention.value"
				@click="setSelectedIntention(intention.value)"
			>
				<div
					style="min-width: 140px; min-height: 140px; width: 140px; height: 140px"
					:style="`background-position: center; background-size: contain; background-image: url('/images/onboarding/intentions/${intention.image}')`"
				></div>
				<div class="ml-3 font-18 brand-medium">{{ intention.heading }}</div>
			</div>
		</div>

		<div class="mt-10 gap-2 row-format centered" id="intentionContinue">
			<v-btn large outlined color="gray_50" width="50" @click="$emit('back')"
				><v-icon color="gray_70">arrow_back</v-icon></v-btn
			>
			<v-btn large color="primary" width="240" :disabled="!selectedIntention" @click="next()">{{ cta }}</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Intentions',

		props: ['intention'],

		components: {},

		data: function () {
			return {
				selectedIntention: this.intention,
				intentions: [
					{ heading: 'Improve my client management', value: 'Client management', cta: '', image: 'clients.png' },
					{ heading: 'Invoicing and getting paid quicker', value: 'Invoices', cta: '', image: 'invoice.png' },
					{
						heading: 'Send professional quotes, proposals, and contracts',
						value: 'Proposals',
						cta: '',
						image: 'proposal.png',
					},
					{ heading: 'Manage my projects better', value: 'Projects', cta: '', image: 'projects.png' },
					{ heading: 'Centralize my business', value: 'Centralization', cta: '', image: 'centralize.png' },
					{ heading: 'Effectively manage my books', value: 'Accounting', cta: '', image: 'accounting.png' },
					{ heading: 'Gain insight through time tracking', value: 'Time tracking', cta: '', image: 'timetracking.png' },
					{ heading: 'Win more business', value: 'Win business', cta: '', image: 'pipeline.png' },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			setSelectedIntention(intention){
				this.selectedIntention = intention;
				this.$nextTick(() => document.getElementById("intentionContinue").scrollIntoView({behavior:'smooth'}));
			},

			next: function () {
				this.$emit('next', this.selectedIntention);
			},
		},

		computed: {
			cta: function () {
				return 'Continue';
			},
		},
	};
</script>

<style scoped lang="scss">
	.intention {
		padding: 16px 16px 0px;
		gap: 8px;

		width: 450px;
		height: 155px;

		border: 1px solid var(--v-gray_30-base);
		border-radius: 4px;
		cursor: pointer;
		color: var(--v-gray_80-base);
		box-shadow: 0px 4px 6px rgba(0, 43, 82, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.3);

		&:hover {
			border: 1px solid var(--v-gray_30-base);
			background-color: var(--v-gray_10-base);
			color: var(--v-gray_90-base);
		}
	}

	.active-intention {
		border: 1px solid var(--v-gray_30-base);
		background-color: var(--v-gray_10-base);
		color: var(--v-gray_90-base);
	}

	.logo {
		width: 150px;
	}
</style>
