<template>
	<div class="column-format text-left">
		<div class="column-format pt-1 pb-4">
			<div class="brand-bold font-18">Contents</div>
			<div class="font-14 font-gray_80">Click a heading to navigate to the section</div>
		</div>
		<draggable :list="agreement.pages" @end="update" class="column-format gap-3">
			<div v-for="(page, index) in agreement.pages" :key="page.id" class="page-preview row-format gap-1 text-left" @click="scrollToPage(index)">
				<v-icon style="cursor: grab" size="20" class="drag-handle">drag_indicator</v-icon>

				<v-text-field v-if="editIndex === index"
						hide-details
						dense
						outlined
						persistent-placeholder
						placeholder="Title (optional)"
						v-model="page.title"
						@blur="update(); editIndex=-1;"
				></v-text-field>

				<div v-else>{{page.title ? page.title : ''}}</div>

				<div class="actions ml-auto row-format align-center">
					<v-icon class="material-symbols-rounded pointer" size="20" color="gray_70" @click.stop="toggleEditIndex(index)">edit</v-icon>
					<v-icon class="material-symbols-rounded pointer" size="20" color="gray_70" @click.stop="confirmDelete(index)"
						>delete</v-icon
					>
				</div>
			</div>
		</draggable>
		<div>
			<v-btn text color="primary" @click="$emit('add-page')">+ Add page</v-btn>
		</div>
	</div>
</template>

<script>
	import Draggable from 'vuedraggable';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'PagesConfig',

		props: ['agreement'],

		components: { Draggable },

		data: function() {
			return {
				editIndex: -1
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			scrollToPage: function(index){
				document.getElementById('page-' + index).scrollIntoView({behavior:'smooth'})
			},

			toggleEditIndex: function(index){
				if(this.editIndex === index){
					this.editIndex = -1;
				}else{
					this.editIndex = index;
				}
			},

			confirmDelete: function(index) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to remove this page and all of the contents?',
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.agreement.pages.splice(index, 1);
						this.update();
					}
				});
			},

			update: function() {
				this.$emit('update');
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
	.drag-handle {
		&:active {
			cursor: grabbing;
		}
	}

	.page-preview {
		background-color: var(--v-gray_10-base);
		padding: 12px 8px;
		border-radius: 8px;
		cursor: pointer;

		.actions {
			opacity: 0;
			transition: all 150ms;
		}

		&:hover {
			.actions {
				opacity: 1;
			}
		}
	}
</style>
