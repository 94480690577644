var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c("div", [
        _c("div", { staticClass: "row-format align-start" }, [
          _c("div", { staticClass: "column-format" }, [
            _c("div", { staticClass: "font-gray_80 mb-1" }, [
              _vm._v(_vm._s(_vm.$t("connected-apps.plaid.desc")))
            ]),
            _vm.paidAccount
              ? _c(
                  "div",
                  [
                    _vm.isConnected
                      ? _c("connected")
                      : _c(
                          "div",
                          {
                            staticClass: "font-primary brand-medium pointer",
                            on: {
                              click: function($event) {
                                return _vm.linkNewAccount(null)
                              }
                            }
                          },
                          [_vm._v("Connect Plaid")]
                        )
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "font-14 pointer",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/subscription")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "", color: "warning" } }, [
                        _vm._v("$alert")
                      ]),
                      _vm._v(
                        "This integration is not available on the Essentials plan. If you would like to automate your expense tracking, please upgrade to Pro or Teams."
                      )
                    ],
                    1
                  )
                ])
          ]),
          _vm.isConnected
            ? _c(
                "div",
                {
                  staticClass: "ml-auto pointer",
                  on: {
                    click: function($event) {
                      _vm.expanded = !_vm.expanded
                    }
                  }
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.expanded ? "$chevronUp" : "$chevronDown"))
                  ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.isConnected && _vm.expanded
          ? _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "v-container",
                  { staticClass: "font-14 px-0" },
                  _vm._l(_vm.items, function(item) {
                    return _c(
                      "v-row",
                      { key: item.itemId },
                      [
                        _c("v-col", [_vm._v(_vm._s(item.institutionName))]),
                        _c("v-col", { attrs: { cols: "5" } }, [
                          _vm._v(_vm._s(item.lastUpdateMessage))
                        ]),
                        _c(
                          "v-col",
                          { staticStyle: { "white-space": "nowrap" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.DateTime.fromISO(
                                  item.lastUpdate
                                ).toLocaleString(_vm.DateTime.DATETIME_SHORT)
                              )
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          { attrs: { align: "right" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.linkNewAccount(item.itemId)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("refresh")])],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.unlinkAccount(item.itemId)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("$delete")])],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "primary-action mt-5",
                    on: {
                      click: function($event) {
                        return _vm.linkNewAccount(null)
                      }
                    }
                  },
                  [_vm._v("Connect new account")]
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }