<template>
	<div style="background-color: var(--v-white-base);">
			<v-data-table
				:headers="headers"
				item-key="id"
				:hide-default-footer="true"
				:disable-pagination="true"
				:items="filteredList"
				@click:row="openInvoice($event.id, $event.invoice.clientId)"
				class="pointer"
			>
				<template v-slot:item.amount="{ item }">
					<div>
						{{ $formatters.dollars(item.invoice.invoice.total, true, true, item.invoice.invoice.currency) }}
					</div>
				</template>
				<template v-slot:item.name="{item}">
					{{item.name ? item.name : '--'}}
				</template>
				<template v-slot:item.client="{ item }">
					<div class="row-format align-center" v-if="item.client">
						<client-avatar :client="item.client" small></client-avatar>
						<div class="ml-n3">{{ item.client.name }}</div>
					</div>
				</template>
				<template v-slot:item.startDate="{ item }">
					{{ DateTime.fromISO(item.startDate).toLocaleString(DateTime.DATE_MED) }}
					<span v-if="item.endDate">
							thru {{ DateTime.fromISO(item.endDate).toLocaleString(DateTime.DATE_MED) }}</span
					>
				</template>
				<template v-slot:item.nextRunTime="{ item }">
					<span v-if="item.nextRunTime && item.enabled">{{
						DateTime.fromISO(item.nextRunTime)
							.setZone($store.state.podAccount.timeZone)
							.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
					}}</span>
					<span v-else>--</span>
				</template>
				<template v-slot:item.status="{ item }">
					<span v-if="item.enabled" style="text-transform: capitalize">{{ item.status.toLowerCase() }}</span>
					<span v-else>Disabled</span>
				</template>
				<template v-slot:item.interval="{ item }">
					{{ getLabel(item) }}
				</template>
			</v-data-table>
	</div>
</template>

<script>
	import RecurringEventMixin from '@/modules/recurrence/RecurringEventMixin';
	import DateTime from '@/modules/utils/HDateTime';
	import RecurringInvoiceDetails from '@/modules/invoices/recurring/RecurringInvoiceDetails';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'RecurringInvoiceList',

		props: ['client', 'filter'],

		mixins: [RecurringEventMixin],

		components: { ClientAvatar },

		data: function () {
			return {
				recurringInvoices: [],
				DateTime: DateTime,
				showInvoices: false,
			};
		},

		mounted() {
			this.loadRecurringInvoices();
		},

		beforeDestroy() {},

		methods: {
			loadRecurringInvoices: function () {
				this.getRecurringInvoices(this.client ? this.client.id : null).then((res) => {
					this.recurringInvoices.splice(0, this.recurringInvoices.length);
					this.recurringInvoices.push(...res.data);
					this.recurringInvoices.forEach((r) => (r.client = this.$store.getters.getClientById(r.invoice.clientId)));
				});
			},

			createRecurringInvoice: function (clientId) {
				this.openInvoice(null, clientId);
			},

			openInvoice: function (id, clientId) {
				let binding = {
					clientId: clientId,
					id: id,
				};
				this.$store.state.globalModalController.openModal(RecurringInvoiceDetails, binding, false, true).then((res) => {
					if (res) {
						let ix = this.recurringInvoices.findIndex((r) => r.id === id);

						if (ix > -1) {
							this.recurringInvoices.splice(ix, 1);
						}

						this.showInvoices = true;

						if (!res.deleted) {
							res.client = this.$store.getters.getClientById(res.invoice.clientId);
							this.recurringInvoices.push(res);
						}
					}
				});
			},

			includedLabel: function (item) {
				let result;
				if (item.allProjects && item.clientHours) {
					result = 'all projects and client hours';
				} else if (item.clientHours && !item.allProjects) {
					result = 'all client hours';
					if (item.includedProjects.length) {
						result = result + ` and ${item.includedProjects.length} projects`;
					}
				} else if (item.allProjects && !item.clientHours) {
					result = 'all projects';
				} else if (!item.allProjects && item.includedProjects.length) {
					result = `${item.includedProjects.length} projects`;
				}

				if (result) {
					return 'Plus ' + result;
				} else {
					return null;
				}
			},
		},

		watch: {
			recurringInvoices: function(val){
				this.$emit('record-count',val.length);
			}
		},

		computed: {
			filteredList: function () {
				let result = [...this.recurringInvoices];

				result.forEach(r => {
					if(r.endDate){
						let endDate = DateTime.fromISO(r.endDate);
						let nextRunTime = DateTime.fromISO(r.nextRunTime);
						if(endDate < nextRunTime){
							r.nextRunTime = null;
							r.status = 'ENDED'
						}
					}
				})

				if (this.filter) {
					return result.filter((i) => {
						if (this.filter.clientList && this.filter.clientList.length > 0) {
							return this.filter.clientList.includes(i.invoice.clientId);
						} else {
							return true;
						}
					});
				} else {
					return result;
				}
			},

			headers: function () {
				let headers = [
					{ text: 'Total', value: 'amount', class: 'nowrap' },
					{ text: 'Desc', value: 'name', class: 'nowrap' },
					{ text: this.$t('recurring.start-date'), value: 'startDate' },
					{ text: this.$t('recurring.repeat-every'), value: 'interval' },
					{ text: this.$t('recurring.next-run-time'), value: 'nextRunTime' },
					{ text: this.$t('invoice.status'), value: 'status' },
				];

				if (!this.client) {
					headers.splice(0, 0, { text: this.$t('global.client'), value: 'client' });
				}

				return headers;
			},
		},
	};
</script>

<style scoped lang="scss"></style>
