import HttpClient from '@/services/HttpClient';


export default class EmailLogService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient(null,true,1);
	}

	getEmailLogs(earliest, latest, recordType="SENT_LOG") {
		let params = {
			earliest: earliest,
			latest: latest,
			recordType: recordType
		};

		return this.httpClient
			.get('/emailLogs', { params: params })
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteEmailLog(id){
		return this.httpClient
			.delete(`/emailLogs/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEmailContent(id){
		return this.httpClient
			.get(`/emailLogs/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getEmailAttachment(id,attachmentName){
		let params = {
			attachmentName: attachmentName
		};
		return this.httpClient
			.get(`/emailLogs/${id}/attachment`, {
				params: params,
				responseType: 'blob',
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
