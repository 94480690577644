<template>
	<div>
		<v-btn
			elevation="0"
			width="400"
			style="height: 40px !important; background-color: var(--v-white-base)"
			outlined
			color="gray_30"
			@click="signInWitMicrosoft"
		>
			<div class="row-format centered" style="width: 100%">
				<v-icon :size="20">$microsoft</v-icon>
				<div class="ml-2 font-gray_90 font-14">{{ microsoftButton }}</div>
			</div>
		</v-btn>
	</div>
</template>

<script>
	import { PublicClientApplication } from '@azure/msal-browser';
	import AuthService from '@/modules/auth/AuthService';
	import SignUpService from '@/modules/auth/SignUpService';

	export default {
		name: 'MicrosoftSignIn',

		props: ['isLogin', 'acceptTerms'],

		components: {},

		data: function() {
			return {
				config: {
					auth: {
						clientId: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
						redirectUri:
							document.location.protocol + '//' + document.location.host + '/integrations/microsoft/signin',
						cacheOption: 'localStorage',
					},
				},
				loginRequest: {
					scopes: ['User.Read'],
					prompt: 'select_account',
				},
				myMsal: null,
				inProcess: false,
			};
		},

		mounted() {
			this.myMsal = new PublicClientApplication(this.config);
			this.initClient();
		},

		beforeDestroy() {},

		methods: {
			initClient: function() {
				this.myMsal = new PublicClientApplication(this.config);
			},

			signInWitMicrosoft: function() {
				this.inProcess = true;
				this.myMsal
					.loginPopup(this.loginRequest)
					.then((res) => {
						if (this.isLogin) {
							this.handleSignInSuccess(res);
						} else {
							this.handleSignUpSuccess(res);
						}
					})
					.catch((err) => this.handleFailure(err))
					.finally(() => (this.inProcess = false));
			},

			runRecaptcha(result) {
				window.grecaptcha.ready(() => {
					window.grecaptcha
						.execute(process.env.VUE_APP_RECATPCHA_SITE_KEY, { action: 'submit' })
						.then((reCaptchaToken) => {
							if (this.isLogin) {
								this.handleSignInSuccess(result, reCaptchaToken);
							} else {
								this.handleSignUpSuccess(result, reCaptchaToken);
							}
						});
				});
			},

			handleFailure(failureResult) {
				console.log(failureResult);
				this.inProcess = false;

				if (this.isLogin) {
					this.$track.record('Microsoft sign in FAIL');
				} else {
					this.$track.record('Microsoft sign up FAIL');
				}
			},

			handleSignInSuccess(result, reCaptchaToken) {
				let url = document.location.protocol + '//' + document.location.host;
				url = url + '/magicToken';

				if (this.$store.state.origRequestedUri) {
					url = url + '?origUri=' + this.$store.state.origRequestedUri;
				}

				AuthService.loginMicrosoft(result.accessToken, reCaptchaToken, url)
					.then((result) => {
						this.$emit('success', {
							result: result,
							source: 'Microsoft',
						});
					})
					.catch((error) => {
						console.log(error);

						if (error.response.status === 412) {
							this.emitError(
								`Your Microsoft account is not currently linked with this email.  Please click the link in the email we just sent to <b>${error.response.data.message}</b> to connect your Moxie and Microsoft user accounts.`,
								this.$t('login.sign-up-button'),
								this.$t('login.sign-in-button')
							);
						} else {
							this.$track.record('Microsoft sign in FAIL');
							this.emitError(
								this.$t('login.sign-in.invalid-microsoft-account'),
								this.$t('login.sign-up-button'),
								this.$t('login.sign-in-button')
							);
						}
					});
			},

			handleSignUpSuccess(result, reCaptchaToken) {
				this.$store.commit('startLoading');

				let url = document.location.protocol + '//' + document.location.host;
				url = url + '/magicToken';
				url = url + '?origUri=/welcome';

				SignUpService.signupWithMicrosoft(result.accessToken, reCaptchaToken, url)
					.then((result) => {
						this.$store.commit('logIn', result);

						AuthService.getProfile().then((res) => {
							this.$store.state.inWelcomeFlow = true;
							this.$store.state.newUser = true;
							this.$store.dispatch('setLoggedInUser', res);
							this.$track.identify(res);
							this.$track.conversion();
							setTimeout(() => this.$router.push('/welcome'), 500);
						});
					})
					.catch((error) => {
						if (error.response.status === 412) {
							this.emitError(
								`To continue, we must verify your Microsoft email account.  Please click the link in the email we just sent to <b>${error.response.data.message}</b> to connect your Moxie and Microsoft user accounts.`,
								this.$t('login.sign-up-button'),
								this.$t('login.sign-in-button')
							);
						} else {
							this.emitError(
								this.$t('login.sign-up.invalid-microsoft-account'),
								this.$t('login.sign-in-button'),
								this.$t('login.sign-up-button')
							);
						}
						console.log('Microsoft Error', error);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			emitError(errorMsg, errorButton, errorButtonSecondary) {
				let errorPayload = {
					errorMsg,
					errorButton,
					errorButtonSecondary,
				};
				this.$emit('error', errorPayload);
			},
		},

		computed: {
			microsoftButton: function() {
				return this.isLogin ? this.$t('login.sign-in.microsoft-button') : this.$t('login.sign-up.microsoft-button');
			},
		},
	};
</script>

<style scoped lang="scss"></style>
