var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode === "standard" || _vm.mode === "dense"
    ? _c(
        "div",
        {
          staticClass: "row-format align-center",
          staticStyle: { width: "100%" }
        },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              "persistent-placeholder": "",
              dense: _vm.mode === "dense",
              outlined: "",
              label: _vm.field.name
            },
            on: { input: _vm.handleChange },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function() {
                    return [
                      _vm.ourValue
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "material-symbols-rounded font-18 font-primary pointer",
                              on: {
                                click: function($event) {
                                  return _vm.openLink()
                                }
                              }
                            },
                            [_vm._v("open_in_new")]
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2017207721
            ),
            model: {
              value: _vm.ourValue,
              callback: function($$v) {
                _vm.ourValue = $$v
              },
              expression: "ourValue"
            }
          })
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass: "row-format align-center",
          staticStyle: { width: "100%" }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.ourValue,
                expression: "ourValue"
              }
            ],
            staticStyle: { color: "var(--v-black-base)", width: "100%" },
            attrs: { type: "url", placeholder: "--" },
            domProps: { value: _vm.ourValue },
            on: {
              change: _vm.handleChange,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.ourValue = $event.target.value
              }
            }
          }),
          _vm.ourValue
            ? _c(
                "span",
                {
                  staticClass:
                    "material-symbols-rounded font-18 font-primary pointer",
                  on: {
                    click: function($event) {
                      return _vm.openLink()
                    }
                  }
                },
                [_vm._v("open_in_new")]
              )
            : _vm._e()
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }