var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pa-0 ma-0",
      staticStyle: { "align-items": "start", overflow: "hidden" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", { attrs: { cols: "12", md: "4" } }, [
            _c(
              "div",
              { staticClass: "pr-4" },
              [
                _c("client-info", {
                  attrs: { client: _vm.client },
                  on: {
                    "edit-contact": function($event) {
                      return _vm.openContactEdit($event)
                    },
                    "create-contact": function($event) {
                      return _vm.openContactEdit({})
                    },
                    "edit-client": function($event) {
                      return _vm.$emit("edit-client")
                    },
                    "unlink-accounting": function($event) {
                      return _vm.$emit("unlink-accounting")
                    },
                    "send-email": function($event) {
                      return _vm.$emit("send-email", $event)
                    }
                  }
                }),
                _c("div", { staticClass: "font-gray_70 font-12 text-left" }, [
                  _vm._v("Files")
                ]),
                _c("file-attachments", {
                  attrs: {
                    "edit-mode": true,
                    files: _vm.client.files,
                    "file-service": _vm.attachmentService
                  }
                })
              ],
              1
            )
          ]),
          _c("v-col", { attrs: { cols: "12", md: "8", align: "center" } }, [
            _c(
              "div",
              [
                _vm.$store.getters.hasFeatureAccess("projects")
                  ? [
                      _c("div", { staticClass: "font-18 text-left mb-3" }, [
                        _vm._v("Your client projects")
                      ]),
                      _c("project-list", {
                        staticClass: "mb-8",
                        attrs: { client: _vm.client, projects: _vm.projects }
                      })
                    ]
                  : _vm._e(),
                _c("div", { staticClass: "font-18 text-left mb-3" }, [
                  _vm._v("Your client insights")
                ]),
                _c("client-insights", {
                  attrs: { client: _vm.client },
                  on: {
                    "time-clicked": function($event) {
                      return _vm.$emit("time-clicked")
                    },
                    "money-clicked": function($event) {
                      return _vm.$emit("money-clicked")
                    }
                  }
                })
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }