import HttpClient from '@/services/HttpClient';
import store from '@/store';

export default class AccountService {
	accountClient;
	userClient;

	constructor() {
		this.accountClient = new HttpClient();
		this.userClient = new HttpClient(store.getters.getPodUrl + 'api/me');
	}

	updateUserProfile(user) {
		return this.userClient
			.put('/profile', user)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteProfilePicture() {
		return this.userClient
			.delete('/profile/picture')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getCommunicationPreferences() {
		return this.accountClient
			.get('/communicationPreferences')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getCurrency() {
		return this.accountClient
			.get('/currency')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getAccount() {
		return this.accountClient
			.get('')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteAccount() {
		return this.accountClient
			.delete('')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteAccountById(accountId) {
		let podUrl = store.getters.getPodUrlForAccount(accountId);

		if(!podUrl){
			return Promise.reject(new Error('Cannot locate account pod'));
		}

		let client = new HttpClient(podUrl);
		return client.delete('')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateAccountByPodUrl(podUrl,account) {
		let client = new HttpClient(podUrl);
		return client.put('',account)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateAccount(account) {
		return this.accountClient
			.put('', account)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	patchAccount(patch) {
		return this.accountClient
			.patch('', patch)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteLogo(lightMode= true) {
		return this.accountClient
			.delete(`/logo?lightMode=${lightMode}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createSampleData(){
		return this.accountClient
			.post('/sampleData')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteSampleData() {
		return this.accountClient
			.delete('/sampleData')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	async getAccountUniqueUrl() {
		return this.accountClient
			.get('/uniqueUrl')
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	verifyUniqueUrl(url) {
		url = encodeURIComponent(url);
		return this.accountClient
			.get(`/uniqueUrl?urlPart=${url}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getLocales() {
		return this.accountClient
			.get(`/availableLocales`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	async ping(){
		return await this.accountClient.get('/ping');
	}
}
