<template>
	<div class="text-left column-format font-14" style="flex: 1 1 0; width:100%">
		<div class="column-format pt-1 pb-4 text-left">
			<div class="brand-bold font-18">Styles</div>
			<div class="font-14 font-gray_80">Page settings, fonts, and colors</div>
		</div>
		<div class="column-format gap-2 mt-2">
			<div class="row-format gap-2">
				<v-select
					hide-details
					dense
					outlined
					label="Page size"
					persistent-placeholder
					v-model="agreement.styles.pageSize"
					:items="pageSizes"
					item-text="label"
					item-value="value"
				>
				</v-select>
				<v-select
					v-if="agreement.styles.pageSize !== 'RESPONSIVE'"
					hide-details
					dense
					outlined
					label="Orientation"
					persistent-placeholder
					v-model="agreement.styles.landscape"
					:items="orientation"
					item-text="label"
					item-value="value"
				>
				</v-select>
			</div>
			<v-slider
				class="pr-3"
				v-model="agreement.styles.maxWidth"
				hide-details
				dense
				label="Max page width"
				:step="10"
				:min="200"
				:max="2048"
				thumb-label
				persistent-hint
				v-if="agreement.styles.pageSize === 'RESPONSIVE'"
			></v-slider>
			<div class="row-format gap-3">
				<color-selector v-model="agreement.styles.canvasColor" label="Canvas color"></color-selector>
				<color-selector v-model="agreement.styles.pageColor" label="Page color"></color-selector>
			</div>
			<v-switch v-model="agreement.styles.pageShadow" hide-details dense label="Page shadow" class="mt-0"></v-switch>
		</div>

		<hr class="my-2" style="background-color: var(--v-gray_50-base); height: 1px; border: 0;" />

		<div>
			<div class="brand-medium row-format pointer" @click="titleExpanded = !titleExpanded">
				Title Style
				<v-icon class="material-symbols-rounded ml-auto" color="black">{{
					titleExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
				}}</v-icon>
			</div>
			<font-style class="py-2" v-model="agreement.styles.h1" v-if="titleExpanded"></font-style>
		</div>
		<hr class="my-2" style="background-color: var(--v-gray_50-base); height: 1px; border: 0;" />
		<div>
			<div class="brand-medium row-format pointer" @click="headingExpanded = !headingExpanded">
				Heading Style
				<v-icon class="material-symbols-rounded ml-auto" color="black">{{
					headingExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
				}}</v-icon>
			</div>
			<font-style class="py-2" v-model="agreement.styles.h2" v-if="headingExpanded"></font-style>
		</div>
		<hr class="my-2" style="background-color: var(--v-gray_50-base); height: 1px; border: 0;" />
		<div>
			<div class="brand-medium row-format pointer" @click="subHeadingExpanded = !subHeadingExpanded">
				Sub-heading Style
				<v-icon class="material-symbols-rounded ml-auto" color="black">{{
					subHeadingExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
				}}</v-icon>
			</div>
			<font-style class="py-2" v-model="agreement.styles.h3" v-if="subHeadingExpanded"></font-style>
		</div>
		<hr class="my-2" style="background-color: var(--v-gray_50-base); height: 1px; border: 0;" />
		<div>
			<div class="brand-medium row-format pointer" @click="paragraphExpanded = !paragraphExpanded">
				Paragraph Style
				<v-icon class="material-symbols-rounded ml-auto" color="black">{{
					paragraphExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
				}}</v-icon>
			</div>
			<font-style class="py-2" v-model="agreement.styles.body" v-if="paragraphExpanded"></font-style>
		</div>
		<hr class="my-2" style="background-color: var(--v-gray_50-base); height: 1px; border: 0;" />
	</div>
</template>

<script>
	import FontStyle from '@/modules/agreements/config/FontStyle';
	import ColorSelector from '@/components/ColorSelector';
	export default {
		name: 'StylesConfig',

		props: ['agreement'],

		components: { FontStyle, ColorSelector },

		data: function() {
			return {
				titleExpanded: false,
				headingExpanded: false,
				subHeadingExpanded: false,
				paragraphExpanded: false,
				pageSizes: [
					{ label: 'US Letter', value: 'US_LETTER' },
					{ label: 'A4 Letter', value: 'A4_LETTER' },
					{ label: 'Responsive', value: 'RESPONSIVE' }
				],
				orientation: [
					{ label: 'Portrait', value: false },
					{ label: 'Landscape', value: true },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
