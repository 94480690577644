<template>
	<div class="fill-width">
		<table id="stages-table">
			<thead>
				<tr>
					<th style="width: 50px;"></th>
					<th>Label</th>
					<th>Stage type</th>
					<th>Highlight color</th>
					<th style="width: 50px;"></th>
				</tr>
			</thead>
			<draggable v-model="stageSettings.stages" group="options" tag="tbody" @change="saveSettings" handle=".drag" v-if="stageSettings">
				<tr v-for="stage in stageSettings.stages" :key="stage.id" class="font-14 text-left">
					<td style="width: 50px; text-align: center">
						<v-icon class="drag">drag_handle</v-icon>
					</td>
					<td>
						<div contenteditable="true" class="pa-1" @blur="setName(stage, $event)">
							{{ stage.label }}
						</div>
					</td>
					<td>
						<v-menu :close-on-click="true" :close-on-content-click="true">
							<template v-slot:activator="{ on }">
								<div v-on="on" class="pointer">{{ translateType(stage.stageType) }}</div>
							</template>
							<div style="background-color: var(--v-white-base)" class="text-left font-14 more-menu">
								<div
									v-for="type in typeList"
									:key="type.id"
									class="pl-3 py-2 pointer more-menu-item"
									@click="setType(stage, type.value)"
								>
									{{ type.label }}
								</div>
							</div>
						</v-menu>
					</td>
					<td>
						<v-menu :close-on-click="true" :close-on-content-click="true">
							<template v-slot:activator="{ on }">
								<div v-on="on" class="stage-color pointer" :style="`--stage-color:${stage.hexColor}`"></div>
							</template>
							<div id="color-picker-div">
								<v-color-picker
									flat
									mode="hexa"
									show-swatches
									hide-canvas
									hide-mode-switch
									hide-inputs
									class="mx-auto"
									swatches-max-height="200"
									:swatches="clientPalette.palette"
									@input="saveSettings()"
									v-model="stage.hexColor"
								></v-color-picker>
							</div>
						</v-menu>
					</td>
					<td style="text-align: center; width: 50px;">
						<v-icon class="material-symbols-rounded" size="20" @click="confirmDelete(stage)">delete</v-icon>
					</td>
				</tr>
			</draggable>
		</table>
	</div>
</template>

<script>
	import PipelineSettingsService from '@/modules/pipeline/settings/PipelineSettingsService';
	import ClientPalette from '@/modules/clients/ClientPalette';
	import draggable from "vuedraggable";
	import ConfirmModal from "@/components/ConfirmModal";
	import {v4 as uuidv4} from 'uuid';

	export default {
		name: 'Stages',

		props: [],

		components: {draggable,},

		data: function() {
			return {
				stageSettings: null,
				clientPalette: new ClientPalette(),
				settingsService: new PipelineSettingsService(),
			};
		},

		mounted() {
			this.$store.state.eventBus.$on('account-changed', this.getStages);
			this.getStages();
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off('account-changed', this.getStages);
		},

		methods: {
			getStages: function() {
				this.stageSettings = null;
				this.settingsService.getPipelineStages().then((res) => {
					this.stageSettings = res.data;
				});
			},

			setName: function(stage, event) {
				if (event.target && event.target.innerText) {
					stage.label = event.target.innerText;
					event.target.innerText = stage.label;
					this.saveSettings();
				}
			},

			setType: function(stage, type) {
				stage.stageType = type;
				this.saveSettings();
			},

			addStage: function(){
				let stage = {
					id: uuidv4(),
					label: '[New stage]',
					hexColor: this.clientPalette.getRandomColor(this.stageSettings.stages.map(s => s.hexColor)),
					stageType: 'InProgress',
					automations: []
				}
				this.stageSettings.stages.push(stage);
				this.saveSettings();
			},

			saveSettings: function(){
				this.settingsService.updatePipelineStages(this.stageSettings);
			},

			confirmDelete: function(stage){
				let binding = {
					headingText: 'Delete stage?',
					bodyText: `Are you sure you want to delete the ${stage.label} stage?`
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						let ix = this.stageSettings.stages.findIndex(s => s.id == stage.id);
						this.stageSettings.stages.splice(ix,1);
						this.saveSettings();
					}
				})
			},

			translateType: function(type) {
				return this.typeMap.get(type);
			},
		},

		computed: {
			typeMap: function() {
				let types = new Map();
				types.set('New', 'New');
				types.set('InProgress', 'In progress');
				types.set('OnHold', 'On hold');
				types.set('ClosedWon', 'Closed won');
				types.set('ClosedLost', 'Closed lost');
				types.set('Complete', 'Complete');
				return types;
			},

			typeList: function() {
				let result = [];
				this.typeMap.forEach((value, key) => {
					result.push({
						label: value,
						value: key,
					});
				});
				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.drag {
		cursor: grab;
		&:active {
			cursor: grabbing;
		}
	}

	#stages-table {
		text-align: left;
		border-collapse: collapse;
		width: 100%;

		.heading {
			padding: 8px;
			font-size: 12px;
			font-weight: 600;
			margin: 0;
			border-bottom: 1px solid var(--v-gray_20-base);
			border-right: 1px solid var(--v-gray_20-base);

			&:first-of-type {
				padding-left: 20px !important;
				border-left: 1px solid var(--v-gray_20-base);
			}

			&:last-of-type {
				padding-right: 12px !important;
			}
		}

		.stage-color {
			background-color: var(--stage-color);
			width: 100%;
			height: 20px;
			min-width: 100%;
			min-height: 20px;
			border-radius: 4px;
		}

		thead {
			tr {
				th {
					padding: 8px;
					font-size: 12px;
					font-weight: 600;
					margin: 0;
					border-top: 1px solid var(--v-gray_20-base);
					border-bottom: 1px solid var(--v-gray_20-base);
					border-right: 1px solid var(--v-gray_20-base);

					&:first-of-type {
						border-left: 1px solid var(--v-gray_20-base);
					}
				}
			}
		}

		tbody {
			tr {
				td {
					padding: 8px;
					margin: 0px;
					border-bottom: 1px solid var(--v-gray_20-base);
					border-right: 1px solid var(--v-gray_20-base);

					&:first-of-type {
						border-left: 1px solid var(--v-gray_20-base);
					}
				}

				&:hover {
					td {
						background-color: var(--v-gray_10-base);
					}
				}
			}
		}
	}
</style>
