<template>
	<div class="modal-padding">
		<v-container fluid class="leadgen-modal">
			<v-row>
				<v-col cols="4" class="text-left pl-0">
					<!-- //* Actions -->
					<div class="body-16 brand-semibold black--text">
						<v-icon color="black" left>$actions</v-icon>{{ $t('global.actions') }}
					</div>

					<!-- //* Next Phase -->
					<div v-if="actions">
						<div class="body-12 grey--text mt-6 mb-1">Next phase</div>
						<div
							class="mt-4 grey-action brand-semibold pointer"
							v-for="action in actions"
							:key="action.name"
							@click="$emit('action-clicked', $event, action.name, card)"
						>
							{{ $t(action.label) }}
						</div>
					</div>

					<!-- //* Admin -->
					<div v-if="showAdminActions">
						<div class="body-12 grey--text mt-6 mb-1">Admin</div>
						<div
							class="mt-4 grey-action brand-semibold pointer"
							v-for="action in adminActions"
							:key="action.name"
							@click="$emit('action-clicked', $event, action.name, card)"
						>
							{{ $t(action.label) }}
						</div>
					</div>
					<!-- //* activity (TABLED FOR NOW)
				<div class="body-16 brand-semibold black--text">
					<v-icon color="black" left>$discovery</v-icon>{{ $t('global.activity') }}
				</div>-->
				</v-col>
				<v-col cols="8" v-if="client !== null" class="text-left pr-0">
					<div class="font-18 brand-medium d-flex justify-start align-center gap-2">
						<client-avatar :client="card.client" />
						<div>{{ card.client.name }}</div>
					</div>
					<div class="body-12 mt-2">
						<span v-if="card.contact">
							<span class="mr-2">{{ $t('leadgen.primary-contact') }}</span>
							<span class="brand-semibold mr-4">{{ card.contact.firstName + ' ' + card.contact.lastName }}</span>
						</span>
						<span class="mr-2">{{ $t('leadgen.phase') }}</span>
						<span :class="'phase ' + phaseColor">{{ card.cardType }}</span>
					</div>
					<notes-embed v-if="clientIsSet" :client-id="client.id"></notes-embed>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import ClientService from '@/modules/clients/ClientService';
	import NotesEmbed from '@/components/NotesEmbed';
	import LeadGenRules from './LeadGenRules.js';
	import ClientAvatar from '@/components/ClientAvatar';

	export default {
		name: 'LeadGenModal',
		components: {
			NotesEmbed,
			ClientAvatar,
		},
		props: { card: { type: Object, required: true } },

		data() {
			return {
				clientService: new ClientService(),
				leadGenRules: new LeadGenRules(this.card.cardType),
				client: null,
				actions: null,
				adminActions: null,
			};
		},

		mounted() {
			this.getClientDetail();

			this.actions = this.leadGenRules.getActions();
			this.adminActions = this.leadGenRules.getAdminActions();
			console.log(this.adminActions);
			if (this.card.cardType === 'Proposal' && this.card.proposal) {
				this.actions = this.leadGenRules.filterProposalActions(this.actions, this.card.proposal.proposalStatus);
				this.adminActions = this.leadGenRules.filterProposalActions(this.adminActions, this.card.proposal.proposalStatus);
			}
		},

		methods: {
			getClientDetail() {
				if (this.card.client) {
					this.clientService
						.getClientDetail(this.card.client.id)
						.then((res) => {
							this.client = res.data;
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
						});
				}
			},
		},
		computed: {
			clientIsSet: function() {
				return this.client !== null;
			},

			isReady: function() {
				return this.card !== null;
			},

			phaseColor: function() {
				return this.leadGenRules.getPhaseColor(this.card.cardType);
			},

			showAdminActions: function() {
				if (this.adminActions == null) return false;
				if (this.adminActions.length) return true;
				return false;
			},
		},
	};
</script>

<style lang="scss">
	.leadgen-modal {
		margin: 0 0 8px;
		padding: 0 !important;
		.phase {
			display: inline;
			padding: 4px 8px;
			font-size: 14px;
			line-height: 24px;
			font-weight: 600;
			letter-spacing: 0.4px;
			height: 32px;
			border-radius: 8px;
			text-align: left;
			transition: background-color 500;
			color: var(--v-black-base);
			/* span.column-head-label {
				transition: color 200ms;
			} */
			&.regent_20 {
				background-color: var(-v-regent_20-base);
			}
			&.dodger_20 {
				background-color: var(-v-dodger_20-base);
			}
			&.bright_sun_20 {
				background-color: var(-v-bright_sun_20-base);
			}
			&.celery_20 {
				background-color: var(-v-celery_20-base);
			}
			&.burnt_sienna_20 {
				background-color: var(-v-burnt_sienna_20-base);
			}
			&.white {
				background-color: var(-v-white-base);
			}
		}
	}
</style>
