var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format gap-3" },
    [
      _c("font-selector", {
        attrs: { label: "Font", font: _vm.element.font },
        on: {
          "font-changed": function($event) {
            return _vm.fontUpdated($event)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "column-format" },
        [
          _c("div", { staticClass: "font-14 text-left font-gray_70" }, [
            _vm._v("Background color")
          ]),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-click": false,
                "close-on-content-click": false,
                bottom: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "div",
                        _vm._g(
                          {
                            staticClass: "pointer",
                            style:
                              "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                              _vm.element.backgroundColor
                          },
                          on
                        ),
                        [_vm._v("   ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.backgroundColorMenu,
                callback: function($$v) {
                  _vm.backgroundColorMenu = $$v
                },
                expression: "backgroundColorMenu"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "ma-3 column-format align-center gap-3",
                  staticStyle: { "background-color": "var(--v-white-base)" }
                },
                [
                  _c("v-color-picker", {
                    attrs: {
                      mode: "hexa",
                      "show-swatches": "",
                      "swatches-max-height": "150px"
                    },
                    on: {
                      input: function($event) {
                        return _vm.updated()
                      }
                    },
                    model: {
                      value: _vm.element.backgroundColor,
                      callback: function($$v) {
                        _vm.$set(_vm.element, "backgroundColor", $$v)
                      },
                      expression: "element.backgroundColor"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          _vm.backgroundColorMenu = false
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "font-14 text-left font-gray_70 mt-1" }, [
            _vm._v("Page color")
          ]),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-click": false,
                "close-on-content-click": false,
                bottom: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "div",
                        _vm._g(
                          {
                            staticClass: "pointer",
                            style:
                              "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                              _vm.element.pageColor
                          },
                          on
                        ),
                        [_vm._v("   ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.pageColorMenu,
                callback: function($$v) {
                  _vm.pageColorMenu = $$v
                },
                expression: "pageColorMenu"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "ma-3 column-format align-center gap-3",
                  staticStyle: { "background-color": "var(--v-white-base)" }
                },
                [
                  _c("v-color-picker", {
                    attrs: {
                      mode: "hexa",
                      "show-swatches": "",
                      "swatches-max-height": "150px"
                    },
                    on: {
                      input: function($event) {
                        return _vm.updated()
                      }
                    },
                    model: {
                      value: _vm.element.pageColor,
                      callback: function($$v) {
                        _vm.$set(_vm.element, "pageColor", $$v)
                      },
                      expression: "element.pageColor"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          _vm.pageColorMenu = false
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ],
                1
              )
            ]
          ),
          _c("div", { staticClass: "font-14 text-left ont-gray_70 mt-1" }, [
            _vm._v("Font color")
          ]),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-click": false,
                "close-on-content-click": false,
                bottom: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "div",
                        _vm._g(
                          {
                            staticClass: "pointer",
                            style:
                              "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                              _vm.element.fontColor
                          },
                          on
                        ),
                        [_vm._v("   ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.fontColorMenu,
                callback: function($$v) {
                  _vm.fontColorMenu = $$v
                },
                expression: "fontColorMenu"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "ma-3 column-format align-center gap-3",
                  staticStyle: { "background-color": "var(--v-white-base)" }
                },
                [
                  _c("v-color-picker", {
                    attrs: {
                      mode: "hexa",
                      "show-swatches": "",
                      "swatches-max-height": "150px"
                    },
                    on: {
                      input: function($event) {
                        return _vm.updated()
                      }
                    },
                    model: {
                      value: _vm.element.fontColor,
                      callback: function($$v) {
                        _vm.$set(_vm.element, "fontColor", $$v)
                      },
                      expression: "element.fontColor"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          _vm.fontColorMenu = false
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "font-14 text-left mb-n3 font-gray_70" }, [
        _vm._v("Page max width (" + _vm._s(_vm.element.maxWidth) + "px)")
      ]),
      _c("v-slider", {
        attrs: {
          min: 0,
          max: 1200,
          step: 10,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.maxWidth,
          callback: function($$v) {
            _vm.$set(_vm.element, "maxWidth", $$v)
          },
          expression: "element.maxWidth"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }