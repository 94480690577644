var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "mock-cancel",
      style:
        "max-width: 900px; font-family: '" +
        _vm.meeting.pageLayout.fontFamily +
        "'; background-color: " +
        _vm.meeting.pageLayout.backgroundMainColor,
      attrs: { id: "solid-hr" }
    },
    [
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _vm.meeting.pageLayout.showLogo
              ? _c("div", { staticClass: "pb-6" }, [
                  _c("img", {
                    staticStyle: { "max-width": "300px" },
                    attrs: { src: this.$store.getters.getAccountLogo }
                  })
                ])
              : _vm._e()
          ]),
          _c(
            "v-col",
            { staticStyle: { "max-width": "500px" }, attrs: { cols: "12" } },
            [
              _c(
                "div",
                {
                  staticClass: "px-4 text-left form-content",
                  on: {
                    click: function($event) {
                      return _vm.activateEditor(true)
                    }
                  }
                },
                [
                  _vm.editorActive
                    ? _c("editor", {
                        ref: "SchedulerTextEditor",
                        staticStyle: { "max-width": "500px" },
                        attrs: {
                          "api-key": _vm.$store.getters.getTinyMceKey,
                          inline: true,
                          init: _vm.mceConfigText
                        },
                        on: {
                          onblur: function($event) {
                            return _vm.activateEditor(false)
                          }
                        },
                        model: {
                          value: _vm.meeting.cancelMeeting.cancelInfo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.meeting.cancelMeeting,
                              "cancelInfo",
                              $$v
                            )
                          },
                          expression: "meeting.cancelMeeting.cancelInfo"
                        }
                      })
                    : _c("div", {
                        staticStyle: {
                          "max-width": "900px",
                          "line-height": "1.8"
                        },
                        domProps: {
                          innerHTML: _vm._s(_vm.tokenizedText(_vm.meeting))
                        }
                      })
                ],
                1
              )
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "mt-3",
              staticStyle: { "max-width": "500px" },
              attrs: { cols: "12" }
            },
            [
              _c(
                "div",
                [
                  _c("v-textarea", {
                    style:
                      "--text-contrast: " +
                      _vm.textContrast +
                      "; font-size: 14px",
                    attrs: {
                      outlined: "",
                      readonly: "",
                      "persistent-placeholder": "",
                      rows: "3"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _c(
                              "span",
                              { style: "color: " + _vm.textContrast },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.meeting.cancelMeeting
                                      .cancellationReasonText
                                  )
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      staticStyle: { "text-transform": "none !important" },
                      attrs: { elevation: "0", color: _vm.lightAccent }
                    },
                    [
                      _c(
                        "span",
                        {
                          style:
                            "letter-spacing:0.3px; color:" +
                            _vm.meeting.pageLayout.accentColor +
                            "; font-weight:800"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.meeting.cancelMeeting.cancelText) +
                              " "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }