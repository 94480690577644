<template>
	<div class="header-wrapper">
		<div :class="'list-view-header' + (borderBottom ? ' border-bottom' : '')">
			<div class="left-side" v-if="title">
				<h2>{{ title }}<v-icon v-if="helpLink" @click="viewHelp" small color="primary" class="ml-1 mb-1 pointer" v-tippy="{content:'View documentation & help'}">help</v-icon></h2>
			</div>
			<div :class="dontWrap ? 'page-tabs-nowrap' : 'page-tabs'">
				<div
					v-for="(page, i) in pages"
					:key="i"
					:class="'page-tab' + (page.value === currentPage ? ' current-page' : '')"
					@click="handlePageClick(page)"
				>
					{{ page.label }}
				</div>
				<slot name="additional"></slot>
			</div>
			<div class="right-side">
				<div class="filter"><slot name="filter"></slot></div>
				<div class="add-new" v-if="addNewLabel || showMenu">
					<template v-if="showMenu">
						<v-menu
							:nudge-bottom="0"
							:elevation="0"
							content-class="add-new-menu"
							bottom
							left
							rounded
							offset-overflow
							offset-y
							v-model="showMenuModel"
							transition="slide-y-transition"
							:disabled="newButtonDisabled"
						>
							<template v-slot:activator="menu">
								<v-btn
									class="primary-action"
									style="min-width: 120px"
									small
									elevation="0"
									v-bind="menu.attrs"
									v-on="menu.on"
								>
									<div>{{ addNewLabel }}</div>
									<v-icon :size="16" right>$arrowDown</v-icon>
								</v-btn>
							</template>
							<div class="add-new-dropdown">
								<div
									v-for="(item, index) in addNewItems"
									:key="index"
									@click="handleAddNewItem(item)"
									class="add-new-item nowrap"
								>
									{{ item.label }}
								</div>
							</div>
						</v-menu>
					</template>
					<v-btn
						v-else
						class="add-new-button primary-action"
						small
						elevation="0"
						@click="handleAddNew()"
						:disabled="newButtonDisabled"
						style="min-width: 120px"
					>
						{{ addNewLabel }}
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { isEmpty } from 'lodash';

	export default {
		name: 'HHeader',
		props: {
			title: { type: String, required: false, default: ''},
			addNewLabel: { type: String, required: false },
			addNewItems: { type: Array, required: false },
			pages: { type: Array, required: false, default: () => [] },
			currentPage: { type: String, required: false, default: '' },
			borderBottom: { type: Boolean, required: false, default: true },
			newButtonDisabled: { type: Boolean, required: false, default: false },
			helpLink: {type: String, required: false, default: null},
			dontWrap: {type: Boolean, required: false, default: false}
		},
		emits: ['add-new', 'set-page'],
		components: {},
		data() {
			return {
				showMenuModel: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleAddNewItem(item) {
				this.$emit('add-new', item.value);
			},
			handleAddNew() {
				this.$emit('add-new', true);
			},
			handlePageClick(page) {
				this.$emit('set-page', page.value);
			},
			viewHelp: function(){
				window.open(this.helpLink,'_blank');
			}
		},

		computed: {
			showMenu() {
				return !isEmpty(this.addNewItems);
			},
		},
	};
</script>

<style scoped lang="scss">
	@import '~vuetify/src/styles/settings/_variables';

	.header-wrapper {
		position: sticky;
		background-color: var(--v-white-base);
		width: 100%;
		top: 0;
		right: 0;
		z-index: 1;
		//border: 1px solid red;
		padding: 0 12px 0;
	}
	.list-view-header {
		//border: 1px solid green;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		min-height: 55px;
		&.border-bottom {
			border-bottom: 1px solid var(--v-gray_50-base);
		}
		.left-side {
			//display: flex;
			//align-items: flex-start;
			//justify-content: flex-start;
			//flex-wrap: wrap;
			flex: 0 1 auto;
			h2 {
				margin: 14px 28px 0 12px;
			}
		}

		.page-tabs {
			font-size: 14px;
			margin-top: 5px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex: 1 0 auto;
			@media #{map-get($display-breakpoints, 'sm-and-down')} {
				flex: 0 0 100%;
				order: 3;
			}
		}

		.page-tabs-nowrap {
			font-size: 14px;
			margin-top: 5px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex: 1 0 auto;
		}

		.page-tab {
			&:first-child {
				margin: 0 0 0 12px;
				//margin: 0;
			}
			&:not(:first-child) {
				margin: 0 0 0 28px;
			}
			cursor: pointer;
			padding: 13px 0 16px;
			color: var(--v-gray_80-base);
			position: relative;

			&.current-page {
				cursor: default !important;
				color: var(--v-primary-base) !important;
				position: relative;
				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 4px;
					border-radius: 2px 2px 0 0;
					background: var(--v-primary-base) !important;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}

			&:hover {
				color: var(--v-black-base);
			}

			&:hover::after {
				content: '';
				display: block;
				width: 100%;
				height: 4px;
				border-radius: 2px 2px 0 0;
				background: var(--v-gray_50-base);
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}

		.right-side {
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			//flex-wrap: wrap;
			.filter,
			.add-new {
				margin-top: 12px;
			}
			.add-new {
				margin-left: 12px;
			}
		}
	}
</style>
