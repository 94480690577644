var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "" + (_vm.active ? "form-item-active" : "form-item-inactive"),
      style:
        "color: " +
        _vm.styles.fontColor +
        "!important; font-family: " +
        _vm.styles.font +
        "!important"
    },
    [
      _c(_vm.item.type, {
        key: _vm.item.type,
        ref: "component",
        tag: "component",
        class: _vm.item.type !== "Container" ? "pb-3" : "",
        attrs: {
          item: _vm.item,
          styles: _vm.styles,
          font: _vm.font,
          active: _vm.active,
          "edit-mode": _vm.editMode,
          "account-logo": _vm.accountLogo,
          index: _vm.index,
          tokens: _vm.tokens,
          invoice: _vm.invoice,
          "token-map": _vm.tokenMap,
          "in-builder": _vm.inBuilder,
          "deposit-account": _vm.depositAccount,
          "has-expenses": _vm.hasExpenses,
          recurring: _vm.recurring,
          "payment-plan": _vm.paymentPlan,
          "invoice-service": _vm.invoiceService,
          "attachment-service": _vm.attachmentService,
          "scheduled-payments": _vm.scheduledPayments
        },
        on: {
          click: function($event) {
            return _vm.$emit("click", $event)
          },
          delete: function($event) {
            return _vm.$emit("delete")
          },
          change: function($event) {
            return _vm.$emit("change", $event)
          },
          "container-item-click": function($event) {
            return _vm.$emit("container-item-click", $event)
          },
          "container-item-update": function($event) {
            return _vm.$emit("container-item-update", $event)
          },
          "add-container-element": function($event) {
            return _vm.$emit("add-container-element", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }