var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "project-card column-format text-left",
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "row-format" },
        [
          _c("client-avatar", {
            attrs: { large: "", client: _vm.project.client }
          }),
          _c(
            "div",
            {
              staticClass: "column-format text-left ml-2",
              staticStyle: { "line-height": "24px", "margin-top": "-4px" }
            },
            [
              _c("div", { staticClass: "font-14 font-gray_70" }, [
                _vm._v(_vm._s(_vm.project.client.name))
              ]),
              _c("div", { staticClass: "font-18 font-primary" }, [
                _vm._v(_vm._s(_vm.project.name))
              ])
            ]
          ),
          _vm.isFullUser
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": true,
                    "close-on-click": true,
                    bottom: "",
                    "nudge-bottom": "30"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                { staticClass: "ml-auto", attrs: { icon: "" } },
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("$moreHorizontal")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    844038165
                  )
                },
                [
                  _c("div", { staticClass: "more-menu" }, [
                    _c(
                      "div",
                      {
                        staticClass: "more-menu-item",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("click")
                          }
                        }
                      },
                      [_vm._v("View details")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "more-menu-item",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("edit")
                          }
                        }
                      },
                      [_vm._v("Edit")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "more-menu-item",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("mark-complete")
                          }
                        }
                      },
                      [_vm._v("Mark complete")]
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isFullUser
        ? _c("div", { staticClass: "mt-4 mb-2" }, [
            _c("div", { staticClass: "font-14 font-gray_70" }, [
              _vm._v(_vm._s(_vm.project.fees))
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "column-format mt-auto" }, [
        _c(
          "div",
          {
            staticClass: "row-format",
            staticStyle: {
              "align-items": "flex-end",
              "justify-content": "space-between"
            }
          },
          [
            _c("div", [
              _vm.project.nextDueDate
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tippy",
                          rawName: "v-tippy",
                          value: { content: _vm.project.nextDueDate.name },
                          expression: "{content: project.nextDueDate.name}"
                        }
                      ],
                      staticClass: "text-left font-14",
                      staticStyle: { width: "fit-content" }
                    },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.project.nextDueDate.date))]),
                      _c("div", { staticClass: "font-gray_70 font-12" }, [
                        _vm._v("Next due")
                      ])
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "text-left font-14",
                      staticStyle: { width: "fit-content" }
                    },
                    [
                      _c("div", [_vm._v("--")]),
                      _c("div", { staticClass: "font-gray_70 font-12" }, [
                        _vm._v("Next due")
                      ])
                    ]
                  )
            ]),
            _vm.collaborators.length
              ? _c("div", { staticClass: "column-format" }, [
                  _c(
                    "div",
                    { staticClass: "row-format align-center pl-2" },
                    _vm._l(_vm.collaborators, function(collaborator, index) {
                      return _c("assigned-user", {
                        key: collaborator.userId,
                        staticClass: "ml-n2",
                        style:
                          "border-radius: 100px; border: 2px solid var(--v-white-base); z-index: " +
                          (5 - index),
                        attrs: {
                          "assigned-to": collaborator.userId,
                          "show-name": false
                        }
                      })
                    }),
                    1
                  ),
                  _c("div", { staticClass: "font-14 font-gray_70" }, [
                    _vm._v("Collaborators")
                  ])
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass: "mt-6",
            staticStyle: { "max-height": "8px", height: "8px" }
          },
          [
            _vm.project.deliverables.length
              ? _c("deliverable-by-status", {
                  attrs: {
                    project: _vm.project,
                    deliverables: _vm.project.deliverables
                  }
                })
              : _c("div", {
                  directives: [
                    {
                      name: "tippy",
                      rawName: "v-tippy",
                      value: {
                        placement: "top",
                        arrow: true,
                        arrowType: "round",
                        animation: "fade",
                        delay: 50,
                        boundary: "window"
                      },
                      expression:
                        "{placement : 'top',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window' }"
                    }
                  ],
                  staticClass: "mt-1",
                  staticStyle: {
                    height: "100%",
                    width: "100%",
                    "border-radius": "2px",
                    "background-color": "var(--v-gray_20-base)"
                  },
                  attrs: { content: "No tasks" }
                })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }