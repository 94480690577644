var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "notification-layout",
    {
      attrs: { id: _vm.id, "sent-at": _vm.sentAt },
      on: {
        action: function($event) {
          return _vm.tryNavigation()
        }
      }
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "v-icon",
            { staticClass: "mr-1", attrs: { color: "primary", small: "" } },
            [_vm._v("$alert")]
          ),
          _c("div", [_vm._v(_vm._s(_vm.notificationPayload.heading))])
        ],
        1
      ),
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "font-12" }, [
          _c("span", [
            _vm._v(" " + _vm._s(_vm.notificationPayload.message) + " ")
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }