var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "edit-fee-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "invoice.details.item-price-modal." + this.type + ".heading"
                  )
                ) +
                " "
            )
          ]),
          _vm.invoiceItem.type !== "DEPOSIT" &&
          _vm.invoiceItem.type !== "DELIVERABLE"
            ? [
                _c("div", {
                  staticClass: "modal-subtitle",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t(
                        "invoice.details.item-price-modal." +
                          this.type +
                          ".subheading"
                      )
                    )
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "h-outline mb-2 d-flex justify-start align-center pa-2"
                  },
                  [
                    _c("v-simple-checkbox", {
                      attrs: { dense: "" },
                      model: {
                        value: _vm.updateProjectToo,
                        callback: function($$v) {
                          _vm.updateProjectToo = $$v
                        },
                        expression: "updateProjectToo"
                      }
                    }),
                    _c("label", { staticClass: "font-14" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "invoice.details.item-price-modal." +
                              _vm.type +
                              ".update-project-terms"
                          )
                        )
                      )
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "text-left font-14" }, [
                  _c("span", { staticClass: "brand-semibold gray_80--text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "invoice.details.item-price-modal." +
                            this.type +
                            ".rate-type"
                        )
                      ) + ": "
                    )
                  ]),
                  _c("span", { staticClass: "brand-semilight gray_90--text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "invoice.details.item-type." + _vm.invoiceItem.type
                        )
                      )
                    )
                  ])
                ]),
                _vm.type === "project"
                  ? _c(
                      "div",
                      { staticClass: "text-left font-12 gray_80--text" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "invoice.details.item-price-modal.project.rate-type-notice"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.invoiceItem.type === "PROJECT"
            ? _c(
                "div",
                { staticClass: "height-control" },
                [
                  _c("v-text-field", {
                    staticClass: "h-outline",
                    attrs: {
                      "persistent-placeholder": "",
                      "hide-details": "",
                      label: _vm.$t(
                        "invoice.details.item-price-modal." + this.type + ".qty"
                      ),
                      type: "number",
                      min: "0"
                    },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleSave.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.invoiceItem.qty,
                      callback: function($$v) {
                        _vm.$set(_vm.invoiceItem, "qty", $$v)
                      },
                      expression: "invoiceItem.qty"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "height-control" },
            [
              _c("v-text-field", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  label: _vm.$t(
                    "invoice.details.item-price-modal." + this.type + ".fee"
                  ),
                  prefix: _vm.currencySymbol,
                  type: "number",
                  min: "0"
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSave.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.invoiceItem.price,
                  callback: function($$v) {
                    _vm.$set(_vm.invoiceItem, "price", $$v)
                  },
                  expression: "invoiceItem.price"
                }
              })
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "px-16 primary-action",
              on: { click: _vm.handleSave }
            },
            [_vm._v(_vm._s(_vm.$t("global.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }