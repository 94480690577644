var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.deliverable
    ? _c("kanban-card", {
        staticClass: "kanban-card",
        attrs: { "show-status": true, deliverable: _vm.deliverable }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }