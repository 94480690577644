import HttpClient from "@/services/HttpClient";

export default class CustomEmailService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getCustomEmail() {
		return this.httpClient
			.get(`/customEmail`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateCustomEmail(customEmail) {
		return this.httpClient
			.put(`/customEmail`,customEmail)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

  updateCustomEmailFromName(emailSettings) {
    let fromName = {
      fromName: emailSettings.fromEmail,
      signature: emailSettings.signature
    }

    return this.httpClient
      .post(`/customEmail/fromName`,fromName)
      .then((res) => Promise.resolve(res))
      .catch((err) => Promise.reject(err));
  }

	deleteCustomEmail(){
		return this.httpClient
			.delete(`/customEmail`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
