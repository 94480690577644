var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [{ to: "/home", label: "Home" }, { label: "Forms" }]
          }
        },
        [_vm._v(" Form builder ")]
      ),
      _c(
        "div",
        { staticClass: "row-format align-center mb-4" },
        [
          _c("v-text-field", {
            staticStyle: {
              "max-width": "200px",
              "background-color": "var(--v-white-base)"
            },
            attrs: {
              outlined: "",
              "hide-details": "",
              dense: "",
              placeholder: "Search...",
              color: "gray_30"
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "material-symbols-rounded font-gray_50" },
                      [_vm._v("search")]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          }),
          _c(
            "v-menu",
            {
              attrs: {
                "nudge-bottom": 0,
                elevation: 0,
                "content-class": "add-new-menu",
                bottom: "",
                left: "",
                rounded: "",
                "offset-overflow": "",
                "offset-y": "",
                transition: "slide-y-transition"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ staticClass: "ml-auto super-action" }, on),
                        [
                          _c("v-icon", { attrs: { size: "20" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v(" Add form")
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("div", { staticClass: "add-new-dropdown" }, [
                _c(
                  "div",
                  {
                    staticClass: "add-new-item",
                    on: {
                      click: function($event) {
                        return _vm.$refs.discovery.saveNewTemplate()
                      }
                    }
                  },
                  [_vm._v("Add new")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "add-new-item",
                    on: {
                      click: function($event) {
                        return _vm.$refs.discovery.aiAssistant()
                      }
                    }
                  },
                  [_vm._v("Form builder ✨Assistant")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "add-new-item",
                    on: {
                      click: function($event) {
                        return _vm.$refs.discovery.templateLibrary()
                      }
                    }
                  },
                  [_vm._v("Search template library")]
                )
              ])
            ]
          )
        ],
        1
      ),
      _c("discovery-templates", {
        ref: "discovery",
        attrs: {
          "modal-state": _vm.modalState,
          "in-list-view": true,
          search: _vm.search
        },
        on: {
          "folder-list": _vm.setFolderList,
          "template-selected": _vm.openTemplate,
          "template-list": _vm.setTemplateList,
          "download-results": function($event) {
            return _vm.downloadResults($event)
          }
        }
      }),
      _vm.discoveryDialog
        ? _c(
            "basic-modal",
            {
              attrs: {
                dialog: _vm.discoveryDialog,
                persistent: true,
                fullscreen: true,
                "retain-focus": false
              },
              on: { close: _vm.handleBuilderClose }
            },
            [
              _c("discovery-builder", {
                attrs: {
                  "template-id": _vm.templateId,
                  folders: _vm.folders,
                  "other-template-names": _vm.otherTemplateNames
                },
                on: {
                  close: _vm.handleBuilderClose,
                  duplicated: _vm.handleBuilderClose
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }