var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { class: _vm.divClass, staticStyle: { width: "100%" } }, [
        _c("span", { staticClass: "red--text" }, [_vm._t("default")], 2)
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }