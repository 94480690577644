var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initialized
    ? _c("div", { staticClass: "column-format flex-grow-1" }, [
        !_vm.transactions.length
          ? _c(
              "div",
              {
                staticClass: "row-format centered fill-height",
                staticStyle: { "min-height": "calc(100vh - 300px)" }
              },
              [
                _c("empty-view", {
                  attrs: {
                    header: "No deposits yet",
                    body:
                      "It’s a best practice to collect a deposit before you do any work for a client. If you do, you can see what you’ve collected, as well as how much of the deposit has been applied to invoices and how much credit still remains.",
                    cta: "Request a deposit",
                    "video-header": "See how it works",
                    "video-body":
                      "Learn how to request deposits and apply them to future invoices.",
                    "video-cta": "Watch the tutorial",
                    "video-id": "VHlGLkW1suI"
                  },
                  on: {
                    "cta-clicked": function($event) {
                      return _vm.addNew()
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.initialized && _vm.transactions.length
          ? _c("div", { staticClass: "row-format gap-3 flex-wrap" }, [
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit text-left" }, [
                  _vm._v("Total collected")
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$formatters.dollars(
                        _vm.depositAccount.liabilities,
                        true,
                        true,
                        _vm.currency
                      )
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit text-left" }, [
                  _vm._v("Total applied")
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$formatters.dollars(
                        _vm.depositAccount.credits,
                        true,
                        true,
                        _vm.currency
                      )
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "kpi-box" }, [
                _c("div", { staticClass: "fit text-left" }, [
                  _vm._v("Current balance")
                ]),
                _c("div", { staticClass: "font-24 brand-medium fit mt-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$formatters.dollars(
                        _vm.depositAccount.balance,
                        true,
                        true,
                        _vm.currency
                      )
                    )
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm.transactions.length
          ? _c(
              "div",
              {
                staticClass: "row-format my-4",
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "div",
                  { staticClass: "row-format ml-auto gap-2" },
                  [
                    _vm._t("filter"),
                    _vm.currencies.length > 1
                      ? _c("v-select", {
                          staticStyle: {
                            "max-width": "100px",
                            "background-color": "var(--v-white-base)"
                          },
                          attrs: {
                            items: _vm.currencies,
                            dense: "",
                            "hide-details": "",
                            outlined: ""
                          },
                          model: {
                            value: _vm.currency,
                            callback: function($$v) {
                              _vm.currency = $$v
                            },
                            expression: "currency"
                          }
                        })
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          : _vm._e(),
        _vm.filteredTransactions.length
          ? _c(
              "div",
              { staticStyle: { "background-color": "var(--v-white-base)" } },
              [
                _vm.initialized
                  ? _c("v-data-table", {
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.filteredTransactions,
                        "hide-default-footer": true,
                        "sort-by": "timestamp",
                        "sort-desc": true,
                        "items-per-page": -1
                      },
                      on: {
                        "click:row": function($event) {
                          return _vm.openInvoice($event.invoiceId)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.timestamp",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.timestamp
                                  ? _c(
                                      "span",
                                      { staticClass: "brand-semilight" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.DateTime.fromISO(
                                              item.timestamp
                                            ).toFormat("DD")
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item.amount",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("span", { staticClass: "brand-semilight" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatAmount(
                                        item.amount,
                                        item.type,
                                        item.currency
                                      )
                                    )
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "item.invoiceNumber",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("div", { staticClass: "brand-semilight" }, [
                                  _vm._v("#" + _vm._s(item.invoiceNumber))
                                ])
                              ]
                            }
                          },
                          {
                            key: "item.type",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("div", {
                                  staticClass: "brand-semilight font-12",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.formatType(item.type))
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "item.action",
                            fn: function(ref) {
                              return undefined
                            }
                          }
                        ],
                        null,
                        false,
                        3201699275
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm.transactions.length && !_vm.filteredTransactions.length
          ? _c(
              "div",
              { staticClass: "mt-6" },
              [_c("empty-filtered-results")],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }