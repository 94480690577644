var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0 selector_terms", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { style: _vm.headerStyle, attrs: { cols: "12" } },
            [
              !_vm.editMode
                ? _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.terms.header) }
                  })
                : _vm._e(),
              _vm.editMode
                ? _c("editor", {
                    ref: "headerEditor",
                    staticClass: "grey-dots",
                    attrs: {
                      "api-key": _vm.$store.getters.getTinyMceKey,
                      "initial-value": _vm.terms.header,
                      inline: true,
                      init: _vm.mceConfigHeader,
                      spellcheck: true
                    },
                    on: { input: _vm.handleHeaderInput }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticStyle: { "text-align": "left", position: "relative" },
              attrs: { cols: "12" },
              on: {
                click: function($event) {
                  return _vm.openTermsEditor()
                }
              }
            },
            [
              _c("span", {
                class: this.editMode ? "pointer" : "",
                style: _vm.bodyStyle,
                domProps: { innerHTML: _vm._s(_vm.terms.evaluatedTerms) }
              }),
              _vm.editMode
                ? _c(
                    "div",
                    {
                      staticClass: "brand-medium font-primary pointer",
                      staticStyle: {
                        transform: "rotate(-20deg)",
                        width: "100%",
                        position: "absolute",
                        top: "20%",
                        "text-align": "center",
                        "font-size": "70px",
                        opacity: "0.4"
                      }
                    },
                    [_vm._v("Click to edit")]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _vm.termsDialog
        ? _c(
            "basic-modal",
            {
              attrs: { dialog: _vm.termsDialog, persistent: true },
              on: { close: _vm.handelCancel }
            },
            [
              _c("terms-dialog", {
                attrs: {
                  "proposal-service": _vm.proposalService,
                  "client-mode": _vm.clientMode,
                  terms: _vm.terms
                },
                on: {
                  cancel: function($event) {
                    return _vm.handelCancel()
                  },
                  save: function($event) {
                    return _vm.handleSave($event, false)
                  },
                  "text-conversion": function($event) {
                    return _vm.handleSave($event, true)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }