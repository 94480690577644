<template>
	<div>
		<v-menu
			:nudge-top="28"
			min-width="150"
			max-width="150"
			bottom
			right
			rounded
			offset-overflow
			offset-y
			:close-on-content-click="true"
			content-class="filter-menu-class"
			transition="slide-y-transition"
			v-model="showMenu"
		>
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" style="min-height: 40px; background-color: var(--v-white-base)" elevation="0" outlined color="gray_30">
					<span :class="`font-14 ${isFilterActive ? 'font-secondary' : 'font-gray_60'} brand-medium`">{{
						filterLabel
					}}</span>
					<v-icon x-small class="mx-1" :color="isFilterActive ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
				</v-btn>
			</template>

			<div class="column-format">
				<div class="filter-option-box">
					<v-radio-group @change="updated" class="small-radio" hide-details dense v-model="filter.sort">
						<v-radio label="Name" value="Name"></v-radio>
						<v-radio label="Client" value="Client"></v-radio>
						<v-radio label="Project start" value="Project start"></v-radio>
						<v-radio label="Project due" value="Project due"></v-radio>
						<v-radio label="Next due" value="Next task due"></v-radio>
					</v-radio-group>
				</div>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'ProjectListSort',

		props: ['filter'],

		data() {
			return {
				showMenu: false,
			};
		},

		mounted() {},

		methods: {
			updated: function() {
				this.$emit('updated', this.filter);
			},

			applyFilter: function() {
				this.showMenu = false;
			},
		},

		computed: {
			isFilterActive: function() {
				return this.filter.sort && this.filter.sort !== 'Name';
			},

			filterLabel: function() {
				if (!this.filter.sort || this.filter.sort === 'Name') {
					return 'Sort';
				} else {
					return 'Sort by ' + this.filter.sort.toLowerCase();
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.small-radio {
		margin-top: 0px !important;
	}

	.small-radio ::v-deep label {
		font-size: 14px;
	}
</style>
