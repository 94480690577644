<template>
  <div>
    <div class="row-format py-2" style="border-bottom: 1px solid var(--v-gray_30-base)">
      <div
          :class="`row-format align-center page-link ${tab === 'Field Settings' ? 'page-link-active' : ''}`"
          @click="selectTab('Field Settings')"
      >
        <v-icon
            class="material-symbols-rounded"
            size="22"
            :color="tab === 'Field Settings' ? 'primary' : 'gray_50'"
        >settings</v-icon
        >
        <div class="ml-1">Field Settings</div>
      </div>
      <div
          :class="`row-format align-center page-link ${tab === 'Conditional Logic' ? 'page-link-active' : ''}`"
          @click="selectTab('Conditional Logic')"
          v-if="!nonConditionalTypes.includes(item.type)"
      >
        <v-icon
            class="material-symbols-rounded"
            size="22"
            :color="tab === 'Conditional Logic' ? 'primary' : 'gray_50'"
        >account_tree</v-icon
        >
        <div class="ml-1">Conditional Logic <v-icon color="success" small v-if="showConditionalHelper">done_outline</v-icon></div>
      </div>
    </div>
    <div >
      <div class="column-format gap-3 px-2 py-2">
        <div class="row-format align-center">
          <div class="font-18 brand-medium">Edit {{this.item.fieldName}}</div>
          <div class="ml-auto row-format align-center gap-2">
            <v-icon size="20" class="material-symbols-rounded pointer" @click="$emit('duplicate')">content_copy</v-icon>
            <v-icon size="20" class="material-symbols-rounded pointer" @click="$emit('delete')">delete</v-icon>
            <v-btn small elevation="0" color="primary" @click="$emit('done')">Done</v-btn>
          </div>
        </div>
        <component v-if="tab === 'Field Settings'" :key="item.type" :is="item.type + 'Config'" :item="item" :template="template" :schema="schema" @change="$emit('change',$event)"></component>
				<conditional-config v-if="tab === 'Conditional Logic'" :item="item" :schema="schema"></conditional-config>
			</div>
    </div>
  </div>
</template>

<script>
	import TextBlockConfig from '@/modules/forms/config/TextBlockConfig';
	import TextAreaConfig from '@/modules/forms/config/TextAreaConfig';
	import TextInputConfig from '@/modules/forms/config/TextInputConfig';
	import SpacerConfig from '@/modules/forms/config/SpacerConfig';
	import SelectConfig from '@/modules/forms/config/SelectConfig';
	import NewPageConfig from '@/modules/forms/config/NewPageConfig';
	import FileInputConfig from '@/modules/forms/config/FileInputConfig';
	import ImageBlockConfig from '@/modules/forms/config/ImageBlockConfig';
	import DividerConfig from '@/modules/forms/config/DividerConfig';
	import DateInputConfig from '@/modules/forms/config/DateInputConfig';
	import ContainerConfig from '@/modules/forms/config/ContainerConfig';
	import CheckboxConfig from '@/modules/forms/config/CheckboxConfig';
	import RadioConfig from '@/modules/forms/config/CheckboxConfig';
	import LogoConfig from "@/modules/forms/config/LogoConfig";
  import ButtonConfig from "@/modules/forms/config/ButtonConfig.vue";
  import ConditionalConfig from "@/modules/forms/config/ConditionalConfig.vue";

	export default {
		name: 'ConfigRenderer',

		props: ['template','item', 'schema'],

		components: {
			CheckboxConfig,
			ContainerConfig,
			DateInputConfig,
			DividerConfig,
			FileInputConfig,
			ImageBlockConfig,
			NewPageConfig,
			RadioConfig,
			SelectConfig,
			SpacerConfig,
			TextBlockConfig,
			TextAreaConfig,
			TextInputConfig,
			LogoConfig,
      ButtonConfig,
      ConditionalConfig
		},

		data: function() {
			return {
        tab: "Field Settings",
        nonConditionalTypes: ["NewPage", "Spacer", "Divider"]
      };
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
      selectTab: function(tab) {
        this.tab = tab;
      },
		},

		computed: {
			showConditionalHelper: function(){
				return this.item.conditionsEnabled && this.item.conditions.length;
			},

			componentType: function(){
				if(this.item.type === 'TextInput') {
					return 'text input'
				}else if(this.item.type === 'TextArea'){
					return 'text area';
				}else if(this.item.type === 'DateInput'){
					return 'date field';
				}else if(this.item.type === 'Select'){
					return 'select box';
				}else if(this.item.type === 'Checkbox'){
					return 'check box';
				}else if(this.item.type === 'Spacer'){
					return 'spacer';
				}else if(this.item.type === 'Divider'){
					return 'divider';
				}else if(this.item.type === 'Container') {
					return 'container';
				}else if(this.item.type === 'NewPage'){
					return 'page break';
        }else if(this.item.type === 'Button'){
          return 'button'
				}else{
					return ''
				}
			}
		},
	};
</script>

<style lang="scss">
</style>
