<template>
	<div id="proposal-comments-menu">
		<v-btn v-if="!clientMode" class="secondary-action activator-button" @click="openMenu()">
			<div class="font-12 d-flex align-center">
				<v-icon small>$comment</v-icon>
				<div class="ml-2">
					{{ $t('proposal.comments.comments') }}
				</div>
				<div class="unread-indicator" v-if="hasUnread"></div>
			</div>
		</v-btn>

		<v-btn v-if="clientMode" class="primary-action" @click="openMenu()">
			<div class="row-format align-center">
				<v-icon small class="">$comment</v-icon>
				<div class="ml-1">{{ $t('proposals.leave-a-comment') }}</div>
				<div v-if="unReadCount" class="ml-1">({{ unReadCount }} unread)</div>
			</div>
		</v-btn>

		<basic-modal :dialog="menu" @close="closeMenu()" :persistent="false">
			<div id="proposal-comments-modal">
				<v-btn icon class="close-dialog" @click="closeMenu()">
					<v-icon>$close</v-icon>
				</v-btn>
				<div class="modal-padding">
					<div class="modal-title">
						{{ $t('proposals.comments') }}
						<v-chip pill x-small>{{ comments.length }}</v-chip>
					</div>

					<div id="comments-wrapper">
						<div
							v-for="(comment, index) in comments"
							:key="comment.id"
							:class="'comment-wrapper' + (canEdit(comment, index) ? ' editable' : '')"
							@mouseover="setHoverIndex(index)"
							@mouseleave="setHoverIndex(null)"
						>
							<div class="author-timestamp-buttons">
								<div class="author-timestamp">
									<div class="author">
										<v-icon size="12" color="var(--v-gray_90-base)" v-if="comment.privateComment"
											>$lock</v-icon
										>
										{{ comment.author }}
									</div>
									<div class="timestamp">
										<v-icon size="12">$clock</v-icon>
										{{ comment.timestamp | formatForTimeAgo }}
									</div>
								</div>
								<div class="edit-delete-buttons">
									<v-btn x-small icon @click.native="editComment(comment, index)">
										<v-icon size="16">$edit</v-icon>
									</v-btn>
									<v-btn x-small icon @click.native="confirmDelete(comment)">
										<v-icon size="16">$delete</v-icon>
									</v-btn>
								</div>
							</div>
							<div v-if="editIndex !== index" class="comment" v-html="comment.comment"></div>
							<div v-if="editIndex === index" class="comment">
								<v-textarea rows="1" auto-grow outlined hide-details v-model="commentToEdit.comment"></v-textarea>
								<div class="foot">
									<v-btn color="secondary-action mr-1" small style="width: 120px;" @click="cancelEdit()"
										>{{ $t('global.cancel') }}</v-btn
									>
									<v-btn class="primary-action ml-1" small style="width: 120px;" @click="saveEdit()"
										>{{ $t('global.save') }}</v-btn
									>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="modal-footer px-6 pb-5">
					<div class="textarea-wrapper">
						<div>
							<v-textarea
								class="no-rules"
								persistent-placeholder
								hide-details
								:autofocus="true"
								v-model="newComment"
								auto-grow
								:placeholder="$t('proposals.write-a-comment')"
								rows="1"
								@keydown.enter="handleEnter($event)"
							></v-textarea>
						</div>
						<div class="vertical-rule"></div>
						<div>
							<div class="send-button" @click="sendComment()">
								<template v-if="willSendEmail">
									{{ $t('global.send') }}
								</template>
								<template v-else>{{ $t('global.save') }}</template>
							</div>
						</div>
					</div>
					<div v-if="!clientMode" class="checkboxes mt-3">
						<label>
							<input v-model="privateComment" type="checkbox" />
							<span>{{ $t('proposals.private-comment') }}</span>
						</label>

						<label class="ml-8" v-if="!privateComment">
							<input v-model="sendEmail" type="checkbox" />
							<span>{{ $t('proposals.send-email') }}</span>
						</label>
					</div>
				</div>
			</div>
		</basic-modal>
		<confirm-dialog
			v-if="confirmDeleteDialog"
			:dialog="confirmDeleteDialog"
			:body-text="$t('proposal.comments.confirm-delete')"
			@close="cancelDelete()"
			@confirm="deleteComment()"
		></confirm-dialog>
	</div>
</template>

<script>
	// import modal from '@bit/hecticapp.common.modal';
	import BasicModal from '@/components/BasicModal';
	import ConfirmDialog from '@/components/ConfirmDialog';

	export default {
		name: 'CommentsMenu',

		props: ['clientId', 'proposalId', 'comments', 'proposalService', 'viewedTimestamps', 'clientMode'],

		components: { BasicModal, ConfirmDialog },

		data: function() {
			return {
				menu: false,
				newComment: null,
				lastReadDate: this.getLastViewedDate(),
				hoverIndex: null,
				editIndex: null,
				commentToEdit: null,
				confirmDeleteDialog: false,
				commentToDelete: null,
				privateComment: false,
				sendEmail: true,
			};
		},

		mounted() {
			this.$store.state.eventBus.$on(this.getChannelName(), this.newCommentEvent);
		},

		beforeDestroy() {
			this.$store.state.eventBus.$off(this.getChannelName(), this.newCommentEvent);
		},

		methods: {
			getLastViewedDate: function() {
				let timestamp = this.viewedTimestamps['user-' + this.$store.state.loggedInUser.userId];
				if (timestamp) {
					return new Date(timestamp);
				} else {
					return null;
				}
			},

			confirmDelete: function(comment) {
				this.commentToDelete = comment;
				this.confirmDeleteDialog = true;
			},

			cancelDelete: function() {
				this.commentToDelete = null;
				this.confirmDeleteDialog = false;
			},

			deleteComment: function() {
				this.proposalService.deleteComment(this.clientId, this.proposalId, this.commentToDelete.id).then(() => {
					this.commentToDelete = null;
					this.confirmDeleteDialog = false;
				});
			},

			editComment: function(comment, index) {
				this.commentToEdit = Object.assign({}, comment);
				this.editIndex = index;

				if (this.editIndex === this.comments.length - 1) {
					this.$nextTick(this.scrollToBottom);
				}
			},

			cancelEdit: function() {
				this.commentToEdit = null;
				this.editIndex = null;
			},

			saveEdit: function() {
				this.proposalService.updateComment(this.clientId, this.proposalId, this.commentToEdit).then(() => {
					this.comments[this.editIndex] = this.commentToEdit;
					this.editIndex = null;
					this.commentToEdit = null;
				});
			},

			canEdit(comment, index) {
				if (
					this.editIndex === null &&
					comment.authorId == this.$store.state.loggedInUser.userId &&
					index === this.hoverIndex
				) {
					return true;
				} else {
					return false;
				}
			},

			setHoverIndex: function(index) {
				this.hoverIndex = index;
			},

			handleEnter: function(event) {
				if (!event.shiftKey) {
					this.sendComment();
					event.preventDefault();
				}
			},

			newCommentEvent: function(event) {
				if (event.userMetadata === 'Comment') {
					event.message.timestamp = new Date().toISOString().split('.')[0] + 'Z';
					let comment = event.message;

					if (comment.edited) {
						for (let i = 0; i < this.comments.length; i++) {
							if (this.comments[i].id === comment.id) {
								if (comment.comment === '_deleted_') {
									this.comments.splice(i, 1);
								} else {
									this.comments[i] = comment;
									if (i === this.comments.length - 1) {
										this.$nextTick(this.scrollToBottom);
									}
								}
							}
						}
					} else {
						this.comments.push(comment);
						this.$nextTick(this.scrollToBottom);
					}
				}
			},

			scrollToBottom: function() {
				let div = document.getElementById('commentContainer');
				if (div) {
					div.scrollTop = div.scrollHeight;
				}
			},

			getChannelName() {
				return this.$store.getters.getMessageChannelPrefix + '.p-' + this.proposalId;
			},

			sendComment: function() {
				if (this.$validations.isEmpty(this.newComment)) {
					return;
				}

				this.proposalService
					.sendComment(this.clientId, this.proposalId, {
						comment: this.newComment,
						privateComment: this.privateComment,
						sendEmail: this.sendEmail,
					})
					.catch((err) => {
						this.$store.commit('error', err.response.data.message);
					});

				this.newComment = null;
			},

			openMenu: function() {
				this.menu = true;
				this.lastReadDate = new Date();
				setTimeout(this.scrollToBottom, 500);
			},

			closeMenu: function() {
				this.menu = false;
				this.lastReadDate = new Date();
				this.editIndex = null;
				this.commentToEdit = null;
				this.hoverIndex = null;
			},
		},

		computed: {
			willSendEmail: function() {
				if (this.privateComment || this.sendEmail === false) {
					return false;
				} else {
					return true;
				}
			},

			unReadCount: function() {
				let unreadCount = 0;
				for (let i = 0; i < this.comments.length; i++) {
					if (
						this.lastReadDate === null ||
						new Date(this.comments[i].timestamp).getTime() > this.lastReadDate.getTime()
					) {
						unreadCount++;
					}
				}
				return unreadCount;
			},

			hasUnread: function() {
				if (!this.menu && this.comments.length > 0) {
					let lastComment = this.comments[this.comments.length - 1];

					return this.lastReadDate === null || new Date(lastComment.timestamp).getTime() > this.lastReadDate.getTime();
				} else {
					return false;
				}
			},
		},
	};
</script>

<style lang="scss">
	#proposal-comments-menu {
		.activator-button {
			.unread-indicator {
				background-color: var(--v-primary-base);
				height: 8px;
				width: 8px;
				border-radius: 4px;
				margin-top: -6px;
			}
		}
	}

	#proposal-comments-modal {
		background-color: var(--v-white-base);
		width: 565px;
		//overflow: hidden;
		//position: relative;

		//#comments-wrapper {
		//	height: 100%;
		//}
		.comment-wrapper {
			padding: 8px;
			border-radius: 4px;
			border: 1px solid var(--v-gray_30-base);
			margin-top: 8px;

			.author-timestamp-buttons {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 12px;
				min-height: 20px; // make room for buttons on right
				.author-timestamp {
					flex: 0 1 auto;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					.v-icon {
						margin-top: -2px;
					}
					.author {
						flex: 0 1 auto;
						font-weight:500;
						color: var(--v-gray_90-base);
						margin-right: 12px;
					}

					.timestamp {
						flex: 0 1 auto;
						color: var(--v-gray_70-base);
					}
				}
				.edit-delete-buttons {
					opacity: 0;
					transition: opacity 0.3s;
					flex: 1 0 auto;
					text-align: right;
					.v-btn {
						color: var(--v-black-base);
						margin: 0 4px;
					}
				}
			}
			.comment {
				text-align: left;
				font-weight: 300;
				font-size: 14px;
				word-wrap: anywhere;
				word-break: break-all;
				overflow-wrap: break-word;
				textarea {
					font-size: 14px;
				}
				.foot {
					margin-top: 8px;
					text-align: right;
				}
			}
			&.editable:hover {
				background-color: var(--v-gray_10-base);
				.edit-delete-buttons {
					transition: opacity 0.3s;
					opacity: 1;
				}
			}
		}
		//.sticky {
		//	position: sticky;
		//	position: -webkit-sticky;
		//	//bottom: 0;
		//	width: 565px;
		//	background-color: var(--v-white-base);
		//}
		.textarea-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			border: 1px solid var(--v-gray_50-base);
			border-radius: 4px;
			min-height: 40px;
			margin-top: 8px;
			> div:first-child {
				flex: 1 0 auto;
				padding: 8px 8px 0;
				.v-input.v-textarea {
					padding-top: 0;
					margin-top: 0;
					align-items: center;
					font-weight: 300 !important;
				}
			}
			div.vertical-rule {
				width: 1px;
				height: 24px;
				background-color: var(--v-gray_50-base);
				margin-top: 7px;
			}
			> div:last-child {
				flex: 0 0 56px;
				.send-button {
					height: 38px;
					width: 56px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: 500;
					font-size: 14px;
					color: var(--v-blue_50-base);
					cursor: pointer;
					border-radius: 4px;
					&:hover {
						opacity: 0.8;
					}
					&:active {
						background-color: var(--v-blue_10-base);
					}
				}
			}
		}
		.checkboxes {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			label {
				display: flex;
				align-items: center;
				cursor: pointer;
				input {
					width: 14px;
					height: 14px;
					margin-right: 8px;
				}
				font-size: 14px;
				font-weight:300;
			}
		}
	}
	//#proposal-comments-delete-modal {
	//	background-color: var(--v-white-base);
	//	width: 300px;
	//}
</style>
