<template>
	<div class="mt-5 column-format">
		<input type="file" ref="FontFiles" @change="setFonts" multiple style="display: none" accept=".otf,.ttf,.woff">
		<v-btn @click="findFonts" color="primary" class="primary-action">Upload custom fonts</v-btn>
		<div class="mt-3">
			<div v-for="font in fonts" :key="font.fontName" class="row-format mb-2 pb-2" style="border-bottom: 1px solid var(--v-gray_30-base)">
				<div>{{font.fontName}}</div>
				<div class="ml-auto">
					<v-btn icon @click="confirmDeleteFont(font)">
						<v-icon size="20">$delete</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CustomFontService from "@/modules/account/CustomFontService";
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'CustomFonts',

		props: [],

		components: {},

		data: function () {
			return {
				customFontService: new CustomFontService(),
				fonts: [],
			};
		},

		mounted() {
			this.getInstalledFonts();
		},

		beforeDestroy() {},

		methods: {
			getInstalledFonts: function(){
				this.customFontService.getCustomFonts().then((res) => {
					this.fonts.splice(0,this.fonts.length);
					this.fonts.push(... res.data);
				});
			},

			findFonts: function(){
				if(this.fonts.length === 0){
					let binding = {
						headingText: 'Heads up!',
						bodyText: `Adding custom fonts will disable the default Google font list in agreements, client portal, and forms. Do you wish to continue?`
					}
					this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
						if(res){
							this.$refs.FontFiles.click();
						}
					});
				}else{
					this.$refs.FontFiles.click();
				}
			},

			setFonts: function(event){
				let files = [];
				event.target.files.forEach(f => {
					files.push(f);
				});
				this.$store.commit('startLoading');
				this.customFontService.uploadCustomFonts(files).then((res) => {
					this.fonts.push(... res.data);
					res.data.forEach((font) => {
						this.loadFont(font);
					})
				}).finally(() => this.$store.commit('stopLoading'))
			},

			loadFont: function(font){
				let newFont = new FontFace(font.fontName, `url(${font.fontUrl})`);
				newFont.load().then((loadedFont) => {
					document.fonts.add(loadedFont)
					console.log('added font',loadedFont);
				})
			},

			confirmDeleteFont: function(font){
				let binding = {
					headingText: 'Confirm delete',
					bodyText: `Are you sure you want to delete ${font.fontName}?  Any documents currently using this font will revert to the default font face.`
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res){
						this.customFontService.deleteCustomFont(font.id).then(() => {
							let ix = this.fonts.findIndex(f => f.id == font.id);
							this.fonts.splice(ix,1);
						})
					}
				})
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
