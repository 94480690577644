<template>
	<div>
		<v-data-table
				:headers="headers"
				:items="payments"
				sort-by="datePaid"
				:sort-desc="true"
				:fixed-header="true"
				@click:row="$emit('open-invoice',$event)"
				style="cursor: pointer"
				:items-per-page="-1"
				:hide-default-footer="true"
		>
			<template v-slot:item.client.name="{ item }">
				<div class="row-format align-center" v-if="item.client">
					<client-avatar small v-if="item.client" :client="item.client" />
					<div>{{ item.client.name }}</div>
				</div>
				<div v-else> -- </div>
			</template>

			<template v-slot:item.datePaid="{ item }">
						<span v-if="item.datePaid" class="brand-semilight">{{
								DateTime.fromISO(item.datePaid).toFormat('DD')
							}}</span>
				<span v-else-if="item.date" class="brand-semilight">{{
						DateTime.fromISO(item.date).toFormat('DD')
					}}</span>
			</template>

			<template v-slot:item.amount="{ item }">
				<div class="brand-semilight">
					{{ $formatters.dollars(item.amount ? item.amount : 0, true, true, item.currency) }}
				</div>
			</template>

			<template v-slot:item.invoiceNumberFormatted="{ item }">
				<span class="brand-semilight">{{ item.invoiceNumberFormatted }}</span>
			</template>

			<template v-slot:item.paymentProvider="{ item }">
				<span class="brand-semilight">{{ $t('invoice.payment-providers.' + item.paymentProvider) }}</span>
			</template>

			<template v-slot:item.pending="{ item }">
				<div class="row-format align-center" v-if="item.type === 'Invoice'">
					<div>{{ item.pending ? 'Pending' : 'Cleared' }}</div>
					<v-icon small v-if="item.pending" :color="item.pending ? 'warning' : 'success'" class="ml-1">{{
							item.pending ? '$alert' : '$success'
						}}</v-icon>
					<v-icon class="ml-2" size="20" v-if="item.integrationKeys && item.integrationKeys.quickbooksId">$quickbooks</v-icon>
				</div>
				<div v-else>--</div>
			</template>

			<template v-slot:no-data> No payments found. </template>
		</v-data-table>
	</div>
</template>

<script>
import ClientAvatar from "@/components/ClientAvatar";
import DateTime from "@/modules/utils/HDateTime";

export default {
	name: "PaymentTable",

	props: ['payments'],

	components: {ClientAvatar},

	data: function () {
		return {
			DateTime:DateTime
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {
		headers: function () {
			let result = [
				{ text: this.$t('invoice.details.payment-details-modal.date-paid'), value: 'datePaid' },
				{ text: this.$t('invoice.details.payment-details-modal.amount'), value: 'amount' },
				{ text: this.$t('client.client'), value: 'client.name' },
				{ text: this.$t('income.source'), value: 'invoiceNumberFormatted' },
				{ text: this.$t('invoice.details.payment-details-modal.payment-type'), value: 'paymentProvider' },
				{ text: this.$t('invoice.status'), value: 'pending' },
			];

			return result;
		},
	},

}
</script>

<style scoped lang="scss">

</style>