<template>
	<div class="pl-2 text-left font-gray_90">
		<div class="row-format align-center">
			<v-icon small color="gray_90">check</v-icon>
			<div class="ml-1" :class="`${event.original.complete ? 'font-strike' : ''}`">{{event.original.item}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ToDoEvent",

	props: ['event','view'],

	components: {},

	data: function () {
		return {}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {},

	computed: {},

}
</script>

<style scoped lang="scss">

</style>