<template>
	<div>
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' }]">
			Preferences
		</page-header>

		<div id="account-settings-preferences" class="column-format">
			<div class="row-format gap-3 pb-4">
				<div
					:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
					v-for="page in pages"
					:key="page.value"
					@click.stop="setView(page.value)"
				>
					<div>{{ page.label }}</div>
				</div>
			</div>
			<div v-if="currentView === 'app'">
				<v-container fluid class="ma-0 pa-0">
					<v-row dense>
						<v-col cols="6" class="pr-3">
							<div class="text-left">Home page</div>
							<v-radio-group dense hide-details class="mt-2 pt-0" v-model="homePage" @change="homePageUpdated">
								<v-radio value="/getting-started" class="h-outline pa-2">
									<template v-slot:label>
										<h-icon2
											class="mr-2"
											name="hummingbird"
											size="25"
											:active="homePage === '/getting-started'"
										></h-icon2
										><span>Getting started</span>
									</template>
								</v-radio>
								<v-radio value="/home" class="h-outline pa-2">
									<template v-slot:label>
										<v-icon class="material-symbols-rounded mr-3">other_houses</v-icon>
										<span>Homebase</span>
									</template>
								</v-radio>
								<v-radio value="/projects" class="h-outline pa-2">
									<template v-slot:label>
										<v-icon class="material-symbols-rounded mr-3">folder_special</v-icon><span>Projects</span>
									</template>
								</v-radio>
								<v-radio value="/accounting" class="h-outline pa-2">
									<template v-slot:label>
										<v-icon class="material-symbols-rounded mr-3">request_quote</v-icon
										><span>Accounting</span>
									</template>
								</v-radio>
								<v-radio value="/pipeline/opportunities" class="h-outline pa-2">
									<template v-slot:label>
										<v-icon class="material-symbols-rounded mr-3">filter_alt</v-icon
										><span>Sales pipeline</span>
									</template>
								</v-radio>
							</v-radio-group>
							<div class="text-left mt-5">Show client logos</div>
							<v-radio-group
								dense
								hide-details
								class="mt-2 pt-0"
								v-model="$store.state.podAccount.accountPreferences.showClientLogos"
								@change="showClientLogosUpdated"
							>
								<v-radio :value="true" class="h-outline pa-2" label="Enabled"> </v-radio>
								<v-radio :value="false" class="h-outline pa-2" label="Disabled"> </v-radio>
							</v-radio-group>
						</v-col>
						<v-col cols="6" class="pl-3">
							<div class="text-left">Dark mode</div>
							<v-radio-group dense hide-details class="mt-2 pt-0" v-model="darkMode" @change="darkModeUpdated">
								<v-radio value="always" class="h-outline pa-2" label="Dark mode always"> </v-radio>
								<v-radio value="never" class="h-outline pa-2" label="Light mode always"> </v-radio>
								<v-radio value="automatic" class="h-outline pa-2" label="Automatic (based on OS)"> </v-radio>
							</v-radio-group>

							<div class="text-left mt-5">Show scroll bars</div>
							<v-radio-group
								dense
								hide-details
								class="mt-2 pt-0"
								v-model="$store.state.showScrollBars"
								@change="showScrollBarsUpdated"
							>
								<v-radio :value="true" class="h-outline pa-2" label="Yes"> </v-radio>
								<v-radio :value="false" class="h-outline pa-2" label="No"> </v-radio>
							</v-radio-group>

							<div class="text-left mt-8">Week starts on</div>
							<v-radio-group
								dense
								hide-details
								class="mt-2 pt-0"
								v-model="weekStartsOn"
								@change="weekStartsOnUpdated"
							>
								<v-radio value="Sunday" class="h-outline pa-2" label="Sunday"> </v-radio>
								<v-radio value="Monday" class="h-outline pa-2" label="Monday"> </v-radio>
							</v-radio-group>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<div v-if="currentView === 'client' && $store.getters.isAccountOwner">
				<v-container fluid class="ma-0 pa-0">
					<v-row dense>
						<v-col cols="12" class="pr-3">
							<div class="column-format gap-3">
								<div class="text-left">New client/contact defaults</div>
								<v-text-field
									type="number"
									v-model="$store.state.podAccount.accountPreferences.defaultHourlyRate"
									hide-details
									persistent-placeholder
									label="Default - Hourly rate"
									:prefix="currencySymbol"
									dense
									outlined
									@change="updateDefaultHourlyRate"
								></v-text-field>
								<v-text-field
									type="number"
									v-model="$store.state.podAccount.accountPreferences.defaultLatePaymentFee"
									hide-details
									persistent-placeholder
									label="Default - late fee"
									suffix="%/mo"
									dense
									outlined
									@change="updateDefaultLatePaymentFee"
								></v-text-field>
								<v-select
									:items="dayOptions"
									v-model="$store.state.podAccount.accountPreferences.defaultPaymentDays"
									hide-details
									dense
									outlined
									persistent-placeholder
									label="Default - payment days"
									@change="updateDefaultPaymentDays"
								>
									<template v-slot:selection="{ item }">
										<span v-if="item === 0">On Receipt</span>
										<span v-if="item !== 0">{{ item }} days</span>
									</template>
									<template v-slot:item="{ item }">
										<span v-if="item === 0">On Receipt</span>
										<span v-if="item !== 0">{{ item }} days</span>
									</template>
								</v-select>
								<v-select
									persistent-placeholder
									hide-details
									dense
									outlined
									:items="paidByOptions"
									v-model="$store.state.podAccount.accountPreferences.defaultWhoPaysCardFees"
									label="Default - pass Stripe credit card fees to client?"
									@change="updateDefaultWhoPaysCardFees"
								></v-select>
								<div style="max-width: fit-content">
									<v-switch
										v-model="$store.state.podAccount.accountPreferences.defaultPortalAccess"
										label="Contacts -Default Portal Access"
										@change="updateDefaultPortalAccess"
										v-tippy="{
											content: 'When a new contact is created, portal access will be enabled by default',
										}"
									></v-switch>
								</div>
								<v-btn block color="primary" class="mt-6 mb-2" @click="updateDefaultClientModal = true"
									>Update all clients with these settings</v-btn
								>
								<div class="font-12 font-italic">
									Note: You will be able to select which values are updated on the next screen
								</div>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<div v-if="currentView === 'invoice' && $store.getters.isAccountOwner">
				<v-container fluid class="ma-0 pa-0">
					<v-row dense>
						<v-col cols="6" class="pr-3">
							<div class="column-format gap-3">
								<div class="text-left">Invoice settings</div>
								<v-select
									label="Invoice reminder frequency"
									@change="invoiceDueNotificationUpdated"
									hide-details
									persistent-placeholder
									dense
									outlined
									:items="reminderOptions"
									item-text="label"
									item-value="value"
									v-model="$store.state.podAccount.accountPreferences.invoiceDueNotification"
								>
								</v-select>

								<v-text-field
									type="number"
									@change="invoiceCardFeeRateUpdated"
									v-model="$store.state.podAccount.accountPreferences.cardFeeRate"
									hide-details
									persistent-placeholder
									label="Stripe card processing fees"
									suffix="%"
									dense
									outlined
								></v-text-field>

								<v-autocomplete
									hide-details
									persistent-placeholder
									dense
									outlined
									label="PDF / Email locale formatting"
									:items="locales"
									item-text="name"
									item-value="locale"
									clearable
									v-model="$store.state.podAccount.accountPreferences.defaultLocale"
									@change="defaultLocaleUpdated"
								></v-autocomplete>

								<v-checkbox
									hide-details
									dense
									class="mt-1"
									@change="invoicePdfA4PageSizeUpdated"
									v-model="$store.state.podAccount.accountPreferences.invoicePdfA4PageSize"
									label="Use A4 Page Size on PDF Invoice"
								></v-checkbox>
							</div>

							<div class="column-format gap-3">
								<div class="text-left mt-5">Default email templates</div>
								<v-select
									v-if="emailTemplates"
									:disabled="emailTemplates.length === 0 || !isPaidAccount"
									hide-details
									persistent-placeholder
									dense
									outlined
									label="New invoice"
									:items="emailTemplates"
									item-value="id"
									item-text="name"
									clearable
									v-model="$store.state.podAccount.accountPreferences.invoiceEmailTemplate"
									@change="emailTemplatesUpdated"
								></v-select>
								<v-select
									v-if="emailTemplates"
									:disabled="emailTemplates.length === 0 || !isPaidAccount"
									hide-details
									persistent-placeholder
									dense
									outlined
									label="Payment confirmation"
									:items="emailTemplates"
									item-value="id"
									item-text="name"
									clearable
									v-model="$store.state.podAccount.accountPreferences.paymentConfirmationTemplate"
									@change="emailTemplatesUpdated"
								></v-select>
								<v-select
									v-if="emailTemplates"
									:disabled="emailTemplates.length === 0 || !isPaidAccount"
									hide-details
									persistent-placeholder
									dense
									outlined
									label="Due date approaching"
									:items="emailTemplates"
									item-value="id"
									item-text="name"
									clearable
									v-model="$store.state.podAccount.accountPreferences.upcomingInvoiceTemplate"
									@change="emailTemplatesUpdated"
								></v-select>
								<v-select
									v-if="emailTemplates"
									:disabled="emailTemplates.length === 0 || !isPaidAccount"
									hide-details
									persistent-placeholder
									dense
									outlined
									label="Past due notification"
									:items="emailTemplates"
									item-value="id"
									item-text="name"
									clearable
									v-model="$store.state.podAccount.accountPreferences.pastDueInvoiceTemplate"
									@change="emailTemplatesUpdated"
								></v-select>
								<div class="text-left mt-1 mb-1">Payment instructions</div>
								<div class="h-outline">
									<editor
										class="mt-1 px-2 text-left"
										style="width: 100%; min-height: 100px; border-bottom: none;"
										ref="editor"
										:api-key="$store.getters.getTinyMceKey"
										:initial-value="$store.state.podAccount.payInstructions"
										:inline="true"
										:init="mceConfigText"
										spellcheck="true"
										@input="handlePayInstructionsInput"
									></editor>
									<v-btn
										:disabled="!payInstructionsChanged"
										outlined
										color="secondary"
										elevation="0"
										class="my-2"
										@click="payInstructionsUpdated"
										>Save payment instructions</v-btn
									>
								</div>
							</div>
						</v-col>
						<v-col cols="6" class="pl-3">
							<div class="d-flex justify-space-between align-center">
								<div class="text-left">Invoice numbering</div>
								<div class="text-right">
									<v-btn text class="primary-action mr-n4 font-12" @click="invoiceNumberModal = true">{{
										$t('global.edit')
									}}</v-btn>
								</div>
							</div>
							<div class="h-outline text-left py-4 px-3 pointer" @click="invoiceNumberModal = true">
								<div class="font-12 gray_80--text brand-medium">Scheme</div>
								<div class="font-14 gray_90--text brand-semilight">
									{{ formattedFormat }}
								</div>
								<div class="font-12 gray_80--text brand-medium mt-2">Starting number</div>
								<div class="font-14 gray_90--text brand-semilight">
									{{ $store.state.podAccount.accountPreferences.invoiceNumberSeed }}
								</div>
								<div class="font-12 gray_80--text brand-medium mt-2">Invoice number by client?</div>
								<div class="font-14 gray_90--text brand-semilight">
									<span v-if="$store.state.podAccount.accountPreferences.invoiceNumberPerClient">
										Number counts up by client
									</span>
									<span v-else> Number counts up globally (not by client) </span>
								</div>
								<div class="font-12 gray_80--text brand-medium mt-2">Invoice number by year?</div>
								<div class="font-14 gray_90--text brand-semilight">
									<span v-if="$store.state.podAccount.accountPreferences.invoiceNumberPerYear">
										Number resets on
										{{ months[$store.state.podAccount.accountPreferences.fiscalYearStartMonth - 1].text }} 1st
									</span>
									<span v-else> Number does not reset every year </span>
								</div>
							</div>

							<div class="column-format gap-3">
								<div class="text-left mt-4">Compliance Settings</div>
								<v-text-field
									@change="invoiceHeaderUpdated"
									v-model="$store.state.podAccount.accountPreferences.invoiceDocumentHeader"
									hide-details
									persistent-placeholder
									label="Compliance Header"
									placeholder="Header text above logo"
									dense
									outlined
								></v-text-field>

								<v-text-field
									@change="paidInvoiceHeaderUpdated"
									v-model="$store.state.podAccount.accountPreferences.paidInvoiceDocumentHeader"
									hide-details
									persistent-placeholder
									label="Paid invoice compliance Header"
									placeholder="Header text above logo"
									dense
									outlined
								></v-text-field>

								<div class="text-left mt-4">Tax preferences</div>
								<v-text-field
									type="number"
									@change="invoiceDefaultTaxUpdated"
									:disabled="!$store.state.podAccount.accountPreferences.invoiceUseTax"
									v-model="$store.state.podAccount.accountPreferences.defaultTaxRate"
									hide-details
									persistent-placeholder
									label="Default rate"
									suffix="%"
									dense
									outlined
								></v-text-field>
								<v-text-field
									@change="invoiceTaxLabelUpdated"
									:disabled="!$store.state.podAccount.accountPreferences.invoiceUseTax"
									v-model="$store.state.podAccount.accountPreferences.taxLabel"
									hide-details
									persistent-placeholder
									label="Tax label"
									placeholder="Tax, VAT, ISV, etc"
									dense
									outlined
								></v-text-field>
							</div>
							<v-checkbox
								hide-details
								dense
								@change="invoiceUseTaxUpdated"
								v-model="$store.state.podAccount.accountPreferences.invoiceUseTax"
								label="Tax enabled on invoices"
							></v-checkbox>
							<v-checkbox
								hide-details
								dense
								@change="showTaxWithZeroPercentUpdated"
								v-model="$store.state.podAccount.accountPreferences.showTaxWithZeroPercent"
								label="Show tax on 0% invoices"
							></v-checkbox>
							<v-checkbox
								hide-details
								dense
								@change="invoiceLateFeeTaxableUpdated"
								v-model="$store.state.podAccount.accountPreferences.invoiceLateFeeTaxable"
								label="Apply tax to late fees"
							></v-checkbox>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<div v-if="currentView === 'agreement' && $store.getters.isAccountOwner">
				<v-container fluid class="ma-0 pa-0">
					<v-row dense>
						<v-col cols="6" class="pr-3">
							<div class="text-left">Enable geo location on Signatures</div>
							<v-radio-group
								dense
								hide-details
								class="mt-2 pt-0"
								v-model="
									$store.state.podAccount.accountPreferences.proposalPreferences.enableGeoLocationOnSignature
								"
								@change="geoLocationUpdated"
							>
								<v-radio :value="true" class="h-outline pa-2" label="Enabled"> </v-radio>
								<v-radio :value="false" class="h-outline pa-2" label="Disabled"> </v-radio>
							</v-radio-group>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<div v-if="currentView === 'fonts' && isAccountFullUser">
				<v-container fluid class="ma-0 pa-0">
					<v-row dense>
						<v-col cols="12">
							<custom-fonts></custom-fonts>
						</v-col>
					</v-row>
				</v-container>
			</div>

			<basic-modal :dialog="invoiceNumberModal" @close="invoiceNumberModal = false" :persistent="false">
				<invoice-number-format-modal
					v-if="invoiceNumberModal"
					@close="invoiceNumberModal = false"
					@set-account-info="handleInvoiceNumberFormatModalUpdate($event)"
				></invoice-number-format-modal>
			</basic-modal>

			<basic-modal
				:dialog="updateDefaultClientModal"
				@close="updateDefaultClientModal = false"
				:persistent="false"
				style="max-width: 400px"
			>
				<div class="column-format gap-4 pa-4" style="max-width: 400px">
					<div>
						What fields would you like to update on your clients? This will update all of your client records with the
						default values specified.
					</div>
					<div>
						<v-checkbox v-model="clientUpdate.hourlyRate" hide-details dense label="Hourly rate"></v-checkbox>
						<v-checkbox v-model="clientUpdate.lateFee" hide-details dense label="Late fee"></v-checkbox>
						<v-checkbox v-model="clientUpdate.paymentDays" hide-details dense label="Payment days"></v-checkbox>
						<v-checkbox v-model="clientUpdate.stripeFees" hide-details dense label="Stripe fees"></v-checkbox>
						<v-checkbox v-model="clientUpdate.portalAccess" hide-details dense label="Portal access"></v-checkbox>
					</div>
					<v-btn
						block
						color="primary"
						@click="updateClientDefaults"
						:disabled="
							!clientUpdate.hourlyRate &&
								!clientUpdate.lateFee &&
								!clientUpdate.paymentDays &&
								!clientUpdate.stripeFees &&
								!clientUpdate.portalAccess
						"
						>Update</v-btn
					>
				</div>
			</basic-modal>
		</div>
	</div>
</template>

<script>
	import AccountService from '@/modules/account/AccountService';
	import InvoiceNumberFormatModal from '@/modules/invoices/InvoiceNumberFormatModal';
	import BasicModal from '@/components/BasicModal';
	import EmailTemplateService from '@/modules/templates/EmailTemplateService';
	import CustomFonts from '@/modules/account/CustomFonts';
	import jsonPatch from 'fast-json-patch';
	import ConfirmModal from '@/components/ConfirmModal';
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import ClientService from '@/modules/clients/ClientService';
	import PageHeader from '@/components/PageHeader';

	export default {
		name: 'Preferences',

		props: [],

		components: {
			editor: () => import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
			CustomFonts,
			InvoiceNumberFormatModal,
			BasicModal,
			PageHeader,
		},

		data: function() {
			return {
				payInstructionsChanged: false,
				homePage: null,
				darkMode: null,
				weekStartsOn: null,
				accountService: new AccountService(),
				invoiceService: new InvoiceService(),
				clientService: new ClientService(),
				invoiceNumberModal: false,
				updateDefaultClientModal: false,
				currentView: 'app',
				emailTemplates: [],
				observer: null,
				locales: [],

				dayOptions: [
					0,
					1,
					2,
					3,
					4,
					5,
					6,
					7,
					8,
					9,
					10,
					11,
					12,
					13,
					14,
					15,
					16,
					17,
					18,
					19,
					20,
					21,
					30,
					45,
					60,
					90,
					120,
					180,
				],

				reminderOptions: [
					{ label: 'Disabled (no emails)', value: 'DISABLED' },
					{ label: 'Every three days', value: 'THREE_DAYS' },
					{ label: 'Weekly', value: 'WEEKLY' },
					{ label: 'Bi-weekly', value: 'BI_WEEKLY' },
					{ label: 'Monthly', value: 'MONTHLY' },
				],

				months: [
					{ value: 1, text: 'January' },
					{ value: 2, text: 'February' },
					{ value: 3, text: 'March' },
					{ value: 4, text: 'April' },
					{ value: 5, text: 'May' },
					{ value: 6, text: 'June' },
					{ value: 7, text: 'July' },
					{ value: 8, text: 'August' },
					{ value: 9, text: 'September' },
					{ value: 10, text: 'October' },
					{ value: 11, text: 'November' },
					{ value: 12, text: 'December' },
				],

				paidByOptions: [
					{ text: 'No', value: 'Freelancer' },
					{ text: 'Yes', value: 'Client' },
					{ text: 'Split it', value: 'Split' },
				],

				mceConfigText: {
					menubar: false,
					inline: true,
					browser_spellcheck: true,
					forced_root_block: '',
					plugins: ['paste'],
					paste_as_text: true,
					toolbar: [],
				},

				clientUpdate: {
					hourlyRate: false,
					lateFee: false,
					paymentDays: false,
					stripeFees: false,
					portalAccess: false,
				},
			};
		},

		mounted() {
			this.darkMode = this.$store.state.darkMode;
			this.homePage = this.$store.state.homePage;
			this.weekStartsOn = this.$store.state.weekStartsOn;
			if (this.isPaidAccount) {
				this.getEmailTemplates();
			}
			this.observer = jsonPatch.observe(this.$store.state.podAccount);
			this.getLocales();
		},

		beforeDestroy() {},

		methods: {
			getLocales: function() {
				this.accountService.getLocales().then((res) => {
					this.locales.splice(0);
					this.locales.push(...res.data);
					this.locales.sort((a, b) => a.name.localeCompare(b.name));
				});
			},

			payInstructionsUpdated() {
				const patch = jsonPatch.generate(this.observer, false);
				if (patch.length > 0) {
					let binding = {
						headingText: 'Update recurring invoices?',
						bodyText:
							'Would you like to update all of your recurring invoice templates with your revised payment instructions?',
					};
					this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
						this.doUpdate(patch).then(() => {
							if (res) {
								this.invoiceService.resetRecurringPaymentTerms();
							}
						});
					});
				}
			},

			updateClientDefaults: function() {
				this.clientService
					.updateClientDefaults(this.clientUpdate)
					.then(() => {
						this.$store.commit('success', 'Client settings have been updated.');
						this.updateDefaultClientModal = false;
					})
					.catch((err) => this.$store.commit('error', err.response.data.message));
			},

			handlePayInstructionsInput: function() {
				this.payInstructionsChanged = true;
				this.$store.state.podAccount.payInstructions = this.$refs.editor.editor.getContent();
			},

			setView: function(view) {
				this.currentView = view;
			},

			getEmailTemplates: function() {
				new EmailTemplateService().getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0, this.emailTemplates.length);
					this.emailTemplates.push(...res.data);
					this.emailTemplates.sort((a, b) => a.name.localeCompare(b.name));
				});
			},

			darkModeUpdated: function() {
				localStorage.setItem('DARK_MODE', this.darkMode);
				this.$store.state.darkMode = this.darkMode;
				this.$store.state.eventBus.$emit('dark-mode-changed', this.darkMode);
			},

			weekStartsOnUpdated: function() {
				localStorage.setItem('WEEK_STARTS_ON', this.weekStartsOn);
				this.$store.state.weekStartsOn = this.weekStartsOn;
				if (this.isAccountOwner) {
					this.patchAccount('/accountPreferences/weekStartsOn', this.weekStartsOn);
				}
			},

			homePageUpdated: function() {
				localStorage.setItem('HOME_PAGE', this.homePage);
				this.$store.state.homePage = this.homePage;
			},

			handleInvoiceNumberFormatModalUpdate(event) {
				this.$store.state.podAccount = event;
			},

			updateDefaultHourlyRate: function() {
				this.patchAccount(
					'/accountPreferences/defaultHourlyRate',
					this.$store.state.podAccount.accountPreferences.defaultHourlyRate
				);
			},

			updateDefaultLatePaymentFee: function() {
				this.patchAccount(
					'/accountPreferences/defaultLatePaymentFee',
					this.$store.state.podAccount.accountPreferences.defaultLatePaymentFee
				);
			},

			updateDefaultPaymentDays: function() {
				this.patchAccount(
					'/accountPreferences/defaultPaymentDays',
					this.$store.state.podAccount.accountPreferences.defaultPaymentDays
				);
			},

			updateDefaultWhoPaysCardFees: function() {
				this.patchAccount(
					'/accountPreferences/defaultWhoPaysCardFees',
					this.$store.state.podAccount.accountPreferences.defaultWhoPaysCardFees
				);
			},

			updateDefaultPortalAccess: function() {
				this.patchAccount(
					'/accountPreferences/defaultPortalAccess',
					this.$store.state.podAccount.accountPreferences.defaultPortalAccess
				);
			},

			showClientLogosUpdated: function() {
				this.patchAccount(
					'/accountPreferences/showClientLogos',
					this.$store.state.podAccount.accountPreferences.showClientLogos
				);
			},

			showScrollBarsUpdated: function() {
				localStorage.setItem('SHOW_SCROLL_BARS', JSON.stringify(this.$store.state.showScrollBars));
			},

			invoiceFormatUpdated: function() {
				this.patchAccount(
					'/accountPreferences/invoiceFormat',
					this.$store.state.podAccount.accountPreferences.invoiceFormat
				);
			},

			invoiceUseTaxUpdated: function() {
				this.patchAccount(
					'/accountPreferences/invoiceUseTax',
					this.$store.state.podAccount.accountPreferences.invoiceUseTax
				);
			},

			invoiceLateFeeTaxableUpdated: function() {
				this.patchAccount(
					'/accountPreferences/invoiceLateFeeTaxable',
					this.$store.state.podAccount.accountPreferences.invoiceLateFeeTaxable
				);
			},

			invoiceDefaultTaxUpdated: function() {
				this.patchAccount(
					'/accountPreferences/defaultTaxRate',
					this.$store.state.podAccount.accountPreferences.defaultTaxRate
				);
			},

			invoiceHeaderUpdated: function() {
				this.patchAccount(
					'/accountPreferences/invoiceDocumentHeader',
					this.$store.state.podAccount.accountPreferences.invoiceDocumentHeader
				);
			},

			paidInvoiceHeaderUpdated: function() {
				this.patchAccount(
					'/accountPreferences/paidInvoiceDocumentHeader',
					this.$store.state.podAccount.accountPreferences.paidInvoiceDocumentHeader
				);
			},

			invoiceTaxLabelUpdated: function() {
				this.patchAccount('/accountPreferences/taxLabel', this.$store.state.podAccount.accountPreferences.taxLabel);
			},

			showTaxWithZeroPercentUpdated: function() {
				this.patchAccount(
					'/accountPreferences/showTaxWithZeroPercent',
					this.$store.state.podAccount.accountPreferences.showTaxWithZeroPercent
				);
			},

			invoicePdfA4PageSizeUpdated: function() {
				this.patchAccount(
					'/accountPreferences/invoicePdfA4PageSize',
					this.$store.state.podAccount.accountPreferences.invoicePdfA4PageSize
				);
			},

			invoiceDueNotificationUpdated: function() {
				this.patchAccount(
					'/accountPreferences/invoiceDueNotification',
					this.$store.state.podAccount.accountPreferences.invoiceDueNotification
				);
			},

			autoCreateProjectUpdated: function() {
				this.patchAccount(
					'/accountPreferences/proposalPreferences/autoCreateProject',
					this.$store.state.podAccount.accountPreferences.proposalPreferences.autoCreateProject
				);
			},

			autoCreateProjectQuoteUpdated: function() {
				this.patchAccount(
					'/accountPreferences/proposalPreferences/autoCreateProjectQuote',
					this.$store.state.podAccount.accountPreferences.proposalPreferences.autoCreateProjectQuote
				);
			},

			autoCreateInvoiceUpdated: function() {
				this.patchAccount(
					'/accountPreferences/proposalPreferences/autoCreateInvoice',
					this.$store.state.podAccount.accountPreferences.proposalPreferences.autoCreateInvoice
				);
			},

			autoCreateInvoiceQuoteUpdated: function() {
				this.patchAccount(
					'/accountPreferences/proposalPreferences/autoCreateInvoiceQuote',
					this.$store.state.podAccount.accountPreferences.proposalPreferences.autoCreateInvoiceQuote
				);
			},

			geoLocationUpdated: function() {
				this.patchAccount(
					'/accountPreferences/proposalPreferences/enableGeoLocationOnSignature',
					this.$store.state.podAccount.accountPreferences.proposalPreferences.enableGeoLocationOnSignature
				);
			},

			invoiceCardFeeRateUpdated: function() {
				this.patchAccount('/accountPreferences/cardFeeRate', this.$store.state.podAccount.accountPreferences.cardFeeRate);
			},

			defaultLocaleUpdated: function() {
				this.patchAccount(
					'/accountPreferences/defaultLocale',
					this.$store.state.podAccount.accountPreferences.defaultLocale
				);
			},

			emailTemplatesUpdated: function() {
				const patch = [
					{
						op: 'replace',
						path: '/accountPreferences/invoiceEmailTemplate',
						value: this.$store.state.podAccount.accountPreferences.invoiceEmailTemplate,
					},
					{
						op: 'replace',
						path: '/accountPreferences/paymentConfirmationTemplate',
						value: this.$store.state.podAccount.accountPreferences.paymentConfirmationTemplate,
					},
					{
						op: 'replace',
						path: '/accountPreferences/upcomingInvoiceTemplate',
						value: this.$store.state.podAccount.accountPreferences.upcomingInvoiceTemplate,
					},
					{
						op: 'replace',
						path: '/accountPreferences/pastDueInvoiceTemplate',
						value: this.$store.state.podAccount.accountPreferences.pastDueInvoiceTemplate,
					},
				];
				this.doUpdate(patch);
			},

			patchAccount: function(path, value) {
				const patch = [
					{
						op: 'replace',
						path: path,
						value: value,
					},
				];
				this.doUpdate(patch);
			},

			doUpdate: function(patch) {
				return new Promise((resolve, reject) => {
					this.$store.commit('startLoading');
					this.accountService
						.patchAccount(patch)
						.then((res) => {
							this.payInstructionsChanged = false;
							this.$store.state.podAccount = res.data;
							this.observer = jsonPatch.observe(this.$store.state.podAccount);
							resolve();
						})
						.catch((err) => {
							this.$store.commit('error', err.response.data.message);
							reject();
						})
						.finally(() => this.$store.commit('stopLoading'));
				});
			},
		},

		computed: {
			currencySymbol: function() {
				return this.$formatters.currencySymbol(this.$store.state.defaultCurrency);
			},

			isAccountOwner: function() {
				return this.$store.getters.isAccountOwner;
			},

			isPaidAccount: function() {
				return this.$store.getters.isPaidAccount;
			},

			isAccountFullUser: function() {
				return this.$store.getters.isAccountFullUser;
			},

			pages: function() {
				let result = [];
				result.push({ label: 'App settings', value: 'app' });

				if (this.$store.getters.isAccountOwner) {
					result.push({ label: 'Clients', value: 'client' });
					result.push({ label: 'Invoices', value: 'invoice' });
					result.push({ label: 'Agreements', value: 'agreement' });
				}

				if (this.isAccountFullUser) {
					result.push({ label: 'Custom fonts', value: 'fonts' });
				}

				return result;
			},

			formattedFormat() {
				let ret = this.$store.state.podAccount.accountPreferences.invoiceNumberFormat.replaceAll('{{', '');
				return ret.replaceAll('}}', '');
			},
		},
	};
</script>

<style lang="scss"></style>
