<template>
	<div id="booking-detail" style="width: 400px">
		<div class="modal-padding">
			<div class="modal-title">{{DateTime.fromISO(bookingDetail.month).toLocaleString({month:'long',year:'numeric'})}} scheduled income for {{bookingDetail.client.label}}</div>
			<div>
				<v-treeview class="text-left custom-tree"
						:items="treeData"
				></v-treeview>
			</div>
		</div>
	</div>
</template>

<script>
	import {v4 as uuidv4} from 'uuid';
	import {DateTime} from 'luxon';
	export default {
		name: 'BookingDetail',

		props: ['bookingDetail','currency'],

		components: {},

		data: function() {
			return {
				DateTime: DateTime,
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			groupBy: function(array, key) {
				return array.reduce((result, currentValue) => {
					(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
					return result;
				}, {});
			},

			buildTree: function(sourceArray, label, useProject = true){
				let totalRecurring = sourceArray.map(b => b.amount).reduce((total, amount) => total + amount)

				let treeItem = {
					id: uuidv4(),
					name: `${this.$formatters.dollars(totalRecurring,true,true,this.currency)} - ${label}`,
					children: []
				}

				sourceArray.forEach(b => {
					let name = this.$formatters.dollars(b.amount,true,true,this.currency);

					if(useProject){
						name = `${name} - ${b.project.name}`
					}

					treeItem.children.push({
						id: uuidv4(),
						amount: b.amount,
						name: name
					})
				})

				treeItem.children.sort((a,b) => b.amount-a.amount);
				return treeItem;
			}

		},

		computed: {
			treeData: function(){
				let groupedBookings = this.groupBy(this.bookingDetail.bookings,'bookingType');
				let items = [];

				if(groupedBookings.RECURRING_PROJECT){
					items.push(this.buildTree(groupedBookings.RECURRING_PROJECT,'Recurring projects'));
				}

				if(groupedBookings.RECURRING_INVOICE){
					items.push(this.buildTree(groupedBookings.RECURRING_INVOICE,'Recurring invoices',false));
				}

				if(groupedBookings.PAYMENT_PLAN){
					items.push(this.buildTree(groupedBookings.PAYMENT_PLAN,'Payment plans',false));
				}

				return items;
			},
		},
	};
</script>

<style lang="scss">
	.custom-tree {
		.v-treeview-node__label {
			font-size: 14px!important;
			line-height: 2!important;
		}
		.v-treeview-node__root {
			min-height: 12px!important;
		}
	}
</style>
