var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { label: "Project management" }
            ]
          }
        },
        [_vm._v(" Project list ")]
      ),
      _vm.isInitialized
        ? _c("div", { staticClass: "column-format fill-height" }, [
            _c(
              "div",
              { staticClass: "pb-4 row-format align-center gap-3 flex-wrap" },
              [
                _c(
                  "div",
                  { staticClass: "row-format align-center gap-3" },
                  [
                    _c("v-text-field", {
                      staticStyle: {
                        "max-width": "200px",
                        "background-color": "var(--v-white-base)"
                      },
                      attrs: {
                        outlined: "",
                        "hide-details": "",
                        dense: "",
                        placeholder: "Search...",
                        color: "gray_30"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend-inner",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "material-symbols-rounded font-gray_50"
                                  },
                                  [_vm._v("search")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        95475665
                      ),
                      model: {
                        value: _vm.filter.search,
                        callback: function($$v) {
                          _vm.$set(_vm.filter, "search", $$v)
                        },
                        expression: "filter.search"
                      }
                    }),
                    _c(
                      "div",
                      [
                        _c("project-list-filter", {
                          attrs: { filter: _vm.filter },
                          on: { updated: _vm.saveCurrentFilter }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("project-list-sort", {
                          attrs: { filter: _vm.filter },
                          on: { updated: _vm.saveCurrentFilter }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ml-auto row-format align-center gap-3" },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: { content: "List" },
                            expression: "{ content: 'List' }"
                          }
                        ],
                        staticClass: "pointer row-format align-center",
                        on: {
                          click: function($event) {
                            return _vm.setView("LIST")
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "material-symbols-rounded",
                            style:
                              "font-size: 24px; color: var(--v-" +
                              (_vm.currentView === "LIST"
                                ? "primary"
                                : "gray_60") +
                              "-base)"
                          },
                          [_vm._v("list")]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tippy",
                            rawName: "v-tippy",
                            value: { content: "Card" },
                            expression: "{ content: 'Card' }"
                          }
                        ],
                        staticClass: "pointer row-format align-center",
                        on: {
                          click: function($event) {
                            return _vm.setView("CARD")
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "material-symbols-rounded",
                            style:
                              "font-size: 24px; color: var(--v-" +
                              (_vm.currentView === "CARD"
                                ? "primary"
                                : "gray_60") +
                              "-base)"
                          },
                          [_vm._v("grid_on")]
                        )
                      ]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "super-action",
                        attrs: { disabled: !_vm.isAccountFullUser },
                        on: {
                          click: function($event) {
                            return _vm.createNewProject()
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { size: "20" } }, [
                          _vm._v("add")
                        ]),
                        _vm._v(" Add project")
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm.projects.length
              ? _c("div", [
                  _vm.currentView === "CARD"
                    ? _c("div", { staticClass: "column-format" }, [
                        _c(
                          "div",
                          {
                            staticClass: "row-format",
                            staticStyle: { "flex-wrap": "wrap", gap: "16px" }
                          },
                          _vm._l(_vm.filteredProjects, function(project) {
                            return _c("project-card", {
                              key: project.id,
                              attrs: { project: project },
                              on: {
                                click: function($event) {
                                  return _vm.routeToProject(project, $event)
                                },
                                edit: function($event) {
                                  return _vm.editProject(project)
                                },
                                "mark-complete": function($event) {
                                  return _vm.markAsComplete(project)
                                }
                              }
                            })
                          }),
                          1
                        )
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "column-format",
                          staticStyle: {
                            "background-color": "var(--v-white-base)",
                            border: "1px solid var(--v-gray_30-base)",
                            "border-radius": "4px"
                          }
                        },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "px-0 mt-n2",
                              staticStyle: {
                                "max-width": "100%",
                                overflow: "hidden"
                              },
                              attrs: { fluid: "" }
                            },
                            _vm._l(_vm.filteredProjects, function(
                              project,
                              index
                            ) {
                              return _c("project-row", {
                                key: project.id,
                                attrs: {
                                  "show-collaborators": _vm.anyCollaborators,
                                  project: project,
                                  index: index
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.routeToProject(project, $event)
                                  },
                                  edit: function($event) {
                                    return _vm.editProject(project)
                                  },
                                  "mark-complete": function($event) {
                                    return _vm.markAsComplete(project)
                                  },
                                  "client-transfer": function($event) {
                                    return _vm.clientTransfer(project)
                                  }
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                ])
              : _c(
                  "div",
                  {
                    staticClass: "row-format centered fill-height",
                    staticStyle: { "min-height": "600px" }
                  },
                  [
                    _c("empty-view", {
                      attrs: {
                        header: "No projects yet",
                        body:
                          "Let's create your first project! That’s the best way to track deadlines, add tasks, and get paid for the work you’ve done.",
                        cta: "Create a project",
                        "video-header": "See how it works",
                        "video-body":
                          "The projects view within client details is a little bit different from the project management page. We’ll show you how they’re different and how to use them.",
                        "video-cta": "Watch the tutorial",
                        "video-id": "WzMkYNIIxSM"
                      },
                      on: {
                        "cta-clicked": function($event) {
                          return _vm.createNewProject()
                        }
                      }
                    })
                  ],
                  1
                )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }