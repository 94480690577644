var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "350px" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.handleClose()
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _vm.isReady
        ? _c("div", { staticClass: "modal-padding text-left" }, [
            _c("h3", { staticClass: "modal-title" }, [
              _vm._v("Select meeting template")
            ]),
            _c(
              "ul",
              _vm._l(_vm.meetingsList, function(meeting) {
                return _c(
                  "li",
                  {
                    key: meeting.id,
                    staticClass: "pointer",
                    on: {
                      click: function($event) {
                        return _vm.selectMeeting(meeting)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(meeting.name) + " ")]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }