<template>
	<div>
		<date-selector
			:micro="mode !== 'standard' && mode !== 'dense'"
			:standard="mode === 'standard' || mode === 'dense'"
			:show-year="true"
			micro-font="font-14"
			:date="value"
			:label="mode === 'standard' || mode === 'dense' ? field.name : '--'"
			@change="ourValue = $event"
		></date-selector>
	</div>
</template>

<script>
	import DateSelector from '@/components/DateSelector';
	export default {
		name: 'CustomDate',

		props: ['field', 'value','mode'],

		components: { DateSelector },

		data: function() {
			return {
				ourValue: this.value,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		watch: {
			ourValue: function(val){
				this.$emit('change',val);
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
