export default class MicrosoftEmailService {
    getScopes(){
        return `User.Read https://outlook.office.com/IMAP.AccessAsUser.All https://outlook.office.com/SMTP.Send offline_access`;
    }

    getRedirectUri(){
        return  document.location.protocol + '//' + document.location.host + '/integrations/microsoft/oauth';
    }

    getClientId(){
        return process.env.VUE_APP_MICROSOFT_CLIENT_ID;
    }

    getLoginUrl(){
        return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${this.getClientId()}&response_type=code&scope=${encodeURIComponent(this.getScopes())}&prompt=select_account&redirect_uri=${encodeURIComponent(this.getRedirectUri())}`
    }
}