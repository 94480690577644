var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.signatureContainerCss },
    [
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0", attrs: { fluid: "", "fill-height": "" } },
        [
          _vm.isSigned
            ? _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _vm.shouldRenderKeyboard
                      ? _c(
                          "div",
                          {
                            staticClass: "signatureWrapper",
                            style: _vm.signatureFont
                          },
                          [
                            _c("div", { staticClass: "signature" }, [
                              _vm._v(_vm._s(_vm.signatureObj.signature))
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm.shouldRenderPad
                      ? _c("div", { staticClass: "svgSignature" }, [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.svgSignature) }
                          })
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e(),
          !_vm.isSigned
            ? _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _vm.isApp
                    ? _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _vm.setSigner
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row"
                                      },
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ml-2",
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column",
                                            width: "90%"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "font-size": "0.9em"
                                            },
                                            attrs: {
                                              label: _vm.$t("proposal.name"),
                                              "hide-details": true
                                            },
                                            nativeOn: {
                                              keypress: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.saveSignerInfo()
                                              }
                                            },
                                            model: {
                                              value: _vm.signerName,
                                              callback: function($$v) {
                                                _vm.signerName = $$v
                                              },
                                              expression: "signerName"
                                            }
                                          }),
                                          _c("v-text-field", {
                                            staticStyle: {
                                              "font-size": "0.9em"
                                            },
                                            attrs: {
                                              label: _vm.$t("proposal.title"),
                                              "hide-details": true
                                            },
                                            nativeOn: {
                                              keypress: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.saveSignerInfo()
                                              }
                                            },
                                            model: {
                                              value: _vm.signerTitle,
                                              callback: function($$v) {
                                                _vm.signerTitle = $$v
                                              },
                                              expression: "signerTitle"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.setSigner
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: { cols: "12", align: "center" }
                                    },
                                    [
                                      _vm.isThem && !_vm.editMode
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: true,
                                                text: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("proposals.pending")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isThem && _vm.editMode
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                disabled: _vm.templateMode
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setSignerInfo()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    this.signatureObj
                                                      .signerLocked
                                                      ? "Change signatory"
                                                      : "Set signatory (optional)"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isUs
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                disabled: _vm.templateMode
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.doSign = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.editMode
                                                      ? "Sign before sending"
                                                      : "Sign"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isPortal
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                            [
                              _vm.isThem
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        disabled: !_vm.deliverableSelected
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.requestThemSign()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.deliverableSelected
                                              ? "Sign"
                                              : "Select Package"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isUs
                                ? _c(
                                    "v-btn",
                                    { attrs: { text: "", disabled: "" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("proposals.pending")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          !_vm.isSigned
            ? _c(
                "basic-modal",
                {
                  attrs: { dialog: _vm.doSign, persistent: "" },
                  on: {
                    close: function($event) {
                      _vm.doSign = false
                    }
                  }
                },
                [
                  _c("signature-dialog", {
                    attrs: {
                      "signature-object": _vm.signatureObj,
                      mode: _vm.mode,
                      "client-mode": _vm.clientMode
                    },
                    on: {
                      cancel: function($event) {
                        _vm.doSign = false
                      },
                      signed: function($event) {
                        return _vm.finalizeSign($event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }