<template>
	<div>
		<v-container style="max-width: 985px!important;" class="mt-4">
			<v-row>
				<v-col cols="12">
					<div style="font-size: 48px" class="">{{ $t('subscription-sumo.heading') }}</div>
					<div style="font-size: 32px" class="brand-semilight">{{ $t('subscription-sumo.sub-heading') }}</div>
				</v-col>
			</v-row>
			<v-row v-if="$store.getters.isDisabledAccount">
				<v-col cols="12">
					<div class="my-1 brand-medium row-format centered gap-2">
						<v-icon color="error">info</v-icon>
						<div style="color: var(--v-error-base)">
							Your current subscription has expired. Please select a new plan to continue using Moxie.
						</div>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<div class="row-format gap-4 pa-4" style="justify-content: center">
						<plan-card
							:current-plan="currentPlan"
							:plan="plan"
							v-for="plan in plans"
							:key="plan.code"
						></plan-card>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div class="brand-medium font-24 text-left mt-8">Frequently Asked Questions</div>
					<div
						v-for="(q, index) in faq"
						:key="index"
						class="text-left brand-semilight my-4 pa-5"
						style="background-color: var(--v-gray_10-base);border-radius: 12px"
					>
						<div
							class="font-20 row-format pointer"
							@click="isExpanded(index) ? collapseFaq(index) : expandFaq(index)"
						>
							<div>{{ q.question }}</div>
							<v-icon class="ml-auto" color="black">{{ isExpanded(index) ? '$minus' : '$plus' }}</v-icon>
						</div>
						<div class="mt-3" style="white-space: pre-wrap" v-if="isExpanded(index)">{{ q.answer }}</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import PlanCard from "@/modules/subscription/sumo/PlanCard";

	export default {
		name: 'Subscription',

		props: ['pricingVersion'],

		components: { PlanCard },

		data: function() {
			return {
				plans: [
					{code:'tier-1'},
					{code:'tier-2'},
					{code:'tier-3'},
				],
				expandedFaq: [],
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {
			expandFaq: function(index) {
				this.expandedFaq.push(index);
			},

			collapseFaq: function(index) {
				let ix = this.expandedFaq.findIndex((f) => f === index);
				if (ix > -1) {
					this.expandedFaq.splice(ix, 1);
				}
			},

			isExpanded: function(index) {
				let ix = this.expandedFaq.findIndex((f) => f === index);
				return ix > -1;
			},

		},

		computed: {
			currentPlan: function(){
				let subscriptionType = this.$store.state.selectedUserAccount.account.subscriptionType;
				let maxTeamMembers = this.$store.state.selectedUserAccount.account.maxTeamMembers;
				if(subscriptionType === 'PAID'){
					return {code: 'tier-1'}
				}else{
					if(maxTeamMembers === 10){
						return {code: 'tier-2'}
					}else{
						return {code: 'tier-3'}
					}
				}
			},

			faq: function() {
				return this.$t('subscription-sumo.faq');
			},
		},
	};
</script>

<style scoped lang="scss"></style>
