var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mark-paid-modal" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-dialog",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("v-icon", [_vm._v("$close")])],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-padding" },
        [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("invoice.mark-paid-modal.heading", {
                    invoiceNumber: _vm.invoice.invoiceNumber
                  })
                ) +
                " "
            )
          ]),
          _c("div", { staticClass: "modal-subtitle" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("invoice.mark-paid-modal.subheading", {
                    invoiceNumber: _vm.invoice.invoiceNumber
                  })
                ) +
                " "
            )
          ]),
          _c("div", { staticClass: "amount-due-wrapper" }, [
            _c("div", { staticClass: "amount-due-label" }, [
              _vm._v(_vm._s(_vm.$t("invoice.mark-paid-modal.due-label")))
            ]),
            _c("div", { staticClass: "amount-due grey-text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$formatters.dollars(
                      _vm.invoice.amountDue,
                      true,
                      true,
                      _vm.invoice.currency
                    )
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "client-name" },
            [
              _c("client-avatar", {
                attrs: { client: _vm.invoice.clientInfo, left: "" }
              }),
              _c("div", [_vm._v(_vm._s(_vm.invoice.clientInfo.name))])
            ],
            1
          ),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              type: "number",
              label: _vm.$t("invoice.mark-paid-modal.payment-amount"),
              placeholder: "0.00",
              min: 0,
              max: _vm.amountDue,
              prefix: _vm.currencySymbol
            },
            model: {
              value: _vm.payment.amount,
              callback: function($$v) {
                _vm.$set(_vm.payment, "amount", $$v)
              },
              expression: "payment.amount"
            }
          }),
          _vm.invoice.currency !== _vm.$store.state.defaultCurrency
            ? _c("v-text-field", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  type: "number",
                  label: "Exchange rate (optional)",
                  placeholder: "0.0000"
                },
                model: {
                  value: _vm.payment.exchangeRate,
                  callback: function($$v) {
                    _vm.$set(_vm.payment, "exchangeRate", $$v)
                  },
                  expression: "payment.exchangeRate"
                }
              })
            : _vm._e(),
          _c("date-selector", {
            attrs: {
              date: _vm.datePaid,
              label: _vm.$t("invoice.mark-paid-modal.date-of-payment")
            },
            on: {
              change: function($event) {
                _vm.datePaid = $event
              }
            }
          }),
          _c("v-select", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              label: _vm.$t("invoice.mark-paid-modal.payment-type"),
              items: _vm.paymentProviders
            },
            model: {
              value: _vm.payment.paymentProvider,
              callback: function($$v) {
                _vm.$set(_vm.payment, "paymentProvider", $$v)
              },
              expression: "payment.paymentProvider"
            }
          }),
          _c("v-text-field", {
            staticClass: "h-outline",
            attrs: {
              "persistent-placeholder": "",
              "hide-details": "",
              type: "text",
              label: _vm.$t("invoice.mark-paid-modal.memo"),
              placeholder: _vm.$t("invoice.mark-paid-modal.memo-placeholder")
            },
            model: {
              value: _vm.payment.memo,
              callback: function($$v) {
                _vm.$set(_vm.payment, "memo", $$v)
              },
              expression: "payment.memo"
            }
          }),
          _vm.payment.amount && _vm.payment.amount < _vm.amountDue
            ? _c("v-checkbox", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  label: _vm.$t("invoice.mark-paid-modal.mark-fully-paid")
                },
                model: {
                  value: _vm.payment.forcePaidInFull,
                  callback: function($$v) {
                    _vm.$set(_vm.payment, "forcePaidInFull", $$v)
                  },
                  expression: "payment.forcePaidInFull"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "v-btn",
            {
              staticClass: "primary-action",
              attrs: { disabled: !_vm.payment.amount },
              on: { click: _vm.handleSave }
            },
            [_vm._v(_vm._s(_vm.$t("invoice.mark-paid-modal.save-button")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }