<template>
	<div style="background-color: var(--v-white-base)" class="pa-3">
		<div class="row-format align-center">
			<div>Time worked events</div>
			<v-icon class="ml-auto" @click="handleClose">$close</v-icon>
		</div>
		<v-data-table
			v-if="isReady"
			:key="refreshKey"
			:headers="headers"
			:items="eventList"
			:hide-default-footer="true"
			sort-by="timerStart"
			:sort-desc="true"
			:fixed-header="true"
			:height="`${eventList.length < 5 ? eventList.length * 75 + 50 : 500}px`"
			style="cursor: pointer"
			:items-per-page="-1"
			@click:row="editTimerEvent($event)"
		>
			<template v-slot:item.invoiceId="{ item }">
				<div class="brand-semilight font-12" v-html="getStatus(item)"></div>
			</template>

			<template v-slot:item.clientId="{ item }">
				<client-avatar small v-if="item.client" :client="item.client"></client-avatar>
			</template>

			<template v-slot:item.timerStartStop="{ item }">
				<span class="brand-semilight"
					>{{ DateTime.fromISO(item.timerStart).toFormat('t').replace(' ', '').toLowerCase() }}-{{
						DateTime.fromISO(item.timerEnd).toFormat('t').replace(' ', '').toLowerCase()
					}}</span
				>
			</template>

			<template v-slot:item.timerStart="{ item }">
				<span class="brand-semilight">{{ DateTime.fromISO(item.timerStart).toFormat('DD') }}</span>
			</template>

			<template v-slot:item.duration="{ item }">
				<span class="brand-semilight">{{ formatSeconds(item.duration) }}</span>
			</template>

			<template v-slot:item.projectName="{ item }">
				<span class="brand-semilight">{{ item.projectName ? item.projectName : ' -- ' }}</span>
			</template>

			<template v-slot:item.deliverableName="{ item }">
				<span class="brand-semilight">{{ item.deliverableName ? item.deliverableName : ' -- ' }}</span>
			</template>

			<template v-slot:header.action="{}">
				<div class="row-format">
					<v-icon size="20" class="ml-auto mr-1 pointer" @click="downloadReport()">$download</v-icon>
				</div>
			</template>

			<template v-slot:item.action="{}">
				<div class="row-format">
					<v-icon size="20" class="ml-auto mr-1 pointer" style="">$moreHorizontal</v-icon>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
	import DateTime from "@/modules/utils/HDateTime";
	import ClientAvatar from '@/components/ClientAvatar';
	import TimeTrackService from "@/modules/timetracking/TimeTrackService";

	export default {
		name: 'AggregateTimerList',

		props: ['events'],

		components: { ClientAvatar },

		data: function () {
			return {
				DateTime: DateTime,
				timeTrackService: new TimeTrackService(),
				eventList: [...this.events],
				isReady: false,
				refreshKey: 0,
			};
		},

		mounted() {
			this.eventList.forEach((e) => {
				if (e.clientId) {
					e.client = this.$store.getters.clients.find((c) => c.id === e.clientId);
				}
			});
			this.eventList.sort(this.sortByDate);
			this.isReady = true;
		},

		beforeDestroy() {},

		methods: {
			sortByDate: function(a,b){
				return a.timerStart.localeCompare(b.timerStart);
			},

			handleClose: function () {
				this.$emit('result', this.eventList);
			},

			editTimerEvent: function (timerEvent) {
				let binding = {
					timerEvent: timerEvent,
					client: this.client,
				};

				this.$store.state.globalModalController.openModal(TimeTrackEdit, binding).then((res) => {
					if (!res) return;
					if (res.action && res.action === 'DELETED') {
						this.timerDeleted(timerEvent.id);
					} else {
						this.timerUpdated(res);
					}
					this.refreshKey++;
				});
			},

			timerUpdated(timerEvent) {
				if (timerEvent.clientId) {
					timerEvent.client = this.$store.getters.clients.find((c) => c.id === timerEvent.clientId);
				} else {
					timerEvent.client = null;
				}

				let ix = this.eventList.findIndex((e) => e.id === timerEvent.id);
				if (ix > -1) {
					this.eventList.splice(ix, 1, timerEvent);
				}
			},

			timerDeleted(id) {
				let ix = this.eventList.findIndex((e) => e.id === id);
				if (ix > -1) {
					this.eventList.splice(ix, 1);
				}
			},

			formatSeconds: function (seconds) {
				let hours = Math.floor(seconds / 3600);
				let minutes = Math.floor((seconds - hours * 3600) / 60);
				seconds = seconds - hours * 3600 - minutes * 60;

				if (hours < 10) {
					hours = '0' + hours;
				}
				if (minutes < 10) {
					minutes = '0' + minutes;
				}
				if (seconds < 10) {
					seconds = '0' + seconds;
				}
				let time = hours + ':' + minutes + ':' + seconds;
				return time;
			},

			getStatus(event) {
				let background, color, label;

				if (event.invoiceId) {
					background = '--v-success_10-base';
					color = '--v-success';
					label = 'Billed';
				}else if(event.billable && (!event.projectId || (event.feeSchedule && event.feeSchedule.feeType === 'Hourly'))){
					background = '--v-gray_20-base';
					color = '--v-gray_80-base';
					label = 'Un-billed';
				} else {
					background = '';
					color = '';
					label = '--';
				}

				return `<div class="invoice-status" style="--color:var(${color});--background:var(${background})">${label}</div>`;
			},


			downloadReport: function() {
				this.$store.commit('startLoading');
				let eventIds = this.eventList.map((e) => e.id);
				this.timeTrackService
						.timerEventDownload(this.earliest.toISO(), this.latest.minus({ seconds: 1 }).toISO(), eventIds)
						.then((res) => {
							let fileURL = window.URL.createObjectURL(res.data);
							let fileLink = document.createElement('a');
							fileLink.href = fileURL;
							fileLink.setAttribute(
									'download',
									'TimerEvents-' + this.earliest.toISODate() + '-thru-' + this.latest.toISODate() + '.xlsx'
							);
							document.body.appendChild(fileLink);
							fileLink.click();
						})
						.finally(() => this.$store.commit('stopLoading'));
			},
		},

		computed: {
			earliest: function(){
				return DateTime.fromISO(this.eventList[0].timerStart).startOf('month');
			},

			latest: function(){
				return DateTime.fromISO(this.eventList[this.eventList.length-1].timerStart).endOf('month');
			},

			headers: function () {
				let result = [
					{ text: this.$t('timetrack.list.status'), value: 'invoiceId' },
					{ text: this.$t('client.client'), value: 'clientId' },
					{ text: this.$t('timetrack.list.date'), value: 'timerStart' },
					{ text: this.$t('timetrack.list.start-stop'), value: 'timerStartStop' },
					{ text: this.$t('timetrack.list.time-worked'), value: 'duration' },
					{ text: this.$t('timetrack.list.project'), value: 'projectName' },
					{ text: this.$t('timetrack.list.deliverable'), value: 'deliverableName' },
					{ text: null, value: 'action', align: 'right', sortable: false },
				];

				return result;
			},
		},
	};
</script>

<style scoped lang="scss">
	.invoice-status {
		width: fit-content;
		padding: 4px 8px;
		flex: none;
		order: 0;
		flex-grow: 0;
		background-color: var(--background);
		color: var(--color);
		border-radius: 4px;
	}
</style>
