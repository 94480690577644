<template>
	<div class="column-format gap-5">
		<v-textarea
			v-model="element.question"
			rows="3"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Question"
			@input="updated"
		></v-textarea>
		<v-text-field
			v-model="element.placeholder"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Placeholder"
			@input="updated"
		></v-text-field>
		<v-switch v-model="element.required" hide-details dense label="Required" class="mt-n2" @change="updated"></v-switch>
		<v-text-field
			v-model="element.fieldName"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Mapping key"
			@input="updated"
		></v-text-field>
		<v-select
			v-model="element.schemaMapping"
			:items="mappings"
			item-text="label"
			item-value="value"
			hide-details
			dense
			outlined
			label="Mapping"
			@change="updated"
		></v-select>
		<v-select
			v-if="showValidateFor"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Validate as"
			clearable
			v-model="element.validateFor"
			:items="validateOptions"
			item-value="value"
			item-text="label"
			@change="updated"
		>
		</v-select>
	</div>
</template>

<script>
	import ConfigMixin from '@/modules/forms/config/ConfigMixin';

	export default {
		name: 'TextInputConfig',

		props: ['item'],

		mixins: [ConfigMixin],

		components: {},

		data: function() {
			return {
				element: { ...this.item },
				validateOptions: [
					{ label: 'N/A', value: null },
					{ label: 'Email', value: 'Email' },
					{ label: 'Phone', value: 'Phone' },
				],
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {
			showValidateFor: function() {
				if (this.element.schemaMapping === 'phone' || this.element.schemaMapping === 'email') {
					return false;
				} else {
					return true;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
