<template>
	<div class="pb-8">
		<page-header
			class="pb-6"
			:bread-crumbs="[
				{ to: '/home', label: 'Home' },
				{ to: '/communicator/inbox', label: 'Communicator' },
			]"
		>
			Email templates
		</page-header>

		<div class="row-format align-center mb-4">
			<v-text-field
				outlined
				hide-details
				dense
				v-model="search"
				placeholder="Search..."
				style="max-width: 200px; background-color: var(--v-white-base)"
				color="gray_30"
			>
				<template v-slot:prepend-inner><span class="material-symbols-rounded font-gray_50">search</span></template>
			</v-text-field>
			<v-menu
				:nudge-bottom="0"
				:elevation="0"
				content-class="add-new-menu"
				bottom
				left
				rounded
				offset-overflow
				offset-y
				transition="slide-y-transition"
			>
				<template v-slot:activator="{ on }">
					<v-btn class="ml-auto super-action" v-on="on"><v-icon size="20">add</v-icon> Add template</v-btn>
				</template>
				<div class="add-new-dropdown">
					<div class="add-new-item" @click="addNew()">New template</div>
					<div class="add-new-item" @click="templateLibrary()">Search template library</div>
				</div>
			</v-menu>
		</div>

		<v-data-table
			:items="filteredEmailTemplates"
			:fixed-header="true"
			:headers="headers"
			@click:row="editTemplate($event)"
			style="cursor: pointer"
			:hide-default-footer="true"
			:items-per-page="-1"
			group-by="folder"
			sort-by="name"
		>
			<template v-slot:group.header="{ group, headers, toggle }">
				<td colspan="4" class="text-left" @click="toggle">
					<div class="row-format align-center gap-2">
						<v-icon class="" size="20" color="secondary">folder</v-icon>{{ group ? group : ' -- ' }}
					</div>
				</td>
			</template>

			<template v-slot:item.attachments="{ item }">
				{{ item.attachments.length }}
			</template>
			<template v-slot:item.created="{ item }">
				{{ DateTime.fromISO(item.created).toLocaleString(DateTime.DATE_MED) }}
			</template>
			<template v-slot:item.action="{ item }">
				<v-menu close-on-content-click close-on-click :nudge-bottom="30">
					<template v-slot:activator="scope">
						<div class="ml-1">
							<v-btn icon class="menu-activator" v-on="scope.on">
								<v-icon :size="20">{{ scope.value ? '$arrowUp' : '$moreHorizontal' }}</v-icon>
							</v-btn>
						</div>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click="editTemplate(item)">Edit</div>
						<div class="more-menu-item" @click="duplicateTemplate(item)">Duplicate</div>
						<div class="more-menu-item" @click="deleteTemplate(item)">Delete</div>
					</div>
				</v-menu>
			</template>
			<template v-slot:no-data>
				<search-button @click="templateLibrary"></search-button>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import EmailTemplateService from '@/modules/templates/EmailTemplateService';
	import DateTime from '@/modules/utils/HDateTime';
	import EmailTemplateEditor from '@/modules/templates/emails/EmailTemplateEditor';
	import Template from '@/modules/templates/Template';
	import ConfirmModal from '@/components/ConfirmModal';
	import SearchButton from '@/modules/templates/library/SearchButton';
	import TemplateLibrary from '@/modules/templates/library/TemplateLibrary';
	import PageHeader from '@/components/PageHeader';

	export default {
		name: 'EmailTemplateList',

		props: [],

		components: { SearchButton, PageHeader },

		data: function() {
			return {
				emailTemplateService: new EmailTemplateService(),
				emailTemplates: [],
				DateTime: DateTime,
				search: null,
				showMenu: false,
			};
		},

		mounted() {
			Template.setLastViewed('emails');
			this.getEmailTemplates();
		},

		beforeDestroy() {},

		methods: {
			getEmailTemplates: function() {
				this.emailTemplateService.getEmailTemplates().then((res) => {
					this.emailTemplates.splice(0, this.emailTemplates.length);
					this.emailTemplates.push(...res.data);
				});
			},

			openEmailTemplate: function(id) {
				this.$store.state.globalModalController
					.openModal(EmailTemplateEditor, { id: id, folders: this.folders })
					.then((res) => {
						if (res) {
							let ix = this.emailTemplates.findIndex((e) => e.id === res.id);
							if (res.deleted) {
								this.emailTemplates.splice(ix, 1);
							} else if (ix > -1) {
								this.emailTemplates.splice(ix, 1, res);
							} else {
								this.emailTemplates.push(res);
							}
						}
					});
			},

			editTemplate: function(template) {
				this.openEmailTemplate(template.id);
			},

			deleteTemplate: function(template) {
				let binding = {
					headingText: 'Confirm',
					bodyText: `Are you sure you want to delete ${template.name}?`,
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.emailTemplateService.deleteEmailTemplate(template.id);
						let ix = this.emailTemplates.findIndex((e) => e.id === template.id);
						this.emailTemplates.splice(ix, 1);
					}
				});
			},

			duplicateTemplate: function(template) {
				this.emailTemplateService.duplicateEmailTemplate(template.id).then((res) => {
					this.emailTemplates.push(res.data);
					this.openEmailTemplate(res.data.id);
				});
			},

			addNew: function() {
				this.openEmailTemplate(null);
			},

			templateLibrary: function() {
				let binding = {
					type: 'Email',
				};
				this.$store.state.globalModalController.openModal(TemplateLibrary, binding).then((template) => {
					if (template) {
						this.emailTemplates.push(template);
						this.openEmailTemplate(template.id);
					}
				});
			},
		},

		computed: {
			folders: function() {
				return this.emailTemplates
					.filter((e) => e.folder)
					.map((e) => e.folder)
					.filter((value, index, self) => self.indexOf(value) === index);
			},

			headers: function() {
				return [
					{ text: this.$t('templates.emails.name'), value: 'name' },
					{ text: this.$t('templates.emails.attachments'), value: 'attachments' },
					{ text: this.$t('templates.emails.created'), value: 'created' },
					{ text: '', value: 'action', align: 'right' },
				];
			},

			filteredEmailTemplates: function() {
				if (this.search) {
					return this.emailTemplates.filter((t) => {
						let search = this.search.toLowerCase();

						return t.name.toLowerCase().includes(search);
					});
				} else {
					return this.emailTemplates;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
