var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-8" },
    [
      _c(
        "page-header",
        {
          staticClass: "pb-6",
          attrs: {
            "bread-crumbs": [
              { to: "/home", label: "Home" },
              { to: "/pipeline/opportunities", label: "Sales pipeline" }
            ]
          }
        },
        [_vm._v(" Settings ")]
      ),
      _c(
        "div",
        { staticClass: "row-format mb-4 gap-3 flex-wrap" },
        [
          _vm._l(_vm.pages, function(page) {
            return _c(
              "div",
              {
                key: page.value,
                class:
                  "page-link " +
                  (_vm.currentView === page.value ? "page-link-active" : ""),
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.currentView = page.value
                  }
                }
              },
              [_c("div", [_vm._v(_vm._s(page.label))])]
            )
          }),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto super-action",
              on: {
                click: function($event) {
                  return _vm.addNew()
                }
              }
            },
            [
              _c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")]),
              _vm._v(
                " Add " + _vm._s(_vm.currentView === 0 ? "stage" : "to-do")
              )
            ],
            1
          )
        ],
        2
      ),
      _vm.currentView === 0 ? _c("stages", { ref: "Stages" }) : _vm._e(),
      _vm.currentView === 1 ? _c("default-to-dos", { ref: "ToDo" }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }