<template>
	<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			:style="styles"
			@mouseover="internalHover = true"
			@mouseleave="internalHover = false"
			@click="$emit('click')">
		<use :id="id" :xlink:href="href"></use>
	</svg>
</template>

<script>
export default {
	name: 'HIcon3',

	props: {
		size: String, // In pixels
		width: String, // In pixels
		height: String,
		name: { type: String, required: true },
		id: String, // id of "use" block for direct styling
		active: {type: Boolean, required: false, default: false},
		disableHover: {type: Boolean, required: false, default: false},
		hovering: {type: Boolean, required: false, default: false},
		color:{ type: String, required: false, default: "var(--v-secondary-base)" },
		hoverColor:{ type: String, required: false, default: "var(--v-primary-base)" },
	},

	data() {
		return {
			internalHover: false,
		};
	},

	computed: {

		href() {
			// return '/icons/' + this.name + '.svg';
			return '/icons3.svg#' + this.name;

		},

		styles() {
			let str = 'outline: none !important; ';

			if (this.size) {
				str += 'height:' + this.size + 'px;width:' + this.size + 'px;';
				str += 'min-height:' + this.size + 'px;min-width:' + this.size + 'px;';
			}else if(this.width && this.height){
				str += 'height:' + this.height + 'px;width:' + this.width + 'px;';
				str += 'min-height:' + this.height + 'px;min-width:' + this.width + 'px;';
			}

			if (this.active) {
				str += '--icon-color: var(--v-primary-base);'
			} else if (!this.disableHover && (this.hovering || this.internalHover)){
				str += `--icon-color: ${this.hoverColor};`;
			} else {
				str += `--icon-color: ${this.color};`;
			}

			if(this.active) {
				str += '; --icon-fill-color: var(--v-primary-base);';
			}else{
				str += '; --icon-fill-color: none; ';
			}

			return str;
		},
	},

	mounted() {},
};
</script>

<style lang="scss">

</style>
