<template>
	<v-container class="questions">
    <v-row>
      <v-col cols="12" style="border-bottom: 1px solid var(--v-gray_30-base)" class="column-format gap-3 mb-2 pb-2">
        <v-autocomplete
            persistent-placeholder
            hide-details
						dense
						outlined
            label="Additional form questions"
            :items="templateList"
            v-model="meeting.attachedFormId"
        ></v-autocomplete>

				<div class="row-format gap-2 align-center pb-2" v-if="meeting.attachedFormId">
					<v-switch hide-details dense label="Save as form submission" class="mt-0" v-model="meeting.saveStandaloneForm"></v-switch>
					<v-menu close-on-content-click close-on-click>
						<template v-slot:activator="{on}">
							<v-icon size="20" v-on="on" class="pointer" color="gray_50">info</v-icon>
						</template>
						<div class="booking-box pa-4 text-left" style="max-width: 300px; background-color: var(--v-white-base)">
							When this feature is enabled, the submitted form data will be saved with the scheduled event as well as creating a record in your Form submission results.
						</div>
					</v-menu>
				</div>
      </v-col>
      <v-col cols="12" v-if="!hideDefaultNameEmail">
        <div class="row-format">
          <v-text-field
              hide-details
              dense
              outlined
              placeholder="First name"
              disabled
              class="mr-1"
          ></v-text-field>
          <v-text-field
              hide-details
              dense
              outlined
              placeholder="Last name"
              disabled
              class="ml-1"
          ></v-text-field>
        </div>
        <v-text-field hide-details dense outlined placeholder="Email" disabled class="mt-2"></v-text-field>
        <v-text-field hide-details dense outlined placeholder="Phone" disabled class="mt-2"></v-text-field>
      </v-col>
      <v-col cols="12" v-if="selectedForm" class="mt-2">
        <form-renderer-v1 v-if="selectedForm.schemaVersion === 1" class="renderer" :schema="selectedForm.schema"></form-renderer-v1>
        <form-renderer-v2 v-if="selectedForm.schemaVersion === 2" class="renderer" :template="selectedForm"></form-renderer-v2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DiscoveryTemplateService from '@/modules/discovery/DiscoveryTemplateService';
import FormRendererV1 from "@/modules/meetings/detail/FormRendererV1";
import FormRendererV2 from "@/modules/meetings/detail/FormRendererV2";

export default {
  name: 'Questions',

  props: ['meeting'],

  components: { FormRendererV1, FormRendererV2 },

  data: function () {
    return {
      templates: [],
      templateService: new DiscoveryTemplateService(),
    };
  },

  mounted() {
    this.getTemplateList();
  },

  beforeDestroy() {},

  methods: {
    getTemplateList: function () {
      this.templateService.getDiscoveryTemplateList().then((res) => {
        this.templates.splice(0, this.templates.length);
        this.templates.push(...res.data);
      });
    },

    hasFieldWithMapping: function (schema, schemaMapping) {
      for (let i = 0; i < schema.length; i++) {
        if (schema[i].schemaMapping === schemaMapping) {
          return true;
        } else if (schema[i].type === 'Container') {
          for (let j = 0; j < schema[i].columns.length; j++) {
            let column = schema[i].columns[j];
            for (let k = 0; k < column.items.length; k++) {
              if (column.items[k].schemaMapping === schemaMapping) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
  },

  computed: {
    hideDefaultNameEmail: function () {
      if (this.selectedForm && this.selectedForm.schemaVersion === 2) {
        let foundFirst = this.hasFieldWithMapping(this.selectedForm.schemaV2, 'firstName');
        let foundEmail = this.hasFieldWithMapping(this.selectedForm.schemaV2, 'email');
        return foundFirst && foundEmail;
      } else {
        return false;
      }
    },

    selectedForm: function () {
      if (this.meeting.attachedFormId) {
        return this.templates.find((t) => t.id === this.meeting.attachedFormId);
      } else {
        return null;
      }
    },

    templateList: function () {
      let results = [];

      results.push({
        text: '-- None -- ',
        value: null,
      });

      this.templates.forEach((t) => {
        results.push({ text: t.name, value: t.id });
      });

      results.sort((a,b) => a.text.localeCompare(b.text));

      return results;
    },
  },
};
</script>

<style scoped lang="scss">
.renderer ::v-deep {
  .question {
    font-size: 14px !important;
    font-weight: 500;
  }
  .words {
    font-size: 14px !important;
  }
}
.questions {
  padding: 50px;
}
</style>