<template>
	<div id="new-proposal" style="min-height: 525px;">
		<v-btn icon class="close-dialog" @click="$emit('result')">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<div class="cloneList">
				<div class="modal-title">
					{{ $t('proposal.new.heading') }}
				</div>

				<v-tabs v-model="tab" v-if="showV1">
					<v-tab>Agreements [v2]</v-tab>
					<v-tab>Agreements [v1 - classic]</v-tab>
				</v-tabs>

				<div v-if="tab === 1">
					<div class="h-outline d-flex align-start justify-start py-3 px-2 mt-4" @click="freshStart('Proposal')">
						<div class="pr-3 pl-1">
							<v-icon>$proposals</v-icon>
						</div>
						<div class="text-left">
							<div class="brand-medium gray_90--text">{{ $t('proposal.new.proposal') }}</div>
							<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.proposal-caption') }}</div>
						</div>
					</div>
					<div class="h-outline d-flex align-start justify-start py-3 px-2 mt-4" @click="freshStart('Contract')">
						<div class="pr-3 pl-1">
							<v-icon>$signature</v-icon>
						</div>
						<div class="text-left">
							<div class="brand-medium gray_90--text">{{ $t('proposal.new.contract') }}</div>
							<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.contract-caption') }}</div>
						</div>
					</div>
					<div class="h-outline d-flex align-start justify-start py-3 px-2 mt-4" @click="freshStart('Quote')">
						<div class="pr-3 pl-1">
							<v-icon>$invoices</v-icon>
						</div>
						<div class="text-left">
							<div class="brand-medium gray_90--text">{{ $t('proposal.new.quote') }}</div>
							<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.quote-caption') }}</div>
						</div>
					</div>
				</div>
				<div v-else class="column-format gap-3 mt-4">
					<div class="h-outline d-flex align-start justify-start py-3 px-2"  @click="freshStart('Agreement')">
						<div class="pr-3 pl-1">
							<v-icon color="primary">handshake</v-icon>
						</div>
						<div class="text-left">
							<div class="brand-medium gray_90--text">Blank agreement</div>
							<div class="font-14 brand-semilight mt-1">Try the fully refreshed agreement module with a ton of great features and improved customization options.</div>
						</div>
					</div>
					<div class="h-outline d-flex align-start justify-start py-3 px-2" @click="freshStart('Agreement-Proposal')">
						<div class="pr-3 pl-1">
							<v-icon>$proposals</v-icon>
						</div>
						<div class="text-left">
							<div class="brand-medium gray_90--text">{{ $t('proposal.new.proposal') }}</div>
							<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.proposal-caption') }}</div>
						</div>
					</div>
					<div class="h-outline d-flex align-start justify-start py-3 px-2" @click="freshStart('Agreement-Contract')">
						<div class="pr-3 pl-1">
							<v-icon>$signature</v-icon>
						</div>
						<div class="text-left">
							<div class="brand-medium gray_90--text">{{ $t('proposal.new.contract') }}</div>
							<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.contract-caption') }}</div>
						</div>
					</div>
					<div class="h-outline d-flex align-start justify-start py-3 px-2" @click="freshStart('Agreement-Quote')">
						<div class="pr-3 pl-1">
							<v-icon>$invoices</v-icon>
						</div>
						<div class="text-left">
							<div class="brand-medium gray_90--text">{{ $t('proposal.new.quote') }}</div>
							<div class="font-14 brand-semilight mt-1">{{ $t('proposal.new.quote-caption') }}</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'NewProposalTemplate',
		components: {  },

		props: [],

		data: function() {
			return {
				tab: 0,
			};
		},

		mounted() {
			let savedTabValue = localStorage.getItem('AGREEMENT_VERSION_PREF');
			if(savedTabValue){
				this.tab = !this.showV1 ? 0 : Number(savedTabValue);
			}
		},

		methods: {
			freshStart: function(type = 'Proposal') {
				this.$emit('result',type);
			},
		},


		computed: {
			isPaid: function() {
				return this.$store.getters.isPaidAccount;
			},

			showV1: function(){
				return this.$store.state.podAccount.accountId < 32437;
			}
		},

		watch: {
			tab: function(newVal){
				localStorage.setItem('AGREEMENT_VERSION_PREF',newVal);
			}
		},
	};
</script>

<style scoped lang="scss">
	#new-proposal {
		width: 420px;
		//min-width: 320px;
		background-color: var(--v-white-base);
		.h-outline:not(.select-client) {
			cursor: pointer;
			min-height: 96px;
			&.disabled {
				opacity: 0.5;
				cursor: default;
			}
			&:hover:not(.disabled) {
				background-color: var(--v-gray_10-base);
				border-color: var(--v-gray_20-base);
				svg {
					--icon-color: var(--v-primary-base);
					color: var(--v-primary-base);
				}
			}
		}
		.previous-proposals-table {
			th,
			td {
				padding: 16px 4px !important;
			}
			td {
				cursor: pointer;
			}
		}
	}

	.loadingIcon {
		position: absolute;
		top: 50%;
		right: 50%;
		z-index: 300;
	}
</style>
