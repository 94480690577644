import HttpClient from "@/services/HttpClient";

export default class ExportService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	exportWorkspace(){
		return this.httpClient
			.get(`/export`,{responseType: 'blob'})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

}
