<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" align="left" style="padding-left: 55px;">
				<v-btn icon color="schooner_20" :href="invoicePdf" target="_blank">
					<v-icon>save</v-icon>
				</v-btn>
			</v-col>
			<v-col cols="12"><pdf :src="invoicePdf"></pdf></v-col>
		</v-row>
	</v-container>
</template>

<script>
	import pdf from 'vue-pdf';

	export default {
		name: 'Invoice',
		props: {
			invoiceId: { type: String },
			downloadSignature: { type: String }
		},

		components: { pdf },

		data() {
			return {};
		},

		mounted() {},

		methods: {},

		computed: {
			invoicePdf() {
				return `${this.$store.getters.getSubscriptionBaseUrl()}/subscription/invoices/${this.invoiceId}?signature=${this.downloadSignature}`;
			},
		},
	};
</script>

<style lang="scss" scoped></style>
