<template>
	<div v-if="branding" class="mb-8">
		<page-header class="pb-6" :bread-crumbs="[{ to: '/home', label: 'Home' },{label: 'Client portal' }]">
			Customization
		</page-header>

		<div id="detail-wrapper" class="align-center">
			<div class="row-format mb-4 gap-3 flex-wrap">
				<div
					:class="`page-link ${currentView === page.value ? 'page-link-active' : ''}`"
					v-for="page in pages"
					:key="page.value"
					@click.stop="setView(page.value)"
				>
					<div>{{ page.label }}</div>
				</div>

				<v-btn v-if="currentView !== 'Custom'" class="ml-auto super-action" @click="handleSave()">Save</v-btn>
				<v-menu v-else close-on-content-click close-on-click bottom left nudge-bottom="30" nudge-left="15">
					<template v-slot:activator="{ on }">
						<v-btn class="ml-auto super-action" v-on="on">+ Add page</v-btn>
					</template>
					<div class="more-menu">
						<div class="more-menu-item" @click="$refs.Custom.addCustomPage('Internal')">Custom page</div>
						<div class="more-menu-item" @click="$refs.Custom.addCustomPage('External')">External link</div>
						<div class="more-menu-item" @click="$refs.Custom.addCustomPage('iFrame')">iFrame</div>
					</div>
				</v-menu>
			</div>
			<div class="body-content">
				<brand :branding="branding" v-if="currentView === 'Brand'" @refresh="getCustomBranding()"></brand>
				<login-screen :branding="branding" v-if="currentView === 'Login'" :mce-config="mceConfig"></login-screen>
				<custom-pages ref="Custom" :branding="branding" v-if="currentView === 'Custom'" :mce-config="mceConfig"></custom-pages>
				<custom-code v-if="currentView === 'Code'" ref="Code"></custom-code>
			</div>
		</div>

		<confirm-dialog
			:dialog="confirm"
			v-if="confirm"
			body-text="You have unsaved changes.  Are you sure you want to leave the page?"
			@confirm="confirmLeave"
			@close="cancelLeave"
		></confirm-dialog>
	</div>
</template>

<script>
	import CustomBrandingService from '@/modules/portal/customization/CustomBrandingService';
	import Brand from '@/modules/portal/customization/Brand';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import jsonPatch from 'fast-json-patch';
	import LoginScreen from '@/modules/portal/customization/LoginScreen';
	import CustomPages from '@/modules/portal/customization/CustomPages';
	import CustomCode from '@/modules/portal/customization/CustomCode';
	import PageHeader from "@/components/PageHeader";

	export default {
		name: 'Customization',

		props: [],

		components: { CustomPages, LoginScreen, ConfirmDialog, Brand, CustomCode, PageHeader },

		data: function() {
			return {
				confirm: false,
				currentView: 'Brand',
				origBranding: null,
				branding: null,
				brandingService: new CustomBrandingService(),
				pages: [
					{ label: 'Brand', value: 'Brand' },
					{ label: 'Login screen', value: 'Login' },
					{ label: 'Custom pages', value: 'Custom' },
					{ label: 'Custom code', value: 'Code' },
				],
				next: null,
				mceConfig: {
					menubar: false,
					inline: false,
					browser_spellcheck: true,
					paste_as_text: false,
					paste_data_images: true,
					table_style_by_css: true,
					statusbar: false,
					extended_valid_elements:
						'iframe[src,id,style], script[language|type|src], style[media|type], link[rel,type,href], a[href,data-height,data-width,class]',
					protect: [/<script[\s\S]*?<\/script>/g],
					forced_root_block: 'div',
					plugins: ['paste', 'lists', 'link', 'table', 'autoresize', 'media', 'code'],
					contextmenu: 'paste | link image | table',
					indentation: '12pt',
					skin: this.$vuetify.theme.dark ? 'oxide-dark' : '',
					content_css: this.$vuetify.theme.dark ? 'dark' : '',
					toolbar: [
						'fontsizeselect styleselect forecolor | bold italic underline |  bullist numlist',
						'blockquote alignleft aligncenter alignright alignjustify indent outdent | table link unlink media code',
					],
					style_formats: [
						{ title: 'Paragraph', format: 'p' },
						{ title: 'Title', format: 'h1' },
						{ title: 'Heading', format: 'h2' },
						{ title: 'Subheading', format: 'h3' },
						{ title: 'Code', format: 'code' },
					],
					table_toolbar:
						'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
				},
			};
		},

		mounted() {
			this.getCustomBranding();
		},

		beforeDestroy() {},

		methods: {
			beforeRouteLeave(to, from, next) {
				this.next = next;
				this.confirm = true;

				let patch = jsonPatch.compare(this.branding, this.origBranding);

				if (patch.length) {
					this.confirm = true;
				} else {
					next();
				}
			},

			confirmLeave: function() {
				this.confirm = false;
				this.next();
			},

			cancelLeave: function() {
				this.next = null;
				this.confirm = false;
			},

			getCustomBranding: function() {
				this.brandingService.getCustomBranding().then((res) => {
					this.setObjects(res.data);
				});
			},

			handleSave: function() {
				if (this.currentView === 'Code') {
					this.$refs.Code.save();
				} else {
					this.saveCustomBranding();
				}
			},

			saveCustomBranding: function() {
				this.$store.commit('startLoading');
				this.brandingService
					.updateCustomBranding(this.branding)
					.then((res) => {
						this.setObjects(res.data);
					})
					.finally(() => this.$store.commit('stopLoading'));
			},

			setObjects(result) {
				this.branding = JSON.parse(JSON.stringify(result));
				this.origBranding = JSON.parse(JSON.stringify(result));
			},

			setView: function(view) {
				this.currentView = view;
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
</style>
