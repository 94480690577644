var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "600px" } }, [
    _c(
      "div",
      { staticClass: "row-format align-center pt-4 px-4" },
      [
        _c("div", { staticClass: "brand-medium font-16" }, [
          _vm._v("Shared ICS Calendar")
        ]),
        _c(
          "v-btn",
          {
            staticClass: "ml-auto",
            attrs: { icon: "" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("result")
              }
            }
          },
          [_c("v-icon", [_vm._v("$close")])],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "px-4 pb-6 text-left column-format gap-4" },
      [
        _c("div", { staticClass: "font-14" }, [
          _vm._v(
            " Select which categories you would like visible in your shared calendar. "
          )
        ]),
        _c(
          "div",
          { staticClass: "column-format" },
          _vm._l(_vm.filteredCategories, function(category) {
            return _c("v-checkbox", {
              key: category.value,
              staticClass: "mx-0 my-2 pa-0",
              attrs: { value: category.value, "hide-details": "", dense: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "client-label row-format pointer align-center"
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { size: "20", color: "gray_80" } },
                              [_vm._v(_vm._s(category.icon))]
                            ),
                            _c("div", { staticClass: "tree-label ml-2" }, [
                              _vm._v(_vm._s(category.label))
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.selectedCategories,
                callback: function($$v) {
                  _vm.selectedCategories = $$v
                },
                expression: "selectedCategories"
              }
            })
          }),
          1
        ),
        _vm.shareableLink
          ? _c(
              "div",
              { staticClass: "d-flex align-center justify-space-between" },
              [
                _c(
                  "div",
                  { staticClass: "mr-1", staticStyle: { flex: "1 1 auto" } },
                  [
                    _c("input", {
                      staticClass: "link-box",
                      attrs: { type: "hidden", id: "linkBox", readonly: "" },
                      domProps: { value: _vm.shareableLink }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "font-14 brand-medium",
                        staticStyle: { "overflow-wrap": "anywhere" }
                      },
                      [_vm._v(_vm._s(_vm.shareableLink))]
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "ml-1", staticStyle: { flex: "0 1 auto" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "super-action",
                        on: {
                          click: function($event) {
                            return _vm.copyLink()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("proposal.share.copy-link")))]
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _c(
          "v-btn",
          {
            staticClass: "super-action",
            attrs: { disabled: _vm.selectedCategories.length === 0 },
            on: {
              click: function($event) {
                return _vm.getLink()
              }
            }
          },
          [_vm._v("Generate Share Link")]
        ),
        _vm._m(0)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "font-12 font-italic" }, [
      _vm._v(
        "The generated link can be used to subscribe to your Moxie calendar from your calendar app of choice. If you aren't familiar with how ICS calendar subscriptions work, check out our "
      ),
      _c(
        "a",
        {
          attrs: {
            href:
              "http://help.withmoxie.com/en/articles/7158813-sharing-your-hectic-calendar",
            target: "_blank"
          }
        },
        [_vm._v(" help article")]
      ),
      _vm._v(" for more information.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }