var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format text-left font-14 font-gray_70 py-3 gap-2" },
    [
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding top"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pt,
              callback: function($$v) {
                _vm.$set(_vm.element, "pt", $$v)
              },
              expression: "element.pt"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding bottom"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pb,
              callback: function($$v) {
                _vm.$set(_vm.element, "pb", $$v)
              },
              expression: "element.pb"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row-format gap-1" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding left"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pl,
              callback: function($$v) {
                _vm.$set(_vm.element, "pl", $$v)
              },
              expression: "element.pl"
            }
          }),
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              outlined: "",
              type: "number",
              min: "0",
              max: "20",
              label: "Padding right"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.pr,
              callback: function($$v) {
                _vm.$set(_vm.element, "pr", $$v)
              },
              expression: "element.pr"
            }
          })
        ],
        1
      ),
      _c("v-select", {
        attrs: {
          dense: "",
          outlined: "",
          "hide-details": "",
          "persistent-placeholder": "",
          label: _vm.$t("proposal.terms.payment-terms"),
          items: _vm.dayOptions
        },
        on: { change: _vm.renderTerms },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                item === 0
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.$t("proposal.terms.on-receipt")))
                    ])
                  : _vm._e(),
                item !== 0
                  ? _c("span", [
                      _vm._v(
                        _vm._s(item) +
                          " " +
                          _vm._s(_vm.$t("proposal.terms.days"))
                      )
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                item === 0
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.$t("proposal.terms.on-receipt")))
                    ])
                  : _vm._e(),
                item !== 0
                  ? _c("span", [
                      _vm._v(
                        _vm._s(item) +
                          " " +
                          _vm._s(_vm.$t("proposal.terms.days"))
                      )
                    ])
                  : _vm._e()
              ]
            }
          }
        ]),
        model: {
          value: _vm.element.paymentDays,
          callback: function($$v) {
            _vm.$set(_vm.element, "paymentDays", $$v)
          },
          expression: "element.paymentDays"
        }
      }),
      _c("v-text-field", {
        attrs: {
          dense: "",
          outlined: "",
          "hide-details": "",
          "persistent-placeholder": "",
          label: _vm.$t("proposal.terms.late-payment"),
          placeholder: "No fee",
          suffix: "% mo.",
          type: "number"
        },
        on: { change: _vm.renderTerms },
        model: {
          value: _vm.element.latePaymentFee,
          callback: function($$v) {
            _vm.$set(_vm.element, "latePaymentFee", $$v)
          },
          expression: "element.latePaymentFee"
        }
      }),
      _c("v-switch", {
        staticClass: "ml-1",
        attrs: {
          inset: "",
          "hide-details": "",
          dense: "",
          label: _vm.$t("proposal.terms.include-terms")
        },
        on: { change: _vm.renderTerms },
        model: {
          value: _vm.element.fullLegalTerms,
          callback: function($$v) {
            _vm.$set(_vm.element, "fullLegalTerms", $$v)
          },
          expression: "element.fullLegalTerms"
        }
      }),
      _vm.element.fullLegalTerms
        ? _c(
            "div",
            { staticClass: "column-format gap-4" },
            [
              _c("div", { staticClass: "modal-title mb-n2" }, [
                _vm._v(_vm._s(_vm.$t("proposal.terms.legal-terms")))
              ]),
              _c("div", { staticClass: "disclaimer" }, [
                _c(
                  "div",
                  [
                    _c("v-icon", { attrs: { size: "30", color: "gray_90" } }, [
                      _vm._v("$info")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "12px", "font-style": "italic" }
                  },
                  [_vm._v(_vm._s(_vm.$t("proposal.terms.disclaimer")))]
                )
              ]),
              _c("date-selector", {
                attrs: {
                  standard: true,
                  date: _vm.effectiveDate,
                  label: _vm.$t("proposal.terms.effective-date")
                },
                on: {
                  change: function($event) {
                    return _vm.effectiveDateUpdated($event)
                  }
                }
              }),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: _vm.$t("proposal.terms.governing-law")
                },
                on: { change: _vm.renderTerms },
                model: {
                  value: _vm.element.governingLaw,
                  callback: function($$v) {
                    _vm.$set(_vm.element, "governingLaw", $$v)
                  },
                  expression: "element.governingLaw"
                }
              }),
              _c("v-select", {
                attrs: {
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: _vm.$t("proposal.terms.cancellation"),
                  items: _vm.dayOptions
                },
                on: { change: _vm.renderTerms },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item === 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("proposal.terms.no-notice"))
                                )
                              ])
                            : _vm._e(),
                          item !== 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(item) +
                                    " " +
                                    _vm._s(_vm.$t("proposal.terms.days"))
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item === 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("proposal.terms.no-notice"))
                                )
                              ])
                            : _vm._e(),
                          item !== 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(item) +
                                    " " +
                                    _vm._s(_vm.$t("proposal.terms.days"))
                                )
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4019304860
                ),
                model: {
                  value: _vm.element.cancellationNoticeDays,
                  callback: function($$v) {
                    _vm.$set(_vm.element, "cancellationNoticeDays", $$v)
                  },
                  expression: "element.cancellationNoticeDays"
                }
              }),
              _c("div", { staticClass: "checkbox-flex" }, [
                _c(
                  "div",
                  [
                    _c("v-simple-checkbox", {
                      attrs: {
                        color: _vm.element.displayInPortfolio
                          ? "var(--v-blue_50-base)"
                          : "var(--v-gray_70-base)"
                      },
                      on: { input: _vm.renderTerms },
                      model: {
                        value: _vm.element.displayInPortfolio,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "displayInPortfolio", $$v)
                        },
                        expression: "element.displayInPortfolio"
                      }
                    })
                  ],
                  1
                ),
                _c("div", [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("proposal.terms.portfolio-label")))
                  ]),
                  _c("div", { staticClass: "details" }, [
                    _vm._v(_vm._s(_vm.$t("proposal.terms.portfolio-details")))
                  ])
                ])
              ]),
              _c("div", { staticClass: "checkbox-flex" }, [
                _c(
                  "div",
                  [
                    _c("v-simple-checkbox", {
                      attrs: {
                        color: _vm.element.grantLicenseForFramework
                          ? "var(--v-blue_50-base)"
                          : "var(--v-gray_70-base)"
                      },
                      on: { input: _vm.renderTerms },
                      model: {
                        value: _vm.element.grantLicenseForFramework,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "grantLicenseForFramework", $$v)
                        },
                        expression: "element.grantLicenseForFramework"
                      }
                    })
                  ],
                  1
                ),
                _c("div", [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("proposal.terms.frameworks-label")))
                  ])
                ])
              ]),
              _c("div", { staticClass: "checkbox-flex" }, [
                _c(
                  "div",
                  [
                    _c("v-simple-checkbox", {
                      attrs: {
                        color: _vm.element.processingFeePassThrough
                          ? "var(--v-blue_50-base)"
                          : "var(--v-gray_70-base)"
                      },
                      on: { input: _vm.renderTerms },
                      model: {
                        value: _vm.element.processingFeePassThrough,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "processingFeePassThrough", $$v)
                        },
                        expression: "element.processingFeePassThrough"
                      }
                    })
                  ],
                  1
                ),
                _c("div", [
                  _c("div", { staticClass: "label" }, [
                    _vm._v(
                      _vm._s(_vm.$t("proposal.terms.fee-pass-through-label"))
                    )
                  ]),
                  _c("div", { staticClass: "details" }, [
                    _vm._v(
                      _vm._s(_vm.$t("proposal.terms.fee-pass-through-detail"))
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "modal-title mb-n2" }, [
                _vm._v(_vm._s(_vm.$t("proposal.terms.the-contractor")))
              ]),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: _vm.$t("proposal.terms.legal-name")
                },
                on: { change: _vm.renderTerms },
                model: {
                  value: _vm.account.accountName,
                  callback: function($$v) {
                    _vm.$set(_vm.account, "accountName", $$v)
                  },
                  expression: "account.accountName"
                }
              }),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "div",
                  { staticClass: "pr-1", staticStyle: { flex: "1 1 50%" } },
                  [
                    _vm.entityTypes.length
                      ? _c("v-select", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("proposal.terms.entity-type"),
                            items: _vm.entityTypes
                          },
                          on: { change: _vm.renderTerms },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [_c("span", [_vm._v(_vm._s(item))])]
                                }
                              }
                            ],
                            null,
                            false,
                            2957485812
                          ),
                          model: {
                            value: _vm.account.entityType,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "entityType", $$v)
                            },
                            expression: "account.entityType"
                          }
                        })
                      : _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("proposal.terms.entity-type")
                          },
                          on: { change: _vm.renderTerms },
                          model: {
                            value: _vm.account.entityType,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "entityType", $$v)
                            },
                            expression: "account.entityType"
                          }
                        })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pl-1", staticStyle: { flex: "1 1 50%" } },
                  [
                    _vm.states.length
                      ? _c("v-select", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("proposal.terms.entity-state"),
                            "item-value": "name",
                            "item-text": "name",
                            items: _vm.states
                          },
                          on: { change: _vm.renderTerms },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("span", [_vm._v(_vm._s(item.name))])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3625759709
                          ),
                          model: {
                            value: _vm.account.entityState,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "entityState", $$v)
                            },
                            expression: "account.entityState"
                          }
                        })
                      : _c("v-text-field", {
                          attrs: {
                            dense: "",
                            outlined: "",
                            "hide-details": "",
                            "persistent-placeholder": "",
                            label: _vm.$t("proposal.terms.entity-state")
                          },
                          on: { change: _vm.renderTerms },
                          model: {
                            value: _vm.account.entityState,
                            callback: function($$v) {
                              _vm.$set(_vm.account, "entityState", $$v)
                            },
                            expression: "account.entityState"
                          }
                        })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "modal-title mb-n2" }, [
                _vm._v(_vm._s(_vm.$t("proposal.terms.the-client")))
              ]),
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: _vm.$t("proposal.terms.legal-name")
                },
                on: { change: _vm.renderTerms },
                model: {
                  value: _vm.element.clientLegalName,
                  callback: function($$v) {
                    _vm.$set(_vm.element, "clientLegalName", $$v)
                  },
                  expression: "element.clientLegalName"
                }
              }),
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "div",
                  { staticClass: "pr-1", staticStyle: { flex: "1 1 50%" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        outlined: "",
                        "hide-details": "",
                        "persistent-placeholder": "",
                        label: _vm.$t("proposal.terms.entity-type")
                      },
                      on: { change: _vm.renderTerms },
                      model: {
                        value: _vm.element.clientEntityType,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "clientEntityType", $$v)
                        },
                        expression: "element.clientEntityType"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pl-1", staticStyle: { flex: "1 1 50%" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        dense: "",
                        outlined: "",
                        "hide-details": "",
                        "persistent-placeholder": "",
                        label: _vm.$t("proposal.terms.entity-state")
                      },
                      on: { change: _vm.renderTerms },
                      model: {
                        value: _vm.element.clientState,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "clientState", $$v)
                        },
                        expression: "element.clientState"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn",
        { attrs: { elevation: "0" }, on: { click: _vm.confirmConvert } },
        [_vm._v("Convert to editable text")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }