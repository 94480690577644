<template>
	<div v-if="mode === 'standard' || mode === 'dense'" style="width:100%">
		<amount-input
			:prefix="$formatters.currencySymbol()"
			hide-details
			persistent-placeholder
			:dense="mode === 'dense'"
			outlined
			type="number"
			v-model="ourValue"
			:label="field.name"
			@input="$emit('change', ourValue)"
		>
		</amount-input>
	</div>
	<div v-else style="width:100%">
		<currency-input
			v-model="ourValue"
			:options="{ currency: $store.state.defaultCurrency }"
			@change="$emit('change', ourValue)"
		></currency-input>
	</div>
</template>

<script>
	import CurrencyInput from '@/components/CurrencyInput';
	import AmountInput from '@/components/AmountInput';

	export default {
		name: 'CustomNumeric',

		props: ['field', 'value', 'mode'],

		components: { AmountInput, CurrencyInput },

		data: function() {
			return {
				ourValue: this.value,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {},
	};
</script>

<style scoped lang="scss">
	input:focus {
		outline: 1px solid var(--v-gray_30-base);
	}

	input {
		width: 100%;
	}
</style>
