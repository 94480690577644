<template>
	<notification-layout :id="id" @action="tryNavigation()" :sent-at="sentAt">
		<template slot="header">
			<v-icon color="primary" small class="mr-1">$alert</v-icon>
			<div>{{ notificationPayload.heading }}</div>
		</template>

		<template slot="content">
			<div class="font-12">
				<span> {{ notificationPayload.message }} </span>
			</div>
		</template>
	</notification-layout>
</template>

<script>
	import NotificationLayout from "@/modules/productivity/notifications/NotificationLayout";

	export default {
		name: 'GenericNotification',

		props: ['notificationPayload', 'sentAt', 'id'],

		components: { NotificationLayout },

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			tryNavigation: function() {
				if (this.notificationPayload.heading === 'Linked account error') {
					this.$router.push('/accounting/accounts');
				}
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss">
</style>
