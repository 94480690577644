import HttpClient from '@/services/HttpClient';

export default class RecurringEventService {
	httpClient;

	constructor() {
		this.httpClient = new HttpClient();
	}

	getRecurringInvoices(clientId) {
		return this.httpClient
			.get(`/recurring/invoices`, {
				params: {
					clientId: clientId,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getRecurringDeliverables(projectId) {
		return this.httpClient
			.get(`/recurring/deliverables`, {
				params: {
					projectId: projectId,
				},
			})
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getRecurringExpenses() {
		return this.httpClient
			.get(`/recurring/expenses`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getRecurringPayments() {
		return this.httpClient
			.get(`/recurring/income`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	getRecurringEvent(id) {
		return this.httpClient
			.get(`/recurring/event/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	createRecurringEvent(event) {
		return this.httpClient.post(`/recurring/event`, event)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	updateRecurringEvent(id,event) {
		return this.httpClient.put(`/recurring/event/${id}`, event)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}

	deleteRecurringEvent(id) {
		return this.httpClient.delete(`/recurring/event/${id}`)
			.then((res) => Promise.resolve(res))
			.catch((err) => Promise.reject(err));
	}
}
