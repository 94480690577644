import { render, staticRenderFns } from "./CustomCode.vue?vue&type=template&id=506f044c&scoped=true&"
import script from "./CustomCode.vue?vue&type=script&lang=js&"
export * from "./CustomCode.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506f044c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2614485219/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('506f044c')) {
      api.createRecord('506f044c', component.options)
    } else {
      api.reload('506f044c', component.options)
    }
    module.hot.accept("./CustomCode.vue?vue&type=template&id=506f044c&scoped=true&", function () {
      api.rerender('506f044c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/portal/customization/CustomCode.vue"
export default component.exports