var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        {
          key: _vm.refreshKey,
          class: _vm.completed ? "completed-form-embed" : "",
          style: _vm.formStyle,
          attrs: { id: _vm.item.id },
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            }
          }
        },
        [
          _vm.item.discoveryTemplate && _vm.schema.length
            ? _c(
                "div",
                [
                  _vm._l(_vm.pages[_vm.currentPage], function(item, index) {
                    return _c(
                      "div",
                      { key: item.id },
                      [
                        _c("item-renderer", {
                          ref: "item",
                          refInFor: true,
                          attrs: {
                            index: index + (_vm.currentPage + 1) * 1000,
                            item: item,
                            styles: _vm.styles,
                            font: _vm.font,
                            "button-props": _vm.buttonProps,
                            disabled:
                              !_vm.clientMode || _vm.agreement.fullyExecuted,
                            "current-page": _vm.currentPage + 1,
                            "page-count": _vm.pages.length,
                            "account-logo": _vm.accountLogo,
                            active: false,
                            results: _vm.results,
                            "known-data": _vm.knownData
                          },
                          on: {
                            "next-page": function($event) {
                              return _vm.nextPage()
                            },
                            "previous-page": function($event) {
                              return _vm.previousPage()
                            },
                            input: function($event) {
                              return _vm.handleInput($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  _vm.isLastPage
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "row-format gap-2 pt-2 px-2",
                            style:
                              "width: 100%; justify-content: " +
                              _vm.justification
                          },
                          [
                            _vm.pages.length > 1
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "elevation-0",
                                    style:
                                      "font-size: var(--form-font-size) !important; " +
                                      (_vm.buttonProps.block
                                        ? "width: 49%!important;"
                                        : ""),
                                    attrs: {
                                      color: _vm.styles.buttonColor,
                                      "x-small": _vm.buttonProps.xSmall,
                                      small: _vm.buttonProps.small,
                                      large: _vm.buttonProps.large,
                                      "x-large": _vm.buttonProps.xLarge
                                    },
                                    on: { click: _vm.previousPage }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-1",
                                        attrs: {
                                          color: _vm.styles.buttonTextColor
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.styles.prevPageIcon))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        style:
                                          "font-family: " +
                                          _vm.font +
                                          "; ; text-transform: none; color: " +
                                          _vm.styles.buttonTextColor
                                      },
                                      [_vm._v(_vm._s(_vm.styles.prevPageText))]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm.form && _vm.schema.length === 0
            ? _c(
                "div",
                {
                  staticClass: "row-format centered",
                  staticStyle: {
                    height: "100%",
                    border: "1px solid var(--v-gray_20-base)",
                    "min-height": "100px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "font-gray_60 row-format align-center" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-outlined",
                          attrs: { color: "gray_60" }
                        },
                        [_vm._v("warning")]
                      ),
                      _c("div", [_vm._v("Selected form has no content")])
                    ],
                    1
                  )
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "row-format centered",
                  staticStyle: {
                    height: "100%",
                    border: "1px solid var(--v-gray_20-base)",
                    "min-height": "100px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "font-gray_60 row-format align-center" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "material-symbols-outlined",
                          attrs: { color: "gray_60" }
                        },
                        [_vm._v("description")]
                      ),
                      _c("div", [
                        _vm._v("Please select an embedded form template")
                      ])
                    ],
                    1
                  )
                ]
              )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }