var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { attrs: { id: "proposal-deliverable-fees" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-padding" },
            [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$t("proposal.deliverables.project-fees")))
              ]),
              _c("v-select", {
                staticClass: "h-outline",
                attrs: {
                  "hide-details": "",
                  "persistent-placeholder": "",
                  items: _vm.feeTypes,
                  "item-text": "label",
                  "item-value": "value",
                  label: _vm.$t("proposal.deliverables.fee-type")
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v(_vm._s(item.label))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2814369483
                ),
                model: {
                  value: _vm.lFeeSchedule.feeType,
                  callback: function($$v) {
                    _vm.$set(_vm.lFeeSchedule, "feeType", $$v)
                  },
                  expression: "lFeeSchedule.feeType"
                }
              }),
              _vm.lFeeSchedule.feeType === "Per Item"
                ? _c(
                    "div",
                    {
                      staticClass: "row-format text-left font-14 font-gray_80",
                      staticStyle: { "align-items": "flex-end" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "proposal.deliverables.is-null.per-item-text"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.lFeeSchedule.feeType !== "Per Item"
                ? _c(
                    "div",
                    {
                      staticClass: "row-format",
                      staticStyle: { "align-items": "flex-end" }
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          "hide-details": "",
                          "persistent-placeholder": "",
                          prefix: _vm.currencySymbol,
                          type: "number",
                          label: _vm.$t("proposal.deliverables.amount")
                        },
                        model: {
                          value: _vm.lFeeSchedule.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.lFeeSchedule, "amount", $$v)
                          },
                          expression: "lFeeSchedule.amount"
                        }
                      }),
                      _vm.taxEnabled
                        ? _c("v-checkbox", {
                            staticClass: "ml-3 mb-2",
                            attrs: { dense: "", "hide-details": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "font-14 ml-n2" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "proposal.deliverables.taxable"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2763662694
                            ),
                            model: {
                              value: _vm.lFeeSchedule.taxable,
                              callback: function($$v) {
                                _vm.$set(_vm.lFeeSchedule, "taxable", $$v)
                              },
                              expression: "lFeeSchedule.taxable"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.lFeeSchedule.feeType === "Retainer"
                ? _c("v-select", {
                    staticClass: "h-outline",
                    attrs: {
                      "hide-details": "",
                      "persistent-placeholder": "",
                      items: _vm.schedule,
                      label: _vm.$t("proposal.deliverables.frequency")
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [_c("span", [_vm._v(_vm._s(item))])]
                          }
                        }
                      ],
                      null,
                      false,
                      2957485812
                    ),
                    model: {
                      value: _vm.lFeeSchedule.retainerSchedule,
                      callback: function($$v) {
                        _vm.$set(_vm.lFeeSchedule, "retainerSchedule", $$v)
                      },
                      expression: "lFeeSchedule.retainerSchedule"
                    }
                  })
                : _vm._e(),
              _vm.lFeeSchedule.feeType === "Hourly" ||
              _vm.lFeeSchedule.feeType === "Retainer"
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "modal-subtitle mt-3 mb-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("proposal.deliverables.hour-estimate")
                            ) +
                            " "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "ml-1",
                            staticStyle: {
                              "font-style": "italic",
                              "font-weight": "300",
                              "font-size": "12px"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "proposal.deliverables.hour-estimate-optional"
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-space-between" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pr-1",
                              staticStyle: { flex: "0 0 50%" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "h-outline",
                                attrs: {
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  label: _vm.$t(
                                    "proposal.deliverables.hour-estimate-min-label"
                                  ),
                                  type: "number"
                                },
                                model: {
                                  value: _vm.lFeeSchedule.estimateMin,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.lFeeSchedule,
                                      "estimateMin",
                                      $$v
                                    )
                                  },
                                  expression: "lFeeSchedule.estimateMin"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "pl-1",
                              staticStyle: { flex: "0 0 50%" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "h-outline",
                                attrs: {
                                  "hide-details": "",
                                  "persistent-placeholder": "",
                                  label: _vm.$t(
                                    "proposal.deliverables.hour-estimate-max-label"
                                  ),
                                  type: "number"
                                },
                                model: {
                                  value: _vm.lFeeSchedule.estimateMax,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.lFeeSchedule,
                                      "estimateMax",
                                      $$v
                                    )
                                  },
                                  expression: "lFeeSchedule.estimateMax"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.lFeeSchedule.feeType === "Retainer" &&
                      _vm.lFeeSchedule.estimateMax
                        ? _c("v-text-field", {
                            staticClass: "h-outline",
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": "",
                              prefix: _vm.currencySymbol,
                              type: "number",
                              label: _vm.$t(
                                "proposal.deliverables.retainer-overage"
                              )
                            },
                            model: {
                              value: _vm.lFeeSchedule.retainerOverageRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.lFeeSchedule,
                                  "retainerOverageRate",
                                  $$v
                                )
                              },
                              expression: "lFeeSchedule.retainerOverageRate"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "column-format gap-3 mt-2",
                  staticStyle: {
                    "border-top": "1px solid var(--v-gray_30-base)"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row-format align-center gap-2 mt-3" },
                    [
                      _c("v-switch", {
                        staticClass: "mt-n1",
                        attrs: {
                          inset: "",
                          flat: "",
                          dense: "",
                          "hide-details": "",
                          label: "Enable automations & payment"
                        },
                        model: {
                          value: _vm.lAutomations.autoCreateProject,
                          callback: function($$v) {
                            _vm.$set(_vm.lAutomations, "autoCreateProject", $$v)
                          },
                          expression: "lAutomations.autoCreateProject"
                        }
                      }),
                      _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: {
                                content:
                                  "When this is enabled, Moxie will automatically create a corresponding project and first invoice based on the settings on this package."
                              },
                              expression:
                                "{content: 'When this is enabled, Moxie will automatically create a corresponding project and first invoice based on the settings on this package.'}"
                            }
                          ],
                          staticClass: "ml-auto pointer",
                          attrs: { color: "accent" }
                        },
                        [_vm._v("help")]
                      )
                    ],
                    1
                  ),
                  _vm.lAutomations.autoCreateProject
                    ? _c(
                        "div",
                        { staticClass: "row-format align-center gap-2" },
                        [
                          _vm.lAutomations.autoCreateProject
                            ? _c("v-text-field", {
                                staticClass: "h-outline",
                                staticStyle: { "margin-bottom": "-4px" },
                                attrs: {
                                  "hide-details": "",
                                  "persistent-placeholder": true,
                                  label: "New project name (optional)"
                                },
                                model: {
                                  value: _vm.lAutomations.projectName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.lAutomations,
                                      "projectName",
                                      $$v
                                    )
                                  },
                                  expression: "lAutomations.projectName"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    content:
                                      "This value will be the name for the new project that is created."
                                  },
                                  expression:
                                    "{content:'This value will be the name for the new project that is created.'}"
                                }
                              ],
                              staticClass: "ml-auto pointer",
                              attrs: { color: "accent" }
                            },
                            [_vm._v("help")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.lAutomations.autoCreateProject
                    ? _c(
                        "div",
                        { staticClass: "row-format align-center gap-2" },
                        [
                          _c("v-select", {
                            staticClass: "h-outline",
                            staticStyle: { "margin-top": "0" },
                            attrs: {
                              items: _vm.packageList,
                              "item-text": "name",
                              "item-value": "id",
                              "hide-details": "",
                              "persistent-placeholder": true,
                              clearable: "",
                              label: "Use tasks from project template"
                            },
                            model: {
                              value:
                                _vm.lAutomations.overrideDeliverablePackageId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.lAutomations,
                                  "overrideDeliverablePackageId",
                                  $$v
                                )
                              },
                              expression:
                                "lAutomations.overrideDeliverablePackageId"
                            }
                          }),
                          _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: {
                                    content:
                                      "Selecting this value will replace the deliverables specified in the agreement with tasks from the specified template when the project is automatically created."
                                  },
                                  expression:
                                    "{content:'Selecting this value will replace the deliverables specified in the agreement with tasks from the specified template when the project is automatically created.'}"
                                }
                              ],
                              staticClass: "ml-auto pointer",
                              attrs: { color: "accent" }
                            },
                            [_vm._v("help")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.lAutomations.autoCreateProject
                    ? _c(
                        "div",
                        [
                          _vm.lFeeSchedule.feeType !== "Retainer"
                            ? _c(
                                "div",
                                { staticClass: "row-format gap-2" },
                                [
                                  _c("v-text-field", {
                                    staticClass: "h-outline",
                                    staticStyle: {
                                      "min-width": "200px",
                                      "margin-top": "0px"
                                    },
                                    attrs: {
                                      type: "number",
                                      "hide-details": "",
                                      "persistent-placeholder": true,
                                      label: "Initial invoice amount",
                                      prefix: _vm.lAutomations.invoicePercentage
                                        ? ""
                                        : _vm.currencySymbol,
                                      suffix: _vm.lAutomations.invoicePercentage
                                        ? "%"
                                        : "",
                                      min: _vm.lAutomations.invoicePercentage
                                        ? "0"
                                        : null,
                                      max: _vm.lAutomations.invoicePercentage
                                        ? "100"
                                        : null
                                    },
                                    on: { change: _vm.scrubInput },
                                    model: {
                                      value: _vm.lAutomations.invoiceAmount,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.lAutomations,
                                          "invoiceAmount",
                                          $$v
                                        )
                                      },
                                      expression: "lAutomations.invoiceAmount"
                                    }
                                  }),
                                  _c("v-select", {
                                    staticClass: "h-outline",
                                    staticStyle: { "margin-top": "0px" },
                                    attrs: {
                                      "hide-details": "",
                                      "persistent-placeholder": true,
                                      label: "Type",
                                      "item-value": "value",
                                      "item-text": "label",
                                      items: _vm.amountTypeOptions
                                    },
                                    on: { change: _vm.scrubInput },
                                    model: {
                                      value: _vm.lAutomations.invoicePercentage,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.lAutomations,
                                          "invoicePercentage",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "lAutomations.invoicePercentage"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-switch", {
                            staticStyle: { "margin-top": "0px" },
                            attrs: {
                              inset: "",
                              flat: "",
                              dense: "",
                              "hide-details": "",
                              label:
                                "Show " +
                                _vm.invoiceLabel +
                                " amount on agreement"
                            },
                            model: {
                              value: _vm.lAutomations.paymentRequiredToStart,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.lAutomations,
                                  "paymentRequiredToStart",
                                  $$v
                                )
                              },
                              expression: "lAutomations.paymentRequiredToStart"
                            }
                          }),
                          !_vm.paymentNodeCount
                            ? _c("v-switch", {
                                staticStyle: { "margin-top": "0px" },
                                attrs: {
                                  inset: "",
                                  flat: "",
                                  dense: "",
                                  "hide-details": "",
                                  label:
                                    "Approval required to send " +
                                    _vm.invoiceLabel
                                },
                                model: {
                                  value: _vm.lAutomations.approvalRequired,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.lAutomations,
                                      "approvalRequired",
                                      $$v
                                    )
                                  },
                                  expression: "lAutomations.approvalRequired"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "secondary-action mr-1",
                  attrs: { width: "120" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("global.cancel")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "primary-action ml-1",
                  attrs: { width: "120" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("global.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }