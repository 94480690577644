var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        [
          _c(
            "page-header",
            {
              staticClass: "pb-3",
              attrs: {
                compact: false,
                "bread-crumbs": [
                  { to: "/home", label: "Home" },
                  { label: "Project management" },
                  { to: "/projects/list", label: "Project list" },
                  {
                    to: "/client/" + _vm.project.client.id,
                    label: _vm.project.client.name
                  }
                ]
              }
            },
            [_vm._v(" " + _vm._s(_vm.project.name) + " ")]
          ),
          _c("div", { attrs: { id: "project-detail-view" } }, [
            _c(
              "div",
              { staticClass: "align-center", attrs: { id: "detail-wrapper" } },
              [
                _c("div", { staticClass: "row-format" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row-format gap-3",
                      staticStyle: { "flex-wrap": "wrap" }
                    },
                    _vm._l(_vm.pages, function(page) {
                      return _c(
                        "div",
                        {
                          key: page.value,
                          class:
                            "page-link " +
                            (_vm.currentView === page.value
                              ? "page-link-active"
                              : ""),
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.setView(page.value, $event)
                            }
                          }
                        },
                        [_c("div", [_vm._v(_vm._s(page.label))])]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "ml-auto" }, [
                    _c(
                      "div",
                      { staticClass: "row-format align-center" },
                      [
                        _vm.currentView === "Overview"
                          ? _c(
                              "v-menu",
                              {
                                attrs: {
                                  "nudge-bottom": 0,
                                  elevation: 0,
                                  "content-class": "add-new-menu",
                                  bottom: "",
                                  left: "",
                                  rounded: "",
                                  "offset-overflow": "",
                                  "offset-y": "",
                                  transition: "slide-y-transition"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              { staticClass: "super-action" },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "20" } },
                                                [_vm._v("add")]
                                              ),
                                              _vm._v(" Add")
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  251512182
                                )
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "add-new-dropdown" },
                                  _vm._l(_vm.addNew, function(item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "add-new-item",
                                        on: {
                                          click: function($event) {
                                            return _vm.handleAddNew(item.value)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm.currentView !== "Meetings"
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "super-action",
                                on: { click: _vm.handleAddNewFromTab }
                              },
                              [
                                _c("v-icon", { attrs: { size: "20" } }, [
                                  _vm._v("add")
                                ]),
                                _vm._v(" Add")
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    key: _vm.refreshKey,
                    staticClass: "body-content mt-6 column-format"
                  },
                  [
                    _vm.currentView === "Overview"
                      ? _c("project-overview", {
                          attrs: { project: _vm.project },
                          on: {
                            "edit-project": function($event) {
                              return _vm.editProject()
                            },
                            "payment-clicked": function($event) {
                              return _vm.setView("Invoices")
                            },
                            "time-clicked": function($event) {
                              return _vm.setView("Time")
                            },
                            "view-deliverables": function($event) {
                              return _vm.setView("Tasks")
                            },
                            change: function($event) {
                              return _vm.saveProject()
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.currentView === "Tasks"
                      ? _c("project-management", {
                          ref: "Tasks",
                          attrs: { project: _vm.project }
                        })
                      : _vm._e(),
                    _vm.currentView === "Notes"
                      ? _c("project-notes", {
                          ref: "Notes",
                          attrs: {
                            project: _vm.project,
                            filter: _vm.filters[_vm.currentView]
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "filter",
                                fn: function() {
                                  return [
                                    _c("project-filter", {
                                      key: _vm.refreshKey,
                                      attrs: {
                                        "current-view": _vm.currentView
                                      },
                                      on: {
                                        "clear-filter": function($event) {
                                          return _vm.clearFilter(
                                            _vm.currentView
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.filters[_vm.currentView],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            _vm.currentView,
                                            $$v
                                          )
                                        },
                                        expression: "filters[currentView]"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2331268159
                          )
                        })
                      : _vm._e(),
                    _vm.currentView === "Time"
                      ? _c("timer-event-list", {
                          ref: "Time",
                          attrs: {
                            project: _vm.project,
                            filter: _vm.filters[_vm.currentView]
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "filter",
                                fn: function() {
                                  return [
                                    _c("project-filter", {
                                      key: _vm.refreshKey,
                                      attrs: {
                                        "current-view": _vm.currentView
                                      },
                                      on: {
                                        "clear-filter": function($event) {
                                          return _vm.clearFilter(
                                            _vm.currentView
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.filters[_vm.currentView],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            _vm.currentView,
                                            $$v
                                          )
                                        },
                                        expression: "filters[currentView]"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2331268159
                          )
                        })
                      : _vm._e(),
                    _vm.currentView === "Invoices"
                      ? _c("invoice-list", {
                          ref: "Invoices",
                          attrs: {
                            project: _vm.project,
                            filter: _vm.filters[_vm.currentView]
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "filter",
                                fn: function() {
                                  return [
                                    _c("project-filter", {
                                      key: _vm.refreshKey,
                                      attrs: {
                                        "current-view": _vm.currentView
                                      },
                                      on: {
                                        "clear-filter": function($event) {
                                          return _vm.clearFilter(
                                            _vm.currentView
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.filters[_vm.currentView],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            _vm.currentView,
                                            $$v
                                          )
                                        },
                                        expression: "filters[currentView]"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2331268159
                          )
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }