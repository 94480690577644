import { render, staticRenderFns } from "./Signature.vue?vue&type=template&id=41f0ff02&scoped=true&"
import script from "./Signature.vue?vue&type=script&lang=js&"
export * from "./Signature.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41f0ff02",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2614485219/src/struxture-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41f0ff02')) {
      api.createRecord('41f0ff02', component.options)
    } else {
      api.reload('41f0ff02', component.options)
    }
    module.hot.accept("./Signature.vue?vue&type=template&id=41f0ff02&scoped=true&", function () {
      api.rerender('41f0ff02', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/agreements/schema/Signature.vue"
export default component.exports