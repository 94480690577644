var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.account
    ? _c(
        "div",
        { attrs: { id: "account-info-modal" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-dialog",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("$close")])],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-padding" },
            [
              _c("div", { staticClass: "modal-title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("invoice.details.account-info-modal.heading")
                    ) +
                    " "
                )
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between align-start" },
                [
                  _c(
                    "div",
                    { staticClass: "pt-1", staticStyle: { flex: "0 0 34%" } },
                    [
                      !_vm.account.accountLogo
                        ? _c(
                            "vue-dropzone",
                            {
                              ref: "fileDropzone",
                              attrs: {
                                id: "dropzone",
                                "use-custom-slot": true,
                                options: _vm.dropzoneOptions
                              },
                              on: {
                                "vdropzone-success": _vm.uploadSuccess,
                                "vdropzone-error": _vm.uploadFailure,
                                "vdropzone-sending": _vm.addFileUploadAuthHeader
                              }
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("v-icon", [
                                    _vm._v("$vuetify.icons.upload")
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-1 font-14",
                                      staticStyle: { "line-height": "19px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "invoice.details.account-info-modal.logo-upload"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.account.accountLogo
                        ? _c(
                            "div",
                            { staticClass: "logo-wrapper" },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: _vm.account.accountLogo,
                                  "max-width": "108",
                                  "max-height": "108"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "delete-wrapper" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.deleteAccountLogo()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("$delete")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pl-2", staticStyle: { flex: "0 0 66%" } },
                    [
                      _c("v-text-field", {
                        staticClass: "h-outline",
                        attrs: {
                          "persistent-placeholder": "",
                          "hide-details": "",
                          label: _vm.$t("proposal.terms.legal-name")
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSave.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.account.accountName,
                          callback: function($$v) {
                            _vm.$set(_vm.account, "accountName", $$v)
                          },
                          expression: "account.accountName"
                        }
                      }),
                      _vm.entityTypes.length
                        ? _c("v-select", {
                            staticClass: "h-outline",
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": "",
                              label: _vm.$t("proposal.terms.entity-type"),
                              items: _vm.entityTypes
                            },
                            model: {
                              value: _vm.account.entityType,
                              callback: function($$v) {
                                _vm.$set(_vm.account, "entityType", $$v)
                              },
                              expression: "account.entityType"
                            }
                          })
                        : _c("v-text-field", {
                            staticClass: "h-outline",
                            staticStyle: { "min-width": "300px" },
                            attrs: {
                              "hide-details": "",
                              "persistent-placeholder": "",
                              placeholder: "Corporation, GmbH, etc ",
                              label: _vm.$t("proposal.terms.entity-type")
                            },
                            model: {
                              value: _vm.account.entityType,
                              callback: function($$v) {
                                _vm.$set(_vm.account, "entityType", $$v)
                              },
                              expression: "account.entityType"
                            }
                          })
                    ],
                    1
                  )
                ]
              ),
              _c("v-autocomplete", {
                staticClass: "h-outline",
                attrs: {
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: _vm.$t("proposal.terms.entity-country"),
                  items: _vm.countriesSimple,
                  "item-text": "name",
                  "item-value": "code"
                },
                model: {
                  value: _vm.account.country,
                  callback: function($$v) {
                    _vm.$set(_vm.account, "country", $$v)
                  },
                  expression: "account.country"
                }
              }),
              _c("v-text-field", {
                staticClass: "h-outline",
                attrs: {
                  "hide-details": "",
                  "persistent-placeholder": "",
                  label: _vm.$t("account.tax-id")
                },
                model: {
                  value: _vm.account.taxId,
                  callback: function($$v) {
                    _vm.$set(_vm.account, "taxId", $$v)
                  },
                  expression: "account.taxId"
                }
              }),
              _c(
                "div",
                {
                  staticClass:
                    "brand-medium font-12 gray_90--text mt-6 text-left mt-1"
                },
                [_vm._v("Mailing address")]
              ),
              _c("v-text-field", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  label: _vm.$t("contact.mailing-address")
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSave.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.account.address1,
                  callback: function($$v) {
                    _vm.$set(_vm.account, "address1", $$v)
                  },
                  expression: "account.address1"
                }
              }),
              _c("v-text-field", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  label: _vm.$t("contact.address-contd")
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSave.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.account.address2,
                  callback: function($$v) {
                    _vm.$set(_vm.account, "address2", $$v)
                  },
                  expression: "account.address2"
                }
              }),
              _c("v-text-field", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  label: _vm.$t("contact.city")
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSave.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.account.city,
                  callback: function($$v) {
                    _vm.$set(_vm.account, "city", $$v)
                  },
                  expression: "account.city"
                }
              }),
              _c("div", { staticClass: "d-flex justify-space-between mt-n1" }, [
                _c(
                  "div",
                  { staticClass: "pr-1", staticStyle: { width: "50%" } },
                  [
                    _c("v-text-field", {
                      staticClass: "h-outline",
                      attrs: {
                        "hide-details": "",
                        "persistent-placeholder": "",
                        label: _vm.$t("proposal.terms.entity-state")
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleSave.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.account.locality,
                        callback: function($$v) {
                          _vm.$set(_vm.account, "locality", $$v)
                        },
                        expression: "account.locality"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pl-1", staticStyle: { width: "50%" } },
                  [
                    _c("v-text-field", {
                      staticClass: "h-outline",
                      attrs: {
                        "persistent-placeholder": "",
                        "hide-details": "",
                        label: _vm.$t("contact.postal")
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleSave.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.account.postal,
                        callback: function($$v) {
                          _vm.$set(_vm.account, "postal", $$v)
                        },
                        expression: "account.postal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("v-text-field", {
                staticClass: "h-outline",
                attrs: {
                  "persistent-placeholder": "",
                  "hide-details": "",
                  label: _vm.$t("contact.mobile")
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSave.apply(null, arguments)
                  }
                },
                model: {
                  value: _vm.account.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.account, "phone", $$v)
                  },
                  expression: "account.phone"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-16 primary-action",
                  on: { click: _vm.handleSave }
                },
                [_vm._v(_vm._s(_vm.$t("global.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }