var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-2 pl-2 row-format align-center" },
      [
        _vm._l(_vm.users, function(user, index) {
          return _c(
            "v-menu",
            {
              key: user.userId,
              attrs: {
                "nudge-top": "0",
                "max-width": "200",
                bottom: "",
                right: "",
                rounded: "",
                "offset-overflow": "",
                "offset-y": "",
                "close-on-content-click": false,
                "content-class": "filter-menu-class",
                transition: "slide-y-transition"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c("assigned-user", {
                          staticClass: "ml-n2",
                          style:
                            "border-radius: 100px; border: 2px solid var(--v-white-base)!important; z-index: " +
                            (10 - index),
                          attrs: {
                            large: true,
                            "assigned-to": user.userId,
                            "show-name": false
                          },
                          on: { click: on.click }
                        })
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _c("div", { staticClass: "pa4" }, [
                _c(
                  "div",
                  {
                    staticClass: "more-menu-item",
                    on: {
                      click: function($event) {
                        return _vm.revokeAccess(user)
                      }
                    }
                  },
                  [_vm._v("Revoke access")]
                )
              ])
            ]
          )
        }),
        _vm.isAccountFullUser
          ? _c(
              "div",
              {
                staticClass: "row-format centered contact-avatar-new pointer",
                on: { click: _vm.inviteUser }
              },
              [
                _c("v-icon", { attrs: { color: "gray_50" } }, [_vm._v("$plus")])
              ],
              1
            )
          : _vm._e()
      ],
      2
    ),
    _vm.invitations.length
      ? _c(
          "div",
          {
            staticClass: "font-gray_70 font-12 pointer",
            on: {
              click: function($event) {
                return _vm.$router.push("/team")
              }
            }
          },
          [
            _vm._v(
              "+ " + _vm._s(_vm.invitations.length) + " pending invitation(s)"
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }