var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "row-format align-center ml-1 " +
        (_vm.view === "Two-Week" ? "mt-1" : ""),
      staticStyle: { position: "relative" }
    },
    [
      _vm.client
        ? _c("client-avatar", {
            staticStyle: { transform: "scale(.92)" },
            attrs: { "disable-click": "", "x-small": "", client: _vm.client }
          })
        : _c(
            "v-icon",
            { staticClass: "material-symbols-rounded", attrs: { small: "" } },
            [_vm._v("confirmation_number")]
          ),
      _c("div", { staticClass: "ml-1 font-gray_90" }, [
        _vm._v(_vm._s(_vm.name))
      ]),
      _c(
        "div",
        { staticClass: "status-box row-format centered" },
        [
          _c(
            "v-icon",
            { staticClass: "material-symbols-rounded", attrs: { small: "" } },
            [_vm._v("confirmation_number")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }