var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mode === "standard" || _vm.mode === "dense"
    ? _c(
        "div",
        { staticStyle: { width: "100%" } },
        [
          _c("amount-input", {
            attrs: {
              prefix: _vm.$formatters.currencySymbol(),
              "hide-details": "",
              "persistent-placeholder": "",
              dense: _vm.mode === "dense",
              outlined: "",
              type: "number",
              label: _vm.field.name
            },
            on: {
              input: function($event) {
                return _vm.$emit("change", _vm.ourValue)
              }
            },
            model: {
              value: _vm.ourValue,
              callback: function($$v) {
                _vm.ourValue = $$v
              },
              expression: "ourValue"
            }
          })
        ],
        1
      )
    : _c(
        "div",
        { staticStyle: { width: "100%" } },
        [
          _c("currency-input", {
            attrs: { options: { currency: _vm.$store.state.defaultCurrency } },
            on: {
              change: function($event) {
                return _vm.$emit("change", _vm.ourValue)
              }
            },
            model: {
              value: _vm.ourValue,
              callback: function($$v) {
                _vm.ourValue = $$v
              },
              expression: "ourValue"
            }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }