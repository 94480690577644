<template>
	<div class="columnDragHandle row-format align-center mb-2 mx-2 py-1" @click.stop>
		<div :style="`width: 12px; min-width:12px; height: 12px; border-radius: 2px; background-color: ${column.hexColor}`"></div>
		<div class="font-14 brand-medium ml-3">
			<input
				:disabled="isCollaborator"
				type="text"
				@click.stop
				@keypress.enter="$event.target.blur()"
				class="truncate"
				style="max-width: 180px; outline: none; color: var(--v-black-base)"
				v-model="column.label"
				@blur="updateDeliverableStatusList()"
			/>
		</div>
		<div style="margin-left: auto" class="row-format align-center">
			<v-icon small @click="$emit('add-deliverable',column)">add</v-icon>
			<kanban-column-edit
				:column="column"
				:all-columns="allColumns"
				:is-collaborator="isCollaborator"
				@update-deliverable-status-list="updateDeliverableStatusList()"
				@confirm-delete-column="confirmDeleteColumn($event)"
			></kanban-column-edit>
		</div>
	</div>
</template>

<script>
	import KanbanColumnEdit from "@/modules/projects/management/KanbanColumnEdit";

	export default {
		name: 'KanbanHeader',

		props: ['allColumns', 'column', 'isCollaborator', 'currentView'],

		components: {KanbanColumnEdit},

		data: function () {
			return {
			};
		},

		mounted() {},

		methods: {
			setStatusComplete: function (column) {
				for (let i = 0; i < this.allColumns.length; i++) {
					this.allColumns[i].complete = false;
				}
				column.complete = true;
				this.updateDeliverableStatusList();
			},

			confirmDeleteColumn: function (column) {
				this.$emit('confirm-delete-column', column);
			},

			updateDeliverableStatusList: function () {
				this.$emit('update-deliverable-status-list');
			},
		},

		computed: {
			maxHeaderWidth: function () {
				let maxWidth = 90;

				if (this.currentView === 'TABLE') {
					maxWidth = 92;
				}
				if (this.currentView === 'CARD') {
					maxWidth = 156;
				}

				return maxWidth;
			},
		},
	};
</script>

<style scoped lang="scss">
	.columnDragHandle {
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}

	.truncate {
		max-width: var(--max-header-width);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
