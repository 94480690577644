<template>
	<div>
		<div v-for="(modal,index) in modals" :key="modal.id">
			<basic-modal :style="`z-index: ${200 + index}`"
					v-if="modal.type === 'standard'"
					:dialog="modal.dialog"
					@close="handleClose(modal)"
					:close-on-escape="modal.closeOnEscape"
					:fullscreen="modal.fullScreen"
					:retain-focus="modal.retainFocus"
					:persistent="modal.persistent"
					overlay-color="modal_overlay"
			>
				<component
						:key="'component-' + modal.id"
						:ref="modal.id"
						:is="modal.component"
						v-bind="modal.binding"
						@result="onResult(modal, $event)"
						@error="onError(modal, $event)"
				></component>
			</basic-modal>

			<right-modal :style="`z-index: ${200 + index}`" v-if="modal.type === 'right'" @close="handleClose(modal)" :persistent="modal.persistent" :close-on-escape="modal.closeOnEscape">
				<component
						:key="'component-' + modal.id"
						:ref="modal.id"
						:is="modal.component"
						v-bind="modal.binding"
						@result="onResult(modal, $event)"
						@error="onError(modal, $event)"
				></component>
			</right-modal>
		</div>
	</div>
</template>

<script>
	import Deferred from '@/modules/utils/Deferred';
	import { v4 as uuidv4 } from 'uuid';
	import BasicModal from '@/components/BasicModal';
	import DeliverableDetail from '@/modules/projects/deliverable/DeliverableDetail';
  import DeliverablePackageEdit from "@/modules/templates/deliverables/DeliverablePackageEdit";
	import InvoiceDetails from '@/modules/invoices/InvoiceDetails';
	import ProposalBuilder from '@/modules/proposals/ProposalBuilder';
	import TimeTrackEdit from '@/modules/timetracking/TimeTrackEdit';
	import GoogleEventDetail from '@/modules/calendar/details/GoogleEventDetail';
	import NativeEventDetail from '@/modules/calendar/details/NativeEventDetail';
	import AggregateTimerList from '@/modules/calendar/lists/AggregateTimerList';
	import CreateInvoiceModal from '@/modules/invoices/CreateInvoiceModal';
	import ProjectSelector from '@/modules/projects/ProjectSelector';
	import NewProposal from '@/modules/proposals/NewProposal';
  import NewProposalTemplate from "@/modules/templates/proposals/NewProposalTemplate.vue";
	import ConfirmModal from '@/components/ConfirmModal';
	import InviteDialog from '@/modules/users/InviteDialog';
	import ProjectListDialog from '@/modules/users/ProjectListDialog';
	import ClearSampleModal from '@/modules/clients/ClearSampleModal';
	import ClientProjectSelectModal from '@/modules/timetracking/ClientProjectSelectModal';
	import SchedulerDetail from '@/modules/meetings/SchedulerDetail';
	import MeetingDetail from '@/modules/meetings/MeetingDetail';
	import AppleCalendarConnect from '@/modules/account/apps/AppleCalendarConnect';
	import AppleEventDetail from '@/modules/calendar/details/AppleEventDetail';
	import PermissionModal from '@/modules/portal/PermissionModal';
	import Mapper from '@/modules/account/import/Mapper';
	import CreatePackageFromProjectModal from '@/modules/templates/deliverables/CreatePackageFromProjectModal';
	import CreatePackageFromProposalDeliverablesModal from '@/modules/templates/deliverables/CreatePackageFromProposalDeliverablesModal';
	import CreateTemplateFromProposal from '@/modules/templates/proposals/CreateTemplateFromProposal';
	import StripeProduct from '@/modules/integrations/stripe/subscriptions/StripeProduct';
	import SendSubscriptionRequest from '@/modules/integrations/stripe/subscriptions/SendSubscriptionRequest';
	import LinkShare from '@/components/LinkShare';
	import SubscriptionPayments from '@/modules/integrations/stripe/subscriptions/SubscriptionPayments';
	import SimpleTextInput from '@/components/SimpleTextInput';
	import ProposalTemplateBuilder from '@/modules/templates/proposals/ProposalTemplateBuilder';
	import ClientCreate from '@/modules/clients/ClientCreate';
	import FormSubmissionDetail from '@/modules/discovery/FormSubmissionDetail';
	import ProposalTemplateModal from '@/modules/templates/proposals/ProposalTemplateModal';
	import Settings from "@/modules/settings/Settings";
	import DeliverableList from "@/modules/calendar/lists/DeliverableList";
	import CalendarPaymentList from "@/modules/calendar/lists/CalendarPaymentList";
	import NewProject from "@/modules/projects/NewProject";
	import ProjectEdit from "@/modules/projects/detail/ProjectEdit";
	import ExpenseDetail from "@/modules/accounting/expenses/ExpenseDetail";
	import MetricTableModal from "@/modules/home/insights/MetricTableModal";
	import VendorDetail from "@/modules/accounting/vendors/VendorDetail";
	import NewEmail from "@/modules/communicator/inbox/email/NewEmail";
	import ContactEdit from "@/modules/clients/contacts/ContactEdit";
	import CommunicationDetailModal from "@/modules/communicator/inbox/CommunicationDetailModal";
	import MailboxConfig from "@/modules/communicator/settings/MailboxConfig";
	import EmailTemplateEditor from "@/modules/templates/emails/EmailTemplateEditor";
	import EmailTemplateSelector from "@/modules/templates/emails/EmailTemplateSelector";
	import PaymentDetail from "@/modules/accounting/payments/PaymentDetail";
	import RecurringDeliverable from "@/modules/projects/deliverable/RecurringDeliverable";
	import RecurringInvoiceDetails from "@/modules/invoices/recurring/RecurringInvoiceDetails";
	import MicrosoftEventDetail from "@/modules/calendar/details/MicrosoftEventDetail";
	import RightModal from "@/components/RightModal";
	import ClientEdit from "@/modules/clients/detail/ClientEdit";
	import TicketTypeModal from "@/modules/communicator/settings/TicketTypeModal";
	import NewWorkspace from "@/modules/account/NewWorkspace";
	import OpportunityDetail from "@/modules/pipeline/opportunity/OpportunityDetail";
	import OpportunitySelector from "@/modules/pipeline/OpportunitySelector";
	import WorkflowEditor from "@/modules/workflow/WorkflowEditor";
	import NewWorkflow from "@/modules/workflow/NewWorkflow";
	import AutomationModal from "@/modules/workflow/AutomationModal";
	import CustomPage from "@/modules/portal/customization/CustomPage";
	import ShareCalendar from "@/modules/calendar/ShareCalendar";
	import FormBuilder from "@/modules/forms/FormBuilder";
	import PaymentDetailsModal from "@/modules/invoices/InvoiceRenderer/PaymentDetailsModal";
  import PasswordEnabled from "@/modules/account/PasswordEnabled.vue";
	import InvoiceTemplateBuilder from "@/modules/templates/invoices/InvoiceTemplateBuilder";
	import SchedulerSelector from "@/modules/meetings/SchedulerSelector";
	import FeatureListDialog from "@/modules/users/FeatureListDialog";
	import CreateTicket from "@/modules/communicator/inbox/tickets/CreateTicket";
	import AgreementBuilder from "@/modules/agreements/AgreementBuilder";
	import PaymentPlanDetails from "@/modules/invoices/paymentPlan/PaymentPlanDetails";
	import AgreementTemplateBuilder from "@/modules/templates/agreements/AgreementTemplateBuilder";
	import TemplateLibrary from "@/modules/templates/library/TemplateLibrary";
	import TicketDetail from "@/modules/communicator/inbox/tickets/TicketDetail";
	import DeliverableImportModal from "@/modules/templates/deliverables/DeliverableImportModal";
	import AiChatWidget from "@/modules/ai/AiChatWidget";
	import AiLicenseAlert from "@/modules/ai/AiLicenseAlert";
	import DeliverablePackageTaskEdit from "@/modules/templates/deliverables/DeliverablePackageTaskEdit";
	import CalDavConnect from "@/modules/account/apps/CalDavConnect";
	import CalDavEventDetail from "@/modules/calendar/details/CalDavEventDetail";
	import ClientTransfer from "@/modules/projects/ClientTransfer";
	import BookMeeting from "@/modules/meetings/BookMeeting";

  export default {
		name: 'GlobalModalController',

		props: [],

		components: {
			BasicModal,
			RightModal,
			DeliverableDetail,
			DeliverablePackageEdit,
			DeliverablePackageTaskEdit,
			InvoiceDetails,
			ProposalBuilder,
			TimeTrackEdit,
			GoogleEventDetail,
			AppleEventDetail,
			NativeEventDetail,
			MicrosoftEventDetail,
			AggregateTimerList,
			DeliverableList,
			CalendarPaymentList,
			CreateInvoiceModal,
			ProjectSelector,
			NewProposal,
      NewProposalTemplate,
			ConfirmModal,
			InviteDialog,
			ProjectListDialog,
			ClearSampleModal,
			ClientProjectSelectModal,
			SchedulerDetail,
			MeetingDetail,
			AppleCalendarConnect,
			PermissionModal,
			Mapper,
			CreatePackageFromProjectModal,
			CreatePackageFromProposalDeliverablesModal,
			CreateTemplateFromProposal,
			StripeProduct,
			SendSubscriptionRequest,
			LinkShare,
			SubscriptionPayments,
			SimpleTextInput,
			ProposalTemplateBuilder,
			ClientCreate,
			FormSubmissionDetail,
			ProposalTemplateModal,
			Settings,
			NewProject,
			ProjectEdit,
			ExpenseDetail,
			MetricTableModal,
			VendorDetail,
			NewEmail,
			ContactEdit,
			CommunicationDetailModal,
			MailboxConfig,
			EmailTemplateEditor,
			EmailTemplateSelector,
			PaymentDetail,
			RecurringDeliverable,
			RecurringInvoiceDetails,
			TicketTypeModal,
			ClientEdit,
			NewWorkspace,
			OpportunityDetail,
			OpportunitySelector,
			WorkflowEditor,
			NewWorkflow,
			AutomationModal,
			CustomPage,
			ShareCalendar,
			FormBuilder,
			PaymentDetailsModal,
      PasswordEnabled,
			InvoiceTemplateBuilder,
			SchedulerSelector,
			FeatureListDialog,
			CreateTicket,
			AgreementBuilder,
			PaymentPlanDetails,
			AgreementTemplateBuilder,
			TemplateLibrary,
      TicketDetail,
			DeliverableImportModal,
			AiChatWidget,
			AiLicenseAlert,
			CalDavConnect,
			CalDavEventDetail,
			ClientTransfer,
			BookMeeting
		},

		data: function() {
			return {
				ignoreBackEvent: false,
				forceClosed: false,
				modals: [],
			};
		},

		mounted() {
			this.$store.state.globalModalController = this;
			window.onpopstate = this.handleHistoryPop;
		},

		beforeDestroy() {
			this.$store.state.globalModalController = null;
		},

		methods: {
			handleHistoryPop: function() {
				if (this.ignoreBackEvent) {
					this.ignoreBackEvent = false; //reset flag
					return;
				}

				let modal = this.modals.pop();
				if (modal) {
					this.removeModal(modal, false);
				}
			},

			forceCloseAll: function(){
				this.forceClosed = true;
				let openModals = [... this.modals];
				for(let i=0; i < openModals.length; i++){
					let modal = openModals[i];
					this.handleClose(modal);
				}
			},

			openModal(component, binding, closeOnEscape = true, fullScreen = false, retainFocus = false, persistent = true) {
				let dfd = new Deferred();
				let modal = {
					id: uuidv4(),
					component: component.name,
					binding: binding,
					dialog: true,
					closeOnEscape: closeOnEscape,
					fullScreen: fullScreen,
					retainFocus: retainFocus,
					persistent: persistent,
					type: component.isRightModal && !binding.forceStandard ? 'right' : 'standard',
					dfd: dfd,
				};

				setTimeout(() => {
					window.history.pushState({ id: modal.id }, document.title, location.href + '#' + modal.id);
				}, 250);

				this.forceClosed = false;
				this.modals.push(modal);
				return dfd.promise;
			},

			handleClose(modal) {
				// Tinymce link modal was erroring out. This test fixes it. Weird.
				if (typeof this.$refs[modal.id][0].handleClose !== 'undefined') {
					this.$refs[modal.id][0].handleClose();
				}
			},

			onResult(modal, result) {
				modal.dialog = false;
				modal.dfd.resolve(result);
				this.removeModal(modal);
			},

			onError(modal, error) {
				modal.dialog = false;
				modal.dfd.reject(error);
				this.removeModal(modal);
			},

			removeModal(modal, goBack = true) {
				if (goBack && !this.forceClosed) {
					this.ignoreBackEvent = true;
					window.history.back();
				}

				let ix = this.modals.findIndex((m) => m.id === modal.id);
				this.modals.splice(ix, 1);
			},
		},

		computed: {},
	};
</script>

<style lang="scss">
	.v-dialog::-webkit-scrollbar {
		display: block !important;
	}
</style>
