<template>
	<div class="fill-all column-format">
		<slide v-bind="slide" @back="handleBack()" @next="handleNext()"></slide>
	</div>
</template>

<script>
	import carousel from './carousel/carousel.json';
	import Slide from '@/modules/welcome/carousel/Slide';


	export default {
		name: 'Carousel',

		props: ['userInfo','initialIndex'],

		components: { Slide},

		data: function () {
			return {
				index: this.initialIndex ? this.initialIndex : 0,
				carousel: carousel,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleBack: function(){
				if(this.index === 0){
					this.$emit('back');
				}else{
					this.index--;
				}
			},

			handleNext: function(){
				if(this.index === this.activeIntention.slides.length -1){
					this.$emit('next');
				}else{
					this.index++;
				}
			}
		},

		watch: {
			index: {
				immediate: true,
				handler: function(val){
					this.$emit('current-index',val);
				}
			},
		},

		computed: {
			activeIntention: function () {
				return this.carousel.find((c) => c.id === this.userInfo.intention);
			},

			slide: function () {
				return this.activeIntention.slides[this.index];
			},

		},
	};
</script>

<style scoped lang="scss"></style>
