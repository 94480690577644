var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format text-left" },
    [
      _c("div", [_vm._v("Columns")]),
      _c("v-slider", {
        attrs: {
          min: 1,
          max: 3,
          step: 1,
          "hide-details": "",
          dense: "",
          "show-ticks": "always",
          "tick-size": "4",
          "tick-labels": ["1", "2", "3", "4"],
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.columnCount,
          callback: function($$v) {
            _vm.$set(_vm.element, "columnCount", $$v)
          },
          expression: "element.columnCount"
        }
      }),
      _c("div", { staticClass: "mt-5" }, [_vm._v("Min Height (px)")]),
      _c("v-slider", {
        attrs: {
          min: 40,
          max: 800,
          step: 1,
          "hide-details": "",
          dense: "",
          "thumb-label": ""
        },
        on: { input: _vm.updated },
        model: {
          value: _vm.element.minHeight,
          callback: function($$v) {
            _vm.$set(_vm.element, "minHeight", $$v)
          },
          expression: "element.minHeight"
        }
      }),
      _c("div", { staticClass: "mt-3" }, [_vm._v("Background color")]),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-click": false,
            "close-on-content-click": false,
            bottom: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g(
                      {
                        staticClass: "pointer",
                        style:
                          "border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                          _vm.element.backgroundColor +
                          "; border: 1px solid var(--v-gray_20-base)"
                      },
                      on
                    ),
                    [_vm._v("   ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.colorMenu,
            callback: function($$v) {
              _vm.colorMenu = $$v
            },
            expression: "colorMenu"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "ma-3 column-format align-center gap-3",
              staticStyle: { "background-color": "var(--v-white-base)" }
            },
            [
              _c("v-color-picker", {
                attrs: {
                  mode: "hexa",
                  "show-swatches": "",
                  "swatches-max-height": "150px"
                },
                on: { input: _vm.updated },
                model: {
                  value: _vm.element.backgroundColor,
                  callback: function($$v) {
                    _vm.$set(_vm.element, "backgroundColor", $$v)
                  },
                  expression: "element.backgroundColor"
                }
              }),
              _c(
                "v-btn",
                {
                  attrs: { block: "" },
                  on: {
                    click: function($event) {
                      _vm.colorMenu = false
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "mt-3" }, [_vm._v("Background image")]),
      _c(
        "div",
        {
          staticClass: "pointer row-format centered",
          style:
            "border-radius: 4px; min-width:100%: min-height: 20px; background-image: url(" +
            _vm.element.backgroundImageUrl +
            "); border: 1px solid var(--v-gray_20-base)"
        },
        [
          _vm.element.backgroundImageUrl
            ? _c(
                "v-btn",
                {
                  staticClass: "secondary-action",
                  attrs: { icon: "", "x-small": "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.setCustomImage(null)
                    }
                  }
                },
                [_c("v-icon", { attrs: { size: "16" } }, [_vm._v("$delete")])],
                1
              )
            : _c("div", [_vm._v("   ")])
        ],
        1
      ),
      _c("div", { staticClass: "mt-3" }, [_vm._v("Image library")]),
      _c(
        "div",
        { staticClass: "image-grid" },
        [
          _vm._l(_vm.imageAssets, function(result) {
            return _c(
              "div",
              {
                key: result.id,
                staticClass: "pointer image-container custom-image-container",
                style: "background-image: url(" + result.thumbnail + ");",
                on: {
                  click: function($event) {
                    return _vm.setCustomImage(result)
                  }
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "secondary-action",
                    attrs: { icon: "", "x-small": "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.confirmDelete(result)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { size: "16" } }, [_vm._v("$delete")])
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "vue-dropzone",
            {
              ref: "fileDropzone",
              staticClass: "image-container no-image-container",
              attrs: {
                id: "dropzone",
                "use-custom-slot": true,
                options: _vm.dropzoneOptions
              },
              on: {
                "vdropzone-success": _vm.uploadSuccess,
                "vdropzone-error": _vm.uploadFailure,
                "vdropzone-sending": _vm.addFileUploadAuthHeader
              }
            },
            [
              _c(
                "div",
                [
                  _c("v-icon", [_vm._v("$vuetify.icons.upload")]),
                  _c("div", { staticClass: "font-12" }, [
                    _vm._v(_vm._s(_vm.$t("proposal.cover.upload")))
                  ])
                ],
                1
              )
            ]
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "mt-4 column-format gap-4 text-left" },
        _vm._l(_vm.element.columnCount, function(index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "px-4 pt-2",
              staticStyle: {
                border: "1px solid var(--v-gray_30-base)",
                "border-radius": "4px"
              }
            },
            [
              _c("span", { staticClass: "font-14 brand-medium" }, [
                _vm._v("Column " + _vm._s(index))
              ]),
              _c(
                "div",
                { staticClass: "row-format gap-1 mb-n3" },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      min: "0",
                      max: "100",
                      label: "Padding left"
                    },
                    model: {
                      value: _vm.element.columns[index - 1].pl,
                      callback: function($$v) {
                        _vm.$set(_vm.element.columns[index - 1], "pl", $$v)
                      },
                      expression: "element.columns[index - 1].pl"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      min: "0",
                      max: "100",
                      label: "Padding right"
                    },
                    model: {
                      value: _vm.element.columns[index - 1].pr,
                      callback: function($$v) {
                        _vm.$set(_vm.element.columns[index - 1], "pr", $$v)
                      },
                      expression: "element.columns[index - 1].pr"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row-format gap-1 mb-n2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      min: "0",
                      max: "100",
                      label: "Padding top"
                    },
                    model: {
                      value: _vm.element.columns[index - 1].pt,
                      callback: function($$v) {
                        _vm.$set(_vm.element.columns[index - 1], "pt", $$v)
                      },
                      expression: "element.columns[index - 1].pt"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      min: "0",
                      max: "100",
                      label: "Padding bottom"
                    },
                    model: {
                      value: _vm.element.columns[index - 1].pb,
                      callback: function($$v) {
                        _vm.$set(_vm.element.columns[index - 1], "pb", $$v)
                      },
                      expression: "element.columns[index - 1].pb"
                    }
                  })
                ],
                1
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }