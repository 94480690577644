<template>
	<v-menu
		v-if="filter"
		:nudge-top="28"
		bottom
		right
		rounded
		offset-overflow
		offset-y
		:close-on-content-click="false"
		content-class="filter-menu-class"
		transition="slide-y-transition"
		v-model="menuOpen"
		max-height="90vh"
		max-width="250"
		:close-on-click="true"
		style="overflow-y: auto"
	>
		<template v-slot:activator="{ on }">
			<v-btn
				v-on="on"
				style="min-height: 40px; background-color: var(--v-white-base)"
				elevation="0"
				outlined
				color="gray_30"
			>
				<span
					:class="`font-14 ${!isDefaultFilter ? 'font-secondary' : 'font-gray_60'} brand-medium`"
					v-html="isDefaultFilter ? 'Filter submissions' : filterDescription"
				></span>
				<v-icon x-small class="mx-1" :color="!isDefaultFilter ? 'secondary' : 'gray_80'">$arrowDown</v-icon>
			</v-btn>
		</template>

		<div class="column-format open-menu">
			<filter-option
				v-show="showClientList"
				:label="$t('client.filter.client-list')"
				:option-list="clientList"
				v-model="filter.clientList"
				@change="updated"
			></filter-option>

			<div class="filter-option-box">
				<div class="row-format" @click="dateExpanded = !dateExpanded">
					<div class="font-14">{{ $t('client.filter.date-range') }}</div>
					<v-icon style="margin-left: auto; margin-top: 1px" small>{{
						dateExpanded ? '$arrowUp' : '$arrowDown'
					}}</v-icon>
				</div>

				<div v-if="dateExpanded">
					<v-radio-group v-model="filter.dateSpecifier" @change="updated()">
						<v-radio v-for="option in dateOptions" :key="option.value" :value="option.value">
							<template v-slot:label>
								<span style="font-size: 14px" class="font-14 brand-semilight">{{ option.text }}</span>
							</template>
						</v-radio>
					</v-radio-group>

					<div class="" v-if="showEarliest">
						<date-selector
							content-class="my-5"
							:date="filter.earliest"
							@change="
								filter.earliest = $event;
								updated();
							"
							label="Earliest"
						></date-selector>
					</div>

					<div class="" v-if="showLatest">
						<date-selector
							content-class="my-5"
							:date="filter.latest"
							@change="
								filter.latest = $event;
								updated();
							"
							label="Latest"
						></date-selector>
					</div>
				</div>
			</div>

			<div class="mx-3 mt-3 mb-2">
				<v-btn text block color="primary" elevation="0" class="filter-clear mt-2 mb-1" @click="clearFilter()"
					><v-icon small>$clear</v-icon> {{ $t('timetrack.filter.clear-filter') }}</v-btn
				>
				<v-btn block class="filter-submit" @click="menuOpen = false" color="primary" elevation="0">{{
					$t('global.done')
				}}</v-btn>
			</div>
		</div>
	</v-menu>
</template>

<script>
	import FilterHelpers from '@/utils/FilterHelpers';
	import DateSelector from '@/components/DateSelector';
	import FilterOption from '@/components/FilterOption';
	import DateTime from '@/modules/utils/HDateTime';

	export default {
		name: 'DiscoveryListFilter',

		props: ['value'],

		components: { FilterOption, DateSelector },

		data: function() {
			return {
				menuOpen: false,
				filter: JSON.parse(JSON.stringify(this.value)),

				dateOptions: FilterHelpers.getDateOptions([
					'today',
					'past-30-days',
					'past-3-months',
					'past-6-months',
					'past-12-months',
					'this-year',
					'last-year',
					'all-time',
					'between',
				]),

				dateExpanded: false,
				meetingStatusExpanded: false,
				clientListExpanded: false,
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			clearFilter: function() {
				this.$emit('clear-filter');
			},
			updated: function() {
				this.$emit('input', this.filter);
			},
		},

		watch: {
			value: {
				deep: true,
				handler(val) {
					this.filter = JSON.parse(JSON.stringify(val));
				},
			},
		},

		computed: {
			filterDescription() {
				let l = [];
				let f = this.filter;

				if (f.dateSpecifier) {
					if (f.dateSpecifier === 'between') {
						return (
							DateTime.fromISO(f.earliest).toLocaleString({ month: 'short', day: 'numeric' }) +
							' - ' +
							DateTime.fromISO(f.latest).toLocaleString({ month: 'short', day: 'numeric' })
						);
					} else {
						l.push(this.dateOptions.find((d) => d.value === f.dateSpecifier).text);
					}
				}

				if (f.clientList.length) {
					l.push(f.clientList.length + ' clients');
				}

				if (l.length) {
					return l.join(' &bull; ');
				} else {
					return null;
				}
			},

			clientList: function() {
				let result = [];
				this.$store.getters.clients.forEach((c) => {
					result.push({
						text: c.name,
						value: c.id,
					});
				});
				return result;
			},

			showEarliest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}

				if (this.filter.dateSpecifier === 'between') {
					return true;
				} else {
					return false;
				}
			},

			showLatest: function() {
				if (!this.filter || !this.filter.dateSpecifier) {
					return false;
				}

				if (this.filter.dateSpecifier === 'between') {
					return true;
				} else {
					return false;
				}
			},

			showDateRange: function() {
				return true;
			},

			showClientList: function() {
				return true;
			},

			isDefaultFilter() {
				if (this.filterDescription === null) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>

<style scoped lang="scss"></style>
