<template>
	<div class="column-format text-left font-14 font-gray_70">
		<div>Weight (px)</div>
		<v-slider
			v-model="element.weight"
			:min="1"
			:max="50"
			:step="1"
			hide-details
			dense
			@input="updated"
			thumb-label
		></v-slider>

		<div>Width (%)</div>
		<v-slider
			v-model="element.width"
			:min="1"
			:max="100"
			:step="1"
			hide-details
			dense
			@input="updated"
			thumb-label
		></v-slider>

		<div>Padding top</div>
		<v-slider v-model="element.pt" :min="0" :max="50" :step="1" hide-details dense @input="updated" thumb-label></v-slider>

		<div>Padding bottom</div>
		<v-slider v-model="element.pb" :min="0" :max="50" :step="1" hide-details dense @input="updated" thumb-label></v-slider>

		<v-menu :close-on-click="false" :close-on-content-click="false" v-model="colorMenu" bottom>
			<template v-slot:activator="{ on }">
				<div
					v-on="on"
					:style="`border-radius: 4px; min-width:100%: min-height: 20px; background-color: ${element.color}`"
					class="pointer"
				>
					&nbsp;
				</div>
			</template>
			<div style="background-color: var(--v-white-base)" class="ma-3 column-format align-center gap-3">
				<v-color-picker
					mode="hexa"
					v-model="element.color"
					show-swatches
					swatches-max-height="150px"
					@input="updated"
				></v-color-picker>
				<v-btn @click="colorMenu = false" block>OK</v-btn>
			</div>
		</v-menu>
	</div>
</template>

<script>
	export default {
		name: 'DividerConfig',

		props: ['item'],

		components: {},

		data: function() {
			return {
				colorMenu: false,
				element: { ...this.item },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>