<template>
	<div class="column-format pa-1" style="height: 100%; border-right: 1px solid var(--v-gray_20-base)">
		<div :class="`pointer link row-format centered ${currentTab === tab.label ? 'active' : ''}`" v-for="tab in tabs" :key="tab.label" v-tippy="{content: tab.label, placement : 'right',arrow : true, arrowType : 'round', animation : 'fade', delay: 50, boundary: 'window'}" @click="$emit('change',tab.label)">
			<v-icon class="material-symbols-rounded" size="24" :color="currentTab === tab.label ? 'primary' : 'black'">{{ tab.icon }}</v-icon>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TabSwitcher',

		props: ['currentTab'],

		components: {},

		data: function() {
			return {};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {},

		computed: {
			selected: function() {
				return this.tabs.find((t) => t.label === this.currentTab);
			},

			tabs: function() {
				return [
					{icon: 'description', label: 'Pages'},
					{icon: 'dashboard_customize', label: 'Elements'},
					{icon: 'palette', label: 'Styles'},
					{icon: 'signature', label: 'Signers'},
					{icon: 'settings', label: 'Settings'},
					{icon: 'alarm', label: 'Reminders'},
				];
			},
		},
	};
</script>

<style scoped lang="scss">
	.link {
		width: 48px;
		height: 48px;
		border-radius: 8px;
	}

	.active {
		background-color: var(--v-gray_10-base);
	}
</style>
