<template>
	<div  :class="`row-format align-center ml-1 ${view === 'Two-Week' ? 'mt-1' : ''}`" style="position:relative;">
		<client-avatar disable-click x-small  style="transform: scale(.92)" :client="client" v-if="client"></client-avatar>
		<v-icon small v-else>splitscreen</v-icon>
		<div class="ml-1 font-gray_90">{{name}}</div>
		<div class="status-box row-format centered">
			<div class="status" :style="`--status-color:${status.hexColor}`"></div>
		</div>
	</div>
</template>

<script>
	import ClientAvatar from "@/components/ClientAvatar";

	export default {
		name: 'DeliverableEvent',

		props: ['event','view'],

		components: { ClientAvatar },

		data: function () {
			return {
				id: this.event.original.meta.id,
				name: this.event.name,
				client: this.event.original.meta.client,
				project: this.event.original.meta.project,
				status: this.$store.getters.getDeliverableStatusById(this.event.original.meta.statusId),
				assignedToList: this.event.original.meta.assignedToList,
			};
		},

		mounted() {
		},

		beforeDestroy() {},

		methods: {},

		computed: {
		},
	};
</script>

<style scoped lang="scss">
	.status-box {
		background-color: var(--v-gray_20_base);
		height:100%;
		width:15px;
		position: absolute;
		right:0;
	}

	.status {
		background-color: var(--status-color);
		width:10px;
		height:10px;
		border-radius: 5px;
	}
</style>
