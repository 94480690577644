<template>
	<div class="column-format fill-height" v-if="isInitialized">
		<input id="shareableLink" :value="shareableLink" type="hidden" />

		<slot name="filter"></slot>


		<div class="row-format centered fill-height"  style="min-height: calc(100vh - 300px)" v-if="isEmpty">
			<empty-view
				header="No forms yet"
				body="The most common kind of form is a discovery form. This helps you learn a potential client’s needs and budget, as well as screen good ones from bad. But these forms are completely customizable, so you can make them into anything you need."
				cta="Send a form"
				video-header="See how it works"
				video-body="Learn how to create and customize forms for discovery, follow-up, customer satisfaction, feedback, or anything else you can think of."
				video-cta="Watch the tutorial"
				video-id="-IL2PtIX6ZQ"
				@cta-clicked="addNew()"
			></empty-view>
		</div>


		<div
			v-if="discoveryList.length"
			class="text-left column-format gap-2 font-14 mt-3 pa-3"
			style="border: 1px solid var(--v-gray_30-base); border-radius: 4px; background-color: var(--v-white-base)"
		>
			<div v-for="discovery in discoveryList" :key="discovery.id" class="form-wrapper font-14">
				<div class="row-format align-center gap-2">
					<div>{{ discovery.name }}</div>
					<div
						v-if="!discovery.completedTimestamp && !discovery.viewedTimestamp"
						class="row-format align-center font-12 font-gray_70"
					>
						Created: {{ DateTime.fromISO(discovery.sentTimestamp).toLocaleString(DateTime.DATETIME_FULL) }}
					</div>

					<div v-else-if="discovery.viewedTimestamp" class="row-format align-center font-12">
						<div style="color: var(--v-primary-base)">Viewed:</div>
						<div style="color: var(--v-primary-base)" class="ml-">
							{{ DateTime.fromISO(discovery.viewedTimestamp).toLocaleString(DateTime.DATETIME_FULL) }}
						</div>
					</div>

					<div class="ml-auto row-format gap-2">
						<v-icon
							size="20"
							class="material-symbols-rounded pointer"
							@click.stop="copyLink(discovery.url)"
							v-tippy="{ content: 'Copy the link for the form request' }"
							>link</v-icon
						>
						<v-icon
							size="20"
							class="material-symbols-rounded pointer"
							@click="editDiscoveryRequest(discovery)"
							v-tippy="{ content: 'Make changes or send the request via email' }"
							>edit</v-icon
						>
						<v-icon
							size="20"
							class="material-symbols-rounded pointer"
							@click="confirmDelete(discovery)"
							v-tippy="{ content: 'Delete form request' }"
							>delete</v-icon
						>
					</div>
				</div>
			</div>
		</div>

		<form-submissions-table
			class="mt-3"
			v-show="formSubmissionCount > 0"
			:client-id="client.id"
			@record-count="setRecordCount($event)"
			:filter="filter"
		></form-submissions-table>

		<basic-modal
			v-if="newDiscoveryDialog"
			:dialog="newDiscoveryDialog"
			:persistent="false"
			:max-width="maxWidth"
			@close="handleTemplateModalClose()"
		>
			<discovery-templates
				:client-id="client.id"
				discoveryOrigin="clientDetail"
				@close="handleTemplateModalClose()"
				:modal-state="modalState"
				:v2-only="true"
				@template-selected="handleTemplateSelected"
			></discovery-templates>
		</basic-modal>

		<basic-modal
			v-if="discoveryDialog"
			:dialog="discoveryDialog"
			:persistent="true"
			:fullscreen="true"
			:retain-focus="false"
			@close="handleBuilderClose"
		>
			<discovery-builder
				:client="client"
				:template-id="templateId"
				@close="handleBuilderClose"
				@sent="handleBuilderSent"
				@duplicated="handleBuilderDuplicated"
			></discovery-builder>
		</basic-modal>

		<confirm-dialog
			:dialog="confirmDeleteDialog"
			heading-text="Delete?"
			body-text="Are you sure you want to delete this discovery request?"
			@close="cancelDelete"
			@confirm="deleteForm"
		>
		</confirm-dialog>
	</div>
</template>

<script>
	import DiscoveryService from '@/modules/discovery/DiscoveryService';
	import DiscoveryTemplates from '@/modules/discovery/DiscoveryTemplates';
	import DateTime from '@/modules/utils/HDateTime';
	import ConfirmDialog from '@/components/ConfirmDialog';
	import BasicModal from '@/components/BasicModal';
	import DiscoveryBuilder from '@/modules/discovery/DiscoveryBuilder';
	import EmptyView from '@/components/EmptyView';
	import FormSubmissionsTable from '@/modules/discovery/FormSubmissionsTable';
	import FormBuilder from '@/modules/forms/FormBuilder';

	export default {
		name: 'FormsList',

		props: ['client', 'isActive', 'filter'],

		components: { FormSubmissionsTable, DiscoveryBuilder, BasicModal, ConfirmDialog, DiscoveryTemplates, EmptyView },

		data: function() {
			return {
				discoveryService: new DiscoveryService(),
				discoveryList: [],
				expanded: [],
				isInitialized: false,
				DateTime: DateTime,
				confirmDeleteDialog: false,
				formToDelete: null,
				newDiscoveryDialog: false,
				shareableLink: null,
				formSubmissionCount: 0,

				discoveryDialog: false,
				templateId: null,

				modalState: {
					inSendMode: false,
				},
			};
		},

		mounted() {
			if (this.isActive) {
				this.initialize();
			}
		},

		beforeDestroy() {},

		methods: {
			setRecordCount: function(count) {
				this.formSubmissionCount = count;
			},

			handleTemplateModalClose: function() {
				this.newDiscoveryDialog = false;
				this.modalState.inSendMode = false;
			},

			handleTemplateSelected: function(template) {
				if (template.schemaVersion === 1) {
					this.handleTemplateModalClose();
					this.discoveryDialog = true;
					this.templateId = template.id;
				} else {
					this.newDiscoveryDialog = false;
					this.discoveryService.createDiscoveryRequest(this.client.id, template).then((res) => {
						this.$store.commit(
							'success',
							`You are now editing a version of this form specifically for ${this.client.name}.  Any changes you make will not be reflected in the main template.`
						);
						this.editDiscoveryRequest(res.data);
					});
				}
			},

			editDiscoveryRequest(request) {
				let binding = {
					id: request.id,
					client: this.client,
				};
				this.$store.state.globalModalController.openModal(FormBuilder, binding, false, true, false, true).then(() => {
					this.initialize();
				});
			},

			handleBuilderClose: function() {
				this.discoveryDialog = false;
				this.templateId = null;
			},

			handleBuilderSent: function() {
				this.discoveryDialog = false;
				this.templateId = null;
				this.initialize();
			},

			handleBuilderDuplicated: function(template) {
				this.templateId = template.id;
			},

			initialize: function() {
				this.discoveryService
					.getDiscoveryRequests(this.client.id)
					.then((res) => {
						this.discoveryList.splice(0, this.discoveryList.length);
						this.discoveryList.push(...res.data);
						this.discoveryList.sort(this.sortBySent);
						this.isInitialized = true;
					})
					.catch((err) => {
						console.log(err);
					});
			},

			addNew: function() {
				console.log('adding new');
				this.newDiscoveryDialog = true;
			},

			confirmDelete: function(form) {
				this.confirmDeleteDialog = true;
				this.formToDelete = form;
			},

			cancelDelete: function() {
				this.confirmDeleteDialog = false;
				this.formToDelete = null;
			},

			copyLink: function(url) {
				navigator.clipboard.writeText(url);
				this.$store.commit('success', 'Link copied to clipboard');
			},

			deleteForm: function() {
				this.confirmDeleteDialog = false;
				this.discoveryService.deleteDiscoveryRequest(this.client.id, this.formToDelete.id).then(() => {
					let ix = this.discoveryList.findIndex((f) => f.id === this.formToDelete.id);
					if (ix > -1) {
						this.discoveryList.splice(ix, 1);
					}
				});
			},

			sortBySent: function(a, b) {
				return b.sentTimestamp.localeCompare(a.sentTimestamp);
			},
		},

		computed: {
			isEmpty: function() {
				if (this.formSubmissionCount == 0 && this.discoveryList.length == 0) {
					return true;
				} else {
					return false;
				}
			},

			maxWidth: function() {
				if (this.modalState.inSendMode) {
					return 684;
				} else {
					return this.$vuetify.breakpoint.width - 100;
				}
			},
		},

		watch: {
			isActive: function(newVal) {
				if (newVal && !this.isInitialized) {
					this.initialize();
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.form-wrapper {
	}
</style>
