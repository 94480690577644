var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    ref: "EmailInput",
    attrs: {
      items: _vm.tokens,
      "hide-details": "",
      dense: "",
      outlined: "",
      "persistent-placeholder": "",
      label: _vm.label,
      multiple: _vm.multiple,
      "small-chips": "",
      filter: _vm.isMatch
    },
    on: {
      change: function($event) {
        return _vm.$emit("change", _vm.to)
      }
    },
    model: {
      value: _vm.to,
      callback: function($$v) {
        _vm.to = $$v
      },
      expression: "to"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }