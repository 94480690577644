var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.assignedUser
      ? _c(
          "div",
          {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { content: _vm.firstName + " " + _vm.lastName },
                expression: "{ content: firstName + ' ' + lastName }"
              }
            ],
            staticClass: "row-format align-center pointer",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.clicked.apply(null, arguments)
              }
            }
          },
          [
            _vm.assignedUser.profilePicture
              ? _c("v-avatar", { attrs: { size: _vm.size } }, [
                  _c("img", {
                    staticClass: "pic",
                    attrs: { src: _vm.assignedUser.profilePicture }
                  })
                ])
              : _c("v-avatar", { attrs: { size: _vm.size, color: "black" } }, [
                  _c("span", { staticStyle: { color: "white" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.firstName.substring(0, 1).toUpperCase() +
                          _vm.lastName.substring(0, 1).toUpperCase()
                      )
                    )
                  ])
                ]),
            _vm.showName
              ? _c("div", { staticClass: "ml-2 assigned-user-name ellipsis" }, [
                  _vm._v(_vm._s(_vm.firstName) + " " + _vm._s(_vm.lastName))
                ])
              : _vm._e()
          ],
          1
        )
      : _c("div", {
          class: "" + _vm.emptyClass,
          domProps: { innerHTML: _vm._s(_vm.emptyLabel) },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.clicked.apply(null, arguments)
            }
          }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }