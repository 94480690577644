<template>
	<div class="panel-modal" style="width: 600px">
		<div class="panel-modal-header">
			<div class="row-format align-center">
				<v-icon small class="mr-2 pointer" @click="$emit('result')">$chevronRight</v-icon>
				<div class="brand-medium font-18">Automation executions</div>
				<div class="ml-auto">
					<v-icon size="20" color="secondary" class="pointer ml-2"  @click="$emit('result')">close</v-icon>
				</div>
			</div>
		</div>
		<div class="text-left show-scrollbar" style="max-height: calc(100% - 60px); overflow-y: auto">
			<automation-list :workflow-id="workflowId" :resource-type="resourceType" :resource-id="resourceId"></automation-list>
		</div>
	</div>
</template>

<script>
	import AutomationList from "@/modules/workflow/AutomationList";

	export default {
		name: 'AutomationModal',
		isRightModal: true,

		props: ['workflowId', 'resourceType', 'resourceId'],

		components: {
			AutomationList
		},

		data: function() {
			return {
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handleClose: function(){
				this.$emit('result');
			}
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
