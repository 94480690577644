<template>
	<div id="payment-details-modal">
		<v-btn icon class="close-dialog" @click="handleClose()">
			<v-icon>$close</v-icon>
		</v-btn>
		<div class="modal-padding">
			<h2 class="modal-title">{{ $t('invoice.details.payment-details-modal.heading') }}</h2>
			<p class="modal-subtitle">
				{{ $t('invoice.mark-paid-modal.subheading', { invoiceNumber: invoice.invoiceNumberFormatted }) }}
			</p>

			<v-container fluid class="px-0 pb-0">
				<v-row dense>
					<v-col cols="5" class="label">{{ $t('invoice.details.payment-details-modal.date-paid') }}</v-col>
					<v-col cols="7" class="value">
						{{ this.formattedDate() }}
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="5" class="label">{{ $t('invoice.status') }}</v-col>
					<v-col cols="7" class="value"  v-if="localPayment.pending">
						{{ $t('invoice.pending') }}
						<v-icon small color="warning" class="mb-1">$alert</v-icon>
					</v-col>
					<v-col v-else cols="7" class="value">
						{{ $t('invoice.cleared') }} <v-icon small color="success" class="mb-1">$success</v-icon>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="5" class="label">{{ $t('invoice.details.payment-details-modal.amount') }}</v-col>
					<v-col cols="7" class="value">
						<span>{{ $formatters.dollars(localPayment.amount,true,true,localPayment.currency) }}</span>
						<span v-if="localPayment.localAmount" class="font-12 font-gray_70"> / {{ $formatters.dollars(localPayment.localAmount,true,true,$store.state.defaultCurrency) }}</span>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="5" class="label">{{ $t('invoice.details.payment-details-modal.payment-type') }}</v-col>
					<v-col cols="7" class="value">
						{{ this.$t('invoice.payment-providers.' + localPayment.paymentProvider) }}
					</v-col>
				</v-row>
				<v-row dense v-if="localPayment.forcePaidInFull">
					<v-col cols="12" class="value brand-medium">{{ $t('invoice.details.payment-details-modal.mark-fully-paid') }}</v-col>
				</v-row>
				<v-row dense v-if="localPayment.memo">
					<v-col cols="5" class="label">{{ $t('invoice.details.payment-details-modal.memo') }}</v-col>
					<v-col cols="7" class="value">
						{{ localPayment.memo }}
					</v-col>
				</v-row>

				<v-row dense class="mt-4">
					<v-col cols="12" class="body-12 text-left gray_70--text">
						<span class="mr-1">{{ $t('invoice.details.payment-details-modal.paid-by') }}</span>
						<span>{{ localPayment.paidBy }}</span>
						<br />

						<div v-if="localPayment.referenceNumber" style="word-wrap:break-word" class="my-2">
							<span class="mr-1">{{ $t('invoice.details.payment-details-modal.reference-number') }}</span>
							<br />
							<span>{{ localPayment.referenceNumber }}</span>
							<br />
						</div>

						<span class="mr-1">{{ $t('invoice.details.payment-details-modal.timestamp') }}</span>
						<span>{{
							$DateTime.fromISO(localPayment.timestamp).toLocaleString($DateTime.DATETIME_SHORT_WITH_SECONDS)
						}}</span>
					</v-col>
				</v-row>
				<v-row dense v-if="localPayment.integrationKeys.quickbooksId">
					<v-col cols="12" align="left">
						<v-icon size="20">$quickbooks</v-icon>
					</v-col>
				</v-row>
				<v-row dense v-if="localPayment.integrationKeys.xeroId">
					<v-col cols="12" align="left">
						<v-icon size="20">$xero</v-icon>
					</v-col>
				</v-row>
				<v-row dense v-if="$store.state.mainApp && invoice.currency !== $store.state.defaultCurrency" class="px-1">
					<v-text-field
							persistent-placeholder
							flat
							hide-details
							type="number"
							step="0.0001"
							label="Exchange rate"
							placeholder="0.0000"
							v-model="exchangeRate"
					>
						<template v-slot:append>
							<v-icon class="pointer material-symbols-rounded" @click="confirmUpdateExchangeRate">save</v-icon>
						</template>
					</v-text-field>
				</v-row>
			</v-container>
		</div>
		<div class="modal-footer">
			<v-btn class="secondary-action mr-1" icon @click="confirmDelete" v-if="editMode && !localPayment.integratedPayment"
				><v-icon size="20">$delete</v-icon></v-btn
			>
			<v-btn class="super-action ml-1" style="width: 140px;" v-if="editMode" @click="sendModal=true">Send receipt</v-btn>
			<v-btn class="primary-action ml-1" style="width: 140px;" @click="handleClose()">{{ $t('global.close') }}</v-btn>
		</div>

		<confirm-dialog
			:dialog="confirmDeleteDialog"
			@close="confirmDeleteDialog = false"
			@confirm="deletePayment()"
			:heading-text="$t('global.confirm')"
			:yes-text="$t('global.yes')"
			:no-text="$t('global.no')"
			:body-text="$t('invoice.delete-payment')"
		>
		</confirm-dialog>

		<basic-modal v-if="editMode && sendModal" :dialog="sendModal" @close="sendModal = false" ref="shareSendModal" :persistent="false" :retain-focus="false">
			<send-widget
					:client-id="invoice.clientId"
					:invoice-id="invoice.id"
					:payment-id="localPayment.id"
					:open-state="true"
					@cancel="sendModal = false"
			></send-widget>
		</basic-modal>
	</div>
</template>

<script>
	import ConfirmDialog from './ConfirmDialog';
	import InvoiceService from '@/modules/invoices/InvoiceService';
	import SendWidget from "@/modules/invoices/SendWidget";
	import BasicModal from "@/components/BasicModal";
	import ConfirmModal from "@/components/ConfirmModal";

	export default {
		name: 'PaymentDetailsModal',

		components: {
			ConfirmDialog,
			SendWidget,
			BasicModal
		},

		props: {
			invoice: { type: Object, required: true },
			payment: { type: Object, required: true },
			editMode: { type: Boolean, required: false, default: false },
		},

		data: function() {
			return {
				localPayment: {... this.payment},
				exchangeRate: null,
				confirmDeleteDialog: false,
				sendModal: false,
				invoiceService: new InvoiceService(),
				paymentProviders: [
					{ value: 'CHECK', text: this.$t('invoice.payment-providers.CHECK') },
					{ value: 'CASH', text: this.$t('invoice.payment-providers.CASH') },
					{ value: 'STRIPE', text: this.$t('invoice.payment-providers.STRIPE') },
				],
			};
		},

		mounted() {
			this.setExchangeRate();
		},

		beforeDestroy() {},

		methods: {
			setExchangeRate: function(){
				if(this.localPayment.exchangeRate){
					this.exchangeRate =  this.localPayment.exchangeRate;
				}else{
					this.exchangeRate = Number(this.localPayment.localAmount / this.localPayment.amount).toFixed(6);
				}
			},

			formattedDate() {
        if(this.localPayment.integratedPayment){
          return this.$DateTime.humanDateTime(this.localPayment.timestamp);
        }else if (this.localPayment.datePaid){
          return this.localPayment.datePaid;
        } else {
          return this.$DateTime.humanDateTime(this.localPayment.timestamp);
        }
			},

			confirmUpdateExchangeRate: function(){
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Do you want to update the exchange rate for this payment?'
				}
				this.$store.state.globalModalController.openModal(ConfirmModal,binding).then((res) => {
					if(res) {
						this.invoiceService.updateExchangeRate(this.invoice.clientId, this.invoice.id, this.localPayment.id, this.exchangeRate).then((update) => {
							this.localPayment = update.data;
							this.setExchangeRate();
							this.$emit('payment-updated',this.localPayment);
						}).catch((err) => console.log(err));
					}
				})
			},

			handleClose: function(){
				this.$emit('close');
				this.$emit('result');
			},

			confirmDelete() {
				this.confirmDeleteDialog = true;
			},

			deletePayment() {
				this.confirmDeleteDialog = false;
				this.invoiceService.deletePayment(this.invoice.clientId, this.invoice.id, this.localPayment.id).then((res) => {
					this.$emit('updated', res.data);
					this.$emit('close');
				});
			},
		},
		watch: {},

		computed: {

		},
	};
</script>

<style lang="scss">
	#payment-details-modal {
		min-width: 300px;

		.label {
			font-size: 14px;
			text-align: left;
			color: var(--v-gray_70-base);
		}
		.value {
			font-size: 14px;
			text-align: left;
		}
	}
</style>
