<template>
	<div>
		<div style="max-width: 55vw; min-width: 55vw">
			<div class="tabs row-format py-2 gap-3">
				<div class="row-format py-2 gap-3">
					<div
						:class="`row-format align-center page-link ${componentSelector === 0 ? 'page-link-active' : ''}`"
						@click="selectTab(0)"
					>
						<div class="ml-1">Calendar view</div>
					</div>
					<div
						:class="`row-format align-center page-link ${componentSelector === 1 ? 'page-link-active' : ''}`"
						@click="selectTab(1)"
					>
						<div class="ml-1">Question form</div>
					</div>
					<div
						:class="`row-format align-center page-link ${componentSelector === 2 ? 'page-link-active' : ''}`"
						@click="selectTab(2)"
					>
						<div class="ml-1">Meeting confirmation</div>
					</div>
					<div
						:class="`row-format align-center page-link ${componentSelector === 3 ? 'page-link-active' : ''}`"
						@click="selectTab(3)"
					>
						<div class="ml-1">Cancel meeting</div>
					</div>
				</div>
			</div>
			<div class="container">
				<mock-scheduler :meeting="meeting" :tokenMap="tokenMapNoFake" v-if="componentSelector === 0" />
				<mock-question-form :meeting="meeting" :tokenMap="tokenMap" v-else-if="componentSelector === 1" />
				<mock-confirmation :meeting="meeting" :tokenMap="tokenMap" v-else-if="componentSelector === 2" />
        <mock-cancel :meeting="meeting" :tokenMap="tokenMap" v-else-if="componentSelector === 3" />
			</div>
		</div>
	</div>
</template>

<script>
	import iFrameResize from 'iframe-resizer/js/iframeResizer';
	import MockScheduler from '@/modules/meetings/detail/components/MockScheduler.vue';
  import MockQuestionForm from '@/modules/meetings/detail/components/MockQuestionForm.vue';
  import MockConfirmation from "@/modules/meetings/detail/components/MockConfirmation.vue";
  import MockCancel from "@/modules/meetings/detail/components/MockCancel.vue";

	export default {
		name: 'Preview',

		props: ['meeting', 'uniqueAccountUrl', 'podId', 'tokenMap', 'tokenMapNoFake'],

		components: { MockScheduler, MockQuestionForm, MockConfirmation, MockCancel },

		data: function() {
			return {
				componentSelector: 0,
				resizerOptions: {
					log: false,
					heightCalculationMethod: 'min',
					sizeWidth: true,
					sizeHeight: true,
					checkOrigin: false,
				},
			};
		},

		mounted() {
			iFrameResize(this.resizerOptions, '#myIframe');
		},

		beforeDestroy() {},

		methods: {
      selectTab(index) {
        this.componentSelector = index;
        this.$emit('selectedTab', index);
      },
    },

		computed: {},
	};
</script>

<style scoped lang="scss">
	.wrapper {
		//border: 1px solid var(--v-gray_50-base);
		//border-radius: 2px;
		height: 46px;
		width: 50%;
	}

	.container {
		width: 100%;
		min-height: 500px;
	}

	.responsive-iframe {
		padding: 0px;
		margin: 0px;
		border: 0;
		max-width: 100%;
		min-width: 100%;
	}

	.tabs {
		font-size: 14px;
		font-weight: 500;
		cursor: pointer;
	}
</style>
