<template>
	<div class="column-format gap-5">
		<v-textarea
			v-model="element.question"
			rows="3"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Question"
			@input="updated"
		></v-textarea>
		<v-text-field
			v-model="element.placeholder"
			hide-details
			dense
			persistent-placeholder
			outlined
			label="Placeholder"
			@input="updated"
		></v-text-field>
		<v-switch v-model="element.required" hide-details dense label="Required" class="mt-n2" @change="updated"></v-switch>
		<v-text-field
				v-model="element.fieldName"
				hide-details
				dense
				persistent-placeholder
				outlined
				label="Mapping key"
				@input="updated"
		></v-text-field>

		<v-select v-model="element.schemaMapping" :items="mappings" item-text="label" item-value="value" hide-details dense outlined label="Mapping" @change="updated"></v-select>


		<draggable :list="element.options" group="elements" class="column-format gap-2" :options="{handle:'.drag-handle'}">
			<div v-for="(option, index) in element.options" :key="index" class="row-format align-center">
				<v-icon tabindex="-1" class="drag-handle" style="cursor: grab">drag_indicator</v-icon>
				<v-text-field
					hide-details
					dense
					outlined
					:value="option"
					placeholder="Option value"
					@input="updateOption(index, $event)"
				></v-text-field>
				<v-icon tabindex="-1" @click="deleteOption(index)">clear</v-icon>
			</div>
		</draggable>
		<v-btn text @click="addOption">+ Add option</v-btn>
	</div>
</template>

<script>
	import draggable from 'vuedraggable';
	import ConfigMixin from "@/modules/forms/config/ConfigMixin";
	export default {
		name: 'SelectConfig',

		props: ['item'],

		mixins: [ConfigMixin],

		components: { draggable },

		data: function() {
			return {
				element: { ...this.item },
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			updateOption: function(index, option) {
				this.element.options.splice(index,1,option);
				this.updated();
			},
			addOption: function() {
				this.element.options.push('');
				this.updated();
			},
			deleteOption: function(index){
				this.element.options.splice(index,1);
				this.updated();
			},
			updated: function() {
				this.$emit('change', this.element);
			},
		},

		computed: {},
	};
</script>

<style scoped lang="scss"></style>
