var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row-format align-center" },
    [
      _c("v-text-field", {
        staticClass: "h-outline mr-2",
        staticStyle: { "max-width": "100px" },
        attrs: {
          type: "number",
          "hide-details": "",
          "persistent-placeholder": "",
          label: "Quantity"
        },
        on: { change: _vm.notifyChange },
        model: {
          value: _vm.quantity,
          callback: function($$v) {
            _vm.quantity = $$v
          },
          expression: "quantity"
        }
      }),
      _c("v-autocomplete", {
        staticClass: "h-outline",
        attrs: {
          "hide-details": "",
          "persistent-placeholder": "",
          "item-text": "name",
          label: "Subscription",
          items: _vm.productsFlattened,
          "return-object": true,
          "item-value": "id"
        },
        on: { change: _vm.notifyChange },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { staticClass: "row-format align-end" }, [
                  _c("div", [_vm._v(_vm._s(item.name))]),
                  _c("div", { staticClass: "ml-2 font-12 font-gray_70" }, [
                    _vm._v(_vm._s(item.amount))
                  ])
                ])
              ]
            }
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { staticClass: "row-format align-end" }, [
                  _c("div", [_vm._v(_vm._s(item.name))]),
                  _c("div", { staticClass: "ml-2 font-12 font-gray_70" }, [
                    _vm._v(_vm._s(item.amount))
                  ])
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.selectedProduct,
          callback: function($$v) {
            _vm.selectedProduct = $$v
          },
          expression: "selectedProduct"
        }
      }),
      _c(
        "v-btn",
        {
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("delete", { id: _vm.id })
            }
          }
        },
        [
          _c("v-icon", { attrs: { small: "", color: "gray_70" } }, [
            _vm._v("$delete")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }