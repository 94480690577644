var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "column-format" },
    [
      _c("v-text-field", {
        attrs: {
          label: "Open URL",
          placeholder: "https://www.yourwebsite.com/confirmation",
          "persistent-placeholder": "",
          "hide-details": "",
          dense: "",
          outlined: ""
        },
        on: {
          input: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.element.redirectUrl,
          callback: function($$v) {
            _vm.$set(_vm.element, "redirectUrl", $$v)
          },
          expression: "element.redirectUrl"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format align-center pt-4" },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              dense: "",
              "persistent-placeholder": "",
              outlined: "",
              label: "Button text"
            },
            on: { input: _vm.updated },
            model: {
              value: _vm.element.text,
              callback: function($$v) {
                _vm.$set(_vm.element, "text", $$v)
              },
              expression: "element.text"
            }
          })
        ],
        1
      ),
      _c("v-select", {
        staticClass: "pt-4",
        attrs: {
          items: _vm.alignment,
          "item-text": "label",
          "item-value": "value",
          "hide-details": "",
          dense: "",
          outlined: "",
          label: "Button alignment"
        },
        on: {
          change: function($event) {
            return _vm.updated()
          }
        },
        model: {
          value: _vm.element.alignment,
          callback: function($$v) {
            _vm.$set(_vm.element, "alignment", $$v)
          },
          expression: "element.alignment"
        }
      }),
      _c(
        "div",
        { staticClass: "row-format gap-2 pt-4" },
        [
          _c("v-select", {
            staticStyle: { width: "48%" },
            attrs: {
              items: ["Normal", "Outlined", "Text"],
              "hide-details": "",
              outlined: "",
              dense: "",
              label: "Button style"
            },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.style,
              callback: function($$v) {
                _vm.$set(_vm.element, "style", $$v)
              },
              expression: "element.style"
            }
          }),
          _c("v-select", {
            staticStyle: { width: "48%" },
            attrs: {
              items: ["X-Small", "Small", "Normal", "Large", "X-Large"],
              "hide-details": "",
              outlined: "",
              dense: "",
              label: "Button size"
            },
            on: {
              change: function($event) {
                return _vm.updated()
              }
            },
            model: {
              value: _vm.element.size,
              callback: function($$v) {
                _vm.$set(_vm.element, "size", $$v)
              },
              expression: "element.size"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "row-format gap-2 mt-n2 pt-4" }, [
        _c(
          "div",
          { staticClass: "column-format", staticStyle: { width: "49%" } },
          [
            _c("div", { staticClass: "font-14 text-left font-gray_70" }, [
              _vm._v("Button color")
            ]),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-click": false,
                  "close-on-content-click": false,
                  bottom: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass: "pointer",
                              style:
                                "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                                _vm.element.color
                            },
                            on
                          ),
                          [_vm._v("   ")]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.colorMenu,
                  callback: function($$v) {
                    _vm.colorMenu = $$v
                  },
                  expression: "colorMenu"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ma-3 column-format align-center gap-3",
                    staticStyle: { "background-color": "var(--v-white-base)" }
                  },
                  [
                    _c("v-color-picker", {
                      attrs: {
                        mode: "hexa",
                        "show-swatches": "",
                        "swatches-max-height": "150px"
                      },
                      on: {
                        input: function($event) {
                          return _vm.updated()
                        }
                      },
                      model: {
                        value: _vm.element.color,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "color", $$v)
                        },
                        expression: "element.color"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            _vm.colorMenu = false
                          }
                        }
                      },
                      [_vm._v("OK")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "column-format", staticStyle: { width: "49%" } },
          [
            _c("div", { staticClass: "font-14 text-left font-gray_70" }, [
              _vm._v("Button text color")
            ]),
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-click": false,
                  "close-on-content-click": false,
                  bottom: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "div",
                          _vm._g(
                            {
                              staticClass: "pointer",
                              style:
                                "border: 1px solid var(--v-gray_30-base); border-radius: 4px; min-width:100%: min-height: 20px; background-color: " +
                                _vm.element.textColor
                            },
                            on
                          ),
                          [_vm._v("   ")]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.textColorMenu,
                  callback: function($$v) {
                    _vm.textColorMenu = $$v
                  },
                  expression: "textColorMenu"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "ma-3 column-format align-center gap-3",
                    staticStyle: { "background-color": "var(--v-white-base)" }
                  },
                  [
                    _c("v-color-picker", {
                      attrs: {
                        mode: "hexa",
                        "show-swatches": "",
                        "swatches-max-height": "150px"
                      },
                      on: {
                        input: function($event) {
                          return _vm.updated()
                        }
                      },
                      model: {
                        value: _vm.element.textColor,
                        callback: function($$v) {
                          _vm.$set(_vm.element, "textColor", $$v)
                        },
                        expression: "element.textColor"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            _vm.textColorMenu = false
                          }
                        }
                      },
                      [_vm._v("OK")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }