var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "plan-card text-left gap-2" }, [
    _c(
      "div",
      {
        staticStyle: {
          "font-size": "36px",
          "font-weight": "600",
          "line-height": "36px"
        }
      },
      [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "subscription-v" +
                  this.pricingVersion +
                  "." +
                  _vm.planType +
                  ".header"
              )
            ) +
            " "
        )
      ]
    ),
    _c("div", { staticClass: "font-20" }, [
      _vm.isAnnual
        ? _c("div", [
            _vm._v(
              _vm._s(
                _vm.$formatters.dollars(_vm.plan.price, false, true, "USD")
              ) + " annually"
            )
          ])
        : _c("div", [
            _vm._v(
              _vm._s(
                _vm.$formatters.dollars(_vm.plan.price, false, true, "USD")
              ) + " monthly"
            )
          ]),
      _vm.isAnnual
        ? _c("div", { staticClass: "font-12 font-gray_70" }, [
            _vm._v(" Save 20% with an annual plan! ")
          ])
        : _vm._e()
    ]),
    _c("div", [
      _c("div", { staticClass: "font-primary" }, [
        _vm._v(
          _vm._s(_vm.$t("subscription-v" + this.pricingVersion + ".who-for"))
        )
      ]),
      _c("div", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "subscription-v" +
                this.pricingVersion +
                "." +
                _vm.planType +
                ".who-for"
            )
          )
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "my-2" },
      [
        _vm.isCurrentPlan && !_vm.inTrial
          ? _c("v-btn", { attrs: { large: "", block: "", disabled: true } }, [
              _vm._v("Current plan")
            ])
          : _c(
              "v-btn",
              {
                attrs: {
                  color: "secondary",
                  large: "",
                  block: "",
                  disabled: _vm.disabled
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("click")
                  }
                }
              },
              [
                _c("span", { staticStyle: { color: "var(--v-white-base)" } }, [
                  _vm._v("Select plan")
                ])
              ]
            )
      ],
      1
    ),
    _c("div", [
      _c(
        "ul",
        { staticClass: "font-14" },
        _vm._l(_vm.points, function(point, index) {
          return _c("li", { key: index }, [_vm._v(_vm._s(point))])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }