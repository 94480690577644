<template>
	<div>
		<div class="mx-3 mb-2">
			<v-switch
				v-model="sendSubscriptionEmails"
				hide-details
				dense
				label="Send emails on subscription events"
				@change="updatePreferences"
			></v-switch>
		</div>

		<v-data-table
			item-key="id"
			class="list-view"
			fixed-header
			:headers="headers"
			:items="subscriptionsComputed"
			:hide-default-footer="true"
			:items-per-page="-1"
		>
			<template v-slot:item.client="{ item }">
				<div class="row-format align-center" v-if="item.clientMini">
					<client-avatar :client="item.clientMini"></client-avatar>
					<div>{{ item.clientMini.name }}</div>
				</div>
				<div v-else>--</div>
			</template>

			<template v-slot:item.periodStart="{ item }">
				<div style="height: 100%" class="row-format align-center">
					<div v-if="item.periodStart && item.periodEnd">
						{{ DateTime.fromISO(item.periodStart).toLocaleString() }} -
						{{ DateTime.fromISO(item.periodEnd).toLocaleString() }}
					</div>
					<div v-else>--</div>
				</div>
			</template>

			<template v-slot:item.daysUntilDue="{ item }">
				<div style="height: 100%" class="row-format align-center">
					<div v-if="item.daysUntilDue">{{ item.daysUntilDue }}</div>
					<div v-else>--</div>
				</div>
			</template>

			<template v-slot:item.subscriptionItems="{ item }">
				<div style="height: 100%" class="row-format align-center">
					<div v-for="(subItem, index) in item.subscriptionItems" :key="item.clientMini + ':' + index">
						{{ subItem.quantity }} x {{ subItem.description }} @
						{{ $formatters.dollars(subItem.amount, true, true, subItem.currency) }}
					</div>
				</div>
			</template>

			<template v-slot:item.status="{ item }">
				<div style="height: 100%" class="row-format align-center">
					<v-icon v-if="item.status === 'active'" color="success" small>$check</v-icon>
					<v-icon v-else-if="item.status === 'canceled' || item.status === 'ended'" color="gray_80" small
						>$cancel</v-icon
					>
					<v-icon v-else-if="item.status === 'sent'" color="primary" small>$email</v-icon>
					<v-icon v-else color="warning" small>$alert</v-icon>

					<div v-if="item.status === 'active' && item.cancelAtPeriodEnd" class="ml-1">Cancellation pending</div>
					<div v-else class="ml-1" style="text-transform: capitalize">{{ item.status.replace('_', ' ') }}</div>
				</div>
			</template>

			<template v-slot:item.action="{ item }">
				<subscription-action-menu
					:subscription="item"
					@cancel-subscription="cancelSubscription(item)"
					@copy-payment-link="copyPaymentLink(item)"
					@cancel-request="cancelSubscriptionRequest(item)"
					@view-payments="viewPayments(item)"
				></subscription-action-menu>
			</template>
		</v-data-table>
	</div>
</template>

<script>
	import StripeSubscriptionService from '@/modules/integrations/stripe/subscriptions/StripeSubscriptionService';
	import ClientAvatar from '@/components/ClientAvatar';
	import DateTime from '@/modules/utils/HDateTime';
	import SendSubscriptionRequest from '@/modules/integrations/stripe/subscriptions/SendSubscriptionRequest';
	import SubscriptionActionMenu from '@/modules/integrations/stripe/subscriptions/SubscriptionActionMenu';
	import LinkShare from '@/components/LinkShare';
	import ConfirmModal from '@/components/ConfirmModal';
	import SubscriptionPayments from '@/modules/integrations/stripe/subscriptions/SubscriptionPayments';
	import StripeIntegrationService from '@/modules/integrations/stripe/StripeIntegrationService';

	export default {
		name: 'StripeSubscriptions',

		props: ['filter', 'stripeIntegration'],

		components: { SubscriptionActionMenu, ClientAvatar },

		data: function() {
			return {
				stripeService: new StripeIntegrationService(),
				stripeSubscriptionService: new StripeSubscriptionService(),
				subscriptions: [],
				requests: [],
				DateTime: DateTime,
				sendSubscriptionEmails: false,
			};
		},

		mounted() {
			this.getSubscriptions();
			this.getSubscriptionRequests();
			if (this.stripeIntegration) {
				this.sendSubscriptionEmails = this.stripeIntegration.sendSubscriptionEmails;
			}
		},

		beforeDestroy() {},

		methods: {
			updatePreferences: function() {
				this.stripeService.updatePreferences(null, null, this.sendSubscriptionEmails);
			},

			getSubscriptions: function() {
				this.stripeSubscriptionService.getSubscriptions().then((res) => {
					this.subscriptions.splice(0, this.subscriptions.length);
					this.subscriptions.push(...res.data);
				});
			},

			getSubscriptionRequests: function() {
				this.stripeSubscriptionService.getSubscriptionRequests().then((res) => {
					this.requests.splice(0, this.requests.length);
					this.requests.push(...res.data);
				});
			},

			addNew: function() {
				this.$store.state.globalModalController.openModal(SendSubscriptionRequest, {}, false, false).then((res) => {
					this.requests.push(res);
				});
			},

			cancelSubscription: function(subscription) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to cancel this subscription?',
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.$store.commit('startLoading');
						this.stripeSubscriptionService
							.cancelSubscription(subscription.id)
							.then(() => {
								this.getSubscriptions();
							})
							.finally(() => this.$store.commit('stopLoading'));
					}
				});
			},

			cancelSubscriptionRequest: function(subscription) {
				let binding = {
					headingText: 'Confirm',
					bodyText: 'Are you sure you want to cancel this subscription request?',
				};

				this.$store.state.globalModalController.openModal(ConfirmModal, binding).then((res) => {
					if (res) {
						this.stripeSubscriptionService.deleteSubscriptionRequest(subscription.id).then(() => {
							let ix = this.requests.findIndex((r) => r.id === subscription.id);
							if (ix > -1) {
								this.requests.splice(ix, 1);
							}
						});
					}
				});
			},

			copyPaymentLink: function(subscription) {
				let binding = {
					link: subscription.checkoutUrl,
				};
				this.$store.state.globalModalController.openModal(LinkShare, binding);
			},

			viewPayments: function(subscription) {
				let binding = {
					subscription: subscription,
				};

				this.$store.state.globalModalController.openModal(SubscriptionPayments, binding);
			},
		},

		computed: {
			subscriptionsComputed: function() {
				let result = [...this.subscriptions];

				for (let i = 0; i < this.requests.length; i++) {
					let request = this.requests[i];

					let pendingSubscription = {
						id: request.id,
						clientMini: this.$store.getters.getClientById(request.clientId),
						periodStart: null,
						periodEnd: null,
						daysUntilDue: null,
						checkoutUrl: request.checkoutUrl,
						status: 'sent',
					};

					console.log(request);
					console.log(pendingSubscription);

					if (request.version === 1) {
						pendingSubscription.subscriptionItems = [
							{
								quantity: request.quantity,
								description: request.subscriptionProduct.name,
								amount: request.subscriptionProduct.prices[0].unitAmount / 100,
								currency: request.subscriptionProduct.prices[0].currency,
							},
						];
					} else if (request.version === 2) {
						pendingSubscription.subscriptionItems = request.productSelections.map((p) => ({
							quantity: p.quantity,
							description: p.subscriptionProduct.name,
							amount: p.subscriptionProduct.prices[0].unitAmount / 100,
							currency: p.subscriptionProduct.prices[0].currency,
						}));
					}

					result.push(pendingSubscription);
				}

				return result
					.filter((r) => {
						if (this.filter.subscriptionStatus.length) {
							return this.filter.subscriptionStatus.includes(r.status);
						} else {
							return true;
						}
					})
					.filter((r) => {
						if (this.filter.clientList.length) {
							return this.filter.clientList.includes(r.clientMini.id);
						} else {
							return true;
						}
					})
					.filter((r) => {
						if (this.filter.search) {
							return r.subscriptionItems[0].description.toLowerCase().includes(this.filter.search.toLowerCase());
						} else {
							return true;
						}
					});
			},

			headers: function() {
				return [
					{ text: this.$t('global.client'), value: 'client' },
					{ text: this.$t('stripe.subscription.period'), value: 'periodStart' },
					{ text: this.$t('stripe.subscription.days-until'), value: 'daysUntilDue' },
					{ text: this.$t('stripe.subscription.items'), value: 'subscriptionItems' },
					{ text: this.$t('stripe.subscription.status'), value: 'status' },
					{ text: '', value: 'action', align: 'right' },
				];
			},
		},
	};
</script>

<style scoped lang="scss"></style>
