<template>
	<v-container fluid style="border: 1px solid var(--v-gray_30-base); border-radius: 4px; overflow: hidden; background-color: var(--v-white-base)">
		<v-row>
			<v-col cols="5" class="pa-0 column-format" style="justify-content: center; background-color: var(--v-gray_5-base); border-right: 1px solid var(--v-gray_30-base)">
				<div class="booking-widget">
					<div class="header">{{label}}</div>
					<div class="body">{{ totalUsage }}</div>
				</div>
				<div class="booking-widget">
					<div class="header">Earnings</div>
					<div class="body">{{ $formatters.dollars(totalValue, true, true) }}</div>
				</div>
			</v-col>
			<v-col cols="7" style="">
				<div class="row-format centered pa-2" style="overflow: hidden">
					<pie v-if="unBilled.length"
						:chart-id="pieRefreshKey"
						datasetIdKey="insight-time-pie"
						:chart-options="pieChartOptions"
						:chart-data="pieChartData"
						:width="200"
						:height="200"
					/>
					<div v-else style="min-height: 200px" class="column-format centered"><v-icon size="100" color="gray_20">block</v-icon></div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import { Pie } from 'vue-chartjs/legacy';
	import {v4 as uuidv4} from "uuid";

	export default {
		name: 'UnBilled',

		props: ['label','unBilled','isTime','appendLink'],

		components: { Pie },

		data: function() {
			return {
				pieRefreshKey: uuidv4(),

				pieChartOptions: {
					responsive: false,
					maintainAspectRatio: false,
					onClick: this.handle,
					plugins: {
						tooltip: {
							callbacks: {
								label: (context) => {
									return context.label + '\n' + this.$formatters.dollars(context.raw, true, true);
								},
							},
						},
					},
				},
			};
		},

		mounted() {},

		beforeDestroy() {},

		methods: {
			handle(point, event) {
				if (!event.length) {
					return;
				}

				let datasetIndex = event[0].index;
				let client = this.totalByClient[datasetIndex];

				if(client){
					let route = `/client/${client.clientId}${this.appendLink ? this.appendLink : ''}`
					if(point && (point.native.ctrlKey || point.native.metaKey)){
						let w = window.open(route,'_blank');
						w.focus();
					}else {
						this.$router.push(route);
					}
				}
			},
		},

		computed: {
			totalByClient: function() {
				let data = JSON.parse(JSON.stringify(this.unBilled));
				data.forEach((d) => (d.clientId = d.client.id));

				let grouped = this.$formatters.groupBy(data, 'clientId');
				let result = [];

				for (let g in grouped) {
					let group = grouped[g];
					let clientTotal;
					if(this.isTime){
						clientTotal = group.reduce((sum, usage) => sum + (usage.quantity/60/60) * usage.rate, 0);
					}else{
						clientTotal = group.reduce((sum, usage) => sum + usage.quantity * usage.rate, 0);
					}

					let client = this.$store.getters.getClientById(g);

					if (!client) {
						client = {
							name: g === 'null' ? 'No client' : '[Deleted]',
							color: '#eee',
						};
					}

					result.push({
						clientId: g,
						clientName: client.name,
						color: client.color,
						total: clientTotal.toFixed(2),
					});
				}
				return result;
			},

			pieChartData: function() {
				return {
					id: 'fd635f2c-bfc1-4a31-ade6-302a25518x7d',
					labels: this.totalByClient.map((b) => b.clientName),
					datasets: [
						{
							label: 'Time',
							data: this.totalByClient.map((b) => b.total),
							backgroundColor: this.totalByClient.map((b) => b.color),
						},
					],
				};
			},

			totalUsage: function() {
				let total = this.unBilled.map((u) => u.quantity).reduce((sum, value) => sum + value, 0);
				if(this.isTime) {
					return this.$formatters.formatSecondsLong(total);
				}else{
					return total;
				}
			},

			totalValue: function() {
				let totalValue;
				if(this.isTime) {
						totalValue = this.unBilled
							.map((u) => (u.quantity / 60 / 60) * u.rate)
							.reduce((sum, value) => sum + value, 0);
				}else{
					totalValue = this.unBilled
							.map((u) => u.quantity * u.rate)
							.reduce((sum, value) => sum + value, 0);
				}
				return Math.round((totalValue + Number.EPSILON) * 100) / 100;
			},
		},
	};
</script>

<style scoped lang="scss">
	.booking-widget {
		text-align: left;
		//background-color: var(--v-white-base);
		padding: 16px;
		border-radius: 4px;

		.header {
			font-size: 16px;
			color: var(--v-gray_80-base);
		}

		.body {
			font-size: 24px;
			font-weight: 600;
		}
	}
</style>
